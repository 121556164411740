// Libraries
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

import CrewSlotForm from '@shared/modules/Dispatch/forms/CrewSlotForm';

const _new = (shouldRunUpsertCostsAndBillingEngineAsync: any) => ({
  crewId: undefined,
  crewSlotForms: [],

  // TODO(peter): Change to false once over capacity modal is implemented
  canCreateNewSlot: true,

  assignTrucksToAllCrews: false,
  shouldRunUpsertCostsAndBillingEngineAsync: shouldRunUpsertCostsAndBillingEngineAsync || false,
});

const edit = withFragment(
  (crew, {canCreateNewSlot, shouldRunUpsertCostsAndBillingEngineAsync}) => ({
    crewId: (crew as any).id,
    crewSlotForms: (crew as any).crewSlotsForStartDateV2.map((crewSlot: any) =>
      CrewSlotForm.edit(crewSlot),
    ),
    canCreateNewSlot,
    assignTrucksToAllCrews: false,
    shouldRunUpsertCostsAndBillingEngineAsync: shouldRunUpsertCostsAndBillingEngineAsync || false,
  }),
  gql`
    ${CrewSlotForm.edit.fragment}

    fragment SetCrewSlotsToCrewForm_edit on Crew {
      id
      crewSlotsForStartDateV2 {
        id
        ...CrewSlotForm_edit
      }
    }
  `,
);

const toForm = ({
  crewId,
  canCreateNewSlot,
  crewSlotForms,
  assignTrucksToAllCrews,
  shouldRunUpsertCostsAndBillingEngineAsync,
}: any) => ({
  crewId,
  crewSlotForms: crewSlotForms.map((crewSlotForm: any) => CrewSlotForm.toForm(crewSlotForm)),
  canCreateNewSlot,
  assignTrucksToAllCrews,
  shouldRunUpsertCostsAndBillingEngineAsync,
});

const toMutation = ({
  crewId,
  canCreateNewSlot,
  crewSlotForms,
  assignTrucksToAllCrews,
  shouldRunUpsertCostsAndBillingEngineAsync,
}: any) => ({
  crewId,
  crewSlotForms: crewSlotForms.map((crewSlotForm: any) => CrewSlotForm.toMutation(crewSlotForm)),
  canCreateNewSlot,
  assignTrucksToAllCrews,
  shouldRunUpsertCostsAndBillingEngineAsync,
});

const SetCrewSlotsToCrewForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default SetCrewSlotsToCrewForm;
