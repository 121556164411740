// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Loading, Styled} from '@supermove/components';

import Page from './Page';

const Container = Styled.View`
  flex: 1;
`;

const ModalPage = ({
  fetchPolicy,
  navigation,
  pollInterval,
  query,
  variables,
  loadingAs,
  children,
}) => (
  <Page fetchPolicy={fetchPolicy} pollInterval={pollInterval} query={query} variables={variables}>
    {({responsive, loading, ...props}) => (
      <Container {...responsive}>
        {navigation({responsive, loading, ...props})}
        <Loading as={loadingAs} loading={loading}>
          {() => children({responsive, ...props})}
        </Loading>
      </Container>
    )}
  </Page>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ModalPage.propTypes = {
  navigation: PropTypes.func,
  pollInterval: PropTypes.number,
};

ModalPage.defaultProps = {
  navigation: () => null,
  pollInterval: 0,
};

export default ModalPage;
