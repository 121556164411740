// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, Icon, Space, Styled, Popover} from '@supermove/components';
import {useDebouncedCallback, useNavigationDOM, usePopover, useState} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {Datetime, URL} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const Header = Styled.Text`
  ${Typography.Label1}
`;

const BodyText = Styled.Text`
  ${Typography.Body3}
  padding-bottom: 12px;
`;

const FiltersRow = Styled.View`
  z-index: ${(props) => 1000 - props.index}
  flex-direction: row;
  align-items: flex-end;
`;

const DateInputContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex: 1;
`;

const TextInput = Styled.TextInput`
  ${Typography.Body}
  height: 36px;
`;

const getButtonText = ({params}) => {
  const {subTab, page, query, ...countedFilters} = params;
  const activeFilters = _.compact(_.values(countedFilters));
  return `Filters${_.isEmpty(activeFilters) ? '' : ` (${activeFilters.length})`}`;
};

const getFilteredUrlFromParams = (params) => {
  const {subTab, ...filters} = params;
  const variables = {...filters, page: '1'};
  return URL.getUrlFromVariables(`/dispatch/long-distance/trips/${subTab}`, variables);
};

const handleUpdateParam = ({navigator, params, paramKey, paramValue}) => {
  navigator.replace(getFilteredUrlFromParams({...params, [paramKey]: paramValue || ''}));
};

const handleClearFilters = ({navigator, params}) => {
  navigator.replace(
    getFilteredUrlFromParams({
      ...params,
      originCity: '',
      originState: '',
      destinationCity: '',
      destinationState: '',
      fromStartDate: '',
      toStartDate: '',
      fromEndDate: '',
      toEndDate: '',
    }),
  );
};

const TextFilter = ({index, paramKey, label, placeholder}) => {
  const {navigator, params} = useNavigationDOM();
  const handleUpdate = useDebouncedCallback(
    ({text}) => handleUpdateParam({navigator, params, paramKey, paramValue: text}),
    500,
  );
  const [value, setValue] = useState(params[paramKey] || '');

  return (
    <DateInputContainer index={index}>
      <FieldInput.LabelText>{label}</FieldInput.LabelText>
      <Space height={4} />
      <TextInput
        placeholder={placeholder}
        onChangeText={(text) => {
          setValue(text);
          handleUpdate({text});
        }}
        value={value}
      />
    </DateInputContainer>
  );
};

const DateFilter = ({index, paramKey, label}) => {
  const {navigator, params} = useNavigationDOM();
  const handleUpdateDate = useDebouncedCallback(
    ({date}) =>
      handleUpdateParam({
        navigator,
        params,
        paramKey,
        paramValue: date ? Datetime.convertToDate(date) : '',
      }),
    500,
  );

  return (
    <DateInputContainer index={index}>
      <FieldInput.LabelText>{label}</FieldInput.LabelText>
      <Space height={4} />
      <DateInput
        placeholder={'MM/DD/YYYY'}
        setFieldValue={() => {}}
        onChangeDate={(date) => handleUpdateDate({date})}
        value={Datetime.toFormDate(params[paramKey])}
        style={{
          width: 154,
        }}
      />
    </DateInputContainer>
  );
};

const ClearFiltersButton = () => {
  const {navigator, params} = useNavigationDOM();

  return (
    <SecondaryButton
      text={'Clear Filters'}
      onPress={() => handleClearFilters({navigator, params})}
    />
  );
};

const OriginFilters = ({index}) => {
  return (
    <FiltersRow index={index}>
      <TextFilter
        index={0}
        paramKey={'originCity'}
        label={'Origin City'}
        placeholder={'Sacramento'}
      />
      <Space width={8} />
      <TextFilter index={1} paramKey={'originState'} label={'Origin State'} placeholder={'CA'} />
    </FiltersRow>
  );
};

const DestinationFilters = ({index}) => {
  return (
    <FiltersRow index={index}>
      <TextFilter
        index={0}
        paramKey={'destinationCity'}
        label={'Destination City'}
        placeholder={'Sacramento'}
      />
      <Space width={8} />
      <TextFilter
        index={1}
        paramKey={'destinationState'}
        label={'Destination State'}
        placeholder={'CA'}
      />
    </FiltersRow>
  );
};

const StartDateFilters = ({index}) => {
  return (
    <FiltersRow index={index}>
      <DateFilter index={0} paramKey={'fromStartDate'} label={'Start Date'} />
      <Space width={8} />
      <BodyText>-</BodyText>
      <Space width={8} />
      <DateFilter index={1} paramKey={'toStartDate'} />
    </FiltersRow>
  );
};

const EndDateFilters = ({index}) => {
  return (
    <FiltersRow index={index}>
      <DateFilter index={0} paramKey={'fromEndDate'} label={'End Date'} />
      <Space width={8} />
      <BodyText>-</BodyText>
      <Space width={8} />
      <DateFilter index={1} paramKey={'toEndDate'} />
    </FiltersRow>
  );
};

const TripFiltersPopoverContent = () => {
  return (
    <ResponsivePopover.StaticContainer width={360}>
      <ContentContainer>
        <Space height={16} />
        <Header>Filters</Header>
        <Space height={12} />
        <OriginFilters index={0} />
        <Space height={12} />
        <DestinationFilters index={0} />
        <Space height={12} />
        <StartDateFilters index={0} />
        <Space height={12} />
        <EndDateFilters index={1} />
        <Space height={12} />
        <ClearFiltersButton />
        <Space height={16} />
      </ContentContainer>
    </ResponsivePopover.StaticContainer>
  );
};

const TripFiltersPopover = ({popover}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <TripFiltersPopoverContent />
    </Popover>
  );
};

const TripFiltersButton = ({style}) => {
  const {params} = useNavigationDOM();
  const tripFilterPopover = usePopover({
    name: 'Trip Filters Popover',
  });

  return (
    <React.Fragment>
      <Popover.Content innerRef={tripFilterPopover.ref}>
        <SecondaryButton
          text={getButtonText({params})}
          style={style}
          onPress={tripFilterPopover.handleOpen}
          iconLeft={Icon.Filter}
        />
      </Popover.Content>
      <TripFiltersPopover popover={tripFilterPopover} />
    </React.Fragment>
  );
};

export default TripFiltersButton;
