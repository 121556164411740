// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import TeamForm from '@shared/modules/TaskManagement/forms/TeamForm';
import useUpdateTeamMutation from '@shared/modules/TaskManagement/hooks/useUpdateTeamMutation';
import TeamDrawerFields from 'modules/Organization/Settings/Staff/components/TeamDrawerFields';

const EditTeamDrawer = ({isOpen, handleClose, team, refetch, isCrewTeam}) => {
  const teamForm = TeamForm.edit(team);
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Team updated!',
  });

  const {form, handleSubmit, submitting} = useUpdateTeamMutation({
    teamForm,
    onSuccess: () => {
      handleClose();
      successToast.handleToast();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Edit Team'}
      primaryActionText={'Save'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <TeamDrawerFields
        form={form}
        field={'teamForm'}
        organization={team.organization}
        isCrewTeam={isCrewTeam}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditTeamDrawer.fragment = gql`
  ${TeamDrawerFields.fragment}
  ${TeamForm.edit.fragment}
  fragment EditTeamDrawer on Team {
    id
    organization {
      id
      ...TeamDrawerFields
    }
    ...TeamForm_edit
  }
`;

export default EditTeamDrawer;
