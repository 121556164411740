// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (userNotificationSetting) => ({
    userNotificationSettingId: (userNotificationSetting as any).id,
    isInAppEnabled: (userNotificationSetting as any).isInAppEnabled,
    isEmailEnabled: (userNotificationSetting as any).isEmailEnabled,
    isSmsEnabled: (userNotificationSetting as any).isSmsEnabled,
  }),
  gql`
    fragment UserNotificationSettingForm_edit on UserNotificationSetting {
      id
      isInAppEnabled
      isEmailEnabled
      isSmsEnabled
    }
  `,
);

const toForm = ({
  userNotificationSettingId,
  isInAppEnabled,
  isEmailEnabled,
  isSmsEnabled,
}: any) => ({
  userNotificationSettingId,
  isInAppEnabled,
  isEmailEnabled,
  isSmsEnabled,
});

const toMutation = ({
  userNotificationSettingId,
  isInAppEnabled,
  isEmailEnabled,
  isSmsEnabled,
}: any) => ({
  userNotificationSettingId,
  isInAppEnabled,
  isEmailEnabled,
  isSmsEnabled,
});

const UserNotificationSettingForm = {
  edit,
  toForm,
  toMutation,
};

export default UserNotificationSettingForm;
