// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {SidebarV1, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useNavigationDOM} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import {CheckboxField, Navigation} from 'modules/App/components';
import CompanyOrganizationsDropdown from 'modules/Company/components/CompanyOrganizationsDropdown';

const Container = Styled.View`
  flex: 1;
  width: 300px;
`;

const Header = Styled.View`
  align-items: flex-end;
`;

const Content = Styled.View`
  flex: 1;
  padding-horizontal: 20px;
`;

const Title = Styled.H3`
  ${fontWeight(900)}
  color: ${colors.gray.primary};
  letter-spacing: -0.5;
`;

const Actions = Styled.View`
  flex-direction: row;
`;

const SelectButton = Styled.Button`
  flex-direction: row;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding-horizontal: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  box-shadow: none;
`;

const SelectButtonText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  letter-spacing: 0.5;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const SectionTitle = Styled.H7`
  margin-bottom: 3px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  text-transform: uppercase;
`;

const Line = Styled.View`
  align-self: stretch;
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Fields = Styled.View`
  margin-top: 10px;
`;

const FilterField = Styled.View`
  margin-top: 10px;
`;

const Footer = Styled.View`
  padding: 10px;
  align-items: flex-end;
  pointer-events: none; // Prevents the footer from blocking salesperson checkboxes
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 20px;
`;

const ButtonText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const setIsSelectedForProjectTypes = ({form, isSelected}) => {
  const newProjectTypesForm = _.cloneDeep(form.values.projectTypes);
  newProjectTypesForm.forEach((projectTypes, index) => {
    _.set(newProjectTypesForm, `${index}.isSelected`, isSelected);
  });
  form.setFieldValue('projectTypes', newProjectTypesForm);
};

const setIsSelectedForSalespersons = ({form, isSelected}) => {
  const newSalespersonsForm = _.cloneDeep(form.values.salespersons);
  newSalespersonsForm.forEach((salespersonForm, index) => {
    _.set(newSalespersonsForm, `${index}.isSelected`, isSelected);
  });
  form.setFieldValue('salespersons', newSalespersonsForm);
};

const setIsSelectedForAll = ({form, isSelected}) => {
  setIsSelectedForProjectTypes({form, isSelected});
  setIsSelectedForSalespersons({form, isSelected});
};

const deselectAll = ({form}) => {
  setIsSelectedForAll({form, isSelected: false});
};
const selectAll = ({form}) => {
  setIsSelectedForAll({form, isSelected: true});
};

const getShowBookingCalendarCompanyOrganizationsDropdown = ({organization}) => {
  return organization && organization.isPrimary && organization.company.organizations.length > 1;
};

const BookingFiltersSidebar = ({
  isOpen,
  form,
  onOpen,
  onClose,
  children,
  queryDate,
  selectedOrganizationSlug,
  organization,
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  return (
    <SidebarV1
      isOpen={isOpen}
      position={'right'}
      onOpen={onOpen}
      onClose={onClose}
      content={
        <Container>
          <Header>
            <Navigation.CloseButton responsive={responsive} onPress={onClose} />
          </Header>
          <Content>
            <Title>Filters</Title>
            <Actions>
              <SelectButton color={colors.white} onPress={() => selectAll({form})}>
                <SelectButtonText>Select All</SelectButtonText>
              </SelectButton>
              <ButtonSpace />
              <SelectButton color={colors.white} onPress={() => deselectAll({form})}>
                <SelectButtonText>Deselect All</SelectButtonText>
              </SelectButton>
            </Actions>
            {responsive.mobile &&
              getShowBookingCalendarCompanyOrganizationsDropdown({organization}) && (
                <React.Fragment>
                  <Space height={8} />
                  <Section index={0}>
                    <CompanyOrganizationsDropdown
                      company={organization.company}
                      form={form}
                      fieldName={'selectedOrganizationSlug'}
                      handleChangeValue={(selectedOrganization) => {
                        if (
                          organization.features
                            .isEnabledBookingCalendarSubContractorViewOwnOrganizationJobs
                        ) {
                          form.setFieldValue(
                            'projectTypes',
                            Organization.getProjectTypes(selectedOrganization),
                          );
                          form.setFieldValue(
                            'salespersons',
                            Organization.getSalesPersons(selectedOrganization),
                          );
                        }
                        navigator.replace(
                          `/booking/calendar?date=${queryDate}&organization=${selectedOrganization.slug}`,
                        );
                      }}
                    />
                  </Section>
                </React.Fragment>
              )}
            <Space height={16} />
            <Section index={1}>
              <SectionTitle>Project Types</SectionTitle>
              <Line />
              <Fields>
                {form.values.projectTypes.map((projectTypes, index) => (
                  <FilterField key={index}>
                    <CheckboxField
                      {...form}
                      name={`projectTypes.${index}.isSelected`}
                      label={projectTypes.name}
                    />
                  </FilterField>
                ))}
              </Fields>
            </Section>
            {form.values.isEnabledBookingCalendarPackingFilters && (
              <React.Fragment>
                <Space height={16} />
                <Section index={2}>
                  <SectionTitle>Job Types</SectionTitle>
                  <Line />
                  <Fields>
                    <FilterField>
                      <CheckboxField
                        {...form}
                        name={`showOnlyPackingJobs`}
                        label={'Show Only Packing Jobs'}
                      />
                    </FilterField>
                    <FilterField>
                      <CheckboxField
                        {...form}
                        name={`showOnlyNonPackingJobs`}
                        label={'Show Only Non Packing Jobs'}
                      />
                    </FilterField>
                  </Fields>
                </Section>
              </React.Fragment>
            )}
            <Space height={16} />
            <Section index={3}>
              <SectionTitle>Salespeople</SectionTitle>
              <Line />
              <Fields>
                {form.values.salespersons.map((salesperson, index) => (
                  <FilterField key={index}>
                    <CheckboxField
                      {...form}
                      name={`salespersons.${index}.isSelected`}
                      label={salesperson.fullName}
                      setFieldValue={(name, value) => {
                        const allField = 'salespersons.0.isSelected';
                        form.setFieldValue(name, value);

                        // If any checkbox is not selected, then we toggle the "All" option to off.
                        if (!value) {
                          form.setFieldValue(allField, false);
                        }

                        // If we're turning on the "All" checkbox, we should check all the
                        // salesperson options.
                        if (name === allField && value) {
                          setIsSelectedForSalespersons({form, isSelected: true});
                        }
                      }}
                    />
                  </FilterField>
                ))}
              </Fields>
            </Section>
          </Content>
          <Footer>
            <Button color={colors.blue.interactive} onPress={onClose}>
              <ButtonText>Close</ButtonText>
            </Button>
          </Footer>
        </Container>
      }
      children={children}
      styles={{sidebar: {background: colors.white}}}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BookingFiltersSidebar.fragment = gql`
  ${CompanyOrganizationsDropdown.fragment}
  ${Organization.getProjectTypes.fragment}
  ${Organization.getSalesPersons.fragment}

  fragment BookingFiltersSidebar on Organization {
    id
    isPrimary
    company {
      id
      organizations {
        id
        slug
        ...Organization_getProjectTypes
        ...Organization_getSalesPersons
      }
      ...CompanyOrganizationsDropdown
    }
  }
`;

export default BookingFiltersSidebar;
