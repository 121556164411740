// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CollectionMetrics from '@shared/modules/Inventory/Summary/components/CollectionMetrics';
import SummaryItemsList from '@shared/modules/Inventory/Summary/components/SummaryItemsList';

const Container = Styled.ButtonV2`
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
  overflow: hidden;
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 16px;
  padding-vertical: 10px;
  background-color: ${colors.gray.background};
`;

const Left = Styled.View`
  flex: 1;
`;

const Name = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Description = Styled.H7`
  color: ${colors.gray.primary};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Content = Styled.View`
  padding-horizontal: 6px;
`;

const DashedLine = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.tertiary};
  border-style: dashed;
  flex: 1;
  margin-horizontal: 10px;
`;

const EmptySubtitle = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
  text-align: center;
  padding-bottom: 12px;
`;

const getItemLists = ({items, isCartonsVisible, isItemsVisible}: any) => {
  const takeItems: any = [];
  const takeCartons: any = [];
  const leaveItems: any = [];
  const leaveCartons: any = [];

  items.forEach((item: any) => {
    const isCarton = _.get(item, 'itemType.kind') === 'CARTON';
    if (item.takeCount > 0) {
      if (isCarton) {
        takeCartons.push(item);
      } else {
        takeItems.push(item);
      }
    }
    if (item.leaveCount > 0) {
      if (isCarton) {
        leaveCartons.push(item);
      } else {
        leaveItems.push(item);
      }
    }
  });

  return {
    takeItems: isItemsVisible ? takeItems : [],
    takeCartons: isCartonsVisible ? takeCartons : [],
    leaveItems: isItemsVisible ? leaveItems : [],
    leaveCartons: isCartonsVisible ? leaveCartons : [],
  };
};

type OwnInventoryRoomItemProps = {
  room: any;
  isDisabled?: boolean;
  showNotes?: boolean;
  isCartonsVisible?: boolean;
  isItemsVisible?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'InventoryRoomItemProps' circularly ref... Remove this comment to see the full error message
type InventoryRoomItemProps = OwnInventoryRoomItemProps & typeof InventoryRoomItem.defaultProps;

// @ts-expect-error TS(7022): 'InventoryRoomItem' implicitly has type 'any' beca... Remove this comment to see the full error message
const InventoryRoomItem = ({
  room,
  isDisabled,
  showNotes,
  showWeight,
  showVolume,
  isCartonsVisible,
  isItemsVisible,
}: InventoryRoomItemProps) => {
  const {takeItems, takeCartons, leaveItems, leaveCartons} = getItemLists({
    items: room.collection.filteredItems,
    isCartonsVisible,
    isItemsVisible,
  });
  const hasTakeItems = takeItems.length > 0;
  const hasTakeCartons = takeCartons.length > 0;
  const hasLeaveItems = leaveItems.length > 0;
  const hasLeaveCartons = leaveCartons.length > 0;
  const hasTakeItemsOrCartons = hasTakeItems || hasTakeCartons;
  const hasLeaveItemsOrCartons = hasLeaveItems || hasLeaveCartons;
  const hasItemsOrCartons = hasTakeItemsOrCartons || hasLeaveItemsOrCartons;

  const [isExpanded, setIsExpanded] = useState(hasItemsOrCartons || isDisabled);

  return (
    <Container
      disabled={isDisabled}
      onPress={() => {
        setIsExpanded(!isExpanded);
      }}
    >
      <Header>
        <Left>
          <Name>{room.name}</Name>
          <Description>{room.description}</Description>
        </Left>
        <Space width={10} />
        <CollectionMetrics
          collection={room.collection}
          showWeight={showWeight}
          showVolume={showVolume}
        />
        <Space width={20} />
        <Icon
          color={colors.gray.primary}
          size={14}
          source={isExpanded ? Icon.ChevronDown : Icon.ChevronUp}
        />
      </Header>
      {isExpanded && (
        <React.Fragment>
          <Line />
          <Content>
            <Space height={12} />
            <SummaryItemsList
              items={takeItems}
              listKey={`${room.id}-TAKE-ITEMS`}
              kind={'TAKE'}
              showNotes={showNotes}
              showVolume={showVolume}
            />
            {hasTakeItems && hasTakeCartons && (
              <React.Fragment>
                <Space height={3} />
                <DashedLine />
                <Space height={8} />
              </React.Fragment>
            )}
            <SummaryItemsList
              items={takeCartons}
              listKey={`${room.id}-TAKE-CARTONS`}
              kind={'TAKE'}
              showNotes={showNotes}
              showVolume={showVolume}
            />
            {hasTakeItemsOrCartons && hasLeaveItemsOrCartons && (
              <React.Fragment>
                <Space height={3} />
                <DashedLine />
                <Space height={8} />
              </React.Fragment>
            )}
            <SummaryItemsList
              items={[...leaveItems, ...leaveCartons]}
              listKey={`${room.id}-LEAVE`}
              kind={'LEAVE'}
              showNotes={showNotes}
              showVolume={showVolume}
            />
            {hasItemsOrCartons ? (
              <Space height={7} />
            ) : (
              <EmptySubtitle>No items added</EmptySubtitle>
            )}
          </Content>
        </React.Fragment>
      )}
    </Container>
  );
};
InventoryRoomItem.defaultProps = {
  isDisabled: false,
  showNotes: false,
  isCartonsVisible: true,
  isItemsVisible: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryRoomItem.fragment = gql`
  ${CollectionMetrics.fragment}
  ${SummaryItemsList.fragment}

  fragment InventoryRoomItem on Room {
    id
    uuid
    name
    description
    collection {
      id
      filteredItems {
        id
        name
        takeCount
        leaveCount
        itemType {
          id
          kind
        }
        ...SummaryItemsList
      }
      ...CollectionMetrics
    }
  }
`;

export default InventoryRoomItem;
