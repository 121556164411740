// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';
import {URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import PayrollReportKind from '@shared/modules/Accounting/enums/PayrollReportKind';
import AccountingPageHeader from 'modules/Accounting/components/AccountingPageHeader';
import ListPayrollReportsPageContent from 'modules/Accounting/components/ListPayrollReportsPageContent';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';

const Container = Styled.View`
  flex: 1;
`;

const BASE_ROUTE = `/accounting/contractor-payroll`;

const getVariablesFromParams = (params) => {
  return {
    kind: PayrollReportKind.CONTRACTOR,
    pagination: PaginationBar.DEFAULT_PAGINATION,
    ...params,
  };
};

const ListContractorReportsPage = () => {
  const {navigator, params} = useNavigationDOM();
  const variables = getVariablesFromParams(params);
  const {loading, data} = useQuery(ListContractorReportsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  // If required params are not set in the route on mount,
  // reload the page with the correct variables
  if (!params.kind || !params.pagination) {
    navigator.replace(URL.getUrlFromVariables(BASE_ROUTE, variables));
  }

  if (loading) {
    return (
      <SidebarPageV2 selected={'accounting'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <SidebarPageV2 selected={'accounting'}>
      <Container>
        <AccountingPageHeader organization={data.viewer.viewingOrganization} />
        <ListPayrollReportsPageContent
          organization={data.viewer.viewingOrganization}
          baseRoute={`/accounting/contractor-payroll`}
          payrollReportKind={PayrollReportKind.CONTRACTOR}
        />
      </Container>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListContractorReportsPage.query = gql`
  ${AccountingPageHeader.fragment}

  query ListContractorReportsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...AccountingPageHeader
      }
    }
  }
`;

export default ListContractorReportsPage;
