// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Popover, Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Sheet from '@shared/design/components/Sheet';
import SheetWithFooter from '@shared/design/components/Sheet/SheetWithFooter';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const ContentContainer = Styled.View`
  padding: 24px;

`;

const Header = Styled.Text`
  ${Typography.Heading2}
`;

const Body = Styled.Text`
  ${Typography.Body}
`;

const FooterRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const NotesInput = ({form}) => {
  return (
    <FieldInput
      {...form}
      name={'dayForm.notes'}
      input={{
        placeholder: 'Enter day notes.',
        style: {height: 120, paddingTop: 8},
        multiline: true,
      }}
      isResponsive
    />
  );
};

const getDescriptionText = ({organization}) =>
  `Visible to the main branch ${!organization?.isPrimaryMultibranch ? `and ${organization?.name}` : ''}`;

const AddDayNotesPopover = ({
  organization,
  popover,
  width,
  form,
  handleSubmit,
  submitting,
  isClosableOutside,
  offset,
  isMultibranchDayView,
  placement,
}) => {
  const responsive = useResponsive();
  return !organization?.features.isEnabledCapacityCalendarResponsiveDayView ||
    responsive.desktop ? (
    <Popover
      placement={placement}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={offset}
      isClosableOutside={isClosableOutside}
    >
      <ResponsivePopover.StaticContainer width={width}>
        <ContentContainer>
          <Header>Day Notes</Header>
          {!_.isEmpty(organization) && isMultibranchDayView && (
            <React.Fragment>
              <Space height={16} />
              <Body>{getDescriptionText({organization})}</Body>
              <Space height={8} />
            </React.Fragment>
          )}
          <Space height={8} />
          <NotesInput form={form} />
          <Space height={8} />
          <FooterRow>
            <TertiaryButton text={'Cancel'} onPress={popover.handleClose} disabled={submitting} />
            <Space width={16} />
            <Button
              iconLeft={Icon.Check}
              text={'Save'}
              onPress={handleSubmit}
              isSubmitting={submitting}
            />
          </FooterRow>
        </ContentContainer>
      </ResponsivePopover.StaticContainer>
    </Popover>
  ) : (
    <Sheet.PreventPropagationContainer>
      <SheetWithFooter
        headerText={`Edit Day Notes`}
        isOpen={popover.isOpen}
        handleClose={popover.handleClose}
        primaryActionText={'Save'}
        handlePrimaryAction={handleSubmit}
        isSubmitting={submitting}
        isFixedFooter
        shouldCloseOnClickOutside={false}
        description={getDescriptionText({organization})}
      >
        <NotesInput form={form} />
      </SheetWithFooter>
    </Sheet.PreventPropagationContainer>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
AddDayNotesPopover.propTypes = {
  offset: PropTypes.array,
  isClosableOutside: PropTypes.bool,
  isMultibranchDayView: PropTypes.bool,
  placement: PropTypes.string,
};

AddDayNotesPopover.defaultProps = {
  offset: [0, 4],
  isClosableOutside: false,
  isMultibranchDayView: false,
  placement: Popover.Positions.BottomStart,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddDayNotesPopover.fragment = gql`
  fragment AddDayNotesPopover on Organization {
    id
    isPrimaryMultibranch
    name
    features {
      isEnabledCapacityCalendarResponsiveDayView: isEnabled(
        feature: "CAPACITY_CALENDAR_RESPONSIVE_DAY_VIEW"
      )
    }
  }
`;

export default AddDayNotesPopover;
