// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteCommercialMaterialTypeMutation from '@shared/modules/CommercialCatalog/hooks/useDeleteCommercialMaterialTypeMutation';

const DeleteCommercialMaterialTypeModal = ({
  isOpen,
  commercialMaterialType,
  handleClose,
  refetch,
}) => {
  const {submitting, handleSubmit} = useDeleteCommercialMaterialTypeMutation({
    commercialMaterialTypeId: commercialMaterialType.id,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete ${commercialMaterialType.name}?`}
      subtitle={`Are you sure you would like to delete ${commercialMaterialType.name}?`}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteCommercialMaterialTypeModal.fragment = gql`
  fragment DeleteCommercialMaterialTypeModal on CommercialMaterialType {
    id
    name
  }
`;

export default DeleteCommercialMaterialTypeModal;
