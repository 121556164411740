// Libraries
import React from 'react';

// Supermove
import {Popover, Space} from '@supermove/components';

// App
import ActionMenu from '@shared/design/components/ActionMenu';

const SectionHeader = ({label, isFirst}) => {
  return (
    <React.Fragment>
      {!isFirst && (
        <React.Fragment>
          <Space height={12} />
          <ActionMenu.Divider />
          <Space height={12} />
        </React.Fragment>
      )}
      <ActionMenu.Label>{label}</ActionMenu.Label>
    </React.Fragment>
  );
};

const ActionMenuItem = ({handleClose, onPress, label, isDisabled, tooltip}) => {
  return (
    <ActionMenu.Item
      style={{paddingLeft: 24}}
      handleClose={handleClose}
      onPress={onPress}
      isDisabled={isDisabled}
      tooltip={tooltip}
    >
      {label}
    </ActionMenu.Item>
  );
};

const ProjectActionsPopover = ({projectActionPopover, projectActionSections}) => {
  return (
    <Popover
      key={projectActionPopover.key}
      placement={Popover.Positions.BottomStart}
      isOpen={projectActionPopover.isOpen}
      handleOpen={projectActionPopover.handleOpen}
      handleClose={projectActionPopover.handleClose}
      reference={projectActionPopover.ref}
      offset={[0, 4]}
    >
      <ActionMenu.Container width={400}>
        <Space height={12} />
        {projectActionSections.map(({label, actions}, index) => (
          <React.Fragment key={index}>
            <SectionHeader label={label} isFirst={index === 0} />
            {actions.map(
              ({label, onPress, handleClose, isDisabled, disabledExplanation}, actionIndex) => (
                <ActionMenuItem
                  key={actionIndex}
                  label={label}
                  onPress={onPress}
                  handleClose={handleClose}
                  isDisabled={isDisabled}
                  tooltip={isDisabled ? disabledExplanation : null}
                />
              ),
            )}
          </React.Fragment>
        ))}
        <Space height={12} />
      </ActionMenu.Container>
    </Popover>
  );
};

export default ProjectActionsPopover;
