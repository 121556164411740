// DEPRECATED(dan) in favor of BillingProjectAddBillDrawer

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Modal, Space, Styled, DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import useCreateBillV3Mutation from '@shared/modules/Billing/hooks/useCreateBillV3Mutation';
import Field from 'modules/App/components/Field';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: ${(props) => (props.mobile ? 320 : 400)}px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 24px;
`;

const TitleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const InstructionText = Styled.H6`
  color: ${colors.black};
  ${fontWeight(500)}
`;

const FieldsContainer = Styled.View`
`;

const InputLabelText = Styled.H7`
  color: ${colors.gray.tertiary};
  ${fontWeight(700)}
`;

const InputLabelCaption = Styled.H7`
  color: ${colors.gray.tertiary};
  ${fontWeight(500)}
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const ActivityIndicator = Styled.Loading`
`;

const AddBillHeader = ({organization}) => {
  return (
    <React.Fragment>
      <TitleText>Add Bill</TitleText>
      <Space height={16} />
      <InstructionText>
        {organization.features.isEnabledIncludeEmptyBills
          ? `Please enter a title and description for this bill.`
          : `Please enter a title and description for this bill. ` +
            `This bill will be linked to the project as a whole ` +
            `and not linked to any specific job.`}
      </InstructionText>
      <Space height={20} />
      <InputLabelText>Title</InputLabelText>
    </React.Fragment>
  );
};

const AddBillFields = ({form, project}) => {
  return (
    <FieldsContainer>
      <Field
        {...form}
        name={`billForm.title`}
        input={{
          placeholder: 'Enter a title for this bill',
          required: !form.values.billForm.title,
        }}
      />
      <Space height={12} />
      <InputLabelText>
        {`Description `}
        <InputLabelCaption>(Optional)</InputLabelCaption>
      </InputLabelText>
      <Space height={4} />
      <Field
        {...form}
        name={`billForm.description`}
        input={{placeholder: 'Enter a description for this bill'}}
      />
      {project.organization.features.isEnabledIncludeEmptyBills && (
        <React.Fragment>
          <Space height={12} />
          <FieldInput
            {...form}
            name={`billForm.jobId`}
            component={DropdownInput}
            label={'Project or Job'}
            input={{
              isSearchable: true,
              placeholder: `Select a job to add to`,
              isPortaled: true,
              options: [
                {
                  label: `Project-${project.identifier}`,
                  value: null,
                },
                ..._.map(project.activeMoveJobs, (job) => ({
                  label: Job.getFullName(job),
                  value: _.toNumber(job.id),
                })),
              ],
              setFieldValue: form.setFieldValue,
              style: {flex: 1},
            }}
          />
        </React.Fragment>
      )}
    </FieldsContainer>
  );
};

const AddBillFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <Actions>
      <Button color={colors.white} onPress={handleClose} disabled={submitting}>
        <ButtonText color={colors.gray.secondary}>Cancel</ButtonText>
      </Button>
      <Space width={8} />
      <Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        onPress={handleSubmit}
        disabled={submitting}
      >
        {submitting ? (
          <ActivityIndicator color={colors.white} size={'small'} />
        ) : (
          <ButtonText color={colors.white}>Confirm</ButtonText>
        )}
      </Button>
    </Actions>
  );
};

const BillingProjectAddBillModal = ({handleClose, isOpen, refetch, project}) => {
  const billForm = BillForm.new(project);
  const {form, submitting, handleSubmit} = useCreateBillV3Mutation({
    billForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error) => {
      console.log({error});
    },
  });

  const responsive = useResponsive();

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ModalContainer {...responsive}>
          <AddBillHeader organization={project.organization} />
          <Space height={4} />
          <AddBillFields form={form} project={project} />
          <Space height={20} />
          <AddBillFooter
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            submitting={submitting}
          />
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectAddBillModal.fragment = gql`
  ${BillForm.new.fragment}
  ${Job.getFullName.fragment}

  fragment BillingProjectAddBillModal on Project {
    id
    identifier
    activeMoveJobs {
      id
      isCancelled
      day {
        id
        value
      }
      ...Job_getFullName
    }
    organization {
      id
      features {
        isEnabledIncludeEmptyBills: isEnabled(feature: "INCLUDE_EMPTY_JOB_BILLS")
      }
    }
    ...BillForm_new
  }
`;

export default BillingProjectAddBillModal;
