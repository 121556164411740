// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import TagForm from '@shared/modules/Tag/forms/TagForm';
import useCreateTagMutation from '@shared/modules/Tag/hooks/useCreateTagMutation';
import TagDrawerFields from 'modules/Tag/components/TagDrawerFields';

const CreateTagDrawer = ({handleClose, isOpen, onSuccess, organizationId}) => {
  const tagForm = TagForm.new({
    organizationId,
  });
  const {form, submitting, handleSubmit} = useCreateTagMutation({
    tagForm,
    onSuccess: ({tag}) => {
      onSuccess(tag);
    },
    onError: (error) => console.log(error),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Create Tag'}
      primaryActionText={'Save'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <TagDrawerFields form={form} />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateTagDrawer.fragment = gql`
  fragment CreateTagDrawer on Organization {
    id
  }
`;

export default CreateTagDrawer;
