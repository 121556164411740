// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const AssignUserToJobMutation = ({job, user, onSuccess, onError, children}) => {
  return (
    <Mutation
      variables={{
        jobId: job ? job.id : null,
        userId: user ? user.id : null,
      }}
      mutation={AssignUserToJobMutation.mutation}
      onCompleted={({response: {jobUser, errors}}) => {
        if (!errors) {
          onSuccess(jobUser);
        } else if (onError) {
          onError(errors);
        }
      }}
      refetchQueries={['EditJobUsersForm']}
      awaitRefetchQueries
    >
      {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
    </Mutation>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignUserToJobMutation.mutation = gql`
  mutation AssignUserToJobMutation(
    $jobId: Int!,
    $userId: Int!,
  ) {
    response: assignUserToJob(
      jobId: $jobId,
      userId: $userId,
    ) {
      ${gql.errors}
      jobUser {
        id
        jobId
        userId
        status
      }
    }
  }
`;

export default AssignUserToJobMutation;
