// Libraries
import React from 'react';

// App
import DataTableForm from '@shared/modules/Billing/forms/DataTableForm';
import useUploadDataTableMutation from '@shared/modules/Billing/hooks/useUploadDataTableMutation';
import DataTableModalContent from 'modules/Organization/Settings/Billing/Tables/components/DataTableModalContent';

const UploadDataTableModal = ({isOpen, handleClose, organizationId, userId, refetch}) => {
  const dataTableForm = DataTableForm.new({organizationId, userId});
  const {form, handleSubmit, submitting} = useUploadDataTableMutation({
    dataTableForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DataTableModalContent
      titleText='Upload Billing Table'
      form={form}
      handleSubmit={handleSubmit}
      submitting={submitting}
      isOpen={isOpen}
      handleClose={handleClose}
      showTableKindDropdown
    />
  );
};

export default UploadDataTableModal;
