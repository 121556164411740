// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {User} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Duration} from '@supermove/utils';

// App
import UpdateMoveUserModal from './UpdateMoveUserModal';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-vertical: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Left = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Right = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Times = Styled.View`
  align-items: flex-end;
`;

const EditButton = Styled.Button`
  width: 60px;
  height: 40px;
  margin-left: 20px;
`;

const EditText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
`;

const Info = Styled.View`
  margin-left: 10px;
`;

const Name = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Organization = Styled.H7`
  ${fontWeight(300)}
  color: ${colors.gray.primary};
`;

const TimePayable = Styled.H6`
`;

const Adjustment = Styled.H7`
  color: ${colors.gray.secondary};
`;

const getTimePayableDisplay = ({form, index, timePayable}) => {
  const adjustment = _.get(form.values, `moveUserInputs.${index}.timePayableAdjustment`, 0);
  return Duration.toDisplayTime(timePayable + adjustment);
};

const getAdjustmentDisplay = ({form, index}) => {
  const adjustment = _.get(form.values, `moveUserInputs.${index}.timePayableAdjustment`, 0);
  if (adjustment === 0) {
    return '';
  } else {
    return `(${Duration.toDisplayTime(adjustment)})`;
  }
};

const UpdateReportMoveMoveUserItem = ({isFirst, index, timePayable, form}) => (
  <Container>
    {isFirst && <Line />}
    <Content>
      <Left>
        <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.User} />
        <Info>
          <Name>{User.getFullName(_.get(form.values, `moveUserInputs.${index}.user`, {}))}</Name>
          <Organization>
            {_.get(form.values, `moveUserInputs.${index}.user.organization.name`, '')}
          </Organization>
        </Info>
      </Left>
      <Right>
        <Times>
          <TimePayable>
            {getTimePayableDisplay({
              form,
              index,
              timePayable,
            })}
          </TimePayable>
          <Adjustment>{getAdjustmentDisplay({form, index})}</Adjustment>
        </Times>
        <UpdateMoveUserModal
          index={index}
          timePayable={timePayable}
          parentForm={form}
          trigger={({handleOpen}) => (
            <EditButton color={colors.blue.accent} onPress={handleOpen}>
              <EditText>Edit</EditText>
            </EditButton>
          )}
        />
      </Right>
    </Content>
    <Line />
  </Container>
);

export default UpdateReportMoveMoveUserItem;
