import {AttachmentKindModel} from '@supermove/models';
import {uuid} from '@supermove/utils';

export interface CreateAttachmentFormType {
  fileId?: string | null;
  jobId?: string;
  projectId?: string;
  roomId?: string;
  inventoryId?: string;
  attachmentCategoryKinds?: AttachmentKindModel[];
  description?: string;
  isVisibleToCrew?: boolean;

  // private
  uploading?: boolean;
  _id?: string; // Used to identify and index while it's uploading
}

const _new = ({
  projectId,
  jobId,
  roomId,
  inventoryId,
  attachmentCategoryKinds,
  description,
  isVisibleToCrew,
  _id = uuid(),
}: CreateAttachmentFormType) => ({
  fileId: null,
  jobId,
  projectId,
  roomId,
  inventoryId,
  attachmentCategoryKinds,
  description,
  isVisibleToCrew: isVisibleToCrew || false,
  _id,
});

const toForm = ({
  fileId,
  jobId,
  projectId,
  roomId,
  inventoryId,
  attachmentCategoryKinds,
  description,
  isVisibleToCrew,
  _id,
}: CreateAttachmentFormType) => ({
  fileId,
  jobId,
  projectId,
  roomId,
  inventoryId,
  attachmentCategoryKinds,
  description,
  isVisibleToCrew,
  _id,
});

const toMutation = ({
  fileId,
  jobId,
  projectId,
  roomId,
  inventoryId,
  attachmentCategoryKinds,
  description,
  isVisibleToCrew,
}: CreateAttachmentFormType) => ({
  fileId,
  jobId,
  projectId,
  roomId,
  inventoryId,
  attachmentCategoryKinds,
  description,
  isVisibleToCrew,
});

const CreateAttachmentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CreateAttachmentForm;
