// Supermove
import {Icon} from '@supermove/components';

// Project Actions
const ASSIGN_TO_BRANCH = 'ASSIGN_TO_BRANCH';
const GET_PROJECT_DATA = 'GET_PROJECT_DATA';
const INVOKE_WEBHOOK = 'INVOKE_WEBHOOK';

// Job Actions
const ASSIGN_TO_BRANCH_JOB = 'ASSIGN_TO_BRANCH_JOB';
const GET_JOB_DATA = 'GET_JOB_DATA';
const ASSIGN_OFFICE_STAFF = 'ASSIGN_OFFICE_STAFF';

// Task Actions
const CREATE_TASK = 'CREATE_TASK';
const COMPLETE_TASK = 'COMPLETE_TASK';
const ARCHIVE_TASK = 'ARCHIVE_TASK';
const UPDATE_TASK = 'UPDATE_TASK';
const FIND_TASK_FROM_PREVIOUS_STEP = 'FIND_TASK_FROM_PREVIOUS_STEP';

// Tag Actions
const PROJECT_ADD_TAG = 'PROJECT_ADD_TAG';
const JOB_ADD_TAG = 'JOB_ADD_TAG';
const PROJECT_REMOVE_TAG = 'PROJECT_REMOVE_TAG';
const JOB_REMOVE_TAG = 'JOB_REMOVE_TAG';

// Email Actions
const SEND_EMAIL = 'SEND_EMAIL';
const SEND_QUOTE = 'SEND_QUOTE';
const SEND_CONFIRMATION = 'SEND_CONFIRMATION';

// Control Actions
const DELAY = 'DELAY';
const GET_DATA_AND_STOP_IF = 'GET_DATA_AND_STOP_IF';

// SMS Actions
const SEND_SMS = 'SEND_SMS';
const SEND_SMS_V2 = 'SEND_SMS_V2';
const SEND_VIRTUAL_WALKTHROUGH = 'SEND_VIRTUAL_WALKTHROUGH';

// DEPRECATED
const STOP_IF = 'STOP_IF';

const VALUES = [
  DELAY,
  CREATE_TASK,
  COMPLETE_TASK,
  ARCHIVE_TASK,
  UPDATE_TASK,
  SEND_EMAIL,
  SEND_QUOTE,
  SEND_CONFIRMATION,
  ASSIGN_OFFICE_STAFF,
  SEND_SMS,
  SEND_SMS_V2,
  SEND_VIRTUAL_WALKTHROUGH,
  GET_PROJECT_DATA,
  GET_JOB_DATA,
  FIND_TASK_FROM_PREVIOUS_STEP,
  ASSIGN_TO_BRANCH,
  ASSIGN_TO_BRANCH_JOB,
  INVOKE_WEBHOOK,
  PROJECT_ADD_TAG,
  JOB_ADD_TAG,
  PROJECT_REMOVE_TAG,
  JOB_REMOVE_TAG,
  GET_DATA_AND_STOP_IF,

  // DEPRECATED
  STOP_IF,
];

// Kind Groups
const CATEGORIES = {
  TASK: 'TASK',
  TAGS: 'TAGS',
  PROJECT: 'PROJECT',
  JOB: 'JOB',
  CONTROL: 'CONTROL',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
};

const KIND_TO_GROUP = {
  [CREATE_TASK]: CATEGORIES.TASK,
  [UPDATE_TASK]: CATEGORIES.TASK,
  [COMPLETE_TASK]: CATEGORIES.TASK,
  [ARCHIVE_TASK]: CATEGORIES.TASK,
  [FIND_TASK_FROM_PREVIOUS_STEP]: CATEGORIES.TASK,

  [GET_PROJECT_DATA]: CATEGORIES.PROJECT,

  [ASSIGN_TO_BRANCH_JOB]: CATEGORIES.JOB,
  [GET_JOB_DATA]: CATEGORIES.JOB,

  [GET_DATA_AND_STOP_IF]: CATEGORIES.CONTROL,

  // DEPRECATED
  [STOP_IF]: CATEGORIES.CONTROL,
};

const GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES = {
  [CATEGORIES.TASK]: {
    label: 'Task Automation Step',
    placeholder: 'Select a automation step for a task',
    optionFilter: (workflowStepActionKind: any) =>
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      KIND_TO_GROUP[workflowStepActionKind] === CATEGORIES.TASK,
  },
  [CATEGORIES.CONTROL]: {
    label: 'Automation Step',
    placeholder: 'Select a automation step to check against',
    optionFilter: (workflowStepActionKind: any) =>
      [CATEGORIES.TASK, CATEGORIES.PROJECT, CATEGORIES.JOB].includes(
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        KIND_TO_GROUP[workflowStepActionKind],
      ),
  },
};

const ATTRIBUTES = {
  [ASSIGN_TO_BRANCH]: {
    value: ASSIGN_TO_BRANCH,
    label: 'Assign to Branch',
    icon: Icon.BuildingCircleArrowRight,
    description: 'Assign this project to a branch.',
    actionForm: 'assignToBranchActionForm',
    graphqlOperationName: 'assignProjectToBranch',
    kind: CATEGORIES.PROJECT,
  },
  [ASSIGN_TO_BRANCH_JOB]: {
    value: ASSIGN_TO_BRANCH_JOB,
    label: 'Assign Job to Branch',
    icon: Icon.BuildingCircleArrowRight,
    description: 'Assign this job to a branch.',
    actionForm: 'assignToBranchActionForm',
    graphqlOperationName: 'assignJobToBranch',
    kind: CATEGORIES.JOB,
  },
  [ASSIGN_OFFICE_STAFF]: {
    value: ASSIGN_OFFICE_STAFF,
    label: 'Assign Office Staff',
    icon: Icon.User,
    description: 'Assign this project to office staff.',
    actionForm: 'assignOfficeStaffActionForm',
    graphqlOperationName: 'assignOfficeUsersToProjectWorkflow',
    kind: CATEGORIES.PROJECT,
  },
  [CREATE_TASK]: {
    value: CREATE_TASK,
    label: 'Create Task',
    icon: Icon.FilePlus,
    description: 'Create a task using a task template.',
    actionForm: 'createTaskFromTemplateForm',
    graphqlOperationName: 'createTaskFromTemplate',
    kind: CATEGORIES.TASK,
  },
  [COMPLETE_TASK]: {
    value: COMPLETE_TASK,
    label: 'Complete Task',
    icon: Icon.FileCheck,
    description: 'Complete a task from this automation.',
    actionForm: 'toggleTaskPropertyActionForm',
    actionFormField: 'taskIdReferencePath',
    workflowStepDropdown: GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES[KIND_TO_GROUP[COMPLETE_TASK]],
    graphqlOperationName: 'updateTaskIsCompleted',
    kind: CATEGORIES.TASK,
  },
  [ARCHIVE_TASK]: {
    value: ARCHIVE_TASK,
    label: 'Archive Task',
    icon: Icon.BoxArchive,
    description: 'Archive a task from this automation.',
    actionForm: 'toggleTaskPropertyActionForm',
    actionFormField: 'taskIdReferencePath',
    workflowStepDropdown: GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES[KIND_TO_GROUP[ARCHIVE_TASK]],
    graphqlOperationName: 'updateTaskIsArchived',
    kind: CATEGORIES.TASK,
  },
  [UPDATE_TASK]: {
    value: UPDATE_TASK,
    label: 'Update Task',
    icon: Icon.Rotate,
    description: 'Update a task from this automation.',
    actionForm: 'updateTaskActionForm',
    actionFormField: 'taskIdReferencePath',
    workflowStepDropdown: GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES[KIND_TO_GROUP[UPDATE_TASK]],
    graphqlOperationName: 'updateOneOrManyTaskProperties',
    kind: CATEGORIES.TASK,
  },
  [FIND_TASK_FROM_PREVIOUS_STEP]: {
    value: FIND_TASK_FROM_PREVIOUS_STEP,
    label: 'Get Task Data',
    icon: Icon.Search,
    actionForm: 'findTaskFromPreviousStepActionForm',
    actionFormField: 'taskIdReferencePath',
    description: 'Get task data from a previous step. The output can be used in future steps.',
    graphqlOperationName: 'taskById',
    workflowStepDropdown:
      GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES[KIND_TO_GROUP[FIND_TASK_FROM_PREVIOUS_STEP]],
    kind: CATEGORIES.TASK,
  },
  [SEND_EMAIL]: {
    value: SEND_EMAIL,
    label: 'Send Email',
    icon: Icon.Envelope,
    actionForm: 'sendEmailFromTemplateActionForm',
    description:
      'Send an automated email using email templates. You will not see a record of this email unless your email address is included.',
    kind: CATEGORIES.EMAIL,
  },
  [SEND_QUOTE]: {
    value: SEND_QUOTE,
    label: 'Send Quote',
    icon: Icon.Envelope,
    actionForm: 'createAndSendConfirmationForProjectActionForm',
    description: 'Send a quote',
    kind: CATEGORIES.EMAIL,
  },
  [SEND_CONFIRMATION]: {
    value: SEND_CONFIRMATION,
    label: 'Send Confirmation',
    icon: Icon.Envelope,
    actionForm: 'createAndSendConfirmationForProjectActionForm',
    description: 'Send a confirmation',
    kind: CATEGORIES.EMAIL,
  },
  [DELAY]: {
    value: DELAY,
    label: 'Delay',
    icon: Icon.Clock,
    actionForm: 'delayWorkflowActionForm',
    kind: CATEGORIES.CONTROL,
  },
  [STOP_IF]: {
    value: STOP_IF,
    label: 'Stop If',
    icon: Icon.StopCircle,
    actionForm: 'stopIfActionForm',
    actionFormField: 'stepReferencePath',
    workflowStepDropdown: GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES[KIND_TO_GROUP[STOP_IF]],
    kind: CATEGORIES.CONTROL,
  },
  [GET_DATA_AND_STOP_IF]: {
    value: GET_DATA_AND_STOP_IF,
    label: 'Stop If',
    icon: Icon.StopCircle,
    actionForm: 'getDataAndStopIfActionForm',
    actionFormField: 'stepReferencePath',
    workflowStepDropdown:
      GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES[KIND_TO_GROUP[GET_DATA_AND_STOP_IF]],
    kind: CATEGORIES.CONTROL,
  },
  [GET_PROJECT_DATA]: {
    value: GET_PROJECT_DATA,
    label: 'Get project data',
    icon: Icon.Search,
    actionForm: 'getProjectDataActionForm',
    graphqlOperationName: 'projectById',
    description:
      'Get project data from the project this automation was triggered on. The output can be used in future steps.',
    kind: CATEGORIES.PROJECT,
  },
  [GET_JOB_DATA]: {
    value: GET_JOB_DATA,
    label: 'Get job data',
    icon: Icon.Search,
    actionForm: 'getJobDataActionForm',
    graphqlOperationName: 'jobById',
    description:
      'Get job data from the job this automation was triggered on. The output can be used in future steps.',
    kind: CATEGORIES.JOB,
  },
  [SEND_SMS]: {
    value: SEND_SMS,
    label: 'Send SMS to Customer',
    icon: Icon.CommentSms,
    actionForm: 'sendSmsFromTemplateActionForm',
    description: 'Send an automated SMS using SMS templates.',
    kind: CATEGORIES.SMS,
  },
  [SEND_SMS_V2]: {
    value: SEND_SMS_V2,
    label: 'Send SMS',
    icon: Icon.CommentSms,
    actionForm: 'sendSmsV2FromTemplateActionForm',
    description: 'Send an automated SMS using SMS templates.',
    kind: CATEGORIES.SMS,
  },
  [SEND_VIRTUAL_WALKTHROUGH]: {
    value: SEND_VIRTUAL_WALKTHROUGH,
    label: 'Send Virtual Walkthrough SMS',
    icon: Icon.CommentSms,
    actionForm: 'sendSmsV2FromTemplateActionForm',
    description: 'Update survey method and send an automated virtual walkthrough SMS.',
    kind: CATEGORIES.SMS,
  },
  [INVOKE_WEBHOOK]: {
    value: INVOKE_WEBHOOK,
    label: 'Invoke Webhook',
    icon: Icon.Bell,
    actionForm: 'invokeWebhookActionForm',
    description: 'Invoke a webhook.',
    kind: CATEGORIES.PROJECT,
  },
  [PROJECT_ADD_TAG]: {
    value: PROJECT_ADD_TAG,
    label: 'Add Project Tag(s)',
    icon: Icon.Tag,
    actionForm: 'updateTagAssignmentActionForm',
    description: 'Add a project tag.',
    kind: CATEGORIES.PROJECT,
  },
  [JOB_ADD_TAG]: {
    value: JOB_ADD_TAG,
    label: 'Add Job Tag(s)',
    icon: Icon.Tag,
    actionForm: 'updateTagAssignmentActionForm',
    description: 'Add a job tag.',
    kind: CATEGORIES.JOB,
  },
  [PROJECT_REMOVE_TAG]: {
    value: PROJECT_REMOVE_TAG,
    label: 'Remove Project Tag(s)',
    icon: Icon.Tag,
    actionForm: 'removeTagAssignmentActionForm',
    description: 'Remove a project tag.',
    kind: CATEGORIES.PROJECT,
  },
  [JOB_REMOVE_TAG]: {
    value: JOB_REMOVE_TAG,
    label: 'Remove Job Tag(s)',
    icon: Icon.Tag,
    actionForm: 'updateTagAssignmentActionForm',
    description: 'Remove a job tag.',
    kind: CATEGORIES.JOB,
  },
};

const getWorkflowStepActionKindDisplayContent = ({workflowStepKind}: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return ATTRIBUTES[workflowStepKind];
};

const WorkflowStepActionKind = {
  // Enum Options
  ASSIGN_TO_BRANCH,
  ASSIGN_TO_BRANCH_JOB,
  ASSIGN_OFFICE_STAFF,
  CREATE_TASK,
  COMPLETE_TASK,
  ARCHIVE_TASK,
  UPDATE_TASK,
  DELAY,
  GET_DATA_AND_STOP_IF,
  STOP_IF,
  SEND_EMAIL,
  SEND_QUOTE,
  SEND_CONFIRMATION,
  FIND_TASK_FROM_PREVIOUS_STEP,
  GET_PROJECT_DATA,
  GET_JOB_DATA,
  SEND_SMS,
  SEND_SMS_V2,
  SEND_VIRTUAL_WALKTHROUGH,
  INVOKE_WEBHOOK,
  PROJECT_ADD_TAG,
  JOB_ADD_TAG,
  PROJECT_REMOVE_TAG,
  JOB_REMOVE_TAG,

  // Enum Kind Groups
  CATEGORIES,

  VALUES,
  ATTRIBUTES,

  getWorkflowStepActionKindDisplayContent,
};

export default WorkflowStepActionKind;
