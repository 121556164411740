// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {ProjectTypeModel} from '@supermove/models';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import Line from 'modules/App/components/Line';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import ProjectTypeSettingsPageNavigationTabs from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageNavigationTabs';

const PageContainer = Styled.View`
`;

const ContentContainer = Styled.View`
  padding-left: 24px;
`;

const getBreadcrumbs = ({
  navigator,
  isStorageProjectType,
  projectType,
}: {
  navigator: any;
  isStorageProjectType: boolean;
  projectType: ProjectTypeModel;
}) => {
  return [
    {name: 'Settings', onPress: () => navigator.push('/settings')},
    ...(isStorageProjectType
      ? [
          {name: 'Storage', onPress: () => navigator.push('/settings/storage')},
          {name: 'Project Types', onPress: () => navigator.push('/settings/storage/project-types')},
        ]
      : [
          {
            name: 'Project Types',
            onPress: () => navigator.push('/settings/projects/project-types'),
          },
        ]),
    {name: projectType.name},
  ];
};

const ProjectTypeSettingsPageBreadcrumb = ({projectType}: {projectType: ProjectTypeModel}) => {
  const {navigator} = useNavigationDOM();
  const isStorageProjectType = projectType.category === ProjectTypeCategory.STORAGE;
  return (
    <StandardOfficeHeader title={''} showLine={false}>
      <Breadcrumbs breadcrumbs={getBreadcrumbs({navigator, isStorageProjectType, projectType})} />
    </StandardOfficeHeader>
  );
};

interface ProjectTypeSettingsPageHeaderProps {
  projectType: ProjectTypeModel;
  selectedTab?: string;
}

const ProjectTypeSettingsPageHeader = ({
  projectType,
  selectedTab,
}: ProjectTypeSettingsPageHeaderProps) => {
  return (
    <PageContainer>
      <ProjectTypeSettingsPageBreadcrumb projectType={projectType} />
      <Space height={12} />
      <ContentContainer>
        <ProjectTypeSettingsPageNavigationTabs
          projectType={projectType}
          // @ts-expect-error remove when this is typed properly, selectedTab is a string type
          selectedTab={selectedTab}
        />
      </ContentContainer>
      <Line />
    </PageContainer>
  );
};

ProjectTypeSettingsPageHeader.fragment = gql`
  ${ProjectTypeSettingsPageNavigationTabs.fragment}

  fragment ProjectTypeSettingsPageHeader on ProjectType {
    id
    name
    category
    ...ProjectTypeSettingsPageNavigationTabs
  }
`;

export default ProjectTypeSettingsPageHeader;
