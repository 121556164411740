// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import Button from '@shared/design/components/Button';
import Table from '@shared/design/components/TableV2Deprecated';
import CreateSlotTypeModal from 'modules/Organization/Settings/Dispatch/Slots/components/CreateSlotTypeModal';
import DeleteSlotTypeModal from 'modules/Organization/Settings/Dispatch/Slots/components/DeleteSlotTypeModal';
import UpdateSlotTypeModal from 'modules/Organization/Settings/Dispatch/Slots/components/UpdateSlotTypeModal';

const Section = Styled.View`
  width: 100%;
`;

const SectionTitle = Styled.Text`
  ${Typography.Label1}
`;

const HeaderText = Styled.Text`
  ${Typography.Label3}
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const EditSlotButton = Styled.ButtonV2`
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const CreateSlotTypeButton = ({refetch}) => {
  const createSlotTypeModal = useModal();
  return (
    <React.Fragment>
      <Button iconLeft={Icon.Plus} text={'Add Slot'} onPress={createSlotTypeModal.handleOpen} />
      <CreateSlotTypeModal
        key={createSlotTypeModal.isOpen}
        isOpen={createSlotTypeModal.isOpen}
        handleClose={createSlotTypeModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const SlotActions = ({slot, refetch}) => {
  const slotActionsPopover = usePopover();
  const deleteSlotTypeModal = useModal({name: 'Delete Slot Type Modal'});
  const updateSlotTypeModal = useModal({name: 'Update Slot Type Modal'});

  return (
    <React.Fragment>
      <EditSlotButton onPress={updateSlotTypeModal.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </EditSlotButton>
      <Space width={6} />
      <Popover.Content innerRef={slotActionsPopover.ref}>
        <MoreActionsButton onPress={slotActionsPopover.handleOpen}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </MoreActionsButton>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={slotActionsPopover.isOpen}
        handleOpen={slotActionsPopover.handleOpen}
        handleClose={slotActionsPopover.handleClose}
        reference={slotActionsPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          width={148}
          actions={[
            {
              text: 'Delete',
              onPress: deleteSlotTypeModal.handleOpen,
            },
          ]}
          handleClose={slotActionsPopover.handleClose}
        />
      </Popover>
      <UpdateSlotTypeModal
        isOpen={updateSlotTypeModal.isOpen}
        slotTypeId={slot.id}
        handleClose={updateSlotTypeModal.handleClose}
        refetch={refetch}
      />
      <DeleteSlotTypeModal
        isOpen={deleteSlotTypeModal.isOpen}
        slotType={slot}
        handleClose={deleteSlotTypeModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const getSlotTableColumnDefinitions = ({refetch, showActions}) => [
  {
    width: 200,
    headerContent: () => {
      return <HeaderText>Slot Number</HeaderText>;
    },
    cellContent: ({item: slot}) => {
      return <Text numberOfLines={1}>{`Slot ${slot.index}`}</Text>;
    },
  },
  {
    width: 200,
    headerContent: () => {
      return <HeaderText>Truck</HeaderText>;
    },
    cellContent: ({item: slot}) => {
      if (slot.truck) {
        return <Text numberOfLines={1}>{slot.truck.name}</Text>;
      }
      return (
        <Text style={{color: colors.gray.tertiary}} numberOfLines={1}>
          Not Selected
        </Text>
      );
    },
  },
  {
    width: 200,
    headerContent: () => {
      return <HeaderText>Driver</HeaderText>;
    },
    cellContent: ({item: slot}) => {
      if (slot.driver) {
        return <Text numberOfLines={1}>{slot.driver.fullName}</Text>;
      }
      return (
        <Text style={{color: colors.gray.tertiary}} numberOfLines={1}>
          Not Selected
        </Text>
      );
    },
  },
  {
    width: 100,
    isHidden: !showActions,
    headerContent: () => {
      return <HeaderText>Actions</HeaderText>;
    },
    cellContent: ({item: slot}) => {
      return <SlotActions slot={slot} refetch={refetch} />;
    },
  },
];

const SlotTypesTable = ({organization, refetch, showActions}) => {
  return (
    <ScrollView horizontal>
      <Table
        columnDefinitions={getSlotTableColumnDefinitions({
          refetch,
          showActions,
        })}
        emptyStateText={'No slots to display'}
        items={organization.slotTypes}
        isDense
        itemKey={'id'}
      />
    </ScrollView>
  );
};

const OrganizationSlotTypesTable = ({organization, refetch, showActions}) => {
  const responsive = useResponsive();
  return (
    <Section responsive={responsive}>
      <SectionTitle>{`${organization.name} Slots (${organization.slotTypes.length})`}</SectionTitle>
      <Space height={16} />
      <SlotTypesTable organization={organization} refetch={refetch} showActions={showActions} />
      <Space height={48} />
    </Section>
  );
};

const SlotsContent = ({viewer, refetch}) => {
  const {nonPrimaryOrganizations} = viewer.viewingOrganization.company;
  return (
    <React.Fragment>
      <CreateSlotTypeButton refetch={refetch} />
      <Space height={24} />
      <OrganizationSlotTypesTable
        organization={viewer.viewingOrganization}
        refetch={refetch}
        showActions
      />
      {viewer.viewingOrganization.isPrimary &&
        nonPrimaryOrganizations.map((nonPrimaryOrganization) => {
          return (
            <OrganizationSlotTypesTable
              key={nonPrimaryOrganization.id}
              organization={nonPrimaryOrganization}
              refetch={refetch}
            />
          );
        })}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SlotsContent.fragment = gql`
  ${DeleteSlotTypeModal.fragment}
  fragment SlotsContent on User {
    ${gql.query}
    id
    viewingOrganization {
        id
        name
        isPrimary
        company {
          id
          nonPrimaryOrganizations {
            id
            name
            slotTypes {
              id
              index
              driver {
                id
                fullName
              }
              truck {
                id
                name
              }
            }
            activeTrucks {
              id
            }
          }
        }
        slotTypes {
          id
          index
          driver {
            id
            fullName
          }
          truck {
            id
            name
          }
          ...DeleteSlotTypeModal
        }
      }
  }
`;

export default SlotsContent;
