// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({userId, fullName}: any) => ({
  teamMemberId: null,
  userId,

  // Private
  fullName,
  isActive: true,
});

const _delete = ({teamMemberId}: any) => ({
  ..._new({}),
  teamMemberId,
});

const edit = withFragment(
  (teamMember) => ({
    teamMemberId: (teamMember as any).id,
    userId: (teamMember as any).user.id,
    // Private
    fullName: (teamMember as any).user.fullName,
    isActive: (teamMember as any).user.isActive,
  }),
  gql`
    fragment TeamMemberForm_edit on TeamMember {
      id
      user {
        id
        fullName
        isActive
      }
    }
  `,
);

const toForm = ({teamMemberId, userId, fullName, isActive}: any) => ({
  teamMemberId: _.toString(teamMemberId),
  userId: _.toString(userId),

  // Private
  fullName,
  isActive,
});

const toMutation = ({teamMemberId, userId}: any) => {
  return {
    teamMemberId: _.toNumber(teamMemberId),
    userId: _.toNumber(userId),
  };
};

const TaskForm = {
  new: _new,
  delete: _delete,
  edit,
  toForm,
  toMutation,
};

export default TaskForm;
