// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {
  BillingLibrary,
  BillingLibraryModel,
  BillRule,
  BillRuleType,
  Formula,
  FormulaModel,
} from '@supermove/models';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import BillRuleKind from '@shared/modules/Billing/enums/BillRuleKind';
import BillRuleTypeForm, {
  BillRuleTypeFormType,
} from '@shared/modules/Billing/forms/BillRuleTypeForm';
import TextInputWithDropdown from 'modules/App/components/TextInputWithDropdown';

const handleChangeKind = ({
  kind,
  prevKind,
  form,
  field,
}: {
  kind: string;
  prevKind: string;
  form: Form<{billRuleTypeForm: BillRuleTypeFormType}>;
  field: string;
}) => {
  const isValueDisabled = kind === BillRuleKind.WAIVE_OVERTIME;
  const isValueTypeChanged =
    BillRuleType.getValueType(kind) !== BillRuleType.getValueType(prevKind);
  if (isValueDisabled || isValueTypeChanged) {
    form.setFieldValue(`${field}.value`, '');
    form.setFieldValue(`${field}.stringValue`, '');
    form.setFieldValue(`${field}.valueFormulaId`, null);
    form.setFieldValue(`${field}.billItemTypeId`, null);
  }
};

const TextAndFormulaField = ({
  form,
  field,
  dropdownField,
  label,
  formulas,
  index,
  isRequired,
  isDisabled,
  onBlur,
}: {
  form: Form<{billRuleTypeForm: BillRuleTypeFormType}>;
  field: string;
  dropdownField: string;
  label: string;
  formulas: FormulaModel[];
  index: number;
  isRequired?: boolean;
  isDisabled?: boolean;
  onBlur?: () => void;
}) => {
  return (
    // @ts-expect-error
    <TextInputWithDropdown
      isResponsive
      isPortaled
      form={form}
      index={index}
      textValueField={field}
      dropdownValueField={dropdownField}
      clearTextValue={''}
      label={label}
      dropdownOptions={Formula.getFormulaDropdownOptions(formulas)}
      placeholder={`Formula or custom ${_.toLower(label)}`}
      noOptionsMessage={'No formula options'}
      isRequired={isRequired}
      disabled={isDisabled}
      onBlur={onBlur}
    />
  );
};

const BillRuleTypeFields = ({
  form,
  field,
  billingLibrary,
}: {
  form: Form<{billRuleTypeForm: BillRuleTypeFormType}>;
  field: string;
  billingLibrary: BillingLibraryModel;
}) => {
  const billRuleTypeForm = _.get(form.values, field);
  const {kind} = billRuleTypeForm;
  const isValueDisabled = kind === BillRuleKind.WAIVE_OVERTIME;
  const {organization} = billingLibrary;
  const {formulas} = organization;
  const isConditionalBillItem =
    _.get(form.values, `${field}.kind`) === BillRuleKind.CONDITIONAL_BILL_ITEM;
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        label={'Kind'}
        name={`${field}.kind`}
        component={DropdownInput}
        isRequired
        isResponsive
        input={{
          isPortaled: true,
          options: organization.features.isEnabledConditionalBillItems
            ? BillRule.KINDS_DROPDOWN_OPTIONS
            : BillRule.KINDS_DROPDOWN_OPTIONS_V1,
          placeholder: 'Select a kind',
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          onChangeValue: (value: string, option: unknown, prevValue: string) =>
            handleChangeKind({kind: value, prevKind: prevValue, form, field}),
          style: {width: '100%'},
          components: {
            Option: DropdownInput.OptionWithDescription,
          },
        }}
      />
      <Space height={16} />
      <TextAndFormulaField
        label={'Name'}
        form={form}
        field={`${field}.name`}
        dropdownField={`${field}.nameFormulaId`}
        formulas={formulas}
        index={1}
        isRequired
      />
      <Space height={16} />
      {isConditionalBillItem ? (
        <FieldInput
          {...form}
          index={2}
          label={'Bill Item'}
          name={`${field}.billItemTypeId`}
          component={DropdownInput}
          isRequired
          isResponsive
          tooltip={'This bill item will be added to the bill when it has a specific value.'}
          input={{
            isPortaled: true,
            options: BillingLibrary.getBillItemTypeDropdownOptions(billingLibrary),
            placeholder: 'Select a bill item',
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
            style: {width: '100%'},
            components: {
              Option: DropdownInput.OptionWithDescriptionAndSecondaryLabel,
            },
          }}
        />
      ) : (
        <TextAndFormulaField
          label={'Value'}
          form={form}
          field={`${field}.value`}
          dropdownField={`${field}.valueFormulaId`}
          formulas={formulas}
          index={2}
          isDisabled={isValueDisabled}
          onBlur={() => {
            const billRuleTypeForm = _.get(form.values, `${field}`);
            const value = BillRuleTypeForm.getFormValueFromInput(billRuleTypeForm);
            form.setFieldValue(`${field}.value`, value);
          }}
        />
      )}
      <Space height={16} />
      <TextAndFormulaField
        label={'Description'}
        form={form}
        field={`${field}.description`}
        dropdownField={`${field}.descriptionFormulaId`}
        formulas={formulas}
        index={3}
      />
      <Space height={16} />
      <SwitchField
        isResponsive
        form={form}
        field={`${field}.isVisibleToCustomer`}
        labelRight={'Show Customers?'}
        hint={'When enabled, this rule will be displayed to the customer when used on a bill.'}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillRuleTypeFields.fragment = gql`
  ${BillingLibrary.getBillItemTypeDropdownOptions.fragment}
  ${Formula.getFormulaDropdownOptions.fragment}
  fragment BillRuleTypeFields on BillingLibrary {
    id
    organization {
      id
      features {
        isEnabledConditionalBillItems: isEnabled(feature: "CONDITIONAL_BILL_ITEMS")
      }
      formulas {
        id
        ...Formula_getFormulaDropdownOptions
      }
    }
    ...BillingLibrary_getBillItemTypeDropdownOptions
  }
`;

export default BillRuleTypeFields;
