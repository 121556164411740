// Libraries
import React from 'react';

// App
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// Components
import {ModalPage, Navigation} from 'modules/App/components';
import {JobRequestsHeader, JobRequestsListV2} from 'modules/JobRequest/List/components';
import {JobRequestShareModal} from 'modules/JobRequest/components';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  width: ${(props) => (props.mobile ? '100%' : '600px')};
  height: 100%;
  padding-horizontal: 20px;
`;

const Header = Styled.View`
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Button = Styled.Button`
  flex-direction: row;
  height: 40px;
  padding-horizontal: 10px;
  margin-top: 20px;
`;

const Text = Styled.H8`
  color: ${colors.white};
  ${fontWeight(700)}
  text-align: center;
`;

const Content = Styled.View`
`;

const NewListJobRequestsPage = () => (
  <ModalPage
    query={NewListJobRequestsPage.query}
    navigation={({loading, data, navigator}) => (
      <Navigation
        side={({responsive}) => (responsive.mobile ? 80 : 140)}
        left={() => <Navigation.CloseButton onPress={() => navigator.goBack()} />}
      />
    )}
  >
    {({responsive, data}) => (
      <Wrapper>
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            alignItems: 'center',
          }}
        >
          <Container {...responsive}>
            <Header>
              <Title>Move requests</Title>
              <JobRequestShareModal
                viewer={data.viewer}
                trigger={({handleOpen}) => (
                  <Button onPress={handleOpen} {...responsive}>
                    <Text>Request Link</Text>
                  </Button>
                )}
              />
            </Header>
            <Content>
              <JobRequestsHeader jobRequestCounter={data.jobRequestCounter} kind={'NEW'} />
              <JobRequestsListV2 jobRequests={data.jobRequests} kind={'NEW'} />
            </Content>
          </Container>
        </ScrollView>
      </Wrapper>
    )}
  </ModalPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
NewListJobRequestsPage.query = gql`
  ${JobRequestShareModal.fragment}
  ${JobRequestsHeader.fragment}
  ${JobRequestsListV2.fragment}

  query NewListJobRequestsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        name
      }
      ...JobRequestShareModal
    }
    jobRequestCounter {
      ...JobRequestsHeader
    }
    jobRequests: jobRequestsV2(status: "NEW") {
      id
      ...JobRequestsListV2
    }
  }
`;

export default NewListJobRequestsPage;
