/**
 * Component - v2.1.0
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const TableV1 = Styled.View`
`;

// --------------------------------------------------
// TableV1Builder.TableRow
// --------------------------------------------------
const TableRow = Styled.View`
  flex-direction: row;
  backgroundColor: ${(props: any) => props.backgroundColor};
`;

TableRow.propTypes = {
  backgroundColor: PropTypes.string,
};

TableRow.defaultProps = {
  backgroundColor: colors.white,
};

// --------------------------------------------------
// TableV1Builder.TableHeaderItem
// --------------------------------------------------
const HeaderItem = Styled.View`
  width: ${(props: any) => props.width};
  padding-horizontal: 10px;
  padding-vertical: 5px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const HeaderText = Styled.Text`
  ${Typography.Label2}
`;

const TableHeaderItem = ({width, text}: any) => {
  return (
    <HeaderItem width={width}>
      <HeaderText>{text}</HeaderText>
    </HeaderItem>
  );
};

// --------------------------------------------------
// TableV1Builder.TableItem
// --------------------------------------------------
const Item = Styled.View`
  justify-content: center;
  width: ${(props: any) => props.width};
  padding-horizontal: 10px;
  padding-vertical: 5px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const TableItem = ({width, text, children}: any) => {
  return (
    <Item width={width}>
      {!!text && <Text>{text}</Text>}
      {children}
    </Item>
  );
};

export const TableV1Builder = {
  TableHeaderItem,
  TableItem,
  TableRow,
  Text,
};

export default TableV1;
