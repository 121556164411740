// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import AddJobTruckItem from './AddJobTruckItem';
import JobTruckItem from './JobTruckItem';

const Container = Styled.View`
  z-index: 1;
`;

const EmptyContainer = Styled.View`
  align-items: center;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const JobTrucksList = ({
  isAddable, // Whether trucks can be added.
  isUnassignable, // Whether trucks can be unassigned.
  job,
  refetch,
}) => {
  const responsive = useResponsive();

  return (
    <Container>
      <FlatList
        data={job.jobTrucks}
        keyExtractor={(jobTruck) => String(jobTruck.id)}
        renderItem={({item: jobTruck}) => (
          <JobTruckItem isUnassignable={isUnassignable} jobTruck={jobTruck} refetch={refetch} />
        )}
        ListEmptyComponent={() =>
          !isAddable &&
          job.jobTrucks.length <= 0 && (
            <EmptyContainer>
              <EmptyMessage>No trucks</EmptyMessage>
            </EmptyContainer>
          )
        }
      />
      {isAddable && <AddJobTruckItem job={job} responsive={responsive} refetch={refetch} />}
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobTrucksList.propTypes = {
  isAddable: PropTypes.bool,
  isUnassignable: PropTypes.bool,
  job: PropTypes.object.isRequired,
  refetch: PropTypes.func,
};

JobTrucksList.defaultProps = {
  isAddable: false,
  isUnassignable: false,
  refetch: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTrucksList.fragment = gql`
  ${AddJobTruckItem.fragment}
  ${JobTruckItem.fragment}

  fragment JobTrucksList on Job {
    id
    crewSize
    hourlyRate
    travelFee
    crewStatus: employeeApprovalStatus {
      error
      status
    }
    jobTrucks {
      id
      ...JobTruckItem
    }
    ...AddJobTruckItem
  }
`;

export default JobTrucksList;
