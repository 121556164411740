// Libraries
import _ from 'lodash';

// Supermove
import {Datetime} from '@supermove/utils';

// App
import ProjectStatus, {
  ProjectStatusUrlFilterType,
} from '@shared/modules/Project/enums/ProjectStatus';

const URL_TO_VARIABLE_MAP = {
  active: 'ACTIVE',
  leads: 'LEAD',
  holds: 'HOLD',
  booked: 'BOOKED',
  cancelled: 'CANCELLED',
  completed: 'COMPLETED',
};

const STORAGE_URL_TO_VARIABLE_MAP = {
  active: 'ACTIVE',
  expired: 'EXPIRED',
  cancelled: 'CANCELLED',
  completed: 'COMPLETED',
};

const ACTIVE_STATUSES = [
  ProjectStatus.LEAD,
  ProjectStatus.HOLD,
  ProjectStatus.BOOKED,
  ProjectStatus.COMPLETED,
];

const AVAILABLE_FILTERS = [
  'crewOrganizationIds',
  'salespersonIds',
  'coordinatorIds',
  'followUpStartDate',
  'followUpEndDate',
  'fromDate',
  'toDate',
  'projectTypeIds',
  'projectTagIds',
  'referralSources',
  'slugs',
];

export type FilteredProjectsFormType = {
  statuses: ProjectStatusUrlFilterType[];
  salesStatuses: string[];
  salespersonIds: string[];
  coordinatorIds: string[];
  crewOrganizationIds: string[];
  followUpStartDate?: Date | null;
  followUpEndDate: Date | null;
  fromDate: Date | null;
  toDate: Date | null;
  searchQuery: string | null;
  sortSettings: string | null;
  projectTypeCategory: string | null;
  projectTypeIds: string[] | null;
  projectTagIds: string[] | null;
  referralSources: string[] | null;
  slugs: string[] | null;
  // Private
  status: ProjectStatusUrlFilterType | null;
};

const _new = () => ({
  statuses: null,
  salesStatuses: null,
  salespersonIds: null,
  coordinatorIds: null,
  crewOrganizationIds: null,
  followUpStartDate: null,
  followUpEndDate: null,
  fromDate: null,
  toDate: null,
  searchQuery: null,
  sortSettings: null,
  projectTypeCategory: null,
  projectTypeIds: null,
  projectTagIds: null,
  referralSources: null,
  slugs: null,
  // Private
  status: null,
});

const toForm = ({
  crewOrganizationIds,
  status,
  statuses,
  salesStatuses,
  salespersonIds,
  coordinatorIds,
  followUpStartDate,
  followUpEndDate,
  fromDate,
  toDate,
  searchQuery,
  sortSettings,
  projectTypeCategory,
  projectTypeIds,
  projectTagIds,
  referralSources,
  slugs,
}: any) => ({
  crewOrganizationIds,
  statuses,
  salesStatuses,
  salespersonIds,
  coordinatorIds,
  followUpStartDate: followUpStartDate ? Datetime.fromDate(followUpStartDate) : null,
  followUpEndDate: followUpEndDate ? Datetime.fromDate(followUpEndDate) : null,
  fromDate: fromDate ? Datetime.fromDate(fromDate) : null,
  toDate: toDate ? Datetime.fromDate(toDate) : null,
  searchQuery,
  sortSettings,
  projectTypeCategory,
  projectTypeIds,
  projectTagIds,
  referralSources,
  slugs,

  // Private
  status,
});

const toQueryParams = ({
  crewOrganizationIds,
  status,
  statuses,
  salesStatuses,
  salespersonIds,
  coordinatorIds,
  followUpStartDate,
  followUpEndDate,
  fromDate,
  toDate,
  searchQuery,
  sortSettings,
  projectTypeCategory,
  projectTypeIds,
  projectTagIds,
  referralSources,
  slugs,
}: FilteredProjectsFormType) => ({
  crewOrganizationIds,
  statuses,
  salesStatuses,
  salespersonIds,
  coordinatorIds,
  followUpStartDate: followUpStartDate ? Datetime.toDate(followUpStartDate) : null,
  followUpEndDate: followUpEndDate ? Datetime.toDate(followUpEndDate) : null,
  fromDate: fromDate ? Datetime.toDate(fromDate) : null,
  toDate: toDate ? Datetime.toDate(toDate) : null,
  searchQuery,
  sortSettings,
  projectTypeCategory,
  projectTypeIds,
  projectTagIds,
  referralSources,
  slugs,

  // Private
  status,
});

const getAvailableSalesStatuses = ({
  status,
  isEnabledProjectHoldStatus,
  isEnabledSalesStatusDepositReceived,
}: any) => {
  switch (status) {
    case 'leads':
      if (isEnabledProjectHoldStatus) {
        return ['NEW', 'QUOTE_PENDING', 'QUOTE_ACCEPTED'];
      }

      if (isEnabledSalesStatusDepositReceived) {
        return [
          'NEW',
          'QUOTE_PENDING',
          'QUOTE_ACCEPTED',
          'DEPOSIT_RECEIVED',
          'CONFIRMATION_PENDING',
        ];
      }

      return ['NEW', 'QUOTE_PENDING', 'QUOTE_ACCEPTED', 'CONFIRMATION_PENDING'];
    case 'holds':
      if (isEnabledSalesStatusDepositReceived) {
        return ['DEPOSIT_RECEIVED', 'CONFIRMATION_PENDING'];
      }
      return ['CONFIRMATION_PENDING'];
    // No filters for these statuses, all sales statuses will show
    case 'cancelled':
    case 'completed':
    default:
      return [];
  }
};

const getNumberOfFiltersApplied = ({form, isPrimary, isRestricted}: any) =>
  AVAILABLE_FILTERS.reduce((appliedFiltersCount, filter) => {
    const formFilter = form.values.filteredProjectsForm[filter];

    // Special case for slugs where we don't want to ALL_ORGANIZATION in the count
    if (
      formFilter &&
      formFilter.length !== 0 &&
      !(filter === 'slugs' && _.isEqual(formFilter, ['ALL_ORGANIZATIONS'])) &&
      isPrimary &&
      !(filter === 'salespersonIds' && isRestricted)
    ) {
      return appliedFiltersCount + 1;
    } else {
      return appliedFiltersCount;
    }
  }, 0);

const FilteredProjectsForm = {
  new: _new,
  toForm,
  toQueryParams,
  getAvailableSalesStatuses,
  getNumberOfFiltersApplied,
  URL_TO_VARIABLE_MAP,
  STORAGE_URL_TO_VARIABLE_MAP,
  ACTIVE_STATUSES,
};

export default FilteredProjectsForm;
