// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Emoji, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import DocumentSuccessStep from 'modules/Customer/Project/Confirmation/components/DocumentSuccessStep';

const Container = Styled.View`
  flex: 1;
  width: ${(props) => (props.mobile ? '100%' : '796px')};
  padding-horizontal: ${(props) => (props.mobile ? 20 : 0)}px;
  align-self: center;
`;

const Header = Styled.Text`
  ${Typography.Heading1}
`;

const Subtitle = Styled.Text`
  ${Typography.Body}
`;

const StepContainer = Styled.View`
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: 1px;
  align-items: ${(props) => (props.mobile ? 'flex-start' : 'center')};
  padding-horizontal: 12px;
  padding-vertical: 12px;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  justify-content: space-between;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const StepTitle = Styled.Text`
  ${Typography.Subheading}
`;

const StepValue = Styled.Text`
  ${Typography.Body}
`;

const Step = ({responsive, emoji, stepTitle, stepValue}) => {
  return (
    <React.Fragment>
      <StepContainer {...responsive}>
        <Row>
          <Emoji name={emoji} />
          <Space width={12} />
          <StepTitle>{stepTitle}</StepTitle>
        </Row>
        {responsive.mobile && <Space height={8} />}
        <Row>
          <StepValue>{stepValue}</StepValue>
        </Row>
      </StepContainer>
      <Space height={24} />
    </React.Fragment>
  );
};

const ConfirmationQuoteSuccess = ({
  confirmation,
  title,
  subtitle,
  isCancelled,
  cancelledMessage,
}) => {
  const responsive = useResponsive();
  return (
    <Container {...responsive}>
      <Space height={60} />
      {isCancelled ? (
        <Header style={{textAlign: 'center'}}>{cancelledMessage}</Header>
      ) : (
        <React.Fragment>
          <Header>{title}</Header>
          <Space height={8} />
          <Subtitle>{subtitle}</Subtitle>
          <Space height={36} />
          {confirmation.steps.map((step, index) => {
            switch (step.kind) {
              case ConfirmationStepKind.CONFIRMATION_STEP_DOCUMENT:
              case ConfirmationStepKind.QUOTE_STEP_DOCUMENT:
                return (
                  <React.Fragment key={index}>
                    <DocumentSuccessStep
                      documentUuid={step.documentUuid}
                      defaultCollapsed={index !== 0}
                    />
                    <Space height={24} />
                  </React.Fragment>
                );
              case ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD:
                return (
                  <Step
                    key={index}
                    responsive={responsive}
                    emoji={'credit_card'}
                    stepTitle={'Cancellation'}
                    stepValue={`Cancellation Fee: ${Currency.toForm(step.value)}, ${
                      responsive.mobile ? '\n' : ''
                    }Cancellation Window: ${step.valueTwo} before move`}
                  />
                );
              case ConfirmationStepKind.CONFIRMATION_STEP_MAKE_DEPOSIT:
                return (
                  <Step
                    key={index}
                    responsive={responsive}
                    emoji={'dollar'}
                    stepTitle={'Deposit'}
                    stepValue={`Deposit: ${Currency.toForm(step.value)}`}
                  />
                );
              default:
                return null;
            }
          })}
        </React.Fragment>
      )}
      <Space height={36} />
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ConfirmationQuoteSuccess.propTypes = {
  confirmation: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isCancelled: PropTypes.bool,
  cancelledMessage: PropTypes.string,
};

ConfirmationQuoteSuccess.defaultProps = {
  title: '',
  subtitle: '',
  isCancelled: false,
  cancelledMessage: '',
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmationQuoteSuccess.fragment = gql`
  fragment ConfirmationQuoteSuccess on Confirmation {
    id
    steps {
      kind
      value
      valueTwo
      documentUuid
    }
  }
`;

export default ConfirmationQuoteSuccess;
