// Supermove
import {BulkUpdateBooleanPropertyForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useBulkUpdateShipmentsIsArchivedMutation = ({
  bulkUpdateBooleanPropertyForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      bulkUpdateBooleanPropertyForm: BulkUpdateBooleanPropertyForm.toForm(
        bulkUpdateBooleanPropertyForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useBulkUpdateShipmentsIsArchivedMutation.mutation,
    variables: {
      bulkUpdateBooleanPropertyForm: BulkUpdateBooleanPropertyForm.toMutation(
        form.values.bulkUpdateBooleanPropertyForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useBulkUpdateShipmentsIsArchivedMutation.mutation = gql`
  mutation useBulkUpdateShipmentsIsArchivedMutation($bulkUpdateBooleanPropertyForm: BulkUpdateBooleanPropertyForm!) {
    response: bulkUpdateShipmentsIsArchived(bulkUpdateBooleanPropertyForm: $bulkUpdateBooleanPropertyForm) {
      ${gql.errors}
      shipments {
        id
        isArchived
      }
    }
  }
`;

export default useBulkUpdateShipmentsIsArchivedMutation;
