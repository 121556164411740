/**
 * WARNING (warren)
 * This is a web component that renders HTML used to generate a PDF.
 */

// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Document} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// Components
import DocumentCustomerSignature from './DocumentCustomerSignature';
import DocumentFreightBillInfo from './DocumentFreightBillInfo';
import DocumentHeaderOrganization from './DocumentHeaderOrganization';

const Container = Styled.View`
  width: 100%;
  background-color: ${colors.white};
`;

const Section = Styled.View`
  margin-top: 20px;
`;

const SectionBody = Styled.H7`
  margin-top: 5px;
  font-size: 13px;
  line-height: 16px;
  color: ${colors.gray.primary};
`;

const Items = Styled.View`
  margin-top: 10px;
`;

const ItemText = Styled.H7`
  font-size: 16px;
  line-height: 20px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const getBodyText = ({organization}) => {
  return (
    `This is to confirm that I have been informed by the ${organization.name} ` +
    `that the conveyance of the item(s) listed below involve a "risky move." A ` +
    `"risky move" occurs when either the item being moved, or the property through ` +
    `which it is being moved, is at risk of damage or loss due to the manner in which ` +
    `the move must be accomplished. Examples of risky moves include, but are not limited ` +
    `to, hoisting items, customer assisted moves, moving property through limited space ` +
    `or where access is limited, and/or involving items which are fragile, awkward, ` +
    `or constructed of non-permanent materials such as particle board, and therefore ` +
    `likely to be damaged by any move.\n\n` +
    `I agree to hold ${organization.name}, its agents and employees, harmless, ` +
    `and to indemnify them from any and all claims of damage, loss, or injury to ` +
    `any person, or property (including the property which is being transported), ` +
    `which arises from the "risky move" as to any and all of the items listed below. ` +
    `If I sign this waiver on behalf of a company or other third party, I warrant that I ` +
    `am authorized to agree to this waiver on behalf of that company or third party. ` +
    `Based on this waiver, and in reliance on it, ${organization.name} will move the ` +
    `following items as a "risky move":`
  );
};

const getSignature = ({job}) => {
  const document = job.move.riskyMoveDocument;
  const documentSignature = _.first(document.documentSignatures);
  return _.get(documentSignature, 'signature', null);
};

const JobRiskyMoveDocument = ({job}) => (
  <Container>
    <DocumentHeaderOrganization title={'Risky Move Agreement'} organization={job.organization} />
    <DocumentFreightBillInfo job={job} />
    <Section>
      <SectionBody>{getBodyText({organization: job.organization})}</SectionBody>
    </Section>
    <Section>
      <Items>
        {Document.getAdditionalInfo(job.move.riskyMoveDocument).items.map((item, index) => (
          <ItemText key={index}>{`${index + 1}. ${item}`}</ItemText>
        ))}
      </Items>
    </Section>
    <Section>
      <DocumentCustomerSignature signature={getSignature({job})} />
    </Section>
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobRiskyMoveDocument.propTypes = {
  job: PropTypes.object.isRequired,
};

JobRiskyMoveDocument.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRiskyMoveDocument.fragment = gql`
  ${DocumentCustomerSignature.fragment}
  ${DocumentFreightBillInfo.fragment}
  ${DocumentHeaderOrganization.fragment}

  fragment JobRiskyMoveDocument on Job {
    id
    organization {
      id
      name
      ...DocumentHeaderOrganization
    }
    move {
      id
      riskyMoveDocument {
        id
        additionalInfo
        documentSignatures {
          id
          documentId
          signatureId
          signature {
            id
            ...DocumentCustomerSignature
          }
        }
      }
    }
    ...DocumentFreightBillInfo
  }
`;

export default JobRiskyMoveDocument;
