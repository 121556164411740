// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Json} from '@supermove/utils';

// Components
import JobUserStatusBadge from 'modules/App/Job/components/JobUserStatusBadge';

const Wrapper = Styled.View`
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => (props.isLast ? 0 : 5)}px;
`;

const Touchable = Styled.Touchable`
`;

const Container = Styled.View`
  align-items: center;
  justify-content: center;
  height: 40px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.secondary};
  border-radius: 5px;
  max-width: 120px;
`;

const Section = Styled.View`
  align-items: center;
  width: 100%;
`;

const Name = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  padding-horizontal: 20px;
`;

const Position = Styled.H8`
  color: ${colors.gray.primary};
  padding-horizontal: 12px;
`;

const Badge = Styled.View`
  position: absolute;
  bottom: 0px;
  right: 2px;
`;

const shouldShowBadge = ({jobUser}) => {
  const notificationSettings = _.get(jobUser, 'user.organization.settings.notifications', '{}');
  const json = Json.toCamelCaseKeys(Json.parse(notificationSettings));
  return json.allowEmployeeText;
};

const UserAvatar = ({jobUser}) => (
  <Container>
    <Section>
      <Name numberOfLines={1}>{jobUser.user.firstName}</Name>
    </Section>
    <Section>
      <Position numberOfLines={1}>{User.getPosition(jobUser.user)}</Position>
      {shouldShowBadge({jobUser}) && (
        <Badge>
          <JobUserStatusBadge jobUser={jobUser} />
        </Badge>
      )}
    </Section>
  </Container>
);

const JobUserAvatar = ({isLast, jobUser, onPress}) => {
  return onPress ? (
    <Wrapper isLast={isLast}>
      <Touchable onPress={() => onPress(jobUser)}>
        <UserAvatar jobUser={jobUser} />
      </Touchable>
    </Wrapper>
  ) : (
    <Wrapper isLast={isLast}>
      <UserAvatar jobUser={jobUser} />
    </Wrapper>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobUserAvatar.propTypes = {
  isLast: PropTypes.bool,
  jobUser: PropTypes.object.isRequired,
  onPress: PropTypes.func,
};

JobUserAvatar.defaultProps = {
  isLast: false,
  onPress: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobUserAvatar.fragment = gql`
  ${JobUserStatusBadge.fragment}

  fragment JobUserAvatar on JobUser {
    id
    jobId
    userId
    user {
      id
      firstName
      position
      organization {
        id
        settings {
          id
          notifications
        }
      }
    }
    ...JobUserStatusBadge
  }
`;

export default JobUserAvatar;
