// App
import WorkflowModelDataTypes from '@shared/modules/Workflow/enums/WorkflowModelDataTypes';
import WorkflowModelProperties from '@shared/modules/Workflow/enums/WorkflowModelProperties';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';

const MODEL_TYPE_TO_MODEL_PROPERTIES = {
  [WorkflowStepActionKind.CATEGORIES.TASK]: [
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    WorkflowModelProperties.MODEL_PROPERTY_OPTIONS[WorkflowModelProperties.TASK_IS_COMPLETED],
  ],
  [WorkflowStepActionKind.CATEGORIES.PROJECT]: [
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    WorkflowModelProperties.MODEL_PROPERTY_OPTIONS[WorkflowModelProperties.PROJECT_MOVE_STATUS],
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    WorkflowModelProperties.MODEL_PROPERTY_OPTIONS[WorkflowModelProperties.PROJECT_STORAGE_STATUS],
  ],
};

const getModelPropertyOptionsFormStepReferenceKind = (stepReferenceKind: any) => {
  return MODEL_TYPE_TO_MODEL_PROPERTIES[stepReferenceKind];
};

const getIsComparatorTypeAssumed = (propertyDataType: any) => {
  return propertyDataType === WorkflowModelDataTypes.BOOLEAN;
};

const WorkflowStopIfHelpers = {
  getModelPropertyOptionsFormStepReferenceKind,
  getIsComparatorTypeAssumed,
};

export default WorkflowStopIfHelpers;
