// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: ${(props) => (props.reversed ? 'row-reverse' : 'row')};
  align-items: center;
  padding-vertical: 3px;
  padding-horizontal: 3px;
  border-width: 1px;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  border-color: ${(props) => props.color};
  border-radius: 3px;
`;

const Circle = Styled.View`
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: ${(props) => props.color};
`;

const Count = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.white};
`;

const Text = Styled.H8`
  ${fontWeight(500)}
  color: ${(props) => props.color};
`;

const Pill = ({icon, color, count, text, reversed, backgroundColor}) => {
  return (
    <Container color={color} reversed={reversed} backgroundColor={backgroundColor}>
      {icon}
      <Circle color={color}>
        <Count>{count}</Count>
      </Circle>
      <Space width={3} />
      <Text color={color}>{text}</Text>
      <Space width={3} />
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
Pill.propTypes = {
  color: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

Pill.defaultProps = {};

export default Pill;
