// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import SendInvoiceToCodatForm from '@shared/modules/Billing/forms/SendInvoiceToCodatForm';

const useSendInvoiceToCodatMutation = ({sendInvoiceToCodatForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      sendInvoiceToCodatForm: SendInvoiceToCodatForm.toForm(sendInvoiceToCodatForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSendInvoiceToCodatMutation.mutation,
    variables: {
      sendInvoiceToCodatForm: SendInvoiceToCodatForm.toMutation(form.values.sendInvoiceToCodatForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSendInvoiceToCodatMutation.mutation = gql`
  mutation useSendInvoiceToCodatMutation($sendInvoiceToCodatForm: SendInvoiceToCodatForm!) {
    response: sendInvoiceToCodat(sendInvoiceToCodatForm: $sendInvoiceToCodatForm) {
      ${gql.errors}
      invoice {
        id
      }
    }
  }
`;

export default useSendInvoiceToCodatMutation;
