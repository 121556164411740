// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import TagForm from '@shared/modules/Tag/forms/TagForm';

const useUnarchiveTagMutation = ({tagForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      tagForm: TagForm.toForm(tagForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUnarchiveTagMutation.mutation,
    variables: {
      tagForm: TagForm.toMutation(form.values.tagForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUnarchiveTagMutation.mutation = gql`
  mutation useUnarchiveTagMutation($tagForm: TagForm!) {
    response: unarchiveTag(tagForm: $tagForm) {
      ${gql.errors}
      tag {
        id
      }
    }
  }
`;

export default useUnarchiveTagMutation;
