import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ImportExternalInvoiceItemMappingsForm from '@shared/modules/Integration/forms/ImportExternalInvoiceItemMappingsForm';

const useImportExternalInvoiceItemMappingsMutation = ({
  importExternalInvoiceItemMappingsForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      importExternalInvoiceItemMappingsForm: ImportExternalInvoiceItemMappingsForm.toForm(
        importExternalInvoiceItemMappingsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useImportExternalInvoiceItemMappingsMutation.mutation,
    variables: {
      importExternalInvoiceItemMappingsForm: ImportExternalInvoiceItemMappingsForm.toMutation(
        form.values.importExternalInvoiceItemMappingsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useImportExternalInvoiceItemMappingsMutation.mutation = gql`
  mutation useImportExternalInvoiceItemMappingsMutation($importExternalInvoiceItemMappingsForm: ImportExternalInvoiceItemMappingsForm!) {
    response: importExternalItemMappings(importExternalInvoiceItemMappingsForm: $importExternalInvoiceItemMappingsForm) {
      ${gql.errors}
    }
  }
`;

export default useImportExternalInvoiceItemMappingsMutation;
