// Supermove
import {useContext} from '@supermove/hooks';
import {invariant} from '@supermove/utils';

// App
import {DayCalendarDispatchOrganizationsContext} from 'modules/Dispatch/Calendar/Day/context/DayCalendarDispatchOrganizationsContextProvider';

const useDayCalendarDispatchOrganizationsContext = () => {
  const context = useContext(DayCalendarDispatchOrganizationsContext);
  invariant(
    context,
    'useDayCalendarDispatchOrganizationsContext must be used within a DayCalendarDispatchOrganizationsContextProvider',
  );

  return context;
};

export default useDayCalendarDispatchOrganizationsContext;
