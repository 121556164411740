// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Popover, ScrollView, Space, Styled} from '@supermove/components';
import {BillRuleForm} from '@supermove/forms';
import {fontWeight, colors} from '@supermove/styles';

const NamePressable = Styled.ButtonV2`
  padding-vertical: 6px;
  padding-left: 8px;
`;

const NameText = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.black};
`;

const getSearchPopoverHeight = () => {
  const spaceAbove = 176;
  const spaceBelow = 80;
  return window.innerHeight - spaceAbove - spaceBelow;
};

const getSearchPopoverWidth = () => {
  const paddingOnSides = 32;
  return window.innerWidth - paddingOnSides;
};

const SearchRuleForms = ({searchRuleForms, handleCloseSearch, handlePress}) => {
  return (
    <React.Fragment>
      {searchRuleForms.map((billRuleForm, idx) => {
        return (
          <React.Fragment key={`BILL_RULE_FORM-${idx}`}>
            <Space height={10} />
            <NamePressable
              onPress={() => {
                handlePress(billRuleForm);
                handleCloseSearch();
              }}
            >
              <NameText>
                {`${billRuleForm.name}${
                  _.isEmpty(billRuleForm.values[0])
                    ? ''
                    : ` (${BillRuleForm.getDisplayValues(billRuleForm)})`
                }`}
              </NameText>
            </NamePressable>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const EditBillRulesSearchPopover = ({popover, searchRuleForms, handleCloseSearch, handlePress}) => {
  return (
    <Popover
      placement={Popover.Positions.Bottom}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ScrollView
        style={{
          borderWidth: 1,
          borderColor: colors.blue.interactive,
          borderRadius: 4,
          paddingHorizontal: 8,
          backgroundColor: colors.white,
          flex: 1,
          width: getSearchPopoverWidth(),
          maxHeight: getSearchPopoverHeight(),
        }}
      >
        <Space height={6} />
        <SearchRuleForms
          searchRuleForms={searchRuleForms}
          handleCloseSearch={handleCloseSearch}
          handlePress={handlePress}
        />
        <Space height={16} />
      </ScrollView>
    </Popover>
  );
};

export default EditBillRulesSearchPopover;
