// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import OrganizationBusinessInfoLicenseForm from '@shared/modules/Organization/forms/OrganizationBusinessInfoLicenseForm';

const useUpdateOrganizationBusinessInfoLicenseMutation = ({
  organizationBusinessInfoLicenseForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      organizationBusinessInfoLicenseForm: OrganizationBusinessInfoLicenseForm.toForm(
        organizationBusinessInfoLicenseForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationBusinessInfoLicenseMutation.mutation,
    variables: {
      organizationBusinessInfoLicenseForm: OrganizationBusinessInfoLicenseForm.toMutation(
        form.values.organizationBusinessInfoLicenseForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationBusinessInfoLicenseMutation.mutation = gql`
  ${OrganizationBusinessInfoLicenseForm.edit.fragment}
  mutation useUpdateOrganizationBusinessInfoLicenseMutation($organizationBusinessInfoLicenseForm: OrganizationBusinessInfoLicenseForm!) {
    response: updateOrganizationBusinessInfoLicense(organizationBusinessInfoLicenseForm: $organizationBusinessInfoLicenseForm) {
      ${gql.errors}
      organization {
        id
        ...OrganizationBusinessInfoLicenseForm_edit
      }
    }
  }
`;

export default useUpdateOrganizationBusinessInfoLicenseMutation;
