// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Task} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Container = Styled.View`
`;

const TaskDueIcon = ({task, size}) => {
  if (!task.isCompleted) {
    if (Task.getIsPastDue(task)) {
      return (
        <TextTooltip text={'Overdue'}>
          <Container>
            <Icon
              source={Icon.ExclamationTriangle}
              size={size}
              color={colors.red.warning}
              style={{marginRight: 6}}
            />
          </Container>
        </TextTooltip>
      );
    }
    if (Task.getIsDueLaterToday(task)) {
      return (
        <TextTooltip text={'Due soon'}>
          <Container>
            <Icon
              source={Icon.ExclamationTriangle}
              size={size}
              color={colors.orange.status}
              style={{marginRight: 6}}
            />
          </Container>
        </TextTooltip>
      );
    }
  }
  return null;
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
TaskDueIcon.propTypes = {
  task: PropTypes.object.isRequired,
  size: PropTypes.number,
};

TaskDueIcon.defaultProps = {
  size: 11,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TaskDueIcon.fragment = gql`
  ${Task.getIsDueLaterToday.fragment}
  ${Task.getIsPastDue.fragment}
  fragment TaskDueIcon on Task {
    id
    ...Task_getIsDueLaterToday
    ...Task_getIsPastDue
  }
`;

export default TaskDueIcon;
