// Libraries
import React from 'react';

// Supermove
import {Loading, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useModal, useQuery} from '@supermove/hooks';
import {Currency} from '@supermove/utils';

// App
import CollapsibleContent from '@shared/design/components/CollapsibleContent';
import FieldValue from 'modules/App/components/FieldValue';
import UpdateValuationCoverageModal from 'modules/Job/V2/Move/components/UpdateValuationCoverageModal';

const Container = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  z-index: ${({index = 0}) => 100 - index};
`;

const FieldSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 0)}px;
  margin-left: ${(props) => (props.mobile ? 0 : 20)}px;
`;

const PaddingContainer = Styled.View`
  padding: 16px;
`;

const JobAccountingValuationCoverageBlock = ({sectionIndex, job, refetch, responsive}) => {
  const editValuationCoverageModal = useModal({
    name: 'Edit Valuation Coverage Modal',
    enableTracking: true,
  });

  return (
    <Container {...responsive} sectionIndex={sectionIndex}>
      <CollapsibleContent
        title={'Valuation Coverage'}
        primaryActionText={'Edit'}
        primaryActionLeftIcon={Icon.Pen}
        handlePrimaryAction={editValuationCoverageModal.handleOpen}
      >
        <PaddingContainer>
          <Row {...responsive}>
            <FieldValue label={'Selected Option'} value={job.valuationCoverage.displayName} />
            {job.valuationCoverage.additionalCost > 0 && (
              <React.Fragment>
                <FieldSpace {...responsive} />
                <FieldValue
                  label={'Selected Value'}
                  value={Currency.display(job.valuationCoverage.amountValue)}
                />
              </React.Fragment>
            )}
            <FieldSpace {...responsive} />
            <FieldValue
              label={'Minimum'}
              value={Currency.display(job.valuationCoverage.amountMinimum)}
            />
          </Row>
        </PaddingContainer>
      </CollapsibleContent>
      <UpdateValuationCoverageModal
        isOpen={editValuationCoverageModal.isOpen}
        jobUuid={job.uuid}
        handleClose={editValuationCoverageModal.handleClose}
        refetch={refetch}
      />
    </Container>
  );
};

const JobValuationCoverageBlockContent = ({sectionIndex, job, refetch}) => {
  const responsive = useResponsive();

  return (
    <JobAccountingValuationCoverageBlock
      sectionIndex={sectionIndex}
      job={job}
      refetch={refetch}
      responsive={responsive}
    />
  );
};

const JobValuationCoverageBlockQuery = ({sectionIndex, job}) => {
  const {data, loading, refetch} = useQuery(JobValuationCoverageBlock.query, {
    fetchPolicy: 'cache-and-network',
    variables: {jobUuid: job.uuid},
  });

  return (
    <Loading loading={loading}>
      {() => {
        return (
          <React.Fragment>
            {data.job.valuationCoverage && (
              <JobValuationCoverageBlockContent
                sectionIndex={sectionIndex}
                job={data.job}
                refetch={refetch}
              />
            )}
          </React.Fragment>
        );
      }}
    </Loading>
  );
};

const JobValuationCoverageBlock = ({sectionIndex, job}) => {
  return (
    <JobValuationCoverageBlockQuery
      key={`${job.project.totalRevenue}_${job.project.valuationCoverage.updatedAt}`}
      sectionIndex={sectionIndex}
      job={job}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobValuationCoverageBlock.propTypes = {};

JobValuationCoverageBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobValuationCoverageBlock.listener = gql`
  fragment JobValuationCoverageBlock_listener on Project {
    id
    totalRevenue
    valuationCoverage {
      id
      updatedAt
    }
  }
`;

JobValuationCoverageBlock.fragment = gql`
  ${JobValuationCoverageBlock.listener}

  fragment JobValuationCoverageBlock on Job {
    id
    uuid
    project {
      id
      ...JobValuationCoverageBlock_listener
    }
  }
`;

JobValuationCoverageBlock.query = gql`
  ${JobValuationCoverageBlock.listener}
  query JobValuationCoverageBlock($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      uuid
      valuationCoverage {
        id
        amountMinimum
        amountValue
        category
        deductible
        displayName
        additionalCost
      }
      project {
        id
        ...JobValuationCoverageBlock_listener
      }
    }
  }
`;

export default JobValuationCoverageBlock;
