// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (confirmation) => ({
    confirmationId: (confirmation as any).id,
  }),
  gql`
    fragment ConfirmationForm_edit on Confirmation {
      id
    }
  `,
);

const toForm = ({confirmationId}: any) => ({confirmationId});

const toMutation = ({confirmationId}: any) => ({confirmationId});

const ConfirmationForm = {
  edit,
  toForm,
  toMutation,
};

export default ConfirmationForm;
