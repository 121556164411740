// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';

const getSubtitle = ({data, isBlocked}) => {
  const project = _.get(data, 'project');
  if (!project) {
    return '';
  }
  return (
    `Dispatch has locked scheduling ${project.projectType.name} jobs on:` +
    `\n• ${project.salesLockedProjectTypeDays
      .map((salesLockedProjectTypeDay) =>
        Datetime.toDisplayDate(
          Datetime.fromDate(salesLockedProjectTypeDay.day.date),
          'ddd, MM/DD/YY',
        ),
      )
      .join('\n• ')}` +
    `${isBlocked ? '' : '\nProceed anyway?'}`
  );
};

const ProjectSalesLockedModal = ({isOpen, projectUuid, handleClose, handleConfirm, isBlocked}) => {
  const {loading, data} = useQuery(ProjectSalesLockedModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
    },
    skip: !isOpen,
  });

  return (
    <ActionModal
      icon={Icon.Calendar}
      color={colors.orange.status}
      title={'Date is locked.'}
      message={getSubtitle({data, isBlocked})}
      isOpen={isOpen}
      handlePrimaryAction={isBlocked ? handleClose : handleConfirm}
      handleSecondaryAction={isBlocked ? null : handleClose}
      primaryActionText={isBlocked ? 'Close' : 'Confirm'}
      secondaryActionText={'Cancel'}
      isLoading={loading || !data}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ProjectSalesLockedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  projectUuid: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func.isRequired,
  isBlocked: PropTypes.bool,
};

ProjectSalesLockedModal.defaultProps = {
  handleClose: () => {},
  isBlocked: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectSalesLockedModal.query = gql`
  query ProjectSalesLockedModal($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      projectType {
        id
        name
      }
      salesLockedProjectTypeDays {
        id
        day {
          id
          date
        }
      }
    }
  }
`;
export default ProjectSalesLockedModal;
