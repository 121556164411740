// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import TextMessageTemplateForm from '@shared/modules/Sms/forms/TextMessageTemplateForm';

const useUpdateTextMessageTemplateMutation = ({
  textMessageTemplateForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      textMessageTemplateForm: TextMessageTemplateForm.toForm(textMessageTemplateForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateTextMessageTemplateMutation.mutation,
    variables: {
      textMessageTemplateForm: TextMessageTemplateForm.toMutation(
        form.values.textMessageTemplateForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateTextMessageTemplateMutation.mutation = gql`
  mutation useUpdateTextMessageTemplateMutation($textMessageTemplateForm: TextMessageTemplateForm!) {
    response: updateTextMessageTemplateMutation(textMessageTemplateForm: $textMessageTemplateForm) {
      ${gql.errors}
      textMessageTemplate {
        id
      }
    }
  }
`;

export default useUpdateTextMessageTemplateMutation;
