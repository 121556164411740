// Libraries
import React from 'react';

// Supermove
import {Notification, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import SendInvoiceToCodatForm from '@shared/modules/Billing/forms/SendInvoiceToCodatForm';
import SyncReportMoveToQuickbooksFailureModal from 'modules/ReportMove/Show/components/SyncReportMoveToQuickbooksFailureModal';
import SyncReportMoveToQuickbooksSuccessModal from 'modules/ReportMove/Show/components/SyncReportMoveToQuickbooksSuccessModal';
import useSyncReportMoveToQuickbooksForm from 'modules/ReportMove/Show/logic/useSyncReportMoveToQuickbooksForm';

const Container = Styled.View`
`;

const SectionSpace = Styled.View`
  margin-top: 10px;
`;

const Button = Styled.LoadingButton`
  padding-vertical: 5px;
  padding-horizontal: 12px;
  border-radius: 10px;
`;

const Text = Styled.H6`
  color: ${colors.white};
`;

const SyncReportMoveToQuickbooksButton = ({reportMove}) => {
  const {
    isOpen: isOpenSyncReportMoveToQuickbooksSuccessModal,
    handleOpen: handleOpenSyncReportMoveToQuickbooksSuccessModal,
    handleClose: handleCloseSyncReportMoveToQuickbooksSuccessModal,
  } = useModal();

  const {
    isOpen: isOpenSyncReportMoveToQuickbooksFailureModal,
    handleOpen: handleOpenSyncReportMoveToQuickbooksFailureModal,
    handleClose: handleCloseSyncReportMoveToQuickbooksFailureModal,
  } = useModal();

  const {
    form,
    submitting,
    handleSubmit: handleSubmitReportMoveToQuickbooks,
  } = useSyncReportMoveToQuickbooksForm({
    reportMove,
    onSuccess: handleOpenSyncReportMoveToQuickbooksSuccessModal,
    onError: handleOpenSyncReportMoveToQuickbooksFailureModal,
  });

  const handleSubmit = () => {
    handleSubmitReportMoveToQuickbooks();
  };

  return (
    <React.Fragment>
      <SyncReportMoveToQuickbooksFailureModal
        isOpen={isOpenSyncReportMoveToQuickbooksFailureModal}
        errors={form.values.errors}
        handleClose={handleCloseSyncReportMoveToQuickbooksFailureModal}
      />
      <SyncReportMoveToQuickbooksSuccessModal
        isOpen={isOpenSyncReportMoveToQuickbooksSuccessModal}
        handleClose={handleCloseSyncReportMoveToQuickbooksSuccessModal}
      />
      <Button loading={submitting} color={colors.green.status} onPress={handleSubmit}>
        <Text>Sync to QuickBooks</Text>
      </Button>
    </React.Fragment>
  );
};

const ReportMoveNotification = ({reportMove}) => {
  const {navigator} = useNavigationDOM();
  const {isEnabledSendCustomerReportInvoiceEmail} = reportMove.organization.features;

  return (
    <Container>
      {isEnabledSendCustomerReportInvoiceEmail && (
        <React.Fragment>
          {reportMove.isFinal ? (
            <Notification.Success text={'This report is final.'}>
              <Button
                color={colors.green.status}
                onPress={() => navigator.push(`/jobs/${reportMove.job.uuid}/move-report/send`)}
              >
                <Text>Email to Customer</Text>
              </Button>
            </Notification.Success>
          ) : (
            <Notification.Error text={'This report is not final.'}>
              {reportMove.job && reportMove.job.project.currentAggregateBill.balance > 0 && (
                <Button
                  color={colors.red.warning}
                  onPress={() => navigator.push(`/jobs/${reportMove.job.uuid}/invoice/send`)}
                >
                  <Text>Email Invoice</Text>
                </Button>
              )}
            </Notification.Error>
          )}
        </React.Fragment>
      )}
      {reportMove.organization.hasQuickbooksCredential && reportMove.isSyncableToQuickbooks && (
        <React.Fragment>
          <SectionSpace />
          <Notification.Success text={'This report is syncable to Quickbooks.'}>
            <SyncReportMoveToQuickbooksButton reportMove={reportMove} />
          </Notification.Success>
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMoveNotification.fragment = gql`
  ${SendInvoiceToCodatForm.edit.fragment}

  fragment ReportMoveNotification on ReportMove {
    id
    isFinal
    isSyncableToQuickbooks
    job {
      id
      uuid
      project {
        id
        currentAggregateBill {
          balance
        }
        mainMoveInvoice {
          id
          identifier
          ...SendInvoiceToCodatForm_edit
        }
      }
    }
    organization {
      id
      hasQuickbooksCredential

      features {
        isEnabledSendCustomerReportInvoiceEmail: isEnabled(
          feature: "SEND_CUSTOMER_REPORT_INVOICE_EMAIL"
        )
      }
    }
  }
`;

export default ReportMoveNotification;
