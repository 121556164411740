// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {PrintCardPage} from 'modules/Document/Print/components';
import {JobCardDocumentV2} from 'modules/Document/components';

const CardPrintJobPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <PrintCardPage query={CardPrintJobPage.query} variables={{uuid}}>
    {({data}) => <JobCardDocumentV2 job={data.job} />}
  </PrintCardPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CardPrintJobPage.query = gql`
  ${JobCardDocumentV2.fragment}

  query CardPrintJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      uuid
      ...JobCardDocumentV2
    }
  }
`;

export default CardPrintJobPage;
