// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components
import SmallModal from '@shared/design/components/Modal/SmallModal';
import CostForm from '@shared/modules/Billing/forms/CostForm';
import useCreateCostMutation from '@shared/modules/Billing/hooks/useCreateCostMutation';
import NewCompensationEntryFields from 'modules/Cost/components/NewCompensationEntryFields';

const NewCompensationEntryHeader = () => {
  return (
    <React.Fragment>
      <SmallModal.HeaderText>Create Compensation Entry</SmallModal.HeaderText>
      <Space height={16} />
    </React.Fragment>
  );
};

const NewCompensationEntryFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <SmallModal.Footer>
      <SmallModal.Button
        textColor={colors.gray.secondary}
        onPress={handleClose}
        isDisabled={submitting}
      >
        Cancel
      </SmallModal.Button>
      <SmallModal.Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        isSubmitting={submitting}
        onPress={handleSubmit}
        isDisabled={submitting}
      >
        <Icon source={Icon.Check} color={colors.white} size={12} />
        <Space width={4} />
        Save
      </SmallModal.Button>
    </SmallModal.Footer>
  );
};

const NewCompensationEntryModal = ({
  project,
  setCostId,
  handleOpenEditCostCostItemsModal,
  refetch,
  isOpen,
  handleClose,
}) => {
  const costForm = CostForm.new();
  const {form, submitting, handleSubmit} = useCreateCostMutation({
    costForm,
    onSuccess: ({cost}) => {
      handleClose();
      // Open EditCostCostItemsModal and show new cost
      setCostId(cost.id);
      handleOpenEditCostCostItemsModal();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose} style={{overflow: 'visible'}}>
      <NewCompensationEntryHeader />
      <Space height={4} />
      <NewCompensationEntryFields form={form} field={'costForm'} project={project} />
      <Space height={16} />
      <NewCompensationEntryFooter
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NewCompensationEntryModal.fragment = gql`
  ${NewCompensationEntryFields.fragment}

  fragment NewCompensationEntryModal on Project {
    id
    ...NewCompensationEntryFields
  }
`;

export default NewCompensationEntryModal;
