// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';

const useUpdateWorkflowMutation = ({workflowForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      workflowForm: WorkflowForm.toForm(workflowForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateWorkflowMutation.mutation,
    variables: {
      workflowForm: WorkflowForm.toMutation(form.values.workflowForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateWorkflowMutation.mutation = gql`
  mutation useUpdateWorkflowMutation($workflowForm: WorkflowForm!) {
    response: updateWorkflow(workflowForm: $workflowForm) {
      ${gql.errors}
      workflow {
        id
      }
    }
  }
`;

export default useUpdateWorkflowMutation;
