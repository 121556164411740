// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CommercialEquipmentForm from '@shared/modules/CommercialCatalog/forms/CommercialEquipmentForm';

const useCreateCommercialEquipmentMutation = ({
  commercialEquipmentForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      commercialEquipmentForm: CommercialEquipmentForm.toForm(commercialEquipmentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateCommercialEquipmentMutation.mutation,
    variables: {
      commercialEquipmentForm: CommercialEquipmentForm.toMutation(
        form.values.commercialEquipmentForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateCommercialEquipmentMutation.mutation = gql`
  mutation useCreateCommercialEquipmentMutation($commercialEquipmentForm: CommercialEquipmentForm!) {
    response: createCommercialEquipment(commercialEquipmentForm: $commercialEquipmentForm) {
      ${gql.errors}
      commercialEquipment {
        id
      }
    }
  }
`;

export default useCreateCommercialEquipmentMutation;
