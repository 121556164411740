// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Typography} from '@supermove/styles';

// Components
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  z-index: ${({index = 0}) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 15)}px;
`;

const CheckboxText = Styled.Text`
  ${Typography.Body}
`;

const LabelText = Styled.Text`
  ${Typography.Body}
`;

const AdditionaItemField = ({name, label, type, input, form, style}) => {
  switch (type) {
    case 'checkbox':
      return (
        <Container style={{flex: 1}}>
          <Checkbox
            isChecked={_.get(form.values, name)}
            handleToggle={(value) => form.setFieldValue(name, value)}
            childrenRight
          >
            <Space width={8} />
            <CheckboxText>{label}</CheckboxText>
          </Checkbox>
        </Container>
      );
    case 'text':
      return (
        <Container style={{flex: 1}}>
          <Row>
            <LabelText>{label}</LabelText>
          </Row>
          <Space height={4} />
          <FieldInput.Memoized {...form} name={name} input={input} style={style} />
        </Container>
      );
    default:
      return null;
  }
};

const getFieldName = ({field, name}) => {
  return field ? `${field}.${name}` : name;
};

const JobAdditionalItemsSection = ({disabled, field, form, responsive}) => (
  <Container>
    {_.map(form.values.jobFormAdditionalItems, (value, item, index) => {
      if (!value) {
        return null;
      }

      const input = _.get(value, 'input', {});
      const multiline = input.multiline || false;
      const name = getFieldName({field, name: `additionalItems.${item}`});

      return (
        <React.Fragment key={item}>
          <Row {...responsive}>
            <AdditionaItemField
              disabled={disabled}
              name={name}
              label={_.get(value, 'label')}
              type={_.get(value, 'type')}
              input={{
                ...input,
                disabled,
                style: {
                  ...(multiline ? {paddingVertical: 9, minHeight: 60} : {}),
                  flex: 1,
                },
              }}
              style={{flex: 1}}
              form={form}
            />
          </Row>
          <RowSpace {...responsive} />
        </React.Fragment>
      );
    })}
  </Container>
);

export default JobAdditionalItemsSection;
