// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import Line from 'modules/App/components/Line';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';

const Container = Styled.View`
  width: 100%;
`;

const CommentInputContainer = Styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 16px;
`;

const IconButton = Styled.ButtonV2`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${({isDisabled}) =>
    isDisabled ? colors.gray.disabled : colors.blue.interactive}
  align-items: center;
  justify-content: center;
`;

const ProjectCommentInput = ({form, handleSubmit}) => {
  const responsive = useResponsive();
  return (
    <Container>
      <CommentInputContainer>
        <FieldInput
          {...form}
          showErrorMessage={false}
          name={'commentForm.subject'}
          style={{flex: 1}}
          handleCommandEnter={handleSubmit}
          isResponsive
          input={{
            placeholder: 'Subject',
            style: {
              borderWidth: 0,
              paddingHorizontal: 0,
              paddingTop: 16,
              paddingBottom: 12,
              height: 'auto',
            },
          }}
        />
      </CommentInputContainer>
      <Line style={{paddingHorizontal: 16}} />
      <CommentInputContainer>
        <ResponsiveTextInput
          form={form}
          field={'commentForm.body'}
          minHeight={56}
          paddingVertical={12}
          handleCommandEnter={handleSubmit}
          isResponsive
          input={{
            name: 'commentForm.body',
            style: {
              fontWeight: '500',
              width: '100%',
              borderWidth: 0,
              paddingHorizontal: 0,
              paddingTop: 12,
              paddingBottom: 12,
            },
            placeholder: 'Add comment',
          }}
        />
        <Space width={12} />
        <IconButton
          isDisabled={_.get(form.values, 'commentForm.subject') === ''}
          onPress={handleSubmit}
          {...responsive}
        >
          <Icon source={Icon.ArrowUp} size={responsive.desktop ? 12 : 16} color={colors.white} />
        </IconButton>
      </CommentInputContainer>
    </Container>
  );
};

export default ProjectCommentInput;
