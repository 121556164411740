// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (slotType) => ({
    slotTypeId: (slotType as any).id,
    index: (slotType as any).index,
    driverId: (slotType as any).driverId,
    organizationId: (slotType as any).organizationId,
    truckId: (slotType as any).truckId,
  }),
  gql`
    fragment SlotTypeForm_edit on SlotType {
      id
      index
      driverId
      organizationId
      truckId
    }
  `,
);

const _new = ({organizationId, index}: any) => ({
  index,
  truckId: undefined,
  driverId: undefined,
  slotTypeId: undefined,
  organizationId,
});

const toForm = ({index, truckId, driverId, slotTypeId, organizationId}: any) => {
  return {
    index,
    truckId,
    driverId,
    slotTypeId,
    organizationId,
  };
};

const toMutation = ({index, truckId, driverId, slotTypeId, organizationId}: any) => {
  return {
    index,
    truckId,
    driverId,
    slotTypeId,
    organizationId,
  };
};

const SlotTypeForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default SlotTypeForm;
