// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import DashboardForm from '@shared/modules/Reports/forms/DashboardForm';

type DashboardResponse = {
  id: string;
  name: string;
  uuid: string;
};

const useUpdateDashboardMutation = ({
  dashboardForm,
  onSuccess,
  onError,
}: {
  dashboardForm: ReturnType<typeof DashboardForm.new> | ReturnType<typeof DashboardForm.edit>;
  onSuccess: (data: {dashboard: DashboardResponse}) => void;
  onError: (errors: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      dashboardForm: DashboardForm.toForm(dashboardForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateDashboardMutation.mutation,
    variables: {
      dashboardForm: DashboardForm.toMutation(form.values.dashboardForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateDashboardMutation.mutation = gql`
  mutation useUpdateDashboardMutation($dashboardForm: DashboardForm!) {
    response: updateDashboard(dashboardForm: $dashboardForm) {
      ${gql.errors}
      dashboard {
        id
        name
        uuid
      }
    }
  }
`;

export default useUpdateDashboardMutation;
