const _new = (projectId: any) => ({
  projectId,
  containerIds: [],
});

const toForm = ({projectId, containerIds}: any) => ({
  projectId,
  containerIds,
});

const toMutation = ({projectId, containerIds}: any) => {
  return {
    projectId,
    containerIds,
  };
};

const RemoveContainersFromProjectForm = {
  new: _new,
  toForm,
  toMutation,
};

export default RemoveContainersFromProjectForm;
