// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import DeleteTimesheetBlockForm from '@shared/modules/Timesheet/forms/DeleteTimesheetBlockForm';
import UpdateTimesheetBlockForm from '@shared/modules/Timesheet/forms/UpdateTimesheetBlockForm';

const edit = withFragment(
  (timesheetBillableEntry, {timesheetBlocksUpdate, isFixedRangeFrom, isFixedRangeTo}) => {
    return {
      timesheetBillableEntryId: (timesheetBillableEntry as any).id,
      timesheetBlocksUpdate: timesheetBlocksUpdate
        ? timesheetBlocksUpdate.map((form: any) =>
            UpdateTimesheetBlockForm.edit(form, {isFixedRangeFrom, isFixedRangeTo}),
          )
        : [],
      timesheetBlocksDelete: [],
    };
  },
  gql`
    ${UpdateTimesheetBlockForm.edit.fragment}
    fragment UpdateTimesheetBillableEntryForm_edit on TimesheetBillableEntry {
      id
      timesheetBlocks {
        id
        ...UpdateTimesheetBlockForm_edit
      }
    }
  `,
);

const toForm = ({timesheetBillableEntryId, timesheetBlocksUpdate, timesheetBlocksDelete}: any) => ({
  timesheetBillableEntryId,
  timesheetBlocksUpdate: timesheetBlocksUpdate.map(UpdateTimesheetBlockForm.toForm),
  timesheetBlocksDelete,
});

const toMutation = ({
  timesheetBillableEntryId,
  timesheetBlocksUpdate,
  timesheetBlocksDelete,
}: any) => ({
  timesheetBillableEntryId,
  timesheetBlocksUpdate: timesheetBlocksUpdate.map(UpdateTimesheetBlockForm.toMutation),
  timesheetBlocksDelete: timesheetBlocksDelete.map(DeleteTimesheetBlockForm.toMutation),
});

const UpdateTimesheetBillableEntryForm = {
  edit,
  toForm,
  toMutation,
};

export default UpdateTimesheetBillableEntryForm;
