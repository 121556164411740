// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (jobUser) => ({
    jobUserId: (jobUser as any).id,
    jobId: (jobUser as any).jobId,
    crewId: (jobUser as any).crewId,
    userId: (jobUser as any).userId,
    position: (jobUser as any).position,
    branchCode: (jobUser as any).branchCode,
    moverPositionIds: (jobUser as any).jobUserMoverPositions.map(
      (jobUserMoverPosition: any) => jobUserMoverPosition.moverPositionId,
    ),
    primaryMoverPositionId: (jobUser as any).jobUserMoverPositions.find(
      (jobUserMoverPosition: any) => jobUserMoverPosition.isPrimary,
    )?.moverPositionId,
    // Only for rendering.
    firstName: (jobUser as any).user.firstName,
  }),
  gql`
    fragment JobUserForm_edit on JobUser {
      id
      jobId
      crewId
      userId
      position
      branchCode
      user {
        id
        firstName
      }
      jobUserMoverPositions {
        id
        isPrimary
        moverPositionId
      }
    }
  `,
);

const _new = ({
  jobId,
  crewId,
  userId,
  firstName,
  position,
  branchCode,
  moverPositionIds,
  primaryMoverPositionId,
}: any) => ({
  jobUserId: null,
  jobId,
  crewId,
  userId,
  position,
  branchCode,
  moverPositionIds,
  primaryMoverPositionId,

  // Only for rendering.
  firstName,
});

const toForm = ({
  jobUserId,
  jobId,
  crewId,
  userId,
  firstName,
  position,
  branchCode,
  moverPositionIds,
  primaryMoverPositionId,
}: any) => ({
  jobUserId,
  jobId,
  crewId,
  userId,
  position,
  branchCode,
  moverPositionIds,
  primaryMoverPositionId,

  // Only for rendering.
  firstName,
});

const toMutation = ({
  jobUserId,
  jobId,
  crewId,
  userId,
  position,
  branchCode,
  moverPositionIds,
  primaryMoverPositionId,
}: any) => ({
  jobUserId,
  jobId,
  crewId,
  userId,
  position,
  branchCode,
  moverPositionIds,
  primaryMoverPositionId,
});

const JobUserForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default JobUserForm;
