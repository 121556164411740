// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({kind, inventoryLibraryId}: any) => ({
  itemTagId: undefined,
  inventoryLibraryId,
  kind,
  name: '',
  shortCode: '',
  isDeleted: false,

  // private
  isDirty: true,
});

const edit = withFragment(
  (itemTag) => ({
    itemTagId: (itemTag as any).id,
    inventoryLibraryId: (itemTag as any).inventoryLibraryId,
    kind: (itemTag as any).kind,
    name: (itemTag as any).name,
    shortCode: (itemTag as any).shortCode,
    isDeleted: (itemTag as any).isDeleted,
    // private
    isDirty: false,
  }),
  gql`
    fragment ItemTagForm_edit on ItemTag {
      id
      inventoryLibraryId
      kind
      name
      shortCode
      isDeleted
    }
  `,
);

const toForm = ({
  itemTagId,
  inventoryLibraryId,
  kind,
  name,
  shortCode,
  isDeleted,

  // private
  isDirty,
}: any) => ({
  itemTagId,
  inventoryLibraryId,
  kind,
  name,
  shortCode,
  isDeleted,

  // private
  isDirty,
});

const toMutation = ({itemTagId, inventoryLibraryId, kind, name, shortCode, isDeleted}: any) => ({
  itemTagId,
  inventoryLibraryId,
  kind,
  name,
  shortCode,
  isDeleted,
});

const ItemTagForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ItemTagForm;
