// Supermove
import {gql} from '@supermove/graphql';
import {useForm} from '@supermove/hooks';

// App
import BookingFiltersForm from '@shared/modules/Booking/forms/BookingFiltersForm';

const useBookingFiltersForm = ({viewerOrganization, selectedOrganizationSlug}: any) => {
  const form = useForm({
    // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type 'FormikVa... Remove this comment to see the full error message
    initialValues: BookingFiltersForm.new({
      organization: viewerOrganization,
      selectedOrganizationSlug,
    }),
  });

  return form;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useBookingFiltersForm.fragment = gql`
  ${BookingFiltersForm.new.fragment}

  fragment useBookingFiltersForm on Organization {
    id
    ...BookingFiltersForm_new
  }
`;

export default useBookingFiltersForm;
