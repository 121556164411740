// Libraries
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Line from 'modules/App/components/Line';
import ProjectEmailFields from 'modules/Email/Project/components/ProjectEmailFields';
import EmailAttachmentsSelector from 'modules/File/Attachment/components/EmailAttachmentsSelector';
import useSendEmailForJobForm from 'modules/Job/Email/logic/useSendEmailForJobForm';

const Section = Styled.View`
  padding-horizontal: ${(props) => (props.mobile ? 20 : 30)}px;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const Button = Styled.LoadingButton`
  height: 30px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const Row = Styled.View`
`;

const SendEmailForJobContent = ({job, viewerId, handleClose}) => {
  const responsive = useResponsive();
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);

  const {form, submitting, handleSubmit} = useSendEmailForJobForm({
    job,
    viewerId,
    onSuccess: handleClose,
    onError: (errors) => console.log({errors}),
  });

  const {emailForm} = form.values;
  return (
    <React.Fragment>
      <Line />
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 40,
        }}
      >
        <Section sectionIndex={0} {...responsive}>
          <Space height={24} />
          <ProjectEmailFields
            field={'emailForm'}
            form={form}
            viewerId={viewerId}
            project={job.project}
            setIsGeneratingPreview={setIsGeneratingPreview}
          />
          <Space height={10} />
          <Row>
            <EmailAttachmentsSelector form={form} project={job.project} name={'emailForm'} />
          </Row>
        </Section>
      </ScrollView>
      <Line />
      <Section
        sectionIndex={1}
        style={{
          paddingVertical: 20,
        }}
        {...responsive}
      >
        <Button
          disabled={!(emailForm.subject && emailForm.body) || isGeneratingPreview}
          loading={submitting}
          onPress={handleSubmit}
        >
          <Text>Send</Text>
        </Button>
      </Section>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendEmailForJobContent.fragment = gql`
  ${EmailAttachmentsSelector.fragment}
  ${ProjectEmailFields.fragment}
  ${useSendEmailForJobForm.fragment}

  fragment SendEmailForJobContent on Job {
    id
    uuid
    organization {
      id
      slug
    }
    project {
      id
      ...EmailAttachmentsSelector
      ...ProjectEmailFields
    }
    ...useSendEmailForJobForm
  }
`;

export default SendEmailForJobContent;
