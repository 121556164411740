// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Sidebar, Styled, Loading, Space, RadioInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, useState} from '@supermove/hooks';
import {Location, Container} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ContainerLocationKind from '@shared/modules/Storage/enums/ContainerLocationKind';
import AssignCollectionsToContainerForm from '@shared/modules/Storage/forms/AssignCollectionsToContainerForm';
import useAssignCollectionsToContainerMutation from '@shared/modules/Storage/hooks/useAssignCollectionsToContainerMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import TextBadge from 'modules/App/components/TextBadge';

const SidebarContainer = Styled.View`
  flex: 1;
  background-color: ${colors.white}
  width: 500px;
  max-height: 48px;
  padding-horizontal: 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.border};
`;

const ContentContainer = Styled.View`
  flex: 1;
  width: 500px;
  background-color: ${colors.white};
`;

const FooterContainer = Styled.View`
  flex: 1;
  background-color: ${colors.white}
  width: 500px;
  max-height: 64px;
  padding: 12px;
  flex-direction: row;
  justify-content: flex-end;
`;

const StorageAssignContainerTitle = Styled.Text`
  ${Typography.Label1}
  text-align: left;
`;

const BodyText = Styled.Text`
  ${Typography.Body3}
  text-align: left;
  margin-vertical: auto;
`;

const GreyBodyText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.tertiary}
  text-align: left;
  margin-vertical: auto;
  font-style: italic;
`;

const ContainerItemWrapper = Styled.ButtonV2`
  padding: 12px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const BlankContainer = Styled.View`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content:center;
`;

const BlankContainerText = Styled.Text`
  ${Typography.Heading6};
  color: ${colors.gray.tertiary};
`;

const ContainerNameContainer = Styled.View`
  width: 160px;
`;

const ScrollViewContainer = Styled.View`
  flex: 1;
`;

const ContainerItem = ({
  form,
  fieldName,
  container,
  index,
  selectedContainerIndex,
  setSelectedContainerIndex,
}) => {
  const selectContainer = () => {
    form.setFieldValue(`${fieldName}.containerId`, container.id);
    setSelectedContainerIndex(index);
  };

  return (
    <ContainerItemWrapper onPress={selectContainer}>
      <Row>
        <RadioInput
          disabled={false}
          isSelected={selectedContainerIndex === index}
          color={colors.blue.interactive}
          circleColor={colors.blue.interactive}
          onSelect={selectContainer}
        />
        <ContainerNameContainer>
          <BodyText numberOfLines={1}>{container.name || '-'}</BodyText>
        </ContainerNameContainer>
        <Space style={{flex: 1}} />
        {_.isEmpty(container.collectionVersions) ? (
          <TextBadge isLabel text={'Empty'} color={colors.green.status} />
        ) : (
          <TextBadge isLabel text={'Occupied'} color={colors.yellow.hover} />
        )}
      </Row>
      <Row>
        <Space width={34} />
        {container.locationKind === ContainerLocationKind.CUSTOM ? (
          <GreyBodyText numberOfLines={1}>
            {container.customLocation ? Location.getDisplayLocation(container.customLocation) : '-'}
          </GreyBodyText>
        ) : (
          <GreyBodyText numberOfLines={1}>
            {Container.getDisplayCoordinates(container)}
          </GreyBodyText>
        )}
      </Row>
    </ContainerItemWrapper>
  );
};

const StorageAssignContainerSidebarContent = ({
  isOpen,
  handleClose,
  containers,
  selectedContainerIndex,
  setSelectedContainerIndex,
  selectedCollections,
  refetch,
}) => {
  const assignCollectionsToContainerForm =
    AssignCollectionsToContainerForm.new(selectedCollections);
  const fieldName = 'assignCollectionsToContainerForm';
  const {form, handleSubmit} = useAssignCollectionsToContainerMutation({
    assignCollectionsToContainerForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <Sidebar.Content
      isOpen={isOpen}
      onClose={handleClose}
      position={'right'}
      shouldCloseOnClickOutside
    >
      <SidebarContainer>
        <Space height={12} />
        <StorageAssignContainerTitle>Assign to Container</StorageAssignContainerTitle>
        <Space height={12} />
      </SidebarContainer>
      <ContentContainer>
        {_.isEmpty(containers) ? (
          <BlankContainer>
            <BlankContainerText>No containers to display</BlankContainerText>
          </BlankContainer>
        ) : (
          <ScrollView>
            <ScrollViewContainer>
              {containers.map((container, index) => (
                <ContainerItem
                  key={index}
                  index={index}
                  selectedContainerIndex={selectedContainerIndex}
                  container={container}
                  setSelectedContainerIndex={setSelectedContainerIndex}
                  form={form}
                  fieldName={fieldName}
                />
              ))}
            </ScrollViewContainer>
          </ScrollView>
        )}
      </ContentContainer>
      <FooterContainer>
        <TertiaryButton textColor={colors.gray.secondary} text={'Cancel'} onPress={handleClose} />
        <Space width={16} />
        <Button text={'Assign'} onPress={handleSubmit} />
      </FooterContainer>
    </Sidebar.Content>
  );
};

const StorageAssignContainerSidebar = ({isOpen, handleClose, refetch, selectedCollections}) => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(StorageAssignContainerSidebar.query, {
    fetchPolicy: 'cache-and-network',
    // TODO(kevin): add in search bar
    variables: {
      searchQuery: params.query,
      pagination: {
        page: 1,
        resultsPerPage: 100,
      },
    },
  });

  const [selectedContainerIndex, setSelectedContainerIndex] = useState();

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => (
        <StorageAssignContainerSidebarContent
          isOpen={isOpen}
          handleClose={handleClose}
          containers={data.paginatedList.containers}
          refetch={refetch}
          selectedContainerIndex={selectedContainerIndex}
          setSelectedContainerIndex={setSelectedContainerIndex}
          selectedCollections={selectedCollections}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageAssignContainerSidebar.query = gql`
  ${Location.getDisplayLocation.fragment}
  ${Container.getDisplayCoordinates.fragment}

  query StorageAssignContainerSidebar(
    $pagination: PaginationInput!
    $searchQuery: String
  ) {
    ${gql.query}
    paginatedList: filteredContainersPaginatedList(
      pagination: $pagination
      searchQuery: $searchQuery
    ) {
      containers: results {
        id
        name
        locationKind
        customLocation {
          id
          ...Location_getDisplayLocation
        }
        collectionVersions {
          id
        }
        ...Container_getDisplayCoordinates
      }
    }
  }
`;

export default StorageAssignContainerSidebar;
