// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  flex: 1;
  background-color: ${colors.white};
`;

const Overlay = Styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(47, 54, 64, 0.4);
`;

const Photo = Styled.Image`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const SplitPhoto = ({source}) => (
  <Container>
    <Content>
      <Photo resizeMode={'cover'} source={source} />
      <Overlay />
    </Content>
  </Container>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
SplitPhoto.propTypes = {};

SplitPhoto.defaultProps = {};

export default SplitPhoto;
