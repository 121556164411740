// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, ResponsiveType} from '@supermove/hooks';
import {Phone} from '@supermove/utils';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import UserSettingsPageWrapper from 'modules/User/Settings/components/UserSettingsPageWrapper';

const Content = Styled.View`
  padding-horizontal: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? 0 : 16)}px;
`;

const GeneralPanelDisplay = ({user}: {user: any}) => {
  return (
    <React.Fragment>
      <FieldValue label={'Name'} value={user.fullName} empty={'None'} />
      <Space height={16} />
      <FieldValue label={'Email'} value={user.email} empty={'None'} />
      <Space height={16} />
      <FieldValue
        label={'Phone Number'}
        value={user.phoneNumber ? Phone.display(user.phoneNumber) : null}
        empty={'None'}
      />
    </React.Fragment>
  );
};

const GeneralPanel = ({user}: {user: any}) => {
  return (
    <ActionPanel
      title={'General'}
      BodyComponent={GeneralPanelDisplay}
      bodyComponentProps={{user}}
    />
  );
};

const ProfileContent = ({viewer}: any) => {
  const responsive = useResponsive();
  return (
    <Content responsive={responsive}>
      <GeneralPanel user={viewer} />
    </Content>
  );
};

const UserSettingsProfilePersonalPage = () => {
  const {loading, data} = useQuery(UserSettingsProfilePersonalPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {},
  });

  return (
    <UserSettingsPageWrapper
      selectedLabel={'Profile/Personal Information'}
      pageTitle={'Personal Information'}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => <ProfileContent viewer={data.viewer} />}
      </Loading>
    </UserSettingsPageWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserSettingsProfilePersonalPage.query = gql`
  query UserSettingsProfilePersonalPage {
    ${gql.query}
    viewer {
      id
      fullName
      email
      phoneNumber
    }
  }
`;

export default UserSettingsProfilePersonalPage;
