// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DragAndDropList, Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDragAndDrop, useSearch, useState} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import AddBillBillRulePopover from 'modules/Project/Billing/components/AddBillBillRulePopover';
import EditBillBillRulesListItem from 'modules/Project/Billing/components/EditBillBillRulesListItem';
import EditBillLineItemCells from 'modules/Project/Billing/components/EditBillLineItemCells';

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  padding-left: 21.5px;
  padding-right: 19px;
`;

const LineItemsListContainer = Styled.View`
  padding-left: 20px;
  flex: 1;
`;

const ColumnLabelText = Styled.H7`
  color: ${colors.gray.tertiary};
  ${fontWeight(500)}
`;

const SearchInputContainer = Styled.View`
  height: 36px;
  width: 716px;
  border-color: ${colors.gray.border};
  border-radius: 0px;
  border-left-width: 1px;
  border-top-width: ${(props) => props.borderTop}px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  justify-content: center;
`;

const SearchInput = Styled.TextInput`
  border-radius: 0px;
  border-width: 0px;
  ${fontWeight(500)}
  padding-left: 12px;
  position: absolute;
  background-color: transparent;
  height: 34px;
  width: 714px;
`;

const SearchPlaceholderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SearchPlaceholderText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.tertiary};
`;

const swapBillRules = ({form, field, fromIndex, toIndex}) => {
  const reorderedBillRules = List.move({
    list: _.get(form.values, field),
    fromIndex,
    toIndex,
  });
  form.setFieldValue(field, reorderedBillRules);
};

const ListHeader = () => {
  return (
    <Row>
      <EditBillLineItemCells.IconCell height={19} />
      <Space width={2} />
      <EditBillLineItemCells.LeftDataCell height={19} isLarge>
        <ColumnLabelText>Kind/Rule/Description</ColumnLabelText>
      </EditBillLineItemCells.LeftDataCell>
      <EditBillLineItemCells.DataCell height={19}>
        <ColumnLabelText>Value</ColumnLabelText>
      </EditBillLineItemCells.DataCell>
      <EditBillLineItemCells.RightDataCell height={19}>
        <ColumnLabelText>Show customer?</ColumnLabelText>
      </EditBillLineItemCells.RightDataCell>
      <Space width={2} />
      <EditBillLineItemCells.IconCell height={19} />
    </Row>
  );
};

const BillRulesSearch = ({bill, form, addBillBillRulePopover, handleScrollToEnd}) => {
  const [isSearching, setIsSearching] = useState(false);
  const {
    setQuery,
    query,
    results: billRuleTypes,
  } = useSearch({
    initialQuery: '',
    items: bill.project.projectType.billingLibrary.billRuleTypes,
    options: {keys: ['name']},
  });

  return (
    <Container>
      <Popover.Content innerRef={addBillBillRulePopover.ref}>
        <SearchInputContainer borderTop={form.values.billForm.billRuleForms.length > 0 ? 0 : 1}>
          {!query && (
            <SearchPlaceholderContainer>
              <Space width={12} />
              {!isSearching && (
                <React.Fragment>
                  <Icon source={Icon.Search} size={12} color={colors.gray.tertiary} />
                  <Space width={8} />
                </React.Fragment>
              )}
              <SearchPlaceholderText>
                Add rules from library or add a custom rule
              </SearchPlaceholderText>
            </SearchPlaceholderContainer>
          )}
          <SearchInput
            onFocus={() => {
              setIsSearching(true);
              addBillBillRulePopover.handleOpen();
            }}
            onChangeText={setQuery}
            value={query}
          />
        </SearchInputContainer>
      </Popover.Content>
      <AddBillBillRulePopover
        addBillBillRulePopover={addBillBillRulePopover}
        billRuleTypes={billRuleTypes}
        form={form}
        query={query}
        setQuery={setQuery}
        setIsSearching={setIsSearching}
        handleScrollToEnd={handleScrollToEnd}
      />
    </Container>
  );
};

const EditBillBillRulesList = ({form, bill, addBillBillRulePopover}) => {
  const {isReordering, handleReorderStart, handleReorderEnd, ref, handleScrollToEnd} =
    useDragAndDrop();
  const field = 'billForm.billRuleForms';
  return (
    <LineItemsListContainer>
      <ListHeader />
      <Space height={8} />
      <DragAndDropList
        isReordering={isReordering}
        onReorder={({fromIndex, toIndex}) => {
          handleReorderStart();
          swapBillRules({form, field, fromIndex, toIndex});
          handleReorderEnd();
        }}
        scrollRef={ref}
      >
        {form.values.billForm.billRuleForms.map((billRuleForm, index) => {
          const conditionalBillItem =
            billRuleForm.billItemId &&
            _.find(
              bill.billItems,
              (billItem) => _.toString(billItem.id) === _.toString(billRuleForm.billItemId),
            );
          return (
            <EditBillBillRulesListItem
              key={`${index}_${billRuleForm.name}`}
              form={form}
              billRuleForm={billRuleForm}
              index={index}
              conditionalBillItemName={conditionalBillItem?.name || ''}
            />
          );
        })}
      </DragAndDropList>
      <BillRulesSearch
        bill={bill}
        form={form}
        addBillBillRulePopover={addBillBillRulePopover}
        handleScrollToEnd={handleScrollToEnd}
      />
      <Space height={16} />
    </LineItemsListContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillBillRulesList.fragment = gql`
  ${EditBillBillRulesListItem.fragment}
  ${AddBillBillRulePopover.fragment}
  fragment EditBillBillRulesListV2 on Bill {
    id
    billRules {
      ...EditBillBillRulesListItem
    }
    billItems {
      id
      name
    }
    project {
      id
      projectType {
        id
        billingLibrary {
          id
          billRuleTypes {
            id
            name
            ...AddBillBillRulePopover
          }
        }
      }
    }
  }
`;

export default EditBillBillRulesList;
