// Libraries
import React from 'react';

// App
import {Modal, ScrollView, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, typography} from '@supermove/styles';

// Components
import {Navigation} from 'modules/App/components';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = Styled.View`
  flex: 1;
  width: 100%;
  background-color: ${colors.gray.background};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
`;

const HeaderMainSection = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const HeaderBorderSection = Styled.View`
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.mobile ? 40 : 60)}px;
`;

const Title = Styled.H5`
  ${({vars}) => (vars.mobile ? typography.h6() : '')}
  color: ${colors.gray.primary};
`;

const Content = Styled.View`
  width: 792px;
  padding-vertical: 36px;
  padding-horizontal: 36px;
  background-color: ${colors.white};
`;

/*
 * The `<Content>` wrapper around `children` simulates the
 * margin of a printed PDF. We cannot simply put padding
 * within the documents (`children` in this case) themselves
 * because such padding will only apply to the whole document,
 * not each page of the document if it spans multiple pages.
 */
const ViewDocumentModal = ({children, title, trigger}) => {
  const responsive = useResponsive();
  return (
    <Modal trigger={trigger}>
      {({handleClose}) => (
        <Overlay pointerEvents={'box-none'}>
          <Wrapper {...responsive}>
            <Header>
              <HeaderBorderSection {...responsive}>
                <Navigation.CloseButton responsive={responsive} onPress={handleClose} />
              </HeaderBorderSection>
              <HeaderMainSection>
                <Title vars={responsive}>{title}</Title>
              </HeaderMainSection>
              <HeaderBorderSection />
            </Header>
            <ScrollView
              contentContainerStyle={{
                alignItems: 'center',
              }}
              style={{
                flex: 1,
                width: '100%',
              }}
            >
              <ScrollView
                contentContainerStyle={{
                  justifyContent: 'center',
                  paddingTop: responsive.mobile ? 0 : 40,
                  paddingBottom: responsive.mobile ? 0 : 200,
                }}
                horizontal
                style={{
                  width: responsive.mobile ? '100%' : 'default',
                }}
              >
                <Content>{children}</Content>
              </ScrollView>
            </ScrollView>
          </Wrapper>
        </Overlay>
      )}
    </Modal>
  );
};

export default ViewDocumentModal;
