// Libraries
import React from 'react';

// Supermove
import {useAnalytics} from '@supermove/analytics';
import {Styled} from '@supermove/components';
import {useCallback, useEffect} from '@supermove/hooks';

export enum EventType {
  // Built-in Explo events: https://docs.explo.co/general-features/custom-js-events#available-events
  SendVariableUpdated = 'sendVariableUpdatedEvent',
  DashboardReadyToLoad = 'dashboardReadyToLoad',
  DashboardLoaded = 'dashboardLoaded',
}

export interface SendVariableUpdated {
  event: EventType.SendVariableUpdated;
  detail: {
    varName: string;
    newValue: any;
  };
}

export interface DashboardReadyToLoad {
  event: EventType.DashboardReadyToLoad;
}

export interface DashboardLoaded {
  event: EventType.DashboardLoaded;
}

export type DashboardEvent = SendVariableUpdated | DashboardReadyToLoad | DashboardLoaded;

const Container = Styled.View`
  flex: 1;
`;

const ExploDashboard = ({
  viewerId,
  name,
  exploDashboardEmbedId,
  exploUserGroupToken,
  dashboardId = undefined,
  exploVariables = '{}',
  style = {},
  onMessage = () => {},
}: {
  viewerId: number;
  name: string;
  exploDashboardEmbedId: string;
  exploUserGroupToken: string;
  dashboardId?: number;
  exploVariables?: string;
  style?: React.CSSProperties;
  onMessage?: (event: DashboardEvent) => void;
}) => {
  const analytics = useAnalytics();
  useEffect(() => {
    analytics.track({
      event: 'Viewed Explo Dashboard Embed',
      data: {
        Type: 'Dashboard View',
        URL: window.location.pathname,
        DashboardName: name,
        DashboardId: exploDashboardEmbedId,
      },
    });
  }, [analytics, name, exploDashboardEmbedId]);

  const listener = useCallback(
    (event: MessageEvent<DashboardEvent>): void => {
      if (event.origin !== 'https://app.explo.co') {
        return;
      }
      const {data} = event;
      if (!data) {
        return;
      }
      onMessage(data);
    },
    [onMessage],
  );

  useEffect(() => {
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, [listener]);

  const searchParams = new URLSearchParams({
    dashboard_id: `${dashboardId}`,
    viewer_id: `${viewerId}`,
    ...asSearchParams(exploVariables),
  });
  const exploUrl = new URL(
    `https://app.explo.co/iframe/${exploDashboardEmbedId}/${exploUserGroupToken}/production?${searchParams}`,
  );
  return (
    <Container style={style}>
      <iframe
        title={'Explo Dashboard'}
        src={exploUrl.toString()}
        style={{
          flex: 1,
          borderWidth: 0,
        }}
      />
    </Container>
  );
};

const asSearchParams = (exploVariables?: string): Record<string, string> => {
  const variables: Record<string, string> = {};
  for (const [key, value] of Object.entries(JSON.parse(exploVariables || '{}'))) {
    variables[key] = JSON.stringify(value);
  }
  return variables;
};

/**
 * Encodes a string in base64url encoding: https://datatracker.ietf.org/doc/html/rfc4648#section-5
 */
export const encodeBase64Url = (data: string): string => {
  const bytes = new TextEncoder().encode(data);
  const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join('');
  const base64 = btoa(binString);
  return base64.replaceAll('+', '-').replaceAll('/', '_');
};

/**
 * Decodes a base64url-encoded string: https://datatracker.ietf.org/doc/html/rfc4648#section-5
 */
export const decodeBase64Url = (base64url: string): string | null => {
  if (!base64url) {
    return null;
  }
  const base64 = decodeURIComponent(base64url).replaceAll('-', '+').replaceAll('_', '/');
  const decodedData = atob(base64);
  return new TextDecoder().decode(Uint8Array.from(decodedData, (m) => m.codePointAt(0) as number));
};

export default ExploDashboard;
