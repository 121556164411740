// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';

// App
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';
import EditProjectBlockWrapper from 'modules/Project/V2/Edit/components/EditProjectBlockWrapper';
import MobileEditJobBlock from 'modules/Project/V2/Edit/components/MobileEditJobBlock';

const NotesInput = ({form, notesField, autoFocus, label, placeholder, isDisabled}) => {
  return (
    <ResponsiveTextInput.Memoized
      form={form}
      field={notesField}
      autoFocus={autoFocus}
      label={label}
      minHeight={96}
      input={{placeholder, disabled: isDisabled}}
      isResponsive
    />
  );
};

const EditJobNotes = ({form, field, isDisabled}) => {
  const {params} = useNavigationDOM();

  return (
    <React.Fragment>
      <NotesInput
        form={form}
        notesField={`${field}.officeNotes`}
        autoFocus={params.field === 'officeNotes'}
        label={'Office Notes'}
        placeholder={'Enter notes for the office'}
        isDisabled={isDisabled}
      />
      <Space height={16} />
      <NotesInput
        form={form}
        notesField={`${field}.dispatchNotes`}
        autoFocus={params.field === 'dispatchNotes'}
        label={'Dispatch Notes'}
        placeholder={'Enter notes for dispatch'}
        isDisabled={isDisabled}
      />
      <Space height={16} />
      <NotesInput
        form={form}
        notesField={`${field}.additionalNotes`}
        label={'Crew Notes'}
        placeholder={'Enter notes for crew'}
        isDisabled={isDisabled}
      />
    </React.Fragment>
  );
};

const EditJobNotesBlock = ({form, field, index, handleSetPositionY, isDisabled}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <ActionPanel
          BodyComponent={EditJobNotes}
          bodyComponentProps={{form, field, isDisabled}}
          title={ProjectBlockKind.getDisplay(ProjectBlockKind.Job.NOTES)}
          style={{width: '100%'}}
        />
      ) : (
        <EditProjectBlockWrapper index={index} handleSetPositionY={handleSetPositionY}>
          <MobileEditJobBlock
            headerText={ProjectBlockKind.getDisplay(ProjectBlockKind.Job.NOTES)}
            responsive={responsive}
          >
            <EditJobNotes form={form} field={field} isDisabled={isDisabled} />
          </MobileEditJobBlock>
        </EditProjectBlockWrapper>
      )}
    </React.Fragment>
  );
};

export default EditJobNotesBlock;
