// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Form} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Phone} from '@supermove/utils';

// Components
import SendCustomerReportMoveReceiptMutation from './SendCustomerReportMoveReceiptMutation';

const getBody = ({job}) => {
  return [
    `Hi ${job.customer.fullName},`,
    ``,
    `It was a pleasure to move you. We have attached your move receipt below.`,
    ``,
    `Please call us anytime if you have questions, concerns, or comments.`,
    ``,
    `Thanks,`,
  ]
    .concat(getSignature({job}))
    .join('<br>');
};

const getSignature = ({job}) => {
  const {organization} = job.project;

  if (job.bookedBy) {
    return [
      `${job.bookedBy.fullName}, ${_.startCase(_.toLower(job.bookedBy.position))}`,
      `${organization.name}${
        organization.phoneNumber ? ` | ${Phone.display(organization.phoneNumber)}` : ''
      }`,
    ];
  } else {
    return [
      organization.name,
      organization.phoneNumber ? Phone.display(organization.phoneNumber) : '',
    ];
  }
};

const SendReportMoveForm = ({job, onSuccess, onError, children}) => {
  return (
    <Form
      initialValues={{
        jobId: job.id,
        body: getBody({job}),
        subject: `Your move receipt`,
        attachmentIds: [],
      }}
      onSubmit={() => {}}
    >
      {({values, submitForm, setMutationErrors, ...form}) => (
        <SendCustomerReportMoveReceiptMutation
          values={values}
          onSuccess={onSuccess}
          onError={onError}
          setMutationErrors={setMutationErrors}
          submitForm={submitForm}
        >
          {({loading, handleSubmit}) =>
            children({
              ...form,
              loading,
              values,
              handleSubmit,
            })
          }
        </SendCustomerReportMoveReceiptMutation>
      )}
    </Form>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SendReportMoveForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

SendReportMoveForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendReportMoveForm.fragment = gql`
  fragment SendReportMoveForm on Job {
    id
    startTime1
    startTime2
    project {
      id
      organization {
        id
        slug
        name
        phoneNumber
      }
    }
    day {
      id
      value
    }
    customer {
      id
      fullName
    }
    bookedBy {
      id
      fullName
      position
    }
  }
`;

export default SendReportMoveForm;
