// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, ScrollView} from '@supermove/components';
import {FilteredProjectsForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {
  useResponsive,
  useNavigationDOM,
  useQuery,
  useEffect,
  useMountEffect,
  usePagination,
  useRef,
  useForm,
} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ListStorageProjectsPageFilters from 'modules/Storage/components/ListStorageProjectsPageFilters';
import StorageProjectsList from 'modules/Storage/components/StorageProjectsList';

const Container = Styled.View`
  flex: 1;
  margin-horizontal: 24px;
`;

const Content = Styled.View`
`;

const Section = Styled.View`
  align-self: stretch;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const getVariablesFromParams = (params, organization) => {
  const {status, salespersonIds, coordinatorIds, ...rest} = params;
  // Redirect to active if no status is specified in URL
  const urlStatus = status || 'active';
  const statuses = [FilteredProjectsForm.STORAGE_URL_TO_VARIABLE_MAP[status]];

  return {
    statuses,
    pagination: PaginationBar.DEFAULT_PAGINATION,
    status: urlStatus,
    projectTypeCategory: ProjectTypeCategory.STORAGE,
    slugs: organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
    salespersonIds: URL.decodeEmptyStringToNull(salespersonIds),
    coordinatorIds: URL.decodeEmptyStringToNull(coordinatorIds),
    ...rest,
  };
};

const getUrlWithVariables = (variables) => {
  const {statuses, ...rest} = variables;

  const baseRoute = `/storage/list`;
  return URL.getUrlFromVariables(baseRoute, rest);
};

const ListStorageProjectsPageContent = ({organization}) => {
  const responsive = useResponsive();
  const ref = useRef();
  const {navigator, params} = useNavigationDOM();
  const variables = getVariablesFromParams(params, organization);
  const form = useForm({
    initialValues: {
      filteredProjectsForm: FilteredProjectsForm.toForm(variables),
    },
  });

  const {loading, data, refetch, error} = useQuery(ListStorageProjectsPageContent.query, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  useMountEffect(() => {
    // If required params are not set in the route on mount,
    // reload the page with the correct variables
    if (!params.pagination || !params.status || !params.slugs) {
      navigator.replace(getUrlWithVariables(variables));
    }
  });

  const pagination = usePagination({
    currentPage: _.toNumber(variables.pagination.page), // The URL is the single source of truth for currentPage
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page) => {
      const url = getUrlWithVariables({
        ...variables,
        pagination: {
          page,
          resultsPerPage: variables.pagination.resultsPerPage,
        },
      });
      navigator.push(url);
    },
  });

  useEffect(() => {
    const queryParams = FilteredProjectsForm.toQueryParams(form.values.filteredProjectsForm);
    if (!ref.current) {
      // Set the initial ref to avoid unnecessary URL updates
      ref.current = queryParams;
      return;
    }

    // Only push new URL when variables have changed
    if (!_.isEqual(ref.current, queryParams)) {
      ref.current = queryParams;
      const url = getUrlWithVariables({
        ...queryParams,
        pagination: PaginationBar.DEFAULT_PAGINATION,
      });
      navigator.replace(url);
    }
  }, [form, navigator, ref, variables]);

  // Only show page loading indicator on initial render
  if (loading && !data) {
    return <PageLoadingIndicator />;
  }

  return (
    <Container>
      <Space height={16} />
      <Section sectionIndex={0}>
        <Content {...responsive}>
          <ListStorageProjectsPageFilters
            form={form}
            organization={organization}
            filteredProjectCountsByStatus={data.filteredProjectCountsByStatus}
            refetch={refetch}
          />
        </Content>
      </Section>
      <ScrollView style={{flex: 1}} contentContainerStyle={{flex: 1}}>
        <ScrollView
          horizontal
          contentContainerStyle={{flexGrow: 1}}
          style={{
            borderWidth: 1,
            borderColor: colors.gray.border,
            borderRadius: 4,
          }}
        >
          <StorageProjectsList
            status={variables.status}
            loading={loading}
            refetch={refetch}
            projects={data ? data.paginatedList.projects : []}
            hasError={!!error}
            canShowBranch={
              organization.isPrimary && organization.features.isEnabledMultibranchStorage
            }
          />
        </ScrollView>
        <Space height={24} />
        <PaginationBar pagination={pagination} />
        <Space height={24} />
      </ScrollView>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListStorageProjectsPageContent.query = gql`
  ${StorageProjectsList.fragment}
  ${ListStorageProjectsPageFilters.countsFragment}
  ${usePagination.fragment}
  query ListStorageProjectsPageContent(
    $pagination: PaginationInput!,
    $statuses: [String],
    $salespersonIds: [Int],
    $coordinatorIds: [Int],
    $fromDate: String,
    $toDate: String,
    $searchQuery: String,
    $sortSettings: [ProjectSortSetting],
    $projectTypeCategory: String,
    $projectTypeIds: [Int],
    $referralSources: [String],
    $slugs: [String],
  ) {
    ${gql.query}
    filteredProjectCountsByStatus(
      salespersonIds: $salespersonIds,
      coordinatorIds: $coordinatorIds,
      fromDate: $fromDate,
      toDate: $toDate,
      searchQuery: $searchQuery,
      sortSettings: $sortSettings,
      projectTypeCategory: $projectTypeCategory,
      projectTypeIds: $projectTypeIds,
      referralSources: $referralSources,
      slugs: $slugs,
    ) {
      ...ListStorageProjectsPageFilters_countsFragment
    }
    paginatedList: filteredProjectsPaginatedList(
      pagination: $pagination,
      statuses: $statuses,
      salespersonIds: $salespersonIds,
      coordinatorIds: $coordinatorIds,
      fromDate: $fromDate,
      toDate: $toDate,
      searchQuery: $searchQuery,
      sortSettings: $sortSettings,
      projectTypeCategory: $projectTypeCategory,
      projectTypeIds: $projectTypeIds,
      referralSources: $referralSources,
      slugs: $slugs,
    ) {
      projects: results {
        id
        ...StorageProjectsList
      }
      paginationMetadata {
        ...usePagination
      }
    }
  }
`;

ListStorageProjectsPageContent.fragment = gql`
  ${ListStorageProjectsPageFilters.fragment}
  fragment ListStorageProjectsPageContent on Organization {
    id
    isPrimary
    features {
      isEnabledMultibranchStorage: isEnabled(feature: "MULTIBRANCH_STORAGE")
    }
    ...ListStorageProjectsPageFilters
  }
`;

export default ListStorageProjectsPageContent;
