// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import EmptyState from '@shared/design/components/EmptyState';
import FieldValue from '@shared/design/components/Field/FieldValue';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import RoomItemsForm from '@shared/modules/Inventory/forms/RoomItemsForm';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const RoomHeaderName = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const RoomSummaryContainer = Styled.ButtonV2`
  padding-horizontal: 32px;
  padding-vertical: 12px;
  background-color: ${colors.white};
`;

const RoomSummary = ({roomItemsForm, itemContainerWidth, onPress, style}) => {
  const responsive = useResponsive();
  const {takeCount, leaveCount, volume, weight, price} = RoomItemsForm.getInfo(roomItemsForm);
  const isStacked = itemContainerWidth < 390;
  const detailStyle = {flex: 1, paddingRight: 16, flexBasis: isStacked ? '33%' : '20%'};
  return (
    <RoomSummaryContainer style={style} onPress={onPress}>
      <RoomHeaderName responsive={responsive}>{roomItemsForm.name}</RoomHeaderName>
      <Space height={8} />
      <Row style={{flexWrap: 'wrap'}}>
        <FieldValue
          isResponsive
          size={FieldValue.SIZE.MICRO}
          label={'Est. Price'}
          value={Currency.display(price)}
          style={detailStyle}
        />
        <FieldValue
          isResponsive
          size={FieldValue.SIZE.MICRO}
          label={'Take'}
          value={takeCount || '0'}
          style={detailStyle}
        />
        <FieldValue
          isResponsive
          size={FieldValue.SIZE.MICRO}
          label={'Leave'}
          value={leaveCount || '0'}
          style={detailStyle}
        />
        {isStacked && <Space height={16} style={{flexBasis: '100%'}} />}
        <FieldValue
          isResponsive
          size={FieldValue.SIZE.MICRO}
          label={'Volume'}
          value={`${volume} cu ft`}
          style={detailStyle}
        />
        <FieldValue
          isResponsive
          size={FieldValue.SIZE.MICRO}
          label={'Weight'}
          value={`${weight} lbs`}
          style={detailStyle}
        />
        {isStacked && <Space style={detailStyle} />}
      </Row>
    </RoomSummaryContainer>
  );
};

const MobileInventoryRoomsSummary = ({
  roomItemsForms,
  itemContainerWidth,
  isTwoColumns,
  inventoryRoomsForm,
  setFilteredCategoryIds,
  setSearchTerm,
  addRoomSheet,
}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {_.isEmpty(roomItemsForms) ? (
        <React.Fragment>
          <EmptyState.ContentContainer>
            <EmptyState title={'No rooms.'} message={`Add a room and it'll appear here.`} />
            {responsive.mobile ? null : (
              <React.Fragment>
                <Space height={16} />
                <SecondaryButton
                  text={'Add Room'}
                  isSmall
                  iconRight={Icon.ChevronDown}
                  onPress={addRoomSheet.handleOpen}
                />
              </React.Fragment>
            )}
          </EmptyState.ContentContainer>
        </React.Fragment>
      ) : (
        <ScrollView
          style={{flex: 1, backgroundColor: colors.gray.background}}
          contentContainerStyle={{flexDirection: 'row', flexWrap: 'wrap'}}
        >
          {roomItemsForms.map((roomItemsForm) => {
            return (
              <RoomSummary
                key={roomItemsForm.uuid}
                roomItemsForm={roomItemsForm}
                itemContainerWidth={itemContainerWidth}
                style={{
                  flexBasis: isTwoColumns ? '50%' : '100%',
                  borderColor: colors.gray.border,
                  borderRightWidth: isTwoColumns ? 1 : 0,
                  borderBottomWidth: 1,
                }}
                onPress={() => {
                  // lookup the actual form index, since the roomItemsForms deleted rooms filtered out
                  const index = _.findIndex(
                    inventoryRoomsForm.values.inventoryRoomsForm.roomItemsForms,
                    (form) => form.uuid === roomItemsForm.uuid,
                  );
                  InventoryRoomsForm.handleSelectRoom({
                    inventoryRoomsForm,
                    index,
                    setSearchTerm,
                    setFilteredCategoryIds,
                  });
                }}
              />
            );
          })}
          {responsive.mobile ? null : (
            <Column style={{alignItems: 'center', justifyContent: 'center', flexBasis: '100%'}}>
              <Space height={16} />
              <SecondaryButton
                text={'Add Room'}
                isSmall
                iconRight={Icon.ChevronDown}
                onPress={addRoomSheet.handleOpen}
              />
              <Space height={32} />
            </Column>
          )}
        </ScrollView>
      )}
    </React.Fragment>
  );
};

export default MobileInventoryRoomsSummary;
