// Libraries
import {gql} from '@supermove/graphql';
import {Float, withFragment} from '@supermove/utils';

const _new = ({
  jobId,
  organizationId,
  isPrimary,
  numberOfRequiredMovers,
  numberOfRequiredTrucks,
}: any) => ({
  jobId,
  organizationId,
  numberOfRequiredMovers,
  numberOfRequiredTrucks,
  isPrimary,
});

const edit = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ jobId, organizationId, number... Remove this comment to see the full error message
  ({jobId, organizationId, numberOfRequiredMovers, numberOfRequiredTrucks, isPrimary}) => ({
    jobId,
    organizationId,
    numberOfRequiredMovers,
    numberOfRequiredTrucks,
    isPrimary,
  }),
  gql`
    fragment CrewForm_edit on Crew {
      id
      jobId
      organizationId
      numberOfRequiredMovers
      numberOfRequiredTrucks
      isPrimary
    }
  `,
);

const toForm = ({
  jobId,
  organizationId,
  numberOfRequiredMovers,
  numberOfRequiredTrucks,
  isPrimary,
}: any) => ({
  jobId,
  organizationId,
  numberOfRequiredMovers: Float.toForm(numberOfRequiredMovers),
  numberOfRequiredTrucks: Float.toForm(numberOfRequiredTrucks),
  isPrimary,
});

const toMutation = ({
  jobId,
  organizationId,
  numberOfRequiredMovers,
  numberOfRequiredTrucks,
  isPrimary,
}: any) => ({
  jobId,
  organizationId,
  numberOfRequiredMovers: Float.toMutation(numberOfRequiredMovers),
  numberOfRequiredTrucks: Float.toMutation(numberOfRequiredTrucks),
  isPrimary,
});

const CrewForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default CrewForm;
