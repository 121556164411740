// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const sortClaimTypes = withFragment(
  (claimTypes) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    return _.orderBy(claimTypes, ['name']);
  },
  gql`
    fragment ClaimType_sortClaimTypes on ClaimType {
      id
      name
    }
  `,
);

const ClaimType = {
  sortClaimTypes,
};

export default ClaimType;
