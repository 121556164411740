/*
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

import TextTooltip from '@shared/design/components/TextTooltip';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TitleText = Styled.Text`
  ${Typography.Label3}
  color: ${(props) => props.color};
`;

const CloseButton = Styled.ButtonV2`
  padding-horizontal: 4px;
`;

const Badge = Styled.ButtonV2`
  background-color: ${({color}) => color};
  padding-horizontal: 4px;
  padding-vertical: 2px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.tertiary};
`;

const BadgeText = Styled.Text`
  ${Typography.Label4}
  color: ${(props) => props.color};
`;

const EstimatedRangeJobBadgeContainer = Styled.ButtonV2`
  background-color: ${({color}) => color};
  padding-horizontal: 8px;
  padding-vertical: 4px;
  border-radius: 20px;
  border-width: 1px;
  border-color: ${({borderColor}) => borderColor};
`;

const EstimatedRangeJobBadgeText = Styled.Text`
  ${Typography.Label3}
  color: ${(props) => props.color};
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const getRelevantJobs = ({job, organizationId}) => {
  const allJobs = job.project.activeJobsExcludingChildJobs;
  const relevantJobs = _.filter(
    allJobs,
    (job) => !!_.find(job.crews, (crew) => crew.organizationId === organizationId),
  );
  return relevantJobs;
};

const getOrganizationCrew = ({crews, organizationId}) => {
  // A job has only one crew for any given org, so we return the
  // first crew that matches the organizationId
  const organizationCrew = _.find(crews, (crew) => crew.organizationId === organizationId);
  return organizationCrew;
};

const getEstimatedRangeJobTooltipText = ({job}) => {
  if (Job.getIsEstimatedRange(job)) {
    return `Estimated Date Range,\n${Job.getCalendarPrimaryStatusText(job)}`;
  }
  return Job.getCalendarPrimaryStatusText(job);
};

const JobBadge = ({job, currentJob, organizationId, handleShowJob}) => {
  const isCurrentJob = job.id === currentJob.id || job.id === String(currentJob.parentJobId);
  const organizationCrew = getOrganizationCrew({crews: job.crews, organizationId});
  return (
    <Badge
      color={isCurrentJob ? colors.blue.interactive : colors.white}
      disabled={isCurrentJob}
      onPress={() =>
        handleShowJob({jobUuid: job.uuid, crewId: organizationCrew ? organizationCrew.id : null})
      }
    >
      <BadgeText color={isCurrentJob ? colors.white : colors.gray.primary}>
        {job.shortName}
      </BadgeText>
    </Badge>
  );
};

const JobProjectJobsRow = ({jobs, currentJob, organizationId, handleShowJob}) => {
  return (
    <Row>
      <Space width={16} />
      {jobs.map((job, index) => {
        return (
          <React.Fragment key={job.id}>
            {index > 0 && <Space width={4} />}
            <JobBadge
              job={job}
              currentJob={currentJob}
              organizationId={organizationId}
              handleShowJob={handleShowJob}
            />
          </React.Fragment>
        );
      })}
      <Space width={16} />
    </Row>
  );
};

const JobProjectJobs = ({job, jobs, organizationId, handleShowJob}) => {
  const sortedJobs = _.sortBy(jobs, [
    (job) => (job.kind === 'REQUEST' ? 0 : 1),
    (job) => (job.day ? job.day.value : job.startDate),
    (job) => job.startTime1,
    (job) => job.endDate,
  ]);
  // If there are less than 4 jobs to show, we show one row.
  // Otherwise we show two rows of jobs.
  if (sortedJobs.length < 4) {
    return (
      <JobProjectJobsRow
        jobs={sortedJobs}
        organizationId={organizationId}
        currentJob={job}
        handleShowJob={handleShowJob}
      />
    );
  }
  const jobRows = _.chunk(sortedJobs, Math.ceil(jobs.length / 2));
  return (
    <Column>
      {jobRows.map((jobs, index) => {
        return (
          <React.Fragment key={index}>
            <JobProjectJobsRow
              jobs={jobs}
              organizationId={organizationId}
              currentJob={job}
              handleShowJob={handleShowJob}
            />
            {index === 0 && <Space height={4} />}
          </React.Fragment>
        );
      })}
    </Column>
  );
};

const EstimatedRangeJobBadge = ({job, currentJob, organizationId, handleShowJob}) => {
  const isCurrentJob = job.id === currentJob.id;
  const organizationCrew = getOrganizationCrew({crews: job.crews, organizationId});
  const {ref, isHovered} = useHover();
  return (
    <TextTooltip text={getEstimatedRangeJobTooltipText({job})}>
      <EstimatedRangeJobBadgeContainer
        ref={ref}
        color={isCurrentJob ? colors.blue.accent : colors.white}
        borderColor={isHovered && !isCurrentJob ? colors.gray.primary : colors.gray.border}
        disabled={isCurrentJob}
        onPress={() =>
          handleShowJob({jobUuid: job.uuid, crewId: organizationCrew ? organizationCrew.id : null})
        }
      >
        <EstimatedRangeJobBadgeText
          color={isCurrentJob || isHovered ? colors.gray.primary : colors.gray.secondary}
        >
          {Job.getDispatchDisplayDate(job, 'MM/DD')}
        </EstimatedRangeJobBadgeText>
      </EstimatedRangeJobBadgeContainer>
    </TextTooltip>
  );
};

const JobEstimatedRangeJobs = ({jobs, currentJob, organizationId, handleShowJob}) => {
  return (
    <Row>
      <Space width={16} />
      {jobs.map((job, index) => {
        return (
          <React.Fragment key={job.id}>
            {index > 0 && <Space width={4} />}
            <EstimatedRangeJobBadge
              job={job}
              currentJob={currentJob}
              organizationId={organizationId}
              handleShowJob={handleShowJob}
            />
          </React.Fragment>
        );
      })}
      <Space width={16} />
    </Row>
  );
};

const JobDispatchPopoverHeader = ({viewer, job, organizationId, handleClose, handleShowJob}) => {
  const {projectType, activeJobsExcludingChildJobs} = job.project;
  const relevantJobs = viewer.viewingOrganization.isPrimary
    ? activeJobsExcludingChildJobs
    : getRelevantJobs({job, organizationId});
  const hasMoreThanOneJob = relevantJobs.length > 1;
  // An estimatedRangeJob will always include itself in job.jobsForEstimatedRange.
  // We only want to show estimated range jobs if there is at least one additional
  // child job as well.
  const showEstimatedRangeJobs = job.jobsForEstimatedRange.length > 1;
  return (
    <Column>
      <Row style={{height: 36, alignItems: 'center'}}>
        <Space width={16} />
        <TitleText color={projectType.color}>{projectType.name}</TitleText>
        <Space width={4} />
        <TitleText color={colors.gray.secondary}>{job.project.name}</TitleText>
        <Space style={{flex: 1}} />
        <CloseButton onPress={handleClose}>
          <Icon source={Icon.Times} color={colors.black} size={16} />
        </CloseButton>
        <Space width={16} />
      </Row>
      {(hasMoreThanOneJob || showEstimatedRangeJobs) && (
        <React.Fragment>
          <Line />
          <ScrollView
            horizontal
            style={{backgroundColor: colors.gray.background, paddingVertical: 8}}
          >
            <JobProjectJobs
              job={job}
              jobs={relevantJobs}
              organizationId={organizationId}
              handleShowJob={handleShowJob}
            />
          </ScrollView>
          <Line />
          {showEstimatedRangeJobs && (
            <React.Fragment>
              <ScrollView horizontal style={{paddingVertical: 8}}>
                <JobEstimatedRangeJobs
                  jobs={job.jobsForEstimatedRange}
                  currentJob={job}
                  organizationId={organizationId}
                  handleShowJob={handleShowJob}
                />
              </ScrollView>
              <Line />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Column>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchPopoverHeader.fragment = gql`
  ${Job.getCalendarPrimaryStatusText.fragment}
  ${Job.getDispatchDisplayDate.fragment}
  ${Job.getIsEstimatedRange.fragment}
  fragment JobDispatchPopoverHeader on Job {
    id
    parentJobId
    project {
      id
      name
      size
      projectType {
        id
        name
        color
      }
      activeJobsExcludingChildJobs {
        id
        uuid
        shortName
        crews {
          id
          organizationId
        }
        startTime1
        ...Job_getDispatchDisplayDate
      }
    }
    jobsForEstimatedRange {
      id
      uuid
      shortName
      ...Job_getCalendarPrimaryStatusText
      ...Job_getDispatchDisplayDate
      ...Job_getIsEstimatedRange
    }
  }
`;

export default JobDispatchPopoverHeader;
