// Supermove
import {gql} from '@supermove/graphql';
import {Json, Phone, withFragment} from '@supermove/utils';

const _new = ({organizationId, clientId}: any) => ({
  userId: null,
  organizationId,
  clientId,
  names: '["", ""]',
  phoneNumber: '',
  email: '',
});

const edit = withFragment(
  (contact) => ({
    userId: (contact as any).id,
    organizationId: (contact as any).organizationId,
    clientId: (contact as any).clientId,
    names: (contact as any).names,
    phoneNumber: (contact as any).phoneNumber,
    email: (contact as any).email,
  }),
  gql`
    fragment ContactForm_edit on User {
      id
      organizationId
      clientId
      names
      phoneNumber
      email
    }
  `,
);

const toForm = ({userId, organizationId, clientId, names, phoneNumber, email}: any) => ({
  userId,
  organizationId,
  clientId,
  names: Json.toForm(names),
  phoneNumber: Phone.strip(phoneNumber),
  email,
});

const toMutation = ({userId, organizationId, clientId, names, phoneNumber, email}: any) => ({
  userId,
  organizationId,
  clientId,
  names: Json.toMutation(names.map((name: any) => name.trim())),
  phoneNumber: Phone.strip(phoneNumber),
  email,
});

const ContactForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ContactForm;
