/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Icon, Loading, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useMountEffect,
  useNavigationDOM,
  usePopover,
  useQuery,
  useResponsive,
} from '@supermove/hooks';
import {Typography, colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import {PageLoadingIndicator} from 'modules/App/components';
import {OfficeHeaderBuilder} from 'modules/App/components/OfficeHeader';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import SidebarPage from 'modules/App/components/SidebarPage';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import Switch from 'modules/App/components/Switch';
import CalendarHelpPopover from 'modules/Dispatch/Calendar/Day/components/CalendarHelpPopover';
import DispatchCalendarActions from 'modules/Dispatch/Calendar/Day/components/DispatchCalendarActions';
import DispatchDayLeftPanel from 'modules/Dispatch/Calendar/Day/components/DispatchDayLeftPanel';
import DispatchDayRightPanel from 'modules/Dispatch/Calendar/Day/components/DispatchDayRightPanel';
import DayCalendarDispatchOrganizationsContextProvider from 'modules/Dispatch/Calendar/Day/context/DayCalendarDispatchOrganizationsContextProvider';
import DayCalendarDispatchViewContextProvider from 'modules/Dispatch/Calendar/Day/context/DayCalendarDispatchViewContextProvider';
import useDayCalendarDispatchViewContext from 'modules/Dispatch/Calendar/Day/context/useDayCalendarDispatchViewContext';

const RIGHT_PANEL_WIDTH = 416;

const Wrapper = Styled.View`
  flex: 1;
`;

const DispatchIconButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  border: 1px solid;
  border-color: ${({vars}) => vars.borderColor}
  border-radius: 4px;
  padding-horizontal: 8px;
  height: 36px;
`;

const Content = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const Left = Styled.View`
  flex: 1;
`;

const Right = Styled.View`
  width: ${({responsive}) => (responsive.mobile ? '100%' : `${RIGHT_PANEL_WIDTH}px`)};
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: ${colors.blue.accent};
`;

const ViewOptionsButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  border: 1px solid;
  border-color: ${({color}) => color}
  border-radius: 4px;
  padding-horizontal: 8px;
  height: 36px;
`;

const ViewOptionsButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  padding-top: 1px;
`;

const ViewOptionRowButton = Styled.ButtonV2`
  flex-direction: row;
  padding-horizontal: 12px;
  height: 20px;
  align-items: center;
`;

const ViewOptionText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const LongDistanceButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  height: 36px;
  padding-horizontal: 12px;
`;

const LongDistanceButtonText = Styled.H7`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
`;

const getCalendarOptions = () => {
  return [
    {label: 'Day', value: 'DAY'},
    {label: 'Month', value: 'MONTH'},
  ];
};

const ViewOptions = () => {
  const viewOptionsPopover = usePopover();
  const {isExpanded, setIsExpanded} = useDayCalendarDispatchViewContext();
  return (
    <React.Fragment>
      <Popover.Content innerRef={viewOptionsPopover.ref}>
        <ViewOptionsButton
          color={viewOptionsPopover.isOpen ? colors.gray.primary : colors.gray.border}
          onPress={viewOptionsPopover.handleToggle}
        >
          <ViewOptionsButtonText>View</ViewOptionsButtonText>
          <Space width={16} />
          <Icon source={Icon.CaretDown} color={colors.gray.secondary} size={Icon.Sizes.Medium} />
        </ViewOptionsButton>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomEnd}
        isOpen={viewOptionsPopover.isOpen}
        handleOpen={viewOptionsPopover.handleOpen}
        handleClose={viewOptionsPopover.handleClose}
        reference={viewOptionsPopover.ref}
        offset={[0, 4]}
      >
        <ResponsivePopover.StaticContainer width={156}>
          <Space height={16} />
          <ViewOptionRowButton onPress={() => setIsExpanded(!isExpanded)}>
            <Switch.Button isOn={isExpanded} color={colors.green.status} />
            <Space width={8} />
            <ViewOptionText>Expanded View</ViewOptionText>
          </ViewOptionRowButton>
          <Space height={16} />
        </ResponsivePopover.StaticContainer>
      </Popover>
    </React.Fragment>
  );
};

const CalendarKindDropdown = ({kind, selectedDate, isMovesCalendarEnabled}) => {
  const {navigator} = useNavigationDOM();

  return (
    <DropdownInput
      name={'calendar'}
      value={kind}
      options={getCalendarOptions()}
      setFieldValue={(field, value) => {
        switch (value) {
          case 'DAY':
            return navigator.push(`/dispatch/calendar/day/${selectedDate}`);
          case 'MONTH':
            if (isMovesCalendarEnabled) {
              return navigator.push(`/calendar`);
            } else {
              return navigator.push(`/moves/capacity/calendar/month`);
            }
          default:
            return;
        }
      }}
      containerStyleOverride={{
        height: 36,
        width: 70,
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colors.gray.border,
      }}
      selectionStyle={{
        fontWeight: 700,
        color: colors.gray.secondary,
        paddingTop: 2,
      }}
      components={{
        DropdownIndicator: () => (
          <Icon
            source={Icon.CaretDown}
            color={colors.gray.secondary}
            size={Icon.Sizes.Medium}
            style={{marginLeft: 8, marginRight: 8}}
          />
        ),
        IndicatorSeparator: () => null,
      }}
    />
  );
};

const HelpButton = () => {
  const helpPopover = usePopover();
  return (
    <React.Fragment>
      <Popover.Content innerRef={helpPopover.ref}>
        <DispatchIconButton
          vars={{borderColor: helpPopover.isOpen ? colors.orange.status : colors.gray.border}}
          onPress={helpPopover.handleToggle}
        >
          <Icon
            source={Icon.QuestionCircle}
            color={helpPopover.isOpen ? colors.orange.status : colors.gray.secondary}
            size={18}
          />
        </DispatchIconButton>
      </Popover.Content>
      <CalendarHelpPopover popover={helpPopover} />
    </React.Fragment>
  );
};

const DispatchPageContent = ({viewerDayId, dayIds, dispatchJobsSummaryKind}) => {
  const {data, loading, refetch} = useQuery(DispatchPageContent.query, {
    fetchPolicy: 'network-only',
    variables: {viewerDayId, dayIds},
  });
  const {params, navigator} = useNavigationDOM();
  const responsive = useResponsive();
  const selectedDate = params.date || Datetime.toMutationDate(Datetime.today);
  const kind = 'DAY';

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        const {isPrimary, hasMultipleOrganizations} = data.dispatchCalendarDay.day.organization;
        const isMultipleOrganizations = isPrimary && hasMultipleOrganizations;
        const dispatchRightPanelCounts = isMultipleOrganizations
          ? data.dispatchCalendarDay.dispatchRightPanelCounts
          : data.dispatchCalendarDay.singleDispatchRightPanelCounts;
        const contextOrganizations = data.dispatchCalendarDay.organizationSlotsSummaries.map(
          (summary) => ({
            organizationId: summary.organization.id,
            isOpen: true,
          }),
        );
        const {isMovesCalendarEnabled} = data.viewer.viewingOrganization.settings;

        return (
          <DayCalendarDispatchViewContextProvider
            isViewingAsPrimaryOrg={data.viewer.viewingOrganization.isPrimary}
          >
            <DayCalendarDispatchOrganizationsContextProvider organizations={contextOrganizations}>
              <Wrapper>
                <StandardOfficeHeader title={''}>
                  <OfficeHeaderBuilder.Content
                    style={{flex: 1, marginLeft: responsive.mobile ? '16px' : '0px'}}
                  >
                    <DispatchCalendarActions
                      kind={kind}
                      calendarDayString={Datetime.convertToDisplayDate(
                        selectedDate,
                        Datetime.DISPLAY_DATE,
                      )}
                      maxDaysAhead={data.viewer.viewingOrganization.calendarMaxDaysAhead}
                    />
                    <Space width={4} />
                    <HelpButton />
                    <Space width={4} />
                    <LongDistanceButton
                      onPress={() => navigator.push(`/dispatch/long-distance/shipments`)}
                    >
                      <LongDistanceButtonText>Long Distance</LongDistanceButtonText>
                    </LongDistanceButton>
                    <Space style={{flex: 1}} />
                    <ViewOptions />
                    <Space width={4} />
                    <CalendarKindDropdown
                      kind={kind}
                      selectedDate={selectedDate}
                      isMovesCalendarEnabled={isMovesCalendarEnabled}
                    />
                    <Space width={20} />
                  </OfficeHeaderBuilder.Content>
                </StandardOfficeHeader>
                <Content>
                  <Left>
                    <DispatchDayLeftPanel data={data} loading={loading} refetch={refetch} />
                  </Left>
                  <Right responsive={responsive}>
                    <DispatchDayRightPanel
                      viewerDayId={viewerDayId}
                      day={data.dispatchCalendarDay.day}
                      organization={data.dispatchCalendarDay.day.organization}
                      dayIds={dayIds}
                      dispatchJobsSummaryKind={dispatchJobsSummaryKind}
                      dispatchRightPanelCounts={dispatchRightPanelCounts}
                      refetchCalendar={refetch}
                    />
                  </Right>
                </Content>
              </Wrapper>
            </DayCalendarDispatchOrganizationsContextProvider>
          </DayCalendarDispatchViewContextProvider>
        );
      }}
    </Loading>
  );
};

/**
 * The DayCalendarDispatch component consists so of the calendar header,
 * left organization slots summaries, and jobs list on the right side.
 */
const DayCalendarDispatch = () => {
  const {params, navigator} = useNavigationDOM();
  const {date: selectedDate, kind: dispatchJobsSummaryKind} = params;

  useMountEffect(() => {
    if (!Datetime.fromDate(params.date).isValid()) {
      navigator.replace(`/dispatch/calendar/day/${Datetime.toMutationDate(Datetime.today)}`);
    }
    if (!params.kind) {
      navigator.replace(`?kind=UNASSIGNED`);
    }
  });

  return (
    <SidebarPage
      selected={'dispatch'}
      query={DayCalendarDispatch.query}
      variables={{date: selectedDate}}
    >
      {({data}) => {
        const {viewerDay} = data.dispatchDays;
        const dayIds = data.dispatchDays.days.map((day) => day.id);

        return (
          <DispatchPageContent
            viewerDayId={viewerDay.id}
            dayIds={dayIds}
            dispatchJobsSummaryKind={dispatchJobsSummaryKind}
          />
        );
      }}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DayCalendarDispatch.query = gql`
  query DayCalendarDispatch($date: String!) {
    ${gql.query}
    dispatchDays(date: $date) {
      viewerDay {
        id
      }
      days {
        id
      }
    }
  }
`;

DispatchPageContent.query = gql`
  ${DispatchDayLeftPanel.fragment}
  ${DispatchDayRightPanel.fragment}

  query DispatchPageContent($viewerDayId: Int!, $dayIds: [Int]!){
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        isPrimary
        calendarMaxDaysAhead
        settings {
          id
          isMovesCalendarEnabled
        }
      }
    }

    dispatchCalendarDay(viewerDayId: $viewerDayId, dayIds: $dayIds){
      ...DispatchDayLeftPanel
      ...DispatchDayRightPanel
    }
  }
`;
export default DayCalendarDispatch;
