// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import BillTipForm from '@shared/modules/Billing/forms/BillTipForm';

const useUpdateBillTipMutation = ({billTipForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      billTipForm: BillTipForm.toForm(billTipForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateBillTipMutation.mutation,
    variables: {
      billTipForm: BillTipForm.toMutation(form.values.billTipForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------

useUpdateBillTipMutation.mutation = gql`
  mutation useUpdateBillTipMutation($billTipForm: BillTipForm!) {
    response: updateBillTip(billTipForm: $billTipForm) {
      ${gql.errors}
      billTip {
        id
      }
    }
  }
`;

export default useUpdateBillTipMutation;
