// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (inventory) => ({
    inventoryId: (inventory as any).id,
    densityFactor: (inventory as any).densityFactor,
  }),
  gql`
    fragment InventoryForm_edit on Inventory {
      id
      densityFactor
    }
  `,
);

const toForm = ({inventoryId, densityFactor}: any) => ({
  inventoryId,
  densityFactor,
});

const toMutation = ({inventoryId, densityFactor}: any) => ({
  inventoryId,
  densityFactor,
});

const InventoryForm = {
  edit,
  toForm,
  toMutation,
};

export default InventoryForm;
