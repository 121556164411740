// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (slot) => ({
    slotId: (slot as any).id,
    truckId: (slot as any).truckId,
  }),
  gql`
    fragment TruckToSlotForm_edit on Slot {
      id
      truckId
    }
  `,
);

const _new = ({truckId, slotId}: any) => ({
  truckId,
  slotId,
});

const toForm = ({truckId, slotId}: any) => {
  return {
    truckId,
    slotId,
  };
};

const toMutation = ({truckId, slotId}: any) => {
  return {
    truckId,
    slotId,
  };
};

const AssignTruckToSlotForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default AssignTruckToSlotForm;
