// Supermove

// Supermove
import {uuid} from '@supermove/utils';

export interface SendEmailFromTemplateActionFormType {
  workflowRunStepUuid: string;
  emailTemplateId: string | null;
}

const _new = (): SendEmailFromTemplateActionFormType => ({
  workflowRunStepUuid: uuid(),
  emailTemplateId: null,
});

const edit = (sendEmailFromTemplateActionForm: any): SendEmailFromTemplateActionFormType => ({
  workflowRunStepUuid: sendEmailFromTemplateActionForm.workflowRunStepUuid,
  emailTemplateId: sendEmailFromTemplateActionForm.emailTemplateId,
});

const toForm = (
  SendEmailFromTemplateActionForm: SendEmailFromTemplateActionFormType,
): SendEmailFromTemplateActionFormType => ({
  workflowRunStepUuid: SendEmailFromTemplateActionForm.workflowRunStepUuid,
  emailTemplateId: SendEmailFromTemplateActionForm.emailTemplateId,
});

const toMutation = (
  SendEmailFromTemplateActionForm: SendEmailFromTemplateActionFormType,
): SendEmailFromTemplateActionFormType => ({
  workflowRunStepUuid: SendEmailFromTemplateActionForm.workflowRunStepUuid,
  emailTemplateId: SendEmailFromTemplateActionForm.emailTemplateId,
});

const SendEmailFromTemplateActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default SendEmailFromTemplateActionForm;
