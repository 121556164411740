// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';

const SendSmsFromTemplateSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  handleSubmitValidateWorkflow,
}) => {
  const {label, description} = WorkflowStepActionKind.ATTRIBUTES[WorkflowStepActionKind.SEND_SMS];
  const field = `${workflowStepsField}.${workflowStepIndex}.sendSmsFromTemplateActionForm`;
  const isDoneDisabled = !_.get(form.values, `${field}.textMessageTemplateId`);

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      isDoneDisabled={isDoneDisabled}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    >
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.textMessageTemplateId`}
        label={'Select Template'}
        isRequired
        input={{
          options: workflow.organization.textMessageTemplatesByCategory.map((smsTemplate) => ({
            value: smsTemplate.id,
            label: smsTemplate.name,
          })),
          placeholder: 'Select SMS template',
          isSearchable: true,
          isPortaled: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
    </WorkflowStepSlide>
  );
};

const SendSmsFromTemplateDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  isPreview,
}) => {
  const field = `${workflowStepsField}.${workflowStepIndex}`;
  const textMessageTemplateId = _.get(
    form.values,
    `${field}.sendSmsFromTemplateActionForm.textMessageTemplateId`,
  );
  const smsTemplate = _.find(workflow.organization.textMessageTemplatesByCategory, [
    'id',
    textMessageTemplateId,
  ]);
  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={smsTemplate ? `Send SMS from template: ${smsTemplate.name} ` : ''}
      isPreview={isPreview}
    />
  );
};

const WorkflowStepSendSmsFromTemplate = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    workflow,
    handleSubmitValidateWorkflow,
    isPreview,
  }) => [
    <SendSmsFromTemplateSlide
      key={'CREATE'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      workflow={workflow}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    />,
    <SendSmsFromTemplateDisplaySlide
      key={'DISPLAY'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      workflow={workflow}
      isPreview={isPreview}
    />,
  ],
  fragment: gql`
    fragment WorkflowStepSendSmsFromTemplate on Workflow {
      id
      organization {
        id
        textMessageTemplatesByCategory(categories: ["CUSTOMER", "OTHER"]) {
          id
          name
        }
      }
    }
  `,
};

export default WorkflowStepSendSmsFromTemplate;
