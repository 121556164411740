// Libraries
import _ from 'lodash';

const CARTON = {
  value: 'CARTON',
  label: 'Carton',
};
const ITEM = {
  value: 'ITEM',
  label: 'Item',
};

const ALL_KINDS = [CARTON, ITEM];

const getLabel = (kind: any) => {
  return _.get(
    _.find(ALL_KINDS, (option) => option.value === kind),
    'label',
    '',
  );
};

const SurveyMethodKind = {
  CARTON,
  ITEM,
  dropdownOptions: ALL_KINDS,
  getLabel,
};

export default SurveyMethodKind;
