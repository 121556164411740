// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';

const HeaderContainer = Styled.View`
  height: 68px;
  background-color: ${colors.white};
  flex-direction: row;
  align-items: center;
  padding-horizontal: 24px;
`;

const NameText = Styled.Text`
  ${Typography.Heading2}
`;

const BackButton = ({formHasChanged}) => {
  const {navigator} = useNavigationDOM();
  const exitEditWorkflowBuilderModal = useModal({name: 'Exit Workflow Builder Modal'});
  return (
    <React.Fragment>
      <TertiaryButton
        style={{paddingHorizontal: 8}}
        onPress={() =>
          formHasChanged ? exitEditWorkflowBuilderModal.handleOpen() : navigator.goBack()
        }
      >
        <Icon source={Icon.ChevronLeft} size={16} color={colors.gray.primary} />
      </TertiaryButton>
      <ActionModal
        title={'Exit this page?'}
        message={'Any unsaved changes will be deleted.'}
        icon={Icon.ExclamationTriangle}
        color={colors.red.warning}
        key={exitEditWorkflowBuilderModal.key}
        isOpen={exitEditWorkflowBuilderModal.isOpen}
        handlePrimaryAction={navigator.goBack}
        handleSecondaryAction={exitEditWorkflowBuilderModal.handleClose}
        primaryActionText={'Exit Page'}
        secondaryActionText={'Cancel'}
      />
    </React.Fragment>
  );
};

const WorkflowBuilderPageHeader = ({
  workflow,
  handleUpdateWorkflowAndWorkflowStepsMutation,
  updateWorkflowForm,
  form,
  handleUpdateWorkflowAndWorkflowStepsMutationSubmitting,
}) => {
  const formHasChanged = form.dirty;
  const formHasNoTriggerIdentifier = !_.get(form.values, 'workflowForm.triggerIdentifier');
  const formHasNoWorkflowSteps = _.get(form.values, 'workflowForm.workflowStepForms').length === 0;
  const isEditingStep = _.get(form.values, 'workflowForm.isEditingStep');
  const isSaveButtonDisabled =
    !formHasChanged || formHasNoTriggerIdentifier || formHasNoWorkflowSteps || isEditingStep;

  return (
    <HeaderContainer>
      <BackButton formHasChanged={formHasChanged} />
      <Space width={12} />
      <NameText>{workflow.name}</NameText>
      <Space style={{flex: 1}} />
      <Button
        text={'Save Automation'}
        isDisabled={isSaveButtonDisabled}
        isSubmitting={handleUpdateWorkflowAndWorkflowStepsMutationSubmitting}
        onPress={() => {
          updateWorkflowForm.setFieldValue('workflowForm', form.values.workflowForm);
          setTimeout(handleUpdateWorkflowAndWorkflowStepsMutation, 0);
        }}
      />
    </HeaderContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowBuilderPageHeader.fragment = gql`
  fragment WorkflowBuilderPageHeader on Workflow {
    id
    name
  }
`;

export default WorkflowBuilderPageHeader;
