// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import EmptyState from '@shared/design/components/EmptyState';
import Line from 'modules/App/components/Line';
import StatusBadge from 'modules/App/components/StatusBadge';
import ClientAddCreditCardModal from 'modules/Client/components/ClientAddCreditCardModal';
import ClientEditCreditCardModal from 'modules/Client/components/ClientEditCreditCardModal';
import CreditCardActionsPopoverButton from 'modules/Client/components/CreditCardActionsPopoverButton';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CreditCardsListContainer = Styled.View`
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const CreditCardsListItemContainer = Styled.View`
  padding: 12px;
  flex-direction: row;
  align-items: flex-start;
`;

const CreditCardNickname = Styled.Text`
  ${Typography.Responsive.Label}
`;

const CreditCardDetails = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const CreditCardsListItem = ({creditCard, client, projectId, refetch, responsive}) => {
  const editPayengineCreditCardModal = useModal({
    name: 'Edit Payengine Credit Card Modal',
    enableTracking: true,
  });

  return (
    <CreditCardsListItemContainer>
      <Container style={{flex: 1}}>
        <Row>
          <Row style={{flex: 1}}>
            <CreditCardNickname responsive={responsive} numberOfLines={1}>
              {creditCard.cardName}
            </CreditCardNickname>
            <Space width={8} />
            {creditCard.isDefault && (
              <StatusBadge label={'Default'} color={colors.gray.secondary} />
            )}
          </Row>
          <Space width={8} />
          <TertiaryButton onPress={editPayengineCreditCardModal.handleOpen}>
            <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
          </TertiaryButton>
          <Space width={8} />
          <CreditCardActionsPopoverButton
            creditCard={creditCard}
            projectId={projectId}
            refetch={refetch}
          />
        </Row>
        <Space height={4} />
        <CreditCardDetails responsive={responsive}>
          {`${creditCard.cardSummaryName}, Expires ${creditCard.expireDate}`}
        </CreditCardDetails>
      </Container>
      <ClientEditCreditCardModal
        key={editPayengineCreditCardModal.key}
        client={client}
        creditCard={creditCard}
        projectId={projectId}
        isOpen={editPayengineCreditCardModal.isOpen}
        handleClose={editPayengineCreditCardModal.handleClose}
        refetch={refetch}
      />
    </CreditCardsListItemContainer>
  );
};

const CreditCardsList = ({client, projectId, clientAddCreditCardModal, refetch}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <CreditCardsListContainer>
        {_.map(client.creditCards, (creditCard, index) => {
          const isFirst = index === 0;
          return (
            <React.Fragment key={creditCard.id}>
              {!isFirst && <Line />}
              <CreditCardsListItem
                creditCard={creditCard}
                client={client}
                projectId={projectId}
                refetch={refetch}
                responsive={responsive}
              />
            </React.Fragment>
          );
        })}
      </CreditCardsListContainer>
      <Space height={8} />
      <QuaternaryButton
        iconLeft={Icon.Plus}
        text={'Add Card'}
        textColor={colors.blue.interactive}
        onPress={clientAddCreditCardModal.handleOpen}
        isResponsive
      />
      <Space height={32} />
    </React.Fragment>
  );
};

const ClientCreditCardsDrawerContent = ({client, projectId, refetch}) => {
  const clientAddCreditCardModal = useModal({
    name: 'Client Add Credit Card Modal',
    enableTracking: true,
  });

  return (
    <React.Fragment>
      {_.isEmpty(client.creditCards) ? (
        <EmptyState.ContentContainer>
          <EmptyState
            title={'No credit cards.'}
            message={'Add a new card and it will appear here.'}
            primaryActionIcon={Icon.Plus}
            primaryActionText={'Add Card'}
            handlePrimaryAction={clientAddCreditCardModal.handleOpen}
          />
        </EmptyState.ContentContainer>
      ) : (
        <CreditCardsList
          client={client}
          projectId={projectId}
          clientAddCreditCardModal={clientAddCreditCardModal}
          refetch={refetch}
        />
      )}
      <ClientAddCreditCardModal
        key={clientAddCreditCardModal.key}
        isOpen={clientAddCreditCardModal.isOpen}
        handleClose={clientAddCreditCardModal.handleClose}
        onSuccess={refetch}
        client={client}
        projectId={projectId}
      />
    </React.Fragment>
  );
};

const ClientCreditCardsDrawer = ({isOpen, handleClose, clientId, projectId}) => {
  const {data, loading, refetch} = useQuery(ClientCreditCardsDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {clientId},
    skip: !isOpen,
  });

  const client = _.get(data, 'client');

  return (
    <DrawerWithClose
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Manage Credit Cards'}
      isResponsive={false}
      bodyProps={
        client && _.isEmpty(client.creditCards)
          ? {
              bodyScrollStyle: {
                flex: 1,
              },
            }
          : null
      }
    >
      <Loading loading={loading || !data}>
        {() => {
          return (
            <ClientCreditCardsDrawerContent
              client={data.client}
              projectId={projectId}
              refetch={refetch}
            />
          );
        }}
      </Loading>
    </DrawerWithClose>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientCreditCardsDrawer.query = gql`
  ${ClientAddCreditCardModal.fragment}
  ${ClientEditCreditCardModal.fragment}

  query ClientCreditCardsDrawer($clientId: Int!) {
    ${gql.query}
    client: clientById(clientId: $clientId) {
      id
      creditCards {
        id
        cardName
        cardSummaryName
        isDefault
        expireDate
      }
      ...ClientAddCreditCardModal
      ...ClientEditCreditCardModal
    }
  }
`;

export default ClientCreditCardsDrawer;
