// Libararies
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';

// TODO(dan) Add weight, dates, and locations as private

const _new = ({organizationId, userId}: any) => ({
  name: '',
  description: '',
  identifier: '',
  isDeleted: false,
  organizationId,
  createdById: userId,
  updatedById: userId,
  shouldEmailDocumentToCustomer: true,
  // TODO(Kevin): for now JOB will be default, this is to set it correctly
  // for organizations that don't have invoice document turned on
  category: DocumentTemplateCategory.JOB,
  isDefaultConfirmationDocumentTemplate: false,
  isDefaultQuoteDocumentTemplate: false,
});

const edit = withFragment(
  (documentTemplate, {userId}) => ({
    documentTemplateV2Id: (documentTemplate as any).id,
    name: (documentTemplate as any).name,
    description: (documentTemplate as any).description,
    identifier: (documentTemplate as any).identifier,
    isDeleted: (documentTemplate as any).isDeleted,
    organizationId: (documentTemplate as any).organizationId,
    createdById: (documentTemplate as any).createdById,
    updatedById: userId,
    shouldEmailDocumentToCustomer: (documentTemplate as any).shouldEmailDocumentToCustomer,
    category: (documentTemplate as any).category,
    isDefaultConfirmationDocumentTemplate: (documentTemplate as any)
      .isDefaultConfirmationDocumentTemplate,
    isDefaultQuoteDocumentTemplate: (documentTemplate as any).isDefaultQuoteDocumentTemplate,
  }),
  gql`
    fragment DocumentTemplateV2Form_edit on DocumentTemplateV2 {
      id
      name
      description
      identifier
      isDeleted
      organizationId
      createdById
      updatedById
      shouldEmailDocumentToCustomer
      category
      isDefaultConfirmationDocumentTemplate
      isDefaultQuoteDocumentTemplate
    }
  `,
);

const copy = withFragment(
  (documentTemplate, {userId}) => ({
    documentTemplateV2Id: (documentTemplate as any).id,
    name: `Copy of ${(documentTemplate as any).name}`,
    description: (documentTemplate as any).description,
    identifier: '',
    isDeleted: (documentTemplate as any).isDeleted,
    organizationId: (documentTemplate as any).organizationId,
    createdById: userId,
    updatedById: userId,
    shouldEmailDocumentToCustomer: (documentTemplate as any).shouldEmailDocumentToCustomer,
    category: (documentTemplate as any).category,
    // Set to False even if documentTemplate is True
    // We don't want to override the default when duplicating
    isDefaultConfirmationDocumentTemplate: false,
    isDefaultQuoteDocumentTemplate: false,
  }),
  gql`
    fragment DocumentTemplateV2Form_copy on DocumentTemplateV2 {
      id
      name
      description
      isDeleted
      organizationId
      createdById
      updatedById
      shouldEmailDocumentToCustomer
      category
    }
  `,
);

const toForm = ({
  name,
  description,
  identifier,
  isDeleted,
  organizationId,
  createdById,
  updatedById,
  shouldEmailDocumentToCustomer,
  documentTemplateV2Id,
  category,
  isDefaultConfirmationDocumentTemplate,
  isDefaultQuoteDocumentTemplate,
}: any) => ({
  name,
  description,
  identifier,
  isDeleted,
  organizationId,
  createdById,
  updatedById,
  shouldEmailDocumentToCustomer,
  documentTemplateV2Id,
  category,
  isDefaultConfirmationDocumentTemplate,
  isDefaultQuoteDocumentTemplate,
});

const toMutation = ({
  name,
  description,
  identifier,
  isDeleted,
  organizationId,
  createdById,
  updatedById,
  shouldEmailDocumentToCustomer,
  documentTemplateV2Id,
  category,
  isDefaultConfirmationDocumentTemplate,
  isDefaultQuoteDocumentTemplate,
}: any) => ({
  name,
  description,
  identifier: identifier || _.toUpper(_.snakeCase(name)),
  isDeleted,
  organizationId,
  createdById,
  updatedById,
  shouldEmailDocumentToCustomer,
  documentTemplateV2Id,
  category,
  isDefaultConfirmationDocumentTemplate,
  isDefaultQuoteDocumentTemplate,
});

const DocumentTemplateV2Form = {
  edit,
  copy,
  toForm,
  toMutation,
  new: _new,
};

export default DocumentTemplateV2Form;
