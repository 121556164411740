// Libraries
import React from 'react';

// App
import {Emoji, Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// Components
import SendRemindersToSetScheduleMutation from './SendRemindersToSetScheduleMutation';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  align-items: center;
  margin-vertical: 20px;
`;

const SubmitButton = Styled.Button`
  flex-direction: row;
  align-items: center;
  height: 30px;
  width: 100px;
`;

const SubmitText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Indicator = Styled.Loading`
  margin-left: 5px;
`;

const SendRemindersToSetScheduleModal = ({employee, trigger}) => (
  <Modal trigger={trigger}>
    {({handleClose}) => (
      <Container pointerEvents={'box-none'}>
        <Wrapper width={350}>
          <Circle>
            <Emoji component={EmojiText} name={'bell'} />
          </Circle>
          <Content>
            <Title>Send Schedule Reminder</Title>
            <Subtitle>{`Remind ${User.getFullName(employee)} to set their schedule.`}</Subtitle>
            <Actions>
              <SendRemindersToSetScheduleMutation
                employeeId={employee.id}
                organizationId={employee.organizationId}
                onSuccess={handleClose}
                onError={() => console.log('error')}
              >
                {({loading, handleSubmit}) => (
                  <SubmitButton disabled={loading} onPress={handleSubmit}>
                    <SubmitText>Send</SubmitText>
                    {loading && <Indicator size={'small'} color={colors.gray.border} />}
                  </SubmitButton>
                )}
              </SendRemindersToSetScheduleMutation>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    )}
  </Modal>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
SendRemindersToSetScheduleModal.propTypes = {};

SendRemindersToSetScheduleModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendRemindersToSetScheduleModal.fragment = gql`
  fragment SendRemindersToSetScheduleModal on User {
    id
    organizationId
    firstName
  }
`;

export default SendRemindersToSetScheduleModal;
