// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';
import CostItemKind from '@shared/modules/Billing/enums/CostItemKind';
import CostItemUnit from '@shared/modules/Billing/enums/CostItemUnit';
import CostItemTypeRateForm from '@shared/modules/Billing/forms/CostItemTypeRateForm';

const _new = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ billingLibrary, category, rol... Remove this comment to see the full error message
  ({billingLibrary, category, role, moverPositions}) => ({
    costItemTypeId: null,
    billItemTypeId: null,
    billTypeId: null,
    billingLibraryId: billingLibrary.id,
    organizationId: billingLibrary.organizationId,
    name: '',
    kind: CostItemKind.PER_BILL_TOTAL,
    unit: CostItemUnit.CENTS,
    role,
    category,
    isDeleted: false,
    costItemTypeRateForms:
      category === CostCategoryKind.COMPENSATION
        ? moverPositions.map((moverPosition: any) =>
            CostItemTypeRateForm.new({moverPositionId: moverPosition.id}),
          )
        : [CostItemTypeRateForm.new({moverPositionId: null})],

    // Private
    billItemTypeOptions: billingLibrary.billItemTypes.filter((billItemType: any) => {
      // Don't include billItemTypes that already have cost items
      const existingCostItemTypeForBillItemTypeAndRole = _.find(
        billingLibrary.costItemTypes,
        (existingCostItemType) => {
          const isRoleEqual =
            category === CostCategoryKind.COMPENSATION ? existingCostItemType.role === role : true;
          return existingCostItemType.billItemTypeId === _.toNumber(billItemType.id) && isRoleEqual;
        },
      );

      return !billItemType.isParent && !existingCostItemTypeForBillItemTypeAndRole;
    }),
    billTypeOptions: billingLibrary.billTypes,
  }),
  gql`
    fragment CostItemTypeForm_new on BillingLibrary {
      id
      organizationId
      costItemTypes(category: $category) {
        id
        role
        billItemTypeId
      }
      billItemTypes {
        id
        name
        isParent
      }
      billTypes {
        id
        name
      }
    }
  `,
);

const edit = withFragment(
  (costItemType: any, {moverPositions, billItemTypeOptions, billTypeOptions}) => ({
    costItemTypeId: costItemType.id,
    billingLibraryId: costItemType.billingLibraryId,
    organizationId: costItemType.organizationId,
    billItemTypeId: costItemType.billItemTypeId,
    billTypeId: costItemType.billTypeId,
    name: costItemType.name,
    kind: costItemType.kind,
    unit: costItemType.unit,
    role: costItemType.role,
    category: costItemType.category,
    isDeleted: costItemType.isDeleted,
    costItemTypeRateForms:
      costItemType.category === CostCategoryKind.COMPENSATION
        ? moverPositions.map((moverPosition: any) => {
            const existingCostItemTypeRate = _.find(
              costItemType.costItemTypeRates,
              (costItemTypeRate) =>
                _.toString(costItemTypeRate.moverPositionId) === moverPosition.id,
            );

            if (existingCostItemTypeRate) {
              return CostItemTypeRateForm.edit(existingCostItemTypeRate);
            } else {
              return CostItemTypeRateForm.new({moverPositionId: moverPosition.id});
            }
          })
        : costItemType.costItemTypeRates.map((existingCostItemTypeRate: any) =>
            CostItemTypeRateForm.edit(existingCostItemTypeRate),
          ),

    // Private
    billItemTypeOptions,
    billTypeOptions,
  }),
  gql`
    ${CostItemTypeRateForm.edit.fragment}

    fragment CostItemTypeForm_edit on CostItemType {
      id
      billingLibraryId
      organizationId
      billItemTypeId
      billTypeId
      name
      kind
      unit
      role
      category
      isDeleted
      costItemTypeRates {
        id
        ...CostItemTypeRateForm_edit
      }
    }
  `,
);

const toForm = ({
  costItemTypeId,
  billingLibraryId,
  organizationId,
  billItemTypeId,
  billTypeId,
  name,
  kind,
  unit,
  role,
  category,
  isDeleted,
  costItemTypeRateForms,

  // Private
  billItemTypeOptions,
  billTypeOptions,
}: any) => {
  return {
    costItemTypeId,
    billingLibraryId,
    organizationId,
    billItemTypeId,
    billTypeId,
    name,
    kind,
    unit,
    role,
    category,
    isDeleted,
    costItemTypeRateForms: costItemTypeRateForms.map((costItemTypeRateForm: any) =>
      CostItemTypeRateForm.toForm({...costItemTypeRateForm, unit}),
    ),

    // Private
    billItemTypeOptions,
    billTypeOptions,
  };
};

const toMutation = ({
  costItemTypeId,
  billingLibraryId,
  organizationId,
  billItemTypeId,
  billTypeId,
  name,
  kind,
  unit,
  role,
  category,
  isDeleted,
  costItemTypeRateForms,
}: any) => {
  return {
    costItemTypeId,
    billingLibraryId,
    organizationId,
    billItemTypeId,
    billTypeId,
    name,
    kind,
    unit,
    role,
    category,
    isDeleted,
    costItemTypeRateForms: costItemTypeRateForms.map((costItemTypeRateForm: any) =>
      CostItemTypeRateForm.toMutation({...costItemTypeRateForm, unit}),
    ),
  };
};

const CostItemTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default CostItemTypeForm;
