// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Text = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const IconContainer = Styled.View`
  width: 12px;
  align-items: center;
`;

const IconTooltip = ({children, text}) => {
  return (
    <TextTooltip text={text}>
      <IconContainer>{children}</IconContainer>
    </TextTooltip>
  );
};

const JobSalesperson = ({job}) => {
  return (
    <Row style={{flexShrink: 1}}>
      <IconTooltip text={'Salesperson'}>
        <Icon color={colors.gray.primary} size={11} source={Icon.UserClock} />
      </IconTooltip>
      <Space width={6} />
      <Text>{job.bookedBy?.fullName ?? 'None'}</Text>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobSalesperson.fragment = gql`
  fragment JobSalesperson on Job {
    id
    bookedBy {
      id
      fullName
    }
  }
`;

export default JobSalesperson;
