import DocumentV2Image from '@shared/modules/Document/components/DocumentV2Image';
import DocumentItemKindV2, {
  DocumentItemKindType,
} from '@shared/modules/Document/enums/DocumentItemKindV2';

export interface DocumentModel {
  id: string;
  shareUuid?: string;
  isShared: boolean;
}

// Document items are objects that are contained in document.document_content_json
// to represent the structure of the document. They are not model objects.
export type DocumentItemSectionType = {
  itemKind: typeof DocumentItemKindV2.SECTION;
  name: string;
  uuid: string;
  documentItems: DocumentItemType[] | DocumentItemColumnType[];
};
export type DocumentItemColumnType = {
  itemKind: typeof DocumentItemKindV2.COLUMN;
  documentItems: DocumentItemType[];
};
const TextTypes = [
  DocumentItemKindV2.TEXT,
  DocumentItemKindV2.TEXT_TITLE,
  DocumentItemKindV2.TEXT_PARAGRAPH,
  DocumentItemKindV2.SECTION_TITLE,
];
type DocumentItemTextType = {
  itemKind: (typeof TextTypes)[number];
  text: string;
};
export type DocumentItemInputSignatureType = {
  itemKind: typeof DocumentItemKindV2.INPUT_SIGNATURE;
  isRequired: boolean;
  input: {
    value: {
      image: string;
      date: string;
    };
  };
};
export type DocumentItemGenericSignatureType = {
  itemKind: typeof DocumentItemKindV2.GENERIC_SIGNATURE;
  isRequired: boolean;
  input: {
    value: {
      name: string;
      image?: string;
      date?: string;
    };
  };
};
export type DocumentItemInputRadioButtonsType = {
  itemKind: typeof DocumentItemKindV2.INPUT_RADIO_BUTTONS;
  isRequired: boolean;
  input: {
    metadata: {
      items: {
        options: string[];
        inputs: Record<string, string>;
      }[];
    };
    value: {
      values: string[][];
    };
  };
};
export type DocumentItemInputTextInputsType = {
  itemKind: typeof DocumentItemKindV2.INPUT_TEXT_INPUTS;
  isRequired: boolean;
  input: {
    metadata: {
      fields: {
        label: string;
        description: string;
        is_hidden: boolean;
      }[];
    };
    value: {
      values: string[];
    };
  };
};
export type DocumentItemInputTextAreasType = {
  itemKind: typeof DocumentItemKindV2.INPUT_TEXT_AREAS;
  isRequired: boolean;
  input: {
    value: {
      values: string[][];
    };
  };
};
export type DocumentItemInputGridType = {
  itemKind: typeof DocumentItemKindV2.INPUT_GRID;
  input: {
    metadata: {
      columns: {
        name: string;
        is_disabled: boolean;
        is_hidden: boolean;
      }[];
      items: string[];
    };
    value: {
      values: string[][];
    };
  };
};
type DocumentItemInputListType = {
  itemKind: typeof DocumentItemKindV2.INPUT_LIST;
  input: {
    value: {
      values: string[];
    };
  };
};
export type DocumentItemImageType = {
  itemKind: typeof DocumentItemKindV2.IMAGE;
  input: {
    width: string;
    height: string;
    position: (typeof DocumentV2Image.POSITION)[keyof typeof DocumentV2Image.POSITION];
    metadata: {
      fileId: string | null;
      size: number;
      width: number;
      height: number;
    };
  };
};
const SummaryTypes = [
  DocumentItemKindV2.INVENTORY_SUMMARY,
  DocumentItemKindV2.SURVEY_CARTONS_SUMMARY,
  DocumentItemKindV2.SURVEY_ITEMS_SUMMARY,
  DocumentItemKindV2.SURVEY_ROOMS_SUMMARY,
];
type DocumentItemSummaryType = {
  itemKind: (typeof SummaryTypes)[number];
  input: {
    showNotes: boolean;
    showWeight: boolean;
    showVolume: boolean;
  };
};
const TimesheetTypes = [
  DocumentItemKindV2.TIMESHEET_INFORMATION,
  DocumentItemKindV2.INPUT_PER_MOVER_TIMESHEET,
];
type DocumentItemTimesheetType = {
  itemKind: (typeof TimesheetTypes)[number];
  input: {
    showMoverNames: boolean;
  };
};
type DocumentItemDefaultType = {
  itemKind: DocumentItemKindType;
};

export type DocumentItemType =
  | DocumentItemSectionType
  | DocumentItemColumnType
  | DocumentItemTextType
  | DocumentItemInputSignatureType
  | DocumentItemGenericSignatureType
  | DocumentItemInputRadioButtonsType
  | DocumentItemInputTextInputsType
  | DocumentItemInputTextAreasType
  | DocumentItemInputGridType
  | DocumentItemInputListType
  | DocumentItemImageType
  | DocumentItemSummaryType
  | DocumentItemTimesheetType
  | DocumentItemDefaultType;
