// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime, Json, Phone} from '@supermove/utils';

const Container = Styled.View`
  display: block;
  width: 100%;
`;

const Row = Styled.View`
  display: block;
`;

const Field = Styled.View`
  display: inline-block;
  width: ${(props) => props.width}px;
`;

const Label = Styled.Text`
  display: block;
  font-size: 14px;
  line-height: 19px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const Value = Styled.Text`
  display: block;
  margin-top: 5px;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.gray.primary};
`;

const FieldValue = ({numberOfLines, label, value, width}) => (
  <Field width={width}>
    <Label numberOfLines={numberOfLines}>{label}</Label>
    <Value numberOfLines={numberOfLines}>{value}</Value>
  </Field>
);

// TODO(mark): Copied, make sure to consolidate.
const getAdditionalItems = ({job}) => {
  const jobFormAdditionalItems = Json.toCamelCaseKeys(
    Json.parse(job.organization.settings.jobFormAdditionalItems),
  );
  const additionalItems = Json.toCamelCaseKeys(Json.parse(job.additionalItems));

  const getLabel = ({jobFormAdditionalItems, key}) => {
    return _.get(jobFormAdditionalItems, `${key}.label`);
  };

  const reducer = (all, value, key) => {
    if (value) {
      return [...all, getLabel({jobFormAdditionalItems, key})];
    }

    // Nothing to add if the value is false.
    return all;
  };

  return _.reduce(additionalItems, reducer, []);
};

const getServiceIdentifers = ({job}) => {
  if (!job.name) {
    return [job.project.identifier, job.identifier].join('\n');
  }

  // Example
  // 411c-82-20
  // UCOP 456985
  return [job.project.identifier, `${job.name} ${job.identifier}`].join('\n');
};

const getContactInformation = ({job}) => {
  return [job.customer.fullName, Phone.display(job.customer.phoneNumber)].join('\n');
};

const getServiceInformation = ({job}) => {
  return [
    Datetime.convertToDisplayDate(job.day.value),
    `${Datetime.convertToDisplayTime(job.startTime1)} - ` +
      `${Datetime.convertToDisplayTime(job.startTime2)}`,
  ].join('\n');
};

const CommercialCustomerInformationView = ({job}) => (
  <Container>
    <Row>
      <FieldValue label={'Service Identifiers'} value={getServiceIdentifers({job})} width={240} />
      <FieldValue label={'Service Information'} value={getServiceInformation({job})} width={240} />
      <FieldValue label={'Contact Information'} value={getContactInformation({job})} width={240} />
    </Row>
    <Row style={{marginTop: 20}}>
      <FieldValue
        label={'Location(s)'}
        value={job.locations.map((location) => location.address).join('\n')}
      />
    </Row>
    {getAdditionalItems({job}).length > 0 && (
      <Row style={{marginTop: 20}}>
        <FieldValue
          label={'Additional Items'}
          value={getAdditionalItems({job}).join(', ')}
          width={960}
        />
      </Row>
    )}
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
CommercialCustomerInformationView.propTypes = {
  job: PropTypes.object.isRequired,
};

CommercialCustomerInformationView.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialCustomerInformationView.fragment = gql`
  fragment CommercialCustomerInformationView on Job {
    id
    name
    startTime1
    startTime2
    additionalItems
    identifier
    organization {
      id
      slug
      settings {
        id
        jobFormAdditionalItems
      }
    }
    day {
      id
      value
    }
    customer {
      id
      fullName
      phoneNumber
    }
    locations {
      id
      address
    }
    project {
      id
      identifier
    }
  }
`;

export default CommercialCustomerInformationView;
