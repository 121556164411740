// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DefaultProjectTypeInventorySettingsForm from '@shared/modules/Inventory/forms/DefaultProjectTypeInventorySettingsForm';

const useSetDefaultProjectTypeInventorySettingsMutation = ({
  defaultProjectTypeInventorySettingsForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      defaultProjectTypeInventorySettingsForm: DefaultProjectTypeInventorySettingsForm.toForm(
        defaultProjectTypeInventorySettingsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSetDefaultProjectTypeInventorySettingsMutation.mutation,
    variables: {
      defaultProjectTypeInventorySettingsForm: DefaultProjectTypeInventorySettingsForm.toMutation(
        form.values.defaultProjectTypeInventorySettingsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSetDefaultProjectTypeInventorySettingsMutation.mutation = gql`
  mutation useSetDefaultProjectTypeInventorySettingsMutation($defaultProjectTypeInventorySettingsForm: DefaultProjectTypeInventorySettingsForm!) {
    response: setDefaultProjectTypeInventorySettings(defaultProjectTypeInventorySettingsForm: $defaultProjectTypeInventorySettingsForm) {
      ${gql.errors}
    }
  }
`;

export default useSetDefaultProjectTypeInventorySettingsMutation;
