// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {useState} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';

const DuplicateSectionModal = ({
  isOpen,
  handleClose,
  handleDuplicate,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleDuplicate: (name: string) => void;
}) => {
  const [name, setName] = useState('');

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Duplicate Section'}
      description={
        "Enter a new name for the new section. You'll be redirected to the section after saving."
      }
      handleAction={() => {
        handleDuplicate(name);
        handleClose();
      }}
      handleActionText={'Save'}
      isDisabledPrimaryAction={!name}
    >
      <Space height={16} />
      <FieldInput
        label={'Section Name'}
        isRequired
        value={name}
        input={{
          placeholder: 'Enter section name',
          onChangeText: setName,
          autoFocus: true,
        }}
      />
      <Space height={16} />
    </SmallModal>
  );
};

export default DuplicateSectionModal;
