// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useModal} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {Percent, Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Table from '@shared/design/components/Table';
import useDeleteBatchMutation from '@shared/modules/Integration/hooks/useDeleteBatchMutation';
import ImportOrganizationProjectsModal from 'modules/Organization/Settings/Jobs/components/ImportOrganizationProjectsModal';
import ImportOrganizationProjectsSmartMovingModal from 'modules/Organization/Settings/Jobs/components/ImportOrganizationProjectsSmartMovingModal';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const maxWidth = '1300px';

const ProjectImportsTableContainer = Styled.View`
  max-width: ${maxWidth};
`;

const SectionSubtitle = Styled.Text`
  ${Typography.PageHeading}
`;

const DeleteBatchModal = ({isOpen, handleClose, batch, refetch}) => {
  const {handleSubmit, submitting} = useDeleteBatchMutation({
    deleteBatchForm: {batchId: batch.id},
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  const description = `This will delete Batch #${batch.id} which includes ${batch.countsOfRelatedObjects.projectCount} projects, ${batch.countsOfRelatedObjects.jobCount} jobs, and ${batch.countsOfRelatedObjects.clientCount} clients. This action will take at least several minutes and cannot be undone.`;

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete batch?`}
      subtitle={description}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      deleteButtonText={`Delete`}
      isSubmitting={submitting}
    />
  );
};

const getProjectImportColumnDefinitions = ({organization, refetch}) => [
  {
    flex: 1,
    headerLabel: 'Filename',
    cellText: (batch) => batch.files.reduce((acc, file) => acc + file.filename, ''),
    numberOfLines: 3,
  },
  {
    flex: 1,
    headerLabel: 'Status',
    cellText: (batch) => batch.status,
  },
  {
    flex: 1,
    headerLabel: '# of Projects',
    cellText: (batch) => batch.taskCount,
  },
  {
    flex: 1,
    headerLabel: 'Import Progress',
    cellText: (batch) =>
      `${batch.completedTaskCount}/${batch.taskCount} (${Percent.display(
        _.round(batch.progress, 2),
      )})`,
  },
  {
    flex: 1,
    headerLabel: 'Uploaded at',
    cellText: (batch) => Datetime.convertToDisplayDatetime(batch.createdAt),
    numberOfLines: 3,
  },
  {
    flex: 1,
    headerLabel: 'Uploaded by',
    cellText: (batch) => batch.creator.fullName,
  },
  {
    flex: 1,
    headerLabel: 'Actions',
    isHidden: organization.features.isEnabledOfficeDeleteImportBatch,
    actions: (batch) => {
      return [
        {
          text: 'Delete Batch',
          onPress: ({handleOpen}) => handleOpen(),
          actionHook: {
            hook: useModal,
            hookArgument: {
              name: 'Delete Batch Modal',
              enableTracking: true,
            },
            renderComponent: ({isOpen, handleClose}) => {
              return (
                <DeleteBatchModal
                  isOpen={isOpen}
                  handleClose={handleClose}
                  batch={batch}
                  refetch={refetch}
                />
              );
            },
          },
        },
      ];
    },
  },
];

const ProjectImportsTable = ({organization, refetch}) => {
  return (
    <ProjectImportsTableContainer>
      <Table
        columnDefinitions={getProjectImportColumnDefinitions({
          organization,
          refetch,
        })}
        emptyStateText='No project imports to display'
        items={organization.projectImportBatches}
        itemKey={'id'}
        hasBorder
      />
    </ProjectImportsTableContainer>
  );
};

const OrganizationSettingsJobsPageContent = ({data, refetch}) => {
  const importOrganizationProjectsModal = useModal();
  const textObjectGeneralImport = {
    title: 'Import CSV File: Past Moves',
    description:
      'Upload a CSV file of past moves into Supermove. The headers of the CSV file must exactly match the list below. This works best with single-day moves with two locations in the move.',
    textValidationNames: [
      'Company Name: optional',
      'Contact First Name: required',
      'Contact Last Name: required',
      'Contact Email: optional',
      'Contact Phone: optional',
      'Project Type: required, project type name that exists in the system, case sensitive',
      'Job Type: required, job type name that exists in the system, case sensitive',
      'Move Size: required',
      'Salesperson: optional, full name of salesperson that exists in system',
      'Referral Source: optional',
      'Referral Details: optional',
      'Office Notes: optional',
      'Move Date: required, date in YYYY-MM-DD format',
      'Moving From Address: required',
      'Moving From Unit #: optional',
      'Moving From City: optional',
      'Moving From State: optional',
      'Moving From Zip Code: required',
      'Moving From Notes: optional',
      'Moving To Address: required',
      'Moving To Unit #: optional',
      'Moving To City: optional',
      'Moving To State: optional',
      'Moving To Zip Code: required',
      'Moving To Notes: optional',
      'Movers Count: optional',
      'Move Start Time 1: optional',
      'Move Start Time 2: optional',
      'Move Total Price: optional, total cost of moving service',
      'Inventory Estimated Weight: optional, weight in lbs',
      'Inventory Actual Weight: optional, weight in lbs',
    ],
    textHeaders:
      'Company Name,Contact First Name,Contact Last Name,Contact Email,Contact Phone,Project Type,Job Type,Move Size,Salesperson,Referral Source,Referral Details,Office Notes,Move Date,Moving From Address,Moving From Unit #,Moving From City,Moving From State,Moving From Zip Code,Moving From Notes,Moving To Address,Moving To Unit #,Moving To City,Moving To State,Moving To Zip Code,Moving To Notes,Movers Count,Move Start Time 1,Move Start Time 2,Move Total Price,Inventory Estimated Weight,Inventory Actual Weight',
  };

  const importOrganizationProjectsSmartMovingModal = useModal();
  const textObjectSmartMovingImport = {
    title: 'Import Past Moves from Smart Moving',
    description:
      'Upload the CSV file that has been exported from Smart Moving. These jobs will be imported into Supermove.',
  };

  const dropdownOptions = {
    general: {
      label: 'Standard Import',
      modal: importOrganizationProjectsModal,
    },
    smartmoving: {
      label: 'Smart Moving Import',
      modal: importOrganizationProjectsSmartMovingModal,
    },
  };

  const ButtonContainer = Styled.View`
    flex-direction: row;
    z-index: ${({index = 0}) => 100 - index};
  `;

  return (
    <React.Fragment>
      {!data.viewer.viewingOrganization.features.isEnabledImportXlsxProjectSuddath &&
        ['super'].includes(data.viewer.role) && (
          <React.Fragment>
            {data.viewer.viewingOrganization.features.isEnabledOfficeSmartMovingImport ? (
              <React.Fragment>
                <ButtonContainer>
                  <DropdownButton
                    text={'Import from File'}
                    actions={_.map(dropdownOptions, (value, key) => ({
                      text: value.label,
                      onPress: value.modal.handleOpen,
                    }))}
                  />
                </ButtonContainer>

                <ImportOrganizationProjectsSmartMovingModal
                  key={`importOrganizationProjectsSmartMovingModal${importOrganizationProjectsSmartMovingModal.isOpen}`}
                  isOpen={importOrganizationProjectsSmartMovingModal.isOpen}
                  handleClose={importOrganizationProjectsSmartMovingModal.handleClose}
                  refetch={refetch}
                  textObject={textObjectSmartMovingImport}
                />
              </React.Fragment>
            ) : (
              <Button
                onPress={importOrganizationProjectsModal.handleOpen}
                text={'Import CSV File'}
              />
            )}
            <ImportOrganizationProjectsModal
              key={`importOrganizationProjectsModal${importOrganizationProjectsModal.isOpen}`}
              isOpen={importOrganizationProjectsModal.isOpen}
              handleClose={importOrganizationProjectsModal.handleClose}
              refetch={refetch}
              textObject={textObjectGeneralImport}
            />
            <Space height={16} />
            <SectionSubtitle>Previous Imports</SectionSubtitle>
            <Space height={16} />
            <ProjectImportsTable organization={data.viewer.viewingOrganization} refetch={refetch} />
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

const OrganizationSettingsJobsPage = () => {
  const {navigator} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(OrganizationSettingsJobsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SettingsPage
      title={'Import Past Moves'}
      description={
        'Import information about past moves your company serviced. Press the button below to view what columns are supported.'
      }
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {name: 'Import Jobs'},
      ]}
    >
      <Loading loading={loading}>
        {() => <OrganizationSettingsJobsPageContent data={data} refetch={refetch} />}
      </Loading>
    </SettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsJobsPage.query = gql`
  query OrganizationSettingsJobsPage {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        features {
          isEnabledImportXlsxProjectSuddath: isEnabled(feature: "IMPORT_XLSX_PROJECT_SUDDATH")
          isEnabledOfficeDeleteImportBatch: isEnabled(feature:"OFFICE_DELETE_IMPORT_BATCH") 
          isEnabledOfficeSmartMovingImport: isEnabled(feature: "OFFICE_SMART_MOVING_IMPORT")
        }
        projectImportBatches {
          id
          createdAt
          creator {
            id
            fullName
          }
          status
          progress
          taskCount
          completedTaskCount
          countsOfRelatedObjects {
            projectCount
            clientCount
            jobCount
          }
          files {
            id
            filename
          }
        }
      }
    }
  }
`;

export default OrganizationSettingsJobsPage;
