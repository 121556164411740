// Libararies
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {List, withFragment} from '@supermove/utils';

// App
import ItemTagKind from '@shared/modules/Inventory/enums/ItemTagKind';

// NOTE(cooper): This _new() constructor looks different from our usual constructors.
// This is because in this case we are instantiating the form after the ItemTagItem
// has been created. This is to support "suboptimal" network mode for driver inventory
const _new = ({kind, itemTagIds, uuid, isDeleted}: any) => ({
  itemTagItemId: null,
  uuid,
  itemId: null, // NOTE(cooper): We don't have itemIds at the time of creation
  itemTagIds,
  kind,
  isDeleted,
});

const edit = withFragment(
  (itemTagItem) => ({
    itemTagItemId: (itemTagItem as any).id,
    uuid: (itemTagItem as any).uuid,
    itemId: (itemTagItem as any).itemId,
    itemTagIds: (itemTagItem as any).itemTagIds,
    kind: (itemTagItem as any).kind,
    isDeleted: (itemTagItem as any).isDeleted,
  }),
  gql`
    fragment ItemTagItemForm_edit on ItemTagItem {
      id
      uuid
      itemId
      itemTagIds
      kind
      isDeleted
    }
  `,
);

const toForm = ({itemTagItemId, uuid, itemId, itemTagIds, kind, isDeleted}: any) => ({
  itemTagItemId,
  uuid,
  itemId,
  itemTagIds,
  kind,
  isDeleted,
});

const toMutation = ({
  itemTagItemId,
  uuid,
  itemId,
  itemTagIds,
  kind,
  isDeleted,

  // NOTE(cooper): Item uuid gets injected when the mutation fires,
  // we don't have it available when the ItemTagItemForm gets created
  itemUuid,
}: any) => ({
  itemTagItemId,
  uuid,
  itemId,
  itemUuid,
  itemTagIds,
  kind,
  isDeleted,
});

const getSelectedItemTagsForItemTagKind = withFragment(
  (inventoryLibrary, {selectedItemTagIds, itemTagKind}) => {
    // @ts-expect-error TS(2339): Property 'itemTags' does not exist on type 'unknow... Remove this comment to see the full error message
    const {itemTags, exceptionLocations, exceptionTypes} = inventoryLibrary;

    const genericGetSelected = (tags: any) =>
      _.reduce(
        selectedItemTagIds,
        // @ts-expect-error TS(2769): No overload matches this call.
        (selectedItemTags, itemTagId) => {
          const foundItemTag = _.find(tags, {id: itemTagId});
          return [...selectedItemTags, ...List.insertIf(!!foundItemTag, foundItemTag)];
        },
        [],
      );

    switch (itemTagKind) {
      case ItemTagKind.ITEM_TAG:
        return genericGetSelected(itemTags);
      case ItemTagKind.EXCEPTION_LOCATION:
        return genericGetSelected(exceptionLocations);
      case ItemTagKind.EXCEPTION_TYPE:
        return genericGetSelected(exceptionTypes);
      default:
        return [];
    }
  },
  gql`
    fragment ItemTagItemForm_getSelectedItemTagsForItemTagKind on InventoryLibrary {
      id
      itemTags: itemTagsByKind(itemTagKind: "${ItemTagKind.ITEM_TAG}") {
        id
        name
      }
      exceptionTypes: itemTagsByKind(itemTagKind: "${ItemTagKind.EXCEPTION_TYPE}") {
        id
        name
      }
      exceptionLocations: itemTagsByKind(itemTagKind: "${ItemTagKind.EXCEPTION_LOCATION}") {
        id
        name
      }
    }
  `,
);

const ItemTagItemForm = {
  new: _new,
  edit,
  toForm,
  toMutation,

  // Helpers
  getSelectedItemTagsForItemTagKind,
};

export default ItemTagItemForm;
