import {Environment} from '@supermove/sdk';

type Values = {
  publicKey: string;
  scriptURL: string;
  // Smaller script that only pulls in form field components
  secureFieldsScriptURL: string;
};

export const PayEngineConfig = {
  get: (mode: 'LIVE' | 'SANDBOX'): Values => {
    const env = Environment.getAppEnv();

    console.log(`Loading PayEngine config with mode: ${mode}`);

    switch (mode) {
      case 'LIVE':
        switch (env) {
          case 'development':
            return {
              publicKey: 'pk_test_fZCR6FSN39ySf7cI6xIYLi2MfNs17ksX',
              scriptURL: 'https://console.payengine.dev/js/1.0.0/embed.js',
              secureFieldsScriptURL: 'https://console.payengine.dev/js/1.0.0/securefields.min.js',
            };
          case 'staging':
            return {
              publicKey: 'pk_test_i48OZmGjQpRFMIVPnVjfghSDEq4R1L7X',
              scriptURL: 'https://console.payengine.dev/js/1.0.0/embed.js',
              secureFieldsScriptURL: 'https://console.payengine.dev/js/1.0.0/securefields.min.js',
            };
          case 'production':
            return {
              publicKey: 'pk_prod_EaJaIPphuaiQwAzrcfK2KA9cncGXTqC3',
              scriptURL: 'https://console.payengine.co/js/1.0.0/embed.js',
              secureFieldsScriptURL: 'https://console.payengine.co/js/1.0.0/securefields.min.js',
            };
          default:
            throw Error(`Unrecognized env value: ${env}`);
        }
      case 'SANDBOX':
        // Same accounts are used for dev & staging, just so we don't have to maintain 3 "live" accounts and
        // 3 "sandbox" accounts.
        switch (env) {
          case 'development':
            return {
              publicKey: 'pk_test_fZCR6FSN39ySf7cI6xIYLi2MfNs17ksX',
              scriptURL: 'https://console.payengine.dev/js/1.0.0/embed.js',
              secureFieldsScriptURL: 'https://console.payengine.dev/js/1.0.0/securefields.min.js',
            };
          case 'staging':
            return {
              publicKey: 'pk_test_i48OZmGjQpRFMIVPnVjfghSDEq4R1L7X',
              scriptURL: 'https://console.payengine.dev/js/1.0.0/embed.js',
              secureFieldsScriptURL: 'https://console.payengine.dev/js/1.0.0/securefields.min.js',
            };
          case 'production':
            return {
              publicKey: 'pk_test_o4V45mx8Nd95zyVC5hYYvb9wmV0jlTas',
              scriptURL: 'https://console.payengine.dev/js/1.0.0/embed.js',
              secureFieldsScriptURL: 'https://console.payengine.dev/js/1.0.0/securefields.min.js',
            };
          default:
            throw Error(`Unrecognized env value: ${env}`);
        }
      default:
        throw Error(`Unrecognized merchant mode: ${mode}`);
    }
  },
};
