// Supermove
import {StripeTerminalType} from '@supermove/payments';

const TEST_READERS = [
  // Fake device for testing
  {
    deviceType: 'CHIPPER_2X',
    isSimulated: true,
    serialNumber: 'TEST',
    softwareVersion: 'unknown',
  },

  // Simulator device from the native library.
  {
    deviceType: 'CHIPPER_2X',
    isSimulated: true,
    serialNumber: 'CHB20SIMULATOR',
    softwareVersion: 'unknown',
  },
];

// NOTE(mark): There isn't a StripeTerminal for web so this is a no-op module.
const StripeTerminal: StripeTerminalType = {
  initialize: async ({host, endpoint, stripeAccountId, onDisconnect}) => {
    // TODO
  },

  getConnectedReader: async () => {
    // TODO
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(null), 2000);
    });
  },

  discoverReaders: async ({deviceType, timeout, simulator}) => {
    if (!simulator) {
      return [];
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(TEST_READERS), 3000);
    });
  },

  connectReader: async ({deviceType, timeout, simulator, serialNumber}) => {
    // TODO(mark): This is mocked to "connect" after 3 seconds.
    return new Promise((resolve, reject) => {
      // Uncomment this to test the successful case.
      setTimeout(() => resolve({deviceType, timeout, simulator, serialNumber}), 3000);

      // Uncomment this to test the failure case.
      // setTimeout(() => reject(new Error('Could not connect to reader')), 3000);
    });
  },

  confirmPaymentIntent: async ({clientSecret}) => {
    // TODO
  },

  // Constants
  DeviceTypeChipper2X: 'DeviceTypeChipper2X',
  DiscoveryMethodBluetoothScan: 'DiscoveryMethodBluetoothScan',
  DiscoveryMethodBluetoothProximity: 'DiscoveryMethodBluetoothProximity',
  PaymentIntentStatusRequiresPaymentMethod: 'PaymentIntentStatusRequiresPaymentMethod',
  PaymentIntentStatusRequiresConfirmation: 'PaymentIntentStatusRequiresConfirmation',
  PaymentIntentStatusRequiresCapture: 'PaymentIntentStatusRequiresCapture',
  PaymentIntentStatusCanceled: 'PaymentIntentStatusCanceled',
  PaymentIntentStatusSucceeded: 'PaymentIntentStatusSucceeded',
  ReaderEventCardInserted: 'ReaderEventCardInserted',
  ReaderEventCardRemoved: 'ReaderEventCardRemoved',
  PaymentStatusNotReady: 'PaymentStatusNotReady',
  PaymentStatusReady: 'PaymentStatusReady',
  PaymentStatusWaitingForInput: 'PaymentStatusWaitingForInput',
  PaymentStatusProcessing: 'PaymentStatusProcessing',
  ConnectionStatusNotConnected: 'ConnectionStatusNotConnected',
  ConnectionStatusConnected: 'ConnectionStatusConnected',
  ConnectionStatusConnecting: 'ConnectionStatusConnecting',
};

export default StripeTerminal;
