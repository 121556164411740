// Supermove
import {colors} from '@supermove/styles';

const DRAFT = 'DRAFT';
const SCHEDULED = 'SCHEDULED';
const UNPAID = 'UNPAID';
const PAID = 'PAID';
const OVERDUE = 'OVERDUE';
const CANCELLED = 'CANCELLED';
const FINALIZED = 'FINALIZED';

const DRAFT_OPTION = {
  value: 'DRAFT',
  label: 'Draft',
  backgroundColor: colors.gray.border,
  textColor: colors.gray.primary,
};

const SCHEDULED_OPTION = {
  value: 'SCHEDULED',
  label: 'Scheduled',
  backgroundColor: colors.blue.interactive,
  textColor: colors.white,
};

const UNPAID_OPTION = {
  value: 'UNPAID',
  label: 'Unpaid',
  backgroundColor: colors.yellow.branding,
  textColor: colors.white,
};

const PAID_OPTION = {
  value: 'PAID',
  label: 'Paid',
  backgroundColor: colors.green.branding,
  textColor: colors.white,
};

const OVERDUE_OPTION = {
  value: 'OVERDUE',
  label: 'Overdue',
  backgroundColor: colors.red.branding,
  textColor: colors.white,
};

const CANCELLED_OPTION = {
  value: 'CANCELLED',
  label: 'Cancelled',
  backgroundColor: colors.gray.background,
  textColor: colors.gray.secondary,
};

const FINALIZED_OPTION = {
  value: 'FINALIZED',
  label: 'Finalized',
  backgroundColor: colors.green.status,
  textColor: colors.white,
};

const OPTIONS = [
  DRAFT_OPTION,
  SCHEDULED_OPTION,
  UNPAID_OPTION,
  PAID_OPTION,
  OVERDUE_OPTION,
  CANCELLED_OPTION,
  FINALIZED_OPTION,
];

const STATUS_OPTION_MAP = {
  [DRAFT]: DRAFT_OPTION,
  [SCHEDULED]: SCHEDULED_OPTION,
  [UNPAID]: UNPAID_OPTION,
  [PAID]: PAID_OPTION,
  [OVERDUE]: OVERDUE_OPTION,
  [CANCELLED]: CANCELLED_OPTION,
  [FINALIZED]: FINALIZED_OPTION,
};

const getStatusOption = (status: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return STATUS_OPTION_MAP[status];
};

const ALL_STATUSES = [DRAFT, SCHEDULED, UNPAID, PAID, OVERDUE, CANCELLED, FINALIZED];

const InvoiceStatus = {
  OPTIONS,
  ALL_STATUSES,

  DRAFT,
  SCHEDULED,
  UNPAID,
  PAID,
  OVERDUE,
  CANCELLED,
  FINALIZED,

  getStatusOption,
};

export default InvoiceStatus;
