// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Variable} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import TextTooltip from '@shared/design/components/TextTooltip';
import DataTableKind from '@shared/modules/Billing/enums/DataTableKind';
import DataTableVariableAccessKind from '@shared/modules/Billing/enums/DataTableVariableAccessKind';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import VariableKind from '@shared/modules/Billing/enums/VariableKind';
import DataTableForm from '@shared/modules/Billing/forms/DataTableForm';
import DataTableVariableForm from '@shared/modules/Billing/forms/DataTableVariableForm';
import VariableForm from '@shared/modules/Billing/forms/VariableForm';
import Switch from 'modules/App/components/Switch';
import UploadDataTableFields from 'modules/Organization/Settings/Billing/Tables/components/UploadDataTableFields';

const Row = Styled.View`
  flex-direction: row;
`;

const Button = Styled.ButtonV2`
`;

const FieldContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const TableToggleText = Styled.Text`
  ${Typography.Label2}
  color: ${({color}) => color};
`;

const DataTableFieldsContainer = Styled.View`
`;

const getDataTableOptions = ({dataTables}) => {
  return dataTables.reduce((filtered, dataTable) => {
    return dataTable.kind === DataTableKind.VARIABLE
      ? [
          ...filtered,
          {
            value: dataTable.id,
            label: dataTable.name,
          },
        ]
      : filtered;
  }, []);
};

const handleToggleTable = ({form, field}) => {
  const isToggleToTableVariable = !VariableForm.getHasDataTable(_.get(form.values, field));
  form.setFieldValue(`${field}.format`, null);
  if (isToggleToTableVariable) {
    form.setFieldValue(`${field}.dataTableVariableForm`, DataTableVariableForm.new());
  } else {
    form.setFieldValue(`${field}.dataTableVariableForm`, null);
  }
};

const VariableTableToggle = ({form, field}) => {
  const hasTable = VariableForm.getHasDataTable(_.get(form.values, field));
  return (
    <Row>
      <Button
        style={{flexDirection: 'row', alignItems: 'center'}}
        onPress={() => handleToggleTable({form, field})}
      >
        <Switch.Button isOn={hasTable} color={colors.blue.interactive} />
        <Space width={8} />
        <TableToggleText color={hasTable ? colors.blue.interactive : colors.gray.tertiary}>
          Calculate variable with table
        </TableToggleText>
      </Button>
    </Row>
  );
};

const UseBreakpointToggle = ({form, field}) => {
  const breakpointField = `${field}.dataTableVariableForm.shouldUseBreakpoint`;
  const isUsingBreakpoint = _.get(form.values, breakpointField);
  return (
    <Row>
      <Button
        style={{flexDirection: 'row', alignItems: 'center'}}
        onPress={() => form.setFieldValue(breakpointField, !isUsingBreakpoint)}
      >
        <Switch.Button isOn={isUsingBreakpoint} color={colors.blue.interactive} />
        <Space width={8} />
        <TableToggleText color={isUsingBreakpoint ? colors.blue.interactive : colors.gray.tertiary}>
          Use breakpoints for calculations
        </TableToggleText>
      </Button>
    </Row>
  );
};

const FormatField = ({form, field, index, options, label}) => {
  const isExistingVariable = _.get(form.values, `${field}.isExistingVariable`);
  return (
    <React.Fragment>
      {isExistingVariable ? (
        <FieldInput
          {...form}
          index={index}
          label={label}
          name={`${field}.format`}
          component={DisabledInputWithTooltip}
          input={{
            value: VariableForm.getDisplayFormat(_.get(form.values, field)),
            tooltip: 'Cannot change format',
          }}
        />
      ) : (
        <FieldInput
          {...form}
          index={index}
          label={label}
          name={`${field}.format`}
          component={DropdownInput}
          input={{
            required: !_.get(form.values, `${field}.format`),
            options,
            placeholder: 'Select a variable format',
            setFieldValue: form.setFieldValue,
            style: {width: '100%'},
            isPortaled: true,
          }}
        />
      )}
    </React.Fragment>
  );
};

const DataTableFields = ({form, field, viewer, isExistingVariable}) => {
  const variableForm = _.get(form.values, field);
  const dataTableVariableForm = _.get(variableForm, 'dataTableVariableForm');
  const {isAddingNewDataTable} = variableForm;
  const organization = viewer.viewingOrganization;
  const {variables} = organization;
  const isUsingTariff = VariableForm.getIsUsingTariff(variableForm);
  return (
    <DataTableFieldsContainer>
      {isExistingVariable ? (
        <FieldInput
          {...form}
          index={0}
          label={'Calculation Type'}
          name={`${field}.dataTableVariableForm.tableAccessKind`}
          component={DisabledInputWithTooltip}
          input={{
            value: DataTableVariableAccessKind.getDisplayName(
              dataTableVariableForm.tableAccessKind,
            ),
            tooltip: 'Cannot change calculation type',
          }}
        />
      ) : (
        <FieldInput
          {...form}
          index={0}
          label={'Calculation Type'}
          name={`${field}.dataTableVariableForm.tableAccessKind`}
          component={DropdownInput}
          input={{
            disabled: isExistingVariable,
            tooltip: 'Cannot change calculation type',
            required: !_.get(form.values, `${field}.dataTableVariableForm.tableAccessKind`),
            options: DataTableVariableAccessKind.DROPDOWN_OPTIONS,
            placeholder: 'Select a calculation type',
            setFieldValue: form.setFieldValue,
            style: {width: '100%'},
            onChangeValue: (tableAccessKind) => {
              const format = DataTableVariableAccessKind.getVariableFormat(tableAccessKind);
              form.setFieldValue(`${field}.format`, format);
              if (
                !DataTableVariableAccessKind.VALUES_THAT_SUPPORT_JOB_TYPE_VARIABLES.includes(
                  tableAccessKind,
                )
              ) {
                form.setFieldValue(`${field}.kind`, VariableKind.PROJECT);
              }
              form.setFieldValue(
                `${field}.isDerived`,
                DataTableVariableAccessKind.DERIVED_KINDS.includes(tableAccessKind),
              );
              form.setFieldValue(`${field}.dataTableVariableForm.shouldUseBreakpoint`, false);
            },
          }}
        />
      )}
      {isUsingTariff && (
        <React.Fragment>
          <Space height={20} />
          <UseBreakpointToggle form={form} field={field} />
        </React.Fragment>
      )}
      {dataTableVariableForm &&
      dataTableVariableForm.tableAccessKind === DataTableVariableAccessKind.VARIABLE_MAPPING ? (
        <React.Fragment>
          <Space height={20} />
          <FieldInput
            {...form}
            index={1}
            label={'Input Variable'}
            name={`${field}.dataTableVariableForm.inputVariableId`}
            component={DropdownInput}
            input={{
              required: !_.get(form.values, `${field}.dataTableVariableForm.inputVariableId`),
              // Derived variables can be used for input variables, but not other mapping table variables
              options: _.sortBy(
                variables.filter((variable) => !variable.isVariableMappingTableVariable),
                'name',
              ).map((variable) => ({
                value: variable.id,
                label: variable.name,
              })),
              placeholder: 'Select a variable',
              setFieldValue: form.setFieldValue,
              style: {width: '100%'},
            }}
          />
        </React.Fragment>
      ) : null}

      {dataTableVariableForm &&
        DataTableVariableAccessKind.VALUES_WITH_CUSTOM_FORMAT.includes(
          dataTableVariableForm.tableAccessKind,
        ) && (
          <React.Fragment>
            <Space height={20} />
            <FormatField
              form={form}
              field={field}
              index={2}
              options={VariableFormat.TABLE_DROPDOWN_OPTIONS}
              label={'Output Format'}
            />
          </React.Fragment>
        )}
      <Space height={20} />
      {isAddingNewDataTable ? (
        <FieldContainer index={2}>
          <UploadDataTableFields
            form={form}
            field={`${field}.dataTableVariableForm.dataTableForm`}
            tableAccessKind={_.get(form.values, `${field}.dataTableVariableForm.tableAccessKind`)}
            showTableKindDropdown={false}
          />
          <Space height={8} />
          <Button
            onPress={() => {
              form.setFieldValue(`${field}.isAddingNewDataTable`, false);
              form.setFieldValue(`${field}.dataTableVariableForm.dataTableForm`, null);
            }}
          >
            <TableToggleText color={colors.blue.interactive}>
              Choose from your tables
            </TableToggleText>
          </Button>
        </FieldContainer>
      ) : (
        <FieldContainer index={3}>
          <FieldInput
            {...form}
            label={'Table'}
            name={`${field}.dataTableVariableForm.dataTableId`}
            component={DropdownInput}
            input={{
              required: !_.get(form.values, `${field}.dataTableVariableForm.dataTableId`),
              options: getDataTableOptions({dataTables: viewer.viewingOrganization.dataTables}),
              placeholder: 'Select a table',
              setFieldValue: form.setFieldValue,
              style: {width: '100%'},
            }}
          />
          <Space height={8} />
          <Button
            onPress={() => {
              const dataTableForm = DataTableForm.new({
                userId: viewer.id,
                organizationId: viewer.viewingOrganization.id,
                kind: DataTableKind.VARIABLE,
              });
              form.setFieldValue(`${field}.isAddingNewDataTable`, true);
              form.setFieldValue(`${field}.dataTableVariableForm.dataTableForm`, dataTableForm);
            }}
          >
            <TableToggleText color={colors.blue.interactive}>Add a new table</TableToggleText>
          </Button>
          <Space height={8} />
        </FieldContainer>
      )}
    </DataTableFieldsContainer>
  );
};

const DisabledInputWithTooltip = ({value, tooltip}) => {
  return (
    <TextTooltip text={tooltip}>
      <FieldInput.TextInput value={value} disabled />
    </TextTooltip>
  );
};

const VariableDrawerFieldsContent = ({form, field, viewer}) => {
  const isExistingVariable = _.get(form.values, `${field}.isExistingVariable`);
  const {dataTableVariableForm} = form.values.variableForm;

  const doesCalculationTypeDisableKindDropdown =
    dataTableVariableForm &&
    dataTableVariableForm.tableAccessKind &&
    !DataTableVariableAccessKind.VALUES_THAT_SUPPORT_JOB_TYPE_VARIABLES.includes(
      dataTableVariableForm.tableAccessKind,
    );
  const disableKindDropdown = doesCalculationTypeDisableKindDropdown;
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        label={'Name'}
        name={`${field}.name`}
        input={{
          required: !_.get(form.values, `${field}.name`),
          placeholder: 'Enter a variable name',
          onChangeText: (text) => {
            if (!isExistingVariable) {
              form.setFieldValue(`${field}.identifier`, Variable.getIdentifierText(text));
            }
          },
        }}
      />
      <Space height={20} />
      {isExistingVariable ? (
        <FieldInput
          {...form}
          label={'Identifier'}
          name={`${field}.identifier`}
          component={DisabledInputWithTooltip}
          input={{
            value: _.get(form.values, `${field}.identifier`),
            tooltip: 'Cannot change identifier',
          }}
        />
      ) : (
        <FieldInput
          {...form}
          label={'Identifier'}
          name={`${field}.identifier`}
          input={{
            disabled: true,
          }}
        />
      )}
      <Space height={20} />
      <FieldInput
        {...form}
        index={2}
        label={'Description'}
        name={`${field}.description`}
        input={{
          placeholder: 'Enter a variable description',
        }}
      />
      <Space height={20} />
      {isExistingVariable ? (
        <FieldInput
          {...form}
          index={3}
          label={'Category'}
          name={`${field}.kind`}
          component={DisabledInputWithTooltip}
          input={{
            value: `${VariableForm.getDisplayKind(_.get(form.values, field))} Type`,
            tooltip: 'Cannot change category',
          }}
        />
      ) : (
        <FieldInput
          {...form}
          index={3}
          label={'Category'}
          name={`${field}.kind`}
          component={DropdownInput}
          input={{
            disabled: disableKindDropdown,
            required: !_.get(form.values, `${field}.kind`),
            options: Variable.getVariableDropdown(VariableKind.getCreatableKinds()).map(
              (option) => ({
                ...option,
                label: `${option.label} Type`,
              }),
            ),
            placeholder: 'Select a variable category',
            setFieldValue: form.setFieldValue,
            style: {width: '100%'},
          }}
        />
      )}
      <React.Fragment>
        <Space height={24} />
        <VariableTableToggle form={form} field={field} />
        <Space height={20} />
      </React.Fragment>
      <Space height={20} />
      {VariableForm.getHasDataTable(_.get(form.values, field)) ? (
        <DataTableFields
          form={form}
          field={field}
          index={5}
          viewer={viewer}
          isExistingVariable={isExistingVariable}
        />
      ) : (
        <FormatField
          form={form}
          field={field}
          index={5}
          options={VariableFormat.getDropdownOptions()}
          label={'Format'}
        />
      )}
    </React.Fragment>
  );
};

const VariableDrawerFields = ({form, field}) => {
  const {data, loading} = useQuery(VariableDrawerFields.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Loading loading={loading}>
      {() => <VariableDrawerFieldsContent form={form} field={field} viewer={data.viewer} />}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
VariableDrawerFields.query = gql`
  query VariableDrawerFields {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        dataTables {
          id
          name
          kind
        }
        variables {
          id
          isDerived
          name
          format
          isVariableMappingTableVariable
        }
      }
    }
  }
`;

export default VariableDrawerFields;
