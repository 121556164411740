// Libraries
import React from 'react';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';

const ConfirmSyncInventoryBillModal = ({isOpen, handleClose, handleSubmit, submitting}) => {
  return (
    <CautionModal
      isOpen={isOpen}
      title={'Regenerate Bill?'}
      message={`This action will regenerate the packing bill and any changes you made to that bill will be removed. This action cannot be undone.`}
      primaryActionText={'Confirm'}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      handleSecondaryAction={handleClose}
      handlePressOutside={handleClose}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ConfirmSyncInventoryBillModal.propTypes = {};

ConfirmSyncInventoryBillModal.defaultProps = {};

export default ConfirmSyncInventoryBillModal;
