/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Currency, Json} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const getFieldName = ({field, name}) => {
  return field ? `${field}.${name}` : name;
};

const getCrewSize = ({moveSize, jobFormCustomValues}) => {
  const option = _.find(jobFormCustomValues.moveSize, (option) => option.value === moveSize);
  return String(_.get(option, 'crewSize', ''));
};

const getEstimateHours1 = ({moveSize, jobFormCustomValues}) => {
  const option = _.find(jobFormCustomValues.moveSize, (option) => option.value === moveSize);
  return String(_.get(option, 'estimateHours1', ''));
};

const getEstimateHours2 = ({moveSize, jobFormCustomValues}) => {
  const option = _.find(jobFormCustomValues.moveSize, (option) => option.value === moveSize);
  return String(_.get(option, 'estimateHours2', ''));
};

const getHourMinimum = ({moveSize, jobFormCustomValues}) => {
  const option = _.find(jobFormCustomValues.moveSize, (option) => option.value === moveSize);
  return String(_.get(option, 'hourMinimum', ''));
};

const getHourlyRate = ({crewSize, jobFormCustomValues}) => {
  const option = _.find(jobFormCustomValues.crewSize, (option) => option.value === crewSize);
  return String(_.get(option, 'hourlyRate', ''));
};

const getTravelFee = ({crewSize, jobFormCustomValues}) => {
  const option = _.find(jobFormCustomValues.crewSize, (option) => option.value === crewSize);
  return String(_.get(option, 'travelFee', ''));
};

const getFuelFee = ({crewSize, jobFormCustomValues}) => {
  const option = _.find(jobFormCustomValues.crewSize, (option) => option.value === crewSize);
  const value = String(_.get(option, 'fuelFee', ''));
  return value ? Currency.display(value, {shouldHideCentsIfZero: true}) : '';
};

const updateFieldsFromMoveSize = ({moveSize, field, form, jobFormCustomValues}) => {
  const crewSize = getCrewSize({moveSize, jobFormCustomValues});
  const estimateHours1 = getEstimateHours1({moveSize, jobFormCustomValues});
  const estimateHours2 = getEstimateHours2({moveSize, jobFormCustomValues});
  const hourMinimum = getHourMinimum({moveSize, jobFormCustomValues});
  if (crewSize) {
    form.setFieldValue(getFieldName({field, name: 'crewSize'}), crewSize);
  }
  form.setFieldValue(getFieldName({field, name: 'estimateHours1'}), estimateHours1);
  form.setFieldValue(getFieldName({field, name: 'estimateHours2'}), estimateHours2);
  form.setFieldValue(getFieldName({field, name: 'hourMinimum'}), hourMinimum);
  return {
    crewSize,
  };
};

const updateFieldsFromCrewSize = ({crewSize, field, form, jobFormCustomValues}) => {
  const hourlyRate = getHourlyRate({crewSize, jobFormCustomValues});
  const travelFee = getTravelFee({crewSize, jobFormCustomValues});
  const fuelFee = getFuelFee({crewSize, jobFormCustomValues});
  // Set the hourlyRate, travelFee, and fuelFee on the form if custom values are available.
  form.setFieldValue(getFieldName({field, name: 'hourlyRate'}), hourlyRate);
  form.setFieldValue(getFieldName({field, name: 'travelFee'}), travelFee);
  form.setFieldValue(getFieldName({field, name: 'fuelFee'}), fuelFee);
};

const getProjectSizeValueOptions = ({
  isEnabledBillingAdditionalVariables,
  organization,
  hardcodedOptions,
}) => {
  const {projectSizeVariable} = organization;
  if (isEnabledBillingAdditionalVariables && projectSizeVariable) {
    const options = JSON.parse(projectSizeVariable.variableOptionsV3);
    if (!_.isEmpty(options)) {
      return options;
    }
  }
  return hardcodedOptions;
};

const ProjectSizeInput = ({organization, form, field, style}) => {
  const responsive = useResponsive();
  const jobFormCustomValues = Json.toForm(organization.settings.jobFormCustomValues);
  const jobForms = _.get(form, `values.${field}.jobForms`);
  const indexFirstMoveJobForm = _.findIndex(jobForms, (jobForm) => jobForm.kind !== 'ESTIMATE');
  const jobField = `${field}.jobForms.${indexFirstMoveJobForm}`;
  const {
    isEnabledProjectSizeIsRequired,
    isEnabledProjectSizeIsTextInput,
    isEnabledHardcodedProjectSizeInput,
    isEnabledBillingAdditionalVariables,
  } = organization.features;
  const isRequired = isEnabledProjectSizeIsRequired && !_.get(form, `values.${field}.size`);

  const projectValueForms = _.get(form, `values.${field}.valueForms`, []);
  const projectSizeValueForm = _.find(
    projectValueForms,
    (valueForm) => valueForm.variableIdentifier === 'PROJECT_SIZE',
  );
  const projectSizeValueIsVisibleForCreateProject = _.get(
    projectSizeValueForm,
    'isVisibleForCreateProject',
    false,
  );

  // NOTE(jholston): We are deprecating the hardcoded project size input for all new organizations.
  // Existing organizations will still have the input visible, but we'll completely remove ProjectSizeInput
  // when we remove isEnabledHardcodedProjectSizeInput.
  // isEnabledBillingAdditionalVariables ensures we only ever hide if the PROJECT_SIZE variable has been created.
  if (
    isEnabledBillingAdditionalVariables &&
    (!isEnabledHardcodedProjectSizeInput || projectSizeValueIsVisibleForCreateProject)
  )
    return null;

  if (isEnabledProjectSizeIsTextInput) {
    return (
      <FieldInput.Memoized
        {...form}
        name={`${field}.size`}
        label={'Project Size'}
        input={{
          required: isRequired,
          placeholder: 'Enter project size',
          style: style || {
            width: responsive.mobile ? '100%' : 250,
          },
        }}
        style={style}
      />
    );
  }

  return (
    <FieldInput
      {...form}
      index={0}
      component={DropdownInput}
      name={`${field}.size`}
      label={'Project Size'}
      input={{
        options: getProjectSizeValueOptions({
          isEnabledBillingAdditionalVariables,
          organization,
          hardcodedOptions: jobFormCustomValues.moveSize,
        }),
        required: isRequired,
        placeholder: 'Select Project Size',
        isSearchable: true,
        setFieldValue: form.setFieldValue,
        onChangeValue: (value) => {
          const {crewSize} = updateFieldsFromMoveSize({
            moveSize: value,
            field: jobField,
            form,
            jobFormCustomValues,
          });
          if (crewSize) {
            updateFieldsFromCrewSize({
              crewSize,
              field: jobField,
              form,
              jobFormCustomValues,
            });
          }
        },
        style: style || {
          width: responsive.mobile ? '100%' : 250,
        },
      }}
      style={style}
    />
  );
};

ProjectSizeInput.getProjectSizeValueOptions = getProjectSizeValueOptions;

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectSizeInput.fragment = gql`
  fragment ProjectSizeInput on Organization {
    id
    settings {
      id
      jobFormCustomValues
    }
    projectSizeVariable {
      id
      variableOptionsV3
    }
    features {
      isEnabledProjectSizeIsRequired: isEnabled(feature: "PROJECT_SIZE_IS_REQUIRED")
      isEnabledProjectSizeIsTextInput: isEnabled(feature: "PROJECT_SIZE_IS_TEXT_INPUT")
      isEnabledHardcodedProjectSizeInput: isEnabled(feature: "HARDCODED_PROJECT_SIZE_INPUT")
      isEnabledBillingAdditionalVariables: isEnabled(feature: "BILLING_ADDITIONAL_VARIABLES")
    }
  }
`;

export default ProjectSizeInput;
