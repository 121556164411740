// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import MessageModal from '@shared/design/components/Modal/SmallModal/MessageModal';

const QuitEditingWidgetModal = ({quitEditingModal, handleCloseParent}) => {
  return (
    <MessageModal
      isOpen={quitEditingModal.isOpen}
      title={'Quit editing?'}
      message={`Your changes will not be saved.`}
      handlePressOutside={quitEditingModal.handleClose}
      handlePrimaryAction={quitEditingModal.handleClose}
      handleSecondaryAction={() => {
        handleCloseParent();
        quitEditingModal.handleClose();
      }}
      primaryActionText={'Keep Editing'}
      secondaryActionText={'Quit Without Saving'}
    />
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
QuitEditingWidgetModal.propTypes = {
  quitEditingModal: PropTypes.object.isRequired,
  handleCloseParent: PropTypes.func.isRequired,
};

QuitEditingWidgetModal.defaultProps = {};

export default QuitEditingWidgetModal;
