// Libraries
import React from 'react';

// Supermove
import {Emoji, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// Components
import CommercialReportMoveTimesheetTable from 'modules/Job/Commercial/Timesheet/components/CommercialReportMoveTimesheetTable';
import BillingProjectBillsListV1 from 'modules/Project/Billing/components/BillingProjectBillsListV1';
import BillingProjectPaymentsList from 'modules/Project/Billing/components/BillingProjectPaymentsList';
import ReportMoveMoveUsers from 'modules/ReportMove/components/ReportMoveMoveUsers';
import ReportMoveMoveUsersTip from 'modules/ReportMove/components/ReportMoveMoveUsersTip';
import ReportMoveTimeRanges from 'modules/ReportMove/components/ReportMoveTimeRanges';

const Container = Styled.View`
`;

const MiniRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Left = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const Row = Styled.View`
  margin-bottom: 40px;
  z-index: ${({index = 0}) => 100 - index};
`;

const RowHeader = Styled.View`
  margin-bottom: 20px;
`;

const SectionTitle = Styled.H5`
  ${fontWeight(700)}
`;

const Line = Styled.View`
  margin-bottom: 40px;
  height: 10px;
  background-color: ${colors.gray.border};
`;

const ReportMoveInfoV1 = ({reportMove, refetch}) => {
  const {isEnabledHideEditReportCrewTips} = reportMove.organization.features;
  const isEnabledCrewTips = !isEnabledHideEditReportCrewTips;

  return (
    <Container>
      <Row index={1}>
        <RowHeader>
          <MiniRow>
            <Left>
              <Emoji component={EmojiText} name={'clipboard'} />
              <SectionTitle>Work Timesheet</SectionTitle>
            </Left>
          </MiniRow>
        </RowHeader>
        {reportMove.job.hasJobFeatureCrewPerMoverTimesheet ? (
          <ScrollView
            horizontal
            contentContainerStyle={{
              minWidth: '100%',
            }}
            style={{
              alignSelf: 'stretch',
            }}
          >
            <CommercialReportMoveTimesheetTable reportMove={reportMove} />
          </ScrollView>
        ) : (
          <ReportMoveTimeRanges reportMove={reportMove} />
        )}
      </Row>
      {!reportMove.job.hasJobFeatureCrewPerMoverTimesheet && (
        <React.Fragment>
          <Line />
          <Row index={2}>
            <RowHeader>
              <MiniRow>
                <Left>
                  <Emoji component={EmojiText} name={'stopwatch'} />
                  <SectionTitle>Crew Hours</SectionTitle>
                </Left>
              </MiniRow>
            </RowHeader>
            <ReportMoveMoveUsers reportMove={reportMove} />
          </Row>
        </React.Fragment>
      )}
      {reportMove.job.hasJobStepReportBilling && (
        <React.Fragment>
          <Line />
          <Row index={3}>
            <RowHeader>
              <MiniRow>
                <Left>
                  <Emoji component={EmojiText} name={'dollar'} />
                  <SectionTitle>Bills</SectionTitle>
                </Left>
              </MiniRow>
            </RowHeader>
            <React.Fragment>
              <BillingProjectBillsListV1
                project={reportMove.job.project}
                aggregateBill={reportMove.job.project.activeJobsAggregateBill}
              />
              <BillingProjectPaymentsList
                aggregateBill={reportMove.job.project.activeJobsAggregateBill}
              />
            </React.Fragment>
          </Row>
          {isEnabledCrewTips && (
            <React.Fragment>
              <Line />
              <Row index={4}>
                <RowHeader>
                  <MiniRow>
                    <Left>
                      <Emoji component={EmojiText} name={'heart'} />
                      <SectionTitle>Crew Tips</SectionTitle>
                    </Left>
                  </MiniRow>
                </RowHeader>
                <ReportMoveMoveUsersTip reportMove={reportMove} />
              </Row>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMoveInfoV1.fragment = gql`
  ${BillingProjectBillsListV1.fragment}
  ${BillingProjectPaymentsList.fragment}
  ${ReportMoveMoveUsers.fragment}
  ${ReportMoveMoveUsersTip.fragment}
  ${ReportMoveTimeRanges.fragment}
  ${CommercialReportMoveTimesheetTable.fragment}

  fragment ReportMoveInfoV1 on ReportMove {
    id
    job {
      id
      hasJobFeatureCrewPerMoverTimesheet: hasJobFeature(kind: "CREW_PER_MOVER_TIMESHEET")
      project {
        id
        activeJobsAggregateBill {
          ...BillingProjectBillsListV1_AggregateBill
          ...BillingProjectPaymentsList_AggregateBill
        }
        ...BillingProjectBillsListV1
      }
      hasJobStepReportBilling: hasJobStep(kind: "REPORT_BILLING")
    }
    organization {
      id
      features {
        isEnabledHideEditReportCrewTips: isEnabled(feature: "HIDE_EDIT_REPORT_CREW_TIPS")
      }
    }
    ...CommercialReportMoveTimesheetTable
    ...ReportMoveMoveUsers
    ...ReportMoveMoveUsersTip
    ...ReportMoveTimeRanges
  }
`;

export default ReportMoveInfoV1;
