// Libraries
import React from 'react';

// Components
import NotFoundPage from 'modules/NotFound/components/NotFoundPage';

const InventoryNotFoundPage = () => {
  return (
    <NotFoundPage
      title={'Inventory Not Found'}
      subtitle={'If you believe this is an error, please contact us.'}
    />
  );
};

export default InventoryNotFoundPage;
