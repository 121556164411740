// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {Job} from '@supermove/models';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import JobPrimaryStatusForm from '@shared/modules/Job/forms/JobPrimaryStatusForm';
import useUpdateJobPrimaryStatusMutation from '@shared/modules/Job/hooks/useUpdateJobPrimaryStatusMutation';

const FinalizeJobModal = ({jobId, isOpen, handleClose, refetch}) => {
  const {submitting, handleSubmit} = useUpdateJobPrimaryStatusMutation({
    jobPrimaryStatusForm: JobPrimaryStatusForm.new({jobId, status: Job.PRIMARY_STATUS.FINAL}),
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <ActionModal
      icon={Icon.Check}
      title={'Finalize Job?'}
      message={'Finalizing the job will prevent any edits to the job.'}
      isOpen={isOpen}
      handlePressOutside={handleClose}
      primaryActionText={'Confirm'}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      handleSecondaryAction={handleClose}
      isSubmitting={submitting}
    />
  );
};

export default FinalizeJobModal;
