// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CollectionMetrics from '@shared/modules/Inventory/Summary/components/CollectionMetrics';
import SummaryItemsList from '@shared/modules/Inventory/Summary/components/SummaryItemsList';

const Container = Styled.ButtonV2`
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
  overflow: hidden;
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 16px;
  padding-vertical: 10px;
  background-color: ${colors.gray.background};
`;

const Name = Styled.H7`
  flex: 1;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Content = Styled.View`
  padding-horizontal: 6px;
`;

const DashedLine = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.tertiary};
  border-style: dashed;
  margin-horizontal: 10px;
  flex: 1;
`;

const EmptySubtitle = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
  text-align: center;
  padding-bottom: 12px;
`;

const reduceItems = (prevItem: any, item: any) => {
  return {
    ...prevItem,
    takeCount: prevItem.takeCount + item.takeCount,
    leaveCount: prevItem.leaveCount + item.leaveCount,
  };
};

const combineItemCounts = (items: any) => {
  return _.reduce(items, reduceItems);
};

const aggregateItems = (items: any) => {
  const groupByItemTypeId = _.groupBy(items, 'itemTypeId');
  return _.sortBy(
    _.values(groupByItemTypeId).map((itemTypeItems) => combineItemCounts(itemTypeItems)),
    ['name'],
  );
};

const getCartonCategoryLists = ({cartonCategory}: any) => {
  const takeCartonItems: any = [];
  const leaveCartonItems: any = [];

  aggregateItems(cartonCategory.collection.items).forEach((item) => {
    if (item.takeCount > 0) {
      takeCartonItems.push(item);
    }
    if (item.leaveCount > 0) {
      leaveCartonItems.push(item);
    }
  });

  return {takeCartonItems, leaveCartonItems};
};

const CartonCategoryItem = ({
  cartonCategory,
  isDisabled,
  showNotes,
  showWeight,
  showVolume,
}: any) => {
  const {takeCartonItems, leaveCartonItems} = getCartonCategoryLists({cartonCategory});
  const hasTakeCartons = takeCartonItems.length > 0;
  const hasLeaveCartons = leaveCartonItems.length > 0;
  const [isExpanded, setIsExpanded] = useState(hasTakeCartons || hasLeaveCartons || isDisabled);

  return (
    <Container
      disabled={isDisabled}
      onPress={() => {
        setIsExpanded(!isExpanded);
      }}
    >
      <Header>
        <Name>{cartonCategory.category.name}</Name>
        <Space width={10} />
        <CollectionMetrics
          collection={cartonCategory.collection}
          showWeight={showWeight}
          showVolume={showVolume}
        />
        <Space width={20} />
        <Icon
          color={colors.gray.primary}
          size={14}
          source={isExpanded ? Icon.ChevronDown : Icon.ChevronUp}
        />
      </Header>
      {isExpanded && (
        <React.Fragment>
          <Line />
          <Content>
            <Space height={12} />
            <SummaryItemsList
              items={takeCartonItems}
              listKey={`${cartonCategory.id}-TAKE-CARTONS`}
              kind={'TAKE'}
              showNotes={showNotes}
              showVolume={showVolume}
            />
            {hasTakeCartons && hasLeaveCartons && (
              <React.Fragment>
                <Space height={3} />
                <DashedLine />
                <Space height={8} />
              </React.Fragment>
            )}
            <SummaryItemsList
              items={leaveCartonItems}
              listKey={`${cartonCategory.id}-LEAVE-CARTONS`}
              kind={'LEAVE'}
              showNotes={showNotes}
              showVolume={showVolume}
            />
            {hasTakeCartons || hasLeaveCartons ? (
              <Space height={7} />
            ) : (
              <EmptySubtitle>No items added</EmptySubtitle>
            )}
          </Content>
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CartonCategoryItem.fragment = gql`
  ${CollectionMetrics.fragment}
  ${SummaryItemsList.fragment}

  fragment CartonCategoryItem on CartonCategory {
    id
    category {
      id
      name
    }
    collection {
      id
      items {
        id
        itemTypeId
        name
        takeCount
        leaveCount
        ...SummaryItemsList
      }
      ...CollectionMetrics
    }
  }
`;

export default CartonCategoryItem;
