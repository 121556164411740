// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({crewId, truckId, slotId, driverId, isPrimary}: any) => ({
  crewId,
  truckId,
  slotId,
  driverId,
  isPrimary,
});

const edit = withFragment(
  (crewSlot) => ({
    crewSlotId: (crewSlot as any).id,
    crewId: (crewSlot as any).crewId,
    slotId: (crewSlot as any).slotId,
    truckId: (crewSlot as any).truckId,
    driverId: (crewSlot as any).driverId,
    isPrimary: (crewSlot as any).isPrimary,
  }),
  gql`
    fragment CrewSlotForm_edit on CrewSlot {
      id
      crewId
      truckId
      slotId
      driverId
      isPrimary
    }
  `,
);

const toForm = ({crewSlotId, isPrimary, crewId, slotId, truckId, driverId}: any) => ({
  crewSlotId,
  crewId,
  truckId,
  slotId,
  driverId,
  isPrimary,
});

const toMutation = ({crewSlotId, isPrimary, crewId, slotId, truckId, driverId}: any) => ({
  crewSlotId,
  crewId,
  truckId,
  slotId,
  driverId,
  isPrimary,
});

const CrewSlotForm = {
  // Initialize
  edit,
  new: _new,

  // Conversion
  toForm,
  toMutation,
};

export default CrewSlotForm;
