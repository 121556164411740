// Libraries
import React from 'react';

// Supermove
import {Modal, Styled} from '@supermove/components';
import {useModal, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

import PayEngineCreditCardInput from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import useSavePayEngineCreditCard from '@shared/modules/Payment/hooks/useSavePayEngineCreditCard';
import {Navigation} from 'modules/App/components';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = Styled.View`
  ${(props) => (props.mobile ? 'width: 100%;' : '')}
  ${(props) => (props.mobile ? 'flex: 1;' : '')}
  background-color: ${colors.white};
`;

const Container = Styled.View`
  ${(props) => (props.mobile ? 'flex: 1;' : '')}
`;

const Header = Styled.View`
  padding-horizontal: 20px;
`;

const Section = Styled.View`
`;

const Title = Styled.H4`
  ${fontWeight(700)}
`;

const Subtitle = Styled.H7`
`;

const Content = Styled.View`
  padding: 20px;
  width: 450px;
`;

const CardInputWrapper = Styled.View`
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  border-radius: 3px;
`;

const ValidationError = Styled.H7`
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const Footer = Styled.View`
  align-items: flex-end;
  padding-vertical: 10px;
  padding-horizontal: 10px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.gray.tertiary};
`;

const Button = Styled.LoadingButton`
  padding-horizontal: 15px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const SavePayEngineCreditCardForCustomerForm = ({clientId, mode, onSuccess, onClose}) => {
  const responsive = useResponsive();

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isFormLoaded, setIsFormLoaded] = React.useState(false);
  const onLoad = React.useCallback(() => {
    setIsFormLoaded(true);
  }, [setIsFormLoaded]);
  const {submitting, handleSubmit, setCreditCardClient} = useSavePayEngineCreditCard({
    clientId,
    onSuccess: (response) => {
      setErrorMessage(null);
      onSuccess(response);
    },
    onError: (error) => {
      console.error(`Error executing SavePayengineCreditCardMutation: `, error);
      setErrorMessage(`Could not save credit card.`);
    },
    onTokenizeError: (error) => {
      console.error(`Error tokenizing credit card: `, error);
      setErrorMessage(`Invalid credit card information.`);
    },
  });

  return (
    <Wrapper {...responsive}>
      <Container {...responsive}>
        <Section>
          <Navigation.CloseButton responsive={responsive} onPress={onClose} />
        </Section>
        <Header>
          <Section>
            <Title>Save Credit Card</Title>
            <Subtitle>Enter in the card details and press save.</Subtitle>
          </Section>
        </Header>
        <Content>
          <CardInputWrapper>
            <PayEngineCreditCardInput
              setCreditCardClient={setCreditCardClient}
              mode={mode}
              onLoad={onLoad}
            />
          </CardInputWrapper>
          {errorMessage ? <ValidationError>{errorMessage}</ValidationError> : null}
        </Content>
        <Footer>
          <Button disabled={!isFormLoaded} loading={submitting} onPress={handleSubmit}>
            <Text>Save</Text>
          </Button>
        </Footer>
      </Container>
    </Wrapper>
  );
};

const SavePayEngineCreditCardForCustomerModal = ({clientId, mode, onClose, trigger}) => {
  const {isOpen, handleOpen, handleClose} = useModal({
    onClose,
  });

  return (
    <React.Fragment>
      {trigger({handleOpen})}
      <Modal.Content isOpen={isOpen} onClose={handleClose}>
        <Overlay pointerEvents={'box-none'}>
          <SavePayEngineCreditCardForCustomerForm
            clientId={clientId}
            mode={mode}
            onSuccess={(response) => {
              handleClose();
              onClose();
            }}
            onClose={handleClose}
          />
        </Overlay>
      </Modal.Content>
    </React.Fragment>
  );
};

export default SavePayEngineCreditCardForCustomerModal;
