// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';

const UpdateTruckStatusModal = ({isOpen, handleClose, handleSubmit, truckName, newStatus}) => {
  return (
    <SmallModal isOpen={isOpen} style={{alignItems: 'center'}}>
      <SmallModal.HeaderIcon source={Icon.Truck} color={colors.blue.interactive} />
      <Space height={16} />
      <SmallModal.HeaderText>{`Move ${truckName} to ${newStatus}?`}</SmallModal.HeaderText>
      <Space height={12} />
      <SmallModal.Text>{`You can change truck status at any time.`}</SmallModal.Text>
      <Space height={16} />
      <SmallModal.Footer style={{justifyContent: 'center'}}>
        <SmallModal.Button onPress={handleClose}>{`Cancel`}</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button onPress={handleSubmit} color={colors.blue.interactive}>
          {`Move Truck`}
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default UpdateTruckStatusModal;
