// Libraries
import React from 'react';

// App
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import WarehouseSettingsPageContent from 'modules/Organization/Settings/Storage/Warehouses/WarehouseSettingsPageContent';

const WarehouseSettingsPage = () => {
  return (
    <SidebarPageV2 selected={'settings'}>
      <WarehouseSettingsPageContent />
    </SidebarPageV2>
  );
};

export default WarehouseSettingsPage;
