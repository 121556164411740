/**
 * Component - v2.1.0
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import useUpdateProjectBookingStatusMutation from '@shared/modules/Project/hooks/useUpdateProjectBookingStatusMutation';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const InstructionText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const ModalContainer = Styled.View`
  width: ${(props) => (props.mobile ? 320 : 400)}px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 24px;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const ProjectUpdateSalesStatusModal = ({isOpen, project, handleClose}) => {
  const responsive = useResponsive();
  const {submitting, handleSubmit} = useUpdateProjectBookingStatusMutation({
    projectId: project.id,
    bookingStatus: 'DEPOSIT_RECEIVED',
    onSuccess: () => handleClose(),
    onError: () => {},
  });

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ModalContainer {...responsive}>
          <InstructionText>{'Has this project received the deposit?'}</InstructionText>
          <Space height={20} />
          <Actions>
            <Button
              disabled={submitting}
              activeOpacity={0.8}
              color={colors.blue.interactive}
              onPress={handleSubmit}
            >
              <ButtonText color={colors.white}>Yes</ButtonText>
            </Button>
            <Space width={8} />
            <Button
              disabled={submitting}
              activeOpacity={0.8}
              color={colors.gray.border}
              onPress={handleClose}
            >
              <ButtonText color={colors.gray.tertiary}>No, Cancel</ButtonText>
            </Button>
          </Actions>
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ProjectUpdateSalesStatusModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
};

ProjectUpdateSalesStatusModal.defaultProps = {
  handleClose: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectUpdateSalesStatusModal.fragment = gql`
  fragment ProjectUpdateSalesStatusModal on Project {
    id
  }
`;
export default ProjectUpdateSalesStatusModal;
