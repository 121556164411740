/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {TimeRange} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const Container = Styled.View`
  flex-direction: row;
`;

const Item = Styled.View`
  flex: ${(props) => props.length || 0};
`;

const Content = Styled.View`
  justify-content: center;
  height: 40px;
  background-color: ${(props) => props.color};
`;

const Name = Styled.H7`
  margin-horizontal: 10px;
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Times = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const StartTime = Styled.H8`
  flex: 1;
  margin-top: 5px;
  margin-horizontal: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const EndTime = Styled.H8`
  margin-top: 5px;
  margin-horizontal: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const TimeRangeItem = ({isLast, length, timeRange}) => (
  <Item length={length}>
    <Content color={TimeRange.getColor(timeRange)}>
      <Name numberOfLines={1}>{timeRange.name}</Name>
    </Content>
    <Times>
      <StartTime numberOfLines={1}>
        {Datetime.convertToDisplayTime(timeRange.start, Datetime.FORM_TIME)}
      </StartTime>
      {isLast && !!timeRange.end && (
        <EndTime numberOfLines={1}>
          {Datetime.convertToDisplayTime(timeRange.end, Datetime.FORM_TIME)}
        </EndTime>
      )}
    </Times>
  </Item>
);

const RemainingTime = ({length}) => (
  <Item length={length}>
    <Content color={colors.gray.border} />
  </Item>
);

const EmptyView = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${colors.gray.border};
`;

const EmptyText = Styled.H6`
  color: ${colors.gray.secondary};
`;

const EmployeeMoveTimesChart = ({hasConfirmedTimes, timeRanges}) => (
  <Container>
    {timeRanges.map((timeRange, index) => (
      <TimeRangeItem
        key={index}
        isFirst={index === 0}
        isLast={index === timeRanges.length - 1}
        length={TimeRange.getMinutes(timeRange)}
        timeRange={timeRange}
      />
    ))}
    {timeRanges.length === 0 ? (
      <EmptyView>
        <EmptyText>No times entered.</EmptyText>
      </EmptyView>
    ) : (
      <RemainingTime
        length={TimeRange.getEstimatedRemainingMinutes(timeRanges, {hasConfirmedTimes})}
      />
    )}
  </Container>
);

export default EmployeeMoveTimesChart;
