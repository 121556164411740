// Libararies
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import StorageProjectStatus from '@shared/modules/Project/enums/StorageProjectStatus';
import ProjectStatusForm from '@shared/modules/Project/forms/ProjectStatusForm';
import useUpdateProjectStatusMutation from '@shared/modules/Project/hooks/useUpdateProjectStatusMutation';
import StatusDropdown from 'modules/Dispatch/LongDistance/components/StatusDropdown';

const Row = Styled.View`
  flex-direction: row;
`;

const EditStorageProjectStatusDropdown = ({project, onSuccess}) => {
  const projectStatusForm = ProjectStatusForm.edit(project);
  const {form, handleSubmit} = useUpdateProjectStatusMutation({
    projectStatusForm,
    onSuccess: ({project}) => {
      onSuccess(project);
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <Row>
      <StatusDropdown
        options={StorageProjectStatus.OPTIONS}
        initialValue={form.values.projectStatusForm.status}
        onSelect={(option) => {
          form.setFieldValue('projectStatusForm.status', option.value);
          setTimeout(() => handleSubmit(), 0);
        }}
        size={StatusDropdown.SIZE.LARGE}
      />
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditStorageProjectStatusDropdown.fragment = gql`
  ${ProjectStatusForm.edit.fragment}

  fragment EditStorageProjectStatusDropdown on Project {
    id
    ...ProjectStatusForm_edit
  }
`;

// --------------------------------------------------
// Props
// --------------------------------------------------
EditStorageProjectStatusDropdown.propTypes = {
  project: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

EditStorageProjectStatusDropdown.defaultProps = {
  onSuccess: () => {},
};

export default EditStorageProjectStatusDropdown;
