import React from 'react';
import {MutationFunctionOptions} from 'react-apollo';

// @ts-expect-error TS(2614): Module '"@shared/modules/Payment/components/PayEng... Remove this comment to see the full error message
import {PayEngine} from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import {New as NewBeginPaymentV3Form} from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import {CreateCardResponse} from '@shared/modules/Payment/types';

import useChargePayEngineCreditCardMutation, {
  ChargePayEngineCreditCardForm,
  ChargePayEngineCreditCardResponse,
  GraphQLResponse,
} from './useChargePayEngineCreditCardMutation';

type Args = {
  beginPaymentV3Form: NewBeginPaymentV3Form;
  jobId?: number;
  saveToClientId?: number;
  saveAsDefault?: boolean;
};

type Result = {
  submitting: boolean;
  handleTokenizeCreditCard: () => Promise<PayEngine.CreditCard>;
  handleChargeCreditCard: (
    options?: MutationFunctionOptions<GraphQLResponse, ChargePayEngineCreditCardForm>,
  ) => Promise<ChargePayEngineCreditCardResponse>;
  setCreditCardClient: React.Dispatch<React.SetStateAction<PayEngine.CreditCardClient>>;
};

/**
 * Charges a credit card via PayEngine and optionally saves the card for future payments.
 *
 * The returned functions should be used in this order:
 *   1. setCreditCardClient
 *        Must be passed as a prop to the PayEngineCreditCardInput component
 *   2. handleTokenizeCreditCard
 *        Tokenizes the credit card info on PayEngineCreditCardInput. This makes an API call to
 *        PayEngine's servers and _does not_ hit our API
 *   3. handleChargeCreditCard
 *        Initiates a charge on the given credit card. The card may also be saved for future usage.
 *
 * @param beginPaymentV3Form payment details provided on the BeginPayment mutation
 * @param jobId optional Job ID to associate with this payment
 * @param saveToClientId if set, the credit card used for this payment will be saved to this Client
 *
 *  TODO(atsu): Consider refactoring this hook and useChargePayEngineCreditCardMutation to take no
 *    mutation params. Usage could be more straightforward if mutation params are always provided
 *    via the handleSubmit(options) API instead.
 *
 */
const useChargePayEngineCreditCard = ({
  beginPaymentV3Form,
  jobId,
  saveToClientId,
  saveAsDefault,
}: Args): Result => {
  const [creditCardClient, setCreditCardClient] = React.useState<PayEngine.CreditCardClient>({
    createCard: () => {
      throw Error('PayEngine credit card client is not initialized');
    },
  });
  const [tokenizeIsSubmitting, setTokenizeIsSubmitting] = React.useState(false);
  const [createCardResponse, setCreateCardResponse] = React.useState<
    CreateCardResponse | undefined
  >(undefined);

  const mutation = useChargePayEngineCreditCardMutation({
    beginPaymentV3Form,
    jobId,
    saveToClientId,
    createCardResponse,
    saveAsDefault,
  });

  const handleTokenizeCreditCard = async (): Promise<PayEngine.CreditCard> => {
    try {
      setTokenizeIsSubmitting(true);
      setCreateCardResponse(undefined);
      const card = await creditCardClient.createCard();
      setCreateCardResponse({
        env: card.env,
        token: card.token,
        cardData: {
          addressZip: card.card_data.address_zip,
          brand: card.card_data.brand,
          expMonth: card.card_data.exp_month,
          expYear: card.card_data.exp_year,
          id: card.card_data.id,
          last4: card.card_data.last_4,
          name: card.card_data.name,
        },
      });
      return card;
    } finally {
      setTokenizeIsSubmitting(false);
    }
  };

  const handleChargeCreditCard = async (
    options?: MutationFunctionOptions<GraphQLResponse, ChargePayEngineCreditCardForm>,
  ) => {
    const response = await mutation.handleSubmit(options);
    if (response.errors) {
      throw response.errors;
    }
    if (response.data?.response?.errors) {
      throw response.data.response.errors;
    }
    if (!response.data?.response) {
      throw new Error('Received empty GraphQL response in ChargePayEngineCreditCard');
    }
    return response.data.response;
  };

  const submitting = tokenizeIsSubmitting || mutation.mutationResult.loading || false;

  return {
    submitting,
    setCreditCardClient,
    handleTokenizeCreditCard,
    handleChargeCreditCard,
  };
};

export default useChargePayEngineCreditCard;
