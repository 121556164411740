// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Datetime} from '@supermove/utils';

const getDisplayCoordinates = withFragment(
  (container) => {
    // @ts-expect-error TS(2339): Property 'warehouseCoordinateX' does not exist on ... Remove this comment to see the full error message
    const {warehouseCoordinateX, warehouseCoordinateY, warehouseCoordinateZ} = container;
    const coordinates = [warehouseCoordinateX, warehouseCoordinateY, warehouseCoordinateZ];
    return coordinates
      .reduce((displayCoordinates, coordinate, index) => {
        if (displayCoordinates.length && coordinate) {
          return [...displayCoordinates, '-', coordinate];
        } else if (coordinate) {
          return [...displayCoordinates, coordinate];
        }

        return displayCoordinates;
      }, [])
      .join(' ');
  },
  gql`
    fragment Container_getDisplayCoordinates on Container {
      id
      warehouseCoordinateX
      warehouseCoordinateY
      warehouseCoordinateZ
    }
  `,
);

const getDisplayDateIn = withFragment(
  (container) => {
    if (!(container as any).startDateForProject) {
      return '--';
    }

    return Datetime.convertToDisplayDate(
      (container as any).startDateForProject,
      Datetime.DISPLAY_SHORT_DATE,
    );
  },
  gql`
    fragment Container_getDisplayDateIn on Container {
      id
      startDateForProject(projectId: $projectId)
    }
  `,
);

const getDisplayDateOut = withFragment(
  (container) => {
    if (!(container as any).endDateForProject) {
      return '--';
    }

    return Datetime.convertToDisplayDate(
      (container as any).endDateForProject,
      Datetime.DISPLAY_SHORT_DATE,
    );
  },
  gql`
    fragment Container_getDisplayDateOut on Container {
      id
      endDateForProject(projectId: $projectId)
    }
  `,
);

const Container = {
  getDisplayCoordinates,
  getDisplayDateIn,
  getDisplayDateOut,
};

export default Container;
