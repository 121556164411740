// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const SegmentedControlsContainer = Styled.View`
  flex-direction: row;
  height: 36px;
`;

const SegmentButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: ${({isFirst}: any) => (isFirst ? 1 : 0)}px;
  border-right-width: ${({isLast}: any) => (isLast ? 1 : 0)}px;
  border-top-left-radius: ${({isFirst}: any) => (isFirst ? 4 : 0)}px;
  border-bottom-left-radius: ${({isFirst}: any) => (isFirst ? 4 : 0)}px;
  border-top-right-radius: ${({isLast}: any) => (isLast ? 4 : 0)}px;
  border-bottom-right-radius: ${({isLast}: any) => (isLast ? 4 : 0)}px;
  border-color: ${(props: any) =>
    props.isSelected ? colors.blue.interactive : colors.gray.secondary};
  background-color: ${({isSelected}: any) =>
    isSelected ? colors.blue.accent : colors.gray.background};
  padding-horizontal: 12px;
`;

const SegmentLabel = Styled.Text`
  ${Typography.Label}
  color: ${({vars}: any) => (vars.isSelected ? colors.blue.interactive : colors.gray.secondary)};
  white-space: normal; // This overrides extra padding added to the labels
`;

const SegmentDividerLine = Styled.View`
  width: 1px;
  background-color: ${({isSelected}: any) =>
    isSelected ? colors.blue.interactive : colors.gray.secondary};
`;

const SegmentDivider = ({isSelected, index, segmentDefinitions}: any) => {
  const priorSegment = _.get(segmentDefinitions, index - 1);
  return <SegmentDividerLine isSelected={isSelected && priorSegment.isSelected} />;
};

const SegmentedControls = ({segmentDefinitions}: any) => {
  return (
    <SegmentedControlsContainer>
      {segmentDefinitions.map(
        ({label, count, isSelected, isDisabled, onPress}: any, index: any) => {
          const isFirst = index === 0;
          const isLast = index === segmentDefinitions.length - 1;
          return (
            <React.Fragment key={label}>
              {!isFirst && (
                <SegmentDivider
                  isSelected={isSelected}
                  index={index}
                  segmentDefinitions={segmentDefinitions}
                />
              )}
              <SegmentButton
                isSelected={isSelected}
                disabled={isDisabled}
                onPress={onPress}
                isFirst={isFirst}
                isLast={isLast}
              >
                <SegmentLabel vars={{isSelected}}> {label}</SegmentLabel>
                {!_.isNil(count) && (
                  <React.Fragment>
                    <Space width={8} />
                    <SegmentLabel vars={{isSelected}}>{count}</SegmentLabel>
                  </React.Fragment>
                )}
              </SegmentButton>
            </React.Fragment>
          );
        },
      )}
    </SegmentedControlsContainer>
  );
};

export default SegmentedControls;
