// App
import AttachmentForm, {AttachmentFormType} from '@shared/modules/File/forms/AttachmentForm';
import UpdateAttachmentsForm from '@shared/modules/File/forms/UpdateAttachmentsForm';

export interface AttachmentsFormTypeWrapper {
  attachmentForms: AttachmentFormType[];
}

const toForm = ({attachmentForms}: AttachmentsFormTypeWrapper) => ({
  attachmentForms,
});

const toMutation = ({attachmentForms}: AttachmentsFormTypeWrapper) => ({
  attachmentForms: attachmentForms.map((attachmentForm) => {
    return AttachmentForm.toMutation(attachmentForm);
  }),
});

const toUpdateAttachmentsForm = (bulkAttachmentsForm: AttachmentsFormTypeWrapper) => {
  return UpdateAttachmentsForm.toForm({updateAttachmentForms: bulkAttachmentsForm.attachmentForms});
};

const BulkAttachmentsForm = {
  toForm,
  toMutation,
  toUpdateAttachmentsForm,
};

export default BulkAttachmentsForm;
