// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Label = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Title = Styled.H3`
  ${fontWeight(900)}
`;

const Subtitle = Styled.H6`
  line-height: 24px;
`;

const ConfirmationStepHeader = ({subtitle, title, confirmation}) => (
  <Container>
    {!confirmation.isComplete && (
      <Label>{`Step ${confirmation.nextIndex + 1} of ${confirmation.stepCount}`}</Label>
    )}
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmationStepHeader.fragment = gql`
  fragment ConfirmationStepHeader on Confirmation {
    id
    isComplete
    nextIndex
    stepCount
  }
`;

export default ConfirmationStepHeader;
