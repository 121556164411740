// Supermove

interface DeleteBatchFormInterface {
  batchId: number;
}

const _new = ({batchId}: DeleteBatchFormInterface) => ({
  batchId,
});

const toForm = ({batchId}: DeleteBatchFormInterface) => {
  return {
    batchId,
  };
};

const toMutation = ({batchId}: DeleteBatchFormInterface) => {
  return {
    batchId,
  };
};

const DeleteBatchForm = {
  new: _new,
  toForm,
  toMutation,
};

export default DeleteBatchForm;
