// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ConfirmationForm from '@shared/modules/Proposal/forms/ConfirmationForm';

const useExpireConfirmationMutation = ({confirmationForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      confirmationForm: ConfirmationForm.toForm(confirmationForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useExpireConfirmationMutation.mutation,
    variables: {
      confirmationForm: ConfirmationForm.toMutation(form.values.confirmationForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useExpireConfirmationMutation.mutation = gql`
  mutation useExpireConfirmationMutation($confirmationForm: ConfirmationForm!) {
    response: expireConfirmation(confirmationForm: $confirmationForm) {
      ${gql.errors}
      confirmation {
        id
      }
    }
  }
`;

export default useExpireConfirmationMutation;
