// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useUpdateSuperAdminImpersonateMutation = ({
  impersonateUserId,
  onSuccess,
  onError,
}: {
  impersonateUserId: number;
  onSuccess: () => void;
  onError: () => void;
}) => {
  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      userId: impersonateUserId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateSuperAdminImpersonateMutation.mutation,
    variables: {
      userId: form.values.userId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateSuperAdminImpersonateMutation.mutation = gql`
  mutation useUpdateSuperAdminImpersonateMutation(
    $userId: Int,
  ) {
    response: updateSuperAdminImpersonate(
      userId: $userId,
    ) {
      ${gql.errors}
      viewer {
        id
        role
      }
    }
  }
`;

export default useUpdateSuperAdminImpersonateMutation;
