// Libraries
import _ from 'lodash';

// Supermove
import {DocumentItemType} from '@supermove/models';

// App
import DocumentItemKindV2, {
  DocumentItemKindType,
} from '@shared/modules/Document/enums/DocumentItemKindV2';
import {
  DocumentContentJsonType,
  SelectedDocumentItemType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';

const getDocumentItemsField = ({
  sectionIndex,
  columnIndex,
}: {
  sectionIndex?: number;
  columnIndex?: number;
}): string => {
  return (
    `documentItems` +
    `${!_.isNil(sectionIndex) ? `.${sectionIndex}.documentItems` : ''}` +
    `${!_.isNil(columnIndex) ? `.${columnIndex}.documentItems` : ''}`
  );
};

const getDocumentItems = (
  documentContentJson: DocumentContentJsonType,
  {sectionIndex, columnIndex}: {sectionIndex?: number; columnIndex?: number} = {},
): DocumentItemType[] => {
  return _.get(documentContentJson, getDocumentItemsField({sectionIndex, columnIndex}));
};

const getHasColumnRestrictedDocumentItem = (
  documentContentJson: DocumentContentJsonType,
  {selectedDocumentItem}: {selectedDocumentItem: SelectedDocumentItemType},
) => {
  const documentItemKinds = getDocumentItems(documentContentJson, {
    sectionIndex: selectedDocumentItem.sectionIndex,
  }).map((documentItem) => documentItem.itemKind);

  return _.some(documentItemKinds, DocumentItemKindV2.getIsDisabledForColumn);
};

const handleAddColumn = (
  documentContentJson: DocumentContentJsonType,
  {selectedDocumentItem}: {selectedDocumentItem: SelectedDocumentItemType},
): DocumentContentJsonType => {
  const documentItemsField = DocumentContentJson.getDocumentItemsField({
    sectionIndex: selectedDocumentItem.sectionIndex,
  });

  const documentItems = _.get(documentContentJson, documentItemsField).filter(
    (documentItem: DocumentItemType) =>
      !DocumentItemKindV2.getIsDisabledForColumn(documentItem.itemKind),
  );

  const leftColumnDocumentItem = DocumentItemKindV2.getNewDocumentItem(DocumentItemKindV2.COLUMN, {
    documentItems,
  });
  const rightColumnDocumentItem = DocumentItemKindV2.getNewDocumentItem(DocumentItemKindV2.COLUMN);
  _.set(documentContentJson, documentItemsField, [leftColumnDocumentItem, rightColumnDocumentItem]);
  return {...documentContentJson};
};

export type StringifiedDocumentItem = {
  input?: string;
  documentItems?: StringifiedDocumentItem[];
  itemKind: DocumentItemKindType;
};

const handleStringifyDocumentItems = (
  documentItems: DocumentItemType[],
): StringifiedDocumentItem[] => {
  return documentItems.map((documentItem) => handleStringifyDocumentItem(documentItem));
};

const handleStringifyDocumentItem = (documentItem: DocumentItemType): StringifiedDocumentItem => {
  const hasInput = 'input' in documentItem && documentItem.input;
  const hasDocumentItems = 'documentItems' in documentItem && documentItem.documentItems;
  return {
    ...documentItem,
    input: hasInput ? JSON.stringify(documentItem.input) : undefined,
    documentItems: hasDocumentItems
      ? handleStringifyDocumentItems(documentItem.documentItems)
      : undefined,
  };
};

const DocumentContentJson = {
  // Getters
  getDocumentItemsField,
  getDocumentItems,
  getHasColumnRestrictedDocumentItem,

  // Handlers
  handleAddColumn,
  handleStringifyDocumentItems,
};

export default DocumentContentJson;
