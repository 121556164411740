// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Emoji, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-horizontal: 15px;
  background-color: ${(props) => (props.isHovered ? colors.blue.interactive : colors.white)};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-radius: 3px;
`;

const Title = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmojiText = Styled.H4`
  width: 24px;
  padding-top: 4px;
`;

const Name = Styled.H7`
  margin-left: 5px;
`;

const Touchable = Styled.Touchable`
`;

const Text = Styled.H7`
  color: ${colors.blue.interactive};
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const AttachmentItem = ({name, onPreview, style}) => (
  <Container style={style}>
    <Title>
      <Emoji component={EmojiText} name={'page_facing_up'} />
      <Name>{name}</Name>
    </Title>
    <Touchable onPress={onPreview}>
      <Text>Preview</Text>
    </Touchable>
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
AttachmentItem.propTypes = {
  name: PropTypes.string.isRequired,
  onPreview: PropTypes.func.isRequired,
};

AttachmentItem.defaultProps = {};

export default AttachmentItem;
