// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    businessName: (organization as any).businessName,
    businessSignature: (organization as any).businessSignature,
    businessWebsite: (organization as any).businessWebsite,
    phoneNumber: (organization as any).phoneNumber,
  }),
  gql`
    fragment OrganizationBusinessInfoContactForm_edit on Organization {
      id
      businessName
      businessSignature
      businessWebsite
      phoneNumber
    }
  `,
);

const toForm = ({
  organizationId,
  businessName,
  businessSignature,
  businessWebsite,
  phoneNumber,
}: any) => ({
  organizationId,
  businessName,
  businessSignature,
  businessWebsite,
  phoneNumber,
});

const toMutation = ({
  organizationId,
  businessName,
  businessSignature,
  businessWebsite,
  phoneNumber,
}: any) => ({
  organizationId,
  businessName,
  businessSignature,
  businessWebsite,
  phoneNumber,
});

const OrganizationBusinessInfoContactForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationBusinessInfoContactForm;
