// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import JobDateForm from '@shared/modules/Job/forms/JobDateForm';

const useAddJobDateMutation = ({jobDateForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      jobDateForm: JobDateForm.toForm(jobDateForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useAddJobDateMutation.mutation,
    variables: {
      jobDateForm: JobDateForm.toMutation(form.values.jobDateForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useAddJobDateMutation.mutation = gql`
  ${JobDateForm.edit.fragment}
  mutation useAddJobDateMutation($jobDateForm: JobDateForm!) {
    response: addJobDate(jobDateForm: $jobDateForm) {
      ${gql.errors}
      job {
        id
        ...JobDateForm_edit
      }
    }
  }
`;

export default useAddJobDateMutation;
