// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {CreditCardInputProps} from '@supermove/payments/src/components/CreditCardInput/CreditCardInputTypes';

// Payments
import StripeCardInput from '../StripeCardInput';

const Container = Styled.View`
  border-width: 1px;
  border-style: solid;
  border-color: #CFCFCF;
  border-radius: 3px;
`;

const CreditCardInput = ({onChange}: CreditCardInputProps) => {
  return (
    <Container>
      <StripeCardInput
        onChange={(event) => {
          const {complete, error} = event;
          onChange({isValid: complete, error});
        }}
        onReady={(element) => {
          onChange({card: element});
        }}
      />
    </Container>
  );
};

export default CreditCardInput;
