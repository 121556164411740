// Libraries
import React from 'react';

// App
import {Query, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components
import DispatchDayJobsList from './DispatchDayJobsList';

const Container = Styled.View`
  flex: 1;
  border-left-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const DispatchDayDetails = ({date}) => (
  <Query
    fetchPolicy={'cache-and-network'}
    pollInterval={1000 * 60} // Refresh every 60 seconds.
    variables={{
      date,
      kinds: ['MOVE'],
    }}
    query={DispatchDayDetails.query}
  >
    {({data}) =>
      !!data && (
        <Container>
          <DispatchDayJobsList date={date} calendarDay={data.calendarDay} />
        </Container>
      )
    }
  </Query>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
DispatchDayDetails.query = gql`
  ${DispatchDayJobsList.fragment}

  query DispatchDayDetails($date: String!, $kinds: [String]!) {
    ${gql.query}
    calendarDay(date: $date, kinds: $kinds) {
      ...DispatchDayJobsList
    }
  }
`;

export default DispatchDayDetails;
