// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

const Container = Styled.View`
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  padding-vertical: 10px;
  padding-horizontal: 20px;
`;

const Touchable = Styled.Touchable`
  flex: 1;
`;

const Link = Styled.H6`
  color: ${colors.blue.interactive};
  text-decoration-line: underline;
`;

const Text = Styled.H6`
  width: 120px;
  color: ${colors.gray.primary};
  text-align: right;
`;

const SalesPayableItemItem = ({isFirst, isLast, index, payableItem, form, onDelete}) => {
  const {navigator} = useNavigationDOM();

  return (
    <Container>
      <Line />
      <Row>
        <Touchable onPress={() => navigator.pushNewTab(`/jobs/${payableItem.job.uuid}`)}>
          <Link numberOfLines={1}>{payableItem.name}</Link>
        </Touchable>
        <Text>{Currency.display(payableItem.amount)}</Text>
        <Text>{Percent.display(payableItem.rate)}</Text>
        <Text>{Currency.display(payableItem.total)}</Text>
      </Row>
      {isLast && <Line />}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SalesPayableItemItem.fragment = gql`
  fragment SalesPayableItemItem on PayableItem {
    name
    amount
    rate
    total
    job {
      id
      uuid
    }
  }
`;

export default SalesPayableItemItem;
