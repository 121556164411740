// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import Timezone from '@shared/modules/Organization/enums/Timezone';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ViewCell = ({label, placeholder, value}) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const BusinessHoursPanelEdit = ({form}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldInput
            {...form}
            index={0}
            label={'Timezone'}
            component={DropdownInput}
            name={'organizationBusinessInfoHoursForm.timezone'}
            input={{
              options: Timezone.getTimezoneDropdownOptions(),
              setFieldValue: form.setFieldValue,
              style: {
                width: '100%',
              },
            }}
            style={{flex: 1}}
          />
        </Column>
        <Space width={24} />
        <Column>
          <FieldInput
            {...form}
            label={`Business Hours`}
            name={'organizationBusinessInfoHoursForm.businessHours'}
            input={{
              placeholder: `Enter business hours`,
              multiline: true,
              style: {
                paddingVertical: 8,
                minHeight: 100,
              },
            }}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

const BusinessHoursPanel = ({organization}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell label={'Timezone'} placeholder={'Add timezone'} value={organization.timezone} />
        </Column>
        <Space width={24} />
        <Column>
          <ViewCell
            label={'Hours of Operation'}
            placeholder={'Add hours of operations'}
            value={organization.businessHours}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

BusinessHoursPanel.Edit = BusinessHoursPanelEdit;

export default BusinessHoursPanel;
