// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Datetime} from '@supermove/utils';

// Components
import {DayJobsList} from 'modules/App/Day/components';
import {DayPage} from 'modules/Day/components';

const Wrapper = Styled.View`
  flex: 1;
  align-items: center;
`;

const Container = Styled.View`
  flex: 1;
  width: ${(props) => (props.mobile ? '100%' : '500px')};
`;

const ShowDayPage = ({
  match: {
    params: {date},
  },
}) => (
  <DayPage
    variables={{
      date,
      kinds: ['MOVE'],
    }}
    query={ShowDayPage.query}
    title={({responsive}) => {
      if (responsive.mobile) {
        return Datetime.convertToDisplayDate(date, 'dddd, MMMM Do');
      } else {
        return Datetime.convertToDisplayDate(date, 'dddd, MMMM Do, YYYY');
      }
    }}
    subtitle={() => 'View jobs'}
    side={({responsive}) => (responsive.mobile ? 70 : 120)}
    onClose={({navigator}) => navigator.goBack()}
  >
    {({responsive, data, refetch}) => (
      <Wrapper>
        <Container {...responsive}>
          <DayJobsList
            date={date}
            calendarDay={data.calendarDay}
            hasTitleDay={false}
            refetch={refetch}
          />
        </Container>
      </Wrapper>
    )}
  </DayPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowDayPage.query = gql`
  ${DayJobsList.fragment}

  query ShowDayPage($date: String!, $kinds: [String]!) {
    ${gql.query}
    viewer {
      id
    }
    calendarDay(date: $date, kinds: $kinds) {
      ...DayJobsList
    }
  }
`;

export default ShowDayPage;
