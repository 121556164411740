// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

// App

// Components
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import CrewPayrollPageContent from 'modules/Payroll/Crew/components/CrewPayrollPageContent';
import {DateRangeHandler, PayrollNavigation} from 'modules/Payroll/components';

const Container = Styled.View`
  flex: 1;
`;

const KIND = 'SALES';

const SalesPayrollPage = () => {
  const {loading, data} = useQuery(SalesPayrollPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={'accounting'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <DateRangeHandler
      getDefaultStartDate={() => Datetime.today.subtract(6, 'days')}
      getDefaultEndDate={({startDate}) => startDate.clone().add(6, 'days')}
    >
      {({startDate, endDate, setStartDate, setEndDate}) => (
        <SidebarPageV2 selected={'payroll'}>
          <Container>
            <StandardOfficeHeader title={'Payroll'} />
            <PayrollNavigation selected={KIND} viewer={data.viewer} />
            <CrewPayrollPageContent
              kind={KIND}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Container>
        </SidebarPageV2>
      )}
    </DateRangeHandler>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SalesPayrollPage.query = gql`
  ${PayrollNavigation.fragment}

  query SalesPayrollPage {
    ${gql.query}
    viewer {
      id
      ...PayrollNavigation
    }
  }
`;

export default SalesPayrollPage;
