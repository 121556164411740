const _new = ({jobId, projectId, organizationId, invoiceId}: any) => ({
  jobId,
  projectId,
  organizationId,
  invoiceId,
  text: '',
});

const toForm = ({jobId, projectId, organizationId, invoiceId, text}: any) => ({
  jobId,
  projectId,
  organizationId,
  invoiceId,
  text,
});

const toMutation = ({jobId, projectId, organizationId, invoiceId, text}: any) => ({
  jobId,
  projectId,
  organizationId,
  invoiceId,
  text,
});

const EvaluateAndReplaceVariablesForm = {
  new: _new,
  toForm,
  toMutation,
};

export default EvaluateAndReplaceVariablesForm;
