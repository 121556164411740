// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {User, Job} from '@supermove/models';
import {withFragment} from '@supermove/utils';

const getDisplayStatus = withFragment(
  (jobUser) => {
    switch ((jobUser as any).status) {
      case 'CONFIRMED':
        return 'Accepted';
      case 'REMOVED':
        return 'Declined';
      default:
        return 'Pending';
    }
  },
  gql`
    fragment JobUser_getDisplayStatus on JobUser {
      id
      status
    }
  `,
);

const getTooltipStatus = withFragment(
  (jobUser) => {
    switch ((jobUser as any).status) {
      case 'CREATED':
        return `${(jobUser as any).user.fullName} (${(jobUser as any).user.position}) has not viewed this job.`;
      case 'VIEWED':
        return `${(jobUser as any).user.fullName} (${(jobUser as any).user.position}) has viewed this job.`;
      case 'CONFIRMED':
        return `${(jobUser as any).user.fullName} (${(jobUser as any).user.position}) has accepted this job.`;
      case 'REMOVED':
        return `${(jobUser as any).user.fullName} (${(jobUser as any).user.position}) has declined this job.`;
      default:
        return `${(jobUser as any).user.fullName}'s (${(jobUser as any).user.position}) status is unknown.`;
    }
  },
  gql`
    fragment JobUser_getTooltipStatus on JobUser {
      id
      status
      user {
        id
        fullName
        position
      }
    }
  `,
);

const JOB_USER_POSITIONS = ['team leader', 'leader', 'foreman', 'driver', 'mover', 'helper'];

/**
 * Returns a sorted list of JobUsers based on position. The jobUsers are sorted first by
 * "team leader", "leader", "foreman", "driver", "mover", "helper", and then other positions.
 * The position string is case insensitive.
 *
 * @param {*} param0
 */
const getJobUsersSortedByPosition = (jobUsers: any) => {
  return _.sortBy(jobUsers, (jobUser) => {
    if (!jobUser.user.position) {
      return 100;
    } else {
      const index = JOB_USER_POSITIONS.findIndex((position) => {
        return User.getPosition(jobUser.user).toLowerCase().includes(position);
      });
      if (index < 0) {
        return 99;
      } else {
        return index;
      }
    }
  });
};

/**
 * Returns a list of confirmed JobUsers.
 *
 * @param {JobUser[]} jobUsers
 */
const getConfirmedJobUsers = (jobUsers: any) => {
  return jobUsers.filter((jobUser: any) => jobUser.status === 'CONFIRMED');
};

/**
 * Gets the JobUsers for a job based on if the job is in a "prepare" state or not.
 * If the job is not in a prepared state, we only want to show confirmed job users.
 * The JobUsers are sorted by position.
 * @param {*} param0
 */
const getJobUsersForJob = ({job, jobUsers}: any) => {
  const filteredJobUsers = Job.isInPrepareState(job) ? jobUsers : getConfirmedJobUsers(jobUsers);
  return getJobUsersSortedByPosition(filteredJobUsers);
};

const getMoverPositionsKey = withFragment(
  (jobUser) => {
    return (jobUser as any).moverPositions.map((moverPosition: any) => moverPosition.id).join('_');
  },
  gql`
    fragment JobUser_getMoverPositionsKey on JobUser {
      id
      moverPositions {
        id
      }
    }
  `,
);

const getFirstNameAndLastInitial = withFragment(
  (jobUser) => {
    const [firstName, lastName] = (jobUser as any).user.fullName.split(' ');

    if (!lastName) return firstName;

    const [lastInitial] = lastName.split('');

    return `${firstName} ${lastInitial}.`;
  },
  gql`
    fragment JobUser_getFirstNameAndLastInitial on JobUser {
      id
      user {
        id
        fullName
      }
    }
  `,
);

const JobUser = {
  // Getters
  getConfirmedJobUsers,
  getDisplayStatus,
  getTooltipStatus,
  getJobUsersForJob,
  getJobUsersSortedByPosition,
  getMoverPositionsKey,
  getFirstNameAndLastInitial,

  getSelected: (jobUsers: any) => {
    return jobUsers.filter((jobUser: any) => !['REMOVED'].includes(jobUser.status));
  },
};

export default JobUser;
