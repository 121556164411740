// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {
  DocumentItemType,
  DocumentItemColumnType,
  DocumentModel,
  InvoiceModel,
  JobModel,
  ProjectModel,
} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import DocumentItemBlockByCategory from '@shared/modules/Document/components/DocumentItemBlockByCategory';
import DocumentTemplateVersionEditorTypes, {
  SelectedDocumentItemType,
  SetSelectedDocumentItemType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';

const DocumentItemBlockContainer = Styled.ButtonV2<{isFocused: boolean; index: number}>`
  z-index: ${({index}) => 100 - index}
  border-color: ${({isFocused}) => (isFocused ? colors.blue.interactive : colors.white)};
  border-width: ${({isFocused}) => (isFocused ? '2px' : '0px')};
  border-radius: ${({isFocused}) => (isFocused ? '4px' : '0px')};
`;

const ColumnsRow = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
  flex: 1;
`;

const SectionWrapper = Styled.View<{isHighlighted?: boolean; index: number}>`
  border-radius: 4px;
  border-width: 2px;
  margin: -2px;
  border-color: ${({isHighlighted}) => (isHighlighted ? colors.blue.interactive : 'transparent')};
  z-index: ${({index}) => 100 - index}
`;

const EmptyContentPlaceholderContainer = Styled.View`
  padding: 16px;
  align-items: center;
`;

const EmptyContentPlaceholderText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const EmptyContentPlaceholder = ({
  isLeftColumn,
  isRightColumn,
}: {
  isLeftColumn?: boolean;
  isRightColumn?: boolean;
}) => {
  const responsive = useResponsive();
  return (
    <EmptyContentPlaceholderContainer>
      <EmptyContentPlaceholderText responsive={responsive}>
        {`Document items in the ${isLeftColumn ? 'left column' : isRightColumn ? 'right column' : 'section'} will appear here.`}
      </EmptyContentPlaceholderText>
    </EmptyContentPlaceholderContainer>
  );
};

const DocumentItem = ({
  isPreview,
  isEditable,
  selectedDocumentItem,
  setSelectedDocumentItem,
  documentItem,
  sectionIndex,
  columnIndex,
  index,
  job,
  project,
  invoice,
  document,
  form,
  field,
}: {
  documentItem: DocumentItemType;
  selectedDocumentItem?: SelectedDocumentItemType;
  setSelectedDocumentItem?: SetSelectedDocumentItemType;
  isPreview?: boolean;
  isEditable?: boolean;
  sectionIndex?: number;
  columnIndex?: number;
  index: number;
  job?: JobModel;
  project?: ProjectModel;
  invoice?: InvoiceModel;
  document?: DocumentModel;
  form?: Record<string, any>;
  field?: string;
}) => {
  const isSelected =
    selectedDocumentItem &&
    !_.isNil(selectedDocumentItem.index) &&
    selectedDocumentItem.index === index &&
    selectedDocumentItem.sectionIndex === sectionIndex &&
    selectedDocumentItem.columnIndex === columnIndex;

  return (
    <DocumentItemBlockContainer
      index={index}
      disabled={!setSelectedDocumentItem}
      isFocused={isSelected}
      onPress={() => {
        if (setSelectedDocumentItem) {
          setSelectedDocumentItem({
            ...selectedDocumentItem,
            index: isSelected ? undefined : index,
            documentItem: isSelected ? undefined : documentItem,
            sectionIndex,
            columnIndex,
          });
        }
      }}
    >
      <DocumentItemBlockByCategory
        index={index}
        item={documentItem}
        isPreview={isPreview}
        isEditable={isEditable}
        job={job}
        project={project || job?.project || null}
        invoice={invoice}
        document={document}
        form={form}
        field={field}
      />
    </DocumentItemBlockContainer>
  );
};

const DocumentItemsList = ({
  documentItems,
  isPreview,
  isEditable,
  isBuilder,

  // LIVE DOCUMENTS
  document,
  job,
  project,
  invoice,
  form,
  field,

  // DOCUMENT TEMPLATE EDITOR
  selectedDocumentItem,
  setSelectedDocumentItem,
  sectionIndex,
  columnIndex,
  highlightSectionIndex,
}: {
  documentItems: DocumentItemType[];
  isPreview?: boolean;
  isEditable?: boolean;
  isBuilder?: boolean;

  // LIVE DOCUMENTS
  // data
  document?: DocumentModel;
  job?: JobModel;
  project?: ProjectModel;
  invoice?: InvoiceModel;
  // editing
  form?: Record<string, any>;
  field?: string;

  // DOCUMENT TEMPLATE EDITOR
  // state - for identifying which section / column is being edited
  selectedDocumentItem?: SelectedDocumentItemType;
  setSelectedDocumentItem?: SetSelectedDocumentItemType;
  sectionIndex?: number;
  columnIndex?: number;
  // highlighting - focus attention towards a specific part of the document
  highlightSectionIndex?: number;
}) => {
  // While unlikely that a documentItems would be empty, it's possible to
  // have a section or column that is empty.
  if (_.isEmpty(documentItems)) {
    return null;
  }

  const {getIsColumn} = DocumentTemplateVersionEditorTypes.DocumentItem;
  if (getIsColumn(documentItems[0])) {
    const columns = documentItems as DocumentItemColumnType[];
    return (
      <ColumnsRow>
        {columns.map((documentItem, index) => {
          const columnField = `${field}.documentItemForms.${index}`;
          return (
            <React.Fragment key={index}>
              {index > 0 && <Space width={8} />}
              <Column>
                {isBuilder && !documentItem.documentItems.length ? (
                  <EmptyContentPlaceholder isLeftColumn={index === 0} isRightColumn={index === 1} />
                ) : (
                  <DocumentItemsList
                    documentItems={
                      form
                        ? _.get(form.values, `${columnField}.documentItemForms`)
                        : documentItem.documentItems
                    }
                    selectedDocumentItem={selectedDocumentItem}
                    setSelectedDocumentItem={setSelectedDocumentItem}
                    isPreview={isPreview}
                    isEditable={isEditable}
                    isBuilder={isBuilder}
                    sectionIndex={sectionIndex}
                    columnIndex={index}
                    job={job}
                    project={project}
                    invoice={invoice}
                    document={document}
                    form={form}
                    field={columnField}
                  />
                )}
              </Column>
            </React.Fragment>
          );
        })}
      </ColumnsRow>
    );
  }

  const {getIsSection} = DocumentTemplateVersionEditorTypes.DocumentItem;
  return (
    <React.Fragment>
      {documentItems.map((documentItem, index) => {
        const sectionField = `${field}.documentItemForms.${index}`;
        return (
          <React.Fragment key={index}>
            {getIsSection(documentItem) ? (
              <SectionWrapper isHighlighted={index === highlightSectionIndex} index={index}>
                {isBuilder && !documentItem.documentItems.length ? (
                  <EmptyContentPlaceholder />
                ) : (
                  <DocumentItemsList
                    documentItems={
                      form
                        ? _.get(form.values, `${sectionField}.documentItemForms`)
                        : documentItem.documentItems
                    }
                    selectedDocumentItem={selectedDocumentItem}
                    setSelectedDocumentItem={setSelectedDocumentItem}
                    isPreview={isPreview}
                    isEditable={isEditable}
                    isBuilder={isBuilder}
                    sectionIndex={index}
                    job={job}
                    project={project}
                    invoice={invoice}
                    document={document}
                    form={form}
                    field={sectionField}
                  />
                )}
              </SectionWrapper>
            ) : (
              <React.Fragment>
                <Space height={2} />
                <DocumentItem
                  documentItem={documentItem}
                  selectedDocumentItem={selectedDocumentItem}
                  setSelectedDocumentItem={setSelectedDocumentItem}
                  isPreview={isPreview}
                  isEditable={isEditable}
                  sectionIndex={sectionIndex}
                  columnIndex={columnIndex}
                  index={index}
                  job={job}
                  project={project}
                  invoice={invoice}
                  document={document}
                  form={form}
                  field={`${field}.documentItemForms.${index}`}
                />
                <Space height={2} />
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentItemsList.fragment = gql`
  ${DocumentItemBlockByCategory.fragment}
  fragment DocumentItemsList on Document {
    id
    ...DocumentItemBlockByCategory
  }
`;

export default DocumentItemsList;
