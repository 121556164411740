export interface Token {
  kind: string;
  value: any;
}
interface TokenizerOutput {
  charactersUsed: number;
  token?: Token;
}
interface TokenizerInput {
  stringToTokenize: string;
  currentPosition: number;
}
export type Tokenizer = (input: TokenizerInput) => TokenizerOutput;

export const makeCharacterTokenizer =
  (kind: string, signifier: string): Tokenizer =>
  ({stringToTokenize, currentPosition}) => {
    return stringToTokenize[currentPosition] === signifier
      ? {charactersUsed: 1, token: {kind, value: signifier}}
      : {charactersUsed: 0, token: undefined};
  };

export const makeRegexTokenizer =
  (kind: string, regex: RegExp): Tokenizer =>
  ({stringToTokenize, currentPosition}) => {
    let currentCharacter = stringToTokenize[currentPosition];
    let charactersUsed = 0;
    if (regex.test(currentCharacter)) {
      let tokenValue = '';
      while (currentCharacter && regex.test(currentCharacter)) {
        tokenValue += currentCharacter;
        charactersUsed += 1;
        currentCharacter = stringToTokenize[currentPosition + charactersUsed];
      }
      return {charactersUsed, token: {kind, value: tokenValue}};
    }
    return {charactersUsed: 0, token: undefined};
  };

export const makeIgnoreTokenizer =
  (regex: RegExp): Tokenizer =>
  ({stringToTokenize, currentPosition}) => {
    if (regex.test(stringToTokenize[currentPosition])) {
      return {
        charactersUsed: 1,
        token: undefined,
      };
    }
    return {
      charactersUsed: 0,
      token: undefined,
    };
  };

export const makeDelimiterTokenizer =
  (kind: string, openDelimiter: string, closedDelimiter: string): Tokenizer =>
  ({stringToTokenize, currentPosition}) => {
    if (stringToTokenize[currentPosition] === openDelimiter) {
      let tokenValue = '';
      let charactersUsed = 1;
      let currentCharacter = stringToTokenize[currentPosition + charactersUsed];
      while (currentCharacter !== closedDelimiter) {
        if (currentCharacter === undefined) {
          throw new Error(`Unterminated entity found while looking for ${closedDelimiter}`);
        }
        tokenValue += currentCharacter;
        charactersUsed += 1;
        currentCharacter = stringToTokenize[currentPosition + charactersUsed];
      }
      return {charactersUsed: charactersUsed + 1, token: {kind, value: tokenValue}};
    }
    return {charactersUsed: 0};
  };

export const makeTwoCharacterTokenizer =
  (kind: string, firstCharacter: string, secondCharacter: string): Tokenizer =>
  ({stringToTokenize, currentPosition}) => {
    if (stringToTokenize[currentPosition] === firstCharacter) {
      let tokenValue = stringToTokenize[currentPosition];
      let charactersUsed = 1;
      const currentCharacter = stringToTokenize[currentPosition + charactersUsed];
      if (currentCharacter === secondCharacter) {
        tokenValue += currentCharacter;
        charactersUsed += 1;
        return {charactersUsed, token: {kind, value: tokenValue}};
      }
    }
    return {charactersUsed: 0};
  };

export const KEYWORDS: {[keyword: string]: string} = {
  and: 'And',
  not: 'Not',
  false: 'False',
  true: 'True',
  or: 'Or',
  TBD: 'TBD',
};

export const tokenizeIdentifier: Tokenizer = ({stringToTokenize, currentPosition}) => {
  let currentCharacter = stringToTokenize[currentPosition];
  let charactersUsed = 0;
  if (/[a-z]/i.test(currentCharacter)) {
    let tokenValue = '';
    while (currentCharacter && /[a-z]/i.test(currentCharacter)) {
      tokenValue += currentCharacter;
      charactersUsed += 1;
      currentCharacter = stringToTokenize[currentPosition + charactersUsed];
    }
    const keywordKind = KEYWORDS[tokenValue];
    return {charactersUsed, token: {kind: keywordKind || 'Identifier', value: tokenValue}};
  }
  return {charactersUsed: 0, token: undefined};
};

export const tokenizeNumber: Tokenizer = ({stringToTokenize, currentPosition}) => {
  const numberRegex = /\d/;
  let currentCharacter = stringToTokenize[currentPosition];
  let charactersUsed = 0;
  let tokenValue = '';
  // Numbers start with a number, not a dot.
  if (numberRegex.test(currentCharacter)) {
    while ((currentCharacter && numberRegex.test(currentCharacter)) || currentCharacter === '.') {
      if (numberRegex.test(currentCharacter)) {
        tokenValue += currentCharacter;
        charactersUsed += 1;
        currentCharacter = stringToTokenize[currentPosition + charactersUsed];
      } else if (currentCharacter === '.') {
        if (tokenValue.includes('.')) {
          throw new Error(`Invalid dot: character ${currentPosition + charactersUsed + 1}`);
        }
        tokenValue += currentCharacter;
        charactersUsed += 1;
        currentCharacter = stringToTokenize[currentPosition + charactersUsed];
      }
    }
    return {charactersUsed, token: {kind: 'Number', value: tokenValue}};
  }
  return {charactersUsed: 0, token: undefined};
};
