/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Distance} from '@supermove/utils';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Text = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const IconContainer = Styled.View`
  width: 12px;
  align-items: center;
`;

const JobDistance = ({job}) => {
  return (
    <Row>
      <TextTooltip text={'Mileage'}>
        <IconContainer>
          <Icon color={colors.gray.primary} size={11} source={Icon.Road} style={{marginTop: 2}} />
        </IconContainer>
      </TextTooltip>
      <Space width={6} />
      <Text color={colors.gray.secondary} numberOfLines={1}>
        {Distance.shortDisplay(job.totalDistance)}
      </Text>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDistance.fragment = gql`
  fragment JobDistance on Job {
    id
    totalDistance
  }
`;

export default JobDistance;
