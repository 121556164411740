export interface EmailPreviewForm {
  projectId: number;
  emailTemplate: any | null;
}

const _new = ({projectId}: {projectId: number}): EmailPreviewForm => ({
  projectId,

  // private
  emailTemplate: {
    subject: '',
    body: '',
    bodyKind: '',
  },
});

const toForm = ({projectId, emailTemplate}: EmailPreviewForm): EmailPreviewForm => ({
  projectId,

  // private
  emailTemplate,
});

const toMutation = ({projectId, emailTemplate}: EmailPreviewForm) => ({
  projectId,
  subject: emailTemplate.subject,
  body: emailTemplate.body,
  bodyKind: emailTemplate.bodyKind,
});

const EmailPreviewForm = {
  new: _new,
  toForm,
  toMutation,
};

export default EmailPreviewForm;
