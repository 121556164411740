// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useModal, useResponsive, useSheet} from '@supermove/hooks';
import {Tag} from '@supermove/models';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import TagPill from 'modules/Tag/components/TagPill';
import UpsertProjectTagModal from 'modules/Tag/components/UpsertProjectTagModal';
import UpsertProjectTagSheet from 'modules/Tag/components/UpsertProjectTagSheet';

const Container = Styled.View`
  width: 100%;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const PressableRow = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ProjectTagPill = ({tag, project, refetchAndReset}) => {
  const responsive = useResponsive();

  return (
    <Row>
      <TagPill
        tag={tag}
        tagAssignmentId={tag.tagAssignmentId}
        refetch={refetchAndReset}
        isSmall
        projectId={_.toNumber(project.id)}
        hasDeleteButton={responsive.desktop}
      />
      <Space width={responsive.desktop ? 4 : 12} />
    </Row>
  );
};

const ProjectTags = ({
  project,
  refetchAndReset,
  setShouldCloseOverviewSheetOnClickOutside = undefined,
}) => {
  const responsive = useResponsive();
  const upsertProjectTagModal = useModal({name: 'Upsert Project Tag Modal', enableTracking: true});
  const upsertProjectTagSheet = useSheet({name: 'Upsert Project Tag Sheet', enableTracking: true});
  const tags = Tag.getProjectTagAndTagAssignment(project);
  const tagIds = tags.map((tag) => tag.id);
  const TagsRow = responsive.desktop ? Row : PressableRow;

  useEffect(() => {
    if (setShouldCloseOverviewSheetOnClickOutside) {
      setShouldCloseOverviewSheetOnClickOutside(!upsertProjectTagSheet.isOpen);
    }
  }, [setShouldCloseOverviewSheetOnClickOutside, upsertProjectTagSheet.isOpen]);

  return (
    <React.Fragment>
      <Container>
        <TagsRow
          style={{flexWrap: 'wrap', alignItems: 'center'}}
          onPress={responsive.desktop ? undefined : upsertProjectTagSheet.handleOpen}
        >
          {tags.map((tag, index) => {
            return (
              <ProjectTagPill
                key={index}
                project={project}
                tag={tag}
                index={index}
                refetchAndReset={refetchAndReset}
              />
            );
          })}
          <TertiaryButton
            style={{
              marginBottom: responsive.desktop ? 6 : 8,
              marginLeft: responsive.desktop ? 4 : 0,
            }}
            iconLeft={Icon.Plus}
            text={tags.length === 0 && 'Add Project Tag'}
            iconSize={responsive.desktop ? 16 : 12}
            isHitSlop
            isResponsive
            onPress={
              responsive.desktop
                ? upsertProjectTagModal.handleOpen
                : upsertProjectTagSheet.handleOpen
            }
          />
        </TagsRow>
      </Container>
      <UpsertProjectTagModal
        key={upsertProjectTagModal.key}
        projectId={project.id}
        projectUuid={project.uuid}
        tagIds={tagIds}
        isOpen={upsertProjectTagModal.isOpen}
        handleClose={upsertProjectTagModal.handleClose}
        refetch={refetchAndReset}
      />
      <UpsertProjectTagSheet
        key={upsertProjectTagSheet.key}
        projectId={project.id}
        projectUuid={project.uuid}
        tagIds={tagIds}
        isOpen={upsertProjectTagSheet.isOpen}
        handleClose={upsertProjectTagSheet.handleClose}
        refetch={refetchAndReset}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectTags.fragment = gql`
  ${Tag.getProjectTagAndTagAssignment.fragment}
  ${TagPill.fragment}

  fragment ProjectTags on Project {
    id
    uuid
    tagAssignmentsByProject {
      id
      tag {
        id
        ...TagPill
      }
    }
    ...Tag_getProjectTagAndTagAssignment
  }
`;

export default ProjectTags;
