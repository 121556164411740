// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getJobUsersNames = withFragment(
  (crew) => {
    if ((crew as any).jobUsers.length > 0) {
      return (crew as any).jobUsers.map((jobUser: any) => jobUser.user.fullName).join(', ');
    }
    return ``;
  },
  gql`
    fragment Crew_getJobUsersNames on Crew {
      id
      jobUsers {
        id
        user {
          id
          fullName
        }
      }
    }
  `,
);

const getJobUsersCount = withFragment(
  (crew) => {
    const numActiveCrewUsers = _.get(crew, 'jobUsers.length', 0);
    const numberOfRequiredMovers = _.get(crew, 'numberOfRequiredMovers', 0);
    return `${numActiveCrewUsers}/${numberOfRequiredMovers}`;
  },
  gql`
    fragment Crew_getJobUsersCount on Crew {
      id
      numberOfRequiredMovers
      jobUsers {
        id
      }
    }
  `,
);

const getJobTrucksCount = withFragment(
  (crew) => {
    const numActiveTrucks = _.get(crew, 'jobTrucks.length', 0);
    const numberOfRequiredTrucks = _.get(crew, 'numberOfRequiredTrucks', 0);
    return `${numActiveTrucks}/${numberOfRequiredTrucks}`;
  },
  gql`
    fragment Crew_getJobTrucksCount on Crew {
      id
      numberOfRequiredTrucks
      jobTrucks {
        id
      }
    }
  `,
);

const getIsFullyAssigned = withFragment(
  (crew) => {
    const numActiveTrucks = _.get(crew, 'jobTrucks.length', 0);
    const numberOfRequiredTrucks = _.get(crew, 'numberOfRequiredTrucks', 0);
    const numActiveCrewUsers = _.get(crew, 'jobUsers', []).filter((jobUser: any) => {
      if ((crew as any).organization.settings.allowMoverAcceptDeclineJob) {
        return jobUser.status === 'CONFIRMED';
      }
      return true;
    });
    const numberOfRequiredMovers = _.get(crew, 'numberOfRequiredMovers', 0);

    return (
      numActiveTrucks >= numberOfRequiredTrucks &&
      numActiveCrewUsers.length >= numberOfRequiredMovers
    );
  },
  gql`
    fragment Crew_getIsFullyAssigned on Crew {
      id
      numberOfRequiredTrucks
      jobTrucks {
        id
      }
      numberOfRequiredMovers
      jobUsers {
        id
        status
      }
      organization {
        id
        settings {
          id
          allowMoverAcceptDeclineJob
        }
      }
    }
  `,
);

const Crew = {
  getJobTrucksCount,
  getJobUsersCount,
  getJobUsersNames,
  getIsFullyAssigned,
};

export default Crew;
