/**
 * Metadata used for server-side rendering for better SEO.
 */
export default {
  DEFAULT: {
    title: 'Supermove',
    description: '',
  },

  // Static Pages
  LOGIN: {
    title: 'Login - Supermove',
    description: '',
  },
  REGISTER: {
    title: 'Sign Up - Supermove',
    description: '',
  },
};
