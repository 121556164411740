// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {generated, gql} from '@supermove/graphql';
import {Dashboard} from '@supermove/models';
import {existenceFilter} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownInput from '@shared/design/components/MultiDropdownInput';
import {DashboardCategoryFormEdit} from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import {DashboardTagFormEdit} from '@shared/modules/DashboardTag/forms/DashboardTagForm';

const Container = Styled.View`
`;

type Option = {
  id: string;
  name?: string | null;
};

const toDashboardCategoryOptions = (dashboardCategories: Option[]) =>
  dashboardCategories
    .map((category) => ({
      label: category.name,
      value: _.toNumber(category.id),
    }))
    .sort((a, b) => a.label?.localeCompare(b.label || '') || 0);

const toDashboardTagOptions = (dashboardTags: Option[]) =>
  dashboardTags
    .filter(existenceFilter)
    .map((tag) => ({
      label: tag.name,
      value: _.toNumber(tag.id),
    }))
    .sort((a, b) => a.label?.localeCompare(b.label || '') || 0);

interface GlobalDashboardsLibraryPageDashboardFieldsProps {
  form: any;
  dashboardCategories: NonNullable<
    generated.GlobalDashboardsLibraryPageDashboardFieldsFragment['dashboardCategories']
  >;
  dashboardTags: NonNullable<
    generated.GlobalDashboardsLibraryPageDashboardFieldsFragment['dashboardTags']
  >;
}

const GlobalDashboardsLibraryPageDashboardFields = ({
  form,
  dashboardCategories,
  dashboardTags,
}: GlobalDashboardsLibraryPageDashboardFieldsProps) => {
  const ref = React.useRef(null);
  const field = 'dashboardForm';

  const tagIds = _.get(form.values, `${field}.tagIds`) as string[];

  return (
    <Container ref={ref}>
      <FieldInput
        {...form}
        index={0}
        name={`${field}.name`}
        label={'Report Name'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter report name',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        name={`${field}.dashboardCategoryId`}
        errorName={`${field}.dashboardCategoryId`}
        label={'Category'}
        isResponsive
        isRequired
        component={DropdownInput}
        input={{
          options: toDashboardCategoryOptions(dashboardCategories.filter(existenceFilter)),
          placeholder: `Type to search`,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          noOptionsMessage: '',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={2}
        name={`${field}.exploDashboardEmbedId`}
        label={'Explo ID'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter Explo ID',
          disabled: !!form.values?.globalDashboardForm?.globalDashboardId,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={3}
        name={`${field}.exploVariables`}
        label={'Explo Variables'}
        tooltip={'JSON of custom Explo variables passed in to this report'}
        isResponsive
        input={{
          placeholder: 'Enter Explo Variables',
          value: Dashboard.formatJson(form.values?.dashboardForm?.exploVariables),
          multiline: true,
          style: {height: 136, paddingTop: 8},
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={4}
        name={`${field}.description`}
        label={'Description'}
        isResponsive
        input={{
          placeholder: 'Enter description',
          multiline: true,
          style: {height: 76, paddingTop: 8},
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        label={'Tags'}
        isResponsive
        tooltip={
          'Descriptive tags to help customers easily find this report based on keywords and topics.'
        }
        component={MultiDropdownInput}
        name={`${field}.tagIds`}
        input={{
          value: tagIds,
          options: toDashboardTagOptions(dashboardTags.filter(existenceFilter)),
          setFieldValue: form.setFieldValue,
          placeholder: `Type to search`,
          style: {flex: 1},
          noOptionsMessage: '',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={5}
        name={`${field}.helpArticleUrl`}
        label={'Link to Help Article'}
        tooltip={'This will be shown to the customer when they are viewing this report.'}
        isResponsive
        input={{
          placeholder: 'https://',
        }}
      />
    </Container>
  );
};

export const GlobalDashboardsLibraryPageDashboardFieldsFragment = gql`
  ${DashboardTagFormEdit}
  ${DashboardCategoryFormEdit}
  fragment GlobalDashboardsLibraryPageDashboardFields on Query {
    ${gql.query}
    dashboardCategories {
      id
      name
      ...DashboardCategoryFormEdit
    }
    dashboardTags {
      id
      name
      ...DashboardTagFormEdit
    }
  }
`;

export default GlobalDashboardsLibraryPageDashboardFields;
