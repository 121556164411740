// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useRef, useEffect} from '@supermove/hooks';

// App
import ManagerSearchField from 'modules/App/components/ManagerSearch/components/ManagerSearchField';

const Wrapper = Styled.View`
  flex: 1;
  height: 100%;
  align-items: center;
  padding-top: ${(props) => (props.mobile ? 50 : 100)}px;
  padding-horizontal: 20px;
  background-color: rgba(75, 75, 75, 0.75);
`;

const ManagerSearch = ({organization, trigger}) => {
  const responsive = useResponsive();
  const closeHandlerRef = useRef(null); // Ref for the close handler

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        closeHandlerRef.current && closeHandlerRef.current();
      }
    };

    // Add keydown listener for closing the modal with Escape key
    window.addEventListener('keyup', handleKeyPress);

    return () => {
      window.removeEventListener('keyup', handleKeyPress);
    };
  }, []);

  return (
    <Modal trigger={trigger}>
      {({handleClose}) => {
        // Assign the close handler to the ref
        closeHandlerRef.current = handleClose;

        return (
          <Wrapper pointerEvents={'box-none'} {...responsive}>
            {organization && (
              <ManagerSearchField organization={organization} handleClose={handleClose} />
            )}
          </Wrapper>
        );
      }}
    </Modal>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ManagerSearch.propTypes = {
  organization: PropTypes.object.isRequired,
  trigger: PropTypes.func.isRequired,
};

ManagerSearch.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ManagerSearch.fragment = gql`
  ${ManagerSearchField.fragment}

  fragment ManagerSearch on User {
    id
    ...ManagerSearchField
  }
`;

export default ManagerSearch;
