// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';

const CheckboxText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
`;

const MoverPositionModalFields = ({form, field}) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Position Name'}
        name={`${field}.name`}
        input={{
          placeholder: 'Enter a position',
          required: !_.get(form.values, `${field}.name`),
        }}
      />
      <Space height={20} />
      <Checkbox
        isChecked={_.get(form.values, `${field}.isDriver`)}
        childrenRight
        handleToggle={() =>
          form.setFieldValue(`${field}.isDriver`, !_.get(form.values, `${field}.isDriver`))
        }
      >
        <Space width={8} />
        <CheckboxText>This position can drive the truck</CheckboxText>
      </Checkbox>
    </React.Fragment>
  );
};

export default MoverPositionModalFields;
