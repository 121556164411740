// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getSortedMoverPositions = withFragment(
  (moverPositions) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    return _.orderBy(moverPositions, ['isDriver', 'name'], ['desc', 'asc']);
  },
  gql`
    fragment MoverPosition_getSortedMoverPositions on MoverPosition {
      id
      isDriver
      name
    }
  `,
);

const MoverPosition = {
  getSortedMoverPositions,
};

export default MoverPosition;
