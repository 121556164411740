// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({organizationId, category}: any) => ({
  organizationId,
  category,
  projectTypeId: null,
  name: '',
  color: '',
  billingLibraryId: null,
  billTypeIds: [],
  isLongDistance: false,
  isJobLevelBilling: false,
});

const edit = withFragment(
  (projectType) => ({
    projectTypeId: (projectType as any).id,
    category: (projectType as any).category,
    organizationId: (projectType as any).organizationId,
    billingLibraryId: String((projectType as any).billingLibraryId),
    color: (projectType as any).color,
    name: (projectType as any).name,
    billTypeIds: (projectType as any).activeBillTypeIds,
    isLongDistance: (projectType as any).isLongDistance,
    isJobLevelBilling: (projectType as any).isJobLevelBilling,
  }),
  gql`
    fragment ProjectTypeForm_edit on ProjectType {
      id
      category
      organizationId
      billingLibraryId
      color
      name
      activeBillTypeIds
      isLongDistance
      isJobLevelBilling
    }
  `,
);

const toForm = ({
  projectTypeId,
  index,
  organizationId,
  category,
  billingLibraryId,
  color,
  name,
  billTypeIds,
  isLongDistance,
  isJobLevelBilling,
}: any) => ({
  projectTypeId,
  index,
  organizationId,
  category,
  billingLibraryId,
  color,
  name,
  billTypeIds,
  isLongDistance,
  isJobLevelBilling,
});

const toMutation = ({
  projectTypeId,
  index,
  organizationId,
  category,
  billingLibraryId,
  color,
  name,
  billTypeIds,
  isLongDistance,
  isJobLevelBilling,
}: any) => ({
  projectTypeId,
  index,
  organizationId,
  category,
  billingLibraryId,
  color,
  name,
  billTypeIds,
  isLongDistance,
  isJobLevelBilling,
});

const ProjectTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ProjectTypeForm;
