/**
 * TODO(mark): This should be deprecated, the new freight bill is backend-driven.
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job, User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Currency, Datetime, Phone} from '@supermove/utils';

// Components

const Container = Styled.View`
`;

const Header = Styled.View`
`;

const HeaderText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Text = Styled.H7`
  line-height: 18px;
  color: ${colors.gray.primary};
`;

const DocumentFreightBillInfo = ({job}) => (
  <Container>
    <Header>
      <HeaderText>{Job.getFullName(job)}</HeaderText>
    </Header>
    <Row>
      <Text>{`Order number: ${job.project.name}`}</Text>
    </Row>
    <Row>
      <Text>{`Date move tendered & bill issued: ${Job.getDispatchDisplayDate(job)}`}</Text>
    </Row>
    <Row>
      <Text>{`Shipper (customer): ${User.getCustomerDisplayName(job.customer)}`}</Text>
    </Row>
    <Row>
      <Text>
        {`Party to be notified on delivery or for Item 100: ${User.getFullName(
          job.customer,
        )} ${Phone.display(job.customer.phoneNumber)}`}
      </Text>
    </Row>
    <Row>
      <Text>
        {`Points of origin / destination: ${job.pickUpLocation.displayAddress} / ${job.dropOffLocation.displayAddress}`}
      </Text>
    </Row>
    <Row>
      <Text>
        {`Description of shipment: ${
          job.moveSize
            ? `Shipper's ${job.moveSize} of household goods`
            : `Shipper's household goods`
        }`}
      </Text>
    </Row>
    {job.organization.features.isEnabledDocumentSummaryShowNumberOfMovers && (
      <Row>
        <Text>{`Number of crew members: ${Job.getActiveJobUsersCountText(job)}`}</Text>
      </Row>
    )}
    <Row>
      <Text>
        {`Date of pickup and expected delivery: ${Datetime.convertToDisplayDate(job.startDate)}`}
      </Text>
    </Row>
    {job.notToExceedPrice ? (
      <React.Fragment>
        <Row>
          <Text>{`Not To Exceed Price: ${Currency.display(job.notToExceedPrice)}`}</Text>
        </Row>
      </React.Fragment>
    ) : null}
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DocumentFreightBillInfo.propTypes = {
  job: PropTypes.object.isRequired,
};

DocumentFreightBillInfo.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentFreightBillInfo.fragment = gql`
  ${Job.getActiveJobUsersCountText.fragment}
  ${Job.getFullName.fragment}
  ${Job.getDispatchDisplayDate.fragment}

  fragment DocumentFreightBillInfo on Job {
    id
    startDate
    startTime1
    startTime2
    moveSize
    additionalItems
    notToExceedPrice: notToExceedPriceV2
    organization {
      id
      features {
        isEnabledDocumentSummaryShowNumberOfMovers: isEnabled(
          feature: "DOCUMENT_SUMMARY_SHOW_NUMBER_OF_MOVERS"
        )
      }
    }
    customer {
      id
      aliasName
      firstName
      lastName
      phoneNumber
    }
    pickUpLocation {
      id
      displayAddress
    }
    dropOffLocation {
      id
      displayAddress
    }
    project {
      id
      name
    }
    ...Job_getActiveJobUsersCountText
    ...Job_getFullName
    ...Job_getDispatchDisplayDate
  }
`;

export default DocumentFreightBillInfo;
