// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import JobTypeForm from '@shared/modules/Job/forms/JobTypeForm';

const useDeleteJobTypeMutation = ({jobTypeForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      jobTypeForm: JobTypeForm.toForm(jobTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteJobTypeMutation.mutation,
    variables: {
      jobTypeForm: JobTypeForm.toMutation(form.values.jobTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteJobTypeMutation.mutation = gql`
  mutation useDeleteJobTypeMutation($jobTypeForm: JobTypeForm!) {
    response: deleteJobType(jobTypeForm: $jobTypeForm) {
      ${gql.errors}
      jobType {
        id
      }
    }
  }
`;

export default useDeleteJobTypeMutation;
