// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import CostItemUnit from '@shared/modules/Billing/enums/CostItemUnit';
import CostItemForm from '@shared/modules/Billing/forms/CostItemForm';
import CostSectionForm from '@shared/modules/Billing/forms/CostSectionForm';
import Line from 'modules/App/components/Line';
import CostItemRateInputs from 'modules/Cost/components/CostItemRateInputs';

const Container = Styled.View`
  z-index: 100;
`;

const Button = Styled.ButtonV2`
`;

const ButtonText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.blue.interactive};
  text-decoration-line: underline;
`;

const ItemRow = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex-direction: row;
  align-items: center;
  padding-horizontal: 8px;
`;

const HeaderLabel = Styled.Text`
  ${Typography.Body5}
  color: ${colors.gray.secondary};
`;

const Cell = Styled.View`
  flex: ${({flex}) => flex};
  flex-direction: row;
  align-items: center;
  justify-content: ${({justify}) => justify};
  padding-horizontal: 8px;
  border-color: ${colors.gray.border};
`;

const CellText = Styled.Text`
  ${Typography.Body4}
`;

const handleAddCostItemToBillItem = ({form, field, billItemForm, costId}) => {
  const costItemForm = CostItemForm.newFromBillItemForm(billItemForm, {costId});
  form.setFieldValue(`${field}.costItemForm`, costItemForm);
};

const handleUpdateTotal = ({form, field, costSectionField, total}) => {
  CostSectionForm.handleUpdateTotalAndCostItem({
    form,
    field: costSectionField,
    costItemField: `${field}.costItemForm`,
    costItemTotal: total,
  });
};

const handleChangeUnit = ({form, field, costSectionField, unit}) => {
  const wasPercent = _.get(form.values, `${field}.costItemForm.unit`) === CostItemUnit.PERCENT;
  const isPercent = unit === CostItemUnit.PERCENT;
  const rate = _.get(form.values, `${field}.costItemForm.rate`);
  if (wasPercent !== isPercent) {
    form.setFieldValue(`${field}.costItemForm.rate`, '');
    handleUpdateTotal({form, field, costSectionField, total: 0});
  } else if (rate) {
    const rateInCents = Currency.convertToCents(rate);
    const quantity = _.get(form.values, `${field}.minQuantity`, 0);
    handleUpdateTotal({form, field, costSectionField, total: rateInCents * quantity});
  }
};

const handleChangeRate = ({form, field, costSectionField, rate}) => {
  const unit = _.get(form.values, `${field}.costItemForm.unit`);
  const rateInCents = Currency.convertToCents(rate);
  switch (unit) {
    case CostItemUnit.PERCENT: {
      const rateInPercent = Percent.toFloat(rate);
      const billItemTotal = _.get(form.values, `${field}.total`);
      return handleUpdateTotal({
        form,
        field,
        costSectionField,
        total: rateInPercent * billItemTotal,
      });
    }
    default: {
      const quantity = _.get(form.values, `${field}.minQuantity`, 0);
      return handleUpdateTotal({form, field, costSectionField, total: rateInCents * quantity});
    }
  }
};

const BILL_ITEMS_TABLE_COLUMNS = [
  {
    flex: 6,
    justify: 'flex-start',
    headerContent: () => {
      return <HeaderLabel>Billing Item Name</HeaderLabel>;
    },
    cellContent: ({form, field}) => {
      const name = _.get(form.values, `${field}.name`);
      return <CellText>{name}</CellText>;
    },
  },
  {
    flex: 1,
    justify: 'flex-end',
    headerContent: () => {
      return <HeaderLabel>Quantity</HeaderLabel>;
    },
    cellContent: ({form, field}) => {
      const quantity = _.get(form.values, `${field}.minQuantity`);
      return <CellText>{quantity}</CellText>;
    },
  },
  {
    flex: 2,
    justify: 'flex-end',
    headerContent: () => {
      return <HeaderLabel>Item Price</HeaderLabel>;
    },
    cellContent: ({form, field}) => {
      const price = _.get(form.values, `${field}.amount`);
      return <CellText>{price}</CellText>;
    },
  },
  {
    flex: 2,
    justify: 'flex-end',
    style: {borderRightWidth: 1},
    headerContent: () => {
      return <HeaderLabel style={{paddingVertical: 8}}>Total Price</HeaderLabel>;
    },
    cellContent: ({form, field}) => {
      const total = _.get(form.values, `${field}.total`);
      return (
        <CellText style={{paddingVertical: 10}}>
          {Currency.display(total, {shouldHideCentsIfZero: true})}
        </CellText>
      );
    },
  },
  {
    flex: 4,
    justify: 'flex-end',
    headerContent: () => {
      return <HeaderLabel>Cost Rate</HeaderLabel>;
    },
    cellContent: ({form, field, costSectionField, billItemForm, costId}) => {
      const costItemForm = _.get(form.values, `${field}.costItemForm`);
      return (
        <React.Fragment>
          {!costItemForm ? (
            <CellText>
              <Button
                onPress={() => handleAddCostItemToBillItem({form, field, billItemForm, costId})}
              >
                <ButtonText>Add</ButtonText>
              </Button>
            </CellText>
          ) : (
            <CostItemRateInputs
              form={form}
              field={`${field}.costItemForm`}
              handleChangeRate={(rate) => {
                handleChangeRate({form, field, costSectionField, rate});
              }}
              handleChangeUnit={(unit) => {
                handleChangeUnit({form, field, costSectionField, unit});
              }}
              costItemForm={costItemForm}
              billItemUnit={billItemForm.unit}
            />
          )}
        </React.Fragment>
      );
    },
  },
  {
    flex: 2,
    justify: 'flex-end',
    headerContent: () => {
      return <HeaderLabel>Cost Value</HeaderLabel>;
    },
    cellContent: ({form, field}) => {
      const costItemTotal = _.get(form.values, `${field}.costItemForm.total`);
      return <CellText>{costItemTotal}</CellText>;
    },
  },
];

const CostSectionBillItems = ({form, field}) => {
  const costId = _.get(form.values, `${field}.costId`);
  const billItemForms = _.get(form.values, `${field}.billItemForms`);
  const isViewingAllBillItems = _.get(form.values, `${field}.isViewingAllBillItems`);
  return (
    <Container>
      <ItemRow index={0}>
        {BILL_ITEMS_TABLE_COLUMNS.map(({flex, justify, style, headerContent}, index) => (
          <Cell key={index} flex={flex} justify={justify} style={style}>
            {headerContent()}
          </Cell>
        ))}
      </ItemRow>
      <Line />
      {billItemForms.map((billItemForm, itemIndex) => {
        if (!isViewingAllBillItems && !billItemForm.costItemForm) {
          return null;
        }
        return (
          <ItemRow
            key={itemIndex}
            style={{backgroundColor: colors.gray.background}}
            index={itemIndex}
          >
            {BILL_ITEMS_TABLE_COLUMNS.map(({flex, justify, style, cellContent}, cellIndex) => (
              <Cell key={cellIndex} flex={flex} justify={justify} style={style}>
                {cellContent({
                  form,
                  field: `${field}.billItemForms.${itemIndex}`,
                  costSectionField: field,
                  billItemForm,
                  costId,
                })}
              </Cell>
            ))}
          </ItemRow>
        );
      })}
    </Container>
  );
};

export default CostSectionBillItems;
