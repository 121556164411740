const ITEM_TAG = 'ITEM_TAG';
const EXCEPTION_TYPE = 'EXCEPTION_TYPE';
const EXCEPTION_LOCATION = 'EXCEPTION_LOCATION';

const ItemTagKind = {
  ITEM_TAG,
  EXCEPTION_TYPE,
  EXCEPTION_LOCATION,
};

export default ItemTagKind;
