// Libraries
import React from 'react';

// Supermove
import {Styled, Tooltip} from '@supermove/components';
import {Typography, colors} from '@supermove/styles';

const Text = Styled.Text`
  ${Typography.Body};
  color: ${colors.white};
`;

type Placement = 'top' | 'bottom' | 'right' | 'left';

const TooltipOverlay = ({
  text,
  placement,
  numberOfLines,
  style,
  children,
}: {
  text: string;
  placement?: Placement;
  numberOfLines?: number;
  style?: object;
  children: React.ReactNode;
}) => {
  return (
    <Tooltip
      overlay={
        <Text numberOfLines={numberOfLines} style={style}>
          {text}
        </Text>
      }
      placement={placement}
      mouseEnterDelay={0.0}
      mouseLeaveDelay={0.0}
      overlayStyle={style || {maxWidth: '20%'}}
      children={children}
    />
  );
};

export type TextTooltipTextType = string | null | undefined;

const TextTooltip = ({
  text,
  placement,
  numberOfLines,
  style,
  children,
}: {
  text?: TextTooltipTextType;
  placement?: Placement;
  numberOfLines?: number;
  style?: object;
  children: React.ReactNode;
}) => {
  return text ? (
    <TooltipOverlay text={text} placement={placement} numberOfLines={numberOfLines} style={style}>
      {children}
    </TooltipOverlay>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

export default TextTooltip;
