// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled, Tooltip} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import Pill from 'components/Pill';

const LockToolTipText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.white};
`;

const LockBackground = Styled.View`
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${colors.gray.border};
`;

const LockView = ({projectTypeDay}) => {
  return (
    <Tooltip
      placement={'bottom'}
      overlay={() => {
        return (
          <LockToolTipText>
            {projectTypeDay.isSalesLocked ? `No Booking Availability` : `Has Booking Availability`}
          </LockToolTipText>
        );
      }}
    >
      <LockBackground>
        {projectTypeDay.isSalesLocked ? (
          <Icon color={colors.gray.tertiary} size={Icon.Sizes.ExtraSmall} source={Icon.Lock} />
        ) : (
          <Icon color={colors.gray.primary} size={Icon.Sizes.ExtraSmall} source={Icon.LockOpen} />
        )}
      </LockBackground>
    </Tooltip>
  );
};

const ProjectTypeDayItem = ({projectTypeDay, isMultiBranch}) => {
  return (
    <Pill
      icon={
        projectTypeDay.organization.canCreateJobs && (
          <React.Fragment>
            <LockView projectTypeDay={projectTypeDay} />
            <Space width={4} />
          </React.Fragment>
        )
      }
      color={projectTypeDay.projectType.color}
      count={projectTypeDay.jobsCount}
      text={
        isMultiBranch
          ? `${projectTypeDay.projectType.name} (${projectTypeDay.projectType.organization.name})`
          : projectTypeDay.projectType.name
      }
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ProjectTypeDayItem.propTypes = {
  projectTypeDay: PropTypes.object.isRequired,
};

ProjectTypeDayItem.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeDayItem.fragment = gql`
  fragment ProjectTypeDayItem on ProjectTypeDay {
    id
    isSalesLocked
    jobsCount
    organization {
      id
      canCreateJobs
      name
    }
    projectType {
      id
      color
      name
    }
  }
`;

export default ProjectTypeDayItem;
