// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight, Typography} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: ${({isSmall}) => (isSmall ? '4px' : '8px')};
  padding-vertical: ${({isSmall}) => (isSmall ? '2px' : '4px')};
  border-radius: ${({isSmall}) => (isSmall ? '4px' : '4px')};
  background-color: ${(props) => colors.alpha(props.color, 0.15)};
`;

const Text = Styled.H7`
  color: ${(props) => props.color};
  ${fontWeight(500)}
`;

const SmallText = Styled.H8`
  color: ${(props) => props.color};
  ${fontWeight(500)}
`;

const LabelText = Styled.Text`
  ${Typography.Label3}
  color: ${(props) => props.color};
`;

const TextBadge = ({text, color, isSmall, isLabel}) => {
  return (
    <Container color={color} isSmall={isSmall}>
      {isLabel ? (
        <LabelText color={color}>{text}</LabelText>
      ) : isSmall ? (
        <SmallText color={color}>{text}</SmallText>
      ) : (
        <Text color={color}>{text}</Text>
      )}
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
TextBadge.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isSmall: PropTypes.bool,
  isLabel: PropTypes.bool,
};

TextBadge.defaultProps = {
  isSmall: false,
  isLabel: false,
};

export default TextBadge;
