// Import dependencies

import {uuid} from '@supermove/utils';

export interface AssignOfficeStaffActionFormType {
  workflowRunStepUuid: string;
  userRoleKind: string | null;
  workflowProjectAssignmentType: string | null;
  specificUserId: number | null;
  roundRobinUserIds: number[] | null;
}

const _new = (): AssignOfficeStaffActionFormType => ({
  workflowRunStepUuid: uuid(),
  userRoleKind: null,
  workflowProjectAssignmentType: null,
  specificUserId: null,
  roundRobinUserIds: null,
});

const edit = (assignOfficeStaffActionForm: any): AssignOfficeStaffActionFormType => ({
  workflowRunStepUuid: assignOfficeStaffActionForm.workflowRunStepUuid,
  userRoleKind: assignOfficeStaffActionForm.userRoleKind,
  workflowProjectAssignmentType: assignOfficeStaffActionForm.workflowProjectAssignmentType,
  specificUserId: assignOfficeStaffActionForm.specificUserId,
  roundRobinUserIds: assignOfficeStaffActionForm.roundRobinUserIds,
});

const toForm = (
  AssignOfficeStaffActionForm: AssignOfficeStaffActionFormType,
): AssignOfficeStaffActionFormType => ({
  workflowRunStepUuid: AssignOfficeStaffActionForm.workflowRunStepUuid,
  userRoleKind: AssignOfficeStaffActionForm.userRoleKind,
  workflowProjectAssignmentType: AssignOfficeStaffActionForm.workflowProjectAssignmentType,
  specificUserId: AssignOfficeStaffActionForm.specificUserId,
  roundRobinUserIds: AssignOfficeStaffActionForm.roundRobinUserIds,
});

const toMutation = (
  AssignOfficeStaffActionForm: AssignOfficeStaffActionFormType,
): AssignOfficeStaffActionFormType => ({
  workflowRunStepUuid: AssignOfficeStaffActionForm.workflowRunStepUuid,
  userRoleKind: AssignOfficeStaffActionForm.userRoleKind,
  workflowProjectAssignmentType: AssignOfficeStaffActionForm.workflowProjectAssignmentType,
  specificUserId: AssignOfficeStaffActionForm.specificUserId,
  roundRobinUserIds: AssignOfficeStaffActionForm.roundRobinUserIds,
});

const AssignOfficeStaffActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default AssignOfficeStaffActionForm;
