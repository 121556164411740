// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

// App
import ProjectDateKind from '@shared/modules/Project/enums/ProjectDateKind';

const edit = withFragment(
  (project, {projectDateKind}) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const jobUuidKey = ProjectDateKind.PROJECT_DATE_KIND_TO_JOB_UUID_MAP[projectDateKind];
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const startDateKey = ProjectDateKind.PROJECT_DATE_KIND_TO_START_DATE_MAP[projectDateKind];
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const endDateKey = ProjectDateKind.PROJECT_DATE_KIND_TO_END_DATE_MAP[projectDateKind];
    const scheduledDateKey =
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ProjectDateKind.PROJECT_DATE_KIND_TO_SCHEDULED_DATE_MAP[projectDateKind];

    return {
      projectId: (project as any).id,
      startDate: (project as any)[startDateKey],
      endDate: (project as any)[endDateKey],
      scheduledDate: (project as any)[scheduledDateKey],
      kind: projectDateKind,
      jobUuid: (project as any)[jobUuidKey],
    };
  },
  gql`
    fragment ProjectDatesForm_edit on Project {
      id
      packJobUuid
      packJobStartDate
      packJobEndDate
      packJobScheduledDate
      loadJobUuid
      loadJobStartDate
      loadJobEndDate
      loadJobScheduledDate
      deliveryJobUuid
      deliveryJobStartDate
      deliveryJobEndDate
      deliveryJobScheduledDate
    }
  `,
);

const toForm = ({projectId, startDate, endDate, kind, jobUuid, scheduledDate}: any) => ({
  projectId,
  jobUuid,
  kind,
  startDate: startDate ? Datetime.fromDate(startDate) : null,
  endDate: endDate ? Datetime.fromDate(endDate) : null,
  scheduledDate: scheduledDate ? Datetime.fromDate(scheduledDate) : null,
});

const toMutation = ({projectId, startDate, endDate, kind, jobUuid, scheduledDate}: any) => ({
  projectId,
  jobUuid,
  kind,
  startDate: startDate ? Datetime.toMutationDate(startDate) : null,
  endDate: endDate ? Datetime.toMutationDate(endDate) : null,
  scheduledDate: scheduledDate ? Datetime.toMutationDate(scheduledDate) : null,
});

const ProjectDatesForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectDatesForm;
