// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import BillingLibraryForm from '@shared/modules/Billing/forms/BillingLibraryForm';

const useCreateBillingLibraryMutation = ({billingLibraryForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      billingLibraryForm: BillingLibraryForm.toForm(billingLibraryForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateBillingLibraryMutation.mutation,
    variables: {
      billingLibraryForm: BillingLibraryForm.toMutation(form.values.billingLibraryForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateBillingLibraryMutation.mutation = gql`
  mutation useCreateBillingLibraryMutation($billingLibraryForm: BillingLibraryForm!) {
    response: createBillingLibrary(billingLibraryForm: $billingLibraryForm) {
      ${gql.errors}
      billingLibrary {
        id
      }
    }
  }
`;

export default useCreateBillingLibraryMutation;
