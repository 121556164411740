// Supermove

import StopActionForm, {StopActionFormType} from '@shared/modules/Workflow/StopActionForm';
import TransformWorkflowActionForm, {
  TransformWorkflowActionFormType,
} from '@shared/modules/Workflow/TransformWorkflowActionForm';
import WorkflowFormulaBuilder from '@shared/modules/Workflow/enums/WorkflowFormulaBuilder';
import WorkflowModelDataTypes from '@shared/modules/Workflow/enums/WorkflowModelDataTypes';
import WorkflowModelProperties from '@shared/modules/Workflow/enums/WorkflowModelProperties';

export interface StopIfActionFormType {
  stopActionForm: StopActionFormType;
  transformWorkflowActionForm: TransformWorkflowActionFormType;
  stepReferencePath: string;
  propertyName: string;
  propertyDataType: string;
  comparatorType: string;
  comparatorValue: string | boolean;
  stepReferenceKind: string;
}

const constructFormulaString = (stopIfActionForm: StopIfActionFormType) => {
  // Get Property Path
  const dbPropertyName = WorkflowModelProperties.getDbPropertyName(stopIfActionForm.propertyName);
  const propertyPath = `get('${stopIfActionForm.stepReferencePath}.${dbPropertyName}')`;

  // Get Comparator String
  const comparatorString = WorkflowFormulaBuilder.getFormulaString(stopIfActionForm.comparatorType);

  // Calculate Comparator Value Text
  const {propertyDataType} = stopIfActionForm;
  const {comparatorValue} = stopIfActionForm;
  const comparatorValueText = WorkflowModelDataTypes.STRING_TYPES.includes(propertyDataType)
    ? `'${comparatorValue}'`
    : comparatorValue;

  return `${propertyPath} ${comparatorString} ${comparatorValueText}`;
};

const _new = (): StopIfActionFormType => ({
  stopActionForm: StopActionForm.new(),
  transformWorkflowActionForm: TransformWorkflowActionForm.new(),
  stepReferencePath: '',
  propertyName: '',
  propertyDataType: '',
  comparatorType: '',
  comparatorValue: '',
  stepReferenceKind: '',
});

const edit = (stopIfActionForm: any): StopIfActionFormType => ({
  stopActionForm: StopActionForm.edit(stopIfActionForm.stopActionForm),
  transformWorkflowActionForm: TransformWorkflowActionForm.edit(
    stopIfActionForm.transformWorkflowActionForm,
  ),
  stepReferencePath: stopIfActionForm.stepReferencePath,
  propertyName: stopIfActionForm.propertyName,
  propertyDataType: stopIfActionForm.propertyDataType,
  comparatorType: stopIfActionForm.comparatorType,
  comparatorValue: stopIfActionForm.comparatorValue,
  stepReferenceKind: stopIfActionForm.stepReferenceKind,
});

const toForm = (stopIfActionForm: StopIfActionFormType): StopIfActionFormType => ({
  stopActionForm: StopActionForm.toForm(stopIfActionForm.stopActionForm),
  transformWorkflowActionForm: TransformWorkflowActionForm.toForm(
    stopIfActionForm.transformWorkflowActionForm,
  ),
  stepReferencePath: stopIfActionForm.stepReferencePath,
  propertyName: stopIfActionForm.propertyName,
  propertyDataType: stopIfActionForm.propertyDataType,
  comparatorType: stopIfActionForm.comparatorType,
  comparatorValue: stopIfActionForm.comparatorValue,
  stepReferenceKind: stopIfActionForm.stepReferenceKind,
});

const toMutation = (stopIfActionForm: StopIfActionFormType): StopIfActionFormType => ({
  stopActionForm: StopActionForm.toMutation(stopIfActionForm.stopActionForm),
  transformWorkflowActionForm: TransformWorkflowActionForm.toMutation({
    ...stopIfActionForm.transformWorkflowActionForm,
    transformWorkflowFormulaString: constructFormulaString(stopIfActionForm),
    transformPath: `workflowRunSteps.["${stopIfActionForm.stopActionForm.workflowRunStepUuid}"].inputMetadata.shouldStop`,
  }),
  stepReferencePath: stopIfActionForm.stepReferencePath,
  propertyName: stopIfActionForm.propertyName,
  propertyDataType: stopIfActionForm.propertyDataType,
  comparatorType: stopIfActionForm.comparatorType,
  comparatorValue: stopIfActionForm.comparatorValue,
  stepReferenceKind: stopIfActionForm.stepReferenceKind,
});

const StopIfActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default StopIfActionForm;
