// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import ItemTypeForm from '@shared/modules/Inventory/forms/ItemTypeForm';

const edit = withFragment(
  (itemTypes) => ({
    itemTypeForms: (itemTypes as any).map((itemType: any) => ItemTypeForm.edit(itemType)),
  }),
  gql`
    ${ItemTypeForm.edit.fragment}
    fragment ItemTypesForm_edit on ItemType {
      id
      ...ItemTypeForm_edit
    }
  `,
);

const toForm = ({itemTypeForms}: any) => ({
  itemTypeForms: itemTypeForms.map((itemTypeForm: any) => ItemTypeForm.toForm(itemTypeForm)),
});

const toMutation = ({itemTypeForms}: any) => ({
  itemTypeForms: _.reduce(
    itemTypeForms,
    // @ts-expect-error TS(2769): No overload matches this call.
    (filteredForms, itemTypeForm, index) => {
      if (itemTypeForm.isDirty) {
        const isDeletingNewForm = itemTypeForm.isDeleted && !itemTypeForm.itemTypeId;
        if (isDeletingNewForm) {
          // we don't pass these forms to the BE since there isn't an object to delete
          return filteredForms;
        }
        return [
          ...filteredForms,
          {
            ...ItemTypeForm.toMutation(itemTypeForm),
            // since we only pass dirty forms, we need to pass the current index to ensure the validation error is shown properly
            validationIndex: index,
          },
        ];
      }
      return filteredForms;
    },
    [],
  ),
});

const ItemTypesForm = {
  edit,
  toForm,
  toMutation,
};

export default ItemTypesForm;
