// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import RadioButton from '@shared/design/components/RadioButton';
import StatusBadge from 'modules/App/components/StatusBadge';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ClientCreditCardsRadioButtonListContainer = Styled.View`
`;

const OptionButton = Styled.ButtonV2`
  padding-vertical: 16px;
  padding-horizontal: 8px;
  border-radius: 4px;
  background-color: ${({isSelected}) => (isSelected ? colors.blue.accent : 'transparent')};
`;

const ErrorCalloutContainer = Styled.View`
  padding-left: 22px;
`;

const CreditCardName = Styled.Text`
  ${Typography.Responsive.Body}
`;

const CreditCardDetails = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const ClientCreditCardsRadioButtonList = ({
  client,
  handleSelectCard,
  selectedCardId,
  isDisabled,
  errorMessage,
  responsive,
}) => {
  return (
    <ClientCreditCardsRadioButtonListContainer>
      {client.creditCards.map((creditCard) => {
        const {isDefault} = creditCard;
        const isSelected = _.toString(creditCard.id) === _.toString(selectedCardId);
        return (
          <OptionButton
            key={creditCard.id}
            isSelected={isSelected}
            onPress={() => handleSelectCard(creditCard)}
            disabled={isSelected || isDisabled}
          >
            <Row>
              <RadioButton isOn={isSelected} />
              <Space width={8} />
              <Column>
                <Row>
                  <CreditCardName responsive={responsive}>{creditCard.cardName}</CreditCardName>
                  {isDefault && (
                    <React.Fragment>
                      <Space width={8} />
                      <StatusBadge label={'Default'} color={colors.gray.secondary} />
                    </React.Fragment>
                  )}
                </Row>
                <Space height={4} />
                <CreditCardDetails responsive={responsive}>
                  {`${creditCard.cardSummaryName}, Expires ${creditCard.expireDate}`}
                </CreditCardDetails>
              </Column>
            </Row>
            {isSelected && !!errorMessage && (
              <React.Fragment>
                <Space height={16} />
                <ErrorCalloutContainer>
                  <ErrorCallout text={errorMessage} />
                </ErrorCalloutContainer>
              </React.Fragment>
            )}
          </OptionButton>
        );
      })}
    </ClientCreditCardsRadioButtonListContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientCreditCardsRadioButtonList.fragment = gql`
  fragment ClientCreditCardsRadioButtonList on Client {
    id
    creditCards {
      id
      cardName
      cardSummaryName
      isDefault
      expireDate
    }
  }
`;

export default ClientCreditCardsRadioButtonList;
