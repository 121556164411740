// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {Form} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import {BillTypeFormToFormType} from '@shared/modules/Billing/forms/BillTypeForm';

const BillTypeDetailsPanel = ({
  form,
  field,
}: {
  form: Form<{billTypeForm: BillTypeFormToFormType}>;
  field: string;
}) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        isRequired
        isResponsive
        label={'Bill Name'}
        name={`${field}.name`}
        input={{
          placeholder: 'Enter name',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        isResponsive
        label={'Bill Description'}
        name={`${field}.description`}
        input={{
          placeholder: 'Enter description',
        }}
      />
    </React.Fragment>
  );
};

export default BillTypeDetailsPanel;
