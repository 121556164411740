// Libraries
import {loadPayengine} from 'payengine';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

import {PayEngineConfig} from '@shared/modules/Payment/components/PayEngineConfig';

// Assets
import './CreditCardInput.css';

const Container = Styled.View``;

const Row = Styled.View`
  flex-direction: row;
`;

const WarningTextContainer = Styled.View`
  margin: 4px
  padding: 10px
  border-radius: 5px;
  background-color: ${colors.orange.accent};
`;
const WarningText = Styled.H7`
  color: ${colors.orange.status};
`;

type OwnProps = {
  setCreditCardClient: (...args: any[]) => any;
  onLoad: (...args: any[]) => any;
  mode: 'LIVE' | 'SANDBOX';
  styleWeb?: any;
  styleNative?: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof PayEngineCreditCardInput.defaultProps;

// @ts-expect-error TS(7022): 'PayEngineCreditCardInput' implicitly has type 'an... Remove this comment to see the full error message
const PayEngineCreditCardInput = ({
  setCreditCardClient,
  onLoad,
  mode,
  styleWeb,
  styleNative,
}: Props) => {
  // TODO(atsu): Figure out how to render a loading indicator for the iframe form fields. We can't use our standard
  //  Loading component here, because the DOM nodes for the fields must exist while the load is happening.
  React.useEffect(() => {
    loadPayengine(PayEngineConfig.get(mode)).then((payengine) => {
      payengine.SecureFields.create()
        .then((form: any) => {
          const styles = {
            successColor: '#4F8A10',
            errorColor: '#D8000C',
          };
          const css = {
            fontSize: '1em',
            color: '#424770',
            background: '#FFFFFF',
            letterSpacing: '0.025em',
            fontFamily: 'Avenir, Source Code Pro, monospace',
            border: '1px solid #cfcfcf',
            borderRadius: '3px',
            boxSizing: 'border-box',
            padding: '10px 14px',
          };

          form.field('#card_number', {
            type: 'card-number',
            name: 'card_number',
            placeholder: 'Card number',
            showCardIcon: true,
            validations: ['required', 'validCardNumber'],
            ...styles,
            css,
          });
          form.field('#card_cvc', {
            type: 'card-security-code',
            name: 'card_cvc',
            placeholder: 'CVC',
            validations: ['required', 'validCardSecurityCode'],
            showCardIcon: true,
            ...styles,
            css,
          });
          form.field('#card_exp', {
            type: 'card-expiration-date',
            name: 'card_exp',
            placeholder: 'MM / YY',
            validations: ['required', 'validCardExpirationDate'],
            ...styles,
            css,
          });
          form.field('#address_zip', {
            type: 'zip-code',
            name: 'address_zip',
            placeholder: 'ZIP',
            validations: ['required', 'postal_code/us,ca'],
            ...styles,
            css,
          });

          setCreditCardClient(form);
        })
        .then(() => {
          onLoad();
        });
    });
  }, [setCreditCardClient, onLoad, mode]);

  // See CreditCardInput.css for styled on these inputs. We can't use styled-components like the
  // rest of our code, because the PayEngine library requires using a raw `div` component to set up
  // its iframe form fields

  return (
    <Container style={styleWeb}>
      {mode === 'SANDBOX' && (
        <WarningTextContainer>
          <WarningText>You're currently in sandbox mode.</WarningText>
        </WarningTextContainer>
      )}
      <Row>
        <div className='payengine-form-field' id='card_number' />
      </Row>
      <Row>
        <div className='payengine-form-field' id='card_exp' />
        <div className='payengine-form-field' id='card_cvc' />
        <div className='payengine-form-field' id='address_zip' />
      </Row>
    </Container>
  );
};

PayEngineCreditCardInput.defaultProps = {
  styleWeb: {},
  styleNative: {},
};

export default PayEngineCreditCardInput;
