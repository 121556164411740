// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, ScrollView, Icon, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery, useToast, useToggle} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SwitchField from '@shared/design/components/Field/SwitchField';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ConfirmationStepsForm from '@shared/modules/Proposal/forms/ConfirmationStepsForm';
import useToggleConfirmationStepsIsEnabledMutation from '@shared/modules/Proposal/hooks/useToggleConfirmationStepsIsEnabledMutation';
import useUpdateConfirmationStepsMutation from '@shared/modules/Proposal/hooks/useUpdateConfirmationStepsMutation';
import useUpdateConfirmationStepsStepsJsonMutation from '@shared/modules/Proposal/hooks/useUpdateConfirmationStepsStepsJsonMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import ConfirmationStepsList from 'modules/Organization/Settings/ProjectTypes/components/ConfirmationStepsList';
import CreateConfirmationStepModal from 'modules/Organization/Settings/ProjectTypes/components/CreateConfirmationStepModal';
import CreateQuoteStepModal from 'modules/Organization/Settings/ProjectTypes/components/CreateQuoteStepModal';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';
import QuoteAndConfirmationSettingsPanel from 'modules/Organization/Settings/ProjectTypes/components/QuoteAndConfirmationSettingsPanel';

const PageContainer = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  flex: 1;
  padding-left: 24px;
  background-color: ${colors.gray.background};
`;

const PageHeaderText = Styled.Text`
  ${Typography.PageHeading}
`;

const PageSubHeaderText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const PanelHeaderContainer = Styled.View`
  padding: 16px 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SectionBodyContainer = Styled.View`
  padding: 24px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SectionHeaderText = Styled.Text`
  ${Typography.Heading1}
`;

const PanelContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 4px;
  border: 1px solid ${colors.gray.border};
  flex-direction: column;
  max-width: 784px;
`;

const DisabledSectionText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const ConfirmationStepsSection = ({
  index,
  headerText,
  refetch,
  confirmationSteps,
  onAddStep,
  organization,
  disabledPlaceholderText,
}) => {
  const settingsPanelToggle = useToggle();
  const settingsUpdatedToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Settings updated!',
  });
  const hasConfirmationSteps = confirmationSteps.stepsJson !== '[]';

  const confirmationStepsForm = ConfirmationStepsForm.edit(confirmationSteps);
  const {form, submitting, handleSubmit} = useUpdateConfirmationStepsMutation({
    confirmationStepsForm,
    onSuccess: () => {
      settingsUpdatedToast.handleToast();
      settingsPanelToggle.handleToggleOff();
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const toggleConfirmationStepsIsEnabled = useToggleConfirmationStepsIsEnabledMutation({
    confirmationStepsForm,
    onSuccess: () => {},
    onError: (error) => {
      console.log(error);
    },
  });
  const updateConfirmationStepsStepsJson = useUpdateConfirmationStepsStepsJsonMutation({
    confirmationStepsForm,
    onSuccess: () => {},
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <React.Fragment>
      <Row>
        <SectionHeaderText>{headerText}</SectionHeaderText>
        <Space width={16} />
        <SwitchField
          index={0}
          form={toggleConfirmationStepsIsEnabled.form}
          field={'confirmationStepsForm.isEnabled'}
          labelRight={
            _.get(toggleConfirmationStepsIsEnabled.form.values, 'confirmationStepsForm.isEnabled')
              ? 'Enabled'
              : 'Disabled'
          }
          onChangeValue={() => {
            setTimeout(() => toggleConfirmationStepsIsEnabled.handleSubmit(), 0);
            settingsPanelToggle.handleToggleOff();
          }}
        />
      </Row>
      <Space height={24} />
      {confirmationSteps.isEnabled ? (
        <React.Fragment>
          <EditPanel
            index={index}
            bodyComponentProps={{confirmationSteps}}
            editBodyComponentProps={{
              organization,
              form,
              field: 'confirmationStepsForm',
              kind: _.toLower(headerText),
            }}
            BodyComponent={QuoteAndConfirmationSettingsPanel}
            EditBodyComponent={QuoteAndConfirmationSettingsPanel.Edit}
            title={'Settings'}
            handleSave={handleSubmit}
            isSubmitting={submitting}
            handleCancel={form.handleReset}
            isEditing={settingsPanelToggle.isOn}
            handleEdit={settingsPanelToggle.handleToggleOn}
            handleClose={settingsPanelToggle.handleToggleOff}
          />
          <Space height={24} />
          <PanelContainer>
            <PanelHeaderContainer>
              <EditPanel.HeaderText>Steps</EditPanel.HeaderText>
            </PanelHeaderContainer>
            <SectionBodyContainer>
              <ConfirmationStepsList
                form={updateConfirmationStepsStepsJson.form}
                handleSubmit={updateConfirmationStepsStepsJson.handleSubmit}
                confirmationSteps={confirmationSteps}
                refetch={refetch}
              />
              {hasConfirmationSteps && <Space height={16} />}
              <TertiaryButton
                textColor={colors.blue.interactive}
                text={'Add Step'}
                iconLeft={Icon.Plus}
                onPress={onAddStep}
              />
            </SectionBodyContainer>
          </PanelContainer>
        </React.Fragment>
      ) : (
        <DisabledSectionText>{disabledPlaceholderText}</DisabledSectionText>
      )}
    </React.Fragment>
  );
};

const ProjectTypeQuotesAndConfirmationsSettingsPageContent = ({projectType, refetch}) => {
  const createConfirmationStepModal = useModal({name: 'Create Confirmation Step Modal'});
  const createQuoteStepModal = useModal({name: 'Create Quote Step Modal'});

  return (
    <PageContainer>
      <ProjectTypeSettingsPageHeader projectType={projectType} />
      <PageContentContainer>
        <ScrollView>
          <Space height={24} />
          <PageHeaderText>Quotes & Confirmations</PageHeaderText>
          <Space height={12} />
          <PageSubHeaderText>
            Configure the default quote and confirmation steps for this project type.
          </PageSubHeaderText>
          <Space height={24} />
          <ConfirmationStepsSection
            key={projectType.quoteConfirmationSteps.stepsJson.length}
            headerText={'Quotes'}
            index={0}
            refetch={refetch}
            confirmationSteps={projectType.quoteConfirmationSteps}
            onAddStep={createQuoteStepModal.handleOpen}
            organization={projectType.organization}
            disabledPlaceholderText={
              'Once enabled, you will be able to configure the settings and steps for quotes.'
            }
          />
          <Space height={32} />
          <ConfirmationStepsSection
            key={projectType.confirmationConfirmationSteps.stepsJson.length}
            headerText={'Confirmations'}
            index={1}
            refetch={refetch}
            confirmationSteps={projectType.confirmationConfirmationSteps}
            onAddStep={createConfirmationStepModal.handleOpen}
            organization={projectType.organization}
            disabledPlaceholderText={
              'Once enabled, you will be able to configure the settings and steps for confirmations.'
            }
          />
          <Space height={80} />
        </ScrollView>
      </PageContentContainer>
      <CreateConfirmationStepModal
        key={createConfirmationStepModal.key}
        isOpen={createConfirmationStepModal.isOpen}
        handleClose={createConfirmationStepModal.handleClose}
        projectType={projectType}
        confirmationStepsId={projectType.confirmationConfirmationSteps.id}
        refetch={refetch}
      />
      <CreateQuoteStepModal
        key={createQuoteStepModal.key}
        isOpen={createQuoteStepModal.isOpen}
        handleClose={createQuoteStepModal.handleClose}
        projectType={projectType}
        confirmationStepsId={projectType.quoteConfirmationSteps.id}
        refetch={refetch}
      />
    </PageContainer>
  );
};

const ProjectTypeQuotesAndConfirmationsSettingsPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(ProjectTypeQuotesAndConfirmationsSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeUuid: params.projectTypeUuid,
    },
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading as={PageLoadingIndicator} loading={loading}>
        {() => (
          <ProjectTypeQuotesAndConfirmationsSettingsPageContent
            projectType={data.projectType}
            refetch={refetch}
          />
        )}
      </Loading>
    </SidebarPageV2>
  );
};

ProjectTypeQuotesAndConfirmationsSettingsPage.query = gql`
  ${ProjectTypeSettingsPageHeader.fragment}
  ${CreateConfirmationStepModal.fragment}
  ${ConfirmationStepsList.fragment}
  ${ConfirmationStepsForm.edit.fragment}
  ${QuoteAndConfirmationSettingsPanel.Edit.fragment}
  ${QuoteAndConfirmationSettingsPanel.fragment}

  query ProjectTypeQuotesAndConfirmationsSettingsPage(
    $projectTypeUuid: String!,
  ) {
    ${gql.query}
    projectType: projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      name
      organization {
        id
        ...QuoteAndConfirmationSettingsPanel_Edit
      }
      quoteConfirmationSteps: confirmationStepsByKind(kind: "QUOTE"){
        id
        isEnabled
        ...ConfirmationStepsForm_edit
        ...ConfirmationStepsList
        ...QuoteAndConfirmationSettingsPanel
      }
      confirmationConfirmationSteps: confirmationStepsByKind(kind: "CONFIRMATION"){
        id
        isEnabled
        ...ConfirmationStepsForm_edit
        ...ConfirmationStepsList
        ...QuoteAndConfirmationSettingsPanel
      }
      ...ProjectTypeSettingsPageHeader
      ...CreateConfirmationStepModal
    }
  }
`;

export default ProjectTypeQuotesAndConfirmationsSettingsPage;
