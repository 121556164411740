// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {PaymentMethodModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TotalContainer = Styled.View`
  padding-horizontal: 24px;
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const SubtotalText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const FeeText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const TotalText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const PaymentTotals = ({
  subtotal,
  paymentMethod,
  paymentFeeAmount,
  total,
}: {
  subtotal: string;
  paymentFeeAmount: string;
  total: string;
  paymentMethod: PaymentMethodModel;
}) => {
  const responsive = useResponsive();
  return (
    <TotalContainer>
      <Space height={12} />
      {paymentMethod.feePercentage && (
        <React.Fragment>
          <Row>
            <SubtotalText responsive={responsive}>Subtotal</SubtotalText>
            <Space flex={1} />
            <SubtotalText responsive={responsive}>{subtotal || Currency.display(0)}</SubtotalText>
          </Row>
          <Space height={4} />
          <Row>
            <FeeText responsive={responsive}>{paymentMethod.displayName}</FeeText>
            <Space flex={1} />
            <FeeText responsive={responsive}>{paymentFeeAmount || Currency.display(0)}</FeeText>
          </Row>
          <Space height={4} />
        </React.Fragment>
      )}
      <Row>
        <TotalText responsive={responsive}>Payment Total</TotalText>
        <Space flex={1} />
        <TotalText responsive={responsive}>{total || Currency.display(0)}</TotalText>
      </Row>
      <Space height={12} />
    </TotalContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PaymentTotals.fragment = gql`
  fragment PaymentTotals on PaymentMethod {
    id
    displayName
    feePercentage
  }
`;

export default PaymentTotals;
