import {Token, Tokenizer} from './tokenizer';

export interface LexerOutput {
  tokens: Token[];
}

export const makeLexer =
  (tokenizers: Tokenizer[]) =>
  (stringToTokenize: string): LexerOutput => {
    let currentPosition = 0;
    const tokens = [];
    while (currentPosition < stringToTokenize.length) {
      let foundMatchingTokenizer = false;
      for (const tokenizer of tokenizers) {
        const {charactersUsed, token} = tokenizer({stringToTokenize, currentPosition});
        if (charactersUsed) {
          foundMatchingTokenizer = true;
          currentPosition += charactersUsed;
          if (token) {
            tokens.push(token);
          }
          break;
        }
      }
      if (!foundMatchingTokenizer) {
        throw new Error(
          `Could not find a tokenizer for character: ${stringToTokenize[currentPosition]}`,
        );
      }
    }
    return {tokens};
  };
