// Libraries
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

import CrewForm from '@shared/modules/Dispatch/forms/CrewForm';
import SetCrewSlotsToCrewForm from '@shared/modules/Dispatch/forms/SetCrewSlotsToCrewForm';

const edit = withFragment(
  (job, {shouldRunUpsertCostsAndBillingEngineAsync}) => {
    // If there's already a primary crew assigned, then we modify that crew.
    if ((job as any).primaryCrew) {
      return {
        crewForm: CrewForm.edit((job as any).primaryCrew),
        // TODO(peter): Change canCreateNewSlot to false and implement the over capacity modal
        setCrewSlotsToCrewForm: SetCrewSlotsToCrewForm.edit((job as any).primaryCrew, {
          canCreateNewSlot: true,
          shouldRunUpsertCostsAndBillingEngineAsync:
            shouldRunUpsertCostsAndBillingEngineAsync || false,
        }),
      };
    }

    // Otherwise, we create the first crew object and start off with the numbers from the job.
    return {
      crewForm: CrewForm.new({
        jobId: (job as any).id,
        isPrimary: true,
        numberOfRequiredMovers: (job as any).numberOfMovers,
        numberOfRequiredTrucks: (job as any).numberOfTrucks,
      }),
      setCrewSlotsToCrewForm: SetCrewSlotsToCrewForm.new(shouldRunUpsertCostsAndBillingEngineAsync),
    };
  },
  gql`
    ${CrewForm.edit.fragment}
    ${SetCrewSlotsToCrewForm.edit.fragment}

    fragment CreateCrewWithSlotsAndTrucksForm_edit on Job {
      id
      numberOfMovers
      numberOfTrucks
      primaryCrew {
        id
        ...CrewForm_edit
        ...SetCrewSlotsToCrewForm_edit
      }
    }
  `,
);

const toForm = ({crewForm, setCrewSlotsToCrewForm}: any) => ({
  crewForm: CrewForm.toForm(crewForm),
  setCrewSlotsToCrewForm: SetCrewSlotsToCrewForm.toForm(setCrewSlotsToCrewForm),
});

const toMutation = ({crewForm, setCrewSlotsToCrewForm}: any) => ({
  crewForm: CrewForm.toMutation(crewForm),
  setCrewSlotsToCrewForm: SetCrewSlotsToCrewForm.toMutation(setCrewSlotsToCrewForm),
});

const CreateCrewWithSlotsAndTrucksForm = {
  edit,
  toForm,
  toMutation,
};

export default CreateCrewWithSlotsAndTrucksForm;
