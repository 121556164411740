import Expression, {
  GroupingExpression,
  IdentifierExpression,
  NumberLiteralExpression,
  SymbolExpression,
  CallExpression,
  StringLiteralExpression,
  OperatorExpression,
} from '@shared/ast/Expression';
import {Parser} from '@shared/ast/parser';

export class FormulaGrammarParser extends Parser {
  parse = (): Expression => {
    const result = this.boolean();
    // This prevents users from inputting more than a single expression as a formula
    if (this.tokens.length !== this.currentTokenIndex) {
      throw new Error(`Unexpected token: "${this.tokens[this.currentTokenIndex].value}"`);
    }
    return result;
  };

  expression = (): Expression => {
    const result = this.boolean();

    return result;
  };

  boolean = (): Expression => {
    let expression = this.equality();
    while (this.match('Or', 'And')) {
      const operator = this.previous();
      const argument = this.equality();
      expression = new OperatorExpression(operator, [expression, argument]);
    }
    return expression;
  };

  equality = (): Expression => {
    let expression = this.comparison();
    while (this.match('BangEqual', 'DoubleEqual')) {
      const operator = this.previous();
      const argument = this.comparison();
      expression = new OperatorExpression(operator, [expression, argument]);
    }
    return expression;
  };

  comparison = (): Expression => {
    let expression = this.term();
    while (this.match('GreaterThan', 'GreaterEqual', 'LessThan', 'LessEqual')) {
      const operator = this.previous();
      const argument = this.term();
      expression = new OperatorExpression(operator, [expression, argument]);
    }
    return expression;
  };

  term = (): Expression => {
    let expression = this.factor();
    while (this.match('Dash', 'Plus')) {
      const operator = this.previous();
      const argument = this.factor();
      expression = new OperatorExpression(operator, [expression, argument]);
    }
    return expression;
  };

  factor = (): Expression => {
    let expression = this.unary();
    while (this.match('Slash', 'Asterisk')) {
      const operator = this.previous();
      const argument = this.unary();
      expression = new OperatorExpression(operator, [expression, argument]);
    }
    return expression;
  };

  unary = (): Expression => {
    if (this.match('Bang', 'Dash', 'Not')) {
      const operator = this.previous();
      const argument = this.unary();
      return new OperatorExpression(operator, [argument]);
    }
    return this.call();
  };

  call = (): Expression => {
    let expression = this.primary();
    /* eslint-disable no-constant-condition */
    while (true) {
      if (this.match('OpenParenthesis')) {
        expression = this.finishCall(expression as IdentifierExpression);
      } else {
        break;
      }
    }
    return expression;
  };

  primary = (): Expression => {
    if (this.match('False')) {
      return new SymbolExpression(false);
    }
    if (this.match('True')) {
      return new SymbolExpression(true);
    }
    if (this.match('TBD')) {
      return new SymbolExpression(null);
    }
    if (this.match('Number')) {
      return new NumberLiteralExpression(Number(this.previous().value));
    }
    if (this.match('String')) {
      return new StringLiteralExpression(this.previous().value);
    }
    if (this.match('Identifier')) {
      return new IdentifierExpression(this.previous().value);
    }
    if (this.match('OpenParenthesis')) {
      const expression = this.expression();
      this.consume('ClosedParenthesis', 'Found unmatched parenthesis.');
      return new GroupingExpression(expression);
    }
    if (this.peek()) {
      throw new Error(`Unmatched: ${JSON.stringify(this.peek())}`);
    } else {
      throw new Error(`Invalid formula.`);
    }
  };

  finishCall = (callee: IdentifierExpression): CallExpression => {
    const args = [];
    if (!this.check('ClosedParenthesis')) {
      /* eslint-disable no-constant-condition */
      while (true) {
        args.push(this.expression());
        if (!this.match('Comma')) {
          break;
        }
      }
    }
    this.consume('ClosedParenthesis', 'Missing parenthesis after arguments.');
    return new CallExpression(callee.value, args);
  };
}
