/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, LocationInput, PhoneInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Organization, ReferralSource} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';
import AccountingCustomerField from 'modules/Organization/Settings/BillingLibraries/components/AccountingCustomerField';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
`;

const Section = Styled.View`
  z-index: ${({index}) => 100 - index};
  padding: 20px;
  border-width: 1px;
  border-radius: 8px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const SectionTitle = Styled.Text`
  ${Typography.Label2}
`;

const ActionText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const CheckboxContainer = Styled.View`
  height: 16px;
  width: 16px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 4px;
  border-width: 2px;
  border-color: ${colors.blue.interactive};
  background-color: ${({isChecked}) => (isChecked ? colors.blue.interactive : colors.white)}
`;

const FieldsGroup = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex-direction: ${({mobile}) => (mobile ? 'column' : 'row')};
  align-items: flex-start;
  width: 100%;
`;

const FieldSpace = () => {
  const responsive = useResponsive();
  return <Space width={responsive.mobile ? 0 : 16} height={responsive.mobile ? 12 : 0} />;
};

const RowSpace = () => {
  return <Space height={12} />;
};

const ClientContactInformation = ({form, field, organization, emailAddressRequired}) => {
  const responsive = useResponsive();
  const isLastNameRequired = organization.features.isEnabledProjectCustomerLastNameRequired;
  const isPhoneNumberRequired = organization.features.isEnabledProjectCustomerPhoneNumberRequired;
  const isShowingName = _.get(form.values, 'clientForm.isShowingName');
  const handleToggleAddName = () => {
    form.setFieldValue('clientForm.name', '');
    form.setFieldValue('clientForm.isShowingName', !isShowingName);
  };
  return (
    <Section index={1}>
      <Row>
        <SectionTitle>Contact Information</SectionTitle>
        <Space style={{flex: 1}} />
        <Button onPress={handleToggleAddName}>
          {isShowingName ? (
            <Row>
              <Icon source={Icon.Trash} size={14} color={colors.blue.interactive} />
              <Space width={8} />
              <ActionText>{`${responsive.mobile ? '' : 'Remove '}Company Name`}</ActionText>
            </Row>
          ) : (
            <Row>
              <Icon source={Icon.Plus} size={14} color={colors.blue.interactive} />
              <Space width={8} />
              <ActionText>{`${responsive.mobile ? '' : 'Add '}Company Name`}</ActionText>
            </Row>
          )}
        </Button>
      </Row>
      <Space height={16} />
      {isShowingName && (
        <React.Fragment>
          <FieldInput.Memoized
            {...form}
            name={`clientForm.name`}
            label={'Company Name'}
            input={{
              required: !_.get(form.values, 'clientForm.name'),
              placeholder: 'Enter company, business name, etc.',
            }}
          />
          <RowSpace />
        </React.Fragment>
      )}
      <FieldsGroup {...responsive} index={1}>
        <FieldInput.Memoized
          {...form}
          name={`${field}.names.0`}
          label={'First Name'}
          input={{
            required: !_.get(form.values, `${field}.names.0`),
            placeholder: 'Enter first name',
          }}
          style={{flex: 1, width: '100%'}}
        />
        <FieldSpace {...responsive} />
        <FieldInput.Memoized
          {...form}
          name={`${field}.names.1`}
          label={'Last Name'}
          input={{
            required: isLastNameRequired && !_.get(form.values, `${field}.names.1`),
            placeholder: 'Enter last name',
          }}
          style={{flex: 1, width: '100%'}}
        />
      </FieldsGroup>
      <RowSpace />
      <FieldsGroup {...responsive} index={2}>
        <FieldInput.Memoized
          {...form}
          component={PhoneInput}
          name={`${field}.phoneNumber`}
          label={'Phone Number'}
          input={{
            required: isPhoneNumberRequired && !_.get(form.values, `${field}.phoneNumber`),
            placeholder: 'Enter phone number',
            setFieldValue: form.setFieldValue,
          }}
          style={{flex: 1, width: '100%'}}
        />
        <FieldSpace {...responsive} />
        <FieldInput.Memoized
          {...form}
          name={`${field}.email`}
          label={'Email Address'}
          input={{
            required: emailAddressRequired,
            placeholder: 'Enter email address',
          }}
          style={{flex: 1, width: '100%'}}
        />
      </FieldsGroup>
    </Section>
  );
};

const LocationFields = ({form, field}) => {
  const responsive = useResponsive();
  const handleOnLocation = ({address, city, zipCode, latitude, longitude, state, country}) => {
    const locationForm = _.get(form.values, field);
    form.setFieldValue(field, {
      ...locationForm,
      address,
      city,
      zipCode,
      latitude,
      longitude,
      state,
      country,
    });
  };
  return (
    <FieldsGroup {...responsive} index={1}>
      <FieldInput.Memoized
        {...form}
        index={1}
        component={LocationInput}
        name={`${field}.address`}
        label={'Address'}
        input={{
          placeholder: 'Enter address',
          setFieldValue: form.setFieldValue,
          setFieldError: form.setFieldError,
          setFieldTouched: form.setFieldTouched,
          onLocation: handleOnLocation,
        }}
        style={{width: '100%', ...(responsive.mobile ? {} : {flex: 2})}}
      />
      <FieldSpace />
      <FieldInput.Memoized
        {...form}
        index={2}
        name={`${field}.zipCode`}
        label={'Zip Code'}
        input={{
          disabled: true,
          placeholder: '--',
        }}
        style={{width: '100%', ...(responsive.mobile ? {} : {flex: 1})}}
      />
    </FieldsGroup>
  );
};

const ClientPrimaryLocation = ({form, field}) => {
  return (
    <Section index={2}>
      <SectionTitle>Client Address</SectionTitle>
      <Space height={16} />
      <LocationFields form={form} field={field} />
    </Section>
  );
};

const ClientBillingLocation = ({form, field, clientId}) => {
  const isSameAddressField = 'clientForm.isSameAddress';
  const isSameAddress = _.get(form.values, isSameAddressField);
  const handleToggleIsSameAddress = () => {
    form.setFieldValue(field, LocationForm.new({clientId}));
    form.setFieldValue(isSameAddressField, !isSameAddress);
  };

  return (
    <Section index={3}>
      <Row>
        <SectionTitle>Billing Address</SectionTitle>
        <Space style={{flex: 1}} />
        <Button>
          <Button onPress={handleToggleIsSameAddress}>
            <Row>
              <CheckboxContainer isChecked={isSameAddress}>
                <Icon source={Icon.Check} size={11} color={colors.white} />
              </CheckboxContainer>
              <Space width={8} />
              <ActionText>Same as Client Address</ActionText>
            </Row>
          </Button>
        </Button>
      </Row>
      {!isSameAddress && (
        <React.Fragment>
          <Space height={16} />
          <LocationFields form={form} field={field} />
        </React.Fragment>
      )}
    </Section>
  );
};

const AccountingInformation = ({
  form,
  field,
  organization,
  latestExternalIntegrationCustomerId,
}) => {
  const currentExternalIntegrationCustomerId = _.get(
    form.values,
    `${field}.externalIntegrationCustomerId`,
  );

  return (
    <Section index={2}>
      <SectionTitle>Accounting Information</SectionTitle>
      <Space height={8} />
      {_.toNumber(latestExternalIntegrationCustomerId) !== currentExternalIntegrationCustomerId && (
        <WarningCallout
          text={
            'Changing the accounting customer linked to this client will export new invoices to the updated accounting customer.'
          }
        />
      )}
      <Space height={8} />
      <AccountingCustomerField
        form={form}
        field={field}
        fieldIndex={2}
        organization={organization}
      />
    </Section>
  );
};

const InternalInformation = ({form, field, organization}) => {
  const responsive = useResponsive();
  const {referralSources} = organization.settings;
  return (
    <Section index={4}>
      <SectionTitle>Internal Information</SectionTitle>
      <Space height={16} />
      <FieldsGroup {...responsive} index={1}>
        <FieldInput.Memoized
          {...form}
          index={1}
          component={DropdownInput}
          name={`${field}.salespersonId`}
          label={'Salesperson'}
          input={{
            options: Organization.getSalespersonOptions(organization),
            placeholder: 'Select salesperson',
            isClearable: true,
            setFieldValue: form.setFieldValue,
            style: {flex: 1},
          }}
          style={{width: '100%', ...(responsive.mobile ? {} : {flex: 1})}}
        />
        <FieldSpace />
        <FieldInput.Memoized
          {...form}
          index={2}
          component={DropdownInput}
          name={`${field}.coordinatorId`}
          label={'Coordinator'}
          input={{
            options: Organization.getCoordinatorOptions(organization),
            placeholder: 'Select coordinator',
            setFieldValue: form.setFieldValue,
            isClearable: true,
            style: {flex: 1},
          }}
          style={{width: '100%', ...(responsive.mobile ? {} : {flex: 1})}}
        />
        <FieldSpace />
        <Space style={{flex: 1}} />
      </FieldsGroup>
      <RowSpace />
      <FieldsGroup {...responsive} index={2}>
        <FieldInput.Memoized
          {...form}
          index={3}
          component={DropdownInput}
          name={`${field}.referralSource`}
          label={'How did you hear about us?'}
          input={{
            options: ReferralSource.getDropdownOptions({referralSources}),
            setFieldValue: (name, value) => {
              const previousValue = _.get(form.values, name);

              // First update the referralSource.
              form.setFieldValue(name, value);

              // When the referralSource is updated, we clear the referralDetails.
              if (previousValue !== value) {
                form.setFieldValue(`${field}.referralDetails`, '');
              }
            },
            style: {flex: 1},
          }}
          style={{width: '100%', ...(responsive.mobile ? {} : {flex: 1})}}
        />
        <FieldSpace />
        <FieldInput.Memoized
          {...form}
          index={4}
          name={`${field}.referralDetails`}
          label={responsive.mobile ? null : ''}
          input={{
            placeholder: 'Please specify',
          }}
          style={{width: '100%', ...(responsive.mobile ? {} : {flex: 1})}}
        />
        <FieldSpace />
        <Space style={{flex: 1}} />
      </FieldsGroup>
    </Section>
  );
};

const ClientNotes = ({form, field}) => {
  return (
    <Section index={5}>
      <SectionTitle>Client Notes (Internal Only)</SectionTitle>
      <Space height={16} />
      <ResponsiveTextInput.Memoized
        form={form}
        field={`${field}.notes`}
        minHeight={56}
        input={{
          style: {
            paddingLeft: 12,
            paddingRight: 12,
          },
          placeholder: 'Enter any additional notes or other contacts',
        }}
      />
    </Section>
  );
};

const EditClientModalFields = ({
  client,
  organization,
  form,
  field,
  emailAddressRequired,
  latestExternalIntegrationCustomerId,
}) => {
  return (
    <React.Fragment>
      <ClientContactInformation
        form={form}
        field={`${field}.primaryContactForm`}
        organization={organization}
        emailAddressRequired={emailAddressRequired}
      />
      <Space height={12} />
      <ClientPrimaryLocation form={form} field={`${field}.primaryLocationForm`} />
      <Space height={12} />
      <ClientBillingLocation
        form={form}
        field={`${field}.billingLocationForm`}
        clientId={client ? client.id : null}
      />
      <Space height={12} />
      {organization.activeCodatIntegration && (
        <React.Fragment>
          <AccountingInformation
            form={form}
            field={field}
            organization={organization}
            latestExternalIntegrationCustomerId={latestExternalIntegrationCustomerId}
          />
          <Space height={12} />
        </React.Fragment>
      )}
      <InternalInformation form={form} field={`${field}`} organization={organization} />
      <Space height={12} />
      <ClientNotes form={form} field={`${field}`} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditClientModalFields.fragment = gql`
  ${Organization.getCoordinatorOptions.fragment}
  ${Organization.getSalespersonOptions.fragment}
  ${ReferralSource.getDropdownOptions.fragment}

  fragment EditClientModalFields on Organization {
    id
    settings {
      id
      referralSources {
        ...ReferralSource_getDropdownOptions
      }
    }
    activeCodatIntegration {
      id
    }
    features {
      isEnabledProjectCustomerLastNameRequired: isEnabled(
        feature: "PROJECT_CUSTOMER_LAST_NAME_REQUIRED"
      )
      isEnabledProjectCustomerPhoneNumberRequired: isEnabled(
        feature: "PROJECT_CUSTOMER_PHONE_NUMBER_REQUIRED"
      )
    }
    ...Organization_getCoordinatorOptions
    ...Organization_getSalespersonOptions
  }
`;

export default EditClientModalFields;
