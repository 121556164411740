// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon, FlatList} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import TripJobCard from 'modules/Dispatch/LongDistance/Trip/components/TripJobCard';

const PastJobsButtonContainer = Styled.View`
  align-items: center;
`;

const JobsListItemContainer = Styled.View`
  padding-horizontal: 16px;
`;

const ListEmptyContainer = Styled.View`
  justify-content: center;
  align-items: center;
`;

const ListEmptyText = Styled.Text`
  ${Typography.Label1};
  color: ${colors.gray.tertiary};
`;

const getGroupedJobs = ({jobs}) => {
  const currentDate = Datetime.toDate(Datetime.today);
  const sortedJobs = _.sortBy(jobs, ['scheduledDate', 'startTime1', 'project.client.name']);
  const groupedJobs = _.groupBy(sortedJobs, (job) => {
    if (!job.scheduledDate) {
      return 'unscheduledJobs';
    }
    if (job.scheduledDate < currentDate) {
      return 'pastJobs';
    }
    return 'upcomingJobs';
  });
  return {
    unscheduledJobs: [],
    pastJobs: [],
    upcomingJobs: [],
    ...groupedJobs,
  };
};

const JobsListHeader = ({isViewPastJobs, setIsViewPastJobs, pastJobs}) => {
  if (_.isEmpty(pastJobs)) {
    return <Space height={16} />;
  }

  return (
    <React.Fragment>
      {isViewPastJobs &&
        pastJobs.map((job, index) => {
          return (
            <React.Fragment key={job.id}>
              <Space height={index === 0 ? 16 : 8} />
              <JobsListItem job={job} isPast />
            </React.Fragment>
          );
        })}
      <Space height={isViewPastJobs ? 12 : 20} />
      <PastJobsButtonContainer>
        <TertiaryButton
          onPress={() => setIsViewPastJobs(!isViewPastJobs)}
          iconRight={isViewPastJobs ? Icon.ChevronDown : Icon.ChevronUp}
          text={`${isViewPastJobs ? 'Hide' : 'View'} Past Jobs`}
        />
      </PastJobsButtonContainer>
      <Space height={12} />
    </React.Fragment>
  );
};

const JobsListItem = ({job, isPast}) => {
  return (
    <JobsListItemContainer>
      <TripJobCard job={job} isPast={isPast} />
    </JobsListItemContainer>
  );
};

const ListEmptyComponent = () => {
  return (
    <ListEmptyContainer>
      <Space height={16} />
      <ListEmptyText>{'There are no upcoming jobs for this trip'}</ListEmptyText>
    </ListEmptyContainer>
  );
};

const TripTicketJobsList = ({jobs, style}) => {
  const [isViewPastJobs, setIsViewPastJobs] = useState(false);
  const {unscheduledJobs, pastJobs, upcomingJobs} = getGroupedJobs({jobs});

  return (
    <FlatList
      data={[...unscheduledJobs, ...upcomingJobs]}
      keyExtractor={(job) => _.toString(job.id)}
      renderItem={({item: job}) => <JobsListItem key={job.id} job={job} />}
      ListHeaderComponent={() => (
        <JobsListHeader
          isViewPastJobs={isViewPastJobs}
          setIsViewPastJobs={setIsViewPastJobs}
          pastJobs={pastJobs}
        />
      )}
      ItemSeparatorComponent={() => <Space height={8} />}
      ListFooterComponent={() => <Space height={16} />}
      ListEmptyComponent={ListEmptyComponent}
      style={style}
    />
  );
};

TripTicketJobsList.fragment = gql`
  ${TripJobCard.fragment}
  fragment TripTicketJobsList on Job {
    id
    scheduledDate
    startTime1
    project {
      id
      client {
        id
        name
      }
    }
    ...TripJobCard
  }
`;

export default TripTicketJobsList;
