// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Modal, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useQuery, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import useUpdateBillMutation from '@shared/modules/Billing/hooks/useUpdateBillMutation';
import EditBillBillRulesList from 'modules/Project/Billing/components/EditBillBillRulesList';

const Touchable = Styled.Touchable`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SplitRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: ${(props) => (props.isMobile ? 'flex-start' : 'center')};
  justify-content: center;
  background-color: ${(props) => (props.isMobile ? colors.white : null)};
`;

const ModalContainer = Styled.View`
  width: 800px;
  background-color: ${colors.white};
  border-radius: ${(props) => (props.isMobile ? '0px' : '16px')};
  flex: 1;
`;

const HeaderContainer = Styled.View`
  padding-horizontal: 42px;
`;

const TitleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const TitleCaptionText = Styled.H6`
  color: ${colors.gray.tertiary};
  ${fontWeight(500)}
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 42px;
  padding-right: 20px;
  padding-vertical: 16px;
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const ErrorsSection = Styled.View`
  justify-content: center;
  flex: 1;
  margin-right: 16px;
  align-self: center;
`;

const ErrorsContainer = Styled.View`
  justify-content: center;
  background-color: ${colors.alpha(colors.red.warning, 0.1)};
  padding: 8px;
  border-radius: 2px;
`;

const ErrorText = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.red.warning};
`;

const BillRulesModalHeader = ({bill, handleClose}) => {
  return (
    <HeaderContainer>
      <SplitRow>
        <TitleText>Edit Billing Rules</TitleText>
        <Touchable activeOpacity={0.8} onPress={handleClose}>
          <Icon source={Icon.Times} size={24} color={colors.gray.primary} />
        </Touchable>
      </SplitRow>
      <Space height={2} />
      <TitleCaptionText>{bill.title}</TitleCaptionText>
    </HeaderContainer>
  );
};

const ErrorMessage = ({message}) => {
  return (
    <Row>
      <Icon source={Icon.Times} color={colors.red.warning} size={12} />
      <Space width={4} />
      <ErrorText>{message}</ErrorText>
    </Row>
  );
};

const BillRulesModalFooter = ({form, handleClose, handleSubmit, submitting}) => {
  const billRuleErrors = _.reduce(
    _.get(form.errors, 'billForm.billRuleForms'),
    (all, errors) => ({...all, ...errors}),
    {},
  );
  return (
    <FooterContainer>
      <ErrorsSection>
        {!_.isEmpty(billRuleErrors) && (
          <ErrorsContainer>
            {_.values(billRuleErrors).map((error, index) => {
              return <ErrorMessage key={index} message={error} />;
            })}
          </ErrorsContainer>
        )}
      </ErrorsSection>
      <Row>
        <Button
          text={'Cancel'}
          color={colors.gray.border}
          onPress={handleClose}
          isDisabled={submitting}
          width={100}
        />
        <Space width={16} />
        <Button text={'Confirm'} isSubmitting={submitting} onPress={handleSubmit} width={100} />
      </Row>
    </FooterContainer>
  );
};

const EditBillBillRulesModalContent = ({bill, handleClose, refetch}) => {
  const billForm = BillForm.edit(bill);
  const addBillBillRulePopover = usePopover();
  const {form, submitting, handleSubmit} = useUpdateBillMutation({
    billForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error) => {
      console.log({error});
    },
  });

  const responsive = useResponsive();

  return (
    <ScreenContainer pointerEvents={'box-none'} isMobile={responsive.mobile}>
      <ScrollView
        horizontal
        style={{flex: 1, width: responsive.mobile ? '100%' : 800}}
        contentContainerStyle={{alignSelf: 'center', height: responsive.mobile ? '100%' : 648}}
      >
        <ModalContainer isMobile={responsive.mobile}>
          <Space height={32} />
          <BillRulesModalHeader bill={bill} handleClose={handleClose} />
          <Space height={20} />
          <EditBillBillRulesList
            bill={bill}
            form={form}
            addBillBillRulePopover={addBillBillRulePopover}
          />
          <BillRulesModalFooter
            form={form}
            handleClose={handleClose}
            handleSubmit={() => setTimeout(handleSubmit, 0)}
            submitting={submitting}
          />
        </ModalContainer>
      </ScrollView>
    </ScreenContainer>
  );
};

const EditBillBillRulesModal = ({billUuid, isOpen, handleClose, refetch}) => {
  const {loading, data} = useQuery(EditBillBillRulesModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      uuid: billUuid,
    },
  });

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <Loading loading={loading}>
        {() =>
          data ? (
            <EditBillBillRulesModalContent
              bill={data.bill}
              handleClose={handleClose}
              refetch={refetch}
            />
          ) : null
        }
      </Loading>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillBillRulesModal.query = gql`
  ${BillForm.edit.fragment}
  ${EditBillBillRulesList.fragment}

  query EditBillBillRulesModal($uuid: String!) {
    bill(uuid: $uuid) {
      id
      title
      ...BillForm_edit
      ...EditBillBillRulesListV2
    }
  }
`;

export default EditBillBillRulesModal;
