// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, useResponsive, useState} from '@supermove/hooks';
import {Cost, CostCategory} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime, Percent} from '@supermove/utils';

// App
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';
import UserRole from '@shared/modules/User/enums/UserRole';
import Line from 'modules/App/components/Line';
import EditCostCostItemsModalV2 from 'modules/Cost/components/EditCostCostItemsModalV2';

const Button = Styled.ButtonV2`
`;

const TableContainer = Styled.View`
border-width: 1px;
border-color: ${colors.gray.border};
border-radius: 4px;
background-color: ${colors.white}
`;

const TableRow = Styled.View`
  flex-direction: row;
  padding-left: ${({mobile}) => (mobile ? 8 : 16)}px;
  padding-right: ${({mobile}) => (mobile ? 0 : 24)}px;
  padding-vertical: 8px;
`;

const Cell = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: ${({justify}) => justify};
  padding-horizontal: 8px;
`;

const CellText = Styled.Text`
  ${Typography.Body}
  color: ${({color}) => color || colors.gray.primary}
`;

const CompensationCostsTableContainer = Styled.View`
  padding-vertical: 4px;
  background-color: ${colors.gray.background};
`;

const LabelText = Styled.Text`
  ${Typography.Label}
`;

const StatusBadge = Styled.View`
  padding: 4px 8px;
  border-radius: 4px;
`;

const StatusBadgeText = Styled.Text`
  ${Typography.MicroLabel}
`;

const getCostsByDate = ({costs}) => {
  const compensationRoles = _.map(UserRole.getRolesForCompensation(), (role) => {
    if (role.toLowerCase() === UserRole.EMPLOYEE) {
      // UserRole.getRolesForCompensation() returns 'employee' instead of 'mover'
      return 'mover';
    } else {
      return _.toLower(role);
    }
  });
  // Sort by date, by role, then by employee name in alphabetical order
  return _.orderBy(
    costs,
    [
      (cost) => new Date(cost.date),
      (cost) => (cost.jobId ? 0 : 1), // Job goes first
      (cost) => compensationRoles.indexOf(_.toLower(Cost.getRole(cost))),
      (cost) => Cost.getDisplayIdentifier(cost), // Cost's project/job identifier in alphabetical order
      (cost) => cost.displayName,
    ],
    ['asc', 'asc', 'desc', 'asc', 'asc'],
  );
};

const PROJECT_COSTS_TABLE_COLUMNS = [
  {
    flex: 2,
    justify: 'flex-start',
    headerContent: () => {
      return null;
    },
    cellContent: ({costCategory}) => {
      return (
        costCategory.kind === CostCategoryKind.OTHER && (
          <LabelText>{CostCategory.getDisplayKind(costCategory)}</LabelText>
        )
      );
    },
    footerContent: () => {
      return null;
    },
  },
  {
    flex: 3,
    justify: 'flex-start',
    headerContent: () => {
      return <LabelText>Date</LabelText>;
    },
    cellContent: () => {
      return null;
    },
    footerContent: () => {
      return null;
    },
  },
  {
    flex: 3,
    justify: 'flex-start',
    headerContent: () => {
      return <LabelText>Project or Job</LabelText>;
    },
    cellContent: () => {
      return null;
    },
    footerContent: () => {
      return null;
    },
  },
  {
    flex: 3,
    justify: 'flex-start',
    headerContent: () => {
      return <LabelText>Employee</LabelText>;
    },
    cellContent: () => {
      return null;
    },
    footerContent: () => {
      return null;
    },
  },
  {
    flex: 2,
    justify: 'flex-start',
    headerContent: () => {
      return <LabelText>Role</LabelText>;
    },
    cellContent: ({costCategory}) => {
      return (
        <LabelText>
          {costCategory.kind === CostCategoryKind.COMPENSATION && 'Total Comp.'}
        </LabelText>
      );
    },
    footerContent: () => {
      return <LabelText>Total</LabelText>;
    },
  },
  {
    flex: 1,
    justify: 'flex-end',
    headerContent: () => {
      return <LabelText>Cost</LabelText>;
    },
    cellContent: ({costCategory}) => {
      if (costCategory.kind === CostCategoryKind.OTHER) {
        return <CellText>{Currency.display(costCategory.totalCost)}</CellText>;
      }
      return <LabelText>{Currency.display(costCategory.totalCost)}</LabelText>;
    },
    footerContent: ({aggregateCost}) => {
      return <LabelText>{Currency.display(aggregateCost.totalCost)}</LabelText>;
    },
  },
  {
    flex: 2,
    justify: 'center',
    isRevenueFeature: true,
    headerContent: ({responsive}) => {
      return <LabelText>{`${responsive.mobile ? '%/Rev' : 'Revenue %'}`}</LabelText>;
    },
    cellContent: ({costCategory, totalRevenue}) => {
      if (costCategory.kind === CostCategoryKind.OTHER) {
        return (
          <CellText>
            {Percent.fromFraction({numerator: costCategory.totalCost, denominator: totalRevenue})}
          </CellText>
        );
      }
      return null;
    },
    footerContent: () => {
      return null;
    },
  },
  {
    width: 32,
    justify: 'flex-end',
    headerContent: () => {
      return null;
    },
    cellContent: ({costCategory, handleEditCost}) => {
      const firstCostId = _.get(costCategory, 'costs.0.id');
      return (
        costCategory.kind === CostCategoryKind.OTHER && (
          <Button style={{paddingRight: 4}} onPress={() => handleEditCost(firstCostId)}>
            <Icon source={Icon.Pen} color={colors.blue.interactive} size={14} />
          </Button>
        )
      );
    },
    footerContent: () => {
      return null;
    },
  },
];

const COMPENSATION_TABLE_COLUMNS = [
  {
    flex: 2,
    justify: 'flex-start',
    cellContent: ({cost}) => {
      const isPaidOutOption = Cost.getIsPaidOutOption(cost);
      return (
        <StatusBadge style={{backgroundColor: isPaidOutOption.backgroundColor}}>
          <StatusBadgeText style={{color: isPaidOutOption.textColor}}>
            {isPaidOutOption.label}
          </StatusBadgeText>
        </StatusBadge>
      );
    },
  },
  {
    flex: 3,
    justify: 'flex-start',
    cellContent: ({cost}) => {
      const hasBalance = Cost.getHasBalanceDue(cost);
      return (
        <CellText color={hasBalance ? colors.red.warning : colors.gray.primary}>
          {cost.date
            ? Datetime.convertToDisplayDate(cost.date, Datetime.DISPLAY_SHORT_DATE)
            : 'TBD'}
        </CellText>
      );
    },
  },
  {
    flex: 3,
    justify: 'flex-start',
    cellContent: ({cost}) => {
      const hasBalance = Cost.getHasBalanceDue(cost);
      return (
        <CellText color={hasBalance ? colors.red.warning : colors.gray.primary}>
          {Cost.getDisplayIdentifier(cost)}
        </CellText>
      );
    },
  },
  {
    flex: 3,
    justify: 'flex-start',
    cellContent: ({cost}) => {
      const hasBalance = Cost.getHasBalanceDue(cost);
      return (
        <CellText color={hasBalance ? colors.red.warning : colors.gray.primary}>
          {cost.displayName}
        </CellText>
      );
    },
  },
  {
    flex: 2,
    justify: 'flex-start',
    cellContent: ({cost}) => {
      const hasBalance = Cost.getHasBalanceDue(cost);
      return (
        <CellText color={hasBalance ? colors.red.warning : colors.gray.primary}>
          {Cost.getRole(cost)}
        </CellText>
      );
    },
  },
  {
    flex: 1,
    justify: 'flex-end',
    cellContent: ({cost}) => {
      const hasBalance = Cost.getHasBalanceDue(cost);
      return (
        <CellText color={hasBalance ? colors.red.warning : colors.gray.primary}>
          {Currency.display(cost.total)}
        </CellText>
      );
    },
  },
  {
    flex: 2,
    justify: 'center',
    isRevenueFeature: true,
    cellContent: ({cost, totalRevenue}) => {
      const hasBalance = Cost.getHasBalanceDue(cost);
      return (
        <CellText color={hasBalance ? colors.red.warning : colors.gray.primary}>
          {Percent.fromFraction({numerator: cost.total, denominator: totalRevenue})}
        </CellText>
      );
    },
  },
  {
    width: 32,
    justify: 'flex-end',
    cellContent: ({cost, handleEditCost}) => {
      return (
        <Button style={{paddingRight: 4}} onPress={() => handleEditCost(cost.id)}>
          <Icon source={Icon.Pen} color={colors.blue.interactive} size={14} />
        </Button>
      );
    },
  },
];

const CompensationCostRow = ({
  cost,
  totalRevenue,
  handleEditCost,
  isCostAndCompensationEnabled,
}) => {
  const {isHovered, ref} = useHover();
  const responsive = useResponsive();
  return (
    <TableRow ref={ref} isHovered={isHovered} {...responsive}>
      {COMPENSATION_TABLE_COLUMNS.map((column, index) => {
        if (column.isRevenueFeature && !isCostAndCompensationEnabled) {
          return null;
        }
        return (
          <Cell
            key={index}
            justify={column.justify}
            style={column.flex ? {flex: column.flex} : {width: column.width}}
          >
            {column.cellContent({cost, totalRevenue, handleEditCost})}
          </Cell>
        );
      })}
    </TableRow>
  );
};

const CompensationCostsTable = ({
  costs,
  project,
  totalRevenue,
  handleEditCost,
  isCostAndCompensationEnabled,
}) => {
  const costsByDate = getCostsByDate({costs});
  return (
    <CompensationCostsTableContainer>
      {costsByDate.map((cost, index) => {
        return (
          <CompensationCostRow
            key={index}
            project={project}
            cost={cost}
            totalRevenue={totalRevenue}
            handleEditCost={handleEditCost}
            isCostAndCompensationEnabled={isCostAndCompensationEnabled}
          />
        );
      })}
    </CompensationCostsTableContainer>
  );
};

const CostCategoryCosts = ({
  costCategory,
  totalRevenue,
  handleEditCost,
  isCostAndCompensationEnabled,
}) => {
  const {isHovered, ref} = useHover();
  const responsive = useResponsive();
  const isCompensation = costCategory.kind === CostCategoryKind.COMPENSATION;
  return (
    <React.Fragment>
      {isCompensation && !_.isEmpty(costCategory.costs) && (
        <React.Fragment>
          <CompensationCostsTable
            costs={costCategory.costs}
            totalRevenue={totalRevenue}
            handleEditCost={handleEditCost}
            isCostAndCompensationEnabled={isCostAndCompensationEnabled}
          />
          <Line />
        </React.Fragment>
      )}
      <TableRow ref={ref} isHovered={isHovered} {...responsive}>
        {PROJECT_COSTS_TABLE_COLUMNS.map((column, index) => {
          if (column.isRevenueFeature && !isCostAndCompensationEnabled) {
            return null;
          }
          return (
            <Cell
              key={index}
              justify={column.justify}
              style={column.flex ? {flex: column.flex} : {width: column.width}}
            >
              {column.cellContent({costCategory, totalRevenue, handleEditCost})}
            </Cell>
          );
        })}
      </TableRow>
    </React.Fragment>
  );
};

const CostAndCompTable = ({project, refetch}) => {
  const [costId, setCostId] = useState();
  const editCostCostItemsModal = useModal({name: 'Edit Cost Cost Items Modal'});
  const responsive = useResponsive();
  const {aggregateCost, totalRevenue} = project;
  const {isCostAndCompensationEnabled} = project.organization.settings;
  return (
    <TableContainer>
      <Space height={4} />
      <TableRow {...responsive}>
        <Cell justify={'flex-start'} style={{flex: 1}}>
          <LabelText>Employee Compensation</LabelText>
        </Cell>
      </TableRow>
      <Space height={4} />
      <Line />
      <TableRow {...responsive}>
        {PROJECT_COSTS_TABLE_COLUMNS.map((column, index) => {
          if (column.isRevenueFeature && !isCostAndCompensationEnabled) {
            return null;
          }
          return (
            <Cell
              key={index}
              justify={column.justify}
              style={column.flex ? {flex: column.flex} : {width: column.width}}
            >
              {column.headerContent({responsive})}
            </Cell>
          );
        })}
      </TableRow>
      {aggregateCost.costCategories.map((costCategory, index) => {
        return (
          <React.Fragment key={index}>
            <Line />
            <CostCategoryCosts
              costCategory={costCategory}
              totalRevenue={totalRevenue}
              handleEditCost={(costId) => {
                setCostId(costId);
                editCostCostItemsModal.handleOpen();
              }}
              isCostAndCompensationEnabled={isCostAndCompensationEnabled}
            />
          </React.Fragment>
        );
      })}
      <Line />
      <TableRow {...responsive}>
        {PROJECT_COSTS_TABLE_COLUMNS.map((column, index) => {
          if (column.isRevenueFeature && !isCostAndCompensationEnabled) {
            return null;
          }
          return (
            <Cell
              key={index}
              justify={column.justify}
              style={column.flex ? {flex: column.flex} : {width: column.width}}
            >
              {column.footerContent({aggregateCost, totalRevenue})}
            </Cell>
          );
        })}
      </TableRow>
      <EditCostCostItemsModalV2
        key={editCostCostItemsModal.key}
        isOpen={editCostCostItemsModal.isOpen}
        handleClose={editCostCostItemsModal.handleClose}
        costId={costId}
        aggregateCost={aggregateCost}
        refetch={refetch}
      />
    </TableContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CostAndCompTable.fragment = gql`
  ${CostCategory.getDisplayKind.fragment}
  ${Cost.getHasBalanceDue.fragment}
  ${Cost.getRole.fragment}
  ${Cost.getIsPaidOutOption.fragment}
  ${Cost.getDisplayIdentifier.fragment}
  ${EditCostCostItemsModalV2.fragment}

  fragment CostAndCompTable on Project {
    id
    totalRevenue
    aggregateCost {
      totalCost
      costCategories {
        totalCost
        costs {
          id
          date
          displayName
          total
          jobId
          ...Cost_getHasBalanceDue
          ...Cost_getRole
          ...Cost_getIsPaidOutOption
          ...Cost_getDisplayIdentifier
        }
        ...CostCategory_getDisplayKind
      }
      ...EditCostCostItemsModalV2
    }
    organization {
      id
      settings {
        id
        isCostAndCompensationEnabled
      }
    }
  }
`;

export default CostAndCompTable;
