// Libraries
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import CustomerDocumentSignatureItem from './CustomerDocumentSignatureItem';

const Container = Styled.View`
`;

const CustomerDocumentSignaturesList = ({shouldPromptSignature, document}) => (
  <Container>
    {document.documentSignatures.map((documentSignature) => (
      <CustomerDocumentSignatureItem
        key={documentSignature.placement}
        shouldPromptSignature={shouldPromptSignature}
        document={document}
        documentSignature={documentSignature}
      />
    ))}
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerDocumentSignaturesList.fragment = gql`
  ${CustomerDocumentSignatureItem.fragment}

  fragment CustomerDocumentSignaturesList on Document {
    id
    documentSignatures {
      id
      placement
      ...CustomerDocumentSignatureItem
    }
    ...CustomerDocumentSignatureItem_Document
  }
`;

export default CustomerDocumentSignaturesList;
