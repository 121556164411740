// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import ButtonComponent from '@shared/design/components/Button';
import IconButton from '@shared/design/components/IconButton';
import Line from 'modules/App/components/Line';

const HeaderContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Heading2};
`;

const Row = Styled.View`
  flex-direction: row;
`;

type DashboardsPageHeaderProps = {
  isDashboardAlreadyAdded: boolean;
  isDashboardSelected: boolean;
  handleAddReport: () => void;
  handleGoBack: () => void;
};

const DashboardsPageHeader = ({
  isDashboardAlreadyAdded,
  isDashboardSelected,
  handleAddReport,
  handleGoBack,
}: DashboardsPageHeaderProps) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <Space height={16} />
      <HeaderContainer>
        <Row style={{alignItems: 'center'}}>
          <Space width={responsive.desktop ? 24 : 16} />
          <IconButton source={Icon.Xmark} isSecondary onPress={handleGoBack} size={20} />
          <Space width={8} />
          <Title responsive={responsive}>Reports Library</Title>
        </Row>
        {isDashboardSelected && (
          <Row>
            <ButtonComponent
              text={isDashboardAlreadyAdded ? 'Add as Custom' : 'Add'}
              iconLeft={Icon.Check}
              onPress={handleAddReport}
            />
            <Space width={responsive.desktop ? 24 : 16} />
          </Row>
        )}
      </HeaderContainer>
      <React.Fragment>
        <Space height={16} />
        <Line />
      </React.Fragment>
    </React.Fragment>
  );
};

export default DashboardsPageHeader;
