import React from 'react';

// Supermove
import {Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ThreadEmail from 'modules/Communication/components/ThreadEmail';
import EmailThreadName from 'modules/Email/components/EmailThreadName';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const Container = Styled.View`
  background-color: ${colors.white};
  padding: 16px;
`;

const EmailThreadContainer = Styled.View`
`;

const BackButtonTouchable = Styled.Touchable`
`;

const CustomerEmailThreadEmails = ({selectedThread, setSelectedThread}) => {
  const responsive = useResponsive();

  return (
    <EmailThreadContainer>
      <Container>
        <Row>
          {responsive.desktop && (
            <React.Fragment>
              <BackButtonTouchable onPress={() => setSelectedThread(null)}>
                <Icon
                  color={colors.gray.primary}
                  size={Icon.Sizes.Large}
                  source={Icon.ChevronLeft}
                />
              </BackButtonTouchable>
              <Space width={16} />
            </React.Fragment>
          )}
          <EmailThreadName emailThread={selectedThread} />
        </Row>
      </Container>
      <Line />
      {selectedThread.sentEmails.map((email, index) => (
        <ThreadEmail email={email} key={index} />
      ))}
    </EmailThreadContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerEmailThreadEmails.fragment = gql`
  ${EmailThreadName.fragment}
  ${ThreadEmail.fragment}

  fragment CustomerEmailThreadEmails on Thread {
    id
    sentEmails {
      id
      ...ThreadEmail
    }
    ...EmailThreadName
  }
`;

export default CustomerEmailThreadEmails;
