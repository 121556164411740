const _new = ({jobId}: any) => ({
  jobId,
});

const toForm = ({jobId}: any) => ({
  jobId,
});

const toMutation = ({jobId}: any) => ({
  jobId,
});

const JobEventForm = {
  new: _new,
  toForm,
  toMutation,
};

export default JobEventForm;
