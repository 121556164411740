// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const Container = Styled.View`
`;

const Content = Styled.View`
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Label = Styled.Text`
  ${Typography.Body2};
  margin-right: 20px;
  color: ${colors.gray.secondary};
`;

const Description = Styled.Text`
  ${Typography.Body2};
  color: ${colors.gray.secondary};
`;

const InputText = Styled.Text`
  ${Typography.Body3};
  color: ${colors.gray.secondary};
`;

const TextInputEdit = ({index, fieldInfo, input, form, field, isRequired}: any) => {
  const valueField = `${field}.input.value.values.${index}`;
  return (
    <Container>
      <Space height={12} />
      <FieldInput.LabelText size={FieldInput.SIZE.MEDIUM}>{fieldInfo.label}</FieldInput.LabelText>
      {!!fieldInfo.description && (
        <React.Fragment>
          <FieldInput.LabelText style={{color: colors.gray.secondary}}>
            {fieldInfo.description}
          </FieldInput.LabelText>
          <Space height={2} />
        </React.Fragment>
      )}
      <Space height={4} />
      <FieldInput.Memoized
        {...form}
        name={valueField}
        size={FieldInput.SIZE.MEDIUM}
        input={{
          required: isRequired && !_.get(form.values, valueField),
          placeholder: 'Enter value',
        }}
      />
    </Container>
  );
};

const TextInputView = ({index, fieldInfo, input}: any) => {
  return (
    <Container>
      <Content>
        <Label numberOfLines={1}>{_.get(fieldInfo, 'label')}</Label>
        <Description numberOfLines={1}>{_.get(fieldInfo, 'description')}</Description>
        <Space height={4} />
        <InputText numberOfLines={1}>{input.value.values[index]}</InputText>
      </Content>
      <Line />
    </Container>
  );
};

const DocumentV2TextInputs = ({input, form, field, isEditable, isRequired}: any) => {
  return (
    <React.Fragment>
      <Line />
      {input.metadata.fields.map((fieldInfo: any, index: any) => {
        if (_.get(fieldInfo, 'isHidden') === true) {
          return null;
        }

        return (
          <React.Fragment key={index}>
            {isEditable ? (
              <TextInputEdit
                index={index}
                fieldInfo={fieldInfo}
                input={input}
                form={form}
                field={field}
                isRequired={isRequired}
              />
            ) : (
              <TextInputView index={index} fieldInfo={fieldInfo} input={input} />
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default DocumentV2TextInputs;
