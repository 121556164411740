// Libraries
import React from 'react';

// App
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';
import InvoiceForm from '@shared/modules/Billing/forms/InvoiceForm';
import useFinalizeInvoiceMutation from '@shared/modules/Billing/hooks/useFinalizeInvoiceMutation';

const FinalizeInvoiceModal = ({invoiceId, isOpen, handleClose, refetch}) => {
  const invoiceForm = InvoiceForm.idOnly({invoiceId});
  const {submitting, handleSubmit} = useFinalizeInvoiceMutation({
    invoiceForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <SuccessModal
      title={'Finalize invoice?'}
      subtitle={'This invoice will be locked and no further changes can be made.'}
      isOpen={isOpen}
      handlePrimaryAction={handleSubmit}
      primaryActionText={'Finalize'}
      handleSecondaryAction={handleClose}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
    />
  );
};

export default FinalizeInvoiceModal;
