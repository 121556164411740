// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Datetime} from '@supermove/utils';

const getServerDate = withFragment(
  (textMessage) => {
    return (textMessage as any).sentAt
      ? Datetime.toDisplayDate(
          Datetime.fromDatetime((textMessage as any).sentAt),
          Datetime.SERVER_DATE,
        )
      : null;
  },
  gql`
    fragment TextMessage_getServerDate on TextMessage {
      id
      sentAt
    }
  `,
);

const getDisplayDate = withFragment(
  (textMessage) => {
    return (textMessage as any).sentAt
      ? Datetime.toDisplayDate(Datetime.fromDatetime((textMessage as any).sentAt), 'M/DD/YYYY')
      : null;
  },
  gql`
    fragment TextMessage_getDisplayDate on TextMessage {
      id
      sentAt
    }
  `,
);

const getDisplayTime = withFragment(
  (textMessage) => {
    return (textMessage as any).sentAt
      ? Datetime.toDisplayTime(Datetime.fromDatetime((textMessage as any).sentAt))
      : null;
  },
  gql`
    fragment TextMessage_getDisplayTime on TextMessage {
      id
      sentAt
    }
  `,
);

const TextMessage = {
  getServerDate,
  getDisplayDate,
  getDisplayTime,
};

export default TextMessage;
