// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {DragAndDropList} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDragAndDrop} from '@supermove/hooks';
import {List} from '@supermove/utils';

// App
import ReorderClaimStatusesForm from '@shared/modules/Claim/forms/ReorderClaimStatusesForm';
import useReorderClaimStatusesMutation from '@shared/modules/Claim/hooks/useReorderClaimStatusesMutation';
import ClaimStatusItem from 'modules/Organization/Settings/Company/components/ClaimStatusItem';

const getClaimStatusesByRank = (claimStatuses) => {
  return _.sortBy(claimStatuses, ['rank']);
};

const onReorder = ({form, fromIndex, toIndex, handleSubmit}) => {
  const claimStatusForms = _.cloneDeep(form.values.reorderClaimStatusesForm.claimStatusForms);
  const reorderedClaimStatusForms = List.move({list: claimStatusForms, fromIndex, toIndex});
  reorderedClaimStatusForms.forEach((claimStatusForm, index) => {
    claimStatusForm.rank = index;
  });
  form.setFieldValue('reorderClaimStatusesForm.claimStatusForms', reorderedClaimStatusForms);
  setTimeout(handleSubmit, 0);
};

const DragAndDropClaimList = ({
  form,
  isReordering,
  sortedClaimStatuses,
  handleSubmit,
  refetch,
  handleReorderStart,
  isOrganizationPrimary,
}) => {
  return (
    <DragAndDropList
      isDisabled={!isOrganizationPrimary}
      isReordering={isReordering}
      onReorder={({fromIndex, toIndex}) => {
        handleReorderStart();
        onReorder({
          form,
          fromIndex,
          toIndex,
          handleSubmit,
        });
      }}
    >
      {form.values.reorderClaimStatusesForm.claimStatusForms.map((claimStatusForm) => {
        const claimStatus = _.find(
          sortedClaimStatuses,
          (claimStatus) => claimStatus.id === claimStatusForm.claimStatusId,
        );
        return (
          <ClaimStatusItem
            key={claimStatusForm.claimStatusId}
            claimStatusForm={claimStatusForm}
            refetch={refetch}
            claimStatus={claimStatus}
            isOrganizationPrimary={isOrganizationPrimary}
          />
        );
      })}
    </DragAndDropList>
  );
};

const OrganizationClaimStatuses = ({
  organization,
  refetch,
  isEnabledClaimsProjectOrganizationMigration,
}) => {
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const sortedClaimStatuses = getClaimStatusesByRank(organization.companySettings.claimStatuses);
  const reorderClaimStatusesForm = ReorderClaimStatusesForm.edit(sortedClaimStatuses);
  const {form, handleSubmit} = useReorderClaimStatusesMutation({
    reorderClaimStatusesForm,
    onSuccess: () => {
      refetch();
      handleReorderEnd();
    },
    onError: () => {
      handleReorderEnd();
    },
  });
  return (
    <React.Fragment>
      <DragAndDropClaimList
        form={form}
        isReordering={isReordering}
        sortedClaimStatuses={sortedClaimStatuses}
        handleReorderStart={handleReorderStart}
        handleSubmit={handleSubmit}
        refetch={refetch}
        isOrganizationPrimary={
          organization.isPrimary || !isEnabledClaimsProjectOrganizationMigration
        }
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationClaimStatuses.fragment = gql`
  ${ClaimStatusItem.fragment}
  fragment OrganizationClaimStatuses on Organization {
    id
    isPrimary
    companySettings {
      claimStatuses {
        id
        name
        color
        rank
        isDefault
        ...ClaimStatusItem
      }
    }
  }
`;

export default OrganizationClaimStatuses;
