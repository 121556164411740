// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CreateAndSendConfirmationForProjectForm from '@shared/modules/Proposal/forms/CreateAndSendConfirmationForProjectForm';

const useCreateAndSendConfirmationForProjectMutation = ({
  createAndSendConfirmationForProjectForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      createAndSendConfirmationForProjectForm: CreateAndSendConfirmationForProjectForm.toForm(
        createAndSendConfirmationForProjectForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateAndSendConfirmationForProjectMutation.mutation,
    variables: {
      createAndSendConfirmationForProjectForm: CreateAndSendConfirmationForProjectForm.toMutation(
        form.values.createAndSendConfirmationForProjectForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateAndSendConfirmationForProjectMutation.mutation = gql`
  mutation useCreateAndSendConfirmationForProjectMutation($createAndSendConfirmationForProjectForm: CreateAndSendConfirmationForProjectForm!) {
    response: createAndSendConfirmationForProject(createAndSendConfirmationForProjectForm: $createAndSendConfirmationForProjectForm) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useCreateAndSendConfirmationForProjectMutation;
