// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UpdateProjectAndJobTypesForProjectForm from '@shared/modules/Project/forms/UpdateProjectAndJobTypesForProjectForm';

const useUpdateProjectAndJobTypesForProjectMutation = ({
  updateProjectAndJobTypesForProjectForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      updateProjectAndJobTypesForProjectForm: UpdateProjectAndJobTypesForProjectForm.toForm(
        updateProjectAndJobTypesForProjectForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectAndJobTypesForProjectMutation.mutation,
    variables: {
      updateProjectAndJobTypesForProjectForm: UpdateProjectAndJobTypesForProjectForm.toMutation(
        form.values.updateProjectAndJobTypesForProjectForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectAndJobTypesForProjectMutation.mutation = gql`
  mutation useUpdateProjectAndJobTypesForProjectMutation($updateProjectAndJobTypesForProjectForm: UpdateProjectAndJobTypesForProjectForm!) {
    response: updateProjectAndJobTypesForProject(updateProjectAndJobTypesForProjectForm: $updateProjectAndJobTypesForProjectForm) {
      ${gql.errors}
      project {
        id
        projectTypeId
        activeJobs {
          id
          jobTypeId
        }
      }
    }
  }
`;

export default useUpdateProjectAndJobTypesForProjectMutation;
