// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useScrollView} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import OrganizationSlotsCrews from 'modules/Dispatch/Calendar/Day/components/OrganizationSlotsCrews';
import OrganizationSlotsInfo from 'modules/Dispatch/Calendar/Day/components/OrganizationSlotsInfo';
import useDayCalendarDispatchOrganizationsContext from 'modules/Dispatch/Calendar/Day/context/useDayCalendarDispatchOrganizationsContext';
import useDayCalendarDispatchViewContext from 'modules/Dispatch/Calendar/Day/context/useDayCalendarDispatchViewContext';

const Touchable = Styled.Touchable`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const SlotInstructionsContainer = Styled.View`
  background-color: ${colors.alpha(colors.orange.accent, 0.5)};
  margin-left: 38px;
  margin-right: 20px;
  border-radius: 4px;
  flex-direction: row;
  border-width: 1px;
  border-color: ${colors.orange.status};
  padding: 12px;
`;

const SlotInstructionsIconContainer = Styled.View`
  height: 18px;
  width: 18px;
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.orange.status};
`;

const SlotInstructionsColumn = Styled.View`
  flex: 1;
`;

const SlotInstructionsTitle = Styled.Text`
  ${Typography.Label1}
  color: ${colors.gray.primary};
`;

const SlotInstructionsCaption = Styled.Text`
${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const SlotInstructionsLink = Styled.Text`
  ${Typography.Body3}
  color: ${colors.orange.status};
  text-decoration: underline;
  text-decoration-color: ${colors.orange.status};
`;

const OrganizationSlotsSummariesWrapper = Styled.View`
  flex: 1;
`;

const LeftPanelControlButton = Styled.ButtonV2`
  height: 24px;
  padding-horizontal: 8px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: 1px;
  align-items: center;
  justify-content: center;
`;

const LeftPanelControlText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
`;

const Indicator = Styled.Loading`
  padding: 20px;
`;

const hasAssignedTruckOrDriverToSlot = (organizationSlotsSummaries) => {
  const slots = _.flatten(organizationSlotsSummaries.map((org) => org.slots));
  return _.find(slots, (slot) => !!slot.truckId || !!slot.driverId);
};

const SlotInstructions = () => {
  const {navigator} = useNavigationDOM();
  const handleNavigateToSettings = () => navigator.push(`/settings/dispatch/slots`);

  return (
    <React.Fragment>
      <Space height={16} />
      <SlotInstructionsContainer>
        <SlotInstructionsIconContainer>
          <Icon source={Icon.Question} size={13} color={colors.orange.accent} />
        </SlotInstructionsIconContainer>
        <Space width={10} />
        <SlotInstructionsColumn>
          <SlotInstructionsTitle>
            It seems like you haven't assigned any trucks or drivers to a slot
          </SlotInstructionsTitle>
          <Space height={10} />
          <SlotInstructionsCaption>
            {`Please assign a truck and driver to a slot on the left column ` +
              `before assigning jobs. You can also setup default trucks and ` +
              `drivers for each slot from your `}
            <Touchable activeOpacity={0.8} onPress={handleNavigateToSettings}>
              <SlotInstructionsLink>{`settings`}</SlotInstructionsLink>
            </Touchable>
            {` to save more time.`}
          </SlotInstructionsCaption>
        </SlotInstructionsColumn>
      </SlotInstructionsContainer>
    </React.Fragment>
  );
};

/**
 * This is the organization slots summaries component representing the left side of the dispatch
 * calendar. We wrap all the organizations in a wrapper that allows for a column flex of each
 * organization with its respective slots.
 */
const DispatchDayLeftPanel = ({data, loading, refetch}) => {
  const {isAllOpen, toggleAllOrganizations} = useDayCalendarDispatchOrganizationsContext();
  const {setLeftPanelWidth} = useDayCalendarDispatchViewContext();
  const {ref, handleScrollTo, handleScrollToEnd} = useScrollView();
  return (
    <Loading as={Indicator} loading={loading || !data}>
      {() => {
        const {organizationSlotsSummaries} = data.dispatchCalendarDay;
        const isVisibleSlotInstructions = !hasAssignedTruckOrDriverToSlot(
          organizationSlotsSummaries,
        );
        return (
          <React.Fragment>
            {isVisibleSlotInstructions && <SlotInstructions />}
            <OrganizationSlotsSummariesWrapper
              onLayout={({nativeEvent}) => setLeftPanelWidth(nativeEvent.layout.width)}
            >
              <Space height={8} />
              <Row>
                <Space style={{flex: 1}} />
                <LeftPanelControlButton onPress={toggleAllOrganizations}>
                  <LeftPanelControlText>
                    {isAllOpen ? 'Close All' : 'Open All'}
                  </LeftPanelControlText>
                </LeftPanelControlButton>
                <Space width={8} />
                <LeftPanelControlButton onPress={() => handleScrollTo({x: 0})}>
                  <Icon source={Icon.ChevronLeft} size={16} color={colors.gray.secondary} />
                </LeftPanelControlButton>
                <Space width={8} />
                <LeftPanelControlButton onPress={() => handleScrollToEnd({})}>
                  <Icon source={Icon.ChevronRight} size={16} color={colors.gray.secondary} />
                </LeftPanelControlButton>
                <Space width={12} />
              </Row>
              <Space height={8} />
              <ScrollView>
                {/**
                 * We use row-reverse to flip the order such that the right side is rendered on
                 * top of the left side. The ScrollView on OrganizationSlotsCrews needs to be the
                 * top-most element so that scrolling horizontally works.
                 */}
                <Space height={8} />
                <Row style={{flexDirection: 'row-reverse'}}>
                  <OrganizationSlotsCrews
                    organizationSlotsSummaries={organizationSlotsSummaries}
                    refetch={refetch}
                    scrollViewRef={ref}
                    handleScrollTo={handleScrollTo}
                  />
                  <OrganizationSlotsInfo
                    organizationSlotsSummaries={organizationSlotsSummaries}
                    refetch={refetch}
                  />
                  <Space width={16} />
                </Row>
                <Space height={60} />
              </ScrollView>
            </OrganizationSlotsSummariesWrapper>
          </React.Fragment>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DispatchDayLeftPanel.fragment = gql`
  ${OrganizationSlotsCrews.fragment}
  ${OrganizationSlotsInfo.fragment}

  fragment DispatchDayLeftPanel on DispatchCalendarDay {
    organizationSlotsSummaries {
      organization {
        id
        name
      }
      slots {
        id
        index
        truckId
        driverId
      }
    }
    ...OrganizationSlotsCrews
    ...OrganizationSlotsInfo
  }
`;

export default DispatchDayLeftPanel;
