const USD = 'USD';
const CAD = 'CAD';

const KIND_DISPLAY_MAP = {
  [USD]: 'USD',
  [CAD]: 'CAD',
};

const DROPDOWN_OPTIONS = [
  {
    label: KIND_DISPLAY_MAP[USD],
    value: USD,
  },
  {
    label: KIND_DISPLAY_MAP[CAD],
    value: CAD,
  },
];

const getDisplayKind = (webhookKind: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return KIND_DISPLAY_MAP[webhookKind];
};

export default {
  USD,
  CAD,
  DROPDOWN_OPTIONS,
  getDisplayKind,
};
