// Libararies
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import UpdateInvoicePageContent from 'modules/Storage/components/UpdateInvoicePageContent';

const UpdateInvoicePage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(UpdateInvoicePage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      invoiceUuid: params.invoiceUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return <UpdateInvoicePageContent invoice={data.invoiceByUuid} viewer={data.viewer} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateInvoicePage.query = gql`
  ${UpdateInvoicePageContent.fragment}
  query UpdateInvoicePage($invoiceUuid: String!) {
    viewer {
      id
      role
    }
    invoiceByUuid(invoiceUuid: $invoiceUuid) {
      id
      status
      project {
        id
        uuid
      }
      ...UpdateInvoicePageContent
    }
  }
`;

export default UpdateInvoicePage;
