// Libraries
import _ from 'lodash';
import React from 'react';
import {Redirect} from 'react-router-dom';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import Page from 'modules/App/components/Page';

const ConfirmationProjectPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <Page fetchPolicy={'network-only'} query={ConfirmationProjectPage.query} variables={{uuid}}>
    {({loading, data}) => (
      <Loading loading={loading}>
        {() => {
          const {project} = data;
          if (!project.confirmation || project.confirmation?.isExpired || project.isCancelled) {
            return <Redirect to={`/0/${slug}/projects/${uuid}/confirmation/expired`} />;
          }

          const nextStep = _.get(data, 'project.confirmation.nextStep');
          const kind = _.get(nextStep, 'kind', null);
          const valueTwo = _.get(nextStep, 'valueTwo', null);
          const documentUuid = _.get(nextStep, 'documentUuid', null);

          switch (kind) {
            case ConfirmationStepKind.CONFIRMATION_STEP_ACCEPT_QUOTE:
              return <Redirect to={`/0/${slug}/projects/${uuid}/confirmation/quote`} />;
            case ConfirmationStepKind.CONFIRMATION_STEP_MAKE_DEPOSIT:
              return <Redirect to={`/0/${slug}/projects/${uuid}/confirmation/deposit`} />;
            case ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD:
              return <Redirect to={`/0/${slug}/projects/${uuid}/confirmation/card`} />;
            case ConfirmationStepKind.CONFIRMATION_STEP_SIGN_INVENTORY:
              return <Redirect to={`/0/${slug}/projects/${uuid}/confirmation/inventory`} />;
            case ConfirmationStepKind.CONFIRMATION_STEP_SIGN_DOCUMENT:
              return <Redirect to={`/0/${slug}/projects/${uuid}/confirmation/document`} />;
            case ConfirmationStepKind.CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE:
              return (
                <Redirect to={`/0/${slug}/projects/${uuid}/confirmation/documents/${valueTwo}`} />
              );
            case ConfirmationStepKind.CONFIRMATION_STEP_DOCUMENT:
              return (
                <Redirect
                  to={`/0/${slug}/projects/${uuid}/confirmation/documents/v2/${documentUuid}`}
                />
              );
            default:
              return <Redirect to={`/0/${slug}/projects/${uuid}/confirmation/success`} />;
          }
        }}
      </Loading>
    )}
  </Page>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmationProjectPage.query = gql`
  query ConfirmationProjectPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      isCancelled
      confirmation {
        id
        kind
        isComplete
        isExpired
        nextStep {
          kind
          valueTwo
          documentUuid
        }
      }
    }
  }
`;

export default ConfirmationProjectPage;
