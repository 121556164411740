// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import BillTipForm from '@shared/modules/Billing/forms/BillTipForm';

const useCreateBillTipMutation = ({billTipForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      billTipForm: BillTipForm.toForm(billTipForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateBillTipMutation.mutation,
    variables: {
      billTipForm: BillTipForm.toMutation(form.values.billTipForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateBillTipMutation.mutation = gql`
  mutation useCreateBillTipMutation($billTipForm: BillTipForm!) {
    response: createBillTip(billTipForm: $billTipForm) {
      ${gql.errors}
      billTip {
        id
      }
    }
  }
`;

export default useCreateBillTipMutation;
