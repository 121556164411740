// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ItemRow = Styled.View`
  flex-direction: row;
  padding-horizontal: 20px;
  padding-top: 2px;
  padding-bottom: 4;
  width: 50%;
`;

const Left = Styled.View`
  flex: 1;
`;

const Text = Styled.H6`
  color: ${colors.gray.secondary};
  ${fontWeight(500)}
`;

const BlackBoldText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const SmallBlackBoldText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const GrayBoldText = Styled.H6`
  color: ${colors.gray.secondary};
  ${fontWeight(600)}
`;

const SmallGrayText = Styled.H8`
  color: ${colors.gray.secondary};
  ${fontWeight(500)}
`;

const LightLine = Styled.View`
  align-self: stretch;
  height: 1px;
  background-color: ${colors.blue.accent};
  margin-horizontal: 16px;
`;

const DarkLine = Styled.View`
  align-self: stretch;
  height: 1px;
  background-color: ${colors.gray.primary};
`;

const CollectionHeaderContainer = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-top: 4px;
  background-color: ${colors.gray.background};
`;

const CollectionHeaderRow = Styled.View`
  flex-direction: row;
  width: 50%;
  padding-bottom: 4px;
  padding-top: 2px;
  padding-horizontal: 19px;
  align-items: center;
`;

const CollectionItemsListContainer = Styled.View`
`;

const CollectionItemsListSection = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const CollectionItemsListSectionSeparator = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border};
  border-style: dashed;
  flex: 1;
  margin-bottom: 3px;
  margin-horizontal: 16px;
`;

const CollectionItemsListCenterVerticalDashedLine = Styled.View`
  border-right-width: 1px;
  border-color: ${colors.gray.border};
  border-style: dashed;
  position: absolute;
  align-self: center;
  height: 100%;
`;

const CollectionHeader = ({title, subtitle, columnLabel}) => {
  return (
    <CollectionHeaderContainer>
      <CollectionHeaderRow>
        <Left>
          <SmallBlackBoldText style={{flex: 1}}>{title}</SmallBlackBoldText>
          <SmallGrayText>{subtitle}</SmallGrayText>
        </Left>
        <Space width={4} />
        <SmallGrayText style={{alignItems: 'flex-end'}}>{columnLabel}</SmallGrayText>
      </CollectionHeaderRow>
      <CollectionHeaderRow>
        <BlackBoldText style={{flex: 1}} />
        <SmallGrayText style={{alignItems: 'flex-end'}}>{columnLabel}</SmallGrayText>
      </CollectionHeaderRow>
    </CollectionHeaderContainer>
  );
};

const CollectionFooter = ({collection}) => {
  return (
    <React.Fragment>
      <LightLine />
      <Space height={12} />
      <Row style={{paddingHorizontal: 20}}>
        <BlackBoldText style={{flex: 1}}>
          {`${pluralize('Item', collection.takeCount, true)}`}
        </BlackBoldText>
        <GrayBoldText style={{alignItems: 'flex-end'}}>
          {`${_.round(collection.totalVolume, 2)} cb ft`}
        </GrayBoldText>
        <Space width={20} />
        <GrayBoldText style={{alignItems: 'flex-end'}}>{`${_.round(
          collection.totalWeight,
          2,
        )} lb`}</GrayBoldText>
      </Row>
    </React.Fragment>
  );
};

const RoomsListHeader = ({collection}) => {
  return (
    <Row style={{alignItems: 'center'}}>
      <Icon source={Icon.Archive} size={14} color={colors.blue.interactive} />
      <Space width={8} />
      <BlackBoldText style={{flex: 1}}>Rooms Summary (Cartons Included)</BlackBoldText>
      <BlackBoldText>Total</BlackBoldText>
      <Space width={16} />
      <BlackBoldText>
        {`${collection.takeCount} `}
        <Text>{`${pluralize('Item', collection.takeCount)}`}</Text>
      </BlackBoldText>
      <Space width={14} />
      <BlackBoldText>
        {`${_.round(collection.totalWeight, 2)} `}
        <Text>lb</Text>
      </BlackBoldText>
    </Row>
  );
};

const RoomsListFooter = ({collection}) => {
  return (
    <Row style={{paddingHorizontal: 20}}>
      <BlackBoldText style={{flex: 1}}>
        {`Total ${pluralize('Item', collection.takeCount, true)}`}
      </BlackBoldText>
      <GrayBoldText style={{alignItems: 'flex-end'}}>
        {`${_.round(collection.totalVolume, 2)} cb ft`}
      </GrayBoldText>
      <Space width={20} />
      <GrayBoldText style={{alignItems: 'flex-end'}}>{`${_.round(
        collection.totalWeight,
        2,
      )} lb`}</GrayBoldText>
    </Row>
  );
};

const Room = ({room}) => {
  return (
    <React.Fragment>
      <CollectionHeader title={room.name} subtitle={room.description} columnLabel={'cb ft'} />
      <RoomCollectionItemsList items={room.collection.filteredItems} />
      <CollectionFooter collection={room.collection} />
    </React.Fragment>
  );
};

const getGroupedItems = ({items}) => {
  const itemItems = [];
  const cartonItems = [];

  _.forEach(items, (item) => {
    if (_.get(item, 'itemType.kind') === 'CARTON') {
      cartonItems.push(item);
    } else {
      itemItems.push(item);
    }
  });

  return {itemItems, cartonItems};
};

const RoomCollectionItemsList = ({items}) => {
  const {itemItems, cartonItems} = getGroupedItems({items});

  return (
    <CollectionItemsListContainer>
      <Space height={12} />
      <CollectionItemsListSection>
        {itemItems.map((item) => {
          return <RoomCollectionItem item={item} key={item.id} />;
        })}
      </CollectionItemsListSection>
      {itemItems.length > 0 && cartonItems.length > 0 && <CollectionItemsListSectionSeparator />}
      <CollectionItemsListSection>
        {cartonItems.map((carton) => {
          return <RoomCollectionItem item={carton} key={carton.id} />;
        })}
      </CollectionItemsListSection>
      <Space height={8} />
      <CollectionItemsListCenterVerticalDashedLine />
    </CollectionItemsListContainer>
  );
};

const RoomCollectionItem = ({item}) => {
  return (
    <ItemRow>
      <BlackBoldText style={{width: 30}}>{item.takeCount}</BlackBoldText>
      <Text style={{flex: 1}}>{item.name}</Text>
      <GrayBoldText>{_.round(item.volume, 2)}</GrayBoldText>
    </ItemRow>
  );
};

const CartonCategoriesListHeader = () => {
  return (
    <Row style={{alignItems: 'center'}}>
      <Icon source={Icon.Archive} size={14} color={colors.blue.interactive} />
      <Space width={8} />
      <BlackBoldText style={{flex: 1}}>Cartons Summary</BlackBoldText>
    </Row>
  );
};

const getCartonTotals = (cartonCategories) => {
  return _.reduce(
    cartonCategories,
    (acc, cartonCategory) => {
      return {
        takeCount: acc.takeCount + cartonCategory.collection.takeCount,
        totalWeight: acc.totalWeight + cartonCategory.collection.totalWeight,
        totalVolume: acc.totalVolume + cartonCategory.collection.totalVolume,
      };
    },
    {takeCount: 0, totalWeight: 0, totalVolume: 0},
  );
};

const CartonCategoriesListFooter = ({cartonCategories}) => {
  const {takeCount, totalWeight, totalVolume} = getCartonTotals(cartonCategories);

  return (
    <Row style={{paddingHorizontal: 20}}>
      <BlackBoldText style={{flex: 1}}>{`Total ${pluralize(
        'Carton',
        takeCount,
        true,
      )}`}</BlackBoldText>
      <GrayBoldText style={{alignItems: 'flex-end'}}>
        {`${_.round(totalVolume, 2)} cb ft`}
      </GrayBoldText>
      <Space width={20} />
      <GrayBoldText style={{alignItems: 'flex-end'}}>{`${totalWeight} lb`}</GrayBoldText>
    </Row>
  );
};

const CartonCategory = ({cartonCategory}) => {
  return (
    <React.Fragment>
      <CollectionHeader title={cartonCategory.category.name} columnLabel={'Room'} />
      <CartonCategoryCollectionItemsList items={cartonCategory.collection.filteredItems} />
      <CollectionFooter collection={cartonCategory.collection} />
    </React.Fragment>
  );
};

const CartonCategoryCollectionItemsList = ({items}) => {
  return (
    <CollectionItemsListContainer>
      <Space height={12} />
      <CollectionItemsListSection>
        {items.map((item) => {
          return <CartonCategoryItem item={item} key={item.id} />;
        })}
      </CollectionItemsListSection>
      <Space height={8} />
      <CollectionItemsListCenterVerticalDashedLine />
    </CollectionItemsListContainer>
  );
};

const CartonCategoryItem = ({item}) => {
  return (
    <ItemRow>
      <BlackBoldText style={{width: 30}}>{item.takeCount}</BlackBoldText>
      <Text style={{flex: 1}}>{item.name}</Text>
      <GrayBoldText style={{maxWidth: 120}}>{item.collection.room.name}</GrayBoldText>
    </ItemRow>
  );
};

const DocumentInventorySummary = ({inventory}) => {
  return (
    <Container>
      <RoomsListHeader collection={inventory.collection} />
      {inventory.rooms.length > 0 && (
        <React.Fragment>
          {inventory.rooms.map((room) => {
            return (
              <React.Fragment key={room.id}>
                <Space height={18} />
                <Room room={room} />
                <Space height={18} />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )}
      <DarkLine />
      <Space height={8} />
      <RoomsListFooter collection={inventory.collection} />
      <Space height={48} />
      <CartonCategoriesListHeader />
      {inventory.rooms.length > 0 && (
        <React.Fragment>
          {inventory.cartonCategories.map((cartonCategory) => {
            return (
              <React.Fragment key={cartonCategory.id}>
                <Space height={16} />
                <CartonCategory cartonCategory={cartonCategory} />
                <Space height={16} />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )}
      <DarkLine />
      <Space height={8} />
      <CartonCategoriesListFooter cartonCategories={inventory.cartonCategories} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentInventorySummary.fragment = gql`
  fragment DocumentInventorySummary on Inventory {
    id
    collection {
      id
      takeCount
      totalVolume
      totalWeight
    }
    rooms {
      id
      name
      description
      collection {
        id
        takeCount
        totalWeight
        totalVolume
        filteredItems {
          id
          name
          takeCount
          volume
          itemType {
            id
            kind
          }
        }
      }
    }
    cartonCategories {
      id
      category {
        id
        name
      }
      collection {
        id
        takeCount
        totalWeight
        totalVolume
        filteredItems {
          id
          itemTypeId
          name
          takeCount
          volume
          collection {
            id
            room {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default DocumentInventorySummary;
