/*
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {FlatList, Icon, Loading, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useHover,
  useMountEffect,
  useNavigationDOM,
  useQuery,
  useResponsive,
} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import InAppNotificationForm from '@shared/modules/InAppNotification/forms/InAppNotificationForm';
import useMarkSingleReadInAppNotificationMutation from '@shared/modules/InAppNotification/hooks/useMarkSingleReadInAppNotificationMutation';
import useUpdateAllReadAtInAppNotificationsMutation from '@shared/modules/InAppNotification/hooks/useUpdateAllReadAtInAppNotificationsMutation';
import useUpdateAllSeenAtInAppNotificationsMutation from '@shared/modules/InAppNotification/hooks/useUpdateAllSeenAtInAppNotificationsMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const Content = Styled.Touchable`
  padding: 12px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
`;

const TitleRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const Circle = Styled.Touchable`
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: ${colors.blue.interactive};
`;

const Title = Styled.H8`
  color: ${colors.gray.primary};
  text-transform: uppercase;
  ${fontWeight(700)}
`;

const Description = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const TimeStamp = Styled.H8`
  color: ${({vars}) => (vars.readAt ? colors.gray.tertiary : colors.blue.interactive)};
  ${fontWeight(500)}
`;

const NoNotificationContainer = Styled.View`
  justify-content: center;
  align-items: center;
  height: 420px;
`;

const NoNotificationText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const NoNotificationSubText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const NoNotificationUrl = Styled.H7`
  color: ${colors.blue.interactive};
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
  ${fontWeight(500)}
`;

const Indicator = Styled.Loading`
  padding: 40px;
`;

const Header = Styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-horizontal: 10px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
`;

const HeaderItem = Styled.Touchable`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-vertical: 8px;
`;

const MarkAllReadText = Styled.H7`
  color: ${colors.gray.tertiary};
  ${fontWeight(500)}
  text-decoration: underline;
  text-decoration-color: ${colors.gray.tertiary};
`;

const NotificationContainer = Styled.View`
  align-items: center;
  justify-content: center;
`;

const NotificationText = Styled.H7`
  background-color: ${colors.red.warning};
  border-radius: 4px;
  text-align: center;
  ${fontWeight(700)}
  color: ${colors.white};
  padding-horizontal: 4px;
`;

const AllText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const IconTouchable = Styled.Touchable`
`;

const MobileHeader = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 6px;
  padding-horizontal: 10px;
`;

const EmptySpace = Styled.View`
  flex: 1;
`;

const NotificationsTitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const getRoute = ({url}) => {
  // search = params. Will be an empty string if no params. If params exist,
  // it will be a string of params as expected in the url, starting with a '?'.
  const {pathname, search} = new URL(url);
  return `${pathname}${search}`;
};

const InAppNotification = ({inAppNotification, onPressNotification, refetch}) => {
  const inAppNotificationForm = InAppNotificationForm.new({
    inAppNotificationId: inAppNotification.id,
  });
  const {submitting, handleSubmit} = useMarkSingleReadInAppNotificationMutation({
    inAppNotificationForm,
    onSuccess: () => {
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  const {isHovered, ref} = useHover();
  const {navigator} = useNavigationDOM();

  return (
    <Content
      ref={ref}
      isHovered={isHovered}
      onPress={() => {
        handleSubmit();
        onPressNotification && onPressNotification();
        return navigator.push(getRoute({url: inAppNotification.url}));
      }}
    >
      {submitting ? (
        <Indicator />
      ) : (
        <React.Fragment>
          <TitleRow>
            <Title>{inAppNotification.title}</Title>
            {!inAppNotification.readAt && <Circle onPress={handleSubmit} />}
          </TitleRow>
          <Space height={2} />
          <Description>{inAppNotification.body}</Description>
          <Space height={8} />
          <TimeStamp vars={{readAt: inAppNotification.readAt}}>
            {!!inAppNotification.sentAt &&
              Datetime.fromDatetime(inAppNotification.sentAt).calendar()}
          </TimeStamp>
        </React.Fragment>
      )}
    </Content>
  );
};

const NoNotificationsContent = () => {
  const {navigator} = useNavigationDOM();

  return (
    <NoNotificationContainer>
      <NoNotificationText>You haven't received any notifications.</NoNotificationText>
      <NoNotificationSubText>
        {'Manage your notifications in the '}
        <NoNotificationUrl onPress={() => navigator.push('/account/profile/notifications')}>
          {'settings page'}
        </NoNotificationUrl>
      </NoNotificationSubText>
    </NoNotificationContainer>
  );
};

const NotificationHeader = ({receiverId, unreadNotificationsCount, refetch}) => {
  const {submitting, handleSubmit} = useUpdateAllReadAtInAppNotificationsMutation({
    receiverId,
    onSuccess: () => {
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  const {navigator} = useNavigationDOM();

  return (
    <Header>
      <HeaderItem
        style={{borderBottomWidth: 1, borderBottomColor: colors.gray.primary, paddingBottom: '7px'}}
      >
        <AllText>All</AllText>
        {unreadNotificationsCount > 0 && (
          <React.Fragment>
            <Space width={4} />
            <NotificationContainer>
              <NotificationText>{unreadNotificationsCount}</NotificationText>
            </NotificationContainer>
          </React.Fragment>
        )}
      </HeaderItem>
      <HeaderItem style={{flex: 1}} />
      <HeaderItem disabled={submitting} onPress={handleSubmit}>
        <MarkAllReadText>Mark All Read</MarkAllReadText>
        {submitting && (
          <React.Fragment>
            <Space width={4} />
            <Indicator style={{padding: 0}} />
          </React.Fragment>
        )}
      </HeaderItem>
      <Space width={8} />
      <HeaderItem
        onPress={() => {
          navigator.push('/account/profile/notifications');
        }}
      >
        <Icon color={colors.gray.tertiary} size={Icon.Sizes.Medium} source={Icon.Cog} />
      </HeaderItem>
    </Header>
  );
};

const NotificationPopoverContent = ({
  receiverId,
  inAppNotifications,
  onPressNotification,
  refetch,
  refetchCount,
}) => {
  const {handleSubmit} = useUpdateAllSeenAtInAppNotificationsMutation({
    receiverId,
    onError: (errors) => {
      console.log({errors});
    },
    onSuccess: () => {
      refetchCount();
    },
  });

  useMountEffect(() => {
    handleSubmit();
  });

  return (
    <FlatList
      data={inAppNotifications}
      keyExtractor={(inAppNotification) => inAppNotification.id}
      renderItem={({item: inAppNotification}) => {
        return (
          <InAppNotification
            inAppNotification={inAppNotification}
            onPressNotification={onPressNotification}
            refetch={refetch}
          />
        );
      }}
      ListEmptyComponent={NoNotificationsContent}
    />
  );
};

const NotificationPopover = ({isOpen, handleOpen, handleClose, popoverRef, refetchCount}) => {
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(NotificationPopover.query, {
    skip: !isOpen,
    fetchPolicy: 'cache-and-network',
  });

  return (
    <ResponsivePopover
      placement={Popover.Positions.BottomEnd}
      isOpen={isOpen}
      handleOpen={handleOpen}
      handleClose={handleClose}
      reference={popoverRef}
      offset={[0, 4]}
    >
      <ResponsivePopover.Container backgroundColor={colors.white} width={380} maxHeight={420}>
        <Loading alwaysUpdate loading={loading || !data} as={Indicator}>
          {() => {
            return (
              <React.Fragment>
                {responsive.mobile && (
                  <MobileHeader>
                    <EmptySpace />
                    <NotificationsTitle>Notifications</NotificationsTitle>
                    <EmptySpace />
                    <IconTouchable onPress={handleClose}>
                      <Icon color={colors.black} size={Icon.Sizes.Large} source={Icon.Times} />
                    </IconTouchable>
                  </MobileHeader>
                )}
                <NotificationHeader
                  receiverId={data.viewer.id}
                  unreadNotificationsCount={data.viewer.unreadNotificationsCount}
                  refetch={refetch}
                  handleClose={handleClose}
                />
                <NotificationPopoverContent
                  receiverId={data.viewer.id}
                  inAppNotifications={data.viewer.inAppNotifications}
                  refetch={refetch}
                  refetchCount={refetchCount}
                />
              </React.Fragment>
            );
          }}
        </Loading>
      </ResponsivePopover.Container>
    </ResponsivePopover>
  );
};

NotificationPopover.Content = NotificationPopoverContent;

// --------------------------------------------------
// Data
// --------------------------------------------------
NotificationPopover.Content.fragment = gql`
  fragment NotificationPopover_Content on InAppNotification {
    id
    body
    title
    readAt
    sentAt
    url
  }
`;

NotificationPopover.query = gql`
  ${NotificationPopover.Content.fragment}

  query NotificationPopover {
    ${gql.query}
    viewer {
      id
      unreadNotificationsCount
      inAppNotifications {
        id
        ...NotificationPopover_Content
      }
    }
  }
`;

export default NotificationPopover;
