/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const DateRangeDayContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-width: 1px;
  border-radius: 20px;
  border-color: ${colors.gray.border};
  background-color: ${colors.gray.border};
`;

const DateRangeDayText = Styled.Text`
  ${Typography.Label4}
  color: ${colors.gray.primary};
`;

const JobEstimatedRangeBadge = ({job, date, style}) => {
  return (
    <TextTooltip text={Job.getDisplayDate(job, 'MM/DD')}>
      <DateRangeDayContainer style={style}>
        <DateRangeDayText>{Job.getEstimatedRangeJobCounter(job, date)}</DateRangeDayText>
      </DateRangeDayContainer>
    </TextTooltip>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobEstimatedRangeBadge.fragment = gql`
  ${Job.getDisplayDate.fragment}
  ${Job.getEstimatedRangeJobCounter.fragment}
  fragment JobEstimatedRangeBadge on Job {
    id
    ...Job_getDisplayDate
    ...Job_getEstimatedRangeJobCounter
  }
`;

export default JobEstimatedRangeBadge;
