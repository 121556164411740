// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Popover, Space} from '@supermove/components';
import {generated, gql} from '@supermove/graphql';
import {usePopover, useQuery, useResponsive, useState} from '@supermove/hooks';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownInputComponent from '@shared/design/components/MultiDropdownInput';
import PopoverFilter from '@shared/modules/App/components/PopoverFilter';
import {GlobalDashboardsLibraryPageUrlFilters} from 'modules/Report/Library/components/types';

interface GlobalDashboardsLibraryPageGlobalDashboardsFilterProps {
  urlFilters: GlobalDashboardsLibraryPageUrlFilters;
  handleApply: (props: {categoryIds: string[]; tagIds: string[]}) => void;
}

const toArray = (value: string[] | string | undefined) => {
  return value ? _.flatten([value]) : [];
};

const GlobalDashboardsLibraryPageGlobalDashboardsFilter = ({
  urlFilters,
  handleApply,
}: GlobalDashboardsLibraryPageGlobalDashboardsFilterProps) => {
  const filterPopover = usePopover({
    name: 'Global dashboards library page global dashboards filter modal popover',
  });

  const filtersCount = urlFilters.getFilterCount({
    filterKeys: ['categoryIds', 'tagIds'],
  });

  return (
    <React.Fragment>
      <Popover.Content innerRef={filterPopover.ref}>
        <SecondaryButton
          text={`Filters ${filtersCount ? `(${filtersCount})` : ''}`}
          onPress={filterPopover.handleOpen}
          iconRight={Icon.AngleDown}
          iconSize={12}
          isSmall
          isWidthOfContainer
          isResponsive
        />
      </Popover.Content>
      <GlobalDashboardsLibraryPageGlobalDashboardsFilterPopover
        selectedCategoryIds={urlFilters.get('categoryIds')}
        selectedTagIds={urlFilters.get('tagIds')}
        popover={filterPopover}
        handleApply={handleApply}
      />
    </React.Fragment>
  );
};

interface GlobalDashboardsLibraryPageGlobalDashboardsFilterPopoverProps {
  popover: ReturnType<typeof usePopover>;
  selectedCategoryIds: string[] | undefined;
  selectedTagIds: string[] | undefined;
  handleApply: GlobalDashboardsLibraryPageGlobalDashboardsFilterProps['handleApply'];
}

const GlobalDashboardsLibraryPageGlobalDashboardsFilterPopover = ({
  popover,
  selectedCategoryIds,
  selectedTagIds,
  handleApply,
}: GlobalDashboardsLibraryPageGlobalDashboardsFilterPopoverProps) => {
  const responsive = useResponsive();

  const [newSelectedCategoryIds, setNewSelectedCategoryIds] = useState(
    toArray(selectedCategoryIds),
  );
  const [newSelectedTagIds, setNewSelectedTagIds] = useState(toArray(selectedTagIds));

  const {data, loading} =
    useQuery<generated.GlobalDashboardsLibraryPageGlobalDashboardsFilterQuery>(
      GlobalDashboardsLibraryPageGlobalDashboardsFilter.query,
      {
        fetchPolicy: 'cache-and-network',
        skip: !popover.isOpen,
      },
    );

  const filtersCount = (newSelectedCategoryIds.length ? 1 : 0) + (newSelectedTagIds.length ? 1 : 0);

  return (
    <PopoverFilter
      activeFiltersCount={filtersCount}
      popover={popover}
      responsive={responsive}
      handleApply={() => {
        handleApply({
          categoryIds: newSelectedCategoryIds,
          tagIds: newSelectedTagIds,
        });

        popover.handleClose();
      }}
      handleClear={() => {
        setNewSelectedCategoryIds([]);
        setNewSelectedTagIds([]);

        handleApply({
          categoryIds: [],
          tagIds: [],
        });

        popover.handleClose();
      }}
    >
      <Loading loading={loading || !data}>
        {() => (
          <GlobalDashboardsLibraryPageGlobalDashboardsFilterBody
            dashboardCategories={data?.dashboardCategories || []}
            dashboardTags={data?.dashboardTags || []}
            newSelectedCategoryIds={newSelectedCategoryIds}
            newSelectedTagIds={newSelectedTagIds}
            setNewSelectedCategoryIds={setNewSelectedCategoryIds}
            setNewSelectedTagIds={setNewSelectedTagIds}
          />
        )}
      </Loading>
    </PopoverFilter>
  );
};

interface GlobalDashboardsLibraryPageGlobalDashboardsFilterBodyProps {
  dashboardCategories: NonNullable<
    generated.GlobalDashboardsLibraryPageGlobalDashboardsFilterQuery['dashboardCategories']
  >;
  dashboardTags: NonNullable<
    generated.GlobalDashboardsLibraryPageGlobalDashboardsFilterQuery['dashboardTags']
  >;
  newSelectedCategoryIds: string[];
  newSelectedTagIds: string[];
  setNewSelectedCategoryIds: (value: string[]) => void;
  setNewSelectedTagIds: (value: string[]) => void;
}

const GlobalDashboardsLibraryPageGlobalDashboardsFilterBody = ({
  dashboardCategories,
  dashboardTags,
  newSelectedCategoryIds,
  newSelectedTagIds,
  setNewSelectedCategoryIds,
  setNewSelectedTagIds,
}: GlobalDashboardsLibraryPageGlobalDashboardsFilterBodyProps) => {
  const sortedCategoryOptions = dashboardCategories
    .map((category) => ({
      label: category?.name || '',
      value: category?.id || '',
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const sortedTagOptions = dashboardTags
    .map((tag) => ({
      label: tag?.name || '',
      value: tag?.id || '',
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <React.Fragment>
      <FieldInput
        index={0}
        component={MultiDropdownInputComponent}
        label={'Category'}
        input={{
          options: sortedCategoryOptions,
          name: 'Category',
          value: newSelectedCategoryIds,
          setFieldValue: (name: string, value: string[]) => setNewSelectedCategoryIds(value),
          placeholder: 'Select categories',
          style: {flex: 1},
          isResponsive: true,
        }}
      />
      <Space height={16} />
      <FieldInput
        index={1}
        component={MultiDropdownInputComponent}
        label={'Tag'}
        input={{
          options: sortedTagOptions,
          name: 'Tag',
          value: newSelectedTagIds,
          setFieldValue: (name: string, value: string[]) => setNewSelectedTagIds(value),
          placeholder: 'Select tags',
          style: {flex: 1},
          isResponsive: true,
        }}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalDashboardsLibraryPageGlobalDashboardsFilter.query = gql`
  query GlobalDashboardsLibraryPageGlobalDashboardsFilter {
    ${gql.query}
    dashboardCategories {
        id
        name
    }
    dashboardTags {
        id
        name
    }
  }
`;

export default GlobalDashboardsLibraryPageGlobalDashboardsFilter;
