/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import CheckboxField from 'modules/App/components/CheckboxField';
import ProjectJobSection from 'modules/Project/Update/components/ProjectJobSection';

const EmptySpace = Styled.View`
  flex: 1;
`;

const SectionTitle = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const FieldGroupContainer = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  z-index: ${({index = 0}) => 100 - index};
`;

const AdditionaItemField = ({name, label, type, input, form, style}) => {
  switch (type) {
    case 'checkbox':
      return <CheckboxField {...form} name={name} label={label} input={input} style={style} />;
    case 'text':
      return (
        <FieldInput.Memoized {...form} name={name} label={label} input={input} style={style} />
      );
    default:
      return null;
  }
};

const ProjectJobAdditionalInfoBlockFields = ({form, jobField, organization}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {organization.features.isEnabledJobHasPackingField && (
        <FieldGroupContainer {...responsive} index={1}>
          <CheckboxField
            {...form}
            name={`${jobField}.hasPacking`}
            label={'Requires Packing?'}
            style={{
              marginTop: 5,
              marginBottom: 10,
            }}
          />
        </FieldGroupContainer>
      )}
      {_.map(form.values.jobFormAdditionalItems, (value, item, index) => {
        if (!value) {
          return null;
        }

        const input = _.get(value, 'input', {});
        const multiline = input.multiline || false;
        const name = `${jobField}.additionalItems.${item}`;

        return (
          <React.Fragment key={item}>
            <FieldGroupContainer {...responsive}>
              <AdditionaItemField
                name={name}
                label={_.get(value, 'label')}
                type={_.get(value, 'type')}
                input={{
                  ...input,
                  style: {
                    ...(multiline ? {paddingVertical: 9, minHeight: 60} : {}),
                    flex: 1,
                  },
                }}
                style={{flex: 1}}
                form={form}
              />
            </FieldGroupContainer>
            <Space height={8} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const ProjectJobAdditionalInfoBlock = ({index, form, jobField, organization}) => {
  if (!form.values.jobFormAdditionalItems && !organization.features.isEnabledJobHasPackingField) {
    return null;
  }

  return (
    <ProjectJobSection
      index={index}
      title={
        <React.Fragment>
          <SectionTitle>Additional Information</SectionTitle>
          <EmptySpace />
          <Space width={8} />
        </React.Fragment>
      }
    >
      <ProjectJobAdditionalInfoBlockFields
        form={form}
        jobField={jobField}
        organization={organization}
      />
    </ProjectJobSection>
  );
};

ProjectJobAdditionalInfoBlock.Fields = ProjectJobAdditionalInfoBlockFields;

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobAdditionalInfoBlock.fragment = gql`
  fragment ProjectJobAdditionalInfoBlock on Organization {
    id
    features {
      isEnabledJobHasPackingField: isEnabled(feature: "JOB_HAS_PACKING_FIELD")
    }
  }
`;

export default ProjectJobAdditionalInfoBlock;
