// Supermove

const _new = ({invoiceId}: any) => ({
  invoiceId,
});

const toForm = ({invoiceId}: any) => {
  return {
    invoiceId,
  };
};

const toMutation = ({invoiceId}: any) => {
  return {
    invoiceId,
  };
};

const DequeueInvoiceForm = {
  new: _new,
  toForm,
  toMutation,
};

export default DequeueInvoiceForm;
