// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteCommercialEquipmentTypeMutation = ({
  commercialEquipmentTypeId,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      commercialEquipmentTypeId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteCommercialEquipmentTypeMutation.mutation,
    variables: {
      commercialEquipmentTypeId: form.values.commercialEquipmentTypeId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteCommercialEquipmentTypeMutation.mutation = gql`
  mutation useDeleteCommercialEquipmentTypeMutation($commercialEquipmentTypeId: Int!) {
    response: deleteCommercialEquipmentType(commercialEquipmentTypeId: $commercialEquipmentTypeId) {
      ${gql.errors}
    }
  }
`;

export default useDeleteCommercialEquipmentTypeMutation;
