// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {DropdownInput, PhoneInput, Space, Styled} from '@supermove/components';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import UserRole from '@shared/modules/User/enums/UserRole';

const Container = Styled.View`
`;

const NameField = ({form, field, isDisabled}) => {
  return (
    <React.Fragment>
      <FieldInput.Memoized
        {...form}
        index={0}
        label={'First Name'}
        name={`${field}.names.0`}
        isRequired={!isDisabled}
        input={{
          autoFocus: true,
          placeholder: 'Enter first name',
        }}
      />
      <Space height={16} />
      <FieldInput.Memoized
        {...form}
        index={0}
        label={'Last Name'}
        name={`${field}.names.1`}
        isRequired={!isDisabled}
        input={{
          placeholder: 'Enter last name',
        }}
      />
    </React.Fragment>
  );
};

const PositionField = ({form, field, isDisabled}) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={1}
      label={'Position'}
      name={`${field}.position`}
      isRequired={!isDisabled}
      input={{
        disabled: isDisabled,
        placeholder: 'Enter position',
      }}
    />
  );
};

const RoleField = ({form, field, isDisabled, isEnabledOperationsAdmins, viewerRole}) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={2}
      component={DropdownInput}
      name={`${field}.role`}
      label={'Supermove Role'}
      isRequired={!isDisabled}
      input={{
        disabled: isDisabled,
        options: UserRole.getDropdownOptions({isEnabledOperationsAdmins, viewerRole}),
        setFieldValue: form.setFieldValue,
        placeholder: 'Select a role',
        style: {
          width: '100%',
        },
      }}
    />
  );
};

const PhoneNumberField = ({form, field, isDisabled}) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={3}
      label={'Phone Number'}
      name={`${field}.phoneNumber`}
      component={PhoneInput}
      input={{
        setFieldValue: form.setFieldValue,
        placeholder: isDisabled ? '' : 'Enter phone number',
        disabled: isDisabled,
      }}
    />
  );
};

const EmailField = ({form, field, isDisabled}) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={4}
      label={'Email'}
      name={`${field}.email`}
      isRequired={!isDisabled}
      input={{
        placeholder: 'Enter email',
        disabled: isDisabled,
      }}
    />
  );
};

const ActiveStatusField = ({form, field, isDisabled}) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={5}
      label={'Status'}
      name={`${field}.isActive`}
      component={DropdownInput}
      isRequired={!isDisabled}
      input={{
        disabled: isDisabled,
        setFieldValue: form.setFieldValue,
        options: [
          {value: true, label: 'Active'},
          {value: false, label: 'Inactive'},
        ],
        style: {width: '100%'},
      }}
    />
  );
};

const UserProfileDrawerFields = ({
  form,
  field,
  isDisabled,
  isEnabledStatus,
  showStatus,
  isEnabledOperationsAdmins,
  viewerRole,
}) => {
  return (
    <Container>
      <NameField form={form} field={field} isDisabled={isDisabled} />
      <Space height={16} />
      <PositionField form={form} field={field} isDisabled={isDisabled} />
      {UserRole.ADMIN_ROLES_PLUS_SUPER.includes(viewerRole) && (
        <React.Fragment>
          <Space height={16} />
          <RoleField
            form={form}
            field={field}
            isDisabled={isDisabled}
            isEnabledOperationsAdmins={isEnabledOperationsAdmins}
            viewerRole={viewerRole}
          />
        </React.Fragment>
      )}
      <Space height={16} />
      <PhoneNumberField form={form} field={field} isDisabled={isDisabled} />
      <Space height={16} />
      <EmailField form={form} field={field} isDisabled={isDisabled} />
      {(showStatus || isEnabledStatus) && (
        <React.Fragment>
          <Space height={16} />
          <ActiveStatusField
            form={form}
            field={field}
            isDisabled={isDisabled || !isEnabledStatus}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
UserProfileDrawerFields.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isEnabledStatus: PropTypes.bool,
  showStatus: PropTypes.bool,
};

UserProfileDrawerFields.defaultProps = {
  isDisabled: false,
  isEnabledStatus: false,
  showStatus: true,
};

export default UserProfileDrawerFields;
