// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Form} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Datetime} from '@supermove/utils';

// App
import SignConfirmationDocumentMutation from 'modules/Customer/Project/Confirmation/Document/components/SignConfirmationDocumentMutation';

const SignProjectConfirmationDocumentForm = ({project, onSuccess, onError, children}) => (
  <Form
    initialValues={{
      isFinished: false,
      isSignatureValid: false,
      // Mutation fields.
      confirmationId: project.confirmation.id,
      date: Datetime.toDate(Datetime.today),
      image: null,
    }}
    onSubmit={() => {}}
  >
    {({values, submitForm, setMutationErrors, ...form}) => (
      <SignConfirmationDocumentMutation
        values={values}
        onSuccess={() => form.setFieldValue('isFinished', true)}
        onError={onError}
        setMutationErrors={setMutationErrors}
        submitForm={submitForm}
      >
        {({loading, handleSubmit}) =>
          children({
            ...form,
            loading,
            values,
            handleSubmit,
          })
        }
      </SignConfirmationDocumentMutation>
    )}
  </Form>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
SignProjectConfirmationDocumentForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

SignProjectConfirmationDocumentForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SignProjectConfirmationDocumentForm.fragment = gql`
  fragment SignProjectConfirmationDocumentForm on Project {
    id
    confirmation {
      id
      isComplete
      nextStep {
        kind
        value
      }
    }
  }
`;

export default SignProjectConfirmationDocumentForm;
