// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment} from '@supermove/utils';

const _new = ({moverPositionId, moverPositionName}: any) => ({
  moverPositionId,
  amount: '',
  amountFormulaId: null,
  externalInvoiceItemId: null,
});

const edit = withFragment(
  (childBillItemType) => ({
    moverPositionId: (childBillItemType as any).moverPositionId,
    amount: (childBillItemType as any).amount,
    amountFormulaId: (childBillItemType as any).amountFormulaId,
    externalInvoiceItemId: (childBillItemType as any).externalInvoiceItem?.id,
  }),
  gql`
    fragment BillItemTypeMoverPositionRateForm_edit on BillItemType {
      moverPositionId
      amount
      amountFormulaId
      externalInvoiceItem {
        id
      }
    }
  `,
);

const toForm = ({moverPositionId, amount, amountFormulaId, externalInvoiceItemId}: any) => {
  return {
    moverPositionId,
    amount: Currency.toForm(amount),
    amountFormulaId,
    externalInvoiceItemId,
  };
};

const toMutation = ({moverPositionId, amount, amountFormulaId, externalInvoiceItemId}: any) => {
  return {
    moverPositionId,
    amount: amountFormulaId ? 0 : Currency.toMutation(amount),
    amountFormulaId,
    externalInvoiceItemId,
  };
};

const BillItemTypeMoverPositionRateForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default BillItemTypeMoverPositionRateForm;
