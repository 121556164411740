// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import Modal from '@shared/design/components/Modal';
import JobForm from '@shared/modules/Job/forms/JobForm';

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  z-index: ${({index = 0}) => 100 - index};
`;

const FieldSpace = () => {
  return <Space height={12} width={8} />;
};

const CrewNotesRow = ({form, field, index}) => {
  return (
    <FieldInput.Memoized
      {...form}
      name={`${field}.additionalNotes`}
      label={'Crew Notes'}
      index={index}
      input={{
        placeholder: 'Enter details for crew',
        style: {minHeight: 76, paddingTop: 8},
        multiline: true,
      }}
    />
  );
};

const DispatchNotesRow = ({form, field, index}) => {
  return (
    <FieldInput.Memoized
      {...form}
      name={`${field}.dispatchNotes`}
      label={'Dispatch Notes'}
      index={index}
      input={{
        placeholder: 'Enter details for dispatch',
        style: {minHeight: 76, paddingTop: 8},
        multiline: true,
      }}
    />
  );
};

const TrucksAndMoversRow = ({form, field, index, organization}) => {
  const responsive = useResponsive();
  const {isEnabledCrewSizeIsRequired} = organization.features;
  const {isMoversInputVisible, isTrucksInputVisible} = JobForm.getDispatchFieldVisibility(
    _.get(form.values, field),
  );

  if (!isMoversInputVisible && !isTrucksInputVisible) {
    return null;
  }

  return (
    <Row index={index} {...responsive}>
      {isTrucksInputVisible && (
        <FieldInput.Memoized
          {...form}
          name={`${field}.numberOfTrucks`}
          label={'Number of Trucks'}
          input={{
            required: !_.get(form.values, `${field}.numberOfTrucks`),
            placeholder: 'Number of Trucks',
            keyboardType: 'phone-pad',
          }}
          style={{flex: 1}}
        />
      )}
      {isTrucksInputVisible && isMoversInputVisible && <FieldSpace />}
      {isMoversInputVisible && (
        <FieldInput.Memoized
          {...form}
          name={`${field}.crewSize`}
          label={'Number of Movers'}
          input={{
            required: isEnabledCrewSizeIsRequired && !_.get(form.values, `${field}.crewSize`),
            placeholder: 'Number of Movers',
            keyboardType: 'phone-pad',
          }}
          style={{flex: 1}}
        />
      )}
    </Row>
  );
};

const ProjectJobDispatchFields = ({form, field, organization}) => {
  return (
    <React.Fragment>
      <Modal.BlockHeader>Dispatch</Modal.BlockHeader>
      <FieldSpace />
      <TrucksAndMoversRow form={form} field={field} index={0} organization={organization} />
      <FieldSpace />
      <CrewNotesRow form={form} field={field} index={1} />
      <FieldSpace />
      <DispatchNotesRow form={form} field={field} index={2} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobDispatchFields.fragment = gql`
  fragment ProjectJobDispatchFields on Organization {
    id
    features {
      isEnabledCrewSizeIsRequired: isEnabled(feature: "CREW_SIZE_IS_REQUIRED")
    }
  }
`;

export default ProjectJobDispatchFields;
