// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';

import TimeRangeForm from './TimeRangeForm';

const sortBy = ({timesheetJobUserPositionOrder, jobUsers, jobUserTimesheetForms}: any) => {
  const allPositions = jobUsers.map((jobUser: any) => jobUser.position);
  const allPositionCounts = _.countBy(allPositions);
  const getPositionIndex = ({jobUser}: any) => {
    // Adjust the index so that the ones not included on the list are at the END and sorted
    // by how common they are.
    const index = timesheetJobUserPositionOrder.indexOf(jobUser.position);

    // Position was found in the list.
    if (index >= 0) {
      return index;
    }

    // Otherwise, the more of that position there is, the further down the list it goes.
    const count = _.get(allPositionCounts, jobUser.position);
    return timesheetJobUserPositionOrder.length + count;
  };

  const jobUserSortFunctions = [
    (jobUser: any) => jobUser.branchCode,
    (jobUser: any) => getPositionIndex({jobUser}),
    (jobUser: any) => User.getFullName(jobUser.user),
  ];

  const sortedJobUsers = _.sortBy(jobUsers, jobUserSortFunctions);
  return _.map(sortedJobUsers, (jobUser) => {
    return _.find(jobUserTimesheetForms, (jobUserTimesheetForm) => {
      return jobUserTimesheetForm.jobUserId === jobUser.id;
    });
  });
};

sortBy.fragment = gql`
  fragment JobUserTimesheetForm_sortBy on JobUser {
    id
    branchCode
    position
    user {
      id
      firstName
      lastName
    }
  }
`;

const edit = (jobUser: any) => ({
  // When the form is created, default select all users.
  isSelected: true,

  jobUserId: jobUser.id,
  timeRangeForms: jobUser.timesheet.timeRanges.map((timeRange: any) =>
    TimeRangeForm.edit(timeRange),
  ),
});

edit.fragment = gql`
  ${TimeRangeForm.edit.fragment}

  fragment JobUserTimesheetForm_edit on JobUser {
    id
    timesheet {
      timeRanges {
        ...TimeRangeForm_edit
      }
    }
  }
`;

const JobUserTimesheetForm = {
  toForm: ({isSelected, jobUserId, timeRangeForms}: any) => ({
    isSelected,
    jobUserId,
    timeRangeForms: timeRangeForms.map((timeRangeForm: any) => TimeRangeForm.toForm(timeRangeForm)),
  }),

  // NOTE(mark): isSelected is ignored and not submitted to the server.
  toMutation: ({isSelected, jobUserId, timeRangeForms}: any) => ({
    jobUserId,
    timeRangeForms: timeRangeForms.map((timeRangeForm: any) => {
      return TimeRangeForm.toMutation(timeRangeForm);
    }),
  }),

  edit,
  sortBy,
};

export default JobUserTimesheetForm;
