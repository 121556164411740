// DEPRECATED

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const UpdateSuperAdminImpersonateMutation = ({user, onSuccess, onError, children}) => (
  <Mutation
    variables={{
      userId: user ? user.id : null,
    }}
    mutation={UpdateSuperAdminImpersonateMutation.mutation}
    onCompleted={({response: {viewer, errors}}) => {
      if (viewer) {
        onSuccess(viewer);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateSuperAdminImpersonateMutation.propTypes = {
  user: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

UpdateSuperAdminImpersonateMutation.defaultProps = {
  user: null,
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateSuperAdminImpersonateMutation.mutation = gql`
  mutation UpdateSuperAdminImpersonateMutation(
    $userId: Int,
  ) {
    response: updateSuperAdminImpersonate(
      userId: $userId,
    ) {
      ${gql.errors}
      viewer {
        id
        role
      }
    }
  }
`;

export default UpdateSuperAdminImpersonateMutation;
