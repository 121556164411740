// Confirmation steps
const CONFIRMATION = 'CONFIRMATION';
const QUOTE = 'QUOTE';
const SEND_CONFIRMATION = 'SEND_CONFIRMATION';
const SIGN_CONFIRMATION = 'SIGN_CONFIRMATION';

// These steps are what is used on the Crew app.
const PRE_MOVE = 'PRE_MOVE';
const AFTER_TIMESHEET = 'AFTER_TIMESHEET';
const POST_MOVE = 'POST_MOVE';

// Optional documents on the Crew app.
const DURING_MOVE = 'DURING_MOVE';

// Custom documents are generated on the Office app.
const CUSTOM = 'CUSTOM';

const DocumentStepKind = {
  CONFIRMATION,
  QUOTE,
  SEND_CONFIRMATION,
  SIGN_CONFIRMATION,
  PRE_MOVE,
  AFTER_TIMESHEET,
  POST_MOVE,
  DURING_MOVE,
  CUSTOM,

  CONFIRMATION_STEPS: [CONFIRMATION, SEND_CONFIRMATION, SIGN_CONFIRMATION],
  QUOTE_STEPS: [QUOTE],
  CREW_APP_STEPS: [PRE_MOVE, AFTER_TIMESHEET, POST_MOVE, DURING_MOVE],
  CUSTOM_STEPS: [CUSTOM],
};

export default DocumentStepKind;
