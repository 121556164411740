// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import TeamMemberForm from '@shared/modules/TaskManagement/forms/TeamMemberForm';

const _new = ({organizationId, teamKind}: any) => ({
  teamId: null,
  organizationId,
  name: '',
  kind: teamKind,
  teamMemberForms: [],

  // Private
  teamMemberUserIds: [],
});

const _delete = ({teamId}: any) => ({
  ..._new({}),
  teamId,
});

const edit = withFragment(
  (team) => ({
    teamId: (team as any).id,
    organizationId: (team as any).organizationId,
    name: (team as any).name,
    kind: (team as any).kind,
    teamMemberForms: (team as any).teamMembers.map((teamMember: any) =>
      TeamMemberForm.edit(teamMember),
    ),
    // Private
    teamMemberUserIds: (team as any).teamMembers.map((teamMember: any) => teamMember.userId),
  }),
  gql`
    ${TeamMemberForm.edit.fragment}
    fragment TeamForm_edit on Team {
      id
      organizationId
      name
      kind
      teamMembers {
        id
        userId
        ...TeamMemberForm_edit
      }
    }
  `,
);

const toForm = ({teamId, organizationId, name, kind, teamMemberForms, teamMemberUserIds}: any) => ({
  teamId,
  organizationId,
  name,
  kind,
  teamMemberForms: teamMemberForms.map((teamMemberForm: any) =>
    TeamMemberForm.toForm(teamMemberForm),
  ),

  // Private
  teamMemberUserIds: teamMemberUserIds.map((id: any) => _.toString(id)),
});

const toMutation = ({teamId, organizationId, name, kind, teamMemberForms}: any) => {
  return {
    teamId,
    organizationId,
    name,
    kind,
    teamMemberForms: teamMemberForms.map((teamMemberForm: any) =>
      TeamMemberForm.toMutation(teamMemberForm),
    ),
  };
};

const TaskForm = {
  new: _new,
  delete: _delete,
  edit,
  toForm,
  toMutation,
};

export default TaskForm;
