// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {HTML} from '@supermove/utils';

// Components

const SendCustomerReportMoveReceiptMutation = ({
  values,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}) => {
  return (
    <Mutation
      variables={{
        jobId: values.jobId,
        body: HTML.fixRichTextEditor(values.body),
        subject: values.subject,
        attachmentIds: values.attachmentIds,
      }}
      mutation={SendCustomerReportMoveReceiptMutation.mutation}
      onCompleted={({response: {job, errors}}) => {
        submitForm();
        setMutationErrors(errors);

        if (!errors) {
          onSuccess(job);
        } else if (onError) {
          onError(errors);
        }
      }}
    >
      {(handleSubmit, {loading, error}) =>
        children({
          loading,
          handleSubmit,
        })
      }
    </Mutation>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
SendCustomerReportMoveReceiptMutation.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  setMutationErrors: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

SendCustomerReportMoveReceiptMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendCustomerReportMoveReceiptMutation.mutation = gql`
  mutation SendCustomerReportMoveReceiptMutation(
    $jobId: Int!,
    $body: String!,
    $subject: String!,
    $attachmentIds: [String]!,
  ) {
    response: sendCustomerReportMoveReceipt(
      jobId: $jobId,
      body: $body,
      subject: $subject,
      attachmentIds: $attachmentIds,
    ) {
      ${gql.errors}
      job {
        id
        uuid
      }
    }
  }
`;

export default SendCustomerReportMoveReceiptMutation;
