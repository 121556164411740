// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useDrawer, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import AddTipDrawer from 'modules/Project/Billing/components/AddTipDrawer';

const Row = Styled.View`
  flex-direction: row;
`;

const Text = Styled.Text`
  ${Typography.Responsive.Label}
`;

const BillTipsContainer = Styled.View<{isEditable?: boolean}>`
  padding: ${({isEditable}) => (isEditable ? 12 : 0)}px;
  border-radius: ${({isEditable}) => (isEditable ? 8 : 0)}px;
  border-width: ${({isEditable}) => (isEditable ? 1 : 0)}px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const TotalTip = ({bill, responsive}: {bill: any; responsive: ResponsiveType}) => {
  return (
    <Row>
      <Text responsive={responsive} numberOfLines={1}>
        Total Tip
      </Text>
      <Space flex={1} />
      <Text responsive={responsive}>
        {Currency.format({value: bill.totalTip, shouldHideCentsIfZero: false})}
      </Text>
    </Row>
  );
};

const AddTip = ({
  projectUuid,
  responsive,
  refetch,
}: {
  projectUuid: string;
  responsive: ResponsiveType;
  refetch: () => void;
}) => {
  const addTipDrawer = useDrawer({name: 'Add Tip Drawer'});
  return (
    <Row>
      <Space flex={1} />
      <TertiaryButton
        iconLeft={Icon.Plus}
        text={'Add Tip'}
        onPress={addTipDrawer.handleOpen}
        isResponsive
      />
      <AddTipDrawer
        key={addTipDrawer.key}
        projectUuid={projectUuid}
        isOpen={addTipDrawer.isOpen}
        handleClose={addTipDrawer.handleClose}
        refetch={refetch}
      />
    </Row>
  );
};

interface BillTotalTipProps {
  bill: any;
  projectUuid: string;
  isEditable?: boolean;
  refetch: () => void;
}

const BillTotalTip = ({bill, projectUuid, isEditable, refetch}: BillTotalTipProps) => {
  const responsive = useResponsive();
  return (
    <BillTipsContainer
      isEditable={isEditable}
      style={responsive.mobile ? null : {width: 400, alignSelf: 'flex-end'}}
    >
      <TotalTip bill={bill} responsive={responsive} />
      {isEditable && (
        <React.Fragment>
          <Space height={16} />
          <AddTip projectUuid={projectUuid} refetch={refetch} responsive={responsive} />
        </React.Fragment>
      )}
    </BillTipsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillTotalTip.fragment = gql`
  fragment BillTotalTip on Bill {
    id
    totalTip
  }
`;

export default BillTotalTip;
