// Libraries
import React from 'react';

// Supermove
import {Loading, Styled, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import ValuationCoverageForm from '@shared/modules/Insurance/forms/ValuationCoverageForm';
import useUpdateValuationCoverageForm from '@shared/modules/Insurance/hooks/useUpdateValuationCoverageForm';
import JobValuationCoverageFields from '@shared/modules/Job/components/JobValuationCoverageFields';
import LargeModal from 'modules/App/Modal/components/LargeModal';

const Section = Styled.View`
  padding-horizontal: ${(props) => (props.mobile ? 20 : 30)}px;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const SectionSpace = Styled.View`
  margin-top: 20px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
`;

const Button = Styled.LoadingButton`
  height: 30px;
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const UpdateValuationCoverageModalContent = ({job, onSuccess}) => {
  const responsive = useResponsive();
  const valuationCoverageForm = ValuationCoverageForm.edit(job.valuationCoverage);
  const {form, submitting, handleSubmit} = useUpdateValuationCoverageForm({
    valuationCoverageForm,
    onSuccess,
    onError: (errors) => console.log({errors}),
  });

  return (
    <React.Fragment>
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 40,
        }}
      >
        <Section {...responsive} sectionIndex={0}>
          <Title>Edit Valuation Coverage</Title>
          <SectionSpace />
          <JobValuationCoverageFields form={form} field={'valuationCoverageForm'} job={job} />
        </Section>
      </ScrollView>
      <Section
        {...responsive}
        sectionIndex={5}
        style={{
          paddingVertical: 20,
          boxShadow: '0 -2px 2px rgba(0,0,0,0.1)',
        }}
      >
        <Button loading={submitting} onPress={handleSubmit}>
          <ButtonText>Update</ButtonText>
        </Button>
      </Section>
    </React.Fragment>
  );
};

const UpdateValuationCoverageModal = ({isOpen, jobUuid, handleClose, refetch}) => {
  const {loading, data} = useQuery(UpdateValuationCoverageModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      jobUuid,
    },
  });

  return (
    <LargeModal isOpen={isOpen} handleClose={handleClose}>
      <Loading loading={loading}>
        {() =>
          data ? (
            <UpdateValuationCoverageModalContent
              job={data.job}
              onSuccess={() => {
                handleClose();
                refetch();
              }}
            />
          ) : null
        }
      </Loading>
    </LargeModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateValuationCoverageModal.query = gql`
  ${JobValuationCoverageFields.fragment}
  ${ValuationCoverageForm.edit.fragment}

  query UpdateValuationCoverageModal($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      valuationCoverage {
        id
        ...ValuationCoverageForm_edit
      }
      ...JobValuationCoverageFields
    }
  }
`;

export default UpdateValuationCoverageModal;
