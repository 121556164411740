// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DragAndDropList, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDragAndDrop, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Panel from '@shared/design/components/Panel';
import ProjectTypeRoomTypeForm from '@shared/modules/Inventory/forms/ProjectTypeRoomTypeForm';
import ReorderProjectTypeRoomTypesForm from '@shared/modules/Inventory/forms/ReorderProjectTypeRoomTypesForm';
import useReorderProjectTypeRoomTypesMutation from '@shared/modules/Inventory/hooks/useReorderProjectTypeRoomTypesMutation';
import ProjectTypeRoomTypeItem from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeRoomTypeItem';

const Column = Styled.View`
  flex: 1;
`;

const onReorder = ({form, fromIndex, toIndex, handleSubmit}) => {
  const projectTypeRoomTypeForms = _.cloneDeep(
    form.values.reorderProjectTypeRoomTypesForm.projectTypeRoomTypeForms,
  );
  const reorderedProjectTypeRoomTypesForms = List.move({
    list: projectTypeRoomTypeForms,
    fromIndex,
    toIndex,
  });
  form.setFieldValue(
    'reorderProjectTypeRoomTypesForm.projectTypeRoomTypeForms',
    reorderedProjectTypeRoomTypesForms,
  );
  setTimeout(handleSubmit, 0);
};

const ProjectTypeRoomTypesPanel = ({index, projectType, refetch}) => {
  const [indexOfEdit, setIndexOfEdit] = useState(null);
  const isEditingProjectTypeRoomType = indexOfEdit !== null;
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const reorderProjectTypeRoomTypesForm = ReorderProjectTypeRoomTypesForm.edit(projectType);
  const {form, handleSubmit} = useReorderProjectTypeRoomTypesMutation({
    reorderProjectTypeRoomTypesForm,
    onSuccess: () => {
      refetch();
      handleReorderEnd();
    },
    onError: (errors) => {
      console.log(errors);
      handleReorderEnd();
    },
  });
  const hasProjectTypeRoomTypeForms = !_.isEmpty(
    form.values.reorderProjectTypeRoomTypesForm.projectTypeRoomTypeForms,
  );

  return (
    <Panel width={784} index={index}>
      <Panel.Header>
        <Column>
          <Panel.HeaderText>Default Rooms</Panel.HeaderText>
          <Space height={8} />
          <Panel.Text style={{color: colors.gray.secondary}}>
            {`These rooms will appear in every survey for a ${projectType.name}. You can edit and delete them in the survey.`}
          </Panel.Text>
        </Column>
      </Panel.Header>
      <Panel.Body>
        {hasProjectTypeRoomTypeForms && (
          <React.Fragment>
            <DragAndDropList
              isReordering={isReordering}
              onReorder={({fromIndex, toIndex}) => {
                handleReorderStart();
                onReorder({
                  form,
                  fromIndex,
                  toIndex,
                  handleSubmit,
                });
              }}
              indexOfEdit={indexOfEdit}
            >
              {form.values.reorderProjectTypeRoomTypesForm.projectTypeRoomTypeForms.map(
                (projectTypeRoomTypeForm, index) => {
                  return index === indexOfEdit ? (
                    <ProjectTypeRoomTypeItem.Edit
                      key={index}
                      index={index}
                      projectTypeRoomTypeForm={projectTypeRoomTypeForm}
                      refetch={refetch}
                      handleCloseEdit={({projectTypeRoomTypeId}) => {
                        // the projectTypeRoomTypeId will be null if a new project type room type was cancelled/not created
                        // in that case, we want to remove the form
                        if (!projectTypeRoomTypeId) {
                          const existingProjectTypeRoomTypeForms =
                            form.values.reorderProjectTypeRoomTypesForm.projectTypeRoomTypeForms;
                          form.setFieldValue(
                            'reorderProjectTypeRoomTypesForm.projectTypeRoomTypeForms',
                            [
                              ...existingProjectTypeRoomTypeForms.slice(0, index),
                              ...existingProjectTypeRoomTypeForms.slice(index + 1),
                            ],
                          );
                        }
                        setIndexOfEdit(null);
                      }}
                    />
                  ) : (
                    <ProjectTypeRoomTypeItem
                      key={index}
                      index={index}
                      projectTypeRoomTypeForm={projectTypeRoomTypeForm}
                      refetch={refetch}
                      setIndexOfEdit={setIndexOfEdit}
                      isEditingAnotherItem={isEditingProjectTypeRoomType}
                    />
                  );
                },
              )}
            </DragAndDropList>
            <Space height={16} />
          </React.Fragment>
        )}
        <DropdownButton
          isDisabled={isEditingProjectTypeRoomType}
          text={'Add Room'}
          actions={_.map(projectType.defaultInventoryLibrary.roomTypes, (roomType) => ({
            text: roomType.name,
            onPress: () => {
              // create project type room type
              const existingProjectTypeRoomTypeForms =
                form.values.reorderProjectTypeRoomTypesForm.projectTypeRoomTypeForms;
              form.setFieldValue('reorderProjectTypeRoomTypesForm.projectTypeRoomTypeForms', [
                ...existingProjectTypeRoomTypeForms,
                ProjectTypeRoomTypeForm.new({
                  projectTypeId: projectType.id,
                  roomTypeId: roomType.id,
                  name: roomType.name,
                }),
              ]);
              setIndexOfEdit(projectType.projectTypeRoomTypes.length);
            },
          }))}
          ButtonComponent={TertiaryButton}
          buttonStyle={{color: colors.blue.interactive}}
          iconLeft={Icon.Plus}
          menuWidth={200}
        />
      </Panel.Body>
    </Panel>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeRoomTypesPanel.fragment = gql`
  ${ReorderProjectTypeRoomTypesForm.edit.fragment}
  fragment ProjectTypeRoomTypesPanel on ProjectType {
    id
    defaultInventoryLibrary {
      id
      roomTypes {
        id
        name
      }
    }
    ...ReorderProjectTypeRoomTypesForm_edit
  }
`;

export default ProjectTypeRoomTypesPanel;
