// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Project} from '@supermove/models';
import {Typography} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const StatusText = Styled.Text`
  ${Typography.Label4}
  color: ${({color}) => color};
`;

const ProjectSalesStatus = ({project}) => {
  const salesStatus = Project.getSalesStatus(project);
  const color = Project.getSalesStatusColor(salesStatus);
  return (
    <Row>
      <Icon source={Project.getSalesStatusIcon(salesStatus)} size={10} color={color} />
      <Space width={6} />
      <StatusText color={color}>{Project.getSalesStatusText(project)}</StatusText>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectSalesStatus.fragment = gql`
  ${Project.getSalesStatus.fragment}
  ${Project.getSalesStatusText.fragment}

  fragment ProjectSalesStatus on Project {
    id
    ...Project_getSalesStatus
    ...Project_getSalesStatusText
  }
`;

export default ProjectSalesStatus;
