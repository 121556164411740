// Libraries
import React from 'react';

// Supermove
import {Styled, Space, Loading, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import StatusCard from '@shared/design/components/StatusCard';
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const StepDot = Styled.View`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${colors.gray.tertiary};
  align-items: center;
  justify-content: center;
  z-index: ${(props) => 100 - props.index};
`;

const StepBar = Styled.View`
  top: 50%;
  left: -16px;
  width: 2px;
  height: 100%;
  background-color: ${colors.gray.tertiary};
  z-index: ${(props) => 99 - props.index};
`;

const OrderText = Styled.Text`
  ${Typography.Responsive.Body};
  color: white;
`;

const LoadingComponent = ({responsive, index, isLast}) => {
  return (
    <React.Fragment>
      <Row>
        <StepDot index={index}>
          <OrderText responsive={responsive}>{index + 1}</OrderText>
        </StepDot>
        {!isLast && <StepBar index={index} />}
        <Space width={8} />
        <SkeletonLoader isFullWidth height={responsive.desktop ? 72 : 78} />
      </Row>
      <Space height={16} />
    </React.Fragment>
  );
};

const ProposalDetailStep = ({step, index, isLast, onPress, responsive}) => {
  const isDocumentStep = ConfirmationStepKind.DOCUMENT_V2_STEPS.includes(step.kind);
  const {data, loading} = useQuery(ProposalDetailStep.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      documentUuid: step.document_uuid,
    },
    skip: step.document_uuid === '',
  });
  return (
    <Loading
      loading={loading}
      as={() => <LoadingComponent responsive={responsive} index={index} isLast={isLast} />}
    >
      {() => {
        const stepDetails = ConfirmationStepKind.getDetails(step.kind, {
          value: isDocumentStep ? data?.document?.displayName : step.value,
          valueTwo: step.value_two,
        });
        const completedAt = step.completed_at
          ? `Completed at: ${Datetime.convertToDisplayDatetime(step.completed_at)}`
          : '';
        const detailsText = [stepDetails, completedAt]
          .filter((detail) => detail !== '')
          .join(' | ');
        return (
          <React.Fragment>
            <Row>
              <StepDot index={index}>
                <OrderText responsive={responsive}>{index + 1}</OrderText>
              </StepDot>
              {!isLast && <StepBar index={index} />}
              <Space width={8} />
              <StatusCard
                headerText={ConfirmationStepKind.getLabel(step.kind)}
                statusText={step.is_done ? 'Completed' : 'Incomplete'}
                color={step.is_done ? colors.green.status : colors.orange.status}
                detailsText={detailsText}
                style={{flex: 1}}
                icon={isDocumentStep ? Icon.MagnifyingGlassPlus : null}
                onPress={onPress}
                responsive={responsive}
              />
            </Row>
            <Space height={16} />
          </React.Fragment>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProposalDetailStep.query = gql`
  query ProposalDetailStep(
    $documentUuid: String!
  ) {
    ${gql.query}
    document(uuid: $documentUuid) {
      id
      displayName
    }
  }
`;

export default ProposalDetailStep;
