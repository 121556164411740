// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Calendar, Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useModal, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// Components
import ReportsDayInfo from './ReportsDayInfo';
import ReportsDayInfoModal from './ReportsDayInfoModal';

const Wrapper = Styled.View`
  flex: 1;
  padding-horizontal: 10px;
`;

const Container = Styled.View`
  padding-bottom: 10px;
`;

const Header = Styled.View`
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
`;

const HeaderItem = Styled.View`
  align-items: center;
`;

const HeaderItemSpace = Styled.View`
  width: 80px;
`;

const MonthRevenue = Styled.Text`
  ${Typography.Heading4};
  letter-spacing: 0.5px;
`;

const MonthRevenueLabel = Styled.Text`
  ${Typography.Body2};
  letter-spacing: 0.5px;
`;

const Content = Styled.View`
  height: 800px;
`;

const DateHeader = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  padding-top: 10px;
  padding-left: ${({vars}) => (vars.compact ? '2px' : '10px')};
`;

const DateHeaderText = Styled.Text`
  ${Typography.Body3};
`;

const Day = Styled.View`
  flex: 1;
  padding-top: 30px;
  background-color: ${(props) => props.color};
  border-right-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const TouchableDay = Styled.ButtonV2`
  flex: 1;
`;

const getCellBackgroundColor = ({calendarDay}) => {
  if (!calendarDay) {
    return colors.alpha(colors.blue.accent, 0.1);
  } else {
    return calendarDay.notFinalCount <= 0
      ? colors.alpha(colors.green.status, 0.05)
      : colors.alpha(colors.orange.status, 0.05);
  }
};

const Cell = ({calendar, date, value, compact}) => {
  const dayInfoModal = useModal();

  const cellDate = Datetime.toMutationDate(value); // The date of the current calendar cell.
  const calendarDays = _.get(calendar, 'calendarDays');
  const calendarDay = _.find(calendarDays, (calendarDay) => {
    return _.get(calendarDay, 'date') === cellDate;
  });

  if (compact) {
    return (
      <React.Fragment>
        <ReportsDayInfoModal
          key={`${dayInfoModal.isOpen}_DAY_INFO`}
          isOpen={dayInfoModal.isOpen}
          handleClose={dayInfoModal.handleClose}
          calendarDay={calendarDay}
        />
        <TouchableDay onPress={() => dayInfoModal.handleOpen()}>
          <Day color={getCellBackgroundColor({calendarDay})}>
            <ReportsDayInfo calendarDay={calendarDay} compact />
          </Day>
        </TouchableDay>
      </React.Fragment>
    );
  }

  return (
    <Day color={getCellBackgroundColor({calendarDay})}>
      <ReportsDayInfo calendarDay={calendarDay} />
    </Day>
  );
};

const ReportsCalendarMonth = ({date, organizationId}) => {
  const responsive = useResponsive();
  const {loading, data} = useQuery(ReportsCalendarMonth.query, {
    fetchPolicy: 'network-only',
    variables: {
      month: Datetime.toMutationMonth(Datetime.fromDate(date)),
      organizationId,
    },
  });

  return (
    <Wrapper>
      <ScrollView style={{flex: 1}}>
        <Loading loading={loading}>
          {() => {
            return (
              <Container>
                <Header>
                  <HeaderItem>
                    <MonthRevenue>{`${data.calendar.reportsCount} reports`}</MonthRevenue>
                    <MonthRevenueLabel>done this month</MonthRevenueLabel>
                  </HeaderItem>
                  <HeaderItemSpace />
                  <HeaderItem>
                    <MonthRevenue>
                      {Currency.display(data.calendar.totalRevenue, {shouldHideCentsIfZero: true})}
                    </MonthRevenue>
                    <MonthRevenueLabel>made this month</MonthRevenueLabel>
                  </HeaderItem>
                </Header>
                <Content>
                  <Calendar
                    hasToolbar={false}
                    isSundayFirst={false}
                    date={date}
                    dateCellWrapperComponent={({value}) => (
                      <Cell
                        calendar={data.calendar}
                        date={date}
                        value={value}
                        compact={responsive.mobile}
                      />
                    )}
                    dateHeaderComponent={({date, label}) => (
                      <DateHeader vars={{compact: responsive.mobile}}>
                        <DateHeaderText>{label}</DateHeaderText>
                      </DateHeader>
                    )}
                  />
                </Content>
              </Container>
            );
          }}
        </Loading>
      </ScrollView>
    </Wrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportsCalendarMonth.query = gql`
  query ReportsCalendarMonth(
    $month: String!,
    $organizationId: Int!,
  ) {
    ${gql.query}
    calendar: reportsCalendarByMonth(month: $month, organizationId: $organizationId) {
      reportsCount
      totalRevenue
      calendarDays {
        date
        notFinalCount
        reportsCount
        totalRevenue
      }
    }
  }
`;

export default ReportsCalendarMonth;
