// Libraries
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';

// App
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import InvoiceCustomerDocumentPage from 'modules/Customer/Invoice/components/InvoiceCustomerDocumentPage';
import InvoiceNotFoundPage from 'modules/Customer/Invoice/components/InvoiceNotFoundPage';

const Header = () => {
  return (
    <React.Fragment>
      <Space height={60} />
      <InvoiceCustomerDocumentPage.PageTitle>
        We've received your payment!
      </InvoiceCustomerDocumentPage.PageTitle>
      <Space height={8} />
      <InvoiceCustomerDocumentPage.Subtitle>
        Your receipt is below.
      </InvoiceCustomerDocumentPage.Subtitle>
    </React.Fragment>
  );
};

const InvoiceSuccessPageContent = ({invoice}) => {
  return (
    <InvoiceCustomerDocumentPage
      invoice={invoice}
      documentTitle={'Receipt'}
      HeaderComponent={Header}
      getIsCorrectPage={({invoice}) => {
        return invoice.status !== InvoiceStatus.UNPAID && invoice.status !== InvoiceStatus.OVERDUE;
      }}
      redirectRoute={`/0/${invoice.organization.slug}/invoices/${invoice.uuid}/review`}
    />
  );
};

const InvoiceSuccessPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(InvoiceSuccessPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      invoiceUuid: params.invoiceUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        if (!data.invoiceByUuid) {
          return <InvoiceNotFoundPage />;
        }
        return <InvoiceSuccessPageContent invoice={data.invoiceByUuid} />;
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceSuccessPage.query = gql`
  ${InvoiceCustomerDocumentPage.fragment}

  query InvoiceSuccessPage($invoiceUuid: String!) {
    ${gql.query}
    invoiceByUuid(invoiceUuid: $invoiceUuid) {
      id
      uuid
      status
      organization {
        id
        slug
      }
      ...InvoiceCustomerDocumentPage
    }
  }
`;

export default InvoiceSuccessPage;
