// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const UpdateJobUserMutation = ({jobId, userId, status, onSuccess, onError, children}) => (
  <Mutation
    variables={{
      jobId,
      userId,
      status,
    }}
    mutation={UpdateJobUserMutation.mutation}
    onCompleted={({response: {jobUser, errors}}) => {
      if (jobUser) {
        onSuccess(jobUser);
      } else {
        onError(errors);
      }
    }}
    refetchQueries={['DetailsJobPage']}
    awaitRefetchQueries
  >
    {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
UpdateJobUserMutation.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

UpdateJobUserMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateJobUserMutation.fragment = gql`
  fragment UpdateJobUserMutation on JobUser {
    id
    jobId
    userId
  }
`;

UpdateJobUserMutation.mutation = gql`
  mutation UpdateJobUserMutation(
    $jobId: Int!,
    $userId: Int!,
    $status: String!,
  ) {
    response: updateJobUser(
      jobId: $jobId,
      userId: $userId,
      status: $status,
    ) {
      ${gql.errors}
      jobUser {
        id
        jobId
        userId
      }
    }
  }
`;

export default UpdateJobUserMutation;
