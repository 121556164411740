/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const DocumentAdditionalInfoListActions = ({name, form}) => (
  <Container>
    <Content>
      <Button
        onPress={() => {
          const items = _.get(form, `values.${name}`);
          form.setFieldValue(name, _.concat(items, ''));
        }}
      >
        <Text>+ Add</Text>
      </Button>
    </Content>
  </Container>
);

export default DocumentAdditionalInfoListActions;
