// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, DropdownInput, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import FieldValue from '@shared/design/components/Field/FieldValue';
import Panel from '@shared/design/components/Panel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import CapacityCalendarSlotMetricKind from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSlotMetricKind';
import CapacityTotalComponentKind from '@shared/modules/CapacityCalendarSetting/enums/CapacityTotalComponentKind';
import CapacityCalendarSlotMetricForm from '@shared/modules/CapacityCalendarSetting/forms/CapacityCalendarSlotMetricForm';
import UserRole from '@shared/modules/User/enums/UserRole';

const Row = Styled.View`
  flex-direction: row;
  z-index: ${({index}) => 100 - index};
`;

const FieldContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const MetricTitle = Styled.Text`
  ${Typography.Subheading}
`;

const MetricFieldContainer = Styled.View`
  flex: 1;
`;

const MetricHeaderContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const Column = Styled.View`
  flex: 1;
`;

const SubheadingText = Styled.Text`
  ${Typography.Subheading}
`;

const Line = Styled.View`
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
`;

const getMetricOptions = ({index, organization}) => {
  // Only show the selected metric in the dropdown if it meets these conditions:
  // 1. It is allowed in the slot (if allowedSlots is not defined then we assume it is allowed in all slots)
  const options = (
    organization.features.isEnabledCapacityCalendarSecondaryMetric
      ? CapacityCalendarSlotMetricKind.DROPDOWN_OPTIONS
      : CapacityCalendarSlotMetricKind.DROPDOWN_OPTIONS_V1
  ).filter(
    (option) =>
      (option.allowedSlots && option.allowedSlots.includes(index + 1)) || !option.allowedSlots,
  );

  // OPTIONAL: Filter based on FF
  // Filter based on Feature Flag, keeping separate so it's easier to remove
  //   return options.filter(
  //     (option) =>
  //       !(
  //         !isEnabledFF &&
  //         option.value ===
  //           CapacityCalendarSlotMetricKind.METRIC_NAME
  //       ),
  //   );

  return options;
};

const handleAddSlotMetric = ({form, index, field}) => {
  const capacityCalendarSlotMetricForms = _.get(
    form.values,
    `${field}.capacityCalendarSlotMetricForms`,
  );
  const newCapacityCalendarSlotMetricForm = CapacityCalendarSlotMetricForm.new({
    slotPriorityIndex: index,
  });
  form.setFieldValue(`${field}.capacityCalendarSlotMetricForms`, [
    ...capacityCalendarSlotMetricForms,
    newCapacityCalendarSlotMetricForm,
  ]);
};

const handleDeleteSlotMetric = ({form, index, field}) => {
  const capacityCalendarSlotMetricForms = _.get(
    form.values,
    `${field}.capacityCalendarSlotMetricForms`,
  );
  form.setFieldValue(
    `${field}.capacityCalendarSlotMetricForms`,
    capacityCalendarSlotMetricForms.filter((_, i) => i !== index),
  );
  form.setFieldValue(`${field}.secondaryMoverPositionId`, null);
};

const CapacityLimitFieldValueComponent = ({
  capacityMetricComponentKind,
  moverPositionId,
  organization,
}) => {
  const moverPositions = organization.features.isEnabledMoverPositionMultibranch
    ? Organization.getCompanySettingsMoverPositionsByRole({organization, role: UserRole.EMPLOYEE})
    : organization.moverPositions;

  const moverPositionName =
    moverPositions.find((moverPosition) => _.toNumber(moverPosition.id) === moverPositionId)
      ?.name || '';
  switch (capacityMetricComponentKind) {
    case CapacityTotalComponentKind.CREW_BY_POSITION:
      return (
        <FieldValue
          label={'Crew Position'}
          value={moverPositionName}
          labelColor={colors.gray.primary}
        />
      );
    case CapacityTotalComponentKind.SINGLE_NUMBER:
    case CapacityTotalComponentKind.AM_PM_SPLIT:
    default:
      return null;
  }
};

const CapacityLimitFieldComponent = ({
  form,
  field,
  capacityMetricComponentKind,
  capacityMetricFieldName,
  organization,
  isSecondaryMetric,
}) => {
  const moverPositions = organization.features.isEnabledMoverPositionMultibranch
    ? Organization.getCompanySettingsMoverPositionsByRole({organization, role: UserRole.EMPLOYEE})
    : organization.moverPositions;

  switch (capacityMetricComponentKind) {
    case CapacityTotalComponentKind.CREW_BY_POSITION:
      return (
        <Row>
          <FieldInput
            {...form}
            label={'Crew Position'}
            name={
              isSecondaryMetric ? `${field}.secondaryMoverPositionId` : `${field}.moverPositionId`
            }
            component={DropdownInput}
            isRequired
            style={{flex: 1}}
            input={{
              // Filter metrics that have already been selected
              options: moverPositions.map((moverPosition) => ({
                label: moverPosition.name,
                value: _.toNumber(moverPosition.id),
              })),
              style: {flex: 1},
              setFieldValue: form.setFieldValue,
            }}
          />
        </Row>
      );
    case CapacityTotalComponentKind.SINGLE_NUMBER:
    case CapacityTotalComponentKind.AM_PM_SPLIT:
    default:
      return null;
  }
};

const MetricField = ({form, label, capacitySlotMetricField, field, index, organization}) => {
  // Each metric can only be selected once
  const selectedMetric = _.get(form.values, `${capacitySlotMetricField}.metric`);
  const selectedMetrics = _.map(
    _.get(form.values, `${field}.capacityCalendarSlotMetricForms`),
    (capacityCalendarSlotMetricForm) => capacityCalendarSlotMetricForm.metric,
  );
  return (
    <FieldContainer index={0}>
      <FieldInput
        {...form}
        label={label}
        name={`${capacitySlotMetricField}.metric`}
        component={DropdownInput}
        isRequired
        input={{
          // Filter metrics that have already been selected
          options: getMetricOptions({
            selectedMetrics,
            selectedMetric,
            index,
            organization,
          }),
          style: {width: '100%'},
          setFieldValue: form.setFieldValue,
          placeholder: 'Select metric',
          showDescriptionInOption: true,
        }}
      />
    </FieldContainer>
  );
};

const CapacityLimitFieldValue = ({capacityCalendarSlotMetric, moverPositionId, organization}) => {
  const {metric} = capacityCalendarSlotMetric;
  const capacityMetricComponentKind =
    CapacityCalendarSlotMetricKind.SLOT_METRIC_KIND_TO_CAPACITY_METRIC_NAME[metric].componentKind;
  return (
    <CapacityLimitFieldValueComponent
      capacityMetricComponentKind={capacityMetricComponentKind}
      organization={organization}
      moverPositionId={moverPositionId}
    />
  );
};

const CapacityLimitField = ({
  form,
  field,
  capacitySlotMetricField,
  organization,
  isSecondaryMetric,
}) => {
  const capacityMetric = _.get(form.values, `${capacitySlotMetricField}.metric`);
  const capacityMetricFieldName =
    capacityMetric &&
    CapacityCalendarSlotMetricKind.SLOT_METRIC_KIND_TO_CAPACITY_METRIC_NAME[capacityMetric]
      .fieldName;
  const capacityMetricComponentKind =
    capacityMetric &&
    CapacityCalendarSlotMetricKind.SLOT_METRIC_KIND_TO_CAPACITY_METRIC_NAME[capacityMetric]
      .componentKind;

  return (
    <FieldContainer index={1}>
      <CapacityLimitFieldComponent
        form={form}
        field={field}
        capacitySlotMetricField={capacitySlotMetricField}
        capacityMetricComponentKind={capacityMetricComponentKind}
        capacityMetricFieldName={capacityMetricFieldName}
        organization={organization}
        isSecondaryMetric={isSecondaryMetric}
      />
    </FieldContainer>
  );
};

const AddMetricButton = ({form, index, field, label}) => {
  return (
    <TertiaryButton
      iconLeft={Icon.Plus}
      text={label || 'Add Calendar Slot'}
      onPress={() => handleAddSlotMetric({form, index, field})}
    />
  );
};

const DeleteMetricButton = ({form, index, field, color}) => {
  return (
    <TertiaryButton onPress={() => handleDeleteSlotMetric({form, index, field})}>
      <Icon source={Icon.Trash} size={16} color={color || colors.gray.tertiary} />
    </TertiaryButton>
  );
};

const CapacityCalendarSlotMetricFields = ({
  form,
  index,
  field,
  organization,
  label,
  isSecondaryMetric,
}) => {
  const capacitySlotMetricField = `${field}.capacityCalendarSlotMetricForms.${index}`;
  return (
    <React.Fragment>
      <MetricField
        form={form}
        field={field}
        label={label || `Slot ${index + 1}`}
        capacitySlotMetricField={capacitySlotMetricField}
        organization={organization}
        index={index}
      />
      <Space height={16} />
      <CapacityLimitField
        form={form}
        field={field}
        capacitySlotMetricField={capacitySlotMetricField}
        organization={organization}
        isSecondaryMetric={isSecondaryMetric}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsCapacityPanelHeader = ({isEditing, handleEdit}) => (
  <Panel.Header style={{alignItems: 'center'}}>
    <Column>
      <Row index={0}>
        <Panel.HeaderText>Capacity Calendar</Panel.HeaderText>
        <Space style={{flex: 1, minWidth: 12}} />
        <EditPanel.EditButton isEditing={isEditing} handleEdit={handleEdit} />
      </Row>
      <Space height={8} />
      <Panel.HeaderDescription>
        {`Configure what daily metrics are shown on the monthly view of the Capacity calendar.`}
      </Panel.HeaderDescription>
    </Column>
  </Panel.Header>
);

const OrganizationSettingsCapacityEditPanel = ({form, organization}) => {
  const field = 'capacityCalendarSettingForm';
  const capacityCalendarSlotMetricForms = _.get(
    form.values,
    `${field}.capacityCalendarSlotMetricForms`,
  );

  const shouldShowAddMetricButton = capacityCalendarSlotMetricForms.length < 2;
  return (
    <React.Fragment>
      {capacityCalendarSlotMetricForms.map((capacityCalendarSlotMetricForm, index) => (
        <React.Fragment key={index}>
          <Row index={index}>
            <MetricFieldContainer index={index}>
              <MetricHeaderContainer>
                {index === 0 && <MetricTitle>Primary Metric</MetricTitle>}
                {index === 1 && (
                  <MetricTitle>
                    {pluralize('Secondary Metric', capacityCalendarSlotMetricForms.length - 1)}
                  </MetricTitle>
                )}
                {index + 1 === capacityCalendarSlotMetricForms.length && index !== 0 && (
                  <DeleteMetricButton
                    form={form}
                    index={index}
                    field={field}
                    color={colors.red.warning}
                  />
                )}
              </MetricHeaderContainer>
              <Space height={16} />
              <CapacityCalendarSlotMetricFields
                form={form}
                index={index}
                field={field}
                organization={organization}
                label={'Metric Type'}
                isSecondaryMetric={index === 1}
              />
            </MetricFieldContainer>
          </Row>
          {index !== capacityCalendarSlotMetricForms.length - 1 && (
            <React.Fragment>
              <Space height={16} />
              <Line />
              <Space height={16} />
            </React.Fragment>
          )}
        </React.Fragment>
      ))}
      {shouldShowAddMetricButton && (
        <React.Fragment>
          <Space height={16} />
          <AddMetricButton
            form={form}
            index={capacityCalendarSlotMetricForms.length + 1}
            field={field}
            label={'Add Metric'}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const MetricSection = ({metrics, label, organization, moverPositionId}) => (
  <React.Fragment>
    <SubheadingText>{pluralize(label, metrics.length)}</SubheadingText>
    <Space height={16} />
    {metrics.map((metric, index) => (
      <React.Fragment key={index}>
        <Row index={index}>
          <FieldValue
            label={'Metric Type'}
            value={CapacityCalendarSlotMetricKind.getDisplayName(metric.metric)}
            labelColor={colors.gray.primary}
          />
          <Space width={16} />
          <CapacityLimitFieldValue
            capacityCalendarSlotMetric={metric}
            moverPositionId={moverPositionId}
            organization={organization}
          />
        </Row>
        {index !== metrics.length - 1 && <Space height={16} />}
      </React.Fragment>
    ))}
  </React.Fragment>
);

const OrganizationSettingsCapacityPanel = ({organization}) => {
  const {capacityCalendarSetting} = organization;
  const {activeCapacityCalendarSlotMetrics, moverPositionId, secondaryMoverPositionId} =
    capacityCalendarSetting;
  const primaryMetric = activeCapacityCalendarSlotMetrics[0];
  const secondaryMetrics = activeCapacityCalendarSlotMetrics.slice(1);
  return (
    <React.Fragment>
      <MetricSection
        metrics={[primaryMetric]}
        label={'Primary Metric'}
        organization={organization}
        moverPositionId={moverPositionId}
      />
      {secondaryMetrics.length > 0 && (
        <React.Fragment>
          <Space height={16} />
          <Line />
          <Space height={16} />
          <MetricSection
            metrics={secondaryMetrics}
            label={'Secondary Metric'}
            organization={organization}
            moverPositionId={secondaryMoverPositionId}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const OrganizationSettingsCapacity = {
  Panel: OrganizationSettingsCapacityPanel,
  EditPanel: OrganizationSettingsCapacityEditPanel,
  PanelHeader: OrganizationSettingsCapacityPanelHeader,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCapacity.Panel.fragment = gql`
  ${Organization.getCompanySettingsMoverPositionsByRole.fragment}

  fragment OrganizationSettingsCapacity_Panel on Organization {
    id
    capacityCalendarSetting {
      id
      moverPositionId
      secondaryMoverPositionId
      activeCapacityCalendarSlotMetrics {
        id
        metric
      }
    }
    moverPositions {
      id
      name
    }
    features {
      isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
      isEnabledCapacityCalendarSecondaryMetric: isEnabled(
        feature: "CAPACITY_CALENDAR_SECONDARY_METRIC"
      )
    }
    ...Organization_getCompanySettingsMoverPositionsByRole
  }
`;

export default OrganizationSettingsCapacity;
