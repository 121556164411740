// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ClaimStatusForm from '@shared/modules/Claim/forms/ClaimStatusForm';

const useCreateClaimStatusMutation = ({claimStatusForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      claimStatusForm: ClaimStatusForm.toForm(claimStatusForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateClaimStatusMutation.mutation,
    variables: {
      claimStatusForm: ClaimStatusForm.toMutation(form.values.claimStatusForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateClaimStatusMutation.mutation = gql`
  mutation useCreateClaimStatusMutation($claimStatusForm: ClaimStatusForm!) {
    response: createClaimStatus(claimStatusForm: $claimStatusForm) {
      ${gql.errors}
      claimStatus {
        id
      }
    }
  }
`;

export default useCreateClaimStatusMutation;
