// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import useCreateMoveProjectMutation from '@shared/modules/Project/Move/hooks/useCreateMoveProjectMutation';
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';
import MoveProjectModalFields from 'modules/Job/V2/Move/components/MoveProjectModalFields';

const CreateMoveProjectModal = ({
  isOpen,
  handleClose,
  refetch,
  project,
  viewerId,
  isDisabledClientFields,
  isDisabledInventoryInformation,
  createProjectSuccessModal,
  containerIds,
  collections = [],
  isJobDateRequired,
}) => {
  const {navigator} = useNavigationDOM();
  const projectForm = ProjectForm.newFromStorageProject(project, {
    collectionIds: collections.map((collection) => collection.id),
    containerIds,
    viewerId,
  });
  const {form, submitting, handleSubmit} = useCreateMoveProjectMutation({
    projectForm,
    onSuccess: ({project}) => {
      navigator.replace(`${navigator.location.pathname}?new=${project.uuid}`);
      createProjectSuccessModal.handleOpen();
      handleClose();
      refetch();
    },
    // TODO(dan) Update error handling
    onError: (error) => console.log({error}),
  });

  return (
    <Modal.Responsive isOpen={isOpen} width={Modal.WIDTH.LARGE} style={{flex: 1}}>
      <Modal.Header style={{paddingHorizontal: 24, paddingVertical: 16}}>
        <Modal.HeaderText>{'Create Move Project'}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body isScrollable>
        <MoveProjectModalFields
          form={form}
          field={'projectForm'}
          isDisabledClientFields={isDisabledClientFields}
          isDisabledInventoryInformation={isDisabledInventoryInformation}
          collections={collections}
          isJobDateRequired={isJobDateRequired}
        />
      </Modal.Body>
      <Modal.Footer style={{paddingHorizontal: 16, paddingVertical: 12}}>
        <Modal.Button onPress={handleClose}>{'Cancel'}</Modal.Button>
        <Space width={8} />
        <Modal.Button
          onPress={handleSubmit}
          isSubmitting={submitting}
          color={colors.blue.interactive}
        >
          {'Create'}
        </Modal.Button>
      </Modal.Footer>
    </Modal.Responsive>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateMoveProjectModal.fragment = gql`
  ${ProjectForm.newFromStorageProject.fragment}
  ${MoveProjectModalFields.fragment}
  fragment CreateMoveProjectModal on Project {
    id
    collections {
      id
      ...MoveProjectModalFields
    }
    ...ProjectForm_newFromStorageProject
  }
`;

export default CreateMoveProjectModal;
