// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {TimeRange} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components

const Container = Styled.View`
  flex-direction: row;
  width: 100%;
  height: 60px;
`;

const Item = Styled.View`
  flex: ${(props) => props.length || 1};
`;

const Content = Styled.View`
  justify-content: center;
  height: 40px;
  background-color: ${(props) => props.color};
`;

const Name = Styled.H7`
  margin-horizontal: 10px;
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Times = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const StartTime = Styled.H8`
  flex: 1;
  margin-top: 5px;
  margin-horizontal: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const EndTime = Styled.H8`
  margin-top: 5px;
  margin-horizontal: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const EmptyView = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${colors.gray.border};
`;

const EmptyText = Styled.H6`
  color: ${colors.gray.secondary};
`;

const filterTimeRanges = ({timeRanges}) => {
  return timeRanges.filter((timeRange) => timeRange.start && timeRange.end);
};

const TimeRangeItem = ({isLast, length, timeRange}) => (
  <Item length={length}>
    <Content color={TimeRange.getColor(timeRange)}>
      <Name numberOfLines={1}>{timeRange.name}</Name>
    </Content>
    <Times>
      <StartTime numberOfLines={1}>
        {Datetime.convertToDisplayTime(timeRange.start, Datetime.FORM_TIME)}
      </StartTime>
      {isLast && !!timeRange.end && (
        <EndTime numberOfLines={1}>
          {Datetime.convertToDisplayTime(timeRange.end, Datetime.FORM_TIME)}
        </EndTime>
      )}
    </Times>
  </Item>
);

const ReportMoveTimeRangesChart = ({timeRanges}) => (
  <Container>
    {filterTimeRanges({timeRanges}).map((timeRange, index) => (
      <TimeRangeItem
        key={index}
        isFirst={index === 0}
        isLast={index === timeRanges.length - 1}
        length={TimeRange.getMinutes(timeRange)}
        timeRange={timeRange}
      />
    ))}
    {filterTimeRanges({timeRanges}).length === 0 && (
      <EmptyView>
        <EmptyText>No times entered</EmptyText>
      </EmptyView>
    )}
  </Container>
);

export default ReportMoveTimeRangesChart;
