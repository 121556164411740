// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {ResponsiveType, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const WIDTH = {
  DEFAULT: 784,
};

const Container = Styled.View<{index: number; width?: number | null; isFlex: boolean}>`
  z-index: ${({index}) => 100 - index};
  background-color: ${colors.white};
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: 1px;
  ${({isFlex = false}) => isFlex && 'flex: 1;'}
  ${({width, isFlex = false}) => (width && !isFlex ? `width: ${width}px;` : '')}
`;

const BodyContainer = Styled.View`
  padding-top: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? '24' : '0')}px;
  padding-bottom: 24px;
  padding-horizontal: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? '24' : '16')}px;
  z-index: 100;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const HeaderDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const BoldText = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  border-bottom-width: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? '1' : '0')}px;
  border-bottom-color: ${colors.gray.border};
  padding-vertical: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? '16' : '24')}px;
  padding-horizontal: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? '24' : '16')}px;
  z-index: 101;
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top-width: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? '1' : '0')}px;
  border-top-color: ${colors.gray.border};
  padding-top: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? '16' : '0')}px;
  padding-bottom: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? '16' : '24')}px;
  padding-horizontal: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? '24' : '16')}px;
`;

const Header = ({style, children}: {style?: object; children: React.ReactNode}) => {
  const responsive = useResponsive();
  return (
    <HeaderContainer responsive={responsive} style={style}>
      {children}
    </HeaderContainer>
  );
};

const Body = ({style, children}: {style?: object; children: React.ReactNode}) => {
  const responsive = useResponsive();
  return (
    <BodyContainer responsive={responsive} style={style}>
      {children}
    </BodyContainer>
  );
};

const Footer = ({style, children}: {style?: object; children: React.ReactNode}) => {
  const responsive = useResponsive();
  return (
    <FooterContainer responsive={responsive} style={style}>
      {children}
    </FooterContainer>
  );
};

const Panel = ({
  index = 0,
  isFlex = false,
  width = null,
  style = null,
  children,
}: {
  index?: number;
  width?: number | null;
  style?: object | null;
  isFlex?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Container index={index} width={width} style={style} isFlex={isFlex}>
      {children}
    </Container>
  );
};

Panel.HeaderText = HeaderText;
Panel.HeaderDescription = HeaderDescription;
Panel.BoldText = BoldText;
Panel.Text = Text;
Panel.LabelText = LabelText;
Panel.Header = Header;
Panel.Body = Body;
Panel.Footer = Footer;
Panel.WIDTH = WIDTH;

export default Panel;
