// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import FinalizeReportMoveMutation from './FinalizeReportMoveMutation';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

const FinalizeReportMoveModal = ({reportMove, onSuccess, refetch, trigger}) => {
  const {isOpen, handleOpen, handleClose} = useModal({onClose: refetch});

  return (
    <React.Fragment>
      {trigger({handleOpen})}
      <Modal.Content isOpen={isOpen} onClose={handleClose}>
        <Container pointerEvents={'box-none'}>
          <Wrapper width={350}>
            <Circle>
              <Emoji component={EmojiText} name={'unlock'} />
            </Circle>
            <Content>
              <Title>Finalize Report</Title>
              <Subtitle>Press confirm below to finalize this report.</Subtitle>
              <Actions>
                <Button color={colors.gray.tertiary} onPress={handleClose}>
                  <Text>Cancel</Text>
                </Button>
                <ButtonSpace />
                <FinalizeReportMoveMutation
                  reportMoveId={reportMove.id}
                  onSuccess={() => onSuccess({handleClose})}
                >
                  {({loading, handleSubmit}) => (
                    <Button loading={loading} onPress={handleSubmit}>
                      <Text>Confirm</Text>
                    </Button>
                  )}
                </FinalizeReportMoveMutation>
              </Actions>
            </Content>
          </Wrapper>
        </Container>
      </Modal.Content>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
FinalizeReportMoveModal.propTypes = {
  reportMove: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
};

FinalizeReportMoveModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
FinalizeReportMoveModal.mutation = gql`
  mutation FinalizeReportMoveModal(
    $reportMoveId: Int!,
  ) {
    response: finalizeReportMove(
      reportMoveId: $reportMoveId,
    ) {
      ${gql.errors}
      reportMove {
        id
        uuid
        isFinal
      }
    }
  }
`;

export default FinalizeReportMoveModal;
