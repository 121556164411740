// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ContainerTypeForm from '@shared/modules/Storage/forms/ContainerTypeForm';

const useUpdateContainerTypeMutation = ({containerTypeForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      containerTypeForm: ContainerTypeForm.toForm(containerTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateContainerTypeMutation.mutation,
    variables: {
      containerTypeForm: ContainerTypeForm.toMutation(form.values.containerTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateContainerTypeMutation.mutation = gql`
  mutation UpdateContainerTypeMutation($containerTypeForm: ContainerTypeForm!) {
    response: updateContainerType(containerTypeForm: $containerTypeForm) {
      ${gql.errors}
      containerType {
        id
      }
    }
  }
`;

export default useUpdateContainerTypeMutation;
