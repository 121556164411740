// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {BillModifier} from '@supermove/models';
import {Currency, Percent, withFragment} from '@supermove/utils';

const getDisplayValue = (billModifierForm: any) => {
  switch (billModifierForm.kind) {
    case BillModifier.KINDS.AMOUNT:
      return billModifierForm.amount;
    case BillModifier.KINDS.PERCENTAGE:
      return billModifierForm.percentage;
    default:
      return null;
  }
};

const getIsAmount = (billModifierForm: any) => {
  return billModifierForm.kind === BillModifier.KINDS.AMOUNT;
};

const getIsPercentage = (billModifierForm: any) => {
  return billModifierForm.kind === BillModifier.KINDS.PERCENTAGE;
};

const getIsBillModifier = (billItemForm: any) => {
  return getIsAmount(billItemForm) || getIsPercentage(billItemForm);
};

const validate = ({billModifierForm, formField}: any) => {
  const isAmount = getIsAmount(billModifierForm);
  const isPercentage = getIsPercentage(billModifierForm);
  const errors = {};
  if (!billModifierForm.kind) {
    _.set(errors, `${formField}.kind`, 'Please select a category');
  }
  if (!billModifierForm.name) {
    _.set(errors, `${formField}.name`, 'Please enter a name');
  }
  if (isAmount && !billModifierForm.amount) {
    _.set(errors, `${formField}.amount`, 'Please enter an amount');
  }
  if (isPercentage && !billModifierForm.percentage) {
    _.set(errors, `${formField}.percentage`, 'Please enter an amount');
  }
  return errors;
};

const _new = ({kind}: any) => ({
  billItemId: null,
  billItemTypeId: null,
  nameFormulaId: null,
  amountFormulaId: null,
  kind,
  name: '',
  description: '',
  percentage: '',
  amount: '',

  // Private
  isEditable: true,
});

const edit = withFragment(
  (billModifier) => ({
    billItemId: (billModifier as any).billItemId,
    billItemTypeId: (billModifier as any).billItemTypeId,
    nameFormulaId: (billModifier as any).nameFormulaId,
    amountFormulaId: (billModifier as any).amountFormulaId,
    kind: (billModifier as any).kind,
    name: (billModifier as any).name,
    description: (billModifier as any).description,
    percentage: (billModifier as any).percentage,
    amount: (billModifier as any).amount,
    // Private
    isEditable: false,
  }),
  gql`
    fragment BillModifierForm_edit on BillModifier {
      billItemId
      billItemTypeId
      nameFormulaId
      amountFormulaId
      kind
      name
      description
      percentage
      amount
    }
  `,
);

const editFromBillModifierType = withFragment(
  (billModifierType) => ({
    billItemId: null,
    billItemTypeId: (billModifierType as any).billItemTypeId,
    nameFormulaId: (billModifierType as any).nameFormulaId,
    amountFormulaId: (billModifierType as any).amountFormulaId,
    kind: (billModifierType as any).kind,
    name: (billModifierType as any).name,
    description: (billModifierType as any).description,
    percentage: (billModifierType as any).percentage,
    amount: (billModifierType as any).amount,
    // Private
    isEditable: false,
  }),
  gql`
    fragment BillModifierForm_editFromBillModifierType on BillModifierType {
      id
      billItemTypeId
      nameFormulaId
      amountFormulaId
      kind
      name
      description
      percentage
      amount
    }
  `,
);

const toForm = ({
  billItemId,
  billItemTypeId,
  nameFormulaId,
  amountFormulaId,
  kind,
  name,
  description,
  percentage,
  amount,
  isEditable,
}: any) => ({
  billItemId,
  billItemTypeId,
  nameFormulaId,
  amountFormulaId,
  kind,
  name,
  description,
  percentage: percentage ? Percent.toForm(percentage) : '',
  amount: amount ? Currency.toForm(amount) : '',

  // Private
  isEditable,
});

const toMutation = ({
  billItemId,
  billItemTypeId,
  nameFormulaId,
  amountFormulaId,
  kind,
  name,
  description,
  percentage,
  amount,
}: any) => ({
  billItemId,
  billItemTypeId,
  nameFormulaId,
  amountFormulaId,
  kind,
  name,
  description,
  percentage: percentage ? Percent.toMutation(percentage) : 0,
  amount: amount ? Currency.toMutation(amount) : 0,
});

const BillModifierForm = {
  getDisplayValue,
  getIsAmount,
  getIsPercentage,
  getIsBillModifier,
  validate,

  new: _new,
  edit,
  editFromBillModifierType,

  toForm,
  toMutation,
};

export default BillModifierForm;
