// Libraries
import _ from 'lodash';
import React from 'react';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import DocumentContentJson from '@shared/modules/Document/helpers/DocumentContentJson';
import {
  DocumentContentJsonType,
  SelectedDocumentItemType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';

const AddColumnCautionModal = ({
  isOpen,
  handleClose,
  handleAddColumn,
  documentContentJson,
  selectedDocumentItem,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleAddColumn: () => void;
  documentContentJson: DocumentContentJsonType;
  selectedDocumentItem: SelectedDocumentItemType;
}) => {
  const documentItemKinds = DocumentContentJson.getDocumentItems(documentContentJson, {
    sectionIndex: selectedDocumentItem.sectionIndex,
  }).map((documentItem) => documentItem.itemKind);
  const disabledDocumentItemKinds = _.uniq(
    documentItemKinds.filter(DocumentItemKindV2.getIsDisabledForColumn),
  );
  const disabledKindsString = disabledDocumentItemKinds
    .map(
      (itemKind) =>
        `\n• ${DocumentItemKindV2.getItemDisplayName({itemKind, isFullyEnabledTimesheetsV2: true})}`,
    )
    .join('');

  return (
    <CautionModal
      title={'Remove items and add column?'}
      message={`This will remove the following items as they aren't supported in colunns:${disabledKindsString}`}
      isOpen={isOpen}
      handlePressOutside={handleClose}
      handlePrimaryAction={() => {
        handleAddColumn();
        handleClose();
      }}
      handleSecondaryAction={handleClose}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
    />
  );
};

export default AddColumnCautionModal;
