// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useDrawer} from '@supermove/hooks';

// App
import Button from '@shared/design/components/Button';
import VariableKind from '@shared/modules/Billing/enums/VariableKind';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import BillingVariablesTable from 'modules/Organization/Settings/Billing/Variables/components/BillingVariablesTable';
import CreateVariableDrawer from 'modules/Organization/Settings/Billing/Variables/components/CreateVariableDrawer';
import OrganizationSettingsBillingSettingsPage from 'modules/Organization/Settings/Billing/components/OrganizationSettingsBillingSettingsPage';

const Container = Styled.View`
`;

const OrganizationSettingsBillingVariablesContent = ({organization, refetch, userId}) => {
  const createVariableDrawer = useDrawer({name: 'Create Variable Drawer'});
  const allVariables = [
    ...organization.customVariables,
    ...organization.systemVariables.map((v) => ({...v, isSystemVariable: true})),
  ];
  const projectTypeVariables = allVariables.filter(
    (variable) => variable.kind === VariableKind.PROJECT,
  );
  const jobTypeVariables = allVariables.filter((variable) => variable.kind === VariableKind.JOB);

  return (
    <Container>
      <Button
        onPress={createVariableDrawer.handleOpen}
        text={'Create Variable'}
        iconLeft={Icon.Plus}
      />
      <Space height={24} />
      <BillingVariablesTable
        title={`Project Type Variables (${projectTypeVariables.length})`}
        variables={projectTypeVariables}
        refetch={refetch}
        userId={userId}
      />
      <Space height={40} />
      <BillingVariablesTable
        title={`Job Type Variables (${jobTypeVariables.length})`}
        variables={jobTypeVariables}
        refetch={refetch}
        userId={userId}
      />
      <CreateVariableDrawer
        key={createVariableDrawer.key}
        organizationId={organization.id}
        isOpen={createVariableDrawer.isOpen}
        handleClose={createVariableDrawer.handleClose}
        refetch={refetch}
        userId={userId}
      />
      <Space height={40} />
    </Container>
  );
};

const OrganizationSettingsBillingVariablesPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsBillingVariablesPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <OrganizationSettingsBillingSettingsPage pageName={'Billing Variables'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <OrganizationSettingsBillingVariablesContent
            organization={data.viewer.viewingOrganization}
            userId={data.viewer.id}
            refetch={refetch}
          />
        )}
      </Loading>
    </OrganizationSettingsBillingSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsBillingVariablesPage.query = gql`
  ${BillingVariablesTable.fragment}

  query OrganizationSettingsBillingVariablesPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        customVariables {
          id
          ...BillingVariablesTable
        }
        systemVariables {
          id
          ...BillingVariablesTable
        }
      }
    }
  }
`;

export default OrganizationSettingsBillingVariablesPage;
