// Libraries
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App

const PageHeaderContainer = Styled.View`
  z-index: 100;
  align-items: flex-start;
  padding-horizontal: ${({desktop}: {desktop: boolean}) => (desktop ? 0 : 16)}px;
`;

const BackButton = Styled.Touchable`
  flex-direction: row;
  align-items: center;
`;

const BackButtonText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const TitleRow = Styled.View`
  width: 100%;
  flex-direction: row;
`;

const PageTitle = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const PageDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const BackRouteText = Styled.Text`
  ${Typography.Responsive.Subheading}
  color: ${colors.gray.tertiary};
`;

const RouteButtonContainer = Styled.Touchable`
`;

const UserSettingsHeader = ({
  pageTitle,
  pageDescription,
  actionElement,
  backNavigationRoute,
  backNavigationRouteName,
}: {
  pageTitle: string;
  pageDescription?: string;
  actionElement?: React.ReactNode;
  backNavigationRoute?: string;
  backNavigationRouteName?: string;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  return (
    <PageHeaderContainer desktop={responsive.desktop}>
      {!responsive.desktop && (
        <React.Fragment>
          <Space height={12} />
          <BackButton onPress={() => navigator.goBack()}>
            <Icon source={Icon.AngleLeft} size={12} color={colors.gray.secondary} />
            <Space width={8} />
            <BackButtonText>{backNavigationRouteName || 'Account'}</BackButtonText>
          </BackButton>
          <Space height={12} />
        </React.Fragment>
      )}
      <TitleRow>
        {backNavigationRoute && !responsive.mobile && (
          <RouteButtonContainer onPress={() => navigator.goBack()}>
            <BackRouteText>{backNavigationRouteName} / </BackRouteText>
          </RouteButtonContainer>
        )}
        <PageTitle>{pageTitle}</PageTitle>
        <Space style={{flex: 1}} />
        {actionElement}
      </TitleRow>
      <Space height={8} />
      <PageDescription>{pageDescription}</PageDescription>
    </PageHeaderContainer>
  );
};

export default UserSettingsHeader;
