/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import CustomerSignDocumentSignatureExpandedItem from 'modules/Customer/Document/components/CustomerSignDocumentSignatureExpandedItem';

const Cell = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const CustomerSignDocumentSignaturesList = ({
  isStepSigned,
  document,
  documentSignatures,
  refetch,
}) => {
  return (
    <React.Fragment>
      {documentSignatures.map((documentSignature, index) => (
        <Cell key={index} index={index}>
          <CustomerSignDocumentSignatureExpandedItem
            editable={!isStepSigned}
            isFirst={index === 0}
            isLast={index === documentSignatures.length - 1}
            documentSignature={documentSignature}
            document={document}
            refetch={refetch}
          />
        </Cell>
      ))}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerSignDocumentSignaturesList.fragment = gql`
  ${CustomerSignDocumentSignatureExpandedItem.fragment}

  fragment CustomerSignDocumentSignaturesList_Document on Document {
    id
    ...CustomerSignDocumentSignatureExpandedItem_Document
  }

  fragment CustomerSignDocumentSignaturesList on DocumentSignature {
    id
    ...CustomerSignDocumentSignatureExpandedItem
  }
`;

export default CustomerSignDocumentSignaturesList;
