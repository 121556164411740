// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import PaymentFeeForm from '@shared/modules/Organization/forms/PaymentFeeForm';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    paymentFeeForms: JSON.parse((organization as any).settings.jobRatesData).map(
      (paymentFee: any) => PaymentFeeForm.editFromJobRateData(paymentFee),
    ),
  }),
  gql`
    fragment OrganizationPaymentFeesForm_edit on Organization {
      id
      settings {
        id
        jobRatesData
      }
    }
  `,
);

// @ts-expect-error TS(7022): 'editWithNewPaymentFee' implicitly has type 'any' ... Remove this comment to see the full error message
const editWithNewPaymentFee = withFragment(
  (organization) => {
    // @ts-expect-error TS(7022): 'organizationPaymentFeesForm' implicitly has type ... Remove this comment to see the full error message
    const organizationPaymentFeesForm = OrganizationPaymentFeesForm.edit(organization);
    return {
      ...organizationPaymentFeesForm,
      paymentFeeForms: [...organizationPaymentFeesForm.paymentFeeForms, PaymentFeeForm.new()],
    };
  },
  gql`
    ${edit.fragment}
    fragment OrganizationPaymentFeesForm_editWithNewPaymentFee on Organization {
      id
      ...OrganizationPaymentFeesForm_edit
    }
  `,
);

const toForm = ({organizationId, paymentFeeForms}: any) => ({
  organizationId,
  paymentFeeForms: paymentFeeForms.map((paymentFeeForm: any) =>
    PaymentFeeForm.toForm(paymentFeeForm),
  ),
});

const toMutation = ({organizationId, paymentFeeForms}: any) => ({
  organizationId,
  paymentFeeForms: paymentFeeForms.map((paymentFeeForm: any) =>
    PaymentFeeForm.toMutation(paymentFeeForm),
  ),
});

// @ts-expect-error TS(7022): 'OrganizationPaymentFeesForm' implicitly has type ... Remove this comment to see the full error message
const OrganizationPaymentFeesForm = {
  edit,
  editWithNewPaymentFee,
  toForm,
  toMutation,
};

export default OrganizationPaymentFeesForm;
