// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const ButtonsRow = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
`;

const Button = Styled.ButtonV2`
  padding: 5px;
`;

const ClientProjectActions = ({emailModal}) => {
  return (
    <ButtonsRow>
      <Button onPress={emailModal.handleOpen}>
        <Icon source={Icon.Envelope} color={colors.gray.secondary} size={16} />
      </Button>
    </ButtonsRow>
  );
};

export default ClientProjectActions;
