// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {
  CurrencyInput,
  DateInput,
  DropdownInputVirtualized,
  PercentInput,
  Space,
  Styled,
  TimeInput,
} from '@supermove/components';
import {Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';

const FieldGroup = Styled.View`
  flex-direction: row;
`;

const TextInput = Styled.TextInput`
  ${Typography.Body3}
`;

const getVariableOption = ({valueForm, key}) => {
  const variableOption = _.find(valueForm.variableOptions, (variableOption) => {
    return variableOption.key === key;
  });

  return variableOption;
};

const handleDropdownSelection = ({valueForm, form, field, handleBlur, amount}) => {
  const setFieldValue = form.customSetFieldValue || form.setFieldValue;

  // This sets the "key" on the ValueForm. We set it to OTHER so that the custom
  // amount is saved to the backend.
  setFieldValue(`${field}.key`, 'OTHER');

  // This sets the "amount" on the ValueForm.
  setFieldValue(`${field}.amount`, amount);

  // While the other inputs handleBlur separately, here we incorporate it into
  // the setFieldValue to resolve any race conditions that happen between the
  // dropdown selection and the blur.
  handleBlur({...valueForm, key: 'OTHER', amount});
};

const DropdownInputField = ({
  form,
  field,
  label,
  disabled,
  required,
  placeholder,
  options,
  optionHeight,
  dropdownSetFieldValue,
  inputComponent,
  handleBlur,
  isTruncatedLabel,
  tooltip,
}) => {
  const valueForm = _.get(form.values, field);

  return (
    <FieldGroup>
      <FieldInput.Memoized
        {...form}
        component={DropdownInputVirtualized}
        name={`${field}.key`}
        label={label}
        isTruncatedLabel={isTruncatedLabel}
        tooltip={tooltip}
        isResponsive
        input={{
          disabled,
          required,
          component: TextInput,
          placeholder,
          options,
          optionHeight,
          setFieldValue: dropdownSetFieldValue,
          setFieldTouched: form.setFieldTouched,
          style: {
            flex: 1,
          },
        }}
        style={{
          flex: 2,
        }}
      />
      <Space width={4} />
      <FieldInput.Memoized
        {...form}
        component={inputComponent}
        name={`${field}.amount`}
        label={'Amount'}
        isResponsive
        input={{
          disabled: valueForm.isEnabledDerivedBillingValues ? disabled : valueForm.key !== 'OTHER',
          required,
          component: TextInput,
          placeholder,
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
        }}
        style={{
          flex: 1,
        }}
        handleBlur={handleBlur}
      />
    </FieldGroup>
  );
};

const ValueField = ({
  required,
  field,
  form,
  optionHeight,
  handleBlur,
  isTruncatedLabel,
  isDisabled,
}) => {
  const valueForm = _.get(form.values, field);
  const disabled = isDisabled || valueForm.isDisabled;
  const label = valueForm.name;
  const {description} = valueForm;
  const name = `${field}.amount`;
  const placeholder = 'Enter value';
  const isRequired = required && !_.get(form.values, `${field}.amount`);
  const setFieldValue = form.customSetFieldValue || form.setFieldValue;

  switch (valueForm.format) {
    case VariableFormat.CITY_VALUE_CURRENCY:
    case VariableFormat.DROPDOWN_CURRENCY:
    case VariableFormat.VALUE_TABLE_CURRENCY:
      return (
        <DropdownInputField
          form={form}
          field={field}
          label={label}
          disabled={disabled}
          required={required}
          optionHeight={optionHeight}
          placeholder={placeholder}
          tooltip={description || null}
          options={valueForm.variableOptions.map((variableOption) => ({
            // The "value" on the dropdown is the unique "key" on the VariableOption.
            label: variableOption.label,
            value: variableOption.key,
          }))}
          dropdownSetFieldValue={(fieldName, value) => {
            const variableOption = getVariableOption({valueForm, key: value});
            const amount = Currency.toForm(variableOption.value);
            handleDropdownSelection({valueForm, form, field, handleBlur, amount});
          }}
          inputComponent={CurrencyInput}
          handleBlur={() => {
            handleBlur(valueForm);
          }}
          isTruncatedLabel={isTruncatedLabel}
        />
      );
    case VariableFormat.CURRENCY:
      return (
        <FieldInput.Memoized
          {...form}
          component={CurrencyInput}
          name={name}
          label={label}
          isTruncatedLabel={isTruncatedLabel}
          tooltip={description || null}
          isResponsive
          input={{
            disabled,
            required: isRequired,
            component: TextInput,
            placeholder,
            setFieldValue,
            setFieldTouched: form.setFieldTouched,
          }}
          style={{
            flex: 1,
          }}
          handleBlur={() => {
            handleBlur(valueForm);
          }}
        />
      );
    case VariableFormat.PERCENT:
      return (
        <FieldInput.Memoized
          {...form}
          component={PercentInput}
          name={name}
          label={label}
          isTruncatedLabel={isTruncatedLabel}
          tooltip={description || null}
          isResponsive
          input={{
            disabled,
            required: isRequired,
            component: TextInput,
            placeholder,
            setFieldValue,
            setFieldTouched: form.setFieldTouched,
          }}
          style={{
            flex: 1,
          }}
          handleBlur={() => {
            handleBlur(valueForm);
          }}
        />
      );
    case VariableFormat.DROPDOWN_STRING:
      return (
        <FieldInput.Memoized
          {...form}
          component={DropdownInputVirtualized}
          name={`${field}.stringValue`}
          label={label}
          isTruncatedLabel={isTruncatedLabel}
          tooltip={description || null}
          isResponsive
          input={{
            disabled,
            required: required && !_.get(form.values, `${field}.stringValue`),
            component: TextInput,
            placeholder,
            options: valueForm.variableOptions.map((variableOption) => ({
              label: variableOption.label,
              value: variableOption.value,
            })),
            setFieldValue: (fieldName, value) => {
              setFieldValue(`${field}.stringValue`, value);
              handleBlur({...valueForm, stringValue: value});
            },
            setFieldTouched: form.setFieldTouched,
            style: {
              flex: 1,
            },
          }}
          style={{
            flex: 2,
          }}
        />
      );
    case VariableFormat.DROPDOWN_PERCENT:
      return (
        <DropdownInputField
          form={form}
          field={field}
          label={label}
          disabled={disabled}
          optionHeight={optionHeight}
          required={required}
          placeholder={placeholder}
          options={valueForm.variableOptions.map((variableOption) => ({
            // The "value" on the dropdown is the unique "key" on the VariableOption.
            label: variableOption.label,
            value: variableOption.key,
          }))}
          dropdownSetFieldValue={(fieldName, value) => {
            const variableOption = getVariableOption({valueForm, key: value});
            const amount = Percent.toForm(variableOption.value);
            handleDropdownSelection({valueForm, form, field, handleBlur, amount});
          }}
          inputComponent={PercentInput}
          handleBlur={() => {
            handleBlur(valueForm);
          }}
          isTruncatedLabel={isTruncatedLabel}
          tooltip={description || null}
        />
      );
    case VariableFormat.DROPDOWN_DISTANCE:
    case VariableFormat.DROPDOWN_FLOAT:
    case VariableFormat.DROPDOWN_INTEGER:
    case VariableFormat.DROPDOWN_WEIGHT:
      return (
        <DropdownInputField
          form={form}
          field={field}
          label={label}
          disabled={disabled}
          optionHeight={optionHeight}
          required={required}
          placeholder={placeholder}
          options={valueForm.variableOptions.map((variableOption) => ({
            // The "value" on the dropdown is the unique "key" on the VariableOption.
            label: variableOption.label,
            value: variableOption.key,
          }))}
          dropdownSetFieldValue={(fieldName, value) => {
            const variableOption = getVariableOption({valueForm, key: value});
            const amount = variableOption.value;
            handleDropdownSelection({valueForm, form, field, handleBlur, amount});
          }}
          inputComponent={TextInput}
          handleBlur={() => {
            handleBlur(valueForm);
          }}
          isTruncatedLabel={isTruncatedLabel}
          tooltip={description || null}
        />
      );
    case VariableFormat.STRING:
      return (
        <FieldInput.Memoized
          {...form}
          name={`${field}.stringValue`}
          label={label}
          isTruncatedLabel={isTruncatedLabel}
          tooltip={description || null}
          isResponsive
          input={{
            disabled,
            required: isRequired,
            placeholder,
          }}
          style={{
            flex: 1,
          }}
          handleBlur={() => {
            handleBlur(valueForm);
          }}
        />
      );
    case VariableFormat.DATE:
      return (
        <FieldInput
          {...form}
          component={DateInput}
          label={label}
          name={`${field}.stringValue`}
          isResponsive
          input={{
            isPortaled: true,
            disabled,
            placeholder: 'Enter date',
            required: isRequired,
            setFieldValue: (name, value) => {
              form.setFieldValue(name, value);
              // handleBlur is called directly to avoid a race condition
              handleBlur({...valueForm, stringValue: value || ''});
            },
            style: {width: '100%'},
          }}
          style={{flex: 1}}
        />
      );
    case VariableFormat.TIME:
      return (
        <FieldInput
          {...form}
          index={1}
          label={label}
          component={TimeInput}
          name={`${field}.stringValue`}
          isResponsive
          input={{
            disabled,
            component: TextInput,
            placeholder: 'Enter time',
            required: isRequired,
            setFieldValue: form.setFieldValue,
            style: {width: '100%'},
            invalidTimeValue: '',
            handleBlur: () => {
              handleBlur(valueForm);
            },
          }}
          style={{flex: 1}}
        />
      );
    case VariableFormat.DISTANCE:
    case VariableFormat.FLOAT:
    case VariableFormat.INTEGER:
    case VariableFormat.WEIGHT:
    default:
      return (
        <FieldInput.Memoized
          {...form}
          name={name}
          label={label}
          isTruncatedLabel={isTruncatedLabel}
          tooltip={description || null}
          isResponsive
          input={{
            disabled,
            required: isRequired,
            placeholder,
          }}
          style={{
            flex: 1,
          }}
          handleBlur={() => {
            handleBlur(valueForm);
          }}
        />
      );
  }
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ValueField.propTypes = {
  required: PropTypes.bool,
  field: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  optionHeight: PropTypes.number,
  handleBlur: PropTypes.func,
  isTruncatedLabel: PropTypes.bool,
};

ValueField.defaultProps = {
  optionHeight: null,
  handleBlur: () => {},
  required: null,
  isTruncatedLabel: false,
};

export default ValueField;
