// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import JobProjectDocumentsCollapsibleTables from 'modules/Job/V2/Move/components/JobProjectDocumentsCollapsibleTables';

const JobProjectDocumentsBlock = ({job, refetch}) => {
  return <JobProjectDocumentsCollapsibleTables job={job} refetch={refetch} />;
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobProjectDocumentsBlock.propTypes = {
  job: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

JobProjectDocumentsBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectDocumentsBlock.fragment = gql`
  ${JobProjectDocumentsCollapsibleTables.fragment}
  fragment JobProjectDocumentsBlock on Job {
    id
    ...JobProjectDocumentsCollapsibleTables
  }
`;

export default JobProjectDocumentsBlock;
