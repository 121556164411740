// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';

const MessageModal = ({
  icon,
  iconColor,
  title,
  message,
  isOpen,
  handlePressOutside,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionText,
  secondaryActionText,
  isDisabled,
  isSubmitting,
  isLoading,
}: any) => {
  return (
    <ActionModal
      icon={icon}
      iconColor={iconColor}
      titleColor={colors.gray.primary}
      title={title}
      message={message}
      isOpen={isOpen}
      handlePressOutside={handlePressOutside}
      handlePrimaryAction={handlePrimaryAction}
      handleSecondaryAction={handleSecondaryAction}
      primaryActionText={primaryActionText}
      secondaryActionText={secondaryActionText}
      isDisabled={isDisabled}
      isSubmitting={isSubmitting}
      isLoading={isLoading}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
MessageModal.propTypes = {
  icon: Icon.SourcePropType,
  iconColor: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handlePressOutside: PropTypes.func,
  handlePrimaryAction: PropTypes.func.isRequired,
  handleSecondaryAction: PropTypes.func,
  primaryActionText: PropTypes.string.isRequired,
  secondaryActionText: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
};

MessageModal.defaultProps = {
  icon: null,
  iconColor: null,
  handlePressOutside: () => {},
  handleSecondaryAction: null,
  secondaryActionText: '',
  isDisabled: false,
  isSubmitting: false,
  isLoading: false,
};

export default MessageModal;
