// Libraries
import React from 'react';

// Supermove
import {Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import WebhookForm from '@shared/modules/Integration/forms/WebhookForm';
import useUpdateWebhookMutation from '@shared/modules/Integration/hooks/useUpdateWebhookMutation';
import OrganizationWebhookModalContent from 'modules/Organization/Settings/Integrations/components/OrganizationWebhookModalContent';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const EditOrganizationWebhookModal = ({webhook, isOpen, handleClose, refetch}) => {
  const webhookForm = WebhookForm.edit(webhook);
  const {form, submitting, handleSubmit} = useUpdateWebhookMutation({
    webhookForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Overlay pointerEvents={'box-none'}>
        <OrganizationWebhookModalContent
          titleText='Edit Webhook'
          subTitleText='Edit details for this webhook.'
          form={form}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
      </Overlay>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditOrganizationWebhookModal.fragment = gql`
  ${WebhookForm.edit.fragment}

  fragment EditOrganizationWebhookModal on Webhook {
    id
    ...WebhookForm_edit
  }
`;

export default EditOrganizationWebhookModal;
