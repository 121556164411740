// Libraries
import _ from 'lodash';

const QUOTE = 'QUOTE';
const CONFIRMATION = 'CONFIRMATION';

const getDisplay = (kind: any) => _.toLower(kind);

const ConfirmationStepsKind = {
  QUOTE,
  CONFIRMATION,

  getDisplay,
};

export default ConfirmationStepsKind;
