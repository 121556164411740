const FULL_PAID = 'FULL_PAID';
const PARTIAL_PAID = 'PARTIAL_PAID';
const UNPAID = 'UNPAID';

const OPTIONS = [
  {
    value: FULL_PAID,
    label: 'Paid (full)',
  },
  {
    value: PARTIAL_PAID,
    label: 'Paid (partial)',
  },
  {
    value: UNPAID,
    label: 'Unpaid',
  },
];

export default {
  FULL_PAID,
  PARTIAL_PAID,
  UNPAID,

  OPTIONS,
};
