// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Datetime} from '@supermove/utils';

const edit = withFragment(
  (job) => ({
    jobId: (job as any).id,
    endDate: (job as any).endDate,
  }),
  gql`
    fragment CompleteEstimatedRangeJobForm_edit on Job {
      id
      endDate
    }
  `,
);

const toForm = ({jobId, endDate}: any) => ({
  jobId,
  endDate: Datetime.toFormDate(endDate),
});

const toMutation = ({jobId, endDate}: any) => ({
  jobId,
  endDate: Datetime.toMutationDate(endDate),
});

const CompleteEstimatedRangeJobForm = {
  edit,
  toForm,
  toMutation,
};

export default CompleteEstimatedRangeJobForm;
