// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Callout from '@shared/design/components/Callout';
import ExploDashboard from '@shared/modules/Reports/components/ExploDashboard';
import Line from 'modules/App/components/Line';
import GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeader, {
  GlobalDashboardDataType,
} from 'modules/Report/Library/components/GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeader';

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
  color: ${colors.gray.primary};
  text-decoration-color: ${colors.gray.primary};
`;

const LinkButton = Styled.ButtonV2`
`;

const Container = Styled.View`
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

const HeaderContainer = Styled.View`
  width: 100%;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 16px;
  padding-horizontal: 24px;
`;

const ExploContainer = Styled.View`
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

type QueryResponse = {
  viewer: {
    id: string;
    viewingOrganization: {
      id: number;
      exploUserGroupToken: string;
    };
  };
  globalDashboard: GlobalDashboardDataType & {
    id: string;
    name: string;
    exploDashboardEmbedId: string;
    exploVariables: string;
  };
};

type GlobalDashboardsLibraryPageGlobalDashboardViewPanelProps = {
  selectedGlobalDashboardUuid: string;
  existingDashboardUuid?: string;
};

const GlobalDashboardsLibraryPageGlobalDashboardViewPanel = ({
  selectedGlobalDashboardUuid,
  existingDashboardUuid,
}: GlobalDashboardsLibraryPageGlobalDashboardViewPanelProps) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  const {data, loading} = useQuery<QueryResponse>(
    GlobalDashboardsLibraryPageGlobalDashboardViewPanel.query,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        uuid: selectedGlobalDashboardUuid,
      },
    },
  );

  return (
    <Loading loading={loading || !data}>
      {() =>
        data && (
          <React.Fragment>
            <Container>
              <HeaderContainer>
                {!!existingDashboardUuid && (
                  <React.Fragment>
                    <Callout>
                      <Callout.Text>
                        <LinkButton
                          onPress={() => {
                            navigator.push(`/reports/${existingDashboardUuid}/view`);
                          }}
                        >
                          <LinkText responsive={responsive}>
                            This report is already in your library.
                          </LinkText>
                        </LinkButton>
                        &nbsp;Adding this report again will add it as a custom report.
                      </Callout.Text>
                    </Callout>
                    <Space height={16} />
                  </React.Fragment>
                )}
                <GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeader
                  globalDashboard={data.globalDashboard}
                />
              </HeaderContainer>
              <Line />
              <ExploContainer style={responsive.desktop ? {padding: 24} : {}}>
                <ExploDashboard
                  name={data.globalDashboard.name}
                  viewerId={_.toNumber(data.viewer.id)}
                  exploDashboardEmbedId={data.globalDashboard.exploDashboardEmbedId}
                  exploUserGroupToken={data.viewer.viewingOrganization.exploUserGroupToken}
                  exploVariables={data.globalDashboard.exploVariables}
                  style={{
                    flex: 1,
                    height: responsive.desktop ? undefined : 564,
                  }}
                />
              </ExploContainer>
            </Container>
          </React.Fragment>
        )
      }
    </Loading>
  );
};

GlobalDashboardsLibraryPageGlobalDashboardViewPanel.query = gql`
  ${GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeader.fragment}
  query GlobalDashboardsLibraryPageGlobalDashboardViewPanel ($uuid: String!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        exploUserGroupToken
      }
    }
    globalDashboard(uuid: $uuid) { 
      id
      name
      exploDashboardEmbedId
      exploVariables
      ...GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragment
    }
  }
`;

export default GlobalDashboardsLibraryPageGlobalDashboardViewPanel;
