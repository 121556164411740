// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UpdateOfficePaymentMethodsForm, {
  UpdateOfficePaymentMethodsFormType,
} from '@shared/modules/PaymentMethod/forms/UpdateOfficePaymentMethodsForm';

const useUpdateOfficePaymentMethodsMutation = ({
  updateOfficePaymentMethodsForm,
  onSuccess,
  onError,
}: {
  updateOfficePaymentMethodsForm: UpdateOfficePaymentMethodsFormType;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      updateOfficePaymentMethodsForm: UpdateOfficePaymentMethodsForm.toForm(
        updateOfficePaymentMethodsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOfficePaymentMethodsMutation.mutation,
    variables: {
      updateOfficePaymentMethodsForm: UpdateOfficePaymentMethodsForm.toMutation(
        form.values.updateOfficePaymentMethodsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOfficePaymentMethodsMutation.mutation = gql`
  mutation useUpdateOfficePaymentMethodsMutation($updateOfficePaymentMethodsForm: UpdateOfficePaymentMethodsForm!) {
    response: updateOfficePaymentMethods(updateOfficePaymentMethodsForm: $updateOfficePaymentMethodsForm) {
      ${gql.errors}
      organization {
        id
      }
    }
  }
`;

export default useUpdateOfficePaymentMethodsMutation;
