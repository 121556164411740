// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import JobInfoSection from 'modules/Job/components/JobInfoSection';

const JobInfoBlock = ({job}) => {
  return <JobInfoSection sectionIndex={0} job={job} />;
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobInfoBlock.propTypes = {
  job: PropTypes.object.isRequired,
};

JobInfoBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobInfoBlock.fragment = gql`
  ${JobInfoSection.fragment}

  fragment JobInfoBlock on Job {
    id
    ...JobInfoSection
  }
`;

export default JobInfoBlock;
