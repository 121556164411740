/* eslint-disable no-case-declarations */
// Libraries
import _ from 'lodash';
import moment from 'moment';

// Supermove
import {Datetime} from '@supermove/utils';

const WEEKLY = 'WEEKLY';
const MONTHLY = 'MONTHLY';
const BI_MONTHLY = 'BI_MONTHLY';

const getDisplayKind = (kind: any) => {
  return _.startCase(_.toLower(kind));
};

const getNextReportCycleDate = (cadenceKind: any, {cadenceRepeatsOn}: any, startDate: any) => {
  switch (cadenceKind) {
    case WEEKLY:
      const targetDayOfWeek = cadenceRepeatsOn + 1; // MomentJS weekdays are off by one compared to our server dates
      return Datetime.toDisplayDate(
        Datetime.nextOccurenceOfDayOfWeek(targetDayOfWeek),
        'MM/DD/YYYY',
      );
    case MONTHLY:
      if (startDate) {
        return Datetime.toDisplayDate(
          Datetime.nextOccurenceOfDayOfMonth(cadenceRepeatsOn, startDate),
          'MM/DD/YYYY',
        );
      }
      return Datetime.toDisplayDate(
        Datetime.nextOccurenceOfDayOfMonth(cadenceRepeatsOn),
        'MM/DD/YYYY',
      );
    case BI_MONTHLY:
      if (moment().date() < 15) {
        return Datetime.toDisplayDate(Datetime.nextOccurenceOfDayOfMonth(15), 'MM/DD/YYYY');
      } else {
        return Datetime.toDisplayDate(Datetime.nextOccurenceOfDayOfMonth(1), 'MM/DD/YYYY');
      }
    default:
      return '[MM/DD/YYYY]';
  }
};

export default {
  WEEKLY,
  MONTHLY,
  BI_MONTHLY,

  VALUES: [WEEKLY, MONTHLY, BI_MONTHLY],

  getDisplayKind,
  getNextReportCycleDate,
};
