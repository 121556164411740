// Libraries
import React from 'react';

// Supermove
import {Space, Loading, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import SmallModal from '@shared/design/components/Modal/SmallModal';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import TagAssignmentForm from '@shared/modules/Tag/forms/TagAssignmentForm';
import useUpsertTagAssignmentMutation from '@shared/modules/Tag/hooks/useUpsertTagAssignmentMutation';
import {PageLoadingIndicator} from 'modules/App/components';
import TagAssignmentFields from 'modules/Tag/components/TagAssignmentFields';

const UpsertProjectTagModal = ({projectId, projectUuid, tagIds, isOpen, handleClose, refetch}) => {
  const tagAssignmentForm = TagAssignmentForm.new({projectId, tagIds});
  const {form, submitting, handleSubmit} = useUpsertTagAssignmentMutation({
    tagAssignmentForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  const {loading, data} = useQuery(UpsertProjectTagModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      categories: [TagCategory.PROJECT],
      projectUuid,
    },
    skip: !isOpen,
  });

  return (
    <SmallModal
      isOpen={isOpen}
      handlePressOutside={handleClose}
      style={{overflow: 'visible', maxHeight: 350}}
    >
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => {
          const projectTags = data.project.organization.companySettings.tags.filter(
            (tag) => tag.category === TagCategory.PROJECT,
          );
          return (
            <React.Fragment>
              <SmallModal.HeaderText>Edit Project Tags</SmallModal.HeaderText>
              <Space height={12} />
              <SmallModal.Text>Select from existing tags.</SmallModal.Text>
              <Space height={12} />
              <ScrollView style={{maxHeight: 300}}>
                <TagAssignmentFields tags={projectTags} form={form} />
              </ScrollView>
              <Space height={16} />
              <SmallModal.Footer>
                <SmallModal.Button isDisabled={submitting} onPress={handleClose}>
                  Cancel
                </SmallModal.Button>
                <Space width={8} />
                <SmallModal.Button
                  isSubmitting={submitting}
                  color={colors.blue.interactive}
                  onPress={handleSubmit}
                >
                  Save
                </SmallModal.Button>
              </SmallModal.Footer>
            </React.Fragment>
          );
        }}
      </Loading>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpsertProjectTagModal.query = gql`
  ${TagAssignmentFields.fragment}

  query UpsertProjectTagModal($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      organization {
        id
        companySettings {
          tags {
            id
            category
          }
        }
        ...TagAssignmentFields
      }
    }
  }
`;

export default UpsertProjectTagModal;
