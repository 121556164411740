const SEND_EMAIL = 'SEND_EMAIL';
const SEND_SMS = 'SEND_SMS';
const NONE = 'NONE';

const DROPDOWN_OPTIONS = [
  {value: NONE, label: 'None'},
  {value: SEND_EMAIL, label: 'Send email'},
  {value: SEND_SMS, label: 'Send SMS'},
];

export default {
  SEND_EMAIL,
  SEND_SMS,
  NONE,
  DROPDOWN_OPTIONS,
};
