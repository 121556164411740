// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useState} from '@supermove/hooks';
import {Location} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Currency, Datetime, pluralize} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import TextBadge from 'modules/App/components/TextBadge';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TripNameHeading = Styled.Text`
  ${Typography.Heading6}
`;

const TripItemContainer = Styled.Touchable`
  margin-horizontal: ${({isListItem}) => (isListItem ? '16px' : `0px`)};
  padding-horizontal: 12px;
  border-width: ${({isListItem}) => (isListItem ? '1px' : `0px`)};
  border-color: ${colors.gray.border};
  border-radius: ${({isListItem}) => (isListItem ? '4px' : '0px')};
  background-color: ${colors.white};
`;

const IconContainer = Styled.View`
  width: 16px;
  align-items: center;
`;

const TripItemInfoText = Styled.Text`
  ${Typography.Body3}
`;

const TripNameRow = ({trip}) => {
  const {label, textColor} = TripStatus.getStatusOption(trip.status);
  return (
    <Row>
      <TripNameHeading>{trip.name}</TripNameHeading>
      <Space width={8} />
      <TextBadge isLabel isSmall text={label} color={textColor} />
    </Row>
  );
};

const RowInformation = ({icon, text}) => {
  return (
    <React.Fragment>
      <Space height={8} />
      <Row>
        <IconContainer>
          <Icon source={icon} size={14} color={colors.gray.primary} />
        </IconContainer>
        <Space width={8} />
        <TripItemInfoText>{text}</TripItemInfoText>
      </Row>
    </React.Fragment>
  );
};

const TripDatesRow = ({trip}) => {
  const startDate = trip.startDate
    ? Datetime.convertToDisplayDate(trip.startDate, 'M/D/YY')
    : 'TBD';
  const endDate = trip.endDate ? Datetime.convertToDisplayDate(trip.endDate, 'M/D/YY') : 'TBD';
  return <RowInformation icon={Icon.Calendar} text={`${startDate} - ${endDate}`} />;
};

const TripLocationsRow = ({trip}) => {
  const startLocation = Location.getDisplayCityStateZip(trip.startLocation, 'TBD');
  const endLocation = Location.getDisplayCityStateZip(trip.endLocation, 'TBD');
  return <RowInformation icon={Icon.MapMarkedAlt} text={`${startLocation} - ${endLocation}`} />;
};

const TripCompensationRow = ({trip}) => {
  return <RowInformation icon={Icon.DollarSign} text={Currency.display(trip.totalCompensation)} />;
};

const TripDriverAndTrucksRow = ({trip}) => {
  const truckNames = trip.tripSegments[0].trucks.map((truck) => truck.name);
  const truckText = _.isEmpty(truckNames) ? '--' : `${truckNames.join(', ')}`;
  const driverText = _.get(trip, 'driver.fullName', '--');

  return <RowInformation icon={Icon.Truck} text={`${driverText} / ${truckText}`} />;
};

const TripDriverNotes = ({trip}) => {
  return (
    <React.Fragment>
      <Space height={8} />
      <Row style={{alignItems: 'flex-start'}}>
        <IconContainer style={{paddingTop: 3}}>
          <Icon source={Icon.StickyNote} size={14} color={colors.gray.primary} />
        </IconContainer>
        <Space width={8} />
        <TripItemInfoText>{trip.driverNotes || '--'}</TripItemInfoText>
      </Row>
    </React.Fragment>
  );
};

const TripWeightRow = ({trip}) => {
  return (
    <RowInformation icon={Icon.WeightHanging} text={`${pluralize('lb', trip.totalWeight, true)}`} />
  );
};

const TripVolumeRow = ({trip}) => {
  return <RowInformation icon={Icon.RulerTriangle} text={`${trip.totalVolume} ft³`} />;
};

const LongDistanceDispatchTripCard = ({trip, isListItem}) => {
  const {navigator, params} = useNavigationDOM();
  const [isCollapse, setIsCollapse] = useState(false);

  return (
    <React.Fragment>
      <TripItemContainer
        disabled={!isListItem}
        isListItem={isListItem}
        onPress={() => {
          navigator.push(`/1/dispatch/trips/${params.subTab}/${trip.uuid}`);
        }}
      >
        <Space height={12} />
        <TripNameRow trip={trip} />
        <TripDatesRow trip={trip} />
        <TripLocationsRow trip={trip} />
        <TripCompensationRow trip={trip} />
        {!isListItem && (
          <React.Fragment>
            {!isCollapse && (
              <React.Fragment>
                <TripWeightRow trip={trip} />
                <TripVolumeRow trip={trip} />
                <TripDriverAndTrucksRow trip={trip} />
                <TripDriverNotes trip={trip} />
              </React.Fragment>
            )}
            <Space height={12} />
            <TertiaryButton
              onPress={() => setIsCollapse(!isCollapse)}
              iconRight={isCollapse ? Icon.ChevronDown : Icon.ChevronUp}
              text={isCollapse ? 'Show more' : 'Show less'}
            />
          </React.Fragment>
        )}
        <Space height={12} />
      </TripItemContainer>
      {isListItem && <Space height={4} />}
    </React.Fragment>
  );
};

LongDistanceDispatchTripCard.fragment = gql`
  ${Location.getDisplayCityStateZip.fragment}

  fragment LongDistanceDispatchTripCard on Trip {
    id
    uuid
    status
    name
    startDate
    endDate
    totalCompensation
    totalWeight
    totalVolume
    driverNotes
    startLocation {
      id
      ...Location_getDisplayCityStateZip
    }
    endLocation {
      id
      ...Location_getDisplayCityStateZip
    }
    driver {
      id
      fullName
    }
    tripSegments {
      id
      trucks {
        id
        name
      }
    }
  }
`;

export default LongDistanceDispatchTripCard;
