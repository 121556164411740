// Libraries
import Tippy from '@tippyjs/react';
import React from 'react';

// Supermove
import {Styled, Calendar, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useState, useMountEffect} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Datetime, URL} from '@supermove/utils';

// App
import ViewDayNotesPopoverContent from 'modules/App/Day/components/ViewDayNotesPopoverContent';
import CapacityCalendarDaySlotMetrics from 'modules/Calendar/Capacity/components/CapacityCalendarDaySlotMetrics';
import CapacityCalendarToolbarHeader from 'modules/Calendar/Capacity/components/CapacityCalendarToolbarHeader';

const Wrapper = Styled.View`
  flex: 1;
  flex-direction: row;
  background-color: ${colors.gray.background};
`;

const CalendarContainer = Styled.View`
  flex: 1;
  padding: 20px;
`;

const Section = Styled.View`
  flex: 1;
`;

const DateHeader = Styled.Text`
  ${Typography.Micro}
  color: ${({vars}) => (vars.isDaySelected ? colors.white : colors.gray.tertiary)};
`;

const DateCell = Styled.View`
  flex: 1;
  border-style: solid;
  height: 100%;
`;

const Touchable = Styled.Touchable`
  flex: 1;
`;

const DateHeaderContainer = Styled.View`
  padding-top: 5px;
  justify-content: flex-end;
  flex-direction: row;
`;

const DateCircle = Styled.View`
  height: 24px;
  width: 24px;
  background-color: ${({isDaySelected}) =>
    isDaySelected ? colors.blue.interactive : colors.white};
  border-radius: 16px;
  border-color: ${({isDaySelected}) =>
    isDaySelected ? colors.blue.interactive : colors.gray.border};
  border-style: solid;
  border-width: 1;
  justify-content: center;
  align-items: center;
`;

const getFilteredUrlFromParams = (params, baseUrl) => {
  return URL.getUrlFromVariables(baseUrl, params);
};

const handleUpdateParam = ({baseUrl, navigator, params, paramKey, paramValue}) => {
  navigator.push(getFilteredUrlFromParams({...params, [paramKey]: paramValue || ''}, baseUrl));
};

const CalendarDateCell = ({isDaySelected, cellDate, data, loading, organization, isSaturday}) => {
  const {navigator, params} = useNavigationDOM();
  const [isHovered, setIsHovered] = useState(false);

  if (loading || !params.slugs) {
    return (
      <DateCell
        isDaySelected={isDaySelected}
        loading={loading}
        style={{
          backgroundColor: `${colors.white}`,
          borderRightWidth: 1,
          borderRightColor: colors.gray.border,
        }}
      >
        <CapacityCalendarDaySlotMetrics.SkeletonLoader />
      </DateCell>
    );
  }

  return (
    <Touchable
      onPress={() => {
        handleUpdateParam({
          baseUrl: '/moves/capacity/calendar/day',
          navigator,
          params,
          paramKey: 'date',
          paramValue: cellDate,
        });
      }}
    >
      <Tippy
        interactive
        appendTo={document.body}
        // This is a hack to get the popover to render inbound
        placement={isSaturday ? 'left-start' : 'right-start'}
        delay={100}
        onShow={() => {
          setIsHovered(true);
        }}
        onHide={() => {
          setIsHovered(false);
        }}
        content={
          <ViewDayNotesPopoverContent
            width={300}
            slugs={params.slugs}
            date={cellDate}
            isPopoverOpen={isHovered}
          />
        }
        arrow={false}
      >
        {/* We need to wrap the initiating div in a span, Tippy requires a div that has event propagation and will not work with Styled Components */}
        <span
          style={{
            height: '100%',
            borderRight: isHovered
              ? `1px solid ${colors.blue.interactive}`
              : `1px solid ${colors.gray.border}`,
            borderLeft: isHovered
              ? `1px solid ${colors.blue.interactive}`
              : `1px solid transparent`,
            borderTop: isHovered ? `1px solid ${colors.blue.interactive}` : `1px solid transparent`,
            borderBottom: isHovered
              ? `1px solid ${colors.blue.interactive}`
              : `1px solid transparent`,
          }}
        >
          <DateCell isDaySelected={isDaySelected} backgroundColor={colors.white}>
            <CapacityCalendarDaySlotMetrics
              capacityCalendar={data.capacityCalendar}
              cellDate={cellDate}
            />
          </DateCell>
        </span>
      </Tippy>
    </Touchable>
  );
};

const CalendarPane = ({
  data,
  date,
  isController,
  loading,
  setCalendarMonthLabel,
  handleSetOnNavigate,
  organization,
}) => {
  const {navigator, params} = useNavigationDOM();
  return (
    <Section>
      <Calendar
        date={date}
        isSundayFirst={false}
        onNavigate={
          isController &&
          ((navigateDate) => {
            handleUpdateParam({
              baseUrl: '/moves/capacity/calendar/month',
              navigator,
              params,
              paramKey: 'date',
              paramValue: Datetime.toMutationDate(navigateDate),
            });
          })
        }
        dateCellWrapperComponent={({value}) => {
          const cellDate = Datetime.toMutationDate(value); // The date of this calendar cell.
          const isDaySelected = cellDate === date; // Whether this calendar cell is the active date selected.

          return (
            <CalendarDateCell
              isDaySelected={isDaySelected}
              cellDate={cellDate}
              data={data}
              date={date}
              loading={loading}
              organization={organization}
              // Prop is used for Tippy to find the end of the calendar
              isSaturday={value.getDay() === 6}
            />
          );
        }}
        toolbarComponent={({onNavigate, label}) => {
          handleSetOnNavigate(() => onNavigate);
          setCalendarMonthLabel(label);
          return null;
        }}
        dateHeaderComponent={({value, date: cellDate, label}) => {
          const isDaySelected = Datetime.toMutationDate(cellDate) === date; // The date of this calendar cell.

          return (
            <Touchable
              onPress={() => {
                handleUpdateParam({
                  baseUrl: '/moves/capacity/calendar/day',
                  navigator,
                  params,
                  paramKey: 'date',
                  paramValue: Datetime.toMutationDate(date),
                });
              }}
            >
              <DateHeaderContainer>
                <Space style={{flex: 1}} />
                <DateCircle isDaySelected={isDaySelected}>
                  <DateHeader vars={{isDaySelected}}>{label}</DateHeader>
                </DateCircle>
              </DateHeaderContainer>
            </Touchable>
          );
        }}
      />
    </Section>
  );
};

const Calendars = ({date, setCalendarMonthLabel, handleSetOnNavigate, organization}) => {
  const {params} = useNavigationDOM();
  const {data, loading} = useQuery(CapacityCalendar.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      month: Datetime.toMutationMonth(date),
      // Only the primary organization should default to all branches and franchises
      slugs: params.slugs
        ? params.slugs
        : organization.isPrimary
          ? ['ALL_ORGANIZATIONS']
          : [organization.slug],
    },
  });
  return (
    <CalendarPane
      data={data}
      date={date}
      loading={loading}
      isController
      organization={organization}
      setCalendarMonthLabel={setCalendarMonthLabel}
      handleSetOnNavigate={handleSetOnNavigate}
    />
  );
};

const CapacityCalendar = ({searchParams, organization}) => {
  const {navigator, params} = useNavigationDOM();
  const {location} = navigator;
  // Stripping these out of the library so that the toolbar doesn't re-render every time we navigate
  const [calendarMonthLabel, setCalendarMonthLabel] = useState('');
  const [onNavigate, handleSetOnNavigate] = useState(null);

  useMountEffect(() => {
    // If required params are not set in the route on mount,
    // set the correct variables
    if (!params.slugs) {
      handleUpdateParam({
        baseUrl: location.pathname,
        navigator,
        params,
        paramKey: 'slugs',
        paramValue: organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
      });
    }
  });

  return (
    <React.Fragment>
      <Wrapper>
        <CalendarContainer>
          <CapacityCalendarToolbarHeader
            onNavigate={onNavigate}
            label={calendarMonthLabel}
            organization={organization}
            canViewOtherBranchesData={organization.canViewOtherBranchesData}
          />
          <Calendars
            date={searchParams.date || Datetime.toMutationDate(Datetime.today)}
            navigator={navigator}
            setCalendarMonthLabel={setCalendarMonthLabel}
            handleSetOnNavigate={handleSetOnNavigate}
            organization={organization}
          />
        </CalendarContainer>
      </Wrapper>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendar.query = gql`
  ${CapacityCalendarDaySlotMetrics.fragment}
  query CapacityCalendar(
    $month: String!
    $slugs: [String!]!
  ) {
    ${gql.query}
    capacityCalendar(month: $month, slugs: $slugs) {
      ...CapacityCalendarDaySlotMetrics
	  }
  }
`;

CapacityCalendar.fragment = gql`
  ${CapacityCalendarToolbarHeader.fragment}

  fragment CapacityCalendar on Organization {
    id
    slug
    isPrimary
    hasMultipleOrganizations
    canViewOtherBranchesData
    ...CapacityCalendarToolbarHeader
  }
`;

export default CapacityCalendar;
