// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';
import {Currency, pluralize} from '@supermove/utils';

const DayItem = Styled.View`
  flex: 1;
  justify-content: center;
  padding-bottom: 10px;
  padding-horizontal: ${({vars}) => (vars?.compact ? '2px' : '10px')};
`;

const EmptyReportsCount = Styled.Text`
  ${Typography.Body3};
  color: ${colors.alpha(colors.gray.tertiary, 0.7)};
`;

const JobsCountRatio = Styled.Text`
  margin-top: 2px;
  margin-bottom: 10px;
  ${({vars}) => (vars.compact ? Typography.Label4 : Typography.Body2)};
  color: ${(props) => props.color};
`;

const Revenue = Styled.Text`
  align-self: flex-start;
  padding-vertical: 5px;
  padding-horizontal: ${({vars}) => (vars.compact ? '2px' : '8px')};
  margin-top: 4px;
  background-color: ${colors.alpha(colors.green.status, 0.1)};
  border-radius: 3px;
  ${({vars}) => (vars.compact ? Typography.Label4 : Typography.Body1)};
  color: ${colors.green.status};
  letter-spacing: 0.5px;
`;

const NotFinalRevenue = Styled.Text`
  align-self: flex-start;
  padding-vertical: 5px;
  padding-horizontal: ${({vars}) => (vars.compact ? '2px' : '8px')};
  margin-top: 4px;
  background-color: ${colors.alpha(colors.orange.status, 0.1)};
  border-radius: 3px;
  ${({vars}) => (vars.compact ? Typography.Label4 : Typography.Body1)};
  color: ${colors.orange.status};
  letter-spacing: 0.5px;
`;

const generateJobsCountRatio = (calendarDay, compact) => {
  const {reportsCount, notFinalCount} = calendarDay;
  const completedJobsCount = reportsCount - notFinalCount;
  const supportingText = compact ? '' : `${pluralize('job', reportsCount)} complete`;
  return `${completedJobsCount}/${reportsCount} ${supportingText}`;
};

const ReportsDayInfo = ({calendarDay, compact}) => {
  if (!calendarDay) {
    return <DayItem />;
  }

  return (
    <DayItem vars={{compact}}>
      {calendarDay.reportsCount > 0 ? (
        <React.Fragment>
          <JobsCountRatio
            numberOfLines={1}
            color={calendarDay.notFinalCount > 0 ? colors.orange.status : colors.green.status}
            vars={{compact}}
          >
            {generateJobsCountRatio(calendarDay, compact)}
          </JobsCountRatio>
          {calendarDay.notFinalCount > 0 ? (
            <NotFinalRevenue numberOfLines={1} vars={{compact}}>
              {Currency.display(calendarDay.totalRevenue, {shouldHideCentsIfZero: true})}
            </NotFinalRevenue>
          ) : (
            <Revenue numberOfLines={1} vars={{compact}}>
              {Currency.display(calendarDay.totalRevenue, {shouldHideCentsIfZero: true})}
            </Revenue>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <EmptyReportsCount numberOfLines={1}>{compact ? '0/0' : 'No reports'}</EmptyReportsCount>
        </React.Fragment>
      )}
    </DayItem>
  );
};

export default ReportsDayInfo;
