// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useMountEffect, useDrawer, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {URL} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import Callout from '@shared/design/components/Callout';
import Tabs from '@shared/design/components/Tabs';
import TagKind from '@shared/modules/Tag/enums/TagKind';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CreateTagDrawer from 'modules/Tag/components/CreateTagDrawer';
import TagsTable from 'modules/Tag/components/TagsTable';

const Container = Styled.View`
`;

const PageHeaderText = Styled.Text`
  ${Typography.PageHeading}
`;

const PageDescriptionText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const TableNameText = Styled.Text`
  ${Typography.Label}
`;

const Row = Styled.View`
  flex-direction: row;
`;

const getFilteredUrlFromParams = (params, baseUrl) => {
  return URL.getUrlFromVariables(baseUrl, params);
};

const handleUpdateParam = ({baseUrl, navigator, params, paramKey, paramValue}) => {
  navigator.replace(getFilteredUrlFromParams({...params, [paramKey]: paramValue || ''}, baseUrl));
};

const tabDefinitions = [
  {
    key: 'active',
    label: 'Active Tags',
  },
  {
    key: 'archived',
    label: 'Archived Tags',
    prependSeparator: true,
  },
];

const TagsSettingHeader = ({
  location,
  navigator,
  params,
  organization,
  refetch,
  isNotPrimaryOrg,
}) => {
  const activeTabIndex = _.findIndex(tabDefinitions, {key: params.status});

  const createTagDrawer = useDrawer({name: 'Create Tag Drawer', enableTracking: true});

  return (
    <React.Fragment>
      <Row>
        <Container>
          <PageHeaderText>Tags</PageHeaderText>
          <Space height={8} />
          <PageDescriptionText>
            Tags help to categorize, filter, and locate your projects and jobs.
          </PageDescriptionText>
        </Container>
        <Space style={{flex: 1}} />
        {!isNotPrimaryOrg && (
          <Button
            iconLeft={Icon.Plus}
            text={'Create New Tag'}
            onPress={createTagDrawer.handleOpen}
          />
        )}
      </Row>
      <Space height={24} />
      {!isNotPrimaryOrg ? (
        <Tabs
          tabs={tabDefinitions}
          handlePressTab={({key}) =>
            handleUpdateParam({
              baseUrl: location.pathname,
              navigator,
              params,
              paramKey: 'status',
              paramValue: key,
            })
          }
          activeTabIndex={activeTabIndex}
        />
      ) : (
        <Callout
          text={`Tag settings are set by the company. Please contact an admin at ${organization.company.primaryOrganization.name} to make adjustments.`}
          width={780}
        />
      )}
      <CreateTagDrawer
        key={createTagDrawer.key}
        isOpen={createTagDrawer.isOpen}
        handleClose={createTagDrawer.handleClose}
        organizationId={organization.id}
        onSuccess={() => {
          createTagDrawer.handleClose();
          refetch();
        }}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsTagsContent = () => {
  const {navigator, params} = useNavigationDOM();
  const {location} = navigator;

  // Set the param status of tags to active if its not present
  useMountEffect(() => {
    if (!params.status) {
      handleUpdateParam({
        baseUrl: location.pathname,
        navigator,
        params,
        paramKey: 'status',
        paramValue: 'active',
      });
    }
  });

  const {loading, data, refetch} = useQuery(OrganizationSettingsTagsContent.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading || !data) {
    return <PageLoadingIndicator />;
  }

  const organization = data.viewer.viewingOrganization;

  const filterByArchivedTag = organization.companySettings.tags.filter((tag) =>
    params.status === 'archived' ? tag.isArchived : !tag.isArchived,
  );

  const sortedCustomTags = _.sortBy(
    filterByArchivedTag.filter((tag) => [TagKind.CUSTOM, TagKind.DEFAULT].includes(tag.kind)),
    ['name'],
  );
  const sortedSystemTags = _.sortBy(
    filterByArchivedTag.filter((tag) => ![TagKind.CUSTOM, TagKind.DEFAULT].includes(tag.kind)),
    ['name'],
  );

  const isNotPrimaryOrg =
    organization.features.isEnabledProjectTagMultibranch && !organization.isPrimary;

  return (
    <React.Fragment>
      <TagsSettingHeader
        navigator={navigator}
        params={params}
        location={location}
        organization={organization}
        refetch={refetch}
        isNotPrimaryOrg={isNotPrimaryOrg}
      />
      {!isNotPrimaryOrg && (
        <React.Fragment>
          <TagsTable organization={organization} refetch={refetch} tags={sortedCustomTags} />
          <Space height={24} />
          <TableNameText>System Tags</TableNameText>
          <Space height={12} />
          <TagsTable
            organization={organization}
            refetch={refetch}
            tags={sortedSystemTags}
            isSystemTag
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsTagsContent.query = gql`
  ${CreateTagDrawer.fragment}
  ${TagsTable.fragment}

  query OrganizationSettingsTagsContent {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        isPrimary
        features {
          isEnabledProjectTagMultibranch: isEnabled(feature: "PROJECT_TAG_MULTIBRANCH")
        }
        company {
          id
          primaryOrganization {
            id
            name
          }
        }
        companySettings {
          tags {
            id
            kind
            ...TagsTable
          }
        }
        ...CreateTagDrawer
      }
    }
  }
`;

export default OrganizationSettingsTagsContent;
