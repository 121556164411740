// Libararies
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import ContainerForm from '@shared/modules/Storage/forms/ContainerForm';
import useCreateContainerMutation from '@shared/modules/Storage/hooks/useCreateContainerMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ContainerModalContent from 'modules/Storage/components/ContainerModalContent';

const CreateContainerModalContent = ({
  organization,
  handleClose,
  viewer,
  refetch,
  refetchModal,
}) => {
  const containerForm = ContainerForm.new({organizationId: organization.id, userId: viewer.id});
  const field = 'containerForm';
  const {form, handleSubmit, submitting} = useCreateContainerMutation({
    containerForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.HeaderText>{'Add Container'}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body style={{zIndex: 100}}>
        <ContainerModalContent
          form={form}
          field={field}
          viewer={viewer}
          organization={organization}
          refetch={refetchModal}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button onPress={handleClose} isDisabled={submitting}>
          {'Cancel'}
        </Modal.Button>
        <Space width={8} />
        <Modal.Button
          onPress={handleSubmit}
          isSubmitting={submitting}
          color={colors.blue.interactive}
        >
          {'Save'}
        </Modal.Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

const CreateContainerModal = ({isOpen, handleClose, refetch}) => {
  const {
    data,
    loading,
    refetch: refetchModal,
  } = useQuery(CreateContainerModal.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Modal isOpen={isOpen} width={Modal.WIDTH.SMALL}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <CreateContainerModalContent
              organization={data.viewer.viewingOrganization}
              viewer={data.viewer}
              handleClose={handleClose}
              refetch={refetch}
              refetchModal={refetchModal}
            />
          );
        }}
      </Loading>
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateContainerModal.query = gql`
  ${ContainerModalContent.fragment}
  query CreateContainerModal {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...ContainerModalContent
      }
    }
  }
`;

export default CreateContainerModal;
