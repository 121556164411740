// Libararies
import React from 'react';

// Supermove
import {Icon, Styled, Space} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 4px
  padding-horizontal: 8px;
  border-radius: 4px;
  background-color: ${({color}) => color};
  ${({responsive}) => !responsive.desktop && 'height: 32px;'}
`;

const StatusBadgeText = Styled.Text`
  ${({size}) => (size === 'LARGE' ? Typography.Label3 : Typography.Label4)}
  color: ${({color}) => color};
`;

const SIZE = {
  SMALL: 'SMALL',
  LARGE: 'LARGE',
};

// TODO(cassie): This component is deprecated, use the component in design system instead.
const StatusBadge = ({label, color, backgroundColor, textColor, isDropdownButton, size}) => {
  const responsive = useResponsive();

  return (
    <Container color={backgroundColor || colors.getBackgroundColor(color)} responsive={responsive}>
      <StatusBadgeText color={textColor || colors.getTextColor(color)} size={size}>
        {label}
      </StatusBadgeText>
      {isDropdownButton && (
        <React.Fragment>
          <Space width={5} />
          <Icon color={textColor} source={Icon.ChevronDown} size={7} />
        </React.Fragment>
      )}
    </Container>
  );
};

StatusBadge.SIZE = SIZE;

export default StatusBadge;
