// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectTypeValuationCoverageForm from '../forms/ProjectTypeValuationCoverageForm';

const useUpdateProjectTypeValuationCoverageMutation = ({
  projectTypeValuationCoverageForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      projectTypeValuationCoverageForm: ProjectTypeValuationCoverageForm.toForm(
        projectTypeValuationCoverageForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectTypeValuationCoverageMutation.mutation,
    variables: {
      projectTypeValuationCoverageForm: ProjectTypeValuationCoverageForm.toMutation(
        form.values.projectTypeValuationCoverageForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateProjectTypeValuationCoverageMutation.mutation = gql`
  mutation useUpdateProjectTypeValuationCoverageMutation($projectTypeValuationCoverageForm: ProjectTypeValuationCoverageForm!){
    response: updateProjectTypeValuationCoverage(projectTypeValuationCoverageForm: $projectTypeValuationCoverageForm) {
      ${gql.errors}
      projectType {
        id
      }
    }
  }
`;

export default useUpdateProjectTypeValuationCoverageMutation;
