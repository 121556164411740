// Libraries
import React from 'react';

// App
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// Components
import Callout from '@shared/design/components/Callout';
import StaffMoverPositionsSection from 'modules/Organization/Settings/Users/List/components/StaffMoverPositionsSection';

const OrganizationSettingsStaffPositionsContent = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsStaffPositionsContent.query, {
    fetchPolicy: 'network-only',
  });

  return (
    <Loading loading={loading}>
      {() => {
        const isNotPrimaryOrg =
          data.viewer.viewingOrganization.features.isEnabledMoverPositionMultibranch &&
          !data.viewer.viewingOrganization.isPrimary;

        return isNotPrimaryOrg ? (
          <Callout
            text={`Positions are set by the company. Please contact an admin at ${data.viewer.viewingOrganization.company.primaryOrganization.name} to make adjustments.`}
            width={780}
          />
        ) : (
          <StaffMoverPositionsSection
            organization={data.viewer.viewingOrganization}
            refetch={refetch}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsStaffPositionsContent.query = gql`
  ${StaffMoverPositionsSection.fragment}

  query OrganizationSettingsStaffPositionsContent {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        isPrimary
        features {
          isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
        }
        company {
          id
          primaryOrganization {
            id
            name
          }
        }
        ...StaffMoverPositionsSection
      }
    }
  }
`;

export default OrganizationSettingsStaffPositionsContent;
