const PERCENT = 'PERCENT';
const RATE = 'RATE';
const NO_FEE = 'NO_FEE';

const PaymentFeeKind = {
  PERCENT,
  RATE,
  NO_FEE,
};

export default PaymentFeeKind;
