// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';

// App
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';
import useUpdateValuesMutation from '@shared/modules/Billing/hooks/useUpdateValuesMutation';
import EditBillingValuesModal from 'modules/Project/Billing/components/EditBillingValuesModal';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';
import ProjectOverviewContentMobile from 'modules/Project/V2/Show/components/ProjectOverviewContentMobile';

const ProjectOverviewBlock = ({
  project,
  index,
  layoutKey,
  handleSetPositionY,
  urlFilters,
  refetch,
  refetchAndReset,
  projectBlockKind,
}) => {
  const editBillingValuesModal = useModal({name: 'Edit Billing Values Modal'});
  const updateValuesForm = UpdateValuesForm.edit(project);
  const updateValuesMutation = useUpdateValuesMutation({
    updateValuesForm,
    onSuccess: () => {
      editBillingValuesModal.handleClose();
      refetch();
    },
    onError: (error) => {
      console.log({error});
    },
  });

  return (
    <ProjectBlockWrapper
      query={ProjectOverviewBlock.query}
      queryVariables={{projectUuid: project.uuid}}
      index={index}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      projectBlockKind={projectBlockKind}
    >
      {({data}) => {
        return (
          <React.Fragment>
            <ProjectOverviewContentMobile
              project={data.project}
              urlFilters={urlFilters}
              refetchAndReset={refetchAndReset}
              editBillingValuesModal={editBillingValuesModal}
            />
            <EditBillingValuesModal
              key={editBillingValuesModal.key}
              isOpen={editBillingValuesModal.isOpen}
              handleClose={editBillingValuesModal.handleClose}
              updateValuesForm={updateValuesMutation.form}
              handleSubmit={updateValuesMutation.handleSubmit}
              projectUuid={project.uuid}
            />
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectOverviewBlock.listener = gql`
  fragment ProjectOverviewBlock_listener on Project {
    id
  }
`;

ProjectOverviewBlock.fragment = gql`
  ${ProjectOverviewBlock.listener}
  fragment ProjectOverviewBlock on Project {
    id
    uuid
    ...ProjectOverviewBlock_listener
  }
`;

ProjectOverviewBlock.query = gql`
  ${ProjectOverviewBlock.listener}
  ${ProjectOverviewContentMobile.fragment}
  ${UpdateValuesForm.edit.fragment}

  query ProjectOverviewBlock($projectUuid: String!) {
    ${gql.query}

    project(uuid: $projectUuid) {
      id
      ...ProjectOverviewBlock_listener
      ...ProjectOverviewContentMobile
      ...UpdateValuesForm_edit
    }
  }
`;

export default ProjectOverviewBlock;
