// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import VariableForm from '@shared/modules/Billing/forms/VariableForm';

const useCreateVariableMutation = ({variableForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      variableForm: VariableForm.toForm(variableForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateVariableMutation.mutation,
    variables: {
      variableForm: VariableForm.toMutation(form.values.variableForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateVariableMutation.mutation = gql`
  mutation useCreateVariableMutation($variableForm: VariableForm!) {
    response: createVariable(variableForm: $variableForm) {
      ${gql.errors}
      variable {
        id
      }
    }
  }
`;

export default useCreateVariableMutation;
