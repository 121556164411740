// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import ValueField from 'modules/Project/Billing/components/ValueField';

const Line = Styled.View`
  border: 1px solid ${colors.gray.border};
`;

const ValueItems = Styled.View`
  flex-direction: column;
`;

const ValueItem = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const Container = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const ValueFields = ({index, valueForms, field, form, startingValueFormIndex = 0}) => {
  return (
    <Container index={index}>
      <Space height={4} />
      <Line />
      <Space height={16} />
      <ValueItems>
        {/* We loop over all value forms so the index is correct */}
        {valueForms.map((valueForm, index) => {
          // We only show the ValueForms that are visible for create project.
          if (!valueForm.isVisibleForCreateProject) {
            return null;
          }
          const valueItemIndex = index + startingValueFormIndex;
          return (
            <ValueItem key={valueItemIndex} index={valueItemIndex}>
              <ValueField
                required={valueForm.isRequiredForCreateProject}
                field={`${field}.${valueItemIndex}`}
                form={form}
              />
              <Space height={16} />
            </ValueItem>
          );
        })}
      </ValueItems>
    </Container>
  );
};

export default ValueFields;
