// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, DropdownInput, MultiDropdownInput, Space, Styled} from '@supermove/components';
import {FilteredProjectsForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Organization, ReferralSource} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import PopoverFilter from '@shared/modules/App/components/PopoverFilter';
import MultiBranchOrganizationField from 'modules/Organization/components/MultibranchOrganizationField';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const BodyText = Styled.Text`
  ${Typography.Body3}
  padding-top: 32px;
`;

const FilterContainer = Styled.View`
  flex-direction: row;
  zIndex: ${(props) => 1000 - props.index}
`;

const getOfficeDropdownOptions = ({options, noneLabel, organization, description}) => {
  if (organization.features.isEnabledAllowEmptyBookedBy) {
    options.unshift({label: noneLabel, value: null, description});
  }
  return options;
};

const FromDateFilter = ({name, form, index}) => {
  return (
    <FieldInput
      {...form}
      index={index}
      isResponsive
      component={DateInput}
      name={`${name}.fromDate`}
      label={'Start Date'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const ToDateFilter = ({name, form, index}) => {
  return (
    <FieldInput
      {...form}
      index={index}
      isResponsive
      component={DateInput}
      name={`${name}.toDate`}
      label={'End Date'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const ProjectTypeFilter = ({index, name, form, projectTypes, isMultiBranch}) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.projectTypeIds`}
      isResponsive
      label={'Project Types'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: projectTypes.map((projectType) => ({
          label: projectType.name,
          value: projectType.id,
          description: projectType.organizationName || projectType.organization.name,
        })),
        placeholder: 'Select project types',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        components: {
          Option: (optionData) => (
            <MultiDropdownCheckboxInput.CheckboxOption
              {...optionData}
              isDescriptionBelow={isMultiBranch}
            />
          ),
        },
      }}
    />
  );
};

const SalespeopleMultiselect = ({index, name, form, organization}) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.salespersonIds`}
      isResponsive
      label={'Salespeople'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: getOfficeDropdownOptions({
          organization,
          noneLabel: 'No salesperson',
          description: organization.features.isEnabledShowSalespersonsFromAllOrganizations
            ? 'All branches'
            : '',
          options: Organization.getSalespersonOptions(organization).map((user) => ({
            label: user.label,
            value: _.toString(user.value), // Keeps filter option selected when page is refreshed
            description: user.description,
          })),
        }),
        placeholder: 'Select salespeople',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        components: organization.features.isEnabledShowSalespersonsFromAllOrganizations
          ? {
              Option: DropdownInput.OptionWithDescription,
            }
          : {},
      }}
    />
  );
};

const CoordinatorMultiselect = ({index, name, form, organization}) => {
  const options = getOfficeDropdownOptions({
    organization,
    noneLabel: 'No coordinator',
    description: '',
    options: Organization.getCoordinatorOptions(organization).map((user) => ({
      label: user.label,
      value: _.toString(user.value), // Keeps filter option selected when page is refreshed
    })),
  });

  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.coordinatorIds`}
      isResponsive
      label={'Coordinators'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options,
        placeholder: 'Select coordinators',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
      }}
    />
  );
};

const ReferralSourceMultiselect = ({index, name, form, organization}) => {
  const {referralSources} = organization.settings;
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.referralSources`}
      isResponsive
      label={'Referral Sources'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: ReferralSource.getDropdownOptions({referralSources}),
        placeholder: 'Select referral sources',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
      }}
    />
  );
};

const StorageProjectFilters = ({organization, form, isRestricted}) => {
  const {params} = useNavigationDOM();
  const field = 'filteredProjectsForm';

  const isMultiBranch =
    organization.isPrimaryMultibranch && organization.features.isEnabledMultibranchStorage;

  const multiBranchProjectTypeFilterOptions = Organization.getProjectTypesForMultibranchSlugs(
    organization,
    params.slugs,
  );

  return (
    <React.Fragment>
      <FilterContainer index={1}>
        <FromDateFilter name={field} form={form} index={2} />
        <Space width={8} />
        <BodyText>-</BodyText>
        <Space width={8} />
        <ToDateFilter name={field} form={form} index={3} />
      </FilterContainer>
      <Space height={8} />
      {isMultiBranch && (
        <React.Fragment>
          <MultiBranchOrganizationField
            label={'Branches'}
            placeholder={'Select branches'}
            width={'100%'}
            index={4}
            isPortaled={false}
            isResponsive
            value={params.slugs || []}
            organizations={organization.company.organizations}
            disableAllOption
            onChangeValue={(newSlugs) => {
              form.setFieldValue(`${field}.slugs`, newSlugs);
              form.setFieldValue(`${field}.projectTypeIds`, null);
            }}
          />
          <Space height={8} />
        </React.Fragment>
      )}
      <ProjectTypeFilter
        projectTypes={
          isMultiBranch ? multiBranchProjectTypeFilterOptions : organization.storageProjectTypes
        }
        form={form}
        name={field}
        isMultiBranch={isMultiBranch}
        index={5}
      />
      <Space height={8} />
      {!isRestricted && (
        <React.Fragment>
          <SalespeopleMultiselect organization={organization} form={form} name={field} index={6} />
          <Space height={8} />
        </React.Fragment>
      )}
      <CoordinatorMultiselect organization={organization} form={form} name={field} index={7} />
      <Space height={8} />
      <ReferralSourceMultiselect organization={organization} form={form} name={field} index={8} />
      <Space height={16} />
    </React.Fragment>
  );
};

const StorageProjectFilterPopoverContent = ({organization, form, isRestricted, responsive}) => {
  return (
    <ContentContainer style={{paddingHorizontal: responsive.desktop ? null : 16}}>
      <StorageProjectFilters organization={organization} form={form} isRestricted={isRestricted} />
      <Space height={16} />
    </ContentContainer>
  );
};

const ListStorageProjectsPageFiltersPopover = ({
  popover,
  organization,
  form,
  isRestricted,
  viewerId,
}) => {
  const responsive = useResponsive();
  const field = 'filteredProjectsForm';

  return (
    <PopoverFilter
      activeFiltersCount={FilteredProjectsForm.getNumberOfFiltersApplied({
        form,
        isPrimary: organization.isPrimary,
        isRestricted,
      })}
      hideActionButtons
      popover={popover}
      responsive={responsive}
      handleApply={popover.handleClose}
      isScrolling
      handleClear={() => {
        form.setFieldValue(`${field}.salespersonIds`, isRestricted ? [viewerId] : null);
        form.setFieldValue(`${field}.fromDate`, null);
        form.setFieldValue(`${field}.toDate`, null);
        form.setFieldValue(`${field}.coordinatorIds`, null);
        form.setFieldValue(`${field}.projectTypeIds`, null);
        form.setFieldValue(`${field}.referralSources`, null);
        form.setFieldValue(
          `${field}.slugs`,
          organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
        );
      }}
    >
      <StorageProjectFilterPopoverContent
        organization={organization}
        form={form}
        isRestricted={isRestricted}
        responsive={responsive}
      />
    </PopoverFilter>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListStorageProjectsPageFiltersPopover.fragment = gql`
  ${Organization.getCoordinatorOptions.fragment}
  ${Organization.getSalespersonOptions.fragment}
  ${ReferralSource.getDropdownOptions.fragment}
  ${MultiBranchOrganizationField.fragment}
  ${Organization.getProjectTypesForMultibranchSlugs.fragment}

  fragment ListStorageProjectsPageFiltersPopover on Organization {
    id
    isPrimary
    isPrimaryMultibranch
    slug
    features {
      isEnabledShowSalespersonsFromAllOrganizations: isEnabled(
        feature: "SHOW_SALESPERSONS_FROM_ALL_ORGANIZATIONS"
      )
      isEnabledMultibranchStorage: isEnabled(feature: "MULTIBRANCH_STORAGE")
      isEnabledAllowEmptyBookedBy: isEnabled(feature: "ALLOW_EMPTY_BOOKED_BY")
    }
    storageProjectTypes: projectTypesForCategory(category: "STORAGE") {
      id
      name
      organization {
        id
        name
      }
    }
    settings {
      id
      referralSources {
        ...ReferralSource_getDropdownOptions
      }
    }
    company {
      id
      organizations {
        id
        storageProjectTypes: projectTypesForCategory(category: "STORAGE") {
          id
          name
          organization {
            id
            name
          }
        }
      }
    }
    ...MultiBranchOrganizationField
    ...Organization_getCoordinatorOptions
    ...Organization_getSalespersonOptions
    ...Organization_getProjectTypesForMultibranchSlugs
  }
`;

export default ListStorageProjectsPageFiltersPopover;
