const CREW_BY_POSITION = 'CREW_BY_POSITION';
const AM_PM_SPLIT = 'AM_PM_SPLIT';
const SINGLE_NUMBER = 'SINGLE_NUMBER';

const CapacityTotalComponentKind = {
  CREW_BY_POSITION,
  AM_PM_SPLIT,
  SINGLE_NUMBER,
};

export default CapacityTotalComponentKind;
