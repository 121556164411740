// Supermove
import {UpdateSinglePropertyForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import TaskForm from '@shared/modules/TaskManagement/forms/TaskForm';

const useUpdateTaskPropertyMutation = ({updateSinglePropertyForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      updateSinglePropertyForm: UpdateSinglePropertyForm.toForm(updateSinglePropertyForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateTaskPropertyMutation.mutation,
    variables: {
      updateSinglePropertyForm: UpdateSinglePropertyForm.toMutation(
        form.values.updateSinglePropertyForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateTaskPropertyMutation.mutation = gql`
  ${TaskForm.edit.fragment}
  mutation UpdateTaskPropertyMutation($updateSinglePropertyForm: UpdateSinglePropertyForm) {
    response: updateTaskProperty(updateSinglePropertyForm: $updateSinglePropertyForm) {
      ${gql.errors}
      task {
        id
        ...TaskForm_edit
      }
    }
  }
`;

export default useUpdateTaskPropertyMutation;
