// Libraries
import React from 'react';

// App
import SmallModalV1 from '@shared/design/components/SmallModalV1';

const Modal = ({isOpen, title, subtitle, onClose, children}) => {
  return (
    <SmallModalV1
      isResponsive={false}
      isOpen={isOpen}
      title={title}
      subtitle={subtitle}
      onClose={onClose}
      children={children}
    />
  );
};

export default Modal;
