// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteBillTypeMutation = ({billTypeId, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      billTypeId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteBillTypeMutation.mutation,
    variables: {
      billTypeId: form.values.billTypeId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteBillTypeMutation.mutation = gql`
  mutation useDeleteBillTypeMutation($billTypeId: Int!) {
    response: deleteBillType(billTypeId: $billTypeId) {
      ${gql.errors}
      billType {
        id
      }
    }
  }
`;

export default useDeleteBillTypeMutation;
