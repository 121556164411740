// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Icon} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';

const EditButton = ({
  isEditing,
  handleEdit,
  isDisabled,
}: {
  isEditing: boolean;
  handleEdit: () => void;
  isDisabled: boolean;
}) => {
  if (isEditing) {
    return null;
  }

  return (
    <TertiaryButton
      isResponsive
      text={'Edit'}
      iconLeft={Icon.Pen}
      onPress={handleEdit}
      isDisabled={isDisabled}
    />
  );
};

export interface EditPanelProps {
  style?: object | null;
  BodyComponent: React.ComponentType<any>;
  EditBodyComponent: React.ComponentType<any>;
  HeaderActionComponent?: React.ComponentType<any> | null;
  headerActionComponentProps?: object;
  HeaderIconComponent?: React.ComponentType<any> | null;
  headerIconComponentProps?: object;
  EditButtonComponent?: React.ComponentType<any>;
  editButtonComponentProps?: object;
  EmptyBodyComponent?: React.ComponentType<any> | null;
  emptyBodyComponentProps?: object;
  HeaderComponent?: React.ComponentType<any> | null;
  headerComponentProps?: object;
  title?: string;
  description?: string;
  handleSave: () => void;
  saveButtonText?: string;
  SaveButtonIconComponent?: React.ComponentType<any> | null;
  headerSubtitle?: string | null;
  handleCancel?: () => void;
  cancelButtonText?: string;
  isDisabled?: boolean;
  isEmpty?: boolean;
  isSubmitting?: boolean;
  width?: number | null;
  index?: number;
  bodyComponentProps?: object;
  editBodyComponentProps?: object;
  bodyStyle?: object;
  isEditButtonVisible?: boolean;
  isFlex?: boolean;
  isEditing?: boolean;
  handleEdit?: () => void;
  handleClose?: () => void;
}

const EditPanel = ({
  title,
  description = '',
  headerSubtitle = null,
  index = 0,
  width = 784,
  style = null,
  bodyStyle = {},
  bodyComponentProps = {},
  editBodyComponentProps = {},
  BodyComponent,
  EditBodyComponent,
  HeaderActionComponent = null,
  headerActionComponentProps = {},
  HeaderIconComponent = null,
  headerIconComponentProps = {},
  EditButtonComponent = EditButton,
  editButtonComponentProps = {},
  EmptyBodyComponent = null,
  emptyBodyComponentProps = {},
  HeaderComponent = null,
  headerComponentProps = {},
  handleSave,
  saveButtonText = 'Save',
  SaveButtonIconComponent = null,
  handleCancel = () => {},
  cancelButtonText = 'Cancel',
  isDisabled = false,
  isEmpty = false,
  isSubmitting = false,
  isEditing = false,
  handleEdit = () => {},
  handleClose = () => {},
  isEditButtonVisible = true,
  isFlex = false,
}: EditPanelProps) => {
  const responsive = useResponsive();
  return (
    <ActionPanel
      title={title}
      description={description}
      headerSubtitle={headerSubtitle}
      index={index}
      width={width}
      isEmpty={isEmpty}
      style={style}
      isFlex={isFlex}
      bodyStyle={bodyStyle}
      bodyComponentProps={isEditing ? editBodyComponentProps : bodyComponentProps}
      BodyComponent={isEditing ? EditBodyComponent : BodyComponent}
      HeaderActionComponent={HeaderActionComponent}
      headerActionComponentProps={headerActionComponentProps}
      HeaderIconComponent={HeaderIconComponent}
      headerIconComponentProps={headerIconComponentProps}
      ActionButtonComponent={() => {
        if (!isEditButtonVisible) {
          return null;
        }

        if (!responsive.desktop) {
          return (
            <React.Fragment>
              {!isEditing ? (
                <TertiaryButton
                  isResponsive
                  text={'Edit'}
                  iconLeft={Icon.Pen}
                  onPress={handleEdit}
                  isDisabled={isDisabled}
                />
              ) : (
                <TertiaryButton
                  isResponsive
                  text={'Cancel'}
                  onPress={() => {
                    handleClose();
                    handleCancel();
                  }}
                />
              )}
            </React.Fragment>
          );
        }
        return (
          <EditButtonComponent
            isEditing={isEditing}
            handleEdit={handleEdit}
            isDisabled={isDisabled}
            {...editButtonComponentProps}
          />
        );
      }}
      actionButtonComponentProps={editButtonComponentProps}
      EmptyBodyComponent={EmptyBodyComponent}
      emptyBodyComponentProps={emptyBodyComponentProps}
      HeaderComponent={HeaderComponent}
      headerComponentProps={headerComponentProps}
      FooterComponent={
        isEditing
          ? () =>
              !responsive.desktop ? (
                <Button
                  isResponsive
                  onPress={handleSave}
                  isDisabled={isDisabled}
                  isSubmitting={isSubmitting}
                  text={saveButtonText}
                  iconLeft={SaveButtonIconComponent}
                  isWidthOfContainer
                  style={{flex: 1, marginHorizontal: 16, marginBottom: 16}}
                />
              ) : (
                <React.Fragment>
                  <Button
                    color={colors.white}
                    textColor={colors.gray.secondary}
                    onPress={() => {
                      handleClose();
                      handleCancel();
                    }}
                    isDisabled={isSubmitting}
                    text={cancelButtonText}
                  />
                  <Space width={16} />
                  <Button
                    onPress={handleSave}
                    isDisabled={isDisabled}
                    isSubmitting={isSubmitting}
                    text={saveButtonText}
                    iconLeft={SaveButtonIconComponent}
                  />
                </React.Fragment>
              )
          : null
      }
    />
  );
};

EditPanel.Text = ActionPanel.Text;
EditPanel.LabelText = ActionPanel.LabelText;
EditPanel.HeaderText = ActionPanel.HeaderText;
EditPanel.EditButton = EditButton;

// --------------------------------------------------
// Props
// --------------------------------------------------
EditPanel.propTypes = {
  style: PropTypes.object,
  BodyComponent: PropTypes.func.isRequired,
  EditBodyComponent: PropTypes.func.isRequired,
  HeaderActionComponent: PropTypes.func,
  headerActionComponentProps: PropTypes.object,
  HeaderIconComponent: PropTypes.func,
  headerIconComponentProps: PropTypes.object,
  EditButtonComponent: PropTypes.func,
  editButtonComponentProps: PropTypes.object,
  EmptyBodyComponent: PropTypes.func,
  emptyBodyComponentProps: PropTypes.object,
  HeaderComponent: PropTypes.func,
  headerComponentProps: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  saveButtonText: PropTypes.string,
  SaveButtonIconComponent: PropTypes.func,
  headerSubtitle: PropTypes.string,
  handleCancel: PropTypes.func,
  cancelButtonText: PropTypes.string,
  isDisabled: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  width: PropTypes.number,
  index: PropTypes.number,
  bodyComponentProps: PropTypes.object,
  editBodyComponentProps: PropTypes.object,
  bodyStyle: PropTypes.object,
  isEditButtonVisible: PropTypes.bool,
  isFlex: PropTypes.bool,
};

EditPanel.defaultProps = {
  style: null,
  HeaderActionComponent: null,
  headerActionComponentProps: {},
  HeaderIconComponent: null,
  headerIconComponentProps: {},
  EditButtonComponent: EditButton,
  headerSubtitle: null,
  editButtonComponentProps: {},
  EmptyBodyComponent: null,
  emptyBodyComponentProps: {},
  HeaderComponent: null,
  headerComponentProps: {},
  title: '',
  isDisabled: false,
  isEmpty: false,
  isSubmitting: false,
  description: '',
  handleCancel: () => {},
  saveButtonText: 'Save',
  SaveButtonIconComponent: null,
  cancelButtonText: 'Cancel',
  width: 784,
  index: 0,
  bodyComponentProps: {},
  editBodyComponentProps: {},
  bodyStyle: {},
  isEditButtonVisible: true,
  isFlex: false,
};

export default EditPanel;
