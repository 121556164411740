// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useResponsive} from '@supermove/hooks';
import {Currency} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';
import EditValuationCoverageDrawer from 'modules/Project/components/EditValuationCoverageDrawer';

const BLOCK_TITLE = 'Valuation Coverage';

const ResponsiveContainer = Styled.View`
  flex-direction: ${({responsive}) => (responsive.desktop ? 'row' : 'column')};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const SkeletonBody = () => {
  return (
    <Row>
      <FieldValue.SkeletonLoader label={'Selected Option'} width={144} style={{flex: 1}} />
      <FieldValue.SkeletonLoader label={'Selected Value'} width={144} style={{flex: 1}} />
      <FieldValue.SkeletonLoader label={'Minimum'} width={144} style={{flex: 1}} />
      <Space style={{flex: 1}} />
    </Row>
  );
};

const SkeletonComponent = () => {
  return (
    <ActionPanel
      BodyComponent={SkeletonBody}
      ActionButtonComponent={EditButton}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
    />
  );
};

const EditButton = ({project, refetch}) => {
  const responsive = useResponsive();
  const editValuationCoverageDrawer = useDrawer({name: 'Edit Valuation Coverage Drawer'});

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <Button
          text={'Edit'}
          iconLeft={Icon.Pen}
          onPress={editValuationCoverageDrawer.handleOpen}
          isSmall
          isDisabled={!project}
        />
      ) : (
        <TertiaryButton
          text={`Edit`}
          onPress={editValuationCoverageDrawer.handleOpen}
          isResponsive
        />
      )}
      {project && (
        <EditValuationCoverageDrawer
          key={editValuationCoverageDrawer.key}
          isOpen={editValuationCoverageDrawer.isOpen}
          handleClose={editValuationCoverageDrawer.handleClose}
          projectUuid={project.uuid}
          onSuccess={refetch}
        />
      )}
    </React.Fragment>
  );
};

const ValuationCoverageFields = ({project}) => {
  const responsive = useResponsive();
  const fieldValueStyle = {flex: responsive.desktop ? 1 : undefined};

  return (
    <ResponsiveContainer responsive={responsive}>
      <FieldValue
        value={project.valuationCoverage.displayName}
        label={'Selected Option'}
        style={fieldValueStyle}
        isResponsive
      />
      <Space width={16} height={16} />
      <FieldValue
        value={Currency.display(project.valuationCoverage.amountValue)}
        label={'Selected Value'}
        style={fieldValueStyle}
        isResponsive
      />
      <Space width={16} height={16} />
      <FieldValue
        value={Currency.display(project.valuationCoverage.amountMinimum)}
        label={'Minimum'}
        style={fieldValueStyle}
        isResponsive
      />
      <Space style={fieldValueStyle} />
    </ResponsiveContainer>
  );
};

const ProjectValuationCoverageBlockContent = ({project, refetch}) => {
  return (
    <ActionPanel
      BodyComponent={ValuationCoverageFields}
      bodyComponentProps={{project}}
      ActionButtonComponent={EditButton}
      actionButtonComponentProps={{project, refetch}}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
    />
  );
};

const ProjectValuationCoverageBlockContentMobile = ({project, refetch}) => {
  return (
    <React.Fragment>
      <MobileProjectBlockHeader
        title={`Valuation Coverage`}
        ActionsComponent={EditButton}
        actionsComponentProps={{project, refetch}}
      />
      <ValuationCoverageFields project={project} />
    </React.Fragment>
  );
};

const ProjectValuationCoverageBlock = ({
  project,
  handleSetPositionY,
  layoutKey,
  index,
  projectBlockKind,
}) => {
  const responsive = useResponsive();

  return (
    <ProjectBlockWrapper
      index={index}
      query={ProjectValuationCoverageBlock.query}
      queryVariables={{projectUuid: project.uuid}}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data, refetch}) => {
        const {project} = data;
        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ProjectValuationCoverageBlockContent project={project} refetch={refetch} />
            ) : (
              <ProjectValuationCoverageBlockContentMobile project={project} refetch={refetch} />
            )}
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectValuationCoverageBlock.listener = gql`
  fragment ProjectValuationCoverageBlock_listener on Project {
    id
    totalRevenue
    valuationCoverage {
      id
      updatedAt
    }
  }
`;

ProjectValuationCoverageBlock.fragment = gql`
  ${ProjectValuationCoverageBlock.listener}
  fragment ProjectValuationCoverageBlock on Project {
    id
    uuid
    ...ProjectValuationCoverageBlock_listener
  }
`;

ProjectValuationCoverageBlock.query = gql`
  ${ProjectValuationCoverageBlock.listener}
  query ProjectValuationCoverageBlock($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      uuid
      valuationCoverage {
        id
        amountMinimum
        amountValue
        displayName
      }
      ...ProjectValuationCoverageBlock_listener
    }
  }
`;

export default ProjectValuationCoverageBlock;
