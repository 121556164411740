// NOTE(jholston): This generic component is design to take different types
// of forms and display them in sections.

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, ResponsiveType, useResponsive} from '@supermove/hooks';
import {OrganizationModel, PaymentMethodModel} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import SwitchField from '@shared/design/components/Field/SwitchField';
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';

const Label = Styled.Text<{responsive: ResponsiveType}>`
  ${Typography.Responsive.Label}
`;

interface FormType {
  sectionLabel: string;
}

interface Section {
  sectionLabel: string;
  sectionForms: unknown[];
  startIndex: number;
}

const getFormsInSections = (displayForms: FormType[]): Section[] => {
  const groupedFormsBySection = displayForms.reduce<Record<string, FormType[]>>(
    (acc, displayForm) => {
      if (!acc[displayForm.sectionLabel]) {
        acc[displayForm.sectionLabel] = [];
      }
      acc[displayForm.sectionLabel].push(displayForm);
      return acc;
    },
    {},
  );

  let currentIndex = 0;
  return Object.entries(groupedFormsBySection).map(([sectionLabel, sectionForms]) => {
    const startIndex = currentIndex;
    const count = sectionForms.length;
    currentIndex += count;
    return {sectionLabel, sectionForms, startIndex};
  });
};

const getHardcodedPaymentMethod = ({
  paymentMethodId,
  hardcodedCrewPaymentMethods,
}: {
  paymentMethodId: string | null;
  hardcodedCrewPaymentMethods: PaymentMethodModel[];
}): PaymentMethodModel | undefined => {
  return paymentMethodId
    ? _.find(
        hardcodedCrewPaymentMethods,
        (paymentMethod) => _.toString(paymentMethod.id) === _.toString(paymentMethodId),
      )
    : undefined;
};

const getHint = (paymentMethod: PaymentMethodModel | undefined) => {
  switch (paymentMethod?.kind) {
    case PaymentMethodKind.INVOICE:
      return 'No payment is required in the Crew App, and the customer will be notified that an invoice will be sent later.';
    case PaymentMethodKind.PAYENGINE_SAVE_CARD:
      return 'Allows the customer to save a card to their profile via the Crew App without being charged yet.';
    default:
      return '';
  }
};

const PaymentMethodOptions = ({
  form,
  field,
  fieldToUpdate,
  displayForms,
  labelField,
  organization,
}: {
  form: Form<any>;
  field: string;
  fieldToUpdate: string;
  displayForms: FormType[];
  labelField: string;
  organization: OrganizationModel;
}) => {
  const responsive = useResponsive();
  const formSections = getFormsInSections(displayForms);
  const {hardcodedCrewPaymentMethods} = organization;
  const isPayengineEnabled = organization.payengineMerchant?.isProcessingEnabled;
  return (
    <React.Fragment>
      {formSections
        .map(({sectionLabel, sectionForms, startIndex}) => (
          <React.Fragment key={sectionLabel}>
            <Label responsive={responsive}>{sectionLabel}</Label>
            <Space height={8} />
            {sectionForms.map((childForm: any, index: number) => {
              const currentIndex = startIndex + index;
              const paymentMethodId = _.get(childForm, 'paymentMethodId', null);
              const hardcodedCrewPaymentMethod = getHardcodedPaymentMethod({
                paymentMethodId,
                hardcodedCrewPaymentMethods,
              });
              if (
                hardcodedCrewPaymentMethod?.kind === PaymentMethodKind.PAYENGINE_SAVE_CARD &&
                !isPayengineEnabled
              ) {
                return null;
              }
              return (
                <React.Fragment key={currentIndex}>
                  <SwitchField
                    index={currentIndex}
                    form={form}
                    field={`${field}.${currentIndex}.${fieldToUpdate}`}
                    labelRight={childForm[labelField]}
                    hint={getHint(hardcodedCrewPaymentMethod)}
                    isResponsive
                  />
                  <Space height={8} />
                </React.Fragment>
              );
            })}
          </React.Fragment>
        ))
        .filter(Boolean)}
    </React.Fragment>
  );
};

PaymentMethodOptions.fragment = gql`
  fragment PaymentMethodOptions on Organization {
    id
    hardcodedCrewPaymentMethods {
      id
      kind
    }
    payengineMerchant {
      id
      isProcessingEnabled
    }
  }
`;

export default PaymentMethodOptions;
