// Libararies
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import EditSmsTemplateContent from 'modules/Organization/Settings/Communication/components/EditSmsTemplateContent';

const EditSmsTemplatesPage = () => {
  const {params} = useNavigationDOM();

  const {loading, data} = useQuery(EditSmsTemplatesPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      textMessageTemplateUuid: params.textMessageTemplateUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <SidebarPageV2 selected={'settings'}>
      <EditSmsTemplateContent textMessageTemplate={data.textMessageTemplateByUuid} />
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditSmsTemplatesPage.query = gql`
  ${EditSmsTemplateContent.fragment}

  query EditSmsTemplatesPage($textMessageTemplateUuid: String!) {
    textMessageTemplateByUuid(textMessageTemplateUuid: $textMessageTemplateUuid) {
      id
      ...EditSmsTemplateContent
      organization {
        id
      }
    }
  }
`;

export default EditSmsTemplatesPage;
