// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import DocumentV2CartonSummary from '@shared/modules/Document/components/DocumentV2CartonSummary';
import DocumentV2SurveyItems from '@shared/modules/Document/components/DocumentV2SurveyItems';

const Container = Styled.View`
`;

const DocumentInventorySummaryV2 = ({job}) => {
  return (
    <Container>
      <DocumentV2SurveyItems isPreview={false} job={job} isDisabled />
      <Space height={48} />
      <DocumentV2CartonSummary isPreview={false} job={job} isDisabled />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentInventorySummaryV2.fragment = gql`
  ${DocumentV2SurveyItems.fragment}
  ${DocumentV2CartonSummary.fragment}

  fragment DocumentInventorySummaryV2 on Job {
    id
    ...DocumentV2SurveyItems
    ...DocumentV2CartonSummary
  }
`;

export default DocumentInventorySummaryV2;
