// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Bill} from '@supermove/models';
import {fontWeight, colors, Typography} from '@supermove/styles';

// App
import BillingDocumentBillBillRules from '@shared/modules/Document/Billing/components/BillingDocumentBillBillRules';
import BillingDocumentBillHeader from '@shared/modules/Document/Billing/components/BillingDocumentBillHeader';
import BillingDocumentBillPreSubtotalBillItems from '@shared/modules/Document/Billing/components/BillingDocumentBillPreSubtotalBillItems';
import BillingDocumentBillSubtotalSection from '@shared/modules/Document/Billing/components/BillingDocumentBillSubtotalSection';

const Row = Styled.View`
  flex-direction: row;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const BillInformationContainer = Styled.View`
  flex: 1;
`;

const BillTotalAmountContainer = Styled.View`
  flex: 3;
  align-items: flex-end;
  `;

const BillTotalTitleContainer = Styled.View`
  flex: 8;
  align-items: flex-end;
`;

const BillTotalText = Styled.Text`
  ${Typography.Label2}
  color: ${(props) => (props as any).color};
`;

const BillTotalTextMobile = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.primary};
  ${fontWeight(900)}
`;

const BillTotal = ({bill}: any) => {
  const responsive = useResponsive();
  const billTotal = bill.isEstimateAvailable ? Bill.getEstimateTotal(bill) : 'TBD';
  if (responsive.mobile) {
    return (
      <Row>
        <BillTotalTextMobile>Total</BillTotalTextMobile>
        <Space style={{flex: 1}} />
        <BillTotalTextMobile>{billTotal}</BillTotalTextMobile>
      </Row>
    );
  }
  return (
    <Row>
      <BillTotalTitleContainer>
        <BillTotalText color={colors.gray.primary}>
          {`Total${bill.title ? ` ${bill.title}` : ''}`}
        </BillTotalText>
      </BillTotalTitleContainer>
      <BillTotalAmountContainer>
        <BillTotalText color={colors.gray.primary}>{billTotal}</BillTotalText>
      </BillTotalAmountContainer>
    </Row>
  );
};

const BillingDocumentBill = ({bill, hasQuantity, hasTotal, hasEmptyTotals}: any) => {
  const hasSalesTax = bill.estimateSalesTaxAmountMin > 0 || bill.estimateSalesTaxAmountMax > 0;
  const hasSubtotal = !_.isEmpty(bill.billItemsPostSubtotal) || hasSalesTax;

  return (
    <Row>
      <BillInformationContainer>
        <BillingDocumentBillHeader bill={bill} />
        <Space height={8} />
        <Line />
        <Space height={8} />
        <BillingDocumentBillPreSubtotalBillItems
          bill={bill}
          hasQuantity={hasQuantity}
          hasTotal={hasTotal}
        />
        <Space height={8} />
        <Line />
        {hasSubtotal && (
          <React.Fragment>
            <Space height={8} />
            <BillingDocumentBillSubtotalSection bill={bill} />
            <Space height={8} />
            <Line />
          </React.Fragment>
        )}
        {(bill.isEstimateAvailable || hasEmptyTotals) && (
          <React.Fragment>
            <Space height={8} />
            <BillTotal bill={bill} />
          </React.Fragment>
        )}
        {bill.hasBillRules && (
          <React.Fragment>
            <Space height={8} />
            <BillingDocumentBillBillRules bill={bill} />
          </React.Fragment>
        )}
        <Space height={16} />
      </BillInformationContainer>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillingDocumentBill.fragment = gql`
  ${BillingDocumentBillHeader.fragment}
  ${BillingDocumentBillPreSubtotalBillItems.fragment}
  ${BillingDocumentBillSubtotalSection.fragment}
  ${BillingDocumentBillBillRules.fragment}
  ${Bill.getEstimateTotal.fragment}

  fragment BillingDocumentBill on Bill {
    id
    title
    isEstimateAvailable
    estimateSalesTaxAmountMin
    estimateSalesTaxAmountMax
    hasBillRules
    billItemsPostSubtotal {
      id
    }
    ...BillingDocumentBillHeader
    ...BillingDocumentBillPreSubtotalBillItems
    ...BillingDocumentBillSubtotalSection
    ...BillingDocumentBillBillRules
    ...Bill_getEstimateTotal
  }
`;

export default BillingDocumentBill;
