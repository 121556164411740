// Libraries
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

import CrewForm from '@shared/modules/Dispatch/forms/CrewForm';

const edit = withFragment(
  (job) => ({
    crewForms: (job as any).crews.map((crew: any) => CrewForm.edit(crew)),
    jobId: (job as any).id,
  }),
  gql`
    ${CrewForm.edit.fragment}
    fragment CreateCrewsForJobForm_edit on Job {
      id
      crews {
        id
        ...CrewForm_edit
      }
    }
  `,
);

const toForm = ({crewForms, jobId}: any) => ({
  crewForms: crewForms.map((crewForm: any) => CrewForm.toForm(crewForm)),
  jobId,
});

const toMutation = ({crewForms, jobId}: any) => ({
  crewForms: crewForms.map((crewForm: any) => CrewForm.toMutation(crewForm)),
  jobId,
});

const CreateCrewsForJobForm = {
  edit,
  toForm,
  toMutation,
};

export default CreateCrewsForJobForm;
