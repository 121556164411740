// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import Switch from 'modules/App/components/Switch';
import CreateCrewWithTrucksAndSlotsPopoverContent from 'modules/Job/V2/Move/components/CreateCrewWithTrucksAndSlotsPopoverContent';
import CreateCrewsPopoverContent from 'modules/Job/V2/Move/components/CreateCrewsPopoverContent';

const HeaderContainer = Styled.View`
  background-color: ${colors.white};
`;

const SwitchContainer = Styled.Touchable`
  padding-bottom: 2px;
  align-self: flex-end;
  margin-right: -4px;
`;

const MultiContractorSwitch = ({isMultiContractor, setIsMultiContractor}) => {
  return (
    <SwitchContainer>
      <Switch
        isOn={isMultiContractor}
        onChange={setIsMultiContractor}
        label={'Multi-Contractor?'}
      />
    </SwitchContainer>
  );
};

const CreateCrewsForJobPopoverContent = ({
  job,
  handleClose,
  refetch,
  shouldRunUpsertCostsAndBillingEngineAsync,
}) => {
  const getSubtitleText = ({job}) => {
    const trucksText = pluralize('Truck', job.numberOfTrucks, true);
    const moversText = pluralize('Mover', job.numberOfMovers, true);

    return `${trucksText}, ${moversText}`;
  };

  const hasMultipleCrews = job.crews.length > 1;
  const [isMultiContractor, setIsMultiContractor] = useState(hasMultipleCrews);
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <HeaderContainer>
        <Space height={responsive.mobile ? 12 : 16} />
        <ResponsivePopover.Header
          title={'Assign Contractor'}
          caption={getSubtitleText({job})}
          handleClose={handleClose}
          headerCaptionRight={
            <MultiContractorSwitch
              isMultiContractor={isMultiContractor}
              setIsMultiContractor={setIsMultiContractor}
            />
          }
        />
        <Space height={responsive.mobile ? 10 : 8} />
      </HeaderContainer>
      {isMultiContractor ? (
        <CreateCrewsPopoverContent job={job} handleClose={handleClose} refetch={refetch} />
      ) : (
        <CreateCrewWithTrucksAndSlotsPopoverContent
          job={job}
          handleClose={handleClose}
          refetch={refetch}
          shouldRunUpsertCostsAndBillingEngineAsync={shouldRunUpsertCostsAndBillingEngineAsync}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
CreateCrewsForJobPopoverContent.propTypes = {
  job: PropTypes.object.isRequired,
};

CreateCrewsForJobPopoverContent.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCrewsForJobPopoverContent.fragment = gql`
  ${CreateCrewsPopoverContent.fragment}
  ${CreateCrewWithTrucksAndSlotsPopoverContent.fragment}
  fragment CreateCrewsForJobPopoverContent on Job {
    id
    numberOfMovers
    numberOfTrucks
    crews {
      id
    }
    ...CreateCrewsPopoverContent
    ...CreateCrewWithTrucksAndSlotsPopoverContent
  }
`;

export default CreateCrewsForJobPopoverContent;
