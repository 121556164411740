// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';

const ErrorModal = ({title, subtitle, closeButtonText, isOpen, handleClose}: any) => {
  return (
    <ActionModal
      color={colors.red.warning}
      icon={Icon.Ban}
      title={title}
      message={subtitle}
      isOpen={isOpen}
      handlePressOutside={handleClose}
      handlePrimaryAction={handleClose}
      primaryActionText={closeButtonText}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  closeButtonText: PropTypes.string,
};

ErrorModal.defaultProps = {
  closeButtonText: 'Close',
  subtitle: undefined,
};

export default ErrorModal;
