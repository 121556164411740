// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment} from '@supermove/utils';

// App
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import CostItemForm from '@shared/modules/Billing/forms/CostItemForm';

const _getCostItemsTotal = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ costItems }: { costItems: any... Remove this comment to see the full error message
  ({costItems}) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    const costTotals = _.reduce(costItems, (result, costItem) => [...result, costItem.total], []);
    return _.sum(costTotals);
  },
  gql`
    fragment _getCostItemsTotal on CostItem {
      id
      total
    }
  `,
);

const handleUpdateTotalAndCostItem = ({form, field, costItemField, costItemTotal}: any) => {
  const currentTotalCost = Currency.convertToCents(_.get(form.values, `${field}.totalCost`));
  const currentItemTotal = Currency.convertToCents(_.get(form.values, `${costItemField}.total`));
  const updatedTotalCost = currentTotalCost - currentItemTotal + costItemTotal;
  form.setFieldValue(
    `${field}.totalCost`,
    Currency.toForm(updatedTotalCost, {shouldHideCentsIfZero: true}),
  );
  form.setFieldValue(
    `${costItemField}.total`,
    Currency.toForm(costItemTotal, {shouldHideCentsIfZero: true}),
  );
};

const edit = withFragment(
  (costSection, {weight, totalDistance, projectRevenue} = {}) => {
    const billItemIdToCostItem = _.groupBy((costSection as any).costItems, 'billItemId');
    const billItems = _.get(costSection, 'bill.billItems', []);
    const billSubtotal = _.get(costSection, 'bill.subtotal');
    const billItemForms = billItems.map((billItem: any) => {
      // A billItem will never have more than one costItem for any given cost.
      const costItem = _.head(billItemIdToCostItem[billItem.id]);
      const costItemForm = costItem
        ? CostItemForm.edit(costItem, {weight, totalDistance, billSubtotal, projectRevenue})
        : null;
      return BillItemForm.edit(billItem, {costItemForm});
    });
    const costItemForms = _.map(
      _.filter((costSection as any).costItems, (costItem) => !costItem.billItemId),
      (costItem) =>
        CostItemForm.edit(costItem, {weight, totalDistance, billSubtotal, projectRevenue}),
    );
    return {
      billItemForms,
      costItemForms,
      // Private
      name: (costSection as any).name,
      isCompensation: (costSection as any).costCategoryKind === CostCategoryKind.COMPENSATION,
      costId: (costSection as any).costId,
      billId: _.get(costSection, 'bill.id'),
      billDayValue: _.get(costSection, 'bill.job.day.value'),
      billSubtotal,
      projectRevenue,
      isViewingAllBillItems: false,
      totalRevenue: (costSection as any).bill ? (costSection as any).bill.totalRevenue : 0,
      totalCost: _getCostItemsTotal({costItems: (costSection as any).costItems}),
    };
  },
  gql`
    ${BillItemForm.edit.fragment}
    ${CostItemForm.edit.fragment}
    ${_getCostItemsTotal.fragment}

    fragment CostSectionForm_edit on CostSection {
      name
      costId
      costCategoryKind
      bill {
        id
        subtotal
        totalRevenue
        billItems {
          id
          ...BillItemForm_edit
        }
        job {
          id
          day {
            id
            value
          }
        }
      }
      costItems {
        id
        billItemId
        ...CostItemForm_edit
        ..._getCostItemsTotal
      }
    }
  `,
);

const toForm = ({
  billItemForms,
  costItemForms,

  // Private
  name,

  isCompensation,
  costId,
  billId,
  billDayValue,
  billSubtotal,
  projectRevenue,
  isViewingAllBillItems,
  totalRevenue,
  totalCost,
}: any) => {
  return {
    billItemForms: billItemForms.map((billItemForm: any) => BillItemForm.toForm(billItemForm)),
    costItemForms: costItemForms.map((costItemForm: any) => CostItemForm.toForm(costItemForm)),

    // Private
    name,
    isCompensation,
    costId,
    billId,
    billDayValue,
    billSubtotal,
    projectRevenue,
    isViewingAllBillItems,
    totalRevenue: Currency.toForm(totalRevenue, {shouldHideCentsIfZero: true}),
    totalCost: Currency.toForm(totalCost, {shouldHideCentsIfZero: true}),
  };
};

const toMutation = ({billItemForms, costItemForms}: any) => {
  return {
    billItemForms: billItemForms.map((billItemForm: any) => BillItemForm.toMutation(billItemForm)),
    costItemForms: costItemForms.map((costItemForm: any) => CostItemForm.toMutation(costItemForm)),
  };
};

const CostForm = {
  edit,
  toForm,
  toMutation,

  handleUpdateTotalAndCostItem,
};

export default CostForm;
