export interface TagAssignmentForm {
  tagAssignmentId: number | null;
  tagIds: number[];
  jobId: number | null;
  projectId: number | null;
  isDeleted: boolean;
}

export interface TagAssignmentFormAsMutation {
  tagAssignmentId: number | null;
  tagIds: number[];
  jobId: number | null;
  projectId: number | null;
  isDeleted: boolean;
}

const _new = ({
  projectId,
  jobId,
  tagIds,
}: {
  projectId: number | null;
  jobId: number | null;
  tagIds: number[];
}): TagAssignmentForm => ({
  tagAssignmentId: null,
  tagIds: tagIds || [],
  jobId,
  projectId,
  isDeleted: false,
});

const _delete = ({
  tagAssignmentId,
  jobId,
  projectId,
}: {
  tagAssignmentId: number;
  jobId: number;
  projectId: number;
}) => ({
  ..._new({} as {projectId: number; jobId: number; tagIds: number[]}),
  tagAssignmentId,
  jobId,
  projectId,
});

const toForm = (tagAssignmentForm: TagAssignmentForm): TagAssignmentForm => ({
  tagAssignmentId: tagAssignmentForm.tagAssignmentId,
  tagIds: tagAssignmentForm.tagIds,
  jobId: tagAssignmentForm.jobId,
  projectId: tagAssignmentForm.projectId,
  isDeleted: tagAssignmentForm.isDeleted,
});

const toMutation = (tagAssignmentForm: TagAssignmentForm): TagAssignmentFormAsMutation => ({
  tagAssignmentId: tagAssignmentForm.tagAssignmentId,
  tagIds: tagAssignmentForm.tagIds,
  jobId: tagAssignmentForm.jobId,
  projectId: tagAssignmentForm.projectId,
  isDeleted: tagAssignmentForm.isDeleted,
});

const TagAssignmentForm = {
  new: _new,
  delete: _delete,
  toForm,
  toMutation,
};

export default TagAssignmentForm;
