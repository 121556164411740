// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// NEW_BILLING_LIBRARY syncs with the "Create new billing library"
// option in the billing libraries dropdown
const NEW_BILLING_LIBRARY = 'NEW_BILLING_LIBRARY';

const _new = ({organizationId}: any) => ({
  billingLibraryId: undefined,
  organizationId,
  name: '',
  baseBillingLibraryId: NEW_BILLING_LIBRARY,
});

const edit = withFragment(
  (billingLibrary) => ({
    billingLibraryId: (billingLibrary as any).id,
    organizationId: (billingLibrary as any).organizationId,
    name: (billingLibrary as any).name,
    baseBillingLibraryId: undefined,
  }),
  gql`
    fragment BillingLibraryForm_edit on BillingLibrary {
      id
      organizationId
      name
    }
  `,
);

const duplicate = withFragment(
  (billingLibrary) => ({
    billingLibraryId: undefined,
    organizationId: (billingLibrary as any).organizationId,
    name: `Copy of ${(billingLibrary as any).name}`,
    baseBillingLibraryId: (billingLibrary as any).id,
  }),
  gql`
    fragment BillingLibraryForm_duplicate on BillingLibrary {
      id
      organizationId
      name
    }
  `,
);

const toForm = ({billingLibraryId, organizationId, name, baseBillingLibraryId}: any) => {
  return {
    billingLibraryId,
    organizationId,
    name,
    baseBillingLibraryId,
  };
};

const toMutation = ({baseBillingLibraryId, billingLibraryId, organizationId, name}: any) => {
  return {
    billingLibraryId,
    organizationId,
    name,
    baseBillingLibraryId:
      baseBillingLibraryId === NEW_BILLING_LIBRARY ? null : baseBillingLibraryId,
  };
};

const BillingLibraryForm = {
  new: _new,
  edit,
  duplicate,
  toForm,
  toMutation,
};

export default BillingLibraryForm;
