// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {DeviceInfo, Linking} from '@supermove/sdk';
import {downloadFromUrl} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DownloadDocumentForm from '@shared/modules/Document/forms/DownloadDocumentForm';
import useDownloadDocumentMutation from '@shared/modules/Document/hooks/useDownloadDocumentMutation';

const DownloadInvoicePDFButton = ({invoice}) => {
  const downloadDocumentForm = DownloadDocumentForm.new({
    documentId: !_.isEmpty(invoice.document) ? invoice.document.id : null,
  });
  const {handleSubmit, submitting} = useDownloadDocumentMutation({
    downloadDocumentForm,
    onSuccess: (response) => {
      // There look to be security issues on IOS browsers with downloadFromUrl. Chrome
      // flags the new tab as a popup and Safari blocks it entirely. As an alternative
      // we use Linking for IOS browsers. We stick with downloadFromUrl on desktop however
      // because with Linking we lose the ability to open the document in a new tab.
      // Additionally, Linking has problems with Android browsers, but Android does not
      // have security issues with downloadFromUrl so can continue using on Android as well.
      if (DeviceInfo.getHardwareOs() === 'IOS') {
        return Linking.openURL(response.url);
      }
      return downloadFromUrl(response.url);
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  if (_.isEmpty(invoice.document)) {
    return null;
  }

  return (
    <TertiaryButton
      text={'Download'}
      iconLeft={Icon.FileDownload}
      iconSize={16}
      isSubmitting={submitting}
      onPress={handleSubmit}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DownloadInvoicePDFButton.fragment = gql`
  fragment DownloadInvoicePDFButton on Invoice {
    id
    organization {
      id
      name
      slug
    }
  }
`;

export default DownloadInvoicePDFButton;
