// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, ResponsiveType} from '@supermove/hooks';
import {DocumentTemplateModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import {
  JobTypeStepDocumentTemplateApiType,
  JobTypeStepDocumentTemplateFormType,
} from '@shared/modules/Job/forms/JobTypeStepDocumentTemplateForm';

import JobTypeStepsDocumentActions from './JobTypeStepsDocumentActions';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const DocumentHeaderText = Styled.Text<{color: string}>`
  ${Typography.Responsive.Label}
  color: ${({color}) => color};
`;

const DocumentStepsTextContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 8px;
  padding-vertical: 4px;
  border-radius: 4px;
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const DeleteDocumentButton = Styled.ButtonV2`
  padding-vertical: 4px;
`;

const DocumentLeftTextContainer = Styled.View`
`;

const DocumentSectionCompleteCount = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const removeDocumentStep = ({
  form,
  documentTemplateField,
  currentDocumentStepKind,
  jobTypeStepDocumentTemplateFormsField,
  documentTemplateId,
  isEnabledMultiPartDocuments,
}: {
  form: Form<JobTypeStepDocumentTemplateFormType>;
  documentTemplateField: string;
  currentDocumentStepKind: string;
  jobTypeStepDocumentTemplateFormsField: string;
  documentTemplateId: string;
  isEnabledMultiPartDocuments: boolean;
}) => {
  const jobTypeStepDocumentTemplateKinds = _.get(form.values, documentTemplateField);
  const updatedDocumentTemplateKinds = _.without(
    jobTypeStepDocumentTemplateKinds,
    currentDocumentStepKind,
  );
  if (isEnabledMultiPartDocuments) {
    const numberDocumentTemplateId = _.toNumber(documentTemplateId);
    const jobTypeStepDocumentTemplateForms = _.get(
      form.values,
      jobTypeStepDocumentTemplateFormsField,
    );
    const updatedJobTypeStepDocumentTemplateForms = _.reduce(
      jobTypeStepDocumentTemplateForms,
      (acc: JobTypeStepDocumentTemplateFormType[], item: JobTypeStepDocumentTemplateFormType) => {
        if (item.documentTemplateId === numberDocumentTemplateId) {
          // Don't add item
          return acc;
        }
        return [...acc, {...item, index: acc.length}]; // Set new position with element removed
      },
      [],
    );
    form.setFieldValue(
      jobTypeStepDocumentTemplateFormsField,
      updatedJobTypeStepDocumentTemplateForms,
    );
  }
  form.setFieldValue(documentTemplateField, updatedDocumentTemplateKinds);
  if (_.isEmpty(updatedDocumentTemplateKinds)) {
    form.setFieldValue('jobTypeStepForm.isEnabled', false);
  }
};

const JobTypeStepDocumentsSectionItem = ({
  documentTemplateKind,
  documentTemplateField,
  jobTypeStepDocumentTemplateFormsField,
  form,
  handleSubmit,
  organization,
  responsive,
  handleSelectDocumentSection,
}: {
  documentTemplateKind: string;
  documentTemplateField: string;
  jobTypeStepDocumentTemplateFormsField: string;
  form: Form<any>;
  handleSubmit: () => void;
  organization: any;
  responsive: ResponsiveType;
  handleSelectDocumentSection: (documentTemplate: DocumentTemplateModel) => void;
}) => {
  const {isEnabledMultiPartDocuments} = organization.features;
  const documentTemplate = _.find(organization.documentTemplatesWithActiveDocumentTemplateVersion, {
    identifier: documentTemplateKind,
  });
  const {documentSectionsCount} = documentTemplate.activeDocumentTemplateVersion;
  const jobTypeStepId = _.toNumber(form.values.jobTypeStepForm.jobTypeStepId);
  const jobTypeStepDocumentTemplate = isEnabledMultiPartDocuments
    ? documentTemplate.jobTypeStepDocumentTemplatesByJobType.find(
        (jobTypeStepDocumentTemplates: JobTypeStepDocumentTemplateApiType) =>
          jobTypeStepDocumentTemplates.jobTypeStepId === jobTypeStepId,
      )
    : undefined;
  const readonlySectionCount = jobTypeStepDocumentTemplate?.readonlySectionUuids?.length ?? 0;
  // TODO(mark): Add back editableSectionNames once resolver performance issues are fixed.
  const editableSectionNames: string[] = [];

  return (
    <Row style={{flex: 1}}>
      <DocumentStepsTextContainer>
        <DocumentLeftTextContainer>
          <DocumentHeaderText
            responsive={responsive}
            numberOfLines={1}
            color={colors.blue.interactive}
          >
            {documentTemplate.name}
          </DocumentHeaderText>
          {isEnabledMultiPartDocuments && !!documentSectionsCount && (
            <React.Fragment>
              <Space height={8} />
              <TextTooltip placement='top' text={editableSectionNames?.join('\n')}>
                <DocumentSectionCompleteCount
                  responsive={responsive}
                >{`${documentSectionsCount - (readonlySectionCount ?? 0)}/${documentSectionsCount} sections to complete`}</DocumentSectionCompleteCount>
              </TextTooltip>
            </React.Fragment>
          )}
        </DocumentLeftTextContainer>
        <Space style={{flex: 1}} />
        {isEnabledMultiPartDocuments ? (
          <JobTypeStepsDocumentActions
            handleRemoveDocument={() => {
              removeDocumentStep({
                form,
                documentTemplateField,
                currentDocumentStepKind: documentTemplateKind,
                jobTypeStepDocumentTemplateFormsField,
                documentTemplateId: documentTemplate.id,
                isEnabledMultiPartDocuments: organization.features.isEnabledMultiPartDocuments,
              });
              setImmediate(handleSubmit);
            }}
            handleSelectDocumentSections={() => handleSelectDocumentSection(documentTemplate)}
            // If they've set readonly sections, they know what this is
            skipSelectSectionConfirmation={readonlySectionCount > 0}
            // Disabled section select if there aren't multiple sections
            selectSectionsDisabled={documentSectionsCount < 2}
          />
        ) : (
          <DeleteDocumentButton
            onPress={() => {
              removeDocumentStep({
                form,
                documentTemplateField,
                currentDocumentStepKind: documentTemplateKind,
                jobTypeStepDocumentTemplateFormsField,
                documentTemplateId: documentTemplate.id,
                isEnabledMultiPartDocuments: organization.features.isEnabledMultiPartDocuments,
              });
              setImmediate(handleSubmit);
            }}
          >
            <Icon source={Icon.Trash} size={12} color={colors.gray.tertiary} />
          </DeleteDocumentButton>
        )}
      </DocumentStepsTextContainer>
    </Row>
  );
};

JobTypeStepDocumentsSectionItem.fragment = gql`
  fragment JobTypeStepDocumentsSectionItem on JobType {
    organization {
      id
      documentTemplatesWithActiveDocumentTemplateVersion {
        id
        name
        identifier
        category
        uuid
        activeDocumentTemplateVersion {
          id
          uuid
          documentSectionsCount
        }
        jobTypeStepDocumentTemplatesByJobType(jobTypeUuid: $jobTypeUuid) {
          id
          jobTypeStepId
          readonlySectionUuids
        }
      }
      features {
        isEnabledMultiPartDocuments: isEnabled(feature: "MULTI_PART_DOCUMENTS")
      }
    }
  }
`;

export default JobTypeStepDocumentsSectionItem;
