// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {Currency, List} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import EmptyState from '@shared/design/components/EmptyState';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import TextTooltip from '@shared/design/components/TextTooltip';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import AddTimesheetBillableBlockModal from '@shared/modules/Timesheet/components/AddTimesheetBillableBlockModal';
import AddTimesheetPayrollBlockModal from '@shared/modules/Timesheet/components/AddTimesheetPayrollBlockModal';
import TimesheetPayrollEntries from '@shared/modules/Timesheet/components/TimesheetPayrollEntries';
import UserRole from '@shared/modules/User/enums/UserRole';
import JobDetailsBlock from 'modules/Project/V2/Show/Blocks/Job/JobDetailsBlock';
import FinalizeJobModal from 'modules/Project/V2/Show/Blocks/Job/components/FinalizeJobModal';
import JobActionDisabledTooltip from 'modules/Project/V2/Show/Blocks/Job/components/JobActionDisabledTooltip';
import JobMissingDateModal from 'modules/Project/V2/Show/Blocks/Job/components/JobMissingDateModal';
import UnfinalizeJobModal from 'modules/Project/V2/Show/Blocks/Job/components/UnfinalizeJobModal';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TipsHeader = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const TipsSection = Styled.View`
  max-width: 240px;
  margin-left: 24px;
`;

const TipText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const TooltipContentContainer = Styled.View`
`;

const getHasMovers = ({job}) => {
  return _.some(job.timesheetBillableEntries);
};

const FinalizeButton = ({job, label, onPress, isVisible}) => {
  if (!isVisible) {
    return null;
  }
  return (
    <React.Fragment>
      <TextTooltip text={job.isComplete ? '' : 'This job must be complete before finalizing.'}>
        <TooltipContentContainer>
          <SecondaryButton isSmall text={label} onPress={onPress} isDisabled={!job.isComplete} />
        </TooltipContentContainer>
      </TextTooltip>
      <Space width={12} />
    </React.Fragment>
  );
};

const DesktopHeaderButtons = ({
  viewer,
  job,
  addTimesheetPayrollBlockModal,
  jobMissingDateModal,
  finalizeJobModal,
  unfinalizeJobModal,
}) => {
  return (
    <Row>
      {job.isFinal ? (
        <FinalizeButton
          job={job}
          label={'Unfinalize Job'}
          onPress={unfinalizeJobModal.handleOpen}
          isVisible={UserRole.getIsStaffAdmin(viewer.role)}
        />
      ) : (
        <FinalizeButton
          job={job}
          label={'Finalize Job'}
          onPress={finalizeJobModal.handleOpen}
          isVisible={UserRole.ADMIN_ROLES_PLUS_SUPER.includes(viewer.role)}
        />
      )}
      <JobActionDisabledTooltip
        job={job}
        customMessage={getHasMovers({job}) ? null : 'Must add movers before adding time.'}
      >
        <Button
          isSmall
          iconLeft={Icon.Plus}
          text={'Add Time'}
          onPress={
            job.startDate
              ? addTimesheetPayrollBlockModal.handleOpen
              : jobMissingDateModal.handleOpen
          }
          isDisabled={!getHasMovers({job}) || job.isFinal}
        />
      </JobActionDisabledTooltip>
    </Row>
  );
};

const MobileActionsDropdown = ({
  viewer,
  job,
  addTimesheetPayrollBlockModal,
  jobMissingDateModal,
  unfinalizeJobModal,
  finalizeJobModal,
}) => {
  const baseFinalizeJobAction = {
    tooltip: job.isComplete ? '' : 'This job must be complete before finalizing.',
    isDisabled: !job.isComplete,
  };
  return (
    <DropdownButton
      text={'Actions'}
      isSmall
      menuWidth={180}
      menuPosition={DropdownButton.MENU_POSITION.RIGHT}
      actions={[
        ...List.insertIf(job.isFinal && UserRole.getIsStaffAdmin(viewer.role), {
          text: 'Unfinalize Job',
          onPress: unfinalizeJobModal.handleOpen,
          ...baseFinalizeJobAction,
        }),
        ...List.insertIf(!job.isFinal && UserRole.ADMIN_ROLES_PLUS_SUPER.includes(viewer.role), {
          text: 'Finalize Job',
          onPress: finalizeJobModal.handleOpen,
          ...baseFinalizeJobAction,
        }),
        {
          text: 'Add Time',
          onPress: job.startDate
            ? addTimesheetPayrollBlockModal.handleOpen
            : jobMissingDateModal.handleOpen,
          tooltip: !getHasMovers({job})
            ? 'Must add movers before adding time.'
            : job.isFinal
              ? UserRole.getJobActionDisabledTooltip(viewer.role)
              : '',
          isDisabled: !getHasMovers({job}) || job.isFinal,
        },
      ]}
      ButtonComponent={MobileProjectBlockHeader.EllipsisButton}
    />
  );
};

const PayrollTimesheets = ({job, refetch, responsive, viewer}) => {
  return (
    <TimesheetPayrollEntries
      job={job}
      paddingHorizontal={responsive.desktop ? 24 : 16}
      onUpdate={refetch}
      viewerRole={viewer.role}
    />
  );
};

const CrewHoursAndTipsContent = ({job, refetch, responsive, viewer, isCrewHoursOnly}) => {
  if (!getHasMovers({job})) {
    return <EmptyState title={'No movers'} message={'Add movers to view and edit crew hours.'} />;
  }

  return (
    <React.Fragment>
      <PayrollTimesheets job={job} refetch={refetch} responsive={responsive} viewer={viewer} />
      {!isCrewHoursOnly && (
        <React.Fragment>
          <Space height={24} />
          <TipsSection>
            <TipsHeader responsive={responsive}>Tips</TipsHeader>
            <Space height={8} />
            {job.assignedJobUsersForTips.map((jobUser, index) => {
              return (
                <React.Fragment key={jobUser.id}>
                  {index > 0 && <Space height={12} />}
                  <Row style={{justifyContent: 'space-between'}}>
                    <TipText responsive={responsive}>{jobUser.user.fullName}</TipText>
                    {/*
                    Currently all tips for a project are split across the movers on the last job.
                    There will later be a project where we'll support linking tips to jobs so that
                    we can distribute a tip across movers for that particular job. We'll also
                    support users being able to control how to split the tip across the movers.
                    */}
                    <TipText responsive={responsive}>{Currency.display(jobUser.tipAmount)}</TipText>
                  </Row>
                </React.Fragment>
              );
            })}
          </TipsSection>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const JobCrewHoursAndTipsBlockContent = ({
  viewer,
  job,
  project,
  refetch,
  responsive,
  isCrewHoursOnly,
}) => {
  const addTimesheetPayrollBlockModal = useModal({name: 'Add Timesheet Payroll Block Modal'});
  const jobMissingDateModal = useModal({name: 'Job Missing Date Modal'});
  const finalizeJobModal = useModal({name: 'Finalize Job Modal'});
  const unfinalizeJobModal = useModal({name: 'Unfinalize Job Modal'});
  const headerText = `Crew Hours${isCrewHoursOnly ? '' : ' & Tips'}`;
  const bodyProps = {job, project, refetch, responsive, viewer, isCrewHoursOnly};
  const actionComponentProps = {
    viewer,
    job,
    addTimesheetPayrollBlockModal,
    jobMissingDateModal,
    finalizeJobModal,
    unfinalizeJobModal,
  };

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <ActionPanel
          BodyComponent={CrewHoursAndTipsContent}
          bodyComponentProps={bodyProps}
          ActionButtonComponent={DesktopHeaderButtons}
          actionButtonComponentProps={actionComponentProps}
          title={headerText}
          style={{flex: 1, width: '100%'}}
          bodyStyle={{paddingHorizontal: 0}}
        />
      ) : (
        <React.Fragment>
          <MobileProjectBlockHeader
            title={headerText}
            ActionsComponent={MobileActionsDropdown}
            actionsComponentProps={actionComponentProps}
            style={{paddingHorizontal: 16}}
          />
          <CrewHoursAndTipsContent {...bodyProps} />
        </React.Fragment>
      )}
      <AddTimesheetPayrollBlockModal
        key={addTimesheetPayrollBlockModal.key}
        isOpen={addTimesheetPayrollBlockModal.isOpen}
        handleClose={addTimesheetPayrollBlockModal.handleClose}
        job={job}
        onUpdate={refetch}
      />
      <JobMissingDateModal
        key={jobMissingDateModal.key}
        job={job}
        projectTypeId={project.projectTypeId}
        isOpen={jobMissingDateModal.isOpen}
        handleClose={jobMissingDateModal.handleClose}
        onSuccess={() => {
          addTimesheetPayrollBlockModal.handleOpen();
          refetch();
        }}
        messageExtension={' before adding time'}
      />
      <FinalizeJobModal
        key={finalizeJobModal.key}
        jobId={job.id}
        isOpen={finalizeJobModal.isOpen}
        handleClose={finalizeJobModal.handleClose}
        refetch={refetch}
      />
      <UnfinalizeJobModal
        key={unfinalizeJobModal.key}
        jobId={job.id}
        isOpen={unfinalizeJobModal.isOpen}
        handleClose={unfinalizeJobModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const JobCrewHoursAndTipsBlockQuery = ({
  jobUuid,
  project,
  blockToPositionY,
  handleSetPositionY,
  layoutKey,
  index,
  isCrewHoursOnly,
  projectBlockKind,
}) => {
  const responsive = useResponsive();

  return (
    <ProjectBlockWrapper
      index={index}
      query={JobCrewHoursAndTipsBlock.query}
      layoutKey={blockToPositionY[ProjectBlockKind.Job.CREW_HOURS_AND_TIPS] || layoutKey}
      queryVariables={{jobUuid}}
      handleSetPositionY={handleSetPositionY}
      style={responsive.desktop ? null : {paddingVertical: 24}}
      projectBlockKind={projectBlockKind}
    >
      {({data, refetch}) => {
        return (
          <JobCrewHoursAndTipsBlockContent
            viewer={data.viewer}
            job={data.job}
            project={project}
            refetch={refetch}
            responsive={responsive}
            isCrewHoursOnly={isCrewHoursOnly}
          />
        );
      }}
    </ProjectBlockWrapper>
  );
};

const JobCrewHoursAndTipsBlock = ({
  jobUuid,
  project,
  blockToPositionY,
  handleSetPositionY,
  layoutKey,
  index,
  isCrewHoursOnly,
  projectBlockKind,
}) => {
  return (
    <JobCrewHoursAndTipsBlockQuery
      key={`${project.jobUsersHash}_${project.timesheetBillableEntriesHash}`}
      jobUuid={jobUuid}
      project={project}
      blockToPositionY={blockToPositionY}
      handleSetPositionY={handleSetPositionY}
      layoutKey={layoutKey}
      index={index}
      isCrewHoursOnly={isCrewHoursOnly}
      projectBlockKind={projectBlockKind}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCrewHoursAndTipsBlock.listener = gql`
  fragment JobCrewHoursAndTipsBlock_listener on Project {
    id
    jobUsersHash
    timesheetBillableEntriesHash
    projectTypeId
  }
`;

JobCrewHoursAndTipsBlock.query = gql`
  ${AddTimesheetBillableBlockModal.fragment}
  ${TimesheetPayrollEntries.fragment}
  ${JobActionDisabledTooltip.fragment}
  ${JobDetailsBlock.listener}
  ${JobMissingDateModal.fragment}

  query JobCrewHoursAndTipsBlock($jobUuid: String!) {
    ${gql.query}
    viewer {
      id
      role
    }
    job(uuid: $jobUuid) {
      id
      startDate
      isComplete
      isFinal
      timesheetBillableEntries {
        id
      }
      assignedJobUsersForTips {
        id
        tipAmount
        user {
          id
          fullName
        }
      }
      ...AddTimesheetBillableBlockModal
      ...TimesheetPayrollEntries
      ...JobActionDisabledTooltip
      ...JobDetailsBlock_listener
      ...JobMissingDateModal
    }
  }
`;

export default JobCrewHoursAndTipsBlock;
