// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ApplyJobTypeConfigsToJobTypesForm from '@shared/modules/Job/forms/ApplyJobTypeConfigsToJobTypesForm';

const useApplyJobTypeConfigsToJobTypesMutation = ({
  applyJobTypeConfigsToJobTypesForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      applyJobTypeConfigsToJobTypesForm: ApplyJobTypeConfigsToJobTypesForm.toForm(
        applyJobTypeConfigsToJobTypesForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useApplyJobTypeConfigsToJobTypesMutation.mutation,
    variables: {
      applyJobTypeConfigsToJobTypesForm: ApplyJobTypeConfigsToJobTypesForm.toMutation(
        form.values.applyJobTypeConfigsToJobTypesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useApplyJobTypeConfigsToJobTypesMutation.mutation = gql`
  mutation useApplyJobTypeConfigsToJobTypesMutation($applyJobTypeConfigsToJobTypesForm: ApplyJobTypeConfigsToJobTypesForm!){
    response: applyJobTypeConfigsToJobTypes(applyJobTypeConfigsToJobTypesForm: $applyJobTypeConfigsToJobTypesForm) {
      ${gql.errors}
      jobType{
        id
      }
    }
  }
`;

export default useApplyJobTypeConfigsToJobTypesMutation;
