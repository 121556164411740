// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useResponsive, useToggle} from '@supermove/hooks';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import Panel from '@shared/design/components/Panel';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import SettingsClaimAssignmentForm from '@shared/modules/Settings/forms/SettingsClaimAssignmentForm';
import useUpdateSettingsClaimAssignmentMutation from '@shared/modules/Settings/hooks/useUpdateSettingsClaimAssignmentMutation';
import ClaimAssignmentPanel from 'modules/Organization/Settings/Company/components/ClaimAssignmentPanel';
import ClaimAssignmentPanelEdit from 'modules/Organization/Settings/Company/components/ClaimAssignmentPanelEdit';
import ClaimTypesTable from 'modules/Organization/Settings/Company/components/ClaimTypesTable';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';
import CreateClaimStatusModal from 'modules/Organization/Settings/Company/components/CreateClaimStatusModal';
import CreateClaimTypeModal from 'modules/Organization/Settings/Company/components/CreateClaimTypeModal';
import OrganizationClaimStatuses from 'modules/Organization/Settings/Company/components/OrganizationClaimStatuses';

const PageContentContainer = Styled.View`
`;

const CalloutContainer = Styled.View`
  max-width: 784px;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 20)}px;
`;

const ClaimSettingsCallout = ({organization, isEnabledClaimsProjectOrganizationMigration}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {isEnabledClaimsProjectOrganizationMigration && !organization.isPrimary && (
        <CalloutContainer responsive={responsive}>
          <Callout
            text={`Claims settings are set by the company. Please contact the admins at ${organization.company.primaryOrganization.name} to make adjustments.`}
          />
          <Space height={24} />
        </CalloutContainer>
      )}
    </React.Fragment>
  );
};

// Produces ClaimType and ClaimStatus create buttons to be used in their respective ActionPanels
const ClaimAttributeCreateButton = ({
  organization,
  isEnabledClaimsProjectOrganizationMigration,
  modal,
}) => {
  return (
    <React.Fragment>
      {isEnabledClaimsProjectOrganizationMigration ? (
        organization.isPrimary && (
          <React.Fragment>
            <TertiaryButton
              iconLeft={Icon.Plus}
              text={'Create'}
              onPress={modal.handleOpen}
              isResponsive
            />
            <Space height={24} />
          </React.Fragment>
        )
      ) : (
        <React.Fragment>
          <TertiaryButton
            iconLeft={Icon.Plus}
            text={'Create'}
            onPress={modal.handleOpen}
            isResponsive
          />
          <Space height={24} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ClaimTypes = ({
  organization,
  refetch,
  isEnabledClaimsProjectOrganizationMigration,
  index,
}) => {
  const createClaimTypeModal = useModal({name: 'Create Claim Type Modal', enableTracking: true});

  return (
    <React.Fragment>
      <ActionPanel
        title={'Types'}
        index={index}
        width={Panel.WIDTH.DEFAULT}
        ActionButtonComponent={ClaimAttributeCreateButton}
        actionButtonComponentProps={{
          organization,
          isEnabledClaimsProjectOrganizationMigration,
          modal: createClaimTypeModal,
        }}
        BodyComponent={ClaimTypesTable}
        bodyComponentProps={{
          organization,
          refetch,
          isEnabledClaimsProjectOrganizationMigration,
        }}
      />
      <CreateClaimTypeModal
        key={createClaimTypeModal.key} // Ensures values are empty when modal is opened
        isOpen={createClaimTypeModal.isOpen}
        handleClose={createClaimTypeModal.handleClose}
        organizationId={organization.id}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const ClaimStatuses = ({
  organization,
  key,
  refetch,
  isEnabledClaimsProjectOrganizationMigration,
  index,
}) => {
  const createClaimStatusModal = useModal({
    name: 'Create Claim Status Modal',
    enableTracking: true,
  });

  return (
    <React.Fragment>
      <ActionPanel
        title={'Statuses'}
        index={index}
        width={Panel.WIDTH.DEFAULT}
        ActionButtonComponent={ClaimAttributeCreateButton}
        actionButtonComponentProps={{
          organization,
          isEnabledClaimsProjectOrganizationMigration,
          modal: createClaimStatusModal,
        }}
        BodyComponent={OrganizationClaimStatuses}
        bodyComponentProps={{
          organization,
          key,
          refetch,
          isEnabledClaimsProjectOrganizationMigration,
        }}
      />
      <CreateClaimStatusModal
        key={createClaimStatusModal.key} // Ensures values are empty when modal is opened
        isOpen={createClaimStatusModal.isOpen}
        handleClose={createClaimStatusModal.handleClose}
        organizationId={organization.id}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const ClaimAssignment = ({
  organization,
  isEnabledClaimsProjectOrganizationMigration,
  refetch,
  index,
}) => {
  const {settings} = organization;
  const editClaimAssignmentToggle = useToggle();
  const settingsClaimAssignmentForm = SettingsClaimAssignmentForm.edit(settings);
  const {form, handleSubmit, submitting} = useUpdateSettingsClaimAssignmentMutation({
    settingsClaimAssignmentForm,
    onSuccess: () => {
      editClaimAssignmentToggle.handleToggleOff();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      title={'Assignment Options'}
      index={index}
      isEditButtonVisible={isEnabledClaimsProjectOrganizationMigration && organization.isPrimary}
      BodyComponent={ClaimAssignmentPanel}
      bodyComponentProps={{settings}}
      EditBodyComponent={ClaimAssignmentPanelEdit}
      editBodyComponentProps={{form}}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editClaimAssignmentToggle.isOn}
      handleEdit={editClaimAssignmentToggle.handleToggleOn}
      handleClose={editClaimAssignmentToggle.handleToggleOff}
    />
  );
};

const SettingsCompanyClaimsPageContent = ({viewer, refetch}) => {
  const {viewingOrganization} = viewer;
  const {isEnabledClaimsProjectOrganizationMigration, isEnabledClaimsAssignMovers} =
    viewingOrganization.features;

  return (
    <PageContentContainer>
      <ClaimSettingsCallout
        organization={viewingOrganization}
        isEnabledClaimsProjectOrganizationMigration={isEnabledClaimsProjectOrganizationMigration}
      />
      <ClaimTypes
        organization={viewingOrganization}
        refetch={refetch}
        isEnabledClaimsProjectOrganizationMigration={isEnabledClaimsProjectOrganizationMigration}
        index={1}
      />
      <Space height={24} />
      <ClaimStatuses
        organization={viewingOrganization}
        key={viewingOrganization.companySettings.claimStatuses.length}
        refetch={refetch}
        isEnabledClaimsProjectOrganizationMigration={isEnabledClaimsProjectOrganizationMigration}
        index={2}
      />
      {isEnabledClaimsAssignMovers && (
        <React.Fragment>
          <Space height={24} />
          <ClaimAssignment
            organization={viewingOrganization}
            isEnabledClaimsProjectOrganizationMigration={
              isEnabledClaimsProjectOrganizationMigration
            }
            refetch={refetch}
            index={3}
          />
        </React.Fragment>
      )}
    </PageContentContainer>
  );
};

const OrganizationSettingsCompanyClaimsPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsCompanyClaimsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage
      title={'Claims'}
      description={'Set up and configure claim types, statuses, and assignments.'}
      isResponsive
    >
      <Loading loading={loading}>
        {() => <SettingsCompanyClaimsPageContent viewer={data.viewer} refetch={refetch} />}
      </Loading>
    </CompanySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyClaimsPage.query = gql`
  ${OrganizationClaimStatuses.fragment}
  ${ClaimTypesTable.fragment}
  ${SettingsClaimAssignmentForm.edit.fragment}
  ${ClaimAssignmentPanel.fragment}

  query OrganizationSettingsCompanyClaimsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        isPrimary
        slug
        company {
          id
          primaryOrganization {
            id
            name
          }
        }
        features {
          isEnabledClaimsProjectOrganizationMigration: isEnabled(feature: "CLAIMS_PROJECT_ORGANIZATION_MIGRATION")
          isEnabledClaimsAssignMovers: isEnabled(feature: "CLAIMS_ASSIGN_MOVERS")
        }
        settings {
          id
          ...SettingsClaimAssignmentForm_edit
          ...ClaimAssignmentPanel
        }
        companySettings {
          claimStatuses {
            id
            rank
          }
        }
        ...ClaimTypesTable
        ...OrganizationClaimStatuses
      }
    }
  }
`;

export default OrganizationSettingsCompanyClaimsPage;
