// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Inventory} from '@supermove/models';
import {withFragment, uuid} from '@supermove/utils';

// App
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import ItemAttachmentForm from '@shared/modules/Inventory/forms/ItemAttachmentForm';
import ItemTagItemForm from '@shared/modules/Inventory/forms/ItemTagItemForm';

const edit = withFragment(
  (item, {index}) => ({
    itemId: (item as any).id,
    collectionId: (item as any).collectionId,
    name: (item as any).name,
    takeCount: (item as any).takeCount,
    leaveCount: (item as any).leaveCount,
    weight: (item as any).weight,
    volume: (item as any).volume,
    price: (item as any).price,
    isDerivedWeight: (item as any).isDerivedWeight,
    itemTypeId: (item as any).itemTypeId,
    isVoid: (item as any).isVoid,
    isDeleted: (item as any).isDeleted,
    notes: (item as any).notes,
    kind: (item as any)?.itemType?.kind || ItemTypeKind.ITEM,
    uuid: (item as any).uuid,
    // Only used for driver inventory
    lotNumber: (item as any).lotNumber,
    color: (item as any).color,
    itemNumber: (item as any).itemNumber,
    itemTagItemForms: (item as any).itemTagItems.map((itemTagItem: any) =>
      ItemTagItemForm.edit(itemTagItem),
    ),
    itemAttachmentForms: (item as any).attachments.map((itemAttachment: any) =>
      ItemAttachmentForm.edit(itemAttachment),
    ),
    // private
    isDirty: false,
    // This makes sure we default the take property to true
    // We only consider the item as not take if the leave count is greater than 0
    take: !((item as any).leaveCount > 0),
    // Index is passed in here so we have a unique identifier for each item
    // itemId cannot be used as a unique id because new items do not have an id
    index,
  }),
  gql`
    ${ItemTagItemForm.edit.fragment}
    ${ItemAttachmentForm.edit.fragment}

    fragment ItemFormV2_edit on Item {
      id
      uuid
      collectionId
      name
      notes
      takeCount
      leaveCount
      weight
      volume
      price
      lotNumber
      color
      itemNumber
      isDerivedWeight
      itemTypeId
      isVoid
      isDeleted
      itemType {
        id
        kind
      }
      itemTagItems {
        id
        ...ItemTagItemForm_edit
      }
      attachments {
        id
        ...ItemAttachmentForm_edit
      }
    }
  `,
);

const _new = ({
  collectionId,
  name = '',
  weight = 0,
  volume = 0,
  price = 0,
  notes = '',
  isDerivedWeight = false,
  itemTypeId,
  index,
  kind,
  isVoid = false,
  lotNumber = null,
  color = null,
  itemNumber = null,
  itemTagItems = [],
  itemAttachments = [],
}: any) => ({
  // Temp item ID used so that we have a unique identifier to delete the correct row in the virtualized table
  // Without a unique identifier we can't identify which row to rerender in the table
  itemId: `NEW_${uuid()}`,
  collectionId,
  name,
  takeCount: 1,
  leaveCount: 0,
  weight,
  volume,
  price,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted: false,
  notes,
  kind,
  uuid: uuid(),
  // Only used for driver inventory
  lotNumber,
  color,
  itemNumber,
  // @ts-expect-error TS(7006): Parameter 'itemTagItem' implicitly has an 'any' ty... Remove this comment to see the full error message
  itemTagItemForms: itemTagItems.map((itemTagItem) => ItemTagItemForm.new(itemTagItem)),
  // @ts-expect-error TS(7006): Parameter 'itemAttachment' implicitly has an 'any'... Remove this comment to see the full error message
  itemAttachmentForms: itemAttachments.map((itemAttachment) =>
    ItemAttachmentForm.new(itemAttachment),
  ),

  // private
  isDirty: true,
  take: true,
  index,
});

const newFromItemType = withFragment(
  (itemType, {collectionId, index}) => {
    return _new({
      collectionId,
      name: (itemType as any).name,
      weight: (itemType as any).weight,
      volume: (itemType as any).volume,
      price: (itemType as any).price,
      isDerivedWeight: (itemType as any).isDerivedWeight,
      itemTypeId: (itemType as any).id,
      index,
      kind: (itemType as any).kind,
    });
  },
  gql`
    fragment ItemFormV2_newFromItemType on ItemType {
      id
      name
      weight
      volume
      price
      isDerivedWeight
      kind
    }
  `,
);

const toForm = ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted,
  notes,
  kind,
  uuid,
  lotNumber,
  color,
  itemNumber,
  itemTagItemForms,
  itemAttachmentForms,

  // private
  isDirty,

  take,
  index,
}: any) => ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted,
  notes,
  kind,
  uuid,
  lotNumber,
  color,
  itemNumber,
  itemTagItemForms: itemTagItemForms.map((itemTagItemForm: any) =>
    ItemTagItemForm.toForm(itemTagItemForm),
  ),
  itemAttachmentForms: itemAttachmentForms.map((itemAttachmentForm: any) =>
    ItemAttachmentForm.toForm(itemAttachmentForm),
  ),

  // private
  isDirty,
  take,
  index,
});

const toMutation = ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted,
  notes,
  uuid,
  lotNumber,
  color,
  itemNumber,
  itemTagItemForms,
  itemAttachmentForms,
}: any) => ({
  // Do not send itemId if it's a newly created item
  itemId: itemId.includes('NEW') ? undefined : itemId,
  collectionId,
  name,
  takeCount: Inventory.getFloatValue(takeCount),
  leaveCount: Inventory.getFloatValue(leaveCount),
  weight: Inventory.getFloatValue(weight),
  volume: Inventory.getFloatValue(volume),
  price,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted,
  notes,
  uuid,
  lotNumber: !lotNumber ? null : lotNumber,
  color: !color ? null : color,
  itemNumber,
  itemTagItemForms: itemTagItemForms.map((itemTagItemForm: any) =>
    ItemTagItemForm.toMutation({...itemTagItemForm, itemUuid: uuid}),
  ),
  itemAttachmentForms: itemAttachmentForms.map((itemAttachmentForm: any) =>
    ItemAttachmentForm.toMutation({...itemAttachmentForm, itemUuid: uuid}),
  ),
});

const getItemTypeMatchesMinItemNumber = ({minItemNumber, itemForm}: any) => {
  const min = _.toNumber(minItemNumber);
  if (min) {
    return itemForm.itemNumber >= min;
  }
  return true;
};

const getItemTypeMatchesMaxItemNumber = ({maxItemNumber, itemForm}: any) => {
  const max = _.toNumber(maxItemNumber);
  if (max) {
    return itemForm.itemNumber <= max;
  }
  return true;
};

const getItemTypeMatchesRoom = ({roomIds, allRooms, itemForm}: any) => {
  const itemRoom = allRooms.find((room: any) =>
    room.itemForms.some((filteredItem: any) => _.isEqual(filteredItem, itemForm)),
  );
  if (roomIds) {
    return _.includes(roomIds, itemRoom.roomId);
  }
  return true;
};

const getItemTypeMatchesLot = ({lotNumbers, itemForm}: any) => {
  if (lotNumbers) {
    return _.includes(lotNumbers, `${itemForm.lotNumber}-${itemForm.color}`);
  }
  return true;
};

const getItemTypeMatchesTagOrException = ({tagsOrExceptions, itemTagItemKind, itemForm}: any) => {
  // This is for both item tag or exception types
  if (tagsOrExceptions) {
    return itemForm.itemTagItemForms.some((itemTagItem: any) => {
      return (
        itemTagItem.kind === itemTagItemKind &&
        itemTagItem.itemTagIds.some((itemTagId: any) =>
          tagsOrExceptions.includes(itemTagId.toString()),
        )
      );
    });
  }
  return true;
};

const getItemTypeShowVoidedLabel = ({showVoidedLabels, itemForm}: any) => {
  return showVoidedLabels !== 'false' || itemForm.isVoid === false;
};

const ItemFormV2 = {
  edit,
  new: _new,
  newFromItemType,
  toForm,
  toMutation,

  // Helpers
  getItemTypeMatchesMinItemNumber,
  getItemTypeMatchesMaxItemNumber,
  getItemTypeMatchesRoom,
  getItemTypeMatchesLot,
  getItemTypeMatchesTagOrException,
  getItemTypeShowVoidedLabel,
};

export default ItemFormV2;
