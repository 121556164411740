// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SortDaySlotsForm from '@shared/modules/Dispatch/forms/SortDaySlotsForm';

const useSortDaySlotsMutation = ({sortDaySlotsForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      sortDaySlotsForm: SortDaySlotsForm.toForm(sortDaySlotsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSortDaySlotsMutation.mutation,
    variables: {
      sortDaySlotsForm: SortDaySlotsForm.toMutation(form.values.sortDaySlotsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useSortDaySlotsMutation.mutation = gql`
  mutation useSortDaySlotsMutation($sortDaySlotsForm: SortDaySlotsForm!) {
    response: sortDaySlots(sortDaySlotsForm: $sortDaySlotsForm) {
      ${gql.errors}
      slots {
        id
      }
    }
  }
`;

export default useSortDaySlotsMutation;
