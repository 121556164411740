// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import ClaimStatusForm from '@shared/modules/Claim/forms/ClaimStatusForm';

const edit = withFragment(
  (claimStatuses) => ({
    claimStatusForms: (claimStatuses as any).map((claimStatus: any) => {
      return ClaimStatusForm.edit(claimStatus);
    }),
  }),
  gql`
    ${ClaimStatusForm.edit.fragment}

    fragment ReorderClaimStatusesForm_edit on ClaimStatus {
      id
      ...ClaimStatusForm_edit
    }
  `,
);

const toForm = ({claimStatusForms}: any) => ({
  claimStatusForms: claimStatusForms.map((claimStatusForm: any) => {
    return ClaimStatusForm.toForm(claimStatusForm);
  }),
});

const toMutation = ({claimStatusForms}: any) => ({
  claimStatusForms: claimStatusForms.map((claimStatusForm: any) => {
    return ClaimStatusForm.toMutation(claimStatusForm);
  }),
});

const ReorderClaimStatusesForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderClaimStatusesForm;
