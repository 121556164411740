// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

const getDisplayDates = withFragment(
  (trip) => {
    // @ts-expect-error TS(2339): Property 'startDate' does not exist on type 'unkno... Remove this comment to see the full error message
    const {startDate, endDate} = trip;
    const startDateText = startDate ? Datetime.convertToDisplayDate(startDate, 'MM/DD/YY') : 'TBD';
    const endDateText = startDate ? Datetime.convertToDisplayDate(endDate, 'MM/DD/YY') : 'TBD';
    return `${startDateText} - ${endDateText}`;
  },
  gql`
    fragment Trip_getDisplayDates on Trip {
      id
      startDate
      endDate
    }
  `,
);

const Trip = {
  getDisplayDates,
};

export default Trip;
