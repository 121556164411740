// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';

const SectionHeaderContainer = Styled.View`
  padding: 12px;
`;

const SectionHeaderText = Styled.Text`
  ${Typography.Label}
`;

const Line = Styled.View`
  height: 1px;
  width: 100%;
  background-color: ${colors.gray.border};
`;

const SectionHeader = ({name, index}: any) => {
  return (
    <React.Fragment>
      {index > 0 && (
        <React.Fragment>
          <Space height={12} />
          <Line />
          <Space height={12} />
        </React.Fragment>
      )}
      <SectionHeaderContainer>
        <SectionHeaderText>{name}</SectionHeaderText>
      </SectionHeaderContainer>
    </React.Fragment>
  );
};

const DropdownSection = ({name, actions, index, handleClose, onSelect}: any) => {
  return (
    <React.Fragment key={index}>
      <SectionHeader name={name} index={index} />
      {actions.map((item: any, itemIndex: any) => (
        <DropdownButton.ActionItem
          {...item}
          key={itemIndex}
          index={itemIndex}
          handleClose={handleClose}
          onSelect={onSelect}
          style={{paddingLeft: 24}}
        />
      ))}
    </React.Fragment>
  );
};

const SectionedDropdownButton = ({children, sections, ...props}: any) => {
  return (
    <DropdownButton {...props} actions={sections}>
      {children}
    </DropdownButton>
  );
};

SectionedDropdownButton.MENU_POSITION = DropdownButton.MENU_POSITION;

// --------------------------------------------------
// Props
// --------------------------------------------------
SectionedDropdownButton.propTypes = {
  isDisabled: PropTypes.bool,
  isWidthOfContainer: PropTypes.bool,
  width: PropTypes.number,
  menuWidth: PropTypes.number,
  menuPosition: PropTypes.string,
  buttonStyle: PropTypes.object,
  style: PropTypes.object,
  ButtonComponent: PropTypes.func,
  isVisibleArrow: PropTypes.bool,
  iconLeft: PropTypes.string,
  isSmall: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSelect: PropTypes.func,
  actionButtonContainerStyle: PropTypes.object,
  ActionItemComponent: PropTypes.func,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      actions: PropTypes.array.isRequired,
    }),
  ).isRequired,
};

SectionedDropdownButton.defaultProps = {
  isDisabled: false,
  isWidthOfContainer: false,
  width: null,
  menuWidth: null,
  menuPosition: DropdownButton.MENU_POSITION.LEFT,
  buttonStyle: {},
  style: {},
  ButtonComponent: undefined,
  isVisibleArrow: true,
  iconLeft: null,
  isSmall: false,
  isSubmitting: false,
  onSelect: () => {},
  actionButtonContainerStyle: {},
  ActionItemComponent: DropdownSection,
};

export default SectionedDropdownButton;
