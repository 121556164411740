// Supermove
import {useCallback, useMountEffect, useState} from '@supermove/hooks';

const useCheckMuxVideoIsReady = ({url}: any) => {
  const [isReady, setIsReady] = useState(false);

  const checkHLSDataReady = (url: any) => {
    fetch(url).then((response) => {
      if (response.status === 200) {
        setIsReady(true);
      } else {
        setTimeout(() => checkHLSDataReady(url), 3000);
      }
    });
  };

  const checkHLSDataReadyCallback = useCallback(checkHLSDataReady, [url]);

  useMountEffect(() => {
    checkHLSDataReadyCallback(url);
  });

  return {
    isReady,
  };
};

export default useCheckMuxVideoIsReady;
