// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, useForm} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import WorkflowBuilder from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilder';

const WorkflowPreviewPanelContainer = Styled.View`
  width: 580px;
`;

const Title = Styled.Text`
  ${Typography.Heading2}
`;

const EmptyContentContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const PanelHeader = Styled.View`
  height: 60px;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 24px;
  padding-vertical: 12px;
  background-color: ${colors.white}
`;

const WorkflowPreviewPlaceholderText = Styled.Text`
  ${Typography.Subheading};
  color: ${colors.gray.tertiary};
`;

const WorkflowPreviewPlaceholder = () => {
  return (
    <EmptyContentContainer>
      <WorkflowPreviewPlaceholderText>
        Click edit automation to start building!
      </WorkflowPreviewPlaceholderText>
    </EmptyContentContainer>
  );
};

const WorkflowPreviewerContent = ({workflow}) => {
  const form = useForm({
    initialValues: {workflowForm: WorkflowForm.edit(workflow)},
  });

  const isEmptyWorkflow = !workflow.workflowSteps.length;
  if (isEmptyWorkflow) {
    return <WorkflowPreviewPlaceholder />;
  }
  return <WorkflowBuilder workflow={workflow} form={form} isPreview />;
};

const WorkflowPreviewPanel = ({workflows}) => {
  const {
    navigator,
    params: {workflowUuid},
  } = useNavigationDOM();
  const selectedWorkflow = _.find(workflows, (workflow) => workflow.uuid === workflowUuid);

  const {loading, data} = useQuery(WorkflowPreviewPanel.query, {
    fetchPolicy: 'cache-and-network',
    skip: !selectedWorkflow,
    variables: {
      uuid: workflowUuid,
    },
  });

  return (
    <WorkflowPreviewPanelContainer>
      {!selectedWorkflow ? (
        <React.Fragment>
          <PanelHeader style={{justifyContent: 'center'}}>
            <WorkflowPreviewPlaceholderText>
              Select a automation to preview
            </WorkflowPreviewPlaceholderText>
          </PanelHeader>
          <Line />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <PanelHeader>
            <Title>{selectedWorkflow.name}</Title>
            <Space style={{flex: 1}} />
            <SecondaryButton
              text={'Edit Automation'}
              iconLeft={Icon.Pen}
              onPress={() => navigator.push(`/settings/automations/${selectedWorkflow.uuid}/build`)}
            />
          </PanelHeader>
          <Line />
          {loading ? (
            <EmptyContentContainer>
              <PageLoadingIndicator />
            </EmptyContentContainer>
          ) : (
            <React.Fragment>
              <WorkflowPreviewerContent workflow={data.workflow} />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </WorkflowPreviewPanelContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowPreviewPanel.query = gql`
  ${WorkflowBuilder.fragment}
  ${WorkflowForm.edit.fragment}

  query WorkflowPreviewPanel($uuid: String!) {
    ${gql.query}
    workflow(uuid: $uuid) {
      id
      workflowSteps {
        id
      }
      ...WorkflowBuilder
      ...WorkflowForm_edit
    }
  }
`;

export default WorkflowPreviewPanel;
