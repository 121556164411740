// Libraries
import React from 'react';

import {Icon} from '@supermove/components';

// App
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';

const BulkArchiveModal = ({title, subtitle, submitText, isOpen, handleClose, handleSubmit}) => {
  return (
    <ConfirmationModal
      icon={Icon.BoxOpen}
      title={title}
      subtitle={subtitle}
      isOpen={isOpen}
      handlePrimaryAction={handleSubmit}
      primaryActionText={submitText}
      handleSecondaryAction={handleClose}
      secondaryActionText={'Nevermind'}
    />
  );
};

export default BulkArchiveModal;
