// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';

const FilterContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const TagDropdownInputField = ({
  options,
  customOptions,
  label,
  placeholder,
  index,
  value,
  onChangeValue,
  isPortaled,
  isRequired,
  showTrainingTag,
}) => {
  // Always filter out "Training" tags from dropdown
  const sortedOptions = _.sortBy(options, ['label']).filter((tag) => {
    return showTrainingTag || tag.label !== '🎓 Training';
  });

  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText isResponsive isRequired={isRequired}>
        {label}
      </FieldInput.LabelText>
      <Space height={4} />
      <MultiDropdownCheckboxInput
        options={sortedOptions}
        customOptions={customOptions}
        placeholder={placeholder}
        style={{width: '100%'}}
        value={value}
        usePills
        isPortaled={isPortaled}
        onChangeValue={(tags) => {
          onChangeValue(tags);
        }}
        isSearchable
        numberOfVisibleSelections={2}
      />
    </FilterContainer>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
TagDropdownInputField.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  index: PropTypes.number,
  value: PropTypes.array,
  onChangeValue: PropTypes.func,
  isPortaled: PropTypes.bool,
  isRequired: PropTypes.bool,
  showTrainingTag: PropTypes.bool,
};

TagDropdownInputField.defaultProps = {
  label: '',
  placeholder: '',
  index: 0,
  value: [],
  isPortaled: false,
  isRequired: false,
  showTrainingTag: false,
  onChangeValue: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TagDropdownInputField.fragment = gql`
  fragment TagDropdownInputField on Tag {
    id
    name
    emoji
    kind
  }
`;

export default TagDropdownInputField;
