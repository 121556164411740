// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Float} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    businessAddress: (organization as any).businessAddress || '',
    warehouseAddress: (organization as any).warehouseAddress || '',
    warehouseLatitude: (organization as any).warehouseLatitude,
    warehouseLongitude: (organization as any).warehouseLongitude,
  }),
  gql`
    fragment OrganizationBusinessInfoAddressForm_edit on Organization {
      id
      businessAddress
      warehouseAddress
      warehouseLatitude
      warehouseLongitude
    }
  `,
);

const toForm = ({
  organizationId,
  businessAddress,
  warehouseAddress,
  warehouseLatitude,
  warehouseLongitude,
}: any) => ({
  organizationId,
  businessAddress,
  warehouseAddress,
  warehouseLatitude,
  warehouseLongitude,
});

const toMutation = ({
  organizationId,
  businessAddress,
  warehouseAddress,
  warehouseLatitude,
  warehouseLongitude,
}: any) => ({
  organizationId,
  businessAddress,
  warehouseAddress,
  warehouseLatitude: warehouseAddress ? Float.toFloat(warehouseLatitude) : null,
  warehouseLongitude: warehouseAddress ? Float.toFloat(warehouseLongitude) : null,
});

const OrganizationBusinessInfoAddressForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationBusinessInfoAddressForm;
