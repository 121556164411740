// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {PaymentMethodModel} from '@supermove/models';

// App
import DeletePaymentMethodForm, {
  DeletePaymentMethodFormType,
} from '@shared/modules/PaymentMethod/forms/DeletePaymentMethodForm';

const useDeletePaymentMethodMutation = ({
  deletePaymentMethodForm,
  onSuccess,
  onError,
}: {
  deletePaymentMethodForm: DeletePaymentMethodFormType;
  onSuccess?: (response: {paymentMethod: PaymentMethodModel}) => void;
  onError?: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      deletePaymentMethodForm: DeletePaymentMethodForm.toForm(deletePaymentMethodForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeletePaymentMethodMutation.mutation,
    variables: {
      deletePaymentMethodForm: DeletePaymentMethodForm.toMutation(
        form.values.deletePaymentMethodForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeletePaymentMethodMutation.mutation = gql`
  mutation useDeletePaymentMethodMutation($deletePaymentMethodForm: DeletePaymentMethodForm!) {
    response: deletePaymentMethod(deletePaymentMethodForm: $deletePaymentMethodForm) {
      ${gql.errors}
      paymentMethod {
        id
      }
    }
  }
`;

export default useDeletePaymentMethodMutation;
