// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

// App
import PayrollReportKind from '@shared/modules/Accounting/enums/PayrollReportKind';

const getDateRange = withFragment(
  (payrollReport, {isShortened} = {}) => {
    const startDate = Datetime.convertToDisplayDate(
      (payrollReport as any).startDate,
      isShortened ? Datetime.DISPLAY_MONTH_DAY : Datetime.DISPLAY_SHORT_DATE,
    );
    const endDate = Datetime.convertToDisplayDate(
      (payrollReport as any).endDate,
      Datetime.DISPLAY_SHORT_DATE,
    );
    return `${startDate} - ${endDate}`;
  },
  gql`
    fragment PayrollReport_getDateRange on PayrollReport {
      id
      startDate
      endDate
    }
  `,
);

const getDisplayKind = withFragment(
  (payrollReport) => {
    switch ((payrollReport as any).kind) {
      case PayrollReportKind.ALL_EMPLOYEE:
        return 'Employee';
      case PayrollReportKind.CONTRACTOR:
        return 'Contractor';
      default:
        return 'Employee';
    }
  },
  gql`
    fragment PayrollReport_getDisplayKind on PayrollReport {
      id
      kind
    }
  `,
);

const getRemainingBalance = withFragment(
  (payrollReport) => {
    // @ts-expect-error TS(2339): Property 'totalCompensation' does not exist on typ... Remove this comment to see the full error message
    const {totalCompensation, totalPaidOut} = payrollReport;
    return totalCompensation - totalPaidOut;
  },
  gql`
    fragment PayrollReport_getRemainingBalance on PayrollReport {
      id
      totalCompensation
      totalPaidOut
    }
  `,
);

const PayrollReport = {
  getDateRange,
  getDisplayKind,
  getRemainingBalance,
};

export default PayrollReport;
