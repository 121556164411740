// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';

import FieldInput from '@shared/design/components/Field/FieldInput';

const CompanyOrganizationsDropdown = ({company, form, fieldName, handleChangeValue}) => {
  return (
    <FieldInput
      {...form}
      name={fieldName}
      component={DropdownInput}
      input={{
        options: _.sortBy(
          company.organizations.map((organization) => ({
            label: organization.name,
            value: organization.slug,
            organization,
          })),
          'label',
        ),
        placeholder: 'Organization',
        style: {width: '200px'},
        setFieldValue: form.setFieldValue,
        onChangeValue: (slug, option) => {
          handleChangeValue(option.organization);
        },
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyOrganizationsDropdown.fragment = gql`
  fragment CompanyOrganizationsDropdown on Company {
    id
    organizations {
      id
      name
      slug
    }
  }
`;

export default CompanyOrganizationsDropdown;
