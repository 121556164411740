// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import BillForm from '@shared/modules/Billing/forms/BillForm';

const useUpdateBillMutation = ({billForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      billForm: BillForm.toForm(billForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateBillMutation.mutation,
    variables: {
      billForm: BillForm.toMutation(form.values.billForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------

useUpdateBillMutation.mutation = gql`
  mutation useUpdateBillMutation($billForm: BillForm!) {
    response: updateBill(billForm: $billForm) {
      ${gql.errors}
      bill {
        id
      }
    }
  }
`;

export default useUpdateBillMutation;
