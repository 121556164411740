// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteCostItemTypeMutation = ({costItemTypeId, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      costItemTypeId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteCostItemTypeMutation.mutation,
    variables: {
      costItemTypeId: form.values.costItemTypeId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteCostItemTypeMutation.mutation = gql`
  mutation useDeleteCostItemTypeMutation($costItemTypeId: Int!) {
    response: deleteCostItemType(costItemTypeId: $costItemTypeId) {
      ${gql.errors}
      costItemType {
        id
      }
    }
  }
`;

export default useDeleteCostItemTypeMutation;
