// Libraries
import React, {useCallback} from 'react';
import {useRutterLink} from 'react-rutter-link';

// Supermove
import {Icon, Space, Styled, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useModal, useState, useQuery} from '@supermove/hooks';
import {Environment} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Panel from '@shared/design/components/Panel';
import CodatIntegrationForm, {
  CodatIntegrationKind,
} from '@shared/modules/Integration/forms/CodatIntegrationForm';
import useCreateCodatIntegrationMutation from '@shared/modules/Integration/hooks/useCreateCodatIntegrationMutation';
import useDeleteCodatIntegrationMutation from '@shared/modules/Integration/hooks/useDeleteCodatIntegrationMutation';
import QuickbooksDesktopLogo from 'modules/Organization/Settings/Company/assets/QuickbooksDesktopLogo';
import QuickbooksLogo from 'modules/Organization/Settings/Company/assets/QuickbooksLogo';
import CodatIntegrationActiveSwitch from 'modules/Organization/Settings/Company/components/CodatIntegrationActiveSwitch';

const RutterMessageContainer = Styled.View`
  align-items: flex-end;
  justify-content: flex-end;
`;

const RutterMessageText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.red.error};
  margin-top: 10px;
`;

const Image = Styled.Image`
  width: 128px;
  height: 38px;
`;

const TextContainer = Styled.View`
`;

const FlexTextContainer = Styled.View`
  display: flex;
  flex-direction: row;
`;

const ButtonContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

const RutterIntegrationContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const DeleteCodatIntegrationModal = ({isOpen, handleClose, handleSubmit, submitting}) => {
  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Are you sure you want to disconnect this integration?'}
      subtitle={
        'This will remove the accounting integration from Supermove. Any and all data related to the integration will be removed. If you re-add the integration later on, you will need to complete the setup process again.'
      }
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

const ConnectToRutter = ({organization, refetch, platform}) => {
  const [errors, setErrors] = useState([]);
  const {form, submitting, handleSubmit} = useCreateCodatIntegrationMutation({
    codatIntegrationForm: CodatIntegrationForm.new({
      kind: CodatIntegrationKind.RUTTER,
      organizationId: organization.id,
    }),
    onSuccess: ({codatIntegration}) => {
      refetch();
    },
    onError: (errors) => {
      setErrors(errors.map((e) => e.message));
    },
  });
  const rutterConfig = {
    publicKey: Environment.get('RUTTER_PUBLIC_KEY'),
    onSuccess: (publicToken) => {
      form.setFieldValue('codatIntegrationForm.rutterPublicToken', publicToken);
      setImmediate(() => {
        handleSubmit();
      });
    },
    onError: (error) => {
      console.log('error', error);
    },
  };
  const {open} = useRutterLink(rutterConfig);
  const qbdCallback = useCallback(() => {
    open({
      platform: 'QUICKBOOKS_DESKTOP',
    });
  }, [open]);
  const qboCallback = useCallback(() => {
    open({
      platform: 'QUICKBOOKS',
    });
  }, [open]);
  return (
    <React.Fragment>
      <Row>
        <TextContainer style={{flex: 1}}>
          <Image
            resizeMode={'contain'}
            source={{uri: platform === 'QUICKBOOKS' ? QuickbooksLogo : QuickbooksDesktopLogo}}
          />
          <Space height={16} />
          <Panel.LabelText>Connect Accounting Software</Panel.LabelText>
          <Space height={8} />
          <Panel.Text>
            Simplify your accounting by connecting your accounting software with Supermove.
          </Panel.Text>
        </TextContainer>
        <Space width={12} />
        <ButtonContainer>
          <Button
            onPress={platform === 'QUICKBOOKS' ? qboCallback : qbdCallback}
            isSubmitting={submitting}
            text={'Connect Account'}
          />
        </ButtonContainer>
      </Row>
      {errors.map((error) => (
        <RutterMessageContainer>
          <RutterMessageText>{error}</RutterMessageText>
        </RutterMessageContainer>
      ))}
    </React.Fragment>
  );
};

const handleNavigateToRutter = (integration) => {
  const connectionId = integration.rutterConnectionId;
  window.location.href = `https://link.rutterapi.com/connection/${connectionId}`;
};

const RutterIntegration = ({integration, refetch}) => {
  const {navigator} = useNavigationDOM();

  const deleteCodatIntegrationModal = useModal();

  const {handleSubmit, submitting} = useDeleteCodatIntegrationMutation({
    codatIntegrationForm: {
      organizationId: integration.organizationId,
      codatIntegrationId: integration.id,
      kind: CodatIntegrationKind.RUTTER,
    },
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      console.error('Error deleting integration', e);
    },
  });

  const {connectionStatus, uuid} = integration;
  const icon = connectionStatus === 'ONLINE' ? Icon.CheckCircle : Icon.TimesCircle;
  const iconColor = connectionStatus === 'ONLINE' ? colors.green.status : colors.red.warning;
  const statusText = connectionStatus === 'ONLINE' ? 'Online' : 'Offline';
  return (
    <RutterIntegrationContainer>
      <TextContainer>
        <Panel.LabelText>Connection Status</Panel.LabelText>
        <Space height={8} />
        <FlexTextContainer>
          <Icon source={icon} size={16} color={iconColor} />
          <Space width={8} />

          <Panel.LabelText
            style={{
              color: iconColor,
            }}
          >
            {statusText}
          </Panel.LabelText>
          <Space height={8} />
        </FlexTextContainer>
      </TextContainer>
      <Space width={16} />
      <TextContainer>
        <Panel.LabelText>Account ID</Panel.LabelText>
        <Space height={8} />
        <TertiaryButton onPress={() => handleNavigateToRutter(integration)}>
          <Panel.Text style={{color: colors.blue.interactive}}>
            {integration.rutterConnectionId}
          </Panel.Text>
        </TertiaryButton>
      </TextContainer>
      <ButtonContainer>
        <SecondaryButton
          text={'Configure'}
          onPress={() => {
            navigator.push(`/settings/company/accounting/${uuid}/general`);
          }}
        />
        <Space width={16} />
        <DropdownButton
          text={'Update Account'}
          actions={[
            {
              text: 'Disconnect Account',
              onPress: deleteCodatIntegrationModal.handleOpen,
            },
          ]}
        />
      </ButtonContainer>
      <DeleteCodatIntegrationModal
        isOpen={deleteCodatIntegrationModal.isOpen}
        handleClose={deleteCodatIntegrationModal.handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    </RutterIntegrationContainer>
  );
};

const getPanelTitle = (platform) => {
  const platformTitles = {
    QUICKBOOKS: 'QuickBooks Online',
    QUICKBOOKS_DESKTOP: 'QuickBooks Desktop',
  };

  return platformTitles[platform] || 'Accounting Integration';
};

const RutterPanelContents = ({organization, refetch, platform}) => {
  const filteredIntegrations = organization.rutterIntegrations.filter(
    (integration) => integration.rutterPlatform === platform,
  );
  const showToggle =
    organization.features.isEnabledAllowTogglingAccountingIntegrationStatuses &&
    filteredIntegrations.length;
  return (
    <React.Fragment>
      <Panel.Header style={{justifyContent: 'space-between', alignItems: 'center'}}>
        <Panel.HeaderText>{getPanelTitle(platform)}</Panel.HeaderText>
        {showToggle ? (
          <React.Fragment>
            <Space width={16} />
            <CodatIntegrationActiveSwitch integration={filteredIntegrations[0]} refetch={refetch} />
          </React.Fragment>
        ) : null}
      </Panel.Header>
      <Panel.Body>
        {filteredIntegrations.length ? (
          <RutterIntegration integration={filteredIntegrations[0]} refetch={refetch} />
        ) : (
          <ConnectToRutter organization={organization} refetch={refetch} platform={platform} />
        )}
      </Panel.Body>
    </React.Fragment>
  );
};

const RutterPanel = ({platform}) => {
  const {loading, data, refetch} = useQuery(RutterPanel.query, {
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Panel width={Panel.WIDTH.DEFAULT}>
      <Loading loading={loading}>
        {() => (
          <RutterPanelContents
            organization={data.viewer.viewingOrganization}
            refetch={refetch}
            platform={platform}
          />
        )}
      </Loading>
    </Panel>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RutterPanel.query = gql`
  ${CodatIntegrationActiveSwitch.fragment}
  query RutterPanel {
    viewer {
      id
      viewingOrganization {
        features {
          isEnabledAllowTogglingAccountingIntegrationStatuses: isEnabled(
            feature: "ALLOW_TOGGLING_ACCOUNTING_INTEGRATION_STATUSES"
          )
        }
        id
        slug
        rutterIntegrations {
          id
          uuid
          rutterConnectionId
          rutterPlatform
          connectionStatus
          ...CodatIntegrationActiveSwitch
        }
      }
    }
  }
`;

export default RutterPanel;
