// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useUpdatePayrollReportPaidOutMutation = ({payrollReportId, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      payrollReportId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdatePayrollReportPaidOutMutation.mutation,
    variables: {
      payrollReportId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdatePayrollReportPaidOutMutation.mutation = gql`
  mutation useUpdatePayrollReportPaidOutMutation($payrollReportId: Int!) {
    response: updatePayrollReportPaidOut(payrollReportId: $payrollReportId) {
      ${gql.errors}
      payrollReport {
        id
      }
    }
  }
`;

export default useUpdatePayrollReportPaidOutMutation;
