// Libraries
import _ from 'lodash';

import {gql} from '@supermove/graphql';
import {OrganizationModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

const ACH = 'ACH';
const AUTHORIZE_DOT_NET = 'AUTHORIZE_DOT_NET';
const CASH = 'CASH';
const CASH_APP = 'CASH_APP';
const CASHIERS_CHECK = 'CASHIERS_CHECK';
const CHECK = 'CHECK';
const EXTERNAL = 'EXTERNAL';
const INVOICE = 'INVOICE';
const OTHER = 'OTHER';
const PAYENGINE_CREDIT_CARD = 'PAYENGINE_CREDIT_CARD';
const PAYENGINE_SAVE_CARD = 'PAYENGINE_SAVE_CARD';
const PAYPAL = 'PAYPAL';
const STRIPE_CREDIT_CARD = 'STRIPE_CREDIT_CARD';
const STRIPE_SAVE_CARD = 'STRIPE_SAVE_CARD';
const STRIPE_TERMINAL = 'STRIPE_TERMINAL';
const VENMO = 'VENMO';
const ZELLE = 'ZELLE';

const PAYMENT_METHOD_KINDS = [
  ACH,
  AUTHORIZE_DOT_NET,
  CASH_APP,
  CASH,
  CASHIERS_CHECK,
  CHECK,
  EXTERNAL,
  INVOICE,
  OTHER,
  PAYENGINE_CREDIT_CARD,
  PAYENGINE_SAVE_CARD,
  PAYPAL,
  STRIPE_CREDIT_CARD,
  STRIPE_SAVE_CARD,
  STRIPE_TERMINAL,
  VENMO,
  ZELLE,
] as const;

export type PaymentMethodKindType = (typeof PAYMENT_METHOD_KINDS)[number];

// These methods are not editable or visible on the office app.
// They are created for all new organizations and can be enabled for the crew app.
const getHardcodedCrewMethods: () => PaymentMethodKindType[] = () => [INVOICE, PAYENGINE_SAVE_CARD];

// Most payment methods are overloaded custom payment methods, used for categorization and reporting.
// Some payment methods can be used to process a transaction (ie. credit cards).
// We want to filter these out when recording manual payments.
const getCanProcessPayments = (kind: PaymentMethodKindType) =>
  _.includes(
    [
      AUTHORIZE_DOT_NET,
      PAYENGINE_CREDIT_CARD,
      PAYENGINE_SAVE_CARD,
      STRIPE_CREDIT_CARD,
      STRIPE_SAVE_CARD,
      STRIPE_TERMINAL,
    ],
    kind,
  );

const getName = (kind: PaymentMethodKindType) => {
  switch (kind) {
    case PAYENGINE_CREDIT_CARD:
    case STRIPE_CREDIT_CARD:
    case AUTHORIZE_DOT_NET:
      return 'Credit Card';
    case EXTERNAL:
      return 'External Credit Card';
    default:
      return getLabel(kind);
  }
};

const getLabel = (kind: PaymentMethodKindType) => {
  switch (kind) {
    case ACH:
      return 'ACH';
    case AUTHORIZE_DOT_NET:
      return 'Credit Card (Authorize.net)';
    case CASH:
      return 'Cash';
    case CASH_APP:
      return 'Cash App';
    case CASHIERS_CHECK:
      return `Cashier's Check`;
    case CHECK:
      return 'Check';
    case EXTERNAL:
      return 'Credit Card (External)';
    case INVOICE:
      return 'Invoice';
    case OTHER:
      return 'Custom';
    case PAYENGINE_CREDIT_CARD:
      return 'Credit Card (Supermove)';
    case PAYENGINE_SAVE_CARD:
      return 'Save Card';
    case PAYPAL:
      return 'PayPal';
    case STRIPE_CREDIT_CARD:
      return 'Credit Card (Stripe)';
    case STRIPE_SAVE_CARD:
      return 'Save Card (Stripe)';
    case STRIPE_TERMINAL:
      return 'Stripe Terminal';
    case VENMO:
      return 'Venmo';
    case ZELLE:
      return 'Zelle';
    default:
      return '';
  }
};

export interface PaymentMethodKindSectionType {
  label: string;
  kinds: PaymentMethodKindType[];
}

const SECTIONS: PaymentMethodKindSectionType[] = [
  {
    label: 'Credit Cards',
    kinds: [PAYENGINE_CREDIT_CARD, EXTERNAL],
  },
  {
    label: 'Digital Payments',
    kinds: [CASH_APP, PAYPAL, VENMO, ZELLE],
  },
  {
    label: 'Bank',
    kinds: [ACH, CASH, CASHIERS_CHECK, CHECK],
  },
  {
    label: 'Other',
    kinds: [OTHER],
  },
];

const getKindToSectionMap = () => {
  const map = new Map<PaymentMethodKindType | '', string>();
  SECTIONS.forEach((section) => {
    section.kinds.forEach((kind) => {
      map.set(kind, section.label);
    });
  });
  return map;
};

const getIsPayengineKind = (kind: PaymentMethodKindType) =>
  _.includes([PAYENGINE_CREDIT_CARD, PAYENGINE_SAVE_CARD], kind);

export interface PaymentMethodSectionType {
  label: string;
  options: PaymentMethodOptionType[];
}

export interface PaymentMethodOptionType {
  label: string;
  value: PaymentMethodKindType;
  isDisabled?: boolean;
}

const getDropdownOptions = withFragment(
  ({organization}: {organization: OrganizationModel}): PaymentMethodSectionType[] => {
    const isPayengineEnabled = organization.payengineMerchant?.isProcessingEnabled;
    return SECTIONS.map(({label, kinds}: {label: string; kinds: PaymentMethodKindType[]}) => ({
      label,
      options: kinds
        .filter((kind) => {
          if (kind === AUTHORIZE_DOT_NET) {
            return organization.features.isEnabledAuthorizeDotNetExternalPayment;
          }
          return true;
        })
        .map((kind) => {
          const isDisabled = !isPayengineEnabled && getIsPayengineKind(kind);
          return {
            label: getLabel(kind),
            value: kind,
            isDisabled,
            tooltip: isDisabled ? 'Set up Supermove Payments under company settings.' : undefined,
          };
        }),
    }));
  },
  gql`
    fragment PaymentMethodKind_getDropdownOptions on Organization {
      id
      features {
        isEnabledAuthorizeDotNetExternalPayment: isEnabled(
          feature: "AUTHORIZE_DOT_NET_EXTERNAL_PAYMENT"
        )
      }
      payengineMerchant {
        id
        isProcessingEnabled
      }
    }
  `,
);

const PaymentMethodKind = {
  ACH,
  AUTHORIZE_DOT_NET,
  CASH_APP,
  CASH,
  CASHIERS_CHECK,
  CHECK,
  EXTERNAL,
  INVOICE,
  OTHER,
  PAYENGINE_CREDIT_CARD,
  PAYENGINE_SAVE_CARD,
  PAYPAL,
  STRIPE_CREDIT_CARD,
  STRIPE_SAVE_CARD,
  STRIPE_TERMINAL,
  VENMO,
  ZELLE,

  // Helpers
  getName,
  getLabel,
  getDropdownOptions,
  getSections: () => SECTIONS,
  getKindToSectionMap,
  getHardcodedCrewMethods,
  getCanProcessPayments,
};

export default PaymentMethodKind;
