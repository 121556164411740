// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, DropdownInput, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import WorkflowFormulaBuilder from '@shared/modules/Workflow/enums/WorkflowFormulaBuilder';
import WorkflowGetDataStopIfHelpers from '@shared/modules/Workflow/enums/WorkflowGetDataStopIfHelpers';
import WorkflowModelProperties from '@shared/modules/Workflow/enums/WorkflowModelProperties';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowTriggerIdentifiers from '@shared/modules/Workflow/enums/WorkflowTriggerIdentifier';
import WorkflowStepForm from '@shared/modules/Workflow/forms/WorkflowStepForm';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const StepNumberContainer = Styled.View`
  width: 56px;
`;

const TagPropertyInput = ({
  form,
  field,
  isJobTypeRequiredWorkflow,
  workflow,
  checkProperty,
  conditionProperty,
}) => {
  return (
    <React.Fragment>
      <ConditionDropdownInput
        form={form}
        field={field}
        checkProperty={checkProperty}
        conditionProperty={conditionProperty}
      />
      <Space height={16} />
      {conditionProperty && (
        <TagDropdownInputFieldByCategory
          form={form}
          field={field}
          isJobTypeRequiredWorkflow={isJobTypeRequiredWorkflow}
          workflow={workflow}
          checkProperty={checkProperty}
          conditionProperty={conditionProperty}
        />
      )}
    </React.Fragment>
  );
};

const TagDropdownInputFieldByCategory = ({
  form,
  field,
  workflow,
  isJobTypeRequiredWorkflow,
  conditionProperty,
}) => {
  const fieldFormForCondition = `${isJobTypeRequiredWorkflow ? 'jobTag' : 'projectTag'}${conditionProperty === WorkflowFormulaBuilder.IS ? 'InclusionList' : 'ExclusionList'}`;
  const tags = workflow.organization.companySettings.tags.filter(
    (tag) =>
      (isJobTypeRequiredWorkflow
        ? [TagCategory.JOB].includes(tag.category)
        : tag.category === TagCategory.PROJECT) && !tag.isArchived,
  );

  const tagOptions = tags
    ? tags.map((tag) => ({
        value: tag.id,
        label: `${tag.emoji} ${tag.name}`,
      }))
    : [];

  return (
    <React.Fragment>
      <TagDropdownInputField
        options={tagOptions}
        index={3}
        placeholder={`Select tag`}
        label={`Select ${isJobTypeRequiredWorkflow ? 'Job' : 'Project'} Tag(s)`}
        isPortaled
        isRequired
        value={_.get(form.values, `${field}.${fieldFormForCondition}`, [])}
        onChangeValue={(tagIds) => {
          form.setFieldValue(`${field}.${fieldFormForCondition}`, tagIds);
        }}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

const ProjectPropertyValueInput = ({form, field, conditionProperty, selectedProperty}) => {
  const fieldFormForCondition = `projectStatus${conditionProperty === WorkflowFormulaBuilder.IS ? 'Equals' : 'DoesNotEqual'}`;
  return (
    <FieldInput
      {...form}
      index={3}
      component={DropdownInput}
      name={`${field}.${fieldFormForCondition}`}
      label={'Select value'}
      isRequired
      input={{
        options: WorkflowModelProperties.getEnumOptions(selectedProperty),
        placeholder: 'Select value',
        isSearchable: true,
        isPortaled: true,
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        menuPlacement: 'auto',
      }}
    />
  );
};

const ProjectPropertyInput = ({
  form,
  field,
  selectedProperty,
  conditionProperty,
  checkProperty,
}) => {
  return (
    <React.Fragment>
      <SelectPropertyDropdownInput
        form={form}
        field={field}
        stepReferenceKind={WorkflowStepActionKind.CATEGORIES.PROJECT}
        checkProperty={checkProperty}
        conditionProperty={conditionProperty}
      />
      <Space height={16} />
      {selectedProperty && (
        <React.Fragment>
          <ConditionDropdownInput
            form={form}
            field={field}
            checkProperty={checkProperty}
            conditionProperty={conditionProperty}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      {selectedProperty && conditionProperty && (
        <React.Fragment>
          <ProjectPropertyValueInput
            form={form}
            field={field}
            conditionProperty={conditionProperty}
            selectedProperty={selectedProperty}
          />
          <Space height={16} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const getWorkflowStepDropdownOptions = ({workflowStepForms}) => {
  return _.map(workflowStepForms, (workflowStepForm) => {
    const {label, kind} =
      WorkflowStepActionKind.ATTRIBUTES[workflowStepForm.workflowStepActionKind];
    return {
      value: WorkflowStepForm.generateWorkflowStepUuidDropdownValue({
        workflowStepForm,
      }),
      kind,
      label,
      stepNumber: workflowStepForm.order + 1,
    };
  });
};

const WorkflowStepOption = ({data, ...props}) => {
  return (
    <DropdownInput.Option {...props}>
      <Row>
        <StepNumberContainer>
          <DropdownInput.OptionSecondaryLabel {...props}>
            {`Step ${data.stepNumber}`}
          </DropdownInput.OptionSecondaryLabel>
        </StepNumberContainer>
        <DropdownInput.OptionLabel {...props}>{data.label}</DropdownInput.OptionLabel>
      </Row>
    </DropdownInput.Option>
  );
};

const WorkflowStepDropdown = ({
  form,
  field,
  workflowStepsField,
  workflowStepIndex,
  conditionProperty,
  index,
}) => {
  const actionKind = _.get(
    form.values,
    `${workflowStepsField}.${workflowStepIndex}.workflowStepActionKind`,
  );
  const {workflowStepDropdown} = WorkflowStepActionKind.ATTRIBUTES[actionKind];

  const workflowStepForms = _.get(form.values, workflowStepsField);
  const priorWorkflowStepForms = _.slice(workflowStepForms, 0, workflowStepIndex);
  const filteredWorkflowStepForms = _.filter(priorWorkflowStepForms, (workflowStepForm) =>
    workflowStepDropdown.optionFilter(workflowStepForm.workflowStepActionKind),
  );

  const fieldFormForCondition = `task${conditionProperty === WorkflowFormulaBuilder.IS ? 'Is' : 'IsNot'}Completed`;

  return (
    <React.Fragment>
      <FieldInput
        {...form}
        component={DropdownInput}
        name={`${field}.${fieldFormForCondition}`}
        label={workflowStepDropdown.label}
        isRequired
        index={index}
        input={{
          options: getWorkflowStepDropdownOptions({
            workflowStepForms: filteredWorkflowStepForms,
          }),
          placeholder: 'Select a automation step',
          isPortaled: true,
          onChangeValue: (uuid) => {
            form.setFieldValue(`${field}.${fieldFormForCondition}`, uuid);
          },
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          menuPlacement: 'auto',
          components: {
            Option: WorkflowStepOption,
          },
        }}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

const TaskPropertyInput = ({
  form,
  field,
  selectedProperty,
  conditionProperty,
  checkProperty,
  workflowStepIndex,
  workflowStepsField,
}) => {
  return (
    <React.Fragment>
      <SelectPropertyDropdownInput
        form={form}
        field={field}
        stepReferenceKind={WorkflowStepActionKind.CATEGORIES.TASK}
        checkProperty={checkProperty}
        conditionProperty={conditionProperty}
      />
      <Space height={16} />
      {selectedProperty && (
        <React.Fragment>
          <ConditionDropdownInput
            form={form}
            field={field}
            checkProperty={checkProperty}
            conditionProperty={conditionProperty}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      {selectedProperty && conditionProperty && (
        <WorkflowStepDropdown
          form={form}
          field={field}
          workflowStepIndex={workflowStepIndex}
          workflowStepsField={workflowStepsField}
          conditionProperty={conditionProperty}
          index={3}
        />
      )}
    </React.Fragment>
  );
};

const CheckDropdownInput = ({form, field}) => {
  return (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={`${field}.checkProperty`}
      label={'Check'}
      index={0}
      isRequired
      input={{
        options: [
          {
            value: WorkflowStepActionKind.CATEGORIES.PROJECT,
            label: 'Project',
          },
          {
            value: WorkflowStepActionKind.CATEGORIES.TAGS,
            label: 'Tag',
          },
          {
            value: WorkflowStepActionKind.CATEGORIES.TASK,
            label: 'Task',
          },
        ],
        isPortaled: true,
        placeholder: 'Select one',
        setFieldValue: form.setFieldValue,
        onChangeValue: (value) => {
          WorkflowGetDataStopIfHelpers.resetGetDataAndStopIfForm({form, field});
          form.setFieldValue(`${field}.checkProperty`, value);
        },
        style: {
          width: '100%',
        },
      }}
      style={{width: '100%'}}
    />
  );
};

const conditionStringForCheck = (checkProperty) => {
  switch (checkProperty) {
    case WorkflowStepActionKind.CATEGORIES.TASK:
      return ['true', 'false'];
    case WorkflowStepActionKind.CATEGORIES.TAGS:
      return ['Is assigned', 'Is not assigned'];
    default:
      return ['Is Equal', 'Is not Equal'];
  }
};

const ConditionDropdownInput = ({form, field, checkProperty}) => {
  const conditionString = conditionStringForCheck(checkProperty);

  return (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={`${field}.conditionProperty`}
      label={'Condition'}
      index={2}
      isRequired
      isPortaled
      input={{
        options: [
          {
            value: WorkflowFormulaBuilder.IS,
            label: conditionString[0],
          },
          {
            value: WorkflowFormulaBuilder.IS_NOT,
            label: conditionString[1],
          },
        ],
        placeholder: 'Select Condition',
        setFieldValue: form.setFieldValue,
        onChangeValue: (value) => {
          WorkflowGetDataStopIfHelpers.resetGetDataAndStopIfForm({form, field});
          form.setFieldValue(`${field}.checkProperty`, checkProperty);
          form.setFieldValue(`${field}.conditionProperty`, value);
        },
        style: {
          width: '100%',
        },
      }}
      style={{width: '100%'}}
    />
  );
};

const SelectPropertyDropdownInput = ({
  form,
  field,
  stepReferenceKind,
  checkProperty,
  conditionProperty,
}) => {
  return (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={`${field}.selectedProperty`}
      label={'Select Property'}
      index={1}
      isRequired
      isPortaled
      input={{
        options:
          WorkflowGetDataStopIfHelpers.getModelPropertyOptionsFormStepReferenceKind(
            stepReferenceKind,
          ),
        placeholder: 'Select Property',
        isPortaled: true,
        onChangeValue: (dataType) => {
          WorkflowGetDataStopIfHelpers.resetGetDataAndStopIfForm({form, field});
          form.setFieldValue(`${field}.checkProperty`, checkProperty);
          form.setFieldValue(`${field}.conditionProperty`, conditionProperty);
          form.setFieldValue(`${field}.selectedProperty`, dataType);
        },
        setFieldValue: form.setFieldValue,
        style: {
          width: '100%',
        },
      }}
      style={{width: '100%'}}
    />
  );
};

const GetDataAndStopIfSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  field,
  handleSubmitValidateWorkflow,
}) => {
  const triggerIdentifier = _.get(form.values, `workflowForm.triggerIdentifier`);
  const isJobTypeRequiredWorkflow =
    WorkflowTriggerIdentifiers.isJobTypeRequiredWorkflow(triggerIdentifier);

  const {label, description} =
    WorkflowStepActionKind.ATTRIBUTES[WorkflowStepActionKind.GET_DATA_AND_STOP_IF];

  const getDataAndStopIfActionForm = _.get(form.values, field);

  const isDoneDisabled = WorkflowGetDataStopIfHelpers.isGetDataAndStopIfFormCompleted({
    form,
    field,
  });

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      isDoneDisabled={!isDoneDisabled}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    >
      <React.Fragment>
        <CheckDropdownInput
          form={form}
          field={field}
          isJobTypeRequiredWorkflow={isJobTypeRequiredWorkflow}
          getDataAndStopIfActionForm={getDataAndStopIfActionForm}
        />
        <Space height={16} />
        {getGetDataStopIfForCheckPoperty({
          form,
          field,
          workflow,
          isJobTypeRequiredWorkflow,
          getDataAndStopIfActionForm,
          workflowStepsField,
          workflowStepIndex,
        })}
      </React.Fragment>
    </WorkflowStepSlide>
  );
};

const getGetDataStopIfForCheckPoperty = ({
  form,
  field,
  workflow,
  isJobTypeRequiredWorkflow,
  getDataAndStopIfActionForm,
  workflowStepsField,
  workflowStepIndex,
}) => {
  const {checkProperty} = getDataAndStopIfActionForm;
  const {conditionProperty} = getDataAndStopIfActionForm;
  const {selectedProperty} = getDataAndStopIfActionForm;

  switch (checkProperty) {
    case WorkflowStepActionKind.CATEGORIES.PROJECT:
      return (
        <ProjectPropertyInput
          form={form}
          field={field}
          selectedProperty={selectedProperty}
          conditionProperty={conditionProperty}
          checkProperty={checkProperty}
        />
      );
    case WorkflowStepActionKind.CATEGORIES.TAGS:
      return (
        <TagPropertyInput
          form={form}
          field={field}
          isJobTypeRequiredWorkflow={isJobTypeRequiredWorkflow}
          workflow={workflow}
          checkProperty={checkProperty}
          conditionProperty={conditionProperty}
        />
      );
    case WorkflowStepActionKind.CATEGORIES.TASK:
      return (
        <TaskPropertyInput
          form={form}
          field={field}
          selectedProperty={selectedProperty}
          conditionProperty={conditionProperty}
          checkProperty={checkProperty}
          workflowStepsField={workflowStepsField}
          workflowStepIndex={workflowStepIndex}
        />
      );
    default:
      return null;
  }
};

const GetDataAndStopIfDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  isPreview,
}) => {
  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={'Stop If'}
      isPreview={isPreview}
    />
  );
};

const WorkflowStepGetDataAndStopIf = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    workflow,
    handleSubmitValidateWorkflow,
    isPreview,
  }) => {
    const field = `${workflowStepsField}.${workflowStepIndex}.getDataAndStopIfActionForm`;
    return [
      <GetDataAndStopIfSlide
        key={'CREATE'}
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        field={field}
        workflow={workflow}
        handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
      />,
      <GetDataAndStopIfDisplaySlide
        key={'DISPLAY'}
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        field={field}
        workflow={workflow}
        isPreview={isPreview}
      />,
    ];
  },
  fragment: gql`
    ${TagDropdownInputField.fragment}

    fragment WorkflowStepGetDataAndStopIf on Workflow {
      id
      organization {
        id
        companySettings {
          tags {
            id
            name
            emoji
            category
            isArchived
            ...TagDropdownInputField
          }
        }
      }
    }
  `,
};

export default WorkflowStepGetDataAndStopIf;
