// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';

const useToggleSettingsIsCostAndCompensationEnabledMutation = ({
  settingsForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      settingsForm: SettingsForm.toForm(settingsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useToggleSettingsIsCostAndCompensationEnabledMutation.mutation,
    variables: {
      settingsForm: SettingsForm.toMutation(form.values.settingsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useToggleSettingsIsCostAndCompensationEnabledMutation.mutation = gql`
  mutation useToggleSettingsIsCostAndCompensationEnabledMutation($settingsForm: SettingsForm!) {
    response: toggleSettingsIsCostAndCompensationEnabled(settingsForm: $settingsForm) {
      ${gql.errors}
      settings {
        id
        isCostAndCompensationEnabled
      }
    }
  }
`;

export default useToggleSettingsIsCostAndCompensationEnabledMutation;
