// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled, Tooltip} from '@supermove/components';
import {useState, useEffect, useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const SIDE_SPACE_AMOUNT = 24;

const TreeNavigationContainer = Styled.View`
  width: ${({width}: any) => width}px;
`;

const NodeButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  padding-right: ${SIDE_SPACE_AMOUNT}px;
  background-color: ${({color}: any) => color};
`;

const NodeButtonText = Styled.Text`
  ${Typography.Micro}
  color: ${({color}: any) => color};
`;

const SelectedIndicator = Styled.View`
  position: absolute;
  left: 0px;
  height: 100%;
  width: 2px;
  background-color: ${colors.blue.interactive};
`;

const HoverIndicator = Styled.View`
  position: absolute;
  left: 0px;
  height: 100%;
  width: 2px;
  background-color: ${colors.blue.hover};
`;

const ExpandButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
`;

const TooltipContentContainer = Styled.View`
`;

const getNodeButtonBackgroundColor = ({isSelected, isHovered, isDisabled}: any) => {
  if (isDisabled) {
    return colors.white;
  }
  if (isSelected || isHovered) {
    return colors.blue.accent;
  }
  return colors.white;
};

const getNodeButtonTextColor = ({isSelected, isHovered, isDisabled}: any) => {
  if (isSelected) {
    return colors.blue.interactive;
  }
  if (isDisabled) {
    return colors.gray.tertiary;
  }
  if (isHovered) {
    return colors.blue.hover;
  }
  return colors.gray.secondary;
};

const getNodeButtonIconColor = ({isSelected, isHovered, isDisabled}: any) => {
  if (isDisabled) {
    return colors.gray.tertiary;
  }
  if (isHovered && !isSelected) {
    return colors.blue.hover;
  }
  return colors.blue.interactive;
};

const handlePressNode = ({node, parentNodes, handleSetValues}: any) => {
  if (!node.items) {
    const values = {};
    [...parentNodes, node].forEach((branchNode) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      values[branchNode.valueKey] = branchNode.value;
    });
    return handleSetValues(values);
  }

  // Behave as if the first child node was pressed
  handlePressNode({node: node.items[0], parentNodes: [...parentNodes, node], handleSetValues});
};

const IndentSpace = ({count}: any) => {
  return <Space width={count * SIDE_SPACE_AMOUNT} />;
};

const StatusBar = ({showSelected, showHovered}: any) => {
  if (showSelected) {
    return <SelectedIndicator />;
  }
  if (showHovered) {
    return <HoverIndicator />;
  }
  return null;
};

const NodeWrapper = ({node, children, tooltipPlacement}: any) => {
  if (node.tooltip) {
    return (
      <Tooltip
        overlay={<NodeButtonText color={colors.white}>{node.tooltip}</NodeButtonText>}
        mouseEnterDelay={0.0}
        mouseLeaveDelay={0.0}
        placement={tooltipPlacement}
        overlayStyle={{opacity: 100}}
      >
        <TooltipContentContainer>{children}</TooltipContentContainer>
      </Tooltip>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const ChildNodes = ({node, parentNodes, handleSetValues, values, spacing}: any) => {
  return (
    <React.Fragment>
      {node.items.map((item: any, index: any) => {
        return (
          <React.Fragment key={index}>
            {spacing && <Space height={spacing} />}
            <Node
              node={item}
              parentNodes={[...parentNodes, node]}
              handleSetValues={handleSetValues}
              values={values}
              spacing={spacing}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const ParentNode = ({
  node,
  parentNodes,
  handleSetValues,
  values,
  spacing,
  isCentered,
  isDisabled,
}: any) => {
  const {ref, isHovered} = useHover();
  const disabled = isDisabled || node.isDisabled;
  const isSelected = values[node.valueKey] === node.value;
  const textColor = getNodeButtonTextColor({isHovered, isDisabled: disabled});
  const [isExpanded, setIsExpanded] = useState(!disabled);

  useEffect(() => {
    if (isSelected) {
      setIsExpanded(true);
    }
  }, [isSelected, setIsExpanded]);

  return (
    <React.Fragment>
      <NodeButton
        ref={ref}
        onPress={() => {
          handlePressNode({node, parentNodes, handleSetValues});
          setIsExpanded(true);
        }}
        color={getNodeButtonBackgroundColor({isHovered, isDisabled: disabled})}
        style={isCentered && {paddingRight: 0, justifyContent: 'center'}}
        disabled={disabled}
      >
        <Space width={18} />
        {!isCentered && <IndentSpace count={parentNodes.length} />}
        <ExpandButton onPress={() => setIsExpanded(!isExpanded)} disabled={disabled}>
          <Icon
            source={isExpanded ? Icon.ChevronDown : Icon.ChevronRight}
            size={10}
            color={textColor}
          />
        </ExpandButton>
        <Space width={2} />
        <NodeButtonText color={textColor}>{node.label}</NodeButtonText>
        {!disabled && (
          <StatusBar showSelected={isSelected && !isExpanded} showHovered={isHovered} />
        )}
      </NodeButton>
      {isExpanded && (
        <ChildNodes
          node={node}
          parentNodes={parentNodes}
          handleSetValues={handleSetValues}
          values={values}
          spacing={spacing}
        />
      )}
    </React.Fragment>
  );
};

const LeafNode = ({node, parentNodes, handleSetValues, values, isCentered, isDisabled}: any) => {
  const {ref, isHovered} = useHover();
  const disabled = isDisabled || node.isDisabled;
  const isSelected = values[node.valueKey] === node.value;

  return (
    <NodeButton
      ref={ref}
      onPress={() => handlePressNode({node, parentNodes, handleSetValues})}
      color={getNodeButtonBackgroundColor({isSelected, isHovered, isDisabled: disabled})}
      disabled={disabled}
      style={isCentered && {paddingRight: 0, justifyContent: 'center'}}
    >
      {!isCentered && <IndentSpace count={parentNodes.length + 1} />}
      {node.icon && (
        <Icon
          source={node.icon}
          color={getNodeButtonIconColor({isSelected, isHovered, isDisabled: disabled})}
          size={16}
        />
      )}
      {node.label && (
        <NodeButtonText
          color={getNodeButtonTextColor({isSelected, isHovered, isDisabled: disabled})}
        >
          {node.label}
        </NodeButtonText>
      )}
      {!disabled && <StatusBar showSelected={isSelected} showHovered={isHovered} />}
    </NodeButton>
  );
};

const Node = ({
  node,
  parentNodes = [],
  handleSetValues,
  values,
  spacing,
  isCentered,
  isDisabled,
  tooltipPlacement,
}: any) => {
  return (
    <NodeWrapper node={node} tooltipPlacement={tooltipPlacement}>
      {node.items ? (
        <ParentNode
          node={node}
          parentNodes={parentNodes}
          handleSetValues={handleSetValues}
          values={values}
          spacing={spacing}
          isCentered={isCentered}
          isDisabled={isDisabled}
        />
      ) : (
        <LeafNode
          node={node}
          parentNodes={parentNodes}
          handleSetValues={handleSetValues}
          values={values}
          isCentered={isCentered}
          isDisabled={isDisabled}
        />
      )}
    </NodeWrapper>
  );
};

const TreeNavigation = ({
  navigationItems,
  width,
  values,
  handleSetValues,
  spacing,
  isCentered,
  isDisabled,
  tooltipPlacement,
}: any) => {
  return (
    <TreeNavigationContainer width={width}>
      {navigationItems.map((item: any, index: any) => {
        return (
          <React.Fragment key={index}>
            {spacing && index > 0 && <Space height={spacing} />}
            <Node
              node={item}
              values={values}
              handleSetValues={handleSetValues}
              spacing={spacing}
              isCentered={isCentered}
              isDisabled={isDisabled}
              tooltipPlacement={tooltipPlacement}
            />
          </React.Fragment>
        );
      })}
    </TreeNavigationContainer>
  );
};

export default TreeNavigation;
