// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ImportOrganizationProjectsForm from '@shared/modules/File/forms/ImportOrganizationProjectsForm';

// App

const useImportOrganizationProjectsMutation = ({
  importOrganizationProjectsForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      importOrganizationProjectsForm: ImportOrganizationProjectsForm.toForm(
        importOrganizationProjectsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useImportOrganizationProjectsMutation.mutation,
    variables: {
      importOrganizationProjectsForm: ImportOrganizationProjectsForm.toMutation(
        form.values.importOrganizationProjectsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useImportOrganizationProjectsMutation.mutation = gql`
  mutation useImportOrganizationProjectsMutation($importOrganizationProjectsForm: ImportOrganizationProjectsForm!) {
    response: importOrganizationProjects(importOrganizationProjectsForm: $importOrganizationProjectsForm) {
      ${gql.errors}
    }
  }
`;

export default useImportOrganizationProjectsMutation;
