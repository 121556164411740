// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useResponsive, useState} from '@supermove/hooks';

// App
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';
import useUpdateValuesMutation from '@shared/modules/Billing/hooks/useUpdateValuesMutation';
import InvoiceChargeCreditCardDrawer from 'modules/Accounting/components/InvoiceChargeCreditCardDrawer';
import ChargeCreditCardForBillModal from 'modules/Job/V2/Bill/components/ChargeCreditCardForBillModal';
import JobProjectBillingBlockHeader from 'modules/Job/V2/Move/components/JobProjectBillingBlockHeader';
import BillingProjectAddBillModal from 'modules/Project/Billing/components/BillingProjectAddBillModal';
import BillingProjectBillsListV1 from 'modules/Project/Billing/components/BillingProjectBillsListV1';
import BillingProjectPaymentsList from 'modules/Project/Billing/components/BillingProjectPaymentsList';
import BillingProjectRecordPaymentModal from 'modules/Project/Billing/components/BillingProjectRecordPaymentModal';
import EditBillingValuesModal from 'modules/Project/Billing/components/EditBillingValuesModal';
import UpdateProjectValuesModal from 'modules/Project/Billing/components/UpdateProjectValuesModal';

const Container = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const JobProjectBillingBlockContent = ({sectionIndex, job, refetch}) => {
  const responsive = useResponsive();
  const addBillModal = useModal({name: 'Add Bill Modal'});
  const updateProjectValuesModal = useModal({name: 'Update Project Values Modal'});
  const editBillingValuesModal = useModal({name: 'Edit Billing Values Modal'});
  const recordPaymentModal = useModal({name: 'Record Payment Modal'});
  const chargeCreditCardModal = useModal({name: 'Charge Credit Card Modal'});
  const [isReordering, setIsReordering] = useState(false);
  const updateValuesForm = UpdateValuesForm.edit(job.project);
  const {form, handleSubmit} = useUpdateValuesMutation({
    updateValuesForm,
    onSuccess: () => {
      editBillingValuesModal.handleClose();
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  return (
    <Container sectionIndex={sectionIndex}>
      <JobProjectBillingBlockHeader
        job={job}
        addBillModal={addBillModal}
        updateProjectValuesModal={editBillingValuesModal}
        recordPaymentModal={recordPaymentModal}
        chargeCreditCardModal={chargeCreditCardModal}
        isReordering={isReordering}
        setIsReordering={setIsReordering}
        refetch={refetch}
      />
      <Space height={responsive.mobile ? 8 : 12} />
      <BillingProjectBillsListV1
        key={job.project.currentBills.length}
        project={job.project}
        aggregateBill={job.project.activeJobsAggregateBill}
        refetch={refetch}
        isEditable
        isReordering={isReordering}
        highlightedJobId={job.id}
      />
      <Space height={20} />
      <BillingProjectPaymentsList
        aggregateBill={job.project.activeJobsAggregateBill}
        refetch={refetch}
        isEditable
      />
      <BillingProjectAddBillModal
        key={addBillModal.key}
        isOpen={addBillModal.isOpen}
        handleClose={addBillModal.handleClose}
        project={job.project}
        refetch={refetch}
      />
      <UpdateProjectValuesModal
        key={updateProjectValuesModal.key}
        isOpen={updateProjectValuesModal.isOpen}
        handleClose={updateProjectValuesModal.handleClose}
        projectUuid={job.project.uuid}
        refetch={refetch}
      />
      <EditBillingValuesModal
        key={editBillingValuesModal.key}
        isOpen={editBillingValuesModal.isOpen}
        handleClose={editBillingValuesModal.handleClose}
        projectUuid={job.project.uuid}
        updateValuesForm={form}
        handleSubmit={handleSubmit}
      />
      <BillingProjectRecordPaymentModal
        key={recordPaymentModal.key}
        isOpen={recordPaymentModal.isOpen}
        handleClose={recordPaymentModal.handleClose}
        refetch={refetch}
        bill={job.project.currentPrimaryBill}
        jobUuid={job.uuid}
      />
      {job.organization.features.isEnabledCustomPaymentMethods ? (
        <InvoiceChargeCreditCardDrawer
          key={chargeCreditCardModal.key}
          isOpen={chargeCreditCardModal.isOpen}
          handleClose={chargeCreditCardModal.handleClose}
          invoiceUuid={job.project.mainMoveInvoice.uuid}
          refetch={refetch}
        />
      ) : (
        <ChargeCreditCardForBillModal
          key={chargeCreditCardModal.key}
          isOpen={chargeCreditCardModal.isOpen}
          jobId={job.id}
          billUuid={job.project.currentPrimaryBill.uuid}
          handleClose={() => {
            chargeCreditCardModal.handleClose();
            refetch();
          }}
        />
      )}
    </Container>
  );
};

const JobProjectBillingBlockQuery = ({sectionIndex, job}) => {
  const {data, loading, refetch} = useQuery(JobProjectBillingBlock.query, {
    fetchPolicy: 'cache-and-network',
    variables: {jobUuid: job.uuid},
  });

  return (
    <Loading loading={loading}>
      {() => {
        return (
          <JobProjectBillingBlockContent
            sectionIndex={sectionIndex}
            job={data.job}
            refetch={refetch}
          />
        );
      }}
    </Loading>
  );
};

const JobProjectBillingBlock = ({sectionIndex, job}) => {
  return (
    <JobProjectBillingBlockQuery
      key={`${job.project.totalRevenue}_${job.project.valuationCoverage.updatedAt}`}
      sectionIndex={sectionIndex}
      job={job}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

JobProjectBillingBlock.listener = gql`
  fragment JobProjectBillingBlock_listener on Project {
    id
    totalRevenue
    valuationCoverage {
      id
      updatedAt
    }
  }
`;

JobProjectBillingBlock.fragment = gql`
  ${JobProjectBillingBlock.listener}
  fragment JobProjectBillingBlock on Job {
    id
    uuid
    project {
      id
      ...JobProjectBillingBlock_listener
    }
  }
`;

JobProjectBillingBlock.query = gql`
  ${JobProjectBillingBlock.listener}
  ${BillingProjectBillsListV1.fragment}
  ${BillingProjectAddBillModal.fragment}
  ${BillingProjectRecordPaymentModal.fragment}
  ${BillingProjectPaymentsList.fragment}
  ${JobProjectBillingBlockHeader.fragment}
  ${UpdateValuesForm.edit.fragment}

  query JobProjectBillingBlock($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      uuid
      organization {
        id
        payengineMerchant {
          id
        }
        features {
          isEnabledCustomPaymentMethods: isEnabled(feature: "CUSTOM_PAYMENT_METHODS")
        }
      }
      project {
        id
        uuid
        currentBills {
          id
          isPrimary
        }
        currentPrimaryBill {
          id
          uuid
          ...BillingProjectRecordPaymentModal
        }
        mainMoveInvoice {
          id
          uuid
        }
        activeJobsAggregateBill {
          ...BillingProjectBillsListV1_AggregateBill
          ...BillingProjectPaymentsList_AggregateBill
        }
        ...BillingProjectBillsListV1
        ...BillingProjectAddBillModal
        ...UpdateValuesForm_edit
        ...JobProjectBillingBlock_listener
      }
      ...JobProjectBillingBlockHeader
    }
  }
`;

export default JobProjectBillingBlock;
