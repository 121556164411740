const _new = ({projectId, jobId, workflowId}: any) => {
  return {
    workflowId,
    jobId,
    projectId,
  };
};

const toForm = ({workflowId, jobId, projectId}: any) => ({
  workflowId,
  jobId,
  projectId,
});

const toMutation = ({workflowId, jobId, projectId}: any) => ({
  workflowId,
  jobId,
  projectId,
});

const ManuallyTriggerWorkflowForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ManuallyTriggerWorkflowForm;
