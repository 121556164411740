// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import AccountingExternalCustomers from 'modules/Organization/Settings/Company/components/AccountingExternalCustomers';
import AccountingImportsTreeNavigation from 'modules/Organization/Settings/Company/components/AccountingImportsTreeNavigation';
import AccountingItems from 'modules/Organization/Settings/Company/components/AccountingItems';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const RowContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  background-color: ${colors.white};
`;

const getTreeNavigationRenderItems = ({key}) => {
  switch (key) {
    case 'items':
      return <AccountingItems />;
    case 'customers':
      return <AccountingExternalCustomers />;
    default:
      return <AccountingItems />;
  }
};

const OrganizationSettingsCompanyIntegrationsAccountingImportsPage = () => {
  const {params, navigator} = useNavigationDOM();

  return (
    <SettingsPage
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {
          name: 'Company Settings',
          onPress: () => navigator.push('/settings/company/business-info'),
        },
        {name: 'Accounting', onPress: () => navigator.push('/settings/company/accounting')},
        {name: `Integration Configuration`},
      ]}
      tabs={[
        {
          label: 'General',
          url: `/settings/company/accounting/${params.uuid}/general`,
        },
        {
          label: 'Import',
          url: `/settings/company/accounting/${params.uuid}/imports`,
        },
        {
          label: 'Logs',
          url: `/settings/company/accounting/${params.uuid}/logs`,
        },
        {
          label: 'Exports',
          url: `/settings/company/accounting/${params.uuid}/exports`,
        },
      ]}
      hasNoChildrenStyle
    >
      <RowContainer>
        <AccountingImportsTreeNavigation codatIntegrationUuid={params.uuid} />
        <Container>
          {getTreeNavigationRenderItems({
            key: params.category,
          })}
        </Container>
      </RowContainer>
    </SettingsPage>
  );
};

export default OrganizationSettingsCompanyIntegrationsAccountingImportsPage;
