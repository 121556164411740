// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (category) => ({
    categoryId: (category as any).id,
    organizationId: (category as any).organizationId,
    kind: (category as any).kind,
    name: (category as any).name,
    isShared: (category as any).isShared,
    inventoryLibraryId: (category as any).inventoryLibraryId,
    isDeleted: (category as any).isDeleted,
    // private
    isDirty: false,
  }),
  gql`
    fragment CategoryForm_edit on Category {
      id
      organizationId
      kind
      name
      isShared
      inventoryLibraryId
      isDeleted
    }
  `,
);

// only v2 upsert categories logic will have an inventoryLibraryId
const _new = ({organizationId, inventoryLibraryId = null}: any) => ({
  categoryId: undefined,
  organizationId,
  inventoryLibraryId,
  kind: '',
  name: '',
  isShared: true,
  isDeleted: false,

  // private
  isDirty: true,
});

const toForm = ({
  categoryId,
  organizationId,
  kind,
  name,
  isShared,
  inventoryLibraryId,
  isDeleted,

  // private
  isDirty,
}: any) => ({
  categoryId,
  organizationId,
  kind,
  name,
  isShared,
  inventoryLibraryId,
  isDeleted,

  // private
  isDirty,
});

const toMutation = ({
  categoryId,
  organizationId,
  kind,
  name,
  isShared,
  inventoryLibraryId,
  isDeleted,
}: any) => ({
  categoryId,
  organizationId,
  kind,
  name,
  isShared,
  inventoryLibraryId,
  isDeleted,
});

const CategoryForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default CategoryForm;
