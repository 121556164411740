// Libraries
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// Components
import ContainerTypeForm from '@shared/modules/Storage/forms/ContainerTypeForm';
import useUpdateContainerTypeMutation from '@shared/modules/Storage/hooks/useUpdateContainerTypeMutation';
import CreateContainerTypeFields from 'modules/Organization/Settings/Storage/Containers/components/CreateContainerTypeFields';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  flex-direction: column;
  width: ${(props) => (props.mobile ? '100%' : 400)}px;
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 24px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading6}
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 80px;
  border-radius: 4px;
  padding-horizontal: 16px;
  padding-vertical: 8px;
  background-color: ${(props) => props.color};
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${(props) => props.color};
`;

const ActivityIndicator = Styled.Loading`
`;

const EditContainerTypeFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <Actions>
      <Button color={colors.white} onPress={handleClose} disabled={submitting}>
        <ButtonText color={colors.gray.secondary}>Cancel</ButtonText>
      </Button>
      <Space width={8} />
      <Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        onPress={handleSubmit}
        disabled={submitting}
      >
        {submitting ? (
          <ActivityIndicator color={colors.white} size={'small'} />
        ) : (
          <ButtonText color={colors.white}>Save</ButtonText>
        )}
      </Button>
    </Actions>
  );
};

const EditContainerTypeModal = ({containerType, userId, refetch, isOpen, handleClose}) => {
  const containerTypeForm = ContainerTypeForm.edit(containerType, {userId});
  const {form, submitting, handleSubmit} = useUpdateContainerTypeMutation({
    containerTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  const responsive = useResponsive();

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ModalContainer {...responsive}>
          <TitleText>Edit Container Type</TitleText>
          <Space height={16} />
          <CreateContainerTypeFields form={form} />
          <Space height={16} />
          <EditContainerTypeFooter
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            submitting={submitting}
          />
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

export default EditContainerTypeModal;
