// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {DocumentItemInputTextAreasType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import {
  DocumentContentJsonType,
  SetDocumentContentJsonType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import InputIsRequiredCheckBox from 'modules/Organization/Settings/Document/components/InputIsRequiredCheckBox';

const TextInput = Styled.TextInput`
  ${Typography.Body3}
  border-width: 1px;
  border-color: ${colors.gray.border};
  width: 100%;
  padding-vertical: 9px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const DeleteFieldColumn = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-left: 16px;
`;

const addFieldInput = ({
  setDraftDocumentContentJson,
  documentItemField,
}: {
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentItemField: string;
}) => {
  setDraftDocumentContentJson((prevState: DocumentContentJsonType) => {
    const values = _.get(prevState, `${documentItemField}.input.value.values`);
    _.set(prevState, `${documentItemField}.input.value.values`, [...values, ['', '']]);
    return {...prevState};
  });
};

const removeFieldInput = ({
  setDraftDocumentContentJson,
  documentItemField,
  fieldIndex,
}: {
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentItemField: string;
  fieldIndex: number;
}) => {
  setDraftDocumentContentJson((prevState: DocumentContentJsonType) => {
    const values = _.get(prevState, `${documentItemField}.input.value.values`);
    values.splice(fieldIndex, 1);
    _.set(prevState, `${documentItemField}.input.value.values`, values);
    return {...prevState};
  });
};

const InputTextAreasInputEditor = ({
  documentItem,
  documentItemField,
  setDraftDocumentContentJson,
}: {
  documentItem: DocumentItemInputTextAreasType;
  documentItemField: string;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
}) => {
  const {values} = documentItem.input.value;
  return (
    <React.Fragment>
      <InputIsRequiredCheckBox
        documentItem={documentItem}
        documentItemField={documentItemField}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
      />
      <Space height={24} />
      {values.map((value, index) => (
        <React.Fragment>
          <Row>
            <React.Fragment>
              <TextInput
                autoFocus
                key={index}
                placeholder={'Text Area Title'}
                value={value[0]}
                multiline
                onChangeText={(text: string) => {
                  setDraftDocumentContentJson((prevState: DocumentContentJsonType) => {
                    _.set(prevState, `${documentItemField}.input.value.values.${index}.0`, text);
                    return {...prevState};
                  });
                }}
              />
            </React.Fragment>
            {values.length > 1 && (
              <DeleteFieldColumn>
                <TertiaryButton
                  iconLeft={Icon.Trash}
                  iconSize={12}
                  textColor={colors.gray.secondary}
                  onPress={() =>
                    removeFieldInput({
                      setDraftDocumentContentJson,
                      documentItemField,
                      fieldIndex: index,
                    })
                  }
                />
              </DeleteFieldColumn>
            )}
          </Row>
          <Space height={32} />
        </React.Fragment>
      ))}
      <TertiaryButton
        iconLeft={Icon.Plus}
        text={'Add Item'}
        onPress={() => addFieldInput({setDraftDocumentContentJson, documentItemField})}
      />
    </React.Fragment>
  );
};

export default InputTextAreasInputEditor;
