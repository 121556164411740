// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {DocumentModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Json, withFragment} from '@supermove/utils';

const getDisplayNameWithIndex = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ indexForStep, countForStep, d... Remove this comment to see the full error message
  ({indexForStep, countForStep, displayName}) => {
    return `[${indexForStep + 1} of ${countForStep}] ${displayName}`;
  },
  gql`
    fragment Document_getDisplayNameWithIndex on Document {
      id
      indexForStep(step: $step)
      countForStep(step: $step)
      displayName
    }
  `,
);

const getStatusColor = withFragment(
  (document) => {
    const signedStatuses = [
      'SIGNED',
      'PRE_MOVE_SIGNED',
      'DURING_MOVE_SIGNED',
      'AFTER_TIMESHEET_SIGNED',
      'POST_MOVE_SIGNED',
    ];

    if (signedStatuses.includes((document as any).status)) {
      return colors.green.status;
    } else {
      return colors.gray.secondary;
    }
  },
  gql`
    fragment Document_getStatusColor on Document {
      id
      status
    }
  `,
);

const getStatusText = withFragment(
  (document) => {
    switch ((document as any).status) {
      case 'NOT_SIGNED':
        return 'Not Signed';
      case 'SIGNED':
      case 'PRE_MOVE_SIGNED':
      case 'DURING_MOVE_SIGNED':
      case 'AFTER_TIMESHEET_SIGNED':
      case 'POST_MOVE_SIGNED':
        return 'Signed';
      case 'PRE_MOVE_SKIPPED':
      case 'DURING_MOVE_SKIPPED':
      case 'AFTER_TIMESHEET_SKIPPED':
      case 'POST_MOVE_SKIPPED':
        return 'Skipped';
      case 'PRE_MOVE_PREPARED':
      case 'DURING_MOVE_PREPARED':
      case 'AFTER_TIMESHEET_PREPARED':
      case 'POST_MOVE_PREPARED':
        return 'Prepared';
      case 'PRE_MOVE_NOT_PREPARED':
      case 'DURING_MOVE_NOT_PREPARED':
      case 'AFTER_TIMESHEET_NOT_PREPARED':
      case 'POST_MOVE_NOT_PREPARED':
        return 'Not Prepared';
      default:
        return '';
    }
  },
  gql`
    fragment Document_getStatusText on Document {
      id
      status
    }
  `,
);

const getIsSigned = withFragment(
  (document) => {
    return [
      'PRE_MOVE_SIGNED',
      'DURING_MOVE_SIGNED',
      'AFTER_TIMESHEET_SIGNED',
      'POST_MOVE_SIGNED',
    ].includes((document as any).status);
  },
  gql`
    fragment Document_getIsSigned on Document {
      status
    }
  `,
);

const getIsSkipped = withFragment(
  (document) => {
    return [
      'PRE_MOVE_SKIPPED',
      'DURING_MOVE_SKIPPED',
      'AFTER_TIMESHEET_SKIPPED',
      'POST_MOVE_SKIPPED',
    ].includes((document as any).status);
  },
  gql`
    fragment Document_getIsSkipped on Document {
      status
    }
  `,
);

const getStepFromStatus = withFragment(
  (document) => {
    switch ((document as any).status) {
      case 'SIGNED':
      case 'NOT_SIGNED':
        return null;
      default:
        if (_.startsWith((document as any).status, 'PRE_MOVE')) {
          return 'PRE_MOVE';
        } else if (_.startsWith((document as any).status, 'AFTER_TIMESHEET')) {
          return 'AFTER_TIMESHEET';
        } else if (_.startsWith((document as any).status, 'POST_MOVE')) {
          return 'POST_MOVE';
        } else {
          return null;
        }
    }
  },
  gql`
    fragment Document_getStepFromStatus on Document {
      status
    }
  `,
);

const getViewUrl = withFragment(
  (document) => {
    switch ((document as any).template) {
      case 'BILL':
        return `/0/${(document as any).organization.slug}/jobs/${(document as any).job.uuid}/bill`;
      case 'BILL_OF_LADING':
        return `/0/${(document as any).organization.slug}/jobs/${(document as any).job.uuid}/bill-of-lading`;
      case 'CONFIRMATION_LETTER':
        return `/0/${(document as any).organization.slug}/projects/${(document as any).project.uuid}/confirmation/document/print`;
      case 'RISKY_MOVE':
        return '';
      default:
        return null;
    }
  },
  gql`
    fragment Document_getViewUrl on Document {
      id
      project {
        id
        uuid
      }
    }
  `,
);

const getShareLink = withFragment(
  (document: DocumentModel) => {
    return `${window.location.origin}/share/documents/${document.shareUuid}`;
  },
  gql`
    fragment Document_getShareLink on Document {
      id
      shareUuid
    }
  `,
);

const getDocumentItems = withFragment(
  (document) => {
    const formattedDocumentContentJson = Json.toCamelCaseKeys(
      JSON.parse((document as any).documentContentJson),
    );
    return _.get(formattedDocumentContentJson, 'documentItems');
  },
  gql`
    fragment Document_getDocumentItems on Document {
      id
      documentContentJson
    }
  `,
);

const Document = {
  // Getters
  getDisplayNameWithIndex,
  getIsSigned,
  getIsSkipped,
  getStatusColor,
  getStatusText,
  getStepFromStatus,
  getViewUrl,
  getShareLink,
  getDocumentItems,

  // Turns 'pre-move' into 'PRE_MOVE'.
  formatStepAsEnum: (step: any) => {
    return _.toUpper(_.snakeCase(step));
  },

  // Turns 'PRE_MOVE' into 'pre-move'.
  formatStepAsParam: (step: any) => {
    return _.snakeCase(step).replace('_', '-');
  },

  getAdditionalInfo: (document: any) => {
    const raw = _.get(document, 'additionalInfo', '{}');
    return Json.toObject(raw);
  },

  getPreparedStatusColor: (document: any) => {
    return document.isStepPrepared ? colors.green.status : colors.gray.secondary;
  },

  getPreparedStatusText: (document: any) => {
    return document.isStepPrepared ? 'Ready' : 'Action Required';
  },
};

export default Document;
