const _new = (collectionIds: any) => ({
  containerId: null,
  collectionIds,
});

const toForm = ({containerId, collectionIds}: any) => ({
  containerId,
  collectionIds,
});

const toMutation = ({containerId, collectionIds}: any) => {
  return {
    containerId,
    collectionIds,
  };
};

const AssignCollectionsToContainerForm = {
  new: _new,
  toForm,
  toMutation,
};

export default AssignCollectionsToContainerForm;
