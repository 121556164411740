// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, useTextInput, useForm, useEffect} from '@supermove/hooks';
import {Formula} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import {EvaluateFormulaStringInput} from '@shared/formulas/src/library/EvaluateFormulaStringInput';
import {fixedArityOne} from '@shared/formulas/src/library/arity';
import {ensureString} from '@shared/formulas/src/library/types';
import evaluateFormulaString, {getVariable} from '@shared/formulas/src/logic/evaluateFormulaString';
import ExtractFormulaIdentifiersPlugin from '@shared/formulas/src/plugins/ExtractFormulaIdentifiersPlugin';
import ExtractVariableNamesPlugin from '@shared/formulas/src/plugins/ExtractVariableNamesPlugin';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import Line from 'modules/App/components/Line';
import FormulaEditorDocumentationSection from 'modules/Organization/Settings/Billing/Formulas/components/FormulaEditorDocumentationSection';

const Container = Styled.View`
`;

const ErrorContainer = Styled.View`
  height: 27px;
  padding-horizontal: 16px;
  padding-vertical: 8px;
`;

const DebugSectionLabel = Styled.Text`
  ${Typography.Body3}
  padding-horizontal: 8px;
`;

const DebugSectionText = Styled.Text`
  ${Typography.Body3}
  background: ${colors.gray.background};
  padding-horizontal: 8px;
`;

const handleInsertTextAtIndex = ({form, field, formulaString, text, startIndex, endIndex}) => {
  const firstFormulaHalf = formulaString.slice(0, startIndex);
  const secondFormulaHalf = formulaString.slice(endIndex);
  const newFormulaString = `${firstFormulaHalf}${text}${secondFormulaHalf}`;
  form.setFieldValue(`${field}.formulaString`, newFormulaString);
};

const FormulaParserSection = ({formulaAST}) => {
  return (
    <Container>
      <DebugSectionLabel>Parsed Formula AST</DebugSectionLabel>
      <DebugSectionText>{JSON.stringify(formulaAST)}</DebugSectionText>
    </Container>
  );
};

const FormulaEvaluatorSection = ({formulaResult, variablesUsed}) => {
  return (
    <Container>
      <DebugSectionLabel>Formula Result</DebugSectionLabel>
      <DebugSectionText>{JSON.stringify(formulaResult)}</DebugSectionText>
      {variablesUsed.length ? (
        <React.Fragment>
          <DebugSectionLabel>Variables Used</DebugSectionLabel>
          {variablesUsed.map((variable) => {
            return <DebugSectionText key={variable}>{variable}</DebugSectionText>;
          })}
        </React.Fragment>
      ) : null}
    </Container>
  );
};

const getCustomFunctionImplementations = (variables, formulas) => {
  const customFunctionImplementations = {};
  if (variables.length > 0) {
    customFunctionImplementations.var = {
      checkArity: fixedArityOne('var'),
      checkTypes: ensureString('var'),
      call: (varName) => {
        const variable = getVariable(varName, variables);
        if (
          variable &&
          [VariableFormat.DROPDOWN_STRING, VariableFormat.STRING].includes(variable.format)
        ) {
          return `{${varName}}`;
        }
        return 0;
      },
    };
    customFunctionImplementations.value = {
      checkArity: fixedArityOne('value'),
      checkTypes: ensureString('value'),
      call: (varName) => {
        const variable = getVariable(varName, variables);
        if (
          variable &&
          [VariableFormat.DROPDOWN_STRING, VariableFormat.STRING].includes(variable.format)
        ) {
          return `{${varName}}`;
        }
        return 0;
      },
    };
    customFunctionImplementations.formula = {
      checkArity: fixedArityOne('formula'),
      checkTypes: ensureString('formula'),
      call: (formulaIdentifier) => {
        const formula = formulas.find((formula) => formula.identifier === formulaIdentifier);
        // TODO: recursive formula eval
        if (formula) {
          const {formulaResult} = evaluateFormulaString({
            formula: Formula.getFormulaString(formula),
            variables,
            plugins: [],
            customFunctionImplementations: {
              var: customFunctionImplementations.var,
              value: customFunctionImplementations.value,
              formula: {
                checkArity: fixedArityOne('formula'),
                checkTypes: ensureString('formula'),
                // we can't really do recursive nested formula references on the FE so we'll return zero one formula down
                call: (formulaIdentifier) => {
                  return 0;
                },
              },
            },
          });

          return formulaResult;
        }

        return 0;
      },
    };

    return customFunctionImplementations;
  }
};

const FormulaEditorDebugSection = ({formulaString, variables, formulas}) => {
  const customFunctionImplementations = getCustomFunctionImplementations(variables, formulas);
  const {formulaAST, formulaResult, plugins} = evaluateFormulaString(
    new EvaluateFormulaStringInput(
      formulaString,
      variables,
      [new ExtractVariableNamesPlugin(formulas)],
      customFunctionImplementations,
    ),
  );
  const variablesUsed = plugins[0].getResult();

  return (
    <Container style={{height: 200}}>
      <ScrollView>
        <FormulaParserSection formulaAST={formulaAST} />
        <FormulaEvaluatorSection formulaResult={formulaResult} variablesUsed={variablesUsed} />
      </ScrollView>
    </Container>
  );
};

const getVariableValues = (variablesUsed, variables, formVariables) => {
  return variablesUsed.reduce((acc, variableIdentifier) => {
    const variable = variables.find((variable) => variable.identifier === variableIdentifier) || {};
    const formVariable = formVariables[variableIdentifier];
    acc[variableIdentifier] = {
      ...variable,
      value: formVariable?.value ? formVariable.value : '',
    };
    return acc;
  }, {});
};

const FormulaEditor = ({form, field, variables, isDebugMode, organization}) => {
  const {formulas} = organization;
  const formulaString = _.get(form.values, `${field}.formulaString`);
  const formulaStringInput = useTextInput();
  const [selection, setSelection] = useState({start: 0, end: 0});
  const [variablesUsed, setVariablesUsed] = useState([]);
  const customFunctionImplementations = getCustomFunctionImplementations(variables, formulas);
  const {formulaError, plugins} = evaluateFormulaString(
    new EvaluateFormulaStringInput(
      formulaString,
      variables,
      [
        new ExtractFormulaIdentifiersPlugin(formulas, _.get(form.values, `${field}.identifier`)),
        new ExtractVariableNamesPlugin(formulas),
      ],
      customFunctionImplementations,
    ),
  );
  const latestVarsUsed = plugins[1].getResult();
  useEffect(() => {
    if (!formulaError && !_.isEqual(latestVarsUsed, variablesUsed)) {
      setVariablesUsed(latestVarsUsed);
    }
  }, [latestVarsUsed]); // eslint-disable-line react-hooks/exhaustive-deps
  const formulaServerError = _.get(form.errors, `${field}.astJson`);
  const hasError = (!!formulaString && !!formulaError) || !!formulaServerError;
  const scratchpadForm = useForm({
    initialValues: {formulaResult: 0, variables: getVariableValues(variablesUsed, variables, {})},
  });
  return (
    <Container style={{flex: 1}}>
      <FieldInput
        {...form}
        name={`${field}.formulaString`}
        input={{
          required: !formulaString,
          innerRef: formulaStringInput.ref,
          multiline: true,
          placeholder: 'Enter formula',
          style: {height: 140, padding: 16, paddingTop: 8, paddingBottom: 8, borderWidth: 0},
          onSelectionChange: (e) => setSelection(e.nativeEvent.selection),
          selection,
        }}
      />
      <ErrorContainer
        style={{
          backgroundColor: formulaString ? 'transparent' : colors.alpha(colors.yellow.hover, 0.1),
        }}
      >
        {hasError && (
          <FieldInput.CaptionText hasErrors>
            {formulaServerError || formulaError}
          </FieldInput.CaptionText>
        )}
      </ErrorContainer>
      <Line />
      <FormulaEditorDocumentationSection
        handleInsertText={(text) => {
          handleInsertTextAtIndex({
            form,
            field,
            formulaString,
            text,
            startIndex: selection.start,
            endIndex: selection.end,
          });
          const updatedCursorPosition = selection.start + text.length;
          formulaStringInput.handleFocus();
          // setTimeout ensures that the positioning of the cursor is the last event.
          setTimeout(
            () => setSelection({start: updatedCursorPosition, end: updatedCursorPosition}),
            0,
          );
        }}
        variablesUsed={variablesUsed}
        scratchpadForm={scratchpadForm}
        variables={variables}
        selection={selection}
        formulaStringInput={formulaStringInput}
        formulaString={formulaString}
        organization={organization}
      />
      {isDebugMode && (
        <React.Fragment>
          <Line />
          <FormulaEditorDebugSection
            formulaString={formulaString}
            variables={variables}
            formulas={formulas}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FormulaEditor.fragment = gql`
  ${FormulaEditorDocumentationSection.fragment}
  fragment FormulaEditor on Variable {
    id
    format
    ...FormulaEditorDocumentationSection_Variable
  }
  fragment FormulaEditor_Organization on Organization {
    id
    ...FormulaEditorDocumentationSection_Organization
    formulas {
      id
      identifier
      astJson
    }
  }
`;

export default FormulaEditor;
