// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

const TwoChoiceModal = ({
  emojiName,
  title,
  subtitle,
  textButtonLeft,
  textButtonRight,
  onPressLeft,
  onPressRight,
  isOpen,
  onClose,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
}) => {
  const {navigator} = useNavigationDOM();

  return (
    <Modal.Content
      isOpen={isOpen}
      onClose={onClose}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <Container pointerEvents={'box-none'}>
        <Wrapper width={350}>
          <Circle>
            <Emoji component={EmojiText} name={emojiName} />
          </Circle>
          <Content>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Actions>
              <Button onPress={() => onPressLeft({navigator, onClose})}>
                <Text>{textButtonLeft}</Text>
              </Button>
              <ButtonSpace />
              <Button onPress={() => onPressRight({navigator})}>
                <Text>{textButtonRight}</Text>
              </Button>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
TwoChoiceModal.propTypes = {
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  emojiName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  textButtonLeft: PropTypes.string.isRequired,
  textButtonRight: PropTypes.string.isRequired,
  onPressLeft: PropTypes.func.isRequired,
  onPressRight: PropTypes.func.isRequired,
};

TwoChoiceModal.defaultProps = {
  shouldCloseOnEsc: false,
  shouldCloseOnOverlayClick: false,
};

export default TwoChoiceModal;
