// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {BillItemType} from '@supermove/models';

// App
import CannotDeleteModal from 'modules/Organization/Settings/Billing/components/CannotDeleteModal';

const CannotDeleteFormulaModal = ({formula, cannotDeleteModal}) => {
  return (
    <CannotDeleteModal
      titleText='Unable to delete'
      description='The formula is connected to active bill items or bill rules or nested formulas and cannot be deleted.'
      listItems={[
        ...formula.billItemTypes.filter((billItemType) => !billItemType.isParent),
        ...formula.billRuleTypes,
        ...formula.consumingFormulas,
      ]}
      cannotDeleteModal={cannotDeleteModal}
      getListItemText={(billItemTypeOrBillRuleType) =>
        billItemTypeOrBillRuleType.moverPosition
          ? BillItemType.getDisplayName(billItemTypeOrBillRuleType)
          : billItemTypeOrBillRuleType.name
      }
      getListItemUrl={(billItemType) =>
        `/settings/billing/billing-libraries/${billItemType.billingLibrary.uuid}/items/fees`
      }
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CannotDeleteFormulaModal.fragment = gql`
  ${BillItemType.getDisplayName.fragment}
  fragment CannotDeleteFormulaModal on Formula {
    id
    consumingFormulas {
      id
      name
    }
    billItemTypes {
      id
      name
      isParent
      billingLibrary {
        id
        uuid
      }
      ...BillItemType_getDisplayName
    }
    billRuleTypes {
      id
      name
      billingLibrary {
        id
        uuid
      }
    }
  }
`;

export default CannotDeleteFormulaModal;
