// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const WorkflowDrawerFields = ({form}) => {
  const nameField = 'workflowForm.name';
  const descriptionField = 'workflowForm.description';

  return (
    <React.Fragment>
      <FieldInput.Memoized
        {...form}
        index={0}
        label={'Name'}
        name={nameField}
        isRequired
        input={{
          placeholder: 'Name of automation',
        }}
      />
      <Space height={12} />
      <FieldInput.Memoized
        {...form}
        index={1}
        label={'Description'}
        name={descriptionField}
        input={{
          multiline: true,
          placeholder: 'Description of automation',
          style: {
            minHeight: 90,
            paddingTop: 8,
          },
        }}
      />
    </React.Fragment>
  );
};

export default WorkflowDrawerFields;
