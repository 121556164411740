// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

// Shared
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import ShipmentForm from '@shared/modules/Dispatch/forms/ShipmentForm';
import LocationForm from '@shared/modules/Location/forms/LocationForm';

const _getShipmentIdsFormShipmentForms = (shipmentForms: any) => {
  // @ts-expect-error TS(2769): No overload matches this call.
  const shipmentIds = _.reduce(
    shipmentForms,
    (result, shipmentForm) => [...result, shipmentForm.shipmentId],
    [],
  );
  return shipmentIds;
};

const _getTripLocationsAndDatesFromShipments = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ shipments }: { shipments: any... Remove this comment to see the full error message
  ({shipments}) => {
    // First, we need to get a master list of all end dates mapped to corresponding location
    // Pack and load should map to start location, and delivery to end location
    const allEndDatesToProject: any = [];

    _.forEach(shipments, (shipment) => {
      const {
        packJobEndDate,
        packJobScheduledDate,
        loadJobEndDate,
        loadJobScheduledDate,
        deliveryJobEndDate,
        deliveryJobScheduledDate,
      } = shipment.project;

      if (packJobScheduledDate) {
        allEndDatesToProject.push({
          date: packJobScheduledDate,
          location: shipment.project.startLocation,
        });
      } else if (packJobEndDate) {
        allEndDatesToProject.push({
          date: packJobEndDate,
          location: shipment.project.startLocation,
        });
      }

      if (loadJobScheduledDate) {
        allEndDatesToProject.push({
          date: loadJobScheduledDate,
          location: shipment.project.startLocation,
        });
      } else if (loadJobEndDate) {
        allEndDatesToProject.push({
          date: loadJobEndDate,
          location: shipment.project.startLocation,
        });
      }

      if (deliveryJobScheduledDate) {
        allEndDatesToProject.push({
          date: deliveryJobScheduledDate,
          location: shipment.project.endLocation,
        });
      } else if (deliveryJobEndDate) {
        allEndDatesToProject.push({
          date: deliveryJobEndDate,
          location: shipment.project.endLocation,
        });
      }
    });

    // For the origin date and location, we find the earliest date
    const origin = _.minBy(
      allEndDatesToProject,
      (endDateToProject) => (endDateToProject as any).date,
    );

    // For the destination date and location, we find the latest date
    const destination = _.maxBy(
      allEndDatesToProject,
      (endDateToProject) => (endDateToProject as any).date,
    );

    return {
      startDate: origin && (origin as any).date,
      startLocation: origin && (origin as any).location,
      endDate: destination && (destination as any).date,
      endLocation: destination && (destination as any).location,
    };
  },
  gql`
    ${LocationForm.edit.fragment}
    fragment TripForm_getTripLocationsAndDatesFromShipments on Shipment {
      id
      project {
        id
        packJobEndDate
        packJobScheduledDate
        deliveryJobEndDate
        deliveryJobScheduledDate
        loadJobEndDate
        loadJobScheduledDate
        startLocation {
          id
          ...LocationForm_edit
        }
        endLocation {
          id
          ...LocationForm_edit
        }
      }
    }
  `,
);

const newWithShipments = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ organizationId, shipments }: ... Remove this comment to see the full error message
  ({organizationId, shipments}) => {
    // @ts-expect-error TS(2339): Property 'startDate' does not exist on type 'unkno... Remove this comment to see the full error message
    const {startDate, startLocation, endDate, endLocation} = _getTripLocationsAndDatesFromShipments(
      {shipments},
    );

    return {
      tripId: null,
      organizationId,
      name: '',
      status: TripStatus.PENDING,
      driverId: null,
      officeNotes: '',
      driverNotes: '',
      truckIds: [],

      // Private
      shipmentForms: shipments ? shipments.map((shipment: any) => ShipmentForm.edit(shipment)) : [],
      tripUuid: null,
      startDate,
      startLocationForm: startLocation ? LocationForm.edit(startLocation) : LocationForm.new(),
      endDate,
      endLocationForm: endLocation ? LocationForm.edit(endLocation) : LocationForm.new(),
    };
  },
  gql`
    ${ShipmentForm.edit.fragment}
    ${_getTripLocationsAndDatesFromShipments.fragment}
    fragment TripForm_newWithShipments on Shipment {
      id
      ...ShipmentForm_edit
      ...TripForm_getTripLocationsAndDatesFromShipments
    }
  `,
);

const edit = withFragment(
  (trip) => ({
    tripId: (trip as any).id,
    organizationId: (trip as any).organizationId,
    name: (trip as any).name,
    status: (trip as any).status,
    driverId: (trip as any).driverId,
    officeNotes: (trip as any).officeNotes,
    driverNotes: (trip as any).driverNotes,
    truckIds: (trip as any).tripSegments[0].truckIds,
    // Private
    shipmentForms: (trip as any).shipments.map((shipment: any) => ShipmentForm.edit(shipment)),
    tripUuid: (trip as any).uuid,
    startDate: (trip as any).startDate,
    startLocationForm: (trip as any).startLocation
      ? LocationForm.edit((trip as any).startLocation)
      : LocationForm.new(),
    endDate: (trip as any).endDate,
    endLocationForm: (trip as any).endLocation
      ? LocationForm.edit((trip as any).endLocation)
      : LocationForm.new(),
  }),
  gql`
    ${LocationForm.edit.fragment}
    ${ShipmentForm.edit.fragment}
    fragment TripForm_edit on Trip {
      id
      uuid
      organizationId
      driverId
      name
      status
      startDate
      endDate
      officeNotes
      driverNotes
      startLocation {
        id
        ...LocationForm_edit
      }
      endLocation {
        id
        ...LocationForm_edit
      }
      shipments {
        id
        ...ShipmentForm_edit
      }
      tripSegments {
        id
        truckIds
      }
    }
  `,
);

const duplicate = withFragment(
  (trip) => ({
    tripId: null,
    organizationId: (trip as any).organizationId,
    name: `Copy of ${(trip as any).name}`,
    status: TripStatus.PENDING,
    startLocationForm: (trip as any).startLocation
      ? LocationForm.edit((trip as any).startLocation)
      : LocationForm.new(),
    startDate: (trip as any).startDate,
    endLocationForm: (trip as any).endLocation
      ? LocationForm.edit((trip as any).endLocation)
      : LocationForm.new(),
    endDate: (trip as any).endDate,
    driverId: (trip as any).driverId,
    officeNotes: (trip as any).officeNotes,
    driverNotes: (trip as any).driverNotes,
    truckIds: (trip as any).tripSegments[0].truckIds,
    // Private
    shipmentForms: [],
    tripUuid: null,
  }),
  gql`
    ${LocationForm.edit.fragment}
    fragment TripForm_duplicate on Trip {
      id
      organizationId
      driverId
      name
      status
      startDate
      endDate
      officeNotes
      driverNotes
      startLocation {
        id
        ...LocationForm_edit
      }
      endLocation {
        id
        ...LocationForm_edit
      }
      tripSegments {
        id
        truckIds
      }
    }
  `,
);

const toForm = ({
  tripId,
  organizationId,
  name,
  status,
  startLocationForm,
  startDate,
  endLocationForm,
  endDate,
  driverId,
  officeNotes,
  driverNotes,
  shipmentForms,
  tripUuid,
  truckIds,
}: any) => ({
  tripId,
  organizationId,
  name,
  status,
  startLocationForm: LocationForm.toForm(startLocationForm),
  startDate: Datetime.toFormDate(startDate),
  endLocationForm: LocationForm.toForm(endLocationForm),
  endDate: Datetime.toFormDate(endDate),
  driverId: _.toString(driverId),
  officeNotes,
  driverNotes,
  truckIds: truckIds.map((truckId: any) => _.toString(truckId)),

  // Private
  shipmentForms: shipmentForms.map((shipmentForm: any) => ShipmentForm.toForm(shipmentForm)),
  tripUuid,
});

const toMutation = ({
  tripId,
  organizationId,
  name,
  status,
  startLocationForm,
  startDate,
  endLocationForm,
  endDate,
  driverId,
  officeNotes,
  driverNotes,
  shipmentForms,
  truckIds,
}: any) => ({
  tripId,
  organizationId,
  name,
  status,
  startLocationForm: startLocationForm.address ? LocationForm.toMutation(startLocationForm) : null,
  startDate: startDate ? Datetime.toMutationDate(startDate) : null,
  endLocationForm: endLocationForm.address ? LocationForm.toMutation(endLocationForm) : null,
  endDate: endDate ? Datetime.toMutationDate(endDate) : null,
  driverId: driverId ? _.toNumber(driverId) : null,
  officeNotes,
  driverNotes,
  shipmentIds: _getShipmentIdsFormShipmentForms(shipmentForms),
  truckIds: truckIds.map((truckId: any) => _.toNumber(truckId)),
});

const TripForm = {
  newWithShipments,
  edit,
  duplicate,
  toForm,
  toMutation,
};

export default TripForm;
