// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import ProjectDateKind from '@shared/modules/Project/enums/ProjectDateKind';

// This projectDateSettings is an abstraction that makes it easy to plug these
// fields into our dropdown component, see how we use this in JobTypeModalFields.js
const makeProjectDateSettings = (jobTypeSettings: any) => {
  // @ts-expect-error TS(2769): No overload matches this call.
  return ProjectDateKind.VALUES.reduce((projectDateSettings, projectDateKind) => {
    const jobTypeSettingsKey =
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ProjectDateKind.PROJECT_DATE_KIND_TO_JOB_TYPE_SETTINGS_MAP[projectDateKind];
    if (jobTypeSettings[jobTypeSettingsKey]) {
      return [...projectDateSettings, projectDateKind];
    }

    return projectDateSettings;
  }, []);
};

const _new = ({organizationId, projectTypeId}: any) => ({
  organizationId,
  projectTypeId,
  jobTypeId: null,
  name: '',
  kind: Job.KIND.MOVE,
  billTypeIds: [],
  setsPackDates: false,
  setsLoadDates: false,
  setsDeliveryDates: false,
});

const edit = withFragment(
  (jobType) => ({
    jobTypeId: (jobType as any).id,
    name: (jobType as any).name,
    kind: (jobType as any).kind,
    index: (jobType as any).index,
    billTypeIds: (jobType as any).billTypes.map((billType: any) => {
      return _.parseInt(billType.id);
    }),
    organizationId: (jobType as any).organization.id,
    projectTypeId: (jobType as any).projectType.id,
    setsPackDates: (jobType as any).setsPackDates,
    setsLoadDates: (jobType as any).setsLoadDates,
    setsDeliveryDates: (jobType as any).setsDeliveryDates,
    // Private
    projectDateSettings: makeProjectDateSettings(jobType),
  }),
  gql`
    fragment JobTypeForm_edit on JobType {
      id
      name
      kind
      index
      billTypes {
        id
      }
      setsPackDates
      setsLoadDates
      setsDeliveryDates
      organization {
        id
      }
      projectType {
        id
      }
    }
  `,
);

const toForm = ({
  jobTypeId,
  index,
  name,
  billTypeIds,
  organizationId,
  projectTypeId,
  kind,
  setsPackDates,
  setsLoadDates,
  setsDeliveryDates,

  // Private
  projectDateSettings,
}: any) => ({
  jobTypeId,
  index,
  name,
  billTypeIds,
  organizationId,
  projectTypeId,
  kind,
  setsPackDates,
  setsLoadDates,
  setsDeliveryDates,

  // Private
  projectDateSettings,
});

const toMutation = ({
  jobTypeId,
  index,
  name,
  billTypeIds,
  organizationId,
  projectTypeId,
  kind,
  setsPackDates,
  setsLoadDates,
  setsDeliveryDates,
}: any) => {
  return {
    jobTypeId,
    index,
    name,
    billTypeIds,
    organizationId,
    projectTypeId,
    kind,
    setsPackDates,
    setsLoadDates,
    setsDeliveryDates,
  };
};

const JobTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default JobTypeForm;
