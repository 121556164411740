/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

const JobNameContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 4px;
  padding-vertical: 2px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.tertiary};
  background-color: ${colors.white};
`;

const JobNameText = Styled.Text`
  ${Typography.Label4}
  color: ${colors.gray.primary};
`;

const JobNameBadge = ({job, style}) => {
  return (
    <JobNameContainer style={style}>
      <JobNameText numberOfLines={1}>{job.fullName}</JobNameText>
    </JobNameContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobNameBadge.fragment = gql`
  fragment JobNameBadge on Job {
    id
    fullName
  }
`;

export default JobNameBadge;
