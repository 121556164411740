// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Emoji, Modal, Mutation, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

const UnfinalizeReportMoveModal = ({reportMove, onSuccess, refetch, trigger}) => (
  <Modal onClose={refetch} trigger={trigger}>
    {({handleClose}) => (
      <Container pointerEvents={'box-none'}>
        <Wrapper width={350}>
          <Circle>
            <Emoji component={EmojiText} name={'unlock'} />
          </Circle>
          <Content>
            <Title>Unlock Report</Title>
            <Subtitle>
              {`Are you sure you want to unlock this report? ` +
                `This may impact other modules in your system (metrics, payroll, etc).`}
            </Subtitle>
            <Actions>
              <Button color={colors.gray.tertiary} onPress={handleClose}>
                <Text>Cancel</Text>
              </Button>
              <ButtonSpace />
              <Mutation
                variables={{
                  reportMoveId: reportMove.id,
                }}
                mutation={UnfinalizeReportMoveModal.mutation}
                onCompleted={({response: {reportMove, errors}}) => {
                  if (reportMove) {
                    onSuccess({handleClose});
                  }
                }}
              >
                {(handleSubmit, {loading, error}) => (
                  <Button color={colors.red.warning} loading={loading} onPress={handleSubmit}>
                    <Text>Confirm</Text>
                  </Button>
                )}
              </Mutation>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    )}
  </Modal>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
UnfinalizeReportMoveModal.propTypes = {
  reportMove: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
};

UnfinalizeReportMoveModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
UnfinalizeReportMoveModal.fragment = gql`
  fragment UnfinalizeReportMoveModal on ReportMove {
    id
    uuid
  }
`;

UnfinalizeReportMoveModal.mutation = gql`
  mutation UnfinalizeReportMoveModal(
    $reportMoveId: Int!,
  ) {
    response: unfinalizeReportMove(
      reportMoveId: $reportMoveId,
    ) {
      ${gql.errors}
      reportMove {
        id
        isFinal
        isFinalizable
        job {
          id
          calendarPrimaryStatus
        }
      }
    }
  }
`;

export default UnfinalizeReportMoveModal;
