// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Checkbox, ScrollView, Styled, Popover, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover, useHover, useNavigationDOM} from '@supermove/hooks';
import {Location, Container} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import ContainerLocationKind from '@shared/modules/Storage/enums/ContainerLocationKind';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import TextBadge from 'modules/App/components/TextBadge';
import ContainerSidebarLink from 'modules/Storage/Containers/ContainerSidebarLink';
import DeleteContainerModal from 'modules/Storage/components/DeleteContainerModal';
import UpdateContainerModal from 'modules/Storage/components/UpdateContainerModal';

const ContentContainer = Styled.View`
`;

const TableContainer = Styled.View`
  min-width: 100%;
`;

const Cell = Styled.View`
  flex: 1;
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
`;

const SecondaryText = Styled.Text`
  ${Typography.Body};
  color: ${colors.gray.secondary}
`;

const Text = Styled.Text`
  ${Typography.Body}
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const IconButton = Styled.ButtonV2`
`;

const MenuOptionButton = Styled.ButtonV2`
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const getColumnDefinitions = ({
  selectedContainers,
  allowMultiselect,
  onContainerSelect,
  isWarehouseView,
  showActions,
  refetch,
  params,
}) => {
  const isAllTab = params.locationKind === 'ALL';

  return [
    // Checkbox/Radio button
    {
      isHidden: !allowMultiselect,
      width: 48,
      headerContent: () => {
        return null;
      },
      cellContent: ({item: container}) => {
        const isSelected = _.includes(selectedContainers, container.id);
        return (
          <ContentContainer style={{alignItems: 'flex-end'}}>
            <Checkbox
              size={16}
              iconSize={12}
              checked={isSelected}
              onChange={() => onContainerSelect(container.id)}
              color={colors.blue.interactive}
              borderColor={isSelected ? colors.blue.interactive : colors.gray.secondary}
            />
          </ContentContainer>
        );
      },
    },
    // Availability status (capacity)
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Status</HeaderText>;
      },
      cellContent: ({item: container}) => {
        if (_.isEmpty(container.collectionVersions)) {
          return <TextBadge isLabel text={'Available'} color={colors.green.status} />;
        }
        return <TextBadge isLabel text={'Occupied'} color={colors.orange.status} />;
      },
    },
    // Container Id
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Container ID</HeaderText>;
      },
      cellContent: ({item: container}) => {
        return <ContainerSidebarLink container={container} refetch={refetch} />;
      },
    },
    // Container Type
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Container Type</HeaderText>;
      },
      cellContent: ({item: container}) => {
        return <Text>{`${container.containerType.name}`}</Text>;
      },
    },
    // Location (Coordinates or Custom Address)
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>{isWarehouseView ? 'Coordinates' : 'Location'}</HeaderText>;
      },
      cellContent: ({item: container}) => {
        return container.locationKind === ContainerLocationKind.CUSTOM ? (
          <Text numberOfLines={1}>
            {container.customLocation
              ? Location.getDisplayLocation(container.customLocation)
              : '--'}
          </Text>
        ) : (
          <Text numberOfLines={1}>{Container.getDisplayCoordinates(container) || '--'}</Text>
        );
      },
    },
    // Customer
    // Warehouse/Warehouse Organization
    {
      flex: 2,
      isHidden: !isAllTab,
      headerContent: () => {
        return (
          <Cell>
            <HeaderText>{`Warehouse`}</HeaderText>
            <SecondaryText>{`Branch`}</SecondaryText>
          </Cell>
        );
      },
      cellContent: ({item: container}) => {
        return (
          <Cell>
            {container.warehouse && (
              <React.Fragment>
                <Text numberOfLines={1}>{container.warehouse?.name || '-'}</Text>
                <SecondaryText numberOfLines={1}>
                  {container.warehouse?.organization.name || '-'}
                </SecondaryText>
              </React.Fragment>
            )}
          </Cell>
        );
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>{`Customer(s)`}</HeaderText>;
      },
      cellContent: ({item: container}) => {
        const customerNames = container.clients
          .map((client) => client.primaryContact.fullName)
          .join(', ');
        return <Text numberOfLines={1}>{customerNames.length ? customerNames : '--'}</Text>;
      },
    },
    // Actions
    {
      flex: 2,
      isHidden: !showActions,
      headerContent: () => {
        return <HeaderText>Actions</HeaderText>;
      },
      cellContent: ({item: container}) => {
        return <ContainerActions container={container} refetch={refetch} />;
      },
    },
  ];
};

const ContainerActions = ({container, refetch}) => {
  const containerActionsPopover = usePopover();
  const updateContainerModal = useModal({name: 'Edit Container Modal', enableTracking: true});
  const deleteContainerModal = useModal({name: 'Delete Container Modal', enableTracking: true});

  return (
    <React.Fragment>
      <Row>
        <IconButton onPress={updateContainerModal.handleOpen}>
          <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
        </IconButton>
        <Space width={25} />
        <Popover.Content innerRef={containerActionsPopover.ref}>
          <IconButton onPress={containerActionsPopover.handleToggle}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </IconButton>
        </Popover.Content>
      </Row>
      <ContainerActionsPopover
        key={`CONTAINER_ACTIONS_POPOVER-${containerActionsPopover.isOpen}`}
        containerActionsPopover={containerActionsPopover}
        deleteContainerModal={deleteContainerModal}
      />
      <DeleteContainerModal
        isOpen={deleteContainerModal.isOpen}
        handleClose={deleteContainerModal.handleClose}
        refetch={refetch}
        container={container}
      />
      <UpdateContainerModal
        isOpen={updateContainerModal.isOpen}
        handleClose={updateContainerModal.handleClose}
        refetch={refetch}
        container={container}
        viewer={{}}
      />
    </React.Fragment>
  );
};

const ContainerActionsPopover = ({containerActionsPopover, deleteContainerModal}) => {
  const {isHovered, ref} = useHover();
  const handleOpenDeleteContainerModal = () => {
    deleteContainerModal.handleOpen();
    containerActionsPopover.handleClose();
  };

  return (
    <Popover
      placement={Popover.Positions.RightStart}
      isOpen={containerActionsPopover.isOpen}
      handleOpen={containerActionsPopover.handleOpen}
      handleClose={containerActionsPopover.handleClose}
      reference={containerActionsPopover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={160}>
        <Space height={8} />
        <MenuOptionButton onPress={handleOpenDeleteContainerModal} isHovered={isHovered} ref={ref}>
          <MenuItemText>Delete</MenuItemText>
        </MenuOptionButton>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const ContainersList = ({
  containers,
  loading,
  refetch,
  hasError,
  allowMultiselect,
  selectedContainers,
  onContainerSelect,
  showActions,
  isWarehouseView,
}) => {
  const {params} = useNavigationDOM();

  return (
    <ScrollView>
      <ContentContainer style={{minHeight: 50, flex: 1}}>
        <ScrollView horizontal contentContainerStyle={{flex: 1}}>
          <TableContainer>
            <Table
              columnDefinitions={getColumnDefinitions({
                allowMultiselect,
                selectedContainers,
                isWarehouseView,
                onContainerSelect,
                showActions,
                refetch,
                params,
              })}
              items={containers}
              loading={loading}
              emptyStateText={'There are no containers that match these filters'}
              isClickable
              onRowPress={({id}) => onContainerSelect(id)}
              hasError={hasError}
            />
          </TableContainer>
        </ScrollView>
      </ContentContainer>
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ContainersList.fragment = gql`
  ${Location.getDisplayLocation.fragment}
  ${Container.getDisplayCoordinates.fragment}
  ${UpdateContainerModal.fragment}
  ${ContainerSidebarLink.fragment}

  fragment ContainersList on Container {
    id
    name
    locationKind
    containerType {
      id
      name
    }
    warehouse {
      id
      name
      organization {
        id
        name
      }
    }
    clients {
      id
      primaryContact {
        id
        fullName
      }
    }
    collectionVersions {
      id
    }
    customLocation {
      id
      ...Location_getDisplayLocation
    }
    ...Container_getDisplayCoordinates
    ...ContainerSidebarLink
    ...UpdateContainerModal
  }
`;

export default ContainersList;
