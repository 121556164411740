// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import CartonCategoriesList from '@shared/modules/Inventory/Summary/components/CartonCategoriesList';
import InventorySection from '@shared/modules/Inventory/Summary/components/InventorySection';

type OwnProps = {
  isPreview?: boolean;
  isDisabled?: boolean;
  showNotes?: boolean;
  showWeight?: boolean;
  showVolume?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ProjectDocumentV2CartonSummary.defaultProps;

// @ts-expect-error TS(7022): 'ProjectDocumentV2CartonSummary' implicitly has ty... Remove this comment to see the full error message
const ProjectDocumentV2CartonSummary = ({
  isPreview,
  project,
  isDisabled,
  showNotes,
  showWeight,
  showVolume,
}: Props) => {
  return (
    <InventorySection icon={Icon.BoxOpen} title={'Carton summary'}>
      <CartonCategoriesList
        isDisabled={isDisabled}
        inventory={isPreview ? MOCK_INVENTORY_DATA : project.inventory}
        showNotes={showNotes}
        showWeight={showWeight}
        showVolume={showVolume}
      />
    </InventorySection>
  );
};
ProjectDocumentV2CartonSummary.defaultProps = {
  isPreview: false,
  isDisabled: false,
  showNotes: false,
  showWeight: true,
  showVolume: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectDocumentV2CartonSummary.fragment = gql`
  ${CartonCategoriesList.fragment}
  fragment ProjectDocumentV2CartonSummary on Project {
    id
    inventory {
      id
      ...CartonCategoriesList
    }
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_INVENTORY_DATA = {
  id: '1',
  cartonCategories: [
    {
      id: '1',
      category: {
        id: '1',
        name: 'Cartons, Packing, Unpacking',
      },
      collection: {
        id: '1',
        items: [
          {
            id: '1',
            itemTypeId: 1,
            leaveCount: 1,
            takeCount: 1,
            volume: 1,
            name: 'Carton, 1.5',
            notes: 'This is a note',
          },
        ],
        leaveCount: 1,
        takeCount: 1,
        totalVolume: 1,
        totalWeight: 1,
      },
    },
  ],
};

export default ProjectDocumentV2CartonSummary;
