// Supermove

const _new = ({batchId}: any) => ({
  batchId,
});

const toForm = ({batchId}: any) => {
  return {
    batchId,
  };
};

const toMutation = ({batchId}: any) => {
  return {
    batchId,
  };
};

const KillBatchForm = {
  new: _new,
  toForm,
  toMutation,
};

export default KillBatchForm;
