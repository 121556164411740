// Libararies
import React from 'react';

// App
import ProjectComments from 'modules/Project/components/ProjectComments';

const ProjectCommentsWidget = ({projectUuid, setIsClosable}) => {
  return <ProjectComments projectUuid={projectUuid} setIsParentClosable={setIsClosable} isWidget />;
};

export default ProjectCommentsWidget;
