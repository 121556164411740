// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  Checkbox,
  DropdownInput,
  Icon,
  ScrollView,
  Space,
  Styled,
  TimeInput,
} from '@supermove/components';
import {ReportMoveUserForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {User, Organization} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Collection, isSameId} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import UserRole from '@shared/modules/User/enums/UserRole';
import useUpdateReportMoveMoveUsersForm from 'modules/ReportMove/Commercial/logic/useUpdateReportMoveMoveUsersForm';

const Container = Styled.View`
  flex: 1;
`;

const Table = Styled.View`
`;

const Header = Styled.View`
  flex-direction: row;
  width: 100%;
`;

const Item = Styled.View`
  flex-direction: row;
  z-index: ${(props) => 100 - props.index};
`;

const Cell = Styled.View`
  width: 100px;
  padding-vertical: 3px;
  padding-horizontal: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Text = Styled.H7`
`;

const TextInput = Styled.TextInput.H7`
`;

const Touchable = Styled.Touchable`
  align-items: center;
  justify-content: center;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const Buttons = Styled.View`
  flex-direction: row;
`;

const Button = Styled.LoadingButton`
  align-items: center;
  height: 30px;
  padding-horizontal: 10px;
`;

const ButtonText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const SkipCheckboxContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SkipText = Styled.H7`
  color: ${colors.gray.primary};
`;

const getDropdownOptions = ({users}) => {
  return users.map((user) => ({
    value: String(user.id),
    label: `${User.getFullName(user)} (${user.organization.name})`,
  }));
};

const formatTimeRanges = ({timeRanges}) => {
  return timeRanges.concat([_.last(timeRanges)]);
};

const getColumnNames = ({reportMove}) => {
  const timeRanges = formatTimeRanges({
    timeRanges: _.get(reportMove, 'organization.defaultTimesheetTimeRanges', []),
  });

  return Collection.mapWith(timeRanges, (timeRange, index, {isLast}) => {
    return isLast ? timeRange.endName : timeRange.startName;
  });
};

const TableHeader = ({names}) => (
  <Header>
    <Cell
      style={{
        width: 80,
      }}
    >
      <Text>Branch / IC Code</Text>
    </Cell>
    <Cell
      style={{
        width: 140,
      }}
    >
      <Text>Name</Text>
    </Cell>
    <Cell
      style={{
        width: 120,
      }}
    >
      <Text>Title</Text>
    </Cell>
    {names.map((name, index) => (
      <Cell
        key={index}
        style={{
          width: 100,
        }}
      >
        <Text>{name}</Text>
      </Cell>
    ))}
    <Cell
      style={{
        width: 50,
      }}
    />
  </Header>
);

const PositionField = ({organization, form, moveUserIndex}) => {
  const positionField = `moveUserInputs.${moveUserIndex}.positions.0`;

  const moverPositions = organization.features.isEnabledMoverPositionMultibranch
    ? Organization.getCompanySettingsMoverPositionsByRole({organization, role: UserRole.EMPLOYEE})
    : organization.moverPositions;

  return (
    <FieldInput.Memoized
      {...form}
      component={DropdownInput}
      name={positionField}
      input={{
        required: !_.get(form.values, positionField),
        options: moverPositions.map((moverPosition) => ({
          label: moverPosition.name,
          value: moverPosition.name,
        })),
        isSearchable: true,
        placeholder: 'Select',
        setFieldValue: form.setFieldValue,
        style: {
          width: '100%',
        },
      }}
    />
  );
};

const ReportMoveUserItem = ({
  moveUserIndex,
  moveUserForm,
  form,
  defaultTimesheetTimeRanges,
  organization,
  job,
}) => {
  const users = job.filteredUsersForCrews;
  return (
    <Item index={moveUserIndex}>
      <Cell
        style={{
          width: 80,
        }}
      >
        <FieldInput.Memoized
          {...form}
          component={TextInput}
          name={`moveUserInputs.${moveUserIndex}.branchCode`}
        />
      </Cell>
      <Cell
        style={{
          width: 140,
        }}
      >
        <FieldInput.Memoized
          {...form}
          component={DropdownInput}
          name={`moveUserInputs.${moveUserIndex}.userId`}
          input={{
            options: getDropdownOptions({users}),
            isSearchable: true,
            placeholder: 'Select one',
            setFieldValue: (name, value) => {
              form.setFieldValue(name, value);
              form.setFieldValue(
                `moveUserInputs.${moveUserIndex}.user`,
                users.find((user) => isSameId(user.id, value)),
              );
            },
            style: {
              width: '100%',
            },
          }}
          style={{
            width: 130,
          }}
        />
      </Cell>
      <Cell
        style={{
          width: 120,
        }}
      >
        <PositionField organization={organization} form={form} moveUserIndex={moveUserIndex} />
      </Cell>
      {moveUserForm.timeRangeInputs.map((timeRangeForm, index) => {
        const current = `moveUserInputs.${moveUserIndex}.timeRangeInputs.${index}`;
        const previous = `moveUserInputs.${moveUserIndex}.timeRangeInputs.${index - 1}`;

        const isSkipped = _.get(form.values, `${current}.kind`) === 'SKIP';
        const count = _.get(form.values, `moveUserInputs.${moveUserIndex}.timeRangeInputs.length`);
        const isFirst = index === 0;
        const isLast = index === count - 1;
        const fieldName = isLast ? `${previous}.end` : `${current}.start`;
        const setStartTime = ({value}) => {
          if (isFirst) {
            form.setFieldValue(`${current}.start`, value);
          } else if (isLast) {
            form.setFieldValue(`${previous}.end`, value);
          } else {
            form.setFieldValue(`${previous}.end`, value);
            form.setFieldValue(`${current}.start`, value);
          }
        };

        return (
          <Cell
            key={`${moveUserIndex}-${index}`}
            style={{
              width: 100,
            }}
          >
            <FieldInput.Memoized
              {...form}
              component={TimeInput}
              name={fieldName}
              input={{
                component: TextInput,
                disabled: isSkipped,
                placeholder: '',
                setFieldValue: (name, value) => setStartTime({value}),
              }}
            />
            <Space height={5} />
            <SkipCheckboxContainer>
              <Checkbox
                checked={isSkipped}
                color={colors.gray.secondary}
                size={16}
                iconSize={12}
                onChange={(nextIsSkipped) => {
                  const defaultTimeRangeKind = _.get(defaultTimesheetTimeRanges, `${index}.kind`);
                  form.setFieldValue(
                    `${current}.kind`,
                    nextIsSkipped ? 'SKIP' : defaultTimeRangeKind,
                  );
                  setStartTime({value: ''});
                }}
              />
              <Space width={5} />
              <SkipText>N/A?</SkipText>
            </SkipCheckboxContainer>
          </Cell>
        );
      })}
      <Cell
        style={{
          width: 50,
          justifyContent: 'center',
        }}
      >
        <Touchable
          onPress={() => {
            const {moveUserInputs} = form.values;
            form.setFieldValue(
              `moveUserInputs`,
              moveUserInputs
                .slice(0, moveUserIndex)
                .concat(moveUserInputs.slice(moveUserIndex + 1)),
            );
          }}
        >
          <Icon color={colors.Pink600} size={Icon.Sizes.Large} source={Icon.Times} />
        </Touchable>
      </Cell>
    </Item>
  );
};

const UpdateCommercialReportMoveTimesheetTable = ({reportMove, handleClose}) => {
  const {form, submitting, handleSubmit} = useUpdateReportMoveMoveUsersForm({
    reportMove,
    onSuccess: () => handleClose(),
    onError: (errors) => console.log({errors}),
  });

  return (
    <Container>
      <ScrollView style={{flex: 1}} contentContainerStyle={{flexGrow: 1}}>
        <ScrollView horizontal>
          <Table>
            <TableHeader names={getColumnNames({reportMove})} />
            {form.values.moveUserInputs.map((moveUserForm, index) => (
              <ReportMoveUserItem
                key={index}
                moveUserIndex={index}
                moveUserForm={moveUserForm}
                form={form}
                defaultTimesheetTimeRanges={reportMove.organization.defaultTimesheetTimeRanges}
                organization={reportMove.organization}
                job={reportMove.job}
              />
            ))}
          </Table>
        </ScrollView>
      </ScrollView>
      <Space height={20} />
      <Footer>
        <Button
          disabled={submitting}
          onPress={() => {
            form.setFieldValue('moveUserInputs', [
              ...form.values.moveUserInputs,
              ReportMoveUserForm.toForm(ReportMoveUserForm.newFromReportMove(reportMove)),
            ]);
          }}
        >
          <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.UserPlus} />
          <ButtonText style={{marginLeft: 10}}>New Crew Member</ButtonText>
        </Button>
        <Buttons>
          <Button disabled={submitting} color={colors.Pink600} onPress={handleClose}>
            <ButtonText>Cancel</ButtonText>
          </Button>
          <ButtonSpace />
          <Button loading={submitting} onPress={handleSubmit}>
            <ButtonText>Save</ButtonText>
          </Button>
        </Buttons>
      </Footer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateCommercialReportMoveTimesheetTable.fragment = gql`
  ${ReportMoveUserForm.newFromReportMove.fragment}
  ${useUpdateReportMoveMoveUsersForm.fragment}
  ${Organization.getCompanySettingsMoverPositionsByRole.fragment}

  fragment UpdateCommercialReportMoveTimesheetTable on ReportMove {
    id
    organization {
      id
      defaultTimesheetTimeRanges {
        kind
        startName
        endName
      }
      filteredUsers: filteredUsersV2(roles: ["employee"]) {
        id
        firstName
        lastName
      }
      moverPositions {
        id
        name
      }
      features {
        isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
      }
      ...Organization_getCompanySettingsMoverPositionsByRole
    }
    job {
      id
      filteredUsersForCrews(roles: ["employee"]) {
        id
        firstName
        lastName
        organization {
          id
          name
        }
      }
    }
    ...ReportMoveUserForm_newFromReportMove
    ...useUpdateReportMoveMoveUsersForm
  }
`;

export default UpdateCommercialReportMoveTimesheetTable;
