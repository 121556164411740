// Libraries
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import ReportMoveTimeRangesChart from './ReportMoveTimeRangesChart';
import ReportMoveTimeRangesList from './ReportMoveTimeRangesList';

const Container = Styled.View`
`;

const Row = Styled.View`
`;

const RowSpace = Styled.View`
  margin-top: 20px;
`;

const ReportMoveTimeRanges = ({reportMove}) => (
  <Container>
    <Row>
      <ReportMoveTimeRangesChart timeRanges={reportMove.timeRanges} />
    </Row>
    <RowSpace />
    <Row>
      <ReportMoveTimeRangesList timeRanges={reportMove.timeRanges} />
    </Row>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMoveTimeRanges.fragment = gql`
  fragment ReportMoveTimeRanges on ReportMove {
    id
    timeRanges {
      name
      kind
      start
      end
    }
  }
`;

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ReportMoveTimeRanges.propTypes = {};

export default ReportMoveTimeRanges;
