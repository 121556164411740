// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Quantity = Styled.Text`
  ${Typography.Responsive.Body}
`;

const Container = Styled.View`
  flex-direction: row;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: 1px;
  overflow: hidden;
  background-color: ${colors.white};
  height: ${({height}) => height || `32`}px;
`;

const QuantityButton = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const VerticalLine = Styled.View`
  height: 100%;
  background-color: ${colors.gray.border};
  width: 1px;
`;

const BaseStepper = ({handleDecrease, handleIncrease, height, style, children}) => {
  return (
    <Container style={style} height={height}>
      <QuantityButton activeOpacity={0.5} onPress={handleDecrease}>
        <Icon source={Icon.Minus} size={12} color={colors.gray.secondary} />
      </QuantityButton>
      <VerticalLine />
      {children}
      <VerticalLine />
      <QuantityButton activeOpacity={0.5} onPress={handleIncrease}>
        <Icon source={Icon.Plus} size={12} color={colors.gray.secondary} />
      </QuantityButton>
    </Container>
  );
};

const InventoryStepper = ({handleDecrease, handleIncrease, onPress, value, height, style}) => {
  const responsive = useResponsive();
  return (
    <BaseStepper
      handleDecrease={handleDecrease}
      handleIncrease={handleIncrease}
      value={value}
      height={height}
      style={style}
    >
      <QuantityButton disabled={!onPress} onPress={onPress}>
        <Quantity responsive={responsive}>{value}</Quantity>
      </QuantityButton>
    </BaseStepper>
  );
};

InventoryStepper.Stepper = BaseStepper;

export default InventoryStepper;
