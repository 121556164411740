// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import TwoChoiceModal from 'modules/App/Modal/components/TwoChoiceModal';

const SendReportMoveSuccessModal = ({job, isOpen, onClose}) => (
  <TwoChoiceModal
    emojiName={'thumbsup'}
    title={'Move Receipt Sent'}
    subtitle={`We successfully sent a move receipt.`}
    textButtonLeft={'Go Home'}
    textButtonRight={'View Move'}
    onPressLeft={({navigator}) => navigator.push('/')}
    onPressRight={({navigator}) => {
      navigator.push('/');
      navigator.push(`/jobs/${job.uuid}`);
    }}
    isOpen={isOpen}
    onClose={onClose}
  />
);

// --------------------------------------------------
// Props
// --------------------------------------------------
SendReportMoveSuccessModal.propTypes = {
  job: PropTypes.object.isRequired,
};

SendReportMoveSuccessModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendReportMoveSuccessModal.fragment = gql`
  fragment SendReportMoveSuccessModal on Job {
    id
    uuid
  }
`;

export default SendReportMoveSuccessModal;
