const JOB_SYNC = 'JOB_SYNC';

const KIND_DISPLAY_MAP = {
  [JOB_SYNC]: 'Job Saved',
};

const DROPDOWN_OPTIONS = [
  {
    label: KIND_DISPLAY_MAP[JOB_SYNC],
    value: JOB_SYNC,
  },
];

const getDisplayKind = (webhookKind: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return KIND_DISPLAY_MAP[webhookKind];
};

export default {
  JOB_SYNC,
  DROPDOWN_OPTIONS,
  getDisplayKind,
};
