// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import DeleteMoverPositionForm from '@shared/modules/User/forms/DeleteMoverPositionForm';

const useDeleteMoverPositionMutation = ({deleteMoverPositionForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      deleteMoverPositionForm: DeleteMoverPositionForm.toForm(deleteMoverPositionForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteMoverPositionMutation.mutation,
    variables: {
      deleteMoverPositionForm: DeleteMoverPositionForm.toMutation(
        form.values.deleteMoverPositionForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteMoverPositionMutation.mutation = gql`
  mutation useDeleteMoverPositionMutation($deleteMoverPositionForm: DeleteMoverPositionForm!) {
    response: deleteMoverPosition(deleteMoverPositionForm: $deleteMoverPositionForm) {
      ${gql.errors}
      moverPositionId
    }
  }
`;

export default useDeleteMoverPositionMutation;
