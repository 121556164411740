// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useToast} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';
import useUpdateWorkflowAndWorkflowStepsMutation from '@shared/modules/Workflow/hooks/useUpdateWorkflowAndWorkflowStepsMutation';
import useValidateWorkflowMutation from '@shared/modules/Workflow/hooks/useValidateWorkflowMutation';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import WorkflowBuilder from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilder';
import WorkflowBuilderPageHeader from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilderPageHeader';

const ContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background}
`;

const WorkflowBuilderPageContent = ({workflow}) => {
  const {navigator} = useNavigationDOM();
  const workflowForm = WorkflowForm.edit(workflow);

  const saveWorkflowSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Automation changes saved!',
  });

  const {form, handleSubmit: handleSubmitValidateWorkflow} = useValidateWorkflowMutation({
    workflowForm,
    onSuccess: () => {
      // The success logic is handled within handlePressDone in WorkflowStepSlide
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  const {
    form: updateWorkflowForm,
    handleSubmit: handleUpdateWorkflowAndWorkflowStepsMutation,
    submitting: handleUpdateWorkflowAndWorkflowStepsMutationSubmitting,
  } = useUpdateWorkflowAndWorkflowStepsMutation({
    workflowForm,
    onSuccess: () => {
      navigator.push(`/settings/automations/${workflow.uuid}`);
      saveWorkflowSuccessToast.handleToast();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <ContentContainer>
      <WorkflowBuilderPageHeader
        workflow={workflow}
        handleUpdateWorkflowAndWorkflowStepsMutation={handleUpdateWorkflowAndWorkflowStepsMutation}
        updateWorkflowForm={updateWorkflowForm}
        form={form}
        handleUpdateWorkflowAndWorkflowStepsMutationSubmitting={
          handleUpdateWorkflowAndWorkflowStepsMutationSubmitting
        }
      />
      <Line />
      <WorkflowBuilder
        workflow={workflow}
        handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
        form={form}
      />
    </ContentContainer>
  );
};

const WorkflowBuilderPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(WorkflowBuilderPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      workflowUuid: params.workflowUuid,
    },
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading as={PageLoadingIndicator} loading={loading}>
        {() => {
          return <WorkflowBuilderPageContent workflow={data.workflow} />;
        }}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowBuilderPage.query = gql`
  ${WorkflowBuilderPageHeader.fragment}
  ${WorkflowBuilder.fragment}
  ${WorkflowForm.edit.fragment}

  query WorkflowBuilderPage($workflowUuid: String!) {
    ${gql.query}
    workflow(uuid: $workflowUuid) {
      id
      uuid
      organizationId
      ...WorkflowForm_edit
      ...WorkflowBuilderPageHeader
      ...WorkflowBuilder
    }
  }
`;

export default WorkflowBuilderPage;
