// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent, pluralize} from '@supermove/utils';

// App
import CostItemKind from '@shared/modules/Billing/enums/CostItemKind';
import CostItemUnit from '@shared/modules/Billing/enums/CostItemUnit';
import CostItemForm from '@shared/modules/Billing/forms/CostItemForm';
import CostSectionForm from '@shared/modules/Billing/forms/CostSectionForm';
import Line from 'modules/App/components/Line';
import CostItemActionsMenuButton from 'modules/Cost/components/CostItemActionsMenuButton';
import CostItemRateInputs from 'modules/Cost/components/CostItemRateInputs';
import CostItemTypeSearchInput from 'modules/Cost/components/CostItemTypeSearchInput';

const Container = Styled.View`
  z-index: 100;
`;

const Button = Styled.ButtonV2`
`;

const ButtonText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.blue.interactive};
  text-decoration-line: underline;
`;

const ItemRow = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex-direction: row;
  align-items: center;
  padding-horizontal: 8px;
`;

const HeaderLabel = Styled.Text`
  ${Typography.Body5}
  color: ${colors.gray.secondary};
`;

const Cell = Styled.View`
  flex: ${({flex}) => flex};
  flex-direction: row;
  align-items: center;
  justify-content: ${({justify}) => justify};
  padding-horizontal: 8px;
  border-color: ${colors.gray.border};
`;

const CellText = Styled.Text`
  ${Typography.Body4}
`;

const handleSetBaseValue = ({
  form,
  field,
  unit,
  costSectionForm,
  weight,
  totalDistance,
  projectRevenue,
}) => {
  switch (unit) {
    case CostItemUnit.PERCENT: {
      if (costSectionForm.billId) {
        const billSubtotal = _.get(costSectionForm, 'billSubtotal', 0);
        return form.setFieldValue(
          `${field}.baseValue`,
          Currency.display(billSubtotal, {shouldHideCentsIfZero: true}),
        );
      }
      return form.setFieldValue(
        `${field}.baseValue`,
        Currency.display(projectRevenue, {shouldHideCentsIfZero: true}),
      );
    }
    case CostItemUnit.CENTS_PER_HOUR: {
      // Requires a bill item with quantity as hours to support CENTS_PER_HOUR
      return form.setFieldValue(`${field}.baseValue`, '');
    }
    case CostItemUnit.CENTS_PER_POUND:
    case CostItemUnit.CENTS_PER_ONE_HUNDRED_POUNDS: {
      return form.setFieldValue(`${field}.baseValue`, pluralize('lbs', weight, true));
    }
    case CostItemUnit.CENTS_PER_MILE: {
      return form.setFieldValue(`${field}.baseValue`, `${totalDistance} mi`);
    }
    default: {
      return form.setFieldValue(`${field}.baseValue`, '');
    }
  }
};

const handleUpdateTotal = ({form, field, costSectionField, total}) => {
  CostSectionForm.handleUpdateTotalAndCostItem({
    form,
    field: costSectionField,
    costItemField: field,
    costItemTotal: total,
  });
};

const handleChangeRate = ({
  form,
  field,
  costSectionField,
  rate,
  unit,
  costSectionForm,
  weight,
  totalDistance,
}) => {
  const currentUnit = unit || _.get(form.values, `${field}.unit`);
  const rateInCents = Currency.convertToCents(rate);
  switch (currentUnit) {
    case CostItemUnit.PERCENT: {
      const rateInPercent = Percent.toFloat(rate);
      const billSubtotal = _.get(costSectionForm, 'billSubtotal', 0);
      return handleUpdateTotal({
        form,
        field,
        costSectionField,
        total: rateInPercent * billSubtotal,
      });
    }
    case CostItemUnit.CENTS_PER_HOUR: {
      // Requires a bill item with quantity as hours to support CENTS_PER_HOUR
      return handleUpdateTotal({form, field, costSectionField, total: 0});
    }
    case CostItemUnit.CENTS_PER_POUND: {
      return handleUpdateTotal({form, field, costSectionField, total: rateInCents * weight});
    }
    case CostItemUnit.CENTS_PER_ONE_HUNDRED_POUNDS: {
      return handleUpdateTotal({
        form,
        field,
        costSectionField,
        total: rateInCents * (weight / 100),
      });
    }
    case CostItemUnit.CENTS_PER_MILE: {
      return handleUpdateTotal({form, field, costSectionField, total: rateInCents * totalDistance});
    }
    default: {
      return handleUpdateTotal({form, field, costSectionField, total: rateInCents});
    }
  }
};

const handleUpdateKind = ({form, field, costSectionForm, unit}) => {
  const kind = CostItemForm.getKindFromUnit({unit, billId: costSectionForm.billId});
  form.setFieldValue(`${field}.kind`, kind);
};

const handleChangeUnit = ({
  form,
  field,
  costSectionField,
  unit,
  costSectionForm,
  weight,
  totalDistance,
  projectRevenue,
}) => {
  const isCustomCostItem = _.get(form.values, `${field}.isCustomCostItem`);
  if (isCustomCostItem) {
    handleUpdateKind({form, field, costSectionForm, unit});
  }
  const wasPercent = _.get(form.values, `${field}.unit`) === CostItemUnit.PERCENT;
  const isPercent = unit === CostItemUnit.PERCENT;
  const rate = _.get(form.values, `${field}.rate`);
  handleSetBaseValue({form, field, unit, costSectionForm, weight, totalDistance, projectRevenue});
  if (wasPercent !== isPercent) {
    form.setFieldValue(`${field}.rate`, '');
    handleUpdateTotal({form, field, costSectionField, total: 0});
  } else if (rate) {
    handleChangeRate({
      form,
      field,
      costSectionField,
      rate,
      unit,
      costSectionForm,
      weight,
      totalDistance,
    });
  }
};

const handleAddCostItem = ({form, field}) => {
  const costId = _.get(form.values, `${field}.costId`);
  const billId = _.get(form.values, `${field}.billId`);
  const newCostItemForm = CostItemForm.newCustomCostItem({
    costId,
    billId,
    name: '',
    rate: '',
    unit: CostItemUnit.CENTS,
    total: 0,
    baseValue: '',
  });
  const costItemForms = _.get(form.values, `${field}.costItemForms`);
  form.setFieldValue(`${field}.costItemForms`, [
    ...costItemForms,
    CostItemForm.toForm(newCostItemForm),
  ]);
};

const getBillCostItemTypes = ({costItemTypes}) => {
  return _.filter(
    costItemTypes,
    (costItemType) =>
      costItemType.kind !== CostItemKind.PER_GRAND_TOTAL &&
      costItemType.kind !== CostItemKind.PER_BILL_ITEM,
  );
};

const getNonBillCostItemTypes = ({costItemTypes}) => {
  return _.filter(
    costItemTypes,
    (costItemType) =>
      costItemType.kind !== CostItemKind.PER_BILL_TOTAL &&
      costItemType.kind !== CostItemKind.PER_BILL_ITEM,
  );
};

const COST_ITEMS_TABLE_COLUMNS = [
  {
    flex: 5,
    justify: 'flex-start',
    headerContent: () => {
      return <HeaderLabel>Cost Item Name</HeaderLabel>;
    },
    cellContent: ({
      form,
      field,
      costSectionField,
      costItemTypes,
      costSectionForm,
      weight,
      totalDistance,
      costItemIndex,
      moverPositionId,
    }) => {
      return (
        <React.Fragment>
          <CostItemActionsMenuButton
            form={form}
            costSectionField={costSectionField}
            costItemIndex={costItemIndex}
          />
          <Space width={4} />
          <CostItemTypeSearchInput
            form={form}
            field={field}
            costSectionField={costSectionField}
            costItemTypes={
              costSectionForm.billId
                ? getBillCostItemTypes({costItemTypes})
                : getNonBillCostItemTypes({costItemTypes})
            }
            costSectionForm={costSectionForm}
            weight={weight}
            totalDistance={totalDistance}
            moverPositionId={moverPositionId}
          />
        </React.Fragment>
      );
    },
  },
  {
    flex: 1,
    justify: 'flex-end',
    style: {borderRightWidth: 1, marginRight: -2, height: 36},
    headerContent: () => {
      return <HeaderLabel>Value</HeaderLabel>;
    },
    cellContent: ({form, field}) => {
      const baseValue = _.get(form.values, `${field}.baseValue`);
      return (
        <CellText style={{paddingVertical: 10}} numberOfLines={1}>
          {baseValue}
        </CellText>
      );
    },
  },
  {
    flex: 2,
    justify: 'flex-end',
    headerContent: () => {
      return <HeaderLabel>Cost Rate</HeaderLabel>;
    },
    cellContent: ({
      form,
      field,
      costSectionField,
      costItemForm,
      costSectionForm,
      weight,
      totalDistance,
      projectRevenue,
    }) => {
      return (
        <CostItemRateInputs
          form={form}
          field={field}
          handleChangeRate={(rate) => {
            handleChangeRate({
              form,
              field,
              costSectionField,
              rate,
              costSectionForm,
              weight,
              totalDistance,
            });
          }}
          handleChangeUnit={(unit) => {
            handleChangeUnit({
              form,
              field,
              costSectionField,
              unit,
              costSectionForm,
              weight,
              totalDistance,
              projectRevenue,
            });
          }}
          costItemForm={costItemForm}
        />
      );
    },
  },
  {
    flex: 1,
    justify: 'flex-end',
    headerContent: () => {
      return <HeaderLabel>Cost Value</HeaderLabel>;
    },
    cellContent: ({costItemForm}) => {
      return <CellText>{costItemForm.total}</CellText>;
    },
  },
];

const CostSectionCostItems = ({
  form,
  field,
  costItemTypes,
  weight,
  totalDistance,
  projectRevenue,
  moverPositionId,
}) => {
  const costItemForms = _.get(form.values, `${field}.costItemForms`);
  return (
    <Container>
      <ItemRow index={100}>
        {COST_ITEMS_TABLE_COLUMNS.map(({flex, justify, style, headerContent}, index) => (
          <Cell key={index} flex={flex} justify={justify} style={style}>
            {headerContent()}
          </Cell>
        ))}
      </ItemRow>
      <Line />
      {costItemForms.map((costItemForm, itemIndex) => {
        if (costItemForm.isDeleted) {
          return null;
        }
        return (
          <ItemRow
            key={itemIndex}
            style={{backgroundColor: colors.gray.background}}
            index={itemIndex}
          >
            {COST_ITEMS_TABLE_COLUMNS.map(({flex, justify, style, cellContent}, cellIndex) => (
              <Cell key={cellIndex} flex={flex} justify={justify} style={style}>
                {cellContent({
                  form,
                  field: `${field}.costItemForms.${itemIndex}`,
                  costSectionField: field,
                  costItemForm,
                  costSectionForm: _.get(form.values, field),
                  weight,
                  totalDistance,
                  projectRevenue,
                  costItemTypes,
                  costItemIndex: itemIndex,
                  moverPositionId,
                })}
              </Cell>
            ))}
          </ItemRow>
        );
      })}
      <ItemRow index={100} style={{backgroundColor: colors.gray.background}}>
        <ItemRow
          index={100}
          style={{borderRightWidth: 1, borderColor: colors.gray.border, width: 365}}
        >
          <Button style={{paddingVertical: 6}} onPress={() => handleAddCostItem({form, field})}>
            <ButtonText>Add compensation item</ButtonText>
          </Button>
        </ItemRow>
        <ItemRow index={100} />
      </ItemRow>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CostSectionCostItems.fragment = gql`
  ${CostItemTypeSearchInput.fragment}

  fragment CostSectionCostItems on CostItemType {
    id
    ...CostItemTypeSearchInput
  }
`;

export default CostSectionCostItems;
