// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors, typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const Container = Styled.View`
  align-self: stretch;
`;

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  background-color: ${colors.gray.background};
`;

const FlatListCell = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const FlatListSpace = Styled.View`
  height: 10px;
`;

const Item = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding-horizontal: 10px;
`;

const Cell = Styled.View`
  width: 100px;
`;

const Text = Styled.H6`
  ${({vars}) => (vars.isCompact ? typography.h7() : '')}
  color: ${colors.gray.secondary};
  ${fontWeight(500)}
`;

const Name = Styled.H6`
  ${({vars}) => (vars.isCompact ? typography.h7() : '')}
`;

const Quantity = Styled.H6`
  ${({vars}) => (vars.isCompact ? typography.h7() : '')}
  color: ${colors.gray.secondary};
`;

const Price = Styled.H6`
  ${({vars}) => (vars.isCompact ? typography.h7() : '')}
  color: ${colors.gray.secondary};
`;

const EmptyContainer = Styled.View`
  padding-horizontal: 12px;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const ListHeader = ({isCompact, job}) => {
  const showPrice = job.organization.features.isEnabledShowEquipmentAndMaterialsPriceOnDocuments;

  return (
    <Header>
      <Cell
        style={{
          flex: 1,
          minWidth: 140,
        }}
      >
        <Text vars={{isCompact}}>Material</Text>
      </Cell>
      <Cell
        style={{
          alignItems: 'flex-end',
          minWidth: 200,
        }}
      >
        <Text vars={{isCompact}}>Quantity Requested</Text>
      </Cell>
      <Cell
        style={{
          alignItems: 'flex-end',
        }}
      >
        <Text vars={{isCompact}}>Used</Text>
      </Cell>
      {showPrice && (
        <Cell
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Text vars={{isCompact}}>Price</Text>
        </Cell>
      )}
    </Header>
  );
};

const CommercialMaterialItem = ({isCompact, commercialMaterial, job}) => {
  const showPrice = job.organization.features.isEnabledShowEquipmentAndMaterialsPriceOnDocuments;

  return (
    <Item>
      <Cell
        style={{
          flex: 1,
          minWidth: 140,
        }}
      >
        <Name vars={{isCompact}}>{commercialMaterial.name}</Name>
      </Cell>
      <Cell
        style={{
          alignItems: 'flex-end',
        }}
      >
        <Quantity vars={{isCompact}}>{commercialMaterial.quantityRequested}</Quantity>
      </Cell>
      <Cell
        style={{
          alignItems: 'flex-end',
        }}
      >
        <Quantity vars={{isCompact}}>{commercialMaterial.quantityDelivered}</Quantity>
      </Cell>
      {showPrice && (
        <Cell
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Price vars={{isCompact}}>{Currency.display(commercialMaterial.price)}</Price>
        </Cell>
      )}
    </Item>
  );
};

const CommercialMaterialsList = ({isCompact, job, style}) => {
  return (
    <Container style={style}>
      <FlatList
        listKey={'CommercialMaterialsList'}
        data={job.commercialCatalog.commercialMaterials}
        keyExtractor={(commercialMaterial, index) => String(index)}
        renderItem={({item: commercialMaterial}) => (
          <CommercialMaterialItem
            isCompact={isCompact}
            commercialMaterial={commercialMaterial}
            job={job}
          />
        )}
        CellRendererComponent={FlatListCell}
        ItemSeparatorComponent={FlatListSpace}
        ListHeaderComponent={() => (
          <React.Fragment>
            <ListHeader isCompact={isCompact} job={job} />
            <FlatListSpace />
          </React.Fragment>
        )}
        ListEmptyComponent={() => (
          <EmptyContainer>
            <EmptyMessage>No Materials</EmptyMessage>
          </EmptyContainer>
        )}
      />
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
CommercialMaterialsList.propTypes = {
  isCompact: PropTypes.bool,
  job: PropTypes.object.isRequired,
  style: PropTypes.object,
};

CommercialMaterialsList.defaultProps = {
  isCompact: false,
  style: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialMaterialsList.fragment = gql`
  fragment CommercialMaterialsList on Job {
    id
    organization {
      id
      features {
        isEnabledShowEquipmentAndMaterialsPriceOnDocuments: isEnabled(
          feature: "SHOW_EQUIPMENT_AND_MATERIALS_PRICE_ON_DOCUMENTS"
        )
      }
    }
    commercialCatalog {
      id
      commercialMaterials {
        id
        name
        quantityDelivered
        quantityRequested
        price
      }
    }
  }
`;

export default CommercialMaterialsList;
