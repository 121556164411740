// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const FILE_INPUT_TYPE_TEXT = 'PDF, PNG, JPG, CSV, TXT, DOC, DOCX, XLS, XLSX, XLSB, MP4, WAV, MOV';

const FileDragInputContainer = Styled.View`
  flex: 1;
  min-height: 220px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: '4px';
  border-bottom-right-radius: '4px';
  border: ${({isDragActive}) => (isDragActive ? '2px dashed' : '1px solid')};
  cursor: pointer;
  padding-horizontal: 46px;
  border-color: ${({isDragActive}) =>
    isDragActive ? colors.blue.interactive : colors.gray.border};
`;

const NoAttachmentsText = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.primary};
`;

const FileDragInputText = Styled.Text`
  ${Typography.Responsive.Body}
  width: 100%;
  text-align: center;
  color: ${colors.gray.tertiary};
`;

const FileDragInputClickText = Styled.Text`
  ${Typography.Responsive.Link}
  width: 100%;
  text-align: center;
`;

const FileDragInputTypeText = Styled.Text`
  ${Typography.Responsive.Micro}
  width: 100%;
  text-align: center;
  color: ${colors.gray.tertiary};
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const TableHeaderContainer = Styled.View`
  border: 1px solid ${colors.gray.border};
  border-top-left-radius: ${(props) => (props.hasBottomBorder ? '0px' : '4px')};
  border-top-right-radius: ${(props) => (props.hasBottomBorder ? '0px' : '4px')};
  border-bottom-left-radius: ${(props) => (props.hasBottomBorder ? '4px' : '0px')};
  border-bottom-right-radius: ${(props) => (props.hasBottomBorder ? '4px' : '0px')};
  border-bottom-width: ${(props) => (props.hasBottomBorder ? '1px' : '0px')};
  width: 100%;
  padding-horizontal: 20px;
  padding-vertical: 12px;
`;

const TableHeaderRowContainer = Styled.View`
  flex-direction: row;
`;

const TableHeaderColContainer = Styled.View`
  flex: 1;
  flex-direction: column;
`;

const TableHeaderRow = ({responsive}) => {
  return (
    <TableHeaderContainer>
      <TableHeaderRowContainer>
        <HeaderText responsive={responsive} style={{flex: 2, textAlign: 'left'}}>
          File Name
        </HeaderText>
        <HeaderText responsive={responsive} style={{flex: 3, textAlign: 'left'}}>
          Notes
        </HeaderText>
        <HeaderText responsive={responsive} style={{flex: 1, textAlign: 'left'}}>
          Uploaded By
        </HeaderText>
        <HeaderText responsive={responsive} style={{flex: 1, textAlign: 'center'}}>
          Uploaded On
        </HeaderText>
        <HeaderText responsive={responsive} style={{flex: 1, textAlign: 'center'}}>
          Actions
        </HeaderText>
      </TableHeaderRowContainer>
    </TableHeaderContainer>
  );
};

const AttachmentsFileDropzone = ({attachments, isTableHeaderVisible, isDragActive}) => {
  const responsive = useResponsive();
  return (
    <TableHeaderColContainer>
      {isTableHeaderVisible && <TableHeaderRow responsive={responsive} />}
      <FileDragInputContainer isDragActive={isDragActive}>
        {_.isEmpty(attachments) && (
          <React.Fragment>
            <NoAttachmentsText responsive={responsive}>{'No Attachments'}</NoAttachmentsText>
            <Space height={12} />
          </React.Fragment>
        )}
        <FileDragInputText responsive={responsive}>
          {'Drag files here'}
          <FileDragInputClickText responsive={responsive}>
            {' or click here to upload'}
          </FileDragInputClickText>
          {'.'}
        </FileDragInputText>
        <Space height={8} />
        <FileDragInputTypeText responsive={responsive}>
          {FILE_INPUT_TYPE_TEXT}
        </FileDragInputTypeText>
      </FileDragInputContainer>
    </TableHeaderColContainer>
  );
};

export default AttachmentsFileDropzone;
