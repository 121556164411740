// Libraries
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// Components
import Button from '@shared/design/components/Button';
import ContainerTypeForm from '@shared/modules/Storage/forms/ContainerTypeForm';
import useCreateContainerTypeMutation from '@shared/modules/Storage/hooks/useCreateContainerTypeMutation';
import CreateContainerTypeFields from 'modules/Organization/Settings/Storage/Containers/components/CreateContainerTypeFields';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: ${(props) => (props.mobile ? '100%' : 400)}px;
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 24px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading6}
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const CreateContainerTypeFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <Actions>
      <Button
        text={'Cancel'}
        color={colors.white}
        textColor={colors.gray.secondary}
        onPress={handleClose}
        isDisabled={submitting}
      />
      <Space width={8} />
      <Button
        text={'Create'}
        color={submitting ? colors.gray.border : colors.blue.interactive}
        onPress={handleSubmit}
        isDisabled={submitting}
      />
    </Actions>
  );
};

const CreateContainerTypeModal = ({organizationId, userId, refetch, isOpen, handleClose}) => {
  const containerTypeForm = ContainerTypeForm.new({organizationId, userId});
  const {form, submitting, handleSubmit} = useCreateContainerTypeMutation({
    containerTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log(errors);
    },
  });

  const responsive = useResponsive();

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ModalContainer {...responsive}>
          <TitleText>Create Container Type</TitleText>
          <Space height={16} />
          <CreateContainerTypeFields form={form} />
          <Space height={16} />
          <CreateContainerTypeFooter
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            submitting={submitting}
          />
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

export default CreateContainerTypeModal;
