export interface CompleteUploadFileFormTypeWrapper {
  completeUploadFileForm: CompleteUploadFileFormType;
}

export interface CompleteUploadFileFormType {
  uuid: string | null;
}

const _new = () => ({
  uuid: null,
});

const toForm = ({uuid}: CompleteUploadFileFormType): CompleteUploadFileFormType => ({
  uuid,
});

const toMutation = ({uuid}: CompleteUploadFileFormType): CompleteUploadFileFormType => ({
  uuid,
});

const CompleteUploadFileForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CompleteUploadFileForm;
