// App
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';

const ROOM = {
  id: null,
  name: 'Custom Room',
};

const ITEM = {
  name: 'Custom Item',
  weight: 0,
  volume: 0,
  price: 0,
  isDerivedWeight: true,
  id: null,
  kind: ItemTypeKind.ITEM,
};

const CustomSurveyTypes = {
  ROOM,
  ITEM,
};

export default CustomSurveyTypes;
