const PICK_UP = 'PICK_UP';
const DROP_OFF = 'DROP_OFF';

// Project location kinds
const ORIGIN = 'ORIGIN';
const INTERMEDIATE = 'INTERMEDIATE';
const DESTINATION = 'DESTINATION';

const display = (kind: any) => {
  switch (kind) {
    case LocationKind.PICK_UP:
      return 'Pick-up';
    case LocationKind.DROP_OFF:
      return 'Drop-off';
    default:
      return '';
  }
};

const LocationKind = {
  PICK_UP,
  DROP_OFF,

  // Project location kinds
  ORIGIN,
  INTERMEDIATE,
  DESTINATION,

  VALUES: [PICK_UP, DROP_OFF],

  display,
};

export default LocationKind;
