// Libraries
import _ from 'lodash';

// Supermove
import {uuid} from '@supermove/utils';

export interface AssignToBranchActionFormType {
  workflowRunStepUuid: string;
  variableId: string | number | null;
  defaultOrganizationId: string | number | null;
}

const _new = (): AssignToBranchActionFormType => ({
  workflowRunStepUuid: uuid(),
  variableId: null,
  defaultOrganizationId: null,
});

const edit = (assignToBranchActionForm: any): AssignToBranchActionFormType => ({
  workflowRunStepUuid: assignToBranchActionForm.workflowRunStepUuid,
  variableId: assignToBranchActionForm.variableId,
  defaultOrganizationId: assignToBranchActionForm.defaultOrganizationId,
});

const toForm = (
  assignToBranchActionForm: AssignToBranchActionFormType,
): AssignToBranchActionFormType => ({
  workflowRunStepUuid: assignToBranchActionForm.workflowRunStepUuid,
  variableId: _.toString(assignToBranchActionForm.variableId),
  defaultOrganizationId: _.toString(assignToBranchActionForm.defaultOrganizationId),
});

const toMutation = (
  assignToBranchActionForm: AssignToBranchActionFormType,
): AssignToBranchActionFormType => ({
  workflowRunStepUuid: assignToBranchActionForm.workflowRunStepUuid,
  variableId: _.toNumber(assignToBranchActionForm.variableId),
  defaultOrganizationId: _.toNumber(assignToBranchActionForm.defaultOrganizationId),
});

const AssignToBranchActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default AssignToBranchActionForm;
