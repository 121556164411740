// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePropUpdatedAt} from '@supermove/hooks';

// App
import Navigation from 'modules/App/components/Navigation';
import Page from 'modules/App/components/Page';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectNotFoundPage from 'modules/Project/components/ProjectNotFoundPage';

const Container = Styled.View`
  flex: 1;
`;

const ProjectPageWithNavigationContent = ({
  theme,
  side,
  title,
  subtitle,
  onClose,
  children,
  ...props
}) => {
  const {updatedAt: loadingUpdatedAt} = usePropUpdatedAt(props.loading);

  return (
    <Container>
      <Navigation
        theme={theme}
        side={side}
        title={title({...props})}
        subtitle={subtitle({...props})}
        left={() =>
          onClose ? (
            <Navigation.CloseButton theme={theme} onPress={() => onClose({...props})} />
          ) : null
        }
      />
      <Loading as={PageLoadingIndicator} loading={props.loading}>
        {() =>
          props.data.project ? children({loadingUpdatedAt, ...props}) : <ProjectNotFoundPage />
        }
      </Loading>
    </Container>
  );
};

const ProjectPageWithNavigation = ({
  theme,
  side,
  title,
  subtitle,
  variables,
  query,
  onClose,
  pollInterval,
  children,
}) => {
  return (
    <Page
      fetchPolicy={'network-only'}
      query={query}
      pollInterval={pollInterval}
      variables={variables}
    >
      {(props) => (
        <ProjectPageWithNavigationContent
          theme={theme}
          side={side}
          title={title}
          subtitle={subtitle}
          onClose={onClose}
          children={children}
          {...props}
        />
      )}
    </Page>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ProjectPageWithNavigation.propTypes = {
  hasActions: PropTypes.bool,
  theme: PropTypes.string,
  onClose: PropTypes.func,
  side: PropTypes.func,
  title: PropTypes.func,
  subtitle: PropTypes.func,
};

ProjectPageWithNavigation.defaultProps = {
  hasActions: false,

  theme: 'light',
  onClose: undefined,

  side: ({responsive}) => (responsive.mobile ? 60 : 120),
  title: () => '',
  subtitle: () => '',
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectPageWithNavigation.fragment = gql`
  fragment ProjectPageWithNavigation on Project {
    id
  }
`;

export default ProjectPageWithNavigation;
