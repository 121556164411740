// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import MessageModal from '@shared/design/components/Modal/SmallModal/MessageModal';

const getSubtitleMessage = ({documentTemplate}) => {
  const defaultLabels = [];
  if (documentTemplate.isDefaultConfirmationDocumentTemplate) {
    defaultLabels.push('Confirmation');
  }
  if (documentTemplate.isDefaultQuoteDocumentTemplate) {
    defaultLabels.push('Quote');
  }
  return `"${documentTemplate.name}" is the default document template for: ${defaultLabels.join(
    ', ',
  )}`;
};

const BlockDeleteDefaultDocumentTemplateModal = ({
  blockDeleteDefaultDocumentTemplateModal,
  documentTemplate,
  handleClose,
}) => {
  return (
    <MessageModal
      isOpen={blockDeleteDefaultDocumentTemplateModal.isOpen}
      title={'Unable to delete'}
      message={getSubtitleMessage({documentTemplate})}
      primaryActionText={'I understand'}
      handlePrimaryAction={handleClose}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BlockDeleteDefaultDocumentTemplateModal.fragment = gql`
  fragment BlockDeleteDefaultDocumentTemplateModal on DocumentTemplateV2 {
    id
    name
    isDefaultConfirmationDocumentTemplate
    isDefaultQuoteDocumentTemplate
  }
`;

export default BlockDeleteDefaultDocumentTemplateModal;
