// Supermove
import {gql} from '@supermove/graphql';
import {JobModel, JobUserModel, ProjectModel, UserModel} from '@supermove/models';
import {Currency, withFragment} from '@supermove/utils';

// App
import JobTipsForm, {
  JobTipsFormType,
  JobTipsFormTypeForm,
  JobTipsFormTypeMutation,
} from '@shared/modules/Billing/forms/JobTipsForm';

export interface UserWithTipType extends UserModel {
  tipAmount: number;
}

export interface ProjectTipsFormType {
  projectId: string;
  jobTipsForms: JobTipsFormType[];
  movers: UserWithTipType[];
  totalTipAmount: number;
}

const getMovers = ({project}: {project: ProjectModel}): UserWithTipType[] => {
  const moverTips = new Map<string, number>();
  const jobUsers: JobUserModel[] = project.scheduledMoveJobs
    .flatMap((job: JobModel) => job.assignedJobUsersForTips)
    .filter((jobUser: JobUserModel) => {
      const userId = jobUser.user.id;
      if (moverTips.has(userId)) {
        moverTips.set(userId, moverTips.get(userId)! + jobUser.tipAmount);
        return false;
      }
      moverTips.set(userId, jobUser.tipAmount);
      return true;
    });

  return jobUsers.map(
    (jobUser: JobUserModel): UserWithTipType => ({
      ...jobUser.user,
      tipAmount: moverTips.get(jobUser.user.id)!,
    }),
  );
};

const edit = withFragment(
  (
    project: ProjectModel,
    {isDynamicJobTip}: {isDynamicJobTip?: boolean} = {},
  ): ProjectTipsFormType => {
    return {
      projectId: project.id,
      jobTipsForms: project.scheduledMoveJobs.map((job: any) =>
        JobTipsForm.edit(job, {billId: project.currentPrimaryBill.id, isDynamicJobTip}),
      ),

      // Private
      movers: getMovers({project}),
      totalTipAmount: project.scheduledMoveJobs.reduce(
        (projectTipTotal: number, job: JobModel) =>
          projectTipTotal +
          job.assignedJobUsersForTips.reduce(
            (jobTipTotal: number, jobUser: JobUserModel) => jobTipTotal + jobUser.tipAmount,
            0,
          ),
        0,
      ),
    };
  },
  gql`
    ${JobTipsForm.edit.fragment}
    fragment ProjectTipsForm_edit on Project {
      id
      currentPrimaryBill {
        id
      }
      scheduledMoveJobs {
        id
        assignedJobUsersForTips {
          id
          tipAmount
          user {
            id
            fullName
          }
        }
        ...JobTipsForm_edit
      }
    }
  `,
);

export interface ProjectTipsFormTypeForm {
  projectId: string;
  jobTipsForms: JobTipsFormTypeForm[];
  movers: UserModel[];
  totalTipAmount: number;
}

const toForm = ({
  projectId,
  jobTipsForms,
  movers,
  totalTipAmount,
}: ProjectTipsFormType): ProjectTipsFormTypeForm => ({
  projectId,
  jobTipsForms: jobTipsForms.map(JobTipsForm.toForm),

  // Private
  movers,
  totalTipAmount,
});

export interface ProjectTipsFormTypeMutation {
  projectId: string;
  jobTipsForms: JobTipsFormTypeMutation[];
}

const toMutation = ({
  projectId,
  jobTipsForms,
}: ProjectTipsFormTypeForm): ProjectTipsFormTypeMutation => ({
  projectId,
  jobTipsForms: jobTipsForms.map(JobTipsForm.toMutation),
});

const getJobUserTipTotal = (projectTipsForm: ProjectTipsFormTypeForm): number => {
  return projectTipsForm.jobTipsForms.reduce(
    (total: number, jobTipsForm: JobTipsFormTypeForm) =>
      total +
      jobTipsForm.jobUserForms.reduce(
        (total: number, jobUserForm: any) => total + Currency.toMutation(jobUserForm.tipAmount),
        0,
      ),
    0,
  );
};

const ProjectTipsForm = {
  edit,
  toForm,
  toMutation,

  // Helpers
  getJobUserTipTotal,
};

export default ProjectTipsForm;
