// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useSearch, useTabs, useToast, useResponsive} from '@supermove/hooks';
import {Workflow} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import WorkflowTriggerIdentifier from '@shared/modules/Workflow/enums/WorkflowTriggerIdentifier';
import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';
import WorkflowStepForm from '@shared/modules/Workflow/forms/WorkflowStepForm';
import useValidateWorkflowTriggerMutation from '@shared/modules/Workflow/hooks/useValidateWorkflowTriggerMutation';
import Line from 'modules/App/components/Line';
import WorkflowBuilderOptionCard from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilderOptionCard';
import WorkflowBuilderSlideSet from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilderSlideSet';
import WorkflowTriggerInputs from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowTriggerInputs';
import TagPill from 'modules/Tag/components/TagPill';

const SUMMARY_SLIDE_HEIGHT = 132;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

const Column = Styled.View`
  flex-direction: column;
`;

const Text = Styled.Text`
  ${Typography.Body};
`;

const LabelText = Styled.Text`
  ${Typography.Label}
`;

const ProjectJobTypeContainer = Styled.View`
  height: 28px;
  justify-content: center;
  padding-horizontal: 8px;
  border-radius: 16px;
  background-color: ${colors.gray.border};
  margin-vertical: 4px;
`;

const ProjectTypeColorIndicator = Styled.Text`
  ${Typography.Body}
  color: ${({color}) => color};
`;

const handlePressCategory = ({form, field, category, setSlideIndex}) => {
  form.setFieldValue(`${field}.triggerIdentifierCategory`, category);
  setSlideIndex(1);
};

const handlePressTrigger = ({form, field, trigger, setSlideIndex}) => {
  form.setFieldValue(`${field}.triggerIdentifier`, trigger);
  setSlideIndex(2);
};

const handleSuccess = ({form, field, validateWorkflowTriggerForm, setSlideIndex}) => {
  const triggerIdentifier = _.get(validateWorkflowTriggerForm.values, `${field}.triggerIdentifier`);
  const triggerVariableIdentifier = _.get(
    validateWorkflowTriggerForm.values,
    `${field}.triggerVariableIdentifier`,
  );
  const triggerHour = _.get(validateWorkflowTriggerForm.values, `${field}.triggerHour`);
  const triggerDaysBefore = _.get(validateWorkflowTriggerForm.values, `${field}.triggerDaysBefore`);

  const projectTypeIdList = _.get(validateWorkflowTriggerForm.values, `${field}.projectTypeIdList`);
  const jobTypeIdList = _.get(validateWorkflowTriggerForm.values, `${field}.jobTypeIdList`);
  const tagIdList = _.get(validateWorkflowTriggerForm.values, `${field}.tagIdList`);

  form.setFieldValue(`${field}.triggerIdentifier`, triggerIdentifier);
  form.setFieldValue(`${field}.triggerVariableIdentifier`, triggerVariableIdentifier);
  form.setFieldValue(`${field}.triggerHour`, triggerHour);
  form.setFieldValue(`${field}.triggerDaysBefore`, triggerDaysBefore);

  form.setFieldValue(`${field}.projectTypeIdList`, projectTypeIdList);
  form.setFieldValue(`${field}.jobTypeIdList`, jobTypeIdList);
  form.setFieldValue(`${field}.tagIdList`, tagIdList);

  const isStepsEnabled = _.get(form.values, `${field}.isStepsEnabled`);
  if (!isStepsEnabled) {
    // If steps are not enabled, then we can enable workflow steps now and
    // proceed the user to add their first workflow step.
    const workflowStepForm = WorkflowStepForm.new({order: 0, workflowStepActionKind: ''});
    form.setFieldValue(`${field}.workflowStepForms`, [workflowStepForm]);
    form.setFieldValue(`${field}.isStepsEnabled`, true);
    form.setFieldValue(`${field}.isEditingStep`, true);
  } else {
    form.setFieldValue(`${field}.isEditingStep`, false);
  }
  setSlideIndex(3);
};

const handlePressEdit = ({form, field, cannotUpdateTwoStepsAtOnceErrorToast, setSlideIndex}) => {
  // Only being allowed to edit one step at a time includes editing the trigger.
  if (_.get(form.values, `${field}.isEditingStep`)) {
    return cannotUpdateTwoStepsAtOnceErrorToast.handleToast();
  }
  form.setFieldValue(`${field}.isEditingStep`, true);
  setSlideIndex(2);
};

const CategoryOptions = ({validateWorkflowTriggerForm, field, categories, setSlideIndex}) => {
  return (
    <WorkflowBuilderOptionCard.GroupedListContainer>
      {categories.map((category, index) => {
        const isFirstRow = index < 2;
        const isNewCategoryRow = index % 2 === 0;
        const {icon, label} = WorkflowTriggerIdentifier.CATEGORY_ATTRIBUTES[category];
        return (
          <React.Fragment key={category}>
            {!isFirstRow && isNewCategoryRow && <WorkflowBuilderOptionCard.RowSeparator />}
            <WorkflowBuilderOptionCard.Paired
              form={validateWorkflowTriggerForm}
              field={field}
              text={label}
              icon={icon}
              index={index}
              onPress={() =>
                handlePressCategory({
                  form: validateWorkflowTriggerForm,
                  field,
                  category,
                  setSlideIndex,
                })
              }
            />
          </React.Fragment>
        );
      })}
    </WorkflowBuilderOptionCard.GroupedListContainer>
  );
};

const WorkflowTriggerCategorySlide = ({
  validateWorkflowTriggerForm,
  field,
  setSlideIndex,
  workflow,
}) => {
  const {results: filteredCategories, setQuery} = useSearch({
    initialQuery: '',
    items: WorkflowTriggerIdentifier.getFilteredCategory(workflow),
  });

  return (
    <WorkflowBuilderSlideSet.Slide>
      <WorkflowBuilderSlideSet.Title>Select a trigger category</WorkflowBuilderSlideSet.Title>
      <Space height={4} />
      <WorkflowBuilderSlideSet.Subtitle>
        Event that triggers an automation
      </WorkflowBuilderSlideSet.Subtitle>
      <Space height={24} />
      {/* TODO(dan) Hiding search for V1. Show once more triggers are added in later versions */}
      {false && (
        <React.Fragment>
          <SearchBar
            placeholder={'Search for trigger'}
            style={{flex: 1}}
            onChangeText={(text) => setQuery(text)}
          />
          <Space height={24} />
        </React.Fragment>
      )}
      <CategoryOptions
        validateWorkflowTriggerForm={validateWorkflowTriggerForm}
        field={field}
        categories={filteredCategories}
        setSlideIndex={setSlideIndex}
      />
    </WorkflowBuilderSlideSet.Slide>
  );
};

const TriggerProjectAndJobTypesContent = ({
  workflow,
  jobTypeIds,
  projectTypeIds,
  isAllProjectTypes,
}) => {
  const allJobTypesForProjectTypes = workflow.organization.allProjectTypes.flatMap((projectType) =>
    Object.values(projectType.jobTypes),
  );

  return (
    <React.Fragment>
      {projectTypeIds.map((id, index) => {
        const projectType = _.find(workflow.organization.allProjectTypes, ['id', id]);
        return (
          <Row style={{flex: 1, flexWrap: 'wrap', flexBasis: 'auto'}} key={id}>
            {index > 0 && <Space height={8} />}
            <React.Fragment>
              <ProjectTypeColorIndicator
                color={isAllProjectTypes ? colors.black : projectType.color}
              >
                •
              </ProjectTypeColorIndicator>
              <Space width={8} />
              <Text>{isAllProjectTypes ? 'ALL Project Types' : projectType.name}</Text>
              <Space width={8} />
              {(isAllProjectTypes ? allJobTypesForProjectTypes : projectType.jobTypes).map(
                (jobType) => {
                  const foundJobType = _.find(
                    jobTypeIds,
                    (jobTypeId) => jobTypeId === _.toString(jobType.id),
                  );

                  if (foundJobType) {
                    return (
                      <React.Fragment key={jobType.id}>
                        <ProjectJobTypeContainer>
                          <Text>{jobType.name}</Text>
                        </ProjectJobTypeContainer>
                        <Space width={8} />
                      </React.Fragment>
                    );
                  }
                  return;
                },
              )}
            </React.Fragment>
          </Row>
        );
      })}
    </React.Fragment>
  );
};

const TriggerProjectTypesAndJobTypes = ({form, field, workflow}) => {
  const projectTypeIds = _.get(form.values, `${field}.projectTypeIdList`);
  const isAllProjectTypes = projectTypeIds[0] === -1;

  const jobTypeIds = _.get(form.values, `${field}.jobTypeIdList`);

  return _.isEmpty(jobTypeIds) ? (
    <Row style={{flex: 1, flexWrap: 'wrap'}}>
      {isAllProjectTypes ? (
        <React.Fragment>
          <ProjectTypeColorIndicator color={colors.black}>•</ProjectTypeColorIndicator>
          <Space width={8} />
          <Text>ALL Project Types</Text>
        </React.Fragment>
      ) : (
        projectTypeIds.map((id, index) => {
          const projectType = _.find(workflow.organization.allProjectTypes, ['id', id]);
          return (
            <React.Fragment key={id}>
              {index > 0 && <Space width={8} />}
              <React.Fragment>
                <ProjectTypeColorIndicator color={projectType.color}>•</ProjectTypeColorIndicator>
                <Space width={8} />
                <Text>{projectType.name}</Text>
              </React.Fragment>
            </React.Fragment>
          );
        })
      )}
    </Row>
  ) : (
    <TriggerProjectAndJobTypesContent
      form={form}
      field={field}
      workflow={workflow}
      projectTypeIds={projectTypeIds}
      jobTypeIds={jobTypeIds}
      isAllProjectTypes={isAllProjectTypes}
    />
  );
};

const WorkflowTriggerIdentifierSlide = ({
  form,
  validateWorkflowTriggerForm,
  field,
  setSlideIndex,
  workflow,
}) => {
  const category = _.get(validateWorkflowTriggerForm.values, `${field}.triggerIdentifierCategory`);
  const label = _.get(WorkflowTriggerIdentifier.CATEGORY_ATTRIBUTES, `${category}.label`, '');
  const triggers = _.get(WorkflowTriggerIdentifier.CATEGORY_ATTRIBUTES, `${category}.triggers`, []);
  const filteredTriggers = Workflow.filterTriggers(workflow, {triggers});

  return (
    <WorkflowBuilderSlideSet.Slide>
      <Row style={{alignItems: 'center'}}>
        <TertiaryButton style={{paddingRight: 8}} onPress={() => setSlideIndex(0)}>
          <Icon source={Icon.ChevronLeft} size={16} color={colors.gray.tertiary} />
        </TertiaryButton>
        <Space width={8} />
        <WorkflowBuilderSlideSet.Title>{label}</WorkflowBuilderSlideSet.Title>
      </Row>
      <Space height={24} />
      {filteredTriggers.map((trigger, index) => {
        return (
          <React.Fragment key={trigger}>
            {index > 0 && <WorkflowBuilderOptionCard.RowSeparator />}
            <WorkflowBuilderOptionCard
              text={WorkflowTriggerIdentifier.getDisplay(trigger)}
              onPress={(text, data, value) => {
                resetWorkflowFormOnInput({validateWorkflowTriggerForm, form, field, trigger}, 0);
                setTimeout(() =>
                  handlePressTrigger({
                    form: validateWorkflowTriggerForm,
                    field,
                    trigger,
                    setSlideIndex,
                  }),
                );
              }}
            />
          </React.Fragment>
        );
      })}
    </WorkflowBuilderSlideSet.Slide>
  );
};

// We need to reset workflow on input when user selects a different kind of trigger
// fields don't bleed through if they wish to change to another trigger input.
// When a new identifer is selected we reset these fields, if the same one is selected,
// we reset it back to what was on the form
const resetWorkflowFormOnInput = ({validateWorkflowTriggerForm, form, field, trigger}) => {
  if (trigger !== _.get(form.values, 'workflowForm.triggerIdentifier')) {
    validateWorkflowTriggerForm.setFieldValue(`${field}.projectTypeIdList`, [-1]);
    validateWorkflowTriggerForm.setFieldValue(`${field}.jobTypeIdList`, []);
    validateWorkflowTriggerForm.setFieldValue(`${field}.tagIdList`, [-1]);
    validateWorkflowTriggerForm.setFieldValue(`${field}.triggerHour`, 7);
    validateWorkflowTriggerForm.setFieldValue(`${field}.triggerDaysBefore`, null);
  } else {
    validateWorkflowTriggerForm.setFieldValue(
      `${field}.projectTypeIdList`,
      _.get(form.values, `${field}.projectTypeIdList`),
    );
    validateWorkflowTriggerForm.setFieldValue(
      `${field}.jobTypeIdList`,
      _.get(form.values, `${field}.jobTypeIdList`),
    );
    validateWorkflowTriggerForm.setFieldValue(
      `${field}.triggerHour`,
      _.get(form.values, `${field}.triggerHour`),
    );
    validateWorkflowTriggerForm.setFieldValue(
      `${field}.triggerDaysBefore`,
      _.get(form.values, `${field}.triggerDaysBefore`),
    );
    validateWorkflowTriggerForm.setFieldValue(
      `${field}.tagIdList`,
      _.get(form.values, `${field}.tagIdList`),
    );
  }
};

const WorkflowTriggerInputsSlide = ({
  validateWorkflowTriggerForm,
  field,
  setSlideIndex,
  workflow,
  handleSubmitValidateWorkflowTrigger,
}) => {
  const triggerIdentifier = _.get(
    validateWorkflowTriggerForm.values,
    `${field}.triggerIdentifier`,
    '',
  );
  const triggerVariableIdentifier = _.get(
    validateWorkflowTriggerForm.values,
    `${field}.triggerVariableIdentifier`,
    '',
  );
  const projectTypeIdList = _.get(validateWorkflowTriggerForm.values, `${field}.projectTypeIdList`);
  const triggerDisplay = WorkflowTriggerIdentifier.getDisplay(triggerIdentifier, {
    triggerVariableIdentifier,
  });

  return (
    <WorkflowBuilderSlideSet.Slide>
      <Row style={{alignItems: 'center'}}>
        <TertiaryButton
          style={{paddingRight: 8}}
          onPress={() => {
            setSlideIndex(1);
          }}
        >
          <Icon source={Icon.ChevronLeft} size={16} color={colors.gray.tertiary} />
        </TertiaryButton>
        <Space width={8} />
        <Column>
          <WorkflowBuilderSlideSet.Label>Trigger</WorkflowBuilderSlideSet.Label>
          <Space height={4} />
          <WorkflowBuilderSlideSet.Title>{triggerDisplay}</WorkflowBuilderSlideSet.Title>
        </Column>
      </Row>
      <Space height={20} />
      <WorkflowTriggerInputs form={validateWorkflowTriggerForm} field={field} workflow={workflow} />
      <Space height={20} />
      <Button
        text={'Done'}
        isWidthOfContainer
        isDisabled={_.isEmpty(projectTypeIdList)}
        onPress={() => handleSubmitValidateWorkflowTrigger()}
      />
    </WorkflowBuilderSlideSet.Slide>
  );
};

const TagTitleDisplay = ({form, field, workflow, tagIdList}) => {
  const responsive = useResponsive();

  const isJobTypeRequiredWorkflow = WorkflowTriggerIdentifier.isJobTypeRequiredWorkflow(
    _.get(form.values, `${field}.triggerIdentifier`),
  );
  const triggerIdentifier = _.get(form.values, `${field}.triggerIdentifier`, '');
  const tags = workflow.organization.companySettings.tags.filter((tag) =>
    isJobTypeRequiredWorkflow
      ? [TagCategory.PROJECT, TagCategory.JOB].includes(tag.category)
      : tag.category === TagCategory.PROJECT,
  );
  return (
    <Row style={{flexWrap: 'wrap', width: responsive.mobile ? 300 : 370}}>
      <LabelText style={{marginBottom: 4}}>{`${
        isJobTypeRequiredWorkflow ? 'Job Tags' : 'Project Tags'
      }`}</LabelText>
      <Space width={4} />
      {tagIdList.includes(-1) ? (
        <React.Fragment>
          <TagPill
            tag={{
              name: 'All Tags',
              isArchived: false,
            }}
            isSmall
            hasDeleteButton={false}
          />
          <Space width={4} />
        </React.Fragment>
      ) : (
        tagIdList.map((tagId, index) => {
          const fetchedTag = tags.find((tag) => tag.id === tagId);
          return (
            <React.Fragment key={index}>
              <TagPill tag={fetchedTag} isSmall hasDeleteButton={false} />
              <Space width={4} />
            </React.Fragment>
          );
        })
      )}
      <LabelText style={{marginBottom: 4}}>
        {[
          WorkflowTriggerIdentifier.JOB_TAG_ADDED,
          WorkflowTriggerIdentifier.PROJECT_TAG_ADDED,
        ].includes(triggerIdentifier)
          ? 'Added'
          : 'Removed'}
      </LabelText>
    </Row>
  );
};

const getTriggerDisplay = ({form, field, timezone, triggerDisplay, workflow}) => {
  const triggerIdentifier = _.get(form.values, `${field}.triggerIdentifier`, '');
  const triggerHour = _.get(form.values, `${field}.triggerHour`, '');
  const triggerDaysBefore = _.get(form.values, `${field}.triggerDaysBefore`, '');
  const tagIdList = _.get(form.values, `${field}.tagIdList`, '');

  if (
    [
      WorkflowTriggerIdentifier.PROJECT_TAG_ADDED,
      WorkflowTriggerIdentifier.PROJECT_TAG_REMOVED,
      WorkflowTriggerIdentifier.JOB_TAG_ADDED,
      WorkflowTriggerIdentifier.JOB_TAG_REMOVED,
    ].includes(triggerIdentifier)
  ) {
    return <TagTitleDisplay form={form} field={field} workflow={workflow} tagIdList={tagIdList} />;
  }

  if (triggerDaysBefore || triggerDaysBefore === 0) {
    return `At ${Datetime.getFriendlyHourString(
      triggerHour,
      timezone,
      false,
    )}, ${triggerDaysBefore} Days ${triggerDisplay}`;
  } else {
    return triggerDisplay;
  }
};

const WorkflowTriggerDisplaySlide = ({form, field, setSlideIndex, workflow, isPreview}) => {
  const responsive = useResponsive();
  const cannotUpdateTwoStepsAtOnceErrorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'You may only edit one step at a time.',
  });
  const triggerIdentifier = _.get(form.values, `${field}.triggerIdentifier`, '');
  const triggerVariableIdentifier = _.get(form.values, `${field}.triggerVariableIdentifier`, '');
  const triggerDisplay = WorkflowTriggerIdentifier.getDisplay(triggerIdentifier, {
    triggerVariableIdentifier,
  });
  const tagIdList = _.get(form.values, `${field}.tagIdList`, '');
  const isJobTypeRequiredWorkflow = WorkflowTriggerIdentifier.isJobTypeRequiredWorkflow(
    _.get(form.values, `${field}.triggerIdentifier`),
  );
  const tags = workflow.organization.companySettings.tags.filter((tag) =>
    isJobTypeRequiredWorkflow
      ? [TagCategory.PROJECT, TagCategory.JOB].includes(tag.category)
      : tag.category === TagCategory.PROJECT,
  );

  return (
    <WorkflowBuilderSlideSet.Slide style={{paddingVertical: 16}}>
      <Row style={{alignItems: _.isEmpty(tagIdList) ? 'center' : 'flex-start'}}>
        <WorkflowBuilderSlideSet.Stamp>
          <Icon source={Icon.Bolt} size={18} color={colors.blue.accentDark} />
        </WorkflowBuilderSlideSet.Stamp>
        <Space width={16} />
        <Column>
          <WorkflowBuilderSlideSet.Label>Trigger</WorkflowBuilderSlideSet.Label>
          <Space height={4} />
          <WorkflowBuilderSlideSet.Title>
            {getTriggerDisplay({
              form,
              field,
              workflow,
              timezone: workflow?.organization.timezone,
              triggerDisplay,
            })}
          </WorkflowBuilderSlideSet.Title>
        </Column>
        {!isPreview && (
          <React.Fragment>
            <Space style={{flex: 1}} />
            <TertiaryButton
              onPress={() =>
                handlePressEdit({form, field, cannotUpdateTwoStepsAtOnceErrorToast, setSlideIndex})
              }
            >
              <Icon source={Icon.Pen} size={16} color={colors.gray.tertiary} />
            </TertiaryButton>
          </React.Fragment>
        )}
      </Row>
      <Space height={20} />
      <TriggerProjectTypesAndJobTypes form={form} field={field} workflow={workflow} />
      <Space height={18} />
      {!_.isEmpty(tagIdList) &&
        ![
          WorkflowTriggerIdentifier.PROJECT_TAG_ADDED,
          WorkflowTriggerIdentifier.PROJECT_TAG_REMOVED,
          WorkflowTriggerIdentifier.JOB_TAG_ADDED,
          WorkflowTriggerIdentifier.JOB_TAG_REMOVED,
        ].includes(triggerIdentifier) && (
          <React.Fragment>
            <Line />
            <Space height={18} />
            {tagIdList.includes(-1) ? (
              <Row style={{flexWrap: 'wrap', width: responsive.mobile ? 300 : 370}}>
                <React.Fragment>
                  <TagPill
                    tag={{
                      name: 'All Tags',
                      isArchived: false,
                    }}
                    isSmall
                    hasDeleteButton={false}
                  />
                  <Space width={4} />
                </React.Fragment>
              </Row>
            ) : (
              <Row style={{flexWrap: 'wrap', width: responsive.mobile ? 300 : 370}}>
                {tagIdList.map((tagId, index) => {
                  const fetchedTag = tags.find((tag) => tag.id === tagId);
                  return (
                    <React.Fragment key={index}>
                      <TagPill tag={fetchedTag} isSmall hasDeleteButton={false} />
                      <Space width={4} />
                    </React.Fragment>
                  );
                })}
              </Row>
            )}
            <Space height={18} />
          </React.Fragment>
        )}
    </WorkflowBuilderSlideSet.Slide>
  );
};

const WorkflowTriggerSelector = ({form, field, workflow, isPreview}) => {
  const isNew = !_.get(form.values, `${field}.triggerIdentifier`);
  const tabs = useTabs({initialIndex: isNew ? 0 : 3});
  const setSlideIndex = tabs.setSelectedIndex;

  const workflowForm = WorkflowForm.edit(workflow);
  const {form: validateWorkflowTriggerForm, handleSubmit: handleSubmitValidateWorkflowTrigger} =
    useValidateWorkflowTriggerMutation({
      workflowForm,
      onSuccess: () => handleSuccess({form, field, validateWorkflowTriggerForm, setSlideIndex}),
      onError: (errors) => console.log({errors}),
    });

  return (
    <WorkflowBuilderSlideSet tabs={tabs} loadingHeight={isNew ? null : SUMMARY_SLIDE_HEIGHT}>
      <WorkflowTriggerCategorySlide
        validateWorkflowTriggerForm={validateWorkflowTriggerForm}
        field={field}
        setSlideIndex={setSlideIndex}
        workflow={workflow}
      />
      <WorkflowTriggerIdentifierSlide
        form={form}
        validateWorkflowTriggerForm={validateWorkflowTriggerForm}
        field={field}
        setSlideIndex={setSlideIndex}
        workflow={workflow}
      />
      <WorkflowTriggerInputsSlide
        validateWorkflowTriggerForm={validateWorkflowTriggerForm}
        field={field}
        setSlideIndex={setSlideIndex}
        workflow={workflow}
        handleSubmitValidateWorkflowTrigger={handleSubmitValidateWorkflowTrigger}
      />
      <WorkflowTriggerDisplaySlide
        form={form}
        field={field}
        setSlideIndex={setSlideIndex}
        workflow={workflow}
        isPreview={isPreview}
      />
    </WorkflowBuilderSlideSet>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowTriggerSelector.fragment = gql`
  ${Workflow.filterTriggers.fragment}
  ${WorkflowTriggerInputs.fragment}
  ${WorkflowForm.edit.fragment}
  ${TagPill.fragment}

  fragment WorkflowTriggerSelector on Workflow {
    id
    triggerHour
    triggerDaysBefore
    organization {
      id
      timezone
      features {
        isEnabledSmsReceivedWorkflowTrigger: isEnabled(feature: "SMS_RECEIVED_WORKFLOW_TRIGGER")
        isEnabledPaymentsAutomationTrigger: isEnabled(feature: "PAYMENTS_AUTOMATION_TRIGGER")
        isEnabledAutomationSendProposalJobStatusStaffAssigned: isEnabled(
          feature: "AUTOMATION_SEND_PROPOSAL_JOB_STATUS_STAFF_ASSIGNED"
        )
      }
      companySettings {
        tags {
          id
          name
          emoji
          category
          isArchived
          ...TagPill
          ...TagDropdownInputField
        }
      }
      allProjectTypes: projectTypesForCategory(showHidden: true) {
        id
        name
        color
        jobTypes {
          id
          name
        }
      }
    }
    ...Workflow_filterTriggers
    ...WorkflowTriggerInputs
    ...WorkflowForm_edit
  }
`;

export default WorkflowTriggerSelector;
