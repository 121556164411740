// Libraries
import React from 'react';

// Supermove
import {ExternalLink, Form, Link, Mutation, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';

const Content = Styled.View`
  width: 100%;
  max-width: 600px;
  padding-top: 40px;
  padding-horizontal: ${(props) => (props.desktop ? 100 : 50)}px;
  max-width: 600px;
`;

const Logo = Styled.H1`
  margin-top: 40px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  letter-spacing: -2;
`;

const Title = Styled.H2`
  margin-top: 20px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Field = Styled.View`
  margin-top: 20px;
`;

const Label = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const Input = Styled.TextInput.H7`
  margin-top: 3px;
`;

const ValidationError = Styled.H7`
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const Actions = Styled.View`
  margin-top: 20px;
  flex-direction: row;
`;

const SubmitButton = Styled.Button`
  height: 40px;
  width: 140px;
`;

const SubmitText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const SubmitIndicator = Styled.Loading`
  margin-left: 5px;
`;

const Links = Styled.View`
  margin-vertical: 30px;
`;

const Row = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const NewUser = Styled.H6`
  margin-bottom: 2px;
  color: ${colors.gray.secondary};
`;

const NewVisitor = Styled.H6`
  ${fontWeight(700)}
`;

const SignIn = Styled.H6`
  color: ${colors.blue.interactive};
`;

const Website = Styled.H6`
  color: ${colors.blue.interactive};
  ${fontWeight(700)}
`;

const RegisterPage = () => {
  const responsive = useResponsive();
  const {params} = useNavigationDOM();

  return (
    <Form
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        organizationSlug: params.slug || '',
        position: params.position || '',
      }}
      onSubmit={(values) => {}}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        setMutationErrors,
        submitForm,
      }) => (
        <Mutation
          variables={{
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            organizationSlug: values.organizationSlug,
            position: values.position,
          }}
          mutation={RegisterPage.mutation}
          onCompleted={async ({createManager: {token, user, errors}}) => {
            submitForm();
            setMutationErrors(errors);
            if (token) {
              await Storage.setItem('token', token);
              await Storage.setItem('userId', user.id);
              window.location.href = '/';
            }
          }}
        >
          {(handleSubmit, {loading, error}) => (
            <ScrollView contentContainerStyle={{alignItems: 'center'}} style={{flex: 1}}>
              <Content {...responsive}>
                <Logo>Supermove</Logo>
                <Title>Create an account</Title>
                <Field>
                  <Label>Full Name</Label>
                  <Input
                    name={'firstName'}
                    placeholder={'Enter your full name'}
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.firstName && errors.firstName && (
                    <ValidationError>{errors.firstName}</ValidationError>
                  )}
                </Field>
                <Field>
                  <Label>Email</Label>
                  <Input
                    name={'email'}
                    placeholder={'Enter your email'}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email && (
                    <ValidationError>{errors.email}</ValidationError>
                  )}
                </Field>
                <Field>
                  <Label>Password</Label>
                  <Input
                    secureTextEntry
                    name={'password'}
                    placeholder={'Enter a password'}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.password && errors.password && (
                    <ValidationError>{errors.password}</ValidationError>
                  )}
                </Field>
                <Field>
                  <Label>Position</Label>
                  <Input
                    disabled={params.position}
                    name={'position'}
                    placeholder={'Enter your position'}
                    value={values.position}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onSubmitEditing={handleSubmit}
                  />
                  {touched.position && errors.position && (
                    <ValidationError>{errors.position}</ValidationError>
                  )}
                </Field>
                <Field>
                  <Label>Organization Code</Label>
                  <Input
                    disabled={params.slug}
                    name={'organizationSlug'}
                    placeholder={'Enter your organization code'}
                    value={values.organizationSlug}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onSubmitEditing={handleSubmit}
                  />
                  {touched.organizationSlug && errors.organizationSlug && (
                    <ValidationError>{errors.organizationSlug}</ValidationError>
                  )}
                </Field>
                <Actions>
                  <SubmitButton disabled={loading} onPress={handleSubmit}>
                    <SubmitText>Continue</SubmitText>
                    {loading && <SubmitIndicator size={'small'} color={colors.gray.border} />}
                  </SubmitButton>
                </Actions>
                <Links>
                  <Row>
                    <NewUser>Already have an account?</NewUser>
                    <Link to={'/login'}>
                      <SignIn>Sign in</SignIn>
                    </Link>
                  </Row>
                  <Row>
                    <NewVisitor>New to Supermove?</NewVisitor>
                    <ExternalLink url={'https://www.supermove.co/'}>
                      <Website>View our website</Website>
                    </ExternalLink>
                  </Row>
                </Links>
              </Content>
            </ScrollView>
          )}
        </Mutation>
      )}
    </Form>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
RegisterPage.propTypes = {};

RegisterPage.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
RegisterPage.mutation = gql`
  mutation RegisterPage(
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $password: String!,
    $organizationSlug: String!,
    $position: String!,
  ) {
    createManager(
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      password: $password,
      organizationSlug: $organizationSlug,
      position: $position,
    ) {
      ${gql.errors}
      user {
        id
      }
      token
    }
  }
`;

export default RegisterPage;
