// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

import ReorderSlotForm from '@shared/modules/Dispatch/forms/ReorderSlotForm';

const edit = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ slots }: { slots: any; }) => ... Remove this comment to see the full error message
  ({slots}) => ({
    reorderSlotForms: slots.map((slot: any) => ReorderSlotForm.edit(slot)),
  }),
  gql`
    ${ReorderSlotForm.edit.fragment}

    fragment ReorderSlotsForm_edit on Slot {
      id
      ...ReorderSlotForm_edit
    }
  `,
);

const toForm = ({reorderSlotForms}: any) => ({
  reorderSlotForms: reorderSlotForms.map((slot: any) => ReorderSlotForm.toForm(slot)),
});

const toMutation = ({reorderSlotForms}: any) => ({
  reorderSlotForms: reorderSlotForms.map((slot: any) => ReorderSlotForm.toMutation(slot)),
});

const ReorderSlotsForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderSlotsForm;
