// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';

const Container = Styled.View`
`;

const Section = Styled.View`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  padding-horizontal: 16px;
`;

const Title = Styled.Text`
  ${Typography.Label1}
`;

const Row = Styled.View`
  z-index: ${({index = 0}) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: ${({mobile}) => (mobile ? '8px' : '16px')};
`;

const JobNotesBlock = ({job}) => {
  const responsive = useResponsive();

  return (
    <Container>
      <Section>
        <Space height={12} />
        <Title>Notes</Title>
        <Space height={16} />
        <Row index={0} {...responsive}>
          <FieldValue empty={'None'} label={'Crew Notes'} value={job.additionalNotes} />
        </Row>
        <RowSpace {...responsive} />
        <Row index={2} {...responsive}>
          <FieldValue empty={'None'} label={'Dispatch Notes'} value={job.dispatchNotes} />
        </Row>
        <RowSpace {...responsive} />
        <Row index={1} {...responsive}>
          <FieldValue empty={'None'} label={'Office Notes'} value={job.officeNotes} />
        </Row>
        <Space height={16} />
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobNotesBlock.propTypes = {
  job: PropTypes.object.isRequired,
};

JobNotesBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobNotesBlock.fragment = gql`
  fragment JobNotesBlock on Job {
    id
    additionalNotes
    officeNotes
    dispatchNotes
  }
`;

export default JobNotesBlock;
