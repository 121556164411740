// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Loading, FlatList, Icon, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useModal, useToast} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Drawer from '@shared/design/components/Drawer';
import MediumModal from '@shared/design/components/Modal/MediumModal';
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import WorkflowRunStatus from '@shared/modules/Workflow/enums/WorkflowRunStatus';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import ManuallyTriggerWorkflowForm from '@shared/modules/Workflow/forms/ManuallyTriggerWorkflowForm';
import useManuallyTriggerWorkflowMutation from '@shared/modules/Workflow/hooks/useManuallyTriggerWorkflowMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const WorkflowRunStepAdditionalDetailsModalContainer = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Column = Styled.View`
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const PrimaryText = Styled.Text`
  ${Typography.Subheading};
`;

const SecondaryText = Styled.Text`
  ${Typography.Micro};
`;

const StatusText = Styled.Text`
  ${Typography.Micro};
  color: ${(props) => props.textColor};
`;

const LabelRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StepDot = Styled.View`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${colors.gray.tertiary};
  align-items: center;
  justify-content: center;
  z-index: ${(props) => 100 - props.index};
`;

const StepBar = Styled.View`
  top: 50%;
  left: -16px;
  width: 2px;
  height: 100%;
  background-color: ${colors.gray.tertiary};
  z-index: ${(props) => 99 - props.index};
`;

const WorkflowRunStepContainer = Styled.ButtonV2`
  padding-vertical: 12px;
  padding-horizontal: 16px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  cursor: default;
`;

const OrderText = Styled.Text`
  ${Typography.Body};
  color: white;
`;

const determineWorkflowStepStatus = (steps) => {
  for (const step of steps) {
    // If any step is these statuses, return that status, since they are the highest priority
    switch (step.status) {
      case WorkflowRunStatus.FAILED:
        return WorkflowRunStatus.FAILED;
      case WorkflowRunStatus.IN_PROGRESS:
        return WorkflowRunStatus.IN_PROGRESS;
      case WorkflowRunStatus.PENDING:
        return WorkflowRunStatus.PENDING;
      default:
        break;
    }
  }
  // Default to 'COMPLETED' if no other status is found
  return WorkflowRunStatus.COMPLETED;
};

const flattenAndProcessWorkflowData = (workflowRunSteps) => {
  // Group workflowRunSteps by workflowStep id so that we can process them together
  const groupedByWorkflowStep = workflowRunSteps.reduce((acc, runStep) => {
    const workflowStepId = runStep.workflowStep.id;
    if (!acc[workflowStepId]) {
      acc[workflowStepId] = [];
    }
    acc[workflowStepId].push(runStep);
    return acc;
  }, {});

  // Process each group and flatten data for the workflowStep
  return Object.entries(groupedByWorkflowStep).map(([_, runSteps]) => {
    return {
      id: runSteps[0].workflowStep.id,
      workflowStepActionKind: runSteps[0].workflowStep.workflowStepActionKind,
      order: runSteps[0].workflowStep.order,
      status: determineWorkflowStepStatus(runSteps),
      completedAt: runSteps[runSteps.length - 1].completedAt,
      inputMetadata: runSteps[0].inputMetadata,
      outputMetadata: runSteps[runSteps.length - 1].outputMetadata,
    };
  });
};

const WorkflowRunStepAdditionalDetailsModal = ({isOpen, handleClose, workflowStep}) => {
  return (
    <MediumModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Automation Run Step Metadata'}
      handlePressOutside={handleClose}
      primaryActionText={'Close'}
      handlePrimaryAction={handleClose}
      isScrollable
    >
      <WorkflowRunStepAdditionalDetailsModalContainer>
        <PrimaryText>Input Metadata</PrimaryText>
        <SecondaryText>{workflowStep.inputMetadata}</SecondaryText>
        <Space height={32} />
        <PrimaryText>Output Metadata</PrimaryText>
        <SecondaryText>{workflowStep.outputMetadata}</SecondaryText>
      </WorkflowRunStepAdditionalDetailsModalContainer>
    </MediumModal>
  );
};

const WorkflowRunStepDetails = ({workflowStep, isLast}) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const statusOption = WorkflowRunStatus.getStatusOption(workflowStep.status);
  const workflowStepActionKind = WorkflowStepActionKind.getWorkflowStepActionKindDisplayContent({
    workflowStepKind: workflowStep.workflowStepActionKind,
  });
  const parsedOutputMetadata = JSON.parse(workflowStep.outputMetadata);
  const stopIfConditionMessage = _.get(
    parsedOutputMetadata,
    'data.getShouldWorkflowStop.decision.reasons[0].message',
  );

  return (
    <Row>
      <StepDot index={workflowStep.order}>
        <OrderText>{workflowStep.order + 1}</OrderText>
      </StepDot>
      {!isLast && <StepBar index={workflowStep.order} />}
      <Space width={8} />
      <Column>
        <Space height={12} />
        <WorkflowRunStepContainer
          backgroundColor={statusOption.backgroundColor}
          borderColor={statusOption.textColor}
          onLongPress={handleOpen}
          activeOpacity={1}
        >
          <LabelRow>
            <Row>
              <Icon source={workflowStepActionKind.icon} size={16} color={colors.gray.secondary} />
              <Space width={8} />
              <PrimaryText>{workflowStepActionKind.label}</PrimaryText>
            </Row>
            <Space width={4} />
            <StatusText textColor={statusOption.textColor}>{statusOption.label}</StatusText>
          </LabelRow>
          {stopIfConditionMessage && (
            <React.Fragment>
              <Space height={4} />
              <SecondaryText>{`Reason: ${stopIfConditionMessage}`}</SecondaryText>
            </React.Fragment>
          )}
          {workflowStep.completedAt && (
            <React.Fragment>
              <Space height={4} />
              <SecondaryText>
                Completed at: {Datetime.convertToDisplayDatetime(workflowStep.completedAt)}
              </SecondaryText>
            </React.Fragment>
          )}
        </WorkflowRunStepContainer>
        <Space height={12} />
        <WorkflowRunStepAdditionalDetailsModal
          isOpen={isOpen}
          handleClose={handleClose}
          workflowStep={workflowStep}
        />
      </Column>
    </Row>
  );
};

const WorkflowRunDetailsDrawerFooter = ({handleClose, workflowRun, refetch}) => {
  const successManuallyTriggerWorkflow = useToast({
    ToastComponent: SuccessToast,
    message: `Workflow successfully triggered.`,
    isClosable: true,
  });
  const manuallyTriggerWorkflowForm = ManuallyTriggerWorkflowForm.new({
    projectId: workflowRun.projectId,
    workflowId: workflowRun.workflowId,
    jobId: workflowRun.jobId,
  });
  const {submitting, handleSubmit} = useManuallyTriggerWorkflowMutation({
    manuallyTriggerWorkflowForm,
    onSuccess: () => {
      confirmTriggerWorkflowModal.handleClose();
      handleClose();
      successManuallyTriggerWorkflow.handleToast();
      refetch();
    },
    onError: (error) => console.log(error),
  });
  const confirmTriggerWorkflowModal = useModal({name: 'Confirm Trigger Workflow Modal'});
  return (
    <React.Fragment>
      <Drawer.Footer
        primaryActionText={'Re-run Automation'}
        primaryAction={confirmTriggerWorkflowModal.handleOpen}
        secondaryAction={handleClose}
        secondaryActionText={'Close'}
      />
      <ActionModal
        isOpen={confirmTriggerWorkflowModal.isOpen}
        handleClose={confirmTriggerWorkflowModal.handleClose}
        title={'Re-run automation?'}
        handlePressOutside={confirmTriggerWorkflowModal.handleClose}
        primaryActionText={'Run Automation'}
        handlePrimaryAction={handleSubmit}
        handleSecondaryAction={confirmTriggerWorkflowModal.handleClose}
        secondaryActionText={'Cancel'}
        isSubmitting={submitting}
        isDisabled={submitting}
      />
    </React.Fragment>
  );
};

const WorkflowRunDetailsDrawer = ({handleClose, isOpen, workflowRun, refetch}) => {
  const {data, loading} = useQuery(WorkflowRunDetailsDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      workflowRunId: workflowRun.id,
    },
    skip: !isOpen,
  });
  return (
    <Drawer isOpen={isOpen} handleClose={handleClose}>
      <Drawer.Header headerText={'Automation Run Details'} isClosable handleClose={handleClose} />
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          const workflowStepsWithStatus = flattenAndProcessWorkflowData(data.workflowRunSteps);
          return (
            <React.Fragment>
              <Drawer.Body bodyScrollStyle={{flex: 1}}>
                <ScrollView style={{flex: 1}}>
                  <FlatList
                    data={workflowStepsWithStatus}
                    keyExtractor={(workflowStep) => workflowStep.id}
                    renderItem={({item: workflowStep}) => (
                      <WorkflowRunStepDetails
                        workflowStep={workflowStep}
                        isLast={workflowStep.order + 1 === workflowStepsWithStatus.length}
                      />
                    )}
                  />
                </ScrollView>
              </Drawer.Body>
              <WorkflowRunDetailsDrawerFooter
                handleClose={handleClose}
                workflowRun={workflowRun}
                refetch={refetch}
              />
            </React.Fragment>
          );
        }}
      </Loading>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowRunDetailsDrawer.fragment = gql`
  fragment WorkflowRunDetailsDrawer on WorkflowRun {
    id
    projectId
    jobId
    workflowId
  }
`;

WorkflowRunDetailsDrawer.query = gql`
  query WorkflowRunDetailsDrawer(
    $workflowRunId: Int!
  ) {
    ${gql.query}
    workflowRunSteps(workflowRunId: $workflowRunId) {
      id
      actionType
      status
      completedAt
      order
      inputMetadata
      outputMetadata
      workflowStep {
        id
        workflowStepActionKind
        order
      }
    }
  }
`;

export default WorkflowRunDetailsDrawer;
