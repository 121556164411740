// Libraries
import _ from 'lodash';

// Supermove
import {generated, gql} from '@supermove/graphql';
import {InputMaybe} from '@supermove/graphql/src/generated/graphql';

const _new = ({
  name,
}: Pick<generated.DashboardCategoryForm, 'name'>): generated.DashboardCategoryForm => ({
  dashboardCategoryId: null,
  name: name || '',
  index: 0,
  isDeleted: false,
  replaceWithDashboardCategoryId: undefined,
});

export const DashboardCategoryFormEdit = gql`
  fragment DashboardCategoryFormEdit on DashboardCategory {
    id
    name
    index
    isDeleted
  }
`;

const edit = (dashboardCategory: generated.DashboardCategoryFormEditFragment) => {
  return {
    dashboardCategoryId: _.toNumber(dashboardCategory.id),
    name: dashboardCategory.name,
    index: dashboardCategory.index,
    isDeleted: dashboardCategory.isDeleted,
    replaceWithDashboardCategoryId: undefined as InputMaybe<number> | undefined,
  };
};

const toForm = (
  dashboardCategory: ReturnType<typeof edit> | ReturnType<typeof _new>,
): generated.DashboardCategoryForm => ({
  dashboardCategoryId: dashboardCategory.dashboardCategoryId
    ? _.toNumber(dashboardCategory.dashboardCategoryId)
    : null,
  name: dashboardCategory.name,
  index: dashboardCategory.index,
  replaceWithDashboardCategoryId: dashboardCategory.replaceWithDashboardCategoryId,
  isDeleted: dashboardCategory.isDeleted,
});

const toMutation = (
  dashboardCategoryForm: generated.DashboardCategoryForm,
): generated.DashboardCategoryForm => ({
  dashboardCategoryId: dashboardCategoryForm.dashboardCategoryId,
  name: dashboardCategoryForm.name,
  index: dashboardCategoryForm.index,
  replaceWithDashboardCategoryId: dashboardCategoryForm.replaceWithDashboardCategoryId,
  isDeleted: dashboardCategoryForm.isDeleted,
});

const DashboardCategoryForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default DashboardCategoryForm;
