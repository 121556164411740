// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import InventoryRoomsList from '@shared/modules/Inventory/Summary/components/InventoryRoomsList';
import InventorySection from '@shared/modules/Inventory/Summary/components/InventorySection';

type OwnProps = {
  isPreview?: boolean;
  isDisabled?: boolean;
  showNotes?: boolean;
  showWeight?: boolean;
  showVolume?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DocumentV2SurveyItems.defaultProps;

// @ts-expect-error TS(7022): 'DocumentV2SurveyItems' implicitly has type 'any' ... Remove this comment to see the full error message
const DocumentV2SurveyItems = ({
  isPreview,
  job,
  isDisabled,
  showNotes,
  showWeight,
  showVolume,
}: Props) => {
  return (
    <InventorySection icon={Icon.Archive} title={'Room items summary'}>
      <InventoryRoomsList
        isDisabled={isDisabled}
        inventory={isPreview ? MOCK_INVENTORY_DATA : job.project.inventory}
        showNotes={showNotes}
        showWeight={showWeight}
        showVolume={showVolume}
        isCartonsVisible={false}
      />
    </InventorySection>
  );
};
DocumentV2SurveyItems.defaultProps = {
  isPreview: false,
  isDisabled: false,
  showNotes: false,
  showWeight: true,
  showVolume: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2SurveyItems.fragment = gql`
  ${InventoryRoomsList.fragment}

  fragment DocumentV2SurveyItems on Job {
    id
    project {
      id
      inventory {
        id
        ...InventoryRoomsList
      }
    }
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_INVENTORY_DATA = {
  id: '1',
  rooms: [
    {
      id: '1',
      name: 'Room',
      description: 'Description',
      collection: {
        id: '1',
        leaveCount: 0,
        takeCount: 0,
        totalVolume: 0,
        totalWeight: 0,
        filteredItems: [],
      },
    },
  ],
};

export default DocumentV2SurveyItems;
