// Supermove
import {Datetime} from '@supermove/utils';

const _new = ({projectId, containerIds, outDate}: any) => ({
  projectId,
  containerIds,
  outDate: outDate || null,
});

const toForm = ({projectId, containerIds, outDate}: any) => ({
  projectId,
  containerIds,
  outDate: Datetime.toFormDate(outDate),
});

const toMutation = ({projectId, containerIds, outDate}: any) => {
  return {
    projectId,
    containerIds,
    outDate: outDate ? Datetime.toMutationDate(outDate) : null,
  };
};

const UpdateContainerOutDatesForProjectForm = {
  new: _new,
  toForm,
  toMutation,
};

export default UpdateContainerOutDatesForProjectForm;
