// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import LocationForm from '@shared/modules/Location/forms/LocationForm';

const _new = ({organizationId, userId}: any) => ({
  warehouseId: null,
  organizationId,
  name: '',
  locationForm: LocationForm.new(),
  notes: '',
  isDefault: false,
  createdById: userId,
  updatedById: userId,
});

const edit = withFragment(
  (warehouse, {userId}) => ({
    warehouseId: (warehouse as any).id,
    organizationId: (warehouse as any).organizationId,
    name: (warehouse as any).name,
    locationForm: LocationForm.edit((warehouse as any).location),
    notes: (warehouse as any).notes,
    isDefault: (warehouse as any).isDefault,
    isDeleted: (warehouse as any).isDeleted,
    createdById: (warehouse as any).createdById,
    updatedById: userId,
  }),
  gql`
    ${LocationForm.edit.fragment}
    fragment WarehouseForm_edit on Warehouse {
      id
      organizationId
      name
      notes
      isDeleted
      isDefault
      createdById
      updatedById
      location {
        id
        ...LocationForm_edit
      }
    }
  `,
);

const toForm = ({
  warehouseId,
  organizationId,
  name,
  locationForm,
  notes,
  isDeleted,
  isDefault,
  createdById,
  updatedById,
}: any) => ({
  warehouseId,
  organizationId,
  name,
  locationForm: LocationForm.toForm(locationForm),
  notes,
  isDeleted,
  isDefault,
  createdById,
  updatedById,
});

const toMutation = ({
  warehouseId,
  organizationId,
  name,
  locationForm,
  notes,
  isDeleted,
  isDefault,
  createdById,
  updatedById,
}: any) => ({
  warehouseId,
  organizationId,
  name,
  locationForm: LocationForm.toMutation(locationForm),
  notes,
  isDeleted,
  isDefault,
  createdById,
  updatedById,
});

const WarehouseForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default WarehouseForm;
