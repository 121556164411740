// Libraries
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useToggle} from '@supermove/hooks';

// App
import EditPanel from '@shared/design/components/Panel/EditPanel';
import CapacityCalendarSettingForm from '@shared/modules/CapacityCalendarSetting/forms/CapacityCalendarSettingForm';
import useUpsertCapacityCalendarSettings from '@shared/modules/CapacityCalendarSetting/hooks/useUpsertCapacityCalendarSettings';
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';
import useUpdateSettingsVisibleCalendarStatusesMutation from '@shared/modules/Settings/hooks/useUpdateSettingsVisibleCalendarStatusesMutation';
import OrganizationSettingsCapacity from 'modules/Organization/Settings/CapacityCalendar/components/OrganizationSettingsCapacity';
import CalendarStatusesPanel from 'modules/Organization/Settings/Moves/components/CalendarStatusesPanel';

const CalendarStatuses = ({viewer}) => {
  const {settings} = viewer.viewingOrganization;
  const isViewingChildBranch = !viewer.viewingOrganization.isPrimary;
  const editCalendarStatusesToggle = useToggle();
  const settingsForm = SettingsForm.edit(settings);
  const {form, submitting, handleSubmit} = useUpdateSettingsVisibleCalendarStatusesMutation({
    settingsForm,
    onSuccess: editCalendarStatusesToggle.handleToggleOff,
    onError: (errors) => console.log({errors}),
  });
  return (
    <EditPanel
      HeaderComponent={CalendarStatusesPanel.Header}
      headerComponentProps={{
        isEditing: editCalendarStatusesToggle.isOn,
        handleEdit: editCalendarStatusesToggle.handleToggleOn,
        isViewingChildBranch,
      }}
      BodyComponent={CalendarStatusesPanel}
      bodyComponentProps={{organization: viewer.viewingOrganization, isViewingChildBranch}}
      EditBodyComponent={CalendarStatusesPanel.Edit}
      editBodyComponentProps={{form, field: 'settingsForm.visibleCalendarStatuses'}}
      isEditing={editCalendarStatusesToggle.isOn}
      handleEdit={editCalendarStatusesToggle.handleToggleOn}
      handleClose={editCalendarStatusesToggle.handleToggleOff}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
    />
  );
};

const Capacity = ({viewer, refetch}) => {
  const {viewingOrganization} = viewer;
  const {capacityCalendarSetting} = viewingOrganization;
  const editCapacityToggle = useToggle();
  const capacityCalendarSettingForm = CapacityCalendarSettingForm.edit(capacityCalendarSetting);
  const {handleSubmit, submitting, form} = useUpsertCapacityCalendarSettings({
    capacityCalendarSettingForm,
    onSuccess: () => {
      refetch();
      editCapacityToggle.handleToggleOff();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  return (
    <EditPanel
      HeaderComponent={OrganizationSettingsCapacity.PanelHeader}
      headerComponentProps={{
        isEditing: editCapacityToggle.isOn,
        handleEdit: editCapacityToggle.handleToggleOn,
      }}
      BodyComponent={OrganizationSettingsCapacity.Panel}
      bodyComponentProps={{organization: viewingOrganization}}
      EditBodyComponent={OrganizationSettingsCapacity.EditPanel}
      editBodyComponentProps={{form, organization: viewingOrganization}}
      isEditing={editCapacityToggle.isOn}
      handleEdit={editCapacityToggle.handleToggleOn}
      handleClose={editCapacityToggle.handleToggleOff}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
    />
  );
};

const OrganizationSettingsCalendarsPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsCalendarsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Loading loading={loading}>
      {() => (
        <React.Fragment>
          <CalendarStatuses viewer={data.viewer} />
          <Space height={16} />
          <Capacity viewer={data.viewer} refetch={refetch} />
        </React.Fragment>
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCalendarsPage.query = gql`
  ${CalendarStatusesPanel.fragment}
  ${CapacityCalendarSettingForm.edit.fragment}
  ${OrganizationSettingsCapacity.Panel.fragment}
  ${SettingsForm.edit.fragment}
  query OrganizationSettingsCalendarsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        isPrimary
        capacityCalendarSetting {
          id
          ...CapacityCalendarSettingForm_edit
        }
        settings {
          id
          ...SettingsForm_edit
        }
        ...CalendarStatusesPanel
        ...OrganizationSettingsCapacity_Panel
      }
    }
  }
`;

export default OrganizationSettingsCalendarsPage;
