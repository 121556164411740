// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CreateTimesheetBlockForm from '@shared/modules/Timesheet/forms/CreateTimesheetBlockForm';

const getInitialTimesheetBillableEntriesForJob = ({job}: any) => {
  const testerBlock = _.last(_.get(job, 'timesheetBillableEntries.0.timesheetBlocks'));
  const isAllSameEndTime = job.timesheetBillableEntries.every((entry: any) => {
    const lastBlock = _.last(entry.timesheetBlocks);
    return (lastBlock as any)?.rangeTo === (testerBlock as any)?.rangeTo;
  });
  if (isAllSameEndTime) {
    return job.timesheetBillableEntries;
  }
  return [];
};

const getCreateTimesheetBlockForms = ({timesheetBillableEntries}: any) => {
  const timesheetBillableEntry = timesheetBillableEntries[0];
  const timesheetBlock = _.last(timesheetBillableEntry?.timesheetBlocks);
  const rangeFromDate = timesheetBillableEntry?.timesheetBlocksDateRange?.end || '';
  return [
    CreateTimesheetBlockForm.new({rangeFrom: (timesheetBlock as any)?.rangeTo, rangeFromDate}),
  ];
};

const newForJob = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ job }: { job: any; }) => { ti... Remove this comment to see the full error message
  ({job}) => {
    const timesheetBillableEntries = getInitialTimesheetBillableEntriesForJob({job});
    return {
      timesheetBillableEntryIds: timesheetBillableEntries.map((entry: any) => entry.id),
      createTimesheetBlockForms: getCreateTimesheetBlockForms({timesheetBillableEntries}),
    };
  },
  gql`
    fragment CreateTimesheetBillableBlocksForm_newForJob on Job {
      id
      timesheetBillableEntries {
        id
        timesheetBlocksDateRange {
          end
        }
        timesheetBlocks {
          id
          rangeTo
        }
      }
    }
  `,
);

const toForm = ({timesheetBillableEntryIds, createTimesheetBlockForms}: any) => ({
  timesheetBillableEntryIds,
  createTimesheetBlockForms: createTimesheetBlockForms.map(CreateTimesheetBlockForm.toForm),
});

const toMutation = ({timesheetBillableEntryIds, createTimesheetBlockForms}: any) => ({
  timesheetBillableEntryIds,
  createTimesheetBlockForms: createTimesheetBlockForms.map(CreateTimesheetBlockForm.toMutation),
});

const CreateTimesheetBillableBlocksForm = {
  newForJob,
  toForm,
  toMutation,
};

export default CreateTimesheetBillableBlocksForm;
