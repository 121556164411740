// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  border-radius: 5px;
  background-color: ${(props) => colors.alpha(props.color, 0.15)};
`;

const Text = Styled.H7`
  margin-left: 6px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const JobTestBadge = () => {
  return (
    <Container color={colors.purple.hover}>
      <Icon
        color={colors.purple.hover}
        size={Icon.Sizes.Large}
        source={Icon.GraduationCap}
        style={{
          width: 20,
          marginBottom: 2,
        }}
      />
      <Text>Training</Text>
    </Container>
  );
};

export default JobTestBadge;
