// Libraries
import React from 'react';

// App
import {Emoji, Modal, Styled} from '@supermove/components';
import {User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// Components
import DeactivateEmployeeMutation from './DeactivateEmployeeMutation';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

const Indicator = Styled.Loading`
  margin-left: 5px;
`;

const DeactivateEmployeeModal = ({user, refetch, trigger}) => {
  return (
    <Modal onClose={refetch} trigger={trigger}>
      {({handleClose}) => (
        <Container pointerEvents={'box-none'}>
          <Wrapper width={350}>
            <Circle>
              <Emoji component={EmojiText} name={'wastebasket'} />
            </Circle>
            <Content>
              <Title>Delete Account</Title>
              <Subtitle>
                {`Are you sure you want to delete the account for ${User.getFullName(user)}?`}
              </Subtitle>
              <Actions>
                <Button color={colors.gray.tertiary} onPress={handleClose}>
                  <Text>Cancel</Text>
                </Button>
                <ButtonSpace />
                <DeactivateEmployeeMutation userId={user.id} onSuccess={handleClose}>
                  {({loading, handleSubmit}) => (
                    <Button color={colors.red.warning} onPress={handleSubmit}>
                      {loading ? (
                        <Indicator size={'small'} colors={colors.gray.border} />
                      ) : (
                        <Text>Confirm</Text>
                      )}
                    </Button>
                  )}
                </DeactivateEmployeeMutation>
              </Actions>
            </Content>
          </Wrapper>
        </Container>
      )}
    </Modal>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
DeactivateEmployeeModal.propTypes = {};

DeactivateEmployeeModal.defaultProps = {};

export default DeactivateEmployeeModal;
