/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useModal, useNavigationDOM, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CollectionMetrics from '@shared/modules/Inventory/Summary/components/CollectionMetrics';
import CloseButton from 'components/CloseButton';
import CreateRoomModal from 'modules/Room/components/CreateRoomModal';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  flex: 1;
  align-self: center;
  max-width: 1120px;
  width: 100%;
  padding-horizontal: 10px;
`;

const Header = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Info = Styled.View`
  flex: 1;
`;

const Name = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const EditInventoryHeader = ({inventory}) => {
  return (
    <Header>
      <Space height={30} />
      <Row>
        <Space width={200}>
          <CloseButton />
        </Space>
        <Info>
          <Name>{'Inventory'}</Name>
          <Space height={10} />
          <Title>Inventory</Title>
        </Info>
        <CollectionMetrics collection={inventory.collection} />
      </Row>
      <Space height={10} />
      <Line />
    </Header>
  );
};

const Columns = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Sidebar = Styled.View`
  width: 180px;
`;

const Button = Styled.Touchable`
  justify-content: center;
  height: 40px;
  width: 180px;
  padding-horizontal: 20px;
  background-color: ${(props) => (props.isSelected ? colors.blue.interactive : colors.white)};
  border-radius: 8px;
`;

const Text = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const EditInventorySidebar = ({inventory}) => {
  const {navigator} = useNavigationDOM();
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <Sidebar>
      <Space height={20} />
      <Button isSelected onPress={handleOpen}>
        <Text>+ Add New Room</Text>
      </Button>
      <Space height={10} />
      {inventory.rooms.map((room) => (
        <React.Fragment key={room.id}>
          <Button
            isSelected={false}
            onPress={() => {
              navigator.push(`/inventories/${inventory.uuid}/rooms/${room.uuid}/edit`);
            }}
          >
            <Text>{room.name}</Text>
          </Button>
          <Space height={10} />
        </React.Fragment>
      ))}
      <CreateRoomModal
        isOpen={isOpen}
        inventory={inventory}
        handleClose={handleClose}
        onSuccess={({room}) => {
          navigator.push(`/inventories/${inventory.uuid}/rooms/${room.uuid}/edit`);
        }}
      />
    </Sidebar>
  );
};

const EmptyContainer = Styled.View`
  flex: 1;
  align-items: center;
  padding-vertical: 200px;
`;

const EmptyTitle = Styled.H7`
  color: ${colors.gray.primary};
  text-align: center;
`;

const EmptySubtitle = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const EditInventoryContent = ({inventory}) => {
  const {navigator} = useNavigationDOM();

  useEffect(() => {
    if (inventory.rooms.length === 0) {
      return;
    }

    const room = _.first(inventory.rooms);
    navigator.replace(`/inventories/${inventory.uuid}/rooms/${room.uuid}/edit`);
  }, [inventory, navigator]);

  return (
    <EmptyContainer>
      <EmptyTitle>You don't have any rooms yet...</EmptyTitle>
      <EmptySubtitle>Press "Add New Room" to start!</EmptySubtitle>
    </EmptyContainer>
  );
};

const EditInventoryPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(EditInventoryPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
  });

  return (
    <Container>
      <Loading loading={loading}>
        {() => (
          <Content>
            <EditInventoryHeader inventory={data.inventory} />
            <Columns>
              <EditInventorySidebar inventory={data.inventory} />
              <Space width={20} />
              <EditInventoryContent inventory={data.inventory} />
            </Columns>
          </Content>
        )}
      </Loading>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditInventoryPage.query = gql`
  ${CollectionMetrics.fragment}
  ${CreateRoomModal.fragment}

  query EditInventoryPage($uuid: String!) {
    ${gql.query}
    inventory(uuid: $uuid) {
      id
      uuid
      collection {
        id
        ...CollectionMetrics
      }
      rooms {
        id
        uuid
        name
      }
      ...CreateRoomModal
    }
  }
`;

export default EditInventoryPage;
