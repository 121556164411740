// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Datetime, withFragment} from '@supermove/utils';

// App

const getCalendarStatusColor = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ status }: { status: any; }) =... Remove this comment to see the full error message
  ({status}) => {
    switch (status.kind) {
      case 'READY':
      case 'IN_PROGRESS':
        return colors.gray.primary;
      case 'INCOMPLETE':
      case 'NOT_FINAL':
        return colors.red.warning;
      case 'COMPLETE':
      case 'FINAL':
        return colors.green.status;
      default:
        return colors.Pink600;
    }
  },
  gql`
    fragment Day_getCalendarStatusIcon on Day {
      status {
        kind
      }
    }
  `,
);

const getCalendarStatusIcon = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ status }: { status: any; }) =... Remove this comment to see the full error message
  ({status}) => {
    switch (status.kind) {
      case 'NOT_READY':
        return Icon.Circle;
      case 'READY':
      case 'IN_PROGRESS':
        return Icon.Check;
      case 'INCOMPLETE':
      case 'NOT_FINAL':
        return Icon.Unlock;
      case 'COMPLETE':
      case 'FINAL':
        return Icon.Lock;
      default:
        return '';
    }
  },
  gql`
    fragment Day_getCalendarStatusIcon on Day {
      status {
        kind
      }
    }
  `,
);

const getDisplayDate = withFragment(
  (day) => {
    const displayDate = day
      ? Datetime.convertToDisplayDate((day as any).value, Datetime.DISPLAY_MONTH_DAY)
      : 'TBD';

    return displayDate;
  },
  gql`
    fragment Day_getDisplayDate on Day {
      value
    }
  `,
);

const Day = {
  getCalendarStatusColor,
  getCalendarStatusIcon,
  getDisplayDate,
};

export default Day;
