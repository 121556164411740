// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useRef, useResponsive, useToast, ResponsiveType} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Callout from '@shared/design/components/Callout';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import IconButton from '@shared/design/components/IconButton';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import {JobTipsFormTypeForm} from '@shared/modules/Billing/forms/JobTipsForm';
import ProjectTipsForm, {ProjectTipsFormType} from '@shared/modules/Billing/forms/ProjectTipsForm';
import useUpdateProjectJobTipsMutation from '@shared/modules/Billing/hooks/useUpdateProjectJobTipsMutation';
import Line from 'modules/App/components/Line';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Row = Styled.View`
  flex-direction: row;
  align-items: flex-start;
`;

const BillTipLabel = Styled.Text<{responsive: ResponsiveType}>`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
  padding-top: ${({responsive}) => (responsive.desktop ? 10 : 12)}px;
`;

const TotalTipContainer = Styled.View`
  padding-horizontal: 24px;
  paddingBottom: 24px;
`;

const TotalTipLabel = Styled.Text`
  ${Typography.Responsive.Label}
`;

const ChangesCallout = () => {
  return (
    <Callout
      text={'Any changes to the tip amounts will be evenly split among the movers on the job.'}
    />
  );
};

const LoadingComponent = () => {
  return (
    <React.Fragment>
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Button} />
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Button} />
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Button} />
    </React.Fragment>
  );
};

interface BillTipProps {
  form: any;
  field: string;
}

const JobBillTip = ({form, field}: BillTipProps) => {
  const {displayName} = _.get(form.values, field);
  const amountField = `${field}.billTipForm.amount`;
  const ref = useRef<HTMLInputElement>(null);
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <Space height={16} />
      <Row>
        <BillTipLabel responsive={responsive} numberOfLines={1}>
          {displayName}
        </BillTipLabel>
        <Space flex={1} style={{minWidth: 16}} />
        <FieldInput
          {...form}
          name={amountField}
          component={CurrencyInput}
          isResponsive
          input={{
            innerRef: ref,
            component: FieldInput.TextInput,
            placeholder: 'Enter amount',
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
          }}
          style={{width: 120}}
        />
        <Space width={8} />
        <IconButton
          source={IconButton.SOURCE.Eraser}
          onPress={() => {
            form.setFieldValue(amountField, '');
            ref.current && ref.current.focus();
          }}
          buttonStyle={{marginTop: responsive.desktop ? 6 : 12}}
        />
      </Row>
    </React.Fragment>
  );
};

const TotalTip = ({form, field}: BillTipProps) => {
  const responsive = useResponsive();
  const jobTipsForms = _.get(form.values, `${field}.jobTipsForms`);
  const totalCents = jobTipsForms.reduce((result: number, jobTipsForm: JobTipsFormTypeForm) => {
    const dollarString = jobTipsForm.billTipForm.amount;
    return result + (Currency.toMutation(dollarString) || 0);
  }, 0);

  return (
    <TotalTipContainer>
      <Line />
      <Space height={12} />
      <Row>
        <TotalTipLabel responsive={responsive}>Total Tip</TotalTipLabel>
        <Space flex={1} style={{minWidth: 16}} />
        <TotalTipLabel responsive={responsive}>{Currency.toForm(totalCents)}</TotalTipLabel>
      </Row>
    </TotalTipContainer>
  );
};

interface AddTipContentProps {
  project: any;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const AddTipContent = ({project, isOpen, handleClose, refetch}: AddTipContentProps) => {
  const projectTipsForm: ProjectTipsFormType = ProjectTipsForm.edit(project);
  const successToast = useToast({
    message: 'Tip added.',
    ToastComponent: SuccessToast,
  });
  const {form, handleSubmit, submitting} = useUpdateProjectJobTipsMutation({
    projectTipsForm,
    onSuccess: () => {
      successToast.handleToast();
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Add Tip'}
      primaryActionText={'Save'}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      isUsingLoadingComponent
      bodyFooterComponent={<TotalTip form={form} field={'projectTipsForm'} />}
    >
      <ChangesCallout />
      {form.values.projectTipsForm.jobTipsForms.map(
        // @ts-expect-error incompatible types
        (jobTipsForm: JobTipsFormTypeForm, index: number) => {
          return (
            <JobBillTip
              key={jobTipsForm.jobId}
              form={form}
              field={`projectTipsForm.jobTipsForms.${index}`}
            />
          );
        },
      )}
    </DrawerWithAction>
  );
};

interface AddTipDrawerProps {
  projectUuid: string;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const AddTipDrawer = ({projectUuid, isOpen, handleClose, refetch}: AddTipDrawerProps) => {
  const {data, loading} = useQuery(AddTipDrawer.query, {
    fetchPolicy: 'network-only',
    variables: {projectUuid},
    skip: !isOpen,
  });

  return (
    <Loading
      loading={!data || loading}
      as={() => {
        return (
          <DrawerWithAction.LoadingComponent
            isOpen={isOpen}
            headerText={'Add Tip'}
            handleClose={handleClose}
          >
            <ChangesCallout />
            <LoadingComponent />
          </DrawerWithAction.LoadingComponent>
        );
      }}
    >
      {() => (
        <AddTipContent
          project={data.project}
          isOpen={isOpen}
          handleClose={handleClose}
          refetch={refetch}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddTipDrawer.query = gql`
  ${ProjectTipsForm.edit.fragment}
  query AddTipDrawer($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      ...ProjectTipsForm_edit
    }
  }
`;

export default AddTipDrawer;
