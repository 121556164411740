// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization, Payment, PaymentMethod} from '@supermove/models';

// Components
import FieldInput from '@shared/design/components/Field/FieldInput';

const FieldsContainer = Styled.View`
  z-index: 100;
`;

const getPaymentMethodOptions = (organization) => {
  if (organization.features.isEnabledCustomPaymentMethods) {
    return PaymentMethod.getOfficePaymentMethodOptions({organization});
  }
  const paymentMethods = Organization.getOfficePaymentMethods(organization);
  return Payment.getMethodDropdownOptions({methods: paymentMethods});
};

const PaymentMethodDropdown = ({form, field, index, organization}) => {
  const options = getPaymentMethodOptions(organization);
  return organization.features.isEnabledCustomPaymentMethods ? (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={`${field}.paymentMethodId`}
      label={'Payment Method'}
      isResponsive
      isRequired
      input={{
        options,
        placeholder: 'Choose payment method',
        setFieldValue: form.setFieldValue,
        onChangeValue: (value, option) => {
          form.setFieldValue(`${field}.method`, option.kind);
          form.setFieldValue(`${field}.paymentMethodId`, value);
        },
        style: {width: '100%'},
        isPortaled: true,
      }}
    />
  ) : (
    <FieldInput
      {...form}
      index={index}
      component={DropdownInput}
      name={`${field}.method`}
      label={'Payment Method'}
      isRequired
      input={{
        options,
        placeholder: 'Choose payment method',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const RecordPaymentFields = ({form, field, organization}) => {
  return (
    <FieldsContainer>
      <FieldInput
        {...form}
        index={0}
        name={`${field}.name`}
        label={'Payment Name'}
        isRequired
        input={{
          placeholder: 'Payment for Invoice',
          autoFocus: true,
        }}
      />
      <Space height={8} />
      <FieldInput
        {...form}
        index={1}
        name={`${field}.amount`}
        label={'Payment Amount'}
        isRequired
        input={{
          placeholder: 'Payment amount',
          style: {width: '100%'},
        }}
      />
      <Space height={8} />
      <PaymentMethodDropdown form={form} field={field} index={2} organization={organization} />
      <Space height={8} />
      <FieldInput
        {...form}
        index={3}
        name={`${field}.description`}
        label={'Payment Description'}
        input={{
          style: {height: 76, paddingTop: 8},
          multiline: true,
          placeholder: 'Enter payment description',
        }}
      />
    </FieldsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RecordPaymentFields.fragment = gql`
  ${Organization.getOfficePaymentMethods.fragment}
  ${PaymentMethod.getOfficePaymentMethodOptions.fragment}
  fragment RecordPaymentFields on Organization {
    id
    features {
      isEnabledCustomPaymentMethods: isEnabled(feature: "CUSTOM_PAYMENT_METHODS")
    }
    ...Organization_getOfficePaymentMethods
    ...PaymentMethod_getOfficePaymentMethodOptions
  }
`;

export default RecordPaymentFields;
