// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {ModalPage, Navigation} from 'modules/App/components';

import DocumentNotFoundPage from './DocumentNotFoundPage';

const DocumentPage = ({
  theme,
  side,
  title,
  subtitle,
  right,
  variables,
  query,
  onClose,
  children,
}) => (
  <ModalPage
    variables={variables}
    query={query}
    navigation={({loading, data, navigator, refetch}) => (
      <Navigation
        theme={theme}
        side={side}
        title={title({loading, data})}
        subtitle={subtitle({loading, data})}
        left={() =>
          onClose ? (
            <Navigation.CloseButton
              theme={theme}
              onPress={() => onClose({loading, data, navigator})}
            />
          ) : null
        }
        right={() => right({loading, data, navigator, refetch})}
      />
    )}
  >
    {(props) => (props.data.document ? children(props) : <DocumentNotFoundPage />)}
  </ModalPage>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DocumentPage.propTypes = {
  theme: PropTypes.string,
  onClose: PropTypes.func,
  side: PropTypes.func,
  title: PropTypes.func,
  subtitle: PropTypes.func,
  right: PropTypes.func,
};

DocumentPage.defaultProps = {
  theme: 'light',
  onClose: undefined,

  // If we just have the close button, we can default to this width.
  side: ({responsive}) => (responsive.mobile ? 40 : 60),
  title: () => '',
  subtitle: () => '',
  right: () => null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentPage.fragment = gql`
  fragment DocumentPage on Document {
    id
  }
`;

export default DocumentPage;
