// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useState,
  useEffect,
  useQuery,
  useResponsive,
  useToast,
  useSheet,
  useRef,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import EmptyState from '@shared/design/components/EmptyState';
import SearchBar from '@shared/design/components/SearchBar';
import Sheet from '@shared/design/components/Sheet';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import CustomSurveyTypes from '@shared/modules/Inventory/enums/CustomSurveyTypes';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import AlphabetSectionList from 'modules/Inventory/Edit/components/AlphabetSectionList';
import CategoryFilters from 'modules/Inventory/Edit/components/CategoryFilters';
import EditInventoryItemSheet from 'modules/Inventory/Edit/components/EditInventoryItemSheet';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
  border-right-color: ${colors.gray.border};
  border-right-width: 1px;
`;

const Row = Styled.View`
  align-items: center;
  flex-direction: row;
`;

const ItemListContainer = Styled.View`
  flex: 1;
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const ItemName = Styled.Text`
  ${Typography.Responsive.Body}
`;

const ItemDetails = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const Item = Styled.ButtonV2`
  padding-horizontal: 32px;
  padding-vertical: 12px;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const getItemTypeMatchesSearch = ({searchTerm, itemType}) => {
  return (
    (searchTerm && itemType.name.toLowerCase().includes(searchTerm.toLowerCase())) || !searchTerm
  );
};

const getFilteredItemTypes = ({searchTerm, itemTypes, filteredCategoryIds}) => {
  return _.filter(itemTypes, (itemType) => {
    const itemTypeCategoryIds = _.map(itemType.itemTypeCategories, (itemTypeCategory) =>
      _.toString(itemTypeCategory.categoryId),
    );
    const categoryFiltersMatchItemCategory = _.some(filteredCategoryIds, (categoryId) =>
      _.includes(itemTypeCategoryIds, categoryId),
    );
    return (
      (_.isEmpty(filteredCategoryIds) || categoryFiltersMatchItemCategory) &&
      getItemTypeMatchesSearch({searchTerm, itemType})
    );
  });
};

const AlphabetSectionListItem = React.memo(
  ({item, noRoomSelectedErrorToast, itemHeight, isItemStacked, selectedRoomIndex, handlePress}) => {
    const responsive = useResponsive();
    return (
      <Item
        key={item.id}
        activeOpacity={0.5}
        style={{
          ...(isItemStacked
            ? {flexDirection: 'column'}
            : {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }),
          height: itemHeight,
        }}
        onPress={() => {
          if (selectedRoomIndex === null || selectedRoomIndex < 0) {
            noRoomSelectedErrorToast.handleToast();
          } else {
            handlePress(item);
          }
        }}
      >
        <ItemName numberOfLines={1} responsive={responsive}>
          {item.id === 'CUSTOM' ? `Add '${item.name}'` : item.name}
        </ItemName>
        {item.id !== 'CUSTOM' && (
          <React.Fragment>
            <Space width={4} height={4} />
            <ItemDetails
              numberOfLines={1}
              responsive={responsive}
              style={{flex: 1, textAlign: isItemStacked ? 'left' : 'right'}}
            >
              {item.volume} cu ft, {item.weight} lbs
            </ItemDetails>
          </React.Fragment>
        )}
      </Item>
    );
  },
);

const ItemList = ({
  items,
  containerWidth,
  inventoryRoomsForm,
  setItemTypeKindFilters,
  searchTerm,
  setSearchTerm,
  handleScrollRoomToBottom,
  isTouchDevice,
  mobileInventoryLibrarySheet,
  defaultDensityFactor,
  setFilteredCategoryIds,
}) => {
  const responsive = useResponsive();
  const noRoomsErrorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Add a room before adding items.',
  });
  const noRoomSelectedErrorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Select a room before adding items.',
  });
  const [itemTypeToCreate, setItemTypeToCreate] = useState(null);
  const addCustomInventoryItemSheet = useSheet({name: 'Add Custom Inventory Item Sheet'});
  const addNewInventoryItemSheet = useSheet({name: 'Add New Inventory Item Sheet'});
  const {selectedRoomIndex} = inventoryRoomsForm.values.inventoryRoomsForm;
  const isItemStacked = containerWidth <= 300;
  const itemHeight = isItemStacked ? 75 : 45;

  // Since AlphabetSectionList is memoized, a ref is used to provide a snapshot of inventoryRoomsForm
  // with the most recent data. This allows AlphabetSectionList to call handlePress with an up to date
  // inventoryRoomsForm but without causing a re-render.
  const inventoryRoomsFormRef = useRef(inventoryRoomsForm);
  useEffect(() => {
    inventoryRoomsFormRef.current = inventoryRoomsForm;
  }, [inventoryRoomsForm]);

  return (
    <ItemListContainer>
      {!!searchTerm && (
        <React.Fragment>
          <AlphabetSectionList.SectionHeader>
            <AlphabetSectionList.SectionHeaderText responsive={responsive}>
              Custom
            </AlphabetSectionList.SectionHeaderText>
          </AlphabetSectionList.SectionHeader>
          <AlphabetSectionListItem
            item={{
              id: 'CUSTOM',
              name: searchTerm,
            }}
            noRoomSelectedErrorToast={noRoomSelectedErrorToast}
            selectedRoomIndex={inventoryRoomsForm.values.inventoryRoomsForm.selectedRoomIndex}
            handlePress={
              responsive.desktop && !isTouchDevice
                ? () => {
                    InventoryRoomsForm.addItemToInventoryRoomsForm({
                      inventoryRoomsForm,
                      itemType: {...CustomSurveyTypes.ITEM, name: searchTerm},
                      noRoomsErrorToast,
                      setItemTypeKindFilters,
                    });
                    setSearchTerm('');
                    if (
                      selectedRoomIndex >= 0 &&
                      !inventoryRoomsForm.values.inventoryRoomsForm.roomItemsForms[
                        selectedRoomIndex
                      ].isDeleted
                    ) {
                      handleScrollRoomToBottom();
                    }
                  }
                : addCustomInventoryItemSheet.handleOpen
            }
            itemHeight={itemHeight}
            isItemStacked={isItemStacked}
          />
        </React.Fragment>
      )}
      {_.isEmpty(items) ? (
        <EmptyState.ContentContainer style={{backgroundColor: colors.white}}>
          <EmptyState
            title={'No results.'}
            message={'No results match your selected filters.\nReset all filters and try again.'}
            primaryActionIcon={Icon.Trash}
            primaryActionText={'Reset Filters'}
            handlePrimaryAction={() => setFilteredCategoryIds([])}
          />
        </EmptyState.ContentContainer>
      ) : (
        <AlphabetSectionList
          items={items}
          selectedRoomIndex={selectedRoomIndex}
          ItemComponent={AlphabetSectionListItem}
          itemComponentProps={{
            noRoomSelectedErrorToast,
            itemHeight,
            isItemStacked,
            selectedRoomIndex: inventoryRoomsForm.values.inventoryRoomsForm.selectedRoomIndex,
            handlePress: (item) => {
              if (responsive.mobile) {
                setItemTypeToCreate(item);
                addNewInventoryItemSheet.handleOpen();
              } else {
                InventoryRoomsForm.upsertItemToInventoryRoomsForm({
                  inventoryRoomsForm: inventoryRoomsFormRef.current,
                  itemType: item,
                  noRoomsErrorToast,
                  setItemTypeKindFilters,
                });
                handleScrollRoomToBottom();
              }
            },
          }}
          itemHeight={itemHeight}
        />
      )}
      <EditInventoryItemSheet.Custom
        key={addCustomInventoryItemSheet.key}
        isOpen={addCustomInventoryItemSheet.isOpen}
        handleClose={
          mobileInventoryLibrarySheet.isOpen
            ? mobileInventoryLibrarySheet.handleClose
            : addCustomInventoryItemSheet.handleClose
        }
        inventoryRoomsForm={inventoryRoomsForm}
        roomItemsFormIndex={selectedRoomIndex}
        name={searchTerm}
        onSubmit={() => {
          setSearchTerm('');
          handleScrollRoomToBottom();
        }}
        defaultDensityFactor={defaultDensityFactor}
        handleGoBack={responsive.mobile ? addCustomInventoryItemSheet.handleClose : null}
      />
      <EditInventoryItemSheet.New
        key={addNewInventoryItemSheet.key}
        isOpen={addNewInventoryItemSheet.isOpen}
        handleClose={
          mobileInventoryLibrarySheet.isOpen
            ? mobileInventoryLibrarySheet.handleClose
            : addNewInventoryItemSheet.handleClose
        }
        inventoryRoomsForm={inventoryRoomsForm}
        roomItemsFormIndex={selectedRoomIndex}
        onSubmit={() => {
          handleScrollRoomToBottom();
          setItemTypeToCreate(null);
        }}
        defaultDensityFactor={defaultDensityFactor}
        itemType={itemTypeToCreate}
        handleGoBack={responsive.mobile ? addNewInventoryItemSheet.handleClose : null}
      />
    </ItemListContainer>
  );
};

const InventoryItemsContent = ({
  itemsData,
  inventoryRoomsForm,
  filteredCategoryIds,
  setFilteredCategoryIds,
  searchTerm,
  setSearchTerm,
  setItemTypeKindFilters,
  containerWidth,
  handleScrollRoomToBottom,
  isTouchDevice,
  mobileInventoryLibrarySheet,
}) => {
  const [filteredItemTypes, setFilteredItemTypes] = useState([]);

  useEffect(() => {
    setFilteredItemTypes(
      getFilteredItemTypes({
        searchTerm,
        filteredCategoryIds,
        itemTypes: itemsData.inventoryLibrary.itemTypes,
      }),
    );
  }, [searchTerm, filteredCategoryIds, itemsData]);

  return (
    <React.Fragment>
      <Space height={16} />
      <CategoryFilters
        inventoryLibrary={itemsData.inventoryLibrary}
        setFilteredCategoryIds={setFilteredCategoryIds}
        filteredCategoryIds={filteredCategoryIds}
      />
      <Space height={16} />
      <Row style={{paddingHorizontal: 16}}>
        <SearchBar
          placeholder={'Type to search or create'}
          iconColor={colors.gray.tertiary}
          containerStyle={{flex: 1}}
          style={{width: '100%'}}
          onChangeText={(value) => {
            setSearchTerm(value);
          }}
          valueOverride={searchTerm}
          isClearable
        />
      </Row>
      <Space height={16} />
      <ItemList
        items={filteredItemTypes}
        containerWidth={containerWidth}
        inventoryRoomsForm={inventoryRoomsForm}
        setItemTypeKindFilters={setItemTypeKindFilters}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        handleScrollRoomToBottom={handleScrollRoomToBottom}
        isTouchDevice={isTouchDevice}
        mobileInventoryLibrarySheet={mobileInventoryLibrarySheet}
        defaultDensityFactor={itemsData.inventoryLibrary.organization.settings.defaultDensityFactor}
        setFilteredCategoryIds={setFilteredCategoryIds}
      />
    </React.Fragment>
  );
};

const MobileSheetWrapper = ({mobileInventoryLibrarySheet, responsive, children}) => {
  if (responsive.mobile) {
    return (
      <Sheet.PreventPropagationContainer>
        <Sheet
          isOpen={mobileInventoryLibrarySheet.isOpen}
          handleClose={mobileInventoryLibrarySheet.handleClose}
          shouldCloseOnClickOutside={false}
          headerText={'Add Item'}
          isFixedHeight
          height={'85%'}
          // If the sheet is animated, the nested alphabet scroller does not work properly. It appears there is a race condition
          // for calculating the onLayout and the animation finishing, so for now we are disabling the animation.
          isAnimated={false}
        >
          {children}
        </Sheet>
      </Sheet.PreventPropagationContainer>
    );
  }
  return children;
};

const ResponsiveInventoryItemLibrary = ({
  inventoryLibraryUuid,
  inventoryRoomsForm,
  filteredCategoryIds,
  setFilteredCategoryIds,
  searchTerm,
  setSearchTerm,
  setItemTypeKindFilters,
  handleScrollRoomToBottom,
  isTouchDevice,
  mobileInventoryLibrarySheet,
}) => {
  const responsive = useResponsive();
  const [containerWidth, setContainerWidth] = useState(0);
  const {loading: itemsLoading, data: itemsData} = useQuery(ResponsiveInventoryItemLibrary.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inventoryLibraryUuid,
    },
  });

  return (
    <MobileSheetWrapper
      mobileInventoryLibrarySheet={mobileInventoryLibrarySheet}
      responsive={responsive}
    >
      <Container
        onLayout={({nativeEvent}) => setContainerWidth(nativeEvent.layout.width)}
        style={responsive.mobile ? {flex: 1} : {minWidth: 248, maxWidth: 400}}
      >
        <Loading loading={itemsLoading} as={PageLoadingIndicator}>
          {() => (
            <InventoryItemsContent
              itemsData={itemsData}
              inventoryRoomsForm={inventoryRoomsForm}
              filteredCategoryIds={filteredCategoryIds}
              setFilteredCategoryIds={setFilteredCategoryIds}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setItemTypeKindFilters={setItemTypeKindFilters}
              containerWidth={containerWidth}
              handleScrollRoomToBottom={handleScrollRoomToBottom}
              isTouchDevice={isTouchDevice}
              mobileInventoryLibrarySheet={mobileInventoryLibrarySheet}
            />
          )}
        </Loading>
      </Container>
    </MobileSheetWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ResponsiveInventoryItemLibrary.query = gql`
  ${CategoryFilters.fragment}
  ${EditInventoryItemSheet.New.fragment}
  ${InventoryRoomsForm.upsertItemToInventoryRoomsForm.fragment}
  query ResponsiveInventoryItemLibrary($inventoryLibraryUuid: String!) {
    inventoryLibrary(uuid: $inventoryLibraryUuid) {
      id
      itemTypes {
        id
        name
        weight
        volume
        itemTypeCategories {
          id
          categoryId
          category {
            id
            name
          }
        }
        ...EditInventoryItemSheet_New
        ...InventoryRoomsForm_upsertItemToInventoryRoomsForm
      }
      organization {
        id
        settings {
          id
          defaultDensityFactor
        }
      }
      ...CategoryFilters
    }
  }
`;

export default ResponsiveInventoryItemLibrary;
