// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import GenerateCustomDocumentsForJobForm from '@shared/modules/Job/forms/GenerateCustomDocumentsForJobForm';

const useGenerateCustomDocumentsForJobMutation = ({
  generateCustomDocumentsForJobForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      generateCustomDocumentsForJobForm: GenerateCustomDocumentsForJobForm.toForm(
        generateCustomDocumentsForJobForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useGenerateCustomDocumentsForJobMutation.mutation,
    variables: {
      generateCustomDocumentsForJobForm: GenerateCustomDocumentsForJobForm.toMutation(
        form.values.generateCustomDocumentsForJobForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useGenerateCustomDocumentsForJobMutation.mutation = gql`
  mutation useGenerateCustomDocumentsForJobMutation($generateCustomDocumentsForJobForm: GenerateCustomDocumentsForJobForm!) {
    response: generateCustomDocumentsForJob(generateCustomDocumentsForJobForm: $generateCustomDocumentsForJobForm) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default useGenerateCustomDocumentsForJobMutation;
