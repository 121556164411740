// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {Animation, colors} from '@supermove/styles';

const Loader = Styled.View<{height?: number; isFullWidth?: boolean; width?: number; color: string}>`
  height: ${({height}) => height}px;
  width: ${({isFullWidth, width}) => (isFullWidth ? '100%' : `${width}px`)};
  border-radius: 4px;
  background-color: ${({color}) => color};
  ${Animation.pulse}
`;

const SkeletonLoader = ({
  height,
  isFullWidth,
  width,
  color = colors.gray.disabled,
  style,
  children = null,
}: {
  height?: number;
  isFullWidth?: boolean;
  width?: number;
  color?: string;
  style?: object;
  children?: React.ReactNode;
}) => {
  return (
    <Loader isFullWidth={isFullWidth} height={height} width={width} color={color} style={style}>
      {children}
    </Loader>
  );
};

SkeletonLoader.HEIGHT = {
  // Typography - matches line height
  Typography: {
    Heading1: 32,
    Heading2: 28,
    Subheading: 24,
    Body: 20,
    Micro: 18,
  },
  Button: 36,
  ButtonSmall: 28,
};

export default SkeletonLoader;
