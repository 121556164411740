// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App

const Container = Styled.View`
  flex-direction: row;
`;

const Block = Styled.View`
`;

const Divider = Styled.View`
  width: 100%;
  margin-vertical: 5px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.blue.accent};
`;

const Label = Styled.H7`
`;

const Value = Styled.View`
  justify-content: flex-end;
  height: 50px;
`;

const Image = Styled.Image`
`;

const DateText = Styled.H6`
`;

const Placeholder = Styled.View`
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: ${colors.orange.accent};
`;

const PlaceholderText = Styled.H7`
  color: ${colors.orange.status};
`;

const DocumentCustomerSignature = ({shouldPromptSignature, signature, style}) => (
  <Container style={style} data-avoid-page-break>
    <Block
      style={{
        flex: 1,
        paddingRight: 40,
      }}
    >
      <Value>
        {shouldPromptSignature ? (
          <Placeholder>
            <PlaceholderText>Sign at the bottom</PlaceholderText>
          </Placeholder>
        ) : (
          <Image
            data-avoid-page-break
            source={{uri: _.get(signature, 'image')}}
            style={{
              width: 100,
              height: 50,
            }}
          />
        )}
      </Value>
      <Divider />
      <Label>{signature ? signature.name : ''}</Label>
    </Block>
    <Block style={{width: 100}}>
      <Value>
        <DateText>
          {signature ? Datetime.convertToDisplayDate(signature.date, 'M/D/YY') : ''}
        </DateText>
      </Value>
      <Divider />
      <Label>Date</Label>
    </Block>
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DocumentCustomerSignature.propTypes = {
  shouldPromptSignature: PropTypes.bool,
  signature: PropTypes.object,
  style: PropTypes.object,
};

DocumentCustomerSignature.defaultProps = {
  shouldPromptSignature: false,
  signature: null,
  style: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentCustomerSignature.fragment = gql`
  fragment DocumentCustomerSignature on Signature {
    id
    date
    name
    image
  }
`;

export default DocumentCustomerSignature;
