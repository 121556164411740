/*
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import UserStatus from '@shared/modules/User/enums/UserStatus';
import InviteUserForm from '@shared/modules/User/forms/InviteUserForm';
import useResendInviteUserToOrganizationMutation from '@shared/modules/User/hooks/useResendInviteUserToOrganizationMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import ConfirmReinviteUserModal from 'modules/Organization/Settings/Users/List/components/ConfirmReinviteUserModal';
import ReinviteOfficeUserSuccessModal from 'modules/Organization/Settings/Users/List/components/ReinviteOfficeUserSuccessModal';
import DeleteUserModal from 'modules/User/components/DeleteUserModal';

const MenuOptionButton = Styled.ButtonV2`
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const IconButton = Styled.ButtonV2`
  padding-horizontal: 8px;
`;

const ReinviteUserModal = ({
  user,
  isOpen,
  handleClose,
  reinviteOfficeUserSuccessModal,
  refetch,
}) => {
  const inviteUserForm = InviteUserForm.new({
    organizationId: user.viewingOrganization.id,
    email: user.email,
  });
  const {handleSubmit} = useResendInviteUserToOrganizationMutation({
    inviteUserForm,
    onSuccess: () => {
      handleClose();
      reinviteOfficeUserSuccessModal.handleOpen();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  return (
    <ConfirmReinviteUserModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleSubmit}
      title={'Resend invite?'}
      subtitle={'Resending invite will email a new one time sign-up link to the user.'}
    />
  );
};

const ActionMenuItem = ({onPress, children, submitting}) => {
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      <MenuOptionButton
        onPress={onPress}
        isHovered={isHovered}
        ref={ref}
        activeOpacity={0.8}
        disabled={submitting}
      >
        <MenuItemText>{children}</MenuItemText>
      </MenuOptionButton>
      <Space height={2} />
    </React.Fragment>
  );
};

const StaffActionsPopover = ({
  staffActionsPopover,
  confirmReinviteUserModal,
  reinviteOfficeUserSuccessModal,
  deleteUserModal,
  updateUserDrawer,
  user,
  refetch,
}) => {
  const handleOpenUpdateUserDrawer = () => {
    updateUserDrawer.handleOpen();
    staffActionsPopover.handleClose();
  };
  const handleOpenDeleteUserModal = () => {
    deleteUserModal.handleOpen();
    staffActionsPopover.handleClose();
  };
  const handleConfirmReinviteUserModal = () => {
    confirmReinviteUserModal.handleOpen();
    staffActionsPopover.handleClose();
  };
  return (
    <Popover
      placement={Popover.Positions.RightStart}
      isOpen={staffActionsPopover.isOpen}
      handleOpen={staffActionsPopover.handleOpen}
      handleClose={staffActionsPopover.handleClose}
      reference={staffActionsPopover.ref}
    >
      <ResponsivePopover.StaticContainer width={160}>
        <Space height={8} />
        {user.status === UserStatus.PENDING && (
          <ActionMenuItem onPress={handleConfirmReinviteUserModal}>
            Resend invitation
          </ActionMenuItem>
        )}
        <ActionMenuItem onPress={handleOpenUpdateUserDrawer}>Edit user</ActionMenuItem>
        <ActionMenuItem onPress={handleOpenDeleteUserModal}>Delete user</ActionMenuItem>
        <DeleteUserModal
          key={deleteUserModal.key}
          isOpen={deleteUserModal.isOpen}
          handleClose={deleteUserModal.handleClose}
          userId={user.id}
          refetch={refetch}
        />
        <ReinviteUserModal
          user={user}
          isOpen={confirmReinviteUserModal.isOpen}
          handleClose={confirmReinviteUserModal.handleClose}
          reinviteOfficeUserSuccessModal={reinviteOfficeUserSuccessModal}
          refetch={refetch}
        />
        <ReinviteOfficeUserSuccessModal
          isOpen={reinviteOfficeUserSuccessModal.isOpen}
          handleClose={reinviteOfficeUserSuccessModal.handleClose}
          title={`${user.firstName} invited!`}
          subtitle={`A new invitation link was sent to ${user.firstName}. They have 7 days to accept the invitation.`}
        />
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const StaffActionsButton = ({user, refetch, updateUserDrawer}) => {
  const staffActionsPopover = usePopover();
  const deleteUserModal = useModal({name: 'DeleteUserModal'});
  const confirmReinviteUserModal = useModal({name: 'ConfirmReinviteUserModal'});
  const reinviteOfficeUserSuccessModal = useModal({name: 'ReinviteOfficeUserSuccessModal'});

  return (
    <React.Fragment>
      <Popover.Content innerRef={staffActionsPopover.ref}>
        <IconButton onPress={staffActionsPopover.handleToggle}>
          <Icon
            source={Icon.EllipsisV}
            color={staffActionsPopover.isOpen ? colors.blue.interactive : colors.gray.secondary}
            size={16}
          />
        </IconButton>
      </Popover.Content>
      <StaffActionsPopover
        key={`STAFF_USERS_ACTIONS_POPOVER-${staffActionsPopover.isOpen}`}
        staffActionsPopover={staffActionsPopover}
        confirmReinviteUserModal={confirmReinviteUserModal}
        reinviteOfficeUserSuccessModal={reinviteOfficeUserSuccessModal}
        deleteUserModal={deleteUserModal}
        updateUserDrawer={updateUserDrawer}
        user={user}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StaffActionsButton.fragment = gql`
  fragment StaffActionsButton on User {
    id
    viewingOrganization {
      id
    }
    firstName
    status
  }
`;

export default StaffActionsButton;
