// Libraries
import _ from 'lodash';

const PRIMARY_CLIENT = 'PRIMARY_CLIENT';
const BILLING_CLIENT = 'BILLING_CLIENT';
const ASSIGNED_MOVERS = 'ASSIGNED_MOVERS';
const SALESPERSON = 'SALESPERSON';
const COORDINATOR = 'COORDINATOR';
const SPECIFIC_USERS = 'SPECIFIC_USERS';
const TEAMS = 'TEAMS';

const KIND_OPTIONS = {
  COORDINATOR: {
    value: COORDINATOR,
    label: 'Project Coordinator',
  },
  SALESPERSON: {
    value: SALESPERSON,
    label: 'Project Salesperson',
  },
  PRIMARY_CLIENT: {
    value: PRIMARY_CLIENT,
    label: 'Primary Client',
  },
  BILLING_CLIENT: {
    value: BILLING_CLIENT,
    label: 'Billing Client',
  },

  ASSIGNED_MOVERS: {
    value: ASSIGNED_MOVERS,
    label: 'Assigned movers',
  },
  SPECIFIC_USERS: {
    value: SPECIFIC_USERS,
    label: 'Specific users',
  },
  TEAMS: {
    value: TEAMS,
    label: 'Teams',
  },
};

const getKindOption = (kind: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return KIND_OPTIONS[kind];
};

export default {
  COORDINATOR,
  SALESPERSON,
  PRIMARY_CLIENT,
  BILLING_CLIENT,
  ASSIGNED_MOVERS,
  SPECIFIC_USERS,
  TEAMS,

  DROPDOWN_OPTIONS: _.values(KIND_OPTIONS),
  getKindOption,
};
