// Libararies
import React from 'react';

// Supermove
import {Emoji, Icon, Styled, Space} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.ButtonV2`
  width: fit-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${({size}: any) => (size === SIZE.LARGE ? '32px' : '24px')};
  padding-horizontal: 12px;
  padding-vertical: 4px;
  border-radius: 24px;
  border-color: ${({borderColor}: any) => borderColor};
  border-width: 1px;
  background-color: ${({backgroundColor}: any) => backgroundColor};
`;

const IconContainer = Styled.ButtonV2`
`;

const Text = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${({color}: any) => color};
`;

const EmojiContainer = Styled.View`
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 18px;
`;

const EmojiText = Styled.Text`
  ${Typography.Micro}
`;

const SIZE = {
  SMALL: 'SMALL',
  LARGE: 'LARGE',
};

const TagIcon = ({icon, color, onPress, isDisabled}: any) => {
  return (
    <IconContainer disabled={isDisabled || !onPress} onPress={onPress}>
      <Icon source={icon} size={12} color={color} />
    </IconContainer>
  );
};

const getBackgroundColor = ({isSelected, isDisabled}: any) => {
  if (isSelected) {
    if (isDisabled) {
      return colors.gray.disabled;
    }
    return colors.blue.interactive;
  }
  return colors.gray.background;
};

const getBorderColor = ({isSelected, isDisabled}: any) => {
  if (isSelected && !isDisabled) {
    return colors.blue.interactive;
  }
  return colors.gray.border;
};

const getIconColor = ({isSelected, isDisabled}: any) => {
  if (isDisabled) {
    if (isSelected) {
      return colors.gray.tertiary;
    }
    return colors.gray.disabled;
  }
  if (isSelected) {
    return colors.white;
  }
  return colors.gray.secondary;
};

const getTextColor = ({isSelected, isDisabled}: any) => {
  if (isDisabled) {
    return colors.gray.tertiary;
  }
  if (isSelected) {
    return colors.white;
  }
  return colors.gray.secondary;
};

const Tag = ({
  label,
  onPress,
  emoji,
  iconLeft,
  iconRight,
  onIconRightPress,
  isDisabled,
  isSelected,
}: any) => {
  const responsive = useResponsive();
  const size = responsive.desktop ? SIZE.SMALL : SIZE.LARGE;
  const backgroundColor = getBackgroundColor({isSelected, isDisabled});
  const borderColor = getBorderColor({isSelected, isDisabled});
  const iconColor = getIconColor({isSelected, isDisabled});
  const textColor = getTextColor({isSelected, isDisabled});
  return (
    <Container
      size={size}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      disabled={!onPress}
      onPress={onPress}
    >
      {iconLeft && (
        <React.Fragment>
          <TagIcon icon={iconLeft} color={iconColor} />
          <Space width={4} />
        </React.Fragment>
      )}
      {emoji && (
        <React.Fragment>
          <EmojiContainer>
            <Emoji component={EmojiText} name={emoji} />
          </EmojiContainer>
          <Space width={4} />
        </React.Fragment>
      )}
      <Text color={textColor} responsive={responsive}>
        {label}
      </Text>
      {iconRight && (
        <React.Fragment>
          <Space width={4} />
          <TagIcon
            icon={iconRight}
            onPress={onIconRightPress}
            isDisabled={isDisabled}
            color={iconColor}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

export default Tag;
