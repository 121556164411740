// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {TimesheetBlock} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TimesheetBillableEntryTimeline from '@shared/modules/Timesheet/components/TimesheetBillableEntryTimeline';
import TimesheetBillableEntryTimesheetBlocks from '@shared/modules/Timesheet/components/TimesheetBillableEntryTimesheetBlocks';
import TimesheetBillableEntryKind from '@shared/modules/Timesheet/enums/TimesheetBillableEntryKind';

const EntryHeaderLabel = Styled.Text`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isLargeHeader ? Typography.Responsive.Heading2 : Typography.Responsive.Label)}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isLargeHeader ? colors.gray.primary : colors.gray.secondary)};
`;

const EntryExpandButton = Styled.ButtonV2`
`;

const EntryHeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmptyPlaceholderContainer = Styled.View`
  height: 64px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: center;
  align-items: center;
`;

const EmptyPlaceholderText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const EmptyPlaceholder = ({responsive}: any) => {
  return (
    <EmptyPlaceholderContainer>
      <EmptyPlaceholderText responsive={responsive}>
        No times added for this timesheet yet.
      </EmptyPlaceholderText>
    </EmptyPlaceholderContainer>
  );
};

const TimesheetBillableEntryHeader = ({
  timesheetBillableEntry,
  isCollapsible,
  isExpanded,
  setIsExpanded,
  hasBlocks,
  isViewOnly,
  isLargeHeader,
  isCondensed,
  isShowNames,
  timelineProps,
}: any) => {
  const responsive = useResponsive();
  const userNames = _.map(timesheetBillableEntry.timesheetPayrollEntries, 'user.fullName');
  const displayUserNames = isShowNames && _.some(userNames) ? ` (${userNames.join(', ')})` : '';

  if (isCollapsible) {
    return (
      <EntryExpandButton
        onPress={() => setIsExpanded(!isExpanded)}
        disabled={!hasBlocks || isViewOnly}
      >
        <EntryHeaderContainer>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <EntryHeaderLabel responsive={responsive} vars={{isLargeHeader}}>
            {timesheetBillableEntry.moverPosition.name}
            {displayUserNames}
          </EntryHeaderLabel>
          <Space width={8} />
          {hasBlocks && !isViewOnly && (
            <Icon
              source={isExpanded ? Icon.AngleUp : Icon.AngleDown}
              size={responsive.desktop ? 14 : 16}
              color={colors.gray.secondary}
            />
          )}
        </EntryHeaderContainer>
        <Space height={isCondensed ? 4 : 12} />
        {hasBlocks && <TimesheetBillableEntryTimeline {...timelineProps} />}
      </EntryExpandButton>
    );
  }

  if (isShowNames) {
    return (
      <React.Fragment>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <EntryHeaderLabel responsive={responsive} vars={{isLargeHeader}}>
          {'Movers'}
          {displayUserNames}
        </EntryHeaderLabel>
        <Space height={isCondensed ? 4 : 12} />
      </React.Fragment>
    );
  }

  return null;
};

const TimesheetBillableEntry = ({
  job,
  timesheetBillableEntry,
  refetch,
  onUpdate,
  isViewOnly,
  isLargeHeader,
  showTotals,
  viewerRole,
  isCondensed,
  isShowNames,
}: any) => {
  const {timesheetBlocks} = timesheetBillableEntry;
  const isCollapsible = timesheetBillableEntry.kind === TimesheetBillableEntryKind.POSITION;
  const isSingleBillableEntry = job.timesheetBillableEntries.length === 1;
  const [isExpanded, setIsExpanded] = useState(!isCollapsible || isSingleBillableEntry);
  const hasBlocks = _.some(timesheetBlocks);
  const responsive = useResponsive();
  const isComplete = job.isComplete || job.hasJobEventDuringMoveJobTimesheetCompleted;
  const timelineProps = {timesheetBillableEntry, isComplete, showTotals, isCondensed};

  return (
    <React.Fragment>
      <TimesheetBillableEntryHeader
        timesheetBillableEntry={timesheetBillableEntry}
        isCollapsible={isCollapsible}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        hasBlocks={hasBlocks}
        isViewOnly={isViewOnly}
        isLargeHeader={isLargeHeader}
        isCondensed={isCondensed}
        isShowNames={isShowNames}
        timelineProps={timelineProps}
      />
      {hasBlocks ? (
        <React.Fragment>
          {!isCollapsible && <TimesheetBillableEntryTimeline {...timelineProps} />}
          {isExpanded && !isViewOnly && (
            <React.Fragment>
              <Space height={12} />
              <TimesheetBillableEntryTimesheetBlocks
                job={job}
                timesheetBillableEntry={timesheetBillableEntry}
                refetch={refetch}
                onUpdate={onUpdate}
                responsive={responsive}
                viewerRole={viewerRole}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <EmptyPlaceholder responsive={responsive} />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TimesheetBillableEntry.fragment = gql`
  ${TimesheetBillableEntryTimeline.fragment}
  ${TimesheetBlock.getDisplayRange.fragment}
  ${TimesheetBlock.getMinutes.fragment}
  ${TimesheetBillableEntryTimesheetBlocks.fragment}

  fragment TimesheetBillableEntry on Job {
    id
    isComplete
    hasJobEventDuringMoveJobTimesheetCompleted: hasJobEvent(
      kind: "DURING_MOVE_JOB_TIMESHEET_COMPLETED"
    )
    timesheetBillableEntries {
      id
      kind
      moverPosition {
        id
        name
      }
      timesheetBlocks {
        id
        kind
        ...TimesheetBlock_getDisplayRange
        ...TimesheetBlock_getMinutes
      }
      timesheetPayrollEntries {
        id
        user {
          id
          fullName
        }
      }
      ...TimesheetBillableEntryTimeline
    }
    ...TimesheetBillableEntryTimesheetBlocks
  }
`;

export default TimesheetBillableEntry;
