import {
  makeCharacterTokenizer,
  makeDelimiterTokenizer,
  makeIgnoreTokenizer,
  makeTwoCharacterTokenizer,
  tokenizeIdentifier,
  tokenizeNumber,
} from '@shared/ast/tokenizer';

const tokenizeOpenParenthesis = makeCharacterTokenizer('OpenParenthesis', '(');
const tokenizeClosedParenthesis = makeCharacterTokenizer('ClosedParenthesis', ')');
const tokenizeComma = makeCharacterTokenizer('Comma', ',');

const tokenizeBang = makeCharacterTokenizer('Bang', '!');
const tokenizeDash = makeCharacterTokenizer('Dash', '-');
const tokenizeSlash = makeCharacterTokenizer('Slash', '/');
const tokenizeAsterisk = makeCharacterTokenizer('Asterisk', '*');
const tokenizePlus = makeCharacterTokenizer('Plus', '+');
const tokenizeGreaterThan = makeCharacterTokenizer('GreaterThan', '>');
const tokenizeLessThan = makeCharacterTokenizer('LessThan', '<');
const tokenizeBangEqual = makeTwoCharacterTokenizer('BangEqual', '!', '=');
const tokenizeDoubleEqual = makeTwoCharacterTokenizer('DoubleEqual', '=', '=');
const tokenizeGreaterEqual = makeTwoCharacterTokenizer('GreaterEqual', '>', '=');
const tokenizeLessEqual = makeTwoCharacterTokenizer('LessEqual', '<', '=');

const tokenizeDoubleQuoteString = makeDelimiterTokenizer('String', '"', '"');
const tokenizeSingleQuoteString = makeDelimiterTokenizer('String', "'", "'");
const tokenizeIgnoreWhitespace = makeIgnoreTokenizer(/\s/);

export const FORMULA_TOKENIZERS = [
  tokenizeOpenParenthesis,
  tokenizeClosedParenthesis,
  tokenizeBangEqual,
  tokenizeDoubleEqual,
  tokenizeLessEqual,
  tokenizeGreaterEqual,
  tokenizeGreaterThan,
  tokenizeLessThan,
  tokenizeNumber,
  tokenizeBang,
  tokenizeComma,
  tokenizeSlash,
  tokenizeAsterisk,
  tokenizePlus,
  tokenizeIdentifier,
  tokenizeDash,
  tokenizeSingleQuoteString,
  tokenizeDoubleQuoteString,
  tokenizeIgnoreWhitespace,
];
