// Libraries
import React from 'react';

// App
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';

const ReinviteOfficeUserSuccessModal = ({handleClose, isOpen, title, subtitle}) => {
  return (
    <SuccessModal
      title={title}
      subtitle={subtitle}
      isOpen={isOpen}
      handlePrimaryAction={handleClose}
      primaryActionText={'Done'}
    />
  );
};

export default ReinviteOfficeUserSuccessModal;
