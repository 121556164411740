// Libararies
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import UserAvatar from 'modules/User/components/UserAvatar';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const EventCardContainer = Styled.ButtonV2`
  flex-direction: row;
`;

const EventCardTitle = Styled.Text`
  ${Typography.Responsive.Label}
`;

const EventCardSubtitle = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const EventCardDescription = Styled.Text`
  ${Typography.Responsive.Body}
`;

const ConnectorLine = Styled.View`
  width: 1px;
  background-color: ${colors.gray.border};
  height: 100%;
`;

const JobLink = Styled.Text`
  ${Typography.Responsive.MicroLink}
`;

const JobNameButtonWrapper = ({children}) => {
  return (
    <Column
      onStartShouldSetResponder={() => true}
      onTouchEnd={(e) => {
        e.stopPropagation();
      }}
      style={{flex: 1}}
    >
      {children}
    </Column>
  );
};

const JobNameButton = ({job, urlFilters, responsive}) => {
  return (
    <JobNameButtonWrapper>
      <JobLink
        numberOfLines={1}
        responsive={responsive}
        onPress={() =>
          urlFilters.handleUpdate({
            section: null,
            block: ProjectBlockKind.Job.JOB_DETAILS,
            jobUuid: job.uuid,
          })
        }
      >
        {job.fullName}
      </JobLink>
    </JobNameButtonWrapper>
  );
};

const EventCardBody = ({jobEvent, isExpanded, urlFilters}) => {
  const {job} = jobEvent;
  const hasDescription = !!jobEvent.description;
  const responsive = useResponsive();

  return (
    <Column style={{flex: 1}}>
      <Row style={{alignItems: 'center'}}>
        <EventCardTitle responsive={responsive}>{jobEvent.name}</EventCardTitle>
        <Space style={{flex: 1}} />
        {hasDescription && (
          <Icon
            source={isExpanded ? Icon.ChevronUp : Icon.ChevronDown}
            size={responsive.desktop ? 12 : 16}
            color={colors.gray.secondary}
          />
        )}
      </Row>
      <Space height={6} />
      <Row>
        <EventCardSubtitle responsive={responsive}>
          {Datetime.convertToDisplayTime(jobEvent.time)}
        </EventCardSubtitle>
        {!!job && (
          <React.Fragment>
            <Space width={8} />
            <ConnectorLine />
            <Space width={8} />
            <JobNameButton job={job} urlFilters={urlFilters} responsive={responsive} />
          </React.Fragment>
        )}
      </Row>
      <Space height={8} />
      {isExpanded && hasDescription && (
        <React.Fragment>
          <EventCardDescription responsive={responsive}>
            {jobEvent.description}
          </EventCardDescription>
          <Space height={8} />
        </React.Fragment>
      )}
      <Space height={16} />
    </Column>
  );
};

const ProjectEventLogCard = ({jobEvent, isLast, expandedEvents, setExpandedEvents, urlFilters}) => {
  const isExpanded = expandedEvents[jobEvent.id];

  return (
    <EventCardContainer
      onPress={() => {
        setExpandedEvents({...expandedEvents, [jobEvent.id]: isExpanded ? undefined : true});
      }}
    >
      <Column style={{alignItems: 'center'}}>
        <UserAvatar user={jobEvent.creator} style={{height: 32, width: 32}} logoSize={12} />
        {!isLast && <ConnectorLine />}
      </Column>
      <Space width={12} />
      <EventCardBody jobEvent={jobEvent} isExpanded={isExpanded} urlFilters={urlFilters} />
    </EventCardContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectEventLogCard.fragment = gql`
  ${UserAvatar.fragment}

  fragment ProjectEventLogCard on JobEvent {
    id
    time
    name
    description
    creator {
      id
      ...UserAvatar
    }
    job {
      id
      uuid
      fullName
    }
  }
`;

export default ProjectEventLogCard;
