// Libraries
import _ from 'lodash';
import React from 'react';

// App
import FileInputFieldV2 from '@shared/modules/File/components/FileInputFieldV2';
import ImportInventoryLibraryRoomTypesForm from '@shared/modules/File/forms/ImportInventoryLibraryRoomTypesForm';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useImportInventoryLibraryRoomTypesMutation from '@shared/modules/File/hooks/useImportInventoryLibraryRoomTypesMutation';
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';
import ImportInventoryLibraryContentsModal from 'modules/Organization/Settings/Inventory/components/ImportInventoryLibraryContentsModal';

const ImportInventoryLibraryRoomTypesModal = ({
  viewerId,
  organizationId,
  inventoryLibraryId,
  isOpen,
  handleClose,
  refetch,
}) => {
  const useUploadFileMutation = useUploadFileForm({
    uploadFileForm: UploadFileForm.new({
      organizationId,
      creatorId: viewerId,
    }),
    onSuccess: ({file}) => {
      importInventoryLibraryRoomTypesMutation.form.setFieldValue(
        'importInventoryLibraryRoomTypesForm.fileId',
        file.id,
      );
    },
    onError: (errors) =>
      FileInputFieldV2.resetUploadFileForm({
        field: 'uploadFileForm',
        form: useUploadFileMutation.form,
      }),
  });

  const importInventoryLibraryRoomTypesMutation = useImportInventoryLibraryRoomTypesMutation({
    importInventoryLibraryRoomTypesForm: ImportInventoryLibraryRoomTypesForm.new({
      inventoryLibraryId,
    }),
    onSuccess: () => {
      FileInputFieldV2.resetUploadFileForm({
        name: 'uploadFileForm',
        form: useUploadFileMutation.form,
      });
      importInventoryLibraryRoomTypesMutation.form.setFieldValue(
        'importInventoryLibraryRoomTypesForm.fileId',
        null,
      );
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });
  const validationErrors = _.reverse(
    _.values(
      importInventoryLibraryRoomTypesMutation.form.errors.importInventoryLibraryRoomTypesForm,
    ),
  );

  return (
    <ImportInventoryLibraryContentsModal
      useUploadFileMutation={useUploadFileMutation}
      isSubmitting={importInventoryLibraryRoomTypesMutation.submitting}
      handleSubmit={importInventoryLibraryRoomTypesMutation.handleSubmit}
      title={'Import Rooms from CSV'}
      isOpen={isOpen}
      handleClose={handleClose}
      validationErrors={validationErrors}
    />
  );
};

export default ImportInventoryLibraryRoomTypesModal;
