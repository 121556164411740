// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';

const useUpdateValuesMutation = ({updateValuesForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      updateValuesForm: UpdateValuesForm.toForm(updateValuesForm),
    },
  });
  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateValuesMutation.mutation,
    variables: {
      updateValuesForm: UpdateValuesForm.toMutation(form.values.updateValuesForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateValuesMutation.mutation = gql`
  mutation useUpdateValuesMutation($updateValuesForm: UpdateValuesForm!) {
    response: updateValues(updateValuesForm: $updateValuesForm) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useUpdateValuesMutation;
