// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Icon, Space, Styled} from '@supermove/components';
import TextInput from '@supermove/components/src/Styled/TextInput';
import {gql} from '@supermove/graphql';
import {useForm, useResponsive, useToast} from '@supermove/hooks';
import {Inventory} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SheetWithFooter from '@shared/design/components/Sheet/SheetWithFooter';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import CustomSurveyTypes from '@shared/modules/Inventory/enums/CustomSurveyTypes';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import ItemFormV2 from '@shared/modules/Inventory/forms/ItemFormV2';
import InventoryStepper from 'modules/Inventory/Edit/components/InventoryStepper';

const Column = Styled.View``;

const Row = Styled.View`
  flex-direction: row;
`;

const IconButton = Styled.ButtonV2`
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
`;

const computeWeight = (volume, defaultDensityFactor) =>
  volume === 0 ? 0 : _.round(volume * defaultDensityFactor, 2);
const computeVolume = (weight, defaultDensityFactor) =>
  weight === 0 ? 0 : _.round(weight / defaultDensityFactor, 2);

const NameInput = ({form, autoFocus}) => {
  return (
    <FieldInput
      {...form}
      isResponsive
      setFieldValue={form.setFieldValue}
      input={{
        autoFocus: autoFocus === 'name',
        onFocus: (event) => event.target.select(),
      }}
      name={'name'}
      label={'Item Name'}
    />
  );
};

const VolumeInput = ({form, autoFocus, defaultDensityFactor}) => {
  const {isDerivedWeight} = form.values;
  return (
    <FieldInput
      {...form}
      isResponsive
      style={{flex: 1}}
      setFieldValue={(name, value) => {
        const numberValue = Inventory.getFloatValue(value);
        form.setFieldValue(name, value.endsWith('.') ? value : numberValue);
        if (isDerivedWeight) {
          form.setFieldValue('weight', computeWeight(numberValue, defaultDensityFactor));
        }
      }}
      input={{
        autoFocus: autoFocus === 'volume',
        onFocus: (event) => event.target.select(),
      }}
      name={'volume'}
      label={'Volume (cu ft)'}
      handleBlur={(event) => {
        const text = event.target.value;
        // Formatting volume removes a trailing decimal
        form.setFieldValue('volume', Inventory.getFloatValue(text));
      }}
    />
  );
};

const WeightInput = ({form, autoFocus, defaultDensityFactor}) => {
  const {isDerivedWeight, volume} = form.values;
  return (
    <FieldInput
      {...form}
      isResponsive
      name={'weight'}
      label={'Weight (lbs)'}
      LabelIconComponent={() => (
        <Row style={{flex: 1, justifyContent: 'flex-end'}}>
          <IconButton
            onPress={() => {
              if (!isDerivedWeight) {
                form.setFieldValue('weight', computeWeight(volume, defaultDensityFactor));
              }
              form.setFieldValue('isDerivedWeight', !isDerivedWeight);
            }}
          >
            <Icon
              source={isDerivedWeight ? Icon.Link : Icon.LinkSlash}
              size={16}
              color={colors.blue.interactive}
            />
          </IconButton>
        </Row>
      )}
      style={{flex: 1}}
      setFieldValue={(name, value) => {
        const numberValue = Inventory.getFloatValue(value);
        form.setFieldValue(name, value.endsWith('.') ? value : numberValue);
        if (isDerivedWeight) {
          form.setFieldValue('volume', computeVolume(numberValue, defaultDensityFactor));
        }
      }}
      input={{
        autoFocus: autoFocus === 'weight',
        onFocus: (event) => event.target.select(),
      }}
      handleBlur={(event) => {
        const text = event.target.value;
        // Formatting weight removes a trailing decimal
        form.setFieldValue('weight', Inventory.getFloatValue(text));
      }}
    />
  );
};

const PriceInput = ({form, autoFocus}) => {
  return (
    <FieldInput
      {...form}
      isResponsive
      component={CurrencyInput}
      style={{flex: 1}}
      setFieldValue={form.setFieldValue}
      input={{
        autoFocus: autoFocus === 'price',
        onFocus: (event) => event.target.select(),
        setFieldValue: form.setFieldValue,
        setFieldTouched: form.setFieldTouched,
        component: TextInput,
      }}
      name={'price'}
      label={'Price ($)'}
    />
  );
};

const QuantityInput = ({form, style, autoFocus}) => {
  const responsive = useResponsive();
  const {take, takeCount, leaveCount} = form.values;
  const quantityField = take ? 'takeCount' : 'leaveCount';
  const quantity = take ? takeCount : leaveCount;
  const handleQuantityChange = (newQuantity) => (newQuantity < 0 ? 0 : newQuantity);

  return (
    <Column style={style}>
      <FieldInput.LabelText isResponsive>Quantity</FieldInput.LabelText>
      <Space height={responsive.desktop ? 4 : 8} />
      <InventoryStepper.Stepper
        value={quantity}
        handleDecrease={() => form.setFieldValue(quantityField, handleQuantityChange(quantity - 1))}
        handleIncrease={() => form.setFieldValue(quantityField, handleQuantityChange(quantity + 1))}
        style={{
          borderColor: colors.gray.tertiary,
        }}
        height={responsive.desktop ? 36 : 48}
      >
        <FieldInput
          {...form}
          isResponsive
          style={{flex: 1}}
          setFieldValue={(name, value) => {
            const quantity = Inventory.getFloatValue(value);
            // getFloatValue blocks trailing decimals, so we allow it so that floats can be entered
            form.setFieldValue(name, value.endsWith('.') ? value : quantity);
          }}
          input={{
            autoFocus,
            onFocus: (event) => event.target.select(),
            style: {borderWidth: 0, textAlign: 'center'},
            onBlur: (event) => {
              const text = event.target.value;
              // Formatting quantity removes a trailing decimal
              form.setFieldValue(quantityField, Inventory.getFloatValue(text));
            },
          }}
          name={quantityField}
        />
      </InventoryStepper.Stepper>
    </Column>
  );
};

const NotesInput = ({form, autoFocus}) => {
  return (
    <FieldInput
      {...form}
      isResponsive
      style={{flex: 1}}
      setFieldValue={form.setFieldValue}
      input={{
        autoFocus: autoFocus === 'notes',
        onFocus: (event) => event.target.select(),
        multiline: true,
        style: {height: 72, paddingTop: 8},
        placeholder: 'Enter item notes',
      }}
      name={'notes'}
      label={'Item Notes'}
    />
  );
};

const TakeLeaveInput = ({form}) => {
  return (
    <Checkbox
      isResponsive
      isChecked={form.values.take}
      label={'Take'}
      hint={'When unchecked, this item will be marked as “Leave”.'}
      handleToggle={(isChecked) => {
        form.setFieldValue('take', isChecked);
        if (isChecked) {
          form.setFieldValue('takeCount', form.values.leaveCount);
          form.setFieldValue('leaveCount', 0);
        } else {
          form.setFieldValue('leaveCount', form.values.takeCount);
          form.setFieldValue('takeCount', 0);
        }
      }}
    />
  );
};

const validateForm = ({form}) => {
  if (form.values.name === '') {
    form.setErrors({name: 'Please enter a name'});
    return true;
  }
  return false;
};

const handleSave = ({form, handleSubmit, inventoryRoomsForm, roomItemsFormIndex}) => {
  const errors = validateForm({form});
  if (errors) return;

  const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms`;
  const {price, take, takeCount, leaveCount, index, volume, weight} = form.values;
  const quantity = take ? takeCount : leaveCount;
  const updatedValues = {
    ...form.values,
    isDirty: true,
    price: Currency.toMutation(price),
    isDeleted: quantity === 0,
    // Formatting weight and volume removes a trailing decimal
    volume: Inventory.getFloatValue(volume),
    weight: Inventory.getFloatValue(weight),
  };

  InventoryRoomsForm.setDirtyForms({
    inventoryRoomsForm,
    roomItemsFormIndex,
    itemIndex: index,
  });

  // This action is different for add and update
  handleSubmit({updatedValues, field, index});
};

const BaseSheet = ({
  isOpen,
  handleClose,
  itemForm,
  sheetLabel,
  autoFocus,
  defaultDensityFactor,
  handleGoBack,
  primaryActionText,
  handlePrimaryAction,
  secondaryActionText,
  handleSecondaryAction,
}) => {
  // ItemFormV2 does not toForm currency values, so we need to do that here
  const form = useForm({
    initialValues: {...itemForm, price: Currency.toForm(itemForm.price)},
  });

  return (
    <SheetWithFooter.PreventPropagationContainer style={{position: 'absolute'}}>
      <SheetWithFooter
        isFixedHeight
        height={'85%'}
        isFixedFooter
        handleGoBack={handleGoBack}
        shouldCloseOnClickOutside={false}
        headerText={sheetLabel}
        isOpen={isOpen}
        handleClose={handleClose}
        primaryActionText={primaryActionText || 'Save'}
        handlePrimaryAction={() => handlePrimaryAction({form})}
        secondaryActionText={secondaryActionText}
        handleSecondaryAction={handleSecondaryAction ? () => handleSecondaryAction({form}) : null}
        isVerticallyStackedFooter
      >
        <NameInput form={form} autoFocus={autoFocus} />
        <Space height={16} />
        <Row>
          <VolumeInput
            form={form}
            autoFocus={autoFocus}
            defaultDensityFactor={defaultDensityFactor}
          />
          <Space width={16} />
          <WeightInput
            form={form}
            autoFocus={autoFocus}
            defaultDensityFactor={defaultDensityFactor}
          />
        </Row>
        <Space height={16} />
        <Row>
          <PriceInput form={form} autoFocus={autoFocus} />
          <Space width={16} />
          <QuantityInput form={form} style={{flex: 1}} autoFocus={autoFocus === 'quantity'} />
        </Row>
        <Space height={16} />
        <NotesInput form={form} autoFocus={autoFocus} />
        <Space height={16} />
        <TakeLeaveInput form={form} />
      </SheetWithFooter>
    </SheetWithFooter.PreventPropagationContainer>
  );
};

const CustomInventoryItemSheet = React.memo(
  ({
    isOpen,
    handleClose,
    inventoryRoomsForm,
    roomItemsFormIndex,
    name,
    onSubmit,
    defaultDensityFactor,
    handleGoBack,
  }) => {
    const itemAddedToast = useToast({
      ToastComponent: SuccessToast,
      message: `Item added!`,
    });

    const customItemType = {...CustomSurveyTypes.ITEM, name};
    const selectedRoomItems = _.get(
      inventoryRoomsForm,
      `values.inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}`,
    );

    // When no room is selected, the sheet should not be open
    // And downstream logic should not be run
    if (roomItemsFormIndex === null || roomItemsFormIndex < 0 || !selectedRoomItems) {
      return null;
    }

    const currentRoomItems = selectedRoomItems.itemForms;
    const customItemForm = ItemFormV2.new({
      collectionId: selectedRoomItems.collectionId,
      name,
      weight: customItemType.weight,
      volume: customItemType.volume,
      price: customItemType.price,
      isDerivedWeight: customItemType.isDerivedWeight,
      itemTypeId: customItemType.id,
      index: selectedRoomItems.itemForms.length,
      kind: customItemType.kind,
    });

    return (
      <BaseSheet
        isOpen={isOpen}
        handleClose={handleClose}
        itemForm={customItemForm}
        sheetLabel={'Add Custom Item'}
        defaultDensityFactor={defaultDensityFactor}
        handleGoBack={handleGoBack}
        handlePrimaryAction={({form}) => {
          handleSave({
            form,
            handleSubmit: ({updatedValues, field}) => {
              inventoryRoomsForm.setFieldValue(field, [...currentRoomItems, updatedValues]);
              itemAddedToast.handleToast({message: `${updatedValues.name} added!`});
              onSubmit();
            },
            inventoryRoomsForm,
            roomItemsFormIndex,
          });
          handleClose();
        }}
      />
    );
  },
  (prevProps, nextProps) =>
    // Memoizing the component so unnecessary re-renders are don't cause the sheet to close
    prevProps.isOpen === nextProps.isOpen,
);

const NewInventoryItemSheet = React.memo(
  ({
    isOpen,
    handleClose,
    inventoryRoomsForm,
    roomItemsFormIndex,
    onSubmit,
    defaultDensityFactor,
    itemType,
    handleGoBack,
  }) => {
    // itemType is set dynamically, so we return early if it's not set
    if (!itemType) return null;

    const selectedRoomItems = _.get(
      inventoryRoomsForm,
      `values.inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}`,
    );

    // When no room is selected, the sheet should not be open
    // And downstream logic should not be run
    if (roomItemsFormIndex === null || roomItemsFormIndex < 0 || !selectedRoomItems) {
      return null;
    }

    const currentRoomItems = selectedRoomItems.itemForms;
    const newItemForm = ItemFormV2.newFromItemType(itemType, {
      collectionId: selectedRoomItems.collectionId,
      index: selectedRoomItems.itemForms.length,
    });

    const handleSaveForm = ({form}) => {
      handleSave({
        form,
        handleSubmit: ({updatedValues, field}) => {
          inventoryRoomsForm.setFieldValue(field, [...currentRoomItems, updatedValues]);
          onSubmit();
        },
        inventoryRoomsForm,
        roomItemsFormIndex,
      });
    };

    return (
      <BaseSheet
        isOpen={isOpen}
        handleClose={handleClose}
        itemForm={newItemForm}
        sheetLabel={'Add Item'}
        defaultDensityFactor={defaultDensityFactor}
        handleGoBack={handleGoBack}
        primaryActionText={'Save & Add Another'}
        handlePrimaryAction={({form}) => {
          handleSaveForm({form});
          handleGoBack();
        }}
        secondaryActionText={'Save & Close'}
        handleSecondaryAction={({form}) => {
          handleSaveForm({form});
          handleClose();
        }}
      />
    );
  },
  (prevProps, nextProps) =>
    // Memoizing the component so unnecessary re-renders are don't cause the sheet to close
    prevProps.isOpen === nextProps.isOpen,
);

NewInventoryItemSheet.fragment = gql`
  ${ItemFormV2.newFromItemType.fragment}
  fragment EditInventoryItemSheet_New on ItemType {
    id
    ...ItemFormV2_newFromItemType
  }
`;

const EditInventoryItemSheet = ({
  isOpen,
  handleClose,
  inventoryRoomsForm,
  roomItemsFormIndex,
  itemForm,
  autoFocus,
  defaultDensityFactor,
  removeItemToast,
}) => {
  const itemUpdatedToast = useToast({
    ToastComponent: SuccessToast,
    message: `Item updated!`,
  });

  // We clone the form and make edits there so that all the changes are made at once
  const clonedItemForm = _.cloneDeep(itemForm);

  return (
    <BaseSheet
      isOpen={isOpen}
      handleClose={handleClose}
      itemForm={clonedItemForm}
      sheetLabel={`Edit ${itemForm.name}`}
      autoFocus={autoFocus}
      defaultDensityFactor={defaultDensityFactor}
      handlePrimaryAction={({form}) => {
        handleSave({
          form,
          handleSubmit: ({updatedValues, field, index}) => {
            inventoryRoomsForm.setFieldValue(`${field}.${index}`, updatedValues);
            if (updatedValues.isDeleted) {
              removeItemToast.handleToast({message: `${itemForm.name} removed.`});
            } else {
              itemUpdatedToast.handleToast({message: `${updatedValues.name} updated!`});
            }
          },
          inventoryRoomsForm,
          roomItemsFormIndex,
        });
        handleClose();
      }}
    />
  );
};

EditInventoryItemSheet.Custom = CustomInventoryItemSheet;
EditInventoryItemSheet.New = NewInventoryItemSheet;

export default EditInventoryItemSheet;
