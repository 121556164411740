// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const Row = Styled.View`
  flex-direction: row;
`;

const Wrapper = Styled.View`
  margin-bottom: 8px;
  z-index: ${(props) => 100 - (props as any).index};
`;

const RowNumber = Styled.Text`
  margin-right: 8px;
  ${Typography.Body2};
`;

const ListItem = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 4px;
  padding-left: 4px;
`;

const ItemText = Styled.Text`
  ${Typography.Body3}
`;

const AddItemButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const AddItemButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${colors.blue.interactive};
`;

const IconCircle = Styled.View`
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
  height: 20px;
  width: 20px;
  border-radius: 10px;
`;

const DeleteButton = Styled.ButtonV2`
  padding-left: 8px;
  padding-vertical: 2px;
`;

const handleAddInput = ({form, itemsField}: any) => {
  const items = _.get(form.values, itemsField);
  form.setFieldValue(itemsField, [...items, '']);
};

const handleRemoveInput = ({form, itemsField, index}: any) => {
  const items = _.get(form.values, itemsField);
  const beforeInputs = items.slice(0, index);
  const afterInputs = items.slice(index + 1);
  form.setFieldValue(itemsField, [...beforeInputs, ...afterInputs]);
};

const InputListEdit = ({form, field}: any) => {
  const itemsField = `${field}.input.value.values`;
  const items = _.get(form.values, itemsField, []);
  const isDeletable = items.length > 1;
  const deleteColor = isDeletable ? colors.red.warning : colors.gray.border;
  return (
    <React.Fragment>
      {items.map((item: any, index: any) => {
        return (
          <ListItem key={index}>
            <RowNumber>{`${index + 1}.`}</RowNumber>
            <FieldInput.Memoized
              {...form}
              name={`${itemsField}.${index}`}
              size={FieldInput.SIZE.MEDIUM}
              input={{
                placeholder: 'Enter value',
              }}
              style={{flex: 1}}
            />
            <DeleteButton
              disabled={!isDeletable}
              onPress={() => handleRemoveInput({form, itemsField, index})}
            >
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <IconCircle color={deleteColor}>
                <Icon source={Icon.Minus} size={12} color={deleteColor} />
              </IconCircle>
            </DeleteButton>
          </ListItem>
        );
      })}
      <Space height={8} />
      <Row>
        <Space width={24} />
        <AddItemButton onPress={() => handleAddInput({form, itemsField})}>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <IconCircle color={colors.blue.interactive}>
            <Icon source={Icon.Plus} size={12} color={colors.blue.interactive} />
          </IconCircle>
          <Space width={8} />
          <AddItemButtonText>Add an item</AddItemButtonText>
        </AddItemButton>
      </Row>
    </React.Fragment>
  );
};

const InputListView = ({input}: any) => {
  const items = _.get(input.value, 'values', []);
  return (
    <React.Fragment>
      {_.isEmpty(items) ? (
        <ItemText>No List Items</ItemText>
      ) : (
        items.map((item: any, index: any) => (
          <ListItem key={index}>
            <RowNumber>{`${index + 1}.`}</RowNumber>
            <ItemText>{item}</ItemText>
          </ListItem>
        ))
      )}
    </React.Fragment>
  );
};

const DocumentV2InputList = ({input, index, isEditable, form, field}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Wrapper index={index}>
      {isEditable ? (
        <InputListEdit input={input} form={form} field={field} />
      ) : (
        <InputListView input={input} />
      )}
    </Wrapper>
  );
};

export default DocumentV2InputList;
