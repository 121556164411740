// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {pluralize} from '@supermove/utils';

// App
import WarningCallout from '@shared/design/components/Callout/WarningCallout';

const ProjectLocationJobsCallout = ({location, marginBottom}) => {
  const jobCount = location.jobs.length;
  return (
    <React.Fragment>
      {!!jobCount && (
        <React.Fragment>
          <WarningCallout
            text={
              `This location is used on ${pluralize(
                'job',
                jobCount,
                true,
              )}. Updates will reflect on:` +
              `${location.jobs.reduce((result, job) => `${result}\n  • ${job.shortName}`, '')}`
            }
          />
          <Space height={marginBottom} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectLocationJobsCallout.fragment = gql`
  fragment ProjectLocationJobsCallout on Location {
    id
    jobs {
      id
      shortName
    }
  }
`;

export default ProjectLocationJobsCallout;
