// Libraries
import React from 'react';

// Supermove
import {Loading, Query} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';

// App
import InvalidResetPasswordTokenView from 'modules/Authentication/Password/Set/components/InvalidResetPasswordTokenView';
import SetPasswordForm from 'modules/Authentication/Password/Set/components/SetPasswordForm';

const SetPasswordPage = () => {
  const responsive = useResponsive();
  const {params} = useNavigationDOM();

  // User can come back empty if using an expired token.
  return (
    <Query variables={{resetPasswordToken: params.token}} query={SetPasswordPage.query}>
      {({loading, data}) => (
        <Loading loading={loading}>
          {() =>
            data.user && data.user.isResetPasswordTokenValid ? (
              <SetPasswordForm user={data.user} token={params.token} responsive={responsive} />
            ) : (
              <InvalidResetPasswordTokenView responsive={responsive} />
            )
          }
        </Loading>
      )}
    </Query>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SetPasswordPage.propTypes = {};

SetPasswordPage.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
SetPasswordPage.query = gql`
  query SetPasswordPage($resetPasswordToken: String) {
    user(resetPasswordToken: $resetPasswordToken) {
      id
      resetPasswordToken
      isResetPasswordTokenValid
    }
  }
`;

export default SetPasswordPage;
