// Libraries
import React from 'react';

// Supermove
import {FileDragInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Panel from '@shared/design/components/Panel';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import OrganizationVirtualWalkthroughSettingsForm from '@shared/modules/Organization/forms/OrganizationVirtualWalkthroughSettingsForm';
import useUpdateOrganizationVirtualWalkthroughSettingsMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationVirtualWalkthroughSettingsMutation';
import useUploadOrganizationVirtualWalkthroughPhoto from '@shared/modules/Organization/hooks/useUploadOrganizationVirtualWalkthroughPhoto';

const ButtonContainer = Styled.View`
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
`;

const TextContainer = Styled.View`
  width: 356px;
`;

const EmptyPhotoContainer = Styled.View`
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.gray.border};
`;

const PhotoCircleContainer = Styled.View`
  width: 70px;
  height: 70px;
  border-radius: 35px;
`;

const ImageCircle = Styled.Image`
  flex: 1;
  border-radius: 35px;
`;

const ActivityIndicator = Styled.Loading`
`;

const AvatarPhoto = ({
  viewer,
  organizationVirtualWalkthroughSettingsForm,
  onUploadStart,
  onUploadSuccess,
  onUploadError,
  onDelete,
}) => {
  const removeAvatarModal = useModal({name: 'Remove Avatar Modal'});
  const uploadFileForm = UploadFileForm.new({
    organizationId: viewer.viewingOrganization.id,
    creatorId: viewer.id,
  });

  const {form, handleSubmit} = useUploadOrganizationVirtualWalkthroughPhoto({
    uploadFileForm,
    organizationVirtualWalkthroughSettingsForm,
    onSuccess: onUploadSuccess,
    onError: (error) => {
      console.log(error);
      onUploadError();
    },
  });

  const {isUploadingPhoto} = organizationVirtualWalkthroughSettingsForm;
  const {virtualWalkthroughPhotoFile} = viewer.viewingOrganization;
  return (
    <React.Fragment>
      <FileDragInput
        onFilesChange={(files) => {
          const file = files[0];
          form.setFieldValue(`uploadFileForm.requestUploadFileForm.mimetype`, file.type);
          form.setFieldValue(`uploadFileForm.requestUploadFileForm.filename`, file.name);
          form.setFieldValue(`uploadFileForm.file`, file);
          onUploadStart();
          handleSubmit();
        }}
        accept={'image/*'}
        dropzoneOptions={{noClick: true, noKeyboard: true}}
        disabled={isUploadingPhoto}
        style={{flex: 1}}
      >
        {({handleOpen}) => {
          const dropdownButtonOptions = [
            {
              text: 'Change Avatar',
              onPress: handleOpen,
            },
            {
              text: 'Remove Avatar',
              onPress: removeAvatarModal.handleOpen,
            },
          ];
          return (
            <React.Fragment>
              {isUploadingPhoto ? (
                <EmptyPhotoContainer>
                  <ActivityIndicator color={colors.gray.tertiary} size={'small'} />
                </EmptyPhotoContainer>
              ) : virtualWalkthroughPhotoFile ? (
                <PhotoCircleContainer>
                  <ImageCircle
                    resizeMode={'cover'}
                    source={{
                      uri: virtualWalkthroughPhotoFile
                        ? virtualWalkthroughPhotoFile.downloadUrl
                        : null,
                    }}
                    accessibilityLabel={
                      virtualWalkthroughPhotoFile ? virtualWalkthroughPhotoFile.filename : null
                    }
                  />
                </PhotoCircleContainer>
              ) : (
                <EmptyPhotoContainer>
                  <Icon source={Icon.Image} size={24} color={colors.gray.tertiary} />
                </EmptyPhotoContainer>
              )}
              <Space width={24} />
              <TextContainer>
                <Panel.LabelText>Avatar for Virtual Walkthroughs</Panel.LabelText>
                <Space height={8} />
                <Panel.Text>
                  {`Upload a jpeg or png of your Avatar to appear when ` +
                    `customers begin the Virtual Walkthrough process.`}
                </Panel.Text>
              </TextContainer>
              <Space width={24} />
              <ButtonContainer>
                {virtualWalkthroughPhotoFile ? (
                  <DropdownButton
                    text={'Update Avatar'}
                    actions={dropdownButtonOptions}
                    width={146}
                  />
                ) : (
                  <Button onPress={handleOpen} disabled={isUploadingPhoto} text={'Upload Avatar'} />
                )}
              </ButtonContainer>
            </React.Fragment>
          );
        }}
      </FileDragInput>
      <DeleteModal
        handleDelete={() => {
          onDelete();
          removeAvatarModal.handleClose();
        }}
        handleClose={removeAvatarModal.handleClose}
        isOpen={removeAvatarModal.isOpen}
        title={'Remove Avatar?'}
        subtitle={'This avatar will no longer appear in Virtual Walkthroughs.'}
      />
    </React.Fragment>
  );
};

const AvatarPanel = ({viewer, refetch}) => {
  const organizationVirtualWalkthroughSettingsForm =
    OrganizationVirtualWalkthroughSettingsForm.edit(viewer.viewingOrganization);

  const {form, handleSubmit} = useUpdateOrganizationVirtualWalkthroughSettingsMutation({
    organizationVirtualWalkthroughSettingsForm,
    onSuccess: refetch,
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <React.Fragment>
      <Panel width={784}>
        <Panel.Header>
          <Panel.HeaderText>Avatar</Panel.HeaderText>
        </Panel.Header>
        <Panel.Body>
          <AvatarPhoto
            viewer={viewer}
            organizationVirtualWalkthroughSettingsForm={
              form.values.organizationVirtualWalkthroughSettingsForm
            }
            onUploadStart={() => {
              form.setFieldValue(
                `organizationVirtualWalkthroughSettingsForm.isUploadingPhoto`,
                true,
              );
            }}
            onUploadSuccess={() => {
              form.setFieldValue(
                `organizationVirtualWalkthroughSettingsForm.isUploadingPhoto`,
                false,
              );
              refetch();
            }}
            onUploadError={() => {
              form.setFieldValue(
                `organizationVirtualWalkthroughSettingsForm.isUploadingPhoto`,
                false,
              );
            }}
            onDelete={() => {
              form.setFieldValue(
                `organizationVirtualWalkthroughSettingsForm.virtualWalkthroughPhotoFileId`,
                null,
              );
              setTimeout(() => handleSubmit(), 0);
            }}
          />
        </Panel.Body>
      </Panel>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AvatarPanel.fragment = gql`
  ${OrganizationVirtualWalkthroughSettingsForm.edit.fragment}
  fragment AvatarPanel on User {
    id
    viewingOrganization {
      id
      virtualWalkthroughPhotoFile {
        id
        downloadUrl
        filename
      }
      ...OrganizationVirtualWalkthroughSettingsForm_edit
    }
  }
`;

export default AvatarPanel;
