interface InfoBlockDocumentationSection {
  header: string;
  description: string;
  syntaxMarkdownSnippet: string;
  exampleMarkdownSnippet: string;
}

interface InfoBlock {
  isHeader?: boolean;
  headerText?: string;
  icon: string;
  documentation?: InfoBlockDocumentationSection;
  insertionText?: string;
}
interface InfoBlockDeclaration {
  [infoBlockKey: string]: InfoBlock;
}

const operatorExpressionInfoBlocks: InfoBlockDeclaration = {
  'operators': {
    isHeader: true,
    headerText: 'Operators',
    icon: '',
    documentation: {
      header: 'Operators',
      description:
        'Operators are used to compare values and peform arithmetic. Hover over an operator to see syntax information and example usage, and click on an operator to insert it into a formula.',
      exampleMarkdownSnippet: ``,
      syntaxMarkdownSnippet: '',
    },
  },
  'not': {
    documentation: {
      header: 'Not',
      description: 'Returns the boolean inverse of its argument.',
      syntaxMarkdownSnippet: `not`,
      exampleMarkdownSnippet: `not false *evaluates* *to* true`,
    },
    icon: '',
    insertionText: 'not',
  },
  '!=': {
    insertionText: '!=',
    documentation: {
      header: 'Not Equal',
      description: 'Returns true if the arguments are not equal.',
      syntaxMarkdownSnippet: `!=`,
      exampleMarkdownSnippet: `3 != 4 *evaluates* *to* true`,
    },
    icon: '',
  },
  '==': {
    insertionText: '==',
    icon: '',
    documentation: {
      header: 'Equal',
      description: 'Returns true if the arguments are equal.',
      syntaxMarkdownSnippet: `==`,
      exampleMarkdownSnippet: `1 == 1 *evaluates* *to* true`,
    },
  },
  '>': {
    insertionText: '>',
    icon: '',

    documentation: {
      header: 'Greater Than',
      description: 'Returns true if the left argument is greater.',
      syntaxMarkdownSnippet: ` \\> `,
      exampleMarkdownSnippet: `5 > 3 *evaluates* *to* true`,
    },
  },
  '>=': {
    insertionText: '>=',
    icon: '',

    documentation: {
      header: 'Greater Equal',
      description: 'Returns true if the left argument is greater or equal to the right argument.',
      syntaxMarkdownSnippet: `\\>=`,
      exampleMarkdownSnippet: `5 >= 3 *evaluates* *to* true \n\n 8 >= 8 *evaluates* *to* true`,
    },
  },
  '<': {
    insertionText: '<',
    icon: '',

    documentation: {
      header: 'Less Than',
      description: 'Returns true if the left argument is less than the right argument.',
      syntaxMarkdownSnippet: `<`,
      exampleMarkdownSnippet: `5 < 8 *evaluates* *to* true`,
    },
  },
  '<=': {
    insertionText: '<=',
    icon: '',
    documentation: {
      header: 'Less Equal',
      description: 'Returns true if the left argument is less than or equal to the right argument.',
      syntaxMarkdownSnippet: '<=',
      exampleMarkdownSnippet: `5 <= 7 *evaluates* *to* true \n\n 2 <= 1 *evaluates* *to* false
      `,
    },
  },
  'or': {
    insertionText: 'or',
    icon: '',
    documentation: {
      header: 'Or',
      description: 'Returns true if at least one argument is true',
      syntaxMarkdownSnippet: '*boolean* or *boolean*',
      exampleMarkdownSnippet: `true or false *evaluates* *to* true \n \n false or false *evaluates* *to* false
      `,
    },
  },
  'and': {
    documentation: {
      header: 'And',
      description: 'Returns true if both arguments are exist or are true, otherwise returns false.',
      syntaxMarkdownSnippet: `*value* and *value*`,
      exampleMarkdownSnippet: `9 and 6 == true`,
    },
    icon: '',
    insertionText: 'and',
  },
  '+': {
    insertionText: '+',
    icon: '',
    documentation: {
      header: 'Add',
      description: 'Adds two numbers together.',
      syntaxMarkdownSnippet: `*number* + *number*`,
      exampleMarkdownSnippet: `3 + 4 *evaluates* *to* 7`,
    },
  },
  '*': {
    insertionText: '*',
    icon: '',
    documentation: {
      header: 'Multiply',
      description: 'Multiplies two numbers together.',
      syntaxMarkdownSnippet: '*number* * *number*',
      exampleMarkdownSnippet: `5 * 2 *evaluates* *to* 10`,
    },
  },
  '-': {
    insertionText: '-',
    icon: '',
    documentation: {
      header: 'Subtract',
      description: 'Subtracts one number from another.',
      syntaxMarkdownSnippet: '*number* - *number*',
      exampleMarkdownSnippet: `5 - 2 *evaluates* *to* 3`,
    },
  },
  '\\': {
    insertionText: '\\',
    icon: '',
    documentation: {
      header: 'Divide',
      description: 'Divides one number by another.',
      syntaxMarkdownSnippet: '*number* / *number*',
      exampleMarkdownSnippet: `10 / 5 *evaluates* *to* 2`,
    },
  },
};

const functionExpressionInfoBlocks: InfoBlockDeclaration = {
  functions: {
    isHeader: true,
    headerText: 'Functions',
    icon: '',
    documentation: {
      header: 'Functions',
      description:
        'Functions are reusable bits of logic that can be used in formulas. Functions provide a variety of abilities, from formatting to comparison and injection of billing values. Hover over a formula to see syntax information and example usage, or click on a function to insert it into a formula.',
      syntaxMarkdownSnippet: '',
      exampleMarkdownSnippet: '',
    },
  },
  ceil: {
    icon: '',
    insertionText: 'ceil(',
    documentation: {
      header: 'Ceil (Round Up)',
      description: 'Rounds its argument up to the nearest whole number.',
      syntaxMarkdownSnippet: 'ceil(*number*)',
      exampleMarkdownSnippet: 'ceil(5.6) *evaluates* *to* 6',
    },
  },
  floor: {
    icon: '',
    insertionText: 'floor(',
    documentation: {
      header: 'Floor (Round Down)',
      description: 'Rounds its argument down to the nearest whole number.',
      syntaxMarkdownSnippet: 'floor(*number*)',
      exampleMarkdownSnippet: 'floor(5.6) *evaluates* *to* 5',
    },
  },
  max: {
    icon: '',
    insertionText: 'max(',
    documentation: {
      header: 'Max',
      description: 'Returns the larger of two numbers',
      syntaxMarkdownSnippet: `max(*number*,*number*)`,
      exampleMarkdownSnippet: `max(5,6) *evaluates* *to* 6 \n \n max(4,-5) *evaluates* *to* 4`,
    },
  },
  min: {
    icon: '',
    insertionText: 'min(',
    documentation: {
      header: 'Min',
      description: 'Returns the smaller of two numbers',
      syntaxMarkdownSnippet: 'min(*number*,*number*)',
      exampleMarkdownSnippet: 'min(5,6) *evaluates* *to* 5',
    },
  },
  if: {
    icon: '',
    insertionText: 'if(',
    documentation: {
      header: 'If',
      description:
        'If the first argument is true, return the second argument. Otherwise return the third argument.',
      syntaxMarkdownSnippet: 'if(*boolean*,*expression*,*expression*)',
      exampleMarkdownSnippet: 'if(true,3,4) *evaluates* *to* 3',
    },
  },
  string: {
    icon: '',
    insertionText: 'string(',
    documentation: {
      header: 'String',
      description:
        'A string is a grouping of characters (often used to build up text in formulas). The string function returns its argument as a string.',
      syntaxMarkdownSnippet: 'string(*number*)',
      exampleMarkdownSnippet: 'string(8) *evaluates* *to* "8"',
    },
  },
  number: {
    icon: '',
    insertionText: 'number(',
    documentation: {
      header: 'Number',
      description: 'Returns its argument as a number.',
      syntaxMarkdownSnippet: 'number(*string*)',
      exampleMarkdownSnippet: 'number("3.8") *evaluates* *to* 3.8',
    },
  },
  round: {
    icon: 'Round',
    insertionText: 'round(',
    documentation: {
      header: 'Round',
      description: 'Rounds its first argument to the closest multiple of the second argument.',
      syntaxMarkdownSnippet: 'round(*number*, *number*)',
      exampleMarkdownSnippet: 'round(34, 15) *evaluates* *to* 30',
    },
  },
  var: {
    icon: '',
    insertionText: 'var(',
    documentation: {
      header: 'Billing Variable Access',
      description:
        'Fetches the billing variable associated with the identifier provided. Billing variables are configurable on the billing settings page.',
      syntaxMarkdownSnippet: 'var(*string*)',
      exampleMarkdownSnippet: 'var("JOB_NUMBER_OF_MOVERS") *evaluates* *to* job.number_of_movers',
    },
  },
  formatNumber: {
    icon: '',
    insertionText: 'formatNumber(',
    documentation: {
      header: 'Format Number',
      description: 'Formats a number as a string, to the provided precision level.',
      syntaxMarkdownSnippet: 'formatNumber(*number*,*number*)',
      exampleMarkdownSnippet: 'formatNumber(3.456,1) *evaluates* *to* "3.4"',
    },
  },
  dollars: {
    icon: '',
    insertionText: 'dollars(',
    documentation: {
      header: 'Format Dollars',
      description: 'Formats a number as a dollar amount, including cents.',
      syntaxMarkdownSnippet: 'dollars(*number*)',
      exampleMarkdownSnippet: 'dollars(99.99) *evaluates* *to* "$99.99"',
    },
  },
  ifs: {
    icon: '',
    insertionText: 'ifs(',
    documentation: {
      header: 'Multiple If',
      description:
        'Checks whether one of many conditions are met and returns a value corresponding to the first true condition. A default value must be provided in case none of the branches are true.',
      syntaxMarkdownSnippet: 'ifs(*condition1*,*value1*,*condition2*,*value2*,...,*defaultValue*)',
      exampleMarkdownSnippet:
        'ifs(3 > 1, "first branch is true","default") *evaluates* *to* "first branch is true" \n \n ifs(3 > 5, "not true", 2 < 5, "second branch is true", "default")  *evaluates* *to* "second branch is true" \n \n ifs(3 < 1, "first branch is false",2 > 5, "second branch is false", 4 > 6, "third branch is false", "default") *evaluates* *to* "default"',
    },
  },
  switch: {
    icon: '',
    insertionText: 'switch(',
    documentation: {
      header: 'Switch Case Statement',
      description:
        'Checks a value against a number of possible matches and returns a value corresponding with the first match. A default value must be provided in case none of the values match.',
      syntaxMarkdownSnippet:
        'switch(*valueToMatch*,*potentialMatch1*,*value1*,*potentialMatch2*,*value2*,...,*defaultValue*)',
      exampleMarkdownSnippet:
        'switch("apple", "apple","first branch is true","default") *evaluates* *to* "first branch is true" \n \n switch("apple", "orange", "first branch is false", "apple","second branch is true", "default")  *evaluates* *to* "second branch is true" \n \n switch("apple", "orange", "first branch is false", "banana","second branch is false", "default") *evaluates* *to* "default"',
    },
  },
  day: {
    icon: '',
    insertionText: 'day(',
    documentation: {
      header: 'Day',
      description: 'Retrieves day value from date string.',
      syntaxMarkdownSnippet: 'day(*string*)',
      exampleMarkdownSnippet: 'day("2023-01-02") *evaluates* *to* "2"',
    },
  },
  dayOfWeek: {
    icon: '',
    insertionText: 'dayOfWeek(',
    documentation: {
      header: 'Day Of Week',
      description: 'Retrieves the day of week value from date string (1 - Monday, 7 - Sunday).',
      syntaxMarkdownSnippet: 'dayOfWeek(*string*)',
      exampleMarkdownSnippet: 'dayOfWeek("2023-01-02") *evaluates* *to* "1"',
    },
  },
  month: {
    icon: '',
    insertionText: 'month(',
    documentation: {
      header: 'Month',
      description: 'Retrieves the month value from date string.',
      syntaxMarkdownSnippet: 'month(*string*)',
      exampleMarkdownSnippet: 'month("2023-01-02") *evaluates* *to* "1"',
    },
  },
  year: {
    icon: '',
    insertionText: 'year(',
    documentation: {
      header: 'Year',
      description: 'Retrieves the year value from date string.',
      syntaxMarkdownSnippet: 'year(*string*)',
      exampleMarkdownSnippet: 'year("2023-01-02") *evaluates* *to* "2023"',
    },
  },
  minute: {
    icon: '',
    insertionText: 'minute(',
    documentation: {
      header: 'Minute',
      description: 'Retrieves the minute value from time string.',
      syntaxMarkdownSnippet: 'minute(*string*)',
      exampleMarkdownSnippet: 'minute("0830") *evaluates* *to* "30"',
    },
  },
  hour: {
    icon: '',
    insertionText: 'hour(',
    documentation: {
      header: 'Hour',
      description: 'Retrieves the hour value from time string.',
      syntaxMarkdownSnippet: 'hour(*string*)',
      exampleMarkdownSnippet: 'hour("0830") *evaluates* *to* "8"',
    },
  },
};

const constantExpressionInfoBlocks: InfoBlockDeclaration = {
  constants: {
    isHeader: true,
    headerText: 'Constants',
    icon: '',
    documentation: {
      header: 'Constants',
      description:
        'Constants will evaluate to a certain value. Hover over a constant to see syntax information and example usage, and click on a constant to insert it into a formula.',
      exampleMarkdownSnippet: ``,
      syntaxMarkdownSnippet: '',
    },
  },
  tbd: {
    icon: '',
    insertionText: 'TBD',
    documentation: {
      header: 'TBD',
      description: 'Used to represet an unknown value.',
      syntaxMarkdownSnippet: 'TBD',
      exampleMarkdownSnippet: 'TBD *evaluates* *to* "TBD"',
    },
  },
};

export const formulaInfoBlock = {
  isHeader: true,
  headerText: 'Formula Editor',
  icon: '',
  documentation: {
    header: 'Formula Editor',
    description:
      'Formulas make it easy to add dynamic values to your bills. Use this editor to create a formula and assign it to a billing library item to use it!',
    exampleMarkdownSnippet: `
    // Charge $4 per mile
    value("PROJECT_MILEAGE") * 4 * 100

    // Show mileage in bill item
    "Mileage Charge: " + value("PROJECT_MILEAGE") + " miles @ $4 per mile"

    // For a project with a total mileage of 100 miles,
    // a bill item will be added that appears as below:

    "Mileage Charge: 100 miles @ $4 per mile: $400"
    `,
    syntaxMarkdownSnippet: '',
  },
};

const formulaExpressionInfoBlocks = {
  formulas: formulaInfoBlock,
  ...Object.keys(operatorExpressionInfoBlocks).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        ...operatorExpressionInfoBlocks[key],
        parent: 'operators',
      },
    };
  }, {}),
  ...Object.keys(functionExpressionInfoBlocks).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        ...functionExpressionInfoBlocks[key],
        parent: 'functions',
      },
    };
  }, {}),
  ...Object.keys(constantExpressionInfoBlocks).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        ...constantExpressionInfoBlocks[key],
        parent: 'constants',
      },
    };
  }, {}),
};
export default formulaExpressionInfoBlocks;
