// Libraries
import React from 'react';

// Supermove
import {Icon, Query, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import TableV1, {TableV1Builder} from '@shared/design/components/TableV1';
import CommercialMaterialForm from '@shared/modules/CommercialCatalog/forms/CommercialMaterialForm';
import useCreateCommercialMaterialMutation from '@shared/modules/CommercialCatalog/hooks/useCreateCommercialMaterialMutation';
import LargeModal from 'modules/App/Modal/components/LargeModal';
import SearchBar from 'modules/App/components/SearchBar';
import CreateCommercialMaterialModal from 'modules/CommercialCatalog/components/CreateCommercialMaterialModal';
import DeleteCommercialMaterialModal from 'modules/CommercialCatalog/components/DeleteCommercialMaterialModal';
import UpdateCommercialMaterialModal from 'modules/CommercialCatalog/components/UpdateCommercialMaterialModal';

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Indicator = Styled.Loading`
`;

const Content = Styled.View`
  flex: 1;
  padding-bottom: 40px;
  margin-top: 10px;
  margin-horizontal: ${(props) => (props.mobile ? 12 : 30)}px;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const TableTitle = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const TableSection = ({index, count, title, children}) => {
  return (
    <Section index={index}>
      <TableTitle>{`${title} (${count})`}</TableTitle>
      {children}
    </Section>
  );
};

const Actions = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const Button = Styled.Button`
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const IconButton = Styled.LoadingButton`
  height: 30px;
  width: 30px;
`;

const IconButtonDelete = ({onPress}) => {
  return (
    <IconButton color={colors.Pink600} onPress={onPress}>
      <Icon color={colors.white} size={14} source={Icon.Trash} />
    </IconButton>
  );
};

const IconButtonEdit = ({onPress}) => {
  return (
    <Button color={colors.blue.interactive} onPress={onPress}>
      <Icon color={colors.white} size={14} source={Icon.Edit} />
    </Button>
  );
};

const EditCommercialMaterialButton = ({commercialMaterial, refetch}) => {
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <React.Fragment>
      <IconButtonEdit onPress={handleOpen} />
      <UpdateCommercialMaterialModal
        isOpen={isOpen}
        commercialMaterial={commercialMaterial}
        handleClose={handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const DeleteCommercialMaterialButton = ({commercialMaterial, refetch}) => {
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <React.Fragment>
      <IconButtonDelete onPress={handleOpen} />
      <DeleteCommercialMaterialModal
        isOpen={isOpen}
        commercialMaterial={commercialMaterial}
        handleClose={handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const CommercialMaterialRow = ({commercialMaterial, refetch}) => {
  return (
    <TableV1Builder.TableRow key={commercialMaterial.id}>
      <TableV1Builder.TableItem width={220} text={commercialMaterial.name} />
      <TableV1Builder.TableItem width={100} text={`${commercialMaterial.quantityRequested}`} />
      <TableV1Builder.TableItem width={100} text={`${commercialMaterial.quantityDelivered}`} />
      <TableV1Builder.TableItem
        width={100}
        text={`${Currency.display(commercialMaterial.price)}`}
      />
      <TableV1Builder.TableItem width={140}>
        <Row>
          <EditCommercialMaterialButton commercialMaterial={commercialMaterial} refetch={refetch} />
          <Space width={5} />
          <DeleteCommercialMaterialButton
            commercialMaterial={commercialMaterial}
            refetch={refetch}
          />
        </Row>
      </TableV1Builder.TableItem>
    </TableV1Builder.TableRow>
  );
};

const CommercialMaterialSearchBar = ({job, form, handleOpen}) => {
  const getSearchResults = (commercialMaterialTypes) => {
    return commercialMaterialTypes.map((commercialMaterialType) => ({
      text: commercialMaterialType.name,
      data: {commercialMaterialType},
    }));
  };

  return (
    <SearchBar
      placeholder={'Add from library...'}
      results={getSearchResults(job.organization.commercialMaterialTypes)}
      onSelect={(row) => {
        const {data} = row;
        form.setFieldValue('commercialMaterialForm.name', data.commercialMaterialType.name);
        form.setFieldValue(
          'commercialMaterialForm.price',
          Currency.toForm(data.commercialMaterialType.price),
        );
        form.setFieldValue(
          'commercialMaterialForm.commercialMaterialTypeId',
          data.commercialMaterialType.id,
        );
        handleOpen();
      }}
    />
  );
};

const EditCommercialMaterialModalContent = ({job, refetch}) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const commercialMaterialForm = CommercialMaterialForm.toForm(
    CommercialMaterialForm.new({
      commercialCatalogId: job.commercialCatalog.id,
      name: '',
      price: '',
    }),
  );

  const {form, submitting, handleSubmit} = useCreateCommercialMaterialMutation({
    commercialMaterialForm,
    onSuccess: () => {
      handleClose();
      refetch();
      form.setValues({
        commercialMaterialForm: CommercialMaterialForm.toForm(
          CommercialMaterialForm.new({
            commercialCatalogId: job.commercialCatalog.id,
            name: '',
            price: '',
          }),
        ),
      });
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <Content>
      <Title>Edit Materials</Title>
      <Space height={20} />
      <Section index={0}>
        <CommercialMaterialSearchBar job={job} handleOpen={handleOpen} form={form} />
      </Section>
      <Space height={20} />
      <ScrollView>
        <ScrollView horizontal>
          <TableSection
            index={1}
            count={job.commercialCatalog.commercialMaterials.length}
            title={'Material'}
          >
            <Space height={10} />
            <Actions>
              <Button onPress={handleOpen}>
                <Text>Create an Material</Text>
              </Button>
            </Actions>
            <CreateCommercialMaterialModal
              key={isOpen}
              isOpen={isOpen}
              form={form}
              submitting={submitting}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
            />
            <Space height={10} />
            <TableV1>
              <TableV1Builder.TableRow>
                <TableV1Builder.TableHeaderItem width={220} text={'Material Name'} />
                <TableV1Builder.TableHeaderItem width={100} text={'Requested'} />
                <TableV1Builder.TableHeaderItem width={100} text={'Used'} />
                <TableV1Builder.TableHeaderItem width={100} text={'Price'} />
                <TableV1Builder.TableHeaderItem width={140} text={'Actions'} />
              </TableV1Builder.TableRow>
              {job.commercialCatalog.commercialMaterials.map((commercialMaterial) => (
                <CommercialMaterialRow
                  key={commercialMaterial.id}
                  commercialMaterial={commercialMaterial}
                  refetch={refetch}
                />
              ))}
            </TableV1>
          </TableSection>
        </ScrollView>
      </ScrollView>
    </Content>
  );
};

const EditCommercialMaterialModal = ({isOpen, jobUuid, handleClose}) => {
  return (
    <LargeModal isOpen={isOpen} width={800} handleClose={handleClose}>
      <Query
        fetchPolicy={'network-only'}
        variables={{uuid: jobUuid}}
        query={EditCommercialMaterialModal.query}
      >
        {({loading, data, refetch}) =>
          loading ? (
            <Indicator size={'large'} color={colors.gray.secondary} />
          ) : (
            <EditCommercialMaterialModalContent
              job={data.job}
              handleClose={handleClose}
              refetch={refetch}
            />
          )
        }
      </Query>
    </LargeModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditCommercialMaterialModal.query = gql`
  ${UpdateCommercialMaterialModal.fragment}
  ${DeleteCommercialMaterialModal.fragment}
  query EditCommercialMaterialModal($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      commercialCatalog {
        id
        commercialMaterials {
          id
          name
          quantityDelivered
          quantityRequested
          price
          ...DeleteCommercialMaterialModal
          ...UpdateCommercialMaterialModal
        }
      }
      organization {
        id
        commercialMaterialTypes {
          id
          name
          price
        }
      }
    }
  }
`;

export default EditCommercialMaterialModal;
