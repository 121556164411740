// Note: This page now always redirects to the job-level confirmation page. This is to redirect
// any job-level confirmation links customers may have.

// Libraries
import React from 'react';
import {Redirect} from 'react-router-dom';

// Components
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';

import {Page} from 'modules/App/components';

const ConfirmJobPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <Page fetchPolicy={'network-only'} query={ConfirmJobPage.query} variables={{uuid}}>
    {({loading, data}) => (
      <Loading loading={loading}>
        {() => {
          return <Redirect to={`/0/${slug}/projects/${data.job.project.uuid}/confirmation`} />;
        }}
      </Loading>
    )}
  </Page>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmJobPage.query = gql`
  query ConfirmJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      project {
        id
        uuid
      }
    }
  }
`;

export default ConfirmJobPage;
