import {FunctionDefinition} from '@shared/formulas/src/library/functions';
import ExtractVariableNamesPlugin from '@shared/formulas/src/plugins/ExtractVariableNamesPlugin';

class EvaluateFormulaStringInput {
  formula: string;

  variables: any[];

  plugins: ExtractVariableNamesPlugin[];

  customFunctionImplementations: {[name: string]: FunctionDefinition};

  constructor(
    formula: string,
    variables?: any[],
    plugins?: ExtractVariableNamesPlugin[],
    customFunctionImplementations?: {[name: string]: FunctionDefinition},
  ) {
    this.formula = formula;
    this.variables = variables || [];
    this.plugins = plugins || [new ExtractVariableNamesPlugin()];
    this.customFunctionImplementations = customFunctionImplementations || {};
  }
}

export {EvaluateFormulaStringInput};
