// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, usePagination} from '@supermove/hooks';
import {URL} from '@supermove/utils';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import WorkflowRunsFilters from 'modules/Organization/Settings/Workflow/components/WorkflowRunsFilters';
import WorkflowRunsTable from 'modules/Organization/Settings/Workflow/components/WorkflowRunsTable';

const Section = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Container = Styled.View`
`;

const PageContainer = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  padding: 24px;
  flex: 1;
  min-width: 1200px;
`;

const getPageUrl = ({params, page}) => {
  const {workflowUuid} = params;
  const variables = {page};
  return URL.getUrlFromVariables(`/settings/automations/${workflowUuid}/runs`, variables);
};

const PageHeader = ({workflowRunName}) => {
  const {navigator} = useNavigationDOM();
  const breadcrumbs = [
    {name: 'Settings', onPress: () => navigator.push('/settings')},
    {
      name: 'Automations',
      onPress: () => navigator.push('/settings/automations'),
      isBeta: true,
    },
    {name: workflowRunName},
  ];

  return (
    <Container>
      <StandardOfficeHeader title={''} showLine={false}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </StandardOfficeHeader>
    </Container>
  );
};

const OrganizationSettingsWorkflowRunsPageContent = ({workflowRuns, pagination, refetch}) => {
  return (
    <PageContentContainer>
      <Section index={0}>
        <Row>
          <WorkflowRunsFilters />
        </Row>
      </Section>
      <Space height={32} />
      <Section index={1} style={{flex: 1}}>
        <WorkflowRunsTable workflowRuns={workflowRuns} refetch={refetch} />
      </Section>
      <Space height={32} />
      <PaginationBar pagination={pagination} />
    </PageContentContainer>
  );
};

const OrganizationSettingsWorkflowRunsPage = () => {
  const {params, navigator} = useNavigationDOM();

  const {loading, data, refetch} = useQuery(OrganizationSettingsWorkflowRunsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      workflowUuid: params.workflowUuid,
      pagination: {
        page: params.page,
        resultsPerPage: 20,
      },
      searchQuery: params.query,
      fromDate: params.fromDate,
      toDate: params.toDate,
      statuses: params.statuses,
    },
  });

  const pagination = usePagination({
    currentPage: _.toNumber(params.page || 1),
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page) => {
      navigator.replace(getPageUrl({params, page}));
    },
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading loading={loading}>
        {() => (
          <PageContainer>
            <PageHeader workflowRunName={data.workflow.name} />
            <OrganizationSettingsWorkflowRunsPageContent
              workflowRuns={data.paginatedList.workflowRuns}
              pagination={pagination}
              refetch={refetch}
            />
          </PageContainer>
        )}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsWorkflowRunsPage.query = gql`
  ${usePagination.fragment}
  ${WorkflowRunsTable.fragment}
  query OrganizationSettingsWorkflowRunsPage(
    $pagination: PaginationInput!
    $workflowUuid: String!
    $searchQuery: String
    $fromDate: String
    $toDate: String
    $statuses: [String]
  ) {
    ${gql.query}
    paginatedList: filteredWorkflowRunsPaginatedList(
      pagination: $pagination,
      workflowUuid: $workflowUuid,
      searchQuery: $searchQuery,
      fromDate: $fromDate,
      toDate: $toDate,
      statuses: $statuses,
    ) {
      workflowRuns: results {
        id
        ...WorkflowRunsTable
      }
      paginationMetadata {
        ...usePagination
      }
    }
    workflow(uuid: $workflowUuid) {
      id
      name
    }
  }
`;

export default OrganizationSettingsWorkflowRunsPage;
