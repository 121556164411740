// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import DocumentTemplateV2Form from '@shared/modules/Document/forms/DocumentTemplateV2Form';
import useDuplicateDocumentTemplateV2Mutation from '@shared/modules/Document/hooks/useDuplicateDocumentTemplateV2Mutation';
import DefaultQuoteConfirmationDocumentTemplateFields from 'modules/Organization/Settings/Document/components/DefaultQuoteConfirmationDocumentTemplateFields';

const DuplicateDocumentTemplateDrawer = ({
  duplicateDocumentTemplateDrawer,
  userId,
  documentTemplate,
  isEnabledDocumentSections,
}) => {
  const {navigator} = useNavigationDOM();
  const documentTemplateV2Form = DocumentTemplateV2Form.copy(documentTemplate, {userId});
  const {form, handleSubmit, submitting} = useDuplicateDocumentTemplateV2Mutation({
    documentTemplateV2Form,
    onSuccess: (response) => {
      const documentTemplateUuid = _.get(response, 'documentTemplateV2.uuid');
      const versionUuid = _.get(response, 'documentTemplateV2.draftTemplateVersion.uuid');
      if (isEnabledDocumentSections) {
        navigator.push(
          `/settings/documents/document-templates/${documentTemplateUuid}/version/${versionUuid}/edit`,
        );
      } else {
        navigator.push(
          `/settings/documents/document-templates/${documentTemplateUuid}/version/${versionUuid}`,
        );
      }
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DrawerWithAction
      isOpen={duplicateDocumentTemplateDrawer.isOpen}
      handleClose={duplicateDocumentTemplateDrawer.handleClose}
      headerText={'Duplicate Document Template'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      primaryActionText={'Duplicate'}
    >
      <FieldInput
        {...form}
        index={0}
        label={'Name'}
        name={'documentTemplateV2Form.name'}
        input={{
          required: !_.get(form.values, 'documentTemplateV2Form.name'),
          placeholder: 'Enter a name',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        label={'Description'}
        name={'documentTemplateV2Form.description'}
        input={{placeholder: 'Enter a description'}}
      />
      <Space height={16} />
      <SwitchField
        index={2}
        form={form}
        field={'documentTemplateV2Form.shouldEmailDocumentToCustomer'}
        labelRight={'Email this document to customers at the end of a move'}
      />
      <Space height={16} />
      <DefaultQuoteConfirmationDocumentTemplateFields
        form={form}
        field={'documentTemplateV2Form'}
        isCreate
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DuplicateDocumentTemplateDrawer.fragment = gql`
  ${DocumentTemplateV2Form.copy.fragment}
  fragment DuplicateDocumentTemplateDrawer on DocumentTemplateV2 {
    id
    ...DocumentTemplateV2Form_copy
  }
`;

export default DuplicateDocumentTemplateDrawer;
