// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, TimeInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {TimesheetBlock} from '@supermove/models';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import TimesheetBlockKindDropdown from '@shared/modules/Timesheet/components/TimesheetBlockKindDropdown';

const Row = Styled.View`
  flex-direction: row;
`;

const IndexedContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const FlexContainer = Styled.View`
  flex: ${({
    // @ts-expect-error TS(2339): Property 'flex' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    flex,
  }) => flex};
`;

const handleRemoveTimesheetBlock = ({form, field, listField, index}: any) => {
  const timesheetBlockFormsList = _.get(form.values, listField);
  const timesheetBlockForm = timesheetBlockFormsList[index];
  const isNew = !timesheetBlockForm.timesheetBlockId;
  const updatedTimesheetBlockFormsList = List.remove(timesheetBlockFormsList, index);
  form.setFieldValue(listField, updatedTimesheetBlockFormsList);
  if (!isNew) {
    const deleteListField = `${field}.timesheetBlocksDelete`;
    const deleteList = _.get(form.values, deleteListField);
    const updatedDeleteList = [...deleteList, timesheetBlockForm];
    form.setFieldValue(deleteListField, updatedDeleteList);
  }
};

const TimesheetBlockInputLabels = () => {
  return (
    <Row>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FlexContainer flex={3}>
        <FieldInput.LabelText isResponsive>Event Type</FieldInput.LabelText>
      </FlexContainer>
      <Space width={16} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FlexContainer flex={2}>
        <FieldInput.LabelText isResponsive>Start Time</FieldInput.LabelText>
      </FlexContainer>
      <Space width={16} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FlexContainer flex={2}>
        <FieldInput.LabelText isResponsive>End Time</FieldInput.LabelText>
      </FlexContainer>
      <Space width={48} />
    </Row>
  );
};

const TimesheetBlockInputs = ({
  form,
  field,
  timesheetBlock,
  isEditable,
  index,
  handleDelete,
}: any) => {
  const rowError = _.get(form.errors, `${field}`);
  const isMultiInputError = typeof rowError === 'string';

  // If we have a timesheet block, we are showing a billable entry timesheetBlock. If we
  // don't have a timesheet block, we are showing a payable entry timesheetBlockForm that
  // can be edited if not disabled.

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <IndexedContainer index={index}>
      <Space height={8} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Row index={index}>
        <TimesheetBlockKindDropdown
          form={form}
          field={`${field}.kind`}
          style={{flex: 3}}
          value={timesheetBlock?.kind}
          isDisabled={!isEditable}
        />
        <Space width={16} />
        <FieldInput
          {...form}
          name={`${field}.rangeFrom`}
          component={TimeInput}
          value={timesheetBlock ? TimesheetBlock.getDisplayRangeFrom(timesheetBlock) : null}
          isResponsive
          style={{flex: 2}}
          input={{
            component: FieldInput.TextInput,
            placeholder: 'Start time',
            disabled: !isEditable,
            setFieldValue: form.setFieldValue,
            style: rowError && isMultiInputError ? {borderColor: colors.red.warning} : null,
          }}
        />
        <Space width={16} />
        <FieldInput
          {...form}
          name={`${field}.rangeTo`}
          component={TimeInput}
          value={timesheetBlock ? TimesheetBlock.getDisplayRangeTo(timesheetBlock) : null}
          isResponsive
          style={{flex: 2}}
          input={{
            component: FieldInput.TextInput,
            placeholder: 'End time',
            disabled: !isEditable,
            initialValue: timesheetBlock ? null : _.get(form.values, `${field}.rangeFrom`),
            setFieldValue: form.setFieldValue,
            style: rowError && isMultiInputError ? {borderColor: colors.red.warning} : null,
          }}
        />
        <Space width={32} />
        {isEditable ? (
          <TertiaryButton onPress={handleDelete}>
            {/* @ts-expect-error TS(2322): Type '{ source: "trash"; color: "#c53a33"; size: n... Remove this comment to see the full error message */}
            <Icon source={Icon.Trash} color={colors.red.warning} size={16} isHitSlop />
          </TertiaryButton>
        ) : (
          <Space width={16} />
        )}
      </Row>
      {isMultiInputError && (
        <React.Fragment>
          <Space height={4} />
          <Row>
            <Space style={{flex: 3}} />
            <Space width={16} />
            <Space style={{flex: 4}}>
              <FieldInput.CaptionText hasErrors isResponsive>
                {rowError}
              </FieldInput.CaptionText>
            </Space>
            <Space width={60} />
          </Row>
        </React.Fragment>
      )}
      <Space height={8} />
    </IndexedContainer>
  );
};

const BlocksToUpdate = ({form, field, isDisabled}: any) => {
  const listField = `${field}.timesheetBlocksUpdate`;

  return (
    <React.Fragment>
      {_.get(form.values, listField).map((timesheetBlockForm: any, index: any) => {
        return (
          <TimesheetBlockInputs
            key={index}
            form={form}
            field={`${listField}.${index}`}
            isEditable={!isDisabled}
            index={index}
            handleDelete={() => handleRemoveTimesheetBlock({form, field, listField, index})}
          />
        );
      })}
    </React.Fragment>
  );
};

const BlocksToCreate = ({form, field}: any) => {
  const listField = `${field}.timesheetBlocksCreate`;

  return (
    <React.Fragment>
      {_.get(form.values, listField).map((timesheetBlockForm: any, index: any) => {
        return (
          <TimesheetBlockInputs
            key={index}
            form={form}
            field={`${listField}.${index}`}
            isEditable
            index={index}
            handleDelete={() => handleRemoveTimesheetBlock({form, field, listField, index})}
          />
        );
      })}
    </React.Fragment>
  );
};

const EditTimesheetPayrollEntryTimesheetBlocks = ({
  form,
  field,
  timesheetBlocks,
  showLabels,
  isDisabled,
}: any) => {
  return (
    <React.Fragment>
      {showLabels && <TimesheetBlockInputLabels />}
      {timesheetBlocks ? (
        timesheetBlocks.map((timesheetBlock: any, index: any) => {
          return (
            <TimesheetBlockInputs
              key={timesheetBlock.id}
              form={form}
              timesheetBlock={timesheetBlock}
              index={index}
            />
          );
        })
      ) : (
        <React.Fragment>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <IndexedContainer index={0}>
            <BlocksToUpdate form={form} field={field} isDisabled={isDisabled} />
          </IndexedContainer>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <IndexedContainer index={1}>
            <BlocksToCreate form={form} field={field} />
          </IndexedContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditTimesheetPayrollEntryTimesheetBlocks.fragment = gql`
  ${TimesheetBlock.getDisplayRangeFrom.fragment}
  ${TimesheetBlock.getDisplayRangeTo.fragment}

  fragment EditTimesheetPayrollEntryTimesheetBlocks on TimesheetBlock {
    id
    kind
    isBillable
    ...TimesheetBlock_getDisplayRangeFrom
    ...TimesheetBlock_getDisplayRangeTo
  }
`;

export default EditTimesheetPayrollEntryTimesheetBlocks;
