// Libraries
import _ from 'lodash';

// Supermove
import {BillModifierForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {Currency, Percent, withFragment} from '@supermove/utils';

// Models
import BillItemType from './BillItemType';
import LineItem from './LineItem';

const toMutationDiscountPercentage = ({discountPercentage}: any) => {
  // @ts-expect-error TS(2345): Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
  return Math.abs(Percent.toMutation(discountPercentage || 0)) * -1;
};

const getEstimateBalance = withFragment(
  (bill) => {
    // @ts-expect-error TS(2339): Property 'estimateBalanceMin' does not exist on ty... Remove this comment to see the full error message
    const {estimateBalanceMin, estimateBalanceMax} = bill;
    // @ts-expect-error TS(2345): Argument of type '{ min: any; max: any; }' is not ... Remove this comment to see the full error message
    return Currency.formatRange({
      min: estimateBalanceMin,
      max: estimateBalanceMax,
    });
  },
  gql`
    fragment Bill_getEstimateBalance on Bill {
      estimateBalanceMin
      estimateBalanceMax
    }
  `,
);

const getEstimateBalanceWithoutTip = withFragment(
  (bill) => {
    if ((bill as any).estimateBalanceMin !== (bill as any).estimateBalanceMax) {
      return (
        `${Currency.display((bill as any).estimateBalanceMin - (bill as any).tip, {shouldHideCentsIfZero: true})}` +
        ` - ${Currency.display((bill as any).estimateBalanceMax - (bill as any).tip, {shouldHideCentsIfZero: true})}`
      );
    } else {
      return Currency.display((bill as any).estimateBalanceMin - (bill as any).tip, {
        shouldHideCentsIfZero: true,
      });
    }
  },
  gql`
    fragment Bill_getEstimateBalanceWithoutTip on Bill {
      estimateBalanceMin
      estimateBalanceMax
      tip
    }
  `,
);

const getEstimateDiscount = withFragment(
  (bill) => {
    if ((bill as any).estimateDiscountMin !== (bill as any).estimateDiscountMax) {
      return (
        `(${Currency.display(-(bill as any).estimateDiscountMin, {shouldHideCentsIfZero: true})}` +
        ` - ${Currency.display(-(bill as any).estimateDiscountMax, {shouldHideCentsIfZero: true})})`
      );
    } else {
      return `(${Currency.display(-(bill as any).estimateDiscountMin, {shouldHideCentsIfZero: true})})`;
    }
  },
  gql`
    fragment Bill_getEstimateDiscount on Bill {
      estimateDiscountMin
      estimateDiscountMax
    }
  `,
);

const getEstimateSalesTaxAmount = withFragment(
  (bill) => {
    if ((bill as any).estimateSalesTaxAmountMin !== (bill as any).estimateSalesTaxAmountMax) {
      return (
        `${Currency.display((bill as any).estimateSalesTaxAmountMin, {shouldHideCentsIfZero: true})}` +
        ` - ${Currency.display((bill as any).estimateSalesTaxAmountMax, {shouldHideCentsIfZero: true})}`
      );
    } else {
      return Currency.display((bill as any).estimateSalesTaxAmountMin, {
        shouldHideCentsIfZero: true,
      });
    }
  },
  gql`
    fragment Bill_getEstimateSalesTaxAmount on Bill {
      estimateSalesTaxAmountMin
      estimateSalesTaxAmountMax
    }
  `,
);

const getEstimateSubtotal = withFragment(
  (bill) => {
    if ((bill as any).estimateSubtotalMin !== (bill as any).estimateSubtotalMax) {
      return (
        `${Currency.display((bill as any).estimateSubtotalMin, {shouldHideCentsIfZero: true})}` +
        ` - ${Currency.display((bill as any).estimateSubtotalMax, {shouldHideCentsIfZero: true})}`
      );
    } else {
      return Currency.display((bill as any).estimateSubtotalMin, {shouldHideCentsIfZero: true});
    }
  },
  gql`
    fragment Bill_getEstimateSubtotal on Bill {
      estimateSubtotalMin
      estimateSubtotalMax
    }
  `,
);

const getEstimateTotal = withFragment(
  (bill) => {
    if ((bill as any).estimateTotalMin !== (bill as any).estimateTotalMax) {
      // @ts-expect-error TS(2345): Argument of type '{ min: any; max: any; }' is not ... Remove this comment to see the full error message
      return Currency.formatRange({
        min: (bill as any).estimateTotalMin,
        max: (bill as any).estimateTotalMax,
      });
    } else {
      return Currency.format({value: (bill as any).estimateTotalMin});
    }
  },
  gql`
    fragment Bill_getEstimateTotal on Bill {
      estimateTotalMin
      estimateTotalMax
    }
  `,
);

const getJobName = withFragment(
  (bill) => {
    return _.get(bill, 'job.fullName') || '';
  },
  gql`
    fragment Bill_getJobName on Bill {
      id
      job {
        id
        fullName
      }
    }
  `,
);

const getBillModifierTypesForBill = withFragment(
  (bill) => {
    return BillItemType.getBillModifierTypesFromBillItemTypes(
      (bill as any).project.projectType.billingLibrary.billItemTypes,
    );
  },
  gql`
    ${BillItemType.getBillModifierTypesFromBillItemTypes.fragment}
    fragment Bill_getBillModifierTypesForBill on Bill {
      id
      project {
        id
        projectType {
          id
          billingLibrary {
            id
            billItemTypes {
              id
              ...BillItemType_getBillModifierTypesFromBillItemTypes
            }
          }
        }
      }
    }
  `,
);

const getTemplateLineItemsForBill = withFragment(
  (bill) => {
    return BillItemType.getTemplateLineItemsFromBillItemTypes(
      (bill as any).project.projectType.billingLibrary.billItemTypes,
    );
  },
  gql`
    ${BillItemType.getTemplateLineItemsFromBillItemTypes.fragment}
    fragment Bill_getTemplateLineItemsForBill on Bill {
      id
      project {
        id
        projectType {
          id
          billingLibrary {
            id
            billItemTypes {
              id
              ...BillItemType_getTemplateLineItemsFromBillItemTypes
            }
          }
        }
      }
    }
  `,
);

const getBillRuleTypesForBill = withFragment(
  (bill) => {
    return (bill as any).project.projectType.billingLibrary.billRuleTypes;
  },
  gql`
    fragment Bill_getBillRuleTypesForBill on Bill {
      id
      project {
        id
        projectType {
          id
          billingLibrary {
            id
            billRuleTypes {
              id
              kind
              name
              description
              value
              valueFormulaId
              isVisibleToCustomer
            }
          }
        }
      }
    }
  `,
);

const Bill = {
  // We do not include 'JOB_ESTIMATE' in `KINDS_ORDER` because
  // we do not want to render the estimate bill to the customer.
  KINDS_ORDER: ['JOB_MAIN', 'JOB_ADDITIONAL', 'CUSTOM'],
  KINDS: {
    JOB_MAIN: 'JOB_MAIN',
    JOB_ADDITIONAL: 'JOB_ADDITIONAL',
    JOB_ESTIMATE: 'JOB_ESTIMATE',
    CUSTOM: 'CUSTOM',
  },
  getBillModifierTypesForBill,
  getBillRuleTypesForBill,
  getTemplateLineItemsForBill,
  getEstimateBalance,
  getEstimateBalanceWithoutTip,
  getEstimateDiscount,
  getEstimateSalesTaxAmount,
  getEstimateSubtotal,
  getEstimateTotal,
  getJobName,

  getFinishScreen: ({kind}: any) => {
    switch (kind) {
      case 'JOB_MAIN':
        return 'PassFinishCustomerJob';
      case 'JOB_ADDITIONAL':
      case 'CUSTOM':
      default:
        return 'FinishCustomerBillJob';
    }
  },

  getFinishParams: ({kind}: any, params: any) => {
    switch (kind) {
      case 'JOB_MAIN':
        return {uuid: params.uuid};
      case 'JOB_ADDITIONAL':
      case 'CUSTOM':
      default:
        return {uuid: params.uuid, billUuid: params.billUuid};
    }
  },

  getTitle: ({kind}: any) => {
    switch (kind) {
      case Bill.KINDS.JOB_ESTIMATE:
        return 'Estimate Bill';
      case Bill.KINDS.JOB_MAIN:
        return 'Main Bill';
      case Bill.KINDS.JOB_ADDITIONAL:
      case Bill.KINDS.CUSTOM:
      default:
        return 'Additional Charge Bill';
    }
  },

  getSigningSubtitle: ({kind}: any) => {
    switch (kind) {
      case Bill.KINDS.JOB_MAIN:
        return 'By signing, you agree that all of your items have been properly moved and fully inspected.';
      case Bill.KINDS.JOB_ADDITIONAL:
      case Bill.KINDS.CUSTOM:
      default:
        return 'By signing, you confirm that you have paid the total amount.';
    }
  },

  /**
   * NOTE
   * `toForm` and `toMutation` do not contain all the fields of
   * the `Bill` table. This is because we previously did not use
   * the `toForm` and `toMutation` pattern. If you find yourself
   * using these methods, you can finish this up and remove this comment.
   */
  toForm: ({id, billId, discountPercentage, lineItems = [], billModifierForms = []}: any) => ({
    billId: id || billId,
    discountPercentage: discountPercentage ? Percent.toForm(Math.abs(discountPercentage)) : '',
    // @ts-expect-error TS(7006): Parameter 'lineItem' implicitly has an 'any' type.
    lineItems: lineItems.map((lineItem) => LineItem.toForm(lineItem)),
    // @ts-expect-error TS(7006): Parameter 'billModifierForm' implicitly has an 'an... Remove this comment to see the full error message
    billModifierForms: billModifierForms.map((billModifierForm) => {
      return BillModifierForm.toForm(billModifierForm);
    }),
  }),

  toMutation: ({billId, discountPercentage, lineItems = [], billModifierForms = []}: any) => ({
    billId,
    discountPercentage: toMutationDiscountPercentage({discountPercentage}),
    // @ts-expect-error TS(7006): Parameter 'lineItem' implicitly has an 'any' type.
    lineItems: lineItems.map((lineItem) => LineItem.toMutation(lineItem)),
    // @ts-expect-error TS(7006): Parameter 'billModifierForm' implicitly has an 'an... Remove this comment to see the full error message
    billModifierForms: billModifierForms.map((billModifierForm) => {
      return BillModifierForm.toMutation(billModifierForm);
    }),
  }),
};

export default Bill;
