import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const TASKS = 'TASKS';
const WORKFLOWS = 'WORKFLOWS';
const COMMENTS = 'COMMENTS';
const SMS = 'SMS';
const EMAILS = 'EMAILS';
const DOCUMENTS = 'DOCUMENTS';
const ATTACHMENTS = 'ATTACHMENTS';
const EVENT_LOG = 'EVENT_LOG';

const VALUES = [TASKS, COMMENTS, SMS, EMAILS, DOCUMENTS, ATTACHMENTS, WORKFLOWS, EVENT_LOG];

const KIND_TO_DISPLAY = {
  TASKS: 'Tasks',
  COMMENTS: 'Comments',
  SMS: 'SMS',
  EMAILS: 'Emails',
  DOCUMENTS: 'Documents',
  ATTACHMENTS: 'Attachments',
  WORKFLOWS: 'Automations',
  EVENT_LOG: 'Event Log',
};

const KIND_TO_ICON = {
  TASKS: Icon.CheckCircle,
  COMMENTS: Icon.CommentPen,
  SMS: Icon.CommentSms,
  EMAILS: Icon.Envelope,
  DOCUMENTS: Icon.FileLines,
  ATTACHMENTS: Icon.Paperclip,
  WORKFLOWS: Icon.Bolt,
  EVENT_LOG: Icon.ClockRotateLeft,
};

const getDisplay = (projectWidgetKind: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return KIND_TO_DISPLAY[projectWidgetKind];
};

const getIcon = (projectWidgetKind: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return KIND_TO_ICON[projectWidgetKind];
};

const getOrganizationWidgets = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ organization }: { organizatio... Remove this comment to see the full error message
  ({organization}) =>
    VALUES.filter((widgetKind) => {
      if (widgetKind === WORKFLOWS && !organization.features.isEnabledProjectPageWorkflowsWidget) {
        return false;
      }
      return true;
    }),
  gql`
    fragment ProjectWidgetKind_getOrganizationWidgets on Organization {
      id
      features {
        isEnabledProjectPageWorkflowsWidget: isEnabled(feature: "PROJECT_PAGE_WORKFLOWS_WIDGET")
      }
    }
  `,
);

const getErrorTitle = (projectWidgetKind: any) => {
  switch (projectWidgetKind) {
    case TASKS:
      return 'Could not load tasks.';
    case COMMENTS:
      return 'Could not load comments.';
    case SMS:
      return 'Could not load messages.';
    case EMAILS:
      return 'Could not load emails.';
    case DOCUMENTS:
      return 'Could not load documents.';
    case ATTACHMENTS:
      return 'Could not load attachments.';
    case WORKFLOWS:
      return 'Could not load automations.';
    case EVENT_LOG:
      return 'Could not load event log.';
    default:
      return 'An error has occurred.';
  }
};

export default {
  TASKS,
  WORKFLOWS,
  COMMENTS,
  SMS,
  EMAILS,
  DOCUMENTS,
  ATTACHMENTS,
  EVENT_LOG,

  VALUES,

  getDisplay,
  getIcon,
  getOrganizationWidgets,
  getErrorTitle,
};
