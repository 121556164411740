// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Tabs from '@shared/design/components/Tabs';

const TabsContainer = Styled.View`
  border-bottom-width: 1px;
  margin-horizontal: 24px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.border};
`;

const tabs = [
  {
    label: 'Warehouses',
    url: `/settings/storage/warehouses`,
  },
  {
    label: 'Container Types',
    url: `/settings/storage/container-types`,
  },
];

const StorageSettingsPageNavigationTabs = () => {
  const {navigator} = useNavigationDOM();
  const currentTab = _.findIndex(tabs, {url: navigator.location.pathname});

  return (
    <TabsContainer>
      <Tabs
        tabs={tabs}
        handlePressTab={(tab) => navigator.push(tab.url)}
        activeTabIndex={currentTab}
      />
    </TabsContainer>
  );
};

export default StorageSettingsPageNavigationTabs;
