// Libararies
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useSidebar, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import StorageContainerSidebar from 'modules/Storage/components/StorageContainerSidebar';

const View = Styled.View``;

const SidebarContainer = Styled.Touchable``;

const LinkButton = Styled.ButtonV2`
  max-width: 200px;
`;

const LinkButtonText = Styled.Text`
  ${Typography.Link}
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const ContainerSidebarLink = ({container, refetch}) => {
  const {data} = useQuery(ContainerSidebarLink.query, {fetchPolicy: 'cache-and-network'});
  const containerSidebar = useSidebar({
    name: 'Container Sidebar',
    enableTracking: true,
  });

  return (
    <View>
      <LinkButton onPress={containerSidebar.handleOpen}>
        <LinkButtonText numberOfLines={1}>{container.name}</LinkButtonText>
      </LinkButton>
      <SidebarContainer>
        <StorageContainerSidebar
          key={containerSidebar.key}
          isOpen={containerSidebar.isOpen}
          handleClose={containerSidebar.handleClose}
          containerUuid={container.uuid}
          viewer={data ? data.viewer : {}}
          refetch={refetch}
        />
      </SidebarContainer>
    </View>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ContainerSidebarLink.fragment = gql`
  fragment ContainerSidebarLink on Container {
    id
    name
    uuid
  }
`;

// TODO(cooper): Currently the container type mutations require us to pass
// the viewer. We should update this such that it grabs the viewer on the backend
// like we normally do. See CreateContainerTypeModal and EditContainerTypeModal.
// Hacking this for now, can remove after we make this change
ContainerSidebarLink.query = gql`
  query ContainerSidebarLink {
    ${gql.query}
    viewer {
      id
    }
  }
`;

export default ContainerSidebarLink;
