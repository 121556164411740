// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import BillsListItemPostSubtotalBillItemsListV1 from 'modules/Project/Billing/components/BillsListItemPostSubtotalBillItemsListV1';

const Row = Styled.View`
  flex-direction: row;
`;

const SplitRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  border-style: ${(props) => (props.dashed ? 'dashed' : 'solid')};
`;

const LineItemName = Styled.H7`
  color: ${colors.gray.primary};
  flex: 4;
`;

const LineItemDetailContainer = Styled.View`
  align-items: flex-end;
  flex: ${(props) => props.flex};
`;

const LineItemDetailText = Styled.H7`
  color: ${(props) => props.color};
  text-align: right;
`;

const MobileLineItemText = Styled.H7`
  color: ${(props) => props.color};
  ${fontWeight(500)};
`;

const AlignRightColumn = Styled.View`
  flex: 8;
  align-items: flex-end;
`;

const BillTotalText = Styled.H7`
  color: ${(props) => props.color};
  ${fontWeight(700)}
`;

const LineItemDetail = ({icon, children, color, flex}) => {
  return (
    <LineItemDetailContainer flex={flex}>
      <Row>
        {icon}
        <LineItemDetailText color={color}>{children}</LineItemDetailText>
      </Row>
    </LineItemDetailContainer>
  );
};

const BillsListItemSubtotalSectionV1 = ({bill, isEditable}) => {
  const responsive = useResponsive();
  const {estimateSubtotalMin: min, estimateSubtotalMax: max} = bill;
  const subtotal = bill.isSubtotalAvailable ? Currency.formatRange({min, max}) : 'TBD';
  return (
    <React.Fragment>
      <Space height={isEditable ? 12 : 8} />
      {responsive.mobile ? (
        <SplitRow>
          <BillTotalText color={colors.gray.primary}>Subtotal</BillTotalText>
          <BillTotalText color={colors.gray.primary}>{subtotal}</BillTotalText>
        </SplitRow>
      ) : (
        <Row>
          <AlignRightColumn>
            <BillTotalText color={colors.gray.primary}>Subtotal</BillTotalText>
          </AlignRightColumn>
          <LineItemDetail flex={3}>
            <BillTotalText color={colors.gray.primary}>{subtotal}</BillTotalText>
          </LineItemDetail>
        </Row>
      )}
      <BillsListItemPostSubtotalBillItemsListV1 bill={bill} />
      {bill.tip > 0 && (
        <React.Fragment>
          {responsive.mobile ? (
            <SplitRow>
              <MobileLineItemText color={colors.gray.primary}>Tip</MobileLineItemText>
              <MobileLineItemText color={colors.gray.primary}>
                {Currency.format({value: bill.tip})}
              </MobileLineItemText>
            </SplitRow>
          ) : (
            <React.Fragment>
              <Space height={4} />
              <Row>
                <AlignRightColumn>
                  <LineItemName>Tip</LineItemName>
                </AlignRightColumn>
                <LineItemDetail flex={3}>{Currency.format({value: bill.tip})}</LineItemDetail>
              </Row>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {(bill.estimateSalesTaxAmountMin > 0 || bill.estimateSalesTaxAmountMax > 0) && (
        <React.Fragment>
          <Space height={4} />
          {responsive.mobile ? (
            <SplitRow>
              <MobileLineItemText color={colors.gray.primary}>{`Sales Tax (${Percent.display(
                bill.project.salesTaxRate,
              )})`}</MobileLineItemText>
              <MobileLineItemText color={colors.gray.primary}>
                {Currency.formatRange({
                  min: bill.estimateSalesTaxAmountMin,
                  max: bill.estimateSalesTaxAmountMax,
                })}
              </MobileLineItemText>
            </SplitRow>
          ) : (
            <Row>
              <AlignRightColumn>
                <LineItemName>{`Sales Tax (${Percent.display(
                  bill.project.salesTaxRate,
                )})`}</LineItemName>
              </AlignRightColumn>
              <LineItemDetail flex={3}>
                {Currency.formatRange({
                  min: bill.estimateSalesTaxAmountMin,
                  max: bill.estimateSalesTaxAmountMax,
                })}
              </LineItemDetail>
            </Row>
          )}
        </React.Fragment>
      )}
      <Space height={isEditable ? 12 : 8} />
      <Line dashed={isEditable} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillsListItemSubtotalSectionV1.fragment = gql`
  ${BillsListItemPostSubtotalBillItemsListV1.fragment}

  fragment BillsListItemSubtotalSectionV1 on Bill {
    id
    isSubtotalAvailable
    estimateSubtotalMin
    estimateSubtotalMax
    tip
    estimateSalesTaxAmountMin
    estimateSalesTaxAmountMax
    ...BillsListItemPostSubtotalBillItemsListV1
    project {
      id
      salesTaxRate
    }
  }
`;

export default BillsListItemSubtotalSectionV1;
