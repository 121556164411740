// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (timesheetBlock) => ({
    timesheetBlockId: (timesheetBlock as any).id,
  }),
  gql`
    fragment DeleteTimesheetBlockForm_edit on TimesheetBlock {
      id
    }
  `,
);

const toForm = ({timesheetBlockId}: any) => ({
  timesheetBlockId,
});

const toMutation = ({timesheetBlockId}: any) => ({
  timesheetBlockId,
});

const DeleteTimesheetBlockForm = {
  edit,
  toForm,
  toMutation,
};

export default DeleteTimesheetBlockForm;
