// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {MultiDropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToggle} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Panel from '@shared/design/components/Panel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import OrganizationNotificationEmailsForm from '@shared/modules/Organization/forms/OrganizationNotificationEmailsForm';
import useUpdateOrganizationNotificationEmailsMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationNotificationEmailsMutation';

const Column = Styled.View``;

const Row = Styled.View`
  flex-direction: row;
`;

const EmptyStateText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const EmailSystemNotificationsPanelBody = ({organization}) => {
  const {notificationEmails} = organization.settings;
  return _.isEmpty(notificationEmails) ? (
    <EmptyStateText>No emails.</EmptyStateText>
  ) : (
    <Row>
      {notificationEmails.map((email, index) => (
        <Row key={index}>
          <Badge label={email} />
          <Space width={8} />
        </Row>
      ))}
    </Row>
  );
};

const EmailSystemNotificationsPanelEditBody = ({form, field}) => {
  const searchInputValue = _.get(form.values, `${field}.searchInputValue`);
  const currentNotificationEmails = _.get(form.values, `${field}.notificationEmails`);
  const options = currentNotificationEmails.map((value) => ({label: value, value}));

  return (
    <React.Fragment>
      <FieldInput
        {...form}
        component={MultiDropdownInput}
        name={`${field}.notificationEmails`}
        input={{
          options: searchInputValue
            ? [...options, {label: searchInputValue, value: searchInputValue}]
            : options,
          placeholder: 'Enter emails',
          setFieldValue: form.setFieldValue,
          isSearchable: true,
          onChangeValue: (values) => {
            form.setFieldValue(`${field}.notificationEmails`, values);
          },
          onInputChange: (value) => {
            form.setFieldValue(`${field}.searchInputValue`, value);
          },
          onBlur: (event) => {
            if (event.target?.value !== '') {
              form.setFieldValue(`${field}.notificationEmails`, [
                ...currentNotificationEmails,
                event.target.value,
              ]);
            }
          },
          components: {
            Menu: () => null,
            DropdownIndicator: () => null,
          },
          style: {width: '100%'},
        }}
        style={{flex: 1}}
      />
    </React.Fragment>
  );
};

const Header = ({isEditing, handleEdit, hasEditPermissions}) => (
  <Panel.Header>
    <Column style={{flex: 1}}>
      <Row style={{alignItems: 'center'}}>
        <Panel.HeaderText>System Notifications</Panel.HeaderText>
        <Space style={{flex: 1, minWidth: 12}} />
        {hasEditPermissions && (
          <EditPanel.EditButton isEditing={isEditing} handleEdit={handleEdit} />
        )}
      </Row>
      <Space height={8} />
      <Panel.Text style={{color: colors.gray.secondary}}>
        {`All system notification emails will be sent to these email addresses.`}
      </Panel.Text>
    </Column>
  </Panel.Header>
);

const EmailSystemNotificationsPanel = ({organization, index, refetch, hasEditPermissions}) => {
  const emailSystemNotificationsToggle = useToggle({name: 'Email System Notifications Toggles'});
  const organizationNotificationEmailsForm = OrganizationNotificationEmailsForm.edit(organization);
  const {form, handleSubmit, submitting} = useUpdateOrganizationNotificationEmailsMutation({
    organizationNotificationEmailsForm,
    onSuccess: () => {
      refetch();
      emailSystemNotificationsToggle.handleToggleOff();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <EditPanel
      index={index}
      BodyComponent={EmailSystemNotificationsPanelBody}
      bodyComponentProps={{organization}}
      EditBodyComponent={EmailSystemNotificationsPanelEditBody}
      editBodyComponentProps={{form, field: 'organizationNotificationEmailsForm'}}
      HeaderComponent={Header}
      headerComponentProps={{
        isEditing: emailSystemNotificationsToggle.isOn,
        handleEdit: emailSystemNotificationsToggle.handleToggleOn,
        hasEditPermissions,
      }}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={emailSystemNotificationsToggle.isOn}
      handleEdit={emailSystemNotificationsToggle.handleToggleOn}
      handleClose={emailSystemNotificationsToggle.handleToggleOff}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailSystemNotificationsPanel.fragment = gql`
  fragment EmailSystemNotificationsPanel on Organization {
    id
    settings {
      id
      notificationEmails
    }
  }
`;

export default EmailSystemNotificationsPanel;
