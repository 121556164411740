// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

// Components
import UpdateReportMoveMoveUserItem from './UpdateReportMoveMoveUserItem';

const Container = Styled.View`
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 10px;
`;

const Right = Styled.View`
  flex-direction: row;
`;

const Vertical = Styled.View`
  align-items: flex-end;
`;

const ButtonPlaceholder = Styled.View`
  width: 60px;
  margin-left: 20px;
`;

const Text = Styled.H6`
  ${fontWeight(500)}
`;

const Adjustment = Styled.H7`
  color: ${colors.gray.secondary};
`;

const Cell = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const UpdateReportMoveMoveUsersList = ({form, timePayable}) => (
  <Container>
    <Header>
      <Text>Crew Name</Text>
      <Right>
        <Vertical>
          <Text>Hours Worked</Text>
          <Adjustment>(Adjustment)</Adjustment>
        </Vertical>
        <ButtonPlaceholder />
      </Right>
    </Header>
    {form.values.moveUserInputs.map((moveUserInput, index) => (
      <Cell key={index} index={index}>
        <UpdateReportMoveMoveUserItem
          isFirst={index === 0}
          form={form}
          index={index}
          timePayable={timePayable}
        />
      </Cell>
    ))}
  </Container>
);

export default UpdateReportMoveMoveUsersList;
