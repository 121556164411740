// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, DragAndDropList} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDragAndDrop, useModal, useResponsive} from '@supermove/hooks';
import {DocumentItemType, OrganizationModel, DocumentItemColumnType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import {DocumentTemplateCategoryType} from '@shared/modules/Document/enums/DocumentTemplateCategory';
import DocumentContentJson from '@shared/modules/Document/helpers/DocumentContentJson';
import {
  SelectedDocumentItemType,
  SetSelectedDocumentItemType,
  DocumentContentJsonType,
  SetDocumentContentJsonType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import AddDocumentItemButton from 'modules/Organization/Settings/Document/components/AddDocumentItemButton';
import DocumentItemCard from 'modules/Organization/Settings/Document/components/DocumentItemCard';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ColumnDocumentItemsDividerContainer = Styled.View`
  flex: 1;
`;

const DividerLineContainer = Styled.View`
  margin-horizontal: -24px;
`;

const ColumnLabel = Styled.Text`
  ${Typography.Responsive.Subheading}
  color: ${colors.gray.secondary};
`;

const PlaceholderText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
`;

const getIsColumn = (documentItem?: DocumentItemType): documentItem is DocumentItemColumnType => {
  return documentItem?.itemKind === DocumentItemKindV2.COLUMN;
};

const handleRemoveItem = ({
  documentItemsField,
  index,
  draftDocumentContentJson,
  setDraftDocumentContentJson,
}: {
  documentItemsField: string;
  index: number;
  draftDocumentContentJson: DocumentContentJsonType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
}) => {
  const removedItem = _.get(draftDocumentContentJson, documentItemsField)[index];
  setDraftDocumentContentJson((prevState: DocumentContentJsonType) => {
    const documentItems = _.get(prevState, documentItemsField);
    _.set(prevState, documentItemsField, List.remove(documentItems, index));
    return {...prevState};
  });
  return removedItem;
};

const handleAddItem = ({
  documentItem,
  documentItemsField,
  index,
  setDraftDocumentContentJson,
}: {
  documentItem: DocumentItemType;
  documentItemsField: string;
  index: number;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
}) => {
  setDraftDocumentContentJson((prevState: DocumentContentJsonType) => {
    const documentItems = _.get(prevState, documentItemsField);
    _.set(prevState, documentItemsField, List.insert(documentItems, documentItem, index));
    return {...prevState};
  });
};

const handleMoveWithColumns = ({
  documentItems,
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  selectedDocumentItem,
  setSelectedDocumentItem,
  fromIndex,
  toIndex,
  documentItemsField,
}: {
  documentItems: DocumentItemColumnType[];
  draftDocumentContentJson: DocumentContentJsonType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  selectedDocumentItem: SelectedDocumentItemType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  fromIndex: number;
  toIndex: number;
  documentItemsField: string;
}) => {
  // TODO(dan): Handle keeping the selected item selected
  setSelectedDocumentItem({...selectedDocumentItem, index: undefined, documentItem: undefined});

  const columnDividerIndex = documentItems[0].documentItems.length;
  const isMovingFromLeftToRight = fromIndex < columnDividerIndex && toIndex >= columnDividerIndex;
  const isMovingFromRightToLeft = fromIndex > columnDividerIndex && toIndex <= columnDividerIndex;
  const leftDocumentItemsField = `${documentItemsField}.0.documentItems`;
  const rightDocumentItemsField = `${documentItemsField}.1.documentItems`;
  const rightColumnOffset = columnDividerIndex + 1;

  if (isMovingFromLeftToRight || isMovingFromRightToLeft) {
    const removedItem = handleRemoveItem({
      documentItemsField: isMovingFromLeftToRight
        ? leftDocumentItemsField
        : rightDocumentItemsField,
      index: isMovingFromLeftToRight ? fromIndex : fromIndex - rightColumnOffset,
      draftDocumentContentJson,
      setDraftDocumentContentJson,
    });
    handleAddItem({
      documentItem: removedItem,
      documentItemsField: isMovingFromLeftToRight
        ? rightDocumentItemsField
        : leftDocumentItemsField,
      index: isMovingFromLeftToRight ? toIndex - columnDividerIndex : toIndex,
      setDraftDocumentContentJson,
    });
  } else {
    const columnIndex = fromIndex < columnDividerIndex ? 0 : 1;
    const columnDocumentItems = documentItems[columnIndex].documentItems;
    const isMovingLeftColumn = columnIndex === 0;
    handleMoveItem({
      documentItems: columnDocumentItems,
      draftDocumentContentJson,
      setDraftDocumentContentJson,
      selectedDocumentItem,
      setSelectedDocumentItem,
      fromIndex: isMovingLeftColumn ? fromIndex : fromIndex - rightColumnOffset,
      toIndex: isMovingLeftColumn ? toIndex : toIndex - rightColumnOffset,
      documentItemsField: `${documentItemsField}.${columnIndex}.documentItems`,
    });
  }
};

const handleMoveItem = ({
  documentItems,
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  selectedDocumentItem,
  setSelectedDocumentItem,
  fromIndex,
  toIndex,
  documentItemsField,
}: {
  documentItems: DocumentItemType[];
  draftDocumentContentJson: DocumentContentJsonType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  selectedDocumentItem: SelectedDocumentItemType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  fromIndex: number;
  toIndex: number;
  documentItemsField: string;
}) => {
  const isColumns = getIsColumn(documentItems[0]);
  if (isColumns) {
    return handleMoveWithColumns({
      documentItems: documentItems as DocumentItemColumnType[],
      draftDocumentContentJson,
      setDraftDocumentContentJson,
      selectedDocumentItem,
      setSelectedDocumentItem,
      fromIndex,
      toIndex,
      documentItemsField,
    });
  }

  const reorderedDocumentItems = List.move({
    list: documentItems,
    fromIndex,
    toIndex,
  });

  // Handle adjusting the position of the selected document item
  const selectedIndex = selectedDocumentItem.index;
  if (!_.isNil(selectedIndex)) {
    const isMovingSelectedItem = fromIndex === selectedIndex;
    if (isMovingSelectedItem) {
      setSelectedDocumentItem({...selectedDocumentItem, index: toIndex});
    }
    const isSelectedItemMovedUp = fromIndex < selectedIndex && toIndex >= selectedIndex;
    if (isSelectedItemMovedUp) {
      setSelectedDocumentItem({...selectedDocumentItem, index: selectedIndex - 1});
    }
    const isSelectedItemMovedDown = fromIndex > selectedIndex && toIndex <= selectedIndex;
    if (isSelectedItemMovedDown) {
      setSelectedDocumentItem({...selectedDocumentItem, index: selectedIndex + 1});
    }
  }

  setDraftDocumentContentJson((prevState: DocumentContentJsonType) => {
    _.set(prevState, documentItemsField, reorderedDocumentItems);
    return {...prevState};
  });
};

const handleDeleteRightColumn = ({
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  selectedDocumentItem,
}: {
  draftDocumentContentJson: DocumentContentJsonType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  selectedDocumentItem: SelectedDocumentItemType;
}) => {
  const documentItemsField = DocumentContentJson.getDocumentItemsField({
    sectionIndex: selectedDocumentItem.sectionIndex,
  });
  const sectionDocumentItems = _.get(
    draftDocumentContentJson,
    documentItemsField,
  ) as DocumentItemColumnType[];
  const leftColumnItems = sectionDocumentItems[0].documentItems;
  setDraftDocumentContentJson((prevState: DocumentContentJsonType) => {
    _.set(prevState, documentItemsField, leftColumnItems);
    return {...prevState};
  });
};

const ColumnDocumentItemsDivider = ({
  organization,
  setDraftDocumentContentJson,
  draftDocumentContentJson,
  setSelectedDocumentItem,
  selectedDocumentItem,
  documentTemplateCategory,
}: {
  organization: OrganizationModel;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  draftDocumentContentJson: DocumentContentJsonType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  selectedDocumentItem: SelectedDocumentItemType;
  documentTemplateCategory: DocumentTemplateCategoryType;
}) => {
  const removeColumnModal = useModal({name: 'Remove Column Modal', enableTracking: true});
  const responsive = useResponsive();
  const rightColumnDocumentItems = DocumentContentJson.getDocumentItems(draftDocumentContentJson, {
    sectionIndex: selectedDocumentItem.sectionIndex,
    columnIndex: 1,
  });
  const hasDocumentItems = rightColumnDocumentItems.length > 0;

  return (
    <ColumnDocumentItemsDividerContainer>
      <AddDocumentItemButton
        organization={organization}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
        draftDocumentContentJson={draftDocumentContentJson}
        setSelectedDocumentItem={setSelectedDocumentItem}
        selectedDocumentItem={selectedDocumentItem}
        documentTemplateCategory={documentTemplateCategory}
        columnIndex={0}
      />
      <Space height={8} />
      <DividerLineContainer>
        <Line />
      </DividerLineContainer>
      <Space height={16} />
      <Row>
        <ColumnLabel responsive={responsive}>Right Column</ColumnLabel>
        <Space flex={1} />
        <IconButton
          source={Icon.Trash}
          isDestructive
          onPress={() =>
            hasDocumentItems
              ? removeColumnModal.handleOpen()
              : handleDeleteRightColumn({
                  draftDocumentContentJson,
                  setDraftDocumentContentJson,
                  selectedDocumentItem,
                })
          }
        />
      </Row>
      {!hasDocumentItems ? (
        <React.Fragment>
          <Space height={16} />
          <PlaceholderText>This column doesn't have any items yet.</PlaceholderText>
          <Space height={4} />
        </React.Fragment>
      ) : (
        <Space height={8} />
      )}
      <DeleteModal
        isOpen={removeColumnModal.isOpen}
        handleClose={removeColumnModal.handleClose}
        title={'Remove column?'}
        subtitle={'Document items in this column will also be removed.'}
        handleDelete={() => {
          handleDeleteRightColumn({
            draftDocumentContentJson,
            setDraftDocumentContentJson,
            selectedDocumentItem,
          });
        }}
        deleteButtonText={'Remove'}
      />
    </ColumnDocumentItemsDividerContainer>
  );
};

const DocumentItemsReorderableList = ({
  organization,
  setDraftDocumentContentJson,
  draftDocumentContentJson,
  setSelectedDocumentItem,
  selectedDocumentItem,
  documentTemplateCategory,
  hoveredSectionIndex,
  setHoveredSectionIndex,
}: {
  organization: OrganizationModel;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  draftDocumentContentJson: DocumentContentJsonType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  selectedDocumentItem: SelectedDocumentItemType;
  documentTemplateCategory: DocumentTemplateCategoryType;
  hoveredSectionIndex?: number;
  setHoveredSectionIndex?: (index?: number) => void;
}) => {
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();

  const documentItemsField = DocumentContentJson.getDocumentItemsField({
    sectionIndex: selectedDocumentItem.sectionIndex,
  });
  const documentItems: DocumentItemType[] = _.get(draftDocumentContentJson, documentItemsField, []);

  const defaultDocumentItemProps = {
    draftDocumentContentJson,
    setDraftDocumentContentJson,
    setSelectedDocumentItem,
    selectedDocumentItem,
    isFullyEnabledTimesheetsV2: organization.isFullyEnabledTimesheetsV2,
  };

  return (
    <DragAndDropList
      isReordering={isReordering}
      spaceBetweenItems={8}
      disabledIndexes={getIsColumn(documentItems[0]) ? [documentItems[0].documentItems.length] : []}
      onReorder={({fromIndex, toIndex}) => {
        handleReorderStart();
        handleMoveItem({
          documentItems,
          draftDocumentContentJson,
          setDraftDocumentContentJson,
          selectedDocumentItem,
          setSelectedDocumentItem,
          fromIndex,
          toIndex,
          documentItemsField,
        });
        handleReorderEnd();
      }}
      isDisabled={documentItems && documentItems.length < 2}
      itemContainerStyle={{paddingLeft: 24, paddingRight: 24}}
    >
      {documentItems.map((documentItem, index) => {
        if (getIsColumn(documentItem)) {
          const isLeftColumn = index === 0;
          const rightColumnIndexOffset = getIsColumn(documentItems[0])
            ? documentItems[0].documentItems.length + 1
            : 0;

          // The null in the left column list serves as the placeholder for
          // the divider between the left and right column document items.
          const listItems = isLeftColumn
            ? [...documentItem.documentItems, null]
            : documentItem.documentItems;
          return listItems.map((columnDocumentItem, columnDocumentItemIndex) => {
            const listItemIndex = isLeftColumn
              ? columnDocumentItemIndex
              : columnDocumentItemIndex + rightColumnIndexOffset;
            if (!columnDocumentItem) {
              return (
                <ColumnDocumentItemsDivider
                  key={listItemIndex}
                  organization={organization}
                  setDraftDocumentContentJson={setDraftDocumentContentJson}
                  draftDocumentContentJson={draftDocumentContentJson}
                  setSelectedDocumentItem={setSelectedDocumentItem}
                  selectedDocumentItem={selectedDocumentItem}
                  documentTemplateCategory={documentTemplateCategory}
                />
              );
            }
            return (
              <DocumentItemCard
                key={listItemIndex}
                index={columnDocumentItemIndex}
                documentItem={columnDocumentItem}
                columnIndex={isLeftColumn ? 0 : 1}
                {...defaultDocumentItemProps}
              />
            );
          });
        }
        return (
          <DocumentItemCard
            key={index}
            index={index}
            documentItem={documentItem}
            hoveredSectionIndex={hoveredSectionIndex}
            setHoveredSectionIndex={setHoveredSectionIndex}
            {...defaultDocumentItemProps}
          />
        );
      })}
    </DragAndDropList>
  );
};

DocumentItemsReorderableList.fragment = gql`
  ${AddDocumentItemButton.fragment}

  fragment DocumentItemsReorderableList on Organization {
    id
    isFullyEnabledTimesheetsV2
    ...AddDocumentItemButton
  }
`;

export default DocumentItemsReorderableList;
