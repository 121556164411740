// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';

const Text = Styled.Text`
  ${Typography.Body2}
`;

const DeleteDefaultVariableSectionWarningModal = ({isOpen, handleClose}) => {
  return (
    <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
      <SmallModal.HeaderText>{'Default Section'}</SmallModal.HeaderText>
      <Space height={16} />
      <Text>
        {
          'This section is currently your default section group. You cannot delete this section until a different section is set as your new default.'
        }
      </Text>
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose} color={colors.blue.interactive}>
          I Understand
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default DeleteDefaultVariableSectionWarningModal;
