// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const UncancelJobMutation = ({job, onSuccess, onError, children}) => (
  <Mutation
    variables={{
      jobId: job.id,
    }}
    mutation={UncancelJobMutation.mutation}
    onCompleted={({response: {job, errors}}) => {
      if (job) {
        onSuccess(job);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UncancelJobMutation.propTypes = {
  job: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

UncancelJobMutation.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UncancelJobMutation.mutation = gql`
  mutation UncancelJobMutation($jobId: Int!) {
    response: uncancelJob(jobId: $jobId) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default UncancelJobMutation;
