// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import FieldValue from '@shared/design/components/Field/FieldValue';
import Line from 'modules/App/components/Line';

const ResponsiveContainer = Styled.View`
  flex-direction: ${({responsive}) => (responsive.desktop ? 'row' : 'column')};
`;

const ResponsiveCell = Styled.View`
  ${({responsive}) => responsive.desktop && 'flex: 1;'}
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ColorDot = Styled.View`
  height: ${({responsive}) => (responsive.desktop ? 8 : 10)}px;
  width: ${({responsive}) => (responsive.desktop ? 8 : 10)}px;
  border-radius: ${({responsive}) => (responsive.desktop ? 4 : 5)}px;
  background-color: ${({color}) => color};
`;

const SubheadingText = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const matchJobTypesToNewProjectType = ({form, field, jobTypes, projectTypeId}) => {
  const projectTypeJobTypes = jobTypes.filter(
    (jobType) => _.toString(jobType.projectTypeId) === _.toString(projectTypeId),
  );
  const updateJobTypeForJobFormsField = `${field}.updateJobTypeForJobForms`;

  // Match job type by name to new project type, otherwise set to null
  const updatedJobTypeForJobForms = _.get(form.values, updateJobTypeForJobFormsField).map(
    (updateJobTypeForJobForm) => {
      const jobTypeName = _.get(updateJobTypeForJobForm, 'jobTypeName');
      const matchedJobType = projectTypeJobTypes.find((jobType) => jobType.name === jobTypeName);
      return {
        ...updateJobTypeForJobForm,
        jobTypeId: matchedJobType ? _.toString(matchedJobType.id) : null,
        isMatched: !!matchedJobType,
      };
    },
  );

  form.setFieldValue(updateJobTypeForJobFormsField, updatedJobTypeForJobForms);
};

const ResetBillingCheckbox = ({form, field, resetField, label, hint}) => {
  return (
    <Checkbox
      isChecked={_.get(form.values, `${field}.${resetField}`)}
      handleToggle={(value) => {
        form.setFieldValue(`${field}.${resetField}`, value);
        // If the project billing is reset, then reset the billing on all jobs
        _.get(form.values, `${field}.updateJobTypeForJobForms`).forEach(
          (updateJobTypeForJobForm, index) => {
            const updateJobTypeForJobFormField = `${field}.updateJobTypeForJobForms.${index}`;
            form.setFieldValue(`${updateJobTypeForJobFormField}.${resetField}`, value);
          },
        );
      }}
      label={label}
      hint={hint}
      childrenRight
      isResponsive
    />
  );
};

const EditProjectTypeContent = ({project, form, field}) => {
  const {projectType, organization} = project;
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <SubheadingText responsive={responsive}>Current Project Type</SubheadingText>
      <Space height={16} />
      <ResponsiveContainer responsive={responsive}>
        <ResponsiveCell responsive={responsive}>
          <FieldValue.LabelText isResponsive responsive={responsive}>
            Project Type
          </FieldValue.LabelText>
          <FieldValue.LabelSpace />
          <Row style={{alignItems: 'center'}}>
            <ColorDot color={projectType.color} responsive={responsive} />
            <Space width={8} />
            <FieldValue.ValueText isResponsive responsive={responsive}>
              {projectType.name}
            </FieldValue.ValueText>
          </Row>
        </ResponsiveCell>
        <Space width={16} height={16} />
        <ResponsiveCell responsive={responsive}>
          <FieldValue.LabelText isResponsive responsive={responsive}>
            Billing Library
          </FieldValue.LabelText>
          <FieldValue.LabelSpace />
          <a
            target={'_blank'}
            href={`/settings/billing/billing-libraries/${projectType.billingLibrary.uuid}/items/fees`}
            style={{alignItems: 'center'}}
          >
            <Icon
              source={Icon.ExternalLinkAlt}
              size={Icon.Sizes.Medium}
              color={colors.blue.interactive}
            />
            <Space width={8} />
            <LinkText responsive={responsive}>{projectType.billingLibrary.name}</LinkText>
          </a>
        </ResponsiveCell>
      </ResponsiveContainer>
      <Space height={24} />
      <Line />
      <Space height={24} />
      <SubheadingText responsive={responsive}>New Project Type</SubheadingText>
      <Space height={16} />
      <ResponsiveContainer responsive={responsive}>
        <ResponsiveCell responsive={responsive}>
          <FieldInput
            {...form}
            name={`${field}.projectTypeId`}
            label={'Project Type'}
            component={DropdownInput}
            isResponsive
            input={{
              options: organization.moveProjectTypes.map((projectType) => ({
                label: projectType.name,
                value: projectType.id,
                billingLibraryId: projectType.billingLibraryId,
              })),
              onChangeValue: (value, {label, billingLibraryId}) => {
                form.setFieldValue(`${field}.billingLibraryId`, _.toString(billingLibraryId));
                form.setFieldValue(`${field}.projectTypeName`, label);
                matchJobTypesToNewProjectType({
                  form,
                  field,
                  jobTypes: organization.jobTypes,
                  projectTypeId: value,
                });
              },
              isPortaled: true,
              placeholder: 'Select a project type',
              setFieldValue: form.setFieldValue,
              style: {
                flex: 1,
                height: responsive.desktop ? 32 : 48,
              },
            }}
            style={{flex: 1}}
          />
        </ResponsiveCell>
        <Space width={16} height={16} />
        <ResponsiveCell responsive={responsive}>
          <FieldInput
            {...form}
            name={`${field}.billingLibraryId`}
            label={'Billing Library'}
            component={DropdownInput}
            isResponsive
            input={{
              options: organization.billingLibraries.map((billingLibrary) => ({
                label: billingLibrary.name,
                value: billingLibrary.id,
              })),
              disabled: true,
              placeholder: 'Select a billing library',
              setFieldValue: form.setFieldValue,
              style: {
                flex: 1,
                height: responsive.desktop ? 32 : 48,
              },
            }}
            style={{flex: 1}}
          />
        </ResponsiveCell>
      </ResponsiveContainer>
      <Space height={16} />
      <ResetBillingCheckbox
        form={form}
        field={field}
        resetField={'isBillingReset'}
        label={'Reset Bills'}
        hint={`When checked, this will replace existing project and job bills with new ones generated with the new billing library.`}
      />
      <Space height={16} />
      <ResetBillingCheckbox
        form={form}
        field={field}
        resetField={'isBillingValuesReset'}
        label={'Reset Billing Values'}
        hint={`When checked, this will replace all current values with new values based on the new project and job types.`}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectTypeContent.fragment = gql`
  fragment EditProjectTypeContent on Project {
    id
    projectType {
      id
      name
      color
      billingLibrary {
        id
        name
        uuid
      }
    }
    organization {
      id
      moveProjectTypes: projectTypesForCategory(category: "MOVE") {
        id
        name
        billingLibraryId
      }
      billingLibraries {
        id
        name
      }
      jobTypes {
        id
        name
        projectTypeId
      }
    }
  }
`;

export default EditProjectTypeContent;
