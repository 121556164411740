// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useInlineFormMutation, useMountEffect, useNavigationDOM, useQuery} from '@supermove/hooks';

// App
import Panel from '@shared/design/components/Panel';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import NylasAccountPanel from 'modules/User/Settings/Communication/Email/components/NylasAccountPanel';
import UserSettingsPageWrapper from 'modules/User/Settings/components/UserSettingsPageWrapper';

const Content = Styled.View`
`;

const CreateAccountGrantForNylas = ({
  nylasCode,
  redirectUri,
  userId,
  organizationId,
}: {
  nylasCode: string;
  redirectUri: string;
  userId: number;
  organizationId: number;
}) => {
  const {submitting, form, handleSubmit} = useInlineFormMutation({
    name: 'accountGrantForm',
    form: {
      kind: 'USER',
      provider: 'NYLAS',
      redirectUri,
      nylasCode,
      userId,
      organizationId,
    },
    mutation: CreateAccountGrantForNylas.mutation,
    onSuccess: () => {
      // After we create an AccountGrant, we need to hard-redirect to the redirectUri which will
      // remove the 'code' from the url params.
      window.location.href = redirectUri;
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  // Submit the form right when this component is mounted.
  useMountEffect(() => {
    handleSubmit();
  });

  return (
    <Panel width={Panel.WIDTH.DEFAULT}>
      <Panel.Header>
        <Panel.HeaderText>Email Account</Panel.HeaderText>
      </Panel.Header>
      <Panel.Body>
        <Space height={16} />
        <PageLoadingIndicator />
        <Space height={16} />
      </Panel.Body>
    </Panel>
  );
};

CreateAccountGrantForNylas.mutation = gql`
  mutation useCreateAccountGrantMutation($accountGrantForm: AccountGrantForm!) {
    response: createAccountGrant(accountGrantForm: $accountGrantForm) {
      ${gql.errors}
      accountGrant {
        id
      }
    }
  }
`;

const EmailContent = ({
  redirectUri,
  viewer,
  refetch,
}: {
  redirectUri: string;
  viewer: any;
  refetch: () => void;
}) => {
  const {params} = useNavigationDOM();

  if (params.code) {
    return (
      <CreateAccountGrantForNylas
        nylasCode={params.code}
        redirectUri={redirectUri}
        userId={viewer.id}
        organizationId={viewer.viewingOrganization.id}
      />
    );
  }

  return (
    <Content>
      <NylasAccountPanel
        redirectUri={redirectUri}
        accountGrant={viewer.activeAccountGrant}
        onDeactivate={() => refetch()}
      />
    </Content>
  );
};

const UserSettingsCommunicationEmailPage = () => {
  const {loading, data, refetch} = useQuery(UserSettingsCommunicationEmailPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {},
  });

  // Create redirect uri specific for this page.
  const redirectPath = '/account/communication/email';
  const redirectUrl = new URL(redirectPath, `${window.location.protocol}//${window.location.host}`);
  const redirectUri = redirectUrl.toString();

  return (
    <UserSettingsPageWrapper selectedLabel={'Communication/Email'} pageTitle={'Email'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => <EmailContent redirectUri={redirectUri} viewer={data.viewer} refetch={refetch} />}
      </Loading>
    </UserSettingsPageWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserSettingsCommunicationEmailPage.query = gql`
  ${NylasAccountPanel.fragment}

  query UserSettingsCommunicationEmailPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
      }
      activeAccountGrant {
        id
        ...NylasAccountPanel
      }
    }
  }
`;

export default UserSettingsCommunicationEmailPage;
