// Libraries

import {gql} from '@supermove/graphql';
import {PhoneNumberModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

const RINGCENTRAL = 'RINGCENTRAL';
const GRASSHOPPER = 'GRASSHOPPER';
const VIRTUAL_PBX = 'VIRTUAL_PBX';
const SPECTRUM = 'SPECTRUM';
const DIALPAD = 'DIALPAD';
const VONAGE = 'VONAGE';
const OPENPHONE = 'OPENPHONE';
const GO_TO_CONNECT = 'GO_TO_CONNECT';
const NEXTIVA = 'NEXTIVA';
const CALLRAIL = 'CALLRAIL';
const GOOGLE_VOICE = 'GOOGLE_VOICE';
const CELL_PHONE = 'CELL_PHONE';
const LANDLINE = 'LANDLINE';
const SUPERMOVE = 'SUPERMOVE';
const TWILIO = 'TWILIO';
const OTHER = 'OTHER';

const RINGCENTRAL_DISPLAY = 'RingCentral';
const GRASSHOPPER_DISPLAY = 'Grasshopper';
const VIRTUAL_PBX_DISPLAY = 'Virtual PBX';
const SPECTRUM_DISPLAY = 'Spectrum';
const DIALPAD_DISPLAY = 'Dialpad';
const VONAGE_DISPLAY = 'Vonage';
const OPENPHONE_DISPLAY = 'OpenPhone';
const GO_TO_CONNECT_DISPLAY = 'Go To Connect';
const NEXTIVA_DISPLAY = 'Nextiva';
const CALLRAIL_DISPLAY = 'CallRail';
const GOOGLE_VOICE_DISPLAY = 'Google Voice';
const CELL_PHONE_DISPLAY = 'Cell Phone';
const LANDLINE_DISPLAY = 'Landline';
const SUPERMOVE_DISPLAY = 'Supermove';
const OTHER_DISPLAY = 'Other';

const PROVIDERS = [
  RINGCENTRAL,
  GRASSHOPPER,
  VIRTUAL_PBX,
  SPECTRUM,
  DIALPAD,
  VONAGE,
  OPENPHONE,
  GO_TO_CONNECT,
  NEXTIVA,
  CALLRAIL,
  GOOGLE_VOICE,
  CELL_PHONE,
  LANDLINE,
  SUPERMOVE,
  OTHER,
] as const;

const getDropdownOptions = withFragment(
  // using unknown to prevent typescript error
  ({phoneNumber}: {phoneNumber?: PhoneNumberModel}): unknown[] => {
    const options = [
      {
        label: CALLRAIL_DISPLAY,
        value: CALLRAIL,
      },
      {
        label: CELL_PHONE_DISPLAY,
        value: CELL_PHONE,
      },
      {
        label: DIALPAD_DISPLAY,
        value: DIALPAD,
      },
      {
        label: GO_TO_CONNECT_DISPLAY,
        value: GO_TO_CONNECT,
      },
      {
        label: GOOGLE_VOICE_DISPLAY,
        value: GOOGLE_VOICE,
      },
      {
        label: GRASSHOPPER_DISPLAY,
        value: GRASSHOPPER,
      },
      {
        label: LANDLINE_DISPLAY,
        value: LANDLINE,
      },
      {
        label: NEXTIVA_DISPLAY,
        value: NEXTIVA,
      },
      {
        label: OPENPHONE_DISPLAY,
        value: OPENPHONE,
      },
      {
        label: RINGCENTRAL_DISPLAY,
        value: RINGCENTRAL,
      },
      {
        label: SPECTRUM_DISPLAY,
        value: SPECTRUM,
      },
      {
        label: VIRTUAL_PBX_DISPLAY,
        value: VIRTUAL_PBX,
      },
      {
        label: VONAGE_DISPLAY,
        value: VONAGE,
      },
      {
        label: OTHER_DISPLAY,
        value: OTHER,
      },
    ];
    if (phoneNumber?.provider === SUPERMOVE) {
      options.push({
        label: SUPERMOVE_DISPLAY,
        value: SUPERMOVE,
      });
    }
    return options;
  },
  gql`
    fragment PhoneNumberProvider_getDropdownOptions on PhoneNumber {
      id
      provider
    }
  `,
);

const convertProviderToDisplay = (provider: string): string => {
  switch (provider) {
    case RINGCENTRAL:
      return RINGCENTRAL_DISPLAY;
    case GRASSHOPPER:
      return GRASSHOPPER_DISPLAY;
    case VIRTUAL_PBX:
      return VIRTUAL_PBX_DISPLAY;
    case SPECTRUM:
      return SPECTRUM_DISPLAY;
    case DIALPAD:
      return DIALPAD_DISPLAY;
    case VONAGE:
      return VONAGE_DISPLAY;
    case OPENPHONE:
      return OPENPHONE_DISPLAY;
    case GO_TO_CONNECT:
      return GO_TO_CONNECT_DISPLAY;
    case NEXTIVA:
      return NEXTIVA_DISPLAY;
    case CALLRAIL:
      return CALLRAIL_DISPLAY;
    case GOOGLE_VOICE:
      return GOOGLE_VOICE_DISPLAY;
    case CELL_PHONE:
      return CELL_PHONE_DISPLAY;
    case LANDLINE:
      return LANDLINE_DISPLAY;
    case SUPERMOVE:
      return SUPERMOVE_DISPLAY;
    case OTHER:
      return OTHER_DISPLAY;
    default:
      return '';
  }
};

const PhoneNumberProvider = {
  RINGCENTRAL,
  GRASSHOPPER,
  VIRTUAL_PBX,
  SPECTRUM,
  DIALPAD,
  VONAGE,
  OPENPHONE,
  GO_TO_CONNECT,
  NEXTIVA,
  CALLRAIL,
  GOOGLE_VOICE,
  CELL_PHONE,
  LANDLINE,
  SUPERMOVE,
  TWILIO,
  OTHER,
  RINGCENTRAL_DISPLAY,
  GRASSHOPPER_DISPLAY,
  VIRTUAL_PBX_DISPLAY,
  SPECTRUM_DISPLAY,
  DIALPAD_DISPLAY,
  VONAGE_DISPLAY,
  OPENPHONE_DISPLAY,
  GO_TO_CONNECT_DISPLAY,
  NEXTIVA_DISPLAY,
  CALLRAIL_DISPLAY,
  GOOGLE_VOICE_DISPLAY,
  CELL_PHONE_DISPLAY,
  LANDLINE_DISPLAY,
  SUPERMOVE_DISPLAY,
  OTHER_DISPLAY,

  getDropdownOptions,
  convertProviderToDisplay,
} as const;

export default PhoneNumberProvider;
