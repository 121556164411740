// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    officePaymentMethods: (organization as any).settings.officePaymentMethods,
  }),
  gql`
    fragment OrganizationOfficePaymentMethodsForm_edit on Organization {
      id
      settings {
        id
        officePaymentMethods
      }
    }
  `,
);

const toForm = ({organizationId, officePaymentMethods}: any) => ({
  organizationId,
  officePaymentMethods: JSON.parse(officePaymentMethods),
});

const toMutation = ({organizationId, officePaymentMethods}: any) => ({
  organizationId,
  officePaymentMethods,
});

const OrganizationOfficePaymentMethodsForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationOfficePaymentMethodsForm;
