// Supermove
import {gql} from '@supermove/graphql';
import {Duration, Float, withFragment} from '@supermove/utils';

const PayableItemForm = {
  SOURCE: {
    MANUAL_MOVE_LABOR: 'MANUAL_MOVE_LABOR',
  },
  UNIT: {
    MINUTE: 'MINUTE',
  },
  toForm: ({source, name, description, unit, rate, amount, userId, jobId, reportMoveId}: any) => ({
    source,
    name,
    description,
    unit,
    rate,
    amount: Float.toForm(Duration.toHours(amount)),
    userId,
    jobId,
    reportMoveId,
  }),
  toMutation: ({
    source,
    name,
    description,
    unit,
    rate,
    amount,
    userId,
    jobId,
    reportMoveId,
  }: any) => ({
    source,
    name,
    description,
    unit,
    rate,
    // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    amount: Float.toMutation(Duration.toMinutes(amount)),
    userId,
    jobId,
    reportMoveId,
  }),
  edit: withFragment(
    (payableItem) => ({
      source: (payableItem as any).source,
      name: (payableItem as any).name,
      description: (payableItem as any).description,
      unit: (payableItem as any).unit,
      rate: (payableItem as any).rate,
      amount: (payableItem as any).amount,
      userId: (payableItem as any).userId,
      jobId: (payableItem as any).jobId,
      reportMoveId: (payableItem as any).reportMoveId,
    }),
    gql`
      fragment PayableItemForm_edit on PayableItem {
        source
        name
        description
        unit
        rate
        amount
        userId
        jobId
        reportMoveId
      }
    `,
  ),
  new: ({source, unit, rate, userId}: any) => ({
    source,
    name: '',
    description: '',
    unit,
    rate,
    amount: 0,
    userId,
    jobId: null,
    reportMoveId: null,
  }),
};

export default PayableItemForm;
