// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

const MUTATION = gql`
  mutation ExportReportMovesReportMutation(
    $organizationId: Int!,
    $startDate: String!,
    $endDate: String!,
    $fileFormat: String!,
  ) {
    response: exportReportMovesReport(
      organizationId: $organizationId,
      startDate: $startDate,
      endDate: $endDate,
      fileFormat: $fileFormat,
    ) {
      ${gql.errors}
      url
      file {
        id
      }
    }
  }
`;

const useExportReportMovesReportForm = ({
  organizationId,
  startDate,
  endDate,
  fileFormat,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      organizationId,
      startDate,
      endDate,
      fileFormat,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: MUTATION,
    variables: {
      organizationId: form.values.organizationId,
      // Convert Moment date objects to string dates.
      startDate: Datetime.toMutationDate(form.values.startDate),
      endDate: Datetime.toMutationDate(form.values.endDate),
      fileFormat: form.values.fileFormat,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

export default useExportReportMovesReportForm;
