// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import CustomerDocumentV2 from 'modules/Document/DocumentV2/CustomerDocumentV2';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const CustomerDocumentV2Show = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(CustomerDocumentV2Show.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <Loading loading={loading}>
      {() => (
        <Container data-print-background>
          <ScrollView horizontal contentContainerStyle={{flex: 1, justifyContent: 'center'}}>
            <CustomerDocumentV2 document={data.document} isPrintable />
          </ScrollView>
        </Container>
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerDocumentV2Show.query = gql`
  ${CustomerDocumentV2.fragment}

  query CustomerDocumentV2Show($uuid: String!) {
    ${gql.query}
    document(uuid: $uuid) {
      id
      ...CustomerDocumentV2
    }
  }
`;

export default CustomerDocumentV2Show;
