import {ExecutionResult, MutationResult, MutationFunctionOptions} from 'react-apollo';

// Supermove
import {gql} from '@supermove/graphql';
import {useMutation} from '@supermove/hooks';
import {MutationResponse} from '@supermove/hooks/src/forms/types';

import BeginPaymentV3Form, {
  New as NewBeginPaymentV3Form,
  GraphQLForm as BeginPaymentV3GraphQLForm,
} from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import PayengineCreditCardForm, {
  PayengineCreditCardFormInterface,
} from '@shared/modules/Payment/forms/PayengineCreditCardForm';
import {CreateCardResponse} from '@shared/modules/Payment/types';

export type ChargePayEngineCreditCardForm = {
  form: {
    beginPaymentV3Form?: BeginPaymentV3GraphQLForm;
    jobId?: number;
    createCardResponse?: CreateCardResponse;
    saveToClientId?: number;
    payengineCreditCardForm?: PayengineCreditCardFormInterface;
  };
};

export type ChargePayEngineCreditCardResponse = {
  payment: {
    id: number;
  };
  savedCardId?: number;
};
export type GraphQLResponse = MutationResponse<ChargePayEngineCreditCardResponse>;

type Args = {
  beginPaymentV3Form: NewBeginPaymentV3Form;
  jobId?: number;
  createCardResponse?: CreateCardResponse;
  saveToClientId?: number;
  saveAsDefault?: boolean;
};

type Result = {
  handleSubmit: (
    options?: MutationFunctionOptions<GraphQLResponse, ChargePayEngineCreditCardForm>,
  ) => Promise<ExecutionResult<GraphQLResponse>>;
  mutationResult: MutationResult<GraphQLResponse>;
};

/**
 * Hook for the ChargePayEngineCreditCardMutation mutation used internally only by
 * useChargePayEngineCreditCard. This avoids using a Formik form, because that complicates state
 * management.
 */
const useChargePayEngineCreditCardMutation = ({
  beginPaymentV3Form,
  jobId,
  createCardResponse,
  saveToClientId,
  saveAsDefault,
}: Args): Result => {
  const [handleSubmit, mutationResult] = useMutation(
    useChargePayEngineCreditCardMutation.mutation,
    {
      variables: {
        form: {
          beginPaymentV3Form: BeginPaymentV3Form.toMutation(
            BeginPaymentV3Form.toForm(beginPaymentV3Form),
          ),
          jobId,
          createCardResponse,
          saveToClientId,
          payengineCreditCardForm: PayengineCreditCardForm.toMutation(
            PayengineCreditCardForm.new({isDefault: saveAsDefault}),
          ),
        } as ChargePayEngineCreditCardForm['form'],
      },
    },
  );

  return {
    handleSubmit,
    mutationResult,
  };
};

useChargePayEngineCreditCardMutation.mutation = gql`
  mutation ChargePayEngineCreditCardMutation(
    $form: ChargePayEngineCreditCardForm!
  ) {
    response: chargePayEngineCreditCard(
      form: $form,
    ) {
      ${gql.errors}
      payment {
        id
      }
      savedCardId
    }
  }
`;

export default useChargePayEngineCreditCardMutation;
