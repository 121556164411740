// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';

const SharedDocumentPage = () => {
  const {navigator, params} = useNavigationDOM();
  const {loading, data} = useQuery(SharedDocumentPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      shareUuid: params.shareUuid,
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <Loading loading={loading}>
      {() => {
        const goTo = (extension: 'not-found' | 'pdf' | 'live') =>
          navigator.replace(`${navigator.location.pathname}/${extension}`);

        const document = data.sharedDocument;

        if (!document) {
          // Throw 404 if no document is found. Any document with a shareUuid
          // is fully accessible.
          goTo('not-found');
        } else if (document.job.project.isComplete) {
          goTo('pdf');
        } else {
          goTo('live');
        }
        return null;
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SharedDocumentPage.query = gql`
  query SharedDocumentPage($shareUuid: String!) {
    ${gql.query}
    sharedDocument(shareUuid: $shareUuid) {
      id
      job {
        id
        project {
          id
          isComplete
        }
      }
    }
  }
`;

export default SharedDocumentPage;
