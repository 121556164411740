import {Currency} from '@supermove/utils';

const edit = (supply) => ({
  name: supply.name,
  value: supply.value,
});

const SupplyForm = {
  toForm: ({name, value}) => ({
    name,
    value: Currency.toForm(value),
  }),

  toMutation: ({name, value}) => ({
    name,
    value: Currency.toMutation(value),
  }),

  new: () => ({
    name: '',
    value: '',
  }),

  edit,
};

export default SupplyForm;
