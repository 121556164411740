// Libraries
import React from 'react';

// Components
import NotFoundPage from 'modules/NotFound/components/NotFoundPage';

const ProjectNotFoundPage = () => {
  return (
    <NotFoundPage
      title={'Project Not Found'}
      subtitle={'If you believe this is an error, please contact us.'}
    />
  );
};

export default ProjectNotFoundPage;
