/*
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import CrewCrewSlotsList from 'modules/Dispatch/Crew/components/CrewCrewSlotsList';

const PopoverContainer = Styled.View`
  width: 320px;
  max-height: 320px;
  backgroundColor: ${colors.white};
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
`;

const HeaderSection = Styled.View`
  padding-horizontal: 16px;
  flex-direction: row;
  justify-content: space-between;
`;

const HeaderTitleText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const HeaderButton = Styled.Button`
  height: auto;
  background-color: ${colors.white};
  box-shadow: none;
  height: 18;
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const Header = ({driver, handleClose}) => {
  return (
    <HeaderSection>
      <HeaderTitleText>{`Select the truck that ${driver.fullName} is driving`}</HeaderTitleText>
      <Space width={16} />
      <HeaderButton onPress={handleClose}>
        <Icon source={Icon.Times} size={16} color={colors.gray.primary} />
      </HeaderButton>
      <Space width={2} />
    </HeaderSection>
  );
};

const CrewSlotDriverPopoverContent = ({driver, crew, form, handleClose}) => {
  return (
    <PopoverContainer>
      <Space height={16} />
      <Header driver={driver} handleClose={handleClose} />
      <Space height={12} />
      <Line />
      <Space height={12} />
      <CrewCrewSlotsList driver={driver} crew={crew} form={form} handleClose={handleClose} />
      <Space height={12} />
    </PopoverContainer>
  );
};

const CrewSlotDriverPopover = ({
  isOpen,
  handleOpen,
  handleClose,
  popoverRef,
  driver,
  crew,
  form,
  onComponentDidHide,
}) => {
  return (
    <ResponsivePopover
      placement={Popover.Positions.BottomEnd}
      isOpen={isOpen}
      handleOpen={handleOpen}
      handleClose={handleClose}
      reference={popoverRef}
      offset={[40, 4]}
      isClosableOutside
      onComponentDidHide={onComponentDidHide}
    >
      <CrewSlotDriverPopoverContent
        driver={driver}
        crew={crew}
        form={form}
        handleClose={handleClose}
      />
    </ResponsivePopover>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewSlotDriverPopover.fragment = gql`
  ${CrewCrewSlotsList.fragment}

  fragment CrewSlotDriverPopover on Crew {
    id
    ...CrewCrewSlotsList
  }
`;

export default CrewSlotDriverPopover;
