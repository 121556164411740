// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';

// App
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import FieldInput from '@shared/design/components/Field/FieldInput';
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';

const getEventTypeContextDefinitions = ({form, field, job}: any) => {
  const kindField = `${field}.kind`;
  const currentKind = _.get(form.values, kindField);
  const timesheetBlockKinds = Organization.getTimesheetBlockKinds(job.organization);
  return timesheetBlockKinds.map((kind) => ({
    label: TimesheetBlockKind.getDisplay(kind),
    isSelected: kind === currentKind,
    onPress: () => form.setFieldValue(kindField, kind),
  }));
};

const TimesheetBlockKindSelector = ({form, field, job}: any) => {
  return (
    <React.Fragment>
      <FieldInput.LabelText isResponsive>Event Type</FieldInput.LabelText>
      <Space height={4} />
      <ContextSwitcher
        isFullWidth
        contextDefinitions={getEventTypeContextDefinitions({form, field, job})}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TimesheetBlockKindSelector.fragment = gql`
  ${Organization.getTimesheetBlockKinds.fragment}

  fragment TimesheetBlockKindSelector on Job {
    id
    organization {
      id
      ...Organization_getTimesheetBlockKinds
    }
  }
`;

export default TimesheetBlockKindSelector;
