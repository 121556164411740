/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

import CollectionForm from '@shared/modules/Inventory/forms/CollectionForm';

// App

const Container = Styled.View`
  flex-direction: row;
`;

const Section = Styled.View`
  width: 50px;
  align-items: flex-end;
`;

const Title = Styled.H8`
  color: ${colors.gray.tertiary};
  text-transform: uppercase;
`;

const Count = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const CollectionFormHeader = ({collectionForm}) => {
  return (
    <Container>
      <Section>
        <Title>Take</Title>
        <Count>{CollectionForm.getTakeCount(collectionForm)}</Count>
      </Section>
      <Section>
        <Title>Leave</Title>
        <Count>{CollectionForm.getLeaveCount(collectionForm)}</Count>
      </Section>
      <Section>
        <Title>CU FT</Title>
        <Count>{CollectionForm.getTotalVolume(collectionForm)}</Count>
      </Section>
      <Section>
        <Title>LB</Title>
        <Count>{CollectionForm.getTotalWeight(collectionForm)}</Count>
      </Section>
    </Container>
  );
};

export default CollectionFormHeader;
