// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Modal, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useState} from '@supermove/hooks';
import {Formula} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import {EvaluateFormulaStringInput} from '@shared/formulas/src/library/EvaluateFormulaStringInput';
import {fixedArityOne} from '@shared/formulas/src/library/arity';
import {ensureString} from '@shared/formulas/src/library/types';
import evaluateFormulaString, {getVariable} from '@shared/formulas/src/logic/evaluateFormulaString';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import Line from 'modules/App/components/Line';
import FormulaEditor from 'modules/Organization/Settings/Billing/Formulas/components/FormulaEditor';

const Row = Styled.View`
  flex-direction: row;
`;

const Button = Styled.ButtonV2`
`;

const VerticalLine = Styled.View`
  height: 100%,
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: 960px;
  border-radius: 16px;
  background-color: ${colors.white};
`;

const HeaderContainer = Styled.View`
  height: 44px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-horizontal: 24px;
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
`;

const BodyContainer = Styled.View`
  flex: 1;
`;

const FooterContainer = Styled.View`
  height: 72px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-horizontal: 30px;
`;

const FooterButton = Styled.ButtonV2`
  height: 40px;
  width: 84px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({color}) => color};
`;

const FooterButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${({color}) => color};
`;

const FormulaEditorModalHeader = ({form, field, title, handleClose}) => {
  const identifier = _.get(form.values, `${field}.identifier`);
  return (
    <HeaderContainer>
      <HeaderText>{`${title}${identifier ? ` - ${identifier}` : ''}`}</HeaderText>
      <Button onPress={handleClose}>
        <Icon source={Icon.Times} color={colors.gray.secondary} size={20} />
      </Button>
    </HeaderContainer>
  );
};

const FormulaEditorModalBody = ({form, field, variables, isDebugMode, organization}) => {
  const isMissingName = !_.get(form.values, `${field}.name`);
  return (
    <BodyContainer>
      <Row>
        <FieldInput
          {...form}
          name={`${field}.name`}
          input={{
            placeholder: 'Name',
            style: {
              paddingLeft: 0,
              paddingRight: 0,
              borderWidth: 0,
              flex: 1,
              backgroundColor: 'transparent',
            },
          }}
          style={{
            paddingHorizontal: 16,
            width: 260,
            alignSelf: 'flex-start',
            backgroundColor: isMissingName ? colors.alpha(colors.yellow.hover, 0.1) : colors.white,
          }}
        />
        <VerticalLine />
        <FieldInput
          {...form}
          name={`${field}.description`}
          input={{
            placeholder: 'Description',
            style: {paddingLeft: 0, paddingRight: 0, borderWidth: 0, flex: 1},
          }}
          style={{paddingHorizontal: 16, flex: 1, alignSelf: 'flex-start'}}
        />
      </Row>
      <Line />
      <FormulaEditor
        form={form}
        field={field}
        variables={variables}
        isDebugMode={isDebugMode}
        organization={organization}
      />
    </BodyContainer>
  );
};

const getCustomFunctionImplementations = (variables) => {
  const customFunctionImplementations = {};
  if (variables.length > 0) {
    customFunctionImplementations.var = {
      checkArity: fixedArityOne('var'),
      checkTypes: ensureString('var'),
      call: (varName) => {
        const variable = getVariable(varName, variables);
        if (
          variable &&
          [VariableFormat.DROPDOWN_STRING, VariableFormat.STRING].includes(variable.format)
        ) {
          return `{${varName}}`;
        }
        return 0;
      },
    };
    customFunctionImplementations.value = {
      checkArity: fixedArityOne('value'),
      checkTypes: ensureString('value'),
      call: (varName) => {
        const variable = getVariable(varName, variables);
        if (
          variable &&
          [VariableFormat.DROPDOWN_STRING, VariableFormat.STRING].includes(variable.format)
        ) {
          return `{${varName}}`;
        }
        return 0;
      },
    };
    return customFunctionImplementations;
  }
};

const FormulaEditorModalFooter = ({
  form,
  field,
  handleClose,
  handleSubmit,
  submitting,
  isDebugMode,
  setIsDebugMode,
  variables,
  formulas,
}) => {
  const formulaString = _.get(form.values, `${field}.formulaString`);
  const {formulaError} = evaluateFormulaString(
    new EvaluateFormulaStringInput(formulaString, [], [], {
      ...getCustomFunctionImplementations(variables),
      formula: {
        checkArity: fixedArityOne('formula'),
        checkTypes: ensureString('formula'),
        call: (formulaIdentifier) => {
          const formula = formulas.find((formula) => formula.identifier === formulaIdentifier);
          // TODO: recursive formula eval
          if (formula) {
            const {formulaResult} = evaluateFormulaString({
              formula: Formula.getFormulaString(formula),
              variables,
              plugins: [],
              customFunctionImplementations: {
                formula: {
                  checkArity: fixedArityOne('formula'),
                  checkTypes: ensureString('formula'),
                  // we can't really do recursive nested formula references on the FE so we'll return zero one formula down
                  call: (formulaIdentifier) => {
                    return 0;
                  },
                },
              },
            });
            return formulaResult;
          }

          return 0;
        },
      },
    }),
  );
  const isDisabledSubmit = (!!formulaString && !!formulaError) || submitting;
  return (
    <FooterContainer>
      <FooterButton
        onPress={handleClose}
        disabled={submitting}
        onLongPress={() => setIsDebugMode(!isDebugMode)}
      >
        <FooterButtonText color={colors.gray.secondary}>Cancel</FooterButtonText>
      </FooterButton>
      <Space width={32} />
      <FooterButton
        color={isDisabledSubmit ? colors.gray.border : colors.blue.interactive}
        disabled={isDisabledSubmit}
        onPress={handleSubmit}
      >
        <FooterButtonText color={colors.white}>Save</FooterButtonText>
      </FooterButton>
    </FooterContainer>
  );
};

const FormulaEditorModal = ({
  organizationId,
  title,
  form,
  field,
  isOpen,
  handleClose,
  handleSubmit,
}) => {
  const [isDebugMode, setIsDebugMode] = useState(false);
  const {data} = useQuery(FormulaEditorModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {organizationId},
    skip: !isOpen,
  });
  const variables = _.get(data, 'organization.allVariables', []);
  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer>
        <ModalContainer style={{height: isDebugMode ? 860 : 700}}>
          <FormulaEditorModalHeader
            form={form}
            field={field}
            title={title}
            handleClose={handleClose}
          />
          <Line />
          {/* TODO(dathan): This data conditional can be removed once the isEnabledPerProjectTypeAndPositionTimesheetRates is turned on for all */}
          {data && (
            <FormulaEditorModalBody
              form={form}
              field={field}
              variables={variables}
              handleClose={handleClose}
              isDebugMode={isDebugMode}
              organization={data.organization}
            />
          )}
          <Line />
          <FormulaEditorModalFooter
            form={form}
            field={field}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            isDebugMode={isDebugMode}
            setIsDebugMode={setIsDebugMode}
            formulas={data?.organization?.formulas || []}
            variables={variables}
          />
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FormulaEditorModal.query = gql`
  ${FormulaEditor.fragment}
  query FormulaEditorModal($organizationId: Int!) {
    ${gql.query}
    organization(organizationId: $organizationId) {
      id
      ...FormulaEditor_Organization
      allVariables {
        id
        ...FormulaEditor
      }
      formulas {
        id
        name
        identifier
        astJson
      }
    }
  }
`;

export default FormulaEditorModal;
