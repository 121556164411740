/**
 * Component - v2.1.0
 */
// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useToast} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {flatten} from '@supermove/utils';

// App
import Modal from '@shared/design/components/Modal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import FileInputFieldV2 from '@shared/modules/File/components/FileInputFieldV2';
import ImportOrganizationProjectsSmartMovingForm from '@shared/modules/File/forms/ImportOrganizationProjectsSmartMovingForm';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useImportOrganizationProjectsSmartMovingMutation from '@shared/modules/File/hooks/useImportOrganizationProjectsSmartMovingMutation';
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';

const Indicator = Styled.Loading`
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const ImportOrganizationProjectsSmartMovingModalContent = ({
  data,
  handleClose,
  refetch,
  textObject,
}) => {
  // const [errors, setErrors] = useState([]);
  const uploadFileFormMutation = useUploadFileForm({
    uploadFileForm: UploadFileForm.new({
      organizationId: data.viewer.viewingOrganization.id,
      creatorId: data.viewer.id,
    }),
    onSuccess: ({file}) => {
      importOrganizationProjectsSmartMovingMutation.form.setFieldValue(
        'importOrganizationProjectsSmartMovingForm.fileId',
        file.id,
      );
      importOrganizationProjectsSmartMovingMutation.form.setErrors([]);
    },
    onError: (errors) => {
      FileInputFieldV2.resetUploadFileForm({
        name: 'uploadFileForm',
        form: uploadFileFormMutation.form,
      });
    },
  });

  const handleImportSubmissionSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message:
      'Import batch is scheduled for import! Please, allow some time for the import to complete.',
  });

  const importOrganizationProjectsSmartMovingMutation =
    useImportOrganizationProjectsSmartMovingMutation({
      importOrganizationProjectsSmartMovingForm: ImportOrganizationProjectsSmartMovingForm.new({
        organizationId: data.viewer.viewingOrganization.id,
        fileId: null,
      }),
      onSuccess: () => {
        FileInputFieldV2.resetUploadFileForm({
          name: 'uploadFileForm',
          form: uploadFileFormMutation.form,
        });
        importOrganizationProjectsSmartMovingMutation.form.setFieldValue(
          'importOrganizationProjectsSmartMovingMutation.fileId',
          null,
        );
        importOrganizationProjectsSmartMovingMutation.form.setFieldValue(
          'importOrganizationProjectsSmartMovingMutation.errors',
          null,
        );
        importOrganizationProjectsSmartMovingMutation.form.setErrors({});
        refetch && refetch();
        handleClose();

        handleImportSubmissionSuccessToast.handleToast();
      },
      onError: (errors) => {
        console.log({errors});
      },
    });
  // hack(alex): converting errors object to flat array
  const processErrors = () => {
    const errorsFlat = _.reverse(
      _.uniq(
        _.concat(
          _.values(flatten(importOrganizationProjectsSmartMovingMutation.form.errors)),
          _.values(flatten(importOrganizationProjectsSmartMovingMutation.form.errors)),
        ),
      ),
    );
    return errorsFlat;
  };
  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.HeaderText>{textObject.title}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body isScrollable>
        <Modal.Text>{textObject.description}</Modal.Text>
        <Space height={16} />
        <Section index={3}>
          <FileInputFieldV2
            field={'uploadFileForm'}
            form={uploadFileFormMutation.form}
            submitting={uploadFileFormMutation.submitting}
            handleSubmit={uploadFileFormMutation.handleSubmit}
            validationErrors={processErrors()}
            supportedFileTypes={['XSLX']}
          />
        </Section>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button
          onPress={handleClose}
          isDisabled={
            uploadFileFormMutation.submitting ||
            importOrganizationProjectsSmartMovingMutation.submitting
          }
        >
          {'Cancel'}
        </Modal.Button>
        <Space width={8} />
        <Modal.Button
          isDisabled={uploadFileFormMutation.submitting}
          onPress={importOrganizationProjectsSmartMovingMutation.handleSubmit}
          isSubmitting={importOrganizationProjectsSmartMovingMutation.submitting}
          color={colors.blue.interactive}
        >
          {'Send'}
        </Modal.Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

const ImportOrganizationProjectsSmartMovingModal = ({isOpen, handleClose, refetch, textObject}) => {
  const {loading, data} = useQuery(ImportOrganizationProjectsSmartMovingModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
  });
  return (
    <Modal isOpen={isOpen} width={Modal.WIDTH.MEDIUM} style={{maxHeight: '100%'}}>
      {loading ? (
        <Indicator size={'large'} color={colors.gray.secondary} />
      ) : (
        <ImportOrganizationProjectsSmartMovingModalContent
          data={data}
          handleClose={handleClose}
          refetch={refetch}
          textObject={textObject}
        />
      )}
    </Modal>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ImportOrganizationProjectsSmartMovingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  textObject: PropTypes.object.isRequired,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ImportOrganizationProjectsSmartMovingModal.query = gql`
  query ImportOrganizationProjectsModal {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
      }
    }
  }
`;

export default ImportOrganizationProjectsSmartMovingModal;
