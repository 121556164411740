// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import StorageProjectForm from '@shared/modules/Project/Storage/forms/StorageProjectForm';

const useUpdateStorageProjectMutation = ({storageProjectForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      storageProjectForm: StorageProjectForm.toForm({
        ...storageProjectForm,
      }),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateStorageProjectMutation.mutation,
    variables: {
      storageProjectForm: StorageProjectForm.toMutation(form.values.storageProjectForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateStorageProjectMutation.mutation = gql`
  ${StorageProjectForm.edit.fragment}
  mutation useUpdateStorageProjectMutation($storageProjectForm: StorageProjectForm!) {
    response: updateStorageProject(storageProjectForm: $storageProjectForm) {
      ${gql.errors}
      project {
        id
        ...StorageProjectForm_edit
      }
    }
  }
`;

export default useUpdateStorageProjectMutation;
