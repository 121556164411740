// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CommercialEquipmentTypeForm from '@shared/modules/CommercialCatalog/forms/CommercialEquipmentTypeForm';

const useCreateCommercialEquipmentTypeMutation = ({
  commercialEquipmentTypeForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      commercialEquipmentTypeForm: CommercialEquipmentTypeForm.toForm(commercialEquipmentTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateCommercialEquipmentTypeMutation.mutation,
    variables: {
      commercialEquipmentTypeForm: CommercialEquipmentTypeForm.toMutation(
        form.values.commercialEquipmentTypeForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateCommercialEquipmentTypeMutation.mutation = gql`
  mutation useCreateCommercialEquipmentTypeMutation($commercialEquipmentTypeForm: CommercialEquipmentTypeForm!) {
    response: createCommercialEquipmentType(commercialEquipmentTypeForm: $commercialEquipmentTypeForm) {
      ${gql.errors}
      commercialEquipmentType {
        id
      }
    }
  }
`;

export default useCreateCommercialEquipmentTypeMutation;
