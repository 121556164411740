// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import InAppNotificationForm from '@shared/modules/InAppNotification/forms/InAppNotificationForm';

const useMarkSingleReadInAppNotificationMutation = ({
  inAppNotificationForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      inAppNotificationForm: InAppNotificationForm.toForm(inAppNotificationForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useMarkSingleReadInAppNotificationMutation.mutation,
    variables: {
      inAppNotificationForm: InAppNotificationForm.toMutation(inAppNotificationForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useMarkSingleReadInAppNotificationMutation.mutation = gql`
  mutation useMarkSingleReadInAppNotificationMutation($inAppNotificationForm: InAppNotificationForm!){
    response: markSingleReadInAppNotification(inAppNotificationForm: $inAppNotificationForm){
      ${gql.errors}
    }
  }
`;

export default useMarkSingleReadInAppNotificationMutation;
