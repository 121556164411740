// Libararies
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// These are effectively the text color
const BLUE = colors.blue.interactive;
const GREEN = colors.green.status;

// Padding reduced by 1 because of border-width
const Button = Styled.ButtonV2`
  padding-vertical: 3px
  padding-horizontal: 7px;
  border-radius: 4px;
  border-width: 1px;
`;

const Text = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  color: ${({color}) => color};
`;

const getButtonStyle = ({color, isOn}) => {
  if (isOn) {
    return {
      backgroundColor: colors.getBackgroundColor(color),
      borderColor: colors.getBackgroundColor(color),
    };
  }

  return {
    backgroundColor: colors.gray.background,
    borderColor: colors.gray.border,
  };
};

const getTextColor = ({color, isOn}) => {
  if (isOn) {
    return color;
  }

  return colors.gray.secondary;
};

const ToggleBadge = ({label, color, isOn, onPress}) => {
  const responsive = useResponsive();
  return (
    <Button style={getButtonStyle({color, isOn})} onPress={onPress} disabled={!onPress}>
      <Text responsive={responsive} color={getTextColor({color, isOn})}>
        {label}
      </Text>
    </Button>
  );
};

ToggleBadge.COLORS = {
  BLUE,
  GREEN,
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ToggleBadge.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isOn: PropTypes.bool,
  onPress: PropTypes.func,
};

ToggleBadge.defaultProps = {
  isOn: false,
  onPress: null,
};

export default ToggleBadge;
