// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// App
import {Panel, SplitPage} from 'modules/App/components';
import CrewJobUserFooter from 'modules/Employee/Job/Details/components/CrewJobUserFooter';
import EmployeeJobFieldValues from 'modules/Employee/Job/Details/components/EmployeeJobFieldValues';
import {
  CrewJobStatusBanner,
  JobPageWithNavigation,
  JobTabs,
  ViewJobMutation,
} from 'modules/Employee/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-bottom: 30px;
`;

const Header = Styled.View`
  margin-horizontal: 20px;
  margin-top: 20px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H6`
  color: ${colors.gray.primary};
`;

const Line = Styled.View`
  margin-horizontal: 20px;
  margin-vertical: 20px;
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const canViewerSeeJobFooter = ({job, viewer}) => {
  // If the job is complete or cancelled, we don't show the option to change the accept/decline
  // anymore.
  if (job.primaryStatus === 'CANCELLED' || job.primaryStatus === 'COMPLETE') {
    return false;
  }

  // If this feature isn't enabled, don't show anything.
  if (!job.organization.settings.allowMoverAcceptDeclineJob) {
    return false;
  }

  const userIds = job.jobUsers.map((jobUser) => String(jobUser.userId));
  return userIds.includes(String(viewer.id));
};

const DetailsJobPage = ({
  match: {
    params: {uuid},
  },
}) => {
  return (
    <JobPageWithNavigation
      variables={{uuid}}
      query={DetailsJobPage.query}
      title={({loading, data}) => (loading ? 'Loading...' : `${data.job.fullName}`)}
      subtitle={() => 'View details'}
      onClose={({navigator}) => navigator.goBack()}
    >
      {({responsive, data}) => (
        <SplitPage split={<Panel />}>
          <ViewJobMutation jobId={data.job.id} userId={data.viewer.id}>
            <Container>
              <JobTabs selected={'details'} job={data.job} />
              <ScrollView style={{flex: 1}}>
                <CrewJobStatusBanner job={data.job} />
                <Content {...responsive}>
                  <Header>
                    <Title>{`${data.job.fullName}: Details`}</Title>
                    <Subtitle>View information about this job.</Subtitle>
                  </Header>
                  <Line />
                  <EmployeeJobFieldValues
                    canViewCustomerInfo={User.canViewCustomerInfo(data.viewer)}
                    job={data.job}
                  />
                </Content>
              </ScrollView>
              {canViewerSeeJobFooter({job: data.job, viewer: data.viewer}) && (
                <CrewJobUserFooter job={data.job} />
              )}
            </Container>
          </ViewJobMutation>
        </SplitPage>
      )}
    </JobPageWithNavigation>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DetailsJobPage.query = gql`
  ${JobPageWithNavigation.fragment}
  ${JobTabs.fragment}
  ${CrewJobStatusBanner.fragment}
  ${CrewJobUserFooter.fragment}
  ${EmployeeJobFieldValues.fragment}
  ${User.canViewCustomerInfo.fragment}

  query DetailsJobPage($uuid: String!) {
    ${gql.query}
    viewer {
      id
      ...User_canViewCustomerInfo
    }
    job(uuid: $uuid) {
      id
      fullName
      primaryStatus: calendarPrimaryStatus
      organization {
        id
        settings {
          id
          schedulingType
          allowMoverAcceptDeclineJob
        }
      }
      jobUsers: activeJobUsers {
        id
        userId
      }
      ...JobPageWithNavigation
      ...JobTabs
      ...CrewJobStatusBanner
      ...CrewJobUserFooter
      ...EmployeeJobFieldValues
    }
  }
`;

export default DetailsJobPage;
