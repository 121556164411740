// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ShareDocumentForm, {
  ShareDocumentFormType,
} from '@shared/modules/Document/forms/ShareDocumentForm';

const useShareDocumentMutation = ({
  shareDocumentForm,
  onSuccess,
  onError,
}: {
  shareDocumentForm: ShareDocumentFormType;
  onSuccess: () => void;
  onError: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      shareDocumentForm: ShareDocumentForm.toForm(shareDocumentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useShareDocumentMutation.mutation,
    variables: {
      shareDocumentForm: ShareDocumentForm.toMutation(form.values.shareDocumentForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useShareDocumentMutation.mutation = gql`
  mutation useShareDocumentMutation($shareDocumentForm: ShareDocumentForm!) {
    response: shareDocument(shareDocumentForm: $shareDocumentForm) {
      ${gql.errors}
      document {
        id
        shareUuid
        isShared
      }
    }
  }
`;

export default useShareDocumentMutation;
