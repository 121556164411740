/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import ReportMoveMoveUserTipItem from './ReportMoveMoveUserTipItem';

const EmptyMessage = Styled.H6`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const ReportMoveMoveUsersTipList = ({moveUsers}) => (
  <FlatList
    data={moveUsers}
    keyExtractor={(moveUser, index) => String(index)}
    renderItem={({item: moveUser, index}) => (
      <ReportMoveMoveUserTipItem isFirst={index === 0} moveUser={moveUser} />
    )}
    ListEmptyComponent={() => <EmptyMessage>No crew members yet</EmptyMessage>}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMoveMoveUsersTipList.fragment = gql`
  ${ReportMoveMoveUserTipItem.fragment}

  fragment ReportMoveMoveUsersTipList on ReportMoveUser {
    ...ReportMoveMoveUserTipItem
  }
`;

export default ReportMoveMoveUsersTipList;
