// Libraries
import _ from 'lodash';

const getMatches = (string: any, regex: any) => {
  return string.match(regex) || [];
};

const sortTrucks = (trucks: any) => {
  return _.sortBy(trucks, (truck) => {
    const numerics = _.first(getMatches(truck.name, /\d+/g));
    if (numerics && (numerics as any).length > 0) {
      return Number(numerics);
    } else {
      return truck.name;
    }
  });
};

const Truck = {
  getFullName: (truck: any) => {
    if (!truck.size) {
      return truck.name;
    } else {
      return `${truck.name} (${truck.size})`;
    }
  },
  sort: (trucks: any) => sortTrucks(trucks),
};

export default Truck;
