// Libraries
import React from 'react';

// App
import {Emoji, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

// TODO(jholston): when all confirmations are v2, update to new typography
const Label = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Title = Styled.H3`
  ${fontWeight(900)}
`;

const Subtitle = Styled.H6`
  line-height: 24px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const Name = Styled.H6`
  ${fontWeight(700)}
`;

const ConfirmationQuoteStepHeader = ({
  isComplete,
  nextIndex,
  stepCount,
  subtitle,
  title,
  documentDisplayName,
}) => (
  <React.Fragment>
    <Space height={60} />
    <Container>
      {!isComplete && <Label>{`Step ${nextIndex + 1} of ${stepCount}`}</Label>}
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
    <Row>
      <Emoji component={EmojiText} name={'pencil'} />
      <Name>{documentDisplayName}</Name>
    </Row>
    <Space height={10} />
  </React.Fragment>
);

export default ConfirmationQuoteStepHeader;
