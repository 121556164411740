// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import TextTooltip from '@shared/design/components/TextTooltip';
import DateRangeInput from 'modules/Project/V2/components/DateRangeInput';

const EstimatedRowContainer = Styled.View`
  width: 100%;
  flex: 1;
`;

const EstimatedRangeRow = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
`;

const EstimatedRangeToggleButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const EstimatedRangeToggleText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${({color}) => color};
`;

const IconContainer = Styled.View`
  width: 16px;
`;

const handleToggleEstimatedRange = ({form, field, wasEstimatedRange, startDate}) => {
  // When toggling the date, we reset the current values that we're toggling away
  // from, but the date value and the startDate value get passed to each other.
  if (wasEstimatedRange) {
    form.setFieldValue(`${field}.isEstimatedRange`, false);
    form.setFieldValue(`${field}.date`, startDate || _.get(form.values, `${field}.startDate`));
    form.setFieldValue(`${field}.startDate`, '');
    form.setFieldValue(`${field}.endDate`, '');
  } else {
    form.setFieldValue(`${field}.isEstimatedRange`, true);
    form.setFieldValue(`${field}.startDate`, startDate || _.get(form.values, `${field}.date`));
    form.setFieldValue(`${field}.date`, '');
  }
};

const EstimatedRangeToggle = ({form, field, isEstimatedRange, isResponsive}) => {
  const responsive = useResponsive();

  return (
    <EstimatedRangeToggleButton
      onPress={() => handleToggleEstimatedRange({form, field, wasEstimatedRange: isEstimatedRange})}
    >
      <Icon
        source={isEstimatedRange ? Icon.Trash : Icon.Plus}
        size={isResponsive && !responsive.desktop ? 12 : 10}
        color={colors.blue.interactive}
      />
      <Space width={5} />
      <EstimatedRangeToggleText
        color={colors.blue.interactive}
        responsive={isResponsive ? responsive : null}
      >
        End Date
      </EstimatedRangeToggleText>
    </EstimatedRangeToggleButton>
  );
};

const SingleDateInput = ({
  field,
  form,
  label,
  isRequired,
  organization,
  projectTypeId,
  isJobDateRequired,
  isResponsive,
  hasPastDateWarning,
}) => {
  const {isEnabledEstimatedRangeJobs, isEnabledCreateProjectDispatchLocks} = organization.features;
  const dateFieldName = `${field}.date`;
  const date = _.get(form.values, dateFieldName);
  const projectType = organization.moveProjectTypes.find(
    (projectType) => projectType.id === _.toString(projectTypeId),
  );
  const jobTypeId = _.get(form.values, `${field}.jobTypeId`);
  const jobType = organization.jobTypes.find((jobType) => jobType.id === _.toString(jobTypeId));
  const hasDispatchLocks = jobType && !['ESTIMATE', 'REQUEST'].includes(jobType.kind);
  const excludeDates =
    projectType && isEnabledCreateProjectDispatchLocks && hasDispatchLocks
      ? projectType.futureSalesLockedProjectTypeDays.map(
          (projectTypeDay) => projectTypeDay.day.date,
        )
      : [];

  const isDateInPast = Datetime.isPast(date);

  return (
    // FieldInput is not memoized because the DateInput is listening for changes to excludeDates
    // excludeDates is being set when ProjectTypeDropdown is changed
    <FieldInput
      {...form}
      component={DateInput}
      name={dateFieldName}
      isResponsive={isResponsive}
      warningMessage={
        hasPastDateWarning && isDateInPast ? 'Selected date is in the past.' : undefined
      }
      hasWarning={hasPastDateWarning && isDateInPast}
      label={
        <React.Fragment>
          {label}{' '}
          {excludeDates.length !== 0 && (
            <TextTooltip placement={'right'} text={`Disabled dates have been locked by dispatch.`}>
              <IconContainer>
                <Icon
                  color={colors.gray.tertiary}
                  size={Icon.Sizes.Medium}
                  source={Icon.InfoCircle}
                />
              </IconContainer>
            </TextTooltip>
          )}
        </React.Fragment>
      }
      action={
        isEnabledEstimatedRangeJobs ? (
          <EstimatedRangeToggle
            form={form}
            field={field}
            isEstimatedRange={false}
            isResponsive={isResponsive}
          />
        ) : null
      }
      input={{
        disabled: isJobDateRequired
          ? !isJobDateRequired
          : isEnabledCreateProjectDispatchLocks && !projectTypeId,
        required: (isJobDateRequired && !date) || (isRequired && !date),
        onChangeDate: () => {
          form.setTouched({...form.touched, [dateFieldName]: true});
        },
        placeholder: 'Enter date',
        setFieldValue: form.setFieldValue,
        style: {
          width: '100%',
        },
        excludeDates,
        isPortaled: true,
      }}
      style={{flex: 1}}
    />
  );
};

const EstimatedRangeInputsV2 = ({field, form, label, isRequired, isResponsive}) => {
  const startDateField = `${field}.startDate`;
  const startDate = _.get(form.values, startDateField);
  const endDateField = `${field}.endDate`;
  const endDate = _.get(form.values, endDateField);

  return (
    <EstimatedRowContainer hasWarning={false}>
      <DateRangeInput
        form={form}
        field={field}
        startDateField={startDateField}
        endDateField={endDateField}
        toggleRangeOnSameDate={(startDate) => {
          handleToggleEstimatedRange({form, field, wasEstimatedRange: true, startDate});
        }}
        startDateProps={{label, isResponsive}}
        startDateInputProps={{
          placeholder: 'Start date',
          isRequired: isRequired && !startDate,
          isPortaled: true,
        }}
        endDateProps={{
          isResponsive,
          action: (
            <React.Fragment>
              <Space style={{flex: 1}} />
              <EstimatedRangeToggle
                form={form}
                field={field}
                isEstimatedRange
                isResponsive={isResponsive}
              />
            </React.Fragment>
          ),
        }}
        endDateInputProps={{
          placeholder: 'End date',
          isRequired: isRequired && !endDate,
          isPortaled: true,
        }}
      />
    </EstimatedRowContainer>
  );
};

const EstimatedRangeInputs = ({field, form, label, isRequired, isResponsive}) => {
  const responsive = useResponsive();
  const startDateFieldName = `${field}.startDate`;
  const startDate = _.get(form.values, startDateFieldName);
  const endDateFieldName = `${field}.endDate`;
  const endDate = _.get(form.values, endDateFieldName);

  return (
    <EstimatedRangeRow {...responsive}>
      <FieldInput.Memoized
        {...form}
        component={DateInput}
        name={startDateFieldName}
        label={label}
        isResponsive={isResponsive}
        input={{
          required: isRequired && !startDate,
          placeholder: 'Start date',
          setFieldValue: form.setFieldValue,
          style: {
            width: '100%',
          },
          isPortaled: true,
        }}
        style={{flex: 1}}
      />
      <Space width={8} />
      <FieldInput.Memoized
        {...form}
        component={DateInput}
        name={endDateFieldName}
        isResponsive={isResponsive}
        action={
          <React.Fragment>
            <Space style={{flex: 1}} />
            <EstimatedRangeToggle
              form={form}
              field={field}
              isEstimatedRange
              isResponsive={isResponsive}
            />
          </React.Fragment>
        }
        input={{
          required: isRequired && !endDate,
          placeholder: 'End date',
          setFieldValue: (field, endDate) => {
            const isSameDay = Datetime.isSameDay(startDate, endDate);

            // If the dates are equal, we set it to be a scheduled job.
            if (isSameDay) {
              return handleToggleEstimatedRange({form, field, wasEstimatedRange: true});
            }

            // If a date is supplied and it's different from the startDate, we set it as normal.
            form.setFieldValue(field, endDate);
          },
          style: {
            width: '100%',
          },
          isPortaled: true,
        }}
        style={{flex: 1}}
      />
    </EstimatedRangeRow>
  );
};

const JobDateFields = ({
  field,
  form,
  label,
  organization,
  projectTypeId,
  isJobDateRequired,
  isResponsive,
}) => {
  const isEstimatedRange = _.get(form.values, `${field}.isEstimatedRange`);
  const isRequired = organization.features.isEnabledJobStartDateIsRequired;
  const hasPastDateWarning = organization.features.isEnabledPastDateWarning;
  const EstimatedRangeInputComponent = hasPastDateWarning
    ? EstimatedRangeInputsV2
    : EstimatedRangeInputs;

  if (isEstimatedRange) {
    return (
      <EstimatedRangeInputComponent
        field={field}
        form={form}
        label={label}
        isRequired={isRequired}
        isResponsive={isResponsive}
      />
    );
  }
  return (
    <SingleDateInput
      field={field}
      form={form}
      label={label}
      isRequired={isRequired}
      organization={organization}
      projectTypeId={projectTypeId}
      isJobDateRequired={isJobDateRequired}
      isResponsive={isResponsive}
      hasPastDateWarning={hasPastDateWarning}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDateFields.fragment = gql`
  fragment JobDateFields on Organization {
    id
    features {
      isEnabledEstimatedRangeJobs: isEnabled(feature: "DATE_RANGE_JOBS")
      isEnabledJobStartDateIsRequired: isEnabled(feature: "JOB_START_DATE_IS_REQUIRED")
      isEnabledCreateProjectDispatchLocks: isEnabled(feature: "CREATE_PROJECT_DISPATCH_LOCKS")
      isEnabledPastDateWarning: isEnabled(feature: "PAST_DATE_WARNING")
    }
    moveProjectTypes: projectTypesForCategory(category: "MOVE") {
      id
      futureSalesLockedProjectTypeDays {
        id
        day {
          id
          date
        }
      }
    }
    jobTypes {
      id
      kind
    }
  }
`;

export default JobDateFields;
