// Libraries

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';
import {titleize} from '@supermove/utils';

const LEAD = 'LEAD' as const;
const HOLD = 'HOLD' as const;
const BOOKED = 'BOOKED' as const;
const IN_PROGRESS = 'IN_PROGRESS' as const;
const COMPLETED = 'COMPLETED' as const;
const CANCELLED = 'CANCELLED' as const;

const PROJECT_STATUS_OPTIONS = [LEAD, HOLD, BOOKED, IN_PROGRESS, COMPLETED, CANCELLED] as const;
const PROJECT_STATUS_TO_URL_FILTER = {
  [LEAD]: 'leads',
  [HOLD]: 'holds',
  [BOOKED]: 'booked',
  [COMPLETED]: 'completed',
  [CANCELLED]: 'cancelled',
} as const;
const URL_FILTER_TO_PROJECT_STATUS = {
  [PROJECT_STATUS_TO_URL_FILTER.LEAD]: LEAD,
  [PROJECT_STATUS_TO_URL_FILTER.HOLD]: HOLD,
  [PROJECT_STATUS_TO_URL_FILTER.BOOKED]: BOOKED,
  [PROJECT_STATUS_TO_URL_FILTER.COMPLETED]: COMPLETED,
  [PROJECT_STATUS_TO_URL_FILTER.CANCELLED]: CANCELLED,
} as const;
type ProjectStatusToUrlFilterType = typeof PROJECT_STATUS_TO_URL_FILTER;
type ProjectStatusToUrlFilterKeysType = keyof ProjectStatusToUrlFilterType;

export type ProjectStatusUrlFilterType =
  ProjectStatusToUrlFilterType[ProjectStatusToUrlFilterKeysType];
export type ProjectStatusType = (typeof PROJECT_STATUS_OPTIONS)[number];

const OPTIONS = [
  {
    value: LEAD,
    label: 'Lead',
  },
  {
    value: HOLD,
    label: 'Hold',
  },
  {
    value: BOOKED,
    label: 'Booked',
  },
  {
    value: IN_PROGRESS,
    label: 'In Progress',
  },
  {
    value: COMPLETED,
    label: 'Completed',
  },
  {
    value: CANCELLED,
    label: 'Cancelled',
  },
];

const getStatusFromUrlStatus = (status: ProjectStatusUrlFilterType) => {
  return URL_FILTER_TO_PROJECT_STATUS[status];
};

const getStatusLabel = (status: ProjectStatusType) => titleize(status.replace('_', ' '));

const getStatusColor = (status: ProjectStatusType) => {
  switch (status) {
    case LEAD:
    case HOLD:
      return colors.orange.status;
    case BOOKED:
      return colors.blue.interactive;
    case COMPLETED:
      return colors.green.status;
    case CANCELLED:
    default:
      return colors.gray.secondary;
  }
};

const getStatusIcon = (status: ProjectStatusType) => {
  switch (status) {
    case LEAD:
      return Icon.Trophy;
    case HOLD:
      return Icon.CalendarClock;
    case BOOKED:
      return Icon.CalendarCheck;
    case COMPLETED:
      return Icon.Check;
    case CANCELLED:
    default:
      return Icon.CalendarXmark;
  }
};

const getUrlFilter = (
  status: Exclude<ProjectStatusType, 'IN_PROGRESS'>,
): ProjectStatusUrlFilterType => {
  return PROJECT_STATUS_TO_URL_FILTER[status];
};

const typeGuard = (
  status: ProjectStatusType,
  {
    isProjectStatuses = [],
    isNotProjectStatuses = [],
  }: {isProjectStatuses?: ProjectStatusType[]; isNotProjectStatuses?: ProjectStatusType[]},
) => {
  return isProjectStatuses.includes(status) && !isNotProjectStatuses.includes(status);
};

export default {
  // Enum Options
  LEAD,
  HOLD,
  BOOKED,
  IN_PROGRESS,
  COMPLETED,
  CANCELLED,

  OPTIONS,

  // Helpers
  getStatusLabel,
  getStatusColor,
  getStatusIcon,
  getStatusFromUrlStatus,
  getUrlFilter,
  typeGuard,
};
