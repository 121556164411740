// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';

// App
import FullWidthTabs from '@shared/design/components/Tabs/FullWidthTabs';
import Line from 'modules/App/components/Line';
import TaskComments from 'modules/TaskManagement/Tasks/components/TaskComments';
import TaskEventsList from 'modules/TaskManagement/Tasks/components/TaskEventsList';

const getTabs = ({task}) => {
  const commentsCount = task.comments.length;
  const defaultTabs = [
    {
      label: 'Comments',
      count: commentsCount || null,
    },
    {
      label: 'Event Log',
    },
  ];

  return defaultTabs;
};

const TabContent = ({tabIndex, task, form, field}) => {
  switch (tabIndex) {
    case 1:
      return <TaskEventsList task={task} />;
    default:
      return <TaskComments task={task} form={form} field={field} />;
  }
};

const TaskDrawerTabs = ({task, form, field}) => {
  const tabs = getTabs({task});
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <React.Fragment>
      <FullWidthTabs
        tabs={tabs}
        handlePressTab={(tab) => setCurrentTab(tab.index)}
        activeTabIndex={currentTab}
      />
      <Line />
      <TabContent task={task} form={form} field={field} tabIndex={currentTab} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TaskDrawerTabs.fragment = gql`
  ${TaskComments.fragment}
  ${TaskEventsList.fragment}

  fragment TaskDrawerTabs on Task {
    id
    comments {
      id
    }
    ...TaskComments
    ...TaskEventsList
  }
`;

export default TaskDrawerTabs;
