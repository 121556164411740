// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import TaskForm from '@shared/modules/TaskManagement/forms/TaskForm';

const useCreateTaskMutation = ({taskForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      taskForm: TaskForm.toForm(taskForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateTaskMutation.mutation,
    variables: {
      taskForm: TaskForm.toMutation(form.values.taskForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateTaskMutation.mutation = gql`
  mutation CreateTaskMutation($taskForm: TaskForm!) {
    response: createTask(taskForm: $taskForm) {
      ${gql.errors}
      task {
        id
      }
    }
  }
`;

export default useCreateTaskMutation;
