// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';

const PageContainer = Styled.View`
`;

const OrganizationSettingsDocumentTemplateBreadcrumb = () => {
  const {navigator} = useNavigationDOM();
  const breadcrumbs = [
    {name: 'Settings', onPress: () => navigator.push('/settings')},
    {name: 'Document Library'},
  ];

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};

const OrganizationSettingsDocumentTemplateHeader = () => {
  return (
    <PageContainer>
      <StandardOfficeHeader title={''} showLine={false}>
        <OrganizationSettingsDocumentTemplateBreadcrumb />
      </StandardOfficeHeader>
    </PageContainer>
  );
};

export default OrganizationSettingsDocumentTemplateHeader;
