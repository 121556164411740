// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useHover} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Row = Styled.View`
  flex-direction: row;
`;

const SkeletonComponent = ({width}) => {
  return <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} width={width} />;
};

const ActionBadge = ({
  icon,
  text,
  isActive,
  isDisabled,
  onPress,
  style,
  containerStyle,
  isWidthOfContainer,
  children,
}) => {
  const {isHovered, ref} = useHover();
  return (
    <Row ref={ref} style={containerStyle}>
      <SecondaryButton
        iconLeft={icon}
        iconSize={12}
        text={text}
        isSmall
        textColor={isActive ? colors.blue.interactive : colors.gray.secondary}
        isDisabled={isDisabled}
        onPress={onPress}
        isWidthOfContainer={isWidthOfContainer}
        isResponsive
        style={{
          paddingHorizontal: 8,
          borderColor: isDisabled
            ? colors.gray.border
            : isActive
              ? colors.blue.interactive
              : isHovered
                ? colors.blue.hover
                : colors.gray.border,
          ...style,
        }}
      >
        {children}
      </SecondaryButton>
    </Row>
  );
};

ActionBadge.SkeletonComponent = SkeletonComponent;

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ActionBadge.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  isActive: PropTypes.bool,
  isWidthOfContainer: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.object,
  containerStyle: PropTypes.object,
};

ActionBadge.defaultProps = {
  icon: null,
  text: null,
  isActive: false,
  isWidthOfContainer: false,
  style: {},
  containerStyle: {},
};

SkeletonComponent.propTypes = {
  width: PropTypes.number,
};

SkeletonComponent.defaultProps = {
  width: 40,
};

export default ActionBadge;
