// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import EmailForm from '@shared/modules/Email/forms/EmailForm';

const useSendEmailForJobForm = ({viewerId, job, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      emailForm: EmailForm.newFromProject({project: job.project, viewerId}),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSendEmailForJobForm.mutation,
    variables: {
      jobId: job.id,
      emailForm: EmailForm.toMutation(form.values.emailForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSendEmailForJobForm.fragment = gql`
  ${EmailForm.newFromProject.fragment}

  fragment useSendEmailForJobForm on Job {
    id
    project {
      id
      ...EmailForm_newFromProject
    }
  }
`;

useSendEmailForJobForm.mutation = gql`
  mutation SendEmailForJobMutation(
    $jobId: Int!,
    $emailForm: EmailForm!,
  ) {
    response: sendEmailForJob(
      jobId: $jobId,
      emailForm: $emailForm,
    ) {
      ${gql.errors}
      email {
        id
        subject
        body
      }
    }
  }
`;

export default useSendEmailForJobForm;
