// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import BillRuleTypeForm from '@shared/modules/Billing/forms/BillRuleTypeForm';
import useUpdateBillRuleTypeMutation from '@shared/modules/Billing/hooks/useUpdateBillRuleTypeMutation';
import BillRuleTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypeDrawer';
import BillRuleTypeFieldsV1 from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypeFieldsV1';

const EditBillRuleTypeDrawerV1 = ({billRuleType, refetch, userId, isOpen, handleClose}) => {
  const billRuleTypeForm = BillRuleTypeForm.edit(billRuleType, {userId});
  const {form, submitting, handleSubmit} = useUpdateBillRuleTypeMutation({
    billRuleTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <BillRuleTypeDrawer
      isOpen={isOpen}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      headerText={'Edit Bill Rule'}
    >
      <BillRuleTypeFieldsV1 form={form} field={'billRuleTypeForm'} />
    </BillRuleTypeDrawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillRuleTypeDrawerV1.fragment = gql`
  ${BillRuleTypeForm.edit.fragment}
  fragment EditBillRuleTypeDrawerV1 on BillRuleType {
    id
    ...BillRuleTypeForm_edit
  }
`;

export default EditBillRuleTypeDrawerV1;
