// Libraries
import React from 'react';

// Supermove
import {Styled, Space, DropdownInput} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

import FieldInput from '@shared/design/components/Field/FieldInput';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const OptionText = Styled.Text`
  ${Typography.Body}
`;

const ColorDot = Styled.View`
  height: 14px;
  width: 14px;
  border-radius: 14px;
  background-color: ${({color}) => color};
`;

const ColorOption = ({isSelected, value, label}) => {
  return (
    <Row>
      <ColorDot color={value} />
      <Space width={8} />
      <OptionText style={{color: isSelected ? colors.white : colors.black}}>{label}</OptionText>
    </Row>
  );
};

const ClaimTypeColorDropdown = ({form, field, label, index, placeholder, disabled}) => {
  return (
    <FieldInput
      {...form}
      label={label}
      name={field}
      component={DropdownInput}
      index={index}
      isRequired
      input={{
        options: colors.getDisplayColorOptions(),
        placeholder,
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        disabled,
        components: {
          SingleValue: (props) => {
            return (
              <DropdownInput.SingleValueContainer {...props}>
                <ColorOption value={props.data.value} label={props.data.label} />
              </DropdownInput.SingleValueContainer>
            );
          },
          Option: (props) => {
            return (
              <DropdownInput.OptionContainer {...props}>
                <ColorOption
                  isSelected={props.isSelected}
                  value={props.data.value}
                  label={props.data.label}
                />
              </DropdownInput.OptionContainer>
            );
          },
        },
      }}
    />
  );
};

export default ClaimTypeColorDropdown;
