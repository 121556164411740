// Supermove
import {gql} from '@supermove/graphql';
import {Distance, Json, withFragment} from '@supermove/utils';

const getDisplayReferral = withFragment(
  (job) => {
    if ((job as any).referralSource === 'Other' && (job as any).referralDetails) {
      return `Other: ${(job as any).referralDetails}`;
    } else if ((job as any).referralSource) {
      return (job as any).referralSource;
    }
    return '';
  },
  gql`
    fragment JobRequest_getDisplayReferral on JobRequest {
      referralSource
      referralDetails
    }
  `,
);

const JobRequest = {
  // Getters
  getDisplayReferral,

  getAdditionalItems: (jobRequest: any) => {
    return Json.toObject(jobRequest.additionalItems);
  },
  getTotalDistanceText: ({totalDistance}: any) => {
    return Distance.display(totalDistance);
  },
};

export default JobRequest;
