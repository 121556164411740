import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getDropdownOptions = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ referralSources }: { referral... Remove this comment to see the full error message
  ({referralSources}) => {
    return [
      ...referralSources.map((referralSource: any) => ({
        value: referralSource.name,
        label: referralSource.name,
      })),
      {value: 'Other', label: 'Other'},
    ];
  },
  gql`
    fragment ReferralSource_getDropdownOptions on ReferralSource {
      name
    }
  `,
);

const ReferralSource = {
  getDropdownOptions,
};

export default ReferralSource;
