// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {Datetime, withFragment} from '@supermove/utils';

const edit = withFragment(
  (job) => ({
    jobId: (job as any).id,
    jobTypeId: (job as any).jobType.id,
    isBillingReset: false,
    isBillingValuesReset: false,
    // Private
    jobName: (job as any).fullName,
    jobDisplayDate: Job.getDisplayDate(job, Datetime.DISPLAY_SHORT_DATE),
    jobTypeName: (job as any).jobType.name,
    isMatched: false,
  }),
  gql`
    ${Job.getDisplayDate.fragment}
    fragment UpdateJobTypeForJobForm_edit on Job {
      id
      jobType {
        id
        name
      }
      fullName
      ...Job_getDisplayDate
    }
  `,
);

const toForm = ({
  jobId,
  jobTypeId,
  isBillingReset,
  isBillingValuesReset,

  // Private
  jobName,

  jobDisplayDate,
  jobTypeName,
  isMatched,
}: any) => ({
  jobId,
  jobTypeId: _.toString(jobTypeId),
  isBillingReset,
  isBillingValuesReset,

  // Private
  jobName,
  jobDisplayDate,
  jobTypeName,
  isMatched,
});

const toMutation = ({jobId, jobTypeId, isBillingReset, isBillingValuesReset}: any) => ({
  jobId,
  jobTypeId,
  isBillingReset,
  isBillingValuesReset,
});

const UpdateJobTypeForJobForm = {
  edit,
  toForm,
  toMutation,
};

export default UpdateJobTypeForJobForm;
