// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Checkbox, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
import UserNotificationSettingForm from '@shared/modules/Notification/forms/UserNotificationSettingForm';
import useUpdateUserNotificationSettingMutation from '@shared/modules/Notification/hooks/useUpdateUserNotificationSettingMutation';

const Container = Styled.View`
`;

const NotificationGroupContainer = Styled.View`
  max-width: 720px;
  margin-bottom: 32px;
`;

const NotificationGroupHeaderContainer = Styled.View`
  flex-direction: row;
`;

const NotificationGroupTitle = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const NotificationType = Styled.Text`
  ${Typography.Label2}
  text-transform: uppercase;
`;

const NotificationTitle = Styled.Text`
  ${Typography.Body3}
`;

const EmptySpace = Styled.View`
  flex: 1;
`;

const RowContainer = Styled.View`
  width: 240px;
  flex-direction: row;
`;

const RowItem = Styled.View`
`;

const RowSpaceItem = Styled.View`
  width: 120px;
  justify-content: center;
  align-items: flex-end;
`;

const NotificationSettingContainer = Styled.View`
  flex-direction: row;
  border: 1px solid ${colors.gray.border};
  border-top-width: ${({isFirst}: any) => (isFirst ? '1px' : '0px')};
  padding-vertical: 12px;
  padding-horizontal: 16px;
`;

const NotficationTemplateGroup = {
  ACCOUNTING: 'ACCOUNTING',
  DISPATCH: 'DISPATCH',
  SALES: 'SALES',
  TASKS: 'TASKS',
};

const SETTINGS_GROUPS_WITH_EMAIL: string[] = [];

// NOTE(dan) All settings are visible now, but leaving this here for
// potential usage in future features.
const getIsSettingVisible = ({userNotificationSetting}: any) => {
  const {kind} = userNotificationSetting.notificationSetting;
  switch (kind) {
    default:
      return true;
  }
};

const NotificationGroupHeader = ({notificationSettingsGroupTitle, hasEmailSetting}: any) => {
  return (
    <NotificationGroupHeaderContainer>
      <NotificationGroupTitle>{notificationSettingsGroupTitle}</NotificationGroupTitle>
      <EmptySpace />
      <RowContainer style={{marginRight: '16px'}}>
        <RowSpaceItem>
          <NotificationType>In-App</NotificationType>
        </RowSpaceItem>
        {hasEmailSetting && (
          <RowSpaceItem>
            <NotificationType>Email</NotificationType>
          </RowSpaceItem>
        )}
      </RowContainer>
    </NotificationGroupHeaderContainer>
  );
};

const NotificationSetting = ({userNotificationSetting, refetch, hasEmailSetting, isFirst}: any) => {
  const userNotificationSettingForm = UserNotificationSettingForm.edit(userNotificationSetting);
  const {form, handleSubmit} = useUpdateUserNotificationSettingMutation({
    userNotificationSettingForm,
    onSuccess: () => {
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const isEmailEnabled = _.get(form.values, 'userNotificationSettingForm.isEmailEnabled');

  return (
    <NotificationSettingContainer isFirst={isFirst}>
      <RowItem>
        <NotificationTitle>
          {userNotificationSetting.notificationSetting.description}
        </NotificationTitle>
      </RowItem>
      <RowItem style={{flex: 1}} />
      <RowItem style={{flexDirection: 'row'}}>
        <RowContainer>
          <RowSpaceItem>
            <Checkbox
              checked={form.values.userNotificationSettingForm.isInAppEnabled}
              color={colors.blue.accentDark}
              iconSize={14}
              onChange={() => {
                const changedValue = !form.values.userNotificationSettingForm.isInAppEnabled;
                form.setFieldValue('userNotificationSettingForm.isInAppEnabled', changedValue);
                setTimeout(handleSubmit, 0);
              }}
            />
          </RowSpaceItem>
          {hasEmailSetting && (
            <RowSpaceItem>
              <Checkbox
                checked={isEmailEnabled}
                color={colors.blue.accentDark}
                iconSize={14}
                onChange={() => {
                  const changedValue = !isEmailEnabled;
                  form.setFieldValue('userNotificationSettingForm.isEmailEnabled', changedValue);
                  setTimeout(handleSubmit, 0);
                }}
              />
            </RowSpaceItem>
          )}
        </RowContainer>
      </RowItem>
    </NotificationSettingContainer>
  );
};

const NotificationGroup = ({
  userNotificationSettings,
  notificationSettingsGroupTitle,
  refetch,
}: any) => {
  const hasEmailSetting = SETTINGS_GROUPS_WITH_EMAIL.includes(notificationSettingsGroupTitle);

  return (
    <NotificationGroupContainer>
      <NotificationGroupHeader
        notificationSettingsGroupTitle={notificationSettingsGroupTitle}
        hasEmailSetting={hasEmailSetting}
      />
      <Space height={16} />
      {userNotificationSettings.map((userNotificationSetting: any, index: number) => {
        const isVisible = getIsSettingVisible({userNotificationSetting});
        if (isVisible) {
          return (
            <NotificationSetting
              key={userNotificationSetting.id}
              userNotificationSetting={userNotificationSetting}
              refetch={refetch}
              hasEmailSetting={hasEmailSetting}
              isFirst={index === 0}
            />
          );
        }
        return null;
      })}
    </NotificationGroupContainer>
  );
};

const UserNotificationsSettingsContent = ({
  viewer,
  refetch,
}: {
  viewer: any;
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  const userNotificationSettingsGroups = _.groupBy(
    viewer.userNotificationSettings,
    'notificationSetting.group',
  );

  return (
    <Container>
      {[
        NotficationTemplateGroup.SALES,
        NotficationTemplateGroup.DISPATCH,
        // NotficationTemplateGroup.ACCOUNTING,
        NotficationTemplateGroup.TASKS,
      ].map((group) => {
        return (
          <NotificationGroup
            key={group}
            userNotificationSettings={_.sortBy(
              _.get(userNotificationSettingsGroups, group, []),
              'notificationSetting.description',
            )}
            notificationSettingsGroupTitle={group}
            refetch={refetch}
          />
        );
      })}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserNotificationsSettingsContent.fragment = gql`
  ${UserNotificationSettingForm.edit.fragment}

  fragment UserNotificationsSettingsContent on User {
    id
    userNotificationSettings {
      id
      notificationSetting {
        kind
        group
        description
      }
      isInAppEnabled
      isEmailEnabled
      ...UserNotificationSettingForm_edit
    }
  }
`;

export default UserNotificationsSettingsContent;
