// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DataTableForm from '@shared/modules/Billing/forms/DataTableForm';

const useUpdateDataTableMutation = ({dataTableForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      dataTableForm: DataTableForm.toForm(dataTableForm),
    },
  });
  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateDataTableMutation.mutation,
    variables: {
      dataTableForm: DataTableForm.toMutation(form.values.dataTableForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateDataTableMutation.mutation = gql`
  mutation useUpdateDataTableMutation($dataTableForm: DataTableForm!) {
    response: updateDataTable(dataTableForm: $dataTableForm) {
      ${gql.errors}
      dataTable {
        id
      }
    }
  }
`;

export default useUpdateDataTableMutation;
