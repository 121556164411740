// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  DropdownInput,
  CurrencyInput,
  PercentInput,
  Styled,
  DateInput,
  TimeInput,
} from '@supermove/components';
import {Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';

const TextInput = Styled.TextInput`
  ${Typography.Body3}
  margin-top: 3px;
`;

const CurrencyFieldInput = ({form, fieldName, isDisabled, onChangeValue}) => (
  <FieldInput
    {...form}
    index={1}
    component={CurrencyInput}
    name={`${fieldName}.defaultAmount`}
    input={{
      disabled: isDisabled,
      component: TextInput,
      placeholder: 'Default value',
      setFieldTouched: form.setFieldTouched,
      setFieldValue: form.setFieldValue,
    }}
    handleBlur={onChangeValue}
  />
);

const PercentFieldInput = ({form, fieldName, isDisabled, onChangeValue}) => (
  <FieldInput
    {...form}
    index={1}
    component={PercentInput}
    name={`${fieldName}.defaultAmount`}
    input={{
      disabled: isDisabled,
      component: TextInput,
      placeholder: 'Default value',
      setFieldTouched: form.setFieldTouched,
      setFieldValue: form.setFieldValue,
    }}
    handleBlur={onChangeValue}
  />
);

const DropdownStringFieldInput = ({form, variable, fieldName, isDisabled, onChangeValue}) => (
  <FieldInput
    {...form}
    index={1}
    component={DropdownInput}
    name={`${fieldName}.defaultStringValue`}
    input={{
      isPortaled: true,
      disabled: isDisabled,
      placeholder: 'Select default value',
      isSearchable: true,
      options: variable.defaultAmountOptions.map((option) => {
        return {label: option.label, value: option.value};
      }),
      setFieldValue: (fieldName, value) => {
        form.setFieldValue(fieldName, value);
      },
      backgroundColor: 'transparent',
      style: {
        width: '100%',
      },
      onChangeValue,
    }}
  />
);

const DefaultAmountInlineField = ({form, variable, fieldName, isDisabled, onChangeValue}) => {
  const variableForm = _.get(form.values, fieldName);
  switch (variableForm.format) {
    case VariableFormat.CITY_VALUE_CURRENCY:
    case VariableFormat.CURRENCY:
    case VariableFormat.VALUE_TABLE_CURRENCY:
      return (
        <CurrencyFieldInput
          form={form}
          fieldName={fieldName}
          isDisabled={isDisabled}
          onChangeValue={onChangeValue}
        />
      );
    case VariableFormat.PERCENT:
      return (
        <PercentFieldInput
          form={form}
          fieldName={fieldName}
          isDisabled={isDisabled}
          onChangeValue={onChangeValue}
        />
      );
    case VariableFormat.DROPDOWN_STRING:
      return (
        <DropdownStringFieldInput
          form={form}
          variable={variable}
          fieldName={fieldName}
          isDisabled={isDisabled}
          onChangeValue={onChangeValue}
        />
      );
    case VariableFormat.STRING:
      return (
        <FieldInput
          {...form}
          index={1}
          name={`${fieldName}.defaultStringValue`}
          input={{
            disabled: isDisabled,
            placeholder: 'Default value',
          }}
          handleChange={onChangeValue}
        />
      );
    case VariableFormat.DATE:
      return (
        <FieldInput
          {...form}
          component={DateInput}
          name={`${fieldName}.defaultStringValue`}
          input={{
            isPortaled: true,
            disabled: isDisabled,
            placeholder: 'Enter date',
            setFieldValue: form.setFieldValue,
            style: {width: '100%'},
            onChangeDate: onChangeValue,
          }}
          style={{flex: 1}}
        />
      );
    case VariableFormat.TIME:
      return (
        <FieldInput
          {...form}
          index={1}
          component={TimeInput}
          name={`${fieldName}.defaultStringValue`}
          input={{
            disabled: isDisabled,
            component: TextInput,
            placeholder: 'Enter time',
            setFieldValue: form.setFieldValue,
            style: {width: '100%'},
            onChangeTime: onChangeValue,
            invalidTimeValue: '',
          }}
          style={{flex: 1}}
        />
      );
    default:
      return (
        <FieldInput
          {...form}
          index={1}
          name={`${fieldName}.defaultAmount`}
          input={{
            disabled: isDisabled,
            placeholder: 'Default value',
          }}
          handleChange={onChangeValue}
        />
      );
  }
};

export default DefaultAmountInlineField;
