const _new = ({inAppNotificationId}: any) => ({
  inAppNotificationId,
});

const toForm = ({inAppNotificationId}: any) => ({
  inAppNotificationId,
});

const toMutation = ({inAppNotificationId}: any) => ({
  inAppNotificationId,
});

const InAppNotificationForm = {
  new: _new,
  toForm,
  toMutation,
};

export default InAppNotificationForm;
