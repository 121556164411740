// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ContainerForm from '@shared/modules/Storage/forms/ContainerForm';

const useDeleteContainerMutation = ({containerForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      containerForm: ContainerForm.toForm(containerForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteContainerMutation.mutation,
    variables: {
      containerForm: ContainerForm.toMutation(form.values.containerForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteContainerMutation.mutation = gql`
  mutation useDeleteContainerMutation($containerForm: ContainerForm) {
    response: deleteContainer(containerForm: $containerForm) {
      ${gql.errors}
      container {
        id
      }
    }
  }
`;

export default useDeleteContainerMutation;
