// Libraries
import React from 'react';

// App
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// Components

const Content = Styled.View`
  flex-direction: row;
  padding-vertical: 10px;
`;

const Wrapper = Styled.View`
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 200px;
  margin-right: 20px;
  border-radius: 5px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const Title = Styled.H3`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Subtitle = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Item = ({title, subtitle}) => (
  <Wrapper>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Wrapper>
);

const SalesPayableUsersReportSummaries = ({payableUsersReport}) => {
  return (
    <ScrollView horizontal>
      <Content>
        <Item title={payableUsersReport.payableUsersCount} subtitle={'employees'} />
      </Content>
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SalesPayableUsersReportSummaries.fragment = gql`
  fragment SalesPayableUsersReportSummaries on PayableUsersReport {
    payableUsersCount
  }
`;

export default SalesPayableUsersReportSummaries;
