// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation, useMemo} from '@supermove/hooks';
import {Organization} from '@supermove/models';

// App
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';

// NOTE(mark): The jobs + jobType are needed for the NewJobSuccessModal on the manager app. We
// should find a better way to handle mutation responses with components.
const MUTATION = gql`
  mutation CreateProjectForm($projectForm: ProjectForm!) {
    response: createProject(projectForm: $projectForm) {
      ${gql.errors}
      project {
        id
        uuid
        jobs {
          id
          uuid
          jobType {
            id
            name
          }
        }
      }
    }
  }
`;

const useCreateProjectMutation = ({projectForm, organization, onSuccess, onError}: any) => {
  const initialValues = useMemo(() => {
    return {
      projectForm: ProjectForm.toForm(projectForm),

      // Private attributes
      jobFormCustomValues: Organization.getJobFormCustomValues(organization),
      jobFormAdditionalItems: Organization.getJobFormAdditionalItems(organization),
    };
  }, [projectForm, organization]);

  const form = useForm({initialValues});
  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: MUTATION,
    variables: {
      projectForm: ProjectForm.toMutation(form.values.projectForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateProjectMutation.fragment = gql`
  ${Organization.getJobFormAdditionalItems.fragment}
  ${Organization.getJobFormCustomValues.fragment}
  fragment useCreateProjectMutation on Organization {
    id
    ...Organization_getJobFormAdditionalItems
    ...Organization_getJobFormCustomValues
  }
`;

export default useCreateProjectMutation;
