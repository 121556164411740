// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';

const Container = Styled.ButtonV2`
  flex-direction: column;
  padding: 12px 16px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const NameContainer = Styled.View`
  flex: 1;
  flex-direction: column;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Label};
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DescriptionEmpty = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

export interface GlobalDashboardDataType {
  id: string;
  uuid: string;
  name: string;
  description: string;
  publishedAt: string;
}

type GlobalDashboardsLibraryPageGlobalDashboardsListItemProps = {
  globalDashboard: GlobalDashboardDataType;
  isSelected: boolean;
  isAdded: boolean;
  handleSelect: () => void;
};

const GlobalDashboardsLibraryPageGlobalDashboardsListItem = ({
  globalDashboard,
  isSelected,
  isAdded,
  handleSelect,
}: GlobalDashboardsLibraryPageGlobalDashboardsListItemProps) => {
  const responsive = useResponsive();

  const publishedAt = Datetime.fromDate(globalDashboard.publishedAt);
  const diffDays = Datetime.getDifference(publishedAt, Datetime.now, 'days');

  return (
    <Container
      onPress={handleSelect}
      style={{
        backgroundColor: isSelected ? colors.blue.accent : colors.white,
      }}
    >
      <Row>
        <NameContainer>
          <Title responsive={responsive}>{globalDashboard.name}</Title>
        </NameContainer>
        <Row>
          {diffDays <= 30 && <Badge label='New!' color={colors.green.status} />}
          {isAdded && (
            <React.Fragment>
              <Space width={8} />
              <Badge label='Added' color={colors.gray.secondary} />
            </React.Fragment>
          )}
        </Row>
      </Row>
      <Space height={4} />
      {globalDashboard.description ? (
        <Description responsive={responsive}>{globalDashboard.description}</Description>
      ) : (
        <DescriptionEmpty responsive={responsive}>No description.</DescriptionEmpty>
      )}
    </Container>
  );
};

GlobalDashboardsLibraryPageGlobalDashboardsListItem.fragment = gql`
  fragment GlobalDashboardsLibraryPageGlobalDashboardsListItem on GlobalDashboard {
    id
    uuid
    name
    description
    publishedAt
  }
`;

export default GlobalDashboardsLibraryPageGlobalDashboardsListItem;
