// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const Header = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Label = Styled.Text`
  ${Typography.Body3}
  color: ${({vars}) => (_.get(vars, 'hasErrors') ? colors.red.warning : colors.gray.primary)};
`;

const Action = Styled.Touchable`
`;

const ActionText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const TextInput = Styled.TextInput`
  ${Typography.Body3}
  color: ${({disabled}) => (disabled ? colors.gray.secondary : colors.gray.primary)};
  background-color: ${({disabled}) => (disabled ? colors.gray.disabled : colors.white)};
`;

const ValidationError = Styled.Text`
  ${Typography.Body3}
  color: ${colors.red.warning};
`;

const Field = ({
  index = 0,
  actionText,
  name,
  label,
  action,
  touched,
  errors,
  values,
  component: InputComponent = TextInput,
  input = {},
  handleAction,
  handleBlur,
  handleChange,
  style,
}) => {
  const isTouched = !!_.get(touched, name);
  const errorMessage = _.get(errors, name);
  const hasErrors = isTouched && errorMessage;
  const hasHeader = !_.isNil(label) || action || (actionText && handleAction);

  return (
    <Container index={index} style={style}>
      {hasHeader && (
        <React.Fragment>
          <Header>
            {!_.isNil(label) && (
              <React.Fragment>
                <Label vars={{hasErrors}} numberOfLines={1}>
                  {label}
                </Label>
              </React.Fragment>
            )}
            {action}
            {!!actionText && !!handleAction && (
              <Action onPress={handleAction}>
                <ActionText>{actionText}</ActionText>
              </Action>
            )}
          </Header>
          <Space height={4} />
        </React.Fragment>
      )}
      <InputComponent
        {...input}
        name={name}
        value={_.get(values, name)}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasErrors && (
        <React.Fragment>
          <Space height={4} />
          <ValidationError>{errorMessage}</ValidationError>
        </React.Fragment>
      )}
    </Container>
  );
};

Field.Label = Label;
Field.TextInput = TextInput;

// --------------------------------------------------
// Props
// --------------------------------------------------
Field.propTypes = {
  action: PropTypes.object,
  actionText: PropTypes.string,
  handleAction: PropTypes.func,
};

Field.defaultProps = {
  action: null,
  actionText: null,
  handleAction: null,
};

export default Field;
