// Supermove
import {colors} from '@supermove/styles';

const ACTIVE = 'ACTIVE';
const COMPLETED = 'COMPLETED';
const CANCELLED = 'CANCELLED';
const EXPIRED = 'EXPIRED';

const OPTIONS = [
  {
    value: ACTIVE,
    label: 'Active',
    textColor: colors.blue.interactive,
    backgroundColor: colors.blue.accent,
  },
  {
    value: COMPLETED,
    label: 'Completed',
    textColor: colors.green.status,
    backgroundColor: colors.green.accent,
  },
  {
    value: CANCELLED,
    label: 'Cancelled',
    textColor: colors.gray.secondary,
    backgroundColor: colors.gray.background,
  },
  {
    value: EXPIRED,
    label: 'Expired',
    textColor: colors.red.hover,
    backgroundColor: colors.red.accent,
  },
];

export default {
  ACTIVE,
  COMPLETED,
  CANCELLED,
  EXPIRED,

  OPTIONS,
};
