// Libraries
import React from 'react';

// Supermove
import {Icon, Loading} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {Form, useToast} from '@supermove/hooks';
import {ProjectTypeModel} from '@supermove/models';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import UpdateProjectTypePaymentMethodsForm, {
  UpdateProjectTypePaymentMethodsFormType,
} from '@shared/modules/PaymentMethod/forms/UpdateProjectTypePaymentMethodsForm';
import useUpdateProjectTypePaymentMethodsMutation from '@shared/modules/PaymentMethod/hooks/useUpdateProjectTypePaymentMethodsMutation';
import ProjectTypePaymentMethodFields from 'modules/Organization/Settings/Company/components/ProjectTypePaymentMethodFields';

const TITLE = 'Select Payment Methods';
const SUBTITLE = 'Select the payment methods available on the Crew App for Local Move.';

const EditProjectTypePaymentMethodsDrawerContent = ({
  isOpen,
  handleClose,
  projectType,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  projectType: ProjectTypeModel;
  refetch: () => void;
}) => {
  const successToast = useToast({ToastComponent: SuccessToast, message: 'Changes saved.'});
  const updateProjectTypePaymentMethodsForm = UpdateProjectTypePaymentMethodsForm.edit(projectType);
  const {form, handleSubmit, submitting} = useUpdateProjectTypePaymentMethodsMutation({
    updateProjectTypePaymentMethodsForm,
    onSuccess: () => {
      successToast.handleToast();
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  const field = 'updateProjectTypePaymentMethodsForm.projectTypePaymentMethodForms';
  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={TITLE}
      description={SUBTITLE}
      primaryActionText={'Save'}
      primaryActionIcon={Icon.Check}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      shouldCloseOnClickOutside={false}
      isUsingLoadingComponent
    >
      <ProjectTypePaymentMethodFields
        form={
          form as unknown as Form<{
            updateProjectTypePaymentMethodsForm: UpdateProjectTypePaymentMethodsFormType;
          }>
        }
        field={field}
        organization={projectType.organization}
      />
    </DrawerWithAction>
  );
};

const EditProjectTypePaymentMethodsDrawer = ({
  isOpen,
  handleClose,
  projectTypeId,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  projectTypeId: number;
  refetch: () => void;
}) => {
  const {data, loading} = useQuery(EditProjectTypePaymentMethodsDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectTypeId},
    skip: !isOpen,
  });

  return (
    <Loading
      loading={loading}
      as={() => {
        return (
          <DrawerWithAction.LoadingComponent
            isOpen={isOpen}
            headerText={TITLE}
            handleClose={handleClose}
          >
            <PageLoadingIndicator />
          </DrawerWithAction.LoadingComponent>
        );
      }}
    >
      {() =>
        data ? (
          <EditProjectTypePaymentMethodsDrawerContent
            isOpen={isOpen}
            handleClose={handleClose}
            projectType={data?.projectType}
            refetch={refetch}
          />
        ) : null
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectTypePaymentMethodsDrawer.query = gql`
  ${ProjectTypePaymentMethodFields.fragment}
  ${UpdateProjectTypePaymentMethodsForm.edit.fragment}
  query EditProjectTypePaymentMethodsDrawer($projectTypeId: Int!) {
    projectType(projectTypeId: $projectTypeId) {
      id
      organization {
        id
        ...ProjectTypePaymentMethodFields
      }
      ...UpdateProjectTypePaymentMethodsForm_edit
    }
  }
`;

export default EditProjectTypePaymentMethodsDrawer;
