// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

// Components

const Container = Styled.View`
`;

const Label = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const Value = Styled.H7`
`;

const Empty = Styled.H7`
  ${fontWeight(500)}
  font-style: italic;
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
`;

const Content = ({newTab, numberOfLines, value, empty}) => {
  if (!value) {
    return <Empty numberOfLines={numberOfLines}>{empty}</Empty>;
  }
  return <Value numberOfLines={numberOfLines}>{value}</Value>;
};

const FieldValue = ({newTab, numberOfLines, label, value, empty, style}) => (
  <Container style={style}>
    <Label>{label}</Label>
    <Content newTab={newTab} numberOfLines={numberOfLines} value={value} empty={empty} />
  </Container>
);

export default FieldValue;
