// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Cost} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import CostForm from '@shared/modules/Billing/forms/CostForm';
import Line from 'modules/App/components/Line';
import Switch from 'modules/App/components/Switch';
import SystemMessage from 'modules/App/components/SystemMessage';
import CostSectionBillItemsV2 from 'modules/Cost/components/CostSectionBillItemsV2';
import CostSectionCostItemsV2 from 'modules/Cost/components/CostSectionCostItemsV2';

const Container = Styled.View`
`;

const IndexedContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Body}
`;

const SubText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const CostSectionWrapper = Styled.View`
  z-index: ${({index}) => 100 - index};
  padding-bottom: 12px;
`;

const CostSectionContainer = Styled.View`
  border-width: 1px;
  border-radius: 8px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const CostSectionRow = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 16px;
`;

const LabelText = Styled.Text`
  ${Typography.Label}
`;

const FooterRow = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 8px;
`;

const Cell = Styled.View`
  flex: ${({flex}) => flex};
  flex-direction: row;
  align-items: center;
  justify-content: ${({justify}) => justify};
  padding-horizontal: 8px;
  border-color: ${colors.gray.border};
`;

const WarningText = Styled.Text`
  ${Typography.Body}
  color: ${colors.red.warning};
`;

const COST_SECTION_FOOTER_COLUMNS = [
  {
    flex: 10,
    justify: 'flex-start',
    cellContent: () => {
      return <SubText>Revenue</SubText>;
    },
  },
  {
    flex: 3,
    justify: 'flex-end',
    style: {borderRightWidth: 1, marginRight: -1, height: 36},
    cellContent: ({form, field}) => {
      const totalRevenue = _.get(form.values, `${field}.totalRevenue`);
      return <LabelText style={{paddingVertical: 10}}>{totalRevenue}</LabelText>;
    },
  },
  {
    flex: 4,
    justify: 'flex-start',
    cellContent: () => {
      return <SubText style={{paddingRight: 26}}>Cost</SubText>;
    },
  },
  {
    flex: 2,
    justify: 'flex-end',
    cellContent: ({form, field}) => {
      const totalCost = _.get(form.values, `${field}.totalCost`);
      return <LabelText>{totalCost}</LabelText>;
    },
  },
];

const CostSectionHeader = ({form, field, cost}) => {
  const name = _.get(form.values, `${field}.name`);
  return (
    <Container>
      <Space height={8} />
      <CostSectionRow>
        <LabelText>{name}</LabelText>
      </CostSectionRow>
      <Space height={8} />
    </Container>
  );
};

const CostSectionFooter = ({form, field}) => {
  return (
    <FooterRow>
      {COST_SECTION_FOOTER_COLUMNS.map(({flex, justify, style, cellContent}, index) => (
        <Cell key={index} flex={flex} justify={justify} style={style}>
          {cellContent({form, field})}
        </Cell>
      ))}
    </FooterRow>
  );
};

const CostSectionsList = ({form, field, cost, costItemTypes, isViewingAllBillItems}) => {
  const costSectionForms = _.get(form.values, `${field}.costSectionForms`);
  return (
    <React.Fragment>
      {costSectionForms.map((costSectionForm, index) => {
        return (
          <CostSectionWrapper key={index} index={index}>
            <CostSectionContainer>
              <CostSectionHeader
                form={form}
                field={`${field}.costSectionForms.${index}`}
                cost={cost}
              />
              <Line />
              {!_.isEmpty(costSectionForm.billItemForms) && (
                <IndexedContainer index={0}>
                  <CostSectionBillItemsV2
                    form={form}
                    field={`${field}.costSectionForms.${index}`}
                    isViewingAllBillItems={isViewingAllBillItems}
                  />
                  <Line />
                </IndexedContainer>
              )}
              <IndexedContainer index={1}>
                <CostSectionCostItemsV2
                  form={form}
                  field={`${field}.costSectionForms.${index}`}
                  costItemTypes={costItemTypes}
                  weight={cost.project.weight}
                  totalDistance={cost.project.totalDistance}
                  projectRevenue={cost.project.totalRevenue}
                  moverPositionId={_.get(cost, 'moverPosition.id')}
                />
                <Line />
              </IndexedContainer>
              <CostSectionFooter form={form} field={`${field}.costSectionForms.${index}`} />
            </CostSectionContainer>
          </CostSectionWrapper>
        );
      })}
    </React.Fragment>
  );
};

const BalanceDueWarning = ({cost}) => {
  const userName = cost.compensationUser.fullName;
  const paidAmount = Currency.display(cost.paidOutAmount);
  const balance = Currency.display(Cost.getRemainingBalance(cost));
  return (
    <SystemMessage containerStyle={{marginHorizontal: 0, paddingHorizontal: 24}}>
      <WarningText>
        {`${userName} was paid ${paidAmount}. There is a remaining balance of ${balance}.`}
      </WarningText>
    </SystemMessage>
  );
};

const EditCostCostItemsModalCostSectionsContent = ({
  form,
  field,
  totalEmployeeCompensation,
  cost,
  costItemTypes,
  isViewingAllBillItems,
  setIsViewingAllBillItems,
}) => {
  const total = CostForm.computeTotal(_.get(form.values, field));
  const isCompensation = _.get(form.values, `${field}.costSectionForms.0.isCompensation`);

  return (
    <Container>
      <Space height={12} />
      <Row>
        {isCompensation && (
          <Button onPress={() => setIsViewingAllBillItems(!isViewingAllBillItems)}>
            <Switch.Button
              isOn={isViewingAllBillItems}
              color={colors.blue.interactive}
              size={Switch.SIZE.MEDIUM}
            />
            <Space width={8} />
            <HeaderText style={{color: colors.gray.secondary}}>View all billing items</HeaderText>
          </Button>
        )}
        <Space style={{flex: 1}} />
        <HeaderText>{Currency.display(total)}</HeaderText>
        <Space width={4} />
        <SubText>{`of ${Currency.display(
          totalEmployeeCompensation,
        )} Total Employee Compensation`}</SubText>
      </Row>
      <Space height={16} />
      {!!cost.compensationUser && Cost.getHasBalanceDue(cost) && (
        <React.Fragment>
          <BalanceDueWarning cost={cost} />
          <Space height={16} />
        </React.Fragment>
      )}
      <CostSectionsList
        form={form}
        field={field}
        cost={cost}
        costItemTypes={costItemTypes}
        isViewingAllBillItems={isViewingAllBillItems}
      />
    </Container>
  );
};

const EditCostCostItemsModalCostSectionsV2 = ({
  form,
  field,
  totalEmployeeCompensation,
  cost,
  isViewingAllBillItems,
  setIsViewingAllBillItems,
}) => {
  const billingLibraryUuid = cost.project.projectType.billingLibrary.uuid;
  const {loading, data} = useQuery(EditCostCostItemsModalCostSectionsV2.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      billingLibraryUuid,
      category: cost.category,
    },
  });
  return (
    <Loading loading={loading}>
      {() => {
        return data ? (
          <EditCostCostItemsModalCostSectionsContent
            form={form}
            field={field}
            totalEmployeeCompensation={totalEmployeeCompensation}
            cost={cost}
            costItemTypes={data.billingLibrary.costItemTypes}
            isViewingAllBillItems={isViewingAllBillItems}
            setIsViewingAllBillItems={setIsViewingAllBillItems}
          />
        ) : null;
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditCostCostItemsModalCostSectionsV2.query = gql`
  ${CostSectionCostItemsV2.fragment}

  query EditCostCostItemsModalCostSectionsV2($billingLibraryUuid: String!, $category: String!) {
    billingLibrary(billingLibraryUuid: $billingLibraryUuid) {
      id
      costItemTypes(category: $category) {
        id
        ...CostSectionCostItemsV2
      }
    }
  }
`;

EditCostCostItemsModalCostSectionsV2.fragment = gql`
  ${Cost.getDisplayIdentifier.fragment}
  ${Cost.getHasBalanceDue.fragment}
  ${Cost.getRemainingBalance.fragment}
  fragment EditCostCostItemsModalCostSectionsV2 on Cost {
    id
    category
    date
    total
    paidOutAmount
    compensationUser {
      id
      fullName
    }
    moverPosition {
      id
    }
    project {
      id
      weight
      totalDistance
      totalRevenue
      projectType {
        id
        billingLibrary {
          id
          uuid
        }
      }
    }
    ...Cost_getDisplayIdentifier
    ...Cost_getHasBalanceDue
    ...Cost_getRemainingBalance
  }
`;

export default EditCostCostItemsModalCostSectionsV2;
