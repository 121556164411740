// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useInlineFormMutation, useModal} from '@supermove/hooks';
import {Config} from '@supermove/sdk';

// App
import Button from '@shared/design/components/Button';
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';
import Panel from '@shared/design/components/Panel';

const Row = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const Group = Styled.View`
  flex: 1;
`;

const DeactivateAccountGrantButton = ({
  accountGrant,
  onDeactivate,
}: {
  accountGrant: any;
  onDeactivate: () => void;
}) => {
  const deactivateModal = useModal({name: 'Deactivate Account Grant Modal'});
  const {submitting, form, handleSubmit} = useInlineFormMutation({
    name: 'accountGrantForm',
    form: {
      accountGrantId: accountGrant.id,
    },
    mutation: DeactivateAccountGrantButton.mutation,
    onSuccess: () => onDeactivate(),
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <React.Fragment>
      <Button onPress={deactivateModal.handleOpen} text={'Disconnect Account'} />
      <WarningModal
        isOpen={deactivateModal.isOpen}
        title={'Deactivate account'}
        message={`This will unlink ${accountGrant.identifier} and remove access to replies to this email address.`}
        handlePressOutside={deactivateModal.handleClose}
        primaryActionText={'Deactivate'}
        handlePrimaryAction={handleSubmit}
        secondaryActionText={'Cancel'}
        handleSecondaryAction={deactivateModal.handleClose}
      />
    </React.Fragment>
  );
};

DeactivateAccountGrantButton.mutation = gql`
  mutation useDeactivateAccountGrantMutation($accountGrantForm: AccountGrantForm!) {
    response: deactivateAccountGrant(accountGrantForm: $accountGrantForm) {
      ${gql.errors}
      accountGrant {
        id
      }
    }
  }
`;

const AccountGrant = ({
  accountGrant,
  onDeactivate,
}: {
  accountGrant: any;
  onDeactivate: () => void;
}) => {
  return (
    <Row>
      <Group>
        <Panel.LabelText>Account ID</Panel.LabelText>
        <Space height={8} />
        <Panel.Text>
          {`${accountGrant.identifier} (${_.startCase(accountGrant.nylasGrantProvider)})`}
        </Panel.Text>
      </Group>
      <Space width={8} />
      <DeactivateAccountGrantButton accountGrant={accountGrant} onDeactivate={onDeactivate} />
    </Row>
  );
};

const navigateToNylasOauthUrl = (redirectUri: string) => {
  const clientId: string = Config.getNylasClientId();
  const nylasUrl = new URL('https://api.us.nylas.com/v3/connect/auth');
  const params = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: 'code',
    access_type: 'online',
  });

  // Redirect the user to the Nylas auth url.
  nylasUrl.search = params.toString();
  window.location.href = nylasUrl.toString();
};

const NoAccountGrant = ({redirectUri}: {redirectUri: string}) => {
  return (
    <Row>
      <Group>
        <Panel.LabelText>Connect Email Account</Panel.LabelText>
        <Space height={8} />
        <Panel.Text>
          Send emails from your existing email account by connecting your account to Supermove.
        </Panel.Text>
      </Group>
      <Space width={8} />
      <Button onPress={() => navigateToNylasOauthUrl(redirectUri)} text={'Connect Email Account'} />
    </Row>
  );
};

const NylasAccountPanel = ({
  redirectUri,
  accountGrant,
  onDeactivate,
}: {
  redirectUri: string;
  accountGrant?: any;
  onDeactivate: () => void;
}) => {
  return (
    <Panel width={Panel.WIDTH.DEFAULT}>
      <Panel.Header>
        <Panel.HeaderText>Email Account</Panel.HeaderText>
      </Panel.Header>
      <Panel.Body>
        {accountGrant ? (
          <AccountGrant accountGrant={accountGrant} onDeactivate={onDeactivate} />
        ) : (
          <NoAccountGrant redirectUri={redirectUri} />
        )}
      </Panel.Body>
    </Panel>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NylasAccountPanel.fragment = gql`
  fragment NylasAccountPanel on AccountGrant {
    id
    identifier
    nylasGrantProvider
  }
`;

export default NylasAccountPanel;
