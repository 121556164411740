// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled, Icon} from '@supermove/components';
import {useEffect, useHover, useRef, useResponsive} from '@supermove/hooks';
import {colors, fontWeight, Typography} from '@supermove/styles';

const LOADING_INDICATOR_SIZE = 14;

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
`;

interface InputVars {
  vars: {
    isLarge: boolean;
  };
}

const Input = Styled.TextInput`
  ${({vars}: InputVars) => (vars.isLarge ? Typography.Subheading : Typography.Body)}
  ${fontWeight(500)}
  height: ${({vars}: InputVars) => (vars.isLarge ? '48px' : '36px')};
  padding-left: ${({vars}: InputVars) => (vars.isLarge ? '38px' : '36px')};
  padding-right: ${({vars}: InputVars) =>
    vars.isLarge ? LOADING_INDICATOR_SIZE + 20 : LOADING_INDICATOR_SIZE + 16}px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
`;

const LoadingIndicator = Styled.Loading`
  position: absolute;
  right: ${({hasClearButton}: {hasClearButton: boolean}) => (hasClearButton ? '32' : '12')}px;
`;

const ClearButton = Styled.ButtonV2`
`;

const SEARCH_BAR_SIZE = {
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
};

const SearchIcon = ({color, isLarge}: {color: string; isLarge: boolean}) => {
  return (
    <Icon
      style={{position: 'absolute', marginLeft: isLarge ? '12px' : '13px'}}
      color={color}
      source={Icon.Search}
      size={isLarge ? 16 : 14}
    />
  );
};

const handleOnKeyPress = ({
  event,
  onEnterPress,
}: {
  event: any;
  onEnterPress: (value: any) => void;
}) => {
  if (event.key === 'Enter') {
    onEnterPress(event.target.value);
  }
};

interface ReactNativeRef {
  current: {
    _node?: {
      value: string;
    };
  } | null;
}

export interface SearchBarProps {
  placeholder?: string;
  style?: object;
  containerStyle?: object;
  iconColor?: string;
  onChangeText?: (value: any) => void;
  onEnterPress?: (value: any) => void;
  defaultValue?: string;
  isLoading?: boolean;
  valueOverride?: string;
  // intentionally not using the react type because we mutate a read-only attr here
  inputRef?: ReactNativeRef | null;
  isClearable?: boolean;
  size?: string;
  autoFocus?: boolean;
  isResponsive?: boolean;
}

const SearchBar = ({
  placeholder = 'Search',
  style = {},
  iconColor = colors.gray.tertiary,
  onChangeText = () => {},
  onEnterPress = () => {},
  defaultValue = undefined,
  isLoading = false,
  containerStyle = {},
  valueOverride = undefined,
  inputRef = null,
  isClearable = false,
  isResponsive = false,
  size = SEARCH_BAR_SIZE.MEDIUM,
  autoFocus = false,
}: SearchBarProps) => {
  const responsive = useResponsive();
  const ref = useRef<any | null>(null);
  const {ref: hoverRef, isHovered} = useHover();
  // a component can only accept a single ref, so we point
  // multiple refs to the same ref.current
  useEffect(() => {
    if (hoverRef) {
      hoverRef.current = ref.current;
    }
    if (inputRef) {
      inputRef.current = ref.current;
    }
  }, [hoverRef, inputRef]);
  // ReactNative TextInput stores the value on the node, not on current.value
  const hasClearButton = isClearable && ref.current && ref.current?._node?.value !== '';

  const isLarge = size === SEARCH_BAR_SIZE.LARGE || (isResponsive && !responsive.desktop);

  return (
    <Container style={containerStyle}>
      <SearchIcon color={iconColor} isLarge={isLarge} />
      <Input
        autoFocus={autoFocus}
        value={valueOverride}
        ref={ref}
        placeholder={placeholder}
        onChangeText={(value: any) => {
          onChangeText(value);
        }}
        onKeyPress={(event: any) => {
          handleOnKeyPress({event, onEnterPress});
        }}
        style={{...style, ...(isHovered && {borderColor: colors.hover})}}
        defaultValue={defaultValue}
        vars={{isLarge}}
      />
      {isLoading && (
        <LoadingIndicator
          size={isLarge ? LOADING_INDICATOR_SIZE + 4 : LOADING_INDICATOR_SIZE}
          color={colors.gray.secondary}
          hasClearButton={hasClearButton}
        />
      )}
      {hasClearButton && (
        <ClearButton
          onPress={() => {
            if (ref) {
              ref.current.clear();
            }
            onChangeText('');
          }}
          style={{position: 'absolute', right: isLarge ? '12px' : '10px'}}
        >
          <Icon color={colors.gray.secondary} source={Icon.XmarkLarge} size={isLarge ? 14 : 12} />
        </ClearButton>
      )}
    </Container>
  );
};

SearchBar.SIZE = SEARCH_BAR_SIZE;

// --------------------------------------------------
// Props
// --------------------------------------------------
SearchBar.propTypes = {
  placeholder: PropTypes.string,
  style: PropTypes.object,
  containerStyle: PropTypes.object,
  iconColor: PropTypes.string,
  onChangeText: PropTypes.func,
  onEnterPress: PropTypes.func,
  valueOverride: PropTypes.string,
  inputRef: PropTypes.object,
  isClearable: PropTypes.bool,
  size: PropTypes.string,
  autoFocus: PropTypes.bool,
};

SearchBar.defaultProps = {
  placeholder: 'Search',
  style: {},
  containerStyle: {},
  iconColor: colors.gray.tertiary,
  onChangeText: () => {},
  onEnterPress: () => {},
  valueOverride: undefined,
  inputRef: null,
  isClearable: false,
  size: SEARCH_BAR_SIZE.MEDIUM,
  autoFocus: false,
};

export default SearchBar;
