// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UpdateTimesheetPayrollEntryForm from '@shared/modules/Timesheet/forms/UpdateTimesheetPayrollEntryForm';

const useUpdateTimesheetPayrollEntryMutation = ({
  updateTimesheetPayrollEntryForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      updateTimesheetPayrollEntryForm: UpdateTimesheetPayrollEntryForm.toForm(
        updateTimesheetPayrollEntryForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateTimesheetPayrollEntryMutation.mutation,
    variables: {
      updateTimesheetPayrollEntryForm: UpdateTimesheetPayrollEntryForm.toMutation(
        form.values.updateTimesheetPayrollEntryForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateTimesheetPayrollEntryMutation.mutation = gql`
  mutation useUpdateTimesheetPayrollEntryMutation($updateTimesheetPayrollEntryForm: UpdateTimesheetPayrollEntryForm!) {
    response: updateTimesheetPayrollEntry(updateTimesheetPayrollEntryForm: $updateTimesheetPayrollEntryForm) {
      ${gql.errors}
      timesheetPayrollEntry {
        id
      }
    }
  }
`;

export default useUpdateTimesheetPayrollEntryMutation;
