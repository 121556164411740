const PACK = 'PACK';
const LOAD = 'LOAD';
const DELIVERY = 'DELIVERY';

const PROJECT_DATE_KIND_TO_JOB_UUID_MAP = {
  PACK: 'packJobUuid',
  LOAD: 'loadJobUuid',
  DELIVERY: 'deliveryJobUuid',
};

const PROJECT_DATE_KIND_TO_START_DATE_MAP = {
  PACK: 'packJobStartDate',
  LOAD: 'loadJobStartDate',
  DELIVERY: 'deliveryJobStartDate',
};

const PROJECT_DATE_KIND_TO_END_DATE_MAP = {
  PACK: 'packJobEndDate',
  LOAD: 'loadJobEndDate',
  DELIVERY: 'deliveryJobEndDate',
};

const PROJECT_DATE_KIND_TO_SCHEDULED_DATE_MAP = {
  PACK: 'packJobScheduledDate',
  LOAD: 'loadJobScheduledDate',
  DELIVERY: 'deliveryJobScheduledDate',
};

const PROJECT_DATE_KIND_TO_JOB_TYPE_SETTINGS_MAP = {
  PACK: 'setsPackDates',
  LOAD: 'setsLoadDates',
  DELIVERY: 'setsDeliveryDates',
};

const PROJECT_DAKE_KIND_TO_JOB_LOCATION_SETTINGS_MAP = {
  PACK: 'packJobLocation',
  LOAD: 'loadJobLocation',
  DELIVERY: 'deliveryJobLocation',
};

export default {
  PACK,
  LOAD,
  DELIVERY,
  VALUES: [PACK, LOAD, DELIVERY],
  PROJECT_DATE_KIND_TO_JOB_UUID_MAP,
  PROJECT_DATE_KIND_TO_START_DATE_MAP,
  PROJECT_DATE_KIND_TO_END_DATE_MAP,
  PROJECT_DATE_KIND_TO_SCHEDULED_DATE_MAP,
  PROJECT_DATE_KIND_TO_JOB_TYPE_SETTINGS_MAP,
  PROJECT_DAKE_KIND_TO_JOB_LOCATION_SETTINGS_MAP,
};
