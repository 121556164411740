// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UpdateProjectTypePaymentMethodsForm, {
  UpdateProjectTypePaymentMethodsFormType,
} from '@shared/modules/PaymentMethod/forms/UpdateProjectTypePaymentMethodsForm';

const useUpdateProjectTypePaymentMethodsMutation = ({
  updateProjectTypePaymentMethodsForm,
  onSuccess,
  onError,
}: {
  updateProjectTypePaymentMethodsForm: UpdateProjectTypePaymentMethodsFormType;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      updateProjectTypePaymentMethodsForm: UpdateProjectTypePaymentMethodsForm.toForm(
        updateProjectTypePaymentMethodsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectTypePaymentMethodsMutation.mutation,
    variables: {
      updateProjectTypePaymentMethodsForm: UpdateProjectTypePaymentMethodsForm.toMutation(
        form.values.updateProjectTypePaymentMethodsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectTypePaymentMethodsMutation.mutation = gql`
  mutation useUpdateProjectTypePaymentMethodsMutation($updateProjectTypePaymentMethodsForm: UpdateProjectTypePaymentMethodsForm!) {
    response: updateProjectTypePaymentMethods(updateProjectTypePaymentMethodsForm: $updateProjectTypePaymentMethodsForm) {
      ${gql.errors}
      organization {
        id
      }
    }
  }
`;

export default useUpdateProjectTypePaymentMethodsMutation;
