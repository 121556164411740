// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const UnassignTruckFromJobMutation = ({jobTruckId, onSuccess, onError, children}) => {
  return (
    <Mutation
      variables={{
        jobTruckId,
      }}
      mutation={UnassignTruckFromJobMutation.mutation}
      onCompleted={({response: {jobTruck, errors}}) => {
        if (!errors) {
          onSuccess(jobTruck);
        } else if (onError) {
          onError(errors);
        }
      }}
      refetchQueries={['PrepareJobPage']}
      awaitRefetchQueries
    >
      {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
    </Mutation>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UnassignTruckFromJobMutation.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

UnassignTruckFromJobMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UnassignTruckFromJobMutation.mutation = gql`
  mutation UnassignTruckFromJobMutation(
    $jobTruckId: Int!,
  ) {
    response: unassignTruckFromJob(
      jobTruckId: $jobTruckId,
    ) {
      ${gql.errors}
      jobTruck {
        truckId
        jobId
      }
    }
  }
`;

export default UnassignTruckFromJobMutation;
