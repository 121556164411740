// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, usePagination, useNavigationDOM} from '@supermove/hooks';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import ShipmentStatus from '@shared/modules/Dispatch/enums/ShipmentStatus';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import AssignShipmentsToTripSidebar from 'modules/Dispatch/LongDistance/components/AssignShipmentsToTripSidebar';
import ShipmentsTable from 'modules/Dispatch/Shipment/components/ShipmentsTable';

const PaginationFooter = ({paginationMetadata, params, navigator, setSelectedShipments}) => {
  const pagination = usePagination({
    currentPage: _.toNumber(params.page),
    paginationMetadata,
    onChangePage: (page) => {
      setSelectedShipments({});
      navigator.push(`/dispatch/long-distance/shipments/${params.subTab}?page=${page}`);
    },
  });

  return <PaginationBar pagination={pagination} />;
};

const LongDistanceDispatchShipmentsTable = ({
  refetchTabData,
  createTripWithShipmentsModal,
  setSelectedShipments,
  selectedShipments,
  assignShipmentsToTripSidebar,
}) => {
  const {navigator, params} = useNavigationDOM();
  const {
    data,
    loading,
    refetch: refetchTableData,
  } = useQuery(LongDistanceDispatchShipmentsTable.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      statuses:
        params.subTab === ShipmentStatus.ACTIVE.toLowerCase()
          ? [ShipmentStatus.UNPLANNED, ShipmentStatus.PLANNED, ShipmentStatus.IN_TRANSIT]
          : [params.subTab.toUpperCase()],
      originCity: params.originCity,
      originState: params.originState,
      destinationCity: params.destinationCity,
      destinationState: params.destinationState,
      fromPackDate: params.fromPackDate,
      toPackDate: params.toPackDate,
      fromLoadDate: params.fromLoadDate,
      toLoadDate: params.toLoadDate,
      fromDeliveryDate: params.fromDeliveryDate,
      toDeliveryDate: params.toDeliveryDate,
      searchQuery: params.query,
      pagination: {
        page: params.page,
        resultsPerPage: 50,
      },
      isLongDistance: true,
    },
  });

  return (
    <React.Fragment>
      <Loading loading={!data} as={PageLoadingIndicator}>
        {() => (
          <ScrollView horizontal contentContainerStyle={{flex: 1}}>
            <ShipmentsTable
              shipments={data.paginatedList.shipments}
              refetchTabData={refetchTabData}
              refetchTableData={refetchTableData}
              createTripWithShipmentsModal={createTripWithShipmentsModal}
              setSelectedShipments={setSelectedShipments}
              selectedShipments={selectedShipments}
              loading={loading}
            />
          </ScrollView>
        )}
      </Loading>
      <Space height={16} />
      {data && (
        <PaginationFooter
          paginationMetadata={data.paginatedList.paginationMetadata}
          params={params}
          navigator={navigator}
          setSelectedShipments={setSelectedShipments}
        />
      )}
      <Space height={32} />
      <AssignShipmentsToTripSidebar
        assignShipmentsToTripSidebar={assignShipmentsToTripSidebar}
        shipmentIds={_.keys(selectedShipments)}
        // Setting key this way ensures previously selected trip does not linger
        key={assignShipmentsToTripSidebar.isOpen}
        onSuccess={() => {
          assignShipmentsToTripSidebar.handleClose();
          refetchTabData();
          refetchTableData();
          setSelectedShipments({});
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
LongDistanceDispatchShipmentsTable.query = gql`
  ${ShipmentsTable.fragment}
  ${usePagination.fragment}

  query LongDistanceDispatchShipmentsTable(
    $statuses: [String]!
    $pagination: PaginationInput!
    $isLongDistance: Boolean!
    $originCity: String
    $originState: String
    $destinationCity: String
    $destinationState: String
    $fromPackDate: String
    $toPackDate: String
    $fromLoadDate: String
    $toLoadDate: String
    $fromDeliveryDate: String
    $toDeliveryDate: String
    $searchQuery: String
  ) {
    paginatedList: filteredShipmentsPaginatedList(
      statuses: $statuses
      pagination: $pagination
      isLongDistance: $isLongDistance
      startCity: $originCity
      startState: $originState
      endCity: $destinationCity
      endState: $destinationState
      fromPackDate: $fromPackDate
      toPackDate: $toPackDate
      fromLoadDate: $fromLoadDate
      toLoadDate: $toLoadDate
      fromDeliveryDate: $fromDeliveryDate
      toDeliveryDate: $toDeliveryDate
      searchQuery: $searchQuery
    ) {
      shipments: results {
        id
        ...ShipmentsTable
      }
      paginationMetadata {
        ...usePagination
      }
    }
  }
`;

export default LongDistanceDispatchShipmentsTable;
