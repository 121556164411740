// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useResponsive, useState} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {List} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import ProjectStatus from '@shared/modules/Project/enums/ProjectStatus';
import useUpdateProjectBookingStatusMutation from '@shared/modules/Project/hooks/useUpdateProjectBookingStatusMutation';
import ManuallyCompleteProjectConfirmationForm from '@shared/modules/Proposal/forms/ManuallyCompleteProjectConfirmationForm';
import useManuallyCompleteProjectConfirmationMutation from '@shared/modules/Proposal/hooks/useManuallyCompleteProjectConfirmationMutation';
import ProjectSalesLockedModal from 'modules/Project/V2/Show/Blocks/components/ProjectSalesLockedModal';

const getIsSendQuoteVisible = ({project}) => {
  return !project.isCancelled && project.projectType.isEnabledConfirmationStepsForQuote;
};

const getIsSendConfirmationVisible = ({project}) => {
  return !project.isCancelled && project.projectType.isEnabledConfirmationStepsForConfirmation;
};

const getIsSkipConfirmationVisible = ({project}) => {
  return [ProjectStatus.LEAD, ProjectStatus.HOLD].includes(project.status);
};

const getIsDepositReceivedVisible = ({project}) => {
  return project.organization.features.isEnabledSalesStatusDepositReceived;
};

const MissingEmailModal = ({proposalRequiresCustomerEmailModal, project, urlFilters}) => {
  return (
    <ActionModal
      title={'Missing email'}
      message={`Please enter the customer's email for ${Project.getName(
        project,
      )} before sending this proposal.`}
      icon={Icon.Envelope}
      key={proposalRequiresCustomerEmailModal.key}
      isOpen={proposalRequiresCustomerEmailModal.isOpen}
      handlePrimaryAction={() =>
        urlFilters.handleUpdate({section: 'Move Details', block: ProjectBlockKind.CLIENTS})
      }
      handleSecondaryAction={proposalRequiresCustomerEmailModal.handleClose}
      primaryActionText={'Add Email'}
      secondaryActionText={'Close'}
    />
  );
};

const SkipConfirmationModal = ({
  skipProjectConfirmationModal,
  project,
  manuallyCompleteProjectConfirmationMutation,
}) => {
  return (
    <CautionModal
      title={'Skip confirmation?'}
      message={`This will skip the confirmation for ${Project.getName(
        project,
      )}. This cannot be undone.`}
      key={skipProjectConfirmationModal.key}
      isOpen={skipProjectConfirmationModal.isOpen}
      handlePrimaryAction={manuallyCompleteProjectConfirmationMutation.handleSubmit}
      isSubmitting={manuallyCompleteProjectConfirmationMutation.submitting}
      handleSecondaryAction={skipProjectConfirmationModal.handleClose}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
    />
  );
};

const DepositReceivedModal = ({
  projectDepositReceivedModal,
  updateProjectBookingStatusMutation,
}) => {
  return (
    <ActionModal
      title={'Deposit received?'}
      message={
        'By marking the deposit as received, you are acknowledging that the payment has been made.'
      }
      icon={Icon.DollarSign}
      key={projectDepositReceivedModal.key}
      isOpen={projectDepositReceivedModal.isOpen}
      handlePrimaryAction={updateProjectBookingStatusMutation.handleSubmit}
      isSubmitting={updateProjectBookingStatusMutation.submitting}
      handleSecondaryAction={projectDepositReceivedModal.handleClose}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
    />
  );
};

const ProjectProposalBlockActionsButton = ({
  project,
  proposalRequiresCustomerEmailModal,
  setSalesLockedModalHandleConfirm,
  setIsBlocked,
  proposalProjectSalesLockedModal,
  skipProjectConfirmationModal,
  projectDepositReceivedModal,
}) => {
  const isSalesLocked = project.isSalesLocked && !project.isTest;
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const navigateToSendQuote = () => navigator.push(`/projects/${project.uuid}/quote`);
  const navigateToSendConfirmation = () => navigator.push(`/projects/${project.uuid}/confirmation`);

  return (
    <DropdownButton
      isSmall={responsive.desktop}
      iconLeft={responsive.desktop ? Icon.Envelope : null}
      text={`Send${responsive.desktop ? ' Proposal' : ''}`}
      ButtonComponent={responsive.desktop ? Button : TertiaryButton}
      menuWidth={200}
      menuPosition={DropdownButton.MENU_POSITION.RIGHT}
      isResponsive
      actions={[
        ...List.insertIf(getIsSendQuoteVisible({project}), {
          text: 'Send quote',
          onPress: () => {
            if (!project.canSendCustomerEmail) {
              return proposalRequiresCustomerEmailModal.handleOpen();
            }
            if (project.organization.features.isEnabledSalesLockedQuotes && isSalesLocked) {
              setSalesLockedModalHandleConfirm(() => navigateToSendQuote);
              setIsBlocked(project.organization.features.isEnabledBlockSendQuotes);
              return proposalProjectSalesLockedModal.handleOpen();
            }
            navigateToSendQuote();
          },
        }),
        ...List.insertIf(getIsSendConfirmationVisible({project}), {
          text: 'Send confirmation',
          onPress: () => {
            if (!project.canSendCustomerEmail) {
              return proposalRequiresCustomerEmailModal.handleOpen();
            }
            if (isSalesLocked) {
              setSalesLockedModalHandleConfirm(() => navigateToSendConfirmation);
              setIsBlocked(project.organization.features.isEnabledBlockSendConfirmations);
              return proposalProjectSalesLockedModal.handleOpen();
            }
            navigateToSendConfirmation();
          },
        }),
        ...List.insertIf(getIsSkipConfirmationVisible({project}), {
          text: 'Skip confirmation',
          onPress: () => {
            if (isSalesLocked) {
              setSalesLockedModalHandleConfirm(() => () => {
                proposalProjectSalesLockedModal.handleClose();
                skipProjectConfirmationModal.handleOpen();
              });
              setIsBlocked(project.organization.features.isEnabledBlockSendConfirmations);
              return proposalProjectSalesLockedModal.handleOpen();
            }
            skipProjectConfirmationModal.handleOpen();
          },
        }),
        ...List.insertIf(getIsDepositReceivedVisible({project}), {
          text: 'Deposit received',
          onPress: () => {
            if (isSalesLocked) {
              setSalesLockedModalHandleConfirm(() => () => {
                proposalProjectSalesLockedModal.handleClose();
                projectDepositReceivedModal.handleOpen();
              });
              setIsBlocked(project.organization.features.isEnabledBlockDepositReceived);
              return proposalProjectSalesLockedModal.handleOpen();
            }
            projectDepositReceivedModal.handleOpen();
          },
        }),
      ]}
    />
  );
};

const ProjectProposalBlockActions = ({project, refetch, urlFilters}) => {
  const [salesLockedModalHandleConfirm, setSalesLockedModalHandleConfirm] = useState(
    () => () => {},
  );
  const [isBlocked, setIsBlocked] = useState(false);
  const proposalRequiresCustomerEmailModal = useModal({
    name: 'Proposal Requires Customer Email Modal',
  });
  const proposalProjectSalesLockedModal = useModal({name: 'Proposal Project Sales Locked Modal'});
  const skipProjectConfirmationModal = useModal({name: 'Skip Project Confirmation Modal'});
  const projectDepositReceivedModal = useModal({name: 'Project Deposit Received Modal'});
  const manuallyCompleteProjectConfirmationForm =
    ManuallyCompleteProjectConfirmationForm.edit(project);
  const manuallyCompleteProjectConfirmationMutation =
    useManuallyCompleteProjectConfirmationMutation({
      manuallyCompleteProjectConfirmationForm,
      onSuccess: () => {
        refetch();
        skipProjectConfirmationModal.handleClose();
      },
      onError: (errors) => console.log({errors}),
    });
  const updateProjectBookingStatusMutation = useUpdateProjectBookingStatusMutation({
    projectId: project.id,
    bookingStatus: 'DEPOSIT_RECEIVED',
    onSuccess: () => {
      refetch();
      projectDepositReceivedModal.handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <React.Fragment>
      <ProjectProposalBlockActionsButton
        project={project}
        proposalRequiresCustomerEmailModal={proposalRequiresCustomerEmailModal}
        setSalesLockedModalHandleConfirm={setSalesLockedModalHandleConfirm}
        setIsBlocked={setIsBlocked}
        proposalProjectSalesLockedModal={proposalProjectSalesLockedModal}
        skipProjectConfirmationModal={skipProjectConfirmationModal}
        projectDepositReceivedModal={projectDepositReceivedModal}
      />
      <MissingEmailModal
        proposalRequiresCustomerEmailModal={proposalRequiresCustomerEmailModal}
        project={project}
        urlFilters={urlFilters}
      />
      <ProjectSalesLockedModal
        isOpen={proposalProjectSalesLockedModal.isOpen}
        projectUuid={project.uuid}
        handleClose={proposalProjectSalesLockedModal.handleClose}
        handleConfirm={salesLockedModalHandleConfirm}
        isBlocked={isBlocked}
      />
      <SkipConfirmationModal
        skipProjectConfirmationModal={skipProjectConfirmationModal}
        project={project}
        manuallyCompleteProjectConfirmationMutation={manuallyCompleteProjectConfirmationMutation}
      />
      <DepositReceivedModal
        projectDepositReceivedModal={projectDepositReceivedModal}
        updateProjectBookingStatusMutation={updateProjectBookingStatusMutation}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectProposalBlockActions.fragment = gql`
  ${ManuallyCompleteProjectConfirmationForm.edit.fragment}
  ${Project.getName.fragment}
  fragment ProjectProposalBlockActions on Project {
    id
    uuid
    isCancelled
    isTest
    status
    canSendCustomerEmail
    isSalesLocked
    projectType {
      id
      isEnabledConfirmationStepsForConfirmation
      isEnabledConfirmationStepsForQuote
    }
    organization {
      id
      features {
        isEnabledSalesLockedQuotes: isEnabled(feature: "SALES_LOCKED_QUOTES")
        isEnabledSalesStatusDepositReceived: isEnabled(feature: "SALES_STATUS_DEPOSIT_RECEIVED")
        isEnabledBlockSendConfirmations: isEnabled(feature: "BLOCK_SEND_CONFIRMATIONS")
        isEnabledBlockSendQuotes: isEnabled(feature: "BLOCK_SEND_QUOTES")
        isEnabledBlockDepositReceived: isEnabled(feature: "BLOCK_DEPOSIT_RECEIEVED")
      }
    }
    ...ManuallyCompleteProjectConfirmationForm_edit
    ...Project_getName
  }
`;

export default ProjectProposalBlockActions;
