// Libraries
import React from 'react';

// Supermove
import {RichTextInput, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Field from 'modules/App/components/Field';

const Container = Styled.View`
`;

const Label = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const EmailContentSection = ({field, form}) => {
  const responsive = useResponsive();

  return (
    <Container>
      <Label>Subject</Label>
      <Space height={2} />
      <Field
        {...form}
        name={`${field}.subject`}
        input={{
          placeholder: 'Enter subject',
          style: {
            width: '100%',
          },
        }}
      />
      <Space height={12} />
      <Field
        {...form}
        name={`${field}.body`}
        component={RichTextInput}
        input={{
          multiline: true,
          placeholder: 'Enter body',
          setFieldValue: form.setFieldValue,
          style: {
            width: '100%',
            height: 360,
            paddingVertical: 9,
          },
        }}
        style={{
          height: responsive.mobile ? 390 : 366,
        }}
      />
    </Container>
  );
};

export default EmailContentSection;
