const _new = ({organizationId}: any) => ({
  fileId: null,
  organizationId,
});

const toForm = ({fileId, organizationId}: any) => ({
  fileId,
  organizationId,
});

const toMutation = ({fileId, organizationId}: any) => ({
  fileId,
  organizationId,
});

const ImportOrganizationProjectsForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ImportOrganizationProjectsForm;
