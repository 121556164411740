// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, TimeInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import DateField from '@shared/design/components/Field/DateField';
import FieldInput from '@shared/design/components/Field/FieldInput';
import TaskForm from '@shared/modules/TaskManagement/forms/TaskForm';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';
import TaskDrawerProjectsSearch from 'modules/TaskManagement/Tasks/components/TaskDrawerProjectsSearch';

const Container = Styled.View`
`;

const FieldRow = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex-direction: row;
`;

const FieldContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const handleTaskTemplate = ({option, form, field}) => {
  const taskTemplate = _.get(option, 'taskTemplate');
  if (taskTemplate) {
    const {taskName, details, dueAtDate, dueAtTime} = taskTemplate;
    form.setFieldValue(`${field}.name`, taskName);
    form.setFieldValue(`${field}.details`, details);
    form.setFieldValue(`${field}.dueAtDate`, Datetime.toFormDate(dueAtDate));
    form.setFieldValue(
      `${field}.dueAtTime`,
      dueAtDate ? dueAtTime || Datetime.currentTime : dueAtTime,
    );

    if (taskTemplate.assignedToRole) {
      const formPropertyForUserId = TaskForm.getFormPropertyForUserIdByAssignedToRole(
        taskTemplate.assignedToRole,
      );
      const assignedToUserId = _.get(form.values, `${field}.${formPropertyForUserId}`, '');
      form.setFieldValue(`${field}.assignedToUserId`, _.toNumber(assignedToUserId));
      form.setFieldValue(`${field}.assignedToTeamId`, null);
      form.setFieldValue(`${field}.assigneeDropdownValue`, `USER-${assignedToUserId}`);
    } else if (taskTemplate.assignedToUserId) {
      form.setFieldValue(`${field}.assignedToUserId`, _.toNumber(taskTemplate.assignedToUserId));
      form.setFieldValue(`${field}.assignedToTeamId`, null);
      form.setFieldValue(`${field}.assigneeDropdownValue`, `USER-${taskTemplate.assignedToUserId}`);
    } else if (taskTemplate.assignedToTeamId) {
      form.setFieldValue(`${field}.assignedToUserId`, null);
      form.setFieldValue(`${field}.assignedToTeamId`, _.toNumber(taskTemplate.assignedToTeamId));
      form.setFieldValue(`${field}.assigneeDropdownValue`, `TEAM-${taskTemplate.assignedToTeamId}`);
    }
  }
};

const getTaskTemplateOptions = ({organization}) => {
  return organization.officeTaskTemplates.map((taskTemplate) => ({
    value: taskTemplate.id,
    label: taskTemplate.name,
    taskTemplate,
  }));
};

const TemplateField = ({form, field, index, organization, isDisabled}) => {
  return (
    <FieldInput
      {...form}
      index={index}
      label={'Task Template (optional)'}
      name={`${field}.taskTemplateId`}
      component={DropdownInput}
      isResponsive
      input={{
        isDisabled,
        placeholder: 'Select template',
        options: getTaskTemplateOptions({organization}),
        setFieldValue: form.setFieldValue,
        onChangeValue: (value, option) => handleTaskTemplate({option, form, field}),
        style: {flex: 1},
        isClearable: true,
      }}
    />
  );
};

const NameField = ({form, field, index, isDisabled}) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={index}
      label={'Task Name'}
      name={`${field}.name`}
      isRequired
      isResponsive
      input={{
        disabled: isDisabled,
        placeholder: 'Name of task',
      }}
    />
  );
};

const DueDateAndTimeFields = ({form, field, index, isDisabled}) => {
  const dateField = `${field}.dueAtDate`;
  const timeField = `${field}.dueAtTime`;
  const responsive = useResponsive();

  return (
    <FieldRow index={index}>
      <DateField
        disabled={isDisabled}
        form={form}
        field={dateField}
        label={'Due Date'}
        isFullWidth
        onChangeDate={(date) => {
          const time = _.get(form.values, timeField);
          if (date && !time) {
            form.setFieldValue(timeField, '11:59 PM');
          }
        }}
      />
      <Space width={4} />
      <FieldContainer index={0} style={{flex: 1}}>
        <Icon
          source={Icon.Clock}
          size={14}
          color={colors.gray.tertiary}
          style={{
            position: 'absolute',
            left: 10,
            top: responsive.desktop ? 34 : 48,
            zIndex: 101,
          }}
        />
        <FieldInput.Memoized
          {...form}
          component={TimeInput}
          label={' '}
          name={timeField}
          isResponsive
          input={{
            disabled: isDisabled,
            placeholder: '00:00 AM/PM',
            setFieldValue: form.setFieldValue,
            component: FieldInput.TextInput,
            style: {paddingLeft: 30},
          }}
          style={{flex: 1}}
        />
      </FieldContainer>
    </FieldRow>
  );
};

const AssigneeField = ({form, field, index, organization, isDisabled, menuPlacement}) => {
  const {isEnabledShowSalespersonsFromAllOrganizations} = organization.features;
  return (
    <FieldInput.Memoized
      {...form}
      index={index}
      component={DropdownInput}
      label={'Assignee'}
      name={`${field}.assigneeDropdownValue`}
      isResponsive
      input={{
        isDisabled,
        placeholder: 'Select assignee',
        options: Organization.getTaskAssigneeDropdownOptions(organization),
        setFieldValue: form.setFieldValue,
        showDescriptionInOption: isEnabledShowSalespersonsFromAllOrganizations,
        isPortaled: true,
        menuPlacement,
        onChangeValue: (value) => {
          const [kind, id] = value.split('-');
          if (kind === 'USER') {
            form.setFieldValue(`${field}.assignedToUserId`, _.toNumber(id));
            form.setFieldValue(`${field}.assignedToTeamId`, null);
          }
          if (kind === 'TEAM') {
            form.setFieldValue(`${field}.assignedToUserId`, null);
            form.setFieldValue(`${field}.assignedToTeamId`, _.toNumber(id));
          }
        },
        style: {
          flex: 1,
        },
      }}
      style={{flex: 1}}
    />
  );
};

const DetailsField = ({form, field, index, isDisabled}) => {
  return (
    <FieldContainer index={index}>
      <ResponsiveTextInput.Memoized
        label={'Details'}
        form={form}
        field={`${field}.details`}
        minHeight={76}
        isResponsive
        input={{
          disabled: isDisabled,
          style: {
            paddingLeft: 12,
            paddingRight: 12,
          },
          placeholder: 'Enter any details',
        }}
      />
    </FieldContainer>
  );
};

const TaskDrawerFields = ({form, field, organization, isDisabled}) => {
  const responsive = useResponsive();
  return (
    <Container key={isDisabled}>
      <TemplateField
        form={form}
        field={field}
        index={0}
        organization={organization}
        isDisabled={isDisabled}
      />
      <Space height={12} />
      <NameField form={form} field={field} index={1} isDisabled={isDisabled} />
      <Space height={12} />
      <TaskDrawerProjectsSearch form={form} field={field} index={2} isDisabled={isDisabled} />
      <Space height={12} />
      <DueDateAndTimeFields form={form} field={field} index={3} isDisabled={isDisabled} />
      <Space height={12} />
      <AssigneeField
        form={form}
        field={field}
        index={4}
        organization={organization}
        isDisabled={isDisabled}
        menuPlacement={responsive.desktop ? 'bottom' : 'top'}
      />
      <Space height={12} />
      <DetailsField form={form} field={field} index={5} isDisabled={isDisabled} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TaskDrawerFields.fragment = gql`
  ${Organization.getTaskAssigneeDropdownOptions.fragment}

  fragment TaskDrawerFields on Organization {
    id
    officeTaskTemplates {
      id
      name
      taskName
      assignedToRole
      assignedToUserId
      assignedToTeamId
      details
      dueAtDate
      dueAtTime
    }
    features {
      isEnabledShowSalespersonsFromAllOrganizations: isEnabled(
        feature: "SHOW_SALESPERSONS_FROM_ALL_ORGANIZATIONS"
      )
    }
    ...Organization_getTaskAssigneeDropdownOptions
  }
`;

export default TaskDrawerFields;
