// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Hover, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Truck} from '@supermove/models';
import {colors} from '@supermove/styles';

// Components
import UnassignTruckFromJobMutation from './UnassignTruckFromJobMutation';

const Touchable = Styled.Touchable`
`;

const Container = Styled.View`
  align-self: flex-start;
  flex-direction: row;
  align-items: center;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  margin-bottom: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  border-radius: 3px;
`;

const Text = Styled.H7`
`;

const Content = ({isHovered, isUnassignable, shouldHideIcon, jobTruck}) => (
  <Container
    style={{
      backgroundColor: isUnassignable && isHovered ? colors.blue.accent : 'none',
    }}
  >
    {!shouldHideIcon && (
      <Icon
        color={colors.gray.primary}
        size={Icon.Sizes.Large}
        source={Icon.Truck}
        style={{marginRight: 5}}
      />
    )}
    <Text>{Truck.getFullName(jobTruck.truck)}</Text>
  </Container>
);

const JobTruckItem = ({isUnassignable, jobTruck, refetch}) =>
  isUnassignable ? (
    <UnassignTruckFromJobMutation jobTruckId={jobTruck.id} onSuccess={refetch}>
      {({loading, handleSubmit}) => (
        <Touchable
          disabled={!isUnassignable || loading}
          onPress={handleSubmit}
          style={{
            alignSelf: 'flex-start',
          }}
        >
          <Hover>
            {({isHovered}) => (
              <Content isHovered={isHovered} isUnassignable={isUnassignable} jobTruck={jobTruck} />
            )}
          </Hover>
        </Touchable>
      )}
    </UnassignTruckFromJobMutation>
  ) : (
    <Content shouldHideIcon jobTruck={jobTruck} />
  );

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobTruckItem.propTypes = {
  isUnassignable: PropTypes.bool,
  jobTruck: PropTypes.object.isRequired,
  refetch: PropTypes.func,
};

JobTruckItem.defaultProps = {
  isUnassignable: false,
  refetch: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTruckItem.fragment = gql`
  fragment JobTruckItem on JobTruck {
    id
    truckId
    jobId
    truck {
      id
      name
      size
    }
  }
`;

export default JobTruckItem;
