// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {TaskTemplate} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import TaskActionKind from '@shared/modules/TaskManagement/enums/TaskActionKind';
import TaskTemplateDueInUnitType from '@shared/modules/TaskManagement/enums/TaskTemplateDueInUnitType';

const _new = ({organizationId, playbookTemplateId, index}: any) => ({
  taskTemplateId: null,
  name: '',
  taskName: '',
  details: '',
  dueInUnitType: TaskTemplateDueInUnitType.DAYS,
  dueInUnits: '',
  dueAtTime: '',
  assignedToRole: '',
  actionKind: null,

  assignedToUserId: null,
  assignedToTeamId: null,
  organizationId,
  playbookTemplateId,
  index,

  // Private
  assignedToValue: '',
});

const _delete = ({taskTemplateId}: any) => ({
  ..._new({}),
  taskTemplateId,
});

const edit = withFragment(
  (taskTemplate) => ({
    taskTemplateId: (taskTemplate as any).id,
    name: (taskTemplate as any).name,
    taskName: (taskTemplate as any).taskName,
    details: (taskTemplate as any).details,
    dueInUnitType: (taskTemplate as any).dueInUnitType,
    dueInUnits: (taskTemplate as any).dueInUnits,
    dueAtTime: (taskTemplate as any).dueAtTime,
    assignedToRole: (taskTemplate as any).assignedToRole,
    index: (taskTemplate as any).index,
    actionKind: (taskTemplate as any).actionKind,
    assignedToUserId: (taskTemplate as any).assignedToUserId,
    assignedToTeamId: (taskTemplate as any).assignedToTeamId,
    organizationId: (taskTemplate as any).organizationId,
    playbookTemplateId: (taskTemplate as any).playbookTemplateId,
    assignedToValue: TaskTemplate.getAssigneeDropdownValue(taskTemplate),
  }),
  gql`
    ${TaskTemplate.getAssigneeDropdownValue.fragment}
    fragment TaskTemplateForm_edit on TaskTemplate {
      id
      name
      taskName
      details
      dueInUnitType
      dueInUnits
      dueAtTime
      index
      assignedToRole
      actionKind
      assignedToUserId
      organizationId
      playbookTemplateId
      ...TaskTemplate_getAssigneeDropdownValue
    }
  `,
);

const toForm = ({
  taskTemplateId,
  name,
  taskName,
  details,
  dueInUnitType,
  dueInUnits,
  dueAtTime,
  assignedToRole,
  assignedToUserId,
  actionKind,
  assignedToTeamId,
  organizationId,
  playbookTemplateId,
  assignedToValue,
  index,
}: any) => ({
  taskTemplateId,
  name,
  taskName,
  details,
  dueInUnitType,
  actionKind: actionKind || TaskActionKind.NONE,
  dueInUnits: _.toString(dueInUnits),
  dueAtTime,
  assignedToRole,
  index,
  // On the UI the assignee dropdown for the task template can recognize
  // an id or a role appropriately and display accordingly.
  assignedToUserId,
  assignedToTeamId,
  organizationId,
  playbookTemplateId,

  // Private
  assignedToValue,
});

const toMutation = ({
  taskTemplateId,
  name,
  taskName,
  details,
  dueInUnitType,
  dueInUnits,
  actionKind,
  index,
  dueAtTime,
  assignedToRole,
  assignedToUserId,
  assignedToTeamId,
  organizationId,
  playbookTemplateId,
}: any) => {
  return {
    taskTemplateId,
    name,
    taskName,
    details,
    index,
    actionKind: actionKind === TaskActionKind.NONE ? null : actionKind,
    dueInUnitType,
    dueInUnits: dueInUnits ? _.toNumber(dueInUnits) : null,
    dueAtTime,
    assignedToRole,
    // Send the value if it's an actual id, clear it otherwise.
    assignedToUserId: _.toNumber(assignedToUserId) || null,
    assignedToTeamId: _.toNumber(assignedToTeamId) || null,
    organizationId,
    playbookTemplateId,
  };
};

const TaskForm = {
  new: _new,
  delete: _delete,
  edit,
  toForm,
  toMutation,
};

export default TaskForm;
