// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {JobUser} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import JobUserAvatar from './JobUserAvatar';
import PlaceholderJobUserAvatar from './PlaceholderJobUserAvatar';

const Container = Styled.View`
  flex-direction: row;
  margin-left: ${(props) => (props.hasMargin ? 5 : 0)}px;
`;

const EmptyContainer = Styled.View`
  align-items: center;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const getPlaceholderAvatars = ({job}) => {
  const jobUsersCount = job.activeJobUsers.length;
  if (jobUsersCount >= job.crewSize) {
    return null;
  } else {
    return (
      <Container hasMargin={jobUsersCount > 0}>
        {_.range(job.crewSize - jobUsersCount).map((index) => (
          <PlaceholderJobUserAvatar
            key={index}
            isLast={index === job.crewSize - jobUsersCount - 1}
          />
        ))}
      </Container>
    );
  }
};

const JobUsersList = ({isCompact, header, job, style, onPressJobUser}) => (
  <FlatList
    horizontal
    data={JobUser.getJobUsersForJob({job, jobUsers: job.activeJobUsers})}
    keyExtractor={(jobUser) => String(jobUser.id)}
    style={style}
    renderItem={({item: jobUser, index}) => (
      <JobUserAvatar
        isLast={index === job.activeJobUsers.length - 1}
        jobUser={jobUser}
        onPress={onPressJobUser}
      />
    )}
    ListEmptyComponent={() =>
      isCompact && (
        <EmptyContainer>
          <EmptyMessage>No crew members</EmptyMessage>
        </EmptyContainer>
      )
    }
    ListHeaderComponent={header}
    ListFooterComponent={!isCompact && getPlaceholderAvatars({job})}
  />
);

// --------------------------------------------------
// Props
// --------------------------------------------------
JobUsersList.propTypes = {
  isCompact: PropTypes.bool,
  job: PropTypes.object.isRequired,
  style: PropTypes.object,
  onPressJobUser: PropTypes.func,
};

JobUsersList.defaultProps = {
  isCompact: false,
  style: {},
  onPressJobUser: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobUsersList.fragment = gql`
  ${JobUserAvatar.fragment}

  fragment JobUsersList on Job {
    id
    crewSize
    calendarPrimaryStatus
    activeJobUsers {
      id
      jobId
      userId
      status
      ...JobUserAvatar
    }
  }
`;

export default JobUsersList;
