// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import BetaBadge from '@shared/design/components/BetaBadge';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
`;

const PreviousScreenText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.secondary};
`;

const CurrentScreenText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.blue.accentDark};
`;

export interface BreadcrumbType {
  name: string;
  onPress?: () => void;
  isBeta?: boolean;
}

const PreviousScreen = ({breadcrumb}: {breadcrumb: BreadcrumbType}) => {
  return (
    <React.Fragment>
      <Button onPress={breadcrumb.onPress}>
        <Row>
          <PreviousScreenText numberOfLines={1}>{breadcrumb.name}</PreviousScreenText>
          {breadcrumb.isBeta && (
            <React.Fragment>
              <Space width={8} />
              <BetaBadge />
            </React.Fragment>
          )}
        </Row>
      </Button>
      <Space width={12} />
      <Icon source={Icon.ChevronRight} color={colors.gray.secondary} size={14} />
      <Space width={12} />
    </React.Fragment>
  );
};

const CurrentScreen = ({breadcrumb}: {breadcrumb: BreadcrumbType}) => {
  return (
    <React.Fragment>
      <CurrentScreenText numberOfLines={1}>{breadcrumb.name}</CurrentScreenText>
      {breadcrumb.isBeta && (
        <React.Fragment>
          <Space width={8} />
          <BetaBadge />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Breadcrumbs = ({breadcrumbs}: {breadcrumbs: BreadcrumbType[]}) => {
  return (
    <Row style={{flex: 1}}>
      {breadcrumbs.map((breadcrumb: BreadcrumbType, index: number) => {
        // If last item, then we only need the name and no onPress for the current page
        if (index === breadcrumbs.length - 1) {
          return <CurrentScreen key={index} breadcrumb={breadcrumb} />;
        }
        return <PreviousScreen key={index} breadcrumb={breadcrumb} />;
      })}
    </Row>
  );
};

export default Breadcrumbs;
