// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {Typography} from '@supermove/styles';

const Wrapper = Styled.View`
  margin-bottom: 10px;
  z-index: ${(props) => 100 - (props as any).index};
`;

const SectionHeaderText = Styled.Text`
  ${Typography.Heading6}
  text-align: center;
`;

const DocumentV2SectionTitle = ({title, index}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Wrapper index={index}>
      <Space height={24} />
      <SectionHeaderText>{title}</SectionHeaderText>
    </Wrapper>
  );
};

export default DocumentV2SectionTitle;
