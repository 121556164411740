// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import JobTypeVariableSettings from 'modules/Organization/Settings/JobTypes/components/JobTypeVariableSettings';

const JobTypeVariableSettingsPage = () => {
  const {params} = useNavigationDOM();

  const {loading, data, refetch} = useQuery(JobTypeVariableSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobTypeUuid: params.jobTypeUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <SidebarPageV2 selected={'settings'}>
      <JobTypeVariableSettings jobType={data.jobTypeByUuid} refetch={refetch} />
    </SidebarPageV2>
  );
};

JobTypeVariableSettingsPage.query = gql`
  ${JobTypeVariableSettings.fragment}

  query JobTypeVariableSettingsPage(
    $jobTypeUuid: String!,
  ) {
    ${gql.query}
    jobTypeByUuid(jobTypeUuid: $jobTypeUuid) {
      id
      ...JobTypeVariableSettings
    }
  }
`;

export default JobTypeVariableSettingsPage;
