// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SplitButton from 'modules/App/components/SplitButton';
import JobEventItem from 'modules/Job/Show/components/JobEventItem';
import JobEventsList from 'modules/Job/Show/components/JobEventsList';
import JobRequiresCustomerEmailModal from 'modules/Job/Show/components/JobRequiresCustomerEmailModal';
import JobSkipConfirmationModal from 'modules/Job/Show/components/JobSkipConfirmationModal';
import JobTimelineV2AllJobEventsModal from 'modules/Job/Show/components/JobTimelineV2AllJobEventsModal';
import ProjectUpdateSalesStatusModal from 'modules/Job/Show/components/ProjectUpdateSalesStatusModal';
import CompleteEstimatedRangeJobModal from 'modules/Job/components/CompleteEstimatedRangeJobModal';
import JobCompleteManuallyModal from 'modules/Job/components/JobCompleteManuallyModal';
import ProjectSalesLockedModal from 'modules/Project/V2/Show/Blocks/components/ProjectSalesLockedModal';

const Container = Styled.View`
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const BlockSection = Styled.View`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  padding-horizontal: 16px;
`;

const BlockTitle = Styled.Text`
  ${Typography.Label1}
`;

const SectionSpace = Styled.View`
  margin-top: 8px;
`;

const Title = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.primary};
  margin-bottom: 8px;
`;

const Subtitle = Styled.Text`
  ${Typography.Body2}
  color: ${colors.gray.secondary};
`;

const Item = Styled.View`
  margin-vertical: 5px;
`;

const Actions = Styled.View`
  margin-vertical: 10px;
`;

const Link = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const WarningText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.orange.status};
`;

const getSummaryJobEventsText = (job) => {
  const numberOfAdditionalJobEvents = job.jobTimelineV2.jobEventsCount - 1;
  if (numberOfAdditionalJobEvents === 0) {
    return 'No more events';
  }
  return pluralize('more event', numberOfAdditionalJobEvents, true);
};

const HistoryLink = ({job}) => {
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <React.Fragment>
      <Link onPress={handleOpen}>
        <LinkText>{getSummaryJobEventsText(job)}</LinkText>
      </Link>
      <JobTimelineV2AllJobEventsModal isOpen={isOpen} jobUuid={job.uuid} onClose={handleClose} />
    </React.Fragment>
  );
};

const getSuggestedJobStepTitle = (jobStep) => {
  switch (jobStep.kind) {
    case 'QUOTE':
      return 'Prepare and send quote.';
    case 'CONFIRMATION':
      return 'Prepare and send confirmation letter.';
    case 'COMPLETE_MANUALLY':
      return 'Mark as complete.';
    default:
      return '';
  }
};

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  margin-vertical: 10px;
  margin-horizontal: 30px;
`;

const CompleteEstimatedRangeJobButton = ({job, refetch}) => {
  const completeEstimatedRangeJobModal = useModal({name: 'Complete Estimated Range Job Modal'});
  return (
    <React.Fragment>
      <Button onPress={completeEstimatedRangeJobModal.handleOpen} text={`Mark This Job Complete`} />
      <CompleteEstimatedRangeJobModal
        key={completeEstimatedRangeJobModal.key}
        isOpen={completeEstimatedRangeJobModal.isOpen}
        handleClose={completeEstimatedRangeJobModal.handleClose}
        jobUuid={job.parentJob ? job.parentJob.uuid : job.uuid}
        refetchParent={refetch}
      />
    </React.Fragment>
  );
};

const getSecondaryActions = ({
  job,
  jobStep,
  projectUpdateSalesStatusModal,
  skipConfirmationModal,
  projectSalesLockedModal,
  skipConfirmationProjectSalesLockedModal,
  depositReceivedProjectSalesLockedModal,
  requiresCustomerEmailModal,
  navigateToSendConfirmation,
}) => {
  const secondaryActions = [];
  if (job.project.organization.features.isEnabledSalesStatusDepositReceived) {
    secondaryActions.push({
      text: 'Deposit Received',
      onPress: () => {
        if (Job.getIsSalesLocked(job)) {
          depositReceivedProjectSalesLockedModal.handleOpen();
        } else {
          projectUpdateSalesStatusModal.handleOpen();
        }
      },
    });
  }

  if (jobStep.kind === 'QUOTE') {
    secondaryActions.push({
      text: 'Send Confirmation',
      onPress: () => {
        // Must be scheduled and have an email in order to send the confirmation.
        if (!job.canSendCustomerEmail) {
          requiresCustomerEmailModal.handleOpen();
        } else if (Job.getIsSalesLocked(job)) {
          projectSalesLockedModal.handleOpen();
        } else {
          navigateToSendConfirmation();
        }
      },
    });
  }

  if (!job.isConfirmationComplete) {
    secondaryActions.push({
      text: 'Skip Confirmation',
      onPress: () => {
        // Must be scheduled but does not require an email in order to send the confirmation.
        if (Job.getIsSalesLocked(job)) {
          skipConfirmationProjectSalesLockedModal.handleOpen();
        } else {
          skipConfirmationModal.handleOpen();
        }
      },
    });
  }
  return secondaryActions;
};

const SuggestedJobStepActions = ({job, jobStep, refetch}) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();

  const projectUpdateSalesStatusModal = useModal({
    name: 'Project Update Sales Status Modal',
    onClose: refetch,
  });
  const skipConfirmationModal = useModal({name: 'Skip Confirmation Modal', onClose: refetch});
  const projectSalesLockedModal = useModal({name: 'Project Sales Locked Modal', onClose: refetch});
  const sendQuoteProjectSalesLockedModal = useModal({
    name: 'Send Quote Project Sales Locked Modal',
    onClose: refetch,
  });
  const skipConfirmationProjectSalesLockedModal = useModal({
    name: 'Skip Confirmation Project Sales Locked Modal',
    onClose: refetch,
  });
  const depositReceivedProjectSalesLockedModal = useModal({
    name: 'Deposit Received Project Sales Locked Modal',
    onClose: refetch,
  });
  const requiresCustomerEmailModal = useModal({
    name: 'Requires Customer Email Modal',
    onClose: refetch,
  });
  const jobCompleteManuallyModal = useModal({name: 'Job Complete Manually Modal'});

  const navigateToSendConfirmation = () => {
    navigator.push(`/projects/${job.project.uuid}/confirmation`);
  };

  const navigateToSendQuote = () => {
    navigator.push(`/projects/${job.project.uuid}/quote`);
  };

  const secondaryActions = getSecondaryActions({
    job,
    jobStep,
    projectUpdateSalesStatusModal,
    skipConfirmationModal,
    projectSalesLockedModal,
    skipConfirmationProjectSalesLockedModal,
    depositReceivedProjectSalesLockedModal,
    requiresCustomerEmailModal,
    navigateToSendConfirmation,
  });

  switch (jobStep.kind) {
    case 'QUOTE':
      return (
        <Row {...responsive}>
          <SplitButton
            primaryText={'Send Quote'}
            secondaryActions={secondaryActions}
            onPrimaryPress={() => {
              if (!job.canSendCustomerEmail) {
                requiresCustomerEmailModal.handleOpen();
              } else if (
                job.project.organization.features.isEnabledSalesLockedQuotes &&
                Job.getIsSalesLocked(job)
              ) {
                sendQuoteProjectSalesLockedModal.handleOpen();
              } else {
                navigateToSendQuote();
              }
            }}
            style={{width: responsive.mobile ? '100%' : 200}}
          />
          <ProjectUpdateSalesStatusModal
            isOpen={projectUpdateSalesStatusModal.isOpen}
            project={job.project}
            handleClose={projectUpdateSalesStatusModal.handleClose}
          />
          <ProjectSalesLockedModal
            isOpen={sendQuoteProjectSalesLockedModal.isOpen}
            projectUuid={job.project.uuid}
            handleClose={sendQuoteProjectSalesLockedModal.handleClose}
            handleConfirm={navigateToSendQuote}
            isBlocked={job.project.organization.features.isEnabledBlockSendQuotes}
          />
          <ProjectSalesLockedModal
            isOpen={projectSalesLockedModal.isOpen}
            projectUuid={job.project.uuid}
            handleClose={projectSalesLockedModal.handleClose}
            handleConfirm={navigateToSendConfirmation}
            isBlocked={job.project.organization.features.isEnabledBlockSendConfirmations}
          />
          <ProjectSalesLockedModal
            isOpen={skipConfirmationProjectSalesLockedModal.isOpen}
            projectUuid={job.project.uuid}
            handleClose={skipConfirmationProjectSalesLockedModal.handleClose}
            handleConfirm={() => {
              skipConfirmationModal.handleOpen();
              skipConfirmationProjectSalesLockedModal.handleClose();
            }}
            isBlocked={job.project.organization.features.isEnabledBlockSendConfirmations}
          />
          <ProjectSalesLockedModal
            isOpen={depositReceivedProjectSalesLockedModal.isOpen}
            projectUuid={job.project.uuid}
            handleClose={depositReceivedProjectSalesLockedModal.handleClose}
            handleConfirm={() => {
              projectUpdateSalesStatusModal.handleOpen();
              depositReceivedProjectSalesLockedModal.handleClose();
            }}
            isBlocked={job.project.organization.features.isEnabledBlockDepositReceived}
          />
          <JobRequiresCustomerEmailModal
            isOpen={requiresCustomerEmailModal.isOpen}
            job={job}
            handleClose={requiresCustomerEmailModal.handleClose}
          />
          <JobSkipConfirmationModal
            isOpen={skipConfirmationModal.isOpen}
            job={job}
            handleClose={skipConfirmationModal.handleClose}
          />
        </Row>
      );
    case 'CONFIRMATION':
      return (
        <React.Fragment>
          <Row {...responsive}>
            <SplitButton
              icon={
                Job.getIsSalesLocked(job) ? (
                  <Icon
                    source={Icon.ExclamationTriangle}
                    color={colors.white}
                    size={Icon.Sizes.Small}
                  />
                ) : null
              }
              color={Job.getIsSalesLocked(job) ? colors.orange.status : colors.blue.interactive}
              primaryText={'Send Confirmation'}
              secondaryActions={secondaryActions}
              onPrimaryPress={() => {
                if (!job.canSendCustomerEmail) {
                  requiresCustomerEmailModal.handleOpen();
                } else if (Job.getIsSalesLocked(job)) {
                  projectSalesLockedModal.handleOpen();
                } else {
                  navigateToSendConfirmation();
                }
              }}
            />
            <ProjectUpdateSalesStatusModal
              isOpen={projectUpdateSalesStatusModal.isOpen}
              project={job.project}
              handleClose={projectUpdateSalesStatusModal.handleClose}
            />
            <ProjectSalesLockedModal
              isOpen={projectSalesLockedModal.isOpen}
              projectUuid={job.project.uuid}
              handleClose={projectSalesLockedModal.handleClose}
              handleConfirm={navigateToSendConfirmation}
              isBlocked={job.project.organization.features.isEnabledBlockSendConfirmations}
            />
            <ProjectSalesLockedModal
              isOpen={skipConfirmationProjectSalesLockedModal.isOpen}
              projectUuid={job.project.uuid}
              handleClose={skipConfirmationProjectSalesLockedModal.handleClose}
              handleConfirm={() => {
                skipConfirmationModal.handleOpen();
                skipConfirmationProjectSalesLockedModal.handleClose();
              }}
              isBlocked={job.project.organization.features.isEnabledBlockSendConfirmations}
            />
            <ProjectSalesLockedModal
              isOpen={depositReceivedProjectSalesLockedModal.isOpen}
              projectUuid={job.project.uuid}
              handleClose={depositReceivedProjectSalesLockedModal.handleClose}
              handleConfirm={() => {
                projectUpdateSalesStatusModal.handleOpen();
                depositReceivedProjectSalesLockedModal.handleClose();
              }}
              isBlocked={job.project.organization.features.isEnabledBlockDepositReceived}
            />
            <JobRequiresCustomerEmailModal
              isOpen={requiresCustomerEmailModal.isOpen}
              job={job}
              handleClose={requiresCustomerEmailModal.handleClose}
            />
            <JobSkipConfirmationModal
              isOpen={skipConfirmationModal.isOpen}
              job={job}
              handleClose={skipConfirmationModal.handleClose}
            />
          </Row>
          {Job.getIsSalesLocked(job) && <WarningText>No booking availability</WarningText>}
        </React.Fragment>
      );
    case 'COMPLETE_MANUALLY':
      return (
        <Row {...responsive}>
          {Job.getIsEstimatedRange(job) || Job.getIsChildJob(job) ? (
            <CompleteEstimatedRangeJobButton job={job} refetch={refetch} />
          ) : (
            <React.Fragment>
              <Button
                onPress={jobCompleteManuallyModal.handleOpen}
                text={`Mark This Job Complete`}
              />
              <JobCompleteManuallyModal
                job={job}
                isOpen={jobCompleteManuallyModal.isOpen}
                handleClose={jobCompleteManuallyModal.handleClose}
                onSuccess={refetch}
              />
            </React.Fragment>
          )}
        </Row>
      );
    default:
      return null;
  }
};

const SuggestedActions = ({job, refetch}) => {
  const responsive = useResponsive();
  if (Job.getIsChildJob(job) && !job.parentJob.isComplete) {
    return (
      <React.Fragment>
        <Subtitle>Mark as complete.</Subtitle>
        <Row {...responsive}>
          <CompleteEstimatedRangeJobButton job={job} refetch={refetch} />
        </Row>
      </React.Fragment>
    );
  }
  const jobStep = job.jobTimelineV2.suggestedJobStep;
  if (jobStep) {
    return (
      <React.Fragment>
        <Subtitle>{getSuggestedJobStepTitle(jobStep)}</Subtitle>
        <SuggestedJobStepActions jobStep={jobStep} job={job} refetch={refetch} />
      </React.Fragment>
    );
  }
  return <Subtitle>No Action</Subtitle>;
};

const JobTimeline = ({showEventHistory, job, refetch}) => {
  const responsive = useResponsive();
  return (
    <Container>
      <Section sectionIndex={0}>
        <Title>Suggested Action</Title>
        <SuggestedActions job={job} refetch={refetch} />
      </Section>
      <SectionSpace {...responsive} />
      <BlockSection>
        <Space height={12} />
        <Section sectionIndex={1}>
          <BlockTitle>Timeline Summary</BlockTitle>
          {_.take(job.jobTimelineV2.timelineJobEvents, 1).map((jobEvent) => (
            <Item key={jobEvent.id}>
              <JobEventItem jobEvent={jobEvent} />
            </Item>
          ))}
        </Section>
        <Section sectionIndex={2}>
          {showEventHistory && (
            <React.Fragment>
              <SectionSpace {...responsive} />
              <BlockTitle>Event History</BlockTitle>
              <JobEventsList jobEvents={job.jobTimelineV2.summaryJobEvents} />
            </React.Fragment>
          )}
          <Actions>
            <HistoryLink job={job} />
          </Actions>
        </Section>
      </BlockSection>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTimeline.fragment = gql`
  ${JobCompleteManuallyModal.fragment}
  ${JobEventItem.fragment}
  ${JobEventsList.fragment}
  ${JobRequiresCustomerEmailModal.fragment}
  ${JobSkipConfirmationModal.fragment}
  ${ProjectUpdateSalesStatusModal.fragment}
  ${Job.getIsEstimatedRange.fragment}
  ${Job.getIsChildJob.fragment}
  ${Job.getIsSalesLocked.fragment}

  fragment JobTimeline on Job {
    id
    uuid
    date
    canSendCustomerEmail
    isTest
    isProjectSalesLocked
    jobTimelineV2 {
      suggestedJobStep {
        kind
      }
      timelineJobEvents {
        id
        ...JobEventItem
      }
      summaryJobEvents {
        id
        ...JobEventsList
      }
      jobEventsCount
    }
    parentJob {
      id
      isComplete
      uuid
    }
    project {
      id
      uuid
      organization {
        id
        features {
          isEnabledSalesLockedQuotes: isEnabled(feature: "SALES_LOCKED_QUOTES")
          isEnabledSalesStatusDepositReceived: isEnabled(feature: "SALES_STATUS_DEPOSIT_RECEIVED")
          isEnabledBlockSendConfirmations: isEnabled(feature: "BLOCK_SEND_CONFIRMATIONS")
          isEnabledBlockSendQuotes: isEnabled(feature: "BLOCK_SEND_QUOTES")
          isEnabledBlockDepositReceived: isEnabled(feature: "BLOCK_DEPOSIT_RECEIEVED")
        }
      }
      ...ProjectUpdateSalesStatusModal
    }
    ...JobCompleteManuallyModal
    ...JobRequiresCustomerEmailModal
    ...JobSkipConfirmationModal
    ...Job_getIsEstimatedRange
    ...Job_getIsChildJob
    ...Job_getIsSalesLocked
  }
`;

export default JobTimeline;
