// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import JobForm from '@shared/modules/Job/forms/JobForm';
import JobMap from 'modules/Job/components/JobMap';

const MAP_MIN_HEIGHT = 400;

const MapContainer = Styled.View`
`;

const getWarehouseLocationFromForm = ({form, field}) => {
  const warehouseLocationFromForm = _.get(form.values, `${field}.warehouseLocationForm`);
  return warehouseLocationFromForm
    ? {
        latitude: warehouseLocationFromForm.latitude,
        longitude: warehouseLocationFromForm.longitude,
      }
    : null;
};

const RouteMap = ({form, field, project, mapRef}) => {
  const {isEnabledCreateStorageMultipleWarehouse} = project.owningOrganization.features;

  const warehouseLocation = !isEnabledCreateStorageMultipleWarehouse
    ? project.owningOrganization.warehouseLocation
    : getWarehouseLocationFromForm({form, field});

  const moveLocations = JobForm.getLocations(_.get(form.values, field));
  const boundLocations = warehouseLocation ? [warehouseLocation, ...moveLocations] : moveLocations;

  return (
    <MapContainer style={{minHeight: MAP_MIN_HEIGHT, borderRadius: 8, overflow: 'hidden'}}>
      <JobMap
        locations={moveLocations}
        boundLocations={boundLocations}
        warehouseLocation={warehouseLocation}
        mapRef={mapRef}
      />
    </MapContainer>
  );
};

const EditJobRouteBlock = ({form, field, project, mapRef}) => {
  return (
    <ActionPanel
      BodyComponent={RouteMap}
      bodyComponentProps={{form, field, project, mapRef}}
      title={'Route'}
      style={{width: '100%'}}
    />
  );
};

EditJobRouteBlock.Map = RouteMap;

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobRouteBlock.fragment = gql`
  fragment EditJobRouteBlock on Project {
    id
    owningOrganization {
      id
      warehouseLocation {
        latitude
        longitude
      }
      features {
        isEnabledCreateStorageMultipleWarehouse: isEnabled(
          feature: "CREATE_STORAGE_MULTIPLE_WAREHOUSE"
        )
      }
    }
  }
`;

export default EditJobRouteBlock;
