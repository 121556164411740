// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';

// Components
import FieldInput from '@shared/design/components/Field/FieldInput';
import ClaimTypeColorDropdown from 'modules/Organization/Settings/Company/components/ClaimTypeColorDropdown';

const FieldsContainer = Styled.View`
  z-index: 100;
`;

const ClaimTypeFields = ({form, field}) => {
  return (
    <FieldsContainer>
      <FieldInput
        {...form}
        index={0}
        name={`${field}.name`}
        label={'Name'}
        isRequired
        input={{
          autoFocus: true,
          placeholder: 'Enter a name',
        }}
      />
      <Space height={8} />
      <ClaimTypeColorDropdown
        form={form}
        field={`${field}.color`}
        index={1}
        label={'Color'}
        placeholder={'Select color'}
      />
      <Space height={8} />
      <FieldInput
        {...form}
        index={2}
        name={`${field}.description`}
        label={'Description'}
        input={{
          placeholder: 'Enter a description',
        }}
      />
    </FieldsContainer>
  );
};

export default ClaimTypeFields;
