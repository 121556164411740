// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState, useToast} from '@supermove/hooks';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ClaimForm from '@shared/modules/Claim/forms/ClaimForm';
import useUpdateClaimMutation from '@shared/modules/Claim/hooks/useUpdateClaimMutation';
import ProjectClaimDrawerFields from 'modules/Project/Claim/components/ProjectClaimDrawerFields';

// We set the negative margins so that the drawer header
// height does not increase when rendering the Edit Button
// while in view mode.
const HeaderButtonRow = Styled.View`
  flex-direction: row;
  flex: ${({responsive}) => (responsive.desktop ? 1 : 'auto')};
  justify-content: flex-end;
  margin-top: -2px;
  margin-bottom: -2px;
  visibility: ${({isHidden}) => (isHidden ? 'hidden' : 'visible')};
`;

const EditProjectClaimDrawer = ({
  claim,
  projectUuid,
  isOpen,
  handleClose,
  onSuccess,
  isViewMode,
  handlePressSms,
  handlePressEmail,
}) => {
  const responsive = useResponsive();
  const [showEditMode, setShowEditMode] = useState(!isViewMode);
  const isMobileViewMode = !responsive.desktop && !showEditMode;

  const updateClaimSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: `Claim ${claim.identifier} updated`,
    isClosable: true,
  });

  const claimForm = ClaimForm.edit(claim);
  const {form, handleSubmit, submitting} = useUpdateClaimMutation({
    claimForm,
    onSuccess: () => {
      updateClaimSuccessToast.handleToast();
      onSuccess();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      width={DrawerWithAction.WIDTH.MEDIUM}
      headerText={`${showEditMode ? 'Edit ' : ''}Claim ${claim.identifier}`}
      HeaderRightComponent={() =>
        showEditMode ? null : (
          <HeaderButtonRow responsive={responsive}>
            <SecondaryButton
              text={'Edit'}
              iconLeft={Icon.Pen}
              onPress={() => setShowEditMode(true)}
            />
            {/* The 48 wide space on mobile is because of the Drawer.HeaderMobile component absolutely positisions this button.
             /* This gives us 16px spacing from the close button. */}
            <Space width={responsive.desktop ? 16 : 48} />
          </HeaderButtonRow>
        )
      }
      handleSubmit={isMobileViewMode ? () => setShowEditMode(true) : handleSubmit}
      primaryActionText={isMobileViewMode ? 'Edit' : 'Save'}
      isClosable
      isSubmitting={submitting}
      isDisabled={ClaimForm.getIsUploadingAttachment(form.values.claimForm)}
      isResponsive
    >
      <ProjectClaimDrawerFields
        form={form}
        field={'claimForm'}
        projectUuid={projectUuid}
        isOpen={isOpen}
        isViewMode={!showEditMode}
        claim={claim}
        handlePressSms={handlePressSms}
        handlePressEmail={handlePressEmail}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectClaimDrawer.fragment = gql`
  ${ClaimForm.edit.fragment}
  ${ProjectClaimDrawerFields.fragment}
  fragment EditProjectClaimDrawer on Claim {
    id
    identifier
    ...ClaimForm_edit
    ...ProjectClaimDrawerFields
  }
`;

export default EditProjectClaimDrawer;
