// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useEffect,
  useNavigationDOM,
  useQuery,
  useResponsive,
  useState,
  useUrlFilters,
} from '@supermove/hooks';
import {Project, ProjectType} from '@supermove/models';
import {List} from '@supermove/utils';

// App
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import UserRole from '@shared/modules/User/enums/UserRole';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import ShowProjectV2PageDesktop from 'modules/Project/V2/Show/ShowProjectV2PageDesktop';
import ShowProjectV2PageMobile from 'modules/Project/V2/Show/ShowProjectV2PageMobile';
import useProjectPageAnalytics from 'modules/Project/V2/Show/hooks/useProjectPageAnalytics';

const getNavigationSections = ({
  projectType,
  isCostAndCompensationEnabled,
  isCrewInventoryEnabled,
  responsive,
}) => {
  // This will move to the backend, as a property of project,
  // once the project page becomes configurable.
  return [
    {
      name: 'Move Details',
      blocks: [
        ...List.insertIf(!responsive.desktop, ProjectBlockKind.PROJECT_OVERVIEW),
        ProjectBlockKind.CLIENTS,
        ProjectBlockKind.LOCATIONS,
        ProjectBlockKind.INTERNAL,
      ],
    },
    {
      name: 'Sales',
      blocks: [
        ProjectBlockKind.SURVEY,
        ProjectBlockKind.VALUATION_COVERAGE,
        ProjectBlockKind.PROPOSALS,
        ProjectBlockKind.BILLING,
      ],
    },
    {
      name: 'Jobs',
      blocks: responsive.desktop ? ProjectType.getJobBlocks(projectType) : [ProjectBlockKind.JOBS],
    },
    ...List.insertIf(isCrewInventoryEnabled, {
      name: 'Inventory',
      blocks: [ProjectBlockKind.INVENTORY_SUMMARY, ProjectBlockKind.INVENTORY_ITEMS],
    }),
    {
      name: 'Accounting',
      blocks: [
        ...List.insertIf(isCostAndCompensationEnabled, ProjectBlockKind.ACCOUNTING_SUMMARY),
        ProjectBlockKind.INVOICE,
        ...List.insertIf(isCostAndCompensationEnabled, ProjectBlockKind.COST_AND_COMPENSATION),
        ProjectBlockKind.CLAIMS,
      ],
    },
  ];
};

const useProjectPageRedirectHandler = ({project, navigator, params, responsive}) => {
  const {isEnabledProjectPageV2, isEnabledProjectPageTablet, isEnabledProjectPageMobile} =
    project.organization.features;

  // Handle redirects between storage page, job page v1, and project page v2.
  // TODO(dan): Remove redirects from resizes once project page v2 is completed.
  useEffect(() => {
    if (project.isStorage) {
      navigator.replace(`/storage/projects/${project.uuid}`);
    } else if (
      (responsive.desktop && !isEnabledProjectPageV2) ||
      (responsive.tablet && !isEnabledProjectPageTablet) ||
      (responsive.mobile && !isEnabledProjectPageMobile)
    ) {
      if (params.jobUuid) {
        navigator.replace(`/jobs/${params.jobUuid}`);
      } else {
        navigator.replace(`/projects/${project.uuid}`);
      }
    }
  }, [responsive.desktop, responsive.tablet, responsive.mobile]); // eslint-disable-line react-hooks/exhaustive-deps
};

const useProjectPageParamHandler = ({
  project,
  viewer,
  params,
  urlFilters,
  navigationSections,
  responsive,
}) => {
  useEffect(() => {
    const {section, block} = params;

    if (section && !block) {
      const navSection = navigationSections.filter((navSection) => navSection.name === section)[0];
      if (navSection) {
        return urlFilters.handleUpdate({block: navSection.blocks[0]});
      }
    }
    if (block && !section) {
      const navSection = navigationSections.filter((navSection) =>
        navSection.blocks.includes(block),
      )[0];
      if (navSection) {
        return urlFilters.handleUpdate({section: navSection.name});
      }
    }
    // If one or the other is missing, and no matching section was found,
    // navigate to the first block of the first section.
    if (!section || !block) {
      const navSection = navigationSections[0];
      urlFilters.handleUpdate({section: navSection.name, block: navSection.blocks[0]});
    }

    // Handle setting jobUuid if needed
    const isJobBlock = ProjectBlockKind.JobBlocks.includes(params.block);
    const firstJob = Project.getDisplayAllJobsExcludingChildJobs(project, params)[0];
    if (isJobBlock && !params.jobUuid && firstJob) {
      urlFilters.handleUpdate({jobUuid: firstJob.uuid});
    }

    // This could happen if the window was resized from mobile to desktop
    const isMobileJobBlock = params.block === ProjectBlockKind.JOBS;
    if (responsive.desktop && isMobileJobBlock) {
      urlFilters.handleUpdate({block: ProjectBlockKind.Job.JOB_DETAILS});
    }
  }, [
    params,
    params.section,
    params.block,
    params.showCancelledJobs,
    urlFilters,
    navigationSections,
    project,
    responsive.desktop,
  ]); // eslint-disable-line react-hooks/exhaustive-deps
};

const useProjectPageAnalyticsHandler = ({params}) => {
  const {trackWidget, trackBlock} = useProjectPageAnalytics();

  // Track anaylitcs for viewing a widget
  useEffect(() => {
    trackWidget();
  }, [params.widget, trackWidget]);

  // Track analytics for viewing a block
  useEffect(() => {
    trackBlock();
  }, [params.block, trackBlock]);
};

const ShowProjectV2PageContent = ({
  project,
  viewer,
  urlFilters,
  refetch,
  refetchAndReset,
  resetKey,
}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();

  const {isCostAndCompensationEnabled, isCrewInventoryEnabled} = project.organization.settings;

  const navigationSections = getNavigationSections({
    projectType: project.projectType,
    isCostAndCompensationEnabled,
    isCrewInventoryEnabled,
    responsive,
  });

  useProjectPageRedirectHandler({project, navigator, params, responsive});
  useProjectPageParamHandler({project, viewer, params, urlFilters, navigationSections, responsive});
  useProjectPageAnalyticsHandler({params});

  const projectPageContentProps = {
    navigationSections,
    project,
    viewer,
    urlFilters,
    refetch,
    refetchAndReset,
    resetKey,
    isStaffAdmin: UserRole.getIsStaffAdmin(viewer.role), // Only used on desktop
  };

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <ShowProjectV2PageDesktop {...projectPageContentProps} />
      ) : (
        <ShowProjectV2PageMobile {...projectPageContentProps} />
      )}
    </React.Fragment>
  );
};

const ShowProjectV2Page = () => {
  const responsive = useResponsive();
  const {params} = useNavigationDOM();
  const [resetKey, setResetKey] = useState(0);

  const urlFilters = useUrlFilters({
    getRoute: () => `/projects/${params.projectUuid}/view`,
    filterKeys: [
      'section',
      'block',
      'widget',
      'jobUuid',
      'showCancelledJobs',
      'workflowRunUuid',
      'timesheet',
    ],
  });

  const {data, loading, refetch} = useQuery(ShowProjectV2Page.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectUuid: params.projectUuid},
  });

  const refetchAndReset = () => {
    refetch();
    setResetKey(resetKey + 1);
  };

  return (
    <SidebarPageV2 selected={'moves'}>
      <Loading
        loading={loading}
        as={
          responsive.desktop
            ? ShowProjectV2PageDesktop.LoadingComponent
            : ShowProjectV2PageMobile.LoadingComponent
        }
      >
        {() => {
          return (
            <ShowProjectV2PageContent
              project={data.project}
              viewer={data.viewer}
              urlFilters={urlFilters}
              refetch={refetch}
              refetchAndReset={refetchAndReset}
              resetKey={resetKey}
            />
          );
        }}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowProjectV2Page.query = gql`
  ${Project.getDisplayAllJobsExcludingChildJobs.fragment}
  ${ProjectType.getJobBlocks.fragment}
  ${ShowProjectV2PageDesktop.fragment}
  ${ShowProjectV2PageMobile.fragment}

  query ShowProjectV2Page($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      isStorage
      organization {
        id
        features {
          isEnabledProjectPageV2: isEnabled(feature: "PROJECT_PAGE_V2")
          isEnabledProjectPageMobile: isEnabled(feature: "PROJECT_PAGE_MOBILE")
          isEnabledProjectPageTablet: isEnabled(feature: "PROJECT_PAGE_TABLET")
        }
        settings {
          id
          isCostAndCompensationEnabled
          isCrewInventoryEnabled
        }
      }
      projectType {
        id
        ...ProjectType_getJobBlocks
      }
      ...Project_getDisplayAllJobsExcludingChildJobs
      ...ShowProjectV2PageDesktop
      ...ShowProjectV2PageMobile
    }
    viewer {
      id
      role
      viewingOrganization {
        id
        features {
          isEnabledViewProjectJobsMobile: isEnabled(feature: "VIEW_PROJECT_JOBS_MOBILE")
        }
      }
      ...ShowProjectV2PageDesktop_User
    }
  }
`;

export default ShowProjectV2Page;
