// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import DayForm from '@shared/modules/Scheduling/forms/DayForm';
import useUpdateDayMutation from '@shared/modules/Scheduling/hooks/useUpdateDayMutation';
import AddDayNotesPopover from 'modules/App/Day/components/AddDayNotesPopover';

const PressableContainer = Styled.ButtonV2`
  background-color: ${colors.gray.background};
  border-color: ${colors.gray.border};
  border-width: 1px;
  border-radius: 4px;
  padding: 8px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.primary};
`;

const IconContainer = Styled.View`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${colors.gray.primary};
  align-items: center;
  justify-content: center;
`;

const DayNotesSection = ({day, style}) => {
  const addDayNotesPopover = usePopover();
  const [width, setWidth] = useState(0);
  const dayForm = DayForm.edit(day);
  const {form, handleSubmit, submitting} = useUpdateDayMutation({
    dayForm,
    onSuccess: addDayNotesPopover.handleClose,
    onError: (errors) => console.log({errors}),
  });
  return (
    <React.Fragment>
      <Popover.Content innerRef={addDayNotesPopover.ref}>
        <PressableContainer
          style={style}
          onPress={() => addDayNotesPopover.handleToggle()}
          onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
        >
          <Row>
            <IconContainer>
              <Icon source={Icon.CalendarDay} size={10} color={colors.white} />
            </IconContainer>
            <Space width={8} />
            <Text numberOfLines={2}>{day.notes || 'Add day notes'}</Text>
          </Row>
        </PressableContainer>
      </Popover.Content>
      <AddDayNotesPopover
        key={addDayNotesPopover.isOpen}
        popover={addDayNotesPopover}
        width={width}
        form={form}
        handleSubmit={handleSubmit}
        submitting={submitting}
        organization={day.organization}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DayNotesSection.fragment = gql`
  ${DayForm.edit.fragment}
  ${AddDayNotesPopover.fragment}

  fragment DayNotesSection on Day {
    id
    notes
    organization {
      id
      ...AddDayNotesPopover
    }
    ...DayForm_edit
  }
`;

export default DayNotesSection;
