// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {colors} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  border-radius: 5px;
  background-color: ${(props) => colors.alpha(props.color, 0.15)};
`;

const Text = Styled.H7`
  margin-left: 8px;
  color: ${colors.gray.primary};
`;

const JobOperationsStatusBadge = ({job}) => {
  return (
    <Container color={Job.getCalendarPrimaryStatusColor(job)}>
      <Icon
        color={Job.getCalendarPrimaryStatusColor(job)}
        size={Icon.Sizes.Large}
        source={Job.getCalendarPrimaryStatusIcon(job)}
        style={{
          width: 20,
          marginBottom: 2,
        }}
      />
      <Text>{Job.getOperationsStatusText(job)}</Text>
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobOperationsStatusBadge.propTypes = {
  job: PropTypes.object.isRequired,
};

JobOperationsStatusBadge.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobOperationsStatusBadge.fragment = gql`
  ${Job.getCalendarPrimaryStatusColor.fragment}
  ${Job.getOperationsStatusText.fragment}

  fragment JobOperationsStatusBadge on Job {
    id
    ...Job_getCalendarPrimaryStatusColor
    ...Job_getOperationsStatusText
  }
`;

export default JobOperationsStatusBadge;
