// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import InventoryLibraryForm from '@shared/modules/Inventory/forms/InventoryLibraryForm';

const useDeleteInventoryLibraryMutation = ({inventoryLibraryForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      inventoryLibraryForm: InventoryLibraryForm.toForm(inventoryLibraryForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteInventoryLibraryMutation.mutation,
    variables: {
      inventoryLibraryForm: InventoryLibraryForm.toMutation(form.values.inventoryLibraryForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteInventoryLibraryMutation.mutation = gql`
  mutation useDeleteInventoryLibraryMutation($inventoryLibraryForm: InventoryLibraryForm!) {
    response: deleteInventoryLibrary(inventoryLibraryForm: $inventoryLibraryForm) {
      ${gql.errors}
      inventoryLibrary {
        id
      }
    }
  }
`;

export default useDeleteInventoryLibraryMutation;
