// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CopyDocumentTemplatesForm from '@shared/modules/Document/forms/CopyDocumentTemplatesForm';

const useCopyDocumentTemplatesMutation = ({copyDocumentTemplatesForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      copyDocumentTemplatesForm: CopyDocumentTemplatesForm.toForm(copyDocumentTemplatesForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCopyDocumentTemplatesMutation.mutation,
    variables: {
      copyDocumentTemplatesForm: CopyDocumentTemplatesForm.toMutation(
        form.values.copyDocumentTemplatesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCopyDocumentTemplatesMutation.mutation = gql`
  mutation useCopyDocumentTemplatesMutation($copyDocumentTemplatesForm: CopyDocumentTemplatesForm!) {
    response: copyDocumentTemplates(copyDocumentTemplatesForm: $copyDocumentTemplatesForm) {
      ${gql.errors}
      organization {
        id
        documentTemplates {
            id
            identifier
        }
      }
    }
  }
`;

export default useCopyDocumentTemplatesMutation;
