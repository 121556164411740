// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {MapView as SupermoveMapView} from '@supermove/components';
import {Location as LocationUtils} from '@supermove/utils';

// Components

/**
 * The map view component that should be used for all maps in Manager.
 */
const MapView = ({
  hasExtraControls,
  initialCenter,
  locations,
  directionLocations,
  onRouteUpdate,
  renderMapAnnotations,
  shouldResetBounds,
  streetViewPoint,
  onReady,
}) => {
  // We need to convert locations into memoized points using LocationUtils.create
  // to prevent re-rendering of the map.
  const points = locations.map((location) => LocationUtils.create(location)).filter(Boolean);

  const directionPoints = directionLocations
    .map((location) => LocationUtils.create(location))
    .filter(Boolean);

  return (
    <SupermoveMapView
      isScrollEnabled={false}
      mapTypeControl={hasExtraControls}
      streetViewControl={hasExtraControls}
      bounds={points}
      shouldResetBounds={shouldResetBounds}
      directions={directionPoints}
      initialCenter={initialCenter}
      streetView={streetViewPoint}
      onRouteUpdate={onRouteUpdate}
      onReady={onReady}
      style={{
        flex: 1,
      }}
    >
      {(props) => <React.Fragment>{renderMapAnnotations(props)}</React.Fragment>}
    </SupermoveMapView>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
MapView.propTypes = {
  /**
   * True if the map should have extra controls such as the map type control (ie. satellite view) and street view
   * control.
   */
  hasExtraControls: PropTypes.bool,

  /**
   * The initial center of the map, represented by a single coordinate.
   */
  initialCenter: PropTypes.object,

  /**
   * An array of locations on the map. This is used to set the initial bounds of the map.
   */
  locations: PropTypes.arrayOf(PropTypes.object),

  /**
   * An array of locations that will be used to create the `route` object of the map.
   * The route object can then be used by a Polyline to draw the route on the map.
   */
  directionLocations: PropTypes.arrayOf(PropTypes.object),

  /**
   * A function that is called when the route is updated.
   */
  onRouteUpdate: PropTypes.func,

  /**
   * A function that renders map annotations.
   */
  renderMapAnnotations: PropTypes.func,

  /**
   * False if the map should only set the bounds initially and never set them again.
   * This usually results in better user experience when set to false so the map does
   * not automatically reset bounds when the user is interacting with the map.
   */
  shouldResetBounds: PropTypes.bool,

  /**
   * A single coordinate that, if set, will render the street map view at that location.
   */
  streetViewPoint: PropTypes.object,
};

MapView.defaultProps = {
  hasExtraControls: false,
  initialCenter: {
    // Supermove's office ;)
    lat: 37.7749,
    lng: -122.4194,
  },
  locations: [],
  directionLocations: [],
  onRouteUpdate: () => {},
  renderMapAnnotations: () => null,
  shouldResetBounds: true,
  streetViewPoint: null,
};

export default MapView;
