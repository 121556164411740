// Libraries
import React from 'react';

// Supermove
import {Styled, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import IconButton from '@shared/design/components/IconButton';
import RemoveClaimStatusModal from 'modules/Organization/Settings/Company/components/RemoveClaimStatusModal';
import UpdateClaimStatusModal from 'modules/Organization/Settings/Company/components/UpdateClaimStatusModal';

const ItemContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 12px 16px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const Container = Styled.View`
  flex-direction: row;
  flex: 1;
  /* Must use padding here to get spacing between components in drag and drop list */
  /* Drag and drop does some weird stuff with the Space component so it does not show up properly */
  padding-vertical: 4px;
`;

const ActionContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const BadgeContainer = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

const DefaultBadgeContainer = Styled.View`
  flex-direction: column;
  align-self: center;
`;

const DefaultBadge = Styled.View`
  padding: 2px 8px;
  border-radius: 8px;
  background-color: ${colors.gray.border};
`;

const DefaultBadgeText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.secondary};
`;

const StatusBadge = Styled.View`
  padding: 4px 8px;
  border-radius: 4px;
`;

const StatusBadgeContainer = Styled.View`
  align-items: flex-start;
`;

const StatusBadgeText = Styled.Text`
  ${Typography.MicroLabel}
  color: ${({color}) => color};
`;

const StatusNameBadge = ({claimStatus}) => {
  const backgroundColor = colors.getBackgroundColor(claimStatus.color);
  const textColor = colors.getTextColor(claimStatus.color);
  return (
    <StatusBadgeContainer>
      <StatusBadge style={{backgroundColor}}>
        <StatusBadgeText numberOfLines={1} style={{color: textColor}}>
          {claimStatus.name}
        </StatusBadgeText>
      </StatusBadge>
    </StatusBadgeContainer>
  );
};

const StatusDefaultBadge = () => {
  return (
    <StatusBadgeContainer>
      <DefaultBadge>
        <DefaultBadgeText>Default</DefaultBadgeText>
      </DefaultBadge>
    </StatusBadgeContainer>
  );
};

const ClaimStatusItem = ({claimStatus, refetch, isOrganizationPrimary}) => {
  const editClaimStatusModal = useModal({name: 'Edit Claim Status Modal'});
  const claimStatusActionsPopover = usePopover({
    name: 'Claim Status Actions Popover',
  });
  const removeClaimStatusModal = useModal({name: 'Remove Claim Status Modal'});
  return (
    <Container>
      <ItemContainer>
        <BadgeContainer>
          <StatusNameBadge claimStatus={claimStatus} />
          {claimStatus.isDefault && (
            <React.Fragment>
              <Space width={12} />
              <DefaultBadgeContainer>
                <StatusDefaultBadge />
              </DefaultBadgeContainer>
            </React.Fragment>
          )}
        </BadgeContainer>
        {isOrganizationPrimary && (
          <ActionContainer>
            <IconButton source={Icon.Pen} onPress={editClaimStatusModal.handleOpen} />
            <Space width={8} />
            <ActionMenuPopover
              popover={claimStatusActionsPopover}
              actions={[
                {
                  text: 'Remove',
                  onPress: removeClaimStatusModal.handleOpen,
                },
              ]}
            >
              <IconButton
                onPress={claimStatusActionsPopover.handleOpen}
                source={Icon.EllipsisV}
                isSecondary
              />
            </ActionMenuPopover>
          </ActionContainer>
        )}
      </ItemContainer>
      <UpdateClaimStatusModal
        key={editClaimStatusModal.key}
        isOpen={editClaimStatusModal.isOpen}
        handleClose={editClaimStatusModal.handleClose}
        claimStatus={claimStatus}
        refetch={refetch}
      />
      <RemoveClaimStatusModal
        key={removeClaimStatusModal.key}
        isOpen={removeClaimStatusModal.isOpen}
        handleClose={removeClaimStatusModal.handleClose}
        claimStatus={claimStatus}
        refetch={refetch}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClaimStatusItem.fragment = gql`
  ${UpdateClaimStatusModal.fragment}
  ${RemoveClaimStatusModal.fragment}
  fragment ClaimStatusItem on ClaimStatus {
    id
    color
    name
    isDefault
    ...UpdateClaimStatusModal
    ...RemoveClaimStatusModal
  }
`;

export default ClaimStatusItem;
