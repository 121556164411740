// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {PayrollReport} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import CompensationReportCosts from 'modules/Accounting/CompensationReport/components/CompensationReportCosts';

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
`;

const CompensationReportContainer = Styled.View`
  background-color: ${colors.white};
  padding-horizontal: 20px;
`;

const TitleText = Styled.Text`
  ${Typography.Label2}
`;

const SubtitleText = Styled.Text`
  ${Typography.Body5}
  font-size: 10px;
  color: ${({color}) => color}
`;

const ReportHeader = ({compensationReport}) => {
  return (
    <Container>
      <Row>
        <TitleText>{`Compensation Report #${compensationReport.identifier}`}</TitleText>
        <Space style={{flex: 1}} />
        <TitleText>{compensationReport.user.fullName}</TitleText>
      </Row>
      <Space height={6} />
      <Row>
        <SubtitleText color={colors.gray.secondary}>Report Range</SubtitleText>
        <Space width={4} />
        <SubtitleText color={colors.gray.primary}>
          {PayrollReport.getDateRange(compensationReport.payrollReport)}
        </SubtitleText>
        <Space style={{flex: 1}} />
        <Container style={{alignItems: 'flex-end'}}>
          <Row>
            <SubtitleText color={colors.gray.secondary}>Position</SubtitleText>
            <Space width={4} />
            <SubtitleText color={colors.gray.primary}>
              {compensationReport.user.position}
            </SubtitleText>
          </Row>
          <Row>
            <SubtitleText color={colors.gray.secondary}>Company</SubtitleText>
            <Space width={4} />
            <SubtitleText color={colors.gray.primary}>
              {compensationReport.user.organization.name}
            </SubtitleText>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

const CompensationReportView = ({compensationReport}) => {
  return (
    <CompensationReportContainer>
      <Space height={20} />
      <ReportHeader compensationReport={compensationReport} />
      <Space height={12} />
      <CompensationReportCosts
        compensationReport={compensationReport}
        isEnabledTbdBillItems={compensationReport.user.organization.features.isEnabledTbdBillItems}
      />
      <Space height={20} />
    </CompensationReportContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompensationReportView.fragment = gql`
  ${CompensationReportCosts.fragment}
  ${PayrollReport.getDateRange.fragment}

  fragment CompensationReportView on CompensationReport {
    id
    identifier
    user {
      id
      fullName
      position
      organization {
        id
        name
        features {
          isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
        }
      }
    }
    payrollReport {
      id
      ...PayrollReport_getDateRange
    }
    ...CompensationReportCosts
  }
`;

export default CompensationReportView;
