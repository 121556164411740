// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DocumentTemplateV2Form from '@shared/modules/Document/forms/DocumentTemplateV2Form';

const useDeleteDocumentTemplateV2Mutation = ({documentTemplateV2Form, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      documentTemplateV2Form: DocumentTemplateV2Form.toForm(documentTemplateV2Form),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteDocumentTemplateV2Mutation.mutation,
    variables: {
      documentTemplateV2Form: DocumentTemplateV2Form.toMutation(form.values.documentTemplateV2Form),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteDocumentTemplateV2Mutation.mutation = gql`
  mutation useDeleteDocumentTemplateV2Mutation($documentTemplateV2Form: DocumentTemplateV2Form!) {
    response: deleteDocumentTemplateV2(documentTemplateV2Form: $documentTemplateV2Form) {
        ${gql.errors}
        documentTemplateV2 {
          id
        }
    }
  }
`;

export default useDeleteDocumentTemplateV2Mutation;
