// Libraries
import React from 'react';

// Supermove
import {Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useNavigationDOM,
  useQuery,
  useState,
  useMountEffect,
  useResponsive,
  useSheet,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, URL, pluralize} from '@supermove/utils';

// App
import Line from 'modules/App/components/Line';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import CapacityCalendarDayPickerBlock from 'modules/Calendar/Capacity/components/CapacityCalendarDayPickerBlock';
import CapacityCalendarJobsListTable from 'modules/Calendar/Capacity/components/CapacityCalendarJobsListTable';
import CapacityCalendarToolbarHeader from 'modules/Calendar/Capacity/components/CapacityCalendarToolbarHeader';
import DayMetricsAndNotes from 'modules/Calendar/Capacity/components/DayMetricsAndNotes';

const PageContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const PageContentContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  background-color: ${colors.gray.background};
`;

const PageContentContainerLeftPanel = Styled.View`
  width: 321px;
`;

const DayNotesRow = Styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const CapacityCalendarDayPickerBlockContainer = Styled.View`
  min-height: 294px;
  width: 100%;
  background-color: ${colors.white};
`;

const PageContentContainerRightPanel = Styled.View`
  border-left-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  flex: 1;
  padding-horizontal: 16px;
`;

const DayDetailsContainer = Styled.View`
  flex: 1;
  padding: 20px;
`;

const DayText = Styled.Text`
  ${Typography.Responsive.Subheading}
  color: ${colors.gray.primary};
`;

const SubheadingText = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const getFilteredUrlFromParams = (params, baseUrl) => {
  return URL.getUrlFromVariables(baseUrl, params);
};

const handleUpdateParam = ({baseUrl, navigator, params, paramKey, paramValue}) => {
  navigator.push(getFilteredUrlFromParams({...params, [paramKey]: paramValue || ''}, baseUrl));
};

const DesktopSidebar = ({
  selectedDate,
  setSelectedDate,
  organization,
  viewingOrganization,
  capacityCalendarSingleDay,
  days,
  isLoading,
  params,
  refetchDayView,
  selectedDateText,
}) => {
  const responsive = useResponsive();
  return (
    <ScrollView style={{maxWidth: 321, paddingBottom: 48, backgroundColor: colors.white}}>
      <PageContentContainerLeftPanel>
        <CapacityCalendarDayPickerBlockContainer>
          <CapacityCalendarDayPickerBlock
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            organization={organization}
          />
        </CapacityCalendarDayPickerBlockContainer>
        <Line />
        <DayDetailsContainer>
          <DayText responsive={responsive}>{selectedDateText}</DayText>
          <Space height={16} />
          <DayMetricsAndNotes
            isLoading={isLoading}
            viewingOrganization={viewingOrganization}
            capacityCalendarSingleDay={capacityCalendarSingleDay}
            days={days}
            selectedDate={selectedDate}
            params={params}
            refetchDayView={refetchDayView}
          />
        </DayDetailsContainer>
      </PageContentContainerLeftPanel>
    </ScrollView>
  );
};

const TableHeading = ({responsive, jobsCount, label}) => {
  if (jobsCount === null) {
    return <SkeletonLoader width={100} height={20} />;
  }
  return (
    <DayNotesRow>
      <SubheadingText responsive={responsive}>
        {pluralize(label, jobsCount || 0, true)}
      </SubheadingText>
    </DayNotesRow>
  );
};

const CapacityCalendarDayView = ({organization}) => {
  const responsive = useResponsive();
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;
  const [isJobsListTableExpanded, setIsJobsListTableExpanded] = useState(false);
  const [totalJobsForDay, setTotalJobsForDay] = useState(null);
  const [totalTrainingJobsForDay, setTotalTrainingJobsForDay] = useState(null);
  const {isEnabledCapacityCalendarResponsiveDayView} = organization.features;

  const [selectedDate, setSelectedDate] = useState(
    params.date || Datetime.toMutationDate(Datetime.today),
  );

  useMountEffect(() => {
    // If required params are not set in the route on mount,
    // set the correct variables
    if (!params.slugs) {
      handleUpdateParam({
        baseUrl: location.pathname,
        navigator,
        params,
        paramKey: 'slugs',
        paramValue: organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
      });
    }
  });

  const {
    data,
    loading,
    refetch: refetchDayView,
  } = useQuery(CapacityCalendarDayView.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      day: selectedDate,
      slugs: params.slugs
        ? params.slugs
        : organization.isPrimary
          ? ['ALL_ORGANIZATIONS']
          : [organization.slug],
    },
  });
  const selectedDateText = Datetime.toDisplayDateWithWeekdayName(new Date(selectedDate));
  const dayNotesSheet = useSheet({name: 'Day Notes Sheet'});

  return (
    <PageContainer>
      <PageContentContainer>
        {(!isEnabledCapacityCalendarResponsiveDayView || responsive.desktop) && (
          <DesktopSidebar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            organization={organization}
            viewingOrganization={data?.viewer?.viewingOrganization}
            capacityCalendarSingleDay={data?.capacityCalendarSingleDay}
            days={data?.days}
            isLoading={loading}
            params={params}
            refetchDayView={refetchDayView}
            selectedDateText={selectedDateText}
          />
        )}
        <PageContentContainerRightPanel>
          <CapacityCalendarToolbarHeader
            isDayView
            organization={organization}
            canViewOtherBranchesData={organization.canViewOtherBranchesData}
            isJobsListTableExpanded={isJobsListTableExpanded}
            setIsJobsListTableExpanded={setIsJobsListTableExpanded}
            totalJobsForDay={totalJobsForDay}
            label={selectedDateText}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            dayNotesSheet={dayNotesSheet}
          />
          <ScrollView style={{flex: 1}}>
            {isEnabledCapacityCalendarResponsiveDayView && !responsive.desktop && (
              <React.Fragment>
                <TableHeading responsive={responsive} jobsCount={totalJobsForDay} label={'Job'} />
                <Space height={16} />
              </React.Fragment>
            )}
            <CapacityCalendarJobsListTable
              selectedDate={selectedDate}
              organization={organization}
              isJobsListTableExpanded={isJobsListTableExpanded}
              refetchDayView={refetchDayView}
              setTotalJobsForDay={setTotalJobsForDay}
            />
            <Space height={24} />
            <TableHeading
              responsive={responsive}
              jobsCount={totalTrainingJobsForDay}
              label={'Training Job'}
            />
            <Space height={16} />
            <CapacityCalendarJobsListTable
              isTrainingOnly
              selectedDate={selectedDate}
              organization={organization}
              isJobsListTableExpanded={isJobsListTableExpanded}
              refetchDayView={refetchDayView}
              setTotalJobsForDay={setTotalTrainingJobsForDay}
            />
            <Space height={24} />
          </ScrollView>
        </PageContentContainerRightPanel>
      </PageContentContainer>
      {isEnabledCapacityCalendarResponsiveDayView && !responsive.desktop && (
        <DayMetricsAndNotes.Sheet
          dayNotesSheet={dayNotesSheet}
          selectedDateText={selectedDateText}
          viewingOrganization={data?.viewer?.viewingOrganization}
          capacityCalendarSingleDay={data?.capacityCalendarSingleDay}
          days={data?.days}
          isLoading={loading}
          params={params}
          selectedDate={selectedDate}
          refetchDayView={refetchDayView}
        />
      )}
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarDayView.query = gql`
  ${DayMetricsAndNotes.fragment}

  query CapacityCalendarDayView($day: String!, $slugs: [String!]!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...DayMetricsAndNotes_Organization
      }
    }
    capacityCalendarSingleDay(day: $day, slugs: $slugs) {
      ...DayMetricsAndNotes_CapacityCalendar
	  }
    days(date: $day, slugs: $slugs) {
      id
      ...DayMetricsAndNotes_Day
    }
  }
`;

CapacityCalendarDayView.fragment = gql`
  ${CapacityCalendarJobsListTable.fragment}
  ${CapacityCalendarToolbarHeader.fragment}

  fragment CapacityCalendarDayView on Organization {
    id
    slug
    isPrimary
    hasMultipleOrganizations
    canViewOtherBranchesData
    features {
      isEnabledCapacityCalendarResponsiveDayView: isEnabled(
        feature: "CAPACITY_CALENDAR_RESPONSIVE_DAY_VIEW"
      )
    }
    ...CapacityCalendarJobsListTable
    ...CapacityCalendarToolbarHeader
  }
`;

export default CapacityCalendarDayView;
