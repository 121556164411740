// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {HTML, withFragment} from '@supermove/utils';

// App
import EmailTemplateBodyKind from '@shared/modules/Email/enums/EmailTemplateBodyKind';
import EmailTemplateRecipientForm from '@shared/modules/Email/forms/EmailTemplateRecipientForm';

const getCustomRecipientOptions = (emailTemplate: any) => {
  const customRecipients: any = [];
  emailTemplate.toEmailTemplateRecipients.forEach((recipient: any) => {
    if (recipient.email) {
      customRecipients.push({value: recipient.email, label: recipient.email});
    }
  });
  emailTemplate.ccEmailTemplateRecipients.forEach((recipient: any) => {
    if (recipient.email) {
      customRecipients.push({value: recipient.email, label: recipient.email});
    }
  });
  emailTemplate.bccEmailTemplateRecipients.forEach((recipient: any) => {
    if (recipient.email) {
      customRecipients.push({value: recipient.email, label: recipient.email});
    }
  });
  return customRecipients;
};

const EmailTemplateForm = {
  toMutation: ({
    emailTemplateId,
    organizationId,
    kind,
    name,
    toEmailTemplateRecipientForms,
    ccEmailTemplateRecipientForms,
    bccEmailTemplateRecipientForms,
    subject,
    body,
    bodyKind,
    attachmentIds,
  }: any) => ({
    emailTemplateId,
    organizationId,
    kind,
    name,
    toEmailTemplateRecipientForms: toEmailTemplateRecipientForms.map((form: any) =>
      EmailTemplateRecipientForm.toMutation(form),
    ),
    ccEmailTemplateRecipientForms: ccEmailTemplateRecipientForms.map((form: any) =>
      EmailTemplateRecipientForm.toMutation(form),
    ),
    bccEmailTemplateRecipientForms: bccEmailTemplateRecipientForms.map((form: any) =>
      EmailTemplateRecipientForm.toMutation(form),
    ),
    subject,
    body: bodyKind === EmailTemplateBodyKind.WYSIWYG ? HTML.fixRichTextEditor(body) : body,
    bodyKind,
    attachmentIds,
  }),
  toForm: ({
    emailTemplateId,
    organizationId,
    kind,
    name,
    toEmailTemplateRecipientForms,
    ccEmailTemplateRecipientForms,
    bccEmailTemplateRecipientForms,
    subject,
    body,
    bodyKind,
    attachmentIds,
    isShowingVariables,
    isShowingCc,
    isShowingBcc,
    toEmailTemplateRecipientValues,
    ccEmailTemplateRecipientValues,
    bccEmailTemplateRecipientValues,
    searchInputValue,
    customRecipientOptions,
  }: any) => ({
    emailTemplateId,
    organizationId,
    kind,
    name,
    toEmailTemplateRecipientForms: toEmailTemplateRecipientForms.map((form: any) =>
      EmailTemplateRecipientForm.toForm(form),
    ),
    ccEmailTemplateRecipientForms: ccEmailTemplateRecipientForms.map((form: any) =>
      EmailTemplateRecipientForm.toForm(form),
    ),
    bccEmailTemplateRecipientForms: bccEmailTemplateRecipientForms.map((form: any) =>
      EmailTemplateRecipientForm.toForm(form),
    ),
    subject,
    body: bodyKind === EmailTemplateBodyKind.WYSIWYG ? HTML.fixRichTextEditor(body) : body,
    bodyKind,
    attachmentIds,
    // Private
    isShowingVariables,
    isShowingCc,
    isShowingBcc,
    toEmailTemplateRecipientValues,
    ccEmailTemplateRecipientValues,
    bccEmailTemplateRecipientValues,
    searchInputValue,
    customRecipientOptions,
  }),
  edit: withFragment(
    (emailTemplate) => ({
      emailTemplateId: (emailTemplate as any).id,
      organizationId: (emailTemplate as any).organizationId,
      kind: (emailTemplate as any).kind,
      name: (emailTemplate as any).name,
      toEmailTemplateRecipientForms: (emailTemplate as any).toEmailTemplateRecipients.map(
        (recipient: any) => EmailTemplateRecipientForm.edit(recipient),
      ),
      ccEmailTemplateRecipientForms: (emailTemplate as any).ccEmailTemplateRecipients.map(
        (recipient: any) => EmailTemplateRecipientForm.edit(recipient),
      ),
      bccEmailTemplateRecipientForms: (emailTemplate as any).bccEmailTemplateRecipients.map(
        (recipient: any) => EmailTemplateRecipientForm.edit(recipient),
      ),
      subject: (emailTemplate as any).subject,
      body: (emailTemplate as any).body,
      bodyKind: (emailTemplate as any).bodyKind,
      attachmentIds: (emailTemplate as any).emailTemplateAttachments.map(
        (emailTemplateAttachment: any) => emailTemplateAttachment.attachment.id,
      ),
      // Private
      isShowingVariables: false,
      isShowingCc: _.size((emailTemplate as any).ccEmailTemplateRecipients) > 0,
      isShowingBcc: _.size((emailTemplate as any).bccEmailTemplateRecipients) > 0,
      // TODO(dan) There should be a way to fully utilize recipient forms in the multidropdown
      // and not have to use these recipient values.
      toEmailTemplateRecipientValues: (emailTemplate as any).toEmailTemplateRecipients.map(
        (recipient: any) => EmailTemplateRecipientForm.getRecipientValue(recipient),
      ),
      ccEmailTemplateRecipientValues: (emailTemplate as any).ccEmailTemplateRecipients.map(
        (recipient: any) => EmailTemplateRecipientForm.getRecipientValue(recipient),
      ),
      bccEmailTemplateRecipientValues: (emailTemplate as any).bccEmailTemplateRecipients.map(
        (recipient: any) => EmailTemplateRecipientForm.getRecipientValue(recipient),
      ),
      searchInputValue: '',
      customRecipientOptions: getCustomRecipientOptions(emailTemplate),
    }),
    gql`
      ${EmailTemplateRecipientForm.edit.fragment}

      fragment EmailTemplateForm_edit on EmailTemplate {
        id
        organizationId
        kind
        name
        toEmailTemplateRecipients {
          ...EmailTemplateRecipientForm_edit
        }
        ccEmailTemplateRecipients {
          ...EmailTemplateRecipientForm_edit
        }
        bccEmailTemplateRecipients {
          ...EmailTemplateRecipientForm_edit
        }
        subject
        body
        bodyKind
        emailTemplateAttachments {
          id
          attachment {
            id
          }
        }
      }
    `,
  ),
  new: ({organizationId}: any) => ({
    organizationId,
    kind: '',
    name: '',
    toEmailTemplateRecipientForms: [],
    ccEmailTemplateRecipientForms: [],
    bccEmailTemplateRecipientForms: [],
    subject: '',
    body: '',
    bodyKind: EmailTemplateBodyKind.WYSIWYG,
    attachmentIds: [],
    // Private
    isShowingVariables: false,
    isShowingCc: false,
    isShowingBcc: false,
    toEmailTemplateRecipientValues: [],
    ccEmailTemplateRecipientValues: [],
    bccEmailTemplateRecipientValues: [],
    searchInputValue: '',
    customRecipientOptions: [],
  }),
};

export default EmailTemplateForm;
