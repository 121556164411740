// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Animation, colors} from '@supermove/styles';

// App
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import ValueField from 'modules/Project/Billing/components/ValueField';

const ValuesGrid = Styled.View`
  display: grid;
  grid-template-columns: repeat(${(props) =>
    props.numColumns || (props.mobile ? 1 : 3)}, minmax(0, 1fr));
  margin-right: -12px;
  margin-bottom: -8px;
`;

const ValueItem = Styled.View`
  margin-right: 12px;
  margin-bottom: ${({responsive}) => (responsive.desktop ? 8 : 16)}px;
  z-index: ${(props) => 100 - props.index};
`;

const Skeleton = Styled.View`
  ${Animation.pulse}
  height: 40px;
  flex: 1;
  border-radius: 4px;
  margin-right: 12px;
  margin-bottom: 8px;
  background-color: ${colors.gray.disabled};
`;

const SkeletonLoader = () => {
  const responsive = useResponsive();
  return (
    <ValuesGrid {...responsive}>
      {_.map(_.range(15), (i) => (
        <Skeleton key={i} />
      ))}
    </ValuesGrid>
  );
};

const ValueFields = ({
  field,
  form,
  handleBlur,
  isOnCreate,
  filteredSectionId,
  isDisabled,
  isDisabledValuesHidden,
  numColumns,
}) => {
  const responsive = useResponsive();
  const valueForms = _.get(form.values, field);

  return (
    <ValuesGrid {...responsive} numColumns={numColumns}>
      {valueForms.reduce((filteredValueForms, valueForm, currentIndex) => {
        // Do not show dynamic variable format values since the display name would all show as the same thing
        const isDynamicVariableFormat = _.has(
          VariableFormat.DYNAMIC_VARIABLE_FORMATS,
          valueForm.variableFormat,
        );

        // CITY_VALUE_CURRENCY values typically have longer text
        // With the virtualized dropdown input we need to know the height ahead of time so must manually set it
        const optionHeight =
          valueForm.variableFormat === VariableFormat.CITY_VALUE_CURRENCY ? 50 : 35;

        const isValueFormDisabled = isDisabled || valueForm.isDisabled;
        const isDisabledValueHidden = isDisabledValuesHidden && isValueFormDisabled;

        if (
          valueForm.isEnabled &&
          !isDynamicVariableFormat &&
          (!isOnCreate || valueForm.isVisibleForCreateProject) &&
          // If we are filtering by section, only show the value forms that match the section
          (!filteredSectionId ||
            _.toString(valueForm.variableSectionId) === _.toString(filteredSectionId)) &&
          !isDisabledValueHidden
        ) {
          return [
            ...filteredValueForms,
            <ValueItem key={currentIndex} index={currentIndex} responsive={responsive}>
              <ValueField
                optionHeight={responsive.desktop ? optionHeight : optionHeight + 12}
                isTruncatedLabel
                field={`${field}.${currentIndex}`}
                form={form}
                handleBlur={handleBlur}
                isDisabled={isDisabled}
                required={
                  isOnCreate &&
                  _.get(form.values, `${field}.${currentIndex}.isRequiredForCreateProject`)
                }
              />
            </ValueItem>,
          ];
        }
        return filteredValueForms;
      }, [])}
    </ValuesGrid>
  );
};

ValueFields.SkeletonLoader = SkeletonLoader;

// --------------------------------------------------
// Props
// --------------------------------------------------
ValueFields.propTypes = {
  field: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  handleBlur: PropTypes.func,
  isOnCreate: PropTypes.bool,
  numColumns: PropTypes.number,
};

ValueFields.defaultProps = {
  handleBlur: () => {},
  isOnCreate: false,
  numColumns: null,
};

export default ValueFields;
