// Libraries
import React from 'react';
// @ts-expect-error library is not typed
import {CardElement} from 'react-stripe-elements';

// Assets
import './Stripe.css';

interface StripeCardInputProps {
  isDisabled?: boolean;
  onChange?: (event: any) => void;
  onReady?: (element: any) => void;
}

const StripeCardInput = ({
  isDisabled = false,
  onChange = () => {}, // From Stripe: (event) => {} where event contains validation information.
  onReady = () => {}, // From Stripe: (element) => {} where element is the underlying card element node.
}: StripeCardInputProps) => (
  <CardElement
    className={'stripe-card-element'}
    disabled={isDisabled}
    onChange={onChange}
    onReady={onReady}
    style={{
      /* stylelint-disable selector-type-no-unknown, unit-allowed-list */
      base: {
        'fontSize': '16px',
        'color': '#424770',
        'letterSpacing': '0.025em',
        'fontFamily': 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    }}
  />
);

export default StripeCardInput;
