// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UserNotificationsSettingsForm from '@shared/modules/Notification/forms/UserNotificationSettingForm';

const useUpdateUserNotificationSettingMutation = ({
  userNotificationSettingForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      userNotificationSettingForm: UserNotificationsSettingsForm.toForm(
        userNotificationSettingForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateUserNotificationSettingMutation.mutation,
    variables: {
      userNotificationSettingForm: UserNotificationsSettingsForm.toMutation(
        form.values.userNotificationSettingForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateUserNotificationSettingMutation.mutation = gql`
  mutation useUpdateUserNotificationSettingMutation($userNotificationSettingForm: UserNotificationSettingForm!) {
    response: updateUserNotificationSetting(userNotificationSettingForm: $userNotificationSettingForm) {
      ${gql.errors}
    }
  }
`;

export default useUpdateUserNotificationSettingMutation;
