// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {JobUser, Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Text = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const BoldText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const IconContainer = Styled.View`
  width: 12px;
  align-items: center;
`;

const getFirstNameAndLastInitial = (name) => {
  const [firstName, lastName] = name.split(' ');

  if (!lastName) return firstName;

  const [lastInitial] = lastName.split('');

  return `${firstName} ${lastInitial}.`;
};

const IconTooltip = ({children, text}) => {
  return (
    <TextTooltip text={text}>
      <IconContainer>{children}</IconContainer>
    </TextTooltip>
  );
};

const JobCrewCounts = ({
  job,
  crewUsersCount,
  crewTrucksCount,
  showNamesWithStatuses,
  showTruckCountInline,
}) => {
  return (
    <Row style={{flexShrink: 1}}>
      {showTruckCountInline && (
        <React.Fragment>
          <IconTooltip text={'Trucks'}>
            <Icon color={colors.gray.primary} size={11} source={Icon.Truck} />
          </IconTooltip>
          <Space width={6} />
          <BoldText>{crewTrucksCount || Job.getDispatchJobTrucksCount(job)}</BoldText>
          <Space width={8} />
        </React.Fragment>
      )}
      <IconTooltip text={'Movers'}>
        <Icon color={colors.gray.primary} size={11} source={Icon.User} />
      </IconTooltip>
      <Space width={6} />
      <BoldText>{crewUsersCount || `0/${job.numberOfMovers}`}</BoldText>
      {showNamesWithStatuses && (
        <React.Fragment>
          {job.activeJobUsers.map((activeJobUser, index) => (
            <React.Fragment key={activeJobUser.id}>
              <Space width={4} />
              <TextTooltip text={JobUser.getTooltipStatus(activeJobUser)}>
                <Row>
                  <Space width={4} />
                  <Icon source={Icon.Circle} color={activeJobUser.statusColor} size={6} />
                  <Space width={4} />
                  <Text>{getFirstNameAndLastInitial(activeJobUser.user.fullName)}</Text>
                </Row>
              </TextTooltip>
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCrewCounts.fragment = gql`
  ${JobUser.getTooltipStatus.fragment}
  ${Job.getDispatchJobTrucksCount.fragment}

  fragment JobCrewCounts on Job {
    id
    numberOfMovers
    activeJobUsers {
      id
      statusColor
      user {
        id
        position
      }
      ...JobUser_getTooltipStatus
    }
    ...Job_getDispatchJobTrucksCount
  }
`;

export default JobCrewCounts;
