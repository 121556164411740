// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled, Icon} from '@supermove/components';
import {useHover, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${({isHovered}) => (isHovered ? colors.gray.border : 'transparent')};
  background-color: ${({isHovered}) => (isHovered ? colors.gray.background : 'transparent')};
  padding-horizontal: 4px;
  padding-vertical: ${({hasTags}) => (hasTags ? 2 : 4)}px;
`;

const EmojiContainer = Styled.View`
  background-color: ${colors.gray.background};
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  align-items: center;
  justify-content: center;
  margin-right: ${({isLast}) => (isLast ? 0 : -8)}px;
`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const TruncateText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${({color}) => color};
`;

const getHoverText = ({tags}) => {
  if (_.isEmpty(tags)) {
    return '';
  }

  const hoverText = tags.map((tag) => `${tag.emoji} ${tag.name}`).join('\n');

  return hoverText;
};

const getIsLastEmoji = ({tags, index, numberOfVisibleSelections}) => {
  return tags.length <= numberOfVisibleSelections
    ? index === tags.length - 1
    : index === numberOfVisibleSelections - 1;
};

// TODO(Kevin): create a new Tag model and use that instead
// Fetch tags from tagAssignmentBy* and passed into component
// With a combined list -> see ProjectTags
const TagEmojiButton = ({tags, numberOfVisibleSelections, upsertTagModal}) => {
  const {ref, isHovered} = useHover();
  const responsive = useResponsive();
  const hasTags = _.some(tags);

  return (
    <Button onPress={upsertTagModal.handleOpen} ref={ref} isHovered={isHovered} hasTags={hasTags}>
      {hasTags ? (
        <TextTooltip text={getHoverText({tags})}>
          <Container>
            {tags.map((tag, index) => {
              if (index >= numberOfVisibleSelections) {
                return null;
              }
              return (
                <EmojiContainer
                  index={index}
                  key={index}
                  isLast={getIsLastEmoji({tags, index, numberOfVisibleSelections})}
                >
                  <BodyText responsive={responsive}>{tag.emoji}</BodyText>
                </EmojiContainer>
              );
            })}
            {tags.length > numberOfVisibleSelections && (
              <React.Fragment>
                <Space width={4} />
                <TruncateText
                  responsive={responsive}
                  color={isHovered ? colors.blue.hover : colors.gray.secondary}
                >{`+${tags.length - numberOfVisibleSelections}`}</TruncateText>
              </React.Fragment>
            )}
          </Container>
        </TextTooltip>
      ) : (
        <Icon
          source={Icon.Plus}
          color={isHovered ? colors.blue.hover : colors.blue.interactive}
          size={16}
        />
      )}
    </Button>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
TagEmojiButton.propTypes = {
  tags: PropTypes.array.isRequired,
  numberOfVisibleSelections: PropTypes.number,
  upsertTagModal: PropTypes.object.isRequired,
};

TagEmojiButton.defaultProps = {
  numberOfVisibleSelections: 2,
};

export default TagEmojiButton;
