// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({tripId, status}: any) => ({
  tripId,
  status,
});

const edit = withFragment(
  (trip) => ({
    tripId: (trip as any).id,
    status: (trip as any).status,
  }),
  gql`
    fragment TripStatusForm_edit on Trip {
      id
      status
    }
  `,
);

const toForm = ({tripId, status}: any) => ({
  tripId,
  status,
});

const toMutation = ({tripId, status}: any) => ({
  tripId,
  status,
});

const TripStatusForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default TripStatusForm;
