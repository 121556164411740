// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import InvoiceCustomerDocumentPage from 'modules/Customer/Invoice/components/InvoiceCustomerDocumentPage';
import InvoiceNotFoundPage from 'modules/Customer/Invoice/components/InvoiceNotFoundPage';

const InvoiceViewPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(InvoiceViewPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      invoiceUuid: params.invoiceUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        if (!data.invoiceByUuid) {
          return <InvoiceNotFoundPage />;
        }
        return (
          <InvoiceCustomerDocumentPage invoice={data.invoiceByUuid} documentTitle={'Invoice'} />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceViewPage.query = gql`
  ${InvoiceCustomerDocumentPage.fragment}
  query InvoiceViewPage($invoiceUuid: String!) {
    ${gql.query}
    invoiceByUuid(invoiceUuid: $invoiceUuid) {
      id
      ...InvoiceCustomerDocumentPage
    }
  }
`;

export default InvoiceViewPage;
