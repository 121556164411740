// Libararies
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StorageContainersPageContent from 'modules/Storage/components/StorageContainersPageContent';
import StoragePageHeader from 'modules/Storage/components/StoragePageHeader';

const Container = Styled.View`
  flex: 1;
`;

const StorageContainersPage = () => {
  const {loading, data} = useQuery(StorageContainersPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={'storage'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  const organization = data.viewer.viewingOrganization;

  return (
    <SidebarPageV2 selected={'storage'}>
      <Container>
        <StoragePageHeader />
        <StorageContainersPageContent organization={organization} />
      </Container>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageContainersPage.query = gql`
  ${StorageContainersPageContent.fragment}
  
  query StorageContainersPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...StorageContainersPageContent
      }
    }
  }
`;

export default StorageContainersPage;
