// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// Components
import {SplitPage, SplitPhoto} from 'modules/App/components';

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Content = Styled.View`
  flex: 1;
  padding-top: 40px;
  padding-horizontal: ${(props) => (props.desktop ? 100 : 50)}px;
`;

const Title = Styled.H2`
  margin-top: 40px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const NotFoundPage = () => {
  const responsive = useResponsive();

  return (
    <SplitPage split={<SplitPhoto source={require('./assets/not-found.jpeg')} />}>
      <Container {...responsive}>
        <Content {...responsive}>
          <Title>Page Not Found</Title>
        </Content>
      </Container>
    </SplitPage>
  );
};

export default NotFoundPage;
