// Libraries
import _ from 'lodash';

const _new = ({ids, property, intValue}: any) => ({
  ids,
  property,
  intValue,
});

const toForm = ({ids, property, intValue}: any) => ({
  ids,
  property,
  intValue,
});

const toMutation = ({ids, property, intValue}: any) => ({
  ids: ids.map((id: any) => _.toNumber(id)),
  property,
  intValue,
});

const BulkUpdateIntPropertyForm = {
  new: _new,
  toForm,
  toMutation,
};

export default BulkUpdateIntPropertyForm;
