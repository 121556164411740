// TODO(mark): This is mostly copied from the PayMoveWithCreditCardStripeForm from
// the crew app with minor changes to use saved cards only.

// TODO(peter): This form needs to use the useForm hook design pattern.

// Libraries
import React from 'react';

// Supermove
import {Form} from '@supermove/components';
import {StripeForm} from '@supermove/payments';
import {Currency, setFormServerErrors} from '@supermove/utils';

// Components
import {BeginPaymentMutation, FinalizePaymentMutation} from 'modules/Payment/components';

/**
 * The `handleSubmit` that is passed to the `children` will set off a set of 3 API calls.
 *
 *    1. BeginPaymentMutation - this will create the PaymentIntent on our server
 *       and pass the Payment object back with the Stripe `clientSecret`.
 *    2. We take the `clientSecret` and send it to Stripe to confirm the PaymentIntent.
 *    3. Once the PaymentIntent is confirmed with Stripe, we submit the FinalizeMoveMutation
 *       to tell our server to reconcile the transactions and store in our DB.
 *
 */
const PayBillWithAccountStripeForm = ({
  jobId,
  billId,
  customerId,
  stripeAccountId,
  partialAmount,
  initialPaymentMethod,
  onSuccess,
  onError,
  children,
}) => (
  <StripeForm stripeAccountId={stripeAccountId}>
    {({stripe}) => (
      <Form
        initialValues={{
          // Private
          loading: false,

          // 1. BeginPayment
          billId,
          customerId,
          stripeAccountId,
          paymentMethod: initialPaymentMethod,
          partialAmount,
          name: '',
          description: '',

          // 2. Stripe Credit Card info
          stripePaymentMethodId: undefined,

          // 3. FinalizePayment
          jobId,
          paymentId: undefined,
          paymentMethodToken: '', // Only needed for PayEngine payments
        }}
        onSubmit={(values) => {}}
      >
        {(form) => {
          const handlePaymentError = (error) => {
            // Credit card is not valid, we need to update the UI.
            form.setValues({...form.values, loading: false});
            form.setFieldTouched('paymentOptionIndex', true);
            // Set the 'paymentMethod' field on errors to match the behavior of setFormServerErrors below
            form.setFieldError('paymentMethod', error.message);
          };

          return (
            <FinalizePaymentMutation
              jobId={form.values.jobId}
              paymentId={form.values.paymentId}
              paymentMethodToken={form.values.paymentMethodToken}
              onSuccess={onSuccess}
              onError={({error, errors, apolloError}) => {
                if (apolloError) {
                  // Most likely cause is that server-side API call to PayEngine threw an exception
                  console.error(`FinalizePayment mutation failed: `, apolloError);
                  handlePaymentError(new Error('Unable to process this credit card.'));
                }
                onError({error, errors, apolloError});
              }}
            >
              {({loading, handleSubmit}) => (
                <BeginPaymentMutation
                  billId={form.values.billId}
                  customerId={form.values.customerId}
                  name={form.values.name}
                  description={form.values.description}
                  paymentMethod={form.values.paymentMethod}
                  partialAmount={Currency.toMutation(form.values.partialAmount)}
                  onSuccess={async (payment) => {
                    const handleStripe = async () => {
                      try {
                        await stripe.setAccountId({id: form.values.stripeAccountId});
                        const result = await stripe.confirmCardPayment({
                          clientSecret: payment.stripePaymentClientSecret,
                          paymentMethodId: form.values.stripePaymentMethodId,
                        });

                        if (result.error) {
                          handlePaymentError(result.error);
                        } else {
                          // If we successfully confirm the PaymentIntent with Stripe, then we can
                          // finalize the move payment with the last mutation.
                          handleSubmit();
                        }
                      } catch (error) {
                        handlePaymentError(error);
                      }
                    };

                    // Save the paymentId to use in the 3rd step.
                    form.setFieldValue('paymentId', payment.id);

                    if (form.values.paymentMethod === 'STRIPE_CREDIT_CARD') {
                      await handleStripe();
                    } else {
                      handleSubmit();
                    }
                  }}
                  onError={async (errors) => {
                    form.setFieldValue('loading', false);
                    await setFormServerErrors({form, errors});
                    form.setFieldTouched('paymentOptionIndex', true);
                    onError(errors);
                  }}
                >
                  {({loading, handleSubmit}) =>
                    children({
                      ...form,
                      handleSubmit: (...args) => {
                        form.setFieldValue('loading', true);
                        handleSubmit(...args);
                      },
                    })
                  }
                </BeginPaymentMutation>
              )}
            </FinalizePaymentMutation>
          );
        }}
      </Form>
    )}
  </StripeForm>
);

export default PayBillWithAccountStripeForm;
