// Libararies
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

import Button from '@shared/design/components/Button';
import SearchBar from '@shared/design/components/SearchBar';
import OrganizationSettingsCustomerFlowsFilter from 'modules/Organization/Settings/CustomerFlows/components/OrganizationSettingsCustomerFlowsFilter';
import {OrganizationSettingsCustomerFlowsUrlFilters} from 'modules/Organization/Settings/CustomerFlows/components/OrganizationSettingsCustomerFlowsFiltersTypes';

const PageHeaderText = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Row = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const Column = Styled.View`
`;

const OrganizationSettingsCustomerFlowsPageHeader = ({
  urlFilters,
}: {
  urlFilters: OrganizationSettingsCustomerFlowsUrlFilters;
}) => {
  const responsive = useResponsive();
  return (
    <Column>
      <Row>
        <PageHeaderText responsive={responsive}>Customer Flows</PageHeaderText>
        <Button isResponsive iconLeft={Icon.Plus} text={'Create Customer Flow'} />
      </Row>
      <Space height={24} />
      <Row>
        <SearchBar
          containerStyle={{flex: 1}}
          style={{width: '100%'}}
          defaultValue={''}
          placeholder='Search by flow name, description, project types'
          onChangeText={() => {}}
          isClearable
          isResponsive
        />
        <Space width={8} />
        <OrganizationSettingsCustomerFlowsFilter
          urlFilters={urlFilters}
          handleApply={urlFilters.handleUpdate}
        />
      </Row>
    </Column>
  );
};
export default OrganizationSettingsCustomerFlowsPageHeader;
