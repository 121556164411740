// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {colors} from '@supermove/styles';

// App
import Button, {ButtonProps} from '@shared/design/components/Button';

const TertiaryButtonText = ({
  isDisabled,
  color,
  style,
  children,
}: {
  isDisabled?: boolean;
  color?: string;
  style?: object;
  children: React.ReactNode;
}) => {
  return (
    <Button.Text color={isDisabled ? colors.gray.border : color} style={style}>
      {children}
    </Button.Text>
  );
};

export interface TertiaryButtonProps extends ButtonProps {
  isDisabled: boolean;
  isSubmitting: boolean;
  textColor: string;
  style: object;
}

const TertiaryButton = ({
  children,
  isDisabled,
  isSubmitting,
  textColor,
  style,
  ...props
}: TertiaryButtonProps) => {
  return (
    <Button
      {...props}
      isSubmitting={isSubmitting}
      isWidthOfContainer={false}
      isDisabled={isDisabled}
      textColor={isDisabled ? colors.gray.border : textColor}
      style={{
        paddingHorizontal: 0,
        paddingVertical: 0,
        backgroundColor: 'transparent',
        height: null,
        ...style,
      }}
    >
      {children}
    </Button>
  );
};

TertiaryButton.Text = TertiaryButtonText;

// --------------------------------------------------
// Props
// --------------------------------------------------
TertiaryButton.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  textColor: PropTypes.string,
  onPress: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  style: PropTypes.object,
};

TertiaryButton.defaultProps = {
  color: colors.white,
  width: null,
  textColor: colors.blue.interactive,
  onPress: () => {},
  isDisabled: false,
  isSubmitting: false,
  style: {},
};

TertiaryButtonText.propTypes = {
  color: PropTypes.string,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
};

TertiaryButtonText.defaultProps = {
  color: colors.blue.interactive,
  isDisabled: false,
  style: null,
};

export default TertiaryButton;
