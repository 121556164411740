// App
import VariableKind from '@shared/modules/Billing/enums/VariableKind';

const CUSTOM = 'CUSTOM';

const START_VIRTUAL_WALKTHROUGH = 'START_VIRTUAL_WALKTHROUGH';
const VIRTUAL_WALKTHROUGH_COMPLETED = 'VIRTUAL_WALKTHROUGH_COMPLETED';
const TRACKING_LINK_CUSTOMER = 'TRACKING_LINK_CUSTOMER';
const CREW_ARRIVED_CUSTOMER = 'CREW_ARRIVED_CUSTOMER';
const SET_SCHEDULE_REMINDER_EMPLOYEE = 'SET_SCHEDULE_REMINDER_EMPLOYEE';
const JOB_UNASSIGNED_EMPLOYEE = 'JOB_UNASSIGNED_EMPLOYEE';
const JOB_CANCELLED_EMPLOYEE = 'JOB_CANCELLED_EMPLOYEE';
const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
const JOB_ASSIGNED_EMPLOYEE = 'JOB_ASSIGNED_EMPLOYEE';
const JOB_RESCHEDULED_EMPLOYEE = 'JOB_RESCHEDULED_EMPLOYEE';

const ATTRIBUTES = {
  START_VIRTUAL_WALKTHROUGH: {
    value: START_VIRTUAL_WALKTHROUGH,
    variableKind: [VariableKind.PROJECT],
    description:
      "This SMS is sent automatically when a user clicks on 'Virtual Walkthrough' > 'Send Link to Customer'",
  },
  VIRTUAL_WALKTHROUGH_COMPLETED: {
    value: VIRTUAL_WALKTHROUGH_COMPLETED,
    variableKind: [VariableKind.PROJECT],
    description:
      'This SMS is sent automatically after a customer completes the virtual walkthrough.',
  },
  TRACKING_LINK_CUSTOMER: {
    value: TRACKING_LINK_CUSTOMER,
    variableKind: [VariableKind.PROJECT, VariableKind.JOB],
    description: 'This SMS is sent when the crew member confirms the crew members on a move.',
  },
  CREW_ARRIVED_CUSTOMER: {
    value: CREW_ARRIVED_CUSTOMER,
    variableKind: [VariableKind.PROJECT, VariableKind.JOB],
    description:
      "This SMS is sent automatically when a mover clicks on 'I have arrived' on the Crew app.",
  },
  SET_SCHEDULE_REMINDER_EMPLOYEE: {
    value: SET_SCHEDULE_REMINDER_EMPLOYEE,
    variableKind: [],
    description: "This SMS is sent when a user hasn't set their schedule for 2 weeks.",
  },
  JOB_UNASSIGNED_EMPLOYEE: {
    value: JOB_UNASSIGNED_EMPLOYEE,
    variableKind: [],
    description: 'This SMS is sent automatically when a mover is unassigned from a job.',
  },
  JOB_CANCELLED_EMPLOYEE: {
    value: JOB_CANCELLED_EMPLOYEE,
    variableKind: [],
    description:
      "This SMS is sent automatically to all movers on a job when a job is marked as 'Cancelled'.",
  },
  CREATE_EMPLOYEE: {
    value: CREATE_EMPLOYEE,
    variableKind: [],
    description:
      'This SMS is sent automatically when a new mover is added to the Supermove system.',
  },
  UPDATE_EMPLOYEE: {
    value: UPDATE_EMPLOYEE,
    variableKind: [],
    description:
      'This SMS is sent automatically when a phone number is updated in the Supermove system.',
  },
  JOB_ASSIGNED_EMPLOYEE: {
    value: JOB_ASSIGNED_EMPLOYEE,
    variableKind: [VariableKind.PROJECT, VariableKind.JOB],
    description:
      "This SMS is sent automatically when a mover is assigned to a job and a user clicks on 'Sent text' on the dispatch section.",
  },
  JOB_RESCHEDULED_EMPLOYEE: {
    value: JOB_RESCHEDULED_EMPLOYEE,
    variableKind: [VariableKind.PROJECT, VariableKind.JOB],
    description:
      'This SMS is sent automatically to all movers confirmed to a job when a job has been rescheduled.',
  },
};

export default {
  CUSTOM,
  START_VIRTUAL_WALKTHROUGH,
  VIRTUAL_WALKTHROUGH_COMPLETED,
  TRACKING_LINK_CUSTOMER,
  CREW_ARRIVED_CUSTOMER,

  ATTRIBUTES,
};
