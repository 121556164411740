// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import JobTypeForm from '@shared/modules/Job/forms/JobTypeForm';
import useCreateJobTypeMutation from '@shared/modules/Job/hooks/useCreateJobTypeMutation';
import JobTypeModalFields from 'modules/Organization/Settings/JobTypes/components/JobTypeModalFields';

const CreateJobTypeModal = ({isOpen, handleClose, refetch, projectType}) => {
  const jobTypeForm = JobTypeForm.new({
    projectTypeId: projectType.id,
    organizationId: projectType.organizationId,
  });
  const {form, handleSubmit, submitting} = useCreateJobTypeMutation({
    jobTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
      <SmallModal.HeaderText>{'Create Job Type'}</SmallModal.HeaderText>
      <Space height={16} />
      <JobTypeModalFields form={form} projectType={projectType} />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={submitting} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          color={colors.blue.interactive}
        >
          Create
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateJobTypeModal.fragment = gql`
  ${JobTypeModalFields.fragment}

  fragment CreateJobTypeModal on ProjectType {
    id
    organizationId
    ...JobTypeModalFields
  }
`;
export default CreateJobTypeModal;
