// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import BillRuleTypeForm from '@shared/modules/Billing/forms/BillRuleTypeForm';
import useUpdateBillRuleTypeMutation from '@shared/modules/Billing/hooks/useUpdateBillRuleTypeMutation';
import SystemMessage from 'modules/App/components/SystemMessage';
import BillRuleTypeFieldsV1 from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypeFieldsV1';

const BillTemplateEditBillRuleTypeModal = ({form, field, isOpen, handleClose}) => {
  const billRuleTypeForm = _.get(form.values, field);
  const {
    form: updateBillRuleTypeForm,
    submitting,
    handleSubmit,
  } = useUpdateBillRuleTypeMutation({
    billRuleTypeForm,
    onSuccess: () => {
      // Since the billRuleType is dynamically updated on the form (ie not refetched)
      // we want to mimick toMutation on the form. We properly cast value and only set
      // stringValue if the type isString. Then, if we re-open the edit modal, it will
      // send the form through toForm.
      const updatedBillRuleTypeForm = updateBillRuleTypeForm.values.billRuleTypeForm;
      const {value, valueFormulaId, kind} = updatedBillRuleTypeForm;
      form.setFieldValue(field, {
        ...updatedBillRuleTypeForm,
        value: BillRuleTypeForm.getMutationValue({valueFormulaId, kind, value}),
        stringValue: BillRuleTypeForm.getIsString({kind}) ? value : '',
      });
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.HeaderText>Edit Bill Rule</SmallModal.HeaderText>
      <Space height={20} />
      <SystemMessage isWarning containerStyle={{margin: 0}}>
        {`WARNING: Updating this bill rule will update it everywhere it is used in this ` +
          `billing library. You may want to create a new bill rule to use instead.`}
      </SystemMessage>
      <Space height={20} />
      <BillRuleTypeFieldsV1 form={updateBillRuleTypeForm} field={'billRuleTypeForm'} />
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose} isDisabled={submitting}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          onPress={handleSubmit}
          color={colors.blue.interactive}
          isSubmitting={submitting}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default BillTemplateEditBillRuleTypeModal;
