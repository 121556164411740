// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const DownloadDocumentMutation = ({documentId, onSuccess, onError, children}) => (
  <Mutation
    variables={{
      documentId,
    }}
    mutation={DownloadDocumentMutation.mutation}
    onCompleted={({downloadDocument: {document, url, errors}}) => {
      if (url) {
        onSuccess(url);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
DownloadDocumentMutation.mutation = gql`
  mutation DownloadDocumentMutation(
    $documentId: Int!,
  ) {
    downloadDocument(
      documentId: $documentId,
    ) {
      ${gql.errors}
      document {
        id
      }
      url
    }
  }
`;

export default DownloadDocumentMutation;
