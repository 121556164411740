// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove

// Components
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

import {Navigation} from 'modules/App/components';
import {getSidebarItems} from 'modules/App/components/Sidebar/data/SidebarData';

import LogOutModal from '../LogOutModal';
import SidebarAvatar from '../SidebarAvatar';

import SidebarItemV2 from './SidebarItemV2';

const Wrapper = Styled.View`
  flex: 1;
  justify-content: space-between;
  width: 240px;
`;

const Container = Styled.View`
  flex: 1;
  align-items: flex-end;
`;

const Items = Styled.View`
  align-items: flex-end;
`;

const Item = Styled.View`
  width: 100%;
`;

const User = Styled.View`
  width: 100%;
`;

const Touchable = Styled.Touchable`
`;

const SidebarItems = ({selectedItem, notificationCounts, viewer, onSidebarClose}) => {
  const responsive = useResponsive();

  return (
    <Wrapper>
      <Container>
        <Navigation.CloseButton responsive={responsive} onPress={onSidebarClose} />
        <ScrollView style={{alignSelf: 'stretch'}}>
          <Items>
            {getSidebarItems({viewer, responsive}).map(({key, to, name, source}) => (
              <Item key={key} {...responsive}>
                <SidebarItemV2
                  isSelected={key === selectedItem}
                  count={_.get(notificationCounts, key, 0)}
                  to={to}
                  name={name}
                  source={source}
                  responsive={responsive}
                />
              </Item>
            ))}
          </Items>
        </ScrollView>
      </Container>
      <User>
        {viewer.viewingOrganization && (
          <LogOutModal
            trigger={({handleOpen}) => (
              <Touchable onPress={handleOpen}>
                <SidebarAvatar size={40} user={viewer} />
              </Touchable>
            )}
          />
        )}
      </User>
    </Wrapper>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SidebarItems.propTypes = {
  selectedItem: PropTypes.string.isRequired,
  notificationCounts: PropTypes.object,
  viewer: PropTypes.object.isRequired,
  onSidebarClose: PropTypes.func.isRequired,
};

SidebarItems.defaultProps = {
  notificationCounts: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SidebarItems.fragment = gql`
  ${SidebarAvatar.fragment}
  ${getSidebarItems.fragment}

  fragment SidebarItems_viewer on User {
    id
    ...SidebarAvatar
    ...User_getSidebarItems
  }
`;

export default SidebarItems;
