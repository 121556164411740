// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

type LineViewProps = {
  isVertical: boolean;
  weight: number;
  color: string;
};

const LineView = Styled.View`
  width: ${({isVertical, weight}: LineViewProps) => (isVertical ? `${weight}px` : '100%')};
  height: ${({isVertical, weight}: LineViewProps) => (isVertical ? '100%' : `${weight}px`)};
  background-color: ${({color}: LineViewProps) => color};
`;

const Line = ({
  isVertical = false,
  color = colors.gray.border,
  weight = 1,
}: {
  isVertical?: boolean;
  color?: string;
  weight?: number;
}) => {
  return <LineView isVertical={isVertical} weight={weight} color={color} />;
};

export default Line;
