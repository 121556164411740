// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  ResponsiveType,
  useNavigationDOM,
  useQuery,
  useResponsive,
  useToggle,
} from '@supermove/hooks';
import {ProjectTypeModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import InvoicePaymentTerm from '@shared/modules/Billing/enums/InvoicePaymentTerm';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import SalesTaxTemplateForm from '@shared/modules/Billing/forms/SalesTaxTemplateForm';
import useUpsertProjectTypeSalesTaxTemplateMutation from '@shared/modules/Billing/hooks/useUpsertProjectTypeSalesTaxTemplateMutation';
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';
import ProjectTypeBillingSettingsForm from '@shared/modules/Project/forms/ProjectTypeBillingSettingsForm';
import useUpdateProjectTypeAccountingSettingsMutation, {
  UpdateProjectTypeAccountingSettingsMutationFormType,
} from '@shared/modules/Project/hooks/useUpdateProjectTypeAccountingSettingsMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import AccountingBillingSettingsPanel from 'modules/Organization/Settings/ProjectTypes/components/AccountingBillingSettingsPanel';
import ProjectTypeSalesTaxTemplatePanel from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSalesTaxTemplatePanel';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';

const Container = Styled.View`
`;

const View = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LinkTextContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link};
`;

const Label = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const PageHeading = Styled.Text`
  ${Typography.Responsive.PageHeading};
`;

const PageDescription = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.secondary};
`;

interface ShowInvoiceSettingsPanelProps {
  projectType: ProjectTypeModel;
}

const ShowInvoiceSettingsPanel = ({projectType}: ShowInvoiceSettingsPanelProps) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <Row>
        <View>
          <EditPanel.LabelText responsive={responsive}>
            Automatically finalize invoices
          </EditPanel.LabelText>
          <Space height={8} />
          <EditPanel.Text responsive={responsive}>
            {projectType.isEnabledAutomaticallyFinalizeInvoices ? 'Yes' : 'No'}
          </EditPanel.Text>
        </View>
      </Row>
      <Space height={24} />
      <Row>
        <View>
          <EditPanel.LabelText responsive={responsive}>Invoice Terms</EditPanel.LabelText>
          <Space height={8} />
          <EditPanel.Text responsive={responsive}>
            {InvoicePaymentTerm.getDisplayName(projectType.defaultInvoicePaymentTerm)}
          </EditPanel.Text>
        </View>
      </Row>
      <Space height={24} />
      <Row>
        <View>
          <EditPanel.LabelText responsive={responsive}>Invoice Document</EditPanel.LabelText>
          <Space height={8} />
          {projectType.defaultInvoiceDocumentTemplate ? (
            <a
              href={`/settings/documents/document-templates/${projectType.defaultInvoiceDocumentTemplate.uuid}`}
              target={'_blank'}
            >
              <LinkText responsive={responsive}>
                {projectType.defaultInvoiceDocumentTemplate.name}
              </LinkText>
            </a>
          ) : (
            <EditPanel.Text responsive={responsive}>None selected</EditPanel.Text>
          )}
        </View>
      </Row>
    </React.Fragment>
  );
};

interface EditInvoiceSettingsPanelProps {
  projectType: ProjectTypeModel;
  form: UpdateProjectTypeAccountingSettingsMutationFormType;
}

const EditInvoiceSettingsPanel = ({form, projectType}: EditInvoiceSettingsPanelProps) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <SwitchField
        form={form}
        field={'projectTypeBillingSettingsForm.isEnabledAutomaticallyFinalizeInvoices'}
        labelRight={'Automatically finalize invoices'}
        isResponsive
        hint={`When enabled, invoices will automatically be locked and finalized when the final job is complete and remaining balance across all invoices is $0. When disabled, invoices must be manually finalized.`}
      />
      <Space height={24} />
      <Row>
        <View>
          <Row>
            <FieldInput
              {...form}
              index={0}
              label={'Invoice Terms'}
              name={`projectTypeBillingSettingsForm.defaultInvoicePaymentTerm`}
              component={DropdownInput}
              input={{
                isPortaled: true,
                options: InvoicePaymentTerm.DROPDOWN_OPTIONS,
                setFieldValue: form.setFieldValue,
                style: {width: responsive.desktop ? '360px' : '100%'},
              }}
              isResponsive
              style={{flex: 1}}
            />
          </Row>
          <Space height={8} />
          <EditPanel.Text responsive={responsive} style={{color: colors.gray.secondary}}>
            Set the invoice terms for this project type. You can override this default when
            previewing and sending an invoice in the project.
          </EditPanel.Text>
        </View>
      </Row>
      <Space height={24} />
      <Row>
        <View>
          <Row>
            <FieldInput
              {...form}
              index={1}
              label={'Invoice Document'}
              name={`projectTypeBillingSettingsForm.defaultInvoiceDocumentTemplateId`}
              component={DropdownInput}
              input={{
                isPortaled: true,
                options: projectType.organization.documentTemplatesByCategory.map(
                  (documentTemplate) => {
                    return {
                      value: _.toNumber(documentTemplate.id),
                      label: documentTemplate.name,
                    };
                  },
                ),
                setFieldValue: form.setFieldValue,
                style: {width: responsive.desktop ? '360px' : '100%'},
              }}
              isResponsive
              style={{flex: 1}}
            />
          </Row>
          <Space height={8} />
          <EditPanel.Text responsive={responsive} style={{color: colors.gray.secondary}}>
            Select the default invoice document that will be sent to the customer. You can override
            the default when previewing and sending an invoice in the project.
          </EditPanel.Text>
        </View>
      </Row>
    </React.Fragment>
  );
};

interface ShowEmailSettingsPanelProps {
  projectType: ProjectTypeModel;
  responsive: ResponsiveType;
}

const ShowEmailSettingsPanel = ({projectType, responsive}: ShowEmailSettingsPanelProps) => {
  const {
    defaultInvoiceEmailTemplate,
    defaultInvoiceReceiptEmailTemplate,
    shouldAttachDocumentsInInvoiceEmail,
    shouldAttachDocumentsInReceiptEmail,
  } = projectType;
  return (
    <React.Fragment>
      <Row>
        <View>
          <Label responsive={responsive}>Invoice Email</Label>
          <Space height={8} />
          {defaultInvoiceEmailTemplate ? (
            <a href={'/settings/communication/email-templates'} target={'_blank'}>
              <LinkTextContainer>
                <Icon source={Icon.ExternalLinkAlt} color={colors.blue.interactive} size={12} />
                <Space width={4} />
                <LinkText responsive={responsive}>{defaultInvoiceEmailTemplate.name}</LinkText>
              </LinkTextContainer>
            </a>
          ) : (
            <EditPanel.Text responsive={responsive}>None</EditPanel.Text>
          )}
        </View>
        <View>
          <Label responsive={responsive}>Include PDFs in Invoice Emails</Label>
          <Space height={8} />
          <EditPanel.Text responsive={responsive}>
            {shouldAttachDocumentsInInvoiceEmail ? 'Yes' : 'No'}
          </EditPanel.Text>
        </View>
      </Row>
      <Space height={24} />
      <Row>
        <View>
          <Label responsive={responsive}>Receipt Email</Label>
          <Space height={8} />
          {defaultInvoiceReceiptEmailTemplate ? (
            <a href={'/settings/communication/email-templates'} target={'_blank'}>
              <LinkTextContainer>
                <Icon source={Icon.ExternalLinkAlt} color={colors.blue.interactive} size={12} />
                <Space width={4} />
                <LinkText responsive={responsive}>
                  {defaultInvoiceReceiptEmailTemplate.name}
                </LinkText>
              </LinkTextContainer>
            </a>
          ) : (
            <EditPanel.Text responsive={responsive}>None</EditPanel.Text>
          )}
        </View>
        <View>
          <Label responsive={responsive}>Include PDFs in Receipt Emails</Label>
          <Space height={8} />
          <EditPanel.Text responsive={responsive}>
            {shouldAttachDocumentsInReceiptEmail ? 'Yes' : 'No'}
          </EditPanel.Text>
        </View>
      </Row>
    </React.Fragment>
  );
};

interface EditEmailSettingsPanelProps {
  form: UpdateProjectTypeAccountingSettingsMutationFormType;
  projectType: ProjectTypeModel;
}

const EditEmailSettingsPanel = ({form, projectType}: EditEmailSettingsPanelProps) => {
  const responsive = useResponsive();
  const {invoiceEmailTemplates, invoiceReceiptEmailTemplates} = projectType.organization;

  return (
    <React.Fragment>
      <Row>
        <View>
          <Row>
            <FieldInput
              {...form}
              index={0}
              label={'Invoice Email'}
              isRequired
              name={`projectTypeBillingSettingsForm.defaultInvoiceEmailTemplateId`}
              component={DropdownInput}
              input={{
                isPortaled: true,
                options: _.isEmpty(invoiceEmailTemplates)
                  ? []
                  : invoiceEmailTemplates.map((emailTemplate) => {
                      return {
                        value: _.toNumber(emailTemplate.id),
                        label: emailTemplate.name,
                      };
                    }),
                setFieldValue: form.setFieldValue,
                style: {width: responsive.desktop ? '360px' : '100%'},
              }}
              isResponsive
              style={{flex: 1}}
            />
          </Row>
          <Space height={8} />
          <EditPanel.Text responsive={responsive} style={{color: colors.gray.secondary}}>
            Select the default invoice email that will be sent to the customer. You can override the
            default when previewing and sending an invoice in the project.
          </EditPanel.Text>
        </View>
      </Row>
      <Space height={16} />
      <SwitchField
        form={form}
        field={'projectTypeBillingSettingsForm.shouldAttachDocumentsInInvoiceEmail'}
        isResponsive
        labelRight={'Include PDFs in Invoice Emails'}
        hint={
          'When enabled, all emails will automatically attach the most recent documents as PDFs to the invoice email sent to the customer.'
        }
      />
      <Space height={24} />
      <Row>
        <View>
          <SwitchField
            form={form}
            field={'projectTypeBillingSettingsForm.isReceiptEmailEnabled'}
            onChangeValue={(switchValue) => {
              if (!switchValue) {
                form.setFieldValue(
                  'projectTypeBillingSettingsForm.defaultInvoiceReceiptEmailTemplateId',
                  null,
                );
              }
            }}
            labelRight={'Automatically Send Receipt After a Full Payment Is Made'}
            isResponsive
          />
          <Space height={16} />
          {_.get(form.values, 'projectTypeBillingSettingsForm.isReceiptEmailEnabled') && (
            <React.Fragment>
              <Row>
                <FieldInput
                  {...form}
                  index={1}
                  label={'Receipt Email'}
                  isRequired
                  name={'projectTypeBillingSettingsForm.defaultInvoiceReceiptEmailTemplateId'}
                  component={DropdownInput}
                  input={{
                    isPortaled: true,
                    options: _.isEmpty(invoiceReceiptEmailTemplates)
                      ? []
                      : invoiceReceiptEmailTemplates.map((receiptEmailTemplate) => {
                          return {
                            value: _.toNumber(receiptEmailTemplate.id),
                            label: receiptEmailTemplate.name,
                          };
                        }),
                    setFieldValue: form.setFieldValue,
                    style: {width: responsive.desktop ? '360px' : '100%'},
                  }}
                  isResponsive
                  style={{flex: 1}}
                />
              </Row>
              <Space height={8} />
              <EditPanel.Text responsive={responsive} style={{color: colors.gray.secondary}}>
                Select the default receipt email that will be sent to the customer. You can override
                the default when previewing and sending an invoice in the project.
              </EditPanel.Text>
            </React.Fragment>
          )}
        </View>
      </Row>
      {form.values.projectTypeBillingSettingsForm.isReceiptEmailEnabled && (
        <React.Fragment>
          <Space height={16} />
          <SwitchField
            form={form}
            field={'projectTypeBillingSettingsForm.shouldAttachDocumentsInReceiptEmail'}
            labelRight={'Include PDFs in Receipt Emails'}
            hint={
              'When enabled, all emails will automatically attach the most recent documents as PDFs to the receipt email sent to the customer.'
            }
            isResponsive
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

interface AccountSettingsSectionsProps {
  projectType: ProjectTypeModel;
  index: number;
  refetch: () => void;
}

const AccountingSettingsSections = ({
  projectType,
  index,
  refetch,
}: AccountSettingsSectionsProps) => {
  const editInvoiceSettingsToggle = useToggle();
  const editEmailSettingsToggle = useToggle();

  const projectTypeBillingSettingsForm = ProjectTypeBillingSettingsForm.edit(projectType);
  const {form, submitting, handleSubmit} = useUpdateProjectTypeAccountingSettingsMutation({
    projectTypeBillingSettingsForm,
    onSuccess: () => {
      editInvoiceSettingsToggle.handleToggleOff();
      editEmailSettingsToggle.handleToggleOff();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <React.Fragment>
      <EditPanel
        index={index}
        bodyComponentProps={{projectType}}
        editBodyComponentProps={{form, projectType}}
        BodyComponent={ShowInvoiceSettingsPanel}
        EditBodyComponent={EditInvoiceSettingsPanel}
        title={'Invoices'}
        handleSave={handleSubmit}
        isSubmitting={submitting}
        handleCancel={form.handleReset}
        isEditing={editInvoiceSettingsToggle.isOn}
        handleEdit={editInvoiceSettingsToggle.handleToggleOn}
        handleClose={editInvoiceSettingsToggle.handleToggleOff}
      />
      <Space height={24} />
      <EditPanel
        index={index + 1}
        bodyComponentProps={{projectType}}
        editBodyComponentProps={{form, projectType}}
        BodyComponent={ShowEmailSettingsPanel}
        EditBodyComponent={EditEmailSettingsPanel}
        title={'Emails'}
        handleSave={handleSubmit}
        isSubmitting={submitting}
        handleCancel={form.handleReset}
        isEditing={editEmailSettingsToggle.isOn}
        handleEdit={editEmailSettingsToggle.handleToggleOn}
        handleClose={editEmailSettingsToggle.handleToggleOff}
      />
    </React.Fragment>
  );
};

interface SalesTaxSectionProps {
  projectType: ProjectTypeModel;
  index: number;
  refetch: () => void;
}

const SalesTaxSection = ({projectType, index, refetch}: SalesTaxSectionProps) => {
  const editSalesTaxToggle = useToggle();
  const {organization} = projectType;
  const salesTaxTemplate = projectType.salesTaxTemplate || organization.settings.salesTaxTemplate;
  const salesTaxTemplateForm = SalesTaxTemplateForm.edit(salesTaxTemplate);
  const {form, submitting, handleSubmit} = useUpsertProjectTypeSalesTaxTemplateMutation({
    salesTaxTemplateForm,
    onSuccess: () => {
      refetch();
      editSalesTaxToggle.handleToggleOff();
    },
    onError: (errors: unknown) => {
      console.log({errors});
    },
  });
  const variableOptions = organization.variables
    .filter((variable) =>
      _.includes([VariableFormat.PERCENT, VariableFormat.DROPDOWN_PERCENT], variable.format),
    )
    .map((variable) => ({
      label: variable.name,
      value: variable.id,
    }));
  const updatedAtDisplay = Datetime.convertToDisplayDatetime(
    salesTaxTemplate.updatedAt,
    'MM/DD/YY [at] h:mm A',
  );
  const handleCancel = form.handleReset;
  const handleClose = editSalesTaxToggle.handleToggleOff;

  return (
    <EditPanel
      index={index}
      HeaderComponent={ProjectTypeSalesTaxTemplatePanel.Header}
      headerComponentProps={{
        projectTypeName: projectType.name,
        isEditing: editSalesTaxToggle.isOn,
        handleEdit: editSalesTaxToggle.handleToggleOn,
        handleCancel,
        handleClose,
      }}
      BodyComponent={ProjectTypeSalesTaxTemplatePanel}
      bodyComponentProps={{salesTaxTemplate}}
      EditBodyComponent={ProjectTypeSalesTaxTemplatePanel.Edit}
      editBodyComponentProps={{
        form,
        field: 'salesTaxTemplateForm',
        variableOptions,
        lastUpdatedDisplay: `${updatedAtDisplay} by ${salesTaxTemplate.updatedBy.fullName}`,
        projectTypeId: projectType.id,
        settingsSalesTaxTemplate: organization.settings.salesTaxTemplate,
      }}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={handleCancel}
      isEditing={editSalesTaxToggle.isOn}
      handleEdit={editSalesTaxToggle.handleToggleOn}
      handleClose={handleClose}
    />
  );
};

interface ProjectTypeAccountingSettingsContentProps {
  projectTypeUuid: string;
}

const ProjectTypeAccountingSettingsContent = ({
  projectTypeUuid,
}: ProjectTypeAccountingSettingsContentProps) => {
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(ProjectTypeAccountingSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeUuid,
      categories: [DocumentTemplateCategory.INVOICE],
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <View>
      <ProjectTypeSettingsPageHeader projectType={data.projectTypeByUuid} />
      <ScrollView
        horizontal
        contentContainerStyle={{backgroundColor: colors.gray.background, flex: 1}}
      >
        <ScrollView>
          <Container style={{paddingHorizontal: responsive.desktop ? 24 : 16}}>
            <Space height={24} />
            <PageHeading responsive={responsive}>Accounting</PageHeading>
            <Space height={16} />
            <PageDescription
              responsive={responsive}
            >{`Manage billing, sales tax, invoice settings, and invoice emails for ${data.projectTypeByUuid.name}.`}</PageDescription>
          </Container>
          <Container style={{paddingHorizontal: responsive.desktop ? 24 : 0}}>
            <Space height={24} />
            <AccountingBillingSettingsPanel
              projectType={data.projectTypeByUuid}
              index={0}
              refetch={refetch}
            />
            <Space height={24} />
            <SalesTaxSection projectType={data.projectTypeByUuid} index={1} refetch={refetch} />
            <Space height={24} />
            <AccountingSettingsSections
              projectType={data.projectTypeByUuid}
              index={2}
              refetch={refetch}
            />
            <Space height={100} />
          </Container>
        </ScrollView>
      </ScrollView>
    </View>
  );
};

const ProjectTypeAccountingSettingsPage = () => {
  const {params} = useNavigationDOM();

  return (
    <SidebarPageV2 selected={'settings'}>
      <ProjectTypeAccountingSettingsContent projectTypeUuid={params.projectTypeUuid} />
    </SidebarPageV2>
  );
};

ProjectTypeAccountingSettingsPage.query = gql`
  ${AccountingBillingSettingsPanel.fragment}
  ${ProjectTypeSettingsPageHeader.fragment}
  ${ProjectTypeBillingSettingsForm.edit.fragment}
  ${ProjectTypeSalesTaxTemplatePanel.fragment}
  ${SalesTaxTemplateForm.edit.fragment}

  fragment ProjectTypeAccountingSettingsPage_SalesTaxTemplate on SalesTaxTemplate {
    id
    updatedAt
    updatedBy {
      id
      fullName
    }
    ...ProjectTypeSalesTaxTemplatePanel
    ...SalesTaxTemplateForm_edit
  }

  query ProjectTypeAccountingSettingsPage(
    $projectTypeUuid: String!,
    $categories: [String]!,
  ) {
    ${gql.query}

    viewer {
      id
      viewingOrganization {
        id
      }
    }

    projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      name
      isEnabledAutomaticallyFinalizeInvoices
      defaultInvoicePaymentTerm
      defaultInvoiceDocumentTemplate {
        id
        name
        uuid
      }
      defaultInvoiceEmailTemplate {
        id
        name
      }
      defaultInvoiceReceiptEmailTemplate {
        id
        name
      }
      organization {
        id
        documentTemplatesByCategory(categories: $categories) {
          id
          name
        }
        invoiceEmailTemplates: emailTemplatesByKind(emailTemplateKinds: ["CUSTOM_INVOICE"]) {
          id
          name
        }
        invoiceReceiptEmailTemplates: emailTemplatesByKind(emailTemplateKinds: ["CUSTOM_INVOICE_RECEIPT"]) {
          id
          name
        }
        settings {
          id
          salesTaxTemplate {
            id
            ...ProjectTypeAccountingSettingsPage_SalesTaxTemplate
            ...ProjectTypeSalesTaxTemplatePanel_SettingsSalesTaxTemplate
          }
        }
        variables {
          id
          name
          format
        }
      }
      salesTaxTemplate {
        id
        ...ProjectTypeAccountingSettingsPage_SalesTaxTemplate
      }
      ...AccountingBillingSettingsPanel
      ...ProjectTypeSettingsPageHeader
      ...ProjectTypeBillingSettingsForm_edit
    }
  }
`;

export default ProjectTypeAccountingSettingsPage;
