// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FileDragInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Panel from '@shared/design/components/Panel';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import OrganizationLogoForm from '@shared/modules/Organization/forms/OrganizationLogoForm';
import useUpdateOrganizationLogoMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationLogoMutation';
import useUploadOrganizationLogoPhoto from '@shared/modules/Organization/hooks/useUploadOrganizationLogoPhoto';
import DocumentHeaderPreview from 'modules/Organization/Settings/Company/components/DocumentHeaderPreview';

const Row = Styled.View`
  flex-direction: row;
  z-index: ${(props) => 100 - props.index};
`;

const ButtonContainer = Styled.View`
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
`;

const TextContainer = Styled.View`
  width: 356px;
`;

const EmptyPhotoContainer = Styled.View`
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.gray.border};
`;

const PhotoCircleContainer = Styled.View`
  width: 70px;
  height: 70px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const ImageCircle = Styled.Image`
  flex: 1;
  border-radius: 8px;
`;

const ActivityIndicator = Styled.Loading`
`;

const CompanyLogoPhoto = ({
  viewer,
  organizationLogoForm,
  onUploadStart,
  onUploadSuccess,
  onUploadError,
  onDelete,
}) => {
  const removeLogoModal = useModal({name: 'Remove Logo Modal'});
  const uploadFileForm = UploadFileForm.new({
    organizationId: viewer.viewingOrganization.id,
    creatorId: viewer.id,
  });

  const {form, handleSubmit, formOrganizationLogo} = useUploadOrganizationLogoPhoto({
    uploadFileForm,
    organizationLogoForm,
    onSuccess: () => {
      onUploadSuccess();
      formOrganizationLogo.resetForm();
    },
    onError: (error) => {
      console.log(error);
      onUploadError();
    },
  });

  const isFileSizeTooLarge = _.get(formOrganizationLogo.errors, 'organizationLogoForm.logoFileId');
  const {isUploadingPhoto} = organizationLogoForm;
  const {logoFile} = viewer.viewingOrganization;
  return (
    <React.Fragment>
      {isFileSizeTooLarge && (
        <React.Fragment>
          <ErrorCallout text={'File size must be less than 500KB.'} />
          <Space height={24} />
        </React.Fragment>
      )}
      <Row index={0}>
        <FileDragInput
          onFilesChange={(files) => {
            const file = files[0];
            form.setFieldValue(`uploadFileForm.requestUploadFileForm.mimetype`, file.type);
            form.setFieldValue(`uploadFileForm.requestUploadFileForm.filename`, file.name);
            form.setFieldValue(`uploadFileForm.file`, file);
            onUploadStart();
            handleSubmit();
          }}
          accept={'image/*'}
          dropzoneOptions={{noKeyboard: true}}
          disabled={isUploadingPhoto}
          style={{flex: 1}}
          // We manually trigger the file picker from handleOpen
          // We disable the ability to click the dropzone so it doesn't trigger twice
          disableClick
        >
          {({handleOpen}) => {
            const dropdownButtonOptions = [
              {
                text: 'Change Logo',
                onPress: handleOpen,
              },
              {
                text: 'Remove Logo',
                onPress: removeLogoModal.handleOpen,
              },
            ];
            return (
              <React.Fragment>
                {isUploadingPhoto ? (
                  <EmptyPhotoContainer>
                    <ActivityIndicator color={colors.gray.tertiary} size={'small'} />
                  </EmptyPhotoContainer>
                ) : logoFile ? (
                  <PhotoCircleContainer>
                    <ImageCircle
                      resizeMode={'contain'}
                      source={{
                        uri: logoFile ? logoFile.downloadUrl : null,
                      }}
                      accessibilityLabel={logoFile ? logoFile.filename : null}
                    />
                  </PhotoCircleContainer>
                ) : (
                  <EmptyPhotoContainer>
                    <Icon source={Icon.Image} size={24} color={colors.gray.tertiary} />
                  </EmptyPhotoContainer>
                )}
                <Space width={24} />
                <TextContainer>
                  <Panel.LabelText>Logo for Document Header</Panel.LabelText>
                  <Space height={8} />
                  <Panel.Text>
                    {'Upload a jpeg or png of your logo to appear on your document headers.'}
                  </Panel.Text>
                </TextContainer>
                <Space width={24} />
                <ButtonContainer>
                  {logoFile ? (
                    <DropdownButton text={'Update Logo'} actions={dropdownButtonOptions} />
                  ) : (
                    <Button onPress={handleOpen} disabled={isUploadingPhoto} text={'Upload Logo'} />
                  )}
                </ButtonContainer>
              </React.Fragment>
            );
          }}
        </FileDragInput>
      </Row>
      <Space height={24} />
      <Row index={1}>
        <DocumentHeaderPreview organization={viewer.viewingOrganization} />
      </Row>
      <DeleteModal
        handleDelete={() => {
          onDelete();
          removeLogoModal.handleClose();
        }}
        handleClose={removeLogoModal.handleClose}
        isOpen={removeLogoModal.isOpen}
        title={'Remove Logo?'}
        subtitle={'This logo will no longer appear on document headers.'}
      />
    </React.Fragment>
  );
};

const CompanyLogoPanel = ({viewer, refetch}) => {
  const organizationLogoForm = OrganizationLogoForm.edit(viewer.viewingOrganization);

  const {form, handleSubmit} = useUpdateOrganizationLogoMutation({
    organizationLogoForm,
    onSuccess: refetch,
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <React.Fragment>
      <Panel width={Panel.WIDTH.DEFAULT}>
        <Panel.Header>
          <Panel.HeaderText>Company Logo</Panel.HeaderText>
        </Panel.Header>
        <Panel.Body>
          <CompanyLogoPhoto
            viewer={viewer}
            organizationLogoForm={form.values.organizationLogoForm}
            onUploadStart={() => {
              form.setFieldValue(`organizationLogoForm.isUploadingPhoto`, true);
            }}
            onUploadSuccess={() => {
              form.setFieldValue(`organizationLogoForm.isUploadingPhoto`, false);
              refetch();
            }}
            onUploadError={() => {
              form.setFieldValue(`organizationLogoForm.isUploadingPhoto`, false);
            }}
            onDelete={() => {
              form.setFieldValue(`organizationLogoForm.logoFileId`, null);
              setTimeout(() => handleSubmit(), 0);
            }}
          />
        </Panel.Body>
      </Panel>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyLogoPanel.fragment = gql`
  ${OrganizationLogoForm.edit.fragment}
  ${DocumentHeaderPreview.fragment}
  fragment CompanyLogoPanel on User {
    id
    viewingOrganization {
      id
      logoFile {
        id
        downloadUrl
        filename
      }
      ...OrganizationLogoForm_edit
      ...DocumentHeaderPreview
    }
  }
`;

export default CompanyLogoPanel;
