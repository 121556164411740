// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import Timezone from '@shared/modules/Organization/enums/Timezone';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    businessHours: (organization as any).businessHours || '',
    timezone: (organization as any).timezone || Timezone.US_PACIFIC,
  }),
  gql`
    fragment OrganizationBusinessInfoHoursForm_edit on Organization {
      id
      businessHours
      timezone
    }
  `,
);

const toForm = ({organizationId, businessHours, timezone}: any) => ({
  organizationId,
  businessHours,
  timezone,
});

const toMutation = ({organizationId, businessHours, timezone}: any) => ({
  organizationId,
  businessHours,
  timezone,
});

const OrganizationBusinessInfoHoursForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationBusinessInfoHoursForm;
