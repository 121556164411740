// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import {UpdateProjectTypePaymentMethodsFormType} from '@shared/modules/PaymentMethod/forms/UpdateProjectTypePaymentMethodsForm';
import PaymentMethodOptions from 'modules/Organization/Settings/Company/components/PaymentMethodOptions';

const ProjectTypePaymentMethodFields = ({
  form,
  field,
  organization,
}: {
  form: Form<{updateProjectTypePaymentMethodsForm: UpdateProjectTypePaymentMethodsFormType}>;
  field: string;
  organization: OrganizationModel;
}) => {
  return (
    <PaymentMethodOptions
      form={form}
      displayForms={_.get(form.values, field)}
      field={field}
      fieldToUpdate={'isEnabled'}
      labelField={'paymentMethodName'}
      organization={organization}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypePaymentMethodFields.fragment = gql`
  ${PaymentMethodOptions.fragment}
  fragment ProjectTypePaymentMethodFields on Organization {
    id
    ...PaymentMethodOptions
  }
`;

export default ProjectTypePaymentMethodFields;
