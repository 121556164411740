// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';

const Container = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TabView = ({initialSelected, tabs, children}) => {
  const [selected, setSelected] = useState(initialSelected);

  return (
    <Container>
      <Row>
        {tabs({
          selected,
          onSelect: (selected) => setSelected(selected),
        })}
      </Row>
      {children({selected})}
    </Container>
  );
};

const Touchable = Styled.Touchable`
  flex: 1;
`;

const TabViewItem = ({onPress, children}) => {
  return (
    <Touchable activeOpacity={1} onPress={onPress}>
      {children}
    </Touchable>
  );
};

TabView.Item = TabViewItem;

export default TabView;
