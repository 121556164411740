// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, usePopover} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Table from '@shared/design/components/TableV2Deprecated';
import TaskTemplateAssignedToRole from '@shared/modules/TaskManagement/enums/TaskTemplateAssignedToRole';
import TaskTemplateForm from '@shared/modules/TaskManagement/forms/TaskTemplateForm';
import useDeleteTaskTemplateMutation from '@shared/modules/TaskManagement/hooks/useDeleteTaskTemplateMutation';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import EditTaskTemplateDrawer from 'modules/TaskManagement/TaskTemplates/components/EditTaskTemplateDrawer';

const Row = Styled.View`
  flex-direction: row;
`;

const EditTemplateIcon = ({taskTemplate, organization, refetch}) => {
  const editTaskTemplateDrawer = useDrawer({name: 'Edit Task Template Drawer'});
  return (
    <React.Fragment>
      <TertiaryButton style={{paddingHorizontal: 8}} onPress={editTaskTemplateDrawer.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={14} />
      </TertiaryButton>
      <EditTaskTemplateDrawer
        key={editTaskTemplateDrawer.key}
        isOpen={editTaskTemplateDrawer.isOpen}
        handleClose={editTaskTemplateDrawer.handleClose}
        taskTemplate={taskTemplate}
        organization={organization}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const TemplateActionsMenu = ({popover, deleteModal}) => {
  return (
    <ActionMenuPopover
      popover={popover}
      placement={Popover.Positions.BottomStart}
      offset={[-110, 4]}
      width={140}
    >
      <ActionMenuPopover.MenuItem
        onPress={() => {
          deleteModal.handleOpen();
          popover.handleClose();
        }}
      >
        Delete Template
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const TemplateActions = ({taskTemplate, organization, refetch}) => {
  const actionsPopover = usePopover();
  const deleteModal = useModal();
  const taskTemplateForm = TaskTemplateForm.delete({taskTemplateId: taskTemplate.id});
  const {handleSubmit, submitting} = useDeleteTaskTemplateMutation({
    taskTemplateForm,
    onSuccess: () => {
      deleteModal.handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <Row style={{marginLeft: -8}}>
      <EditTemplateIcon taskTemplate={taskTemplate} organization={organization} refetch={refetch} />
      <Space width={4} />
      <Popover.Content innerRef={actionsPopover.ref}>
        <TertiaryButton style={{paddingHorizontal: 8}} onPress={actionsPopover.handleToggle}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={14} />
        </TertiaryButton>
      </Popover.Content>
      <TemplateActionsMenu popover={actionsPopover} deleteModal={deleteModal} />
      <DeleteModal
        isOpen={deleteModal.isOpen}
        title={`Are you sure you want to delete ${taskTemplate.name}?`}
        subtitle={"You can't undo this action."}
        handleClose={deleteModal.handleClose}
        handleDelete={handleSubmit}
        isSubmitting={submitting}
      />
    </Row>
  );
};

const getTableColumnDefinitions = ({organization, refetch}) => [
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Template Name</Table.HeaderText>;
    },
    cellContent: ({item: taskTemplate}) => {
      return <Table.CellText>{taskTemplate.name}</Table.CellText>;
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Task Name</Table.HeaderText>;
    },
    cellContent: ({item: taskTemplate}) => {
      return <Table.CellText>{taskTemplate.taskName}</Table.CellText>;
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Assignee</Table.HeaderText>;
    },
    cellContent: ({item: taskTemplate}) => {
      const assignedToUserName = _.get(taskTemplate, 'assignedToUser.fullName');
      const assignedToTeamName = _.get(taskTemplate, 'assignedToTeam.name');
      const assignedToRole = _.get(taskTemplate, 'assignedToRole');
      return (
        <Table.CellTextWithDefault>
          {assignedToRole
            ? TaskTemplateAssignedToRole.getDisplayRole(assignedToRole)
            : assignedToUserName || assignedToTeamName}
        </Table.CellTextWithDefault>
      );
    },
  },
  {
    width: 140,
    headerContent: () => {
      return <Table.HeaderText>Actions</Table.HeaderText>;
    },
    cellContent: ({item: taskTemplate}) => {
      return (
        <TemplateActions
          taskTemplate={taskTemplate}
          organization={organization}
          refetch={refetch}
        />
      );
    },
  },
];

const OrganizationTaskTemplatesTable = ({organization, loading, refetch}) => {
  return (
    <Table
      columnDefinitions={getTableColumnDefinitions({organization, refetch})}
      emptyStateText='No tasks templates'
      items={organization.officeTaskTemplates}
      itemKey={'id'}
      loading={loading}
      isDense
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationTaskTemplatesTable.fragment = gql`
  ${EditTaskTemplateDrawer.fragment}
  fragment OrganizationTaskTemplatesTable on Organization {
    id
    officeTaskTemplates {
      id
      name
      taskName
      assignedToRole
      assignedToUser {
        id
        fullName
      }
      assignedToTeam {
        id
        name
      }
      ...EditTaskTemplateDrawer
    }
    ...EditTaskTemplateDrawer_Organization
  }
`;

export default OrganizationTaskTemplatesTable;
