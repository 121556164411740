// Libararies
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Tabs from '@shared/design/components/Tabs';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import LongDistanceDispatchShipmentsTabContent from 'modules/Dispatch/LongDistance/components/LongDistanceDispatchShipmentsTabContent';
import LongDistanceDispatchTripsTabContent from 'modules/Dispatch/LongDistance/components/LongDistanceDispatchTripsTabContent';
import DispatchPageHeader from 'modules/Dispatch/components/DispatchPageHeader';

const Container = Styled.View`
  flex: 1;
`;

const TabsContainer = Styled.View`
  border-bottom-width: 1px;
  margin-horizontal: 16px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.border};
`;

const getTabIndex = ({navigator}) => {
  if (navigator.location.pathname.includes('/long-distance/shipments')) {
    return 0;
  }
  if (navigator.location.pathname.includes('/long-distance/trips')) {
    return 1;
  }
  return 0;
};

const getTabDefinitions = ({organization}) => {
  const {longDistanceShipmentCountsByStatus, tripCountsByStatus} = organization;
  return [
    {label: 'Shipments', count: longDistanceShipmentCountsByStatus.activeCount, value: 'shipments'},
    {
      label: 'Trips',
      count: tripCountsByStatus.activeCount,
      value: 'trips',
    },
  ];
};

const TabHeader = ({organization, navigator}) => {
  const tabDefinitions = getTabDefinitions({organization});
  const activeTabIndex = getTabIndex({navigator});

  return (
    <TabsContainer>
      <Tabs
        tabs={tabDefinitions}
        handlePressTab={({value}) => handleTabClicked({tabValue: value, navigator})}
        activeTabIndex={activeTabIndex}
      />
    </TabsContainer>
  );
};

const handleTabClicked = ({tabValue, navigator}) => {
  navigator.replace(`/dispatch/long-distance/${tabValue}`);
};

const LongDistanceDispatchPageTabContent = ({tabIndex}) => {
  switch (tabIndex) {
    case 0:
      return <LongDistanceDispatchShipmentsTabContent />;
    case 1:
      return <LongDistanceDispatchTripsTabContent />;
    default:
      return <LongDistanceDispatchShipmentsTabContent />;
  }
};

const LongDistanceDispatchPage = () => {
  const {navigator} = useNavigationDOM();
  const activeTabIndex = getTabIndex({navigator});

  const {data} = useQuery(LongDistanceDispatchPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (!data) {
    return (
      <SidebarPageV2 selected={'dispatch'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <SidebarPageV2 selected={'dispatch'}>
      <Container>
        <DispatchPageHeader />
        <TabHeader organization={data.viewer.viewingOrganization} navigator={navigator} />
        <Container>
          <LongDistanceDispatchPageTabContent tabIndex={activeTabIndex} />
        </Container>
      </Container>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
LongDistanceDispatchPage.query = gql`

  query LongDistanceDispatchPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        longDistanceShipmentCountsByStatus{
          activeCount
        }
        tripCountsByStatus{
          activeCount
        }
      }
    }
  }
`;

export default LongDistanceDispatchPage;
