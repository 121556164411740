// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import TruckForm from '@shared/modules/Dispatch/forms/TruckForm';
import useUpdateTruckMutation from '@shared/modules/Dispatch/hooks/useUpdateTruckMutation';

import TruckFormFields from './TruckFormFields';

const UpdateTruckForm = ({truck, handleClose, refetch}) => {
  const responsive = useResponsive();
  const truckForm = TruckForm.edit(truck);
  const {form, submitting, handleSubmit} = useUpdateTruckMutation({
    truckForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: () => {},
  });
  return (
    <TruckFormFields
      responsive={responsive}
      isUpdate
      form={form}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    />
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateTruckForm.propTypes = {
  truck: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

UpdateTruckForm.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateTruckForm.fragment = gql`
  ${TruckForm.edit.fragment}
  fragment UpdateTruckForm on Truck {
    id
    ...TruckForm_edit
  }
`;

export default UpdateTruckForm;
