// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {PrintJobDocument} from 'modules/Document/Job/components';
import {PrintDocumentPage} from 'modules/Document/Print/components';

const PrintJobPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <PrintDocumentPage query={PrintJobPage.query} variables={{uuid}}>
    {({data}) => <PrintJobDocument job={data.job} />}
  </PrintDocumentPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PrintJobPage.query = gql`
  ${PrintJobDocument.fragment}

  query PrintJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      ...PrintJobDocument
    }
  }
`;

export default PrintJobPage;
