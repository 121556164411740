// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import InventoryLibraryForm from '@shared/modules/Inventory/forms/InventoryLibraryForm';
import useUpdateInventoryLibraryMutation from '@shared/modules/Inventory/hooks/useUpdateInventoryLibraryMutation';

const UpdateInventoryLibraryModal = ({updateInventoryLibraryModal, inventoryLibrary, refetch}) => {
  const inventoryLibraryForm = InventoryLibraryForm.edit(inventoryLibrary);
  const {form, handleSubmit, submitting} = useUpdateInventoryLibraryMutation({
    inventoryLibraryForm,
    onSuccess: () => {
      refetch();
      form.resetForm();
      updateInventoryLibraryModal.handleClose();
    },
    onError: (errors) => console.log(errors),
  });
  const nameField = 'inventoryLibraryForm.name';
  return (
    <SmallModal key={updateInventoryLibraryModal.key} isOpen={updateInventoryLibraryModal.isOpen}>
      <SmallModal.HeaderText>{'Edit Inventory Library'}</SmallModal.HeaderText>
      <Space height={16} />
      <FieldInput
        {...form}
        isRequired
        label={'Name'}
        name={nameField}
        input={{
          autoFocus: true,
          placeholder: 'Enter library name',
        }}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <QuaternaryButton
          isDisabled={submitting}
          textColor={colors.blue.interactive}
          onPress={() => {
            form.resetForm();
            updateInventoryLibraryModal.handleClose();
          }}
          text={'Cancel'}
        />
        <Space width={16} />
        <Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          iconLeft={Icon.Check}
          text={'Save'}
        />
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateInventoryLibraryModal.fragment = gql`
  ${InventoryLibraryForm.edit.fragment}
  fragment UpdateInventoryLibraryModal on InventoryLibrary {
    id
    ...InventoryLibraryForm_edit
  }
`;

export default UpdateInventoryLibraryModal;
