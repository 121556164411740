// Libraries
import _ from 'lodash';
import React from 'react';
import ReactMarkdown from 'react-markdown';

// Supermove
import {Icon, ScrollView, Space, Styled, SectionList} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useHover, useSearch, useState} from '@supermove/hooks';
import {Formula} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// Apps
import FieldInput from '@shared/design/components/Field/FieldInput';
import formulaExpressionInfoBlocks from '@shared/formulas/src/consts/infoBlocks';
import {EvaluateFormulaStringInput} from '@shared/formulas/src/library/EvaluateFormulaStringInput';
import {fixedArityOne} from '@shared/formulas/src/library/arity';
import {ensureString} from '@shared/formulas/src/library/types';
import evaluateFormulaString from '@shared/formulas/src/logic/evaluateFormulaString';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import Line from 'modules/App/components/Line';

const Container = Styled.View`
`;

const VerticalLine = Styled.View`
  height: 100%;
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const FormulaEditorDocumentationSectionContainer = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const ExpressionOptionsColumn = Styled.View`
  width: 260px;
`;

const ExpressionOptionsCategoryContainer = Styled.View`
  padding-horizontal: 16px;
  padding-vertical: 8px;
  border-color: ${colors.gray.border};
  border-top-width: ${({isFirstCategory}) => (isFirstCategory ? '0px' : '1px')};
  border-bottom-width: ${({isFirstCategory}) => (isFirstCategory ? '0px' : '1px')};
  background: ${colors.white};
`;

const ExpressionOptionsCategoryText = Styled.Text`
  ${Typography.Label2}
`;

const ExpressionOptionButton = Styled.ButtonV2`
  padding-horizontal: 16px;
  padding-vertical: 8px;
  background: ${({isHovered}) => (isHovered ? colors.hover : colors.white)};
`;

const ExpressionOptionText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
`;

const SearchInput = Styled.TextInput`
  ${Typography.Body3}
  border-width: 0px;
  border-radius: 0px;
  padding-left: 36px;
`;

const DefinitionColumn = Styled.View`
  flex: 1;
  padding-horizontal: 16px;
  padding-vertical: 8px;
`;

const DefinitionHeader = Styled.Text`
  ${Typography.Label1};
`;

const DefinitionText = Styled.Text`
  ${Typography.Body3}
`;

const DefinitionTextBlock = Styled.View`
  padding-horizontal: 8px;
  padding-vertical: 4px;
  background-color: ${colors.gray.border};
`;

const createFormulaRunnerBlock = () => {
  return {
    test: {
      key: 'runner',
      isHeader: true,
      headerText: 'Scratchpad',
    },
  };
};

const createDocumentBlockMapFromVariables = (variables) => {
  return variables.reduce(
    (acc, variable) => {
      acc[variable.identifier] = {
        key: variable.identifier,
        name: variable.name,
        documentation: {
          header: variable.name,
          description: [variable.description, `Format: ${variable.format}`]
            .filter(Boolean)
            .join('\n\n'),
          exampleMarkdownSnippet: `var("${variable.identifier}")`,
        },
        insertionText: `var("${variable.identifier}")`,
        parent: 'variables',
      };
      return acc;
    },
    {
      variables: {
        key: 'variables',
        isHeader: true,
        headerText: 'Billing Variables',
        documentation: {
          header: 'Billing Variables',
          description:
            'Billing variables allow project and job data to be referenced in formulas. Hover over a billing variable to see a description, or click on a billing variable to insert into a formula.',
        },
      },
    },
  );
};

const createDocumentBlockMapFromFormulas = (formulas) => {
  return formulas.reduce(
    (acc, formula) => {
      const prettyFormulaName = formula.name;
      acc[formula.identifier] = {
        key: formula.identifier,
        name: prettyFormulaName,
        documentation: {
          header: prettyFormulaName,
          description: formula.description,
          syntaxMarkdownSnippet: `formula("${formula.identifier}")`,
          formulaString: Formula.getFormulaString(formula),
        },
        insertionText: `formula("${formula.identifier}")`,
        parent: 'formulaLabel',
      };
      return acc;
    },
    {
      formulaLabel: {
        key: 'formulaLabel',
        isHeader: true,
        headerText: 'Billing Formulas',
        documentation: {
          header: 'Billing Formulas',
          description:
            'You can nest formulas! Save from having to write the same logic in multiple formulas using the `formula()` function.',
        },
      },
    },
  );
};

const getDocumentationBlockMap = ({variables, organization}) => {
  const formulaExpressionInfoBlocksFiltered = Object.keys(formulaExpressionInfoBlocks).reduce(
    (acc, key) => {
      const block = formulaExpressionInfoBlocks[key];

      // Filter out the 'constants' section if the feature is disabled
      if (block.parent === 'constants' && !organization.features.isEnabledTbdBillItems) {
        return acc;
      }

      acc[key] = formulaExpressionInfoBlocks[key];
      return acc;
    },
    {},
  );
  let blocks = {
    ...createFormulaRunnerBlock(),
    ...formulaExpressionInfoBlocksFiltered,
  };
  if (organization.features.isEnabledNestedFormulas) {
    blocks = {
      ...blocks,
      formula: {
        icon: '',
        insertionText: 'formula(',
        documentation: {
          header: 'Formula',
          description:
            'Evaluates another formula from your organization and returns the result. This is useful for reusing logic across multiple formulas.',
          syntaxMarkdownSnippet: 'formula("OTHER_FORMULA_IDENTIFIER")',
          exampleMarkdownSnippet:
            'formula("OTHER_FORMULA_IDENTIFIER") *evaluates* *to* 7, assuming you have another formula called OTHER_FORMULA_IDENTIFIER that returns 7.',
          parent: 'functions',
        },
      },
      ...createDocumentBlockMapFromVariables(variables),

      ...createDocumentBlockMapFromFormulas(organization.formulas),
    };
  } else {
    blocks = {
      ...blocks,
      ...createDocumentBlockMapFromVariables(variables),
    };
  }

  return blocks;
};

const handleExpressionClick =
  ({key, documentationBlockMap, handleInsertText}) =>
  () => {
    const text = documentationBlockMap[key].insertionText;
    handleInsertText(text);
  };

const FormulaEditorTableOfContentsRow = ({
  handleClick,
  setSelectedExpressionKey,
  expressionKey,
  expression,
  isFirstRow,
  isHeader,
}) => {
  const {ref, isHovered} = useHover();
  useEffect(() => {
    if (isHovered) {
      setSelectedExpressionKey(expressionKey);
    }
  }, [isHovered, setSelectedExpressionKey, expressionKey]);
  if (expression.isHeader) {
    return (
      <ExpressionOptionsCategoryContainer
        ref={ref}
        isHovered={isHovered}
        isFirstCategory={isFirstRow}
      >
        <ExpressionOptionsCategoryText>{expression.headerText}</ExpressionOptionsCategoryText>
      </ExpressionOptionsCategoryContainer>
    );
  }
  return (
    <ExpressionOptionButton ref={ref} isHovered={isHovered} onPress={handleClick(expressionKey)}>
      <ExpressionOptionText>{expression.name || expressionKey}</ExpressionOptionText>
    </ExpressionOptionButton>
  );
};

const FormulaEditorEmptyExpressionInfoBlock = () => {
  return <DefinitionText>Hover over an item in the sidebar for information</DefinitionText>;
};

const ExpressionInfoBlockMarkdownSnippet = ({markdownSnippet}) => {
  return (
    <DefinitionTextBlock>
      <ReactMarkdown>{markdownSnippet}</ReactMarkdown>
    </DefinitionTextBlock>
  );
};

const ExpressionInfoBlock = ({
  header,
  description,
  syntaxMarkdownSnippet,
  exampleMarkdownSnippet,
  formulaString,
}) => {
  return (
    <React.Fragment>
      <Container>
        <DefinitionHeader>{header}</DefinitionHeader>
      </Container>
      {description && (
        <Container>
          <Space height={2} />
          <DefinitionText>{description}</DefinitionText>
        </Container>
      )}
      {syntaxMarkdownSnippet && (
        <Container>
          <Space height={16} />
          <DefinitionText>Syntax</DefinitionText>
          <ExpressionInfoBlockMarkdownSnippet markdownSnippet={syntaxMarkdownSnippet} />
        </Container>
      )}
      {exampleMarkdownSnippet && (
        <Container>
          <Space height={16} />
          <DefinitionText>Examples</DefinitionText>
          <ExpressionInfoBlockMarkdownSnippet markdownSnippet={exampleMarkdownSnippet} />
        </Container>
      )}
      {formulaString && (
        <Container>
          <Space height={16} />
          <DefinitionText>Formula Contents</DefinitionText>
          <ExpressionInfoBlockMarkdownSnippet markdownSnippet={formulaString} />
        </Container>
      )}
    </React.Fragment>
  );
};

const getVariableValues = (variablesUsed, variables, formVariables) => {
  return variablesUsed.reduce((acc, variableIdentifier) => {
    const variable = variables.find((variable) => variable.identifier === variableIdentifier) || {};
    const formVariable = formVariables[variableIdentifier];
    acc[variableIdentifier] = {
      ...variable,
      value: formVariable?.value ? formVariable.value : '',
    };
    return acc;
  }, {});
};

const FormulaRunnerBlock = ({formulaString, variablesUsed, variables, formulas, form}) => {
  const customFunctionDefinitions = {
    var: {
      checkArity: fixedArityOne('var'),
      checkTypes: ensureString('var'),
      call: (variableIdentifier) => {
        const variable =
          variables.find((variable) => variable.identifier === variableIdentifier) || {};
        const variableValue = form.values.variables[variableIdentifier].value;
        if (VariableFormat.getStringVariableFormats().includes(variable.format)) {
          return variableValue || '';
        }
        return variableValue === '' ? null : Number(variableValue);
      },
    },
    value: {
      checkArity: fixedArityOne('value'),
      checkTypes: ensureString('value'),
      call: (variableIdentifier) => {
        const variable =
          variables.find((variable) => variable.identifier === variableIdentifier) || {};

        const variableValue = form.values.variables[variableIdentifier].value;
        if (VariableFormat.getStringVariableFormats().includes(variable.format)) {
          return variableValue || '';
        }
        return variableValue === '' ? null : Number(variableValue);
      },
    },
  };

  customFunctionDefinitions.formula = {
    checkArity: fixedArityOne('formula'),
    checkTypes: ensureString('formula'),
    call: (formulaIdentifier) => {
      const formula = formulas.find((formula) => formula.identifier === formulaIdentifier);
      // TODO: recursive formula eval
      if (formula) {
        const {formulaResult} = evaluateFormulaString({
          formula: Formula.getFormulaString(formula),
          variables,
          plugins: [],
          customFunctionImplementations: {
            formula: {
              checkArity: fixedArityOne('formula'),
              checkTypes: ensureString('formula'),
              // we can't really do recursive nested formula references on the FE so we'll return zero one formula down
              call: (formulaIdentifier) => {
                return 0;
              },
            },
            ...customFunctionDefinitions,
          },
        });

        return formulaResult;
      }

      return 0;
    },
  };
  useEffect(() => {
    const variableValues = getVariableValues(variablesUsed, variables, form.values.variables);
    if (!_.isEqual(variableValues, form.values.variables)) {
      form.setValues({
        variables: variableValues,
      });
    }
  }, [variablesUsed]); // eslint-disable-line react-hooks/exhaustive-deps

  const {formulaResult} = evaluateFormulaString(
    new EvaluateFormulaStringInput(
      formulaString,
      form.values.variables,
      [],
      customFunctionDefinitions,
    ),
  );
  const isTbd = formulaResult === null;
  const formattedResult = isNaN(Number(formulaResult))
    ? `${formulaResult}`
    : `${formulaResult} (${Currency.display(formulaResult)})`;
  return (
    <React.Fragment>
      <Container>
        <DefinitionHeader>Scratchpad</DefinitionHeader>
        <Container>
          <Space height={8} />
          <Container>
            <Space height={2} />
            <DefinitionText>
              Plug in some test values for the variables used in this formula to view the result.
              Keep in mind that all numeric variables use cents; one dollar in a formula is 100.
              Similarly, percentages are calculated as a decimal (e.g. 50% is 0.5).
            </DefinitionText>
          </Container>
        </Container>
        <Space height={8} />
        <DefinitionHeader>Variables:</DefinitionHeader>
        <Space height={8} />
        <ScrollView
          contentContainerStyle={{alignItems: 'flex-start', flexDirection: 'row', flexWrap: 'wrap'}}
          style={{
            maxWidth: '100%',
            height: '160px',
            borderWidth: 1,
            borderColor: colors.gray.border,
          }}
        >
          {Object.keys(form.values.variables).map((key) => {
            return (
              <FieldInput
                {...form}
                style={{padding: 8}}
                name={`variables.${key}.value`}
                label={
                  (variables.find((variable) => variable.identifier === key) || {name: key}).name
                }
                input={{
                  style: {
                    height: 24,
                    width: 120,
                  },
                }}
              />
            );
          })}
        </ScrollView>
        <Space height={16} />
        <DefinitionHeader>{`Result: ${isTbd ? 'TBD' : formattedResult}`}</DefinitionHeader>
      </Container>
    </React.Fragment>
  );
};

const FormulaEditorTableOfContents = ({
  filteredDocumentationBlocks,
  headerBlocks,
  handleExpressionClick,
  setSelectedExpressionKey,
  documentationBlockMap,
  handleInsertText,
}) => {
  // Grouping documentation blocks into sections
  const sections = headerBlocks.map((headerBlock) => ({
    ...headerBlock,
    data: [],
  }));

  filteredDocumentationBlocks.forEach((documentationBlock, index) => {
    const expressionKey = documentationBlock.key;
    const expression = documentationBlockMap[expressionKey];
    const currentSection = _.find(sections, (section) => section.key === expression.parent);
    if (currentSection) {
      currentSection.data.push(documentationBlock);
    }
  });

  return (
    <SectionList
      sections={sections}
      keyExtractor={(item, index) => item + index}
      stickySectionHeadersEnabled
      renderItem={({item, index, section}) => (
        <FormulaEditorTableOfContentsRow
          key={item.key}
          handleClick={(key) =>
            handleExpressionClick({key, documentationBlockMap, handleInsertText})
          }
          setSelectedExpressionKey={setSelectedExpressionKey}
          expressionKey={item.key}
          expression={documentationBlockMap[item.key]}
          isFirstRow={index === 0}
        />
      )}
      renderSectionHeader={({section}) => {
        const {key} = section;
        return (
          <FormulaEditorTableOfContentsRow
            key={key}
            handleClick={(key) =>
              handleExpressionClick({key, documentationBlockMap, handleInsertText})
            }
            setSelectedExpressionKey={setSelectedExpressionKey}
            expressionKey={key}
            expression={documentationBlockMap[key]}
            isHeader
          />
        );
      }}
    />
  );
};

const FormulaEditorDocumentationSection = ({
  handleInsertText,
  variables,
  variablesUsed,
  organization,
  formulaString,
  scratchpadForm,
}) => {
  const [selectedExpressionKey, setSelectedExpressionKey] = useState('formulas');

  const documentationBlockMap = getDocumentationBlockMap({variables, organization});
  const selectedExpression = documentationBlockMap[selectedExpressionKey];
  const {headerBlocks, searchItems} = _.reduce(
    documentationBlockMap,
    (acc, value, key) => {
      if (value.isHeader) {
        // Add to headerBlocks if it's a header
        acc.headerBlocks.push({...value, key});
      } else {
        // Otherwise, add to searchItems
        acc.searchItems.push({...value, key});
      }
      return acc;
    },
    {headerBlocks: [], searchItems: []},
  );
  const {
    query,
    results: filteredDocumentationBlocks,
    setQuery,
  } = useSearch({
    initialQuery: '',
    items: searchItems,
    options: {keys: ['key', 'insertionText', 'documentation.header', 'name']},
  });

  return (
    <FormulaEditorDocumentationSectionContainer>
      <ExpressionOptionsColumn>
        <Container>
          <SearchInput
            value={query}
            onChangeText={(text) => setQuery(text)}
            placeholder={'Search'}
          />
          <Icon
            source={Icon.Search}
            size={12}
            color={colors.gray.tertiary}
            style={{position: 'absolute', left: 14, top: 13}}
          />
        </Container>
        <Line />
        <FormulaEditorTableOfContents
          documentationBlockMap={documentationBlockMap}
          headerBlocks={headerBlocks}
          filteredDocumentationBlocks={filteredDocumentationBlocks}
          handleExpressionClick={handleExpressionClick}
          handleInsertText={handleInsertText}
          query={query}
          setSelectedExpressionKey={setSelectedExpressionKey}
        />
      </ExpressionOptionsColumn>
      <VerticalLine />
      <DefinitionColumn>
        {selectedExpression && selectedExpression.key === 'runner' ? (
          <FormulaRunnerBlock
            formulaString={formulaString}
            variables={variables}
            variablesUsed={variablesUsed}
            form={scratchpadForm}
            formulas={organization.formulas}
          />
        ) : selectedExpression && selectedExpression.documentation ? (
          <ExpressionInfoBlock {...selectedExpression.documentation} />
        ) : (
          <FormulaEditorEmptyExpressionInfoBlock />
        )}
      </DefinitionColumn>
    </FormulaEditorDocumentationSectionContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FormulaEditorDocumentationSection.fragment = gql`
  fragment FormulaEditorDocumentationSection_Variable on Variable {
    id
    identifier
    name
    description
  }
  fragment FormulaEditorDocumentationSection_Organization on Organization {
    id
    features {
      isEnabledNestedFormulas: isEnabled(feature: "NESTED_FORMULAS")
      isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
    }
  }
`;

export default FormulaEditorDocumentationSection;
