// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import OrganizationLogo from '@shared/modules/Organization/components/OrganizationLogo';

const Container = Styled.View`
  width: 100%;
`;

const JobRequestOrganizationLogo = ({organization}) => {
  const responsive = useResponsive();

  return (
    <Container>
      <OrganizationLogo
        organization={organization}
        style={{
          width: '100%',
          height: responsive.mobile ? '145px' : '215px',
        }}
      />
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobRequestOrganizationLogo.propTypes = {
  organization: PropTypes.object.isRequired,
};

JobRequestOrganizationLogo.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestOrganizationLogo.fragment = gql`
  ${OrganizationLogo.fragment}
  fragment JobRequestOrganizationLogo on Organization {
    id
    ...OrganizationLogo
  }
`;

export default JobRequestOrganizationLogo;
