// Supermove
import {CreateTrainingProjectForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useCreateTrainingProjectMutation = ({createTrainingProjectForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      createTrainingProjectForm,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateTrainingProjectMutation.mutation,
    variables: {
      createTrainingProjectForm: CreateTrainingProjectForm.toMutation(
        form.values.createTrainingProjectForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateTrainingProjectMutation.mutation = gql`
  mutation useCreateTrainingProjectMutation($createTrainingProjectForm: CreateTrainingProjectForm!) {
    response: createTrainingProject(createTrainingProjectForm: $createTrainingProjectForm) {
      ${gql.errors}
      project {
        id
        uuid
      }
    }
  }
`;

export default useCreateTrainingProjectMutation;
