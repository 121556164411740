// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Loading, FlatList, Icon, Tabs, ToastContainer} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useState, useTabs} from '@supermove/hooks';
import {Proposal} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Drawer from '@shared/design/components/Drawer';
import FieldInput from '@shared/design/components/Field/FieldInput';
import FieldValue from '@shared/design/components/Field/FieldValue';
import Sheet from '@shared/design/components/Sheet';
import ProjectWidgetKind from '@shared/modules/Project/enums/ProjectWidgetKind';
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import StatusBadge from 'modules/App/components/StatusBadge';
import DocumentV2Inline from 'modules/Document/DocumentV2/DocumentV2Inline';
import ProjectProposalActions from 'modules/Project/V2/Show/Blocks/components/ProjectProposalActions';
import ProposalDetailStep from 'modules/Project/V2/Show/Blocks/components/ProposalDetailStep';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Column = Styled.View`
  height: 100%;
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
`;

const SheetContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const LayoverWrapper = ({isOpen, handleClose, responsive, tabs, children}) => {
  if (responsive.desktop) {
    return (
      <Drawer isOpen={isOpen} handleClose={handleClose}>
        <Row style={{flex: 1}}>{children}</Row>
      </Drawer>
    );
  }

  const isDocument = tabs.selectedIndex === 1;

  return (
    <Sheet
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={`View ${isDocument ? 'Document' : 'Proposal'}`}
      isFixedHeight
      HeaderLeftComponent={() =>
        isDocument ? (
          <TertiaryButton onPress={() => tabs.setSelectedIndex(0)} isHitSlop>
            <Icon source={Icon.ChevronLeft} size={16} color={colors.gray.primary} />
          </TertiaryButton>
        ) : null
      }
    >
      {children}
    </Sheet>
  );
};

const DrawerDocumentPreview = ({
  documentUuid,
  setIsDocumentPreviewOpen,
  handleClose,
  urlFilters,
}) => {
  return (
    <Column style={{width: '820px', borderRightWidth: 1, borderColor: colors.gray.border}}>
      <Drawer.Header
        headerText={'View Document'}
        handleClose={() => setIsDocumentPreviewOpen(false)}
        isClosable
      >
        <HeaderContainer>
          <SecondaryButton
            isSmall
            text={'See All Documents'}
            iconLeft={Icon.ExternalLinkAlt}
            onPress={() => {
              handleClose();
              urlFilters.handleUpdate({
                widget: ProjectWidgetKind.DOCUMENTS,
              });
            }}
          />
          <Space width={12} />
        </HeaderContainer>
      </Drawer.Header>
      <Drawer.Body>
        <DocumentV2Inline uuid={documentUuid} />
      </Drawer.Body>
    </Column>
  );
};

const SheetDocumentPreview = ({documentUuid, handleClose, urlFilters}) => {
  if (!documentUuid) {
    return null;
  }
  return (
    <Column style={{flex: 1, paddingHorizontal: 16}}>
      <SecondaryButton
        isSmall
        isWidthOfContainer
        text={'View All Documents'}
        iconLeft={Icon.ExternalLinkAlt}
        onPress={() => {
          setTimeout(() => {
            handleClose();
            urlFilters.handleUpdate(
              {
                widget: ProjectWidgetKind.DOCUMENTS,
              },
              true,
            );
          }, 0);
        }}
      />
      <Space height={16} />
      <DocumentV2Inline key={documentUuid} uuid={documentUuid} />
      <Space height={16} />
    </Column>
  );
};

const ProjectDetailsContentContainer = ({responsive, children}) => {
  if (responsive.desktop) {
    return <Drawer.Body>{children}</Drawer.Body>;
  }
  return (
    <SheetContentContainer>
      {children}
      <Space height={16} />
    </SheetContentContainer>
  );
};

const ProposalDetailsDrawerContent = ({
  handleClose,
  isOpen,
  confirmationId,
  setDocumentUuid,
  setIsDocumentPreviewOpen,
  responsive,
  tabs,
}) => {
  const {data, loading, refetch} = useQuery(ProposalDetailsDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      confirmationId,
    },
    skip: !isOpen,
  });

  return (
    <Column
      style={{
        width: responsive.desktop ? Drawer.WIDTH.DEFAULT : '100%',
      }}
    >
      {responsive.desktop && (
        <Drawer.Header headerText={'View Proposal'} handleClose={handleClose} isClosable />
      )}
      <ProjectDetailsContentContainer responsive={responsive}>
        <Loading loading={loading}>
          {() => {
            const {confirmation: proposal} = data;
            const {statusText, statusColor} = Proposal.getStatus(proposal);
            const steps = JSON.parse(proposal.stepsJson);
            return (
              <React.Fragment>
                {responsive.desktop ? (
                  <Space height={8} />
                ) : (
                  <React.Fragment>
                    <Row>
                      <Column style={{flex: 1}}>
                        <ProjectProposalActions.MarkAsExpiredButton
                          proposal={proposal}
                          refetch={refetch}
                        />
                      </Column>
                      <Space width={8} />
                      <Column style={{flex: 1}}>
                        <ProjectProposalActions.CopyLinkButton
                          project={proposal.project}
                          proposal={proposal}
                        />
                      </Column>
                    </Row>
                    <Space height={16} />
                  </React.Fragment>
                )}
                <FieldValue
                  label={'Type'}
                  value={_.capitalize(proposal.kind)}
                  empty={'Unknown'}
                  isResponsive
                />
                <Space height={16} />
                <FieldInput.LabelText isResponsive>Status</FieldInput.LabelText>
                <Space height={8} />
                <Row>
                  <StatusBadge
                    label={statusText}
                    color={statusColor}
                    textColor={statusColor}
                    size={responsive.desktop ? StatusBadge.SIZE.SMALL : StatusBadge.SIZE.LARGE}
                  />
                </Row>
                <Space height={16} />
                <FieldValue
                  label={'Sent On'}
                  value={Datetime.convertToDisplayDatetime(proposal.createdAt)}
                  empty={'None'}
                  isResponsive
                />
                <Space height={16} />
                <FieldValue
                  label={'Sent By'}
                  value={proposal.createdBy?.fullName}
                  empty={'None'}
                  isResponsive
                />
                <Space height={16} />
                <FieldValue
                  label={'Completed On'}
                  value={
                    proposal.completedAt
                      ? Datetime.convertToDisplayDatetime(proposal.completedAt)
                      : null
                  }
                  empty={'None'}
                  isResponsive
                />
                <Space height={16} />
                <FlatList
                  data={steps}
                  style={{maxWidth: Drawer.WIDTH.DEFAULT}}
                  renderItem={({item: step, index}) => (
                    <ProposalDetailStep
                      step={step}
                      index={index}
                      isLast={index + 1 === steps.length}
                      onPress={
                        ConfirmationStepKind.DOCUMENT_V2_STEPS.includes(step.kind)
                          ? () => {
                              if (responsive.desktop) {
                                setIsDocumentPreviewOpen(true);
                              } else {
                                tabs.setSelectedIndex(1);
                              }
                              setDocumentUuid(step.document_uuid);
                            }
                          : null
                      }
                      responsive={responsive}
                    />
                  )}
                />
              </React.Fragment>
            );
          }}
        </Loading>
      </ProjectDetailsContentContainer>
      <ToastContainer />
    </Column>
  );
};

const ProposalDetailsDrawer = ({handleClose, isOpen, confirmationId, urlFilters}) => {
  const responsive = useResponsive();
  const [documentUuid, setDocumentUuid] = useState('');
  const [isDocumentPreviewOpen, setIsDocumentPreviewOpen] = useState(false);
  const tabs = useTabs({
    initialIndex: 0,
    onChangeIndex: (index) => (index === 0 ? setDocumentUuid('') : null),
  });

  const ProposalDetails = () => {
    return (
      <ProposalDetailsDrawerContent
        handleClose={handleClose}
        isOpen={isOpen}
        confirmationId={confirmationId}
        setDocumentUuid={setDocumentUuid}
        setIsDocumentPreviewOpen={setIsDocumentPreviewOpen}
        responsive={responsive}
        tabs={tabs}
      />
    );
  };

  return (
    <LayoverWrapper isOpen={isOpen} handleClose={handleClose} responsive={responsive} tabs={tabs}>
      {isDocumentPreviewOpen && (
        <DrawerDocumentPreview
          documentUuid={documentUuid}
          setIsDocumentPreviewOpen={setIsDocumentPreviewOpen}
          handleClose={handleClose}
          urlFilters={urlFilters}
        />
      )}
      {responsive.desktop ? (
        <ProposalDetails />
      ) : (
        <Tabs.SlideViewer
          tabs={tabs}
          disabledSwipe={tabs.selectedIndex === 0}
          containerStyle={{
            transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
          }}
        >
          <ProposalDetails />
          <SheetDocumentPreview
            documentUuid={documentUuid}
            handleClose={handleClose}
            urlFilters={urlFilters}
          />
        </Tabs.SlideViewer>
      )}
    </LayoverWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProposalDetailsDrawer.query = gql`
  ${Proposal.getStatus.fragment}
  ${ProjectProposalActions.projectFragment}
  ${ProjectProposalActions.confirmationFragment}

  query ProposalDetailsDrawer(
    $confirmationId: Int!
  ) {
    ${gql.query}
    confirmation(confirmationId: $confirmationId) {
      id
      kind
      createdAt
      createdBy {
        id
        fullName
      }
      completedAt
      stepsJson
      project {
        id
        ...ProjectProposalActions_Project
      }
      ...Proposal_getStatus
      ...ProjectProposalActions_Confirmation
    }
  }
`;

export default ProposalDetailsDrawer;
