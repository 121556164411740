// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Line from 'modules/App/components/Line';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';

const Container = Styled.View`
  background-color: ${colors.white};
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-color: ${colors.gray.border};
  overflow: hidden;
`;

const CommentContainer = Styled.View`
  padding-horizontal: 12px;
`;

const CommentBody = Styled.Text`
  ${Typography.Body}
`;

const CommentAuthor = Styled.Text`
  ${Typography.Micro}
`;

const CommentInput = ({form, field}) => {
  return (
    <ResponsiveTextInput
      form={form}
      field={`${field}.comment`}
      minHeight={56}
      paddingVertical={18}
      input={{
        style: {
          fontWeight: '500',
          width: '100%',
          borderWidth: 0,
          borderRadius: 0,
          paddingVertical: 18,
          paddingLeft: 12,
          paddingRight: 12,
        },
        placeholder: 'Add a comment',
      }}
    />
  );
};

const TaskComments = ({task, form, field}) => {
  return (
    <Container>
      {task.comments.map((comment) => (
        <React.Fragment key={comment.id}>
          <Space height={12} />
          <CommentContainer>
            <CommentBody>{comment.body}</CommentBody>
            <Space height={8} />
            <CommentAuthor>
              {comment.user.fullName}
              {', '}
              {Datetime.toDisplayDate(
                Datetime.fromDatetime(comment.createdAt),
                Datetime.DISPLAY_DATETIME,
              )}
            </CommentAuthor>
          </CommentContainer>
          <Space height={12} />
          <Line />
        </React.Fragment>
      ))}
      <CommentInput form={form} field={field} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TaskComments.fragment = gql`
  fragment TaskComments on Task {
    id
    comments {
      id
      body
      createdAt
      user {
        id
        fullName
        email
      }
    }
  }
`;

export default TaskComments;
