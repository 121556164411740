// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({jobId}: any) => ({
  jobId,
  additionalNotes: '',
  dispatchNotes: '',
  officeNotes: '',
  noteToCustomer: '',
  noteFromCustomer: '',
});

const edit = withFragment(
  (job) => ({
    jobId: (job as any).id,
    additionalNotes: (job as any).additionalNotes,
    dispatchNotes: (job as any).dispatchNotes,
    officeNotes: (job as any).officeNotes,
    noteToCustomer: (job as any).noteToCustomer,
    noteFromCustomer: (job as any).noteFromCustomer,
  }),
  gql`
    fragment JobNotesForm_edit on Job {
      id
      additionalNotes
      dispatchNotes
      officeNotes
      noteToCustomer
      noteFromCustomer
    }
  `,
);

const toForm = ({
  jobId,
  additionalNotes,
  dispatchNotes,
  officeNotes,
  noteToCustomer,
  noteFromCustomer,
}: any) => ({
  jobId,
  additionalNotes,
  dispatchNotes: dispatchNotes || '',
  officeNotes,
  noteToCustomer,
  noteFromCustomer,
});

const toMutation = ({
  jobId,
  additionalNotes,
  dispatchNotes,
  officeNotes,
  noteToCustomer,
  noteFromCustomer,
}: any) => ({
  jobId,
  additionalNotes,
  dispatchNotes,
  officeNotes,
  noteToCustomer,
  noteFromCustomer,
});

const JobNotesForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default JobNotesForm;
