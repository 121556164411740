// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';

// App
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import BillingSettingsPageNavigationTabs from 'modules/Organization/Settings/Billing/BillingSettingsPageNavigationTabs';
import SettingsContent from 'modules/Organization/Settings/components/SettingsContent';
import SettingsHeaderNavigation from 'modules/Organization/Settings/components/SettingsHeaderNavigation';

const PageContainer = Styled.View`
  flex: 1;
`;

const OrganizationSettingsBillingSettingsPage = ({pageName, children}) => {
  const {navigator} = useNavigationDOM();
  return (
    <SidebarPageV2 selected={'settings'}>
      <PageContainer>
        <StandardOfficeHeader title={''} showLine={false}>
          <SettingsHeaderNavigation>
            <SettingsHeaderNavigation.PreviousScreen onPress={() => navigator.push('/settings')}>
              Settings
            </SettingsHeaderNavigation.PreviousScreen>
            <SettingsHeaderNavigation.CurrentScreen>
              {pageName}
            </SettingsHeaderNavigation.CurrentScreen>
          </SettingsHeaderNavigation>
        </StandardOfficeHeader>
        <BillingSettingsPageNavigationTabs />
        <ScrollView style={{flex: 1}}>
          <SettingsContent>{children}</SettingsContent>
        </ScrollView>
      </PageContainer>
    </SidebarPageV2>
  );
};

export default OrganizationSettingsBillingSettingsPage;
