// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import FinalizeReportMoveMutation from 'modules/ReportMove/components/FinalizeReportMoveMutation';

const FinalizeReportMoveModalV2 = ({reportMoveId, onSuccess, isOpen, handleClose}) => {
  // This is legacy logic that we are looking to deprecate, so here we use
  // this setup to utilize old logic without creating new forms/mutations.
  const {submitting, handleSubmit} = useFormMutation({
    form: useForm({initialValues: {}}),
    mutation: FinalizeReportMoveMutation.mutation,
    variables: {
      reportMoveId,
    },
    onSuccess: () => {
      onSuccess();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <ActionModal
      icon={Icon.Lock}
      title={'Finalize Report'}
      message={'Press confirm below to finalize this report.'}
      handlePressOutside={handleClose}
      primaryActionText={'Confirm'}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      handleSecondaryAction={handleClose}
      isOpen={isOpen}
      isSubmitting={submitting}
    />
  );
};

export default FinalizeReportMoveModalV2;
