import {ExecutionResult, MutationResult, MutationFunctionOptions} from 'react-apollo';

// Supermove
import {gql} from '@supermove/graphql';
import {useMutation} from '@supermove/hooks';
import {MutationResponse} from '@supermove/hooks/src/forms/types';

type CreatePayEngineMerchantResponse = {
  payengineMerchant: {
    id: number;
    merchantId: string;
  };
};
type GraphQLResponse = MutationResponse<CreatePayEngineMerchantResponse>;

type Args = {
  organizationId: number;
};

type Result = {
  handleSubmit: (
    options?: MutationFunctionOptions<GraphQLResponse, Args>,
  ) => Promise<ExecutionResult<GraphQLResponse>>;
  mutationResult: MutationResult<GraphQLResponse>;
};

const useCreatePayEngineMerchantMutation = ({organizationId}: Args): Result => {
  const [handleSubmit, mutationResult] = useMutation(useCreatePayEngineMerchantMutation.mutation, {
    variables: {
      organizationId,
    },
  });

  return {
    handleSubmit,
    mutationResult,
  };
};

useCreatePayEngineMerchantMutation.mutation = gql`
  mutation CreatePayEngineMerchantMutation(
    $organizationId: Int!
  ) {
    response: createPayEngineMerchant(
      organizationId: $organizationId,
    ) {
      ${gql.errors}
      payengineMerchant {
        id 
        merchantId
      }
    }
  }
`;

export default useCreatePayEngineMerchantMutation;
