// Libraries
import _ from 'lodash';

// Supermove
import {generated, gql} from '@supermove/graphql';

const _new = ({name}: Pick<generated.DashboardTagForm, 'name'>): generated.DashboardTagForm => ({
  dashboardTagId: null,
  name: name || '',
  isDeleted: false,
});

export const DashboardTagFormEdit = gql`
  fragment DashboardTagFormEdit on Tag {
    id
    name
    isDeleted
  }
`;

const edit = (tag: generated.DashboardTagFormEditFragment) => {
  return {
    dashboardTagId: _.toNumber(tag.id),
    name: tag.name,
    isDeleted: tag.isDeleted,
  };
};

const toForm = (
  dashboardTagForm: ReturnType<typeof edit> | ReturnType<typeof _new>,
): generated.DashboardTagForm => ({
  dashboardTagId: dashboardTagForm.dashboardTagId
    ? _.toNumber(dashboardTagForm.dashboardTagId)
    : undefined,
  name: dashboardTagForm.name,
  isDeleted: dashboardTagForm.isDeleted,
});

const toMutation = (dashboardTagForm: generated.DashboardTagForm): generated.DashboardTagForm => ({
  dashboardTagId: dashboardTagForm.dashboardTagId,
  name: dashboardTagForm.name,
  isDeleted: dashboardTagForm.isDeleted,
});

const DashboardTagForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default DashboardTagForm;
