// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import ValueForm from '@shared/modules/Billing/forms/ValueForm';

const edit = withFragment(
  (values) => ({
    valueForms: (values as any).map((value: any) => ValueForm.edit(value)),
  }),
  gql`
    ${ValueForm.edit.fragment}
    fragment DisplayValuesForm_edit on Value {
      id
      ...ValueForm_edit
    }
  `,
);

const toForm = ({valueForms}: any) => ({
  valueForms: valueForms.map((valueForm: any) => ValueForm.toForm(valueForm)),
});

const DisplayValuesForm = {
  // Initialize
  edit,
  toForm,
};

export default DisplayValuesForm;
