// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {useState} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';

const EditSectionNameModal = ({
  sectionName,
  isOpen,
  handleClose,
  handleUpdate,
}: {
  sectionName: string;
  isOpen: boolean;
  handleClose: () => void;
  handleUpdate: (name: string) => void;
}) => {
  const [name, setName] = useState(sectionName);

  const handleSubmit = () => {
    handleUpdate(name);
    handleClose();
  };

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Edit Section Name'}
      handleAction={handleSubmit}
      handleActionText={'Save'}
      isDisabledPrimaryAction={!name}
    >
      <FieldInput
        label={'Section Name'}
        isRequired
        value={name}
        handleEnter={handleSubmit}
        input={{
          placeholder: 'Enter section name',
          onChangeText: setName,
          autoFocus: true,
        }}
      />
      <Space height={16} />
    </SmallModal>
  );
};

export default EditSectionNameModal;
