// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';
import useUpdateWorkflowMutation from '@shared/modules/Workflow/hooks/useUpdateWorkflowMutation';
import WorkflowDrawerFields from 'modules/Organization/Settings/Workflow/components/WorkflowDrawerFields';

const UpdateWorkflowDrawer = ({handleClose, isOpen, onSuccess, workflow}) => {
  const workflowForm = WorkflowForm.edit(workflow);
  const {form, submitting, handleSubmit} = useUpdateWorkflowMutation({
    workflowForm,
    onSuccess: () => {
      onSuccess();
      form.handleReset();
    },
    onError: (error) => console.log(error),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Update Automation'}
      primaryActionText={'Update Automation'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <WorkflowDrawerFields form={form} />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateWorkflowDrawer.fragment = gql`
  ${WorkflowForm.edit.fragment}
  fragment UpdateWorkflowDrawer on Workflow {
    id
    ...WorkflowForm_edit
  }
`;

export default UpdateWorkflowDrawer;
