// Libraries
import React from 'react';

// Components
import {SidebarPage} from 'modules/App/components';

const JobsPage = ({variables, query, children, ...props}) => (
  <SidebarPage
    {...props}
    selected={'jobs'}
    variables={variables}
    query={query}
    children={children}
  />
);

export default JobsPage;
