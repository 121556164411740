// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, DropdownInput, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import JobForm from '@shared/modules/Job/forms/JobForm';
import JobValueFields from 'modules/Project/Billing/components/JobValueFields';
import ProjectValueFields from 'modules/Project/Billing/components/ProjectValueFields';
import ValueField from 'modules/Project/Billing/components/ValueField';

const INPUT_WIDTH = 250;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index}px;
`;

const Title = Styled.H4`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  z-index: ${({index = 0}) => 300 - index};
`;

const ValueRow = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  z-index: ${({index = 0, valueRowIndex}) => valueRowIndex - index};
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 15)}px;
`;

const FieldSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 0)}px;
  margin-left: ${(props) => (props.mobile ? 0 : 10)}px;
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const getFieldName = ({field, name}) => {
  return field ? `${field}.${name}` : name;
};

const getFieldValue = ({form, field, name}) => {
  return _.get(form.values, `${field}.${name}`);
};

const getHourlyRate = ({crewSize, jobFormCustomValues}) => {
  const option = _.find(jobFormCustomValues.crewSize, (option) => option.value === crewSize);
  return String(_.get(option, 'hourlyRate', ''));
};

const getTravelFee = ({crewSize, jobFormCustomValues}) => {
  const option = _.find(jobFormCustomValues.crewSize, (option) => option.value === crewSize);
  return String(_.get(option, 'travelFee', ''));
};

const getFuelFee = ({crewSize, jobFormCustomValues}) => {
  const option = _.find(jobFormCustomValues.crewSize, (option) => option.value === crewSize);
  const value = String(_.get(option, 'fuelFee', ''));
  return value ? Currency.display(value, {shouldHideCentsIfZero: true}) : '';
};

const updateFieldsFromCrewSize = ({crewSize, field, form, jobFormCustomValues}) => {
  const hourlyRate = getHourlyRate({crewSize, jobFormCustomValues});
  const travelFee = getTravelFee({crewSize, jobFormCustomValues});
  const fuelFee = getFuelFee({crewSize, jobFormCustomValues});
  // Set the hourlyRate, travelFee, and fuelFee on the form if custom values are available.
  form.setFieldValue(getFieldName({field, name: 'hourlyRate'}), hourlyRate);
  form.setFieldValue(getFieldName({field, name: 'travelFee'}), travelFee);
  form.setFieldValue(getFieldName({field, name: 'fuelFee'}), fuelFee);
};

const CrewSizeField = ({disabled, index, field, form, organization}) => {
  const {isEnabledCrewSizeIsRequired, isEnabledCrewSizeIsNumberInput} = organization.features;
  const isRequired = isEnabledCrewSizeIsRequired && !getFieldValue({form, field, name: 'crewSize'});

  if (isEnabledCrewSizeIsNumberInput) {
    return (
      <FieldInput.Memoized
        {...form}
        index={index}
        name={getFieldName({field, name: 'crewSize'})}
        label={'# of Movers'}
        input={{
          disabled,
          required: isRequired,
          placeholder: 'Enter number',
          keyboardType: 'phone-pad',
        }}
        style={{
          width: INPUT_WIDTH,
        }}
      />
    );
  }

  return (
    <FieldInput.Memoized
      {...form}
      index={index}
      component={DropdownInput}
      name={getFieldName({field, name: 'crewSize'})}
      label={'# of Movers'}
      input={{
        disabled,
        required: isRequired,
        isSearchable: true,
        options: form.values.jobFormCustomValues.crewSize,
        placeholder: 'Enter size',
        setFieldValue: form.setFieldValue,
        onChangeValue: (value) => {
          updateFieldsFromCrewSize({
            crewSize: value,
            field,
            form,
            jobFormCustomValues: form.values.jobFormCustomValues,
          });
        },
        style: {
          width: INPUT_WIDTH,
        },
      }}
    />
  );
};

const MoveRateFields = ({disabled, field, form, organization, responsive}) => {
  return (
    <React.Fragment>
      <React.Fragment>
        <FieldInput.Memoized
          {...form}
          index={1}
          name={getFieldName({field, name: 'hourlyRate'})}
          label={'Hourly Rate'}
          input={{
            disabled,
            placeholder: 'Enter rate',
            keyboardType: 'phone-pad',
          }}
          style={{
            width: INPUT_WIDTH,
          }}
        />
        <FieldSpace {...responsive} />
        <FieldInput.Memoized
          {...form}
          index={2}
          name={getFieldName({field, name: 'travelFee'})}
          label={'Flat Fee'}
          input={{
            disabled,
            placeholder: 'Enter fee',
            keyboardType: 'phone-pad',
          }}
          style={{
            width: INPUT_WIDTH,
          }}
        />
      </React.Fragment>
      {organization.hasFuelFee && (
        <React.Fragment>
          <FieldSpace {...responsive} />
          <FieldInput.Memoized
            {...form}
            index={3}
            component={CurrencyInput}
            name={getFieldName({field, name: 'fuelFee'})}
            label={'Fuel Fee'}
            input={{
              disabled,
              shouldHideCentsIfZero: true,
              component: TextInput,
              placeholder: 'Enter fuel fee',
              setFieldValue: form.setFieldValue,
              setFieldTouched: form.setFieldTouched,
            }}
            style={{
              width: INPUT_WIDTH,
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const NumberOfTrucksField = ({disabled, index, field, form}) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={index}
      name={getFieldName({field, name: 'numberOfTrucks'})}
      label={'# of Trucks'}
      input={{
        disabled,
        placeholder: 'Enter trucks',
        keyboardType: 'phone-pad',
      }}
      style={{
        width: INPUT_WIDTH,
      }}
    />
  );
};

const DefaultMoveSection = ({
  disabled,
  isUpdate,
  field,
  form,
  responsive,
  organization,
  isMoversInputVisible,
  isTrucksInputVisible,
  isHourMinimumVisible,
}) => {
  return (
    <React.Fragment>
      <Row index={2} {...responsive}>
        {isMoversInputVisible && (
          <CrewSizeField
            disabled={disabled}
            index={1}
            field={field}
            form={form}
            organization={organization}
          />
        )}
        {isMoversInputVisible && isTrucksInputVisible && <FieldSpace {...responsive} />}
        {isTrucksInputVisible && (
          <NumberOfTrucksField disabled={disabled} index={2} field={field} form={form} />
        )}
      </Row>
      {!isUpdate && organization.features.isEnabledShowBillingInputs && (
        <React.Fragment>
          <RowSpace {...responsive} />
          <Row index={3} {...responsive}>
            <MoveRateFields
              disabled={disabled}
              field={field}
              form={form}
              organization={organization}
              responsive={responsive}
            />
          </Row>
        </React.Fragment>
      )}
      {!isUpdate && organization.hasTimeStartsAt && (
        <React.Fragment>
          <RowSpace {...responsive} />
          <Row index={4} {...responsive}>
            <FieldInput.Memoized
              {...form}
              index={0}
              name={getFieldName({field, name: 'confirmationBillingStart'})}
              label={'Time Starts At'}
              input={{
                disabled,
                placeholder: 'Enter description',
              }}
              style={{
                width: responsive.mobile ? '100%' : 250,
              }}
            />
            <FieldSpace {...responsive} />
            <FieldInput.Memoized
              {...form}
              index={1}
              name={getFieldName({field, name: 'confirmationBillingEnd'})}
              label={'Time Ends At'}
              input={{
                disabled,
                placeholder: 'Enter description',
              }}
              style={{
                width: responsive.mobile ? '100%' : 250,
              }}
            />
          </Row>
        </React.Fragment>
      )}
      {isHourMinimumVisible && !isUpdate && organization.features.isEnabledShowJobHourMinimum && (
        <React.Fragment>
          <RowSpace {...responsive} />
          <Row index={5} {...responsive}>
            <FieldInput.Memoized
              {...form}
              index={0}
              name={getFieldName({field, name: 'hourMinimum'})}
              label={'Hour Minimum'}
              input={{
                disabled,
                placeholder: 'Enter hours',
                keyboardType: 'phone-pad',
              }}
              style={{
                width: INPUT_WIDTH,
              }}
            />
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const CommercialMoveSection = ({
  disabled,
  field,
  form,
  responsive,
  isMoversInputVisible,
  isTrucksInputVisible,
}) => {
  if (!isMoversInputVisible && !isTrucksInputVisible) {
    return null;
  }
  return (
    <Row index={2} {...responsive}>
      {isMoversInputVisible && (
        <FieldInput.Memoized
          {...form}
          index={0}
          name={getFieldName({field, name: 'crewSize'})}
          label={'# of Movers'}
          input={{
            disabled,
            required: !getFieldValue({form, field, name: 'crewSize'}),
            placeholder: 'Enter number',
            keyboardType: 'phone-pad',
          }}
          style={{
            width: INPUT_WIDTH,
          }}
        />
      )}
      {isMoversInputVisible && isTrucksInputVisible && <FieldSpace {...responsive} />}
      {isTrucksInputVisible && (
        <NumberOfTrucksField disabled={disabled} index={1} field={field} form={form} />
      )}
    </Row>
  );
};

const BillingFields = ({disabled, isUpdate, kind, jobField, form, responsive, organization}) => {
  const {isMoversInputVisible, isTrucksInputVisible, isHourMinimumVisible} =
    JobForm.getDispatchFieldVisibility(_.get(form.values, `${jobField}`), {
      isEnabledManualJobHourMinimum: organization.features.isEnabledManualJobHourMinimum,
    });

  switch (kind) {
    case Job.KIND.COMMERCIAL:
      return (
        <CommercialMoveSection
          disabled={disabled}
          isUpdate={isUpdate}
          field={jobField}
          form={form}
          responsive={responsive}
          organization={organization}
          isMoversInputVisible={isMoversInputVisible}
          isTrucksInputVisible={isTrucksInputVisible}
        />
      );
    default:
      return (
        <DefaultMoveSection
          disabled={disabled}
          isUpdate={isUpdate}
          field={jobField}
          form={form}
          responsive={responsive}
          organization={organization}
          isMoversInputVisible={isMoversInputVisible}
          isTrucksInputVisible={isTrucksInputVisible}
          isHourMinimumVisible={isHourMinimumVisible}
        />
      );
  }
};

const ValuesRow = Styled.View`
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 520px;
`;

const ValueItem = Styled.View`
  width: ${INPUT_WIDTH}px;
  margin-right: 10px;
  margin-bottom: 10px;
  z-index: ${(props) => 100 - props.index};
`;

const ValueFields = ({
  index,
  field,
  form,
  valueForms,
  isJobValueForm,
  startingValueFormIndex = 0,
}) => {
  const valueRowIndex = isJobValueForm ? 100 : 200;

  if (valueForms.length === 0) {
    return null;
  }

  return (
    <ValueRow index={index} valueRowIndex={valueRowIndex}>
      <ValuesRow>
        {valueForms.map((valueForm, index) => {
          // We only show the ValueForms that are visible for create project.
          if (!valueForm.isVisibleForCreateProject) {
            return null;
          }
          const valueFormIndex = startingValueFormIndex + index;

          return (
            <ValueItem key={valueFormIndex} index={valueFormIndex}>
              <ValueField
                required={valueForm.isRequiredForCreateProject}
                field={`${field}.${valueFormIndex}`}
                form={form}
                isTruncatedLabel
              />
            </ValueItem>
          );
        })}
      </ValuesRow>
    </ValueRow>
  );
};

const JobBillingFields = ({
  disabled,
  isUpdate,
  index,
  field,
  jobField,
  form,
  responsive,
  organization,
}) => {
  const kind = _.get(form.values, getFieldName({field, name: 'kind'}));

  // Estimates do not render any billing fields.
  if (kind === Job.KIND.ESTIMATE) {
    return null;
  }

  const projectValueFormsField = `${field}.valueForms`;
  const jobValueFormsField = `${jobField}.valueForms`;

  return (
    <Section data-test-id='form-billing-information-section' index={index}>
      <Title vars={responsive}>Billing Information</Title>
      <BillingFields
        disabled={disabled}
        isUpdate={isUpdate}
        kind={kind}
        jobField={jobField}
        form={form}
        responsive={responsive}
        organization={organization}
      />
      <RowSpace {...responsive} />
      <ProjectValueFields
        index={3}
        projectTypeField={`${field}.projectTypeId`}
        valueFormsField={projectValueFormsField}
        form={form}
        isEdit={isUpdate}
      >
        {({index, valueForms, valueFormsField, form, startingValueFormIndex}) => (
          <ValueFields
            index={index}
            valueForms={valueForms}
            field={valueFormsField}
            form={form}
            startingValueFormIndex={startingValueFormIndex}
          />
        )}
      </ProjectValueFields>
      <JobValueFields
        index={4}
        jobTypeField={`${jobField}.jobTypeId`}
        valueFormsField={jobValueFormsField}
        form={form}
        isEdit={isUpdate}
      >
        {({index, valueForms, valueFormsField, form, startingValueFormIndex}) => (
          <ValueFields
            index={index}
            valueForms={valueForms}
            field={valueFormsField}
            form={form}
            startingValueFormIndex={startingValueFormIndex}
          />
        )}
      </JobValueFields>
    </Section>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobBillingFields.fragment = gql`
  fragment JobBillingFields on Organization {
    id
    slug
    hasFuelFee
    hasTimeStartsAt
    features {
      isEnabledCrewSizeIsNumberInput: isEnabled(feature: "CREW_SIZE_IS_NUMBER_INPUT")
      isEnabledCrewSizeIsRequired: isEnabled(feature: "CREW_SIZE_IS_REQUIRED")
      isEnabledJobIdentifierField: isEnabled(feature: "JOB_IDENTIFIER_FIELD")
      isEnabledShowBillingInputs: isEnabled(feature: "SHOW_BILLING_INPUTS")
      isEnabledShowJobHourMinimum: isEnabled(feature: "SHOW_JOB_HOUR_MINIMUM")
    }
  }
`;

export default JobBillingFields;
