// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SetCrewSlotsToCrewForm from '@shared/modules/Dispatch/forms/SetCrewSlotsToCrewForm';

const useSetCrewSlotsToCrewMutation = ({
  setCrewSlotsToCrewForm,
  refetchQueries,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      setCrewSlotsToCrewForm: SetCrewSlotsToCrewForm.toForm(setCrewSlotsToCrewForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSetCrewSlotsToCrewMutation.mutation,
    variables: {
      setCrewSlotsToCrewForm: SetCrewSlotsToCrewForm.toMutation(form.values.setCrewSlotsToCrewForm),
    },
    refetchQueries,
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useSetCrewSlotsToCrewMutation.mutation = gql`
  mutation useSetCrewSlotsToCrewMutation(
    $setCrewSlotsToCrewForm: SetCrewSlotsToCrewForm!
  ) {
    response: setCrewSlotsToCrew(
      setCrewSlotsToCrewForm: $setCrewSlotsToCrewForm
    ) {
      ${gql.errors}
      # NOTE: This is a hack to get updated job user data back from the server for the CapacityCalendarJobsListTable
      job {
        id
        jobTrucks {
          id
          truck {
            id
            name
            organizationId
          }
        }
        numberOfAssignedTrucks
        numberOfTrucks
      }
    }
  }
`;

export default useSetCrewSlotsToCrewMutation;
