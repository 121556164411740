// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components
import SmallModal from '@shared/design/components/Modal/SmallModal';

const getInvoiceListForMap = (slugToInvoicesMap) => {
  return Object.keys(slugToInvoicesMap).reduce((invoiceList, key) => {
    return invoiceList.concat(slugToInvoicesMap[key]);
  }, []);
};

const getInvoiceIdListFromValidInvoice = (validInvoices) => {
  return Object.keys(validInvoices).reduce((invoiceList, key) => {
    validInvoices[key].forEach((invoice) => {
      invoiceList.push(invoice.id);
    });
    return invoiceList;
  }, []);
};

const getValidInvoicesForCodatExport = ({invoices, selectedInvoices}) => {
  const validInvoices = {};
  const invalidInvoices = {};
  const invoicesMissingDates = [];
  // selectedInvoices is a list of invoice Ids
  selectedInvoices.forEach((selectedInvoiceId) => {
    // Find the invoice that matches the selectedInvoiceId
    const selectedInvoice = invoices.find((invoice) => invoice.id === selectedInvoiceId);
    if (!selectedInvoice) {
      return;
    }
    if (!selectedInvoice.date || !selectedInvoice.hasAtLeastOneInvoiceItem) {
      invoicesMissingDates.push(selectedInvoice);
      return;
    }
    const selectedInvoiceOrganization = selectedInvoice.project.projectType.organization;
    // Send the correct invoice list status based on codat integration to the dictionaries
    if (selectedInvoiceOrganization.activeCodatIntegration) {
      validInvoices[selectedInvoiceOrganization.name]
        ? validInvoices[selectedInvoiceOrganization.name].push(selectedInvoice)
        : (validInvoices[selectedInvoiceOrganization.name] = [selectedInvoice]);
      // We need to check for the name since its being stored as a key, selectedInvoiceOrganization can be null
    } else if (selectedInvoiceOrganization.name) {
      invalidInvoices[selectedInvoiceOrganization.name]
        ? invalidInvoices[selectedInvoiceOrganization.name].push(selectedInvoice)
        : (invalidInvoices[selectedInvoiceOrganization.name] = [selectedInvoice]);
    }
  });

  return {
    validInvoices,
    invalidInvoices,
    invoicesMissingDates,
  };
};

const ConfirmationBulkCodatExportModalHeader = ({invoicesForCodatExport}) => {
  const invalidInvoicesCount = getInvoiceListForMap(invoicesForCodatExport.invalidInvoices);
  const validInvoicesCount = getInvoiceListForMap(invoicesForCodatExport.validInvoices);
  const invalidBranchNames = Object.keys(invoicesForCodatExport.invalidInvoices).join('\n');
  const invalidBranchText = `because an accounting integration has not been set up for branches:\n\n${invalidBranchNames}`;
  const invoicesMissingDatesText = `because ${invoicesForCodatExport.invoicesMissingDates.length} invoices are missing dates or have no line items.`;
  const combinedInvalidText = [
    invalidInvoicesCount.length > 0 ? invalidBranchText : null,
    invoicesForCodatExport.invoicesMissingDates.length > 0 ? invoicesMissingDatesText : null,
  ]
    .filter(Boolean)
    .join(' and ');
  const totalInvalidCount =
    invalidInvoicesCount.length + invoicesForCodatExport.invoicesMissingDates.length;
  return (
    <React.Fragment>
      <SmallModal.HeaderText>Queue Invoices for Export</SmallModal.HeaderText>
      <Space height={16} />
      {totalInvalidCount === 0 ? (
        <SmallModal.Text>{`${validInvoicesCount.length} invoices will be queued for export`}</SmallModal.Text>
      ) : (
        <SmallModal.Text>{`${validInvoicesCount.length} invoices will be queued for export and ${totalInvalidCount} invoices will be skipped ${combinedInvalidText}`}</SmallModal.Text>
      )}
    </React.Fragment>
  );
};

const ConfirmationBulkCodatExportModalFooter = ({
  handleClose,
  handleSubmit,
  invoicesForCodatExport,
}) => {
  return (
    <SmallModal.Footer>
      <SmallModal.Button textColor={colors.gray.secondary} onPress={handleClose}>
        Cancel
      </SmallModal.Button>
      <SmallModal.Button
        color={colors.blue.interactive}
        onPress={handleSubmit}
        isDisabled={_.isEmpty(invoicesForCodatExport.validInvoices)}
      >
        <Space width={4} />
        Queue invoices
      </SmallModal.Button>
    </SmallModal.Footer>
  );
};

const ConfirmationBulkCodatExportModal = ({
  selectedInvoices,
  invoices,
  isOpen,
  handleClose,
  handleSubmitBulkSendInvoicesToCodat,
  setSelectedInvoices,
}) => {
  const invoicesForCodatExport = getValidInvoicesForCodatExport({selectedInvoices, invoices});
  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose} style={{overflow: 'visible'}}>
      <ConfirmationBulkCodatExportModalHeader invoicesForCodatExport={invoicesForCodatExport} />
      <Space height={16} />
      <ConfirmationBulkCodatExportModalFooter
        handleClose={handleClose}
        invoicesForCodatExport={invoicesForCodatExport}
        handleSubmit={() => {
          setSelectedInvoices(
            getInvoiceIdListFromValidInvoice(invoicesForCodatExport.validInvoices),
          );
          // Setting timeout to ensure submit is on next tick to prevent race condition
          setTimeout(handleSubmitBulkSendInvoicesToCodat, 0);
          handleClose();
        }}
      />
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmationBulkCodatExportModal.fragment = gql`
  fragment ConfirmationBulkCodatExportModal on Invoice {
    id
    date
    hasAtLeastOneInvoiceItem
    project {
      id
      projectType {
        id
        organization {
          id
          name
          activeCodatIntegration {
            id
          }
        }
      }
    }
  }
`;

export default ConfirmationBulkCodatExportModal;
