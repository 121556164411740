// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import SurveyMethodForm from '@shared/modules/Inventory/forms/SurveyMethodForm';

const edit = withFragment(
  (surveyMethods) => ({
    surveyMethodForms: (surveyMethods as any).map((surveyMethod: any) => {
      return SurveyMethodForm.edit(surveyMethod);
    }),
  }),
  gql`
    ${SurveyMethodForm.edit.fragment}
    fragment ReorderSurveyMethodsForm_edit on SurveyMethod {
      id
      ...SurveyMethodForm_edit
    }
  `,
);

const toForm = ({surveyMethodForms}: any) => ({
  surveyMethodForms: surveyMethodForms.map((surveyMethodForm: any) => {
    return SurveyMethodForm.toForm(surveyMethodForm);
  }),
});

const toMutation = ({surveyMethodForms}: any) => ({
  surveyMethodForms: surveyMethodForms.map((surveyMethodForm: any) => {
    return SurveyMethodForm.toMutation(surveyMethodForm);
  }),
});

const ReorderSurveyMethodsForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderSurveyMethodsForm;
