// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const KIND_CUSTOM = 'CUSTOM';
const KIND_CUSTOM_INVOICE = 'CUSTOM_INVOICE';
const KIND_CUSTOM_INVOICE_RECEIPT = 'CUSTOM_INVOICE_RECEIPT';
const KIND_CONFIRMATION = 'CONFIRMATION';
const KIND_CUSTOMER_UPCOMING_JOB = 'CUSTOMER_UPCOMING_JOB';
const KIND_CONFIRMATION_COMPLETE = 'CONFIRMATION_COMPLETE';
const KIND_QUOTE = 'QUOTE';
const KIND_MOVE_COMPLETE = 'MOVE_COMPLETE';
const KIND_PRE_MOVE_DOCUMENTS_COMPLETE = 'PRE_MOVE_DOCUMENTS_COMPLETE';
const KIND_DURING_MOVE_DOCUMENT_COMPLETE = 'DURING_MOVE_DOCUMENT_COMPLETE';
const KIND_MOVE_RECEIPT_SEND = 'MOVE_RECEIPT_SEND';
const KIND_NEW_PROJECT = 'NEW_PROJECT';
const KIND_NEW_VIRTUAL_WALKTHROUGH = 'NEW_VIRTUAL_WALKTHROUGH';
const KIND_AFTER_TIMESHEET = 'AFTER_TIMESHEET';

// DEPRECATED
const KIND_INVOICE_CREATE = 'INVOICE_CREATE';
const KIND_STORAGE_INVOICE_CREATE = 'STORAGE_INVOICE_CREATE';
const KIND_INVOICE_RECEIPT = 'INVOICE_RECEIPT';

const KIND_DISPLAY_CUSTOM = 'Custom email template';
const KIND_DISPLAY_CUSTOM_INVOICE = 'Custom invoice email template';
const KIND_DISPLAY_CUSTOM_INVOICE_RECEIPT = 'Custom invoice receipt email template';
const KIND_DISPLAY_CONFIRMATION = 'Confirmation email template';
const KIND_DISPLAY_CONFIRMATION_COMPLETE = 'Confirmation complete email template';
const KIND_DISPLAY_CUSTOMER_UPCOMING_JOB = 'Customer upcoming job template';
const KIND_DISPLAY_QUOTE = 'Quote email template';
const KIND_DISPLAY_MOVE_COMPLETE = 'Move complete email template';
const KIND_DISPLAY_PRE_MOVE_DOCUMENTS_COMPLETE = 'Before move documents signed email template';
const KIND_DISPLAY_MOVE_DOCUMENT_COMPLETE = 'During move document signed email template';
const KIND_DISPLAY_MOVE_RECEIPT_SEND = 'Send move receipt email template';
const KIND_DISPLAY_NEW_PROJECT = 'New lead template';
const KIND_DISPLAY_NEW_VIRTUAL_WALKTHROUGH = 'New virtual walkthrough template';
const KIND_DISPLAY_AFTER_TIMESHEET = 'Documents signed after timesheet';

// DEPRECATED
const KIND_DISPLAY_INVOICE_CREATE = 'Send move invoice email template';
const KIND_DISPLAY_STORAGE_INVOICE_CREATE = 'Send storage invoice email template';
const KIND_DISPLAY_INVOICE_RECEIPT = 'Custom Storage Receipt Email Template';

const STRING_TEMPLATE_CONFIRMATION_STEPS = '{CONFIRMATION_STEPS}';

// Email template recipient values
const PROJECT_COORDINATOR = 'PROJECT_COORDINATOR';
const PROJECT_CUSTOMER = 'PROJECT_CUSTOMER';
const PROJECT_SALESPERSON = 'PROJECT_SALESPERSON';
const PROJECT_BRANCH = 'PROJECT_BRANCH';
const COMPANY_NOTIFICATION_EMAILS = 'COMPANY_NOTIFICATION_EMAILS';
const ADDITIONAL_SALESPERSON_EMAILS = 'ADDITIONAL_SALESPERSON_EMAILS';
const INVOICE_BILL_TO = 'INVOICE_BILL_TO';
const BILL_TO_CLIENT_CONTACT_EMAIL = 'BILL_TO_CLIENT_CONTACT_EMAIL';

// Email template recipient display values
const PROJECT_COORDINATOR_DISPLAY = '{COORDINATOR}';
const PROJECT_CUSTOMER_DISPLAY = '{CUSTOMER}';
const PROJECT_SALESPERSON_DISPLAY = '{SALESPERSON}';
const PROJECT_BRANCH_DISPLAY = '{PROJECT_BRANCH}';
const COMPANY_NOTIFICATION_EMAILS_DISPLAY = '{COMPANY_NOTIFICATION_EMAILS}';
const ADDITIONAL_SALESPERSON_EMAILS_DISPLAY = '{ADDITIONAL_SALESPERSON_EMAILS}';
const INVOICE_BILL_TO_DISPLAY = '{INVOICE_BILL_TO}';
const BILL_TO_CLIENT_CONTACT_EMAIL_DISPLAY = '{BILL_TO_CLIENT_CONTACT_EMAIL}';

const RECIPIENT_DROPDOWN_OPTIONS = [
  {
    label: PROJECT_COORDINATOR_DISPLAY,
    value: PROJECT_COORDINATOR,
  },
  {
    label: PROJECT_CUSTOMER_DISPLAY,
    value: PROJECT_CUSTOMER,
  },
  {
    label: PROJECT_SALESPERSON_DISPLAY,
    value: PROJECT_SALESPERSON,
  },
  {
    label: PROJECT_BRANCH_DISPLAY,
    value: PROJECT_BRANCH,
  },
  {
    label: COMPANY_NOTIFICATION_EMAILS_DISPLAY,
    value: COMPANY_NOTIFICATION_EMAILS,
  },
  {
    label: ADDITIONAL_SALESPERSON_EMAILS_DISPLAY,
    value: ADDITIONAL_SALESPERSON_EMAILS,
  },
  {
    label: BILL_TO_CLIENT_CONTACT_EMAIL_DISPLAY,
    value: BILL_TO_CLIENT_CONTACT_EMAIL,
  },
];

const getRecipientDropdownOptions = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ organization }: { organizatio... Remove this comment to see the full error message
  ({organization}) => {
    return _.filter(RECIPIENT_DROPDOWN_OPTIONS, (option) => {
      if (option.value === EmailTemplate.RECIPIENT_VALUES.PROJECT_BRANCH) {
        return organization.features.isEnabledBillingAdditionalVariables;
      }
      return true;
    });
  },
  gql`
    fragment EmailTemplate_getRecipientDropdownOptions on Organization {
      id
      features {
        isEnabledBillingAdditionalVariables: isEnabled(feature: "BILLING_ADDITIONAL_VARIABLES")
      }
    }
  `,
);

const RECIPIENT_VALUES = {
  PROJECT_COORDINATOR,
  PROJECT_CUSTOMER,
  PROJECT_SALESPERSON,
  PROJECT_BRANCH,
  COMPANY_NOTIFICATION_EMAILS,
  ADDITIONAL_SALESPERSON_EMAILS,
  INVOICE_BILL_TO,
  BILL_TO_CLIENT_CONTACT_EMAIL,
};

const RECIPIENT_DISPLAY_VALUES = {
  [PROJECT_COORDINATOR]: PROJECT_COORDINATOR_DISPLAY,
  [PROJECT_CUSTOMER]: PROJECT_CUSTOMER_DISPLAY,
  [PROJECT_SALESPERSON]: PROJECT_SALESPERSON_DISPLAY,
  [PROJECT_BRANCH]: PROJECT_BRANCH_DISPLAY,
  [COMPANY_NOTIFICATION_EMAILS]: COMPANY_NOTIFICATION_EMAILS_DISPLAY,
  [ADDITIONAL_SALESPERSON_EMAILS]: ADDITIONAL_SALESPERSON_EMAILS_DISPLAY,
  [INVOICE_BILL_TO]: INVOICE_BILL_TO_DISPLAY,
  [BILL_TO_CLIENT_CONTACT_EMAIL]: BILL_TO_CLIENT_CONTACT_EMAIL,
};

const getDisplayFromKind = (kind: any) => {
  switch (kind) {
    case KIND_CUSTOM:
      return KIND_DISPLAY_CUSTOM;
    case KIND_CUSTOM_INVOICE:
      return KIND_DISPLAY_CUSTOM_INVOICE;
    case KIND_CUSTOM_INVOICE_RECEIPT:
      return KIND_DISPLAY_CUSTOM_INVOICE_RECEIPT;
    case KIND_CONFIRMATION:
      return KIND_DISPLAY_CONFIRMATION;
    case KIND_CUSTOMER_UPCOMING_JOB:
      return KIND_DISPLAY_CUSTOMER_UPCOMING_JOB;
    case KIND_CONFIRMATION_COMPLETE:
      return KIND_DISPLAY_CONFIRMATION_COMPLETE;
    case KIND_QUOTE:
      return KIND_DISPLAY_QUOTE;
    case KIND_MOVE_COMPLETE:
      return KIND_DISPLAY_MOVE_COMPLETE;
    case KIND_PRE_MOVE_DOCUMENTS_COMPLETE:
      return KIND_DISPLAY_PRE_MOVE_DOCUMENTS_COMPLETE;
    case KIND_DURING_MOVE_DOCUMENT_COMPLETE:
      return KIND_DISPLAY_MOVE_DOCUMENT_COMPLETE;
    case KIND_INVOICE_CREATE:
      return KIND_DISPLAY_INVOICE_CREATE;
    case KIND_STORAGE_INVOICE_CREATE:
      return KIND_DISPLAY_STORAGE_INVOICE_CREATE;
    case KIND_INVOICE_RECEIPT:
      return KIND_DISPLAY_INVOICE_RECEIPT;
    case KIND_MOVE_RECEIPT_SEND:
      return KIND_DISPLAY_MOVE_RECEIPT_SEND;
    case KIND_NEW_PROJECT:
      return KIND_DISPLAY_NEW_PROJECT;
    case KIND_NEW_VIRTUAL_WALKTHROUGH:
      return KIND_DISPLAY_NEW_VIRTUAL_WALKTHROUGH;
    case KIND_AFTER_TIMESHEET:
      return KIND_DISPLAY_AFTER_TIMESHEET;
    default:
      return '';
  }
};

const getCustomRecipientDropdownOptions = withFragment(
  (emailTemplate) => {
    const customRecipientOptions: any = [];
    _.get(emailTemplate, 'toEmailTemplateRecipients', []).forEach(({email}: any) => {
      if (email) {
        customRecipientOptions.push({label: email, value: email});
      }
    });
    _.get(emailTemplate, 'ccEmailTemplateRecipients', []).forEach(({email}: any) => {
      if (email) {
        customRecipientOptions.push({label: email, value: email});
      }
    });
    _.get(emailTemplate, 'bccEmailTemplateRecipients', []).forEach(({email}: any) => {
      if (email) {
        customRecipientOptions.push({label: email, value: email});
      }
    });
    return customRecipientOptions;
  },
  gql`
    fragment EmailTemplate_getCustomRecipientDropdownOptions on EmailTemplate {
      id
      toEmailTemplateRecipients {
        email
      }
      ccEmailTemplateRecipients {
        email
      }
      bccEmailTemplateRecipients {
        email
      }
    }
  `,
);

const isCustomEmailTemplate = (emailTemplate: any) => {
  const customEmailTemplates = [
    EmailTemplate.KINDS.CUSTOM,
    EmailTemplate.KINDS.CUSTOM_INVOICE,
    EmailTemplate.KINDS.CUSTOM_INVOICE_RECEIPT,
  ];

  return customEmailTemplates.includes(emailTemplate.kind);
};

const isDeprecatedEmailTemplate = (emailTemplate: any) => {
  const deprecatedEmailTemplates = [
    EmailTemplate.KINDS.INVOICE_CREATE,
    EmailTemplate.KINDS.STORAGE_INVOICE_CREATE,
    EmailTemplate.KINDS.INVOICE_RECEIPT,
  ];

  return deprecatedEmailTemplates.includes(emailTemplate.kind);
};

const isInvoiceSystemEmailTemplate = (emailTemplate: any) => {
  const systemCreatedInvoiceTemplateNames = [
    'Default Move Invoice',
    'Default Storage Invoice',
    'Default Move Receipt',
    'Default Storage Receipt',
  ];

  return systemCreatedInvoiceTemplateNames.includes(emailTemplate.name);
};

const CUSTOM_EMAIL_TEMPLATE = {
  id: '',
  kind: KIND_CUSTOM,
  name: getDisplayFromKind(KIND_CUSTOM),
  subject: ``,
  body: ``,
  emailTemplateAttachments: [],
  toEmailTemplateRecipients: [],
  ccEmailTemplateRecipients: [],
  bccEmailTemplateRecipients: [],
};

const EmailTemplate = {
  CUSTOM_EMAIL_TEMPLATE,

  KINDS: {
    // Custom email template kinds.
    [KIND_CUSTOM]: KIND_CUSTOM,
    [KIND_CUSTOM_INVOICE]: KIND_CUSTOM_INVOICE,
    [KIND_CUSTOM_INVOICE_RECEIPT]: KIND_CUSTOM_INVOICE_RECEIPT,
    // Special email template kinds.
    [KIND_CONFIRMATION]: KIND_CONFIRMATION,
    [KIND_CONFIRMATION_COMPLETE]: KIND_CONFIRMATION_COMPLETE,
    [KIND_CUSTOMER_UPCOMING_JOB]: KIND_CUSTOMER_UPCOMING_JOB,
    [KIND_QUOTE]: KIND_QUOTE,
    [KIND_MOVE_COMPLETE]: KIND_MOVE_COMPLETE,
    [KIND_PRE_MOVE_DOCUMENTS_COMPLETE]: KIND_PRE_MOVE_DOCUMENTS_COMPLETE,
    [KIND_DURING_MOVE_DOCUMENT_COMPLETE]: KIND_DURING_MOVE_DOCUMENT_COMPLETE,
    [KIND_NEW_PROJECT]: KIND_NEW_PROJECT,
    [KIND_NEW_VIRTUAL_WALKTHROUGH]: KIND_NEW_VIRTUAL_WALKTHROUGH,
    [KIND_AFTER_TIMESHEET]: KIND_AFTER_TIMESHEET,

    // DEPRECATED
    [KIND_INVOICE_CREATE]: KIND_INVOICE_CREATE,
    [KIND_STORAGE_INVOICE_CREATE]: KIND_STORAGE_INVOICE_CREATE,
    [KIND_INVOICE_RECEIPT]: KIND_INVOICE_RECEIPT,
  },

  KINDS_DESCRIPTIONS: {
    // CUSTOM
    [KIND_CUSTOM]: 'This email is sent by you. This email may include any information you want.',
    [KIND_CUSTOM_INVOICE]:
      'This email is sent when you send an invoice. This email may include any information you want.',
    [KIND_CUSTOM_INVOICE_RECEIPT]:
      'This email is sent when you send an invoice receipt. This email may include any information you want.',
    // SALES
    [KIND_CONFIRMATION]:
      'This email is sent by you. This email should include a link to the online confirmation process.',
    [KIND_CONFIRMATION_COMPLETE]:
      'This email is sent by Supermove. ' +
      'This email is sent after a customer completes the confirmation process for an upcoming move. ' +
      'This email includes signed documents as attachments.',
    [KIND_QUOTE]:
      'This email is sent by you. This email should include a link to the online quote process.',
    [KIND_CUSTOMER_UPCOMING_JOB]:
      'This email is sent by Supermove based on the configuration you have set up with us. ' +
      'Each morning, we send out the emails to customers for upcoming moves.',
    // LIVE / MOVING DAY
    [KIND_MOVE_COMPLETE]:
      'This email is sent by Supermove. ' +
      'This email is sent after a move is completed on a tablet. ' +
      'This email includes signed documents as attachments.',
    [KIND_PRE_MOVE_DOCUMENTS_COMPLETE]:
      'This email is sent by Supermove. This email is sent after a customer signs documents that allow moving services to begin. This email includes the signed documents as attachments.',
    [KIND_DURING_MOVE_DOCUMENT_COMPLETE]:
      'This email is sent by Supermove. This email is sent after a customer signs a document when moving services are in progress. This email includes the signed document as an attachment.',
    [KIND_MOVE_RECEIPT_SEND]:
      'This email is sent by you. This email includes a receipt as an attachment.',
    [KIND_NEW_PROJECT]:
      'This email is sent by Supermove. It is internal to your organization. It is sent when a new lead comes in.',
    [KIND_NEW_VIRTUAL_WALKTHROUGH]:
      'This email is sent by Supermove. It is internal to your organization. It is sent when a virtual walkthrough is completed.',
    [KIND_AFTER_TIMESHEET]:
      'This email is sent by Supermove after the customer signs the documents that appear after the timesheet step.',

    // DEPRECATED
    [KIND_INVOICE_RECEIPT]:
      'This email is sent automatically after payment is completed and an invoice status is changed to paid.',
    [KIND_INVOICE_CREATE]:
      'This email is sent by you. This email includes an invoice as an attachment.',
    [KIND_STORAGE_INVOICE_CREATE]:
      'This email is sent by you. This email includes a storage invoice as an attachment.',
  },

  KINDS_DROPDOWN_OPTIONS: [
    {label: KIND_DISPLAY_CUSTOM, value: KIND_CUSTOM},
    {label: KIND_DISPLAY_CUSTOM_INVOICE, value: KIND_CUSTOM_INVOICE},
    {label: KIND_DISPLAY_CUSTOM_INVOICE_RECEIPT, value: KIND_CUSTOM_INVOICE_RECEIPT},
    {label: KIND_DISPLAY_CONFIRMATION, value: KIND_CONFIRMATION},
    {label: KIND_DISPLAY_CONFIRMATION_COMPLETE, value: KIND_CONFIRMATION_COMPLETE},
    {label: KIND_DISPLAY_CUSTOMER_UPCOMING_JOB, value: KIND_CUSTOMER_UPCOMING_JOB},
    {label: KIND_DISPLAY_QUOTE, value: KIND_QUOTE},
    {label: KIND_DISPLAY_PRE_MOVE_DOCUMENTS_COMPLETE, value: KIND_PRE_MOVE_DOCUMENTS_COMPLETE},
    {label: KIND_DISPLAY_MOVE_DOCUMENT_COMPLETE, value: KIND_DURING_MOVE_DOCUMENT_COMPLETE},
    {label: KIND_DISPLAY_MOVE_COMPLETE, value: KIND_MOVE_COMPLETE},
    {label: KIND_DISPLAY_MOVE_RECEIPT_SEND, value: KIND_MOVE_RECEIPT_SEND},
    {label: KIND_DISPLAY_NEW_PROJECT, value: KIND_NEW_PROJECT},
    {label: KIND_DISPLAY_NEW_VIRTUAL_WALKTHROUGH, value: KIND_NEW_VIRTUAL_WALKTHROUGH},
    {label: KIND_DISPLAY_AFTER_TIMESHEET, value: KIND_AFTER_TIMESHEET},

    // DEPRECATED
    {label: KIND_DISPLAY_INVOICE_CREATE, value: KIND_INVOICE_CREATE},
    {label: KIND_DISPLAY_STORAGE_INVOICE_CREATE, value: KIND_STORAGE_INVOICE_CREATE},
    {label: KIND_DISPLAY_INVOICE_RECEIPT, value: KIND_INVOICE_RECEIPT},
  ],

  RECIPIENT_DROPDOWN_OPTIONS,
  RECIPIENT_VALUES,
  RECIPIENT_DISPLAY_VALUES,

  getDisplayFromKind,
  getCustomRecipientDropdownOptions,
  isCustomEmailTemplate,
  isDeprecatedEmailTemplate,
  isInvoiceSystemEmailTemplate,
  getRecipientDropdownOptions,
};

export default EmailTemplate;
