// Libraries
import React from 'react';

// Supermove
import {Icon, Modal, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
import CreateMoverPositionForm from '@shared/modules/User/forms/CreateMoverPositionForm';
import useCreateMoverPositionMutation from '@shared/modules/User/hooks/useCreateMoverPositionMutation';
import Field from 'modules/App/components/Field';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: ${(props) => (props.mobile ? 320 : 400)}px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 24px;
`;

const TitleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const InstructionText = Styled.H6`
  color: ${colors.black};
  ${fontWeight(500)}
`;

const FieldsContainer = Styled.View`
`;

const InputLabelText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.tertiary};
`;

const PrimaryText = Styled.Text`
  ${Typography.Body3}
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${(props) => props.color};
`;

const ActivityIndicator = Styled.Loading`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const CheckBox = Styled.Touchable`
  height: 14px;
  width: 14px;
  background-color: ${(props) => (props.isSelected ? colors.blue.interactive : colors.white)};
  border-color: ${(props) => (props.isSelected ? colors.blue.interactive : colors.gray.tertiary)};
  border-width: 1px;
  border-radius: 2px;
  margin-top: 2px;
`;

const AddMoverPositionHeader = () => {
  return (
    <React.Fragment>
      <TitleText>Create Position</TitleText>
      <Space height={16} />
      <InstructionText>{`Fill in the details to create this position.`}</InstructionText>
      <Space height={20} />
      <InputLabelText>Position Name</InputLabelText>
    </React.Fragment>
  );
};

const AddMoverPositionFields = ({form}) => {
  return (
    <FieldsContainer>
      <Field
        {...form}
        name={`createMoverPositionForm.name`}
        input={{
          placeholder: 'Enter a position',
          required: !form.values.createMoverPositionForm.name,
        }}
      />
      <Space height={12} />
      <Row>
        <CheckBox
          isSelected={form.values.createMoverPositionForm.isDriver}
          onPress={() =>
            form.setFieldValue(
              'createMoverPositionForm.isDriver',
              !form.values.createMoverPositionForm.isDriver,
            )
          }
        >
          <Icon source={Icon.Check} size={12} color={colors.white} />
        </CheckBox>
        <Space width={8} />
        <PrimaryText>This position can drive the truck</PrimaryText>
      </Row>
    </FieldsContainer>
  );
};

const AddMoverPositionFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <Actions>
      <Button color={colors.white} onPress={handleClose} disabled={submitting}>
        <ButtonText color={colors.gray.secondary}>Cancel</ButtonText>
      </Button>
      <Space width={8} />
      <Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        onPress={handleSubmit}
        disabled={submitting}
      >
        {submitting ? (
          <ActivityIndicator color={colors.white} size={'small'} />
        ) : (
          <ButtonText color={colors.white}>Confirm</ButtonText>
        )}
      </Button>
    </Actions>
  );
};

const CreateMoverPositionModalV1 = ({handleClose, isOpen, refetch, organizationId}) => {
  const createMoverPositionForm = CreateMoverPositionForm.new({organizationId});

  const {form, submitting, handleSubmit} = useCreateMoverPositionMutation({
    createMoverPositionForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error) => {
      console.log({error});
    },
  });

  const responsive = useResponsive();

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ModalContainer {...responsive}>
          <AddMoverPositionHeader />
          <Space height={4} />
          <AddMoverPositionFields form={form} />
          <Space height={20} />
          <AddMoverPositionFooter
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            submitting={submitting}
          />
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

export default CreateMoverPositionModalV1;
