// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import ConfirmationStepsKind from '@shared/modules/Proposal/enums/ConfirmationStepsKind';
import Page from 'modules/App/components/Page';
import ExpiredProposal from 'modules/Customer/Project/Confirmation/components/ExpiredProposal';

const ExpiredConfirmationProjectPage = ({
  match: {
    params: {uuid},
  },
}) => (
  <Page
    fetchPolicy={'network-only'}
    query={ExpiredConfirmationProjectPage.query}
    variables={{uuid}}
  >
    {({loading, data}) => (
      <Loading loading={loading}>
        {() => <ExpiredProposal kind={ConfirmationStepsKind.CONFIRMATION} project={data.project} />}
      </Loading>
    )}
  </Page>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ExpiredConfirmationProjectPage.query = gql`
  ${ExpiredProposal.fragment}
  query ExpiredConfirmationProjectPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      ...ExpiredProposal
    }
  }
`;

export default ExpiredConfirmationProjectPage;
