// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Icon, Space, DragAndDropList} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDragAndDrop, useModal, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import DocumentV2Image from '@shared/modules/Document/components/DocumentV2Image';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';

const TemplateStructureEditorContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  width: 100%;
  z-index: ${({index = 0}) => 99999 - index};
`;

const Button = Styled.ButtonV2`
  justify-content: center;
  flex: 1;
`;

const ButtonText = Styled.Text`
  ${Typography.Label}
`;

const ButtonDescriptionText = Styled.Text`
  ${Typography.Micro}
`;

const ItemKindItemKebabContainer = Styled.View`
  flex-direction: row;
  flex: 1;
  align-items: center;
  background-color: ${colors.white};
  border-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.border)};
  border-width: ${({isSelected}) => (isSelected ? '2px' : '1px')};
  border-radius: 6px;
  padding-horizontal: 16px;
  padding-vertical: 8px;
`;

const DocumentItemText = Styled.Text`
  ${Typography.Label1}
`;

const DocumentItemDescriptionText = Styled.Text`
  ${Typography.Body};
  color: ${colors.gray.secondary};
`;

const DocumentItemsContainer = Styled.View`
  flex: 1;
  padding-horizontal: 24;
  padding-top: 24;
`;

const IconButton = Styled.ButtonV2`
  width: 16px;
  height: 16px;
  align-items: center;
`;

const swapDocumentItems = (
  documentItems,
  setDraftDocumentContentJson,
  setSelectedDocumentItem,
  fromIndex,
  toIndex,
) => {
  const reorderedDocumentItems = List.move({
    list: documentItems,
    fromIndex,
    toIndex,
  });

  setDraftDocumentContentJson((prevState) => {
    prevState.documentItems = reorderedDocumentItems;

    setSelectedDocumentItem({
      documentItem: reorderedDocumentItems[toIndex],
      index: toIndex,
    });
    return {
      ...prevState,
    };
  });
};

const DocumentItemKebabColumn = ({
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  setSelectedDocumentItem,
  selectedDocumentItem,
  index,
}) => {
  const documentItemActionsPopover = usePopover({name: 'Document Item Actions Popover'});
  const {documentItems} = draftDocumentContentJson;
  const handleDeleteItem = () => {
    documentItems.splice(index, 1);

    setDraftDocumentContentJson((prevState) => {
      prevState.documentItems = documentItems;

      // Set selected state to initial when a selected document is deleted
      // or if there are no document items left
      // or if selected is undefined
      if (
        documentItems.length === 0 ||
        _.isEmpty(selectedDocumentItem) ||
        (!_.isEmpty(selectedDocumentItem) && selectedDocumentItem.index === index)
      ) {
        setSelectedDocumentItem({});
        // If selected is below deleted in list, we move one up
      } else if (selectedDocumentItem.index > index) {
        setSelectedDocumentItem({
          documentItem: documentItems[selectedDocumentItem.index - 1],
          index: selectedDocumentItem.index - 1,
        });
        // If selected is above deleted in list, we stay the same spot
      } else {
        setSelectedDocumentItem({
          documentItem: documentItems[selectedDocumentItem.index],
          index: selectedDocumentItem.index,
        });
      }

      return {
        ...prevState,
      };
    });
  };
  const handleDuplicateItem = () => {
    // We need to deep clone the document item so that the state refreshes
    // for the input editor, this allows textInput to not read from each other
    documentItems.splice(index + 1, 0, _.cloneDeep(documentItems[index]));

    setDraftDocumentContentJson((prevState) => {
      prevState.documentItems = documentItems;

      // Set focus on duplicated item
      setSelectedDocumentItem({documentItem: documentItems[index + 1], index: index + 1});

      return {
        ...prevState,
      };
    });
  };

  return (
    <ActionMenuPopover
      popover={documentItemActionsPopover}
      placement={ActionMenuPopover.Position.RightStart}
      width={240}
      actions={[
        {
          text: 'Delete',
          onPress: handleDeleteItem,
        },
        {
          text: 'Duplicate',
          onPress: handleDuplicateItem,
        },
      ]}
    >
      <IconButton onPress={documentItemActionsPopover.handleOpen} style={{paddingHorizontal: 4}}>
        <Icon source={Icon.EllipsisV} color={colors.gray.tertiary} size={16} />
      </IconButton>
    </ActionMenuPopover>
  );
};

const getDocumentItemDescription = (documentItem) => {
  // For plain text items, returns the text
  // For rich text items, returns the first line of text without html tags
  // Header and paragraph tags are always considered a full line
  return (
    !!documentItem.text &&
    documentItem.text
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<\/?p[^>]*>/gi, '\n')
      .replace(/<\/?h[1-6][^>]*>/gi, '\n')
      .replace(/^\s*\n/gm, '')
      .replace(/<\/?[^>]+(>|$)/g, '')
      .split('\n')[0]
  );
};

const DocumentTemplateVersionEditorItem = ({
  documentItem,
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  setSelectedDocumentItem,
  selectedDocumentItem,
  organization,
  index,
}) => {
  const isSelected = selectedDocumentItem && selectedDocumentItem.index === index;
  const documentItemDescription = getDocumentItemDescription(documentItem);

  return (
    <React.Fragment>
      <Row index={index} style={{flex: 1}}>
        <ItemKindItemKebabContainer isSelected={isSelected}>
          <Button
            onPress={() => {
              // Allow Deselecting a document item
              if (!_.isEmpty(selectedDocumentItem) && selectedDocumentItem.index === index) {
                setSelectedDocumentItem({});
              } else {
                setSelectedDocumentItem({documentItem, index});
              }
            }}
          >
            <ButtonText>
              {DocumentItemKindV2.getItemDisplayName({
                itemKind: documentItem.itemKind,
                isFullyEnabledTimesheetsV2: organization.isFullyEnabledTimesheetsV2,
              })}
            </ButtonText>
            {!!documentItemDescription && (
              <React.Fragment>
                <Space height={4} />
                <ButtonDescriptionText numberOfLines={1}>
                  {documentItemDescription}
                </ButtonDescriptionText>
              </React.Fragment>
            )}
          </Button>
          <Space width={16} />
          <DocumentItemKebabColumn
            draftDocumentContentJson={draftDocumentContentJson}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
            setSelectedDocumentItem={setSelectedDocumentItem}
            selectedDocumentItem={selectedDocumentItem}
            index={index}
          />
        </ItemKindItemKebabContainer>
      </Row>
      <Space height={12} />
    </React.Fragment>
  );
};

const generateDefaultTemplateForItemKind = ({itemKind, organization}) => {
  switch (itemKind) {
    case DocumentItemKindV2.TEXT:
      return {
        itemKind,
        text: '',
      };
    case DocumentItemKindV2.SECTION_TITLE:
      return {
        itemKind,
        text: '',
      };
    case DocumentItemKindV2.TEXT_TITLE:
      return {
        itemKind,
        text: '',
      };
    case DocumentItemKindV2.TEXT_PARAGRAPH:
      return {
        itemKind,
        text: '',
      };
    case DocumentItemKindV2.INPUT_SIGNATURE:
      return {
        itemKind,
        isRequired: true,
        input: {
          value: {
            image: '',
            date: '',
          },
        },
      };
    case DocumentItemKindV2.GENERIC_SIGNATURE:
      return {
        itemKind,
        isRequired: true,
        input: {
          value: {
            name: '',
            image: '',
            date: '',
          },
        },
      };
    case DocumentItemKindV2.INPUT_RADIO_BUTTONS:
      return {
        itemKind,
        input: {
          metadata: {
            items: [
              {
                options: [],
                inputs: {},
              },
            ],
          },
          value: {
            values: [['', '', '']],
          },
        },
      };
    case DocumentItemKindV2.INPUT_TEXT_INPUTS:
      return {
        itemKind,
        input: {
          metadata: {
            fields: [
              {
                label: '',
                description: '',
                is_hidden: false,
              },
            ],
          },
          value: {
            values: [''],
          },
        },
      };
    case DocumentItemKindV2.INPUT_TEXT_AREAS:
      return {
        itemKind,
        input: {
          value: {
            values: [['', '']],
          },
        },
      };
    case DocumentItemKindV2.INPUT_GRID:
      return {
        itemKind,
        input: {
          metadata: {
            columns: [
              {
                name: '',
                is_disabled: false,
                is_hidden: false,
              },
              {
                name: '',
                is_disabled: false,
                is_hidden: false,
              },
            ],
            items: [],
          },
          value: {
            values: [['', '']],
          },
        },
      };
    case DocumentItemKindV2.INPUT_LIST:
      return {
        itemKind,
        input: {
          value: {
            values: ['', ''],
          },
        },
      };
    case DocumentItemKindV2.IMAGE:
      return {
        itemKind,
        input: {
          width: '',
          height: '',
          position: DocumentV2Image.POSITION.CENTER,
          metadata: {
            fileId: null,
            size: 0,
            width: 0,
            height: 0,
          },
        },
      };
    case DocumentItemKindV2.INVENTORY_SUMMARY:
    case DocumentItemKindV2.SURVEY_CARTONS_SUMMARY:
    case DocumentItemKindV2.SURVEY_ITEMS_SUMMARY:
    case DocumentItemKindV2.SURVEY_ROOMS_SUMMARY:
      return {
        itemKind,
        input: {
          showNotes: false,
          showWeight: true,
          showVolume: true,
        },
      };
    case DocumentItemKindV2.TIMESHEET_INFORMATION:
    case DocumentItemKindV2.INPUT_PER_MOVER_TIMESHEET:
      if (organization.isFullyEnabledTimesheetsV2) {
        return {
          itemKind,
          input: {
            showMoverNames: false,
          },
        };
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return {
        itemKind,
      };
  }
};

const DocumentItemSettingsButton = ({setDraftDocumentContentJson, setSelectedDocumentItem}) => {
  const documentItemSettingsPopover = usePopover({name: 'Document Item Settings Popover'});
  const mergeTextModal = useModal({name: 'Merge Text Modal'});
  return (
    <React.Fragment>
      <ActionMenuPopover
        popover={documentItemSettingsPopover}
        placement={ActionMenuPopover.Position.LeftStart}
        width={240}
        actions={[
          {
            text: 'Merge text blocks',
            onPress: mergeTextModal.handleOpen,
          },
        ]}
      >
        <IconButton onPress={documentItemSettingsPopover.handleOpen}>
          <Icon source={Icon.EllipsisV} color={colors.gray.tertiary} size={16} />
        </IconButton>
      </ActionMenuPopover>
      <ConfirmationModal
        title={'Merge text blocks?'}
        subtitle={'This will merge text blocks adjacent to each other.'}
        isOpen={mergeTextModal.isOpen}
        handlePressOutside={mergeTextModal.handleClose}
        handlePrimaryAction={() => {
          setSelectedDocumentItem({});
          setDraftDocumentContentJson((prevState) => {
            prevState.documentItems = DocumentItemKindV2.mergeTextItems({
              documentItems: [...prevState.documentItems],
            });
            return {
              ...prevState,
            };
          });
          mergeTextModal.handleClose();
        }}
        handleSecondaryAction={mergeTextModal.handleClose}
        primaryActionText={'Confirm'}
        secondaryActionText={'Cancel'}
      />
    </React.Fragment>
  );
};

const DocumentTemplateVersionEditorTemplateStructureEditorV1 = ({
  organization,
  setDraftDocumentContentJson,
  draftDocumentContentJson,
  setSelectedDocumentItem,
  selectedDocumentItem,
  documentTemplateCategory,
}) => {
  const {isEnabledGenericSignatureDocumentItem} = organization.features;
  const {isEquipmentAndMaterialsEnabled} = organization.settings;
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const documentItemKinds = DocumentItemKindV2.getDocumentItemKindBasedOnCategory({
    category: documentTemplateCategory,
    isEnabledGenericSignatureDocumentItem,
    isEquipmentAndMaterialsEnabled,
  });
  const saveActions = documentItemKinds.map((itemKind) => {
    return {
      text: DocumentItemKindV2.getItemDisplayName({
        itemKind,
        isFullyEnabledTimesheetsV2: organization.isFullyEnabledTimesheetsV2,
      }),
      onPress: () => {
        const documentItem = generateDefaultTemplateForItemKind({itemKind, organization});
        setDraftDocumentContentJson((prevState) => {
          prevState.documentItems = [...prevState.documentItems, documentItem];
          const documentItemsIndex = prevState.documentItems.length - 1;
          setSelectedDocumentItem({
            documentItem: prevState.documentItems[documentItemsIndex],
            index: documentItemsIndex,
          });
          return {
            ...prevState,
          };
        });
      },
    };
  });

  return (
    <TemplateStructureEditorContainer>
      <DocumentItemsContainer>
        <Row style={{alignItems: 'center'}}>
          <DocumentItemText>{'Document Items'}</DocumentItemText>
          <Space style={{flex: 1}} />
          <DocumentItemSettingsButton
            setDraftDocumentContentJson={setDraftDocumentContentJson}
            setSelectedDocumentItem={setSelectedDocumentItem}
          />
        </Row>
        <Space height={8} />
        {draftDocumentContentJson.documentItems.length === 0 && (
          <DocumentItemDescriptionText>
            {'Start adding items to build your own custom document.'}
          </DocumentItemDescriptionText>
        )}
        <Space height={8} />
        <DropdownButton
          text={'Add Item'}
          iconLeft={Icon.Plus}
          iconSize={10}
          iconColor={colors.blue.interactive}
          style={{width: '100%', zIndex: 99999}}
          actions={saveActions}
          maxDropdownHeight={300}
          ButtonComponent={TertiaryButton}
          isDense
          isSearchable
        />
        <Space height={12} />
        <DragAndDropList
          isReordering={isReordering}
          spaceBetweenItems={12}
          onReorder={({fromIndex, toIndex}) => {
            handleReorderStart();
            swapDocumentItems(
              draftDocumentContentJson.documentItems,
              setDraftDocumentContentJson,
              setSelectedDocumentItem,
              fromIndex,
              toIndex,
            );
            handleReorderEnd();
          }}
          droppableStyle={{paddingBottom: 120}}
        >
          {draftDocumentContentJson.documentItems.map((documentItem, index) => {
            return (
              <DocumentTemplateVersionEditorItem
                key={index}
                index={index}
                documentItem={documentItem}
                draftDocumentContentJson={draftDocumentContentJson}
                setDraftDocumentContentJson={setDraftDocumentContentJson}
                setSelectedDocumentItem={setSelectedDocumentItem}
                selectedDocumentItem={selectedDocumentItem}
                organization={organization}
              />
            );
          })}
        </DragAndDropList>
      </DocumentItemsContainer>
    </TemplateStructureEditorContainer>
  );
};

DocumentTemplateVersionEditorTemplateStructureEditorV1.fragment = gql`
  fragment DocumentTemplateVersionEditorTemplateStructureEditorV1 on Organization {
    id
    slug
    isFullyEnabledTimesheetsV2
    features {
      isEnabledGenericSignatureDocumentItem: isEnabled(feature: "GENERIC_SIGNATURE_DOCUMENT_ITEM")
    }
    settings {
      id
      isEquipmentAndMaterialsEnabled
    }
  }
`;

export default DocumentTemplateVersionEditorTemplateStructureEditorV1;
