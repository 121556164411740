// Libraries

// Supermove
import {uuid} from '@supermove/utils';

export interface ToggleTaskPropertyActionFormType {
  workflowRunStepUuid: string;
  taskIdReferencePath: string | null;
}

const _new = (): ToggleTaskPropertyActionFormType => ({
  workflowRunStepUuid: uuid(),
  taskIdReferencePath: null,
});

const edit = (toggleTaskPropertyActionForm: any): ToggleTaskPropertyActionFormType => ({
  workflowRunStepUuid: toggleTaskPropertyActionForm.workflowRunStepUuid,
  taskIdReferencePath: toggleTaskPropertyActionForm.taskIdReferencePath,
});

const toForm = (
  toggleTaskPropertyActionForm: ToggleTaskPropertyActionFormType,
): ToggleTaskPropertyActionFormType => ({
  workflowRunStepUuid: toggleTaskPropertyActionForm.workflowRunStepUuid,
  taskIdReferencePath: toggleTaskPropertyActionForm.taskIdReferencePath,
});

const toMutation = (
  toggleTaskPropertyActionForm: ToggleTaskPropertyActionFormType,
): ToggleTaskPropertyActionFormType => ({
  workflowRunStepUuid: toggleTaskPropertyActionForm.workflowRunStepUuid,
  taskIdReferencePath: toggleTaskPropertyActionForm.taskIdReferencePath,
});

const ToggleTaskPropertyActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ToggleTaskPropertyActionForm;
