const ACTIVE = 'ACTIVE';
const INACTIVE = 'INACTIVE';
const PENDING = 'PENDING';

const UserStatus = {
  ACTIVE,
  INACTIVE,
  PENDING,
};

export default UserStatus;
