// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {generated, gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM, useToast} from '@supermove/hooks';
import {existenceFilter} from '@supermove/utils';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DashboardForm, {
  DashboardFormEditFragment,
} from '@shared/modules/Reports/forms/DashboardForm';
import useUpdateDashboardMutation from '@shared/modules/Reports/hooks/useUpdateDashboardMutation';
import GlobalDashboardsLibraryPageDashboardFields, {
  GlobalDashboardsLibraryPageDashboardFieldsFragment,
} from 'modules/Report/Library/components/GlobalDashboardsLibraryPageDashboardFields';

const GlobalDashboardsLibraryPageUpdateDashboardDrawerContent = ({
  isOpen,
  isLoading,
  dashboard,
  dashboardCategories,
  dashboardTags,
  handleClose,
  refetch,
}: {
  isOpen: boolean;
  isLoading: boolean;
  dashboard: generated.DashboardFormEditFragment;
  dashboardCategories: NonNullable<
    generated.GlobalDashboardsLibraryPageDashboardFieldsFragment['dashboardCategories']
  >;
  dashboardTags: NonNullable<
    generated.GlobalDashboardsLibraryPageDashboardFieldsFragment['dashboardTags']
  >;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const {navigator} = useNavigationDOM();

  const createDashboardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard created!',
  });
  const dashboardForm = DashboardForm.edit(dashboard);
  const {form, submitting, handleSubmit} = useUpdateDashboardMutation({
    dashboardForm,
    onSuccess: ({dashboard}) => {
      createDashboardSuccessToast.handleToast({
        message: `'${dashboard.name}' created!`,
      });
      handleClose();
      navigator.replace(`/reports/${dashboard.uuid}/view`);
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      shouldCloseOnClickOutside={!form.dirty}
      headerText={'Edit Custom Report'}
      primaryActionText={'Save'}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      isDisabled={isLoading}
    >
      <Loading loading={isLoading}>
        {() => (
          <GlobalDashboardsLibraryPageDashboardFields
            form={form}
            dashboardCategories={dashboardCategories}
            dashboardTags={dashboardTags}
          />
        )}
      </Loading>
    </DrawerWithAction>
  );
};

const GlobalDashboardsLibraryPageUpdateDashboardDrawer = ({
  isOpen,
  dashboardUuid,
  handleClose,
  refetch,
}: {
  isOpen: boolean;
  dashboardUuid: string;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const {loading, data} = useQuery<generated.GlobalDashboardsLibraryPageUpdateDashboardDrawerQuery>(
    GlobalDashboardsLibraryPageUpdateDashboardDrawer.query,
    {
      variables: {
        uuid: dashboardUuid,
      },
      fetchPolicy: 'cache-and-network',
      skip: !isOpen,
    },
  );

  if (!data || !data.dashboard) {
    return null;
  }

  return (
    <GlobalDashboardsLibraryPageUpdateDashboardDrawerContent
      isOpen={isOpen}
      isLoading={loading}
      dashboard={data.dashboard}
      dashboardCategories={data.dashboardCategories?.filter(existenceFilter) || []}
      dashboardTags={data.dashboardTags?.filter(existenceFilter) || []}
      handleClose={handleClose}
      refetch={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalDashboardsLibraryPageUpdateDashboardDrawer.query = gql`
  ${GlobalDashboardsLibraryPageDashboardFieldsFragment}
  ${DashboardFormEditFragment}
  query GlobalDashboardsLibraryPageUpdateDashboardDrawer($uuid: String!) {
    ${gql.query}
    dashboard(dashboardUuid: $uuid) { 
      id
      ...DashboardFormEdit
    }
    ...GlobalDashboardsLibraryPageDashboardFields
  }
`;

export default GlobalDashboardsLibraryPageUpdateDashboardDrawer;
