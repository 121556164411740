// Libraries
import {gql} from '@supermove/graphql';

// App
import JobUserTimesheetForm from './JobUserTimesheetForm';

const edit = (job: any) => ({
  jobId: job.id,

  timeRanges: job.timeRanges.map((timeRange: any) => ({
    kind: timeRange.kind,
    startName: timeRange.startName,
    endName: timeRange.endName,
    startIsRequired: timeRange.startIsRequired,
    endIsRequired: timeRange.endIsRequired,
  })),

  jobUserTimesheetForms: JobUserTimesheetForm.sortBy({
    timesheetJobUserPositionOrder: job.organization.timesheetJobUserPositionOrder,
    jobUsers: job.confirmedJobUsers,
    jobUserTimesheetForms: job.confirmedJobUsers.map((jobUser: any) =>
      JobUserTimesheetForm.edit(jobUser),
    ),
  }),
});

edit.fragment = gql`
  ${JobUserTimesheetForm.edit.fragment}
  ${JobUserTimesheetForm.sortBy.fragment}

  fragment CommercialJobTimesheetForm_edit on Job {
    id
    organization {
      id
      timesheetJobUserPositionOrder
    }
    timeRanges {
      kind
      startName
      endName
      startIsRequired
      endIsRequired
    }
    confirmedJobUsers {
      id
      ...JobUserTimesheetForm_edit
      ...JobUserTimesheetForm_sortBy
    }
  }
`;

const CommercialJobTimesheetForm = {
  toForm: ({jobId, timeRanges, jobUserTimesheetForms}: any) => ({
    jobId,
    timeRanges,
    jobUserTimesheetForms: jobUserTimesheetForms.map((jobUserTimesheetForm: any) => {
      return JobUserTimesheetForm.toForm(jobUserTimesheetForm);
    }),
  }),

  // NOTE(mark): TimeRanges are only used for rendering right now. We do not send
  // back in the mutation at the moment.
  toMutation: ({jobId, timeRanges, jobUserTimesheetForms}: any) => ({
    jobId,
    jobUserTimesheetForms: jobUserTimesheetForms.map((jobUserTimesheetForm: any) => {
      return JobUserTimesheetForm.toMutation(jobUserTimesheetForm);
    }),
  }),

  edit,
};

export default CommercialJobTimesheetForm;
