// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

const Container = Styled.View`
  width: 100%;
`;

const AnchorText = Styled.Text`
  ${Typography.Link2};
  color: ${colors.blue.interactive};
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const AnchorWrapper = Styled.ButtonV2`
`;

const PhoneText = Styled.Text`
  ${Typography.Body3}
`;

const PhoneNumber = ({isClickable, phoneNumber}: any) => {
  return (
    <Container>
      {isClickable ? (
        <AnchorWrapper>
          <a href={Phone.createUrl(phoneNumber)}>
            <AnchorText numberOfLines={1}>{Phone.display(phoneNumber)}</AnchorText>
          </a>
        </AnchorWrapper>
      ) : (
        <PhoneText numberOfLines={1}>{Phone.display(phoneNumber)}</PhoneText>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
PhoneNumber.propTypes = {
  isClickable: PropTypes.bool,
  phoneNumber: PropTypes.string.isRequired,
};

PhoneNumber.defaultProps = {
  isClickable: false,
};

export default PhoneNumber;
