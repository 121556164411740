// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {ProjectTypePaymentMethodModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export interface ProjectTypePaymentMethodFormType {
  projectTypePaymentMethodId: string | undefined;
  paymentMethodId: string | undefined;
  projectTypeId: string | undefined;
  isEnabled: boolean;

  // Private
  sectionLabel?: string;
  paymentMethodName?: string;
}

const _new = ({
  paymentMethodId = undefined,
  projectTypeId = undefined,
  isEnabled = false,
}: {
  paymentMethodId?: string;
  projectTypeId?: string;
  isEnabled?: boolean;
}): ProjectTypePaymentMethodFormType => ({
  projectTypePaymentMethodId: undefined,
  paymentMethodId,
  projectTypeId,
  isEnabled,

  // Private
  sectionLabel: undefined,
  paymentMethodName: undefined,
});

const edit = withFragment(
  (
    projectTypePaymentMethod: ProjectTypePaymentMethodModel,
    {sectionLabel, paymentMethodName}: {sectionLabel?: string; paymentMethodName?: string},
  ): ProjectTypePaymentMethodFormType => ({
    projectTypePaymentMethodId: projectTypePaymentMethod.id,
    paymentMethodId: projectTypePaymentMethod.paymentMethodId,
    projectTypeId: projectTypePaymentMethod.projectTypeId,
    isEnabled: projectTypePaymentMethod.isEnabled,

    // Private
    sectionLabel,
    paymentMethodName,
  }),
  gql`
    fragment ProjectTypePaymentMethodForm_edit on ProjectTypePaymentMethod {
      id
      paymentMethodId
      projectTypeId
      isEnabled
    }
  `,
);

const toForm = ({
  projectTypePaymentMethodId,
  paymentMethodId,
  projectTypeId,
  isEnabled,

  // Private
  sectionLabel,
  paymentMethodName,
}: ProjectTypePaymentMethodFormType) => {
  return {
    projectTypePaymentMethodId: _.toString(projectTypePaymentMethodId),
    paymentMethodId: _.toString(paymentMethodId),
    projectTypeId: _.toString(projectTypeId),
    isEnabled,

    // Private
    sectionLabel,
    paymentMethodName,
  };
};

const toMutation = ({
  projectTypePaymentMethodId,
  paymentMethodId,
  projectTypeId,
  isEnabled,
}: ProjectTypePaymentMethodFormType) => {
  return {
    projectTypePaymentMethodId: projectTypePaymentMethodId
      ? _.toNumber(projectTypePaymentMethodId)
      : undefined,
    paymentMethodId: paymentMethodId ? _.toNumber(paymentMethodId) : undefined,
    projectTypeId: projectTypeId ? _.toNumber(projectTypeId) : undefined,
    isEnabled,
  };
};

const ProjectTypePaymentMethodForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ProjectTypePaymentMethodForm;
