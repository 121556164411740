const WAREHOUSE = 'WAREHOUSE';
const CUSTOM = 'CUSTOM';

const WAREHOUSE_COORDINATE_X = 'WAREHOUSE_COORDINATE_X';
const WAREHOUSE_COORDINATE_Y = 'WAREHOUSE_COORDINATE_Y';
const WAREHOUSE_COORDINATE_Z = 'WAREHOUSE_COORDINATE_Z';

export default {
  WAREHOUSE,
  CUSTOM,

  WAREHOUSE_COORDINATE_X,
  WAREHOUSE_COORDINATE_Y,
  WAREHOUSE_COORDINATE_Z,
};
