// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import PaymentMethodForm, {
  PaymentMethodFormType,
} from '@shared/modules/PaymentMethod/forms/PaymentMethodForm';

const useUpdatePaymentMethodMutation = ({
  paymentMethodForm,
  onSuccess,
  onError,
}: {
  paymentMethodForm: PaymentMethodFormType;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      paymentMethodForm: PaymentMethodForm.toForm(paymentMethodForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdatePaymentMethodMutation.mutation,
    variables: {
      paymentMethodForm: PaymentMethodForm.toMutation(form.values.paymentMethodForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdatePaymentMethodMutation.mutation = gql`
  mutation useUpdatePaymentMethodMutation($paymentMethodForm: PaymentMethodForm!) {
    response: updatePaymentMethod(paymentMethodForm: $paymentMethodForm) {
      ${gql.errors}
      paymentMethod {
        id
      }
    }
  }
`;

export default useUpdatePaymentMethodMutation;
