// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import UserNotificationsSettingsContent from 'modules/User/Settings/Profile/Notifications/components/UserNotificationsSettingsContent';
import UserSettingsPageWrapper from 'modules/User/Settings/components/UserSettingsPageWrapper';

const Content = Styled.View`
`;

const NotificationsContent = ({viewer, refetch}: {viewer: any; refetch: () => void}) => {
  return (
    <Content>
      <UserNotificationsSettingsContent viewer={viewer} refetch={refetch} />
    </Content>
  );
};

const UserSettingsProfileNotificationsPage = () => {
  const {loading, data, refetch} = useQuery(UserSettingsProfileNotificationsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {},
  });

  return (
    <UserSettingsPageWrapper selectedLabel={'Profile/Notifications'} pageTitle={'Notifications'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => <NotificationsContent viewer={data.viewer} refetch={refetch} />}
      </Loading>
    </UserSettingsPageWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserSettingsProfileNotificationsPage.query = gql`
  ${UserNotificationsSettingsContent.fragment}

  query UserSettingsProfileNotificationsPage {
    ${gql.query}
    viewer {
      id
      ...UserNotificationsSettingsContent
    }
  }
`;

export default UserSettingsProfileNotificationsPage;
