/* eslint-disable no-unused-vars */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, Form, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ProjectDatesForm from '@shared/modules/Project/forms/ProjectDatesForm';
import useUpdateProjectDatesMutation from '@shared/modules/Project/hooks/useUpdateProjectDatesMutation';

const DateRangePickerContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  padding: 12px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  z-index: ${({index = 0}) => 100 - index};
`;

const InputContainer = Styled.View`
  flex: 1;
`;

const SingleDateInput = ({form, excludeDates, isScheduledDateInsideDateRange}) => {
  return (
    <InputContainer>
      <FieldInput
        {...form}
        index={0}
        component={DateInput}
        name={'projectDatesForm.scheduledDate'}
        label={'Scheduled date'}
        input={{
          placeholder: 'Scheduled date',
          setFieldValue: form.setFieldValue,
          excludeDates,
        }}
        hasWarning={!isScheduledDateInsideDateRange}
        warningMessage={'Scheduled date is not within range'}
      />
    </InputContainer>
  );
};

const DateRangeInputs = ({form, label, excludeDates}) => {
  return (
    <React.Fragment>
      <InputContainer>
        <FieldInput
          {...form}
          index={0}
          component={DateInput}
          name={'projectDatesForm.startDate'}
          label={'Start Date'}
          input={{
            placeholder: 'Start date',
            setFieldValue: form.setFieldValue,
            excludeDates,
          }}
        />
      </InputContainer>
      <Space width={8} />
      <InputContainer>
        <FieldInput
          {...form}
          index={1}
          component={DateInput}
          name={'projectDatesForm.endDate'}
          label={'End Date'}
          input={{
            placeholder: 'End date',
            setFieldValue: form.setFieldValue,
            excludeDates,
          }}
        />
      </InputContainer>
    </React.Fragment>
  );
};

const ShipmentsTableDatePickerPopover = ({popover, project, projectDateKind, excludeDates}) => {
  const projectDatesForm = ProjectDatesForm.edit(project, {projectDateKind});

  const {form, handleSubmit} = useUpdateProjectDatesMutation({
    projectDatesForm,
    onSuccess: () => {
      popover.handleClose();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  const isScheduledDateInsideDateRange = Datetime.getIsDateInsideOfDateRange({
    date: form.values.projectDatesForm.scheduledDate,
    startDate: form.values.projectDatesForm.startDate,
    endDate: form.values.projectDatesForm.endDate,
  });

  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      key={popover.isOpen}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={() => {
        popover.handleClose();
        form.handleReset();
      }}
      reference={popover.ref}
      offset={[0, 4]}
      isClosableOutside
    >
      <DateRangePickerContainer>
        <Row index={0}>
          <SingleDateInput
            form={form}
            excludeDates={excludeDates}
            isScheduledDateInsideDateRange={isScheduledDateInsideDateRange}
          />
        </Row>
        <Space height={8} />
        <Row index={1}>
          <DateRangeInputs form={form} label={'Date'} index={1} excludeDates={excludeDates} />
        </Row>
        <Space height={8} />
        <Row index={2} style={{justifyContent: 'flex-end'}}>
          <QuaternaryButton style={{width: 80}} text={'Cancel'} onPress={popover.handleClose} />
          <Space width={8} />
          <Button style={{width: 80}} text={'Save'} onPress={handleSubmit} />
        </Row>
      </DateRangePickerContainer>
    </Popover>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShipmentsTableDatePickerPopover.fragment = gql`
  ${ProjectDatesForm.edit.fragment}
  fragment ShipmentsTableDatePickerPopover on Project {
    id
    ...ProjectDatesForm_edit
  }
`;

export default ShipmentsTableDatePickerPopover;
