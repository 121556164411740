// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Icon, Link, Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

// Components

const Wrapper = Styled.View`
  flex-direction: row;
  width: 100%;
`;

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Content = Styled.View`
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-vertical: 10px;
  background-color: ${(props) => (props.isSelected ? colors.blue.interactive : 'none')};
`;

const Name = Styled.H7`
  margin-top: 5px;
  color: ${colors.white};
  line-height: 20px;
  text-transform: uppercase;
`;

const Notification = Styled.View`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
`;

const NotificationText = Styled.H7`
  width: 30px;
  padding-vertical: 5px;
  margin-right: 10px;
  background-color: ${colors.red.warning};
  border-radius: 3px;
  text-align: center;
  ${fontWeight(700)}
  color: ${colors.white};
`;

/**
 * Component for mobile sidebar items.
 */
const SidebarItemV2 = ({isSelected, count, name, source, to, responsive}) => (
  <Link to={to}>
    <Wrapper>
      <Container {...responsive}>
        <Content isSelected={isSelected}>
          <Icon source={source} color={colors.white} size={20} style={{height: 20, width: 20}} />
          <Name>{name}</Name>
        </Content>
        {count > 0 && (
          <Notification>
            <NotificationText>{count}</NotificationText>
          </Notification>
        )}
      </Container>
    </Wrapper>
  </Link>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
SidebarItemV2.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  responsive: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
};

SidebarItemV2.defaultProps = {};

export default SidebarItemV2;
