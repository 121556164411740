// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

const CheckButton = Styled.ButtonV2`
`;

const CheckContainer = Styled.View`
  height: ${({desktop}) => (desktop ? '22' : '24')}px;
  width: ${({desktop}) => (desktop ? '28' : '32')}px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-width: 2px;
  border-color: ${({isCompleted}) => (isCompleted ? colors.green.status : colors.gray.border)}
  background-color: ${({isCompleted}) => (isCompleted ? colors.green.status : colors.white)}
`;

const TaskIsCompletedCheckbox = ({task, onPress, isDisabled, style}) => {
  const responsive = useResponsive();
  return (
    <CheckButton key={task.isCompleted} disabled={isDisabled} onPress={onPress} style={style}>
      <CheckContainer isCompleted={task.isCompleted} {...responsive}>
        {task.isCompleted && <Icon source={Icon.Check} color={colors.white} size={11} />}
      </CheckContainer>
    </CheckButton>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
TaskIsCompletedCheckbox.propTypes = {
  task: PropTypes.object.isRequired,
  onPress: PropTypes.func,
  isDisabled: PropTypes.bool,
};

TaskIsCompletedCheckbox.defaultProps = {
  onPress: null,
  isDisabled: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TaskIsCompletedCheckbox.fragment = gql`
  fragment TaskIsCompletedCheckbox on Task {
    id
    isCompleted
  }
`;

export default TaskIsCompletedCheckbox;
