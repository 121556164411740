// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ReferralSourceForm from '@shared/modules/Company/forms/ReferralSourceForm';
import useUpdateOrganizationReferralSourcesMutation from '@shared/modules/Company/hooks/useUpdateOrganizationReferralSourcesMutation';

const EditReferralTypeModal = ({
  editReferralTypeModal,
  refetch,
  referral,
  referralSources,
  organizationId,
}) => {
  const referralSourceForms = referralSources.map((referralSource) =>
    ReferralSourceForm.edit(referralSource),
  );

  const {form, submitting, handleSubmit} = useUpdateOrganizationReferralSourcesMutation({
    organizationReferralSourcesForm: {
      organizationId,
      referralSourceForms,
    },
    onSuccess: () => {
      refetch();
      editReferralTypeModal.handleClose();
    },
    onError: (error) => console.log(error),
  });

  const getReferralSourceIndex = _.findIndex(referralSources, (referralSource) => {
    return referralSource.name === referral.name;
  });
  const field = `organizationReferralSourcesForm.referralSourceForms.${getReferralSourceIndex}`;

  return (
    <SmallModal isOpen={editReferralTypeModal.isOpen}>
      <SmallModal.BoldText>Edit Referral Source</SmallModal.BoldText>
      <Space height={16} />
      <SmallModal.Text>{`This will appear in the 'How did you hear about us?' dropdown when creating a new project.`}</SmallModal.Text>
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.name`}
        label={'Name'}
        value={_.get(form.values, `${field}.name`)}
        input={{
          autoFocus: true,
          placeholder: 'Enter referral source name',
        }}
      />
      <Space height={24} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={submitting} onPress={editReferralTypeModal.handleClose}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          isSubmitting={submitting}
          color={colors.blue.interactive}
          onPress={handleSubmit}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditReferralTypeModal.fragment = gql`
  fragment EditReferralTypeModal on Organization {
    id
    settings {
      id
      referralSources {
        name
      }
    }
  }
`;

export default EditReferralTypeModal;
