const VALUE = {
  INVOICE: 'INVOICE',
  JOB: 'JOB',
} as const;

export type DocumentTemplateCategoryType = (typeof VALUE)[keyof typeof VALUE];

const INVOICE_OPTION = {
  value: VALUE.INVOICE,
  label: 'Invoice',
};
const JOB_OPTION = {
  value: VALUE.JOB,
  label: 'Job',
};

const DocumentTemplateCategory = {
  ...VALUE,

  DOCUMENT_TEMPLATE_CATEGORY_OPTIONS: [JOB_OPTION, INVOICE_OPTION],
};

export default DocumentTemplateCategory;
