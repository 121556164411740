import Expression, {
  GroupingExpression,
  IdentifierExpression,
  NumberLiteralExpression,
  SymbolExpression,
  CallExpression,
  StringLiteralExpression,
  OperatorExpression,
} from '@shared/ast/Expression';
import {Visitor} from '@shared/ast/Visitor';

type OperatorMap = {[operatorKind: string]: boolean};

export default class FormulaPrettyPrinter extends Visitor {
  visitGrouping = (groupingExpression: GroupingExpression) => {
    return this.parenthesize(groupingExpression.expression);
  };

  visitIdentifier = (identifierExpression: IdentifierExpression) => {
    return identifierExpression.value;
  };

  visitNumberLiteral = (numberLiteralExpression: NumberLiteralExpression) => {
    return String(numberLiteralExpression.value);
  };

  visitStringLiteral = (stringLiteralExpression: StringLiteralExpression) => {
    return `"${stringLiteralExpression.value}"`;
  };

  visitSymbol = (symbolExpression: SymbolExpression) => {
    if (symbolExpression.value === null) {
      return 'TBD';
    }
    return symbolExpression.value;
  };

  visitCall = (callExpression: CallExpression) => {
    let callString = `${callExpression.name}(`;
    const resolvedExpressionString = callExpression.args
      .map((expression) => {
        return expression && expression.accept(this);
      })
      .join(', ');
    callString += resolvedExpressionString;
    callString += ')';
    return callString;
  };

  visitPrefixOperator = (operatorExpression: OperatorExpression): string => {
    const operatorsWithoutSpaces: OperatorMap = {
      Bang: true,
      Dash: true,
    };
    const maybeSpace = operatorsWithoutSpaces[operatorExpression.operator.kind] ? '' : ' ';
    return `${operatorExpression.operator.value}${maybeSpace}${operatorExpression.args[0].accept(
      this,
    )}`;
  };

  visitInfixOperator = (operatorExpression: OperatorExpression): string => {
    return `${operatorExpression.args[0].accept(this)} ${
      operatorExpression.operator.value
    } ${operatorExpression.args[1].accept(this)}`;
  };

  visitOperator = (operatorExpression: OperatorExpression) => {
    const prefixOperators: OperatorMap = {
      Bang: true,
      Not: true,
    };
    const infixOperators: OperatorMap = {
      BangEqual: true,
      DoubleEqual: true,
      GreaterThan: true,
      GreaterEqual: true,
      LessThan: true,
      LessEqual: true,
      Or: true,
      And: true,
      Plus: true,
      Slash: true,
      Asterisk: true,
    };
    const operatorKind = operatorExpression.operator.kind;
    const argumentList = operatorExpression.args;
    // Dash is prefix if there is only one argument
    if (operatorKind === 'Dash') {
      return argumentList.length === 1
        ? this.visitPrefixOperator(operatorExpression)
        : this.visitInfixOperator(operatorExpression);
    }
    if (prefixOperators[operatorKind]) {
      return this.visitPrefixOperator(operatorExpression);
    }
    if (infixOperators[operatorKind]) {
      return this.visitInfixOperator(operatorExpression);
    }
  };

  visitExpression = (expression: Expression) => {
    return '';
  };

  print = (expression: Expression) => {
    return expression.accept(this);
  };

  parenthesize = (...expressions: Expression[]) => {
    let parenthesizedString = '(';
    const resolvedExpressionString = expressions
      .map((expression) => {
        return expression.accept(this);
      })
      .join(' ');
    parenthesizedString += resolvedExpressionString;
    parenthesizedString += ')';
    return parenthesizedString;
  };
}
