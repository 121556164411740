/**
 * This is a modal but is set up directory-wise so that it can be our first modal
 * that acts as a page (with a unique navigation url).
 */

// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {DateInput, Emoji, Loading, Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// App
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';
import useCopyProjectMutation from '@shared/modules/Project/hooks/useCopyProjectMutation';
import Field from 'modules/App/components/Field';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Inputs = Styled.View`
  margin-vertical: 10px;
  z-index: 100;
`;

const RowSpace = Styled.View`
  margin-top: 10px;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.LoadingButton`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

const JobActionCopyModalContent = ({job, handleClose}) => {
  const {navigator} = useNavigationDOM();
  const {form, submitting, handleSubmit} = useCopyProjectMutation({
    projectForm: ProjectForm.copyFromJob(job),
    onSuccess: ({project}) => {
      const uuid = _.get(project, 'jobs.0.uuid');
      navigator.replace(`/jobs/${uuid}`);
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <Wrapper width={350}>
      <Circle>
        <Emoji component={EmojiText} name={'arrow_forward'} />
      </Circle>
      <Content>
        <Title>{`Copy Job`}</Title>
        <Subtitle>
          {`Select the date that you would like to copy ${Job.getFullName(job)} to.`}
        </Subtitle>
        <Inputs>
          {job.organization.features.isEnabledProjectIdentifierField && (
            <React.Fragment>
              <Field
                {...form}
                name={'projectForm.identifier'}
                label={'Project Identifier'}
                input={{
                  placeholder: 'Enter project identifier',
                }}
                style={{
                  width: '100%',
                }}
              />
              <RowSpace />
            </React.Fragment>
          )}
          {job.organization.features.isEnabledJobIdentifierField && (
            <React.Fragment>
              <Field
                {...form}
                name={'projectForm.jobForms.0.identifier'}
                label={'Job Identifier'}
                input={{
                  placeholder: 'Enter job identifier',
                }}
                style={{
                  width: '100%',
                }}
              />
              <RowSpace />
            </React.Fragment>
          )}
          <Field
            {...form}
            index={1}
            component={DateInput}
            name={'projectForm.jobForms.0.date'}
            label={'Date'}
            input={{
              placeholder: 'Select date',
              setFieldValue: form.setFieldValue,
              style: {
                width: '100%',
              },
            }}
            style={{
              width: '100%',
            }}
          />
        </Inputs>
        <Actions>
          <Button color={colors.gray.secondary} onPress={handleClose}>
            <Text>Cancel</Text>
          </Button>
          <ButtonSpace />
          <Button loading={submitting} color={colors.blue.interactive} onPress={handleSubmit}>
            <Text>Confirm</Text>
          </Button>
        </Actions>
      </Content>
    </Wrapper>
  );
};
const JobActionCopyModal = ({jobUuid, onClose, trigger}) => {
  const {isOpen, handleOpen, handleClose} = useModal({onClose});
  const {loading, data} = useQuery(JobActionCopyModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobUuid,
    },
    skip: !isOpen,
  });

  return (
    <React.Fragment>
      {trigger({handleOpen})}
      <Modal.Content isOpen={isOpen} onClose={handleClose}>
        <Container pointerEvents={'box-none'}>
          <Loading loading={loading || !data} as={PageLoadingIndicator}>
            {() => <JobActionCopyModalContent job={data.job} handleClose={handleClose} />}
          </Loading>
        </Container>
      </Modal.Content>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobActionCopyModal.propTypes = {
  jobUuid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
};

JobActionCopyModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobActionCopyModal.query = gql`
  ${Job.getFullName.fragment}
  ${ProjectForm.copyFromJob.fragment}
  query JobActionCopyModal($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      uuid
      organization {
        id
        features {
          isEnabledJobIdentifierField: isEnabled(feature: "JOB_IDENTIFIER_FIELD")
          isEnabledProjectIdentifierField: isEnabled(feature: "PROJECT_IDENTIFIER_FIELD")
        }
      }
      ...Job_getFullName
      ...ProjectForm_copyFromJob
    }
  }
`;

export default JobActionCopyModal;
