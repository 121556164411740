// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SimpleHeader from 'modules/App/components/SimpleHeader';
import SendEmailForJobContent from 'modules/Job/Email/components/SendEmailForJobContent';

const SendEmailForJobPage = () => {
  const {params, navigator} = useNavigationDOM();
  const {loading, data} = useQuery(SendEmailForJobPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobUuid: params.jobUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => (
        <React.Fragment>
          <SimpleHeader title={`Send Email`} showLine showBackButton numberOfLines={1} />
          <SendEmailForJobContent
            job={data.job}
            viewerId={data.viewer.id}
            handleClose={() => navigator.goBack()}
          />
        </React.Fragment>
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendEmailForJobPage.query = gql`
  ${SendEmailForJobContent.fragment}

  query SendEmailForJobPage(
    $jobUuid: String!,
  ) {
    ${gql.query}
    viewer {
      id
    }
    job(uuid: $jobUuid) {
      id
      ...SendEmailForJobContent
    }
  }
`;

export default SendEmailForJobPage;
