// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import JobTypeForm from '@shared/modules/Job/forms/JobTypeForm';
import useDeleteJobTypeMutation from '@shared/modules/Job/hooks/useDeleteJobTypeMutation';

const RemoveJobTypeModal = ({jobType, removeJobTypeModal, refetch}) => {
  const jobTypeForm = JobTypeForm.edit(jobType);
  const {handleSubmit} = useDeleteJobTypeMutation({
    jobTypeForm,
    onSuccess: () => {
      removeJobTypeModal.handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DeleteModal
      handleDelete={handleSubmit}
      handleClose={removeJobTypeModal.handleClose}
      isOpen={removeJobTypeModal.isOpen}
      title={`Remove ${jobType.name}?`}
      subtitle={'Removing will not impact any existing work connected to this job type.'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RemoveJobTypeModal.fragment = gql`
  ${JobTypeForm.edit.fragment}

  fragment RemoveJobTypeModal on JobType {
    id
    name
    ...JobTypeForm_edit
  }
`;
export default RemoveJobTypeModal;
