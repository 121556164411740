// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ToggleWorkflowIsActiveForm from '@shared/modules/Workflow/ToggleWorkflowIsActiveForm';

const useToggleWorkflowIsActiveMutation = ({
  toggleWorkflowIsActiveForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      toggleWorkflowIsActiveForm: ToggleWorkflowIsActiveForm.toForm(toggleWorkflowIsActiveForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useToggleWorkflowIsActiveMutation.mutation,
    variables: {
      toggleWorkflowIsActiveForm: ToggleWorkflowIsActiveForm.toMutation(
        form.values.toggleWorkflowIsActiveForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useToggleWorkflowIsActiveMutation.mutation = gql`
  mutation ToggleWorkflowIsActiveMutation($toggleWorkflowIsActiveForm: ToggleWorkflowIsActiveForm!) {
    response: toggleWorkflowIsActive(toggleWorkflowIsActiveForm: $toggleWorkflowIsActiveForm) {
      ${gql.errors}
      workflow {
        id
        isActive
      }
    }
  }
`;

export default useToggleWorkflowIsActiveMutation;
