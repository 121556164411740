// Supermove
import {gql} from '@supermove/graphql';
import {Float, Datetime, withFragment} from '@supermove/utils';

const _new = (projectId: any) => ({
  collectionId: null,
  projectId,
  containerId: null,
  shipmentId: null,
  name: '',
  notes: '',
  startDate: Datetime.now,
  endDate: '',
  estimatedWeight: '',
  assignedToType: null,
});

const edit = withFragment(
  (collection) => ({
    collectionId: (collection as any).id,
    projectId: (collection as any).projectId,
    containerId: (collection as any).activeCollectionVersion.containerId,
    // NOTE(cooper): Currently we only allow assigning to containers when updating collections, not shipments
    shipmentId: null,
    name: (collection as any).name,
    notes: (collection as any).notes || '',
    startDate: (collection as any).startDate,
    endDate: (collection as any).endDate,
    estimatedWeight: (collection as any).activeCollectionVersion.estimatedWeight,
    assignedToType: (collection as any).activeCollectionVersion.assignedToType,
  }),
  gql`
    fragment CollectionFormV2_edit on Collection {
      id
      name
      notes
      startDate
      endDate
      projectId
      activeCollectionVersion {
        id
        estimatedWeight
        containerId
        shipmentId
        assignedToType
      }
    }
  `,
);

const toForm = ({
  collectionId,
  projectId,
  containerId,
  shipmentId,
  name,
  notes,
  startDate,
  endDate,
  estimatedWeight,
  assignedToType,
}: any) => ({
  collectionId,
  projectId,
  containerId,
  shipmentId,
  name,
  notes,
  startDate: Datetime.toFormDate(startDate),
  endDate: Datetime.toFormDate(endDate),
  estimatedWeight,
  assignedToType,
});

const toMutation = ({
  collectionId,
  projectId,
  containerId,
  shipmentId,
  name,
  notes,
  startDate,
  endDate,
  estimatedWeight,
  assignedToType,
}: any) => {
  return {
    collectionId,
    projectId,
    containerId,
    shipmentId,
    name,
    notes,
    startDate: startDate ? Datetime.toMutationDate(startDate) : null,
    endDate: endDate ? Datetime.toMutationDate(endDate) : null,
    estimatedWeight: Float.toFloat(estimatedWeight),
    assignedToType,
  };
};

const CollectionFormV2 = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default CollectionFormV2;
