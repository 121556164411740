// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const Container = Styled.View`
  flex-direction: row;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Touchable = Styled.Touchable`
  flex: 1;
  padding: 12px;
`;

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  align-items: ${(props) => (props.mobile ? 'flex-start' : 'center')};
  justify-content: ${(props) => (props.mobile ? 'flex-start' : 'space-between')};
`;

const Info = Styled.View`
  ${(props) => (props.mobile ? 'align-self: stretch;' : '')}
`;

const Name = Styled.H5`
  ${fontWeight(500)}
  color: ${({vars}) => (vars.isCancelled ? colors.gray.tertiary : colors.gray.primary)};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const StartTime = Styled.H7`
  ${fontWeight(500)}
  color: ${({vars}) => (vars.isCancelled ? colors.gray.tertiary : colors.gray.primary)};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const MoveSize = Styled.H7`
  margin-top: 2px;
  color: ${({vars}) => (vars.isCancelled ? colors.gray.tertiary : colors.gray.primary)};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const getJobStartTimeText = (job) => {
  const showDate = Job.getIsChildJob(job) || Job.getIsEstimatedRange(job);
  const dateText = showDate ? `${Job.getDisplayDate(job, 'MM/DD', 'TBD')} ` : '';
  const startTimeText = job.startTime1 ? Datetime.convertToDisplayTime(job.startTime1) : 'TBD';
  const locationText = job.pickUpLocation.city;
  return `${dateText}${startTimeText} in ${locationText}`;
};

const UserDayJobUserItem = ({jobUser}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  return (
    <Container>
      <Touchable onPress={() => navigator.push(`/1/jobs/${jobUser.job.uuid}`)}>
        <Row {...responsive}>
          <Info {...responsive}>
            <Name numberOfLines={1} vars={{isCancelled: jobUser.job.isCancelled}}>
              {Job.getJobNameForCrew(jobUser.job)}
            </Name>
            <StartTime numberOfLines={1} vars={{isCancelled: jobUser.job.isCancelled}}>
              {getJobStartTimeText(jobUser.job)}
            </StartTime>
            <MoveSize numberOfLines={1} vars={{isCancelled: jobUser.job.isCancelled}}>
              {jobUser.job.project.size}
            </MoveSize>
          </Info>
        </Row>
      </Touchable>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserDayJobUserItem.fragment = gql`
  ${Job.getFullName.fragment}
  ${Job.getJobNameForCrew.fragment}
  ${Job.getIsEstimatedRange.fragment}
  ${Job.getIsChildJob.fragment}
  ${Job.getDisplayDate.fragment}

  fragment UserDayJobUserItem on JobUser {
    id
    job {
      id
      uuid
      isCancelled
      moveSize
      startTime1
      startTime2
      project {
        id
        size
      }
      pickUpLocation {
        id
        city
      }
      ...Job_getFullName
      ...Job_getJobNameForCrew
      ...Job_getIsEstimatedRange
      ...Job_getIsChildJob
      ...Job_getDisplayDate
    }
  }
`;

export default UserDayJobUserItem;
