// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import useBookingCalendarContext from 'modules/Calendar/Booking/context/useBookingCalendarContext';

import BookingCalendarJobItemV1 from './BookingCalendarJobItemV1';

const Container = Styled.View`
  flex: 1;
  padding-horizontal: 7px;
  min-width: 180px;
`;

const Content = Styled.View`
`;
const Status = Styled.View`
  flex-direction: row;
`;

const StatusText = Styled.H8`
  color: ${(props) => props.color};
  ${fontWeight(700)}
  font-size: 13px;
`;

const StatusSpace = Styled.View`
  margin-bottom: 5px;
`;

const getCrewAvailableColor = ({calendarDay}) => {
  if (calendarDay.crewAvailable <= 0) {
    return colors.Pink600;
  } else if (calendarDay.crewAvailable <= 2) {
    return colors.red.warning;
  } else {
    return colors.green.status;
  }
};

const sortJobs = ({jobs}) => {
  return _.sortBy(jobs, ['startTime1', 'startTime2']);
};

const JobItems = ({jobs}) => (
  <Content>
    {sortJobs({jobs}).map((job) => (
      <BookingCalendarJobItemV1 key={job.id} job={job} />
    ))}
  </Content>
);

const BookingCalendarWeekDayV1 = ({
  calendarDay,
  isEnabledBookingCalendarTruckAndMoverAvailability,
}) => {
  const {columnMinWidth} = useBookingCalendarContext();
  const availableMovers = calendarDay.crewAvailable;
  const assignedMovers = calendarDay.crewTotal - availableMovers;
  return (
    <Container style={{minWidth: columnMinWidth}}>
      <Space height={10} />
      {!isEnabledBookingCalendarTruckAndMoverAvailability && (
        <React.Fragment>
          <Status>
            <Icon
              color={getCrewAvailableColor({calendarDay})}
              size={Icon.Sizes.Medium}
              source={Icon.User}
              style={{marginRight: 5}}
            />
            <StatusText numberOfLines={1} color={getCrewAvailableColor({calendarDay})}>
              {`${availableMovers} available ${pluralize('mover', availableMovers)}`}
            </StatusText>
          </Status>
          <StatusSpace />
          <Status>
            <Icon
              color={colors.gray.secondary}
              size={Icon.Sizes.Medium}
              source={Icon.User}
              style={{marginRight: 5}}
            />
            <StatusText numberOfLines={1} color={colors.gray.secondary}>
              {`${assignedMovers} assigned ${pluralize('mover', assignedMovers)}`}
            </StatusText>
          </Status>
        </React.Fragment>
      )}
      <Space height={10} />
      <JobItems jobs={calendarDay.jobs} />
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
BookingCalendarWeekDayV1.propTypes = {
  isEnabledBookingCalendarTruckAndMoverAvailability: PropTypes.bool.isRequired,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BookingCalendarWeekDayV1.fragment = gql`
  ${BookingCalendarJobItemV1.fragment}

  fragment BookingCalendarWeekDayV1 on CalendarDayV4 {
    crewAvailable
    crewTotal
    status {
      kind
      value
      total
    }
    jobs {
      id
      ...BookingCalendarJobItemV1
    }
  }
`;

export default BookingCalendarWeekDayV1;
