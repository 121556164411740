// Libraries
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import AvatarPanel from 'modules/Organization/Settings/Company/components/AvatarPanel';
import CompanyLogoPanel from 'modules/Organization/Settings/Company/components/CompanyLogoPanel';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';

const CompanyBrandingContent = ({viewer, refetch}) => {
  return (
    <React.Fragment>
      <CompanyLogoPanel viewer={viewer} refetch={refetch} />
      <Space height={24} />
      <AvatarPanel viewer={viewer} refetch={refetch} />
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyBrandingPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsCompanyBrandingPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage
      title={'Branding'}
      description={
        'Upload your logos below to customize your document headers and virtual walkthroughs.'
      }
    >
      <Loading loading={loading}>
        {() => <CompanyBrandingContent viewer={data.viewer} refetch={refetch} />}
      </Loading>
    </CompanySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyBrandingPage.query = gql`
  ${AvatarPanel.fragment}
  ${CompanyLogoPanel.fragment}
  query OrganizationSettingsCompanyBrandingPage {
    ${gql.query}
    viewer {
      id
      ...AvatarPanel
      ...CompanyLogoPanel
    }
  }
`;

export default OrganizationSettingsCompanyBrandingPage;
