// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import Toast from '@shared/design/components/Toast';
import ProjectIsTestForm from '@shared/modules/Project/forms/ProjectIsTestForm';
import useSetProjectIsTestMutation from '@shared/modules/Project/hooks/useSetProjectIsTestMutation';

const ConvertToTrainingModal = ({project, isOpen, handleClose, refetch}) => {
  const projectConvertedToTrainingToast = useToast({ToastComponent: Toast});
  const projectIsTestForm = ProjectIsTestForm.edit(project);
  const {handleSubmit} = useSetProjectIsTestMutation({
    projectIsTestForm,
    onSuccess: ({project: {isTest}}) => {
      projectConvertedToTrainingToast.handleToast({
        message: `Project converted to ${isTest ? 'training' : 'real'}.`,
      });
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  return (
    <SmallModal.PreventPropagationContainer style={{position: 'absolute'}}>
      <ConfirmationModal
        isOpen={isOpen}
        icon={Icon.ExclamationTriangle}
        title={`Convert to ${project.isTest ? 'Real' : 'Training'}?`}
        subtitle={`This will convert the entire project and its jobs to ${
          project.isTest ? 'real' : 'training'
        }.`}
        handlePrimaryAction={handleSubmit}
        handleSecondaryAction={handleClose}
        primaryActionText={'Confirm'}
        secondaryActionText={'Cancel'}
      />
    </SmallModal.PreventPropagationContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ConvertToTrainingModal.fragment = gql`
  ${ProjectIsTestForm.edit.fragment}

  fragment ConvertToTrainingModal on Project {
    id
    isTest
    ...ProjectIsTestForm_edit
  }
`;

export default ConvertToTrainingModal;
