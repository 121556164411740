// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, usePopover} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Line from 'modules/App/components/Line';
import EditProjectLocationsDrawer from 'modules/Project/V2/Show/Blocks/components/EditProjectLocationsDrawer';
import LocationActionsPopover from 'modules/Project/V2/Show/Blocks/components/LocationActionsPopover';
import LocationsListItem from 'modules/Project/V2/Show/Blocks/components/LocationsListItem';
import DeleteProjectLocationModal from 'modules/Project/V2/Show/components/DeleteProjectLocationModal';

const Column = Styled.View`
`;

const SkeletonComponent = () => {
  return (
    <Column>
      <LocationsListItem.SkeletonComponent />
      <Space height={16} />
      <Line />
      <Space height={16} />
      <LocationsListItem.SkeletonComponent />
    </Column>
  );
};

const ProjectLocationActions = ({project, location, index, refetch}) => {
  const locationActionsPopover = usePopover();
  const editProjectLocationsDrawer = useDrawer({name: 'Edit Project Locations Drawer'});
  const deleteLocationModal = useModal({name: 'Delete Project Location Modal'});
  return (
    <React.Fragment>
      <LocationActionsPopover
        location={location}
        popover={locationActionsPopover}
        handleEdit={editProjectLocationsDrawer.handleOpen}
        handleDelete={deleteLocationModal.handleOpen}
        isDeletable
      >
        <TertiaryButton
          style={{paddingHorizontal: 6}}
          onPress={locationActionsPopover.handleToggle}
        >
          <Icon source={Icon.EllipsisV} size={16} color={colors.gray.secondary} />
        </TertiaryButton>
      </LocationActionsPopover>
      <EditProjectLocationsDrawer
        key={editProjectLocationsDrawer.key}
        isOpen={editProjectLocationsDrawer.isOpen}
        handleClose={editProjectLocationsDrawer.handleClose}
        project={project}
        scrollToIndex={index}
        refetch={refetch}
      />
      <DeleteProjectLocationModal
        key={deleteLocationModal.key}
        isOpen={deleteLocationModal.isOpen}
        handleClose={deleteLocationModal.handleClose}
        projectId={project.id}
        locationId={location.id}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const ProjectLocationsList = ({project, mapRef, locationIdsWithStreetView, refetch}) => {
  return (
    <Column>
      {project.locations.map((location, index) => {
        return (
          <React.Fragment key={location.id}>
            {index > 0 && (
              <React.Fragment>
                <Space height={16} />
                <Line />
                <Space height={16} />
              </React.Fragment>
            )}
            <LocationsListItem
              location={location}
              totalLocations={project.locations.length}
              index={index}
              mapRef={mapRef}
              hasStreetView={locationIdsWithStreetView.includes(location.id)}
              LocationActionsComponent={ProjectLocationActions}
              locationActionsComponentProps={{project, location, index, refetch}}
              isOrigin={_.toString(project.originLocationId) === location.id}
              isDestination={_.toString(project.destinationLocationId) === location.id}
            />
          </React.Fragment>
        );
      })}
    </Column>
  );
};

ProjectLocationsList.SkeletonComponent = SkeletonComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectLocationsList.fragment = gql`
  ${LocationsListItem.fragment}
  ${EditProjectLocationsDrawer.fragment}

  fragment ProjectLocationsList on Project {
    id
    originLocationId
    destinationLocationId
    locations {
      id
      ...LocationsListItem
    }
    ...EditProjectLocationsDrawer
  }
`;

export default ProjectLocationsList;
