// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Typography} from '@supermove/styles';

// Components
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import InviteUserForm from '@shared/modules/User/forms/InviteUserForm';
import useInviteUserToOrganizationMutation from '@shared/modules/User/hooks/useInviteUserToOrganizationMutation';
import UserProfileDrawerFields from 'modules/Organization/Settings/Users/components/UserProfileDrawerFields';

const Caption = Styled.Text`
  ${Typography.Label}
`;

const InviteUserDrawer = ({
  organizationId,
  refetch,
  isOpen,
  inviteOfficeUserSuccessModal,
  handleClose,
  isEnabledOperationsAdmins,
  viewerRole,
}) => {
  const inviteUserForm = InviteUserForm.new({organizationId});
  const {form, submitting, handleSubmit} = useInviteUserToOrganizationMutation({
    inviteUserForm,
    onSuccess: () => {
      handleClose();
      inviteOfficeUserSuccessModal.handleOpen();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Invite Office Member'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <Caption>
        Invite an office member to your organization. Unused invitations will expire in 7 days.
      </Caption>
      <Space height={16} />
      <UserProfileDrawerFields
        form={form}
        field={'inviteUserForm'}
        showStatus={false}
        isEnabledOperationsAdmins={isEnabledOperationsAdmins}
        viewerRole={viewerRole}
      />
    </DrawerWithAction>
  );
};

export default InviteUserDrawer;
