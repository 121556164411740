// Libraries
import _ from 'lodash';

export interface NavigationItem {
  label: string;
  value:
    | string
    | {
        name: string;
        params?: object;
      };
  valueKey: 'section' | 'route';
  items?: NavigationItem[];
}

const includeIf = (flag: boolean, navigationItem: NavigationItem): NavigationItem[] => {
  return flag ? [navigationItem] : [];
};

// --------------------------------------------------
// Helpers
// --------------------------------------------------
const getLabelsFromNavigationItems = ({
  parentLabel,
  items,
}: {
  parentLabel: string;
  items?: NavigationItem[];
}): {[key: string]: string} => {
  const extractLabels = (all: any, item: NavigationItem) => {
    return {
      ...all,
      ...getLabelsFromNavigationItems({parentLabel: item.label, items: item.items}),
      [item.label]: item.value,
    };
  };
  const children = items || [];
  const childrenLabels = _.reduce(children, extractLabels, {});
  return _.mapKeys(childrenLabels, (value, label) =>
    parentLabel ? `${parentLabel}/${label}` : label,
  );
};

/**
 * Takes in a label and returns the route for the label. For nested items, the labels
 * are combined using a '/'.
 */
const getRouteForLabel = ({
  navigationItems,
  label,
}: {
  navigationItems: NavigationItem[];
  label: string;
}): string => {
  const labelsToValue = getLabelsFromNavigationItems({
    parentLabel: '',
    items: navigationItems,
  });
  return labelsToValue[label];
};

const NavigationItems = {
  includeIf,
  getRouteForLabel,
};

export default NavigationItems;
