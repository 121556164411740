// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import LocationTypeForm from '@shared/modules/Organization/forms/LocationTypeForm';
import useUpdateOrganizationLocationTypesMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationLocationTypesMutation';

const ItemContainer = Styled.View`
  background-color: ${colors.white};
  max-width: 464px;
  border-radius: 8px;
  padding: 12px 16px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  z-index: ${(props) => 100 - props.index};
  flex: 1;
`;

const LocationTypeText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.black};
`;

const Container = Styled.View`
  flex-direction: row;
  flex: 1;
  /* Must use padding here to get spacing between components in drag and drop list */
  /* Drag and drop does some weird stuff with the Space component so it does not show up properly */
  padding-vertical: 4px;
`;

const ActionButton = Styled.ButtonV2`
  min-width: 16px;
  align-items: center;
`;

const EllipseButton = ({locationTypeForm, refetch, organization}) => {
  const deleteLocationTypeModal = useModal({name: 'Delete Location Type Modal'});
  const actionsPopover = usePopover();
  const {handleSubmit} = useUpdateOrganizationLocationTypesMutation({
    organizationLocationTypesForm: {
      organizationId: organization.id,
      locationTypeForms: [
        ...organization.settings.locationTypes
          .filter((locationType) => locationType.name !== locationTypeForm.name)
          .map((locationType) => LocationTypeForm.edit(locationType)),
      ],
    },
    onSuccess: () => {
      refetch();
      deleteLocationTypeModal.handleClose();
    },
    onError: (error) => console.log(error),
  });
  return (
    <React.Fragment>
      <Popover.Content innerRef={actionsPopover.ref}>
        <ActionButton onPress={actionsPopover.handleOpen}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </ActionButton>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={actionsPopover.isOpen}
        handleOpen={actionsPopover.handleOpen}
        handleClose={actionsPopover.handleClose}
        reference={actionsPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          handleClose={actionsPopover.handleClose}
          actions={[
            {
              text: 'Remove',
              onPress: deleteLocationTypeModal.handleOpen,
            },
          ]}
        />
      </Popover>
      <DeleteModal
        title={`Remove ${locationTypeForm.name}?`}
        subtitle={`Removing will not impact any existing projects connected to this location type.`}
        isOpen={deleteLocationTypeModal.isOpen}
        handleClose={deleteLocationTypeModal.handleClose}
        handleDelete={handleSubmit}
      />
    </React.Fragment>
  );
};

const LocationTypeItem = ({locationTypeForm, index, refetch, organization}) => {
  return (
    <Container>
      <ItemContainer index={index}>
        <LocationTypeText>{locationTypeForm.name}</LocationTypeText>
        <EllipseButton
          locationTypeForm={locationTypeForm}
          refetch={refetch}
          organization={organization}
        />
      </ItemContainer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
LocationTypeItem.fragment = gql`
  ${LocationTypeForm.edit.fragment}
  fragment LocationTypeItem on Organization {
    id
    settings {
      id
      locationTypes {
        ...LocationTypeForm_edit
      }
    }
  }
`;

export default LocationTypeItem;
