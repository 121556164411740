// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import EmptyState from '@shared/design/components/EmptyState';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import InventoryItemsPanel from 'modules/Inventory/InventoryItemsPanel';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const BLOCK_TITLE = 'Items';

const EmptyBody = () => {
  return (
    <EmptyState
      icon={Icon.BoxOpen}
      title={'No inventory items to display'}
      message={'Once inventory is captured, items will appear here.'}
      paddingVertical={24}
    />
  );
};

const SkeletonComponent = () => {
  return (
    <ActionPanel
      BodyComponent={() => <SkeletonLoader height={150} isFullWidth />}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
    />
  );
};

const ProjectInventoryItemsBlockContent = ({project, jobIds}) => {
  return (
    <ActionPanel
      BodyComponent={InventoryItemsPanel}
      bodyComponentProps={{inventory: project.driverInventory, jobIds}}
      EmptyBodyComponent={EmptyBody}
      isEmpty={_.isEmpty(project.driverInventory) || !project.driverInventory.lotsByJobIds.length}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
    />
  );
};

const ProjectInventoryItemsBlock = ({
  project,
  handleSetPositionY,
  layoutKey,
  index,
  projectBlockKind,
}) => {
  return (
    <ProjectBlockWrapper
      index={index}
      query={ProjectInventoryItemsBlock.query}
      queryVariables={{
        projectUuid: project.uuid,
        jobIds: project.activeMoveJobs.map((job) => job.id),
      }}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data}) => {
        return (
          <ProjectInventoryItemsBlockContent
            project={data.project}
            jobIds={project.activeMoveJobs.map((job) => job.id)}
          />
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectInventoryItemsBlock.fragment = gql`
  fragment ProjectInventoryItemsBlock on Project {
    id
    uuid
    activeMoveJobs {
      id
    }
  }
`;

ProjectInventoryItemsBlock.query = gql`
  ${InventoryItemsPanel.fragment}

  query ProjectInventoryItemsBlock($projectUuid: String!, $jobIds: [Int]) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      uuid
      driverInventory {
        id
        lotsByJobIds(jobIds: $jobIds) {
          lotNumber
        }
        ...InventoryItemsPanel
      }
    }
  }
`;

export default ProjectInventoryItemsBlock;
