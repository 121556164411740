// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDrawer,
  useNavigationDOM,
  usePagination,
  useQuery,
  useState,
  useUrlFilters,
} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {URL} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import EmptyState from '@shared/design/components/EmptyState';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Switch from '@shared/design/components/Switch';
import Tabs from '@shared/design/components/Tabs';
import UserRole from '@shared/modules/User/enums/UserRole';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import BulkUpdateTasksActions from 'modules/TaskManagement/Tasks/List/components/BulkUpdateTaskActions';
import TasksListFilters from 'modules/TaskManagement/Tasks/List/components/TasksListFilters';
import TasksTable from 'modules/TaskManagement/Tasks/List/components/TasksTable';
import EditTaskDrawer from 'modules/TaskManagement/Tasks/components/EditTaskDrawer';
import NewTaskDrawer from 'modules/TaskManagement/Tasks/components/NewTaskDrawer';

const Section = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const PageContainer = Styled.View`
  flex: 1;
  min-width: 1140px;
`;

const PageContentContainer = Styled.View`
  background-color: ${colors.gray.background};
  padding-horizontal: 24px;
  flex: 1;
`;

const TABS = {
  ME: 'me',
  TEAM: 'team',
  ALL: 'all',
};

const getShowTeamsTab = ({viewer}) => {
  return !_.isEmpty(viewer.teamMemberships);
};

const getTabDefinitions = ({taskCounts, viewer}) => {
  return [
    {
      label: 'My Tasks',
      count: _.get(taskCounts, 'myTasks', '  '),
      tab: TABS.ME,
    },
    ...(getShowTeamsTab({viewer})
      ? [
          {
            label: 'My Teams',
            count: _.get(taskCounts, 'teamTasks', '  '),
            tab: TABS.TEAM,
          },
        ]
      : []),
    ...(UserRole.hasManagerPermissions(viewer.role)
      ? [
          {
            label: 'All Tasks',
            count: _.get(taskCounts, 'allTasks', '  '),
            tab: TABS.ALL,
          },
        ]
      : []),
  ];
};

const getActiveTabIndex = ({params, viewer}) => {
  const TAB_TO_INDEX = {
    [TABS.ME]: 0,
    [TABS.TEAM]: 1,
    [TABS.ALL]: getShowTeamsTab({viewer}) ? 2 : 1,
  };
  return _.get(TAB_TO_INDEX, params.tab, 0);
};

const getPageUrl = ({params, page}) => {
  const {tab, ...filters} = params;
  const variables = {...filters, page};
  return URL.getUrlFromVariables(`/tasks/${tab}`, variables);
};

const getAssigneeFiltersFromParams = ({params}) => {
  // Note(dan): The assignee values in the params will be written as such:
  // "USER_XX" or "TEAM_XX", with "XX" being the id. The logic below splits
  // the param value by the underscore and uses the resulting values to
  // determine if this is a team id or a user id.
  const assignedToValues = _.get(params, 'assignedToValues', []);
  const filters = _.reduce(
    assignedToValues,
    (result, value) => {
      if (value === '-1') {
        return {...result, USER_IDS: [...result.USER_IDS, -1]};
      }
      const [type, id] = value.split('-');
      if (type === 'USER') {
        return {...result, USER_IDS: [...result.USER_IDS, _.toNumber(id)]};
      }
      if (type === 'TEAM') {
        return {...result, TEAM_IDS: [...result.TEAM_IDS, _.toNumber(id)]};
      }
    },
    {USER_IDS: [], TEAM_IDS: []},
  );
  return filters;
};

const getAssignedToUserIdsFilter = ({params, assigneeFiltersFromParams}) => {
  switch (params.tab) {
    case TABS.ME:
      // Backend will handle null by filtering only for the current viewer
      return null;
    case TABS.TEAM:
      // Teams tab will only watch for team assignee filters
      return [];
    default:
      return assigneeFiltersFromParams.USER_IDS;
  }
};

const getAssignedToTeamIdsFilter = ({params, assigneeFiltersFromParams}) => {
  switch (params.tab) {
    case TABS.ME:
      // Ignore any assignee filters on 'My Tasks' tab
      return [];
    case TABS.TEAM:
      // Use team filters if present. Otherwise, null will return all current viewer's teams
      return _.isEmpty(assigneeFiltersFromParams.TEAM_IDS)
        ? null
        : assigneeFiltersFromParams.TEAM_IDS;
    default:
      return assigneeFiltersFromParams.TEAM_IDS;
  }
};

const TasksListPageContent = ({
  loading,
  organization,
  tasks,
  taskCounts,
  refetch,
  paginationMetadata,
  viewer,
}) => {
  const {navigator, params} = useNavigationDOM();
  const [isBulkEditEnabled, setIsBulkEditEnabled] = useState(false);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const newTaskDrawer = useDrawer({name: 'New Task Drawer - Tasks Page'});
  const {tab, ...filters} = params;

  const pagination = usePagination({
    currentPage: _.toNumber(params.page || 1),
    paginationMetadata,
    onChangePage: (page) => {
      navigator.replace(getPageUrl({params, page}));
    },
  });

  const urlFilters = useUrlFilters({
    getRoute: () => `/tasks/${params.tab}`,
    filtersForUpdate: {page: '1'},
    onUpdate: () => setSelectedTaskIds([]),
    filterKeys: [
      'query',
      'isCompleted',
      'fromDueDate',
      'toDueDate',
      'assignedToValues',
      'isArchived',
      'projectTagIds',
    ],
  });

  return (
    <PageContentContainer>
      <Space height={24} />
      <Section index={0}>
        <Row>
          <TasksListFilters urlFilters={urlFilters} organization={organization} />
          <Space width={10} />
          {isBulkEditEnabled ? (
            <BulkUpdateTasksActions
              taskIds={selectedTaskIds}
              onUpdate={() => {
                setSelectedTaskIds([]);
                setIsBulkEditEnabled(false);
                refetch();
              }}
            />
          ) : (
            <Button text={'Create Task'} iconLeft={Icon.Plus} onPress={newTaskDrawer.handleOpen} />
          )}
        </Row>
        <Space height={16} />
      </Section>
      <Section index={2}>
        <Row>
          <Tabs
            tabs={getTabDefinitions({taskCounts, viewer})}
            handlePressTab={({tab}) => {
              const variables = {...filters, page: '1'};
              navigator.push(URL.getUrlFromVariables(`/tasks/${tab}`, variables));
            }}
            activeTabIndex={getActiveTabIndex({params, viewer})}
          />
          <Space style={{flex: 1}} />
          <Switch
            isOn={isBulkEditEnabled}
            labelRight={'Bulk Edit'}
            onChange={() => {
              setIsBulkEditEnabled(!isBulkEditEnabled);
              setSelectedTaskIds([]);
            }}
          />
        </Row>
      </Section>
      <Section index={2} style={{flex: 1}}>
        <TasksTable
          loading={loading}
          tasks={tasks}
          organization={organization}
          isBulkEditEnabled={isBulkEditEnabled}
          selectedTaskIds={selectedTaskIds}
          setSelectedTaskIds={setSelectedTaskIds}
          EmptyStateComponent={() => {
            return (
              <EmptyState.ContentContainer>
                <EmptyState
                  title={'No tasks found.'}
                  message={'Create a new task or try clearing your filters.'}
                  primaryActionIcon={Icon.Plus}
                  primaryActionText={'Create Task'}
                  handlePrimaryAction={newTaskDrawer.handleOpen}
                  secondaryActionIcon={Icon.Trash}
                  secondaryActionText={'Clear Filters'}
                  handleSecondaryAction={() => urlFilters.handleReset()}
                />
              </EmptyState.ContentContainer>
            );
          }}
          refetch={refetch}
        />
      </Section>
      <Space height={32} />
      <PaginationBar pagination={pagination} />
      <Space height={32} />
      <NewTaskDrawer
        isOpen={newTaskDrawer.isOpen}
        handleClose={newTaskDrawer.handleClose}
        refetch={refetch}
      />
    </PageContentContainer>
  );
};

const UrlTaskDrawer = ({task, refetch}) => {
  const {navigator} = useNavigationDOM();
  const editTaskDrawer = useDrawer({
    name: 'Edit Task Drawer',
    enableTracking: true,
    initialIsOpen: true,
  });

  return (
    <EditTaskDrawer
      isOpen={editTaskDrawer.isOpen}
      handleClose={() => {
        editTaskDrawer.handleClose();
        navigator.replace(navigator.location.pathname);
      }}
      task={task}
      refetch={refetch}
    />
  );
};

const TasksListPage = () => {
  const {params} = useNavigationDOM();
  const assigneeFiltersFromParams = getAssigneeFiltersFromParams({params});
  const {data, loading, refetch} = useQuery(TasksListPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        page: params.page,
        resultsPerPage: 50,
      },
      searchQuery: params.query,
      isCompleted: params.isCompleted ? params.isCompleted === 'true' : null,
      fromDueDate: params.fromDueDate,
      toDueDate: params.toDueDate,
      isArchived: params.isArchived,
      assignedToUserIds: getAssignedToUserIdsFilter({params, assigneeFiltersFromParams}),
      assignedToTeamIds: getAssignedToTeamIdsFilter({params, assigneeFiltersFromParams}),
      projectTagIds: params.projectTagIds,
      taskUuid: params.taskUuid || '',
    },
  });

  const wrapperQuery = useQuery(TasksListPage.wrapperQuery, {
    fetchPolicy: 'cache-and-network',
  });

  // A url task exists when a task gets shared via the copy link
  // action in the edit task drawer. When a user opens this link
  // we want to automatically open a drawer for the task .
  const urlTask = _.get(data, 'task');

  return (
    <SidebarPageV2 selected={'tasks'}>
      <PageContainer>
        <Loading loading={wrapperQuery.loading || loading}>
          {() => {
            return (
              <React.Fragment>
                <StandardOfficeHeader title={'Tasks'} />
                <TasksListPageContent
                  viewer={wrapperQuery.data.viewer}
                  organization={_.get(data, 'viewer.viewingOrganization')}
                  tasks={_.get(data, 'paginatedList.tasks', [])}
                  taskCounts={_.get(data, 'taskCounts', {})}
                  paginationMetadata={_.get(data, 'paginatedList.paginationMetadata')}
                  loading={loading}
                  refetch={refetch}
                />
              </React.Fragment>
            );
          }}
        </Loading>
      </PageContainer>
      {!!urlTask && <UrlTaskDrawer task={urlTask} refetch={refetch} />}
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TasksListPage.query = gql`
  ${EditTaskDrawer.fragment}
  ${TasksTable.fragment}
  ${usePagination.fragment}
  ${TasksListFilters.fragment}

  query TasksListPage(
    $pagination: PaginationInput!
    $searchQuery: String
    $assignedToUserIds: [Int]
    $assignedToTeamIds: [Int]
    $projectTagIds: [Int]
    $isCompleted: Boolean
    $isArchived: Boolean
    $fromDueDate: String
    $toDueDate: String
    $taskUuid: String!
  ) {
    viewer {
      id
      viewingOrganization {
        id
        ...TasksListFilters
        ...TasksTable_Organization
      }
    }
    paginatedList: filteredTasksPaginatedList(
      pagination: $pagination
      searchQuery: $searchQuery
      assignedToUserIds: $assignedToUserIds
      assignedToTeamIds: $assignedToTeamIds
      projectTagIds: $projectTagIds
      isCompleted: $isCompleted
      isArchived: $isArchived
      fromDueDate: $fromDueDate
      toDueDate: $toDueDate
    ) {
      tasks: results {
        id
        ...TasksTable
      }
      paginationMetadata {
        ...usePagination
      }
    }
    taskCounts(
      searchQuery: $searchQuery
      assignedToUserIds: $assignedToUserIds
      assignedToTeamIds: $assignedToTeamIds
      projectTagIds: $projectTagIds
      isCompleted: $isCompleted
      isArchived: $isArchived
      fromDueDate: $fromDueDate
      toDueDate: $toDueDate
    ) {
      myTasks
      teamTasks
      allTasks
    }
    task(uuid: $taskUuid) {
      id
      ...EditTaskDrawer
    }
  }
`;

TasksListPage.wrapperQuery = gql`
  query TasksListPage {
    viewer {
      id
      role
      teamMemberships {
        id
      }
    }
  }
`;

export default TasksListPage;
