// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import UpdateProjectValuesForm from '@shared/modules/Billing/forms/UpdateProjectValuesForm';

const useUpdateProjectValuesMutation = ({updateProjectValuesForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      updateProjectValuesForm: UpdateProjectValuesForm.toForm(updateProjectValuesForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectValuesMutation.mutation,
    variables: {
      updateProjectValuesForm: UpdateProjectValuesForm.toMutation(
        form.values.updateProjectValuesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectValuesMutation.mutation = gql`
  mutation useUpdateProjectValuesMutation($updateProjectValuesForm: UpdateProjectValuesForm!) {
    response: updateProjectValues(updateProjectValuesForm: $updateProjectValuesForm) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useUpdateProjectValuesMutation;
