// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import DeleteAttachmentForm from '@shared/modules/File/forms/DeleteAttachmentForm';
import useDeleteAttachmentForm from '@shared/modules/File/hooks/useDeleteAttachmentForm';

type OwnProps = {
  attachmentId: string;
  attachmentName: string;
  deleteAttachmentModal: any;
  onDeleteSuccess?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DeleteAttachmentModal.defaultProps;

// @ts-expect-error TS(7022): 'DeleteAttachmentModal' implicitly has type 'any' ... Remove this comment to see the full error message
const DeleteAttachmentModal = ({
  attachmentId,
  attachmentName,
  deleteAttachmentModal,
  onDeleteSuccess,
}: Props) => {
  const deleteAttachmentForm = DeleteAttachmentForm.new({attachmentId});
  const {handleSubmit} = useDeleteAttachmentForm({
    deleteAttachmentForm,
    onSuccess: () => {
      deleteAttachmentModal.handleClose();
      onDeleteSuccess();
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <DeleteModal
      handleDelete={handleSubmit}
      handleClose={deleteAttachmentModal.handleClose}
      isOpen={deleteAttachmentModal.isOpen}
      title={`Remove "${attachmentName}"?`}
      subtitle={`You can't undo this action.`}
    />
  );
};

DeleteAttachmentModal.defaultProps = {
  onDeleteSuccess: () => {},
};

export default DeleteAttachmentModal;
