// Supermove
import {Datetime, Json} from '@supermove/utils';

// App
import LocationKind from '@shared/modules/Location/enums/LocationKind';
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import CustomerForm from '@shared/modules/User/forms/CustomerForm';

const _new = ({bookedById, organizationId, preferredDate, additionalItems}: any) => ({
  // Relations
  organizationId,
  bookedById,

  // Forms
  customerForm: CustomerForm.new({organizationId}),
  locationForms: [
    // NOTE(mark): We seed with one pick up and one drop off for now.
    LocationForm.new({kind: LocationKind.PICK_UP}),
    LocationForm.new({kind: LocationKind.DROP_OFF}),
  ],
  locationDistances: '[]',

  // Job attributes
  moveSize: '',
  preferredCrewSize: '',
  preferredDate,
  preferredStartTime: '',
  isFlexible: false,
  notes: '',
  additionalItems,
  referralSource: '',
  referralDetails: '',
});

const toForm = ({
  organizationId,
  bookedById,
  customerForm,
  locationForms,
  locationDistances,
  moveSize,
  preferredCrewSize,
  preferredDate,
  preferredStartTime,
  isFlexible,
  notes,
  additionalItems,
  referralSource,
  referralDetails,
}: any) => ({
  organizationId,
  bookedById,
  customerForm: CustomerForm.toForm(customerForm),
  locationForms: locationForms.map((locationForm: any) => LocationForm.toForm(locationForm)),
  locationDistances: Json.toForm(locationDistances),
  moveSize,
  preferredCrewSize,
  preferredDate: Datetime.toFormDate(preferredDate),
  preferredStartTime: Datetime.toFormTime(preferredStartTime),
  isFlexible,
  notes,
  additionalItems: Json.toForm(additionalItems),
  referralSource,
  referralDetails,
});

const toMutation = ({
  organizationId,
  bookedById,
  customerForm,
  locationForms,
  locationDistances,
  moveSize,
  preferredCrewSize,
  preferredDate,
  preferredStartTime,
  isFlexible,
  notes,
  additionalItems,
  referralSource,
  referralDetails,
}: any) => ({
  organizationId,
  bookedById,
  customerForm: CustomerForm.toMutation(customerForm),
  locationForms: locationForms.map((locationForm: any) => LocationForm.toMutation(locationForm)),
  locationDistances,
  moveSize,
  preferredCrewSize,
  preferredDate: Datetime.toMutationDate(preferredDate),
  preferredStartTime: Datetime.toMutationTime(preferredStartTime),
  isFlexible,
  notes,
  additionalItems: Json.toMutation(additionalItems),
  referralSource,
  referralDetails,
});

const JobRequestForm = {
  new: _new,
  toForm,
  toMutation,
};

export default JobRequestForm;
