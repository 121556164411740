// Libraries
import React from 'react';

// Supermove
import {Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, usePopover} from '@supermove/hooks';
import {Linking} from '@supermove/sdk';
import {Phone} from '@supermove/utils';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import ProjectWidgetKind from '@shared/modules/Project/enums/ProjectWidgetKind';
import EditProjectClientsDrawer from 'modules/Project/V2/Show/Blocks/components/EditProjectClientsDrawer';

import ProjectClientDataActions from './ProjectClientDataActions';

const ClientPhoneNumberActionsPopover = ({popover, project, client, urlFilters}) => {
  return (
    <Popover
      key={popover.key}
      placement={Popover.Positions.BottomEnd}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ActionMenu
        handleClose={popover.handleClose}
        width={320}
        maxHeight={244}
        isTruncated
        actions={[
          {
            text: `Call ${Phone.display(client.primaryContact.phoneNumber)}`,
            onPress: () => Linking.openURL(Phone.createUrl(client.primaryContact.phoneNumber)),
          },
          {
            text: 'Send SMS',
            onPress: () => urlFilters.handleUpdate({widget: ProjectWidgetKind.SMS}),
          },
          ...project.organization.customerTextMessageTemplates
            .filter((textMessageTemplate) => textMessageTemplate.systemTemplateKind === 'CUSTOM')
            .map((textMessageTemplate) => ({
              text: `Compose '${textMessageTemplate.name}' SMS`,
              onPress: () =>
                urlFilters.handleUpdate({
                  widget: ProjectWidgetKind.SMS,
                  textMessageTemplateUuid: textMessageTemplate.uuid,
                }),
            })),
        ]}
      />
    </Popover>
  );
};

const ProjectClientPhoneNumber = ({project, urlFilters, isMicro, isBilling}) => {
  const client = isBilling ? project.billingClient : project.client;
  const clientPhoneNumberActionsPopover = usePopover({name: 'Client Phone Number Actions Popover'});
  const editProjectClientsDrawer = useDrawer({name: 'Edit Project Clients Drawer'});

  return (
    <React.Fragment>
      <ProjectClientDataActions
        data={Phone.display(client.primaryContact.phoneNumber)}
        addDataText={'Add Phone Number'}
        onAddDataPress={editProjectClientsDrawer.handleOpen}
        icon={isMicro && Icon.Phone}
        iconTooltip={'Contact Phone Number'}
        isBilling={isBilling}
        isMicro={isMicro}
        popover={clientPhoneNumberActionsPopover}
      />
      <EditProjectClientsDrawer
        key={editProjectClientsDrawer.key}
        isOpen={editProjectClientsDrawer.isOpen}
        handleClose={editProjectClientsDrawer.handleClose}
        project={project}
      />
      <ClientPhoneNumberActionsPopover
        popover={clientPhoneNumberActionsPopover}
        project={project}
        client={client}
        urlFilters={urlFilters}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectClientPhoneNumber.fragment = gql`
  ${EditProjectClientsDrawer.fragment}
  fragment ProjectClientPhoneNumber on Project {
    id
    client {
      id
      primaryContact {
        id
        phoneNumber
      }
    }
    billingClient {
      id
      primaryContact {
        id
        phoneNumber
      }
    }
    organization {
      id
      customerTextMessageTemplates: textMessageTemplatesByCategory(categories: ["CUSTOMER"]) {
        id
        name
        uuid
        systemTemplateKind
      }
    }
    ...EditProjectClientsDrawer
  }
`;

export default ProjectClientPhoneNumber;
