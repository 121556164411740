// Libraries
import React from 'react';

const COLUMN_MIN_WIDTH = 180;

export const BookingCalendarContext = React.createContext(null);

const BookingCalendarContextProvider = ({children}) => {
  const store = {
    columnMinWidth: COLUMN_MIN_WIDTH,
    pageMinWidth: COLUMN_MIN_WIDTH * 7,
  };

  return (
    <BookingCalendarContext.Provider value={store}>{children}</BookingCalendarContext.Provider>
  );
};

export default BookingCalendarContextProvider;
