// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import JobTimeline from 'modules/Job/Show/components/JobTimeline';

const JobTimelineBlock = ({job, refetch}) => {
  return <JobTimeline showEventHistory={false} job={job} refetch={refetch} />;
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobTimelineBlock.propTypes = {
  job: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

JobTimelineBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTimelineBlock.fragment = gql`
  ${JobTimeline.fragment}

  fragment JobTimelineBlock on Job {
    id
    ...JobTimeline
  }
`;

export default JobTimelineBlock;
