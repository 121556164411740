// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CodatIntegrationForm from '@shared/modules/Integration/forms/CodatIntegrationForm';

const useCreateCodatIntegration = ({codatIntegrationForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      codatIntegrationForm: CodatIntegrationForm.toForm(codatIntegrationForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateCodatIntegration.mutation,
    variables: {
      codatIntegrationForm: CodatIntegrationForm.toMutation(form.values.codatIntegrationForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateCodatIntegration.mutation = gql`
  mutation useCreateCodatIntegration($codatIntegrationForm: CodatIntegrationForm!) {
    response: createCodatIntegration(codatIntegrationForm: $codatIntegrationForm) {
      ${gql.errors}
      codatIntegration {
        id
        codatRedirectUrl
      }
    }
  }
`;

export default useCreateCodatIntegration;
