// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Icon, Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import useUpsertSurveyMethodMutation from '@shared/modules/Inventory/hooks/useUpsertSurveyMethodMutation';

const ItemContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 12px 16px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  z-index: ${(props) => 100 - props.index};
  flex: 1;
`;

const IconButton = Styled.ButtonV2`
`;

const Container = Styled.View`
  flex-direction: row;
  flex: 1;
  /* Must use padding here to get spacing between components in drag and drop list */
  /* Drag and drop does some weird stuff with the Space component so it does not show up properly */
  padding-vertical: 4px;
`;

const ActionContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const EditSurveyMethodItem = ({surveyMethodForm, index, refetch, handleCloseEdit}) => {
  const {form, handleSubmit, submitting} = useUpsertSurveyMethodMutation({
    surveyMethodForm,
    onSuccess: ({surveyMethod}) => {
      refetch();
      handleCloseEdit({surveyMethodId: _.get(surveyMethod, 'id')});
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <Container>
      <ItemContainer index={index}>
        <FieldInput
          {...form}
          name={'surveyMethodForm.name'}
          input={{
            autoFocus: true,
            placeholder: 'Enter survey method name',
            setFieldValue: form.setFieldValue,
            style: {
              width: '100%',
            },
          }}
          style={{flex: 1}}
          handleEnter={handleSubmit}
        />
        <Space width={24} />
        <ActionContainer>
          <IconButton onPress={handleSubmit} disabled={submitting}>
            <Icon source={Icon.Check} color={colors.blue.interactive} size={16} />
          </IconButton>
          <Space width={12} />
          <IconButton
            onPress={() =>
              handleCloseEdit({surveyMethodId: _.get(surveyMethodForm, 'surveyMethodId')})
            }
            disabled={submitting}
          >
            <Icon source={Icon.Times} color={colors.gray.secondary} size={16} />
          </IconButton>
          <Space width={3} />
        </ActionContainer>
      </ItemContainer>
    </Container>
  );
};

export default EditSurveyMethodItem;
