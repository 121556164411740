// Libraries
import React from 'react';

// Supermove
import {Icon, Modal, Space, Styled} from '@supermove/components';
import {useForm} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import Field from 'modules/App/components/Field';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const MobileContainer = Styled.View`
  background-color: ${colors.gray.background};
  flex: 1;
`;

const HeaderContainer = Styled.View`
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  padding-horizontal: 4px;
`;

const TitleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const HeaderButton = Styled.ButtonV2`
  padding-horizontal: 12px;
  align-items: center;
`;

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const FieldsContainer = Styled.View`
`;

const TitleInput = Styled.TextInput.H6`
  border-color: ${colors.gray.border};
`;

const DescriptionInput = Styled.TextInput.H6`
  border-color: ${colors.gray.border};
  height: 100px;
  padding-top: 12px;
`;

const InputLabelText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  padding-bottom: 4px;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 40px;
  padding-horizontal: 30px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const ButtonText = Styled.H5`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const ActivityIndicator = Styled.Loading`
`;

const EditBillHeader = ({handleClose}) => {
  return (
    <HeaderContainer>
      <Space height={16} />
      <Row>
        <HeaderButton onPress={handleClose}>
          <Icon source={Icon.ArrowLeft} size={20} color={colors.gray.primary} />
        </HeaderButton>
        <TitleText>Edit Bill</TitleText>
      </Row>
      <Space height={16} />
    </HeaderContainer>
  );
};

const EditBillFields = ({form}) => {
  return (
    <FieldsContainer>
      <InputLabelText>Title</InputLabelText>
      <Field
        {...form}
        name={`title`}
        component={TitleInput}
        input={{
          placeholder: 'Enter title',
          required: !form.values.title,
        }}
      />
      <Space height={16} />
      <InputLabelText>Description</InputLabelText>
      <Space height={4} />
      <Field
        {...form}
        name={`description`}
        component={DescriptionInput}
        input={{
          placeholder: 'Enter description',
          multiline: true,
        }}
      />
    </FieldsContainer>
  );
};

const EditBillFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <Actions>
      <Button color={colors.gray.background} onPress={handleClose} disabled={submitting}>
        <ButtonText color={colors.gray.tertiary}>Cancel</ButtonText>
      </Button>
      <Space width={8} />
      <Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        onPress={handleSubmit}
        disabled={submitting}
      >
        {submitting ? (
          <ActivityIndicator color={colors.white} size={'small'} />
        ) : (
          <ButtonText color={colors.white}>Done</ButtonText>
        )}
      </Button>
    </Actions>
  );
};

const EditBillEditTitleModal = ({isOpen, handleClose, billForm, handleSubmit}) => {
  const {title, description} = billForm;
  const form = useForm({initialValues: {title, description}});

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <MobileContainer>
        <EditBillHeader handleClose={handleClose} />
        <Space height={16} />
        <ContentContainer>
          <EditBillFields form={form} />
          <Space height={16} />
          <EditBillFooter
            handleClose={handleClose}
            handleSubmit={() => handleSubmit(form.values)}
          />
        </ContentContainer>
      </MobileContainer>
    </Modal.Content>
  );
};

export default EditBillEditTitleModal;
