// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import DocumentForm from '@shared/modules/Document/forms/DocumentForm';

const _new = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ document, step }: { document:... Remove this comment to see the full error message
  ({document, step}) => {
    return {
      step,
      documentForm: DocumentForm.edit(document),

      // Private
      numberOfErrors: 0,
    };
  },
  gql`
    ${DocumentForm.edit.fragment}

    fragment CompleteDocumentV2Form_new on Document {
      id
      ...DocumentForm_edit
    }
  `,
);

const toForm = ({step, documentForm, numberOfErrors}: any) => {
  return {
    step,
    documentForm: DocumentForm.toForm(documentForm),

    // Private
    numberOfErrors,
  };
};

const toMutation = ({step, documentForm}: any) => {
  return {
    step,
    documentForm: DocumentForm.toMutation(documentForm),
  };
};

const CompleteDocumentV2Form = {
  new: _new,
  toForm,
  toMutation,
};

export default CompleteDocumentV2Form;
