// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = (organizationId: any) => ({
  organizationId,
  name: '',
  size: '',
  notes: '',
});

const edit = withFragment(
  (truck) => ({
    truckId: (truck as any).id,
    name: (truck as any).name,
    size: (truck as any).size,
    notes: (truck as any).notes,
    status: (truck as any).status,
  }),
  gql`
    fragment TruckForm_edit on Truck {
      id
      name
      size
      notes
      status
    }
  `,
);

const toForm = ({truckId, organizationId, name, size, notes, isActive, status}: any) => ({
  truckId,
  organizationId,
  name,
  size,
  notes,
  status,
});

const toMutation = ({truckId, organizationId, name, size, notes, isActive, status}: any) => ({
  truckId,
  organizationId,
  name,
  size,
  notes,
  status,
});
const TruckForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default TruckForm;
