// Libraries
import React from 'react';

// App
import OrganizationSettingsEmailPageContent from 'modules/Organization/Settings/Email/components/OrganizationSettingsEmailPageContent';

const EmailTemplatesPage = () => {
  return <OrganizationSettingsEmailPageContent isCommunicationSettings />;
};

export default EmailTemplatesPage;
