// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

// App

// Components
import AccountingPageHeader from 'modules/Accounting/components/AccountingPageHeader';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import CrewPayrollPageContent from 'modules/Payroll/Crew/components/CrewPayrollPageContent';
import {DateRangeHandler} from 'modules/Payroll/components';

const Container = Styled.View`
  flex: 1;
`;

const ListCrewPayrollPage = () => {
  const {loading, data} = useQuery(ListCrewPayrollPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={'accounting'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <DateRangeHandler
      getDefaultStartDate={() => Datetime.today.subtract(6, 'days')}
      getDefaultEndDate={({startDate}) => startDate.clone().add(6, 'days')}
    >
      {({startDate, endDate, setStartDate, setEndDate}) => (
        <SidebarPageV2 selected={'accounting'}>
          <Container>
            <AccountingPageHeader organization={data.viewer.viewingOrganization} />
            <CrewPayrollPageContent
              kind={'CREW'}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Container>
        </SidebarPageV2>
      )}
    </DateRangeHandler>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListCrewPayrollPage.query = gql`
  ${AccountingPageHeader.fragment}

  query ListCrewPayrollPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...AccountingPageHeader
      }
    }
  }
`;

export default ListCrewPayrollPage;
