// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import BillRuleTypeForm, {
  BillRuleTypeFormType,
} from '@shared/modules/Billing/forms/BillRuleTypeForm';

const useUpdateBillRuleTypeMutation = ({
  billRuleTypeForm,
  onSuccess,
  onError,
}: {
  billRuleTypeForm: BillRuleTypeFormType;
  onSuccess: () => void;
  onError: (errors: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      billRuleTypeForm: BillRuleTypeForm.toForm(billRuleTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateBillRuleTypeMutation.mutation,
    variables: {
      billRuleTypeForm: BillRuleTypeForm.toMutation(form.values.billRuleTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateBillRuleTypeMutation.mutation = gql`
  mutation useUpdateBillRuleTypeMutation($billRuleTypeForm: BillRuleTypeForm!) {
    response: updateBillRuleType(billRuleTypeForm: $billRuleTypeForm) {
      ${gql.errors}
      billRuleType {
        id
      }
    }
  }
`;

export default useUpdateBillRuleTypeMutation;
