/*
 * Component - v2.1.0
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const SystemMessageContainer = Styled.View`
  background-color: ${({vars}) => (vars.isWarning ? colors.orange.accent : colors.red.accent)};
  border-radius: 4px;
  margin-horizontal: 12px;
  padding-horizontal: 12px;
  padding-vertical: 8px;
`;

const SystemMessageText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${({vars}) => (vars.isWarning ? colors.orange.status : colors.red.warning)};
`;

const SystemMessage = ({children, isWarning, containerStyle, isResponsive}) => {
  const responsive = useResponsive();

  return (
    <SystemMessageContainer vars={{isWarning}} style={containerStyle}>
      <SystemMessageText vars={{isWarning}} responsive={isResponsive ? responsive : null}>
        {children}
      </SystemMessageText>
    </SystemMessageContainer>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SystemMessage.propTypes = {
  containerStyle: PropTypes.object,
};

SystemMessage.defaultProps = {
  containerStyle: {},
};

SystemMessage.Container = SystemMessageContainer;
SystemMessage.Text = SystemMessageText;

export default SystemMessage;
