// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {List} from '@supermove/utils';

// App
import {PageLoadingIndicator} from 'modules/App/components';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import OrganizationSettingsCalendarsPage from 'modules/Organization/Settings/Moves/OrganizationSettingsCalendarsPage';
import OrganizationSettingsEquipmentAndMaterialPage from 'modules/Organization/Settings/Moves/OrganizationSettingsEquipmentAndMaterialPage';
import OrganizationSettingsLocationTypesPage from 'modules/Organization/Settings/Moves/OrganizationSettingsLocationTypesPage';
import OrganizationSettingsSlotsPage from 'modules/Organization/Settings/Moves/OrganizationSettingsSlotsPage';
import OrganizationSettingsTrucksPage from 'modules/Organization/Settings/Moves/OrganizationSettingsTrucksPage';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const getTabDefinitions = ({organization}) => {
  return [
    {
      label: 'Calendars',
      url: '/settings/moves/calendars',
      component: <OrganizationSettingsCalendarsPage />,
      pageTitle: 'Calendars',
      pageDescription: 'Configure the calendars shown on the “Moves” tab.',
    },
    {
      label: 'Trucks',
      url: '/settings/moves/trucks',
      component: <OrganizationSettingsTrucksPage />,
      pageTitle: 'Trucks',
      pageDescription: `Add new trucks and organize them by 'Active', 'Overflow' or 'Inactive' using the actions menu.`,
    },
    {
      label: 'Slots',
      url: '/settings/moves/slots',
      component: <OrganizationSettingsSlotsPage />,
      pageTitle: 'Slots',
      pageDescription:
        `Slots appear in your Dispatch Calendar. Think of the slot as a numbered parking spot for a truck—a way ` +
        `to stage and allocate trucks that are used daily. You can also associate a driver who uses that truck each ` +
        `day as a way to quickly see resourcing.`,
    },
    {
      label: 'Location Types',
      url: '/settings/moves/location-types',
      component: <OrganizationSettingsLocationTypesPage />,
      pageTitle: 'Location Types',
      pageDescription: `Location Types will appear in the 'Location Type' dropdown when creating a new project. You can add locations or reorder this list by using the drag and drop.`,
    },
    ...List.insertIf(organization.settings.isEquipmentAndMaterialsEnabled, {
      label: 'Equipment & Materials',
      url: '/settings/moves/equipment-and-materials',
      component: <OrganizationSettingsEquipmentAndMaterialPage />,
      pageTitle: 'Equipment & Material',
      pageDescription: '',
    }),
  ];
};

const MoveSettingsPageTabContent = ({tabs, currentTabIndex}) => {
  return tabs[currentTabIndex].component;
};

const MoveSettingsPage = () => {
  const {navigator} = useNavigationDOM();
  const {data} = useQuery(MoveSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (!data) {
    return (
      <SidebarPageV2 selected={'settings'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  const organization = data.viewer.viewingOrganization;
  const tabs = getTabDefinitions({organization});
  const currentTabIndex = _.findIndex(tabs, (tab) => navigator.location.pathname.includes(tab.url));

  return (
    <SettingsPage
      title={tabs[currentTabIndex].pageTitle}
      description={tabs[currentTabIndex].pageDescription}
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {name: 'Moves'},
      ]}
      tabs={tabs}
      hasSubTabs={tabs[currentTabIndex].hasSubTabs}
    >
      <MoveSettingsPageTabContent tabs={tabs} currentTabIndex={currentTabIndex} />
    </SettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MoveSettingsPage.query = gql`
  query MoveSettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        settings {
          id
          isEquipmentAndMaterialsEnabled
        }
      }
    }
  }
`;

export default MoveSettingsPage;
