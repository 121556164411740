// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import ProjectCommentsSidebar from 'modules/Project/components/ProjectCommentsSidebar';

const Text = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.blue.interactive};
`;

const ButtonContainer = Styled.Touchable`
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const IconContainer = Styled.View`
  width: 12px;
  align-items: center;
`;

const JobProjectCommentsButton = ({job, sidebar}) => {
  const responsive = useResponsive();
  return (
    <Button onPress={() => sidebar.handleOpen()}>
      <Text responsive={responsive}>
        {pluralize('Comment', job.project.officeThread.commentsCount, true)}
      </Text>
      <Space width={6} />
      <IconContainer>
        <Icon color={colors.blue.interactive} size={12} source={Icon.ArrowRight} />
      </IconContainer>
    </Button>
  );
};

const JobProjectComments = ({job, sidebar, hideTooltip}) => {
  return (
    <React.Fragment>
      <ButtonContainer>
        {hideTooltip ? (
          <JobProjectCommentsButton job={job} sidebar={sidebar} />
        ) : (
          <TextTooltip text={'Project Comments'}>
            <JobProjectCommentsButton job={job} sidebar={sidebar} />
          </TextTooltip>
        )}
      </ButtonContainer>
      <ProjectCommentsSidebar
        key={sidebar.key}
        isOpen={sidebar.isOpen}
        handleClose={sidebar.handleClose}
        projectUuid={job.project.uuid}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectComments.fragment = gql`
  fragment JobProjectComments on Job {
    id
    project {
      id
      uuid
      officeThread {
        id
        commentsCount
      }
    }
  }
`;

export default JobProjectComments;
