const AVAILABLE = 'AVAILABLE';
const OCCUPIED = 'OCCUPIED';

const ALL = [AVAILABLE, OCCUPIED];

export default {
  ALL,

  AVAILABLE,
  OCCUPIED,
};
