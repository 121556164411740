// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Emoji, Styled, Tooltip} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

const Container = Styled.View`
  cursor: default;
`;

const Text = Styled.H7`
  color: ${colors.white};
  ${fontWeight(300)}
`;

const EmojiText = Styled.H4`
  margin-top: 5px;
`;

const EmojiTooltip = ({emoji, tooltip, style}) => (
  <Tooltip placement={'bottom'} overlay={() => <Text>{tooltip}</Text>}>
    <Container style={style}>
      <Emoji component={EmojiText} name={emoji} />
    </Container>
  </Tooltip>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
EmojiTooltip.propTypes = {
  emoji: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

EmojiTooltip.defaultProps = {};

export default EmojiTooltip;
