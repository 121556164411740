// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Form} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Currency, Percent} from '@supermove/utils';

import CreateEmployeeMutation from './CreateEmployeeMutation';
import EmployeeFormFields from './EmployeeFormFields';

const CreateEmployeeForm = ({organization, onSuccess, onError}) => (
  <Form
    initialValues={{
      organizationId: organization.id,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      payrollIdentifier: '',
      tabletPin: '',
      position: '',
      branchCode: '',
      billingHourlyRate: Currency.toForm(0),
      compensationPercentage: Percent.toForm(0),
      isDriver: false,
      features: {VIEW_CUSTOMER_INFO: false},
      moverPositionIds: [],
      primaryMoverPositionId: null,
    }}
    onSubmit={(values) => {}}
  >
    {({submitForm, setMutationErrors, ...form}) => (
      <CreateEmployeeMutation
        {...form}
        onSuccess={onSuccess}
        submitForm={submitForm}
        setMutationErrors={setMutationErrors}
      >
        {({loading, handleSubmit}) => (
          <EmployeeFormFields
            form={form}
            loading={loading}
            organization={organization}
            handleSubmit={handleSubmit}
          />
        )}
      </CreateEmployeeMutation>
    )}
  </Form>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
CreateEmployeeForm.propTypes = {
  organization: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

CreateEmployeeForm.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateEmployeeForm.fragment = gql`
  ${EmployeeFormFields.fragment}

  fragment CreateEmployeeForm on Organization {
    id
    ...EmployeeFormFields
  }
`;

export default CreateEmployeeForm;
