const _new = ({userId}: any) => ({
  userId,
  viewingOrganizationId: null,
});

const toForm = ({userId, viewingOrganizationId}: any) => ({
  userId,
  viewingOrganizationId,
});

const toMutation = ({userId, viewingOrganizationId}: any) => ({
  userId,
  viewingOrganizationId,
});

const UpdateMultibranchAdminViewingOrganizationForm = {
  new: _new,
  toForm,
  toMutation,
};

export default UpdateMultibranchAdminViewingOrganizationForm;
