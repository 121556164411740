export default {
  MANAGER_JOB_ADDITIONAL: 'MANAGER_JOB_ADDITIONAL',
  MANAGER_JOB_ATTACHMENTS: 'MANAGER_JOB_ATTACHMENTS',
  MANAGER_JOB_BILLING_CLIENT: 'MANAGER_JOB_BILLING_CLIENT',
  MANAGER_JOB_COMMENTS: 'MANAGER_JOB_COMMENTS',
  MANAGER_JOB_COMMERCIAL_EQUIPMENT_AND_MATERIALS: 'MANAGER_JOB_COMMERCIAL_EQUIPMENT_AND_MATERIALS',
  MANAGER_JOB_CUSTOMER: 'MANAGER_JOB_CUSTOMER',
  MANAGER_JOB_INFO: 'MANAGER_JOB_INFO',
  MANAGER_JOB_INTERNAL: 'MANAGER_JOB_INTERNAL',
  MANAGER_JOB_INVENTORY: 'MANAGER_JOB_INVENTORY',
  MANAGER_JOB_LOCATIONS: 'MANAGER_JOB_LOCATIONS',
  MANAGER_JOB_MOVE: 'MANAGER_JOB_MOVE',
  MANAGER_JOB_NOTES: 'MANAGER_JOB_NOTES',
  MANAGER_JOB_PROJECT: 'MANAGER_JOB_PROJECT',
  MANAGER_JOB_PROJECT_DOCUMENTS: 'MANAGER_JOB_PROJECT_DOCUMENTS',
  MANAGER_JOB_STATUS: 'MANAGER_JOB_STATUS',
  MANAGER_JOB_TASKS: 'MANAGER_JOB_TASKS',
  MANAGER_JOB_TIMELINE: 'MANAGER_JOB_TIMELINE',
  MANAGER_JOB_PROJECT_COSTS: 'MANAGER_JOB_PROJECT_COSTS',
  MANAGER_JOB_PROJECT_BILLING: 'MANAGER_JOB_PROJECT_BILLING',
  MANAGER_JOB_PROJECT_CLAIMS: 'MANAGER_JOB_PROJECT_CLAIMS',
  MANAGER_JOB_DISPATCH: 'MANAGER_JOB_DISPATCH',
  MANAGER_JOB_VALUATION_COVERAGE: 'MANAGER_JOB_VALUATION_COVERAGE',
  MANAGER_JOB_REPORT: 'MANAGER_JOB_REPORT',
  MANAGER_JOB_PROJECT_DATES: 'MANAGER_JOB_PROJECT_DATES',
  MANAGER_JOB_PROJECT_ACCOUNTING_BLOCK: 'MANAGER_JOB_PROJECT_ACCOUNTING_BLOCK',
};
