// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectTypeFeatureForm from '@shared/modules/Project/forms/ProjectTypeFeatureForm';

const useUpdateProjectTypeFeatureMutation = ({projectTypeFeatureForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      projectTypeFeatureForm: ProjectTypeFeatureForm.toForm(projectTypeFeatureForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectTypeFeatureMutation.mutation,
    variables: {
      projectTypeFeatureForm: ProjectTypeFeatureForm.toMutation(form.values.projectTypeFeatureForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateProjectTypeFeatureMutation.mutation = gql`
  mutation useUpdateProjectTypeFeatureMutation($projectTypeFeatureForm: ProjectTypeFeatureForm!) {
    response: updateProjectTypeFeature(projectTypeFeatureForm: $projectTypeFeatureForm) {
      ${gql.errors}
      projectType {
        id
      }
    }
  }
`;

export default useUpdateProjectTypeFeatureMutation;
