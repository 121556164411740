// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({organizationId, userId}: any) => ({
  containerTypeId: null,
  organizationId,
  name: '',
  dimensionLength: null,
  dimensionWidth: null,
  dimensionHeight: null,
  weightCapacity: null,
  notes: '',
  createdById: userId,
  updatedById: userId,
});

const edit = withFragment(
  (containerType, {userId}) => ({
    containerTypeId: (containerType as any).id,
    organizationId: (containerType as any).organizationId,
    name: (containerType as any).name,
    dimensionLength: (containerType as any).dimensionLength
      ? (containerType as any).dimensionLength
      : null,
    dimensionWidth: (containerType as any).dimensionWidth
      ? (containerType as any).dimensionWidth
      : null,
    dimensionHeight: (containerType as any).dimensionHeight
      ? (containerType as any).dimensionHeight
      : null,
    weightCapacity: (containerType as any).weightCapacity
      ? (containerType as any).weightCapacity
      : null,
    notes: (containerType as any).notes,
    isDeleted: (containerType as any).isDeleted,
    createdById: (containerType as any).createdById,
    updatedById: userId,
  }),
  gql`
    fragment ContainerTypeForm_edit on ContainerType {
      id
      organizationId
      name
      dimensionLength
      dimensionWidth
      dimensionHeight
      weightCapacity
      notes
      isDeleted
      createdById
      updatedById
    }
  `,
);

const toForm = ({
  containerTypeId,
  organizationId,
  name,
  dimensionLength,
  dimensionWidth,
  dimensionHeight,
  weightCapacity,
  notes,
  isDeleted,
  createdById,
  updatedById,
}: any) => ({
  containerTypeId,
  organizationId,
  name,
  dimensionLength,
  dimensionWidth,
  dimensionHeight,
  weightCapacity,
  notes,
  isDeleted,
  createdById,
  updatedById,
});

const toMutation = ({
  containerTypeId,
  organizationId,
  name,
  dimensionLength,
  dimensionWidth,
  dimensionHeight,
  weightCapacity,
  notes,
  isDeleted,
  createdById,
  updatedById,
}: any) => {
  return {
    containerTypeId,
    organizationId,
    name,
    dimensionLength: Number(dimensionLength),
    dimensionWidth: Number(dimensionWidth),
    dimensionHeight: Number(dimensionHeight),
    weightCapacity: Number(weightCapacity),
    notes,
    isDeleted,
    createdById,
    updatedById,
  };
};

const ContainerTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ContainerTypeForm;
