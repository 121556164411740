// Libraries
import _ from 'lodash';

const SALESPERSON = 'SALESPERSON';
const COORDINATOR = 'COORDINATOR';

const KIND_OPTIONS = {
  COORDINATOR: {
    value: COORDINATOR,
    label: 'Coordinator',
  },
  SALESPERSON: {
    value: SALESPERSON,
    label: 'Salesperson',
  },
};

const getKindOption = (kind: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return KIND_OPTIONS[kind];
};

export default {
  COORDINATOR,
  SALESPERSON,

  DROPDOWN_OPTIONS: _.values(KIND_OPTIONS),
  getKindOption,
};
