// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({organizationId}: any) => ({
  organizationId,
  claimStatusId: null,
  name: '',
  color: '',
  rank: -1,
  isDefault: false,
});

const edit = withFragment(
  (claimStatus) => ({
    claimStatusId: (claimStatus as any).id,
    organizationId: (claimStatus as any).organizationId,
    name: (claimStatus as any).name,
    color: (claimStatus as any).color,
    rank: (claimStatus as any).rank,
    isDefault: (claimStatus as any).isDefault,
  }),
  gql`
    fragment ClaimStatusForm_edit on ClaimStatus {
      id
      organizationId
      name
      color
      rank
      isDefault
    }
  `,
);

const toForm = ({claimStatusId, organizationId, name, color, rank, isDefault}: any) => ({
  claimStatusId,
  organizationId,
  name,
  color,
  rank,
  isDefault,
});

const toMutation = ({claimStatusId, organizationId, name, color, rank, isDefault}: any) => ({
  claimStatusId,
  organizationId,
  name,
  color,
  rank,
  isDefault,
});

const ClaimStatusForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ClaimStatusForm;
