/*
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, usePopover} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import BillTipForm from '@shared/modules/Billing/forms/BillTipForm';
import useDeleteBillTipMutation from '@shared/modules/Billing/hooks/useDeleteBillTipMutation';
import DeleteModal from 'modules/App/components/DeleteModal';
import AddBillTipModal from 'modules/Project/Billing/BillTip/components/AddBillTipModal';
import EditBillTipModal from 'modules/Project/Billing/BillTip/components/EditBillTipModal';

const PopoverContainer = Styled.View`
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
  overflow: visible;
  width: 180px;
`;

const MenuItemTouchable = Styled.Touchable`
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
`;

const MenuItemText = Styled.H7`
  padding-vertical: 4px;
  padding-horizontal: 12px;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const IconContainerTouchable = Styled.Touchable`
  width: 30px;
  height: 27px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray.background};
  border-radius: 4px;
`;

const ActionMenuItem = ({onPress, children}) => {
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      <MenuItemTouchable onPress={onPress} isHovered={isHovered} ref={ref} activeOpacity={0.8}>
        <MenuItemText>{children}</MenuItemText>
      </MenuItemTouchable>
      <Space height={2} />
    </React.Fragment>
  );
};

const BillTipActionsPopover = ({
  billTipActionsPopover,
  editBillTipModal,
  deleteBillTipModal,
  addBillTipModal,
  billTip,
}) => {
  const handleOpeneditBillTipModal = () => {
    editBillTipModal.handleOpen();
    billTipActionsPopover.handleClose();
  };
  const handleOpenDeleteBillTipModal = () => {
    deleteBillTipModal.handleOpen();
    billTipActionsPopover.handleClose();
  };
  const handleOpenAddBillTipModal = () => {
    addBillTipModal.handleOpen();
    billTipActionsPopover.handleClose();
  };
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={billTipActionsPopover.isOpen}
      handleOpen={billTipActionsPopover.handleOpen}
      handleClose={billTipActionsPopover.handleClose}
      reference={billTipActionsPopover.ref}
      offset={[0, 4]}
    >
      <PopoverContainer>
        <Space height={8} />
        {!!billTip && (
          <React.Fragment>
            <ActionMenuItem onPress={handleOpeneditBillTipModal}>Edit Tip</ActionMenuItem>
            <Space height={4} />
            <ActionMenuItem onPress={handleOpenDeleteBillTipModal}>Delete Tip</ActionMenuItem>
            <Space height={4} />
          </React.Fragment>
        )}
        <ActionMenuItem onPress={handleOpenAddBillTipModal}>Add Tip</ActionMenuItem>
        <Space height={8} />
      </PopoverContainer>
    </Popover>
  );
};

const DeleteBillTipModal = ({billTip, deleteBillTipModal, refetch}) => {
  const billTipForm = BillTipForm.edit(billTip);
  const {handleSubmit} = useDeleteBillTipMutation({
    billTipForm,
    onSuccess: () => {
      deleteBillTipModal.handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={deleteBillTipModal.isOpen}
      title={'Are you sure you want to delete this tip?'}
      subtitle={"You can't undo this action."}
      handleClose={deleteBillTipModal.handleClose}
      handleConfirm={handleSubmit}
    />
  );
};

const BillTipActionsButton = ({billTip, billId, refetch, project}) => {
  const billTipActionsPopover = usePopover();
  const editBillTipModal = useModal();
  const deleteBillTipModal = useModal();
  const addBillTipModal = useModal();

  return (
    <React.Fragment>
      <Popover.Content innerRef={billTipActionsPopover.ref}>
        <IconContainerTouchable activeOpacity={0.8} onPress={billTipActionsPopover.handleToggle}>
          <Icon source={Icon.EllipsisV} color={colors.blue.interactive} size={14} />
        </IconContainerTouchable>
      </Popover.Content>
      <BillTipActionsPopover
        key={`BILL_TIP_ACTIONS_POPOVER-${billTipActionsPopover.isOpen}`}
        billTipActionsPopover={billTipActionsPopover}
        editBillTipModal={editBillTipModal}
        deleteBillTipModal={deleteBillTipModal}
        addBillTipModal={addBillTipModal}
        billTip={billTip}
      />
      {!!billTip && (
        <React.Fragment>
          <EditBillTipModal
            key={`EDIT_BILL_TIP_MODAL-${editBillTipModal.isOpen}`}
            billTip={billTip}
            projectUuid={project.uuid}
            isOpen={editBillTipModal.isOpen}
            handleClose={editBillTipModal.handleClose}
            refetch={refetch}
          />
          <DeleteBillTipModal
            key={`DELETE_BILL_TIP_MODAL-${deleteBillTipModal.isOpen}`}
            billTip={billTip}
            deleteBillTipModal={deleteBillTipModal}
            refetch={refetch}
          />
        </React.Fragment>
      )}
      <AddBillTipModal
        key={`ADD_BILL_TIP_MODAL-${addBillTipModal.isOpen}`}
        billTip={billTip}
        isOpen={addBillTipModal.isOpen}
        handleClose={addBillTipModal.handleClose}
        refetch={refetch}
        projectUuid={project.uuid}
        billId={billId || billTip.billId}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillTipActionsButton.fragment = gql`
  ${BillTipForm.edit.fragment}
  ${EditBillTipModal.fragment}

  fragment BillTipActionsButton on BillTip {
    id
    billId
    ...BillTipForm_edit
    ...EditBillTipModal
  }

  fragment BillTipActionsButton_Project on Project {
    id
    uuid
  }
`;

export default BillTipActionsButton;
