// Libraries
import React from 'react';

// Supermove
import {ExternalLink, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography, colors} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import EmptyState from '@shared/design/components/EmptyState';

const PageContainer = Styled.View`
  padding: 32px;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const PhoneLink = ({organization}) => {
  return (
    <ExternalLink url={Phone.createUrl(organization.phoneNumber)}>
      <LinkText>{Phone.display(organization.phoneNumber)}</LinkText>
    </ExternalLink>
  );
};

const InvoiceCancelledPage = ({invoice}) => {
  const {organization} = invoice;
  const businessName =
    organization.businessName !== '' ? organization.businessName : 'your moving company';
  return (
    <PageContainer>
      <EmptyState
        icon={Icon.ExclamationTriangle}
        iconColor={colors.orange.status}
        title={`This invoice was cancelled.`}
        MessageComponent={() => (
          <EmptyState.DescriptionText style={{textAlign: 'center'}}>
            {`Please contact ${businessName} `}
            {organization.phoneNumber !== '' && (
              <React.Fragment>
                {`at `}
                <PhoneLink organization={organization} />
                {` `}
              </React.Fragment>
            )}
            {`for further assistance.`}
          </EmptyState.DescriptionText>
        )}
      />
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceCancelledPage.fragment = gql`
  fragment InvoiceCancelledPage on Invoice {
    id
    organization {
      id
      businessName
      phoneNumber
    }
  }
`;

export default InvoiceCancelledPage;
