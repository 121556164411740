// Libraries
import _ from 'lodash';
import React from 'react';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';

const LINE_HEIGHT = 20;

const getIsAtNumberOfLines = ({layout, numberOfLines}) => {
  return layout.height >= numberOfLines * LINE_HEIGHT;
};

const FieldValueExpandable = ({
  data,
  size,
  property,
  label,
  numberOfLines,
  style,
  valueStyle,
  empty,
  isTruncated,
  isEnabledToggle,
  setTruncated,
  setEnabledToggle,
  truncatedKey,
  isResponsive,
}) => {
  return (
    <FieldValue
      style={{flex: 1, ...style}}
      valueStyle={valueStyle}
      value={_.get(data, property)}
      label={label}
      empty={empty || 'None'}
      numberOfLines={isTruncated ? numberOfLines : null}
      size={size}
      isResponsive={isResponsive}
      onPressValue={
        isEnabledToggle
          ? () => setTruncated((values) => ({...values, [truncatedKey ?? property]: !isTruncated}))
          : null
      }
      onLayoutValue={({nativeEvent: {layout}}) =>
        setEnabledToggle((values) => ({
          ...values,
          [truncatedKey ?? property]: getIsAtNumberOfLines({layout, numberOfLines}),
        }))
      }
    />
  );
};

export default FieldValueExpandable;
