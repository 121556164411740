// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import ReorderBillForm from '@shared/modules/Billing/forms/ReorderBillForm';

const edit = withFragment(
  (bills) => ({
    reorderBillForms: (bills as any).map((bill: any) => {
      return ReorderBillForm.edit(bill);
    }),
    // DEPRECATED
    billForms: (bills as any).map((bill: any) => {
      return ReorderBillForm.edit(bill);
    }),
  }),
  gql`
    ${ReorderBillForm.edit.fragment}

    fragment ReorderBillsForm_edit on Bill {
      id
      ...ReorderBillForm_edit
    }
  `,
);

const toForm = ({billForms, reorderBillForms}: any) => ({
  reorderBillForms: !_.isEmpty(reorderBillForms)
    ? reorderBillForms.map((reorderBillForm: any) => {
        return ReorderBillForm.toForm(reorderBillForm);
      })
    : [],

  // DEPRECATED
  billForms: !_.isEmpty(billForms)
    ? billForms.map((billForm: any) => {
        return ReorderBillForm.toForm(billForm);
      })
    : [],
});

const toMutation = ({billForms, reorderBillForms}: any) => ({
  reorderBillForms: !_.isEmpty(reorderBillForms)
    ? reorderBillForms.map((reorderBillForm: any) => {
        return ReorderBillForm.toMutation(reorderBillForm);
      })
    : [],

  // DEPRECATED
  billForms: !_.isEmpty(billForms)
    ? billForms.map((billForm: any) => {
        return ReorderBillForm.toMutation(billForm);
      })
    : [],
});

const ReorderBillsForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderBillsForm;
