import _ from 'lodash';

const IN_PROGRESS = 'IN_PROGRESS';
const PAID_OUT = 'PAID_OUT';
const NOT_PAID_OUT = 'NOT_PAID_OUT';

const getDisplayStatus = (status: any) => {
  return _.startCase(_.toLower(status));
};

export default {
  IN_PROGRESS,
  PAID_OUT,
  NOT_PAID_OUT,

  VALUES: [IN_PROGRESS, NOT_PAID_OUT, PAID_OUT],

  getDisplayStatus,
};
