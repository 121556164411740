// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Pill from 'components/Pill';
import SearchCrewsList from 'modules/Dispatch/Calendar/Day/RightPanel/components/SearchCrewsList';

const BorderedContainer = Styled.View`
  flex: 1;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${colors.gray.border};
`;

const PillContainer = Styled.View`
  flex-direction: row;
`;

const PillTouchable = Styled.Touchable`
`;

const Indicator = Styled.Loading`
  padding: 20px;
`;

const OrganizationPill = ({
  dispatchJobsSummary,
  count,
  selectedOrganizationId,
  setSelectedOrganizationId,
}) => {
  const isSelected = selectedOrganizationId === dispatchJobsSummary.organization.id;

  return (
    <React.Fragment>
      <PillTouchable onPress={() => setSelectedOrganizationId(dispatchJobsSummary.organization.id)}>
        <Pill
          color={isSelected ? colors.gray.primary : colors.gray.border}
          backgroundColor={isSelected ? colors.blue.accent : null}
          count={count}
          text={dispatchJobsSummary.organization.name}
          reversed
        />
      </PillTouchable>
      <Space width={4} />
    </React.Fragment>
  );
};

const AllOrganizationPills = ({
  dispatchJobsSummaries,
  selectedOrganizationId,
  setSelectedOrganizationId,
}) => {
  const sortedDispatchJobsSummaries = _.sortBy(dispatchJobsSummaries, ['organization.name']);
  return (
    <PillContainer>
      <ScrollView horizontal contentContainerStyle={{padding: 8}}>
        {sortedDispatchJobsSummaries.map((dispatchJobsSummary) => {
          return (
            <OrganizationPill
              key={dispatchJobsSummary.organization.id}
              count={dispatchJobsSummary.crewCounts}
              selectedOrganizationId={selectedOrganizationId}
              setSelectedOrganizationId={setSelectedOrganizationId}
              dispatchJobsSummary={dispatchJobsSummary}
            />
          );
        })}
      </ScrollView>
    </PillContainer>
  );
};

const AllOrganizationsCrewsList = ({
  viewerDayId,
  dayIds,
  organizationId,
  refetchCalendar,
  canAssignOrganization,
}) => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(organizationId);
  const {loading, data, refetch} = useQuery(AllOrganizationsCrewsList.query, {
    fetchPolicy: 'network-only',
    variables: {viewerDayId, dayIds, organizationId: selectedOrganizationId},
  });

  if (loading) {
    return <Indicator />;
  }

  const refetchBoth = () => {
    refetchCalendar();
    refetch();
  };

  return (
    <BorderedContainer>
      <AllOrganizationPills
        selectedOrganizationId={selectedOrganizationId}
        setSelectedOrganizationId={setSelectedOrganizationId}
        dispatchJobsSummaries={data.dispatchCalendarDay.dispatchJobsSummaries}
      />
      <SearchCrewsList
        crews={data.dispatchCalendarDay.unassignedCrews}
        refetchCalendar={refetchBoth}
        canAssignOrganization={canAssignOrganization}
      />
    </BorderedContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AllOrganizationsCrewsList.query = gql`
  ${SearchCrewsList.fragment}

  query AllOrganizationsCrewsList(
    $viewerDayId: Int!,
    $dayIds: [Int]!,
    $organizationId: Int!,
  ) {
    ${gql.query}
    dispatchCalendarDay(viewerDayId: $viewerDayId, dayIds: $dayIds) {
      unassignedCrews(organizationId: $organizationId) {
        id
        ...SearchCrewsList
      }
      dispatchJobsSummaries {
        organization {
          id
          name
        }
        crewCounts
      }
    }
  }
`;

export default AllOrganizationsCrewsList;
