// Library
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Tabs from '@shared/design/components/Tabs';
import ProjectContainerStatusKind from '@shared/modules/Storage/enums/ProjectContainerStatusKind';
import AttachmentsPanel from 'modules/Project/Attachments/components/AttachmentsPanel';
import CollectionsPanel from 'modules/Storage/Collections/CollectionsPanel';
import ContainersPanel from 'modules/Storage/Containers/ContainersPanel';
import InvoicesPanel from 'modules/Storage/components/InvoicesPanel';
import StorageProjectEventLogList from 'modules/Storage/components/StorageProjectEventLogList';
import StorageProjectPageInformationBlockContent from 'modules/Storage/components/StorageProjectPageInformationBlockContent';

const StorageProjectPageInformationBlockContainer = Styled.View`
  width: 288px;
`;

const Container = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const StorageProjectPageRightContainer = Styled.View`
  flex: 1;
  padding: 24px;
  padding-left: 0px;
`;

const PanelContainer = Styled.View`
  padding: 16px;
  background-color: ${colors.white};
  border-radius: 4px;
  border: 1px solid ${colors.gray.border};
`;

const getTopPanelTabs = (counts) => {
  return [
    {key: 'activeContainers', label: 'Active Containers', count: counts.activeCount},
    {key: 'upcomingContainers', label: 'Incoming Containers', count: counts.upcomingCount},
    {key: 'inactiveContainers', label: 'Past Containers', count: counts.inactiveCount},
  ];
};

const StorageProjectTopPanels = ({project, refetch}) => {
  const topPanelTabs = getTopPanelTabs(project.containerCountsForProjectByStatusKind);
  const [selectedTab, setSelectedTab] = useState(topPanelTabs[0].key);

  return (
    <React.Fragment>
      <Tabs
        tabs={topPanelTabs}
        handlePressTab={({key}) => setSelectedTab(key)}
        activeTabIndex={_.findIndex(topPanelTabs, ({key}) => key === selectedTab)}
      />
      <PanelContainer>
        {selectedTab === 'activeContainers' && (
          <ContainersPanel
            project={project}
            projectContainerStatusKind={ProjectContainerStatusKind.ACTIVE}
            refetch={refetch}
          />
        )}
        {selectedTab === 'upcomingContainers' && (
          <ContainersPanel
            project={project}
            projectContainerStatusKind={ProjectContainerStatusKind.UPCOMING}
            refetch={refetch}
          />
        )}
        {selectedTab === 'inactiveContainers' && (
          <ContainersPanel
            project={project}
            projectContainerStatusKind={ProjectContainerStatusKind.INACTIVE}
            refetch={refetch}
          />
        )}
      </PanelContainer>
    </React.Fragment>
  );
};

const getBottomPanelTabs = () => {
  return [
    {key: 'invoices', label: 'Invoices'},
    {key: 'attachments', label: 'Attachments'},
    {key: 'events', label: 'Event Log'},
  ];
};

const StorageProjectBottomPanels = ({project}) => {
  const bottomPanelTabs = getBottomPanelTabs();
  const [selectedTab, setSelectedTab] = useState(bottomPanelTabs[0].key);
  return (
    <React.Fragment>
      <Tabs
        tabs={bottomPanelTabs}
        handlePressTab={({key}) => setSelectedTab(key)}
        activeTabIndex={_.findIndex(bottomPanelTabs, ({key}) => key === selectedTab)}
      />
      <PanelContainer>
        {selectedTab === 'invoices' && <InvoicesPanel />}
        {selectedTab === 'attachments' && <AttachmentsPanel project={project} />}
        {selectedTab === 'events' && <StorageProjectEventLogList />}
      </PanelContainer>
    </React.Fragment>
  );
};

const StorageProjectPageContent = ({project, refetch}) => {
  return (
    <Container>
      <StorageProjectPageInformationBlockContainer>
        <StorageProjectPageInformationBlockContent project={project} refetch={refetch} />
      </StorageProjectPageInformationBlockContainer>
      <StorageProjectPageRightContainer>
        <ScrollView>
          <StorageProjectTopPanels project={project} refetch={refetch} />
          <Space height={24} />
          <StorageProjectBottomPanels project={project} />
        </ScrollView>
      </StorageProjectPageRightContainer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageProjectPageContent.fragment = gql`
  ${AttachmentsPanel.fragment}
  ${StorageProjectPageInformationBlockContent.fragment}
  ${CollectionsPanel.fragment}
  ${ContainersPanel.fragment}

  fragment StorageProjectPageContent on Project {
    id
    uuid
    containerCountsForProjectByStatusKind {
      activeCount
      inactiveCount
      upcomingCount
    }
    ...AttachmentsPanel
    ...StorageProjectPageInformationBlockContent
    ...CollectionsPanel
    ...ContainersPanel
  }
`;

export default StorageProjectPageContent;
