// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  DropdownInput,
  Icon,
  Loading,
  Modal,
  ScrollView,
  Space,
  Styled,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import FileViewer from '@shared/modules/File/components/FileViewer';
import CollectionForm from '@shared/modules/Inventory/forms/CollectionForm';
import useUpdateCollectionMutationWithCollectionFragment from '@shared/modules/Inventory/hooks/useUpdateCollectionMutationWithCollectionFragment';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CollectionItemsList from 'modules/Collection/components/CollectionItemsList';
import CreateCustomItemModal from 'modules/Item/components/CreateCustomItemModal';
import CollectionFormHeader from 'modules/Job/Inventory/components/CollectionFormHeader';
import SaveCollectionChangesModal from 'modules/Job/Inventory/components/SaveCollectionChangesModal';
import CreateRoomModal from 'modules/Room/components/CreateRoomModal';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const Content = Styled.View`
  align-self: center;
  max-width: 1120px;
  width: 100%;
  padding-horizontal: 10px;
`;

const Header = Styled.View`
  height: 64px;
  background-color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Info = Styled.View`
  flex: 1;
`;

const Title = Styled.Text`
  ${Typography.Heading2}
  text-align: center;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const BackButton = Styled.ButtonV2`
`;

const getSaveButtonText = ({hasChanges, hasSavedChanges}) => {
  if (hasChanges) {
    return 'Save changes';
  }
  if (hasSavedChanges) {
    return 'Saved';
  }
  return 'No changes found';
};

const EditInventoryHeader = ({
  inventory,
  form,
  hasChanges,
  handleSubmit,
  isSubmitting,
  selectedRoomName,
}) => {
  const {navigator} = useNavigationDOM();
  const saveCollectionChangesModal = useModal({name: 'Save Collection Changes Modal'});
  const handleNavigation = () => {
    navigator.replace(`/projects/${inventory.project.uuid}`);
  };

  const hasSavedChanges = _.get(form.values, 'collectionForm.hasSavedChanges');

  return (
    <Header>
      <Content style={{flex: 1}}>
        <Row style={{flex: 1, alignItems: 'center'}}>
          <Space width={200}>
            <BackButton
              onPress={() => {
                if (hasChanges) {
                  return saveCollectionChangesModal.handleOpen();
                }
                handleNavigation();
              }}
            >
              <Icon color={colors.gray.primary} size={16} source={Icon.ArrowLeft} />
            </BackButton>
          </Space>
          <Info>
            <Title>Virtual Walkthrough</Title>
          </Info>
          <Space width={200} style={{alignItems: 'flex-end'}}>
            <Button
              isSubmitting={isSubmitting}
              isDisabled={isSubmitting || !hasChanges}
              onPress={() => {
                form.setFieldValue('collectionForm.isSubmitting', true);
                handleSubmit();
              }}
              iconRight={!hasChanges && hasSavedChanges && Icon.Check}
              text={getSaveButtonText({hasChanges, hasSavedChanges})}
              style={{width: '160px'}}
            />
          </Space>
        </Row>
      </Content>
      <Line />
      <SaveCollectionChangesModal
        key={saveCollectionChangesModal.key}
        isOpen={saveCollectionChangesModal.isOpen}
        handleClose={saveCollectionChangesModal.handleClose}
        handleSubmit={handleSubmit}
        submitting={isSubmitting}
        onSuccess={handleNavigation}
        roomName={selectedRoomName}
      />
    </Header>
  );
};

const Sidebar = Styled.View`
  width: 180px;
`;

const RoomTabButton = Styled.ButtonV2`
  justify-content: center;
  height: 40px;
  width: 180px;
  padding-horizontal: 20px;
  background-color: ${(props) => (props.isSelected ? colors.blue.accent : colors.white)};
  border-radius: 8px;
  border: 1px solid ${colors.gray.border};
`;

const Text = Styled.Text`
  ${Typography.Label}
  color: ${colors.gray.primary};
`;

const RoomTab = ({inventory, room, selectedRoom, hasChanges, handleSubmit, isSubmitting}) => {
  const {navigator} = useNavigationDOM();
  const saveCollectionChangesModal = useModal({name: 'Save Collection Changes Modal'});
  const handleNavigation = () => {
    navigator.replace(`/inventories/${inventory.uuid}/rooms/${room.uuid}/edit`);
  };

  return (
    <React.Fragment>
      <RoomTabButton
        isSelected={room.id === selectedRoom.id}
        onPress={() => {
          if (hasChanges) {
            return saveCollectionChangesModal.handleOpen();
          }
          handleNavigation();
        }}
      >
        <Text>{room.name}</Text>
      </RoomTabButton>
      <SaveCollectionChangesModal
        key={saveCollectionChangesModal.key}
        isOpen={saveCollectionChangesModal.isOpen}
        handleClose={saveCollectionChangesModal.handleClose}
        handleSubmit={handleSubmit}
        submitting={isSubmitting}
        onSuccess={() => {
          handleNavigation();
        }}
        roomName={selectedRoom ? selectedRoom.name : ''}
      />
    </React.Fragment>
  );
};

const EditRoomSidebar = ({
  inventory,
  room: selectedRoom,
  hasChanges,
  handleSubmit,
  isSubmitting,
}) => {
  const {navigator} = useNavigationDOM();
  const createRoomModal = useModal({name: 'Create Room Modal'});
  const saveCollectionChangesModal = useModal({name: 'Save Collection Changes Modal'});

  return (
    <Sidebar>
      <Space height={16} />
      <RoomTabButton
        isSelected={false}
        onPress={() => {
          if (hasChanges) {
            return saveCollectionChangesModal.handleOpen();
          }
          createRoomModal.handleOpen();
        }}
        style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}
      >
        <Icon color={colors.gray.primary} size={12} source={Icon.Plus} />
        <Space width={8} />
        <Text>Add New Room</Text>
      </RoomTabButton>
      <Space height={10} />
      {inventory.rooms.map((room) => (
        <React.Fragment key={room.id}>
          <RoomTab
            inventory={inventory}
            room={room}
            selectedRoom={selectedRoom}
            hasChanges={hasChanges}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
          <Space height={10} />
        </React.Fragment>
      ))}
      <CreateRoomModal
        key={createRoomModal.key}
        isOpen={createRoomModal.isOpen}
        inventory={inventory}
        handleClose={createRoomModal.handleClose}
        onSuccess={({room}) => {
          navigator.push(`/inventories/${inventory.uuid}/rooms/${room.uuid}/edit`);
        }}
      />
      <SaveCollectionChangesModal
        key={saveCollectionChangesModal.key}
        isOpen={saveCollectionChangesModal.isOpen}
        handleClose={saveCollectionChangesModal.handleClose}
        handleSubmit={handleSubmit}
        submitting={isSubmitting}
        onSuccess={() => {
          saveCollectionChangesModal.handleClose();
          createRoomModal.handleOpen();
        }}
        roomName={selectedRoom.name}
      />
    </Sidebar>
  );
};

const SectionContainer = Styled.View`
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
`;

const SectionHeader = Styled.View`
  flex-direction: row;
  align-items: center;
  padding: 16px;
`;

const SectionTitle = Styled.Text`
  ${Typography.Label}
  flex: 1;
`;

const Section = ({title, action, children}) => {
  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle>{title}</SectionTitle>
        {action}
      </SectionHeader>
      <Line />
      {children}
    </SectionContainer>
  );
};

const SectionContent = Styled.View`
  padding-vertical: 12px;
  padding-horizontal: 16px;
`;

const Touchable = Styled.ButtonV2`
`;

const RoomContent = Styled.View`
  flex: 1;
`;

const Side = Styled.View`
  flex: 1;
`;

const getInitialSelectedCategoryId = ({room}) => {
  const isCollectionEmpty = room.collection.takeCount === 0 && room.collection.leaveCount === 0;
  const primaryCategoryId = _.get(room, 'roomType.primaryCategory.id');
  return isCollectionEmpty && primaryCategoryId ? primaryCategoryId : 'ALL';
};

const getCategoryOptions = ({primaryCategoryId, inventory}) => {
  const {categories} = inventory.project.projectType.defaultInventoryLibrary;
  const filteredCategories = categories.filter((category) => {
    return category.isShared || primaryCategoryId === category.id;
  });

  const orderedCategories = _.orderBy(
    filteredCategories,
    [(category) => category.id === primaryCategoryId, (category) => category.name],
    [
      // Show the primary category first.
      'desc',

      // Then show the rest of the categories ordered by name in alphabetical order.
      'asc',
    ],
  );

  const categoryOptions = orderedCategories.map((category) => ({
    label: category.name,
    value: category.id,
  }));

  return [{label: 'All', value: 'ALL'}, ...categoryOptions];
};

const LinkText = Styled.Text`
  ${Typography.Link}
  text-decoration-line: none;
`;
const AddCustomItemLink = ({collection, inventory, form}) => {
  const createCustomItemModal = useModal({name: 'Create Custom Item Modal'});

  return (
    <React.Fragment>
      <TertiaryButton iconLeft={Icon.Plus} onPress={createCustomItemModal.handleOpen}>
        <LinkText>Add Custom Item</LinkText>
      </TertiaryButton>
      <CreateCustomItemModal
        key={createCustomItemModal.key} // This ensures a new ItemForm is created when the modal is opened.
        isOpen={createCustomItemModal.isOpen}
        collectionId={collection.id}
        form={form}
        inventory={inventory}
        handleClose={createCustomItemModal.handleClose}
      />
    </React.Fragment>
  );
};

const VideoContainer = Styled.View`
  height: 720px;
  background-color: ${colors.black};
`;

const Empty = Styled.View`
  height: 340px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray.background};
`;

const EmptyText = Styled.Text`
  ${Typography.Body}
`;

const PhotoRow = Styled.View`
  flex-direction: row;
`;

const Photo = Styled.ButtonV2`
  flex: 1;
  height: 160px;
`;

const RoomCustomerNotes = Styled.Text`
  ${Typography.Body}
`;

const Overlay = Styled.View`
  flex: 1;
  background-color: ${colors.alpha(colors.black, 0.7)};
`;

const Wrapper = Styled.View`
  flex: 1;
`;

const ModalHeader = Styled.View`
  height: 60px;
  padding-horizontal: 12px;
  align-items: flex-end;
  justify-content: center;
`;

const Image = Styled.Image`
  height: inherit;
  width: inherit;
`;

const AttachmentModal = ({isOpen, attachment, handleClose}) => {
  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Overlay pointerEvents={'box-none'}>
        <ModalHeader>
          <Touchable onPress={handleClose}>
            <Icon color={colors.white} size={20} source={Icon.Times} />
          </Touchable>
        </ModalHeader>
        <Wrapper>
          <Image
            resizeMode={'contain'}
            source={{uri: attachment.file.downloadUrl}}
            accessibilityLabel={attachment.file.name}
            style={{flex: 1}}
          />
        </Wrapper>
      </Overlay>
    </Modal.Content>
  );
};

const PhotoAttachment = ({attachment}) => {
  const attachmentModal = useModal({name: 'Attachment Modal'});

  return (
    <React.Fragment>
      <Photo onPress={attachmentModal.handleOpen}>
        <Image
          resizeMode={'cover'}
          source={{uri: attachment.file.downloadUrl}}
          accessibilityLabel={attachment.file.name}
        />
      </Photo>
      <AttachmentModal
        key={attachmentModal.key}
        isOpen={attachmentModal.isOpen}
        attachment={attachment}
        handleClose={attachmentModal.handleClose}
      />
    </React.Fragment>
  );
};

const EditRoomInventoryPageContent = ({room, inventory, refetch}) => {
  const primaryCategoryId = _.get(room, 'roomType.primaryCategory.id');
  const collectionForm = CollectionForm.edit(room.collection, {
    selectedCategoryId: getInitialSelectedCategoryId({room}),
  });

  const {form, submitting, handleSubmit} = useUpdateCollectionMutationWithCollectionFragment({
    collectionForm,
    collectionFragment: gql`
      ${EditRoomInventoryPage.collectionFragment}
      fragment collectionFragment on Collection {
        id
        ...EditRoomInventoryPage_collectionFragment
      }
    `,
    onSuccess: () => {
      form.setFieldValue('collectionForm.hasChanges', false);
      form.setFieldValue('collectionForm.hasSavedChanges', true);
      form.setFieldValue('collectionForm.isSubmitting', false);
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  const hasChanges = _.get(form.values, 'collectionForm.hasChanges');
  const isSubmitting = submitting || _.get(form.values, 'collectionForm.isSubmitting');

  return (
    <React.Fragment>
      <EditInventoryHeader
        inventory={inventory}
        form={form}
        hasChanges={hasChanges}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        selectedRoomName={room ? room.name : ''}
      />
      <ScrollView style={{flex: 1}}>
        <Content>
          <Row>
            <EditRoomSidebar
              inventory={inventory}
              room={room}
              hasChanges={hasChanges}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
            <Space width={20} />
            <RoomContent>
              <Space height={16} />
              <Row style={{flex: 1}}>
                <Side>
                  <Section title={room.name}>
                    <SectionContent>
                      {room.latestVideoAttachment ? (
                        <VideoContainer>
                          <FileViewer
                            height={720}
                            file={room.latestVideoAttachment.file}
                            width={'100%'}
                          />
                        </VideoContainer>
                      ) : (
                        <Empty>
                          <EmptyText>No recording available</EmptyText>
                        </Empty>
                      )}
                    </SectionContent>
                  </Section>
                  <Space height={20} />
                  <Section title={`Closet Photos (${room.filteredAttachments.length})`}>
                    <SectionContent>
                      {_.chunk(room.filteredAttachments, 2).map((row, index) => (
                        <PhotoRow key={index}>
                          {row.map((attachment) => (
                            <PhotoAttachment key={attachment.id} attachment={attachment} />
                          ))}
                        </PhotoRow>
                      ))}
                    </SectionContent>
                  </Section>
                  <Space height={20} />
                  <Section title={'Notes from Customer'}>
                    <SectionContent>
                      <RoomCustomerNotes>{room.customerNotes}</RoomCustomerNotes>
                    </SectionContent>
                  </Section>
                  <Space height={20} />
                </Side>
                <Space width={10} />
                <Side>
                  <Section title={'Surveyed Items Per Room'}>
                    <SectionContent>
                      <CollectionFormHeader collectionForm={form.values.collectionForm} />
                      <Space height={10} />
                      <ScrollView style={{height: 300}}>
                        <CollectionItemsList showSurveyedOnly form={form} inventory={inventory} />
                      </ScrollView>
                    </SectionContent>
                  </Section>
                  <Space height={20} />
                  <Section
                    title={'Add From Inventory'}
                    action={
                      <AddCustomItemLink
                        collection={room.collection}
                        inventory={inventory}
                        organization={inventory.project.organization}
                        form={form}
                      />
                    }
                  >
                    <SectionContent>
                      <Row
                        style={{
                          zIndex: 100,
                          alignItems: 'center',
                        }}
                      >
                        <FieldInput
                          {...form}
                          name={'collectionForm.searchQuery'}
                          input={{
                            placeholder: 'Search by name',
                            onChangeText: (searchQuery) => {
                              if (form.values.collectionForm.selectedCategoryId !== 'ALL') {
                                form.setFieldValue('collectionForm.selectedCategoryId', 'ALL');
                              }
                            },
                          }}
                          style={{
                            flex: 1,
                          }}
                        />
                        <Space width={8} />
                        <Text>or</Text>
                        <Space width={8} />
                        <FieldInput
                          {...form}
                          component={DropdownInput}
                          name={'collectionForm.selectedCategoryId'}
                          input={{
                            disabled: !!form.values.collectionForm.searchQuery,
                            options: getCategoryOptions({primaryCategoryId, inventory}),
                            placeholder: 'Select a category',
                            setFieldValue: form.setFieldValue,
                            style: {
                              flex: 1,
                            },
                          }}
                          style={{
                            flex: 1,
                          }}
                        />
                      </Row>
                      <Space height={20} />
                      <ScrollView style={{height: 480}}>
                        <CollectionItemsList form={form} inventory={inventory} />
                      </ScrollView>
                    </SectionContent>
                  </Section>
                  <Space height={20} />
                </Side>
              </Row>
            </RoomContent>
          </Row>
        </Content>
      </ScrollView>
    </React.Fragment>
  );
};

const EditRoomInventoryPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(EditRoomInventoryPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inventoryUuid: params.inventoryUuid,
      uuid: params.uuid,
    },
  });

  return (
    <Container>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <EditRoomInventoryPageContent
            key={data.room.id}
            room={data.room}
            inventory={data.inventory}
            refetch={refetch}
          />
        )}
      </Loading>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditRoomInventoryPage.collectionFragment = gql`
  ${CollectionForm.edit.fragment}
  fragment EditRoomInventoryPage_collectionFragment on Collection {
    id
    takeCount
    leaveCount
    ...CollectionForm_edit
  }
`;

EditRoomInventoryPage.query = gql`
  ${EditRoomInventoryPage.collectionFragment}
  ${CollectionItemsList.fragment}
  ${CreateCustomItemModal.fragment}
  ${CreateRoomModal.fragment}
  ${FileViewer.fragment}

  query EditRoomInventoryPage($inventoryUuid: String!, $uuid: String!) {
    ${gql.query}
    inventory(uuid: $inventoryUuid) {
      id
      uuid
      project {
        id
        uuid
        projectType {
          id
          defaultInventoryLibrary {
            id
            categories {
              id
              name
              isShared
            }
          }
        }
        organization {
          id
          categories {
            id
            name
            isShared
          }
        }
      }
      collection {
        id
      }
      rooms {
        id
        uuid
        name
      }
      ...CollectionItemsList
      ...CreateCustomItemModal
      ...CreateRoomModal
    }
    room(uuid: $uuid) {
      id
      uuid
      name
      customerNotes
      roomType {
        id
        primaryCategory {
          id
        }
      }
      collection {
        id
        ...EditRoomInventoryPage_collectionFragment
      }
      latestVideoAttachment {
        id
        file {
          id
          ...FileViewer
        }
      }
      filteredAttachments(attachmentCategoryKinds: ["ROOM_PHOTO"]) {
        id
        file {
          id
          name
          downloadUrl
        }
      }
    }
  }
`;

export default EditRoomInventoryPage;
