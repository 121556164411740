// Libraries
import React from 'react';

// Supermove
import {useNavigationDOM} from '@supermove/hooks';

// App
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const InventorySettingsPage = ({title, description, children}) => {
  const {navigator} = useNavigationDOM();

  return (
    <SettingsPage
      title={title}
      description={description}
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {name: 'Inventory'},
      ]}
      tabs={[
        {
          label: 'General',
          url: '/settings/inventory/general',
        },
        {
          label: 'Libraries',
          url: '/settings/inventory/libraries',
        },
      ]}
    >
      {children}
    </SettingsPage>
  );
};

export default InventorySettingsPage;
