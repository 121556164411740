// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Loading, SidebarV1, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {Animation, colors, Typography} from '@supermove/styles';

// Components
import {Navigation} from 'modules/App/components';

import JobActionsList from './JobActionsList';

const Container = Styled.View`
  width: 320px;
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

const Content = Styled.View`
  flex: 1;
`;

const Title = Styled.Text`
  padding-horizontal: 20px;
  ${Typography.PageHeading}
`;

const Line = Styled.View`
  align-self: stretch;
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const LoadingContainer = Styled.View`
  margin-top: 20px;
`;

const LoadingRow = Styled.View`
  ${Animation.pulse}
  height: 24px;
  width: ${({isHeader}) => (isHeader ? '80%' : '65%')};
  background-color: ${({isHeader}) => (isHeader ? colors.gray.border : colors.gray.background)};
  border-radius: 4px;
  margin-bottom: 16px;
  margin-horizontal: 20px;
`;

const LoadingPlaceholder = () => {
  return (
    <LoadingContainer>
      {[..._.range(0, 30)].map((row, i) => (
        <LoadingRow key={i} isHeader={i % 5 === 0} />
      ))}
    </LoadingContainer>
  );
};

const JobPageSidebar = ({isOpen, speed, onOpen, onClose, refetch, children}) => {
  const {params} = useNavigationDOM();

  const {loading, data} = useQuery(JobPageSidebar.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
    skip: !isOpen,
  });

  return (
    <SidebarV1
      key={isOpen}
      isOpen={isOpen}
      position={'right'}
      onOpen={onOpen}
      onClose={onClose}
      content={
        <Container>
          <Header>
            <Title>Actions</Title>
            <Navigation.CloseButton onPress={onClose} />
          </Header>
          <Line />
          <Content>
            <Loading loading={loading || !data} as={LoadingPlaceholder}>
              {() => (
                <JobActionsList
                  job={data.job}
                  viewer={data.viewer}
                  handleClose={() => {
                    refetch();
                    onClose();
                  }}
                />
              )}
            </Loading>
          </Content>
        </Container>
      }
      children={children}
      styles={{
        sidebar: {
          background: colors.white,
          transition: `transform ${speed}s ease-out`,
          WebkitTransition: `-webkit-transform ${speed}s ease-out`,
        },
        content: {
          transition: `left ${speed}s ease-out, right ${speed}s ease-out`,
        },
        overlay: {
          transition: `opacity ${speed}s ease-out, visibility ${speed}s ease-out`,
        },
      }}
    />
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobPageSidebar.propTypes = {
  speed: PropTypes.number,
};

JobPageSidebar.defaultProps = {
  speed: 0.15,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobPageSidebar.query = gql`
  ${JobActionsList.fragment}

  query JobPageSidebar($uuid: String!) {
    ${gql.query}

    viewer {
      id
      ...JobActionsList_Viewer
    }

    job(uuid: $uuid) {
      id
      ...JobActionsList_Job
    }
  }
`;

export default JobPageSidebar;
