export interface ToggleWorkflowIsActiveForm {
  workflowId: number;
  isActive: boolean;
}

const _new = ({
  workflowId,
  isActive,
}: {
  workflowId: number;
  isActive: boolean;
}): ToggleWorkflowIsActiveForm => ({
  workflowId,
  isActive,
});

const toForm = ({
  workflowId,
  isActive,
}: {
  workflowId: number;
  isActive: boolean;
}): ToggleWorkflowIsActiveForm => ({
  workflowId,
  isActive,
});

const toMutation = ({
  workflowId,
  isActive,
}: {
  workflowId: number;
  isActive: boolean;
}): ToggleWorkflowIsActiveForm => ({
  workflowId,
  isActive: !isActive,
});

const ToggleWorkflowIsActiveForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ToggleWorkflowIsActiveForm;
