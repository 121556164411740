// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import DeleteAttachmentForm from '@shared/modules/File/forms/DeleteAttachmentForm';
import useDeleteAttachmentForm from '@shared/modules/File/hooks/useDeleteAttachmentForm';

const DeleteAttachmentModal = ({attachment, deleteAttachmentModal, refetch}) => {
  const deleteAttachmentForm = DeleteAttachmentForm.new({attachmentId: attachment.id});
  const {handleSubmit} = useDeleteAttachmentForm({
    deleteAttachmentForm,
    onSuccess: () => {
      deleteAttachmentModal.handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DeleteModal
      handleDelete={handleSubmit}
      handleClose={deleteAttachmentModal.handleClose}
      isOpen={deleteAttachmentModal.isOpen}
      title={`Remove "${attachment.file.name}"?`}
      subtitle={`You can't undo this action.`}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteAttachmentModal.fragment = gql`
  fragment DeleteAttachmentModal on Attachment {
    id
    file {
      id
      name
    }
  }
`;
export default DeleteAttachmentModal;
