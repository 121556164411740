// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import LocationKind from '@shared/modules/Location/enums/LocationKind';
import ToggleBadge from 'modules/App/components/ToggleBadge';

const Row = Styled.View`
  flex-direction: row;
`;

const LocationNumber = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  color: ${colors.gray.secondary};
`;

const LocationBadge = ({label, color}) => {
  return (
    <React.Fragment>
      <Space width={12} />
      <ToggleBadge label={label} color={color} isOn />
    </React.Fragment>
  );
};

const LocationsListItemHeader = ({
  location,
  totalLocations,
  index,
  children,
  isOrigin,
  isDestination,
  isStops,
}) => {
  const responsive = useResponsive();

  return (
    <Row style={{alignItems: 'center'}}>
      <LocationNumber responsive={responsive}>{`Location ${
        index + 1
      } of ${totalLocations}`}</LocationNumber>
      {isStops ? (
        <React.Fragment>
          {location.kind === LocationKind.PICK_UP && (
            <LocationBadge label={'Pick-up'} color={colors.blue.interactive} />
          )}
          {location.kind === LocationKind.DROP_OFF && (
            <LocationBadge label={'Drop-off'} color={colors.green.status} />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {isOrigin && <LocationBadge label={'Origin'} color={colors.blue.interactive} />}
          {isDestination && <LocationBadge label={'Destination'} color={colors.green.status} />}
        </React.Fragment>
      )}
      <Space style={{flex: 1}} />
      {children}
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
LocationsListItemHeader.fragment = gql`
  fragment LocationsListItemHeader on Location {
    id
    kind
  }
`;

export default LocationsListItemHeader;
