// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

// App
import Expression from '@shared/ast/Expression';
import FormulaPrettyPrinter from '@shared/formulas/src/FormulaPrettyPrinter';

const getDisplayUpdatedAt = withFragment(
  (formula) => {
    return Datetime.convertToDisplayDatetime((formula as any).updatedAt);
  },
  gql`
    fragment Formula_getDisplayUpdatedAt on Formula {
      id
      updatedAt
    }
  `,
);

const getFormulaDropdownOptions = withFragment(
  (formulas) => {
    return (formulas as any).map((formula: any) => ({
      value: _.toNumber(formula.id),
      label: formula.name,
    }));
  },
  gql`
    fragment Formula_getFormulaDropdownOptions on Formula {
      id
      name
    }
  `,
);

const getFormulaString = withFragment(
  (formula) => {
    if ((formula as any).astJson) {
      return new FormulaPrettyPrinter().print(
        Expression.fromJSON(JSON.parse((formula as any).astJson)),
      );
    }
    return '';
  },
  gql`
    fragment Formula_getFormulaString on Formula {
      id
      astJson
    }
  `,
);

const Formula = {
  getDisplayUpdatedAt,
  getFormulaDropdownOptions,
  getFormulaString,
};

export default Formula;
