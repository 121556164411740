// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {useModal, usePopover} from '@supermove/hooks';
import {DocumentItemSectionType, DocumentItemType} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import IconButton from '@shared/design/components/IconButton';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import DocumentContentJson from '@shared/modules/Document/helpers/DocumentContentJson';
import DocumentTemplateVersionEditorTypes, {
  SelectedDocumentItemInSectionType,
  SetSelectedDocumentItemType,
  DocumentContentJsonWithSectionsType,
  SetDocumentContentJsonWithSectionsType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import AddColumnCautionModal from 'modules/Organization/Settings/Document/components/AddColumnCautionModal';
import DuplicateSectionModal from 'modules/Organization/Settings/Document/components/DuplicateSectionModal';
import EditSectionNameModal from 'modules/Organization/Settings/Document/components/EditSectionNameModal';
import MergeTextBlocksModal from 'modules/Organization/Settings/Document/components/MergeTextBlocksModal';

const handleUpdateSectionName = ({
  name,
  selectedDocumentItem,
  setDraftDocumentContentJson,
}: {
  name: string;
  selectedDocumentItem: SelectedDocumentItemInSectionType;
  setDraftDocumentContentJson: SetDocumentContentJsonWithSectionsType;
}) => {
  setDraftDocumentContentJson((prevState) => {
    _.set(prevState, `documentItems.${selectedDocumentItem.sectionIndex}.name`, name);
    return {...prevState};
  });
};

const DocumentTemplateSectionSettingsButton = ({
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  setSelectedDocumentItem,
  selectedDocumentItem,
}: {
  draftDocumentContentJson: DocumentContentJsonWithSectionsType;
  setDraftDocumentContentJson: SetDocumentContentJsonWithSectionsType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  selectedDocumentItem: SelectedDocumentItemInSectionType;
}) => {
  const documentItemSettingsPopover = usePopover({name: 'Document Item Settings Popover'});
  const addColumnCautionModal = useModal({name: 'Add Column Caution Modal', enableTracking: true});
  const mergeTextModal = useModal({name: 'Merge Text Modal', enableTracking: true});
  const editSectionNameModal = useModal({name: 'Edit Section Name Modal', enableTracking: true});
  const duplicateSectionModal = useModal({name: 'Duplicate Section Modal', enableTracking: true});
  const removeSectionModal = useModal({name: 'Remove Section Modal', enableTracking: true});
  const {sectionIndex} = selectedDocumentItem;
  const section = draftDocumentContentJson.documentItems[sectionIndex] as DocumentItemSectionType;
  const documentItemsField = DocumentContentJson.getDocumentItemsField({sectionIndex});
  const documentItems = _.get(draftDocumentContentJson, documentItemsField) as DocumentItemType[];
  const hasColumns =
    documentItems.length === 2 &&
    _.every(documentItems, DocumentTemplateVersionEditorTypes.DocumentItem.getIsColumn);
  const hasDocumentItems = hasColumns
    ? _.some(_.get(documentItems, '0.documentItems')) ||
      _.some(_.get(documentItems, '1.documentItems'))
    : _.some(documentItems);

  const hasRestrictedColumnItem = DocumentContentJson.getHasColumnRestrictedDocumentItem(
    draftDocumentContentJson,
    {selectedDocumentItem},
  );
  const addColumn = () => {
    setDraftDocumentContentJson((prevState) => {
      return DocumentContentJson.handleAddColumn(_.cloneDeep(prevState), {selectedDocumentItem});
    });
    if (hasRestrictedColumnItem) {
      // Reset the selected item if items are getting removed and moving around.
      setSelectedDocumentItem({...selectedDocumentItem, index: undefined, documentItem: undefined});
    } else {
      setSelectedDocumentItem({...selectedDocumentItem, columnIndex: 0});
    }
  };
  const deleteSection = () => {
    setSelectedDocumentItem({});
    setDraftDocumentContentJson((prevState) => {
      prevState.documentItems.splice(sectionIndex, 1);
      return {...prevState};
    });
  };

  return (
    <React.Fragment>
      <ActionMenuPopover
        popover={documentItemSettingsPopover}
        placement='right-start'
        width={400}
        actions={[
          {
            text: 'Add column',
            description: 'Splits the section into two columns.',
            onPress: hasRestrictedColumnItem ? addColumnCautionModal.handleOpen : addColumn,
            isDisabled: hasColumns,
            tooltip: hasColumns ? 'Columns have already been added.' : undefined,
          },
          {
            text: 'Merge text blocks',
            description: 'Merges all adjacent "Text" document items into one item.',
            onPress: mergeTextModal.handleOpen,
          },
          {
            text: 'Edit section name',
            description: 'Changes the name of this section.',
            onPress: editSectionNameModal.handleOpen,
          },
          {
            text: 'Duplicate section',
            description: 'Creates a copy of this section and its document items.',
            onPress: duplicateSectionModal.handleOpen,
          },
          {
            text: 'Remove section',
            description: 'Removes this section and all its document items.',
            onPress: hasDocumentItems ? removeSectionModal.handleOpen : deleteSection,
            color: colors.red.warning,
          },
        ]}
      >
        <IconButton
          onPress={documentItemSettingsPopover.handleOpen}
          source={Icon.Cog}
          isSecondary
        />
      </ActionMenuPopover>
      <AddColumnCautionModal
        isOpen={addColumnCautionModal.isOpen}
        handleClose={addColumnCautionModal.handleClose}
        handleAddColumn={addColumn}
        documentContentJson={draftDocumentContentJson}
        selectedDocumentItem={selectedDocumentItem}
      />
      <MergeTextBlocksModal
        isOpen={mergeTextModal.isOpen}
        handleClose={mergeTextModal.handleClose}
        selectedDocumentItem={selectedDocumentItem}
        setSelectedDocumentItem={setSelectedDocumentItem}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
      />
      <EditSectionNameModal
        sectionName={section.name}
        key={editSectionNameModal.key}
        isOpen={editSectionNameModal.isOpen}
        handleClose={editSectionNameModal.handleClose}
        handleUpdate={(name: string) => {
          handleUpdateSectionName({
            name,
            selectedDocumentItem,
            setDraftDocumentContentJson,
          });
        }}
      />
      <DuplicateSectionModal
        key={duplicateSectionModal.key}
        isOpen={duplicateSectionModal.isOpen}
        handleClose={duplicateSectionModal.handleClose}
        handleDuplicate={(name: string) => {
          const newSectionIndex = draftDocumentContentJson.documentItems.length;
          setDraftDocumentContentJson((prevState) => {
            const documentItemsCopy = _.cloneDeep(documentItems);
            const newSection = DocumentItemKindV2.getNewDocumentItem(DocumentItemKindV2.SECTION, {
              name,
              documentItems: documentItemsCopy,
            }) as DocumentItemSectionType;

            return {
              ...prevState,
              documentItems: [...prevState.documentItems, newSection],
            };
          });
          setSelectedDocumentItem({
            sectionIndex: newSectionIndex,
            index: undefined,
            documentItem: undefined,
          });
        }}
      />
      <DeleteModal
        isOpen={removeSectionModal.isOpen}
        handleClose={removeSectionModal.handleClose}
        title={'Remove section?'}
        subtitle={'Document items in this section will also be removed.'}
        handleDelete={() => {
          deleteSection();
          removeSectionModal.handleClose();
        }}
        deleteButtonText={'Remove'}
      />
    </React.Fragment>
  );
};

export default DocumentTemplateSectionSettingsButton;
