// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Lifecycle, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const ViewScheduleMutation = ({date, userId, onSuccess, onError, children}) => (
  <Mutation
    variables={{
      date,
      userId,
    }}
    mutation={ViewScheduleMutation.mutation}
    onCompleted={({response: {errors}}) => {
      if (!errors) {
        onSuccess();
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) => (
      <Lifecycle onMount={() => handleSubmit()}>{children}</Lifecycle>
    )}
  </Mutation>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
ViewScheduleMutation.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

ViewScheduleMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewScheduleMutation.mutation = gql`
  mutation ViewScheduleMutation(
    $date: String!,
    $userId: Int!,
  ) {
    response: viewSchedule(
      date: $date,
      userId: $userId,
    ) {
      ${gql.errors}
    }
  }
`;

export default ViewScheduleMutation;
