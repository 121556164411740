// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import CostItemTypeForm from '@shared/modules/Billing/forms/CostItemTypeForm';
import useCreateCostItemTypeMutation from '@shared/modules/Billing/hooks/useCreateCostItemTypeMutation';
import CostItemTypeSidebarContent from 'modules/Organization/Settings/BillingLibraries/components/CostItemTypeSidebarContent';

const CreateCostItemTypeSidebar = ({
  billingLibrary,
  category,
  header,
  role,
  moverPositions,
  isOpen,
  handleClose,
  refetch,
}) => {
  const costItemTypeForm = CostItemTypeForm.new({
    billingLibrary,
    category,
    role,
    moverPositions,
  });

  const {form, handleSubmit} = useCreateCostItemTypeMutation({
    costItemTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <CostItemTypeSidebarContent
      form={form}
      header={header}
      category={category}
      moverPositions={moverPositions}
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCostItemTypeSidebar.fragment = gql`
  ${CostItemTypeForm.new.fragment}
  ${CostItemTypeSidebarContent.fragment}

  fragment CreateCostItemTypeSidebar on BillingLibrary {
    id
    billItemTypes {
      id
      ...CostItemTypeSidebarContent
    }
    ...CostItemTypeForm_new
  }
`;

export default CreateCostItemTypeSidebar;
