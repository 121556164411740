import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

interface WebhookFormArgs {
  webhookId: string | null;
  organizationId: string;
  kind: string | null;
  url: string;
  headers: string;
  isActive: boolean;
  isDeleted: boolean;
  name: string;
  description: string;
}

const _new = ({organizationId}: {organizationId: string}): WebhookFormArgs => ({
  webhookId: null,
  organizationId,
  kind: null,
  url: '',
  headers: '{}',
  isActive: true,
  isDeleted: false,
  name: '',
  description: '',
});

const edit = withFragment(
  (webhook: {[key: string]: any}) => ({
    webhookId: webhook.id,
    organizationId: webhook.organizationId,
    kind: webhook.kind,
    url: webhook.url,
    headers: webhook.headers,
    isActive: webhook.isActive,
    name: webhook.name,
    description: webhook.description,
  }),
  gql`
    fragment WebhookForm_edit on Webhook {
      id
      organizationId
      kind
      url
      headers
      isActive
      name
      description
    }
  `,
);

const toForm = ({
  webhookId,
  organizationId,
  kind,
  url,
  headers,
  isActive,
  isDeleted,
  name,
  description,
}: WebhookFormArgs) => {
  return {
    webhookId,
    organizationId,
    kind,
    url,
    headers: headers ? JSON.stringify(JSON.parse(headers), undefined, 2) : '',
    isActive,
    isDeleted,
    name,
    description,
  };
};

const toMutation = ({
  webhookId,
  organizationId,
  kind,
  url,
  headers,
  isActive,
  isDeleted,
  name,
  description,
}: WebhookFormArgs) => {
  return {
    webhookId,
    organizationId,
    kind,
    url,
    headers: headers || '{}',
    isActive,
    isDeleted,
    name,
    description,
  };
};

const WebhookForm = {
  new: _new,
  toForm,
  toMutation,
  edit,
};

export default WebhookForm;
