// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import useCreateBillV3Mutation from '@shared/modules/Billing/hooks/useCreateBillV3Mutation';

const InstructionText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const AddBillFields = ({form, project}) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        name={`billForm.title`}
        label={'Title'}
        isRequired
        isResponsive
        input={{
          placeholder: 'Enter a title for this bill',
          required: !form.values.billForm.title,
        }}
      />
      <Space height={12} />
      <FieldInput
        {...form}
        name={`billForm.description`}
        label={'Description'}
        isResponsive
        input={{placeholder: 'Enter a description for this bill'}}
      />
      {project.organization.features.isEnabledIncludeEmptyBills && (
        <React.Fragment>
          <Space height={16} />
          <FieldInput
            {...form}
            name={`billForm.jobId`}
            component={DropdownInput}
            label={'Project or Job'}
            isResponsive
            input={{
              isSearchable: true,
              placeholder: `Select a job to add to`,
              isPortaled: true,
              options: [
                {
                  label: `Project-${project.identifier}`,
                  value: null,
                },
                ..._.map(project.activeMoveJobs, (job) => ({
                  label: Job.getFullName(job),
                  value: _.toNumber(job.id),
                })),
              ],
              setFieldValue: form.setFieldValue,
              style: {flex: 1},
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const BillingProjectAddBillDrawer = ({handleClose, isOpen, refetch, project}) => {
  const billForm = BillForm.new(project);
  const {form, submitting, handleSubmit} = useCreateBillV3Mutation({
    billForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error) => {
      console.log({error});
    },
  });

  const responsive = useResponsive();

  return (
    <DrawerWithAction
      headerText={'Add New Bill'}
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      primaryActionText={'Add'}
    >
      <InstructionText responsive={responsive}>
        {`Please enter a title and description for this bill.`}
        {!project.organization.features.isEnabledIncludeEmptyBills &&
          ` This bill will be linked to the project as a whole ` +
            `and not linked to any specific job.`}
      </InstructionText>
      <Space height={16} />
      <AddBillFields form={form} project={project} />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectAddBillDrawer.fragment = gql`
  ${BillForm.new.fragment}
  ${Job.getFullName.fragment}

  fragment BillingProjectAddBillDrawer on Project {
    id
    identifier
    activeMoveJobs {
      id
      ...Job_getFullName
    }
    organization {
      id
      features {
        isEnabledIncludeEmptyBills: isEnabled(feature: "INCLUDE_EMPTY_JOB_BILLS")
      }
    }
    ...BillForm_new
  }
`;

export default BillingProjectAddBillDrawer;
