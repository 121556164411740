// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Emoji, Modal, Styled, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useState} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';
import {fontWeight, colors} from '@supermove/styles';

// App
import UpdateSuperAdminImpersonateMutation from '../UpdateSuperAdminImpersonateMutation';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.LoadingButton`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

const logOut = async () => {
  await Storage.clear();
  window.location.href = '/';
};

const shouldUndoImpersonation = ({actualViewer, viewer}) => {
  return actualViewer.role === 'super' && viewer.role !== 'super';
};

const LogOutModal = ({trigger}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {loading, data} = useQuery(LogOutModal.query, {
    skip: !isOpen,
    fetchPolicy: 'network-only',
  });
  const {actualViewer, viewer} = data || {};
  return (
    <Modal trigger={trigger} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
      {({handleClose}) => (
        <Container pointerEvents={'box-none'}>
          <Wrapper width={350}>
            <Loading loading={loading}>
              {() => {
                return (
                  <React.Fragment>
                    <Circle>
                      <Emoji component={EmojiText} name={'wave'} />
                    </Circle>
                    <Content>
                      <Title>Sign out</Title>
                      <Subtitle>Are you sure you want to sign out?</Subtitle>
                      <Actions>
                        {shouldUndoImpersonation({actualViewer, viewer}) ? (
                          <UpdateSuperAdminImpersonateMutation
                            user={null}
                            onSuccess={() => {
                              window.location = '/settings/users/staff';
                            }}
                          >
                            {({loading, handleSubmit}) => (
                              <Button loading={loading} onPress={handleSubmit}>
                                <Text>Undo Imp</Text>
                              </Button>
                            )}
                          </UpdateSuperAdminImpersonateMutation>
                        ) : (
                          <Button color={colors.gray.tertiary} onPress={handleClose}>
                            <Text>Cancel</Text>
                          </Button>
                        )}
                        <ButtonSpace />
                        {shouldUndoImpersonation({actualViewer, viewer}) ? (
                          <UpdateSuperAdminImpersonateMutation
                            user={null}
                            onSuccess={() => logOut()}
                          >
                            {({loading, handleSubmit}) => (
                              <Button loading={loading} onPress={handleSubmit}>
                                <Text>Sign Out</Text>
                              </Button>
                            )}
                          </UpdateSuperAdminImpersonateMutation>
                        ) : (
                          <Button onPress={() => logOut()}>
                            <Text>Sign Out</Text>
                          </Button>
                        )}
                      </Actions>
                    </Content>
                  </React.Fragment>
                );
              }}
            </Loading>
          </Wrapper>
        </Container>
      )}
    </Modal>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
LogOutModal.propTypes = {
  trigger: PropTypes.func.isRequired,
};

LogOutModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
LogOutModal.query = gql`
query LogOutModal {
  ${gql.query}
  
  actualViewer {
    id
    role
  }
  
  viewer {
    id
    role
  }

}
`;

export default LogOutModal;
