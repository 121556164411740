// Libraries
import _ from 'lodash';

// Supermove
import {useNavigationDOM, useState, useTrackEvent} from '@supermove/hooks';

// App
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';

const useProjectPageAnalytics = () => {
  const {params} = useNavigationDOM();
  const [prevWidget, setPrevWidget] = useState();
  const [prevBlock, setPrevBlock] = useState();

  const {trackEvent: trackWidgetEvent} = useTrackEvent({
    // Event name should not be changed. It should be derived from the enum, made human readable.
    eventName: `Viewed ${_.startCase(_.toLower(params.widget))} Widget`,
    eventType: 'Project Widget View',
  });

  const {trackEvent: trackBlockEvent} = useTrackEvent({
    // Event name should not be changed. It should be derived from the enum, made human readable.
    eventName: `Viewed ${_.startCase(_.toLower(params.block))} Block`,
    eventType: 'Project Block View',
    additionalData: {
      // TODO(jholston): Block value should be changed to dynamic block names when they exist.
      Block: ProjectBlockKind.getDisplay(params.block),
      Section: params.section,
    },
  });

  // Track anaylitcs for viewing a widget
  const trackWidget = () => {
    if (params.widget && params.widget !== prevWidget) {
      trackWidgetEvent();
      setPrevWidget(params.widget);
    }
  };

  // Track analytics for viewing a block
  const trackBlock = () => {
    if (params.block && params.block !== prevBlock) {
      trackBlockEvent();
      setPrevBlock(params.block);
    }
  };

  return {
    trackWidget,
    trackBlock,
  };
};

export default useProjectPageAnalytics;
