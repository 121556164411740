// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useSyncReportMoveToQuickbooksForm = ({reportMove, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      errors: null,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSyncReportMoveToQuickbooksForm.mutation,
    variables: {
      reportMoveId: reportMove.id,
    },
    onSuccess,
    onError: (errors) => {
      form.setFieldValue('errors', errors);
      onError(errors);
    },
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useSyncReportMoveToQuickbooksForm.fragment = gql`
  fragment useSyncReportMoveToQuickbooksForm on ReportMove {
    id
  }
`;

useSyncReportMoveToQuickbooksForm.mutation = gql`
  mutation useSyncReportMoveToQuickbooksForm(
    $reportMoveId: Int!,
  ) {
    response: syncReportMoveToQuickbooks(
      reportMoveId: $reportMoveId,
    ) {
      ${gql.errors}
      reportMove {
        id
        job {
          id
        }
      }
    }
  }
`;

export default useSyncReportMoveToQuickbooksForm;
