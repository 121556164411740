// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Query} from '@supermove/components';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';

const Page = ({fetchPolicy, pollInterval, query, skip, variables, children}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();

  return (
    <Query
      fetchPolicy={fetchPolicy}
      pollInterval={pollInterval}
      query={query}
      skip={skip}
      variables={variables}
    >
      {({loading, data, refetch, startPolling, stopPolling}) =>
        children({
          responsive,
          navigator,
          params,
          loading,
          data,
          refetch,
          startPolling,
          stopPolling,
        })
      }
    </Query>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
Page.propTypes = {
  fetchPolicy: PropTypes.string,
  pollInterval: PropTypes.number,
  skip: PropTypes.bool,
};

Page.defaultProps = {
  fetchPolicy: 'cache-and-network',
  pollInterval: 0,
  skip: false,
};

export default Page;
