// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {JobReportMoveInvoiceDocument} from 'modules/Document/ReportMove/components';
import {ViewDocumentModal} from 'modules/Document/components';

const PreviewReportMoveInvoiceModal = ({job, trigger}) => (
  <ViewDocumentModal title={'Move Receipt'} trigger={trigger}>
    <JobReportMoveInvoiceDocument job={job} />
  </ViewDocumentModal>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PreviewReportMoveInvoiceModal.fragment = gql`
  ${JobReportMoveInvoiceDocument.fragment}

  fragment PreviewReportMoveInvoiceModal on Job {
    id
    ...JobReportMoveInvoiceDocument
  }
`;

export default PreviewReportMoveInvoiceModal;
