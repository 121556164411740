// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Emoji, Icon, Lifecycle, Ref, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import ConfirmationExpiredModal from 'modules/Customer/Project/Confirmation/Document/components/ConfirmationExpiredModal';
import SignProjectConfirmationDocumentForm from 'modules/Customer/Project/Confirmation/Document/components/SignProjectConfirmationDocumentForm';
import ConfirmationStepHeader from 'modules/Customer/Project/Confirmation/components/ConfirmationStepHeader';
import ProjectPage from 'modules/Customer/Project/components/ProjectPage';
import PreviewDocument from 'modules/Document/components/PreviewDocument';
import ProjectConfirmationDocument from 'modules/Project/Confirmation/components/ProjectConfirmationDocument';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  flex: 1;
  width: ${(props) => (props.mobile ? '100%' : '796px')};
  padding-horizontal: ${(props) => (props.mobile ? 20 : 0)}px;
`;

const Content = Styled.View`
  align-self: stretch;
  padding-top: 60px;
  padding-bottom: 90px;
`;

const Row = Styled.View`
  margin-top: 30px;
`;

const Section = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const Name = Styled.H6`
  ${fontWeight(700)}
`;

const Footer = Styled.View`
  width: 100%;
  margin-top: 30px;
`;

const Button = Styled.StatefulButton`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Title = Styled.H3`
  ${fontWeight(900)}
  color: ${colors.gray.primary};
`;

const getSubtitleText = ({responsive}) => {
  if (responsive.mobile) {
    return (
      'Please read through the move confirmation letter below. Afterwards, ' +
      'sign at the bottom and press "Continue".'
    );
  } else {
    return (
      'Please read through the move confirmation letter below.\nAfterwards, ' +
      'sign at the bottom and press "Continue".\nYou will be able to ' +
      'print a copy of the signed confirmation letter on the next page.'
    );
  }
};

const isButtonDisabled = ({form}) => {
  return !form.values.image;
};

const ButtonContent = ({form}) => {
  if (form.loading) {
    return null;
  }

  if (form.values.isFinished) {
    return <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.Check} />;
  }

  return <Text>Continue</Text>;
};

const isCorrectPage = ({project}) => {
  return (
    _.get(project, 'confirmation.nextStep.kind') ===
    ConfirmationStepKind.CONFIRMATION_STEP_SIGN_DOCUMENT
  );
};

const onMountOrUpdate = ({navigator, project}) => {
  if (!isCorrectPage({project})) {
    navigator.push(`/0/${project.organization.slug}/projects/${project.uuid}/confirmation`);
  }
};

// If the next step of the confirmation is not to sign document,
// redirect the customer to the show confirmation page.
const Redirect = ({children, navigator, project}) => (
  <Lifecycle onMount={() => onMountOrUpdate({navigator, project})}>
    {isCorrectPage({project}) ? children : null}
  </Lifecycle>
);

const DocumentConfirmationProjectPageContent = ({
  data,
  form,
  responsive,
  signatureRef,
  navigator,
}) => {
  const hasValidationErrors = ({form}) => {
    return !!_.get(form, 'errors.confirmation.isActive');
  };

  if (data.project.isCancelled) {
    return (
      <Container>
        <Section
          sectionIndex={0}
          style={{
            marginBottom: 20,
          }}
        >
          <Row>
            <Section>
              <Emoji component={EmojiText} name={'pencil'} />
              <Name>Confirmation letter</Name>
            </Section>
            <Space height={10} />
            <Section>
              <Title>This confirmation has expired</Title>
            </Section>
          </Row>
        </Section>
      </Container>
    );
  }

  return (
    <Content>
      <ConfirmationStepHeader
        title={'Sign your confirmation letter'}
        subtitle={getSubtitleText({responsive})}
        confirmation={data.project.confirmation}
      />
      <Row>
        <Section>
          <Emoji component={EmojiText} name={'pencil'} />
          <Name>Confirmation letter</Name>
        </Section>
        <PreviewDocument
          // TODO (warren): re-enable fullscreen once you can sign in fullscreen mode.
          canFullscreen={false}
          title={'Confirmation Letter'}
          style={{marginTop: 10}}
        >
          <ProjectConfirmationDocument
            project={data.project}
            values={form.values}
            // Signable props.
            isSignable
            form={form}
            signatureRef={signatureRef}
          />
        </PreviewDocument>
      </Row>
      <Footer>
        <Button
          disabled={isButtonDisabled({form})}
          loading={form.loading}
          onPress={form.handleSubmit}
          onSuccess={() =>
            navigator.push(
              `/0/${data.project.organization.slug}/projects/${data.project.uuid}/confirmation`,
            )
          }
          success={form.values.isFinished}
        >
          <ButtonContent form={form} />
        </Button>
        <ConfirmationExpiredModal
          isOpen={hasValidationErrors({form})}
          handleClose={() => window.location.reload()}
        />
      </Footer>
    </Content>
  );
};

const DocumentConfirmationProjectPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <ProjectPage variables={{uuid}} query={DocumentConfirmationProjectPage.query}>
    {({responsive, navigator, data}) => (
      <Redirect navigator={navigator} project={data.project}>
        <Wrapper>
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Container {...responsive}>
              <SignProjectConfirmationDocumentForm project={data.project}>
                {(form) => (
                  <Ref>
                    {(signatureRef) => (
                      <DocumentConfirmationProjectPageContent
                        data={data}
                        form={form}
                        responsive={responsive}
                        signatureRef={signatureRef}
                        navigator={navigator}
                      />
                    )}
                  </Ref>
                )}
              </SignProjectConfirmationDocumentForm>
            </Container>
          </ScrollView>
        </Wrapper>
      </Redirect>
    )}
  </ProjectPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentConfirmationProjectPage.query = gql`
  ${ProjectPage.fragment}
  ${ConfirmationStepHeader.fragment}
  ${ProjectConfirmationDocument.fragment}
  ${SignProjectConfirmationDocumentForm.fragment}

  query DocumentConfirmationProjectPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      isCancelled
      confirmation {
        id
        nextStep {
          kind
          value
        }
        ...ConfirmationStepHeader
      }
      organization {
        id
        slug
        name
      }
      ...ProjectPage
      ...ProjectConfirmationDocument
      ...SignProjectConfirmationDocumentForm
    }
  }
`;

export default DocumentConfirmationProjectPage;
