import Expression, {
  GroupingExpression,
  IdentifierExpression,
  NumberLiteralExpression,
  SymbolExpression,
  CallExpression,
  StringLiteralExpression,
  OperatorExpression,
} from './Expression';

export abstract class Visitor {
  abstract visitExpression: (expression: Expression) => any;

  abstract visitGrouping: (groupingExpression: GroupingExpression) => any;

  abstract visitIdentifier: (identifierExpression: IdentifierExpression) => any;

  abstract visitNumberLiteral: (numberLiteralExpression: NumberLiteralExpression) => any;

  abstract visitStringLiteral: (stringLiteralExpression: StringLiteralExpression) => any;

  abstract visitSymbol: (symbolExpression: SymbolExpression) => any;

  abstract visitCall: (callExpresssion: CallExpression) => any;

  abstract visitOperator: (operatorExpression: OperatorExpression) => any;
}
