// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FileDragInput, Styled, Space, DropdownInput} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import DataTableKind from '@shared/modules/Billing/enums/DataTableKind';
import DataTableVariableAccessKind from '@shared/modules/Billing/enums/DataTableVariableAccessKind';
import SystemMessage from 'modules/App/components/SystemMessage';

const Row = Styled.View`
  flex-direction: row;
`;

const FileDragInputContainer = Styled.View`
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-style: dotted;
  border-width: 2px;
  cursor: pointer;
  border-color: ${colors.gray.tertiary};
  background-color: ${({isHighlighted}) => (isHighlighted ? colors.gray.background : colors.white)};
`;

const FileDragInputText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
`;

const FileNameText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.blue.interactive};
`;

const Requirement = ({children}) => {
  return (
    <Row>
      <SystemMessage.Text vars={{isWarning: true}}>•</SystemMessage.Text>
      <Space width={8} />
      <SystemMessage.Text vars={{isWarning: true}}>{children}</SystemMessage.Text>
    </Row>
  );
};

const UploadInput = ({form, field}) => {
  return (
    <FileDragInput
      onFilesChange={(files) => {
        form.setFieldValue(`${field}.data`, files[0]);
      }}
    >
      {({isDragActive}) => (
        <FileDragInputContainer isHighlighted={isDragActive}>
          {!_.get(form.values, `${field}.data`) ? (
            <FileDragInputText>
              {isDragActive ? 'Drop the file here' : 'Drag a file or click to upload'}
            </FileDragInputText>
          ) : (
            <FileNameText>{_.get(form.values, `${field}.data`).name}</FileNameText>
          )}
        </FileDragInputContainer>
      )}
    </FileDragInput>
  );
};

const UploadDataTableFields = ({form, field, tableAccessKind, showTableKindDropdown = true}) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        label={'Table Name'}
        name={`${field}.name`}
        input={{
          required: !_.get(form.values, `${field}.name`),
          placeholder: 'Enter a table name',
        }}
      />
      {showTableKindDropdown && (
        <React.Fragment>
          <Space height={8} />
          <FieldInput
            {...form}
            index={1}
            label={'Table Kind'}
            name={`${field}.kind`}
            component={DropdownInput}
            input={{
              required: !_.get(form.values, `${field}.kind`),
              options: _.map(DataTableKind.VALUES, (kind) => ({
                label: DataTableKind.getDisplayKind(kind),
                value: kind,
              })),
              placeholder: 'Select a table kind',
              setFieldValue: form.setFieldValue,
              style: {width: '100%'},
            }}
          />
        </React.Fragment>
      )}
      <Space height={8} />
      <FieldInput
        {...form}
        index={2}
        name={`${field}.data`}
        component={UploadInput}
        input={{
          form,
          field,
        }}
      />
      <Space height={8} />
      <SystemMessage.Container vars={{isWarning: true}} style={{margin: 0}}>
        {DataTableVariableAccessKind.getTableReqirements(tableAccessKind).map(
          (requirement, index) => (
            <React.Fragment key={index}>
              {index > 0 && <Space height={4} />}
              <Requirement>{requirement}</Requirement>
            </React.Fragment>
          ),
        )}
      </SystemMessage.Container>
    </React.Fragment>
  );
};

export default UploadDataTableFields;
