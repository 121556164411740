// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.Touchable`
  flex-direction: row;
  align-items: center;
`;

const DisabledContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SwitchOuterOval = Styled.View`
  height: ${({isSmall}) => (isSmall ? '12px' : '16px')};
  width: ${({isSmall}) => (isSmall ? '18px' : '24px')};
  background-color: ${(props) => props.color};
  border-radius: 20px;
  justify-content: center;
  align-items: ${(props) => (props.isOn ? 'flex-end' : 'flex-start')};
  padding: 2px;
`;

const SwitchInnerCircle = Styled.View`
height: ${({isSmall}) => (isSmall ? '8px' : '12px')};
width: ${({isSmall}) => (isSmall ? '8px' : '12px')};
  border-radius: 6px;
  background-color: ${colors.white};
`;

const SwitchText = Styled.H8`
  ${fontWeight(700)}
  color: ${({vars}) => (vars.isOn ? colors.blue.interactive : colors.gray.tertiary)}
`;

const SIZES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
};

const SwitchContainer = ({onPress, children, disabled}) => {
  if (disabled) {
    return (
      <DisabledContainer onPress={onPress} activeOpacity={0.8}>
        {children}
      </DisabledContainer>
    );
  }

  return (
    <Container onPress={onPress} activeOpacity={0.8}>
      {children}
    </Container>
  );
};

const SwitchButton = ({isOn, color, size, disabled}) => {
  const isSmall = size === SIZES.SMALL;
  const baseColor = isOn ? color : colors.gray.tertiary;
  const switchColor = disabled ? colors.alpha(baseColor, 0.4) : baseColor;
  return (
    <SwitchOuterOval isOn={isOn} color={switchColor} activeOpacity={0.8} isSmall={isSmall}>
      <SwitchInnerCircle isSmall={isSmall} />
    </SwitchOuterOval>
  );
};

const Switch = ({isOn, color, onChange, label, size, disabled}) => {
  return (
    <SwitchContainer onPress={() => !disabled && onChange(!isOn)} disabled={disabled}>
      {!!label && (
        <React.Fragment>
          <SwitchText vars={{isOn}}>{label}</SwitchText>
          <Space width={6} />
        </React.Fragment>
      )}
      <SwitchButton isOn={isOn} color={color} size={size} disabled={disabled} />
    </SwitchContainer>
  );
};

Switch.Container = SwitchContainer;
Switch.Button = SwitchButton;
Switch.Text = SwitchText;
Switch.SIZE = SIZES;

// --------------------------------------------------
// Props
// --------------------------------------------------
Switch.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

Switch.defaultProps = {
  color: colors.blue.interactive,
  size: SIZES.MEDIUM,
};

export default Switch;
