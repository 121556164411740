// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Currency, Json, Percent} from '@supermove/utils';

const prepareFeatures = ({features}) => {
  return Json.stringify(Json.toUpperCaseKeys(Json.toSnakeCaseKeys(features)));
};

const CreateEmployeeMutation = ({
  values,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}) => (
  <Mutation
    variables={{
      organizationId: values.organizationId,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      payrollIdentifier: values.payrollIdentifier,
      tabletPin: values.tabletPin,
      position: values.position,
      branchCode: values.branchCode,
      billingHourlyRate: Currency.toMutation(values.billingHourlyRate),
      compensationPercentage: Percent.toMutation(values.compensationPercentage),
      isDriver: values.isDriver,
      features: prepareFeatures({features: values.features}),
      moverPositionIds: values.moverPositionIds,
      primaryMoverPositionId: values.primaryMoverPositionId,
    }}
    mutation={CreateEmployeeMutation.mutation}
    onCompleted={({response: {user, errors}}) => {
      submitForm();
      setMutationErrors(errors);

      if (user) {
        onSuccess(user);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
CreateEmployeeMutation.propTypes = {
  values: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
  setMutationErrors: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

CreateEmployeeMutation.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateEmployeeMutation.mutation = gql`
  mutation CreateEmployeeMutation(
    $organizationId: Int!,
    $firstName: String!,
    $lastName: String!,
    $phoneNumber: String!,
    $payrollIdentifier: String!,
    $tabletPin: String!,
    $position: String!,
    $branchCode: String!,
    $billingHourlyRate: Int!,
    $compensationPercentage: Float!,
    $isDriver: Boolean!,
    $features: JSONString!,
    $moverPositionIds: [Int],
    $primaryMoverPositionId: Int,
  ) {
    response: createEmployee(
      organizationId: $organizationId,
      firstName: $firstName,
      lastName: $lastName,
      phoneNumber: $phoneNumber,
      payrollIdentifier: $payrollIdentifier,
      tabletPin: $tabletPin,
      position: $position,
      branchCode: $branchCode,
      billingHourlyRate: $billingHourlyRate,
      compensationPercentage: $compensationPercentage,
      isDriver: $isDriver,
      features: $features,
      moverPositionIds: $moverPositionIds,
      primaryMoverPositionId: $primaryMoverPositionId,
    ) {
      ${gql.errors}
      user {
        id
        organizationId
        firstName
        lastName
        phoneNumber
        payrollIdentifier
        tabletPin
        billingHourlyRate
        compensationPercentage
        position
        isDriver
        features
      }
    }
  }
`;

export default CreateEmployeeMutation;
