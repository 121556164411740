// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  align-items: center;
  justify-content: center;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-width: ${(props) => props.borderSize}px;
  border-color: ${(props) => props.borderColor};
  border-radius: ${(props) => props.size / 2}px;
  background-color: ${(props) => props.color};
`;

const Text = Styled.H5`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const Avatar = ({borderColor, borderSize, color, size, text, textColor}) => (
  <Container borderColor={borderColor} borderSize={borderSize} color={color} size={size}>
    <Text color={textColor}>{text}</Text>
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
Avatar.propTypes = {
  borderColor: PropTypes.string,
  borderSize: PropTypes.number,
  color: PropTypes.string,
  size: PropTypes.number.isRequired,
  text: PropTypes.string,
  textColor: PropTypes.string,
};

Avatar.defaultProps = {
  borderColor: colors.blue.interactive,
  borderSize: 0,
  color: colors.blue.interactive,
  text: '',
  textColor: colors.white,
};

export default Avatar;
