// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import TaskTemplateForm from '@shared/modules/TaskManagement/forms/TaskTemplateForm';

const useDeleteTaskTemplateMutation = ({taskTemplateForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      taskTemplateForm: TaskTemplateForm.toForm(taskTemplateForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteTaskTemplateMutation.mutation,
    variables: {
      taskTemplateForm: TaskTemplateForm.toMutation(form.values.taskTemplateForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteTaskTemplateMutation.mutation = gql`
  mutation DeleteTaskTemplateMutation($taskTemplateForm: TaskTemplateForm!) {
    response: deleteTaskTemplate(taskTemplateForm: $taskTemplateForm) {
      ${gql.errors}
      taskTemplate {
        id
      }
    }
  }
`;

export default useDeleteTaskTemplateMutation;
