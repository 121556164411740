// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import FieldValue from '@shared/design/components/Field/FieldValue';
import Line from 'components/Line';
import EditStorageProjectModal from 'modules/Job/V2/Storage/components/EditStorageProjectModal';

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  z-index: ${({index = 0}) => 100 - index};
`;

const InfoBlockHeader = Styled.Text`
  ${Typography.Heading2};
  color: ${({color}) => color};
`;

const InfoBlockHeaderContainer = Styled.View`
  padding: 24px;
  flex-direction: row;
  align-items: center;
`;

const InfoBlockContentContainer = Styled.View`
    padding: 24px;
`;

const FieldContainer = Styled.View`
  width: 30%;
`;

const ClientInformationBlock = ({project, form, field, headerLabel}) => {
  const editStorageProjectModal = useModal({name: 'EditStorageProjectModal'});
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <InfoBlockHeaderContainer>
        <InfoBlockHeader>{headerLabel}</InfoBlockHeader>
        <Space style={{flex: 1}} />
        <QuaternaryButton
          text={'Edit'}
          textColor={colors.blue.interactive}
          iconLeft={Icon.Pen}
          onPress={editStorageProjectModal.handleOpen}
        />
        <EditStorageProjectModal
          key={editStorageProjectModal.key}
          isOpen={editStorageProjectModal.isOpen}
          handleClose={editStorageProjectModal.handleClose}
          project={project}
          invoiceForm={form}
          refetch={() => {}}
        />
      </InfoBlockHeaderContainer>
      <Line />
      <InfoBlockContentContainer>
        <Row index={0} {...responsive}>
          <FieldContainer>
            <FieldValue
              label={'Company Name'}
              value={_.get(form.values, `${field}.name`)}
              empty={'-'}
              numberOfLines={2}
              size={FieldValue.SIZE.MEDIUM}
            />
          </FieldContainer>
          {responsive.mobile ? <Space height={12} width={8} /> : <Space style={{flex: 1}} />}
          <FieldContainer>
            <FieldValue
              label={'Name'}
              value={_.get(form.values, `${field}.primaryContactForm.names`).join(' ')}
              empty={'-'}
              numberOfLines={2}
              size={FieldValue.SIZE.MEDIUM}
            />
          </FieldContainer>
          {responsive.mobile ? <Space height={12} width={8} /> : <Space style={{flex: 1}} />}
          <FieldContainer>
            <FieldValue
              label={'Phone Number'}
              value={Phone.display(_.get(form.values, `${field}.primaryContactForm.phoneNumber`))}
              empty={'-'}
              numberOfLines={1}
              size={FieldValue.SIZE.MEDIUM}
            />
          </FieldContainer>
          {responsive.mobile ? <Space height={12} width={8} /> : <Space style={{flex: 1}} />}
        </Row>
        {!responsive.mobile && <Space height={12} />}
        <Row index={1} {...responsive}>
          <FieldContainer>
            <FieldValue
              label={'Email'}
              value={_.get(form.values, `${field}.primaryContactForm.email`)}
              empty={'-'}
              numberOfLines={2}
              size={FieldValue.SIZE.MEDIUM}
            />
          </FieldContainer>
          {responsive.mobile ? <Space height={12} width={8} /> : <Space style={{flex: 1}} />}
          <FieldContainer>
            <FieldValue
              label={'Address'}
              value={_.get(form.values, `${field}.primaryLocationForm.address`)}
              empty={'-'}
              numberOfLines={2}
              size={FieldValue.SIZE.MEDIUM}
            />
          </FieldContainer>
          {responsive.mobile ? <Space height={12} width={8} /> : <Space style={{flex: 1}} />}
          <FieldContainer />
          {responsive.mobile ? <Space height={12} width={8} /> : <Space style={{flex: 1}} />}
        </Row>
      </InfoBlockContentContainer>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientInformationBlock.fragment = gql`
  ${EditStorageProjectModal.fragment}
  fragment ClientInformationBlock on Project {
    id
    ...EditStorageProjectModal
  }
`;

export default ClientInformationBlock;
