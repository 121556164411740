// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {ModalPage, Navigation} from 'modules/App/components';

const DayPage = ({side, title, subtitle, right, variables, query, onClose, children}) => (
  <ModalPage
    fetchPolicy={'network-only'}
    variables={variables}
    query={query}
    navigation={({responsive, loading, data, navigator}) => (
      <Navigation
        side={side}
        title={title({responsive, loading, data})}
        subtitle={subtitle({responsive, loading, data})}
        left={() =>
          onClose ? (
            <Navigation.CloseButton onPress={() => onClose({loading, data, navigator})} />
          ) : null
        }
        right={() => right({loading, data, navigator})}
      />
    )}
  >
    {({data, ...props}) => children({...props, data})}
  </ModalPage>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DayPage.propTypes = {
  onClose: PropTypes.func,
  side: PropTypes.func,
  title: PropTypes.func,
  subtitle: PropTypes.func,
  right: PropTypes.func,
};

DayPage.defaultProps = {
  onClose: undefined,
  side: () => 60,
  title: () => '',
  subtitle: () => '',
  right: () => null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DayPage.fragment = gql`
  fragment DayPage on Day {
    id
  }
`;

export default DayPage;
