// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

const edit = withFragment(
  (project) => ({
    projectId: (project as any).id,
    followUpDate: (project as any).followUpDate,
  }),
  gql`
    fragment ProjectFollowUpDateForm_edit on Project {
      id
      followUpDate
    }
  `,
);

const toForm = ({projectId, followUpDate}: any) => ({
  projectId,
  followUpDate: followUpDate ? Datetime.fromDate(followUpDate) : null,
});

const toMutation = ({projectId, followUpDate}: any) => ({
  projectId,
  followUpDate: followUpDate ? Datetime.toDate(followUpDate) : null,
});

const ProjectFollowUpDateForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectFollowUpDateForm;
