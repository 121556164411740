// Libararies
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import UpdateJobUserForm from '@shared/modules/User/forms/UpdateJobUserForm';
import useUpdateJobUserV2Mutation from '@shared/modules/User/hooks/useUpdateJobUserV2Mutation';
import JobUserMoverPositionPopover from 'modules/JobUser/components/JobUserMoverPositionPopover';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const JobUserMoverPositionButton = Styled.ButtonV2`
  padding: 2px 8px;
  border-radius: 4px;
`;

const JobUserMoverPositionText = Styled.Text`
  ${({vars}) => (vars.isLarge ? Typography.Responsive.Label : Typography.Responsive.MicroLabel)}
`;

// Negative margin makes it take the same space as the chevron that it
// replaces when the mutation is submitting.
const ActivityIndicator = Styled.Loading`
  marginLeft: -1px;
  marginRight: -1px;
`;

const MoverPositionBadge = ({
  isPrimary,
  isPressOnly,
  isDisabled,
  isSubmitting,
  onPress,
  color,
  name,
  style,
  responsive,
  isLarge,
}) => {
  return (
    <TextTooltip text={isPrimary ? 'This is the primary position.' : ''} placement={'top'}>
      <JobUserMoverPositionButton
        style={{backgroundColor: colors.getBackgroundColor(color), ...style}}
        onPress={onPress}
        disabled={isDisabled || isSubmitting}
      >
        <Container>
          {isPrimary && (
            <React.Fragment>
              <Icon color={color} source={Icon.Star} size={10} />
              <Space width={4} />
            </React.Fragment>
          )}
          <JobUserMoverPositionText style={{color}} responsive={responsive} vars={{isLarge}}>
            {name}
          </JobUserMoverPositionText>
          {!isPressOnly && !isDisabled && (
            <React.Fragment>
              <Space width={8} />
              {isSubmitting ? (
                <ActivityIndicator size={14} color={colors.gray.tertiary} />
              ) : (
                <Icon color={color} source={Icon.ChevronDown} size={10} />
              )}
            </React.Fragment>
          )}
        </Container>
      </JobUserMoverPositionButton>
    </TextTooltip>
  );
};

const JobUserMoverPosition = ({
  jobUser,
  refetch,
  handleOpenChildPopover,
  handleCloseChildPopover,
  isDisabled,
  isPressOnly,
  style,
  responsive,
}) => {
  const jobUserMoverPositionPopover = usePopover({name: 'Job User Mover Position Popover'});

  const updateJobUserForm = UpdateJobUserForm.new(jobUser);
  const {form, submitting, handleSubmit} = useUpdateJobUserV2Mutation({
    updateJobUserForm,
    onSuccess: () => {
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <JobUserMoverPositionPopover
      popover={jobUserMoverPositionPopover}
      jobUser={jobUser}
      form={form}
      field={'updateJobUserForm'}
      handleSubmit={handleCloseChildPopover(handleSubmit)}
    >
      <MoverPositionBadge
        isDisabled={isDisabled}
        isPressOnly={isPressOnly}
        isSubmitting={submitting}
        onPress={handleOpenChildPopover(jobUserMoverPositionPopover.handleToggle)}
        color={colors.gray.secondary}
        name={jobUser.moverPosition.name}
        style={style}
        responsive={responsive}
      />
    </JobUserMoverPositionPopover>
  );
};

JobUserMoverPosition.Badge = MoverPositionBadge;

// --------------------------------------------------
// Props
// --------------------------------------------------
JobUserMoverPosition.propTypes = {
  jobUser: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  handleOpenChildPopover: PropTypes.func,
  handleCloseChildPopover: PropTypes.func,
  style: PropTypes.object,
};

JobUserMoverPosition.defaultProps = {
  handleOpenChildPopover: (handleOpen) => {
    return () => {
      handleOpen();
    };
  },
  handleCloseChildPopover: (handleClose) => {
    return () => {
      handleClose();
    };
  },
  style: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobUserMoverPosition.fragment = gql`
  ${UpdateJobUserForm.new.fragment}
  ${JobUserMoverPositionPopover.fragment}

  fragment JobUserMoverPosition on JobUser {
    id
    moverPosition {
      id
      name
    }
    ...UpdateJobUserForm_new
    ...JobUserMoverPositionPopover
  }
`;

export default JobUserMoverPosition;
