// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useResponsive, useDrawer, useState} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Datetime, Document} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import DocumentProcess from '@shared/modules/Document/enums/DocumentProcess';
import DocumentOptionsButton from 'modules/Job/V2/Move/components/DocumentOptionsButton';
import DocumentTemplateViewer from 'modules/Job/V2/Move/components/DocumentTemplateViewer';
import DownloadDocumentButton from 'modules/Job/V2/Move/components/DownloadDocumentButton';

const Container = Styled.View`
  width: 100%;
`;

const Title = Styled.Text`
  ${Typography.Heading1}
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const PrintButtonContainer = Styled.ButtonV2`
`;

const IconContainer = Styled.View`
  align-items: center;
  justify-content: center;
`;

const LeftHeaderComponents = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const RightHeaderComponents = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label}
`;

const TableBodyText = Styled.Text`
  ${Typography.Body3}
`;

const TableBodyLink = Styled.Text`
  ${Typography.Link}
`;

const Indicator = Styled.Loading`
`;

const openPrintCompletedDocumentDialog = async ({url, setIsLoading, document}) => {
  const filename = 'document.pdf';
  url = `${window.location.origin}${url}`;
  setIsLoading(true);
  if (document.isCompleted) {
    // For completed documents, we fetch the PDF from S3 rather than generating from the live document
    const pdf = await Document.fetchPDFFromS3({filename, documentUuid: document.uuid});
    Document.printFile({file: pdf});
  } else {
    const pdf = await Document.generatePDFFromUrl({filename, url});
    Document.printFile({file: pdf});
  }
  setIsLoading(false);
};

const PrintCompletedDocumentButton = ({document, job}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isPrintingDisabled = document.isCompleted && !document.isUploaded;

  if (isLoading) {
    return (
      <IconContainer>
        <Indicator size={'small'} color={colors.gray.secondary} />
      </IconContainer>
    );
  }
  return isPrintingDisabled ? (
    <TextTooltip text={'Document failed to upload'}>
      <IconContainer>
        <Icon color={colors.gray.tertiary} size={Icon.Sizes.Large} source={Icon.Print} />
      </IconContainer>
    </TextTooltip>
  ) : (
    <PrintButtonContainer
      onPress={() => {
        const documentUrl = getDocumentLink({document, job});
        openPrintCompletedDocumentDialog({
          url: documentUrl,
          setIsLoading,
          document,
        });
      }}
    >
      <IconContainer>
        <Icon color={colors.blue.interactive} size={Icon.Sizes.Large} source={Icon.Print} />
      </IconContainer>
    </PrintButtonContainer>
  );
};

const openPrintLiveDocumentDialog = async ({url, setIsLoading}) => {
  const filename = 'document.pdf';
  url = `${window.location.origin}${url}`;
  setIsLoading(true);
  const pdf = await Document.generatePDFFromUrl({filename, url});
  Document.printFile({file: pdf});
  setIsLoading(false);
};

const PrintLiveDocumentButton = ({document, job}) => {
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return (
      <IconContainer>
        <Indicator size={'small'} color={colors.gray.secondary} />
      </IconContainer>
    );
  }
  return (
    <PrintButtonContainer
      onPress={() => {
        const documentUrl = getDocumentLink({document, job});
        openPrintLiveDocumentDialog({
          url: documentUrl,
          setIsLoading,
        });
      }}
    >
      <IconContainer>
        <Icon color={colors.blue.interactive} size={Icon.Sizes.Large} source={Icon.Print} />
      </IconContainer>
    </PrintButtonContainer>
  );
};

const getDocumentColumnDefinitions = ({job, isCompleted}) => {
  const {isEnabledShowCompletedDocuments} = job.organization.features;
  return [
    {
      flex: 3,
      headerContent: () => {
        return <TableHeaderText>Document Name</TableHeaderText>;
      },
      cellContent: ({item: document}) => {
        return (
          <a href={getDocumentLink({document, job})} target={'_blank'}>
            <TableBodyLink>{document.displayName}</TableBodyLink>
          </a>
        );
      },
    },
    {
      flex: 1,
      headerContent: () => {
        const tableHeaderText = isCompleted ? 'Completed At' : 'Created At';
        return <TableHeaderText>{tableHeaderText}</TableHeaderText>;
      },
      cellContent: ({item: document}) => {
        const datetime = isCompleted
          ? Datetime.convertToDisplayDatetime(document.updatedAt)
          : Datetime.convertToDisplayDatetime(document.createdAt);
        return <TableBodyText>{datetime}</TableBodyText>;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <TableHeaderText>Process</TableHeaderText>;
      },
      cellContent: ({item: document}) => {
        return <TableBodyText>{DocumentProcess.getDocumentProcessName(document)}</TableBodyText>;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <TableHeaderText>Actions</TableHeaderText>;
      },
      cellContent: ({item: document}) => {
        return (
          <Row>
            <DownloadDocumentButton document={document} />
            <Space width={24} />
            {isEnabledShowCompletedDocuments ? (
              <PrintCompletedDocumentButton document={document} job={job} />
            ) : (
              <PrintLiveDocumentButton document={document} job={job} />
            )}
          </Row>
        );
      },
    },
  ];
};

const getDocumentLink = ({document, job}) => {
  const documentLinkMap = {
    BILL: () => `/0/${job.organization.slug}/jobs/${job.uuid}/bill`,
    CHANGE_ORDER: () => `/0/${job.organization.slug}/jobs/${job.uuid}/change-order`,
    CONFIRMATION_LETTER: () =>
      `/0/${job.organization.slug}/projects/${job.project.uuid}/confirmation/document/print`,
    REPORT_MOVE: () => `/0/${job.organization.slug}/jobs/${job.uuid}/move-report`,
    RISKY_MOVE: () => `/0/${job.organization.slug}/jobs/${job.uuid}/risky-move`,
    DEFAULT: () => {
      // If there's no document_content_json or if the v1 document has a signature that's been completed,
      // then we need to render the v1 document. Otherwise we can safetly render the v2 doc.
      if (!document.isDocumentV2 || document.isDocumentV1Completed) {
        return `/0/${job.organization.slug}/documents/${document.uuid}`;
      }
      if (job.organization.features.isEnabledShowCompletedDocuments && document.isCompleted) {
        return `/0/${job.organization.slug}/documents/v2/${document.uuid}/pdf`;
      }
      return `/0/${job.organization.slug}/documents/v2/${document.uuid}`;
    },
  };
  return (documentLinkMap[document.template] || documentLinkMap.DEFAULT)();
};

const sortByDisplayName = (documents) => {
  return _.sortBy(documents, (document) => document.displayName);
};

const sortByUpdatedAtDate = (documents) => {
  return _.sortBy(documents, (document) => document.updatedAt);
};

const getFilteredDocuments = ({activeDocuments}) => {
  const completedDocuments = [];
  const incompleteDocuments = [];
  activeDocuments.forEach((document) => {
    if (document.isCompleted || document.isDocumentV1Completed) {
      completedDocuments.push(document);
    } else {
      incompleteDocuments.push(document);
    }
  });
  return {completedDocuments, incompleteDocuments};
};

const JobProjectDocumentsCollapsibleTables = ({job, refetch}) => {
  const responsive = useResponsive();
  const optionsPopover = usePopover();
  const documentTemplateDrawer = useDrawer({
    name: 'Document Template Drawer',
    enableTracking: true,
  });
  const {completedDocuments, incompleteDocuments} = getFilteredDocuments({
    activeDocuments: job.project.activeDocuments,
  });

  return (
    <Container>
      <Header style={{justifyContent: 'space-between'}}>
        <LeftHeaderComponents>
          <Title>Documents</Title>
          <Space width={8} />
          <DocumentOptionsButton popover={optionsPopover} job={job} refetch={refetch} />
        </LeftHeaderComponents>
        <RightHeaderComponents>
          <TertiaryButton
            text={'Add New Document'}
            iconLeft={Icon.Plus}
            onPress={documentTemplateDrawer.handleOpen}
          />
        </RightHeaderComponents>
      </Header>
      <Space height={responsive.mobile ? 8 : 16} />
      <Table.Collapsible
        title={`Completed Documents (${completedDocuments.length})`}
        columnDefinitions={getDocumentColumnDefinitions({job, isCompleted: true})}
        emptyStateText={'No completed documents'}
        items={sortByUpdatedAtDate(completedDocuments)}
        itemKey={'id'}
        defaultCollapsed={false}
      />
      <Space height={responsive.mobile ? 8 : 32} />
      <Table.Collapsible
        key={incompleteDocuments.length}
        title={`Incomplete Documents (${incompleteDocuments.length})`}
        columnDefinitions={getDocumentColumnDefinitions({job, isCompleted: false})}
        emptyStateText={'No incomplete documents'}
        items={sortByDisplayName(incompleteDocuments)}
        itemKey={'id'}
        defaultCollapsed={_.isEmpty(incompleteDocuments)}
      />
      <DocumentTemplateViewer
        key={documentTemplateDrawer.key}
        documentTemplateViewer={documentTemplateDrawer}
        jobId={job.id}
        jobUuid={job.uuid}
        project={job.project}
        refetch={refetch}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectDocumentsCollapsibleTables.fragment = gql`
  ${DocumentOptionsButton.fragment}
  ${DocumentProcess.getDocumentProcessName.fragment}
  ${DocumentTemplateViewer.fragment}
  ${DownloadDocumentButton.fragment}
  fragment JobProjectDocumentsCollapsibleTables on Job {
    id
    uuid
    project {
      id
      uuid
      activeDocuments {
        id
        createdAt
        updatedAt
        step
        uuid
        displayName
        template
        isDocumentV2
        isDocumentV1Completed
        isCompleted
        ...DownloadDocumentButton
        ...DocumentProcess_getDocumentProcessName
      }
      ...DocumentTemplateViewer
    }
    organization {
      id
      features {
        isEnabledShowCompletedDocuments: isEnabled(feature: "SHOW_COMPLETED_DOCUMENTS")
      }
    }
    ...DocumentOptionsButton
  }
`;

export default JobProjectDocumentsCollapsibleTables;
