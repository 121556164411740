// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

/**
 * Returns true if the job truck was updated within 3 minutes ago. false otherwise.
 *
 * @param {object} jobTruck
 */
const isTruckLocationRecent = withFragment(
  (jobTruck) => {
    // Get Datetime object for the latest position of the truck
    const datetime = Datetime.fromDatetime((jobTruck as any).latestPosition.timestamp);

    // Anything older than 3 minutes ago is not recent
    const isRecentThreshold = Datetime.now.subtract(3, 'minutes');

    return Datetime.isBetweenTime(datetime, isRecentThreshold, Datetime.now);
  },
  gql`
    fragment JobTruck_isTruckLocationRecent on JobTruck {
      latestPosition {
        id
        latitude
        longitude
        timestamp
      }
    }
  `,
);

const JobTruck = {
  // Getters
  isTruckLocationRecent,
};

export default JobTruck;
