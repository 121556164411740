// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import RemoveShipmentsFromTripForm from '@shared/modules/Dispatch/forms/RemoveShipmentsFromTripForm';

const useRemoveShipmentsFormTripMutation = ({
  removeShipmentsFromTripForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      removeShipmentsFromTripForm: RemoveShipmentsFromTripForm.toForm(removeShipmentsFromTripForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useRemoveShipmentsFormTripMutation.mutation,
    variables: {
      removeShipmentsFromTripForm: RemoveShipmentsFromTripForm.toMutation(
        form.values.removeShipmentsFromTripForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useRemoveShipmentsFormTripMutation.mutation = gql`
  mutation useRemoveShipmentsFormTripMutation($removeShipmentsFromTripForm: RemoveShipmentsFromTripForm!) {
    response: removeShipmentsFromTrip(removeShipmentsFromTripForm: $removeShipmentsFromTripForm) {
      ${gql.errors}
      trip {
        id
        shipments {
          id
        }
      }
    }
  }
`;

export default useRemoveShipmentsFormTripMutation;
