// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import OrganizationBusinessInfoContactForm from '@shared/modules/Organization/forms/OrganizationBusinessInfoContactForm';

const useUpdateOrganizationBusinessInfoContactMutation = ({
  organizationBusinessInfoContactForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      organizationBusinessInfoContactForm: OrganizationBusinessInfoContactForm.toForm(
        organizationBusinessInfoContactForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationBusinessInfoContactMutation.mutation,
    variables: {
      organizationBusinessInfoContactForm: OrganizationBusinessInfoContactForm.toMutation(
        form.values.organizationBusinessInfoContactForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationBusinessInfoContactMutation.mutation = gql`
  ${OrganizationBusinessInfoContactForm.edit.fragment}
  mutation useUpdateOrganizationBusinessInfoContactMutation($organizationBusinessInfoContactForm: OrganizationBusinessInfoContactForm!) {
    response: updateOrganizationBusinessInfoContact(organizationBusinessInfoContactForm: $organizationBusinessInfoContactForm) {
      ${gql.errors}
      organization {
        id
        ...OrganizationBusinessInfoContactForm_edit
      }
    }
  }
`;

export default useUpdateOrganizationBusinessInfoContactMutation;
