// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Form, Query, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState, useDebouncedCallback} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

import SearchJobItem from './SearchJobItem';

const Container = Styled.View`
  flex: 1;
  width: ${(props) => (props.mobile ? '100%' : '600px')};
  border-radius: 5px;
`;

const Input = Styled.TextInput.H6`
  padding-vertical: 25px;
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const Footer = Styled.View`
  flex: 1;
`;

const Results = Styled.View`
  flex: 1;
  margin-top: 10px;
`;

const Result = Styled.View`
  padding: 10px;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-radius: 3px;
`;

const Name = Styled.H7`
  ${fontWeight(700)}
`;

const LoadingCard = () => {
  return (
    <Results>
      <Result>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={100} />
        <Space height={10} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.SubheadingText} width={250} />
        <Space height={10} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={175} />
        <Space height={10} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={175} />
        <Space height={10} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={175} />
      </Result>
    </Results>
  );
};

const SearchResults = ({data, loading, searchQuery, handleClose}) => {
  if (searchQuery.length <= 0) {
    return null;
  }

  if (loading || !data || !data.jobs) {
    return <LoadingCard />;
  }

  return data.jobs.length > 0 ? (
    <Results pointerEvents={'box-none'}>
      <ScrollView
        style={{
          flex: 1,
        }}
      >
        {data.jobs.map((job) => (
          <SearchJobItem key={job.id} job={job} handleClose={handleClose} />
        ))}
      </ScrollView>
    </Results>
  ) : (
    <Results pointerEvents={'box-none'}>
      <Result>
        <Name>No jobs found</Name>
      </Result>
    </Results>
  );
};

const ManagerSearchField = ({organization, handleClose}) => {
  const responsive = useResponsive();

  // Determines whether to search for jobs.
  const [shouldQuery, setShouldQuery] = useState(false);
  const debouncer = useDebouncedCallback(() => setShouldQuery(true), 1000);
  return (
    <Form
      initialValues={{
        searchQuery: '',
      }}
      onSubmit={(values) => {}}
    >
      {({values, setFieldValue}) => (
        <Query
          fetchPolicy={'network-only'}
          skip={!shouldQuery}
          variables={{
            organizationId: organization.id,
            searchQuery: values.searchQuery,
          }}
          query={ManagerSearchField.query}
        >
          {({loading, data}) => {
            return (
              <Container pointerEvents={'box-none'} {...responsive}>
                <Input
                  autoFocus
                  name={'searchQuery'}
                  placeholder={
                    responsive.mobile
                      ? 'Job # or customer name/email/phone #'
                      : 'Search jobs by job #, customer name, customer email, or customer phone #'
                  }
                  value={values.searchQuery}
                  onChangeText={(text) => {
                    setShouldQuery(false);
                    setFieldValue('searchQuery', text);
                    if (text.length > 0) {
                      debouncer();
                    }
                  }}
                />
                <Footer pointerEvents={'box-none'}>
                  <SearchResults
                    data={data}
                    loading={loading}
                    searchQuery={values.searchQuery}
                    handleClose={handleClose}
                  />
                </Footer>
              </Container>
            );
          }}
        </Query>
      )}
    </Form>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ManagerSearchField.propTypes = {
  organization: PropTypes.object.isRequired,
};

ManagerSearchField.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ManagerSearchField.fragment = gql`
  fragment ManagerSearchField on User {
    id
    viewingOrganization {
      id
    }
  }
`;

ManagerSearchField.query = gql`
  ${SearchJobItem.fragment}

  query ManagerSearchField(
    $organizationId: Int!,
    $searchQuery: String!,
  ) {
    ${gql.query}
    jobs: searchJobsByQuery(
      organizationId: $organizationId,
      searchQuery: $searchQuery,
    ) {
      id
      ...SearchJobItem
    }
  }
`;

export default ManagerSearchField;
