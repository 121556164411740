// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({organizationId}: any) => ({
  organizationId,
  claimTypeId: null,
  name: '',
  color: '',
  description: '',
});

const edit = withFragment(
  (claimType) => ({
    claimTypeId: (claimType as any).id,
    organizationId: (claimType as any).organizationId,
    name: (claimType as any).name,
    color: (claimType as any).color,
    description: (claimType as any).description,
  }),
  gql`
    fragment ClaimTypeForm_edit on ClaimType {
      id
      organizationId
      name
      color
      description
    }
  `,
);

const toForm = ({claimTypeId, organizationId, name, color, description}: any) => ({
  claimTypeId,
  organizationId,
  name,
  color,
  description,
});

const toMutation = ({claimTypeId, organizationId, name, color, description}: any) => ({
  claimTypeId,
  organizationId,
  name,
  color,
  description,
});

const ClaimTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ClaimTypeForm;
