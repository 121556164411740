// Libraries
import _ from 'lodash';

// Supermove
import {generated, gql} from '@supermove/graphql';
import {existenceFilter, URL} from '@supermove/utils';

// App
import DashboardCategory from '@shared/modules/Reports/enums/DashboardCategory';
import DashboardKind from '@shared/modules/Reports/enums/DashboardKind';

const edit = (dashboard: generated.DashboardFormEditFragment) => ({
  dashboardId: dashboard.id,
  uuid: dashboard.uuid,
  name: dashboard.name,
  organizationId: dashboard.organizationId,
  kind: dashboard.kind,
  category: dashboard.category,
  exploDashboardEmbedId: dashboard.exploDashboardEmbedId,
  exploVariables: dashboard.exploVariables,
  index: dashboard.index,
  dashboardCategoryId: dashboard.dashboardCategoryId,
  dashboardTags: dashboard.dashboardTags,
  description: dashboard.description,
  helpArticleUrl: dashboard.helpArticleUrl,
});

const _new = ({
  organizationId,
  name,
  exploDashboardEmbedId,
  exploVariables,
}: {
  organizationId: string;
  name: string;
  exploDashboardEmbedId: string;
  exploVariables: string;
}) => ({
  dashboardId: null,
  organizationId,
  kind: DashboardKind.EXPLO,
  category: DashboardCategory.GENERAL,
  dashboardCategoryId: null,
  name: name || '',
  exploDashboardEmbedId: exploDashboardEmbedId || '',
  exploVariables: exploVariables || null,
  index: null,
  uuid: null,
  description: null,
  helpArticleUrl: null,
  dashboardTags: [],
});

const toForm = (
  dashboard: ReturnType<typeof edit> | ReturnType<typeof _new>,
): generated.DashboardForm => ({
  dashboardId: dashboard.dashboardId ? _.toNumber(dashboard.dashboardId) : undefined,
  organizationId: _.toNumber(dashboard.organizationId),
  kind: dashboard.kind,
  category: dashboard.category,
  name: dashboard.name,
  description: dashboard.description,
  helpArticleUrl: dashboard.helpArticleUrl,
  exploDashboardEmbedId: dashboard.exploDashboardEmbedId,
  exploVariables: dashboard.exploVariables,
  index: dashboard.index,
  dashboardCategoryId: dashboard.dashboardCategoryId,
  tagIds:
    dashboard.dashboardTags
      ?.filter(existenceFilter)
      .map((dt) => dt?.tag?.id)
      .filter(existenceFilter)
      .map(_.toNumber) || [],
});

const toMutation = (dashboard: generated.DashboardForm): generated.DashboardForm => {
  const helpArticleUrl = dashboard.helpArticleUrl?.trim();
  return {
    organizationId: dashboard.organizationId,
    kind: dashboard.kind,
    category: dashboard.category,
    name: dashboard.name,
    exploDashboardEmbedId: dashboard.exploDashboardEmbedId,
    exploVariables:
      dashboard.exploVariables && dashboard.exploVariables.trim() ? dashboard.exploVariables : null,
    dashboardId: dashboard.dashboardId,
    index: dashboard.index,
    dashboardCategoryId: dashboard.dashboardCategoryId,
    tagIds: dashboard.tagIds,
    description: dashboard.description,
    helpArticleUrl: helpArticleUrl ? URL.prependHttp(helpArticleUrl) : null,
  };
};

export const DashboardFormEditFragment = gql`
  fragment DashboardFormEdit on Dashboard {
    id
    organizationId
    kind
    category
    name
    exploDashboardEmbedId
    exploVariables
    index
    uuid
    dashboardCategoryId
    globalDashboardId
    dashboardTags {
      id
      tag {
        id
        name
      }
    }
    description
    helpArticleUrl
  }
`;

const DashboardForm = {
  new: _new,
  toForm,
  toMutation,
  edit,
};

export default DashboardForm;
