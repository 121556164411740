// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Currency, Phone, Datetime} from '@supermove/utils';

// App
import InvoicePaymentTerm from '@shared/modules/Billing/enums/InvoicePaymentTerm';
import InvoiceSectionsContent from 'modules/Customer/Invoice/components/InvoiceSectionsContent';
import DocumentHeaderOrganization from 'modules/Document/components/DocumentHeaderOrganization';

const Container = Styled.View`
`;

const Content = Styled.View`
  width: 100%;
  background-color: ${colors.white};
`;

const Section = Styled.View`
  align-self: stretch;
  padding-horizontal: 24px;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const Title = Styled.Text`
  ${Typography.Heading2}
`;

const Subtitle = Styled.Text`
  ${Typography.Subheading}
`;

const Text = Styled.Text`
  ${Typography.Body}
  color: ${(props) => props.color};
`;

const ItalicizedText = Styled.Text`
  ${Typography.Body}
  font-style: italic;
  color: ${colors.gray.tertiary};
`;

const PaymentsText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Label = Styled.Text`
  ${Typography.Label}
  color: ${colors.gray.secondary};
`;

const BlackLabel = Styled.Text`
  ${Typography.Label}
  text-align: right;
`;

const Line = Styled.View`
  border-top-width: 1px;
  border-style: ${(props) => (props.dashed ? 'dashed' : 'solid')};
  border-color: ${colors.gray.border};
  flex: 1;
`;

const InvoiceTotalSectionContainer = Styled.View`
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  background-color: ${(props) => props.backgroundColor};
`;

const InvoiceTotalContainer = Styled.View`
  flex-direction: row;
  justify-content: ${(props) => (props.isPostSubtotal ? 'flex-end' : 'flex-start')};
`;

const PaymentRow = Styled.View`
  flex-direction: row;
`;

const CustomerInformationSection = ({primaryContact, isBillToSection}) => {
  return (
    <React.Fragment>
      <Label>{isBillToSection ? 'Bill To' : 'Customer Information'}</Label>
      <Space height={4} />
      <Text>{`${primaryContact.fullName}`}</Text>
      <Text>{`${Phone.display(primaryContact.phoneNumber)}`}</Text>
      <Text>{`${primaryContact.email}`}</Text>
    </React.Fragment>
  );
};

const BillToSection = ({billingClient}) => {
  return (
    <React.Fragment>
      <Label>Bill To</Label>
      <Space height={4} />
      <Text>{`${billingClient.name}`}</Text>
      {billingClient.name !== billingClient.primaryContact.fullName && (
        <Text>{`${billingClient.primaryContact.fullName}`}</Text>
      )}
      {billingClient.primaryContact.phoneNumber && (
        <Text>{`${Phone.display(billingClient.primaryContact.phoneNumber)}`}</Text>
      )}
      <Text>{`${billingClient.primaryContact.email}`}</Text>
      {billingClient.primaryLocation && <Text>{`${billingClient.primaryLocation.address}`}</Text>}
    </React.Fragment>
  );
};

const ProjectDetailsSection = ({project}) => {
  return (
    <React.Fragment>
      <Label>Project Details</Label>
      <Space height={4} />
      <Text>
        Project Type:{' '}
        {project.projectType.name ? (
          `${project.projectType.name}`
        ) : (
          <ItalicizedText>None</ItalicizedText>
        )}
      </Text>
      <Text>
        Date In:{' '}
        {project.startDate ? (
          `${Datetime.convertToDisplayDate(project.startDate, Datetime.DISPLAY_SHORT_DATE)}`
        ) : (
          <ItalicizedText>None</ItalicizedText>
        )}
      </Text>
      <Text>
        Date Out:{' '}
        {project.endDate ? (
          `${Datetime.convertToDisplayDate(project.endDate, Datetime.DISPLAY_SHORT_DATE)}`
        ) : (
          <ItalicizedText>None</ItalicizedText>
        )}
      </Text>
    </React.Fragment>
  );
};

const InvoiceInformationSection = ({invoice}) => {
  return (
    <React.Fragment>
      <Label>Invoice Information</Label>
      <Space height={4} />
      <Text>
        Invoice Date:{' '}
        {`${Datetime.convertToDisplayDate(invoice.date, Datetime.DISPLAY_SHORT_DATE)}`}
      </Text>
      <Text>Payment Terms: {`${InvoicePaymentTerm.getDisplayName(invoice.paymentTerm)}`}</Text>
      <Text>
        Due Date:{' '}
        {invoice.dueDate ? (
          `${Datetime.convertToDisplayDate(invoice.dueDate, Datetime.DISPLAY_SHORT_DATE)}`
        ) : (
          <ItalicizedText>None</ItalicizedText>
        )}
      </Text>
    </React.Fragment>
  );
};

const PaymentItem = ({payment}) => {
  return (
    <PaymentRow>
      <Text style={{flex: 1, textAlign: 'left'}}>{`${payment.name}`}</Text>
      <Text color={colors.gray.secondary} style={{flex: 1, textAlign: 'center'}}>
        {payment.displayMethod}
      </Text>
      <Text style={{flex: 1, textAlign: 'right'}}>{`${Currency.display(
        payment.totalAmount,
      )}`}</Text>
    </PaymentRow>
  );
};

const PaymentsSection = ({invoice}) => {
  return (
    <React.Fragment>
      <Section sectionIndex={5}>
        <Subtitle>Payments</Subtitle>
        <Space height={8} />
        <Line />
        {invoice.payments.length > 0 ? (
          invoice.payments.map((payment, index) => {
            return (
              <React.Fragment key={index}>
                <Space height={8} />
                <PaymentItem payment={payment} />
              </React.Fragment>
            );
          })
        ) : (
          <React.Fragment>
            <Space height={8} />
            <PaymentsText>No payments have been recorded for this invoice</PaymentsText>
          </React.Fragment>
        )}
        <Space height={8} />
        <Line dashed />
        <Space height={8} />
        <BlackLabel>
          Remaining Balance <Space width={60} /> {`${Currency.display(invoice.remainingBalance)}`}
        </BlackLabel>
      </Section>
      <Space height={24} />
    </React.Fragment>
  );
};

const InvoiceSection = ({invoice}) => {
  return (
    <Container>
      <Content>
        <Space height={24} />
        {!!invoice.organization.logoUri && (
          <Section sectionIndex={0}>
            <DocumentHeaderOrganization
              shouldShowBusinessLicense={false}
              organization={invoice.organization}
            />
            <Space height={24} />
          </Section>
        )}
        <Section sectionIndex={1}>
          <Title>{`Invoice ${invoice.identifier}`}</Title>
          <Space height={24} />
          <Line />
        </Section>
        <Space height={24} />
        <Section sectionIndex={2}>
          <CustomerInformationSection primaryContact={invoice.project.client.primaryContact} />
          <Space height={24} />
          <BillToSection billingClient={invoice.project.billingClient} />
          <Space height={24} />
          <ProjectDetailsSection project={invoice.project} />
          <Space height={24} />
          <InvoiceInformationSection invoice={invoice} />
        </Section>
        <Space height={24} />
        <Section sectionIndex={3}>
          <InvoiceSectionsContent invoiceSections={invoice.invoiceSections} />
        </Section>
        <Space height={24} />
        <Section sectionIndex={4}>
          <InvoiceTotalSectionContainer>
            <InvoiceTotalContainer isPostSubtotal>
              <Text style={{fontWeight: 'bold'}}>Grand Total</Text>
              <Space width={16} />
              <Text style={{fontWeight: 'bold'}}>{`${Currency.display(invoice.grandTotal)}`}</Text>
            </InvoiceTotalContainer>
          </InvoiceTotalSectionContainer>
        </Section>
        <Space height={24} />
        <PaymentsSection invoice={invoice} />
      </Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceSection.fragment = gql`
  ${DocumentHeaderOrganization.fragment}
  ${InvoiceSectionsContent.fragment}

  fragment InvoiceSection on Invoice {
    id
    identifier
    date
    paymentTerm
    grandTotal
    dueDate
    remainingBalance
    payments {
      id
      name
      displayMethod
      totalAmount
    }
    invoiceSections {
      id
      ...InvoiceSectionsContent
    }
    project {
      id
      projectType {
        id
        name
      }
      startDate
      endDate
      billingClient {
        id
        name
        primaryContact {
          id
          fullName
          phoneNumber
          email
        }
        primaryLocation {
          id
          address
        }
      }
      client {
        id
        primaryContact {
          id
          fullName
          phoneNumber
          email
        }
      }
    }
    organization {
      id
      name
      logoUri
      ...DocumentHeaderOrganization
    }
  }
`;

export default InvoiceSection;
