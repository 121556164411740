// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {Device} from '@supermove/sdk';
import {Typography, colors} from '@supermove/styles';

// App
import EmptyState from '@shared/design/components/EmptyState';
import ErrorBoundary from '@shared/modules/Error/util/ErrorBoundary';

interface ErrorCatcherProps {
  children: React.ReactNode;
  ErrorComponent?: () => React.ReactNode;
  onError?: () => void;
}

const LinkButton = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const DefaultError: () => React.ReactNode = () => (
  <EmptyState.ContentContainer>
    <EmptyState
      icon={Icon.ExclamationTriangle}
      iconColor={colors.red.warning}
      title={'An error has occurred.'}
      MessageComponent={() => (
        <EmptyState.DescriptionText style={{textAlign: 'center'}}>
          {`There was an error retrieving data. Check your connection and `}
          <LinkButton onPress={() => Device.restartApp()}>
            <LinkText>reload</LinkText>
          </LinkButton>
          {` the page .`}
        </EmptyState.DescriptionText>
      )}
    />
  </EmptyState.ContentContainer>
);

const ErrorCatcher: React.FC<ErrorCatcherProps> = ({
  onError = () => {},
  ErrorComponent = DefaultError,
  children,
}) => (
  <ErrorBoundary onError={onError} FallbackComponent={ErrorComponent}>
    {children}
  </ErrorBoundary>
);

export default ErrorCatcher;
