// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import CreateTruckForm from 'modules/Organization/Settings/Dispatch/Trucks/components/CreateTruckForm';

const CreateTruckModal = ({isOpen, onClose, refetch, viewer}) => {
  return (
    <SmallModal isOpen={isOpen}>
      <SmallModal.BoldText>{`Add Truck`}</SmallModal.BoldText>
      <Space height={16} />
      <SmallModal.Text>{`Add a new truck to your fleet.`}</SmallModal.Text>
      <Space height={16} />
      <CreateTruckForm handleClose={onClose} refetch={refetch} viewer={viewer} />
    </SmallModal>
  );
};

export default CreateTruckModal;
