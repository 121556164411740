// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';

// App
import AccountingPageHeader from 'modules/Accounting/components/AccountingPageHeader';
import ListInvoicesPageContent from 'modules/Accounting/components/ListInvoicesPageContent';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';

const Container = Styled.View`
  flex: 1;
`;

const ListInvoicesPage = () => {
  const {
    navigator: {location},
  } = useNavigationDOM();
  const projectTypeCategory = _.last(location.pathname.split('/'));

  const {loading, data} = useQuery(ListInvoicesPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={'accounting'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <SidebarPageV2 selected={'accounting'}>
      <Container>
        <AccountingPageHeader organization={data.viewer.viewingOrganization} />
        <ListInvoicesPageContent
          organization={data.viewer.viewingOrganization}
          projectTypeCategory={projectTypeCategory}
        />
      </Container>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListInvoicesPage.query = gql`
  ${AccountingPageHeader.fragment}
  ${ListInvoicesPageContent.fragment}

  query ListInvoicesPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...AccountingPageHeader
        ...ListInvoicesPageContent
      }
    }
  }
`;

export default ListInvoicesPage;
