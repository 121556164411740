// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Bill} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import BillRuleKind from '@shared/modules/Billing/enums/BillRuleKind';
import BillsListItemBillRulesList from 'modules/Project/Billing/components/BillsListItemBillRulesList';
import BillsListItemHeader from 'modules/Project/Billing/components/BillsListItemHeader';
import BillsListItemPreSubtotalBillItemsList from 'modules/Project/Billing/components/BillsListItemPreSubtotalBillItemsList';
import BillsListItemSubtotalSection from 'modules/Project/Billing/components/BillsListItemSubtotalSection';
import EditBillLineItemCells from 'modules/Project/Billing/components/EditBillLineItemCells';

const Row = Styled.View`
  flex-direction: row;
`;

const SplitRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  border-style: ${(props) => (props.dashed ? 'dashed' : 'solid')};
`;

const BillsListItemContainer = Styled.View`
  flex-direction: row;
  border-color: ${colors.blue.interactive};
`;

const BillInformationContainer = Styled.View`
  padding: ${(props) => (props.isEditable ? '12px' : '0px')};
  border-color: ${colors.gray.border};
  border-width: ${(props) => (props.isEditable ? '1px' : '0px')};
  border-radius: 4px;
  flex: 1;
`;

const BillDataContainer = Styled.View`
  align-items: flex-end;
  flex: ${(props) => props.flex};
`;

const AlignRightColumn = Styled.View`
  flex: 8;
  align-items: flex-end;
`;

const BillTotalText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const MicroLabelText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const handleChangeOrder = ({form, field, fromIndex, toIndex, handleSubmit}) => {
  const billForms = _.get(form.values, field);
  const reorderedBillForms = List.move({list: billForms, fromIndex, toIndex});
  reorderedBillForms.forEach((billForm, index) => {
    billForm.index = index;
  });
  form.setFieldValue(field, reorderedBillForms);

  // The setTimeout ensures that form.setFieldValue completes before running handleSubmit
  setTimeout(() => handleSubmit(), 0);
};

const ReorderButtonsColumn = ({form, field, index, handleSubmit}) => {
  const isFirstItem = index === 0;
  const isLastItem = index === _.get(form.values, field).length - 1;
  const handlePress = ({fromIndex, toIndex}) => {
    handleChangeOrder({form, field, fromIndex, toIndex, handleSubmit});
  };

  return (
    <EditBillLineItemCells.IconCell justify={'center'}>
      <EditBillLineItemCells.IconCircle
        activeOpacity={0.8}
        disabled={isFirstItem}
        onPress={() => handlePress({fromIndex: index, toIndex: index - 1})}
      >
        <Icon
          source={Icon.ArrowUp}
          size={12}
          color={isFirstItem ? colors.gray.border : colors.blue.interactive}
        />
      </EditBillLineItemCells.IconCircle>
      <Space height={2} />
      <EditBillLineItemCells.IconCircle
        activeOpacity={0.8}
        disabled={isLastItem}
        onPress={() => handlePress({fromIndex: index, toIndex: index + 1})}
      >
        <Icon
          source={Icon.ArrowDown}
          size={12}
          color={isLastItem ? colors.gray.border : colors.blue.interactive}
        />
      </EditBillLineItemCells.IconCircle>
    </EditBillLineItemCells.IconCell>
  );
};

const BillTotal = ({bill}) => {
  const responsive = useResponsive();
  const billTotal = bill.isEstimateAvailable ? Bill.getEstimateTotal(bill) : 'TBD';
  if (responsive.mobile) {
    return (
      <SplitRow>
        <LabelText responsive={responsive}>Total</LabelText>
        <MicroLabelText style={{color: colors.gray.primary}} responsive={responsive}>
          {billTotal}
        </MicroLabelText>
      </SplitRow>
    );
  }
  return (
    <Row>
      <AlignRightColumn>
        <BillTotalText responsive={responsive}>{`Total${
          bill.title ? ` ${bill.title}` : ''
        }`}</BillTotalText>
      </AlignRightColumn>
      <BillDataContainer flex={3}>
        <BillTotalText responsive={responsive}>{billTotal}</BillTotalText>
      </BillDataContainer>
    </Row>
  );
};

const BillsListItem = ({
  index,
  bill,
  isDocument,
  isEditable,
  isReordering,
  isCollapsed,
  showQuantity,
  showTotal,
  showBillRules,
  showEmptyTotals,
  refetch,
  pageRefetch,
  reorderBillsForm,
  handleSubmit,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  isEnabledConditionalBillItems,
}) => {
  const hasTip = bill.tip > 0;
  const hasSalesTax = bill.estimateSalesTaxAmountMin > 0 || bill.estimateSalesTaxAmountMax > 0;
  const showSubtotal = bill.hasBillModifiers || hasTip || hasSalesTax;
  const conditionalBillItemIdToBillRuleName = _.reduce(
    _.filter(bill.billRules, (billRule) => billRule.kind === BillRuleKind.CONDITIONAL_BILL_ITEM),
    (result, billRule) => {
      result[_.toString(billRule.billItemId)] = billRule.name;
      return result;
    },
    {},
  );

  return (
    <React.Fragment>
      <BillsListItemContainer>
        {isReordering && (
          <React.Fragment>
            <ReorderButtonsColumn
              form={reorderBillsForm}
              field={'reorderBillsForm.reorderBillForms'}
              index={index}
              handleSubmit={handleSubmit}
            />
            <Space width={8} />
          </React.Fragment>
        )}
        <BillInformationContainer isEditable={isEditable}>
          <BillsListItemHeader
            bill={bill}
            refetch={refetch}
            pageRefetch={pageRefetch}
            isEditable={isEditable}
            showTotal={showTotal}
            showQuantity={showQuantity}
          />
          <Space height={8} />
          {!isCollapsed && (
            <React.Fragment>
              <Line />
              <Space height={8} />
              <BillsListItemPreSubtotalBillItemsList
                bill={bill}
                refetch={refetch}
                isDocument={isDocument}
                isEditable={isEditable}
                showQuantity={showQuantity}
                showTotal={showTotal}
                descriptionField={descriptionField}
                getTruncatedKey={getTruncatedKey}
                truncated={truncated}
                setTruncated={setTruncated}
                enabledToggle={enabledToggle}
                setEnabledToggle={setEnabledToggle}
                conditionalBillItemIdToBillRuleName={conditionalBillItemIdToBillRuleName}
                isEnabledConditionalBillItems={isEnabledConditionalBillItems}
              />
              <Space height={8} />
            </React.Fragment>
          )}
          <Line dashed={isEditable} />
          {showSubtotal && !isCollapsed && (
            <BillsListItemSubtotalSection
              bill={bill}
              isEditable={isEditable}
              conditionalBillItemIdToBillRuleName={conditionalBillItemIdToBillRuleName}
              isEnabledConditionalBillItems={isEnabledConditionalBillItems}
            />
          )}
          {showTotal && (showEmptyTotals || bill.isEstimateAvailable) && (
            <React.Fragment>
              <Space height={isEditable ? 12 : 8} />
              <BillTotal bill={bill} />
            </React.Fragment>
          )}
          {(showBillRules || isEditable) && bill.hasBillRules && !isCollapsed && (
            <BillsListItemBillRulesList
              bill={bill}
              isEditable={isEditable}
              isEnabledConditionalBillItems={isEnabledConditionalBillItems}
            />
          )}
        </BillInformationContainer>
      </BillsListItemContainer>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillsListItem.fragment = gql`
  ${Bill.getEstimateTotal.fragment}
  ${BillsListItemBillRulesList.fragment}
  ${BillsListItemHeader.fragment}
  ${BillsListItemPreSubtotalBillItemsList.fragment}
  ${BillsListItemSubtotalSection.fragment}

  fragment BillsListItem on Bill {
    id
    uuid
    title
    isEstimateAvailable
    tip
    estimateSalesTaxAmountMin
    estimateSalesTaxAmountMax
    hasBillModifiers
    hasBillRules
    index
    billRules {
      name
      billItemId
    }
    project {
      id
      organization {
        id
        features {
          isEnabledProjectBillingEditMobileView: isEnabled(
            feature: "PROJECT_BILLING_EDIT_MOBILE_VIEW"
          )
        }
      }
    }
    ...Bill_getEstimateTotal
    ...BillsListItemBillRulesList
    ...BillsListItemHeader
    ...BillsListItemPreSubtotalBillItemsList
    ...BillsListItemSubtotalSection
  }
`;

export default BillsListItem;
