// App
import {AttachmentKindModel} from '@supermove/models';
import {uuid} from '@supermove/utils';

import CompleteUploadFileForm, {
  CompleteUploadFileFormType,
} from '@shared/modules/File/forms/CompleteUploadFileForm';
import CreateAttachmentForm, {
  CreateAttachmentFormType,
} from '@shared/modules/File/forms/CreateAttachmentForm';
import RequestUploadFileForm, {
  RequestUploadFileFormType,
} from '@shared/modules/File/forms/RequestUploadFileForm';

export interface UploadFileFormTypeWrapper {
  uploadFileForm: UploadFileFormType;
}

export interface UploadFileFormType {
  requestUploadFileForm: RequestUploadFileFormType;
  completeUploadFileForm: CompleteUploadFileFormType;
  createAttachmentForm: CreateAttachmentFormType;
  file?: File | null;
  url?: string | null;
  fields?: any;

  // private
  error?: any;
  attemptedUpload?: boolean;
  _id?: string; // Used to identify and index while it's uploading
}

export interface NewUploadFileFormType {
  organizationId: string;
  creatorId: string;
  projectId?: string;
  jobId?: string;
  inventoryId?: string;
  roomId?: string;
  attachmentCategoryKinds?: AttachmentKindModel[];
  _id?: string;
}

const _new = ({
  organizationId,
  creatorId,
  projectId,
  jobId,
  inventoryId,
  roomId,
  attachmentCategoryKinds,
  _id = uuid(),
}: NewUploadFileFormType): UploadFileFormType => ({
  requestUploadFileForm: RequestUploadFileForm.new({
    organizationId,
    creatorId,
  }),
  completeUploadFileForm: CompleteUploadFileForm.new(),
  createAttachmentForm: CreateAttachmentForm.new({
    projectId,
    jobId,
    inventoryId,
    roomId,
    attachmentCategoryKinds,
    description: '',
  }),
  file: null,
  url: null,
  fields: null,
  _id,
});

const toForm = ({
  requestUploadFileForm,
  completeUploadFileForm,
  createAttachmentForm,
  file,
  url,
  fields,
  error,
  attemptedUpload,
  _id,
}: UploadFileFormType) => ({
  requestUploadFileForm: RequestUploadFileForm.toForm(requestUploadFileForm),
  completeUploadFileForm: CompleteUploadFileForm.toForm(completeUploadFileForm),
  createAttachmentForm: CreateAttachmentForm.toForm(createAttachmentForm),
  file,
  url,
  fields,
  error,
  attemptedUpload,
  _id,
});

// Our URL encoding doesn't handle spaces well, so replace with underscores
const formatName = (name: string = '') => {
  return name.replace(/\s/g, '_');
};

const UploadFileForm = {
  new: _new,
  toForm,
  formatName,
  // This form uses RequestUploadFileForm and CompleteUploadFileForm for toMutation
};

export default UploadFileForm;
