// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

const getMutation = ({response}: any) => {
  switch (response) {
    case 'DOCUMENT_NAVIGATION':
      return useFinalizePaymentMutation.documentNavigationMutation;
    default:
      return useFinalizePaymentMutation.mutation;
  }
};

const useFinalizePaymentMutation = ({jobId, onSuccess, onError, response}: any) => {
  const form = useForm({
    initialValues: {
      jobId: jobId || null,
      paymentId: '',
      paymentMethodToken: null,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: getMutation({response}),
    variables: {
      jobId: form.values.jobId,
      paymentId: form.values.paymentId,
      paymentMethodToken: form.values.paymentMethodToken,
      timestamp: Datetime.toTimestamp(Datetime.now),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useFinalizePaymentMutation.mutation = gql`
  mutation useFinalizePaymentMutation(
    $jobId: Int,
    $paymentId: Int!,
    $paymentMethodToken: String,
    $timestamp: String!,
  ) {
    response: finalizePayment(
      jobId: $jobId,
      paymentId: $paymentId,
      paymentMethodToken: $paymentMethodToken,
      timestamp: $timestamp,
    ) {
      ${gql.errors}
      payment {
        id
      }
    }
  }
`;

// Any changes in the response payment data should also reflect in crew Navigation
useFinalizePaymentMutation.documentNavigationMutation = gql`
  mutation useFinalizePaymentMutation(
    $jobId: Int,
    $paymentId: Int!,
    $paymentMethodToken: String,
    $timestamp: String!,
  ) {
    response: finalizePayment(
      jobId: $jobId,
      paymentId: $paymentId,
      paymentMethodToken: $paymentMethodToken,
      timestamp: $timestamp,
    ) {
      ${gql.errors}
      payment {
        id
        bill {
          id
          total
          isComplete
          customer {
            id
            firstName
            lastName
          }
          document {
            id
          }
          project {
            id
            activeMoveJobs {
              id
              uuid
              hasJobEventPostMovePaymentsFinished: hasJobEvent(kind: "POST_MOVE_PAYMENTS_FINISHED")
              id
              uuid
              isLastActiveJob
              hasJobEventPostMovePaymentsFinished: hasJobEvent(kind: "POST_MOVE_PAYMENTS_FINISHED")
              hasJobStepCrewPostMoveBilling: hasJobStep(kind: "CREW_POST_MOVE_BILLING")
              move {
                id
                bill {
                  id
                  uuid
                  isComplete
                }
              }
              documentsV2PostMove: documentsV2ForStep(step: "POST_MOVE") {
                id
                uuid
                isCompleted
              }
            }
            projectType {
              id
              isJobLevelBilling
              features {
                timesheetsV2
              }
            }
          }
        }
      }
    }
  }
`;

export default useFinalizePaymentMutation;
