// Libraries
import React from 'react';

// App
import {Link, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex-direction: row;
  border-color: ${colors.gray.border};
  border-top-width: 1px;
  border-bottom-width: 1px;
`;

const Tab = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${({vars}) => (vars.isSelected ? colors.gray.background : colors.white)};
  border-color: ${colors.gray.border};
  border-left-width: ${({vars}) => (vars.isFirst ? 0 : 1)}px;
`;

const Name = Styled.H6`
  color: ${colors.gray.primary};
`;

const JobTabs = ({selected, job}) => (
  <Container>
    <Link replace to={`/1/jobs/${job.uuid}/details`} style={{flex: 1}}>
      <Tab
        vars={{
          isSelected: selected === 'details',
        }}
      >
        <Name>Details</Name>
      </Tab>
    </Link>
    {!job.organization.features.isEnabledMoverAppShowLimitedJobDetails && (
      <Link replace to={`/1/jobs/${job.uuid}/crew`} style={{flex: 1}}>
        <Tab
          vars={{
            isFirst: true,
            isSelected: selected === 'crew',
          }}
        >
          <Name>Crew</Name>
        </Tab>
      </Link>
    )}
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTabs.fragment = gql`
  fragment JobTabs on Job {
    id
    uuid
    organization {
      id
      features {
        isEnabledMoverAppShowLimitedJobDetails: isEnabled(
          feature: "MOVER_APP_SHOW_LIMITED_JOB_DETAILS"
        )
      }
    }
  }
`;

export default JobTabs;
