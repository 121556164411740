// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import BillItemTypeForm from '@shared/modules/Billing/forms/BillItemTypeForm';

const useCreateBillItemTypeMutation = ({billItemTypeForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      billItemTypeForm: BillItemTypeForm.toForm(billItemTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateBillItemTypeMutation.mutation,
    variables: {
      billItemTypeForm: BillItemTypeForm.toMutation(form.values.billItemTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateBillItemTypeMutation.mutation = gql`
  mutation useCreateBillItemTypeMutation($billItemTypeForm: BillItemTypeForm!) {
    response: createBillItemType(billItemTypeForm: $billItemTypeForm) {
      ${gql.errors}
      billItemType {
        id
      }
    }
  }
`;

export default useCreateBillItemTypeMutation;
