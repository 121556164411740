// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import BetaBadge from '@shared/design/components/BetaBadge';
import Line from 'modules/App/components/Line';
import OfficeHeader, {OfficeHeaderBuilder} from 'modules/App/components/OfficeHeader';

const Container = Styled.View`
  z-index: 100;
`;

const BackButtonContainer = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const BackButtonText = Styled.Text`
  ${Typography.Label}
  color: ${colors.gray.primary};
`;

const BackButton = () => {
  const {navigator} = useNavigationDOM();
  return (
    <BackButtonContainer onPress={() => navigator.goBack()}>
      <Icon source={Icon.ArrowLeft} size={15} color={colors.gray.primary} />
      <Space width={8} />
      <BackButtonText>Back</BackButtonText>
    </BackButtonContainer>
  );
};

const DesktopOfficeHeader = ({
  title,
  children,
  officeHeaderStyle,
  isBeta,
}: {
  title: string;
  children?: React.ReactNode;
  officeHeaderStyle?: React.CSSProperties;
  isBeta?: boolean;
}) => {
  return (
    <OfficeHeader style={officeHeaderStyle}>
      <OfficeHeaderBuilder.Content>
        <OfficeHeaderBuilder.Title>{title}</OfficeHeaderBuilder.Title>
        {isBeta && (
          <React.Fragment>
            <Space width={8} />
            <BetaBadge />
          </React.Fragment>
        )}
      </OfficeHeaderBuilder.Content>
      {children || <OfficeHeaderBuilder.Content style={{flex: 1}} />}
    </OfficeHeader>
  );
};

const StandardOfficeHeader = ({
  title,
  showLine,
  children,
  officeHeaderStyle,
  isBeta,
}: {
  title: string;
  showLine?: boolean;
  children?: React.ReactNode;
  officeHeaderStyle?: React.CSSProperties;
  isBeta?: boolean;
}) => {
  const responsive = useResponsive();

  return responsive.mobile ? (
    <Container>
      {!!children && (
        <React.Fragment>
          <Space height={12} />
          <Container style={{flexDirection: 'row'}}>{children}</Container>
          <Space height={12} />
        </React.Fragment>
      )}
    </Container>
  ) : (
    <React.Fragment>
      <Space height={16} />
      <DesktopOfficeHeader title={title} officeHeaderStyle={officeHeaderStyle} isBeta={isBeta}>
        {children}
      </DesktopOfficeHeader>
      {showLine ? (
        <React.Fragment>
          <Space height={16} />
          <Line />
        </React.Fragment>
      ) : (
        <Space height={12} />
      )}
    </React.Fragment>
  );
};

StandardOfficeHeader.BackButton = BackButton;

// --------------------------------------------------
// Props
// --------------------------------------------------
StandardOfficeHeader.propTypes = {
  title: PropTypes.string.isRequired,
  showLine: PropTypes.bool,
  officeHeaderStyle: PropTypes.object,
};

StandardOfficeHeader.defaultProps = {
  showLine: true,
  officeHeaderStyle: {marginHorizontal: '24px'},
};

export default StandardOfficeHeader;
