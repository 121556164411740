// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DragAndDropList, Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useDragAndDrop} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import LocationTypeForm from '@shared/modules/Organization/forms/LocationTypeForm';
import useUpdateOrganizationLocationTypesMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationLocationTypesMutation';
import CreateLocationTypeModal from 'modules/Organization/Settings/Moves/components/CreateLocationTypeModal';
import LocationTypeItem from 'modules/Organization/Settings/Moves/components/LocationTypeItem';

const SectionHeaderText = Styled.Text`
  ${Typography.Label1}
`;

const onReorder = ({form, fromIndex, toIndex, handleSubmit}) => {
  const locationTypeForms = _.cloneDeep(
    form.values.organizationLocationTypesForm.locationTypeForms,
  );
  const reorderedLocationTypeForms = List.move({list: locationTypeForms, fromIndex, toIndex});
  form.setFieldValue('organizationLocationTypesForm.locationTypeForms', reorderedLocationTypeForms);
  setTimeout(handleSubmit, 0);
};

const LocationTypesContent = ({organization, refetch, locationTypes}) => {
  const createLocationTypeModal = useModal({name: 'Create Location Type Modal'});
  const locationTypeForms = locationTypes.map((locationType) =>
    LocationTypeForm.edit(locationType),
  );
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();

  const {form, handleSubmit} = useUpdateOrganizationLocationTypesMutation({
    organizationLocationTypesForm: {
      organizationId: organization.id,
      locationTypeForms,
    },
    onSuccess: () => {
      refetch();
      handleReorderEnd();
    },
    onError: () => {
      handleReorderEnd();
    },
  });

  return (
    <React.Fragment>
      <Button
        iconLeft={Icon.Plus}
        text={'Add Location Type'}
        onPress={createLocationTypeModal.handleOpen}
      />
      <Space height={24} />
      <SectionHeaderText>{`Location Types (${locationTypes.length})`}</SectionHeaderText>
      <Space height={16} />
      <DragAndDropList
        isReordering={isReordering}
        onReorder={({fromIndex, toIndex}) => {
          handleReorderStart();
          onReorder({
            form,
            fromIndex,
            toIndex,
            handleSubmit,
          });
        }}
      >
        {form.values.organizationLocationTypesForm.locationTypeForms.map(
          (locationTypeForm, index) => {
            return (
              <LocationTypeItem
                key={locationTypeForm.name}
                organization={organization}
                locationTypeForm={locationTypeForm}
                refetch={refetch}
                index={index}
                isFirstItem={index === 0}
                isLastItem={index === locationTypeForms.length - 1}
              />
            );
          },
        )}
      </DragAndDropList>
      <CreateLocationTypeModal
        key={createLocationTypeModal.key}
        isOpen={createLocationTypeModal.isOpen}
        handleClose={createLocationTypeModal.handleClose}
        locationTypes={organization.settings.locationTypes}
        organizationId={organization.id}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsLocationTypesPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsLocationTypesPage.query, {
    fetchPolicy: 'network-only',
  });

  return (
    <Loading loading={loading}>
      {() => (
        <LocationTypesContent
          key={data.organization.settings.locationTypes.length} // This key is important to make sure list updates when a location type is added or deleted
          locationTypes={data.organization.settings.locationTypes}
          refetch={refetch}
          organization={data.organization}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsLocationTypesPage.query = gql`
  ${LocationTypeForm.edit.fragment}
  ${LocationTypeItem.fragment}
  query OrganizationSettingsLocationTypesPage {
    ${gql.query}
    organization {
      id
      settings {
        id
        locationTypes {
          ...LocationTypeForm_edit
        }
      }
      ...LocationTypeItem
    }
  }
`;

export default OrganizationSettingsLocationTypesPage;
