// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const UpdateReportMoveTimeRangesMutation = ({
  reportMoveId,
  timeRangeInputs,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}) => (
  <Mutation
    variables={{
      reportMoveId,
      timeRangeInputs,
    }}
    mutation={UpdateReportMoveTimeRangesMutation.mutation}
    onCompleted={({response: {reportMove, errors}}) => {
      submitForm();
      setMutationErrors(errors);

      if (!errors) {
        onSuccess(reportMove);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateReportMoveTimeRangesMutation.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

UpdateReportMoveTimeRangesMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateReportMoveTimeRangesMutation.mutation = gql`
  mutation UpdateReportMoveTimeRangesMutation(
    $reportMoveId: Int!,
    $timeRangeInputs: [TimeRangeInput]!,
  ) {
    response: updateReportMoveTimeRanges(
      reportMoveId: $reportMoveId,
      timeRangeInputs: $timeRangeInputs,
    ) {
      ${gql.errors}
      reportMove {
        id
        uuid
        timeRanges {
          kind
          name
          start
          end
        }
      }
    }
  }
`;

export default UpdateReportMoveTimeRangesMutation;
