// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';

// App
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';

const PageContainer = Styled.View`
`;

const ClaimsPageHeader = () => {
  return (
    <PageContainer>
      <StandardOfficeHeader title={'Claims'} isBeta />
    </PageContainer>
  );
};

export default ClaimsPageHeader;
