// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {MapView as SupermoveMapView, Styled} from '@supermove/components';
import {useResponsive, useState} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Location as LocationUtils} from '@supermove/utils';

// App

// Components
import {MapMarker} from 'modules/App/Map/components';
import MapView from 'modules/App/components/MapView';

const Container = Styled.View`
  flex: 1;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  right: 60px;
  bottom: 25px;
  z-index: 1;
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: ${(props) => (props.mobile ? 10 : 15)}px;
  margin-left: ${(props) => (props.isFirst ? 0 : 10)}px;
  background-color: ${(props) => (props.selected ? colors.blue.interactive : colors.white)};
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) =>
    props.disabled ? colors.gray.tertiary : props.selected ? colors.white : colors.gray.primary};
`;

const getInitialCenter = ({warehouseLocation}) => {
  return warehouseLocation
    ? {
        lat: warehouseLocation.latitude,
        lng: warehouseLocation.longitude,
      }
    : {
        lat: 37.7749,
        lng: -122.4194,
      };
};

const getStreetView = ({selectedIndex, points}) => {
  return _.get(points, selectedIndex);
};

const isValid = (location) => Boolean(LocationUtils.create(location));

const ROUTE_INDEX = -1;

const JobMap = ({
  isCustomer,
  isHidden,
  renderMapAnnotations,
  boundLocations,
  locations,
  warehouseLocation,
  onRouteUpdate,
  mapRef,
  onReady,
}) => {
  const responsive = useResponsive();
  const [selectedIndex, setSelectedIndex] = useState(ROUTE_INDEX);

  // We memoize locations (LocationUtils.create) so a lat/lon pair always render the same object
  // to avoid re-rending the map markers.
  const points = locations.map((location) => LocationUtils.create(location)).filter(Boolean);

  const warehousePosition = warehouseLocation ? LocationUtils.create(warehouseLocation) : null;

  const hasExtraControls = !isCustomer && !isHidden;

  return (
    <Container
      style={{
        visibility: isHidden ? 'hidden' : 'visible',
      }}
    >
      <MapView
        hasExtraControls={hasExtraControls}
        initialCenter={getInitialCenter({warehouseLocation})}
        locations={[...locations, ...boundLocations]}
        directionLocations={locations}
        onRouteUpdate={onRouteUpdate}
        onReady={(mapProps, map) => {
          if (mapRef) {
            mapRef.current = map;
          }
          if (onReady) {
            onReady(map);
          }
        }}
        renderMapAnnotations={({route, ...props}) => (
          <React.Fragment>
            {points.map((point, index) => (
              <MapMarker
                key={index}
                kind={MapMarker.DEFAULT}
                name={`Stop ${index + 1}`}
                label={MapMarker.createMarkerLabel(index + 1)}
                position={point}
                {...props}
              />
            ))}
            {renderMapAnnotations(props)}
            {route && route.length > 0 && (
              <SupermoveMapView.Polyline
                path={route}
                strokeColor={colors.gray.primary}
                strokeWeight={5}
                {...props}
              />
            )}
            {warehousePosition && (
              <MapMarker kind={MapMarker.WAREHOUSE} position={warehousePosition} {...props} />
            )}
          </React.Fragment>
        )}
        streetViewPoint={getStreetView({selectedIndex, points})}
      />
      {hasExtraControls && (
        <Actions>
          <Button
            isFirst
            key={'route'}
            selected={selectedIndex === ROUTE_INDEX}
            onPress={() => setSelectedIndex(ROUTE_INDEX)}
            {...responsive}
          >
            <ButtonText selected={selectedIndex === ROUTE_INDEX}>Route</ButtonText>
          </Button>
          {locations.map((location, index) => (
            <Button
              key={index}
              disabled={!isValid(location)}
              selected={selectedIndex === index}
              onPress={() => setSelectedIndex(index)}
              {...responsive}
            >
              <ButtonText disabled={!isValid(location)} selected={selectedIndex === index}>
                {responsive.mobile ? `#${index + 1}` : `Stop #${index + 1}`}
              </ButtonText>
            </Button>
          ))}
        </Actions>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobMap.propTypes = {
  isCustomer: PropTypes.bool,
  isHidden: PropTypes.bool,

  /**
   * An array of the starting, middle, and end stops for a move.
   */
  locations: PropTypes.array.isRequired,

  /**
   * An array of all the locations on the map. This controls the zoom and where the map is centered.
   */
  boundLocations: PropTypes.arrayOf(
    PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  ),

  /**
   * Called when a route is updated.
   */
  onRouteUpdate: PropTypes.func,

  /**
   * A function that accepts props and renders an array of map annotations.
   */
  renderMapAnnotations: PropTypes.func,

  /**
   * An object {latitude, longitude} that species where the warehouse is.
   * TODO(peter) the component should get merged into renderMapAnnotations. The location should get merged into boundLocations.
   */
  warehouseLocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
};

JobMap.defaultProps = {
  isCustomer: false,
  isHidden: false,
  boundLocations: [],
  onRouteUpdate: () => {},
  renderMapAnnotations: () => null,
  warehouseLocation: null,
};

export default JobMap;
