// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {useModal, usePopover} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import IconButton from '@shared/design/components/IconButton';
import JobTypeStepsDocumentSelectSectionsModal from 'modules/Organization/Settings/JobTypes/components/JobTypeStepsDocumentSelectSectionsModal';

const JobTypeStepsDocumentActions = ({
  handleSelectDocumentSections,
  handleRemoveDocument,
  skipSelectSectionConfirmation,
  selectSectionsDisabled,
}: {
  handleSelectDocumentSections: () => void;
  handleRemoveDocument: () => void;
  skipSelectSectionConfirmation?: boolean;
  selectSectionsDisabled?: boolean;
}) => {
  const selectDocumentSectionsModal = useModal({
    name: 'Select Document Sections Modal',
    enableTracking: true,
  });
  const jobTypeStepsDocumentActionsPopover = usePopover({
    name: 'Job Type Steps Document Actions Popover',
  });

  return (
    <React.Fragment>
      <ActionMenuPopover
        popover={jobTypeStepsDocumentActionsPopover}
        placement={ActionMenuPopover.Position.RightStart}
        actions={[
          {
            text: 'Select specific sections to complete',
            description: 'Allows customers to complete only relevant sections for this step.',
            onPress: () => {
              if (skipSelectSectionConfirmation) {
                handleSelectDocumentSections();
                return;
              } else {
                selectDocumentSectionsModal.handleOpen();
              }
            },
            isDisabled: selectSectionsDisabled,
            tooltip: selectSectionsDisabled
              ? "This document doesn't have any sections."
              : undefined,
          },
          {
            text: 'Remove document',
            description: 'Removes this document from this step.',
            onPress: handleRemoveDocument,
            color: colors.red.warning,
          },
        ]}
      >
        <IconButton
          onPress={jobTypeStepsDocumentActionsPopover.handleOpen}
          source={Icon.EllipsisV}
          isSecondary
        />
      </ActionMenuPopover>
      <JobTypeStepsDocumentSelectSectionsModal
        key={selectDocumentSectionsModal.key}
        isOpen={selectDocumentSectionsModal.isOpen}
        handleClose={selectDocumentSectionsModal.handleClose}
        handleContinue={() => {
          selectDocumentSectionsModal.handleClose();
          handleSelectDocumentSections();
        }}
      />
    </React.Fragment>
  );
};

export default JobTypeStepsDocumentActions;
