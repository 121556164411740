// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Project} from '@supermove/models';
import {Datetime} from '@supermove/utils';

// Components
import FieldInput from '@shared/design/components/Field/FieldInput';

const FieldsContainer = Styled.View`
  z-index: 100;
`;

const getProjectOrJobOptions = ({project}) => {
  return [
    {
      value: '-1',
      label: `Project ${project.identifier}`,
      description: Datetime.convertToDisplayDate(project.endDate, Datetime.DISPLAY_SHORT_DATE),
    },
    ...Project.getActiveJobsDropdownOptions(project),
  ];
};

const NewCompensationEntryFields = ({form, field, project}) => {
  return (
    <FieldsContainer>
      <FieldInput
        {...form}
        index={0}
        component={DropdownInput}
        name={`${field}.compensationUserId`}
        label={'Employee'}
        isRequired
        input={{
          options: Project.getMoverDropdownOptions(project),
          placeholder: 'Select an employee',
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        component={DropdownInput}
        name={`${field}.jobId`}
        tooltip={'Select a specific job for this entry, or set it as a project-level expense.'}
        label={'Project or Job'}
        isRequired
        input={{
          options: getProjectOrJobOptions({project}),
          placeholder: 'Select a project or job',
          setFieldValue: (name, value) => {
            form.setFieldValue(name, value);
            // Set or remove project id based on whether a project or a job is selected
            if (value === '-1') {
              form.setFieldValue('costForm.projectId', project.id);
            } else {
              form.setFieldValue('costForm.projectId', null);
            }
          },
          style: {flex: 1},
          components: {
            Option: DropdownInput.OptionWithDescription,
          },
        }}
      />
    </FieldsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NewCompensationEntryFields.fragment = gql`
  ${Project.getMoverDropdownOptions.fragment}
  ${Project.getActiveJobsDropdownOptions.fragment}

  fragment NewCompensationEntryFields on Project {
    id
    endDate
    identifier
    ...Project_getActiveJobsDropdownOptions
    ...Project_getMoverDropdownOptions
  }
`;

export default NewCompensationEntryFields;
