// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useNavigationDOM, useQuery, useState} from '@supermove/hooks';
import {Project, ProjectType} from '@supermove/models';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Tabs from '@shared/design/components/Tabs';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import Line from 'modules/App/components/Line';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import ProjectSection from 'modules/Project/V2/Show/components/ProjectSection';

const HeaderRow = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 48px;
`;

const LoaderContainer = Styled.View`
  padding-horizontal: 16px;
`;

const handleInvalidParams = ({params, jobs, urlFilters, isValidJobUuid}) => {
  // 1. Handle an invalid jobUuid param
  // 2. Handle when a jobUuid or block param is missing
  const {jobUuid, block} = params;

  if (_.some(jobs)) {
    return urlFilters.handleUpdate({
      jobUuid: isValidJobUuid ? jobUuid : jobs[0].uuid,
      block: block || ProjectBlockKind.EditJobBlocks[0],
    });
  }
};

const getJobTabs = ({jobs}) => {
  return jobs.map((job) => ({
    key: job.id,
    label: job.shortName,
    jobUuid: job.uuid,
  }));
};

const LoadingComponent = () => {
  return (
    <React.Fragment>
      <LoaderContainer style={{height: 48, justifyContent: 'center'}}>
        <SkeletonLoader isFullWidth height={SkeletonLoader.HEIGHT.SubheadingText} />
      </LoaderContainer>
      <Line />
      <LoaderContainer style={{height: 48, justifyContent: 'center'}}>
        <SkeletonLoader isFullWidth height={SkeletonLoader.HEIGHT.SubheadingText} />
      </LoaderContainer>
      <Line style={{height: 2}} />
      <Space height={16} />
      <LoaderContainer>
        <SkeletonLoader isFullWidth height={200} />
      </LoaderContainer>
      <Space height={16} />
      <LoaderContainer>
        <SkeletonLoader isFullWidth height={200} />
      </LoaderContainer>
      <Space height={16} />
      <LoaderContainer>
        <SkeletonLoader isFullWidth height={200} />
      </LoaderContainer>
    </React.Fragment>
  );
};

const Header = ({project, urlFilters}) => {
  return (
    <HeaderRow>
      <Space width={16} />
      <TertiaryButton
        onPress={() =>
          urlFilters.handleReset({
            block: ProjectBlockKind.JOBS,
            showCancelledJobs: urlFilters.get('showCancelledJobs'),
          })
        }
        isHitSlop
        iconLeft={Icon.ChevronLeft}
        text={`Back to ${Project.getName(project)}`}
        isResponsive
      />
    </HeaderRow>
  );
};

const ViewProjectJobsContent = ({project, urlFilters, refetch}) => {
  const {params} = useNavigationDOM();
  const parentJobs = Project.getDisplayAllJobsExcludingChildJobs(project, params);
  const parentAndChildJobs = _.reduce(
    parentJobs,
    (result, job) => [...result, ...(job.isEstimatedRange ? job.jobsForEstimatedRange : [job])],
    [],
  );
  const isValidJobUuid = !!_.find(parentAndChildJobs, ['uuid', params.jobUuid]);

  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (!isValidJobUuid || !params.block) {
      handleInvalidParams({params, jobs: parentAndChildJobs, urlFilters, isValidJobUuid});
    }
  }, [params.block, params.jobUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  const job = _.find(parentAndChildJobs, ['uuid', params.jobUuid]);

  return (
    <React.Fragment>
      <Header project={project} urlFilters={urlFilters} />
      <Line />
      <Tabs
        tabs={getJobTabs({jobs: parentAndChildJobs})}
        handlePressTab={(tab) => urlFilters.handleUpdate({jobUuid: tab.jobUuid})}
        activeTabIndex={_.findIndex(parentAndChildJobs, (job) => job.uuid === params.jobUuid) || 0}
        scrollViewStyle={{paddingTop: 12}}
        tabStyle={{paddingHorizontal: 24}}
      />
      <Line style={{height: 2}} />
      {job && (
        <ProjectSection
          key={`${job.uuid}_${resetKey}`}
          tab={{label: job.shortName}}
          project={project}
          urlFilters={urlFilters}
          projectNavigationSections={[
            {
              name: 'Jobs',
              blocks: ProjectType.getJobBlocks(project.projectType),
            },
          ]}
          pageRefetch={refetch}
          refetchAndReset={() => {
            refetch();
            setResetKey(resetKey + 1);
          }}
        />
      )}
    </React.Fragment>
  );
};

const ViewProjectJobs = ({urlFilters}) => {
  const {params} = useNavigationDOM();
  const {data, loading, refetch} = useQuery(ViewProjectJobs.query, {
    fetchPolicy: 'network-only',
    variables: {projectUuid: params.projectUuid},
  });

  return (
    <Loading loading={loading} as={LoadingComponent}>
      {() => {
        return (
          <ViewProjectJobsContent
            project={data.project}
            urlFilters={urlFilters}
            refetch={refetch}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewProjectJobs.query = gql`
  ${ProjectSection.fragment}
  ${Project.getName.fragment}
  ${Project.getDisplayAllJobsExcludingChildJobs.fragment}
  ${ProjectType.getJobBlocks.fragment}

  query ViewProjectJobs($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      allJobsExcludingChildJobs {
        id
        uuid
        shortName
        isEstimatedRange
        jobsForEstimatedRange {
          id
          uuid
          shortName
        }
      }
      projectType {
        id
        ...ProjectType_getJobBlocks
      }
      ...ProjectSection
      ...Project_getName
      ...Project_getDisplayAllJobsExcludingChildJobs
    }
  }
`;

export default ViewProjectJobs;
