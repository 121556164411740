const PERCENTAGE = 'PERCENTAGE';
const FRACTION = 'FRACTION';
const TEXT = 'TEXT';

const CapacityCalendarSlotDisplayKind = {
  PERCENTAGE,
  FRACTION,
  TEXT,
};

export default CapacityCalendarSlotDisplayKind;
