// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {CurrencyInput, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import {Field} from 'modules/App/components';

const Container = Styled.View`
  padding: 10px;
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 15)}px;
`;

const Button = Styled.LoadingButton`
`;

const Text = Styled.H7`
  color: ${colors.white};
  ${fontWeight(600)}
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const SupplyFormFields = ({field, form, isUpdate, loading, handleSubmit}) => {
  const responsive = useResponsive();

  return (
    <Container>
      <Field
        {...form}
        name={`${field}.name`}
        label={'Name'}
        value={_.get(form.values, `${field}.name`)}
        input={{
          autoFocus: true,
          placeholder: 'Enter a name (Supply #1)',
        }}
      />
      <RowSpace {...responsive} />
      <Field
        {...form}
        component={CurrencyInput}
        input={{
          component: TextInput,
          placeholder: '$100',
          setFieldValue: (name, value) => form.setFieldValue(name, value),
          setFieldTouched: form.setFieldTouched,
          style: {
            width: 100,
          },
        }}
        name={`${field}.value`}
        label={'Price'}
        value={_.get(form.values, `${field}.value`)}
      />
      <RowSpace {...responsive} />

      <Button loading={loading} onPress={handleSubmit}>
        <Text>{`${isUpdate ? 'Update' : 'Create'} Supply`}</Text>
      </Button>
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
SupplyFormFields.propTypes = {
  form: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

SupplyFormFields.defaultProps = {
  isUpdate: false,
};

export default SupplyFormFields;
