// Libraries
import React from 'react';

// Supermove
import {Lifecycle, ScrollView, Styled, ZoomView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ConfirmationQuoteSuccess from 'modules/Customer/Project/Confirmation/ConfirmationQuoteSuccess';
import ProjectPage from 'modules/Customer/Project/components/ProjectPage';
import CustomerTaskProjectQuoteAcceptedContent from 'modules/Project/Confirmation/components/CustomerTaskProjectQuoteAcceptedContent';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  width: ${(props) => (props.mobile ? '100%' : '722px')};
  align-self: center;
`;

const Content = Styled.View`
  align-self: stretch;
  padding-bottom: 90px;
`;

const DocumentContainer = Styled.View`
  margin: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const isCorrectPage = ({project}) => {
  return project.quote.isComplete;
};

const onMountOrUpdate = ({navigator, project}) => {
  if (!isCorrectPage({project})) {
    navigator.push(`/0/${project.organization.slug}/projects/${project.uuid}/quote`);
  }
};

// If the next step of the confirmation is not to save credit card,
// redirect the customer to the show confirmation page.
const Redirect = ({children, navigator, project}) => (
  <Lifecycle
    onMount={() => onMountOrUpdate({navigator, project})}
    onUpdate={() => onMountOrUpdate({navigator, project})}
  >
    {isCorrectPage({project}) ? children : null}
  </Lifecycle>
);

const SuccessProjectQuoteCustomerPageContent = ({project}) => {
  const responsive = useResponsive();
  const scale = responsive.mobile ? 0.5 : 1;

  return (
    <DocumentContainer>
      <ZoomView
        defaultScale={scale}
        defaultPositionX={0}
        defaultPositionY={0}
        minScale={scale}
        centerContent={false}
        wrapperClass={responsive.mobile ? 'react-zoom-mobile-document' : ''}
      >
        <CustomerTaskProjectQuoteAcceptedContent project={project} />
      </ZoomView>
    </DocumentContainer>
  );
};

const SuccessProjectQuoteCustomerPage = ({
  match: {
    params: {slug, uuid},
  },
}) => {
  return (
    <ProjectPage
      fetchPolicy={'network-only'}
      variables={{uuid}}
      query={SuccessProjectQuoteCustomerPage.query}
    >
      {({responsive, navigator, data}) => {
        const organizationName =
          data.project.organization.businessName || data.project.organization.name;
        return (
          <Redirect navigator={navigator} project={data.project}>
            <Wrapper>
              <ScrollView style={{flex: 1}}>
                {data.project.quote.isV2 ? (
                  <ConfirmationQuoteSuccess
                    confirmation={data.project.quote}
                    title={'Your Move Is Almost Booked'}
                    subtitle={
                      `Thank you for confirming your interest in working with ` +
                      `${organizationName}. We will be in touch shortly to confirm details. ` +
                      `Please feel free to call our office if you have questions.`
                    }
                    isCancelled={data.project.isCancelled}
                    cancelledMessage={'This quote has expired'}
                  />
                ) : (
                  <Container {...responsive}>
                    <Content>
                      <SuccessProjectQuoteCustomerPageContent project={data.project} />
                    </Content>
                  </Container>
                )}
              </ScrollView>
            </Wrapper>
          </Redirect>
        );
      }}
    </ProjectPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SuccessProjectQuoteCustomerPage.query = gql`
${ConfirmationQuoteSuccess.fragment}
${CustomerTaskProjectQuoteAcceptedContent.fragment}
${ProjectPage.fragment}

  query SuccessProjectQuoteCustomerPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      quote {
        id
        isV2
        isComplete
        steps {
          kind
        }
        ...ConfirmationQuoteSuccess
      }
      organization {
        id
        slug
        name
        businessName
      }
      ...CustomerTaskProjectQuoteAcceptedContent
      ...ProjectPage
    }
  }
`;

export default SuccessProjectQuoteCustomerPage;
