// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import JobAdditionalFields from 'modules/Job/components/JobAdditionalFields';
import JobBillingFields from 'modules/Job/components/JobBillingFields';
import JobInternalFields from 'modules/Job/components/JobInternalFields';
import JobLocationsFields from 'modules/Job/components/JobLocationsFields';
import JobScheduleFields from 'modules/Job/components/JobScheduleFields';

const Container = Styled.View`
  padding-horizontal: 30px;
  z-index: 100;
`;

const Space = Styled.View`
  margin-top: ${(props) => (props.mobile ? 30 : 40)}px;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const JobFields = ({
  field,
  organization,
  form,
  jobIndex,
  isUpdate,
  shouldResetJobDateOnJobTypeChange,
  jobFormField,
  warehouseLocation,
  mapRef,
}) => {
  const responsive = useResponsive();
  const jobField = `${field}.jobForms.${jobIndex}`;
  return (
    <React.Fragment>
      <Container>
        <Section index={0}>
          <JobScheduleFields
            index={0}
            field={jobField}
            form={form}
            responsive={responsive}
            organization={organization}
            shouldResetJobDateOnJobTypeChange={shouldResetJobDateOnJobTypeChange}
            jobFormField={jobFormField}
          />
          <Space {...responsive} />
          <JobLocationsFields
            index={1}
            field={jobField}
            form={form}
            responsive={responsive}
            organization={organization}
            warehouseLocation={warehouseLocation}
            mapRef={mapRef}
          />
          <Space {...responsive} />
          <JobBillingFields
            isUpdate={isUpdate}
            index={2}
            field={field}
            jobField={jobField}
            form={form}
            responsive={responsive}
            organization={organization}
          />
          <Space {...responsive} />
        </Section>
        <JobAdditionalFields
          index={1}
          field={jobField}
          form={form}
          responsive={responsive}
          organization={organization}
        />
      </Container>
      <JobInternalFields
        index={2}
        field={field}
        jobField={jobField}
        form={form}
        organization={organization}
        responsive={responsive}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobFields.propTypes = {
  jobIndex: PropTypes.number.isRequired,
  isUpdate: PropTypes.bool,
};

JobFields.defaultProps = {
  isUpdate: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobFields.fragment = gql`
  ${JobAdditionalFields.fragment}
  ${JobBillingFields.fragment}
  ${JobInternalFields.fragment}
  ${JobLocationsFields.fragment}
  ${JobScheduleFields.fragment}

  fragment JobFields on Organization {
    id
    ...JobAdditionalFields
    ...JobBillingFields
    ...JobInternalFields
    ...JobLocationsFields
    ...JobScheduleFields
  }
`;

export default JobFields;
