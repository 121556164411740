// Libraries
import React from 'react';

// Supermove
import {Icon, Query, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import TableV1, {TableV1Builder} from '@shared/design/components/TableV1';
import CommercialEquipmentForm from '@shared/modules/CommercialCatalog/forms/CommercialEquipmentForm';
import useCreateCommercialEquipmentMutation from '@shared/modules/CommercialCatalog/hooks/useCreateCommercialEquipmentMutation';
import LargeModal from 'modules/App/Modal/components/LargeModal';
import SearchBar from 'modules/App/components/SearchBar';
import CreateCommercialEquipmentModal from 'modules/CommercialCatalog/components/CreateCommercialEquipmentModal';
import DeleteCommercialEquipmentModal from 'modules/CommercialCatalog/components/DeleteCommercialEquipmentModal';
import UpdateCommercialEquipmentModal from 'modules/CommercialCatalog/components/UpdateCommercialEquipmentModal';

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Indicator = Styled.Loading`
`;

const Content = Styled.View`
  flex: 1;
  padding-bottom: 40px;
  margin-top: 10px;
  margin-horizontal: ${(props) => (props.mobile ? 12 : 30)}px;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const TableTitle = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const TableSection = ({index, count, title, children}) => {
  return (
    <Section index={index}>
      <TableTitle>{`${title} (${count})`}</TableTitle>
      {children}
    </Section>
  );
};

const Actions = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const Button = Styled.Button`
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const IconButton = Styled.LoadingButton`
  height: 30px;
  width: 30px;
`;

const IconButtonDelete = ({onPress}) => {
  return (
    <IconButton color={colors.Pink600} onPress={onPress}>
      <Icon color={colors.white} size={14} source={Icon.Trash} />
    </IconButton>
  );
};

const IconButtonEdit = ({onPress}) => {
  return (
    <IconButton color={colors.blue.interactive} onPress={onPress}>
      <Icon color={colors.white} size={14} source={Icon.Edit} />
    </IconButton>
  );
};

const EditCommercialEquipmentButton = ({commercialEquipment, refetch}) => {
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <React.Fragment>
      <IconButtonEdit onPress={handleOpen} />
      <UpdateCommercialEquipmentModal
        isOpen={isOpen}
        commercialEquipment={commercialEquipment}
        handleClose={handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const DeleteCommercialEquipmentButton = ({commercialEquipment, refetch}) => {
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <React.Fragment>
      <IconButtonDelete onPress={handleOpen} />
      <DeleteCommercialEquipmentModal
        isOpen={isOpen}
        commercialEquipment={commercialEquipment}
        handleClose={handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const CommercialEquipmentRow = ({commercialEquipment, refetch}) => {
  return (
    <TableV1Builder.TableRow key={commercialEquipment.id}>
      <TableV1Builder.TableItem width={220} text={commercialEquipment.name} />
      <TableV1Builder.TableItem width={100} text={`${commercialEquipment.quantityRequested}`} />
      <TableV1Builder.TableItem width={100} text={`${commercialEquipment.quantityDelivered}`} />
      <TableV1Builder.TableItem
        width={100}
        text={`${Currency.display(commercialEquipment.price)}`}
      />
      <TableV1Builder.TableItem width={140}>
        <Row>
          <EditCommercialEquipmentButton
            commercialEquipment={commercialEquipment}
            refetch={refetch}
          />
          <Space width={5} />
          <DeleteCommercialEquipmentButton
            commercialEquipment={commercialEquipment}
            refetch={refetch}
          />
        </Row>
      </TableV1Builder.TableItem>
    </TableV1Builder.TableRow>
  );
};

const CommercialEquipmentSearchBar = ({job, form, handleOpen}) => {
  const getSearchResults = (commercialEquipmentTypes) => {
    return commercialEquipmentTypes.map((commercialEquipmentType) => ({
      text: commercialEquipmentType.name,
      data: {commercialEquipmentType},
    }));
  };

  return (
    <SearchBar
      placeholder={'Add from library...'}
      results={getSearchResults(job.organization.commercialEquipmentTypes)}
      onSelect={(row) => {
        const {data} = row;
        form.setFieldValue('commercialEquipmentForm.name', data.commercialEquipmentType.name);
        form.setFieldValue(
          'commercialEquipmentForm.price',
          Currency.toForm(data.commercialEquipmentType.price),
        );
        form.setFieldValue(
          'commercialEquipmentForm.commercialEquipmentTypeId',
          data.commercialEquipmentType.id,
        );
        handleOpen();
      }}
    />
  );
};

const EditCommercialEquipmentModalContent = ({job, refetch}) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const commercialEquipmentForm = CommercialEquipmentForm.toForm(
    CommercialEquipmentForm.new({
      commercialCatalogId: job.commercialCatalog.id,
      name: '',
      price: '',
    }),
  );
  const {form, submitting, handleSubmit} = useCreateCommercialEquipmentMutation({
    commercialEquipmentForm,
    onSuccess: () => {
      handleClose();
      refetch();
      form.setValues({
        commercialEquipmentForm: CommercialEquipmentForm.toForm(
          CommercialEquipmentForm.new({
            commercialCatalogId: job.commercialCatalog.id,
            name: '',
            price: '',
          }),
        ),
      });
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  return (
    <Content>
      <Title>Edit Equipment</Title>
      <Space height={20} />
      <Section index={0}>
        <CommercialEquipmentSearchBar job={job} handleOpen={handleOpen} form={form} />
      </Section>
      <Space height={20} />
      <ScrollView>
        <ScrollView horizontal>
          <TableSection
            index={1}
            count={job.commercialCatalog.commercialEquipments.length}
            title={'Equipment'}
          >
            <Space height={10} />
            <Actions>
              <Button onPress={handleOpen}>
                <Text>Create an Equipment</Text>
              </Button>
            </Actions>
            <CreateCommercialEquipmentModal
              key={isOpen}
              isOpen={isOpen}
              form={form}
              submitting={submitting}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
            />
            <Space height={10} />
            <TableV1>
              <TableV1Builder.TableRow>
                <TableV1Builder.TableHeaderItem width={220} text={'Equipment Name'} />
                <TableV1Builder.TableHeaderItem width={100} text={'Requested'} />
                <TableV1Builder.TableHeaderItem width={100} text={'Left on Site'} />
                <TableV1Builder.TableHeaderItem width={100} text={'Price'} />
                <TableV1Builder.TableHeaderItem width={140} text={'Actions'} />
              </TableV1Builder.TableRow>
              {job.commercialCatalog.commercialEquipments.map((commercialEquipment) => (
                <CommercialEquipmentRow
                  key={commercialEquipment.id}
                  commercialEquipment={commercialEquipment}
                  refetch={refetch}
                />
              ))}
            </TableV1>
          </TableSection>
        </ScrollView>
      </ScrollView>
    </Content>
  );
};

const EditCommercialEquipmentModal = ({isOpen, jobUuid, handleClose}) => {
  return (
    <LargeModal isOpen={isOpen} width={800} handleClose={handleClose}>
      <Query
        fetchPolicy={'network-only'}
        variables={{uuid: jobUuid}}
        query={EditCommercialEquipmentModal.query}
      >
        {({loading, data, refetch}) =>
          loading ? (
            <Indicator size={'large'} color={colors.gray.secondary} />
          ) : (
            <EditCommercialEquipmentModalContent
              job={data.job}
              handleClose={handleClose}
              refetch={refetch}
            />
          )
        }
      </Query>
    </LargeModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditCommercialEquipmentModal.query = gql`
  ${UpdateCommercialEquipmentModal.fragment}
  ${DeleteCommercialEquipmentModal.fragment}
  query EditCommercialEquipmentModal($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      commercialCatalog {
        id
        commercialEquipments {
          id
          name
          quantityDelivered
          quantityRequested
          price
          ...DeleteCommercialEquipmentModal
          ...UpdateCommercialEquipmentModal
        }
      }
      organization {
        id
        commercialEquipmentTypes {
          id
          name
          price
        }
      }
    }
  }
`;

export default EditCommercialEquipmentModal;
