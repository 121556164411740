// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, usePopover} from '@supermove/hooks';
import {BillRuleType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import AddBillRuleTypeDrawerV1 from 'modules/Organization/Settings/BillingLibraries/components/AddBillRuleTypeDrawerV1';
import DeleteBillRuleTypeModal from 'modules/Organization/Settings/BillingLibraries/components/DeleteBillRuleTypeModal';
import EditBillRuleTypeDrawerV1 from 'modules/Organization/Settings/BillingLibraries/components/EditBillRuleTypeDrawerV1';

const TableContainer = Styled.View`
  width: 1450px;
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const TableCellTextTag = Styled.Text`
  ${({vars = {}}) => (vars.hasFormula ? Typography.Label2 : Typography.Body3)}
  color: ${({vars = {}}) => (vars.hasFormula ? colors.orange.status : colors.gray.primary)};
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
;`;

const CellColumn = Styled.View`
  flex-direction: column;
`;

const EditBillRuleTypeButton = Styled.ButtonV2`
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const BillRuleTypeActionsMenu = ({popover, deleteBillRuleTypeModal}) => {
  return (
    <ActionMenuPopover popover={popover} placement={Popover.Positions.BottomStart} width={208}>
      <ActionMenuPopover.MenuItem
        onPress={() => {
          deleteBillRuleTypeModal.handleOpen();
          popover.handleClose();
        }}
      >
        Delete
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const BillRuleTypeActions = ({billRuleType, refetch, userId}) => {
  const editBillRuleTypeDrawer = useDrawer({name: 'Edit Bill Rule Type Drawer'});
  const billRuleTypePopover = usePopover();
  const deleteBillRuleTypeModal = useModal({name: 'Delete Bill Rule Type Modal'});

  return (
    <React.Fragment>
      <EditBillRuleTypeButton onPress={editBillRuleTypeDrawer.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </EditBillRuleTypeButton>
      <Space width={12} />
      <Popover.Content innerRef={billRuleTypePopover.ref}>
        <MoreActionsButton onPress={billRuleTypePopover.handleOpen}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </MoreActionsButton>
      </Popover.Content>
      <BillRuleTypeActionsMenu
        popover={billRuleTypePopover}
        deleteBillRuleTypeModal={deleteBillRuleTypeModal}
      />
      <EditBillRuleTypeDrawerV1
        key={editBillRuleTypeDrawer.key}
        isOpen={editBillRuleTypeDrawer.isOpen}
        handleClose={editBillRuleTypeDrawer.handleClose}
        billRuleType={billRuleType}
        userId={userId}
        refetch={refetch}
      />
      <DeleteBillRuleTypeModal
        key={deleteBillRuleTypeModal.key}
        isOpen={deleteBillRuleTypeModal.isOpen}
        handleClose={deleteBillRuleTypeModal.handleClose}
        billRuleTypeId={billRuleType.id}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const TableCellText = ({children, vars, tooltipContent}) => {
  const tableCellImplContent = <TableCellTextTag vars={vars}>{children}</TableCellTextTag>;
  if (tooltipContent) {
    return (
      <React.Fragment>
        <TextTooltip text={tooltipContent}>{tableCellImplContent}</TextTooltip>
      </React.Fragment>
    );
  }
  return tableCellImplContent;
};

const getColumnDefinitions = ({userId, refetch}) => [
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText>Kind</TableHeaderText>;
    },
    cellContent: ({item: billRuleType}) => {
      return <TableCellText>{BillRuleType.getDisplayKind(billRuleType)}</TableCellText>;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderText>Name</TableHeaderText>;
    },
    cellContent: ({item: billRuleType}) => {
      return (
        <TableCellText
          vars={{
            hasFormula: BillRuleType.hasNameFormula(billRuleType),
          }}
          tooltipContent={BillRuleType.renderNameFormulaString(billRuleType)}
        >
          {billRuleType.name}
        </TableCellText>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderText>Value</TableHeaderText>;
    },
    cellContent: ({item: billRuleType}) => {
      return (
        <TableCellText
          vars={{
            hasFormula: BillRuleType.hasValueFormula(billRuleType),
          }}
          tooltipContent={BillRuleType.renderValueFormulaString(billRuleType)}
        >
          {BillRuleType.getDisplayValue({...billRuleType, showValueFormulaName: true})}
        </TableCellText>
      );
    },
  },
  {
    flex: 3,
    headerContent: () => {
      return <TableHeaderText>Description</TableHeaderText>;
    },
    cellContent: ({item: billRuleType}) => {
      return (
        <TableCellText
          vars={{
            hasFormula: BillRuleType.hasDescriptionFormula(billRuleType),
          }}
          tooltipContent={BillRuleType.renderDescriptionFormulaString(billRuleType)}
        >
          {billRuleType.description}
        </TableCellText>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText>Show Customers?</TableHeaderText>;
    },
    cellContent: ({item: billRuleType}) => {
      return (
        <React.Fragment>
          {billRuleType.isVisibleToCustomer && (
            <Icon source={Icon.Check} size={16} color={colors.green.status} />
          )}
        </React.Fragment>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText>Last Updated</TableHeaderText>;
    },
    cellContent: ({item: billRuleType}) => {
      return (
        <CellColumn>
          <Text>{BillRuleType.getDisplayUpdatedAt(billRuleType)}</Text>
          {!!billRuleType.updatedBy && (
            <React.Fragment>
              <Space height={4} />
              <Text>{billRuleType.updatedBy.fullName}</Text>
            </React.Fragment>
          )}
        </CellColumn>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText>Actions</TableHeaderText>;
    },
    cellContent: ({item: billRuleType}) => {
      return <BillRuleTypeActions billRuleType={billRuleType} refetch={refetch} userId={userId} />;
    },
  },
];

const BillRuleTypesTableV1 = ({billingLibrary, refetch, userId}) => {
  const addBillRuleTypeDrawer = useModal({name: 'Add Bill Rule Type Drawer'});
  return (
    <React.Fragment>
      <Button
        iconLeft={Icon.Plus}
        text={'Add Bill Rule'}
        onPress={addBillRuleTypeDrawer.handleOpen}
      />
      <Space height={8} />
      <TableContainer>
        <Table
          columnDefinitions={getColumnDefinitions({
            userId,
            refetch,
          })}
          emptyStateText='No bill rules to display'
          items={billingLibrary.billRuleTypes}
          itemKey={'id'}
        />
      </TableContainer>
      <AddBillRuleTypeDrawerV1
        key={addBillRuleTypeDrawer.key}
        isOpen={addBillRuleTypeDrawer.isOpen}
        handleClose={addBillRuleTypeDrawer.handleClose}
        billingLibraryId={billingLibrary.id}
        organizationId={billingLibrary.organizationId}
        userId={userId}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillRuleTypesTableV1.fragment = gql`
  ${BillRuleType.getDisplayKind.fragment}
  ${BillRuleType.getDisplayValue.fragment}
  ${BillRuleType.getDisplayUpdatedAt.fragment}
  ${BillRuleType.hasDescriptionFormula.fragment}
  ${BillRuleType.hasNameFormula.fragment}
  ${BillRuleType.hasValueFormula.fragment}
  ${BillRuleType.renderDescriptionFormulaString.fragment}
  ${BillRuleType.renderNameFormulaString.fragment}
  ${BillRuleType.renderValueFormulaString.fragment}
  ${EditBillRuleTypeDrawerV1.fragment}
  fragment BillRuleTypesTableV1 on BillingLibrary {
    id
    name
    organizationId
    billRuleTypes {
      id
      name
      description
      isVisibleToCustomer
      updatedBy {
        id
        fullName
      }
      ...BillRuleType_getDisplayKind
      ...BillRuleType_getDisplayValue
      ...BillRuleType_getDisplayUpdatedAt
      ...BillRuleType_hasDescriptionFormula
      ...BillRuleType_hasNameFormula
      ...BillRuleType_hasValueFormula
      ...BillRuleType_renderDescriptionFormulaString
      ...BillRuleType_renderNameFormulaString
      ...BillRuleType_renderValueFormulaString
      ...EditBillRuleTypeDrawerV1
    }
  }
`;

export default BillRuleTypesTableV1;
