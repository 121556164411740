/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, Tooltip} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useState} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import DayForm from '@shared/modules/Scheduling/forms/DayForm';
import useUpdateDayMutation from '@shared/modules/Scheduling/hooks/useUpdateDayMutation';

const ProjectTypeDaysContent = Styled.View`
  border: 1px solid ${colors.gray.border};
  border-radius: 4px;
`;

const ProjectTypeDaysBar = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-vertical: 8px;
`;

const EmptySpace = Styled.View`
  flex: 1;
`;

const ProjectTypeItemContent = Styled.View`
  flex-direction: row;
  margin-bottom: 2px;
  align-items: center;
`;

const ProjectTypeText = Styled.H7`
  color: ${(props) => props.color};
  ${fontWeight(700)}
`;

const ProjectTypeNumber = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ProjectTypeNumberBackground = Styled.View`
  height: 18px;
  min-width: 18px;
  background-color: ${(props) => props.color};
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  padding-vertical: 1px;
  padding-horizontal: 2px;
`;

const LockBackground = Styled.Touchable`
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${colors.gray.border};
`;

const CaretBackground = Styled.View`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
  justify-content: center;
  align-items: center;
`;

const LockToolTipText = Styled.H7`
  color: ${colors.white};
`;

const Indicator = Styled.Loading`
`;

const LockButton = ({day, field, refetch}) => {
  const dayForm = DayForm.edit(day);
  const {form, submitting, handleSubmit} = useUpdateDayMutation({
    dayForm,
    onSuccess: () => refetch(),
    onError: (errors) => console.log({errors}),
  });

  const isLocked = _.get(day, field);

  if (submitting) {
    return <Indicator />;
  }

  return (
    <Tooltip
      placement={'bottomRight'}
      align={{
        offset: [10, 0],
      }}
      overlay={() => {
        return <LockToolTipText>{isLocked ? `Unlock sales?` : `Lock sales?`}</LockToolTipText>;
      }}
    >
      <LockBackground
        disabled={submitting}
        onPress={() => {
          form.setFieldValue(`dayForm.${field}`, !isLocked);
          setTimeout(() => handleSubmit(), 0);
        }}
      >
        {isLocked ? (
          <Icon color={colors.gray.tertiary} size={Icon.Sizes.Small} source={Icon.Lock} />
        ) : (
          <Icon color={colors.gray.primary} size={Icon.Sizes.Small} source={Icon.LockOpen} />
        )}
      </LockBackground>
    </Tooltip>
  );
};

const DayLockItem = ({day, field, text, refetch}) => {
  return (
    <ProjectTypeItemContent>
      <Space width={8} />
      <ProjectTypeNumberBackground color={colors.gray.primary}>
        <ProjectTypeNumber />
      </ProjectTypeNumberBackground>
      <Space width={6} />
      <ProjectTypeText color={colors.gray.primary}>{'Packing'}</ProjectTypeText>
      <EmptySpace />
      <LockButton day={day} field={field} refetch={refetch} />
      <Space width={8} />
    </ProjectTypeItemContent>
  );
};

const DayLocksSection = ({day, refetch}) => {
  const [isOpen, setIsOpen] = useState(true);
  const {ref, isHovered} = useHover();

  return (
    <ProjectTypeDaysContent>
      <ProjectTypeDaysBar onPress={() => setIsOpen(!isOpen)}>
        <Space width={8} />
        <ProjectTypeText color={colors.gray.secondary}>Job Types</ProjectTypeText>
        <Space width={4} />
        <EmptySpace />
        <CaretBackground color={isHovered ? colors.hover : colors.white} ref={ref}>
          <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.CaretDown} />
        </CaretBackground>
        <Space width={8} />
      </ProjectTypeDaysBar>
      {isOpen && (
        <React.Fragment>
          <DayLockItem day={day} field={'isPackingJobsLocked'} text={'Packing'} refetch={refetch} />
          <Space height={4} />
        </React.Fragment>
      )}
    </ProjectTypeDaysContent>
  );
};

DayLocksSection.fragment = gql`
  ${DayForm.edit.fragment}

  fragment DayLocksSection on Day {
    id
    isPackingJobsLocked
    ...DayForm_edit
  }
`;

export default DayLocksSection;
