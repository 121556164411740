// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import UpdateMoverPositionForm from '@shared/modules/User/forms/UpdateMoverPositionForm';

const useUpdateMoverPositionMutation = ({updateMoverPositionForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      updateMoverPositionForm: UpdateMoverPositionForm.toForm(updateMoverPositionForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateMoverPositionMutation.mutation,
    variables: {
      updateMoverPositionForm: UpdateMoverPositionForm.toMutation(
        form.values.updateMoverPositionForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateMoverPositionMutation.mutation = gql`
  mutation useUpdateMoverPositionMutation($updateMoverPositionForm: UpdateMoverPositionForm!) {
    response: updateMoverPosition(updateMoverPositionForm: $updateMoverPositionForm) {
      ${gql.errors}
      moverPosition {
        id
      }
    }
  }
`;

export default useUpdateMoverPositionMutation;
