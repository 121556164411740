// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {
  CurrencyInput,
  DropdownInput,
  PercentInput,
  PhoneInput,
  Space,
  Styled,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import UserRole from '@shared/modules/User/enums/UserRole';
import CheckboxField from 'modules/App/components/CheckboxField';
import JobUserMoverPosition from 'modules/JobUser/components/JobUserMoverPosition';

const Container = Styled.View`
  padding: 10px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 15)}px;
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const Button = Styled.LoadingButton`
`;

const Text = Styled.H7`
  color: ${colors.white};
  ${fontWeight(600)}
`;

const CheckboxDescription = Styled.H7`
  color: ${colors.gray.secondary};
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const EMPLOYEE_FEATURES = [
  {
    name: 'VIEW_CUSTOMER_INFO',
    label: 'Can View Move Details',
    description:
      'Check if you want to allow this crew member to be able to view move details. ' +
      'Uncheck if you want to only allow this crew member to view move date and time.',
  },
];

// Return new features object after updating it with checkbox setFieldValue change.
const getUpdatedFeatures = ({form, name, value}) => {
  const features = _.clone(form.values.features);
  features[name] = value;
  return features;
};

const PositionField = ({organization, form}) => {
  const field = 'moverPositionIds';
  const {isEnabledMoverPositionMultibranch} = organization.features;

  const organizationMoverPositions = isEnabledMoverPositionMultibranch
    ? Organization.getCompanySettingsAllMoverPositionsByRole({
        organization,
        role: UserRole.EMPLOYEE,
      })
    : organization.companySettings.allMoverPositions;

  const moverPositionIds = _.get(form.values, field);
  const primaryMoverPositionId = _.get(form.values, 'primaryMoverPositionId');

  return (
    <FieldInput
      {...form}
      component={MultiDropdownCheckboxInput}
      name={field}
      label={'Position(s)'}
      isRequired
      input={{
        options: organizationMoverPositions.map((moverPosition) => ({
          label: moverPosition.name,
          value: Number(moverPosition.id),
          isHidden:
            moverPosition.isDeleted && !moverPositionIds.includes(_.toNumber(moverPosition.id)),
        })),
        placeholder: 'Select one',
        setFieldValue: (name, moverPositionIds) => {
          // set isDriver to true if any position's isDriver in moverPositionIds is true
          const moverPositions = _.chain(organizationMoverPositions)
            .keyBy('id')
            .at(moverPositionIds)
            .value();
          const isDriver = !_.every(moverPositions, ['isDriver', false]);
          form.setFieldValue('isDriver', isDriver);
          form.setFieldValue(name, moverPositionIds);

          // Handle primaryMoverPositionId
          if (!moverPositionIds.length) {
            form.setFieldValue('primaryMoverPositionId', null);
          } else if (
            !primaryMoverPositionId ||
            !moverPositionIds.includes(primaryMoverPositionId)
          ) {
            form.setFieldValue('primaryMoverPositionId', moverPositionIds[0]);
          }
        },
        style: {
          width: '100%',
        },
      }}
    />
  );
};

const PrimaryPositionField = ({organization, form}) => {
  const primaryPositionError = _.get(form.errors, 'primaryMoverPositionId');

  return (
    <React.Fragment>
      <FieldInput.LabelText isRequired>Select a primary position</FieldInput.LabelText>
      <Row index={2} style={{flex: 1, flexWrap: 'wrap'}}>
        {_.get(form.values, 'moverPositionIds').map((moverPositionId, index) => {
          const moverPosition = organization.companySettings.allMoverPositions.find(
            (moverPosition) => _.toString(moverPosition.id) === _.toString(moverPositionId),
          );
          const isPrimary =
            _.toString(moverPositionId) ===
            _.toString(_.get(form.values, 'primaryMoverPositionId'));
          return (
            <React.Fragment key={moverPositionId}>
              {index > 0 && <Space width={4} />}
              <JobUserMoverPosition.Badge
                isPrimary={isPrimary}
                isPressOnly
                isLarge
                onPress={() => form.setFieldValue('primaryMoverPositionId', moverPositionId)}
                color={colors.gray.secondary}
                name={moverPosition.name}
                style={{marginTop: 8}}
              />
            </React.Fragment>
          );
        })}
      </Row>
      {primaryPositionError && (
        <React.Fragment>
          <Space height={8} />
          <FieldInput.CaptionText hasErrors>{primaryPositionError}</FieldInput.CaptionText>
        </React.Fragment>
      )}
      <Space height={4} />
    </React.Fragment>
  );
};

const ActiveField = ({form}) => {
  const field = 'isActive';
  const userActiveOptions = [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ];
  return (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={field}
      label={'Active'}
      input={{
        options: userActiveOptions,
        setFieldValue: form.setFieldValue,
        style: {
          width: '100%',
        },
      }}
    />
  );
};

const EmployeeFormFields = ({isUpdate, form, loading, organization, handleSubmit}) => {
  const responsive = useResponsive();

  return (
    <Container>
      <Section index={0}>
        <FieldInput
          {...form}
          name={'firstName'}
          label={'Name'}
          isRequired
          input={{
            autoFocus: true,
            placeholder: 'Enter name',
          }}
        />
      </Section>
      <RowSpace {...responsive} />
      <Section index={1}>
        <FieldInput
          {...form}
          component={PhoneInput}
          name={'phoneNumber'}
          label={'Phone Number'}
          isRequired
          input={{
            placeholder: 'Enter a phone number',
            setFieldValue: form.setFieldValue,
          }}
        />
      </Section>
      <Section index={2}>
        <RowSpace {...responsive} />
        <FieldInput
          {...form}
          name={'payrollIdentifier'}
          label={'Payroll Identifier'}
          input={{
            placeholder: 'Enter payroll identifier',
          }}
        />
      </Section>
      <Section index={3}>
        <RowSpace {...responsive} />
        <FieldInput
          {...form}
          name={'tabletPin'}
          label={'Tablet Passcode (Minimum of 4 digits)'}
          input={{
            placeholder: 'Enter tablet pin',
          }}
        />
      </Section>
      {organization.features.isEnabledUserBillingHourlyRate && (
        <Section index={4}>
          <RowSpace {...responsive} />
          <FieldInput
            {...form}
            component={CurrencyInput}
            name={'billingHourlyRate'}
            label={'Compensation Hourly Rate'}
            input={{
              component: TextInput,
              placeholder: 'Enter compensation hourly rate',
              setFieldValue: form.setFieldValue,
              setFieldTouched: form.setFieldTouched,
            }}
          />
        </Section>
      )}
      {organization.features.isEnabledUserCompensationPercentage && (
        <Section index={5}>
          <RowSpace {...responsive} />
          <FieldInput
            {...form}
            component={PercentInput}
            name={'compensationPercentage'}
            label={'Compensation %'}
            input={{
              component: TextInput,
              placeholder: 'Enter a percent',
              setFieldValue: form.setFieldValue,
              setFieldTouched: form.setFieldTouched,
            }}
          />
        </Section>
      )}
      <Section index={6}>
        <RowSpace {...responsive} />
        <PositionField organization={organization} form={form} />
      </Section>
      <Section index={7}>
        <RowSpace {...responsive} />
        <PrimaryPositionField organization={organization} form={form} />
      </Section>
      {isUpdate && (
        <React.Fragment>
          <RowSpace {...responsive} />
          <Section index={8}>
            <ActiveField form={form} />
          </Section>
        </React.Fragment>
      )}
      {organization.shouldCreateCommercialMoves && (
        <Section index={9}>
          <RowSpace {...responsive} />
          <FieldInput
            {...form}
            name={'branchCode'}
            label={'Branch Code'}
            input={{
              placeholder: 'Enter a branch code',
            }}
          />
        </Section>
      )}
      {EMPLOYEE_FEATURES.map((employeeFeature) => (
        <Section index={10} key={employeeFeature.name}>
          <RowSpace {...responsive} />
          <CheckboxField
            {...form}
            name={employeeFeature.name}
            label={employeeFeature.label}
            values={form.values.features}
            setFieldValue={(name, value) =>
              form.setFieldValue('features', getUpdatedFeatures({form, name, value}))
            }
          />
          <CheckboxDescription>{employeeFeature.description}</CheckboxDescription>
        </Section>
      ))}
      <RowSpace {...responsive} />
      <Button loading={loading} onPress={handleSubmit}>
        <Text>{`${isUpdate ? 'Update' : 'Create'} Crew Member`}</Text>
      </Button>
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
EmployeeFormFields.propTypes = {
  form: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

EmployeeFormFields.defaultProps = {
  isUpdate: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmployeeFormFields.fragment = gql`
  ${Organization.getCompanySettingsAllMoverPositionsByRole.fragment}

  fragment EmployeeFormFields on Organization {
    id
    shouldCreateCommercialMoves
    features {
      isEnabledUserBillingHourlyRate: isEnabled(feature: "USER_BILLING_HOURLY_RATE")
      isEnabledUserCompensationPercentage: isEnabled(feature: "USER_COMPENSATION_PERCENTAGE")
      isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
    }
    companySettings {
      allMoverPositions {
        id
        name
        isDriver
        isDeleted
      }
    }
    ...Organization_getCompanySettingsAllMoverPositionsByRole
  }
`;

export default EmployeeFormFields;
