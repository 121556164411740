// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const newFromCalendarDayV4 = withFragment(
  (calendarDay) => {
    return {
      dayId: (calendarDay as any).dayId,
      notes: (calendarDay as any).notes,
      isPackingJobsLocked: false,
    };
  },
  gql`
    fragment DayForm_newFromCalendarDayV4 on CalendarDayV4 {
      dayId
      notes
    }
  `,
);

const _new = ({organizationId, value}: any) => ({
  notes: '',
  value,
  isPackingJobsLocked: false,
  organizationId,
});

const edit = withFragment(
  (day) => ({
    dayId: (day as any).id,
    notes: (day as any).notes,
    value: (day as any).value,
    isPackingJobsLocked: (day as any).isPackingJobsLocked,
    organizationId: (day as any).organizationId,
  }),
  gql`
    fragment DayForm_edit on Day {
      id
      notes
      value
      isPackingJobsLocked
      organizationId
    }
  `,
);

const upsert = withFragment(
  (day, {organizationId, value}) => {
    if (day) {
      return edit(day);
    } else {
      return _new({organizationId, value});
    }
  },
  gql`
    fragment DayForm_upsert on Day {
      id
      ...DayForm_edit
    }
  `,
);

const toForm = ({dayId, notes, organizationId, isPackingJobsLocked, value}: any) => ({
  dayId,
  notes,
  organizationId,
  isPackingJobsLocked,
  value,
});

const toMutation = ({dayId, notes, organizationId, isPackingJobsLocked, value}: any) => ({
  dayId,
  notes,
  organizationId,
  isPackingJobsLocked,
  value,
});

const DayForm = {
  newFromCalendarDayV4,
  edit,
  upsert,
  toForm,
  toMutation,
};

export default DayForm;
