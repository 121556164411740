// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import JobPrimaryStatusForm from '@shared/modules/Job/forms/JobPrimaryStatusForm';

const useUpdateJobPrimaryStatusMutation = ({jobPrimaryStatusForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      jobPrimaryStatusForm: JobPrimaryStatusForm.toForm(jobPrimaryStatusForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateJobPrimaryStatusMutation.mutation,
    variables: {
      jobPrimaryStatusForm: JobPrimaryStatusForm.toMutation(form.values.jobPrimaryStatusForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateJobPrimaryStatusMutation.mutation = gql`
  mutation useUpdateJobPrimaryStatusMutation($jobPrimaryStatusForm: JobPrimaryStatusForm!) {
    response: updateJobPrimaryStatus(jobPrimaryStatusForm: $jobPrimaryStatusForm) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default useUpdateJobPrimaryStatusMutation;
