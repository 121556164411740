// Libraries

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import TagKind from '@shared/modules/Tag/enums/TagKind';

export interface TagForm {
  tagId: number | null;
  organizationId: number;
  name: string;
  description: string;
  category: string;
  emoji: string;
  kind: string;
  isArchived: boolean;
  isDeleted: boolean;
}

export interface TagFormAsMutation {
  tagId: number | null;
  organizationId: number;
  name: string;
  description: string;
  category: string;
  emoji: string;
  kind: string;
  isArchived: boolean;
  isDeleted: boolean;
}

const _new = ({organizationId}: {organizationId: number}): TagForm => ({
  tagId: null,
  organizationId,
  name: '',
  description: '',
  category: '',
  emoji: '🚛',
  kind: TagKind.CUSTOM,
  isArchived: false,
  isDeleted: false,
});

const edit = withFragment(
  (tag: any): TagForm => ({
    tagId: tag.id,
    organizationId: tag.organizationId,
    name: tag.name,
    description: tag.description,
    category: tag.category,
    emoji: tag.emoji,
    kind: tag.kind,
    isArchived: tag.isDeleted,
    isDeleted: tag.isActive,
  }),
  gql`
    fragment TagForm_edit on Tag {
      id
      organizationId
      name
      description
      category
      emoji
      kind
      isArchived
      isDeleted
    }
  `,
);

const toForm = (tagForm: TagForm): TagForm => ({
  tagId: tagForm.tagId,
  organizationId: tagForm.organizationId,
  name: tagForm.name,
  description: tagForm.description,
  category: tagForm.category,
  emoji: tagForm.emoji,
  kind: tagForm.kind,
  isArchived: tagForm.isDeleted,
  isDeleted: tagForm.isDeleted,
});

const toMutation = (tagForm: TagForm): TagFormAsMutation => ({
  tagId: tagForm.tagId,
  organizationId: tagForm.organizationId,
  name: tagForm.name,
  description: tagForm.description,
  category: tagForm.category,
  emoji: tagForm.emoji,
  kind: tagForm.kind,
  isArchived: tagForm.isDeleted,
  isDeleted: tagForm.isDeleted,
});

const WorkflowForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default WorkflowForm;
