// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteBillItemTypeMutation = ({billItemTypeId, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      billItemTypeId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteBillItemTypeMutation.mutation,
    variables: {
      billItemTypeId: form.values.billItemTypeId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteBillItemTypeMutation.mutation = gql`
  mutation useDeleteBillItemTypeMutation($billItemTypeId: Int!) {
    response: deleteBillItemType(billItemTypeId: $billItemTypeId) {
      ${gql.errors}
      billItemType {
        id
      }
    }
  }
`;

export default useDeleteBillItemTypeMutation;
