// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';

const Container = Styled.View`
  width: 100%;
  z-index: ${({index}) => 100 - index};
`;

const AccountingItemField = ({form, field, fieldIndex, organization}) => {
  const externalInvoiceItemId = `${field}.externalInvoiceItemId`;

  return (
    <Container index={fieldIndex}>
      <FieldInput
        {...form}
        component={DropdownInput}
        name={externalInvoiceItemId}
        label={'Accounting Item'}
        tooltip={
          organization.activeCodatIntegration
            ? `Select the item from ${organization.activeCodatIntegration.sourceKindDisplayName} that this bill item will export to.`
            : null
        }
        input={{
          options: !_.isEmpty(organization.externalInvoiceItems)
            ? organization.externalInvoiceItems.map((externalInvoiceItem) => ({
                label: externalInvoiceItem.name,
                value: externalInvoiceItem.id,
                description: externalInvoiceItem.description,
              }))
            : [],
          showDescriptionInOption: true,
          isSearchable: true,
          isClearable: true,
          placeholder: 'Type to search',
          setFieldValue: form.setFieldValue,
          style: {
            width: '100%',
          },
        }}
        style={{width: '100%'}}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AccountingItemField.fragment = gql`
  fragment AccountingItemField on Organization {
    id
    activeCodatIntegration {
      id
      sourceKindDisplayName
    }
    externalInvoiceItems {
      id
      name
      description
    }
  }
`;

export default AccountingItemField;
