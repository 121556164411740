// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled, SupermoveLogo} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const AvatarContainer = Styled.View`
  height: ${({desktop}) => (desktop ? '28' : '32')}px;
  min-width: ${({desktop}) => (desktop ? '28' : '32')}px;
  padding-horizontal: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${({isLogo}) => (isLogo ? colors.black : colors.gray.border)}
  border-radius: 4px;
`;

const AvatarText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  color: ${colors.gray.primary}
`;

const UserAvatar = ({user, tooltipPlacement, style, TextComponent, logoSize}) => {
  const responsive = useResponsive();
  return (
    <TextTooltip text={user ? user.fullName : 'Supermove'} placement={tooltipPlacement}>
      <AvatarContainer style={style} isLogo={!user} {...responsive}>
        {user ? (
          <TextComponent responsive={responsive}>{user.initials}</TextComponent>
        ) : (
          <SupermoveLogo size={logoSize || (responsive.desktop ? 10 : 12)} />
        )}
      </AvatarContainer>
    </TextTooltip>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UserAvatar.propTypes = {
  user: PropTypes.object,
  tooltipPlacement: PropTypes.string,
  TextComponent: PropTypes.object,
  logoSize: PropTypes.number,
};

UserAvatar.defaultProps = {
  user: null,
  tooltipPlacement: 'bottom',
  TextComponent: AvatarText,
  logoSize: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserAvatar.fragment = gql`
  fragment UserAvatar on User {
    id
    initials
    fullName
  }
`;

export default UserAvatar;
