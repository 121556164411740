// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import OrganizationVirtualWalkthroughSettingsForm from '@shared/modules/Organization/forms/OrganizationVirtualWalkthroughSettingsForm';

// App

const useUpdateOrganizationVirtualWalkthroughSettingsMutation = ({
  organizationVirtualWalkthroughSettingsForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      organizationVirtualWalkthroughSettingsForm: OrganizationVirtualWalkthroughSettingsForm.toForm(
        organizationVirtualWalkthroughSettingsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationVirtualWalkthroughSettingsMutation.mutation,
    variables: {
      organizationVirtualWalkthroughSettingsForm:
        OrganizationVirtualWalkthroughSettingsForm.toMutation(
          form.values.organizationVirtualWalkthroughSettingsForm,
        ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationVirtualWalkthroughSettingsMutation.mutation = gql`
  mutation useUpdateOrganizationVirtualWalkthroughSettingsMutation($organizationVirtualWalkthroughSettingsForm: OrganizationVirtualWalkthroughSettingsForm!) {
    response: updateOrganizationVirtualWalkthroughSettings(organizationVirtualWalkthroughSettingsForm: $organizationVirtualWalkthroughSettingsForm) {
      ${gql.errors}
      organization {
        id
        virtualWalkthroughPhotoFile {
          id
          downloadUrl
          filename
        }
      }
    }
  }
`;

export default useUpdateOrganizationVirtualWalkthroughSettingsMutation;
