// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useNavigationDOM,
  useInternet,
  useDebouncedCallback,
  useEffect,
  useResponsive,
  useState,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Currency} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';

const HeaderContainer = Styled.View`
  height: 150px;
  flex-direction: row;
  justify-content: space-between;
  padding-vertical: 24px;
  padding-horizontal: ${({isDense}) => (isDense ? '16px' : '24px')};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
  border-bottom-style: solid;
`;

const HeaderColumnLeft = Styled.View`
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const HeaderColumnRight = Styled.View`
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  flex: 2;
`;

const HeaderTitleText = Styled.Text`
  ${Typography.PageHeading}
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
  color: ${colors.gray.secondary};
`;

const HeaderSubtitleText = Styled.Text`
  ${Typography.Micro}
`;

const LastSavedText = Styled.Text`
  ${Typography.Micro}
`;

const InventoryMetricValueText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const InventoryMetricContainer = Styled.View`
  flex-direction: column;
  align-items: center;
  min-width: ${({isDense}) => (isDense ? '60px' : '64px')};
`;

const InfoTitleText = Styled.Text`
  ${Typography.Micro}
`;

const InfoValueText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.primary};
`;

const InfoText = ({title, value}) => {
  return (
    <Row>
      <InfoTitleText>{title}</InfoTitleText>
      <InfoValueText>{value}</InfoValueText>
    </Row>
  );
};

const getDateString = ({startDate, endDate}) => {
  if (!startDate && !endDate) {
    return '';
  }
  const displayStartDate = Datetime.convertToDisplayDate(startDate, Datetime.DISPLAY_SHORT_DATE);
  const displayEndDate = Datetime.convertToDisplayDate(endDate, Datetime.DISPLAY_SHORT_DATE);
  if (displayStartDate !== displayEndDate) {
    return `${displayStartDate} - ${displayEndDate}`;
  }
  return displayStartDate;
};

const getLocationString = ({startLocation, endLocation}) => {
  if (!startLocation && !endLocation) {
    return '';
  }
  return `${startLocation?.city}, ${startLocation?.state} - ${endLocation?.city}, ${endLocation?.state}`;
};

const ProjectInfo = ({project}) => {
  const responsive = useResponsive();
  return (
    <Row>
      <InfoText title={'Customer: '} value={project.customer.fullName} />
      {responsive.large && (
        <React.Fragment>
          <Space width={24} />
          <InfoText
            title={'Date(s): '}
            value={getDateString({startDate: project.startDate, endDate: project.endDate})}
          />
          <Space width={24} />
          <InfoText
            title={'Origin & Destination: '}
            value={getLocationString({
              startLocation: project.startLocation,
              endLocation: project.endLocation,
            })}
          />
        </React.Fragment>
      )}
    </Row>
  );
};

const InventoryMetric = ({isDense, title, value}) => {
  return (
    <InventoryMetricContainer isDense={isDense}>
      <HeaderSubtitleText>{title}</HeaderSubtitleText>
      <InventoryMetricValueText>{value}</InventoryMetricValueText>
    </InventoryMetricContainer>
  );
};

const InventoryInfo = ({isDense, inventoryRoomsInfo}) => {
  const SPACE_WIDTH = isDense ? 4 : 8;
  return (
    <Row>
      <InventoryMetric
        isDense={isDense}
        title={'EST.PRICE'}
        value={Currency.display(inventoryRoomsInfo.price)}
      />
      <Space width={SPACE_WIDTH} />
      <InventoryMetric isDense={isDense} title={'ROOMS'} value={inventoryRoomsInfo.roomCount} />
      <Space width={SPACE_WIDTH} />
      <InventoryMetric isDense={isDense} title={'TAKE'} value={inventoryRoomsInfo.takeCount} />
      <Space width={SPACE_WIDTH} />
      <InventoryMetric isDense={isDense} title={'LEAVE'} value={inventoryRoomsInfo.leaveCount} />
      <Space width={SPACE_WIDTH} />
      <InventoryMetric
        isDense={isDense}
        title={'VOLUME'}
        value={`${inventoryRoomsInfo.volume} cu ft`}
      />
      <Space width={SPACE_WIDTH} />
      <InventoryMetric
        isDense={isDense}
        title={'WEIGHT'}
        value={`${inventoryRoomsInfo.weight} lbs`}
      />
    </Row>
  );
};

const LastSavedInfo = ({inventory, isConnected}) => {
  const {lastSyncedAt} = inventory;
  const [lastSyncedText, setLastSyncedText] = useState(Datetime.timeAgo(inventory.lastSyncedAt));

  useEffect(() => {
    if (lastSyncedAt) {
      setLastSyncedText(Datetime.timeAgo(lastSyncedAt));
    }
  }, [lastSyncedAt]);

  return (
    <Row>
      {!isConnected && (
        <React.Fragment>
          <Icon
            source={Icon.ExclamationTriangle}
            color={colors.red.warning}
            size={Icon.Sizes.ExtraSmall}
          />
          <Space width={8} />
          <LastSavedText style={{color: colors.red.warning}}>You are offline. </LastSavedText>
        </React.Fragment>
      )}

      <LastSavedText style={{color: colors.gray.secondary}}>
        Last Saved: {lastSyncedText}
      </LastSavedText>
    </Row>
  );
};

const EditInventoryPageHeader = ({
  inventoryRoomsForm,
  inventory,
  handleSubmit,
  isSaving,
  setIsSaving,
}) => {
  const {navigator, params} = useNavigationDOM();
  const {isConnected} = useInternet();
  const responsive = useResponsive();
  const isDense = responsive.mobile || responsive.tablet;
  const inventoryRoomsInfo = InventoryRoomsForm.getInfo(
    _.get(inventoryRoomsForm.values, 'inventoryRoomsForm'),
  );
  const handleSubmitIfConnected = (isConnected) => {
    if (isConnected && inventoryRoomsInfo.hasUnsavedChanges) {
      setIsSaving(true);
      handleSubmit();
    }
  };

  // Auto save every 10 seconds if changes are detected
  const handleDebouncedSave = useDebouncedCallback(handleSubmitIfConnected, 10 * 1000);
  useEffect(
    () => handleDebouncedSave(isConnected),
    [handleDebouncedSave, isConnected, inventoryRoomsForm],
  );

  return (
    <HeaderContainer isDense={isDense}>
      <HeaderColumnLeft>
        <HeaderButton
          onPress={() => {
            if (params.back) {
              navigator.goBack();
            } else {
              navigator.replace(`/projects/${inventory.project.uuid}`);
            }
            handleDebouncedSave.flush();
          }}
        >
          <Icon source={Icon.ChevronLeft} color={colors.gray.secondary} size={Icon.Sizes.Medium} />
          <Space width={8} />
          <HeaderText>Back to Project</HeaderText>
        </HeaderButton>
        <HeaderTitleText>Survey for Project {inventory.project.identifier}</HeaderTitleText>
        <ProjectInfo project={inventory.project} />
      </HeaderColumnLeft>
      <HeaderColumnRight>
        <Row />
        <Row>
          <InventoryInfo isDense={isDense} inventoryRoomsInfo={inventoryRoomsInfo} />
          <Space width={isDense ? 8 : 24} />
          <Button
            text={'Save'}
            isSubmitting={isSaving}
            isDisabled={!inventoryRoomsInfo.hasUnsavedChanges}
            iconLeft={Icon.Check}
            onPress={() => {
              setIsSaving(true);
              handleSubmit();
            }}
          />
        </Row>
        <LastSavedInfo inventory={inventory} isConnected={isConnected} />
      </HeaderColumnRight>
    </HeaderContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditInventoryPageHeader.fragment = gql`
  fragment EditInventoryPageHeader on Inventory {
    id
    lastSyncedAt
    project {
      id
      uuid
      identifier
      startDate
      endDate
      startLocation {
        id
        city
        state
      }
      endLocation {
        id
        city
        state
      }
      customer {
        id
        fullName
      }
    }
  }
`;

export default EditInventoryPageHeader;
