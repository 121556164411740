// Libararies
import React from 'react';

// Supermove
import {Drawer, Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import TripTicketJobsList from 'modules/Dispatch/LongDistance/Trip/components/TripTicketJobsList';
import LongDistanceDispatchTripCard from 'modules/Dispatch/LongDistance/components/LongDistanceDispatchTripCard';

const DriverTripViewDrawerContainer = Styled.View`
  width: 424px;
  flex: 1;
  background-color: ${colors.white};
`;

const TripSummaryContainer = Styled.View`
  padding-horizontal: 16px;
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 16px;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading2}
`;

const InformationMessageContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  padding: 8px;
  background-color: ${colors.gray.background};
`;

const InformationMessageText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.primary}
`;

const Header = ({handleClose}) => {
  return (
    <HeaderContainer>
      <Space width={16} />
      <HeaderText>Driver Trip View</HeaderText>
      <Space style={{flex: 1}} />
      <QuaternaryButton onPress={handleClose}>
        <Icon source={Icon.Times} size={18} color={colors.gray.primary} />
      </QuaternaryButton>
    </HeaderContainer>
  );
};

const InformationMessage = () => {
  return (
    <InformationMessageContainer>
      <Space width={4} />
      <Icon source={Icon.InfoCircle} size={13} color={colors.gray.tertiary} />
      <Space width={10} />
      <InformationMessageText>
        This is the view that the driver sees during their trip
      </InformationMessageText>
    </InformationMessageContainer>
  );
};

const DriverTripViewDrawerContent = ({trip}) => {
  return (
    <React.Fragment>
      <TripSummaryContainer>
        <Space height={16} />
        <InformationMessage />
        <LongDistanceDispatchTripCard trip={trip} />
        <Line />
      </TripSummaryContainer>
      <TripTicketJobsList jobs={trip.packLoadDeliveryJobs} />
    </React.Fragment>
  );
};

const DriverTripViewDrawer = ({isOpen, handleClose, tripUuid}) => {
  const {data, loading} = useQuery(DriverTripViewDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {tripUuid},
  });
  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      <DriverTripViewDrawerContainer>
        <Header handleClose={handleClose} />
        <Line />
        <Loading loading={loading} as={PageLoadingIndicator}>
          {() => <DriverTripViewDrawerContent trip={data.trip} />}
        </Loading>
      </DriverTripViewDrawerContainer>
    </Drawer>
  );
};

DriverTripViewDrawer.query = gql`
  ${LongDistanceDispatchTripCard.fragment}
  ${TripTicketJobsList.fragment}

  query DriverTripViewDrawer(
    $tripUuid: String!,
  ) {
    ${gql.query}
    trip(tripUuid: $tripUuid) {
      id
      packLoadDeliveryJobs {
        id
        ...TripTicketJobsList
      }
      ...LongDistanceDispatchTripCard
    }
  }
`;

export default DriverTripViewDrawer;
