// Library
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';
import {Datetime, withFragment} from '@supermove/utils';

// App
import ValueForm from '@shared/modules/Billing/forms/ValueForm';
import ClientForm from '@shared/modules/Client/forms/ClientForm';

const newForOrganization = withFragment(
  (organization) => ({
    projectId: null,
    sourceProjectId: null,
    projectTypeId: null,
    organizationId: (organization as any).id,
    assignedOrganizationId: (organization as any).features.isEnabledMultibranchStorage
      ? (organization as any).isPrimaryMultibranch
        ? null
        : (organization as any).features.isEnabledStorageMultibranchAssignProject
          ? (organization as any).ownerOrganization.id
          : (organization as any).id
      : (organization as any).id,
    bookedById: null,
    coordinatedById: null,
    additionalSalespersonIds: [],
    collectionIds: [],
    name: '',
    identifier: '',
    startDate: '',
    endDate: '',
    officeNotes: '',
    warehouseNotes: '',
    referralSource: '',
    referralDetails: '',
    isTest: false,
    clientForm: ClientForm.new({organizationId: (organization as any).id}),
    billingClientForm: null,
    valueForms: [],
    // Private Fields
    isProjectNameVisible: false,
    isClientSearchEnabled: true,
    isSameBillingClient: true,
  }),
  gql`
    ${Organization.makeProjectValueFormsFromOrganizationVariables.fragment}
    fragment StorageProjectForm_newForOrganization on Organization {
      id
      isFranchise
      isPrimaryMultibranch
      ownerOrganization {
        id
      }
      features {
        isEnabledMultibranchStorage: isEnabled(feature: "MULTIBRANCH_STORAGE")
        isEnabledStorageMultibranchAssignProject: isEnabled(
          feature: "STORAGE_MULTIBRANCH_ASSIGN_PROJECT"
        )
      }
      ...Organization_makeProjectValueFormsFromOrganizationVariables
    }
  `,
);

const newFromMoveProject = withFragment(
  (project, {collectionIds} = {}) => ({
    projectId: null,
    sourceProjectId: (project as any).id,
    projectTypeId: null,
    organizationId: (project as any).organization.features.isEnabledMultibranchStorage
      ? (project as any).owningOrganization.id
      : (project as any).organization.id,
    assignedOrganizationId: (project as any).organization.features.isEnabledMultibranchStorage
      ? (project as any).owningOrganization.id
      : (project as any).organization.id,
    bookedById: (project as any).bookedById,
    coordinatedById: (project as any).coordinatedById,
    additionalSalespersonIds: (project as any).additionalSalespersonIds,
    collectionIds,
    name: '',
    identifier: '',
    startDate: (project as any).endDate,
    endDate: '',
    officeNotes: '',
    warehouseNotes: '',
    referralSource: (project as any).referralSource,
    referralDetails: (project as any).referralDetails,
    isTest: (project as any).isTest,
    clientForm: ClientForm.edit((project as any).client),
    billingClientForm: (project as any).billingClient
      ? ClientForm.edit((project as any).billingClient)
      : ClientForm.edit((project as any).client),
    valueForms: [],
    // Private Fields
    isProjectNameVisible: false,
    isClientSearchEnabled: false,
    isSameBillingClient:
      (project as any).billingClient && (project as any).client
        ? (project as any).billingClient.id === (project as any).client.id
        : !(project as any).billingClient && !(project as any).client,
  }),
  gql`
    ${ClientForm.edit.fragment}
    ${Organization.makeProjectValueFormsFromOrganizationVariables.fragment}
    fragment StorageProjectForm_newFromMoveProject on Project {
      id
      bookedById
      coordinatedById
      additionalSalespersonIds
      endDate
      referralSource
      referralDetails
      isTest
      owningOrganization {
        id
      }
      client {
        id
        ...ClientForm_edit
      }
      billingClient {
        id
        ...ClientForm_edit
      }
      organization {
        id
        features {
          isEnabledMultibranchStorage: isEnabled(feature: "MULTIBRANCH_STORAGE")
        }
        ...Organization_makeProjectValueFormsFromOrganizationVariables
      }
    }
  `,
);

const cancel = withFragment(
  (project) => ({
    projectId: (project as any).id,
  }),
  gql`
    fragment StorageProjectForm_cancel on Project {
      id
    }
  `,
);

const edit = withFragment(
  (project, {isClientSearchEnabled} = {}) => ({
    projectId: (project as any).id,
    sourceProjectId: (project as any).sourceProjectId,
    projectTypeId: (project as any).projectTypeId,
    organizationId: (project as any).organizationId,
    assignedOrganizationId: (project as any).assignedOrganizationId,
    bookedById: (project as any).bookedById,
    coordinatedById: (project as any).coordinatedById,
    additionalSalespersonIds: (project as any).additionalSalespersonIds,
    collectionIds: (project as any).collections.map((collection: any) => collection.id),
    name: (project as any).name,
    identifier: (project as any).identifier,
    startDate: (project as any).startDate,
    endDate: (project as any).endDate,
    officeNotes: (project as any).officeNotes,
    warehouseNotes: (project as any).warehouseNotes,
    referralSource: (project as any).referralSource,
    referralDetails: (project as any).referralDetails,
    isTest: (project as any).isTest,
    clientForm: ClientForm.edit((project as any).client),
    billingClientForm: (project as any).billingClient
      ? ClientForm.edit((project as any).billingClient)
      : ClientForm.edit((project as any).client),
    valueForms: Organization.makeProjectValueFormsFromProjectValues({project}),
    // Private Fields
    isProjectNameVisible: !!(project as any).name,
    isCompanyNameVisible: !!(project as any).name,
    isClientSearchEnabled,
    isSameBillingClient:
      (project as any).billingClient && (project as any).client
        ? (project as any).billingClient.id === (project as any).client.id
        : !(project as any).billingClient && !(project as any).client,
  }),
  gql`
    ${ClientForm.edit.fragment}
    ${Organization.makeProjectValueFormsFromProjectValues.fragment}
    ${ValueForm.edit.fragment}

    fragment StorageProjectForm_edit on Project {
      id
      sourceProjectId
      projectTypeId
      organizationId
      assignedOrganizationId
      bookedById
      coordinatedById
      additionalSalespersonIds
      name
      identifier
      startDate
      endDate
      officeNotes
      warehouseNotes
      referralSource
      referralDetails
      isTest
      client {
        id
        ...ClientForm_edit
      }
      billingClient {
        id
        ...ClientForm_edit
      }
      collections {
        id
      }
      values {
        id
        ...ValueForm_edit
      }
      ...Organization_makeProjectValueFormsFromProjectValues
    }
  `,
);

const toForm = ({
  projectId,
  sourceProjectId,
  projectTypeId,
  organizationId,
  assignedOrganizationId,
  bookedById,
  coordinatedById,
  additionalSalespersonIds,
  collectionIds,
  name,
  identifier,
  startDate,
  endDate,
  officeNotes,
  warehouseNotes,
  referralSource,
  referralDetails,
  isTest,
  clientForm,
  billingClientForm,
  valueForms,

  // Private Fields
  isProjectNameVisible,

  isCompanyNameVisible,
  isClientSearchEnabled,
  isSameBillingClient,
}: any) => ({
  projectId,
  sourceProjectId,
  projectTypeId: _.toNumber(projectTypeId),
  organizationId: _.toNumber(organizationId),
  assignedOrganizationId: _.toNumber(assignedOrganizationId),
  bookedById,
  coordinatedById,
  additionalSalespersonIds,
  collectionIds,
  name,
  identifier,
  startDate: Datetime.toFormDate(startDate),
  endDate: Datetime.toFormDate(endDate),
  officeNotes,
  warehouseNotes,
  referralSource,
  referralDetails,
  isTest,
  clientForm: clientForm && ClientForm.toForm(clientForm),
  billingClientForm: billingClientForm
    ? ClientForm.toForm(billingClientForm)
    : clientForm && ClientForm.toForm(clientForm),
  valueForms: _.map(valueForms, (valueForm) => ValueForm.toForm(valueForm)),

  // Private Fields
  isProjectNameVisible,
  isCompanyNameVisible,
  isClientSearchEnabled,
  isSameBillingClient,
});

const toMutation = ({
  projectId,
  sourceProjectId,
  organizationId,
  assignedOrganizationId,
  projectTypeId,
  bookedById,
  coordinatedById,
  additionalSalespersonIds,
  collectionIds,
  name,
  identifier,
  startDate,
  endDate,
  officeNotes,
  warehouseNotes,
  referralSource,
  referralDetails,
  isTest,
  clientForm,
  billingClientForm,
  valueForms,
  isSameBillingClient,
}: any) => ({
  projectId,
  sourceProjectId,
  organizationId,
  assignedOrganizationId,
  projectTypeId,
  bookedById: bookedById === '' ? null : bookedById,
  coordinatedById: coordinatedById === '' ? null : coordinatedById,
  additionalSalespersonIds,
  collectionIds,
  name,
  identifier,
  startDate: startDate ? Datetime.toMutationDate(startDate) : null,
  endDate: endDate ? Datetime.toMutationDate(endDate) : null,
  officeNotes,
  warehouseNotes,
  referralSource,
  referralDetails,
  isTest,
  clientForm: clientForm && ClientForm.toMutation(clientForm),
  billingClientForm: billingClientForm
    ? ClientForm.toMutation(billingClientForm)
    : clientForm
      ? ClientForm.toMutation(clientForm)
      : null,
  valueForms: valueForms.map((valueForm: any) => ValueForm.toMutation(valueForm)),
  isSameBillingClient,
});

const StorageProjectForm = {
  newForOrganization,
  newFromMoveProject,
  edit,
  cancel,
  toForm,
  toMutation,
};

export default StorageProjectForm;
