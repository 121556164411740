// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, MultiDropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import TeamMemberForm from '@shared/modules/TaskManagement/forms/TeamMemberForm';

const Container = Styled.View`
`;

const Placeholder = Styled.Text`
  ${Typography.Body};
  color: ${colors.gray.tertiary};
  position: absolute;
  top: 33px;
  left: 14px;
  z-index: 100;
`;

const TeamMembersListContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -4px;
`;

const ChipContainer = Styled.ButtonV2`
  flex-direction: row;
  margin-top: 8px;
  height: 24px;
  align-items: center;
  padding-horizontal: 8px;
  background-color: ${colors.gray.border}
  border-radius: 16px;
  margin-left: 4px;
`;

const ChipText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.primary};
`;

const handleAddTeamMemberForm = ({form, field, userId, fullName}) => {
  const teamId = _.get(form.values, `${field}.team_id`);
  const teamMemberForm = TeamMemberForm.new({teamId, userId, fullName});
  const teamMemberForms = _.get(form.values, `${field}.teamMemberForms`);
  form.setFieldValue(`${field}.teamMemberForms`, [...teamMemberForms, teamMemberForm]);
};

const handleRemoveTeamMemberForm = ({form, field, userId}) => {
  const teamMemberForms = _.get(form.values, `${field}.teamMemberForms`);
  const updatedTeamMemberForms = _.filter(
    teamMemberForms,
    (form) => form.userId !== _.toString(userId),
  );
  form.setFieldValue(`${field}.teamMemberForms`, updatedTeamMemberForms);
};

const handleRemoveTeamMember = ({form, field, userId}) => {
  const teamMemberUserIds = _.get(form.values, `${field}.teamMemberUserIds`);
  const updatedTeamMemberUserIds = _.filter(teamMemberUserIds, (id) => id !== userId);
  handleRemoveTeamMemberForm({form, field, userId});
  form.setFieldValue(`${field}.teamMemberUserIds`, updatedTeamMemberUserIds);
};

const getTeamMemberDropdownOptions = ({form, field, organization, isCrewTeam}) => {
  const StaffOptions = isCrewTeam
    ? getCrewStaffOptions({organization})
    : getOfficeStaffOptions({organization});
  const teamMemberForms = _.get(form.values, `${field}.teamMemberForms`);
  const inactiveTeamMemberForms = _.filter(
    teamMemberForms,
    (teamMemberForm) => !teamMemberForm.isActive,
  );
  const inactiveTeamMemberOptions = inactiveTeamMemberForms.map(({userId, fullName}) => ({
    value: _.toString(userId),
    label: `[Inactive] ${fullName}`,
  }));
  return [...StaffOptions, ...inactiveTeamMemberOptions];
};

const getOfficeStaffOptions = ({organization}) => {
  return Organization.getOfficeStaffDropdownOptions(organization).map((option) => ({
    ...option,
    value: _.toString(option.value),
  }));
};

const getCrewStaffOptions = ({organization}) => {
  return Organization.getMoverStaffDropdownOptions(organization).map((option) => ({
    ...option,
    value: _.toString(option.value),
  }));
};

const NameField = ({form, field, isDisabled}) => {
  return (
    <FieldInput.Memoized
      {...form}
      label={'Team Name'}
      name={`${field}.name`}
      isRequired
      input={{
        autoFocus: true,
        disabled: isDisabled,
        placeholder: 'Enter team name',
      }}
    />
  );
};

const TeamMemberDropdown = ({form, field, organization, isCrewTeam}) => {
  const [inputValue, setInputValue] = useState(false);
  return (
    <Container>
      <FieldInput.Memoized
        {...form}
        component={MultiDropdownInput}
        label={'Team Members'}
        name={`${field}.teamMemberUserIds`}
        isRequired
        input={{
          isPortaled: true,
          placeholder: '',
          options: getTeamMemberDropdownOptions({form, field, organization, isCrewTeam}),
          setFieldValue: form.setFieldValue,
          style: {
            flex: 1,
            paddingLeft: 2,
          },
          isClearable: true,
          hideSelectedOptions: false,
          closeMenuOnSelect: false,
          onInputChange: (searchText) => setInputValue(searchText),
          onSelectValue: (value, {option}) => {
            handleAddTeamMemberForm({form, field, userId: value, fullName: option.label});
            form.setErrors({});
          },
          onDeselectValue: (value) => {
            handleRemoveTeamMemberForm({form, field, userId: value});
          },
          components: {
            MultiValueContainer: () => '',
            Option: MultiDropdownCheckboxInput.CheckboxOption,
          },
        }}
        style={{backgroundColor: 'transparent'}}
      />
      {!inputValue && <Placeholder pointerEvents={'none'}>Select team members</Placeholder>}
    </Container>
  );
};

const TeamMembersListChip = ({form, field, teamMemberForm}) => {
  const {isActive, userId, fullName} = teamMemberForm;
  return (
    <ChipContainer
      onPress={() => handleRemoveTeamMember({form, field, userId: _.toString(userId)})}
    >
      <ChipText>{`${isActive ? '' : '[Inactive] '}${fullName}`}</ChipText>
      <Space width={8} />
      <Icon source={Icon.Xmark} size={13} color={colors.gray.primary} />
    </ChipContainer>
  );
};

const TeamMembersList = ({form, field}) => {
  const teamMemberForms = _.get(form.values, `${field}.teamMemberForms`);
  const sortedTeamMemberForms = _.sortBy(
    teamMemberForms,
    (teamMemberForm) => teamMemberForm.fullName,
  );

  return (
    <TeamMembersListContainer>
      {sortedTeamMemberForms.map((teamMemberForm) => {
        return (
          <TeamMembersListChip
            key={teamMemberForm.userId}
            form={form}
            field={field}
            teamMemberForm={teamMemberForm}
          />
        );
      })}
    </TeamMembersListContainer>
  );
};

const TeamDrawerFields = ({form, field, organization, isDisabled, isCrewTeam}) => {
  return (
    <Container>
      <NameField form={form} field={field} isDisabled={isDisabled} />
      <Space height={16} />
      <TeamMemberDropdown
        form={form}
        field={field}
        organization={organization}
        isCrewTeam={isCrewTeam}
      />
      <Space height={8} />
      <TeamMembersList form={form} field={field} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TeamDrawerFields.fragment = gql`
  ${Organization.getOfficeStaffDropdownOptions.fragment}
  ${Organization.getMoverStaffDropdownOptions.fragment}

  fragment TeamDrawerFields on Organization {
    id
    ...Organization_getOfficeStaffDropdownOptions
    ...Organization_getMoverStaffDropdownOptions
  }
`;

export default TeamDrawerFields;
