// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useModal, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Panel from '@shared/design/components/Panel';
import CodatIntegrationForm from '@shared/modules/Integration/forms/CodatIntegrationForm';
import useCreateCodatIntegrationMutation from '@shared/modules/Integration/hooks/useCreateCodatIntegrationMutation';
import useDeleteCodatIntegrationMutation from '@shared/modules/Integration/hooks/useDeleteCodatIntegrationMutation';
import CodatIntegrationActiveSwitch from 'modules/Organization/Settings/Company/components/CodatIntegrationActiveSwitch';

const CodatMessageContainer = Styled.View`
  align-items: flex-end;
  justify-content: flex-end;
`;

const CodatMessageText = Styled.Text`
  ${Typography.Body3}
  margin-top: 10px;
`;

const TextContainer = Styled.View`
`;

const FlexTextContainer = Styled.View`
  display: flex;
  flex-direction: row;
`;

const ButtonContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

const CodatIntegrationContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Row = Styled.View`
  flex-direction: row;
`;

/**
 * On a successful Codat operation, shows a message on redirect.
 *
 * On an unsuccessful Codat Link or delete operation, shows an error.
 */
const CodatAuthenticationMessage = ({code}) => {
  const codeMap = {
    'connect-success': {
      text: 'Successfully connected your accounting software.',
      kind: 'success',
    },
    'connect-error': {
      text: 'An error occurred while attempting to connect your accounting software.',
      kind: 'error',
    },
    'disconnect-success': {
      text: 'Successfully disconnected your accounting software.',
      kind: 'success',
    },
    'disconnect-error': {
      text: 'An error occurred while attempting to disconnect your accounting software.',
      kind: 'error',
    },
  };
  const message = codeMap[code];
  if (message) {
    return (
      <CodatMessageContainer>
        <CodatMessageText
          style={{color: message.kind === 'error' ? colors.red.warning : colors.green.status}}
        >
          {message.text}
        </CodatMessageText>
      </CodatMessageContainer>
    );
  } else {
    return null;
  }
};

const handleNavigateToCodat = (integration) => {
  const url = integration.codatRedirectUrl;
  window.location.href = url;
};

const ConnectToCodat = ({organization, settingsPath}) => {
  const {submitting, handleSubmit} = useCreateCodatIntegrationMutation({
    codatIntegrationForm: CodatIntegrationForm.new({organizationId: organization.id}),
    onSuccess: ({codatIntegration}) => {
      window.location.href = codatIntegration.codatRedirectUrl;
    },
    onError: () => {
      window.location.href = `${settingsPath}?codat=connect-error`;
    },
  });

  return (
    <Row>
      <TextContainer style={{flex: 1}}>
        <Panel.LabelText>Connect Accounting Software</Panel.LabelText>
        <Space height={8} />
        <Panel.Text>
          Simplify your accounting by connecting your accounting software with Supermove.
        </Panel.Text>
      </TextContainer>
      <Space width={12} />
      <ButtonContainer>
        <Button onPress={handleSubmit} text={'Connect Account'} isSubmitting={submitting} />
      </ButtonContainer>
    </Row>
  );
};

const ListCodatIntegrations = ({organization, settingsPath}) => {
  return organization.codatCodatIntegrations.map((integration) => {
    return (
      <CodatIntegration
        integration={integration}
        settingsPath={settingsPath}
        key={integration.codatCompanyId}
      />
    );
  });
};

const DeleteCodatIntegrationModal = ({isOpen, handleClose, handleSubmit, submitting}) => {
  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Are you sure you want to disconnect this integration?'}
      subtitle={
        'This will remove the accounting integration from Supermove. Any and all data related to the integration will be removed. If you re-add the integration later on, you will need to complete the setup process again.'
      }
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

const CodatIntegration = ({integration, settingsPath}) => {
  const {navigator} = useNavigationDOM();
  const deleteCodatIntegrationModal = useModal();

  const {handleSubmit, submitting} = useDeleteCodatIntegrationMutation({
    codatIntegrationForm: {
      organizationId: integration.organizationId,
      codatIntegrationId: integration.id,
    },
    onSuccess: () => {
      window.location.href = `${settingsPath}?codat=disconnect-success`;
    },
    onError: () => {
      window.location.href = `${settingsPath}?codat=disconnect-error`;
    },
  });
  const {connectionStatus} = integration;
  const icon = connectionStatus === 'ONLINE' ? Icon.CheckCircle : Icon.TimesCircle;
  const iconColor = connectionStatus === 'ONLINE' ? colors.green.status : colors.red.warning;
  const statusText = connectionStatus === 'ONLINE' ? 'Online' : 'Offline';
  return (
    <CodatIntegrationContainer>
      <TextContainer>
        <Panel.LabelText>Connection Status</Panel.LabelText>
        <Space height={8} />
        <FlexTextContainer>
          <Icon source={icon} size={16} color={iconColor} />
          <Space width={8} />

          <Panel.LabelText
            style={{
              color: iconColor,
            }}
          >
            {statusText}
          </Panel.LabelText>
          <Space height={8} />
        </FlexTextContainer>
      </TextContainer>
      <Space width={16} />
      <TextContainer>
        <Panel.LabelText>Account ID</Panel.LabelText>
        <Space height={8} />
        <TertiaryButton onPress={() => handleNavigateToCodat(integration)}>
          <Panel.Text style={{color: colors.blue.interactive}}>
            {integration.codatCompanyId}
          </Panel.Text>
        </TertiaryButton>
      </TextContainer>
      <ButtonContainer>
        <SecondaryButton
          text={'Configure'}
          onPress={() => {
            navigator.push(`/settings/company/accounting/${integration.uuid}/general`);
          }}
        />
        <Space width={16} />
        <DropdownButton
          text={'Update Account'}
          actions={[
            {
              text: 'Disconnect Account',
              onPress: deleteCodatIntegrationModal.handleOpen,
            },
          ]}
        />
      </ButtonContainer>
      <DeleteCodatIntegrationModal
        isOpen={deleteCodatIntegrationModal.isOpen}
        handleClose={deleteCodatIntegrationModal.handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    </CodatIntegrationContainer>
  );
};

const CodatPanelContents = ({organization, settingsPath, refetch}) => {
  const {params} = useNavigationDOM();
  const showToggle =
    organization.features.isEnabledAllowTogglingAccountingIntegrationStatuses &&
    organization.codatCodatIntegrations.length;
  return (
    <React.Fragment>
      <Panel.Header style={{justifyContent: 'space-between', alignItems: 'center'}}>
        <Panel.HeaderText>Accounting Integration (Legacy)</Panel.HeaderText>
        {showToggle ? (
          <CodatIntegrationActiveSwitch
            integration={organization.codatCodatIntegrations[0]}
            refetch={refetch}
          />
        ) : null}
      </Panel.Header>
      <Panel.Body>
        {organization.codatCodatIntegrations.length ? (
          <ListCodatIntegrations organization={organization} settingsPath={settingsPath} />
        ) : (
          <ConnectToCodat organization={organization} settingsPath={settingsPath} />
        )}
        <CodatAuthenticationMessage code={params.codat} />
      </Panel.Body>
    </React.Fragment>
  );
};

const CodatPanel = ({settingsPath}) => {
  const {loading, data, refetch} = useQuery(CodatPanel.query, {
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Panel width={Panel.WIDTH.DEFAULT}>
      <Loading loading={loading}>
        {() => (
          <CodatPanelContents
            organization={data.viewer.viewingOrganization}
            refetch={refetch}
            settingsPath={settingsPath}
          />
        )}
      </Loading>
    </Panel>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CodatPanel.query = gql`
  ${CodatIntegrationActiveSwitch.fragment}
  query CodatPanel {
    viewer {
      id
      viewingOrganization {
        id
        slug
        features {
          isEnabledAllowTogglingAccountingIntegrationStatuses: isEnabled(
            feature: "ALLOW_TOGGLING_ACCOUNTING_INTEGRATION_STATUSES"
          )
        }
        codatCodatIntegrations {
          id
          uuid
          organizationId
          codatRedirectUrl
          codatCompanyId
          isActive
          connectionStatus
          ...CodatIntegrationActiveSwitch
        }
      }
    }
  }
`;

export default CodatPanel;
