// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ImportInventoryLibraryItemTypesForm from '@shared/modules/File/forms/ImportInventoryLibraryItemTypesForm';

const useImportInventoryLibraryItemTypesMutation = ({
  importInventoryLibraryItemTypesForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      importInventoryLibraryItemTypesForm: ImportInventoryLibraryItemTypesForm.toForm(
        importInventoryLibraryItemTypesForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useImportInventoryLibraryItemTypesMutation.mutation,
    variables: {
      importInventoryLibraryItemTypesForm: ImportInventoryLibraryItemTypesForm.toMutation(
        form.values.importInventoryLibraryItemTypesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useImportInventoryLibraryItemTypesMutation.mutation = gql`
  mutation useImportInventoryLibraryItemTypesMutation(
    $importInventoryLibraryItemTypesForm: ImportInventoryLibraryItemTypesForm!
  ) {
    response: importInventoryLibraryItemTypes(
      importInventoryLibraryItemTypesForm: $importInventoryLibraryItemTypesForm
    ) {
      ${gql.errors}
    }
  }
`;

export default useImportInventoryLibraryItemTypesMutation;
