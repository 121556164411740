// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, usePagination, useQuery, useResponsive} from '@supermove/hooks';
import {PayrollReport} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import PayrollReportStatus from '@shared/modules/Accounting/enums/PayrollReportStatus';
import useUpdatePayrollPaidOutMutation from '@shared/modules/Accounting/hooks/useUpdatePayrollReportPaidOutMutation';
import CompensationReportsList from 'modules/Accounting/CompensationReport/components/CompensationReportsList';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';

import ShowPayrollReportAccountingPageContentV2 from './ShowPayrollReportAccountingPageContentV2';

const Container = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Column = Styled.View`
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${({color}) => color};
`;

const Label = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary}
`;

const Text = Styled.Text`
  ${Typography.Body4}
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-horizontal: ${({mobile}) => (mobile ? '12px' : '20px')};
  max-width: 1440px;
`;

const BackText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const DateText = Styled.Text`
  ${Typography.Label1};
`;

const PaidOutButton = Styled.ButtonV2`
  padding-vertical: 4px;
  padding-horizontal: 12px;
  background-color: ${({disabled}) => (disabled ? colors.gray.tertiary : colors.blue.interactive)};
  border-radius: 4px;
`;

const PayrollReportSummaryContainer = Styled.View`
  flex-direction: row;
  width: 294px;
  padding-horizontal: 16px;
  padding-vertical: 12px;
  background-color: ${colors.gray.background};
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const getVariablesFromParams = (params) => {
  return {
    pagination: PaginationBar.DEFAULT_PAGINATION,
    ...params,
  };
};

const ContentHeader = ({payrollReport, refetch}) => {
  const {navigator} = useNavigationDOM();
  const {handleSubmit} = useUpdatePayrollPaidOutMutation({
    payrollReportId: payrollReport.id,
    onSuccess: () => refetch(),
    onError: (errors) => {},
  });
  const isPaidOut =
    payrollReport.status === PayrollReportStatus.PAID_OUT ||
    payrollReport.compensationReportsCount === 0;
  return (
    <React.Fragment>
      <Space height={16} />
      <Button onPress={() => navigator.goBack()}>
        <Icon source={Icon.ArrowLeft} color={colors.gray.secondary} size={10} />
        <Space width={8} />
        <BackText>Back</BackText>
      </Button>
      <Space height={8} />
      <Row>
        <DateText>{`Payroll ${PayrollReport.getDateRange(payrollReport)}`}</DateText>
        <Space style={{flex: 1, minWidth: 16}} />
        <PaidOutButton onPress={() => handleSubmit()} disabled={isPaidOut}>
          <ButtonText color={colors.white}>Mark as Paid Out</ButtonText>
        </PaidOutButton>
      </Row>
      <Space height={16} />
    </React.Fragment>
  );
};

const PayrollReportSummary = ({payrollReport}) => {
  return (
    <PayrollReportSummaryContainer>
      <Column>
        <Label>Number of Employees</Label>
        <Space height={4} />
        <Text>{payrollReport.compensationReportsCount}</Text>
      </Column>
      <Space style={{flex: 1}} />
      <Column>
        <Label>Total Compensation</Label>
        <Space height={4} />
        <Text>{Currency.display(payrollReport.totalCompensation)}</Text>
      </Column>
    </PayrollReportSummaryContainer>
  );
};

const ShowPayrollReportAccountingPageContent = ({payrollReport, viewer, refetch}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const variables = getVariablesFromParams(params);
  const pagination = usePagination({
    currentPage: _.toNumber(variables.pagination.page),
    paginationMetadata: _.get(payrollReport, 'compensationReportsPaginatedList.paginationMetadata'),
    onChangePage: (page) => {
      navigator.replace(
        URL.getUrlFromVariables(`/accounting/payroll-reports/${payrollReport.uuid}`, {
          ...variables,
          pagination: {
            ...variables.pagination,
            page,
          },
        }),
      );
    },
  });
  return (
    <ContentContainer {...responsive}>
      <ContentHeader payrollReport={payrollReport} refetch={refetch} />
      <Line />
      <ScrollView>
        <Space height={16} />
        <PayrollReportSummary payrollReport={payrollReport} />
        <Space height={16} />
        <CompensationReportsList
          compensationReports={payrollReport.compensationReportsPaginatedList.compensationReports}
          organizationSlug={payrollReport.organization.slug}
          refetch={refetch}
          viewerUserRole={viewer.role}
          isCostAndCompensationEnabled={
            payrollReport.organization.settings.isCostAndCompensationEnabled
          }
          isEnabledCompensationCsvDownload={
            payrollReport.organization.features.isEnabledCompensationCsvDownload
          }
        />
        <Space height={32} />
        <PaginationBar pagination={pagination} />
        <Space height={32} />
      </ScrollView>
    </ContentContainer>
  );
};

const ShowPayrollReportAccountingPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(ShowPayrollReportAccountingPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: getVariablesFromParams(params),
  });

  return (
    <SidebarPageV2 selected={'accounting'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <Container>
              <StandardOfficeHeader title={'Payroll'} />
              {data.payrollReport.organization.features.isEnabledJobLevelCosts ? (
                <ShowPayrollReportAccountingPageContentV2
                  payrollReport={data.payrollReport}
                  viewerRole={data.viewer.role}
                  refetch={refetch}
                />
              ) : (
                <ShowPayrollReportAccountingPageContent
                  payrollReport={data.payrollReport}
                  viewer={data.viewer}
                  refetch={refetch}
                />
              )}
            </Container>
          );
        }}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowPayrollReportAccountingPage.query = gql`
  ${CompensationReportsList.fragment}
  ${PayrollReport.getDateRange.fragment}
  ${ShowPayrollReportAccountingPageContentV2.fragment}
  ${usePagination.fragment}

  query ShowPayrollReportAccountingPage($payrollReportUuid: String!, $pagination: PaginationInput!) {
    ${gql.query}

    viewer {
      id
      role
    }

    payrollReport(payrollReportUuid: $payrollReportUuid) {
      id
      uuid
      compensationReportsCount
      totalCompensation
      status
      organization {
        id
        slug
        features {
          isEnabledJobLevelCosts: isEnabled(feature: "JOB_LEVEL_COSTS")
          isEnabledCompensationCsvDownload: isEnabled(feature: "COMPENSATION_CSV_DOWNLOAD")
        }
        settings {
          id
          isCostAndCompensationEnabled
        }
      }
      compensationReportsPaginatedList(pagination: $pagination) {
        compensationReports: results {
          id
          ...CompensationReportsList
        }
        paginationMetadata {
          ...usePagination
        }
      }
      ...PayrollReport_getDateRange
      ...ShowPayrollReportAccountingPageContentV2
    }
  }
`;

export default ShowPayrollReportAccountingPage;
