// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';

const Container = Styled.View`
`;

const Section = Styled.View`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  padding-horizontal: 16px;
`;

const Title = Styled.Text`
  ${Typography.Label1}
`;

const Row = Styled.View`
  z-index: ${({index = 0}) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: ${({mobile}) => (mobile ? '8px' : '16px')};
`;

const getCreatedBy = ({job}) => {
  if (!job.creator) {
    return '';
  }
  if (job.jobRequest) {
    return `Created from job request by ${job.creator.fullName}`;
  }
  return `Created manually by ${job.creator.fullName}`;
};

const JobInternal = ({job}) => {
  const responsive = useResponsive();
  const {project} = job;
  const {features} = project.organization;

  return (
    <Container>
      <Section>
        <Space height={12} />
        <Title>Internal Information</Title>
        <Space height={12} />
        <Row index={2} {...responsive}>
          <FieldValue
            label={'Salesperson'}
            value={project.bookedBy ? project.bookedBy.fullName : ''}
            empty={'N/A'}
          />
        </Row>
        <RowSpace {...responsive} />
        {features.isEnabledAdditionalSalespersonIdsInput && (
          <React.Fragment>
            <Row index={3} {...responsive}>
              <FieldValue
                label={
                  features.isEnabledProjectManagersField
                    ? 'Project Managers'
                    : 'Additional Salespersons'
                }
                value={project.additionalSalespersons
                  .map((salesperson) => {
                    return salesperson.fullName;
                  })
                  .join(', ')}
                empty={'N/A'}
              />
            </Row>
            <RowSpace {...responsive} />
          </React.Fragment>
        )}
        <Row index={3} {...responsive}>
          <FieldValue
            label={'Coordinator'}
            value={project.coordinatedBy ? project.coordinatedBy.fullName : ''}
            empty={'N/A'}
          />
        </Row>
        <RowSpace {...responsive} />
        <Row index={4} {...responsive}>
          <FieldValue
            label={'How did you hear about us?'}
            value={Job.getDisplayReferral(job)}
            empty={'Unknown'}
            style={{flex: 1}}
          />
        </Row>
        <RowSpace {...responsive} />
        <Row index={5} {...responsive}>
          <FieldValue empty={'Unknown'} label={'Created By'} value={getCreatedBy({job})} />
        </Row>
        <React.Fragment>
          <RowSpace {...responsive} />
          <Row index={4} {...responsive}>
            <FieldValue
              label={'Tablet Version'}
              value={_.get(job, 'appInstall.release.version')}
              empty={'---'}
              style={{flex: 1}}
            />
          </Row>
        </React.Fragment>
        <Space height={16} />
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobInternal.propTypes = {
  job: PropTypes.object.isRequired,
};

JobInternal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobInternal.fragment = gql`
  ${Job.getDisplayReferral.fragment}

  fragment JobInternal on Job {
    id
    creator {
      id
      fullName
    }
    jobRequest {
      id
    }
    appInstall {
      id
      release {
        id
        version
      }
    }
    project {
      id
      organization {
        id
        features {
          isEnabledAdditionalSalespersonIdsInput: isEnabled(
            feature: "ADDITIONAL_SALESPERSON_IDS_INPUT"
          )
          isEnabledProjectManagersField: isEnabled(feature: "PROJECT_MANAGERS_FIELD")
        }
      }
      bookedBy {
        id
        fullName
      }
      coordinatedBy {
        id
        fullName
      }
      additionalSalespersons {
        id
        fullName
      }
    }
    ...Job_getDisplayReferral
  }
`;

export default JobInternal;
