const CUSTOM = 'CUSTOM';
const DEFAULT = 'DEFAULT';
const TRAINING_PROJECT = 'TRAINING_PROJECT';
const IGNORE_FOR_CAPACITY = 'IGNORE_FOR_CAPACITY';
// Deprecated
const SYSTEM = 'SYSTEM';

const TagKind = {
  CUSTOM,
  DEFAULT,
  TRAINING_PROJECT,
  IGNORE_FOR_CAPACITY,

  // Deprecated
  SYSTEM,
};

export default TagKind;
