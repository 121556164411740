// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Currency} from '@supermove/utils';

// Forms
import TimeRangeForm from './TimeRangeForm';

const newFromReportMove = withFragment(
  (reportMove) => {
    return {
      userId: null,
      jobId: (reportMove as any).jobId ? String((reportMove as any).jobId) : null,
      moveId: (reportMove as any).moveId ? String((reportMove as any).moveId) : null,
      position: '',
      positions: [],
      branchCode: '',
      timePayableAdjustment: 0,
      timeRangeInputs: (reportMove as any).organization.defaultTimesheetTimeRanges.map(
        (timeRange: any) => {
          return TimeRangeForm.edit(timeRange);
        },
      ),
      tip: 0,
      // Private fields.
      user: null,
    };
  },
  gql`
    ${TimeRangeForm.edit.fragment}

    fragment ReportMoveUserForm_newFromReportMove on ReportMove {
      id
      jobId
      moveId
      organization {
        id
        defaultTimesheetTimeRanges {
          ...TimeRangeForm_edit
        }
      }
    }
  `,
);

const extendTimeRangeInputs = ({timeRangeInputs}: any) => {
  return _.concat(timeRangeInputs, _.last(timeRangeInputs));
};

const shrinkTimeRangeInputs = ({timeRangeInputs}: any) => {
  return _.initial(timeRangeInputs);
};

const ReportMoveUserForm = {
  toForm: ({
    userId,
    jobId,
    moveId,
    position,
    positions,
    branchCode,
    timePayableAdjustment,
    timeRangeInputs,
    tip,
    // Private fields.
    user,
  }: any) => ({
    userId,
    jobId,
    moveId,
    position,
    positions,
    branchCode,
    timePayableAdjustment,
    timeRangeInputs: extendTimeRangeInputs({timeRangeInputs}).map((timeRangeForm) => {
      return TimeRangeForm.toForm(timeRangeForm);
    }),
    tip: Currency.toForm(tip),
    // Private fields.
    user,
  }),
  toMutation: ({
    userId,
    jobId,
    moveId,
    position,
    positions,
    branchCode,
    timePayableAdjustment,
    timeRangeInputs,
    tip,
  }: any) => ({
    userId,
    jobId,
    moveId,
    position,
    positions,
    branchCode,
    timePayableAdjustment,
    timeRangeInputs: shrinkTimeRangeInputs({timeRangeInputs}).map((timeRangeForm) => {
      return TimeRangeForm.toMutation(timeRangeForm);
    }),
    tip: Currency.toMutation(tip),
  }),
  edit: withFragment(
    (reportMoveUser) => ({
      userId: (reportMoveUser as any).userId ? String((reportMoveUser as any).userId) : null,
      jobId: (reportMoveUser as any).jobId ? String((reportMoveUser as any).jobId) : null,
      moveId: (reportMoveUser as any).moveId ? String((reportMoveUser as any).moveId) : null,
      position: (reportMoveUser as any).position,
      positions: (reportMoveUser as any).positions,
      branchCode: (reportMoveUser as any).branchCode,
      timePayableAdjustment: (reportMoveUser as any).timePayableAdjustment,
      timeRangeInputs: (reportMoveUser as any).timeRanges.map((timeRange: any) => {
        return TimeRangeForm.edit(timeRange);
      }),
      tip: (reportMoveUser as any).tip,
      // Private fields.
      user: (reportMoveUser as any).user,
    }),
    gql`
      ${TimeRangeForm.edit.fragment}

      fragment ReportMoveUserForm_edit on ReportMoveUser {
        userId
        jobId
        moveId
        position
        positions
        branchCode
        timePayableAdjustment
        tip
        timeRanges {
          ...TimeRangeForm_edit
        }
      }
    `,
  ),
  newFromReportMove,
};

export default ReportMoveUserForm;
