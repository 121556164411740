// Supermove

// Supermove
import {uuid} from '@supermove/utils';

export interface SendSmsFromTemplateActionFormType {
  workflowRunStepUuid: string;
  textMessageTemplateId: string | null;
}

const _new = (): SendSmsFromTemplateActionFormType => ({
  workflowRunStepUuid: uuid(),
  textMessageTemplateId: null,
});

const edit = (sendSmsFromTemplateActionForm: any): SendSmsFromTemplateActionFormType => ({
  workflowRunStepUuid: sendSmsFromTemplateActionForm.workflowRunStepUuid,
  textMessageTemplateId: sendSmsFromTemplateActionForm.textMessageTemplateId,
});

const toForm = (
  SendSmsFromTemplateActionForm: SendSmsFromTemplateActionFormType,
): SendSmsFromTemplateActionFormType => ({
  workflowRunStepUuid: SendSmsFromTemplateActionForm.workflowRunStepUuid,
  textMessageTemplateId: SendSmsFromTemplateActionForm.textMessageTemplateId,
});

const toMutation = (
  SendSmsFromTemplateActionForm: SendSmsFromTemplateActionFormType,
): SendSmsFromTemplateActionFormType => ({
  workflowRunStepUuid: SendSmsFromTemplateActionForm.workflowRunStepUuid,
  textMessageTemplateId: SendSmsFromTemplateActionForm.textMessageTemplateId,
});

const SendSmsFromTemplateActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default SendSmsFromTemplateActionForm;
