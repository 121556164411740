// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import StorageProjectForm from '@shared/modules/Project/Storage/forms/StorageProjectForm';
import useUpdateStorageProjectMutation from '@shared/modules/Project/Storage/hooks/useUpdateStorageProjectMutation';
import StorageProjectModalFields from 'modules/Job/V2/Storage/components/StorageProjectModalFields';

const Container = Styled.View`
  flex: 1;
`;

const setInvoiceForm = ({form, invoiceForm}) => {
  // If an invoice form is passed in, we need to also update the client form and/or billing client form
  // within the invoice form after the project is edited
  if (invoiceForm) {
    // We first set the invoice form's client form
    invoiceForm.setFieldValue(
      `invoiceForm.clientForm`,
      _.get(form.values, 'storageProjectForm.clientForm'),
    );
    if (_.get(form.values, `storageProjectForm.isSameBillingClient`)) {
      // Set billing client to same value as client if the same as client checkbox is checked
      invoiceForm.setFieldValue(
        `invoiceForm.billingClientForm`,
        _.get(form.values, `storageProjectForm.clientForm`),
      );
    } else {
      // Set billing client to value in billing client form
      invoiceForm.setFieldValue(
        `invoiceForm.billingClientForm`,
        _.get(form.values, 'storageProjectForm.billingClientForm'),
      );
    }
  }
};

const EditStorageProjectModalContent = ({
  handleClose,
  project,
  invoiceForm,
  refetch,
  isViewerOrgPrimaryMultibranch,
}) => {
  const storageProjectForm = StorageProjectForm.edit(project);
  const {form, submitting, handleSubmit} = useUpdateStorageProjectMutation({
    storageProjectForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    // TODO(dan) Update error handling
    onError: (error) => console.log({error}),
  });

  return (
    <Container>
      <Modal.Header style={{paddingHorizontal: 24, paddingVertical: 16}}>
        <Modal.HeaderText>{'Edit Storage Project'}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body isScrollable>
        <StorageProjectModalFields
          form={form}
          field={'storageProjectForm'}
          collections={project.collections}
          organization={project.assignedOrganization}
          projectTypeUuid={project.projectType.uuid}
          hasContainersAssigned={project.hasContainers}
          isViewerOrgPrimaryMultibranch={isViewerOrgPrimaryMultibranch}
          isEdit
        />
      </Modal.Body>
      <Modal.Footer style={{paddingHorizontal: 16, paddingVertical: 12}}>
        <Modal.Button onPress={handleClose}>{'Cancel'}</Modal.Button>
        <Space width={8} />
        <Modal.Button
          onPress={() => {
            setInvoiceForm({form, invoiceForm});
            setTimeout(handleSubmit, 0);
          }}
          isSubmitting={submitting}
          color={colors.blue.interactive}
        >
          {'Save'}
        </Modal.Button>
      </Modal.Footer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditStorageProjectModalContent.fragment = gql`
  ${StorageProjectForm.edit.fragment}
  ${StorageProjectModalFields.fragment}
  ${StorageProjectModalFields.organizationFragment}

  fragment EditStorageProjectModalContent on Project {
    id
    projectType {
      id
      uuid
    }
    collections {
      id
      ...StorageProjectModalFields
    }
    hasContainers
    assignedOrganization {
      id
      features {
        isEnabledStorageMultibranchAssignProject: isEnabled(
          feature: "STORAGE_MULTIBRANCH_ASSIGN_PROJECT"
        )
      }
      ...StorageProjectModalFields_organizationFragment
    }
    ...StorageProjectForm_edit
  }
`;

export default EditStorageProjectModalContent;
