// Libraries
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

const Image = Styled.Image`
`;

type OwnProps = {
  organization: any;
  style?: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof OrganizationLogo.defaultProps;

// @ts-expect-error TS(7022): 'OrganizationLogo' implicitly has type 'any' becau... Remove this comment to see the full error message
const OrganizationLogo = ({organization, style}: Props) => {
  return (
    <React.Fragment>
      {!!organization.logoUri && (
        <Image resizeMode={'contain'} source={{uri: organization.logoUri}} style={style} />
      )}
    </React.Fragment>
  );
};

OrganizationLogo.defaultProps = {
  style: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationLogo.fragment = gql`
  fragment OrganizationLogo on Organization {
    id
    logoUri
  }
`;

export default OrganizationLogo;
