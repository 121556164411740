// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {PaymentMethod, PaymentMethodModel, ProjectTypeModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import ProjectTypePaymentMethodForm, {
  ProjectTypePaymentMethodFormType,
} from '@shared/modules/PaymentMethod/forms/ProjectTypePaymentMethodForm';

export interface UpdateProjectTypePaymentMethodsFormType {
  organizationId: number;
  projectTypePaymentMethodForms: ProjectTypePaymentMethodFormType[];
}

const existenceFilter = <T>(item: T | null | undefined): item is T => {
  return Boolean(item);
};

const edit = withFragment(
  (projectType: ProjectTypeModel): UpdateProjectTypePaymentMethodsFormType => {
    const {projectTypePaymentMethods, organization} = projectType;
    // Sort all projectTypePaymentMethods by section using paymentMethods.
    const projectTypePaymentMethodForms = PaymentMethod.getSortedPaymentMethodsBySection(
      organization.paymentMethods,
    )
      .flatMap(({label, paymentMethods}: {label: string; paymentMethods: PaymentMethodModel[]}) => {
        return paymentMethods.map((paymentMethod) => {
          const projectTypePaymentMethod = _.find(
            projectTypePaymentMethods,
            (projectTypePaymentMethod) =>
              _.toString(projectTypePaymentMethod.paymentMethodId) === _.toString(paymentMethod.id),
          );
          return projectTypePaymentMethod
            ? ProjectTypePaymentMethodForm.edit(projectTypePaymentMethod, {
                sectionLabel: label,
                paymentMethodName: paymentMethod.name,
              })
            : null;
        });
      })
      .filter(existenceFilter);
    return {
      organizationId: projectType.organizationId,
      projectTypePaymentMethodForms,
    };
  },
  gql`
    ${ProjectTypePaymentMethodForm.edit.fragment}
    fragment UpdateProjectTypePaymentMethodsForm_edit on ProjectType {
      id
      organizationId
      projectTypePaymentMethods {
        id
        ...ProjectTypePaymentMethodForm_edit
      }
      organization {
        id
        paymentMethods {
          id
          name
          kind
        }
      }
    }
  `,
);

const toForm = ({
  organizationId,
  projectTypePaymentMethodForms,
}: UpdateProjectTypePaymentMethodsFormType) => {
  return {
    organizationId,
    projectTypePaymentMethodForms: projectTypePaymentMethodForms.map(
      (projectTypePaymentMethodForm: ProjectTypePaymentMethodFormType) =>
        ProjectTypePaymentMethodForm.toForm(projectTypePaymentMethodForm),
    ),
  };
};

const toMutation = ({
  organizationId,
  projectTypePaymentMethodForms,
}: UpdateProjectTypePaymentMethodsFormType) => {
  return {
    organizationId,
    projectTypePaymentMethodForms: projectTypePaymentMethodForms.map(
      (projectTypePaymentMethodForm: ProjectTypePaymentMethodFormType) =>
        ProjectTypePaymentMethodForm.toMutation(projectTypePaymentMethodForm),
    ),
  };
};

const UpdateProjectTypePaymentMethodsForm = {
  edit,
  toForm,
  toMutation,
};

export default UpdateProjectTypePaymentMethodsForm;
