// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (roomType) => ({
    roomTypeId: (roomType as any).id,
    organizationId: (roomType as any).organizationId,
    primaryCategoryId: (roomType as any).primaryCategoryId
      ? String((roomType as any).primaryCategoryId)
      : undefined,
    name: (roomType as any).name,
    inventoryLibraryId: (roomType as any).inventoryLibraryId,
    isDeleted: (roomType as any).isDeleted,
    // private
    isDirty: false,
  }),
  gql`
    fragment RoomTypeForm_edit on RoomType {
      id
      organizationId
      primaryCategoryId
      name
      inventoryLibraryId
      isDeleted
    }
  `,
);

const _new = ({organizationId, inventoryLibraryId = null}: any) => ({
  roomTypeId: undefined,
  organizationId,
  primaryCategoryId: undefined,
  name: '',
  inventoryLibraryId,
  isDeleted: false,

  // private
  isDirty: true,
});

const toForm = ({
  roomTypeId,
  organizationId,
  primaryCategoryId,
  name,
  inventoryLibraryId,
  isDeleted,

  // private
  isDirty,
}: any) => ({
  roomTypeId,
  organizationId,
  primaryCategoryId,
  name,
  inventoryLibraryId,
  isDeleted,

  // private
  isDirty,
});

const toMutation = ({
  roomTypeId,
  organizationId,
  primaryCategoryId,
  name,
  inventoryLibraryId,
  isDeleted,
}: any) => ({
  roomTypeId,
  organizationId,
  primaryCategoryId,
  name,
  inventoryLibraryId,
  isDeleted,
});

const RoomTypeForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default RoomTypeForm;
