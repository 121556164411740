// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

import JobTruckForm from './JobTruckForm';

const newFromJob = withFragment(
  (job, {userId}) => ({
    jobId: (job as any).id,
    userId,
    jobTruckForms: (job as any).jobTrucks.map((jobTruck: any) => JobTruckForm.edit(jobTruck)),
  }),
  gql`
    ${JobTruckForm.edit.fragment}

    fragment JobConfirmTrucksForm_newFromJob on Job {
      id
      jobTrucks {
        id
        ...JobTruckForm_edit
      }
    }
  `,
);

const toForm = ({jobId, userId, jobTruckForms}: any) => ({
  jobId,
  userId,
  jobTruckForms: jobTruckForms.map((jobTruckForm: any) => JobTruckForm.toForm(jobTruckForm)),
});

const toMutation = ({jobId, userId, jobTruckForms}: any) => ({
  jobId,
  userId,
  jobTruckForms: jobTruckForms.map((jobTruckForm: any) => JobTruckForm.toMutation(jobTruckForm)),
});

const JobConfirmTrucksForm = {
  newFromJob,
  toForm,
  toMutation,
};

export default JobConfirmTrucksForm;
