// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Workflow} from '@supermove/models';

// App
import AssignOfficeStaffActionForm from '@shared/modules/Workflow/AssignOfficeStaffActionForm';
import CreateAndSendConfirmationForProjectActionForm from '@shared/modules/Workflow/CreateAndSendConfirmationForProjectActionForm';
import CreateTaskFromTemplateForm from '@shared/modules/Workflow/CreateTaskFromTemplateForm';
import DelayWorkflowActionForm from '@shared/modules/Workflow/DelayWorkflowActionForm';
import FindTaskFromPreviousStepActionForm from '@shared/modules/Workflow/FindTaskFromPreviousStepActionForm';
import GetDataAndStopIfActionForm from '@shared/modules/Workflow/GetDataAndStopIfActionForm';
import GetJobDataActionForm from '@shared/modules/Workflow/GetJobDataActionForm';
import GetProjectDataActionForm from '@shared/modules/Workflow/GetProjectDataActionForm';
import InvokeWebhookActionForm from '@shared/modules/Workflow/InvokeWebhookActionForm';
import RemoveTagAssignmentActionForm from '@shared/modules/Workflow/RemoveTagAssignmentActionForm';
import SendEmailFromTemplateActionForm from '@shared/modules/Workflow/SendEmailFromTemplateActionForm';
import SendSmsFromTemplateActionForm from '@shared/modules/Workflow/SendSmsFromTemplateActionForm';
import sendSmsV2FromTemplateActionForm from '@shared/modules/Workflow/SendSmsV2FromTemplateActionForm';
import SendVirtualWalkthroughActionForm from '@shared/modules/Workflow/SendVirtualWalkthroughActionForm';
import StopIfActionForm from '@shared/modules/Workflow/StopIfActionForm';
import ToggleTaskPropertyActionForm from '@shared/modules/Workflow/ToggleTaskPropertyActionForm';
import UpdateTagAssignmentActionForm from '@shared/modules/Workflow/UpdateTagAssignmentActionForm';
import UpdateTaskActionForm from '@shared/modules/Workflow/UpdateTaskActionForm';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import AssignToBranchActionForm from '@shared/modules/Workflow/forms/WorkflowStepAction/AssignToBranchActionForm';
import WorkflowBuilderOptionCard from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilderOptionCard';
import WorkflowBuilderSlideSet from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilderSlideSet';
import WorkflowStepAssignOfficeStaff from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepAssignOfficeStaff';
import WorkflowStepAssignToBranch from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepAssignToBranch';
import WorkflowStepCreateTask from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepCreateTask';
import WorkflowStepDelay from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepDelay';
import WorkflowStepEditTask from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepEditTask';
import WorkflowStepGetDataAndStopIf from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepGetDataAndStopIf';
import WorkflowStepGetJobData from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepGetJobData';
import WorkflowStepGetProjectData from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepGetProjectData';
import WorkflowStepHandleTag from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepHandleTag';
import WorkflowStepInvokeWebhook from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepInvokeWebhook';
import WorkflowStepSendConfirmation from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSendConfirmation';
import WorkflowStepSendEmailFromTemplate from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSendEmailFromTemplate';
import WorkflowStepSendQuote from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSendQuote';
import WorkflowStepSendSmsFromTemplate from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSendSmsFromTemplate';
import WorkflowStepSendSmsV2FromTemplate from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSendSmsV2FromTemplate';
import WorkflowStepSendVirtualWalkthrough from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSendVirtualWalkthrough';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';
import WorkflowStepStopIf from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepStopIf';

const SUMMARY_SLIDE_HEIGHT = 80;

const getAdditionalWorkflowStepSlides = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  handleSubmitValidateWorkflow,
  isPreview,
}) => {
  const actionKind = _.get(
    form.values,
    `${workflowStepsField}.${workflowStepIndex}.workflowStepActionKind`,
  );
  switch (actionKind) {
    case WorkflowStepActionKind.STOP_IF:
      return WorkflowStepStopIf.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.GET_DATA_AND_STOP_IF:
      return WorkflowStepGetDataAndStopIf.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.DELAY:
      return WorkflowStepDelay.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.CREATE_TASK:
      return WorkflowStepCreateTask.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.COMPLETE_TASK:
    case WorkflowStepActionKind.ARCHIVE_TASK:
    case WorkflowStepActionKind.FIND_TASK_FROM_PREVIOUS_STEP:
    case WorkflowStepActionKind.UPDATE_TASK:
      return WorkflowStepEditTask.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        handleSubmitValidateWorkflow,
        organizationId: workflow.organizationId,
        isPreview,
      });
    case WorkflowStepActionKind.ASSIGN_TO_BRANCH_JOB:
    case WorkflowStepActionKind.ASSIGN_TO_BRANCH:
      return WorkflowStepAssignToBranch.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.GET_PROJECT_DATA:
      return WorkflowStepGetProjectData.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.GET_JOB_DATA:
      return WorkflowStepGetJobData.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.SEND_EMAIL:
      return WorkflowStepSendEmailFromTemplate.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.SEND_SMS:
      return WorkflowStepSendSmsFromTemplate.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.SEND_QUOTE:
      return WorkflowStepSendQuote.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.SEND_CONFIRMATION:
      return WorkflowStepSendConfirmation.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.SEND_SMS_V2:
      return WorkflowStepSendSmsV2FromTemplate.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.SEND_VIRTUAL_WALKTHROUGH:
      return WorkflowStepSendVirtualWalkthrough.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.INVOKE_WEBHOOK:
      return WorkflowStepInvokeWebhook.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.PROJECT_ADD_TAG:
    case WorkflowStepActionKind.JOB_ADD_TAG:
    case WorkflowStepActionKind.PROJECT_REMOVE_TAG:
    case WorkflowStepActionKind.JOB_REMOVE_TAG:
      return WorkflowStepHandleTag.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    case WorkflowStepActionKind.ASSIGN_OFFICE_STAFF:
      return WorkflowStepAssignOfficeStaff.getSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      });
    default:
      return [];
  }
};

// ensures that unused action forms are not submitted to backend.
// Make sure to add new action type forms here.
const handleResetActionForms = ({form, field}) => {
  form.setFieldValue(`${field}.stopIfActionForm`, null);
  form.setFieldValue(`${field}.getDataAndStopIfActionForm`, null);
  form.setFieldValue(`${field}.delayWorkflowActionForm`, null);
  form.setFieldValue(`${field}.createTaskFromTemplateForm`, null);
  form.setFieldValue(`${field}.toggleTaskPropertyActionForm`, null);
  form.setFieldValue(`${field}.updateTaskActionForm`, null);
  form.setFieldValue(`${field}.sendEmailFromTemplateActionForm`, null);
  form.setFieldValue(`${field}.createAndSendConfirmationForProjectActionForm`, null);
  form.setFieldValue(`${field}.findTaskFromPreviousStepActionForm`, null);
  form.setFieldValue(`${field}.getProjectDataActionForm`, null);
  form.setFieldValue(`${field}.sendSmsFromTemplateActionForm`, null);
  form.setFieldValue(`${field}.sendSmsV2FromTemplateActionForm`, null);
  form.setFieldValue(`${field}.sendVirtualWalkthroughActionForm`, null);
  form.setFieldValue(`${field}.assignToBranchActionForm`, null);
  form.setFieldValue(`${field}.assignToBranchJobActionForm`, null);
  form.setFieldValue(`${field}.invokeWebhookActionForm`, null);
  form.setFieldValue(`${field}.updateTagAssignmentActionForm`, null);
  form.setFieldValue(`${field}.removeTagAssignmentActionForm`, null);
  form.setFieldValue(`${field}.getJobDataActionForm`, null);
};

const handleAddActionForm = ({form, field, actionKind}) => {
  handleResetActionForms({form, field});
  switch (actionKind) {
    case WorkflowStepActionKind.STOP_IF:
      return form.setFieldValue(`${field}.stopIfActionForm`, StopIfActionForm.new({}));
    case WorkflowStepActionKind.GET_DATA_AND_STOP_IF:
      return form.setFieldValue(
        `${field}.getDataAndStopIfActionForm`,
        GetDataAndStopIfActionForm.new({}),
      );
    case WorkflowStepActionKind.DELAY:
      return form.setFieldValue(
        `${field}.delayWorkflowActionForm`,
        DelayWorkflowActionForm.new({}),
      );
    case WorkflowStepActionKind.ASSIGN_TO_BRANCH_JOB:
    case WorkflowStepActionKind.ASSIGN_TO_BRANCH:
      return form.setFieldValue(
        `${field}.assignToBranchActionForm`,
        AssignToBranchActionForm.new({}),
      );
    case WorkflowStepActionKind.ASSIGN_OFFICE_STAFF:
      return form.setFieldValue(
        `${field}.assignOfficeStaffActionForm`,
        AssignOfficeStaffActionForm.new({}),
      );
    case WorkflowStepActionKind.CREATE_TASK:
      return form.setFieldValue(
        `${field}.createTaskFromTemplateForm`,
        CreateTaskFromTemplateForm.new({}),
      );
    case WorkflowStepActionKind.COMPLETE_TASK:
      return form.setFieldValue(
        `${field}.toggleTaskPropertyActionForm`,
        ToggleTaskPropertyActionForm.new({}),
      );
    case WorkflowStepActionKind.ARCHIVE_TASK:
      return form.setFieldValue(
        `${field}.toggleTaskPropertyActionForm`,
        ToggleTaskPropertyActionForm.new({}),
      );
    case WorkflowStepActionKind.UPDATE_TASK:
      return form.setFieldValue(`${field}.updateTaskActionForm`, UpdateTaskActionForm.new({}));
    case WorkflowStepActionKind.SEND_EMAIL:
      return form.setFieldValue(
        `${field}.sendEmailFromTemplateActionForm`,
        SendEmailFromTemplateActionForm.new({}),
      );
    case WorkflowStepActionKind.SEND_QUOTE:
      return form.setFieldValue(
        `${field}.createAndSendConfirmationForProjectActionForm`,
        CreateAndSendConfirmationForProjectActionForm.new({}),
      );
    case WorkflowStepActionKind.SEND_CONFIRMATION:
      return form.setFieldValue(
        `${field}.createAndSendConfirmationForProjectActionForm`,
        CreateAndSendConfirmationForProjectActionForm.new({}),
      );
    case WorkflowStepActionKind.FIND_TASK_FROM_PREVIOUS_STEP:
      return form.setFieldValue(
        `${field}.findTaskFromPreviousStepActionForm`,
        FindTaskFromPreviousStepActionForm.new({}),
      );
    case WorkflowStepActionKind.GET_PROJECT_DATA:
      return form.setFieldValue(
        `${field}.getProjectDataActionForm`,
        GetProjectDataActionForm.new({}),
      );
    case WorkflowStepActionKind.GET_JOB_DATA:
      return form.setFieldValue(`${field}.getJobDataActionForm`, GetJobDataActionForm.new({}));
    case WorkflowStepActionKind.SEND_SMS:
      return form.setFieldValue(
        `${field}.sendSmsFromTemplateActionForm`,
        SendSmsFromTemplateActionForm.new({}),
      );
    case WorkflowStepActionKind.SEND_SMS_V2:
      return form.setFieldValue(
        `${field}.sendSmsV2FromTemplateActionForm`,
        sendSmsV2FromTemplateActionForm.new({}),
      );
    case WorkflowStepActionKind.SEND_VIRTUAL_WALKTHROUGH:
      return form.setFieldValue(
        `${field}.sendVirtualWalkthroughActionForm`,
        SendVirtualWalkthroughActionForm.new({}),
      );
    case WorkflowStepActionKind.INVOKE_WEBHOOK:
      return form.setFieldValue(
        `${field}.invokeWebhookActionForm`,
        InvokeWebhookActionForm.new({}),
      );
    case WorkflowStepActionKind.PROJECT_ADD_TAG:
    case WorkflowStepActionKind.JOB_ADD_TAG:
      return form.setFieldValue(
        `${field}.updateTagAssignmentActionForm`,
        UpdateTagAssignmentActionForm.new({}),
      );
    case WorkflowStepActionKind.PROJECT_REMOVE_TAG:
    case WorkflowStepActionKind.JOB_REMOVE_TAG:
      return form.setFieldValue(
        `${field}.removeTagAssignmentActionForm`,
        RemoveTagAssignmentActionForm.new({}),
      );
    default:
      return null;
  }
};

const ActionKindSelectorSlide = ({
  workflow,
  form,
  workflowStepsField,
  workflowStepIndex,
  handleSubmitValidateWorkflow,
  isPreview,
}) => {
  const field = `${workflowStepsField}.${workflowStepIndex}`;

  const triggerIdentifierFromForm = _.get(form.values, 'workflowForm.triggerIdentifier');

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={'Select action'}
      subtitle={'Event that happens after a trigger'}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
      isPreview={isPreview}
    >
      <WorkflowBuilderOptionCard.GroupedListContainer>
        {Workflow.getWorkflowStepActionKindOptions(workflow, triggerIdentifierFromForm).map(
          (actionKind, index) => {
            const {label, icon} = WorkflowStepActionKind.ATTRIBUTES[actionKind];
            const isNewRow = index % 2 === 0;
            const isFirstRow = index === 0;
            return (
              <React.Fragment key={actionKind}>
                {!isFirstRow && isNewRow && <WorkflowBuilderOptionCard.RowSeparator />}
                <WorkflowBuilderOptionCard.Paired
                  index={index}
                  text={label}
                  icon={icon}
                  onPress={() => {
                    form.setFieldValue(`${field}.workflowStepActionKind`, actionKind);
                    form.setFieldValue(`${field}.slideIndex`, 1);
                    handleAddActionForm({form, field, actionKind});
                  }}
                />
              </React.Fragment>
            );
          },
        )}
      </WorkflowBuilderOptionCard.GroupedListContainer>
    </WorkflowStepSlide>
  );
};

const WorkflowStep = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  handleSubmitValidateWorkflow,
  isPreview,
}) => {
  const slideIndex = _.get(form.values, `${workflowStepsField}.${workflowStepIndex}.slideIndex`);
  const isNew = slideIndex === 0;
  return (
    <WorkflowBuilderSlideSet
      tabs={{selectedIndex: slideIndex}}
      loadingHeight={isNew ? null : SUMMARY_SLIDE_HEIGHT}
    >
      <ActionKindSelectorSlide
        workflow={workflow}
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
        isPreview={isPreview}
      />
      {getAdditionalWorkflowStepSlides({
        form,
        workflowStepsField,
        workflowStepIndex,
        workflow,
        handleSubmitValidateWorkflow,
        isPreview,
      })}
    </WorkflowBuilderSlideSet>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowStep.fragment = gql`
  ${WorkflowStepAssignToBranch.fragment}
  ${WorkflowStepCreateTask.fragment}
  ${WorkflowStepSendEmailFromTemplate.fragment}
  ${Workflow.getWorkflowStepActionKindOptions.fragment}
  ${WorkflowStepSendSmsFromTemplate.fragment}
  ${WorkflowStepSendSmsV2FromTemplate.fragment}
  ${WorkflowStepHandleTag.fragment}
  ${WorkflowStepAssignOfficeStaff.fragment}
  ${WorkflowStepGetDataAndStopIf.fragment}

  fragment WorkflowStep on Workflow {
    id
    organizationId
    ...WorkflowStepHandleTag
    ...WorkflowStepAssignToBranch
    ...WorkflowStepCreateTask
    ...WorkflowStepSendEmailFromTemplate
    ...WorkflowStepGetDataAndStopIf
    ...Workflow_getWorkflowStepActionKindOptions
    ...WorkflowStepSendSmsFromTemplate
    ...WorkflowStepSendSmsV2FromTemplate
    ...WorkflowStepAssignOfficeStaff
  }
`;

export default WorkflowStep;
