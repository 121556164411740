// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import OrganizationReferralSourcesForm from '@shared/modules/Company/forms/OrganizationReferralSourcesForm';

const useUpdateOrganizationReferralSourcesMutation = ({
  organizationReferralSourcesForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      organizationReferralSourcesForm: OrganizationReferralSourcesForm.toForm(
        organizationReferralSourcesForm,
      ),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationReferralSourcesMutation.mutation,
    variables: {
      organizationReferralSourcesForm: OrganizationReferralSourcesForm.toMutation(
        form.values.organizationReferralSourcesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationReferralSourcesMutation.mutation = gql`
  mutation useUpdateOrganizationReferralSourcesMutation(
    $organizationReferralSourcesForm: OrganizationReferralSourcesForm!
  ) {
    response: updateOrganizationReferralSources(
      organizationReferralSourcesForm: $organizationReferralSourcesForm
    ) {
      ${gql.errors}
      organization {
        id
        settings {
          id
          referralSources {
            name
          }
        }
      }
    }
  }
`;

export default useUpdateOrganizationReferralSourcesMutation;
