/**
 * WARNING (warren)
 * This is a web component that renders HTML used to generate a PDF.
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// App
import {DocumentFreightBillInfo, DocumentHeaderOrganization} from 'modules/Document/components';
import DocumentBillingInformation from 'modules/Document/components/DocumentBillingInformation';
import DocumentMoveTimesChart from 'modules/Document/components/DocumentMoveTimesChart';

const Container = Styled.View`
  width: 100%;
  background-color: ${colors.white};
`;

const Section = Styled.View`
  margin-top: 20px;
`;

const HeaderText = Styled.H5`
  margin-bottom: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const JobReportMoveInvoiceDocument = ({job}) => {
  return (
    <Container>
      <DocumentHeaderOrganization
        title={'Moving Services Invoice'}
        organization={job.organization}
      />
      <DocumentFreightBillInfo job={job} />
      {job.organization.features.isEnabledShowTimesheetOnInvoiceDocument && (
        <Section>
          <HeaderText>Work Timesheet</HeaderText>
          <DocumentMoveTimesChart hasConfirmedTimes timeRanges={job.reportMove.timeRanges} />
        </Section>
      )}
      <Section>
        <DocumentBillingInformation showBillRules={false} showPayments job={job} />
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobReportMoveInvoiceDocument.propTypes = {
  job: PropTypes.object.isRequired,
};

JobReportMoveInvoiceDocument.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobReportMoveInvoiceDocument.fragment = gql`
  ${DocumentBillingInformation.fragment}
  ${DocumentFreightBillInfo.fragment}
  ${DocumentHeaderOrganization.fragment}
  ${DocumentMoveTimesChart.fragment}

  fragment JobReportMoveInvoiceDocument on Job {
    id
    organization {
      id
      features {
        isEnabledShowTimesheetOnInvoiceDocument: isEnabled(
          feature: "SHOW_TIMESHEET_ON_INVOICE_DOCUMENT"
        )
      }
      ...DocumentHeaderOrganization
    }
    reportMove {
      id
      timeRanges {
        ...DocumentMoveTimesChart
      }
    }
    estimateBill {
      id
      balance
    }
    finalBill {
      id
      balance
    }
    mainBill {
      id
      balance
    }
    ...DocumentBillingInformation
    ...DocumentFreightBillInfo
  }
`;

export default JobReportMoveInvoiceDocument;
