/**
 * Component - v2.1.0
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

const CreateProjectQuoteSuccessModal = ({isOpen, project, handleClose}) => {
  const {navigator} = useNavigationDOM();

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Container pointerEvents={'box-none'}>
        <Wrapper width={350}>
          <Circle>
            <Emoji component={EmojiText} name={'thumbsup'} />
          </Circle>
          <Content>
            <Title>Quote Sent!</Title>
            <Subtitle>
              {`We successfully sent an email to ${project.customer.email}. ` +
                `You can check the status of the quote on the move page.`}
            </Subtitle>
            <Actions>
              <Button
                onPress={() => {
                  navigator.replace('/');
                }}
              >
                <Text>Go Home</Text>
              </Button>
              <ButtonSpace />
              <Button
                onPress={() => {
                  navigator.replace('/');
                  navigator.push(`/projects/${project.uuid}?block=${ProjectBlockKind.PROPOSALS}`);
                }}
              >
                <Text>View Move</Text>
              </Button>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
CreateProjectQuoteSuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
};

CreateProjectQuoteSuccessModal.defaultProps = {
  handleClose: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateProjectQuoteSuccessModal.fragment = gql`
  fragment CreateProjectQuoteSuccessModal on Project {
    id
    uuid
    customer {
      id
      email
    }
    organization {
      id
      email
    }
  }
`;

export default CreateProjectQuoteSuccessModal;
