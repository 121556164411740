// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';

// App
import CommercialJobReport from 'modules/Job/Commercial/Complete/components/CommercialJobReport';
import CommercialJobReportIncomplete from 'modules/Job/Commercial/InProgress/components/CommercialJobReportIncomplete';
import JobReportMove from 'modules/Job/Complete/components/JobReportMove';
import JobTimetableBlock from 'modules/Job/Show/components/JobTimetableBlock';

const JobReportBlock = ({sectionIndex, job, viewer, refetch, showHeader}) => {
  const isDuringMove = Job.getIsDuringMove(job);
  const isAfterMove = Job.getIsAfterMove(job);
  const isCommercial = Job.getIsCommercial(job);
  if (isDuringMove) {
    if (isCommercial || job.hasJobFeatureCrewPerMoverTimesheet) {
      return <CommercialJobReportIncomplete job={job} showHeader={showHeader} />;
    }
    return <JobTimetableBlock job={job} showHeader={showHeader} />;
  }
  if (isAfterMove) {
    if (isCommercial || job.hasJobFeatureCrewPerMoverTimesheet) {
      return (
        <CommercialJobReport
          sectionIndex={sectionIndex}
          job={job}
          viewer={viewer}
          refetch={refetch}
          showHeader={showHeader}
        />
      );
    }
  }
  return (
    <JobReportMove
      sectionIndex={sectionIndex}
      job={job}
      viewer={viewer}
      refetch={refetch}
      showHeader={showHeader}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobReportBlock.fragment = gql`
  ${Job.getIsDuringMove.fragment}
  ${Job.getIsCommercial.fragment}
  ${Job.getIsAfterMove.fragment}
  ${CommercialJobReport.fragment}
  ${CommercialJobReportIncomplete.fragment}
  ${JobReportMove.fragment}
  ${JobTimetableBlock.fragment}
  fragment JobReportBlock_Viewer on User {
    id
    ...CommercialJobReport_Viewer
    ...JobReportMove_Viewer
  }
  fragment JobReportBlock_Job on Job {
    id
    hasJobFeatureCrewPerMoverTimesheet: hasJobFeature(kind: "CREW_PER_MOVER_TIMESHEET")
    ...CommercialJobReport_Job
    ...CommercialJobReportIncomplete
    ...JobReportMove
    ...JobTimetableBlock
    ...Job_getIsDuringMove
    ...Job_getIsAfterMove
    ...Job_getIsCommercial
  }
`;

export default JobReportBlock;
