// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';
import useDeleteProjectTypeMutation from '@shared/modules/Project/hooks/useDeleteProjectTypeMutation';

const RemoveProjectTypeModal = ({projectType, removeProjectTypeModal, refetch}) => {
  const projectTypeForm = ProjectTypeForm.edit(projectType);
  const {handleSubmit} = useDeleteProjectTypeMutation({
    projectTypeForm,
    onSuccess: () => {
      removeProjectTypeModal.handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DeleteModal
      handleDelete={handleSubmit}
      handleClose={removeProjectTypeModal.handleClose}
      isOpen={removeProjectTypeModal.isOpen}
      title={`Remove ${projectType.name}?`}
      subtitle={'Removing will not impact any existing work connected to this project type.'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RemoveProjectTypeModal.fragment = gql`
  ${ProjectTypeForm.edit.fragment}

  fragment RemoveProjectTypeModal on ProjectType {
    id
    name
    ...ProjectTypeForm_edit
  }
`;
export default RemoveProjectTypeModal;
