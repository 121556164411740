// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import TimesheetBillableEntry from '@shared/modules/Timesheet/components/TimesheetBillableEntry';
import useAppContext from 'modules/App/context/useAppContext';

const ContentContainer = Styled.View`
`;

const getSpacing = ({responsive, isCondensed}) => {
  return isCondensed ? (responsive.desktop ? 12 : 8) : responsive.desktop ? 24 : 16;
};

const JobTimesheetBillableEntries = ({
  job,
  refetch,
  responsive,
  onUpdate,
  contentContainerStyle,
  isViewOnly,
  isCondensed,
}) => {
  const {viewer} = useAppContext();

  return (
    <ContentContainer style={contentContainerStyle}>
      {job.timesheetBillableEntries.map((timesheetBillableEntry, index) => (
        <React.Fragment key={timesheetBillableEntry.id}>
          {index > 0 && <Space height={getSpacing({responsive, isCondensed})} />}
          <TimesheetBillableEntry
            job={job}
            timesheetBillableEntry={timesheetBillableEntry}
            refetch={refetch}
            onUpdate={onUpdate}
            isViewOnly={isViewOnly}
            showTotals={!isCondensed}
            isCondensed={isCondensed}
            viewerRole={viewer?.role}
          />
        </React.Fragment>
      ))}
    </ContentContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTimesheetBillableEntries.fragment = gql`
  ${TimesheetBillableEntry.fragment}

  fragment JobTimesheetBillableEntries on Job {
    id
    ...TimesheetBillableEntry
  }
`;

export default JobTimesheetBillableEntries;
