// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {useNavigationDOM} from '@supermove/hooks';

// App
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';

const CreateTripSuccessModal = ({form, field, isOpen, handleClose}) => {
  const tripName = _.get(form.values, `${field}.name`);
  const tripUuid = _.get(form.values, `${field}.tripUuid`);
  const {navigator} = useNavigationDOM();
  return (
    <SuccessModal
      title={`${tripName} created!`}
      subtitle={`You've successfully created ${tripName}.`}
      isOpen={isOpen}
      handlePrimaryAction={() =>
        navigator.push(`/dispatch/long-distance/trips/${tripUuid}/shipments`)
      }
      handleSecondaryAction={handleClose}
      primaryActionText={'View Trip'}
      secondaryActionText={'Go Back'}
    />
  );
};

export default CreateTripSuccessModal;
