// App
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';
import useUpdateOrganizationVirtualWalkthroughSettingsMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationVirtualWalkthroughSettingsMutation';

const useUploadOrganizationVirtualWalkthroughPhoto = ({
  uploadFileForm,
  organizationVirtualWalkthroughSettingsForm,
  onSuccess,
  onError,
}: any) => {
  // Update organization virtual walkthrough settings (#2)
  const {
    form: formOrganizationVirtualWalkthroughSettings,
    submitting: submittingOrganizationVirtualWalkthroughSettings,
    handleSubmit: handleSubmitOrganizationVirtualWalkthroughSettings,
  } = useUpdateOrganizationVirtualWalkthroughSettingsMutation({
    organizationVirtualWalkthroughSettingsForm,
    onSuccess,
    onError,
  });

  // Upload and save file (#1)
  const {
    form: formUploadFile,
    submitting: submittingUploadFile,
    handleSubmit: handleSubmitUploadFile,
  } = useUploadFileForm({
    uploadFileForm,
    onSuccess: async ({file}) => {
      await formOrganizationVirtualWalkthroughSettings.setFieldValue(
        `organizationVirtualWalkthroughSettingsForm.virtualWalkthroughPhotoFileId`,
        file.id,
      );
      handleSubmitOrganizationVirtualWalkthroughSettings();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return {
    form: formUploadFile,
    submitting:
      submittingUploadFile ||
      submittingOrganizationVirtualWalkthroughSettings ||
      uploadFileForm.isSubmitting,
    handleSubmit: handleSubmitUploadFile,
  };
};

export default useUploadOrganizationVirtualWalkthroughPhoto;
