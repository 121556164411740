// Libraries
import React from 'react';

import {ErrorHandler} from '@supermove/sdk';

/**
 * This component catches RENDER errors only. For more information about what it
 * does and does not catch, see:
 * https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
 */

// This component should render a fallback if an error occurs in a child component.
// ErrorView is a separate component that handles initialization of ErrorHandler (Sentry).
// This should be used downstream of ErrorView.

interface ErrorBoundaryProps {
  onError: (error: Error) => void;
  FallbackComponent: () => React.ReactNode;
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      error,
    };
  }

  componentDidCatch(error: Error): void {
    // Send the error via ErrorHandler, then handle within the app.
    ErrorHandler.handleError(error);
    this.props.onError(error);
  }

  render(): React.ReactNode {
    return this.state.error ? this.props.FallbackComponent() : this.props.children;
  }
}

export default ErrorBoundary;
