// App
import CostItemUnit from '@shared/modules/Billing/enums/CostItemUnit';

import BillItemUnit from './BillItemUnit';

const PER_BILL_TOTAL = 'PER_BILL_TOTAL';
const PER_WEIGHT = 'PER_WEIGHT';
const PER_BILL_ITEM = 'PER_BILL_ITEM';
const PER_MILE = 'PER_MILE';
const PER_GRAND_TOTAL = 'PER_GRAND_TOTAL';

const VALUES = [PER_BILL_TOTAL, PER_WEIGHT, PER_BILL_ITEM, PER_MILE, PER_GRAND_TOTAL];

const DEFAULT_UNITS = [CostItemUnit.CENTS, CostItemUnit.PERCENT, CostItemUnit.CENTS_PER_HOUR];
const COST_ITEM_UNITS_MAP = {
  [PER_WEIGHT]: [CostItemUnit.CENTS_PER_POUND, CostItemUnit.CENTS_PER_ONE_HUNDRED_POUNDS],
  [PER_MILE]: [CostItemUnit.CENTS_PER_MILE],
  [PER_BILL_TOTAL]: DEFAULT_UNITS,
  [PER_BILL_ITEM]: DEFAULT_UNITS,
  [PER_GRAND_TOTAL]: DEFAULT_UNITS,
};

const getCostItemUnits = (kind: any, {billItemUnit}: any = {}) => {
  if (kind === PER_BILL_ITEM) {
    if (billItemUnit === BillItemUnit.HOUR) {
      return [CostItemUnit.PERCENT, CostItemUnit.CENTS_PER_HOUR];
    }
    return [CostItemUnit.CENTS, CostItemUnit.PERCENT];
  }
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return COST_ITEM_UNITS_MAP[kind];
};

const DISPLAY_VALUE_MAP = {
  [PER_WEIGHT]: 'Per Weight',
  [PER_MILE]: 'Per Mile',
  [PER_BILL_TOTAL]: 'Per Bill',
  [PER_BILL_ITEM]: 'Per Item',
  [PER_GRAND_TOTAL]: 'Per Grand Total',
};

const getDisplayValue = (kind: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return DISPLAY_VALUE_MAP[kind];
};

export default {
  PER_BILL_TOTAL: 'PER_BILL_TOTAL',
  PER_WEIGHT: 'PER_WEIGHT',
  PER_BILL_ITEM: 'PER_BILL_ITEM',
  PER_MILE: 'PER_MILE',
  PER_GRAND_TOTAL: 'PER_GRAND_TOTAL',

  VALUES,
  getCostItemUnits,
  getDisplayValue,
};
