// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ documentId }: { documentId: a... Remove this comment to see the full error message
  ({documentId}) => {
    return {
      documentId,
    };
  },
  gql`
    fragment DownloadDocumentForm_new on Document {
      id
    }
  `,
);

const toForm = ({documentId}: any) => {
  return {
    documentId,
  };
};

const toMutation = ({documentId}: any) => {
  return {
    documentId,
  };
};

const DownloadDocumentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default DownloadDocumentForm;
