// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';

const GetPositionWrapper = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const EditProjectBlockWrapper = ({index, layoutKey, handleSetPositionY, children}) => {
  return (
    <GetPositionWrapper index={index} key={layoutKey} onLayout={handleSetPositionY}>
      {children}
    </GetPositionWrapper>
  );
};

export default EditProjectBlockWrapper;
