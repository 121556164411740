// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {BillingLibraryModel} from '@supermove/models';

// App
import Line from '@shared/design/components/Line';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import BillingLibraryPageNavigationTabs from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageNavigationTabs';
import SettingsHeaderNavigation from 'modules/Organization/Settings/components/SettingsHeaderNavigation';

const HeaderContainer = Styled.View`
`;

interface BillingLibraryPageHeaderProps {
  billingLibrary: BillingLibraryModel;
}

const BillingLibraryPageHeader = ({billingLibrary}: BillingLibraryPageHeaderProps) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <StandardOfficeHeader title={''} showLine={false}>
        <SettingsHeaderNavigation>
          <SettingsHeaderNavigation.PreviousScreen onPress={() => navigator.push('/settings')}>
            Settings
          </SettingsHeaderNavigation.PreviousScreen>
          <SettingsHeaderNavigation.PreviousScreen
            onPress={() => navigator.push('/settings/billing/billing-libraries')}
          >
            Billing Libraries
          </SettingsHeaderNavigation.PreviousScreen>
          <SettingsHeaderNavigation.CurrentScreen>
            {billingLibrary.name}
          </SettingsHeaderNavigation.CurrentScreen>
        </SettingsHeaderNavigation>
      </StandardOfficeHeader>
      <HeaderContainer style={{paddingHorizontal: responsive.desktop ? 24 : 12}}>
        <BillingLibraryPageNavigationTabs billingLibrary={billingLibrary} />
      </HeaderContainer>
      <Line />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryPageHeader.fragment = gql`
  ${BillingLibraryPageNavigationTabs.fragment}
  fragment BillingLibraryPageHeader on BillingLibrary {
    id
    name
    ...BillingLibraryPageNavigationTabs
  }
`;

export default BillingLibraryPageHeader;
