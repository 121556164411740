// Libraries
import React from 'react';

// Supermove
import {colors} from '@supermove/styles';

// App
import Button, {ButtonProps} from '@shared/design/components/Button';

export interface SecondaryButtonTextProps {
  color: string;
  isDisabled: boolean;
  style: object;
  children: React.ReactNode;
}

const SecondaryButtonText = ({
  isDisabled = false,
  color = colors.blue.interactive,
  style,
  children,
}: SecondaryButtonTextProps) => {
  return (
    <Button.Text color={isDisabled ? colors.gray.border : color} style={style}>
      {children}
    </Button.Text>
  );
};

export interface SecondaryButtonProps extends ButtonProps {
  color?: string;
  textColor?: string;
  isDisabled?: boolean;
  style?: object;
  children?: React.ReactNode;
}

const SecondaryButton = ({
  color = colors.white,
  children,
  isDisabled = false,
  textColor = colors.blue.interactive,
  style = {},
  ...props
}: SecondaryButtonProps) => {
  return (
    <Button
      {...props}
      isDisabled={isDisabled}
      textColor={isDisabled ? colors.gray.border : textColor}
      style={{
        borderWidth: 1,
        borderColor: isDisabled ? colors.gray.border : textColor || colors.blue.interactive,
        backgroundColor: colors.white,
        ...style,
      }}
    >
      {children}
    </Button>
  );
};

SecondaryButton.Text = SecondaryButtonText;

export default SecondaryButton;
