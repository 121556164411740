/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Form, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Json} from '@supermove/utils';

const PrepareDocumentSignatureForm = ({
  documentSignatureId,
  additionalInfo,
  onSuccess,
  onError,
  refetchQueries,
  children,
}) => (
  <Form
    initialValues={{
      documentSignatureId,
      additionalInfo: Json.toForm(additionalInfo),
    }}
    onSubmit={(values) => {}}
  >
    {({values, submitForm, setMutationErrors, ...form}) => (
      <Mutation
        awaitRefetchQueries
        variables={{
          documentSignatureId: values.documentSignatureId,
          additionalInfo: Json.toMutation(values.additionalInfo),
        }}
        mutation={PrepareDocumentSignatureForm.mutation}
        refetchQueries={refetchQueries}
        onCompleted={({prepareDocumentSignature: {documentSignature, errors}}) => {
          submitForm();
          setMutationErrors(errors);

          if (documentSignature) {
            onSuccess(documentSignature);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit, {loading, error}) =>
          children({
            ...form,
            loading,
            values,
            handleSubmit,
          })
        }
      </Mutation>
    )}
  </Form>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
PrepareDocumentSignatureForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

PrepareDocumentSignatureForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PrepareDocumentSignatureForm.mutation = gql`
  mutation PrepareDocumentSignatureForm(
    $documentSignatureId: Int!,
    $additionalInfo: JSONString,
  ) {
    prepareDocumentSignature(
      documentSignatureId: $documentSignatureId,
      additionalInfo: $additionalInfo,
    ) {
      ${gql.errors}
      documentSignature {
        id
      }
    }
  }
`;

export default PrepareDocumentSignatureForm;
