// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ShipmentStatusForm from '@shared/modules/Dispatch/forms/ShipmentStatusForm';

const useUpdateShipmentStatusMutation = ({shipmentStatusForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      shipmentStatusForm: ShipmentStatusForm.toForm(shipmentStatusForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateShipmentStatusMutation.mutation,
    variables: {
      shipmentStatusForm: ShipmentStatusForm.toMutation(form.values.shipmentStatusForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateShipmentStatusMutation.mutation = gql`
  mutation useUpdateShipmentStatusMutation($shipmentStatusForm: ShipmentStatusForm!) {
    response: updateShipmentStatus(shipmentStatusForm: $shipmentStatusForm) {
      ${gql.errors}
      shipment {
        id
        status
      }
    }
  }
`;

export default useUpdateShipmentStatusMutation;
