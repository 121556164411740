// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SalesTaxForm from '@shared/modules/Billing/forms/SalesTaxForm';

const useUpdateSalesTaxMutation = ({salesTaxForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      salesTaxForm: SalesTaxForm.toForm(salesTaxForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateSalesTaxMutation.mutation,
    variables: {
      salesTaxForm: SalesTaxForm.toMutation(form.values.salesTaxForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateSalesTaxMutation.mutation = gql`
  mutation useUpdateSalesTaxMutation($salesTaxForm: SalesTaxForm!) {
    response: updateSalesTax(salesTaxForm: $salesTaxForm) {
      ${gql.errors}
      salesTax {
        id
      }
    }
  }
`;

export default useUpdateSalesTaxMutation;
