import SuppliesForm from './SuppliesForm';

const OrganizationSuppliesForm = {
  toForm: ({organizationId, suppliesForm}) => ({
    organizationId,
    suppliesForm: SuppliesForm.toForm(suppliesForm),
  }),

  toMutation: ({organizationId, suppliesForm}) => ({
    organizationId,
    suppliesForm: SuppliesForm.toMutation(suppliesForm),
  }),
};

export default OrganizationSuppliesForm;
