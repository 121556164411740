// Libraries
import _ from 'lodash';

// Supermove
import {uuid} from '@supermove/utils';

export interface CreateTaskFromTemplateFormType {
  workflowRunStepUuid: string;
  taskTemplateId: string | number | null;
}

const _new = (): CreateTaskFromTemplateFormType => ({
  workflowRunStepUuid: uuid(),
  taskTemplateId: null,
});

const edit = (createTaskFromTemplateForm: any): CreateTaskFromTemplateFormType => ({
  workflowRunStepUuid: createTaskFromTemplateForm.workflowRunStepUuid,
  taskTemplateId: _.toString(createTaskFromTemplateForm.taskTemplateId),
});

const toForm = (
  CreateTaskFromTemplateForm: CreateTaskFromTemplateFormType,
): CreateTaskFromTemplateFormType => ({
  workflowRunStepUuid: CreateTaskFromTemplateForm.workflowRunStepUuid,
  taskTemplateId: CreateTaskFromTemplateForm.taskTemplateId,
});

const toMutation = (
  CreateTaskFromTemplateForm: CreateTaskFromTemplateFormType,
): CreateTaskFromTemplateFormType => ({
  workflowRunStepUuid: CreateTaskFromTemplateForm.workflowRunStepUuid,
  taskTemplateId: _.toNumber(CreateTaskFromTemplateForm.taskTemplateId),
});

const CreateTaskFromTemplateForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default CreateTaskFromTemplateForm;
