// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import InventoryLibraryForm from '@shared/modules/Inventory/forms/InventoryLibraryForm';

const useUpdateInventoryLibraryMutation = ({inventoryLibraryForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      inventoryLibraryForm: InventoryLibraryForm.toForm(inventoryLibraryForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateInventoryLibraryMutation.mutation,
    variables: {
      inventoryLibraryForm: InventoryLibraryForm.toMutation(form.values.inventoryLibraryForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateInventoryLibraryMutation.mutation = gql`
  mutation useUpdateInventoryLibraryMutation($inventoryLibraryForm: InventoryLibraryForm!) {
    response: updateInventoryLibrary(inventoryLibraryForm: $inventoryLibraryForm) {
      ${gql.errors}
      inventoryLibrary {
        id
      }
    }
  }
`;

export default useUpdateInventoryLibraryMutation;
