// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Link, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Percent} from '@supermove/utils';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import SalesTaxForm from '@shared/modules/Billing/forms/SalesTaxForm';
import useUpdateSalesTaxMutation from '@shared/modules/Billing/hooks/useUpdateSalesTaxMutation';
import TextInputWithDropdown from 'modules/App/components/TextInputWithDropdown';

const LinkText = Styled.Text`
  ${Typography.Responsive.Link};
`;

const UpdateSalesTaxModalContent = ({project, handleClose}) => {
  const responsive = useResponsive();
  const salesTaxForm = SalesTaxForm.edit(project.salesTax);
  const {form, submitting, handleSubmit} = useUpdateSalesTaxMutation({
    salesTaxForm,
    onSuccess: handleClose,
    onError: (errors) => console.log({errors}),
  });
  const field = 'salesTaxForm';
  const salesTaxTemplate =
    project.projectType.salesTaxTemplate || project.organization.settings.salesTaxTemplate;

  const variableOptions = project.organization.variables
    .filter((variable) =>
      _.includes([VariableFormat.PERCENT, VariableFormat.DROPDOWN_PERCENT], variable.format),
    )
    .map((variable) => ({
      label: variable.name,
      value: variable.id,
    }));

  return (
    <React.Fragment>
      <SmallModal.HeaderText style={{alignSelf: 'center'}} responsive={responsive}>
        Set Sales Tax Rate
      </SmallModal.HeaderText>
      <Space height={16} />
      <SmallModal.Text responsive={responsive}>
        {`Set the default sales tax rate for this project by entering a value or selecting a project variable. ` +
          `This will override the project type's default `}
        <Link to={`/settings/projects/project-types/${project.projectType.uuid}/accounting`}>
          <LinkText responsive={responsive}>{`sales tax rate`}</LinkText>
        </Link>
        {` for this project only.`}
      </SmallModal.Text>
      <Space height={16} />
      <TextInputWithDropdown
        form={form}
        textValueField={`${field}.rate`}
        clearTextValue={''}
        dropdownValueField={`${field}.variableId`}
        label={'Sales Tax Rate'}
        dropdownOptions={variableOptions}
        placeholder={'Enter a percentage or select a variable'}
        isPortaled
        isResponsive
        onBlur={() => {
          const rateField = `${field}.rate`;
          const rate = _.get(form.values, rateField);
          if (_.get(form.values, `${field}.variableId`)) {
            form.setFieldValue(rateField, '');
          } else if (rate !== '') {
            form.setFieldValue(rateField, Percent.toForm(Percent.toFloat(rate)));
          }
        }}
        noOptionsMessage={'No variable options'}
        action={
          <React.Fragment>
            <Icon source={Icon.ArrowRotateRight} size={12} color={colors.blue.interactive} />
            <Space width={8} />
          </React.Fragment>
        }
        actionText={'Reset to Default'}
        handleAction={() => {
          form.setFieldValue(`${field}.rate`, Percent.toForm(salesTaxTemplate.rate));
          form.setFieldValue(`${field}.variableId`, _.toString(salesTaxTemplate.variableId));
        }}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose} isDisabled={submitting} isResponsive>
          Cancel
        </SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          onPress={handleSubmit}
          isSubmitting={submitting}
          color={colors.blue.interactive}
          iconLeft={Icon.Check}
          iconSize={12}
          isResponsive
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </React.Fragment>
  );
};

const UpdateSalesTaxModal = ({isOpen, handleClose, projectUuid, parentRefetch}) => {
  const {loading, data} = useQuery(UpdateSalesTaxModal.query, {
    variables: {projectUuid},
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });
  const isLoading = loading || !data;
  return (
    <SmallModal isOpen={isOpen} isLoading={isLoading} isResponsive>
      {!isLoading && (
        <UpdateSalesTaxModalContent
          project={data.project}
          handleClose={() => {
            parentRefetch();
            handleClose();
          }}
        />
      )}
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateSalesTaxModal.query = gql`
  ${SalesTaxForm.edit.fragment}
  fragment UpdateSalesTaxModal_SalesTaxTemplate on SalesTaxTemplate {
    id
    rate
    variableId
  }

  query UpdateSalesTaxModal($projectUuid: String!) {
    project(uuid: $projectUuid) {
      id
      salesTax {
        id
        ...SalesTaxForm_edit
      }
      projectType {
        id
        uuid
        salesTaxTemplate {
          id
          ...UpdateSalesTaxModal_SalesTaxTemplate
        }
      }
      organization {
        id
        settings {
          id
          salesTaxTemplate {
            id
            ...UpdateSalesTaxModal_SalesTaxTemplate
          }
        }
        variables {
          id
          name
          format
        }
      }
    }
  }
`;

export default UpdateSalesTaxModal;
