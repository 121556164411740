// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import AssignUsersToCrewWithCrewSlotsForm from '@shared/modules/Dispatch/forms/AssignUsersToCrewWithCrewSlotsForm';

const useAssignUsersToCrewWithCrewSlotsMutation = ({
  assignUsersToCrewWithCrewSlotsForm,
  refetchQueries,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      assignUsersToCrewWithCrewSlotsForm: AssignUsersToCrewWithCrewSlotsForm.toForm(
        assignUsersToCrewWithCrewSlotsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useAssignUsersToCrewWithCrewSlotsMutation.mutation,
    refetchQueries,
    variables: {
      assignUsersToCrewWithCrewSlotsForm: AssignUsersToCrewWithCrewSlotsForm.toMutation(
        form.values.assignUsersToCrewWithCrewSlotsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useAssignUsersToCrewWithCrewSlotsMutation.mutation = gql`
  mutation useAssignUsersToCrewWithCrewSlotsMutation(
    $assignUsersToCrewWithCrewSlotsForm: AssignUsersToCrewWithCrewSlotsForm!
  ) {
    response: assignUsersToCrewWithCrewSlots(
      assignUsersToCrewWithCrewSlotsForm: $assignUsersToCrewWithCrewSlotsForm
    ) {
      ${gql.errors}
      # NOTE: This is a hack to get updated job user data back from the server for the CapacityCalendarJobsListTable
      job {
        id
        activeJobUsers {
          id
          statusColor
          status
          user {
            id
            position
            organizationId
            fullName
          }
        }
        activeJobUsersCount
        numberOfMovers
      }
    }
  }
`;

export default useAssignUsersToCrewWithCrewSlotsMutation;
