// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {BillModel, PaymentFeeModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const Row = Styled.View`
  flex-direction: row;
`;

// TODO(jholston): Update to responsive typographies when all
// document bill elements are updated
const Text = Styled.Text`
  ${Typography.Body3}
`;

const EmptySpace = Styled.View`
  flex: 6;
`;

const Container = Styled.View`
  flex: 7;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const NameContainer = Styled.View`
  flex: 1;
  padding-left: 12px;
  flex-direction: row;
  align-items: center;
`;

const NameRow = Styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: ${(props) => ((props as any).mobile ? 'flex-start' : 'flex-end')};
`;

const AmountContainer = Styled.View`
  justify-content: center;
  align-items: flex-end;
`;

const PaymentFee = ({paymentFee}: {paymentFee: PaymentFeeModel}) => {
  const responsive = useResponsive();
  const isDiscount = paymentFee.amount < 0;
  return (
    <Row>
      <NameContainer {...responsive}>
        <NameRow {...responsive}>
          <Space width={8} />
          <Text numberOfLines={1}>{paymentFee.displayName}</Text>
        </NameRow>
      </NameContainer>
      <AmountContainer style={!responsive.mobile && {flex: 1}}>
        {!!paymentFee && (
          <Text style={{color: isDiscount ? colors.green.status : colors.gray.primary}}>
            {Currency.format({value: paymentFee.amount})}
          </Text>
        )}
      </AmountContainer>
    </Row>
  );
};

const BillingDocumentBillPaymentFees = ({bill}: {bill: BillModel}) => {
  const responsive = useResponsive();
  return (
    <Row>
      {!responsive.mobile && <EmptySpace />}
      <Container>
        {bill.paymentFees.map((paymentFee, index) => {
          return (
            <React.Fragment key={paymentFee.id}>
              {index > 0 && <Space height={8} />}
              <PaymentFee paymentFee={paymentFee} />
            </React.Fragment>
          );
        })}
      </Container>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingDocumentBillPaymentFees.fragment = gql`
  fragment BillingDocumentBillPaymentFees on Bill {
    id
    paymentFees {
      id
      displayName
      amount
    }
  }
`;

export default BillingDocumentBillPaymentFees;
