export interface DeletePaymentMethodFormType {
  paymentMethodId: string;
}

const edit = (paymentMethodId: string): DeletePaymentMethodFormType => ({
  paymentMethodId,
});

const toForm = ({paymentMethodId}: DeletePaymentMethodFormType) => ({
  paymentMethodId,
});

const toMutation = ({paymentMethodId}: DeletePaymentMethodFormType) => ({
  paymentMethodId,
});

const DeletePaymentMethodForm = {
  edit,
  toForm,
  toMutation,
};

export default DeletePaymentMethodForm;
