// Libraries

// Supermove
import {uuid} from '@supermove/utils';

export interface GetJobDataActionFormType {
  workflowRunStepUuid: string;
}

const _new = (): GetJobDataActionFormType => ({
  workflowRunStepUuid: uuid(),
});

const edit = (getJobDataActionForm: any): GetJobDataActionFormType => ({
  workflowRunStepUuid: getJobDataActionForm.workflowRunStepUuid,
});

const toForm = (getJobDataActionForm: GetJobDataActionFormType): GetJobDataActionFormType => ({
  workflowRunStepUuid: getJobDataActionForm.workflowRunStepUuid,
});

const toMutation = (getJobDataActionForm: GetJobDataActionFormType): GetJobDataActionFormType => ({
  workflowRunStepUuid: getJobDataActionForm.workflowRunStepUuid,
});

const GetJobDataActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default GetJobDataActionForm;
