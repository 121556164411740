// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FileDragInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
import AttachmentForm from '@shared/modules/File/forms/AttachmentForm';

const DragInputContainer = Styled.View`
  padding: ${(props) => ((props as any).mobile ? '16' : '0')}px;
  background-color: ${colors.gray.background};
  borderBottomWidth: ${(props) => ((props as any).mobile ? '1' : '0')}px;
  borderBottomColor: ${colors.gray.border};
`;

const FileDragInputContainer = Styled.View`
  height: 126px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px dashed;
  cursor: pointer;
  padding-horizontal: 46px;
  border-color: ${colors.gray.secondary};
  background-color: ${colors.white};
`;

const FileDragInputText = Styled.Text`
  ${Typography.Label}
  width: 100%;
  text-align: center;
  color: ${colors.gray.secondary};
`;

const FileDragInputClickText = Styled.Text`
  ${Typography.Link}
  width: 100%;
  text-align: center;
  ${fontWeight(700)}
`;

const FileTypeText = Styled.Text`
  ${Typography.Body}
  width: 100%;
  text-align: ${(props) => (props as any).center || 'left'};
  color: ${(props) => (props as any).color};
`;

const ErrorContainer = Styled.View`
  padding-vertical: 8px;
  padding-horizontal: 24px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${colors.red.accent};
  background-color: ${colors.red.accent};
`;

const FileDragInputInstructions = ({isDragActive, isMobile}: any) => {
  if (isDragActive) {
    return <FileDragInputText>{'Drop files here'}</FileDragInputText>;
  }
  if (isMobile) {
    return <FileDragInputClickText>{'Click to upload'}</FileDragInputClickText>;
  }
  return (
    <FileDragInputText>
      {'Drag files here or '}
      <FileDragInputClickText>{'click to upload'}</FileDragInputClickText>
      {'.'}
    </FileDragInputText>
  );
};

const DragInput = ({form, field, project, isMobile}: any) => {
  const attachmentForms = _.get(form.values, field);
  return (
    <FileDragInput
      onFilesChange={(files) => {
        const newAttachmentForms = files.map((file) => {
          return AttachmentForm.toForm({
            organizationId: project.organizationId,
            projectId: project.id,
            mimetype: file.type,
            filename: file.name,
            // @ts-expect-error TS(2322): Type '"PROJECT_ATTACHMENT"' is not assignable to t... Remove this comment to see the full error message
            attachmentCategoryKinds: ['PROJECT_ATTACHMENT'],
            description: '',
            identifier: '',
            file,

            // Private
            hasUploadError: false,
          });
        });
        form.setFieldValue(field, [...attachmentForms, ...newAttachmentForms]);
      }}
    >
      {({isDragActive}) => (
        <FileDragInputContainer>
          <FileDragInputInstructions isDragActive={isDragActive} isMobile={isMobile} />
          <Space height={8} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <FileTypeText color={colors.gray.tertiary} center={'center'}>
            PDF, PNG, JPG, CSV, TXT, DOC, DOCX, XLS, XLSX, XLSB, MP4, WAV, MOV
          </FileTypeText>
        </FileDragInputContainer>
      )}
    </FileDragInput>
  );
};

const ErrorAlert = () => {
  return (
    <ErrorContainer>
      <FileTypeText color={colors.red.warning}>
        Some files failed to upload. Please check and reupload them.
      </FileTypeText>
    </ErrorContainer>
  );
};

type OwnProjectAttachmentUploaderDragInputProps = {
  form: any;
  field: string;
  project: any;
  isViewMode?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'ProjectAttachmentUploaderDragInputProp... Remove this comment to see the full error message
type ProjectAttachmentUploaderDragInputProps = OwnProjectAttachmentUploaderDragInputProps &
  typeof ProjectAttachmentUploaderDragInput.defaultProps;

// @ts-expect-error TS(7022): 'ProjectAttachmentUploaderDragInput' implicitly ha... Remove this comment to see the full error message
const ProjectAttachmentUploaderDragInput = ({
  form,
  field,
  project,
  isViewMode,
  dragInputContainerStyle,
}: ProjectAttachmentUploaderDragInputProps) => {
  const responsive = useResponsive();
  const attachmentForms = _.get(form.values, field);

  const uploadErrorCount = attachmentForms.reduce((errorCount: any, attachmentForm: any) => {
    if (attachmentForm.hasUploadError) {
      return errorCount + 1;
    }
    return errorCount;
  }, 0);

  return (
    <React.Fragment>
      {!isViewMode && (
        <DragInputContainer {...responsive} style={dragInputContainerStyle}>
          <DragInput form={form} field={field} project={project} isMobile={responsive.mobile} />
          {uploadErrorCount > 0 && (
            <React.Fragment>
              <Space height={16} />
              {/* @ts-expect-error TS(2322): Type '{ form: any; field: any; }' is not assignabl... Remove this comment to see the full error message */}
              <ErrorAlert form={form} field={field} />
            </React.Fragment>
          )}
          <Space height={16} />
        </DragInputContainer>
      )}
    </React.Fragment>
  );
};

ProjectAttachmentUploaderDragInput.defaultProps = {
  isViewMode: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectAttachmentUploaderDragInput.fragment = gql`
  fragment ProjectAttachmentUploaderDragInput on Project {
    id
    organizationId
  }
`;

export default ProjectAttachmentUploaderDragInput;
