// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Formula} from '@supermove/models';

// App
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import AccountingItemField from 'modules/Organization/Settings/BillingLibraries/components/AccountingItemField';
import BillItemTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/BillItemTypeDrawer';
import PerPositionRateFields from 'modules/Organization/Settings/BillingLibraries/components/PerPositionRateFields';

const Row = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex-direction: row;
`;

const ValueField = ({form, field, fieldIndex, formulaOptions, isDisabled}) => {
  const kind = _.get(form.values, `${field}.kind`);
  const isKindAmount = kind === BillItemTypeKind.AMOUNT;
  return (
    <Row index={fieldIndex}>
      {isKindAmount ? (
        <BillItemTypeDrawer.AmountField
          form={form}
          field={field}
          formulaOptions={formulaOptions}
          isDisabled={isDisabled}
        />
      ) : (
        <BillItemTypeDrawer.PercentField
          form={form}
          field={field}
          formulaOptions={formulaOptions}
          isDisabled={isDisabled}
        />
      )}
      <Space width={8} />
      <FieldInput
        {...form}
        label={'Unit'}
        name={`${field}.unit`}
        component={DropdownInput}
        input={{
          disabled: true,
          options: [
            {label: '$', value: BillItemUnit.DOLLAR},
            {label: '%', value: BillItemUnit.PERCENT},
            {label: '/ hour', value: BillItemUnit.HOUR},
          ],
          style: {width: 116},
        }}
      />
    </Row>
  );
};

const EditBillItemTypeFields = ({
  form,
  field,
  category,
  formulas,
  moverPositions,
  organization,
  showGlobalEditWarning,
}) => {
  const isEnabledPerPositionRates = _.get(form.values, `${field}.isEnabledPerPositionRates`);
  const isCategorySupplies = category === BillItemTypeCategory.SUPPLIES;
  const isCategoryFees = category === BillItemTypeCategory.FEES;
  const billStage = _.get(form.values, `${field}.billStage`);
  const isPreSubtotal = billStage === BillStage.PRE_SUBTOTAL;
  const unit = _.get(form.values, `${field}.unit`);
  const isUnitHour = unit === BillItemUnit.HOUR;
  const isUnitDollar = unit === BillItemUnit.DOLLAR;
  const isShowingPerPositionRateFields =
    isCategoryFees && isPreSubtotal && (isUnitHour || isUnitDollar);
  const formulaOptions = Formula.getFormulaDropdownOptions(formulas);

  return (
    <React.Fragment>
      {showGlobalEditWarning && (
        <React.Fragment>
          <WarningCallout
            text={'Updating this bill item will change it everywhere in the billing library.'}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      <BillItemTypeDrawer.NameField
        form={form}
        field={field}
        fieldIndex={0}
        formulaOptions={formulaOptions}
      />
      <Space height={16} />
      <BillItemTypeDrawer.DescriptionField form={form} field={field} fieldIndex={5} />
      <Space height={16} />
      {isPreSubtotal && (
        <React.Fragment>
          <BillItemTypeDrawer.QuantityField
            form={form}
            field={field}
            fieldIndex={1}
            formulaOptions={formulaOptions}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      {isCategorySupplies ? (
        <BillItemTypeDrawer.PriceField
          form={form}
          field={field}
          fieldIndex={2}
          formulaOptions={formulaOptions}
          isDisabled={isEnabledPerPositionRates}
        />
      ) : (
        <React.Fragment>
          <ValueField
            form={form}
            field={field}
            fieldIndex={3}
            formulaOptions={formulaOptions}
            isDisabled={isEnabledPerPositionRates}
          />
          <Space height={16} />
          <FieldInput.LabelText>Before / After Subtotal</FieldInput.LabelText>
          <Space height={4} />
          <FieldInput.TextInput disabled value={BillStage.getDisplayName(billStage)} />
        </React.Fragment>
      )}
      <Space height={16} />
      <BillItemTypeDrawer.TaxableField form={form} field={field} />
      {isCategoryFees && (
        <PerPositionRateFields
          form={form}
          field={field}
          fieldIndex={6}
          moverPositions={moverPositions}
          formulaOptions={formulaOptions}
          isShowingPerPositionRateFields={isShowingPerPositionRateFields}
          organization={organization}
        />
      )}
      {!isEnabledPerPositionRates && (
        <React.Fragment>
          <Space height={16} />
          <AccountingItemField
            form={form}
            field={field}
            organization={organization}
            fieldIndex={7}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillItemTypeFields.fragment = gql`
  ${AccountingItemField.fragment}
  ${Formula.getFormulaDropdownOptions.fragment}
  ${PerPositionRateFields.fragment}
  fragment EditBillItemTypeFields_Organization on Organization {
    id
    ...AccountingItemField
    ...PerPositionRateFields_Organization
  }

  fragment EditBillItemTypeFields_MoverPosition on MoverPosition {
    id
    ...PerPositionRateFields
  }

  fragment EditBillItemTypeFields_Formula on Formula {
    id
    ...Formula_getFormulaDropdownOptions
  }
`;

export default EditBillItemTypeFields;
