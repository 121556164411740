// Supermove
import {gql} from '@supermove/graphql';
import {AttachmentModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (attachment: AttachmentModel): UpdateAttachmentFormType => {
    return {
      attachmentId: attachment.id,
      attachmentUuid: attachment.uuid,
      filename: attachment.file.name,
      description: attachment.description,
      isVisibleToCrew: attachment.isVisibleToCrew,

      downloadUrl: attachment.file.downloadUrl,
      mimeType: attachment.file.mimetype,
    };
  },
  gql`
    fragment UpdateAttachmentForm_edit on Attachment {
      id
      uuid
      description
      isVisibleToCrew
      file {
        id
        name
      }
    }
  `,
);

export interface UpdateAttachmentFormType {
  attachmentId: string;
  attachmentUuid: string;
  filename: string;
  description?: string;
  isVisibleToCrew: boolean;

  // private
  error?: any;
  downloadUrl?: string;
  mimeType?: string;
}

export interface UpdateAttachmentFormTypeMutation {
  attachmentId: string;
  filename: string;
  description?: string;
  isVisibleToCrew: boolean;
}

const toForm = ({
  attachmentId,
  attachmentUuid,
  filename,
  description,
  isVisibleToCrew,
  downloadUrl,
  mimeType,
}: UpdateAttachmentFormType): UpdateAttachmentFormType => ({
  attachmentId,
  attachmentUuid,
  filename,
  description,
  isVisibleToCrew,
  downloadUrl,
  mimeType,
});

const toMutation = ({
  attachmentId,
  filename,
  description,
  isVisibleToCrew,
}: UpdateAttachmentFormType): UpdateAttachmentFormTypeMutation => ({
  attachmentId,
  filename,
  description,
  isVisibleToCrew,
});

const UpdateAttachmentForm = {
  edit,
  toForm,
  toMutation,
};

export default UpdateAttachmentForm;
