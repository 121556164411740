// Libararies
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useNavigationDOM, useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Tabs from '@shared/design/components/Tabs';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import DriverTripViewDrawer from 'modules/Dispatch/LongDistance/Trip/components/DriverTripViewDrawer';
import TripDayViewContent from 'modules/Dispatch/LongDistance/Trip/components/TripDayViewContent';
import TripLogisticsColumn from 'modules/Dispatch/LongDistance/Trip/components/TripLogisticsColumn';
import TripShipmentsContent from 'modules/Dispatch/LongDistance/Trip/components/TripShipmentsContent';

const Container = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TabContentContainer = Styled.View`
  flex: 1;
  padding-top: 16px;
  padding-horizontal: 16px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
`;

const getBreadcrumbs = ({navigator, trip}) => [
  {name: 'Dispatch', onPress: () => navigator.push('/dispatch')},
  {name: 'Long Distance', onPress: () => navigator.push('/dispatch/long-distance/trips')},
  {name: trip.name},
];

const getTabIndex = ({tab}) => {
  switch (tab) {
    case 'day':
      return 1;
    case 'shipments':
    default:
      return 0;
  }
};

const handleTabPress = ({tabValue, tripUuid, navigator}) => {
  navigator.push(`/dispatch/long-distance/trips/${tripUuid}/${tabValue}`);
};

const getTripPageTabs = ({trip}) => {
  return [{label: 'Shipments', count: trip.shipments.length, value: 'shipments'}];
};

const TripLongDistanceDispatchPageHeader = ({trip}) => {
  const {navigator} = useNavigationDOM();

  return (
    <StandardOfficeHeader
      title={''}
      showLine={false}
      officeHeaderStyle={{marginVertical: '10px', marginHorizontal: '16px'}}
    >
      <Breadcrumbs breadcrumbs={getBreadcrumbs({navigator, trip})} />
    </StandardOfficeHeader>
  );
};

const TripLongDistanceDispatchPageTabContent = ({trip, activeTabIndex, refetch}) => {
  switch (activeTabIndex) {
    case 1:
      return <TripDayViewContent trip={trip} refetch={refetch} />;
    case 0:
    default:
      return <TripShipmentsContent trip={trip} refetch={refetch} />;
  }
};

const TripLongDistanceDispatchPageContent = ({trip, activeTabIndex, refetch}) => {
  const {params, navigator} = useNavigationDOM();
  const driverTripViewDrawer = useDrawer({
    name: 'Driver Trip View Drawer',
    enableTracking: true,
  });

  return (
    <Container>
      <Space height={16} />
      <Row>
        <Tabs
          tabs={getTripPageTabs({trip})}
          handlePressTab={({value}) =>
            handleTabPress({tabValue: value, tripUuid: params.tripUuid, navigator})
          }
          activeTabIndex={activeTabIndex}
        />
        <Space style={{flex: 1}} />
        <TertiaryButton
          text={'Driver Trips View'}
          iconLeft={Icon.Eye}
          onPress={driverTripViewDrawer.handleOpen}
        />
      </Row>
      <TabContentContainer>
        <TripLongDistanceDispatchPageTabContent
          trip={trip}
          activeTabIndex={activeTabIndex}
          refetch={refetch}
        />
      </TabContentContainer>
      <Space height={16} />
      <DriverTripViewDrawer
        isOpen={driverTripViewDrawer.isOpen}
        handleClose={driverTripViewDrawer.handleClose}
        tripUuid={trip.uuid}
      />
    </Container>
  );
};

const TripLongDistanceDispatchPage = () => {
  const {params} = useNavigationDOM();
  const activeTabIndex = getTabIndex({tab: params.tab});
  const {data, refetch} = useQuery(TripLongDistanceDispatchPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {tripUuid: params.tripUuid},
  });

  if (!data) {
    return (
      <SidebarPageV2 selected={'dispatch'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }
  const {trip} = data;
  return (
    <SidebarPageV2 selected={'dispatch'}>
      <Container>
        <TripLongDistanceDispatchPageHeader trip={trip} />
        <Line />
        <Container style={{flexDirection: 'row'}}>
          <TripLogisticsColumn trip={trip} refetch={refetch} />
          <TripLongDistanceDispatchPageContent
            trip={trip}
            activeTabIndex={activeTabIndex}
            refetch={refetch}
          />
          <Space width={16} />
        </Container>
      </Container>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TripLongDistanceDispatchPage.query = gql`
  ${TripLogisticsColumn.fragment}
  ${TripDayViewContent.fragment}
  ${TripShipmentsContent.fragment}
  query TripLongDistanceDispatchPage($tripUuid: String!) {
    ${gql.query}
    trip(tripUuid: $tripUuid) {
      id
      name
      shipments {
        id
      }
      ...TripLogisticsColumn
      ...TripDayViewContent
      ...TripShipmentsContent
    }
  }
`;

export default TripLongDistanceDispatchPage;
