// Libraries
import React from 'react';

// Supermove
import {useNavigationDOM} from '@supermove/hooks';

// App
import OrganizationSettingsTaskTemplates from 'modules/Organization/Settings/Tasks/components/OrganizationSettingsTaskTemplates';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const TABS = {
  TASK_TEMPLATES: 'task-templates',
};

const TAB_INFO = {
  [TABS.TASK_TEMPLATES]: {
    title: 'Task Templates',
    description: 'Manage your task templates here.',
  },
};

const PageContent = () => {
  const {params} = useNavigationDOM();

  switch (params.tab) {
    case TABS.TASK_TEMPLATES:
      return <OrganizationSettingsTaskTemplates />;
    default:
      return null;
  }
};

const OrganizationSettingsTasksPage = () => {
  const {params, navigator} = useNavigationDOM();
  const {title, description} = TAB_INFO[params.tab];

  return (
    <SettingsPage
      title={title}
      description={description}
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {name: 'Tasks'},
      ]}
      tabs={[
        {
          label: 'Task Templates',
          url: '/settings/tasks/task-templates',
        },
      ]}
    >
      <PageContent />
    </SettingsPage>
  );
};

export default OrganizationSettingsTasksPage;
