// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CommercialEquipmentForm from '@shared/modules/CommercialCatalog/forms/CommercialEquipmentForm';
import CommercialMaterialForm from '@shared/modules/CommercialCatalog/forms/CommercialMaterialForm';

const edit = withFragment(
  (commercialCatalog) => ({
    commercialCatalogId: (commercialCatalog as any).id,
    commercialEquipmentForms: (commercialCatalog as any).commercialEquipments.map(
      (commercialEquipment: any) => {
        return CommercialEquipmentForm.edit(commercialEquipment);
      },
    ),
    commercialMaterialForms: (commercialCatalog as any).commercialMaterials.map(
      (commercialMaterial: any) => {
        return CommercialMaterialForm.edit(commercialMaterial);
      },
    ),
  }),
  gql`
    ${CommercialEquipmentForm.edit.fragment}
    ${CommercialMaterialForm.edit.fragment}

    fragment CommercialCatalogForm_edit on CommercialCatalog {
      id
      commercialEquipments {
        id
        ...CommercialEquipmentForm_edit
      }
      commercialMaterials {
        id
        ...CommercialMaterialForm_edit
      }
    }
  `,
);

const _new = () => ({
  commercialCatalogId: undefined,
  commercialEquipmentForms: [],
  commercialMaterialForms: [],
});

const toForm = ({commercialCatalogId, commercialEquipmentForms, commercialMaterialForms}: any) => ({
  commercialCatalogId,
  commercialEquipmentForms: commercialEquipmentForms.map((commercialEquipmentForm: any) => {
    return CommercialEquipmentForm.toForm(commercialEquipmentForm);
  }),
  commercialMaterialForms: commercialMaterialForms.map((commercialMaterialForm: any) => {
    return CommercialMaterialForm.toForm(commercialMaterialForm);
  }),
});

const toMutation = ({
  commercialCatalogId,
  commercialEquipmentForms,
  commercialMaterialForms,
}: any) => ({
  commercialCatalogId,
  commercialEquipmentForms: commercialEquipmentForms.map((commercialEquipmentForm: any) => {
    return CommercialEquipmentForm.toMutation(commercialEquipmentForm);
  }),
  commercialMaterialForms: commercialMaterialForms.map((commercialMaterialForm: any) => {
    return CommercialMaterialForm.toMutation(commercialMaterialForm);
  }),
});

const CommercialCatalogForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default CommercialCatalogForm;
