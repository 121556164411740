// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled, Tabs} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, usePopover, useQuery, useTabs} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import BillForm from '@shared/modules/Billing/forms/BillForm';
import useUpdateBillMutation from '@shared/modules/Billing/hooks/useUpdateBillMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import EditBillEditTitleModal from 'modules/Project/Billing/Bill/Edit/components/EditBillEditTitleModal';
import EditBillSlide from 'modules/Project/Billing/Bill/Edit/components/EditBillSlide';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.justify};
`;

const TitleTextRow = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const TitleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  line-height: 28px;
`;

const CaptionText = Styled.H7`
  color: ${colors.gray.tertiary};
  ${fontWeight(500)}
`;

const ScreenContainer = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View`
  padding-horizontal: 4px;
`;

const HeaderButton = Styled.ButtonV2`
  padding-horizontal: 12px;
  align-items: center;
`;

const FooterContainer = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-horizontal: 16px;
  padding-vertical: 12px;
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const FooterButton = Styled.ButtonV2`
  padding-vertical: 8px;
  width: 100px
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const ActivityIndicator = Styled.Loading`
`;

const ButtonText = Styled.H5`
  ${fontWeight(700)}
  color: ${(props) => props.color};
  line-height: 28px;
`;

const BillItemsModalHeader = ({form, handleClose, editBillModal}) => {
  return (
    <HeaderContainer>
      <Row justify={'space-between'}>
        <HeaderButton onPress={handleClose}>
          <Icon source={Icon.ArrowLeft} size={20} color={colors.gray.primary} />
        </HeaderButton>
        <TitleTextRow justify={'flex-start'}>
          <TitleText numberOfLines={1}>{form.values.billForm.title}</TitleText>
        </TitleTextRow>
        <HeaderButton onPress={editBillModal.handleOpen}>
          <Icon source={Icon.Pen} size={20} color={colors.blue.interactive} />
        </HeaderButton>
      </Row>
      <Space height={2} />
      <Row justify={'flex-start'}>
        <Space width={42} />
        <CaptionText numberOfLines={1}>{form.values.billForm.description}</CaptionText>
      </Row>
    </HeaderContainer>
  );
};

const Tab = ({tab, isSelected, handlePress}) => {
  return (
    <Tabs.TabContainer
      isSelected={isSelected}
      onPress={handlePress}
      style={{flex: 1, justifyContent: 'center'}}
    >
      <Tabs.TabText isSelected={isSelected}>{tab.label}</Tabs.TabText>
    </Tabs.TabContainer>
  );
};

const BillItemsModalFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <FooterContainer>
      <Row justify={'flex-end'}>
        <FooterButton color={colors.white} onPress={handleClose} disabled={submitting}>
          <ButtonText color={colors.gray.tertiary}>Cancel</ButtonText>
        </FooterButton>
        <Space width={10} />
        <FooterButton
          color={submitting ? colors.gray.border : colors.blue.interactive}
          disabled={submitting}
          onPress={handleSubmit}
        >
          {submitting ? (
            <ActivityIndicator color={colors.white} />
          ) : (
            <ButtonText color={colors.white}>Save</ButtonText>
          )}
        </FooterButton>
      </Row>
    </FooterContainer>
  );
};

const EditBillBillingProjectPageContent = ({bill, handleClose}) => {
  const billForm = BillForm.editWithAllOptions(bill);
  const {form, submitting, handleSubmit} = useUpdateBillMutation({
    billForm,
    onSuccess: handleClose,
    onError: (error) => {
      console.log({error});
    },
  });

  const editBillModal = useModal();
  const billItemsSearchPopover = usePopover();
  const billRulesSearchPopover = usePopover();
  const tabs = useTabs({initialIndex: 0});

  // Whenever we switch tabs we want to make sure that all popovers are closed.
  // If a popover is open, it will show up on the newly selected tab because
  // popovers always move to be in view.
  const handleCloseAllSearch = () => {
    billItemsSearchPopover.handleClose();
    billRulesSearchPopover.handleClose();
  };

  return (
    <ScreenContainer>
      <Space height={15} />
      <BillItemsModalHeader form={form} handleClose={handleClose} editBillModal={editBillModal} />
      <Space height={14} />
      <Tabs
        tabs={tabs}
        initialIndex={0}
        TabComponent={Tab}
        style={{flex: 1}}
        tabBarStyle={{paddingHorizontal: 16}}
        scrollEnabled={false}
        onChangeIndex={handleCloseAllSearch}
      >
        <Tabs.Slide key={0} tab={{label: 'Items'}}>
          <EditBillSlide
            bill={bill}
            form={form}
            type={'ITEMS'}
            showSearch={billItemsSearchPopover.isOpen}
            handleOpenSearch={billItemsSearchPopover.handleOpen}
            handleCloseSearch={billItemsSearchPopover.handleClose}
            searchPopover={billItemsSearchPopover}
          />
        </Tabs.Slide>
        <Tabs.Slide key={1} tab={{label: 'Rules'}}>
          <EditBillSlide
            bill={bill}
            form={form}
            type={'RULES'}
            showSearch={billRulesSearchPopover.isOpen}
            handleOpenSearch={billRulesSearchPopover.handleOpen}
            handleCloseSearch={billRulesSearchPopover.handleClose}
            searchPopover={billRulesSearchPopover}
          />
        </Tabs.Slide>
      </Tabs>
      <BillItemsModalFooter
        form={form}
        handleClose={handleClose}
        handleSubmit={() => setTimeout(handleSubmit, 0)}
        submitting={submitting}
      />
      <EditBillEditTitleModal
        key={editBillModal.isOpen}
        isOpen={editBillModal.isOpen}
        handleClose={editBillModal.handleClose}
        billForm={form.values.billForm}
        handleSubmit={({title, description}) => {
          form.setFieldValue('billForm.title', title);
          form.setFieldValue('billForm.description', description);
          editBillModal.handleClose();
        }}
      />
    </ScreenContainer>
  );
};

const EditBillBillingProjectPage = () => {
  const {params, navigator} = useNavigationDOM();
  const {loading, data} = useQuery(EditBillBillingProjectPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      billUuid: params.billUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        if (!data) {
          return null;
        }
        return (
          <EditBillBillingProjectPageContent
            bill={data.bill}
            handleClose={() => navigator.goBack()}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillBillingProjectPage.query = gql`
  ${BillForm.editWithAllOptions.fragment}
  ${EditBillSlide.fragment}

  query EditBillBillingProjectPage($billUuid: String!) {
    bill(uuid: $billUuid) {
      id
      title
      description
      ...BillForm_editWithAllOptions
      ...EditBillSlide
    }
  }
`;

export default EditBillBillingProjectPage;
