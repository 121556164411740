// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Icon, Loading, Modal, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import BeginPaymentForm from '@shared/modules/Payment/forms/BeginPaymentForm';
import useBeginPaymentMutation from '@shared/modules/Payment/hooks/useBeginPaymentMutation';
import useFinalizePaymentMutation from '@shared/modules/Payment/hooks/useFinalizePaymentMutation';
import Field from 'modules/App/components/Field';
import BillingProjectPaymentMethodsDropdown from 'modules/Project/Billing/components/BillingProjectPaymentMethodsDropdown';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: ${(props) => (props.mobile ? 320 : 400)}px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 24px;
`;

const TitleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const InstructionText = Styled.H6`
  color: ${colors.black};
  ${fontWeight(500)}
`;

const FieldsContainer = Styled.View`
`;

const FieldContainer = Styled.View`
  z-index: ${({zIndex = 0}) => zIndex};
`;

const RemainingBalanceText = Styled.H8`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const TextInput = Styled.TextInput.H7`
`;

const ActivityIndicator = Styled.Loading`
`;

const AuthorizeDotNetButtonContainer = Styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-color: ${colors.blue.interactive};
  background-color: ${colors.Blue10};
  border-width: 1px;
  border-radius: 4px;
  padding-horizontal: 12px;
  padding-vertical: 8px;
`;

const AuthorizeDotNetButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive}
`;

const getPaymentAmount = (bill) => {
  const min = bill.project.activeJobsAggregateBill.minBalance;
  const max = bill.project.activeJobsAggregateBill.maxBalance;
  if (min > 0) {
    return min;
  }
  if (max > 0) {
    return max;
  }
  return 0;
};

const PaymentHeader = () => {
  return (
    <React.Fragment>
      <TitleText>Record Payment</TitleText>
      <Space height={16} />
      <InstructionText>
        Select a payment name and enter an amount. These payments should have happened already.
      </InstructionText>
    </React.Fragment>
  );
};

const AuthorizeDotNetLink = ({job, form}) => {
  const amount = Currency.convertToCents(_.get(form.values, 'beginPaymentForm.amount'));
  return (
    <a target={'_blank'} href={Job.getAuthorizeDotNetUrlWithAmount(job, {amount})}>
      <AuthorizeDotNetButtonContainer>
        <AuthorizeDotNetButtonText>Click to pay with authorize.net</AuthorizeDotNetButtonText>
        <Space width={8} />
        <Icon source={Icon.ArrowRight} size={12} color={colors.blue.interactive} />
      </AuthorizeDotNetButtonContainer>
    </a>
  );
};

const RemainingBalance = ({bill}) => {
  const min = bill.project.activeJobsAggregateBill.minBalance;
  const max = bill.project.activeJobsAggregateBill.maxBalance;
  const color = min > 0 || max > 0 ? colors.red.warning : colors.green.status;
  return (
    <RemainingBalanceText color={color}>
      {`Remaining Balance: ${Currency.formatRange({min, max})}`}
    </RemainingBalanceText>
  );
};

const PaymentFields = ({form, bill, job}) => {
  const isPaymentMethodAuthorizeDotNet =
    _.get(form.values, 'beginPaymentForm.method') === 'AUTHORIZE_DOT_NET';
  return (
    <FieldsContainer>
      <FieldContainer zIndex={4}>
        <Field
          {...form}
          name={'beginPaymentForm.name'}
          label={'Payment Name'}
          input={{
            placeholder: 'Enter a payment name',
            required: !form.values.beginPaymentForm.name,
          }}
        />
      </FieldContainer>
      <Space height={12} />
      <FieldContainer zIndex={3}>
        <Field
          {...form}
          name={'beginPaymentForm.amount'}
          label={'Payment Amount'}
          component={CurrencyInput}
          handleBlur={() => {
            const amount = _.get(form.values, 'beginPaymentForm.amount');
            const formattedAmount = Currency.display(Currency.convertToCents(amount), {
              shouldHideCentsIfZero: true,
            });
            form.setFieldValue('beginPaymentForm.amount', formattedAmount);
          }}
          input={{
            component: TextInput,
            placeholder: 'Enter a payment amount',
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
          }}
        />
      </FieldContainer>
      <Space height={4} />
      <RemainingBalance bill={bill} />
      <Space height={12} />
      <FieldContainer zIndex={2}>
        <BillingProjectPaymentMethodsDropdown form={form} bill={bill} />
      </FieldContainer>
      {!!job.authorizeDotNetUrl && isPaymentMethodAuthorizeDotNet && (
        <React.Fragment>
          <Space height={16} />
          <AuthorizeDotNetLink job={job} form={form} />
          <Space height={4} />
        </React.Fragment>
      )}
      <Space height={12} />
      <FieldContainer zIndex={1}>
        <Field
          {...form}
          name={'beginPaymentForm.description'}
          label={'Payment Description'}
          input={{
            placeholder: 'Add description',
            required:
              (form.values.beginPaymentForm.method === 'CHECK' ||
                form.values.beginPaymentForm.method === 'AUTHORIZE_DOT_NET') &&
              !form.values.beginPaymentForm.description,
            style: {height: 80, paddingTop: 10},
            multiline: true,
          }}
        />
      </FieldContainer>
    </FieldsContainer>
  );
};

const PaymentFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <Actions>
      <Button color={colors.white} onPress={handleClose} disabled={submitting}>
        <ButtonText color={colors.gray.secondary}>Cancel</ButtonText>
      </Button>
      <Space width={8} />
      <Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        onPress={handleSubmit}
        disabled={submitting}
      >
        {submitting ? (
          <ActivityIndicator color={colors.white} size={'small'} />
        ) : (
          <ButtonText color={colors.white}>Confirm</ButtonText>
        )}
      </Button>
    </Actions>
  );
};

const BillingProjectRecordPaymentModalContent = ({handleClose, refetch, bill, job}) => {
  const responsive = useResponsive();
  const beginPaymentForm = BeginPaymentForm.new({
    billId: bill.id,
    customerId: bill.customerId,
    amount: getPaymentAmount(bill),
    name: Job.getPaymentName(job),
  });

  const beginPaymentMutation = useBeginPaymentMutation({
    beginPaymentForm,
    onSuccess: ({payment}) => {
      return payment;
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  const finalizePaymentMutation = useFinalizePaymentMutation({
    jobId: job.id,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  const submitting = finalizePaymentMutation.submitting || beginPaymentMutation.submitting;
  const handleSubmit = async () => {
    const {data} = await beginPaymentMutation.handleSubmit();
    if (!data.response.payment) {
      return;
    }
    await finalizePaymentMutation.form.setFieldValue('paymentId', data.response.payment.id);
    finalizePaymentMutation.handleSubmit();
  };

  return (
    <ScreenContainer pointerEvents={'box-none'}>
      <ModalContainer {...responsive}>
        <PaymentHeader />
        <Space height={20} />
        <FieldContainer zIndex={1}>
          <PaymentFields form={beginPaymentMutation.form} bill={bill} job={job} />
        </FieldContainer>
        <Space height={20} />
        <PaymentFooter
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
      </ModalContainer>
    </ScreenContainer>
  );
};

const BillingProjectRecordPaymentModal = ({handleClose, isOpen, refetch, bill, jobUuid}) => {
  const {loading, data} = useQuery(BillingProjectRecordPaymentModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      uuid: bill.uuid,
      jobUuid,
    },
  });

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <Loading loading={loading}>
        {() => (
          <BillingProjectRecordPaymentModalContent
            handleClose={handleClose}
            refetch={refetch}
            bill={data.bill}
            job={data.job}
          />
        )}
      </Loading>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectRecordPaymentModal.query = gql`
  ${BillingProjectPaymentMethodsDropdown.fragment}
  ${Job.getPaymentName.fragment}
  ${Job.getAuthorizeDotNetUrlWithAmount.fragment}

  query BillingProjectRecordPaymentModal ($uuid: String!, $jobUuid: String!) {
    ${gql.query}
    bill(uuid: $uuid) {
      id
      customerId
      project {
        id
        activeJobsAggregateBill {
          minBalance
          maxBalance
        }
      }
      ...BillingProjectPaymentMethodsDropdown
    }
    job(uuid: $jobUuid) {
      id
      authorizeDotNetUrl
      ...Job_getPaymentName
      ...Job_getAuthorizeDotNetUrlWithAmount
    }
  }
`;

BillingProjectRecordPaymentModal.fragment = gql`
  fragment BillingProjectRecordPaymentModal on Bill {
    id
    uuid
  }
`;

export default BillingProjectRecordPaymentModal;
