// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

const _getDisplayTime = (datetime: any, format: any, timezone: any) => {
  if (!datetime) {
    return 'TBD';
  }
  return Datetime.toDisplayTime(Datetime.fromDatetime(datetime, true), format, timezone);
};

const getDisplayRangeFrom = withFragment(
  (timesheetBlock, {format, timezone} = {}) => {
    return _getDisplayTime((timesheetBlock as any).rangeFrom, format, timezone);
  },
  gql`
    fragment TimesheetBlock_getDisplayRangeFrom on TimesheetBlock {
      id
      rangeFrom
    }
  `,
);

const getDisplayRangeTo = withFragment(
  (timesheetBlock, {format, timezone} = {}) => {
    return _getDisplayTime((timesheetBlock as any).rangeTo, format, timezone);
  },
  gql`
    fragment TimesheetBlock_getDisplayRangeTo on TimesheetBlock {
      id
      rangeTo
    }
  `,
);

const getDisplayRange = withFragment(
  (timesheetBlock, {format, timezone} = {}) => {
    const from = getDisplayRangeFrom(timesheetBlock, {format, timezone});
    const to = getDisplayRangeTo(timesheetBlock, {format, timezone});
    return `${from} - ${to}`;
  },
  gql`
    ${getDisplayRangeFrom.fragment}
    ${getDisplayRangeTo.fragment}

    fragment TimesheetBlock_getDisplayRange on TimesheetBlock {
      id
      ...TimesheetBlock_getDisplayRangeFrom
      ...TimesheetBlock_getDisplayRangeTo
    }
  `,
);

const getMinutes = withFragment(
  (timesheetBlock, defaultMinutes) => {
    if ((timesheetBlock as any).rangeTo) {
      const fromMoment = Datetime.fromDatetime((timesheetBlock as any).rangeFrom, true);
      const toMoment = Datetime.fromDatetime((timesheetBlock as any).rangeTo, true);
      return toMoment.diff(fromMoment, 'minutes');
    }
    return defaultMinutes || 0;
  },
  gql`
    fragment TimesheetBlock_getMinutes on TimesheetBlock {
      id
      rangeFrom
      rangeTo
    }
  `,
);

const TimesheetBlock = {
  getDisplayRangeFrom,
  getDisplayRangeTo,
  getDisplayRange,
  getMinutes,
};

export default TimesheetBlock;
