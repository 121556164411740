// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {Job} from '@supermove/models';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';

const SelectBillTemplateDrawer = ({isOpen, handleClose, project, handleAddBill, isSubmitting}) => {
  const [selectedBillType, setSelectedBillType] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [error, setError] = useState(null);

  const billTypes = _.get(project.projectType, 'billingLibrary.billTypes');
  const isStorageProject = project.projectType.category === ProjectTypeCategory.STORAGE;

  return (
    <DrawerWithAction
      headerText={'Select Bill Template'}
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={() => {
        if (selectedBillType) {
          handleAddBill(selectedBillType, selectedJob);
        } else {
          setError('Select a bill template');
        }
      }}
      isSubmitting={isSubmitting}
      primaryActionText={'Add'}
    >
      {!isStorageProject && (
        <React.Fragment>
          <FieldInput
            component={DropdownInput}
            index={0}
            label={'Project or Job'}
            isResponsive
            isRequired
            input={{
              placeholder: `Select a job to add to`,
              value: selectedJob,
              options: [
                {
                  label: `Project-${project.identifier}`,
                  value: null,
                },
                ..._.map(project.activeMoveJobs, (job) => ({
                  label: Job.getFullName(job),
                  value: _.toNumber(job.id),
                })),
              ],
              onChangeValue: (jobId) => {
                setSelectedJob(jobId);
                setError(null);
              },
              setFieldValue: () => {},
              style: {flex: 1},
            }}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      <FieldInput
        name={'billTypeId'}
        component={DropdownInput}
        index={1}
        errors={{billTypeId: error}}
        touched={{billTypeId: !!error}}
        label={'Bill Template'}
        isRequired
        isResponsive
        input={{
          placeholder: `Select a bill template to add`,
          value: selectedBillType,
          options: _.map(billTypes, ({id, name}) => ({
            label: name,
            value: id,
          })),
          onChangeValue: (billTypeId) => {
            setSelectedBillType(billTypeId);
            setError(null);
          },
          setFieldValue: () => {},
          style: {flex: 1},
        }}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SelectBillTemplateDrawer.fragment = gql`
  ${Job.getFullName.fragment}

  fragment SelectBillTemplateDrawer on Project {
    id
    identifier
    activeMoveJobs {
      id
      ...Job_getFullName
    }
    projectType {
      id
      category
      billingLibrary {
        id
        billTypes {
          id
          name
        }
      }
    }
  }
`;

export default SelectBillTemplateDrawer;
