import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {withFragment} from '@supermove/utils';

// App
import PhoneNumberProvider from '@shared/modules/Phone/enums/PhoneNumberProvider';
import PhoneNumberStatus from '@shared/modules/Phone/enums/PhoneNumberStatus';

const getDisplayProvider = withFragment(
  (phoneNumber: any) => {
    switch (phoneNumber.provider) {
      case PhoneNumberProvider.RINGCENTRAL:
        return PhoneNumberProvider.RINGCENTRAL_DISPLAY;
      case PhoneNumberProvider.GRASSHOPPER:
        return PhoneNumberProvider.GRASSHOPPER_DISPLAY;
      case PhoneNumberProvider.VIRTUAL_PBX:
        return PhoneNumberProvider.VIRTUAL_PBX_DISPLAY;
      case PhoneNumberProvider.SPECTRUM:
        return PhoneNumberProvider.SPECTRUM_DISPLAY;
      case PhoneNumberProvider.DIALPAD:
        return PhoneNumberProvider.DIALPAD_DISPLAY;
      case PhoneNumberProvider.VONAGE:
        return PhoneNumberProvider.VONAGE_DISPLAY;
      case PhoneNumberProvider.OPENPHONE:
        return PhoneNumberProvider.OPENPHONE_DISPLAY;
      case PhoneNumberProvider.GO_TO_CONNECT:
        return PhoneNumberProvider.GO_TO_CONNECT_DISPLAY;
      case PhoneNumberProvider.NEXTIVA:
        return PhoneNumberProvider.NEXTIVA_DISPLAY;
      case PhoneNumberProvider.CALLRAIL:
        return PhoneNumberProvider.CALLRAIL_DISPLAY;
      case PhoneNumberProvider.GOOGLE_VOICE:
        return PhoneNumberProvider.GOOGLE_VOICE_DISPLAY;
      case PhoneNumberProvider.CELL_PHONE:
        return PhoneNumberProvider.CELL_PHONE_DISPLAY;
      case PhoneNumberProvider.LANDLINE:
        return PhoneNumberProvider.LANDLINE_DISPLAY;
      case PhoneNumberProvider.OTHER:
        return PhoneNumberProvider.OTHER_DISPLAY;
    }
  },
  gql`
    fragment PhoneNumber_getDisplayProvider on PhoneNumber {
      provider
    }
  `,
);

const getDisplayStatus = (phoneNumber: any) => {
  return _.startCase(_.toLower(phoneNumber.status));
};

const getColorForStatus = (phoneNumber: any) => {
  switch (phoneNumber.status) {
    case PhoneNumberStatus.ACTIVE:
      return colors.green.status;
    case PhoneNumberStatus.INACTIVE:
      return colors.gray.secondary;
    default:
      return colors.gray.secondary;
  }
};

const PhoneNumber = {
  getDisplayProvider,
  getDisplayStatus,
  getColorForStatus,
};

export default PhoneNumber;
