// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import JobTypeStepDocumentTemplateForm, {
  JobTypeStepDocumentTemplateApiType,
  JobTypeStepDocumentTemplateFormType,
} from '@shared/modules/Job/forms/JobTypeStepDocumentTemplateForm';

export interface JobTypeStepFormType {
  jobTypeStepId: string;
  jobTypeId: string;
  organizationId: string;
  updatedById: string;
  createdById: string;
  kind: string;
  jobTypeStepDocumentTemplateForms: JobTypeStepDocumentTemplateFormType[];
  documentTemplateKinds: string[];
  documentTemplateOptions: string[];
  isEnabled: boolean;
  canHaveDocuments: boolean;
}

type JobTypeStepFormMutationType = Omit<
  JobTypeStepFormType,
  'documentTemplateOptions' | 'canHaveDocuments' | 'jobTypeStepDocumentTemplateForms'
> & {jobTypeStepDocumentTemplateForms: JobTypeStepDocumentTemplateFormType[]};

type JobTypeStepFormEditType = Omit<
  JobTypeStepFormType,
  'jobTypeStepId' | 'jobTypeStepDocumentTemplateForms'
> & {
  id: string;
  jobTypeStepDocumentTemplates: JobTypeStepDocumentTemplateApiType[];
};

const edit = withFragment(
  (jobTypeStep: JobTypeStepFormEditType): JobTypeStepFormType => ({
    jobTypeStepId: jobTypeStep.id,
    jobTypeId: jobTypeStep.jobTypeId,
    organizationId: jobTypeStep.organizationId,
    updatedById: jobTypeStep.updatedById,
    createdById: jobTypeStep.createdById,
    kind: jobTypeStep.kind,
    jobTypeStepDocumentTemplateForms: (jobTypeStep.jobTypeStepDocumentTemplates ?? []).map(
      JobTypeStepDocumentTemplateForm.edit,
    ),
    documentTemplateKinds: jobTypeStep.documentTemplateKinds,
    documentTemplateOptions: jobTypeStep.documentTemplateOptions,
    isEnabled: jobTypeStep.isEnabled,
    canHaveDocuments: jobTypeStep.canHaveDocuments,
  }),
  gql`
    fragment JobTypeStepForm_edit on JobTypeStep {
      id
      organizationId
      jobTypeId
      updatedById
      createdById
      kind
      isEnabled
      documentTemplateKinds
      documentTemplateOptions
      canHaveDocuments
    }
  `,
);

const toForm = ({
  jobTypeStepId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
  jobTypeStepDocumentTemplateForms = [],
  documentTemplateKinds,
  documentTemplateOptions,
  canHaveDocuments,
}: JobTypeStepFormType): JobTypeStepFormType => ({
  jobTypeStepId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
  jobTypeStepDocumentTemplateForms: jobTypeStepDocumentTemplateForms
    .map(JobTypeStepDocumentTemplateForm.toForm)
    .sort(JobTypeStepDocumentTemplateForm.sort),
  documentTemplateKinds,
  documentTemplateOptions,
  canHaveDocuments,
});

const toMutation = ({
  jobTypeStepId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
  jobTypeStepDocumentTemplateForms = [],
  documentTemplateKinds,
  canHaveDocuments,
}: JobTypeStepFormType): JobTypeStepFormMutationType => ({
  jobTypeStepId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled: canHaveDocuments && !_.isEmpty(documentTemplateKinds) ? true : isEnabled,
  jobTypeStepDocumentTemplateForms: jobTypeStepDocumentTemplateForms.map(
    JobTypeStepDocumentTemplateForm.toMutation,
  ),
  documentTemplateKinds,
});

const JobTypeStepForm = {
  edit,
  toForm,
  toMutation,
};

export default JobTypeStepForm;
