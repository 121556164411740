// Supermove
import {gql} from '@supermove/graphql';
import {DocumentItemType, DocumentTemplateVersionModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export type DocumentContentJsonFormType = {
  documentItems: DocumentItemType[];
};

export interface DocumentTemplateVersionFormType {
  name: string | null;
  version?: string | null;
  status?: string | null;
  isDeleted?: boolean;
  documentContentJson?: string | null;
  identifier?: string | null;
  documentContentJsonForm?: DocumentContentJsonFormType;
  organizationId?: string | null;
  documentTemplateId: string | null;
  createdById: string | null;
  updatedById: string | null;
}

const _new = ({organizationId}: {organizationId: string}) => ({
  name: null,
  version: null,
  status: null,
  isDeleted: false,
  documentContentJson: null,
  identifier: null,
  documentContentJsonForm: {},
  organizationId,
  documentTemplateId: null,
});

const edit = withFragment(
  (
    documentTemplateVersion: DocumentTemplateVersionModel & {
      documentContentJsonForm: DocumentContentJsonFormType;
    },
  ): DocumentTemplateVersionFormType => ({
    name: documentTemplateVersion.name,
    version: documentTemplateVersion.version,
    status: documentTemplateVersion.status,
    documentContentJson: documentTemplateVersion.documentContentJson,
    identifier: documentTemplateVersion.identifier,
    documentContentJsonForm: documentTemplateVersion.documentContentJsonForm,
    organizationId: documentTemplateVersion.organizationId,
    documentTemplateId: documentTemplateVersion.documentTemplateId,
    isDeleted: false,
    createdById: null,
    updatedById: null,
  }),
  gql`
    fragment DocumentTemplateVersionForm_edit on DocumentTemplateVersion {
      id
      name
      version
      status
      isDeleted
      documentContentJson
      identifier
      organizationId
      documentTemplateId
    }
  `,
);

const toForm = ({
  name,
  version,
  status,
  isDeleted,
  documentContentJson,
  identifier,
  organizationId,
  createdById,
  updatedById,
  documentTemplateId,
}: DocumentTemplateVersionFormType) => ({
  name,
  version,
  status,
  isDeleted,
  documentContentJson,
  identifier,
  organizationId,
  createdById,
  updatedById,
  documentTemplateId,
});

const toMutation = ({
  name,
  version,
  status,
  isDeleted,
  documentContentJson,
  identifier,
  organizationId,
  createdById,
  updatedById,
  documentTemplateId,
}: DocumentTemplateVersionFormType) => ({
  name,
  version,
  status,
  isDeleted,
  documentContentJson,
  identifier,
  organizationId,
  createdById,
  updatedById,
  documentTemplateId,
});

const DocumentTemplateVersionForm = {
  edit,
  toForm,
  toMutation,
  new: _new,
};

export default DocumentTemplateVersionForm;
