// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ReferralSource} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import OrganizationStaffDropdown from 'modules/Organization/components/OrganizationStaffDropdown';

const Container = Styled.View`
  padding-vertical: 30px;
  padding-horizontal: 30px;
  background-color: ${colors.gray.background};
  z-index: ${(props) => 100 - props.index}px;
`;

const ManagerLine = Styled.View`
  margin-bottom: 20px;
  height: 1px;
  background-color: ${colors.gray.tertiary};
`;

const Title = Styled.H4`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  align-items: ${(props) => (props.mobile ? 'stretch' : 'flex-start')};
  z-index: ${({index = 0}) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 15)}px;
`;

const FieldSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 0)}px;
  margin-left: ${(props) => (props.mobile ? 0 : 10)}px;
`;

const getFieldName = ({field, name}) => {
  return field ? `${field}.${name}` : name;
};

const JobInternalFields = ({disabled, index, field, jobField, form, responsive, organization}) => {
  const {referralSources} = organization.settings;

  return (
    <Container index={index}>
      {disabled && <ManagerLine />}
      <Title vars={responsive}>Internal Information</Title>
      <Row index={0} {...responsive}>
        <FieldInput.Memoized
          {...form}
          name={getFieldName({field: jobField, name: 'additionalNotes'})}
          label={'Crew Notes'}
          input={{
            disabled,
            multiline: true,
            placeholder: 'Enter any details for the crew',
            style: {
              paddingVertical: 9,
              width: responsive.mobile ? '100%' : 510,
              minHeight: 100,
            },
          }}
        />
      </Row>
      <RowSpace {...responsive} />
      <Row index={1} {...responsive}>
        <FieldInput.Memoized
          {...form}
          name={getFieldName({field: jobField, name: 'dispatchNotes'})}
          label={'Dispatch Notes'}
          input={{
            disabled,
            multiline: true,
            placeholder: 'Enter any details for dispatch',
            style: {
              paddingVertical: 9,
              width: responsive.mobile ? '100%' : 510,
              minHeight: 100,
            },
          }}
        />
      </Row>
      <RowSpace {...responsive} />
      <Row index={2} {...responsive}>
        <FieldInput.Memoized
          {...form}
          name={getFieldName({field: jobField, name: 'officeNotes'})}
          label={'Office Notes'}
          input={{
            disabled,
            multiline: true,
            placeholder: 'Enter any details for the office',
            style: {
              paddingVertical: 9,
              width: responsive.mobile ? '100%' : 510,
              minHeight: 100,
            },
          }}
        />
      </Row>
      <RowSpace {...responsive} />
      <React.Fragment key={_.get(form.values, `${jobField}.organizationId`)}>
        <Row index={3} {...responsive}>
          <OrganizationStaffDropdown
            isDisabled={disabled}
            organization={organization}
            form={form}
            field={getFieldName({field, name: 'bookedById'})}
            label={'Salesperson'}
            placeholder={'Select salesperson'}
            index={0}
            inputStyle={{width: responsive.mobile ? '100%' : 250}}
            isSalesperson
          />
          <FieldSpace {...responsive} />
          <OrganizationStaffDropdown
            isDisabled={disabled}
            required={
              organization.features.isEnabledProjectCoordinatorRequired &&
              !_.get(form.values, getFieldName({field, name: 'coordinatedById'}))
            }
            organization={organization}
            form={form}
            field={getFieldName({field, name: 'coordinatedById'})}
            label={'Coordinator'}
            placeholder={'Select coordinator'}
            index={1}
            inputStyle={{width: responsive.mobile ? '100%' : 250}}
            isCoordinator
          />
        </Row>
        <RowSpace {...responsive} />
        {organization.features.isEnabledAdditionalSalespersonIdsInput && (
          <React.Fragment>
            <Row index={5} {...responsive}>
              <OrganizationStaffDropdown
                isMultiSelect
                isDisabled={disabled}
                organization={organization}
                form={form}
                field={getFieldName({field, name: 'additionalSalespersonIds'})}
                label={
                  organization.features.isEnabledProjectManagersField
                    ? 'Project Managers'
                    : 'Additional Salespersons'
                }
                placeholder={`Select ${
                  organization.features.isEnabledProjectManagersField
                    ? 'project managers'
                    : 'additional salespersons'
                }`}
                index={0}
                inputStyle={{width: responsive.mobile ? '100%' : 510}}
                isSalesperson
              />
            </Row>
            <RowSpace {...responsive} />
          </React.Fragment>
        )}
        <Row index={6} {...responsive}>
          <FieldInput
            {...form}
            index={0}
            component={DropdownInput}
            name={getFieldName({field: jobField, name: 'referralSource'})}
            label={'How did you hear about us?'}
            input={{
              disabled,
              required:
                organization.features.isEnabledProjectReferralSourceRequired &&
                !_.get(form.values, getFieldName({field: jobField, name: 'referralSource'})),
              options: ReferralSource.getDropdownOptions({referralSources}),
              placeholder: 'Select one',
              isSearchable: true,
              setFieldValue: (name, value) => {
                const fieldName = getFieldName({field: jobField, name: 'referralSource'});
                const previousValue = _.get(form.values, fieldName);

                // First update the referralSource.
                form.setFieldValue(name, value);

                // When the referralSource is updated, we clear the referralDetails.
                if (previousValue !== value) {
                  form.setFieldValue(`${jobField}.referralDetails`, '');
                }
              },
              style: {
                width: responsive.mobile ? '100%' : 250,
              },
            }}
          />
          <FieldSpace {...responsive} />
          <FieldInput.Memoized
            {...form}
            index={1}
            name={getFieldName({field: jobField, name: 'referralDetails'})}
            label={'Referral Details'}
            input={{
              placeholder: 'Please specify',
              style: {
                width: responsive.mobile ? '100%' : 250,
              },
            }}
          />
        </Row>
      </React.Fragment>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobInternalFields.fragment = gql`
  ${OrganizationStaffDropdown.fragment}
  ${ReferralSource.getDropdownOptions.fragment}

  fragment JobInternalFields on Organization {
    id
    features {
      isEnabledAdditionalSalespersonIdsInput: isEnabled(feature: "ADDITIONAL_SALESPERSON_IDS_INPUT")
      isEnabledProjectCoordinatorRequired: isEnabled(feature: "PROJECT_COORDINATOR_REQUIRED")
      isEnabledProjectReferralSourceRequired: isEnabled(feature: "PROJECT_REFERRAL_SOURCE_REQUIRED")
      isEnabledProjectManagersField: isEnabled(feature: "PROJECT_MANAGERS_FIELD")
    }
    settings {
      id
      referralSources {
        ...ReferralSource_getDropdownOptions
      }
    }
    ...OrganizationStaffDropdown
  }
`;

export default JobInternalFields;
