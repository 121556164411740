const JOB_TYPE_FEATURES = {
  label: 'Configuration',
  value: 'JOB_TYPE_FEATURES',
};
const CREW_STEPS = {
  label: 'Crew Steps',
  value: 'CREW_STEPS',
};

const JobTypeConfigs = {
  JOB_TYPE_FEATURES,
  CREW_STEPS,
  getDropdownOptions: [JOB_TYPE_FEATURES, CREW_STEPS],
};

export default JobTypeConfigs;
