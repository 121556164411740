// App
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';

const _new = ({billItemForm, updateValuesForm}: any) => ({
  billItemForm,
  updateValuesForm,
});

const toForm = ({billItemForm, updateValuesForm}: any) => ({
  // The way this form is used, the billItemForm provided is already in a toForm state.
  billItemForm,
  updateValuesForm: UpdateValuesForm.toForm(updateValuesForm),
});

const toMutation = ({billItemForm, updateValuesForm}: any) => ({
  billItemForm: BillItemForm.toMutation(billItemForm),
  updateValuesForm: UpdateValuesForm.toMutation(updateValuesForm),
});

const UpdateBillItemAndValuesForm = {
  new: _new,
  toForm,
  toMutation,
};

export default UpdateBillItemAndValuesForm;
