// Libraries
import React from 'react';

// App
import {ScrollView, Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Organization, User} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Json, Phone} from '@supermove/utils';

// Components
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Table from '@shared/design/components/Table';
import Tabs from '@shared/design/components/Tabs';
import useUpdateSuperAdminImpersonateMutation from '@shared/modules/User/hooks/useUpdateSuperAdminImpersonateMutation';
import StaffCommunicationDrawer from 'modules/Communication/components/StaffCommunicationDrawer';
import JobUserMoverPosition from 'modules/JobUser/components/JobUserMoverPosition';
import PasscodeCell from 'modules/Organization/Settings/Company/components/PasscodeCell';
import CreateTeamDrawer from 'modules/Organization/Settings/Staff/components/CreateTeamDrawer';
import TeamsTable from 'modules/Organization/Settings/Staff/components/TeamsTable';
import CreateEmployeeModal from 'modules/User/components/CreateEmployeeModal';
import DeleteUserModal from 'modules/User/components/DeleteUserModal';
import UpdateEmployeeModal from 'modules/User/components/UpdateEmployeeModal';
import UpdateUserDrawer from 'modules/User/components/UpdateUserDrawer';

const PageContentContainer = Styled.View`
  flex: 1;
`;

const Section = Styled.View`
  margin-bottom: 20px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const PositionsContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const ActiveText = Styled.Text`
  ${Typography.Subheading}
`;

const TABS = {
  CREW: 'crew',
  TEAMS: 'teams',
};

const getTabIndex = ({tab}) => {
  switch (tab) {
    case TABS.CREW:
      return 0;
    case TABS.TEAMS:
      return 1;
    default:
      return 0;
  }
};

const handleTabPress = ({route, navigator}) => {
  navigator.push(`/settings/staff/movers/${route}`);
};

const getPageSubTabs = ({organization}) => {
  return [
    {
      label: 'Crew',
      count: organization.activeMovers.length,
      route: TABS.CREW,
    },
    {
      label: 'Teams',
      count: organization.crewTeams.length,
      route: TABS.TEAMS,
      prependSeparator: true,
    },
  ];
};

const OfficeMoverContent = ({
  tab,
  canImpersonate,
  organization,
  viewer,
  refetch,
  crewTeams,
  isCrewTeam,
}) => {
  switch (tab) {
    case TABS.CREW:
      return (
        <ActiveInactiveMoversSection
          canImpersonate={canImpersonate}
          activeMovers={organization.activeMovers}
          inactiveMovers={organization.inactiveMovers}
          organization={organization}
          viewerRole={viewer.role}
          refetch={refetch}
        />
      );
    case TABS.TEAMS:
      return (
        <TeamsTable teams={crewTeams} refetch={refetch} viewer={viewer} isCrewTeam={isCrewTeam} />
      );
    default:
      return null;
  }
};

const getStaffMoversSectionColumnDefinitions = ({
  canImpersonate,
  organization,
  refetch,
  viewerRole,
  responsive,
}) => [
  {
    flex: 1,
    cellText: (user, rowIndex) => `${rowIndex + 1}`,
    isHidden: !responsive.desktop,
  },
  {
    flex: 2,
    isHidden: !canImpersonate,
    headerLabel: 'Super',
    actions: (user) => {
      return [
        {
          text: 'Impersonate',
          onPress: ({handleSubmit}) => handleSubmit(),
          tooltip: responsive.desktop ? 'Impersonate this user' : null,
          actionHook: {
            hook: useUpdateSuperAdminImpersonateMutation,
            hookArgument: {
              impersonateUserId: user.id,
              onSuccess: () => {
                window.location = '/';
              },
              onError: (errors) => console.log({errors}),
            },
          },
          desktopIcon: Icon.SquareUser,
          desktopIconSize: 32,
        },
      ];
    },
  },
  {
    flex: 4,
    minWidth: 170,
    headerLabel: 'Name',
    cellText: (user) => user.fullName,
    mobileOptions: {
      isInHeader: true,
    },
  },
  {
    flex: 3,
    headerLabel: 'Driver',
    cellText: (user) => (user.hasDriverMoverPosition ? 'Yes' : 'No'),
  },
  {
    flex: 8,
    minWidth: 250,
    headerLabel: 'Positions',
    cellComponent: (user) => {
      return <Positions user={user} />;
    },
  },
  {
    flex: 3,
    isHidden: !organization.shouldCreateCommercialMoves,
    headerLabel: 'Branch Code',
    cellText: (user) => user.branchCode,
  },
  {
    flex: 3,
    minWidth: 150,
    headerLabel: 'Phone Number',
    cellText: (user) => (user.phoneNumber ? Phone.display(user.phoneNumber) : null),
  },
  {
    flex: 3,
    headerLabel: 'Tablet Passcode',
    cellComponent: (user) => {
      return user.tabletPin === '' ? <Text>-</Text> : <PasscodeCell passcode={user.tabletPin} />;
    },
  },
  {
    flex: 3,
    minWidth: 150,
    headerLabel: 'Can View Move Details',
    cellComponent: (user) => {
      if (Json.parse(user.features).VIEW_CUSTOMER_INFO) {
        return <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.Check} />;
      }
    },
  },
  {
    flex: 4,
    minWidth: 50,
    headerLabel: 'Actions',
    actions: (user) => {
      const isEnabledSms = Organization.getNotifications(organization).allowEmployeeText;
      return [
        {
          text: 'Send SMS',
          onPress: ({handleOpen}) => handleOpen(),
          isDisabled: !isEnabledSms,
          desktopIcon: Icon.CommentDots,
          tooltip: !isEnabledSms
            ? 'Sending SMS to movers is disabled. Update this setting on the permissions tab.'
            : null,
          actionHook: {
            hook: useDrawer,
            hookArgument: {name: 'Staff Communication Drawer'},
            renderComponent: ({isOpen, handleClose}) => {
              return (
                <StaffCommunicationAction
                  isOpen={isOpen}
                  handleClose={handleClose}
                  user={user}
                  refetch={refetch}
                  viewerRole={viewerRole}
                />
              );
            },
          },
        },
        {
          text: 'Edit',
          onPress: ({handleOpen}) => handleOpen(),
          actionHook: {
            hook: useModal,
            hookArgument: {name: 'Update Employee Modal'},
            renderComponent: ({isOpen, handleClose}) => {
              return (
                <UpdateEmployeeModal
                  isOpen={isOpen}
                  handleClose={handleClose}
                  userUuid={user.uuid}
                  refetch={refetch}
                />
              );
            },
          },
        },
        {
          text: 'Remove',
          onPress: ({handleOpen}) => handleOpen(),
          actionHook: {
            hook: useModal,
            hookArgument: {name: 'Delete User Modal'},
            renderComponent: ({isOpen, handleClose}) => {
              return (
                <DeleteUserModal
                  isOpen={isOpen}
                  handleClose={handleClose}
                  userId={user.id}
                  refetch={refetch}
                />
              );
            },
          },
        },
      ];
    },
  },
];

const Positions = ({user}) => {
  const userMoverPositions = User.getSortedUserMoverPositions(user);

  return (
    <PositionsContainer>
      {userMoverPositions.map((userMoverPosition, index) => (
        <React.Fragment key={userMoverPosition.id}>
          {index > 0 && <Space width={4} />}
          <JobUserMoverPosition.Badge
            isPrimary={userMoverPosition.isPrimary}
            color={colors.gray.secondary}
            name={userMoverPosition.moverPosition.name}
            isDisabled
            style={{marginVertical: 2}}
          />
        </React.Fragment>
      ))}
    </PositionsContainer>
  );
};

const StaffCommunicationAction = ({isOpen, handleClose, user, refetch, viewerRole}) => {
  const updateUserDrawer = useDrawer({name: 'Update User Drawer'});

  return (
    <React.Fragment>
      <StaffCommunicationDrawer
        user={user}
        isOpen={isOpen}
        handleClose={handleClose}
        handleMissingContactInfo={updateUserDrawer.handleOpen}
      />
      <UpdateUserDrawer
        key={updateUserDrawer.key}
        user={user}
        isOpen={updateUserDrawer.isOpen}
        handleClose={updateUserDrawer.handleClose}
        handleSuccess={() => {
          updateUserDrawer.handleClose();
          refetch();
        }}
        viewerRole={viewerRole}
      />
    </React.Fragment>
  );
};

const MoversSectionTable = ({canImpersonate, viewerRole, refetch, organization, users}) => {
  const responsive = useResponsive();

  return (
    <Table
      columnDefinitions={getStaffMoversSectionColumnDefinitions({
        canImpersonate,
        organization,
        viewerRole,
        refetch,
        responsive,
      })}
      emptyStateText={'No users to display'}
      items={users}
      hasBorder
    />
  );
};

const ActiveInactiveMoversSection = ({
  canImpersonate,
  activeMovers,
  inactiveMovers,
  organization,
  viewerRole,
  refetch,
}) => {
  return (
    <ScrollView>
      <Section>
        <Section>
          <MoversSectionTable
            canImpersonate={canImpersonate}
            viewerRole={viewerRole}
            refetch={refetch}
            users={activeMovers}
            organization={organization}
          />
        </Section>
        <Section>
          <ActiveText>Inactive ({inactiveMovers.length})</ActiveText>
          <Space height={10} />
          <MoversSectionTable
            canImpersonate={canImpersonate}
            viewerRole={viewerRole}
            refetch={refetch}
            users={inactiveMovers}
            organization={organization}
          />
        </Section>
      </Section>
    </ScrollView>
  );
};

const StaffMoversSection = ({organization, viewer, refetch}) => {
  const {navigator, params} = useNavigationDOM();
  const createTeamDrawer = useDrawer({name: 'Create Team Drawer'});
  const activeTabIndex = getTabIndex({tab: params.subTab});

  return (
    <React.Fragment>
      <PageContentContainer>
        <Row>
          <Space style={{flex: 1}} />
          <SecondaryButton text={'Create Team'} onPress={createTeamDrawer.handleOpen} />
          <Space width={12} />
          <CreateEmployeeModal
            organization={organization}
            refetch={refetch}
            trigger={({handleOpen}) => <Button text={'Create Crew Member'} onPress={handleOpen} />}
          />
          <Space width={12} />
          <Button
            text={'View Crew Schedules'}
            onPress={() => navigator.push('/settings/staff/schedules')}
          />
        </Row>
        <Space height={16} />
        <Tabs
          tabs={getPageSubTabs({organization})}
          handlePressTab={({route}) => handleTabPress({route, navigator})}
          activeTabIndex={activeTabIndex}
        />
        <OfficeMoverContent
          tab={params.subTab}
          canImpersonate={viewer.role === 'super'}
          organization={organization}
          viewer={viewer}
          refetch={refetch}
          crewTeams={organization.crewTeams}
          isCrewTeam
        />
      </PageContentContainer>
      <CreateTeamDrawer
        key={createTeamDrawer.key}
        isOpen={createTeamDrawer.isOpen}
        organization={organization}
        isCrewTeam
        handleClose={() => {
          createTeamDrawer.handleClose();
          if (params.subTab === TABS.TEAMS) {
            return refetch();
          }
          navigator.push(`/settings/staff/movers/${TABS.TEAMS}`);
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StaffMoversSection.fragment = gql`
  ${CreateEmployeeModal.fragment}
  ${TeamsTable.fragment}
  ${CreateTeamDrawer.fragment}
  ${Organization.getNotifications.fragment}
  ${User.getSortedUserMoverPositions.fragment}
  ${StaffCommunicationDrawer.fragment}
  ${UpdateUserDrawer.fragment}

  fragment StaffMoversSection on Organization {
    id
    shouldCreateCommercialMoves
    features {
      isEnabledNewMoverApp: isEnabled(feature: "NEW_MOVER_APP")
    }
    crewTeams {
      id
      members {
        id
        role
      }
      ...TeamsTable
    }
    activeMovers: filteredActiveUsersByRole(roles: ["employee"]) {
      id
      uuid
      fullName
      hasDriverMoverPosition
      lastName
      phoneNumber
      email
      features
      role
      tabletPin
      branchCode
      userMoverPositions {
        id
        isPrimary
        moverPosition {
          id
          name
        }
      }
      ...StaffCommunicationDrawer
      ...UpdateUserDrawer
      ...User_getSortedUserMoverPositions
    }
    inactiveMovers: filteredInactiveUsersByRole(roles: ["employee"]) {
      id
      uuid
      fullName
      hasDriverMoverPosition
      lastName
      phoneNumber
      email
      features
      role
      tabletPin
      branchCode
      userMoverPositions {
        id
        isPrimary
        moverPosition {
          id
          name
        }
      }
      ...UpdateUserDrawer
      ...StaffCommunicationDrawer
    }
    ...CreateEmployeeModal
    ...CreateTeamDrawer
    ...Organization_getNotifications
  }

  fragment StaffMoversSection_Viewer on User {
    id
    ...TeamsTable_Viewer
  }
`;

export default StaffMoversSection;
