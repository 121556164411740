// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Lifecycle, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useRef, useResponsive} from '@supermove/hooks';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import ConfirmationStepHeader from 'modules/Customer/Project/Confirmation/components/ConfirmationStepHeader';
import InventoryQuoteForm from 'modules/Customer/Project/Quote/Inventory/components/InventoryQuoteForm';
import ProjectPage from 'modules/Customer/Project/components/ProjectPage';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  flex: 1;
  width: ${(props) => (props.mobile ? '100%' : '796px')};
`;

const Content = Styled.View`
  align-self: stretch;
  padding-top: 60px;
  padding-bottom: 90px;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const SectionSpace = Styled.View`
  margin-top: 30px;
`;

const getSubtitleText = () => {
  return 'Please read through the inventory items below.\nAfterwards, press "Continue".';
};

const isCorrectPage = ({project}) => {
  return _.get(project, 'quote.nextStep.kind') === ConfirmationStepKind.QUOTE_STEP_ACCEPT_INVENTORY;
};

const onMountOrUpdate = ({navigator, project}) => {
  if (!isCorrectPage({project})) {
    navigator.push(`/0/${project.organization.slug}/projects/${project.uuid}/quote`);
  }
};

// If the next step of the quote is not to sign document,
// redirect the customer to the show quote page.
const Redirect = ({children, navigator, project}) => (
  <Lifecycle onMount={() => onMountOrUpdate({navigator, project})}>
    {isCorrectPage({project}) ? children : null}
  </Lifecycle>
);

const InventoryQuoteProjectPageContent = ({project, refetch}) => {
  const responsive = useResponsive();
  const scrollView = useRef();

  return (
    <Wrapper>
      <ScrollView
        ref={scrollView}
        contentContainerStyle={{
          flexGrow: 1,
          alignItems: 'center',
        }}
      >
        <Container {...responsive}>
          <Content>
            <Section sectionIndex={0}>
              <ConfirmationStepHeader
                title={'Inventory Review'}
                subtitle={getSubtitleText()}
                confirmation={project.quote}
              />
            </Section>
            <SectionSpace />
            <Section sectionIndex={1}>
              <InventoryQuoteForm confirmation={project.quote} />
            </Section>
          </Content>
        </Container>
      </ScrollView>
    </Wrapper>
  );
};

const InventoryQuoteProjectPage = ({
  match: {
    params: {slug, uuid},
  },
}) => {
  return (
    <ProjectPage variables={{uuid}} query={InventoryQuoteProjectPage.query}>
      {({navigator, data, refetch}) => (
        <Redirect navigator={navigator} project={data.project}>
          <InventoryQuoteProjectPageContent project={data.project} refetch={refetch} />
        </Redirect>
      )}
    </ProjectPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryQuoteProjectPage.query = gql`
  ${InventoryQuoteForm.fragment}
  ${ConfirmationStepHeader.fragment}
  ${ProjectPage.fragment}

  query InventoryQuoteProjectPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      quote {
        id
        nextStep {
          kind
          value
        }
        ...InventoryQuoteForm
        ...ConfirmationStepHeader
      }
      organization {
        id
        slug
        name
      }
      ...ProjectPage
    }
  }
`;

export default InventoryQuoteProjectPage;
