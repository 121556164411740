// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({email}: any) => ({
  email,
});

const edit = withFragment(
  (emailRecipient) => ({
    email: (emailRecipient as any).email,
  }),
  gql`
    fragment EmailRecipientForm_edit on EmailRecipient {
      email
    }
  `,
);

const toForm = ({email}: any) => ({
  email,
});

const toMutation = ({email}: any) => ({
  email,
});

const EmailRecipientForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default EmailRecipientForm;
