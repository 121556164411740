// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (slot) => ({
    slotId: (slot as any).id,
    driverId: (slot as any).driverId,
  }),
  gql`
    fragment DriverToSlotForm_edit on Slot {
      id
      driverId
    }
  `,
);

const _new = ({driverId, slotId}: any) => ({
  driverId,
  slotId,
});

const toForm = ({driverId, slotId}: any) => {
  return {
    driverId,
    slotId,
  };
};

const toMutation = ({driverId, slotId}: any) => {
  return {
    driverId,
    slotId,
  };
};

const AssignDriverToSlotForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default AssignDriverToSlotForm;
