// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors, typography} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: ${({vars}) => (vars.theme === 'light' ? colors.white : colors.gray.primary)};
  z-index: 100;
`;

const Center = Styled.View`
  flex: 1;
  align-items: center;
  margin-horizontal: 10px;
`;

const Title = Styled.H5`
  ${({vars}) => (vars.mobile ? typography.h6() : '')}
  color: ${({vars}) => (vars.theme === 'light' ? colors.gray.primary : colors.white)};
`;

const Subtitle = Styled.H7`
  ${({vars}) => (vars.mobile ? typography.h8() : '')}
  ${({vars}) => (vars.mobile ? 'font-size: 13px' : '')}
  margin-top: -2px;
  color: ${({vars}) => (vars.theme === 'light' ? colors.gray.primary : colors.white)};
`;

const Side = Styled.View`
  width: ${(props) => props.width}px;
`;

const Navigation = ({theme, title, subtitle, side, left, right}) => {
  const responsive = useResponsive();

  return (
    <Container vars={{theme}}>
      <Side width={side({responsive})}>{left()}</Side>
      <Center>
        <Title vars={{...responsive, theme}} numberOfLines={1}>
          {title}
        </Title>
        <Subtitle vars={{...responsive, theme}} numberOfLines={1}>
          {subtitle}
        </Subtitle>
      </Center>
      <Side width={side({responsive})}>{right()}</Side>
    </Container>
  );
};

Navigation.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  side: PropTypes.func,
  left: PropTypes.func,
  right: PropTypes.func,
};

Navigation.defaultProps = {
  theme: 'light',
  side: () => 0,
  left: () => null,
  right: () => null,
};

const Touchable = Styled.Touchable`
`;

const CloseIcon = Styled.Image`
  width: ${({responsive}) => (responsive.mobile ? 20 : 30)}px;
  height: ${({responsive}) => (responsive.mobile ? 20 : 30)}px;
  margin: ${({responsive}) => (responsive.mobile ? 10 : 15)}px;
`;

const CloseButton = ({theme, onPress}) => {
  const responsive = useResponsive();

  return (
    <Touchable onPress={onPress}>
      <CloseIcon
        source={
          theme === 'light'
            ? require('./assets/close-dark.png')
            : require('./assets/close-light.png')
        }
        responsive={responsive}
      />
    </Touchable>
  );
};

Navigation.CloseButton = CloseButton;

Navigation.CloseButton.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
};

Navigation.CloseButton.defaultProps = {
  theme: 'light',
};

Navigation.Button = Styled.Button`
  height: 40px;
  width: ${(props) => props.width}px;
  ${(props) => props.style};
`;

Navigation.ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

export default Navigation;
