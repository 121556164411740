// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ViewCell = ({label, placeholder = 'Select default item', value}) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const ExternalInvoiceItemsDropdown = ({form, field, label, options, isDisabled = false}) => {
  return (
    <FieldInput
      {...form}
      label={label}
      name={`codatIntegrationForm.${field}`}
      isRequired
      component={DropdownInput}
      input={{
        disabled: isDisabled,
        isPortaled: true,
        options,
        isClearable: true,
        placeholder: 'Select default item',
        setFieldValue: form.setFieldValue,
        style: {
          flex: 1,
        },
      }}
      style={{
        flex: 1,
      }}
    />
  );
};

const CodatExportSettingsPanelEdit = ({form, codatIntegration}) => {
  const shouldExportInvoiceSectionTitlesField =
    'codatIntegrationForm.shouldExportInvoiceSectionTitles';
  const shouldExportInvoiceSectionTitles = _.get(
    form.values,
    shouldExportInvoiceSectionTitlesField,
  );
  const externalInvoiceOptions = codatIntegration.externalInvoiceItems.map(
    (externalInvoiceOption) => ({
      value: _.toString(externalInvoiceOption.id),
      label: externalInvoiceOption.name,
    }),
  );
  const accountOptions = codatIntegration.accountOptions.map((accountOption) => ({
    value: JSON.stringify(accountOption),
    label: accountOption.name,
  }));

  return (
    <React.Fragment>
      <Row>
        <Column>
          <ExternalInvoiceItemsDropdown
            form={form}
            label={'Library Bill Items'}
            field={'defaultExternalInvoiceItemId'}
            options={externalInvoiceOptions}
          />
        </Column>
        <Space width={24} />
        <Column>
          <ExternalInvoiceItemsDropdown
            form={form}
            label={'Custom Bill Items'}
            field={'customExternalInvoiceItemId'}
            options={externalInvoiceOptions}
          />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <ExternalInvoiceItemsDropdown
            form={form}
            label={'Tips'}
            field={'tipExternalInvoiceItemId'}
            options={externalInvoiceOptions}
          />
        </Column>
        <Space width={24} />
        <Column>
          <ExternalInvoiceItemsDropdown
            form={form}
            label={'Sales Tax'}
            field={'salesTaxExternalInvoiceItemId'}
            options={externalInvoiceOptions}
          />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <EditPanel.LabelText>{'Should Export Invoice Section Titles?'}</EditPanel.LabelText>
          <Space height={8} />
          <SwitchField
            form={form}
            field={shouldExportInvoiceSectionTitlesField}
            labelRight={shouldExportInvoiceSectionTitles ? 'Yes' : 'No'}
            onChangeValue={(switchValue) => {
              if (!switchValue) {
                form.setFieldValue(
                  'codatIntegrationForm.invoiceSectionExternalInvoiceItemId',
                  null,
                );
              }
            }}
          />
        </Column>
        <Space width={24} />
        <Column>
          <ExternalInvoiceItemsDropdown
            form={form}
            label={'Invoice Section Titles'}
            field={'invoiceSectionExternalInvoiceItemId'}
            options={externalInvoiceOptions}
            isDisabled={!shouldExportInvoiceSectionTitles}
          />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <ExternalInvoiceItemsDropdown
            label={'Payment Method Fees'}
            field={`paymentMethodFeeExternalInvoiceItemId`}
            options={externalInvoiceOptions}
            form={form}
          />
        </Column>
        <Space width={24} />
        <Column>
          <FieldInput
            {...form}
            label={'Associated Account for Payment'}
            name={`codatIntegrationForm.codatAccountReferenceJson`}
            isRequired
            component={DropdownInput}
            input={{
              isPortaled: true,
              options: accountOptions,
              isClearable: true,
              placeholder: 'Select account',
              noOptionsMessage: () => 'No accounts found. Please refresh the page.',
              setFieldValue: form.setFieldValue,
              style: {
                flex: 1,
              },
            }}
            style={{
              flex: 1,
            }}
          />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <ExternalInvoiceItemsDropdown
            label={'Valuation Coverage'}
            field={`valuationCoverageExternalInvoiceItemId`}
            options={externalInvoiceOptions}
            form={form}
          />
        </Column>
        <Space width={24} />
        <Column />
      </Row>
    </React.Fragment>
  );
};

const CodatExportSettingsPanel = ({codatIntegration}) => {
  const {
    defaultExternalInvoiceItem,
    customExternalInvoiceItem,
    shouldExportInvoiceSectionTitles,
    invoiceSectionExternalInvoiceItem,
    tipExternalInvoiceItem,
    salesTaxExternalInvoiceItem,
    paymentMethodFeeExternalInvoiceItem,
    valuationCoverageExternalInvoiceItem,
    codatAccountReferenceJson,
  } = codatIntegration;
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell label={'Library Bill Items'} value={defaultExternalInvoiceItem?.name} />
        </Column>
        <Space width={24} />
        <Column>
          <ViewCell label={'Custom Bill Items'} value={customExternalInvoiceItem?.name} />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <ViewCell label={'Tips'} value={tipExternalInvoiceItem?.name} />
        </Column>
        <Space width={24} />
        <Column>
          <ViewCell label={'Sales Tax'} value={salesTaxExternalInvoiceItem?.name} />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <ViewCell
            label={'Export Invoice Section Titles?'}
            value={shouldExportInvoiceSectionTitles ? 'Yes' : 'No'}
          />
        </Column>
        <Space width={24} />
        <Column>
          <ViewCell
            label={'Invoice Section Titles'}
            value={invoiceSectionExternalInvoiceItem?.name}
          />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <ViewCell
            label={'Payment Method Fees'}
            value={paymentMethodFeeExternalInvoiceItem?.name}
          />
        </Column>
        <Space width={24} />
        <Column>
          <ViewCell
            label={'Associated Account for Payment'}
            value={codatAccountReferenceJson ? JSON.parse(codatAccountReferenceJson).name : null}
            placeholder={'Select account'}
          />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <ViewCell
            label={'Valuation Coverage'}
            value={valuationCoverageExternalInvoiceItem?.name}
          />
        </Column>
        <Space width={24} />
        <Column />
      </Row>
    </React.Fragment>
  );
};

CodatExportSettingsPanel.Loading = () => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <EditPanel.LabelText>{'Library Bill Items'}</EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={128} />
        </Column>
        <Space width={24} />
        <Column>
          <EditPanel.LabelText>{'Custom Bill Items'}</EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={128} />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <EditPanel.LabelText>{'Tips'}</EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={128} />
        </Column>
        <Space width={24} />
        <Column>
          <EditPanel.LabelText>{'Sales Tax'}</EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={128} />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <EditPanel.LabelText>{'Export Invoice Section Titles?'}</EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={128} />
        </Column>
        <Space width={24} />
        <Column>
          <EditPanel.LabelText>{'Invoice Section Titles'}</EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={128} />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <EditPanel.LabelText>{'Payment Method Fees'}</EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={128} />
        </Column>
        <Space width={24} />
        <Column>
          <EditPanel.LabelText>{'Associated Account for Payment'}</EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={128} />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <EditPanel.LabelText>{'Valuation Coverage'}</EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={128} />
        </Column>
        <Space width={24} />
        <Column />
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CodatExportSettingsPanel.fragment = gql`
  fragment CodatExportSettingsPanel on CodatIntegration {
    id
    externalInvoiceItems {
      id
      name
    }
    defaultExternalInvoiceItem {
      id
      name
    }
    customExternalInvoiceItem {
      id
      name
    }
    shouldExportInvoiceSectionTitles
    invoiceSectionExternalInvoiceItem {
      id
      name
    }
    salesTaxExternalInvoiceItem {
      id
      name
    }
    valuationCoverageExternalInvoiceItem {
      id
      name
    }
    tipExternalInvoiceItem {
      id
      name
    }
    paymentMethodFeeExternalInvoiceItem {
      id
      name
    }
    accountOptions {
      id
      name
    }
    codatAccountReferenceJson
  }
`;

CodatExportSettingsPanel.Edit = CodatExportSettingsPanelEdit;

export default CodatExportSettingsPanel;
