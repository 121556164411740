// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import CurrencyCode from '@shared/modules/Integration/enums/CurrencyCode';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ViewCell = ({label, placeholder = 'Select default item', value}) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const AccountingIntegrationCurrencyPanelEdit = ({form}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldInput
            {...form}
            label={'Currency'}
            name={`codatIntegrationForm.currencyCode`}
            isRequired
            component={DropdownInput}
            input={{
              isPortaled: true,
              options: CurrencyCode.DROPDOWN_OPTIONS,
              placeholder: 'Choose Currency',
              setFieldValue: form.setFieldValue,
              style: {
                flex: 1,
              },
            }}
            style={{
              flex: 1,
            }}
          />
        </Column>
      </Row>
      <Space height={16} />
    </React.Fragment>
  );
};

const AccountingIntegrationCurrencyPanel = ({codatIntegration}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell label={'Currency'} value={codatIntegration.currencyCode} />
        </Column>
      </Row>
    </React.Fragment>
  );
};

AccountingIntegrationCurrencyPanel.Loading = () => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <SkeletonLoader height={24} width={250} />
        </Column>
      </Row>
      <Space height={16} />
      <Row>
        <Column>
          <SkeletonLoader height={24} width={250} />
        </Column>
      </Row>
    </React.Fragment>
  );
};

AccountingIntegrationCurrencyPanel.fragment = gql`
  fragment AccountingIntegrationCurrencyPanel on CodatIntegration {
    id
    currencyCode
  }
`;

AccountingIntegrationCurrencyPanel.Edit = AccountingIntegrationCurrencyPanelEdit;

export default AccountingIntegrationCurrencyPanel;
