// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, Popover} from '@supermove/components';
import {useDebouncedCallback, usePopover} from '@supermove/hooks';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import FilteredCodatPushOperationsForm from '@shared/modules/Integration/forms/FilteredCodatPushOperationsForm';
import OrganizationSettingsCompanyIntegrationsAccountingHistoryPageFiltersPopover from 'modules/Organization/Settings/Company/components/OrganizationSettingsCompanyIntegrationsAccountingHistoryPageFiltersPopover';

const HeaderContainer = Styled.View`
`;

const LeftContainer = Styled.View`
  flex: 3;
  flex-direction: row;
  align-items: center;
`;

const FiltersContainers = Styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const FilterButton = ({form}) => {
  const organizationSettingsCompanyIntegrationsAcountingHistoryPagePopover = usePopover({
    name: 'Organization Settings Company Integrations Accounting History Page Filters Popover',
  });
  // Determine number of filters applied
  const numberOfFiltersApplied = FilteredCodatPushOperationsForm.getNumberOfFiltersApplied({
    form,
  });

  return (
    <React.Fragment>
      <Popover.Content
        innerRef={organizationSettingsCompanyIntegrationsAcountingHistoryPagePopover.ref}
      >
        <SecondaryButton
          text={numberOfFiltersApplied === 0 ? 'Filter' : `Filter (${numberOfFiltersApplied})`}
          iconLeft={Icon.Filter}
          onPress={organizationSettingsCompanyIntegrationsAcountingHistoryPagePopover.handleToggle}
        />
      </Popover.Content>
      <OrganizationSettingsCompanyIntegrationsAccountingHistoryPageFiltersPopover
        form={form}
        popover={organizationSettingsCompanyIntegrationsAcountingHistoryPagePopover}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyIntegrationsAccountingHistoryPageFilters = ({form}) => {
  const handleChangeSearch = useDebouncedCallback(
    (input) => {
      form.setFieldValue('filteredCodatPushOperationsForm.searchQuery', input);
    },
    250,
    {leading: true},
  );

  return (
    <HeaderContainer>
      <FiltersContainers>
        <LeftContainer>
          <SearchBar
            onChangeText={handleChangeSearch}
            placeholder='Search by customer name, invoice identifier, or project identifier'
            style={{width: '500px'}}
            defaultValue={_.get(form.values, 'filteredCodatPushOperationsForm.searchQuery')}
          />
          <Space width={12} />
          <FilterButton form={form} />
        </LeftContainer>
      </FiltersContainers>
    </HeaderContainer>
  );
};

export default OrganizationSettingsCompanyIntegrationsAccountingHistoryPageFilters;
