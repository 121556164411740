// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components

const Container = Styled.View`
  justify-content: flex-start;
  width: ${(props) => props.width}px;
  height: 75px;
  padding: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  background-color: ${(props) => props.color};
  opacity: ${(props) => props.opacity || 1.0};
  overflow: hidden;
  cursor: ${(props) => props.cursor};
`;

const Job = Styled.View`
  width: 100%;
  padding-horizontal: 3px;
  padding-vertical: ${(props) => (props.isTarget ? '10px' : '5px')};
  margin-bottom: 3px;
  background-color: ${(props) => (props.isTarget ? colors.gray.primary : colors.gray.tertiary)};
  border-radius: 3px;
`;

const JobText = Styled.H8`
  font-weight: ${({vars = {}}) => (vars.isTarget ? 700 : 500)};
  color: ${colors.white};
`;

const IndicatorWrapper = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Indicator = Styled.Loading`
`;

const sortJobs = ({jobs}) => {
  return _.sortBy(jobs, ['startTime1', 'startTime2']);
};

const ScheduleCell = ({loading, boldJobId, width, jobs, styleColor, styleCursor, styleOpacity}) => (
  <Container color={styleColor} cursor={styleCursor} opacity={styleOpacity} width={width}>
    {loading ? (
      <IndicatorWrapper>
        <Indicator size={'small'} color={colors.gray.border} />
      </IndicatorWrapper>
    ) : (
      <React.Fragment>
        {sortJobs({jobs}).map((job) => (
          <Job key={job.id} isTarget={job.id === boldJobId}>
            <JobText numberOfLines={1} vars={{isTarget: job.id === boldJobId}}>
              {`${job.identifier} - ` +
                `${job.startTime1 ? Datetime.convertToDisplayTime(job.startTime1) : 'Time TBD'}`}
            </JobText>
          </Job>
        ))}
      </React.Fragment>
    )}
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ScheduleCell.propTypes = {
  loading: PropTypes.bool,
  boldJobId: PropTypes.string,
  width: PropTypes.number,
  jobs: PropTypes.array.isRequired,
  styleColor: PropTypes.string.isRequired,
  styleCursor: PropTypes.string.isRequired,
  styleOpacity: PropTypes.number.isRequired,
};

ScheduleCell.defaultProps = {
  boldJobId: null,
  width: 130,
  loading: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ScheduleCell.fragment = gql`
  fragment ScheduleCell on Job {
    id
    identifier
    startTime1
  }
`;

export default ScheduleCell;
