// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import JobTypeFeatureForm from '@shared/modules/Job/forms/JobTypeFeatureForm';

const useUpsertJobTypeFeatureMutation = ({jobTypeFeatureForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      jobTypeFeatureForm: JobTypeFeatureForm.toForm(jobTypeFeatureForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertJobTypeFeatureMutation.mutation,
    variables: {
      jobTypeFeatureForm: JobTypeFeatureForm.toMutation(form.values.jobTypeFeatureForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpsertJobTypeFeatureMutation.mutation = gql`
  mutation useUpsertJobTypeFeatureMutation($jobTypeFeatureForm: JobTypeFeatureForm!){
    response: upsertJobTypeFeature(jobTypeFeatureForm: $jobTypeFeatureForm) {
      ${gql.errors}
      jobTypeFeature{
        id
        isEnabled
      }
    }
  }
`;

export default useUpsertJobTypeFeatureMutation;
