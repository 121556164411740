import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';

const FEES = 'FEES';
const SUPPLIES = 'SUPPLIES';
const DISCOUNTS = 'DISCOUNTS';

const CATEGORY_DISPLAY_MAP = {
  [FEES]: 'Fee',
  [SUPPLIES]: 'Supply',
  [DISCOUNTS]: 'Discount',
};

const getDisplayCategory = (billItemTypeCategory: any, {unit, kind}: any = {}) => {
  if (!billItemTypeCategory) {
    return '[category not found]';
  }
  if (unit === BillItemUnit.HOUR) {
    return 'Hourly Fee';
  }
  if (kind === BillItemTypeKind.PERCENTAGE) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return `Percent ${CATEGORY_DISPLAY_MAP[billItemTypeCategory]}`;
  }
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return CATEGORY_DISPLAY_MAP[billItemTypeCategory];
};

export default {
  FEES: 'FEES',
  SUPPLIES: 'SUPPLIES',
  DISCOUNTS: 'DISCOUNTS',

  getDisplayCategory,
};
