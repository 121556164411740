// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ImportOrganizationProjectsSmartMovingForm from '@shared/modules/File/forms/ImportOrganizationProjectsSmartMovingForm';

// App

const useImportOrganizationProjectsSmartMovingMutation = ({
  importOrganizationProjectsSmartMovingForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      importOrganizationProjectsSmartMovingForm: ImportOrganizationProjectsSmartMovingForm.toForm(
        importOrganizationProjectsSmartMovingForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useImportOrganizationProjectsSmartMovingMutation.mutation,
    variables: {
      importOrganizationProjectsSmartMovingForm:
        ImportOrganizationProjectsSmartMovingForm.toMutation(
          form.values.importOrganizationProjectsSmartMovingForm,
        ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useImportOrganizationProjectsSmartMovingMutation.mutation = gql`
  mutation useImportOrganizationProjectsSmartmovingMutation($importOrganizationProjectsSmartMovingForm: ImportOrganizationProjectsSmartMovingForm!) {
    response: importOrganizationProjectsSmartmoving(importOrganizationProjectsSmartMovingForm: $importOrganizationProjectsSmartMovingForm) {
      ${gql.errors}
  }
  }
`;

export default useImportOrganizationProjectsSmartMovingMutation;
