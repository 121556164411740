/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {RadioInput, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const removeItemFromList = ({selectedValues, value}) => {
  return _.without(selectedValues, value);
};

const addItemToList = ({selectedValues, value}) => {
  return _.uniq([...selectedValues, value]);
};

const getNewList = ({isSelected, selectedValues, value}) => {
  if (isSelected) {
    return removeItemFromList({selectedValues, value});
  } else {
    return addItemToList({selectedValues, value});
  }
};

const DocumentAdditionalInfoSelectMultipleInput = ({
  isFirst,
  isLast,
  name,
  index,
  value,
  values,
  setFieldValue,
  setFieldTouched,
}) => {
  const selectedValues = _.get(values, name) || [];
  const isSelected = selectedValues.includes(value);

  return (
    <Container>
      {isFirst && <Line />}
      <Content>
        <RadioInput
          isSelected={isSelected}
          color={colors.blue.interactive}
          label={value}
          value={value}
          onSelect={(value) => {
            const newList = getNewList({isSelected, selectedValues, value});
            setFieldValue(name, newList);
          }}
        />
      </Content>
      <Line />
    </Container>
  );
};

export default DocumentAdditionalInfoSelectMultipleInput;
