// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, usePopover, useResponsive, useToast} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Float} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import TextTooltip from '@shared/design/components/TextTooltip';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import CompleteEstimatedRangeJobModal from 'modules/Job/components/CompleteEstimatedRangeJobModal';
import JobCancelModal from 'modules/Job/components/JobCancelModal';
import JobCompleteManuallyModal from 'modules/Job/components/JobCompleteManuallyModal';
import ResetJobModal from 'modules/Job/components/ResetJobModal';
import RestoreJobModal from 'modules/Job/components/RestoreJobModal';
import FinalizeJobModal from 'modules/Project/V2/Show/Blocks/Job/components/FinalizeJobModal';
import UnfinalizeJobModal from 'modules/Project/V2/Show/Blocks/Job/components/UnfinalizeJobModal';

const ProjectJobsBarContainer = Styled.View`
  height: 60px;
  justify-content: center;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TooltipContentContainer = Styled.View`
`;

const getEstimatedRangeJobBadgeLabel = ({job, parentJob, displayJobs, index}) => {
  const jobsWithChildJobs = displayJobs.filter((job) => _.some(job.jobsForEstimatedRange));
  const displayIndex =
    _.findIndex(jobsWithChildJobs, ({id}) => _.toString(id) === _.toString(parentJob.id)) + 1;

  const isParent = job.id === parentJob.id;
  const badgeLetter = isParent ? '' : Float.toLowercaseLetter(index - 1);
  return `${displayIndex}${badgeLetter}${job.isCancelled ? ' - Cancelled' : ''}`;
};

const getJobsActions = ({project, urlFilters}) => {
  const allJobs = project.allJobsExcludingChildJobs.reduce(
    (result, job) => [
      ...result,
      ...(_.some(job.jobsForEstimatedRange) ? job.jobsForEstimatedRange : [job]),
    ],
    [],
  );
  const cancelledJobsCount = allJobs.filter((job) => job.isCancelled).length;
  const showCancelledJobs = urlFilters.get('showCancelledJobs');

  return [
    {
      text: `${showCancelledJobs ? 'Hide' : 'Show'} cancelled jobs (${cancelledJobsCount})`,
      onPress: () =>
        urlFilters.handleUpdate({showCancelledJobs: showCancelledJobs ? null : 'true'}),
    },
  ];
};

const getDisplayRangeJobs = ({jobs, params}) => {
  return jobs.filter((job) => params.showCancelledJobs || !job.isCancelled);
};

const getJobContext = ({
  project,
  job,
  displayJobs,
  badge,
  activeJobUuid,
  handlePressJob,
  urlFilters,
  refetch,
  navigator,
  params,
  moverLinkToast,
  trackingLinkToast,
  responsive,
  viewer,
}) => {
  const showCancelledJobs = urlFilters.get('showCancelledJobs');

  return {
    label: job.shortName,
    isSelected: job.uuid === activeJobUuid,
    onPress: () => handlePressJob({jobUuid: job.uuid}),
    ...(badge
      ? {
          leftBadgeLabel: badge,
          leftBadgeColor: colors.gray.secondary,
        }
      : {}),
    actions: Job.getActions(job, {
      responsive,
      navigator,
      userRole: viewer.role,
      projectUuid: params.projectUuid,
      isProjectAtMaxJobs: Project.getIsAtMaxJobs(project),
      moverLinkToast,
      trackingLinkToast,

      // Desktop hook handlers
      markAsCompletedActionHook: {
        hook: useModal,
        hookArgument: {name: 'Job Complete Manually Modal', enableTracking: true},
        Component: Job.getIsEstimatedRange(job)
          ? CompleteEstimatedRangeJobModal
          : JobCompleteManuallyModal,
        componentProps: {
          // Props for JobCompleteManuallyModal
          job,
          onSuccess: refetch,

          // Props for CompleteEstimatedRangeJobModal
          jobUuid: job.parentJob ? job.parentJob.uuid : job.uuid,
          refetchParent: refetch,
        },
      },
      cancelJobActionHook: {
        hook: useModal,
        hookArgument: {name: 'Cancel Job Modal', enableTracking: true},
        Component: JobCancelModal,
        componentProps: {
          key: job.isCancelled,
          jobId: job.id,
          refetch,
          onSuccess: () => {
            if (!showCancelledJobs && job.uuid === activeJobUuid) {
              const availableJob = _.find(
                displayJobs,
                (displayJob) => displayJob.uuid !== activeJobUuid,
              );
              urlFilters.handleUpdate({
                jobUuid: availableJob?.uuid,
                block: ProjectBlockKind.JobBlocks[0],
              });
            }
          },
        },
      },
      restoreJobActionHook: {
        hook: useModal,
        hookArgument: {name: 'Restore Job Modal', enableTracking: true},
        Component: RestoreJobModal,
        componentProps: {
          key: job.isCancelled,
          job,
          refetch,
        },
      },
      resetJobActionHook: {
        hook: useModal,
        hookArgument: {name: 'Reset Job Modal', enableTracking: true},
        Component: ResetJobModal,
        componentProps: {
          moveId: job.moveId,
          refetch,
        },
      },
      finalizeJobActionHook: {
        hook: useModal,
        hookArgument: {name: 'Finalize Job Modal'},
        Component: FinalizeJobModal,
        componentProps: {
          jobId: job.id,
          refetch,
        },
      },
      unfinalizeJobActionHook: {
        hook: useModal,
        hookArgument: {name: 'Unfinalize Job Modal'},
        Component: UnfinalizeJobModal,
        componentProps: {
          jobId: job.id,
          refetch,
        },
      },
    }),
  };
};

const getContextDefinitions = ({
  project,
  displayJobs,
  activeJobUuid,
  handlePressJob,
  urlFilters,
  refetch,
  navigator,
  params,
  moverLinkToast,
  trackingLinkToast,
  responsive,
  viewer,
}) => {
  return displayJobs.reduce((result, job) => {
    if (_.some(job.jobsForEstimatedRange)) {
      const displayRangeJobs = getDisplayRangeJobs({jobs: job.jobsForEstimatedRange, params});
      const hasChildJobsToDisplay = displayRangeJobs.length > 1;
      if (hasChildJobsToDisplay) {
        return [
          ...result,
          ...displayRangeJobs.map((estimatedRangeJob, index) =>
            getJobContext({
              project,
              job: estimatedRangeJob,
              displayJobs,
              badge: getEstimatedRangeJobBadgeLabel({
                job: estimatedRangeJob,
                parentJob: job,
                displayJobs,
                index,
              }),
              activeJobUuid,
              handlePressJob,
              urlFilters,
              refetch,
              navigator,
              params,
              moverLinkToast,
              trackingLinkToast,
              responsive,
              viewer,
            }),
          ),
        ];
      }
    }
    return [
      ...result,
      getJobContext({
        project,
        job,
        badge: job.isCancelled ? 'Cancelled' : null,
        activeJobUuid,
        handlePressJob,
        urlFilters,
        refetch,
        navigator,
        params,
        moverLinkToast,
        trackingLinkToast,
        responsive,
        viewer,
      }),
    ];
  }, []);
};

const LoadingComponent = () => {
  return (
    <ProjectJobsBarContainer>
      <Row>
        <Space width={24} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Button} width={160} />
        <Space width={12} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Button} width={100} />
      </Row>
    </ProjectJobsBarContainer>
  );
};

const ProjectJobsBar = ({project, activeJobUuid, handlePressJob, urlFilters, refetch, viewer}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const displayJobs = Project.getDisplayAllJobsExcludingChildJobs(project, params);
  const jobActionsPopover = usePopover();
  const moverLinkToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Mover link copied to clipboard!',
  });
  const trackingLinkToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Customer tracking link copied to clipboard!',
  });

  return (
    <ProjectJobsBarContainer>
      <Row>
        <Space width={24} />
        {!_.isEmpty(displayJobs) && (
          <React.Fragment>
            <ContextSwitcher
              contextDefinitions={getContextDefinitions({
                project,
                displayJobs,
                activeJobUuid,
                handlePressJob,
                urlFilters,
                refetch,
                navigator,
                params,
                moverLinkToast,
                trackingLinkToast,
                responsive,
                viewer,
              })}
              actionMenuWidth={320}
            />
          </React.Fragment>
        )}
        <Space style={{flex: 1, minWidth: 16}} />
        <SecondaryButton
          iconLeft={Icon.Pen}
          text={'Edit Jobs'}
          onPress={() => {
            const {jobUuid} = params;
            const url = `/projects/${params.projectUuid}/edit/jobs${
              jobUuid !== '' ? `?jobUuid=${jobUuid}` : ''
            }`;
            navigator.push(url);
          }}
        />
        <Space width={16} />
        <TextTooltip
          text={
            Project.getIsAtMaxJobs(project)
              ? 'You have reached the maximum number of jobs for this project.'
              : ''
          }
        >
          <TooltipContentContainer>
            <SecondaryButton
              iconLeft={Icon.Plus}
              text={'Add New Job'}
              isDisabled={Project.getIsAtMaxJobs(project)}
              onPress={() =>
                navigator.push(
                  `/projects/${params.projectUuid}/edit/jobs?action=${Project.JOB_ACTIONS.ADD}`,
                )
              }
            />
          </TooltipContentContainer>
        </TextTooltip>
        <Space width={8} />
        <ActionMenuPopover
          popover={jobActionsPopover}
          width={200}
          actions={getJobsActions({project, urlFilters})}
        >
          <QuaternaryButton onPress={jobActionsPopover.handleToggle}>
            <Icon source={Icon.EllipsisV} size={16} color={colors.gray.secondary} />
          </QuaternaryButton>
        </ActionMenuPopover>
        <Space width={16} />
      </Row>
    </ProjectJobsBarContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobsBar.JobFragment = gql`
  ${Job.getIsEstimatedRange.fragment}
  ${Job.sortJobsBySequence.fragment}
  ${JobCompleteManuallyModal.fragment}
  ${RestoreJobModal.fragment}
  ${Job.getActions.fragment}

  fragment ProjectJobsBar_Job on Job {
    id
    uuid
    isCancelled
    shortName
    moveId
    ...Job_getIsEstimatedRange
    ...Job_sortJobsBySequence
    ...JobCompleteManuallyModal
    ...RestoreJobModal
    ...Job_getActions
  }
`;

ProjectJobsBar.fragment = gql`
  ${Project.getDisplayAllJobsExcludingChildJobs.fragment}
  ${Project.getIsAtMaxJobs.fragment}
  ${ProjectJobsBar.JobFragment}

  fragment ProjectJobsBar on Project {
    id
    allJobsExcludingChildJobs {
      id
      identifier
      jobsForEstimatedRange {
        id
        ...ProjectJobsBar_Job
      }
      ...ProjectJobsBar_Job
    }
    ...Project_getDisplayAllJobsExcludingChildJobs
    ...Project_getIsAtMaxJobs
  }

  fragment ProjectJobsBar_User on User {
    id
    role
  }
`;

ProjectJobsBar.SkeletonLoader = LoadingComponent;

export default ProjectJobsBar;
