// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography} from '@supermove/styles';

// App
import JobTimetable from 'modules/App/components/JobTimetable';

const Container = Styled.View`
`;

const Title = Styled.Text`
  ${Typography.Heading1}
  margin-bottom: 5px;
`;

const JobTimetableBlock = ({job, showHeader}) => {
  return (
    <Container>
      {showHeader && <Title>Timesheets</Title>}
      <JobTimetable job={job} />
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobTimetableBlock.propTypes = {
  job: PropTypes.object.isRequired,
};

JobTimetableBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTimetableBlock.fragment = gql`
  ${JobTimetable.fragment}

  fragment JobTimetableBlock on Job {
    id
    ...JobTimetable
  }
`;

export default JobTimetableBlock;
