// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Space, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useQuery,
  useHover,
  useDebouncedCallback,
  usePagination,
  usePopover,
  useState,
} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {titleize} from '@supermove/utils';

// App
import ButtonGroup from '@shared/design/components/Button/ButtonGroup';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import SearchBar from '@shared/design/components/SearchBar';
import TextTooltip from '@shared/design/components/TextTooltip';
import ContainerCapacityKind from '@shared/modules/Storage/enums/ContainerCapacityKind';
import ContainerLocationKind from '@shared/modules/Storage/enums/ContainerLocationKind';
import useFilteredContainersReducer from '@shared/modules/Storage/hooks/useFilteredContainersReducer';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import ContainersList from 'modules/Storage/Containers/ContainersList';

const View = Styled.View`
`;

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Row = Styled.View`
  flex-direction: row;
  width: 100%;
`;

const LeftContainer = Styled.View`
  flex: 1;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: ${colors.gray.border};
  background-color: ${colors.white}
  padding: 24px 0;
`;

const RightContainer = Styled.View`
  flex: 4;
  background-color: ${colors.gray.background}
  padding: 24px;
`;

const WarehouseTile = Styled.ButtonV2`
  border: 1px solid;
  background-color: ${({backgroundColor}) => backgroundColor};
  border-color: ${({borderColor}) => borderColor};
  height: 48px;
  border-radius: 4px;
  padding: 12px 16px;
  margin: 0 24px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const WarehouseTileText = Styled.Text`
  ${Typography.Subheading}
`;

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const FilterTitle = Styled.Text`
  ${Typography.Heading2}
`;

const FilterContainer = Styled.View`
  flex-direction: row;
`;

const Label = Styled.Text`
  ${Typography.Body}
`;

const WarehouseList = ({warehouses, selectedWarehouseId, setWarehouse, setLocationKind}) => {
  return (
    <ScrollView>
      {warehouses.map((warehouse, index) => {
        return (
          <React.Fragment key={index}>
            <Warehouse
              warehouse={warehouse}
              isSelected={warehouse.id === selectedWarehouseId}
              setWarehouse={(warehouseId) => {
                setWarehouse(warehouseId);
                setLocationKind(ContainerLocationKind.WAREHOUSE);
              }}
            />
            <Space height={16} />
          </React.Fragment>
        );
      })}
      <Warehouse
        warehouse={{
          name: 'Other Locations',
          notes: 'Any containers that are not located in one of your warehouses will appear here.',
        }}
        isSelected={!selectedWarehouseId}
        setWarehouse={() => {
          // NOTE(cooper): We explicitly set the warehouse id to null for CUSTOM locations
          setWarehouse(null);
          setLocationKind(ContainerLocationKind.CUSTOM);
        }}
      />
      <Space height={16} />
    </ScrollView>
  );
};

const Warehouse = ({warehouse, isSelected, setWarehouse}) => {
  const {ref, isHovered} = useHover();
  const backgroundColor = isSelected ? colors.blue.accent : colors.white;
  const borderColor = isSelected
    ? colors.blue.interactive
    : isHovered
      ? colors.blue.hover
      : colors.gray.border;

  return (
    <WarehouseTile
      ref={ref}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      onPress={() => setWarehouse(warehouse.id)}
    >
      <WarehouseTileText>{warehouse.name}</WarehouseTileText>
      {!!warehouse.notes && (
        <TextTooltip placement={'top'} text={warehouse.notes}>
          <View>
            <Icon color={colors.gray.tertiary} size={Icon.Sizes.Small} source={Icon.InfoCircle} />
          </View>
        </TextTooltip>
      )}
    </WarehouseTile>
  );
};

const POPOVER_FILTERS = [
  'containerTypeIds',
  'warehouseCoordinateX',
  'warehouseCoordinateY',
  'warehouseCoordinateZ',
  'customLocationSearchQuery',
];

const ContainerFilters = ({filters, dispatch, actions, containerTypes}) => {
  const filtersPopover = usePopover({name: 'Assign Containers Page Filters Popover'});
  const debouncedSetSearchQuery = useDebouncedCallback(
    (searchQuery) => dispatch({type: actions.SET_SEARCH_QUERY, payload: searchQuery}),
    300,
  );

  const handleCapacityFilterPress = (capacityKind) => {
    // Using _.xor() to remove a value if it already exists, otherwise add it
    const newCapacityKinds = _.xor(filters.capacityKinds, [capacityKind]);
    dispatch({type: actions.SET_CAPACITY_KINDS, payload: newCapacityKinds});
  };

  // NOTE(cooper): This only applies to filters _within_ the popover
  const appliedFiltersCount = _.reduce(
    filters,
    (appliedFiltersCount, filterValue, filterKey) => {
      if (_.includes(POPOVER_FILTERS, filterKey) && !!filterValue) {
        return appliedFiltersCount + 1;
      }
      return appliedFiltersCount;
    },
    0,
  );

  return (
    <Row>
      <View style={{flex: 1}}>
        <SearchBar
          onChangeText={debouncedSetSearchQuery}
          placeholder='Search by container details'
          style={{height: '38px', width: '100%'}}
          defaultValue={filters.searchQuery}
        />
      </View>
      <Space width={16} />
      <View>
        <ButtonGroup
          options={ContainerCapacityKind.ALL.map((capacityKind) => ({
            value: capacityKind,
            label: titleize(capacityKind),
            disabled:
              filters.capacityKinds.includes(capacityKind) && filters.capacityKinds.length === 1,
          }))}
          selectedOptionValues={filters.capacityKinds}
          handleOptionPress={handleCapacityFilterPress}
        />
      </View>
      <Space width={16} />
      <React.Fragment>
        <Popover.Content innerRef={filtersPopover.ref}>
          <SecondaryButton
            text={appliedFiltersCount === 0 ? 'Filter' : `Filter (${appliedFiltersCount})`}
            iconLeft={Icon.Filter}
            onPress={filtersPopover.handleToggle}
          />
        </Popover.Content>
        <FiltersPopover
          filters={filters}
          dispatch={dispatch}
          actions={actions}
          popover={filtersPopover}
          containerTypes={containerTypes}
        />
      </React.Fragment>
    </Row>
  );
};

const FiltersPopover = ({filters, dispatch, actions, popover, containerTypes}) => {
  const [displayWarehouseCoordinateX, setDisplayWarehouseCoordinateX] = useState(
    filters.warehouseCoordinateX,
  );
  const [displayWarehouseCoordinateY, setDisplayWarehouseCoordinateY] = useState(
    filters.warehouseCoordinateY,
  );
  const [displayWarehouseCoordinateZ, setDisplayWarehouseCoordinateZ] = useState(
    filters.warehouseCoordinateZ,
  );
  const [displayCustomLocationSearchQuery, setDisplayCustomLocationSearchQuery] = useState(
    filters.customLocationSearchQuery,
  );
  const debouncedSetCoordinate = useDebouncedCallback(
    (coordinate, coordinateType) =>
      dispatch({type: actions.SET_WAREHOUSE_COORDINATE, coordinateType, payload: coordinate}),
    300,
  );
  const debouncedSetCustomLocationSearchQuery = useDebouncedCallback(
    (customLocationSearchQuery) =>
      dispatch({
        type: actions.SET_CUSTOM_LOCATION_SEARCH_QUERY,
        payload: customLocationSearchQuery,
      }),
    300,
  );

  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={320} maxHeight={580}>
        <ContentContainer>
          <Space height={16} />
          <FilterTitle>Filters</FilterTitle>
          <Space height={16} />
          <FieldInput
            label={'Container Type'}
            component={MultiDropdownCheckboxInput}
            index={1}
            input={{
              value: filters.containerTypeIds,
              options: containerTypes.map(({id, name}) => ({label: name, value: id})),
              placeholder: `Select container types(s)`,
              onChangeValue: (containerTypeIds) =>
                dispatch({
                  type: actions.SET_CONTAINER_TYPES,
                  payload: containerTypeIds.length ? containerTypeIds : null,
                }),
            }}
          />
          <Space height={16} />
          {filters.warehouseIds ? (
            <React.Fragment>
              <Label>Coordinates</Label>
              <Space height={4} />
              <FilterContainer>
                <FieldInput
                  index={2}
                  style={{flex: 1}}
                  input={{
                    placeholder: 'X',
                    onChangeText: (coordinate) => {
                      setDisplayWarehouseCoordinateX(coordinate);
                      debouncedSetCoordinate(
                        coordinate,
                        ContainerLocationKind.WAREHOUSE_COORDINATE_X,
                      );
                    },
                    value: displayWarehouseCoordinateX,
                  }}
                />
                <Space width={8} />
                <FieldInput
                  index={3}
                  style={{flex: 1}}
                  input={{
                    placeholder: 'Y',
                    onChangeText: (coordinate) => {
                      setDisplayWarehouseCoordinateY(coordinate);
                      debouncedSetCoordinate(
                        coordinate,
                        ContainerLocationKind.WAREHOUSE_COORDINATE_Y,
                      );
                    },
                    value: displayWarehouseCoordinateY,
                  }}
                />
                <Space width={8} />
                <FieldInput
                  index={4}
                  style={{flex: 1}}
                  input={{
                    placeholder: 'Z',
                    onChangeText: (coordinate) => {
                      setDisplayWarehouseCoordinateZ(coordinate);
                      debouncedSetCoordinate(
                        coordinate,
                        ContainerLocationKind.WAREHOUSE_COORDINATE_Z,
                      );
                    },
                    value: displayWarehouseCoordinateZ,
                  }}
                />
              </FilterContainer>
            </React.Fragment>
          ) : (
            <FieldInput
              index={5}
              style={{flex: 1}}
              label={'Location'}
              input={{
                placeholder: 'Address',
                onChangeText: (customLocationSearchQuery) => {
                  setDisplayCustomLocationSearchQuery(customLocationSearchQuery);
                  debouncedSetCustomLocationSearchQuery(customLocationSearchQuery);
                },
                value: displayCustomLocationSearchQuery,
              }}
            />
          )}
          <Space height={16} />
          <TertiaryButton
            text={'Reset Filters'}
            iconLeft={Icon.Trash}
            onPress={() => {
              setDisplayWarehouseCoordinateX('');
              setDisplayWarehouseCoordinateY('');
              setDisplayWarehouseCoordinateZ('');
              setDisplayCustomLocationSearchQuery('');
              dispatch({type: actions.RESET_FILTERS});
            }}
          />
          <Space height={16} />
        </ContentContainer>
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const getInitialFilters = (initialWarehouseId) => ({
  locationKind: ContainerLocationKind.WAREHOUSE,
  warehouseIds: [initialWarehouseId],
  containerTypeIds: null,
  capacityKinds: ContainerCapacityKind.ALL,
  warehouseCoordinateX: '',
  warehouseCoordinateY: '',
  warehouseCoordinateZ: '',
  searchQuery: '',
  customLocationSearchQuery: '',
  pagination: PaginationBar.DEFAULT_PAGINATION,
});

const AssignContainersPage = ({
  organization,
  allowMultiselect,
  selectedContainers,
  onContainerSelect,
}) => {
  const {filters, dispatch, actions} = useFilteredContainersReducer(
    getInitialFilters(organization.warehouses[0].id),
  );

  const {data, loading, error, refetch} = useQuery(AssignContainersPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: filters,
  });

  const pagination = usePagination({
    currentPage: _.toNumber(filters.pagination.page),
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page) => dispatch({type: actions.SET_PAGINATION, payload: page}),
  });

  return (
    <ScrollView horizontal contentContainerStyle={{flex: 1}}>
      <Container style={{minWidth: '1250px'}}>
        <LeftContainer>
          <WarehouseList
            warehouses={organization.warehouses}
            selectedWarehouseId={filters.warehouseIds ? filters.warehouseIds[0] : null}
            setWarehouse={(warehouseId) =>
              dispatch({type: actions.SET_WAREHOUSE, payload: warehouseId})
            }
            setLocationKind={(locationKind) =>
              dispatch({type: actions.SET_LOCATION_KIND, payload: locationKind})
            }
          />
        </LeftContainer>
        <RightContainer>
          <ContainerFilters
            filters={filters}
            dispatch={dispatch}
            actions={actions}
            containerTypes={organization.containerTypes}
          />
          <Space height={16} />
          <ScrollView style={{flex: 1}}>
            <ContainersList
              loading={loading}
              refetch={refetch}
              containers={data ? data.paginatedList.containers : []}
              hasError={!!error}
              allowMultiselect={allowMultiselect}
              selectedContainers={selectedContainers}
              onContainerSelect={onContainerSelect}
              isWarehouseView={!!filters.warehouseIds}
            />
            <Space height={40} />
            <PaginationBar pagination={pagination} />
            <Space height={70} />
          </ScrollView>
        </RightContainer>
      </Container>
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignContainersPage.query = gql`
  ${usePagination.fragment}
  ${ContainersList.fragment}

  query AssignContainersPage(
    $locationKind: String!
    $pagination: PaginationInput!
    $warehouseIds: [Int]
    $containerTypeIds: [Int]
    $capacityKinds: [String]
    $warehouseCoordinateX: String
    $warehouseCoordinateY: String
    $warehouseCoordinateZ: String
    $searchQuery: String
    $customLocationSearchQuery: String
  ) {
    ${gql.query}
    paginatedList: filteredContainersPaginatedList(
      locationKind: $locationKind
      pagination: $pagination
      warehouseIds: $warehouseIds
      containerTypeIds: $containerTypeIds
      capacityKinds: $capacityKinds
      warehouseCoordinateX: $warehouseCoordinateX
      warehouseCoordinateY: $warehouseCoordinateY
      warehouseCoordinateZ: $warehouseCoordinateZ
      searchQuery: $searchQuery
      customLocationSearchQuery: $customLocationSearchQuery
    ) {
      containers: results {
        id
        ...ContainersList
      }
      paginationMetadata {
        ...usePagination
      }
    }
  }
`;

AssignContainersPage.fragment = gql`
  fragment AssignContainersPage on Organization {
    id
    warehouses {
      id
      name
      notes
    }
    containerTypes {
      id
      name
    }
  }
`;

export default AssignContainersPage;
