// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import CreateSmsTemplatesContent from 'modules/Organization/Settings/Communication/components/CreateSmsTemplateContent';

const CreateSmsTemplatesPage = () => {
  const {loading, data} = useQuery(CreateSmsTemplatesPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <SidebarPageV2 selected={'settings'}>
      <CreateSmsTemplatesContent organization={data.viewer.viewingOrganization} />
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateSmsTemplatesPage.query = gql`
  ${CreateSmsTemplatesContent.fragment}

  query CreateSmsTemplatesPage {
    viewer {
      id
      viewingOrganization {
        id
        ...CreateSmsTemplatesContent
      }
    }
  }
`;

export default CreateSmsTemplatesPage;
