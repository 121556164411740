// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {PrintDocumentPage} from 'modules/Document/Print/components';
import {DocumentPdfView} from 'modules/Document/components';

const CustomerDocumentShowPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <PrintDocumentPage query={CustomerDocumentShowPage.query} variables={{uuid}}>
    {({data}) => <DocumentPdfView document={data.document} />}
  </PrintDocumentPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerDocumentShowPage.query = gql`
  ${DocumentPdfView.fragment}

  query CustomerDocumentShowPage($uuid: String!) {
    ${gql.query}
    document(uuid: $uuid) {
      id
      ...DocumentPdfView
    }
  }
`;

export default CustomerDocumentShowPage;
