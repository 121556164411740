// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CategoryForm from '@shared/modules/Inventory/forms/CategoryForm';

const edit = withFragment(
  (inventoryLibrary) => ({
    categoryForms: (inventoryLibrary as any).categories.map((category: any) =>
      CategoryForm.edit(category),
    ),
  }),
  gql`
    ${CategoryForm.edit.fragment}
    fragment CategoriesForm_edit on InventoryLibrary {
      id
      categories {
        id
        ...CategoryForm_edit
      }
    }
  `,
);

const toForm = ({categoryForms}: any) => ({
  categoryForms: categoryForms.map((categoryForm: any) => CategoryForm.toForm(categoryForm)),
});

const toMutation = ({categoryForms}: any) => ({
  categoryForms: _.reduce(
    categoryForms,
    // @ts-expect-error TS(2769): No overload matches this call.
    (filteredForms, categoryForm, index) => {
      if (categoryForm.isDirty) {
        const isDeletingNewForm = categoryForm.isDeleted && !categoryForm.categoryId;
        if (isDeletingNewForm) {
          // we don't pass these forms to the BE since there isn't an object to delete
          return filteredForms;
        }
        return [
          ...filteredForms,
          {
            ...CategoryForm.toMutation(categoryForm),
            // since we only pass dirty forms, we need to pass the current index to ensure the validation error is shown properly
            validationIndex: index,
          },
        ];
      }
      return filteredForms;
    },
    [],
  ),
});

const CategoriesForm = {
  edit,
  toForm,
  toMutation,
};

export default CategoriesForm;
