/**
 * TODO(herrick): Replace this file with ProjectJobLocationsInfoBlock to consolidate
 * logic once create/update project have been migrated.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// App
import JobMap from './JobMap';
import LocationFormsSection from './LocationFormsSection';

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index}px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H7`
  color: ${colors.gray.secondary};
`;

const getFieldName = ({field, name}) => {
  return field ? `${field}.${name}` : name;
};

const getLocationForms = ({field, form}) => {
  return _.get(form.values, getFieldName({field, name: 'locationForms'}));
};

const JobLocationsFields = ({
  disabled,
  index,
  field,
  form,
  responsive,
  organization,
  warehouseLocation,
  mapRef,
}) => {
  const locationDistancesFieldName = getFieldName({field, name: 'locationDistances'});
  const locationDistances = _.get(form.values, locationDistancesFieldName);
  const miles = _.sum(locationDistances).toFixed(2);
  return (
    <Section index={index}>
      <Row>
        <Title vars={responsive}>Location Information</Title>
        <Space width={8} />
        <Subtitle>{`(Mileage: ${miles} mi)`}</Subtitle>
      </Row>
      <Space height={8} />
      <LocationFormsSection
        disabled={disabled}
        field={getFieldName({field, name: 'locationForms'})}
        form={form}
        locationForms={getLocationForms({field, form})}
        responsive={responsive}
        organization={organization}
        isBuildingTypeRequired={false}
        warehouseLocation={warehouseLocation}
        mapRef={mapRef}
      />
      <JobMap
        isHidden
        locations={getLocationForms({field, form})}
        onRouteUpdate={({distances}) => {
          form.setFieldValue(locationDistancesFieldName, distances);
        }}
      />
    </Section>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobLocationsFields.fragment = gql`
  ${LocationFormsSection.fragment}

  fragment JobLocationsFields on Organization {
    id
    ...LocationFormsSection
  }
`;

export default JobLocationsFields;
