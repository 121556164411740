// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import EditProjectBlockWrapper from 'modules/Project/V2/Edit/components/EditProjectBlockWrapper';
import MobileEditJobBlock from 'modules/Project/V2/Edit/components/MobileEditJobBlock';

const ResponsiveContainer = Styled.View`
  flex-direction: ${({responsive}) => (responsive.desktop ? 'row' : 'column')};
`;

const EditJobDispatch = ({
  form,
  field,
  responsive,
  isDisabled,
  isMoversInputVisible,
  isTrucksInputVisible,
}) => {
  return (
    <ResponsiveContainer responsive={responsive}>
      {isTrucksInputVisible && (
        <FieldInput.Memoized
          {...form}
          name={`${field}.numberOfTrucks`}
          label={'Number of Trucks'}
          input={{placeholder: 'Enter #', disabled: isDisabled}}
          style={{flex: 1}}
          isResponsive
        />
      )}
      {isTrucksInputVisible && isMoversInputVisible && <Space width={12} height={16} />}
      {isMoversInputVisible && (
        <FieldInput.Memoized
          {...form}
          name={`${field}.crewSize`}
          label={'Number of Movers'}
          input={{placeholder: 'Enter #', disabled: isDisabled}}
          style={{flex: 1}}
          isResponsive
        />
      )}
      {responsive.desktop && (
        <React.Fragment>
          <Space width={12} />
          <Space style={{flex: 1}} />
        </React.Fragment>
      )}
    </ResponsiveContainer>
  );
};

const EditJobDispatchBlockContent = ({
  form,
  field,
  isDisabled,
  isMoversInputVisible,
  isTrucksInputVisible,
}) => {
  const responsive = useResponsive();
  const title = ProjectBlockKind.getDisplay(ProjectBlockKind.Job.DISPATCH);

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <ActionPanel
          BodyComponent={EditJobDispatch}
          bodyComponentProps={{
            form,
            field,
            responsive,
            isDisabled,
            isMoversInputVisible,
            isTrucksInputVisible,
          }}
          title={title}
          style={{width: '100%'}}
        />
      ) : (
        <MobileEditJobBlock headerText={title} responsive={responsive}>
          <EditJobDispatch
            form={form}
            field={field}
            responsive={responsive}
            isDisabled={isDisabled}
            isMoversInputVisible={isMoversInputVisible}
            isTrucksInputVisible={isTrucksInputVisible}
          />
        </MobileEditJobBlock>
      )}
    </React.Fragment>
  );
};

const EditJobDispatchBlock = ({
  form,
  field,
  index,
  handleSetPositionY,
  layoutKey,
  isDisabled,
  isMoversInputVisible,
  isTrucksInputVisible,
}) => {
  return (
    <EditProjectBlockWrapper
      index={index}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
    >
      <EditJobDispatchBlockContent
        form={form}
        field={field}
        isDisabled={isDisabled}
        isMoversInputVisible={isMoversInputVisible}
        isTrucksInputVisible={isTrucksInputVisible}
      />
    </EditProjectBlockWrapper>
  );
};

export default EditJobDispatchBlock;
