// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const DateText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-transform: uppercase;
`;

const WeekHeader = ({date}) => (
  <Container>
    <DateText>{`Week of ${Datetime.convertToDisplayDate(date, 'M/D/YY')}`}</DateText>
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
WeekHeader.propTypes = {
  date: PropTypes.object.isRequired,
};

WeekHeader.defaultProps = {};

export default WeekHeader;
