// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import LocationForm from '@shared/modules/Location/forms/LocationForm';

const edit = withFragment(
  (shipment) => ({
    shipmentId: (shipment as any).id,
    tripId: (shipment as any).tripId,
    projectId: (shipment as any).project.id,
    status: (shipment as any).status,
    // Private
    weight: (shipment as any).project.weight,
    clientName: (shipment as any).project.client.name,
    clientUuid: (shipment as any).project.client.uuid,
    originLocationForm: LocationForm.edit((shipment as any).project.startLocation),
    destinationLocationForm: LocationForm.edit((shipment as any).project.endLocation),
    packJobStartDate: (shipment as any).project.packJobStartDate,
    packJobEndDate: (shipment as any).project.packJobEndDate,
    packJobScheduledDate: (shipment as any).project.packJobScheduledDate,
    deliveryJobStartDate: (shipment as any).project.deliveryJobStartDate,
    deliveryJobEndDate: (shipment as any).project.deliveryJobEndDate,
    deliveryJobScheduledDate: (shipment as any).project.deliveryJobScheduledDate,
    loadJobStartDate: (shipment as any).project.loadJobStartDate,
    loadJobEndDate: (shipment as any).project.loadJobEndDate,
    loadJobScheduledDate: (shipment as any).project.loadJobScheduledDate,
  }),
  gql`
    ${LocationForm.edit.fragment}
    fragment ShipmentForm_edit on Shipment {
      id
      tripId
      status
      project {
        id
        weight
        packJobStartDate
        packJobEndDate
        packJobScheduledDate
        deliveryJobStartDate
        deliveryJobEndDate
        deliveryJobScheduledDate
        loadJobStartDate
        loadJobEndDate
        loadJobScheduledDate
        client {
          id
          name
          uuid
        }
        startLocation {
          id
          ...LocationForm_edit
        }
        endLocation {
          id
          ...LocationForm_edit
        }
      }
    }
  `,
);

const toForm = ({
  shipmentId,
  tripId,
  projectId,
  status,
  weight,
  clientName,
  clientUuid,
  originLocationForm,
  destinationLocationForm,
  packJobStartDate,
  packJobEndDate,
  packJobScheduledDate,
  deliveryJobStartDate,
  deliveryJobEndDate,
  deliveryJobScheduledDate,
  loadJobStartDate,
  loadJobEndDate,
  loadJobScheduledDate,
}: any) => ({
  shipmentId,
  tripId,
  projectId,
  status,

  // Private
  weight,
  clientName,
  clientUuid,
  originLocationForm,
  destinationLocationForm,
  packJobStartDate,
  packJobEndDate,
  packJobScheduledDate,
  deliveryJobStartDate,
  deliveryJobEndDate,
  deliveryJobScheduledDate,
  loadJobStartDate,
  loadJobEndDate,
  loadJobScheduledDate,
});

const toMutation = ({shipmentId, tripId, projectId, status}: any) => ({
  shipmentId,
  tripId,
  projectId,
  status,
});

const ShipmentForm = {
  edit,
  toForm,
  toMutation,
};

export default ShipmentForm;
