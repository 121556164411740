// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import {ReportMoveTimeRangesChart} from 'modules/ReportMove/components';

import UpdateTimeRangesList from './UpdateTimeRangesList';

const Container = Styled.View`
`;

const Row = Styled.View`
  z-index: ${({index = 0}) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: 20px;
`;

const UpdateReportMoveTimeRangesFields = ({form}) => (
  <Container>
    <Row index={0}>
      <ReportMoveTimeRangesChart timeRanges={form.values.timeRangeInputs} />
    </Row>
    <RowSpace />
    <Row index={1}>
      <UpdateTimeRangesList form={form} />
    </Row>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateReportMoveTimeRangesFields.fragment = gql`
  fragment UpdateReportMoveTimeRangesFields on ReportMove {
    id
    timeRanges {
      name
      kind
      start
      end
    }
  }
`;

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateReportMoveTimeRangesFields.propTypes = {
  form: PropTypes.object.isRequired,
};

export default UpdateReportMoveTimeRangesFields;
