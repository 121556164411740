// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import JobLocationForm from '@shared/modules/Job/forms/JobLocationForm';

const useDeleteJobLocationMutation = ({jobLocationForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      jobLocationForm: JobLocationForm.toForm(jobLocationForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteJobLocationMutation.mutation,
    variables: {
      jobLocationForm: JobLocationForm.toMutation(form.values.jobLocationForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteJobLocationMutation.mutation = gql`
  mutation useDeleteJobLocationMutation($jobLocationForm: JobLocationForm!) {
    response: deleteJobLocation(jobLocationForm: $jobLocationForm) {
      ${gql.errors}
      location {
        id
      }
    }
  }
`;

export default useDeleteJobLocationMutation;
