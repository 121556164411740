// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';

// App
import JobTimetable from 'modules/App/components/JobTimetable';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SimpleHeader from 'modules/App/components/SimpleHeader';
import JobPositionsWithMap from 'modules/Job/components/JobPositionsWithMap';
import JobTimesheetBillableEntries from 'modules/Project/V2/Show/Blocks/Job/components/JobTimesheetBillableEntries';

const PageContainer = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
`;

const AuditTimesheetJobPageContent = ({job}) => {
  const {features} = job.project.projectType;
  const responsive = useResponsive();

  return (
    <PageContainer>
      <SimpleHeader
        title={`View GPS tracking for ${Job.getFullName(job)}`}
        subtitle={`Tablet version ${job.tabletVersion || '---'}`}
        showLine
        showBackButton
        numberOfLines={1}
      />
      <Space height={16} />
      <Container style={{paddingHorizontal: 16}}>
        {features.timesheetsV2 && (
          <React.Fragment>
            <JobTimetable.Label>Job Timesheet</JobTimetable.Label>
            <JobTimesheetBillableEntries job={job} responsive={responsive} isViewOnly />
            <Space height={16} />
          </React.Fragment>
        )}
        <JobTimetable job={job} hideWorkTimesheet={features.timesheetsV2} />
      </Container>
      <Space height={16} />
      <Line />
      <Container style={{flex: 1}}>
        <JobPositionsWithMap job={job} />
      </Container>
    </PageContainer>
  );
};

const AuditTimesheetJobPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(AuditTimesheetJobPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobUuid: params.jobUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => <AuditTimesheetJobPageContent job={data.job} />}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AuditTimesheetJobPage.query = gql`
  ${Job.getFullName.fragment}
  ${JobPositionsWithMap.fragment}
  ${JobTimetable.fragment}
  ${JobTimesheetBillableEntries.fragment}

  query AuditTimesheetJobPage(
    $jobUuid: String!,
  ) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      uuid
      tabletVersion
      project {
        id
        projectType {
          id
          features {
            timesheetsV2
          }
        }
      }
      ...JobPositionsWithMap
      ...JobTimetable
      ...Job_getFullName
      ...JobTimesheetBillableEntries
    }
  }
`;

export default AuditTimesheetJobPage;
