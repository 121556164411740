// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UpdateBillItemAndValuesForm from '@shared/modules/Billing/forms/UpdateBillItemAndValuesForm';

const useUpdateBillItemAndValuesMutation = ({
  updateBillItemAndValuesForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      updateBillItemAndValuesForm: UpdateBillItemAndValuesForm.toForm(updateBillItemAndValuesForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateBillItemAndValuesMutation.mutation,
    variables: {
      updateBillItemAndValuesForm: UpdateBillItemAndValuesForm.toMutation(
        form.values.updateBillItemAndValuesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateBillItemAndValuesMutation.mutation = gql`
  mutation useUpdateBillItemAndValuesMutation($updateBillItemAndValuesForm: UpdateBillItemAndValuesForm!) {
    response: updateBillItemAndValues(updateBillItemAndValuesForm: $updateBillItemAndValuesForm) {
      ${gql.errors}
      billItem {
        id
      }
    }
  }
`;

export default useUpdateBillItemAndValuesMutation;
