// Supermove
import {JobRequestForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {Organization} from '@supermove/models';

const MUTATION = gql`
  mutation CreateJobRequestV2Mutation($jobRequestForm: JobRequestForm!) {
    response: createJobRequestV2(jobRequestForm: $jobRequestForm) {
      ${gql.errors}
      jobRequest {
        id
        uuid
      }
    }
  }
`;

const useCreateJobRequestV2Form = ({jobRequestForm, organization, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      jobRequestForm: JobRequestForm.toForm(jobRequestForm),

      // Private attributes
      shouldShowSingleNameCustomerField: organization.shouldShowSingleNameCustomerField,
      jobFormCustomValues: Organization.getJobFormCustomValues(organization),
      jobFormAdditionalItems: Organization.getJobFormAdditionalItems(organization),
    },
  });
  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: MUTATION,
    variables: {
      jobRequestForm: JobRequestForm.toMutation(form.values.jobRequestForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateJobRequestV2Form.fragment = gql`
  ${Organization.getJobFormAdditionalItems.fragment}
  ${Organization.getJobFormCustomValues.fragment}
  fragment useCreateJobRequestV2Form on Organization {
    id
    shouldShowSingleNameCustomerField
    ...Organization_getJobFormAdditionalItems
    ...Organization_getJobFormCustomValues
  }
`;

export default useCreateJobRequestV2Form;
