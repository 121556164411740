// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import ContainerTypeForm from '@shared/modules/Storage/forms/ContainerTypeForm';
import useDeleteContainerTypeMutation from '@shared/modules/Storage/hooks/useDeleteContainerTypeMutation';

const DeleteContainerTypeModal = ({containerType, deleteContainerTypeModal, userId, refetch}) => {
  const containerTypeForm = ContainerTypeForm.edit(containerType, {userId});
  const {handleSubmit} = useDeleteContainerTypeMutation({
    containerTypeForm,
    onSuccess: () => {
      deleteContainerTypeModal.handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DeleteModal
      handleDelete={handleSubmit}
      handleClose={deleteContainerTypeModal.handleClose}
      isOpen={deleteContainerTypeModal.isOpen}
      title={`Remove "${containerType.name}"?`}
      subtitle={'Removing this container type will not impact existing containers of this type.'}
      deleteButtonText={'Remove'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteContainerTypeModal.fragment = gql`
  ${ContainerTypeForm.edit.fragment}

  fragment DeleteContainerTypeModal on ContainerType {
    id
    name
    ...ContainerTypeForm_edit
  }
`;
export default DeleteContainerTypeModal;
