// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Wrapper = Styled.View`
  margin-bottom: 10px;
  z-index: ${(props) => 100 - (props as any).index};
`;

const SectionTitle = Styled.Text`
  font-size: 14px;
  line-height: 19px;
  ${Typography.Label2}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const DocumentV2Title = ({title, index}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Wrapper index={index}>
      <SectionTitle>{title}</SectionTitle>
    </Wrapper>
  );
};

export default DocumentV2Title;
