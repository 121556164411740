// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {TimeRange} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 60px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Circle = Styled.View`
  margin-right: 10px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
`;

const Name = Styled.H6`
  flex: 1;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Value = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const getFullTimeValue = ({timeRange}) => {
  const startTimeFormatted = Datetime.convertToDisplayTime(timeRange.start, Datetime.FORM_TIME);
  const endTimeFormatted = Datetime.convertToDisplayTime(timeRange.end, Datetime.FORM_TIME);
  return `${startTimeFormatted} - ${endTimeFormatted}`;
};

const ReportMoveTimeRangeItem = ({isFirst, timeRange}) => (
  <Container>
    {isFirst && <Line />}
    <Content>
      <Circle color={TimeRange.getColor(timeRange)} />
      <Name>{timeRange.name}</Name>
      <Value>{getFullTimeValue({timeRange})}</Value>
    </Content>
    <Line />
  </Container>
);

export default ReportMoveTimeRangeItem;
