// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteEmailTemplateForm from 'modules/Organization/Settings/Email/logic/useDeleteEmailTemplateForm';

const DeleteEmailTemplateModal = ({isOpen, emailTemplate, handleClose, refetch}) => {
  const {submitting, handleSubmit} = useDeleteEmailTemplateForm({
    emailTemplate,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete ${emailTemplate.name}?`}
      subtitle={'Are you sure you want to delete this email template?'}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
DeleteEmailTemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  emailTemplate: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
};

DeleteEmailTemplateModal.defaultProps = {
  handleClose: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteEmailTemplateModal.fragment = gql`
  ${useDeleteEmailTemplateForm.fragment}
  fragment DeleteEmailTemplateModal on EmailTemplate {
    id
    uuid
    name
    ...useDeleteEmailTemplateForm
  }
`;

export default DeleteEmailTemplateModal;
