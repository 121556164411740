// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

import InviteUserForm from '@shared/modules/User/forms/InviteUserForm';

const useUserInviteSignUpMutation = ({inviteUserForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      inviteUserForm: InviteUserForm.toForm(inviteUserForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUserInviteSignUpMutation.mutation,
    variables: {
      inviteUserForm: InviteUserForm.toMutation(form.values.inviteUserForm),
    },
    onSuccess: async ({token, user}) => {
      // Store the token to sign the user in.
      await Storage.setItem('token', token);
      onSuccess({user});
    },
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUserInviteSignUpMutation.mutation = gql`
  mutation UserInviteSignUpMutation($inviteUserForm: InviteUserForm!) {
    response: userInviteSignUp(inviteUserForm: $inviteUserForm) {
      ${gql.errors}
      token
      user {
        id
      }
    }
  }
`;

export default useUserInviteSignUpMutation;
