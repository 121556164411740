// Supermove
import {PayableUserDayForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const MUTATION = gql`
  mutation UpdatePayableUserDayMutation(
    $payableUserDayForm: PayableUserDayForm!,
  ) {
    response: updatePayableUserDay(
      payableUserDayForm: $payableUserDayForm,
    ) {
      ${gql.errors}
      payableUserDay {
        id
      }
    }
  }
`;

const useUpdatePayableUserDayForm = ({payableUserDay, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      payableUserDayForm: PayableUserDayForm.toForm(PayableUserDayForm.edit(payableUserDay)),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: MUTATION,
    variables: {
      payableUserDayForm: PayableUserDayForm.toMutation(form.values.payableUserDayForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdatePayableUserDayForm.fragment = gql`
  ${PayableUserDayForm.edit.fragment}

  fragment useUpdatePayableUserDayForm on PayableUserDay {
    ...PayableUserDayForm_edit
  }
`;

export default useUpdatePayableUserDayForm;
