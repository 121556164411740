// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {PrintDocumentPage} from 'modules/Document/Print/components';
import {JobRiskyMoveDocument} from 'modules/Document/components';

const CustomerRiskyMovePage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <PrintDocumentPage query={CustomerRiskyMovePage.query} variables={{uuid}}>
    {({data}) => <JobRiskyMoveDocument job={data.job} />}
  </PrintDocumentPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerRiskyMovePage.query = gql`
  ${JobRiskyMoveDocument.fragment}

  query CustomerRiskyMovePage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      ...JobRiskyMoveDocument
    }
  }
`;

export default CustomerRiskyMovePage;
