// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Dropdown, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import useAppContext from 'modules/App/context/useAppContext';
import {UpdateCommercialReportMoveTimesheetModal} from 'modules/ReportMove/Commercial/components';
import {FinalizeReportMoveModal, UnfinalizeReportMoveModal} from 'modules/ReportMove/components';

const Button = Styled.Button`
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding-horizontal: 10px;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  box-shadow: none;
`;

const Items = Styled.View`
  margin-top: 5px;
  padding-bottom: 5px;
  width: 220px;
  background-color: ${colors.white};
  border: 1px solid ${colors.blue.accent};
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(128,128,128,0.5);
`;

const Option = Styled.Touchable`
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  padding-left: 10px;
  background-color: ${(props) => (props.isHovered ? colors.blue.accent : colors.white)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  z-index: 100;
`;

const OptionText = Styled.H7`
  color: ${colors.gray.secondary};
  ${fontWeight(700)}
  letter-spacing: 0.5;
`;

const CommercialJobReportDropdown = ({job, refetch}) => {
  const {viewer} = useAppContext();
  const {isAuthorizedAccountingActions} = viewer || {};
  const {navigator} = useNavigationDOM();

  return (
    <Dropdown
      trigger={({handleToggle}) => (
        <Button onPress={handleToggle}>
          <Icon color={colors.gray.secondary} size={Icon.Sizes.Large} source={Icon.EllipsisH} />
        </Button>
      )}
    >
      {({isOpen}) => (
        <Dropdown.Content isVisible={isOpen}>
          <Items>
            {!job.isFinal && (
              <UpdateCommercialReportMoveTimesheetModal
                job={job}
                refetch={refetch}
                trigger={({handleOpen}) => (
                  <Option onPress={handleOpen}>
                    <OptionText>Edit Timesheet</OptionText>
                  </Option>
                )}
              />
            )}
            <Option
              onPress={() => {
                navigator.push(`/move-reports/${job.reportMove.uuid}/show`);
              }}
            >
              <OptionText>Edit Report</OptionText>
            </Option>
            {isAuthorizedAccountingActions &&
              (job.reportMove.isFinal ? (
                <UnfinalizeReportMoveModal
                  reportMove={job.reportMove}
                  onSuccess={({handleClose}) => handleClose()}
                  refetch={refetch}
                  trigger={({handleOpen}) => (
                    <Option onPress={handleOpen}>
                      <OptionText>Unlock Report</OptionText>
                    </Option>
                  )}
                />
              ) : (
                <FinalizeReportMoveModal
                  reportMove={job.reportMove}
                  onSuccess={({handleClose}) => handleClose()}
                  refetch={refetch}
                  trigger={({handleOpen}) => (
                    <Option onPress={handleOpen}>
                      <OptionText>Finalize Report</OptionText>
                    </Option>
                  )}
                />
              ))}
          </Items>
        </Dropdown.Content>
      )}
    </Dropdown>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
CommercialJobReportDropdown.propTypes = {
  job: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

CommercialJobReportDropdown.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialJobReportDropdown.fragment = gql`
  fragment CommercialJobReportDropdown on Job {
    id
    isFinal
    reportMove {
      id
      uuid
      isFinal
    }
  }
`;

export default CommercialJobReportDropdown;
