const NO_EXPORT = 'NO_EXPORT';
const SUCCESS = 'SUCCESS';
const FAILED = 'FAILED';
const PENDING = 'PENDING';

const OPTIONS = [
  {
    value: NO_EXPORT,
    label: 'No export',
  },
  {
    value: SUCCESS,
    label: 'Success',
  },
  {
    value: FAILED,
    label: 'Failed',
  },
  {
    value: PENDING,
    label: 'Pending',
  },
];

export default {
  // Enum Options
  NO_EXPORT,
  SUCCESS,
  FAILED,
  PENDING,

  OPTIONS,
};
