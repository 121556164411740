// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import BillTypeForm, {BillTypeFormType} from '@shared/modules/Billing/forms/BillTypeForm';

const useUpdateBillTypeMutation = ({
  billTypeForm,
  onSuccess,
  onError,
}: {
  billTypeForm: BillTypeFormType;
  onSuccess: () => void;
  onError: (errors: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      billTypeForm: BillTypeForm.toForm(billTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateBillTypeMutation.mutation,
    variables: {
      billTypeForm: BillTypeForm.toMutation(form.values.billTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateBillTypeMutation.mutation = gql`
  mutation useUpdateBillTypeMutation($billTypeForm: BillTypeForm!) {
    response: updateBillType(billTypeForm: $billTypeForm) {
      ${gql.errors}
      billType {
        id
      }
    }
  }
`;

export default useUpdateBillTypeMutation;
