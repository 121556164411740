// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Emoji, Lifecycle, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import ConfirmationQuoteSuccess from 'modules/Customer/Project/Confirmation/ConfirmationQuoteSuccess';
import ConfirmationStepHeader from 'modules/Customer/Project/Confirmation/components/ConfirmationStepHeader';
import ProjectPage from 'modules/Customer/Project/components/ProjectPage';
import DocumentPdfView from 'modules/Document/components/DocumentPdfView';
import PreviewDocument from 'modules/Document/components/PreviewDocument';
import ProjectConfirmationDocument from 'modules/Project/Confirmation/components/ProjectConfirmationDocument';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  flex: 1;
  width: ${(props) => (props.mobile ? '100%' : '796px')};
  padding-horizontal: ${(props) => (props.mobile ? 20 : 0)}px;
`;

const Content = Styled.View`
  align-self: stretch;
  padding-top: 60px;
  padding-bottom: 90px;
`;

const Row = Styled.View`
  margin-top: 30px;
`;

const Section = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const Name = Styled.H6`
  ${fontWeight(700)}
`;

const Title = Styled.H3`
  ${fontWeight(900)}
  color: ${colors.gray.primary};
`;

const getSignatureDate = ({project}) => {
  const {document} = project.confirmation;
  const date = _.get(document, 'documentSignatures[0].signature.date');
  return Datetime.toFormDate(date);
};

const getSignatureImage = ({project}) => {
  const {document} = project.confirmation;
  return _.get(document, 'documentSignatures[0].signature.image');
};

const getSignatureText = ({project}) => {
  const {document} = project.confirmation;
  return getSignatureImage({project}) || _.get(document, 'documentSignatures[0].signature.text');
};

const hasInventoryDocument = ({project}) => {
  const {confirmation} = project;
  const step = confirmation.steps.find(
    (step) => step.kind === ConfirmationStepKind.CONFIRMATION_STEP_SIGN_INVENTORY,
  );
  return step && confirmation.inventoryWithSignature;
};

const hasConfirmationLetter = ({project}) => {
  const {confirmation} = project;
  const step = confirmation.steps.find(
    (step) => step.kind === ConfirmationStepKind.CONFIRMATION_STEP_SIGN_DOCUMENT,
  );
  return step && confirmation.document;
};

const getSubtitleText = ({responsive}) => {
  if (responsive.mobile) {
    return 'You will receive an email with your signed document(s). If you have any questions, please contact us by phone or email.';
  } else {
    return 'You will receive an email with your signed document(s).\nIf you have any questions, please contact us by phone or email.';
  }
};

const isCorrectPage = ({project}) => {
  return project.confirmation.isComplete;
};

const onMountOrUpdate = ({navigator, project}) => {
  if (!isCorrectPage({project})) {
    navigator.push(`/0/${project.organization.slug}/projects/${project.uuid}/confirmation`);
  }
};

// If the next step of the confirmation is not to save credit card,
// redirect the customer to the show confirmation page.
const Redirect = ({children, navigator, project}) => (
  <Lifecycle
    onMount={() => onMountOrUpdate({navigator, project})}
    onUpdate={() => onMountOrUpdate({navigator, project})}
  >
    {isCorrectPage({project}) ? children : null}
  </Lifecycle>
);

const SuccessConfirmationProjectPageContent = ({project}) => {
  const responsive = useResponsive();

  if (project.isCancelled) {
    return (
      <Container>
        <Section
          sectionIndex={0}
          style={{
            marginBottom: 20,
          }}
        >
          <Row>
            <Section>
              <Emoji component={EmojiText} name={'pencil'} />
              <Name>Confirmation letter</Name>
            </Section>
            <Space height={10} />
            <Section>
              <Title>This confirmation has expired</Title>
            </Section>
          </Row>
        </Section>
      </Container>
    );
  }
  return (
    <Content>
      <ConfirmationStepHeader
        title={'Your move is confirmed'}
        subtitle={getSubtitleText({responsive})}
        confirmation={project.confirmation}
      />
      {hasConfirmationLetter({project}) && (
        <Row>
          <Section>
            <Emoji component={EmojiText} name={'pencil'} />
            <Name>Confirmation letter</Name>
          </Section>
          <PreviewDocument
            canFullscreen
            printLink={`/0/${project.organization.slug}/projects/${project.uuid}/confirmation/document/print`}
            title={'Confirmation Letter'}
            style={{marginTop: 10}}
          >
            <ProjectConfirmationDocument
              project={project}
              values={{
                date: getSignatureDate({project}),
                image: getSignatureImage({project}),
                text: getSignatureText({project}),
              }}
            />
          </PreviewDocument>
        </Row>
      )}
      {hasInventoryDocument({project}) && (
        <Row>
          <Section>
            <Emoji component={EmojiText} name={'pencil'} />
            <Name>Inventory Review</Name>
          </Section>
          <PreviewDocument
            canFullscreen
            printLink={
              `/0/${project.organization.slug}/documents` +
              `/${project.confirmation.inventoryWithSignature.uuid}`
            }
            title={'Itemized Inventory'}
            style={{marginTop: 10}}
          >
            <DocumentPdfView document={project.confirmation.inventoryWithSignature} />
          </PreviewDocument>
        </Row>
      )}
    </Content>
  );
};

const SuccessConfirmationProjectPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <ProjectPage
    fetchPolicy={'network-only'}
    variables={{uuid}}
    query={SuccessConfirmationProjectPage.query}
  >
    {({responsive, navigator, data}) => (
      <Redirect navigator={navigator} project={data.project}>
        <Wrapper>
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {data.project.confirmation.isV2 ? (
              <ConfirmationQuoteSuccess
                confirmation={data.project.confirmation}
                title={'Your move is confirmed!'}
                subtitle={getSubtitleText({responsive})}
                isCancelled={data.project.isCancelled}
                cancelledMessage={'This confirmation has expired'}
              />
            ) : (
              <Container {...responsive}>
                <SuccessConfirmationProjectPageContent project={data.project} />
              </Container>
            )}
          </ScrollView>
        </Wrapper>
      </Redirect>
    )}
  </ProjectPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
SuccessConfirmationProjectPage.query = gql`
  ${ConfirmationQuoteSuccess.fragment}
  ${DocumentPdfView.fragment}
  ${ProjectPage.fragment}
  ${ProjectConfirmationDocument.fragment}

  query SuccessConfirmationProjectPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      isCancelled
      confirmation {
        id
        isComplete
        isV2
        billOfLading {
          id
          uuid
          ...DocumentPdfView
        }
        inventoryWithSignature {
          id
          uuid
          ...DocumentPdfView
        }
        document {
          id
          template
          documentSignatures {
            id
            documentId
            signatureId
            signature {
              id
              date
              kind
              image
              text
            }
          }
        }
        steps {
          kind
        }
        ...ConfirmationQuoteSuccess
      }
      organization {
        id
        name
      }
      ...ProjectPage
      ...ProjectConfirmationDocument
    }
  }
`;

export default SuccessConfirmationProjectPage;
