// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {
  DropdownInput,
  Styled,
  Space,
  DateInput,
  Icon,
  Drawer,
  ScrollView,
  Loading,
} from '@supermove/components';
import {CreateTrainingProjectForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SourceKind from '@shared/modules/Project/enums/SourceKind';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ValueFields from 'modules/Organization/Settings/TrainingProject/components/ValueFields';
import useCreateTrainingProjectMutation from 'modules/Organization/Settings/TrainingProject/logic/useCreateTrainingProjectMutation';
import JobValueFields from 'modules/Project/Billing/components/JobValueFields';
import ProjectValueFields from 'modules/Project/Billing/components/ProjectValueFields';

const DrawerContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
  width: ${({responsive}) => (responsive.mobile ? '100%' : '424px')};
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 16px;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
`;

const BodyContainer = Styled.View`
  flex: 1;
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
  padding-horizontal: 16px;
  padding-vertical: 12px;
  background-color: ${colors.white};
`;

const CloseButton = Styled.ButtonV2`
`;

const HeaderText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.primary};
`;

const DateRangeToggleButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const DateRangeToggleText = Styled.Text`
  ${Typography.Label2}
  color: ${({color}) => color};
`;

const DateRangeRow = Styled.View`
  flex-direction: row;
  z-index: ${({index}) => 100 - index};
`;

const handleToggleDateRange = ({form, field, isEstimatedRange}) => {
  // When toggling the date, we reset the current values that we're toggling away
  // from, but the date value and the startDate value get passed to each other.
  if (isEstimatedRange) {
    form.setFieldValue(`${field}.isEstimatedRange`, false);
    form.setFieldValue(`${field}.date`, _.get(form.values, `${field}.startDate`));
    form.setFieldValue(`${field}.startDate`, '');
    form.setFieldValue(`${field}.endDate`, '');
  } else {
    form.setFieldValue(`${field}.isEstimatedRange`, true);
    form.setFieldValue(`${field}.startDate`, _.get(form.values, `${field}.date`));
    form.setFieldValue(`${field}.date`, '');
  }
};

const DateRangeToggle = ({form, field, isEstimatedRange}) => {
  return (
    <DateRangeToggleButton onPress={() => handleToggleDateRange({form, field, isEstimatedRange})}>
      <Icon
        source={isEstimatedRange ? Icon.Trash : Icon.Plus}
        size={10}
        color={colors.blue.interactive}
      />
      <Space width={5} />
      <DateRangeToggleText color={colors.blue.interactive}>End Date</DateRangeToggleText>
    </DateRangeToggleButton>
  );
};

const DateFields = ({form}) => {
  const isEstimatedRangeField = 'createTrainingProjectForm.isEstimatedRange';
  const dateField = 'createTrainingProjectForm.date';
  const startDateField = 'createTrainingProjectForm.startDate';
  const endDateField = 'createTrainingProjectForm.endDate';
  const isEstimatedRange = _.get(form.values, isEstimatedRangeField);

  return isEstimatedRange ? (
    <DateRangeRow index={3}>
      <FieldInput.Memoized
        {...form}
        component={DateInput}
        name={startDateField}
        label={'Date'}
        input={{
          placeholder: 'Start date',
          setFieldValue: form.setFieldValue,
          isPortaled: true,
          style: {width: '100%'},
        }}
        style={{flex: 1}}
      />
      <Space width={8} />
      <FieldInput
        {...form}
        component={DateInput}
        name={endDateField}
        action={
          <React.Fragment>
            <Space style={{flex: 1}} />
            <DateRangeToggle form={form} field={'createTrainingProjectForm'} isEstimatedRange />
          </React.Fragment>
        }
        input={{
          placeholder: 'End date',
          setFieldValue: form.setFieldValue,
          isPortaled: true,
          position: 'bottom-end',
          style: {width: '100%'},
          minDate: _.get(form.values, startDateField),
        }}
        style={{flex: 1}}
      />
    </DateRangeRow>
  ) : (
    <FieldInput
      {...form}
      index={3}
      component={DateInput}
      name={dateField}
      label={'Date'}
      action={
        <DateRangeToggle form={form} field={'createTrainingProjectForm'} isEstimatedRange={false} />
      }
      input={{
        placeholder: 'Enter date',
        setFieldValue: form.setFieldValue,
        isPortaled: true,
        style: {
          width: '100%',
        },
      }}
      style={{
        flex: 1,
      }}
    />
  );
};

const getStatusDropdownOptions = ({projectType, organization}) => {
  const statuses = [];
  if (!projectType) {
    return [];
  }
  const isConfirmationEnabled = projectType.isEnabledConfirmationStepsForConfirmation;
  if (isConfirmationEnabled) {
    statuses.push('LEAD');
  }
  if (organization.features.isEnabledProjectHoldStatus) {
    statuses.push('HOLD');
  }
  statuses.push('BOOKED');
  return statuses.map((status) => ({
    label: _.startCase(_.toLower(status)),
    value: status,
  }));
};

const getProjectTypeForId = ({projectTypes, projectTypeId}) => {
  return _.find(projectTypes, (projectType) => {
    return projectType.id === projectTypeId;
  });
};

const getJobTypes = ({projectTypes, projectTypeId}) => {
  const projectType = _.find(projectTypes, (projectType) => {
    return String(projectType.id) === String(projectTypeId);
  });
  return projectType.jobTypes;
};

const CreateTrainingProjectDrawerFields = ({form, organization, projectTypes}) => {
  const jobTypes = getJobTypes({
    projectTypes,
    projectTypeId: form.values.createTrainingProjectForm.projectTypeId,
  });

  const projectTypeField = 'createTrainingProjectForm.projectTypeId';
  const jobTypeField = 'createTrainingProjectForm.jobTypeId';
  const jobStatusField = 'createTrainingProjectForm.jobStatus';
  const jobValueFormsField = 'createTrainingProjectForm.jobValueForms';
  const projectValueFormsField = 'createTrainingProjectForm.projectValueForms';

  return (
    <React.Fragment>
      <FieldInput
        {...form}
        component={DropdownInput}
        name={projectTypeField}
        label={'Project Type'}
        index={0}
        input={{
          required: !_.get(form.values, projectTypeField),
          options: projectTypes.map((projectType) => ({
            label: projectType.name,
            value: projectType.id,
          })),
          placeholder: 'Select project type',
          setFieldValue: (name, value) => {
            form.setFieldValue(name, value);
            form.setFieldValue(jobTypeField, null);
          },
          style: {
            flex: 1,
          },
          onChangeValue: () => {
            form.setFieldValue(jobValueFormsField, []);
          },
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        component={DropdownInput}
        name={jobStatusField}
        label={'Project Status'}
        index={1}
        input={{
          required: !_.get(form.values, jobStatusField),
          options: getStatusDropdownOptions({
            projectType: getProjectTypeForId({
              projectTypes,
              projectTypeId: _.get(form.values, projectTypeField),
            }),
            organization,
          }),
          placeholder: 'Select project status',
          setFieldValue: form.setFieldValue,
          style: {
            flex: 1,
          },
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        component={DropdownInput}
        name={jobTypeField}
        label={'Job Type'}
        index={2}
        input={{
          required: !_.get(form.values, jobTypeField),
          options: jobTypes.map((jobType) => ({label: jobType.name, value: jobType.id})),
          placeholder: 'Select job type',
          setFieldValue: form.setFieldValue,
          style: {
            flex: 1,
          },
        }}
      />
      <Space height={16} />
      <DateFields form={form} />
      <Space height={16} />
      <ProjectValueFields
        index={4}
        projectTypeField={projectTypeField}
        valueFormsField={projectValueFormsField}
        form={form}
      >
        {({index, valueForms, valueFormsField, form, startingValueFormIndex}) => (
          <ValueFields
            index={index}
            valueForms={valueForms}
            field={valueFormsField}
            form={form}
            startingValueFormIndex={startingValueFormIndex}
          />
        )}
      </ProjectValueFields>
      <JobValueFields
        index={5}
        jobTypeField={jobTypeField}
        valueFormsField={jobValueFormsField}
        form={form}
      >
        {({index, valueForms, valueFormsField, form, startingValueFormIndex}) => (
          <ValueFields
            index={index}
            valueForms={valueForms}
            field={valueFormsField}
            form={form}
            startingValueFormIndex={startingValueFormIndex}
          />
        )}
      </JobValueFields>
    </React.Fragment>
  );
};

const Header = ({headerText, handleClose}) => {
  return (
    <HeaderContainer>
      <HeaderText>{headerText}</HeaderText>
      <CloseButton onPress={handleClose}>
        <Icon source={Icon.Times} size={20} color={colors.gray.primary} />
      </CloseButton>
    </HeaderContainer>
  );
};

const Footer = ({submitting, handleSubmit, handleClose}) => {
  return (
    <FooterContainer>
      <Button
        color={colors.white}
        textColor={colors.gray.secondary}
        onPress={handleClose}
        isDisabled={submitting}
        text={'Cancel'}
      />
      <Space width={8} />
      <Button
        onPress={handleSubmit}
        isDisabled={submitting}
        isSubmitting={submitting}
        text={'Create'}
      />
    </FooterContainer>
  );
};

const CreateTrainingProjectContent = ({data, onSuccess, handleClose}) => {
  const {organizationForCreateProject} = data.viewer;
  const {moveProjectTypes, source} = organizationForCreateProject;
  const initialProjectType = moveProjectTypes[0];
  const createTrainingProjectForm = CreateTrainingProjectForm.new({
    creatorId: data.viewer.id,
    projectTypeId: initialProjectType.id,
    sourceId: source.id,
    organizationId: organizationForCreateProject.id,
  });
  const {form, submitting, handleSubmit} = useCreateTrainingProjectMutation({
    createTrainingProjectForm,
    onSuccess: ({project}) => {
      onSuccess({projectUuid: project.uuid});
    },
    onError: (error) => console.log(error),
  });

  return (
    <React.Fragment>
      <BodyContainer>
        <ScrollView contentContainerStyle={{padding: 16}}>
          <CreateTrainingProjectDrawerFields
            form={form}
            organization={organizationForCreateProject}
            projectTypes={moveProjectTypes}
          />
        </ScrollView>
      </BodyContainer>
      <Footer submitting={submitting} handleSubmit={handleSubmit} handleClose={handleClose} />
    </React.Fragment>
  );
};

const CreateTrainingProjectDrawer = ({handleClose, isOpen, onSuccess}) => {
  const responsive = useResponsive();
  const {loading, data} = useQuery(CreateTrainingProjectDrawer.query, {
    variables: {
      sourceKind: SourceKind.OFFICE_TRAINING_PROJECT,
    },
  });
  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      <DrawerContainer responsive={responsive}>
        <Header headerText={'Create Training Project'} handleClose={handleClose} />
        <Loading loading={loading} as={PageLoadingIndicator}>
          {() => (
            <CreateTrainingProjectContent
              data={data}
              onSuccess={onSuccess}
              handleClose={handleClose}
            />
          )}
        </Loading>
      </DrawerContainer>
    </Drawer>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
CreateTrainingProjectDrawer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

CreateTrainingProjectDrawer.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateTrainingProjectDrawer.query = gql`
  query CreateTrainingProjectDrawer($sourceKind: String!) {
    ${gql.query}
    viewer {
      id
      organizationForCreateProject {
        id
        moveProjectTypes: projectTypesForCategory(category: "MOVE") {
          id
          name
          isEnabledConfirmationStepsForConfirmation
          jobTypes {
            id
            name
          }
        }
        source(sourceKind: $sourceKind) {
          id
        }
        features {
          isEnabledProjectHoldStatus: isEnabled(feature: "PROJECT_HOLD_STATUS")
        }
      }
    }
  }
`;

export default CreateTrainingProjectDrawer;
