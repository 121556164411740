// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePagination, useNavigationDOM} from '@supermove/hooks';
import {Location, Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/TableV2Deprecated';
import EditShipmentStatusDropdown from 'modules/Dispatch/LongDistance/components/EditShipmentStatusDropdown';

const Container = Styled.View`
`;

const TableContainer = Styled.View`
  min-width: 100%;
`;

const HeaderText = Styled.Text`
  ${Typography.Label2}
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const LinkButton = Styled.ButtonV2`
`;

const LinkButtonText = Styled.Text`
  ${Typography.Link2}
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const getDisplayDate = (date) => {
  return Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE);
};

const getShipmentsTableColumnDefinitions = ({refetch, navigator}) => {
  return [
    // Status
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Status</HeaderText>;
      },
      cellContent: ({item: shipment}) => {
        return (
          <EditShipmentStatusDropdown
            shipment={shipment}
            onSuccess={() => {
              refetch();
            }}
          />
        );
      },
    },
    // Project Id
    {
      flex: 4,
      headerContent: () => {
        return <HeaderText>Project Name</HeaderText>;
      },
      cellContent: ({item: shipment}) => {
        return (
          <Container style={{width: 'fit-content'}}>
            <LinkButton
              style={{width: 'fit-content'}}
              onPress={() => navigator.push(`/storage/projects/${shipment.project.uuid}`)}
            >
              <LinkButtonText>{Project.getName(shipment.project)}</LinkButtonText>
            </LinkButton>
          </Container>
        );
      },
    },
    // Customer
    {
      flex: 4,
      headerContent: () => {
        return <HeaderText>Customer</HeaderText>;
      },
      cellContent: ({item: shipment}) => {
        return (
          <Container style={{width: 'fit-content'}}>
            <LinkButton
              style={{width: 'fit-content'}}
              onPress={() => {
                navigator.push(`/clients/${shipment.project.client.uuid}`);
              }}
            >
              <LinkButtonText>{shipment.project.client.name}</LinkButtonText>
            </LinkButton>
          </Container>
        );
      },
    },
    // Weight
    {
      flex: 3,
      headerContent: () => {
        return <HeaderText>Weight</HeaderText>;
      },
      cellContent: ({item: shipment}) => {
        return <Text>{shipment.project.weight} lbs</Text>;
      },
    },
    // Project Start Date
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Date In</HeaderText>;
      },
      cellContent: ({item: shipment}) => {
        return (
          <Text>{`${
            shipment.project.startDate ? getDisplayDate(shipment.project.startDate) : '-'
          }`}</Text>
        );
      },
    },
    // Project End Date
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Date Out</HeaderText>;
      },
      cellContent: ({item: shipment}) => {
        return (
          <Text>{`${
            shipment.project.endDate ? getDisplayDate(shipment.project.endDate) : '-'
          }`}</Text>
        );
      },
    },
    // Origin
    {
      flex: 3,
      headerContent: () => {
        return <HeaderText>Origin</HeaderText>;
      },
      cellContent: ({item: shipment}) => {
        return (
          <Text style={{width: 'fit-content'}}>
            {_.get(shipment, 'project.startLocation')
              ? Location.getDisplayLocation(shipment.project.startLocation)
              : '-'}
          </Text>
        );
      },
    },
    // Destination
    {
      flex: 3,
      headerContent: () => {
        return <HeaderText>Destination</HeaderText>;
      },
      cellContent: ({item: shipment}) => {
        return (
          <Text style={{width: 'fit-content'}}>
            {_.get(shipment, 'project.endLocation')
              ? Location.getDisplayLocation(shipment.project.endLocation)
              : '-'}
          </Text>
        );
      },
    },
  ];
};

const StorageShipmentsTable = ({shipments, pagination, refetch, loading}) => {
  const {navigator} = useNavigationDOM();

  return (
    <ScrollView>
      <Container style={{minHeight: 50, flex: 1}}>
        <ScrollView horizontal contentContainerStyle={{flex: 1}}>
          <TableContainer>
            <Table
              columnDefinitions={getShipmentsTableColumnDefinitions({
                refetch,
                navigator,
              })}
              emptyStateText='No shipments to display'
              items={shipments}
              itemKey={'id'}
              loading={loading}
            />
          </TableContainer>
        </ScrollView>
      </Container>
      <Space height={40} />
      <PaginationBar pagination={pagination} />
      <Space height={70} />
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageShipmentsTable.fragment = gql`
  ${usePagination.fragment}
  ${EditShipmentStatusDropdown.fragment}
  ${Location.getDisplayLocation.fragment}
  ${Project.getName.fragment}

  fragment StorageShipmentsTable on Shipment {
    id
    project {
      id
      uuid
      name
      weight
      startDate
      endDate
      identifier
      client {
        id
        uuid
        name
      }
      startLocation {
        id
        ...Location_getDisplayLocation
      }
      endLocation {
        id
        ...Location_getDisplayLocation
      }
      ...Project_getName
    }
    ...EditShipmentStatusDropdown
  }
`;

export default StorageShipmentsTable;
