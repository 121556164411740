// Libraries
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// Components
import Button from '@shared/design/components/Button';
import useRecordPaymentMutation from '@shared/modules/Payment/hooks/useRecordPaymentMutation';
import RecordPaymentForm from '@shared/modules/Storage/forms/RecordPaymentForm';
import RecordPaymentFields from 'modules/Storage/components/RecordPaymentFields';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: ${(props) => (props.mobile ? '100%' : 400)}px;
  background-color: ${colors.white};
  border-radius: 4px;
  padding-horizontal: 24px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading2}
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Subtitle = Styled.Text`
  ${Typography.Body}
`;

const RecordPaymentHeader = () => {
  return (
    <React.Fragment>
      <TitleText>Record Payment</TitleText>
      <Space height={16} />
      <Subtitle>
        Add a payment name and enter an amount. This payment should have happened already.
      </Subtitle>
      <Space height={16} />
    </React.Fragment>
  );
};

const RecordPaymentFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <Actions>
      <Button
        text={'Cancel'}
        color={colors.white}
        textColor={colors.gray.secondary}
        onPress={handleClose}
        isDisabled={submitting}
      />
      <Space width={8} />
      <Button
        text={'Save'}
        color={submitting ? colors.gray.border : colors.blue.interactive}
        onPress={handleSubmit}
        isDisabled={submitting}
        isSubmitting={submitting}
      />
    </Actions>
  );
};

const RecordPaymentModal = ({invoice, refetch, isOpen, handleClose}) => {
  const paymentForm = RecordPaymentForm.new({
    name: `Payment for Invoice ${invoice.identifier}`,
    invoice,
  });
  const {form, submitting, handleSubmit} = useRecordPaymentMutation({
    paymentForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log(errors);
    },
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ModalContainer>
          <Space height={24} />
          <RecordPaymentHeader />
          <Space height={4} />
          <RecordPaymentFields
            form={form}
            field={'paymentForm'}
            organization={invoice.organization}
          />
          <Space height={16} />
          <RecordPaymentFooter
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            submitting={submitting}
          />
          <Space height={24} />
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RecordPaymentModal.fragment = gql`
  ${RecordPaymentFields.fragment}

  fragment RecordPaymentModal on Invoice {
    id
    grandTotal
    organization {
      id
      ...RecordPaymentFields
    }
  }
`;

export default RecordPaymentModal;
