// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import DeletePaymentMethodForm from '@shared/modules/PaymentMethod/forms/DeletePaymentMethodForm';
import useDeletePaymentMethodMutation from '@shared/modules/PaymentMethod/hooks/useDeletePaymentMethodMutation';

const DeletePaymentMethodModal = ({
  isOpen,
  handleClose,
  paymentMethodId,
  paymentMethodName,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  paymentMethodId: string;
  paymentMethodName: string;
  refetch: () => void;
}) => {
  const deletePaymentMethodForm = DeletePaymentMethodForm.edit(paymentMethodId);
  const {handleSubmit, submitting} = useDeletePaymentMethodMutation({
    deletePaymentMethodForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DeleteModal
      title={`Remove '${paymentMethodName}'?`}
      subtitle={
        'This will remove the payment method from future use, but will remain on any past payments.'
      }
      isOpen={isOpen}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
      deleteButtonText={'Remove'}
    />
  );
};

export default DeletePaymentMethodModal;
