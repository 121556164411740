// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({threadId, userId}: any) => {
  return {
    threadId,
    userId,
    body: '',
    subject: '',
  };
};

const edit = withFragment(
  (comment) => ({
    commentId: (comment as any).id,
    threadId: (comment as any).threadId,
    userId: (comment as any).userId,
    subject: (comment as any).subject,
    body: (comment as any).body,
  }),
  gql`
    fragment CommentForm_edit on Comment {
      id
      threadId
      userId
      body
      subject
    }
  `,
);

const toForm = ({commentId, threadId, userId, body, subject}: any) => ({
  commentId,
  threadId,
  userId,
  body,
  subject,
});

const toMutation = ({commentId, threadId, userId, body, subject}: any) => ({
  commentId,
  threadId,
  userId,
  body,
  subject,
});

const CommentForm = {
  toForm,
  toMutation,
  new: _new,
  edit,
};

export default CommentForm;
