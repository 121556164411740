// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import WarehouseForm from '@shared/modules/Storage/forms/WarehouseForm';
import useDeleteWarehouseMutation from '@shared/modules/Storage/hooks/useDeleteWarehouseMutation';

const DeleteWarehouseModal = ({warehouse, deleteWarehouseModal, userId, refetch}) => {
  const warehouseForm = WarehouseForm.edit(warehouse, {userId});
  const {handleSubmit} = useDeleteWarehouseMutation({
    warehouseForm,
    onSuccess: () => {
      deleteWarehouseModal.handleClose();
      refetch();
    },
    onError: (errors) => console.log(errors),
  });
  return (
    <DeleteModal
      handleDelete={handleSubmit}
      handleClose={deleteWarehouseModal.handleClose}
      isOpen={deleteWarehouseModal.isOpen}
      title={`Remove '${warehouse.name}'?`}
      subtitle={'Any existing containers in this warehouse will not be affected.'}
      deleteButtonText={'Confirm'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteWarehouseModal.fragment = gql`
  ${WarehouseForm.edit.fragment}

  fragment DeleteWarehouseModal on Warehouse {
    id
    name
    ...WarehouseForm_edit
  }
`;
export default DeleteWarehouseModal;
