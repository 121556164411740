const _new = ({organizationId}: any) => {
  return {
    organizationId,
    data: null,
  };
};

const toForm = ({organizationId, data}: any) => ({
  organizationId,
  data,
});

const toMutation = ({organizationId, data}: any) => ({
  organizationId,
  data,
});

const BulkContainersForm = {
  new: _new,
  toForm,
  toMutation,
};

export default BulkContainersForm;
