// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useNavigationDOM, useEffect} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import TreeNavigation from '@shared/design/components/TreeNavigation';

const TreeNavigationContainer = Styled.View`
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const getTreeNavigationTabs = () => {
  return [
    {
      label: 'Accounting Items',
      value: 'items',
      valueKey: 'category',
    },
    {
      label: 'Accounting Customers',
      value: 'customers',
      valueKey: 'category',
    },
  ];
};

const AccountingImportsTreeNavigation = ({codatIntegrationUuid}) => {
  const {navigator, params} = useNavigationDOM();
  useEffect(() => {
    if (!['items', 'customers'].includes(params.category)) {
      navigator.replace(`${navigator.location.pathname}/items`);
    }
  }, [navigator, params.category]);

  return (
    <TreeNavigationContainer>
      <Space height={24} />
      <TreeNavigation
        width={200}
        handleSetValues={({category}) => {
          navigator.push(
            `/settings/company/accounting/${codatIntegrationUuid}/imports/${category}`,
          );
        }}
        navigationItems={getTreeNavigationTabs()}
        values={{category: params.category}}
      />
    </TreeNavigationContainer>
  );
};

export default AccountingImportsTreeNavigation;
