// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (shipment) => ({
    shipmentId: (shipment as any).id,
    status: (shipment as any).status,
  }),
  gql`
    fragment ShipmentStatusForm_edit on Shipment {
      id
      status
    }
  `,
);

const toForm = ({shipmentId, status}: any) => ({
  shipmentId,
  status,
});

const toMutation = ({shipmentId, status}: any) => ({
  shipmentId,
  status,
});

const ShipmentStatusForm = {
  edit,
  toForm,
  toMutation,
};

export default ShipmentStatusForm;
