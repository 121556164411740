import Expression, {CallExpression} from '@shared/ast/Expression';
import {FormulaEvaluator} from '@shared/formulas/src/FormulaEvaluator';

import {fixedArityOne} from '../library/arity';
import {ensureString} from '../library/types';

type FormulaDict = {
  identifier: string;
  astJson: string;
};
export default class ExtractVariableNamesPlugin {
  variableNames: {[key: string]: boolean};

  formulas: FormulaDict[];

  constructor(formulas?: FormulaDict[]) {
    this.formulas = formulas || [];
    this.variableNames = {};
  }

  evaluate = (expression: Expression) => {
    if (expression instanceof CallExpression) {
      if (['value', 'var'].includes(expression.name)) {
        const variableName = new FormulaEvaluator().evaluate(expression.args[0]);
        this.variableNames[variableName] = true;
      } else if (expression.name === 'formula') {
        const formula = this.formulas.find(
          (formula) => formula.identifier === expression.args[0].value,
        );
        if (formula) {
          const evaluator = new FormulaEvaluator({
            plugins: [new ExtractVariableNamesPlugin(this.formulas)],
            customFunctionImplementations: {
              formula: {
                call: (arg: any) => 0,
                checkArity: fixedArityOne('formula'),
                checkTypes: ensureString('formula'),
              },
            },
          });
          evaluator.evaluate(Expression.fromJSON(JSON.parse(formula.astJson)));
          const nestedVarsUsed = evaluator.plugins[0].getResult();
          nestedVarsUsed.forEach((varName: string) => {
            this.variableNames[varName] = true;
          });
        }
      }
    }
  };

  onVisit = (expression: Expression) => {
    this.evaluate(expression);
  };

  getResult = () => {
    return Object.keys(this.variableNames);
  };
}
