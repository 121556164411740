// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import ClientForm from '@shared/modules/Client/forms/ClientForm';

const edit = withFragment(
  (project) => ({
    projectId: (project as any).id,
    clientForm: ClientForm.edit((project as any).client),
    billingClientForm: ClientForm.edit((project as any).billingClient),
    isSameBillingClient: (project as any).clientId === (project as any).billingClientId,
  }),
  gql`
    ${ClientForm.edit.fragment}
    ${ClientForm.newFromProject.fragment}

    fragment ProjectClientsForm_edit on Project {
      id
      clientId
      billingClientId
      client {
        id
        ...ClientForm_edit
      }
      billingClient {
        id
        ...ClientForm_edit
      }
      ...ClientForm_newFromProject
    }
  `,
);

const toForm = ({projectId, clientForm, billingClientForm, isSameBillingClient}: any) => ({
  projectId,
  clientForm: ClientForm.toForm(clientForm),
  billingClientForm: ClientForm.toForm(billingClientForm),
  isSameBillingClient,
});

const toMutation = ({projectId, clientForm, billingClientForm, isSameBillingClient}: any) => ({
  projectId,
  clientForm: ClientForm.toMutation(clientForm),
  billingClientForm: ClientForm.toMutation(billingClientForm),
  isSameBillingClient,
});

const ProjectClientsForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectClientsForm;
