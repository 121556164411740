// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import BulkContainersForm from '@shared/modules/Storage/forms/BulkContainersForm';

const useUpsertBulkContainersMutation = ({bulkContainersForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      bulkContainersForm: BulkContainersForm.toForm(bulkContainersForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertBulkContainersMutation.mutation,
    variables: {
      bulkContainersForm: BulkContainersForm.toMutation(form.values.bulkContainersForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpsertBulkContainersMutation.mutation = gql`
  mutation UpsertBulkContainersMutation($bulkContainersForm: BulkContainersForm!) {
    response: upsertBulkContainers(bulkContainersForm: $bulkContainersForm) {
      ${gql.errors}
    }
  }
`;

export default useUpsertBulkContainersMutation;
