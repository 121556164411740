// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CommentForm from '@shared/modules/Comment/forms/CommentForm';

const useUpdateCommentMutation = ({commentForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      commentForm: CommentForm.toForm(commentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCommentMutation.mutation,
    variables: {
      commentForm: CommentForm.toMutation(form.values.commentForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCommentMutation.mutation = gql`
  ${CommentForm.edit.fragment}
  mutation useUpdateCommentMutation($commentForm: CommentForm!) {
    response: updateComment(commentForm: $commentForm) {
      ${gql.errors}
      comment {
        id
        ...CommentForm_edit
      }
    }
  }
`;

export default useUpdateCommentMutation;
