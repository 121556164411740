// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled, Tabs} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import JobForm from '@shared/modules/Job/forms/JobForm';
import ProjectJobAdditionalInfoBlock from 'modules/Project/Update/components/ProjectJobAdditionalInfoBlock';
import ProjectJobDeleteModal from 'modules/Project/Update/components/ProjectJobDeleteModal';
import ProjectJobDispatchInfoBlock from 'modules/Project/Update/components/ProjectJobDispatchInfoBlock';
import ProjectJobInternalInfoBlock from 'modules/Project/Update/components/ProjectJobInternalInfoBlock';
import ProjectJobJobInfoBlock from 'modules/Project/Update/components/ProjectJobJobInfoBlock';
import ProjectJobLocationsInfoBlock from 'modules/Project/Update/components/ProjectJobLocationsInfoBlock';
import ProjectJobVariablesInfoBlock from 'modules/Project/Update/components/ProjectJobVariablesInfoBlock';

const ActionsRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  margin-horizontal: 16px;
`;

const ButtonContainer = Styled.ButtonV2`
  flex-direction: row;
  border: 1px solid ${colors.gray.border};
  border-radius: 20px;
  align-items: center;
  padding-vertical: 4px;
  padding-horizontal: 12px;
  justify-content: center;
  background-color: ${colors.white};
`;

const ActionsButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${(props) => props.color};
`;

const ErrorSectionContainer = Styled.View`
  background-color: ${colors.red.accent};
  border: 1px solid ${colors.gray.border};
  border-radius: 8px;
  padding-horizontal: 12px;
  margin-horizontal: 16px;
  z-index: ${(props) => 100 - props.index};
`;

const SectionHeader = Styled.Text`
  ${Typography.Body3}
  flex-direction: row;
  align-items: center;
  color: ${colors.red.warning};
`;

const NewTagContainer = Styled.View`
  height: 20px;
  width: 42px;
  border-radius: 20px;
  background-color: ${colors.orange.accent};
  justify-content: center;
  align-items: center;
`;

const NewTagText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.orange.status};
`;

const TabTextContainer = Styled.View`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${({color}) => color};
  padding-vertical: 4px;
  padding-horizontal: 8px;
`;

const TabText = Styled.Text`
  ${Typography.Label4}
  color: ${({color}) => color};
`;

const getTabTextColor = ({tab, isSelected}) => {
  if (tab.hasErrors) {
    return colors.red.warning;
  }
  if (isSelected) {
    return colors.blue.interactive;
  }
  return colors.gray.primary;
};

const getTabBorderColor = ({tab, isSelected}) => {
  if (tab.hasErrors) {
    return colors.red.warning;
  }
  if (isSelected) {
    return colors.blue.interactive;
  }
  return colors.gray.tertiary;
};

const Tab = ({tab, isSelected, handlePress}) => {
  return (
    <Tabs.TabContainer
      onPress={handlePress}
      hasErrors={tab.hasErrors}
      isSelected={isSelected}
      style={{paddingHorizontal: 12}}
    >
      {tab.hasErrors && (
        <React.Fragment>
          <Icon source={Icon.ExclamationTriangle} size={14} color={colors.red.warning} />
          <Space width={8} />
        </React.Fragment>
      )}
      <TabTextContainer color={getTabBorderColor({tab, isSelected})}>
        <TabText color={getTabTextColor({tab, isSelected})}>{tab.label}</TabText>
      </TabTextContainer>
      {tab.isNewJob && (
        <React.Fragment>
          <Space width={8} />
          <NewTagContainer>
            <NewTagText>New</NewTagText>
          </NewTagContainer>
        </React.Fragment>
      )}
    </Tabs.TabContainer>
  );
};

const handleCopyJobForm = ({form, jobIndex}) => {
  const jobFormsField = `projectForm.jobForms`;
  const jobForms = _.get(form.values, jobFormsField);
  const firstJobForms = _.slice(jobForms, 0, jobIndex + 1);
  const lastJobForms = _.slice(jobForms, jobIndex + 1, jobForms.length);
  const jobForm = _.get(form.values, `${jobFormsField}.${jobIndex}`);
  const copiedForm = JobForm.copyJobForm(jobForm);
  const newJobForms = [...firstJobForms, copiedForm, ...lastJobForms];
  form.setFieldValue(jobFormsField, newJobForms);
};

const CopyButton = ({form, jobIndex, setSelectedIndex, selectedIndex, isDisabled}) => {
  const color = isDisabled ? colors.gray.tertiary : colors.blue.interactive;

  return (
    <ButtonContainer
      onPress={() => {
        handleCopyJobForm({form, jobIndex});
        setSelectedIndex(selectedIndex + 1);
      }}
      disabled={isDisabled}
    >
      <Icon source={Icon.CopyRegular} size={14} color={color} />
      <Space width={8} />
      <ActionsButtonText color={color}>Copy</ActionsButtonText>
    </ButtonContainer>
  );
};

const handleDeleteJobForm = ({form, jobIndex}) => {
  const jobFormsField = `projectForm.jobForms`;
  const jobForms = _.get(form.values, jobFormsField);
  const firstJobForms = _.slice(jobForms, 0, jobIndex); // Ignore index to remove jobForm
  const lastJobForms = _.slice(jobForms, jobIndex + 1, jobForms.length);
  const newJobForms = [...firstJobForms, ...lastJobForms];
  form.setFieldValue(jobFormsField, newJobForms);
};

// NOTE: We set up the delete button only for new jobs on the ProjectJobsModal.
const DeleteButton = ({onPress}) => {
  return (
    <React.Fragment>
      <ButtonContainer onPress={onPress}>
        <Icon source={Icon.TrashAltRegular} size={14} color={colors.red.warning} />
        <Space width={8} />
        <ActionsButtonText color={colors.red.warning}>Delete</ActionsButtonText>
      </ButtonContainer>
    </React.Fragment>
  );
};

const ErrorsSection = ({jobErrorsCount}) => {
  const numberOfErrorsText = pluralize('error', jobErrorsCount, true);

  return (
    <React.Fragment>
      <ErrorSectionContainer index={0}>
        <Space height={16} />
        <SectionHeader>
          {`Found ${numberOfErrorsText}. Please fix the errors in order to save.`}
        </SectionHeader>
        <Space height={16} />
      </ErrorSectionContainer>
      <Space height={8} />
    </React.Fragment>
  );
};

const ProjectJobModalBlock = ({block, index, jobField, form, project, isNewJob, organization}) => {
  switch (block.kind) {
    case 'MANAGER_PROJECT_JOB_JOB_INFO':
      return (
        <ProjectJobJobInfoBlock
          index={index}
          jobField={jobField}
          form={form}
          organization={project.organization}
          isNewJob={isNewJob}
        />
      );
    case 'MANAGER_PROJECT_JOB_DISPATCH_INFO':
      return (
        <ProjectJobDispatchInfoBlock
          index={index}
          jobField={jobField}
          form={form}
          organization={project.organization}
        />
      );
    case 'MANAGER_PROJECT_JOB_VARIABLES_INFO':
      return (
        <ProjectJobVariablesInfoBlock
          index={index}
          jobField={jobField}
          form={form}
          organization={project.organization}
          isEdit={_.get(form.values, `${jobField}.valueForms`).length >= 0}
        />
      );
    case 'MANAGER_PROJECT_JOB_LOCATIONS_INFO':
      return (
        <ProjectJobLocationsInfoBlock
          index={index}
          jobField={jobField}
          form={form}
          project={project}
        />
      );
    case 'MANAGER_PROJECT_JOB_ADDITIONAL_INFO':
      return (
        <ProjectJobAdditionalInfoBlock
          index={index}
          jobField={jobField}
          form={form}
          organization={project.organization}
        />
      );
    case 'MANAGER_PROJECT_JOB_INTERNAL_INFO':
      return (
        <ProjectJobInternalInfoBlock
          index={index}
          jobField={jobField}
          form={form}
          organization={project.organization}
        />
      );
    default:
      return null;
  }
};

const ProjectJobFormTabSlide = ({
  form,
  index,
  project,
  hasErrors,
  isNewJob,
  isAtMaxJobs,
  jobErrorsCount,
  jobField,
  scrollViewRef,
  setSelectedIndex,
  selectedIndex,
}) => {
  const projectJobDeleteModal = useModal();
  return (
    <React.Fragment>
      <ScrollView ref={scrollViewRef}>
        <Space height={8} />
        {hasErrors && <ErrorsSection jobErrorsCount={jobErrorsCount} />}
        <ActionsRow>
          <CopyButton
            form={form}
            jobIndex={index}
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
            isDisabled={isAtMaxJobs}
          />
          {isNewJob && (
            <React.Fragment>
              <Space width={8} />
              <DeleteButton onPress={projectJobDeleteModal.handleOpen} />
            </React.Fragment>
          )}
        </ActionsRow>
        <Space height={8} />
        {project.organization.managerProjectJobsModalBlock.blocks.map((block, blockIndex) => {
          return (
            <React.Fragment key={blockIndex}>
              <ProjectJobModalBlock
                block={block}
                index={index + blockIndex}
                jobField={jobField}
                form={form}
                project={project}
                isNewJob={isNewJob}
                organization={project.organization}
              />
              <Space height={8} />
            </React.Fragment>
          );
        })}
      </ScrollView>
      <ProjectJobDeleteModal
        onPress={() => {
          handleDeleteJobForm({form, jobIndex: index});
          setSelectedIndex(selectedIndex - 1);
        }}
        isOpen={projectJobDeleteModal.isOpen}
        handleClose={projectJobDeleteModal.handleClose}
      />
    </React.Fragment>
  );
};

const ProjectJobsContent = ({form, project, scrollViewRef, tabs, isAtMaxJobs}) => {
  const {jobForms} = form.values.projectForm;

  return (
    <React.Fragment>
      <Tabs
        tabs={tabs}
        TabComponent={Tab}
        tabBarStyle={{paddingHorizontal: 24}}
        style={{flex: 1}}
        disabledSwipe
      >
        {jobForms.map((jobForm, index) => {
          const jobFormDate = JobForm.getDisplayDate(jobForm);
          const {jobTypeName} = jobForm;
          const tabLabel = jobTypeName ? `${jobTypeName}: ${jobFormDate}` : jobFormDate;
          const jobField = `projectForm.jobForms.${index}`;
          const jobErrorsCount = _.size(_.get(form.errors, jobField) || 0);
          const hasErrors = jobErrorsCount > 0;
          const isNewJob = _.isNil(jobForm.jobId);

          return (
            <Tabs.Slide
              key={index}
              tab={{label: tabLabel, hasErrors, isNewJob}}
              style={{
                backgroundColor: colors.alpha(colors.gray.border, 0.5),
              }}
            >
              {tabs.selectedIndex === index && (
                <ProjectJobFormTabSlide
                  key={`jobForm.jobId.${index}`}
                  jobForm={jobForm}
                  index={index}
                  form={form}
                  project={project}
                  hasErrors={hasErrors}
                  isNewJob={isNewJob}
                  isAtMaxJobs={isAtMaxJobs}
                  jobErrorsCount={jobErrorsCount}
                  jobField={jobField}
                  scrollViewRef={scrollViewRef}
                  selectedIndex={tabs.selectedIndex}
                  setSelectedIndex={tabs.setSelectedIndex}
                />
              )}
            </Tabs.Slide>
          );
        })}
      </Tabs>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobsContent.fragment = gql`
  ${ProjectJobAdditionalInfoBlock.fragment}
  ${ProjectJobDispatchInfoBlock.fragment}
  ${ProjectJobInternalInfoBlock.fragment}
  ${ProjectJobJobInfoBlock.fragment}
  ${ProjectJobLocationsInfoBlock.fragment}

  fragment ProjectJobsContent on Project {
    id
    organization {
      id
      managerProjectJobsModalBlock: block(kind: "MANAGER_PROJECT_JOBS_MODAL") {
        blocks {
          kind
        }
      }
      ...ProjectJobAdditionalInfoBlock
      ...ProjectJobDispatchInfoBlock
      ...ProjectJobInternalInfoBlock
      ...ProjectJobJobInfoBlock
    }
    ...ProjectJobLocationsInfoBlock
  }
`;

export default ProjectJobsContent;
