// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (compensationReport) => ({
    compensationReportId: (compensationReport as any).id,
    userId: (compensationReport as any).userId,
    status: (compensationReport as any).status,
    isDeleted: (compensationReport as any).isDeleted,
    // Private
    identifier: (compensationReport as any).identifier,
  }),
  gql`
    fragment CompensationReportForm_edit on CompensationReport {
      id
      userId
      identifier
      status
      isDeleted
    }
  `,
);

const toForm = ({compensationReportId, userId, status, isDeleted, identifier}: any) => ({
  compensationReportId,
  userId,
  status,
  isDeleted,

  // Private
  identifier,
});

const toMutation = ({compensationReportId, userId, status, isDeleted}: any) => ({
  compensationReportId,
  userId,
  status,
  isDeleted,
});

const CompensationReportForm = {
  edit,
  toForm,
  toMutation,
};

export default CompensationReportForm;
