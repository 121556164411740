// Supermove
import {Currency} from '@supermove/utils';

const _new = ({billId, customerId, amount, name}: any) => ({
  billId,
  customerId,
  amount,
  name,
  description: '',
  method: '',
  paymentMethodId: null,
});

const toForm = ({billId, customerId, name, description, amount, method, paymentMethodId}: any) => ({
  billId,
  customerId,
  amount: Currency.toForm(amount),
  name,
  description,
  method,
  paymentMethodId,
});

const toMutation = ({
  billId,
  customerId,
  name,
  description,
  amount,
  method,
  paymentMethodId,
}: any) => ({
  billId,
  customerId,
  amount: Currency.toMutation(amount),
  name,
  description,
  method,
  paymentMethodId,
});

const BeginPaymentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default BeginPaymentForm;
