// Libraries
import * as Sentry from '@sentry/react';
import React from 'react';

// Components
import ManagerApp from './ManagerApp';

// Assets
import '@supermove/styles/css/Global.css';
import '@supermove/styles/css/Avenir.css';
import '@supermove/styles/css/Rouge.css';

const AppWrapper = () => {
  return <ManagerApp />;
};

export default Sentry.withProfiler(AppWrapper);
