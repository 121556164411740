// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const ConditionalIconContainer = Styled.View`
  flex-direction: row;
  width: 24px;
  align-items: center;
  justify-content: center;
`;

const TooltipContentContainer = Styled.View``;

const ConditionalBillItemIcon = ({isVisible, billRuleName}) => {
  if (!isVisible) {
    return null;
  }
  return (
    <ConditionalIconContainer>
      <Space width={12} />
      <TextTooltip
        text={`This bill item was automatically added by the “${billRuleName}” bill rule.`}
      >
        <TooltipContentContainer>
          <Icon source={Icon.Calculator} color={colors.gray.secondary} size={12} />
        </TooltipContentContainer>
      </TextTooltip>
    </ConditionalIconContainer>
  );
};

export default ConditionalBillItemIcon;
