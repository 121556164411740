// Libraries
import React from 'react';

// App
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import {SidebarPage} from 'modules/App/components';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import {EmployeeSchedules} from 'modules/Schedule/components';

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const TitleSection = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Touchable = Styled.Touchable`
`;

const Title = Styled.H5`
  ${fontWeight(900)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H6`
  color: ${colors.gray.primary};
  padding-horizontal: 32px;
  ${fontWeight(500)}
`;

const Body = Styled.View`
  width: 100%;
  padding-horizontal: ${(props) => (props.mobile ? 0 : 30)}px;
  margin-bottom: 30px;
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.mobile ? 'center' : 'flex-start')};
  margin-bottom: 10px;
`;

const NavigationText = Styled.H6`
  margin-horizontal: 10px;
  letter-spacing: 0.5;
`;

const Button = Styled.Button`
  padding: 10px;
`;

const ButtonText = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Container = Styled.View`
  flex: 1;
`;

const getDatetimeToQuery = ({date}) => {
  if (date) {
    return Datetime.previousMonday(Datetime.fromDate(date));
  } else {
    return Datetime.previousMonday(Datetime.today);
  }
};

const getBackPath = ({date}) => {
  const query = Datetime.toDate(
    Datetime.previousMonday(getDatetimeToQuery({date})).subtract(7, 'days'),
  );
  return `/settings/staff/schedules?date=${query}`;
};

const getNextPath = ({date}) => {
  const query = Datetime.toDate(Datetime.previousMonday(getDatetimeToQuery({date})).add(7, 'days'));
  return `/settings/staff/schedules?date=${query}`;
};

const getDisplayDateRange = ({date}) => {
  const startDate = Datetime.convertToDisplayDate(getDatetimeToQuery({date}), 'ddd M/D/YY');
  const endDate = Datetime.convertToDisplayDate(
    getDatetimeToQuery({date}).add(6, 'days'),
    'ddd M/D/YY',
  );
  return `${startDate} - ${endDate}`;
};

const Navigation = ({date, navigator, responsive}) => (
  <Header {...responsive}>
    <Button onPress={() => navigator.push(getBackPath({date}))}>
      <ButtonText>{'<'}</ButtonText>
    </Button>
    <NavigationText>{getDisplayDateRange({date})}</NavigationText>
    <Button onPress={() => navigator.push(getNextPath({date}))}>
      <ButtonText>{'>'}</ButtonText>
    </Button>
  </Header>
);

const SchedulePage = () => {
  return (
    <SidebarPage selected={'settings'} query={SchedulePage.query}>
      {({responsive, navigator, params, data}) => (
        <Container>
          <StandardOfficeHeader title={'Settings'} />
          <ScrollView style={{flex: 1}}>
            <Space height={18} />
            <TitleSection>
              <Space width={22} />
              <Touchable activeOpacity={0.8} onPress={() => navigator.goBack()}>
                <Icon source={Icon.ArrowLeft} size={18} color={colors.gray.primary} />
              </Touchable>
              <Space width={18} />
              <Title>Schedule - Movers</Title>
            </TitleSection>
            <Space height={18} />
            <Line />
            <Space height={12} />
            <Subtitle>
              View schedules of your crew members by week. You can edit a crew member's availability
              by pressing a table cell.
            </Subtitle>
            <Space height={12} />
            <Body {...responsive}>
              <Navigation date={params.date} navigator={navigator} responsive={responsive} />
              <EmployeeSchedules
                date={params.date}
                organization={data.viewer.viewingOrganization}
              />
            </Body>
          </ScrollView>
        </Container>
      )}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SchedulePage.query = gql`
  ${EmployeeSchedules.fragment}

  query SchedulePage {
    ${gql.query}
    viewer {
      id
      firstName
      ...EmployeeSchedules
    }
  }
`;

export default SchedulePage;
