// Libraries
import React from 'react';

// App
import {Modal, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import ReportsDayInfo from './ReportsDayInfo';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  flex: 1;
  max-height: 230px;
  width: 280px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding-horizontal: 14px;
  padding-vertical: 24px;
`;

const Header = Styled.Text`
  ${Typography.Heading5};
  padding-horizontal: 10px;
`;

const Button = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  margin-horizontal: 10px;
  background-color: ${colors.blue.interactive};
`;

const ButtonText = Styled.Text`
  ${Typography.Label2};
  color: ${colors.white};
`;

const ReportsDayInfoModal = ({handleClose, isOpen, calendarDay}) => {
  if (!calendarDay) {
    return null;
  }

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ModalContainer>
          <Header>{Datetime.convertToDisplayDate(calendarDay.date, 'MMMM Do, YYYY')}</Header>
          <ReportsDayInfo calendarDay={calendarDay} />
          <Button color={colors.white} onPress={handleClose}>
            <ButtonText color={colors.gray.secondary}>Got it</ButtonText>
          </Button>
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

export default ReportsDayInfoModal;
