// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DashboardLinkedToGlobalDashboardForm from '@shared/modules/Reports/forms/DashboardLinkedToGlobalDashboardForm';

const useUpdateDashboardLinkedToGlobalDashboardMutation = ({
  dashboardLinkedToGlobalDashboardForm,
  onSuccess,
  onError,
}: {
  dashboardLinkedToGlobalDashboardForm: ReturnType<
    typeof DashboardLinkedToGlobalDashboardForm.edit
  >;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      dashboardLinkedToGlobalDashboardForm: DashboardLinkedToGlobalDashboardForm.toForm(
        dashboardLinkedToGlobalDashboardForm,
      ),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateDashboardLinkedToGlobalDashboardMutation.mutation,
    variables: {
      dashboardLinkedToGlobalDashboardForm: DashboardLinkedToGlobalDashboardForm.toMutation(
        form.values.dashboardLinkedToGlobalDashboardForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateDashboardLinkedToGlobalDashboardMutation.mutation = gql`
  mutation useUpdateDashboardLinkedToGlobalDashboardMutation($dashboardLinkedToGlobalDashboardForm: DashboardLinkedToGlobalDashboardForm!) {
    response: updateDashboardLinkedToGlobalDashboard(dashboardLinkedToGlobalDashboardForm: $dashboardLinkedToGlobalDashboardForm) {
      ${gql.errors}
      dashboard {
        id
      }
    }
  }
`;

export default useUpdateDashboardLinkedToGlobalDashboardMutation;
