import _ from 'lodash';

// Supermove
import {colors} from '@supermove/styles';

const PAID_OUT = 'PAID_OUT';
const NOT_PAID_OUT = 'NOT_PAID_OUT';

const UNPAID_OPTION = {
  value: 'NOT_PAID_OUT',
  label: 'Unpaid',
  backgroundColor: colors.yellow.accent,
  textColor: colors.yellow.status,
};

const PAID_OPTION = {
  value: 'PAID_OUT',
  label: 'Paid',
  backgroundColor: colors.green.accent,
  textColor: colors.green.status,
};

const STATUS_OPTION_MAP = {
  [NOT_PAID_OUT]: UNPAID_OPTION,
  [PAID_OUT]: PAID_OPTION,
};

const getDisplayStatus = (status: any) => {
  return _.startCase(_.toLower(status));
};

export default {
  PAID_OUT,
  NOT_PAID_OUT,

  VALUES: [NOT_PAID_OUT, PAID_OUT],

  STATUS_OPTION_MAP,
  getDisplayStatus,
};
