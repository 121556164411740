// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (project) => ({
    projectId: (project as any).id,
    status: (project as any).status,
  }),
  gql`
    fragment ProjectStatusForm_edit on Project {
      id
      status
    }
  `,
);

const toForm = ({projectId, status}: any) => ({
  projectId,
  status,
});

const toMutation = ({projectId, status}: any) => ({
  projectId,
  status,
});

const ProjectStatusForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectStatusForm;
