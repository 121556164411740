// Supermove
import {useReducer} from '@supermove/hooks';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';

const SET_LOT = 'SET_LOT';
const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
const SET_PAGINATION = 'SET_PAGINATION';

const reducer = (state: any, action: any) => {
  // We reset to page 1 on any filter change
  const stateWithPaginationReset = {...state, pagination: PaginationBar.DEFAULT_PAGINATION};

  switch (action.type) {
    case SET_LOT:
      return {
        ...stateWithPaginationReset,
        lotNumbers: [action.payload.lotNumber],
        colors: [action.payload.color],
      };
    case SET_SEARCH_QUERY:
      return {...stateWithPaginationReset, searchQuery: action.payload};
    case SET_PAGINATION:
      return {
        ...stateWithPaginationReset,
        pagination: {
          page: action.payload,
          resultsPerPage: PaginationBar.DEFAULT_PAGINATION.resultsPerPage,
        },
      };
    default:
      return stateWithPaginationReset;
  }
};

const useFilteredItemsReducer = (initialFilters: any) => {
  const [filters, dispatch] = useReducer(
    (state: any, action: any) => reducer(state, action),
    initialFilters,
  );

  return {
    filters,
    dispatch,
    actions: {
      SET_LOT,
      SET_SEARCH_QUERY,
      SET_PAGINATION,
    },
  };
};

export default useFilteredItemsReducer;
