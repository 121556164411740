// Libraries
import React from 'react';

// Supermove
import {Lifecycle} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import ConfirmationQuoteStepHeader from 'modules/Customer/Project/Confirmation/components/ConfirmationQuoteStepHeader';
import CustomerDocumentV2Sign from 'modules/Document/DocumentV2/CustomerDocumentV2Sign';

const getSubtitleText = ({responsive, submitButtonText}) =>
  `Please read through the document below and press "${submitButtonText || 'Continue'}".${
    responsive.mobile ? ' ' : '\n'
  }You will be able to print a copy of the document on the last page.`;

const onMountOrUpdate = ({navigator, project, isCorrectPage, redirectRoute}) => {
  if (!isCorrectPage({project})) {
    navigator.push(redirectRoute);
  }
};

// If the next step of the confirmation is not to sign document,
// redirect the customer to the show confirmation page.
const Redirect = ({children, navigator, project, isCorrectPage, redirectRoute}) => (
  <Lifecycle onMount={() => onMountOrUpdate({navigator, project, isCorrectPage, redirectRoute})}>
    {isCorrectPage({project}) ? children : null}
  </Lifecycle>
);

const ConfirmationQuoteDocumentV2 = ({
  project,
  document,
  isComplete,
  nextIndex,
  stepCount,
  isCorrectPage,
  redirectRoute,
  navigator,
  responsive,
  submitButtonText,
  beforeSubmit,
}) => {
  const handleNavigate = () => {
    navigator.push(redirectRoute);
  };
  return (
    <Redirect
      navigator={navigator}
      isCorrectPage={isCorrectPage}
      project={project}
      redirectRoute={redirectRoute}
    >
      <CustomerDocumentV2Sign
        documentUuid={document.uuid}
        onSubmit={handleNavigate}
        HeaderComponent={ConfirmationQuoteStepHeader}
        headerComponentProps={{
          isComplete,
          nextIndex,
          stepCount,
          title: 'Review Document',
          subtitle: getSubtitleText({responsive, submitButtonText}),
          documentDisplayName: document.displayName,
        }}
        submitButtonText={submitButtonText}
        beforeSubmit={beforeSubmit}
      />
    </Redirect>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmationQuoteDocumentV2.fragment = gql`
  fragment ConfirmationQuoteDocumentV2 on Document {
    id
    displayName
    uuid
  }
`;

export default ConfirmationQuoteDocumentV2;
