// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';

// App
import CollapsibleContent from '@shared/design/components/CollapsibleContent';
import CommercialEquipmentsList from 'modules/CommercialCatalog/components/CommercialEquipmentsList';
import CommercialMaterialsList from 'modules/CommercialCatalog/components/CommercialMaterialsList';
import EditCommercialEquipmentModal from 'modules/CommercialCatalog/components/EditCommercialEquipmentModal';
import EditCommercialMaterialsModal from 'modules/CommercialCatalog/components/EditCommercialMaterialsModal';
import CommercialCatalogBillSection from 'modules/Job/V2/Move/components/CommercialCatalogBillSection';

const Container = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
  align-self: stretch;
`;

const PaddingContainer = Styled.View`
  padding: 16px;
`;

const getActions = ({
  handleOpenEditCommercialEquipmentModal,
  handleOpenEditCommercialMaterialsModal,
}) => {
  const actions = [
    {
      text: 'Edit equipment',
      onPress: () => handleOpenEditCommercialEquipmentModal(),
    },
    {
      text: 'Edit materials',
      onPress: () => handleOpenEditCommercialMaterialsModal(),
    },
  ];

  return actions;
};

const shouldShowEditButton = ({job, viewer}) => {
  if (job.isFinal) {
    return false;
  }
  if (job.isComplete && !viewer.viewingOrganization.features.isEnabledCanEditCompleteJob) {
    return false;
  }
  return true;
};

const MaterialsAndEquipmentsHeader = ({job, viewer, refetch}) => {
  const editCommericalEquipmentModal = useModal({
    name: 'Edit Commerical Equipment Modal',
    enableTracking: true,
  });
  const editCommericalMaterialsModal = useModal({
    name: 'Edit Commerical Materials Modal',
    enableTracking: true,
  });

  return (
    <React.Fragment>
      <CollapsibleContent
        title={'Equipment & Materials'}
        primaryActionText={shouldShowEditButton({job, viewer}) ? 'Edit' : null}
        primaryActionLeftIcon={Icon.Pen}
        primaryActionRightIcon={Icon.ChevronDown}
        primaryDropdownActions={getActions({
          handleOpenEditCommercialEquipmentModal: editCommericalEquipmentModal.handleOpen,
          handleOpenEditCommercialMaterialsModal: editCommericalMaterialsModal.handleOpen,
        })}
        isPrimaryActionDropdown
      >
        <PaddingContainer>
          <React.Fragment>
            <CommercialCatalogBillSection
              index={2}
              commercialCatalog={job.commercialCatalog}
              refetch={refetch}
            />
            <Space height={24} />
          </React.Fragment>
          <ScrollView horizontal>
            <CommercialEquipmentsList
              job={job}
              style={{
                flex: 1,
              }}
            />
            <Space height={16} />
            <CommercialMaterialsList
              job={job}
              style={{
                flex: 1,
              }}
            />
          </ScrollView>
        </PaddingContainer>
      </CollapsibleContent>
      <EditCommercialEquipmentModal
        isOpen={editCommericalEquipmentModal.isOpen}
        jobUuid={job.uuid}
        handleClose={editCommericalEquipmentModal.handleClose}
        refetch={refetch}
      />
      <EditCommercialMaterialsModal
        isOpen={editCommericalMaterialsModal.isOpen}
        jobUuid={job.uuid}
        handleClose={editCommericalMaterialsModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const JobCommercialMaterialsAndEquipmentsBlock = ({sectionIndex, job, viewer, refetch}) => {
  return (
    <Container sectionIndex={sectionIndex}>
      <MaterialsAndEquipmentsHeader job={job} viewer={viewer} refetch={refetch} />
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobCommercialMaterialsAndEquipmentsBlock.propTypes = {};

JobCommercialMaterialsAndEquipmentsBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCommercialMaterialsAndEquipmentsBlock.fragment = gql`
  ${CommercialEquipmentsList.fragment}
  ${CommercialMaterialsList.fragment}
  ${CommercialCatalogBillSection.fragment}

  fragment JobCommercialMaterialsAndEquipmentsBlock_Viewer on User {
    id
    viewingOrganization {
      id
      features {
        isEnabledCanEditCompleteJob: isEnabled(feature: "CAN_EDIT_COMPLETE_JOB")
      }
    }
  }

  fragment JobCommercialMaterialsAndEquipmentsBlock_Job on Job {
    id
    isFinal
    isComplete
    commercialCatalog {
      id
      ...CommercialCatalogBillSection
    }
    ...CommercialEquipmentsList
    ...CommercialMaterialsList
  }
`;

export default JobCommercialMaterialsAndEquipmentsBlock;
