// Supermove
import {Currency} from '@supermove/utils';

const _new = ({name, invoice}: any) => ({
  invoiceId: invoice.id,
  name: name || '',
  amount: invoice.remainingBalance,
  method: '',
  paymentMethodId: undefined,
  description: '',
});

const toForm = ({invoiceId, name, amount, method, paymentMethodId, description}: any) => ({
  invoiceId,
  name,
  amount: Currency.toForm(amount),
  method,
  paymentMethodId,
  description,
});

const toMutation = ({invoiceId, name, amount, method, paymentMethodId, description}: any) => ({
  invoiceId,
  name,
  amount: Currency.toMutation(amount),
  method,
  paymentMethodId,
  description,
});

const RecordPaymentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default RecordPaymentForm;
