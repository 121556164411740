// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {useHover, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const ActionButton = Styled.ButtonV2`
  height: 18px;
  width: 18px;
  border-radius: 9px;
  align-items: center;
  justify-content: center;
  background-color: ${({color}) => color}
`;

const MenuPopoverContainer = Styled.View`
  width: 120px;
  border-radius: 6px;
  position: absolute;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
`;

const MenuItemButton = Styled.ButtonV2`
  background-color: ${({isHighlighted}) => (isHighlighted ? colors.hover : 'transparent')};
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  padding-vertical: 8px;
  padding-horizontal: 16px;
`;

const getActionButtonColor = ({isActive, isHovered}) => {
  if (isActive) {
    return colors.blue.interactive;
  }
  if (isHovered) {
    return colors.hover;
  }
  return colors.gray.background;
};

const handleDeleteCostItemForm = ({form, costSectionField, costItemIndex}) => {
  const costItemForm = _.get(form.values, `${costSectionField}.costItemForms.${costItemIndex}`);
  const costItemForms = _.get(form.values, `${costSectionField}.costItemForms`);
  // Remove new cost items, or mark as deleted for saved cost items
  if (!costItemForm.costItemId) {
    const updatedCostItemForms = costItemForms.filter(
      (costItemForm, index) => index !== costItemIndex,
    );
    form.setFieldValue(`${costSectionField}.costItemForms`, updatedCostItemForms);
  } else {
    form.setFieldValue(`${costSectionField}.costItemForms.${costItemIndex}.isDeleted`, true);
  }
  // Update total cost
  const currentTotalCost = Currency.convertToCents(
    _.get(form.values, `${costSectionField}.totalCost`),
  );
  const removedCostTotal = Currency.convertToCents(costItemForm.total);
  form.setFieldValue(
    `${costSectionField}.totalCost`,
    Currency.toForm(currentTotalCost - removedCostTotal, {shouldHideCentsIfZero: true}),
  );
};

const MenuItem = ({onPress, children}) => {
  const {isHovered, ref} = useHover();
  return (
    <MenuItemButton onPress={onPress} isHighlighted={isHovered} ref={ref}>
      <MenuItemText>{children}</MenuItemText>
    </MenuItemButton>
  );
};

const ActionsMenuPopover = ({form, costSectionField, costItemIndex, popover}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <MenuPopoverContainer>
        <Space height={8} />
        <MenuItem
          onPress={() => {
            handleDeleteCostItemForm({form, costSectionField, costItemIndex});
            popover.handleClose();
          }}
        >
          Delete
        </MenuItem>
        <Space height={8} />
      </MenuPopoverContainer>
    </Popover>
  );
};

const CostItemActionsMenuButton = ({form, costSectionField, costItemIndex}) => {
  const costItemActionsPopover = usePopover();
  const {isHovered, ref} = useHover();
  const isActive = costItemActionsPopover.isOpen;
  return (
    <React.Fragment>
      <Popover.Content innerRef={costItemActionsPopover.ref}>
        <ActionButton
          onPress={costItemActionsPopover.handleToggle}
          color={getActionButtonColor({isActive, isHovered})}
          ref={ref}
        >
          <Icon
            source={Icon.EllipsisV}
            color={isActive ? colors.white : colors.blue.interactive}
            size={12}
          />
        </ActionButton>
      </Popover.Content>
      <ActionsMenuPopover
        form={form}
        costSectionField={costSectionField}
        costItemIndex={costItemIndex}
        popover={costItemActionsPopover}
      />
    </React.Fragment>
  );
};

export default CostItemActionsMenuButton;
