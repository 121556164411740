// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useNavigationDOM,
  useState,
  useModal,
  usePopover,
  useResponsive,
  useToast,
} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldValue from '@shared/design/components/Field/FieldValue';
import Panel from '@shared/design/components/Panel';
import TextTooltip from '@shared/design/components/TextTooltip';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import Line from 'modules/App/components/Line';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import CompleteEstimatedRangeJobModal from 'modules/Job/components/CompleteEstimatedRangeJobModal';
import JobCancelModal from 'modules/Job/components/JobCancelModal';
import JobCompleteManuallyModal from 'modules/Job/components/JobCompleteManuallyModal';
import ResetJobModal from 'modules/Job/components/ResetJobModal';
import RestoreJobModal from 'modules/Job/components/RestoreJobModal';
import FinalizeJobModal from 'modules/Project/V2/Show/Blocks/Job/components/FinalizeJobModal';
import JobActionDisabledTooltip from 'modules/Project/V2/Show/Blocks/Job/components/JobActionDisabledTooltip';
import JobDispatchStatus from 'modules/Project/V2/Show/Blocks/Job/components/JobDispatchStatus';
import JobTags from 'modules/Project/V2/Show/Blocks/Job/components/JobTags';
import UnfinalizeJobModal from 'modules/Project/V2/Show/Blocks/Job/components/UnfinalizeJobModal';
import FieldValueExpandable from 'modules/Project/V2/Show/Blocks/components/FieldValueExpandable';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const DetailButton = Styled.ButtonV2`
`;

const DetailIconContainer = Styled.View`
  width: 16px;
  align-items: center;
`;

const DetailText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const DESCRIPTION = 'description';
const OFFICE_NOTES = 'officeNotes';

const SkeletonField = ({label}) => {
  return (
    <Column style={{flex: 1}}>
      <FieldValue.LabelText>{label}</FieldValue.LabelText>
      <Space height={8} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={144} />
    </Column>
  );
};

const SkeletonComponent = () => {
  return (
    <Panel>
      <Panel.Header>
        <Column style={{flex: 1}}>
          <Row>
            <Panel.HeaderText>Job Details</Panel.HeaderText>
            <Space style={{flex: 1}} />
            <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} width={100} />
          </Row>
          <Space height={12} />
          <Row>
            <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={88} />
            <Space width={32} />
            <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={88} />
            <Space width={32} />
            <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={48} />
          </Row>
        </Column>
      </Panel.Header>
      <Panel.Body>
        <Row style={{flex: 1}}>
          <SkeletonField label={'Job Description'} />
          <SkeletonField label={'Office Notes'} />
        </Row>
      </Panel.Body>
    </Panel>
  );
};

const JobDetailWrapper = ({tooltip, responsive, children}) => {
  if (responsive.desktop) {
    return (
      <TextTooltip text={tooltip} placement={'top'}>
        {children}
      </TextTooltip>
    );
  }
  return (
    <React.Fragment>
      <Space height={12} />
      {children}
      <Space height={12} />
    </React.Fragment>
  );
};

const JobDetail = ({icon, tooltip, children, responsive, ContentComponent}) => {
  return (
    <JobDetailWrapper tooltip={tooltip} responsive={responsive}>
      <Row style={{alignItems: 'center'}}>
        <DetailIconContainer>
          <Icon
            source={icon}
            size={responsive.desktop ? 12 : 16}
            color={responsive.desktop ? colors.gray.secondary : colors.blue.interactive}
          />
        </DetailIconContainer>
        <Space width={8} />
        {ContentComponent ? (
          <ContentComponent />
        ) : (
          <DetailText responsive={responsive}>{children}</DetailText>
        )}
      </Row>
    </JobDetailWrapper>
  );
};

const JobDates = ({job, responsive}) => {
  return (
    <JobDetail icon={Icon.Calendar} tooltip={'Date(s)'} responsive={responsive}>
      {Job.getDisplayDate(job, 'MM/DD/YY', 'TBD')}
    </JobDetail>
  );
};

const JobArrivalTime = ({job, responsive}) => {
  return (
    <JobDetail icon={Icon.Clock} tooltip={'Origin Arrival Window'} responsive={responsive}>
      {Job.getOriginArrivalWindow(job)}
    </JobDetail>
  );
};

const JobEstimateHours = ({job, responsive}) => {
  return (
    <JobDetail icon={Icon.CalendarClock} tooltip={'Estimate Hours'} responsive={responsive}>
      {Job.getDisplayEstimateHours(job)}
    </JobDetail>
  );
};

const JobOfficeNotes = ({
  job,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  responsive,
}) => {
  return (
    <JobDetail
      icon={Icon.StickyNote}
      responsive={responsive}
      ContentComponent={() => {
        return (
          <OfficeNotesField
            job={job}
            truncated={truncated}
            setTruncated={setTruncated}
            enabledToggle={enabledToggle}
            setEnabledToggle={setEnabledToggle}
            responsive={responsive}
          />
        );
      }}
    />
  );
};

const ShareJobPopoverButton = ({job, moverLinkToast, trackingLinkToast}) => {
  const shareActionsPopover = usePopover({name: 'Share Actions Popover'});

  return (
    <ActionMenuPopover
      popover={shareActionsPopover}
      placement={ActionMenuPopover.Position.BottomEnd}
      actions={Job.getShareActions(job, {moverLinkToast, trackingLinkToast})}
    >
      <SecondaryButton
        text={'Share Job'}
        iconLeft={Icon.Share}
        onPress={shareActionsPopover.handleOpen}
        isSmall
      />
    </ActionMenuPopover>
  );
};

const PanelHeader = ({job, refetchAndReset, responsive, moverLinkToast, trackingLinkToast}) => {
  const {navigator, params} = useNavigationDOM();

  return (
    <Column style={{flex: 1}}>
      <Row style={{alignItems: 'center'}}>
        <JobDispatchStatus job={job} />
        <Space width={8} />
        <Panel.HeaderText>{Job.getFullName(job)}</Panel.HeaderText>
        <Space style={{flex: 1, minWidth: 12}} />
        <ShareJobPopoverButton
          job={job}
          moverLinkToast={moverLinkToast}
          trackingLinkToast={trackingLinkToast}
        />
        <Space width={16} />
        <JobActionDisabledTooltip job={job}>
          <Button
            text={'Edit Job'}
            iconLeft={Icon.Pen}
            onPress={() =>
              Job.goToEditProjectJobs(job, navigator, {projectUuid: params.projectUuid})
            }
            isSmall
            isDisabled={job.isFinal}
          />
        </JobActionDisabledTooltip>
      </Row>
      <Space height={12} />
      {job.organization.features.isEnabledProjectTag && (
        <React.Fragment>
          <JobTags job={job} refetch={refetchAndReset} responsive={responsive} />
          <Space height={12} />
        </React.Fragment>
      )}
      <Row>
        <JobDates job={job} responsive={responsive} />
        <Space width={32} />
        <JobArrivalTime job={job} responsive={responsive} />
        <Space width={32} />
        <JobEstimateHours job={job} responsive={responsive} />
      </Row>
    </Column>
  );
};

const DescriptionField = ({
  job,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  responsive,
}) => {
  return (
    <FieldValueExpandable
      data={job}
      property={DESCRIPTION}
      label={responsive.desktop ? 'Job Description' : ''}
      numberOfLines={5}
      isTruncated={truncated[DESCRIPTION]}
      isEnabledToggle={enabledToggle[DESCRIPTION]}
      setTruncated={setTruncated}
      setEnabledToggle={setEnabledToggle}
      isResponsive
    />
  );
};

const OfficeNotesField = ({
  job,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  responsive,
}) => {
  return (
    <FieldValueExpandable
      data={job}
      property={OFFICE_NOTES}
      label={responsive.desktop ? 'Office Notes' : ''}
      empty={responsive.desktop ? null : 'No office notes'}
      numberOfLines={5}
      isTruncated={truncated[OFFICE_NOTES]}
      isEnabledToggle={enabledToggle[OFFICE_NOTES]}
      setTruncated={setTruncated}
      setEnabledToggle={setEnabledToggle}
      isResponsive
    />
  );
};

const PanelBody = ({job, truncated, setTruncated, enabledToggle, setEnabledToggle, responsive}) => {
  return (
    <Row style={{flex: 1}}>
      <DescriptionField
        job={job}
        truncated={truncated}
        setTruncated={setTruncated}
        enabledToggle={enabledToggle}
        setEnabledToggle={setEnabledToggle}
        responsive={responsive}
      />
      <Space width={16} />
      <OfficeNotesField
        job={job}
        truncated={truncated}
        setTruncated={setTruncated}
        enabledToggle={enabledToggle}
        setEnabledToggle={setEnabledToggle}
        responsive={responsive}
      />
    </Row>
  );
};

const MobileActionsComponent = ({
  job,
  project,
  navigator,
  params,
  responsive,
  refetchAndReset,
  moverLinkToast,
  trackingLinkToast,
  urlFilters,
  viewer,
}) => {
  const actionsMenuPopover = usePopover();
  const completeEstimatedRangeJobModal = useModal({
    name: 'Complete Estimated Range Job Modal',
    enableTracking: true,
  });
  const jobCompleteManuallyModal = useModal({
    name: 'Job Complete Manually Modal',
    enableTracking: true,
  });
  const resetJobModal = useModal({name: 'Reset Job Modal', enableTracking: true});
  const jobCancelModal = useModal({name: 'Cancel Job Modal', enableTracking: true});
  const restoreJobModal = useModal({name: 'Restore Job Modal', enableTracking: true});
  const finalizeJobModal = useModal({name: 'Finalize Job Modal', enableTracking: true});
  const unfinalizeJobModal = useModal({name: 'Unfinalize Job Modal', enableTracking: true});

  return (
    <Column style={{height: '100%'}}>
      <Row style={{alignItems: 'center'}}>
        <JobActionDisabledTooltip job={job}>
          <MobileProjectBlockHeader.TextButton
            text={`Edit`}
            onPress={() => Job.goToEditProjectJobs(job, navigator, {projectUuid: project.uuid})}
            isDisabled={job.isFinal}
          />
        </JobActionDisabledTooltip>
        <Space width={8} />
        <ActionMenuPopover
          popover={actionsMenuPopover}
          width={200}
          actions={Job.getActions(job, {
            responsive,
            navigator,
            projectUuid: params.projectUuid,
            isProjectAtMaxJobs: Project.getIsAtMaxJobs(project),
            userRole: viewer.role,
            onPressMarkAsCompleted: Job.getIsEstimatedRange(job)
              ? completeEstimatedRangeJobModal.handleOpen
              : jobCompleteManuallyModal.handleOpen,
            onPressResetJob: resetJobModal.handleOpen,
            onPressCancelJob: jobCancelModal.handleOpen,
            onPressRestoreJob: restoreJobModal.handleOpen,
            onPressFinalizeJob: finalizeJobModal.handleOpen,
            onPressUnfinalizeJob: unfinalizeJobModal.handleOpen,
            moverLinkToast,
            trackingLinkToast,
          })}
        >
          <MobileProjectBlockHeader.EllipsisButton onPress={actionsMenuPopover.handleToggle} />
        </ActionMenuPopover>
      </Row>
      <CompleteEstimatedRangeJobModal
        key={completeEstimatedRangeJobModal.key}
        jobUuid={job.uuid}
        refetchParent={refetchAndReset}
        isOpen={completeEstimatedRangeJobModal.isOpen}
        handleClose={completeEstimatedRangeJobModal.handleClose}
      />
      <JobCompleteManuallyModal
        key={jobCompleteManuallyModal.key}
        job={job}
        onSuccess={refetchAndReset}
        isOpen={jobCompleteManuallyModal.isOpen}
        handleClose={jobCompleteManuallyModal.handleClose}
      />
      <ResetJobModal
        key={resetJobModal.key}
        moveId={job.moveId}
        refetch={refetchAndReset}
        isOpen={resetJobModal.isOpen}
        handleClose={resetJobModal.handleClose}
      />
      <JobCancelModal
        key={jobCancelModal.key}
        jobId={job.id}
        refetch={refetchAndReset}
        isOpen={jobCancelModal.isOpen}
        handleClose={jobCancelModal.handleClose}
        onSuccess={() => urlFilters.handleReset({block: ProjectBlockKind.JOBS})}
      />
      <RestoreJobModal
        key={restoreJobModal.key}
        job={job}
        refetch={refetchAndReset}
        isOpen={restoreJobModal.isOpen}
        handleClose={restoreJobModal.handleClose}
      />
      <FinalizeJobModal
        key={finalizeJobModal.key}
        jobId={job.id}
        refetch={refetchAndReset}
        isOpen={finalizeJobModal.isOpen}
        handleClose={finalizeJobModal.handleClose}
      />
      <UnfinalizeJobModal
        key={unfinalizeJobModal.key}
        jobId={job.id}
        refetch={refetchAndReset}
        isOpen={unfinalizeJobModal.isOpen}
        handleClose={unfinalizeJobModal.handleClose}
      />
    </Column>
  );
};

const MobileDetailButton = ({job, focusField, block, children}) => {
  const {navigator, params} = useNavigationDOM();

  return (
    <DetailButton
      onPress={() =>
        Job.goToEditProjectJobs(job, navigator, {
          projectUuid: params.projectUuid,
          block,
          field: focusField,
        })
      }
    >
      {children}
    </DetailButton>
  );
};

const MobileProjectBlockContent = ({
  job,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  refetchAndReset,
  responsive,
}) => {
  return (
    <React.Fragment>
      {job.description && (
        <React.Fragment>
          <DescriptionField
            job={job}
            truncated={truncated}
            setTruncated={setTruncated}
            enabledToggle={enabledToggle}
            setEnabledToggle={setEnabledToggle}
            responsive={responsive}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      <Row>
        <JobDispatchStatus job={job} responsive={responsive} showLabel />
      </Row>
      <Space height={16} />
      <JobTags job={job} refetch={refetchAndReset} responsive={responsive} />
      <Space height={12} />
      <MobileDetailButton job={job} focusField={'startDate'}>
        <JobDates job={job} responsive={responsive} />
      </MobileDetailButton>
      <Line />
      <MobileDetailButton job={job} focusField={'startTime1'}>
        <JobArrivalTime job={job} responsive={responsive} />
      </MobileDetailButton>
      <Line />
      <MobileDetailButton job={job} focusField={'estimateHours1'}>
        <JobEstimateHours job={job} responsive={responsive} />
      </MobileDetailButton>
      <Line />
      <MobileDetailButton job={job} focusField={'officeNotes'} block={ProjectBlockKind.Job.NOTES}>
        <JobOfficeNotes
          job={job}
          truncated={truncated}
          setTruncated={setTruncated}
          enabledToggle={enabledToggle}
          setEnabledToggle={setEnabledToggle}
          responsive={responsive}
        />
      </MobileDetailButton>
    </React.Fragment>
  );
};

const JobDetailsBlockContent = ({
  job,
  project,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  refetchAndReset,
  urlFilters,
  viewer,
}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const moverLinkToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Mover link copied to clipboard!',
  });
  const trackingLinkToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Customer tracking link copied to clipboard!',
  });

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <Panel>
          <Panel.Header>
            <PanelHeader
              job={job}
              refetchAndReset={refetchAndReset}
              responsive={responsive}
              moverLinkToast={moverLinkToast}
              trackingLinkToast={trackingLinkToast}
            />
          </Panel.Header>
          <Panel.Body>
            <PanelBody
              job={job}
              truncated={truncated}
              setTruncated={setTruncated}
              enabledToggle={enabledToggle}
              setEnabledToggle={setEnabledToggle}
              responsive={responsive}
            />
          </Panel.Body>
        </Panel>
      ) : (
        <React.Fragment>
          <MobileProjectBlockHeader
            title={job.displayName}
            ActionsComponent={MobileActionsComponent}
            actionsComponentProps={{
              job,
              project,
              navigator,
              params,
              responsive,
              refetchAndReset,
              moverLinkToast,
              trackingLinkToast,
              urlFilters,
              viewer,
            }}
          />
          <MobileProjectBlockContent
            job={job}
            truncated={truncated}
            setTruncated={setTruncated}
            enabledToggle={enabledToggle}
            setEnabledToggle={setEnabledToggle}
            refetchAndReset={refetchAndReset}
            responsive={responsive}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const JobDetailsBlock = ({
  project,
  index,
  jobUuid,
  handleSetPositionY,
  layoutKey,
  refetchAndReset,
  urlFilters,
  projectBlockKind,
}) => {
  const [truncated, setTruncated] = useState({[DESCRIPTION]: true, [OFFICE_NOTES]: true});
  const [enabledToggle, setEnabledToggle] = useState({[DESCRIPTION]: false, [OFFICE_NOTES]: false});
  return (
    <ProjectBlockWrapper
      index={index}
      query={JobDetailsBlock.query}
      queryVariables={{jobUuid}}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data}) => {
        return (
          <JobDetailsBlockContent
            job={data.job}
            project={project}
            truncated={truncated}
            setTruncated={setTruncated}
            enabledToggle={enabledToggle}
            setEnabledToggle={setEnabledToggle}
            refetchAndReset={refetchAndReset}
            urlFilters={urlFilters}
            viewer={data.viewer}
          />
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDetailsBlock.listener = gql`
  fragment JobDetailsBlock_listener on Job {
    id
    calendarPrimaryStatus
  }
`;

JobDetailsBlock.fragment = gql`
  ${Project.goToAddJob.fragment}
  ${Project.getIsAtMaxJobs.fragment}

  fragment JobDetailsBlock on Project {
    id
    ...Project_goToAddJob
    ...Project_getIsAtMaxJobs
  }
`;

JobDetailsBlock.query = gql`
  ${Job.getActions.fragment}
  ${Job.getDisplayDate.fragment}
  ${Job.getFullName.fragment}
  ${JobDispatchStatus.fragment}
  ${Job.getOriginArrivalWindow.fragment}
  ${Job.getDisplayEstimateHours.fragment}
  ${Job.getIsEstimatedRange.fragment}
  ${Job.goToEditProjectJobs.fragment}
  ${JobTags.fragment}
  ${JobActionDisabledTooltip.fragment}

  query JobDetailsBlock($jobUuid: String!) {
    ${gql.query}
    viewer {
      id
      role
    }
    job(uuid: $jobUuid) {
      id
      moveId
      uuid
      displayName
      kind
      startTime1
      startTime2
      estimateHours1
      estimateHours2
      description
      officeNotes
      isCancelled
      isFinal
      organization {
        id
        slug
        features {
          isEnabledProjectTag: isEnabled(feature: "PROJECT_TAG")
          isEnabledPrintJobCard: isEnabled(feature: "PRINT_JOB_CARD")
        }
      }
      ...Job_getActions
      ...Job_getDisplayDate
      ...Job_getFullName
      ...JobDispatchStatus
      ...Job_getOriginArrivalWindow
      ...Job_getEstimatedHours
      ...Job_getIsEstimatedRange
      ...Job_goToEditProjectJobs
      ...JobTags
      ...JobActionDisabledTooltip
    }
  }
`;

export default JobDetailsBlock;
