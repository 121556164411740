// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useHover, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';

const Row = Styled.View`
  flex-direction: row;
`;

const Text = Styled.Text<{isDisabled: boolean}>`
  ${Typography.Responsive.Body}
  ${({isDisabled}) => isDisabled && `color: ${colors.gray.tertiary};`}
`;

const MicroText = Styled.Text<{isDisabled: boolean}>`
  ${Typography.Responsive.Micro}
  ${({isDisabled}) => isDisabled && `color: ${colors.gray.tertiary};`}
`;

const OptionContainer = Styled.ButtonV2<{isHovered: boolean; isDisabled: boolean}>`
  padding: 12px;
  background-color: ${({isDisabled, isHovered}) => (isHovered && !isDisabled ? colors.blue.accent : colors.white)};
`;

export interface AddBillItemOrRuleTypeOptionType {
  text: string;
  primaryText: string;
  bottomLeftText: string;
  topRightText: string;
  bottomRightText: string;
  onPress: () => void;
  isDisabled: boolean;
}

const Option = ({
  primaryText,
  bottomLeftText,
  topRightText,
  bottomRightText,
  onPress,
  isDisabled,
}: AddBillItemOrRuleTypeOptionType) => {
  const responsive = useResponsive();
  const {isHovered, ref} = useHover();

  return (
    <OptionContainer
      ref={ref}
      isHovered={isHovered}
      isDisabled={isDisabled}
      onPress={onPress}
      disabled={isDisabled}
    >
      <Row>
        <Text isDisabled={isDisabled} responsive={responsive}>
          {primaryText}
        </Text>
        <Space flex={1} />
        <Text isDisabled={isDisabled} responsive={responsive}>
          {topRightText}
        </Text>
      </Row>
      <Space height={4} />
      <Row>
        <MicroText isDisabled={isDisabled} responsive={responsive}>
          {bottomLeftText}
        </MicroText>
        <Space flex={1} />
        <MicroText isDisabled={isDisabled} responsive={responsive}>
          {bottomRightText}
        </MicroText>
      </Row>
    </OptionContainer>
  );
};

const AddBillItemOrRuleTypeButton = ({
  label,
  options,
}: {
  label: string;
  options: AddBillItemOrRuleTypeOptionType[];
}) => {
  return (
    <DropdownButton
      text={label}
      iconLeft={Icon.Plus}
      isVisibleArrow={false}
      actions={options}
      maxDropdownHeight={300}
      ButtonComponent={TertiaryButton}
      isDense
      isSearchable
      menuWidth={752}
      ActionItemComponent={(props: AddBillItemOrRuleTypeOptionType) => <Option {...props} />}
    />
  );
};

export default AddBillItemOrRuleTypeButton;
