// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToggle} from '@supermove/hooks';
import {Organization, Payment} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import Switch from '@shared/design/components/Switch';
import OrganizationOfficePaymentMethodsForm from '@shared/modules/Organization/forms/OrganizationOfficePaymentMethodsForm';
import useUpdateOrganizationOfficePaymentMethodsMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationOfficePaymentMethodsMutation';

const Row = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const EmptyText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const Body = ({organization}) => {
  const activePaymentMethods = Organization.getOfficePaymentMethods(organization);
  return (
    <Row>
      {_.isEmpty(activePaymentMethods) ? (
        <EmptyText>No payment methods.</EmptyText>
      ) : (
        activePaymentMethods.sort().map((method) => (
          <React.Fragment>
            <Badge label={Payment.getDisplayMethod({method})} />
            <Space width={8} />
          </React.Fragment>
        ))
      )}
    </Row>
  );
};

const EditBody = ({form, organization}) => {
  const availablePaymentMethods = Payment.getOfficeMethodOptions().filter((method) => {
    switch (method.value) {
      case 'AUTHORIZE_DOT_NET':
        return organization.features.isEnabledAuthorizeDotNetExternalPayment;
      default:
        return true;
    }
  });

  const paymentMethods = _.get(
    form.values,
    'organizationOfficePaymentMethodsForm.officePaymentMethods',
  );
  const isMethodOn = (method) => {
    return _.includes(paymentMethods, method);
  };
  const toggleisMethodOn = (methodToUpdate) => {
    form.setFieldValue(
      'organizationOfficePaymentMethodsForm.officePaymentMethods',
      _.xor(paymentMethods, [methodToUpdate]),
    );
  };

  return (
    <React.Fragment>
      {availablePaymentMethods.map((paymentMethod) => {
        return (
          <React.Fragment key={paymentMethod.value}>
            <Switch
              isOn={isMethodOn(paymentMethod.value)}
              onChange={() => toggleisMethodOn(paymentMethod.value)}
              labelRight={paymentMethod.name}
            />
            <Space height={12} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const OfficePaymentMethodsPanel = ({organization, index}) => {
  const editOfficePaymentMethodsToggle = useToggle(false);
  const organizationOfficePaymentMethodsForm =
    OrganizationOfficePaymentMethodsForm.edit(organization);
  const {form, handleSubmit, submitting} = useUpdateOrganizationOfficePaymentMethodsMutation({
    organizationOfficePaymentMethodsForm,
    onSuccess: () => {
      editOfficePaymentMethodsToggle.handleToggleOff();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      index={index}
      title={'Payment Methods'}
      BodyComponent={Body}
      bodyComponentProps={{organization}}
      EditBodyComponent={EditBody}
      editBodyComponentProps={{organization, form}}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editOfficePaymentMethodsToggle.isOn}
      handleEdit={editOfficePaymentMethodsToggle.handleToggleOn}
      handleClose={editOfficePaymentMethodsToggle.handleToggleOff}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OfficePaymentMethodsPanel.fragment = gql`
  ${Organization.getOfficePaymentMethods.fragment}
  ${OrganizationOfficePaymentMethodsForm.edit.fragment}
  fragment OfficePaymentMethodsPanel on Organization {
    id
    features {
      isEnabledAuthorizeDotNetExternalPayment: isEnabled(
        feature: "AUTHORIZE_DOT_NET_EXTERNAL_PAYMENT"
      )
    }
    ...Organization_getOfficePaymentMethods
    ...OrganizationOfficePaymentMethodsForm_edit
  }
`;

export default OfficePaymentMethodsPanel;
