/**
 * DEPRECATED(mark): Once all organizations stop using DocumentTemplate.Kind.BILL, we can remove
 * this page.
 *
 * WARNING (warren)
 * This is a web component that renders HTML used to generate a PDF.
 */

// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// App
import DocumentMoveTimesChart from 'modules/Document/components/DocumentMoveTimesChart';

// Components
import DocumentBillingInformation from './DocumentBillingInformation';
import DocumentCustomerSignature from './DocumentCustomerSignature';
import DocumentFreightBillInfo from './DocumentFreightBillInfo';
import DocumentHeaderOrganization from './DocumentHeaderOrganization';

const Container = Styled.View`
  width: 100%;
  background-color: ${colors.white};
`;

const Section = Styled.View`
`;

const SectionSpace = Styled.View`
  margin-top: 10px;
`;

const HeaderText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Text = Styled.H7`
  color: ${colors.gray.primary};
`;

const JobBillDocument = ({job}) => (
  <Container>
    <Section>
      <DocumentHeaderOrganization
        title={'SHIPPING ORDER AND FREIGHT BILL'}
        organization={job.organization}
      />
    </Section>
    <SectionSpace />
    <Section>
      <DocumentFreightBillInfo job={job} />
    </Section>
    <SectionSpace />
    <Section>
      <HeaderText>Work Timesheet</HeaderText>
      <DocumentMoveTimesChart
        hasConfirmedTimes={job.move.hasConfirmedTimes}
        timeRanges={job.move.timeRanges}
      />
    </Section>
    <SectionSpace />
    <Section data-avoid-page-break>
      <HeaderText>Final Bill</HeaderText>
      <DocumentBillingInformation showPayments showBillRules={false} job={job} />
    </Section>
    <SectionSpace />
    <Section>
      <Text>
        NOTICE: PLEASE INSPECT YOUR GOODS PROMPTLY. CLAIMS FOR ANY LOST OR DAMAGED GOODS MUST BE
        FILED WITH THE MOVER IN WRITING.
      </Text>
      {!!_.get(job, 'move.bill.document.documentSignatures.0') && (
        <DocumentCustomerSignature
          signature={_.get(job, 'move.bill.document.documentSignatures.0.signature')}
          style={{
            marginTop: 20,
            marginBottom: 10,
          }}
        />
      )}
    </Section>
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobBillDocument.propTypes = {
  job: PropTypes.object.isRequired,
};

JobBillDocument.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobBillDocument.fragment = gql`
  ${DocumentBillingInformation.fragment}
  ${DocumentCustomerSignature.fragment}
  ${DocumentFreightBillInfo.fragment}
  ${DocumentHeaderOrganization.fragment}
  ${DocumentMoveTimesChart.fragment}

  fragment JobBillDocument on Job {
    id
    organization {
      id
      ...DocumentHeaderOrganization
    }
    move {
      id
      hasConfirmedTimes
      bill {
        id
        document {
          id
          documentSignatures {
            id
            signature {
              id
              ...DocumentCustomerSignature
            }
          }
        }
      }
      timeRanges {
        ...DocumentMoveTimesChart
      }
    }
    ...DocumentBillingInformation
    ...DocumentFreightBillInfo
  }
`;

export default JobBillDocument;
