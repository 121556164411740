/**
 * TODO(mark): This page is being migrated over to the modules/Inventory/Room/Edit page.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery, useToast} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import CollectionForm from '@shared/modules/Inventory/forms/CollectionForm';
import useUpdateCollectionMutationWithCollectionFragment from '@shared/modules/Inventory/hooks/useUpdateCollectionMutationWithCollectionFragment';
import Field from 'modules/App/components/Field';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CollectionItemsList from 'modules/Collection/components/CollectionItemsList';
import CreateCustomItemModal from 'modules/Item/components/CreateCustomItemModal';
import CollectionFormHeader from 'modules/Job/Inventory/components/CollectionFormHeader';
import InventoryHeader from 'modules/Job/Inventory/components/InventoryHeader';
import InventorySidebar from 'modules/Job/Inventory/components/InventorySidebar';
import UpdateRoomModal from 'modules/Room/Show/components/UpdateRoomModal';

const Container = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  align-self: center;
  max-width: 1120px;
  width: 100%;
  padding-horizontal: 10px;
`;

const Header = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Columns = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Touchable = Styled.ButtonV2`
`;

const SaveButton = Styled.ButtonV2`
  width: 160px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-vertical: 8px;
  padding-horizontal: 16px;
  border-radius: 4px;
  background-color: ${({disabled}) => (disabled ? colors.gray.border : colors.blue.interactive)};
`;

const SaveButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const ActivityIndicator = Styled.Loading`
`;

const getSaveButtonContent = ({form, isSubmitting}) => {
  const hasChanges = _.get(form.values, 'collectionForm.hasChanges');
  const hasSavedChanges = _.get(form.values, 'collectionForm.hasSavedChanges');
  if (isSubmitting) {
    return <ActivityIndicator size={'small'} color={colors.white} />;
  }
  if (hasChanges) {
    return <SaveButtonText>Save changes</SaveButtonText>;
  }
  if (hasSavedChanges) {
    return (
      <React.Fragment>
        <SaveButtonText>Saved</SaveButtonText>
        <Space width={8} />
        <Icon source={Icon.Check} size={12} color={colors.white} />
      </React.Fragment>
    );
  }
  return <SaveButtonText>No changes found</SaveButtonText>;
};

const EditRoomHeader = ({room, refetch, handleSubmit, submitting, form}) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const collectionHasUpdates = _.get(form.values, 'collectionForm.hasChanges');
  const isSubmitting = submitting || _.get(form.values, 'collectionForm.isSubmitting');

  return (
    <React.Fragment>
      <Header>
        <Row style={{alignItems: 'center'}}>
          <Title>{room.name}</Title>
          <Space width={5} />
          <Touchable onPress={handleOpen}>
            <Icon color={colors.gray.primary} size={14} source={Icon.EllipsisH} />
          </Touchable>
          <Space style={{flex: 1}} />
          <SaveButton
            disabled={isSubmitting || !collectionHasUpdates}
            onPress={() => {
              form.setFieldValue('collectionForm.isSubmitting', true);
              handleSubmit();
            }}
          >
            {getSaveButtonContent({form, isSubmitting})}
          </SaveButton>
        </Row>
        <Space height={10} />
        <Line />
      </Header>
      <UpdateRoomModal
        isOpen={isOpen}
        room={room}
        handleOpen={handleOpen}
        handleClose={handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const RoomContent = Styled.View`
  flex: 1;
`;

const Side = Styled.View`
  flex: 1;
`;

const Subtitle = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const TextInput = Styled.TextInput.H7`
`;

const OrText = Styled.H6`
  ${fontWeight(700)}
  margin-horizontal: 5px;
  color: ${colors.gray.primary};
`;

const getInitialSelectedCategoryId = ({room}) => {
  const isCollectionEmpty = room.collection.takeCount === 0 && room.collection.leaveCount === 0;
  const primaryCategoryId = _.get(room, 'roomType.primaryCategory.id');
  return isCollectionEmpty && primaryCategoryId ? primaryCategoryId : 'ALL';
};

const getCategoryOptions = ({primaryCategoryId, project}) => {
  const filteredCategories = project.organization.categories.filter((category) => {
    return category.isShared || primaryCategoryId === category.id;
  });

  const orderedCategories = _.orderBy(
    filteredCategories,
    [
      (category) => category.kind === 'CARTON',
      (category) => category.id === primaryCategoryId,
      (category) => category.name,
    ],
    [
      // Show cartons first.
      'desc',

      // Show the primary category next.
      'desc',

      // Then show the rest of the categories ordered by name in alphabetical order.
      'asc',
    ],
  );

  const categoryOptions = orderedCategories.map((category) => ({
    label: category.name,
    value: category.id,
  }));

  return [{label: 'All', value: 'ALL'}, ...categoryOptions];
};

const CreateLink = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.blue.accentDark};
`;

const AddInventoryHeader = ({collection, inventory, form}) => {
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <React.Fragment>
      <Row style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <Subtitle>Add From Inventory</Subtitle>
        <Touchable onPress={handleOpen}>
          <CreateLink>+ Add Custom Item</CreateLink>
        </Touchable>
      </Row>
      <CreateCustomItemModal
        key={isOpen} // This ensures a new ItemForm is created when the modal is opened.
        isOpen={isOpen}
        collectionId={collection.id}
        form={form}
        inventory={inventory}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
};

const EditRoomInventoryJobPageContent = ({room, inventory, job, refetch}) => {
  const primaryCategoryId = _.get(room, 'roomType.primaryCategory.id');
  const collectionForm = CollectionForm.edit(room.collection, {
    selectedCategoryId: getInitialSelectedCategoryId({room}),
  });
  const errorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Error saving changes.',
  });

  const {form, submitting, handleSubmit} = useUpdateCollectionMutationWithCollectionFragment({
    collectionForm,
    collectionFragment: gql`
      ${EditRoomInventoryJobPage.collectionFragment}
      fragment collectionFragment on Collection {
        id
        ...EditRoomInventoryJobPage_collectionFragment
      }
    `,
    onSuccess: () => {
      form.setFieldValue('collectionForm.hasChanges', false);
      form.setFieldValue('collectionForm.hasSavedChanges', true);
      form.setFieldValue('collectionForm.isSubmitting', false);
      refetch();
    },
    onError: (errors) => {
      setTimeout(() => errorToast.handleToast(), 0);
      form.setFieldValue('collectionForm.isSubmitting', false);
      console.log({errors});
    },
  });

  const hasChanges = _.get(form.values, 'collectionForm.hasChanges');

  return (
    <ContentContainer>
      <InventoryHeader
        inventory={inventory}
        title={Job.getFullName(job)}
        selectedRoomName={room ? room.name : ''}
        hasChanges={hasChanges}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
      <Columns>
        <InventorySidebar
          inventory={inventory}
          selectedRoom={room}
          hasChanges={hasChanges}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
        <Space width={20} />
        <RoomContent>
          <Space height={20} />
          <EditRoomHeader
            room={room}
            refetch={refetch}
            handleSubmit={handleSubmit}
            submitting={submitting}
            form={form}
          />
          <Space height={20} />
          <Row style={{flex: 1}}>
            <Side>
              <Space height={20} />
              <AddInventoryHeader collection={room.collection} inventory={inventory} form={form} />
              <Space height={10} />
              <Row
                style={{
                  zIndex: 100,
                  alignItems: 'center',
                }}
              >
                <Field
                  {...form}
                  component={TextInput}
                  name={'collectionForm.searchQuery'}
                  label={''}
                  input={{
                    placeholder: 'Search by name',
                    onChangeText: (searchQuery) => {
                      if (form.values.collectionForm.selectedCategoryId !== 'ALL') {
                        form.setFieldValue('collectionForm.selectedCategoryId', 'ALL');
                      }
                    },
                  }}
                  style={{
                    flex: 1,
                  }}
                />
                <OrText>or</OrText>
                <Field
                  {...form}
                  component={DropdownInput}
                  name={'collectionForm.selectedCategoryId'}
                  label={''}
                  input={{
                    disabled: !!form.values.collectionForm.searchQuery,
                    options: getCategoryOptions({primaryCategoryId, project: inventory.project}),
                    placeholder: 'Select a category',
                    setFieldValue: form.setFieldValue,
                    style: {
                      flex: 1,
                    },
                  }}
                  style={{
                    flex: 1,
                  }}
                />
              </Row>
              <Space height={20} />
              <ScrollView style={{flex: 1}}>
                <CollectionItemsList form={form} inventory={inventory} />
              </ScrollView>
            </Side>
            <Space width={10} />
            <Side
              style={{
                backgroundColor: colors.gray.background,
                borderWidth: 1,
                borderColor: colors.blue.accent,
                borderRadius: 8,
              }}
            >
              <Space height={10} />
              <Row style={{justifyContent: 'flex-end'}}>
                <CollectionFormHeader collectionForm={form.values.collectionForm} />
                <Space width={20} />
              </Row>
              <Space height={10} />
              <Line />
              <Space height={10} />
              <ScrollView style={{flex: 1}}>
                <CollectionItemsList showSurveyedOnly form={form} inventory={inventory} />
              </ScrollView>
            </Side>
          </Row>
        </RoomContent>
      </Columns>
    </ContentContainer>
  );
};

const EditRoomInventoryJobPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(EditRoomInventoryJobPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobUuid: params.jobUuid,
      inventoryUuid: params.uuid,
      roomUuid: params.roomUuid,
    },
  });

  return (
    <Container>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <EditRoomInventoryJobPageContent
            key={data.room.id}
            room={data.room}
            inventory={data.inventory}
            job={data.job}
            refetch={refetch}
          />
        )}
      </Loading>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditRoomInventoryJobPage.collectionFragment = gql`
  ${CollectionForm.edit.fragment}
  fragment EditRoomInventoryJobPage_collectionFragment on Collection {
    id
    takeCount
    leaveCount
    ...CollectionForm_edit
  }
`;

EditRoomInventoryJobPage.query = gql`
  ${EditRoomInventoryJobPage.collectionFragment}
  ${Job.getFullName.fragment}
  ${CollectionItemsList.fragment}
  ${CreateCustomItemModal.fragment}
  ${InventoryHeader.fragment}
  ${InventorySidebar.fragment}
  ${UpdateRoomModal.fragment}

  query EditRoomInventoryJobPage($jobUuid: String!, $inventoryUuid: String!, $roomUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      ...Job_getFullName
    }
    inventory(uuid: $inventoryUuid) {
      id
      uuid
      project {
        id
        organization {
          id
          categories {
            id
            name
            isShared
            kind
          }
        }
      }
      rooms {
        id
        uuid
        name
        roomType {
          id
          primaryCategory {
            id
          }
        }
      }
      ...CollectionItemsList
      ...CreateCustomItemModal
      ...InventoryHeader
      ...InventorySidebar
    }
    room(uuid: $roomUuid) {
      id
      uuid
      name
      collection {
        id
        ...EditRoomInventoryJobPage_collectionFragment
      }
      ...UpdateRoomModal
    }
  }
`;

export default EditRoomInventoryJobPage;
