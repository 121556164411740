// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import ListProjectsPageContent from 'modules/Project/List/components/ListProjectsPageContent';
import ListProjectsPageContentV1 from 'modules/Project/List/components/ListProjectsPageContentV1';

const PageContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const LoadingComponentContainer = Styled.View`
  padding: 16px;
`;

const LoadingComponent = () => {
  const responsive = useResponsive();
  const loaderHeight = responsive.desktop ? SkeletonLoader.HEIGHT.SubheadingText : 120;
  return (
    <LoadingComponentContainer>
      <SkeletonLoader height={loaderHeight} isFullWidth />
      <Space height={16} />
      <SkeletonLoader height={loaderHeight} isFullWidth />
      <Space height={16} />
      <SkeletonLoader height={loaderHeight} isFullWidth />
    </LoadingComponentContainer>
  );
};

const ListProjectsPage = () => {
  const {loading, data} = useQuery(ListProjectsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <PageContainer>
      <Loading loading={loading} as={LoadingComponent}>
        {() => {
          return (
            <React.Fragment>
              {data.viewer.viewingOrganization.features.isEnabledResponsiveProjectsList ? (
                <ListProjectsPageContent organization={data.viewer.viewingOrganization} />
              ) : (
                <ListProjectsPageContentV1
                  organization={data.viewer.viewingOrganization}
                  jobRequestsCount={data.jobRequestCounter.new}
                />
              )}
            </React.Fragment>
          );
        }}
      </Loading>
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListProjectsPage.query = gql`
  ${ListProjectsPageContentV1.fragment}
  ${ListProjectsPageContent.fragment}

  query ListProjectsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledResponsiveProjectsList: isEnabled(feature: "RESPONSIVE_PROJECTS_LIST")
        }
        ...ListProjectsPageContentV1
        ...ListProjectsPageContent
      }
    }
    jobRequestCounter {
      new
    }
  }
`;

export default ListProjectsPage;
