// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Float, pluralize, withFragment} from '@supermove/utils';

const KIND_DISCOUNT = 'discount';
const KIND_FLAT_RATE = 'flat_rate';
const KIND_HOURLY = 'hourly';
const KIND_SUPPLIES = 'supplies';
const KIND_ADDITIONAL_HOURS = 'additional_hours';
const KIND_VALUATION_COVERAGE = 'valuation_coverage';

const KIND_DISPLAY_DISCOUNT = 'Discount';
const KIND_DISPLAY_FLAT_RATE = 'Flat Rate';
const KIND_DISPLAY_HOURLY = 'Hourly Rate';
const KIND_DISPLAY_SUPPLIES = 'Supplies';
const KIND_DISPLAY_ADDITIONAL_HOURS = 'Additional Hours';
const KIND_DISPLAY_VALUATION_COVERAGE = 'Valuation Coverage';

/**
 * If price is an empty string, convert it to 0 before a `toMutation` transformation.
 * This is necessary because of the `toForm` transformation on price.
 *
 * Additionally enforce that discount line items always have a negative price.
 */
const toMutationPrice = ({kind, price}: any) => {
  return kind === LineItem.KINDS.DISCOUNT
    ? Math.abs(Currency.toMutation(price || 0)) * -1
    : Currency.toMutation(price || 0);
};

const getDisplayKind = withFragment(
  (lineItem) => {
    switch ((lineItem as any).kind) {
      case KIND_DISCOUNT:
        return KIND_DISPLAY_DISCOUNT;
      case KIND_FLAT_RATE:
        return KIND_DISPLAY_FLAT_RATE;
      case KIND_HOURLY:
        return KIND_DISPLAY_HOURLY;
      case KIND_ADDITIONAL_HOURS:
        return KIND_DISPLAY_ADDITIONAL_HOURS;
      case KIND_VALUATION_COVERAGE:
        return KIND_DISPLAY_VALUATION_COVERAGE;
      case KIND_SUPPLIES:
      default:
        return KIND_DISPLAY_SUPPLIES;
    }
  },
  gql`
    fragment LineItem_getDisplayKind on LineItem {
      kind
    }
  `,
);

const getDisplayPrice = withFragment(
  (lineItem) => {
    const formattedPrice = Currency.display((lineItem as any).price, {shouldHideCentsIfZero: true});
    switch ((lineItem as any).kind) {
      case KIND_HOURLY:
      case KIND_ADDITIONAL_HOURS:
        return `${formattedPrice} / hour`;
      default:
        return formattedPrice;
    }
  },
  gql`
    fragment LineItem_getDisplayPrice on LineItem {
      kind
      price
    }
  `,
);

const getEstimateQuantity = withFragment(
  (lineItem) => {
    if (!(lineItem as any).quantity && !(lineItem as any).quantityTwo) {
      return 'TBD';
    }

    const hasDifferentQuantityTwo =
      !!(lineItem as any).quantityTwo &&
      Float.toFloat((lineItem as any).quantityTwo) > Float.toFloat((lineItem as any).quantity);
    const text = hasDifferentQuantityTwo
      ? `${(lineItem as any).quantity} - ${(lineItem as any).quantityTwo}`
      : (lineItem as any).quantity;
    switch ((lineItem as any).kind) {
      case LineItem.KINDS.HOURLY:
      case LineItem.KINDS.ADDITIONAL_HOURS:
        if (!(lineItem as any).unit) {
          return text;
        }
        if (hasDifferentQuantityTwo) {
          return `${text} ${pluralize((lineItem as any).unit, (lineItem as any).quantityTwo)}`;
        }
        return `${text} ${pluralize((lineItem as any).unit, (lineItem as any).quantity)}`;
      default:
        return text;
    }
  },
  gql`
    fragment LineItem_getEstimateQuantity on LineItem {
      kind
      quantity
      quantityTwo
      unit
    }
  `,
);

const getDisplayTotal = (total: any) => {
  return Currency.display(Math.abs(total), {shouldHideCentsIfZero: true});
};

const getEstimateTotal = withFragment(
  (lineItem) => {
    if (!(lineItem as any).estimateTotalMin && !(lineItem as any).estimateTotalMax) {
      return 'TBD';
    }

    const isDiscount = (lineItem as any).estimateTotalMin < 0;
    const hasValidMax =
      !!(lineItem as any).estimateTotalMax &&
      Math.abs((lineItem as any).estimateTotalMax) > Math.abs((lineItem as any).estimateTotalMin);

    // For items that are discounts, negative values on the bill, we want to show the estimate
    // or estimate range inside of parentheses and show the negative symbol on the outside.
    // Eg. Instead of "-$50", show "-($50)". And instead of "-$50 - -$100", show "-($50 - $100)".

    if (hasValidMax && isDiscount) {
      return (
        `(${getDisplayTotal((lineItem as any).estimateTotalMin)}` +
        ` - ${getDisplayTotal((lineItem as any).estimateTotalMax)})`
      );
    }
    if (hasValidMax && !isDiscount) {
      return `${getDisplayTotal((lineItem as any).estimateTotalMin)} - ${getDisplayTotal((lineItem as any).estimateTotalMax)}`;
    }
    if (!hasValidMax && isDiscount) {
      return `(${getDisplayTotal((lineItem as any).estimateTotalMin)})`;
    }
    return `${getDisplayTotal((lineItem as any).estimateTotalMin)}`;
  },
  gql`
    fragment LineItem_getEstimateTotal on LineItem {
      estimateTotalMin
      estimateTotalMax
    }
  `,
);

const LineItem = {
  KINDS: {
    DISCOUNT: KIND_DISCOUNT,
    FLAT_RATE: KIND_FLAT_RATE,
    HOURLY: KIND_HOURLY,
    SUPPLIES: KIND_SUPPLIES,
    ADDITIONAL_HOURS: KIND_ADDITIONAL_HOURS,
    VALUATION_COVERAGE: KIND_VALUATION_COVERAGE,
  },

  KINDS_ORDER: [KIND_HOURLY, KIND_FLAT_RATE, KIND_SUPPLIES, KIND_DISCOUNT, KIND_ADDITIONAL_HOURS],

  KINDS_DROPDOWN_OPTIONS: [
    {label: KIND_DISPLAY_HOURLY, value: KIND_HOURLY},
    {label: KIND_DISPLAY_FLAT_RATE, value: KIND_FLAT_RATE},
    {label: KIND_DISPLAY_SUPPLIES, value: KIND_SUPPLIES},
    {label: KIND_DISPLAY_DISCOUNT, value: KIND_DISCOUNT},
    {label: KIND_DISPLAY_ADDITIONAL_HOURS, value: KIND_ADDITIONAL_HOURS},
  ],

  KINDS_TAB_OPTIONS: [
    {label: KIND_DISPLAY_SUPPLIES, value: KIND_SUPPLIES},
    {label: KIND_DISPLAY_DISCOUNT, value: KIND_DISCOUNT},
    {label: KIND_DISPLAY_FLAT_RATE, value: KIND_FLAT_RATE},
    {label: KIND_DISPLAY_HOURLY, value: KIND_HOURLY},
    {label: KIND_DISPLAY_ADDITIONAL_HOURS, value: KIND_ADDITIONAL_HOURS},
  ],

  getDisplayKind,
  getDisplayPrice,

  getEstimateQuantity,
  getEstimateTotal,

  toForm: ({name, description, price, quantity, quantityTwo, kind, unit, total}: any) => ({
    name,
    description,
    // If price is 0, convert it to an empty string.
    // This prevents forms from starting with $0.00 as an initial value.
    price: price ? Currency.toForm(Math.abs(price)) : '',
    quantity: String(quantity),
    quantityTwo: String(quantityTwo),
    kind,
    unit,
    total: Currency.toForm(total),
  }),

  toMutation: ({name, description, price, quantity, quantityTwo, kind, unit}: any) => ({
    name,
    description,
    price: toMutationPrice({kind, price}),
    quantity: Float.toMutation(quantity),
    quantityTwo: Float.toMutation(quantityTwo),
    kind,
    unit,
  }),

  getDetails: ({kind, unit, quantity, price}: any) => {
    switch (kind) {
      case LineItem.KINDS.HOURLY:
      case LineItem.KINDS.ADDITIONAL_HOURS:
        return (
          `${quantity ? pluralize(unit, quantity, true) : 'TBD'} x ` +
          `${Currency.display(price)} / ${unit}`
        );
      case LineItem.KINDS.SUPPLIES:
        return `${quantity} x ${Currency.display(price)}`;
      case LineItem.KINDS.FLAT_RATE:
      case LineItem.KINDS.DISCOUNT:
      default:
        return '';
    }
  },
};

export default LineItem;
