// Supermove
import {gql} from '@supermove/graphql';
import {Proposal} from '@supermove/models';
import {Currency, Float, withFragment} from '@supermove/utils';

// App
import EmailForm from '@shared/modules/Email/forms/EmailForm';
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';

const stepsToForm = ({steps}: any) => {
  return steps.map((step: any) => Proposal.stepToForm(step));
};

const createStepInputs = ({steps}: any) => {
  const result = steps.map((step: any) => {
    const CREDIT_CARD_STEPS = [
      ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD,
      ConfirmationStepKind.CONFIRMATION_STEP_MAKE_DEPOSIT,
    ];

    if (CREDIT_CARD_STEPS.includes(step.kind)) {
      return {
        kind: step.kind,
        value: Currency.toMutation(step.value),
        valueTwo: step.valueTwo || '',
        actionText: step.actionText,
      };
    }

    return {
      kind: step.kind,
      isDone: step.isDone,
      value: step.value,
      valueTwo: step.valueTwo,
      actionText: step.actionText,
    };
  });
  return result;
};

const _new = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ viewerId, project, emailTempl... Remove this comment to see the full error message
  ({viewerId, project, emailTemplate, kind}) => {
    // @ts-expect-error TS(2339): Property 'isEnabledExpiration' does not exist on t... Remove this comment to see the full error message
    const {isEnabledExpiration, expirationTimeframeUnitKind, expirationTimeframe} =
      Proposal.getProjectTypeProposalExpirationDefaults({projectType: project.projectType, kind});
    return {
      projectId: project.id,
      kind,
      steps: Proposal.getProjectTypeConfirmationStepsShownByDefault({
        projectType: project.projectType,
        kind,
      }),
      emailForm: EmailForm.newFromProjectWithEmailTemplate({
        viewerId,
        project,
        emailTemplate,
        kind,
      }),
      isEnabledExpiration,
      expirationTimeframeUnitKind,
      expirationTimeframe,

      // Private
      isEditingSteps: false,
    };
  },
  gql`
    ${EmailForm.newFromProjectWithEmailTemplate.fragment}
    ${Proposal.getProjectTypeConfirmationStepsShownByDefault.fragment}
    ${Proposal.getProjectTypeProposalExpirationDefaults.fragment}

    fragment CreateAndSendConfirmationForProjectForm_new_Project on Project {
      id
      projectType {
        id
        ...Proposal_getProjectTypeConfirmationStepsShownByDefault
        ...Proposal_getProjectTypeProposalExpirationDefaults
      }
      ...EmailForm_newFromProjectWithEmailTemplate_Project
    }

    fragment CreateAndSendConfirmationForProjectForm_new_EmailTemplate on EmailTemplate {
      id
      kind
      ...EmailForm_newFromProjectWithEmailTemplate_EmailTemplate
    }
  `,
);

const toForm = ({
  projectId,
  kind,
  steps,
  emailForm,
  isEditingSteps,
  isEnabledExpiration,
  expirationTimeframeUnitKind,
  expirationTimeframe,
}: any) => ({
  projectId,
  kind,
  steps: stepsToForm({steps}),
  emailForm: EmailForm.toForm(emailForm),
  isEnabledExpiration,
  expirationTimeframeUnitKind,
  expirationTimeframe: Float.toForm(expirationTimeframe),

  // Private
  isEditingSteps,
});

const toMutation = ({
  projectId,
  kind,
  steps,
  emailForm,
  isEnabledExpiration,
  expirationTimeframeUnitKind,
  expirationTimeframe,
}: any) => ({
  projectId,
  kind,
  stepInputs: createStepInputs({steps}),
  emailForm: EmailForm.toMutation(emailForm),
  isEnabledExpiration,
  expirationTimeframeUnitKind,
  expirationTimeframe: Float.toMutation(expirationTimeframe),
});

const CreateAndSendConfirmationForProjectForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CreateAndSendConfirmationForProjectForm;
