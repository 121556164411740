// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getCreditCardClient = withFragment(
  (card) => {
    return {
      createCard: async () => ({
        env: (card as any).env,
        token: (card as any).token,
        card_data: {
          id: (card as any).cardId,
          name: (card as any).name,
          brand: (card as any).brand,
          exp_month: (card as any).expMonth,
          exp_year: (card as any).expYear,
          address_zip: (card as any).addressZip,
          last_4: (card as any).last4,
        },
      }),
    };
  },
  gql`
    fragment PayengineCreditCard_getCreditCardClient on PayEngineCreditCard {
      id
      env
      token
      cardId
      name
      brand
      expMonth
      expYear
      addressZip
      last4
    }
  `,
);

const PayengineCreditCard = {
  getCreditCardClient,
};

export default PayengineCreditCard;
