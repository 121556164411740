// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import JobProjectNavigation from './JobProjectNavigation';

const JobNavigation = ({job, viewer, handleOpenSidebar, refetch}) => {
  return (
    <JobProjectNavigation
      job={job}
      viewer={viewer}
      handleOpenSidebar={handleOpenSidebar}
      refetch={refetch}
    />
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobNavigation.propTypes = {};

JobNavigation.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobNavigation.fragment = gql`
  ${JobProjectNavigation.fragment}

  fragment JobNavigation_Viewer on User {
    id
    ...JobProjectNavigation_Viewer
  }

  fragment JobNavigation_Job on Job {
    id
    ...JobProjectNavigation_Job
  }
`;

export default JobNavigation;
