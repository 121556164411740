// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import EmptyState from '@shared/design/components/EmptyState';

const PageContainer = Styled.View`
  padding: 32px;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const InvoiceNotFoundPage = () => {
  return (
    <PageContainer>
      <EmptyState
        icon={Icon.ExclamationTriangle}
        iconColor={colors.orange.status}
        title={`Invoice not found.`}
        MessageComponent={() => (
          <EmptyState.DescriptionText style={{textAlign: 'center'}}>
            {`Please contact your moving company for further assistance.`}
          </EmptyState.DescriptionText>
        )}
      />
    </PageContainer>
  );
};

export default InvoiceNotFoundPage;
