// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useQuery} from '@supermove/hooks';

// App
import Button from '@shared/design/components/Button';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import OrganizationTaskTemplatesTable from 'modules/Organization/Settings/Tasks/components/OrganizationTaskTemplatesTable';
import NewTaskTemplateDrawer from 'modules/TaskManagement/TaskTemplates/components/NewTaskTemplateDrawer';

const OrganizationSettingsTaskTemplatesContent = ({organization, loading, refetch}) => {
  const newTaskTemplateDrawer = useDrawer({name: 'New Task Template Drawer'});

  return (
    <React.Fragment>
      <Button
        text={'Create Task Template'}
        iconLeft={Icon.Plus}
        onPress={newTaskTemplateDrawer.handleOpen}
      />
      <Space height={24} />
      <OrganizationTaskTemplatesTable
        organization={organization}
        loading={loading}
        refetch={refetch}
      />
      <NewTaskTemplateDrawer
        isOpen={newTaskTemplateDrawer.isOpen}
        handleClose={newTaskTemplateDrawer.handleClose}
        organization={organization}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsTaskTemplates = () => {
  const {data, loading, refetch} = useQuery(OrganizationSettingsTaskTemplates.query, {
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return (
          <OrganizationSettingsTaskTemplatesContent
            organization={data.viewer.viewingOrganization}
            loading={loading}
            refetch={refetch}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsTaskTemplates.query = gql`
  ${OrganizationTaskTemplatesTable.fragment}
  ${NewTaskTemplateDrawer.fragment}
  query OrganizationSettingsTaskTemplates {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...OrganizationTaskTemplatesTable
        ...NewTaskTemplateDrawer
      }
    }
  }
`;

export default OrganizationSettingsTaskTemplates;
