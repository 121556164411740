// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SlotTypeForm from '@shared/modules/Dispatch/forms/SlotTypeForm';

const useCreateSlotTypeMutation = ({slotTypeForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      slotTypeForm: SlotTypeForm.toForm(slotTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateSlotTypeMutation.mutation,
    variables: {
      slotTypeForm: SlotTypeForm.toMutation(form.values.slotTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateSlotTypeMutation.mutation = gql`
  mutation useCreateSlotTypeMutation($slotTypeForm: SlotTypeForm!) {
    response: createSlotType(slotTypeForm: $slotTypeForm) {
      ${gql.errors}
      slotType {
        id
      }
    }
  }
`;

export default useCreateSlotTypeMutation;
