// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ItemColumn = Styled.View`
  padding-horizontal: 20px;
  padding-top: 2px;
  padding-bottom: 4;
  width: 50%;
`;

const ItemRow = Styled.View`
  flex-direction: row;
`;

const Left = Styled.View`
  flex: 1;
`;

const Text = Styled.Text`
  ${Typography.Body2}
  color: ${colors.gray.secondary};
`;

const NotesText = Styled.Text`
  ${Typography.Micro}
`;

const BlackBoldText = Styled.Text`
  ${Typography.Body2}
  color: ${colors.gray.primary};
`;

const SmallBlackBoldText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const GrayBoldText = Styled.Text`
  ${Typography.Body2}
  color: ${colors.gray.secondary};
`;

const SmallGrayText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const LightLine = Styled.View`
  align-self: stretch;
  height: 1px;
  background-color: ${colors.blue.accent};
  margin-horizontal: 16px;
`;

const DarkLine = Styled.View`
  align-self: stretch;
  height: 1px;
  background-color: ${colors.gray.primary};
`;

const CollectionHeaderContainer = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-top: 4px;
  background-color: ${colors.gray.background};
`;

const CollectionHeaderRow = Styled.View`
  flex-direction: row;
  width: 50%;
  padding-bottom: 4px;
  padding-top: 2px;
  padding-horizontal: 19px;
  align-items: center;
`;

const CollectionItemsListContainer = Styled.View`
`;

const CollectionItemsListSection = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const CollectionItemsListSectionSeparator = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border};
  border-style: dashed;
  flex: 1;
  margin-bottom: 3px;
  margin-horizontal: 16px;
`;

const CollectionItemsListCenterVerticalDashedLine = Styled.View`
  border-right-width: 1px;
  border-color: ${colors.gray.border};
  border-style: dashed;
  position: absolute;
  align-self: center;
  height: 100%;
`;

const CollectionHeader = ({title, subtitle, columnLabel}: any) => {
  return (
    <CollectionHeaderContainer>
      <CollectionHeaderRow>
        <Left>
          <SmallBlackBoldText style={{flex: 1}}>{title}</SmallBlackBoldText>
          <SmallGrayText>{subtitle}</SmallGrayText>
        </Left>
        <Space width={4} />
        <SmallGrayText style={{alignItems: 'flex-end'}}>{columnLabel}</SmallGrayText>
      </CollectionHeaderRow>
      <CollectionHeaderRow>
        <BlackBoldText style={{flex: 1}} />
        <SmallGrayText style={{alignItems: 'flex-end'}}>{columnLabel}</SmallGrayText>
      </CollectionHeaderRow>
    </CollectionHeaderContainer>
  );
};

const CollectionFooter = ({collection, showWeight, showVolume}: any) => {
  return (
    <React.Fragment>
      <LightLine />
      <Space height={12} />
      <Row style={{paddingHorizontal: 20}}>
        <BlackBoldText style={{flex: 1}}>
          {`${pluralize('Item', collection.takeCount, true)}`}
        </BlackBoldText>
        {showVolume && (
          <GrayBoldText style={{alignItems: 'flex-end'}}>
            {`${_.round(collection.totalVolume, 2)} cb ft`}
          </GrayBoldText>
        )}
        {showWeight && showVolume && <Space width={20} />}
        {showWeight && (
          <GrayBoldText style={{alignItems: 'flex-end'}}>{`${_.round(
            collection.totalWeight,
            2,
          )} lb`}</GrayBoldText>
        )}
      </Row>
    </React.Fragment>
  );
};

const RoomsListHeader = ({collection, showWeight}: any) => {
  return (
    <Row style={{alignItems: 'center'}}>
      <Icon source={Icon.Archive} size={14} color={colors.blue.interactive} />
      <Space width={8} />
      <BlackBoldText style={{flex: 1}}>Rooms Summary (Cartons Included)</BlackBoldText>
      <BlackBoldText>Total</BlackBoldText>
      <Space width={16} />
      <BlackBoldText>
        {`${collection.takeCount} `}
        <Text>{`${pluralize('Item', collection.takeCount)}`}</Text>
      </BlackBoldText>
      {showWeight && (
        <React.Fragment>
          <Space width={14} />
          <BlackBoldText>
            {`${_.round(collection.totalWeight, 2)} `}
            <Text>lb</Text>
          </BlackBoldText>
        </React.Fragment>
      )}
    </Row>
  );
};

const RoomsListFooter = ({collection, showWeight, showVolume}: any) => {
  return (
    <Row style={{paddingHorizontal: 20}}>
      <BlackBoldText style={{flex: 1}}>
        {`Total ${pluralize('Item', collection.takeCount, true)}`}
      </BlackBoldText>
      {showVolume && (
        <GrayBoldText style={{alignItems: 'flex-end'}}>
          {`${_.round(collection.totalVolume, 2)} cb ft`}
        </GrayBoldText>
      )}
      {showVolume && showWeight && <Space width={20} />}
      {showWeight && (
        <GrayBoldText style={{alignItems: 'flex-end'}}>{`${_.round(
          collection.totalWeight,
          2,
        )} lb`}</GrayBoldText>
      )}
    </Row>
  );
};

const Room = ({room, showNotes, showWeight, showVolume}: any) => {
  return (
    <React.Fragment>
      <CollectionHeader
        title={room.name}
        subtitle={room.description}
        columnLabel={showVolume ? 'cb ft' : ''}
      />
      <RoomCollectionItemsList
        items={room.collection.filteredItems}
        showNotes={showNotes}
        showVolume={showVolume}
      />
      <CollectionFooter
        collection={room.collection}
        showWeight={showWeight}
        showVolume={showVolume}
      />
    </React.Fragment>
  );
};

const getGroupedItems = ({items}: any) => {
  const itemItems: any = [];
  const cartonItems: any = [];

  _.forEach(items, (item) => {
    if (_.get(item, 'itemType.kind') === 'CARTON') {
      cartonItems.push(item);
    } else {
      itemItems.push(item);
    }
  });

  return {itemItems, cartonItems};
};

const RoomCollectionItemsList = ({items, showNotes, showVolume}: any) => {
  const {itemItems, cartonItems} = getGroupedItems({items});

  return (
    <CollectionItemsListContainer>
      <Space height={12} />
      <CollectionItemsListSection>
        {/* @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type. */}
        {itemItems.map((item) => {
          return (
            <RoomCollectionItem
              item={item}
              key={item.id}
              showNotes={showNotes}
              showVolume={showVolume}
            />
          );
        })}
      </CollectionItemsListSection>
      {itemItems.length > 0 && cartonItems.length > 0 && <CollectionItemsListSectionSeparator />}
      <CollectionItemsListSection>
        {/* @ts-expect-error TS(7006): Parameter 'carton' implicitly has an 'any' type. */}
        {cartonItems.map((carton) => {
          return (
            <RoomCollectionItem
              item={carton}
              key={carton.id}
              showNotes={showNotes}
              showVolume={showVolume}
            />
          );
        })}
      </CollectionItemsListSection>
      <Space height={8} />
      <CollectionItemsListCenterVerticalDashedLine />
    </CollectionItemsListContainer>
  );
};

const RoomCollectionItem = ({item, showNotes, showVolume}: any) => {
  return (
    <ItemColumn>
      <ItemRow>
        <BlackBoldText style={{width: 30}}>{item.takeCount}</BlackBoldText>
        <Text style={{flex: 1}}>{item.name}</Text>
        {showVolume && <GrayBoldText>{_.round(item.volume, 2)}</GrayBoldText>}
      </ItemRow>
      {showNotes && !!item.notes && (
        <React.Fragment>
          <Space height={4} />
          <NotesText numberOfLines={1}>{item.notes}</NotesText>
        </React.Fragment>
      )}
    </ItemColumn>
  );
};

const CartonCategoriesListHeader = () => {
  return (
    <Row style={{alignItems: 'center'}}>
      <Icon source={Icon.Archive} size={14} color={colors.blue.interactive} />
      <Space width={8} />
      <BlackBoldText style={{flex: 1}}>Cartons Summary</BlackBoldText>
    </Row>
  );
};

const getCartonTotals = (cartonCategories: any) => {
  return _.reduce(
    cartonCategories,
    (acc, cartonCategory) => {
      return {
        takeCount: acc.takeCount + cartonCategory.collection.takeCount,
        totalWeight: acc.totalWeight + cartonCategory.collection.totalWeight,
        totalVolume: acc.totalVolume + cartonCategory.collection.totalVolume,
      };
    },
    {takeCount: 0, totalWeight: 0, totalVolume: 0},
  );
};

const CartonCategoriesListFooter = ({cartonCategories, showWeight, showVolume}: any) => {
  const {takeCount, totalWeight, totalVolume} = getCartonTotals(cartonCategories);

  return (
    <Row style={{paddingHorizontal: 20}}>
      <BlackBoldText style={{flex: 1}}>{`Total ${pluralize(
        'Carton',
        takeCount,
        true,
      )}`}</BlackBoldText>
      {showVolume && (
        <GrayBoldText style={{alignItems: 'flex-end'}}>
          {`${_.round(totalVolume, 2)} cb ft`}
        </GrayBoldText>
      )}
      {showVolume && showWeight && <Space width={20} />}
      {showWeight && (
        <GrayBoldText style={{alignItems: 'flex-end'}}>{`${totalWeight} lb`}</GrayBoldText>
      )}
    </Row>
  );
};

const CartonCategory = ({cartonCategory, showNotes, showWeight, showVolume}: any) => {
  return (
    <React.Fragment>
      <CollectionHeader title={cartonCategory.category.name} columnLabel={'Room'} />
      <CartonCategoryCollectionItemsList
        items={cartonCategory.collection.filteredItems}
        showNotes={showNotes}
      />
      <CollectionFooter
        collection={cartonCategory.collection}
        showWeight={showWeight}
        showVolume={showVolume}
      />
    </React.Fragment>
  );
};

const CartonCategoryCollectionItemsList = ({items, showNotes}: any) => {
  return (
    <CollectionItemsListContainer>
      <Space height={12} />
      <CollectionItemsListSection>
        {items.map((item: any) => {
          return <CartonCategoryItem item={item} key={item.id} showNotes={showNotes} />;
        })}
      </CollectionItemsListSection>
      <Space height={8} />
      <CollectionItemsListCenterVerticalDashedLine />
    </CollectionItemsListContainer>
  );
};

const CartonCategoryItem = ({item, showNotes}: any) => {
  return (
    <ItemColumn>
      <ItemRow>
        <BlackBoldText style={{width: 30}}>{item.takeCount}</BlackBoldText>
        <Text style={{flex: 1}}>{item.name}</Text>
        <GrayBoldText style={{maxWidth: 120}}>{item.collection.room.name}</GrayBoldText>
      </ItemRow>
      {showNotes && !!item.notes && (
        <React.Fragment>
          <Space height={4} />
          <NotesText numberOfLines={1}>{item.notes}</NotesText>
        </React.Fragment>
      )}
    </ItemColumn>
  );
};

const DocumentV2InventorySummaryContent = ({inventory, showNotes, showWeight, showVolume}: any) => {
  return (
    <Container>
      <RoomsListHeader collection={inventory.collection} showWeight={showWeight} />
      {inventory.rooms.length > 0 && (
        <React.Fragment>
          {inventory.rooms.map((room: any) => {
            return (
              <React.Fragment key={room.id}>
                <Space height={18} />
                <Room
                  room={room}
                  showNotes={showNotes}
                  showWeight={showWeight}
                  showVolume={showVolume}
                />
                <Space height={18} />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )}
      <DarkLine />
      <Space height={8} />
      <RoomsListFooter
        collection={inventory.collection}
        showWeight={showWeight}
        showVolume={showVolume}
      />
      <React.Fragment>
        <Space height={48} />
        <CartonCategoriesListHeader />
        {inventory.rooms.length > 0 && (
          <React.Fragment>
            {inventory.cartonCategories.map((cartonCategory: any) => {
              return (
                <React.Fragment key={cartonCategory.id}>
                  <Space height={16} />
                  <CartonCategory
                    cartonCategory={cartonCategory}
                    showNotes={showNotes}
                    showWeight={showWeight}
                    showVolume={showVolume}
                  />
                  <Space height={16} />
                </React.Fragment>
              );
            })}
          </React.Fragment>
        )}
        <DarkLine />
        <Space height={8} />
        <CartonCategoriesListFooter
          cartonCategories={inventory.cartonCategories}
          showWeight={showWeight}
          showVolume={showVolume}
        />
      </React.Fragment>
    </Container>
  );
};

const DocumentV2InventorySummaryPreview = ({showNotes, showWeight, showVolume}: any) => {
  const inventory = MOCK_INVENTORY_DATA;
  return (
    <DocumentV2InventorySummaryContent
      inventory={inventory}
      showNotes={showNotes}
      showWeight={showWeight}
      showVolume={showVolume}
    />
  );
};

const DocumentV2InventorySummaryNonPreview = ({
  project,
  showNotes,
  showWeight,
  showVolume,
}: any) => {
  return (
    <DocumentV2InventorySummaryContent
      inventory={project.inventory}
      showNotes={showNotes}
      showWeight={showWeight}
      showVolume={showVolume}
    />
  );
};

type OwnProjectDocumentV2InventorySummaryProps = {
  isPreview?: boolean;
  showNotes?: boolean;
  showWeight?: boolean;
  showVolume?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'ProjectDocumentV2InventorySummaryProps... Remove this comment to see the full error message
type ProjectDocumentV2InventorySummaryProps = OwnProjectDocumentV2InventorySummaryProps &
  typeof ProjectDocumentV2InventorySummary.defaultProps;

// @ts-expect-error TS(7022): 'ProjectDocumentV2InventorySummary' implicitly has... Remove this comment to see the full error message
const ProjectDocumentV2InventorySummary = ({
  isPreview,
  project,
  showNotes,
  showWeight,
  showVolume,
}: ProjectDocumentV2InventorySummaryProps) => {
  return (
    <React.Fragment>
      {isPreview ? (
        <DocumentV2InventorySummaryPreview
          showNotes={showNotes}
          showWeight={showWeight}
          showVolume={showVolume}
        />
      ) : (
        <DocumentV2InventorySummaryNonPreview
          project={project}
          showNotes={showNotes}
          showWeight={showWeight}
          showVolume={showVolume}
        />
      )}
    </React.Fragment>
  );
};
ProjectDocumentV2InventorySummary.defaultProps = {
  isPreview: false,
  showNotes: false,
  showWeight: true,
  showVolume: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectDocumentV2InventorySummary.fragment = gql`
  fragment ProjectDocumentV2InventorySummary on Project {
    id
    inventory {
      id
      collection {
        id
        takeCount
        totalVolume
        totalWeight
      }
      rooms {
        id
        name
        description
        collection {
          id
          takeCount
          totalWeight
          totalVolume
          filteredItems {
            id
            notes
            name
            takeCount
            volume
            itemType {
              id
              kind
            }
          }
        }
      }
      cartonCategories {
        id
        category {
          id
          name
        }
        collection {
          id
          takeCount
          totalWeight
          totalVolume
          filteredItems {
            id
            notes
            itemTypeId
            name
            takeCount
            volume
            collection {
              id
              room {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_INVENTORY_DATA = {
  id: 1,
  collection: {
    id: 1,
    takeCount: 1,
    totalVolume: 1000,
    totalWeight: 1000,
  },
  rooms: [
    {
      id: 1,
      name: 'Room',
      description: 'Description',
      collection: {
        id: 1,
        takeCount: 1,
        totalWeight: 1000,
        totalVolume: 1000,
        filteredItems: [
          {
            id: 1,
            name: 'Item',
            notes: 'A description of the item',
            takeCount: 1,
            volume: 500,
            itemType: {
              id: 1,
              kind: 'KIND',
            },
          },
        ],
      },
    },
  ],
  cartonCategories: [
    {
      id: 1,
      category: {
        id: 1,
        name: 'Name',
      },
      collection: {
        id: 1,
        takeCount: 1,
        totalWeight: 1000,
        totalVolume: 1000,
        filteredItems: [
          {
            id: 1,
            itemTypeId: 1,
            name: 'Name',
            notes: 'A description of the carton',
            takeCount: 1,
            volume: 1000,
            collection: {
              id: 1,
              room: {
                id: 1,
                name: 'Name',
              },
            },
          },
        ],
      },
    },
  ],
};

export default ProjectDocumentV2InventorySummary;
