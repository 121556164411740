// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import useUpdateProjectLocationMutation from '@shared/modules/Project/hooks/useUpdateProjectLocationMutation';
import EditProjectLocationFields from 'modules/Project/V2/Edit/components/EditProjectLocationFields';

const EditProjectLocationDrawer = ({isOpen, handleClose, locationForm, onSuccess}) => {
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Saved location updated!',
  });

  const {form, handleSubmit, submitting} = useUpdateProjectLocationMutation({
    locationForm: {
      ...locationForm,
      locationId: locationForm.locationId || locationForm.copyOfLocationId,
    },
    onSuccess: () => {
      handleClose();
      successToast.handleToast();
      onSuccess(form.values.locationForm);
    },
    onError: (errors) => console.log({errors}),
  });

  const {data, loading} = useQuery(EditProjectLocationDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !locationForm.locationId && !locationForm.copyOfLocationId,
    variables: {locationId: locationForm.locationId || locationForm.copyOfLocationId},
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Edit Saved Location'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      primaryActionText={'Save'}
      width={520}
    >
      <Loading loading={loading || !data}>
        {() => {
          return (
            <EditProjectLocationFields
              form={form}
              field={'locationForm'}
              project={data.location.project}
              autoFocus
              showJobsCallout
            />
          );
        }}
      </Loading>
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectLocationDrawer.query = gql`
  ${EditProjectLocationFields.fragment}

  query EditProjectLocationDrawer($locationId: Int!) {
    ${gql.query}
    location(locationId: $locationId) {
      id
      project {
        id
        ...EditProjectLocationFields
      }
    }
  }
`;

export default EditProjectLocationDrawer;
