// Supermove
import {DocumentItemType, DocumentItemSectionType, DocumentItemColumnType} from '@supermove/models';

// Shared
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';

export type SelectedDocumentItemType = {
  documentItem?: DocumentItemType;
  index?: number;
  sectionIndex?: number;
  columnIndex?: number;
};
export type SetSelectedDocumentItemType = (item: SelectedDocumentItemType, index?: number) => void;
export type DocumentContentJsonType = {documentItems: DocumentItemType[]};
export type SetDocumentContentJsonType = (func: (json: DocumentContentJsonType) => void) => void;

// Types with sections
export type SelectedDocumentItemInSectionType = SelectedDocumentItemType & {
  sectionIndex: number;
};
export type DocumentContentJsonWithSectionsType = {documentItems: DocumentItemSectionType[]};
export type SetDocumentContentJsonWithSectionsType = (
  func: (json: DocumentContentJsonWithSectionsType) => void,
) => void;

const DocumentTemplateVersionEditorTypes = {
  DocumentItem: {
    getIsSection: (documentItem: DocumentItemType): documentItem is DocumentItemSectionType => {
      return documentItem.itemKind === DocumentItemKindV2.SECTION;
    },
    getIsColumn: (documentItem: DocumentItemType): documentItem is DocumentItemColumnType => {
      return documentItem.itemKind === DocumentItemKindV2.COLUMN;
    },
  },
};

export default DocumentTemplateVersionEditorTypes;
