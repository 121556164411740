/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

import {gql} from '@supermove/graphql';

// App
import EmployeeMoveTimesChart from 'modules/Customer/Document/components/EmployeeMoveTimesChart';

const JobMoveTimesheetView = ({job}) => {
  return (
    <EmployeeMoveTimesChart
      hasConfirmedTimes={_.get(job, 'move.hasConfirmedTimes')}
      timeRanges={_.get(job, 'move.timeRanges', [])}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobMoveTimesheetView.fragment = gql`
  fragment JobMoveTimesheetView on Job {
    id
    move {
      id
      hasConfirmedTimes
      timeRanges {
        name
        kind
        start
        end
      }
    }
  }
`;

export default JobMoveTimesheetView;
