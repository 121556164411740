// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  DateInput,
  DropdownInput,
  LocationInput,
  MultiDropdownInput,
  Space,
  Styled,
  Icon,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {openNewTab, Datetime} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import LocationForm from '@shared/modules/Location/forms/LocationForm';

const SectionContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
  padding: 12px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white};
`;

const Row = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex-direction: row;
`;

const SectionTitle = Styled.Text`
  ${Typography.Label1}
`;

const Button = Styled.ButtonV2`
  flex: 1;
`;

const IconContainer = Styled.View`
  width: 16px;
`;

const FieldSpace = () => {
  return <Space height={12} width={12} />;
};

const SectionSpace = () => {
  return <Space height={16} />;
};

const Section = ({title, children, index}) => {
  return (
    <SectionContainer index={index}>
      <SectionTitle>{title}</SectionTitle>
      <FieldSpace />
      {children}
    </SectionContainer>
  );
};

const LabelIconComponent = ({text}) => {
  return (
    <React.Fragment>
      <TextTooltip placement={'right'} text={text}>
        <IconContainer>
          <Icon source={Icon.Lock} color={colors.gray.tertiary} size={12} />
        </IconContainer>
      </TextTooltip>
    </React.Fragment>
  );
};

const TripDetailsSection = ({form, field, index}) => {
  return (
    <Section title={'Trip Details'} index={index}>
      <FieldInput.Memoized
        {...form}
        label={'Name'}
        name={`${field}.name`}
        input={{required: !_.get(form.values, `${field}.name`), placeholder: 'Enter trip name'}}
      />
      <FieldSpace />
      <Row index={0}>
        <FieldInput.Memoized
          {...form}
          component={LocationInput}
          name={`${field}.startLocationForm.address`}
          label={'Origin'}
          LabelIconComponent={() => (
            <LabelIconComponent text={'Start location is set by the earliest shipment date.'} />
          )}
          input={{
            disabled: true,
          }}
          style={{flex: 1}}
        />
        <FieldSpace />
        <FieldInput.Memoized
          {...form}
          component={DateInput}
          label={'Start Date'}
          name={`${field}.startDate`}
          style={{flex: 1}}
          LabelIconComponent={() => (
            <LabelIconComponent text={'Start date is set by the earliest shipment date.'} />
          )}
          input={{
            disabled: true,
            style: {width: '100%'},
          }}
        />
      </Row>
      <FieldSpace />
      <Row index={1}>
        <FieldInput.Memoized
          {...form}
          component={LocationInput}
          name={`${field}.endLocationForm.address`}
          label={'Destination'}
          LabelIconComponent={() => (
            <LabelIconComponent text={'End location is set by the latest shipment date.'} />
          )}
          input={{
            disabled: true,
          }}
          style={{flex: 1}}
        />
        <FieldSpace />
        <FieldInput.Memoized
          {...form}
          component={DateInput}
          label={'End Date'}
          name={`${field}.endDate`}
          style={{flex: 1}}
          LabelIconComponent={() => (
            <LabelIconComponent text={'End date is set by the latest shipment date.'} />
          )}
          input={{
            disabled: true,
            style: {width: '100%'},
          }}
        />
      </Row>
    </Section>
  );
};

const DriverDetailsSection = ({form, field, index, organization}) => {
  return (
    <Section title={'Driver Details'} index={index}>
      <Row index={0}>
        <FieldInput.Memoized
          {...form}
          component={DropdownInput}
          label={'Driver'}
          name={`${field}.driverId`}
          input={{
            placeholder: 'Select a driver',
            options: organization.activeDrivers.map((driver) => ({
              value: driver.id,
              label: driver.fullName,
            })),
            setFieldValue: form.setFieldValue,
            style: {flex: 1},
            isClearable: true,
          }}
          style={{flex: 1}}
        />
        <FieldSpace />
        <FieldInput.Memoized
          {...form}
          label={'Truck(s)'}
          name={`${field}.truckIds`}
          component={MultiDropdownInput}
          input={{
            options: organization.activeTrucks.map((truck) => ({
              value: truck.id,
              label: truck.name,
            })),
            placeholder: `Select trucks`,
            setFieldValue: form.setFieldValue,
            style: {flex: 1},
          }}
          style={{flex: 1}}
        />
      </Row>
    </Section>
  );
};

const getColumnDefinitions = () => [
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Customer</Table.HeaderText>;
    },
    cellContent: ({item: shipmentForm}) => {
      return (
        <Button onPress={() => openNewTab(`/clients/${shipmentForm.clientUuid}`)}>
          <Table.CellLink numberOfLines={2} style={{textDecorationLine: 'underline'}}>
            {shipmentForm.clientName}
          </Table.CellLink>
        </Button>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Weight</Table.HeaderText>;
    },
    cellContent: ({item: shipmentForm}) => {
      const {weight} = shipmentForm;
      return <Table.CellText>{weight ? `${weight} lbs` : 'TBD'}</Table.CellText>;
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Load Date</Table.HeaderText>;
    },
    cellContent: ({item: shipmentForm}) => {
      const {loadJobScheduledDate, loadJobEndDate} = shipmentForm;
      const displayLoadJobScheduledDate = Datetime.convertToDisplayDate(
        loadJobScheduledDate,
        Datetime.DISPLAY_SHORT_DATE,
      );
      const displayLoadJobEndDate = Datetime.convertToDisplayDate(
        loadJobEndDate,
        Datetime.DISPLAY_SHORT_DATE,
      );
      return (
        <Table.CellText>
          {(loadJobScheduledDate && displayLoadJobScheduledDate) ||
            (loadJobEndDate && displayLoadJobEndDate) ||
            '-'}
        </Table.CellText>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Delivery Window</Table.HeaderText>;
    },
    cellContent: ({item: shipmentForm}) => {
      const {deliveryJobStartDate, deliveryJobEndDate} = shipmentForm;
      const displayDeliveryJobStartDate = Datetime.convertToDisplayDate(
        deliveryJobStartDate,
        Datetime.DISPLAY_SHORT_DATE,
      );
      const displayDeliveryJobEndDate = Datetime.convertToDisplayDate(
        deliveryJobEndDate,
        Datetime.DISPLAY_SHORT_DATE,
      );

      if (
        deliveryJobStartDate &&
        deliveryJobEndDate &&
        deliveryJobStartDate !== deliveryJobEndDate
      ) {
        return (
          <Table.CellText>
            {displayDeliveryJobStartDate} - {displayDeliveryJobEndDate}
          </Table.CellText>
        );
      }

      return (
        <Table.CellText>{deliveryJobStartDate ? displayDeliveryJobStartDate : '-'}</Table.CellText>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Actual Delivery Date</Table.HeaderText>;
    },
    cellContent: ({item: shipmentForm}) => {
      const {deliveryJobScheduledDate} = shipmentForm;
      const displayDeliveryJobScheduledDate = Datetime.convertToDisplayDate(
        deliveryJobScheduledDate,
        Datetime.DISPLAY_SHORT_DATE,
      );
      return (
        <Table.CellText>
          {deliveryJobScheduledDate ? displayDeliveryJobScheduledDate : '-'}
        </Table.CellText>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Origin</Table.HeaderText>;
    },
    cellContent: ({item: shipmentForm}) => {
      return (
        <Table.CellText>
          {LocationForm.getDisplayCityState(shipmentForm.originLocationForm)}
        </Table.CellText>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Destination</Table.HeaderText>;
    },
    cellContent: ({item: shipmentForm}) => {
      return (
        <Table.CellText>
          {LocationForm.getDisplayCityState(shipmentForm.destinationLocationForm)}
        </Table.CellText>
      );
    },
  },
];

const ShipmentDetailsSection = ({form, field, index}) => {
  const shipmentForms = _.get(form.values, `${field}.shipmentForms`);
  return (
    <Section title={'Shipment Details'} index={index}>
      {!_.isEmpty(shipmentForms) && (
        <React.Fragment>
          <Space height={4} />
          <Table columnDefinitions={getColumnDefinitions()} items={shipmentForms} isDense />
        </React.Fragment>
      )}
    </Section>
  );
};

const InternalInformationSection = ({form, field, index}) => {
  return (
    <Section title={'Internal Information'} index={index}>
      <FieldInput.Memoized
        {...form}
        label={'Office Notes (visible to office only)'}
        name={`${field}.officeNotes`}
        style={{flex: 1}}
        input={{
          style: {height: 96, paddingTop: 8},
          multiline: true,
          placeholder: 'Enter any office notes',
        }}
      />
      <FieldSpace />
      <FieldInput.Memoized
        {...form}
        label={'Driver Notes (visible to driver)'}
        name={`${field}.driverNotes`}
        style={{flex: 1}}
        input={{
          style: {height: 96, paddingTop: 8},
          multiline: true,
          placeholder: 'Enter any driver notes',
        }}
      />
    </Section>
  );
};

const TripModalContent = ({form, field, organization}) => {
  const hasShipments = _.get(form.values, `${field}.shipmentForms`).length > 0;
  return (
    <React.Fragment>
      <TripDetailsSection form={form} field={field} index={0} />
      <SectionSpace />
      <DriverDetailsSection form={form} field={field} index={1} organization={organization} />
      <SectionSpace />
      {hasShipments && (
        <React.Fragment>
          <ShipmentDetailsSection form={form} field={field} index={2} />
          <SectionSpace />
        </React.Fragment>
      )}
      <InternalInformationSection form={form} field={field} index={3} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TripModalContent.fragment = gql`
  fragment TripModalContent_Organization on Organization {
    id
    activeDrivers {
      id
      fullName
    }
    activeTrucks {
      id
      name
    }
  }
`;

export default TripModalContent;
