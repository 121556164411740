/**
 * WARNING (warren)
 * This is a web component that renders HTML used to generate a PDF.
 */

// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Document} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// Components
import DocumentCustomerSignature from './DocumentCustomerSignature';
import DocumentFreightBillInfo from './DocumentFreightBillInfo';
import DocumentHeaderOrganization from './DocumentHeaderOrganization';

const Container = Styled.View`
  width: 100%;
  background-color: ${colors.white};
`;

const Section = Styled.View`
  margin-top: 20px;
`;

const SectionBody = Styled.H7`
  margin-top: 5px;
  font-size: 13px;
  line-height: 16px;
  color: ${colors.gray.primary};
`;

const Items = Styled.View`
  margin-top: 10px;
`;

const ItemText = Styled.H7`
  font-size: 16px;
  line-height: 20px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const getBodyText = ({document}) => {
  return (
    `Customer acknowledges and agrees that cost for performing the additional ` +
    `services shall be paid by Company as indicated above and such services ` +
    `and the costs thereof are not included in the original estimate, bid price, ` +
    `or agreement.\n\n` +
    `The "Not to Exceed" price is now: ` +
    `${Currency.display(Document.getAdditionalInfo(document).notToExceedPrice)}\n\n` +
    `The following items have changed resulting in a change of scope:`
  );
};

const getSignature = ({job}) => {
  const document = job.move.changeOrderDocument;
  const documentSignature = _.first(document.documentSignatures);
  return _.get(documentSignature, 'signature', null);
};

const JobChangeOrderDocument = ({job}) => (
  <Container>
    <DocumentHeaderOrganization title={'Change Order'} organization={job.organization} />
    <DocumentFreightBillInfo job={job} />
    <Section>
      <SectionBody>
        {getBodyText({
          document: job.move.changeOrderDocument,
          organization: job.organization,
        })}
      </SectionBody>
    </Section>
    <Items>
      {Document.getAdditionalInfo(job.move.changeOrderDocument).items.map((item, index) => (
        <ItemText key={index}>{`${index + 1}. ${item}`}</ItemText>
      ))}
    </Items>
    <Section>
      <DocumentCustomerSignature signature={getSignature({job})} />
    </Section>
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobChangeOrderDocument.propTypes = {
  job: PropTypes.object.isRequired,
};

JobChangeOrderDocument.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobChangeOrderDocument.fragment = gql`
  ${DocumentCustomerSignature.fragment}
  ${DocumentFreightBillInfo.fragment}
  ${DocumentHeaderOrganization.fragment}

  fragment JobChangeOrderDocument on Job {
    id
    organization {
      id
      name
      ...DocumentHeaderOrganization
    }
    move {
      id
      changeOrderDocument {
        id
        additionalInfo
        documentSignatures {
          id
          documentId
          signatureId
          signature {
            id
            ...DocumentCustomerSignature
          }
        }
      }
    }
    ...DocumentFreightBillInfo
  }
`;

export default JobChangeOrderDocument;
