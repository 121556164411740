// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Datetime, Json} from '@supermove/utils';

// Components
import {JobUserStatusBadge} from 'modules/App/Job/components';

const Container = Styled.View`
  justify-content: flex-start;
  width: ${(props) => props.width}px;
  height: 75px;
  padding: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  background-color: ${(props) => props.color};
  opacity: ${(props) => props.opacity || 1.0};
  overflow: hidden;
  cursor: ${(props) => props.cursor};
`;

const Job = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-horizontal: 3px;
  padding-vertical: ${(props) => (props.isTarget ? '10px' : '5px')};
  margin-bottom: 3px;
  background-color: ${(props) => (props.isTarget ? colors.gray.primary : colors.gray.tertiary)};
  border-radius: 3px;
`;

const JobText = Styled.H8`
  font-weight: ${({vars = {}}) => (vars.isTarget ? 700 : 500)};
  color: ${colors.white};
`;

const IndicatorWrapper = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Indicator = Styled.Loading`
`;

const shouldShowBadge = ({jobUser}) => {
  const notificationSettings = _.get(jobUser, 'job.organization.settings.notifications', '{}');
  const json = Json.toCamelCaseKeys(Json.parse(notificationSettings));
  return json.allowEmployeeText;
};

const sortJobUsers = ({jobUsers}) => {
  return _.sortBy(jobUsers, ['job.startTime1', 'id']);
};

/**
 * Render the jobs assigned to crew member for a day.
 * The difference between V2 and V1 is V2 renders
 * JobUsers, whereas V1 renders Jobs.
 */
const ScheduleCellV2 = ({
  loading,
  boldJobId,
  width,
  jobUsers,
  styleColor,
  styleCursor,
  styleOpacity,
}) => (
  <Container color={styleColor} cursor={styleCursor} opacity={styleOpacity} width={width}>
    {loading ? (
      <IndicatorWrapper>
        <Indicator size={'small'} color={colors.gray.border} />
      </IndicatorWrapper>
    ) : (
      <React.Fragment>
        {sortJobUsers({jobUsers}).map((jobUser) => (
          <Job key={jobUser.id} isTarget={jobUser.job.id === boldJobId}>
            <JobText numberOfLines={1} vars={{isTarget: jobUser.job.id === boldJobId}}>
              {`#${jobUser.job.identifier} - ${Datetime.convertToDisplayTime(
                jobUser.job.startTime1,
              )}`}
            </JobText>
            {shouldShowBadge({jobUser}) && <JobUserStatusBadge jobUser={jobUser} />}
          </Job>
        ))}
      </React.Fragment>
    )}
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ScheduleCellV2.propTypes = {
  loading: PropTypes.bool,
  boldJobId: PropTypes.string,
  width: PropTypes.number,
  jobUsers: PropTypes.array.isRequired,
  styleColor: PropTypes.string.isRequired,
  styleCursor: PropTypes.string.isRequired,
  styleOpacity: PropTypes.number.isRequired,
};

ScheduleCellV2.defaultProps = {
  boldJobId: null,
  width: 130,
  loading: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ScheduleCellV2.fragment = gql`
  ${JobUserStatusBadge.fragment}

  fragment ScheduleCellV2 on JobUser {
    id
    job {
      id
      identifier
      startTime1
      organization {
        id
        settings {
          id
          notifications
        }
      }
    }
    ...JobUserStatusBadge
  }
`;

export default ScheduleCellV2;
