// Libararies
import React from 'react';

// Supermove
import {Loading, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import FullWidthTabs from '@shared/design/components/Tabs/FullWidthTabs';
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import LongDistanceDispatchTripsList from 'modules/Dispatch/LongDistance/components/LongDistanceDispatchTripsList';
import EmployeePage from 'modules/Employee/components/EmployeePage';

const Container = Styled.View`
  flex: 1;
  max-width: 500px;
  padding-left: ${({responsive}) => (responsive.mobile ? '0px' : '16px')};
  border-right-width: ${({responsive}) => (responsive.mobile ? '0px' : '1px')};
  border-color: ${colors.gray.background};
`;

const TripsListContentContainer = Styled.View`
  flex: 1;
`;

const TabsContainer = Styled.View`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.border};
  width: 100%;
`;

const HeaderContainer = Styled.View`
  padding-horizontal: ${({mobile}) => (mobile ? '12px' : '20px')};
`;

const Title = Styled.Text`
  ${Typography.Heading5}
`;

const Subtitle = Styled.Text`
  ${Typography.Body2}
`;

const TripsListHeader = ({viewer}) => {
  const responsive = useResponsive();
  return (
    <HeaderContainer {...responsive}>
      <Space height={responsive.mobile ? 16 : 32} />
      <Title>{viewer.viewingOrganization.name}</Title>
      <Space height={12} />
      <Subtitle>{`Please view your assigned trips below.`}</Subtitle>
      <Space height={16} />
    </HeaderContainer>
  );
};

const LongDistanceDispatchTripsListPageTabContent = ({tabIndex}) => {
  if (tabIndex === 1) {
    return <LongDistanceDispatchTripsList statuses={[TripStatus.COMPLETED]} />;
  }
  return <LongDistanceDispatchTripsList statuses={[TripStatus.IN_TRANSIT, TripStatus.PLANNED]} />;
};

const getTabIndex = ({navigator}) => {
  if (navigator.location.pathname.includes('1/dispatch/trips/active')) {
    return 0;
  }
  if (navigator.location.pathname.includes('1/dispatch/trips/past')) {
    return 1;
  }
};

const TripsListTabs = ({navigator, activeTabIndex, tripCountsByStatus}) => {
  const tabDefinitions = [
    {
      label: 'Active',
      value: 'active',
      count: tripCountsByStatus.plannedCount + tripCountsByStatus.inTransitCount,
    },
    {label: 'Past', value: 'past', count: tripCountsByStatus.completedCount},
  ];

  return (
    <TabsContainer>
      <FullWidthTabs
        tabs={tabDefinitions}
        handlePressTab={({value}) => navigator.replace(`${value}`)}
        activeTabIndex={activeTabIndex}
      />
    </TabsContainer>
  );
};

const LongDistanceDispatchTripsListPage = () => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const activeTabIndex = getTabIndex({navigator});
  const {data, loading} = useQuery(LongDistanceDispatchTripsListPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <EmployeePage selected={'dispatchtripview'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <Container responsive={responsive}>
            <TripsListHeader viewer={data.viewer} />
            <TripsListTabs
              navigator={navigator}
              activeTabIndex={activeTabIndex}
              tripCountsByStatus={data.viewer.tripCountForUserByStatus}
            />
            <TripsListContentContainer>
              <LongDistanceDispatchTripsListPageTabContent tabIndex={activeTabIndex} />
            </TripsListContentContainer>
          </Container>
        )}
      </Loading>
    </EmployeePage>
  );
};

LongDistanceDispatchTripsListPage.query = gql`
  query LongDistanceDispatchTripsListPage {
    ${gql.query}
    viewer {
      id
      tripCountForUserByStatus{
        plannedCount
        inTransitCount
        completedCount
      }
      viewingOrganization {
        id
        name
      }
    }
  }
`;

export default LongDistanceDispatchTripsListPage;
