// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {Currency, Datetime, Phone} from '@supermove/utils';

const Container = Styled.View`
  height: 375px;
  width: 500px;
  padding: 5px;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Section = Styled.View`
  width: 100%;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 25px;
`;

const RowSpace = Styled.View`
  height: 4px;
`;

const Label = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const TextSpace = Styled.View`
  width: 5px;
`;

const SmallText = Styled(Styled.H7`
  padding-horizontal: 4px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`);

const Text = SmallText`
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.primary};
`;

const AreaWrapper = Styled.View`
  align-self: stretch;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.primary};
  overflow: hidden;
`;

const AreaText = SmallText`
  width: 100%;
`;

const Line = Styled.View`
  height: 1px;
  margin-vertical: 5px;
  background-color: ${colors.blue.accent};
`;

const Checkbox = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CheckboxField = ({checked, label, style}) => (
  <Checkbox style={style}>
    <Label>{label}</Label>
    <TextSpace />
    <Text style={{width: 22}}>{checked ? 'Y' : ' '}</Text>
  </Checkbox>
);

const JobCardDocumentV2 = ({job}) => {
  const hasStartTimes = !!job.startTime1 && !!job.startTime2;
  const clientName = job.name ? ` (${job.name})` : '';
  const customerName = `${job.customer.fullName}${clientName}`;
  const hourlyRateDisplay =
    job.hourlyRateValue && job.hourlyRateValue.amount > 0
      ? Currency.display(job.hourlyRateValue.amount)
      : `$${job.hourlyRate}`;

  return (
    <Container>
      <Section>
        <Row>
          <Label numberOfLines={1}>Date</Label>
          <TextSpace />
          <Text numberOfLines={1} style={{width: 80}}>
            {job.day ? Datetime.convertToDisplayDate(job.day.value, 'M/D/YY') : 'None'}
          </Text>
          <TextSpace />
          <Label numberOfLines={1}>Time</Label>
          <TextSpace />
          <Text numberOfLines={1} style={{width: 160}}>
            {hasStartTimes
              ? `${Datetime.convertToDisplayTime(job.startTime1)} - ` +
                `${Datetime.convertToDisplayTime(job.startTime2)}`
              : 'None'}
          </Text>
          <TextSpace />
          <Label numberOfLines={1}>Book date</Label>
          <TextSpace />
          <Text numberOfLines={1} style={{width: 80}}>
            {Datetime.convertToDisplayDatetime(job.createdAt, 'M/D/YY')}
          </Text>
        </Row>
        <Row>
          <Label numberOfLines={1} style={{width: 40}}>
            Name
          </Label>
          <TextSpace />
          <Text numberOfLines={1} style={{width: 280}}>
            {customerName}
          </Text>
          <TextSpace />
          <Label numberOfLines={1} style={{width: 20}}>
            PH
          </Label>
          <TextSpace />
          <Text numberOfLines={1} style={{width: 120}}>
            {Phone.display(job.customer.phoneNumber)}
          </Text>
        </Row>
        <Row>
          <Label numberOfLines={1} style={{width: 40}}>
            Email
          </Label>
          <TextSpace />
          <Text numberOfLines={1} style={{width: 280}}>
            {job.customer.email}
          </Text>
          <TextSpace />
          <Label numberOfLines={1} style={{width: 20}}>
            E
          </Label>
          <TextSpace />
          <Text numberOfLines={1} style={{width: 120}}>
            {job.bookedBy?.fullName ?? 'None'}
          </Text>
        </Row>
      </Section>
      <Line />
      <Section>
        <Row>
          <Label numberOfLines={1}>Size</Label>
          <TextSpace />
          <Text numberOfLines={1}>{job.project.size}</Text>
          <TextSpace />
          <Text numberOfLines={1}>{`${job.crewSize} Men ${hourlyRateDisplay} / hr`}</Text>
          <TextSpace />
          <Label numberOfLines={1}>TF</Label>
          <TextSpace />
          <Text numberOfLines={1}>{`$${job.travelFee}`}</Text>
          <TextSpace />
          <CheckboxField checked={false} label={'CONF?'} />
          <TextSpace />
          <CheckboxField checked={false} label={'INVR?'} />
        </Row>
      </Section>
      <Line />
      <Section>
        {job.locations.map((location, index) => (
          <React.Fragment key={location.id}>
            <Row>
              <Label numberOfLines={1} style={{width: 20}}>
                {`L${index + 1}`}
              </Label>
              <TextSpace />
              <Text numberOfLines={1}>{location.displayAddress}</Text>
              <TextSpace />
              <Label numberOfLines={1}>Unit #</Label>
              <TextSpace />
              <Text numberOfLines={1}>{location.unit || 'n/a'}</Text>
            </Row>
            <Row>
              <Label numberOfLines={1} style={{width: 20}} />
              <TextSpace />
              <CheckboxField checked={location.hasElevator} label={'EL?'} />
              <TextSpace />
              <CheckboxField checked={location.hasLongWalk} label={'LW?'} />
              <TextSpace />
              <Label numberOfLines={1}>Floor #</Label>
              <TextSpace />
              <Text numberOfLines={1}>{location.floorNumber || 'n/a'}</Text>
              <TextSpace />
              <Label numberOfLines={1}># Stairs</Label>
              <TextSpace />
              <Text numberOfLines={1}>
                {location.stairCount || location.stairDescription || 'n/a'}
              </Text>
            </Row>
          </React.Fragment>
        ))}
      </Section>
      <Line />
      <Section style={{flex: 1}}>
        <Row>
          <Label numberOfLines={1}>Notes for crew / Notes for customer</Label>
        </Row>
        <Row style={{flex: 1}}>
          <AreaWrapper>
            <AreaText>{job.additionalNotes || ' '}</AreaText>
          </AreaWrapper>
        </Row>
        <RowSpace />
        <Row style={{flex: 1}}>
          <AreaWrapper>
            <AreaText>{job.project.description}</AreaText>
          </AreaWrapper>
        </Row>
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCardDocumentV2.fragment = gql`
  fragment JobCardDocumentV2 on Job {
    id
    name
    crewSize
    startTime1
    startTime2
    hourlyRate
    hourlyRateValue {
      id
      amount
    }
    travelFee
    additionalNotes
    createdAt
    project {
      id
      description
      size
    }
    day {
      id
      value
    }
    customer {
      id
      fullName
      phoneNumber
      email
    }
    locations {
      id
      city
      displayAddress
      unit
      floorNumber
      stairCount
      stairDescription
      hasElevator
      hasLongWalk
    }
    bookedBy {
      id
      fullName
    }
  }
`;

export default JobCardDocumentV2;
