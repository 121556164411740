import _ from 'lodash';

const VALUATION_COVERAGE = 'VALUATION_COVERAGE';
const VARIABLE = 'VARIABLE';

const getDisplayKind = (kind: any) => _.startCase(_.toLower(kind));

export default {
  VALUATION_COVERAGE,
  VARIABLE,

  VALUES: [VALUATION_COVERAGE, VARIABLE],

  getDisplayKind,
};
