// Libraries
import React from 'react';

// Supermove
import {Space, Styled, ScrollView} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';

const Text = Styled.Text`
  ${Typography.Body3}
  color: ${colors.blue.interactive};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const StyledScrollView = Styled(ScrollView)`
  max-height: 400px;
  padding: 8px;
  background-color: ${colors.gray.background}
  border-radius: 4px;
`;

const Button = Styled.ButtonV2`
`;

// getListItemText and getListItemUrl are functions that take in a listItem and return a string
const ListItems = ({listItems, getListItemText, getListItemUrl}) => {
  const {navigator} = useNavigationDOM();
  return (
    <StyledScrollView>
      {listItems.map((listItem) => (
        <Row key={listItem.id}>
          <Button onPress={() => navigator.pushNewTab(getListItemUrl(listItem))}>
            <Text>{getListItemText(listItem)}</Text>
          </Button>
        </Row>
      ))}
    </StyledScrollView>
  );
};

const CannotDeleteModal = ({
  titleText,
  description,
  listItems,
  cannotDeleteModal,
  getListItemText,
  getListItemUrl,
  buttonText,
}) => {
  return (
    <SmallModal
      handlePressOutside={cannotDeleteModal.handleClose}
      isOpen={cannotDeleteModal.isOpen}
    >
      <SmallModal.BoldText>{titleText}</SmallModal.BoldText>
      <Space height={8} />
      <SmallModal.Text>{description}</SmallModal.Text>
      <Space height={8} />
      <ListItems
        listItems={listItems}
        getListItemText={getListItemText}
        getListItemUrl={getListItemUrl}
      />
      <Space height={36} />
      <SmallModal.Footer>
        <SmallModal.Button color={colors.blue.interactive} onPress={cannotDeleteModal.handleClose}>
          {buttonText || 'Confirm'}
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default CannotDeleteModal;
