// Libraries
import React from 'react';

// App
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import {Panel, SplitPage} from 'modules/App/components';
import {UserDayJobUsersList} from 'modules/Employee/UserDay/Show/components';
import {UserDayPage} from 'modules/Employee/UserDay/components';

const Content = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: ${(props) => (props.mobile ? 10 : 30)}px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H6`
  color: ${colors.gray.primary};
`;

const Jobs = Styled.View`
  margin-top: 20px;
`;

const EmployeeShowUserDayPage = ({
  match: {
    params: {date},
  },
}) => (
  <UserDayPage
    variables={{date}}
    query={EmployeeShowUserDayPage.query}
    title={({responsive, loading, data}) => {
      if (loading) {
        return 'Loading...';
      } else if (responsive.mobile) {
        return Datetime.convertToDisplayDate(date, 'dddd, M/D');
      } else {
        return Datetime.convertToDisplayDate(date, 'dddd, M/D/YY');
      }
    }}
    subtitle={() => 'View jobs'}
    onClose={({navigator}) => navigator.goBack()}
  >
    {({responsive, data}) => (
      <SplitPage split={<Panel />}>
        <ScrollView style={{flex: 1}}>
          <Content {...responsive}>
            <Title>Jobs</Title>
            <Subtitle>Press a job for more information.</Subtitle>
            <Jobs>
              <UserDayJobUsersList jobUsers={data.userDay.jobUsersV2} />
            </Jobs>
          </Content>
        </ScrollView>
      </SplitPage>
    )}
  </UserDayPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
EmployeeShowUserDayPage.query = gql`
  ${UserDayPage.fragment}
  ${UserDayJobUsersList.fragment}

  query EmployeeShowUserDayPage($date: String!) {
    ${gql.query}
    userDay(date: $date) {
      id
      day {
        id
        value
      }
      jobUsersV2 {
        id
        ...UserDayJobUsersList
      }
      ...UserDayPage
    }
  }
`;

export default EmployeeShowUserDayPage;
