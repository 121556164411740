// Libraries
import React from 'react';

// Supermove
import {CurrencyInput, DropdownInput, Query, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import SmallModalV1 from '@shared/design/components/SmallModalV1';
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import Field from 'modules/App/components/Field';

import PayBillWithAccountStripeForm from './PayBillWithAccountStripeForm';
import SavePayEngineCreditCardForCustomerModal from './SavePayEngineCreditCardForCustomerModal';

const Indicator = Styled.Loading`
`;

const Wrapper = Styled.View`
  ${(props) => (props.mobile ? 'width: 100%;' : 'width: 400px;')}
  ${(props) => (props.mobile ? 'flex: 1;' : '')}
  background-color: ${colors.white};
`;

const Container = Styled.View`
  ${(props) => (props.mobile ? 'flex: 1;' : '')}
`;

const Content = Styled.View`
  padding: 20px;
  z-index: 100;
`;

const Row = Styled.View`
  flex-direction: row;
  z-index: ${(props) => 100 - props.index};
`;

const RowSpace = Styled.View`
  height: 10px;
`;

const FieldSpace = Styled.View`
  width: 10px;
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const FooterContainer = Styled.View`
  flex: 1;
  flex-basis: 0%;
  flex-direction: row;
  align-items: flex-end;
`;

const Footer = Styled.View`
  flex: 1;
  align-items: flex-end;
  padding-vertical: 10px;
  padding-horizontal: 10px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.gray.tertiary};
`;

const Button = Styled.LoadingButton`
  padding-horizontal: 15px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const getStartingBalance = ({bill}) => {
  if (bill.project.activeJobsAggregateBill.isTotalAvailable) {
    return bill.project.activeJobsAggregateBill.balance;
  }
  return null;
};

const CreatePaymentForBillForm = ({jobId, bill, onClose, refetch}) => {
  const stripeAccountId = Organization.getStripeAccountId(bill.organization);
  const balance = getStartingBalance({bill});

  return (
    <PayBillWithAccountStripeForm
      jobId={jobId}
      billId={bill.id}
      customerId={bill.customer.id}
      stripeAccountId={stripeAccountId}
      partialAmount={Currency.toForm(balance)}
      initialPaymentMethod={''}
      onSuccess={() => onClose()}
      onError={(errors) => console.log({errors})}
    >
      {(form) => <CreatePaymentForBillFields bill={bill} form={form} refetch={refetch} />}
    </PayBillWithAccountStripeForm>
  );
};

const CreatePaymentForBillFields = ({bill, form, refetch}) => {
  const savedPaymentMethods = bill.project.billingClient.savedPaymentMethods.flatMap((method) => {
    switch (method.paymentMethod) {
      case PaymentMethodKind.STRIPE_CREDIT_CARD:
        return method;
      case PaymentMethodKind.PAYENGINE_CREDIT_CARD:
        return method;
      default:
        console.error('Found unsupported saved payment method: ', method);
        return [];
    }
  });

  const onCloseSaveCreditCardModal = () => {
    form.setValues({
      ...form.values,
      paymentOptionIndex: undefined,
      paymentMethod: '',
      stripePaymentMethodId: undefined,
      paymentMethodToken: undefined,
    });
    refetch();
  };

  const responsive = useResponsive();

  return (
    <Wrapper {...responsive}>
      <Container {...responsive}>
        <Content>
          <Row index={0}>
            <Field
              {...form}
              name={'name'}
              label={'Name'}
              input={{
                required: !form.values.name,
                autoFocus: true,
                placeholder: 'Enter a payment name',
                style: {
                  width: responsive.mobile ? '100%' : 360,
                },
              }}
              style={{
                width: responsive.mobile ? '100%' : 360,
              }}
            />
          </Row>
          <RowSpace />
          <Row
            index={1}
            style={{
              alignItems: 'flex-start',
            }}
          >
            <Field
              {...form}
              index={1}
              component={DropdownInput}
              name={'paymentOptionIndex'}
              label={'Credit Card'}
              errors={{paymentOptionIndex: form.errors?.paymentMethod}}
              input={{
                required: form.values.paymentOptionIndex === undefined,
                options: savedPaymentMethods.map((method, index) => {
                  return {
                    label: method.displayName,
                    value: index,
                  };
                }),
                placeholder: 'Select Credit Card',
                setFieldValue: (name, index) => {
                  form.setFieldValue(name, index);

                  const savedPaymentMethod = savedPaymentMethods[index];
                  form.setFieldValue('paymentMethod', savedPaymentMethod.paymentMethod);
                  switch (savedPaymentMethod.paymentMethod) {
                    case PaymentMethodKind.STRIPE_CREDIT_CARD:
                      form.setFieldValue(
                        'stripePaymentMethodId',
                        savedPaymentMethod.stripePaymentMethodId,
                      );
                      break;
                    case PaymentMethodKind.PAYENGINE_CREDIT_CARD:
                      form.setFieldValue('paymentMethodToken', savedPaymentMethod.payengineToken);
                      break;
                    default:
                      break; // Unreachable
                  }
                },
                style: {
                  width: responsive.mobile ? '100%' : 360,
                },
              }}
              style={{
                width: responsive.mobile ? '100%' : 360,
              }}
            />
            <FieldSpace />
          </Row>
          <Row index={2}>
            <SavePayEngineCreditCardForCustomerModal
              clientId={bill.project.billingClient.id}
              mode={bill.organization.mode}
              onClose={onCloseSaveCreditCardModal}
              trigger={({handleOpen}) => (
                <Button color={colors.blue.accent} onPress={handleOpen} style={{marginTop: 10}}>
                  <Text style={{color: colors.blue.interactive}}>+ Add Card</Text>
                </Button>
              )}
            />
          </Row>
          <RowSpace />
          <Row index={3}>
            <Field
              {...form}
              name={'partialAmount'}
              label={'Amount'}
              component={CurrencyInput}
              input={{
                component: TextInput,
                required: !form.values.partialAmount,
                placeholder: 'Enter amount',
                setFieldValue: form.setFieldValue,
                setFieldTouched: form.setFieldTouched,
                style: {
                  width: responsive.mobile ? '100%' : 360,
                },
              }}
              style={{
                width: responsive.mobile ? '100%' : 360,
              }}
            />
          </Row>
        </Content>
        <FooterContainer>
          <Footer>
            <Button loading={form.values.loading} onPress={form.handleSubmit}>
              <Text>Charge</Text>
            </Button>
          </Footer>
        </FooterContainer>
      </Container>
    </Wrapper>
  );
};

const ChargeCreditCardForBillModal = ({isOpen, jobId, billUuid, handleClose}) => {
  return (
    <SmallModalV1
      isOpen={isOpen}
      onClose={handleClose}
      title={'Charge Credit Card'}
      subtitle={
        'Select a credit card and enter an amount to charge. The credit card will be charged when you press "Charge".'
      }
    >
      <Query
        fetchPolicy={'network-only'}
        variables={{uuid: billUuid}}
        query={ChargeCreditCardForBillModal.query}
      >
        {({loading, data, refetch}) =>
          loading ? (
            <Indicator
              size={'large'}
              color={colors.gray.secondary}
              style={{
                height: 357,
              }}
            />
          ) : (
            <CreatePaymentForBillForm
              jobId={jobId}
              bill={data.bill}
              onClose={handleClose}
              refetch={refetch}
            />
          )
        }
      </Query>
    </SmallModalV1>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ChargeCreditCardForBillModal.query = gql`
  ${Organization.getStripeAccountId.fragment}

  query ChargeCreditCardForBillModalQuery ($uuid: String!) {
    ${gql.query}
    bill(uuid: $uuid) {
      id
      isEstimateAvailable
      balance
      estimateTotalMin
      estimateTotalMax
      organization {
        id
        mode
        ...Organization_getStripeAccountId
      }
      customer {
        id
        account {
          id
          stripePaymentMethodId
        }
      }
      project {
        id
        activeJobsAggregateBill {
          isTotalAvailable
          balance
        }
        billingClient {
          id
          savedPaymentMethods {
            paymentMethod
            displayName
            stripePaymentMethodId
            payengineToken
          }
        }
      }
    }
  }
`;

export default ChargeCreditCardForBillModal;
