// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm, useModal, useNavigationDOM, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ProjectTypeVariableSectionForm from '@shared/modules/Variable/forms/ProjectTypeVariableSectionForm';
import ProjectTypeVariableSectionsForm from '@shared/modules/Variable/forms/ProjectTypeVariableSectionsForm';
import useUpsertProjectTypeVariableSectionsMutation from '@shared/modules/Variable/hooks/useUpsertProjectTypeVariableSectionsMutation';
import DeleteDefaultVariableSectionWarningModal from 'modules/Organization/Settings/ProjectTypes/components/DeleteDefaultVariableSectionWarningModal';
import DeleteVariableSectionModal from 'modules/Organization/Settings/ProjectTypes/components/DeleteVariableSectionModal';
import EditVariableSectionModal from 'modules/Organization/Settings/ProjectTypes/components/EditVariableSectionModal';
import MoveToVariableSectionModal from 'modules/Organization/Settings/ProjectTypes/components/MoveToVariableSectionModal';
import NewVariableSectionModal from 'modules/Organization/Settings/ProjectTypes/components/NewVariableSectionModal';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';
import VariableProjectAndJobTypeList from 'modules/Organization/Settings/ProjectTypes/components/VariableProjectAndJobTypeList';
import VariableSectionsTable from 'modules/Organization/Settings/ProjectTypes/components/VariableSectionsTable';

const PageContainer = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const LeftHeaderColumn = Styled.View`
`;

const RightHeaderColumn = Styled.View`
  align-items: flex-end;
`;

const ButtonContainer = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const LeftColumn = Styled.View`
  align-items: center;
`;

const RightColumn = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const DescriptionText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const HeaderText = Styled.Text`
  ${Typography.Heading4}
`;

const LastSavedText = Styled.Text`
  ${Typography.Micro}
`;

const ManageVariablesButton = Styled.ButtonV2`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`;

const ManageVariablesButtonText = Styled.Text`
  ${Typography.Link}
`;

const Header = ({projectType, isEdit, setIsEdit, handleSubmit, isSubmitting, handleResetForm}) => {
  const lastUpdatedAt = Datetime.fromDatetime(projectType.variableSectionsUpdatedAt).format(
    Datetime.DISPLAY_DATETIME,
  );
  return (
    <HeaderContainer>
      <LeftHeaderColumn>
        <HeaderText>Configure Variables</HeaderText>
        <Space height={12} />
        <DescriptionText>
          {`Configure variables for ${projectType.name}. Supermove will automatically apply these variables to your projects when they are created.`}
        </DescriptionText>
      </LeftHeaderColumn>
      <RightHeaderColumn>
        {isEdit ? (
          <ButtonContainer>
            <SecondaryButton
              onPress={() => {
                handleResetForm();
                setIsEdit(false);
              }}
              text={'Cancel'}
            />
            <Space width={16} />
            <Button onPress={handleSubmit} isSubmitting={isSubmitting} text={'Save Changes'} />
          </ButtonContainer>
        ) : (
          <Button onPress={() => setIsEdit(true)} text={'Edit Variables'} iconLeft={Icon.Pen} />
        )}
        <Space height={8} />
        <LastSavedText>{`Last Updated: ${lastUpdatedAt}`}</LastSavedText>
      </RightHeaderColumn>
    </HeaderContainer>
  );
};

const NewProjectTypeVariableSectionModal = ({
  newVariableSectionModal,
  parentForm,
  projectTypeId,
}) => {
  const projectTypeVariableSectionForm = ProjectTypeVariableSectionForm.new(projectTypeId);
  const form = useForm({
    initialValues: {
      projectTypeVariableSectionForm: ProjectTypeVariableSectionForm.toForm(
        projectTypeVariableSectionForm,
      ),
    },
  });
  return (
    <NewVariableSectionModal
      key={newVariableSectionModal.key}
      isOpen={newVariableSectionModal.isOpen}
      handleClose={newVariableSectionModal.handleClose}
      parentForm={parentForm}
      form={form}
      field={'projectTypeVariableSectionForm'}
      sectionFormsField={'projectTypeVariableSectionsForm.projectTypeVariableSectionForms'}
    />
  );
};

const EditProjectTypeVariableSectionModal = ({
  editVariableSectionModal,
  parentForm,
  variableSectionFormField,
}) => {
  // create a copy of the section form object
  // this allows us to make edits without altering the parent form directly
  const projectTypeVariableSectionForm = {..._.get(parentForm.values, variableSectionFormField)};
  const form = useForm({
    initialValues: {
      projectTypeVariableSectionForm,
    },
  });
  return (
    <EditVariableSectionModal
      key={editVariableSectionModal.key}
      isOpen={editVariableSectionModal.isOpen}
      handleClose={editVariableSectionModal.handleClose}
      parentForm={parentForm}
      form={form}
      field={'projectTypeVariableSectionForm'}
      sectionFormsField={'projectTypeVariableSectionsForm.projectTypeVariableSectionForms'}
      variableSectionFormField={variableSectionFormField}
    />
  );
};

const DeleteProjectTypeVariableSectionModal = ({
  deleteVariableSectionModal,
  form,
  variableSectionFormField,
}) => {
  const handleDelete = ({variableDestinationField}) => {
    ProjectTypeVariableSectionsForm.removeVariableSection({
      form,
      sectionFieldToRemove: variableSectionFormField,
      destinationField: variableDestinationField,
    });
    deleteVariableSectionModal.handleClose();
  };
  return (
    <DeleteVariableSectionModal
      key={deleteVariableSectionModal.key}
      isOpen={deleteVariableSectionModal.isOpen}
      handleClose={deleteVariableSectionModal.handleClose}
      parentForm={form}
      sectionFormsField={'projectTypeVariableSectionsForm.projectTypeVariableSectionForms'}
      variableSectionFormField={variableSectionFormField}
      handleDelete={handleDelete}
    />
  );
};

const MoveToProjectTypeVariableSectionModal = ({moveToVariableSectionModal, form}) => {
  const handleMove = ({variableDestinationField}) => {
    const variableIdsToMove = _.get(
      form.values,
      'projectTypeVariableSectionsForm.selectedVariableIds',
    );
    ProjectTypeVariableSectionsForm.moveVariablesToSection({
      form,
      variableIdsToMove,
      destinationField: variableDestinationField,
    });
    moveToVariableSectionModal.handleClose();
    form.setFieldValue('projectTypeVariableSectionsForm.selectedVariableIds', []);
  };
  return (
    <MoveToVariableSectionModal
      key={moveToVariableSectionModal.key}
      isOpen={moveToVariableSectionModal.isOpen}
      handleClose={moveToVariableSectionModal.handleClose}
      form={form}
      sectionFormsField={'projectTypeVariableSectionsForm.projectTypeVariableSectionForms'}
      handleSubmit={handleMove}
    />
  );
};

const ProjectTypeVariableSettings = ({projectType, refetch}) => {
  const {navigator} = useNavigationDOM();
  const newVariableSectionModal = useModal({name: 'New Variable Section Modal'});
  const editVariableSectionModal = useModal({name: 'Edit Variable Section Modal'});
  const deleteVariableSectionModal = useModal({name: 'Delete Variable Section Modal'});
  const deleteDefaultVariableSectionWarningModal = useModal({
    name: 'Delete Default Variable Section Warning Modal',
  });
  const moveToVariableSectionModal = useModal({name: 'Move To Variable Section Modal'});
  const [variableSectionFormField, setVariableSectionFormField] = useState('');

  const projectTypeVariableSectionsForm = ProjectTypeVariableSectionsForm.edit(projectType);
  const {form, handleSubmit, submitting} = useUpsertProjectTypeVariableSectionsMutation({
    projectTypeVariableSectionsForm,
    onSuccess: () => {
      setIsEdit(false);
      refetch();
    },
    onError: () => {},
  });

  const variableSectionsFormField = 'projectTypeVariableSectionsForm';
  const variableSectionsForm = _.get(form.values, variableSectionsFormField);
  const isEdit = _.get(variableSectionsForm, 'isEdit');
  const setIsEdit = (isEdit) => form.setFieldValue(`${variableSectionsFormField}.isEdit`, isEdit);

  return (
    <PageContainer>
      <ProjectTypeSettingsPageHeader projectType={projectType} />
      <ContentContainer>
        <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
          <ScrollView contentContainerStyle={{padding: 24, flex: 1}}>
            <Header
              projectType={projectType}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              handleSubmit={handleSubmit}
              isSubmitting={submitting}
              handleResetForm={() => form.resetForm()}
            />
            <Space height={24} />
            <Container>
              <LeftColumn>
                <VariableProjectAndJobTypeList projectType={projectType} />
                <Space height={24} />
                <ManageVariablesButton
                  onPress={() => navigator.push('/settings/billing/variables')}
                >
                  <Icon size={12} source={Icon.ExternalLinkAlt} color={colors.blue.interactive} />
                  <Space width={8} />
                  <ManageVariablesButtonText>Manage Variables</ManageVariablesButtonText>
                </ManageVariablesButton>
                <Space height={24} />
              </LeftColumn>
              <Space width={24} />
              <RightColumn>
                <VariableSectionsTable
                  title={projectType.name}
                  refetch={refetch}
                  form={form}
                  variableSectionsForm={variableSectionsForm}
                  variableSectionsFormField={variableSectionsFormField}
                  variableSectionFormsField={'projectTypeVariableSectionForms'}
                  variableFormsField={'projectTypeVariableForms'}
                  handleNewSection={newVariableSectionModal.handleOpen}
                  handleEditSection={(variableSectionFormField) => {
                    setVariableSectionFormField(variableSectionFormField);
                    editVariableSectionModal.handleOpen();
                  }}
                  handleDeleteSection={(variableSectionFormField) => {
                    setVariableSectionFormField(variableSectionFormField);
                    const variableSectionToDelete = _.get(form.values, variableSectionFormField);
                    const isDefaultSection = _.get(variableSectionToDelete, 'isDefault');
                    if (isDefaultSection) {
                      deleteDefaultVariableSectionWarningModal.handleOpen();
                    } else {
                      deleteVariableSectionModal.handleOpen();
                    }
                  }}
                  handleMoveTo={moveToVariableSectionModal.handleOpen}
                  isEnabledTbdBillItems={projectType.organization.features.isEnabledTbdBillItems}
                />
                {newVariableSectionModal.isOpen && (
                  <NewProjectTypeVariableSectionModal
                    newVariableSectionModal={newVariableSectionModal}
                    parentForm={form}
                    projectTypeId={projectType.id}
                  />
                )}
                {editVariableSectionModal.isOpen && (
                  <EditProjectTypeVariableSectionModal
                    editVariableSectionModal={editVariableSectionModal}
                    parentForm={form}
                    variableSectionFormField={variableSectionFormField}
                  />
                )}
                <DeleteDefaultVariableSectionWarningModal
                  key={deleteDefaultVariableSectionWarningModal.key}
                  isOpen={deleteDefaultVariableSectionWarningModal.isOpen}
                  handleClose={deleteDefaultVariableSectionWarningModal.handleClose}
                />
                <DeleteProjectTypeVariableSectionModal
                  deleteVariableSectionModal={deleteVariableSectionModal}
                  form={form}
                  variableSectionFormField={variableSectionFormField}
                />
                <MoveToProjectTypeVariableSectionModal
                  moveToVariableSectionModal={moveToVariableSectionModal}
                  form={form}
                />
              </RightColumn>
            </Container>
          </ScrollView>
        </ScrollView>
        <Space height={48} />
      </ContentContainer>
    </PageContainer>
  );
};

ProjectTypeVariableSettings.fragment = gql`
  ${ProjectTypeSettingsPageHeader.fragment}
  ${ProjectTypeVariableSectionsForm.edit.fragment}
  ${VariableProjectAndJobTypeList.fragment}

  fragment ProjectTypeVariableSettings on ProjectType {
    id
    name
    variableSectionsUpdatedAt
    organization {
      id
      features {
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
      }
    }
    ...ProjectTypeSettingsPageHeader
    ...ProjectTypeVariableSectionsForm_edit
    ...VariableProjectAndJobTypeList
  }
`;

export default ProjectTypeVariableSettings;
