// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UpdateAttachmentForm from '@shared/modules/File/forms/UpdateAttachmentForm';

const useUpdateAttachmentForm = ({updateAttachmentForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      updateAttachmentForm: UpdateAttachmentForm.toForm(updateAttachmentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateAttachmentForm.mutation,
    variables: {
      updateAttachmentForm: UpdateAttachmentForm.toMutation(form.values.updateAttachmentForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateAttachmentForm.mutation = gql`
  mutation useUpdateAttachmentForm($updateAttachmentForm: UpdateAttachmentForm!) {
    response: updateAttachment(updateAttachmentForm: $updateAttachmentForm) {
      ${gql.errors}
      attachment {
        id
      }
    }
  }
`;

export default useUpdateAttachmentForm;
