// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useSheet, useResponsive, useToast} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {colors} from '@supermove/styles';
import {List, pluralize} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import DropdownSheet from '@shared/design/components/DropdownInput/components/DropdownSheet';
import TextTooltip from '@shared/design/components/TextTooltip';
import Toast from '@shared/design/components/Toast';
import JobForm from '@shared/modules/Job/forms/JobForm';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const EditProjectJobsBarContainer = Styled.View`
  height: 60px;
  justify-content: center;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ToastButton = Styled.ButtonV2`
  flex: 1;
`;

const ButtonWrapperContainer = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const TooltipContentContainer = Styled.View`
`;

const handleAddJob = ({urlFilters}) => {
  urlFilters.handleUpdate({action: Project.JOB_ACTIONS.ADD});
};

const handleDuplicateJob = ({urlFilters, jobUuid}) => {
  urlFilters.handleUpdate({
    action: Project.JOB_ACTIONS.DUPLICATE,
    actionUuid: jobUuid,
  });
};

const handleDeleteJob = ({form, field, jobForms, index, urlFilters}) => {
  const isDeletingCurrentJob = jobForms[index].uuid === urlFilters.get('jobUuid');
  if (isDeletingCurrentJob) {
    urlFilters.handleUpdate({
      jobUuid: _.get(jobForms, `${index - 1}.uuid`),
      block: ProjectBlockKind.EditJobBlocks[0],
    });
  }

  const updatedJobForms = List.remove(jobForms, index);
  form.setFieldValue(`${field}.jobForms`, updatedJobForms);
  form.setErrors({});
};

const countErrors = (object) => {
  return Object.keys(object).reduce((total, key) => {
    if (Array.isArray(object[key])) {
      return (
        total +
        object[key].reduce((arrayTotal, arrayObject) => {
          const currentErrorCount = arrayObject ? countErrors(arrayObject) : 0;
          return arrayTotal + currentErrorCount;
        }, 0)
      );
    }
    return total + 1;
  }, 0);
};

const getErrorCount = ({form, field, index}) => {
  const jobFormErrors = _.get(form.errors, `${field}.jobForms.${index}`);

  if (jobFormErrors) {
    const totalErrors = countErrors(jobFormErrors);
    if (totalErrors) {
      return totalErrors;
    }
  }

  return null;
};

const getContextDefinitions = ({form, field, urlFilters, jobForms, project}) => {
  const projectForm = _.get(form.values, field);
  const isAtMaxJobs = ProjectForm.getIsAtMaxJobs(projectForm, {project});

  return jobForms.map((jobForm, index) => {
    const errorCount = getErrorCount({form, field, index});
    const isNewJob = !jobForm.jobId;
    return {
      leftBadgeLabel: jobForm.label || (isNewJob && 'New'),
      leftBadgeColor: jobForm.label ? colors.gray.secondary : colors.green.status,
      rightBadgeLabel: errorCount && `${pluralize('error', errorCount, true)}`,
      rightBadgeColor: colors.red.warning,
      label: JobForm.getJobName(jobForm, {project}),
      isSelected: jobForm.uuid === urlFilters.get('jobUuid'),
      onPress: () =>
        urlFilters.handleUpdate({jobUuid: jobForm.uuid, block: ProjectBlockKind.EditJobBlocks[0]}),
      actions: [
        {
          text: 'Duplicate job',
          isDisabled: isAtMaxJobs,
          tooltip: isAtMaxJobs ? 'You have reached the maximum number of jobs' : '',
          onPress: () => handleDuplicateJob({urlFilters, jobUuid: jobForm.uuid}),
        },
        ...List.insertIf(isNewJob, {
          text: 'Delete job',
          color: colors.red.warning,
          onPress: () => handleDeleteJob({form, field, jobForms, index, urlFilters}),
        }),
      ],
    };
  });
};

const getMobileDropdownActions = ({urlFilters}) => {
  return [
    {
      label: 'Duplicate current job',
      handleAction: () => handleDuplicateJob({urlFilters, jobUuid: urlFilters.get('jobUuid')}),
    },
    {
      label: 'Add new job',
      handleAction: () => handleAddJob({urlFilters}),
    },
  ];
};

const LoadingComponent = () => {
  return (
    <EditProjectJobsBarContainer>
      <Row>
        <Space width={24} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Button} width={160} />
        <Space width={12} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Button} width={100} />
      </Row>
    </EditProjectJobsBarContainer>
  );
};

const ButtonWrapper = ({project, toast, children}) => {
  const {handleToast} = useToast({
    ToastComponent: Toast,
    message: toast,
  });

  if (toast) {
    return (
      <ButtonWrapperContainer style={{flex: 1}}>
        <ToastButton onPress={handleToast}>{children}</ToastButton>
      </ButtonWrapperContainer>
    );
  }
  return <ButtonWrapperContainer>{children}</ButtonWrapperContainer>;
};

const MobileEditActions = ({form, field, jobForms, urlFilters, project, scrollView}) => {
  const {params} = useNavigationDOM();
  const mobileAddJobSheet = useSheet({name: 'Mobile Add Job Sheet'});
  const index = jobForms.findIndex((jobForm) => jobForm.uuid === params.jobUuid);
  const isNewJob = !jobForms[index]?.jobId;
  const isAtMaxJobs = ProjectForm.getIsAtMaxJobs(_.get(form.values, field), {project});

  return (
    <Row>
      {isNewJob && (
        <React.Fragment>
          <ButtonWrapper>
            <SecondaryButton
              text={'Delete Job'}
              onPress={() => handleDeleteJob({form, field, jobForms, index, urlFilters})}
              textColor={colors.red.warning}
              isResponsive
              isWidthOfContainer
              style={{flex: 1}}
            />
          </ButtonWrapper>
          <Space width={16} />
        </React.Fragment>
      )}
      <ButtonWrapper
        project={project}
        toast={isAtMaxJobs ? 'You have reached the maximum number of jobs' : ''}
      >
        <SecondaryButton
          text={'Add Job'}
          onPress={mobileAddJobSheet.handleOpen}
          isResponsive
          isWidthOfContainer
          style={{flex: 1}}
          iconRight={Icon.ChevronDown}
          isDisabled={isAtMaxJobs}
        />
      </ButtonWrapper>
      <DropdownSheet
        isOpen={mobileAddJobSheet.isOpen}
        handleClose={mobileAddJobSheet.handleClose}
        headerText={'Add Job'}
        isSearchable={false}
        options={getMobileDropdownActions({urlFilters})}
      />
    </Row>
  );
};

const EditProjectJobsBar = ({form, field, urlFilters, project, scrollView}) => {
  const responsive = useResponsive();
  const {params} = useNavigationDOM();
  const projectForm = _.get(form.values, field);
  const {jobForms} = projectForm;
  const isAtMaxJobs = ProjectForm.getIsAtMaxJobs(projectForm, {project});

  if (!responsive.desktop) {
    return (
      <MobileEditActions
        form={form}
        field={field}
        jobForms={jobForms}
        urlFilters={urlFilters}
        project={project}
        scrollView={scrollView}
      />
    );
  }

  return (
    <EditProjectJobsBarContainer>
      <Row>
        <Space width={24} />
        {!_.isEmpty(jobForms) && (
          <React.Fragment>
            <ContextSwitcher
              contextDefinitions={getContextDefinitions({
                form,
                field,
                urlFilters,
                jobForms,
                project,
              })}
              scrollView={scrollView}
            />
            <Space width={16} />
          </React.Fragment>
        )}
        <Space style={{flex: 1, minWidth: 16}} />
        <TextTooltip text={isAtMaxJobs ? 'You have reached the maximum number of jobs' : ''}>
          <TooltipContentContainer>
            <SecondaryButton
              iconLeft={Icon.Copy}
              text={'Duplicate Current Job'}
              isDisabled={isAtMaxJobs}
              onPress={() => handleDuplicateJob({urlFilters, jobUuid: params.jobUuid})}
            />
          </TooltipContentContainer>
        </TextTooltip>
        <Space width={16} />
        <TextTooltip text={isAtMaxJobs ? 'You have reached the maximum number of jobs' : ''}>
          <TooltipContentContainer>
            <SecondaryButton
              iconLeft={Icon.Plus}
              text={'Add New Job'}
              isDisabled={isAtMaxJobs}
              onPress={() => handleAddJob({urlFilters})}
            />
          </TooltipContentContainer>
        </TextTooltip>
        <Space width={24} />
      </Row>
    </EditProjectJobsBarContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectJobsBar.fragment = gql`
  ${JobForm.getJobName.fragment}
  ${Project.getIsAtMaxJobs.fragment}
  ${ProjectForm.getIsAtMaxJobs.fragment}

  fragment EditProjectJobsBar on Project {
    id
    projectType {
      id
      jobTypes {
        id
        name
      }
    }
    ...JobForm_getJobName
    ...Project_getIsAtMaxJobs
    ...ProjectForm_getIsAtMaxJobs
  }
`;

EditProjectJobsBar.SkeletonLoader = LoadingComponent;
EditProjectJobsBar.getErrorCount = getErrorCount;

export default EditProjectJobsBar;
