/**
 * Component - v2.1.0
 */
// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FileDragInput, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const FileDragInputContainer = Styled.View`
  height: 150px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  border-color: ${colors.gray.secondary};
`;

const FileDragInputText = Styled.Text`
  ${Typography.Body2}
  width: 100%;
  text-align: center;
`;

const FilenameText = Styled.Text`
  ${Typography.Body2}
  width: 100%;
  text-align: center;
`;

const DestructiveButton = Styled.Button`
  margin-top: 30px;
  height: 40px;
  width: 100px;
`;

const ChangeFileButton = ({name, form}: any) => {
  return (
    <DestructiveButton
      color={colors.red.warning}
      onPress={() => FileInputField.resetUploadFileForm({name, form})}
    >
      <ButtonText>Change</ButtonText>
    </DestructiveButton>
  );
};

const ValidationError = Styled.Text`
  ${Typography.Body3}
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const FileInputField = ({name, form, submitting, handleSubmit, validationErrors}: any) => {
  const file = _.get(form, `values.${name}.file`);

  if (form.values.uploadFileForm.file) {
    return (
      <React.Fragment>
        <FileDragInputContainer>
          <FilenameText>{submitting ? 'Loading...' : `Uploaded ${file.name}`}</FilenameText>
        </FileDragInputContainer>
        <ChangeFileButton name={name} form={form} />
        {validationErrors.map((value: any, index: any) => {
          return <ValidationError key={value}>{value}</ValidationError>;
        })}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <FileDragInput
        onFilesChange={(files) => {
          const file = files[0];
          form.setFieldValue(`${name}.requestUploadFileForm.mimetype`, file.type);
          form.setFieldValue(
            `${name}.requestUploadFileForm.filename`,
            UploadFileForm.formatName(file.name),
          );
          form.setFieldValue(`${name}.file`, file);

          // TODO(peter): Temporary hack to fix flickering of loading indicator
          form.setSubmitting(true);
          handleSubmit();
        }}
      >
        {({isDragActive}) => (
          <FileDragInputContainer>
            <FileDragInputText>
              {isDragActive ? 'Drop the file here' : 'Drag a file here or press to upload'}
            </FileDragInputText>
          </FileDragInputContainer>
        )}
      </FileDragInput>
      <ValidationError>
        {_.get(form.errors, `${name}.requestUploadFileForm.mimetype`)}
      </ValidationError>
      <ValidationError>
        {_.get(form.errors, `${name}.requestUploadFileForm.filename`)}
      </ValidationError>
      <ValidationError>{_.get(form.errors, `${name}.file`)}</ValidationError>
    </React.Fragment>
  );
};

FileInputField.resetUploadFileForm = ({name, form}: any) => {
  form.setFieldValue(`${name}.requestUploadFileForm.mimetype`, null);
  form.setFieldValue(`${name}.requestUploadFileForm.filename`, null);
  form.setFieldValue(`${name}.file`, null);
};

export default FileInputField;
