// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {ModalPage, Navigation} from 'modules/App/components';

import JobNotFoundPage from './JobNotFoundPage';

const JobPageWithNavigation = ({
  side,
  title,
  subtitle,
  right,
  variables,
  query,
  onClose,
  children,
}) => (
  <ModalPage
    variables={variables}
    query={query}
    navigation={({loading, data, navigator, refetch}) => (
      <Navigation
        side={side}
        title={title({loading, data})}
        subtitle={subtitle({loading, data})}
        left={() =>
          onClose ? (
            <Navigation.CloseButton onPress={() => onClose({loading, data, navigator})} />
          ) : null
        }
        right={() => right({loading, data, navigator, refetch})}
      />
    )}
  >
    {(props) => (props.data.job ? children(props) : <JobNotFoundPage />)}
  </ModalPage>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobPageWithNavigation.propTypes = {
  onClose: PropTypes.func,
  side: PropTypes.func,
  title: PropTypes.func,
  subtitle: PropTypes.func,
  right: PropTypes.func,
};

JobPageWithNavigation.defaultProps = {
  onClose: undefined,

  // If we just have the close button, we can default to this width.
  side: ({responsive}) => (responsive.mobile ? 40 : 60),
  title: () => '',
  subtitle: () => '',
  right: () => null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobPageWithNavigation.fragment = gql`
  fragment JobPageWithNavigation on Job {
    id
  }
`;

export default JobPageWithNavigation;
