// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Form} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import RadioButton from '@shared/design/components/RadioButton';
import {SettingsClaimAssignmentFormType} from '@shared/modules/Settings/forms/SettingsClaimAssignmentForm';

const RadioContainer = Styled.View`
`;

type FormType = Form<{
  settingsClaimAssignmentForm: SettingsClaimAssignmentFormType;
}>;

const ClaimAssignmentPanelEdit = ({form}: {form: FormType}) => {
  const mustLinkClaimToJobField = 'settingsClaimAssignmentForm.mustLinkClaimToJob';
  const autoAssignJobMoversToClaimField = 'settingsClaimAssignmentForm.autoAssignJobMoversToClaim';
  const mustLinkClaimToJob = _.get(form.values, mustLinkClaimToJobField);
  const autoAssignJobMoversToClaim = _.get(form.values, autoAssignJobMoversToClaimField);

  return (
    <React.Fragment>
      <RadioContainer>
        <FieldInput.LabelText isResponsive>Link to Job</FieldInput.LabelText>
        <FieldInput.Spacer isResponsive />
        <RadioButton
          isOn={mustLinkClaimToJob}
          label={'Require every claim to be linked to a specific job'}
          onPress={() => {
            form.setFieldValue(mustLinkClaimToJobField, true);
          }}
        />
        <Space height={8} />
        <RadioButton
          isOn={!mustLinkClaimToJob}
          label={'Do not require claims to be linked to a specific job'}
          onPress={() => {
            form.setFieldValue(mustLinkClaimToJobField, false);
          }}
        />
      </RadioContainer>
      <Space height={16} />
      <RadioContainer>
        <FieldInput.LabelText isResponsive>Assign Movers</FieldInput.LabelText>
        <FieldInput.Spacer isResponsive />
        <RadioButton
          isOn={autoAssignJobMoversToClaim}
          label={'Automatically assign all movers on the job to the claim'}
          onPress={() => {
            form.setFieldValue(autoAssignJobMoversToClaimField, true);
          }}
        />
        <Space height={8} />
        <RadioButton
          isOn={!autoAssignJobMoversToClaim}
          label={'Do not automatically assign movers on the job to the claim'}
          onPress={() => {
            form.setFieldValue(autoAssignJobMoversToClaimField, false);
          }}
        />
      </RadioContainer>
    </React.Fragment>
  );
};

export default ClaimAssignmentPanelEdit;
