// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Form} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {Currency, Json, Percent} from '@supermove/utils';

// Components
import EmployeeFormFields from './EmployeeFormFields';
import UpdateEmployeeMutation from './UpdateEmployeeMutation';

const getInitialMoverPositionIds = ({user}) => {
  return User.getSortedUserMoverPositions(user).map(
    (userMoverPosition) => userMoverPosition.moverPositionId,
  );
};

const UpdateEmployeeForm = ({user, onSuccess, onError}) => {
  const initialValues = {
    userId: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber || '',
    position: user.position,
    branchCode: user.branchCode || '',
    payrollIdentifier: user.payrollIdentifier,
    tabletPin: user.tabletPin,
    billingHourlyRate: Currency.toForm(user.billingHourlyRate),
    compensationPercentage: Percent.toForm(user.compensationPercentage),
    isDriver: user.isDriver,
    isActive: user.isActive,
    features: Json.parse(user.features),
    moverPositionIds: getInitialMoverPositionIds({user}),
    primaryMoverPositionId: user.userMoverPositions.find(
      (userMoverPosition) => userMoverPosition.isPrimary,
    )?.moverPositionId,
  };

  return (
    <Form initialValues={initialValues} onSubmit={(values) => {}}>
      {({submitForm, setMutationErrors, ...form}) => (
        <UpdateEmployeeMutation
          {...form}
          onSuccess={onSuccess}
          submitForm={submitForm}
          setMutationErrors={setMutationErrors}
        >
          {({loading, handleSubmit}) => (
            <EmployeeFormFields
              isUpdate
              form={form}
              loading={loading}
              organization={user.viewingOrganization}
              handleSubmit={handleSubmit}
            />
          )}
        </UpdateEmployeeMutation>
      )}
    </Form>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateEmployeeForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

UpdateEmployeeForm.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateEmployeeForm.fragment = gql`
  ${EmployeeFormFields.fragment}
  ${User.getSortedUserMoverPositions.fragment}

  fragment UpdateEmployeeForm on User {
    id
    firstName
    lastName
    phoneNumber
    payrollIdentifier
    tabletPin
    position
    branchCode
    billingHourlyRate
    compensationPercentage
    isDriver
    isActive
    features
    viewingOrganization {
      id
      ...EmployeeFormFields
    }
    userMoverPositions {
      id
      moverPositionId
      isPrimary
    }
    ...User_getSortedUserMoverPositions
  }
`;

export default UpdateEmployeeForm;
