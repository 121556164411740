// Libraries
import React from 'react';

// App
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
`;

const Text = Styled.H8`
  margin-top: 5px;
  color: ${colors.white};
  font-size: 10px;
  line-height: 10px;
  word-break: break-all;
`;

const SidebarSuperAdmin = ({responsive, user}) => (
  <Container {...responsive}>
    <Icon color={colors.white} size={Icon.Sizes.ExtraLarge} source={Icon.Ghost} />
    {!responsive.mobile && <Text>{user.viewingOrganization.slug}</Text>}
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
SidebarSuperAdmin.fragment = gql`
  fragment SidebarSuperAdmin on User {
    id
    viewingOrganization {
      id
      slug
    }
  }
`;

export default SidebarSuperAdmin;
