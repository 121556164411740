// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment} from '@supermove/utils';

const edit = withFragment(
  (valuationCoverage) => {
    const {valuationCoverageOptions} = (valuationCoverage as any).valuationCoverageConfig;

    const selectedIndex = _.findIndex(valuationCoverageOptions, (valuationCoverageOption) => {
      return (
        (valuationCoverageOption as any).category === (valuationCoverage as any).category &&
        (valuationCoverageOption as any).deductible === (valuationCoverage as any).deductible
      );
    });
    if (selectedIndex === -1) {
      // Can happen if a previous selected item is no longer available
      // as an option, due to config changes.
      return {
        valuationCoverageId: (valuationCoverage as any).id,
      };
    }

    return {
      valuationCoverageId: (valuationCoverage as any).id,
      amountValue: (valuationCoverage as any).amountValue,
      category: (valuationCoverage as any).category,
      deductible: (valuationCoverage as any).deductible,
      amountMinimum: (valuationCoverage as any).amountMinimum,
      // Private
      isShowingRatesTable: false,
      selectedIndex,
    };
  },
  gql`
    fragment ValuationCoverageForm_edit on ValuationCoverage {
      id
      amountMinimum
      amountValue
      category
      deductible
      valuationCoverageConfig {
        valuationCoverageOptions {
          category
          deductible
        }
      }
    }
  `,
);

const ValuationCoverageForm = {
  toForm: ({
    amountMinimum,
    valuationCoverageId,
    amountValue,
    category,
    deductible,
    selectedIndex,
    isShowingRatesTable,
  }: any) => ({
    amountMinimum: Currency.toForm(amountMinimum),
    valuationCoverageId,
    amountValue: Currency.toForm(amountValue),
    category,
    deductible: deductible !== null ? Currency.toForm(deductible) : null,

    // Private
    selectedIndex,
    isShowingRatesTable,
  }),

  toMutation: ({amountMinimum, valuationCoverageId, amountValue, category, deductible}: any) => ({
    amountMinimum: Currency.toMutation(amountMinimum),
    valuationCoverageId,
    amountValue: Currency.toMutation(amountValue),
    category,
    deductible: deductible !== null ? Currency.toMutation(deductible) : deductible,
  }),

  edit,
};

export default ValuationCoverageForm;
