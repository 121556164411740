// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const LineView = Styled.View`
  height: 1px;
  background-color: ${({color}: {color: string}) => color};
`;

const Line = ({color, style}: any) => {
  return <LineView color={color} style={style} />;
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
Line.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

Line.defaultProps = {
  color: colors.gray.border,
  style: undefined,
};

export default Line;
