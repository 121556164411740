// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {useNavigationDOM} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

// App
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';

const InvoiceSuccessModal = ({form, field, isOpen, projectUuid, isEdit}) => {
  const invoiceIdentifier = _.get(form.values, `${field}.identifier`);
  const isSavedAsDraft = _.get(form.values, `${field}.status`) === InvoiceStatus.DRAFT;
  const isToday = Datetime.isToday(_.get(form.values, 'invoiceForm.date'));
  const {navigator, params} = useNavigationDOM();

  const subtitle = isSavedAsDraft
    ? `You've successfully ${
        isEdit ? 'updated' : 'created'
      } a draft of Invoice ${invoiceIdentifier}.`
    : `We successfully ${isEdit ? 'updated' : 'created'} and ${
        isToday ? 'sent' : 'scheduled'
      } Invoice ${invoiceIdentifier}. You can check the status of the invoice on the project page.`;
  return (
    <SuccessModal
      title={
        isSavedAsDraft
          ? 'Invoice saved as draft!'
          : `Invoice ${isEdit ? 'updated' : 'created'} & ${isToday ? 'sent' : 'scheduled'}!`
      }
      subtitle={subtitle}
      isOpen={isOpen}
      handlePrimaryAction={() =>
        navigator.push(
          `${params.projectKind === 'storage' ? '/storage' : ''}/projects/${projectUuid}`,
        )
      }
      primaryActionText={'Done'}
    />
  );
};

export default InvoiceSuccessModal;
