// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Notification, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Day} from '@supermove/models';

const Container = Styled.View`
  width: 100%;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const DayJobsStatusBanner = ({status}) => (
  <Container>
    {!!Day.getCalendarStatusColor({status}) && (
      <Notification color={Day.getCalendarStatusColor({status})} text={status.text} />
    )}
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DayJobsStatusBanner.propTypes = {
  status: PropTypes.object.isRequired,
};

DayJobsStatusBanner.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
DayJobsStatusBanner.fragment = gql`
  fragment DayJobsStatusBanner on CalendarDay {
    status {
      kind
      text
    }
  }
`;

export default DayJobsStatusBanner;
