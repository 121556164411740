// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ClientForm from '@shared/modules/Client/forms/ClientForm';

const useCreateClientMutation = ({clientForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      clientForm: ClientForm.toForm(clientForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateClientMutation.mutation,
    variables: {
      clientForm: ClientForm.toMutation(form.values.clientForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateClientMutation.mutation = gql`
  mutation useCreateClientMutation($clientForm: ClientForm!) {
    response: createClient(clientForm: $clientForm) {
      ${gql.errors}
      client {
        id
        uuid
      }
    }
  }
`;

export default useCreateClientMutation;
