// Libraries
import React from 'react';

// Supermove
import {Space, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {BillTypeModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import BillTypeForm from '@shared/modules/Billing/forms/BillTypeForm';
import useUpdateBillTypeMutation from '@shared/modules/Billing/hooks/useUpdateBillTypeMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import SimpleHeader from 'modules/App/components/SimpleHeader';
import BillTypeBillItemsPanel from 'modules/Organization/Settings/BillingLibraries/BillTypes/components/BillTypeBillItemsPanel';
import BillTypeBillRulesPanel from 'modules/Organization/Settings/BillingLibraries/BillTypes/components/BillTypeBillRulesPanel';
import BillTypeDetailsPanel from 'modules/Organization/Settings/BillingLibraries/BillTypes/components/BillTypeDetailsPanel';
import BillTypePage from 'modules/Organization/Settings/BillingLibraries/BillTypes/components/BillTypePage';
import BillingLibraryBillTypeEditor from 'modules/Organization/Settings/BillingLibraries/BillTypes/components/BillingLibraryBillTypeEditor';

// /////////////////////////////////////////////////////////////////////
// TODO(jholston): Remove this block with isEnabledConditionalBillItems
// /////////////////////////////////////////////////////////////////////
const Container = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  height: 100%;
  background-color: ${colors.gray.background};
`;

const BillTypeEditorBoxContainer = Styled.View`
  z-index: 100;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;

const BillTypeEditorBox = Styled.View`
  flex: 1;
  max-width: 825px;
  background-color: ${colors.white};
  border-radius: 20px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  padding-horizontal: 32px;
`;

const UpdateBillTypePageContentV1 = ({
  billType,
  userId,
}: {
  billType: BillTypeModel;
  userId: string;
}) => {
  const {navigator} = useNavigationDOM();
  const billTypeForm = BillTypeForm.edit(billType, {userId});
  const {form, handleSubmit} = useUpdateBillTypeMutation({
    billTypeForm,
    onSuccess: () => {
      navigator.push(
        `/settings/billing/billing-libraries/${billType.billingLibrary.uuid}/bill-templates`,
      );
    },
    onError: (errors: unknown) => console.log({errors}),
  });

  return (
    <Container>
      {/* @ts-expect-error */}
      <SimpleHeader
        title={'Edit Bill Template'}
        showLine
        showActionButton
        showBackButton
        actionButtonText={'Save'}
        onPress={handleSubmit}
      />
      <ContentContainer>
        <ScrollView contentContainerStyle={{flexGrow: 1}}>
          <Container>
            <Space height={24} />
            <BillTypeEditorBoxContainer>
              <BillTypeEditorBox>
                <BillingLibraryBillTypeEditor
                  form={form}
                  billingLibrary={billType.billingLibrary}
                  userId={userId}
                />
              </BillTypeEditorBox>
            </BillTypeEditorBoxContainer>
            <Space height={24} />
          </Container>
        </ScrollView>
      </ContentContainer>
    </Container>
  );
};
// /////////////////////////////////////////////////////////////////////
// END
// /////////////////////////////////////////////////////////////////////
const UpdateBillTypePageContent = ({
  billType,
  userId,
}: {
  billType: BillTypeModel;
  userId: string;
}) => {
  const {billingLibrary} = billType;
  const {navigator} = useNavigationDOM();
  const handleNavigateToBillTemplates = () =>
    navigator.push(`/settings/billing/billing-libraries/${billingLibrary.uuid}/bill-templates`);
  const billTypeForm = BillTypeForm.edit(billType, {userId});
  const {form, handleSubmit} = useUpdateBillTypeMutation({
    billTypeForm,
    onSuccess: handleNavigateToBillTemplates,
    onError: (errors: unknown) => console.log({errors}),
  });
  const field = 'billTypeForm';
  return (
    <BillTypePage
      title={'Edit Bill Template'}
      handleSubmit={handleSubmit}
      handleClose={handleNavigateToBillTemplates}
    >
      <ActionPanel
        index={0}
        title={'Name & Description'}
        headerSubtitle={
          'Add a name and description for this bill. It will be shown to the customer.'
        }
        BodyComponent={BillTypeDetailsPanel}
        bodyComponentProps={{form, field}}
      />
      <Space height={16} />
      <ActionPanel
        index={1}
        title={'Bill Items'}
        headerSubtitle={'Add bill items to always auto-populate when using this bill template.'}
        BodyComponent={BillTypeBillItemsPanel}
        bodyComponentProps={{
          form,
          field: `${field}.billItemTypeIds`,
          billItemTypes: billingLibrary.billItemTypes,
          userId,
          isEnabledTbdBillItems: billingLibrary.organization.features.isEnabledTbdBillItems,
          organization: billType.organization,
        }}
      />
      <Space height={16} />
      <ActionPanel
        index={2}
        title={'Bill Rules'}
        headerSubtitle={
          'Add bill rules to set terms like hour minimums or maximums that apply to the entire bill.'
        }
        BodyComponent={BillTypeBillRulesPanel}
        bodyComponentProps={{
          form,
          field: `${field}.billRuleTypeIds`,
          billRuleTypes: billingLibrary.billRuleTypes,
          userId,
          billingLibrary,
        }}
      />
    </BillTypePage>
  );
};

const UpdateBillTypePage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(UpdateBillTypePage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      billTypeUuid: params.billTypeUuid,
    },
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={'settings'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  const {billType, viewer} = data;
  const {isEnabledConditionalBillItems} = billType.organization.features;

  return (
    <SidebarPageV2 selected={'settings'}>
      {isEnabledConditionalBillItems ? (
        <UpdateBillTypePageContent billType={billType} userId={viewer.id} />
      ) : (
        <UpdateBillTypePageContentV1 billType={billType} userId={viewer.id} />
      )}
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateBillTypePage.query = gql`
  ${BillingLibraryBillTypeEditor.fragment}
  ${BillTypeBillItemsPanel.fragment}
  ${BillTypeBillRulesPanel.fragment}
  ${BillTypeForm.edit.fragment}

  query UpdateBillTypePage(
    $billTypeUuid: String!,
  ) {
    ${gql.query}
    viewer {
      id
    }
    billType(billTypeUuid: $billTypeUuid) {
      id
      organization {
        id
        features {
          isEnabledConditionalBillItems: isEnabled(feature: "CONDITIONAL_BILL_ITEMS")
          isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
        }
        ...BillTypeBillItemsPanel_Organization
      }
      billingLibrary {
        id
        uuid
        billItemTypes {
          id
          ...BillTypeBillItemsPanel_BillItemType
        }
        billRuleTypes {
          id
          ...BillTypeBillRulesPanel_BillRuleType
        }
        ...BillingLibraryBillTypeEditor
        ...BillTypeBillRulesPanel_BillingLibrary
      }
      ...BillTypeForm_edit
    }
  }
`;

export default UpdateBillTypePage;
