// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToggle} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import Table from '@shared/design/components/TableV2Deprecated';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import CompanyOrganizationsForm from '@shared/modules/Organization/forms/CompanyOrganizationsForm';
import useUpdateCompanyOrganizationsMutation from '@shared/modules/Organization/hooks/useUpdateCompanyOrganizationsMutation';

const PanelTable = ({items, isEdit, form, field = ''}) => (
  <Table
    columnDefinitions={[
      {
        flex: 1,
        headerContent: () => <Table.HeaderText>Branch Name</Table.HeaderText>,
        cellContent: isEdit
          ? ({item: organizationForm, rowIndex}) => (
              <FieldInput
                {...form}
                name={`${field}.${rowIndex}.name`}
                input={{
                  placeholder: 'Enter branch name',
                }}
                style={{flex: 1}}
              />
            )
          : ({item: organization}) => <Table.CellText>{organization.name}</Table.CellText>,
      },
      {
        flex: 1,
        headerContent: () => <Table.HeaderText>Identifier</Table.HeaderText>,
        cellContent: isEdit
          ? ({item: organizationForm}) => <Table.CellText>{organizationForm.slug}</Table.CellText>
          : ({item: organization}) => <Table.CellText>{organization.slug}</Table.CellText>,
      },
    ]}
    items={items}
    isDense
  />
);

const CompanyBranchesPanel = ({title, organization, kind, index, refetch, hasEditPermissions}) => {
  const companyBranchesPanelToggle = useToggle({name: `Company Branches Panel Toggle: ${title}`});
  const companyOrganizationsForm = CompanyOrganizationsForm.edit(organization, {
    organizationKind: kind,
  });
  const {form, handleSubmit, submitting} = useUpdateCompanyOrganizationsMutation({
    companyOrganizationsForm,
    onSuccess: () => {
      refetch();
      companyBranchesPanelToggle.handleToggleOff();
    },
    onError: (errors) => console.log({errors}),
  });

  const {organizations} = organization.company;
  // Organizations filtered by panel kind
  const organizationsForKind = organizations.filter((org) => org.kind === kind);
  // Primary branch can see all branches. Child branches can see the main branch or themselves.
  const panelOrganizations = _.orderBy(
    organization.isPrimary || kind === OrganizationKind.MAIN
      ? organizationsForKind
      : organizationsForKind.filter((org) => org.id === organization.id),
    [(organization) => organization.name.toLowerCase()],
    ['asc'],
  );

  if (_.isEmpty(panelOrganizations)) {
    return null;
  }

  return (
    <React.Fragment>
      <EditPanel
        index={index}
        BodyComponent={PanelTable}
        bodyComponentProps={{items: panelOrganizations, isEdit: false}}
        EditBodyComponent={PanelTable}
        editBodyComponentProps={{
          items: _.get(form.values, 'companyOrganizationsForm.organizationForms'),
          isEdit: true,
          form,
          field: 'companyOrganizationsForm.organizationForms',
        }}
        title={title}
        handleSave={handleSubmit}
        isSubmitting={submitting}
        handleCancel={form.handleReset}
        isEditing={companyBranchesPanelToggle.isOn}
        handleEdit={companyBranchesPanelToggle.handleToggleOn}
        handleClose={companyBranchesPanelToggle.handleToggleOff}
        // Primary organizations can edit all branches. Child branches can edit themselves.
        isEditButtonVisible={
          hasEditPermissions && (organization.isPrimary || organization.kind === kind)
        }
      />
      <Space height={24} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyBranchesPanel.fragment = gql`
  fragment CompanyBranchesPanel on Organization {
    id
    isPrimary
    kind
    company {
      id
      organizations {
        id
        kind
        name
        slug
      }
    }
  }
`;

export default CompanyBranchesPanel;
