// Libraries
import React from 'react';

// App
import {Loading, Modal, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// Components
import {Navigation} from 'modules/App/components';
import UpdateEmployeeForm from 'modules/User/components/UpdateEmployeeForm';

const Overlay = Styled.View`
  flex: 1;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props.mobile ? '100%' : '500px')};
  padding-horizontal: 20px;
`;

const Container = Styled.View`
  width: 100%;
  margin-vertical: 60px;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.blue.accent};
`;

const HeaderMainSection = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const HeaderBorderSection = Styled.View`
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.mobile ? 40 : 60)}px;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const UpdateEmployeeModal = ({isOpen, handleClose, userUuid, refetch}) => {
  const responsive = useResponsive();
  const {loading, data} = useQuery(UpdateEmployeeModal.query, {
    skip: !isOpen,
    fetchPolicy: 'network-only',
    variables: {
      userUuid,
    },
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Overlay pointerEvents={'box-none'}>
        <Loading loading={loading}>
          {() =>
            data ? (
              <ScrollView
                contentContainerStyle={{
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Wrapper {...responsive}>
                  <Container>
                    <Header>
                      <HeaderBorderSection {...responsive}>
                        <Navigation.CloseButton responsive={responsive} onPress={handleClose} />
                      </HeaderBorderSection>
                      <HeaderMainSection>
                        <Title>Edit Crew Member</Title>
                      </HeaderMainSection>
                      <HeaderBorderSection />
                    </Header>
                    <UpdateEmployeeForm
                      user={data.user}
                      onSuccess={() => {
                        refetch();
                        handleClose();
                      }}
                    />
                  </Container>
                </Wrapper>
              </ScrollView>
            ) : null
          }
        </Loading>
      </Overlay>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateEmployeeModal.query = gql`
  ${UpdateEmployeeForm.fragment}

  query UpdateEmployeeModal($userUuid: String!) {
    ${gql.query}
    user(uuid: $userUuid) {
      id
      ...UpdateEmployeeForm
    }
  }
`;

export default UpdateEmployeeModal;
