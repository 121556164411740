// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CommercialCatalogForm from '@shared/modules/CommercialCatalog/forms/CommercialCatalogForm';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import ValuationCoverageForm from '@shared/modules/Insurance/forms/ValuationCoverageForm';

export interface ReadonlySectionSettingsType {
  matchSectionUuid?: string;
  setReadonlyForAllSteps?: boolean;
}

const edit = withFragment(
  (documentItem, {document}) => {
    return {
      itemKind: (documentItem as any).itemKind,
      text: (documentItem as any).text,
      isReadonly: (documentItem as any).isReadonly,
      isRequired: (documentItem as any).isRequired,
      input: (documentItem as any).input,
      documentItemForms: (documentItem as any).documentItems?.map((documentItem: any) =>
        DocumentItemForm.edit(documentItem, {document}),
      ),
      valuationCoverageForm:
        (documentItem as any).itemKind === DocumentItemKindV2.INPUT_VALUATION_COVERAGE
          ? ValuationCoverageForm.edit(document.job.valuationCoverage)
          : null,
      commercialCatalogForm: [
        DocumentItemKindV2.INPUT_COMMERCIAL_CATALOG_EQUIPMENTS,
        DocumentItemKindV2.INPUT_COMMERCIAL_CATALOG_MATERIALS,
      ].includes((documentItem as any).itemKind)
        ? CommercialCatalogForm.edit(document.job.commercialCatalog)
        : null,
    };
  },
  gql`
    ${CommercialCatalogForm.edit.fragment}
    ${ValuationCoverageForm.edit.fragment}

    fragment DocumentItemForm_edit on Document {
      id
      job {
        id
        commercialCatalog {
          id
          ...CommercialCatalogForm_edit
        }
        valuationCoverage {
          id
          ...ValuationCoverageForm_edit
        }
      }
    }
  `,
);

const toForm = ({
  itemKind,
  text,
  isReadonly,
  isRequired,
  input,
  documentItemForms,
  commercialCatalogForm,
  valuationCoverageForm,
}: any) => {
  return {
    itemKind,
    text,
    isReadonly,
    isRequired,
    input,
    documentItemForms: documentItemForms ? documentItemForms.map(DocumentItemForm.toForm) : null,
    commercialCatalogForm: commercialCatalogForm
      ? CommercialCatalogForm.toForm(commercialCatalogForm)
      : null,
    valuationCoverageForm: valuationCoverageForm
      ? ValuationCoverageForm.toForm(valuationCoverageForm)
      : null,
  };
};

const toMutation = ({
  itemKind,
  text,
  isReadonly,
  isRequired,
  input,
  documentItemForms,
  commercialCatalogForm,
  valuationCoverageForm,
}: any) => {
  return {
    itemKind,
    text,
    isReadonly,
    isRequired,
    input: JSON.stringify(input),
    documentItemForms: documentItemForms
      ? documentItemForms.map(DocumentItemForm.toMutation)
      : null,
    commercialCatalogForm: commercialCatalogForm
      ? CommercialCatalogForm.toMutation(commercialCatalogForm)
      : null,
    valuationCoverageForm: valuationCoverageForm
      ? ValuationCoverageForm.toMutation(valuationCoverageForm)
      : null,
  };
};

const DocumentItemForm = {
  edit,
  toForm,
  toMutation,
};

export default DocumentItemForm;
