/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteBillRuleTypeMutation from '@shared/modules/Billing/hooks/useDeleteBillRuleTypeMutation';

const DeleteBillRuleTypeModal = ({billRuleTypeId, isOpen, handleClose, refetch}) => {
  const {handleSubmit, submitting} = useDeleteBillRuleTypeMutation({
    billRuleTypeId,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (error) => console.log({error}),
  });
  return (
    <DeleteModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
      title={'Delete Bill Rule?'}
      subtitle={
        "This will delete this bill rule and remove it from all bill templates. You can't undo this action."
      }
    />
  );
};

export default DeleteBillRuleTypeModal;
