// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SettingsClaimAssignmentForm, {
  SettingsClaimAssignmentFormType,
} from '@shared/modules/Settings/forms/SettingsClaimAssignmentForm';

const useUpdateSettingsClaimAssignmentMutation = ({
  settingsClaimAssignmentForm,
  onSuccess,
  onError,
}: {
  settingsClaimAssignmentForm: SettingsClaimAssignmentFormType;
  onSuccess: (response: unknown) => void;
  onError: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      settingsClaimAssignmentForm: SettingsClaimAssignmentForm.toForm(settingsClaimAssignmentForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateSettingsClaimAssignmentMutation.mutation,
    variables: {
      settingsClaimAssignmentForm: SettingsClaimAssignmentForm.toMutation(
        form.values.settingsClaimAssignmentForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateSettingsClaimAssignmentMutation.mutation = gql`
  mutation useUpdateSettingsClaimAssignmentMutation($settingsClaimAssignmentForm: SettingsClaimAssignmentForm!) {
    response: updateSettingsClaimAssignment(settingsClaimAssignmentForm: $settingsClaimAssignmentForm) {
      ${gql.errors}
      settings {
        id
      }
    }
  }
`;

export default useUpdateSettingsClaimAssignmentMutation;
