// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Drawer, Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import TextInputWithDropdown from 'modules/App/components/TextInputWithDropdown';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
  width: ${({responsive}) => (responsive.mobile ? '100%' : '424px')};
`;

const BodyContainer = Styled.View`
  flex: 1;
`;

const LoadingContainer = Styled.View`
  flex: 1;
  background-color: ${colors.white};
  justify-content: center;
`;

const LoadingIndicator = Styled.Loading`
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 16px;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
  padding-horizontal: 16px;
  padding-vertical: 12px;
  background-color: ${colors.white};
`;

const CloseButton = Styled.ButtonV2`
`;

const HeaderText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex-direction: row;
`;

const DropdownSelectedBadgeContainer = Styled.View`
  padding-vertical: 2px;
  padding-horizontal: 4px;
  background-color: ${colors.alpha(colors.orange.status, 0.1)}
  border-radius: 16px;
  border-color: ${colors.orange.status}
  border-width: 1px;
`;

const DropdownSelectedBadgeText = Styled.Text`
  ${Typography.Label4}
  color: ${colors.orange.status};
`;

const handleBlurCurrency = ({form, field}) => {
  const currency = _.get(form.values, field);
  if (currency) {
    form.setFieldValue(field, Currency.toForm(Currency.convertToCents(currency)));
  }
};

const handleBlurPercent = ({form, field}) => {
  const percent = _.get(form.values, field);
  if (percent) {
    form.setFieldValue(field, Percent.toForm(Percent.toFloat(percent)));
  }
};

const DropdownSelectedBadge = () => {
  return (
    <DropdownSelectedBadgeContainer>
      <DropdownSelectedBadgeText>Formula</DropdownSelectedBadgeText>
    </DropdownSelectedBadgeContainer>
  );
};

const NameField = ({form, field, fieldIndex, formulaOptions}) => {
  return (
    <TextInputWithDropdown
      form={form}
      textValueField={`${field}.name`}
      dropdownValueField={`${field}.nameFormulaId`}
      label={'Name'}
      index={fieldIndex}
      dropdownOptions={formulaOptions}
      placeholder={'Formula or custom name'}
      noOptionsMessage={'No formula options'}
      DropdownSelectedBadge={DropdownSelectedBadge}
      isRequired
    />
  );
};

const DescriptionField = ({form, field, fieldIndex}) => {
  return (
    <FieldInput
      {...form}
      index={fieldIndex}
      label={'Description'}
      name={`${field}.description`}
      input={{placeholder: 'Enter a description'}}
    />
  );
};

const AmountField = ({form, field, formulaOptions, isDisabled}) => {
  return (
    <TextInputWithDropdown
      form={form}
      disabled={isDisabled}
      textValueField={`${field}.amount`}
      clearTextValue={''}
      dropdownValueField={`${field}.amountFormulaId`}
      label={'Value'}
      dropdownOptions={formulaOptions}
      placeholder={'Formula or custom value'}
      noOptionsMessage={'No formula options'}
      onBlur={() => handleBlurCurrency({form, field: `${field}.amount`})}
      DropdownSelectedBadge={DropdownSelectedBadge}
    />
  );
};

const PercentField = ({form, field, formulaOptions, isDisabled}) => {
  return (
    <TextInputWithDropdown
      form={form}
      disabled={isDisabled}
      textValueField={`${field}.percentage`}
      clearTextValue={''}
      dropdownValueField={`${field}.amountFormulaId`}
      label={'Value'}
      dropdownOptions={formulaOptions}
      placeholder={'Formula or custom percent'}
      noOptionsMessage={'No formula options'}
      onBlur={() => handleBlurPercent({form, field: `${field}.percentage`})}
      DropdownSelectedBadge={DropdownSelectedBadge}
    />
  );
};

const PriceField = ({form, field, fieldIndex, formulaOptions, isDisabled}) => {
  return (
    <TextInputWithDropdown
      form={form}
      disabled={isDisabled}
      index={fieldIndex}
      textValueField={`${field}.amount`}
      clearTextValue={''}
      dropdownValueField={`${field}.amountFormulaId`}
      label={'Price'}
      dropdownOptions={formulaOptions}
      placeholder={'Formula or custom price'}
      noOptionsMessage={'No formula options'}
      onBlur={() => handleBlurCurrency({form, field: `${field}.amount`})}
      DropdownSelectedBadge={DropdownSelectedBadge}
    />
  );
};

const QuantityField = ({form, field, fieldIndex, formulaOptions}) => {
  return (
    <Row index={fieldIndex}>
      <TextInputWithDropdown
        form={form}
        textValueField={`${field}.minQuantity`}
        clearTextValue={''}
        dropdownValueField={`${field}.minQuantityFormulaId`}
        label={'Min Quantity'}
        dropdownOptions={formulaOptions}
        placeholder={'Formula or quantity'}
        noOptionsMessage={'No formula options'}
        DropdownSelectedBadge={DropdownSelectedBadge}
      />
      <Space width={8} />
      <TextInputWithDropdown
        form={form}
        textValueField={`${field}.maxQuantity`}
        clearTextValue={''}
        dropdownValueField={`${field}.maxQuantityFormulaId`}
        label={'Max Quantity'}
        dropdownOptions={formulaOptions}
        placeholder={'Formula or quantity'}
        noOptionsMessage={'No formula options'}
        DropdownSelectedBadge={DropdownSelectedBadge}
      />
    </Row>
  );
};

const TaxableField = ({form, field}) => {
  const billStage = _.get(form.values, `${field}.billStage`);
  const isBefore = billStage === BillStage.PRE_SUBTOTAL;
  const isTaxable = _.get(form.values, `${field}.isTaxable`);
  return (
    <Checkbox
      isDisabled={!isBefore}
      label={'Taxable'}
      hint={
        'When checked, sales tax will automatically be applied to this item. This can be overridden in each bill.'
      }
      isChecked={isTaxable}
      handleToggle={() => form.setFieldValue(`${field}.isTaxable`, !isTaxable)}
    />
  );
};

const Header = ({headerText, handleClose}) => {
  return (
    <HeaderContainer>
      <HeaderText>{headerText}</HeaderText>
      <CloseButton onPress={handleClose}>
        <Icon source={Icon.Times} size={20} color={colors.gray.primary} />
      </CloseButton>
    </HeaderContainer>
  );
};

const Footer = ({submitting, handleSubmit, handleClose}) => {
  return (
    <FooterContainer>
      <Button
        color={colors.white}
        textColor={colors.gray.secondary}
        onPress={handleClose}
        isDisabled={submitting}
        text={'Cancel'}
      />
      <Space width={8} />
      <Button
        onPress={handleSubmit}
        isDisabled={submitting}
        isSubmitting={submitting}
        text={'Save'}
      />
    </FooterContainer>
  );
};

const BillItemLoading = () => {
  return (
    <LoadingContainer>
      <LoadingIndicator />
    </LoadingContainer>
  );
};

const DrawerContent = ({
  headerText,
  handleClose,
  isLoading,
  isSubmitting,
  handleSubmit,
  children,
}) => {
  const responsive = useResponsive();
  return (
    <Container responsive={responsive}>
      <Header headerText={headerText} handleClose={handleClose} />
      <BodyContainer>
        <ScrollView contentContainerStyle={{padding: 16}}>
          <Loading loading={isLoading} as={BillItemLoading}>
            {() => <React.Fragment>{children}</React.Fragment>}
          </Loading>
        </ScrollView>
      </BodyContainer>
      <Footer submitting={isSubmitting} handleSubmit={handleSubmit} handleClose={handleClose} />
    </Container>
  );
};

const BillItemTypeDrawer = ({
  isOpen,
  isLoading,
  handleClose,
  headerText,
  isSubmitting,
  handleSubmit,
  children,
}) => {
  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      <DrawerContent
        handleClose={handleClose}
        headerText={headerText}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      >
        {children}
      </DrawerContent>
    </Drawer>
  );
};

BillItemTypeDrawer.DropdownSelectedBadge = DropdownSelectedBadge;
BillItemTypeDrawer.NameField = NameField;
BillItemTypeDrawer.DescriptionField = DescriptionField;
BillItemTypeDrawer.AmountField = AmountField;
BillItemTypeDrawer.PriceField = PriceField;
BillItemTypeDrawer.PercentField = PercentField;
BillItemTypeDrawer.QuantityField = QuantityField;
BillItemTypeDrawer.TaxableField = TaxableField;

// --------------------------------------------------
// Props
// --------------------------------------------------
BillItemTypeDrawer.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  headerText: PropTypes.string,
};

BillItemTypeDrawer.defaultProps = {
  isOpen: false,
  isLoading: false,
  isSubmitting: false,
  handleSubmit: () => {},
  handleClose: () => {},
  headerText: '',
};

export default BillItemTypeDrawer;
