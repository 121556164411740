// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {FlatList, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import CalendarDaySummaryModal from 'modules/App/Day/components/CalendarDaySummaryModal';
import CalendarProjectTypeDaysSection from 'modules/App/Day/components/CalendarProjectTypeDaysSection';
import DayJobItem from 'modules/App/Day/components/DayJobItem';
import DayJobItemV1 from 'modules/App/Day/components/DayJobItemV1';
import DayNotesSection from 'modules/App/Day/components/DayNotesSection';
import MultibranchCalendarProjectTypeDaysSection from 'modules/App/Day/components/MultibranchCalendarProjectTypeDaysSection';

const Container = Styled.View`
  flex: 1;
`;

const Header = Styled.View`
  padding-top: ${(props) => (props.mobile ? 0 : 10)}px;
  padding-bottom: ${(props) => (props.mobile ? 10 : 10)}px;
  background-color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-horizontal: 10px;
`;

const Title = Styled.H3`
  ${fontWeight(900)}
  color: ${colors.gray.primary};
  letter-spacing: -0.5;
`;

const Subtitle = Styled.H7`
  color: ${colors.gray.primary};
`;

const Jobs = Styled.View`
`;

const Section = Styled.View`
  margin-bottom: 10px;
`;

const Label = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Job = Styled.View`
  margin-bottom: ${(props) => (props.isLast ? 0 : 10)}px;
  margin-horizontal: 10px;
`;

const EmptyContainer = Styled.View`
  flex: 1;
  align-items: center;
  padding-vertical: 20px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.gray.border};
`;

const EmptyMessage = Styled.H8`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const Actions = Styled.View`
`;

const Touchable = Styled.Touchable`
`;

const ViewSummaryLink = Styled.H6`
  color: ${colors.blue.interactive};
`;

const getUnscheduledJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return job.primaryStatus !== 'CANCELLED' && !job.isTest && !job.startTime1;
  });
};

const getMorningJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return (
      job.primaryStatus !== 'CANCELLED' &&
      !job.isTest &&
      Datetime.isBetweenTime(job.startTime1, '0000', '1200')
    );
  });
};

const getAfternoonJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return (
      job.primaryStatus !== 'CANCELLED' &&
      !job.isTest &&
      Datetime.isBetweenTime(job.startTime1, '1200', '1800')
    );
  });
};

const getEveningJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return (
      job.primaryStatus !== 'CANCELLED' &&
      !job.isTest &&
      Datetime.isBetweenTime(job.startTime1, '1800', '2400')
    );
  });
};

const getCancelledJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return job.primaryStatus === 'CANCELLED' && !job.isTest;
  });
};

const getTrainingJobs = ({jobs}) => {
  return jobs.filter((job) => job.isTest);
};

const getMorningJobsCount = ({jobs}) => {
  return getMorningJobs({jobs}).length;
};

const getAfternoonJobsCount = ({jobs}) => {
  return getAfternoonJobs({jobs}).length;
};

const getEveningJobsCount = ({jobs}) => {
  return getEveningJobs({jobs}).length;
};

const getPrimaryJobsCountsText = ({jobs}) => {
  return (
    `${getMorningJobsCount({jobs})} morning - ` +
    `${getAfternoonJobsCount({jobs})} afternoon - ` +
    `${getEveningJobsCount({jobs})} evening`
  );
};

const JobsSection = ({
  hideIfEmpty,
  empty,
  label,
  jobs,
  isEnabledMovesCalendarJobCardV2,
  refetch,
}) => {
  if (hideIfEmpty && jobs.length === 0) {
    return null;
  }

  return (
    <Section>
      <Row>
        <Label>{`${label} (${jobs.length})`}</Label>
      </Row>
      <Space height={5} />
      <FlatList
        data={jobs}
        keyExtractor={(job) => job.id}
        renderItem={({item: job, index}) => (
          <Job isLast={index === jobs.length - 1}>
            {isEnabledMovesCalendarJobCardV2 ? (
              <DayJobItem job={job} refetch={refetch} />
            ) : (
              <DayJobItemV1 job={job} />
            )}
          </Job>
        )}
        ListEmptyComponent={() => (
          <Row>
            <EmptyContainer>
              <EmptyMessage>{empty}</EmptyMessage>
            </EmptyContainer>
          </Row>
        )}
      />
    </Section>
  );
};

const JobsList = ({jobs, isEnabledMovesCalendarJobCardV2, refetch}) => (
  <Jobs>
    <JobsSection
      hideIfEmpty
      empty={`No unscheduled jobs`}
      label={'Unscheduled Jobs'}
      jobs={getUnscheduledJobs({jobs})}
      refetch={refetch}
      isEnabledMovesCalendarJobCardV2={isEnabledMovesCalendarJobCardV2}
    />
    <Space height={20} />
    <JobsSection
      empty={`No morning jobs`}
      label={'Morning Jobs'}
      jobs={getMorningJobs({jobs})}
      refetch={refetch}
      isEnabledMovesCalendarJobCardV2={isEnabledMovesCalendarJobCardV2}
    />
    <Space height={20} />
    <JobsSection
      empty={`No afternoon jobs`}
      label={'Afternoon Jobs'}
      jobs={getAfternoonJobs({jobs})}
      refetch={refetch}
      isEnabledMovesCalendarJobCardV2={isEnabledMovesCalendarJobCardV2}
    />
    <Space height={20} />
    <JobsSection
      empty={`No evening jobs`}
      label={'Evening Jobs'}
      jobs={getEveningJobs({jobs})}
      refetch={refetch}
      isEnabledMovesCalendarJobCardV2={isEnabledMovesCalendarJobCardV2}
    />
    <Space height={20} />
    <JobsSection
      empty={'No cancelled jobs'}
      label={'Cancelled Jobs'}
      jobs={getCancelledJobs({jobs})}
      refetch={refetch}
      isEnabledMovesCalendarJobCardV2={isEnabledMovesCalendarJobCardV2}
    />
    <Space height={20} />
    <JobsSection
      empty={`No training jobs`}
      label={'Training Jobs'}
      jobs={getTrainingJobs({jobs})}
      refetch={refetch}
      isEnabledMovesCalendarJobCardV2={isEnabledMovesCalendarJobCardV2}
    />
    <Space height={20} />
  </Jobs>
);

const DayJobsHeader = ({date, hasTitleDay, jobs, calendarDay, responsive}) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const organization = _.get(calendarDay, 'day.organization', false);
  const {isEnabledCalendarDaySummary, isEnabledMultibranchFilterView} = organization.features;

  return (
    <Header {...responsive}>
      {hasTitleDay && (
        <Row>
          <Title>{Datetime.convertToDisplayDate(date)}</Title>
        </Row>
      )}
      <Row>
        <Subtitle>{getPrimaryJobsCountsText({jobs})}</Subtitle>
      </Row>
      {isEnabledCalendarDaySummary && !!calendarDay && (
        <Row style={{marginTop: 5}}>
          <Actions>
            <Touchable onPress={handleOpen}>
              <ViewSummaryLink>View Summary</ViewSummaryLink>
            </Touchable>
          </Actions>
          <CalendarDaySummaryModal
            isOpen={isOpen}
            calendarDay={calendarDay}
            onClose={handleClose}
          />
        </Row>
      )}
      {!!calendarDay && (
        <Row>
          {isEnabledMultibranchFilterView && organization.canViewOtherBranchesData ? (
            <MultibranchCalendarProjectTypeDaysSection date={date} showEstimates />
          ) : (
            <CalendarProjectTypeDaysSection date={date} showEstimates />
          )}
        </Row>
      )}
    </Header>
  );
};

const EstimatesDayJobsList = ({date, calendarDay, hasTitleDay, refetch}) => {
  const responsive = useResponsive();

  return (
    <Container>
      {!responsive.mobile && (
        <DayJobsHeader
          date={date}
          hasTitleDay={hasTitleDay}
          jobs={calendarDay ? calendarDay.jobs : []}
          calendarDay={calendarDay}
          responsive={responsive}
        />
      )}
      <ScrollView
        style={{
          flex: 1,
        }}
      >
        {responsive.mobile && (
          <DayJobsHeader
            date={date}
            hasTitleDay={false}
            jobs={calendarDay ? calendarDay.jobs : []}
            calendarDay={calendarDay}
            responsive={responsive}
          />
        )}
        {!!calendarDay && (
          <DayNotesSection day={calendarDay.day} style={{marginHorizontal: '10px'}} />
        )}
        <Space height={16} />
        <JobsList
          refetch={refetch}
          jobs={calendarDay ? calendarDay.jobs : []}
          isEnabledMovesCalendarJobCardV2={
            calendarDay.day.organization.features.isEnabledMovesCalendarJobCardV2
          }
        />
      </ScrollView>
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
EstimatesDayJobsList.propTypes = {
  date: PropTypes.string.isRequired,
  hasTitleDay: PropTypes.bool,
};

EstimatesDayJobsList.defaultProps = {
  hasTitleDay: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EstimatesDayJobsList.fragment = gql`
  ${CalendarDaySummaryModal.fragment}
  ${DayJobItemV1.fragment}
  ${DayJobItem.fragment}
  ${DayNotesSection.fragment}

  fragment EstimatesDayJobsList on CalendarDay {
    day {
      id
      organization {
        id
        canViewOtherBranchesData
        features {
          isEnabledCalendarDaySummary: isEnabled(feature: "CALENDAR_DAY_SUMMARY")
          isEnabledMovesCalendarJobCardV2: isEnabled(feature: "MOVES_CALENDAR_JOB_CARD_V2")
          isEnabledMultibranchFilterView: isEnabled(feature: "MULTIBRANCH_FILTER_VIEW")
        }
      }
      ...DayNotesSection
    }
    jobs {
      id
      isTest
      primaryStatus: calendarPrimaryStatus
      ...DayJobItemV1
      ...DayJobItem
    }
    ...CalendarDaySummaryModal
  }
`;

export default EstimatesDayJobsList;
