/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const DispatchNotesText = Styled.Text`
  ${({isResponsive}) => (isResponsive ? Typography.Responsive.Body : Typography.Body4)}
  color: ${colors.gray.secondary};
  text-decoration: underline;
  text-decoration-color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
`;

const IconContainer = Styled.View`
  width: 12px;
  align-items: center;
`;

const DispatchNotesTextWrapper = ({dispatchNotes, isHovered, hasTooltip, children}) => {
  if (dispatchNotes && hasTooltip) {
    return (
      <TextTooltip
        text={dispatchNotes}
        style={{width: 320}}
        visible={isHovered}
        placement={'bottomLeft'}
      >
        {children}
      </TextTooltip>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const JobDispatchNotes = ({job, numberOfLines, popover, isResponsive, hideIcon, style}) => {
  const responsive = useResponsive();
  const {ref, isHovered} = useHover();
  const {dispatchNotes} = job;
  return (
    <Button onPress={popover.handleToggle} ref={ref} style={style}>
      <Popover.Content innerRef={popover.ref}>
        <Row>
          {!hideIcon && (
            <React.Fragment>
              <TextTooltip text={'Dispatch Notes'}>
                <IconContainer>
                  <Icon
                    source={Icon.StickyNote}
                    color={colors.gray.primary}
                    size={11}
                    style={{marginBottom: 2}}
                  />
                </IconContainer>
              </TextTooltip>
              <Space width={6} />
            </React.Fragment>
          )}
          <DispatchNotesTextWrapper
            dispatchNotes={job.dispatchNotes}
            isHovered={isHovered}
            hasTooltip={isResponsive ? responsive.desktop : true}
          >
            <DispatchNotesText
              isResponsive={isResponsive}
              responsive={responsive}
              numberOfLines={numberOfLines}
              style={{
                fontStyle: dispatchNotes ? null : 'italic',
                fontWeight: isHovered || popover.isOpen ? '700' : '500',
              }}
            >
              {dispatchNotes || 'Click to add a note'}
            </DispatchNotesText>
          </DispatchNotesTextWrapper>
        </Row>
      </Popover.Content>
    </Button>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchNotes.fragment = gql`
  fragment JobDispatchNotes on Job {
    id
    dispatchNotes
  }
`;

export default JobDispatchNotes;
