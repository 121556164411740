// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Styled, Space, Icon} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {DocumentItemInputRadioButtonsType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import {SetDocumentContentJsonType} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import InputIsRequiredCheckBox from 'modules/Organization/Settings/Document/components/InputIsRequiredCheckBox';

const TextInput = Styled.TextInput`
  ${Typography.Responsive.Body}
  border-width: 1px;
  border-color: ${colors.gray.border};
  width: 100%;
  padding-vertical: 8px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const HelperText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.secondary};
`;

const RadioButtonContainer = Styled.View<{index: number}>`
  z-index: ${({index}) => 100 - index};
`;

const DeleteFieldColumn = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-left: 16px;
`;

const getOptions = (maxValue: number) => {
  const options = [];
  for (let i = 1; i <= maxValue; i += 1) {
    options.push({label: i, value: `${i}`});
  }
  return options;
};

const addFieldInput = ({
  setDraftDocumentContentJson,
  documentItemField,
}: {
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentItemField: string;
}) => {
  setDraftDocumentContentJson((prevState) => {
    const documentItem = _.get(prevState, documentItemField);
    documentItem.input.value.values.push(['', '', '']);
    documentItem.input.metadata.items.push({
      options: [],
      inputs: {},
    });
    _.set(prevState, documentItemField, documentItem);
    return {...prevState};
  });
};

const removeFieldInput = ({
  setDraftDocumentContentJson,
  documentItemField,
  valueIndex,
}: {
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentItemField: string;
  valueIndex: number;
}) => {
  setDraftDocumentContentJson((prevState) => {
    const documentItem = _.get(prevState, documentItemField);
    documentItem.input.value.values.splice(valueIndex, 1);
    documentItem.input.metadata.items.splice(valueIndex, 1);
    _.set(prevState, documentItemField, documentItem);
    return {...prevState};
  });
};

const InputRadioButtonsWithTextInputsEditor = ({
  documentItem,
  documentItemField,
  setDraftDocumentContentJson,
}: {
  documentItem: DocumentItemInputRadioButtonsType;
  documentItemField: string;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
}) => {
  const responsive = useResponsive();
  const {values} = documentItem.input.value;
  const {items} = documentItem.input.metadata;
  const initialOptionChoicesAndInput = items.map((currentItem) => {
    const lowerCaseOptions = currentItem.options.map((option) => _.camelCase(option.toLowerCase()));
    return {
      optionNum: currentItem.options.length ? currentItem.options.length : '',
      optionNumInputIndex: lowerCaseOptions.indexOf(Object.keys(currentItem.inputs)[0]),
    };
  });

  return (
    <React.Fragment>
      <InputIsRequiredCheckBox
        documentItem={documentItem}
        documentItemField={documentItemField}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
      />
      <Space height={24} />
      {values.map((value, valueIndex) => (
        <RadioButtonContainer key={valueIndex} index={valueIndex}>
          <Text>{`Radio Button ${valueIndex + 1}`}</Text>
          <Space height={8} />
          <Row>
            <TextInput
              key={valueIndex}
              responsive={responsive}
              placeholder={'Enter radio button title'}
              value={value[0]}
              multiline
              onChangeText={(text: string) => {
                setDraftDocumentContentJson((prevState) => {
                  _.set(prevState, `${documentItemField}.input.value.values.${valueIndex}.0`, text);
                  return {...prevState};
                });
              }}
            />
            {values.length > 1 && (
              <DeleteFieldColumn>
                <TertiaryButton
                  iconLeft={Icon.Trash}
                  iconSize={12}
                  textColor={colors.gray.secondary}
                  onPress={() =>
                    removeFieldInput({
                      setDraftDocumentContentJson,
                      documentItemField,
                      valueIndex,
                    })
                  }
                  isResponsive
                />
              </DeleteFieldColumn>
            )}
          </Row>
          <Space height={24} />
          <FieldInput
            value={`${initialOptionChoicesAndInput[valueIndex].optionNum}`}
            label={'Choices'}
            component={DropdownInput}
            isResponsive
            input={{
              style: {width: 250},
              options: getOptions(10),
              setFieldValue: (name: string, text: string) => {
                const number = Number(text);
                setDraftDocumentContentJson((prevState) => {
                  const {metadata} = _.get(prevState, documentItemField).input;
                  const options = [];

                  for (let optionsLength = 0; optionsLength <= number - 1; optionsLength += 1) {
                    const currentOption = metadata.items[valueIndex].options[optionsLength];
                    options.push(currentOption || '');
                  }

                  metadata.items[valueIndex].options = options;
                  metadata.items[valueIndex].inputs = metadata.items[valueIndex].inputs || {};
                  _.set(prevState, `${documentItemField}.input.metadata`, metadata);

                  return {...prevState};
                });
              },
            }}
          />
          <Space height={8} />
          <HelperText
            responsive={responsive}
          >{`If 'add input' is selected, an input field will appear below the choice.`}</HelperText>
          <Space height={8} />
          {items[valueIndex].options.map((option, optionIndex) => {
            const isChecked =
              initialOptionChoicesAndInput[valueIndex].optionNumInputIndex === optionIndex;
            return (
              <React.Fragment key={optionIndex}>
                <Row>
                  <FieldInput
                    key={`${optionIndex} option name`}
                    style={{width: '60%'}}
                    isResponsive
                    input={{
                      placeholder: `Option ${optionIndex + 1}`,
                      onChangeText: (text: string) => {
                        setDraftDocumentContentJson((prevState) => {
                          _.set(
                            prevState,
                            `${documentItemField}.input.metadata.items.${valueIndex}.options.${optionIndex}`,
                            text,
                          );
                          return {...prevState};
                        });
                      },
                    }}
                    value={option}
                  />
                  <Space width={12} />
                  <Checkbox
                    key={`${optionIndex} add input`}
                    isChecked={isChecked}
                    isDisabled={option === ''}
                    handleToggle={() => {
                      setDraftDocumentContentJson((prevState) => {
                        const itemState = _.get(prevState, documentItemField).input.metadata.items[
                          valueIndex
                        ];
                        const getInputKey = items[valueIndex].options[optionIndex];

                        if (isChecked) {
                          itemState.inputs = {};
                        } else {
                          itemState.inputs = {[_.camelCase(getInputKey.toLowerCase())]: true};
                        }

                        _.set(
                          prevState,
                          `${documentItemField}.input.metadata.items.${valueIndex}`,
                          itemState,
                        );
                        return {...prevState};
                      });
                    }}
                    childrenRight
                  >
                    <Space width={8} />
                    <Text>{'Add input'}</Text>
                  </Checkbox>
                </Row>
                <Space height={8} />
              </React.Fragment>
            );
          })}
          <Space height={32} />
        </RadioButtonContainer>
      ))}
      <TertiaryButton
        iconLeft={Icon.Plus}
        text={'Add Item'}
        onPress={() => addFieldInput({setDraftDocumentContentJson, documentItemField})}
      />
    </React.Fragment>
  );
};

export default InputRadioButtonsWithTextInputsEditor;
