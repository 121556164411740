const IS = 'IS';
const IS_NOT = 'IS_NOT';
const IS_EMPTY = 'IS_EMPTY';
const IS_NOT_EMPTY = 'IS_NOT_EMPTY';

const COMPARATOR_TYPE_TO_FORMULA_STRING = {
  [IS]: '==',
  [IS_NOT]: '!=',
};

const COMPARATOR_TYPE_OPTIONS = [
  {
    value: IS,
    label: 'Is equal to',
  },
  {
    value: IS_NOT,
    label: 'Is not equal to',
  },
];

const getFormulaString = (comparatorType: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return COMPARATOR_TYPE_TO_FORMULA_STRING[comparatorType];
};

const WorkflowFormulaBuilder = {
  // Enum Options
  IS,
  IS_NOT,
  IS_EMPTY,
  IS_NOT_EMPTY,

  COMPARATOR_TYPE_OPTIONS,
  getFormulaString,
};

export default WorkflowFormulaBuilder;
