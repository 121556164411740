// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import EquipmentAndMaterialContent from 'modules/Organization/Settings/Moves/components/EquipmentAndMaterialContent';

const OrganizationSettingsEquipmentAndMaterialPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsEquipmentAndMaterialPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Loading loading={loading}>
      {() => <EquipmentAndMaterialContent viewer={data.viewer} refetch={refetch} />}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsEquipmentAndMaterialPage.query = gql`
  ${EquipmentAndMaterialContent.fragment}
  query OrganizationSettingsEquipmentAndMaterialPage {
    ${gql.query}
    viewer {
      id
      ...EquipmentAndMaterialContent
    }
  }
`;

export default OrganizationSettingsEquipmentAndMaterialPage;
