/* eslint-disable no-case-declarations */
// Libraries
import _ from 'lodash';
import moment from 'moment';

// App
import RecurringTaskCadenceKind from '@shared/modules/Accounting/enums/RecurringTaskCadenceKind';
import RecurringTaskCadenceRepeatsOn from '@shared/modules/Accounting/enums/RecurringTaskCadenceRepeatsOn';

const EMPLOYEE_PAYROLL_REPORT = 'EMPLOYEE_PAYROLL_REPORT';
const CONTRACTOR_PAYROLL_REPORT = 'CONTRACTOR_PAYROLL_REPORT';
const INVOICE = 'INVOICE';

const getDisplayRecurringTask = (recurringTask: any) => {
  const emptyStateMessage = 'Set Report Cycle';
  if (!recurringTask) {
    return emptyStateMessage;
  }

  const {cadenceKind, cadenceInterval, cadenceRepeatsOn} = recurringTask;
  switch (cadenceKind) {
    case RecurringTaskCadenceKind.WEEKLY:
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const dayOfWeek = _.find(RecurringTaskCadenceRepeatsOn.CADENCE_REPEATS_ON_WEEKDAY_MAP, {
        value: cadenceRepeatsOn,
      }).label;
      if (cadenceInterval > 1) {
        return `Every ${cadenceInterval} weeks on ${dayOfWeek}`;
      } else {
        return `Weekly on ${dayOfWeek}`;
      }
    case RecurringTaskCadenceKind.MONTHLY:
      const dayOfMonth = moment().date(cadenceRepeatsOn).format('Do');
      if (cadenceInterval > 1) {
        return `Every ${cadenceInterval} months on the ${dayOfMonth}`;
      } else {
        return `Monthly on the ${dayOfMonth}`;
      }
    case RecurringTaskCadenceKind.BI_MONTHLY:
      return `Bi-monthly on the 1st and 15th`;
    default:
      return emptyStateMessage;
  }
};

export default {
  EMPLOYEE_PAYROLL_REPORT,
  CONTRACTOR_PAYROLL_REPORT,

  INVOICE,

  getDisplayRecurringTask,
};
