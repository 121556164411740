// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {ClaimModel, AttachmentModel} from '@supermove/models';
import {Currency, withFragment, Datetime} from '@supermove/utils';

// App
import UpdateAttachmentForm, {
  UpdateAttachmentFormType,
  UpdateAttachmentFormTypeMutation,
} from '@shared/modules/File/forms/UpdateAttachmentForm';

export interface ClaimFormType {
  claimId: string;
  organizationId: number;
  projectId: number;
  claimTypeId: number;
  claimStatusId: number;
  jobId: number | null;
  notes: string;
  amount: number | null;
  estimatedAmount: number | null;
  closedDate: Date | null;
  userIds: string[];
  attachmentForms: UpdateAttachmentFormType[];

  // Private
  createdAt: Date | null;
}

export interface ClaimFormTypeForm {
  claimId: string;
  organizationId: string;
  projectId: string;
  claimTypeId: string;
  claimStatusId: string;
  jobId: string | null;
  notes: string;
  amount: string | null;
  estimatedAmount: string | null;
  closedDate: string | null;
  userIds: string[];
  attachmentForms: UpdateAttachmentFormType[];

  // Private
  createdAt: string | null;
}

export interface ClaimFormTypeMutation {
  claimId: number;
  organizationId: number;
  projectId: number;
  claimTypeId: number;
  claimStatusId: number;
  jobId: number | null;
  notes: string;
  amount: number | null;
  estimatedAmount: number | null;
  closedDate: Date | null;
  userIds: number[];
  attachmentForms: UpdateAttachmentFormTypeMutation[];
}

const getIsUploadingAttachment = (claimForm: ClaimFormType) => {
  // Attachments get uploaded immediately upon selection. If an attachmentForm does
  // not have an attachmentId, then the attachment was just added and is in the middle
  // of being uploaded. Once uploaded the form will update with the attachmentId.
  return !!_.find(claimForm.attachmentForms, (attachmentForm) => !attachmentForm.attachmentId);
};

const _new = ({organizationId, projectId}: {organizationId?: number; projectId?: number}) => ({
  claimId: null,
  organizationId,
  projectId,
  claimTypeId: null,
  claimStatusId: null,
  jobId: null,
  notes: '',
  amount: '',
  estimateAmount: '',
  createdAt: null,
  closedDate: null,
  userIds: [],
  attachmentForms: [],
});

const _delete = ({claimId}: {claimId: number}) => ({
  ..._new({}),
  claimId,
});

const edit = withFragment(
  (claim: ClaimModel): ClaimFormType => ({
    claimId: claim.id,
    organizationId: claim.organizationId,
    projectId: claim.projectId,
    claimTypeId: claim.claimTypeId,
    claimStatusId: claim.claimStatusId,
    jobId: claim.jobId,
    notes: claim.notes,
    amount: claim.amount,
    estimatedAmount: claim.estimatedAmount,
    closedDate: claim.closedDate,
    userIds: claim.activeUsers.map((user) => _.toString(user.id)),
    attachmentForms: claim.attachments.map((attachment: AttachmentModel) =>
      UpdateAttachmentForm.edit(attachment),
    ),

    // Private, only for rendering
    createdAt: claim.createdAt,
  }),
  gql`
    ${UpdateAttachmentForm.edit.fragment}
    fragment ClaimForm_edit on Claim {
      id
      organizationId
      projectId
      claimTypeId
      claimStatusId
      jobId
      notes
      amount
      estimatedAmount
      createdAt
      closedDate
      activeUsers {
        id
      }
      attachments {
        id
        ...UpdateAttachmentForm_edit
      }
    }
  `,
);

const toForm = ({
  claimId,
  organizationId,
  projectId,
  claimTypeId,
  claimStatusId,
  jobId,
  notes,
  amount,
  estimatedAmount,
  createdAt,
  closedDate,
  userIds,
  attachmentForms,
}: ClaimFormType): ClaimFormTypeForm => ({
  claimId,
  organizationId: _.toString(organizationId),
  projectId: _.toString(projectId),
  claimTypeId: _.toString(claimTypeId),
  claimStatusId: _.toString(claimStatusId),
  jobId: jobId ? _.toString(jobId) : null,
  notes,
  amount: _.isNil(amount) ? '' : Currency.toForm(amount),
  estimatedAmount: _.isNil(estimatedAmount) ? '' : Currency.toForm(estimatedAmount),
  closedDate: Datetime.toFormDate(closedDate),
  userIds: userIds.map((userId) => _.toString(userId)),
  attachmentForms: attachmentForms.map((attachmentForm: any) =>
    UpdateAttachmentForm.toForm(attachmentForm),
  ),

  // Private
  createdAt: Datetime.toFormDate(createdAt),
});

// createdAt is non-editable, so it is excluded from toMutation
const toMutation = ({
  claimId,
  organizationId,
  projectId,
  claimTypeId,
  claimStatusId,
  jobId,
  notes,
  amount,
  estimatedAmount,
  closedDate,
  userIds,
  attachmentForms,
}: ClaimFormTypeForm): ClaimFormTypeMutation => ({
  claimId: _.toNumber(claimId),
  organizationId: _.toNumber(organizationId),
  projectId: _.toNumber(projectId),
  claimTypeId: _.toNumber(claimTypeId),
  claimStatusId: _.toNumber(claimStatusId),
  jobId: jobId ? _.toNumber(jobId) : null,
  notes,
  amount: amount === '' ? null : Currency.toMutation(amount),
  estimatedAmount: estimatedAmount === '' ? null : Currency.toMutation(estimatedAmount),
  closedDate: closedDate ? Datetime.toMutationDate(closedDate) : null,
  userIds: [...new Set(userIds)].map((userId) => _.toNumber(userId)),
  attachmentForms: attachmentForms.map((attachmentForm: UpdateAttachmentFormType) =>
    UpdateAttachmentForm.toMutation(attachmentForm),
  ),
});

const ClaimForm = {
  new: _new,
  delete: _delete,
  edit,
  toForm,
  toMutation,

  // Helpers
  getIsUploadingAttachment,
};

export default ClaimForm;
