// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompanyOrganizationsForm from '@shared/modules/Organization/forms/CompanyOrganizationsForm';

const useUpdateCompanyOrganizationsMutation = ({
  companyOrganizationsForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      companyOrganizationsForm: CompanyOrganizationsForm.toForm(companyOrganizationsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCompanyOrganizationsMutation.mutation,
    variables: {
      companyOrganizationsForm: CompanyOrganizationsForm.toMutation(
        form.values.companyOrganizationsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCompanyOrganizationsMutation.mutation = gql`
  mutation useUpdateCompanyOrganizationsMutation($companyOrganizationsForm: CompanyOrganizationsForm!) {
    response: updateCompanyOrganizations(companyOrganizationsForm: $companyOrganizationsForm) {
      ${gql.errors}
      organization {
        id
      }
    }
  }
`;

export default useUpdateCompanyOrganizationsMutation;
