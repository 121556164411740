// Libraries
import _ from 'lodash';

const IS_ALL_COLLAPSED = 'IS_ALL_COLLAPSED';
const IS_ALL_EXPANDED = 'IS_ALL_EXPANDED';
const IS_EXPANDED_AND_COLLAPSED = 'IS_EXPANDED_AND_COLLAPSED';

const getIsSectionCollapsed = ({isCollapsedSections, sectionId, projectOrJobId}: any) => {
  const sectionIndex = _.findIndex(isCollapsedSections, (section) => {
    return (section as any).sectionIdentifier === `${projectOrJobId}${sectionId}`;
  });
  return isCollapsedSections[sectionIndex]?.isCollapsed || false;
};

const toggleSectionCollapsed = ({
  isCollapsedSections,
  sectionId,
  setIsCollapsedSections,
  isCollapsed,
  projectOrJobId,
}: any) => {
  const sectionIndex = _.findIndex(isCollapsedSections, (section) => {
    return (section as any).sectionIdentifier === `${projectOrJobId}${sectionId}`;
  });
  const updatedCollapsedSections = _.clone(isCollapsedSections);
  const sectionToUpdate = updatedCollapsedSections[sectionIndex];
  if (sectionToUpdate) {
    sectionToUpdate.isCollapsed = isCollapsed;
  }
  setIsCollapsedSections(updatedCollapsedSections);
};

const VariableSectionsCollapsedStates = {
  IS_ALL_COLLAPSED,
  IS_ALL_EXPANDED,
  IS_EXPANDED_AND_COLLAPSED,

  // Functions
  getIsSectionCollapsed,
  toggleSectionCollapsed,
};

export default VariableSectionsCollapsedStates;
