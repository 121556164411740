// Libraries
import React from 'react';

// Supermove
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Datetime} from '@supermove/utils';

const FinalizePaymentMutation = ({
  jobId,
  paymentId,
  paymentMethodToken, // Only required for PayEngine payments
  onSuccess,
  onError,
  children,
}) => (
  <Mutation
    variables={{
      jobId,
      paymentId,
      timestamp: Datetime.toTimestamp(Datetime.now),
      paymentMethodToken,
    }}
    mutation={FinalizePaymentMutation.mutation}
    onCompleted={({response: {error, errors, payment}}) => {
      if (!error && !errors) {
        onSuccess(payment);
      } else {
        onError({error, errors});
      }
    }}
    onError={(apolloError) => {
      onError({apolloError});
    }}
  >
    {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
FinalizePaymentMutation.mutation = gql`
  mutation FinalizePaymentMutation(
    $jobId: Int,
    $paymentId: Int!,
    $paymentMethodToken: String,
    $timestamp: String!,
  ) {
    response: finalizePayment(
      jobId: $jobId,
      paymentId: $paymentId,
      paymentMethodToken: $paymentMethodToken,
      timestamp: $timestamp,
    ) {
      ${gql.errors}
      payment {
        id
        bill {
          id
          total
          customer {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export default FinalizePaymentMutation;
