// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, Popover} from '@supermove/components';
import {FilteredProjectsForm, FilteredProjectsFormType} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {Form, useNavigationDOM, useDebouncedCallback, usePopover} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import Tabs from '@shared/design/components/Tabs';
import ProjectStatus from '@shared/modules/Project/enums/ProjectStatus';
import ListProjectsPageFiltersPopover from 'modules/Project/List/components/ListProjectsPageFiltersPopover';

const FiltersContainers = Styled.View`
  flex-direction: row;
  padding-horizontal: 16px;
`;

type FormType = Form<{filteredProjectsForm: FilteredProjectsFormType}>;

type FilteredProjectCountsByStatus = {
  leadsCount: number;
  holdsCount: number;
  bookedCount: number;
  completedCount: number;
  cancelledCount: number;
};

const getTabDefinitions = ({
  leadsCount,
  holdsCount,
  bookedCount,
  completedCount,
  cancelledCount,
}: FilteredProjectCountsByStatus) => [
  {
    key: _.lowerCase(ProjectStatus.LEAD),
    label: 'Leads',
    count: leadsCount,
    status: ProjectStatus.getUrlFilter(ProjectStatus.LEAD),
  },
  {
    key: _.lowerCase(ProjectStatus.HOLD),
    label: 'Holds',
    count: holdsCount,
    status: ProjectStatus.getUrlFilter(ProjectStatus.HOLD),
  },
  {
    key: _.lowerCase(ProjectStatus.BOOKED),
    label: 'Booked',
    count: bookedCount,
    status: ProjectStatus.getUrlFilter(ProjectStatus.BOOKED),
  },
  {
    key: _.lowerCase(ProjectStatus.COMPLETED),
    label: 'Completed',
    count: completedCount,
    status: ProjectStatus.getUrlFilter(ProjectStatus.COMPLETED),
  },
  {
    key: _.lowerCase(ProjectStatus.CANCELLED),
    label: 'Cancelled',
    count: cancelledCount,
    status: `${_.lowerCase(ProjectStatus.CANCELLED)}`,
  },
];

const TabHeader = ({
  activeTabKey,
  filteredProjectCountsByStatus,
  form,
  isEnabledProjectHoldStatus,
  isEnabledSalesStatusDepositReceived,
}: {
  activeTabKey: string;
  filteredProjectCountsByStatus: FilteredProjectCountsByStatus;
  form: FormType;
  isEnabledProjectHoldStatus: boolean;
  isEnabledSalesStatusDepositReceived: boolean;
}) => {
  const tabDefinitions = getTabDefinitions(filteredProjectCountsByStatus);
  const activeTabIndex = _.findIndex(
    tabDefinitions,
    (definition) => definition.status === activeTabKey,
  );

  return (
    <Tabs
      tabs={tabDefinitions}
      isSpacedTabs={false}
      tabStyle={{paddingHorizontal: 16}}
      handlePressTab={({status}) => {
        form.setFieldValue('filteredProjectsForm.status', status);

        // Reset sales status filters since they are dependent on the selected status
        const defaultSalesStatuses = FilteredProjectsForm.getAvailableSalesStatuses({
          status,
          isEnabledProjectHoldStatus,
          isEnabledSalesStatusDepositReceived,
        });
        form.setFieldValue('filteredProjectsForm.salesStatuses', defaultSalesStatuses);
      }}
      activeTabIndex={activeTabIndex}
    />
  );
};

const ProjectsFilterButton = ({
  form,
  organization,
  isRestricted,
  viewerId,
}: {
  form: FormType;
  organization: OrganizationModel;
  isRestricted: boolean;
  viewerId?: string;
}) => {
  const listProjectsPageFiltersPopover = usePopover({name: 'List Projects Page Filters Popover'});
  // Determine number of filters applied
  const numberOfFiltersApplied = FilteredProjectsForm.getNumberOfFiltersApplied({
    form,
    isPrimary: organization.isPrimary,
    isRestricted,
  });

  return (
    <React.Fragment>
      <Popover.Content innerRef={listProjectsPageFiltersPopover.ref}>
        <SecondaryButton
          text={`(${numberOfFiltersApplied})`}
          iconLeft={Icon.Filter}
          onPress={listProjectsPageFiltersPopover.handleToggle}
          isResponsive
        />
      </Popover.Content>
      <ListProjectsPageFiltersPopover
        activeFilterCount={numberOfFiltersApplied}
        organization={organization}
        form={form}
        popover={listProjectsPageFiltersPopover}
        isRestricted={isRestricted}
        viewerId={viewerId}
      />
    </React.Fragment>
  );
};

const ListProjectsPageSearchAndFilters = ({
  form,
  organization,
  filteredProjectCountsByStatus,
  isRestricted,
  viewerId,
}: {
  form: FormType;
  organization: OrganizationModel;
  filteredProjectCountsByStatus: FilteredProjectCountsByStatus;
  isRestricted: boolean;
  viewerId?: string;
}) => {
  const {params} = useNavigationDOM();

  const handleChangeSearch = useDebouncedCallback(
    (input) => form.setFieldValue('filteredProjectsForm.searchQuery', input),
    250,
    {leading: true},
  );

  const {isEnabledProjectHoldStatus, isEnabledSalesStatusDepositReceived} = organization.features;

  return (
    <React.Fragment>
      <FiltersContainers>
        <SearchBar
          onChangeText={handleChangeSearch}
          placeholder='Search'
          containerStyle={{flex: 1}}
          style={{flex: 1}}
          defaultValue={_.get(form.values, 'filteredProjectsForm.searchQuery')}
          isClearable
          isResponsive
        />
        <Space width={16} />
        <ProjectsFilterButton
          organization={organization}
          form={form}
          isRestricted={isRestricted}
          viewerId={viewerId}
        />
      </FiltersContainers>
      <Space height={16} />
      <TabHeader
        isEnabledProjectHoldStatus={isEnabledProjectHoldStatus}
        isEnabledSalesStatusDepositReceived={isEnabledSalesStatusDepositReceived}
        activeTabKey={params.status}
        filteredProjectCountsByStatus={filteredProjectCountsByStatus}
        form={form}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListProjectsPageSearchAndFilters.fragment = gql`
  ${ListProjectsPageFiltersPopover.fragment}

  fragment ListProjectsPageSearchAndFilters on Organization {
    id
    isPrimary
    features {
      isEnabledProjectHoldStatus: isEnabled(feature: "PROJECT_HOLD_STATUS")
      isEnabledSalesStatusDepositReceived: isEnabled(feature: "SALES_STATUS_DEPOSIT_RECEIVED")
    }
    ...ListProjectsPageFiltersPopover
  }
`;

ListProjectsPageSearchAndFilters.countsFragment = gql`
  fragment ListProjectsPageSearchAndFilters_countsFragment on ProjectCountsByStatus {
    leadsCount
    holdsCount
    bookedCount
    completedCount
    cancelledCount
  }
`;

export default ListProjectsPageSearchAndFilters;
