// Libraries
import _ from 'lodash';
import React from 'react';

// App
import Checkbox from '@shared/design/components/Checkbox';
import {SetDocumentContentJsonType} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';

const SurveyDocumentCheckbox = ({
  label,
  isChecked,
  property,
  documentItemField,
  setDraftDocumentContentJson,
}: {
  label: string;
  isChecked: boolean;
  property: string;
  documentItemField: string;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
}) => {
  return (
    <Checkbox
      label={label}
      isChecked={isChecked}
      handleToggle={(value) => {
        setDraftDocumentContentJson((prevState) => {
          _.set(prevState, `${documentItemField}.input.${property}`, value);
          return {...prevState};
        });
      }}
    />
  );
};

export default SurveyDocumentCheckbox;
