// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ConfirmationStepsForm from '@shared/modules/Proposal/forms/ConfirmationStepsForm';

const useUpdateConfirmationStepsMutation = ({confirmationStepsForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      confirmationStepsForm: ConfirmationStepsForm.toForm(confirmationStepsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateConfirmationStepsMutation.mutation,
    variables: {
      confirmationStepsForm: ConfirmationStepsForm.toMutation(form.values.confirmationStepsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateConfirmationStepsMutation.mutation = gql`
  mutation useUpdateConfirmationStepsMutation($confirmationStepsForm: ConfirmationStepsForm!) {
    response: updateConfirmationSteps(confirmationStepsForm: $confirmationStepsForm) {
      ${gql.errors}
      confirmationSteps {
        id
      }
    }
  }
`;

export default useUpdateConfirmationStepsMutation;
