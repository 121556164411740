// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import ContactForm from '@shared/modules/User/forms/ContactForm';

const _contactNamesToClientName = (names: any) => {
  return names
    .map((name: any) => name.trim())
    .join(' ')
    .trim();
};

const _new = ({organizationId, isOkayDuplicatePhoneNumber, salespersonId = null}: any) => ({
  clientId: null,
  coordinatorId: null,
  organizationId,
  salespersonId,
  name: '',
  referralDetails: '',
  referralSource: '',
  billingLocationForm: LocationForm.new(),
  primaryContactForm: ContactForm.new({organizationId}),
  primaryLocationForm: LocationForm.new(),
  notes: '',
  isOkayDuplicatePhoneNumber,

  // Private
  clientUuid: '',
  isShowingName: false,
  isSameAddress: true,
  warningMessage: '',
  isDuplicatePhoneNumber: false,
  externalIntegrationCustomerId: null,
  externalIntegrationSearchQuery: '',
});

const newFromJobRequest = withFragment(
  (jobRequest, {viewerId}) => ({
    clientId: '',
    organizationId: (jobRequest as any).organizationId,
    salespersonId:
      (jobRequest as any).bookedById ||
      ((jobRequest as any).organization.features.isEnabledNoAutoAssignBookedByToViewer
        ? undefined
        : viewerId),
    name: '',
    referralDetails: (jobRequest as any).referralDetails ? (jobRequest as any).referralDetails : '',
    referralSource: (jobRequest as any).referralSource ? (jobRequest as any).referralSource : '',
    billingLocationForm: LocationForm.new(),
    primaryContactForm: ContactForm.edit((jobRequest as any).customer),
    primaryLocationForm: LocationForm.new(),
    notes: '',
    isOkayDuplicatePhoneNumber: true,
    // Private
    clientUuid: '',
    isShowingName: false,
    isSameAddress: true,
    warningMessage: '',
    isDuplicatePhoneNumber: false,
    externalIntegrationCustomerId: null,
    externalIntegrationSearchQuery: '',
  }),
  gql`
    ${ContactForm.edit.fragment}

    fragment ClientForm_newFromJobRequest on JobRequest {
      id
      bookedById
      organizationId
      organization {
        id
        features {
          isEnabledNoAutoAssignBookedByToViewer: isEnabled(
            feature: "NO_AUTO_ASSIGN_BOOKED_BY_TO_VIEWER"
          )
        }
      }
      referralDetails
      referralSource
      customer {
        id
        ...ContactForm_edit
      }
    }
  `,
);

const newFromProject = withFragment(
  (project) => {
    const job = _.get(project, 'activeJobs.0');
    return {
      clientId: null,
      organizationId: (project as any).organizationId,
      salespersonId: (project as any).bookedById,
      name: job ? job.name : (project as any).customer.fullName,
      referralDetails: (project as any).referralDetails,
      referralSource: (project as any).referralSource,
      billingLocationForm: LocationForm.new(),
      primaryContactForm: ContactForm.edit((project as any).customer),
      primaryLocationForm: LocationForm.new(),
      notes: '',
      isOkayDuplicatePhoneNumber: true,
      // Private
      clientUuid: '',
      isShowingName: job ? job.name !== (project as any).customer.fullName : false,
      isSameAddress: true,
      isDuplicatePhoneNumber: false,
      externalIntegrationCustomerId: null,
      externalIntegrationSearchQuery: '',
    };
  },
  gql`
    ${ContactForm.edit.fragment}

    fragment ClientForm_newFromProject on Project {
      id
      bookedById
      organizationId
      referralDetails
      referralSource
      activeJobs {
        id
        name
      }
      customer {
        id
        fullName
        ...ContactForm_edit
      }
    }
  `,
);

const edit = withFragment(
  (client, isLeadForm = false) => ({
    clientId: (client as any).id,
    coordinatorId: (client as any).coordinatorId,
    organizationId: (client as any).organizationId,
    salespersonId: (client as any).salespersonId,
    name: (client as any).name,
    referralDetails: (client as any).referralDetails ? (client as any).referralDetails : '',
    referralSource: (client as any).referralSource ? (client as any).referralSource : '',
    billingLocationForm: (client as any).billingLocation
      ? LocationForm.edit((client as any).billingLocation)
      : LocationForm.new({clientId: (client as any).id}),
    primaryContactForm: ContactForm.edit((client as any).primaryContact),
    primaryLocationForm: (client as any).primaryLocation
      ? LocationForm.edit((client as any).primaryLocation)
      : LocationForm.new({clientId: (client as any).id}),
    notes: (client as any).notes ? (client as any).notes : '',
    isOkayDuplicatePhoneNumber: true,
    // Private
    clientUuid: (client as any).uuid,
    isShowingName: isLeadForm || (client as any).name !== (client as any).primaryContact.fullName,
    isSameAddress: !(client as any).billingLocation,
    warningMessage: '',
    isDuplicatePhoneNumber: false,
    externalIntegrationCustomerId: (client as any).latestExternalIntegrationCustomer?.id,
    externalIntegrationSearchQuery: (client as any).latestExternalIntegrationCustomer?.name,
  }),
  gql`
    ${ContactForm.edit.fragment}
    ${LocationForm.edit.fragment}

    fragment ClientForm_edit on Client {
      id
      uuid
      coordinatorId
      organizationId
      salespersonId
      name
      referralDetails
      referralSource
      notes
      billingLocation {
        id
        ...LocationForm_edit
      }
      organization {
        id
        features {
          isEnabledNoAutoAssignBookedByToViewer: isEnabled(
            feature: "NO_AUTO_ASSIGN_BOOKED_BY_TO_VIEWER"
          )
        }
      }
      primaryLocation {
        id
        ...LocationForm_edit
      }
      primaryContact {
        id
        fullName
        ...ContactForm_edit
      }
      latestExternalIntegrationCustomer {
        id
        name
      }
    }
  `,
);

const toForm = ({
  clientId,
  coordinatorId,
  organizationId,
  salespersonId,
  name,
  referralDetails,
  referralSource,
  billingLocationForm,
  primaryContactForm,
  primaryLocationForm,
  notes,
  isOkayDuplicatePhoneNumber,
  clientUuid,
  isShowingName,
  isSameAddress,
  warningMessage,
  isDuplicatePhoneNumber,
  externalIntegrationCustomerId,
  externalIntegrationSearchQuery,
}: any) => ({
  clientId,
  coordinatorId,
  organizationId,
  salespersonId,
  name,
  referralDetails,
  referralSource,
  billingLocationForm: LocationForm.toForm(billingLocationForm),
  primaryContactForm: ContactForm.toForm(primaryContactForm),
  primaryLocationForm: LocationForm.toForm(primaryLocationForm),
  notes,
  isOkayDuplicatePhoneNumber,

  // Private
  clientUuid,
  isShowingName,
  isSameAddress,
  warningMessage,
  isDuplicatePhoneNumber,
  externalIntegrationCustomerId: externalIntegrationCustomerId
    ? _.toNumber(externalIntegrationCustomerId)
    : null,
  externalIntegrationSearchQuery,
});

const toMutation = ({
  clientId,
  coordinatorId,
  organizationId,
  salespersonId,
  name,
  referralDetails,
  referralSource,
  billingLocationForm,
  primaryContactForm,
  primaryLocationForm,
  notes,
  isOkayDuplicatePhoneNumber,
  isShowingName,
  externalIntegrationCustomerId,
}: any) => ({
  clientId: clientId || null,
  coordinatorId: coordinatorId === '' ? null : coordinatorId,
  organizationId,
  salespersonId: salespersonId === '' ? null : salespersonId,
  name: isShowingName ? name : _contactNamesToClientName(primaryContactForm.names),
  referralDetails,
  referralSource,
  billingLocationForm: LocationForm.toMutation(billingLocationForm),
  primaryContactForm: ContactForm.toMutation(primaryContactForm),
  primaryLocationForm: LocationForm.toMutation(primaryLocationForm),
  notes,
  isOkayDuplicatePhoneNumber,
  externalIntegrationCustomerId,
});

const ClientForm = {
  new: _new,
  newFromJobRequest,
  newFromProject,
  edit,
  toForm,
  toMutation,
};

export default ClientForm;
