// Libraries
import _ from 'lodash';

import {Currency} from '@supermove/utils';

// Confirmations
const CONFIRMATION_STEP_DOCUMENT = 'CONFIRMATION_STEP_DOCUMENT';
const CONFIRMATION_STEP_MAKE_DEPOSIT = 'CONFIRMATION_STEP_MAKE_DEPOSIT';
const CONFIRMATION_STEP_SAVE_CREDIT_CARD = 'CONFIRMATION_STEP_SAVE_CREDIT_CARD';

// Confirmations V1
const CONFIRMATION_STEP_ACCEPT_QUOTE = 'CONFIRMATION_STEP_ACCEPT_QUOTE';
const CONFIRMATION_STEP_SIGN_DOCUMENT = 'CONFIRMATION_STEP_SIGN_DOCUMENT';
const CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE = 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE';
const CONFIRMATION_STEP_SIGN_INVENTORY = 'CONFIRMATION_STEP_SIGN_INVENTORY';

// Quotes
const QUOTE_STEP_DOCUMENT = 'QUOTE_STEP_DOCUMENT';

// Quotes V1
const QUOTE_STEP_ACCEPT_QUOTE = 'QUOTE_STEP_ACCEPT_QUOTE';
const QUOTE_STEP_ACCEPT_INVENTORY = 'QUOTE_STEP_ACCEPT_INVENTORY';

const CONFIRMATION_STEPS = [
  CONFIRMATION_STEP_DOCUMENT,
  CONFIRMATION_STEP_MAKE_DEPOSIT,
  CONFIRMATION_STEP_SAVE_CREDIT_CARD,
];
const QUOTE_STEPS = [QUOTE_STEP_DOCUMENT];

const getLabel = (confirmationStepKind: any) => {
  switch (confirmationStepKind) {
    case CONFIRMATION_STEP_SAVE_CREDIT_CARD:
      return 'Cancellation';
    case CONFIRMATION_STEP_DOCUMENT:
    case QUOTE_STEP_DOCUMENT:
    case CONFIRMATION_STEP_ACCEPT_QUOTE:
    case CONFIRMATION_STEP_SIGN_DOCUMENT:
    case CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE:
    case CONFIRMATION_STEP_SIGN_INVENTORY:
    case QUOTE_STEP_ACCEPT_QUOTE:
    case QUOTE_STEP_ACCEPT_INVENTORY:
      return 'Document';
    case CONFIRMATION_STEP_MAKE_DEPOSIT:
      return 'Deposit';
    default:
      return confirmationStepKind;
  }
};

const getDetails = (confirmationStepKind: any, {value, valueTwo}: any) => {
  switch (confirmationStepKind) {
    case CONFIRMATION_STEP_SAVE_CREDIT_CARD:
      return `${Currency.display(value)}, ${valueTwo}`;
    case CONFIRMATION_STEP_DOCUMENT:
    case QUOTE_STEP_DOCUMENT:
      return value;
    case CONFIRMATION_STEP_SIGN_INVENTORY:
    case QUOTE_STEP_ACCEPT_INVENTORY:
      return 'Inventory';
    case CONFIRMATION_STEP_ACCEPT_QUOTE:
    case QUOTE_STEP_ACCEPT_QUOTE:
      return 'Quote';
    case CONFIRMATION_STEP_SIGN_DOCUMENT:
      return 'Confirmation';
    case CONFIRMATION_STEP_MAKE_DEPOSIT:
      return Currency.display(value);
    case CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE:
    default:
      return '';
  }
};

const getDropdownOptions = ({kinds}: any) => {
  return _.map(
    _.orderBy(kinds, (kind) => getLabel(kind).toLowerCase(), ['asc']),
    (kind) => {
      return {
        label: getLabel(kind),
        value: kind,
      };
    },
  );
};

const ConfirmationStepKind = {
  CONFIRMATION_STEP_DOCUMENT,
  CONFIRMATION_STEP_MAKE_DEPOSIT,
  CONFIRMATION_STEP_SAVE_CREDIT_CARD,
  CONFIRMATION_STEP_ACCEPT_QUOTE,
  CONFIRMATION_STEP_SIGN_DOCUMENT,
  CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE,
  CONFIRMATION_STEP_SIGN_INVENTORY,
  QUOTE_STEP_DOCUMENT,
  QUOTE_STEP_ACCEPT_QUOTE,
  QUOTE_STEP_ACCEPT_INVENTORY,

  CONFIRMATION_STEPS,
  DOCUMENT_V2_STEPS: [CONFIRMATION_STEP_DOCUMENT, QUOTE_STEP_DOCUMENT],
  QUOTE_STEPS,

  getLabel,
  getDropdownOptions,
  getDetails,
};

export default ConfirmationStepKind;
