// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import FormulaForm from '@shared/modules/Billing/forms/FormulaForm';

const useUpdateFormulaMutation = ({formulaForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      formulaForm: FormulaForm.toForm(formulaForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateFormulaMutation.mutation,
    variables: {
      formulaForm: FormulaForm.toMutation(form.values.formulaForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateFormulaMutation.mutation = gql`
  mutation useUpdateFormulaMutation($formulaForm: FormulaForm!) {
    response: updateFormula(formulaForm: $formulaForm) {
      ${gql.errors}
      formula {
        id
      }
    }
  }
`;

export default useUpdateFormulaMutation;
