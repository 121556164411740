/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer} from '@supermove/hooks';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import CustomerCommunicationDrawer from 'modules/Communication/components/CustomerCommunicationDrawer';

const ClientChatButton = ({client, handleMissingPhoneNumber, projectId, noText, isLarge}) => {
  const customerCommunicationDrawer = useDrawer({name: 'Customer Communication Drawer'});

  return (
    <React.Fragment>
      <TertiaryButton
        iconLeft={Icon.CommentDots}
        iconSize={isLarge ? 20 : 14}
        text={!noText && 'Contact'}
        onPress={customerCommunicationDrawer.handleOpen}
      />
      <CustomerCommunicationDrawer
        isOpen={customerCommunicationDrawer.isOpen}
        handleClose={customerCommunicationDrawer.handleClose}
        client={client}
        projectId={projectId}
        handleMissingContactInfo={handleMissingPhoneNumber}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientChatButton.fragment = gql`
  ${CustomerCommunicationDrawer.fragment}

  fragment ClientChatButton on Client {
    id
    ...CustomerCommunicationDrawer
  }
`;

export default ClientChatButton;
