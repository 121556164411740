// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, useNavigationDOM, usePopover, useSidebar} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Line from 'modules/App/components/Line';
import JobDispatchNotesPopover from 'modules/Dispatch/Calendar/Day/components/JobDispatchNotesPopover';
import JobDispatchPopover from 'modules/Dispatch/Calendar/Day/components/JobDispatchPopover';
import JobArrivalTime from 'modules/Job/JobCard/components/JobArrivalTime';
import JobCrewCounts from 'modules/Job/JobCard/components/JobCrewCounts';
import JobDispatchNotes from 'modules/Job/JobCard/components/JobDispatchNotes';
import JobDispatchStatus from 'modules/Job/JobCard/components/JobDispatchStatus';
import JobDistance from 'modules/Job/JobCard/components/JobDistance';
import JobEstimatedRangeBadge from 'modules/Job/JobCard/components/JobEstimatedRangeBadge';
import JobInventoryWeight from 'modules/Job/JobCard/components/JobInventoryWeight';
import JobLocations from 'modules/Job/JobCard/components/JobLocations';
import JobNameBadge from 'modules/Job/JobCard/components/JobNameBadge';
import JobProjectComments from 'modules/Job/JobCard/components/JobProjectComments';
import ProjectGrandTotal from 'modules/Job/JobCard/components/ProjectGrandTotal';
import ProjectSalesStatus from 'modules/Job/JobCard/components/ProjectSalesStatus';
import JobJobUsersScheduleModal from 'modules/Job/V2/Move/components/JobJobUsersScheduleModal';
import JobCancelModal from 'modules/Job/components/JobCancelModal';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Container = Styled.View`
  background-color: ${colors.white};
  flex-direction: row;
  border-radius: 4px;
  margin-bottom: 8px;
  overflow: hidden;
`;

const ProjectTypeColorLeftBorder = Styled.View`
  width: 3px;
  background-color: ${({color}) => color};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const JobInfoContainerButton = Styled.ButtonV2`
  flex: 1;
  background-color: ${({color}) => colors.alpha(color, 0.03)}
  border-color: ${({color}) => color};
  border-width: ${({borderWidth}) => borderWidth}px;
  border-left-width: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-style: dashed;
`;

const JobInfoInnerContainer = Styled.View`
  padding-horizontal: 8px;
`;

const CustomerNameText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.primary};
`;

const JobCardExpansionContainer = Styled.View`
  position: absolute;
  top: 0px;
  left: -30px;
  width: 30px;
  height: 42px;
  flex: 1;
`;

const GoToJobIconButton = Styled.ButtonV2`
  height: 26px;
  width: 26px;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  background-color: ${({isVisible}) =>
    isVisible ? colors.alpha(colors.gray.primary, 0.9) : 'transparent'}
`;

const BookingCalendarJobItem = ({job, date, refetch}) => {
  const jobDispatchPopover = usePopover();
  const jobDispatchNotesPopover = usePopover();
  const jobJobUsersScheduleModal = useModal();
  const jobCancelModal = useModal();
  const jobCardHover = useHover();
  const jobCardExpansionHover = useHover();
  const projectCommentsSidebar = useSidebar({
    name: 'ProjectCommentsSidebar',
    enableTracking: true,
  });
  const {navigator} = useNavigationDOM();
  const goToJobPage = () => navigator.push(`/jobs/${job.uuid}`);
  const showActions = jobCardHover.isHovered || jobCardExpansionHover.isHovered;
  const isReady = job.bookingPrimaryStatus === 'READY';
  const projectTypeColor = job.project.projectType.color;

  return (
    <Popover.Content innerRef={jobDispatchPopover.ref}>
      <Container ref={jobCardHover.ref}>
        <ProjectTypeColorLeftBorder color={projectTypeColor} />
        <JobInfoContainerButton
          color={projectTypeColor}
          borderWidth={isReady ? 0 : 1}
          onPress={() => jobDispatchPopover.handleToggle()}
        >
          <JobInfoInnerContainer>
            <Space height={8} />
            <Row>
              <ProjectSalesStatus project={job.project} />
              <Space style={{flex: 1}} />
              <JobDispatchStatus job={job} />
            </Row>
            <Space height={4} />
            <Row>
              {Job.hasEstimatedRangeJobCounter(job) && (
                <React.Fragment>
                  <JobEstimatedRangeBadge job={job} date={date} />
                  <Space width={4} />
                </React.Fragment>
              )}
              <JobNameBadge job={job} style={{flexShrink: 1}} />
            </Row>
            <Space height={2} />
            <CustomerNameText>{job.project.client.name}</CustomerNameText>
            <Space height={8} />
            <JobCrewCounts
              job={job}
              crewUsersCount={Job.getDispatchJobUsersCount(job)}
              showTruckCountInline
            />
            <Space height={4} />
            <JobArrivalTime job={job} showIcon />
            <Space height={2} />
            <Row>
              <JobInventoryWeight job={job} />
              <Space width={8} />
              <ProjectGrandTotal project={job.project} />
            </Row>
            <Space height={2} />
            <JobDistance job={job} />
            <Space height={2} />
            <JobLocations job={job} />
            <Space height={2} />
            <JobDispatchNotes
              job={job}
              numberOfLines={2}
              refetch={refetch}
              popover={jobDispatchNotesPopover}
            />
            <Space height={8} />
          </JobInfoInnerContainer>
          <Line />
          <JobInfoInnerContainer>
            <Space height={8} />
            <JobProjectComments job={job} sidebar={projectCommentsSidebar} />
            <Space height={8} />
          </JobInfoInnerContainer>
        </JobInfoContainerButton>
        <JobCardExpansionContainer ref={jobCardExpansionHover.ref}>
          <GoToJobIconButton isVisible={showActions} onPress={goToJobPage}>
            <Icon
              source={Icon.ExternalLinkAlt}
              size={13}
              color={showActions ? colors.white : 'transparent'}
            />
          </GoToJobIconButton>
        </JobCardExpansionContainer>
        <JobDispatchPopover
          key={jobDispatchPopover.isOpen}
          job={job}
          organizationId={job.organizationId}
          isOpen={jobDispatchPopover.isOpen}
          handleOpen={jobDispatchPopover.handleOpen}
          handleClose={jobDispatchPopover.handleClose}
          popoverRef={jobDispatchPopover.ref}
          jobJobUsersScheduleModal={jobJobUsersScheduleModal}
          jobCancelModal={jobCancelModal}
          refetch={refetch}
        />
        <JobDispatchNotesPopover
          job={job}
          popover={jobDispatchNotesPopover}
          placement={Popover.Positions.Left}
          refetch={refetch}
        />
        <JobJobUsersScheduleModal
          jobUuid={job.uuid}
          isOpen={jobJobUsersScheduleModal.isOpen}
          handleClose={() => {
            jobJobUsersScheduleModal.handleClose();
            refetch();
          }}
        />
        <JobCancelModal
          jobId={job.id}
          isOpen={jobCancelModal.isOpen}
          handleClose={() => {
            jobCancelModal.handleClose();
            refetch();
          }}
        />
      </Container>
    </Popover.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BookingCalendarJobItem.fragment = gql`
  ${Job.getDispatchJobUsersCount.fragment}
  ${Job.hasEstimatedRangeJobCounter.fragment}
  ${JobArrivalTime.fragment}
  ${JobCrewCounts.fragment}
  ${JobEstimatedRangeBadge.fragment}
  ${JobDispatchNotes.fragment}
  ${JobDispatchNotesPopover.fragment}
  ${JobDispatchPopover.fragment}
  ${JobDispatchStatus.fragment}
  ${JobDistance.fragment}
  ${JobInventoryWeight.fragment}
  ${JobLocations.fragment}
  ${JobNameBadge.fragment}
  ${JobProjectComments.fragment}
  ${ProjectGrandTotal.fragment}
  ${ProjectSalesStatus.fragment}

  fragment BookingCalendarJobItem on Job {
    id
    uuid
    organizationId
    bookingPrimaryStatus
    project {
      id
      projectType {
        id
        color
      }
      client {
        id
        name
      }
      ...ProjectGrandTotal
      ...ProjectSalesStatus
    }
    ...Job_getDispatchJobUsersCount
    ...Job_hasEstimatedRangeJobCounter
    ...JobArrivalTime
    ...JobCrewCounts
    ...JobEstimatedRangeBadge
    ...JobDispatchNotes
    ...JobDispatchNotesPopover
    ...JobDispatchPopover
    ...JobDispatchStatus
    ...JobDistance
    ...JobInventoryWeight
    ...JobLocations
    ...JobNameBadge
    ...JobProjectComments
  }
`;

export default BookingCalendarJobItem;
