// Supermove
import {colors} from '@supermove/styles';

const TruckAndMoverAvailabilityStatusColors = {
  HIGH_AVAILABILITY: colors.green.status,
  MEDIUM_AVAILABILITY: colors.orange.status,
  LOW_AVAILABILITY: colors.red.warning,
};

export default TruckAndMoverAvailabilityStatusColors;
