// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import ProjectTypeVariableSettings from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeVariableSettings';

const ProjectTypeVariableSettingsContent = ({projectTypeUuid}) => {
  const {loading, data, refetch} = useQuery(ProjectTypeVariableSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return <ProjectTypeVariableSettings projectType={data.projectTypeByUuid} refetch={refetch} />;
};

const ProjectTypeVariableSettingsPage = () => {
  const {params} = useNavigationDOM();

  return (
    <SidebarPageV2 selected={'settings'}>
      <ProjectTypeVariableSettingsContent projectTypeUuid={params.projectTypeUuid} />
    </SidebarPageV2>
  );
};

ProjectTypeVariableSettingsPage.query = gql`
  ${ProjectTypeVariableSettings.fragment}

  query ProjectTypeVariableSettingsPage(
    $projectTypeUuid: String!,
  ) {
    ${gql.query}
    projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      ...ProjectTypeVariableSettings
    }
  }
`;

export default ProjectTypeVariableSettingsPage;
