// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Emoji, Icon, Lifecycle, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useRef} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import CustomerSignDocumentSignaturesList from 'modules/Customer/Document/components/CustomerSignDocumentSignaturesList';
import CreateSignatureForDocumentStepForm from 'modules/Customer/Project/Confirmation/Document/Show/components/CreateSignatureForDocumentStepForm';
import DocumentNotPreparedErrorModal from 'modules/Customer/Project/Confirmation/Document/Show/components/DocumentNotPreparedErrorModal';
import ConfirmationStepHeader from 'modules/Customer/Project/Confirmation/components/ConfirmationStepHeader';
import ProjectPage from 'modules/Customer/Project/components/ProjectPage';
import DocumentHeaderOrganization from 'modules/Document/components/DocumentHeaderOrganization';
import PreviewDocument from 'modules/Document/components/PreviewDocument';
import SignatureField from 'modules/Document/components/SignatureField';
import ProjectConfirmationDocument from 'modules/Project/Confirmation/components/ProjectConfirmationDocument';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  flex: 1;
  width: ${(props) => (props.mobile ? '100%' : '796px')};
  padding-horizontal: ${(props) => (props.mobile ? 20 : 0)}px;
`;

const Content = Styled.View`
  align-self: stretch;
  padding-top: 60px;
  padding-bottom: 90px;
`;

const Section = Styled.View`
`;

const SectionSpace = Styled.View`
  margin-top: 30px;
`;

const Description = Styled.H6`
  margin-bottom: 10px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const Name = Styled.H6`
  ${fontWeight(700)}
`;

const Button = Styled.StatefulButton`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Title = Styled.H3`
  ${fontWeight(900)}
  color: ${colors.gray.primary};
`;

const getSubtitleText = ({responsive}) => {
  if (responsive.mobile) {
    return (
      'Please read through the document below. Afterwards, ' +
      'sign at the bottom and press "Continue".'
    );
  } else {
    return (
      'Please read through the document below.\nAfterwards, ' +
      'sign at the bottom and press "Continue".\nYou will be able to ' +
      'print a copy of the signed document on the next page.'
    );
  }
};

const ButtonContent = ({form}) => {
  if (form.loading) {
    return null;
  }

  if (form.values.isFinished) {
    return <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.Check} />;
  }

  return <Text>Continue</Text>;
};

const isCorrectPage = ({project}) => {
  const kind = _.get(project, 'confirmation.nextStep.kind');
  return kind === ConfirmationStepKind.CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE;
};

const onMountOrUpdate = ({navigator, project}) => {
  if (!isCorrectPage({project})) {
    navigator.push(`/0/${project.organization.slug}/projects/${project.uuid}/confirmation`);
  }
};

// If the next step of the confirmation is not to sign document,
// redirect the customer to the show confirmation page.
const Redirect = ({children, navigator, project}) => (
  <Lifecycle onMount={() => onMountOrUpdate({navigator, project})}>
    {isCorrectPage({project}) ? children : null}
  </Lifecycle>
);

const CancelledConfirmationSection = () => {
  return (
    <Container>
      <Section
        sectionIndex={0}
        style={{
          marginBottom: 20,
        }}
      >
        <Row>
          <Section>
            <Emoji component={EmojiText} name={'pencil'} />
            <Name>Document</Name>
          </Section>
          <Space height={10} />
          <Section>
            <Title>This confirmation has expired</Title>
          </Section>
        </Row>
      </Section>
    </Container>
  );
};

const ShowDocumentConfirmationProjectPageContent = ({data, responsive, navigator, refetch}) => {
  const documentNotPreparedErrorModal = useModal();
  const signatureRef = useRef();
  const handleNavigate = () => {
    navigator.push(
      `/0/${data.project.organization.slug}/projects/${data.project.uuid}/confirmation`,
    );
  };

  const {filteredDocumentSignatures} = data.document;
  const notPreparedDocumentSignatures = _.filter(
    filteredDocumentSignatures,
    (documentSignature) => !documentSignature.isPrepared,
  );

  const {isEnabledQuoteAndConfirmationShowAddressAndPhoneNumber} =
    data.project.organization.features;

  return (
    <CreateSignatureForDocumentStepForm
      documentId={data.document.id}
      customerId={data.project.client.primaryContact.id}
      step={'SIGN_CONFIRMATION'}
      date={Datetime.toDate(Datetime.today)}
      kind={'FULL_TRACED'}
      name={data.project.client.primaryContact.fullName}
      onSuccess={() => handleNavigate()}
      onError={(errors) => documentNotPreparedErrorModal.handleOpen()}
    >
      {(form) => (
        <Content>
          <ConfirmationStepHeader
            title={`Sign ${data.document.name}`}
            subtitle={getSubtitleText({responsive})}
            confirmation={data.project.confirmation}
          />
          <Section style={{zIndex: 100}}>
            <Row>
              <Emoji component={EmojiText} name={'pencil'} />
              <Name>{data.document.name}</Name>
            </Row>
            <PreviewDocument
              canZoom={false}
              canFullscreen={false}
              title={data.document.name}
              style={{marginTop: 10}}
            >
              <DocumentHeaderOrganization
                title={data.document.displayName}
                organization={data.document.job.project.organization}
                shouldShowBusinessAddressAndPhoneNumber={
                  isEnabledQuoteAndConfirmationShowAddressAndPhoneNumber
                }
              />
              <CustomerSignDocumentSignaturesList
                isStepSigned={data.document.isStepSigned}
                documentSignatures={filteredDocumentSignatures}
                document={data.document}
                refetch={refetch}
              />
            </PreviewDocument>
          </Section>
          <SectionSpace />
          <Section style={{zIndex: 99}}>
            <Description>By signing here, you are agreeing to all items above.</Description>
            <SignatureField
              signatureRef={signatureRef}
              onEnd={() => form.setFieldValue('isSignatureValid', true)}
              onSave={(image) => form.handleSubmit({variables: {image}})}
            />
          </Section>
          <SectionSpace />
          <Section>
            <Button
              disabled={!form.values.isSignatureValid}
              loading={form.submitting}
              onPress={() => signatureRef.current.save()}
            >
              <ButtonContent form={form} />
            </Button>
          </Section>
          <DocumentNotPreparedErrorModal
            isOpen={documentNotPreparedErrorModal.isOpen}
            handleClose={documentNotPreparedErrorModal.handleClose}
            count={notPreparedDocumentSignatures.length}
          />
        </Content>
      )}
    </CreateSignatureForDocumentStepForm>
  );
};

const ShowDocumentConfirmationProjectPage = ({
  match: {
    params: {slug, uuid, documentUuid},
  },
}) => {
  return (
    <ProjectPage variables={{uuid, documentUuid}} query={ShowDocumentConfirmationProjectPage.query}>
      {({responsive, navigator, data, refetch}) => (
        <Redirect navigator={navigator} project={data.project}>
          <Wrapper>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Container {...responsive}>
                {data.project.isCancelled ? (
                  <CancelledConfirmationSection />
                ) : (
                  <ShowDocumentConfirmationProjectPageContent
                    data={data}
                    responsive={responsive}
                    navigator={navigator}
                    refetch={refetch}
                  />
                )}
              </Container>
            </ScrollView>
          </Wrapper>
        </Redirect>
      )}
    </ProjectPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowDocumentConfirmationProjectPage.query = gql`
  ${ConfirmationStepHeader.fragment}
  ${CustomerSignDocumentSignaturesList.fragment}
  ${DocumentHeaderOrganization.fragment}
  ${ProjectConfirmationDocument.fragment}
  ${ProjectPage.fragment}

  query ShowDocumentConfirmationProjectPage($uuid: String!, $documentUuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      isCancelled
      confirmation {
        id
        nextStep {
          kind
          value
        }
        ...ConfirmationStepHeader
      }
      organization {
        id
        slug
        name
        features {
          isEnabledQuoteAndConfirmationShowAddressAndPhoneNumber: isEnabled(feature: "QUOTE_AND_CONFIRMATION_SHOW_ADDRESS_AND_PHONE_NUMBER")
        }
      }
      client {
        id
        primaryContact {
          id
          fullName
        }
      }
      ...ProjectPage
      ...ProjectConfirmationDocument
    }
    document(uuid: $documentUuid) {
      id
      name
      displayName
      job {
        id
        project {
          id
          organization {
            id
            ...DocumentHeaderOrganization
          }
        }
      }
      filteredDocumentSignatures(step: "SIGN_CONFIRMATION") {
        id
        isPrepared
        ...CustomerSignDocumentSignaturesList
      }
      ...CustomerSignDocumentSignaturesList_Document
    }
  }
`;

export default ShowDocumentConfirmationProjectPage;
