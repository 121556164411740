// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Styled, Space, ScrollView, Modal} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import Drawer from '@shared/design/components/Drawer';
import FileViewer from '@shared/modules/File/components/FileViewer';
import ItemTagItemKind from '@shared/modules/Inventory/enums/ItemTagItemKind';
import ItemTagKind from '@shared/modules/Inventory/enums/ItemTagKind';
import FieldValue from 'modules/App/components/FieldValue';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const View = Styled.View``;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SectionText = Styled.Text`
  ${Typography.Heading2}
`;

const LotColorIndicator = Styled.View`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: ${({color}) => color};
  border-width: 1px;
  border-color: ${({borderColor, color}) => borderColor || color};
`;

const ItemTagsContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const ItemTagBadge = Styled.View`
  margin-vertical: 4px;
  padding-vertical: 4px;
  padding-horizontal: 12px;
  margin-right: 8px;
  background-color: ${colors.blue.accent}
  border-radius: 4px;
`;

const ItemTagBadgeText = Styled.Text`
  ${Typography.Label}
  color: ${colors.blue.interactive}
`;

const BodyText = Styled.Text`
  ${Typography.Body}
`;

const EmptyText = Styled.Text`
  ${Typography.Body2}
  font-style: italic;
`;

const ImageGrid = Styled.View`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const PreviewImageContainer = Styled.ButtonV2`
  height: 126px;
  background-image: ${({fileUrl}) => `url(${fileUrl})`};
  background-size: contain;
  background-position: center center;  
  background-repeat: no-repeat;
  background-origin: content-box;
`;

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = Styled.View`
  width: 100%;
  height: 100%;
  padding-horizontal: 16px;
`;

const IconButton = Styled.ButtonV2`
  position: absolute;
  top: 16px;
  right: 0;
  z-index: 1000;
`;

const ItemTagsSection = ({itemTagItems}) => {
  return (
    <ItemTagsContainer>
      {!itemTagItems.length && <EmptyText style={{color: colors.gray.tertiary}}>None</EmptyText>}
      {itemTagItems.map((itemTagItem, index) => (
        <React.Fragment key={index}>
          <ItemTagBadge>
            <ItemTagBadgeText>{itemTagItem.itemTags[0].name}</ItemTagBadgeText>
          </ItemTagBadge>
        </React.Fragment>
      ))}
    </ItemTagsContainer>
  );
};

const ExceptionsSection = ({itemTagItems}) => {
  return (
    <ItemTagsContainer>
      {!itemTagItems.length && <EmptyText style={{color: colors.gray.tertiary}}>None</EmptyText>}
      {itemTagItems.map((itemTagItem, index) => {
        const exceptionLocations = _.filter(
          itemTagItem.itemTags,
          (itemTag) => itemTag.kind === ItemTagKind.EXCEPTION_LOCATION,
        );
        const exceptionTypes = _.filter(
          itemTagItem.itemTags,
          (itemTag) => itemTag.kind === ItemTagKind.EXCEPTION_TYPE,
        );

        return (
          <Row key={index}>
            {exceptionLocations.map((exceptionLocation, index) => (
              <ItemTagBadge key={index}>
                <ItemTagBadgeText>{exceptionLocation.name}</ItemTagBadgeText>
              </ItemTagBadge>
            ))}
            {exceptionLocations.length && exceptionTypes.length ? (
              <React.Fragment>
                <View>
                  <BodyText>-</BodyText>
                </View>
                <Space width={8} />
              </React.Fragment>
            ) : null}
            {exceptionTypes.map((exceptionType, index) => (
              <ItemTagBadge key={index}>
                <ItemTagBadgeText>{exceptionType.name}</ItemTagBadgeText>
              </ItemTagBadge>
            ))}
          </Row>
        );
      })}
    </ItemTagsContainer>
  );
};

const FullScreenImageModal = ({isOpen, handleClose, attachment}) => {
  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Overlay pointerEvents={'box-none'}>
        <Wrapper>
          <View style={{flex: 1}}>
            <IconButton onPress={() => handleClose()}>
              <Icon color={colors.white} size={36} source={Icon.Times} />
            </IconButton>
            <FileViewer
              file={{
                downloadUrl: attachment.file.downloadUrl,
                filename: attachment.file.filename,
                mimetype: 'image/png',
              }}
            />
          </View>
        </Wrapper>
      </Overlay>
    </Modal.Content>
  );
};

const PhotoPreviewDisplay = ({attachment}) => {
  const fullScreenImageModal = useModal({name: 'Full Screen Image Modal'});

  return (
    <View style={{marginBottom: 8}}>
      <PreviewImageContainer
        fileUrl={attachment.file.downloadUrl}
        onPress={() => fullScreenImageModal.handleOpen()}
      />
      <FullScreenImageModal
        isOpen={fullScreenImageModal.isOpen}
        handleClose={fullScreenImageModal.handleClose}
        attachment={attachment}
      />
    </View>
  );
};

const PhotosSection = ({attachments}) => {
  return (
    <Row style={{flexWrap: 'wrap', justifyContent: 'space-between'}}>
      {!attachments.length && <EmptyText style={{color: colors.gray.tertiary}}>None</EmptyText>}
      <ImageGrid>
        {attachments.map((attachment, index) => (
          <PhotoPreviewDisplay key={index} attachment={attachment} />
        ))}
      </ImageGrid>
    </Row>
  );
};

const InventoryItemDrawerContent = ({isOpen, handleClose, loading, data}) => {
  return (
    <Drawer isOpen={isOpen} handleClose={handleClose} width={450}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          if (!data) {
            return null;
          }

          const {
            name,
            isVoid,
            volume,
            weight,
            price,
            color,
            lotNumber,
            itemNumber,
            notes,
            itemTagItems,
            attachments,
          } = data.itemByUuid;

          return (
            <React.Fragment>
              <Drawer.Header handleClose={handleClose} isClosable>
                <Row style={{flex: 1, justifyContent: 'flex-start'}}>
                  {isVoid && (
                    <>
                      <Badge
                        textColor={colors.red700}
                        backgroundColor={colors.red50}
                        label={'Void'}
                        size={Badge.SIZE.SMALL}
                      />
                      <Space width={8} />
                    </>
                  )}
                  <Drawer.HeaderText>{name}</Drawer.HeaderText>
                </Row>
              </Drawer.Header>
              <Drawer.Body style={{flex: 1, paddingHorizontal: 16}}>
                <ScrollView>
                  <SectionText>Item Details</SectionText>
                  <Space height={16} />
                  <Row style={{justifyContent: 'space-between'}}>
                    <View style={{flex: 1}}>
                      <FieldValue.Label>{'Lot'}</FieldValue.Label>
                      <Space height={4} />
                      <Row>
                        <LotColorIndicator
                          color={color}
                          borderColor={
                            _.toLower(color) === _.toLower(colors.white) && colors.gray.secondary
                          }
                        />
                        <Space width={4} />
                        <FieldValue.Value>{lotNumber}</FieldValue.Value>
                      </Row>
                    </View>
                    <FieldValue label={'Item No.'} value={itemNumber} style={{flex: 1}} />
                    <View style={{flex: 1}} />
                  </Row>
                  <Space height={16} />
                  <Row style={{justifyContent: 'space-between'}}>
                    <FieldValue
                      label={'Volume'}
                      value={`${_.round(volume, 2)} cu ft`}
                      style={{flex: 1}}
                    />
                    <FieldValue
                      label={'Weight'}
                      value={`${_.round(weight, 2)}  lbs`}
                      style={{flex: 1}}
                    />
                    <FieldValue label={'Price'} value={Currency.display(price)} style={{flex: 1}} />
                  </Row>
                  <Space height={16} />
                  <Row style={{justifyContent: 'space-between'}}>
                    <FieldValue label={'Notes'} value={notes} empty={'None'} style={{flex: 1}} />
                  </Row>

                  <Space height={32} />
                  <SectionText>Item Tags</SectionText>
                  <Space height={16} />
                  <ItemTagsSection
                    itemTagItems={_.filter(
                      itemTagItems,
                      (itemTagItem) => itemTagItem.kind === ItemTagItemKind.ITEM_TAG,
                    )}
                  />

                  <Space height={32} />
                  <SectionText>Exceptions</SectionText>
                  <Space height={16} />
                  <ExceptionsSection
                    itemTagItems={_.filter(
                      itemTagItems,
                      (itemTagItem) => itemTagItem.kind === ItemTagItemKind.EXCEPTION,
                    )}
                  />

                  <Space height={32} />
                  <SectionText>Photos</SectionText>
                  <Space height={16} />
                  <PhotosSection attachments={attachments} />
                </ScrollView>
              </Drawer.Body>
            </React.Fragment>
          );
        }}
      </Loading>
    </Drawer>
  );
};

const InventoryItemDrawer = ({isOpen, handleClose, itemUuid}) => {
  const {loading, data} = useQuery(InventoryItemDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {uuid: itemUuid},
    skip: !isOpen,
  });

  return (
    <InventoryItemDrawerContent
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      data={data}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryItemDrawer.query = gql`
  query InventoryItemDrawer($uuid: String!) {
    ${gql.query}
    itemByUuid(uuid: $uuid){
      id
      name
      isVoid
      color
      lotNumber
      itemNumber
      volume
      weight
      price
      notes
      itemTagItems {
        id
        kind
        itemTags {
          id
          name
          kind
        }
      }
      attachments {
        id
        file {
          id
          downloadUrl
          filename
        }
      }
    }
  }
`;

export default InventoryItemDrawer;
