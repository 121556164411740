const EXACT_DATE = 'EXACT_DATE';
const WEEKDAY = 'WEEKDAY';
const FIRST_AND_FIFTEENTH = 'FIRST_AND_FIFTEENTH';

export default {
  EXACT_DATE,
  WEEKDAY,
  FIRST_AND_FIFTEENTH,

  VALUES: [EXACT_DATE, WEEKDAY, FIRST_AND_FIFTEENTH],
};
