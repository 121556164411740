// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Icon, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => (props.isLast ? 0 : 5)}px;
`;

const Avatar = Styled.View`
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border-width: 1px;
  border-style: dashed;
  border-color: ${colors.gray.tertiary};
`;

const PlaceholderJobUserAvatar = ({isLast}) => (
  <Container isLast={isLast}>
    <Avatar>
      <Icon color={colors.gray.tertiary} size={Icon.Sizes.Small} source={Icon.Plus} />
    </Avatar>
  </Container>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
PlaceholderJobUserAvatar.propTypes = {
  isLast: PropTypes.bool.isRequired,
};

PlaceholderJobUserAvatar.defaultProps = {};

export default PlaceholderJobUserAvatar;
