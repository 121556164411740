// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (invoice) => ({
    invoiceId: (invoice as any).id,
  }),
  gql`
    fragment SendInvoiceToCodatForm_edit on Invoice {
      id
    }
  `,
);

const toForm = ({invoiceId}: any) => ({
  invoiceId,
});

const toMutation = ({invoiceId}: any) => ({
  invoiceId,
});

const SendInvoiceToCodatForm = {
  edit,
  toForm,
  toMutation,
};

export default SendInvoiceToCodatForm;
