// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover} from '@supermove/hooks';
import {Truck} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import Button from '@shared/design/components/Button';
import Table from '@shared/design/components/TableV2Deprecated';
import TruckStatus from '@shared/modules/Dispatch/enums/TruckStatus';
import TruckForm from '@shared/modules/Dispatch/forms/TruckForm';
import useUpdateTruckMutation from '@shared/modules/Dispatch/hooks/useUpdateTruckMutation';
import CreateTruckModal from 'modules/Organization/Settings/Dispatch/Trucks/components/CreateTruckModal';
import DeleteTruckModal from 'modules/Organization/Settings/Dispatch/Trucks/components/DeleteTruckModal';
import UpdateTruckModal from 'modules/Organization/Settings/Dispatch/Trucks/components/UpdateTruckModal';
import UpdateTruckStatusModal from 'modules/Organization/Settings/Dispatch/Trucks/components/UpdateTruckStatusModal';

const Section = Styled.View`
  margin-bottom: 20px;
`;

const SectionTitle = Styled.Text`
  ${Typography.Label1}
`;

const DescriptionText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const HeaderText = Styled.Text`
  ${Typography.Label3}
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const EditTruckButton = Styled.ButtonV2`
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const getTruckSectionColumnDefinitions = ({refetch, organization}) => [
  {
    width: 200,
    headerContent: () => {
      return <HeaderText>Name</HeaderText>;
    },
    cellContent: ({item: truck}) => {
      return <Text numberOfLines={1}>{truck.name}</Text>;
    },
  },
  {
    width: 200,
    headerContent: () => {
      return <HeaderText>Size</HeaderText>;
    },
    cellContent: ({item: truck}) => {
      return <Text numberOfLines={1}>{truck.size}</Text>;
    },
  },
  {
    width: 200,
    headerContent: () => {
      return <HeaderText>Notes</HeaderText>;
    },
    cellContent: ({item: truck}) => {
      return <Text numberOfLines={1}>{truck.notes}</Text>;
    },
  },
  {
    width: 100,
    headerContent: () => {
      return <HeaderText>Actions</HeaderText>;
    },
    cellContent: ({item: truck}) => {
      return <TruckSectionsActions truck={truck} refetch={refetch} organization={organization} />;
    },
  },
];

const getMoveTruckActions = ({truck, form, onPress, organization}) => {
  const options = [];
  if (truck.status !== TruckStatus.ACTIVE) {
    options.push({
      text: 'Move to Active',
      onPress: () => {
        form.setFieldValue('status', TruckStatus.ACTIVE);
        setTimeout(() => onPress(), 0);
      },
    });
  }
  if (truck.status !== TruckStatus.OVERFLOW) {
    options.push({
      text: 'Move to Overflow',
      onPress: () => {
        form.setFieldValue('status', TruckStatus.OVERFLOW);
        setTimeout(() => onPress(), 0);
      },
    });
  }
  if (truck.status !== TruckStatus.INACTIVE) {
    options.push({
      text: 'Move to Inactive',
      onPress: () => {
        form.setFieldValue('status', TruckStatus.INACTIVE);
        setTimeout(() => onPress(), 0);
      },
    });
  }
  return options;
};

const TruckSectionsActions = ({truck, refetch, organization}) => {
  const truckActionsPopover = usePopover();
  const deleteTruckModal = useModal({name: 'DeleteTruckModal'});
  const updateTruckModal = useModal({name: 'UpdateTruckModal'});
  const updateTruckStatusModal = useModal({name: 'Update Truck Status Modal'});
  const truckForm = TruckForm.edit(truck);
  const {form, handleSubmit} = useUpdateTruckMutation({
    truckForm,
    onSuccess: () => {
      refetch();
    },
    onError: () => {},
  });

  return (
    <React.Fragment>
      <EditTruckButton onPress={updateTruckModal.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </EditTruckButton>
      <Space width={6} />
      <UpdateTruckModal
        truck={truck}
        refetch={refetch}
        isOpen={updateTruckModal.isOpen}
        onClose={updateTruckModal.handleClose}
      />
      <Popover.Content innerRef={truckActionsPopover.ref}>
        <MoreActionsButton onPress={truckActionsPopover.handleOpen}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </MoreActionsButton>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={truckActionsPopover.isOpen}
        handleOpen={truckActionsPopover.handleOpen}
        handleClose={truckActionsPopover.handleClose}
        reference={truckActionsPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          width={148}
          actions={[
            ...getMoveTruckActions({
              form,
              onPress: updateTruckStatusModal.handleOpen,
              truck,
              organization,
            }),
            {
              text: 'Delete',
              onPress: deleteTruckModal.handleOpen,
            },
          ]}
          handleClose={truckActionsPopover.handleClose}
        />
      </Popover>
      <DeleteTruckModal
        deleteTruckModal={deleteTruckModal}
        handleClose={deleteTruckModal.handleClose}
        truck={truck}
        refetch={refetch}
      />
      <UpdateTruckStatusModal
        key={updateTruckStatusModal.key}
        isOpen={updateTruckStatusModal.isOpen}
        handleClose={updateTruckStatusModal.handleClose}
        truckName={truck.name}
        newStatus={TruckStatus.getDisplayValue(_.get(form.values, 'status'))}
        handleSubmit={handleSubmit}
      />
    </React.Fragment>
  );
};

const TruckSectionTable = ({trucks, refetch, organization}) => (
  <ScrollView horizontal>
    <Table
      columnDefinitions={getTruckSectionColumnDefinitions({
        refetch,
        organization,
      })}
      emptyStateText={'No trucks to display'}
      items={trucks}
      isDense
      itemKey={'id'}
    />
  </ScrollView>
);

const TruckSection = ({title, description, trucks, dropdownActions, refetch, organization}) => (
  <Section>
    <SectionTitle>{`${title} (${trucks.length})`}</SectionTitle>
    <Space height={8} />
    <DescriptionText>{description}</DescriptionText>
    <Space height={16} />
    <TruckSectionTable
      trucks={Truck.sort(trucks)}
      refetch={refetch}
      dropdownActions={dropdownActions}
      organization={organization}
    />
    <Space height={48} />
  </Section>
);

const TrucksContent = ({viewer, refetch}) => {
  const createTruckModal = useModal({name: 'CreateTruckModal'});
  return (
    <React.Fragment>
      <Button iconLeft={Icon.Plus} text={'Add Truck'} onPress={createTruckModal.handleOpen} />
      <Space height={24} />
      <CreateTruckModal
        refetch={refetch}
        isOpen={createTruckModal.isOpen}
        onClose={createTruckModal.handleClose}
        viewer={viewer}
      />
      <TruckSection
        title={`Active Trucks`}
        description={`Trucks you use daily which are shown in your booking calendar.`}
        refetch={refetch}
        trucks={viewer.viewingOrganization.activeTrucks}
        organization={viewer.viewingOrganization}
      />
      <TruckSection
        title={`Overflow Trucks`}
        description={`Back-up trucks that are not shown in your booking calendar but can still be assigned to jobs.`}
        refetch={refetch}
        trucks={viewer.viewingOrganization.overflowTrucks}
        organization={viewer.viewingOrganization}
      />
      <TruckSection
        title={`Inactive Trucks`}
        description={`Trucks that are not shown in your booking calendar and cannot be assigned to jobs.`}
        refetch={refetch}
        trucks={viewer.viewingOrganization.inactiveTrucks}
        organization={viewer.viewingOrganization}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TrucksContent.fragment = gql`
  ${TruckForm.edit.fragment}
  ${UpdateTruckModal.fragment}
  ${DeleteTruckModal.fragment}
  fragment TrucksContent on User {
    ${gql.query}
    id
    viewingOrganization {
      id
      activeTrucks {
        id
        name
        size
        notes
        status
        ...TruckForm_edit
        ...UpdateTruckModal
        ...DeleteTruckModal
      }
      overflowTrucks {
        id
        name
        size
        notes
        status
        ...TruckForm_edit
        ...UpdateTruckModal
        ...DeleteTruckModal
      }
      inactiveTrucks {
        id
        name
        size
        notes
        status
        ...TruckForm_edit
        ...UpdateTruckModal
        ...DeleteTruckModal
      }
    }
  }
`;

export default TrucksContent;
