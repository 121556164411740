/*
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Popover, RadioInput, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, usePopover, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import AssignTruckToSlotForm from '@shared/modules/Dispatch/forms/AssignTruckToSlotForm';
import useAssignTruckToSlotMutation from '@shared/modules/Dispatch/hooks/useAssignTruckToSlotMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const TruckRowContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  height: 36;
`;

const SlotButton = Styled.ButtonV2`
  height: 20px;
  width: 75px;
  padding-left: 6px;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.color};
  border: ${(props) => (props.truck ? `1px solid ${colors.gray.border}` : 'none')};
  border-radius: 4px;
`;

const SlotButtonText = Styled.H8`
  ${(props) => (props.truck ? fontWeight(500) : fontWeight(700))}
  color: ${(props) =>
    props.selected ? colors.white : props.truck ? colors.black : colors.gray.primary};
  overflow: ${({vars}) => (vars.isHovered ? 'visible' : 'hidden')};
`;

const ContainerHeader = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-horizontal: 16px;
`;

const Title = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.black}
`;

const ContainerFooter = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const CancelTouchable = Styled.ButtonV2`
  background-color: transparent;
  padding-vertical: 8px;
  padding-horizontal: 16px;
`;

const GrayText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.tertiary}
`;

const WhiteText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white}
`;

const CancelTimesTouchable = Styled.ButtonV2`
`;

const AssignButton = Styled.LoadingButton`
  background-color: ${colors.blue.interactive};
  padding-vertical: 8px;
  padding-horizontal: 16px;
`;

const TruckName = Styled.H7`
  color: ${(props) => (props.disabled ? colors.gray.tertiary : colors.black)}
  ${fontWeight(500)}
`;

const SlotText = Styled.H7`
  font-style: italic;
  color: ${(props) => (props.disabled ? colors.gray.tertiary : colors.black)}
`;

const Indicator = Styled.Loading`
`;

const TruckRow = ({form, truck, truckToSlotIndexMap, truckIdsWithSlots}) => {
  const slotIndex = _.get(truckToSlotIndexMap, truck.id);
  const disabled = truckIdsWithSlots.includes(truck.id);
  const isSelected = form.values.assignTruckToSlotForm.truckId === truck.id;
  return (
    <TruckRowContainer backgroundColor={isSelected ? colors.blue.interactive : null}>
      <Space width={10} />
      <RadioInput
        disabled={disabled}
        isSelected={isSelected}
        color={colors.blue.accent}
        circleColor={colors.blue.interactive}
        onSelect={() => {
          if (isSelected) {
            form.setFieldValue(`assignTruckToSlotForm.truckId`, null);
          } else {
            form.setFieldValue(`assignTruckToSlotForm.truckId`, truck.id);
          }
        }}
      >
        <Row>
          <TruckName disabled={disabled}>{truck.name}</TruckName>
          {!!truck.size && <TruckName disabled={disabled}>{`, ${truck.size}`}</TruckName>}
          {!!slotIndex && (
            <React.Fragment>
              <Space width={8} />
              <SlotText disabled={disabled}>Slot {slotIndex}</SlotText>
            </React.Fragment>
          )}
        </Row>
      </RadioInput>
    </TruckRowContainer>
  );
};

const TruckSlotPopoverMutationContent = ({
  truckIdsWithSlots,
  truckToSlotIndexMap,
  activeTrucks,
  refetch,
  slotId,
  handleClose,
  selectedTruckId,
}) => {
  const assignTruckToSlotForm = AssignTruckToSlotForm.new({slotId, truckId: selectedTruckId});
  const {form, submitting, handleSubmit} = useAssignTruckToSlotMutation({
    assignTruckToSlotForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  return (
    <React.Fragment>
      <Space height={16} />
      <ContainerHeader>
        <Title>Assign Truck</Title>
        <CancelTimesTouchable onPress={handleClose}>
          <Icon color={colors.black} size={20} source={Icon.Times} />
          <Space width={4} />
        </CancelTimesTouchable>
      </ContainerHeader>
      <Space height={12} />
      <Line />
      <ScrollView>
        {activeTrucks.map((truck) => (
          <React.Fragment key={`${truck.id}-fragment`}>
            <TruckRow
              form={form}
              truck={truck}
              truckToSlotIndexMap={truckToSlotIndexMap}
              truckIdsWithSlots={truckIdsWithSlots}
            />
            <Space height={2} />
          </React.Fragment>
        ))}
      </ScrollView>
      <Space height={12} />
      <ContainerFooter>
        <CancelTouchable onPress={handleClose}>
          <GrayText>Cancel</GrayText>
        </CancelTouchable>
        <AssignButton loading={submitting} onPress={handleSubmit}>
          <WhiteText>Assign</WhiteText>
        </AssignButton>
        <Space width={16} />
      </ContainerFooter>
      <Space height={12} />
    </React.Fragment>
  );
};

/*
 * Get a mapping of truck ids -> slot index if they exist on the slot.
 */
const getTruckToSlotIndexMap = ({slots}) => {
  const truckToSlotIndexMap = {};
  for (const slot of slots) {
    if (slot.truckId && slot.index) {
      truckToSlotIndexMap[slot.truckId] = slot.index;
    }
  }
  return truckToSlotIndexMap;
};

const TruckSlotPopoverContent = ({refetch, isOpen, slotId, handleClose, selectedTruckId}) => {
  const {data} = useQuery(TruckSlotPopoverContent.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      slotId,
    },
  });
  return (
    <Loading as={Indicator} loading={!data}>
      {() => {
        const truckToSlotIndexMap = getTruckToSlotIndexMap({slots: data.slot.day.slots});
        const truckIdsWithSlots = _.compact(
          data.slot.day.slots
            .map((slot) => slot.truckId)
            .filter((truckId) => truckId !== data.slot.truckId),
        ).map((truckId) => truckId.toString());
        const {activeTrucks} = data.slot.organization;
        return (
          <TruckSlotPopoverMutationContent
            slotId={slotId}
            refetch={refetch}
            truckToSlotIndexMap={truckToSlotIndexMap}
            truckIdsWithSlots={truckIdsWithSlots}
            activeTrucks={activeTrucks}
            handleClose={handleClose}
            selectedTruckId={data.slot.truckId ? data.slot.truckId.toString() : null}
          />
        );
      }}
    </Loading>
  );
};

const getButtonColor = ({isSelected, hasTruck, isTripSlot}) => {
  if (isTripSlot) {
    return colors.gray.disabled;
  }
  if (isSelected) {
    return colors.blue.interactive;
  }
  if (hasTruck) {
    return colors.white;
  }
  return colors.gray.border;
};

const ButtonTooltip = ({text, children}) => {
  if (text) {
    return <TextTooltip text={text}>{children}</TextTooltip>;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const TruckSlotButton = ({slot, isTripSlot, refetch}) => {
  const {truck} = slot;
  const {ref, isOpen, handleOpen, handleClose, handleToggle} = usePopover();
  const {isHovered, ref: hoverRef} = useHover();

  return (
    <React.Fragment>
      <Popover.Content innerRef={ref}>
        <ButtonTooltip
          text={
            isTripSlot && 'This is a trip truck and driver and can only be modified by the trip.'
          }
        >
          <SlotButton
            onPress={handleToggle}
            selected={isOpen}
            isHovered={isHovered}
            truck={truck}
            ref={hoverRef}
            disabled={isTripSlot}
            color={getButtonColor({isSelected: isOpen, hasTruck: !!truck, isTripSlot})}
          >
            {!truck ? (
              <React.Fragment>
                <Space width={2} />
                <Icon
                  color={isOpen ? colors.white : colors.gray.primary}
                  size={8}
                  source={Icon.Plus}
                />
                <Space width={4} />
              </React.Fragment>
            ) : (
              <Space width={2} />
            )}
            <SlotButtonText numberOfLines={1} selected={isOpen} truck={truck} vars={{isHovered}}>
              {truck ? truck.name : 'Truck'}
            </SlotButtonText>
            <Space width={1} />
          </SlotButton>
        </ButtonTooltip>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={isOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        reference={ref}
        offset={[0, 4]}
      >
        <ResponsivePopover.Container width={280} maxHeight={320}>
          <TruckSlotPopoverContent
            refetch={refetch}
            isOpen={isOpen}
            slotId={slot.id}
            handleClose={handleClose}
          />
        </ResponsivePopover.Container>
      </Popover>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TruckSlotButton.fragment = gql`
  fragment TruckSlotButton_Slot on Slot {
    id
    organizationId
    truck {
      id
      name
    }
  }
`;

TruckSlotPopoverContent.query = gql`
  query TruckPopoverContent($slotId: Int!) {
    ${gql.query}
    slot(slotId: $slotId) {
      id
      truckId
      organization {
        id
        activeTrucks {
          id
          name
          size
        }
      }
      day {
        id
        slots {
          id
          index
          truckId
        }
      }
    }
  }
`;

export default TruckSlotButton;
