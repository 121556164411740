// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import InvoiceForm from '@shared/modules/Billing/forms/InvoiceForm';
import ClientFields from 'modules/Client/components/ClientFields';
import ClientInformationBlock from 'modules/Client/components/ClientInformationBlock';
import InvoiceBillingFields from 'modules/Storage/components/InvoiceBillingFields';

const Section = Styled.View`
  background-color: ${colors.white}
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border}
  padding: ${({padding = '24px'}) => padding};
  z-index: ${({index = 0}) => 200 - index};  
`;

const HeaderText = Styled.Text`
  ${Typography.Heading2}
`;

const ClientInformation = ({form, field, project, organization}) => {
  return (
    <Section index={0} padding={'0px'}>
      <ClientInformationBlock
        index={0}
        project={project}
        organization={organization}
        form={form}
        field={`${field}.clientForm`}
        isSearchEnabled
        disabled={form.values.invoiceForm.isClientSameAsBill}
        headerLabel={'Client Information'}
        emailAddressRequired
      />
    </Section>
  );
};

const BillingClientInformation = ({form, field, project, organization}) => {
  return (
    <Section index={1} padding={'0px'}>
      <ClientInformationBlock
        index={1}
        project={project}
        organization={organization}
        form={form}
        field={`${field}.billingClientForm`}
        isSearchEnabled
        disabled={form.values.invoiceForm.isClientSameAsBill}
        headerLabel={'Billing Information'}
        emailAddressRequired
      />
    </Section>
  );
};

const InvoiceFields = ({form, field, project, viewer, setGrandTotalLoading, grandTotalLoading}) => {
  return (
    <React.Fragment>
      <ClientInformation
        form={form}
        field={field}
        project={project}
        organization={project.organization}
      />
      <Space height={24} />
      <BillingClientInformation
        form={form}
        field={field}
        project={project}
        organization={project.organization}
      />
      <Space height={24} />
      <Section index={2}>
        <InvoiceBillingFields
          form={form}
          field={field}
          project={project}
          viewer={viewer}
          setGrandTotalLoading={setGrandTotalLoading}
          grandTotalLoading={grandTotalLoading}
        />
      </Section>
      <Space height={24} />
      <Section index={3}>
        <HeaderText>Notes</HeaderText>
        <Space height={12} />
        <FieldInput
          {...form}
          name={`invoiceForm.customerNotes`}
          label={'Note to Customer (shown to customer)'}
          input={{
            placeholder: 'Enter notes for the customer',
            multiline: true,
            style: {
              paddingVertical: 9,
              minHeight: 96,
            },
          }}
        />
      </Section>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceFields.fragment = gql`
  ${InvoiceForm.newFromStorageProject.fragment}
  ${ClientFields.fragment}
  ${InvoiceBillingFields.fragment}
  ${ClientInformationBlock.fragment}

  fragment InvoiceFields on Project {
    id
    organization {
      id
      ...ClientFields
    }
    ...InvoiceForm_newFromStorageProject
    ...InvoiceBillingFields
    ...ClientInformationBlock
  }
`;

export default InvoiceFields;
