// Supermove
import {useMountEffect, useNavigationDOM} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

const DispatchPage = () => {
  const {navigator} = useNavigationDOM();
  const date = Datetime.toMutationDate(Datetime.today);

  useMountEffect(() => {
    navigator.replace(`/dispatch/calendar/day/${date}`);
  });

  return null;
};

export default DispatchPage;
