// App
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Forms
import PayableItemForm from './PayableItemForm';

const PayableUserDayForm = {
  toForm: ({payableUserDayId, payableItemForms}: any) => ({
    payableUserDayId,
    payableItemForms: payableItemForms.map((payableItemForm: any) => {
      return PayableItemForm.toForm(payableItemForm);
    }),
  }),
  toMutation: ({payableUserDayId, payableItemForms}: any) => ({
    payableUserDayId,
    payableItemForms: payableItemForms.map((payableItemForm: any) => {
      return PayableItemForm.toMutation(payableItemForm);
    }),
  }),
  edit: withFragment(
    (payableUserDay) => ({
      payableUserDayId: (payableUserDay as any).id,
      payableItemForms: (payableUserDay as any).payableItems.map((payableItem: any) => {
        return PayableItemForm.edit(payableItem);
      }),
    }),
    gql`
      ${PayableItemForm.edit.fragment}

      fragment PayableUserDayForm_edit on PayableUserDay {
        id
        payableItems {
          ...PayableItemForm_edit
        }
      }
    `,
  ),
};

export default PayableUserDayForm;
