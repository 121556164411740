// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Panel from '@shared/design/components/Panel';

const Line = Styled.View`
  background-color: ${colors.gray.border};
  height: ${({height}: {height: number}) => height}px;
`;

const HeaderColumn = Styled.View`
  flex: 1;
`;

const HeaderTextContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const MobileHeaderContainer = Styled.View`
  padding-vertical: 24px;
  padding-horizontal: 16px;
`;

const HeaderSubtitle = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const ActionPanelBody = ({
  isEmpty,
  EmptyBodyComponent,
  emptyBodyComponentProps,
  BodyComponent,
  bodyComponentProps,
}: {
  isEmpty: boolean;
  EmptyBodyComponent: React.ComponentType<any> | null;
  emptyBodyComponentProps: object;
  BodyComponent: React.ComponentType<any>;
  bodyComponentProps: object;
}) => {
  if (isEmpty && EmptyBodyComponent) {
    return <EmptyBodyComponent {...emptyBodyComponentProps} />;
  }

  return <BodyComponent {...bodyComponentProps} />;
};

export interface ActionPanelProps {
  index?: number;
  style?: object | null;
  isFlex?: boolean;
  BodyComponent: React.ComponentType<any>;
  bodyComponentProps?: object;
  HeaderActionComponent?: React.ComponentType<any> | null;
  headerActionComponentProps?: object;
  HeaderIconComponent?: React.ComponentType<any> | null;
  headerIconComponentProps?: object;
  ActionButtonComponent?: React.ComponentType<any> | null;
  actionButtonComponentProps?: object;
  EmptyBodyComponent?: React.ComponentType<any> | null;
  emptyBodyComponentProps?: object;
  HeaderComponent?: React.ComponentType<any> | null;
  headerComponentProps?: object;
  FooterComponent?: React.ComponentType<any> | null;
  footerComponentProps?: object;
  title?: string;
  description?: string;
  headerSubtitle?: string | React.ReactNode | null;
  isEmpty?: boolean;
  bodyStyle?: object;
  width?: number | null;
  isResponsive?: boolean;
  mobileBodyStyle?: object;
  mobileDividerHeight?: number;
}

const ActionPanel = ({
  index = 0,
  style = null,
  isFlex = false,
  BodyComponent,
  bodyComponentProps = {},
  HeaderActionComponent = null,
  headerActionComponentProps = {},
  HeaderIconComponent = null,
  headerIconComponentProps = {},
  ActionButtonComponent = () => null,
  actionButtonComponentProps = {},
  EmptyBodyComponent = null,
  emptyBodyComponentProps = {},
  HeaderComponent = null,
  headerComponentProps = {},
  FooterComponent = null,
  footerComponentProps = {},
  title = '',
  description = '',
  headerSubtitle = null,
  isEmpty = false,
  bodyStyle = {},
  width = null,
  mobileBodyStyle = {},
  mobileDividerHeight = 4,
}: ActionPanelProps) => {
  const responsive = useResponsive();

  if (!responsive.desktop) {
    return (
      <React.Fragment>
        <Line height={mobileDividerHeight} />
        {HeaderComponent ? (
          <HeaderComponent {...headerComponentProps} />
        ) : (
          <MobileHeaderContainer>
            <Row>
              <Panel.HeaderText>{title}</Panel.HeaderText>
              <Space style={{flex: 1, minWidth: 12}} />
              {ActionButtonComponent ? (
                <ActionButtonComponent {...actionButtonComponentProps} isResponsive />
              ) : null}
            </Row>
            {!!headerSubtitle && (
              <React.Fragment>
                <Space height={8} />
                <HeaderSubtitle>{headerSubtitle}</HeaderSubtitle>
              </React.Fragment>
            )}
          </MobileHeaderContainer>
        )}
        <Panel.Body style={mobileBodyStyle}>
          <ActionPanelBody
            isEmpty={isEmpty}
            EmptyBodyComponent={EmptyBodyComponent}
            emptyBodyComponentProps={emptyBodyComponentProps}
            BodyComponent={BodyComponent}
            bodyComponentProps={bodyComponentProps}
          />
        </Panel.Body>
        {FooterComponent && <FooterComponent {...footerComponentProps} />}
      </React.Fragment>
    );
  }

  return (
    <Panel width={width} style={style} index={index} isFlex={isFlex}>
      {HeaderComponent ? (
        <HeaderComponent {...headerComponentProps} />
      ) : (
        <React.Fragment>
          <Panel.Header>
            <HeaderColumn>
              <Row style={{alignItems: 'center'}}>
                <HeaderTextContainer>
                  {HeaderIconComponent && (
                    <Row>
                      <HeaderIconComponent {...headerIconComponentProps} />
                      <Space width={12} />
                    </Row>
                  )}
                  <Panel.HeaderText>{title}</Panel.HeaderText>
                  {!!description && (
                    <React.Fragment>
                      <Space width={12} />
                      <Panel.HeaderDescription>{description}</Panel.HeaderDescription>
                    </React.Fragment>
                  )}
                </HeaderTextContainer>
                {HeaderActionComponent && (
                  <Row>
                    <Space width={12} />
                    <HeaderActionComponent {...headerActionComponentProps} />
                  </Row>
                )}
                <Space style={{flex: 1, minWidth: 12}} />
                {ActionButtonComponent ? (
                  <ActionButtonComponent {...actionButtonComponentProps} />
                ) : null}
              </Row>
              {!!headerSubtitle && (
                <React.Fragment>
                  <Space height={8} />
                  <Row>
                    <HeaderSubtitle>{headerSubtitle}</HeaderSubtitle>
                  </Row>
                </React.Fragment>
              )}
            </HeaderColumn>
          </Panel.Header>
        </React.Fragment>
      )}
      <Panel.Body style={bodyStyle}>
        <ActionPanelBody
          isEmpty={isEmpty}
          EmptyBodyComponent={EmptyBodyComponent}
          emptyBodyComponentProps={emptyBodyComponentProps}
          BodyComponent={BodyComponent}
          bodyComponentProps={bodyComponentProps}
        />
      </Panel.Body>
      {FooterComponent && (
        <Panel.Footer>
          <FooterComponent {...footerComponentProps} />
        </Panel.Footer>
      )}
    </Panel>
  );
};

ActionPanel.Text = Panel.Text;
ActionPanel.LabelText = Panel.LabelText;
ActionPanel.HeaderText = Panel.HeaderText;

export default ActionPanel;
