// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import ItemFormItem from 'modules/Item/components/ItemFormItem';

const Container = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const Item = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const getFilteredItemForms = ({showSurveyedOnly, form}) => {
  const {itemForms, searchQuery, selectedCategoryId} = form.values.collectionForm;

  // This is the "surveyed" category that should show all non-zero item forms.
  if (showSurveyedOnly) {
    return itemForms.filter((itemForm) => itemForm.takeCount > 0 || itemForm.leaveCount > 0);
  }

  if (selectedCategoryId !== 'ALL') {
    return itemForms.filter((itemForm) => {
      return itemForm.categoryIds.includes(selectedCategoryId);
    });
  }

  if (searchQuery) {
    return itemForms.filter((itemForm) => {
      return itemForm.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  return itemForms;
};

const getFilteredItemIds = ({showSurveyedOnly, form}) => {
  const itemForms = getFilteredItemForms({showSurveyedOnly, form});
  return itemForms.map((itemForm) => itemForm.itemId);
};

const CollectionItemsList = ({showSurveyedOnly, form, inventory}) => {
  const itemIds = getFilteredItemIds({showSurveyedOnly, form});

  return (
    <Container>
      {form.values.collectionForm.itemForms.map((itemForm, index) => {
        // We always map over all itemForms to get the correct index but we only render the ones
        // that are included in the current filters.
        if (!itemIds.includes(itemForm.itemId)) {
          return null;
        }

        return (
          <Item key={index}>
            <ItemFormItem
              index={index}
              field={`collectionForm.itemForms.${index}`}
              itemForm={itemForm}
              form={form}
              inventory={inventory}
            />
          </Item>
        );
      })}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CollectionItemsList.fragment = gql`
  ${ItemFormItem.fragment}

  fragment CollectionItemsList on Inventory {
    id
    ...ItemFormItem
  }
`;

export default CollectionItemsList;
