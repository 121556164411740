// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {useModal, useSearch} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import EditBillCustomItemModal from 'modules/Project/Billing/Bill/Edit/components/EditBillCustomItemModal';
import EditBillCustomRuleModal from 'modules/Project/Billing/Bill/Edit/components/EditBillCustomRuleModal';
import EditBillItemsSearchPopover from 'modules/Project/Billing/Bill/Edit/components/EditBillItemsSearchPopover';
import EditBillRulesSearchPopover from 'modules/Project/Billing/Bill/Edit/components/EditBillRulesSearchPopover';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.justify};
`;

const HeaderContainer = Styled.View`
  background-color: ${colors.white};
  padding-horizontal: 16px;
  padding-vertical: 12px;
`;

const SearchButton = Styled.Touchable`
  height: 40px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const PlaceholderText = Styled.H6`
  color: ${colors.gray.tertiary};
  ${fontWeight(500)}
`;

const CustomItemButton = Styled.ButtonV2`
  height: 40px;
  width: 150px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: ${colors.blue.interactive};
  border-radius: 20px;
`;

const CustomItemText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
`;

const SearchInput = Styled.TextInput.H7`
  flex: 1;
  padding-right: 72px;
  border-width: 1px;
  border-color: ${colors.blue.interactive};
`;

const CancelSearchButton = Styled.ButtonV2`
  position: absolute;
  right: 12px;
  top: 11px;
`;

const CancelSearchText = Styled.H7`
  color: ${colors.blue.interactive};
  ${fontWeight(700)}
`;

const handleAddBillItemFormPreSubtotal = ({form, billItemForm}) => {
  const field = 'billForm.billItemFormsPreSubtotal';
  const billItemForms = _.get(form.values, field);
  form.setFieldValue(field, [...billItemForms, {...billItemForm, index: billItemForms.length}]);
};

const handleAddBillItemFormPostSubtotal = ({form, billItemForm}) => {
  const field = 'billForm.billItemFormsPostSubtotal';
  const billItemForms = _.get(form.values, field);
  form.setFieldValue(field, [...billItemForms, {...billItemForm, index: billItemForms.length}]);
};

const handleAddBillItemForm = ({form, billItemForm}) => {
  if (billItemForm.billStage === BillStage.PRE_SUBTOTAL) {
    handleAddBillItemFormPreSubtotal({form, billItemForm});
  } else {
    handleAddBillItemFormPostSubtotal({form, billItemForm});
  }
};

const handleAddBillRuleForm = ({form, billItemForm}) => {
  const field = 'billForm.billRuleForms';
  const forms = _.get(form.values, field);
  form.setFieldValue(field, [...forms, billItemForm]);
};

const getSearchItems = ({billForm, type}) => {
  if (type === 'ITEMS') {
    const allValidBillItemFormsPreSubtotal = BillForm.getValidBillItemFormsPreSubtotal(billForm);
    return _.orderBy(
      [...allValidBillItemFormsPreSubtotal, ...billForm.allBillItemFormsPostSubtotal],
      (billItemForm) => _.toUpper(billItemForm.name),
    );
  }
  if (type === 'RULES') {
    return _.orderBy(billForm.allBillRuleForms, (billRuleForm) => _.toUpper(billRuleForm.name));
  }
  return [];
};

const Search = ({handleOpenSearch, handleCloseSearch, setSearchQuery}) => {
  return (
    <React.Fragment>
      <SearchInput
        onFocus={handleOpenSearch}
        onChangeText={setSearchQuery}
        placeholder={'Search'}
      />
      <CancelSearchButton>
        <CancelSearchText onPress={handleCloseSearch}>Cancel</CancelSearchText>
      </CancelSearchButton>
    </React.Fragment>
  );
};

const AddToBillButtons = ({type, handleOpenSearch, addCustomModal}) => {
  return (
    <React.Fragment>
      <SearchButton activeOpacity={1} onPress={handleOpenSearch}>
        <Space width={8} />
        <Icon source={Icon.Search} color={colors.gray.secondary} size={14} />
        <Space width={8} />
        <PlaceholderText>{`Add from library`}</PlaceholderText>
      </SearchButton>
      <Space width={8} />
      <CustomItemButton onPress={addCustomModal.handleOpen}>
        <Icon source={Icon.Plus} color={colors.blue.interactive} size={12} />
        <Space width={8} />
        <CustomItemText>{`Custom ${type === 'ITEMS' ? 'Item' : 'Rule'}`}</CustomItemText>
      </CustomItemButton>
    </React.Fragment>
  );
};

const EditBillSlideHeader = ({
  billId,
  form,
  type,
  showSearch,
  handleOpenSearch,
  handleCloseSearch,
  searchPopover,
  handleScrollToEnd,
  isEnabledTbdBillItems,
}) => {
  const addCustomModal = useModal();
  const {results: searchResults, setQuery: setSearchQuery} = useSearch({
    initialQuery: '',
    items: getSearchItems({billForm: form.values.billForm, type}),
    options: {keys: ['name']},
  });
  return (
    <HeaderContainer>
      <Popover.Content innerRef={searchPopover.ref}>
        <Row justify={'flex-start'}>
          {showSearch ? (
            <Search
              handleOpenSearch={handleOpenSearch}
              handleCloseSearch={() => {
                setSearchQuery('');
                handleCloseSearch();
              }}
              setSearchQuery={setSearchQuery}
            />
          ) : (
            <AddToBillButtons
              type={type}
              handleOpenSearch={handleOpenSearch}
              addCustomModal={addCustomModal}
            />
          )}
        </Row>
      </Popover.Content>
      {type === 'ITEMS' && (
        <React.Fragment>
          <EditBillItemsSearchPopover
            popover={searchPopover}
            searchItemForms={searchResults}
            form={form}
            handleCloseSearch={handleCloseSearch}
            handlePress={(billItemForm) => {
              handleAddBillItemForm({form, billItemForm});
              // We setTimeout so that the scroll is triggered after adding
              // the bill item form has been completed.
              setTimeout(() => handleScrollToEnd({animated: true}), 0);
            }}
          />
          <EditBillCustomItemModal
            billId={billId}
            key={`CUSTOM_ITEM-${addCustomModal.isOpen}`}
            isOpen={addCustomModal.isOpen}
            handleClose={addCustomModal.handleClose}
            handleSubmit={(billItemForm) => {
              handleAddBillItemForm({form, billItemForm});
              // We setTimeout so that the scroll is triggered after adding
              // the bill rule form has been completed.
              setTimeout(() => handleScrollToEnd({animated: true}), 0);
            }}
            isEnabledTbdBillItems={isEnabledTbdBillItems}
          />
        </React.Fragment>
      )}
      {type === 'RULES' && (
        <React.Fragment>
          <EditBillRulesSearchPopover
            popover={searchPopover}
            searchRuleForms={searchResults}
            form={form}
            handleCloseSearch={handleCloseSearch}
            handlePress={(billItemForm) => {
              handleAddBillRuleForm({form, billItemForm});
              // We setTimeout so that the scroll is triggered after adding
              // the bill rule form has been completed.
              setTimeout(() => handleScrollToEnd({animated: true}), 0);
            }}
          />
          <EditBillCustomRuleModal
            key={`CUSTOM_RULE-${addCustomModal.isOpen}`}
            isOpen={addCustomModal.isOpen}
            handleClose={addCustomModal.handleClose}
            handleSubmit={(billItemForm) => {
              handleAddBillRuleForm({form, billItemForm});
              // We setTimeout so that the scroll is triggered after adding
              // the bill item form has been completed.
              setTimeout(() => handleScrollToEnd({animated: true}), 0);
            }}
          />
        </React.Fragment>
      )}
    </HeaderContainer>
  );
};

export default EditBillSlideHeader;
