// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Styled, Space, DragAndDropList} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  Form,
  ResponsiveType,
  useDragAndDrop,
  useDrawer,
  useNavigationDOM,
  useQuery,
  useResponsive,
  useState,
} from '@supermove/hooks';
import {DocumentTemplateModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import Switch from '@shared/design/components/Switch';
import TextTooltip from '@shared/design/components/TextTooltip';
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';
import JobTypeConfigs from '@shared/modules/Job/enums/JobTypeConfigs';
import JobTypeStepDocumentTemplateForm, {
  JobTypeStepDocumentTemplateApiType,
  JobTypeStepDocumentTemplateFormType,
} from '@shared/modules/Job/forms/JobTypeStepDocumentTemplateForm';
import JobTypeStepForm from '@shared/modules/Job/forms/JobTypeStepForm';
import useUpsertJobTypeStepMutation from '@shared/modules/Job/hooks/useUpsertJobTypeStepMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import ApplyJobTypeConfigPopoverButton from 'modules/Organization/Settings/JobTypes/components/ApplyJobTypeConfigPopoverButton';
import JobTypeSettingsPageHeader from 'modules/Organization/Settings/JobTypes/components/JobTypeSettingsPageHeader';

import JobTypeStepDocumentsSectionItem from './components/JobTypeStepDocumentsSectionItem';
import JobTypeStepsSelectDocumentSectionDrawer from './components/JobTypeStepsSelectDocumentSectionDrawer';

const View = Styled.View``;

const PageContainer = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-left: 24px;
  background-color: ${colors.gray.background};
`;

const SearchDocumentInputContainer = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const DocumentRow = Styled.View`
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
  padding-vertical: 8px;
  padding-horizontal: 16px;
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const JobTypeStepsItemContainer = Styled.View<{index: number}>`
  max-width: 840px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
  zIndex: ${({index}) => 100 - index};
  background-color: ${colors.white};
`;

const DocumentsContainer = Styled.View`
  flex: 1;
`;

const Text = Styled.Text<{color?: string}>`
  ${Typography.Responsive.Body}
  color: ${({color = colors.gray.secondary}) => color};
`;

const JobTypeHeaderView = Styled.View`
  flex-direction: row;
  flex-shrink: 0;
  flex-basis: 35%;
`;

const JobTypeHeaderText = Styled.Text<{color?: string}>`
  ${Typography.Responsive.Label}
  color: ${({color}) => color};
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading1}
`;

const DocumentSectionItemContainer = Styled.View`
  z-index: -1;
`;

const DocumentSectionListEmpty = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
`;

const ButtonRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 840px;
`;

const ADDITIONAL_STEPS_KIND = 'DURING_MOVE';

const getDocumentListItems = (
  organizationDocumentTemplates: any[],
  formDocumentTemplateField: Form<any>[],
) => {
  return organizationDocumentTemplates.reduce(
    (filteredDocumentTemplateOptions, documentTemplateOption) => {
      if (
        !formDocumentTemplateField.includes(documentTemplateOption.identifier) &&
        documentTemplateOption.category !== DocumentTemplateCategory.INVOICE
      ) {
        return [
          ...filteredDocumentTemplateOptions,
          {
            label: documentTemplateOption.name,
            value: documentTemplateOption.identifier,
            id: documentTemplateOption.id,
          },
        ];
      }
      return filteredDocumentTemplateOptions;
    },
    [],
  );
};

const swapDocumentTemplateKinds = ({
  form,
  documentTemplateField,
  fromIndex,
  toIndex,
  handleSubmit,
  isEnabledMultiPartDocuments,
}: {
  form: Form<JobTypeStepDocumentTemplateFormType>;
  documentTemplateField: string;
  fromIndex: number;
  toIndex: number;
  handleSubmit: () => void;
  isEnabledMultiPartDocuments: boolean;
}) => {
  const jobTypeStepDocumentTemplateKinds = _.get(form.values, documentTemplateField);
  const reorderedDocumentTemplateKinds = List.move({
    list: jobTypeStepDocumentTemplateKinds,
    fromIndex,
    toIndex,
  });
  form.setFieldValue(documentTemplateField, reorderedDocumentTemplateKinds);
  if (isEnabledMultiPartDocuments) {
    const jobTypeStepDocumentTemplateForms: JobTypeStepDocumentTemplateFormType[] = _.get(
      form.values,
      'jobTypeStepForm.jobTypeStepDocumentTemplateForms',
    );
    const reorderedDocumentTemplateForms = List.move({
      list: jobTypeStepDocumentTemplateForms,
      fromIndex,
      toIndex,
    });
    reorderedDocumentTemplateForms.forEach((documentTemplateForm, index) => {
      documentTemplateForm.index = index;
    });
    form.setFieldValue(
      'jobTypeStepForm.jobTypeStepDocumentTemplateForms',
      reorderedDocumentTemplateForms,
    );
  }
  setImmediate(handleSubmit);
};

const JobTypeStepDocumentSearch = ({
  form,
  handleSubmit,
  organization,
  documentTemplateField,
  formDocumentTemplateField,
  jobTypeStepDocumentTemplateFormsField,
  handleMenuClose,
}: {
  form: Form<any>;
  handleSubmit: () => void;
  organization: any;
  documentTemplateField: string;
  formDocumentTemplateField: Form<any>[];
  jobTypeStepDocumentTemplateFormsField: string;
  handleMenuClose: () => void;
}) => {
  const jobTypeStepDocumentTemplateForms =
    _.get(form.values, jobTypeStepDocumentTemplateFormsField) || [];

  return (
    <SearchDocumentInputContainer>
      <FieldInput
        {...form}
        component={DropdownInput}
        input={{
          placeholder: 'Search',
          autoFocus: true,
          defaultMenuIsOpen: true,
          isSearchable: true,
          onMenuClose: handleMenuClose,
          name: documentTemplateField,
          options: getDocumentListItems(
            organization.documentTemplatesWithActiveDocumentTemplateVersion,
            formDocumentTemplateField,
          ),
          onChangeValue: (value: string, documentOption: {id: string}) => {
            form.setFieldValue(documentTemplateField, [...formDocumentTemplateField, value]);
            if (organization.features.isEnabledMultiPartDocuments) {
              form.setFieldValue(jobTypeStepDocumentTemplateFormsField, [
                ...jobTypeStepDocumentTemplateForms,
                JobTypeStepDocumentTemplateForm.toForm(
                  JobTypeStepDocumentTemplateForm.new({
                    jobTypeStepId: form.values.jobTypeStepForm.jobTypeStepId,
                    organizationId: organization.id,
                    documentTemplateId: documentOption.id,
                    readonlySectionUuids: [],
                    index: jobTypeStepDocumentTemplateForms?.length || 0,
                  }),
                ),
              ]);
            }
            setImmediate(handleSubmit);
            handleMenuClose();
          },
          setFieldValue: form.setFieldValue,
          style: {
            width: '100%',
            borderRadius: 8,
            borderColor: colors.gray.border,
            paddingLeft: 20,
          },
          components: {
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
          },
        }}
        style={{width: '100%'}}
      />
      <Icon
        source={Icon.Search}
        size={12}
        color={colors.gray.tertiary}
        style={{position: 'absolute', top: 11, left: 12, zIndex: 100}}
      />
    </SearchDocumentInputContainer>
  );
};

const JobTypeStepDocumentsSection = ({
  form,
  handleSubmit,
  organization,
  responsive,
  handleSelectDocumentSection,
}: {
  form: Form<any>;
  handleSubmit: () => void;
  organization: any;
  responsive: ResponsiveType;
  handleSelectDocumentSection: (documentTemplate: DocumentTemplateModel) => void;
}) => {
  const [isAddDocumentsOpen, setIsAddDocumentsOpen] = useState(false);
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const documentTemplateField = 'jobTypeStepForm.documentTemplateKinds';
  const formDocumentTemplateField = _.get(form.values, documentTemplateField) || [];
  const jobTypeStepDocumentTemplateFormsField = 'jobTypeStepForm.jobTypeStepDocumentTemplateForms';

  return (
    <DocumentsContainer>
      <Space height={8} />

      <Space height={8} />
      <DocumentSectionItemContainer>
        {formDocumentTemplateField.length === 0 && (
          <DocumentSectionListEmpty>
            This step will be enabled once documents are added.
          </DocumentSectionListEmpty>
        )}
        <DragAndDropList
          isReordering={isReordering}
          spaceBetweenItems={10}
          onReorder={({fromIndex, toIndex}) => {
            handleReorderStart();
            swapDocumentTemplateKinds({
              form,
              documentTemplateField,
              fromIndex,
              toIndex,
              handleSubmit,
              isEnabledMultiPartDocuments: organization.features.isEnabledMultiPartDocuments,
            });
            handleReorderEnd();
          }}
        >
          {formDocumentTemplateField.map((documentTemplateKind: string, index: number) => (
            <JobTypeStepDocumentsSectionItem
              key={index}
              form={form}
              documentTemplateField={documentTemplateField}
              jobTypeStepDocumentTemplateFormsField={jobTypeStepDocumentTemplateFormsField}
              documentTemplateKind={documentTemplateKind}
              handleSubmit={handleSubmit}
              organization={organization}
              responsive={responsive}
              handleSelectDocumentSection={handleSelectDocumentSection}
            />
          ))}
        </DragAndDropList>
      </DocumentSectionItemContainer>
      <Space height={8} />
      {isAddDocumentsOpen ? (
        <JobTypeStepDocumentSearch
          form={form}
          handleSubmit={handleSubmit}
          organization={organization}
          documentTemplateField={documentTemplateField}
          formDocumentTemplateField={formDocumentTemplateField}
          jobTypeStepDocumentTemplateFormsField={jobTypeStepDocumentTemplateFormsField}
          handleMenuClose={() => setIsAddDocumentsOpen(false)}
        />
      ) : (
        <TertiaryButton
          iconLeft={Icon.Plus}
          iconRight={Icon.AngleDown}
          text='Add Document from Library'
          onPress={() => setIsAddDocumentsOpen(true)}
        />
      )}
    </DocumentsContainer>
  );
};

const FakeJobTypeStepsItem = ({
  name,
  description,
  stepIndex,
  responsive,
}: {
  name: string;
  description: string;
  stepIndex: number;
  responsive: ResponsiveType;
}) => {
  return (
    <JobTypeStepsItemContainer index={stepIndex}>
      <Space height={12} />
      <Row style={{paddingHorizontal: 16}}>
        <JobTypeHeaderView>
          <JobTypeHeaderText
            responsive={responsive}
            style={{width: 32}}
            color={colors.gray.secondary}
          >{`${stepIndex + 1}.`}</JobTypeHeaderText>
          <JobTypeHeaderText responsive={responsive} numberOfLines={1}>
            {name}
          </JobTypeHeaderText>
        </JobTypeHeaderView>
        <Text responsive={responsive} numberOfLines={2}>
          {description}
        </Text>
        <Space style={{flex: 1}} />
        <Space width={16} />
        <TextTooltip text={'This step is on by default for all jobs'}>
          <View>
            <Switch isOn disabled color={colors.blue.interactive} size={Switch.SIZE.MEDIUM} />
          </View>
        </TextTooltip>
      </Row>
      <Space height={8} />
    </JobTypeStepsItemContainer>
  );
};

const JobTypeStepsItem = ({
  jobTypeStep,
  stepIndex,
  organization,
  responsive,
  handleSelectDocumentSection,
}: {
  jobTypeStep: any;
  stepIndex: number;
  organization: any;
  responsive: ResponsiveType;
  handleSelectDocumentSection: (documentTemplate: DocumentTemplateModel) => void;
}) => {
  const jobTypeStepForm = JobTypeStepForm.edit(jobTypeStep);

  const jobTypeStepIsEnabledField = 'jobTypeStepForm.isEnabled';
  const jobTypeStepIdField = 'jobTypeStepForm.jobTypeStepId';
  const jobTypeStepDocumentTemplateKindsField = 'jobTypeStepForm.documentTemplateKinds';
  const jobTypeStepDocumentTemplateFormsField = 'jobTypeStepForm.jobTypeStepDocumentTemplateForms';

  const {form, handleSubmit} = useUpsertJobTypeStepMutation({
    jobTypeStepForm,
    onSuccess: ({jobTypeStep}: {jobTypeStep: any}) => {
      form.setFieldValue(jobTypeStepIdField, jobTypeStep.id);
      form.setFieldValue(jobTypeStepIsEnabledField, jobTypeStep.isEnabled);
      form.setFieldValue(jobTypeStepDocumentTemplateKindsField, jobTypeStep.documentTemplateKinds);
      if (organization.features.isEnabledMultiPartDocuments) {
        const existingJobTypeStepDocumentTemplateForms = _.get(
          form.values,
          jobTypeStepDocumentTemplateFormsField,
        );
        const updatedJobTypeStepDocumentTemplateForms =
          jobTypeStep.jobTypeStepDocumentTemplates.map(
            (jobTypeStepDocumentTemplateForm: JobTypeStepDocumentTemplateApiType) => {
              const existingDocumentTemplateForm = _.find(
                existingJobTypeStepDocumentTemplateForms,
                {
                  documentTemplateId: jobTypeStepDocumentTemplateForm.documentTemplateId,
                },
              );
              return _.merge(
                existingDocumentTemplateForm,
                JobTypeStepDocumentTemplateForm.toForm(
                  JobTypeStepDocumentTemplateForm.edit(jobTypeStepDocumentTemplateForm),
                ),
              );
            },
          );
        updatedJobTypeStepDocumentTemplateForms.sort(JobTypeStepDocumentTemplateForm.sort);
        form.setFieldValue(
          `${jobTypeStepDocumentTemplateFormsField}`,
          updatedJobTypeStepDocumentTemplateForms,
        );
      }
    },
    onError: () =>
      form.setFieldValue(jobTypeStepIsEnabledField, !form.values.jobTypeStepForm.isEnabled),
  });

  const tooltipText = (() => {
    if (jobTypeStep.canHaveDocuments) {
      if (_.get(form.values, jobTypeStepIsEnabledField)) {
        return 'Remove documents to disable this step.';
      }
      return 'This step will be enabled once documents are added.';
    }
  })();

  return (
    <JobTypeStepsItemContainer index={stepIndex}>
      <Space height={12} />
      <Row style={{paddingHorizontal: 16}}>
        <JobTypeHeaderView>
          {jobTypeStep.kind !== ADDITIONAL_STEPS_KIND && (
            <JobTypeHeaderText
              responsive={responsive}
              style={{width: 32}}
              color={colors.gray.secondary}
            >
              {`${stepIndex + 1}.`}
            </JobTypeHeaderText>
          )}
          <JobTypeHeaderText responsive={responsive} numberOfLines={1}>
            {jobTypeStep.name}
          </JobTypeHeaderText>
        </JobTypeHeaderView>
        <Text responsive={responsive} numberOfLines={2}>
          {jobTypeStep.description}
        </Text>
        <Space style={{flex: 1}} />
        <Space width={16} />
        <TextTooltip text={tooltipText}>
          <View>
            <SwitchField
              form={form}
              disabled={jobTypeStep.canHaveDocuments}
              field={jobTypeStepIsEnabledField}
              onChangeValue={() => setImmediate(handleSubmit)}
            />
          </View>
        </TextTooltip>
      </Row>
      <Space height={8} />
      {jobTypeStep.canHaveDocuments && (
        <DocumentRow>
          <JobTypeStepDocumentsSection
            form={form}
            handleSubmit={handleSubmit}
            organization={organization}
            responsive={responsive}
            handleSelectDocumentSection={handleSelectDocumentSection}
          />
        </DocumentRow>
      )}
    </JobTypeStepsItemContainer>
  );
};

// NOTE(kevin): This transformation takes combines job types steps with organization
// configured job steps
const mergedJobStepsWithOrganizationJobSteps = (
  baseJobSteps: any[],
  jobTypeSteps: any[],
  jobTypeId: string,
  organizationId: string,
) => {
  return (
    baseJobSteps
      // We merge the crew default steps with job type step
      .reduce((mergedJobTypeSteps, defaultJobStep) => {
        // Remove Pre Move payments from the FE, we can't do this in the backend
        // as we can't remove a kind from config if there are existing job steps with the kind
        if (defaultJobStep.kind === 'CREW_PRE_MOVE_PAYMENTS') {
          return mergedJobTypeSteps;
        }

        // Find job type step exist for given organization job steps
        const jobTypeStep = _.find(jobTypeSteps, {
          kind: defaultJobStep.kind,
        });

        if (jobTypeStep) {
          return [...mergedJobTypeSteps, jobTypeStep];
        } else {
          return [
            ...mergedJobTypeSteps,
            {
              ...defaultJobStep,
              documentTemplateKinds: [],
              isEnabled: false,
              jobTypeId,
              organizationId,
            },
          ];
        }
      }, [])
  );
};

const PrimarySteps = ({
  jobTypeSteps,
  organization,
  responsive,
  handleSelectDocumentSection,
}: {
  jobTypeSteps: any[];
  organization: any;
  responsive: ResponsiveType;
  handleSelectDocumentSection: (
    documentTemplate: DocumentTemplateModel,
    jobTypeStepKind: string,
  ) => void;
}) => {
  // Used to determine step number
  const postMoveBillingIndex = _.findIndex(jobTypeSteps, {
    kind: 'CREW_POST_MOVE_BILLING',
  });

  return jobTypeSteps.map((jobTypeStep, index) => {
    const isAfterInsertedStep = index >= postMoveBillingIndex;
    if (jobTypeStep.kind === 'AFTER_TIMESHEET') {
      return (
        <React.Fragment key={index}>
          {/* Insert a fake job step item (doesn't exist on the backend) to be clear to users that the timesheets step exists here */}
          <FakeJobTypeStepsItem
            name={'Job Timesheet'}
            description={
              'Allow the crew to record time worked on the job. These hours are billed to the customer.'
            }
            stepIndex={index}
            responsive={responsive}
          />
          <Space height={8} />
          <JobTypeStepsItem
            jobTypeStep={jobTypeStep}
            stepIndex={index + 1}
            organization={organization}
            responsive={responsive}
            handleSelectDocumentSection={(documentTemplate) =>
              handleSelectDocumentSection(documentTemplate, jobTypeStep.kind)
            }
          />
          <Space height={8} />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment key={index}>
        <JobTypeStepsItem
          jobTypeStep={jobTypeStep}
          stepIndex={isAfterInsertedStep ? index + 1 : index}
          organization={organization}
          responsive={responsive}
          handleSelectDocumentSection={(documentTemplate) =>
            handleSelectDocumentSection(documentTemplate, jobTypeStep.kind)
          }
        />
        <Space height={8} />
      </React.Fragment>
    );
  });
};

const AdditionalSteps = ({
  index,
  jobTypeStep,
  organization,
  responsive,
  handleSelectDocumentSection,
}: {
  index: number;
  jobTypeStep: any;
  organization: any;
  responsive: ResponsiveType;
  handleSelectDocumentSection: (documentTemplate: DocumentTemplateModel) => void;
}) => {
  return (
    <React.Fragment key={index}>
      <HeaderText responsive={responsive}>Additional Steps</HeaderText>
      <Space height={24} />
      <JobTypeStepsItem
        jobTypeStep={jobTypeStep}
        stepIndex={index}
        organization={organization}
        responsive={responsive}
        handleSelectDocumentSection={handleSelectDocumentSection}
      />
      <Space height={8} />
    </React.Fragment>
  );
};

type SelectedDocumentTemplateInfo = {
  documentTemplate: DocumentTemplateModel;
  kind: string;
};

const JobTypeStepsSettingsContent = ({jobTypeUuid}: {jobTypeUuid: string}) => {
  const selectDocumentSectionsDrawer = useDrawer({name: 'Select Document Sections Drawer'});
  const [selectedDocumentTemplateInfo, setSelectedDocumentTemplateInfo] = useState<
    SelectedDocumentTemplateInfo | undefined
  >();
  const {loading, data, refetch} = useQuery(JobTypeStepsSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobTypeUuid,
    },
  });
  const responsive = useResponsive();

  if (loading) {
    return <PageLoadingIndicator />;
  }

  const jobType = data.jobTypeByUuid;
  const {organization, jobTypeSteps, projectType} = jobType;

  const jobTypeStepsTransformed = mergedJobStepsWithOrganizationJobSteps(
    jobType.projectType.defaultCrewJobSteps,
    jobTypeSteps,
    jobType.id,
    organization.id,
  );

  return (
    <PageContainer>
      <JobTypeSettingsPageHeader jobType={jobType} />
      <ContentContainer>
        <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
          <ScrollView>
            <Space height={24} />
            <ButtonRow>
              <HeaderText responsive={responsive}>Crew App Steps</HeaderText>
              <ApplyJobTypeConfigPopoverButton
                jobType={jobType}
                refetch={refetch}
                defaultJobTypeConfigs={[JobTypeConfigs.CREW_STEPS.value]}
              />
            </ButtonRow>
            <Space height={12} />
            <Text responsive={responsive}>
              {'Configure crew app steps and document settings for this job type.'}
            </Text>
            <Space height={24} />
            {/* @ts-expect-error element arrays are valid children */}
            <PrimarySteps
              jobTypeSteps={_.filter(jobTypeStepsTransformed, (jobTypeStep) => {
                if (
                  projectType.features.timesheetsV2 &&
                  jobTypeStep.kind === 'CREW_AFTER_MOVE_TIMESHEET'
                ) {
                  return false;
                }
                return jobTypeStep.kind !== ADDITIONAL_STEPS_KIND;
              })}
              organization={organization}
              responsive={responsive}
              handleSelectDocumentSection={(documentTemplate, kind) => {
                setSelectedDocumentTemplateInfo({documentTemplate, kind});
                setImmediate(selectDocumentSectionsDrawer.handleOpen);
              }}
            />
            <Space height={40} />
            <AdditionalSteps
              index={jobTypeStepsTransformed.length}
              jobTypeStep={_.find(jobTypeStepsTransformed, {kind: ADDITIONAL_STEPS_KIND})}
              organization={organization}
              responsive={responsive}
              handleSelectDocumentSection={(documentTemplate) => {
                setSelectedDocumentTemplateInfo({documentTemplate, kind: ADDITIONAL_STEPS_KIND});
                setImmediate(selectDocumentSectionsDrawer.handleOpen);
              }}
            />
          </ScrollView>
        </ScrollView>
        <Space height={48} />
      </ContentContainer>
      <JobTypeStepsSelectDocumentSectionDrawer
        drawerHandler={selectDocumentSectionsDrawer}
        refetch={refetch}
        documentTemplateUuid={selectedDocumentTemplateInfo?.documentTemplate.uuid}
        documentTemplateVersionUuid={
          selectedDocumentTemplateInfo?.documentTemplate.activeDocumentTemplateVersion.uuid
        }
        jobTypeUuid={jobTypeUuid}
        jobTypeStepKind={selectedDocumentTemplateInfo?.kind}
      />
    </PageContainer>
  );
};

const JobTypeStepsSettingsPage = () => {
  const {params} = useNavigationDOM();
  return (
    <SidebarPageV2 selected={'settings'}>
      <JobTypeStepsSettingsContent jobTypeUuid={params.jobTypeUuid} />
    </SidebarPageV2>
  );
};

JobTypeStepsSettingsPage.query = gql`
${ApplyJobTypeConfigPopoverButton.fragment}
${JobTypeSettingsPageHeader.fragment}
${JobTypeStepDocumentsSectionItem.fragment}

query JobTypeStepsSettingsPage(
  $jobTypeUuid: String!,
) {
  ${gql.query}
  jobTypeByUuid(jobTypeUuid: $jobTypeUuid) {
    id
    organization {
      id
      documentTemplatesWithActiveDocumentTemplateVersion {
        id
        name
        identifier
        category
        uuid
      }
      features {
        isEnabledMultiPartDocuments: isEnabled(feature: "MULTI_PART_DOCUMENTS")
      }
    }
    projectType {
      id
      defaultCrewJobSteps {
        kind
        name
        description
        documentTemplateOptions {
          kind
          name
        }
        canHaveDocuments
      }
      features {
        timesheetsV2
      }
    }
    jobTypeSteps {
      id
      kind
      documentTemplateKinds
      description
      canHaveDocuments
      jobTypeStepDocumentTemplates {
        id
        organizationId
        index
        readonlySectionUuids
        documentTemplateId
        jobTypeStepId
      }
      documentTemplateOptions {
        kind
        name
      }
      name
      isEnabled
      jobTypeId
      organizationId
    }
    ...ApplyJobTypeConfigPopoverButton
    ...JobTypeSettingsPageHeader
    ...JobTypeStepDocumentsSectionItem
  }
}
`;

export default JobTypeStepsSettingsPage;
