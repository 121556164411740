// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Datetime} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    name: (organization as any).name,
    // Business Info
    businessName: (organization as any).businessName,
    businessAddress: (organization as any).businessAddress,
    businessStory: (organization as any).businessStory,
    businessValues: (organization as any).businessValues,
    businessFoundingDate: (organization as any).businessFoundingDate,
    // private
    slug: (organization as any).slug,
  }),
  gql`
    fragment OrganizationForm_edit on Organization {
      id
      name
      slug
      businessName
      businessAddress
      businessStory
      businessValues
      businessFoundingDate
    }
  `,
);

const toForm = (organization: any) => ({
  organizationId: organization.organizationId,
  name: organization.name,

  // Business Info
  businessName: organization.businessName,

  businessAddress: organization.businessAddress,
  businessStory: organization.businessStory,
  businessValues: organization.businessValues,
  businessFoundingDate: Datetime.toFormDate(organization.businessFoundingDate),

  // private
  slug: organization.slug,
});

const toMutation = (organization: any) => ({
  organizationId: organization.organizationId,
  name: organization.name,

  // Business Info
  businessName: organization.businessName,

  businessAddress: organization.businessAddress,
  businessStory: organization.businessStory,
  businessValues: organization.businessValues,
  businessFoundingDate: Datetime.toMutationDate(organization.businessFoundingDate),
});

const OrganizationForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationForm;
