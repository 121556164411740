// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Datetime} from '@supermove/utils';

// App
import ConfirmationNotFound from 'modules/Customer/Project/Confirmation/components/ConfirmationNotFound';
import PrintDocumentPage from 'modules/Document/Print/components/PrintDocumentPage';
import ProjectConfirmationDocument from 'modules/Project/Confirmation/components/ProjectConfirmationDocument';

const getSignatureDate = ({project}) => {
  const {document} = project.confirmation;
  const date = _.get(document, 'documentSignatures[0].signature.date');
  return Datetime.toFormDate(date);
};

const getSignatureImage = ({project}) => {
  const {document} = project.confirmation;
  return _.get(document, 'documentSignatures[0].signature.image');
};

const getSignatureText = ({project}) => {
  const {document} = project.confirmation;
  return getSignatureImage({project}) || _.get(document, 'documentSignatures[0].signature.text');
};

const PrintConfirmProjectPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <PrintDocumentPage query={PrintConfirmProjectPage.query} variables={{uuid}}>
    {({data}) =>
      !_.get(data, 'project.confirmation', null) ? (
        <ConfirmationNotFound />
      ) : (
        <ProjectConfirmationDocument
          project={data.project}
          values={{
            date: getSignatureDate({project: data.project}),
            image: getSignatureImage({project: data.project}),
            text: getSignatureText({project: data.project}),
          }}
        />
      )
    }
  </PrintDocumentPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PrintConfirmProjectPage.query = gql`
  ${ProjectConfirmationDocument.fragment}

  query PrintConfirmProjectPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      confirmation {
        id
        isComplete
        document {
          id
          template
          documentSignatures {
            id
            documentId
            signatureId
            signature {
              id
              date
              kind
              image
              text
            }
          }
        }
      }
      ...ProjectConfirmationDocument
    }
  }
`;

export default PrintConfirmProjectPage;
