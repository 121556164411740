// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {Crew} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import SetCrewSlotsToCrewForm from '@shared/modules/Dispatch/forms/SetCrewSlotsToCrewForm';
import useSetCrewSlotsToCrewMutation from '@shared/modules/Dispatch/hooks/useSetCrewSlotsToCrewMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import SystemMessage from 'modules/App/components/SystemMessage';
import TrucksAndSlotsList from 'modules/Dispatch/Crew/components/TrucksAndSlotsList';

const HeaderContainer = Styled.View`
  background-color: ${colors.white};
`;

const HeaderButton = Styled.Button`
  height: auto;
  background-color: ${colors.white};
  box-shadow: none;
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const OptionMessageContainer = Styled.ButtonV2`
  background-color: ${colors.orange.accent};
  border-radius: 4px;
  margin-horizontal: 12px;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  flex-direction: row;
  align-items: center;
`;

const OptionMessage = Styled.H8`
  color: ${colors.orange.status};
  ${fontWeight(500)}
`;

const Checkbox = Styled.View`;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  border-width: 1px;
  border-color: ${colors.orange.status};
  align-items: center;
  justify-content: center;
  background-color: ${({color}) => color};
`;

const getHeaderTitle = (crew) => {
  return `Assign Trucks ${Crew.getJobTrucksCount(crew)}`;
};

const getHeaderCaption = (crew) => {
  return (
    `${pluralize('Truck', crew.numberOfRequiredTrucks, true)}, ` +
    `${pluralize('Mover', crew.numberOfRequiredMovers, true)}`
  );
};

const handleToggleAssignTrucksToAllCrews = ({form}) => {
  const field = 'setCrewSlotsToCrewForm.assignTrucksToAllCrews';
  const assignTrucksToAllCrews = _.get(form.values, field);
  form.setFieldValue(field, !assignTrucksToAllCrews);
};

const HeaderRight = ({handleClose}) => {
  const responsive = useResponsive();
  if (responsive.mobile) {
    return null;
  }
  return (
    <React.Fragment>
      <HeaderButton onPress={handleClose}>
        <Icon source={Icon.Times} size={16} color={colors.gray.primary} />
      </HeaderButton>
      <Space width={2} />
    </React.Fragment>
  );
};

const CrewTrucksContent = ({
  crew,
  handleClose,
  refetch,
  refetchQueries,
  setIsCloseable,
  shouldRunUpsertCostsAndBillingEngineAsync,
}) => {
  const setCrewSlotsToCrewForm = SetCrewSlotsToCrewForm.edit(crew, {
    canCreateNewSlot: true,
    shouldRunUpsertCostsAndBillingEngineAsync,
  });

  const {form, submitting, handleSubmit} = useSetCrewSlotsToCrewMutation({
    setCrewSlotsToCrewForm,
    refetchQueries,
    onSuccess: () => {
      setIsCloseable(true);
      handleClose();
      refetch();
    },
    onError: (errors) => {
      setIsCloseable(true);
      console.log({errors});
    },
  });

  const crewSlotFormsField = 'setCrewSlotsToCrewForm.crewSlotForms';
  const hasTooManySelections =
    _.get(form.values, crewSlotFormsField).length > crew.numberOfRequiredTrucks;
  const assignTrucksToAllCrews = _.get(
    form.values,
    'setCrewSlotsToCrewForm.assignTrucksToAllCrews',
  );
  const responsive = useResponsive();

  return (
    <ResponsivePopover.Container width={320} maxHeight={340}>
      <HeaderContainer>
        <Space height={responsive.mobile ? 12 : 16} />
        <ResponsivePopover.Header
          title={getHeaderTitle(crew)}
          caption={getHeaderCaption(crew)}
          handleClose={handleClose}
          headerRight={<HeaderRight handleClose={handleClose} />}
        />
        <Space height={responsive.mobile ? 10 : 8} />
        <Line />
      </HeaderContainer>
      <TrucksAndSlotsList
        organizationId={crew.organizationId}
        job={crew.job}
        crewId={crew.id}
        field={crewSlotFormsField}
        form={form}
        responsive={responsive}
      />
      <Space height={8} />
      <OptionMessageContainer onPress={() => handleToggleAssignTrucksToAllCrews({form})}>
        <Checkbox color={assignTrucksToAllCrews ? colors.orange.status : colors.orange.accent}>
          <Icon source={Icon.Check} size={10} color={colors.orange.accent} />
        </Checkbox>
        <Space width={8} />
        <OptionMessage>Assign selected truck(s) to all jobs</OptionMessage>
      </OptionMessageContainer>
      <Space height={8} />
      {hasTooManySelections && (
        <React.Fragment>
          <SystemMessage isWarning>
            There are more selected trucks than the requested number of trucks.
          </SystemMessage>
          <Space height={8} />
        </React.Fragment>
      )}
      <ResponsivePopover.Footer
        handleClose={handleClose}
        handleSubmit={() => {
          setIsCloseable(false);
          handleSubmit();
        }}
        handleSubmitText={'Assign'}
        disabled={submitting}
        loading={submitting}
        submitButtonWidth={80}
      />
      <Space height={12} />
    </ResponsivePopover.Container>
  );
};

const CrewTrucksPopover = ({
  crewId,
  isOpen,
  handleOpen,
  handleClose,
  popoverRef,
  offset,
  refetch,
  refetchQueries,
  placement,
  isDispatchCloseable,
  setIsDispatchCloseable,
  shouldRunUpsertCostsAndBillingEngineAsync,
}) => {
  const {loading, data} = useQuery(CrewTrucksPopover.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      crewId,
    },
  });
  return (
    <ResponsivePopover
      placement={placement}
      isOpen={isOpen}
      handleOpen={handleOpen}
      handleClose={handleClose}
      reference={popoverRef}
      offset={offset}
      isClosableOutside={isDispatchCloseable}
    >
      <Loading loading={loading || !data}>
        {() => {
          return (
            <CrewTrucksContent
              crew={data.crew}
              handleClose={handleClose}
              refetch={refetch}
              refetchQueries={refetchQueries}
              setIsCloseable={setIsDispatchCloseable}
              shouldRunUpsertCostsAndBillingEngineAsync={shouldRunUpsertCostsAndBillingEngineAsync}
            />
          );
        }}
      </Loading>
    </ResponsivePopover>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
CrewTrucksPopover.propTypes = {
  isDispatchCloseable: PropTypes.bool,
  setIsDispatchCloseable: PropTypes.func,
};

CrewTrucksPopover.defaultProps = {
  isDispatchCloseable: true,
  setIsDispatchCloseable: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewTrucksPopover.query = gql`
  ${Crew.getJobTrucksCount.fragment}
  ${TrucksAndSlotsList.fragment}
  ${SetCrewSlotsToCrewForm.edit.fragment}

  query CrewTrucksPopover($crewId: Int!) {
    crew(crewId: $crewId) {
      id
      numberOfRequiredTrucks
      numberOfRequiredMovers
      organizationId
      job {
        id
        ...TrucksAndSlotsList
      }
      ...Crew_getJobTrucksCount
      ...SetCrewSlotsToCrewForm_edit
    }
  }
`;

export default CrewTrucksPopover;
