// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {User} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const Container = Styled.View`
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Row = Styled.View`
  flex-direction: row;
  padding-vertical: 20px;
  padding-horizontal: 20px;
`;

const Touchable = Styled.Touchable`
  ${({vars}) => (vars.desktop ? 'flex: 1;' : '')}
`;

const Link = Styled.H6`
  width: 150px;
  color: ${colors.blue.interactive};
  text-decoration-line: underline;
`;

const Text = Styled.H6`
  width: 150px;
  color: ${colors.gray.primary};
  text-align: right;
`;

const SalesPayableUserItem = ({isFirst, isLast, index, payableUser}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();

  return (
    <Container>
      <Line />
      <Row>
        <Touchable
          vars={responsive}
          onPress={() => {
            const {startDate, endDate} = params;
            const {
              user: {uuid},
            } = payableUser;
            navigator.push(`/payroll/sales/${uuid}?startDate=${startDate}&endDate=${endDate}`);
          }}
        >
          <Link numberOfLines={1} style={{width: 200}}>
            {User.getFullName(payableUser.user)}
          </Link>
        </Touchable>
        <Text numberOfLines={1}>{payableUser.jobsCount}</Text>
        <Text numberOfLines={1}>{Currency.display(payableUser.amount)}</Text>
        <Text numberOfLines={1}>{Currency.display(payableUser.total)}</Text>
        <Text numberOfLines={1}>{Currency.display(payableUser.additionalTotal)}</Text>
      </Row>
      {isLast && <Line />}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SalesPayableUserItem.fragment = gql`
  fragment SalesPayableUserItem on PayableUser {
    jobsCount
    amount
    total
    additionalTotal
    user {
      id
      uuid
      firstName
      lastName
    }
  }
`;

export default SalesPayableUserItem;
