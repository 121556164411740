/**
 * Component - v2.0.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Modal, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import BillingLibraryForm from '@shared/modules/Billing/forms/BillingLibraryForm';
import useCreateBillingLibraryMutation from '@shared/modules/Billing/hooks/useCreateBillingLibraryMutation';
import Field from 'modules/App/components/Field';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = Styled.View`
  width: 400px;
  border-radius: 16px;
  background-color: ${colors.white};
  padding-horizontal: 24px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading6}
`;

const Text = Styled.Text`
  ${Typography.Body2}
`;

const FooterRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const FooterButton = Styled.ButtonV2`
  width: 104px;
  height: 40px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: ${({color}) => color};
`;

const FooterButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${({color}) => color};
`;

const getOrganizationBillingLibraryOptions = ({organization}) => {
  const newLibraryOption = {label: 'Create a New Billing Library', value: 'NEW_BILLING_LIBRARY'};
  const billingLibraryOptions = organization.billingLibraries.map((billingLibrary) => {
    return {
      label: billingLibrary.name,
      value: billingLibrary.id,
    };
  });
  return [newLibraryOption, ...billingLibraryOptions];
};

const CreateOrganizationBillingLibraryModal = ({organization, isOpen, handleClose, refetch}) => {
  const billingLibraryForm = BillingLibraryForm.new({organizationId: organization.id});
  const {form, submitting, handleSubmit} = useCreateBillingLibraryMutation({
    billingLibraryForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Overlay pointerEvents={'box-none'}>
        <ContentContainer>
          <Space height={24} />
          <TitleText>Create Billing Library</TitleText>
          <Space height={20} />
          <Text>
            {`Create a new billing library from scratch or ` +
              `select an existing billing library to duplicate.`}
          </Text>
          <Space height={20} />
          <Field
            {...form}
            index={0}
            label={'Select Billing Library To Duplicate'}
            name={'billingLibraryForm.baseBillingLibraryId'}
            component={DropdownInput}
            input={{
              options: getOrganizationBillingLibraryOptions({organization}),
              placeholder: 'Select a billing library',
              setFieldValue: form.setFieldValue,
              style: {width: '100%'},
            }}
          />
          <Space height={20} />
          <Field
            {...form}
            index={1}
            label={'Billing Library Name'}
            name={'billingLibraryForm.name'}
            input={{
              required: !_.get(form.values, 'billingLibraryForm.name'),
              placeholder: 'Enter a billing library name',
            }}
          />
          <Space height={36} />
          <FooterRow>
            <FooterButton color={colors.white} onPress={handleClose}>
              <FooterButtonText color={colors.gray.secondary}>Cancel</FooterButtonText>
            </FooterButton>
            <FooterButton
              color={colors.blue.interactive}
              onPress={handleSubmit}
              disabled={submitting}
            >
              <FooterButtonText color={colors.white}>Save</FooterButtonText>
            </FooterButton>
          </FooterRow>
          <Space height={24} />
        </ContentContainer>
      </Overlay>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateOrganizationBillingLibraryModal.fragment = gql`
  fragment CreateOrganizationBillingLibraryModal on Organization {
    id
    billingLibraries {
      id
      name
    }
  }
`;

export default CreateOrganizationBillingLibraryModal;
