// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {DeviceInfo} from '@supermove/sdk';

// App
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import JobPageBlock from 'modules/Job/Show/components/JobPageBlock';

const PanelsContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const SidePanel = Styled.View`
  width: 290px;
  zIndex: 1;
`;

const CenterPanel = Styled.View`
  flex: 1;
`;

const JobPageBlocks = ({blocks, job, viewer, refetch, style}) => {
  const responsive = useResponsive();
  return (
    <ScrollView contentContainerStyle={style} style={{flex: 1}}>
      <Space height={responsive.mobile ? 8 : 16} />
      {blocks.map((block, index) => (
        <JobPageBlock
          key={index}
          index={index}
          block={block}
          job={job}
          viewer={viewer}
          refetch={refetch}
        />
      ))}
      <Space height={responsive.mobile ? 32 : 128} />
    </ScrollView>
  );
};

const JobPageContent = ({
  job,
  leftBlocks,
  centerBlocks,
  rightBlocks,
  mobileBlocks,
  viewer,
  refetch,
}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const isOverrideV1 = _.includes(Object.keys(params), 'overrideV1');
  const {isEnabledProjectPageV2, isEnabledProjectPageTablet, isEnabledProjectPageMobile} =
    job.organization.features;

  // Possibly navigate to the project page v2. JobPageContent is used in
  // prepare, in-progress, and completed job pages, so handling this here
  // will handle it on all those pages.
  useEffect(() => {
    if (!isOverrideV1) {
      if (
        (responsive.desktop && isEnabledProjectPageV2) ||
        (responsive.tablet && isEnabledProjectPageTablet) ||
        (responsive.mobile && isEnabledProjectPageMobile)
      ) {
        navigator.push(
          `/projects/${job.project.uuid}/view?${
            responsive.desktop
              ? `block=${ProjectBlockKind.Job.JOB_DETAILS}&jobUuid=${job.uuid}`
              : `block=${ProjectBlockKind.JOBS}`
          }`,
        );
      }
    }
  }, [responsive.desktop, responsive.tablet, responsive.mobile]); // eslint-disable-line react-hooks/exhaustive-deps

  if (responsive.mobile) {
    return (
      <JobPageBlocks
        blocks={mobileBlocks}
        job={job}
        viewer={viewer}
        refetch={refetch}
        style={{paddingHorizontal: 8}}
      />
    );
  }

  const browser = DeviceInfo.getBrowser();
  const hasObstructiveScrollbar = _.includes(['SAFARI', 'FIREFOX', 'CHROME'], browser);
  return (
    <PanelsContainer>
      <SidePanel data-test-id='job-page-side-panel-left'>
        <JobPageBlocks
          blocks={leftBlocks}
          job={job}
          viewer={viewer}
          refetch={refetch}
          style={{paddingLeft: 16, paddingRight: hasObstructiveScrollbar ? 12 : 8}}
        />
      </SidePanel>
      <CenterPanel data-test-id='job-page-center-panel'>
        <JobPageBlocks
          blocks={centerBlocks}
          job={job}
          viewer={viewer}
          refetch={refetch}
          style={{
            paddingLeft: hasObstructiveScrollbar ? 4 : 8,
            paddingRight: hasObstructiveScrollbar ? 12 : 8,
          }}
        />
      </CenterPanel>
      <SidePanel data-test-id='job-page-side-panel-right'>
        <JobPageBlocks
          blocks={rightBlocks}
          job={job}
          viewer={viewer}
          refetch={refetch}
          style={{paddingLeft: hasObstructiveScrollbar ? 4 : 8, paddingRight: 16}}
        />
      </SidePanel>
    </PanelsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobPageContent.fragment = gql`
  ${JobPageBlock.fragment}

  fragment JobPageContent_Block on Block {
    ...JobPageBlock_Block
  }

  fragment JobPageContent_Viewer on User {
    id
    ...JobPageBlock_Viewer
  }

  fragment JobPageContent_Job on Job {
    id
    uuid
    project {
      id
      uuid
    }
    organization {
      id
      features {
        isEnabledProjectPageV2: isEnabled(feature: "PROJECT_PAGE_V2")
        isEnabledProjectPageTablet: isEnabled(feature: "PROJECT_PAGE_TABLET")
        isEnabledProjectPageMobile: isEnabled(feature: "PROJECT_PAGE_MOBILE")
      }
    }
    ...JobPageBlock_Job
  }
`;

export default JobPageContent;
