// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';

const Text = Styled.Text`
  ${Typography.Body2}
`;

const DeleteVariableSectionModal = ({
  isOpen,
  handleClose,
  parentForm,
  sectionFormsField,
  variableSectionFormField,
  handleDelete,
}) => {
  const [variableDestinationField, setVariableDestinationField] = useState('');
  const existingSectionForms = _.get(parentForm.values, sectionFormsField);
  const getVariableSectionOptions = ({
    existingSectionForms,
    sectionFormsField,
    variableSectionFormField,
  }) => {
    return existingSectionForms
      .map(({name}, index) => ({
        label: name,
        value: `${sectionFormsField}[${index}]`,
      }))
      .filter((option) => option.value !== variableSectionFormField);
  };
  return (
    <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
      <SmallModal.HeaderText>{'Delete Section'}</SmallModal.HeaderText>
      <Space height={16} />
      <Text>
        {'Before deleting, select which section you would like to move the current variables to:'}
      </Text>
      <Space height={16} />
      <FieldInput
        component={DropdownInput}
        input={{
          required: true,
          placeholder: 'Select section',
          value: variableDestinationField,
          options: getVariableSectionOptions({
            existingSectionForms,
            sectionFormsField,
            variableSectionFormField,
          }),
          onChangeValue: (variableDestinationField) => {
            setVariableDestinationField(variableDestinationField);
          },
          setFieldValue: () => {},
          style: {flex: 1},
        }}
        style={{width: '100%'}}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <SmallModal.Button
          isDisabled={!variableDestinationField}
          onPress={() => handleDelete({variableDestinationField})}
          color={colors.blue.interactive}
        >
          Move & Delete
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default DeleteVariableSectionModal;
