const CENTS = 'CENTS';
const PERCENT = 'PERCENT';
const CENTS_PER_HOUR = 'CENTS_PER_HOUR';
const CENTS_PER_POUND = 'CENTS_PER_POUND';
const CENTS_PER_ONE_HUNDRED_POUNDS = 'CENTS_PER_ONE_HUNDRED_POUNDS';
const CENTS_PER_MILE = 'CENTS_PER_MILE';

const DISPLAY_VALUE_MAP = {
  [CENTS]: '$',
  [PERCENT]: '%',
  [CENTS_PER_HOUR]: '$/hr',
  [CENTS_PER_POUND]: '$/lb',
  [CENTS_PER_ONE_HUNDRED_POUNDS]: '$/100lb',
  [CENTS_PER_MILE]: '$/mi',
};

const getDisplayValue = (unit: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return DISPLAY_VALUE_MAP[unit];
};

export default {
  CENTS,
  PERCENT,
  CENTS_PER_HOUR,
  CENTS_PER_POUND,
  CENTS_PER_ONE_HUNDRED_POUNDS,
  CENTS_PER_MILE,

  VALUES: [
    CENTS,
    PERCENT,
    CENTS_PER_HOUR,
    CENTS_PER_POUND,
    CENTS_PER_ONE_HUNDRED_POUNDS,
    CENTS_PER_MILE,
  ],
  VALUES_NO_BILL_ITEM: [
    CENTS,
    PERCENT,
    CENTS_PER_POUND,
    CENTS_PER_ONE_HUNDRED_POUNDS,
    CENTS_PER_MILE,
  ],

  getDisplayValue,
};
