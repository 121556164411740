// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

import OrganizationTabletSettingsForm from '@shared/modules/Organization/forms/OrganizationTabletSettingsForm';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    organizationTabletSettingsForms: (organization as any).isPrimary
      ? (organization as any).company.organizations.map((organization: any) =>
          OrganizationTabletSettingsForm.edit(organization),
        )
      : [OrganizationTabletSettingsForm.edit(organization)],
  }),
  gql`
    ${OrganizationTabletSettingsForm.edit.fragment}

    fragment CompanyTabletSettingsForm_edit on Organization {
      id
      isPrimary
      company {
        id
        organizations {
          id
          ...OrganizationTabletSettingsForm_edit
        }
      }
      ...OrganizationTabletSettingsForm_edit
    }
  `,
);

const toForm = ({organizationId, organizationTabletSettingsForms}: any) => ({
  organizationId,
  organizationTabletSettingsForms: organizationTabletSettingsForms.map(
    (organizationTabletSettingsForm: any) =>
      OrganizationTabletSettingsForm.toForm(organizationTabletSettingsForm),
  ),
});

const toMutation = ({organizationId, organizationTabletSettingsForms}: any) => ({
  organizationId,
  organizationTabletSettingsForms: organizationTabletSettingsForms.map(
    (organizationTabletSettingsForm: any) =>
      OrganizationTabletSettingsForm.toMutation(organizationTabletSettingsForm),
  ),
});

const CompanyTabletSettingsForm = {
  edit,
  toForm,
  toMutation,
};

export default CompanyTabletSettingsForm;
