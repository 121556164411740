// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import ActionModal, {
  ActionModalProps,
} from '@shared/design/components/Modal/SmallModal/ActionModal';

// Caution modal is a subset of the ActionModal with the color and icon set
export type CautionModalProps = Pick<
  ActionModalProps,
  | 'handlePressOutside'
  | 'handlePrimaryAction'
  | 'handleSecondaryAction'
  | 'icon'
  | 'isMobileSheet'
  | 'isSubmitting'
  | 'isOpen'
  | 'message'
  | 'primaryActionText'
  | 'secondaryActionText'
  | 'title'
  | 'primaryActionFlexSize'
>;

const CautionModal = ({
  icon,
  title,
  message,
  isOpen,
  handlePressOutside,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionText,
  secondaryActionText,
  isSubmitting,
  isMobileSheet,
  primaryActionFlexSize = 1,
}: CautionModalProps) => {
  return (
    <ActionModal
      color={colors.orange.status}
      icon={icon}
      title={title}
      message={message}
      isOpen={isOpen}
      handlePressOutside={handlePressOutside}
      handlePrimaryAction={handlePrimaryAction}
      handleSecondaryAction={handleSecondaryAction}
      primaryActionText={primaryActionText}
      secondaryActionText={secondaryActionText}
      isSubmitting={isSubmitting}
      isMobileSheet={isMobileSheet}
      primaryActionFlexSize={primaryActionFlexSize}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
CautionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  icon: Icon.SourcePropType,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  handlePressOutside: PropTypes.func,
  handlePrimaryAction: PropTypes.func.isRequired,
  handleSecondaryAction: PropTypes.func,
  primaryActionText: PropTypes.string.isRequired,
  secondaryActionText: PropTypes.string,
  isMobileSheet: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

CautionModal.defaultProps = {
  icon: Icon.ExclamationTriangle,
  handlePressOutside: () => {},
  handleSecondaryAction: null,
  isMobileSheet: true,
  isSubmitting: undefined,
  message: undefined,
  secondaryActionText: '',
};

export default CautionModal;
