// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Currency} from '@supermove/utils';

// App
import SheetWithFooter from '@shared/design/components/Sheet/SheetWithFooter';
import DeletePaymentModal from 'modules/Bill/components/DeletePaymentModal';

const MobileLabelText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const MobileBodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const PaymentInfoTextSection = ({label, body}) => {
  return (
    <React.Fragment>
      <MobileLabelText>{label}</MobileLabelText>
      <MobileBodyText>{body}</MobileBodyText>
    </React.Fragment>
  );
};

const PaymentInfo = ({payment}) => {
  return (
    <React.Fragment>
      <PaymentInfoTextSection
        label={'Timestamp'}
        body={Datetime.convertToDisplayDatetime(payment.createdAt, Datetime.DISPLAY_DATETIME)}
      />
      <Space height={16} />
      <PaymentInfoTextSection label={'Payment Name'} body={payment.name} />
      <Space height={16} />
      <PaymentInfoTextSection label={'Method'} body={payment.displayMethod} />
      <Space height={16} />
      <PaymentInfoTextSection label={'Description'} body={payment.description || 'None'} />
      <Space height={16} />
      <PaymentInfoTextSection label={'Amount'} body={Currency.display(payment.totalAmount)} />
    </React.Fragment>
  );
};

// Note: This sheet currently is only used by Mobile
const PaymentSheet = ({payment, isOpen, handleClose, refetch}) => {
  const deletePaymentModal = useModal({name: 'Delete Payment Modal'});

  return (
    <React.Fragment>
      <SheetWithFooter
        isOpen={isOpen}
        handleClose={handleClose}
        headerText={'Payment'}
        handlePrimaryAction={deletePaymentModal.handleOpen}
        primaryActionText={'Delete'}
        primaryActionColor={colors.red.warning}
      >
        <PaymentInfo payment={payment} />
      </SheetWithFooter>
      <DeletePaymentModal
        key={`DeletePaymentModal_${payment.id}`}
        isOpen={deletePaymentModal.isOpen}
        payment={payment}
        handleClose={() => {
          deletePaymentModal.handleClose();
          handleClose();
        }}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PaymentSheet.fragment = gql`
  ${DeletePaymentModal.fragment}

  fragment PaymentSheet on Payment {
    id
    createdAt
    name
    displayMethod
    description
    totalAmount
    ...DeletePaymentModal
  }
`;

export default PaymentSheet;
