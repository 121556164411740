// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, ScrollView} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import JobDocumentContentRendererV1 from '@shared/modules/Document/components/JobDocumentContentRendererV1';

const DocumentTemplatePreviewContentContainer = Styled.View`
  padding: 32px;
`;

const TemplateContainer = Styled.View`
  padding: 8px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray.border};
  width: 800px;
  margin: 24px;
`;

const BlankEditorTemplatePreview = Styled.View`
  height: 100%;
  width: 100%;
  padding: 32px;
  justify-content: center;
  align-items: center;
`;

const BlankEditorTemplatePreviewText = Styled.Text`
  ${Typography.Heading4};
  color: ${colors.gray.tertiary};
  width: 400px;
  text-align: center;
`;

const DocumentTemplateVersionEditorTemplatePreviewV1 = ({
  documentContentJson,
  selectedDocumentItem,
  setSelectedDocumentItem,
}) => {
  // We first set the index to null or index so renderer can load correctly
  // if null we don't focus on any components in renderer
  // if index passes, we will match it with the correct index on the renderer
  const documentEditorSelectedIndex = _.isEmpty(selectedDocumentItem)
    ? null
    : selectedDocumentItem.index;
  return (
    <TemplateContainer>
      {!_.isEmpty(documentContentJson.documentItems) ? (
        <ScrollView style={{flex: 1}}>
          <DocumentTemplatePreviewContentContainer>
            <JobDocumentContentRendererV1
              documentContentJson={documentContentJson}
              isPreview
              isEditable={false}
              documentEditorSelectedIndex={documentEditorSelectedIndex}
              setSelectedDocumentItem={setSelectedDocumentItem}
            />
          </DocumentTemplatePreviewContentContainer>
        </ScrollView>
      ) : (
        <BlankEditorTemplatePreview>
          <BlankEditorTemplatePreviewText>
            {'Document items will display on this page as you add them.'}
          </BlankEditorTemplatePreviewText>
        </BlankEditorTemplatePreview>
      )}
    </TemplateContainer>
  );
};

export default DocumentTemplateVersionEditorTemplatePreviewV1;
