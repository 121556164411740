import _ from 'lodash';

import {OrganizationKindModel, WarehouseModel} from '@supermove/models';

// Sort in the following order, lower index is higher priority
const mapOrganizationKindToSortOrder: Record<OrganizationKindModel | 'DEFAULT', number> = {
  MAIN: 1,
  // Branch and contractor both appear as "Corporate" on the UI and should be sorted together
  BRANCH: 2,
  CONTRACTOR: 2,
  FRANCHISE: 4,
  DEFAULT: 5,
};

const sortByOrganizationKind = (warehouses: WarehouseModel[] = []) => {
  return _.sortBy(warehouses, [
    // If there's no branch kind, then put it at the end
    (warehouse) => mapOrganizationKindToSortOrder[warehouse.organization?.kind ?? 'DEFAULT'],
    (warehouse) => warehouse.name,
  ]);
};

const Warehouse = {
  sortByOrganizationKind,
};

export default Warehouse;
