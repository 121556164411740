// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, Icon, Space, Styled} from '@supermove/components';
import {UpdateSinglePropertyForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {
  useEffect,
  useDrawer,
  useHandleMutationAlertErrors,
  useNavigationDOM,
  useHover,
  useState,
  useToast,
} from '@supermove/hooks';
import {Organization, Project, Task} from '@supermove/models';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Checkbox from '@shared/design/components/Checkbox';
import Table from '@shared/design/components/TableV2Deprecated';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import TaskDueAtForm from '@shared/modules/TaskManagement/forms/TaskDueAtForm';
import useUpdateTaskDueAtMutation from '@shared/modules/TaskManagement/hooks/useUpdateTaskDueAtMutation';
import useUpdateTaskPropertyMutation from '@shared/modules/TaskManagement/hooks/useUpdateTaskPropertyMutation';
import InlineEditDropdownInput from 'modules/App/components/InlineEditDropdownInput';
import EditTaskDrawer from 'modules/TaskManagement/Tasks/components/EditTaskDrawer';
import TaskDueIcon from 'modules/TaskManagement/Tasks/components/TaskDueIcon';
import TaskIsCompletedCheckbox from 'modules/TaskManagement/Tasks/components/TaskIsCompletedCheckbox';
import useToggleTaskIsCompleted from 'modules/TaskManagement/Tasks/hooks/useToggleTaskIsCompleted';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SecondaryText = Styled.Text`
  ${Typography.Micro}
  color: ${({color}) => color};
`;

const CommentsCount = Styled.Text`
  ${Typography.Micro}
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const EditTaskText = Styled.Text`
  ${Typography.Micro}
  ${fontWeight(700)}
  color: ${colors.blue.interactive}
  padding-vertical: 8px;
`;

const handleCheckAllTasks = ({setSelectedTaskIds, isSelectedAllTasks, allTaskIds}) => {
  if (isSelectedAllTasks) {
    return setSelectedTaskIds([]);
  }
  return setSelectedTaskIds(allTaskIds);
};

const handleCheckTask = ({selectedTaskIds, setSelectedTaskIds, isSelected, taskId}) => {
  if (isSelected) {
    const taskIdsToKeep = _.difference(selectedTaskIds, [taskId]);
    return setSelectedTaskIds(taskIdsToKeep);
  }
  const mergedTaskIds = _.union(selectedTaskIds, [taskId]);
  return setSelectedTaskIds(mergedTaskIds);
};

const handleSubmitAssignee = ({form, field, value, handleSubmit}) => {
  const [kind, id] = value.split('-');
  if (kind === 'USER') {
    form.setFieldValue(`${field}.property`, Task.API_PROPERTY.assignedToUserId);
  }
  if (kind === 'TEAM') {
    form.setFieldValue(`${field}.property`, Task.API_PROPERTY.assignedToTeamId);
  }
  form.setFieldValue(`${field}.integerValue`, _.toNumber(id));
  setTimeout(handleSubmit, 0);
};

const BulkEditCheckbox = ({isChecked, onPress, style}) => {
  return <Checkbox isChecked={isChecked} handleToggle={onPress} style={style} />;
};

const ToggleIsCompletedButton = ({task}) => {
  const {handleSubmit, submitting} = useToggleTaskIsCompleted({task});

  return (
    <TaskIsCompletedCheckbox
      task={task}
      onPress={handleSubmit}
      isDisabled={submitting}
      style={{
        paddingVertical: 8,
        paddingRight: 8,
      }}
    />
  );
};

const SelectAllCheckbox = ({tasks, selectedTaskIds, setSelectedTaskIds}) => {
  const allTaskIds = tasks.map((task) => task.id);
  const isSelectedAllTasks = allTaskIds.length === selectedTaskIds.length;
  return (
    <BulkEditCheckbox
      isChecked={isSelectedAllTasks}
      onPress={() =>
        handleCheckAllTasks({
          setSelectedTaskIds,
          isSelectedAllTasks,
          allTaskIds,
        })
      }
      style={{paddingRight: 8}}
    />
  );
};

const SelectTaskCheckbox = ({task, selectedTaskIds, setSelectedTaskIds}) => {
  const isSelected = _.includes(selectedTaskIds, task.id);
  return (
    <BulkEditCheckbox
      isChecked={isSelected}
      onPress={() =>
        handleCheckTask({
          selectedTaskIds,
          setSelectedTaskIds,
          isSelected,
          taskId: task.id,
        })
      }
      style={{paddingVertical: 8, paddingRight: 8}}
    />
  );
};

const PrimaryText = ({text, task}) => {
  return (
    <Table.CellText style={task.isCompleted && {color: colors.gray.secondary}}>
      {text || '-'}
    </Table.CellText>
  );
};

const SubText = ({text}) => {
  return (
    <SecondaryText color={text ? colors.gray.secondary : colors.gray.tertiary} numberOfLines={1}>
      {text || '-'}
    </SecondaryText>
  );
};

const TaskName = ({task}) => {
  return (
    <Table.CellText>
      {task.isArchived && (
        <Icon
          source={Icon.Archive}
          color={colors.gray.secondary}
          size={12}
          style={{marginRight: 8}}
        />
      )}
      {task.name}
    </Table.CellText>
  );
};

const RecentComment = ({task}) => {
  return (
    <Row>
      <CommentsCount>{task.comments.length}</CommentsCount>
      <Space width={3} />
      <Icon
        source={Icon.CommentAlt}
        size={11}
        color={colors.gray.secondary}
        style={{marginTop: -1}}
      />
      <Space width={6} />
      <SubText text={'-'} />
      <Space width={6} />
      <SubText text={_.last(task.comments).body} />
    </Row>
  );
};

const EditDueDateButton = ({task}) => {
  const hasDueDate = !!task.dueAt;
  const taskDueAtForm = TaskDueAtForm.edit(task);
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Date updated!',
  });
  const errorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Error saving due date',
  });
  const {form, handleSubmit} = useUpdateTaskDueAtMutation({
    taskDueAtForm,
    onSuccess: successToast.handleToast,
    onError: (errors) => {
      errorToast.handleToast();
      console.log({errors});
    },
  });
  const dueDateText = task.dueAt
    ? Datetime.toDisplayDate(Datetime.fromDate(task.dueAt), 'MM/DD/YYYY')
    : '-';
  return (
    <Container style={{overflow: 'visible'}}>
      <Row>
        {task.isCompleted ? (
          <PrimaryText text={dueDateText} task={task} />
        ) : (
          <React.Fragment>
            <Table.CellText style={{color: colors.gray.tertiary, position: 'absolute'}}>
              MM/DD/YY
            </Table.CellText>
            <Row style={{marginLeft: -1}}>
              <DateInput
                value={Datetime.toFormDate(task.dueAt)}
                setFieldValue={form.setFieldValue}
                name={'taskDueAtForm.dueAtDate'}
                onChangeDate={() => setTimeout(handleSubmit, 0)}
                isPortaled
                isEnabledFlip
                style={{
                  padding: 0,
                  width: 78,
                  height: 20,
                  borderColor: colors.white,
                  marginLeft: -1,
                  backgroundColor: hasDueDate ? colors.white : 'transparent',
                }}
              />
            </Row>
          </React.Fragment>
        )}
        <Space width={4} />
        <TaskDueIcon task={task} size={12} />
      </Row>
      {hasDueDate && <SubText text={Task.getDisplayTime(task)} />}
    </Container>
  );
};

const EditTaskButton = ({task, refetch}) => {
  const editTaskDrawer = useDrawer({
    name: 'Edit Task Drawer',
    enableTracking: true,
  });

  // Where we would normally refetch in the edit task drawer, we'll
  // instead log that we want to refetch when the drawer closes. This
  // way the drawer will not suddenly disappear when there is a change
  // on the task that would cause the task to get filtered out of the
  // tasks table.
  const [refetchOnClose, setRefetchOnClose] = useState(false);
  useEffect(() => {
    if (refetchOnClose && !editTaskDrawer.isOpen) {
      setRefetchOnClose(false);
      refetch();
    }
  }, [editTaskDrawer, editTaskDrawer.isOpen, refetch, refetchOnClose]);

  return (
    <React.Fragment>
      <EditTaskText onPress={editTaskDrawer.handleOpen}>View/Edit</EditTaskText>
      <EditTaskDrawer
        isOpen={editTaskDrawer.isOpen}
        handleClose={editTaskDrawer.handleClose}
        task={task}
        refetch={() => setRefetchOnClose(true)}
      />
    </React.Fragment>
  );
};

const AssigneeSelector = ({task, organization}) => {
  const field = 'updateSinglePropertyForm';

  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Assignee updated!',
  });
  const alertHandler = useHandleMutationAlertErrors({ToastComponent: ErrorToast});

  const isAssignedToTeam = !!task.assignedToTeamId;
  const updateSinglePropertyForm = UpdateSinglePropertyForm.new({
    id: task.id,
    property: isAssignedToTeam
      ? Task.API_PROPERTY.assignedToTeamId
      : Task.API_PROPERTY.assignedToUserId,
    stringValue: Task.getAssigneeDropdownValue(task),
  });
  const {form, handleSubmit} = useUpdateTaskPropertyMutation({
    updateSinglePropertyForm,
    onSuccess: successToast.handleToast,
    onError: (errors) => {
      console.log({errors});
      alertHandler.handleMutationAlertErrors({errors});
    },
  });

  const assigneeOptions = organization
    ? Organization.getTaskAssigneeDropdownOptions(organization)
    : [];
  const showDescriptionInOption = _.get(
    organization,
    'features.isEnabledShowSalespersonsFromAllOrganizations',
  );

  return (
    <InlineEditDropdownInput
      options={assigneeOptions}
      form={form}
      field={`${field}.stringValue`}
      handleSubmit={(value) => handleSubmitAssignee({form, field, value, handleSubmit})}
      isDisabled={task.isCompleted}
      placeholder={'Select assignee'}
      showDescriptionInOption={showDescriptionInOption}
    />
  );
};

const TaskProjectButton = ({task}) => {
  const {project} = task;
  const {navigator} = useNavigationDOM();
  const {ref, isHovered} = useHover();
  const openProject = () => navigator.pushNewTab(`/projects/${project.uuid}`);
  return (
    <Container style={{flex: 1}}>
      <Row ref={ref}>
        {isHovered && (
          <Icon
            source={Icon.ExternalLinkAlt}
            size={11}
            color={colors.blue.interactive}
            style={{position: 'absolute', left: -16}}
          />
        )}
        <Table.CellLink onPress={openProject}>{`Project ${project.identifier}`}</Table.CellLink>
      </Row>
      <SubText text={_.get(project, 'client.name')} />
    </Container>
  );
};

const getTasksTableColumnDefinitions = ({
  refetch,
  tasks,
  isBulkEditEnabled,
  selectedTaskIds,
  setSelectedTaskIds,
  organization,
}) => {
  const columnDefinitions = [
    {
      width: 32,
      isHidden: !isBulkEditEnabled,
      headerContent: () => {
        return (
          <SelectAllCheckbox
            tasks={tasks}
            selectedTaskIds={selectedTaskIds}
            setSelectedTaskIds={setSelectedTaskIds}
          />
        );
      },
      cellContent: ({item: task}) => {
        return (
          <SelectTaskCheckbox
            task={task}
            selectedTaskIds={selectedTaskIds}
            setSelectedTaskIds={setSelectedTaskIds}
          />
        );
      },
    },
    {
      width: 56,
      headerContent: () => {
        return <Table.HeaderText>Status</Table.HeaderText>;
      },
      cellContent: ({item: task}) => {
        return <ToggleIsCompletedButton task={task} />;
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <Table.HeaderText>Task Name</Table.HeaderText>;
      },
      cellContent: ({item: task}) => {
        return (
          <Container style={{flex: 1}}>
            <TaskName key={task.name} task={task} refetch={refetch} />
            {!_.isEmpty(task.comments) && <RecentComment task={task} />}
          </Container>
        );
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText>Due Date</Table.HeaderText>;
      },
      cellContent: ({item: task}) => {
        return <EditDueDateButton task={task} refetch={refetch} />;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText>Assignee</Table.HeaderText>;
      },
      cellContent: ({item: task}) => {
        return <AssigneeSelector task={task} organization={organization} />;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText>Project Name</Table.HeaderText>;
      },
      cellContent: ({item: task}) => {
        return <TaskProjectButton task={task} />;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText>Project Type</Table.HeaderText>;
      },
      cellContent: ({item: task}) => {
        return <PrimaryText text={_.get(task, 'project.projectType.name')} task={task} />;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText>Project Status</Table.HeaderText>;
      },
      cellContent: ({item: task}) => {
        const status = Project.getProjectStatus(task.project);
        return <PrimaryText text={status} task={task} />;
      },
    },
    {
      flex: 0.5,
      headerContent: () => {
        return <Table.HeaderText>Actions</Table.HeaderText>;
      },
      cellContent: ({item: task}) => {
        return <EditTaskButton task={task} refetch={refetch} />;
      },
    },
  ];

  return columnDefinitions;
};

const TasksTable = ({
  tasks,
  organization,
  loading,
  isBulkEditEnabled,
  selectedTaskIds,
  setSelectedTaskIds,
  EmptyStateComponent,
  refetch,
}) => {
  return (
    <Table.FixedHeaderScroll
      columnDefinitions={getTasksTableColumnDefinitions({
        refetch,
        tasks,
        isBulkEditEnabled,
        selectedTaskIds,
        setSelectedTaskIds,
        organization,
      })}
      emptyStateText='No tasks'
      EmptyStateComponent={EmptyStateComponent}
      items={tasks}
      itemKey={'id'}
      loading={loading}
      isDense
      calculateRowStyle={({item: task}) => ({
        backgroundColor: task.isCompleted ? colors.gray.background : colors.white,
      })}
      scrollViewStyle={{paddingBottom: 300}}
      containerStyle={{overflow: 'visible'}}
      headerStyle={{
        backgroundColor: colors.white,
        borderWidth: 1,
        borderColor: colors.gray.border,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        height: 34,
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TasksTable.fragment = gql`
  ${EditTaskDrawer.fragment}
  ${Project.getProjectStatus.fragment}
  ${TaskDueAtForm.edit.fragment}
  ${TaskDueIcon.fragment}
  ${Task.getAssigneeDropdownValue.fragment}
  ${useToggleTaskIsCompleted.fragment}

  ${Organization.getTaskAssigneeDropdownOptions.fragment}
  fragment TasksTable on Task {
    id
    assignedToUserId
    assignedToTeamId
    name
    dueAt
    isCompleted
    isArchived
    comments {
      id
      body
    }
    assignedToUser {
      id
      fullName
    }
    project {
      id
      uuid
      identifier
      client {
        id
        name
      }
      projectType {
        id
        name
      }
      ...Project_getProjectStatus
    }
    ...EditTaskDrawer
    ...TaskDueAtForm_edit
    ...TaskDueIcon
    ...Task_getAssigneeDropdownValue
    ...useToggleTaskIsCompleted
  }

  fragment TasksTable_Organization on Organization {
    id
    features {
      isEnabledShowSalespersonsFromAllOrganizations: isEnabled(
        feature: "SHOW_SALESPERSONS_FROM_ALL_ORGANIZATIONS"
      )
    }
    ...Organization_getTaskAssigneeDropdownOptions
  }
`;

export default TasksTable;
