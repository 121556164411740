// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getRecipientValue = (recipient: any) => {
  if (recipient.userId) {
    return String(recipient.userId);
  }
  if (recipient.role) {
    return recipient.role;
  }
  if (recipient.email) {
    return recipient.email;
  }
};

const _new = ({userId, role, email}: any) => ({
  userId,
  role,
  email,
});

const edit = withFragment(
  (emailRecipient) => ({
    userId: (emailRecipient as any).userId,
    role: (emailRecipient as any).role,
    email: (emailRecipient as any).email,
  }),
  gql`
    fragment EmailTemplateRecipientForm_edit on EmailTemplateRecipient {
      userId
      role
      email
    }
  `,
);

const toForm = ({userId, role, email}: any) => ({
  userId,
  role,
  email,
});

const toMutation = ({userId, role, email}: any) => ({
  userId,
  role,
  email,
});

const EmailTemplateRecipientForm = {
  new: _new,
  edit,
  toForm,
  toMutation,

  getRecipientValue,
};

export default EmailTemplateRecipientForm;
