// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Tabs} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const TabText = Styled.Text`
  ${Typography.Label1};
  color: ${colors.blue.interactive};
;`;

const SelectedTabBottomBorder = Styled.View`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-width: 1px;
  border-radius: 1px;
  border-color: ${colors.blue.interactive};
`;

const getIntegrationsPageTabs = () => {
  const baseTabs = [
    {
      label: 'WEBHOOKS',
      url: `/settings/integrations/webhooks`,
    },
  ];

  return [...baseTabs];
};

const Tab = ({tab, isSelected}) => {
  const {navigator} = useNavigationDOM();

  return (
    <Tabs.TabContainer
      onPress={() => navigator.push(tab.url)}
      isSelected={isSelected}
      style={{
        marginRight: 24,
        borderBottomWidth: 0,
      }}
    >
      <TabText color={isSelected ? colors.blue.interactive : colors.gray.secondary}>
        {tab.label}
        {isSelected && <SelectedTabBottomBorder />}
      </TabText>
    </Tabs.TabContainer>
  );
};

const IntegrationsPageNavigationTabs = () => {
  const {navigator} = useNavigationDOM();
  const tabs = getIntegrationsPageTabs();
  const currentTab = _.findIndex(tabs, {url: navigator.location.pathname});

  return (
    <Tabs.TabBar
      selectedIndex={currentTab}
      handleChangeIndex={() => {}}
      TabComponent={Tab}
      style={{
        borderBottomWidth: 1,
        borderColor: colors.gray.border,
      }}
    >
      {tabs.map(({label, url}) => (
        <Tabs.Tab key={label} tab={{label, url}} />
      ))}
    </Tabs.TabBar>
  );
};

export default IntegrationsPageNavigationTabs;
