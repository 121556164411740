// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';

const useCreateMoveProjectMutation = ({projectForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      projectForm: ProjectForm.toForm(projectForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateMoveProjectMutation.mutation,
    variables: {
      projectForm: ProjectForm.toMutation(form.values.projectForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateMoveProjectMutation.mutation = gql`
  mutation useCreateMoveProjectMutation($projectForm: ProjectForm!) {
    response: createMoveProject(projectForm: $projectForm) {
      ${gql.errors}
      project {
        id
        uuid
      }
    }
  }
`;

export default useCreateMoveProjectMutation;
