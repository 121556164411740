// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    notificationEmails: (organization as any).settings.notificationEmails,
    // private
    searchInputValue: '',
  }),
  gql`
    fragment OrganizationNotificationEmailsForm_edit on Organization {
      id
      settings {
        id
        notificationEmails
      }
    }
  `,
);

const toForm = ({organizationId, notificationEmails, searchInputValue}: any) => ({
  organizationId,
  notificationEmails,

  // private
  searchInputValue,
});

const toMutation = ({organizationId, notificationEmails}: any) => ({
  organizationId,
  notificationEmails,
});

const OrganizationNotificationEmailsForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationNotificationEmailsForm;
