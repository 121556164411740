// Libraries
import React from 'react';

// App
import {DateInput, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import {SidebarPage} from 'modules/App/components';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import {CrewPayableUserDaysList} from 'modules/Payroll/Crew/PayableUser/Show/components';
import {DateRangeHandler} from 'modules/Payroll/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-top: 20px;
  margin-bottom: 80px;
  margin-horizontal: ${(props) => (props.mobile ? 12 : 30)}px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const BackLink = Styled.Touchable`
`;

const BackLinkText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
`;

const Header = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  align-items: center;
  z-index: 100;
  margin-top: 10px;
`;

const Info = Styled.View`
  ${(props) => (props.mobile ? 'margin-bottom: 20px;' : 'flex: 1;')}
`;

const Title = Styled.H2`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: ${({vars}) => (vars.mobile ? 'center' : 'left')};
`;

const Subtitle = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: ${({vars}) => (vars.mobile ? 'center' : 'left')};
`;

const Dates = Styled.View`
  flex-direction: row;
`;

const DateWrapper = Styled.View`
`;

const DateSpace = Styled.View`
  width: 10px;
`;

const Days = Styled.View`
  margin-top: 40px;
`;

const ShowPayableUserCrewPayrollPage = ({
  match: {
    params: {uuid},
  },
}) => {
  return (
    <DateRangeHandler
      getDefaultStartDate={() => Datetime.today.subtract(6, 'days')}
      getDefaultEndDate={({startDate}) => startDate.clone().add(6, 'days')}
    >
      {({startDate, endDate, setStartDate, setEndDate}) => (
        <SidebarPage
          selected={'payroll'}
          variables={{
            uuid,
            startDate: Datetime.toMutationDate(startDate),
            endDate: Datetime.toMutationDate(endDate),
          }}
          query={ShowPayableUserCrewPayrollPage.query}
        >
          {({responsive, navigator, data, refetch}) => (
            <Container>
              <StandardOfficeHeader title={'Payroll'} />
              <ScrollView style={{flex: 1}}>
                <Content {...responsive}>
                  <Row>
                    <BackLink onPress={() => navigator.goBack()}>
                      <BackLinkText>← Back</BackLinkText>
                    </BackLink>
                  </Row>
                  <Header {...responsive}>
                    <Info {...responsive}>
                      <Title vars={responsive}>
                        {`Payroll for ${User.getFullName(data.payableUser.user)}`}
                      </Title>
                      <Subtitle vars={responsive}>
                        {`View hours worked for ` +
                          `${Datetime.toDisplayDate(startDate, 'MMMM Do, YYYY')} - ` +
                          `${Datetime.toDisplayDate(endDate, 'MMMM Do, YYYY')}`}
                      </Subtitle>
                    </Info>
                    <Dates>
                      <DateWrapper>
                        <DateInput
                          value={startDate}
                          setFieldValue={(name, date) => setStartDate(date)}
                        />
                      </DateWrapper>
                      <DateSpace />
                      <DateWrapper>
                        <DateInput
                          value={endDate}
                          setFieldValue={(name, date) => setEndDate(date)}
                        />
                      </DateWrapper>
                    </Dates>
                  </Header>
                  <Days>
                    <CrewPayableUserDaysList
                      payableUserDays={data.payableUser.payableUserDays}
                      refetch={refetch}
                    />
                  </Days>
                </Content>
              </ScrollView>
            </Container>
          )}
        </SidebarPage>
      )}
    </DateRangeHandler>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowPayableUserCrewPayrollPage.query = gql`
  ${CrewPayableUserDaysList.fragment}

  query ShowPayableUserCrewPayrollPage($uuid: String!, $startDate: String!, $endDate: String!) {
    ${gql.query}
    payableUser(uuid: $uuid, startDate: $startDate, endDate: $endDate) {
      user {
        id
        firstName
        lastName
      }
      payableUserDays {
        id
        ...CrewPayableUserDaysList
      }
    }
  }
`;

export default ShowPayableUserCrewPayrollPage;
