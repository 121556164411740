// Supermove
import {Datetime} from '@supermove/utils';

const UPON_RECEIPT = 'UPON_RECEIPT';
const NO_DUE_DATE = 'NO_DUE_DATE';
const NET_15 = 'NET_15';
const NET_30 = 'NET_30';
const NET_45 = 'NET_45';
const NET_60 = 'NET_60';
const NET_90 = 'NET_90';

const VALUES = [UPON_RECEIPT, NO_DUE_DATE, NET_15, NET_30, NET_45, NET_60, NET_90];

const VALUE_LABELS: Record<string, string> = {
  UPON_RECEIPT: 'On receipt',
  NO_DUE_DATE: 'No due date',
  NET_15: 'Net 15',
  NET_30: 'Net 30',
  NET_45: 'Net 45',
  NET_60: 'Net 60',
  NET_90: 'Net 90',
};

const PAYMENT_TERM_VALUES: Record<string, null | number> = {
  UPON_RECEIPT: null,
  NO_DUE_DATE: null,
  NET_15: 15,
  NET_30: 30,
  NET_45: 45,
  NET_60: 60,
  NET_90: 90,
};

const getDisplayName = (paymentTerm: string) => {
  return VALUE_LABELS[paymentTerm];
};

const getPaymentTermValues = (date: string, paymentTerm: string) => {
  if (paymentTerm === UPON_RECEIPT) {
    return date;
  }
  return PAYMENT_TERM_VALUES[paymentTerm];
};

const getNextDateWithPaymentTerm = (date: string, paymentTerm: string) => {
  const paymentTermValue = getPaymentTermValues(date, paymentTerm);
  if (!paymentTermValue) {
    return null;
  }
  return Datetime.addDaysToDate(date, paymentTermValue);
};

export default {
  UPON_RECEIPT,
  NO_DUE_DATE,
  NET_15,
  NET_30,
  NET_45,
  NET_60,
  NET_90,

  VALUES,

  DROPDOWN_OPTIONS: VALUES.map((value) => ({value, label: VALUE_LABELS[value]})),

  getDisplayName,
  getPaymentTermValues,
  getNextDateWithPaymentTerm,
};
