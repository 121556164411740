// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Line from 'modules/App/components/Line';

const Container = Styled.View`
`;

const HeaderRow = Styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-horizontal: 24px;
`;

const HeaderItemContainer = Styled.View`
  flex: ${({flex}) => flex};
  align-items: ${({align}) => align};
`;

const HeaderText = Styled.Text`
  ${Typography.Heading2};
`;

const Subtitle = Styled.Text`
  ${Typography.Micro}
  align-self: center;
`;

const Button = Styled.ButtonV2`
  padding-horizontal: 20px;
  padding-vertical: 6px;
  border-radius: 4px;
  background-color: ${colors.blue.interactive};
`;

const ButtonText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.white};
`;

const BackButtonContainer = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const BackButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.primary};
  padding-top: 2px;
`;

const BackButton = () => {
  const {navigator} = useNavigationDOM();
  return (
    <BackButtonContainer onPress={() => navigator.goBack()}>
      <Icon source={Icon.ArrowLeft} size={15} color={colors.gray.primary} />
      <Space width={8} />
      <BackButtonText>Back</BackButtonText>
    </BackButtonContainer>
  );
};

const SimpleHeader = ({
  title,
  subtitle,
  showBackButton,
  showActionButton,
  showLine,
  actionButtonText,
  onPress,
  numberOfLines,
}) => {
  return (
    <Container>
      <Space height={16} />
      <HeaderRow>
        <HeaderItemContainer flex={1} align={'flex-start'}>
          {showBackButton && <BackButton />}
        </HeaderItemContainer>
        <HeaderItemContainer flex={3} align={'center'}>
          <HeaderText numberOfLines={numberOfLines}>{title}</HeaderText>
        </HeaderItemContainer>
        <HeaderItemContainer flex={1} align={'flex-end'}>
          {showActionButton && (
            <Button onPress={onPress}>
              <ButtonText>{actionButtonText}</ButtonText>
            </Button>
          )}
        </HeaderItemContainer>
      </HeaderRow>
      {subtitle && (
        <React.Fragment>
          <Space height={8} />
          <Subtitle>{subtitle}</Subtitle>
        </React.Fragment>
      )}
      <Space height={16} />
      {showLine && <Line />}
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SimpleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  showLine: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showActionButton: PropTypes.bool,
  actionButtonText: PropTypes.string,
  onPress: PropTypes.func,
};

SimpleHeader.defaultProps = {
  showBackButton: false,
  showLine: true,
  showActionButton: false,
  actionButtonText: 'Save',
  onPress: () => {},
};

export default SimpleHeader;
