// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useNavigationDOM, useState, useToast} from '@supermove/hooks';
import {Linking} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';
import {withFragment} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Toast from '@shared/design/components/Toast';
import ProjectWidgetKind from '@shared/modules/Project/enums/ProjectWidgetKind';
import Line from 'components/Line';
import EditProjectClientsDrawer from 'modules/Project/V2/Show/Blocks/components/EditProjectClientsDrawer';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const MobileBodyText = Styled.Text`
  ${Typography.Mobile.Body};
`;

const MobileMicroLabelText = Styled.Text`
  ${Typography.Mobile.MicroLabel};
`;

const MobileMicroText = Styled.Text`
  ${Typography.Mobile.Micro};
`;

const IconButton = Styled.ButtonV2`
  padding: 8px;
`;

const ClientFields = ({title, client, isBilling}) => {
  return (
    <React.Fragment>
      <MobileMicroLabelText>{title}</MobileMicroLabelText>
      <Space height={4} />
      {isBilling && !client ? (
        <MobileBodyText>Same as client</MobileBodyText>
      ) : (
        <React.Fragment>
          <MobileBodyText>{client.primaryContact.fullName}</MobileBodyText>
          {client.name && (
            <React.Fragment>
              <Space height={2} />
              <MobileMicroText>{client.name}</MobileMicroText>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ClientButtons = ({project, client, urlFilters, isBilling}) => {
  const {phoneNumber} = client.primaryContact;

  const {navigator} = useNavigationDOM();
  const editProjectClientsDrawer = useDrawer({name: 'Edit Project Clients Drawer'});
  const [focusField, setFocusField] = useState();
  const missingPhoneNumberToast = useToast({
    ToastComponent: Toast,
    message: 'Phone number is missing.',
    actionText: 'Add Phone Number',
    handleAction: () => {
      if (isBilling) {
        setFocusField(EditProjectClientsDrawer.FOCUS_FIELD.BILLING_CLIENT_PHONE);
      } else {
        setFocusField(EditProjectClientsDrawer.FOCUS_FIELD.PRIMARY_CLIENT_PHONE);
      }
      setTimeout(() => {
        editProjectClientsDrawer.handleOpen();
      }, 0);
    },
    isClosable: true,
  });

  return (
    <Row>
      <IconButton
        onPress={() =>
          phoneNumber
            ? Linking.openURL(`tel:${phoneNumber}`)
            : missingPhoneNumberToast.handleToast()
        }
      >
        <Icon source={Icon.PhoneAlt} size={16} color={colors.blue.interactive} />
      </IconButton>
      <Space width={8} />
      <IconButton onPress={() => urlFilters.handleUpdate({widget: ProjectWidgetKind.SMS}, true)}>
        <Icon source={Icon.CommentSms} size={16} color={colors.blue.interactive} />
      </IconButton>
      <Space width={8} />
      <IconButton onPress={() => navigator.push(`/clients/${client.uuid}`)}>
        <Icon source={Icon.InfoCircle} size={16} color={colors.blue.interactive} />
      </IconButton>
      <EditProjectClientsDrawer
        key={editProjectClientsDrawer.key}
        isOpen={editProjectClientsDrawer.isOpen}
        handleClose={() => {
          editProjectClientsDrawer.handleClose();
          setFocusField();
        }}
        project={project}
        focusField={focusField}
      />
    </Row>
  );
};

const ClientInformation = withFragment(
  ({title, project, client, isBilling, urlFilters}) => {
    return (
      <Row style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <Column style={{flex: 1}}>
          <ClientFields title={title} client={client} isBilling={isBilling} />
        </Column>
        <Space width={16} />
        <Column>
          {client && (
            <ClientButtons
              project={project}
              client={client}
              urlFilters={urlFilters}
              isBilling={isBilling}
            />
          )}
        </Column>
      </Row>
    );
  },
  gql`
    fragment ProjectClientsBlockContentMobile_ClientInformation on Client {
      id
      uuid
      name
      primaryContact {
        id
        fullName
        phoneNumber
      }
    }
  `,
);

const EditClientsButton = ({project}) => {
  const editProjectClientsDrawer = useDrawer({name: 'Edit Project Clients Drawer'});

  return (
    <React.Fragment>
      <TertiaryButton
        text={`Edit`}
        onPress={editProjectClientsDrawer.handleOpen}
        isResponsive
        isHitSlop
      />
      <EditProjectClientsDrawer
        key={editProjectClientsDrawer.key}
        isOpen={editProjectClientsDrawer.isOpen}
        handleClose={editProjectClientsDrawer.handleClose}
        project={project}
      />
    </React.Fragment>
  );
};

const ProjectClientsBlockContentMobile = ({project, isSameBillingClient, urlFilters}) => {
  return (
    <Column>
      <MobileProjectBlockHeader
        title={`Client & Billing Information`}
        ActionsComponent={EditClientsButton}
        actionsComponentProps={{project}}
      />
      <ClientInformation
        title={`Client Information`}
        project={project}
        client={project.client}
        urlFilters={urlFilters}
      />
      <Space height={16} />
      <Line />
      <Space height={16} />
      <ClientInformation
        title={`Billing Information`}
        project={project}
        client={isSameBillingClient ? null : project.billingClient}
        urlFilters={urlFilters}
        isBilling
      />
    </Column>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectClientsBlockContentMobile.fragment = gql`
  ${ClientInformation.fragment}
  ${EditProjectClientsDrawer.fragment}

  fragment ProjectClientsBlockContentMobile on Project {
    id
    client {
      id
      ...ProjectClientsBlockContentMobile_ClientInformation
    }
    billingClient {
      id
      ...ProjectClientsBlockContentMobile_ClientInformation
    }
    ...EditProjectClientsDrawer
  }
`;

export default ProjectClientsBlockContentMobile;
