/* eslint-disable max-classes-per-file */
import {Visitor} from './Visitor';
import {Token} from './tokenizer';

export interface ExpressionJSON {
  kind: string;
  name: string;
  args: ExpressionJSON[];
  operator: Token;
  value: any;
  expression: ExpressionJSON;
}

export default class Expression {
  kind: string;

  value?: any;

  constructor(kind: string, value?: any) {
    this.kind = kind;
    if (value) {
      this.value = value;
    }
  }

  accept = (visitor: Visitor) => {
    return visitor.visitExpression(this);
  };

  static fromJSON = (expressionJSON: ExpressionJSON) => {
    const ExpressionKinds: {
      [expressionKind: string]: (expressionJSON: ExpressionJSON) => Expression;
    } = {
      Expression: ({kind, value}) => new Expression(kind, value),
      CallExpression: ({name, args}) =>
        new CallExpression(
          name,
          args.map((arg) => Expression.fromJSON(arg)),
        ),
      GroupingExpression: ({expression}) => new GroupingExpression(Expression.fromJSON(expression)),
      IdentifierExpression: ({value}) => new IdentifierExpression(value),
      NumberLiteralExpression: ({value}) => new NumberLiteralExpression(value),
      StringLiteralExpression: ({value}) => new StringLiteralExpression(value),
      SymbolExpression: ({value}) => new SymbolExpression(value),
      OperatorExpression: ({operator, args}) =>
        new OperatorExpression(
          operator,
          args.map((arg) => Expression.fromJSON(arg)),
        ),
    };
    const kind = ExpressionKinds[expressionJSON.kind];
    if (kind) {
      return kind(expressionJSON);
    }
    return new Expression(expressionJSON.kind, expressionJSON.value);
  };
}

export class GroupingExpression extends Expression {
  expression: Expression;

  constructor(expression: Expression) {
    super('GroupingExpression');
    this.expression = expression;
  }

  accept = (visitor: Visitor) => {
    return visitor.visitGrouping(this);
  };
}

export class IdentifierExpression extends Expression {
  value: any;

  constructor(value: any) {
    super('IdentifierExpression');
    this.value = value;
  }

  accept = (visitor: Visitor) => {
    return visitor.visitIdentifier(this);
  };
}
export class NumberLiteralExpression extends Expression {
  value: number;

  constructor(value: number) {
    super('NumberLiteralExpression');
    this.value = value;
  }

  accept = (visitor: Visitor) => {
    return visitor.visitNumberLiteral(this);
  };
}

export class StringLiteralExpression extends Expression {
  value: string;

  constructor(value: string) {
    super('StringLiteralExpression');
    this.value = value;
  }

  accept = (visitor: Visitor) => {
    return visitor.visitStringLiteral(this);
  };
}

export class SymbolExpression extends Expression {
  value: any;

  constructor(value: any) {
    super('SymbolExpression');
    this.value = value;
  }

  accept = (visitor: Visitor) => {
    return visitor.visitSymbol(this);
  };
}

export class CallExpression extends Expression {
  name: string;

  args: Expression[];

  constructor(name: string, args: Expression[]) {
    super('CallExpression');
    this.name = name;
    this.args = args;
  }

  accept = (visitor: Visitor) => {
    return visitor.visitCall(this);
  };
}

export class OperatorExpression extends Expression {
  operator: Token;

  args: Expression[];

  constructor(operator: Token, args: Expression[]) {
    super('OperatorExpression');
    this.operator = operator;
    this.args = args;
  }

  accept = (visitor: Visitor) => {
    return visitor.visitOperator(this);
  };
}
