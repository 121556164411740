// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive, useSheet, useState, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Checkbox from '@shared/design/components/Checkbox';
import Toast from '@shared/design/components/Toast';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import EditInventoryItemSheet from 'modules/Inventory/Edit/components/EditInventoryItemSheet';
import InventoryItemActionSheet from 'modules/Inventory/Edit/components/InventoryItemActionSheet';
import InventoryStepper from 'modules/Inventory/Edit/components/InventoryStepper';

const Card = Styled.ButtonV2`
  padding-horizontal: 24px;
  padding-vertical: 12px;
  background-color: ${colors.white};
`;

const CheckboxContainer = Styled.View`
  justify-content: center;
  height: 32px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Name = Styled.Text`
  ${Typography.Responsive.Label}
`;

const Note = Styled.Text`
  ${({vars: {isEmpty}}) =>
    isEmpty ? Typography.Responsive.MicroLink : Typography.Responsive.Micro}
  color: ${({vars: {isEmpty}}) => (isEmpty ? colors.gray.tertiary : colors.gray.secondary)};
  text-decoration-color: ${({vars: {isEmpty}}) =>
    isEmpty ? colors.gray.tertiary : colors.gray.secondary};
`;

const Button = Styled.ButtonV2`
  padding-vertical: 4px;
  justify-content: center;
`;

const IconButton = Styled.ButtonV2`
  padding-left: 16px;
  padding-vertical: 8px;
`;

const PillButton = Styled.ButtonV2`
  height: 32px;
  padding-vertical: 6px;
  padding-horizontal: 12px;
  border-radius: 24px;
  border-color: ${colors.gray.border};
  border-width: 1px;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.gray.background};
`;

const PillText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const StepperContainer = Styled.View`
  width: 120px;
`;

const ItemAttributePill = ({label, icon, onPress, responsive, isCompact}) => {
  return (
    <PillButton onPress={onPress}>
      {!isCompact && (
        <React.Fragment>
          <Icon source={icon} size={12} color={colors.blue.interactive} />
          <Space width={8} />
        </React.Fragment>
      )}
      <PillText responsive={responsive}>{label}</PillText>
    </PillButton>
  );
};

const TakeCheckbox = ({itemForm, inventoryRoomsForm, roomItemsFormIndex}) => {
  return (
    <CheckboxContainer>
      <Checkbox
        isResponsive
        isChecked={itemForm.take}
        label={'Take'}
        handleToggle={(isChecked) => {
          InventoryRoomsForm.handleTakeToggle({
            inventoryRoomsForm,
            isChecked,
            roomItemsFormIndex,
            itemIndex: itemForm.index,
          });
        }}
        checkboxStyle={{alignSelf: 'center', marginTop: 0}}
      />
    </CheckboxContainer>
  );
};

const Stepper = ({inventoryRoomsForm, itemForm, roomItemsFormIndex, onPress, removeItemToast}) => {
  return (
    <StepperContainer>
      <InventoryStepper
        handleDecrease={() => {
          const newQuantity = InventoryRoomsForm.handleQuantityChange({
            inventoryRoomsForm,
            quantity: -1,
            item: itemForm,
            roomItemsFormIndex,
            itemIndex: itemForm.index,
          });
          if (newQuantity === 0) {
            InventoryRoomsForm.handleDeleteToggle({
              inventoryRoomsForm,
              isDeleted: true,
              roomItemsFormIndex,
              itemIndex: itemForm.index,
            });
            removeItemToast.handleToast({message: `${itemForm.name} removed.`});
          }
        }}
        handleIncrease={() => {
          InventoryRoomsForm.handleQuantityChange({
            inventoryRoomsForm,
            quantity: 1,
            item: itemForm,
            roomItemsFormIndex,
            itemIndex: itemForm.index,
          });
        }}
        onPress={onPress}
        value={itemForm.take ? itemForm.takeCount : itemForm.leaveCount}
        style={{marginBottom: 8}}
      />
    </StepperContainer>
  );
};

const MobileInventoryItem = React.memo(
  ({
    itemForm,
    inventoryRoomsForm,
    roomItemsFormIndex,
    defaultDensityFactor,
    style,
    handleScrollRoomToBottom,
    isCompact,
  }) => {
    const responsive = useResponsive();
    const editInventoryItemSheet = useSheet({name: 'Edit Inventory Item Sheet'});
    const inventoryItemActionSheet = useSheet({name: 'Inventory Item Action Sheet'});
    const [targetField, setTargetField] = useState('');
    const removeItemToast = useToast({
      ToastComponent: Toast,
      message: 'Item removed.',
      actionText: 'Undo',
      handleAction: () => {
        InventoryRoomsForm.handleDeleteToggle({
          inventoryRoomsForm,
          isDeleted: false,
          roomItemsFormIndex,
          itemIndex: itemForm.index,
        });
        // Reset quantity to 1 if undoing a delete
        const quantityField = itemForm.take ? 'takeCount' : 'leaveCount';
        inventoryRoomsForm.setFieldValue(
          `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${itemForm.index}.${quantityField}`,
          1,
        );
      },
    });

    return (
      <React.Fragment>
        <Card onPress={editInventoryItemSheet.handleOpen} style={style}>
          <Row style={{width: '100%', justifyContent: 'space-between'}}>
            <Button
              style={{flex: 1}}
              onPress={() => {
                setTargetField('name');
                editInventoryItemSheet.handleOpen();
              }}
            >
              <Name numberOfLines={1} responsive={responsive}>
                {itemForm.name}
              </Name>
            </Button>
            <Space width={8} />
            <IconButton onPress={inventoryItemActionSheet.handleOpen} hitSlop={{right: 16}}>
              <Icon source={Icon.EllipsisV} size={16} color={colors.gray.secondary} />
            </IconButton>
          </Row>
          <Space height={8} />
          <Row>
            <Button
              style={{maxWidth: '100%'}}
              onPress={() => {
                setTargetField('notes');
                editInventoryItemSheet.handleOpen();
              }}
            >
              <Note
                numberOfLines={1}
                vars={{isEmpty: itemForm.notes === ''}}
                responsive={responsive}
              >
                {itemForm.notes || 'Tap to add a note.'}
              </Note>
            </Button>
          </Row>
          <Space height={12} />
          <Row style={{flexDirection: isCompact ? 'column' : 'row'}}>
            <Row>
              <ItemAttributePill
                isCompact={isCompact}
                responsive={responsive}
                icon={Icon.RulerTriangle}
                label={`${itemForm.volume} cu ft`}
                onPress={() => {
                  setTargetField('volume');
                  editInventoryItemSheet.handleOpen();
                }}
              />
              <Space width={8} />
              <ItemAttributePill
                isCompact={isCompact}
                responsive={responsive}
                icon={Icon.WeightHanging}
                label={`${itemForm.weight} lbs`}
                onPress={() => {
                  setTargetField('weight');
                  editInventoryItemSheet.handleOpen();
                }}
              />
              <Space width={8} />
              <ItemAttributePill
                isCompact={isCompact}
                responsive={responsive}
                icon={Icon.DollarSign}
                label={Currency.display(itemForm.price)}
                onPress={() => {
                  setTargetField('price');
                  editInventoryItemSheet.handleOpen();
                }}
              />
            </Row>
            <Space style={isCompact ? {height: 12} : {minWidth: 16, flex: 1}} />
            <Row style={isCompact ? {width: '100%'} : {}}>
              <TakeCheckbox
                itemForm={itemForm}
                inventoryRoomsForm={inventoryRoomsForm}
                roomItemsFormIndex={roomItemsFormIndex}
              />
              <Space style={isCompact ? {flex: 1, minWidth: 16} : {width: 16}} />
              <Stepper
                inventoryRoomsForm={inventoryRoomsForm}
                itemForm={itemForm}
                roomItemsFormIndex={roomItemsFormIndex}
                onPress={() => {
                  setTargetField('quantity');
                  editInventoryItemSheet.handleOpen();
                }}
                removeItemToast={removeItemToast}
              />
            </Row>
          </Row>
        </Card>
        <EditInventoryItemSheet
          key={editInventoryItemSheet.key}
          isOpen={editInventoryItemSheet.isOpen}
          handleClose={() => {
            editInventoryItemSheet.handleClose();
            setTargetField('');
          }}
          inventoryRoomsForm={inventoryRoomsForm}
          roomItemsFormIndex={roomItemsFormIndex}
          itemForm={itemForm}
          autoFocus={targetField}
          defaultDensityFactor={defaultDensityFactor}
          removeItemToast={removeItemToast}
        />
        <InventoryItemActionSheet
          key={inventoryItemActionSheet.key}
          isOpen={inventoryItemActionSheet.isOpen}
          handleClose={inventoryItemActionSheet.handleClose}
          editInventoryItemSheet={editInventoryItemSheet}
          inventoryRoomsForm={inventoryRoomsForm}
          roomItemsFormIndex={roomItemsFormIndex}
          itemForm={itemForm}
          removeItemToast={removeItemToast}
          handleScrollRoomToBottom={handleScrollRoomToBottom}
        />
      </React.Fragment>
    );
  },
  (prevProps, nextProps) =>
    // We don't want to re-render when inventoryRoomsForm changes
    // Since it's a form used for all rooms/items, any update will cause a re-render negatively affecting performance
    _.isEqual(prevProps.itemForm, nextProps.itemForm) &&
    prevProps.roomItemsFormIndex === nextProps.roomItemsFormIndex &&
    prevProps.defaultDensityFactor === nextProps.defaultDensityFactor &&
    _.isEqual(prevProps.style, nextProps.style) &&
    prevProps.isCompact === nextProps.isCompact,
);

export default MobileInventoryItem;
