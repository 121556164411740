// Libraries
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (referralSource) => ({
    name: (referralSource as any).name,
  }),
  gql`
    fragment ReferralSourceForm_edit on ReferralSource {
      name
    }
  `,
);

const ReferralSourceForm = {
  toForm: ({name}: any) => ({
    name,
  }),

  toMutation: ({name}: any) => ({
    name,
  }),

  new: () => ({
    name: '',
  }),
  edit,
};

export default ReferralSourceForm;
