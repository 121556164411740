// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const AssignTruckToJobV2Mutation = ({
  truckId,
  jobId,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}) => {
  return (
    <Mutation
      variables={{
        truckId,
        jobId,
      }}
      mutation={AssignTruckToJobV2Mutation.mutation}
      onCompleted={({response: {jobTruck, errors}}) => {
        submitForm();
        setMutationErrors(errors);

        if (!errors) {
          onSuccess(jobTruck);
        } else if (onError) {
          onError(errors);
        }
      }}
      refetchQueries={['PrepareJobPage']}
      awaitRefetchQueries
    >
      {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
    </Mutation>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
AssignTruckToJobV2Mutation.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  setMutationErrors: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

AssignTruckToJobV2Mutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignTruckToJobV2Mutation.mutation = gql`
  mutation AssignTruckToJobV2Mutation(
    $truckId: Int!,
    $jobId: Int!,
  ) {
    response: assignTruckToJobV2(
      truckId: $truckId,
      jobId: $jobId,
    ) {
      ${gql.errors}
      jobTruck {
        truckId
        jobId
      }
    }
  }
`;

export default AssignTruckToJobV2Mutation;
