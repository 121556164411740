// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';

const ConfirmInvoiceEditModal = ({invoice, isOpen, handleAction, handleClose}) => {
  return (
    <SuccessModal
      title={`Edit Invoice ${invoice.identifier}`}
      subtitle={
        'You are making changes to a “Paid” invoice. Do you want to continue editing? If so, you may need to resend the invoice to the customer.'
      }
      isOpen={isOpen}
      handlePrimaryAction={() => {
        handleAction();
        handleClose();
      }}
      primaryActionText={'Continue Editing'}
      handleSecondaryAction={handleClose}
      secondaryActionText={'Cancel'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmInvoiceEditModal.fragment = gql`
  fragment ConfirmInvoiceEditModal on Invoice {
    id
    identifier
  }
`;

export default ConfirmInvoiceEditModal;
