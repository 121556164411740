// Supermove
import {Environment} from '@supermove/sdk';

// TODO(Hammad): Consider adding auth token support. See getMoverAppUrl and the Mover app AuthenticatedWrapper component
export const getAdminAppUrl = ({companySlug}: {companySlug?: string} = {}): string => {
  // TODO(Hammad): Would be great to add types to the Enviroment module
  const appEnv = Environment.getAppEnv();

  const companyDetailsPath = companySlug ? `companies/${companySlug}` : '';

  if (Environment.isLocal()) {
    return `http://localhost:9003/${companyDetailsPath}`;
  }

  switch (appEnv) {
    case 'production':
      return `https://admin.supermove.co/${companyDetailsPath}`;
    case 'staging':
      return `https://admin.staging.supermove.co/${companyDetailsPath}`;
    default:
      return `https://admin.development.supermove.co/${companyDetailsPath}`;
  }
};
