// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {SettingsModel} from '@supermove/models';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';

const getMustLinkToJobLabel = (settings: SettingsModel) => {
  return settings.mustLinkClaimToJob
    ? 'Require every claim to be linked to a specific job'
    : 'Do not require claims to be linked to a specific job';
};

const getAutoAssignJobMoversToClaim = (settings: SettingsModel) => {
  return settings.autoAssignJobMoversToClaim
    ? 'Automatically assign all movers on the job to the claim'
    : 'Do not automatically assign movers on the job to the claim';
};

const ClaimAssignmentPanel = ({settings}: {settings: SettingsModel}) => {
  return (
    <React.Fragment>
      <FieldValue label={'Link to Job'} value={getMustLinkToJobLabel(settings)} isResponsive />
      <Space height={16} />
      <FieldValue
        label={'Assign Movers'}
        value={getAutoAssignJobMoversToClaim(settings)}
        isResponsive
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClaimAssignmentPanel.fragment = gql`
  fragment ClaimAssignmentPanel on Settings {
    id
    mustLinkClaimToJob
    autoAssignJobMoversToClaim
  }
`;

export default ClaimAssignmentPanel;
