// Libraries
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';
import useUpdateProjectTypeMutation from '@shared/modules/Project/hooks/useUpdateProjectTypeMutation';
import ProjectTypeModalFields from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeModalFields';

const EditProjectTypeModalContent = ({
  projectType,
  isOpen,
  handleClose,
  refetch,
  isStorageSettingsPage,
}) => {
  const projectTypeForm = ProjectTypeForm.edit(projectType);
  const {form, submitting, handleSubmit} = useUpdateProjectTypeMutation({
    projectTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
      <SmallModal.HeaderText>{'Edit Project Type'}</SmallModal.HeaderText>
      <Space height={16} />
      <ProjectTypeModalFields
        form={form}
        organization={projectType.organization}
        isStorageSettingsPage={isStorageSettingsPage}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={submitting} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          color={colors.blue.interactive}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};
const EditProjectTypeModal = ({
  projectTypeId,
  isOpen,
  handleClose,
  refetch,
  isStorageSettingsPage,
}) => {
  const {loading, data} = useQuery(EditProjectTypeModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {projectTypeId},
  });

  return (
    <Loading loading={loading}>
      {() =>
        data ? (
          <EditProjectTypeModalContent
            projectType={data.projectType}
            isOpen={isOpen}
            handleClose={handleClose}
            refetch={refetch}
            isStorageSettingsPage={isStorageSettingsPage}
          />
        ) : null
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

EditProjectTypeModal.query = gql`
  ${ProjectTypeForm.edit.fragment}
  ${ProjectTypeModalFields.fragment}

  query EditProjectTypeModal($projectTypeId: Int!) {
    ${gql.query}
    projectType(projectTypeId: $projectTypeId) {
      id
      organization {
        id
        ...ProjectTypeModalFields
      }
      ...ProjectTypeForm_edit
    }
  }
`;

export default EditProjectTypeModal;
