import {ExecutionResult, MutationFunctionOptions} from 'react-apollo';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {MutationError, Form, MutationResponse} from '@supermove/hooks/src/forms/types';

// App
import {PayengineCreditCardFormInterface} from '@shared/modules/Payment/forms/PayengineCreditCardForm';
import SavePayengineCreditCardForm, {
  SavePayengineCreditCardFormType,
} from '@shared/modules/Payment/forms/SavePayengineCreditCardForm';

export type SavePayengineCreditCardResponse = {
  id: number;
};

type GraphQLResponse = MutationResponse<SavePayengineCreditCardResponse>;

type Args = {
  clientId: number;
  payengineCreditCardForm?: PayengineCreditCardFormInterface;
  onSuccess: (response: SavePayengineCreditCardResponse) => void;
  onError: (errors: MutationError[]) => void;
};

export type UseSavePayengineCreditCardForm = {
  form: SavePayengineCreditCardFormType;
};

type Result = {
  form: Form<UseSavePayengineCreditCardForm>;
  submitting: boolean | undefined;
  handleSubmit: (
    options?: MutationFunctionOptions<GraphQLResponse, UseSavePayengineCreditCardForm>,
  ) => Promise<ExecutionResult<GraphQLResponse>>;
};

/**
 * Saves a PayEngine credit card token into Supermove's DB. Application code should typically call
 * `useSavePayEngineCreditCard` instead of this hook.
 */
const useSavePayEngineCreditCardMutation = ({
  clientId,
  payengineCreditCardForm,
  onSuccess,
  onError,
}: Args): Result => {
  const savePayengineCreditCardForm = SavePayengineCreditCardForm.new({
    clientId,
    payengineCreditCardForm,
  });

  const form = useForm<UseSavePayengineCreditCardForm>({
    initialValues: {
      form: SavePayengineCreditCardForm.toForm(savePayengineCreditCardForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSavePayEngineCreditCardMutation.mutation,
    variables: {
      form: SavePayengineCreditCardForm.toMutation(form.values.form),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useSavePayEngineCreditCardMutation.mutation = gql`
  mutation SavePayEngineCreditCardMutation(
    $form: SavePayEngineCreditCardForm!
  ) {
    response: savePayEngineCreditCard(
      form: $form,
    ) {
      ${gql.errors}
      id
    }
  }
`;

export default useSavePayEngineCreditCardMutation;
