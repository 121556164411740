// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Percent} from '@supermove/utils';

// App
import Panel from '@shared/design/components/Panel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import TextInputWithDropdown from 'modules/App/components/TextInputWithDropdown';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Text = Styled.Text`
  ${Typography.Body};
  color: ${colors.gray.secondary};
`;

const ViewCell = ({label, placeholder, value}) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const SalesTaxTemplatePanelHeader = ({isEditing, handleEdit}) => {
  return (
    <Panel.Header style={{alignItems: 'center'}}>
      <Column>
        <Row>
          <Panel.HeaderText>Sales Tax</Panel.HeaderText>
          <Space style={{flex: 1, minWidth: 12}} />
          <EditPanel.EditButton isEditing={isEditing} handleEdit={handleEdit} />
        </Row>
        <Space height={8} />
        <Panel.HeaderDescription>
          {`This sales tax rate will be set as the default for all taxable bill items. ` +
            `This can be overridden per project type or per project.`}
        </Panel.HeaderDescription>
      </Column>
    </Panel.Header>
  );
};

const SalesTaxTemplatePanelEdit = ({form, field, variableOptions, lastUpdatedDisplay}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <TextInputWithDropdown
            form={form}
            textValueField={`${field}.rate`}
            clearTextValue={''}
            dropdownValueField={`${field}.variableId`}
            label={'Default Sales Tax Rate'}
            dropdownOptions={variableOptions}
            placeholder={'Enter a percentage or select a variable'}
            onBlur={() => {
              const rateField = `${field}.rate`;
              if (_.get(form.values, `${field}.variableId`)) {
                form.setFieldValue(rateField, '');
              } else {
                form.setFieldValue(
                  rateField,
                  Percent.toForm(Percent.toFloat(_.get(form.values, rateField))),
                );
              }
            }}
            noOptionsMessage={'No variable options'}
          />
          <Space height={8} />
          <Text>{`Last Updated: ${lastUpdatedDisplay}`}</Text>
        </Column>
        <Space width={24} />
        <Column />
      </Row>
    </React.Fragment>
  );
};

const SalesTaxTemplatePanel = ({salesTaxTemplate}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell
            label={'Default Sales Tax Rate'}
            placeholder={'Add a sales tax rate'}
            value={salesTaxTemplate.variable?.name || Percent.display(salesTaxTemplate.rate)}
          />
        </Column>
        <Space width={24} />
        <Column />
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SalesTaxTemplatePanel.fragment = gql`
  fragment SalesTaxTemplatePanel on SalesTaxTemplate {
    id
    rate
    variable {
      id
      name
    }
  }
`;

SalesTaxTemplatePanel.Edit = SalesTaxTemplatePanelEdit;
SalesTaxTemplatePanel.Header = SalesTaxTemplatePanelHeader;

export default SalesTaxTemplatePanel;
