// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const UpdateUserDayByDateMutation = ({
  date,
  scheduleStatus,
  userId,
  refetchQueries,
  onSuccess,
  onError,
  children,
}) => (
  <Mutation
    awaitRefetchQueries
    mutation={UpdateUserDayByDateMutation.mutation}
    refetchQueries={refetchQueries}
    variables={{
      date,
      scheduleStatus,
      userId,
    }}
    onCompleted={({response: {userDay, errors}}) => {
      if (!errors) {
        onSuccess(userDay);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateUserDayByDateMutation.propTypes = {
  date: PropTypes.string.isRequired,
  scheduleStatus: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  refetchQueries: PropTypes.array,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

UpdateUserDayByDateMutation.defaultProps = {
  refetchQueries: [],
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateUserDayByDateMutation.mutation = gql`
  mutation UpdateUserDayByDateMutation(
    $date: String!,
    $scheduleStatus: String!,
    $userId: Int!,
  ) {
    response: updateUserDayByDate(
      date: $date,
      scheduleStatus: $scheduleStatus,
      userId: $userId,
    ) {
      ${gql.errors}
      userDay {
        id
        dayId
        userId
        scheduleStatus
      }
    }
  }
`;

export default UpdateUserDayByDateMutation;
