// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Lifecycle} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import JobPageContent from 'modules/Job/Show/components/JobPageContent';
import JobNavigation from 'modules/Job/components/JobNavigation';
import JobPage from 'modules/Job/components/JobPage';

const isCorrectPage = ({job}) => {
  return ['CANCELLED', 'LEAD', 'HOLD', 'NOT_READY', 'READY'].includes(job.primaryStatus);
};

const onMountOrUpdate = ({navigator, job}) => {
  if (!isCorrectPage({job})) {
    navigator.replace(`/jobs/${job.uuid}`);
  }
};

// If the next step of the confirmation is not to make a deposit,
// redirect the customer to the show confirmation page.
const Redirect = ({children, navigator, job}) => (
  <Lifecycle
    onMount={() => onMountOrUpdate({navigator, job})}
    onUpdate={() => onMountOrUpdate({navigator, job})}
  >
    {isCorrectPage({job}) ? children : null}
  </Lifecycle>
);

const PrepareJobPageContent = ({job, viewer, refetch}) => {
  const leftBlocks = _.get(job, 'organization.page.left.blocks', []);
  const centerBlocks = _.get(job, 'organization.page.center.blocks', []);
  const rightBlocks = _.get(job, 'organization.page.right.blocks', []);
  const mobileBlocks = _.get(job, 'organization.jobPageMobile.blocks', []);

  return (
    <JobPageContent
      job={job}
      leftBlocks={leftBlocks}
      centerBlocks={centerBlocks}
      rightBlocks={rightBlocks}
      mobileBlocks={mobileBlocks}
      viewer={viewer}
      refetch={refetch}
    />
  );
};

const PrepareJobPage = ({
  match: {
    params: {uuid},
  },
}) => {
  return (
    <JobPage
      fetchPolicy={'cache-and-network'}
      variables={{uuid}}
      query={PrepareJobPage.query}
      pollInterval={1000 * 600} // Refresh every 600 seconds.
      navigation={({data, handleOpenSidebar, refetch}) =>
        data && data.job ? (
          <JobNavigation
            job={data.job}
            viewer={data.viewer}
            handleOpenSidebar={handleOpenSidebar}
            refetch={refetch}
          />
        ) : null
      }
    >
      {({navigator, data, refetch, loadingUpdatedAt}) => (
        <Redirect navigator={navigator} job={data.job}>
          <PrepareJobPageContent
            loadingUpdatedAt={loadingUpdatedAt}
            job={data.job}
            viewer={data.viewer}
            refetch={refetch}
          />
        </Redirect>
      )}
    </JobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PrepareJobPage.query = gql`
  ${JobNavigation.fragment}
  ${JobPage.fragment}
  ${JobPageContent.fragment}

  query PrepareJobPage($uuid: String!) {
    ${gql.query}
    viewer {
      id
      ...JobNavigation_Viewer
      ...JobPageContent_Viewer
    }
    job(uuid: $uuid) {
      id
      uuid
      primaryStatus: calendarPrimaryStatus
      organization {
        id
        page: block(kind: "MANAGER_JOB_PREPARE_PAGE") {
          left: block(kind: "MANAGER_JOB_PREPARE_LEFT") {
            blocks {
              ...JobPageContent_Block
            }
          }
          center: block(kind: "MANAGER_JOB_PREPARE_CENTER") {
            blocks {
              ...JobPageContent_Block
            }
          }
          right: block(kind: "MANAGER_JOB_PREPARE_RIGHT") {
            blocks {
              ...JobPageContent_Block
            }
          }
        }
        jobPageMobile: block(kind: "MANAGER_JOB_PREPARE_PAGE_MOBILE") {
          blocks {
            ...JobPageContent_Block
          }
        }
      }
      ...JobNavigation_Job
      ...JobPage
      ...JobPageContent_Job
    }
  }
`;

export default PrepareJobPage;
