// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';

const useCreateProjectTypeMutation = ({projectTypeForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      projectTypeForm: ProjectTypeForm.toForm(projectTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateProjectTypeMutation.mutation,
    variables: {
      projectTypeForm: ProjectTypeForm.toMutation(form.values.projectTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateProjectTypeMutation.mutation = gql`
  mutation useCreateProjectTypeMutation($projectTypeForm: ProjectTypeForm!) {
    response: createProjectType(projectTypeForm: $projectTypeForm) {
      ${gql.errors}
      projectType {
        id
        uuid
      }
    }
  }
`;

export default useCreateProjectTypeMutation;
