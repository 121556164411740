// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import BookingFiltersForm from '@shared/modules/Booking/forms/BookingFiltersForm';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import BookingCalendarWeekDay from 'modules/Calendar/Booking/components/BookingCalendarWeekDay';
import BookingCalendarWeekDayHeader from 'modules/Calendar/Booking/components/BookingCalendarWeekDayHeader';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.border};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  background-color: ${colors.white};
  box-shadow: 0 2px 2px rgba(194, 194, 194, 0.5);
  z-index: 1;
`;

const getDateToQuery = ({date}) => {
  return Datetime.toMutationDate(Datetime.previousSunday(Datetime.fromDate(date)));
};

const getDateRange = ({date, responsive}) => {
  return responsive.mobile
    ? [date]
    : Datetime.getDateRange(getDateToQuery({date}), 7).map((datetime) => Datetime.toDate(datetime));
};

const DayHeader = ({data, date, refetch, form}) => {
  const calendarDays = _.get(data, 'calendar.calendarDays');
  const calendarDay = _.find(calendarDays, (calendarDay) => {
    return _.get(calendarDay, 'date') === date;
  });

  return <BookingCalendarWeekDayHeader calendarDay={calendarDay} form={form} refetch={refetch} />;
};

const DayColumn = ({data, date, refetch}) => {
  const calendarDays = _.get(data, 'calendar.calendarDays');
  const calendarDay = _.find(calendarDays, (calendarDay) => {
    return _.get(calendarDay, 'date') === date;
  });

  return <BookingCalendarWeekDay calendarDay={calendarDay} refetch={refetch} />;
};

const BookingCalendarWeek = ({date, form}) => {
  const responsive = useResponsive();
  const salespersonIds = BookingFiltersForm.getSelectedSalespersonIds(form.values);
  const projectTypeIds = BookingFiltersForm.getSelectedProjectTypeIds(form.values);

  const {loading, data, refetch} = useQuery(BookingCalendarWeek.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      date: getDateToQuery({date}),
      salespersonIds,
      projectTypeIds,
      showOnlyPackingJobs: form.values.showOnlyPackingJobs,
      showOnlyNonPackingJobs: form.values.showOnlyNonPackingJobs,
      organizationSlug: form.values.selectedOrganizationSlug,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return (
          <Container data-test-id='booking-calendar-week'>
            <Header>
              {getDateRange({date, responsive}).map((date) => (
                <DayHeader key={date} data={data} date={date} form={form} refetch={refetch} />
              ))}
            </Header>
            <ScrollView
              contentContainerStyle={{
                flexDirection: 'row',
                paddingBottom: 180,
              }}
              style={{flex: 1}}
            >
              {getDateRange({date, responsive}).map((date) => (
                <DayColumn key={date} data={data} date={date} refetch={refetch} />
              ))}
            </ScrollView>
          </Container>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BookingCalendarWeek.query = gql`
  ${BookingCalendarWeekDay.fragment}
  ${BookingCalendarWeekDayHeader.fragment}

  query BookingCalendarWeek(
    $date: String!,
    $salespersonIds: [Int]!,
    $projectTypeIds: [Int]!,
    $showOnlyPackingJobs: Boolean!,
    $showOnlyNonPackingJobs: Boolean!,
    $organizationSlug: String!,
  ) {
    ${gql.query}
    calendar: calendarV4(
      date: $date,
      salespersonIds: $salespersonIds,
      projectTypeIds: $projectTypeIds,
      showOnlyPackingJobs: $showOnlyPackingJobs,
      showOnlyNonPackingJobs: $showOnlyNonPackingJobs,
      organizationSlug: $organizationSlug,
    ) {
      calendarDays {
        ...BookingCalendarWeekDay
        ...BookingCalendarWeekDayHeader
      }
    }
  }
`;

export default BookingCalendarWeek;
