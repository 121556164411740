// Libraries
import _ from 'lodash';

// Supermove
import {DocumentItemType} from '@supermove/models';
import {humanize, uuid} from '@supermove/utils';

// App
import DocumentV2Image from '@shared/modules/Document/components/DocumentV2Image';
import DocumentTemplateCategory, {
  DocumentTemplateCategoryType,
} from '@shared/modules/Document/enums/DocumentTemplateCategory';

// Page setup items
const SECTION = 'SECTION' as const;
const COLUMN = 'COLUMN' as const;

// Document display items
const TEXT = 'TEXT' as const;
const ORGANIZATION_SIGNATURE = 'ORGANIZATION_SIGNATURE' as const;
const BILLING_INFORMATION = 'BILLING_INFORMATION' as const;
const TIMESHEET_INFORMATION = 'TIMESHEET_INFORMATION' as const;
const VALUATION_DECLARATION_TABLE = 'VALUATION_DECLARATION_TABLE' as const;
const SURVEY_CARTONS_SUMMARY = 'SURVEY_CARTONS_SUMMARY' as const;
const SURVEY_ITEMS_SUMMARY = 'SURVEY_ITEMS_SUMMARY' as const;
const SURVEY_ROOMS_SUMMARY = 'SURVEY_ROOMS_SUMMARY' as const;
const INVENTORY_SUMMARY = 'INVENTORY_SUMMARY' as const;
const GENERAL_INVENTORY_SUMMARY = 'GENERAL_INVENTORY_SUMMARY' as const;
const INVENTORY_ITEMS_SUMMARY = 'INVENTORY_ITEMS_SUMMARY' as const;
const INPUT_PER_MOVER_TIMESHEET = 'INPUT_PER_MOVER_TIMESHEET' as const;
const INPUT_COMMERCIAL_CATALOG_EQUIPMENTS = 'INPUT_COMMERCIAL_CATALOG_EQUIPMENTS' as const;
const INPUT_COMMERCIAL_CATALOG_MATERIALS = 'INPUT_COMMERCIAL_CATALOG_MATERIALS' as const;
const COMMERCIAL_CATALOG_VALUES = [
  INPUT_COMMERCIAL_CATALOG_EQUIPMENTS,
  INPUT_COMMERCIAL_CATALOG_MATERIALS,
] as const;
const INPUT_GRID = 'INPUT_GRID' as const;
const INPUT_LIST = 'INPUT_LIST' as const;
const INPUT_RADIO_BUTTONS = 'INPUT_RADIO_BUTTONS' as const;
const INPUT_TEXT_INPUTS = 'INPUT_TEXT_INPUTS' as const;
const INPUT_TEXT_AREAS = 'INPUT_TEXT_AREAS' as const;
const INPUT_VALUATION_COVERAGE = 'INPUT_VALUATION_COVERAGE' as const;
const INPUT_SIGNATURE = 'INPUT_SIGNATURE' as const;
const GENERIC_SIGNATURE = 'GENERIC_SIGNATURE' as const;
const DOCUMENT_HEADER = 'DOCUMENT_HEADER' as const;
const LOGO = 'LOGO' as const;
const IMAGE = 'IMAGE' as const;

// INVOICE category document items
const INVOICE_SUMMARY_INFORMATION = 'INVOICE_SUMMARY_INFORMATION' as const;

const DROPDOWN_VALUES = [
  DOCUMENT_HEADER,
  LOGO,
  TEXT,
  ORGANIZATION_SIGNATURE,
  BILLING_INFORMATION,
  INVOICE_SUMMARY_INFORMATION,
  VALUATION_DECLARATION_TABLE,
  TIMESHEET_INFORMATION,
  SURVEY_CARTONS_SUMMARY,
  SURVEY_ITEMS_SUMMARY,
  SURVEY_ROOMS_SUMMARY,
  INVENTORY_SUMMARY,
  GENERAL_INVENTORY_SUMMARY,
  IMAGE,
  INVENTORY_ITEMS_SUMMARY,
  INPUT_TEXT_INPUTS,
  INPUT_TEXT_AREAS,
  INPUT_RADIO_BUTTONS,
  INPUT_LIST,
  INPUT_GRID,
  INPUT_SIGNATURE,
  GENERIC_SIGNATURE,
  INPUT_VALUATION_COVERAGE,
  INPUT_PER_MOVER_TIMESHEET,
  INPUT_COMMERCIAL_CATALOG_EQUIPMENTS,
  INPUT_COMMERCIAL_CATALOG_MATERIALS,
] as const;

type DropdownSectionType = {
  name: string;
  kinds: DocumentItemKindType[];
};

const DROPDOWN_SECTION_NAME = {
  DOCUMENT_ELEMENTS: 'Document Elements',
  INPUTS: 'Inputs',
  SIGNATURES: 'Signatures',
  SURVEY: 'Survey',
  INVENTORY: 'Inventory',
  VALUATION_COVERAGE: 'Valuation Coverage',
  BILLING: 'Billing',
  EQUIPMENT_AND_MATERIALS: 'Equipment & Materials',
  TIMESHEETS: 'Timesheets',
};
const DROPDOWN_SECTIONS: DropdownSectionType[] = [
  {
    name: DROPDOWN_SECTION_NAME.DOCUMENT_ELEMENTS,
    kinds: [DOCUMENT_HEADER, LOGO, IMAGE, TEXT],
  },
  {
    name: DROPDOWN_SECTION_NAME.INPUTS,
    kinds: [INPUT_LIST, INPUT_RADIO_BUTTONS, INPUT_TEXT_INPUTS, INPUT_TEXT_AREAS, INPUT_GRID],
  },
  {
    name: DROPDOWN_SECTION_NAME.SIGNATURES,
    kinds: [GENERIC_SIGNATURE, ORGANIZATION_SIGNATURE, INPUT_SIGNATURE],
  },
  {
    name: DROPDOWN_SECTION_NAME.SURVEY,
    kinds: [SURVEY_CARTONS_SUMMARY, SURVEY_ITEMS_SUMMARY, INVENTORY_SUMMARY, SURVEY_ROOMS_SUMMARY],
  },
  {
    name: DROPDOWN_SECTION_NAME.INVENTORY,
    kinds: [INVENTORY_ITEMS_SUMMARY, GENERAL_INVENTORY_SUMMARY],
  },
  {
    name: DROPDOWN_SECTION_NAME.VALUATION_COVERAGE,
    kinds: [INPUT_VALUATION_COVERAGE, VALUATION_DECLARATION_TABLE],
  },
  {
    name: DROPDOWN_SECTION_NAME.BILLING,
    kinds: [BILLING_INFORMATION, INVOICE_SUMMARY_INFORMATION],
  },
  {
    name: DROPDOWN_SECTION_NAME.EQUIPMENT_AND_MATERIALS,
    kinds: [INPUT_COMMERCIAL_CATALOG_EQUIPMENTS, INPUT_COMMERCIAL_CATALOG_MATERIALS],
  },
  {
    name: DROPDOWN_SECTION_NAME.TIMESHEETS,
    kinds: [TIMESHEET_INFORMATION, INPUT_PER_MOVER_TIMESHEET],
  },
];

// DEPRECATED: These document item kinds were deprecated with the introduction of TEXT.
// They are still definied here for backwards compatibility, but are omitted intentionally from DROPDOWN_VALUES.
const TEXT_TITLE = 'TEXT_TITLE' as const;
const TEXT_PARAGRAPH = 'TEXT_PARAGRAPH' as const;
const SECTION_TITLE = 'SECTION_TITLE' as const;

export type DocumentItemKindType =
  | typeof SECTION
  | typeof COLUMN
  | (typeof DROPDOWN_VALUES)[number]
  // DEPRECATED
  | typeof TEXT_TITLE
  | typeof TEXT_PARAGRAPH
  | typeof SECTION_TITLE;

const getItemDisplayName = ({
  itemKind,
  isFullyEnabledTimesheetsV2,
}: {
  itemKind: DocumentItemKindType;
  isFullyEnabledTimesheetsV2: boolean;
}) => {
  const itemConfig = _.get(DocumentItemKindV2.DOCUMENT_ITEM_KIND_TABLE_VALUES, itemKind);

  if (itemKind === DocumentItemKindV2.INPUT_PER_MOVER_TIMESHEET && !isFullyEnabledTimesheetsV2) {
    return 'Per-Mover Timesheet';
  }

  return _.get(itemConfig, 'displayName') || (itemKind ? humanize(itemKind) : '');
};

const getDescription = (documentItemKind: DocumentItemKindType) => {
  const documentItemKindConfig = _.get(
    DocumentItemKindV2.DOCUMENT_ITEM_KIND_TABLE_VALUES,
    documentItemKind,
  );
  return documentItemKindConfig.displayDescription;
};

const DISABLED_DROPDOWN_SECTIONS_FOR_COLUMNS = [
  DROPDOWN_SECTION_NAME.SURVEY,
  DROPDOWN_SECTION_NAME.INVENTORY,
  DROPDOWN_SECTION_NAME.VALUATION_COVERAGE,
  DROPDOWN_SECTION_NAME.BILLING,
  DROPDOWN_SECTION_NAME.EQUIPMENT_AND_MATERIALS,
  DROPDOWN_SECTION_NAME.TIMESHEETS,
];

const DISABLED_FOR_COLUMNS = _.flatMap(
  DISABLED_DROPDOWN_SECTIONS_FOR_COLUMNS.map(
    (sectionName) => DROPDOWN_SECTIONS.find((section) => section.name === sectionName)?.kinds,
  ),
);

const getIsDisabledForColumn = (itemKind: DocumentItemKindType) => {
  return _.includes(DISABLED_FOR_COLUMNS, itemKind);
};

const _documentItemKindFilterByCategory = ({
  documentItemKind,
  documentTemplateCategory,
  isEnabledGenericSignatureDocumentItem,
  isEquipmentAndMaterialsEnabled,
}: {
  documentItemKind: DocumentItemKindType;
  documentTemplateCategory: DocumentTemplateCategoryType;
  isEnabledGenericSignatureDocumentItem: boolean;
  isEquipmentAndMaterialsEnabled: boolean;
}): boolean => {
  const notShownItemsForInvoice = [BILLING_INFORMATION, SURVEY_CARTONS_SUMMARY];
  if (!isEnabledGenericSignatureDocumentItem && documentItemKind === GENERIC_SIGNATURE) {
    return false;
  }
  // @ts-expect-error TS(2345)
  if (!isEquipmentAndMaterialsEnabled && COMMERCIAL_CATALOG_VALUES.includes(documentItemKind)) {
    return false;
  }
  // (Kevin): We can refactor this out if we need to support projects,
  // for now this is only used invoice which filters out all JOB document item kinds
  return documentTemplateCategory === DocumentTemplateCategory.INVOICE
    ? // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      DocumentItemKindV2.DOCUMENT_ITEM_KIND_TABLE_VALUES[documentItemKind].category !==
        // @ts-expect-error TS(2345)
        DocumentTemplateCategory.JOB && !notShownItemsForInvoice.includes(documentItemKind)
    : // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      DocumentItemKindV2.DOCUMENT_ITEM_KIND_TABLE_VALUES[documentItemKind].category !==
        DocumentTemplateCategory.INVOICE;
};

const getDocumentItemKindBasedOnCategory = ({
  category,
  isEnabledGenericSignatureDocumentItem,
  isEquipmentAndMaterialsEnabled,
}: {
  category: DocumentTemplateCategoryType;
  isEnabledGenericSignatureDocumentItem: boolean;
  isEquipmentAndMaterialsEnabled: boolean;
}) => {
  return DocumentItemKindV2.VALUES.filter((documentItemKind) =>
    _documentItemKindFilterByCategory({
      documentItemKind,
      documentTemplateCategory: category,
      isEnabledGenericSignatureDocumentItem,
      isEquipmentAndMaterialsEnabled,
    }),
  );
};

const getDropdownSections = ({
  documentTemplateCategory,
  isEnabledGenericSignatureDocumentItem,
  isEquipmentAndMaterialsEnabled,
}: {
  documentTemplateCategory: DocumentTemplateCategoryType;
  isEnabledGenericSignatureDocumentItem: boolean;
  isEquipmentAndMaterialsEnabled: boolean;
}) => {
  const dropdownSectionsWithFilteredKinds = DROPDOWN_SECTIONS.map((section) => {
    return {
      name: section.name,
      kinds: section.kinds.filter((kind) =>
        _documentItemKindFilterByCategory({
          documentItemKind: kind,
          documentTemplateCategory,
          isEnabledGenericSignatureDocumentItem,
          isEquipmentAndMaterialsEnabled,
        }),
      ),
    };
  });
  return dropdownSectionsWithFilteredKinds.filter((section) => section.kinds.length > 0);
};

const getConvertedItemText = ({item}: any) => {
  const textWithLineBreaks = item.text.replace(/(?:\r\n|\r|\n)/g, '<br>');
  switch (item.itemKind) {
    case TEXT_PARAGRAPH:
      return `<p>${textWithLineBreaks}</p>`;
    case TEXT_TITLE:
      return `<h2>${textWithLineBreaks}</h2>`;
    case SECTION_TITLE:
      return `<h1 class='ql-align-center'>${textWithLineBreaks}</h1>`;
    default:
      return item.text;
  }
};

const convertTextItems = ({documentItems}: any) => {
  let hasUpdatedDocumentItems = false;
  const convertedDocumentItems = documentItems.map((item: any) => {
    if (_.includes([TEXT_PARAGRAPH, TEXT_TITLE, SECTION_TITLE], item.itemKind)) {
      hasUpdatedDocumentItems = true;
      return {
        ...item,
        text: getConvertedItemText({item}),
        itemKind: 'TEXT',
      };
    } else {
      return item;
    }
  });
  return {
    hasUpdatedDocumentItems,
    documentItems: convertedDocumentItems,
  };
};

const mergeTextItems = ({documentItems}: any) => {
  const mergedDocumentItems = [];

  for (let i = 0; i < documentItems.length; i += 1) {
    const currentItem = documentItems[i];
    if (currentItem.itemKind === 'TEXT') {
      // Combine consecutive TEXT items
      let combinedText = currentItem.text;

      // Continue combining until a non-TEXT item is encountered or end of array
      while (i + 1 < documentItems.length && documentItems[i + 1].itemKind === 'TEXT') {
        // Since existing items have space between them, add a p tag with a line break (emulates the rich text input behavior)
        combinedText += `<p><br></p>${documentItems[i + 1].text}`;
        i += 1;
      }

      mergedDocumentItems.push({itemKind: 'TEXT', text: combinedText});
    } else {
      // Keep non-TEXT items as they are
      mergedDocumentItems.push(currentItem);
    }
  }

  return mergedDocumentItems;
};

const getNewDocumentItem = (
  itemKind: DocumentItemKindType,
  {
    name = '',
    documentItems = [],
    isFullyEnabledTimesheetsV2,
  }: {
    name?: string;
    documentItems?: DocumentItemType[];
    isFullyEnabledTimesheetsV2?: boolean;
  } = {},
): DocumentItemType => {
  switch (itemKind) {
    case DocumentItemKindV2.SECTION:
      return {
        itemKind,
        name,
        uuid: uuid(),
        documentItems,
      };
    case DocumentItemKindV2.COLUMN:
      return {
        itemKind,
        documentItems,
      };
    case DocumentItemKindV2.TEXT:
    case DocumentItemKindV2.TEXT_TITLE:
    case DocumentItemKindV2.TEXT_PARAGRAPH:
    case DocumentItemKindV2.SECTION_TITLE:
      return {
        itemKind,
        text: '',
      };
    case DocumentItemKindV2.INPUT_SIGNATURE:
      return {
        itemKind,
        isRequired: true,
        input: {
          value: {
            image: '',
            date: '',
          },
        },
      };
    case DocumentItemKindV2.GENERIC_SIGNATURE:
      return {
        itemKind,
        isRequired: true,
        input: {
          value: {
            name: '',
            image: '',
            date: '',
          },
        },
      };
    case DocumentItemKindV2.INPUT_RADIO_BUTTONS:
      return {
        itemKind,
        isRequired: false,
        input: {
          metadata: {
            items: [
              {
                options: [],
                inputs: {},
              },
            ],
          },
          value: {
            values: [['', '', '']],
          },
        },
      };
    case DocumentItemKindV2.INPUT_TEXT_INPUTS:
      return {
        itemKind,
        isRequired: false,
        input: {
          metadata: {
            fields: [
              {
                label: '',
                description: '',
                is_hidden: false,
              },
            ],
          },
          value: {
            values: [''],
          },
        },
      };
    case DocumentItemKindV2.INPUT_TEXT_AREAS:
      return {
        itemKind,
        isRequired: false,
        input: {
          value: {
            values: [['', '']],
          },
        },
      };
    case DocumentItemKindV2.INPUT_GRID:
      return {
        itemKind,
        input: {
          metadata: {
            columns: [
              {
                name: '',
                is_disabled: false,
                is_hidden: false,
              },
              {
                name: '',
                is_disabled: false,
                is_hidden: false,
              },
            ],
            items: [],
          },
          value: {
            values: [['', '']],
          },
        },
      };
    case DocumentItemKindV2.INPUT_LIST:
      return {
        itemKind,
        input: {
          value: {
            values: ['', ''],
          },
        },
      };
    case DocumentItemKindV2.IMAGE:
      return {
        itemKind,
        input: {
          width: '',
          height: '',
          position: DocumentV2Image.POSITION.CENTER,
          metadata: {
            fileId: null,
            size: 0,
            width: 0,
            height: 0,
          },
        },
      };
    case DocumentItemKindV2.INVENTORY_SUMMARY:
    case DocumentItemKindV2.SURVEY_CARTONS_SUMMARY:
    case DocumentItemKindV2.SURVEY_ITEMS_SUMMARY:
    case DocumentItemKindV2.SURVEY_ROOMS_SUMMARY:
      return {
        itemKind,
        input: {
          showNotes: false,
          showWeight: true,
          showVolume: true,
        },
      };
    case DocumentItemKindV2.TIMESHEET_INFORMATION:
    case DocumentItemKindV2.INPUT_PER_MOVER_TIMESHEET:
      if (isFullyEnabledTimesheetsV2) {
        return {
          itemKind,
          input: {
            showMoverNames: false,
          },
        };
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return {
        itemKind,
      };
  }
};

const DocumentItemKindV2 = {
  // Page setup items
  SECTION,
  COLUMN,

  // Document display items
  TEXT,
  TEXT_TITLE,
  TEXT_PARAGRAPH,
  ORGANIZATION_SIGNATURE,
  BILLING_INFORMATION,
  TIMESHEET_INFORMATION,
  VALUATION_DECLARATION_TABLE,
  SURVEY_CARTONS_SUMMARY,
  SURVEY_ITEMS_SUMMARY,
  SURVEY_ROOMS_SUMMARY,
  INVENTORY_SUMMARY,
  GENERAL_INVENTORY_SUMMARY,
  IMAGE,
  INVENTORY_ITEMS_SUMMARY,
  INPUT_PER_MOVER_TIMESHEET,
  INPUT_COMMERCIAL_CATALOG_EQUIPMENTS,
  INPUT_COMMERCIAL_CATALOG_MATERIALS,
  INPUT_GRID,
  INPUT_LIST,
  INPUT_RADIO_BUTTONS,
  INPUT_TEXT_INPUTS,
  INPUT_TEXT_AREAS,
  INPUT_VALUATION_COVERAGE,
  INPUT_SIGNATURE,
  GENERIC_SIGNATURE,
  SECTION_TITLE,
  DOCUMENT_HEADER,
  LOGO,
  // INVOICE Category document item kind
  INVOICE_SUMMARY_INFORMATION,

  DOCUMENT_ITEM_KIND_TABLE_VALUES: {
    // Document Elements
    DOCUMENT_HEADER: {
      displayName: 'Header',
      displayDescription: 'Displays your logo and business information.',
    },
    LOGO: {
      displayName: 'Logo',
      displayDescription: 'Displays your company logo.',
    },
    IMAGE: {
      displayName: 'Image',
      displayDescription: 'Allows you to upload an image from your device.',
    },
    TEXT: {
      displayName: 'Text',
      displayDescription: 'Adds a custom text block.',
    },
    // Inputs
    INPUT_LIST: {
      displayName: 'List Input',
      displayDescription: 'Inserts a blank numbered list.',
    },
    INPUT_RADIO_BUTTONS: {
      displayName: 'Multiple Choice Input',
      displayDescription: 'Adds multiple options to choose from.',
    },
    INPUT_TEXT_INPUTS: {
      displayName: 'Single-Line Input',
      displayDescription: 'Adds an input for short answers.',
    },
    INPUT_TEXT_AREAS: {
      displayName: 'Multi-Line Input',
      displayDescription: 'Adds an input for long answers.',
    },
    INPUT_GRID: {
      displayName: 'Table Input',
      displayDescription: 'Inserts a table with adjustable # of columns and rows.',
    },
    // Signatures
    GENERIC_SIGNATURE: {
      displayName: 'Generic Signature',
      displayDescription: 'Adds a generic signature line for any signer.',
    },
    ORGANIZATION_SIGNATURE: {
      displayName: 'Company Signature',
      displayDescription: 'Adds a signature line for your company.',
    },
    INPUT_SIGNATURE: {
      displayName: 'Customer Signature',
      displayDescription: 'Adds a signature line for the customer.',
    },
    // Survey
    SURVEY_CARTONS_SUMMARY: {
      displayName: 'Cartons Survey Summary',
      displayDescription: "Adds a carton summary from the project's survey.",
    },
    SURVEY_ITEMS_SUMMARY: {
      displayName: 'Items Survey Summary',
      displayDescription: "Adds an item summary from the project's survey.",
    },
    INVENTORY_SUMMARY: {
      displayName: 'Items and Cartons Survey Summary',
      displayDescription: "Adds an item and carton summary from the project's survey.",
    },
    SURVEY_ROOMS_SUMMARY: {
      displayName: 'Rooms Survey Summary',
      displayDescription: "Adds a room summary form the project's survey.",
    },
    // Inventory
    INVENTORY_ITEMS_SUMMARY: {
      displayName: 'Items Inventory Summary',
      displayDescription: "Adds an item summary from the project's inventory.",
    },
    GENERAL_INVENTORY_SUMMARY: {
      displayName: 'Inventory Summary',
      displayDescription: "Adds a summary of the project's inventory.",
    },
    // Valuation Coverage
    INPUT_VALUATION_COVERAGE: {
      category: DocumentTemplateCategory.JOB,
      displayName: 'Valuation Coverage Input',
      displayDescription: 'Adds an input for the customer to select their valuation coverage.',
    },
    VALUATION_DECLARATION_TABLE: {
      category: DocumentTemplateCategory.JOB,
      displayName: 'Valuation Options',
      displayDescription: 'Adds a table of valuation options.',
    },
    // Billing
    BILLING_INFORMATION: {
      displayName: 'Billing Summary',
      displayDescription: 'Adds the bills fom the project and its jobs.',
    },
    INVOICE_SUMMARY_INFORMATION: {
      category: DocumentTemplateCategory.INVOICE,
      displayName: 'Invoice Summary Information',
      displayDescription: 'Adds the invoice from the project.',
    },
    // Equipment & Materials
    INPUT_COMMERCIAL_CATALOG_EQUIPMENTS: {
      category: DocumentTemplateCategory.JOB,
      displayName: 'Equipment Input',
      displayDescription: 'Adds a table listing equipment used on the project.',
    },
    INPUT_COMMERCIAL_CATALOG_MATERIALS: {
      category: DocumentTemplateCategory.JOB,
      displayName: 'Materials Input',
      displayDescription: 'Adds a table listing materials used on the project.',
    },
    // Timesheets
    TIMESHEET_INFORMATION: {
      category: DocumentTemplateCategory.JOB,
      displayName: 'Job Timesheet',
      displayDescription: 'Adds a table of logged hours from the crew on the job.',
    },
    INPUT_PER_MOVER_TIMESHEET: {
      category: DocumentTemplateCategory.JOB,
      displayName: 'Per-Position Timesheet Input',
      displayDescription: 'Adds a table of logged hours on the job broken down by position.',
    },
    // DEPRECATED
    SECTION_TITLE: {
      displayName: 'Section Title',
      displayDescription: 'Bold centered title for a new section',
    },
    TEXT_TITLE: {
      displayName: 'Paragraph Title',
      displayDescription: 'Title for a new paragraph',
    },
    TEXT_PARAGRAPH: {
      displayName: 'Paragraph',
      displayDescription: 'Block of text',
    },
  },
  VALUES: DROPDOWN_VALUES,
  DISABLED_FOR_COLUMNS,

  getNewDocumentItem,
  getDocumentItemKindBasedOnCategory,
  getItemDisplayName,
  convertTextItems,
  mergeTextItems,
  getDropdownSections,
  getDescription,
  getIsDisabledForColumn,
};

export default DocumentItemKindV2;
