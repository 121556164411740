// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import InvoiceForm from '@shared/modules/Billing/forms/InvoiceForm';
import useCancelInvoiceMutation from '@shared/modules/Billing/hooks/useCancelInvoiceMutation';

const CancelInvoiceModal = ({invoiceId, invoiceIdentifier, isOpen, handleClose, refetch}) => {
  const invoiceForm = InvoiceForm.idOnly({invoiceId});
  const {handleSubmit, submitting} = useCancelInvoiceMutation({
    invoiceForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Cancel invoice ${invoiceIdentifier}?`}
      subtitle={'We will notify the customer of the cancellation. You can’t undo this action.'}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      deleteButtonText={'Cancel Invoice'}
      cancelButtonText={'Nevermind'}
      isSubmitting={submitting}
    />
  );
};

export default CancelInvoiceModal;
