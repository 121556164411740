// Libraries
import React from 'react';

// App
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// Components
import Avatar from 'modules/App/components/Avatar';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-vertical: 10px;
  padding-horizontal: ${(props) => (props.mobile ? 10 : 0)}px;
  background-color: ${colors.alpha(colors.white, 0.2)};
  border-radius: ${(props) => (props.mobile ? 0 : 3)}px;
`;

const UserInfo = Styled.View`
  flex: 1;
  margin-left: 10px;
`;

const UserName = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.white};
`;

const OrganizationName = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.gray.tertiary};
`;

const Caret = Styled.View`
  padding-left: 5px;
`;

const SidebarAvatar = ({size, user}) => {
  const responsive = useResponsive();

  return (
    <Container {...responsive}>
      <Avatar text={User.getInitials(user)} size={size} />
      {responsive.mobile && (
        <UserInfo>
          <UserName numberOfLines={1}>{User.getFullName(user)}</UserName>
          <OrganizationName numberOfLines={1}>{user.viewingOrganization.name}</OrganizationName>
        </UserInfo>
      )}
      <Caret>
        <Icon color={colors.gray.tertiary} size={Icon.Sizes.Large} source={Icon.CaretDown} />
      </Caret>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SidebarAvatar.fragment = gql`
  fragment SidebarAvatar on User {
    id
    firstName
    lastName
    viewingOrganization {
      id
      name
    }
  }
`;

export default SidebarAvatar;
