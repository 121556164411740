// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';

const useCreateWorkflowMutation = ({workflowForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      workflowForm: WorkflowForm.toForm(workflowForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateWorkflowMutation.mutation,
    variables: {
      workflowForm: WorkflowForm.toMutation(form.values.workflowForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateWorkflowMutation.mutation = gql`
  mutation useCreateWorkflowMutation($workflowForm: WorkflowForm!) {
    response: createWorkflow(workflowForm: $workflowForm) {
      ${gql.errors}
      workflow {
        id
        uuid
      }
    }
  }
`;

export default useCreateWorkflowMutation;
