// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

import ItemForm from './ItemForm';

const getLeaveCount = ({itemForms}: any) => {
  return _.sumBy(itemForms, (itemForm) => ItemForm.getLeaveCount(itemForm));
};

const getTakeCount = ({itemForms}: any) => {
  return _.sumBy(itemForms, (itemForm) => ItemForm.getTakeCount(itemForm));
};

const getTotalVolume = ({itemForms}: any) => {
  return _.round(
    _.sumBy(itemForms, (itemForm) => {
      return ItemForm.getTakeCount(itemForm) * ItemForm.getVolume(itemForm);
    }),
    2,
  );
};

const getTotalWeight = ({itemForms}: any) => {
  return _.round(
    _.sumBy(itemForms, (itemForm) => {
      return ItemForm.getTakeCount(itemForm) * ItemForm.getWeight(itemForm);
    }),
    2,
  );
};

const edit = withFragment(
  (collection, {selectedCategoryId}) => ({
    collectionId: (collection as any).id,
    itemForms: _.orderBy(
      (collection as any).items.map((item: any) => ItemForm.edit(item)),
      ['kind'],
      ['desc'],
    ),
    // Private
    searchQuery: '',
    selectedCategoryId,
    hasChanges: false,
    hasSavedChanges: false,
    isSubmitting: false,
  }),
  gql`
    ${ItemForm.edit.fragment}

    fragment CollectionForm_edit on Collection {
      id
      items {
        id
        ...ItemForm_edit
      }
    }
  `,
);

const toForm = ({
  collectionId,
  itemForms,
  searchQuery,
  selectedCategoryId,
  hasChanges,
  hasSavedChanges,
  isSubmitting,
}: any) => ({
  collectionId,
  itemForms: itemForms.map((itemForm: any) => ItemForm.toForm(itemForm)),

  // Private
  searchQuery,
  selectedCategoryId,
  hasChanges,
  hasSavedChanges,
  isSubmitting,
});

const toMutation = ({collectionId, itemForms}: any) => ({
  collectionId,
  itemForms: itemForms.map((itemForm: any) => ItemForm.toMutation(itemForm)),
});

const CollectionForm = {
  // Initialize
  edit,

  // Getters
  getLeaveCount,
  getTakeCount,
  getTotalVolume,
  getTotalWeight,

  // Conversion
  toForm,
  toMutation,
};

export default CollectionForm;
