// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Form} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {MoveUser} from '@supermove/models';

// Components
import UpdateReportMoveMoveUsersMutation from './UpdateReportMoveMoveUsersMutation';

const getInitialMoveUserInputs = ({reportMove}) => {
  return reportMove.moveUsers.map((moveUser) => {
    return MoveUser.toForm(moveUser);
  });
};

const UpdateReportMoveMoveUsersForm = ({reportMove, onSuccess, onError, children}) => {
  return (
    <Form
      enableReinitialize
      initialValues={{
        reportMoveId: reportMove.id,
        moveUserInputs: getInitialMoveUserInputs({reportMove}),
      }}
      onSubmit={(values) => {}}
    >
      {({submitForm, setMutationErrors, ...form}) => (
        <UpdateReportMoveMoveUsersMutation
          reportMoveId={reportMove.id}
          moveUserInputs={form.values.moveUserInputs.map((moveUserInput) => {
            return MoveUser.toMutation(moveUserInput);
          })}
          onSuccess={onSuccess}
          setMutationErrors={setMutationErrors}
          submitForm={submitForm}
        >
          {({loading, handleSubmit}) =>
            children({
              ...form,
              loading,
              handleSubmit,
            })
          }
        </UpdateReportMoveMoveUsersMutation>
      )}
    </Form>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
UpdateReportMoveMoveUsersForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

UpdateReportMoveMoveUsersForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateReportMoveMoveUsersForm.fragment = gql`
  fragment UpdateReportMoveMoveUsersForm on ReportMove {
    id
    uuid
    moveUsers {
      jobId
      moveId
      userId
      timePayableAdjustment
      tip
      user {
        id
        firstName
        lastName
        organization {
          id
          name
        }
      }
    }
  }
`;

export default UpdateReportMoveMoveUsersForm;
