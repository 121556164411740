// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import Drawer from '@shared/design/components/Drawer';
import TextMessageTemplateCategory from '@shared/modules/Sms/enums/TextMessageTemplateCategory';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SmsEditor from 'modules/Communication/components/SmsEditor';

const Row = Styled.View`
  flex-direction: row;
`;

const Button = Styled.ButtonV2`
`;

const Container = Styled.View`
  flex: 1;
`;

const Text = Styled.Text`
  ${Typography.Body}
  color: ${({color}) => color};
`;

const HeaderContainer = Styled.View`
  background-color: ${colors.white};
`;

const HeaderTextContainer = Styled.View`
  padding-horizontal: 24px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading2}
`;

const getPhoneNumberText = ({customer}) => {
  if (customer.phoneNumber) {
    return Phone.display(customer.phoneNumber);
  }
  return 'No phone number';
};

const StaffCommunicationDrawerHeader = ({customer, handleClose}) => {
  return (
    <HeaderContainer>
      <HeaderTextContainer>
        <Space height={24} />
        <Row>
          <TitleText>{customer.fullName}</TitleText>
          <Space style={{flex: 1}} />
          <Button
            onPress={() => {
              handleClose();
            }}
          >
            <Icon source={Icon.Times} size={24} color={colors.gray.primary} />
          </Button>
        </Row>
        <Space height={8} />
        <Text color={colors.gray.secondary}>{getPhoneNumberText({customer})}</Text>
      </HeaderTextContainer>
      <Space height={16} />
    </HeaderContainer>
  );
};

const StaffCommunicationDrawer = ({user, handleMissingContactInfo, isOpen, handleClose}) => {
  const {loading, data, refetch} = useQuery(StaffCommunicationDrawer.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      userUuid: user.uuid,
      categories: TextMessageTemplateCategory.getNonCustomerCategories(),
    },
  });

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} width={500}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          if (!data) {
            return null;
          }
          return (
            <Container>
              <StaffCommunicationDrawerHeader customer={data.user} handleClose={handleClose} />
              <SmsEditor
                user={data.user}
                viewer={data.viewer}
                handleMissingPhoneNumber={handleMissingContactInfo}
                refetch={refetch}
                textMessageTemplates={data.user.organization.textMessageTemplatesByCategory}
              />
            </Container>
          );
        }}
      </Loading>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StaffCommunicationDrawer.query = gql`
  ${SmsEditor.fragment}

  query StaffCommunicationDrawer(
    $userUuid: String!,
    $categories: [String],
    ) {
      ${gql.query}
      viewer {
        id
        ...SmsEditor_Viewer
      }
      user(uuid: $userUuid) {
        id
        email
        organization {
          id
          textMessageTemplatesByCategory(categories: $categories) {
            id
            ...SmsEditor_TextMessageTemplate
          }
        }
        ...SmsEditor_User
      }
    }
`;

StaffCommunicationDrawer.fragment = gql`
  fragment StaffCommunicationDrawer on User {
    id
    uuid
  }
`;

export default StaffCommunicationDrawer;
