// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job, Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index}px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  padding-horizontal: 16px;
`;

const Title = Styled.Text`
  ${Typography.Label1}
`;

const JobAdditionalItemsBlock = ({sectionIndex, job}) => {
  const responsive = useResponsive();
  const additionalItems = Job.getAdditionalItems(job);
  if (_.isEmpty(additionalItems)) {
    return null;
  }
  return (
    <Section index={sectionIndex}>
      <Space height={16} />
      <Title vars={responsive}>Additional Information</Title>
      <Space height={responsive.mobile ? 0 : 16} />
      {_.map(additionalItems, (value, key) => (
        <React.Fragment key={key}>
          <FieldValue
            label={Organization.getJobFormAdditionalItemLabel(job.organization, {key})}
            value={Organization.getJobFormAdditionalItemValue(job.organization, {key, value})}
            empty={'None'}
          />
          <Space height={responsive.mobile ? 8 : 16} />
        </React.Fragment>
      ))}
    </Section>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobAdditionalItemsBlock.fragment = gql`
  ${Job.getAdditionalItems.fragment}
  ${Organization.getJobFormAdditionalItemLabel.fragment}
  ${Organization.getJobFormAdditionalItemValue.fragment}
  fragment JobAdditionalItemsBlock on Job {
    id
    organization {
      id
      ...Organization_getJobFormAdditionalItemLabel
      ...Organization_getJobFormAdditionalItemValue
    }
    ...Job_getAdditionalItems
  }
`;

export default JobAdditionalItemsBlock;
