// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Tabs, Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import DashboardCategory from '@shared/modules/Reports/enums/DashboardCategory';

const TabText = Styled.Text`
  ${({vars}) => (vars.isSelected ? Typography.Label1 : Typography.Body2)};
  color: ${({vars}) => (vars.isSelected ? colors.gray.primary : colors.gray.primary)};
`;

const SelectedTabBottomBorder = Styled.View`
  left: 0px;
  bottom: 0px;
  position: absolute;
  background-color: ${colors.gray.primary};
  width: 100%;
  height: 4px;
  border-radius: 2px;
`;

const Tab = ({tab, isSelected}) => {
  const {navigator} = useNavigationDOM();

  return (
    <Tabs.TabContainer
      onPress={() => navigator.push(tab.value)}
      isSelected={isSelected}
      style={{
        marginHorizontal: 12,
        borderBottomWidth: 0,
      }}
    >
      <TabText vars={{isSelected}}>
        {tab.label}
        {isSelected && <SelectedTabBottomBorder />}
      </TabText>
    </Tabs.TabContainer>
  );
};

const getReportViewTabs = (organization) => {
  const {reportViews, dashboards} = organization;
  const calendarReport = organization.features.isEnabledDeprecateReportsCalendar
    ? []
    : [
        {
          key: 'calendar',
          title: 'Calendar',
          link: '/reports/calendar',
        },
      ];

  const dashboardReports = dashboards
    .filter((dashboard) => dashboard.category === DashboardCategory.GENERAL)
    .map((dashboard) => ({
      key: dashboard.uuid,
      title: dashboard.name,
      link: `/reports/dashboards/${dashboard.uuid}`,
    }));

  const metabaseReports = reportViews.map((metabaseReport) => ({
    key: metabaseReport.slug,
    title: metabaseReport.title,
    link: `/reports/${metabaseReport.slug}`,
  }));

  return [...calendarReport, ...dashboardReports, ...metabaseReports];
};

const ReportsNavigation = () => {
  const {
    navigator: {location},
  } = useNavigationDOM();
  const {loading, data} = useQuery(ReportsNavigation.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Loading loading={loading}>
      {() => {
        const tabs = getReportViewTabs(data.organization);
        const currentTab = _.findIndex(tabs, {link: location.pathname});

        return (
          <Tabs.TabBar
            selectedIndex={currentTab}
            handleChangeIndex={() => {}}
            TabComponent={Tab}
            style={{
              paddingTop: 12,
              paddingHorizontal: 12,
              borderBottomWidth: 1,
              borderBottomColor: colors.gray.border,
            }}
            showFullWidthBar
            fullWidthBarColor={colors.white}
            scrollEnabled
          >
            {tabs.map(({key, title, link}) => (
              <Tabs.Tab key={key} tab={{label: title, value: link}} />
            ))}
          </Tabs.TabBar>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportsNavigation.query = gql`
  query ReportsNavigation {
    ${gql.query}
    organization {
      id
      dashboards {
        id
        name
        uuid
        category
      }
      reportViews {
        title
        slug
      }
      features {
        isEnabledDeprecateReportsCalendar: isEnabled(feature: "DEPRECATE_REPORTS_CALENDAR")
      }
    }
  }
`;

export default ReportsNavigation;
