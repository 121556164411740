// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';

const useDeleteProjectTypeMutation = ({projectTypeForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      projectTypeForm: ProjectTypeForm.toForm(projectTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteProjectTypeMutation.mutation,
    variables: {
      projectTypeForm: ProjectTypeForm.toMutation(form.values.projectTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteProjectTypeMutation.mutation = gql`
  mutation useDeleteProjectTypeMutation($projectTypeForm: ProjectTypeForm!) {
    response: deleteProjectType(projectTypeForm: $projectTypeForm) {
      ${gql.errors}
      projectType {
        id
      }
    }
  }
`;

export default useDeleteProjectTypeMutation;
