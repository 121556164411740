// Libraries
import _ from 'lodash';

// Supermove
import {Currency} from '@supermove/utils';

// App
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';

const OTHER_METHOD = {
  value: 'OTHER',
  name: 'Other',
  settingsName: 'Other',
  action: 'Other',
  projectBillingScreen: 'OtherMethodNewPaymentBillingProjectJobPage',
};

const Payment = {
  /**
   * List of methods that customers can pay with.
   *   value: Database value.
   *   name: Name of the payment method shown with "Paid By: {name}".
   *   action: Name shown on the Crew app to use this method.
   *   projectBillingScreen: Crew app screen that should be navigated to.
   */
  METHODS: [
    {
      value: PaymentMethodKind.CASH,
      name: 'Cash',
      settingsName: 'Cash',
      action: 'Cash',
      projectBillingScreen: 'CashMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: PaymentMethodKind.CHECK,
      name: 'Check',
      settingsName: 'Check',
      action: 'Check',
      projectBillingScreen: 'CheckMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: PaymentMethodKind.ACH,
      name: 'ACH',
      settingsName: 'ACH',
      action: 'ACH',
      // This method isn't supported on the crew app so we have it route to the "Other" page for now.
      projectBillingScreen: 'OtherMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: PaymentMethodKind.AUTHORIZE_DOT_NET,
      name: 'Credit Card (Authorize.net)',
      settingsName: 'Credit Card (Authorize.net)',
      action: 'Credit Card - Pay Now',
      projectBillingScreen: 'AuthorizeDotNetMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: PaymentMethodKind.EXTERNAL,
      name: 'External Credit Card',
      settingsName: 'External Credit Card',
      action: 'External Credit Card',
      projectBillingScreen: 'ExternalMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: PaymentMethodKind.PAYPAL,
      name: 'PayPal',
      settingsName: 'PayPal',
      action: 'PayPal',
      projectBillingScreen: 'PaypalMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: PaymentMethodKind.PAYENGINE_CREDIT_CARD,
      name: 'Credit Card',
      settingsName: 'Credit Card (Supermove)',
      action: 'Credit Card - Pay Now',
      projectBillingScreen: 'PayEngineCreditCardMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: PaymentMethodKind.STRIPE_CREDIT_CARD,
      name: 'Credit Card',
      settingsName: 'Credit Card (Stripe)',
      action: 'Credit Card - Pay Now',
      projectBillingScreen: 'CreditCardMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: PaymentMethodKind.STRIPE_TERMINAL,
      name: 'Swiped Credit Card',
      settingsName: 'Swiped Credit Card',
      action: 'Swipe Credit Card',
    },
    {
      value: PaymentMethodKind.VENMO,
      name: 'Venmo',
      settingsName: 'Venmo',
      action: 'Venmo',
      projectBillingScreen: 'VenmoMethodNewPaymentBillingProjectJobPage',
    },

    // Pay Later options, these are not valid PaymentMethods but they have
    // options that the customer can select.
    {
      value: PaymentMethodKind.INVOICE,
      name: 'Invoice',
      settingsName: 'Invoice',
      action: 'Invoice - Pay Later',
      projectBillingScreen: 'InvoiceMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: PaymentMethodKind.STRIPE_SAVE_CARD,
      name: 'Save Card & Pay Later',
      settingsName: 'Save Card & Pay Later',
      action: 'Save Card & Pay Later',
      projectBillingScreen: 'SaveCardMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: PaymentMethodKind.PAYENGINE_SAVE_CARD,
      name: 'Save Card & Pay Later',
      settingsName: 'Save Card & Pay Later',
      action: 'Save Card & Pay Later',
      projectBillingScreen: 'PayEngineSaveCardMethodNewPaymentBillingProjectJobPage',
    },

    // Generic catch-all method for when a PaymentMethod isn't known.
    OTHER_METHOD,
  ],

  /**
   * DEPRECATED: Use paymentMethod.isEnabledForOffice instead.
   * TODO(jholston): Remove with isEnabledCustomPaymentMethods
   *
   * Used to determine the methods available in the `Payment > Record Payment` dropdown on the job
   * page. Credit card methods are hidden here, because the `Payment > Charge Credit Card` option
   * should handle those charges
   */
  getOfficeMethodOptions: () => {
    // Credit card methods are hidden here
    const hiddenOfficeMethods = [
      PaymentMethodKind.STRIPE_SAVE_CARD,
      PaymentMethodKind.INVOICE,
      PaymentMethodKind.STRIPE_TERMINAL,
      PaymentMethodKind.STRIPE_CREDIT_CARD,
      PaymentMethodKind.PAYENGINE_CREDIT_CARD,
      PaymentMethodKind.PAYENGINE_SAVE_CARD,
    ];
    const visibleOfficeMethods = Payment.METHODS.filter(
      (method) => !_.includes(hiddenOfficeMethods, method.value),
    );
    return _.sortBy(visibleOfficeMethods, (method) => method.name);
  },

  /**
   * Returns true if the specified method is a known method this client can handle.
   */
  isValidMethod: ({method}: any) => {
    return !!_.find(Payment.METHODS, (option) => option.value === method);
  },

  isCreditCardMethod: ({method}: any) => {
    return [PaymentMethodKind.PAYENGINE_CREDIT_CARD, PaymentMethodKind.STRIPE_CREDIT_CARD].includes(
      method,
    );
  },

  getMethodOption: ({method}: any) => {
    return _.find(Payment.METHODS, (option) => option.value === method) || OTHER_METHOD;
  },

  getMethodOptions: ({methods}: any) => {
    return methods.map((method: any) => Payment.getMethodOption({method}));
  },

  /**
   * DEPRECATED: Use paymentMethod.display_method instead.
   * TODO(jholston): Remove with isEnabledCustomPaymentMethods
   *
   * Name of the payment method displayed on billing-related pages. This should be the default used
   * in most parts of the app.
   */
  getDisplayMethod: ({method}: any) => {
    return _.get(Payment.getMethodOption({method}), 'name');
  },

  /**
   * DEPRECATED: Use paymentMethod.name instead.
   * TODO(jholston): Remove with isEnabledCustomPaymentMethods
   *
   * Name of the payment method displayed on settings pages. This displays more details on the
   * underlying payment processor (e.g. 'Credit Card (Stripe)' instead of just 'Credit Card').
   */
  getSettingsDisplayMethod: ({method}: any) => {
    return _.get(Payment.getMethodOption({method}), 'settingsName');
  },

  getMethodDropdownOptions: ({methods}: any) => {
    const options = Payment.getMethodOptions({methods});
    return options.map((option: any) => ({
      label: option.name,
      value: option.value,
    }));
  },

  /**
   * DEPRECATED: Use paymentMethod.name instead.
   * TODO(jholston): Remove with isEnabledCustomPaymentMethods
   */
  getActionForMethod: ({method}: any) => {
    return _.get(Payment.getMethodOption({method}), 'action');
  },

  /**
   * DEPRECATED: Use getPaymentScreenForPaymentMethod instead.
   * TODO(jholston): Remove with isEnabledCustomPaymentMethods
   */
  getProjectBillingScreenForMethod: ({method}: any) => {
    return _.get(Payment.getMethodOption({method}), 'projectBillingScreen');
  },

  /**
   * For payment methods that have dedicated logic, return the specific screen for that method.
   * For all other payment methods, return the generic payment page.
   */
  getPaymentScreenForPaymentMethod: ({kind}: {kind: string}) => {
    if (
      _.includes(
        [
          PaymentMethodKind.AUTHORIZE_DOT_NET,
          PaymentMethodKind.PAYENGINE_CREDIT_CARD,
          PaymentMethodKind.STRIPE_CREDIT_CARD,
          PaymentMethodKind.INVOICE,
          PaymentMethodKind.STRIPE_SAVE_CARD,
          PaymentMethodKind.PAYENGINE_SAVE_CARD,
          // Stripe Terminal is deprecated and should be removed.
          PaymentMethodKind.STRIPE_TERMINAL,
        ],
        kind,
      )
    ) {
      return _.get(Payment.getMethodOption({method: kind}), 'projectBillingScreen');
    }
    return 'DefaultPaymentPage';
  },

  toForm: ({paymentId, method, totalAmount}: any) => ({
    paymentId,
    method,
    totalAmount: Currency.toForm(totalAmount),
  }),

  toMutation: ({paymentId, method, totalAmount}: any) => ({
    paymentId,
    method,
    totalAmount: Currency.toMutation(totalAmount),
  }),
};

export default Payment;
