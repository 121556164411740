// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {FilteredProjectsForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {titleize} from '@supermove/utils';

// App
import SegmentedControls from '@shared/design/components/SegmentedControls';

const FilterButtonGroup = ({name, form, statuses, projectCountsBySalesStatus}) => {
  const selectedSalesStatuses = form.values.filteredProjectsForm.salesStatuses;
  const handlePress = (status) => {
    // Using _.xor() to remove a value if it already exists, otherwise add it
    const newSalesStatuses = _.xor(selectedSalesStatuses, [status]);
    form.setFieldValue(`${name}.salesStatuses`, newSalesStatuses);
  };

  if (statuses.length === 0) {
    return <Space />;
  }

  return (
    <React.Fragment>
      <SegmentedControls
        segmentDefinitions={statuses.map((status) => {
          const isSelected = _.includes(selectedSalesStatuses, status);
          const count = projectCountsBySalesStatus[`${_.camelCase(status)}Count`];
          return {
            label: `${titleize(status.replace('_', ' '))}`,
            count,
            isSelected,
            isDisabled: isSelected && selectedSalesStatuses.length === 1,
            onPress: () => handlePress(status),
          };
        })}
      />
      {statuses.length && <Space height={16} />}
    </React.Fragment>
  );
};

const ListProjectsPageSalesStatusFilter = ({
  status,
  form,
  filteredProjectCountsBySalesStatus,
  isEnabledProjectHoldStatus,
  isEnabledSalesStatusDepositReceived,
}) => {
  const formName = 'filteredProjectsForm';
  const availableSalesStatuses = FilteredProjectsForm.getAvailableSalesStatuses({
    status,
    isEnabledProjectHoldStatus,
    isEnabledSalesStatusDepositReceived,
  });

  return (
    <FilterButtonGroup
      form={form}
      name={formName}
      statuses={availableSalesStatuses}
      projectCountsBySalesStatus={filteredProjectCountsBySalesStatus}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListProjectsPageSalesStatusFilter.fragment = gql`
  fragment ListProjectsPageSalesStatusFilter on Organization {
    id
    features {
      isEnabledProjectHoldStatus: isEnabled(feature: "PROJECT_HOLD_STATUS")
      isEnabledSalesStatusDepositReceived: isEnabled(feature: "SALES_STATUS_DEPOSIT_RECEIVED")
    }
  }
`;

ListProjectsPageSalesStatusFilter.countsFragment = gql`
  fragment ListProjectsPageSalesStatusFilter_countsFragment on ProjectCountsBySalesStatus {
    newCount
    quotePendingCount
    quoteAcceptedCount
    depositReceivedCount
    confirmationPendingCount
    confirmationCompletedCount
  }
`;

export default ListProjectsPageSalesStatusFilter;
