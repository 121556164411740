/*
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, usePopover} from '@supermove/hooks';
import {BillingLibrary} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import BillingLibraryForm from '@shared/modules/Billing/forms/BillingLibraryForm';
import useCreateBillingLibraryMutation from '@shared/modules/Billing/hooks/useCreateBillingLibraryMutation';
import useDeleteBillingLibraryMutation from '@shared/modules/Billing/hooks/useDeleteBillingLibraryMutation';
import DeleteModal from 'modules/App/components/DeleteModal';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import CannotDeleteBillingLibraryModal from 'modules/Organization/Settings/BillingLibraries/components/CannotDeleteBillingLibraryModal';
import CannotDeleteDefaultBillingLibraryModal from 'modules/Organization/Settings/BillingLibraries/components/CannotDeleteDefaultBillingLibraryModal';

const MenuOptionButton = Styled.ButtonV2`
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const IconButton = Styled.ButtonV2`
  padding-horizontal: 8px;
`;

const DeleteBillingLibraryModal = ({deleteBillingLibraryModal, billingLibrary, refetch}) => {
  const billingLibraryForm = BillingLibraryForm.edit(billingLibrary);
  const {handleSubmit} = useDeleteBillingLibraryMutation({
    billingLibraryForm,
    onSuccess: () => {
      deleteBillingLibraryModal.handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={deleteBillingLibraryModal.isOpen}
      title={'Are you sure you want to delete this billing library?'}
      subtitle={"You can't undo this action."}
      handleClose={deleteBillingLibraryModal.handleClose}
      handleConfirm={handleSubmit}
    />
  );
};

const ActionMenuItem = ({onPress, children, submitting}) => {
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      <MenuOptionButton
        onPress={onPress}
        isHovered={isHovered}
        ref={ref}
        activeOpacity={0.8}
        disabled={submitting}
      >
        <MenuItemText>{children}</MenuItemText>
      </MenuOptionButton>
      <Space height={2} />
    </React.Fragment>
  );
};

const BillingLibraryActionsPopover = ({
  billingLibraryActionsPopover,
  deleteBillingLibraryModal,
  cannotDeleteBillingLibraryModal,
  cannotDeleteDefaultBillingLibraryModal,
  billingLibrary,
  refetch,
}) => {
  const isDefaultBillingLibrary = BillingLibrary.getIsDefaultBillingLibrary(billingLibrary);
  const isAssignedToProjectType = BillingLibrary.getIsAssignedToProjectType(billingLibrary);
  const handleOpenDeleteBillingLibraryModal = () => {
    if (isDefaultBillingLibrary) {
      cannotDeleteDefaultBillingLibraryModal.handleOpen();
    } else if (isAssignedToProjectType) {
      cannotDeleteBillingLibraryModal.handleOpen();
    } else {
      deleteBillingLibraryModal.handleOpen();
    }
    billingLibraryActionsPopover.handleClose();
  };
  const billingLibraryForm = BillingLibraryForm.duplicate(billingLibrary);
  const createBillingLibraryMutation = useCreateBillingLibraryMutation({
    billingLibraryForm,
    onSuccess: () => {
      billingLibraryActionsPopover.handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  return (
    <Popover
      placement={Popover.Positions.RightStart}
      isOpen={billingLibraryActionsPopover.isOpen}
      handleOpen={billingLibraryActionsPopover.handleOpen}
      handleClose={billingLibraryActionsPopover.handleClose}
      reference={billingLibraryActionsPopover.ref}
    >
      <ResponsivePopover.StaticContainer width={160}>
        <Space height={8} />
        <ActionMenuItem
          onPress={createBillingLibraryMutation.handleSubmit}
          submitting={createBillingLibraryMutation.submitting}
        >
          Duplicate
        </ActionMenuItem>
        <Space height={4} />
        <ActionMenuItem onPress={handleOpenDeleteBillingLibraryModal}>Delete</ActionMenuItem>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const BillingLibraryActionsButton = ({billingLibrary, refetch}) => {
  const billingLibraryActionsPopover = usePopover();
  const deleteBillingLibraryModal = useModal();
  const cannotDeleteBillingLibraryModal = useModal();
  const cannotDeleteDefaultBillingLibraryModal = useModal();

  return (
    <React.Fragment>
      <Popover.Content innerRef={billingLibraryActionsPopover.ref}>
        <IconButton onPress={billingLibraryActionsPopover.handleToggle}>
          <Icon
            source={Icon.EllipsisV}
            color={
              billingLibraryActionsPopover.isOpen ? colors.blue.interactive : colors.gray.secondary
            }
            size={16}
          />
        </IconButton>
      </Popover.Content>
      <BillingLibraryActionsPopover
        key={`BILLING_LIBRARY_ACTIONS_POPOVER-${billingLibraryActionsPopover.isOpen}`}
        billingLibraryActionsPopover={billingLibraryActionsPopover}
        deleteBillingLibraryModal={deleteBillingLibraryModal}
        cannotDeleteBillingLibraryModal={cannotDeleteBillingLibraryModal}
        cannotDeleteDefaultBillingLibraryModal={cannotDeleteDefaultBillingLibraryModal}
        billingLibrary={billingLibrary}
        refetch={refetch}
      />
      <DeleteBillingLibraryModal
        key={`DELETE_BILLING_LIBRARY-${deleteBillingLibraryModal.isOpen}`}
        isOpen={deleteBillingLibraryModal.isOpen}
        handleClose={deleteBillingLibraryModal.handleClose}
        deleteBillingLibraryModal={deleteBillingLibraryModal}
        billingLibrary={billingLibrary}
        refetch={refetch}
      />
      <CannotDeleteBillingLibraryModal
        key={`CANNOT_DELETE_BILLING_LIBRARY-${cannotDeleteBillingLibraryModal.isOpen}`}
        isOpen={cannotDeleteBillingLibraryModal.isOpen}
        handleClose={cannotDeleteBillingLibraryModal.handleClose}
        billingLibrary={billingLibrary}
      />
      <CannotDeleteDefaultBillingLibraryModal
        key={`CANNOT_DELETE_DEFAULT_BILLING_LIBRARY-${cannotDeleteDefaultBillingLibraryModal.isOpen}`}
        isOpen={cannotDeleteDefaultBillingLibraryModal.isOpen}
        handleClose={cannotDeleteDefaultBillingLibraryModal.handleClose}
        billingLibrary={billingLibrary}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryActionsButton.fragment = gql`
  ${BillingLibrary.getIsAssignedToProjectType.fragment}
  ${BillingLibrary.getIsDefaultBillingLibrary.fragment}
  ${BillingLibraryForm.duplicate.fragment}
  ${CannotDeleteBillingLibraryModal.fragment}
  fragment BillingLibraryActionsButton on BillingLibrary {
    id
    ...BillingLibrary_getIsAssignedToProjectType
    ...BillingLibrary_getIsDefaultBillingLibrary
    ...BillingLibraryForm_duplicate
    ...CannotDeleteBillingLibraryModal
  }
`;

export default BillingLibraryActionsButton;
