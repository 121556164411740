// Libraries
import React from 'react';

// Supermove
import {CurrencyInput, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const CreateCommercialMaterialModal = ({form, isOpen, handleClose, submitting, handleSubmit}) => {
  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.HeaderText>Add a Material</SmallModal.HeaderText>
      <Space height={12} />
      <SmallModal.Text>Enter a material name to add and press submit.</SmallModal.Text>
      <Space height={12} />
      <FieldInput
        {...form}
        name={'commercialMaterialForm.name'}
        label={'Material Name'}
        input={{
          required: !form.values.commercialMaterialForm.name,
          autoFocus: true,
          placeholder: 'Enter an material name',
        }}
        style={{
          flex: 1,
        }}
      />
      <Space height={12} />
      <FieldInput
        {...form}
        name={'commercialMaterialForm.quantityRequested'}
        label={'Quantity Requested'}
        input={{
          placeholder: 'Enter quantity requested',
          keyboardType: 'phone-pad',
        }}
        style={{
          flex: 1,
        }}
      />
      <Space height={12} />
      <FieldInput
        {...form}
        name={'commercialMaterialForm.quantityDelivered'}
        label={'Quantity Delivered'}
        input={{
          placeholder: 'Enter quantity delivered',
          keyboardType: 'phone-pad',
        }}
        style={{
          flex: 1,
        }}
      />
      <Space height={12} />
      <FieldInput
        {...form}
        name={'commercialMaterialForm.price'}
        label={'Price'}
        component={CurrencyInput}
        input={{
          component: TextInput,
          placeholder: 'Enter the price',
          keyboardType: 'phone-pad',
          setFieldTouched: form.setFieldTouched,
          setFieldValue: form.setFieldValue,
        }}
        style={{
          flex: 1,
        }}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          onPress={handleSubmit}
          color={colors.blue.interactive}
          isSubmitting={submitting}
        >
          Submit
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default CreateCommercialMaterialModal;
