// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, pluralize} from '@supermove/utils';

// App
import PhoneNumber from '@shared/design/components/PhoneNumber';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import ClientActions from 'modules/Client/components/ClientActions';

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
`;

const TableCell = Styled.View`
  flex: ${({flex}) => flex};
  flex-direction: row;
  align-items: center;
  padding-right: 8px;
  box-sizing: border-box;
`;

const TableCellText = Styled.Text`
  ${Typography.Body3}
`;

const Container = Styled.View`
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
`;

const LinkText = Styled.Text`
  ${Typography.Link2}
  color: ${colors.blue.interactive};
  text-decoration-line: underline;
`;

const getDisplayDate = (date) => {
  return Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE);
};

const TableHeaderCell = ({flex, children}) => {
  return (
    <TableCell flex={flex}>
      <TableHeaderText>{children}</TableHeaderText>
    </TableCell>
  );
};

const ClientName = ({client}) => {
  const {navigator} = useNavigationDOM();
  const hasSeparateContactName = client.name !== client.primaryContact.fullName;
  return (
    <Container style={{flex: 1}}>
      <Button onPress={() => navigator.push(`/clients/${client.uuid}`)}>
        <LinkText>{client.name}</LinkText>
      </Button>
      {hasSeparateContactName && <TableCellText>{client.primaryContact.fullName}</TableCellText>}
    </Container>
  );
};

const getBranchHoverText = ({slugs}) => {
  if (_.isEmpty(slugs)) {
    return '';
  }

  const hoverText = slugs.map((slug) => `${slug}`).join('\n');

  return hoverText;
};

const getClientsListTableColumnDefinitions = ({refetch, isPrimaryOrganization}) => {
  return [
    {
      flex: 4,
      headerContent: () => {
        return <TableHeaderCell>Customer Name</TableHeaderCell>;
      },
      cellContent: ({item: client}) => {
        return <ClientName client={client} />;
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <TableHeaderCell>Contact</TableHeaderCell>;
      },
      cellContent: ({item: client}) => {
        return (
          <Container style={{flex: 1}}>
            {client.primaryContact.phoneNumber ? (
              <PhoneNumber isClickable phoneNumber={client.primaryContact.phoneNumber} />
            ) : (
              <TableCellText numberOfLines={1}>--</TableCellText>
            )}
            <TableCellText style={{color: colors.gray.tertiary}} numberOfLines={1}>
              {client.primaryContact.email}
            </TableCellText>
          </Container>
        );
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <TableHeaderCell>Customer Since</TableHeaderCell>;
      },
      cellContent: ({item: client}) => {
        return <TableCellText>{Datetime.timeAgo(client.createdAt)}</TableCellText>;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <TableHeaderText>Projects</TableHeaderText>;
      },
      cellContent: ({item: client}) => {
        return (
          <TableCellText>
            {_.isEmpty(client.projects) ? '--' : pluralize('Project', client.projects.length, true)}
          </TableCellText>
        );
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <TableHeaderText>Recent Project</TableHeaderText>;
      },
      cellContent: ({item: client}) => {
        const lastProject = _.last(client.projects);
        if (!lastProject) {
          return <TableCellText>--</TableCellText>;
        }
        const {startDate, endDate} = lastProject;
        const startText = startDate ? getDisplayDate(startDate) : 'TBD';
        const endText = endDate ? getDisplayDate(endDate) : 'TBD';
        const isOneDayProject = startDate === endDate;
        return (
          <Container style={{flex: 1}}>
            <TableCellText>{startText}</TableCellText>
            {startDate && !isOneDayProject && <TableCellText>{endText}</TableCellText>}
          </Container>
        );
      },
    },
    {
      isHidden: !isPrimaryOrganization,
      flex: 2.5,
      headerContent: () => {
        return <TableHeaderText>Branch</TableHeaderText>;
      },
      cellContent: ({item: client}) => {
        // TODO(Kevin): switch this to use project organization when we support project transfer to multibranch
        const uniqueOrganizationName = client.organizationsForClientProjects.map((organization) => {
          return organization.name;
        });

        return (
          <TextTooltip text={getBranchHoverText({slugs: uniqueOrganizationName})}>
            <Container style={{flex: 1}}>
              <TableCellText numberOfLines={2}>{uniqueOrganizationName.join(', ')}</TableCellText>
            </Container>
          </TextTooltip>
        );
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return (
          <Container>
            <TableHeaderText>Salesperson</TableHeaderText>
            <TableHeaderText>Coordinator</TableHeaderText>
          </Container>
        );
      },
      cellContent: ({item: client}) => {
        return (
          <Container>
            <TableCellText>{_.get(client, 'salesperson.fullName', '--')}</TableCellText>
            <TableCellText style={{color: colors.gray.tertiary}}>
              {_.get(client, 'coordinator.fullName', '--')}
            </TableCellText>
          </Container>
        );
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <TableHeaderCell>Actions</TableHeaderCell>;
      },
      cellContent: ({item: client}) => {
        return (
          <Table.PreventPropagationContainer>
            <ClientActions client={client} refetch={refetch} organization={client.organization} />
          </Table.PreventPropagationContainer>
        );
      },
    },
  ];
};

const ClientsList = ({loading, clients, refetch, hasError, isPrimaryOrganization}) => {
  const {navigator} = useNavigationDOM();

  return (
    <Container data-test-id='customer-clients-list'>
      <Table
        columnDefinitions={getClientsListTableColumnDefinitions({
          refetch,
          isPrimaryOrganization,
        })}
        items={clients}
        emptyStateText='There are no customers that match these filters'
        loading={loading}
        isClickable
        onRowPress={(client) => navigator.push(`/clients/${client.uuid}`)}
        hasError={hasError}
        rowStyle={{overflowY: 'visible', overflowX: 'visible'}}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientsList.fragment = gql`
  ${ClientActions.fragment}

  fragment ClientsList on Client {
    id
    uuid
    createdAt
    name
    organizationsForClientProjects {
      id
      name
    }
    primaryContact {
      id
      email
      phoneNumber
      fullName
    }
    projects {
      id
      startDate
      endDate
    }
    salesperson {
      id
      fullName
    }
    coordinator {
      id
      fullName
    }
    ...ClientActions
  }
`;

export default ClientsList;
