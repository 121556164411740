// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Form, Query, Ref, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {User} from '@supermove/models';
import {colors} from '@supermove/styles';
import {focus, Phone} from '@supermove/utils';

const Wrapper = Styled.View`
  width: 100%;
`;

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
`;

const Input = Styled.TextInput.H7`
  flex: 1;
`;

const Footer = Styled.View`
`;

const Results = Styled.View`
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-radius: 3px;
`;

const Touchable = Styled.Touchable`
`;

const Result = Styled.View`
  padding: 12px;
`;

const Name = Styled.H7`
  color: ${colors.gray.primary};
`;

const Contact = Styled.H8`
  margin-top: 3px;
  color: ${colors.gray.secondary};
`;

const SearchResults = ({employees, setFieldValue, setShouldQuery, onSelectEmployee}) => {
  return employees.length > 0 ? (
    <Results>
      {employees.map((employee) => (
        <Touchable
          key={employee.id}
          onPress={() => {
            setShouldQuery(false);
            setFieldValue('searchQuery', '');
            onSelectEmployee(employee);
          }}
        >
          <Result>
            <Name>{`${User.getFullName(employee)} (${employee.organization.name})`}</Name>
            <Contact>{employee.phoneNumber ? Phone.display(employee.phoneNumber) : '--'}</Contact>
          </Result>
        </Touchable>
      ))}
    </Results>
  ) : (
    <Results>
      <Result>
        <Name>No crew members found</Name>
      </Result>
    </Results>
  );
};

const EmployeeSearchField = ({
  shouldAutoFocus,
  companyId,
  organizationId,
  selectedEmployeeIds,
  onSelectEmployee,
}) => {
  // Determines whether to query the server.
  const [shouldQuery, setShouldQuery] = useState(false);

  return (
    <Form
      initialValues={{
        searchQuery: '',
      }}
      onSubmit={() => {}}
    >
      {({values, setFieldValue}) => (
        <Query
          fetchPolicy={'network-only'}
          skip={!shouldQuery}
          variables={{
            companyId,
            organizationId,
            searchQuery: values.searchQuery,
          }}
          query={EmployeeSearchField.query}
        >
          {({loading, data}) => {
            const fetchDebounced = _.debounce(() => setShouldQuery(true), 500);
            const employees = _.get(data, 'employees', []);
            const shouldShowResults =
              !loading && values.searchQuery.length > 0 && employees.length > 0;

            return (
              <Wrapper>
                <Container>
                  <Ref>
                    {(ref) => (
                      <Input
                        autoFocus={shouldAutoFocus}
                        placeholder={'Search for a crew member'}
                        ref={ref}
                        value={values.searchQuery}
                        onChangeText={(text) => {
                          setShouldQuery(false);
                          setFieldValue('searchQuery', text);
                          if (text.length > 0) {
                            fetchDebounced();
                          }
                        }}
                        onSubmitEditing={() => {
                          if (shouldShowResults && employees.length > 0) {
                            setShouldQuery(false);
                            setFieldValue('searchQuery', '');
                            onSelectEmployee(employees[0]);
                            focus(ref);
                          }
                        }}
                      />
                    )}
                  </Ref>
                </Container>
                <Footer>
                  {shouldShowResults && (
                    <SearchResults
                      employees={employees}
                      selectedEmployeeIds={selectedEmployeeIds}
                      setFieldValue={setFieldValue}
                      setShouldQuery={setShouldQuery}
                      onSelectEmployee={onSelectEmployee}
                    />
                  )}
                </Footer>
              </Wrapper>
            );
          }}
        </Query>
      )}
    </Form>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
EmployeeSearchField.propTypes = {
  selectedEmployeeIds: PropTypes.array.isRequired,
  onSelectEmployee: PropTypes.func.isRequired,
  companyId: PropTypes.number,
  organizationId: PropTypes.number,
  shouldAutoFocus: PropTypes.bool,
};

EmployeeSearchField.defaultProps = {
  companyId: undefined,
  organizationId: undefined,
  shouldAutoFocus: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmployeeSearchField.query = gql`
  query EmployeeSearchField(
    $companyId: Int,
    $organizationId: Int,
    $searchQuery: String!,
  ) {
    ${gql.query}
    employees: searchEmployeesByQuery(
      companyId: $companyId,
      organizationId: $organizationId,
      searchQuery: $searchQuery,
    ) {
      id
      firstName
      lastName
      phoneNumber
      email
      organization {
        id
        name
      }
    }
  }
`;

export default EmployeeSearchField;
