// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useState, useToggle} from '@supermove/hooks';

// App
import EditPanel from '@shared/design/components/Panel/EditPanel';
import OrganizationInventorySettingsForm from '@shared/modules/Inventory/forms/OrganizationInventorySettingsForm';
import useUpdateOrganizationInventorySettingsMutation from '@shared/modules/Inventory/hooks/useUpdateOrganizationInventorySettingsMutation';
import InventorySettingsPage from 'modules/Organization/Settings/Inventory/components/InventorySettingsPage';
import MeasurementsPanel from 'modules/Organization/Settings/Inventory/components/MeasurementsPanel';
import SurveyMethodsPanel from 'modules/Organization/Settings/Inventory/components/SurveyMethodsPanel';

const Container = Styled.View`
`;

const Measurements = ({index, organization, refetch}) => {
  const editMeasurementsToggle = useToggle();
  const organizationInventorySettingsForm = OrganizationInventorySettingsForm.edit(organization);
  const {form, submitting, handleSubmit} = useUpdateOrganizationInventorySettingsMutation({
    organizationInventorySettingsForm,
    onSuccess: () => {
      editMeasurementsToggle.handleToggleOff();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      index={index}
      bodyComponentProps={{organization}}
      editBodyComponentProps={{form}}
      BodyComponent={MeasurementsPanel}
      EditBodyComponent={MeasurementsPanel.Edit}
      title={'Measurements'}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editMeasurementsToggle.isOn}
      handleEdit={editMeasurementsToggle.handleToggleOn}
      handleClose={editMeasurementsToggle.handleToggleOff}
      SaveButtonIconComponent={Icon.Check}
    />
  );
};

const GeneralInventorySettings = ({organization, refetch}) => {
  return (
    <ScrollView horizontal>
      <Container>
        <Measurements index={0} organization={organization} refetch={refetch} />
        <Space height={24} />
        <SurveyMethodsPanel index={1} organization={organization} refetch={refetch} />
      </Container>
    </ScrollView>
  );
};

const OrganizationSettingsInventoryGeneralSettings = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const {loading, data, refetch} = useQuery(OrganizationSettingsInventoryGeneralSettings.query, {
    fetchPolicy: 'cache-and-network',
  });
  const refetchWithKeyUpdate = () => {
    refetch();
    setRefreshKey(refreshKey + 1);
  };
  return (
    <InventorySettingsPage
      title={'General Inventory Settings'}
      description={'Configure your default measurements and survey methods.'}
    >
      <Loading loading={loading}>
        {() => (
          <GeneralInventorySettings
            key={refreshKey} // This key is important to make sure list updates when the settings are updated
            organization={data.viewer.viewingOrganization}
            refetch={refetchWithKeyUpdate}
          />
        )}
      </Loading>
    </InventorySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsInventoryGeneralSettings.query = gql`
  ${MeasurementsPanel.fragment}
  ${OrganizationInventorySettingsForm.edit.fragment}
  ${SurveyMethodsPanel.fragment}
  query OrganizationSettingsInventoryGeneralSettings {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...MeasurementsPanel
        ...OrganizationInventorySettingsForm_edit
        ...SurveyMethodsPanel
      }
    }
  }
`;

export default OrganizationSettingsInventoryGeneralSettings;
