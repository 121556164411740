// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import InvoiceForm from '@shared/modules/Billing/forms/InvoiceForm';

const useUnfinalizeInvoiceMutation = ({invoiceForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      invoiceForm: InvoiceForm.toForm(invoiceForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUnfinalizeInvoiceMutation.mutation,
    variables: {
      invoiceForm: InvoiceForm.toMutation(form.values.invoiceForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUnfinalizeInvoiceMutation.mutation = gql`
  mutation useUnfinalizeInvoiceMutation($invoiceForm: InvoiceForm!) {
    response: unfinalizeInvoice(invoiceForm: $invoiceForm) {
      ${gql.errors}
      invoice {
        id
      }
    }
  }
`;

export default useUnfinalizeInvoiceMutation;
