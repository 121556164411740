// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {useNavigationDOM} from '@supermove/navigation';
import {Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Panel from '@shared/design/components/Panel';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import PayengineMerchantStatus from '@shared/modules/Payment/enums/PayengineMerchantStatus';
import useCreatePayEngineMerchantMutation from '@shared/modules/Payment/hooks/useCreatePayEngineMerchantMutation';
import UserRole from '@shared/modules/User/enums/UserRole';
import PayEngineLogo from 'modules/Organization/Settings/Company/assets/PayEngineLogo';

const Image = Styled.Image`
  width: 128px;
  height: 38px;
`;

const PanelRow = Styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center
`;

const PanelRowElement = Styled.View`
`;

const Pill = Styled.View`
  padding-horizontal: 8px;
  padding-vertical: 4px;
  background-color: ${({color}) => color}
  border-radius: 20px;
`;

const PillText = Styled.Text`
  ${Typography.Micro}
  color: ${({color}) => color};
`;

const StatusPill = ({merchantDetails}) => {
  const badgeColors = PayengineMerchantStatus.getBadgeColors(merchantDetails.status);

  return (
    <Pill color={badgeColors.background}>
      <PillText color={badgeColors.text}>{merchantDetails.displayStatus}</PillText>
    </Pill>
  );
};

const MerchantApplication = ({organizationId, merchantId}) => {
  const {navigator} = useNavigationDOM();
  const createPayEngineMerchantMutation = useCreatePayEngineMerchantMutation({organizationId});
  const errorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Error starting onboarding process.',
  });

  const isSubmitting = createPayEngineMerchantMutation.mutationResult.loading || false;
  const onPress = async () => {
    if (!merchantId) {
      const result = await createPayEngineMerchantMutation.handleSubmit();
      const errors = result.errors || result?.data?.response?.errors;
      if (errors) {
        console.error(`Error executing CreatePayEngineMerchant mutation: `, errors);
        errorToast.handleToast();
        return;
      }
      console.log(`Created PayEngine merchant:`, result.data.response.payengineMerchant);
    }
    navigator.push('/settings/company/payments/onboarding');
  };

  return (
    <PanelRow>
      <PanelRowElement>
        <Panel.LabelText>Supermove Payments</Panel.LabelText>
        <Space height={8} />
        <Panel.Text>All payments in one place.</Panel.Text>
      </PanelRowElement>
      <PanelRowElement>
        <Button
          onPress={onPress}
          isSubmitting={isSubmitting}
          text={merchantId ? 'Continue Onboarding' : 'Start Onboarding'}
        />
      </PanelRowElement>
    </PanelRow>
  );
};

const MerchantStatus = ({merchantDetails, role}) => {
  const {navigator} = useNavigationDOM();

  let button = <React.Fragment />;
  switch (merchantDetails.status) {
    case 'ACTIVE':
      if (UserRole.ADMIN_ROLES_PLUS_SUPER.includes(role)) {
        button = (
          <PanelRowElement>
            <Button
              onPress={() => navigator.push('/payments/transactions')}
              text={'View Transactions'}
            />
          </PanelRowElement>
        );
      }
      break;
    case 'DECLINED':
      button = (
        <PanelRowElement>
          <Button
            onPress={() => {
              if (!window.zE) {
                console.error('Zendesk widget is not initialized');
                return;
              }
              // Opens the Zendesk chat widget:
              // https://developer.zendesk.com/api-reference/widget-messaging/web/core/#open
              window.zE('messenger', 'open');
            }}
            text={'Contact Supermove'}
          />
        </PanelRowElement>
      );
      break;
    default:
  }

  return (
    <PanelRow>
      <PanelRowElement>
        <Panel.LabelText>Account ID</Panel.LabelText>
        <Space height={8} />
        <Panel.Text>{merchantDetails.id}</Panel.Text>
      </PanelRowElement>
      {button}
    </PanelRow>
  );
};

const PayEnginePanel = ({organization, role}) => {
  const merchantDetails = organization.payengineMerchant?.merchantDetails;
  const isApplicationSubmitted = !!merchantDetails && merchantDetails.status !== 'EDITING';

  return (
    <Panel width={Panel.WIDTH.DEFAULT}>
      <Panel.Header>
        <Panel.HeaderText>Supermove Payments</Panel.HeaderText>
      </Panel.Header>
      <Panel.Body>
        <PanelRow>
          <PanelRowElement>
            <Image resizeMode={'contain'} source={{uri: PayEngineLogo}} />
          </PanelRowElement>
          {merchantDetails && (
            <PanelRowElement>
              <StatusPill merchantDetails={merchantDetails} />
            </PanelRowElement>
          )}
        </PanelRow>
        <Space height={16} />
        {isApplicationSubmitted ? (
          <MerchantStatus merchantDetails={merchantDetails} role={role} />
        ) : (
          <MerchantApplication
            organizationId={organization.id}
            merchantId={organization.payengineMerchant?.merchantId}
          />
        )}
      </Panel.Body>
    </Panel>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PayEnginePanel.fragment = gql`
  fragment PayEnginePanel on Organization {
    id
    slug
    payengineMerchant {
      id
      merchantId
      hmac
      merchantDetails {
        id
        status
        displayStatus
      }
    }
  }
`;

export default PayEnginePanel;
