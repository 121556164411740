// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {ClaimStatusModel} from '@supermove/models';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import ClaimStatusForm from '@shared/modules/Claim/forms/ClaimStatusForm';
import useDeleteClaimStatusMutation from '@shared/modules/Claim/hooks/useDeleteClaimStatusMutation';

const RemoveClaimStatusModal = ({
  claimStatus,
  isOpen,
  handleClose,
  refetch,
}: {
  claimStatus: ClaimStatusModel;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const claimStatusForm = ClaimStatusForm.edit(claimStatus);
  const {handleSubmit} = useDeleteClaimStatusMutation({
    claimStatusForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: unknown) => console.log({errors}),
  });
  return (
    <DeleteModal
      handleDelete={handleSubmit}
      handleClose={handleClose}
      isOpen={isOpen}
      title={'Remove claim status?'}
      subtitle={
        "This claim status will no longer exist in settings but can still be viewed in the claim. You can't undo this action."
      }
      deleteButtonText={'Remove'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RemoveClaimStatusModal.fragment = gql`
  ${ClaimStatusForm.edit.fragment}

  fragment RemoveClaimStatusModal on ClaimStatus {
    id
    ...ClaimStatusForm_edit
  }
`;
export default RemoveClaimStatusModal;
