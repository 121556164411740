// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {BulkUpdateIntPropertyForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// Components
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import useBulkUpdateClaimStatusMutation from '@shared/modules/Claim/hooks/useBulkUpdateClaimStatusMutation';
import ClaimStatusDropdown from 'modules/Project/Claim/components/ClaimStatusDropdown';

const BulkEditClaimStatusHeader = ({selectedClaims}) => {
  return (
    <React.Fragment>
      <SmallModal.HeaderText>Edit Claim Status</SmallModal.HeaderText>
      <Space height={16} />
      <SmallModal.Text>{`You selected ${pluralize(
        'claims',
        _.size(selectedClaims),
        true,
      )}. Edit the claim status using the dropdown below.`}</SmallModal.Text>
    </React.Fragment>
  );
};

const BulkEditClaimStatusFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <SmallModal.Footer>
      <SmallModal.Button
        textColor={colors.gray.secondary}
        onPress={handleClose}
        isDisabled={submitting}
      >
        Cancel
      </SmallModal.Button>
      <SmallModal.Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        isSubmitting={submitting}
        onPress={handleSubmit}
        isDisabled={submitting}
      >
        <Icon source={Icon.Check} color={colors.white} size={12} />
        <Space width={4} />
        Save
      </SmallModal.Button>
    </SmallModal.Footer>
  );
};

const BulkEditClaimStatusModal = ({
  organization,
  selectedClaims,
  handleResetSelections,
  refetch,
  isOpen,
  handleClose,
}) => {
  const bulkUpdateIntPropertyForm = BulkUpdateIntPropertyForm.new({
    ids: selectedClaims,
    intValue: null,
  });
  const bulkEditClaimStatusToast = useToast({
    ToastComponent: SuccessToast,
    message: `Claim status updated`,
    isClosable: true,
  });

  const {form, submitting, handleSubmit} = useBulkUpdateClaimStatusMutation({
    bulkUpdateIntPropertyForm,
    onSuccess: () => {
      handleResetSelections();
      bulkEditClaimStatusToast.handleToast();
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose} style={{overflow: 'visible'}}>
      <BulkEditClaimStatusHeader selectedClaims={selectedClaims} />
      <Space height={16} />
      <ClaimStatusDropdown
        form={form}
        organization={organization}
        customField={'bulkUpdateIntPropertyForm.intValue'}
        index={0}
      />
      <Space height={16} />
      <BulkEditClaimStatusFooter
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BulkEditClaimStatusModal.fragment = gql`
  ${ClaimStatusDropdown.fragment}
  fragment BulkEditClaimStatusModal on Organization {
    id
    ...ClaimStatusDropdown
  }
`;

export default BulkEditClaimStatusModal;
