// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, IconSource} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';

interface WarningModalProps {
  isOpen: boolean;
  icon?: IconSource;
  title: string;
  message?: string;
  handlePressOutside?: () => void;
  handlePrimaryAction: () => void;
  handleSecondaryAction?: () => void;
  primaryActionText: string;
  secondaryActionText?: string;
  isDisabled?: boolean;
  isSecondaryDisabled?: boolean;
  isSubmitting?: boolean;
}

const WarningModal = ({
  icon,
  title,
  message,
  isOpen,
  handlePressOutside,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionText,
  secondaryActionText,
  isDisabled,
  isSecondaryDisabled,
  isSubmitting,
}: WarningModalProps) => {
  return (
    <ActionModal
      color={colors.red.warning}
      icon={icon}
      title={title}
      message={message}
      isOpen={isOpen}
      handlePressOutside={handlePressOutside}
      handlePrimaryAction={handlePrimaryAction}
      handleSecondaryAction={handleSecondaryAction}
      primaryActionText={primaryActionText}
      secondaryActionText={secondaryActionText}
      isDisabled={isDisabled}
      isSecondaryDisabled={isSecondaryDisabled}
      isSubmitting={isSubmitting}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
WarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  icon: Icon.SourcePropType,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  handlePressOutside: PropTypes.func,
  handlePrimaryAction: PropTypes.func.isRequired,
  handleSecondaryAction: PropTypes.func,
  primaryActionText: PropTypes.string.isRequired,
  secondaryActionText: PropTypes.string,
};

WarningModal.defaultProps = {
  message: '',
  icon: Icon.ExclamationTriangle,
  handlePressOutside: () => {},
  handleSecondaryAction: null,
  secondaryActionText: '',
};

export default WarningModal;
