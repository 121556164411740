/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';
import {colors} from '@supermove/styles';

const Touchable = Styled.Touchable`
`;

const CloseButton = () => {
  const {navigator} = useNavigationDOM();

  return (
    <Touchable onPress={() => navigator.goBack()}>
      <Icon color={colors.gray.primary} size={24} source={Icon.Times} />
    </Touchable>
  );
};

export default CloseButton;
