// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Form, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const CreateSignatureForDocumentStepForm = ({
  documentId,
  customerId,
  step,
  date,
  kind,
  name,
  onSuccess,
  onError,
  children,
}) => (
  <Form
    initialValues={{
      documentId,
      customerId,
      step,
      date,
      kind,
      image: '',
      name,
    }}
    onSubmit={(values) => {}}
  >
    {({values, submitForm, setMutationErrors, ...form}) => (
      <Mutation
        variables={{
          documentId: values.documentId,
          customerId: values.customerId,
          step: values.step,
          date: values.date,
          kind: values.kind,
          image: values.image,
          name: values.name,
        }}
        mutation={CreateSignatureForDocumentStepForm.mutation}
        onCompleted={({createSignatureForDocumentStep: {document, errors}}) => {
          submitForm();
          setMutationErrors(errors);

          if (document) {
            onSuccess(document);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit, {loading, error}) =>
          children({
            ...form,
            loading,
            values,
            handleSubmit,
          })
        }
      </Mutation>
    )}
  </Form>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
CreateSignatureForDocumentStepForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

CreateSignatureForDocumentStepForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateSignatureForDocumentStepForm.mutation = gql`
  mutation CreateSignatureForDocumentStepForm(
    $documentId: Int!,
    $customerId: Int!,
    $step: String!,
    $date: String,
    $kind: String,
    $image: String,
    $name: String,
  ) {
    createSignatureForDocumentStep(
      documentId: $documentId,
      customerId: $customerId,
      step: $step,
      date: $date,
      kind: $kind,
      image: $image,
      name: $name,
    ) {
      ${gql.errors}
      document {
        id
        isStepCompleted(step: $step)
      }
    }
  }
`;

export default CreateSignatureForDocumentStepForm;
