// Libraries
import React from 'react';

// Supermove
import {Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, usePopover, useState} from '@supermove/hooks';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import SendEmailForProjectModal from 'modules/Email/Project/components/SendEmailForProjectModal';
import EditProjectClientsDrawer from 'modules/Project/V2/Show/Blocks/components/EditProjectClientsDrawer';
import ProjectClientDataActions from 'modules/Project/V2/Show/components/ProjectClientDataActions';

const ClientEmailActionsPopover = ({
  popover,
  project,
  sendEmailForProjectModal,
  setSelectedEmailTemplateUuid,
}) => {
  return (
    <Popover
      key={popover.key}
      placement={Popover.Positions.BottomEnd}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ActionMenu
        handleClose={popover.handleClose}
        width={400}
        maxHeight={244}
        isTruncated
        actions={[
          {
            text: 'Compose new email',
            onPress: sendEmailForProjectModal.handleOpen,
          },
          ...project.organization.sortedEmailTemplates.map((emailTemplate) => ({
            text: `Compose '${emailTemplate.name}'`,
            onPress: () => {
              setSelectedEmailTemplateUuid(emailTemplate.uuid);
              sendEmailForProjectModal.handleOpen();
            },
          })),
        ]}
      />
    </Popover>
  );
};

const ProjectClientEmail = ({project, isMicro, isBilling}) => {
  const [selectedEmailTemplateUuid, setSelectedEmailTemplateUuid] = useState('');
  const client = isBilling ? project.billingClient : project.client;
  const clientEmailActionsPopover = usePopover({name: 'Client Phone Number Actions Popover'});
  const editProjectClientsDrawer = useDrawer({name: 'Edit Project Clients Drawer'});
  const sendEmailForProjectModal = useModal({name: 'Send Email For Project Modal'});

  return (
    <React.Fragment>
      <ProjectClientDataActions
        data={client.primaryContact.email}
        addDataText={'Add Email'}
        onAddDataPress={editProjectClientsDrawer.handleOpen}
        icon={Icon.Envelope}
        iconTooltip={'Contact Email'}
        isBilling={isBilling}
        isMicro={isMicro}
        popover={clientEmailActionsPopover}
      />
      <EditProjectClientsDrawer
        key={editProjectClientsDrawer.key}
        isOpen={editProjectClientsDrawer.isOpen}
        handleClose={editProjectClientsDrawer.handleClose}
        project={project}
      />
      <SendEmailForProjectModal
        key={sendEmailForProjectModal.key}
        project={project}
        isOpen={sendEmailForProjectModal.isOpen}
        handleClose={() => {
          sendEmailForProjectModal.handleClose();
          setSelectedEmailTemplateUuid('');
        }}
        emailTemplateUuid={selectedEmailTemplateUuid}
      />
      <ClientEmailActionsPopover
        popover={clientEmailActionsPopover}
        project={project}
        sendEmailForProjectModal={sendEmailForProjectModal}
        setSelectedEmailTemplateUuid={setSelectedEmailTemplateUuid}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectClientEmail.fragment = gql`
  ${SendEmailForProjectModal.fragment}
  fragment ProjectClientEmail on Project {
    id
    client {
      id
      primaryContact {
        id
        email
      }
    }
    billingClient {
      id
      primaryContact {
        id
        email
      }
    }
    organization {
      id
      sortedEmailTemplates {
        id
        name
        uuid
      }
    }
    ...SendEmailForProjectModal
  }
`;

export default ProjectClientEmail;
