// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Datetime} from '@supermove/utils';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import ConfirmationQuoteDocumentV2 from 'modules/Customer/Project/Confirmation/ConfirmationQuoteDocumentV2';
import ProjectPage from 'modules/Customer/Project/components/ProjectPage';

const CustomerProjectQuoteDocumentV2 = ({
  match: {
    params: {slug, uuid, documentUuid},
  },
}) => {
  return (
    <ProjectPage variables={{uuid, documentUuid}} query={CustomerProjectQuoteDocumentV2.query}>
      {({responsive, navigator, data}) => {
        const {project, document} = data;
        const {quote} = project;
        const actionText = _.get(quote, 'nextStep.actionText', '');
        const redirectRoute = `/0/${project.organization.slug}/projects/${project.uuid}/quote`;
        return (
          <ConfirmationQuoteDocumentV2
            project={project}
            document={document}
            isComplete={quote.isComplete}
            nextIndex={quote.nextIndex}
            stepCount={quote.stepCount}
            isCorrectPage={({project}) => {
              const kind = _.get(project, 'quote.nextStep.kind');
              return (
                kind === ConfirmationStepKind.QUOTE_STEP_DOCUMENT &&
                !project.isCancelled &&
                !quote.isExpired
              );
            }}
            redirectRoute={redirectRoute}
            navigator={navigator}
            responsive={responsive}
            submitButtonText={actionText || 'Continue'}
            beforeSubmit={(handleSubmit) => {
              if (
                quote.expirationDatetime &&
                Datetime.fromDatetime(quote.expirationDatetime) <= Datetime.now
              ) {
                navigator.push(redirectRoute);
              } else {
                handleSubmit();
              }
            }}
          />
        );
      }}
    </ProjectPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerProjectQuoteDocumentV2.query = gql`
  ${ConfirmationQuoteDocumentV2.fragment}
  ${ProjectPage.fragment}

  query CustomerProjectQuoteDocumentV2($uuid: String!, $documentUuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      isCancelled
      quote {
        id
        isExpired
        expirationDatetime
        isComplete
        nextIndex
        stepCount
        nextStep {
          kind
          actionText
        }
      }
      organization {
        id
        slug
      }
      ...ProjectPage
    }
    document(uuid: $documentUuid) {
      id
      ...ConfirmationQuoteDocumentV2
    }
  }
`;

export default CustomerProjectQuoteDocumentV2;
