// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive, ResponsiveType} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import {JobTipsFormTypeForm} from '@shared/modules/Billing/forms/JobTipsForm';
import Line from 'modules/App/components/Line';

const Row = Styled.View`
  flex-direction: row;
`;

const DateText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const JobNameText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const JobTipAmountText = Styled.Text`
  ${Typography.Responsive.Label}
  align-self: flex-end;
`;

interface ColumnComponentProps {
  children: React.ReactNode;
  responsive: ResponsiveType;
  style: any;
}

const JobColumnHeader = ({
  jobTipsForm,
  responsive,
}: {
  jobTipsForm: JobTipsFormTypeForm;
  responsive: ResponsiveType;
}) => {
  return (
    <React.Fragment>
      <DateText responsive={responsive}>{jobTipsForm.displayDate}</DateText>
      <JobNameText numberOfLines={1} responsive={responsive}>
        {jobTipsForm.displayName}
      </JobNameText>
      {responsive.desktop && (
        <React.Fragment>
          <Space height={8} />
          <Line />
          <Space height={4} />
          <JobTipAmountText responsive={responsive}>
            {jobTipsForm.billTipForm.amount}
          </JobTipAmountText>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const EditTipPayoutsJobsHeader = ({
  jobTipsForms,
  ColumnComponent,
}: {
  jobTipsForms: JobTipsFormTypeForm[];
  ColumnComponent: React.FC<ColumnComponentProps>;
}) => {
  const responsive = useResponsive();

  return (
    <Row>
      {jobTipsForms.map((jobTipsForm: JobTipsFormTypeForm, index: number) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && <Space width={16} />}
            <ColumnComponent
              responsive={responsive}
              style={responsive.desktop ? null : {alignItems: 'center'}}
            >
              <JobColumnHeader jobTipsForm={jobTipsForm} responsive={responsive} />
            </ColumnComponent>
          </React.Fragment>
        );
      })}
    </Row>
  );
};

export default EditTipPayoutsJobsHeader;
