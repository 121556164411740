// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useQuery} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const Container = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const LabelText = Styled.Text`
  ${Typography.Label}
`;

const EmptyStateText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const getSectionsWithValueForms = ({valueForms, projectTypeVariableSections}) => {
  const valueFormsBySectionId = _.groupBy(valueForms, (valueForm) => valueForm.variableSectionId);
  let sectionIndex = 0;
  return _.map(projectTypeVariableSections, (projectTypeVariableSection) => {
    const sectionValueForms = valueFormsBySectionId[projectTypeVariableSection.id] || [];
    const startingValueFormIndex = sectionIndex;
    sectionIndex += sectionValueForms.length;
    return {
      id: projectTypeVariableSection.id,
      name: projectTypeVariableSection.name,
      valueForms: sectionValueForms,
      // all of the ValueField logic relies on utilizing valueForms and fetching by index
      // we need to store the section index so that the ValueFields nested will have the correct index
      startingValueFormIndex,
      hasVisibleValueForms: !_.isEmpty(
        _.filter(
          sectionValueForms,
          (sectionValueForm) => !!sectionValueForm.isVisibleForCreateProject,
        ),
      ),
    };
    // we filter out sections without any value forms
    // this is different than a section with value forms but none visible on create project
  }).filter((sectionWithValueForms) => sectionWithValueForms.valueForms.length > 0);
};

const ProjectValueFields = ({index, projectTypeField, valueFormsField, form, children, isEdit}) => {
  const projectTypeId = _.get(form.values, projectTypeField, null);
  const existingValueForms = _.get(form.values, valueFormsField);
  const {loading, data} = useQuery(ProjectValueFields.query, {
    variables: {
      projectTypeId,
    },
    skip: !projectTypeId,
  });

  useEffect(() => {
    if (data && data.projectType) {
      form.setFieldValue(
        valueFormsField,
        Organization.makeProjectValueFormsFromProjectTypeVariableSections({
          projectType: data.projectType,
          existingValueForms,
          isEdit,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) {
    return (
      <Container index={index}>
        <Space height={24} />
        <PageLoadingIndicator />
        <Space height={24} />
      </Container>
    );
  }

  const valueForms = _.get(form.values, valueFormsField);
  const visibleValueForms = valueForms.filter((valueForm) => !!valueForm.isVisibleForCreateProject);

  if (visibleValueForms.length === 0 || !data?.projectType) {
    return null;
  }

  const sectionsWithValueForms = getSectionsWithValueForms({
    valueForms,
    projectTypeVariableSections: data.projectType.projectTypeVariableSections,
  });
  return (
    <Container index={index}>
      {_.map(sectionsWithValueForms, (sectionWithValueForms, sectionIndex) => {
        return (
          <React.Fragment key={sectionWithValueForms.id}>
            <LabelText>{sectionWithValueForms.name}</LabelText>
            <Space height={4} />
            {sectionWithValueForms.hasVisibleValueForms ? (
              children({
                index: sectionIndex,
                form,
                valueFormsField,
                valueForms: sectionWithValueForms.valueForms,
                startingValueFormIndex: sectionWithValueForms.startingValueFormIndex,
              })
            ) : (
              <React.Fragment>
                <EmptyStateText>No variables to display</EmptyStateText>
                <Space height={16} />
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectValueFields.query = gql`
  ${Organization.makeProjectValueFormsFromProjectTypeVariableSections.fragment}
  query ProjectValueFields(
    $projectTypeId: Int!,
  ) {
    ${gql.query}
    projectType(projectTypeId: $projectTypeId) {
      id
      projectTypeVariableSections {
        id
        name
      }
      ...Organization_makeProjectValueFormsFromProjectTypeVariableSections
    }
  }
`;

export default ProjectValueFields;
