// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Section = Styled.View`
`;

const Row = Styled.View`
flex-direction: row;
align-items: center;
`;

const Title = Styled.H7`
${fontWeight(700)}
color: ${colors.gray.primary};
`;

const InventorySection = ({icon, title, children}: any) => {
  return (
    <Section>
      <Row>
        <Icon color={colors.blue.interactive} size={12} source={icon} style={{marginBottom: 1}} />
        <Space width={8} />
        <Title>{title}</Title>
        <Space width={5} />
      </Row>
      <Space height={10} />
      {children}
    </Section>
  );
};

export default InventorySection;
