// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useDebouncedCallback} from '@supermove/hooks';

// App
import SearchBar from '@shared/design/components/SearchBar';
import Tabs from '@shared/design/components/Tabs';
import ShipmentKind from '@shared/modules/Storage/enums/ShipmentKind';

const HeaderContainer = Styled.View`
`;

const LeftContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const FiltersContainers = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TabsContainer = Styled.View`
`;

const TabHeader = ({activeTabKey, shipmentCountsByKind, dispatchURLVariables}) => {
  const tabDefinitions = [
    {
      key: ShipmentKind.getKindOption(ShipmentKind.INCOMING).key,
      label: ShipmentKind.getKindOption(ShipmentKind.INCOMING).label,
      count: shipmentCountsByKind.incomingCount,
    },
    {
      key: ShipmentKind.getKindOption(ShipmentKind.OUTGOING).key,
      label: ShipmentKind.getKindOption(ShipmentKind.OUTGOING).label,
      count: shipmentCountsByKind.outgoingCount,
    },
    {
      key: 'all',
      label: 'All',
      count: shipmentCountsByKind.allCount,
    },
  ];

  const activeTabIndex = _.findIndex(
    tabDefinitions,
    (definition) => definition.key === activeTabKey,
  );

  return (
    <TabsContainer>
      <Tabs
        tabs={tabDefinitions}
        handlePressTab={({key}) => {
          dispatchURLVariables({type: 'updateKind', payload: key});
        }}
        activeTabIndex={activeTabIndex}
        TabComponent={Tabs.SecondaryTab}
      />
    </TabsContainer>
  );
};

const StorageShipmentFilters = ({shipmentCountsByKind, dispatchURLVariables}) => {
  const {params} = useNavigationDOM();

  // Reset state of searchQuery based on searchbar input
  const handleChangeSearch = useDebouncedCallback((text) => {
    dispatchURLVariables({type: 'updateSearchQuery', payload: text});
  }, 500);

  return (
    <HeaderContainer>
      <FiltersContainers>
        <LeftContainer>
          <SearchBar
            onChangeText={handleChangeSearch}
            placeholder='Search by shipment details'
            style={{width: '348px'}}
            defaultValue={params.searchQuery}
          />
        </LeftContainer>
      </FiltersContainers>
      <Space height={16} />
      <TabHeader
        activeTabKey={params.kind}
        shipmentCountsByKind={shipmentCountsByKind}
        params={params}
        dispatchURLVariables={dispatchURLVariables}
      />
    </HeaderContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageShipmentFilters.fragment = gql`
  fragment StorageShipmentFilters on ShipmentCountsByKind {
    allCount
    incomingCount
    outgoingCount
  }
`;

export default StorageShipmentFilters;
