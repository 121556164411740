// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  width: 100%;
`;

const Banner = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background-color: ${(props) => props.color};
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

/**
 * The job status banner for crew members only shows whether
 * the job is a training move or whether it is cancelled.
 */
const CrewJobStatusBanner = ({job}) => (
  <Container>
    {job.isTest && (
      <Banner color={colors.purple.hover}>
        <Text>{`${job.fullName} is a training move`}</Text>
      </Banner>
    )}
    {job.calendarPrimaryStatus === 'CANCELLED' && (
      <Banner color={Job.getCalendarPrimaryStatusColor(job)}>
        <Text>{Job.getCalendarPrimaryStatusTextWithName(job)}</Text>
      </Banner>
    )}
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
CrewJobStatusBanner.propTypes = {
  job: PropTypes.object.isRequired,
};

CrewJobStatusBanner.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewJobStatusBanner.fragment = gql`
  ${Job.getCalendarPrimaryStatusColor.fragment}
  ${Job.getCalendarPrimaryStatusTextWithName.fragment}

  fragment CrewJobStatusBanner on Job {
    id
    isTest
    calendarPrimaryStatus
    fullName
    ...Job_getCalendarPrimaryStatusColor
    ...Job_getCalendarPrimaryStatusTextWithName
  }
`;

export default CrewJobStatusBanner;
