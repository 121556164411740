// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useResponsive, useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import EmptyState from '@shared/design/components/EmptyState';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import ProjectBlockKind, {
  ProjectBlockKindType,
} from '@shared/modules/Project/enums/ProjectBlockKind';
import UserRole from '@shared/modules/User/enums/UserRole';
import EditTipPayoutsModal from 'modules/Project/Billing/components/EditTipPayoutsModal';
import ProjectTipsJobBreakdownModal from 'modules/Project/Billing/components/ProjectTipsJobBreakdownModal';
import JobActionDisabledTooltip from 'modules/Project/V2/Show/Blocks/Job/components/JobActionDisabledTooltip';
import ProjectTipPayouts from 'modules/Project/V2/Show/Blocks/ProjectTipPayouts';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const HeaderLink = Styled.Text`
  ${Typography.Responsive.Link}
`;

const TipPayoutsContainer = Styled.View`
  padding-horizontal: 24px;
`;

const getHasMovers = ({job}: {job: any}) => {
  const hasMoverOnAnyScheduledMoveJob = _.some(job.project.scheduledMoveJobs, (job: any) => {
    return _.some(job.assignedJobUsersForTips);
  });
  return hasMoverOnAnyScheduledMoveJob;
};

const DesktopHeaderButtons = ({
  job,
  projectTipsModal,
  editTipPayoutsModal,
}: {
  job: any;
  projectTipsModal: any;
  editTipPayoutsModal: any;
}) => {
  return (
    <Row>
      <SecondaryButton isSmall text={'View Job Breakdown'} onPress={projectTipsModal.handleOpen} />
      <Space width={12} />
      <JobActionDisabledTooltip
        job={job}
        customMessage={getHasMovers({job}) ? null : 'Must add movers before editing tip payouts.'}
      >
        <Button
          isSmall
          iconLeft={Icon.Pen}
          text={'Edit Tip Payouts'}
          onPress={editTipPayoutsModal.handleOpen}
          isDisabled={!getHasMovers({job}) || job.isFinal}
        />
      </JobActionDisabledTooltip>
    </Row>
  );
};

const MobileActionsDropdown = ({
  viewer,
  job,
  projectTipsModal,
  editTipPayoutsModal,
}: {
  viewer: any;
  job: any;
  projectTipsModal: any;
  editTipPayoutsModal: any;
}) => {
  return (
    <DropdownButton
      text={'Actions'}
      isSmall
      menuWidth={180}
      menuPosition={DropdownButton.MENU_POSITION.RIGHT}
      actions={[
        {
          text: 'View Job Breakdown',
          onPress: projectTipsModal.handleOpen,
        },
        {
          text: 'Edit Tip Payouts',
          onPress: editTipPayoutsModal.handleOpen,
          tooltip: !getHasMovers({job})
            ? 'Must add movers before editing tip payouts.'
            : job.isFinal
              ? UserRole.getJobActionDisabledTooltip(viewer.role)
              : '',
          isDisabled: !getHasMovers({job}) || job.isFinal,
        },
      ]}
      ButtonComponent={MobileProjectBlockHeader.EllipsisButton}
    />
  );
};

const TipPayoutsContent = ({job}: {job: any}) => {
  if (!getHasMovers({job})) {
    return <EmptyState title={'No movers'} message={'Add movers to view and edit tip payouts.'} />;
  }

  return (
    <TipPayoutsContainer>
      <ProjectTipPayouts project={job.project} />
    </TipPayoutsContainer>
  );
};

const JobTipPayoutsBlockContent = ({
  viewer,
  job,
  urlFilters,
  project,
  refetch,
  responsive,
}: {
  viewer: any;
  job: any;
  urlFilters: any;
  project: any;
  refetch: () => void;
  responsive: ResponsiveType;
}) => {
  const projectTipsModal = useModal({name: 'View Project Tips Modal'});
  const editTipPayoutsModal = useModal({name: 'Edit Tip Payouts Modal'});
  const headerText = 'Tip Payouts';
  const bodyProps = {job};
  const actionComponentProps = {
    viewer,
    job,
    projectTipsModal,
    editTipPayoutsModal,
  };

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <ActionPanel
          BodyComponent={TipPayoutsContent}
          bodyComponentProps={{job}}
          ActionButtonComponent={DesktopHeaderButtons}
          actionButtonComponentProps={actionComponentProps}
          title={headerText}
          headerSubtitle={
            <HeaderText responsive={responsive}>
              {'Go to '}
              <HeaderLink
                onPress={() =>
                  urlFilters.handleReset({
                    block: ProjectBlockKind.BILLING,
                    widget: urlFilters.get('widget'),
                  })
                }
              >
                {'billing'}
              </HeaderLink>
              {' to edit the tip for a job.'}
            </HeaderText>
          }
          style={{flex: 1, width: '100%'}}
          bodyStyle={{paddingHorizontal: 0}}
        />
      ) : (
        <React.Fragment>
          <MobileProjectBlockHeader
            title={headerText}
            ActionsComponent={MobileActionsDropdown}
            actionsComponentProps={actionComponentProps}
            style={{paddingHorizontal: 16}}
          />
          <TipPayoutsContent {...bodyProps} />
        </React.Fragment>
      )}
      <ProjectTipsJobBreakdownModal
        projectUuid={project.uuid}
        isOpen={projectTipsModal.isOpen}
        handleClose={projectTipsModal.handleClose}
        urlFilters={urlFilters}
        refetch={refetch}
      />
      <EditTipPayoutsModal
        projectUuid={project.uuid}
        isOpen={editTipPayoutsModal.isOpen}
        handleClose={editTipPayoutsModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

interface JobTipPayoutsBlockProps {
  jobUuid: string;
  project: any;
  blockToPositionY: any;
  handleSetPositionY: () => void;
  layoutKey: string;
  index: number;
  urlFilters: any;
  projectBlockKind: ProjectBlockKindType;
}

const JobTipPayoutsBlockQuery = ({
  jobUuid,
  project,
  blockToPositionY,
  handleSetPositionY,
  layoutKey,
  index,
  urlFilters,
  projectBlockKind,
}: JobTipPayoutsBlockProps) => {
  const responsive = useResponsive();

  return (
    <ProjectBlockWrapper
      index={index}
      query={JobTipPayoutsBlock.query}
      layoutKey={blockToPositionY[ProjectBlockKind.Job.CREW_HOURS] || layoutKey}
      queryVariables={{jobUuid}}
      handleSetPositionY={handleSetPositionY}
      style={responsive.desktop ? null : {paddingVertical: 24}}
      projectBlockKind={projectBlockKind}
    >
      {({data, refetch}: {data: any; refetch: () => void}) => {
        return (
          <JobTipPayoutsBlockContent
            viewer={data.viewer}
            job={data.job}
            project={project}
            refetch={refetch}
            responsive={responsive}
            urlFilters={urlFilters}
          />
        );
      }}
    </ProjectBlockWrapper>
  );
};

const JobTipPayoutsBlock = ({
  jobUuid,
  project,
  blockToPositionY,
  handleSetPositionY,
  layoutKey,
  index,
  urlFilters,
  projectBlockKind,
}: JobTipPayoutsBlockProps) => {
  return (
    <JobTipPayoutsBlockQuery
      key={project.jobUsersHash}
      jobUuid={jobUuid}
      project={project}
      urlFilters={urlFilters}
      blockToPositionY={blockToPositionY}
      handleSetPositionY={handleSetPositionY}
      layoutKey={layoutKey}
      index={index}
      projectBlockKind={projectBlockKind}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTipPayoutsBlock.listener = gql`
  fragment JobTipPayoutsBlock_listener on Project {
    id
    uuid
    jobUsersHash
  }
`;

JobTipPayoutsBlock.query = gql`
  ${JobActionDisabledTooltip.fragment}
  ${ProjectTipPayouts.fragment}

  query JobTipPayoutsBlock($jobUuid: String!) {
    ${gql.query}
    viewer {
      id
      role
    }
    job(uuid: $jobUuid) {
      id
      isFinal
      project {
        id
        scheduledMoveJobs {
          id
          assignedJobUsersForTips {
            id
          }
        }
        ...ProjectTipPayouts
      }
      ...JobActionDisabledTooltip
    }
  }
`;

export default JobTipPayoutsBlock;
