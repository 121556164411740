// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {DeviceInfo, Linking} from '@supermove/sdk';
import {colors} from '@supermove/styles';
import {downloadFromUrl} from '@supermove/utils';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import DocumentStepKind from '@shared/modules/Document/enums/DocumentStepKind';
import DownloadDocumentForm from '@shared/modules/Document/forms/DownloadDocumentForm';
import useDownloadDocumentMutation from '@shared/modules/Document/hooks/useDownloadDocumentMutation';

const DownloadButton = Styled.ButtonV2`
`;

const IconContainer = Styled.View`
  align-items: center;
  justify-content: flex-start;
  overflow: visible;
  width: 13px;
`;

const Indicator = Styled.Loading`
`;

const DownloadDocumentButton = ({document, iconSize, tooltipPlacement}) => {
  const downloadDocumentForm = DownloadDocumentForm.new({
    documentId: document.id,
  });
  const {handleSubmit, submitting} = useDownloadDocumentMutation({
    downloadDocumentForm,
    onSuccess: (response) => {
      // There look to be security issues on IOS browsers with downloadFromUrl. Chrome
      // flags the new tab as a popup and Safari blocks it entirely. As an alternative
      // we use Linking for IOS browsers. We stick with downloadFromUrl on desktop however
      // because with Linking we lose the ability to open the document in a new tab.
      // Additionally, Linking has problems with Android browsers, but Android does not
      // have security issues with downloadFromUrl so can continue using on Android as well.
      if (DeviceInfo.getHardwareOs() === 'IOS') {
        return Linking.openURL(response.url);
      }
      return downloadFromUrl(response.url);
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  if (submitting) {
    return (
      <IconContainer>
        <Indicator size={'small'} color={colors.gray.secondary} />
      </IconContainer>
    );
  }
  return document.isUploaded ? (
    <DownloadButton onPress={handleSubmit}>
      <IconContainer>
        <Icon color={colors.blue.interactive} size={iconSize} source={Icon.FileDownload} />
      </IconContainer>
    </DownloadButton>
  ) : (
    <TextTooltip
      text={
        _.includes(DocumentStepKind.CREW_APP_STEPS, document.step)
          ? 'Crew app documents must be completed first'
          : 'Document is being uploaded'
      }
      placement={tooltipPlacement}
    >
      <IconContainer>
        <Icon color={colors.gray.tertiary} size={iconSize} source={Icon.FileDownload} />
      </IconContainer>
    </TextTooltip>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DownloadDocumentButton.propTypes = {
  document: PropTypes.object.isRequired,
  iconSize: PropTypes.number,
};

DownloadDocumentButton.defaultProps = {
  iconSize: Icon.Sizes.Large,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DownloadDocumentButton.fragment = gql`
  ${DownloadDocumentForm.new.fragment}
  fragment DownloadDocumentButton on Document {
    id
    isUploaded
    step
    ...DownloadDocumentForm_new
  }
`;

export default DownloadDocumentButton;
