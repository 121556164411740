// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils/src';

const _new = withFragment(
  (jobUser) => ({
    jobUserId: (jobUser as any).id,
    moverPositionIds: (jobUser as any).jobUserMoverPositions.map(
      (jobUserMoverPosition: any) => jobUserMoverPosition.moverPositionId,
    ),
    primaryMoverPositionId: (jobUser as any).jobUserMoverPositions.find(
      (jobUserMoverPosition: any) => jobUserMoverPosition.isPrimary,
    )?.moverPositionId,
  }),
  gql`
    fragment UpdateJobUserForm_new on JobUser {
      id
      jobUserMoverPositions {
        id
        moverPositionId
        isPrimary
      }
    }
  `,
);

const toForm = ({jobUserId, moverPositionIds, primaryMoverPositionId}: any) => ({
  jobUserId,
  moverPositionIds,
  primaryMoverPositionId,
});

const toMutation = ({jobUserId, moverPositionIds, primaryMoverPositionId}: any) => ({
  jobUserId,
  moverPositionIds,
  primaryMoverPositionId,
});

const UpdateJobUserForm = {
  new: _new,
  toForm,
  toMutation,
};

export default UpdateJobUserForm;
