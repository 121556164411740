// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {DocumentItemInputTextInputsType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import {SetDocumentContentJsonType} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import InputIsRequiredCheckBox from 'modules/Organization/Settings/Document/components/InputIsRequiredCheckBox';

const TextInput = Styled.TextInput`
  ${Typography.Body3}
  border-width: 1px;
  border-color: ${colors.gray.border};
  width: 100%;
  padding-vertical: 9px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
  flex-direction: column;
  flex: 1;
`;

const DeleteFieldColumn = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-left: 16px;
`;

const addFieldInput = ({
  setDraftDocumentContentJson,
  documentItemField,
}: {
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentItemField: string;
}) => {
  setDraftDocumentContentJson((prevState) => {
    const fields = _.get(prevState, `${documentItemField}.input.metadata.fields`);
    const values = _.get(prevState, `${documentItemField}.input.value.values`);
    _.set(prevState, `${documentItemField}.input.metadata.fields`, [
      ...fields,
      {
        label: '',
        description: '',
        is_hidden: false,
      },
    ]);
    _.set(prevState, `${documentItemField}.input.value.values`, [...values, '']);
    return {...prevState};
  });
};

const removeFieldInput = ({
  setDraftDocumentContentJson,
  documentItemField,
  fieldIndex,
}: {
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentItemField: string;
  fieldIndex: number;
}) => {
  setDraftDocumentContentJson((prevState) => {
    const input = _.get(prevState, `${documentItemField}.input`);
    input.metadata.fields.splice(fieldIndex, 1);
    input.value.values.splice(fieldIndex, 1);
    _.set(prevState, `${documentItemField}.input`, input);
    return {...prevState};
  });
};

const InputTextInputEditor = ({
  documentItem,
  documentItemField,
  setDraftDocumentContentJson,
}: {
  documentItem: DocumentItemInputTextInputsType;
  documentItemField: string;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
}) => {
  const {fields} = documentItem.input.metadata;
  return (
    <React.Fragment>
      <InputIsRequiredCheckBox
        documentItem={documentItem}
        documentItemField={documentItemField}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
      />
      <Space height={24} />
      {fields.map((field, index) => (
        <React.Fragment key={index}>
          <Row>
            <Column>
              <TextInput
                autoFocus
                placeholder={'Enter input title'}
                value={field.label}
                multiline
                onChangeText={(text: string) => {
                  setDraftDocumentContentJson((prevState) => {
                    _.set(
                      prevState,
                      `${documentItemField}.input.metadata.fields.${index}.label`,
                      text,
                    );
                    return {...prevState};
                  });
                }}
              />
              <Space height={8} />
              <TextInput
                placeholder={'Enter input description'}
                value={field.description}
                multiline
                onChangeText={(text: string) => {
                  setDraftDocumentContentJson((prevState) => {
                    _.set(
                      prevState,
                      `${documentItemField}.input.metadata.fields.${index}.description`,
                      text,
                    );
                    return {...prevState};
                  });
                }}
              />
            </Column>
            {fields.length > 1 && (
              <DeleteFieldColumn>
                <TertiaryButton
                  iconLeft={Icon.Trash}
                  iconSize={12}
                  textColor={colors.gray.secondary}
                  onPress={() =>
                    removeFieldInput({
                      setDraftDocumentContentJson,
                      documentItemField,
                      fieldIndex: index,
                    })
                  }
                />
              </DeleteFieldColumn>
            )}
          </Row>
          <Space height={32} />
        </React.Fragment>
      ))}
      <TertiaryButton
        iconLeft={Icon.Plus}
        text={'Add Item'}
        onPress={() => addFieldInput({setDraftDocumentContentJson, documentItemField})}
      />
    </React.Fragment>
  );
};

export default InputTextInputEditor;
