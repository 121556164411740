// Libraries
import _ from 'lodash';
import React from 'react';
import {Redirect} from 'react-router-dom';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import UserRole from '@shared/modules/User/enums/UserRole';
import {Page} from 'modules/App/components';

const HomePage = () => (
  <Page query={HomePage.query}>
    {({loading, data}) => (
      <Loading loading={loading}>
        {() => {
          const {viewer} = data;
          if (!viewer) {
            return <Redirect to={'/login'} />;
          }

          const {role} = viewer;
          if (role === UserRole.EMPLOYEE) {
            return <Redirect to={'/1/schedule'} />;
          }

          if (UserRole.OFFICE_ROLES_PLUS_SUPER.includes(role)) {
            if (_.get(data, 'viewer.viewingOrganization.settings.isMovesTabPrimary', false)) {
              return <Redirect to={'/moves/capacity/calendar'} />;
            }
            return <Redirect to={'/calendar'} />;
          }

          return <Redirect to={'/login'} />;
        }}
      </Loading>
    )}
  </Page>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
HomePage.query = gql`
  query HomePage {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        settings {
          id
          isMovesTabPrimary
        }
      }
    }
  }
`;

export default HomePage;
