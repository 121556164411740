// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils/src';

const _new = withFragment(
  (moverPosition) => ({
    name: (moverPosition as any).name,
    isDriver: (moverPosition as any).isDriver,
    moverPositionId: (moverPosition as any).id,
  }),
  gql`
    fragment UpdateMoverPositionForm_new on MoverPosition {
      id
      name
      isDriver
    }
  `,
);

const toForm = ({name, isDriver, moverPositionId}: any) => ({
  name,
  isDriver,
  moverPositionId,
});

const toMutation = ({name, isDriver, moverPositionId}: any) => ({
  name,
  isDriver,
  moverPositionId,
});

const UpdateMoverPositionForm = {
  new: _new,
  toForm,
  toMutation,
};

export default UpdateMoverPositionForm;
