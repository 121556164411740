// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const sortClaimStatuses = withFragment(
  (claimStatuses) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    return _.orderBy(claimStatuses, ['rank']);
  },
  gql`
    fragment ClaimStatus_sortClaimStatuses on ClaimStatus {
      id
      rank
    }
  `,
);

const ClaimStatus = {
  sortClaimStatuses,
};

export default ClaimStatus;
