// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import Modal from '@shared/design/components/Modal';
import JobMap from 'modules/Job/components/JobMap';
import LocationFormsSection from 'modules/Job/components/LocationFormsSection';

const FieldSpace = () => {
  return <Space height={12} width={8} />;
};

const ProjectJobLocationFields = ({form, field, organization}) => {
  const locationFormsField = `${field}.locationForms`;
  const locationForms = _.get(form.values, locationFormsField);
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <Modal.BlockHeader>Locations</Modal.BlockHeader>
      <FieldSpace />
      <LocationFormsSection
        form={form}
        field={locationFormsField}
        locationForms={locationForms}
        responsive={responsive}
        organization={organization}
        isBuildingTypeRequired={false}
        isFullWidth
      />
      {/* NOTE(dan) JobMap is hidden and is only used
      to calculate and set location distances */}
      <JobMap
        isHidden
        locations={locationForms}
        onRouteUpdate={({distances}) => {
          form.setFieldValue(`${field}.locationDistances`, distances);
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobLocationFields.fragment = gql`
  ${LocationFormsSection.fragment}

  fragment ProjectJobLocationFields on Organization {
    id
    ...LocationFormsSection
  }
`;

export default ProjectJobLocationFields;
