// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const UpdateConfirmationCardMutation = ({
  confirmationId,
  paymentMethodId,
  payengineCreditCardId,
  onSuccess,
  onError,
  children,
}) => (
  <Mutation
    variables={{
      confirmationId,
      paymentMethodId,
      payengineCreditCardId,
    }}
    mutation={UpdateConfirmationCardMutation.mutation}
    onCompleted={({response: {confirmation, errors}}) => {
      if (!errors) {
        onSuccess(confirmation);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateConfirmationCardMutation.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

UpdateConfirmationCardMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateConfirmationCardMutation.mutation = gql`
  mutation UpdateConfirmationCardMutation(
    $confirmationId: Int!,
    $paymentMethodId: String,
    $payengineCreditCardId: Int,
  ) {
    response: updateConfirmationCard(
      confirmationId: $confirmationId,
      paymentMethodId: $paymentMethodId,
      payengineCreditCardId: $payengineCreditCardId,
    ) {
      ${gql.errors}
      confirmation {
        id
      }
    }
  }
`;

export default UpdateConfirmationCardMutation;
