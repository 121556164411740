// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive, useToggle} from '@supermove/hooks';
import {Payment, ProjectTypeModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import EmptyState from '@shared/design/components/EmptyState';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import UpdateProjectTypePaymentMethodsForm from '@shared/modules/PaymentMethod/forms/UpdateProjectTypePaymentMethodsForm';
import useUpdateProjectTypePaymentMethodsMutation from '@shared/modules/PaymentMethod/hooks/useUpdateProjectTypePaymentMethodsMutation';
import ProjectTypeBillingSettingsForm from '@shared/modules/Project/forms/ProjectTypeBillingSettingsForm';
import useUpdateProjectTypePaymentSettingsMutation from '@shared/modules/Project/hooks/useUpdateProjectTypePaymentSettingsMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import Switch from 'modules/App/components/Switch';
import PaymentMethodBadges from 'modules/Organization/Settings/Company/components/PaymentMethodBadges';
import ProjectTypePaymentMethodFields from 'modules/Organization/Settings/Company/components/ProjectTypePaymentMethodFields';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';

const View = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const PageHeading = Styled.Text`
  ${Typography.Responsive.PageHeading};
`;

const PageDescription = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.secondary};
`;

// /////////////////////////////////////////////////////////////////////
// TODO(jholston): Remove this block with isEnabledCustomPaymentMethods
// NOTE: any usage will be removed with feature flag
// /////////////////////////////////////////////////////////////////////
const EmptyPaymentSettingsBody = ({onPrimaryAction}: any) => {
  return (
    <EmptyState
      title={'No payment methods added.'}
      message={'To get started, add a payment method.'}
      primaryActionIcon={Icon.Plus}
      primaryActionText={'Add Payment Method'}
      handlePrimaryAction={onPrimaryAction}
    />
  );
};

const ShowPaymentSettingsPanel = ({projectType}: any) => {
  return (
    <Row>
      <View>
        <EditPanel.LabelText>Crew App</EditPanel.LabelText>
        <EditPanel.Text style={{color: colors.gray.secondary}}>
          Payment methods for the Crew App.
        </EditPanel.Text>
        <Space height={16} />
        {projectType.crewPaymentMethods.map((method: string, index: number) => (
          <Row key={index} style={{paddingBottom: '8px'}}>
            <EditPanel.Text>{Payment.getSettingsDisplayMethod({method})}</EditPanel.Text>
          </Row>
        ))}
      </View>
    </Row>
  );
};

const EditPaymentSettingsPanel = ({projectType, form}: any) => {
  const paymentMethods = [
    PaymentMethodKind.CASH,
    PaymentMethodKind.CHECK,
    PaymentMethodKind.EXTERNAL,
    PaymentMethodKind.PAYPAL,
    PaymentMethodKind.VENMO,
    PaymentMethodKind.INVOICE,
    ...List.insertIf(
      projectType.organization.features.isEnabledAuthorizeDotNetExternalPayment,
      PaymentMethodKind.AUTHORIZE_DOT_NET,
    ),
    ...List.insertIf(
      projectType.organization.payengineMerchant?.isProcessingEnabled,
      PaymentMethodKind.PAYENGINE_CREDIT_CARD,
      PaymentMethodKind.PAYENGINE_SAVE_CARD,
    ),
  ];

  const {crewPaymentMethods} = form.values.projectTypeBillingSettingsForm;
  const setPaymentMethod = (method: string) => {
    const newPaymentMethods = _.xor(crewPaymentMethods, [method]);
    form.setFieldValue('projectTypeBillingSettingsForm.crewPaymentMethods', newPaymentMethods);
  };

  return (
    <Row>
      <View>
        <EditPanel.LabelText>Crew App</EditPanel.LabelText>
        <EditPanel.Text style={{color: colors.gray.secondary}}>
          Payment methods for the Crew App.
        </EditPanel.Text>
        <Space height={16} />
        {paymentMethods.map((method, index) => (
          <Row key={index} style={{paddingBottom: '8px'}}>
            {/* @ts-expect-error */}
            <Switch
              isOn={_.includes(crewPaymentMethods, method)}
              onChange={() => setPaymentMethod(method)}
            />
            <Space width={8} />
            <EditPanel.Text>{Payment.getSettingsDisplayMethod({method})}</EditPanel.Text>
          </Row>
        ))}
      </View>
    </Row>
  );
};

const PaymentMethodSectionV1 = ({projectType, refetch}: any) => {
  const editPaymentSettingsToggle = useToggle();
  const projectTypeBillingSettingsForm = ProjectTypeBillingSettingsForm.edit(projectType);
  const {form, submitting, handleSubmit} = useUpdateProjectTypePaymentSettingsMutation({
    projectTypeBillingSettingsForm,
    onSuccess: () => {
      editPaymentSettingsToggle.handleToggleOff();
      refetch();
    },
    onError: (errors: unknown) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      index={0}
      bodyComponentProps={{projectType}}
      editBodyComponentProps={{projectType, form}}
      emptyBodyComponentProps={{onPrimaryAction: editPaymentSettingsToggle.handleToggleOn}}
      EmptyBodyComponent={EmptyPaymentSettingsBody}
      isEmpty={!projectType.crewPaymentMethods.length && !editPaymentSettingsToggle.isOn}
      BodyComponent={ShowPaymentSettingsPanel}
      EditBodyComponent={EditPaymentSettingsPanel}
      title={'Payment Methods'}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editPaymentSettingsToggle.isOn}
      handleEdit={editPaymentSettingsToggle.handleToggleOn}
      handleClose={editPaymentSettingsToggle.handleToggleOff}
    />
  );
};
// /////////////////////////////////////////////////////////////////////
// END
// /////////////////////////////////////////////////////////////////////
const PaymentMethodSection = ({
  projectType,
  refetch,
}: {
  projectType: ProjectTypeModel;
  refetch: () => void;
}) => {
  const editPaymentMethodsToggle = useToggle();
  const updateProjectTypePaymentMethodsForm = UpdateProjectTypePaymentMethodsForm.edit(projectType);
  const {form, handleSubmit, submitting} = useUpdateProjectTypePaymentMethodsMutation({
    updateProjectTypePaymentMethodsForm,
    onSuccess: () => {
      editPaymentMethodsToggle.handleToggleOff();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  const field = 'updateProjectTypePaymentMethodsForm.projectTypePaymentMethodForms';

  return (
    <EditPanel
      index={0}
      BodyComponent={PaymentMethodBadges}
      bodyComponentProps={{paymentMethods: projectType.paymentMethods}}
      EditBodyComponent={ProjectTypePaymentMethodFields}
      editBodyComponentProps={{form, field, organization: projectType.organization}}
      title={'Payment Methods'}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editPaymentMethodsToggle.isOn}
      handleEdit={editPaymentMethodsToggle.handleToggleOn}
      handleClose={editPaymentMethodsToggle.handleToggleOff}
    />
  );
};

const ProjectTypePaymentSettingsContent = ({projectTypeUuid}: {projectTypeUuid: string}) => {
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(ProjectTypePaymentSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <View>
      <ProjectTypeSettingsPageHeader projectType={data.projectTypeByUuid} />
      <PageContentContainer style={{paddingHorizontal: responsive.desktop ? 24 : 0}}>
        <ScrollView>
          <Space height={24} />
          {data.projectTypeByUuid.organization.features.isEnabledCustomPaymentMethods ? (
            <React.Fragment>
              <PageHeading responsive={responsive}>Payments</PageHeading>
              <Space height={16} />
              <PageDescription responsive={responsive}>
                Select the payment methods available on the Crew App for Local Move.
              </PageDescription>
              <Space height={16} />
              <PaymentMethodSection projectType={data.projectTypeByUuid} refetch={refetch} />
            </React.Fragment>
          ) : (
            <PaymentMethodSectionV1 projectType={data.projectTypeByUuid} refetch={refetch} />
          )}
          <Space height={48} />
        </ScrollView>
      </PageContentContainer>
    </View>
  );
};

const ProjectTypePaymentSettingsPage = () => {
  const {params} = useNavigationDOM();

  return (
    <SidebarPageV2 selected={'settings'}>
      <ProjectTypePaymentSettingsContent projectTypeUuid={params.projectTypeUuid} />
    </SidebarPageV2>
  );
};

ProjectTypePaymentSettingsPage.query = gql`
  ${PaymentMethodBadges.fragment}
  ${ProjectTypeSettingsPageHeader.fragment}
  ${ProjectTypeBillingSettingsForm.edit.fragment}
  ${ProjectTypePaymentMethodFields.fragment}
  ${UpdateProjectTypePaymentMethodsForm.edit.fragment}

  query ProjectTypePaymentSettingsPage($projectTypeUuid: String!) {
    ${gql.query}

    projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      crewPaymentMethods
      organization {
        id
        payengineMerchant {
          id
          isProcessingEnabled
        }
        features {
          isEnabledAuthorizeDotNetExternalPayment: isEnabled(feature: "AUTHORIZE_DOT_NET_EXTERNAL_PAYMENT")
          isEnabledCustomPaymentMethods: isEnabled(feature: "CUSTOM_PAYMENT_METHODS")
        }
        ...ProjectTypePaymentMethodFields
      }
      paymentMethods {
        id
        ...PaymentMethodBadges
      }
      ...ProjectTypeSettingsPageHeader
      ...ProjectTypeBillingSettingsForm_edit
      ...UpdateProjectTypePaymentMethodsForm_edit
    }
  }
`;

export default ProjectTypePaymentSettingsPage;
