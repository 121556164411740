// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import ItemTagForm from '@shared/modules/Inventory/forms/ItemTagForm';

const edit = withFragment(
  (inventoryLibrary) => ({
    itemTagForms: (inventoryLibrary as any).itemTagsByKind.map((itemTag: any) =>
      ItemTagForm.edit(itemTag),
    ),
  }),
  gql`
    ${ItemTagForm.edit.fragment}
    fragment ItemTagsForm_edit on InventoryLibrary {
      id
      itemTagsByKind(itemTagKind: $itemTagKind) {
        id
        ...ItemTagForm_edit
      }
    }
  `,
);

const toForm = ({itemTagForms}: any) => ({
  itemTagForms: itemTagForms.map((itemTagForm: any) => ItemTagForm.toForm(itemTagForm)),
});

const toMutation = ({itemTagForms}: any) => ({
  itemTagForms: _.reduce(
    itemTagForms,
    // @ts-expect-error TS(2769): No overload matches this call.
    (filteredForms, itemTagForm, index) => {
      if (itemTagForm.isDirty) {
        const isDeletingNewForm = itemTagForm.isDeleted && !itemTagForm.itemTagId;
        if (isDeletingNewForm) {
          // We don't pass these forms to the BE since there isn't an object to delete
          return filteredForms;
        }
        return [
          ...filteredForms,
          {
            ...ItemTagForm.toMutation(itemTagForm),
            // Since we only pass dirty forms, we need to pass the current index to ensure the validation error is shown properly
            validationIndex: index,
          },
        ];
      }
      return filteredForms;
    },
    [],
  ),
});

const ItemTagsForm = {
  edit,
  toForm,
  toMutation,
};

export default ItemTagsForm;
