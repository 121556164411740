// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';

// Components
import CompensationReportView from 'modules/Accounting/CompensationReport/components/CompensationReportView';
import PrintDocumentPage from 'modules/Document/Print/components/PrintDocumentPage';

const PrintCompensationReportAccountingPage = () => {
  const {params} = useNavigationDOM();
  return (
    <PrintDocumentPage
      query={PrintCompensationReportAccountingPage.query}
      variables={{compensationReportUuid: params.compensationReportUuid}}
    >
      {({data}) => <CompensationReportView compensationReport={data.compensationReport} />}
    </PrintDocumentPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PrintCompensationReportAccountingPage.query = gql`
  ${CompensationReportView.fragment}

  query PrintCompensationReportAccountingPage($compensationReportUuid: String!) {
    ${gql.query}
    compensationReport(compensationReportUuid: $compensationReportUuid) {
      id
      ...CompensationReportView
    }
  }
`;

export default PrintCompensationReportAccountingPage;
