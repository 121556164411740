// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ReorderClaimStatusesForm from '@shared/modules/Claim/forms/ReorderClaimStatusesForm';

const useReorderClaimStatusesMutation = ({reorderClaimStatusesForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      reorderClaimStatusesForm: ReorderClaimStatusesForm.toForm(reorderClaimStatusesForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useReorderClaimStatusesMutation.mutation,
    variables: {
      reorderClaimStatusesForm: ReorderClaimStatusesForm.toMutation(
        form.values.reorderClaimStatusesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useReorderClaimStatusesMutation.mutation = gql`
  mutation useReorderClaimStatusesMutation($reorderClaimStatusesForm: ReorderClaimStatusesForm!) {
    response: reorderClaimStatuses(reorderClaimStatusesForm: $reorderClaimStatusesForm) {
      ${gql.errors}
      claimStatuses {
        id
      }
    }
  }
`;

export default useReorderClaimStatusesMutation;
