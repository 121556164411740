// Libraries
import React from 'react';

// Supermove
import {Styled, Tooltip} from '@supermove/components';
import {useToast, useTouch} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// Local
import Toast from '../Toast';

const TooltipText = Styled.H7`
  color: ${colors.white};
  ${fontWeight(500)};
`;

const Container = Styled.View`
`;

const ToastButton = Styled.ButtonV2`
`;

const TooltipHint = ({
  text,
  placement = 'top',
  numberOfLines = undefined,
  tooltipStyle = null,
  tooltipChildrenContainerStyle = null,
  children,
}: {
  text: string;
  placement?: string;
  numberOfLines?: number;
  tooltipStyle?: object | null;
  tooltipChildrenContainerStyle?: object | null;
  children: React.ReactNode;
}) => {
  return (
    <Tooltip
      overlay={<TooltipText numberOfLines={numberOfLines}>{text}</TooltipText>}
      placement={placement}
      mouseEnterDelay={0.0}
      mouseLeaveDelay={0.0}
      overlayStyle={tooltipStyle || {maxWidth: '20%'}}
    >
      <Container style={tooltipChildrenContainerStyle}>{children}</Container>
    </Tooltip>
  );
};

const ToastHint = ({
  message,
  isDisabled = false,
  children,
}: {
  message: string;
  isDisabled?: boolean;
  children: React.ReactNode;
}) => {
  const hintToast = useToast({
    ToastComponent: Toast,
    message,
  });

  // The React Native behavior here is such if the child component takes the touch
  // event, then the ToastButton will not. If there are no received touch events on
  // the child or all child touch events are disabled, then ToastButton will receive
  // the touch event.
  return (
    <ToastButton onPress={hintToast.handleToast} disabled={isDisabled}>
      {children}
    </ToastButton>
  );
};

const Hint = ({
  message,
  isDisabled = false,
  tooltipPlacement = 'top',
  tooltipNumberOfLines = undefined,
  tooltipStyle = null,
  tooltipChildrenContainerStyle = null,
  children,
}: {
  message: string;
  isDisabled?: boolean;
  tooltipPlacement?: string;
  tooltipNumberOfLines?: number;
  tooltipStyle?: object | null;
  tooltipChildrenContainerStyle?: object | null;
  children: React.ReactNode;
}) => {
  const {isTouch} = useTouch();

  if (isTouch) {
    return (
      <ToastHint message={message} isDisabled={isDisabled}>
        {children}
      </ToastHint>
    );
  }

  return isDisabled || !message ? (
    children
  ) : (
    <TooltipHint
      text={message}
      placement={tooltipPlacement}
      numberOfLines={tooltipNumberOfLines}
      tooltipStyle={tooltipStyle}
      tooltipChildrenContainerStyle={tooltipChildrenContainerStyle}
    >
      {children}
    </TooltipHint>
  );
};

export default Hint;
