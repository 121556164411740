// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ReferralSource} from '@supermove/models';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ProjectInternalInformationForm from '@shared/modules/Project/forms/ProjectInternalInformationForm';
import useUpdateProjectInternalInformationMutation from '@shared/modules/Project/hooks/useUpdateProjectInternalInformationMutation';
import OrganizationStaffDropdown from 'modules/Organization/components/OrganizationStaffDropdown';

const EditProjectInternalInformationDrawerFields = ({form, field, project}) => {
  const {organization} = project;
  const {
    isEnabledAdditionalSalespersons,
    isEnabledProjectCoordinatorRequired,
    isEnabledProjectReferralSourceRequired,
    isEnabledProjectManagersField,
  } = organization.features;
  const {referralSources} = organization.settings;
  return (
    <React.Fragment>
      <OrganizationStaffDropdown
        index={0}
        organization={organization}
        form={form}
        field={`${field}.bookedById`}
        label={'Salesperson'}
        placeholder={'Select salesperson'}
        inputStyle={{flex: 1}}
        isSalesperson
        isResponsive
      />
      <Space height={16} />
      {isEnabledAdditionalSalespersons && (
        <React.Fragment>
          <OrganizationStaffDropdown
            index={1}
            organization={organization}
            form={form}
            field={`${field}.additionalSalespersonIds`}
            label={isEnabledProjectManagersField ? 'Project Managers' : 'Additional Salespersons'}
            placeholder={`Select ${
              isEnabledProjectManagersField ? 'project managers' : 'salespersons'
            }`}
            isMultiSelect
            inputStyle={{flex: 1}}
            isSalesperson
            isResponsive
          />
          <Space height={16} />
        </React.Fragment>
      )}
      <OrganizationStaffDropdown
        index={2}
        organization={organization}
        form={form}
        field={`${field}.coordinatedById`}
        label={'Coordinator'}
        placeholder={'Select coordinator'}
        inputStyle={{flex: 1}}
        isRequired={isEnabledProjectCoordinatorRequired}
        isCoordinator
        isResponsive
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={3}
        component={DropdownInput}
        name={`${field}.referralSource`}
        label={'How did you hear about us?'}
        isRequired={isEnabledProjectReferralSourceRequired}
        isResponsive
        input={{
          placeholder: 'Select referral source',
          options: ReferralSource.getDropdownOptions({referralSources}),
          setFieldValue: (name, value) => {
            const previousValue = _.get(form.values, name);

            // First update the referralSource.
            form.setFieldValue(name, value);

            // When the referralSource is updated, we clear the referralDetails.
            if (previousValue !== value) {
              form.setFieldValue(`${field}.referralDetails`, '');
            }
          },
          style: {flex: 1},
        }}
        style={{flex: 1}}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={4}
        name={`${field}.referralDetails`}
        label={'Referral Details'}
        input={{
          placeholder: 'Enter referral details',
        }}
        style={{flex: 1}}
        isResponsive
      />
      <Space height={16} />
    </React.Fragment>
  );
};

const EditProjectInternalInformationDrawer = ({project, isOpen, handleClose, refetch}) => {
  const projectInternalInformationForm = ProjectInternalInformationForm.edit(project);

  const {form, handleSubmit, submitting} = useUpdateProjectInternalInformationMutation({
    projectInternalInformationForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Edit Internal Information'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <EditProjectInternalInformationDrawerFields
        form={form}
        field={'projectInternalInformationForm'}
        project={project}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectInternalInformationDrawer.fragment = gql`
  ${OrganizationStaffDropdown.fragment}
  ${ProjectInternalInformationForm.edit.fragment}
  ${ReferralSource.getDropdownOptions.fragment}

  fragment EditProjectInternalInformationDrawer on Project {
    id
    organization {
      id
      settings {
        id
        referralSources {
          ...ReferralSource_getDropdownOptions
        }
      }
      features {
        isEnabledAdditionalSalespersons: isEnabled(feature: "ADDITIONAL_SALESPERSON_IDS_INPUT")
        isEnabledProjectCoordinatorRequired: isEnabled(feature: "PROJECT_COORDINATOR_REQUIRED")
        isEnabledProjectReferralSourceRequired: isEnabled(
          feature: "PROJECT_REFERRAL_SOURCE_REQUIRED"
        )
        isEnabledProjectManagersField: isEnabled(feature: "PROJECT_MANAGERS_FIELD")
      }
      ...OrganizationStaffDropdown
    }
    ...ProjectInternalInformationForm_edit
  }
`;

export default EditProjectInternalInformationDrawer;
