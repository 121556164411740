/**
 * Component - v2.0.0
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Modal, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  ${(props) => (props.mobile ? '' : 'padding-vertical: 40px;')}
`;

const Wrapper = Styled.View`
  flex: 1;
  width: ${(props) => (props.mobile ? '100%' : `${props.width}px`)};
  background-color: ${colors.white};
  ${(props) => (props.mobile ? '' : 'box-shadow: 0 2px 5px rgba(194,194,194,0.5);')}
  ${(props) => (props.mobile ? '' : 'border-radius: 5px;')}
`;

const HeaderSection = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
  flex-direction: row;
  align-items: center;
  height: 60px;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const HeaderText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.primary};
`;

const CloseButton = Styled.ButtonV2`
  padding: 6px;
`;

/**
 * Renders a large modal on top of an opaque overlay.
 * In terms of width:
 * - On desktop web, modal accepts width as a prop with a default.
 * - On mobile web, modal fills page.
 * In terms of height:
 * - On desktop web, modal fills page except for some vertical margin.
 * - On mobile web, modal fills page.
 */
const LargeModal = ({isOpen, width, handleClose, disableCloseOnPressOutside, title, children}) => {
  const responsive = useResponsive();

  return (
    <Modal.Content isOpen={isOpen} onClose={disableCloseOnPressOutside ? () => {} : handleClose}>
      <Overlay pointerEvents={'box-none'} {...responsive}>
        <Wrapper width={width} {...responsive}>
          <HeaderSection sectionIndex={0}>
            <Space width={28} />
            <HeaderText>{title}</HeaderText>
            <Space style={{flex: 1}} />
            <CloseButton onPress={handleClose}>
              <Icon source={Icon.Times} size={24} color={colors.gray.primary} />
            </CloseButton>
            <Space width={28} />
          </HeaderSection>
          <Section
            sectionIndex={1}
            style={{
              flex: 1,
            }}
          >
            {children}
          </Section>
        </Wrapper>
      </Overlay>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
LargeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  disableCloseOnPressOutside: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
};

LargeModal.defaultProps = {
  width: 600,
  disableCloseOnPressOutside: false,
};

export default LargeModal;
