// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Query, SidebarV1, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';

// Components
import {Page, PageHeader, PageLoadingIndicator} from 'modules/App/components';
import {SidebarItems} from 'modules/App/components/Sidebar/components';

import EmployeeSidebar from './EmployeeSidebar';

const Container = Styled.View`
  flex: 1;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
`;

// DEPRECATED(dan). This is no longer used. Use EmployeePage instead.
const EmployeePageV1 = ({selected, variables, query, children, isTripShipmentPage}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <Query fetchPolicy={'cache-and-network'} query={EmployeePageV1.query}>
      {({data}) => (
        <Page variables={variables} query={query}>
          {({responsive, loading, ...props}) =>
            responsive.mobile ? (
              <SidebarV1
                isOpen={isSidebarOpen}
                position={'right'}
                onOpen={() => setIsSidebarOpen(true)}
                onClose={() => setIsSidebarOpen(false)}
                content={
                  <SidebarItems
                    selectedItem={selected}
                    viewer={_.get(data, 'viewer', {})}
                    onSidebarClose={() => setIsSidebarOpen(false)}
                  />
                }
                styles={{sidebar: {background: '#222f3e'}}}
              >
                <Container {...responsive}>
                  <PageHeader
                    viewer={_.get(data, 'viewer', {})}
                    onSidebarOpen={() => setIsSidebarOpen(true)}
                    isTripShipmentPage={isTripShipmentPage}
                  />
                  <Loading loading={loading} as={PageLoadingIndicator}>
                    {() => children({responsive, ...props})}
                  </Loading>
                </Container>
              </SidebarV1>
            ) : (
              <Container {...responsive}>
                <EmployeeSidebar selected={selected} />
                <Loading loading={loading} as={PageLoadingIndicator}>
                  {() => children({responsive, ...props})}
                </Loading>
              </Container>
            )
          }
        </Page>
      )}
    </Query>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmployeePageV1.query = gql`
  ${PageHeader.fragment}
  ${SidebarItems.fragment}

  query EmployeePageV1 {
    ${gql.query}

    viewer {
      id
      role
      ...PageHeader
      ...SidebarItems_viewer
    }
  }
`;

export default EmployeePageV1;
