const _new = ({jobId, status}: any) => ({
  jobId,
  status,
});

const toForm = ({jobId, status}: any) => ({
  jobId,
  status,
});

const toMutation = ({jobId, status}: any) => ({
  jobId,
  status,
});

const JobPrimaryStatusForm = {
  new: _new,
  toForm,
  toMutation,
};

export default JobPrimaryStatusForm;
