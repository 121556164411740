// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, PercentInput, Popover, Space, Styled} from '@supermove/components';
import {usePopover, useToast} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Toast from '@shared/design/components/Toast';
import OrganizationTipOptionsForm from '@shared/modules/Organization/forms/OrganizationTipOptionsForm';
import useUpdateOrganizationTipOptionsMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationTipOptionsMutation';

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  flex-direction: row;
  flex: 1;
  /* Must use padding here to get spacing between components in drag and drop list */
  /* Drag and drop does some weird stuff with the Space component so it does not show up properly */
  padding-vertical: 4px;
`;

const ItemContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 8px;
  padding-vertical: 12px;
  padding-horizontal: 16px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  z-index: ${({index}) => 100 - index};
  flex: 1;
`;

const Label = Styled.Text`
  ${Typography.Responsive.Label}
`;

const EllipseButton = ({form, field, handleSubmit, index, isDisabled}) => {
  const actionsPopover = usePopover();
  const deleteTipOption = useToast({
    ToastComponent: Toast,
    message: 'Tip deleted.',
  });
  return (
    <React.Fragment>
      <Popover.Content innerRef={actionsPopover.ref}>
        <TertiaryButton
          onPress={actionsPopover.handleToggle}
          style={{paddingHorizontal: 8}}
          isDisabled={isDisabled}
        >
          <Icon
            source={Icon.EllipsisV}
            color={isDisabled ? colors.gray.disabled : colors.gray.secondary}
            size={16}
          />
        </TertiaryButton>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={actionsPopover.isOpen}
        handleOpen={actionsPopover.handleOpen}
        handleClose={actionsPopover.handleClose}
        reference={actionsPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          handleClose={actionsPopover.handleClose}
          actions={[
            {
              text: 'Delete',
              onPress: () => {
                deleteTipOption.handleToast();
                const forms = _.cloneDeep(_.get(form.values, field));
                const updatedForms = List.remove(forms, index);
                form.setFieldValue(field, updatedForms);
                setTimeout(handleSubmit, 0);
              },
            },
          ]}
        />
      </Popover>
    </React.Fragment>
  );
};

const EditTipOption = ({
  index,
  form: parentForm,
  field: parentField,
  setIndexOfEdit,
  isViewingChildBranch,
}) => {
  const isNewTipOption =
    index === _.get(parentForm.values, parentField).length - 1 &&
    !_.get(parentForm.values, `${parentField}.${index}.amount`);
  const handleCancelEdit = () => {
    if (isNewTipOption) {
      const forms = _.cloneDeep(_.get(parentForm.values, parentField));
      const updatedForms = List.remove(forms, index);
      parentForm.setFieldValue(parentField, updatedForms);
    }
    setIndexOfEdit(null);
  };
  // Duplicate the existing form and create a separate mutation hook.
  // This allows the parent form remain unaffected on cancel.
  const {form, handleSubmit, submitting} = useUpdateOrganizationTipOptionsMutation({
    organizationTipOptionsForm: OrganizationTipOptionsForm.toMutation(
      _.get(parentForm.values, 'organizationTipOptionsForm'),
    ),
    onSuccess: () => {
      // On success, update the parent form with the new values and close the edit.
      parentForm.setFieldValue(parentField, _.get(form.values, parentField));
      setIndexOfEdit(null);
    },
    onError: (errors) => {
      console.log(errors);
    },
  });
  const field = `${parentField}.${index}`;
  const isDisabled = isViewingChildBranch || submitting;
  return (
    <Container>
      <ItemContainer index={index}>
        <FieldInput
          {...form}
          name={`${field}.amount`}
          component={PercentInput}
          isResponsive
          input={{
            component: FieldInput.TextInput,
            autoFocus: true,
            placeholder: 'Enter tip amount',
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
            style: {
              width: '100%',
            },
          }}
          handleEnter={handleSubmit}
          style={{flex: 1}}
        />
        <Space width={16} />
        <Row>
          <TertiaryButton onPress={handleSubmit} isDisabled={isDisabled}>
            <Icon
              source={Icon.Check}
              color={isDisabled ? colors.gray.disabled : colors.blue.interactive}
              size={16}
            />
          </TertiaryButton>
          <Space width={8} />
          <TertiaryButton
            onPress={handleCancelEdit}
            isDisabled={isDisabled || submitting}
            style={{paddingHorizontal: 4}}
          >
            <Icon
              source={Icon.XmarkLarge}
              color={isDisabled ? colors.gray.disabled : colors.gray.secondary}
              size={16}
            />
          </TertiaryButton>
        </Row>
      </ItemContainer>
    </Container>
  );
};

const NoTipOption = ({responsive, index}) => {
  return (
    <Row style={{alignItems: 'center', paddingVertical: 4}}>
      <Icon
        color={colors.gray.secondary}
        size={Icon.Sizes.Large}
        source={Icon.GripVertical}
        style={{opacity: 0.5}}
      />
      <Space width={10} />
      <ItemContainer index={index}>
        <Label responsive={responsive}>Cash/No Tip</Label>
      </ItemContainer>
    </Row>
  );
};

const TipOption = ({
  index,
  form,
  field,
  tipOptionForm,
  handleSubmit,
  setIndexOfEdit,
  responsive,
  isDisabled,
}) => {
  return (
    <Container>
      <ItemContainer index={index}>
        <Label responsive={responsive}>{tipOptionForm.amount}</Label>
        <Space style={{flex: 1, minWidth: 16}} />
        <Row>
          <TertiaryButton onPress={() => setIndexOfEdit(index)} isDisabled={isDisabled}>
            <Icon
              source={Icon.Pen}
              color={isDisabled ? colors.gray.disabled : colors.blue.interactive}
              size={16}
            />
          </TertiaryButton>
          <Space width={8} />
          <EllipseButton
            form={form}
            field={field}
            index={index}
            handleSubmit={handleSubmit}
            isDisabled={isDisabled}
          />
        </Row>
      </ItemContainer>
    </Container>
  );
};

TipOption.Edit = EditTipOption;
TipOption.NoTip = NoTipOption;

export default TipOption;
