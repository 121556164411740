// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useEffect, useState} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import RadioButton from '@shared/design/components/RadioButton';
import {ReadonlySectionSettingsType} from '@shared/modules/Document/forms/DocumentItemForm';
import DocumentTemplateVersionEditorTypes, {
  DocumentContentJsonType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';

const SelectSectionContainer = Styled.View`
`;

const CreateSectionModal = ({
  documentContentJson,
  isOpen,
  isEnabledMultiPartDocuments,
  handleClose,
  handleCreateSection,
}: {
  documentContentJson: DocumentContentJsonType;
  isOpen: boolean;
  isEnabledMultiPartDocuments: boolean;
  handleClose: () => void;
  handleCreateSection: (
    name: string,
    readonlySectionSettings?: ReadonlySectionSettingsType,
  ) => void;
}) => {
  const [name, setName] = useState('');
  const [readonlySectionSettings, setReadonlySectionSettings] = useState<
    ReadonlySectionSettingsType | undefined
  >(undefined);

  const handleSubmit = () => {
    handleCreateSection(name, readonlySectionSettings);
    handleClose();
  };

  const sections = _.filter(
    documentContentJson.documentItems,
    DocumentTemplateVersionEditorTypes.DocumentItem.getIsSection,
  );

  const showSectionSelection = isEnabledMultiPartDocuments && sections.length > 0;

  // If there's no sections we don't want to set any selected section
  // But if there are, default to "None" on first render
  useEffect(() => {
    if (showSectionSelection && sections.length) {
      setReadonlySectionSettings({setReadonlyForAllSteps: true});
    }
  }, []);

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Create Section'}
      handleAction={handleSubmit}
      handleActionText={'Save'}
      isDisabledPrimaryAction={!name}
    >
      <FieldInput
        label={'Section Name'}
        isRequired
        handleEnter={handleSubmit}
        input={{
          placeholder: 'Enter section name',
          onChangeText: setName,
          autoFocus: true,
        }}
      />
      {showSectionSelection && (
        <SelectSectionContainer>
          <Space height={16} />
          <FieldInput.LabelText isRequired>Select Section to Match Crew Steps</FieldInput.LabelText>
          <Space height={8} />
          <RadioButton
            isOn={!!readonlySectionSettings?.setReadonlyForAllSteps}
            onPress={() => setReadonlySectionSettings({setReadonlyForAllSteps: true})}
            label='None'
            hint='This section will default to read-only, even with inputs.'
          />
          {sections.map((section) => (
            <React.Fragment key={section.uuid}>
              <Space height={8} />
              <RadioButton
                isOn={readonlySectionSettings?.matchSectionUuid === section.uuid}
                onPress={() => setReadonlySectionSettings({matchSectionUuid: section.uuid})}
                label={section.name}
              />
            </React.Fragment>
          ))}
          <Space height={8} />
        </SelectSectionContainer>
      )}
      <Space height={16} />
    </SmallModal>
  );
};

export default CreateSectionModal;
