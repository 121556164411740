// Supermove

// Supermove
import {uuid} from '@supermove/utils';

export interface CreateAndSendConfirmationForProjectActionFormType {
  workflowRunStepUuid: string;
}

const _new = (): CreateAndSendConfirmationForProjectActionFormType => ({
  workflowRunStepUuid: uuid(),
});

const edit = (
  createAndSendConfirmationForProjectActionForm: any,
): CreateAndSendConfirmationForProjectActionFormType => ({
  workflowRunStepUuid: createAndSendConfirmationForProjectActionForm.workflowRunStepUuid,
});

const toForm = (
  CreateAndSendConfirmationForProjectActionForm: CreateAndSendConfirmationForProjectActionFormType,
): CreateAndSendConfirmationForProjectActionFormType => ({
  workflowRunStepUuid: CreateAndSendConfirmationForProjectActionForm.workflowRunStepUuid,
});

const toMutation = (
  CreateAndSendConfirmationForProjectActionForm: CreateAndSendConfirmationForProjectActionFormType,
): CreateAndSendConfirmationForProjectActionFormType => ({
  workflowRunStepUuid: CreateAndSendConfirmationForProjectActionForm.workflowRunStepUuid,
});

const CreateAndSendConfirmationForProjectActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default CreateAndSendConfirmationForProjectActionForm;
