// Supermove
import _ from 'lodash';

import {gql} from '@supermove/graphql';
import {Currency, Datetime, Float, Percent, withFragment} from '@supermove/utils';

// App
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';

export interface VariableOption {
  label: string;
  key: string;
  value: string;
}

export interface ValueForm {
  valueId?: number;
  projectId?: number;
  jobId?: number;
  dataTableVariableId?: number;
  variableId: number;
  variableIdentifier: string;
  variableFormat: string;
  projectTypeVariableId?: number;
  jobTypeVariableId?: number;
  isVisibleForCreateProject: boolean;
  isRequiredForCreateProject: boolean;
  isEnabled: boolean;
  key: string;
  amount: number | null;
  stringValue: string;

  // Private
  name: string;
  description: string;
  format: string;
  isDerived: boolean;
  isZipcodeCityVariable: boolean;
  variableOptions: VariableOption[];
  variableSectionId?: number;
  isDisabled: boolean;

  // TODO(kevin): remove this FF when released to all, much easier
  // to pass the FF into the form as a private variable since the entire value
  // fields uses a form and no model object
  isEnabledDerivedBillingValues: boolean;
  isEnabledTbdBillItems: boolean;
}

export interface ValueFormAsForm {
  valueId?: number;
  projectId?: number;
  jobId?: number;
  dataTableVariableId?: number;
  variableId: number;
  variableIdentifier: string;
  variableFormat: string;
  projectTypeVariableId?: number;
  jobTypeVariableId?: number;
  isVisibleForCreateProject: boolean;
  isRequiredForCreateProject: boolean;
  isEnabled: boolean;
  key: string;
  amount: string | null;
  stringValue: string;

  // Private
  name: string;
  description: string;
  format: string;
  isDerived: boolean;
  isZipcodeCityVariable: boolean;
  variableOptions: VariableOption[];
  variableSectionId?: number;
  isDisabled: boolean;

  // TODO(kevin): remove this FF when released to all, much easier
  // to pass the FF into the form as a private variable since the entire value
  // fields uses a form and no model object
  isEnabledDerivedBillingValues: boolean;
  isEnabledTbdBillItems: boolean;
}

export interface ValueFormAsMutation {
  valueId?: number;
  projectId?: number;
  jobId?: number;
  variableId: number;
  variableIdentifier: string;
  variableFormat: string;
  dataTableVariableId?: number;
  projectTypeVariableId?: number;
  jobTypeVariableId?: number;
  isVisibleForCreateProject: boolean;
  isRequiredForCreateProject: boolean;
  isEnabled: boolean;
  key: string;
  amount: number | null;
  stringValue: string;
}

const newFromVariable = withFragment(
  (variable: any, {variableSectionId = undefined} = {}): ValueForm => ({
    valueId: undefined,
    projectId: undefined,
    jobId: undefined,
    variableId: variable.id,
    variableIdentifier: variable.identifier,
    variableFormat: variable.format,
    dataTableVariableId: _.get(variable, 'dataTableVariable.id'),
    projectTypeVariableId: undefined,
    key: '',
    amount: variable.organization.features.isEnabledTbdBillItems ? null : 0,
    isVisibleForCreateProject: variable.isVisibleForCreateProject,
    isRequiredForCreateProject: false,
    isEnabled: true,
    stringValue: '',

    // Private
    name: variable.name,
    description: variable.description,
    format: variable.format,
    isDerived: variable.isDerived,
    isZipcodeCityVariable: false,
    variableOptions: JSON.parse(variable.variableOptionsV3),
    variableSectionId,
    isDisabled: variable.isDerived,

    // TODO(Kevin): Remove this FF when all rolled out
    isEnabledDerivedBillingValues: variable.organization.features.isEnabledDerivedBillingValues,
    isEnabledTbdBillItems: variable.organization.features.isEnabledTbdBillItems,
  }),
  gql`
    fragment ValueForm_newFromVariable on Variable {
      id
      description
      identifier
      name
      format
      isDerived
      isVisibleForCreateProject
      variableOptionsV3
      dataTableVariable {
        id
      }
      organization {
        id
        features {
          isEnabledDerivedBillingValues: isEnabled(feature: "DERIVED_BILLING_VALUES")
          isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
        }
      }
    }
  `,
);

const newFromProjectTypeVariable = withFragment(
  (projectTypeVariable: any, {variableSectionId = undefined} = {}): ValueForm => ({
    valueId: undefined,
    projectId: undefined,
    jobId: undefined,
    variableId: projectTypeVariable.variableId,
    variableIdentifier: projectTypeVariable.variable.identifier,
    variableFormat: projectTypeVariable.variable.format,
    dataTableVariableId: _.get(projectTypeVariable, 'variable.dataTableVariable.id'),
    projectTypeVariableId: projectTypeVariable.id,
    key: '',
    amount: projectTypeVariable.defaultAmount,
    isVisibleForCreateProject: projectTypeVariable.isVisibleForCreateProject,
    isRequiredForCreateProject: projectTypeVariable.isRequiredForCreateProject,
    isEnabled: projectTypeVariable.isEnabled,
    stringValue: projectTypeVariable.defaultStringValue,

    // Private
    name: projectTypeVariable.variable.name,
    description: projectTypeVariable.variable.description,
    format: projectTypeVariable.variable.format,
    isDerived: projectTypeVariable.variable.isDerived,
    isZipcodeCityVariable: false,
    variableOptions: JSON.parse(projectTypeVariable.variable.variableOptionsV3),
    variableSectionId,
    isDisabled: projectTypeVariable.variable.isDerived,

    // TODO(Kevin): Remove this FF when all rolled out
    isEnabledDerivedBillingValues:
      projectTypeVariable.variable.organization.features.isEnabledDerivedBillingValues,
    isEnabledTbdBillItems: projectTypeVariable.variable.organization.features.isEnabledTbdBillItems,
  }),
  gql`
    fragment ValueForm_newFromProjectTypeVariable on ProjectTypeVariable {
      id
      isVisibleForCreateProject
      isRequiredForCreateProject
      isEnabled
      defaultAmount
      defaultStringValue
      variable {
        id
        identifier
        name
        description
        format
        isDerived
        variableOptionsV3
        dataTableVariable {
          id
        }
        organization {
          id
          features {
            isEnabledDerivedBillingValues: isEnabled(feature: "DERIVED_BILLING_VALUES")
            isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
          }
        }
      }
    }
  `,
);

const newFromJobTypeVariable = withFragment(
  (jobTypeVariable: any, {variableSectionId = undefined} = {}): ValueForm => ({
    valueId: undefined,
    projectId: undefined,
    jobId: undefined,
    variableId: jobTypeVariable.variableId,
    variableIdentifier: jobTypeVariable.variable.identifier,
    variableFormat: jobTypeVariable.variable.format,
    dataTableVariableId: _.get(jobTypeVariable, 'variable.dataTableVariable.id'),
    jobTypeVariableId: jobTypeVariable.id,
    key: '',
    amount: jobTypeVariable.defaultAmount,
    isVisibleForCreateProject: jobTypeVariable.isVisibleForCreateProject,
    isRequiredForCreateProject: jobTypeVariable.isRequiredForCreateProject,
    isEnabled: jobTypeVariable.isEnabled,
    stringValue: jobTypeVariable.defaultStringValue,

    // Private
    name: jobTypeVariable.variable.name,
    description: jobTypeVariable.variable.description,
    format: jobTypeVariable.variable.format,
    isDerived: jobTypeVariable.variable.isDerived,
    isZipcodeCityVariable: false,
    variableOptions: JSON.parse(jobTypeVariable.variable.variableOptionsV3),
    variableSectionId,
    isDisabled: jobTypeVariable.variable.isDerived,

    // TODO(Kevin): Remove this FF when all rolled out
    isEnabledDerivedBillingValues:
      jobTypeVariable.variable.organization.features.isEnabledDerivedBillingValues,
    isEnabledTbdBillItems: jobTypeVariable.variable.organization.features.isEnabledTbdBillItems,
  }),
  gql`
    fragment ValueForm_newFromJobTypeVariable on JobTypeVariable {
      id
      isVisibleForCreateProject
      isRequiredForCreateProject
      isEnabled
      defaultAmount
      defaultStringValue
      variable {
        id
        identifier
        name
        description
        format
        isDerived
        variableOptionsV3
        dataTableVariable {
          id
        }
        organization {
          id
          features {
            isEnabledDerivedBillingValues: isEnabled(feature: "DERIVED_BILLING_VALUES")
            isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
          }
        }
      }
    }
  `,
);

const edit = withFragment(
  (value: any, {variableSectionId = undefined} = {}): ValueForm => ({
    valueId: value.id,
    projectId: value.projectId,
    jobId: value.jobId,
    variableId: value.variableId,
    variableIdentifier: value.variable.identifier,
    projectTypeVariableId: value.projectTypeVariableId,
    variableFormat: value.variable.format,
    dataTableVariableId: _.get(value, 'variable.dataTableVariable.id'),
    jobTypeVariableId: value.jobTypeVariableId,
    key: value.key,
    amount: value.amount,
    isVisibleForCreateProject: value.isVisibleForCreateProject,
    isRequiredForCreateProject: value.isRequiredForCreateProject,
    isEnabled: value.isEnabled,
    stringValue: value.stringValue,

    // Private
    name: value.variable.name,
    description: value.variable.description,
    format: value.variable.format,
    isDerived: value.variable.isDerived,
    isZipcodeCityVariable: value.isZipcodeCityVariable,
    variableOptions: JSON.parse(value.variable.variableOptionsV3),
    variableSectionId,
    isDisabled: value.variable.organization.features.isEnabledDerivedBillingValues
      ? value.variable.isDerived || value.isZipcodeCityVariable
      : value.variable.isDerived,

    // TODO(Kevin): Remove this FF when all rolled out
    isEnabledDerivedBillingValues:
      value.variable.organization.features.isEnabledDerivedBillingValues,
    isEnabledTbdBillItems: value.variable.organization.features.isEnabledTbdBillItems,
  }),
  gql`
    fragment ValueForm_edit on Value {
      id
      projectId
      jobId
      variableId
      key
      amount
      isVisibleForCreateProject
      isRequiredForCreateProject
      isEnabled
      isZipcodeCityVariable
      stringValue
      variable {
        id
        identifier
        name
        description
        format
        isDerived
        variableOptionsV3
        dataTableVariable {
          id
        }
        organization {
          id
          features {
            isEnabledDerivedBillingValues: isEnabled(feature: "DERIVED_BILLING_VALUES")
            isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
          }
        }
      }
    }
  `,
);

const getAmountToForm = (valueForm: ValueForm): string => {
  const {format, amount, isEnabledTbdBillItems} = valueForm;

  switch (format) {
    case VariableFormat.CITY_VALUE_CURRENCY:
    case VariableFormat.VALUE_TABLE_CURRENCY:
    case VariableFormat.DROPDOWN_CURRENCY:
    case VariableFormat.CURRENCY:
      return Currency.toForm(amount);
    case VariableFormat.DROPDOWN_PERCENT:
    case VariableFormat.PERCENT:
      return Percent.toForm(amount);
    default:
      return Float.toForm(amount, isEnabledTbdBillItems);
  }
};

const getAmountToMutation = (valueForm: ValueFormAsForm): number | null => {
  const {format, amount, isEnabledTbdBillItems} = valueForm;

  switch (format) {
    case VariableFormat.CITY_VALUE_CURRENCY:
    case VariableFormat.VALUE_TABLE_CURRENCY:
    case VariableFormat.DROPDOWN_CURRENCY:
    case VariableFormat.CURRENCY:
      return Currency.toMutation(amount, isEnabledTbdBillItems);
    case VariableFormat.DROPDOWN_PERCENT:
    case VariableFormat.PERCENT:
      return Percent.toMutation(amount, isEnabledTbdBillItems);
    default:
      return Float.toMutation(amount, isEnabledTbdBillItems);
  }
};

const getStringValueToForm = (valueForm: ValueForm): any => {
  const {format, stringValue} = valueForm;

  switch (format) {
    case VariableFormat.DATE:
      return Datetime.toFormDate(stringValue);
    case VariableFormat.TIME:
      return Datetime.toFormTime(stringValue);
    default:
      return stringValue;
  }
};

const getStringValueToMutation = (valueForm: ValueFormAsForm): string => {
  const {format, stringValue} = valueForm;

  switch (format) {
    case VariableFormat.DATE:
      return stringValue ? Datetime.toMutationDate(stringValue) : '';
    case VariableFormat.TIME:
      return stringValue ? Datetime.toMutationTime(stringValue) : '';
    default:
      return stringValue;
  }
};

const toForm = (valueForm: ValueForm): ValueFormAsForm => ({
  valueId: valueForm.valueId,
  projectId: valueForm.projectId,
  jobId: valueForm.jobId,
  variableId: valueForm.variableId,
  variableIdentifier: valueForm.variableIdentifier,
  variableFormat: valueForm.variableFormat,
  dataTableVariableId: valueForm.dataTableVariableId,
  projectTypeVariableId: valueForm.projectTypeVariableId,
  jobTypeVariableId: valueForm.jobTypeVariableId,
  isVisibleForCreateProject: valueForm.isVisibleForCreateProject,
  isRequiredForCreateProject: valueForm.isRequiredForCreateProject,
  isEnabled: valueForm.isEnabled,
  key: valueForm.key,
  amount: getAmountToForm(valueForm),
  stringValue: getStringValueToForm(valueForm),

  // Private
  name: valueForm.name,
  description: valueForm.description,
  format: valueForm.format,
  isDerived: valueForm.isDerived,
  isZipcodeCityVariable: valueForm.isZipcodeCityVariable,
  variableOptions: valueForm.variableOptions,
  variableSectionId: valueForm.variableSectionId,
  isDisabled: valueForm.isDisabled,

  // TODO(Kevin): Remove this FF when all rolled out
  isEnabledDerivedBillingValues: valueForm.isEnabledDerivedBillingValues,
  isEnabledTbdBillItems: valueForm.isEnabledTbdBillItems,
});

const toMutation = (valueForm: ValueFormAsForm): ValueFormAsMutation => ({
  valueId: valueForm.valueId,
  projectId: valueForm.projectId,
  jobId: valueForm.jobId,
  variableId: valueForm.variableId,
  variableIdentifier: valueForm.variableIdentifier,
  variableFormat: valueForm.variableFormat,
  dataTableVariableId: valueForm.dataTableVariableId,
  projectTypeVariableId: valueForm.projectTypeVariableId,
  jobTypeVariableId: valueForm.jobTypeVariableId,
  isVisibleForCreateProject: valueForm.isVisibleForCreateProject,
  isRequiredForCreateProject: valueForm.isRequiredForCreateProject,
  key: valueForm.key,
  isEnabled: valueForm.isEnabled,
  amount: getAmountToMutation(valueForm),
  stringValue: getStringValueToMutation(valueForm),
});

const ValueForm = {
  // Initialize
  newFromVariable,
  newFromProjectTypeVariable,
  newFromJobTypeVariable,
  edit,

  // Serialize
  toForm,
  toMutation,
};

export default ValueForm;
