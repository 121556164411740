// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import EvaluateAndReplaceVariablesForm from '@shared/modules/Variable/forms/EvaluateAndReplaceVariablesForm';

const useEvaluateAndReplaceVariablesMutation = ({
  evaluateAndReplaceVariablesForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      evaluateAndReplaceVariablesForm: EvaluateAndReplaceVariablesForm.toForm(
        evaluateAndReplaceVariablesForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useEvaluateAndReplaceVariablesMutation.mutation,
    variables: {
      evaluateAndReplaceVariablesForm: EvaluateAndReplaceVariablesForm.toMutation(
        form.values.evaluateAndReplaceVariablesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useEvaluateAndReplaceVariablesMutation.mutation = gql`
  mutation useEvaluateAndReplaceVariablesMutation($evaluateAndReplaceVariablesForm: EvaluateAndReplaceVariablesForm!){
    response: evaluateAndReplaceVariables(evaluateAndReplaceVariablesForm: $evaluateAndReplaceVariablesForm) {
      ${gql.errors}
      text
    }
  }
`;

export default useEvaluateAndReplaceVariablesMutation;
