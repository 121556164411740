// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CollectionMetrics from '@shared/modules/Inventory/Summary/components/CollectionMetrics';
import SaveCollectionChangesModal from 'modules/Job/Inventory/components/SaveCollectionChangesModal';

const Container = Styled.View`
  height: 70px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const Content = Styled.View`
  flex-direction: row;
`;

const SidebarArea = Styled.View`
  width: 200px;
`;

const BackButton = Styled.Touchable`
`;

const TitleArea = Styled.View`
  flex: 1;
`;

const Title = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const InventoryHeader = ({
  inventory,
  title,
  selectedRoomName,
  hasChanges,
  handleSubmit,
  submitting,
}) => {
  const {navigator} = useNavigationDOM();
  const saveCollectionChangesModal = useModal({name: 'Save Collection Changes Modal'});

  return (
    <Container>
      <Space height={24} />
      <Content>
        <SidebarArea>
          <Space height={8} />
          <BackButton
            onPress={() => {
              if (hasChanges) {
                return saveCollectionChangesModal.handleOpen();
              }
              navigator.goBack();
            }}
          >
            <Icon color={colors.gray.primary} size={16} source={Icon.ArrowLeft} />
          </BackButton>
        </SidebarArea>
        <TitleArea>
          <Space height={4} />
          <Title numberOfLines={1}>{title}</Title>
        </TitleArea>
        <CollectionMetrics collection={inventory.collection} />
      </Content>
      <SaveCollectionChangesModal
        isOpen={saveCollectionChangesModal.isOpen}
        handleClose={saveCollectionChangesModal.handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
        onSuccess={() => {
          navigator.goBack();
        }}
        roomName={selectedRoomName}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryHeader.fragment = gql`
  ${CollectionMetrics.fragment}

  fragment InventoryHeader on Inventory {
    id
    collection {
      id
      ...CollectionMetrics
    }
    project {
      id
      name
    }
  }
`;

export default InventoryHeader;
