// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Storage} from '@supermove/sdk';

const UpdateSuperAdminOrganizationMutation = ({onSuccess, onError, children}) => (
  <Mutation
    mutation={UpdateSuperAdminOrganizationMutation.mutation}
    onCompleted={async ({response: {viewer, token, errors}}) => {
      if (viewer) {
        await Storage.setItem('token', token);
        onSuccess(viewer);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateSuperAdminOrganizationMutation.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

UpdateSuperAdminOrganizationMutation.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateSuperAdminOrganizationMutation.mutation = gql`
  mutation UpdateSuperAdminOrganizationMutation(
    $organizationId: Int!,
  ) {
    response: updateSuperAdminOrganization(
      organizationId: $organizationId,
    ) {
      ${gql.errors}
      token
      viewer {
        id
        role
      }
    }
  }
`;

export default UpdateSuperAdminOrganizationMutation;
