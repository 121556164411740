// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useHover} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import GenerateDocumentsForJobModal from 'modules/Job/V2/Move/components/GenerateDocumentsForJobModal';

const ActionContainer = Styled.ButtonV2`
  height: 28px;
  justify-content: center;
  background-color: ${({vars}) => (vars.isHighlighted ? colors.hover : colors.white)};
  padding-horizontal: 12px;
  padding-vertical: 4px;
`;

const ActionText = Styled.Text`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 27px;
  min-width: 34px;
  padding-horizontal: 10px;
  background-color: ${colors.gray.background};
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({isSelected}) => (isSelected ? colors.gray.primary : colors.gray.border)};
`;

const OptionsContainer = Styled.View`
  background-color: ${colors.white};
  width: 200px;
  border-radius: 6px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
`;

const Option = ({label, handlePress, index}) => {
  const {ref, isHovered} = useHover();

  return (
    <React.Fragment>
      {index > 0 && <Space height={4} />}
      <ActionContainer ref={ref} vars={{isHighlighted: isHovered}} onPress={handlePress}>
        <ActionText>{label}</ActionText>
      </ActionContainer>
    </React.Fragment>
  );
};

const DocumentOptionsPopover = ({job, popover, refetch}) => {
  const generateDocumentsModal = useModal({
    name: 'GenerateDocumentsForJobModal',
    enableTracking: true,
  });
  const options = [{label: 'Generate Crew Documents', onPress: generateDocumentsModal.handleOpen}];

  return (
    <React.Fragment>
      <Popover
        placement={Popover.Positions.BottomStart}
        key={popover.isOpen}
        isOpen={popover.isOpen}
        handleOpen={popover.handleOpen}
        handleClose={popover.handleClose}
        reference={popover.ref}
        offset={[0, 4]}
      >
        <OptionsContainer>
          <Space height={10} />
          {options.map((option, index) => {
            return (
              <Option
                key={option.label}
                index={index}
                label={option.label}
                handlePress={() => {
                  option.onPress();
                  popover.handleClose();
                }}
              />
            );
          })}
          <Space height={10} />
        </OptionsContainer>
      </Popover>
      <GenerateDocumentsForJobModal
        key={generateDocumentsModal.key}
        isOpen={generateDocumentsModal.isOpen}
        handleClose={generateDocumentsModal.handleClose}
        jobId={job.id}
        project={job.project}
        onSuccess={() => {
          generateDocumentsModal.handleClose();
          refetch();
        }}
      />
    </React.Fragment>
  );
};

const DocumentOptionsButton = ({popover, job, refetch}) => {
  return (
    <React.Fragment>
      <Popover.Content innerRef={popover.ref}>
        <Button onPress={popover.handleToggle} isSelected={popover.isOpen}>
          <Icon source={Icon.EllipsisV} size={14} color={colors.gray.primary} />
        </Button>
      </Popover.Content>
      <DocumentOptionsPopover job={job} popover={popover} refetch={refetch} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentOptionsButton.fragment = gql`
  ${GenerateDocumentsForJobModal.fragment}
  fragment DocumentOptionsButton on Job {
    id
    project {
      id
      ...GenerateDocumentsForJobModal
    }
  }
`;
export default DocumentOptionsButton;
