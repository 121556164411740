// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ManuallyTriggerWorkflowForm from '@shared/modules/Workflow/forms/ManuallyTriggerWorkflowForm';

const useManuallyTriggerWorkflowMutation = ({
  manuallyTriggerWorkflowForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      manuallyTriggerWorkflowForm: ManuallyTriggerWorkflowForm.toForm(manuallyTriggerWorkflowForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useManuallyTriggerWorkflowMutation.mutation,
    variables: {
      manuallyTriggerWorkflowForm: ManuallyTriggerWorkflowForm.toMutation(
        form.values.manuallyTriggerWorkflowForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// GraphQL Mutation
useManuallyTriggerWorkflowMutation.mutation = gql`
  mutation manuallyTriggerWorkflowMutation(
    $manuallyTriggerWorkflowForm: ManuallyTriggerWorkflowForm!
  ) {
    response: manuallyTriggerWorkflow(manuallyTriggerWorkflowForm: $manuallyTriggerWorkflowForm) {
      workflowRun {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export default useManuallyTriggerWorkflowMutation;
