// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Text = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const BoldText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const IconContainer = Styled.View`
  width: 12px;
  align-items: center;
`;

const IconTooltip = ({children, text}) => {
  return (
    <TextTooltip text={text}>
      <IconContainer>{children}</IconContainer>
    </TextTooltip>
  );
};

const JobTruckInformation = ({job}) => {
  return (
    <React.Fragment>
      <Row style={{flexShrink: 1}}>
        <IconTooltip text={'Trucks'}>
          <Icon color={colors.gray.primary} size={11} source={Icon.Truck} />
        </IconTooltip>
        <Space width={6} />
        <BoldText>{Job.getDispatchJobTrucksCount(job)}</BoldText>
        <React.Fragment>
          {job.jobTrucks &&
            job.jobTrucks.map((jobTruck) => (
              <React.Fragment key={jobTruck.id}>
                <Space width={4} />
                <Text>{jobTruck.truck.name}</Text>
              </React.Fragment>
            ))}
        </React.Fragment>
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTruckInformation.fragment = gql`
  ${Job.getDispatchJobTrucksCount.fragment}

  fragment JobTruckInformation on Job {
    id
    numberOfTrucks
    jobTrucks {
      id
      truck {
        id
        name
      }
    }
    ...Job_getDispatchJobTrucksCount
  }
`;

export default JobTruckInformation;
