// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import ProjectTypeVariableForm from '@shared/modules/Project/forms/ProjectTypeVariableForm';

const _new = (projectTypeId: any) => ({
  projectTypeVariableSectionId: null,
  projectTypeId,
  name: '',
  isDefault: false,
  projectTypeVariableForms: [],
});

const edit = withFragment(
  (projectTypeVariableSection: any) => {
    const {isEnabledTbdBillItems} = projectTypeVariableSection.organization.features;
    return {
      projectTypeVariableSectionId: projectTypeVariableSection.id,
      projectTypeId: projectTypeVariableSection.projectTypeId,
      name: projectTypeVariableSection.name,
      isDefault: projectTypeVariableSection.isDefault,
      projectTypeVariableForms: projectTypeVariableSection.orderedVariables
        .filter((variable: any) => {
          // filter out dynamic variables not editable on FE, like PER_POSITION...
          return !_.has(VariableFormat.DYNAMIC_VARIABLE_FORMATS, variable.format);
        })
        .map((variable: any) => {
          const projectTypeVariable = _.find(
            projectTypeVariableSection.projectType.projectTypeVariables,
            {
              variableId: _.toNumber(variable.id),
            },
          );
          const variableOptions = JSON.parse(variable.variableOptionsV3);
          if (projectTypeVariable) {
            return ProjectTypeVariableForm.edit(
              {
                ...projectTypeVariable,
              },
              {
                name: variable.name,
                format: variable.format,
                defaultAmountOptions: variableOptions,
                isDerived: variable.isDerived,
                description: variable.description,
                isEnabledTbdBillItems,
              },
            );
          } else {
            return ProjectTypeVariableForm.edit(
              {
                defaultAmount: isEnabledTbdBillItems ? null : 0,
                isRequiredForCreateProject: false,
                isVisibleForCreateProject: !!variable.isVisibleForCreateProject,
                projectTypeId: projectTypeVariableSection.projectTypeId,
                variableId: _.toNumber(variable.id),
                isEnabled: true,
              },
              {
                name: variable.name,
                format: variable.format,
                defaultAmountOptions: variableOptions,
                isDerived: variable.isDerived,
                description: variable.description,
                isEnabledTbdBillItems,
              },
            );
          }
        }),

      // Private
      isCollapsed: false,
    };
  },
  gql`
    ${ProjectTypeVariableForm.edit.fragment}
    fragment ProjectTypeVariableSectionForm_edit on ProjectTypeVariableSection {
      id
      projectTypeId
      name
      isDefault
      orderedVariables {
        id
        name
        isVisibleForCreateProject
        format
        variableOptionsV3
        isDerived
        description
      }
      organization {
        id
        features {
          isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
        }
      }
      projectType {
        id
        projectTypeVariables {
          id
          ...ProjectTypeVariableForm_edit
        }
      }
    }
  `,
);

const toForm = ({
  projectTypeVariableSectionId,
  projectTypeId,
  name,
  isDefault,
  projectTypeVariableForms,

  // Private
  isCollapsed,
}: any) => ({
  projectTypeVariableSectionId,
  projectTypeId,
  name,
  isDefault,
  projectTypeVariableForms: projectTypeVariableForms.map((projectTypeVariableForm: any) =>
    ProjectTypeVariableForm.toForm(projectTypeVariableForm),
  ),

  // Private
  isCollapsed,
});

const toMutation = ({
  projectTypeVariableSectionId,
  projectTypeId,
  name,
  isDefault,
  projectTypeVariableForms,
}: any) => ({
  projectTypeVariableSectionId,
  projectTypeId,
  name,
  isDefault,
  // We only pass the dirty/modified forms to the backend
  // Only these Project Type Variables will be upsertted
  projectTypeVariableForms: projectTypeVariableForms
    .filter((projectTypeVariableForm: any) => _.get(projectTypeVariableForm, 'isDirty'))
    .map((projectTypeVariableForm: any) =>
      ProjectTypeVariableForm.toMutation(projectTypeVariableForm),
    ),
  // We pass all orderedVariableIds based on the unfiltered list of projectTypeVariableForms
  orderedVariableIds: getVariableIdsFromProjectTypeVariableForms({projectTypeVariableForms}),
});

const removeVariableFormsById = ({projectTypeVariableSectionForm, variableIdsToRemove}: any) => {
  const projectTypeVariableForms = _.get(
    projectTypeVariableSectionForm,
    'projectTypeVariableForms',
    [],
  );
  return _.remove(projectTypeVariableForms, (projectTypeVariableForm) =>
    _.includes(variableIdsToRemove, _.get(projectTypeVariableForm, 'variableId')),
  );
};

const getVariableIdsFromProjectTypeVariableForms = ({projectTypeVariableForms}: any) => {
  return _.map(projectTypeVariableForms, (projectTypeVariableForm) =>
    _.get(projectTypeVariableForm, 'variableId'),
  );
};

const getVariableIdsInSection = ({projectTypeVariableSectionForm}: any) => {
  const projectTypeVariableForms = _.get(
    projectTypeVariableSectionForm,
    'projectTypeVariableForms',
    [],
  );
  return getVariableIdsFromProjectTypeVariableForms({projectTypeVariableForms});
};

const ProjectTypeVariableSectionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,

  removeVariableFormsById,
  getVariableIdsInSection,
};

export default ProjectTypeVariableSectionForm;
