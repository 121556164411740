const CUSTOM = 'CUSTOM';
const NO_SURVEY = 'NO_SURVEY';
const VIRTUAL_WALKTHROUGH = 'VIRTUAL_WALKTHROUGH';

const SurveyMethodKind = {
  CUSTOM,
  NO_SURVEY,
  VIRTUAL_WALKTHROUGH,
};

export default SurveyMethodKind;
