// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import EditPanel from '@shared/design/components/Panel/EditPanel';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ViewCell = ({label, placeholder, value}) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const BusinessLicensePanelEdit = ({form}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldInput
            {...form}
            label={'Business License'}
            name={'organizationBusinessInfoLicenseForm.businessLicense'}
            input={{
              placeholder: 'Enter business license',
            }}
          />
        </Column>
        <Space width={24} />
        <Column />
      </Row>
    </React.Fragment>
  );
};

const BusinessLicensePanel = ({organization}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell
            label={'Business License'}
            placeholder={'Add business license'}
            value={organization.businessLicense}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

BusinessLicensePanel.Edit = BusinessLicensePanelEdit;

export default BusinessLicensePanel;
