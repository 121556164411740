// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {Organization} from '@supermove/models';

// App
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';

const useUpdateProjectMutation = ({project, projectForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      projectForm: ProjectForm.toForm(projectForm),

      // Private attributes
      jobFormCustomValues: Organization.getJobFormCustomValues(project.organization),
      jobFormAdditionalItems: Organization.getJobFormAdditionalItems(project.organization),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectMutation.mutation,
    variables: {
      projectForm: ProjectForm.toMutation(form.values.projectForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectMutation.fragment = gql`
  ${Organization.getJobFormAdditionalItems.fragment}
  ${Organization.getJobFormCustomValues.fragment}
  fragment useUpdateProjectMutation on Project {
    id
    organization {
      id
      ...Organization_getJobFormAdditionalItems
      ...Organization_getJobFormCustomValues
    }
  }
`;

// Crews are added to the response in order to update the dispatch block
// on the job page which runs a separate query from the rest of the job
// page to fetch its own data. If dates get removed from a job, all trucks
// and jobUsers will also get removed from the job. The dispatch block
// should reflect these updates.
useUpdateProjectMutation.mutation = gql`
  mutation useUpdateProjectMutation($projectForm: ProjectForm!) {
    response: updateProject(projectForm: $projectForm) {
      ${gql.errors}
      project {
        id
        uuid
        activeJobs {
          id
          uuid
          crews {
            id
            crewSlotsForStartDateV2 {
              id
            }
            jobUsers {
              id
            }
          }
        }
      }
    }
  }
`;

export default useUpdateProjectMutation;
