// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import SyncAllCommercialCatalogBillsForm from '@shared/modules/CommercialCatalog/forms/SyncAllCommercialCatalogBillsForm';
import useSyncAllCommercialCatalogBillsMutation from '@shared/modules/CommercialCatalog/hooks/useSyncAllCommercialCatalogBillsMutation';

const ConfirmSyncCommercialCatalogBillModal = ({isOpen, onClose, commercialCatalog, refetch}) => {
  const syncAllCommercialCatalogBillsForm = SyncAllCommercialCatalogBillsForm.edit(
    commercialCatalog.project,
  );
  const {submitting, handleSubmit} = useSyncAllCommercialCatalogBillsMutation({
    syncAllCommercialCatalogBillsForm,
    onSuccess: () => {
      onClose();
      refetch();
    },
    onError: () => console.log('syncAllCommercialCatalogBillsMutation error'),
  });

  return (
    <ActionModal
      color={colors.orange.status}
      icon={Icon.ExclamationTriangle}
      title={'Regenerate bill?'}
      message={
        `This will regenerate the equipment and materials bill ` +
        `and remove any changes you made to it. This action ` +
        `cannot be undone.`
      }
      isOpen={isOpen}
      handlePressOutside={onClose}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={onClose}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ConfirmSyncCommercialCatalogBillModal.propTypes = {};

ConfirmSyncCommercialCatalogBillModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmSyncCommercialCatalogBillModal.fragment = gql`
  ${SyncAllCommercialCatalogBillsForm.edit.fragment}

  fragment ConfirmSyncCommercialCatalogBillModal on CommercialCatalog {
    id
    project {
      id
      ...SyncAllCommercialCatalogBillsForm_edit
    }
  }
`;

export default ConfirmSyncCommercialCatalogBillModal;
