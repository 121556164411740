// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useToast, useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import VariableKind from '@shared/modules/Billing/enums/VariableKind';
import TextMessageTemplateCategory from '@shared/modules/Sms/enums/TextMessageTemplateCategory';
import TextMessageTemplateForm from '@shared/modules/Sms/forms/TextMessageTemplateForm';
import useCreateTextMessageTemplateMutation from '@shared/modules/Sms/hooks/useCreateTextMessageTemplateMutation';
import HardcodedSystemVariables from '@shared/modules/Variable/enums/HardcodedSystemVariables';
import SmsTemplatesField from 'modules/Organization/Settings/Communication/components/SmsTemplatesField';
import VariableList from 'modules/Variable/components/VariableList';

const Container = Styled.View`
  flex: 1;
`;

const PageContainer = Styled.View`
  width: 100%;
  background-color: ${colors.gray.background};
  flex-direction: row;
  height: 100%;
`;

const CreateSmsTemplateHeaderContainer = Styled.View`
  padding: 24px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  height: 84px;
  width: 100%;
`;

const SmsTemplateFieldsContainer = Styled.View`
  width: 500px;
  padding: 24px;
`;

const SmsTemplateVariablesContainer = Styled.View`
  width: 40%;
  padding: 24px;
`;

const BackButton = Styled.ButtonV2`
`;

const HeaderText = Styled.Text`
  ${Typography.Heading2};
`;

const SubheadingText = Styled.Text`
  ${Typography.Subheading};
`;

const CreateSmsTemplatePageHeader = ({
  navigator,
  handleSubmit,
  isSubmitting,
  form,
  textTemplateChangesFoundModal,
}) => {
  return (
    <CreateSmsTemplateHeaderContainer>
      <BackButton
        onPress={() => {
          if (form.dirty) {
            textTemplateChangesFoundModal.handleOpen();
          } else {
            navigator.push(`/settings/communication/sms-templates`);
          }
        }}
      >
        <Icon source={Icon.ChevronLeft} color={colors.black} size={24} />
      </BackButton>
      <Space width={16} />
      <HeaderText>Create Custom SMS Template</HeaderText>
      <Space style={{flex: 1}} />
      <Button text={'Create'} isSubmitting={isSubmitting} width={80} onPress={handleSubmit} />
    </CreateSmsTemplateHeaderContainer>
  );
};

const CreateSmsTemplatesContent = ({organization}) => {
  const {navigator} = useNavigationDOM();

  const createSmsTemplateToast = useToast({
    ToastComponent: SuccessToast,
    message: `Sms template created!`,
    isClosable: true,
  });

  const textMessageTemplateForm = TextMessageTemplateForm.new({organization});
  const {form, handleSubmit} = useCreateTextMessageTemplateMutation({
    textMessageTemplateForm,
    onSuccess: () => {
      createSmsTemplateToast.handleToast();
      navigator.push(`/settings/communication/sms-templates`);
    },
    onError: (errors) => console.log({errors}),
  });

  const field = 'textMessageTemplateForm';

  const textTemplateChangesFoundModal = useModal({name: 'Text Template Changes Found Modal'});

  const isCustomerCategory =
    _.get(form.values, `${field}.category`) === TextMessageTemplateCategory.CUSTOMER;

  return (
    <Container>
      <CreateSmsTemplatePageHeader
        form={form}
        textTemplateChangesFoundModal={textTemplateChangesFoundModal}
        handleSubmit={handleSubmit}
        navigator={navigator}
      />
      <PageContainer>
        <SmsTemplateFieldsContainer>
          <SubheadingText>Template Details</SubheadingText>
          <Space height={16} />
          <SmsTemplatesField form={form} field={field} organization={organization} />
        </SmsTemplateFieldsContainer>
        {isCustomerCategory && (
          <SmsTemplateVariablesContainer>
            <SubheadingText>Variables</SubheadingText>
            <Space height={24} />
            <VariableList
              organization={organization}
              hardcodedSystemVariables={HardcodedSystemVariables.getProjectAndFilteredVariablesByKind(
                HardcodedSystemVariables.EMAIL_VARIABLES,
                [],
              )}
              variableKindsToExclude={[VariableKind.JOB, VariableKind.INVOICE]}
              isCollapsible={false}
              containerStyle={{backgroundColor: colors.white}}
            />
          </SmsTemplateVariablesContainer>
        )}
      </PageContainer>
      <ActionModal
        title={'Exit this page?'}
        message={'Any unsaved changes will be deleted.'}
        icon={Icon.ExclamationTriangle}
        color={colors.red.warning}
        key={textTemplateChangesFoundModal.key}
        isOpen={textTemplateChangesFoundModal.isOpen}
        handlePrimaryAction={() => {
          navigator.push(`/settings/communication/sms-templates`);
        }}
        handleSecondaryAction={textTemplateChangesFoundModal.handleClose}
        primaryActionText={'Exit Page'}
        secondaryActionText={'Cancel'}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateSmsTemplatesContent.fragment = gql`
  ${SmsTemplatesField.fragment}
  ${VariableList.fragment}

  fragment CreateSmsTemplatesContent on Organization {
    id
    ...SmsTemplatesField
    ...VariableList
  }
`;

export default CreateSmsTemplatesContent;
