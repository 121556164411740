// Libraries
import React from 'react';

// Supermove
import {LocationInput, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import EditPanel from '@shared/design/components/Panel/EditPanel';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ViewCell = ({label, placeholder, value}) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const BusinessAddressPanelEdit = ({form}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldInput
            {...form}
            label={'Business Address'}
            name={'organizationBusinessInfoAddressForm.businessAddress'}
            component={LocationInput}
            input={{
              setFieldValue: form.setFieldValue,
              setFieldError: form.setFieldError,
              setFieldTouched: form.setFieldTouched,
              placeholder: 'Enter business address',
              onLocation: ({address}) => {
                form.setFieldValue('organizationBusinessInfoAddressForm.businessAddress', address);
              },
            }}
          />
        </Column>
        <Space width={24} />
        <Column>
          <FieldInput
            {...form}
            label={`Warehouse Address`}
            name={'organizationBusinessInfoAddressForm.warehouseAddress'}
            component={LocationInput}
            input={{
              setFieldValue: form.setFieldValue,
              setFieldError: form.setFieldError,
              setFieldTouched: form.setFieldTouched,
              placeholder: `Enter warehouse address`,
              onLocation: ({address, latitude, longitude}) => {
                form.setFieldValue('organizationBusinessInfoAddressForm.warehouseAddress', address);
                form.setFieldValue(
                  'organizationBusinessInfoAddressForm.warehouseLatitude',
                  latitude,
                );
                form.setFieldValue(
                  'organizationBusinessInfoAddressForm.warehouseLongitude',
                  longitude,
                );
              },
            }}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

const BusinessAddressPanel = ({organization}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell
            label={'Business Address'}
            placeholder={'Add business address'}
            value={organization.businessAddress}
          />
        </Column>
        <Space width={24} />
        <Column>
          <ViewCell
            label={'Warehouse Address'}
            placeholder={'Add warehouse address'}
            value={organization.warehouseAddress}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

BusinessAddressPanel.Edit = BusinessAddressPanelEdit;

export default BusinessAddressPanel;
