// Libraries
import React from 'react';

// Supermove
import {Icon, Loading} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {Form, useToast} from '@supermove/hooks';
import {PaymentMethodModel} from '@supermove/models';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import PaymentMethodForm, {
  PaymentMethodFormToFormType,
} from '@shared/modules/PaymentMethod/forms/PaymentMethodForm';
import useUpdatePaymentMethodMutation from '@shared/modules/PaymentMethod/hooks/useUpdatePaymentMethodMutation';
import PaymentMethodFields from 'modules/Organization/Settings/Company/components/PaymentMethodFields';

const TITLE = 'Edit Payment Method';

const EditPaymentMethodDrawerContent = ({
  isOpen,
  handleClose,
  paymentMethod,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  paymentMethod: PaymentMethodModel;
  refetch: () => void;
}) => {
  const successToast = useToast({ToastComponent: SuccessToast, message: 'Changes saved.'});
  const field = 'paymentMethodForm';
  const paymentMethodForm = PaymentMethodForm.edit(paymentMethod, {});
  const {form, handleSubmit, submitting} = useUpdatePaymentMethodMutation({
    paymentMethodForm,
    onSuccess: () => {
      successToast.handleToast();
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={TITLE}
      primaryActionText={'Save'}
      primaryActionIcon={Icon.Check}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      shouldCloseOnClickOutside={false}
      isUsingLoadingComponent
    >
      <PaymentMethodFields
        form={form as Form<{paymentMethodForm: PaymentMethodFormToFormType}>}
        field={field}
        organization={paymentMethod.organization}
        isUpdate
      />
    </DrawerWithAction>
  );
};

const EditPaymentMethodDrawer = ({
  isOpen,
  handleClose,
  paymentMethodId,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  paymentMethodId: number;
  refetch: () => void;
}) => {
  const {data, loading} = useQuery(EditPaymentMethodDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {paymentMethodId},
    skip: !isOpen,
  });

  return (
    <Loading
      loading={loading}
      as={() => {
        return (
          <DrawerWithAction.LoadingComponent
            isOpen={isOpen}
            headerText={TITLE}
            handleClose={handleClose}
          >
            <PageLoadingIndicator />
          </DrawerWithAction.LoadingComponent>
        );
      }}
    >
      {() =>
        data ? (
          <EditPaymentMethodDrawerContent
            isOpen={isOpen}
            handleClose={handleClose}
            paymentMethod={data?.paymentMethod}
            refetch={refetch}
          />
        ) : null
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditPaymentMethodDrawer.query = gql`
  ${PaymentMethodFields.fragment}
  ${PaymentMethodForm.edit.fragment}
  query EditPaymentMethodDrawer($paymentMethodId: Int!) {
    paymentMethod(paymentMethodId: $paymentMethodId) {
      id
      organization {
        id
        ...PaymentMethodFields
      }
      ...PaymentMethodForm_edit
    }
  }
`;

export default EditPaymentMethodDrawer;
