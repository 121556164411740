// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import BillItemTypeForm from '@shared/modules/Billing/forms/BillItemTypeForm';
import BillItemTypeMoverPositionRateForm from '@shared/modules/Billing/forms/BillItemTypeMoverPositionRateForm';
import TextInputWithDropdown from 'modules/App/components/TextInputWithDropdown';
import AccountingItemField from 'modules/Organization/Settings/BillingLibraries/components/AccountingItemField';

const Row = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex-direction: row;
`;

const Container = Styled.View`
  flex: 1
  flex-direction: row;
`;

const PerPositionRateFieldsContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const DropdownSelectedBadgeContainer = Styled.View`
  padding-vertical: 2px;
  padding-horizontal: 4px;
  background-color: ${colors.alpha(colors.orange.status, 0.1)}
  border-radius: 16px;
  border-color: ${colors.orange.status}
  border-width: 1px;
`;

const DropdownSelectedBadgeText = Styled.Text`
  ${Typography.Label4}
  color: ${colors.orange.status};
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const HintText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const handleBlurCurrency = ({form, field}) => {
  const currency = _.get(form.values, field);
  if (currency) {
    form.setFieldValue(field, Currency.toForm(Currency.convertToCents(currency)));
  }
};

const DropdownSelectedBadge = () => {
  return (
    <DropdownSelectedBadgeContainer>
      <DropdownSelectedBadgeText>Formula</DropdownSelectedBadgeText>
    </DropdownSelectedBadgeContainer>
  );
};

const getMoverPositionName = ({moverPositionId, moverPositions}) => {
  const moverPosition = moverPositions.find(
    (moverPosition) => moverPosition.id === _.toString(moverPositionId),
  );
  return moverPosition ? moverPosition.name : '';
};

const handleUpdateMoverPositions = ({
  form,
  moverPositionIds,
  billItemTypeMoverPositionRateFormsField,
  billItemTypeMoverPositionRateForms,
}) => {
  const filteredMoverPositionRateForms = billItemTypeMoverPositionRateForms.filter(
    (billItemTypeMoverPositionRateForms) =>
      _.includes(moverPositionIds, billItemTypeMoverPositionRateForms.moverPositionId),
  );
  const isMoverPositionRemoved =
    filteredMoverPositionRateForms.length !== billItemTypeMoverPositionRateForms.length;
  if (isMoverPositionRemoved) {
    form.setFieldValue(billItemTypeMoverPositionRateFormsField, filteredMoverPositionRateForms);
  } else {
    moverPositionIds.forEach((moverPositionId) => {
      const moverPositionRateFormExists = billItemTypeMoverPositionRateForms.find(
        (billItemTypeMoverPositionRateForm) =>
          billItemTypeMoverPositionRateForm.moverPositionId === moverPositionId,
      );
      if (!moverPositionRateFormExists) {
        form.setFieldValue(billItemTypeMoverPositionRateFormsField, [
          ...billItemTypeMoverPositionRateForms,
          BillItemTypeMoverPositionRateForm.new({moverPositionId}),
        ]);
      }
    });
  }
};

const EnablePerPositionRatesField = ({
  form,
  isEnabledPerPositionRatesField,
  isShowingPerPositionRateFields,
  field,
  responsive,
}) => {
  return (
    <React.Fragment>
      <SwitchField
        form={form}
        field={isEnabledPerPositionRatesField}
        labelRight={'Enable Position Rates'}
        disabled={!isShowingPerPositionRateFields}
        // If position is enabled/disabled, we should reset all the fields
        onChangeValue={() => {
          BillItemTypeForm.handlePositionRatesToggled({form, field});
        }}
      />
      <Space height={4} />
      <Row index={0}>
        <Space width={32} />
        <HintText responsive={responsive}>
          {`Enabling this feature allows for different rates based on position types. ` +
            `These rates apply when calculating the bill item before the subtotal and when calculating in dollars or hours.`}
        </HintText>
      </Row>
    </React.Fragment>
  );
};

const MoverPositionsDropdown = ({
  form,
  field,
  moverPositions,
  billItemTypeMoverPositionRateFormsField,
  billItemTypeMoverPositionRateForms,
}) => {
  const moverPositionIdsField = `${field}.moverPositionIds`;
  return (
    <FieldInput
      {...form}
      label={'Positions'}
      name={moverPositionIdsField}
      component={MultiDropdownCheckboxInput}
      index={1}
      input={{
        options: moverPositions.map((moverPosition) => ({
          label: moverPosition.name,
          value: _.toNumber(moverPosition.id),
        })),
        placeholder: 'Select position(s)',
        setFieldValue: form.setFieldValue,
        onChangeValue: (moverPositionIds) =>
          handleUpdateMoverPositions({
            form,
            moverPositionIds,
            billItemTypeMoverPositionRateFormsField,
            billItemTypeMoverPositionRateForms,
          }),
      }}
    />
  );
};

const AmountAndFormulaField = ({form, billItemTypeMoverPositionFormField, formulaOptions}) => {
  return (
    <Container>
      <TextInputWithDropdown
        form={form}
        textValueField={`${billItemTypeMoverPositionFormField}.amount`}
        clearTextValue={''}
        dropdownValueField={`${billItemTypeMoverPositionFormField}.amountFormulaId`}
        label={'Value'}
        dropdownOptions={formulaOptions}
        placeholder={'Formula or custom value'}
        noOptionsMessage={'No formula options'}
        onBlur={() =>
          handleBlurCurrency({form, field: `${billItemTypeMoverPositionFormField}.amount`})
        }
        DropdownSelectedBadge={DropdownSelectedBadge}
      />
    </Container>
  );
};

const MoverPositionRateField = ({
  form,
  field,
  index,
  billItemTypeMoverPositionRateForm,
  moverPositions,
  formulaOptions,
  organization,
  responsive,
}) => {
  const billItemTypeMoverPositionFormField = `${field}.billItemTypeMoverPositionRateForms.${index}`;

  return (
    <React.Fragment>
      <Space height={16} />
      <React.Fragment>
        <LabelText responsive={responsive}>
          {getMoverPositionName({
            moverPositionId: billItemTypeMoverPositionRateForm.moverPositionId,
            moverPositions,
          })}
        </LabelText>
        <Row index={2 + index}>
          <AmountAndFormulaField
            form={form}
            billItemTypeMoverPositionFormField={billItemTypeMoverPositionFormField}
            formulaOptions={formulaOptions}
          />
          <Space width={8} />
          <Container>
            <AccountingItemField
              form={form}
              field={billItemTypeMoverPositionFormField}
              fieldIndex={3 + index}
              organization={organization}
            />
          </Container>
        </Row>
      </React.Fragment>
    </React.Fragment>
  );
};

const PerPositionRateFields = ({
  form,
  field,
  fieldIndex,
  moverPositions,
  formulaOptions,
  isShowingPerPositionRateFields,
  organization,
}) => {
  const responsive = useResponsive();
  const isEnabledPerPositionRatesField = `${field}.isEnabledPerPositionRates`;
  const isEnabledPerPositionRates = _.get(form.values, isEnabledPerPositionRatesField);
  const billItemTypeMoverPositionRateFormsField = `${field}.billItemTypeMoverPositionRateForms`;
  const billItemTypeMoverPositionRateForms = _.get(
    form.values,
    billItemTypeMoverPositionRateFormsField,
  );

  return (
    <PerPositionRateFieldsContainer index={fieldIndex}>
      <Space height={16} />
      <EnablePerPositionRatesField
        form={form}
        isEnabledPerPositionRatesField={isEnabledPerPositionRatesField}
        isShowingPerPositionRateFields={isShowingPerPositionRateFields}
        field={field}
        responsive={responsive}
      />
      {isShowingPerPositionRateFields && isEnabledPerPositionRates && (
        <React.Fragment>
          <Space height={16} />
          <MoverPositionsDropdown
            form={form}
            field={field}
            moverPositions={moverPositions}
            billItemTypeMoverPositionRateFormsField={billItemTypeMoverPositionRateFormsField}
            billItemTypeMoverPositionRateForms={billItemTypeMoverPositionRateForms}
          />
          {billItemTypeMoverPositionRateForms.map((billItemTypeMoverPositionRateForm, index) => {
            return (
              <MoverPositionRateField
                key={billItemTypeMoverPositionRateForm.moverPositionId}
                form={form}
                field={field}
                index={index}
                billItemTypeMoverPositionRateForm={billItemTypeMoverPositionRateForm}
                moverPositions={moverPositions}
                formulaOptions={formulaOptions}
                organization={organization}
                responsive={responsive}
              />
            );
          })}
        </React.Fragment>
      )}
    </PerPositionRateFieldsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PerPositionRateFields.fragment = gql`
  ${AccountingItemField.fragment}
  fragment PerPositionRateFields on MoverPosition {
    id
    name
  }

  fragment PerPositionRateFields_Organization on Organization {
    id
    ...AccountingItemField
  }
`;

export default PerPositionRateFields;
