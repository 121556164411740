// Libararies
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {Tag} from '@supermove/models';

// App
import TagEmojiButton from 'modules/Tag/components/TagEmojiButton';
import UpsertJobTagModal from 'modules/Tag/components/UpsertJobTagModal';

const JobTagsButton = ({job, refetch}) => {
  const upsertJobTagModal = useModal({name: 'Upsert Job Tag Modal', enableTracking: true});
  const tags = Tag.getJobAndProjectTagAndTagAssignment(job);
  const tagIds = tags.map((tag) => tag.id);

  const refetchDayViewAndTable = () => {
    refetch({shouldFetchJobsListTable: true});
  };

  return (
    <React.Fragment>
      <TagEmojiButton
        tags={tags}
        numberOfVisibleSelections={2}
        upsertTagModal={upsertJobTagModal}
      />
      <UpsertJobTagModal
        key={`${upsertJobTagModal.key}_${job.id}`}
        tagIds={tagIds}
        job={job}
        refetch={refetchDayViewAndTable}
        isOpen={upsertJobTagModal.isOpen}
        handleClose={upsertJobTagModal.handleClose}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

// HACK(jholston): TagEmojiButton should have it's own fragment but since getJobAndProjectTagAndTagAssignment
// fetches the tags, we rely on it to fetch the required data to render. This should be refactored.
JobTagsButton.fragment = gql`
  ${Tag.getJobAndProjectTagAndTagAssignment.fragment}
  ${UpsertJobTagModal.fragment}

  fragment JobTagsButton on Job {
    id
    ...Tag_getJobAndProjectTagAndTagAssignment
    ...UpsertJobTagModal
  }
`;

export default JobTagsButton;
