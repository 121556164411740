// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import WarehouseForm from '@shared/modules/Storage/forms/WarehouseForm';

const useCreateWarehouseMutation = ({warehouseForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      warehouseForm: WarehouseForm.toForm(warehouseForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateWarehouseMutation.mutation,
    variables: {
      warehouseForm: WarehouseForm.toMutation(form.values.warehouseForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateWarehouseMutation.mutation = gql`
  mutation CreateWarehouseMutation($warehouseForm: WarehouseForm!) {
    response: createWarehouse(warehouseForm: $warehouseForm) {
      ${gql.errors}
      warehouse {
        id
      }
    }
  }
`;

export default useCreateWarehouseMutation;
