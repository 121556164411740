/*
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// App
import {Modal, Space, Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 16px;
  padding-horizontal: 16px;
`;

const MainText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const SubText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => props.color}
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const DocumentNotPreparedErrorModal = ({isOpen, handleClose, count}) => {
  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <Container pointerEvents={'box-none'}>
        <Wrapper width={392}>
          <Space height={24} />
          <MainText>Please fill out the entire document</MainText>
          <Space height={8} />
          <SubText>
            {`Currently, the document has ${pluralize('incomplete section', count, true)}. ` +
              `Make sure to save each section after filling it out.`}
          </SubText>
          <Space height={24} />
          <Actions>
            <Button color={colors.blue.interactive} onPress={handleClose}>
              <ButtonText color={colors.white}>Got It</ButtonText>
            </Button>
            <Space width={8} />
          </Actions>
          <Space height={24} />
        </Wrapper>
      </Container>
    </Modal.Content>
  );
};

export default DocumentNotPreparedErrorModal;
