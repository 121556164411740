// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import CreateAttachmentForm, {
  CreateAttachmentFormType,
} from '@shared/modules/File/forms/CreateAttachmentForm';

// App

interface CreateAttachmentFormTypeWrapper {
  createAttachmentForm: CreateAttachmentFormType;
}

const useCreateAttachmentForm = ({
  createAttachmentForm,
  onSuccess,
  onError,
}: {
  createAttachmentForm: CreateAttachmentFormType;
  onSuccess: () => void;
  onError: (e: any) => void;
}) => {
  const form = useForm<CreateAttachmentFormTypeWrapper>({
    initialValues: {
      createAttachmentForm: CreateAttachmentForm.toForm(createAttachmentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation<CreateAttachmentFormTypeWrapper>({
    form,
    mutation: useCreateAttachmentForm.mutation,
    variables: {
      createAttachmentForm: CreateAttachmentForm.toMutation(form.values.createAttachmentForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateAttachmentForm.mutation = gql`
  mutation useCreateAttachmentForm($createAttachmentForm: CreateAttachmentForm!) {
    response: createAttachment(createAttachmentForm: $createAttachmentForm) {
      ${gql.errors}
      attachment {
        id
      }
    }
  }
`;

export default useCreateAttachmentForm;
