// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Typography} from '@supermove/styles';

// Components
import FieldInput from '@shared/design/components/Field/FieldInput';

const FieldsContainer = Styled.View`
  z-index: 100;
`;

const DimensionsContainer = Styled.View`
  flex-direction: row;
`;

const DimensionFieldContainer = Styled.View`
  flex-direction: column;
  flex: 1;
`;

const Label = Styled.Text`
  ${Typography.Body3}
`;

const CreateContainerTypeFields = ({form}) => {
  return (
    <FieldsContainer>
      <FieldInput
        {...form}
        index={0}
        name={'containerTypeForm.name'}
        label={'Container Type Name'}
        input={{
          required: !_.get(form.values, 'containerTypeForm.name'),
          autoFocus: true,
          placeholder: 'Ex: Vault, lift van, overflow',
        }}
      />
      <Space height={12} />
      <Label>Dimensions</Label>
      <Space height={4} />
      <DimensionsContainer>
        <DimensionFieldContainer>
          <FieldInput
            {...form}
            index={1}
            name={'containerTypeForm.dimensionLength'}
            input={{
              placeholder: '0 ft',
              keyboardType: 'numeric',
            }}
            caption={'Length'}
          />
        </DimensionFieldContainer>
        <Space width={8} />
        <DimensionFieldContainer>
          <FieldInput
            {...form}
            index={2}
            name={'containerTypeForm.dimensionWidth'}
            input={{
              placeholder: '0 ft',
              keyboardType: 'numeric',
            }}
            caption={'Width'}
          />
        </DimensionFieldContainer>
        <Space width={8} />
        <DimensionFieldContainer>
          <FieldInput
            {...form}
            index={3}
            name={'containerTypeForm.dimensionHeight'}
            input={{
              placeholder: '0 ft',
              keyboardType: 'numeric',
            }}
            caption={'Height'}
          />
        </DimensionFieldContainer>
      </DimensionsContainer>
      <Space height={12} />
      <FieldInput
        {...form}
        index={4}
        name={'containerTypeForm.weightCapacity'}
        label={'Weight Capacity'}
        input={{
          placeholder: '0 lbs',
          keyboardType: 'numeric',
        }}
      />
      <Space height={12} />
      <FieldInput
        {...form}
        index={5}
        name={'containerTypeForm.notes'}
        label={'Description'}
        input={{
          style: {height: 173, paddingTop: 8},
          multiline: true,
          placeholder: 'Enter description',
        }}
      />
    </FieldsContainer>
  );
};

export default CreateContainerTypeFields;
