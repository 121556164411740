// Supermove
// @ts-expect-error TS(2614): Module '"@shared/modules/Payment/components/PayEng... Remove this comment to see the full error message
import {PayEngine} from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import PayengineCreditCardForm, {
  PayengineCreditCardFormInterface,
} from '@shared/modules/Payment/forms/PayengineCreditCardForm';

// App

export type SavePayengineCreditCardFormType = {
  clientId: number;
  payengineCreditCardForm: PayengineCreditCardFormInterface;
  createCardResponse: PayEngine.CreditCard | null;
};

type New = {
  clientId: number;
  payengineCreditCardForm?: PayengineCreditCardFormInterface;
};

const _new = ({clientId, payengineCreditCardForm}: New): SavePayengineCreditCardFormType => ({
  clientId,
  payengineCreditCardForm: payengineCreditCardForm || PayengineCreditCardForm.new(),
  createCardResponse: null,
});

const toForm = ({
  clientId,
  payengineCreditCardForm,
  createCardResponse,
}: SavePayengineCreditCardFormType): SavePayengineCreditCardFormType => ({
  clientId,
  payengineCreditCardForm: PayengineCreditCardForm.toForm(payengineCreditCardForm),
  createCardResponse,
});

const toMutation = ({
  clientId,
  payengineCreditCardForm,
  createCardResponse,
}: SavePayengineCreditCardFormType): SavePayengineCreditCardFormType => ({
  clientId,
  payengineCreditCardForm: PayengineCreditCardForm.toMutation(payengineCreditCardForm),
  createCardResponse,
});

const SavePayengineCreditCardForm = {
  new: _new,
  toForm,
  toMutation,
};

export default SavePayengineCreditCardForm;
