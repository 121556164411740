// Supermove
import {colors} from '@supermove/styles';

const EDITING = 'EDITING';
const IN_REVIEW = 'IN_REVIEW';
const SUBMITTED_TO_PE = 'SUBMITTED_TO_PE';
const ACTIVE = 'ACTIVE';
const DECLINED = 'DECLINED';
const SUSPENDED = 'SUSPENDED';
const CANCELLED = 'CANCELLED';

// Return value = {text, background}
const getBadgeColors = (payengineMerchantStatus: any) => {
  switch (payengineMerchantStatus) {
    case EDITING:
      return colors.BADGE.Blue;
    case IN_REVIEW:
    case SUBMITTED_TO_PE:
      return colors.BADGE.Orange;
    case ACTIVE:
      return colors.BADGE.Green;
    case DECLINED:
    case SUSPENDED:
    case CANCELLED:
      return colors.BADGE.Red;
    default:
      console.error(`Found unexpected merchant status: ${payengineMerchantStatus}`);
      return colors.BADGE.Gray;
  }
};

export default {
  // Enum Options
  EDITING,
  IN_REVIEW,
  SUBMITTED_TO_PE,
  ACTIVE,
  DECLINED,
  SUSPENDED,
  CANCELLED,

  // Helpers
  getBadgeColors,
};
