// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';

// App
import TimesheetBlockModal from '@shared/modules/Timesheet/components/TimesheetBlockModal';
import CreateTimesheetBillableBlocksForm from '@shared/modules/Timesheet/forms/CreateTimesheetBillableBlocksForm';
import useCreateTimesheetBillableBlocksMutation from '@shared/modules/Timesheet/hooks/useCreateTimesheetBillableBlocksMutation';

const handleSubmitWithGqlErrors = async ({handleSubmit, setHasError}: any) => {
  setHasError(false);
  try {
    const response = await handleSubmit();
    return response.data.response;
  } catch (error) {
    setHasError(true);
  }
};

const AddTimesheetBillableBlockModal = ({isOpen, handleClose, job, refetch, onUpdate}: any) => {
  const [hasError, setHasError] = useState();
  const createTimesheetBillableBlocksForm = CreateTimesheetBillableBlocksForm.newForJob({job});
  const {form, handleSubmit, submitting} = useCreateTimesheetBillableBlocksMutation({
    createTimesheetBillableBlocksForm,
    onSuccess: () => {
      handleClose();
      refetch();
      onUpdate && onUpdate();
    },
    onError: (error: any) => console.log({error}),
  });
  const field = 'createTimesheetBillableBlocksForm';
  const timesheetBlockField = `${field}.createTimesheetBlockForms.0`;

  return (
    <TimesheetBlockModal
      job={job}
      form={form}
      field={field}
      timesheetBlockField={timesheetBlockField}
      handleSubmit={() => handleSubmitWithGqlErrors({handleSubmit, setHasError})}
      isSubmitting={submitting}
      isOpen={isOpen}
      handleClose={handleClose}
      hasError={hasError}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddTimesheetBillableBlockModal.fragment = gql`
  ${CreateTimesheetBillableBlocksForm.newForJob.fragment}
  ${TimesheetBlockModal.fragment}

  fragment AddTimesheetBillableBlockModal on Job {
    id
    timesheetBillableEntries {
      id
      kind
    }
    ...CreateTimesheetBillableBlocksForm_newForJob
    ...TimesheetBlockModal
  }
`;

export default AddTimesheetBillableBlockModal;
