// Supermove
import {Datetime} from '@supermove/utils';

// App
import ValueForm from '@shared/modules/Billing/forms/ValueForm';

const _new = ({creatorId, projectTypeId, jobStatus, sourceId, organizationId}: any) => ({
  organizationId,
  primaryLaborSourceOrganizationId: organizationId,
  creatorId,
  projectTypeId,
  jobTypeId: null,
  jobStatus,
  date: Datetime.now,
  sourceId,
  startDate: '',
  endDate: '',
  isEstimatedRange: false,
  projectValueForms: [],
  jobValueForms: [],
});

const toForm = ({
  organizationId,
  primaryLaborSourceOrganizationId,
  creatorId,
  projectTypeId,
  jobTypeId,
  jobStatus,
  date,
  sourceId,
  startDate,
  endDate,
  isEstimatedRange,
  projectValueForms,
  jobValueForms,
}: any) => ({
  organizationId,
  primaryLaborSourceOrganizationId,
  creatorId,
  projectTypeId,
  jobTypeId,
  jobStatus,
  date: Datetime.toFormDate(date),
  sourceId,
  startDate: Datetime.toFormDate(startDate),
  endDate: Datetime.toFormDate(endDate),
  isEstimatedRange,
  projectValueForms: projectValueForms.map((valueForm: any) => ValueForm.toForm(valueForm)),
  jobValueForms: jobValueForms.map((valueForm: any) => ValueForm.toForm(valueForm)),
});

const toMutation = ({
  organizationId,
  primaryLaborSourceOrganizationId,
  creatorId,
  projectTypeId,
  jobTypeId,
  jobStatus,
  date,
  sourceId,
  startDate,
  endDate,
  isEstimatedRange,
  projectValueForms,
  jobValueForms,
}: any) => ({
  organizationId,
  primaryLaborSourceOrganizationId,
  creatorId,
  projectTypeId,
  jobTypeId,
  jobStatus,
  date: Datetime.toMutationDate(date),
  sourceId,
  startDate: startDate ? Datetime.toMutationDate(startDate) : null,
  endDate: endDate ? Datetime.toMutationDate(endDate) : null,
  isDateRange: isEstimatedRange,
  projectValueForms: projectValueForms.map((valueForm: any) => ValueForm.toMutation(valueForm)),
  jobValueForms: jobValueForms.map((valueForm: any) => ValueForm.toMutation(valueForm)),
});

const CreateTrainingProjectForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CreateTrainingProjectForm;
