// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Form} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {TimeRange} from '@supermove/models';

// Components
import UpdateReportMoveTimeRangesMutation from './UpdateReportMoveTimeRangesMutation';

const UpdateReportMoveTimeRangesForm = ({reportMove, onSuccess, onError, children}) => (
  <Form
    initialValues={{
      reportMoveId: reportMove.id,
      timeRangeInputs: reportMove.timeRanges.map((timeRange) => TimeRange.toForm(timeRange)),
    }}
    onSubmit={(values) => {}}
  >
    {({submitForm, setMutationErrors, ...form}) => (
      <UpdateReportMoveTimeRangesMutation
        reportMoveId={reportMove.id}
        timeRangeInputs={form.values.timeRangeInputs.map((timeRangeInput) =>
          TimeRange.toMutation(timeRangeInput),
        )}
        onSuccess={onSuccess}
        setMutationErrors={setMutationErrors}
        submitForm={submitForm}
      >
        {({loading, handleSubmit}) =>
          children({
            ...form,
            loading,
            handleSubmit,
          })
        }
      </UpdateReportMoveTimeRangesMutation>
    )}
  </Form>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
UpdateReportMoveTimeRangesForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

UpdateReportMoveTimeRangesForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateReportMoveTimeRangesForm.fragment = gql`
  fragment UpdateReportMoveTimeRangesForm on ReportMove {
    id
    uuid
    timeRanges {
      name
      kind
      start
      end
    }
  }
`;

export default UpdateReportMoveTimeRangesForm;
