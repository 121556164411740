// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ImportInventoryLibraryItemTagsForm from '@shared/modules/File/forms/ImportInventoryLibraryItemTagsForm';

const useImportInventoryLibraryItemTagsMutation = ({
  importInventoryLibraryItemTagsForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      importInventoryLibraryItemTagsForm: ImportInventoryLibraryItemTagsForm.toForm(
        importInventoryLibraryItemTagsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useImportInventoryLibraryItemTagsMutation.mutation,
    variables: {
      importInventoryLibraryItemTagsForm: ImportInventoryLibraryItemTagsForm.toMutation(
        form.values.importInventoryLibraryItemTagsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useImportInventoryLibraryItemTagsMutation.mutation = gql`
  mutation useImportInventoryLibraryItemTagsMutation(
    $importInventoryLibraryItemTagsForm: ImportInventoryLibraryItemTagsForm!
  ) {
    response: importInventoryLibraryItemTags(
      importInventoryLibraryItemTagsForm: $importInventoryLibraryItemTagsForm
    ) {
      ${gql.errors}
    }
  }
`;

export default useImportInventoryLibraryItemTagsMutation;
