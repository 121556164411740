// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ProjectWidgetKind from '@shared/modules/Project/enums/ProjectWidgetKind';

const ContentContainer = Styled.View`
  padding: 8px;
`;

const WidgetButtonsContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const WidgetButtonContainer = Styled.View`
  justify-content: center;
  align-items: center;
`;

const Label = Styled.Text`
  ${Typography.Mobile.Body}
`;

const ICONS_PER_ROW = 2;
const SPACES_PER_ROW = ICONS_PER_ROW + 1;
const SPACING = 16;
const SPACES_WIDTH = SPACING * SPACES_PER_ROW;

const getWidgetButtonWidth = () => {
  const spaceForButtons = window.innerWidth - SPACES_WIDTH;
  return spaceForButtons / ICONS_PER_ROW;
};

const WidgetButton = ({kind, urlFilters, menuSheet}) => {
  return (
    <TertiaryButton
      onPress={() => {
        urlFilters.handleUpdate({widget: kind});
        menuSheet.handleClose();
      }}
      style={{
        height: 120,
        width: getWidgetButtonWidth(),
        borderWidth: 1,
        borderRadius: 4,
        borderColor: colors.gray.border,
        backgroundColor: colors.white,
        margin: 8,
      }}
    >
      <WidgetButtonContainer>
        <Icon
          source={ProjectWidgetKind.getIcon(kind)}
          color={colors.blue.interactive}
          size={20}
          style={{height: 20, width: 20}}
        />
        <Space height={16} />
        <Label>{ProjectWidgetKind.getDisplay(kind)}</Label>
      </WidgetButtonContainer>
    </TertiaryButton>
  );
};

const ProjectWidgetsMenu = ({project, urlFilters, menuSheet}) => {
  const {organization} = project;

  return (
    <ContentContainer>
      <WidgetButtonsContainer>
        {ProjectWidgetKind.getOrganizationWidgets({organization}).map((kind) => {
          return (
            <WidgetButton key={kind} kind={kind} urlFilters={urlFilters} menuSheet={menuSheet} />
          );
        })}
      </WidgetButtonsContainer>
    </ContentContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectWidgetsMenu.fragment = gql`
  ${ProjectWidgetKind.getOrganizationWidgets.fragment}
  fragment ProjectWidgetsMenu on Project {
    id
    organization {
      id
      ...ProjectWidgetKind_getOrganizationWidgets
    }
  }
`;

export default ProjectWidgetsMenu;
