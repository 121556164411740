// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ClaimTypeForm from '@shared/modules/Claim/forms/ClaimTypeForm';

const useCreateClaimTypeMutation = ({claimTypeForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      claimTypeForm: ClaimTypeForm.toForm(claimTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateClaimTypeMutation.mutation,
    variables: {
      claimTypeForm: ClaimTypeForm.toMutation(form.values.claimTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateClaimTypeMutation.mutation = gql`
  mutation useCreateClaimTypeMutation($claimTypeForm: ClaimTypeForm!) {
    response: createClaimType(claimTypeForm: $claimTypeForm) {
      ${gql.errors}
      claimType {
        id
      }
    }
  }
`;

export default useCreateClaimTypeMutation;
