// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {useState, useMountEffect, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Table from '@shared/design/components/TableV2Deprecated';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useUploadAttachmentForm from '@shared/modules/File/hooks/useUploadAttachmentForm';

const Label = Styled.Text`
  ${Typography.Label}
`;

const RemoveButton = Styled.ButtonV2``;

const LoadingIndicator = Styled.Loading`
`;

const LinkButton = Styled.ButtonV2`
  width: 100%;
`;

const Cell = Styled.View`
  align-items: ${(props) => ((props as any).isCentered ? 'center' : 'flex-start')};
  justify-content: center;
  flex: 1;
`;

const AttachmentUpload = ({attachmentForm, form, field, index, viewerId, responsive}: any) => {
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFailure, setUploadFailure] = useState(false);

  const {
    form: uploadFileForm,
    handleSubmit,
    submitting,
  } = useUploadAttachmentForm({
    uploadFileForm: UploadFileForm.new({
      organizationId: attachmentForm.organizationId,
      creatorId: viewerId,
      projectId: attachmentForm.projectId,
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
      roomId: null,
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
      inventoryId: null,
      attachmentCategoryKinds: attachmentForm.attachmentCategoryKinds,
    }),
    onSuccess: ({attachment}: any) => {
      setUploadSuccess(true);
      setUploadFailure(false);
      form.setFieldValue(`${field}.${index}.attachmentId`, attachment.id);
    },
    onError: (errors: any) => {
      console.log(errors);
      setUploadFailure(true);
      setUploadSuccess(false);
      form.setFieldValue(`${field}.${index}.hasUploadError`, true);
    },
  });

  useMountEffect(() => {
    uploadFileForm.setFieldValue(
      `uploadFileForm.requestUploadFileForm.mimetype`,
      attachmentForm.file.type,
    );
    uploadFileForm.setFieldValue(
      `uploadFileForm.requestUploadFileForm.filename`,
      UploadFileForm.formatName(attachmentForm.file.name),
    );
    uploadFileForm.setFieldValue(`uploadFileForm.file`, attachmentForm.file);
    setTimeout(() => handleSubmit(), 0);
  });

  const size = responsive.desktop ? 14 : 16;

  const getUploadStatusIndicator = () => {
    if (submitting) {
      return <LoadingIndicator size={size} />;
    } else if (uploadSuccess) {
      return <Icon source={Icon.Check} size={size} color={colors.green.status} />;
    } else if (uploadFailure) {
      return <Icon source={Icon.ExclamationTriangle} size={size} color={colors.red.warning} />;
    }
  };

  return <Label>{getUploadStatusIndicator()}</Label>;
};

const UploadStatus = ({attachmentForm, form, field, rowIndex, viewerId}: any) => {
  // If the attachment already exists, no need to reupload
  const responsive = useResponsive();
  if (attachmentForm.attachmentId) {
    return (
      <Icon source={Icon.Check} size={responsive.desktop ? 14 : 16} color={colors.green.status} />
    );
  }

  return (
    <AttachmentUpload
      attachmentForm={attachmentForm}
      form={form}
      field={field}
      index={rowIndex}
      viewerId={viewerId}
      responsive={responsive}
    />
  );
};

const Filename = ({
  isViewMode,
  projectUuid,
  attachmentForm,
  form,
  field,
  rowIndex,
  navigator,
}: any) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {isViewMode ? (
        <LinkButton
          onPress={() =>
            navigator.push(
              `/projects/${projectUuid}/attachments/${attachmentForm.attachmentUuid}?status=view`,
            )
          }
        >
          <Table.CellLink numberOfLines={2} responsive={responsive}>
            {attachmentForm.filename}
          </Table.CellLink>
        </LinkButton>
      ) : (
        <FieldInput
          {...form}
          name={`${field}.${rowIndex}.filename`}
          input={{
            placeholder: 'Enter file name',
          }}
          style={{flex: 1}}
          isRequired
          isResponsive
        />
      )}
    </React.Fragment>
  );
};

const Notes = ({isViewMode, attachmentForm, form, field, rowIndex}: any) => {
  return (
    <React.Fragment>
      {isViewMode ? (
        <Table.CellText>{attachmentForm.description}</Table.CellText>
      ) : (
        <FieldInput
          {...form}
          name={`${field}.${rowIndex}.description`}
          input={{
            placeholder: 'Enter notes',
          }}
          style={{flex: 1}}
          isResponsive
        />
      )}
    </React.Fragment>
  );
};

const VisibleToCrew = ({label, isViewMode, attachmentForm, form, field, rowIndex}: any) => {
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Cell isCentered={!label}>
      {isViewMode ? (
        attachmentForm.isVisibleToCrew && (
          <Icon
            color={colors.green.status}
            size={responsive.desktop ? 16 : 18}
            source={Icon.Check}
          />
        )
      ) : (
        <Checkbox
          isChecked={attachmentForm.isVisibleToCrew}
          handleToggle={(isChecked) => {
            form.setFieldValue(`${field}.${rowIndex}.isVisibleToCrew`, isChecked);
          }}
          label={label}
          isMobile={!responsive.desktop}
        />
      )}
    </Cell>
  );
};

const RemoveAttachment = ({handleRemoveAttachment, rowIndex, form, field}: any) => {
  const responsive = useResponsive();
  const {attachmentId: isUploaded, hasUploadError} = _.get(form.values, `${field}.${rowIndex}`);
  const isDisabled = !isUploaded && !hasUploadError;
  return (
    <RemoveButton
      disabled={isDisabled}
      onPress={() => handleRemoveAttachment({removedAttachmentIndex: rowIndex, hasUploadError})}
    >
      <Icon
        source={Icon.Trash}
        size={responsive.desktop ? 14 : 16}
        color={isDisabled ? colors.gray.disabled : colors.red.warning}
      />
    </RemoveButton>
  );
};

const ProjectAttachmentUploaderTableCells = () => {
  return null;
};

ProjectAttachmentUploaderTableCells.UploadStatus = UploadStatus;
ProjectAttachmentUploaderTableCells.Filename = Filename;
ProjectAttachmentUploaderTableCells.Notes = Notes;
ProjectAttachmentUploaderTableCells.VisibleToCrew = VisibleToCrew;
ProjectAttachmentUploaderTableCells.RemoveAttachment = RemoveAttachment;

export default ProjectAttachmentUploaderTableCells;
