// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import TextMessageSystemKind from '@shared/modules/Sms/enums/TextMessageSystemKind';

const _new = ({organization}: any) => ({
  name: '',
  body: '',
  category: null,
  systemTemplateKind: TextMessageSystemKind.CUSTOM,
  phoneNumberRecipients: [],
  teamIdRecipients: [],
  projectUserRecipients: [],
  isDeleted: false,
  organizationId: organization.id,

  // Private
  phoneNumberSearchInputValue: '',
});

const edit = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ textMessageTemplate }: { text... Remove this comment to see the full error message
  ({textMessageTemplate}) => ({
    textMessageTemplateId: textMessageTemplate.id,
    name: textMessageTemplate.name,
    body: textMessageTemplate.body,
    category: textMessageTemplate.category,
    systemTemplateKind: textMessageTemplate.systemTemplateKind,
    phoneNumberRecipients: textMessageTemplate.phoneNumberRecipients,
    teamIdRecipients: textMessageTemplate.teamIdRecipients,
    projectUserRecipients: textMessageTemplate.projectUserRecipients,
    isDeleted: textMessageTemplate.isDeleted,
    organizationId: textMessageTemplate.organizationId,
  }),
  gql`
    fragment TextMessageTemplateForm_edit on TextMessageTemplate {
      id
      name
      body
      category
      systemTemplateKind
      phoneNumberRecipients
      teamIdRecipients
      projectUserRecipients
      isDeleted
      organizationId
    }
  `,
);

const toForm = ({
  textMessageTemplateId,
  name,
  body,
  category,
  systemTemplateKind,
  phoneNumberRecipients,
  teamIdRecipients,
  projectUserRecipients,
  isDeleted,
  organizationId,

  // Private
  phoneNumberSearchInputValue,
}: any) => ({
  textMessageTemplateId,
  name,
  body,
  category,
  systemTemplateKind,
  phoneNumberRecipients,
  teamIdRecipients,
  projectUserRecipients,
  isDeleted,
  organizationId,

  // Private
  phoneNumberSearchInputValue,
});

const toMutation = ({
  textMessageTemplateId,
  name,
  body,
  category,
  systemTemplateKind,
  phoneNumberRecipients,
  teamIdRecipients,
  projectUserRecipients,
  isDeleted,
  organizationId,
}: any) => ({
  textMessageTemplateId,
  name,
  body,
  category,
  systemTemplateKind,
  phoneNumberRecipients,
  teamIdRecipients,
  projectUserRecipients,
  isDeleted,
  organizationId,
});

const TextMessageTemplateForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default TextMessageTemplateForm;
