// Libraries
import React from 'react';

// Supermove
import {useNavigationDOM} from '@supermove/hooks';

// App
import EmailTemplatesPage from 'modules/Organization/Settings/Communication/components/EmailTemplatesPage';
import OrganizationSettingsCommunicationGeneralPage from 'modules/Organization/Settings/Communication/components/OrganizationSettingsCommunicationGeneralPage';
import SmsTemplatesPage from 'modules/Organization/Settings/Communication/components/SmsTemplatesPage';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const TABS = {
  GENERAL: 'general',
  EMAIL_TEMPLATES: 'email-templates',
  SMS_TEMPLATES: 'sms-templates',
};

const PageContent = () => {
  const {params} = useNavigationDOM();

  switch (params.tab) {
    case TABS.GENERAL:
      return <OrganizationSettingsCommunicationGeneralPage />;
    case TABS.EMAIL_TEMPLATES:
      return <EmailTemplatesPage />;
    case TABS.SMS_TEMPLATES:
      return <SmsTemplatesPage />;
    default:
      return null;
  }
};

const OrganizationSettingsCommunicationPage = () => {
  const {navigator} = useNavigationDOM();

  return (
    <SettingsPage
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {name: 'Communication'},
      ]}
      tabs={[
        {
          label: 'General',
          url: `/settings/communication/${TABS.GENERAL}`,
        },
        {
          label: 'Email Templates',
          url: `/settings/communication/${TABS.EMAIL_TEMPLATES}`,
        },
        {
          label: 'SMS Templates',
          url: `/settings/communication/${TABS.SMS_TEMPLATES}`,
        },
      ]}
      hasNoChildrenStyle
    >
      <PageContent />
    </SettingsPage>
  );
};

export default OrganizationSettingsCommunicationPage;
