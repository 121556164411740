// Libararies
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography} from '@supermove/styles';

// App
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';

const StatusButton = Styled.View`
  padding: 4px 8px;
  border-radius: 4px;
`;

const StatusDropdownText = Styled.Text`
  ${Typography.Label3}
`;

const StatusButtonContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const InvoiceStatusBadge = ({invoice}) => {
  const invoiceStatus = InvoiceStatus.getStatusOption(invoice.status);
  return (
    <StatusButton style={{backgroundColor: invoiceStatus.backgroundColor}}>
      <StatusButtonContainer>
        {invoice.isRecurring && invoice.status === InvoiceStatus.SCHEDULED && (
          <React.Fragment>
            <Icon color={invoiceStatus.textColor} source={Icon.Sync} size={10} />
            <Space width={5} />
          </React.Fragment>
        )}
        <StatusDropdownText style={{color: invoiceStatus.textColor}}>
          {invoiceStatus.label}
        </StatusDropdownText>
      </StatusButtonContainer>
    </StatusButton>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceStatusBadge.fragment = gql`
  fragment InvoiceStatusBadge on Invoice {
    id
    status
    isRecurring
  }
`;

export default InvoiceStatusBadge;
