// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Row = Styled.View`
  flex-direction: row;
  padding-vertical: 10px;
  padding-horizontal: 20px;
  background-color: ${colors.gray.background};
`;

const Item = Styled.H6`
  ${fontWeight(700)}
  width: 120px;
  color: ${colors.gray.secondary};
  text-align: right;
`;

const SalesPayableItemsListHeader = () => (
  <Container>
    <Line />
    <Row>
      <Item style={{flex: 1, textAlign: 'left'}}>Item</Item>
      <Item>Labor Revenue</Item>
      <Item>Percent</Item>
      <Item>Earnings</Item>
    </Row>
  </Container>
);

export default SalesPayableItemsListHeader;
