// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Float, withFragment} from '@supermove/utils';

const edit = withFragment(
  (item) => ({
    itemId: (item as any).id,
    collectionId: (item as any).collectionId,
    name: (item as any).name,
    takeCount: (item as any).takeCount,
    leaveCount: (item as any).leaveCount,
    weight: (item as any).weight,
    volume: (item as any).volume,
    price: (item as any).price,
    isDerivedWeight: (item as any).isDerivedWeight,
    // Private
    densityFactor: (item as any).weight / (item as any).volume,
    categoryIds: _.get(item, 'itemType.itemTypeCategories', []).map((itemTypeCategory: any) => {
      return String(itemTypeCategory.categoryId);
    }),
    kind: _.get(item, 'itemType.kind'),
  }),
  gql`
    fragment ItemForm_edit on Item {
      id
      collectionId
      name
      takeCount
      leaveCount
      weight
      volume
      price
      isDerivedWeight
      itemType {
        id
        kind
        itemTypeCategories {
          id
          categoryId
        }
      }
    }
  `,
);

const _new = ({
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
  densityFactor,
  kind,
}: any) => ({
  itemId: undefined,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,

  // Private
  densityFactor,
  categoryIds: [],
  kind,
});

const getDensityFactor = ({densityFactor}: any) => {
  return Float.toFloat(densityFactor);
};

const getLeaveCount = ({leaveCount}: any) => {
  return Float.toFloat(leaveCount);
};

const getPrice = ({price}: any) => {
  return Currency.convertToCents(price);
};

const getTakeCount = ({takeCount}: any) => {
  return Float.toFloat(takeCount);
};

const getVolume = ({volume}: any) => {
  return Float.toFloat(volume);
};

const getWeight = ({weight}: any) => {
  return Float.toFloat(weight);
};

const toForm = ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,

  // Private
  densityFactor,

  categoryIds,
  kind,
}: any) => ({
  itemId,
  collectionId,
  name,
  takeCount: Float.toForm(takeCount),
  leaveCount: Float.toForm(leaveCount),
  weight: Float.toForm(weight),
  volume: Float.toForm(volume),
  price: Currency.toForm(price),
  isDerivedWeight,

  // Private
  densityFactor: Float.toForm(densityFactor),
  categoryIds,
  kind,
});

const toMutation = ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
}: any) => ({
  itemId,
  collectionId,
  name,
  takeCount: Float.toMutation(takeCount),
  leaveCount: Float.toMutation(leaveCount),
  weight: Float.toMutation(weight),
  volume: Float.toMutation(volume),
  price: Currency.toMutation(price),
  isDerivedWeight,
});

const ItemForm = {
  // Initialize
  edit,
  new: _new,

  // Getters
  getDensityFactor,
  getLeaveCount,
  getPrice,
  getTakeCount,
  getVolume,
  getWeight,

  // Conversion
  toForm,
  toMutation,
};

export default ItemForm;
