// Libraries
import React from 'react';

// Supermove
import {Popover, Space, Styled, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, usePopover, useSidebar} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Line from 'modules/App/components/Line';
import JobDispatchNotesPopover from 'modules/Dispatch/Calendar/Day/components/JobDispatchNotesPopover';
import JobArrivalTime from 'modules/Job/JobCard/components/JobArrivalTime';
import JobCrewCounts from 'modules/Job/JobCard/components/JobCrewCounts';
import JobDispatchNotes from 'modules/Job/JobCard/components/JobDispatchNotes';
import JobDispatchStatus from 'modules/Job/JobCard/components/JobDispatchStatus';
import JobDistance from 'modules/Job/JobCard/components/JobDistance';
import JobEstimatedRangeBadge from 'modules/Job/JobCard/components/JobEstimatedRangeBadge';
import JobInventoryWeight from 'modules/Job/JobCard/components/JobInventoryWeight';
import JobLocations from 'modules/Job/JobCard/components/JobLocations';
import JobNameBadge from 'modules/Job/JobCard/components/JobNameBadge';
import JobProjectComments from 'modules/Job/JobCard/components/JobProjectComments';
import JobSalesperson from 'modules/Job/JobCard/components/JobSalesperson';
import JobTruckInformation from 'modules/Job/JobCard/components/JobTruckInformation';

const BoldText = Styled.Text`
  ${Typography.Label3}
  color: ${(props) => props.color};
`;

const Text = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.primary};
`;

const JobCardContentWrapper = Styled.View`
  padding-vertical: 1px;
  overflow: hidden;
  flex: 1;
`;

const Container = Styled.View`
`;

const JobCardButton = Styled.ButtonV2`
`;

const JobCardContainer = Styled.View`
  position: relative;
  flex-direction: row;
  background-color: ${(props) =>
    props.isPending ? colors.white : colors.alpha(props.color, 0.08)};
  border-top-width: ${(props) => (props.isPending ? '1px' : '0px')};
  border-right-width: ${(props) => (props.isPending ? '1px' : '0px')};
  border-bottom-width: ${(props) => (props.isPending ? '1px' : '0px')};
  border-color: ${(props) => props.color}
  border-style: dashed;
  border-radius: 4px;
  align-items: center;
`;

const JobCardLeftStrip = Styled.View`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: ${(props) => props.color};
  width: 4px;
  align-self: stretch;
`;

const JobInfoRow = Styled.View`
  margin-horizontal: 6px;
  flex-direction: row;
  align-items: center;
`;

const ProjectTypeDot = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({color}) => color}
`;

const ProjectType = ({job, projectColor}) => {
  return (
    <JobInfoRow>
      <Space width={4} />
      <ProjectTypeDot color={projectColor} />
      <Space width={6} />
      <Text>{job.project.projectType.name}</Text>
      <Space style={{flex: 1}} />
      <Text style={{color: colors.gray.secondary}}>{job.organization.name}</Text>
    </JobInfoRow>
  );
};

const JobTitleRow = ({job}) => {
  const {params} = useNavigationDOM();
  const {project} = job;
  const {client} = project;
  const isPrimaryClientNameDifferent = client.name !== client.primaryContact.fullName;
  const date = params.date || Datetime.toMutationDate(Datetime.today);

  return (
    <JobInfoRow>
      <JobDispatchStatus job={job} />
      <Space width={4} />
      {Job.hasEstimatedRangeJobCounter(job) && (
        <React.Fragment>
          <JobEstimatedRangeBadge job={job} date={date} />
          <Space width={4} />
        </React.Fragment>
      )}
      <JobNameBadge job={job} />
      <Space width={4} />
      <Space style={{flexShrink: 1, flexDirection: 'row'}}>
        <BoldText numberOfLines={1}>
          {project.name ? `${project.name}: ` : ''}
          {client.name}
          {isPrimaryClientNameDifferent ? ` (${client.primaryContact.fullName})` : ''}
        </BoldText>
      </Space>
      <Space width={4} />
      <JobArrivalTime job={job} />
    </JobInfoRow>
  );
};

const JobDispatchNotesRow = ({job, jobDispatchNotesPopover}) => {
  return (
    <JobInfoRow>
      <JobDispatchNotes job={job} popover={jobDispatchNotesPopover} numberOfLines={1} />
    </JobInfoRow>
  );
};

const JobLogisticsRow = ({job}) => {
  return (
    <JobInfoRow>
      <JobInventoryWeight job={job} />
      <Space width={8} />
      <JobDistance job={job} />
      <Space width={8} />
      <JobLocations job={job} direction={'row'} />
    </JobInfoRow>
  );
};

const JobCrewInformationRow = ({job, crewUsersCount, jobCardId}) => {
  return (
    <JobInfoRow>
      <JobTruckInformation job={job} />
      <Space width={8} />
      <JobCrewCounts
        job={job}
        crewUsersCount={crewUsersCount}
        showNamesWithStatuses
        tooltipContainerId={jobCardId}
      />
    </JobInfoRow>
  );
};

const JobSalespersonInformationRow = ({job}) => {
  return (
    <JobInfoRow>
      <JobSalesperson job={job} />
    </JobInfoRow>
  );
};

const JobProjectCommentsRow = ({job}) => {
  const projectCommentsSidebar = useSidebar({
    name: 'ProjectCommentsSidebar',
    enableTracking: true,
  });
  return (
    <JobInfoRow>
      <JobProjectComments job={job} sidebar={projectCommentsSidebar} />
    </JobInfoRow>
  );
};

const DayJobItem = ({job, refetch}) => {
  const {navigator} = useNavigationDOM();
  const projectColor = job.project.projectType.color;
  const jobDispatchNotesPopover = usePopover();
  const projectStatus = Project.getSalesStatus(job.project);
  const jobCardId = `job-card-id-${job.id}`;

  return (
    <Container>
      <JobCardContainer
        isPending={['LEAD', 'HOLD'].includes(projectStatus)}
        color={projectColor}
        nativeID={jobCardId}
      >
        <JobCardLeftStrip color={projectColor} />
        <JobCardContentWrapper>
          <JobCardButton onPress={() => navigator.push(`/jobs/${job.uuid}`)}>
            <Space height={12} />
            <ProjectType job={job} projectColor={projectColor} />
            <Space height={8} />
            <JobTitleRow job={job} />
            <Space height={4} />
            <JobLogisticsRow job={job} />
            <Space height={4} />
            <JobDispatchNotesRow job={job} jobDispatchNotesPopover={jobDispatchNotesPopover} />
            <Space height={4} />
            <JobSalespersonInformationRow job={job} />
            <Space height={4} />
          </JobCardButton>
          <ScrollView horizontal>
            <Container>
              <JobCrewInformationRow
                job={job}
                crewUsersCount={Job.getDispatchJobUsersCount(job)}
                jobCardId={jobCardId}
              />
              <Space height={12} />
            </Container>
          </ScrollView>
          <Line />
          <Space height={12} />
          <JobProjectCommentsRow job={job} />
          <Space height={12} />
        </JobCardContentWrapper>
        <JobDispatchNotesPopover
          job={job}
          popover={jobDispatchNotesPopover}
          placement={Popover.Positions.Left}
          refetch={refetch}
        />
      </JobCardContainer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DayJobItem.fragment = gql`
  ${JobArrivalTime.fragment}
  ${JobCrewCounts.fragment}
  ${JobTruckInformation.fragment}
  ${JobSalesperson.fragment}
  ${JobEstimatedRangeBadge.fragment}
  ${JobDispatchNotes.fragment}
  ${JobDispatchNotesPopover.fragment}
  ${JobDispatchStatus.fragment}
  ${JobDistance.fragment}
  ${JobInventoryWeight.fragment}
  ${JobLocations.fragment}
  ${JobNameBadge.fragment}
  ${JobProjectComments.fragment}
  ${Job.hasEstimatedRangeJobCounter.fragment}
  ${Project.getSalesStatus.fragment}
  ${Job.getDispatchJobUsersCount.fragment}
  ${Job.getDispatchJobUsersNames.fragment}

  fragment DayJobItem on Job {
    id
    uuid
    organization {
      id
      name
    }
    project {
      id
      name
      projectType {
        id
        color
        name
      }
      client {
        id
        name
        primaryContact {
          id
          fullName
        }
      }
      ...Project_getSalesStatus
    }
    ...JobArrivalTime
    ...JobCrewCounts
    ...JobTruckInformation
    ...JobEstimatedRangeBadge
    ...JobDispatchNotes
    ...JobDispatchNotesPopover
    ...JobDispatchStatus
    ...JobDistance
    ...JobInventoryWeight
    ...JobLocations
    ...JobNameBadge
    ...JobProjectComments
    ...JobSalesperson
    ...Job_hasEstimatedRangeJobCounter
    ...Job_getDispatchJobUsersCount
    ...Job_getDispatchJobUsersNames
  }
`;

export default DayJobItem;
