// Libraries
import React from 'react';

// Supermove
import {Icon, IconSource, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import Modal from '@shared/design/components/Modal';
import Sheet from '@shared/design/components/Sheet';
import SheetWithFooter from '@shared/design/components/Sheet/SheetWithFooter';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const IconContainer = Styled.View`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-color: ${({color}: any) => colors.getBackgroundColor(color)};
`;

const Title = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${({color}: any) => color};
`;

const FooterContainer = Styled.View`
  flex-direction: ${({isResponsive, mobile}: any) =>
    isResponsive && mobile ? 'column-reverse' : 'row'};
  justify-content: flex-end;
  width: 100%;
`;

const LoadingContainer = Styled.View`
  align-items: center;
  justify-content: center;
`;

const Indicator = Styled.Loading`
`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const LoadingIndicator = () => (
  <LoadingContainer>
    <Space height={36} />
    <Indicator size={'large'} color={colors.gray.secondary} />
    <Space height={36} />
  </LoadingContainer>
);

export interface HeaderIconProps {
  source: IconSource;
  color?: string;
  label?: string;
  style?: object;
}

const HeaderIcon = ({source, color, label, style}: HeaderIconProps) => {
  return (
    <Container style={style}>
      <IconContainer color={color}>
        <Icon source={source} color={color} size={18} />
      </IconContainer>
      {label && (
        <React.Fragment>
          <Space height={20} />
          <Title color={color}>{label}</Title>
        </React.Fragment>
      )}
    </Container>
  );
};

export interface FooterProps {
  isResponsive?: boolean;
  style?: object;
  children: React.ReactNode;
}

const Footer = ({isResponsive, style, children}: FooterProps) => {
  const responsive = useResponsive();

  return (
    <FooterContainer {...responsive} isResponsive={isResponsive} style={style}>
      {children}
    </FooterContainer>
  );
};

export interface SmallModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  isResponsive?: boolean;
  isMobileSheet?: boolean;
  isFixedHeightSheet?: boolean;
  isDisabledPrimaryAction?: boolean;
  shouldCloseOnClickOutside?: boolean;
  headerComponent?: React.ReactElement;
  sheetHeaderContainerStyle?: object;
  sheetBodyStyle?: object;
  handleHeaderAction?: () => void;
  headerActionText?: string;
  headerActionIcon?: IconSource;
  handleAction?: () => void;
  handleActionText?: string;
  handleClose?: () => void;
  handlePressOutside?: () => void;
  title?: string;
  description?: string | React.ReactElement;
  sheetLabel?: string;
  style?: object;
  sheetStyle?: object;
  children: React.ReactNode;
}

const SmallModal = ({
  isOpen,
  isLoading = false,
  isResponsive = false,
  isMobileSheet = false,
  isFixedHeightSheet = false,
  isDisabledPrimaryAction = false,
  shouldCloseOnClickOutside = true,
  headerComponent,
  sheetHeaderContainerStyle = {},
  sheetBodyStyle = {},
  handleHeaderAction,
  headerActionText = '',
  headerActionIcon,
  handleAction,
  handleActionText = 'Save',
  handleClose = () => {},
  handlePressOutside = () => {},
  title = '',
  description = '',
  sheetLabel = '',
  style = {},
  sheetStyle,
  children,
}: SmallModalProps) => {
  const responsive = useResponsive();

  if (isMobileSheet && !responsive.desktop) {
    const SheetComponent = handleAction ? SheetWithFooter : Sheet;

    return (
      <SheetComponent
        isOpen={isOpen}
        handleClose={handleClose}
        headerComponent={headerComponent}
        headerText={sheetLabel || title}
        description={description}
        isFixedHeight={isFixedHeightSheet}
        shouldCloseOnClickOutside={shouldCloseOnClickOutside}
        handlePrimaryAction={handleAction}
        primaryActionText='Save'
        handleSecondaryAction={handleHeaderAction}
        secondaryActionText={headerActionText}
        headerContainerStyle={sheetHeaderContainerStyle}
        style={sheetStyle}
        isDisabled={isDisabledPrimaryAction}
      >
        <Space height={8} />
        {isLoading ? (
          <Container style={{flex: 1, justifyContent: 'center'}}>
            <LoadingIndicator />
          </Container>
        ) : (
          <Container style={{paddingHorizontal: 16, ...sheetBodyStyle}}>{children}</Container>
        )}
        <Space height={16} />
      </SheetComponent>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      handlePressOutside={handlePressOutside}
      style={{
        width: isResponsive && responsive.mobile ? '100%' : Modal.WIDTH.SMALL,
        maxWidth: Modal.WIDTH.SMALL,
        padding: isResponsive && responsive.mobile ? 16 : 24,
        ...style,
      }}
      screenContainerStyle={{padding: 16}}
    >
      {!!headerComponent && headerComponent}
      {!!title && (
        <React.Fragment>
          <Row>
            <Modal.HeaderText responsive={responsive}>{title}</Modal.HeaderText>
            {!!handleHeaderAction && (
              <React.Fragment>
                <Space style={{flex: 1, minWidth: 16}} />
                {headerActionText ? (
                  <TertiaryButton
                    text={headerActionText}
                    iconLeft={headerActionIcon}
                    onPress={handleHeaderAction}
                    isResponsive
                  />
                ) : (
                  <IconButton
                    source={headerActionIcon as IconSource}
                    onPress={handleHeaderAction}
                    isSecondary
                    size={20}
                  />
                )}
              </React.Fragment>
            )}
          </Row>
          {!!description && (
            <React.Fragment>
              <Space height={8} />
              <Modal.HeaderDescription responsive={responsive}>
                {description}
              </Modal.HeaderDescription>
            </React.Fragment>
          )}
          <Space height={16} />
        </React.Fragment>
      )}
      {isLoading ? <LoadingIndicator /> : children}
      {handleAction && (
        <Footer>
          <QuaternaryButton
            textColor={colors.blue.interactive}
            onPress={handleClose}
            text={'Cancel'}
            isResponsive
            style={responsive.desktop ? {width: 104} : {flex: 1}}
            isWidthOfContainer={!responsive.desktop}
          />
          <Space width={16} />
          <Button
            onPress={handleAction}
            text={handleActionText}
            iconLeft={Icon.Check}
            isResponsive
            style={responsive.desktop ? {width: 104} : {flex: 1}}
            isWidthOfContainer={!responsive.desktop}
            isDisabled={isDisabledPrimaryAction}
          />
        </Footer>
      )}
    </Modal>
  );
};

SmallModal.HeaderIcon = HeaderIcon;
SmallModal.HeaderText = Modal.HeaderText;
SmallModal.BoldText = Modal.BoldText;
SmallModal.Text = BodyText;
SmallModal.Button = Modal.Button;
SmallModal.ButtonText = Modal.ButtonText;
SmallModal.Footer = Footer;
SmallModal.PreventPropagationContainer = Modal.PreventPropagationContainer;

export default SmallModal;
