/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeletePaymentMutation from '@shared/modules/Billing/hooks/useDeletePaymentMutation';

const DeletePaymentModal = ({isOpen, payment, handleClose, refetch}) => {
  const {submitting, handleSubmit} = useDeletePaymentMutation({
    paymentId: payment.id,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Delete payment?'}
      subtitle={`'${payment.name}' will be deleted from this project. This cannot be undone.`}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeletePaymentModal.fragment = gql`
  fragment DeletePaymentModal on Payment {
    id
  }
`;

export default DeletePaymentModal;
