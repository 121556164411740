// Libraries
import React from 'react';

// Supermove
import {DateInput, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime, titleize} from '@supermove/utils';

// App

// Components
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import {
  CrewPayableUsersList,
  CrewPayableUsersReportSummaries,
} from 'modules/Payroll/Crew/components';
import {
  SalesPayableUsersList,
  SalesPayableUsersReportSummaries,
} from 'modules/Payroll/Sales/components';

const Content = Styled.View`
  margin-top: 20px;
  margin-bottom: 80px;
  margin-horizontal: ${(props) => (props.mobile ? 12 : 30)}px;
`;

const Header = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  align-items: center;
  z-index: 100;
  margin-top: 10px;
`;

const Info = Styled.View`
  ${(props) => (props.mobile ? 'margin-bottom: 20px;' : 'flex: 1;')}
`;

const Title = Styled.H2`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: ${({vars}) => (vars.mobile ? 'center' : 'left')};
`;

const Subtitle = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: ${({vars}) => (vars.mobile ? 'center' : 'left')};
`;

const Dates = Styled.View`
  flex-direction: row;
`;

const DateWrapper = Styled.View`
`;

const DateSpace = Styled.View`
  width: 10px;
`;

const Summaries = Styled.View`
  margin-top: 20px;
`;

const Users = Styled.View`
  margin-top: 20px;
`;

const CrewPayrollPageContent = ({kind, startDate, endDate, setStartDate, setEndDate}) => {
  const responsive = useResponsive();

  const {loading, data} = useQuery(CrewPayrollPageContent.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      kind,
      startDate: Datetime.toMutationDate(startDate),
      endDate: Datetime.toMutationDate(endDate),
    },
  });

  if (loading && !data) {
    return <PageLoadingIndicator />;
  }

  return (
    <ScrollView style={{flex: 1}}>
      <Content {...responsive}>
        <Header {...responsive}>
          <Info {...responsive}>
            <Title vars={responsive}>{titleize(kind)} Payroll</Title>
            <Subtitle vars={responsive}>
              {`${Datetime.toDisplayDate(startDate, 'MMMM Do, YYYY')} - ` +
                `${Datetime.toDisplayDate(endDate, 'MMMM Do, YYYY')}`}
            </Subtitle>
          </Info>
          <Dates>
            <DateWrapper>
              <DateInput value={startDate} setFieldValue={(name, date) => setStartDate(date)} />
            </DateWrapper>
            <DateSpace />
            <DateWrapper>
              <DateInput
                isFutureDisabled
                position={'bottom-end'}
                value={endDate}
                setFieldValue={(name, date) => setEndDate(date)}
              />
            </DateWrapper>
          </Dates>
        </Header>
        <Summaries>
          {kind === 'CREW' && (
            <CrewPayableUsersReportSummaries payableUsersReport={data.payableUsersReport} />
          )}
          {kind === 'SALES' && (
            <SalesPayableUsersReportSummaries payableUsersReport={data.payableUsersReport} />
          )}
        </Summaries>
        <Users>
          {kind === 'CREW' && (
            <CrewPayableUsersList payableUsers={data.payableUsersReport.payableUsers} />
          )}
          {kind === 'SALES' && (
            <SalesPayableUsersList payableUsers={data.payableUsersReport.payableUsers} />
          )}
        </Users>
      </Content>
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewPayrollPageContent.query = gql`
  ${CrewPayableUsersReportSummaries.fragment}
  ${CrewPayableUsersList.fragment}

  query CrewPayrollPageContent($kind: String!, $startDate: String!, $endDate: String!) {
    ${gql.query}
    payableUsersReport(kind: $kind, startDate: $startDate, endDate: $endDate) {
      payableUsers {
        ...CrewPayableUsersList
      }
      ...CrewPayableUsersReportSummaries
    }
  }
`;

export default CrewPayrollPageContent;
