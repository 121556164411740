// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const Container = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const Row = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: ${(props) => 100 - props.index};
`;

const LabelContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const LabelText = Styled.Text`
  ${Typography.Label}
`;

const ColorDot = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background-color: ${({color}) => color};
`;

const ReassignProjectTypeFields = ({
  form,
  field,
  dropdownField,
  dropdownOptions,
  dropdownPlaceholder,
  defaultProjectTypeInventorySettingsForms,
}) => {
  return (
    <Container index={0}>
      {defaultProjectTypeInventorySettingsForms.map(
        (defaultProjectTypeInventorySettingsForm, index) => {
          return (
            <React.Fragment>
              <Row index={index}>
                <LabelContainer>
                  <ColorDot
                    color={_.get(form.values, `${field}.${index}.projectTypeColor`, colors.white)}
                  />
                  <Space width={8} />
                  <LabelText>{_.get(form.values, `${field}.${index}.projectTypeName`)}</LabelText>
                </LabelContainer>
                <FieldInput
                  {...form}
                  index={index}
                  name={`${field}.${index}.${dropdownField}`}
                  component={DropdownInput}
                  input={{
                    isPortaled: true,
                    options: dropdownOptions,
                    placeholder: dropdownPlaceholder,
                    setFieldValue: form.setFieldValue,
                    style: {width: '325px'},
                  }}
                />
              </Row>
              <Space height={16} />
            </React.Fragment>
          );
        },
      )}
    </Container>
  );
};

export default ReassignProjectTypeFields;
