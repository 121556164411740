// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import InvoiceForm from '@shared/modules/Billing/forms/InvoiceForm';
import useUnfinalizeInvoiceMutation from '@shared/modules/Billing/hooks/useUnfinalizeInvoiceMutation';

const UnfinalizeInvoiceModal = ({invoiceId, isOpen, handleClose, refetch}) => {
  const invoiceForm = InvoiceForm.idOnly({invoiceId});
  const {submitting, handleSubmit} = useUnfinalizeInvoiceMutation({
    invoiceForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Unfinalize invoice?`}
      subtitle={`Please note when unfinalizing this invoice:\n${String.fromCharCode(
        0x2022,
      )} Some bill items may have been updated.\n${String.fromCharCode(
        0x2022,
      )} Please review the invoice line items.\n${String.fromCharCode(
        0x2022,
      )} The invoice will need to be manually finalized.`}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      deleteButtonText={'Unfinalize'}
      cancelButtonText={'Cancel'}
      isSubmitting={submitting}
    />
  );
};

export default UnfinalizeInvoiceModal;
