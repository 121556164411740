// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import ClientChatButton from 'modules/Client/Show/components/ClientChatButton';
import EditClientModal from 'modules/Client/Show/components/EditClientModal';

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index}px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  padding-horizontal: 16px;
`;

const Title = Styled.Text`
  ${Typography.Label1}
`;

const HeaderRow = Styled.View`
  z-index: ${({index = 0}) => 100 - index};
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
`;

const RowSpace = () => {
  const responsive = useResponsive();
  return <Space height={responsive.mobile ? 8 : 16} />;
};

const JobClientBlock = ({sectionIndex, job, refetch, isShowingBillingClient}) => {
  const responsive = useResponsive();
  const {client, billingClient} = job.project;
  const jobClient = isShowingBillingClient ? billingClient : client;
  const editClientModal = useModal({name: 'EditClientModal'});
  return (
    <Section index={sectionIndex}>
      <Space height={12} />
      <EditClientModal
        key={editClientModal.key}
        isOpen={editClientModal.isOpen}
        handleClose={editClientModal.handleClose}
        clientUuid={client.uuid}
        refetch={refetch}
      />
      <HeaderRow>
        <Title>{isShowingBillingClient ? 'Billing Information' : 'Client Information'}</Title>
        {!isShowingBillingClient && (
          <ClientChatButton
            projectId={job.project.id}
            client={client}
            handleMissingPhoneNumber={editClientModal.handleOpen}
          />
        )}
      </HeaderRow>
      <Space height={responsive.mobile ? 0 : 12} />
      <FieldValue
        label={'Client Name'}
        value={jobClient.name}
        link={`/clients/${jobClient.uuid}`}
      />
      <RowSpace />
      <FieldValue label={'Contact Name'} value={jobClient.primaryContact.fullName} />
      <RowSpace />
      <FieldValue
        label={'Phone Number'}
        value={Phone.display(jobClient.primaryContact.phoneNumber)}
        link={Phone.createUrl(jobClient.primaryContact.phoneNumber)}
        empty={'Not Specified'}
      />
      <RowSpace />
      <FieldValue label={'Email'} value={jobClient.primaryContact.email} empty={'Not Specified'} />
      <RowSpace />
      <FieldValue label={'Client Notes (Internal Only)'} value={jobClient.notes} empty={'None'} />
      <RowSpace />
      <FieldValue label={'Notes from Client'} value={job.noteFromCustomer} empty={'None'} />
      <Space height={16} />
    </Section>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobClientBlock.fragment = gql`
  ${ClientChatButton.fragment}
  fragment JobClientBlock on Job {
    id
    noteFromCustomer
    project {
      id
      client {
        id
        uuid
        name
        notes
        primaryContact {
          id
          fullName
          phoneNumber
          email
        }
        ...ClientChatButton
      }
      billingClient {
        id
        uuid
        name
        notes
        primaryContact {
          id
          fullName
          phoneNumber
          email
        }
      }
    }
  }
`;

export default JobClientBlock;
