// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {ModalPage, Navigation} from 'modules/App/components';

import UserDayNotFoundPage from './UserDayNotFoundPage';

const UserDayPage = ({side, title, subtitle, right, variables, query, onClose, children}) => (
  <ModalPage
    variables={variables}
    query={query}
    navigation={({responsive, loading, data, navigator}) => (
      <Navigation
        side={side}
        title={title({responsive, loading, data})}
        subtitle={subtitle({responsive, loading, data})}
        left={() =>
          onClose ? (
            <Navigation.CloseButton onPress={() => onClose({loading, data, navigator})} />
          ) : null
        }
        right={() => right({loading, data, navigator})}
      />
    )}
  >
    {({data, ...props}) => (data.userDay ? children({...props, data}) : <UserDayNotFoundPage />)}
  </ModalPage>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UserDayPage.propTypes = {
  onClose: PropTypes.func,
  side: PropTypes.func,
  title: PropTypes.func,
  subtitle: PropTypes.func,
  right: PropTypes.func,
};

UserDayPage.defaultProps = {
  onClose: undefined,
  side: () => 60,
  title: () => '',
  subtitle: () => '',
  right: () => null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserDayPage.fragment = gql`
  fragment UserDayPage on UserDay {
    id
  }
`;

export default UserDayPage;
