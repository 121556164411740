// Libararies
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import Line from 'modules/App/components/Line';

const Container = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View`
  padding: 16px;
  background-color: ${colors.white};
`;

const BodyContainer = Styled.View`
  background-color: ${colors.gray.background};
  flex: 1;
`;

const Header = ({children}) => {
  return (
    <React.Fragment>
      <HeaderContainer>{children}</HeaderContainer>
      <Line />
    </React.Fragment>
  );
};

const Body = ({children}) => {
  return (
    <BodyContainer>
      <ScrollView style={{padding: 16}}>{children}</ScrollView>
    </BodyContainer>
  );
};

const ProjectWidgetContainer = ({children}) => {
  return <Container>{children}</Container>;
};

ProjectWidgetContainer.Header = Header;
ProjectWidgetContainer.Body = Body;

export default ProjectWidgetContainer;
