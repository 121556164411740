// Libraries
import React from 'react';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';

const JobTypeStepsDocumentSelectSectionsModal = ({
  isOpen,
  handleClose,
  handleContinue,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleContinue: () => void;
}) => {
  return (
    <CautionModal
      isOpen={isOpen}
      handlePressOutside={handleClose}
      title={'Select document sections?'}
      message={
        'Select which sections customers can complete in the Crew App for this step. Unselected sections will be read-only, even if they have required inputs. To have the entire document completed, exit this flow.'
      }
      handlePrimaryAction={() => {
        handleContinue();
        handleClose();
      }}
      primaryActionText={'Continue'}
      handleSecondaryAction={handleClose}
      secondaryActionText={'Cancel'}
    />
  );
};

export default JobTypeStepsDocumentSelectSectionsModal;
