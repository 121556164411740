// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Lifecycle, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// App
import useAcceptQuoteConfirmationForm from 'modules/Customer/Project/Confirmation/Quote/logic/useAcceptQuoteConfirmationForm';
import ProjectPage from 'modules/Customer/Project/components/ProjectPage';
import CustomerTaskProjectQuoteUnacceptedContent from 'modules/Project/Confirmation/components/CustomerTaskProjectQuoteUnacceptedContent';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  flex: 1;
  width: ${(props) => (props.mobile ? '100%' : '576px')};
`;

const Content = Styled.View`
  align-self: stretch;
  padding-top: 60px;
  padding-bottom: 90px;
`;

const Section = Styled.View`
  align-self: stretch;
  padding-horizontal: 20px;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const SectionSpace = Styled.View`
  margin-top: 30px;
`;

const Text = Styled.H6`
  margin-bottom: 10px;
  color: ${colors.gray.secondary};
`;

const Button = Styled.StatefulButton`
  height: 40px;
  align-self: stretch;
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonContent = ({form}) => {
  if (form.loading) {
    return null;
  }
  return form.values.isFinished ? (
    <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.Check} />
  ) : (
    <ButtonText>Continue</ButtonText>
  );
};

// This is only the incorrect page when the whole
// confirmation is complete already. Otherwise,
// we allow the customer to view the quote page again.
const isCorrectPage = ({project}) => {
  return !_.get(project, 'confirmation.isComplete', false);
};

const onMountOrUpdate = ({navigator, project}) => {
  if (!isCorrectPage({project})) {
    navigator.push(`/0/${project.organization.slug}/projects/${project.uuid}/confirmation`);
  }
};

// If the next step of the confirmation is not to sign document,
// redirect the customer to the show confirmation page.
const Redirect = ({children, navigator, project}) => (
  <Lifecycle onMount={() => onMountOrUpdate({navigator, project})}>
    {isCorrectPage({project}) ? children : null}
  </Lifecycle>
);

const Footer = ({project, navigator}) => {
  const {form, submitting, handleSubmit} = useAcceptQuoteConfirmationForm({
    confirmation: project.confirmation,
    onSuccess: () => form.setFieldValue('isFinished', true),
    onError: (errors) => console.log({errors}),
  });
  return (
    <Section sectionIndex={0}>
      <Text>You will begin the confirmation process next.</Text>
      <Button
        loading={submitting}
        onPress={handleSubmit}
        onSuccess={() =>
          navigator.push(`/0/${project.organization.slug}/projects/${project.uuid}/confirmation`)
        }
        success={form.values.isFinished}
      >
        <ButtonContent form={form} />
      </Button>
    </Section>
  );
};

const QuoteConfirmProjectPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <ProjectPage variables={{uuid}} query={QuoteConfirmProjectPage.query}>
    {({responsive, navigator, data}) => (
      <Redirect navigator={navigator} project={data.project}>
        <Wrapper>
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: 'center',
            }}
          >
            <Container {...responsive}>
              <Content>
                <CustomerTaskProjectQuoteUnacceptedContent project={data.project} />
                <SectionSpace />
                <Footer project={data.project} navigator={navigator} />
              </Content>
            </Container>
          </ScrollView>
        </Wrapper>
      </Redirect>
    )}
  </ProjectPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
QuoteConfirmProjectPage.query = gql`
  ${CustomerTaskProjectQuoteUnacceptedContent.fragment}
  ${ProjectPage.fragment}

  query QuoteConfirmProjectPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      confirmation {
        id
        isComplete
        document {
          id
          template
        }
        nextStep {
          kind
          value
        }
      }
      organization {
        id
        slug
        name
      }
      ...CustomerTaskProjectQuoteUnacceptedContent
      ...ProjectPage
    }
  }
`;

export default QuoteConfirmProjectPage;
