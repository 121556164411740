/**
 * Component - v2.0.0
 */

// Libraries
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import BillingLibraryForm from '@shared/modules/Billing/forms/BillingLibraryForm';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = Styled.View`
  width: 400px;
  border-radius: 16px;
  background-color: ${colors.white};
  padding-horizontal: 24px;
`;

const Text = Styled.Text`
  ${Typography.Label1}
`;

const FooterRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const FooterButton = Styled.ButtonV2`
  width: 104px;
  height: 40px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: ${({color}) => color};
`;

const FooterButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${({color}) => color};
`;

const getBillingLibraryMessage = (billingLibrary) => {
  const projectTypeNames = billingLibrary.projectTypes.map((projectType) => projectType.name);
  const projectTypesText = projectTypeNames.join(', ');
  return (
    `This billing library is currently selected for the following project types: ` +
    `${projectTypesText}. ` +
    `You must remove this billing library from those project types before deleting it.`
  );
};

const CannotDeleteBillingLibraryModal = ({billingLibrary, isOpen, handleClose}) => {
  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Overlay pointerEvents={'box-none'}>
        <ContentContainer>
          <Space height={24} />
          <Text>{getBillingLibraryMessage(billingLibrary)}</Text>
          <Space height={20} />
          <FooterRow>
            <FooterButton color={colors.white} onPress={handleClose}>
              <FooterButtonText color={colors.gray.secondary}>Cancel</FooterButtonText>
            </FooterButton>
            <FooterButton color={colors.blue.interactive} onPress={handleClose}>
              <FooterButtonText color={colors.white}>Got it</FooterButtonText>
            </FooterButton>
          </FooterRow>
          <Space height={24} />
        </ContentContainer>
      </Overlay>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CannotDeleteBillingLibraryModal.fragment = gql`
  ${BillingLibraryForm.edit.fragment}
  fragment CannotDeleteBillingLibraryModal on BillingLibrary {
    id
    projectTypes {
      id
      name
    }
  }
`;

export default CannotDeleteBillingLibraryModal;
