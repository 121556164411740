// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components
import ReportMoveMoveUserItem from './ReportMoveMoveUserItem';

const EmptyMessage = Styled.H6`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const ReportMoveMoveUsersList = ({moveUsers}) => (
  <ScrollView>
    {_.some(moveUsers) ? (
      moveUsers.map((moveUser, index) => (
        <ReportMoveMoveUserItem isFirst={index === 0} moveUser={moveUser} key={index} />
      ))
    ) : (
      <EmptyMessage>No crew members yet</EmptyMessage>
    )}
  </ScrollView>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMoveMoveUsersList.fragment = gql`
  ${ReportMoveMoveUserItem.fragment}

  fragment ReportMoveMoveUsersList on ReportMoveUser {
    ...ReportMoveMoveUserItem
  }
`;

export default ReportMoveMoveUsersList;
