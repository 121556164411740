// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';

const useUpdateInventoryRoomsMutation = ({
  inventoryRoomsForm,
  onSuccess,
  onError,
  enableReinitialize = false,
}: any) => {
  const form = useForm({
    initialValues: {
      inventoryRoomsForm: InventoryRoomsForm.toForm(inventoryRoomsForm),
    },
    enableReinitialize,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateInventoryRoomsMutation.mutation,
    variables: {
      inventoryRoomsForm: InventoryRoomsForm.toMutation(form.values.inventoryRoomsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
    // Exposing this for use with useSubmitMutationWithKeepAlive()
    mutation: useUpdateInventoryRoomsMutation.mutation,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateInventoryRoomsMutation.mutation = gql`
  mutation useUpdateInventoryRoomsMutation($inventoryRoomsForm: InventoryRoomsForm!) {
    response: updateInventoryRooms(inventoryRoomsForm: $inventoryRoomsForm) {
      ${gql.errors}
      inventory {
        id
      }
    }
  }
`;

export default useUpdateInventoryRoomsMutation;
