// Libraries
import _ from 'lodash';
import React from 'react';

import {gql} from '@supermove/graphql';

// App
import InventoryConfirmationDocument from 'modules/Customer/Project/Confirmation/Inventory/components/InventoryConfirmationDocument';
import ConfirmationNotFound from 'modules/Customer/Project/Confirmation/components/ConfirmationNotFound';
import PrintDocumentPage from 'modules/Document/Print/components/PrintDocumentPage';

const PrintInventoryConfirmationPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <PrintDocumentPage query={PrintInventoryConfirmationPage.query} variables={{uuid}}>
    {({data}) =>
      !_.get(data, 'project.confirmation.inventoryWithSignature', null) ? (
        <ConfirmationNotFound />
      ) : (
        <InventoryConfirmationDocument
          confirmation={data.project.confirmation}
          shouldPromptSignature={false}
        />
      )
    }
  </PrintDocumentPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PrintInventoryConfirmationPage.query = gql`
  ${InventoryConfirmationDocument.fragment}

  query PrintInventoryConfirmationPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      confirmation {
        id
        ...InventoryConfirmationDocument
      }
    }
  }
`;

export default PrintInventoryConfirmationPage;
