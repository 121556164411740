// Libraries
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = () => ({
  name: '',
});

const edit = withFragment(
  (locationType) => ({
    name: (locationType as any).name,
  }),
  gql`
    fragment LocationTypeForm_edit on LocationType {
      name
    }
  `,
);

const toForm = ({name}: any) => ({
  name,
});

const toMutation = ({name}: any) => ({
  name,
});

const LocationTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default LocationTypeForm;
