// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useEffect, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const Button = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 120px;
  border-radius: 4px;
  background-color: ${({disabled}) => (disabled ? colors.gray.border : colors.blue.interactive)};
`;

const ActivityIndicator = Styled.Loading`
  flex: 1;
`;

const SubmitButton = ({form, handleSubmit, buttonText}) => {
  const [isWaiting, setIsWaiting] = useState(false);
  useEffect(() => {
    // Once the submit has started, if there are no errors we can continue
    // showing the activity indicator while we wait for all the onSuccess
    // actions to run. This logic assumes that the onSuccess will also do
    // something that unmounts this SubmitButton when it is done. If errors
    // are found, then we re-enable this button so that the user can try
    // submitting again.
    const hasErrors = !_.isEmpty(form.errors);
    if (isWaiting && hasErrors) {
      setIsWaiting(false);
    }
  }, [isWaiting, setIsWaiting, form.errors]);

  return (
    <Button
      disabled={isWaiting}
      onPress={() => {
        setIsWaiting(true);
        handleSubmit();
      }}
    >
      {isWaiting ? (
        <ActivityIndicator color={colors.white} size={'small'} />
      ) : (
        <ButtonText>{buttonText}</ButtonText>
      )}
    </Button>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
SubmitButton.propTypes = {
  buttonText: PropTypes.string,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

SubmitButton.defaultProps = {
  buttonText: 'Save',
};

export default SubmitButton;
