// Supermove
import {BulkUpdateBooleanPropertyForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useBulkUpdateTasksMutation = ({bulkUpdateBooleanPropertyForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      bulkUpdateBooleanPropertyForm: BulkUpdateBooleanPropertyForm.toForm(
        bulkUpdateBooleanPropertyForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useBulkUpdateTasksMutation.mutation,
    variables: {
      bulkUpdateBooleanPropertyForm: BulkUpdateBooleanPropertyForm.toMutation(
        form.values.bulkUpdateBooleanPropertyForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useBulkUpdateTasksMutation.mutation = gql`
  mutation useBulkUpdateTasksMutation($bulkUpdateBooleanPropertyForm: BulkUpdateBooleanPropertyForm!) {
    response: bulkUpdateTasks(bulkUpdateBooleanPropertyForm: $bulkUpdateBooleanPropertyForm) {
      ${gql.errors}
      tasks {
        id
        isArchived
      }
    }
  }
`;

export default useBulkUpdateTasksMutation;
