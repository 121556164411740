// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import InventoryRoomsList from '@shared/modules/Inventory/Summary/components/InventoryRoomsList';
import InventorySection from '@shared/modules/Inventory/Summary/components/InventorySection';

type OwnProps = {
  isPreview?: boolean;
  isDisabled?: boolean;
  showNotes?: boolean;
  showWeight?: boolean;
  showVolume?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ProjectDocumentV2SurveyRoomsSummary.defaultProps;

// @ts-expect-error TS(7022): 'ProjectDocumentV2SurveyRoomsSummary' implicitly h... Remove this comment to see the full error message
const ProjectDocumentV2SurveyRoomsSummary = ({
  isPreview,
  project,
  isDisabled,
  showNotes,
  showWeight,
  showVolume,
}: Props) => {
  return (
    <InventorySection icon={Icon.Archive} title={'Room summary (Cartons included)'}>
      <InventoryRoomsList
        isDisabled={isDisabled}
        inventory={isPreview ? MOCK_INVENTORY_DATA : project.inventory}
        showNotes={showNotes}
        showWeight={showWeight}
        showVolume={showVolume}
      />
    </InventorySection>
  );
};
ProjectDocumentV2SurveyRoomsSummary.defaultProps = {
  isPreview: false,
  isDisabled: false,
  showNotes: false,
  showWeight: true,
  showVolume: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectDocumentV2SurveyRoomsSummary.fragment = gql`
  ${InventoryRoomsList.fragment}
  fragment ProjectDocumentV2SurveyRoomsSummary on Project {
    id
    inventory {
      id
      ...InventoryRoomsList
    }
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_INVENTORY_DATA = {
  id: '1',
  rooms: [
    {
      id: '1',
      name: 'Room',
      description: 'Description',
      collection: {
        id: '1',
        leaveCount: 1,
        takeCount: 1,
        totalVolume: 1,
        totalWeight: 1,
        filteredItems: [
          {
            id: '1',
            itemTypeId: 1,
            leaveCount: 1,
            takeCount: 1,
            volume: 1,
            name: 'Bed',
            notes: 'This is a note',
          },
        ],
      },
    },
  ],
};

export default ProjectDocumentV2SurveyRoomsSummary;
