// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Form} from '@supermove/components';

// Components
import SupplyFormFields from './SupplyFormFields';
import UpdateSuppliesMutation from './UpdateSuppliesMutation';

const UpdateSupplyForm = ({index, organizationSuppliesForm, onSuccess, onError}) => (
  <Form
    initialValues={{
      organizationSuppliesForm,
    }}
    onSubmit={(values) => {}}
  >
    {({submitForm, setMutationErrors, ...form}) => (
      <UpdateSuppliesMutation
        organizationSuppliesForm={form.values.organizationSuppliesForm}
        onSuccess={onSuccess}
        submitForm={submitForm}
        setMutationErrors={setMutationErrors}
      >
        {({loading, handleSubmit}) => (
          <SupplyFormFields
            field={`organizationSuppliesForm.suppliesForm.supplyForms.${index}`}
            form={form}
            isUpdate
            loading={loading}
            handleSubmit={handleSubmit}
          />
        )}
      </UpdateSuppliesMutation>
    )}
  </Form>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateSupplyForm.propTypes = {
  index: PropTypes.number.isRequired,
  organizationSuppliesForm: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

UpdateSupplyForm.defaultProps = {
  onError: () => {},
};

export default UpdateSupplyForm;
