// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import UnassignUserFromJobMutation from './UnassignUserFromJobMutation';

const UnassignUserFromJobButton = ({job, user, onSuccess, children}) => (
  <UnassignUserFromJobMutation
    jobId={job.id}
    userId={user.id}
    refetchQueries={['EditJobUsersForm']}
    onSuccess={onSuccess}
  >
    {({loading, handleSubmit, ...props}) => children({loading, handleSubmit, ...props})}
  </UnassignUserFromJobMutation>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UnassignUserFromJobButton.propTypes = {
  job: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

UnassignUserFromJobButton.defaultProps = {};

export default UnassignUserFromJobButton;
