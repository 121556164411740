// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight} from '@supermove/styles';

import ReportMoveMoveUsersList from './ReportMoveMoveUsersList';

const Container = Styled.View`
`;

const Section = Styled.View`
`;

const SectionSpace = Styled.View`
  margin-top: 10px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = Styled.H6`
  ${fontWeight(700)}
`;

const Value = Styled.H6`
  ${fontWeight(700)}
`;

const ReportMoveMoveUsers = ({reportMove}) => (
  <Container>
    <Section>
      <Row>
        <Title>Crew Member</Title>
        <Value>Hours payable</Value>
      </Row>
    </Section>
    <SectionSpace />
    <Section>
      <ReportMoveMoveUsersList moveUsers={reportMove.moveUsers} />
    </Section>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMoveMoveUsers.fragment = gql`
  ${ReportMoveMoveUsersList.fragment}

  fragment ReportMoveMoveUsers on ReportMove {
    id
    moveUsers {
      ...ReportMoveMoveUsersList
    }
  }
`;

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ReportMoveMoveUsers.propTypes = {
  reportMove: PropTypes.object.isRequired,
};

ReportMoveMoveUsers.defaultProps = {};

export default ReportMoveMoveUsers;
