// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {fontWeight, colors, Typography} from '@supermove/styles';

// App
import UserRole from '@shared/modules/User/enums/UserRole';
import JobTimetable from 'modules/App/components/JobTimetable';

import JobReportMoveMoveUsers from './JobReportMoveMoveUsers';

const Container = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const TitleRow = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = Styled.Text`
  ${Typography.Heading1}
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const SectionSpace = Styled.View`
  margin-top: 20px;
`;

const Label = Styled.H7`
  margin-bottom: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const EmptyText = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const hasCrewHours = ({job}) => {
  const moveUsers = _.get(job, 'reportMove.moveUsers', []);
  return moveUsers.length > 0;
};

const TitleAndBadge = Styled.View`
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
`;

const Touchable = Styled.Touchable`
`;

const View = Styled.H6`
  color: ${colors.blue.interactive};
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const JobReportMove = ({sectionIndex, job, viewer, showHeader}) => {
  const {navigator} = useNavigationDOM();
  return (
    <Container sectionIndex={sectionIndex}>
      {showHeader && (
        <Section sectionIndex={0}>
          <TitleRow>
            <TitleAndBadge>
              <Title>
                {job.organization.settings.isCostAndCompensationEnabled
                  ? 'Timesheet'
                  : 'Accounting'}
              </Title>
            </TitleAndBadge>
            {UserRole.ADMIN_ROLES_PLUS_SUPER.includes(viewer.role) &&
              !_.isEmpty(job.reportMove) && (
                <Row>
                  <Touchable
                    onPress={() => {
                      navigator.push(
                        `/0/${job.organization.slug}/jobs/${job.uuid}/move-report/invoice`,
                      );
                    }}
                  >
                    <View>Print Invoice</View>
                  </Touchable>
                  <Space width={10} />
                  <Touchable
                    onPress={() => {
                      navigator.push(`/move-reports/${job.reportMove.uuid}/show`);
                    }}
                  >
                    <View>Edit Report</View>
                  </Touchable>
                </Row>
              )}
          </TitleRow>
        </Section>
      )}
      <Section sectionIndex={1}>
        <JobTimetable job={job} />
      </Section>
      <SectionSpace />
      <Section sectionIndex={2}>
        <Label>Crew Hours</Label>
        {hasCrewHours({job}) ? (
          <JobReportMoveMoveUsers moveUsers={job.reportMove.moveUsers} />
        ) : (
          <EmptyText>No crew hours yet</EmptyText>
        )}
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobReportMove.propTypes = {
  job: PropTypes.object.isRequired,
};

JobReportMove.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobReportMove.fragment = gql`
  ${JobReportMoveMoveUsers.fragment}
  ${JobTimetable.fragment}

  fragment JobReportMove_Viewer on User {
    id
    role
  }

  fragment JobReportMove on Job {
    id
    uuid
    organization {
      id
      slug
      settings {
        id
        isCostAndCompensationEnabled
      }
    }
    reportMove {
      id
      uuid
      isFinal
      moveUsers {
        ...JobReportMoveMoveUsers
      }
    }
    ...JobTimetable
  }
`;

export default JobReportMove;
