// Supermove
import {gql} from '@supermove/graphql';
import {Float, withFragment} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    defaultDensityFactor: (organization as any).settings.defaultDensityFactor,
  }),
  gql`
    fragment OrganizationInventorySettingsForm_edit on Organization {
      id
      settings {
        id
        defaultDensityFactor
      }
    }
  `,
);

const toForm = ({organizationId, defaultDensityFactor}: any) => ({
  organizationId,
  defaultDensityFactor: Float.toForm(defaultDensityFactor),
});

const toMutation = ({organizationId, defaultDensityFactor}: any) => ({
  organizationId,
  defaultDensityFactor: Float.toMutation(defaultDensityFactor),
});

const OrganizationInventorySettingsForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationInventorySettingsForm;
