// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment} from '@supermove/utils';

type Constructor = {
  billId?: number;
  // TODO(Kevin): Make jobId required after roll out
  jobId?: number;
  name?: string;
  amount?: number;
};
export type New = {
  billId?: number;
  jobId?: number | null;
  paymentId: number | null;
  name?: string;
  amount?: number;
};
const _new = ({billId, jobId, name, amount}: Constructor): New => ({
  billId,
  jobId,
  paymentId: null,
  name,
  amount,
});

type BillTip = {
  id: number;
  jobId: number | null;
  billId: number;
  name: string;
  amount: number;
};

const edit = withFragment(
  (billTip: BillTip) => ({
    billTipId: billTip.id,
    billId: billTip.billId,
    jobId: billTip.jobId,
    name: billTip.name,
    amount: billTip.amount,
  }),
  gql`
    fragment BillTipForm_edit on BillTip {
      id
      billId
      jobId
      name
      amount
    }
  `,
);

export type FormikForm = {
  billTipId?: number;
  billId?: number;
  jobId?: number | null;
  paymentId: number | null;
  name?: string;
  amount?: string;
};

const toForm = ({
  billTipId,
  billId,
  jobId,
  paymentId,
  name,
  amount,
}: New & {billTipId?: number}): FormikForm => {
  return {
    billTipId,
    billId,
    jobId,
    paymentId,
    name,
    amount: Currency.toForm(amount),
  };
};

export type GraphQLForm = {
  billTipId?: number;
  billId?: number;
  jobId?: number | null;
  paymentId: number | null;
  name?: string;
  amount?: number;
};

const toMutation = ({
  billTipId,
  billId,
  jobId,
  paymentId,
  name,
  amount,
}: FormikForm): GraphQLForm => {
  return {
    billTipId,
    billId,
    jobId,
    paymentId,
    name,
    amount: Currency.toMutation(amount),
  };
};

const BillTipForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default BillTipForm;
