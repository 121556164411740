// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Popover, ScrollView, Space, Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {BillItem} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// App
import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';

const Row = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const KindFilterButton = Styled.ButtonV2`
  padding-vertical: 4px;
  padding-horizontal: 8px;
  border-width: 1px;
  border-color: ${colors.blue.interactive};
  background-color: ${(props) => (props.isSelected ? colors.blue.interactive : colors.white)};
  border-radius: 20px;
  margin-top: 8px;
`;

const KindFilterText = Styled.H8`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const KindText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.tertiary};
`;

const NamePressable = Styled.ButtonV2`
  padding-vertical: 6px;
  padding-left: 8px;
`;

const NameText = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.black};
`;

const getSearchPopoverHeight = () => {
  const spaceAbove = 176;
  const spaceBelow = 80;
  return window.innerHeight - spaceAbove - spaceBelow;
};

const getSearchPopoverWidth = () => {
  const paddingOnSides = 32;
  return window.innerWidth - paddingOnSides;
};

const getGroupedItems = (billItemForms) => {
  const groups = _.reduce(
    billItemForms,
    (all, billItemForm) => {
      _.forEach(BillItem.FILTERS, (filter) => {
        if (
          filter.category === billItemForm.category &&
          filter.kind === billItemForm.kind &&
          filter.billStage === billItemForm.billStage &&
          !filter.unit === !billItemForm.unit
        ) {
          const currentGroup = all[filter.name];
          if (currentGroup) {
            all[filter.name] = [...currentGroup, billItemForm];
          } else {
            all[filter.name] = [billItemForm];
          }
        }
      });
      return all;
    },
    {},
  );
  return groups;
};

const getBillItemValueDisplay = (billItemForm) => {
  if (billItemForm.kind === BillItemTypeKind.PERCENTAGE) {
    return `(${billItemForm.percentage})`;
  }
  if (billItemForm.unit === BillItemUnit.HOUR) {
    return `(${billItemForm.amount} / hr)`;
  }
  return `(${billItemForm.amount})`;
};

const ItemFilters = ({selectedFilterNames, setSelectedFilterNames}) => {
  const handlePressFilter = (filter) => {
    if (_.includes(selectedFilterNames, filter.name)) {
      setSelectedFilterNames(
        _.filter(selectedFilterNames, (selectedKind) => selectedKind !== filter.name),
      );
    } else {
      setSelectedFilterNames([...selectedFilterNames, filter.name]);
    }
  };

  return (
    <Row>
      {BillItem.FILTERS.map((filter, index) => {
        const isSelected = _.includes(selectedFilterNames, filter.name);
        return (
          <React.Fragment key={`BILL_ITEMS_FILTER-${index}`}>
            {index > 0 && <Space width={4} />}
            <KindFilterButton onPress={() => handlePressFilter(filter)} isSelected={isSelected}>
              <KindFilterText color={isSelected ? colors.white : colors.blue.interactive}>
                {`${filter.name}${
                  filter.billStage === BillStage.POST_SUBTOTAL ? ' (After Subtotal)' : ''
                }`}
              </KindFilterText>
            </KindFilterButton>
          </React.Fragment>
        );
      })}
    </Row>
  );
};

const SearchItemForms = ({
  selectedFilterNames,
  searchItemForms,
  handleCloseSearch,
  handlePress,
}) => {
  const groupedItems = getGroupedItems(searchItemForms);
  const filterSelections = _.filter(
    BillItem.FILTERS,
    (filter) => selectedFilterNames.length === 0 || _.includes(selectedFilterNames, filter.name),
  );
  return (
    <React.Fragment>
      {filterSelections.map((filter, index) => {
        const billItemForms = _.get(groupedItems, filter.name, []);
        return (
          <React.Fragment key={`BILL_ITEM_KIND-${index}`}>
            <Space height={12} />
            <KindText>{`${filter.name} ${
              filter.billStage === BillStage.POST_SUBTOTAL ? '(After Subtotal) ' : ''
            }(${billItemForms.length})`}</KindText>
            {billItemForms.map((billItemForm, idx) => {
              return (
                <React.Fragment key={`BILL_ITEM_FORM-${idx}`}>
                  <Space height={6} />
                  <NamePressable
                    onPress={() => {
                      handlePress(billItemForm);
                      handleCloseSearch();
                    }}
                  >
                    <NameText>{`${BillItemForm.getDisplayName(
                      billItemForm,
                    )} ${getBillItemValueDisplay(billItemForm)}`}</NameText>
                  </NamePressable>
                </React.Fragment>
              );
            })}
            <Space height={10} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const EditBillItemsSearchPopover = ({popover, searchItemForms, handleCloseSearch, handlePress}) => {
  const [selectedFilterNames, setSelectedFilterNames] = useState([]);

  return (
    <Popover
      placement={Popover.Positions.Bottom}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ScrollView
        style={{
          borderWidth: 1,
          borderColor: colors.blue.interactive,
          borderRadius: 4,
          paddingHorizontal: 8,
          backgroundColor: colors.white,
          flex: 1,
          width: getSearchPopoverWidth(),
          maxHeight: getSearchPopoverHeight(),
        }}
      >
        <ItemFilters
          selectedFilterNames={selectedFilterNames}
          setSelectedFilterNames={setSelectedFilterNames}
        />
        <SearchItemForms
          selectedFilterNames={selectedFilterNames}
          searchItemForms={searchItemForms}
          handleCloseSearch={handleCloseSearch}
          handlePress={handlePress}
        />
        <Space height={16} />
      </ScrollView>
    </Popover>
  );
};

export default EditBillItemsSearchPopover;
