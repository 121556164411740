// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (project) => {
    return {
      projectId: (project as any).id,
      isTest: !(project as any).isTest,
    };
  },
  gql`
    fragment ProjectIsTestForm_edit on Project {
      id
      isTest
    }
  `,
);

const toForm = ({projectId, isTest}: any) => {
  return {
    projectId,
    isTest,
  };
};

const toMutation = ({projectId, isTest}: any) => {
  return {
    projectId,
    isTest,
  };
};

const ProjectIsTestForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectIsTestForm;
