// Libraries
import React from 'react';

// App
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';

const InviteOfficeUserSuccessModal = ({
  handleInviteAnotherUser,
  handleClose,
  isOpen,
  title,
  subtitle,
}) => {
  return (
    <SuccessModal
      title={title}
      subtitle={subtitle}
      isOpen={isOpen}
      handlePrimaryAction={handleInviteAnotherUser}
      handleSecondaryAction={handleClose}
      primaryActionText={'Invite Another User'}
      secondaryActionText={"I'm Done"}
    />
  );
};

export default InviteOfficeUserSuccessModal;
