// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Loading, Query, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import CrewScheduleItem from './CrewScheduleItem';
import SendReminderToSetScheduleButton from './SendReminderToSetScheduleButton';

const Container = Styled.View`
  width: 100%;
`;

const FixedColumn = Styled.View`
  width: 110px;
`;

const Column = Styled.View`
`;

const Table = Styled.View`
  flex-direction: row;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const HeaderCell = Styled.View`
  justify-content: center;
  width: 110px;
  height: 60px;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const FixedCell = Styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 75px;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  background-color: ${(props) => props.color};
`;

const HeaderEdgeCell = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  background-color: ${(props) => props.color};
`;

const ActionsCell = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 75px;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  background-color: ${(props) => props.color};
`;

const HeaderText = Styled.H7`
  ${fontWeight(700)}
`;

const Text = Styled.H7`

`;

const sortSchedules = ({schedules}) => {
  return _.sortBy(schedules, ['user.firstName', 'user.lastName']);
};

const getDatetimeToQuery = ({date}) => {
  if (date) {
    return Datetime.previousMonday(Datetime.fromDate(date));
  } else {
    return Datetime.previousMonday(Datetime.today);
  }
};

const getDateToQuery = ({date}) => {
  return Datetime.toDate(getDatetimeToQuery({date}));
};

const getDateRange = ({date}) => {
  return Datetime.getDateRange(getDatetimeToQuery({date}), 7).map((datetime) =>
    Datetime.toDate(datetime),
  );
};

const getDisplayDate = ({date}) => {
  return Datetime.convertToDisplayDate(date, 'ddd (M/D)');
};

const EmployeeNames = ({schedules}) => (
  <FixedColumn>
    <HeaderEdgeCell>
      <HeaderText>Employee</HeaderText>
    </HeaderEdgeCell>
    {schedules.map((schedule) => (
      <Row key={schedule.user.id}>
        <FixedCell>
          <Text>{`${schedule.user.firstName} ${schedule.user.lastName}`}</Text>
        </FixedCell>
      </Row>
    ))}
  </FixedColumn>
);

const EmployeeDays = ({shouldShowActions, date, responsive, schedules}) => (
  <ScrollView horizontal>
    <Column>
      <Row {...responsive}>
        {getDateRange({date}).map((date) => (
          <HeaderCell key={date}>
            <HeaderText>{`${getDisplayDate({date})}`}</HeaderText>
          </HeaderCell>
        ))}
        {shouldShowActions && (
          <HeaderEdgeCell>
            <HeaderText>Actions</HeaderText>
          </HeaderEdgeCell>
        )}
      </Row>
      {schedules.map((schedule) => (
        <Row key={schedule.user.id} {...responsive}>
          <CrewScheduleItem scheduleDays={schedule.scheduleDays} user={schedule.user} />
          {shouldShowActions && (
            <ActionsCell>
              <SendReminderToSetScheduleButton employee={schedule.user} />
            </ActionsCell>
          )}
        </Row>
      ))}
    </Column>
  </ScrollView>
);

const EmployeeSchedules = ({date, shouldShowActions, organization}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  return (
    <Query
      fetchPolicy={'cache-and-network'}
      variables={{
        date: getDateToQuery({date}),
        organizationId: organization.id,
      }}
      query={EmployeeSchedules.query}
    >
      {({loading, data, refetch}) => (
        <Loading loading={loading}>
          {() => (
            <Container {...responsive}>
              <Table>
                <EmployeeNames
                  responsive={responsive}
                  schedules={sortSchedules({schedules: data.employeeSchedules.schedules})}
                />
                <EmployeeDays
                  date={date}
                  navigator={navigator}
                  responsive={responsive}
                  schedules={sortSchedules({schedules: data.employeeSchedules.schedules})}
                  shouldShowActions={shouldShowActions}
                />
              </Table>
            </Container>
          )}
        </Loading>
      )}
    </Query>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmployeeSchedules.fragment = gql`
  fragment EmployeeSchedules on User {
    id
    viewingOrganization {
      id
    }
  }
`;

EmployeeSchedules.query = gql`
  ${CrewScheduleItem.fragment}
  ${SendReminderToSetScheduleButton.fragment}

  query EmployeeSchedules(
    $date: String!,
    $organizationId: Int!,
  ) {
    ${gql.query}
    employeeSchedules: employeeSchedulesByOrganizationId(
      date: $date,
      organizationId: $organizationId,
    ) {
      schedules: employeeSchedules {
        user {
          id
          firstName
          lastName
          ...SendReminderToSetScheduleButton
        }
        scheduleDays: employeeScheduleDays {
          ...CrewScheduleItem
        }
      }
    }
  }
`;

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
EmployeeSchedules.propTypes = {
  date: PropTypes.string,
  organization: PropTypes.object.isRequired,
  shouldShowActions: PropTypes.bool,
};

EmployeeSchedules.defaultProps = {
  date: null,
  shouldShowActions: true,
};

export default EmployeeSchedules;
