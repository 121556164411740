import SupplyForm from './SupplyForm';

const SuppliesForm = {
  toForm: ({supplyForms}) => ({
    supplyForms: supplyForms.map((supply) => SupplyForm.toForm(supply)),
  }),

  toMutation: ({supplyForms}) => ({
    supplyForms: supplyForms.map((supply) => SupplyForm.toMutation(supply)),
  }),
};

export default SuppliesForm;
