// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';

const MessageModal = ({handleConfirm, handleClose, isOpen, submitting, message, confirmText}) => {
  return (
    <SmallModal handlePressOutside={handleClose} isOpen={isOpen}>
      <SmallModal.BoldText>{message}</SmallModal.BoldText>
      <Space height={36} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          color={colors.blue.interactive}
          onPress={handleConfirm}
          isSubmitting={submitting}
        >
          {confirmText}
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
MessageModal.propTypes = {
  confirmText: PropTypes.string,
};

MessageModal.defaultProps = {
  confirmText: 'Confirm',
};

export default MessageModal;
