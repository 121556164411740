// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import UpdateCommercialReportMoveTimesheetTable from 'modules/ReportMove/Commercial/components/UpdateCommercialReportMoveTimesheetTable';

const Container = Styled.View`
  flex: 1;
`;

const Title = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Table = Styled.View`
  flex: 1;
  padding-top: 12px;
`;

const UpdateCommercialReportMoveTimesheetModalV2 = ({jobUuid, isOpen, handleClose}) => {
  const {data, loading} = useQuery(UpdateCommercialReportMoveTimesheetModalV2.query, {
    fetchPolicy: 'cache-and-network',
    variables: {jobUuid},
    skip: !isOpen,
  });

  return (
    <Modal
      isOpen={isOpen}
      handlePressOutside={handleClose}
      style={{
        height: '90%',
        width: 800,
        padding: 24,
        backgroundColor: colors.white,
      }}
    >
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => (
          <Container>
            <Title>Work Timesheet</Title>
            <Table>
              <UpdateCommercialReportMoveTimesheetTable
                reportMove={data.job.reportMove}
                handleClose={handleClose}
              />
            </Table>
          </Container>
        )}
      </Loading>
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateCommercialReportMoveTimesheetModalV2.query = gql`
  ${UpdateCommercialReportMoveTimesheetTable.fragment}

  query UpdateCommercialReportMoveTimesheetModalV2(
    $jobUuid: String!,
  ) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      reportMove {
        id
        ...UpdateCommercialReportMoveTimesheetTable
      }
    }
  }
`;

export default UpdateCommercialReportMoveTimesheetModalV2;
