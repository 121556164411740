// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const UpdateReportMoveMoveUsersMutation = ({
  reportMoveId,
  moveUserInputs,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}) => (
  <Mutation
    variables={{
      reportMoveId,
      moveUserInputs,
    }}
    mutation={UpdateReportMoveMoveUsersMutation.mutation}
    onCompleted={({response: {reportMove, errors}}) => {
      submitForm();
      setMutationErrors(errors);

      if (!errors) {
        onSuccess(reportMove);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateReportMoveMoveUsersMutation.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

UpdateReportMoveMoveUsersMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateReportMoveMoveUsersMutation.mutation = gql`
  mutation UpdateReportMoveMoveUsersMutation(
    $reportMoveId: Int!,
    $moveUserInputs: [ReportMoveUserInput]!,
  ) {
    response: updateReportMoveMoveUsers(
      reportMoveId: $reportMoveId,
      moveUserInputs: $moveUserInputs,
    ) {
      ${gql.errors}
      reportMove {
        id
        uuid
        timePayable
        moveUsers {
          userId
          timePayableAdjustment
          tip
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export default UpdateReportMoveMoveUsersMutation;
