const _new = ({attachmentId}: any) => ({
  attachmentId,
});

const toForm = ({attachmentId}: any) => ({
  attachmentId,
});

const toMutation = ({attachmentId}: any) => ({
  attachmentId,
});

const DeleteAttachmentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default DeleteAttachmentForm;
