// Libraries
import React from 'react';

// Supermove
import {Query, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import EstimatesDayJobsList from 'modules/Job/Estimate/components/EstimatesDayJobsList';

const Container = Styled.View`
  flex: 1;
  border-left-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Loading = Styled.View`
  padding-top: 40px;
`;

const Indicator = Styled.Loading`
`;

const EstimatesDayDetails = ({date, params}) => (
  <Query
    fetchPolicy={'network-only'}
    variables={{
      date,
      slugs: params.slugs,
    }}
    query={EstimatesDayDetails.query}
  >
    {({loading, data, refetch}) => (
      <Container data-test-id='estimates-day-details'>
        {loading ? (
          <Loading>
            <Indicator size={'large'} color={colors.gray.secondary} />
          </Loading>
        ) : (
          <EstimatesDayJobsList
            date={date}
            calendarDay={data.estimatesCalendarDay}
            refetch={refetch}
          />
        )}
      </Container>
    )}
  </Query>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
EstimatesDayDetails.query = gql`
  ${EstimatesDayJobsList.fragment}

  query EstimatesDayDetails($date: String!, $slugs: [String]) {
    ${gql.query}
    estimatesCalendarDay(date: $date, slugs: $slugs) {
      ...EstimatesDayJobsList
    }
  }
`;

export default EstimatesDayDetails;
