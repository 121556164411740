/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const IconContainer = Styled.View`
  background-color: ${({color}) => colors.alpha(color, 0.15)};
  height: 18px;
  width: 18px;
  border-radius: 9px;
  border-width: 1px;
  border-color: ${({color}) => color};
  align-items: center;
  justify-content: center;
`;

const JobDispatchStatus = ({job}) => {
  const color = Job.getCalendarPrimaryStatusColor(job);

  return (
    <TextTooltip text={Job.getCalendarPrimaryStatusText(job)}>
      <IconContainer color={color}>
        <Icon source={Job.getCalendarPrimaryStatusIcon(job)} size={10} color={color} />
      </IconContainer>
    </TextTooltip>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchStatus.fragment = gql`
  ${Job.getCalendarPrimaryStatusColor.fragment}
  ${Job.getCalendarPrimaryStatusIcon.fragment}
  ${Job.getCalendarPrimaryStatusText.fragment}

  fragment JobDispatchStatus on Job {
    id
    ...Job_getCalendarPrimaryStatusColor
    ...Job_getCalendarPrimaryStatusIcon
    ...Job_getCalendarPrimaryStatusText
  }
`;

export default JobDispatchStatus;
