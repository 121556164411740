// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Emoji, Modal, Mutation, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

const JobSkipConfirmationModal = ({isOpen, job, handleClose}) => {
  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Container pointerEvents={'box-none'}>
        <Wrapper width={350}>
          <Circle>
            <Emoji component={EmojiText} name={'spiral_calendar_pad'} />
          </Circle>
          <Content>
            <Title>Skip Confirmation</Title>
            <Subtitle>
              {`Are you sure you want to skip the confirmation for ${Job.getFullName(job)}?`}
            </Subtitle>
            <Actions>
              <Button color={colors.gray.tertiary} onPress={handleClose}>
                <Text>Cancel</Text>
              </Button>
              <ButtonSpace />
              <Mutation
                variables={{
                  jobId: job.id,
                }}
                mutation={JobSkipConfirmationModal.mutation}
                onCompleted={({response: {job, errors}}) => {
                  if (job) {
                    handleClose();
                  }
                }}
              >
                {(handleSubmit, {loading, error}) => (
                  <Button color={colors.red.warning} loading={loading} onPress={handleSubmit}>
                    <Text>Confirm</Text>
                  </Button>
                )}
              </Mutation>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobSkipConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  job: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
};

JobSkipConfirmationModal.defaultProps = {
  handleClose: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobSkipConfirmationModal.fragment = gql`
  ${Job.getFullName.fragment}

  fragment JobSkipConfirmationModal on Job {
    id
    ...Job_getFullName
  }
`;

JobSkipConfirmationModal.mutation = gql`
  mutation JobSkipConfirmationModal($jobId: Int!) {
    response: completeConfirmationManually(jobId: $jobId) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default JobSkipConfirmationModal;
