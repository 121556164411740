// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
`;

const Label = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const Value = Styled.Text<{vars?: {isLink?: boolean}}>`
  ${Typography.Body2}
  color: ${({vars = {isLink: false}}) =>
    vars.isLink ? colors.blue.interactive : colors.gray.primary};
`;

const Empty = Styled.Text`
  ${Typography.Body2}
  font-style: italic;
  color: ${colors.gray.secondary};
`;

interface ContentProps {
  newTab?: boolean;
  numberOfLines?: number;
  value?: string;
  link?: string;
  empty?: string;
}

const Content = ({newTab, numberOfLines, value, link, empty}: ContentProps) => {
  if (!value) {
    return <Empty numberOfLines={numberOfLines}>{empty}</Empty>;
  }

  if (link) {
    return (
      <a target={newTab ? '_blank' : '_self'} href={link}>
        <Value vars={{isLink: true}} numberOfLines={numberOfLines}>
          {value}
        </Value>
      </a>
    );
  }

  return <Value numberOfLines={numberOfLines}>{value}</Value>;
};

interface FieldValueProps {
  newTab?: boolean;
  numberOfLines?: number;
  label?: string;
  value?: string;
  link?: string;
  empty?: string;
  style?: object;
}

const FieldValue = ({newTab, numberOfLines, label, value, link, empty, style}: FieldValueProps) => (
  <Container style={style}>
    {!!label && (
      <React.Fragment>
        <Label>{label}</Label>
        <Space height={2} />
      </React.Fragment>
    )}
    <Content
      newTab={newTab}
      numberOfLines={numberOfLines}
      value={value}
      link={link}
      empty={empty}
    />
  </Container>
);

FieldValue.Empty = Empty;
FieldValue.Label = Label;
FieldValue.Value = Value;

export default FieldValue;
