// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import DocumentStepKind from '@shared/modules/Document/enums/DocumentStepKind';

const OFFICE = 'OFFICE';
const CONFIRMATION = 'CONFIRMATION';
const QUOTE = 'QUOTE';
const CREW = 'CREW';
const PROCESSES = [OFFICE, CONFIRMATION, QUOTE, CREW];
const PROPOSAL = [CONFIRMATION, QUOTE];

const getDocumentProcess = withFragment(
  (document) => {
    if (_.includes(DocumentStepKind.CUSTOM_STEPS, (document as any).step)) {
      return OFFICE;
    }
    // using document.template should be deprecated once confirmation documents are not hard-coded
    if (
      _.includes(DocumentStepKind.CONFIRMATION_STEPS, (document as any).step) ||
      (document as any).template === 'CONFIRMATION_LETTER'
    ) {
      return CONFIRMATION;
    }
    if (_.includes(DocumentStepKind.QUOTE_STEPS, (document as any).step)) {
      return QUOTE;
    }
    return CREW;
  },
  gql`
    fragment DocumentProcess_getDocumentProcess on Document {
      id
      step
    }
  `,
);

const getIsProposalDocument = withFragment(
  (document) => {
    const process = getDocumentProcess(document);
    return _.includes(PROPOSAL, process);
  },
  gql`
    ${getDocumentProcess.fragment}
    fragment DocumentProcess_getIsProposalDocument on Document {
      id
      ...DocumentProcess_getDocumentProcess
    }
  `,
);

const getProcessName = (process: any) => {
  switch (process) {
    case OFFICE:
      return 'Office App';
    case CREW:
      return 'Crew App';
    default:
      return _.capitalize(process);
  }
};

const getDocumentProcessName = withFragment(
  (document) => {
    const process = getDocumentProcess(document);
    return getProcessName(process);
  },
  gql`
    ${getDocumentProcess.fragment}
    fragment DocumentProcess_getDocumentProcessName on Document {
      id
      ...DocumentProcess_getDocumentProcess
    }
  `,
);

const getDropdownOptions = () => {
  return _.orderBy(
    PROCESSES.map((process) => ({
      label: getProcessName(process),
      value: process,
    })),
    ['label'],
    ['asc'],
  );
};

const DocumentProcess = {
  OFFICE,
  CONFIRMATION,
  QUOTE,
  CREW,
  getDocumentProcess,
  getDocumentProcessName,
  getProcessName,
  getDropdownOptions,
  getIsProposalDocument,
};

export default DocumentProcess;
