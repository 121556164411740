// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useUncancelJobMutation = ({jobId, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      jobId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUncancelJobMutation.mutation,
    variables: {
      jobId: form.values.jobId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUncancelJobMutation.mutation = gql`
  mutation useUncancelJobMutation($jobId: Int!) {
    response: uncancelJob(jobId: $jobId) {
      ${gql.errors}
      job {
        id
        primaryStatus
        calendarPrimaryStatus
      }
    }
  }
`;

export default useUncancelJobMutation;
