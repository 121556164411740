// Supermove
import {gql} from '@supermove/graphql';
import {DocumentModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export type ShareDocumentFormType = {
  documentId: string;
};

const edit = withFragment(
  (document: DocumentModel): ShareDocumentFormType => ({
    documentId: document.id,
  }),
  gql`
    fragment ShareDocumentForm_edit on Document {
      id
    }
  `,
);

const toForm = ({documentId}: ShareDocumentFormType) => {
  return {
    documentId,
  };
};

const toMutation = ({documentId}: ShareDocumentFormType) => {
  return {
    documentId,
  };
};

const ShareDocumentForm = {
  edit,
  toForm,
  toMutation,
};

export default ShareDocumentForm;
