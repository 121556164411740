/**
 * Component - v2.0.0
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Query, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import useSendEmailForProjectMutation from '@shared/modules/Email/hooks/useSendEmailForProjectMutation';
import LargeModal from 'modules/App/Modal/components/LargeModal';
import Line from 'modules/App/components/Line';
import ProjectEmailFields from 'modules/Email/Project/components/ProjectEmailFields';
import EmailAttachmentsSelector from 'modules/File/Attachment/components/EmailAttachmentsSelector';

const Section = Styled.View`
  padding-horizontal: ${(props) => (props.mobile ? 20 : 30)}px;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const Indicator = Styled.Loading`
`;

const Button = Styled.LoadingButton`
  height: 30px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const Row = Styled.View`
`;

const SendEmailForProjectModalContent = ({viewer, project, emailTemplate, handleClose}) => {
  const responsive = useResponsive();
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);

  const {form, submitting, handleSubmit} = useSendEmailForProjectMutation({
    project,
    viewerId: viewer.id,
    emailTemplate,
    onSuccess: handleClose,
    onError: (errors) => console.log({errors}),
  });

  const {emailForm} = form.values;

  return (
    <React.Fragment>
      <Line />
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 40,
        }}
      >
        <Section sectionIndex={0} {...responsive}>
          <Space height={24} />
          <ProjectEmailFields
            field={'emailForm'}
            form={form}
            viewerId={viewer.id}
            project={project}
            setIsGeneratingPreview={setIsGeneratingPreview}
          />
          <Space height={10} />
          <Row>
            <EmailAttachmentsSelector form={form} project={project} name={'emailForm'} />
          </Row>
        </Section>
      </ScrollView>
      <Line />
      <Section
        sectionIndex={1}
        style={{
          paddingVertical: 20,
        }}
        {...responsive}
      >
        <Button
          disabled={!(emailForm.subject && emailForm.body) || isGeneratingPreview}
          loading={submitting}
          onPress={handleSubmit}
        >
          <Text>Send</Text>
        </Button>
      </Section>
    </React.Fragment>
  );
};

const SendEmailForProjectModal = ({isOpen, project, emailTemplateUuid, handleClose}) => {
  return (
    <LargeModal
      isOpen={isOpen}
      handleClose={handleClose}
      disableCloseOnPressOutside
      title={'Send Email from Template'}
      width={800}
    >
      <Query
        fetchPolicy={'cache-and-network'}
        variables={{
          projectUuid: project.uuid,
          emailTemplateUuid: emailTemplateUuid || '',
        }}
        skip={!isOpen}
        query={SendEmailForProjectModal.query}
      >
        {({loading, data}) =>
          loading || !data ? (
            <Indicator size={'large'} color={colors.gray.secondary} />
          ) : (
            <SendEmailForProjectModalContent
              viewer={data.viewer}
              project={data.project}
              emailTemplate={data.emailTemplate}
              handleClose={handleClose}
            />
          )
        }
      </Query>
    </LargeModal>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SendEmailForProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
};

SendEmailForProjectModal.defaultProps = {
  handleClose: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendEmailForProjectModal.query = gql`
  ${EmailAttachmentsSelector.fragment}
  ${ProjectEmailFields.fragment}
  ${useSendEmailForProjectMutation.fragment}
  
  query SendEmailForProjectModal($projectUuid: String!, $emailTemplateUuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    project(uuid: $projectUuid) {
      id
      uuid
      ...useSendEmailForProjectMutation_Project
      ...EmailAttachmentsSelector
      ...ProjectEmailFields
    }
    emailTemplate(uuid: $emailTemplateUuid) {
      id
      ...useSendEmailForProjectMutation_EmailTemplate
    }
  }
`;

SendEmailForProjectModal.fragment = gql`
  fragment SendEmailForProjectModal on Project {
    id
    uuid
  }
`;

export default SendEmailForProjectModal;
