// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import VariableForm from '@shared/modules/Billing/forms/VariableForm';
import useUpdateVariableMutation from '@shared/modules/Billing/hooks/useUpdateVariableMutation';
import VariableDrawerFields from 'modules/Organization/Settings/Billing/Variables/components/VariableDrawerFields';

const UpdateVariableDrawer = ({variable, isOpen, handleClose, refetch, userId}) => {
  const variableForm = VariableForm.edit(variable, {userId});

  const {form, handleSubmit, submitting} = useUpdateVariableMutation({
    variableForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Update Variable'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <VariableDrawerFields form={form} field={'variableForm'} />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateVariableDrawer.fragment = gql`
  ${VariableForm.edit.fragment}
  fragment UpdateVariableDrawer on Variable {
    id
    ...VariableForm_edit
  }
`;

export default UpdateVariableDrawer;
