// Supermove
import {Datetime, Json} from '@supermove/utils';

const _new = () => ({
  salespersonId: null,
  startDate: null,
  endDate: null,
});

const toForm = (form) => ({
  ...form,
});

const toFilterObject = (form) => Json.stringify(Json.toSnakeCaseKeys(form));

const EMPTY_FILTER_STRING = '-1';

const toUrlParameters = (form) => {
  const newQueryParameters = [];

  if (form.salespersonId !== null && form.salespersonId !== undefined) {
    newQueryParameters.push(`salespersonId=${form.salespersonId}`);
  } else {
    newQueryParameters.push(`salespersonId=${EMPTY_FILTER_STRING}`);
  }

  if (form.startDate !== null && form.startDate !== undefined) {
    newQueryParameters.push(`startDate=${Datetime.toDate(form.startDate)}`);
  } else {
    newQueryParameters.push(`startDate=${EMPTY_FILTER_STRING}`);
  }

  if (form.endDate !== null && form.endDate !== undefined) {
    newQueryParameters.push(`endDate=${Datetime.toDate(form.endDate)}`);
  } else {
    newQueryParameters.push(`endDate=${EMPTY_FILTER_STRING}`);
  }

  return `${newQueryParameters.join('&')}`;
};

const fromUrlParameters = (params) => {
  const form = _new();

  if (params.salespersonId === EMPTY_FILTER_STRING) {
    form.salespersonId = null;
  } else if (params.salespersonId !== null && params.salespersonId !== undefined) {
    form.salespersonId = params.salespersonId;
  } else {
    form.salespersonId = null;
  }

  if (params.startDate === EMPTY_FILTER_STRING) {
    form.startDate = null;
  } else if (params.startDate !== null && params.startDate !== undefined) {
    form.startDate = Datetime.fromDate(params.startDate);
  } else {
    form.startDate = null;
  }

  if (params.endDate === EMPTY_FILTER_STRING) {
    form.endDate = null;
  } else if (params.endDate !== null && params.endDate !== undefined) {
    form.endDate = Datetime.fromDate(params.endDate);
  } else {
    form.endDate = null;
  }

  return form;
};

const ReportViewFiltersForm = {
  new: _new,
  toForm,
  toFilterObject,
  toUrlParameters,
  fromUrlParameters,
};

export default ReportViewFiltersForm;
