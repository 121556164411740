/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import RoomForm from '@shared/modules/Inventory/forms/RoomForm';
import useUpdateRoomMutation from '@shared/modules/Inventory/hooks/useUpdateRoomMutation';
import Modal from 'components/Modal';
import Field from 'modules/App/components/Field';
import DeleteRoomModal from 'modules/Room/Show/components/DeleteRoomModal';

const Container = Styled.View`
  padding: 20px;
`;

const Actions = Styled.View`
  align-items: stretch;
`;

const Button = Styled.LoadingButton`
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const UpdateRoomModal = ({isOpen, room, handleOpen, handleClose, refetch}) => {
  const {
    isOpen: isOpenDelete,
    handleOpen: handleOpenDelete,
    handleClose: handleCloseDelete,
  } = useModal();

  const responsive = useResponsive();
  const roomForm = RoomForm.edit(room);
  const {form, submitting, handleSubmit} = useUpdateRoomMutation({
    roomForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        title={'Edit Room Details'}
        subtitle={room.roomType ? room.roomType.name : 'Custom Room'}
        onClose={handleClose}
      >
        <Container>
          <Field
            {...form}
            name={'roomForm.name'}
            label={'Room Name'}
            input={{
              required: !form.values.roomForm.name,
              autoFocus: true,
              placeholder: 'Enter a custom room name',
              style: {
                width: responsive.mobile ? '100%' : 360,
              },
            }}
            style={{
              width: responsive.mobile ? '100%' : 360,
            }}
          />
          <Space height={20} />
          <Field
            {...form}
            name={'roomForm.floorNumber'}
            label={'Floor'}
            input={{
              placeholder: '#',
              style: {
                width: responsive.mobile ? '100%' : 360,
              },
            }}
            style={{
              width: responsive.mobile ? '100%' : 360,
            }}
          />
          <Space height={20} />
          <Field
            {...form}
            name={'roomForm.description'}
            label={'Additional Room Notes'}
            input={{
              placeholder: 'Enter additional notes',
              style: {
                width: responsive.mobile ? '100%' : 360,
              },
            }}
            style={{
              width: responsive.mobile ? '100%' : 360,
            }}
          />
          <Space height={20} />
          <Actions>
            <Button color={colors.blue.accentDark} loading={submitting} onPress={handleSubmit}>
              <Text color={colors.white}>Save Changes</Text>
            </Button>
          </Actions>
          <Space height={20} />
          <Line />
          <Space height={20} />
          <Actions>
            <Button
              color={colors.red.accent}
              onPress={() => {
                handleClose();
                handleOpenDelete();
              }}
            >
              <Text color={colors.red.warning}>Delete Room</Text>
            </Button>
          </Actions>
        </Container>
      </Modal>
      <DeleteRoomModal
        isOpen={isOpenDelete}
        room={room}
        handleClose={handleCloseDelete}
        handleCancel={() => {
          handleCloseDelete();
          handleOpen();
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateRoomModal.fragment = gql`
  ${RoomForm.edit.fragment}
  ${DeleteRoomModal.fragment}

  fragment UpdateRoomModal on Room {
    id
    roomType {
      id
      name
    }
    ...RoomForm_edit
    ...DeleteRoomModal
  }
`;

export default UpdateRoomModal;
