// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';

// App
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';

const ConfirmationModal = ({
  icon,
  title,
  subtitle,
  isOpen,
  handlePressOutside,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionText,
  secondaryActionText,
  color,
  isDisabled,
  isSecondaryDisabled,
}: any) => {
  return (
    <SuccessModal
      icon={icon}
      title={title}
      subtitle={subtitle}
      isOpen={isOpen}
      handlePressOutside={handlePressOutside}
      handlePrimaryAction={handlePrimaryAction}
      handleSecondaryAction={handleSecondaryAction}
      primaryActionText={primaryActionText}
      secondaryActionText={secondaryActionText}
      color={color}
      isDisabled={isDisabled}
      isSecondaryDisabled={isSecondaryDisabled}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  icon: Icon.SourcePropType,
  title: PropTypes.string.isRequired,
  handlePressOutside: PropTypes.func,
  handlePrimaryAction: PropTypes.func.isRequired,
  handleSecondaryAction: PropTypes.func,
  primaryActionText: PropTypes.string.isRequired,
  secondaryActionText: PropTypes.string,
  color: PropTypes.any,
  subtitle: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  icon: Icon.Question,
  handlePressOutside: () => {},
  handleSecondaryAction: null,
  secondaryActionText: '',
  color: null,
  subtitle: '',
};

export default ConfirmationModal;
