// Libraries
import _ from 'lodash';

const _new = ({codatIntegrationId}: any) => ({
  codatIntegrationId,
});

const toForm = ({codatIntegrationId}: any) => {
  return {
    codatIntegrationId: _.toString(codatIntegrationId),
  };
};

const toMutation = ({codatIntegrationId}: any) => {
  return {
    codatIntegrationId: codatIntegrationId ? _.toNumber(codatIntegrationId) : null,
  };
};

const CodatBatchExportForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CodatBatchExportForm;
