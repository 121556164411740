// Libraries
import React from 'react';

// Supermove
import {useToast} from '@supermove/hooks';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import useUnfinalizeBulkInvoicesMutation from '@shared/modules/Billing/hooks/useUnfinalizeBulkInvoicesMutation';

const UnfinalizeBulkInvoicesModal = ({
  invoiceIds,
  isOpen,
  handleClose,
  refetch,
  resetSelectedInvoices,
}) => {
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: `Invoice(s) unfinalized`,
    isClosable: true,
  });

  const {handleSubmit} = useUnfinalizeBulkInvoicesMutation({
    invoiceIds,
    onSuccess: () => {
      refetch();
      resetSelectedInvoices();
      handleClose();
      successToast.handleToast();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Unfinalize invoice(s)?`}
      subtitle={`Please note when unfinalizing invoices:\n${String.fromCharCode(
        0x2022,
      )} Some bill items may have been updated.\n${String.fromCharCode(
        0x2022,
      )} Please review the invoice line items.\n${String.fromCharCode(
        0x2022,
      )} The invoice will need to be manually finalized.`}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      deleteButtonText={'Unfinalize'}
      cancelButtonText={'Cancel'}
    />
  );
};

export default UnfinalizeBulkInvoicesModal;
