// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';

const CreateTaskSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  handleSubmitValidateWorkflow,
}) => {
  const {label, description} =
    WorkflowStepActionKind.ATTRIBUTES[WorkflowStepActionKind.CREATE_TASK];
  const field = `${workflowStepsField}.${workflowStepIndex}.createTaskFromTemplateForm`;
  const isDoneDisabled = !_.get(form.values, `${field}.taskTemplateId`);

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      isDoneDisabled={isDoneDisabled}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    >
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.taskTemplateId`}
        label={'Task Template'}
        isRequired
        input={{
          options: workflow.organization.officeTaskTemplates.map((taskTemplate) => ({
            value: _.toString(taskTemplate.id),
            label: taskTemplate.name,
          })),
          placeholder: 'Select a task template',
          isSearchable: true,
          isPortaled: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
    </WorkflowStepSlide>
  );
};

const CreateTaskDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  isPreview,
}) => {
  const field = `${workflowStepsField}.${workflowStepIndex}`;
  const taskTemplateId = _.get(form.values, `${field}.createTaskFromTemplateForm.taskTemplateId`);
  const taskTemplate = _.find(workflow.organization.officeTaskTemplates, [
    'id',
    _.toString(taskTemplateId),
  ]);
  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={taskTemplate ? `Create task: ${taskTemplate.name}` : ''}
      isPreview={isPreview}
    />
  );
};

const WorkflowStepCreateTask = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    workflow,
    handleSubmitValidateWorkflow,
    isPreview,
  }) => [
    <CreateTaskSlide
      key={'CREATE'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      workflow={workflow}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    />,
    <CreateTaskDisplaySlide
      key={'DISPLAY'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      workflow={workflow}
      isPreview={isPreview}
    />,
  ],
  fragment: gql`
    fragment WorkflowStepCreateTask on Workflow {
      id
      organization {
        id
        officeTaskTemplates {
          id
          name
        }
      }
    }
  `,
};

export default WorkflowStepCreateTask;
