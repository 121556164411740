// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';

const AssignToBranchSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  handleSubmitValidateWorkflow,
  label,
  description,
}) => {
  const field = `${workflowStepsField}.${workflowStepIndex}.assignToBranchActionForm`;
  const isDoneDisabled =
    !_.get(form.values, `${field}.variableId`) ||
    !_.get(form.values, `${field}.defaultOrganizationId`);

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      isDoneDisabled={isDoneDisabled}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    >
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.variableId`}
        label={'Variable For Branch Selection'}
        isRequired
        tooltip={'Choose a variable that outputs a branch identifier.'}
        input={{
          options: workflow.organization.projectTypeVariables.map((variable) => ({
            value: _.toString(variable.id),
            label: variable.name,
          })),
          placeholder: 'Select a variable',
          isSearchable: true,
          isPortaled: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.defaultOrganizationId`}
        label={'Default Branch'}
        isRequired
        input={{
          options: workflow.organization.allOrganizations.map((organization) => ({
            value: _.toString(organization.id),
            label: organization.name,
          })),
          placeholder: 'Select a default branch',
          isSearchable: true,
          isPortaled: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
    </WorkflowStepSlide>
  );
};

const AssignToBranchDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  isPreview,
  label,
}) => {
  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={label}
      isPreview={isPreview}
    />
  );
};

const WorkflowStepAssignToBranch = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    workflow,
    handleSubmitValidateWorkflow,
    isPreview,
  }) => {
    const workflowStepActionKind = _.get(
      form.values,
      `${workflowStepsField}[${workflowStepIndex}].workflowStepActionKind`,
    );
    const {label, description} = WorkflowStepActionKind.ATTRIBUTES[workflowStepActionKind];

    return [
      <AssignToBranchSlide
        key={workflowStepActionKind}
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        workflow={workflow}
        handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
        label={label}
        description={description}
      />,
      <AssignToBranchDisplaySlide
        key={'DISPLAY'}
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        workflow={workflow}
        isPreview={isPreview}
        label={label}
      />,
    ];
  },
  fragment: gql`
    fragment WorkflowStepAssignToBranch on Workflow {
      id
      organization {
        id
        projectTypeVariables {
          id
          name
        }
        allOrganizations {
          id
          name
        }
      }
    }
  `,
};

export default WorkflowStepAssignToBranch;
