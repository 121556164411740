/**
 * Component - v2.0.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';

// App
import CompleteJobMap from 'modules/Job/Dispatch/components/CompleteJobMap';
import PositionsList from 'modules/Job/Dispatch/components/PositionsList';

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Section = Styled.View`
  flex: 1;
`;

const getSortedPositions = ({positions}) => {
  return _.sortBy(positions, ['timestamp']);
};

const JobPositionsWithMap = ({job}) => {
  const [activePosition, setActivePosition] = useState(null);

  return (
    <Container>
      <Section>
        <ScrollView style={{flex: 1}}>
          <PositionsList
            activePosition={activePosition}
            positions={getSortedPositions({positions: job.positions})}
            onDeselectPosition={() => setActivePosition(null)}
            onSelectPosition={(position) => setActivePosition(position)}
          />
        </ScrollView>
      </Section>
      <Section>
        <CompleteJobMap
          activePosition={activePosition}
          locations={job.locations}
          positions={getSortedPositions({positions: job.positions})}
          warehouseLocation={job.project.owningOrganization.warehouseLocation}
          onDeselectPosition={() => setActivePosition(null)}
          onSelectPosition={(position) => setActivePosition(position)}
        />
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobPositionsWithMap.fragment = gql`
  ${CompleteJobMap.fragment}
  ${PositionsList.fragment}

  fragment JobPositionsWithMap on Job {
    id
    positions {
      id
      ...CompleteJobMap_Position
      ...PositionsList
    }
    locations {
      id
      ...CompleteJobMap_Location
    }
    project {
      id
      owningOrganization {
        id
        warehouseLocation {
          latitude
          longitude
        }
      }
    }
  }
`;

export default JobPositionsWithMap;
