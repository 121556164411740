// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Platform} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';

// App
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';

const Container = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  padding-vertical: ${({desktop}: any) => (desktop ? '8px' : '12px')};
  background-color: ${({color}: any) => color};
  border-radius: 4px;
  min-height: ${({desktop}: any) => (desktop ? '44px' : '60px')};
  width: ${({mobile}: any) => (mobile ? 'calc(100vw - 32px)' : 'auto')};
`;

const TextColumn = Styled.View`
  ${Platform.select({
    android: 'max-width: 80%;',
    default: 'flex: 1;',
  })}
`;

const Message = Styled.Text`
    ${Typography.Responsive.Body}
    color: ${colors.white};
`;

const ToastAction = ({handleRemove, handleAction, color, actionText}: any) => {
  const responsive = useResponsive();
  return (
    <SecondaryButton
      text={actionText}
      textColor={colors.white}
      isWidthOfContainer={responsive.mobile}
      isSmall
      isResponsive
      style={{
        backgroundColor: color,
        borderColor: colors.alpha(colors.white, 0.5),
      }}
      onPress={() => {
        handleRemove();
        handleAction();
      }}
    />
  );
};

const Toast = ({
  icon,
  message,
  actionText,
  handleAction,
  handleDismiss,
  handleRemove,
  isCloseable,
  color,
  style,
}: any) => {
  const responsive = useResponsive();

  return (
    <Container {...responsive} color={color} style={style}>
      <Space width={16} />
      {icon && (
        <React.Fragment>
          <Icon
            source={icon}
            size={16}
            color={colors.white}
            style={{marginTop: responsive.desktop ? 6 : 10}}
          />
          <Space width={8} />
        </React.Fragment>
      )}
      <TextColumn>
        <Space height={responsive.desktop ? 4 : 6} />
        <Message responsive={responsive}>{message}</Message>
        {!!actionText && responsive.mobile && (
          <React.Fragment>
            <Space height={12} />
            <ToastAction
              handleAction={handleAction}
              handleRemove={handleRemove}
              color={color}
              actionText={actionText}
            />
          </React.Fragment>
        )}
      </TextColumn>
      <Space width={8} />
      {!!actionText && !responsive.mobile && (
        <React.Fragment>
          <Space width={16} />
          <ToastAction
            handleAction={handleAction}
            handleRemove={handleRemove}
            color={color}
            actionText={actionText}
          />
        </React.Fragment>
      )}
      {isCloseable && (
        <React.Fragment>
          <Space width={8} />
          <QuaternaryButton
            onPress={handleDismiss}
            isSmall
            isResponsive
            style={{
              paddingHorizontal: 12,
            }}
          >
            <Icon source={Icon.Times} size={16} color={colors.white} />
          </QuaternaryButton>
        </React.Fragment>
      )}
      <Space width={isCloseable ? 8 : 16} />
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
Toast.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  handleAction: PropTypes.func,
  handleDismiss: PropTypes.func,
  handleRemove: PropTypes.func,
  color: PropTypes.string,
  isCloseable: PropTypes.bool,
  style: PropTypes.object,
};

Toast.defaultProps = {
  icon: null,
  actionText: null,
  handleAction: () => {},
  handleDismiss: () => {},
  handleRemove: () => {},
  color: colors.gray.primary,
  isCloseable: true,
  style: {},
};

export default Toast;
