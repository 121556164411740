// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Wrapper = Styled.View`
  margin-bottom: 10px;
  z-index: ${(props) => 100 - (props as any).index};
`;

const SectionBody = Styled.Text`
  margin-top: 5px;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.gray.primary};
`;

const DocumentV2Paragraph = ({paragraph, index}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Wrapper index={index}>
      <SectionBody>{paragraph}</SectionBody>
    </Wrapper>
  );
};

export default DocumentV2Paragraph;
