// Libararies
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import SheetWithFooter from '@shared/design/components/Sheet/SheetWithFooter';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import TagAssignmentForm from '@shared/modules/Tag/forms/TagAssignmentForm';
import useUpsertTagAssignmentMutation from '@shared/modules/Tag/hooks/useUpsertTagAssignmentMutation';
import {PageLoadingIndicator} from 'modules/App/components';
import TagAssignmentFields from 'modules/Tag/components/TagAssignmentFields';

const MobileBodyText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const UpsertProjectTagSheet = ({projectId, projectUuid, tagIds, isOpen, handleClose, refetch}) => {
  const tagAssignmentForm = TagAssignmentForm.new({projectId, tagIds});
  const {form, submitting, handleSubmit} = useUpsertTagAssignmentMutation({
    tagAssignmentForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  const {loading, data} = useQuery(UpsertProjectTagSheet.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
    },
    skip: !isOpen,
  });

  return (
    <SheetWithFooter
      headerText={`Edit Project Tags`}
      isOpen={isOpen}
      handleClose={handleClose}
      primaryActionText={'Save'}
      handlePrimaryAction={handleSubmit}
      isSubmitting={submitting}
      isFixedFooter
      shouldCloseOnClickOutside={!form.dirty}
    >
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => {
          const projectTags = data.project.organization.companySettings.tags.filter(
            (tag) => tag.category === TagCategory.PROJECT,
          );
          return (
            <React.Fragment>
              <MobileBodyText>Select from existing tags.</MobileBodyText>
              <Space height={16} />
              <TagAssignmentFields
                label={'Project Tags'}
                tags={projectTags}
                form={form}
                isDropdownPortaled={false}
              />
            </React.Fragment>
          );
        }}
      </Loading>
    </SheetWithFooter>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

UpsertProjectTagSheet.query = gql`
${TagAssignmentFields.fragment}

  query UpsertProjectTagSheet($projectUuid: String!) {
    ${gql.query}

    project(uuid: $projectUuid) {
      id
      organization {
        id
        companySettings {
          tags {
            id
            category
          }
        }
        ...TagAssignmentFields
      }
    }
  }
`;

export default UpsertProjectTagSheet;
