// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useToast} from '@supermove/hooks';
import {Location} from '@supermove/models';
import {colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import Toast from '@shared/design/components/Toast';
import ProjectLocationsForm from '@shared/modules/Project/forms/ProjectLocationsForm';
import useDeleteProjectLocationsMutation from '@shared/modules/Project/hooks/useDeleteProjectLocationsMutation';

const DELETE_MESSAGE =
  'Deleting this location will remove it from the project. This cannot be undone.';

const getUsedMessage = ({location}) => {
  const {jobs} = location;
  const locationJobsList = jobs.reduce((result, job) => `${result}\n•  ${job.shortName}`, '');
  return `Cannot delete this location because it is\nbeing used on ${pluralize(
    'job',
    jobs.length,
    true,
  )}:${locationJobsList}`;
};

const getActionModalProps = ({location, handleDelete, handleSubmit, submitting, handleClose}) => {
  const isLocationUsed = _.some(location.jobs);

  // Closing the modal was triggering the press action behind the modal. Using a prevent
  // propagation wrapper was also not working. The behavior seems to close the modal and
  // then trigger the press action on the button behind the modal, so attempting to catch
  // the press event has no effect. This allows the press event to be contained, and then
  // closes the modal after the press event has been handled.
  const handleAsyncClose = () => setTimeout(handleClose, 0);

  // Only unused locations can be deleted
  return {
    color: isLocationUsed ? colors.orange.status : colors.red.warning,
    icon: isLocationUsed ? Icon.ExclamationTriangle : Icon.Trash,
    title: isLocationUsed ? 'Cannot delete location.' : 'Delete location?',
    message: isLocationUsed ? getUsedMessage({location}) : DELETE_MESSAGE,
    handlePrimaryAction: isLocationUsed ? handleAsyncClose : handleDelete || handleSubmit,
    primaryActionText: isLocationUsed ? 'Close' : 'Delete',
    handleSecondaryAction: isLocationUsed ? null : handleAsyncClose,
    secondaryActionText: isLocationUsed ? '' : 'Cancel',
    isSubmitting: submitting,
  };
};

const DeleteProjectLocationModal = ({
  projectId,
  locationId,
  isOpen,
  handleClose,
  handleDelete,
  refetch,
}) => {
  const deleteToast = useToast({
    ToastComponent: Toast,
    message: 'Location deleted',
  });

  const {data} = useQuery(DeleteProjectLocationModal.query, {
    fetchPolicy: 'network-only',
    variables: {locationId},
    skip: !isOpen,
  });

  const projectLocationsForm = ProjectLocationsForm.delete({projectId, locationIds: [locationId]});
  const {handleSubmit, submitting} = useDeleteProjectLocationsMutation({
    projectLocationsForm,
    onSuccess: () => {
      handleClose();
      deleteToast.handleToast();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  const location = data?.location;

  return (
    <ActionModal
      isLoading={!location}
      isOpen={isOpen}
      {...(!location
        ? {}
        : getActionModalProps({location, handleDelete, handleSubmit, submitting, handleClose}))}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteProjectLocationModal.query = gql`
  ${Location.getDisplayAddressCityStateZip.fragment}

  query DeleteProjectLocationModal($locationId: Int!) {
    ${gql.query}
    location(locationId: $locationId) {
      id
      name
      jobs {
        id
        shortName
      }
      ...Location_getDisplayAddressCityStateZip
    }
  }
`;

export default DeleteProjectLocationModal;
