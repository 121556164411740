// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import TeamForm from '@shared/modules/TaskManagement/forms/TeamForm';

const useUpdateTeamMutation = ({teamForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      teamForm: TeamForm.toForm(teamForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateTeamMutation.mutation,
    variables: {
      teamForm: TeamForm.toMutation(form.values.teamForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateTeamMutation.mutation = gql`
  mutation UpdateTeamMutation($teamForm: TeamForm!) {
    response: updateTeam(teamForm: $teamForm) {
      ${gql.errors}
      team {
        id
      }
    }
  }
`;

export default useUpdateTeamMutation;
