// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {SidebarV1, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import PageHeader from 'modules/App/components/PageHeader';
import SidebarItems from 'modules/App/components/Sidebar/components/SidebarItems';
import EmployeeSidebar from 'modules/Employee/components/EmployeeSidebar';

const Container = Styled.View`
  flex: 1;
  flex-direction: ${({mobile}) => (mobile ? 'column' : 'row')};
`;

const EmployeePage = ({selected, children, HeaderComponent}) => {
  const responsive = useResponsive();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const {data} = useQuery(EmployeePage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (responsive.mobile) {
    return (
      <SidebarV1
        isOpen={isSidebarOpen}
        position={'right'}
        onOpen={() => setIsSidebarOpen(true)}
        onClose={() => setIsSidebarOpen(false)}
        content={
          <SidebarItems
            selectedItem={selected}
            viewer={_.get(data, 'viewer', {})}
            onSidebarClose={() => setIsSidebarOpen(false)}
          />
        }
        styles={{sidebar: {background: colors.black}}}
      >
        <Container mobile>
          <PageHeader
            viewer={_.get(data, 'viewer', {})}
            onSidebarOpen={() => setIsSidebarOpen(true)}
            HeaderComponent={HeaderComponent}
          />
          {children}
        </Container>
      </SidebarV1>
    );
  }

  return (
    <Container {...responsive}>
      <EmployeeSidebar selected={selected} />
      {children}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmployeePage.query = gql`
  ${PageHeader.fragment}
  ${SidebarItems.fragment}

  query EmployeePage {
    ${gql.query}

    viewer {
      id
      role
      ...PageHeader
      ...SidebarItems_viewer
    }
  }
`;

export default EmployeePage;
