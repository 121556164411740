import {ExecutionResult, MutationFunctionOptions} from 'react-apollo';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {MutationError, Form, MutationResponse} from '@supermove/hooks/src/forms/types';

// App
import PayengineCreditCardForm, {
  PayengineCreditCardFormInterface,
} from '@shared/modules/Payment/forms/PayengineCreditCardForm';

export type UpdatePayengineCreditCardForm = {
  payengineCreditCardForm: PayengineCreditCardFormInterface;
};

export type UpdatePayengineCreditCardResponse = {
  payengineCreditCard: {
    id: number;
  };
};
type GraphQLResponse = MutationResponse<UpdatePayengineCreditCardResponse>;

type Args = {
  payengineCreditCardForm: PayengineCreditCardFormInterface;
  onSuccess: (response: UpdatePayengineCreditCardResponse) => void;
  onError: (errors: MutationError[]) => void;
};

type Result = {
  form: Form<UpdatePayengineCreditCardForm>;
  submitting: boolean | undefined;
  handleSubmit: (
    options?: MutationFunctionOptions<GraphQLResponse, UpdatePayengineCreditCardForm>,
  ) => Promise<ExecutionResult<GraphQLResponse>>;
};

const useUpdatePayengineCreditCardMutation = ({
  payengineCreditCardForm,
  onSuccess,
  onError,
}: Args): Result => {
  const form = useForm<UpdatePayengineCreditCardForm>({
    initialValues: {
      payengineCreditCardForm,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdatePayengineCreditCardMutation.mutation,
    variables: {
      payengineCreditCardForm: PayengineCreditCardForm.toMutation(
        form.values.payengineCreditCardForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdatePayengineCreditCardMutation.mutation = gql`
  mutation useUpdatePayengineCreditCardMutation(
    $payengineCreditCardForm: PayengineCreditCardForm!
  ) {
    response: updatePayengineCreditCard(
      payengineCreditCardForm: $payengineCreditCardForm,
    ) {
      ${gql.errors}
      payengineCreditCard {
        id
      }
    }
  }
`;

export default useUpdatePayengineCreditCardMutation;
