// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import ClaimForm from '@shared/modules/Claim/forms/ClaimForm';
import useDeleteClaimMutation from '@shared/modules/Claim/hooks/useDeleteClaimMutation';

// TODO(Hammad) rename file and component to DeleteClaimModal in a separate PR, to be safe
const RemoveClaimModal = ({claimId, isOpen, handleClose, onSuccess}) => {
  const claimForm = ClaimForm.delete({claimId});
  const {submitting, handleSubmit} = useDeleteClaimMutation({
    claimForm,
    onSuccess: () => {
      onSuccess();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Delete claim?'}
      subtitle={"This claim will no longer exist. You can't undo this action."}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
      deleteButtonText={'Delete'}
    />
  );
};

export default RemoveClaimModal;
