// Supermove
import {colors} from '@supermove/styles';

const ACTIVE = 'ACTIVE';
const PENDING = 'PENDING';
const PLANNED = 'PLANNED';
const IN_TRANSIT = 'IN_TRANSIT';
const COMPLETED = 'COMPLETED';
const CANCELLED = 'CANCELLED';

const ACTIVE_OPTION = {
  value: 'ACTIVE',
  label: 'Active',
};

const PENDING_OPTION = {
  value: 'PENDING',
  label: 'Pending',
  backgroundColor: colors.red.accent,
  textColor: colors.red.warning,
};

const PLANNED_OPTION = {
  value: 'PLANNED',
  label: 'Planned',
  backgroundColor: colors.blue.accent,
  textColor: colors.gray.primary,
};

const IN_TRANSIT_OPTION = {
  value: 'IN_TRANSIT',
  label: 'In Transit',
  backgroundColor: colors.blue.accent,
  textColor: colors.blue.interactive,
};

const COMPLETED_OPTION = {
  value: 'COMPLETED',
  label: 'Completed',
  backgroundColor: colors.green.accent,
  textColor: colors.green.status,
};

const CANCELLED_OPTION = {
  value: 'CANCELLED',
  label: 'Cancelled',
  backgroundColor: colors.gray.background,
  textColor: colors.gray.secondary,
};

const OPTIONS = [
  PENDING_OPTION,
  PLANNED_OPTION,
  IN_TRANSIT_OPTION,
  COMPLETED_OPTION,
  CANCELLED_OPTION,
];

const STATUS_OPTION_MAP = {
  [ACTIVE]: ACTIVE_OPTION,
  [PENDING]: PENDING_OPTION,
  [PLANNED]: PLANNED_OPTION,
  [IN_TRANSIT]: IN_TRANSIT_OPTION,
  [COMPLETED]: COMPLETED_OPTION,
  [CANCELLED]: CANCELLED_OPTION,
};

const getStatusOption = (status: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return STATUS_OPTION_MAP[status];
};

const TripStatus = {
  OPTIONS,

  ACTIVE,
  PENDING,
  PLANNED,
  IN_TRANSIT,
  COMPLETED,
  CANCELLED,

  getStatusOption,
};

export default TripStatus;
