// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, useToast, useResponsive, ResponsiveType} from '@supermove/hooks';
import {ClaimModel, ProjectModel} from '@supermove/models';
import {Currency, Datetime, pluralize} from '@supermove/utils';

// App
import Table, {TableComponents} from '@shared/design/components/Table';
import TextTooltip from '@shared/design/components/TextTooltip';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import StatusBadge from 'modules/App/components/StatusBadge';
import ClaimActionsButton from 'modules/Project/Claim/components/ClaimActionsButton';
import EditProjectClaimDrawer from 'modules/Project/Claim/components/EditProjectClaimDrawer';
import RemoveClaimModal from 'modules/Project/Claim/components/RemoveClaimModal';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ClaimTypeColor = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({color}: {color: string}) => color};
`;

const sortClaims = (claims: ClaimModel[]) => {
  return _.orderBy(claims, ['createdAt']);
};

const ClaimStatusBadge = ({label, color}: {label: string; color: string}) => {
  return (
    <Row style={{flex: 1, overflow: 'hidden'}}>
      {/* @ts-expect-error */}
      <StatusBadge color={color} label={label} size={StatusBadge.SIZE.LARGE} />
    </Row>
  );
};

const ClaimStatus = ({claim}: {claim: ClaimModel}) => {
  const {name, color} = claim.claimStatus;
  return <ClaimStatusBadge label={name} color={color} />;
};

const DeleteClaim = ({
  claim,
  refetch,
  isOpen,
  handleClose,
  hookKey,
}: {
  claim: ClaimModel;
  refetch: () => void;
  isOpen: boolean;
  handleClose: () => void;
  hookKey: string;
}) => {
  const removeClaimSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: `Claim ${claim.identifier} deleted`,
    isCloseable: true,
  });
  return (
    <RemoveClaimModal
      key={hookKey}
      claimId={claim.id}
      isOpen={isOpen}
      handleClose={handleClose}
      onSuccess={() => {
        removeClaimSuccessToast.handleToast();
        refetch();
      }}
    />
  );
};

const getColumnDefinitions = ({
  project,
  refetch,
  responsive,
}: {
  project: ProjectModel;
  refetch: () => void;
  responsive: ResponsiveType;
}) => {
  return [
    // Status
    {
      flex: 2,
      headerLabel: 'Status',
      cellComponent: (claim: ClaimModel) => <ClaimStatus claim={claim} />,
    },
    // Claim ID and Type
    {
      flex: 3,
      headerLabel: 'Claim ID',
      cellText: (claim: ClaimModel) => `Claim ${claim.identifier}`,
      secondary: {
        headerLabel: 'Type',
        cellComponent: (claim: ClaimModel) => <ClaimTypeColor color={claim.claimType.color} />,
        cellText: (claim: ClaimModel) => claim.claimType.name,
      },
      mobileOptions: {
        isInHeader: true,
        rank: 0,
      },
    },
    // Claim Amounts
    // TODO(Hammad): Remove the extra code once we add support for labels in mobile card headers
    {
      flex: 3,
      headerLabel: 'Actual Amount',
      cellText: (claim: ClaimModel) => {
        const amountText = _.isNil(claim.amount) ? null : Currency.display(claim.amount);
        if (responsive.desktop) {
          return amountText;
        } else {
          return _.isNil(amountText) ? null : `Actual ${amountText}`;
        }
      },
      emptyText: responsive.desktop ? 'TBD' : 'Actual TBD',
      secondary: {
        headerLabel: 'Estimated Amount',
        cellText: (claim: ClaimModel) => {
          const estimatedAmountText = _.isNil(claim.estimatedAmount)
            ? null
            : Currency.display(claim.estimatedAmount);
          if (responsive.desktop) {
            return estimatedAmountText;
          } else {
            return _.isNil(estimatedAmountText) ? null : `Estimated ${estimatedAmountText}`;
          }
        },
        emptyText: responsive.desktop ? 'None' : 'Estimated None',
      },
      mobileOptions: {
        isInHeader: true,
        rank: 1,
      },
    },
    // Claim Job and Movers
    {
      flex: 3,
      headerLabel: 'Job',
      cellText: (claim: ClaimModel) => claim.job?.fullName,
      secondary: {
        headerLabel: 'Movers',
        cellComponent: (claim: ClaimModel) => {
          const moversText = _.isEmpty(claim.activeUsers)
            ? null
            : pluralize('movers', claim.activeUsers.length, true);
          const moversTooltip = claim.activeUsers.map((user) => user.fullName).join(', ');

          return moversText ? (
            <TextTooltip
              text={moversTooltip}
              placement='top'
              numberOfLines={1}
              style={{maxWidth: 200}}
            >
              <Container>
                <TableComponents.SecondaryText responsive={responsive} numberOfLines={1}>
                  {moversText}
                </TableComponents.SecondaryText>
              </Container>
            </TextTooltip>
          ) : null;
        },
      },
    },
    // Created and Closed at
    {
      flex: 2,
      headerLabel: 'Created At',
      cellText: (claim: ClaimModel) =>
        claim.createdAt
          ? Datetime.convertToDisplayDate(claim.createdAt, Datetime.DISPLAY_SHORT_DATE)
          : null,
      secondary: {
        headerLabel: 'Closed At',
        cellText: (claim: ClaimModel) =>
          claim.closedDate
            ? Datetime.convertToDisplayDate(claim.closedDate, Datetime.DISPLAY_SHORT_DATE)
            : null,
        emptyText: 'TBD',
      },
    },
    // Last Action
    {
      flex: 4,
      headerLabel: 'Last Action',
      cellText: (claim: ClaimModel) => (claim.isEdited ? 'Claim updated' : 'Claim created'),
      secondary: {
        cellText: (claim: ClaimModel) =>
          `${Datetime.convertToDisplayDatetime(claim.updatedAt, 'MM/DD/YY, h:mm A')} by ${claim.updatedBy.fullName}`,
      },
    },
    // Actions
    {
      headerLabel: 'Actions',
      minWidth: 80,
      actions: (claim: ClaimModel) => [
        {
          text: 'Edit Claim',
          onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
          desktopIcon: Icon.Pen,
          actionHook: {
            hook: useDrawer,
            hookArgument: {
              name: 'Edit Claim Drawer',
            },
            renderComponent: ({
              isOpen,
              handleClose,
              hookKey,
            }: {
              isOpen: boolean;
              handleClose: () => void;
              hookKey: string;
            }) => {
              return (
                // @ts-expect-error
                <EditProjectClaimDrawer
                  key={hookKey}
                  claim={claim}
                  projectUuid={project.uuid}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  onSuccess={() => {
                    handleClose();
                    refetch();
                  }}
                />
              );
            },
          },
        },
        {
          text: 'Delete Claim',
          onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
          actionHook: {
            hook: useModal,
            hookArgument: {
              name: 'Delete Claim Modal',
            },
            renderComponent: ({
              isOpen,
              handleClose,
              hookKey,
            }: {
              isOpen: boolean;
              handleClose: () => void;
              hookKey: string;
            }) => (
              <DeleteClaim
                claim={claim}
                refetch={refetch}
                isOpen={isOpen}
                handleClose={handleClose}
                hookKey={hookKey}
              />
            ),
          },
        },
      ],
    },
  ];
};

const ProjectClaimsV2 = ({project, refetch}: {project: ProjectModel; refetch: () => void}) => {
  const responsive = useResponsive();

  return (
    <Table
      columnDefinitions={getColumnDefinitions({
        project,
        refetch,
        responsive,
      })}
      emptyStateText={'There are no claims for this project'}
      items={sortClaims(project.claims)}
      itemKey={'id'}
      hasBorder={!responsive.desktop}
      // @ts-expect-error
      onRowPress={(item, {handleOpen}: {handleOpen: () => void}) => handleOpen()}
      rowHook={{
        hook: useDrawer,
        hookArgument: {name: 'Edit Claim Drawer'},
        renderComponent: ({isOpen, handleClose, hookKey, item}) => (
          // @ts-expect-error
          <EditProjectClaimDrawer
            key={hookKey}
            claim={item}
            projectUuid={project.uuid}
            isOpen={isOpen}
            handleClose={handleClose}
            onSuccess={() => {
              handleClose();
              refetch();
            }}
            isViewMode
          />
        ),
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectClaimsV2.fragment = gql`
  ${ClaimActionsButton.fragment}
  ${EditProjectClaimDrawer.fragment}

  fragment ProjectClaimsV2 on Project {
    ${gql.query}
    id
    uuid
    claims {
      id
      identifier
      amount
      estimatedAmount
      closedDate
      createdAt
      updatedAt
      isEdited
      createdAt
      claimStatus {
        id
        name
        color
      }
      claimType {
        id
        name
        color
      }
      job {
        id
        fullName
      }
      activeUsers {
        id
        fullName
      }
      updatedBy {
        id
        fullName
      }
      ...ClaimActionsButton
      ...EditProjectClaimDrawer
    }
  }
`;

export default ProjectClaimsV2;
