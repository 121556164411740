// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useQuery,
  useNavigationDOM,
  useState,
  useModal,
  useResponsive,
  useSheet,
  useTouch,
} from '@supermove/hooks';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import useUpdateInventoryRoomsMutation from '@shared/modules/Inventory/hooks/useUpdateInventoryRoomsMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import EditInventoryPageHeader from 'modules/Inventory/Edit/components/EditInventoryPageHeader';
import EditInventoryPageSidebar from 'modules/Inventory/Edit/components/EditInventoryPageSidebar';
import EditInventoryRoomsSection from 'modules/Inventory/Edit/components/EditInventoryRoomsSection';
import ResponsiveInventoryHeader from 'modules/Inventory/Edit/components/ResponsiveInventoryHeader';
import ResponsiveInventoryItemLibrary from 'modules/Inventory/Edit/components/ResponsiveInventoryItemLibrary';

const EditInventoryPageBody = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const EditInventoryPageContainer = Styled.View`
  flex-direction: column;
  flex: 1;
`;

const handleInventoryRoomsFormResetIsDirty = ({form}) => {
  if (_.get(form.values, 'inventoryRoomsForm.isDirty')) {
    form.setFieldValue('inventoryRoomsForm.isDirty', false);
  }
  // Set all forms to isDirty false after successfully submitting the data
  const roomItemsForms = _.get(form.values, 'inventoryRoomsForm.roomItemsForms');
  roomItemsForms.forEach((roomItemsForm, roomItemsFormIndex) => {
    if (roomItemsForm.isDirty) {
      form.setFieldValue(`inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.isDirty`, false);
    }
    roomItemsForm.itemForms.forEach((itemForm, itemFormIndex) => {
      if (itemForm.isDirty) {
        form.setFieldValue(
          `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${itemFormIndex}.isDirty`,
          false,
        );
      }
    });
  });
};

const EditInventoryPageV2Content = ({inventory, refetch}) => {
  const {isEnabledResponsiveSurveysForDesktop} = inventory.project.organization.features;
  const responsive = useResponsive();
  const {isTouch} = useTouch();
  // All touchable devices should have the responsive survey experience
  const [isResponsiveSurveysVisible, setIsResponsiveSurveysVisible] = useState(
    !responsive.desktop || isTouch,
  );
  // Show the responsive sidebar and header for responsive surveys (mobile, tablet, touchable) and for desktop if the feature is enabled
  const isResponsiveInventoryHeaderAndLibraryVisible =
    isResponsiveSurveysVisible || (isEnabledResponsiveSurveysForDesktop && responsive.desktop);
  const updateInventoryRoomsErrorModal = useModal({name: 'Update Inventory Rooms Error Modal'});
  const inventoryRoomsForm = InventoryRoomsForm.edit(inventory, {
    selectedRoomIndex: isResponsiveSurveysVisible ? -1 : 0,
  });
  const [copyInventoryRoomsForm, setCopyInventoryRoomsForm] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [filteredCategoryIds, setFilteredCategoryIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeRoomRef, setActiveRoomRef] = useState(null);
  const [itemTypeKindFilters, setItemTypeKindFilters] = useState([
    ItemTypeKind.ITEM,
    ItemTypeKind.CARTON,
  ]);
  const mobileInventoryLibrarySheet = useSheet({name: 'Mobile Inventory Library Sheet'});
  const {form, handleSubmit, submitting} = useUpdateInventoryRoomsMutation({
    inventoryRoomsForm,
    onSuccess: () => {
      setIsSaving(false);
      refetch();
    },
    onError: (error) => {
      setIsSaving(false);
      updateInventoryRoomsErrorModal.handleOpen();
    },
  });
  const handleScrollRoomToBottom = () => {
    if (activeRoomRef && activeRoomRef.current) {
      if (isResponsiveSurveysVisible) {
        const index = Math.floor(
          (activeRoomRef.current.props.data.length - 1) / activeRoomRef.current.props.numColumns,
        );
        setTimeout(() => {
          activeRoomRef.current.scrollToIndex({index, animated: true});
        }, 0);
      } else {
        setTimeout(
          () => activeRoomRef.current.scrollToItem(activeRoomRef.current.props.itemCount),
          0,
        );
      }
    }
  };
  return (
    <EditInventoryPageContainer>
      {isResponsiveInventoryHeaderAndLibraryVisible ? (
        <ResponsiveInventoryHeader
          inventoryRoomsForm={form}
          inventory={inventory}
          handleSubmit={() => {
            setCopyInventoryRoomsForm(_.cloneDeep(form.values.inventoryRoomsForm));
            handleSubmit();
            handleInventoryRoomsFormResetIsDirty({form});
          }}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          isDesktopNonTouchDevice={responsive.desktop && !isTouch}
          isResponsiveSurveysVisible={isResponsiveSurveysVisible}
          toggleIsResponsiveSurveysVisible={() =>
            setIsResponsiveSurveysVisible(!isResponsiveSurveysVisible)
          }
        />
      ) : (
        <EditInventoryPageHeader
          inventoryRoomsForm={form}
          inventory={inventory}
          handleSubmit={() => {
            setCopyInventoryRoomsForm(_.cloneDeep(form.values.inventoryRoomsForm));
            handleSubmit();
            handleInventoryRoomsFormResetIsDirty({form});
          }}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      <EditInventoryPageBody>
        {isResponsiveInventoryHeaderAndLibraryVisible ? (
          <ResponsiveInventoryItemLibrary
            inventoryLibraryUuid={inventory.project.projectType.defaultInventoryLibrary.uuid}
            inventoryRoomsForm={form}
            filteredCategoryIds={filteredCategoryIds}
            setFilteredCategoryIds={setFilteredCategoryIds}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setItemTypeKindFilters={setItemTypeKindFilters}
            handleScrollRoomToBottom={handleScrollRoomToBottom}
            activeRoomRef={activeRoomRef}
            isTouchDevice={isTouch}
            mobileInventoryLibrarySheet={mobileInventoryLibrarySheet}
          />
        ) : (
          <EditInventoryPageSidebar
            inventoryLibraryUuid={inventory.project.projectType.defaultInventoryLibrary.uuid}
            inventoryRoomsForm={form}
            filteredCategoryIds={filteredCategoryIds}
            setFilteredCategoryIds={setFilteredCategoryIds}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            activeRoomRef={activeRoomRef}
            setItemTypeKindFilters={setItemTypeKindFilters}
          />
        )}
        <EditInventoryRoomsSection
          isResponsiveSurveysVisible={isResponsiveSurveysVisible}
          inventoryRoomsForm={form}
          setFilteredCategoryIds={setFilteredCategoryIds}
          setSearchTerm={setSearchTerm}
          setActiveRoomRef={setActiveRoomRef}
          itemTypeKindFilters={itemTypeKindFilters}
          setItemTypeKindFilters={setItemTypeKindFilters}
          handleScrollRoomToBottom={handleScrollRoomToBottom}
          mobileInventoryLibrarySheet={mobileInventoryLibrarySheet}
        />
      </EditInventoryPageBody>
      <DeleteModal
        title={'Changes could not be saved.'}
        subtitle={'The page will reload and your changes will be lost.'}
        cancelButtonText={`Don't Save`}
        deleteButtonText={'Try Again'}
        isOpen={updateInventoryRoomsErrorModal.isOpen}
        handleClose={() => {
          updateInventoryRoomsErrorModal.handleClose();
          // Reload the page to reset everything to the original state
          window.location.reload();
        }}
        handleDelete={() => {
          form.setFieldValue('inventoryRoomsForm', copyInventoryRoomsForm);
          setTimeout(handleSubmit, 0);
        }}
        isSubmitting={submitting}
      />
    </EditInventoryPageContainer>
  );
};

const EditInventoryPageV2 = () => {
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(EditInventoryPageV2.query, {
    fetchPolicy: 'network-only',
    variables: {
      inventoryUuid: params.inventoryUuid,
    },
  });

  return (
    <SidebarPageV2 selected={'moves'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return <EditInventoryPageV2Content inventory={data.inventory} refetch={refetch} />;
        }}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditInventoryPageV2.query = gql`
  ${EditInventoryPageHeader.fragment}
  ${ResponsiveInventoryHeader.fragment}
  ${InventoryRoomsForm.edit.fragment}

  query EditInventoryPageV2($inventoryUuid: String!, $jobUuids: [String]) {
    ${gql.query}
    inventory(uuid: $inventoryUuid) {
      id
      project {
        id
        projectType {
          id
          defaultInventoryLibrary {
            id
            uuid
          }
        }
        organization {
          id
          features {
            isEnabledResponsiveSurveysForDesktop: isEnabled(feature: "RESPONSIVE_SURVEYS_FOR_DESKTOP")
          }
        }
      }
      ...InventoryRoomsForm_edit
      ...EditInventoryPageHeader
      ...ResponsiveInventoryHeader
    }
  }
`;

export default EditInventoryPageV2;
