// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (project) => ({
    projectId: (project as any).id,
    bookedById: (project as any).bookedById,
    additionalSalespersonIds: (project as any).additionalSalespersonIds,
    coordinatedById: (project as any).coordinatedById,
    referralSource: (project as any).referralSource,
    referralDetails: (project as any).referralDetails,
  }),
  gql`
    fragment ProjectInternalInformationForm_edit on Project {
      id
      bookedById
      additionalSalespersonIds
      coordinatedById
      referralSource
      referralDetails
    }
  `,
);

const toForm = ({
  projectId,
  bookedById,
  additionalSalespersonIds,
  coordinatedById,
  referralSource,
  referralDetails,
}: any) => ({
  projectId,
  bookedById,
  additionalSalespersonIds,
  coordinatedById,
  referralSource,
  referralDetails,
});

const toMutation = ({
  projectId,
  bookedById,
  additionalSalespersonIds,
  coordinatedById,
  referralSource,
  referralDetails,
}: any) => ({
  projectId,
  bookedById: bookedById === '' ? null : bookedById,
  additionalSalespersonIds,
  coordinatedById: coordinatedById === '' ? null : coordinatedById,
  referralSource,
  referralDetails,
});

const ProjectInternalInformationForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectInternalInformationForm;
