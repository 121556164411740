// Libararies
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StorageProjectPageContent from 'modules/Storage/components/StorageProjectPageContent';
import StorageProjectPageHeader from 'modules/Storage/components/StorageProjectPageHeader';

const Container = Styled.View`
  flex: 1;
`;

const StorageProjectPageContentContainer = Styled.View`
  background-color: ${colors.gray.background};
  flex: 1;
`;

const StorageProjectPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(StorageProjectPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: params.projectUuid,
    },
  });

  return (
    <SidebarPageV2 selected={'storage'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <Container>
              <StorageProjectPageHeader project={data.project} />
              <StorageProjectPageContentContainer>
                <StorageProjectPageContent project={data.project} refetch={refetch} />
              </StorageProjectPageContentContainer>
            </Container>
          );
        }}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageProjectPage.query = gql`
  ${StorageProjectPageHeader.fragment}
  ${StorageProjectPageContent.fragment}

  query StorageProjectPage($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      ...StorageProjectPageHeader
      ...StorageProjectPageContent
    }
  }
`;

export default StorageProjectPage;
