// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Formula, Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillItemTypeForm from '@shared/modules/Billing/forms/BillItemTypeForm';
import useCreateBillItemTypeMutation from '@shared/modules/Billing/hooks/useCreateBillItemTypeMutation';
import UserRole from '@shared/modules/User/enums/UserRole';
import AccountingItemField from 'modules/Organization/Settings/BillingLibraries/components/AccountingItemField';
import BillItemTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/BillItemTypeDrawer';
import PerPositionRateFields from 'modules/Organization/Settings/BillingLibraries/components/PerPositionRateFields';

const Row = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex-direction: row;
`;

const Label = Styled.Text`
  ${Typography.Body3}
`;

const LeftToggleButton = Styled.ButtonV2`
  flex: 1;
  align-items: center;
  padding-vertical: 12px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-width: 1px;
  border-right-width: 0px;
  border-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.tertiary)};
  background-color: ${({isSelected, disabled}) =>
    isSelected ? colors.blue.accent : disabled ? colors.gray.border : colors.white};
`;

const RightToggleButton = Styled.ButtonV2`
  flex: 1;
  align-items: center;
  padding-vertical: 12px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-width: 1px;
  border-left-width: 0px;
  border-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.tertiary)};
  background-color: ${({isSelected, disabled}) =>
    isSelected ? colors.blue.accent : disabled ? colors.gray.border : colors.white};
`;

const ToggleDivider = Styled.View`
  border-right-width: 1px;
  border-color: ${colors.blue.interactive};
`;

const ToggleButtonText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const getHeaderText = (category) => {
  if (category === BillItemTypeCategory.SUPPLIES) {
    return 'Add Supply';
  }
  if (category === BillItemTypeCategory.FEES) {
    return 'Add Fee';
  }
  return 'Add Discount';
};

const getUnitOptions = (category) => {
  const unitOptions = [
    {label: '$', value: BillItemUnit.DOLLAR},
    {label: '%', value: BillItemUnit.PERCENT},
  ];
  if (category === BillItemTypeCategory.FEES) {
    unitOptions.push({label: '/ hour', value: BillItemUnit.HOUR});
  }
  return unitOptions;
};

const handleChangeUnit = ({unit, form, field}) => {
  if (unit === BillItemUnit.PERCENT) {
    form.setFieldValue(`${field}.billStage`, BillStage.POST_SUBTOTAL);
    form.setFieldValue(`${field}.amount`, '');
    // PERCENT is not allowed to have per mover position rates
    resetPerMoverPositionFields({form, field});
  } else if (unit === BillItemUnit.HOUR) {
    form.setFieldValue(`${field}.billStage`, BillStage.PRE_SUBTOTAL);
    form.setFieldValue(`${field}.percentage`, '');
  } else {
    form.setFieldValue(`${field}.percentage`, '');
  }
};

const ValueField = ({form, field, fieldIndex, formulaOptions, category, isDisabled}) => {
  const unit = _.get(form.values, `${field}.unit`);
  const isUnitPercent = unit === BillItemUnit.PERCENT;
  return (
    <Row index={fieldIndex}>
      {isUnitPercent ? (
        <BillItemTypeDrawer.PercentField
          form={form}
          field={field}
          formulaOptions={formulaOptions}
          isDisabled={isDisabled}
        />
      ) : (
        <BillItemTypeDrawer.AmountField
          form={form}
          field={field}
          formulaOptions={formulaOptions}
          isDisabled={isDisabled}
        />
      )}
      <Space width={8} />
      <FieldInput
        {...form}
        label={'Unit'}
        name={`${field}.unit`}
        component={DropdownInput}
        input={{
          options: getUnitOptions(category),
          onChangeValue: (value) => handleChangeUnit({unit: value, form, field}),
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          style: {width: 116},
        }}
      />
    </Row>
  );
};

const BillStageField = ({form, field, fieldIndex}) => {
  const billStage = _.get(form.values, `${field}.billStage`);
  const isBefore = billStage === BillStage.PRE_SUBTOTAL;
  const unit = _.get(form.values, `${field}.unit`);
  const isUnitPercent = unit === BillItemUnit.PERCENT;
  const isUnitHour = unit === BillItemUnit.HOUR;

  return (
    <React.Fragment>
      <Label>Before / After Subtotal</Label>
      <Space height={4} />
      <Row index={fieldIndex}>
        <LeftToggleButton
          isSelected={isBefore}
          onPress={() => form.setFieldValue(`${field}.billStage`, BillStage.PRE_SUBTOTAL)}
          disabled={isUnitPercent}
        >
          <ToggleButtonText
            style={isBefore ? {fontWeight: '700', color: colors.blue.interactive} : null}
          >
            Before Subtotal
          </ToggleButtonText>
        </LeftToggleButton>
        <ToggleDivider />
        <RightToggleButton
          isSelected={!isBefore}
          onPress={() => {
            form.setFieldValue(`${field}.billStage`, BillStage.POST_SUBTOTAL);
            resetPerMoverPositionFields({form, field});
            form.setFieldValue(`${field}.isTaxable`, false);
          }}
          disabled={isUnitHour}
        >
          <ToggleButtonText
            style={!isBefore ? {fontWeight: '700', color: colors.blue.interactive} : null}
          >
            After Subtotal
          </ToggleButtonText>
        </RightToggleButton>
      </Row>
    </React.Fragment>
  );
};

const resetPerMoverPositionFields = ({form, field}) => {
  form.setFieldValue(`${field}.isEnabledPerPositionRates`, false);
  form.setFieldValue(`${field}.billItemTypeMoverPositionRateForms`, []);
  form.setFieldValue(`${field}.moverPositionIds`, []);
};

const AddBillItemFields = ({form, field, category, formulas, moverPositions, organization}) => {
  const isEnabledPerPositionRates = _.get(form.values, `${field}.isEnabledPerPositionRates`);
  const isCategorySupplies = category === BillItemTypeCategory.SUPPLIES;
  const isCategoryFees = category === BillItemTypeCategory.FEES;
  const isPreSubtotal = _.get(form.values, `${field}.billStage`) === BillStage.PRE_SUBTOTAL;
  const unit = _.get(form.values, `${field}.unit`);
  const isUnitHour = unit === BillItemUnit.HOUR;
  const isUnitDollar = unit === BillItemUnit.DOLLAR;
  const isShowingPerPositionRateFields =
    isCategoryFees && isPreSubtotal && (isUnitHour || isUnitDollar);
  const formulaOptions = Formula.getFormulaDropdownOptions(formulas);
  return (
    <React.Fragment>
      <BillItemTypeDrawer.NameField
        form={form}
        field={field}
        fieldIndex={0}
        formulaOptions={formulaOptions}
      />
      <Space height={16} />
      <BillItemTypeDrawer.DescriptionField form={form} field={field} fieldIndex={5} />
      <Space height={16} />
      {isPreSubtotal && (
        <React.Fragment>
          <BillItemTypeDrawer.QuantityField
            form={form}
            field={field}
            fieldIndex={1}
            formulaOptions={formulaOptions}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      {isCategorySupplies ? (
        <BillItemTypeDrawer.PriceField
          form={form}
          field={field}
          fieldIndex={2}
          formulaOptions={formulaOptions}
          isDisabled={isEnabledPerPositionRates}
        />
      ) : (
        <React.Fragment>
          <ValueField
            form={form}
            field={field}
            fieldIndex={3}
            formulaOptions={formulaOptions}
            category={category}
            isDisabled={isEnabledPerPositionRates}
          />
          <Space height={16} />
          <BillStageField form={form} field={field} fieldIndex={4} />
        </React.Fragment>
      )}
      <Space height={16} />
      <BillItemTypeDrawer.TaxableField form={form} field={field} />
      {isCategoryFees && (
        <PerPositionRateFields
          form={form}
          field={field}
          fieldIndex={6}
          moverPositions={moverPositions}
          formulaOptions={formulaOptions}
          isShowingPerPositionRateFields={isShowingPerPositionRateFields}
          organization={organization}
        />
      )}
      {!isEnabledPerPositionRates && (
        <React.Fragment>
          <Space height={16} />
          <AccountingItemField
            form={form}
            field={field}
            fieldIndex={7}
            organization={organization}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const AddBillItemTypeDrawer = ({
  billingLibrary,
  category,
  refetch,
  userId,
  isOpen,
  handleClose,
}) => {
  const {data, loading} = useQuery(AddBillItemTypeDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {billingLibraryUuid: billingLibrary.uuid},
  });
  const billItemTypeForm = BillItemTypeForm.new({
    billingLibraryId: billingLibrary.id,
    organizationId: billingLibrary.organization.id,
    category,
    userId,
    isEnabledTbdBillItems: billingLibrary.organization.features.isEnabledTbdBillItems,
  });
  const {form, submitting, handleSubmit} = useCreateBillItemTypeMutation({
    billItemTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <BillItemTypeDrawer
      isOpen={isOpen}
      isLoading={loading}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      headerText={getHeaderText(category)}
    >
      {!loading && data && (
        <AddBillItemFields
          form={form}
          field={'billItemTypeForm'}
          category={category}
          formulas={data.billingLibrary.organization.formulas}
          moverPositions={
            data.billingLibrary.organization.features.isEnabledMoverPositionMultibranch
              ? Organization.getCompanySettingsMoverPositionsByRole({
                  organization: data.billingLibrary.organization,
                  role: UserRole.EMPLOYEE,
                })
              : data.billingLibrary.organization.moverPositions
          }
          organization={data.billingLibrary.organization}
        />
      )}
    </BillItemTypeDrawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddBillItemTypeDrawer.query = gql`
  ${AccountingItemField.fragment}
  ${Formula.getFormulaDropdownOptions.fragment}
  ${PerPositionRateFields.fragment}
  ${Organization.getCompanySettingsMoverPositionsByRole.fragment}

  query AddBillItemTypeDrawer($billingLibraryUuid: String!) {
    billingLibrary(billingLibraryUuid: $billingLibraryUuid) {
      id
      uuid
      organization {
        id
        moverPositions {
          id
          ...PerPositionRateFields
        }
        formulas {
          id
          ...Formula_getFormulaDropdownOptions
        }
        features {
          isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
        }
        ...AccountingItemField
        ...Organization_getCompanySettingsMoverPositionsByRole
        ...PerPositionRateFields_Organization
      }
    }
  }
`;

AddBillItemTypeDrawer.fragment = gql`
  fragment AddBillItemTypeDrawer on BillingLibrary {
    id
    organization {
      id
      features {
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
      }
    }
  }
`;

export default AddBillItemTypeDrawer;
