// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteSlotTypeMutation from '@shared/modules/Dispatch/hooks/useDeleteSlotTypeMutation';

const DeleteSlotTypeModal = ({isOpen, slotType, handleClose, refetch}) => {
  const {handleSubmit} = useDeleteSlotTypeMutation({
    slotTypeId: slotType.id,
    organizationId: slotType.organizationId,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete Slot ${slotType.index}?`}
      subtitle={`You can't undo this action.`}
      handleClose={handleClose}
      handleDelete={handleSubmit}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteSlotTypeModal.fragment = gql`
  fragment DeleteSlotTypeModal on SlotType {
    id
    organizationId
    index
  }
`;

export default DeleteSlotTypeModal;
