// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import RoomTypeForm from '@shared/modules/Inventory/forms/RoomTypeForm';

const edit = withFragment(
  (inventoryLibrary) => ({
    roomTypeForms: (inventoryLibrary as any).roomTypes.map((roomType: any) =>
      RoomTypeForm.edit(roomType),
    ),
  }),
  gql`
    ${RoomTypeForm.edit.fragment}
    fragment RoomTypesForm_edit on InventoryLibrary {
      id
      roomTypes {
        id
        ...RoomTypeForm_edit
      }
    }
  `,
);

const toForm = ({roomTypeForms}: any) => ({
  roomTypeForms: roomTypeForms.map((roomTypeForm: any) => RoomTypeForm.toForm(roomTypeForm)),
});

const toMutation = ({roomTypeForms}: any) => ({
  roomTypeForms: _.reduce(
    roomTypeForms,
    // @ts-expect-error TS(2769): No overload matches this call.
    (filteredForms, roomTypeForm, index) => {
      if (roomTypeForm.isDirty) {
        const isDeletingNewForm = roomTypeForm.isDeleted && !roomTypeForm.roomTypeId;
        if (isDeletingNewForm) {
          // we don't pass these forms to the BE since there isn't an object to delete
          return filteredForms;
        }
        return [
          ...filteredForms,
          {
            ...RoomTypeForm.toMutation(roomTypeForm),
            // since we only pass dirty forms, we need to pass the current index to ensure the validation error is shown properly
            validationIndex: index,
          },
        ];
      }
      return filteredForms;
    },
    [],
  ),
});

const RoomTypesForm = {
  edit,
  toForm,
  toMutation,
};

export default RoomTypesForm;
