// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({projectTypeId, projectTypeName = '', projectTypeColor = ''}: any) => ({
  projectTypeId,
  inventoryLibraryId: null,
  surveyMethodId: null,

  // private
  projectTypeName,
  projectTypeColor,
});

const edit = withFragment(
  (projectType) => ({
    projectTypeId: (projectType as any).id,
    inventoryLibraryId: (projectType as any).defaultInventoryLibraryId,
    surveyMethodId: (projectType as any).defaultSurveyMethodId,
    // private
    projectTypeName: (projectType as any).name,
    projectTypeColor: (projectType as any).color,
  }),
  gql`
    fragment DefaultProjectTypeInventorySettingsForm_edit on ProjectType {
      id
      name
      color
      defaultInventoryLibraryId
      defaultSurveyMethodId
    }
  `,
);

const toForm = ({
  projectTypeId,
  inventoryLibraryId,
  surveyMethodId,
  projectTypeName,
  projectTypeColor,
}: any) => ({
  projectTypeId,
  inventoryLibraryId,
  surveyMethodId,

  // private
  projectTypeName,
  projectTypeColor,
});

const toMutation = ({projectTypeId, inventoryLibraryId, surveyMethodId}: any) => ({
  projectTypeId,
  inventoryLibraryId,
  surveyMethodId,
});

const DefaultProjectTypeInventorySettingsForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default DefaultProjectTypeInventorySettingsForm;
