// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// App

// Components
import {MapView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight} from '@supermove/styles';
import {Datetime, Location as LocationUtils} from '@supermove/utils';

import {MapMarker} from 'modules/App/Map/components';

const Container = Styled.View`
  flex: 1;
`;

const InfoContainer = Styled.View`
`;

const InfoText = Styled.H7`
  ${fontWeight(700)}
`;

const InfoSubtitle = Styled.H8`
`;

const getBounds = ({locations, positions}) => {
  return locations
    .map((location) => LocationUtils.create(location))
    .filter(Boolean)
    .concat(positions.map((position) => LocationUtils.create(position)));
};

const getLocations = ({locations, warehousePosition}) => {
  if (warehousePosition) {
    return [...locations, warehousePosition];
  }
  return locations;
};

const InfoWindow = ({position}) => {
  return (
    <InfoContainer>
      {position && (
        <React.Fragment>
          <InfoText>
            {Datetime.convertToDisplayDatetime(position.timestamp, 'M/D/YY h:mm:ss A')}
          </InfoText>
          <InfoSubtitle>{`(${position.latitude}, ${position.longitude})`}</InfoSubtitle>
        </React.Fragment>
      )}
    </InfoContainer>
  );
};

const CompleteJobMap = ({
  activePosition,
  locations,
  positions,
  warehouseLocation,
  onDeselectPosition,
  onSelectPosition,
}) => {
  const warehousePosition = warehouseLocation ? LocationUtils.create(warehouseLocation) : null;

  return (
    <Container>
      <MapView
        isInfoWindowVisible={activePosition !== null}
        isScrollEnabled={false}
        mapTypeControl
        streetViewControl
        bounds={getBounds({
          locations: getLocations({locations, warehousePosition}),
          positions,
        })}
        infoWindowChild={<InfoWindow position={activePosition} />}
        infoWindowPosition={activePosition ? LocationUtils.create(activePosition) : null}
        initialCenter={{
          lat: 37.7749,
          lng: -122.4194,
        }}
        onClick={() => onDeselectPosition()}
        onInfoWindowClose={() => onDeselectPosition()}
        style={{flex: 1}}
      >
        {(props) => (
          <React.Fragment>
            {locations.map((location, index) => (
              <MapMarker
                key={index}
                kind={MapMarker.DEFAULT}
                name={`Stop ${index + 1}`}
                label={MapMarker.createMarkerLabel(index + 1)}
                position={LocationUtils.create(location)}
                {...props}
              />
            ))}
            {positions.map((position) => (
              <MapMarker
                key={position.id}
                kind={
                  _.get(activePosition, 'id', false) === position.id
                    ? MapMarker.DOT_BLUE
                    : MapMarker.DOT_RED
                }
                payload={position}
                position={LocationUtils.create(position)}
                onClick={(props, marker, e) => onSelectPosition(props.payload)}
                {...props}
              />
            ))}
            {warehousePosition && (
              <MapMarker kind={MapMarker.WAREHOUSE} position={warehousePosition} {...props} />
            )}
          </React.Fragment>
        )}
      </MapView>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompleteJobMap.fragment = gql`
  fragment CompleteJobMap_Location on Location {
    id
    address
    city
    latitude
    longitude
    zipCode
    unit
  }

  fragment CompleteJobMap_Position on Position {
    id
    timestamp
    latitude
    longitude
    accuracy
    heading
    speed
  }
`;

// --------------------------------------------------
// Props
// --------------------------------------------------
CompleteJobMap.propTypes = {
  activePosition: PropTypes.object,
  locations: PropTypes.array.isRequired,
  positions: PropTypes.array.isRequired,
  warehouseLocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  onDeselectPosition: PropTypes.func.isRequired,
  onSelectPosition: PropTypes.func.isRequired,
};

CompleteJobMap.defaultProps = {
  activePosition: null,
  warehouseLocation: null,
};

export default CompleteJobMap;
