// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Link, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import JobActionCopyModal from 'modules/Job/Action/Copy';
import SendEmailForJobModal from 'modules/Job/Email/components/SendEmailForJobModal';
import SetProjectIsTestModal from 'modules/Job/Project/components/SetProjectIsTestModal';
import JobRequiresCustomerEmailModal from 'modules/Job/Show/components/JobRequiresCustomerEmailModal';
import JobSkipConfirmationModal from 'modules/Job/Show/components/JobSkipConfirmationModal';
import CreateStorageProjectFromMoveProjectModal from 'modules/Job/V2/Storage/components/CreateStorageProjectFromMoveProjectModal';
import CompleteEstimatedRangeJobModal from 'modules/Job/components/CompleteEstimatedRangeJobModal';
import JobCancelModal from 'modules/Job/components/JobCancelModal';
import JobCompleteManuallyModal from 'modules/Job/components/JobCompleteManuallyModal';
import JobShareModal from 'modules/Job/components/JobShareModal';
import JobTrackModal from 'modules/Job/components/JobTrackModal';
import JobUncancelModal from 'modules/Job/components/JobUncancelModal';
import MoveResetModal from 'modules/Job/components/MoveResetModal';
import ProjectSalesLockedModal from 'modules/Project/V2/Show/Blocks/components/ProjectSalesLockedModal';
import CreateProjectSuccessModal from 'modules/Project/components/CreateProjectSuccessModal';

const Container = Styled.View`
`;

const Section = Styled.View`
  margin-top: 20px;
`;

const Title = Styled.Text`
  ${Typography.Label1}
  color: ${colors.gray.primary};
  padding-bottom: 10px;
  padding-horizontal: 20px;
`;

const Item = Styled.Touchable`
  width: 100%;
  padding-vertical: 10px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Body2}
  color: ${({vars = {}}) => (vars.color ? vars.color : colors.gray.secondary)};
`;

const hasPreparedPreMoveDocuments = (job) => {
  return _.get(job, 'move.isPreMoveDocumentsPrepared');
};

const hasSignedBillOfLading = (job) => {
  return !!_.get(job, 'move.hasSignedBillOfLading');
};

const isLead = ({job}) => {
  if (!['LEAD'].includes(job.primaryStatus)) {
    return false;
  }
  if (!job.day) {
    return false;
  }
  return true;
};

const isCancelled = ({job}) => {
  return job.primaryStatus === 'CANCELLED';
};

const isBeforeMove = ({job}) => {
  if (['IN_PROGRESS', 'INCOMPLETE', 'COMPLETE'].includes(job.primaryStatus)) {
    return false;
  }
  if (job.isInPast) {
    return false;
  }
  return true;
};

const isDuringMove = ({job}) => {
  if (!['NOT_READY', 'READY', 'IN_PROGRESS', 'INCOMPLETE'].includes(job.primaryStatus)) {
    return false;
  }
  if (!job.day) {
    return false;
  }
  return true;
};

const isSuperAdmin = ({viewer}) => {
  return viewer.role === 'super';
};

const Option = ({handlePress, children}) => {
  const {isHovered, ref} = useHover();

  return (
    <Item
      disabled={!handlePress}
      ref={ref}
      style={{backgroundColor: isHovered ? colors.hover : 'none'}}
      onPress={handlePress}
    >
      {children}
    </Item>
  );
};

const JobActionEditJob = ({job, viewer}) => {
  const canEdit = !['CANCELLED'].includes(job.primaryStatus);
  const getEditLink = (job) => {
    return `/jobs/${job.uuid}/edit`;
  };

  if (job.isComplete && !viewer.viewingOrganization.features.isEnabledCanEditCompleteJob) {
    return null;
  }

  if (!canEdit) {
    return null;
  }

  return (
    <Link to={getEditLink(job)} style={{width: '100%'}}>
      <Option>
        <Text>{`Edit Job`}</Text>
      </Option>
    </Link>
  );
};

const JobActionCopyJob = ({job, viewer, handleClose}) => {
  if (!viewer.viewingOrganization.canCopyJob) {
    return null;
  }

  return (
    <JobActionCopyModal
      jobUuid={job.uuid}
      onClose={handleClose}
      trigger={({handleOpen}) => (
        <Option handlePress={handleOpen}>
          <Text>{`Copy Job`}</Text>
        </Option>
      )}
    />
  );
};

const JobActionCancelJob = ({job, handleClose}) => {
  const jobCancelModal = useModal({name: 'Job Cancel Modal'});

  return (
    <React.Fragment>
      {['HOLD', 'LEAD', 'NOT_READY', 'READY', 'INCOMPLETE'].includes(job.primaryStatus) && (
        <Option handlePress={jobCancelModal.handleOpen}>
          <Text vars={{color: colors.red.warning}}>{`Cancel Job`}</Text>
        </Option>
      )}
      {job.primaryStatus === 'CANCELLED' && (
        <JobUncancelModal
          job={job}
          onClose={handleClose}
          trigger={({handleOpen}) => (
            <Option handlePress={handleOpen}>
              <Text vars={{color: colors.blue.interactive}}>{`Reactivate Job`}</Text>
            </Option>
          )}
        />
      )}
      <JobCancelModal jobId={job.id} isOpen={jobCancelModal.isOpen} handleClose={handleClose} />
    </React.Fragment>
  );
};

const JobActionSendEmail = ({job, viewer}) => {
  const sendEmailForJobModal = useModal({name: 'Send Email For Job Modal'});
  const requiresCustomerEmailModal = useModal({name: 'Requires Customer Email Modal'});
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  return (
    <React.Fragment>
      <Option
        handlePress={() => {
          if (!job.canSendCustomerEmail) {
            return requiresCustomerEmailModal.handleOpen();
          }
          if (responsive.mobile) {
            return navigator.push(`/jobs/${job.uuid}/email/send`);
          }
          return sendEmailForJobModal.handleOpen();
        }}
      >
        <Text>Send Email</Text>
      </Option>
      <JobRequiresCustomerEmailModal
        isOpen={requiresCustomerEmailModal.isOpen}
        job={job}
        handleClose={requiresCustomerEmailModal.handleClose}
      />
      <SendEmailForJobModal
        isOpen={sendEmailForJobModal.isOpen}
        jobUuid={job.uuid}
        handleClose={sendEmailForJobModal.handleClose}
      />
    </React.Fragment>
  );
};

const JobActionManuallyCompleteJobLead = ({job, handleClose}) => {
  const jobCompleteManuallyModal = useModal({name: 'Job Complete Manually Modal'});
  if (!isLead({job})) {
    return null;
  }

  if (Datetime.isFuture(job.day.value)) {
    return null;
  }

  return (
    <React.Fragment>
      <Option handlePress={jobCompleteManuallyModal.handleOpen}>
        <Text>{`Mark This Job Complete`}</Text>
      </Option>
      <JobCompleteManuallyModal
        job={job}
        isOpen={jobCompleteManuallyModal.isOpen}
        handleClose={jobCompleteManuallyModal.handleClose}
        onSuccess={handleClose}
      />
    </React.Fragment>
  );
};

const CompleteEstimatedRangeJobOption = ({job, handleClose}) => {
  const completeEstimatedRangeJobModal = useModal({name: 'Complete Estimated Range Job Modal'});

  return (
    <React.Fragment>
      <Option handlePress={completeEstimatedRangeJobModal.handleOpen}>
        <Text>{`Mark This Job Complete`}</Text>
      </Option>
      <CompleteEstimatedRangeJobModal
        key={completeEstimatedRangeJobModal.key}
        isOpen={completeEstimatedRangeJobModal.isOpen}
        handleClose={completeEstimatedRangeJobModal.handleClose}
        jobUuid={job.parentJob ? job.parentJob.uuid : job.uuid}
        refetchParent={handleClose}
      />
    </React.Fragment>
  );
};

const JobActionManuallyCompleteJobMove = ({job, handleClose}) => {
  const jobCompleteManuallyModal = useModal({name: 'Job Complete Manually Modal'});
  if (Job.getIsEstimatedRange(job) && !job.isComplete) {
    return <CompleteEstimatedRangeJobOption job={job} handleClose={handleClose} />;
  }

  if (!isDuringMove({job})) {
    return null;
  }

  if (job.day ? Datetime.isFuture(job.day.value) : Datetime.isFuture(job.startDate)) {
    return null;
  }

  if (Job.getIsChildJob(job)) {
    return <CompleteEstimatedRangeJobOption job={job} handleClose={handleClose} />;
  }

  return (
    <React.Fragment>
      <Option handlePress={jobCompleteManuallyModal.handleOpen}>
        <Text>{`Mark This Job Complete`}</Text>
      </Option>
      <JobCompleteManuallyModal
        job={job}
        isOpen={jobCompleteManuallyModal.isOpen}
        handleClose={jobCompleteManuallyModal.handleClose}
        onSuccess={handleClose}
      />
    </React.Fragment>
  );
};

const JobActionSendConfirmation = ({job, isConfirmationEnabled}) => {
  const {navigator} = useNavigationDOM();
  const requiresCustomerEmailModal = useModal({name: 'Requires Customer Email Modal'});
  const projectSalesLockedModal = useModal({name: 'Project Sales Locked Modal'});

  const navigateToSendConfirmation = () => {
    navigator.push(`/projects/${job.project.uuid}/confirmation`);
  };

  if (isCancelled({job})) {
    return null;
  }

  if (!isConfirmationEnabled) {
    return null;
  }

  return (
    <React.Fragment>
      <Option
        handlePress={() => {
          if (!job.canSendCustomerEmail) {
            return requiresCustomerEmailModal.handleOpen();
          } else if (Job.getIsSalesLocked(job)) {
            return projectSalesLockedModal.handleOpen();
          }
          navigateToSendConfirmation();
        }}
      >
        <Text>Send Confirmation</Text>
      </Option>
      <JobRequiresCustomerEmailModal
        isOpen={requiresCustomerEmailModal.isOpen}
        job={job}
        handleClose={requiresCustomerEmailModal.handleClose}
      />
      <ProjectSalesLockedModal
        isOpen={projectSalesLockedModal.isOpen}
        projectUuid={job.project.uuid}
        handleClose={projectSalesLockedModal.handleClose}
        handleConfirm={navigateToSendConfirmation}
        isBlocked={job.project.organization.features.isEnabledBlockSendConfirmations}
      />
    </React.Fragment>
  );
};

const JobActionSendQuote = ({job, isQuoteEnabled}) => {
  const {navigator} = useNavigationDOM();
  const requiresCustomerEmailModal = useModal({name: 'Requires Customer Email Modal'});
  const projectSalesLockedModal = useModal({name: 'Project Sales Locked Modal'});

  if (isCancelled({job})) {
    return null;
  }

  if (!isQuoteEnabled) {
    return null;
  }

  const navigateToSendQuote = () => navigator.push(`/projects/${job.project.uuid}/quote`);

  return (
    <React.Fragment>
      <Option
        handlePress={() => {
          if (!job.canSendCustomerEmail) {
            return requiresCustomerEmailModal.handleOpen();
          }
          if (
            job.project.organization.features.isEnabledSalesLockedQuotes &&
            Job.getIsSalesLocked(job)
          ) {
            return projectSalesLockedModal.handleOpen();
          }
          navigateToSendQuote();
        }}
      >
        <Text>Send Quote</Text>
      </Option>
      <JobRequiresCustomerEmailModal
        isOpen={requiresCustomerEmailModal.isOpen}
        job={job}
        handleClose={requiresCustomerEmailModal.handleClose}
      />
      <ProjectSalesLockedModal
        isOpen={projectSalesLockedModal.isOpen}
        projectUuid={job.project.uuid}
        handleClose={projectSalesLockedModal.handleClose}
        handleConfirm={navigateToSendQuote}
        isBlocked={job.project.organization.features.isEnabledBlockSendQuotes}
      />
    </React.Fragment>
  );
};

const JobActionSkipConfirmation = ({job, handleClose: onClose, isConfirmationEnabled}) => {
  const jobActionSkipConfirmationModal = useModal({name: 'Job Action Skip Confirmation Modal'});
  const projectSalesLockedModal = useModal({name: 'Project Sales Locked Modal'});

  if (isCancelled({job})) {
    return null;
  }

  if (!isBeforeMove({job})) {
    return null;
  }

  if (!isConfirmationEnabled) {
    return null;
  }

  if (job.isConfirmationComplete) {
    return null;
  }

  return (
    <React.Fragment>
      <Option
        handlePress={() => {
          if (Job.getIsSalesLocked(job)) {
            projectSalesLockedModal.handleOpen();
          } else {
            jobActionSkipConfirmationModal.handleOpen();
          }
        }}
      >
        <Text>Skip Confirmation</Text>
      </Option>
      <ProjectSalesLockedModal
        isOpen={projectSalesLockedModal.isOpen}
        projectUuid={job.project.uuid}
        handleClose={projectSalesLockedModal.handleClose}
        handleConfirm={() => {
          jobActionSkipConfirmationModal.handleOpen();
          projectSalesLockedModal.handleClose();
        }}
        isBlocked={job.project.organization.features.isEnabledBlockSendConfirmations}
      />
      <JobSkipConfirmationModal
        isOpen={jobActionSkipConfirmationModal.isOpen}
        job={job}
        handleClose={() => {
          jobActionSkipConfirmationModal.handleClose();
          onClose();
        }}
      />
    </React.Fragment>
  );
};

const JobActionResetJob = ({job, handleClose}) => {
  if (
    !job.move ||
    job.primaryStatus !== 'IN_PROGRESS' ||
    hasPreparedPreMoveDocuments(job) ||
    hasSignedBillOfLading(job)
  ) {
    return null;
  }

  return (
    <MoveResetModal
      moveId={job.move.id}
      onClose={handleClose}
      trigger={({handleOpen}) => (
        <Option handlePress={handleOpen}>
          <Text vars={{color: colors.red.warning}}>{`Reset Job`}</Text>
        </Option>
      )}
    />
  );
};

const JobActionPrintInventory = ({job}) => {
  return (
    <a
      href={`/0/${job.project.organization.slug}/projects/${job.project.uuid}/inventory/print`}
      target={'_blank'}
      style={{width: '100%'}}
    >
      <Option>
        <Text>{`Print Inventory`}</Text>
      </Option>
    </a>
  );
};

const JobActionPrintJob = ({job, viewer}) => {
  if (viewer.viewingOrganization.features.isEnabledPrintJobCard) {
    return (
      <a
        href={`/0/${job.project.organization.slug}/jobs/${job.uuid}/print/card`}
        target={'_blank'}
        style={{width: '100%'}}
      >
        <Option>
          <Text>Print Card</Text>
        </Option>
      </a>
    );
  }

  return (
    <a
      href={`/0/${job.project.organization.slug}/jobs/${job.uuid}/print`}
      target={'_blank'}
      style={{width: '100%'}}
    >
      <Option>
        <Text>Print Job</Text>
      </Option>
    </a>
  );
};

const JobActionGetShareLink = ({job}) => {
  return (
    <JobShareModal
      job={job}
      trigger={({handleOpen}) => (
        <Option handlePress={handleOpen}>
          <Text>Get Share Link</Text>
        </Option>
      )}
    />
  );
};

const JobActionGetTrackLink = ({job}) => {
  if (job.isInProgress || job.isComplete) {
    return (
      <JobTrackModal
        job={job}
        trigger={({handleOpen}) => (
          <Option handlePress={handleOpen}>
            <Text>Get Track Link</Text>
          </Option>
        )}
      />
    );
  }

  return null;
};

const JobActionConvertJob = ({job, handleClose}) => {
  const setProjectIsTestModal = useModal({name: 'Set Project Is Test Modal'});

  return (
    <React.Fragment>
      <Option handlePress={setProjectIsTestModal.handleOpen}>
        <Text>{job.isTest ? 'Convert Training Job to Real' : 'Convert Real Job to Training'}</Text>
      </Option>
      <SetProjectIsTestModal
        project={job.project}
        isOpen={setProjectIsTestModal.isOpen}
        handleClose={() => {
          setProjectIsTestModal.handleClose();
          handleClose();
        }}
      />
    </React.Fragment>
  );
};

const JobActionAuditTimesheet = ({job}) => {
  const {navigator} = useNavigationDOM();

  return (
    <Option handlePress={() => navigator.push(`/jobs/${job.uuid}/timesheet/audit`)}>
      <Text>View GPS Tracking</Text>
    </Option>
  );
};

const JobActionProjectPage = ({job, handleClose}) => {
  const {navigator} = useNavigationDOM();

  return (
    <Option
      handlePress={() => {
        navigator.push(`/projects/${job.project.uuid}`);
      }}
    >
      <Text>View Project</Text>
    </Option>
  );
};

const JobActionGeneral = () => {
  return (
    <Section>
      <Title>General</Title>
    </Section>
  );
};

const JobActionLead = ({job}) => {
  if (!isLead({job})) {
    return null;
  }

  return (
    <Section>
      <Title>Lead</Title>
    </Section>
  );
};

const JobActionBeforeMove = ({job, isConfirmationEnabled, isQuoteEnabled}) => {
  if (isCancelled({job})) {
    return null;
  }

  if (!isBeforeMove({job})) {
    return null;
  }

  // Do not show title if job does not have confirmation or quote
  if (!isConfirmationEnabled && !isQuoteEnabled) {
    return null;
  }

  if (Job.getIsEstimatedRange(job) && job.isComplete) {
    return null;
  }

  return (
    <Section>
      <Title>Before Move</Title>
    </Section>
  );
};

const JobActionDuringMove = ({job}) => {
  if (!isDuringMove({job})) {
    return null;
  }

  return (
    <Section>
      <Title>Move</Title>
    </Section>
  );
};

const JobActionStorage = ({job, viewer}) => {
  const {params, navigator} = useNavigationDOM();
  const createStorageProjectForMoveProjectModal = useModal({
    name: 'Job Actions Create Storage Project Modal',
  });
  const createProjectSuccessModal = useModal({
    name: 'Job Actions Create Project Success Modal',
  });

  if (!viewer.viewingOrganization.settings.isStorageEnabled) {
    return null;
  }

  return (
    <Section>
      <Title>Storage</Title>
      <Option handlePress={createStorageProjectForMoveProjectModal.handleOpen}>
        <Text>Create Storage Project</Text>
      </Option>
      <CreateStorageProjectFromMoveProjectModal
        key={createStorageProjectForMoveProjectModal.key}
        isOpen={createStorageProjectForMoveProjectModal.isOpen}
        handleClose={createStorageProjectForMoveProjectModal.handleClose}
        projectUuid={job.project.uuid}
        isDisabledClientFields
        createProjectSuccessModal={createProjectSuccessModal}
      />
      <CreateProjectSuccessModal
        key={params.new}
        projectUuid={params.new}
        isOpen={createProjectSuccessModal.isOpen}
        handleClose={createProjectSuccessModal.handleClose}
        title={'Storage project created!'}
        backButtonText={'Close'}
        handleViewProject={() => navigator.push(`/storage/projects/${params.new}`)}
      />
    </Section>
  );
};

const JobActionPrint = () => {
  return (
    <Section>
      <Title>Print</Title>
    </Section>
  );
};

const JobActionLinks = () => {
  return (
    <Section>
      <Title>Links</Title>
    </Section>
  );
};

const JobActionSuperAdmin = ({viewer, job}) => {
  const {navigator} = useNavigationDOM();
  if (!isSuperAdmin({viewer})) {
    return null;
  }

  return (
    <React.Fragment>
      <Section>
        <Title>Super Admin</Title>
      </Section>
      <Option
        handlePress={() => {
          navigator.push(`/projects/${job.project.uuid}/view`);
        }}
      >
        <Text>View Project V2</Text>
      </Option>
    </React.Fragment>
  );
};

const JobActionBlock = ({index, block, job, viewer, handleClose}) => {
  const {project} = job;
  const isConfirmationEnabled = project.projectType.isEnabledConfirmationStepsForConfirmation;
  const isQuoteEnabled = project.projectType.isEnabledConfirmationStepsForQuote;
  switch (block.kind) {
    case 'MANAGER_JOB_ACTIONS_GENERAL':
      return <JobActionGeneral />;
    case 'MANAGER_JOB_ACTIONS_EDIT_JOB':
      return <JobActionEditJob job={job} viewer={viewer} handleClose={handleClose} />;
    case 'MANAGER_JOB_ACTIONS_COPY_JOB':
      return <JobActionCopyJob job={job} viewer={viewer} handleClose={handleClose} />;
    case 'MANAGER_JOB_ACTIONS_CANCEL_JOB':
      return <JobActionCancelJob job={job} handleClose={handleClose} />;
    case 'MANAGER_JOB_ACTIONS_SEND_EMAIL':
      return <JobActionSendEmail job={job} viewer={viewer} handleClose={handleClose} />;
    case 'MANAGER_JOB_ACTIONS_LEAD':
      return <JobActionLead job={job} />;
    case 'MANAGER_JOB_ACTIONS_MANUALLY_COMPLETE_JOB_LEAD':
      return <JobActionManuallyCompleteJobLead job={job} handleClose={handleClose} />;
    case 'MANAGER_JOB_ACTIONS_BEFORE_MOVE':
      return (
        <JobActionBeforeMove
          job={job}
          isConfirmationEnabled={isConfirmationEnabled}
          isQuoteEnabled={isQuoteEnabled}
        />
      );
    case 'MANAGER_JOB_ACTIONS_SEND_QUOTE':
      return <JobActionSendQuote job={job} isQuoteEnabled={isQuoteEnabled} />;
    case 'MANAGER_JOB_ACTIONS_SEND_CONFIRMATION':
      return (
        <JobActionSendConfirmation
          job={job}
          handleClose={handleClose}
          isConfirmationEnabled={isConfirmationEnabled}
        />
      );
    case 'MANAGER_JOB_ACTIONS_SKIP_CONFIRMATION':
      return (
        <JobActionSkipConfirmation
          job={job}
          handleClose={handleClose}
          isConfirmationEnabled={isConfirmationEnabled}
        />
      );
    case 'MANAGER_JOB_ACTIONS_DURING_MOVE':
      return <JobActionDuringMove job={job} />;
    case 'MANAGER_JOB_ACTIONS_MANUALLY_COMPLETE_JOB_MOVE':
      return <JobActionManuallyCompleteJobMove job={job} handleClose={handleClose} />;
    case 'MANAGER_JOB_ACTIONS_RESET_JOB':
      return <JobActionResetJob job={job} handleClose={handleClose} />;
    case 'MANAGER_JOB_ACTIONS_STORAGE':
      return <JobActionStorage job={job} viewer={viewer} />;
    case 'MANAGER_JOB_ACTIONS_PRINT':
      return <JobActionPrint />;
    case 'MANAGER_JOB_ACTIONS_PRINT_INVENTORY':
      return <JobActionPrintInventory job={job} handleClose={handleClose} />;
    case 'MANAGER_JOB_ACTIONS_PRINT_JOB':
      return <JobActionPrintJob job={job} viewer={viewer} handleClose={handleClose} />;
    case 'MANAGER_JOB_ACTIONS_LINKS':
      return <JobActionLinks />;
    case 'MANAGER_JOB_ACTIONS_GET_SHARE_LINK':
      return <JobActionGetShareLink job={job} handleClose={handleClose} />;
    case 'MANAGER_JOB_ACTIONS_GET_TRACK_LINK':
      return <JobActionGetTrackLink job={job} handleClose={handleClose} />;
    case 'MANAGER_JOB_ACTIONS_SUPER_ADMIN':
      return <JobActionSuperAdmin viewer={viewer} job={job} />;
    case 'MANAGER_JOB_ACTIONS_CONVERT_JOB':
      return <JobActionConvertJob job={job} handleClose={handleClose} />;
    case 'MANAGER_JOB_ACTIONS_AUDIT_TIMESHEET':
      return <JobActionAuditTimesheet job={job} />;
    case 'MANAGER_JOB_ACTIONS_VIEW_PROJECT_PAGE':
      return <JobActionProjectPage job={job} viewer={viewer} handleClose={handleClose} />;
    default:
      return null;
  }
};

// Invoke to close sidebar.
const JobActionsList = ({job, viewer, handleClose}) => {
  const blocks = _.get(viewer, 'viewingOrganization.managerJobActionsPanelBlock.blocks', []);

  return (
    <Container>
      {blocks.map((block, index) => (
        <JobActionBlock
          key={index}
          index={index}
          block={block}
          job={job}
          viewer={viewer}
          handleClose={handleClose}
        />
      ))}
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobActionsList.propTypes = {
  job: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

JobActionsList.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobActionsList.fragment = gql`
  ${JobRequiresCustomerEmailModal.fragment}
  ${JobSkipConfirmationModal.fragment}
  ${JobTrackModal.fragment}
  ${SetProjectIsTestModal.fragment}
  ${Job.getIsEstimatedRange.fragment}
  ${Job.getIsChildJob.fragment}
  ${Job.getIsSalesLocked.fragment}

  fragment JobActionsList_Job on Job {
    id
    uuid
    isTest
    isConfirmationComplete
    canSendCustomerEmail
    isComplete
    isInProgress
    isInPast
    isProjectSalesLocked
    primaryStatus: calendarPrimaryStatus
    date
    day {
      id
      value
    }
    parentJob {
      id
      uuid
    }
    project {
      id
      uuid
      organization {
        id
        slug
        features {
          isEnabledSalesLockedQuotes: isEnabled(feature: "SALES_LOCKED_QUOTES")
          isEnabledBlockSendConfirmations: isEnabled(feature: "BLOCK_SEND_CONFIRMATIONS")
          isEnabledBlockSendQuotes: isEnabled(feature: "BLOCK_SEND_QUOTES")
          isEnabledPrintJobCard: isEnabled(feature: "PRINT_JOB_CARD")
        }
      }
      projectType {
        id
        isEnabledConfirmationStepsForConfirmation
        isEnabledConfirmationStepsForQuote
      }
      ...SetProjectIsTestModal
    }
    move {
      id
      isPreMoveDocumentsPrepared
    }
    ...JobRequiresCustomerEmailModal
    ...JobSkipConfirmationModal
    ...JobTrackModal
    ...Job_getIsEstimatedRange
    ...Job_getIsChildJob
    ...Job_getIsSalesLocked
  }

  fragment JobActionsList_Viewer on User {
    id
    role
    viewingOrganization {
      id
      slug
      canCopyJob
      features {
        isEnabledCanEditCompleteJob: isEnabled(feature: "CAN_EDIT_COMPLETE_JOB")
      }
      settings {
        id
        isStorageEnabled
      }
      managerJobActionsPanelBlock: block(kind: "MANAGER_JOB_ACTIONS_PANEL") {
        blocks {
          kind
        }
      }
      shouldCreateCommercialMoves
    }
  }
`;

export default JobActionsList;
