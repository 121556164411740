// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CostItemTypeForm from '@shared/modules/Billing/forms/CostItemTypeForm';

const useUpdateCostItemTypeMutation = ({costItemTypeForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      costItemTypeForm: CostItemTypeForm.toForm(costItemTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCostItemTypeMutation.mutation,
    variables: {
      costItemTypeForm: CostItemTypeForm.toMutation(form.values.costItemTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCostItemTypeMutation.mutation = gql`
  mutation useUpdateCostItemTypeMutation($costItemTypeForm: CostItemTypeForm!) {
    response: updateCostItemType(costItemTypeForm: $costItemTypeForm) {
      ${gql.errors}
      costItemType {
        id
      }
    }
  }
`;

export default useUpdateCostItemTypeMutation;
