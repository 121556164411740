// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {useModal, usePopover} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import IconButton from '@shared/design/components/IconButton';
import DocumentContentJson from '@shared/modules/Document/helpers/DocumentContentJson';
import {
  SelectedDocumentItemType,
  SetSelectedDocumentItemType,
  DocumentContentJsonType,
  SetDocumentContentJsonType,
  DocumentContentJsonWithSectionsType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import MoveDocumentItemModal from 'modules/Organization/Settings/Document/components/MoveDocumentItemModal';

const handleDuplicateItem = ({
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  selectedDocumentItem,
  setSelectedDocumentItem,
  index,
  columnIndex,
}: {
  draftDocumentContentJson: DocumentContentJsonType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  selectedDocumentItem: SelectedDocumentItemType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  index: number;
  columnIndex?: number;
}) => {
  const documentItemsField = DocumentContentJson.getDocumentItemsField({
    sectionIndex: selectedDocumentItem.sectionIndex,
    columnIndex,
  });
  const documentItems = _.get(draftDocumentContentJson, documentItemsField);
  // We need to deep clone the document item so that the state refreshes
  // for the input editor, this allows textInput to not read from each other
  const newDocumentItem = _.cloneDeep(documentItems[index]);
  const newDocumentItemIndex = index + 1;
  documentItems.splice(newDocumentItemIndex, 0, newDocumentItem);

  setDraftDocumentContentJson((prevState: DocumentContentJsonType) => {
    _.set(prevState, documentItemsField, documentItems);

    // Set focus on duplicated item
    setSelectedDocumentItem({
      ...selectedDocumentItem,
      documentItem: newDocumentItem,
      index: newDocumentItemIndex,
    });

    return {...prevState};
  });
};

const handleRemoveItem = ({
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  selectedDocumentItem,
  setSelectedDocumentItem,
  index,
  columnIndex,
}: {
  draftDocumentContentJson: DocumentContentJsonType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  selectedDocumentItem: SelectedDocumentItemType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  index: number;
  columnIndex?: number;
}) => {
  const documentItemsField = DocumentContentJson.getDocumentItemsField({
    sectionIndex: selectedDocumentItem.sectionIndex,
    columnIndex,
  });
  const documentItems = _.get(draftDocumentContentJson, documentItemsField);

  if (!_.isNil(selectedDocumentItem.index)) {
    // Handle possibly adjusting the selected document item. We need to
    // handle the column as well because we show two columns in the same
    // view as opposed to sections where only one section is shown at a time.
    const isSameColumn = selectedDocumentItem.columnIndex === columnIndex;
    const isSameIndex = selectedDocumentItem.index === index;
    const isSelectionRemoved = isSameColumn && isSameIndex;
    const isSelectionMovedDown = isSameColumn && selectedDocumentItem.index > index;
    if (isSelectionRemoved) {
      setSelectedDocumentItem({...selectedDocumentItem, documentItem: undefined, index: undefined});
    }
    if (isSelectionMovedDown) {
      setSelectedDocumentItem({...selectedDocumentItem, index: selectedDocumentItem.index - 1});
    }
  }

  // Remove the document item
  setDraftDocumentContentJson((prevState: DocumentContentJsonType) => {
    documentItems.splice(index, 1);
    _.set(prevState, documentItemsField, documentItems);
    return {...prevState};
  });
};

const DocumentTemplateDocumentItemActions = ({
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  setSelectedDocumentItem,
  selectedDocumentItem,
  documentItemName,
  index,
  columnIndex,
}: {
  draftDocumentContentJson: DocumentContentJsonType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  selectedDocumentItem: SelectedDocumentItemType;
  documentItemName: string;
  index: number;
  columnIndex?: number;
}) => {
  const moveDocumentItemModal = useModal({name: 'Move Document Item Modal', enableTracking: true});
  const documentItemActionsPopover = usePopover({name: 'Document Item Actions Popover'});
  const {documentItems} = draftDocumentContentJson;
  const isInSection = !_.isNil(selectedDocumentItem.sectionIndex);
  const isOneSection = isInSection && documentItems.length === 1;

  return (
    <React.Fragment>
      <ActionMenuPopover
        popover={documentItemActionsPopover}
        placement={ActionMenuPopover.Position.RightStart}
        width={200}
        actions={[
          {
            text: 'Duplicate item',
            onPress: () =>
              handleDuplicateItem({
                draftDocumentContentJson,
                selectedDocumentItem,
                setDraftDocumentContentJson,
                setSelectedDocumentItem,
                index,
                columnIndex,
              }),
          },
          ...List.insertIf(isInSection, {
            text: 'Move to another section',
            onPress: moveDocumentItemModal.handleOpen,
            isDisabled: isOneSection,
            tooltip: isOneSection ? 'There are no other sections' : undefined,
          }),
          {
            text: 'Remove item',
            onPress: () =>
              handleRemoveItem({
                draftDocumentContentJson,
                selectedDocumentItem,
                setDraftDocumentContentJson,
                setSelectedDocumentItem,
                index,
                columnIndex,
              }),
            color: colors.red.warning,
          },
        ]}
      >
        <IconButton
          onPress={documentItemActionsPopover.handleOpen}
          source={Icon.EllipsisV}
          isSecondary
        />
      </ActionMenuPopover>
      <MoveDocumentItemModal
        key={moveDocumentItemModal.key}
        isOpen={moveDocumentItemModal.isOpen}
        handleClose={moveDocumentItemModal.handleClose}
        selectedDocumentItem={selectedDocumentItem}
        setSelectedDocumentItem={setSelectedDocumentItem}
        draftDocumentContentJson={draftDocumentContentJson as DocumentContentJsonWithSectionsType}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
        documentItemName={documentItemName}
        index={index}
        columnIndex={columnIndex}
      />
    </React.Fragment>
  );
};

export default DocumentTemplateDocumentItemActions;
