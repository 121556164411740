// Libararies
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';
import {withFragment} from '@supermove/utils';

const getSelectedProjectTypeIds = (bookingFiltersForm: any) => {
  const selectedProjectTypes = bookingFiltersForm.projectTypes.filter(
    (projectType: any) => projectType.isSelected,
  );
  const selectedProjectTypeIds = selectedProjectTypes.map((projectType: any) => projectType.id);
  return selectedProjectTypeIds;
};

const getSelectedSalespersonIds = (bookingFiltersForm: any) => {
  const selectedSalespersons = bookingFiltersForm.salespersons.filter(
    (salesperson: any) => salesperson.isSelected,
  );
  const selectedSalespersonIds = selectedSalespersons.map((salesperson: any) => salesperson.id);
  return selectedSalespersonIds;
};

const _new = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ organization, selectedOrganiz... Remove this comment to see the full error message
  ({organization, selectedOrganizationSlug}) => {
    // If the isEnabledBookingCalendarSubContractorViewOwnOrganizationJobs is turned on,
    // Show the jobs created by the subcontractors
    // This is for organizations that will create jobs directly in the subcontractor organizations
    // Else, if this is a subcontractor organization, get the projecttypes and salespersons from the parent organization
    // This is to ensure we can view the jobs created by the parent and assigned to the subcontractors
    // This is the typical case where jobs are created in the parent organization then assigned to the subcontractors
    const primaryCompanyOrganization = _.find(
      organization.company.organizations,
      (companyOrganization) => {
        if (organization.features.isEnabledBookingCalendarSubContractorViewOwnOrganizationJobs)
          return companyOrganization.slug === selectedOrganizationSlug;
        return companyOrganization.isPrimary;
      },
    );

    return {
      isEnabledBookingCalendarPackingFilters:
        organization.features.isEnabledBookingCalendarPackingFilters,
      showOnlyPackingJobs: false,
      showOnlyNonPackingJobs: false,
      projectTypes: Organization.getProjectTypes(primaryCompanyOrganization),
      salespersons: Organization.getSalesPersons(primaryCompanyOrganization),
      selectedOrganizationSlug,
    };
  },
  gql`
    ${Organization.getProjectTypes.fragment}
    ${Organization.getSalesPersons.fragment}

    fragment BookingFiltersForm_new on Organization {
      id
      features {
        isEnabledBookingCalendarPackingFilters: isEnabled(
          feature: "BOOKING_CALENDAR_PACKING_FILTERS"
        )
        isEnabledBookingCalendarSubContractorViewOwnOrganizationJobs: isEnabled(
          feature: "BOOKING_CALENDAR_SUB_CONTRACTOR_VIEW_OWN_ORGANIZATION_JOBS"
        )
      }
      company {
        organizations {
          id
          slug
          isPrimary
          ...Organization_getProjectTypes
          ...Organization_getSalesPersons
        }
      }
    }
  `,
);

const BookingFiltersForm = {
  new: _new,
  getSelectedSalespersonIds,
  getSelectedProjectTypeIds,
};

export default BookingFiltersForm;
