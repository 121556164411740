// Libraries
import _ from 'lodash';
import React from 'react';
import {components as SelectComponents} from 'react-select';

// Supermove
import {Styled, Space, DropdownInput} from '@supermove/components';
import {ProjectType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

import FieldInput from '@shared/design/components/Field/FieldInput';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const OptionText = Styled.Text`
  ${Typography.Body3}
`;

const ColorDot = Styled.View`
  height: 14px;
  width: 14px;
  border-radius: 14px;
  background-color: ${({color}) => color};
`;

const ColorOption = ({isSelected, value, label}) => {
  return (
    <Row>
      <ColorDot color={value} />
      <Space width={8} />
      <OptionText style={{color: isSelected ? colors.white : colors.black}}>{label}</OptionText>
    </Row>
  );
};

const getColorOptions = ({isCurrentColorCustom, currentColor}) => {
  return isCurrentColorCustom
    ? [{label: 'Custom Color', value: currentColor}, ...ProjectType.getColorOptions()]
    : ProjectType.getColorOptions();
};

const ProjectTypeColorDropdown = ({form, field, label, index, placeholder, disabled}) => {
  const currentColor = _.get(form.values, field);
  const isColorAnOption = ProjectType.getColorOptions().some(
    (colorOption) => colorOption.value === currentColor,
  );
  const isCurrentColorCustom = !!currentColor && !isColorAnOption;

  return (
    <FieldInput
      {...form}
      label={label}
      name={field}
      component={DropdownInput}
      index={index}
      input={{
        required: !_.get(form.values, field),
        options: getColorOptions({isCurrentColorCustom, currentColor}),
        placeholder,
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        disabled,
        components: {
          SingleValue: (props) => {
            return (
              <SelectComponents.SingleValue {...props}>
                <ColorOption value={props.data.value} label={props.data.label} />
              </SelectComponents.SingleValue>
            );
          },
          Option: (props) => {
            return (
              <SelectComponents.Option {...props}>
                <ColorOption
                  isSelected={props.isSelected}
                  value={props.data.value}
                  label={props.data.label}
                />
              </SelectComponents.Option>
            );
          },
        },
      }}
    />
  );
};

export default ProjectTypeColorDropdown;
