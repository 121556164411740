// Supermove
import {StripeType} from '@supermove/payments';

const wrap: StripeType['wrap'] = (stripe) => {
  return {
    setAccountId: async ({id}) => {
      // No-op on dom since this is handled by the StripeForm provider.
      return Promise.resolve(true);
    },

    confirmPaymentIntent: async ({clientSecret, params}) => {
      const {card} = params;
      return stripe.confirmCardPayment(clientSecret, {
        // Params includes the `{card: element}` along with billing details.
        // See: https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-payment
        payment_method: {
          card,
        },
        setup_future_usage: params.setupFutureUsage || null,
      });
    },

    createPaymentMethod: async ({params}) => {
      const {method, billingDetails} = params;
      return stripe.createPaymentMethod(method, {billing_details: billingDetails});
    },

    confirmCardPayment: async ({clientSecret, paymentMethodId}) => {
      return stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
      });
    },

    // DEPRECATED(mark): Stripe has deprecated this in favor of the confirmCardPayment method.
    handleCardPayment: async ({clientSecret}) => {
      return stripe.handleCardPayment(clientSecret);
    },
  };
};

const Stripe: StripeType = {
  wrap,
};

export default Stripe;
