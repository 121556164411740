// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// App
import {DocumentFreightBillInfo, DocumentHeaderOrganization} from 'modules/Document/components';
import DocumentBillingInformation from 'modules/Document/components/DocumentBillingInformation';
import DocumentMoveTimesChart from 'modules/Document/components/DocumentMoveTimesChart';

const Container = Styled.View`
  width: 100%;
  background-color: ${colors.white};
`;

const Section = Styled.View`
  margin-top: 20px;
`;

const SectionHeader = Styled.View`
  margin-bottom: 5px;
`;

const HeaderText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const JobReportMoveDocument = ({job}) => {
  return (
    <Container>
      <DocumentHeaderOrganization title={'Moving Services Bill'} organization={job.organization} />
      <DocumentFreightBillInfo job={job} />
      <Section>
        <SectionHeader>
          <HeaderText>Work Timesheet</HeaderText>
        </SectionHeader>
        <DocumentMoveTimesChart hasConfirmedTimes timeRanges={job.reportMove.timeRanges} />
      </Section>
      <Section>
        <DocumentBillingInformation showBillRules={false} showPayments job={job} />
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobReportMoveDocument.propTypes = {
  job: PropTypes.object.isRequired,
};

JobReportMoveDocument.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobReportMoveDocument.fragment = gql`
  ${DocumentBillingInformation.fragment}
  ${DocumentFreightBillInfo.fragment}
  ${DocumentHeaderOrganization.fragment}
  ${DocumentMoveTimesChart.fragment}

  fragment JobReportMoveDocument on Job {
    id
    organization {
      id
      ...DocumentHeaderOrganization
    }
    reportMove {
      id
      timeRanges {
        ...DocumentMoveTimesChart
      }
    }
    estimateBill {
      id
      balance
    }
    finalBill {
      id
      balance
    }
    mainBill {
      id
      balance
    }
    ...DocumentBillingInformation
    ...DocumentFreightBillInfo
  }
`;

export default JobReportMoveDocument;
