// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {colors} from '@supermove/styles';

// App
import Switch from 'modules/App/components/Switch';

const SwitchField = ({name, values, setFieldValue, onChangeValue, disabled}) => {
  const isEnabled = _.get(values, name, false);

  return (
    <Switch
      isOn={isEnabled}
      disabled={disabled}
      color={colors.blue.interactive}
      size={Switch.SIZE.MEDIUM}
      onChange={(value) => {
        setFieldValue(name, value);
        setTimeout(() => onChangeValue(name, value), 0);
      }}
    />
  );
};

SwitchField.defaultProps = {
  onChangeValue: () => {},
};

export default SwitchField;
