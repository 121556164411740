// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {JobRequest, Organization} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime, Language, Phone} from '@supermove/utils';

// App

// Components
import {FieldValue} from 'modules/App/components';
import {LocationsFieldValues} from 'modules/Job/components';

import JobRequestInternalNotes from './JobRequestInternalNotes';

const Container = Styled.View`
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index}px;
`;

const Title = Styled.H5`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 15)}px;
`;

const Line = Styled.View`
  margin-top: ${(props) => (props.mobile ? 30 : 40)}px;
`;

const CustomerSection = ({index, jobRequest, responsive}) => (
  <Section index={index}>
    <Title>Customer Information</Title>
    <Row>
      <FieldValue label={'Contact Name'} value={jobRequest.customer.firstName} style={{flex: 1}} />
    </Row>
    <RowSpace {...responsive} />
    <Row>
      <FieldValue
        label={'Phone Number'}
        value={Phone.display(jobRequest.customer.phoneNumber)}
        link={Phone.createUrl(jobRequest.customer.phoneNumber)}
        style={{flex: 1}}
      />
    </Row>
    <RowSpace {...responsive} />
    <Row>
      <FieldValue
        label={'Email'}
        value={jobRequest.customer.email}
        link={`mailto:${jobRequest.customer.email}`}
        style={{flex: 1}}
      />
    </Row>
  </Section>
);

const MoveSection = ({index, jobRequest, responsive}) => (
  <Section index={index}>
    <Title>Move Information</Title>
    <Row>
      <FieldValue label={'Move Size'} value={jobRequest.moveSize} style={{flex: 1}} />
    </Row>
    <RowSpace {...responsive} />
    <Row>
      <FieldValue
        label={'Preferred Date'}
        value={
          jobRequest.preferredDate ? Datetime.convertToDisplayDate(jobRequest.preferredDate) : null
        }
        empty={'No Date Specified'}
        style={{flex: 1}}
      />
    </Row>
    {!!jobRequest.preferredStartTime && (
      <React.Fragment>
        <RowSpace {...responsive} />
        <Row>
          <FieldValue
            label={'Preferred Time'}
            value={Datetime.convertToDisplayTime(jobRequest.preferredStartTime)}
            style={{flex: 1}}
          />
        </Row>
      </React.Fragment>
    )}
    <RowSpace {...responsive} />
    <Row>
      <FieldValue
        label={'Date and time are flexible'}
        value={Language.yesNo(jobRequest.isFlexible)}
        style={{flex: 1}}
      />
    </Row>
  </Section>
);

const AdditionalSection = ({index, jobRequest, responsive}) => (
  <Section index={index}>
    <Title>Additional Information</Title>
    {_.map(JobRequest.getAdditionalItems(jobRequest), (value, key) => (
      <React.Fragment key={key}>
        <Row>
          <FieldValue
            label={Organization.getJobFormAdditionalItemLabel(jobRequest.organization, {key})}
            value={Organization.getJobFormAdditionalItemValue(jobRequest.organization, {
              key,
              value,
            })}
            empty={'None'}
            style={{flex: 1}}
          />
        </Row>
        <RowSpace {...responsive} />
      </React.Fragment>
    ))}
    <Row>
      <FieldValue label={'Notes'} value={jobRequest.notes} empty={'None'} style={{flex: 1}} />
    </Row>
  </Section>
);

const InternalSection = ({index, jobRequest, responsive}) => (
  <Section index={index}>
    <Title>Internal Information</Title>
    <JobRequestInternalNotes jobRequest={jobRequest} />
    <RowSpace {...responsive} />
    <Row>
      <FieldValue
        label={'Salesperson'}
        value={jobRequest.bookedBy ? jobRequest.bookedBy.firstName : null}
        empty={'None'}
        style={{flex: 1}}
      />
    </Row>
    <RowSpace {...responsive} />
    <Row>
      <FieldValue
        label={'How did you hear about us?'}
        value={JobRequest.getDisplayReferral(jobRequest)}
        empty={'Unknown'}
        style={{flex: 1}}
      />
    </Row>
    <RowSpace {...responsive} />
    <Row>
      <FieldValue
        label={'Created At'}
        value={Datetime.convertToDisplayDatetime(jobRequest.createdAt)}
        empty={'Unknown'}
        style={{flex: 1}}
      />
    </Row>
  </Section>
);

const JobRequestFieldValues = ({isCustomer, jobRequest}) => {
  const responsive = useResponsive();

  return (
    <Container>
      {!isCustomer && (
        <React.Fragment>
          <InternalSection index={0} jobRequest={jobRequest} responsive={responsive} />
          <Line {...responsive} />
        </React.Fragment>
      )}
      <CustomerSection index={1} jobRequest={jobRequest} responsive={responsive} />
      <Line {...responsive} />
      <MoveSection index={2} jobRequest={jobRequest} responsive={responsive} />
      <Line {...responsive} />
      <LocationsFieldValues
        isCustomer={isCustomer}
        index={3}
        totalDistance={jobRequest.totalDistance}
        locations={jobRequest.locations}
        warehouseLocation={!isCustomer ? jobRequest.organization.warehouseLocation : null}
      />
      <Line {...responsive} />
      <AdditionalSection index={4} jobRequest={jobRequest} responsive={responsive} />
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobRequestFieldValues.propTypes = {
  isCustomer: PropTypes.bool,
  jobRequest: PropTypes.object.isRequired,
};

JobRequestFieldValues.defaultProps = {
  isCustomer: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestFieldValues.fragment = gql`
  ${JobRequest.getDisplayReferral.fragment}
  ${JobRequestInternalNotes.fragment}
  ${LocationsFieldValues.fragment}
  ${Organization.getJobFormAdditionalItemLabel.fragment}
  ${Organization.getJobFormAdditionalItemValue.fragment}

  fragment JobRequestFieldValues on JobRequest {
    id
    moveSize
    preferredCrewSize
    preferredDate
    preferredStartTime
    totalDistance
    isFlexible
    notes
    additionalItems
    createdAt
    bookedBy {
      id
      firstName
    }
    organization {
      id
      warehouseLocation {
        latitude
        longitude
      }
      ...Organization_getJobFormAdditionalItemLabel
      ...Organization_getJobFormAdditionalItemValue
    }
    customer {
      id
      firstName
      phoneNumber
      email
    }
    locations {
      id
      ...LocationsFieldValues
    }
    ...JobRequest_getDisplayReferral
    ...JobRequestInternalNotes
  }
`;

export default JobRequestFieldValues;
