// Libraries
import _ from 'lodash';

const _new = ({ids, property, booleanValue}: any) => ({
  ids,
  property,
  booleanValue,
});

const toForm = ({ids, property, booleanValue}: any) => ({
  ids,
  property,
  booleanValue,
});

const toMutation = ({ids, property, booleanValue}: any) => ({
  ids: ids.map((id: any) => _.toNumber(id)),
  property,
  booleanValue,
});

const BulkUpdateBooleanPropertyForm = {
  new: _new,
  toForm,
  toMutation,
};

export default BulkUpdateBooleanPropertyForm;
