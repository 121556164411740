// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    replyToKind: (organization as any).settings.replyToKind,
    replyToEmail: (organization as any).settings.replyToEmail,
  }),
  gql`
    fragment OrganizationReplyToForm_edit on Organization {
      id
      settings {
        id
        replyToKind
        replyToEmail
      }
    }
  `,
);

const toForm = ({organizationId, replyToKind, replyToEmail}: any) => ({
  organizationId,
  replyToKind,
  replyToEmail: replyToEmail || '',
});

const toMutation = ({organizationId, replyToKind, replyToEmail}: any) => ({
  organizationId,
  replyToKind,
  replyToEmail,
});

const OrganizationReplyToForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationReplyToForm;
