// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Bill, Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const Row = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
  flex: 1;
  justify-content: center;
`;

const CaptionText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const BillingDocumentBillHeaderRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const BillTitleText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.primary};
`;

const BadgeContainer = Styled.View`
  padding-horizontal: 8px;
  background-color: ${colors.gray.border};
  border-radius: 4px;
  justify-content: center;
  height: 20px;
`;

const BadgeText = Styled.Text`
  ${Typography.Label4}
  font-size: 10px;
  color: ${colors.gray.primary}
`;

const BillingDocumentBillHeader = ({bill}: any) => {
  return (
    <BillingDocumentBillHeaderRow>
      <Column>
        {!!bill.job && (
          <React.Fragment>
            <Row>
              {!!bill.job.date && (
                <React.Fragment>
                  <BadgeContainer>
                    <BadgeText>
                      {Job.getDisplayDate(bill.job, Datetime.DISPLAY_MONTH_DAY)}
                    </BadgeText>
                  </BadgeContainer>
                  <Space width={4} />
                </React.Fragment>
              )}
              <BadgeContainer>
                <BadgeText numberOfLines={1}>{Bill.getJobName(bill)}</BadgeText>
              </BadgeContainer>
            </Row>
            <Space height={8} />
          </React.Fragment>
        )}
        <BillTitleText numberOfLines={2}>{bill.title}</BillTitleText>
        {!!bill.description && (
          <React.Fragment>
            <Space height={4} />
            <CaptionText>{bill.description}</CaptionText>
          </React.Fragment>
        )}
      </Column>
    </BillingDocumentBillHeaderRow>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillingDocumentBillHeader.fragment = gql`
  ${Bill.getJobName.fragment}
  ${Job.getDisplayDate.fragment}

  fragment BillingDocumentBillHeader on Bill {
    id
    description
    job {
      id
      date
      ...Job_getDisplayDate
    }
    ...Bill_getJobName
  }
`;

export default BillingDocumentBillHeader;
