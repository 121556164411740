// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import AttachmentsPanel from 'modules/Project/Attachments/components/AttachmentsPanel';

const Container = Styled.View`
  align-self: stretch;
`;

const Title = Styled.Text`
  ${Typography.Heading1}
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TitleAndStatus = Styled.View`
  flex: 1;
  flex-direction: ${(props) => (props.desktop ? 'row' : 'column')};
  align-items: ${(props) => (props.desktop ? 'center' : 'flex-start')};
`;

const JobAttachmentsBlock = ({job, refetch}) => {
  const responsive = useResponsive();

  return (
    <Container>
      <Header>
        <TitleAndStatus {...responsive}>
          <Title>Attachments</Title>
        </TitleAndStatus>
      </Header>
      <Space height={8} />
      <AttachmentsPanel project={job.project} refetch={refetch} />
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobAttachmentsBlock.propTypes = {
  job: PropTypes.object.isRequired,
};

JobAttachmentsBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobAttachmentsBlock.fragment = gql`
  ${AttachmentsPanel.fragment}
  fragment JobAttachmentsBlock on Job {
    id
    project {
      id
      ...AttachmentsPanel
    }
  }
`;

export default JobAttachmentsBlock;
