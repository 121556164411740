// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SurveyMethodForm from '@shared/modules/Inventory/forms/SurveyMethodForm';

const useUpsertSurveyMethodMutation = ({surveyMethodForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      surveyMethodForm: SurveyMethodForm.toForm(surveyMethodForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertSurveyMethodMutation.mutation,
    variables: {
      surveyMethodForm: SurveyMethodForm.toMutation(form.values.surveyMethodForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpsertSurveyMethodMutation.mutation = gql`
  mutation useUpsertSurveyMethodMutation($surveyMethodForm: SurveyMethodForm!) {
    response: upsertSurveyMethod(surveyMethodForm: $surveyMethodForm) {
      ${gql.errors}
      surveyMethod {
        id
      }
    }
  }
`;

export default useUpsertSurveyMethodMutation;
