// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import ProjectTypeVariableSectionForm from '@shared/modules/Variable/forms/ProjectTypeVariableSectionForm';

const edit = withFragment(
  (projectType) => ({
    projectTypeId: (projectType as any).id,
    projectTypeVariableSectionForms: (projectType as any).projectTypeVariableSections.map(
      (projectTypeVariableSection: any) =>
        ProjectTypeVariableSectionForm.edit(projectTypeVariableSection),
    ),
    // Private
    isEdit: false,
    selectedVariableIds: [],
  }),
  gql`
    ${ProjectTypeVariableSectionForm.edit.fragment}
    fragment ProjectTypeVariableSectionsForm_edit on ProjectType {
      id
      projectTypeVariableSections {
        id
        ...ProjectTypeVariableSectionForm_edit
      }
    }
  `,
);

const toForm = ({
  projectTypeId,
  projectTypeVariableSectionForms,
  isEdit,
  selectedVariableIds,
}: any) => ({
  projectTypeId,
  projectTypeVariableSectionForms: projectTypeVariableSectionForms.map(
    (projectTypeVariableSectionForm: any) =>
      ProjectTypeVariableSectionForm.toForm(projectTypeVariableSectionForm),
  ),

  // Private
  isEdit,
  selectedVariableIds,
});

const toMutation = ({projectTypeId, projectTypeVariableSectionForms}: any) => ({
  projectTypeId,
  projectTypeVariableSectionForms: projectTypeVariableSectionForms.map(
    (projectTypeVariableSectionForm: any) =>
      ProjectTypeVariableSectionForm.toMutation(projectTypeVariableSectionForm),
  ),
});

const removeVariableFormsById = ({projectTypeVariableSectionsForm, variableIdsToRemove}: any) => {
  const projectTypeVariableSectionForms = _.get(
    projectTypeVariableSectionsForm,
    'projectTypeVariableSectionForms',
    [],
  );
  return _.flatMap(projectTypeVariableSectionForms, (projectTypeVariableSectionForm) =>
    ProjectTypeVariableSectionForm.removeVariableFormsById({
      projectTypeVariableSectionForm,
      variableIdsToRemove,
    }),
  );
};

const getRemovedVariableFormsForDestination = ({
  form,
  projectTypeVariableSectionsForm,
  variableIdsToRemove,
  destinationField,
}: any) => {
  const removedVariableForms = removeVariableFormsById({
    projectTypeVariableSectionsForm,
    variableIdsToRemove,
  });
  const destinationSectionVariableFormsField = `${destinationField}.projectTypeVariableForms`;
  const existingVariablesInNewSection = _.get(form.values, destinationSectionVariableFormsField);
  return [...existingVariablesInNewSection, ...removedVariableForms];
};

const removeVariableSection = ({form, sectionFieldToRemove, destinationField}: any) => {
  const projectTypeVariableSectionsForm = _.get(form.values, 'projectTypeVariableSectionsForm');
  const variableSectionFormToDelete = _.get(form.values, sectionFieldToRemove);
  const variableIdsToRemove = ProjectTypeVariableSectionForm.getVariableIdsInSection({
    projectTypeVariableSectionForm: variableSectionFormToDelete,
  });
  const updatedVariableForms = getRemovedVariableFormsForDestination({
    form,
    projectTypeVariableSectionsForm,
    variableIdsToRemove,
    destinationField,
  });
  const destinationSectionVariableFormsField = `${destinationField}.projectTypeVariableForms`;
  _.set(form.values, destinationSectionVariableFormsField, updatedVariableForms);

  const existingSectionForms = _.get(
    form.values,
    'projectTypeVariableSectionsForm.projectTypeVariableSectionForms',
  );
  const sectionFormToRemove = _.get(form.values, sectionFieldToRemove);
  const updatedSectionForms = _.difference(existingSectionForms, [sectionFormToRemove]);
  projectTypeVariableSectionsForm.projectTypeVariableSectionForms = updatedSectionForms;
  form.setFieldValue(
    'projectTypeVariableSectionsForm.projectTypeVariableSectionForms',
    updatedSectionForms,
  );
};

const moveVariablesToSection = ({form, variableIdsToMove, destinationField}: any) => {
  const projectTypeVariableSectionsForm = _.get(form.values, 'projectTypeVariableSectionsForm');
  const updatedVariableForms = getRemovedVariableFormsForDestination({
    form,
    projectTypeVariableSectionsForm,
    variableIdsToRemove: variableIdsToMove,
    destinationField,
  });
  form.setFieldValue(`${destinationField}.projectTypeVariableForms`, updatedVariableForms);
};

const ProjectTypeVariableSectionsForm = {
  edit,
  toForm,
  toMutation,

  removeVariableSection,
  moveVariablesToSection,
};

export default ProjectTypeVariableSectionsForm;
