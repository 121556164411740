/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const LineView = Styled.View`
  flex: 1;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const Line = () => {
  return <LineView />;
};

export default Line;
