// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import PayengineCreditCardForm from '@shared/modules/Payment/forms/PayengineCreditCardForm';
import useSavePayEngineCreditCardV2 from '@shared/modules/Payment/hooks/useSavePayEngineCreditCardV2';
import ClientCreditCardFields from 'modules/Client/components/ClientCreditCardFields';

const ContentContainer = Styled.View`
`;

const DescriptionText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const ClientAddCreditCardModalContent = ({client, projectId, onSuccess, onClose, responsive}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const payengineCreditCardForm = PayengineCreditCardForm.new({
    isDefault: _.isEmpty(client.creditCards),
    projectId,
  });
  const {form, submitting, handleSubmit, setCreditCardClient} = useSavePayEngineCreditCardV2({
    clientId: client.id,
    payengineCreditCardForm,
    onSuccess: (response) => {
      setErrorMessage(null);
      onSuccess(response);
    },
    onError: (error) => {
      console.error(`Error executing SavePayengineCreditCardMutation: `, error);
      setErrorMessage(`Could not save credit card.`);
    },
    handleCardErrorMessage: setErrorMessage,
  });

  const field = `form.payengineCreditCardForm`;

  return (
    <React.Fragment>
      {errorMessage && (
        <React.Fragment>
          <ErrorCallout text={errorMessage} />
          <Space height={16} />
        </React.Fragment>
      )}
      <ClientCreditCardFields
        client={client}
        form={form}
        field={field}
        setCreditCardClient={setCreditCardClient}
      />
      <Space height={32} />
      {responsive.desktop ? (
        <SmallModal.Footer>
          <QuaternaryButton onPress={onClose} text={'Cancel'} />
          <Space width={16} />
          <Button
            isSubmitting={submitting}
            onPress={handleSubmit}
            iconLeft={Icon.Check}
            text={'Save'}
          />
        </SmallModal.Footer>
      ) : (
        <Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          iconLeft={Icon.Check}
          text={'Save'}
          isResponsive
          isWidthOfContainer
        />
      )}
    </React.Fragment>
  );
};

const ClientAddCreditCardModal = ({client, projectId, isOpen, handleClose, onSuccess}) => {
  const responsive = useResponsive();

  return (
    <SmallModal
      isOpen={isOpen}
      onClose={handleClose}
      isMobileSheet
      sheetLabel={'Add Credit Card'}
      handleClose={handleClose}
    >
      {responsive.desktop && (
        <React.Fragment>
          <SmallModal.HeaderText>Add Credit Card</SmallModal.HeaderText>
          <Space height={16} />
        </React.Fragment>
      )}
      <ContentContainer>
        <DescriptionText responsive={responsive}>
          This card will be saved to this client's profile.
        </DescriptionText>
        <Space height={16} />
        <ClientAddCreditCardModalContent
          client={client}
          projectId={projectId}
          onSuccess={() => {
            handleClose();
            onSuccess && onSuccess();
          }}
          onClose={handleClose}
          responsive={responsive}
        />
      </ContentContainer>
    </SmallModal>
  );
};

ClientAddCreditCardModal.fragment = gql`
  ${ClientCreditCardFields.fragment}

  fragment ClientAddCreditCardModal on Client {
    id
    creditCards {
      id
    }
    ...ClientCreditCardFields
  }
`;

export default ClientAddCreditCardModal;
