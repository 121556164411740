// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectIsTestForm from '@shared/modules/Project/forms/ProjectIsTestForm';

const useSetProjectIsTestMutation = ({projectIsTestForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      projectIsTestForm: ProjectIsTestForm.toForm(projectIsTestForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSetProjectIsTestMutation.mutation,
    variables: {
      projectIsTestForm: ProjectIsTestForm.toMutation(form.values.projectIsTestForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSetProjectIsTestMutation.mutation = gql`
  mutation useSetProjectIsTestMutation($projectIsTestForm: ProjectIsTestForm!) {
    response: setProjectIsTest(projectIsTestForm: $projectIsTestForm) {
      ${gql.errors}
      project {
        id
        isTest
      }
    }
  }
`;

export default useSetProjectIsTestMutation;
