// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {DateInput, DropdownInput, PhoneInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useRef, useResponsive} from '@supermove/hooks';
import {ReferralSource} from '@supermove/models';
import {fontWeight, colors, Typography} from '@supermove/styles';

// Components
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import {JobAdditionalItemsSection, JobMap, LocationFormsSection} from 'modules/Job/components';
import ProjectSizeInput from 'modules/Project/components/ProjectSizeInput';

const Container = Styled.View`
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index}px;
`;

const SectionSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 30 : 40)}px;
`;

const Title = Styled.H4`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  width: 100%;
  z-index: ${({index = 0}) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 15)}px;
`;

const FieldSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 0)}px;
  margin-left: ${(props) => (props.mobile ? 0 : 10)}px;
`;

const Disclosure = Styled.H7`
  color: ${colors.gray.tertiary};
`;

const CheckboxText = Styled.Text`
  ${Typography.Body}
`;

const getFieldName = ({field, name}) => {
  return field ? `${field}.${name}` : name;
};

const getCustomerFieldName = ({field, name}) => {
  return field ? `${field}.customerForm.${name}` : name;
};

const getFieldValue = ({form, name}) => {
  return _.get(form.values, name);
};

const CustomerSection = ({index, field, form, responsive}) => {
  const namesField0 = getCustomerFieldName({field, name: 'names.0'});
  const namesField1 = getCustomerFieldName({field, name: 'names.1'});
  const phoneNumberField = getCustomerFieldName({field, name: 'phoneNumber'});
  const emailField = getCustomerFieldName({field, name: 'email'});

  return (
    <Section index={index}>
      <Title>What is your contact information?</Title>
      <Row {...responsive}>
        <FieldInput.Memoized
          {...form}
          name={namesField0}
          label={form.values.shouldShowSingleNameCustomerField ? 'Full Name' : 'First Name'}
          input={{
            required: !getFieldValue({form, name: namesField0}),
            placeholder: form.values.shouldShowSingleNameCustomerField
              ? 'Enter full name'
              : 'Enter first name',
            style: {
              flex: 1,
            },
          }}
          style={{flex: 1}}
        />
        {!form.values.shouldShowSingleNameCustomerField && (
          <React.Fragment>
            <FieldSpace {...responsive} />
            <FieldInput.Memoized
              {...form}
              name={namesField1}
              label={'Last Name'}
              input={{
                required: !getFieldValue({form, name: namesField1}),
                placeholder: 'Enter last name',
                style: {
                  flex: 1,
                },
              }}
              style={{flex: 1}}
            />
          </React.Fragment>
        )}
      </Row>
      <RowSpace {...responsive} />
      <Row {...responsive}>
        <FieldInput.Memoized
          {...form}
          component={PhoneInput}
          name={phoneNumberField}
          label={'Phone Number'}
          input={{
            required: !getFieldValue({form, name: phoneNumberField}),
            placeholder: 'Enter phone number',
            setFieldValue: form.setFieldValue,
            style: {
              flex: 1,
            },
          }}
          style={{flex: 1}}
        />
        <FieldSpace {...responsive} />
        <FieldInput.Memoized
          {...form}
          name={emailField}
          label={'Email Address'}
          input={{
            required: !getFieldValue({form, name: emailField}),
            placeholder: 'Enter email address',
            style: {
              flex: 1,
            },
          }}
          style={{flex: 1}}
        />
      </Row>
    </Section>
  );
};

const MoveSection = ({index, field, form, responsive, organization}) => {
  const moveSizeField = getFieldName({field, name: 'moveSize'});
  const preferredDateField = getFieldName({field, name: 'preferredDate'});
  const preferredStartTimeField = getFieldName({field, name: 'preferredStartTime'});

  return (
    <Section index={index}>
      <Title>When would you like to move?</Title>
      <Row index={0} {...responsive}>
        <FieldInput.Memoized
          {...form}
          index={0}
          component={DropdownInput}
          name={moveSizeField}
          label={'Move Size'}
          input={{
            required: !getFieldValue({form, name: moveSizeField}),
            // An empty option is added for internal variable dropdowns to allow unsetting the value
            // We remove it here since this is end user facing
            options: ProjectSizeInput.getProjectSizeValueOptions({
              isEnabledBillingAdditionalVariables:
                organization.features.isEnabledBillingAdditionalVariables,
              organization,
              hardcodedOptions: form.values.jobFormCustomValues.moveSize,
            }).filter((option) => option.value !== ''),
            placeholder: 'Enter size',
            setFieldValue: form.setFieldValue,
            style: {
              flex: 1,
            },
          }}
          style={{flex: 1}}
        />
        <FieldSpace {...responsive} />
        <FieldInput.Memoized
          {...form}
          index={1}
          component={DateInput}
          name={preferredDateField}
          label={'Preferred Date'}
          input={{
            isPastDisabled: true,
            required: !getFieldValue({form, name: preferredDateField}),
            placeholder: 'Enter date',
            setFieldValue: form.setFieldValue,
            style: {
              width: '100%',
            },
          }}
          style={{flex: 1}}
        />
        {form.values.jobFormCustomValues.preferredStartTime.length > 0 && (
          <React.Fragment>
            <FieldSpace {...responsive} />
            <FieldInput.Memoized
              {...form}
              index={2}
              component={DropdownInput}
              name={preferredStartTimeField}
              label={'Preferred Time'}
              input={{
                required: !getFieldValue({form, name: preferredStartTimeField}),
                options: form.values.jobFormCustomValues.preferredStartTime,
                placeholder: 'Enter time',
                setFieldValue: form.setFieldValue,
                style: {
                  flex: 1,
                },
              }}
              style={{flex: 1}}
            />
          </React.Fragment>
        )}
      </Row>
      {_.get(form.values, 'jobRequestForm.moveSize') === 'Other' && (
        <React.Fragment>
          <RowSpace {...responsive} />
          <Row index={1}>
            <Disclosure>
              Please describe your move in the "Additional Information" at the bottom of this form
              so we can better serve you.
            </Disclosure>
          </Row>
        </React.Fragment>
      )}
      <RowSpace {...responsive} />
      <Row index={2}>
        <Disclosure>
          We will do our best to accommodate your preferred date and time, but we cannot guarantee
          we will be able to, due to our availabilities.
        </Disclosure>
      </Row>
      <RowSpace {...responsive} />
      <Row index={3} {...responsive}>
        <Checkbox
          isChecked={_.get(form.values, getFieldName({field, name: 'isFlexible'}))}
          handleToggle={(value) =>
            form.setFieldValue(getFieldName({field, name: 'isFlexible'}), value)
          }
          childrenRight
        >
          <Space width={8} />
          <CheckboxText>Date and time are flexible</CheckboxText>
        </Checkbox>
      </Row>
    </Section>
  );
};

const LocationsSection = ({index, field, form, organization}) => {
  const mapRef = useRef();

  return (
    <Section index={index}>
      <Title>Where are you moving from/to?</Title>
      <Disclosure
        style={{
          marginBottom: 15,
        }}
      >
        Please enter in all locations you'll want us to stop at during your move (a typical move is
        two stops).
      </Disclosure>
      <LocationFormsSection
        sectionIndex={2}
        field={`${field}.locationForms`}
        form={form}
        locationForms={_.get(form.values, `${field}.locationForms`)}
        organization={organization}
        isBuildingTypeRequired
        mapRef={mapRef}
      />
      <JobMap
        isHidden
        locations={_.get(form.values, `${field}.locationForms`)}
        onRouteUpdate={({distances}) => {
          form.setFieldValue(`${field}.locationDistances`, distances);
        }}
      />
    </Section>
  );
};

const AdditionalSection = ({index, field, form, responsive}) => (
  <Section index={index}>
    <Title>Is there anything else we should know about?</Title>
    <JobAdditionalItemsSection field={field} form={form} responsive={responsive} />
    <Row {...responsive} index={0}>
      <FieldInput.Memoized
        {...form}
        name={getFieldName({field, name: 'notes'})}
        label={'Additional Notes'}
        input={{
          multiline: true,
          placeholder:
            'Enter any additional information we should know about. If you are ' +
            'flexible with dates and times, please list your other availabilities here.',
          style: {
            paddingVertical: 9,
            flex: 1,
            minHeight: 90,
          },
        }}
        style={{flex: 1}}
      />
    </Row>
  </Section>
);

const ReferralSection = ({index, field, form, responsive, referralSources}) => {
  const referralSourceField = getFieldName({field, name: 'referralSource'});

  return (
    <Section index={index}>
      <Title>How did you hear about us?</Title>
      <Row index={0} {...responsive}>
        <FieldInput.Memoized
          {...form}
          index={0}
          component={DropdownInput}
          name={referralSourceField}
          input={{
            required: !getFieldValue({form, name: referralSourceField}),
            options: ReferralSource.getDropdownOptions({referralSources}),
            placeholder: 'Select one',
            setFieldValue: (name, value) => {
              if (value !== 'Other' && _.get(form.values, `${field}.referralDetails`)) {
                form.setFieldValue(`${field}.referralDetails`, '');
              }
              form.setFieldValue(name, value);
            },
            style: {
              flex: 1,
            },
          }}
          style={{flex: 1}}
        />
        {_.get(form.values, `${field}.referralSource`) === 'Other' && (
          <React.Fragment>
            <FieldSpace {...responsive} />
            <FieldInput.Memoized
              {...form}
              index={1}
              name={getFieldName({field, name: 'referralDetails'})}
              input={{
                placeholder: 'Please specify',
                style: {
                  flex: 1,
                },
              }}
              style={{flex: 1}}
            />
          </React.Fragment>
        )}
      </Row>
    </Section>
  );
};

const JobRequestFields = ({field, form, organization}) => {
  const responsive = useResponsive();

  return (
    <Container {...responsive}>
      <CustomerSection index={0} field={field} form={form} responsive={responsive} />
      <SectionSpace {...responsive} />
      <MoveSection
        index={1}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
      />
      <SectionSpace {...responsive} />
      <LocationsSection index={2} field={field} form={form} organization={organization} />
      <SectionSpace {...responsive} />
      <AdditionalSection
        index={2 + _.get(form.values, `${field}.locationForms.length`)}
        field={field}
        form={form}
        responsive={responsive}
      />
      <SectionSpace {...responsive} />
      <ReferralSection
        index={3 + _.get(form.values, `${field}.locationForms.length`)}
        field={field}
        form={form}
        responsive={responsive}
        referralSources={organization.settings.referralSources}
      />
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobRequestFields.propTypes = {
  form: PropTypes.object.isRequired,
};

JobRequestFields.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestFields.fragment = gql`
  ${ProjectSizeInput.fragment}
  ${ReferralSource.getDropdownOptions.fragment}
  ${LocationFormsSection.fragment}

  fragment JobRequestFields on Organization {
    id
    settings {
      id
      referralSources {
        ...ReferralSource_getDropdownOptions
      }
    }
    projectSizeVariable {
      id
      variableOptionsV3
    }
    features {
      isEnabledBillingAdditionalVariables: isEnabled(feature: "BILLING_ADDITIONAL_VARIABLES")
    }
    ...LocationFormsSection
    ...ProjectSizeInput
  }
`;

export default JobRequestFields;
