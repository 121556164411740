// Libraries
import React from 'react';

// Supermove
import {Icon, IconSource, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Location} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Location as LocationUtils} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import TextTooltip from '@shared/design/components/TextTooltip';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

interface LocationProps {
  latitude?: number;
  longitude?: number;
  address?: string;
  [key: string]: any;
}

const getIsDisabled = ({location}: {location: LocationProps}) => {
  return !location.address || !location.latitude || !location.longitude;
};

const getLocationFromLocationForm = ({latitude, longitude, address}: LocationProps) => {
  return {
    latitude,
    longitude,
    address,
  };
};

const getOpenIcon = ({isDisabled = false}: {isDisabled: boolean}) => {
  return (
    <Icon
      source={Icon.ArrowUpRightFromSquare}
      size={16}
      color={isDisabled ? colors.gray.tertiary : colors.blue.interactive}
    />
  );
};

const getMenuActions = ({
  location,
  navigator,
  hasStreetView = true,
}: {
  location: LocationProps;
  navigator: any;
  hasStreetView?: boolean;
}) => {
  const isDisabled = getIsDisabled({location});

  return [
    {
      text: 'Open in Google Maps',
      onPress: () => navigator.pushNewTab(LocationUtils.createGoogleMapsUrl(location.address)),
      rightComponent: getOpenIcon({isDisabled}),
      isDisabled,
      tooltip: isDisabled ? 'Unable to open location in Google Maps.' : '',
    },
    {
      text: 'Open in Zillow',
      onPress: () => navigator.pushNewTab(Location.getZillowLink(location)),
      rightComponent: getOpenIcon({isDisabled}),
      isDisabled,
      tooltip: isDisabled ? 'Unable to open location in Zillow.' : '',
    },
    {
      text: 'Open street view',
      onPress: () => navigator.pushNewTab(Location.getStreetViewLink(location)),
      rightComponent: getOpenIcon({isDisabled: isDisabled || !hasStreetView}),
      isDisabled: isDisabled || !hasStreetView,
      tooltip:
        isDisabled || !hasStreetView ? 'Street view is not available for this location.' : '',
    },
  ];
};

interface LocationButtonProps {
  location: LocationProps;
  isDisabled?: boolean;
  style?: React.CSSProperties;
}

interface StreetViewButtonProps extends LocationButtonProps {
  map?: any;
  hasStreetView?: boolean;
}

const ButtonContentTooltip = ({text, children}: {text: string; children: React.ReactNode}) => {
  return (
    <TextTooltip placement={'top'} text={text} style={{maxWidth: null}}>
      <Column>{children}</Column>
    </TextTooltip>
  );
};

const ExternalLinkButton = ({
  url,
  tooltip,
  icon,
  isDisabled,
  style = {},
}: {
  url: string;
  tooltip: string;
  icon: IconSource;
  isDisabled?: boolean;
  style?: any;
}) => {
  return (
    <a
      target={'_blank'}
      href={url}
      style={{justifyContent: 'center', alignItems: 'center', ...style}}
      onClick={(e) => isDisabled && e.preventDefault()}
    >
      <ButtonContentTooltip text={tooltip}>
        <Icon source={icon} color={isDisabled ? colors.gray.disabled : null} />
      </ButtonContentTooltip>
    </a>
  );
};

const GoogleMaps = ({location, isDisabled, style}: LocationButtonProps) => {
  return (
    <ExternalLinkButton
      url={LocationUtils.createGoogleMapsUrl(location.address)}
      tooltip={
        isDisabled
          ? 'Unable to open location in Google Maps.'
          : 'Open address on Google Maps in a new tab.'
      }
      icon={Icon.GoogleMaps}
      isDisabled={isDisabled}
      style={style}
    />
  );
};

const Zillow = ({location, isDisabled, style}: LocationButtonProps) => {
  return (
    <ExternalLinkButton
      url={Location.getZillowLink(location)}
      tooltip={
        isDisabled ? 'Unable to open location in Zillow.' : 'Open address on Zillow in a new tab.'
      }
      icon={Icon.Zillow}
      isDisabled={isDisabled}
      style={style}
    />
  );
};

const StreetView = ({location, map, isDisabled, hasStreetView, style}: StreetViewButtonProps) => {
  const isDisabledStreetView = !hasStreetView || isDisabled;

  return (
    <TertiaryButton
      isDisabled={isDisabledStreetView}
      onPress={() => {
        if (map) {
          const locationCoordinates = {lat: location.latitude, lng: location.longitude};
          const streetView = map.getStreetView();
          streetView.setPosition(locationCoordinates);
          streetView.setVisible(true);
        } else {
          window.open(Location.getStreetViewLink(location), '_blank');
        }
      }}
      style={style}
    >
      <ButtonContentTooltip
        text={
          isDisabledStreetView
            ? 'Street view is not available for this location.'
            : 'Opens street view in the map preview.'
        }
      >
        <Icon source={Icon.StreetView} color={isDisabledStreetView ? colors.gray.disabled : null} />
      </ButtonContentTooltip>
    </TertiaryButton>
  );
};

const ViewLocationButtons = ({
  location,
  map,
  showZillow = true,
  hasStreetView = true,
  buttonStyle = {width: 24, height: 24},
  rowStyle = {},
}: {
  location: LocationProps;
  map?: object;
  showZillow?: boolean;
  hasStreetView?: boolean;
  buttonStyle?: object;
  rowStyle?: object;
}) => {
  const isDisabled = getIsDisabled({location});

  return (
    <Row style={rowStyle}>
      <GoogleMaps location={location} isDisabled={isDisabled} style={buttonStyle} />
      {showZillow && (
        <React.Fragment>
          <Space width={8} />
          <Zillow location={location} isDisabled={isDisabled} style={buttonStyle} />
        </React.Fragment>
      )}
      <Space width={8} />
      <StreetView
        location={location}
        map={map}
        hasStreetView={hasStreetView}
        isDisabled={isDisabled}
        style={buttonStyle}
      />
    </Row>
  );
};

ViewLocationButtons.getLocationFromLocationForm = getLocationFromLocationForm;
ViewLocationButtons.getMenuActions = getMenuActions;

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewLocationButtons.fragment = gql`
  ${Location.getZillowLink.fragment}
  ${Location.getStreetViewLink.fragment}

  fragment ViewLocationButtons on Location {
    id
    address
    latitude
    longitude
    ...Location_getZillowLink
    ...Location_getStreetViewLink
  }
`;

export default ViewLocationButtons;
