// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import BillForm from '@shared/modules/Billing/forms/BillForm';

const useCreateBillV3Mutation = ({billForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      billForm: BillForm.toForm(billForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateBillV3Mutation.mutation,
    variables: {
      billForm: BillForm.toMutation(form.values.billForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateBillV3Mutation.mutation = gql`
  mutation useCreateBillV3Mutation($billForm: BillForm!) {
    response: createBillV3(billForm: $billForm) {
      ${gql.errors}
      bill {
        id
      }
    }
  }
`;

export default useCreateBillV3Mutation;
