// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import JobForm from '@shared/modules/Job/forms/JobForm';

const EmptySpace = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 24px;
`;

const HeaderTitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const NumberOfActiveJobsContainer = Styled.View`
  padding-horizontal: 8px;
  padding-vertical: 4px;
  background-color: ${colors.blue.accent};
  border-radius: 20px;
`;

const NumberOfActiveJobsText = Styled.H8`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const AddJobButton = ({onPress, isDisabled}) => {
  return (
    <SecondaryButton
      onPress={onPress}
      isDisabled={isDisabled}
      iconLeft={Icon.Plus}
      text={'Add Job'}
    />
  );
};

const insertJobForm = ({viewerId, project, form, jobFormsField, jobForms}) => {
  const newJobForm = JobForm.newForProjectForm({
    viewerId,
    organization: project.organization,
    projectForm: _.get(form.values, `projectForm`),
  });

  const newJobForms = [...jobForms, newJobForm];
  form.setFieldValue(jobFormsField, newJobForms);
};

const ProjectJobsHeader = ({viewerId, project, form, setSelectedIndex, isAtMaxJobs}) => {
  const jobFormsField = `projectForm.jobForms`;
  const jobForms = _.get(form.values, jobFormsField);
  const numberOfActiveJobsText = pluralize('Job', jobForms.length, true);

  return (
    <HeaderContainer>
      <HeaderTitle>{`Add Jobs to Project ${project.identifier}`}</HeaderTitle>
      <Space width={8} />
      <NumberOfActiveJobsContainer>
        <NumberOfActiveJobsText>{numberOfActiveJobsText}</NumberOfActiveJobsText>
      </NumberOfActiveJobsContainer>
      <EmptySpace />
      <AddJobButton
        isDisabled={isAtMaxJobs}
        onPress={() => {
          insertJobForm({viewerId, project, form, jobFormsField, jobForms});
          setSelectedIndex(jobForms.length);
        }}
      />
    </HeaderContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobsHeader.fragment = gql`
  ${JobForm.newForProjectForm.fragment}

  fragment ProjectJobsHeader on Project {
    id
    identifier
    organization {
      id
      ...JobForm_newForProjectForm
    }
  }
`;
export default ProjectJobsHeader;
