// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {PrintDocumentPage} from 'modules/Document/Print/components';
import {JobReportMoveDocument} from 'modules/Document/ReportMove/components';

const CustomerReportMoveJobPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <PrintDocumentPage query={CustomerReportMoveJobPage.query} variables={{uuid}}>
    {({data}) => <JobReportMoveDocument job={data.job} />}
  </PrintDocumentPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerReportMoveJobPage.query = gql`
  ${JobReportMoveDocument.fragment}

  query CustomerReportMoveJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      ...JobReportMoveDocument
    }
  }
`;

export default CustomerReportMoveJobPage;
