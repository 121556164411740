// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useState, useTextInput} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Currency, Float} from '@supermove/utils';

// App
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import EditBillLineItemCells from 'modules/Project/Billing/components/EditBillLineItemCells';

const Row = Styled.View`
  flex-direction: row;
`;

const KindLabelContainer = Styled.View`
  padding-horizontal: 8px;
  padding-top: ${(props) => (props.index > 0 ? 7 : 6)}px;
`;

const DescriptionButton = Styled.Touchable`
  position: absolute;
  top: 7px;
  right: 8px;
  flex-direction: row;
  align-items: center;
`;

const swapBillItems = (form, field, index1, index2) => {
  const billItemForms = _.get(form.values, field);
  [billItemForms[index1], billItemForms[index2]] = [billItemForms[index2], billItemForms[index1]];
  form.setFieldValue(field, billItemForms);
};

const removeBillItem = (form, field, index, billFormField) => {
  const deletedBillItemFormsField = `${billFormField}.deletedBillItemForms`;
  const billItemForms = _.get(form.values, field);
  const removedBillItemForm = _.get(form.values, `${field}.${index}`);
  const updatedBillItemForms = _.remove(billItemForms, (form, i) => i !== index);
  const deletedBillItemForms = [
    ..._.get(form.values, deletedBillItemFormsField),
    removedBillItemForm,
  ];
  form.setFieldValue(field, updatedBillItemForms);
  form.setFieldValue(deletedBillItemFormsField, deletedBillItemForms);
};

const MoveItemColumn = ({form, field, index}) => {
  const billItemFormsCount = _.get(form.values, field).length;
  const isFirstItem = index === 0;
  const isLastItem = index === billItemFormsCount - 1;
  const handleMoveItemUp = () => {
    swapBillItems(form, field, index, index - 1);
  };
  const handleMoveItemDown = () => {
    swapBillItems(form, field, index, index + 1);
  };

  return (
    <EditBillLineItemCells.IconCell>
      <EditBillLineItemCells.IconCircle
        activeOpacity={0.8}
        onPress={handleMoveItemUp}
        disabled={isFirstItem}
      >
        <Icon
          source={Icon.ArrowUp}
          size={12}
          color={isFirstItem ? colors.gray.border : colors.blue.interactive}
        />
      </EditBillLineItemCells.IconCircle>
      <Space height={2} />
      <EditBillLineItemCells.IconCircle
        activeOpacity={0.8}
        onPress={handleMoveItemDown}
        disabled={isLastItem}
      >
        <Icon
          source={Icon.ArrowDown}
          size={12}
          color={isLastItem ? colors.gray.border : colors.blue.interactive}
        />
      </EditBillLineItemCells.IconCircle>
    </EditBillLineItemCells.IconCell>
  );
};

const NameColumn = ({
  form,
  field,
  billItemForm,
  index,
  isShowingDescription,
  handleShowDescription,
}) => {
  const nameField = `${field}.${index}.name`;
  const hasError = _.get(form.errors, nameField);
  const disabled = !!_.get(form.values, `${field}.${index}.nameFormulaId`);
  const handleChangeText = (text) => {
    form.setFieldValue(nameField, text);
  };

  return (
    <EditBillLineItemCells.LeftDataCell
      disabled={disabled}
      isLarge
      hasBorder
      hasError={hasError}
      isFirstItem={index === 0}
      style={{width: null, flex: 1}}
    >
      <KindLabelContainer index={index}>
        <EditBillLineItemCells.CellCaption>
          {BillItemTypeCategory.getDisplayCategory(billItemForm.category, billItemForm)}
        </EditBillLineItemCells.CellCaption>
      </KindLabelContainer>
      <EditBillLineItemCells.NameInput
        handleChangeText={handleChangeText}
        value={billItemForm.name}
        disabled={disabled}
      />
      <DescriptionButton activeOpacity={0.8} onPress={handleShowDescription}>
        <EditBillLineItemCells.CellButtonText>Description</EditBillLineItemCells.CellButtonText>
        <Space width={4} />
        <Icon
          source={isShowingDescription ? Icon.ChevronUp : Icon.ChevronDown}
          color={colors.blue.interactive}
          size={11}
        />
      </DescriptionButton>
    </EditBillLineItemCells.LeftDataCell>
  );
};

const QuantityColumn = ({form, field, billItemForm, index}) => {
  const [isFocused, setIsFocused] = useState(false);
  const minQuantityField = `${field}.${index}.minQuantity`;
  const hasFieldError = _.get(form.errors, minQuantityField);
  const disabled = BillItemForm.getHasQuantityFormula(billItemForm);
  const handleChangeText = (text) => {
    form.setFieldValue(minQuantityField, Float.toFloat(text));
  };
  return (
    <EditBillLineItemCells.DataCell hasBorder isFirstItem={index === 0} disabled={disabled}>
      {isFocused ? (
        <KindLabelContainer index={index}>
          <EditBillLineItemCells.CellCaption>Quantity</EditBillLineItemCells.CellCaption>
          <Space height={24} />
        </KindLabelContainer>
      ) : !isFocused && !_.isEmpty(billItemForm.minQuantity) ? (
        <React.Fragment>
          <KindLabelContainer index={index}>
            <EditBillLineItemCells.CellCaption vars={{textAlign: 'right'}}>
              Quantity
            </EditBillLineItemCells.CellCaption>
            <EditBillLineItemCells.CellText vars={{textAlign: 'right'}}>
              {billItemForm.minQuantity}
            </EditBillLineItemCells.CellText>
          </KindLabelContainer>
        </React.Fragment>
      ) : (
        <EditBillLineItemCells.CellText vars={{hasPad: true}}>
          <EditBillLineItemCells.CellCaption>Quantity</EditBillLineItemCells.CellCaption>
        </EditBillLineItemCells.CellText>
      )}
      <EditBillLineItemCells.CellInput
        vars={{isFocused, alignLeft: true, hasError: hasFieldError}}
        width={'100%'}
        onFocus={() => setIsFocused(true)}
        onChangeText={handleChangeText}
        value={billItemForm.minQuantity}
        disabled={disabled}
      />
    </EditBillLineItemCells.DataCell>
  );
};

const PriceColumn = ({form, field, billItemForm, index, disableEditPrices}) => {
  const [value, setValue] = useState(billItemForm.amount);
  const [isFocused, setIsFocused] = useState(false);
  const amountField = `${field}.${index}.amount`;
  const disabledByPriceFormula = !!_.get(form.values, `${field}.${index}.amountFormulaId`);
  const disabled = disableEditPrices || disabledByPriceFormula;
  const hasError = _.get(form.errors, amountField);
  const handleChangeText = (text) => {
    setValue(text);
    form.setFieldValue(amountField, Currency.toForm(Currency.convertToCents(text)));
  };
  return (
    <EditBillLineItemCells.RightDataCell disabled={disabled} hasBorder isFirstItem={index === 0}>
      {isFocused ? (
        <KindLabelContainer index={index}>
          <EditBillLineItemCells.CellCaption>Price</EditBillLineItemCells.CellCaption>
          <Space height={24} />
        </KindLabelContainer>
      ) : !isFocused && !_.isEmpty(billItemForm.amount) ? (
        <React.Fragment>
          <KindLabelContainer index={index}>
            <EditBillLineItemCells.CellCaption vars={{textAlign: 'right'}}>
              Price
            </EditBillLineItemCells.CellCaption>
            <EditBillLineItemCells.CellText vars={{textAlign: 'right'}}>
              {billItemForm.amount}
            </EditBillLineItemCells.CellText>
          </KindLabelContainer>
        </React.Fragment>
      ) : (
        <EditBillLineItemCells.CellText vars={{hasPad: true}}>
          <EditBillLineItemCells.CellCaption>Price</EditBillLineItemCells.CellCaption>
        </EditBillLineItemCells.CellText>
      )}
      <EditBillLineItemCells.CellInput
        disabled={disabled}
        vars={{isFocused, hasError}}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          setValue(Currency.toForm(Currency.convertToCents(value)));
        }}
        onChangeText={handleChangeText}
        value={value}
      />
    </EditBillLineItemCells.RightDataCell>
  );
};

const DeleteItemColumn = ({form, field, index, billFormField}) => {
  const handleRemoveItem = () => {
    removeBillItem(form, field, index, billFormField);
  };
  return (
    <EditBillLineItemCells.IconCell>
      <Space style={{flex: 1}} />
      <EditBillLineItemCells.IconCircle
        activeOpacity={0.8}
        color={colors.gray.tertiary}
        onPress={handleRemoveItem}
      >
        <Icon source={Icon.Trash} size={12} color={colors.gray.secondary} />
      </EditBillLineItemCells.IconCircle>
      <Space style={{flex: 1}} />
    </EditBillLineItemCells.IconCell>
  );
};

const InvoiceBillBillItemsPreSubtotalListItem = ({
  form,
  field,
  billFormField,
  billItemForm,
  index,
  disableEditPrices,
}) => {
  const [isShowingDescription, setIsShowingDescription] = useState(!!billItemForm.description);
  const [inputHeight, setInputHeight] = useState();
  const [isUpdatingDescription, setIsUpdatingDescription] = useState(false);
  const descriptionInput = useTextInput();
  const handleUpdateForm = (text) => {
    form.setFieldValue(`${field}.${index}.description`, text);
  };
  const handleOnFocus = () => {
    setIsUpdatingDescription(true);
  };
  const handleOnBlur = () => {
    setIsUpdatingDescription(false);
  };

  return (
    <React.Fragment>
      <Row style={{width: '100%'}}>
        <MoveItemColumn form={form} field={field} index={index} />
        <Space width={2} />
        <NameColumn
          form={form}
          field={field}
          billItemForm={billItemForm}
          index={index}
          handleShowDescription={() => {
            if (isShowingDescription) {
              setIsShowingDescription(false);
            } else {
              setIsShowingDescription(true);
              descriptionInput.handleFocus();
            }
          }}
          isShowingDescription={isShowingDescription}
        />
        <QuantityColumn form={form} field={field} billItemForm={billItemForm} index={index} />
        <PriceColumn
          form={form}
          field={field}
          billItemForm={billItemForm}
          index={index}
          disableEditPrices={disableEditPrices}
        />
        <Space width={2} />
        <DeleteItemColumn form={form} field={field} index={index} billFormField={billFormField} />
      </Row>
      {isShowingDescription && (
        <Row style={{width: '100%'}}>
          <EditBillLineItemCells.FullWidthCell
            style={{width: null, flex: 1, marginLeft: 22, marginRight: 22}}
          >
            <EditBillLineItemCells.DescriptionInputLabel>
              Description
            </EditBillLineItemCells.DescriptionInputLabel>
            <EditBillLineItemCells.DescriptionInput
              ref={descriptionInput.ref}
              value={billItemForm.description}
              onChangeText={handleUpdateForm}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onContentSizeChange={(event) => setInputHeight(event.nativeEvent.contentSize.height)}
              height={inputHeight}
              vars={{isUpdating: isUpdatingDescription}}
              multiline
            />
          </EditBillLineItemCells.FullWidthCell>
        </Row>
      )}
    </React.Fragment>
  );
};

export default InvoiceBillBillItemsPreSubtotalListItem;
