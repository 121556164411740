// Libararies
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import CreateInvoicePage from 'modules/Storage/CreateInvoicePage';
import UpdateInvoicePage from 'modules/Storage/UpdateInvoicePage';

const InvoicePage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(InvoicePage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: params.projectUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <SidebarPageV2 selected={params.projectKind === 'storage' ? 'storage' : 'moves'}>
      {params.invoiceUuid ? (
        <UpdateInvoicePage />
      ) : (
        <CreateInvoicePage project={data.project} viewer={data.viewer} />
      )}
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoicePage.query = gql`
  ${CreateInvoicePage.fragment}

  query InvoicePage($projectUuid: String!) {
    viewer {
      id
      role
    }
    project(uuid: $projectUuid) {
      id
      ...CreateInvoicePage
    }
  }
`;

export default InvoicePage;
