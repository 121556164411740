const BillRuleKind = {
  ADDITIONAL_HOURS: 'ADDITIONAL_HOURS',
  BEFORE_JOB_MINIMUM_PAYMENT: 'BEFORE_JOB_MINIMUM_PAYMENT',
  CONDITIONAL_BILL_ITEM: 'CONDITIONAL_BILL_ITEM',
  CUSTOM: 'CUSTOM',
  MINIMUM_DOLLAR_AMOUNT: 'MINIMUM_DOLLAR_AMOUNT',
  MINIMUM_HOURS: 'MINIMUM_HOURS',
  WAIVE_OVERTIME: 'WAIVE_OVERTIME',
  NOT_TO_EXCEED_PRICE: 'NOT_TO_EXCEED_PRICE',
} as const;

export type BillRuleKindType = keyof typeof BillRuleKind;

export default BillRuleKind;
