// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

// Components
import OrganizationSuppliesForm from '../forms/OrganizationSuppliesForm';

const UpdateSuppliesMutation = ({
  organizationSuppliesForm,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}) => (
  <Mutation
    variables={{
      organizationSuppliesForm: OrganizationSuppliesForm.toMutation(organizationSuppliesForm),
    }}
    mutation={UpdateSuppliesMutation.mutation}
    onCompleted={({response: {organization, errors}}) => {
      if (submitForm) {
        submitForm();
      }

      if (setMutationErrors) {
        setMutationErrors(errors);
      }

      if (!errors) {
        onSuccess(organization);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateSuppliesMutation.propTypes = {
  organizationSuppliesForm: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

UpdateSuppliesMutation.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateSuppliesMutation.mutation = gql`
  mutation UpdateSuppliesMutation($organizationSuppliesForm: OrganizationSuppliesForm!) {
    response: updateOrganizationSupplies(
      organizationSuppliesForm: $organizationSuppliesForm
    ) {
      ${gql.errors}
      organization {
        id
        settings {
          id
          supplies
        }
      }
    }
  }
`;

export default UpdateSuppliesMutation;
