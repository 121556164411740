// Libraries
import React from 'react';

// App
import OrganizationSettingsEmailPageContent from 'modules/Organization/Settings/Email/components/OrganizationSettingsEmailPageContent';

const OrganizationSettingsEmailPage = () => {
  return <OrganizationSettingsEmailPageContent />;
};

export default OrganizationSettingsEmailPage;
