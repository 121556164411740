// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useToggle} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Callout from '@shared/design/components/Callout';
import Panel from '@shared/design/components/Panel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import Switch from '@shared/design/components/Switch';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import SalesTaxTemplateForm from '@shared/modules/Billing/forms/SalesTaxTemplateForm';
import useUpdateSalesTaxTemplateMutation from '@shared/modules/Billing/hooks/useUpdateSalesTaxTemplateMutation';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';
import useToggleSettingsIsCostAndCompensationEnabledMutation from '@shared/modules/Settings/hooks/useToggleSettingsIsCostAndCompensationEnabledMutation';
import CodatPanel from 'modules/Organization/Settings/Company/components/CodatPanel';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';
import QuickbooksPanel from 'modules/Organization/Settings/Company/components/QuickbooksPanel';
import RutterPanel from 'modules/Organization/Settings/Company/components/RutterPanel';
import SalesTaxTemplatePanel from 'modules/Organization/Settings/Company/components/SalesTaxTemplatePanel';

const Container = Styled.View`
  flex: 1;
`;

const SectionTitle = Styled.Text`
  ${Typography.Responsive.Heading1}
`;

const Heading = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Description = Styled.Text`
${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const IndexContainer = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const Column = Styled.View``;

const Row = Styled.View`
  flex-direction: row;
`;

const SalesTax = ({organization, index, refetch}) => {
  const editSalesTaxToggle = useToggle();
  const {salesTaxTemplate} = organization.settings;
  const salesTaxTemplateForm = SalesTaxTemplateForm.edit(salesTaxTemplate);
  const {form, submitting, handleSubmit} = useUpdateSalesTaxTemplateMutation({
    salesTaxTemplateForm,
    onSuccess: () => {
      refetch();
      editSalesTaxToggle.handleToggleOff();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  const variableOptions = organization.variables
    .filter((variable) =>
      _.includes([VariableFormat.PERCENT, VariableFormat.DROPDOWN_PERCENT], variable.format),
    )
    .map((variable) => ({
      label: variable.name,
      value: variable.id,
    }));
  const updatedAtDisplay = Datetime.convertToDisplayDatetime(
    salesTaxTemplate.updatedAt,
    'MM/DD/YY [at] h:mm A',
  );

  return (
    <EditPanel
      index={index}
      HeaderComponent={SalesTaxTemplatePanel.Header}
      headerComponentProps={{
        isEditing: editSalesTaxToggle.isOn,
        handleEdit: editSalesTaxToggle.handleToggleOn,
      }}
      BodyComponent={SalesTaxTemplatePanel}
      bodyComponentProps={{salesTaxTemplate: organization.settings.salesTaxTemplate}}
      EditBodyComponent={SalesTaxTemplatePanel.Edit}
      editBodyComponentProps={{
        form,
        field: 'salesTaxTemplateForm',
        variableOptions,
        lastUpdatedDisplay: `${updatedAtDisplay} by ${salesTaxTemplate.updatedBy.fullName}`,
      }}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editSalesTaxToggle.isOn}
      handleEdit={editSalesTaxToggle.handleToggleOn}
      handleClose={editSalesTaxToggle.handleToggleOff}
    />
  );
};

const Integrations = ({organization, responsive, refetch}) => {
  return (
    <React.Fragment>
      {OrganizationKind.getIsIndependent(organization.kind) ? (
        <React.Fragment>
          <Space height={24} />
          <IndexContainer index={2}>
            <RutterPanel platform={'QUICKBOOKS'} />
          </IndexContainer>
          <Space height={24} />
          <IndexContainer index={3}>
            <RutterPanel platform={'QUICKBOOKS_DESKTOP'} />
          </IndexContainer>
        </React.Fragment>
      ) : null}
      {organization.features.isEnabledCodatPanel &&
      OrganizationKind.getIsIndependent(organization.kind) ? (
        <React.Fragment>
          <Space height={24} />
          <IndexContainer index={1}>
            <CodatPanel organization={organization} settingsPath={'/settings/company/accounting'} />
          </IndexContainer>
        </React.Fragment>
      ) : null}
      {organization.features.isEnabledLegacyQuickbooksIntegration && (
        <React.Fragment>
          <Space height={24} />
          <IndexContainer index={4}>
            <QuickbooksPanel organization={organization} />
          </IndexContainer>
        </React.Fragment>
      )}
      <Space height={24} />
    </React.Fragment>
  );
};

const CostAndCompensation = ({organization, refetch}) => {
  const settingsForm = SettingsForm.edit(organization.settings);
  const {handleSubmit} = useToggleSettingsIsCostAndCompensationEnabledMutation({
    settingsForm,
    onSuccess: refetch,
    onError: (errors) => {
      console.log({errors});
    },
  });
  const {isCostAndCompensationEnabled} = organization.settings;
  const isViewingChildBranch = !organization.isPrimary;
  return (
    <Panel width={Panel.WIDTH.DEFAULT}>
      <Panel.Header style={isViewingChildBranch ? {} : {borderWidth: 0}}>
        <Column>
          <Row style={{justifyContent: 'space-between', alignItems: 'center'}}>
            <Panel.HeaderText>Cost & Compensation</Panel.HeaderText>
            <Space width={16} />
            <Switch
              disabled={isViewingChildBranch}
              isOn={isCostAndCompensationEnabled}
              onChange={handleSubmit}
              labelRight={isCostAndCompensationEnabled ? 'Enabled' : 'Disabled'}
            />
          </Row>
          <Space height={8} />
          <Panel.Text style={{color: colors.gray.secondary}}>
            {`When enabled, this feature will measure the costs associated with each job to determine your per-move profit.`}
          </Panel.Text>
        </Column>
      </Panel.Header>
      {isViewingChildBranch && (
        <Panel.Body>
          <Callout
            text={`Cost & compensation is set by the company. Please contact the admins at ${organization.company.primaryOrganization.name} to make changes.`}
          />
        </Panel.Body>
      )}
    </Panel>
  );
};

const OrganizationSettingsCompanyAccountingPage = () => {
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(OrganizationSettingsCompanyAccountingPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage>
      <ScrollView horizontal>
        <Container>
          <Loading loading={loading || !data}>
            {() => {
              const {viewingOrganization} = data.viewer;
              return (
                <React.Fragment>
                  <Heading responsive={responsive}>Accounting</Heading>
                  <Space height={12} />
                  <Description
                    responsive={responsive}
                  >{`Manage your accounting settings here, including tax, costs, and integrations.`}</Description>
                  <Space height={24} />
                  <SectionTitle responsive={responsive}>Tax</SectionTitle>
                  <Space height={24} />
                  <SalesTax organization={viewingOrganization} refetch={refetch} />
                  <Space height={24} />
                  <SectionTitle responsive={responsive}>Costs</SectionTitle>
                  <Space height={24} />
                  <CostAndCompensation organization={viewingOrganization} refetch={refetch} />
                  <Space height={24} />
                  <SectionTitle responsive={responsive}>Integrations</SectionTitle>
                  <Integrations organization={viewingOrganization} refetch={refetch} />
                </React.Fragment>
              );
            }}
          </Loading>
        </Container>
      </ScrollView>
    </CompanySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyAccountingPage.query = gql`
  ${QuickbooksPanel.fragment}
  ${SalesTaxTemplateForm.edit.fragment}
  ${SalesTaxTemplatePanel.fragment}
  ${SettingsForm.edit.fragment}
  query OrganizationSettingsCompanyAccountingPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        isPrimary
        kind
        features {
          isEnabledLegacyQuickbooksIntegration: isEnabled(feature:"LEGACY_QUICKBOOKS_INTEGRATION")
          isEnabledCodatPanel: isEnabled(feature:"CODAT_PANEL")
        }
        settings {
          id
          isCostAndCompensationEnabled
          salesTaxTemplate {
            id
            rate
            updatedAt
            updatedBy {
              id
              fullName
            }
            ...SalesTaxTemplateForm_edit
            ...SalesTaxTemplatePanel
          }
          ...SettingsForm_edit
        }
        variables {
          id
          name
          format
        }
        company {
          id
          primaryOrganization {
            id
            name
          }
        }
        ...QuickbooksPanel
      }
    }
  }
`;

export default OrganizationSettingsCompanyAccountingPage;
