// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Icon, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Text = Styled.H6`
  margin-right: 5px;
  color: ${({vars}) => vars.color};
`;

const Touchable = Styled.Touchable`
`;

const FinalLock = ({isFinal, onPress}) => {
  const responsive = useResponsive();

  return (
    <Container>
      {isFinal ? (
        <Touchable
          onPress={onPress}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text vars={{color: colors.green.status}}>
            {responsive.mobile ? `Unlock to edit` : `Press the lock to make changes`}
          </Text>
          <Icon
            color={colors.green.status}
            size={Icon.Sizes.Large}
            source={Icon.Lock}
            style={{marginBottom: 5}}
          />
        </Touchable>
      ) : (
        <React.Fragment>
          <Text vars={{color: colors.red.warning}}>
            {responsive.mobile ? `Changes allowed` : `Changes are allowed`}
          </Text>
          <Icon
            color={colors.red.warning}
            size={Icon.Sizes.Large}
            source={Icon.Unlock}
            style={{marginBottom: 5}}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
FinalLock.propTypes = {
  isFinal: PropTypes.bool.isRequired,
  onPress: PropTypes.func,
};

FinalLock.defaultProps = {
  onPress: null,
};

export default FinalLock;
