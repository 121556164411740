// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Styled} from '@supermove/components';
import {useNavigationDOM, usePopover} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';

const MenuButton = Styled.ButtonV2`
  padding-horizontal: 12px;
  height: ${({isSmall}) => (isSmall ? 28 : 32)}px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-color: ${({disabled}) => (disabled ? colors.gray.disabled : colors.blue.interactive)};
  border-width: 1px;
`;

const AccessSurveyV1ActionsMenuButton = ({jobUuid, inventoryUuid, isDisabled, isSmall}) => {
  const {navigator} = useNavigationDOM();
  const accessSurveyV1Popover = usePopover({name: 'Access Survey V1 Popover'});
  return (
    <ActionMenuPopover
      popover={accessSurveyV1Popover}
      placement={Popover.Positions.BottomEnd}
      width={220}
      actions={[
        {
          text: 'Access old survey view',
          onPress: () => navigator.push(`/jobs/${jobUuid}/inventories/${inventoryUuid}/summary`),
        },
      ]}
    >
      <MenuButton
        disabled={isDisabled}
        isSmall={isSmall}
        onPress={() => accessSurveyV1Popover.handleToggle()}
      >
        <Icon
          source={Icon.EllipsisV}
          color={isDisabled ? colors.gray.tertiary : colors.blue.interactive}
          size={16}
        />
      </MenuButton>
    </ActionMenuPopover>
  );
};

export default AccessSurveyV1ActionsMenuButton;
