// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const ProjectIdContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.blue.accentDark};
  border-radius: 4px;
  max-width: 150px;
  padding: 4px;
  justify-content: center;
  align-items: center;
`;

const EmailTextContainer = Styled.View`
  flex: 1;
`;

const ProjectIdText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  color: ${colors.blue.accentDark};
`;

const EmailNameText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const EmailThreadName = ({emailThread}) => {
  const responsive = useResponsive();
  return (
    <Row>
      <ProjectIdContainer>
        <ProjectIdText responsive={responsive} numberOfLines={1}>
          Project {emailThread.project.identifier}
        </ProjectIdText>
      </ProjectIdContainer>
      <Space width={12} />
      <EmailTextContainer>
        <EmailNameText responsive={responsive} numberOfLines={1}>
          {emailThread.name}
        </EmailNameText>
      </EmailTextContainer>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailThreadName.fragment = gql`
  fragment EmailThreadName on Thread {
    id
    name
    project {
      id
      identifier
    }
  }
`;

export default EmailThreadName;
