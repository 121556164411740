// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {useNavigationDOM, useToast} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import InventoryLibraryKind from '@shared/modules/Inventory/enums/InventoryLibraryKind';
import InventoryLibraryForm from '@shared/modules/Inventory/forms/InventoryLibraryForm';
import useCreateInventoryLibraryMutation from '@shared/modules/Inventory/hooks/useCreateInventoryLibraryMutation';

const CreateInventoryLibraryModal = ({
  createInventoryLibraryModal,
  organizationId,
  inventoryLibraryKind,
}) => {
  const {navigator} = useNavigationDOM();
  const createInventoryLibrarySuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Inventory library created!',
  });
  const inventoryLibraryForm = InventoryLibraryForm.new({
    organizationId,
    kind: inventoryLibraryKind || InventoryLibraryKind.SURVEY,
  });
  const {form, handleSubmit, submitting} = useCreateInventoryLibraryMutation({
    inventoryLibraryForm,
    onSuccess: ({inventoryLibrary}) => {
      createInventoryLibrarySuccessToast.handleToast();
      navigator.push(`/settings/inventory/libraries/${inventoryLibrary.uuid}`);
    },
    onError: (errors) => console.log(errors),
  });
  const nameField = 'inventoryLibraryForm.name';
  const headerName = InventoryLibraryKind.getDisplayHeader(inventoryLibraryKind);
  const headerText = headerName
    ? `Create ${headerName} Inventory Library`
    : 'Create Inventory Library';
  return (
    <SmallModal key={createInventoryLibraryModal.key} isOpen={createInventoryLibraryModal.isOpen}>
      <SmallModal.HeaderText>{headerText}</SmallModal.HeaderText>
      <Space height={16} />
      <FieldInput
        {...form}
        isRequired
        label={'Name'}
        name={nameField}
        input={{
          autoFocus: true,
          placeholder: 'Enter library name',
        }}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <QuaternaryButton
          isDisabled={submitting}
          textColor={colors.blue.interactive}
          onPress={() => {
            form.resetForm();
            createInventoryLibraryModal.handleClose();
          }}
          text={'Cancel'}
        />
        <Space width={16} />
        <Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          iconLeft={Icon.Check}
          text={'Create'}
        />
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default CreateInventoryLibraryModal;
