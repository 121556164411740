// Libraries
import _ from 'lodash';

// Supremove
import {generated, gql} from '@supermove/graphql';

const _new = () => ({
  dashboardId: null,
  organizationId: null,
  dashboardCategoryId: null,
  name: '',
  description: '',
  exploVariables: '',
});

const edit = (dashboard: generated.DashboardLinkedToGlobalDashboardFormEditFragment) => {
  return {
    dashboardId: dashboard.id,
    organizationId: dashboard.organizationId,
    dashboardCategoryId: dashboard.dashboardCategoryId,
    name: dashboard.name,
    description: dashboard.description,
    exploVariables: dashboard.exploVariables,
  };
};

export const DashboardLinkedToGlobalDashboardFormEditFragment = gql`
  fragment DashboardLinkedToGlobalDashboardFormEdit on Dashboard {
    id
    organizationId
    dashboardCategoryId
    name
    description
    exploVariables
  }
`;

const toForm = (
  dashboardForm: ReturnType<typeof edit> | ReturnType<typeof _new>,
): generated.DashboardLinkedToGlobalDashboardForm => ({
  dashboardId: dashboardForm.dashboardId ? _.toNumber(dashboardForm.dashboardId) : undefined,
  organizationId: dashboardForm.organizationId,
  dashboardCategoryId: dashboardForm.dashboardCategoryId,
  name: dashboardForm.name,
  description: dashboardForm.description,
  exploVariables: dashboardForm.exploVariables,
});

const toMutation = (
  dashboardForm: generated.DashboardLinkedToGlobalDashboardForm,
): generated.UseUpdateDashboardLinkedToGlobalDashboardMutationMutationVariables['dashboardLinkedToGlobalDashboardForm'] => {
  return {
    dashboardId: dashboardForm.dashboardId,
    name: dashboardForm.name,
    description: dashboardForm.description,
    organizationId: dashboardForm.organizationId,
    dashboardCategoryId: dashboardForm.dashboardCategoryId,
    exploVariables: dashboardForm.exploVariables,
  };
};

const DashboardLinkedToGlobalDashboardForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default DashboardLinkedToGlobalDashboardForm;
