// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive} from '@supermove/hooks';
import {
  DocumentItemType,
  DocumentItemSectionType,
  OrganizationModel,
  DocumentItemColumnType,
} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import {DocumentTemplateCategoryType} from '@shared/modules/Document/enums/DocumentTemplateCategory';
import {ReadonlySectionSettingsType} from '@shared/modules/Document/forms/DocumentItemForm';
import DocumentContentJson from '@shared/modules/Document/helpers/DocumentContentJson';
import {
  SelectedDocumentItemType,
  SetSelectedDocumentItemType,
  DocumentContentJsonType,
  SetDocumentContentJsonType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import AddDocumentItemButton from 'modules/Organization/Settings/Document/components/AddDocumentItemButton';
import CreateSectionModal from 'modules/Organization/Settings/Document/components/CreateSectionModal';
import DocumentItemsReorderableList from 'modules/Organization/Settings/Document/components/DocumentItemsReorderableList';

const PaddingContainer = Styled.View`
  padding-horizontal: 24px;
`;

const ColumnLabel = Styled.Text`
  ${Typography.Responsive.Subheading}
  color: ${colors.gray.secondary};
`;

const PlaceholderText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
`;

const getIsSection = (documentItem: DocumentItemType): documentItem is DocumentItemSectionType => {
  return documentItem.itemKind === DocumentItemKindV2.SECTION;
};

const getIsColumn = (documentItem: DocumentItemType): documentItem is DocumentItemColumnType => {
  return documentItem.itemKind === DocumentItemKindV2.COLUMN;
};

const getIsViewingSections = ({
  selectedDocumentItem,
  draftDocumentContentJson,
}: {
  selectedDocumentItem: SelectedDocumentItemType;
  draftDocumentContentJson: DocumentContentJsonType;
}) => {
  const documentItems = DocumentContentJson.getDocumentItems(draftDocumentContentJson, {
    sectionIndex: selectedDocumentItem.sectionIndex,
  });
  return _.some(documentItems) && getIsSection(documentItems[0]);
};

const getIsViewingColumns = ({
  selectedDocumentItem,
  draftDocumentContentJson,
}: {
  selectedDocumentItem: SelectedDocumentItemType;
  draftDocumentContentJson: DocumentContentJsonType;
}) => {
  const documentItems = DocumentContentJson.getDocumentItems(draftDocumentContentJson, {
    sectionIndex: selectedDocumentItem.sectionIndex,
  });
  return _.some(documentItems) && getIsColumn(documentItems[0]);
};

const handleAddEmptyDocumentSection = ({
  name,
  readonlySectionSettings,
  setDraftDocumentContentJson,
  setSelectedDocumentItem,
}: {
  name: string;
  readonlySectionSettings?: ReadonlySectionSettingsType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
}) => {
  setDraftDocumentContentJson((prevState) => {
    const documentItem = {
      ...DocumentItemKindV2.getNewDocumentItem(DocumentItemKindV2.SECTION, {name}),
      readonlySectionSettings,
    };
    prevState.documentItems = [...prevState.documentItems, documentItem];
    setSelectedDocumentItem({});
    return {
      ...prevState,
    };
  });
};

const LeftColumnHeader = ({
  draftDocumentContentJson,
  selectedDocumentItem,
}: {
  draftDocumentContentJson: DocumentContentJsonType;
  selectedDocumentItem: SelectedDocumentItemType;
}) => {
  const responsive = useResponsive();
  const leftColumnDocumentItems = DocumentContentJson.getDocumentItems(draftDocumentContentJson, {
    sectionIndex: selectedDocumentItem.sectionIndex,
    columnIndex: 0,
  });
  const hasDocumentItems = _.some(leftColumnDocumentItems);

  return (
    <PaddingContainer>
      <ColumnLabel responsive={responsive}>Left Column</ColumnLabel>
      <Space height={16} />
      {!hasDocumentItems && (
        <React.Fragment>
          <PlaceholderText>This column doesn't have any items yet.</PlaceholderText>
          <Space height={12} />
        </React.Fragment>
      )}
    </PaddingContainer>
  );
};

const DocumentTemplateDocumentItems = ({
  organization,
  setDraftDocumentContentJson,
  draftDocumentContentJson,
  setSelectedDocumentItem,
  selectedDocumentItem,
  documentTemplateCategory,
  hoveredSectionIndex,
  setHoveredSectionIndex,
}: {
  organization: OrganizationModel;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  draftDocumentContentJson: DocumentContentJsonType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  selectedDocumentItem: SelectedDocumentItemType;
  documentTemplateCategory: DocumentTemplateCategoryType;
  hoveredSectionIndex?: number;
  setHoveredSectionIndex?: (index?: number) => void;
}) => {
  const createSectionModal = useModal({name: 'Create Section Modal', enableTracking: true});

  // isViewingSections means that the ui is showing a list of sections, as opposed to showing
  // the document items within a given section.
  const isViewingSections = getIsViewingSections({selectedDocumentItem, draftDocumentContentJson});

  // isViewingColumns could be at the top level without any section in the document, or it could
  // be showing the document items in a section that's been configured to have columns.
  const isViewingColumns = getIsViewingColumns({selectedDocumentItem, draftDocumentContentJson});

  return (
    <React.Fragment>
      {isViewingColumns && (
        <LeftColumnHeader
          draftDocumentContentJson={draftDocumentContentJson}
          selectedDocumentItem={selectedDocumentItem}
        />
      )}
      <DocumentItemsReorderableList
        organization={organization}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
        draftDocumentContentJson={draftDocumentContentJson}
        setSelectedDocumentItem={setSelectedDocumentItem}
        selectedDocumentItem={selectedDocumentItem}
        documentTemplateCategory={documentTemplateCategory}
        hoveredSectionIndex={hoveredSectionIndex}
        setHoveredSectionIndex={setHoveredSectionIndex}
      />
      <PaddingContainer>
        {isViewingSections ? (
          <TertiaryButton
            iconLeft={Icon.Plus}
            text={'Add Section'}
            onPress={createSectionModal.handleOpen}
            style={{paddingVertical: 8}}
          />
        ) : (
          <AddDocumentItemButton
            organization={organization}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
            draftDocumentContentJson={draftDocumentContentJson}
            setSelectedDocumentItem={setSelectedDocumentItem}
            selectedDocumentItem={selectedDocumentItem}
            documentTemplateCategory={documentTemplateCategory}
            columnIndex={isViewingColumns ? 1 : undefined}
          />
        )}
      </PaddingContainer>
      <CreateSectionModal
        documentContentJson={draftDocumentContentJson}
        isOpen={createSectionModal.isOpen}
        isEnabledMultiPartDocuments={organization.features.isEnabledMultiPartDocuments}
        handleClose={createSectionModal.handleClose}
        handleCreateSection={(name, readonlySectionSettings) => {
          handleAddEmptyDocumentSection({
            name,
            readonlySectionSettings,
            setDraftDocumentContentJson,
            setSelectedDocumentItem,
          });
        }}
      />
    </React.Fragment>
  );
};

DocumentTemplateDocumentItems.fragment = gql`
  ${AddDocumentItemButton.fragment}
  ${DocumentItemsReorderableList.fragment}

  fragment DocumentTemplateDocumentItems on Organization {
    id
    features {
      isEnabledMultiPartDocuments: isEnabled(feature: "MULTI_PART_DOCUMENTS")
    }
    ...AddDocumentItemButton
    ...DocumentItemsReorderableList
  }
`;

export default DocumentTemplateDocumentItems;
