// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment, Datetime} from '@supermove/utils';

// App
import Expression from '@shared/ast/Expression';
import FormulaPrettyPrinter from '@shared/formulas/src/FormulaPrettyPrinter';
import BillRuleKind from '@shared/modules/Billing/enums/BillRuleKind';

const getValueType = (kind: any) => {
  switch (kind) {
    case BillRuleKind.WAIVE_OVERTIME:
    case BillRuleKind.CONDITIONAL_BILL_ITEM:
      return 'NONE';
    case BillRuleKind.CUSTOM:
      return 'STRING';
    case BillRuleKind.BEFORE_JOB_MINIMUM_PAYMENT:
    case BillRuleKind.MINIMUM_DOLLAR_AMOUNT:
    case BillRuleKind.NOT_TO_EXCEED_PRICE:
      return 'CURRENCY';
    case BillRuleKind.ADDITIONAL_HOURS:
    case BillRuleKind.MINIMUM_HOURS:
    default:
      return 'FLOAT';
  }
};

const getDisplayKind = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ kind }: { kind: any; }) => "#... Remove this comment to see the full error message
  ({kind}) => {
    switch (kind) {
      case BillRuleKind.ADDITIONAL_HOURS:
        return '# Additional Hours';
      case BillRuleKind.BEFORE_JOB_MINIMUM_PAYMENT:
        return 'Minimum Payment Before the Move';
      case BillRuleKind.MINIMUM_DOLLAR_AMOUNT:
        return '$ Amount Minimum';
      case BillRuleKind.MINIMUM_HOURS:
        return '# Hours Minimum';
      case BillRuleKind.WAIVE_OVERTIME:
        return 'Waive Overtime';
      case BillRuleKind.NOT_TO_EXCEED_PRICE:
        return 'Not to Exceed Price';
      case BillRuleKind.CONDITIONAL_BILL_ITEM:
        return 'Conditional Bill Item';
      case BillRuleKind.CUSTOM:
      default:
        return 'Custom Rule';
    }
  },
  gql`
    fragment BillRuleType_getDisplayKind on BillRuleType {
      id
      kind
    }
  `,
);

const getDisplayValue = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ kind, value, valueFormulaId, ... Remove this comment to see the full error message
  ({kind, value, valueFormulaId, stringValue, valueFormula, showValueFormulaName = false}) => {
    if (valueFormulaId) {
      return showValueFormulaName ? valueFormula.name : 'TBD';
    }
    switch (kind) {
      case BillRuleKind.BEFORE_JOB_MINIMUM_PAYMENT:
      case BillRuleKind.MINIMUM_DOLLAR_AMOUNT:
      case BillRuleKind.NOT_TO_EXCEED_PRICE:
        return value ? Currency.display(value, {shouldHideCentsIfZero: true}) : '';
      case BillRuleKind.ADDITIONAL_HOURS:
      case BillRuleKind.MINIMUM_HOURS:
        return value ? `${value} hours` : '';
      case BillRuleKind.CUSTOM:
        return stringValue || '';
      case BillRuleKind.CONDITIONAL_BILL_ITEM:
      default:
        return '';
    }
  },
  gql`
    fragment BillRuleType_getDisplayValue on BillRuleType {
      id
      kind
      value
      valueFormulaId
      stringValue
      valueFormula {
        id
        name
      }
    }
  `,
);

const getDisplayUpdatedAt = withFragment(
  (billRuleType) => {
    return Datetime.convertToDisplayDatetime((billRuleType as any).updatedAt);
  },
  gql`
    fragment BillRuleType_getDisplayUpdatedAt on BillRuleType {
      id
      updatedAt
    }
  `,
);

const hasDescriptionFormula = withFragment(
  (billRuleType) => {
    return !!(billRuleType as any).descriptionFormulaId;
  },
  gql`
    fragment BillRuleType_hasDescriptionFormula on BillRuleType {
      id
      descriptionFormulaId
    }
  `,
);

const renderDescriptionFormulaString = withFragment(
  (billRuleType) => {
    if (
      (billRuleType as any).descriptionFormula &&
      (billRuleType as any).descriptionFormula.astJson
    ) {
      return new FormulaPrettyPrinter().print(
        Expression.fromJSON(JSON.parse((billRuleType as any).descriptionFormula.astJson)),
      );
    }
  },
  gql`
    fragment BillRuleType_renderDescriptionFormulaString on BillRuleType {
      id
      descriptionFormula {
        id
        astJson
      }
    }
  `,
);

const hasNameFormula = withFragment(
  (billRuleType) => {
    return !!(billRuleType as any).nameFormulaId;
  },
  gql`
    fragment BillRuleType_hasNameFormula on BillRuleType {
      id
      nameFormulaId
    }
  `,
);

const renderNameFormulaString = withFragment(
  (billRuleType) => {
    if ((billRuleType as any).nameFormula && (billRuleType as any).nameFormula.astJson) {
      return new FormulaPrettyPrinter().print(
        Expression.fromJSON(JSON.parse((billRuleType as any).nameFormula.astJson)),
      );
    }
  },
  gql`
    fragment BillRuleType_renderNameFormulaString on BillRuleType {
      id
      nameFormula {
        id
        astJson
      }
    }
  `,
);

const hasValueFormula = withFragment(
  (billRuleType) => {
    return !!(billRuleType as any).valueFormulaId;
  },
  gql`
    fragment BillRuleType_hasValueFormula on BillRuleType {
      id
      valueFormulaId
    }
  `,
);

const renderValueFormulaString = withFragment(
  (billRuleType) => {
    if ((billRuleType as any).valueFormula && (billRuleType as any).valueFormula.astJson) {
      return new FormulaPrettyPrinter().print(
        Expression.fromJSON(JSON.parse((billRuleType as any).valueFormula.astJson)),
      );
    }
  },
  gql`
    fragment BillRuleType_renderValueFormulaString on BillRuleType {
      id
      valueFormula {
        id
        astJson
      }
    }
  `,
);

const BillRuleType = {
  getValueType,
  getDisplayKind,
  getDisplayValue,
  getDisplayUpdatedAt,

  // Formulas
  hasDescriptionFormula,
  hasNameFormula,
  hasValueFormula,
  renderDescriptionFormulaString,
  renderNameFormulaString,
  renderValueFormulaString,
};

export default BillRuleType;
