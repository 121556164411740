// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import SendEmailForJobForm from '@shared/modules/Email/forms/SendEmailForJobForm';

const useSendReceiptEmailMutation = ({
  job,
  subject,
  body,
  attachmentIds,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      sendEmailForJobForm: SendEmailForJobForm.new({
        jobId: job.id,
        subject,
        body,
        attachmentIds,
      }),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSendReceiptEmailMutation.mutation,
    variables: {
      sendEmailForJobForm: SendEmailForJobForm.toMutation(form.values.sendEmailForJobForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSendReceiptEmailMutation.mutation = gql`
  mutation useSendReceiptEmailMutation($sendEmailForJobForm: SendEmailForJobForm!) {
    response: sendReceiptEmail(sendEmailForJobForm: $sendEmailForJobForm) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default useSendReceiptEmailMutation;
