// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {EmailTemplate} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import HardcodedSystemVariables from '@shared/modules/Variable/enums/HardcodedSystemVariables';
import Field from 'modules/App/components/Field';
import EmailTemplateAttachmentsSelector from 'modules/File/Attachment/components/EmailTemplateAttachmentsSelector';
import OrganizationEmailTemplateRecipients from 'modules/Organization/Settings/Email/components/OrganizationEmailTemplateRecipients';
import VariableList from 'modules/Variable/components/VariableList';

const Label = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const Description = Styled.Text`
  ${Typography.Body3}
  margin-top: 8px;
  color: ${colors.gray.secondary};
  padding-horizontal: 4;
`;

const BackgroundContainer = Styled.View`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray.border};
  border-radius: 4px;
  padding: 8px;
`;

const CollapsibleSectionHeaderContainer = Styled.Touchable`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 4px;
`;

const CollapsibleSectionHeaderText = Styled.Text`
  ${Typography.Label};
`;

const EmailTemplateKindSection = ({form, field, isKindEditable, organization}) => {
  const kindField = `${field}.kind`;
  const selectedKind = _.get(form.values, kindField);
  const isCustom =
    selectedKind === EmailTemplate.KINDS.CUSTOM ||
    selectedKind === EmailTemplate.KINDS.CUSTOM_INVOICE ||
    selectedKind === EmailTemplate.KINDS.CUSTOM_INVOICE_RECEIPT;
  const nameField = `${field}.name`;

  return (
    <React.Fragment>
      <Label>Email Template Kind</Label>
      <Space height={2} />
      <Section sectionIndex={1}>
        <Field
          {...form}
          component={DropdownInput}
          name={kindField}
          input={{
            // TODO(Kevin): Remove filter logic once 1.1 is out, this is part of deprecation
            // process to remove these template kinds from the FE
            options: EmailTemplate.KINDS_DROPDOWN_OPTIONS.filter((option) => {
              const transformedKind = {kind: option.value};
              return !EmailTemplate.isDeprecatedEmailTemplate(transformedKind);
            }),
            placeholder: 'Select an email template kind',
            disabled: !isKindEditable,
            setFieldValue: form.setFieldValue,
            onChangeValue: (kind) => {
              if (kind === EmailTemplate.KINDS.CUSTOM) {
                form.setFieldValue(nameField, '');
              } else {
                form.setFieldValue(nameField, EmailTemplate.getDisplayFromKind(kind));
              }
            },
            style: {
              width: '100%',
            },
          }}
          style={{
            width: '100%',
          }}
        />
        {!!selectedKind && (
          <Description>{EmailTemplate.KINDS_DESCRIPTIONS[selectedKind]}</Description>
        )}
      </Section>
      <Space height={12} />
      {isCustom && (
        <React.Fragment>
          <Section sectionIndex={2}>
            <Label>Email Template Name</Label>
            <Space height={2} />
            <Field
              {...form}
              name={nameField}
              input={{
                placeholder: 'Enter name',
                style: {
                  width: '100%',
                },
              }}
            />
          </Section>
          <Space height={12} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const CollapsibleSectionHeader = ({title, onPress, isExpanded}) => {
  return (
    <CollapsibleSectionHeaderContainer onPress={onPress}>
      <CollapsibleSectionHeaderText>{title}</CollapsibleSectionHeaderText>
      <Icon
        source={isExpanded ? Icon.ChevronUp : Icon.ChevronDown}
        color={colors.gray.primary}
        size={16}
      />
    </CollapsibleSectionHeaderContainer>
  );
};

const OrganizationEmailTemplateFieldsV2 = ({isKindEditable, field, form, organization}) => {
  const [isVariableListExpanded, setIsVariableListExpanded] = useState(false);
  const [isAttachmentListExpanded, setIsAttachmentListExpanded] = useState(false);
  return (
    <React.Fragment>
      <Section sectionIndex={1}>
        <EmailTemplateKindSection
          form={form}
          field={field}
          isKindEditable={isKindEditable}
          organization={organization}
        />
      </Section>
      <Section sectionIndex={2}>
        <OrganizationEmailTemplateRecipients
          form={form}
          field={field}
          organization={organization}
        />
      </Section>
      <Section sectionIndex={3}>
        <Label>Subject</Label>
        <Space height={2} />
        <Field
          {...form}
          name={`${field}.subject`}
          input={{
            placeholder: 'Enter subject',
            style: {
              width: '100%',
            },
          }}
        />
      </Section>
      <Space height={12} />
      <Section sectionIndex={4}>
        <BackgroundContainer>
          <CollapsibleSectionHeader
            title={'Attachments'}
            onPress={() => setIsAttachmentListExpanded(!isAttachmentListExpanded)}
            isExpanded={isAttachmentListExpanded}
          />
          {isAttachmentListExpanded && (
            <EmailTemplateAttachmentsSelector form={form} name={'emailTemplateForm'} />
          )}
        </BackgroundContainer>
      </Section>
      <Space height={12} />
      <Section sectionIndex={5}>
        <BackgroundContainer>
          <CollapsibleSectionHeader
            title={'Variables'}
            onPress={() => setIsVariableListExpanded(!isVariableListExpanded)}
            isExpanded={isVariableListExpanded}
          />
          {isVariableListExpanded && (
            <VariableList
              organization={organization}
              hardcodedSystemVariables={HardcodedSystemVariables.EMAIL_VARIABLES}
              containerStyle={{border: 'none', backgroundColor: colors.white}}
              isCollapsible={false}
            />
          )}
        </BackgroundContainer>
      </Section>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
OrganizationEmailTemplateFieldsV2.propTypes = {
  isKindEditable: PropTypes.bool,
  field: PropTypes.string.isRequired, // ex. 'emailForm'
  form: PropTypes.object.isRequired,
};

OrganizationEmailTemplateFieldsV2.defaultProps = {
  isKindEditable: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationEmailTemplateFieldsV2.fragment = gql`
  ${OrganizationEmailTemplateRecipients.fragment}
  ${VariableList.fragment}

  fragment OrganizationEmailTemplateFieldsV2 on Organization {
    id
    ...OrganizationEmailTemplateRecipients
    ...VariableList
  }
`;

export default OrganizationEmailTemplateFieldsV2;
