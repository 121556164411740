// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DocumentTemplateVersionForm, {
  DocumentContentJsonFormType,
} from '@shared/modules/Document/forms/DocumentTemplateVersionForm';
import DocumentContentJson from '@shared/modules/Document/helpers/DocumentContentJson';

const convertDocumentTemplateInputToJsonString = (
  documentContentJsonForm: DocumentContentJsonFormType,
) => {
  const documentItems = _.cloneDeep(documentContentJsonForm.documentItems);
  return {documentItems: DocumentContentJson.handleStringifyDocumentItems(documentItems)};
};

const usePublishDocumentTemplateVersionMutation = ({
  documentTemplateVersionForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      documentTemplateVersionForm: DocumentTemplateVersionForm.toForm(documentTemplateVersionForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: usePublishDocumentTemplateVersionMutation.mutation,
    variables: {
      documentTemplateVersionForm: {
        ...DocumentTemplateVersionForm.toMutation(form.values.documentTemplateVersionForm),
        documentContentJsonForm: convertDocumentTemplateInputToJsonString(
          documentTemplateVersionForm.documentContentJsonForm,
        ),
      },
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
usePublishDocumentTemplateVersionMutation.mutation = gql`
  mutation usePublishDocumentTemplateVersionMutation($documentTemplateVersionForm: DocumentTemplateVersionForm!) {
    response: publishDocumentTemplateVersion(documentTemplateVersionForm: $documentTemplateVersionForm) {
      ${gql.errors}
      documentTemplateVersion {
        id
      }
    }
  }
`;

export default usePublishDocumentTemplateVersionMutation;
