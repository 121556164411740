// Supermove
import {JobUserForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (crew) => ({
    crewId: (crew as any).id,
    jobUserForms: (crew as any).jobUsers.map((jobUser: any) => JobUserForm.edit(jobUser)),
  }),
  gql`
    ${JobUserForm.edit.fragment}

    fragment AssignUsersToCrewForm_edit on Crew {
      id
      jobUsers {
        id
        ...JobUserForm_edit
      }
    }
  `,
);

const toForm = ({crewId, jobUserForms}: any) => ({
  crewId,
  jobUserForms: jobUserForms.map((jobUserForm: any) => JobUserForm.toForm(jobUserForm)),
});

const toMutation = ({crewId, jobUserForms}: any) => ({
  crewId,
  jobUserForms: jobUserForms.map((jobUserForm: any) => JobUserForm.toMutation(jobUserForm)),
});

const AssignUsersToCrewForm = {
  // Initialize
  edit,

  // Conversion
  toForm,
  toMutation,
};

export default AssignUsersToCrewForm;
