// Libararies
import React from 'react';

// Supermove
import {useUrlFilters} from '@supermove/hooks';

// App
import {OrganizationSettingsCustomerFlowsFiltersType} from 'modules/Organization/Settings/CustomerFlows/components/OrganizationSettingsCustomerFlowsFiltersTypes';
import OrganizationSettingsCustomerFlowsPageHeader from 'modules/Organization/Settings/CustomerFlows/components/OrganizationSettingsCustomerFlowsPageHeader';

const OrganizationSettingsCustomerFlowsPageContent = () => {
  const urlFilters = useUrlFilters<OrganizationSettingsCustomerFlowsFiltersType>({
    getRoute: () => '/settings/projects/customer-flows',
    filterKeys: ['searchQuery', 'projectTypeIds'],
  });
  return <OrganizationSettingsCustomerFlowsPageHeader urlFilters={urlFilters} />;
};

export default OrganizationSettingsCustomerFlowsPageContent;
