// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({fileId, filename, uuid, description, downloadUrl, isDeleted}: any) => ({
  attachmentId: null,
  fileId,
  uuid,
  itemId: null, // NOTE(cooper): We don't have itemIds at the time of creation
  filename,
  description,
  downloadUrl,
  isDeleted,
});

const newWithAttachmentId = ({
  id,
  itemId,
  fileId,
  filename,
  uuid,
  description,
  downloadUrl,
  isDeleted,
}: any) => ({
  attachmentId: id,
  fileId,
  uuid,
  itemId,
  filename,
  description,
  downloadUrl,
  isDeleted,
});

const edit = withFragment(
  (attachment) => ({
    attachmentId: (attachment as any).id,
    fileId: (attachment as any).fileId,
    uuid: (attachment as any).uuid,
    itemId: (attachment as any).itemId,
    filename: (attachment as any).file.name,
    description: (attachment as any).description,
    downloadUrl: (attachment as any).file.downloadUrl,
    isDeleted: (attachment as any).isDeleted,
  }),
  gql`
    fragment ItemAttachmentForm_edit on Attachment {
      id
      fileId
      uuid
      itemId
      description
      isDeleted
      file {
        id
        name
        downloadUrl
      }
    }
  `,
);

const toForm = ({
  attachmentId,
  fileId,
  uuid,
  itemId,
  filename,
  description,
  downloadUrl,
  isDeleted,
}: any) => ({
  attachmentId,
  fileId,
  uuid,
  itemId,
  filename,
  description,
  downloadUrl,
  isDeleted,
});

const toMutation = ({
  attachmentId,
  fileId,
  uuid,
  itemId,
  filename,
  description,
  isDeleted,

  // NOTE(cooper): Item uuid gets injected when the mutation fires,
  // we don't have it available when the ItemAttachmentForm gets created
  itemUuid,
}: any) => ({
  attachmentId,
  fileId,
  uuid,
  itemId,
  itemUuid,
  filename,
  description,
  isDeleted,
});

const ItemAttachmentForm = {
  new: _new,
  newWithAttachmentId,
  edit,
  toForm,
  toMutation,
};

export default ItemAttachmentForm;
