// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {Sidebar} from 'modules/App/components';
import {getSidebarItems} from 'modules/App/components/Sidebar/data/SidebarData';

const EmployeeSidebar = ({selected}) => (
  <Sidebar
    selected={selected}
    getItems={getSidebarItems}
    isVisibleSignOut
    query={{
      fetchPolicy: 'cache-and-network',
      query: EmployeeSidebar.query,
    }}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
EmployeeSidebar.query = gql`
  ${Sidebar.fragment}
  ${getSidebarItems.fragment}

  query EmployeeSidebar {
    ${gql.query}
    isAuthenticated
    viewer {
      id
      role
      ...Sidebar_viewer
      ...User_getSidebarItems
    }
  }
`;

export default EmployeeSidebar;
