// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useMountEffect, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';

// App
import SidebarPage from 'modules/App/components/SidebarPage';
import ProjectNotFoundPage from 'modules/Project/components/ProjectNotFoundPage';

const getJobs = ({project}) => {
  if (!_.isEmpty(project.activeMoveJobs)) {
    return project.activeMoveJobs;
  }
  return project.jobs;
};

const ShowProjectPageContent = ({project}) => {
  const {navigator, params} = useNavigationDOM();
  const responsive = useResponsive();
  const jobs = getJobs({project});
  const sortedJobs = Job.sortJobsBySequence(jobs);

  const {isEnabledProjectPageV2, isEnabledProjectPageTablet, isEnabledProjectPageMobile} =
    project.organization.features;
  const isOverrideV1 = _.includes(Object.keys(params), 'overrideV1');
  const urlParamString = isOverrideV1 ? '?overrideV1' : '';

  useMountEffect(() => {
    if (project.isStorage) {
      navigator.replace(`/storage/projects/${project.uuid}`);
    } else if (
      isOverrideV1 ||
      (responsive.desktop && !isEnabledProjectPageV2) ||
      (responsive.tablet && !isEnabledProjectPageTablet) ||
      (responsive.mobile && !isEnabledProjectPageMobile)
    ) {
      navigator.replace(`/jobs/${_.get(sortedJobs, '0.uuid')}${urlParamString}`);
    } else {
      navigator.replace(
        `/projects/${project.uuid}/view?` +
          `${params.block ? `block=${params.block}&` : ''}` +
          `${params.widget ? `widget=${params.widget}&` : ''}`,
      );
    }
  });

  return null;
};

const ShowProjectPage = () => {
  const {params} = useNavigationDOM();

  return (
    <SidebarPage
      fetchPolicy={'cache-and-network'}
      selected={'calendar'}
      variables={{uuid: params.uuid}}
      query={ShowProjectPage.query}
    >
      {({data}) =>
        data.project ? <ShowProjectPageContent project={data.project} /> : <ProjectNotFoundPage />
      }
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowProjectPage.query = gql`
  ${Job.sortJobsBySequence.fragment}
  query ShowProjectPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      isStorage
      organization {
        id
        slug
        features {
          isEnabledProjectPageV2: isEnabled(feature: "PROJECT_PAGE_V2")
          isEnabledProjectPageTablet: isEnabled(feature: "PROJECT_PAGE_TABLET")
          isEnabledProjectPageMobile: isEnabled(feature: "PROJECT_PAGE_MOBILE")
          isEnabledProjectPageTablet: isEnabled(feature: "PROJECT_PAGE_TABLET")
        }
      }
      activeMoveJobs {
        id
        uuid
        ...Job_sortJobsBySequence
      }
      jobs {
        id
        uuid
        ...Job_sortJobsBySequence
      }
    }
  }
`;

export default ShowProjectPage;
