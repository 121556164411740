// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useMountEffect, useResponsive, useScrollView} from '@supermove/hooks';
import {TimesheetBillableEntry} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Duration} from '@supermove/utils';

// App
import EditTimesheetPayrollEntryModal from '@shared/modules/Timesheet/components/EditTimesheetPayrollEntryModal';
import TimesheetPayrollEntriesLegend from '@shared/modules/Timesheet/components/TimesheetPayrollEntriesLegend';
import TimesheetBillableEntryKind from '@shared/modules/Timesheet/enums/TimesheetBillableEntryKind';
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';

const CELL_MINUTES = 60;
const MINUTES_SPACE_MULTIPLIER = 1.5;
const BLOCK_WIDTH = CELL_MINUTES * MINUTES_SPACE_MULTIPLIER;

// Expected height of block with one line name and hours
const getBlockHeight = ({responsive}: any) => (responsive.desktop ? 68 : 72);

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const HeadersContainer = Styled.View`
  flex-direction: row;
  border-top-left-radius: ${({
    // @ts-expect-error TS(2339): Property 'isNameColumn' does not exist on type 'Th... Remove this comment to see the full error message
    isNameColumn,
  }) => (isNameColumn ? 4 : 0)}px;
  border-top-right-radius: ${({
    // @ts-expect-error TS(2339): Property 'isNameColumn' does not exist on type 'Th... Remove this comment to see the full error message
    isNameColumn,
  }) => (isNameColumn ? 0 : 4)}px;
  border-top-width: 1px;
  border-right-width: ${({
    // @ts-expect-error TS(2339): Property 'isNameColumn' does not exist on type 'Th... Remove this comment to see the full error message
    isNameColumn,
  }) => (isNameColumn ? 0 : 1)}px;
  border-left-width: ${({
    // @ts-expect-error TS(2339): Property 'isNameColumn' does not exist on type 'Th... Remove this comment to see the full error message
    isNameColumn,
  }) => (isNameColumn ? 1 : 0)}px;
  border-color: ${colors.gray.border};
  overflow: hidden;
`;

const ColumnDivider = Styled.View`
  width: 1px;
  background-color: ${colors.gray.border};
`;

const TimesheetBlocksContainer = Styled.View`
  border-bottom-left-radius: ${({
    // @ts-expect-error TS(2339): Property 'isNameColumn' does not exist on type 'Th... Remove this comment to see the full error message
    isNameColumn,
  }) => (isNameColumn ? 4 : 0)}px;
  border-bottom-right-radius: ${({
    // @ts-expect-error TS(2339): Property 'isNameColumn' does not exist on type 'Th... Remove this comment to see the full error message
    isNameColumn,
  }) => (isNameColumn ? 0 : 4)}px;
  border-left-width: ${({
    // @ts-expect-error TS(2339): Property 'isNameColumn' does not exist on type 'Th... Remove this comment to see the full error message
    isNameColumn,
  }) => (isNameColumn ? 1 : 0)}px;
  border-bottom-width: 1px;
  border-right-width: ${({
    // @ts-expect-error TS(2339): Property 'isNameColumn' does not exist on type 'Th... Remove this comment to see the full error message
    isNameColumn,
  }) => (isNameColumn ? 0 : 1)}px;
  border-color: ${colors.gray.border};
  overflow: hidden;
`;

const TimesheetBlocksRow = Styled.ButtonV2`
  flex-direction: row;
`;

const RowDivider = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const UserColumn = Styled.View`
  width: 200px;
  padding: ${({
    // @ts-expect-error TS(2339): Property 'isNameColumn' does not exist on type 'Th... Remove this comment to see the full error message
    isNameColumn,
  }) => (isNameColumn ? '8px' : '12px')};
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isNameColumn' does not exist on type 'Th... Remove this comment to see the full error message
    isNameColumn,
  }) => (isNameColumn ? colors.gray.background : colors.white)};
  border-right-width: 1px;
  border-color: ${colors.gray.border}
`;

const TimeColumnHeader = Styled.View`
  width: 90px;
  padding: 8px;
  align-items: center;
  background-color: ${colors.gray.background};
`;

const CrewTimeCellContainer = Styled.View`
  flex-direction: row;
  width: ${BLOCK_WIDTH}px;
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const TimeWorkedText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const sortPositions = (position1: any, position2: any) => {
  return position1.localeCompare(position2);
};

const getSortedTimesheetBillableEntries = ({job}: any) => {
  return job.timesheetBillableEntries.sort((a: any, b: any) => {
    const position1 = a.timesheetPayrollEntries[0]?.moverPosition?.name || '';
    const position2 = b.timesheetPayrollEntries[0]?.moverPosition?.name || '';
    return sortPositions(position1, position2);
  });
};

const getJobTimesheetPayrollEntryGroups = ({job}: any) => {
  const groupsByMoverPosition = job.timesheetBillableEntries[0].timesheetPayrollEntries.reduce(
    (grouped: any, timesheetPayrollEntry: any) => {
      const key = timesheetPayrollEntry.moverPosition.name;
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(timesheetPayrollEntry);
      return grouped;
    },
    {},
  );

  const sortedKeys = Object.keys(groupsByMoverPosition).sort((position1, position2) => {
    return sortPositions(position1, position2);
  });

  return sortedKeys.map((key) => groupsByMoverPosition[key]);
};

const getSortedTimesheetPayrollEntries = ({timesheetPayrollEntries}: any) => {
  return timesheetPayrollEntries.sort((a: any, b: any) => {
    return a.user.fullName.localeCompare(b.user.fullName);
  });
};

const getDisplayTime = (minutes: any) => {
  return Duration.toDisplayTime(minutes, {
    hoursLabel: 'H',
    minutesLabel: 'M',
    alwaysShowMinutes: true,
    alwaysShowHours: true,
  });
};

const getDatetimesForDate = ({date}: any) => {
  return _.map(_.range(0, 24), (hour) => {
    const isNextDay = hour >= 24;
    const timeString = `${!isNextDay ? hour : hour - 24}:${'00'}`;
    return Datetime.fromDateAndTime(date, timeString);
  });
};

const getDatetimes = ({timesheetBillableEntry}: any) => {
  const dates = TimesheetBillableEntry.getDates(timesheetBillableEntry);
  return _.flatten(
    dates.map((date) => {
      return getDatetimesForDate({date});
    }),
  );
};

const getTimesheetBlocksForDatetime = ({datetime, timesheetBlocks}: any) => {
  const endDatetime = datetime.clone().add(60, 'minutes');
  const filteredBlocks = timesheetBlocks.filter((block: any) => {
    const isValidStart = block.datetimeFrom.isBefore(endDatetime);
    const isValidEnd = block.datetimeTo.isAfter(datetime);
    return isValidStart && isValidEnd;
  });
  const filteredBlocksWithCellMinutes = filteredBlocks.map((block: any) => {
    const startTime = datetime.isBefore(block.datetimeFrom) ? block.datetimeFrom : datetime;
    const endTime = endDatetime.isBefore(block.datetimeTo) ? endDatetime : block.datetimeTo;
    return {
      ...block,
      cellMinutes: endTime.diff(startTime, 'minutes'),
    };
  });
  return filteredBlocksWithCellMinutes;
};

const getEarliestTimesheetBlock = ({job}: any) => {
  const timesheetBlocks = job.timesheetBillableEntries.flatMap((billableEntry: any) =>
    _.flatMap(billableEntry.timesheetPayrollEntries, 'effectiveTimesheetBlocks'),
  );
  return timesheetBlocks.reduce((earliestBlock: any, currentBlock: any) => {
    return !earliestBlock
      ? currentBlock
      : Datetime.fromDatetime(currentBlock.rangeFrom, true).isBefore(
            Datetime.fromDatetime(earliestBlock.rangeFrom, true),
          )
        ? currentBlock
        : earliestBlock;
  }, null);
};

const ColumnHeaders = ({label, datetimes, isNameColumn}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <HeadersContainer isNameColumn={isNameColumn}>
      {isNameColumn ? (
        // @ts-expect-error TS(2769): No overload matches this call.
        <UserColumn isNameColumn>
          <LabelText numberOfLines={1}>{label}</LabelText>
        </UserColumn>
      ) : (
        datetimes.map((datetime: any, index: any) => {
          return (
            <React.Fragment key={index}>
              {index > 0 && <ColumnDivider />}
              <Column>
                <TimeColumnHeader>
                  <LabelText>{Datetime.toDisplayTime(datetime)}</LabelText>
                </TimeColumnHeader>
              </Column>
            </React.Fragment>
          );
        })
      )}
    </HeadersContainer>
  );
};

const UserColumnCell = ({job, timesheetPayrollEntry, responsive}: any) => {
  const {timeWorked} = timesheetPayrollEntry;
  const breakMinutes = timeWorked.totalBreak / 60;
  const minutesWorked = timeWorked.withoutAdjustments / 60 - breakMinutes;
  const timesheetAdjustmentMinutes = timeWorked.adjustmentsFromTimesheet / 60;
  const billRulesAdjustmentMinutes = timeWorked.adjustmentsFromBillRules / 60;
  const {crewHoursAdjustmentMinutes} = job;
  const hasAdjustment =
    timesheetAdjustmentMinutes || billRulesAdjustmentMinutes || crewHoursAdjustmentMinutes;
  const minutesTotal =
    minutesWorked +
    timesheetAdjustmentMinutes +
    billRulesAdjustmentMinutes +
    crewHoursAdjustmentMinutes;

  return (
    <UserColumn>
      <Row style={{alignItems: 'center', flex: 1}}>
        <LabelText
          style={{color: colors.gray.primary, width: 148}}
          responsive={responsive}
          numberOfLines={1}
        >
          {timesheetPayrollEntry.user.fullName}
        </LabelText>
        <Space style={{flex: 1}} />
        <Icon source={Icon.Pen} size={14} color={colors.blue.interactive} />
      </Row>
      <Space height={4} />
      {hasAdjustment ? (
        <Row style={{alignItems: 'center'}}>
          <Icon source={Icon.ExclamationTriangle} size={14} color={colors.orange.hover} />
          <Space width={8} />
          <TimeWorkedText style={{textDecorationLine: 'line-through'}} responsive={responsive}>
            {getDisplayTime(minutesWorked)}
          </TimeWorkedText>
          <Space width={8} />
          <TimeWorkedText responsive={responsive}>{getDisplayTime(minutesTotal)}</TimeWorkedText>
        </Row>
      ) : (
        <TimeWorkedText>{getDisplayTime(minutesWorked)}</TimeWorkedText>
      )}
    </UserColumn>
  );
};

const CrewTimeCell = ({datetime, timesheetPayrollEntry, height}: any) => {
  const timesheetBlocks = timesheetPayrollEntry.effectiveTimesheetBlocks.map((block: any) => ({
    ...block,
    datetimeFrom: Datetime.fromDatetime(block.rangeFrom, true),
    datetimeTo: Datetime.fromDatetime(block.rangeTo, true),
  }));
  const datetimeBillableBlocks = getTimesheetBlocksForDatetime({datetime, timesheetBlocks});
  const firstBlock = datetimeBillableBlocks[0];
  const isBlocksStartsAfterCellStartTime = firstBlock?.datetimeFrom.isAfter(datetime);

  return (
    <CrewTimeCellContainer>
      {isBlocksStartsAfterCellStartTime && (
        <TimesheetPayrollEntriesLegend.ColorComponent
          key={'PLACEHOLDER'}
          color={colors.white}
          height={height}
          width={
            Datetime.getDifference(datetime, firstBlock.datetimeFrom) * MINUTES_SPACE_MULTIPLIER
          }
        />
      )}
      {datetimeBillableBlocks.map((block: any, index: any) => {
        return (
          <TimesheetPayrollEntriesLegend.ColorComponent
            key={index}
            color={TimesheetBlockKind.getColor(block.kind)}
            backgroundColor={!block.isBillable && TimesheetBlockKind.getBackgroundColor(block.kind)}
            height={height}
            width={block.cellMinutes * MINUTES_SPACE_MULTIPLIER}
          />
        );
      })}
    </CrewTimeCellContainer>
  );
};

const TimesheetPayrollEntryRow = ({
  job,
  timesheetPayrollEntry,
  timesheetBillableEntry,
  datetimes,
  onUpdate,
  isNameColumn,
  responsive,
  viewerRole,
}: any) => {
  const editTimesheetPayrollEntryModal = useModal({name: 'Edit Timesheet Payroll Entry Modal'});

  return (
    <TimesheetBlocksRow
      onPress={editTimesheetPayrollEntryModal.handleOpen}
      style={{height: getBlockHeight({responsive})}}
    >
      {isNameColumn ? (
        <UserColumnCell
          job={job}
          timesheetPayrollEntry={timesheetPayrollEntry}
          editTimesheetPayrollEntryModal={editTimesheetPayrollEntryModal}
          responsive={responsive}
        />
      ) : (
        datetimes.map((datetime: any, index: any) => {
          return (
            <React.Fragment key={index}>
              {index > 0 && <ColumnDivider />}
              <CrewTimeCell
                datetime={datetime}
                timesheetPayrollEntry={timesheetPayrollEntry}
                height={getBlockHeight({responsive})}
                responsive={responsive}
              />
            </React.Fragment>
          );
        })
      )}
      <EditTimesheetPayrollEntryModal
        key={editTimesheetPayrollEntryModal.key}
        isOpen={editTimesheetPayrollEntryModal.isOpen}
        handleClose={editTimesheetPayrollEntryModal.handleClose}
        timesheetPayrollEntry={timesheetPayrollEntry}
        timesheetBillableEntryMinutes={TimesheetBillableEntry.getMinutesWorked(
          timesheetBillableEntry,
        )}
        rangeFromDate={timesheetBillableEntry.timesheetBlocksDateRange.start}
        crewHoursAdjustmentMinutes={job.crewHoursAdjustmentMinutes}
        onUpdate={onUpdate}
        isDisabled={job.isFinal}
        viewerRole={viewerRole}
      />
    </TimesheetBlocksRow>
  );
};

const PayrollEntriesTable = ({
  job,
  timesheetBillableEntry,
  timesheetPayrollEntries,
  onUpdate,
  isNameColumn,
  responsive,
  viewerRole,
}: any) => {
  const datetimes = getDatetimes({timesheetBillableEntry});

  return (
    <Column>
      <ColumnHeaders
        label={timesheetPayrollEntries[0].moverPosition.name}
        datetimes={datetimes}
        isNameColumn={isNameColumn}
      />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <TimesheetBlocksContainer isNameColumn={isNameColumn}>
        {getSortedTimesheetPayrollEntries({timesheetPayrollEntries}).map(
          (timesheetPayrollEntry: any) => {
            return (
              <React.Fragment key={timesheetPayrollEntry.id}>
                <RowDivider />
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                <Row isNameColumn={isNameColumn}>
                  <TimesheetPayrollEntryRow
                    job={job}
                    timesheetPayrollEntry={timesheetPayrollEntry}
                    timesheetBillableEntry={timesheetBillableEntry}
                    datetimes={datetimes}
                    onUpdate={onUpdate}
                    isNameColumn={isNameColumn}
                    responsive={responsive}
                    viewerRole={viewerRole}
                  />
                </Row>
              </React.Fragment>
            );
          },
        )}
      </TimesheetBlocksContainer>
    </Column>
  );
};

const TimesheetPayrollEntriesList = ({
  job,
  onUpdate,
  isNameColumn,
  responsive,
  viewerRole,
}: any) => {
  const isPerPositionTimesheet =
    job.timesheetBillableEntryKind === TimesheetBillableEntryKind.POSITION;
  const tableProps = {onUpdate, isNameColumn, viewerRole};

  if (isPerPositionTimesheet) {
    return (
      <React.Fragment>
        {getSortedTimesheetBillableEntries({job}).map((timesheetBillableEntry: any) => {
          return (
            <React.Fragment key={timesheetBillableEntry.id}>
              <Space height={24} />
              <PayrollEntriesTable
                responsive={responsive}
                job={job}
                timesheetBillableEntry={timesheetBillableEntry}
                timesheetPayrollEntries={timesheetBillableEntry.timesheetPayrollEntries}
                {...tableProps}
              />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {getJobTimesheetPayrollEntryGroups({job}).map((group, index) => {
        return (
          <React.Fragment key={index}>
            <Space height={24} />
            <PayrollEntriesTable
              job={job}
              responsive={responsive}
              timesheetBillableEntry={job.timesheetBillableEntries[0]}
              timesheetPayrollEntries={group}
              {...tableProps}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const TimesheetPayrollEntries = ({job, onUpdate, paddingHorizontal = 24, viewerRole}: any) => {
  const responsive = useResponsive();
  const scrollView = useScrollView();
  useMountEffect(() => {
    const blockWidthWithDivider = BLOCK_WIDTH + 1;
    const timesheetBlock = getEarliestTimesheetBlock({job});
    if (timesheetBlock) {
      const timesheetBlockStart = Datetime.fromDatetime(timesheetBlock.rangeFrom, true);
      const timesheetBlockStartHour = timesheetBlockStart.get('hour');

      if (timesheetBlockStartHour > 0) {
        const timesheetBlockStartMinute = timesheetBlockStart.get('minute');
        if (timesheetBlockStartMinute > 0) {
          // Eg. If the first block starts at 8:15am, scroll to 8am
          // @ts-expect-error TS(2345): Argument of type '{ animated: false; x: number; }'... Remove this comment to see the full error message
          scrollView.handleScrollTo({
            animated: false,
            x: blockWidthWithDivider * timesheetBlockStartHour,
          });
        } else {
          // Eg. If the first block starts at 8:00am, scroll to 7am
          // @ts-expect-error TS(2345): Argument of type '{ animated: false; x: number; }'... Remove this comment to see the full error message
          scrollView.handleScrollTo({
            animated: false,
            x: blockWidthWithDivider * (timesheetBlockStartHour - 1),
          });
        }
      }
    } else {
      // Default is to scroll to 6am
      // @ts-expect-error TS(2345): Argument of type '{ animated: false; x: number; }'... Remove this comment to see the full error message
      scrollView.handleScrollTo({animated: false, x: blockWidthWithDivider * 6});
    }
  });
  return (
    <React.Fragment>
      <TimesheetPayrollEntriesLegend
        responsive={responsive}
        paddingHorizontal={paddingHorizontal}
      />
      <Row>
        <Space width={paddingHorizontal} />
        <Column>
          <TimesheetPayrollEntriesList
            job={job}
            onUpdate={onUpdate}
            responsive={responsive}
            isNameColumn
            viewerRole={viewerRole}
          />
        </Column>
        <Column style={{flex: 1}}>
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={responsive.desktop}
            ref={scrollView.ref}
          >
            <Column>
              <TimesheetPayrollEntriesList
                job={job}
                onUpdate={onUpdate}
                responsive={responsive}
                viewerRole={viewerRole}
              />
            </Column>
            <Space width={paddingHorizontal} />
          </ScrollView>
        </Column>
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TimesheetPayrollEntries.fragment = gql`
  ${TimesheetBillableEntry.getMinutesWorked.fragment}
  ${TimesheetBillableEntry.getNumberOfDays.fragment}
  ${TimesheetBillableEntry.getDates.fragment}
  ${EditTimesheetPayrollEntryModal.fragment}

  fragment TimesheetPayrollEntries on Job {
    id
    isFinal
    timesheetBillableEntryKind
    crewHoursAdjustmentMinutes
    timesheetBillableEntries {
      id
      timesheetBlocksDateRange {
        start
      }
      timesheetPayrollEntries {
        id
        user {
          id
          fullName
        }
        moverPosition {
          id
          name
        }
        timeWorked {
          withoutAdjustments
          adjustmentsFromTimesheet
          adjustmentsFromBillRules
          totalBreak
        }
        effectiveTimesheetBlocks {
          id
          kind
          rangeFrom
          rangeTo
          isBillable
        }
        ...EditTimesheetPayrollEntryModal
      }
      ...TimesheetBillableEntry_getMinutesWorked
      ...TimesheetBillableEntry_getNumberOfDays
      ...TimesheetBillableEntry_getDates
    }
  }
`;

export default TimesheetPayrollEntries;
