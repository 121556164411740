// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Popover, ScrollView, Space, Styled} from '@supermove/components';
import {useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const PopoverContentContainer = Styled.View`
  width: ${({width}) => width}px;
  border-radius: 4px;
  position: absolute;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
`;

const MenuItemButton = Styled.ButtonV2`
  background-color: ${({isHighlighted}) => (isHighlighted ? colors.hover : 'transparent')};
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  padding-vertical: 8px;
  padding-horizontal: 16px;
  color: ${({color}) => color};
`;

const MenuItem = ({onPress, color, disabled, children}) => {
  const {isHovered, ref} = useHover();
  return (
    <MenuItemButton onPress={onPress} isHighlighted={isHovered} ref={ref} disabled={disabled}>
      <MenuItemText color={color}>{children}</MenuItemText>
    </MenuItemButton>
  );
};

const ActionMenuPopover = ({
  popover,
  handleOpen,
  handleClose,
  placement,
  width,
  offset,
  scrollStyle,
  children,
}) => {
  return (
    <Popover
      placement={placement}
      isOpen={popover.isOpen}
      handleOpen={handleOpen || popover.handleOpen}
      handleClose={handleClose || popover.handleClose}
      reference={popover.ref}
      offset={offset}
    >
      <PopoverContentContainer width={width}>
        <ScrollView style={scrollStyle}>
          <Space height={8} />
          {children}
          <Space height={8} />
        </ScrollView>
      </PopoverContentContainer>
    </Popover>
  );
};

ActionMenuPopover.MenuItem = MenuItem;

// --------------------------------------------------
// Props
// --------------------------------------------------
ActionMenuPopover.propTypes = {
  popover: PropTypes.object.isRequired,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  placement: PropTypes.string,
  offset: PropTypes.arrayOf(PropTypes.number),
  width: PropTypes.number,
  scrollStyle: PropTypes.object,
};

ActionMenuPopover.defaultProps = {
  handleOpen: null,
  handleClose: null,
  placement: Popover.Positions.Auto,
  offset: [0, 4],
  width: 120,
  scrollStyle: {},
};

MenuItem.propTypes = {
  onPress: PropTypes.func.isRequired,
  color: PropTypes.string,
};

MenuItem.defaultProps = {
  color: colors.gray.primary,
};

export default ActionMenuPopover;
