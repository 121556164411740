// Libraries
import React from 'react';

// Supermove
import {Space, Icon, Popover, ScrollView, Styled} from '@supermove/components';
import {ResponsiveType, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import Button from '@shared/design/components/Button/index';
import Sheet from '@shared/design/components/Sheet';

const PopoverContainer = Styled.View`
  width: 400px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
  overflow: visible;
`;
const ContentContainer = Styled.View`
  padding-horizontal: 24px;
`;

const Header = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FilterCount = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const ClearFiltersContainer = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ClearFilters = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.blue.interactive};
`;

const ActionButtonContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  z-index: -1;
  padding-horizontal: 16px;
`;

interface PopoverFilterProps {
  activeFiltersCount: number;
  /** Needed for compatibility but should not be set on future components */
  hideActionButtons?: boolean;
  handleClear?: () => void;
  handleApply?: () => void;
  popover: ReturnType<typeof usePopover>;
  children: React.ReactElement;
  isScrolling?: boolean; // For popovers with many filters, the content should be scrollable
  maxHeight?: number;
  responsive: ResponsiveType;
}

const DEFAULT_MAX_HEIGHT = 580;

const PopoverContentContainer = ({
  children,
  isScrolling,
  maxHeight,
}: {
  children: JSX.Element;
  isScrolling?: boolean;
  maxHeight: number;
}) => {
  if (isScrolling) {
    return <ScrollView style={{maxHeight}}>{children}</ScrollView>;
  }
  return children;
};

const PopoverFilter = ({
  activeFiltersCount,
  handleClear,
  handleApply,
  hideActionButtons,
  children,
  isScrolling,
  popover,
  responsive,
}: PopoverFilterProps) => {
  return responsive.desktop ? (
    <Popover
      key={popover.key}
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <PopoverContent
        activeFiltersCount={activeFiltersCount}
        handleApply={handleApply}
        handleCancel={popover.handleClose}
        handleClear={handleClear}
        hideActionButtons={hideActionButtons}
        isScrolling={isScrolling}
        responsive={responsive}
      >
        {children}
      </PopoverContent>
    </Popover>
  ) : (
    <Sheet.PreventPropagationContainer>
      <Sheet
        isOpen={popover.isOpen}
        handleClose={popover.handleClose}
        headerText={`Filters (${activeFiltersCount || 0})`}
        isFixedHeight={false}
        HeaderRightComponent={() => (
          <ClearFilter handleClear={handleClear} responsive={responsive} />
        )}
      >
        <ScrollView>
          {children}
          {hideActionButtons ? null : (
            <Footer
              handleCancel={popover.handleClose}
              handleApply={handleApply}
              responsive={responsive}
            />
          )}
          <Space height={16} />
        </ScrollView>
      </Sheet>
    </Sheet.PreventPropagationContainer>
  );
};

interface PopoverContentProps extends Omit<PopoverFilterProps, 'popover'> {
  handleCancel: () => void;
}

const PopoverContent = ({
  activeFiltersCount,
  handleApply,
  handleCancel,
  handleClear,
  hideActionButtons,
  isScrolling,
  children,
  maxHeight = DEFAULT_MAX_HEIGHT,
  responsive,
}: PopoverContentProps) => {
  return (
    <PopoverContainer>
      <PopoverContentContainer isScrolling={isScrolling} maxHeight={maxHeight}>
        <ContentContainer>
          <Space height={24} />
          <Header>
            <FilterCount responsive={responsive}>Filters ({activeFiltersCount || 0})</FilterCount>
            <ClearFilter handleClear={handleClear} responsive={responsive} />
          </Header>
          <Space height={12} />
          {children}
          {hideActionButtons ? null : (
            <Footer handleCancel={handleCancel} handleApply={handleApply} responsive={responsive} />
          )}
          <Space height={24} />
        </ContentContainer>
      </PopoverContentContainer>
    </PopoverContainer>
  );
};

const ClearFilter = ({
  handleClear,
  responsive,
}: {
  handleClear?: () => void;
  responsive: ResponsiveType;
}) => {
  return (
    <ClearFiltersContainer onPress={handleClear}>
      <Icon source={Icon.Trash} color={colors.blue.interactive} size={12} />
      <Space width={8} />
      <ClearFilters
        responsive={responsive}
      >{`Clear${responsive.desktop ? ' Filters' : ''}`}</ClearFilters>
    </ClearFiltersContainer>
  );
};

const Footer = ({
  handleCancel,
  handleApply,
  responsive,
}: {
  handleCancel: () => void;
  handleApply?: () => void;
  responsive: ResponsiveType;
}) => {
  const isDesktop = responsive.desktop;
  return (
    <ActionButtonContainer style={{justifyContent: isDesktop ? 'flex-end' : 'space-around'}}>
      <QuaternaryButton
        onPress={handleCancel}
        text='Cancel'
        textColor={colors.blue.interactive}
        isWidthOfContainer={!isDesktop}
        style={isDesktop ? undefined : {flex: 1}}
      />
      <Space width={32} />
      <Button
        onPress={handleApply}
        text='Apply'
        iconLeft={Icon.Check}
        isWidthOfContainer={!isDesktop}
        style={isDesktop ? undefined : {flex: 1}}
      />
    </ActionButtonContainer>
  );
};

export default PopoverFilter;
