// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive, useQuery} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SendJobDetailsButton from 'modules/Day/Jobs/components/SendJobDetailsButton';
import CreateCrewsForJobButton from 'modules/Job/V2/Move/components/CreateCrewsForJobButton';
import CrewDispatchSection from 'modules/Job/V2/Move/components/CrewDispatchSection';
import JobDispatchBlockShipments from 'modules/Job/V2/Move/components/JobDispatchBlockShipments';
import JobDispatchCrewsList from 'modules/Job/V2/Move/components/JobDispatchCrewsList';

const Container = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: ${({mobile}) => (mobile ? 'flex-end' : 'center')};
  justify-content: space-between;
`;

const StatusesRow = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.Text`
  ${Typography.Heading1}
`;

const Status = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const StatusDot = Styled.View`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  margin-right: 5px;
`;

const StatusText = Styled.H7`
  color: ${colors.gray.secondary};
`;

const Actions = Styled.View`
  flex-direction: row;
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const getShowTrucks = ({job}) => {
  if (Job.getIsBeforeMove(job)) {
    return job.hasJobStepDispatchTrucks;
  }
  return true;
};

const getShowMovers = ({job}) => {
  if (Job.getIsBeforeMove(job)) {
    return Job.getIsMove(job);
  }
  return true;
};

const getTitle = ({showTrucks, showMovers, responsive}) => {
  if (showTrucks && showMovers) {
    return `Trucks${responsive.mobile ? '/' : ' & '}Movers`;
  }
  if (showTrucks) {
    return 'Trucks';
  }
  return 'Movers';
};

const StatusMovers = ({job}) => {
  return (
    <Status>
      <StatusDot
        color={job.employeeApprovalStatus.isFullyApproved ? colors.green.status : colors.Pink600}
      />
      <StatusText>
        {`${job.employeeApprovalStatus.numberApproved}/${job.numberOfMovers} Confirmed Movers`}
      </StatusText>
    </Status>
  );
};

const StatusTrucks = ({job}) => {
  return (
    <Status>
      <StatusDot
        color={
          job.numberOfAssignedTrucks < job.numberOfTrucks ? colors.Pink600 : colors.green.status
        }
      />
      <StatusText>{`${job.numberOfAssignedTrucks}/${job.numberOfTrucks} Trucks`}</StatusText>
    </Status>
  );
};

const Statuses = ({job, showTrucks, showMovers}) => {
  const {navigator} = useNavigationDOM();
  const tripUuid = _.get(job, 'project.shipment.trip.uuid');
  return (
    <StatusesRow>
      {showTrucks && <StatusTrucks job={job} />}
      {showTrucks && showMovers && <Space width={8} />}
      {showMovers && <StatusMovers job={job} />}
      {tripUuid && (
        <QuaternaryButton
          style={{paddingHorizontal: 8}}
          onPress={() => {
            navigator.push(`/dispatch/long-distance/trips/${tripUuid}/shipments`);
          }}
        >
          <LinkText>View Trip</LinkText>
        </QuaternaryButton>
      )}
    </StatusesRow>
  );
};

const BlockHeader = ({responsive, job, viewer, refetch, showTrucks, showMovers}) => {
  return (
    <React.Fragment>
      <Header {...responsive}>
        <Title>{getTitle({showTrucks, showMovers, responsive})}</Title>
        {responsive.desktop && (
          <React.Fragment>
            <Space width={16} />
            <Statuses job={job} showTrucks={showTrucks} showMovers={showMovers} />
          </React.Fragment>
        )}
        <Actions>
          {job.organization.hasMultipleOrganizations && (
            <CreateCrewsForJobButton job={job} viewer={viewer} refetch={refetch} isSmall />
          )}
          <Space width={4} />
          <SendJobDetailsButton job={job} refetch={refetch} />
        </Actions>
      </Header>
      {responsive.mobile && (
        <React.Fragment>
          <Space height={16} />
          <Statuses job={job} showTrucks={showTrucks} showMovers={showMovers} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const JobDispatchTrucksAndMoversContent = ({job, viewer, refetch}) => {
  const responsive = useResponsive();
  const showTrucks = getShowTrucks({job});
  const showMovers = getShowMovers({job});

  return (
    <React.Fragment>
      <BlockHeader
        responsive={responsive}
        job={job}
        viewer={viewer}
        refetch={refetch}
        showTrucks={showTrucks}
        showMovers={showMovers}
      />
      <Space height={responsive.mobile ? 16 : 8} />
      {job.organization.hasMultipleOrganizations ? (
        // An organization with multiple organizations will show a list of each assigned crew
        <JobDispatchCrewsList
          job={job}
          viewer={viewer}
          refetch={refetch}
          showTrucks={showTrucks}
          showMovers={showMovers}
        />
      ) : (
        // An organization that does not have multiple organizations will always have
        // a default crew that gets created when the job is created
        <CrewDispatchSection
          crew={job.crews[0]}
          refetch={refetch}
          showTrucks={showTrucks}
          showMovers={showMovers}
        />
      )}
    </React.Fragment>
  );
};

const JobDispatchBlockQuery = ({sectionIndex, refetch}) => {
  const responsive = useResponsive();
  const {params} = useNavigationDOM();
  const {
    loading,
    data,
    refetch: refetchJobDispatchBlock,
  } = useQuery(JobDispatchBlock.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
  });

  return (
    <Loading loading={loading}>
      {() => {
        return (
          <Container sectionIndex={sectionIndex}>
            {data.job.project.projectType.isLongDistance && (
              <React.Fragment>
                <JobDispatchBlockShipments job={data.job} />
                <Space height={responsive.mobile ? 28 : 40} />
              </React.Fragment>
            )}
            <JobDispatchTrucksAndMoversContent
              job={data.job}
              viewer={data.viewer}
              refetch={() => {
                // This first refetch is to update data for the rest of the page
                refetch();
                refetchJobDispatchBlock();
              }}
            />
          </Container>
        );
      }}
    </Loading>
  );
};

const JobDispatchBlock = ({sectionIndex, job, refetch}) => {
  return (
    <JobDispatchBlockQuery
      key={`${job.numberOfMovers}_${job.numberOfTrucks}`}
      sectionIndex={sectionIndex}
      refetch={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchBlock.listener = gql`
  fragment JobDispatchBlock_listener on Job {
    id
    numberOfMovers
    numberOfTrucks
  }
`;

JobDispatchBlock.fragment = gql`
  ${JobDispatchBlock.listener}

  fragment JobDispatchBlock on Job {
    id
    ...JobDispatchBlock_listener
  }
`;

JobDispatchBlock.query = gql`
  ${CrewDispatchSection.fragment}
  ${CreateCrewsForJobButton.fragment}
  ${Job.getIsBeforeMove.fragment}
  ${Job.getIsMove.fragment}
  ${JobDispatchBlockShipments.fragment}
  ${JobDispatchCrewsList.fragment}
  ${SendJobDetailsButton.fragment}
  ${JobDispatchBlock.listener}

  query JobDispatchBlock($uuid: String!) {
    job(uuid: $uuid) {
      id
      numberOfAssignedTrucks
      hasJobStepDispatchTrucks: hasJobStep(kind: "DISPATCH_TRUCKS")
      employeeApprovalStatus {
        isFullyApproved
        numberApproved
      }
      crews {
        id
        ...CrewDispatchSection
      }
      organization {
        id
        hasMultipleOrganizations
      }
      project {
        id
        projectType {
          id
          isLongDistance
        }
        shipment {
          id
          trip {
            id
            uuid
          }
        }
      }
      ...CreateCrewsForJobButton_Job
      ...Job_getIsBeforeMove
      ...Job_getIsMove
      ...JobDispatchBlockShipments
      ...JobDispatchCrewsList
      ...SendJobDetailsButton
      ...JobDispatchBlock_listener
    }

    viewer {
      id
      ...JobDispatchCrewsList_Viewer
      ...CreateCrewsForJobButton_Viewer
    }
  }
`;

export default JobDispatchBlock;
