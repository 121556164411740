// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Emoji, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive, useScrollView} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import DocumentV2ByCategory from '@shared/modules/Document/components/DocumentV2ByCategory';
import DownloadInvoicePDFButton from 'modules/Customer/Invoice/components/DownloadInvoicePDFButton';
import InvoiceCancelledPage from 'modules/Customer/Invoice/components/InvoiceCancelledPage';
import InvoiceNotFoundPage from 'modules/Customer/Invoice/components/InvoiceNotFoundPage';
import InvoiceSection from 'modules/Customer/Invoice/components/InvoiceSection';

const Container = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  width: ${(props) => (props.mobile ? '100%' : '796px')};
  padding-horizontal: ${(props) => (props.mobile ? 20 : 0)}px;
`;

const DocumentContainer = Styled.View`
  border-width: 1;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  padding: ${(props) => (props.mobile ? 10 : 20)}px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.Text`
  ${Typography.PageHeading}
`;

const Subtitle = Styled.Text`
  ${Typography.Body}
`;

const DocumentTitle = Styled.Text`
  ${Typography.Heading2}
`;

const InvoiceCustomerDocumentPage = ({
  invoice,
  documentTitle,
  HeaderComponent,
  headerComponentProps,
  FooterComponent,
  footerComponentProps,
  getIsCorrectPage,
  redirectRoute,
}) => {
  const responsive = useResponsive();
  const scrollView = useScrollView();
  const {navigator} = useNavigationDOM();

  if (!getIsCorrectPage({invoice})) {
    navigator.push(redirectRoute);
  } else if (invoice.status === InvoiceStatus.CANCELLED) {
    return <InvoiceCancelledPage invoice={invoice} />;
  }

  return (
    <Container>
      <ScrollView
        ref={scrollView.ref}
        alwaysBounceVertical={false}
        style={{flex: 1}}
        contentContainerStyle={{alignItems: 'center'}}
      >
        <ContentContainer {...responsive}>
          {HeaderComponent && <HeaderComponent {...headerComponentProps} scrollView={scrollView} />}
          <Space height={30} />
          <Row style={{justifyContent: 'center'}}>
            <Emoji name={'memo'} />
            <Space width={5} />
            <DocumentTitle>{documentTitle}</DocumentTitle>
            <Space style={{flex: 1}} />
            <DownloadInvoicePDFButton invoice={invoice} />
          </Row>
          <Space height={10} />
          <DocumentContainer {...responsive}>
            {_.isEmpty(invoice.document) ? (
              <Container style={{minHeight: '800px'}}>
                <InvoiceNotFoundPage />
              </Container>
            ) : (
              <DocumentV2ByCategory document={invoice.document} />
            )}
          </DocumentContainer>
          <Space height={30} />
          {FooterComponent && <FooterComponent {...footerComponentProps} />}
          <Space height={60} />
        </ContentContainer>
      </ScrollView>
    </Container>
  );
};

InvoiceCustomerDocumentPage.PageTitle = Title;
InvoiceCustomerDocumentPage.Subtitle = Subtitle;

// --------------------------------------------------
// Props
// --------------------------------------------------
InvoiceCustomerDocumentPage.propTypes = {
  invoice: PropTypes.object.isRequired,
  documentTitle: PropTypes.string.isRequired,
  HeaderComponent: PropTypes.func,
  headerComponentProps: PropTypes.object,
  FooterComponent: PropTypes.func,
  footerComponentProps: PropTypes.object,
  getIsCorrectPage: PropTypes.func,
  redirectRoute: PropTypes.string,
};

InvoiceCustomerDocumentPage.defaultProps = {
  HeaderComponent: null,
  headerComponentProps: {},
  FooterComponent: null,
  footerComponentProps: {},
  getIsCorrectPage: () => true,
  redirectRoute: '',
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceCustomerDocumentPage.fragment = gql`
  ${DocumentV2ByCategory.fragment}
  ${DownloadInvoicePDFButton.fragment}
  ${InvoiceCancelledPage.fragment}
  ${InvoiceSection.fragment}
  fragment InvoiceCustomerDocumentPage on Invoice {
    id
    status
    document {
      id
      ...DocumentV2ByCategory
    }
    ...DownloadInvoicePDFButton
    ...InvoiceCancelledPage
    ...InvoiceSection
  }
`;

export default InvoiceCustomerDocumentPage;
