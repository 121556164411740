// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    logoFileId: (organization as any).logoFileId,
    // Private
    isUploadingPhoto: false,
  }),
  gql`
    fragment OrganizationLogoForm_edit on Organization {
      id
      logoFileId
    }
  `,
);

const toForm = ({organizationId, logoFileId}: any) => ({
  organizationId,
  logoFileId,

  // Private
  isUploadingPhoto: false,
});

const toMutation = ({organizationId, logoFileId}: any) => ({
  organizationId,
  logoFileId,
});

const OrganizationLogoForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationLogoForm;
