// Libraries
import React from 'react';

// Supermove

// App
import Drawer from '@shared/design/components/Drawer';

export interface DrawerWithCloseProps {
  isOpen: boolean;
  handleClose: () => void;
  headerText: string;
  description?: string;
  width?: number;
  children: React.ReactNode;
  bodyProps?: object;
}

const DrawerWithClose = ({
  isOpen = false,
  handleClose = () => {},
  headerText = '',
  description = '',
  width = Drawer.WIDTH.DEFAULT,
  children,
  bodyProps = {},
}: DrawerWithCloseProps) => {
  return (
    <Drawer isOpen={isOpen} handleClose={handleClose} width={width}>
      <Drawer.Header
        headerText={headerText}
        description={description}
        handleClose={handleClose}
        isClosable
        isResponsive
      />
      <Drawer.Body {...bodyProps}>{children}</Drawer.Body>
    </Drawer>
  );
};

DrawerWithClose.Text = Drawer.Text;
DrawerWithClose.WIDTH = Drawer.WIDTH;

export default DrawerWithClose;
