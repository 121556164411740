// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';

const shouldResetJobDate = ({
  prevJobTypeId,
  nextJobTypeId,
  jobTypes,
  shouldResetJobDateOnJobTypeChange,
}) => {
  // Only returns true if flag is set and switch from an unblocked to a blocked job type
  // i.e. from ESTIMATE to MOVE
  const unblockedJobTypes = ['ESTIMATE', 'REQUEST'];
  const jobType = jobTypes.find((jobType) => jobType.id === _.toString(nextJobTypeId));
  const prevJobType = jobTypes.find((jobType) => jobType.id === _.toString(prevJobTypeId));
  const isPrevUnblocked = prevJobType && unblockedJobTypes.includes(prevJobType.kind);
  const isNextBlocked = jobType && !unblockedJobTypes.includes(jobType.kind);
  return shouldResetJobDateOnJobTypeChange && isPrevUnblocked && isNextBlocked;
};

const handleChangeJobType = ({
  form,
  field,
  value,
  prevValue,
  organization,
  shouldResetJobDateOnJobTypeChange,
  jobFormField,
  jobTypes,
}) => {
  const {isEnabledCreateProjectDispatchLocks} = organization.features;
  if (
    isEnabledCreateProjectDispatchLocks &&
    shouldResetJobDate({
      prevJobTypeId: prevValue,
      nextJobTypeId: value,
      jobTypes,
      shouldResetJobDateOnJobTypeChange,
    })
  ) {
    form.setFieldValue(`${jobFormField}.date`, '');
  }

  form.setFieldValue(`${field}.valueForms`, []);
};

const JobTypeDropdown = ({
  index,
  organization,
  projectTypeId,
  form,
  field,
  shouldResetJobDateOnJobTypeChange,
  jobFormField,
  isRequired,
  isResponsive,
  isDisabled,
  style,
}) => {
  const responsive = useResponsive();
  const projectType = _.find(organization.moveProjectTypes, (projectType) => {
    return String(projectType.id) === String(projectTypeId);
  });
  const jobTypes = projectType ? projectType.jobTypes : [];

  return (
    <FieldInput
      {...form}
      index={index}
      component={DropdownInput}
      name={`${field}.jobTypeId`}
      label={'Job Type'}
      isRequired={isRequired}
      isResponsive={isResponsive}
      isDisabled
      input={{
        options: jobTypes.map((jobType) => ({
          label: jobType.name,
          value: Number(jobType.id),
        })),
        // If the isRequired prop is passed in, we show the
        // updated styling for marking as required
        required: !isRequired && !_.get(form.values, `${field}.jobTypeId`),
        placeholder: 'Select Job Type',
        isSearchable: true,
        isDisabled,
        setFieldValue: form.customSetFieldValue || form.setFieldValue,
        onChangeValue: (value, option, prevValue) => {
          handleChangeJobType({
            form,
            field,
            value,
            prevValue,
            organization,
            shouldResetJobDateOnJobTypeChange,
            jobFormField,
            jobTypes,
          });
        },
        style: style || {
          width: responsive.mobile ? '100%' : 250,
        },
      }}
      style={
        style || {
          width: responsive.mobile ? '100%' : 250,
        }
      }
    />
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobTypeDropdown.propTypes = {
  index: PropTypes.number,
  organization: PropTypes.object.isRequired,
  projectTypeId: PropTypes.number,
  form: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  shouldResetJobDateOnJobTypeChange: PropTypes.bool,
  jobFormField: PropTypes.string.isRequired,
  style: PropTypes.object,
};

JobTypeDropdown.defaultProps = {
  index: 0,
  projectTypeId: null,
  shouldResetJobDateOnJobTypeChange: false,
  style: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTypeDropdown.fragment = gql`
  fragment JobTypeDropdown on Organization {
    id
    features {
      isEnabledCreateProjectDispatchLocks: isEnabled(feature: "CREATE_PROJECT_DISPATCH_LOCKS")
    }
    moveProjectTypes: projectTypesForCategory(category: "MOVE") {
      id
      jobTypes {
        id
        kind
        name
      }
    }
  }
`;

export default JobTypeDropdown;
