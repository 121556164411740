// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, TimeInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import Modal from '@shared/design/components/Modal';
import JobDateFields from 'modules/Job/components/JobDateFields';
import JobTypeDropdown from 'modules/Job/components/JobTypeDropdown';
import ProjectSizeInput from 'modules/Project/components/ProjectSizeInput';

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  z-index: ${({index = 0}) => 100 - index};
`;

const RowSectionContainer = Styled.View`
  flex: 1;
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const TextInput = Styled.TextInput`
  ${Typography.Body3}
`;

const FieldSpace = () => {
  return <Space height={12} width={8} />;
};

const JobDescriptionToggle = ({form, field}) => {
  const isDescriptionVisible = _.get(form.values, `${field}.isDescriptionVisible`);
  return (
    <Row index={0} style={{alignItems: 'center'}}>
      <Icon
        source={isDescriptionVisible ? Icon.Trash : Icon.Plus}
        size={14}
        color={colors.blue.interactive}
      />
      <Space width={8} />
      <Button
        onPress={() => {
          form.setFieldValue(`${field}.isDescriptionVisible`, !isDescriptionVisible);
          form.setFieldValue(`${field}.description`, '');
        }}
      >
        <ButtonText>
          {isDescriptionVisible ? 'Remove job description' : 'Add job description'}
        </ButtonText>
      </Button>
    </Row>
  );
};

const HeaderRow = ({form, field}) => {
  const responsive = useResponsive();
  return (
    <Row index={0} {...responsive}>
      <Modal.BlockHeader>Job Information</Modal.BlockHeader>
      {responsive.mobile ? <FieldSpace /> : <Space style={{flex: 1}} />}
      <JobDescriptionToggle form={form} field={field} />
    </Row>
  );
};

const TypeAndNameRow = ({form, field, jobFormField, index, organization}) => {
  const responsive = useResponsive();
  return (
    <Row index={index} {...responsive}>
      <JobTypeDropdown
        organization={organization}
        projectTypeId={_.get(form.values, `${field}.projectTypeId`)}
        field={jobFormField}
        form={form}
        shouldResetJobDateOnJobTypeChange
        jobFormField={jobFormField}
        style={{flex: 1}}
      />
      <FieldSpace />
      <FieldInput.Memoized
        {...form}
        name={`${field}.name`}
        label={'Job Name'}
        input={{
          placeholder: 'Enter job name',
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const ArrivalWindowFields = ({form, jobFormField, organization}) => {
  return (
    <RowSectionContainer>
      <Row>
        <FieldInput.LabelText>Origin Arrival Window</FieldInput.LabelText>
      </Row>
      <Space height={4} />
      <Row>
        <FieldInput.Memoized
          {...form}
          index={1}
          component={TimeInput}
          name={`${jobFormField}.startTime1`}
          input={{
            required:
              organization.features.isEnabledJobStartTime1IsRequired &&
              !_.get(form.values, `${jobFormField}.startTime1`),
            component: TextInput,
            placeholder: 'Start',
            setFieldValue: form.setFieldValue,
          }}
          style={{
            flex: 1,
          }}
        />
        <FieldSpace />
        <FieldInput.Memoized
          {...form}
          index={2}
          component={TimeInput}
          name={`${jobFormField}.startTime2`}
          input={{
            component: TextInput,
            placeholder: 'End',
            setFieldValue: form.setFieldValue,
          }}
          style={{
            flex: 1,
          }}
        />
      </Row>
    </RowSectionContainer>
  );
};

const EstimatedHoursFields = ({form, jobFormField, organization}) => {
  return (
    <RowSectionContainer>
      <Row>
        <FieldInput.LabelText># of Estimated Hours</FieldInput.LabelText>
      </Row>
      <Space height={4} />
      <Row>
        <FieldInput.Memoized
          {...form}
          index={1}
          name={`${jobFormField}.estimatedHours1`}
          input={{
            placeholder: 'Min',
            keyboardType: 'phone-pad',
          }}
          style={{
            flex: 1,
          }}
        />
        <FieldSpace />
        <FieldInput.Memoized
          {...form}
          index={2}
          name={`${jobFormField}.estimatedHours2`}
          input={{
            placeholder: 'Max',
            keyboardType: 'phone-pad',
          }}
          style={{
            flex: 1,
          }}
        />
      </Row>
    </RowSectionContainer>
  );
};

const DateAndTimeRow = ({form, field, jobFormField, index, organization, isJobDateRequired}) => {
  const responsive = useResponsive();
  const projectTypeId = _.get(form.values, `${field}.projectTypeId`);
  return (
    <Row index={index} {...responsive}>
      <JobDateFields
        form={form}
        field={jobFormField}
        label={'Job Date'}
        organization={organization}
        projectTypeId={projectTypeId}
        isJobDateRequired={isJobDateRequired}
      />
      <FieldSpace />
      <Row style={{flex: 1}}>
        <ArrivalWindowFields form={form} jobFormField={jobFormField} organization={organization} />
        <FieldSpace />
        <EstimatedHoursFields form={form} jobFormField={jobFormField} organization={organization} />
      </Row>
    </Row>
  );
};

const MoveProjectJobFields = ({form, field, organization, isJobDateRequired}) => {
  const isDescriptionVisible = _.get(form.values, `${field}.isDescriptionVisible`);
  const jobFormField = `${field}.jobForms.0`;
  return (
    <React.Fragment>
      <HeaderRow form={form} field={field} />
      <FieldSpace />
      <TypeAndNameRow
        form={form}
        field={field}
        jobFormField={jobFormField}
        index={0}
        organization={organization}
      />
      <FieldSpace />
      <DateAndTimeRow
        form={form}
        field={field}
        jobFormField={jobFormField}
        index={1}
        organization={organization}
        isJobDateRequired={isJobDateRequired}
      />
      {isDescriptionVisible && (
        <React.Fragment>
          <FieldSpace />
          <FieldInput.Memoized
            {...form}
            name={`${jobFormField}.description`}
            label={'Job Description (shown to customer)'}
            index={2}
            input={{
              placeholder: 'Enter a description for the job. This will be shown to the customer.',
              style: {minHeight: 76, paddingTop: 8},
              multiline: true,
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MoveProjectJobFields.fragment = gql`
  ${ProjectSizeInput.fragment}
  ${JobDateFields.fragment}
  ${JobTypeDropdown.fragment}
  fragment MoveProjectJobFields on Organization {
    id
    ...ProjectSizeInput
    ...JobDateFields
    ...JobTypeDropdown
  }
`;

export default MoveProjectJobFields;
