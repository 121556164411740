// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime, pluralize} from '@supermove/utils';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ClientChatButton from 'modules/Client/Show/components/ClientChatButton';
import EditClientModal from 'modules/Client/Show/components/EditClientModal';
import SendEmailForProjectModal from 'modules/Email/Project/components/SendEmailForProjectModal';
import CancelStorageProjectModal from 'modules/Job/V2/Storage/components/CancelStorageProjectModal';
import EditStorageProjectModal from 'modules/Job/V2/Storage/components/EditStorageProjectModal';
import EditStorageProjectStatusDropdown from 'modules/Storage/components/EditStorageProjectStatusDropdown';

const ButtonContainer = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const StorageProjectPageInformationBlockContentContainer = Styled.View`
  padding: 24px;
`;

const InfoBlockContainer = Styled.View`
  z-index: ${(props) => 100 - props.index};
  background-color: ${colors.white};
  border: 1px solid ${colors.gray.border};
  border-radius: 4px;
`;

const InfoBlockHeader = Styled.Text`
  ${Typography.Label3};
  color: ${({color}) => color};
`;

const ProjectInfoBlockContainer = Styled.View`
  padding-vertical: 12px;
  padding-horizontal: 16px;
`;

const InfoBlockHeaderContainer = Styled.View`
  padding-vertical: 12px;
  padding-horizontal: 16px;
  flex-direction: row;
  align-items: center;
`;

const InfoBlockContentContainer = Styled.View`
  padding-vertical: 12px;
  padding-horizontal: 16px;
`;

const ProjectNameText = Styled.Text`
  ${Typography.Label1};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const CancelledStatusContainer = Styled.View`
  background-color: ${colors.red.accent};
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CancelledStatusText = Styled.Text`
  ${Typography.Label3};
  color: ${colors.red.warning};
`;

const getProjectActions = ({
  editStorageProjectModal,
  sendEmailForStorageProjectModal,
  cancelStorageProjectModal,
}) => {
  return [
    {
      text: 'Edit Project',
      onPress: editStorageProjectModal.handleOpen,
    },
    {
      text: 'Send Email',
      onPress: sendEmailForStorageProjectModal.handleOpen,
    },
    // TODO: Add action back once we have decided how to handle cancelled storage projects from a product perspective
    // {
    //   text: 'Cancel Project',
    //   textColor: colors.red.warning,
    //   onPress: cancelStorageProjectModal.handleOpen,
    // },
  ];
};

const getDisplayDate = (date) => {
  if (date) {
    return Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE);
  }
};

const StorageProjectActionsButton = ({index, project, refetch}) => {
  const editStorageProjectModal = useModal({
    name: 'Edit Storage Project Modal',
    enableTracking: true,
  });
  const cancelStorageProjectModal = useModal({
    name: 'Cancel Storage Project Modal',
    enableTracking: true,
  });
  const sendEmailForStorageProjectModal = useModal({
    name: 'Send Email For Storage Project Modal',
    enableTracking: true,
  });
  return (
    <ButtonContainer index={index}>
      <DropdownButton
        text={'Actions'}
        actions={getProjectActions({
          editStorageProjectModal,
          cancelStorageProjectModal,
          sendEmailForStorageProjectModal,
        })}
        isWidthOfContainer
        ButtonComponent={SecondaryButton}
      />
      <EditStorageProjectModal
        key={editStorageProjectModal.key}
        isOpen={editStorageProjectModal.isOpen}
        handleClose={editStorageProjectModal.handleClose}
        project={project}
        refetch={refetch}
      />
      <CancelStorageProjectModal
        key={cancelStorageProjectModal.key}
        isOpen={cancelStorageProjectModal.isOpen}
        handleClose={cancelStorageProjectModal.handleClose}
        project={project}
        refetch={refetch}
      />
      <SendEmailForProjectModal
        key={sendEmailForStorageProjectModal.key}
        project={project}
        isOpen={sendEmailForStorageProjectModal.isOpen}
        handleClose={sendEmailForStorageProjectModal.handleClose}
      />
    </ButtonContainer>
  );
};

const StorageProjectProjectTitleBlock = ({index, project}) => {
  return (
    <InfoBlockContainer index={index}>
      <ProjectInfoBlockContainer>
        <ProjectNameText>{project.assignedOrganization.name}</ProjectNameText>
        <Space height={8} />
        <InfoBlockHeader color={project.projectType.color}>
          {project.projectType.name}
        </InfoBlockHeader>
        <Space height={8} />
        <ProjectNameText>{Project.getName(project)}</ProjectNameText>
        <Space height={8} />
        {/* If isCancelled=true we override the status toggle, since the actual project status may not necessarily be cancelled */}
        {project.isCancelled ? (
          <Row>
            <CancelledStatusContainer>
              <CancelledStatusText>Cancelled</CancelledStatusText>
            </CancelledStatusContainer>
          </Row>
        ) : (
          <EditStorageProjectStatusDropdown project={project} size={'LARGE'} />
        )}
      </ProjectInfoBlockContainer>
    </InfoBlockContainer>
  );
};

const StorageProjectProjectInfoBlock = ({index, project}) => {
  return (
    <InfoBlockContainer index={index}>
      <InfoBlockHeaderContainer>
        <InfoBlockHeader>Project Information</InfoBlockHeader>
      </InfoBlockHeaderContainer>
      <Line />
      <InfoBlockContentContainer>
        <FieldValue label={'Date In'} value={getDisplayDate(project.startDate)} empty={'-'} />
        <Space height={12} />
        <FieldValue label={'Date Out'} value={getDisplayDate(project.endDate)} empty={'-'} />
        <Space height={12} />
        <FieldValue
          label={'Weight'}
          value={project.weight && pluralize('lb', project.weight, true)}
          empty={'-'}
        />
      </InfoBlockContentContainer>
    </InfoBlockContainer>
  );
};

const StorageProjectCustomerInfoBlock = ({index, client, projectId, isBillingClient, refetch}) => {
  const editClientModal = useModal({name: 'EditClientModal'});

  return (
    <InfoBlockContainer index={index}>
      <InfoBlockHeaderContainer>
        <InfoBlockHeader>
          {isBillingClient ? 'Billing Information' : 'Client Information'}
        </InfoBlockHeader>
        <Space style={{flex: 1}} />
        {!isBillingClient && (
          <ClientChatButton
            projectId={projectId}
            client={client}
            handleMissingPhoneNumber={editClientModal.handleOpen}
          />
        )}
        <EditClientModal
          key={editClientModal.key}
          isOpen={editClientModal.isOpen}
          handleClose={editClientModal.handleClose}
          clientUuid={client.uuid}
          refetch={refetch}
        />
      </InfoBlockHeaderContainer>
      <Line />
      <InfoBlockContentContainer>
        <FieldValue label={'Client Name'} value={client.name} empty={'-'} />
        <Space height={12} />
        <FieldValue label={'Phone'} value={client.primaryContact.phoneNumber} empty={'-'} />
        <Space height={12} />
        <FieldValue label={'Email'} value={client.primaryContact.email} empty={'-'} />
      </InfoBlockContentContainer>
    </InfoBlockContainer>
  );
};

const StorageProjectInternalInfoBlock = ({index, project}) => {
  const {salesperson, coordinator} = project.client;
  const {additionalSalespersons} = project;
  const organization = project.assignedOrganization;

  return (
    <InfoBlockContainer index={index}>
      <InfoBlockHeaderContainer>
        <InfoBlockHeader>Internal Information</InfoBlockHeader>
      </InfoBlockHeaderContainer>
      <Line />
      <InfoBlockContentContainer>
        <FieldValue label={'Salesperson'} value={_.get(salesperson, 'fullName')} empty={'-'} />
        {organization.features.isEnabledAdditionalSalespersonIdsInput && (
          <React.Fragment>
            <Space height={12} />
            <FieldValue
              label={
                organization.features.isEnabledProjectManagersField
                  ? 'Project Managers'
                  : 'Additional Salespersons'
              }
              value={
                additionalSalespersons.length !== 0 &&
                additionalSalespersons.map((salesperson) => salesperson.fullName).join(', ')
              }
              empty={'-'}
            />
          </React.Fragment>
        )}
        <Space height={12} />
        <FieldValue label={'Coordinator'} value={_.get(coordinator, 'fullName')} empty={'-'} />
        <Space height={12} />
        <FieldValue
          label={'How did you hear about us?'}
          value={project.referralSource}
          empty={'-'}
        />
        <Space height={12} />
        <FieldValue label={'Created by'} value={_.get(project, 'creator.fullName')} empty={'-'} />
      </InfoBlockContentContainer>
    </InfoBlockContainer>
  );
};

const StorageProjectNotesBlock = ({index, project}) => {
  return (
    <InfoBlockContainer index={index}>
      <InfoBlockHeaderContainer>
        <InfoBlockHeader>Notes</InfoBlockHeader>
      </InfoBlockHeaderContainer>
      <Line />
      <InfoBlockContentContainer>
        <FieldValue label={'Office Notes'} value={project.officeNotes} empty={'-'} />
        <Space height={12} />
        <FieldValue label={'Warehouse Notes'} value={project.warehouseNotes} empty={'-'} />
      </InfoBlockContentContainer>
    </InfoBlockContainer>
  );
};

const StorageProjectPageInformationBlockContent = ({project, refetch}) => {
  return (
    <ScrollView style={{flex: 1}}>
      <StorageProjectPageInformationBlockContentContainer>
        <StorageProjectActionsButton index={0} project={project} refetch={refetch} />
        <Space height={16} />
        <StorageProjectProjectTitleBlock index={1} project={project} />
        <Space height={16} />
        <StorageProjectProjectInfoBlock index={2} project={project} />
        <Space height={16} />
        <StorageProjectCustomerInfoBlock
          index={3}
          client={project.client}
          projectId={project.id}
          refetch={refetch}
        />
        <Space height={16} />
        {project.billingClient && (
          <React.Fragment>
            <StorageProjectCustomerInfoBlock
              index={4}
              client={project.billingClient}
              projectId={project.id}
              isBillingClient
              refetch={refetch}
            />
            <Space height={16} />
          </React.Fragment>
        )}
        <StorageProjectInternalInfoBlock index={5} project={project} />
        <Space height={16} />
        <StorageProjectNotesBlock index={6} project={project} />
        <Space height={32} />
      </StorageProjectPageInformationBlockContentContainer>
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageProjectPageInformationBlockContent.listener = gql`
  fragment StorageProjectPageInformationBlockContent_listener on Project {
    id
    weight
  }
`;

StorageProjectPageInformationBlockContent.fragment = gql`
  ${EditStorageProjectModal.fragment}
  ${EditStorageProjectStatusDropdown.fragment}
  ${CancelStorageProjectModal.fragment}
  ${Project.getName.fragment}
  ${ClientChatButton.fragment}
  ${SendEmailForProjectModal.fragment}
  fragment StorageProjectPageInformationBlockContent on Project {
    id
    startDate
    endDate
    weight
    officeNotes
    warehouseNotes
    referralSource
    isCancelled
    creator {
      id
      fullName
    }
    projectType {
      id
      name
      color
    }
    client {
      id
      name
      uuid
      primaryContact {
        id
        phoneNumber
        email
      }
      salesperson {
        id
        fullName
      }
      coordinator {
        id
        fullName
      }
      ...ClientChatButton
    }
    billingClient {
      id
      name
      uuid
      primaryContact {
        id
        phoneNumber
        email
      }
      salesperson {
        id
        fullName
      }
      coordinator {
        id
        fullName
      }
      ...ClientChatButton
    }
    assignedOrganization {
      id
      name
      features {
        isEnabledAdditionalSalespersonIdsInput: isEnabled(
          feature: "ADDITIONAL_SALESPERSON_IDS_INPUT"
        )
        isEnabledProjectManagersField: isEnabled(feature: "PROJECT_MANAGERS_FIELD")
        isEnabledStorageMultibranchAssignProject: isEnabled(
          feature: "STORAGE_MULTIBRANCH_ASSIGN_PROJECT"
        )
      }
    }
    additionalSalespersons {
      id
      fullName
    }
    collections {
      id
    }
    ...EditStorageProjectModal
    ...CancelStorageProjectModal
    ...EditStorageProjectStatusDropdown
    ...Project_getName
    ...SendEmailForProjectModal
  }
`;

export default StorageProjectPageInformationBlockContent;
