// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Float, withFragment} from '@supermove/utils';

// App
import JobForm from '@shared/modules/Job/forms/JobForm';

const handleCleanupForm = (projectJobsForm: any) => {
  const {jobForms} = projectJobsForm;
  const cleanedUpJobForms = jobForms.map((jobForm: any) =>
    JobForm.handleEmptyLocationForms(jobForm),
  );
  return {...projectJobsForm, jobForms: cleanedUpJobForms};
};

const getJobFormsForEstimatedRange = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ job, project }: { job: any; p... Remove this comment to see the full error message
  ({job, project}) => {
    const activeRangeJobs = job.jobsForEstimatedRange.filter((job: any) => !job.isCancelled);
    const hasActiveChildJobs = activeRangeJobs.length > 1;

    if (hasActiveChildJobs) {
      const rangeJobs = project.activeJobsExcludingChildJobs.filter((job: any) =>
        _.some(job.jobsForEstimatedRange),
      );
      const number = _.findIndex(rangeJobs, ({id}) => id === job.id) + 1;
      return activeRangeJobs.map((estimatedRangeJob: any, index: any) => {
        // The first job in the list is parent job
        const isFirst = index === 0;
        return JobForm.editV2(estimatedRangeJob, {
          label: `${number}${isFirst ? '' : Float.toLowercaseLetter(index - 1)}`,
        });
      });
    }

    return [JobForm.editV2(job)];
  },
  gql`
    ${JobForm.editV2.fragment}

    fragment ProjectJobsForm_getJobFormsForEstimatedRange on Job {
      id
      identifier
      jobsForEstimatedRange {
        id
        isCancelled
        ...JobForm_editV2
      }
    }
  `,
);

const edit = withFragment(
  (project) => {
    return {
      projectId: (project as any).id,
      jobForms: (project as any).activeJobsExcludingChildJobs.reduce((result: any, job: any) => {
        return [
          ...result,
          // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
          ...(_.some(job.jobsForEstimatedRange)
            ? getJobFormsForEstimatedRange({job, project})
            : [JobForm.editV2(job)]),
        ];
      }, []),
    };
  },
  gql`
    ${JobForm.editV2.fragment}
    ${getJobFormsForEstimatedRange.fragment}

    fragment ProjectJobsForm_edit on Project {
      id
      activeJobsExcludingChildJobs {
        id
        jobsForEstimatedRange {
          id
          isCancelled
        }
        ...JobForm_editV2
        ...ProjectJobsForm_getJobFormsForEstimatedRange
      }
    }
  `,
);

const toForm = ({projectId, jobForms}: any) => ({
  projectId,
  jobForms: jobForms.map((jobForm: any) => JobForm.toForm(jobForm)),
});

const toMutation = ({projectId, jobForms}: any) => ({
  projectId,
  jobForms: jobForms.map((jobForm: any) => JobForm.toMutation(jobForm)),
});

const ProjectJobsForm = {
  edit,
  toForm,
  toMutation,

  handleCleanupForm,
};

export default ProjectJobsForm;
