// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, useResponsive, usePropUpdatedAt} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import {Page, PageLoadingIndicator, Navigation} from 'modules/App/components';

import JobNotFoundPage from './JobNotFoundPage';
import JobPageSidebar from './JobPageSidebar';

const Container = Styled.View`
  flex: 1;
`;

const Button = Styled.Button`
  align-self: center;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  box-shadow: none;
`;

const ButtonText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  letter-spacing: 0.5;
`;

const ActionsButton = ({responsive, onPress}) => {
  const {isHovered, ref} = useHover();

  return (
    <Button ref={ref} color={isHovered ? colors.hover : colors.white} onPress={onPress}>
      {!responsive.mobile && <ButtonText style={{marginRight: 5}}>Actions</ButtonText>}
      <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.AngleDown} />
    </Button>
  );
};

const JobPageWithNavigationContent = ({
  hasActions,
  theme,
  side,
  title,
  subtitle,
  onClose,
  children,
  ...props
}) => {
  const {isOpen, handleClose, handleOpen} = useModal();
  const responsive = useResponsive();
  const {updatedAt: loadingUpdatedAt} = usePropUpdatedAt(props.loading);

  return (
    <JobPageSidebar
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      refetch={props.refetch}
    >
      <Container>
        <Navigation
          theme={theme}
          side={side}
          title={title({...props})}
          subtitle={subtitle({...props})}
          left={() =>
            onClose ? (
              <Navigation.CloseButton theme={theme} onPress={() => onClose({...props})} />
            ) : null
          }
          right={() =>
            hasActions ? <ActionsButton responsive={responsive} onPress={handleOpen} /> : null
          }
        />
        <Loading as={PageLoadingIndicator} loading={props.loading}>
          {() => (props.data.job ? children({loadingUpdatedAt, ...props}) : <JobNotFoundPage />)}
        </Loading>
      </Container>
    </JobPageSidebar>
  );
};

const JobPageWithNavigation = ({
  hasActions,
  theme,
  side,
  title,
  subtitle,
  variables,
  query,
  onClose,
  pollInterval,
  children,
}) => {
  return (
    <Page
      fetchPolicy={'network-only'}
      query={query}
      pollInterval={pollInterval}
      variables={variables}
    >
      {(props) => (
        <JobPageWithNavigationContent
          hasActions={hasActions}
          theme={theme}
          side={side}
          title={title}
          subtitle={subtitle}
          onClose={onClose}
          children={children}
          {...props}
        />
      )}
    </Page>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobPageWithNavigation.propTypes = {
  hasActions: PropTypes.bool,
  theme: PropTypes.string,
  onClose: PropTypes.func,
  side: PropTypes.func,
  title: PropTypes.func,
  subtitle: PropTypes.func,
};

JobPageWithNavigation.defaultProps = {
  hasActions: false,

  theme: 'light',
  onClose: undefined,

  side: ({responsive}) => (responsive.mobile ? 60 : 120),
  title: () => '',
  subtitle: () => '',
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobPageWithNavigation.fragment = gql`
  fragment JobPageWithNavigation on Job {
    id
  }
`;

export default JobPageWithNavigation;
