// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Percent, Float, withFragment, Datetime} from '@supermove/utils';

// App
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';

const edit = withFragment(
  (
    jobTypeVariable,
    {format, defaultAmountOptions, name, isDerived, description, isEnabledTbdBillItems},
  ) => ({
    jobTypeVariableId: (jobTypeVariable as any).id,
    variableId: (jobTypeVariable as any).variableId,
    jobTypeId: (jobTypeVariable as any).jobTypeId,
    isVisibleForCreateProject: (jobTypeVariable as any).isVisibleForCreateProject,
    isRequiredForCreateProject: (jobTypeVariable as any).isRequiredForCreateProject,
    isEnabled: (jobTypeVariable as any).isEnabled,
    defaultAmount: (jobTypeVariable as any).defaultAmount,
    defaultStringValue: (jobTypeVariable as any).defaultStringValue,
    // Private
    format,
    defaultAmountOptions,
    name,
    isDerived,
    description,
    isDirty: false,
    isEnabledTbdBillItems,
  }),
  gql`
    fragment JobTypeVariableForm_edit on JobTypeVariable {
      id
      variableId
      jobTypeId
      isVisibleForCreateProject
      isRequiredForCreateProject
      isEnabled
      defaultAmount
      defaultStringValue
    }
  `,
);

const toForm = ({
  jobTypeVariableId,
  variableId,
  jobTypeId,
  isVisibleForCreateProject,
  isRequiredForCreateProject,
  isEnabled,
  defaultAmount,
  defaultStringValue,

  // Private
  format,

  defaultAmountOptions,
  name,
  isDerived,
  description,
  isDirty,
  isEnabledTbdBillItems,
}: any) => ({
  jobTypeVariableId,
  variableId,
  jobTypeId,
  isVisibleForCreateProject,
  isRequiredForCreateProject,
  isEnabled,
  defaultAmount: amountToForm({
    defaultAmount,
    jobTypeVariableFormat: format,
    isEnabledTbdBillItems,
  }),
  defaultStringValue: stringValueToForm(defaultStringValue, format),

  // Private
  format,
  defaultAmountOptions,
  name,
  isDerived,
  description,
  isDirty,
  isEnabledTbdBillItems,
});

const toMutation = ({
  jobTypeVariableId,
  variableId,
  jobTypeId,
  isVisibleForCreateProject,
  isRequiredForCreateProject,
  isEnabled,
  defaultAmount,
  defaultStringValue,

  // private
  format,

  isEnabledTbdBillItems,
}: any) => ({
  jobTypeVariableId,
  variableId,
  jobTypeId,
  isVisibleForCreateProject,
  isRequiredForCreateProject,
  isEnabled,
  defaultAmount: amountToMutation({
    defaultAmount,
    jobTypeVariableFormat: format,
    isEnabledTbdBillItems,
  }),
  defaultStringValue: stringValueToMutation(defaultStringValue, format),
});

const amountToForm = ({defaultAmount, jobTypeVariableFormat, isEnabledTbdBillItems}: any) => {
  switch (jobTypeVariableFormat) {
    case VariableFormat.CITY_VALUE_CURRENCY:
    case VariableFormat.CURRENCY:
    case VariableFormat.VALUE_TABLE_CURRENCY:
      return Currency.toForm(defaultAmount);
    case VariableFormat.PERCENT:
      return Percent.toForm(defaultAmount);
    default:
      // To prevent toForm to parse 0 into empty strings for FE Rendering
      if (defaultAmount === 0) {
        return '0';
      }
      return Float.toForm(defaultAmount, isEnabledTbdBillItems);
  }
};

const amountToMutation = ({defaultAmount, jobTypeVariableFormat, isEnabledTbdBillItems}: any) => {
  switch (jobTypeVariableFormat) {
    case VariableFormat.CITY_VALUE_CURRENCY:
    case VariableFormat.CURRENCY:
    case VariableFormat.VALUE_TABLE_CURRENCY:
      return Currency.toMutation(defaultAmount, isEnabledTbdBillItems);
    case VariableFormat.PERCENT:
      return Percent.toMutation(defaultAmount, isEnabledTbdBillItems);
    default:
      return Float.toMutation(defaultAmount, isEnabledTbdBillItems);
  }
};

const stringValueToForm = (defaultStringValue: any, projectTypeVariableFormat: any) => {
  switch (projectTypeVariableFormat) {
    case VariableFormat.DATE:
      return Datetime.toFormDate(defaultStringValue);
    case VariableFormat.TIME:
      return Datetime.toFormTime(defaultStringValue);
    default:
      return defaultStringValue;
  }
};

const stringValueToMutation = (defaultStringValue: any, projectTypeVariableFormat: any) => {
  switch (projectTypeVariableFormat) {
    case VariableFormat.DATE:
      return defaultStringValue ? Datetime.toMutationDate(defaultStringValue) : '';
    case VariableFormat.TIME:
      return defaultStringValue ? Datetime.toMutationTime(defaultStringValue) : '';
    default:
      return defaultStringValue;
  }
};

const JobTypeVariableForm = {
  edit,
  toForm,
  toMutation,
};

export default JobTypeVariableForm;
