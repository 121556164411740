// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, Float, withFragment} from '@supermove/utils';

const edit = withFragment(
  (job) => {
    // @ts-expect-error TS(2339): Property 'numberOfMoversValue' does not exist on t... Remove this comment to see the full error message
    const {numberOfMoversValue, numberOfTrucksValue, minEstimateHoursValue, maxEstimateHoursValue} =
      job;
    return {
      jobId: (job as any).id,
      startTime1: (job as any).startTime1,
      startTime2: (job as any).startTime2,
      estimateHours1: minEstimateHoursValue
        ? minEstimateHoursValue.amount
        : (job as any).estimateHours1,
      estimateHours2: maxEstimateHoursValue
        ? maxEstimateHoursValue.amount
        : (job as any).estimateHours2,
      crewSize: numberOfMoversValue ? numberOfMoversValue.amount : (job as any).crewSize,
      numberOfTrucks: numberOfTrucksValue
        ? numberOfTrucksValue.amount
        : (job as any).numberOfTrucks,
    };
  },
  gql`
    fragment JobDispatchInfoForm_edit on Job {
      id
      startTime1
      startTime2
      estimateHours1
      estimateHours2
      crewSize
      numberOfTrucks
      numberOfMoversValue {
        id
        amount
      }
      numberOfTrucksValue {
        id
        amount
      }
      minEstimateHoursValue {
        id
        amount
      }
      maxEstimateHoursValue {
        id
        amount
      }
    }
  `,
);

const toForm = ({
  jobId,
  startTime1,
  startTime2,
  estimateHours1,
  estimateHours2,
  crewSize,
  numberOfTrucks,
}: any) => ({
  jobId,
  startTime1: Datetime.toFormTime(startTime1),
  startTime2: Datetime.toFormTime(startTime2),
  estimateHours1: Float.toString(estimateHours1),
  estimateHours2: Float.toString(estimateHours2),
  crewSize: Float.toString(crewSize),
  numberOfTrucks: Float.toString(numberOfTrucks),
});

const toMutation = ({
  jobId,
  startTime1,
  startTime2,
  estimateHours1,
  estimateHours2,
  crewSize,
  numberOfTrucks,
}: any) => ({
  jobId,
  startTime1: Datetime.toMutationTime(startTime1),
  startTime2: Datetime.toMutationTime(startTime2),
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  estimateHours1: Float.toFloat(estimateHours1, null),
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  estimateHours2: Float.toFloat(estimateHours2, null),
  crewSize: Float.toFloat(crewSize),
  numberOfTrucks: Float.toFloat(numberOfTrucks),
});

const JobDispatchInfoForm = {
  edit,
  toForm,
  toMutation,
};

export default JobDispatchInfoForm;
