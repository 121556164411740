// Libraries
import React from 'react';

// Supermove
import {PhoneInput, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import EditPanel from '@shared/design/components/Panel/EditPanel';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ViewCell = ({label, placeholder, value}) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const ContactInformationPanelEdit = ({form}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldInput
            {...form}
            label={'Business Name'}
            name={'organizationBusinessInfoContactForm.businessName'}
            input={{
              placeholder: 'Enter business name',
            }}
          />
          <Space height={24} />
          <FieldInput
            {...form}
            component={PhoneInput}
            name={'organizationBusinessInfoContactForm.phoneNumber'}
            label={'Business Phone Number'}
            input={{
              placeholder: `Enter business phone number`,
              setFieldValue: form.setFieldValue,
            }}
          />
        </Column>
        <Space width={24} />
        <Column>
          <FieldInput
            {...form}
            label={`Owner's Name`}
            name={'organizationBusinessInfoContactForm.businessSignature'}
            input={{
              placeholder: `Enter owner's name`,
            }}
          />
          <Space height={24} />
          <FieldInput
            {...form}
            label={`Business Website`}
            name={'organizationBusinessInfoContactForm.businessWebsite'}
            input={{
              placeholder: `Enter business website`,
            }}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

const ContactInformationPanel = ({organization}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell
            label={'Business Name'}
            placeholder={'Add business name'}
            value={organization.businessName}
          />
          <Space height={24} />
          <ViewCell
            label={'Business Phone Number'}
            placeholder={'(555) 555-5555'}
            value={Phone.display(organization.phoneNumber)}
          />
        </Column>
        <Space width={24} />
        <Column>
          <ViewCell
            label={"Owner's Name"}
            placeholder={"Add owner's name"}
            value={organization.businessSignature}
          />
          <Space height={24} />
          <ViewCell
            label={'Business Website'}
            placeholder={'Add URL'}
            value={organization.businessWebsite}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

ContactInformationPanel.Edit = ContactInformationPanelEdit;

export default ContactInformationPanel;
