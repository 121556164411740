// Supermove

const _new = ({
  organizationId,
  globalDashboardId,
}: {
  organizationId: string;
  globalDashboardId: string;
}) => ({
  dashboardId: null,
  organizationId,
  globalDashboardId,
});

const toForm = ({
  organizationId,
  globalDashboardId,
}: {
  organizationId: string;
  globalDashboardId: string;
}) => ({
  organizationId,
  globalDashboardId,
});

const toMutation = ({
  organizationId,
  globalDashboardId,
}: {
  organizationId: string;
  globalDashboardId: string;
}) => ({
  organizationId,
  globalDashboardId,
});

const DashboardFromGlobalDashboardForm = {
  new: _new,
  toForm,
  toMutation,
};

export default DashboardFromGlobalDashboardForm;
