// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {BulkUpdateBooleanPropertyForm} from '@supermove/forms';
import {useModal} from '@supermove/hooks';
import {Task} from '@supermove/models';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import useBulkUpdateTasksMutation from '@shared/modules/TaskManagement/hooks/useBulkUpdateTasksMutation';

const getActions = ({
  form,
  taskIds,
  bulkArchiveTasksModal,
  bulkUnarchiveTasksModal,
  bulkCompleteTasksModal,
  bulkIncompleteTasksModal,
}) => {
  const hasSelectedTasks = !_.isEmpty(taskIds);

  return [
    {
      text: 'Archive tasks',
      isDisabled: !hasSelectedTasks,
      onPress: () => {
        form.setFieldValue('bulkUpdateBooleanPropertyForm.ids', taskIds);
        form.setFieldValue('bulkUpdateBooleanPropertyForm.property', Task.API_PROPERTY.isArchived);
        form.setFieldValue('bulkUpdateBooleanPropertyForm.booleanValue', true);
        bulkArchiveTasksModal.handleOpen();
      },
    },
    {
      text: 'Unarchive tasks',
      isDisabled: !hasSelectedTasks,
      onPress: () => {
        form.setFieldValue('bulkUpdateBooleanPropertyForm.ids', taskIds);
        form.setFieldValue('bulkUpdateBooleanPropertyForm.property', Task.API_PROPERTY.isArchived);
        form.setFieldValue('bulkUpdateBooleanPropertyForm.booleanValue', false);
        bulkUnarchiveTasksModal.handleOpen();
      },
    },
    {
      text: 'Complete tasks',
      isDisabled: !hasSelectedTasks,
      onPress: () => {
        form.setFieldValue('bulkUpdateBooleanPropertyForm.ids', taskIds);
        form.setFieldValue('bulkUpdateBooleanPropertyForm.property', Task.API_PROPERTY.isCompleted);
        form.setFieldValue('bulkUpdateBooleanPropertyForm.booleanValue', true);
        bulkCompleteTasksModal.handleOpen();
      },
    },
    {
      text: 'Mark incomplete',
      isDisabled: !hasSelectedTasks,
      onPress: () => {
        form.setFieldValue('bulkUpdateBooleanPropertyForm.ids', taskIds);
        form.setFieldValue('bulkUpdateBooleanPropertyForm.property', Task.API_PROPERTY.isCompleted);
        form.setFieldValue('bulkUpdateBooleanPropertyForm.booleanValue', false);
        bulkIncompleteTasksModal.handleOpen();
      },
    },
  ];
};

const ArchiveTasksModal = ({isOpen, handleClose, handleSubmit}) => {
  return (
    <ConfirmationModal
      icon={Icon.BoxOpen}
      title={'Archive tasks?'}
      subtitle={'Are you sure you want to archive all the selected tasks?'}
      primaryActionText={'Archive tasks'}
      secondaryActionText={'Nevermind'}
      isOpen={isOpen}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
    />
  );
};

const UnarchiveTasksModal = ({isOpen, handleClose, handleSubmit}) => {
  return (
    <ConfirmationModal
      icon={Icon.BoxOpen}
      title={'Unarchive tasks?'}
      subtitle={'Are you sure you want to unarchive all the selected tasks?'}
      primaryActionText={'Unarchive tasks'}
      secondaryActionText={'Nevermind'}
      isOpen={isOpen}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
    />
  );
};

const CompleteTasksModal = ({isOpen, handleClose, handleSubmit}) => {
  return (
    <ConfirmationModal
      icon={Icon.Check}
      title={'Complete tasks?'}
      subtitle={'Are you sure you want to mark all the selected tasks as completed?'}
      primaryActionText={'Complete tasks'}
      secondaryActionText={'Nevermind'}
      isOpen={isOpen}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
    />
  );
};

const IncompleteTasksModal = ({isOpen, handleClose, handleSubmit}) => {
  return (
    <ConfirmationModal
      icon={Icon.Check}
      title={'Mark incomplete?'}
      subtitle={'Are you sure you want to mark all the selected tasks as incomplete?'}
      primaryActionText={'Mark incomplete'}
      secondaryActionText={'Nevermind'}
      isOpen={isOpen}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
    />
  );
};

const BulkUpdateTasksActions = ({taskIds, onUpdate}) => {
  const bulkArchiveTasksModal = useModal({name: 'Bulk Archive Tasks Modal'});
  const bulkUnarchiveTasksModal = useModal({name: 'Bulk Unarchive Tasks Modal'});
  const bulkCompleteTasksModal = useModal({name: 'Bulk Complete Tasks Modal'});
  const bulkIncompleteTasksModal = useModal({name: 'Bulk Incomplete Tasks Modal'});
  const bulkUpdateBooleanPropertyForm = BulkUpdateBooleanPropertyForm.new({
    ids: [],
    booleanValue: true,
  });
  const {form, handleSubmit} = useBulkUpdateTasksMutation({
    bulkUpdateBooleanPropertyForm,
    onSuccess: () => {
      bulkArchiveTasksModal.handleClose();
      bulkUnarchiveTasksModal.handleClose();
      onUpdate();
    },
    onError: (error) => console.log({error}),
  });

  return (
    <React.Fragment>
      <DropdownButton
        text={'Actions'}
        menuWidth={200}
        menuPosition={DropdownButton.MENU_POSITION.RIGHT}
        actions={getActions({
          form,
          taskIds,
          bulkArchiveTasksModal,
          bulkUnarchiveTasksModal,
          bulkCompleteTasksModal,
          bulkIncompleteTasksModal,
        })}
      />
      <ArchiveTasksModal
        key={bulkArchiveTasksModal.key}
        isOpen={bulkArchiveTasksModal.isOpen}
        handleClose={bulkArchiveTasksModal.handleClose}
        handleSubmit={handleSubmit}
      />
      <UnarchiveTasksModal
        key={bulkUnarchiveTasksModal.key}
        isOpen={bulkUnarchiveTasksModal.isOpen}
        handleClose={bulkUnarchiveTasksModal.handleClose}
        handleSubmit={handleSubmit}
      />
      <CompleteTasksModal
        key={bulkCompleteTasksModal.key}
        isOpen={bulkCompleteTasksModal.isOpen}
        handleClose={bulkCompleteTasksModal.handleClose}
        handleSubmit={handleSubmit}
      />
      <IncompleteTasksModal
        key={bulkIncompleteTasksModal.key}
        isOpen={bulkIncompleteTasksModal.isOpen}
        handleClose={bulkIncompleteTasksModal.handleClose}
        handleSubmit={handleSubmit}
      />
    </React.Fragment>
  );
};

export default BulkUpdateTasksActions;
