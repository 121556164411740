// Libraries

// Supermove
import {gql} from '@supermove/graphql';

// App
import NavigationItems, {NavigationItem} from 'core/NavigationItems';

const getItems = ({viewer}: {viewer: any}): NavigationItem[] => {
  return [
    {
      label: 'Profile',
      value: 'PROFILE',
      valueKey: 'section',
      items: [
        {
          label: 'Personal Information',
          value: '/account/profile/personal-information',
          valueKey: 'route',
        },
        {
          label: 'Notifications',
          value: '/account/profile/notifications',
          valueKey: 'route',
        },
      ],
    },
    ...NavigationItems.includeIf(
      viewer.viewingOrganization.features.isEnabledOfficeAccountEmailSettings,
      {
        label: 'Communication',
        value: 'COMMUNICATION',
        valueKey: 'section',
        items: [
          {
            label: 'Email',
            value: '/account/communication/email',
            valueKey: 'route',
          },
        ],
      },
    ),
  ];
};

// --------------------------------------------------
// Data
// --------------------------------------------------
getItems.fragment = gql`
  fragment UserSettingsNavigation_getItems on User {
    id
    viewingOrganization {
      id
      features {
        isEnabledOfficeAccountEmailSettings: isEnabled(feature: "OFFICE_ACCOUNT_EMAIL_SETTINGS")
      }
    }
  }
`;

const UserSettingsNavigation = {
  getItems,
};

export default UserSettingsNavigation;
