// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, usePopover, useToast} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Toast from '@shared/design/components/Toast';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import JobLocationForm from '@shared/modules/Job/forms/JobLocationForm';
import useDeleteJobLocationMutation from '@shared/modules/Job/hooks/useDeleteJobLocationMutation';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import Line from 'modules/App/components/Line';
import JobActionDisabledTooltip from 'modules/Project/V2/Show/Blocks/Job/components/JobActionDisabledTooltip';
import LocationActionsPopover from 'modules/Project/V2/Show/Blocks/components/LocationActionsPopover';
import LocationsListItem from 'modules/Project/V2/Show/Blocks/components/LocationsListItem';

const Column = Styled.View`
`;

const ActivityIndicator = Styled.Loading`
  height: 12px;
`;

const handleEditLocation = ({navigator, params, job, location}) =>
  Job.goToEditProjectJobs(job, navigator, {
    projectUuid: params.projectUuid,
    block: ProjectBlockKind.Job.STOPS,
    index: location.index,
  });

const SkeletonComponent = () => {
  return (
    <Column>
      <LocationsListItem.SkeletonComponent />
      <Space height={16} />
      <Line />
      <Space height={16} />
      <LocationsListItem.SkeletonComponent />
    </Column>
  );
};

const RemoveJobStopModal = ({isOpen, handleClose, handleDelete}) => {
  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Remove stop?'}
      subtitle={'This will remove the stop from the job.'}
      handleClose={handleClose}
      handleDelete={handleDelete}
      deleteButtonText={'Remove'}
    />
  );
};

const JobLocationActions = ({job, location, refetch}) => {
  const {navigator, params} = useNavigationDOM();
  const locationActionsPopover = usePopover();
  const removeJobStopModal = useModal({name: 'Remove Job Stop Modal', enableTracking: true});
  const deleteSuccessToast = useToast({
    ToastComponent: Toast,
    message: 'Stop removed.',
  });

  const deleteFailToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Unable to remove stop.',
  });

  const jobLocationForm = JobLocationForm.delete({
    jobId: job.id,
    locationId: location.id,
  });

  const deleteJobLocationMutation = useDeleteJobLocationMutation({
    jobLocationForm,
    onSuccess: () => {
      refetch();
      deleteSuccessToast.handleToast();
    },
    onError: (errors) => {
      console.log({errors});
      deleteFailToast.handleToast();
    },
  });

  return (
    <React.Fragment>
      {deleteJobLocationMutation.submitting ? (
        <ActivityIndicator color={colors.gray.tertiary} />
      ) : (
        <LocationActionsPopover
          popover={locationActionsPopover}
          handleEdit={() => handleEditLocation({navigator, params, job, location})}
          handleDelete={removeJobStopModal.handleOpen}
          isStop
          isDeletable={job.locations.length > 1}
          location={location}
        >
          <JobActionDisabledTooltip job={job}>
            <TertiaryButton
              style={{paddingHorizontal: 6}}
              onPress={locationActionsPopover.handleToggle}
              isHitSlop
              isDisabled={job.isFinal}
            >
              <Icon
                source={Icon.EllipsisV}
                size={16}
                color={job.isFinal ? colors.gray.tertiary : colors.gray.secondary}
              />
            </TertiaryButton>
          </JobActionDisabledTooltip>
        </LocationActionsPopover>
      )}
      <RemoveJobStopModal
        isOpen={removeJobStopModal.isOpen}
        handleClose={removeJobStopModal.handleClose}
        handleDelete={() => {
          deleteJobLocationMutation.handleSubmit();
          removeJobStopModal.handleClose();
        }}
      />
    </React.Fragment>
  );
};

const JobLocationsList = ({job, mapRef, locationIdsWithStreetView, refetch}) => {
  return (
    <Column>
      {job.warehouseLocation &&
        job.organization.features.isEnabledCreateStorageMultipleWarehouse && (
          <React.Fragment>
            <LocationsListItem.WarehouseLocationListItem
              location={job.warehouseLocation}
              mapRef={mapRef}
              hasStreetView={locationIdsWithStreetView.includes(job.warehouseLocation.id)}
            />
            <Space height={20} />
            <Line />
            <Space height={16} />
          </React.Fragment>
        )}
      {job.locations.map((location, index) => {
        return (
          <React.Fragment key={location.id}>
            {index > 0 && (
              <React.Fragment>
                <Space height={16} />
                <Line />
                <Space height={16} />
              </React.Fragment>
            )}
            <LocationsListItem
              location={location}
              totalLocations={job.locations.length}
              index={index}
              mapRef={mapRef}
              hasStreetView={locationIdsWithStreetView.includes(location.id)}
              LocationActionsComponent={JobLocationActions}
              locationActionsComponentProps={{
                job,
                location,
                refetch,
              }}
              isStops
            />
          </React.Fragment>
        );
      })}
    </Column>
  );
};

JobLocationsList.SkeletonComponent = SkeletonComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------
JobLocationsList.fragment = gql`
  ${LocationActionsPopover.fragment}
  ${LocationsListItem.fragment}
  ${Job.goToEditProjectJobs.fragment}
  ${JobActionDisabledTooltip.fragment}

  fragment JobLocationsList on Job {
    id
    uuid
    warehouseLocation {
      id
      index
      name
      ...LocationActionsPopover
      ...LocationsListItem
    }
    organization {
      id
      features {
        isEnabledCreateStorageMultipleWarehouse: isEnabled(
          feature: "CREATE_STORAGE_MULTIPLE_WAREHOUSE"
        )
      }
    }
    locations {
      id
      index
      ...LocationActionsPopover
      ...LocationsListItem
    }
    ...Job_goToEditProjectJobs
    ...JobActionDisabledTooltip
  }
`;

export default JobLocationsList;
