const CATEGORIES = 'CATEGORIES';
const ROOMS = 'ROOMS';
const CARTONS = 'CARTONS';
const ITEMS = 'ITEMS';
const ITEM_TAGS = 'ITEM_TAGS';
const EXCEPTION_LOCATIONS = 'EXCEPTION_LOCATIONS';
const EXCEPTION_TYPES = 'EXCEPTION_TYPES';

const InventoryLibraryObjectKind = {
  CATEGORIES,
  ROOMS,
  CARTONS,
  ITEMS,
  ITEM_TAGS,
  EXCEPTION_LOCATIONS,
  EXCEPTION_TYPES,
};

export default InventoryLibraryObjectKind;
