// Libararies
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import Sheet from '@shared/design/components/Sheet';
import Line from 'modules/App/components/Line';

const SheetMenuItemContainer = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-vertical: 12px;
`;

const MobileLabelText = Styled.Text`
  ${Typography.Mobile.Label};
`;

const MobileBodyText = Styled.Text`
  ${Typography.Mobile.Body};
`;

const SectionDivider = () => {
  return (
    <React.Fragment>
      <Space height={16} />
      <Line color={colors.gray.border} />
      <Space height={16} />
    </React.Fragment>
  );
};

const SheetMenuItem = ({label, onPress, handleClose}) => {
  return (
    <SheetMenuItemContainer
      activeOpacity={0.8}
      onPress={() => {
        onPress();
        handleClose();
      }}
    >
      <MobileBodyText>{label}</MobileBodyText>
      <Icon source={Icon.AngleRight} size={16} color={colors.gray.secondary} />
    </SheetMenuItemContainer>
  );
};

const ProjectActionsSheet = ({isOpen, handleClose, projectActionSections}) => {
  return (
    <Sheet.PreventPropagationContainer style={{position: 'absolute'}}>
      <Sheet headerText={`Actions`} isOpen={isOpen} handleClose={handleClose}>
        <ScrollView
          contentContainerStyle={{
            paddingHorizontal: 16,
            paddingBottom: 16,
          }}
        >
          {projectActionSections.map(({label, actions}, index) => (
            <React.Fragment key={index}>
              {index > 0 && <SectionDivider />}
              <MobileLabelText>{label}</MobileLabelText>
              <Space height={16} />
              {actions.map(({label, onPress, handleClose}) => (
                <SheetMenuItem
                  key={label}
                  label={label}
                  onPress={onPress}
                  handleClose={handleClose}
                />
              ))}
            </React.Fragment>
          ))}
        </ScrollView>
      </Sheet>
    </Sheet.PreventPropagationContainer>
  );
};

export default ProjectActionsSheet;
