// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useToggle} from '@supermove/hooks';

// App
import EditPanel from '@shared/design/components/Panel/EditPanel';
import JobTypeFeatureForm from '@shared/modules/Job/forms/JobTypeFeatureForm';
import useUpsertJobTypeFeatureMutation from '@shared/modules/Job/hooks/useUpsertJobTypeFeatureMutation';
import JobTypeGeneralSettingsTimesheetsPanel from 'modules/Organization/Settings/JobTypes/components/JobTypeGeneralSettingsTimesheetsPanel';

const JobTypeTimesheetCategorySection = ({jobTypeFeature, refetch}) => {
  const editTimesheetsSettingsToggle = useToggle();
  const jobTypeFeatureForm = JobTypeFeatureForm.edit(jobTypeFeature);

  const jobFeatureIsEnabledField = 'jobTypeFeatureForm.isEnabled';
  const jobFeatureIdField = 'jobTypeFeatureForm.jobTypeFeatureId';

  const {form, submitting, handleSubmit} = useUpsertJobTypeFeatureMutation({
    jobTypeFeatureForm,
    onSuccess: ({jobTypeFeature}) => {
      form.setFieldValue(jobFeatureIdField, jobTypeFeature.id);
      form.setFieldValue(jobFeatureIsEnabledField, jobTypeFeature.isEnabled);
      refetch();
    },
    onError: () => form.setFieldValue(jobFeatureIsEnabledField, !form.values.isEnabled),
  });

  return (
    <EditPanel
      bodyComponentProps={{jobTypeFeature}}
      editBodyComponentProps={{form, jobTypeFeature}}
      BodyComponent={JobTypeGeneralSettingsTimesheetsPanel}
      EditBodyComponent={JobTypeGeneralSettingsTimesheetsPanel.Edit}
      width={1150}
      title={'Timesheets'}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editTimesheetsSettingsToggle.isOn}
      handleEdit={editTimesheetsSettingsToggle.handleToggleOn}
      handleClose={editTimesheetsSettingsToggle.handleToggleOff}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTypeTimesheetCategorySection.fragment = gql`
  ${JobTypeFeatureForm.edit.fragment}
  ${JobTypeGeneralSettingsTimesheetsPanel.fragment}

  fragment JobTypeTimesheetCategorySection on JobTypeFeature {
    id
    name
    description
    ...JobTypeFeatureForm_edit
    ...JobTypeGeneralSettingsTimesheetsPanel
  }
`;

export default JobTypeTimesheetCategorySection;
