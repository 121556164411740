// Libararies
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import CollectionFormV2 from '@shared/modules/Storage/forms/CollectionFormV2';
import useCreateCollectionMutation from '@shared/modules/Storage/hooks/useCreateCollectionMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CollectionModalFields from 'modules/Storage/components/CollectionModalFields';

const CreateCollectionModalContent = ({handleClose, refetch, project, containers}) => {
  const collectionForm = CollectionFormV2.new(project.id);
  const field = 'collectionForm';
  const {form, handleSubmit, submitting} = useCreateCollectionMutation({
    collectionForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.HeaderText>{'Create Collection'}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body style={{zIndex: 100}}>
        <CollectionModalFields form={form} field={field} containers={containers} />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button onPress={handleClose} isDisabled={submitting}>
          {'Cancel'}
        </Modal.Button>
        <Space width={8} />
        <Modal.Button
          onPress={handleSubmit}
          isSubmitting={submitting}
          color={colors.blue.interactive}
        >
          {'Save'}
        </Modal.Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

const CreateCollectionModal = ({isOpen, handleClose, refetch, project}) => {
  const {data, loading} = useQuery(CreateCollectionModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        page: 1,
        resultsPerPage: 100,
      },
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <Modal isOpen={isOpen} width={Modal.WIDTH.SMALL}>
      <CreateCollectionModalContent
        handleClose={handleClose}
        refetch={refetch}
        project={project}
        containers={data.paginatedList.containers}
      />
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCollectionModal.query = gql`
  ${CollectionModalFields.fragment}

  query CreateCollectionModal($pagination: PaginationInput!) {
    paginatedList: filteredContainersPaginatedList(pagination: $pagination) {
      containers: results {
        id
        ...CollectionModalFields
      }
    }
  }
`;

CreateCollectionModal.fragment = gql`
  fragment CreateCollectionModal on Project {
    id
  }
`;

export default CreateCollectionModal;
