// Libraries
import React from 'react';

// Supermove
import {MultiDropdownInput as BaseMultiDropdownInput} from '@supermove/components';
import {useResponsive, useSheet} from '@supermove/hooks';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import DropdownSheet from '@shared/design/components/DropdownInput/components/DropdownSheet';

const getSelectedSectionedOptions = ({selections, options}: any) => {
  return selections.map((optionValue: any) =>
    DropdownInput.getSelectedSectionedOption({value: optionValue, options}),
  );
};

const MultiDropdownInput = ({
  // Shared props for both mobile and desktop
  name,

  value,
  onChangeValue,
  setFieldValue,
  onInputChange,
  isClearable,
  isDisabled,
  isLoading,
  showDescriptionInOption,
  options,

  // Mobile sheet only props
  label,

  // Customization props
  style,

  ...props
}: any) => {
  const mobileSheet = useSheet({name: 'Mobile Multi Dropdown Sheet', enableTracking: false});
  const responsive = useResponsive();
  const isSectionedList = options[0]?.options;

  return (
    <React.Fragment>
      <DropdownInput.SheetButtonWrapper
        responsive={responsive}
        mobileSheet={mobileSheet}
        isDisabled={isDisabled}
        isResponsiveSheet
      >
        <BaseMultiDropdownInput
          {...props}
          isMobile={!responsive.desktop}
          name={name}
          value={value}
          onChangeValue={onChangeValue}
          setFieldValue={setFieldValue}
          onInputChange={onInputChange}
          isClearable={isClearable}
          isLoading={isLoading}
          showDescriptionInOption={showDescriptionInOption}
          options={options}
          disabled={isDisabled}
          // Customizations
          selectedOptions={
            isSectionedList ? getSelectedSectionedOptions({selections: value, options}) : null
          }
          fontSize={responsive.desktop ? 14 : 16}
          style={{
            height: responsive.desktop ? 36 : 48,
            ...style,
          }}
          DropdownIndicatorComponent={() => (
            <DropdownInput.DropdownIndicatorComponent responsive={responsive} />
          )}
          optionStyle={isSectionedList ? {paddingLeft: 24} : null}
          menuIsOpen={responsive.desktop ? undefined : false}
          isSearchable={responsive.desktop}
        />
      </DropdownInput.SheetButtonWrapper>
      <DropdownSheet
        key={mobileSheet.key}
        isOpen={mobileSheet.isOpen}
        handleClose={mobileSheet.handleClose}
        headerText={label}
        options={options}
        name={name}
        value={value}
        onChangeValue={onChangeValue}
        setFieldValue={setFieldValue}
        onInputChange={onInputChange}
        // @ts-expect-error TS(2322): Type '{ key: any; isOpen: any; handleClose: any; h... Remove this comment to see the full error message
        isClearable={isClearable}
        isLoading={isLoading}
        showDescriptionInOption={showDescriptionInOption}
        isMultiSelect
        HeaderRightComponent={DropdownSheet.DoneButton}
      />
    </React.Fragment>
  );
};

MultiDropdownInput.DropdownIndicator = BaseMultiDropdownInput.DropdownIndicator;

export default MultiDropdownInput;
