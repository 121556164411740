// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover} from '@supermove/hooks';
import {Payment, Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Table from '@shared/design/components/TableV2Deprecated';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import DeletePaymentModal from 'modules/Bill/components/DeletePaymentModal';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const BalanceDueText = Styled.Text`
  ${Typography.Label}
  color: ${({color}) => color};
`;

const getDeletePaymentTooltip = ({isInvoiceFinalized, isCreditCard}) => {
  if (isInvoiceFinalized) {
    return 'Cannot delete payments once invoice is finalized.';
  }
  if (isCreditCard) {
    return 'Cannot delete credit card payments.';
  }
  return null;
};

const PaymentActionsCell = ({payment, refetch}) => {
  const paymentActionsPopover = usePopover();
  const deletePaymentModal = useModal({name: 'Delete Payment Modal'});
  const isInvoiceFinalized = _.get(payment, 'invoice.status') === InvoiceStatus.FINALIZED;
  const isCreditCard = Payment.isCreditCardMethod({method: payment.method});

  return (
    <Row style={{flex: 1, justifyContent: 'center'}}>
      <ActionMenuPopover
        popover={paymentActionsPopover}
        placement={ActionMenuPopover.Position.LeftStart}
        width={200}
        actions={[
          {
            text: 'Delete payment',
            isDisabled: isInvoiceFinalized || isCreditCard,
            tooltip: getDeletePaymentTooltip({isInvoiceFinalized, isCreditCard}),
            onPress: () => {
              paymentActionsPopover.handleClose();
              deletePaymentModal.handleOpen();
            },
          },
        ]}
      >
        <TertiaryButton onPress={paymentActionsPopover.handleToggle} style={{paddingHorizontal: 8}}>
          <Icon source={Icon.EllipsisV} size={16} color={colors.gray.secondary} />
        </TertiaryButton>
      </ActionMenuPopover>
      <DeletePaymentModal
        isOpen={deletePaymentModal.isOpen}
        handleClose={deletePaymentModal.handleClose}
        payment={payment}
        refetch={refetch}
      />
    </Row>
  );
};

const getColumnDefinitions = ({project, refetch}) => [
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Timestamp</Table.HeaderText>;
    },
    cellContent: ({item: payment}) => {
      return (
        <Table.CellText>
          {Datetime.convertToDisplayDatetime(payment.createdAt, Datetime.DISPLAY_DATETIME)}
        </Table.CellText>
      );
    },
    footerContent: () => null,
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Payment Name</Table.HeaderText>;
    },
    cellContent: ({item: payment}) => {
      return <Table.CellText>{payment.name}</Table.CellText>;
    },
    footerContent: () => null,
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Method</Table.HeaderText>;
    },
    cellContent: ({item: payment}) => {
      return <Table.CellText>{payment.displayMethod}</Table.CellText>;
    },
    footerContent: () => null,
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Description</Table.HeaderText>;
    },
    cellContent: ({item: payment}) => {
      return <Table.CellText>{payment.description}</Table.CellText>;
    },
    footerContent: () => (
      <BalanceDueText color={Project.getActiveJobsBalanceColor(project)}>
        Balance Due
      </BalanceDueText>
    ),
  },
  {
    flex: 1,
    minWidth: 80,
    headerContent: () => {
      return <Table.HeaderText>Amount</Table.HeaderText>;
    },
    cellContent: ({item: payment}) => {
      return <Table.CellText>{Currency.display(payment.totalAmount)}</Table.CellText>;
    },
    footerContent: () => {
      const {isTotalAvailable, minBalance, maxBalance} = project.activeJobsAggregateBill;
      const color = Project.getActiveJobsBalanceColor(project);
      const balance = isTotalAvailable
        ? Currency.formatRange({min: minBalance, max: maxBalance})
        : 'TBD';
      return <BalanceDueText color={color}>{balance}</BalanceDueText>;
    },
  },
  {
    width: 80,
    headerContent: () => {
      return <Table.HeaderText>Actions</Table.HeaderText>;
    },
    cellContent: ({item: payment}) => {
      return <PaymentActionsCell payment={payment} refetch={refetch} />;
    },
    footerContent: () => null,
  },
];

const BillingProjectPaymentsTable = ({project, refetch}) => {
  const {payments} = project.activeJobsAggregateBill;

  return (
    <Table
      itemKey={'id'}
      items={project.activeJobsAggregateBill.payments}
      emptyStateText='No Payments'
      columnDefinitions={getColumnDefinitions({project, refetch})}
      hasFooter={_.some(payments)}
      isDense
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectPaymentsTable.fragment = gql`
  ${DeletePaymentModal.fragment}
  ${Project.getActiveJobsBalanceColor.fragment}

  fragment BillingProjectPaymentsTable on Project {
    id
    activeJobsAggregateBill {
      isTotalAvailable
      minBalance
      maxBalance
      payments {
        id
        createdAt
        name
        method
        displayMethod
        description
        totalAmount
        invoice {
          id
          status
        }
        ...DeletePaymentModal
      }
    }
    ...Project_getActiveJobsBalanceColor
  }
`;

export default BillingProjectPaymentsTable;
