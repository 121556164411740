// Supermove

// Supermove
import {uuid} from '@supermove/utils';

export interface RemoveTagAssignmentActionFormType {
  workflowRunStepUuid: string;
  tagIds: string[] | null;
  projectId: string | null;
  jobId: string | null;
}

const _new = (): RemoveTagAssignmentActionFormType => ({
  workflowRunStepUuid: uuid(),
  tagIds: [],
  projectId: null,
  jobId: null,
});

const edit = (removeTagAssignmentActionForm: any): RemoveTagAssignmentActionFormType => ({
  workflowRunStepUuid: removeTagAssignmentActionForm.workflowRunStepUuid,
  tagIds: removeTagAssignmentActionForm.tagIds,
  projectId: removeTagAssignmentActionForm.projectId,
  jobId: removeTagAssignmentActionForm.jobId,
});

const toForm = (
  removeTagAssignmentActionForm: RemoveTagAssignmentActionFormType,
): RemoveTagAssignmentActionFormType => ({
  workflowRunStepUuid: removeTagAssignmentActionForm.workflowRunStepUuid,
  tagIds: removeTagAssignmentActionForm.tagIds,
  projectId: removeTagAssignmentActionForm.projectId,
  jobId: removeTagAssignmentActionForm.jobId,
});

const toMutation = (
  removeTagAssignmentActionForm: RemoveTagAssignmentActionFormType,
): RemoveTagAssignmentActionFormType => ({
  workflowRunStepUuid: removeTagAssignmentActionForm.workflowRunStepUuid,
  tagIds: removeTagAssignmentActionForm.tagIds,
  projectId: removeTagAssignmentActionForm.projectId,
  jobId: removeTagAssignmentActionForm.jobId,
});

const RemoveTagAssignmentActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default RemoveTagAssignmentActionForm;
