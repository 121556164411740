// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useToast} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PaymentMethodForm, {
  PaymentMethodFormToFormType,
} from '@shared/modules/PaymentMethod/forms/PaymentMethodForm';
import useCreatePaymentMethodMutation from '@shared/modules/PaymentMethod/hooks/useCreatePaymentMethodMutation';

import PaymentMethodFields from './PaymentMethodFields';

const CreatePaymentMethodDrawer = ({
  isOpen,
  handleClose,
  organization,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  organization: OrganizationModel;
  refetch: () => void;
}) => {
  const successToast = useToast({ToastComponent: SuccessToast, message: 'Payment method created.'});
  const field = 'paymentMethodForm';
  const paymentMethodForm = PaymentMethodForm.new({organization});
  const {form, handleSubmit, submitting} = useCreatePaymentMethodMutation({
    paymentMethodForm,
    onSuccess: ({paymentMethod}) => {
      successToast.handleToast({message: `'${paymentMethod.name}' created.`});
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Create Payment Method'}
      primaryActionText={'Save'}
      primaryActionIcon={Icon.Check}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      shouldCloseOnClickOutside={false}
    >
      <PaymentMethodFields
        form={form as Form<{paymentMethodForm: PaymentMethodFormToFormType}>}
        field={field}
        organization={organization}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreatePaymentMethodDrawer.fragment = gql`
  ${PaymentMethodFields.fragment}
  ${PaymentMethodForm.new.fragment}
  fragment CreatePaymentMethodDrawer on Organization {
    id
    ...PaymentMethodFields
    ...PaymentMethodForm_new
  }
`;

export default CreatePaymentMethodDrawer;
