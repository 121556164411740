// Libararies
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import ShipmentStatus from '@shared/modules/Dispatch/enums/ShipmentStatus';
import ShipmentStatusForm from '@shared/modules/Dispatch/forms/ShipmentStatusForm';
import useUpdateShipmentStatusMutation from '@shared/modules/Dispatch/hooks/useUpdateShipmentStatusMutation';
import StatusDropdown from 'modules/Dispatch/LongDistance/components/StatusDropdown';

const EditShipmentStatusDropdown = ({shipment, onSuccess}) => {
  const shipmentStatusForm = ShipmentStatusForm.edit(shipment);
  const {form, handleSubmit} = useUpdateShipmentStatusMutation({
    shipmentStatusForm,
    onSuccess: ({shipment}) => {
      onSuccess(shipment);
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <StatusDropdown
      options={ShipmentStatus.OPTIONS}
      initialValue={form.values.shipmentStatusForm.status}
      onSelect={(option) => {
        form.setFieldValue('shipmentStatusForm.status', option.value);
        setTimeout(() => handleSubmit(), 0);
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditShipmentStatusDropdown.fragment = gql`
  ${ShipmentStatusForm.edit.fragment}

  fragment EditShipmentStatusDropdown on Shipment {
    id
    ...ShipmentStatusForm_edit
  }
`;

// --------------------------------------------------
// Props
// --------------------------------------------------
EditShipmentStatusDropdown.propTypes = {
  shipment: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

EditShipmentStatusDropdown.defaultProps = {
  onSuccess: () => {},
};

export default EditShipmentStatusDropdown;
