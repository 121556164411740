// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SlotTypeForm from '@shared/modules/Dispatch/forms/SlotTypeForm';
import useCreateSlotTypeMutation from '@shared/modules/Dispatch/hooks/useCreateSlotTypeMutation';
import {PageLoadingIndicator} from 'modules/App/components';

const Indicator = () => {
  return (
    <React.Fragment>
      <Space height={48} />
      <PageLoadingIndicator />
      <Space height={48} />
    </React.Fragment>
  );
};

const CreateSlotTypeModalContent = ({organization, handleClose, refetch}) => {
  const numSlotTypes = organization.slotTypes.length;
  const slotTypeForm = SlotTypeForm.new({
    organizationId: organization.id,
    index: numSlotTypes + 1,
  });
  const {form, submitting, handleSubmit} = useCreateSlotTypeMutation({
    slotTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  const allTruckOptions = [...organization.activeTrucks, ...organization.overflowTrucks].map(
    ({id, name}) => {
      return {label: name, value: id};
    },
  );
  const activeDriverOptions = organization.activeDrivers.map(({id, fullName}) => {
    return {label: fullName, value: id};
  });
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        name={'displayIndex'}
        label={'Slot Name'}
        input={{
          disabled: true,
          placeholder: `Slot ${numSlotTypes + 1}`,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        component={DropdownInput}
        name={'slotTypeForm.truckId'}
        label={'Truck'}
        input={{
          autoFocus: true,
          isSearchable: true,
          placeholder: 'Assign default truck for this slot',
          options: allTruckOptions,
          setFieldValue: form.setFieldValue,
          style: {
            flex: 1,
          },
        }}
        style={{flex: 1}}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={2}
        component={DropdownInput}
        name={'slotTypeForm.driverId'}
        label={'Driver'}
        input={{
          isSearchable: true,
          placeholder: 'Assign default driver for this slot',
          options: activeDriverOptions,
          setFieldValue: form.setFieldValue,
          style: {
            flex: 1,
          },
        }}
        style={{flex: 1}}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={4} />
        <SmallModal.Button
          color={colors.blue.interactive}
          isSubmitting={submitting}
          onPress={handleSubmit}
        >
          Add
        </SmallModal.Button>
      </SmallModal.Footer>
    </React.Fragment>
  );
};

const CreateSlotTypeModal = ({isOpen, handleClose, refetch}) => {
  const {loading, data} = useQuery(CreateSlotTypeModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
  });

  return (
    <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
      <React.Fragment>
        <SmallModal.BoldText>Add Slot</SmallModal.BoldText>
        <Space height={16} />
        <SmallModal.Text>
          Add slot to the dispatch calendar and assign a default truck or driver.
        </SmallModal.Text>
        <Space height={16} />
        <Loading loading={loading} as={Indicator}>
          {() => (
            <CreateSlotTypeModalContent
              organization={data.viewer.viewingOrganization}
              handleClose={handleClose}
              refetch={refetch}
            />
          )}
        </Loading>
      </React.Fragment>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateSlotTypeModal.query = gql`
  query CreateSlotTypeModal {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        slotTypes {
          id
        }
        activeTrucks {
          id
          name
        }
        overflowTrucks {
          id
          name
        }
        activeDrivers {
          id
          fullName
        }
      }
    }
  }
`;

export default CreateSlotTypeModal;
