// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {RadioInput, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Text = Styled.Text`
  ${Typography.Body2};
`;

const Content = Styled.View`
  align-items: flex-start;
`;

const QuestionSection = Styled.View`
  flex-direction: row;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isRequired' does not exist on type 'Them... Remove this comment to see the full error message
    isRequired,
  }) => (isRequired ? colors.alpha(colors.yellow.hover, 0.1) : colors.white)}
`;

const RadioButtonOptions = ({options, radioValue, getOnSelect}: any) => {
  return (
    <Content>
      {options.map((option: any, optionIndex: any) => (
        <React.Fragment key={optionIndex}>
          <Space width={10} />
          {/* @ts-expect-error TS(2741): Property 'value' is missing in type '{ isSelected:... Remove this comment to see the full error message */}
          <RadioInput
            isSelected={option === radioValue}
            color={colors.blue.interactive}
            label={option}
            onSelect={() => {
              getOnSelect(option);
            }}
          />
        </React.Fragment>
      ))}
    </Content>
  );
};

const RadioButtonEdit = ({item, index, form, field, isRequired}: any) => {
  const valueField = `${field}.input.value.values.${index}`;
  const labelValueField = `${valueField}.0`;
  const selectionValueField = `${valueField}.1`;
  const informationValueField = `${valueField}.2`;

  const labelValue = _.get(form.values, labelValueField);
  const selectionValue = _.get(form.values, selectionValueField);
  const textInputValue = _.get(form.values, informationValueField);

  const {options, inputs} = item;
  const shouldShowTextInput = !!selectionValue && !!_.get(inputs, _.camelCase(selectionValue));

  const selectionRequiredError = _.get(form.errors, labelValueField);

  return (
    <Container>
      {index > 0 && <Space height={4} />}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <QuestionSection isRequired={isRequired && !selectionValue}>
        <Container style={{flex: 1, paddingTop: 4}}>
          <Text>{labelValue}</Text>
        </Container>
      </QuestionSection>
      <Space height={4} />
      <RadioButtonOptions
        options={options}
        radioValue={selectionValue}
        getOnSelect={(option: any) => {
          form.setFieldValue(selectionValueField, option);
          form.setFieldValue(informationValueField, '');
        }}
      />
      {selectionRequiredError && (
        <React.Fragment>
          <Space height={2} />
          <Row style={{justifyContent: 'flex-end'}}>
            <FieldInput.CaptionText hasErrors size={FieldInput.SIZE.MEDIUM}>
              {selectionRequiredError}
            </FieldInput.CaptionText>
          </Row>
        </React.Fragment>
      )}
      {shouldShowTextInput && (
        <React.Fragment>
          <Space height={2} />
          <FieldInput.Memoized
            {...form}
            name={informationValueField}
            size={FieldInput.SIZE.MEDIUM}
            input={{
              required: !textInputValue,
              placeholder: 'Enter additional information',
            }}
          />
          <Space height={8} />
        </React.Fragment>
      )}
    </Container>
  );
};

const RadioButtonView = ({item, index, input}: any) => {
  const [label, radioValue, textValue] = input.value.values[index];
  const {options, inputs} = item;
  const shouldShowTextInput = !!radioValue && !!_.get(inputs, _.camelCase(radioValue));
  return (
    <Container>
      <Row>
        <Container style={{flex: 1, paddingTop: 4}}>
          <Text>{label}</Text>
        </Container>
      </Row>
      <Space height={4} />
      <RadioButtonOptions options={options} radioValue={radioValue} getOnSelect={() => {}} />
      {shouldShowTextInput && (
        <React.Fragment>
          <Space height={2} />
          <FieldInput
            input={{
              value: textValue,
              disabled: true,
              style: {backgroundColor: colors.white},
            }}
          />
          <Space height={8} />
        </React.Fragment>
      )}
    </Container>
  );
};

const DocumentV2RadioButtons = ({input, isRequired, isEditable, form, field}: any) => {
  return (
    <React.Fragment>
      <Container>
        {input.metadata.items.map((item: any, index: any) => {
          return (
            <React.Fragment key={index}>
              {isEditable ? (
                <RadioButtonEdit
                  item={item}
                  index={index}
                  form={form}
                  field={field}
                  isRequired={isRequired}
                />
              ) : (
                <RadioButtonView item={item} index={index} input={input} />
              )}
            </React.Fragment>
          );
        })}
      </Container>
    </React.Fragment>
  );
};

export default DocumentV2RadioButtons;
