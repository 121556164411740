// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, DropdownInput, Emoji, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import DocumentTemplatePreview from 'modules/Job/V2/Move/components/DocumentTemplatePreview';

const Container = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Content = Styled.View`
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-horizontal: 16px;
  background-color: ${(props) => (props.isHovered ? colors.hover : colors.white)};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.isError ? colors.red.warning : colors.gray.border)};
  border-radius: 4px;
`;

const Left = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Right = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Title = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;
`;

const EmojiText = Styled.H4`
  width: 24px;
  padding-vertical: 4px;
`;

const Values = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
`;

const ValueSpace = Styled.View`
  width: 10px;
`;

const Name = Styled.Text`
  ${Typography.Body}
  margin-left: 8px;
`;

const Amount = Styled.Text`
  ${Typography.Body}
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const TextInput = Styled.TextInput.H7`
  width: 100px;
`;

const Touchable = Styled.Touchable`
  padding-vertical: 5px;
`;

const getStepsAfterChangeValue = ({kind, steps, value}) => {
  return steps.map((step) => {
    if (step.kind === kind) {
      return {...step, value};
    } else {
      return step;
    }
  });
};

const getStepsAfterChangeValueTwo = ({kind, steps, valueTwo}) => {
  return steps.map((step) => {
    if (step.kind === kind) {
      return {...step, valueTwo};
    } else {
      return step;
    }
  });
};

const getStepsAfterRemoveStep = ({steps, stepIndex}) => {
  return steps.filter((step, index) => index !== stepIndex);
};

// TODO(dan) Can remove this hack once fully migrated to EmailTemplateV2
const getFieldName = (name, field) => {
  return field ? `${field}.${name}` : name;
};

const handleRemoveStep = ({form, field, stepIndex}) => {
  const stepsField = getFieldName('steps', field);
  const steps = _.get(form.values, stepsField);
  form.setFieldValue(stepsField, getStepsAfterRemoveStep({steps, stepIndex}));
};

const Value = ({isEditable, kind, form, field}) => {
  const stepsField = getFieldName('steps', field);
  const steps = _.get(form.values, stepsField);
  const step = steps.find((step) => step.kind === kind);

  return isEditable ? (
    <FieldInput
      {...form}
      component={CurrencyInput}
      input={{
        shouldHideCentsIfZero: true,
        component: TextInput,
        placeholder: 'Fee ($)',
        setFieldValue: (name, value) => {
          form.setFieldValue(stepsField, getStepsAfterChangeValue({kind, steps, value}));
        },
        setFieldTouched: form.setFieldTouched,
        style: {
          width: 70,
        },
      }}
      name={'value'}
      values={{
        value: step.value,
      }}
    />
  ) : (
    <Amount>{step.value}</Amount>
  );
};

const ValueTwo = ({isEditable, kind, form, field}) => {
  const stepsField = getFieldName('steps', field);
  const steps = _.get(form.values, stepsField);
  const step = steps.find((step) => step.kind === kind);

  return isEditable ? (
    <FieldInput
      {...form}
      input={{
        placeholder: 'Window (days)',
        onChangeText: (text) => {
          form.setFieldValue(
            stepsField,
            getStepsAfterChangeValueTwo({kind, steps, valueTwo: text}),
          );
        },
        style: {
          width: 125,
          marginTop: 0,
        },
      }}
      name={'valueTwo'}
      values={{
        valueTwo: step.valueTwo,
      }}
    />
  ) : (
    <Amount>{step.valueTwo}</Amount>
  );
};

const getDocumentTemplateByIdentifier = ({documentTemplates, identifier}) => {
  return _.find(
    documentTemplates,
    (documentTemplate) => documentTemplate.identifier === identifier,
  );
};

const DocumentValue = ({
  isEditable,
  form,
  field,
  documentTemplates,
  documentTemplate,
  stepIndex,
  project,
}) => {
  const confirmationStepDocumentTemplatePreviewDrawer = useDrawer({
    name: 'Confirmation Step Document Template Preview Drawer',
  });
  const stepsField = getFieldName('steps', field);
  const steps = _.get(form.values, stepsField);
  const step = steps[stepIndex];

  if (isEditable) {
    return (
      <FieldInput
        {...form}
        index={stepIndex}
        component={DropdownInput}
        input={{
          isPortaled: true,
          options: documentTemplates.map((documentTemplate) => ({
            label: documentTemplate.name,
            value: documentTemplate.identifier,
          })),
          setFieldValue: (name, value) => {
            const updatedSteps = steps.map((step, index) =>
              index === stepIndex ? {...step, value} : step,
            );
            form.setFieldValue(stepsField, updatedSteps);
          },
          setFieldTouched: form.setFieldTouched,
          style: {
            width: 205,
          },
        }}
        name={'value'}
        values={{
          value: step.value,
        }}
      />
    );
  } else {
    return (
      <React.Fragment>
        {documentTemplate && (
          <React.Fragment>
            <Touchable onPress={confirmationStepDocumentTemplatePreviewDrawer.handleOpen}>
              <LinkText>{documentTemplate.name}</LinkText>
            </Touchable>
            <DrawerWithClose
              key={confirmationStepDocumentTemplatePreviewDrawer.key}
              isOpen={confirmationStepDocumentTemplatePreviewDrawer.isOpen}
              handleClose={confirmationStepDocumentTemplatePreviewDrawer.handleClose}
              headerText={`Preview: ${documentTemplate.name}`}
              width={900}
              bodyProps={{bodyScrollStyle: {flex: 1}}}
            >
              <DocumentTemplatePreview
                documentTemplateUuid={documentTemplate.uuid}
                // this is how confirmation.job is set on the backend
                // confirmation.job won't exist before the confirmation is sent
                jobUuid={project.activeMoveJobs[0]?.uuid ?? project.activeJobs[0]?.uuid}
              />
            </DrawerWithClose>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
};

const Action = ({form, field, isAddable, isEditable, stepIndex}) => {
  if (isAddable) {
    return <Icon color={colors.blue.interactive} size={Icon.Sizes.Large} source={Icon.Plus} />;
  }

  if (isEditable) {
    return (
      <Touchable
        onPress={() => handleRemoveStep({form, field, stepIndex})}
        style={{marginLeft: 16}}
      >
        <Icon color={colors.red.warning} size={Icon.Sizes.Medium} source={Icon.XmarkLarge} />
      </Touchable>
    );
  }

  return null;
};

const ProjectConfirmationStepContent = ({
  isAddable,
  isEditable,
  isHovered,
  step,
  stepIndex,
  stepError,
  project,
  form,
  field,
}) => {
  switch (step.kind) {
    case ConfirmationStepKind.CONFIRMATION_STEP_MAKE_DEPOSIT:
      return (
        <Content isHovered={isHovered} isError={stepError}>
          <Left>
            <Title>
              <Emoji component={EmojiText} name={'dollar'} />
              <Name numberOfLines={1}>Deposit</Name>
            </Title>
            {!isAddable && (
              <Values>
                <Value isEditable={isEditable} kind={step.kind} form={form} field={field} />
              </Values>
            )}
          </Left>
          <Right>
            <Action
              form={form}
              field={field}
              isAddable={isAddable}
              isEditable={isEditable}
              stepIndex={stepIndex}
            />
          </Right>
        </Content>
      );
    case ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD:
      return (
        <Content isHovered={isHovered} isError={stepError}>
          <Left>
            <Title>
              <Emoji component={EmojiText} name={'credit_card'} />
              <Name numberOfLines={1}>Cancellation Policy</Name>
            </Title>
            {!isAddable && (
              <Values>
                <Value isEditable={isEditable} kind={step.kind} form={form} field={field} />
                <ValueSpace />
                <ValueTwo isEditable={isEditable} kind={step.kind} form={form} field={field} />
              </Values>
            )}
          </Left>
          <Right>
            <Action
              form={form}
              field={field}
              isAddable={isAddable}
              isEditable={isEditable}
              stepIndex={stepIndex}
            />
          </Right>
        </Content>
      );
    case ConfirmationStepKind.CONFIRMATION_STEP_DOCUMENT:
    case ConfirmationStepKind.QUOTE_STEP_DOCUMENT: {
      const documentTemplates = _.orderBy(
        _.filter(
          project.organization.jobDocumentTemplates,
          (documentTemplate) => documentTemplate.activeOrMostRecentDocumentTemplateVersion,
        ),
        [(documentTemplate) => documentTemplate.name.toLowerCase()],
        ['asc'],
      );
      const documentTemplate = getDocumentTemplateByIdentifier({
        documentTemplates,
        identifier: step.value,
      });
      return (
        <Content isHovered={isHovered} isError={stepError}>
          <Title>
            <Emoji component={EmojiText} name={'pencil'} />
            <Name numberOfLines={1}>Document</Name>
          </Title>
          <Values>
            {isAddable ? (
              <React.Fragment>
                {documentTemplate && (
                  <Amount style={{paddingHorizontal: 16}}>{documentTemplate.name}</Amount>
                )}
              </React.Fragment>
            ) : (
              <DocumentValue
                isEditable={isEditable}
                form={form}
                field={field}
                documentTemplates={documentTemplates}
                documentTemplate={documentTemplate}
                stepIndex={stepIndex}
                project={project}
              />
            )}
          </Values>
          <Right>
            <Action
              form={form}
              field={field}
              isAddable={isAddable}
              isEditable={isEditable}
              stepIndex={stepIndex}
            />
          </Right>
        </Content>
      );
    }
    default:
      return null;
  }
};

const getSwappedSteps = ({steps, fromIndex, toIndex}) => {
  [steps[fromIndex], steps[toIndex]] = [steps[toIndex], steps[fromIndex]];
  return steps;
};

const handleMoveStepUp = ({index, form, field}) => {
  const stepsField = getFieldName('steps', field);
  const steps = _.get(form.values, stepsField);
  form.setFieldValue(stepsField, getSwappedSteps({steps, fromIndex: index, toIndex: index - 1}));
};

const handleMoveStepDown = ({index, form, field}) => {
  const stepsField = getFieldName('steps', field);
  const steps = _.get(form.values, stepsField);
  form.setFieldValue(stepsField, getSwappedSteps({steps, fromIndex: index, toIndex: index + 1}));
};

const Actions = Styled.View`
  justify-content: center;
`;

const IconCircle = Styled.Touchable`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? colors.gray.border : colors.blue.interactive)};
  justify-content: center;
  align-items: center;
`;

const ProjectConfirmationStep = ({
  showActions,
  isFirst,
  isLast,
  isAddable,
  isEditable,
  isHovered,
  index,
  step,
  project,
  form,
  field,
  style,
}) => {
  const stepError = _.get(form, `errors.${field}.steps.${index}`);
  return (
    <Container style={style} index={index}>
      <Row>
        <ProjectConfirmationStepContent
          isAddable={isAddable}
          isEditable={isEditable}
          isHovered={isHovered}
          step={step}
          stepIndex={index}
          project={project}
          form={form}
          field={field}
          stepError={stepError}
        />
        {showActions && (
          <React.Fragment>
            <Space width={8} />
            <Actions>
              <IconCircle disabled={isFirst} onPress={() => handleMoveStepUp({index, form, field})}>
                <Icon source={Icon.ArrowUp} size={10} color={colors.white} />
              </IconCircle>
              <Space height={8} />
              <IconCircle
                disabled={isLast}
                onPress={() => handleMoveStepDown({index, form, field})}
              >
                <Icon source={Icon.ArrowDown} size={10} color={colors.white} />
              </IconCircle>
            </Actions>
          </React.Fragment>
        )}
      </Row>
      {stepError && (
        <React.Fragment>
          <Space height={8} />
          <Name style={{color: colors.red.warning}}>{stepError}</Name>
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectConfirmationStep.fragment = gql`
  fragment ProjectConfirmationStep on Project {
    id
    activeJobs {
      id
      uuid
    }
    activeMoveJobs {
      id
      uuid
    }
    organization {
      id
      jobDocumentTemplates: documentTemplatesByCategory(categories: ["JOB"]) {
        id
        name
        identifier
        uuid
        activeOrMostRecentDocumentTemplateVersion {
          id
        }
      }
    }
  }
`;

export default ProjectConfirmationStep;
