// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import InventoryForm from '@shared/modules/Inventory/forms/InventoryForm';

const useSyncInventoryBillMutation = ({inventoryForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      inventoryForm: InventoryForm.toForm(inventoryForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSyncInventoryBillMutation.mutation,
    variables: {
      inventoryForm: InventoryForm.toMutation(form.values.inventoryForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSyncInventoryBillMutation.mutation = gql`
  mutation useSyncInventoryBillMutation($inventoryForm: InventoryForm!) {
    response: syncInventoryBill(inventoryForm: $inventoryForm) {
      ${gql.errors}
      inventory {
        id
      }
    }
  }
`;

export default useSyncInventoryBillMutation;
