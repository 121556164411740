// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, Popover} from '@supermove/components';
import {usePopover, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import WorkflowBuilderSlideSet from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilderSlideSet';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Column = Styled.View`
  flex-direction: column;
  flex: 1;
`;

const BackButtonContainer = Styled.View`
  width: 32px;
`;

const StepNumber = Styled.Text`
  ${Typography.Subheading};
  color: ${colors.blue.accentDark};
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const handlePressBack = ({form, field}) => {
  const slideIndex = _.get(form.values, `${field}.slideIndex`);
  form.setFieldValue(`${field}.slideIndex`, slideIndex - 1);
};

const handlePressDelete = ({form, workflowStepsField, workflowStepIndex}) => {
  const workflowStepForms = _.get(form.values, workflowStepsField);
  const formsBeforeIndex = _.slice(workflowStepForms, 0, workflowStepIndex);
  const formsAfterIndex = _.slice(
    workflowStepForms,
    workflowStepIndex + 1,
    workflowStepForms.length,
  );
  const updatedForms = [...formsBeforeIndex, ...formsAfterIndex].map((workflowStepForm, index) => ({
    ...workflowStepForm,
    order: index,
  }));
  form.setFieldValue(workflowStepsField, updatedForms);
  form.setFieldValue(`workflowForm.isEditingStep`, false);
};

const handlePressDone = async ({form, field, handleSubmitValidateWorkflow}) => {
  const mutationResponse = await handleSubmitValidateWorkflow();
  if (_.isEmpty(mutationResponse.data.response.errors)) {
    const slideIndex = _.get(form.values, `${field}.slideIndex`);
    form.setFieldValue(`${field}.slideIndex`, slideIndex + 1);
    form.setFieldValue(`workflowForm.isEditingStep`, false);
  }
};

const BackButton = ({form, field}) => {
  return (
    <BackButtonContainer>
      <TertiaryButton style={{paddingRight: 8}} onPress={() => handlePressBack({form, field})}>
        <Icon source={Icon.ChevronLeft} size={16} color={colors.gray.tertiary} />
      </TertiaryButton>
    </BackButtonContainer>
  );
};

const DeleteButton = ({form, workflowStepsField, workflowStepIndex}) => {
  return (
    <TertiaryButton
      onPress={() => handlePressDelete({form, workflowStepsField, workflowStepIndex})}
    >
      <Icon source={Icon.Trash} size={16} color={colors.gray.tertiary} />
    </TertiaryButton>
  );
};

const EditButton = ({form, field}) => {
  const cannotUpdateTwoStepsAtOnceErrorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'You may only edit one step at a time.',
  });
  return (
    <TertiaryButton
      onPress={() => {
        if (_.get(form.values, `workflowForm.isEditingStep`)) {
          return cannotUpdateTwoStepsAtOnceErrorToast.handleToast();
        }
        handlePressBack({form, field});
        form.setFieldValue(`workflowForm.isEditingStep`, true);
      }}
    >
      <Icon source={Icon.Pen} size={16} color={colors.gray.tertiary} />
    </TertiaryButton>
  );
};

const DoneButton = ({form, field, isDisabled, handleSubmitValidateWorkflow}) => {
  return (
    <Button
      text={'Done'}
      isWidthOfContainer
      isDisabled={isDisabled}
      onPress={() => handlePressDone({form, field, handleSubmitValidateWorkflow})}
    />
  );
};

const WorkflowStepDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  description,
  isPreview,
}) => {
  const field = `${workflowStepsField}.${workflowStepIndex}`;
  const workflowStepActionsPopover = usePopover();

  return (
    <WorkflowBuilderSlideSet.Slide style={{paddingVertical: 16}}>
      <Row>
        <WorkflowBuilderSlideSet.Stamp>
          <StepNumber>{workflowStepIndex + 1}</StepNumber>
        </WorkflowBuilderSlideSet.Stamp>
        <Space width={16} />
        <Column>
          <WorkflowBuilderSlideSet.Label>Action</WorkflowBuilderSlideSet.Label>
          <Space height={4} />
          <WorkflowBuilderSlideSet.Subheading>{description}</WorkflowBuilderSlideSet.Subheading>
        </Column>
        <Space width={16} />
        {!isPreview && (
          <React.Fragment>
            <EditButton form={form} field={field} />
            <Space width={8} />
            <Popover.Content innerRef={workflowStepActionsPopover.ref}>
              <MoreActionsButton onPress={workflowStepActionsPopover.handleOpen}>
                <Icon source={Icon.EllipsisV} color={colors.gray.tertiary} size={16} />
              </MoreActionsButton>
            </Popover.Content>
            <ActionMenuPopover
              popover={workflowStepActionsPopover}
              placement={Popover.Positions.BottomStart}
              offset={[0, 4]}
            >
              <ActionMenuPopover.MenuItem
                onPress={() => {
                  workflowStepActionsPopover.handleClose();
                  handlePressDelete({form, workflowStepsField, workflowStepIndex});
                }}
              >
                Delete Step
              </ActionMenuPopover.MenuItem>
            </ActionMenuPopover>
          </React.Fragment>
        )}
      </Row>
    </WorkflowBuilderSlideSet.Slide>
  );
};

const WorkflowStepSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  title,
  subtitle,
  children,
  showDoneButton,
  isDoneDisabled,
  handleSubmitValidateWorkflow,
  isPreview,
}) => {
  const field = `${workflowStepsField}.${workflowStepIndex}`;
  const isFirstSlide = _.get(form.values, `${field}.slideIndex`) === 0;
  return (
    <WorkflowBuilderSlideSet.Slide>
      <Row>
        {!isFirstSlide && <BackButton form={form} field={field} />}
        <WorkflowBuilderSlideSet.Title>{title}</WorkflowBuilderSlideSet.Title>
        <Space style={{flex: 1}} />
        <DeleteButton
          form={form}
          workflowStepsField={workflowStepsField}
          workflowStepIndex={workflowStepIndex}
        />
      </Row>
      {!!subtitle && (
        <React.Fragment>
          <Space height={4} />
          <Row>
            {!isFirstSlide && <BackButtonContainer />}
            <WorkflowBuilderSlideSet.Subtitle>{subtitle}</WorkflowBuilderSlideSet.Subtitle>
          </Row>
        </React.Fragment>
      )}
      <Space height={24} />
      {children}
      {showDoneButton && (
        <React.Fragment>
          <Space height={24} />
          <DoneButton
            form={form}
            field={field}
            isDisabled={isDoneDisabled}
            handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
          />
        </React.Fragment>
      )}
    </WorkflowBuilderSlideSet.Slide>
  );
};

WorkflowStepSlide.Display = WorkflowStepDisplaySlide;

export default WorkflowStepSlide;
