// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {SignatureInput, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Container = Styled.View`
  max-width: 100%;
  width: 350px;
  height: 175px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.gray.border};
`;

const SignatureCapture = ({innerRef, onEnd, onSave, style}) => (
  <Container style={style}>
    <SignatureInput ref={innerRef} color={colors.black} onEnd={onEnd} onSave={onSave} />
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
SignatureCapture.propTypes = {
  innerRef: PropTypes.object.isRequired,
  onEnd: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  style: PropTypes.object,
};

SignatureCapture.defaultProps = {
  style: {},
};

export default SignatureCapture;
