// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ProjectDatesForm from '@shared/modules/Project/forms/ProjectDatesForm';

const useUpdateProjectDatesMutation = ({projectDatesForm, onSuccess, onError}: any) => {
  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      projectDatesForm: ProjectDatesForm.toForm(projectDatesForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectDatesMutation.mutation,
    variables: {
      projectDatesForm: ProjectDatesForm.toMutation(form.values.projectDatesForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectDatesMutation.mutation = gql`
  mutation useUpdateProjectDatesMutation($projectDatesForm: ProjectDatesForm!) {
    response: updateProjectDates(projectDatesForm: $projectDatesForm) {
      ${gql.errors}
      project {
        id
        packJobStartDate
        packJobEndDate
        packJobScheduledDate
        loadJobStartDate
        loadJobEndDate
        loadJobScheduledDate
        deliveryJobStartDate
        deliveryJobEndDate
        deliveryJobScheduledDate
        shipment {
          id
          trip {
            id
            startDate
            endDate
            startLocation {
              id
            }
            endLocation {
              id
            }
          }
        }
      }
    }
  }
`;

export default useUpdateProjectDatesMutation;
