// Libararies
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';

// App
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StoragePageHeader from 'modules/Storage/components/StoragePageHeader';
import StorageShipmentsPageContent from 'modules/Storage/components/StorageShipmentsPageContent';

const Container = Styled.View`
  flex: 1;
`;

const StorageShipmentsPage = () => {
  return (
    <SidebarPageV2 selected={'storage'}>
      <Container>
        <StoragePageHeader />
        <StorageShipmentsPageContent />
      </Container>
    </SidebarPageV2>
  );
};

export default StorageShipmentsPage;
