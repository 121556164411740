// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, MultiDropdownInput, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm, usePopover} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import DocumentProcess from '@shared/modules/Document/enums/DocumentProcess';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const Row = Styled.View`
  flex-direction: row;
`;

const PopoverContainer = Styled.View`
  padding-horizontal: 24px;
`;

const PopoverHeader = Styled.Text`
  ${Typography.Heading2}
`;

const getActiveFilters = ({form, field}) => {
  const filters = _.cloneDeep(_.get(form.values, field));
  return Object.keys(filters).reduce((result, key) => {
    if (filters[key].length > 0) {
      result[key] = filters[key];
    }
    return result;
  }, {});
};

const DocumentFiltersPopover = ({project, appliedFilters, setAppliedFilters, handleClose}) => {
  const field = 'documentFiltersForm';
  const form = useForm({
    initialValues: {
      documentFiltersForm: {
        processes: appliedFilters.processes || [],
        jobIds: appliedFilters.jobIds || [],
      },
    },
  });
  const activeFiltersCount = Object.keys(getActiveFilters({form, field})).length;
  return (
    <ResponsivePopover.StaticContainer width={400} maxHeight={352}>
      <PopoverContainer>
        <Space height={24} />
        <Row style={{justifyContent: 'space-between'}}>
          <PopoverHeader>{`Filters (${activeFiltersCount})`}</PopoverHeader>
          <TertiaryButton
            text={'Clear Filters'}
            iconLeft={Icon.Trash}
            onPress={() => {
              form.setFieldValue('documentFiltersForm', {
                processes: [],
                jobIds: [],
              });
            }}
          />
        </Row>
        <Space height={16} />
        <FieldInput
          {...form}
          component={MultiDropdownInput}
          name={`${field}.processes`}
          label={'Process'}
          index={0}
          input={{
            options: DocumentProcess.getDropdownOptions(),
            placeholder: 'Select process',
            setFieldValue: form.setFieldValue,
            style: {flex: 1},
          }}
        />
        <Space height={16} />
        <FieldInput
          {...form}
          component={MultiDropdownInput}
          name={`${field}.jobIds`}
          label={'Jobs'}
          index={1}
          input={{
            options: _.map(project.activeJobs, (job) => ({
              label: job.fullName,
              value: job.id,
            })),
            placeholder: 'Select process',
            setFieldValue: form.setFieldValue,
            style: {flex: 1},
          }}
        />
        <Space height={16} />
        <Row style={{justifyContent: 'flex-end'}}>
          <QuaternaryButton text={'Cancel'} onPress={handleClose} />
          <Space width={16} />
          <Button
            iconLeft={Icon.Check}
            text={'Apply'}
            onPress={() => {
              setAppliedFilters(getActiveFilters({form, field}));
              handleClose();
            }}
          />
        </Row>
        <Space height={24} />
      </PopoverContainer>
    </ResponsivePopover.StaticContainer>
  );
};

const DocumentFilters = ({project, appliedFiltersCount, appliedFilters, setAppliedFilters}) => {
  const documentFiltersPopover = usePopover({name: 'Document Filters Popover'});
  return (
    <React.Fragment>
      <Popover.Content innerRef={documentFiltersPopover.ref}>
        <SecondaryButton
          text={`(${appliedFiltersCount})`}
          iconLeft={Icon.Filter}
          iconSize={14}
          onPress={documentFiltersPopover.handleToggle}
          isResponsive
        />
      </Popover.Content>
      <Popover
        key={documentFiltersPopover.key}
        placement={Popover.Positions.BottomEnd}
        isOpen={documentFiltersPopover.isOpen}
        handleOpen={documentFiltersPopover.handleOpen}
        handleClose={documentFiltersPopover.handleClose}
        reference={documentFiltersPopover.ref}
        offset={[0, 4]}
      >
        <DocumentFiltersPopover
          project={project}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          handleClose={documentFiltersPopover.handleClose}
        />
      </Popover>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentFilters.fragment = gql`
  fragment DocumentFilters on Project {
    id
    activeJobs {
      id
      identifier
      fullName
    }
  }
`;

export default DocumentFilters;
