// Libraries
import React from 'react';

// App
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// Components
import {Navigation} from 'modules/App/components';
import {
  JobRequestBookedBy,
  JobRequestOrganizationLogo,
  JobRequestPage,
} from 'modules/Customer/JobRequest/components';
import {JobRequestFieldValues} from 'modules/JobRequest/Show/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  align-self: center;
  width: ${(props) => (props.mobile ? '100%' : '570px')};
  padding-horizontal: ${(props) => (props.mobile ? 15 : 30)}px;
  padding-bottom: 240px;
`;

const Header = Styled.View`
  margin-top: 20px;
`;

const Section = Styled.View`
  margin-top: 20px;
`;

const Title = Styled.H4`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H6`
  color: ${colors.gray.primary};
`;

const BookedBy = Styled.View`
  margin-top: 20px;
`;

const Details = Styled.View`
  margin-top: 20px;
`;

const SuccessJobRequestPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <JobRequestPage
    variables={{uuid}}
    query={SuccessJobRequestPage.query}
    title={({loading, data}) => (loading ? 'Loading...' : data.jobRequest.organization.name)}
    subtitle={({loading, data}) => 'Move request submitted'}
    side={({responsive}) => (responsive.mobile ? 80 : 140)}
    right={(loading, data, navigator, responsive) =>
      !loading && data.viewer ? (
        <Navigation.Button
          width={responsive.mobile ? 60 : 120}
          onPress={() => {
            // Open up the app behind the main request page so that closing the modal will open
            // the app up (home page).
            navigator.push('/');
            navigator.push(`/jobs/requests/${data.jobRequest.uuid}`);
          }}
          style={{marginHorizontal: 10}}
        >
          <Navigation.ButtonText>{responsive.mobile ? 'Open' : 'Open App'}</Navigation.ButtonText>
        </Navigation.Button>
      ) : null
    }
  >
    {({responsive, data}) => (
      <Container>
        <ScrollView style={{flex: 1}}>
          <Content {...responsive}>
            <Header>
              <JobRequestOrganizationLogo organization={data.jobRequest.organization} />
              {!!data.jobRequest.bookedBy && (
                <BookedBy>
                  <JobRequestBookedBy user={data.jobRequest.bookedBy} />
                </BookedBy>
              )}
              <Section>
                <Title>{`Thank you, ${data.jobRequest.customer.firstName}!`}</Title>
                <Subtitle>
                  We have received your move request and will contact you shortly to discuss your
                  move. You can review your submission below.
                </Subtitle>
              </Section>
            </Header>
            <Details>
              <JobRequestFieldValues isCustomer jobRequest={data.jobRequest} />
            </Details>
          </Content>
        </ScrollView>
      </Container>
    )}
  </JobRequestPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
SuccessJobRequestPage.query = gql`
  ${JobRequestPage.fragment}
  ${JobRequestBookedBy.fragment}
  ${JobRequestFieldValues.fragment}
  ${JobRequestOrganizationLogo.fragment}

  query SuccessJobRequestPage($uuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    jobRequest(uuid: $uuid) {
      id
      uuid
      bookedBy {
        id
        ...JobRequestBookedBy
      }
      organization {
        id
        name
        ...JobRequestOrganizationLogo
      }
      ...JobRequestFieldValues
      ...JobRequestPage
    }
  }
`;

export default SuccessJobRequestPage;
