// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import InvoiceDocumentV2InvoiceSectionsContent from '@shared/modules/Document/components/InvoiceDocumentItemKind/InvoiceDocumentV2InvoiceSectionsContent';

const PlaceholderContainer = Styled.View`
  height: 180px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
`;

const PlaceholderText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const Container = Styled.View`
`;

const Content = Styled.View`
  width: 100%;
  background-color: ${(props) => (props as any).backgroundColor};
`;

const Section = Styled.View`
  align-self: stretch;
  z-index: ${(props) => 100 - (props as any).sectionIndex};
  background-color: ${colors.white};
`;

const Subtitle = Styled.Text`
  ${Typography.Subheading}
`;

const Text = Styled.Text`
  ${Typography.Body}
  color: ${(props) => (props as any).color};
`;

const PaymentsText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const BlackLabel = Styled.Text`
  ${Typography.Label}
  text-align: right;
`;

const Line = Styled.View`
  border-top-width: 1px;
  border-style: ${(props) => ((props as any).dashed ? 'dashed' : 'solid')};
  border-color: ${colors.gray.border};
`;

const InvoiceTotalSectionContainer = Styled.View`
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  background-color: ${(props) => (props as any).backgroundColor};
  border-radius: 4px;
`;

const InvoiceTotalContainer = Styled.View`
  flex-direction: row;
`;

const PaymentRow = Styled.View`
  flex-direction: row;
`;

const PaymentsSection = ({invoice}: any) => {
  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={2}>
        <Subtitle>Payments</Subtitle>
        <Space height={8} />
        <Line />
        {invoice.payments.length > 0 ? (
          invoice.payments.map((payment: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <Space height={8} />
                <PaymentItem payment={payment} key={`${index}_PAYMENT_TRANSACTION`} index={index} />
              </React.Fragment>
            );
          })
        ) : (
          <React.Fragment>
            <Space height={8} />
            <PaymentsText>No payments have been recorded for this invoice</PaymentsText>
          </React.Fragment>
        )}
        <Space height={8} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Line dashed />
        <Space height={8} />
        <BlackLabel>
          Remaining Balance <Space width={60} /> {`${Currency.display(invoice.remainingBalance)}`}
        </BlackLabel>
      </Section>
      <Space height={16} />
    </React.Fragment>
  );
};

const PaymentItem = ({payment}: any) => {
  return (
    <PaymentRow>
      <Text style={{flex: 1, textAlign: 'left'}}>{`${payment.name}`}</Text>
      <Text color={colors.gray.secondary} style={{flex: 1, textAlign: 'center'}}>
        {payment.displayMethod}
      </Text>
      <Text style={{flex: 1, textAlign: 'right'}}>{`${Currency.display(
        payment.totalAmount,
      )}`}</Text>
    </PaymentRow>
  );
};

const DocumentV2SummaryInformationContent = ({invoice, isNoPayments, backgroundColor}: any) => {
  return (
    <Container>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Content backgroundColor={backgroundColor}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Section sectionIndex={0}>
          <InvoiceDocumentV2InvoiceSectionsContent invoiceSections={invoice.invoiceSections} />
        </Section>
        <Space height={16} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Section sectionIndex={1}>
          <InvoiceTotalSectionContainer>
            <InvoiceTotalContainer>
              <Text style={{fontWeight: 'bold'}}>Grand Total</Text>
              <Space style={{flex: 1}} />
              <Text style={{fontWeight: 'bold'}}>{`${Currency.display(invoice.grandTotal)}`}</Text>
            </InvoiceTotalContainer>
          </InvoiceTotalSectionContainer>
        </Section>
        <Space height={16} />
        {!isNoPayments && <PaymentsSection invoice={invoice} />}
      </Content>
    </Container>
  );
};

const DocumentV2SummaryInformationPreview = () => {
  return (
    <PlaceholderContainer>
      <PlaceholderText>Invoice Summary Information</PlaceholderText>
    </PlaceholderContainer>
  );
};

const DocumentV2SummaryInformationInformationQueried = ({
  invoice,
  isNoPayments,
  backgroundColor,
}: any) => {
  return (
    <DocumentV2SummaryInformationContent
      invoice={invoice}
      isNoPayments={isNoPayments}
      backgroundColor={backgroundColor}
    />
  );
};

type OwnInvoiceDocumentV2SummaryInformationProps = {
  isPreview?: boolean;
  isNoPayments?: boolean;
  backgroundColor?: string;
};

// @ts-expect-error TS(2456): Type alias 'InvoiceDocumentV2SummaryInformationPro... Remove this comment to see the full error message
type InvoiceDocumentV2SummaryInformationProps = OwnInvoiceDocumentV2SummaryInformationProps &
  typeof InvoiceDocumentV2SummaryInformation.defaultProps;

// @ts-expect-error TS(7022): 'InvoiceDocumentV2SummaryInformation' implicitly h... Remove this comment to see the full error message
const InvoiceDocumentV2SummaryInformation = ({
  isPreview,
  invoice,
  isNoPayments,
  backgroundColor,
}: InvoiceDocumentV2SummaryInformationProps) => {
  return (
    <React.Fragment>
      <Space height={5} />
      {isPreview ? (
        <DocumentV2SummaryInformationPreview />
      ) : (
        <DocumentV2SummaryInformationInformationQueried
          invoice={invoice}
          isNoPayments={isNoPayments}
          backgroundColor={backgroundColor}
        />
      )}
    </React.Fragment>
  );
};

InvoiceDocumentV2SummaryInformation.defaultProps = {
  isPreview: false,
  isNoPayments: false,
  backgroundColor: colors.white,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceDocumentV2SummaryInformation.fragment = gql`
  ${InvoiceDocumentV2InvoiceSectionsContent.fragment}

  fragment InvoiceDocumentV2SummaryInformation on Invoice {
    id
    grandTotal
    remainingBalance
    invoiceSections {
      id
      ...InvoiceDocumentV2InvoiceSectionsContent
    }
    payments {
      id
      name
      displayMethod
      totalAmount
    }
  }
`;

export default InvoiceDocumentV2SummaryInformation;
