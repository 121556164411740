// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';

// Components
import {Panel, SplitPage} from 'modules/App/components';
import EmployeeJobFieldValues from 'modules/Employee/Job/Details/components/EmployeeJobFieldValues';
import {CrewJobStatusBanner, JobPageWithNavigation} from 'modules/Employee/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: 20px;
`;

const ViewJobPage = ({
  match: {
    params: {uuid},
  },
}) => (
  <JobPageWithNavigation
    variables={{uuid}}
    query={ViewJobPage.query}
    title={({loading, data}) => (loading ? 'Loading...' : Job.getFullName(data.job))}
    subtitle={() => 'View details'}
  >
    {({responsive, navigator, data}) => (
      <SplitPage split={<Panel />}>
        <Container>
          <ScrollView style={{flex: 1}}>
            <CrewJobStatusBanner job={data.job} />
            <Content {...responsive}>
              <EmployeeJobFieldValues canViewCustomerInfo job={data.job} />
            </Content>
          </ScrollView>
        </Container>
      </SplitPage>
    )}
  </JobPageWithNavigation>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewJobPage.query = gql`
  ${JobPageWithNavigation.fragment}
  ${CrewJobStatusBanner.fragment}
  ${EmployeeJobFieldValues.fragment}
  ${Job.getFullName.fragment}

  query ViewJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      uuid
      ...JobPageWithNavigation
      ...CrewJobStatusBanner
      ...EmployeeJobFieldValues
      ...Job_getFullName
    }
  }
`;

export default ViewJobPage;
