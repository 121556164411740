// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SearchBar from '@shared/design/components/SearchBar';
import TextTooltip from '@shared/design/components/TextTooltip';
import Line from 'modules/App/components/Line';
import EmailThreadsListV2 from 'modules/Communication/Email/components/EmailThreadsListV2';

const EmailActionsContainer = Styled.View`
  padding: 16px;
  background-color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TooltipContainer = Styled.View`
`;

const SearchResultsText = Styled.Text`
  ${Typography.Responsive.Body};
`;

const EmailActions = ({
  resultsCount,
  handleChangeQuery,
  searchInput,
  searchQuery,
  selectedThread,
  isLoading,
  handleComposeEmail,
  isCustomerEmailProvided,
}) => {
  const responsive = useResponsive();
  const showActions = !selectedThread || !responsive.desktop;

  return (
    <React.Fragment>
      {showActions && (
        <EmailActionsContainer>
          <Row>
            <SearchBar
              inputRef={searchInput.ref}
              onChangeText={(text) => handleChangeQuery(text)}
              placeholder={'Search'}
              containerStyle={{flex: 1}}
              style={{width: '100%'}}
              defaultValue={''}
              isLoading={isLoading}
              isClearable
              isResponsive
            />
            {handleComposeEmail && (
              <Row>
                <Space width={responsive.desktop ? 12 : 16} />
                {isCustomerEmailProvided ? (
                  <Button
                    text={null}
                    iconLeft={Icon.Plus}
                    iconSize={14}
                    onPress={handleComposeEmail}
                    isResponsive
                  />
                ) : (
                  <TextTooltip text={'Missing Email'} placement={'left'}>
                    <TooltipContainer>
                      <Button
                        isDisabled
                        text={null}
                        iconLeft={Icon.Plus}
                        iconSize={14}
                        isResponsive
                      />
                    </TooltipContainer>
                  </TextTooltip>
                )}
              </Row>
            )}
          </Row>
          <Space height={4} />
          {!!searchQuery && (
            <React.Fragment>
              <Space height={4} />
              <SearchResultsText responsive={responsive}>
                {pluralize('result', resultsCount, true)} found
              </SearchResultsText>
              <Space height={4} />
            </React.Fragment>
          )}
        </EmailActionsContainer>
      )}
    </React.Fragment>
  );
};

const EmailThreadsV2 = ({
  emailThreads,
  handleMissingContactInfo,
  selectedThread,
  setSelectedThread,
  searchInput,
  searchQuery,
  handleChangeQuery,
  isLoading,
  customer,
  refetch,
  handleComposeEmail,
}) => {
  return (
    <React.Fragment>
      <EmailActions
        resultsCount={emailThreads.length}
        handleChangeQuery={handleChangeQuery}
        searchInput={searchInput}
        searchQuery={searchQuery}
        selectedThread={selectedThread}
        isLoading={isLoading}
        handleComposeEmail={handleComposeEmail}
        isCustomerEmailProvided={customer.email}
      />
      <Line />
      <EmailThreadsListV2
        emailThreads={emailThreads}
        customer={customer}
        handleMissingContactInfo={handleMissingContactInfo}
        refetch={refetch}
        selectedThread={selectedThread}
        setSelectedThread={setSelectedThread}
        handleComposeEmail={handleComposeEmail}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Prop Types
// --------------------------------------------------
EmailThreadsV2.propTypes = {
  emailThreads: PropTypes.array.isRequired,
  handleMissingContactInfo: PropTypes.func.isRequired,
  selectedThread: PropTypes.object,
  setSelectedThread: PropTypes.func,
  searchInput: PropTypes.object,
  searchQuery: PropTypes.string,
  handleChangeQuery: PropTypes.func,
  isLoading: PropTypes.bool,
  customer: PropTypes.object.isRequired,
  refetch: PropTypes.func,
  handleComposeEmail: PropTypes.func,
};

EmailThreadsV2.defaultProps = {
  selectedThread: null,
  setSelectedThread: () => {},
  searchInput: null,
  searchQuery: '',
  handleChangeQuery: () => {},
  isLoading: false,
  refetch: () => {},
  handleComposeEmail: undefined,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailThreadsV2.fragment = gql`
  ${EmailThreadsListV2.fragment}

  fragment EmailThreadsV2_User on User {
    id
    ...EmailThreadsListV2_User
  }

  fragment EmailThreadsV2_Thread on Thread {
    id
    ...EmailThreadsListV2_Thread
  }
`;

export default EmailThreadsV2;
