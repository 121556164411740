// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const HeaderText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const HeaderLink = Styled.Text`
  ${Typography.Responsive.Link}
`;

const JobRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TipAmountText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const LoadingComponent = () => {
  return (
    <React.Fragment>
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} />
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} />
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} />
    </React.Fragment>
  );
};

interface ProjectTipsJobBreakdownModalContentProps {
  project: any;
  refetch: () => void;
}

const ProjectTipsJobBreakdownModalContent = ({
  project,
  refetch,
}: ProjectTipsJobBreakdownModalContentProps) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {project.scheduledMoveJobs.map((job: any, index: number) => (
        <React.Fragment key={job.id}>
          <Space height={index > 0 ? 16 : 8} />
          <JobRow>
            <Badge label={Job.getJobDisplayDate(job, Datetime.DISPLAY_MONTH_DAY)} isResponsive />
            <Space width={8} />
            <Badge label={job.fullName} isResponsive />
            <Space flex={1} style={{minWidth: 8}} />
            <TipAmountText responsive={responsive}>{Currency.display(job.totalTip)}</TipAmountText>
          </JobRow>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const GoToBillingText = ({urlFilters}: {urlFilters: any}) => {
  const responsive = useResponsive();

  return (
    <HeaderText responsive={responsive}>
      {'Go to '}
      <HeaderLink
        responsive={responsive}
        onPress={() =>
          urlFilters.handleReset({
            block: ProjectBlockKind.BILLING,
            widget: urlFilters.get('widget'),
          })
        }
      >
        {'billing'}
      </HeaderLink>
      {' to edit the tip for a job.'}
    </HeaderText>
  );
};

interface ProjectTipsJobBreakdownModalProps {
  projectUuid: string;
  isOpen: boolean;
  handleClose: () => void;
  urlFilters: any;
  refetch: () => void;
}

const ProjectTipsJobBreakdownModal = ({
  projectUuid,
  isOpen,
  handleClose,
  urlFilters,
  refetch,
}: ProjectTipsJobBreakdownModalProps) => {
  const {data, loading} = useQuery(ProjectTipsJobBreakdownModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectUuid},
    skip: !isOpen,
  });

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleHeaderAction={handleClose}
      headerActionIcon={Icon.Xmark}
      title={'Job Breakdown'}
      description={<GoToBillingText urlFilters={urlFilters} />}
      isMobileSheet
      isFixedHeightSheet
    >
      <Loading loading={!data || loading} as={LoadingComponent}>
        {() => <ProjectTipsJobBreakdownModalContent project={data.project} refetch={refetch} />}
      </Loading>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTipsJobBreakdownModal.query = gql`
  ${Job.getJobDisplayDate.fragment}

  query ProjectTipsJobBreakdownModal($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      scheduledMoveJobs {
        id
        fullName
        totalTip
        ...Job_getJobDisplayDate
      }
    }
  }
`;

export default ProjectTipsJobBreakdownModal;
