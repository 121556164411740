/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useSearch, useState, useQuery} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import Pill from 'components/Pill';
import DayNotesSection from 'modules/App/Day/components/DayNotesSection';
import MultipleOrganizationJobsLists from 'modules/Dispatch/Calendar/Day/RightPanel/components/MultipleOrganizationJobsLists';
import OrganizationCrewsLists from 'modules/Dispatch/Calendar/Day/RightPanel/components/OrganizationCrewsLists';
import CrewInfoContent from 'modules/Dispatch/Calendar/Day/components/CrewInfoContent';
import DayLocksSection from 'modules/Dispatch/Calendar/Day/components/DayLocksSection';
import JobInfoContent from 'modules/Dispatch/Calendar/Day/components/JobInfoContent';
import ProjectTypeDaysSection from 'modules/Dispatch/Calendar/Day/components/ProjectTypeDaysSection';

const Tab = Styled.ButtonV2`
  flex-direction: row;
  background-color: ${(props) => (props.selected ? colors.blue.accent : colors.white)};
  border: 1px solid;
  border-color: ${(props) => (props.selected ? colors.blue.accentDark : colors.gray.border)};
  border-radius: 4px;
  padding: 8px;
`;

const TabContainer = Styled.View`
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 4px;
`;

const TabTitle = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props.selected ? colors.gray.primary : colors.gray.secondary)};
`;

const TabNumber = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.white};
  line-height: 16px;
`;

const TabNumberBackground = Styled.View`
  background-color: ${(props) => (props.selected ? colors.red.warning : colors.gray.border)};
  border-radius: 2px;
  padding-horizontal: 2px;
  justify-content: center;
`;

const Wrapper = Styled.View`
  flex: 1;
  margin-horizontal: 16px;
  justify-content: flex-start;
`;

const BorderedContainer = Styled.View`
  flex: 1;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${colors.gray.border};
`;

const ItemContainer = Styled.View`
  padding-vertical: 2px;
  padding-horizontal: 8px;
`;

const SearchBarInput = Styled.TextInput`
  margin-vertical: 2px;
  margin-horizontal: 8px;
  height: 40px;
  flex: 1;
  border-width: 0;
  color: ${colors.gray.secondary};
  background-color: ${colors.gray.background};
`;

const SearchContainer = Styled.View`
  margin-vertical: 8px;
  margin-horizontal: 8px;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  border-radius: 4px;
  background-color: ${colors.gray.background};
`;

const PillContainer = Styled.View`
  margin-vertical: 2px;
  margin-horizontal: 8px;
  flex-direction: row;
`;

const PillTouchable = Styled.Touchable`
`;

const Indicator = Styled.Loading`
  padding: 20px;
`;

const JobTab = ({numberOfHours, selected, dispatchJobsSummaryKind, label}) => {
  const {navigator} = useNavigationDOM();
  return (
    <Tab selected={selected} onPress={() => navigator.replace(`?kind=${dispatchJobsSummaryKind}`)}>
      <TabTitle selected={selected}>{label}</TabTitle>
      <Space width={4} />
      <TabNumberBackground selected={selected}>
        <TabNumber>{numberOfHours}</TabNumber>
      </TabNumberBackground>
    </Tab>
  );
};

const AssignmentTabs = ({dispatchRightPanelCounts, organization, viewerDayId, dayIds}) => {
  const {params} = useNavigationDOM();
  const kindSelected = params.kind;
  const {all, unassigned, contractors} = dispatchRightPanelCounts;

  return (
    <TabContainer>
      <JobTab
        numberOfHours={all}
        selected={kindSelected === 'ALL'}
        dispatchJobsSummaryKind={'ALL'}
        label={'All'}
      />
      <Space width={4} />
      <JobTab
        numberOfHours={unassigned}
        selected={kindSelected === 'UNASSIGNED'}
        dispatchJobsSummaryKind={'UNASSIGNED'}
        label={'Unassigned'}
      />
      {organization.isPrimary && organization.hasMultipleOrganizations && (
        <React.Fragment>
          <Space width={4} />
          <JobTab
            numberOfHours={contractors}
            selected={kindSelected === 'CONTRACTORS'}
            dispatchJobsSummaryKind={'CONTRACTORS'}
            label={'Labor Sources'}
          />
        </React.Fragment>
      )}
    </TabContainer>
  );
};

const SearchCrewsList = ({crews, refetchCalendar, canAssignOrganization}) => {
  const {
    query,
    results: filteredCrews,
    setQuery,
  } = useSearch({
    initialQuery: '',
    items: crews,
    options: {keys: ['job.identifier', 'job.customer.fullName']},
  });

  return (
    <React.Fragment>
      <SearchContainer>
        <Icon color={colors.gray.secondary} size={Icon.Sizes.Medium} source={Icon.Search} />
        <SearchBarInput
          placeholder={'Customer name, job name'}
          onChangeText={(text) => setQuery(text)}
          value={query}
        />
      </SearchContainer>
      <ScrollView>
        {filteredCrews.map((crew) => {
          return (
            <ItemContainer key={crew.id}>
              <CrewInfoContent
                crew={crew}
                isAssignable
                refetch={refetchCalendar}
                canAssignOrganization={canAssignOrganization}
              />
            </ItemContainer>
          );
        })}
      </ScrollView>
    </React.Fragment>
  );
};

SearchCrewsList.fragment = gql`
  ${CrewInfoContent.fragment}

  fragment SearchCrewsList on Crew {
    id
    job {
      id
      identifier
      customer {
        id
        fullName
      }
    }
    ...CrewInfoContent
  }
`;

const OrganizationPill = ({
  dispatchJobsSummary,
  count,
  selectedOrganizationId,
  setSelectedOrganizationId,
}) => {
  const isSelected = selectedOrganizationId === dispatchJobsSummary.organization.id;

  return (
    <React.Fragment>
      <PillTouchable onPress={() => setSelectedOrganizationId(dispatchJobsSummary.organization.id)}>
        <Pill
          color={isSelected ? colors.blue.accentDark : colors.gray.border}
          backgroundColor={isSelected ? colors.blue.accent : null}
          count={count}
          text={dispatchJobsSummary.organization.name}
          reversed
        />
      </PillTouchable>
      <Space width={4} />
    </React.Fragment>
  );
};

const AllOrganizationPills = ({
  dispatchJobsSummaries,
  selectedOrganizationId,
  setSelectedOrganizationId,
}) => {
  const sortedDispatchJobsSummaries = _.sortBy(dispatchJobsSummaries, ['organization.name']);
  return (
    <PillContainer>
      <ScrollView horizontal>
        {sortedDispatchJobsSummaries.map((dispatchJobsSummary) => {
          return (
            <OrganizationPill
              key={dispatchJobsSummary.organization.id}
              count={dispatchJobsSummary.crewCounts}
              selectedOrganizationId={selectedOrganizationId}
              setSelectedOrganizationId={setSelectedOrganizationId}
              dispatchJobsSummary={dispatchJobsSummary}
            />
          );
        })}
      </ScrollView>
    </PillContainer>
  );
};

const AllOrganizationsCrewsList = ({
  viewerDayId,
  dayIds,
  organizationId,
  refetchCalendar,
  canAssignOrganization,
}) => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(organizationId);
  const {loading, data, refetch} = useQuery(AllOrganizationsCrewsList.query, {
    fetchPolicy: 'network-only',
    variables: {viewerDayId, dayIds, organizationId: selectedOrganizationId},
  });

  if (loading) {
    return <Indicator />;
  }

  const refetchBoth = () => {
    refetchCalendar();
    refetch();
  };

  return (
    <BorderedContainer>
      <AllOrganizationPills
        selectedOrganizationId={selectedOrganizationId}
        setSelectedOrganizationId={setSelectedOrganizationId}
        dispatchJobsSummaries={data.dispatchCalendarDay.dispatchJobsSummaries}
      />
      <SearchCrewsList
        crews={data.dispatchCalendarDay.unassignedCrews}
        refetchCalendar={refetchBoth}
        canAssignOrganization={canAssignOrganization}
      />
    </BorderedContainer>
  );
};

AllOrganizationsCrewsList.query = gql`
  ${SearchCrewsList.fragment}

  query AllOrganizationsCrewsList(
    $viewerDayId: Int!,
    $dayIds: [Int]!,
    $organizationId: Int!,
  ) {
    ${gql.query}
    dispatchCalendarDay(viewerDayId: $viewerDayId, dayIds: $dayIds) {
      unassignedCrews(organizationId: $organizationId) {
        id
        ...SearchCrewsList
      }
      dispatchJobsSummaries {
        organization {
          id
          name
        }
        crewCounts
      }
    }
  }
`;

const SearchJobsList = ({jobs, refetchCalendar, canAssignOrganization}) => {
  const {
    query,
    results: filteredJobs,
    setQuery,
  } = useSearch({
    initialQuery: '',
    items: jobs,
    options: {keys: ['identifier', 'customer.fullName']},
  });

  return (
    <React.Fragment>
      <SearchContainer>
        <Icon color={colors.gray.secondary} size={Icon.Sizes.Medium} source={Icon.Search} />
        <SearchBarInput
          placeholder={'Customer name, job name'}
          onChangeText={(text) => setQuery(text)}
          value={query}
        />
      </SearchContainer>
      <ScrollView>
        {filteredJobs.map((job) => {
          return (
            <ItemContainer key={job.id}>
              <JobInfoContent
                job={job}
                crewUsersCount={Job.getDispatchJobUsersCount(job)}
                crewTrucksCount={Job.getDispatchJobTrucksCount(job)}
                isAssignable
                refetch={refetchCalendar}
                canAssignOrganization={canAssignOrganization}
              />
            </ItemContainer>
          );
        })}
      </ScrollView>
    </React.Fragment>
  );
};

SearchJobsList.fragment = gql`
  ${Job.getDispatchJobTrucksCount.fragment}
  ${Job.getDispatchJobUsersCount.fragment}
  ${Job.getDispatchJobUsersNames.fragment}
  ${JobInfoContent.fragment}

  fragment SearchJobsList on Job {
    id
    identifier
    customer {
      id
      fullName
    }
    ...Job_getDispatchJobTrucksCount
    ...Job_getDispatchJobUsersCount
    ...Job_getDispatchJobUsersNames
    ...JobInfoContent
  }
`;

const JobOrCrewsList = ({
  viewerDayId,
  dayIds,
  dispatchJobsSummaryKind,
  organization,
  refetchCalendar,
}) => {
  const {isPrimary, hasMultipleOrganizations} = organization;

  // Primary Organization with multiple orgs case
  if (isPrimary && hasMultipleOrganizations) {
    return (
      <MultipleOrganizationJobsLists
        dispatchJobsSummaryKind={dispatchJobsSummaryKind}
        viewerDayId={viewerDayId}
        dayIds={dayIds}
        organizationId={organization.id}
        refetchCalendar={refetchCalendar}
        canAssignOrganization
      />
    );
  }

  // Single Organization case
  if (isPrimary) {
    return (
      <OrganizationCrewsLists
        dispatchJobsSummaryKind={dispatchJobsSummaryKind}
        viewerDayId={viewerDayId}
        dayIds={dayIds}
        organizationId={organization.id}
        refetchCalendar={refetchCalendar}
        canAssignOrganization
      />
    );
  }

  // Contractor case
  return (
    <OrganizationCrewsLists
      dispatchJobsSummaryKind={dispatchJobsSummaryKind}
      viewerDayId={viewerDayId}
      dayIds={dayIds}
      organizationId={organization.id}
      refetchCalendar={refetchCalendar}
    />
  );
};

const DispatchDayRightPanel = ({
  viewerDayId,
  day,
  organization,
  dayIds,
  dispatchJobsSummaryKind,
  dispatchRightPanelCounts,
  refetchCalendar,
}) => {
  return (
    <Wrapper>
      <Space height={8} />
      <DayNotesSection day={day} />
      {day.organization.canCreateJobs && (
        <React.Fragment>
          <Space height={8} />
          <ProjectTypeDaysSection date={day.value} />
        </React.Fragment>
      )}
      {day.organization.features.isEnabledDispatchPackingLock && day.organization.canCreateJobs && (
        <React.Fragment>
          <Space height={8} />
          <DayLocksSection day={day} refetch={refetchCalendar} />
        </React.Fragment>
      )}
      <AssignmentTabs
        dispatchRightPanelCounts={dispatchRightPanelCounts}
        organization={organization}
        viewerDayId={viewerDayId}
        dayIds={dayIds}
      />
      <JobOrCrewsList
        viewerDayId={viewerDayId}
        dayIds={dayIds}
        dispatchJobsSummaryKind={dispatchJobsSummaryKind}
        organization={organization}
        refetchCalendar={refetchCalendar}
      />
    </Wrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DispatchDayRightPanel.fragment = gql`
  ${DayLocksSection.fragment}
  ${DayNotesSection.fragment}

  fragment DispatchDayRightPanel on DispatchCalendarDay {
    day {
      id
      value
      organization {
        id
        isPrimary
        canCreateJobs
        hasMultipleOrganizations
        features {
          isEnabledDispatchPackingLock: isEnabled(feature: "DISPATCH_PACKING_LOCK")
        }
      }
      ...DayLocksSection
      ...DayNotesSection
    }
    dispatchRightPanelCounts {
      all
      unassigned
      contractors
    }
    singleDispatchRightPanelCounts {
      all
      unassigned
      contractors
    }
  }
`;

export default DispatchDayRightPanel;
