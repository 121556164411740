// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ClaimForm from '@shared/modules/Claim/forms/ClaimForm';

const useDeleteClaimMutation = ({claimForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      claimForm: ClaimForm.toForm(claimForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteClaimMutation.mutation,
    variables: {
      claimForm: ClaimForm.toMutation(form.values.claimForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteClaimMutation.mutation = gql`
  mutation useDeleteClaimMutation($claimForm: ClaimForm!) {
    response: deleteClaim(claimForm: $claimForm) {
      ${gql.errors}
      claim {
        id
      }
    }
  }
`;

export default useDeleteClaimMutation;
