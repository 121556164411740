// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import JobNotesForm from '@shared/modules/Job/forms/JobNotesForm';
import useUpdateJobNotesMutation from '@shared/modules/Job/hooks/useUpdateJobNotesMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TabContainerButton = Styled.ButtonV2`
  flex: 1;
  padding-vertical: 8px;
  align-items: center;
  border-bottom-width: 2px;
  border-color: ${(props) => (props.isSelected ? colors.blue.interactive : 'transparent')}
`;

const TabText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
`;

const InformationText = Styled.H8`
  font-size: 11px;
  line-height: 11px;
  color: ${colors.orange.status};
  ${fontWeight(500)}
`;

const NotesContainer = Styled.View`
  max-height: 480px;
  padding-horizontal: 16px;
`;

const NotesSectionTitle = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const NotesText = Styled.H7`
  color: ${colors.gray.secondary};
`;

const FooterRow = Styled.View`
  flex-direction: row;
  flex; 1;
  justify-content: flex-end;
  align-items: center;
`;

const Button = Styled.ButtonV2`
`;

const ButtonBackground = Styled.View`
  background-color: ${(props) => props.color};
  height: 36px;
  width: 80px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const ButtonLoading = Styled.Loading`
`;

// Notes tab options
const SALES = 'SALES';
const DISPATCH = 'DISPATCH';
const TAB_OPTIONS = [
  {value: SALES, displayText: 'Sales Notes'},
  {value: DISPATCH, displayText: 'Dispatch Notes'},
];

const NotesTabs = ({selectedTab, setSelectedTab}) => {
  return (
    <Row>
      {TAB_OPTIONS.map((option, index) => {
        return (
          <TabContainerButton
            key={index}
            onPress={() => setSelectedTab(option.value)}
            isSelected={selectedTab === option.value}
          >
            <TabText>{option.displayText}</TabText>
          </TabContainerButton>
        );
      })}
    </Row>
  );
};

const Information = ({children}) => {
  return (
    <Row>
      <Icon source={Icon.ExclamationCircle} color={colors.orange.status} size={12} />
      <Space width={4} />
      <InformationText>{children}</InformationText>
    </Row>
  );
};

const SalesNotesSection = ({title, notes}) => {
  return (
    <React.Fragment>
      <NotesSectionTitle>{title}</NotesSectionTitle>
      <Space height={4} />
      <NotesText>{notes || 'None'}</NotesText>
    </React.Fragment>
  );
};

const SalesNotes = ({job}) => {
  return (
    <NotesContainer>
      <ScrollView style={{maxHeight: 380}}>
        <Space height={10} />
        <Information>View only</Information>
        <Space height={10} />
        <SalesNotesSection title={'Notes From Customer'} notes={job.noteFromCustomer} />
        <Space height={8} />
        <SalesNotesSection title={'Project Description'} notes={job.project.description} />
        <Space height={8} />
        <SalesNotesSection title={'Job Description'} notes={job.description} />
        <Space height={8} />
        <SalesNotesSection title={'Office Notes'} notes={job.officeNotes} />
      </ScrollView>
    </NotesContainer>
  );
};

const DispatchNotesFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <FooterRow>
      <Button onPress={handleClose} disabled={submitting}>
        <ButtonText color={colors.gray.secondary}>Cancel</ButtonText>
      </Button>
      <Space width={26} />
      <Button onPress={handleSubmit} disabled={submitting}>
        <ButtonBackground color={submitting ? colors.gray.border : colors.blue.interactive}>
          {submitting ? (
            <ButtonLoading size={'small'} color={colors.white} />
          ) : (
            <ButtonText color={colors.white}>Save</ButtonText>
          )}
        </ButtonBackground>
      </Button>
    </FooterRow>
  );
};

const DispatchNotes = ({job, handleClose, refetch}) => {
  const jobNotesForm = JobNotesForm.edit(job);
  const {form, handleSubmit, submitting} = useUpdateJobNotesMutation({
    jobNotesForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <NotesContainer>
      <Space height={10} />
      <Space height={10} />
      <FieldInput
        {...form}
        name={'jobNotesForm.dispatchNotes'}
        input={{
          placeholder: 'Add dispatch notes...',
          style: {height: 200, paddingTop: 8},
          multiline: true,
        }}
      />
      <Space height={12} />
      <DispatchNotesFooter
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
        refetch={refetch}
      />
    </NotesContainer>
  );
};

const JobDispatchNotesPopoverContent = ({job, handleClose, refetch}) => {
  const [selectedTab, setSelectedTab] = useState(DISPATCH);
  return (
    <ResponsivePopover.Container width={320} maxHeight={480}>
      <NotesTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {selectedTab === SALES && <SalesNotes job={job} />}
      {selectedTab === DISPATCH && (
        <DispatchNotes job={job} handleClose={handleClose} refetch={refetch} />
      )}
      <Space height={16} />
    </ResponsivePopover.Container>
  );
};

const JobDispatchNotesPopover = ({popover, job, refetch, setIsClosable, placement}) => {
  return (
    <ResponsivePopover
      placement={placement}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      onComponentDidHide={() => setIsClosable(true)}
      offset={[0, 4]}
    >
      <JobDispatchNotesPopoverContent
        job={job}
        handleClose={popover.handleClose}
        refetch={refetch}
      />
    </ResponsivePopover>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobDispatchNotesPopover.propTypes = {
  setIsClosable: PropTypes.func,
};

JobDispatchNotesPopover.defaultProps = {
  setIsClosable: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchNotesPopover.fragment = gql`
  ${JobNotesForm.edit.fragment}

  fragment JobDispatchNotesPopover on Job {
    id
    dispatchNotes
    officeNotes
    noteFromCustomer
    description
    project {
      id
      description
    }
    ...JobNotesForm_edit
  }
`;

export default JobDispatchNotesPopover;
