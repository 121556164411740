// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import Drawer from '@shared/design/components/Drawer';
import GlobalNavigationSidebar from 'modules/App/Global/GlobalNavigationSidebar';
import MobileHeader from 'modules/App/Global/components/MobileHeader';
import {getSidebarItems} from 'modules/App/components/Sidebar/data/SidebarData';

const getNotificationsCount = ({key, data}) => {
  const {jobRequestCounter, viewer} = data;
  switch (key) {
    case 'leads':
    case 'projects':
    case 'moves':
      return jobRequestCounter.new;
    case 'tasks':
      return viewer.dueTasksCount;
    default:
      return 0;
  }
};

const MobileNavigationDrawer = ({isOpen, handleClose}) => {
  return (
    <Drawer isOpen={isOpen} handleClose={handleClose} isLeft>
      <MobileHeader handleClose={handleClose} />
      <GlobalNavigationSidebar
        getItems={getSidebarItems}
        getNotificationsCount={getNotificationsCount}
        query={{
          fetchPolicy: 'cache-and-network',
          query: MobileNavigationDrawer.query,
        }}
        handleClose={handleClose}
      />
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MobileNavigationDrawer.query = gql`
  ${getSidebarItems.fragment}

  query MobileNavigationDrawer {
    ${gql.query}
    jobRequestCounter {
      new
    }
    viewer {
      id
      dueTasksCount
      ...User_getSidebarItems
    }
  }
`;

export default MobileNavigationDrawer;
