// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, DateInput} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import CollectionVersionAssignedToType from '@shared/modules/Storage/enums/CollectionVersionAssignedToType';

const CollectionModalFields = ({form, field, containers}) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        label={'Collection Name'}
        name={`${field}.name`}
        input={{
          required: !_.get(form.values, 'collectionForm.name'),
          placeholder: 'Enter collection name',
        }}
      />
      <Space height={8} />
      <FieldInput
        {...form}
        index={1}
        label={'Container ID'}
        component={DropdownInput}
        name={`${field}.containerId`}
        input={{
          placeholder: 'Choose container',
          options: containers.map((container) => ({
            value: _.toNumber(container.id),
            label: container.name,
          })),
          setFieldValue: form.setFieldValue,
          onChangeValue: (value) => {
            if (value) {
              form.setFieldValue(
                `${field}.assignedToType`,
                CollectionVersionAssignedToType.CONTAINER,
              );
            } else {
              form.setFieldValue(`${field}.assignedToType`, null);
            }
          },
          style: {flex: 1},
          // Only allow clear on create
          isClearable: !form.values.collectionForm.collectionId,
          isSearchable: true,
        }}
      />
      <Space height={8} />
      <FieldInput
        {...form}
        index={2}
        component={DateInput}
        disabled
        label={'Date In'}
        name={`${field}.startDate`}
        input={{
          disabled: true,
          placeholder: 'MM/DD/YYYY',
          style: {width: '50%'},
          setFieldValue: form.setFieldValue,
        }}
      />
      <Space height={8} />
      <FieldInput
        {...form}
        index={3}
        label={'Weight'}
        name={`${field}.estimatedWeight`}
        input={{
          placeholder: '0 lbs',
        }}
      />
      <Space height={8} />
      <FieldInput
        {...form}
        index={4}
        label={'Collection Notes'}
        name={`${field}.notes`}
        style={{flex: 1}}
        input={{
          style: {height: 120, paddingTop: 8},
          multiline: true,
          placeholder: 'Enter notes for this collection',
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CollectionModalFields.fragment = gql`
  fragment CollectionModalFields on Container {
    id
    name
  }
`;

export default CollectionModalFields;
