// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

export interface ProjectTypeBillingSettingsFormType {
  projectTypeId: string;
  crewPaymentMethods: string;
  isEnabledAutomaticallyExportInvoiceToCodat: boolean;
  isEnabledAutomaticallyFinalizeInvoices: boolean;
  defaultInvoiceDocumentTemplateId: string;
  defaultInvoicePaymentTerm: number;
  defaultInvoiceEmailTemplateId: string;
  defaultInvoiceReceiptEmailTemplateId: string;
  shouldAttachDocumentsInInvoiceEmail: boolean;
  shouldAttachDocumentsInReceiptEmail: boolean;

  // Private
  isReceiptEmailEnabled: boolean;
}

const edit = withFragment(
  (projectType: any) => ({
    projectTypeId: projectType.id,
    crewPaymentMethods: projectType.crewPaymentMethods,
    isEnabledAutomaticallyExportInvoiceToCodat:
      projectType.isEnabledAutomaticallyExportInvoiceToCodat,
    isEnabledAutomaticallyFinalizeInvoices: projectType.isEnabledAutomaticallyFinalizeInvoices,
    defaultInvoiceDocumentTemplateId: projectType.defaultInvoiceDocumentTemplateId,
    defaultInvoicePaymentTerm: projectType.defaultInvoicePaymentTerm,
    defaultInvoiceEmailTemplateId: projectType.defaultInvoiceEmailTemplateId,
    defaultInvoiceReceiptEmailTemplateId: projectType.defaultInvoiceReceiptEmailTemplateId,
    shouldAttachDocumentsInInvoiceEmail: projectType.shouldAttachDocumentsInInvoiceEmail,
    shouldAttachDocumentsInReceiptEmail: projectType.shouldAttachDocumentsInReceiptEmail,

    // Private
    isReceiptEmailEnabled: !!projectType.defaultInvoiceReceiptEmailTemplateId,
  }),
  gql`
    fragment ProjectTypeBillingSettingsForm_edit on ProjectType {
      id
      crewPaymentMethods
      isEnabledAutomaticallyExportInvoiceToCodat
      isEnabledAutomaticallyFinalizeInvoices
      defaultInvoiceDocumentTemplateId
      defaultInvoicePaymentTerm
      defaultInvoiceEmailTemplateId
      defaultInvoiceReceiptEmailTemplateId
      shouldAttachDocumentsInInvoiceEmail
      shouldAttachDocumentsInReceiptEmail
    }
  `,
);

const toForm = ({
  projectTypeId,
  crewPaymentMethods,
  isEnabledAutomaticallyExportInvoiceToCodat,
  isEnabledAutomaticallyFinalizeInvoices,
  defaultInvoiceDocumentTemplateId,
  defaultInvoicePaymentTerm,
  defaultInvoiceEmailTemplateId,
  defaultInvoiceReceiptEmailTemplateId,
  shouldAttachDocumentsInInvoiceEmail,
  shouldAttachDocumentsInReceiptEmail,

  // Private
  isReceiptEmailEnabled,
}: ProjectTypeBillingSettingsFormType) => ({
  projectTypeId,
  crewPaymentMethods,
  isEnabledAutomaticallyExportInvoiceToCodat,
  isEnabledAutomaticallyFinalizeInvoices,
  defaultInvoiceDocumentTemplateId,
  defaultInvoicePaymentTerm,
  defaultInvoiceEmailTemplateId,
  defaultInvoiceReceiptEmailTemplateId,
  shouldAttachDocumentsInInvoiceEmail,
  shouldAttachDocumentsInReceiptEmail,

  // Private
  isReceiptEmailEnabled,
});

const toMutation = ({
  projectTypeId,
  crewPaymentMethods,
  isEnabledAutomaticallyExportInvoiceToCodat,
  isEnabledAutomaticallyFinalizeInvoices,
  defaultInvoiceDocumentTemplateId,
  defaultInvoicePaymentTerm,
  defaultInvoiceEmailTemplateId,
  defaultInvoiceReceiptEmailTemplateId,
  shouldAttachDocumentsInInvoiceEmail,
  shouldAttachDocumentsInReceiptEmail,
}: ProjectTypeBillingSettingsFormType) => ({
  projectTypeId,
  crewPaymentMethods,
  isEnabledAutomaticallyExportInvoiceToCodat,
  isEnabledAutomaticallyFinalizeInvoices,
  defaultInvoiceDocumentTemplateId,
  defaultInvoicePaymentTerm,
  defaultInvoiceEmailTemplateId,
  defaultInvoiceReceiptEmailTemplateId,
  shouldAttachDocumentsInInvoiceEmail,
  shouldAttachDocumentsInReceiptEmail,
});

const ProjectTypeBillingSettingsForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectTypeBillingSettingsForm;
