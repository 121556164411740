// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {ModalPage, Navigation} from 'modules/App/components';

const JobRequestPage = ({side, title, subtitle, variables, query, onClose, right, children}) => (
  <ModalPage
    variables={variables}
    query={query}
    navigation={({loading, data, navigator}) => (
      <Navigation
        side={side}
        title={title({loading, data})}
        subtitle={subtitle({loading, data})}
        left={() => <Navigation.CloseButton onPress={() => onClose({loading, data, navigator})} />}
        right={() => right({loading, data, navigator})}
      />
    )}
    children={children}
  />
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobRequestPage.propTypes = {
  onClose: PropTypes.func.isRequired,
  side: PropTypes.func,
  title: PropTypes.func,
  subtitle: PropTypes.func,
  right: PropTypes.func,
};

JobRequestPage.defaultProps = {
  // If we just have the close button, we can default to this width.
  side: () => 60,
  title: () => '',
  subtitle: () => '',
  right: () => null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestPage.fragment = gql`
  fragment JobRequestPage on JobRequest {
    id
  }
`;

export default JobRequestPage;
