const PRE_SUBTOTAL = 'PRE_SUBTOTAL';
const POST_SUBTOTAL = 'POST_SUBTOTAL';

const BILL_STAGE_DISPLAY_MAP = {
  [PRE_SUBTOTAL]: 'Before Subtotal',
  [POST_SUBTOTAL]: 'After Subtotal',
};

const getDisplayName = (billStage: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return BILL_STAGE_DISPLAY_MAP[billStage];
};

export default {
  PRE_SUBTOTAL,
  POST_SUBTOTAL,

  getDisplayName,
};
