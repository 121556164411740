// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Dropdown, Icon, Space, Styled, Tooltip} from '@supermove/components';
import {useHover} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

const Container = Styled.View`
  align-self: flex-start;
`;

const Buttons = Styled.View`
  align-self: stretch;
  flex-direction: row;
`;

const PrimaryButton = Styled.LoadingButton`
  flex: 1;
  align-items: center;
  padding-horizontal: 12px;
`;

const PrimaryText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const SecondaryButton = Styled.Button`
  align-items: center;
  justify-content: center;
  padding-horizontal: 12px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: ${colors.gray.secondary};
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;

const SecondaryActions = Styled.View`
  align-self: stretch;
  margin-top: 5px;
  padding-vertical: 6px;
  background-color: ${colors.white};
  border: 1px solid ${colors.blue.accent};
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const SecondaryAction = Styled.Touchable`
  padding-vertical: 6px;
  padding-horizontal: 12px;
  background-color: ${({isHovered}) => (isHovered ? colors.hover : colors.white)};
`;

const SecondaryText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const TooltipText = Styled.H7`
  color: ${colors.white};
  ${fontWeight(300)}
`;

const SecondaryActionItem = ({secondaryAction, handleClose}) => {
  const {isHovered, ref} = useHover();

  return (
    <SecondaryAction
      ref={ref}
      isHovered={isHovered}
      onPress={() => {
        secondaryAction.onPress();
        handleClose();
      }}
    >
      <SecondaryText>{secondaryAction.text}</SecondaryText>
    </SecondaryAction>
  );
};

const SplitButton = ({
  isDisabled,
  isLoading,
  color,
  primaryText,
  secondaryTooltipPlacement,
  secondaryActions,
  onPrimaryPress,
  icon,
  style,
}) => {
  const secondaryActionsCount = secondaryActions.length;
  const hasSecondaryActions = secondaryActionsCount > 0;

  return (
    <Container style={style}>
      <Dropdown
        trigger={({handleToggle}) => (
          <Buttons>
            <PrimaryButton
              disabled={isDisabled}
              loading={isLoading}
              color={color}
              onPress={onPrimaryPress}
              style={{
                borderTopLeftRadius: 3,
                borderBottomLeftRadius: 3,
                borderTopRightRadius: hasSecondaryActions ? 0 : 3,
                borderBottomRightRadius: hasSecondaryActions ? 0 : 3,
              }}
            >
              {!isLoading && icon && (
                <React.Fragment>
                  {icon}
                  <Space width={8} />
                </React.Fragment>
              )}
              {!isLoading && <PrimaryText>{primaryText}</PrimaryText>}
            </PrimaryButton>
            {hasSecondaryActions && (
              <Tooltip
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0.2}
                placement={secondaryTooltipPlacement}
                overlay={() => (
                  <TooltipText>
                    {pluralize('additional action', secondaryActionsCount, true)}
                  </TooltipText>
                )}
              >
                <SecondaryButton
                  disabled={isDisabled || isLoading}
                  color={color}
                  onPress={handleToggle}
                >
                  <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.CaretDown} />
                </SecondaryButton>
              </Tooltip>
            )}
          </Buttons>
        )}
      >
        {({isOpen, handleClose}) => (
          <Dropdown.Content
            isVisible={isOpen}
            style={{
              width: '100%',
            }}
          >
            <SecondaryActions>
              {secondaryActions.map((secondaryAction, index) => (
                <SecondaryActionItem
                  key={index}
                  secondaryAction={secondaryAction}
                  handleClose={handleClose}
                />
              ))}
            </SecondaryActions>
          </Dropdown.Content>
        )}
      </Dropdown>
    </Container>
  );
};

SplitButton.propTypes = {
  isDisabled: PropTypes.bool,
  color: PropTypes.string,
  primaryText: PropTypes.string.isRequired,
  secondaryTooltipPlacement: PropTypes.string,
  secondaryActions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onPress: PropTypes.func.isRequired,
    }),
  ),
  onPrimaryPress: PropTypes.func.isRequired,
  style: PropTypes.object,
};

SplitButton.defaultProps = {
  isDisabled: false,
  color: colors.blue.interactive,
  secondaryTooltipPlacement: 'top',
  secondaryActions: [],
  style: {},
};

export default SplitButton;
