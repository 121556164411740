// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Currency} from '@supermove/utils';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';

const getValuationCoverageDropdownOptions = ({valuationCoverageOptions}: any) => {
  // @ts-expect-error TS(7006): Parameter 'valuationCoverageOption' implicitly has... Remove this comment to see the full error message
  return valuationCoverageOptions.map((valuationCoverageOption, index) => ({
    label: valuationCoverageOption.displayName,
    value: index,
  }));
};

const getAmountValueOptions = ({values, valuationCoverageMinimum}: any) => {
  const filteredValues = values.filter((value: any) => value >= valuationCoverageMinimum);
  // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
  return filteredValues.map((value, index) => ({
    label: Currency.display(value),
    value: Currency.toForm(value),
  }));
};

const getAdditionalCostFromValuationCoverageOption = ({
  amountValue,
  values,
  valuationCoverageOption,
}: any) => {
  const index = _.findIndex(values, (value) => value === amountValue);
  return _.get(valuationCoverageOption, `values.${index}`);
};

const getAdditionalCost = ({form, field, values, valuationCoverageOption}: any) => {
  if (!valuationCoverageOption) {
    return 0;
  }
  const amountValue = Currency.toMutation(_.get(form.values, `${field}.amountValue`));
  const {transportationRateAmount, transportationRatePer} = valuationCoverageOption;

  if (values.length > 0) {
    return getAdditionalCostFromValuationCoverageOption({
      amountValue,
      values,
      valuationCoverageOption,
    });
  }

  if (!transportationRateAmount || !transportationRatePer) {
    return 0;
  }

  return (amountValue * transportationRateAmount) / transportationRatePer;
};

const getAmountMinimum = ({valuationCoverageMinimum, valuationCoverageOption}: any) => {
  if (valuationCoverageMinimum > 0) {
    return Currency.toForm(valuationCoverageMinimum);
  }
  const {amountMinimum} = valuationCoverageOption;
  return amountMinimum === null ? null : Currency.toForm(amountMinimum);
};

const ProjectValuationCoverageFields = ({form, field, project}: any) => {
  const {valuationCoverageMinimum} = project;
  const {valuationCoverageOptions, values} = project.projectType.valuationCoverageConfig;
  const selectedValuationCoverageOption = _.get(
    valuationCoverageOptions,
    _.get(form.values, `${field}.selectedIndex`),
  );
  const additionalCost = getAdditionalCost({
    form,
    field,
    values,
    valuationCoverageOption: selectedValuationCoverageOption,
  });

  const {isEnabledRoundValuationCoverageByWeightToNextValue} = project.organization.features;
  const categoryError = _.get(form.errors, `${field}.category`);

  return (
    <React.Fragment>
      <FieldInput.Memoized
        {...form}
        label={'Minimum Shipment Value ($)'}
        component={CurrencyInput}
        name={`${field}.amountMinimum`}
        isResponsive
        input={{
          disabled: isEnabledRoundValuationCoverageByWeightToNextValue,
          component: FieldInput.TextInput,
          placeholder: 'Enter amount',
          setFieldValue: (name: any, value: any) => {
            form.setFieldValue(name, value);
            const amountMinimum = Currency.toMutation(_.get(form.values, `${field}.amountMinimum`));
            const amountValue = Currency.toMutation(_.get(form.values, `${field}.amountValue`));
            if (amountMinimum >= amountValue) {
              form.setFieldValue(`${field}.amountValue`, value);
            }
          },
          setFieldTouched: form.setFieldTouched,
        }}
      />
      <Space height={16} />
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.selectedIndex`}
        label={'Valuation Coverage Option'}
        isResponsive
        input={{
          isPortaled: true,
          options: getValuationCoverageDropdownOptions({valuationCoverageOptions}),
          placeholder: 'Select one',
          setFieldValue: (name: any, value: any) => {
            const valuationCoverageOption = _.get(valuationCoverageOptions, value);
            const {deductible, category} = valuationCoverageOption;
            const deductibleInCents = deductible === null ? null : Currency.toForm(deductible);
            const amountMinimum = getAmountMinimum({
              valuationCoverageMinimum,
              valuationCoverageOption,
            });

            form.setFieldValue(name, value);
            form.setFieldValue(`${field}.category`, category);
            form.setFieldValue(`${field}.deductible`, deductibleInCents);
            form.setFieldValue(`${field}.amountMinimum`, amountMinimum);
            form.setFieldValue(`${field}.amountValue`, amountMinimum);
          },
          style: {
            width: '100%',
          },
        }}
        style={{flex: 1}}
      />
      {categoryError && (
        <FieldInput.CaptionText hasErrors style={{marginTop: 4}} isResponsive>
          {categoryError}
        </FieldInput.CaptionText>
      )}
      <Space height={16} />
      {values.length > 0 ? (
        <FieldInput.Memoized
          {...form}
          component={DropdownInput}
          name={`${field}.amountValue`}
          label={'Shipment Value ($)'}
          isResponsive
          input={{
            isPortaled: true,
            options: getAmountValueOptions({values, valuationCoverageMinimum}),
            placeholder: 'Select option',
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
            style: {
              width: '100%',
            },
            isSearchable: true,
          }}
          style={{flex: 1}}
        />
      ) : (
        <FieldInput.Memoized
          {...form}
          component={CurrencyInput}
          name={`${field}.amountValue`}
          label={'Shipment Value ($)'}
          isResponsive
          input={{
            component: FieldInput.TextInput,
            placeholder: 'Enter amount',
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
          }}
          style={{flex: 1}}
        />
      )}
      <Space height={16} />
      <FieldInput
        {...form}
        label={'Additional Cost'}
        isResponsive
        input={{
          disabled: true,
          value: Currency.display(additionalCost),
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectValuationCoverageFields.fragment = gql`
  fragment ProjectValuationCoverageFields on Project {
    id
    valuationCoverageMinimum
    organization {
      id
      features {
        isEnabledRoundValuationCoverageByWeightToNextValue: isEnabled(
          feature: "ROUND_VALUATION_COVERAGE_BY_WEIGHT_TO_NEXT_VALUE"
        )
      }
    }
    projectType {
      id
      valuationCoverageConfig {
        values
        valuationCoverageOptions {
          category
          deductible
          displayName
          amountMinimum
          transportationRateAmount
          transportationRatePer
          values
        }
      }
    }
  }
`;

export default ProjectValuationCoverageFields;
