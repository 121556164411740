// Supermove
import {colors} from '@supermove/styles';

const OFFICE = 'OFFICE';
const CREW = 'CREW';
const SALES = 'SALES';

const LABELS: Record<string, string> = {
  [OFFICE]: 'Office App',
  [CREW]: 'Crew App',
  [SALES]: 'AI Sales Copilot',
};

const SUPERMOVE_PRODUCT_KIND_OPTIONS = [OFFICE, CREW, SALES] as const;

export type SupermoveProductKindType = (typeof SUPERMOVE_PRODUCT_KIND_OPTIONS)[number];

const SupermoveProductKind = {
  OFFICE,
  CREW,
  SALES,

  getLabel: (supermoveProductKind: string): string => {
    return LABELS[supermoveProductKind] || supermoveProductKind;
  },
  getColor: (supermoveProductKind: string): string => {
    switch (supermoveProductKind) {
      case OFFICE:
        return colors.orange.status;
      case SALES:
        return colors.green.status;
      default:
        return colors.blue.interactive;
    }
  },
  getSortedKinds: () => SUPERMOVE_PRODUCT_KIND_OPTIONS,
};

export default SupermoveProductKind;
