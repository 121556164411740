// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import SearchBar from '@shared/design/components/SearchBar';
import Line from 'modules/App/components/Line';

const HeaderContainer = Styled.View`
  flex-direction: row;
  padding: 12px 16px;
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding: 12px 16px;
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
`;

const Container = Styled.View`
`;

const TruckText = Styled.Text`
  ${Typography.Body3}
`;

const handleCheckboxToggle = ({form, truck}) => {
  const truckIds = [...form.values.assignDriverAndTrucksToTripForm.truckIds];
  if (_.includes(truckIds, truck.id)) {
    form.setFieldValue(
      'assignDriverAndTrucksToTripForm.truckIds',
      _.remove(truckIds, (truckId) => truckId !== truck.id),
    );
  } else {
    form.setFieldValue('assignDriverAndTrucksToTripForm.truckIds', [...truckIds, truck.id]);
  }
};

const TruckBlock = ({truck, form}) => {
  return (
    <Container style={{paddingTop: 8}}>
      <Checkbox
        isChecked={_.includes(form.values.assignDriverAndTrucksToTripForm.truckIds, truck.id)}
        handleToggle={() => handleCheckboxToggle({form, truck})}
        childrenRight
      >
        <Space width={16} />
        <TruckText>{truck.name}</TruckText>
      </Checkbox>
    </Container>
  );
};

const Trucks = ({searchTerm, trucks, form}) => {
  const filteredTrucks = searchTerm
    ? trucks.filter((truck) => truck.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : trucks;
  return (
    <Container>
      {filteredTrucks.map((truck) => (
        <TruckBlock key={truck.id} truck={truck} form={form} />
      ))}
    </Container>
  );
};

const Footer = ({assignDriverAndTrucksPopover, handleSubmit}) => {
  return (
    <FooterContainer>
      <QuaternaryButton
        width={80}
        text={'Cancel'}
        onPress={assignDriverAndTrucksPopover.handleClose}
      />
      <Space width={16} />
      <Button width={76} text={'Assign'} onPress={handleSubmit} />
    </FooterContainer>
  );
};

const AssignTrucksPopoverContent = ({
  assignDriverAndTrucksPopover,
  form,
  handleSubmit,
  activeTrucks,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <Container style={{flex: 1}}>
      <HeaderContainer>
        <HeaderText>Assign Truck(s) to Trip</HeaderText>
      </HeaderContainer>
      <Line />
      <Container style={{padding: 12, flex: 1}}>
        <SearchBar
          style={{width: '100%'}}
          placeholder={'Search by truck name'}
          onChangeText={setSearchTerm}
        />
        <Space height={12} />
        <ScrollView style={{maxHeight: 300}}>
          <Trucks trucks={activeTrucks} form={form} searchTerm={searchTerm} />
        </ScrollView>
      </Container>
      <Footer
        assignDriverAndTrucksPopover={assignDriverAndTrucksPopover}
        handleSubmit={handleSubmit}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignTrucksPopoverContent.fragment = gql`
  fragment AssignTrucksPopoverContent on Organization {
    activeTrucks {
      id
      name
    }
  }
`;

export default AssignTrucksPopoverContent;
