const ALL = 'ALL';
const MOVE = 'MOVE';
const STORAGE = 'STORAGE';

const ALL_CATEGORIES = [MOVE, STORAGE];

const ProjectTypeCategory = {
  ALL,
  MOVE,
  STORAGE,

  ALL_CATEGORIES,
};

export default ProjectTypeCategory;
