// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import Line from 'components/Line';

const Row = Styled.View`
  flex-direction: row;
  z-index: ${({index = 0}) => 100 - index};
`;

const Container = Styled.View`
  z-index: ${({index = 0}) => 100 - index};
  margin-vertical: -3px;
  margin-horizontal: -5px;
`;

const CellRow = Styled.View`
  flex-direction: row;
`;

const Cell = Styled.View`
  padding-vertical: 3px;
  padding-horizontal: 5px;
  min-height: 22px;
  flex: 1;
`;

const generateValuesArray = ({numRows, numColumns, documentItem}) => {
  // Add and remove values based on rows and columns without deleting existing values
  let {values} = documentItem.input.value;
  const currentNumRows = values.length;
  const currentNumCols = values[0].length;
  // Handle adding rows
  if (numRows > currentNumRows) {
    for (let i = currentNumRows; i < numRows; i += 1) {
      values.push(_.fill(Array(numColumns), ''));
    }
  }
  // Handle adding columns
  if (numColumns > currentNumCols) {
    for (let i = 0; i < values.length; i += 1) {
      values[i] = values[i].concat(_.fill(Array(numColumns - currentNumCols), ''));
    }
  }
  // Handle remove rows
  if (numRows < currentNumRows) {
    values = values.slice(0, numRows);
  }
  // Handle remove columns
  if (numColumns < currentNumCols) {
    for (let i = 0; i < values.length; i += 1) {
      values[i] = values[i].slice(0, numColumns);
    }
  }
  return values;
};

const getColumns = ({numColumns, documentItem}) => {
  let {columns} = documentItem.input.metadata;
  if (columns.length === numColumns) {
    return columns;
  }
  if (columns.length > numColumns) {
    columns = columns.slice(0, numColumns);
    return columns;
  }
  for (let i = columns.length; i < numColumns; i += 1) {
    columns.push({name: '', is_disabled: false, is_hidden: false});
  }
  return columns;
};

const getOptions = (maxValue) => {
  const options = [];
  for (let i = 1; i <= maxValue; i += 1) {
    options.push({label: i, value: i});
  }
  return options;
};

const HeaderRow = ({documentItem, setDraftDocumentContentJson, documentItemIndex}) => {
  return (
    <CellRow>
      {documentItem.input.metadata.columns.map((column, index) => {
        return (
          <Cell key={`header-${index}`}>
            <FieldInput
              value={column.name}
              input={{
                placeholder: 'Title',
                onChangeText: (text) => {
                  setDraftDocumentContentJson((prevState) => {
                    prevState.documentItems[documentItemIndex].input.metadata.columns[index].name =
                      text;
                    return {
                      ...prevState,
                    };
                  });
                },
              }}
            />
            <Space height={8} />
            <Line />
            <Space height={2} />
          </Cell>
        );
      })}
    </CellRow>
  );
};

const InputRows = ({documentItem, setDraftDocumentContentJson, documentItemIndex}) => {
  return (
    <React.Fragment>
      {documentItem.input.value.values.map((row, rowIndex) => {
        return (
          <CellRow key={`row-${rowIndex}`}>
            {row.map((columnValue, columnIndex) => {
              return (
                <Cell key={`row-${rowIndex}-col-${columnIndex}`}>
                  <FieldInput
                    value={columnValue}
                    input={{
                      onChangeText: (text) => {
                        setDraftDocumentContentJson((prevState) => {
                          prevState.documentItems[documentItemIndex].input.value.values[rowIndex][
                            columnIndex
                          ] = text;
                          return {
                            ...prevState,
                          };
                        });
                      },
                    }}
                  />
                </Cell>
              );
            })}
          </CellRow>
        );
      })}
    </React.Fragment>
  );
};

const InputGridEditorV1 = ({documentItem, documentItemIndex, setDraftDocumentContentJson}) => {
  const [numRows, setNumRows] = useState(documentItem.input.value.values.length);
  const [numColumns, setNumColumns] = useState(documentItem.input.metadata.columns.length);
  return (
    <React.Fragment>
      <Row index={0}>
        <FieldInput
          value={numColumns}
          component={DropdownInput}
          input={{
            style: {flex: 1},
            options: getOptions(5),
            setFieldValue: (name, value) => {
              setNumColumns(value);
              setDraftDocumentContentJson((prevState) => {
                prevState.documentItems[documentItemIndex].input.metadata.columns = getColumns({
                  numColumns: value,
                  documentItem,
                });
                prevState.documentItems[documentItemIndex].input.value.values = generateValuesArray(
                  {
                    numRows,
                    numColumns: value,
                    documentItem,
                  },
                );
                return {
                  ...prevState,
                };
              });
            },
          }}
          label={'Columns'}
          style={{flex: 1}}
        />
        <Space width={16} />
        <FieldInput
          value={numRows}
          label={'Rows'}
          component={DropdownInput}
          style={{flex: 1}}
          input={{
            style: {flex: 1},
            options: getOptions(50),
            setFieldValue: (name, value) => {
              setNumRows(value);
              setDraftDocumentContentJson((prevState) => {
                prevState.documentItems[documentItemIndex].input.value.values = generateValuesArray(
                  {
                    numRows: value,
                    numColumns,
                    documentItem,
                  },
                );
                return {
                  ...prevState,
                };
              });
            },
          }}
        />
      </Row>
      <Space height={16} />
      <Container index={1}>
        <HeaderRow
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentItemIndex={documentItemIndex}
        />
        <InputRows
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentItemIndex={documentItemIndex}
        />
      </Container>
    </React.Fragment>
  );
};

export default InputGridEditorV1;
