export const ensureNumber = (name: string) => (args: any[]) => {
  args.forEach((arg) => {
    if (!['number', typeof null].includes(typeof arg)) {
      throw new TypeError(`${arg} is not a number. All arguments to ${name} must be numbers.`);
    }
  });
};

export const ensureSameType = (name: string) => (args: any[]) => {
  const types: {[type: string]: boolean} = {};
  args.forEach((arg) => {
    types[typeof arg] = true;
    if (Object.keys(types).length !== 1) {
      throw new TypeError(
        `${arg} is not a ${
          Object.keys(types)[0]
        }. All arguments to ${name} must share the same type.`,
      );
    }
  });
};

export const ensureAlways = (name: string) => (args: any[]) => true;

export const ensureString = (name: string) => (args: any[]) => {
  args.forEach((arg) => {
    if (!['string', typeof null].includes(typeof arg)) {
      throw new TypeError(`${arg} is not a string. All arguments to ${name} must be strings.`);
    }
  });
};
