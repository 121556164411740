// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ReorderBillsForm from '@shared/modules/Billing/forms/ReorderBillsForm';

const useReorderBillsMutation = ({reorderBillsForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      reorderBillsForm: ReorderBillsForm.toForm(reorderBillsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useReorderBillsMutation.mutation,
    variables: {
      reorderBillsForm: ReorderBillsForm.toMutation(form.values.reorderBillsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useReorderBillsMutation.mutation = gql`
  mutation useReorderBillsMutation($reorderBillsForm: ReorderBillsForm!) {
    response: reorderBills(reorderBillsForm: $reorderBillsForm) {
      ${gql.errors}
      bills {
        id
      }
    }
  }
`;

export default useReorderBillsMutation;
