// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {Job as JobModel} from '@supermove/models';
import {Typography} from '@supermove/styles';
import {Currency, Datetime, pluralize} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import IconButton from '@shared/design/components/IconButton';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const JobUserNameColumn = Styled.View`
  flex: 3;
`;

const JobCountColumn = Styled.View`
  flex: 4;
`;

const TipAmountRow = Styled.View`
  flex-direction: row;
  width: 100px;
  justify-content: flex-end;
  align-items: center;
`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const getUsersWithJobUsersWithJob = (project: Project): UserWithJobUsersWithJob[] => {
  const jobUsersWithJob = _.flatMap(project.scheduledMoveJobs, (job: Job) =>
    job.assignedJobUsersForTips.map((jobUser: JobUser) => ({...jobUser, job})),
  );

  const usersWithJobAndJobUserList = jobUsersWithJob.reduce(
    (result: {user: User; jobUsersWithJob: JobUserWithJob[]}[], jobUserWithJob: JobUserWithJob) => {
      const hasExistingUser = !!_.find(
        result,
        (resultUser: UserWithJobUsersWithJob) => resultUser.user.id === jobUserWithJob.user.id,
      );
      if (hasExistingUser) {
        return result.map((resultUser: UserWithJobUsersWithJob) => {
          if (resultUser.user.id === jobUserWithJob.user.id) {
            return {
              ...resultUser,
              jobUsersWithJob: [...resultUser.jobUsersWithJob, jobUserWithJob],
            };
          }
          return resultUser;
        });
      } else {
        return [...result, {user: jobUserWithJob.user, jobUsersWithJob: [jobUserWithJob]}];
      }
    },
    [],
  );

  return usersWithJobAndJobUserList;
};

const TipPayoutUserJobs = ({jobUsersWithJob}: {jobUsersWithJob: JobUserWithJob[]}) => {
  return (
    <Row>
      <JobUserNameColumn />
      <Space width={16} />
      <JobCountColumn>
        {jobUsersWithJob.map((jobUser: JobUserWithJob, index: number) => {
          return (
            <React.Fragment key={jobUser.id}>
              <Space height={8} />
              <Row>
                <Badge
                  label={JobModel.getJobDisplayDate(jobUser.job, Datetime.DISPLAY_MONTH_DAY)}
                  isResponsive
                />
                <Space width={8} />
                <Badge label={jobUser.job.fullName} isResponsive />
              </Row>
            </React.Fragment>
          );
        })}
      </JobCountColumn>
      <TipAmountRow />
    </Row>
  );
};

const TipPayoutUser = ({
  user,
  jobUsersWithJob,
}: {
  user: User;
  jobUsersWithJob: JobUserWithJob[];
}) => {
  const responsive = useResponsive();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <React.Fragment>
      <Row>
        <JobUserNameColumn>
          <BodyText responsive={responsive}>
            {user.fullName} {jobUsersWithJob.length}
          </BodyText>
        </JobUserNameColumn>
        <Space width={16} />
        <JobCountColumn>
          <BodyText responsive={responsive}>
            {pluralize('job', jobUsersWithJob.length, true)}
          </BodyText>
        </JobCountColumn>
        <TipAmountRow>
          <BodyText responsive={responsive}>
            {Currency.display(_.sumBy(jobUsersWithJob, 'tipAmount'))}
          </BodyText>
          <Space width={16} />
          <IconButton
            source={isExpanded ? IconButton.SOURCE.AngleUp : IconButton.SOURCE.AngleDown}
            onPress={() => setIsExpanded(!isExpanded)}
          />
        </TipAmountRow>
      </Row>
      {isExpanded && <TipPayoutUserJobs jobUsersWithJob={jobUsersWithJob} />}
    </React.Fragment>
  );
};

interface ProjectTipPayoutsProps {
  project: Project;
}

const ProjectTipPayouts = ({project}: ProjectTipPayoutsProps) => {
  const usersWithJobUsersWithJob = getUsersWithJobUsersWithJob(project);

  return (
    <React.Fragment>
      {usersWithJobUsersWithJob.map(
        ({user, jobUsersWithJob}: UserWithJobUsersWithJob, index: number) => {
          return (
            <React.Fragment key={user.id}>
              {index > 0 && <Space height={12} />}
              <TipPayoutUser user={user} jobUsersWithJob={jobUsersWithJob} />
            </React.Fragment>
          );
        },
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTipPayouts.fragment = gql`
  ${JobModel.getJobDisplayDate.fragment}

  fragment ProjectTipPayouts on Project {
    id
    scheduledMoveJobs {
      id
      fullName
      assignedJobUsersForTips {
        id
        tipAmount
        user {
          id
          fullName
        }
      }
      ...Job_getJobDisplayDate
    }
  }
`;

// --------------------------------------------------
// Types
// --------------------------------------------------
interface Project {
  scheduledMoveJobs: Job[];
}

interface Job {
  fullName: string;
  assignedJobUsersForTips: JobUser[];
}

interface JobUser {
  id: number;
  user: User;
  tipAmount: number;
}

interface User {
  id: number;
  fullName: string;
}

interface JobUserWithJob extends JobUser {
  job: Job;
}

interface UserWithJobUsersWithJob {
  user: User;
  jobUsersWithJob: JobUserWithJob[];
}

export default ProjectTipPayouts;
