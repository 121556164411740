// Supermove
import {gql} from '@supermove/graphql';
import {Document} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import DocumentItemForm from '@shared/modules/Document/forms/DocumentItemForm';

const edit = withFragment(
  (document) => {
    return {
      documentId: (document as any).id,
      completionStatus: (document as any).completionStatus,
      documentContentJson: (document as any).documentContentJson,
      documentItemForms: Document.getDocumentItems(document).map((documentItem: any) =>
        DocumentItemForm.edit(documentItem, {document}),
      ),
    };
  },
  gql`
    ${Document.getDocumentItems.fragment}
    ${DocumentItemForm.edit.fragment}

    fragment DocumentForm_edit on Document {
      id
      completionStatus
      documentContentJson
      ...Document_getDocumentItems
      ...DocumentItemForm_edit
    }
  `,
);

const toForm = ({documentId, completionStatus, documentContentJson, documentItemForms}: any) => {
  return {
    documentId,
    completionStatus,
    documentContentJson,
    documentItemForms: documentItemForms.map((documentItemForm: any) =>
      DocumentItemForm.toForm(documentItemForm),
    ),
  };
};

const toMutation = ({
  documentId,
  completionStatus,
  documentContentJson,
  documentItemForms,
}: any) => {
  return {
    documentId,
    completionStatus,
    documentContentJson,
    documentItemForms: documentItemForms.map((documentItemForm: any) =>
      DocumentItemForm.toMutation(documentItemForm),
    ),
  };
};

const DocumentForm = {
  edit,
  toForm,
  toMutation,
};

export default DocumentForm;
