// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DocumentItemType} from '@supermove/models';

// App
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import DocumentContentJson from '@shared/modules/Document/helpers/DocumentContentJson';
import DocumentTemplateVersionEditorTypes, {
  DocumentContentJsonType,
  SelectedDocumentItemType,
  SetDocumentContentJsonType,
  SetSelectedDocumentItemType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';

const MergeTextBlocksModal = ({
  isOpen,
  handleClose,
  selectedDocumentItem,
  setSelectedDocumentItem,
  setDraftDocumentContentJson,
}: {
  isOpen: boolean;
  handleClose: () => void;
  selectedDocumentItem: SelectedDocumentItemType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
}) => {
  return (
    <ConfirmationModal
      title={'Merge text blocks?'}
      subtitle={'This will merge text blocks adjacent to each other.'}
      isOpen={isOpen}
      handlePressOutside={handleClose}
      handlePrimaryAction={() => {
        setDraftDocumentContentJson((prevState: DocumentContentJsonType) => {
          setSelectedDocumentItem({
            ...selectedDocumentItem,
            index: undefined,
            columnIndex: undefined,
            documentItem: undefined,
          });
          const documentItemsField = DocumentContentJson.getDocumentItemsField({
            sectionIndex: selectedDocumentItem.sectionIndex,
          });
          const documentItems = _.get(prevState, documentItemsField) as DocumentItemType[];
          if (_.some(documentItems)) {
            const {getIsColumn} = DocumentTemplateVersionEditorTypes.DocumentItem;
            if (getIsColumn(documentItems[0]) && getIsColumn(documentItems[1])) {
              const leftColumnMergedItems: DocumentItemType[] = DocumentItemKindV2.mergeTextItems({
                documentItems: documentItems[0].documentItems,
              });
              const rightColumnMergedItems: DocumentItemType[] = DocumentItemKindV2.mergeTextItems({
                documentItems: documentItems[1].documentItems,
              });
              _.set(prevState, `${documentItemsField}.0.documentItems`, leftColumnMergedItems);
              _.set(prevState, `${documentItemsField}.1.documentItems`, rightColumnMergedItems);
            } else {
              const mergedDocumentItems = DocumentItemKindV2.mergeTextItems({
                documentItems,
              });
              _.set(prevState, documentItemsField, mergedDocumentItems);
            }
          }
          return {...prevState};
        });
        setSelectedDocumentItem({
          ...selectedDocumentItem,
          index: undefined,
          columnIndex: undefined,
          documentItem: undefined,
        });
        handleClose();
      }}
      handleSecondaryAction={handleClose}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
    />
  );
};

export default MergeTextBlocksModal;
