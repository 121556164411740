// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Tabs} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App

const TabsWrapper = Styled.View`
  margin-left: 20px;
`;

const TabText = Styled.Text`
  ${Typography.Label2};
  color: ${({color}) => color};
;`;

const SelectedTabBottomBorder = Styled.View`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-width: 1px;
  border-radius: 1px;
  border-color: ${colors.blue.interactive};
`;

const getBillingLibraryTabs = () => {
  return [
    {
      label: 'LIBRARIES',
      url: `/settings/billing/billing-libraries`,
    },
    {
      label: 'FORMULAS',
      url: `/settings/billing/formulas`,
    },
    {
      label: 'VARIABLES',
      url: `/settings/billing/variables`,
    },
    {
      label: 'TABLES',
      url: `/settings/billing/tables`,
    },
  ];
};

const Tab = ({tab, isSelected}) => {
  const {navigator} = useNavigationDOM();
  return (
    <Tabs.TabContainer
      onPress={() => navigator.push(tab.url)}
      isSelected={isSelected}
      style={{
        marginRight: 24,
        borderBottomWidth: 0,
      }}
    >
      <TabText color={isSelected ? colors.blue.interactive : colors.gray.secondary}>
        {tab.label}
        {isSelected && <SelectedTabBottomBorder />}
      </TabText>
    </Tabs.TabContainer>
  );
};

const BillingSettingsPageNavigationTabs = () => {
  const {navigator} = useNavigationDOM();
  const tabs = getBillingLibraryTabs();
  const currentTab = _.findIndex(tabs, {url: navigator.location.pathname});
  return (
    <TabsWrapper>
      <Tabs.TabBar
        selectedIndex={currentTab}
        handleChangeIndex={() => {}}
        TabComponent={Tab}
        style={{
          borderBottomWidth: 1,
          borderColor: colors.gray.border,
        }}
      >
        {tabs.map(({label, url}) => (
          <Tabs.Tab key={label} tab={{label, url}} />
        ))}
      </Tabs.TabBar>
    </TabsWrapper>
  );
};

export default BillingSettingsPageNavigationTabs;
