// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Datetime} from '@supermove/utils';

// App
import TimeRangesChart from 'modules/App/components/TimeRangesChart';

const Text = Styled.H7`
  margin-top: 5px;
`;

const getTimesheetText = ({timeRanges}) => {
  return timeRanges
    .map((timeRange) => {
      const start = Datetime.convertToDisplayTime(timeRange.start, Datetime.FORM_TIME);
      if (timeRange.end) {
        const end = Datetime.convertToDisplayTime(timeRange.end, Datetime.FORM_TIME);
        return `${timeRange.name} (${start} - ${end})`;
      }
      return `${timeRange.name} (${start}, In Progress)`;
    })
    .join(', ');
};

const DocumentMoveTimesChart = ({hasConfirmedTimes, timeRanges}) => {
  return (
    <React.Fragment>
      <TimeRangesChart hasConfirmedTimes={hasConfirmedTimes} timeRanges={timeRanges} />
      <Text>{getTimesheetText({timeRanges})}</Text>
    </React.Fragment>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DocumentMoveTimesChart.propTypes = {};

DocumentMoveTimesChart.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentMoveTimesChart.fragment = gql`
  ${TimeRangesChart.fragment}

  fragment DocumentMoveTimesChart on TimeRange {
    name
    kind
    start
    end
    ...TimeRangesChart
  }
`;

export default DocumentMoveTimesChart;
