// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CommercialMaterialForm from '@shared/modules/CommercialCatalog/forms/CommercialMaterialForm';

const useCreateCommercialMaterialMutation = ({commercialMaterialForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      commercialMaterialForm: CommercialMaterialForm.toForm(commercialMaterialForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateCommercialMaterialMutation.mutation,
    variables: {
      commercialMaterialForm: CommercialMaterialForm.toMutation(form.values.commercialMaterialForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateCommercialMaterialMutation.mutation = gql`
  mutation useCreateCommercialMaterialMutation($commercialMaterialForm: CommercialMaterialForm!) {
    response: createCommercialMaterial(commercialMaterialForm: $commercialMaterialForm) {
      ${gql.errors}
      commercialMaterial {
        id
      }
    }
  }
`;

export default useCreateCommercialMaterialMutation;
