// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ClaimTypeForm from '@shared/modules/Claim/forms/ClaimTypeForm';
import useUpdateClaimTypeMutation from '@shared/modules/Claim/hooks/useUpdateClaimTypeMutation';
import ClaimTypeFields from 'modules/Organization/Settings/Company/components/ClaimTypeFields';

const UpdateClaimTypeHeader = () => {
  return (
    <React.Fragment>
      <SmallModal.HeaderText>Edit Claim Type</SmallModal.HeaderText>
      <Space height={16} />
    </React.Fragment>
  );
};

const UpdateClaimTypeFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <SmallModal.Footer>
      <SmallModal.Button
        textColor={colors.gray.secondary}
        onPress={handleClose}
        isDisabled={submitting}
      >
        Cancel
      </SmallModal.Button>
      <SmallModal.Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        isSubmitting={submitting}
        onPress={handleSubmit}
        isDisabled={submitting}
      >
        <Icon source={Icon.Check} color={colors.white} size={12} />
        <Space width={4} />
        Save
      </SmallModal.Button>
    </SmallModal.Footer>
  );
};

const UpdateClaimTypeModal = ({claimType, refetch, isOpen, handleClose}) => {
  const claimTypeForm = ClaimTypeForm.edit(claimType);
  const {form, submitting, handleSubmit} = useUpdateClaimTypeMutation({
    claimTypeForm,
    onSuccess: ({claimType}) => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose} style={{overflow: 'visible'}}>
      <UpdateClaimTypeHeader />
      <Space height={4} />
      <ClaimTypeFields form={form} field={'claimTypeForm'} />
      <Space height={16} />
      <UpdateClaimTypeFooter
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateClaimTypeModal.fragment = gql`
  ${ClaimTypeForm.edit.fragment}
  fragment UpdateClaimTypeModal on ClaimType {
    id
    ...ClaimTypeForm_edit
  }
`;

export default UpdateClaimTypeModal;
