import {makeLexer} from '@shared/ast/lexer';
import {FormulaEvaluator} from '@shared/formulas/src/FormulaEvaluator';
import {FormulaGrammarParser} from '@shared/formulas/src/FormulaGrammarParser';
import {EvaluateFormulaStringInput} from '@shared/formulas/src/library/EvaluateFormulaStringInput';
import {FORMULA_TOKENIZERS} from '@shared/formulas/src/tokenizers';

const lexer = makeLexer(FORMULA_TOKENIZERS);

export const getVariable = (varName: string, variables: any[]) => {
  return variables.find((variable) => {
    return variable.identifier === varName;
  });
};

const evaluateFormulaString = (evaluateFormulaStringInput: EvaluateFormulaStringInput) => {
  const {formula, plugins, customFunctionImplementations} = evaluateFormulaStringInput;
  let lexerOutput;
  let formulaAST;
  let formulaResult;
  let formulaError;
  try {
    lexerOutput = lexer(formula);
    formulaAST = new FormulaGrammarParser(lexerOutput.tokens).parse();
    formulaResult = new FormulaEvaluator({plugins, customFunctionImplementations}).evaluate(
      formulaAST,
    );
  } catch (error) {
    formulaError = (error as Error).message;
  }
  return {
    plugins,
    lexerOutput,
    formulaAST,
    formulaResult,
    formulaError,
    formulaString: formula,
  };
};

export default evaluateFormulaString;
