import {GraphQLError} from 'graphql';
import React from 'react';

// Supermove
import {useState} from '@supermove/hooks';
import {Form, MutationError} from '@supermove/hooks/src/forms/types';

// App
// @ts-expect-error TS(2614): Module '"@shared/modules/Payment/components/PayEng... Remove this comment to see the full error message
import {PayEngine} from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import {PayengineCreditCardFormInterface} from '@shared/modules/Payment/forms/PayengineCreditCardForm';
import usePayengine from '@shared/modules/Payment/hooks/usePayengine';
import useSavePayEngineCreditCardMutation, {
  SavePayengineCreditCardResponse,
  UseSavePayengineCreditCardForm,
} from '@shared/modules/Payment/hooks/useSavePayEngineCreditCardMutation';

type Args = {
  clientId: number;
  payengineCreditCardForm: PayengineCreditCardFormInterface;
  onSuccess: (response: SavePayengineCreditCardResponse) => void;
  onError: (errors: MutationError[] | GraphQLError[] | Error) => void;
  handleCardErrorMessage: (errorMessage: any) => void;
};

type Result = {
  form: Form<UseSavePayengineCreditCardForm>;
  submitting: boolean | undefined;
  handleSubmit: () => Promise<SavePayengineCreditCardResponse | undefined>;
  setCreditCardClient: React.Dispatch<React.SetStateAction<PayEngine.CreditCardClient>>;
};

/**
 * Saves a credit card for the given client using PayEngine's APIs.
 */
const useSavePayEngineCreditCard = ({
  clientId,
  payengineCreditCardForm,
  onSuccess,
  onError,
  handleCardErrorMessage,
}: Args): Result => {
  const [creditCardClient, setCreditCardClient] = useState<PayEngine.CreditCardClient>({
    createCard: () => {
      throw Error('PayEngine credit card client is not initialized');
    },
  });
  const [tokenizeIsSubmitting, setTokenizeIsSubmitting] = useState(false);

  const savePayEngineCreditCardMutation = useSavePayEngineCreditCardMutation({
    clientId,
    payengineCreditCardForm,
    onSuccess,
    onError,
  });

  const {formatCreateCardResponse, handleTokenizeCreditCardErrors} = usePayengine();

  const submitting = tokenizeIsSubmitting || savePayEngineCreditCardMutation.submitting;
  const handleSubmit = async (): Promise<SavePayengineCreditCardResponse | undefined> => {
    try {
      setTokenizeIsSubmitting(true);
      const card = await creditCardClient.createCard();
      savePayEngineCreditCardMutation.form.setFieldValue(
        'form.createCardResponse',
        formatCreateCardResponse(card),
      );
    } catch (tokenizeCreditCardErrors) {
      handleTokenizeCreditCardErrors({
        tokenizeCreditCardErrors: tokenizeCreditCardErrors as PayEngine.TokenizeError,
        handleCardErrorMessage,
      });
      return;
    } finally {
      setTokenizeIsSubmitting(false);
    }

    try {
      const {data, errors} = await savePayEngineCreditCardMutation.handleSubmit();
      if (errors || !data) {
        onError(errors || new Error('Received empty GraphQL response from server'));
        return;
      }
      return data.response;
    } catch (error) {
      onError(error as Error);
    }
  };

  return {
    form: savePayEngineCreditCardMutation.form,
    submitting,
    handleSubmit,
    setCreditCardClient,
  };
};

export default useSavePayEngineCreditCard;
