// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Container = Styled.ButtonV2`
  width: ${({width}: any) => width};
  border-width: 1px;
  border-color: ${({color}: any) => color};
  border-radius: 4px;
  background-color: ${({color}: any) => colors.getBackgroundColor(color)};
  padding-vertical: 12px;
  padding-horizontal: 16px;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const StatusText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${({color}: any) => color};
`;

const DetailsText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const StatusCard = ({
  color,
  onPress,
  icon,
  statusText,
  headerText,
  detailsText,
  width,
  responsive,
  style,
}: any) => {
  const isMobile = responsive && !responsive.desktop;
  return (
    <Container onPress={onPress} disabled={!onPress} color={color} width={width} style={style}>
      <Row style={{justifyContent: 'space-between'}}>
        <Row style={{flex: 1}}>
          {icon && (
            <React.Fragment>
              <Icon
                source={icon}
                color={colors.gray.secondary}
                size={Icon.Sizes.Medium + (isMobile ? 2 : 0)}
              />
              <Space width={4} />
            </React.Fragment>
          )}
          <HeaderText numberOfLines={1} responsive={responsive}>
            {headerText}
          </HeaderText>
        </Row>
        <StatusText numberOfLines={1} color={color} responsive={responsive}>
          {statusText}
        </StatusText>
      </Row>
      <Space height={8} />
      <DetailsText responsive={responsive}>{detailsText}</DetailsText>
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
StatusCard.propTypes = {
  color: PropTypes.string,
  onPress: PropTypes.func,
  icon: PropTypes.string,
  statusText: PropTypes.string,
  headerText: PropTypes.string,
  detailsText: PropTypes.string,
  width: PropTypes.string,
};

StatusCard.defaultProps = {
  color: colors.green.status,
  onPress: null,
  icon: null,
  statusText: '',
  headerText: '',
  detailsText: '',
  width: '100%',
};

export default StatusCard;
