// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMountEffect} from '@supermove/hooks';
import {ClaimStatus} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import StatusBadge from 'modules/App/components/StatusBadge';

const Row = Styled.View`
  flex-direction: row;
`;

const getClaimStatusOptions = ({organization}) => {
  const claimStatuses = ClaimStatus.sortClaimStatuses(organization.companySettings.claimStatuses);
  return claimStatuses.map((claimStatus) => ({
    value: _.toString(claimStatus.id),
    label: claimStatus.name,
    color: claimStatus.color,
  }));
};

const ClaimStatusBadge = ({label, color}) => {
  return (
    <Row>
      <StatusBadge color={color} label={label} size={StatusBadge.SIZE.LARGE} />
    </Row>
  );
};

const ClaimStatusDropdown = ({form, field, customField, index, organization, isDefaulted}) => {
  const claimStatusIdField = customField || `${field}.claimStatusId`;

  useMountEffect(() => {
    const claimStatusId = _.get(form.values, claimStatusIdField);
    if (isDefaulted && !claimStatusId) {
      form.setFieldValue(
        claimStatusIdField,
        _.toString(organization.companySettings.defaultClaimStatus.id),
      );
    }
  });

  return (
    <FieldInput
      {...form}
      label={'Status'}
      name={claimStatusIdField}
      component={DropdownInput}
      index={index}
      isRequired
      isResponsive
      input={{
        options: getClaimStatusOptions({organization}),
        placeholder: 'Select claim status',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        components: {
          SingleValue: (props) => {
            return (
              <DropdownInput.SingleValueContainer {...props}>
                <ClaimStatusBadge {...props.data} />
              </DropdownInput.SingleValueContainer>
            );
          },
          Option: (props) => {
            return (
              <DropdownInput.OptionContainer {...props}>
                <ClaimStatusBadge {...props.data} />
              </DropdownInput.OptionContainer>
            );
          },
        },
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClaimStatusDropdown.fragment = gql`
  ${ClaimStatus.sortClaimStatuses.fragment}
  fragment ClaimStatusDropdown on Organization {
    id
    companySettings {
      defaultClaimStatus {
        id
      }
      claimStatuses {
        id
        name
        color
        ...ClaimStatus_sortClaimStatuses
      }
    }
  }
`;

export default ClaimStatusDropdown;
