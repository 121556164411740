// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import BillForm from '@shared/modules/Billing/forms/BillForm';

const useCreateBillAndBillItemsMutation = ({billForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      billForm: BillForm.toForm(billForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateBillAndBillItemsMutation.mutation,
    variables: {
      billForm: BillForm.toMutation(form.values.billForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateBillAndBillItemsMutation.mutation = gql`
  mutation useCreateBillAndBillItemsMutation($billForm: BillForm!) {
    response: createBillAndBillItemsMutation(billForm: $billForm) {
      ${gql.errors}
      bill {
        id
      }
    }
  }
`;

export default useCreateBillAndBillItemsMutation;
