// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize, withFragment} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import ExpirationTimeframeUnitKind from '@shared/modules/Proposal/enums/ExpirationTimeframeUnitKind';

const Column = Styled.View`
  flex: 1;
`;

const SwitchDetailsContainer = Styled.View`
padding-left: 32px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LinkButton = Styled.ButtonV2`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

interface ViewCellProps {
  label: string;
  placeholder: string;
  value: string | null;
  onLinkPress?: () => void;
  responsive: ResponsiveType;
}

const ViewCell = ({label, placeholder, value, onLinkPress, responsive}: ViewCellProps) => {
  return (
    <Row>
      <Column>
        <Label responsive={responsive}>{label}</Label>
        <Space height={8} />
        {onLinkPress ? (
          <LinkButton onPress={onLinkPress}>
            <Icon
              source={Icon.ExternalLinkAlt}
              size={Icon.Sizes.Medium}
              color={colors.blue.interactive}
            />
            <Space width={8} />
            <LinkText responsive={responsive}>{value}</LinkText>
          </LinkButton>
        ) : (
          <EditPanel.Text
            style={{
              color: value ? colors.black : colors.gray.tertiary,
              fontStyle: value ? 'normal' : 'italic',
            }}
          >
            {value || placeholder}
          </EditPanel.Text>
        )}
      </Column>
    </Row>
  );
};

interface QuoteAndConfirmationSettingsPanelEditProps {
  organization: OrganizationModel;
  // TODO(jay): Type properly when parent file is ts
  form: any;
  field: string;
  kind: string;
  responsive: ResponsiveType;
}

const QuoteAndConfirmationSettingsPanelEdit = withFragment(
  ({organization, form, field, kind, responsive}: QuoteAndConfirmationSettingsPanelEditProps) => {
    const isEnabledAutomaticExpiration = _.get(
      form.values,
      `${field}.isEnabledAutomaticExpiration`,
    );

    return (
      <React.Fragment>
        <FieldInput
          {...form}
          isRequired
          index={0}
          name={`${field}.emailTemplateId`}
          component={DropdownInput}
          style={{width: 360}}
          label={'Default Email Template'}
          input={{
            options: organization.sortedEmailTemplates.map((emailTemplate) => ({
              value: emailTemplate.id,
              label: emailTemplate.name,
            })),
            placeholder: 'Select email template',
            isSearchable: true,
            isPortaled: true,
            style: {flex: 1},
            setFieldValue: form.setFieldValue,
          }}
        />
        <Space height={16} />
        <SwitchField
          index={1}
          form={form}
          field={`${field}.isEnabledAutomaticExpiration`}
          labelRight={'Automatically Set Expiration'}
        />
        <Space height={4} />
        <SwitchDetailsContainer>
          <MicroText
            responsive={responsive}
          >{`When enabled, all sent ${kind} will automatically expire after the specified timeframe. You can override this when preparing the ${kind}.`}</MicroText>
          {isEnabledAutomaticExpiration && (
            <React.Fragment>
              <Space height={8} />
              <Row>
                <FieldInput
                  {...form}
                  isRequired
                  index={2}
                  name={`${field}.defaultExpirationTimeframe`}
                  style={{width: 240}}
                  label={'Expiration Timeframe'}
                  input={{
                    placeholder: 'Enter #',
                    style: {flex: 1},
                  }}
                />
                <Space width={16} />
                <FieldInput
                  {...form}
                  index={0}
                  name={`${field}.defaultExpirationTimeframeUnitKind`}
                  component={DropdownInput}
                  // an empty label is provided so inputs are inline even when errors are displayed
                  label={' '}
                  style={{width: 120}}
                  input={{
                    options: ExpirationTimeframeUnitKind.getDropdownOptions({
                      kinds: ExpirationTimeframeUnitKind.ALL_UNIT_KINDS,
                    }),
                    style: {flex: 1},
                    setFieldValue: form.setFieldValue,
                  }}
                />
              </Row>
            </React.Fragment>
          )}
        </SwitchDetailsContainer>
        <Space height={16} />
        <SwitchField
          index={1}
          form={form}
          field={`${field}.shouldAttachDocumentsInEmail`}
          labelRight={'Include PDFs in Emails'}
        />
        <Space height={4} />
        <SwitchDetailsContainer>
          <MicroText>{`When enabled, all ${kind} will automatically attach the documents as PDFs to the email sent to the customer.`}</MicroText>
        </SwitchDetailsContainer>
      </React.Fragment>
    );
  },
  gql`
    fragment QuoteAndConfirmationSettingsPanel_Edit on Organization {
      id
      sortedEmailTemplates {
        id
        name
      }
    }
  `,
);

interface QuoteAndConfirmationSettingsPanelProps {
  // TODO(jay): Type properly
  confirmationSteps: any;
}

const QuoteAndConfirmationSettingsPanel = withFragment(
  ({confirmationSteps}: QuoteAndConfirmationSettingsPanelProps) => {
    const {navigator} = useNavigationDOM();
    const responsive = useResponsive();

    return (
      <React.Fragment>
        <Row>
          <Column>
            <ViewCell
              label={'Default Email Template'}
              placeholder={'None'}
              value={confirmationSteps.emailTemplate?.name}
              onLinkPress={
                confirmationSteps.emailTemplate
                  ? () => {
                      navigator.push(
                        `/settings/email/email-templates/${confirmationSteps.emailTemplate.uuid}/edit`,
                      );
                    }
                  : undefined
              }
              responsive={responsive}
            />
            <Space height={16} />
            <ViewCell
              label={'Include PDFs in Emails'}
              placeholder={'No'}
              value={confirmationSteps.shouldAttachDocumentsInEmail ? 'Yes' : 'No'}
              responsive={responsive}
            />
          </Column>
          <Space width={24} />
          <Column>
            <ViewCell
              label={'Default Expiration Timeframe'}
              placeholder={'None'}
              value={
                confirmationSteps.isEnabledAutomaticExpiration
                  ? `${pluralize(
                      _.toLower(
                        ExpirationTimeframeUnitKind.getLabel({
                          kind: confirmationSteps.defaultExpirationTimeframeUnitKind,
                        }),
                      ),
                      confirmationSteps.defaultExpirationTimeframe,
                      true,
                    )} after sending`
                  : null
              }
              responsive={responsive}
            />
          </Column>
        </Row>
      </React.Fragment>
    );
  },
  gql`
    fragment QuoteAndConfirmationSettingsPanel on ConfirmationSteps {
      id
      isEnabled
      isEnabledAutomaticExpiration
      defaultExpirationTimeframe
      defaultExpirationTimeframeUnitKind
      shouldAttachDocumentsInEmail
      emailTemplate {
        id
        name
        uuid
      }
      organization {
        id
      }
    }
  `,
);

// @ts-ignore
QuoteAndConfirmationSettingsPanel.Edit = QuoteAndConfirmationSettingsPanelEdit;

export default QuoteAndConfirmationSettingsPanel;
