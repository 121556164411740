// Supermove
import {uuid} from '@supermove/utils';

export interface SendVirtualWalkthroughActionFormType {
  workflowRunStepUuid: string;
}

const _new = (): SendVirtualWalkthroughActionFormType => ({
  workflowRunStepUuid: uuid(),
});

const edit = (sendVirtualWalkthroughActionForm: any): SendVirtualWalkthroughActionFormType => ({
  workflowRunStepUuid: sendVirtualWalkthroughActionForm.workflowRunStepUuid,
});

const toForm = (
  sendVirtualWalkthroughActionForm: SendVirtualWalkthroughActionFormType,
): SendVirtualWalkthroughActionFormType => ({
  workflowRunStepUuid: sendVirtualWalkthroughActionForm.workflowRunStepUuid,
});

const toMutation = (
  sendVirtualWalkthroughActionForm: SendVirtualWalkthroughActionFormType,
): SendVirtualWalkthroughActionFormType => ({
  workflowRunStepUuid: sendVirtualWalkthroughActionForm.workflowRunStepUuid,
});

const SendVirtualWalkthroughActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default SendVirtualWalkthroughActionForm;
