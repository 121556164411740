// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Linking} from '@supermove/sdk';
import {Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Tag from '@shared/design/components/Tag';

const Container = Styled.View`
`;

const Column = Styled.View`
  flex-direction: column;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CategoryText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const TagsContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
`;

export type GlobalDashboardDataType = {
  id: string;
  name: string;
  description: string;
  helpArticleUrl?: string;
  dashboardCategory: {
    id: string;
    name: string;
  };
  dashboardTags: {
    id: number;
    tag: {
      id: string;
      name: string;
    };
  }[];
};

type GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderProps = {
  globalDashboard: GlobalDashboardDataType;
};

const GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeader = ({
  globalDashboard,
}: GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderProps) => {
  const responsive = useResponsive();

  const isTagsPresent = !!globalDashboard.dashboardTags.length;
  const isHelpArticleUrlPresent = !!globalDashboard.helpArticleUrl && responsive.desktop;

  return (
    <Container>
      <Column>
        <Row>
          <CategoryText responsive={responsive}>
            {globalDashboard.dashboardCategory.name}
          </CategoryText>
          {isHelpArticleUrlPresent && (
            <TertiaryButton
              iconLeft={Icon.QuestionCircle}
              iconSize={16}
              isDisabled={!globalDashboard.helpArticleUrl}
              text='Help'
              onPress={() => {
                if (globalDashboard.helpArticleUrl) {
                  Linking.openURL(globalDashboard.helpArticleUrl);
                }
              }}
            />
          )}
        </Row>
        <Space height={8} />
        <Row>
          <Row style={{flex: 1, justifyContent: 'flex-start'}}>
            <Title responsive={responsive}>{globalDashboard.name}</Title>
            <Space width={4} />
          </Row>
          {!responsive.desktop && (
            <Row>
              <TertiaryButton
                iconLeft={Icon.QuestionCircle}
                iconSize={16}
                isDisabled={!globalDashboard.helpArticleUrl}
                text='Help'
                onPress={() => {
                  if (globalDashboard.helpArticleUrl) {
                    Linking.openURL(globalDashboard.helpArticleUrl);
                  }
                }}
              />
            </Row>
          )}
        </Row>
        {!!globalDashboard.description && (
          <Column>
            <Space height={12} />
            <Description responsive={responsive}>{globalDashboard.description}</Description>
          </Column>
        )}
        {isTagsPresent && (
          <Column>
            <Space height={12} />
            <Row>
              <TagsContainer style={{flex: 1}}>
                {isTagsPresent &&
                  globalDashboard.dashboardTags.map((dashboardTag) => (
                    <React.Fragment key={dashboardTag.tag.id}>
                      <Tag label={dashboardTag.tag.name} />
                      <Space width={8} />
                    </React.Fragment>
                  ))}
              </TagsContainer>
            </Row>
          </Column>
        )}
      </Column>
    </Container>
  );
};

GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeader.fragment = gql`
  fragment GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragment on GlobalDashboard {
    id
    name
    description
    helpArticleUrl
    dashboardCategory {
      id
      name
    }
    dashboardTags {
      id
      tag {
        id
        name
      }
    }
  }
`;

export default GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeader;
