// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useQuery, useResponsive} from '@supermove/hooks';
import {OrganizationModel, UserModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import AuthorizeDotNetPanel from 'modules/Organization/Settings/Company/components/AuthorizeDotNetPanel';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';
import CrewShowSavedCardsPanel from 'modules/Organization/Settings/Company/components/CrewShowSavedCardsPanel';
import OfficePaymentMethodsPanel from 'modules/Organization/Settings/Company/components/OfficePaymentMethodsPanel';
import PayEnginePanel from 'modules/Organization/Settings/Company/components/PayEnginePanel';
import PaymentFeesPanel from 'modules/Organization/Settings/Company/components/PaymentFeesPanel';
import PaymentMethodsSettings from 'modules/Organization/Settings/Company/components/PaymentMethodsSettings';
import StripePanel from 'modules/Organization/Settings/Company/components/StripePanel';
import TipOptionsPanel from 'modules/Organization/Settings/Company/components/TipOptionsPanel';

const SectionTitle = Styled.Text`
  ${Typography.Responsive.Heading1}
`;

const Heading = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const Row = Styled.View<{index: number}>`
  z-index: ${({index}) => 100 - index};
`;

// /////////////////////////////////////////////////////////////////////
// TODO(jholston): Remove this block with isEnabledCustomPaymentMethods
// /////////////////////////////////////////////////////////////////////
const PaymentMethodsV1 = ({
  organization,
  responsive,
  refetch,
}: {
  organization: OrganizationModel;
  responsive: ResponsiveType;
  refetch: () => void;
}) => {
  return (
    <React.Fragment>
      <SectionTitle responsive={responsive}>Office App</SectionTitle>
      <Space height={24} />
      <OfficePaymentMethodsPanel index={0} organization={organization} />
      <Space height={24} />
      <SectionTitle responsive={responsive}>Crew App</SectionTitle>
      <Space height={24} />
      <PaymentFeesPanel
        organization={organization}
        refetch={refetch}
        index={0}
        isViewingChildBranch={!organization.isPrimary}
      />
      <Space height={24} />
      <CrewShowSavedCardsPanel
        organization={organization}
        index={1}
        isViewingChildBranch={!organization.isPrimary}
      />
      <Space height={24} />
      <TipOptionsPanel
        organization={organization}
        index={2}
        isViewingChildBranch={!organization.isPrimary}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const ProcessorsV1 = ({
  organization,
  role,
  responsive,
}: {
  organization: OrganizationModel;
  role: string;
  responsive: ResponsiveType;
}) => {
  return (
    <React.Fragment>
      <Heading responsive={responsive}>Processors</Heading>
      <Space height={16} />
      <Description responsive={responsive}>
        Configure the payment processors used on Supermove.
      </Description>
      <Space height={24} />
      <Row index={0}>
        <PayEnginePanel organization={organization} role={role} />
      </Row>
      {organization.settings.isStripePayengineGatewayEnabled && (
        <React.Fragment>
          <Space height={24} />
          <Row index={1}>
            <StripePanel organization={organization} redirectPath={'/settings/company/payments'} />
          </Row>
        </React.Fragment>
      )}
      {organization.features.isEnabledPayengineGatewayAuthorizeDotNet && (
        <React.Fragment>
          <Space height={24} />
          <Row index={2}>
            <AuthorizeDotNetPanel organization={organization} />
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyPaymentsPageContentV1 = ({
  viewer,
  refetch,
}: {
  viewer: UserModel;
  refetch: () => void;
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <PaymentMethodsV1
        organization={viewer.viewingOrganization}
        responsive={responsive}
        refetch={refetch}
      />
      <Space height={24} />
      <ProcessorsV1
        organization={viewer.viewingOrganization}
        role={viewer.role}
        responsive={responsive}
      />
    </React.Fragment>
  );
};
// /////////////////////////////////////////////////////////////////////
// END
// /////////////////////////////////////////////////////////////////////
const BillingAndTips = ({
  viewingOrganization,
  refetch,
}: {
  viewingOrganization: OrganizationModel;
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <Heading responsive={responsive}>Billing & Tips (Crew App)</Heading>
      <Space height={16} />
      <Description responsive={responsive}>
        Manage the options shown on the Crew App when customers are making a payment.
      </Description>
      <Space height={24} />
      <PaymentFeesPanel
        organization={viewingOrganization}
        refetch={refetch}
        index={0}
        isViewingChildBranch={!viewingOrganization.isPrimary}
      />
      <Space height={24} />
      <CrewShowSavedCardsPanel
        organization={viewingOrganization}
        index={1}
        isViewingChildBranch={!viewingOrganization.isPrimary}
      />
      <Space height={24} />
      <TipOptionsPanel
        organization={viewingOrganization}
        index={2}
        isViewingChildBranch={!viewingOrganization.isPrimary}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const Processors = ({
  viewingOrganization,
  role,
}: {
  viewingOrganization: OrganizationModel;
  role: string;
}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <Heading responsive={responsive}>Processors</Heading>
      <Space height={16} />
      <Description responsive={responsive}>
        Configure the payment processors used on Supermove.
      </Description>
      <Space height={24} />
      <Row index={0}>
        <PayEnginePanel organization={viewingOrganization} role={role} />
      </Row>
      {viewingOrganization.settings.isStripePayengineGatewayEnabled && (
        <React.Fragment>
          <Space height={24} />
          <Row index={1}>
            <StripePanel
              organization={viewingOrganization}
              redirectPath={'/settings/company/payments'}
            />
          </Row>
        </React.Fragment>
      )}
      {viewingOrganization.features.isEnabledPayengineGatewayAuthorizeDotNet && (
        <React.Fragment>
          <Space height={24} />
          <Row index={2}>
            <AuthorizeDotNetPanel organization={viewingOrganization} />
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyPaymentsPageContent = ({
  viewer,
  refetch,
}: {
  viewer: UserModel;
  refetch: () => void;
}) => {
  const {viewingOrganization, role} = viewer;
  return (
    <React.Fragment>
      <PaymentMethodsSettings viewingOrganization={viewingOrganization} refetch={refetch} />
      <Space height={32} />
      <BillingAndTips viewingOrganization={viewingOrganization} refetch={refetch} />
      <Space height={32} />
      <Processors viewingOrganization={viewingOrganization} role={role} />
      <Space height={32} />
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyPaymentsPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsCompanyPaymentsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage
      title={'Payments'}
      description={'Manage payment methods, fees, tips, and payment processors.'}
      isResponsive
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() =>
          data?.viewer.viewingOrganization.features.isEnabledCustomPaymentMethods ? (
            <OrganizationSettingsCompanyPaymentsPageContent
              viewer={data?.viewer}
              refetch={refetch}
            />
          ) : (
            <OrganizationSettingsCompanyPaymentsPageContentV1
              viewer={data?.viewer}
              refetch={refetch}
            />
          )
        }
      </Loading>
    </CompanySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyPaymentsPage.query = gql`
  ${AuthorizeDotNetPanel.fragment}
  ${CrewShowSavedCardsPanel.fragment}
  ${OfficePaymentMethodsPanel.fragment}
  ${PayEnginePanel.fragment}
  ${PaymentFeesPanel.fragment}
  ${PaymentMethodsSettings.fragment}
  ${StripePanel.fragment}
  ${TipOptionsPanel.fragment}
  query OrganizationSettingsCompanyPaymentsPage {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        isPrimary
        features {
          isEnabledPayengineGatewayAuthorizeDotNet: isEnabled(feature: "PAYENGINE_GATEWAY_AUTHORIZE_DOT_NET")
          isEnabledCustomPaymentMethods: isEnabled(feature: "CUSTOM_PAYMENT_METHODS")
        }
        settings {
          id
          isStripePayengineGatewayEnabled
        }
        ...CrewShowSavedCardsPanel
        ...OfficePaymentMethodsPanel
        ...StripePanel
        ...PayEnginePanel
        ...PaymentFeesPanel
        ...PaymentMethodsSettings
        ...AuthorizeDotNetPanel
        ...TipOptionsPanel
      }
    }
  }
`;

export default OrganizationSettingsCompanyPaymentsPage;
