// Libraries
import React from 'react';

// Supermove

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import VariableCategory from '@shared/modules/Billing/enums/VariableCategory';
import VariableForm from '@shared/modules/Billing/forms/VariableForm';
import useCreateVariableMutation from '@shared/modules/Billing/hooks/useCreateVariableMutation';
import VariableDrawerFields from 'modules/Organization/Settings/Billing/Variables/components/VariableDrawerFields';

const CreateVariableDrawer = ({organizationId, isOpen, handleClose, refetch, userId}) => {
  const field = 'variableForm';
  const variableForm = VariableForm.new({
    organizationId,
    userId,
    category: VariableCategory.PROJECT,
  });
  const {form, handleSubmit, submitting} = useCreateVariableMutation({
    variableForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Create Variable'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <VariableDrawerFields form={form} field={field} />
    </DrawerWithAction>
  );
};

export default CreateVariableDrawer;
