// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TreeNavigation from '@shared/design/components/TreeNavigation';
import NavigationItems from 'core/NavigationItems';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import UserSettingsHeader from 'modules/User/Settings/components/UserSettingsHeader';
import UserSettingsNavigation from 'modules/User/Settings/components/UserSettingsNavigation';

const Container = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const ContentBodyContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const VerticalDivider = Styled.View`
  height: 100%;
  width: 1px;
  background-color: ${colors.gray.border};
`;

const PageHeaderContainer = Styled.View`
  background-color: ${colors.white};
  padding-vertical: 16px;
  padding-horizontal: 24px;
`;

const PageHeaderText = Styled.Text`
  ${Typography.Responsive.Heading2};
`;

const NavigationContainer = Styled.View`
  width: 260px;
`;

const NavigationContent = ({viewer, selectedLabel}: {viewer: any; selectedLabel: string}) => {
  const {navigator} = useNavigationDOM();
  const navigationItems = UserSettingsNavigation.getItems({viewer});
  const selectedRoute = NavigationItems.getRouteForLabel({
    navigationItems,
    label: selectedLabel,
  });

  return (
    <TreeNavigation
      navigationItems={navigationItems}
      values={{route: selectedRoute}}
      handleSetValues={({route}: {route: string}) => {
        navigator.push(route);
      }}
    />
  );
};

const UserSettingsPageWrapper = ({
  children,
  selectedLabel,
  pageTitle,
  pageDescription,
  actionElement,
  backNavigationRoute,
  backNavigationRouteName,
}: {
  children: React.ReactNode;
  selectedLabel: string;
  pageTitle: string;
  pageDescription?: string;
  actionElement?: React.ReactNode;
  backNavigationRoute?: string;
  backNavigationRouteName?: string;
}) => {
  const responsive = useResponsive();
  const {loading, data} = useQuery(UserSettingsPageWrapper.query, {
    fetchPolicy: 'cache-and-network',
    variables: {},
  });

  if (responsive.desktop) {
    return (
      <Container>
        <PageHeaderContainer>
          <PageHeaderText>Settings</PageHeaderText>
        </PageHeaderContainer>
        <Line />
        <ContentContainer>
          <NavigationContainer>
            <Loading loading={loading} as={PageLoadingIndicator}>
              {() => <NavigationContent viewer={data.viewer} selectedLabel={selectedLabel} />}
            </Loading>
          </NavigationContainer>
          <VerticalDivider />
          <ContentBodyContainer>
            <ScrollView
              contentContainerStyle={{
                alignSelf: 'center',
                width: '100%',
                maxWidth: 800,
                // Margin preserves the 800 width. Padding would decrease the width to 752.
                margin: 24,
              }}
            >
              <UserSettingsHeader
                pageTitle={pageTitle}
                pageDescription={pageDescription}
                actionElement={actionElement}
                backNavigationRoute={backNavigationRoute}
                backNavigationRouteName={backNavigationRouteName}
              />
              <Space height={16} />
              {children}
            </ScrollView>
          </ContentBodyContainer>
        </ContentContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ScrollView style={{backgroundColor: colors.gray.background}}>
        <UserSettingsHeader
          pageTitle={pageTitle}
          pageDescription={pageDescription}
          actionElement={actionElement}
          backNavigationRoute={backNavigationRoute}
          backNavigationRouteName={backNavigationRouteName}
        />
        <Space height={16} />
        {children}
      </ScrollView>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserSettingsPageWrapper.query = gql`
  ${UserSettingsNavigation.getItems.fragment}

  query UserSettingsPageWrapper {
    ${gql.query}
    viewer {
      id
      ...UserSettingsNavigation_getItems
    }
  }
`;

export default UserSettingsPageWrapper;
