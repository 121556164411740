// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (project) => ({
    projectId: (project as any).id,
    name: (project as any).name,
    description: (project as any).description,
  }),
  gql`
    fragment ProjectInformationForm_edit on Project {
      id
      name
      description
    }
  `,
);

const toForm = ({projectId, name, description}: any) => ({
  projectId,
  name,
  description,
});

const toMutation = ({projectId, name, description}: any) => ({
  projectId,
  name,
  description,
});

const ProjectInformationForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectInformationForm;
