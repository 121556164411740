// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {FlatList, Lifecycle, ScrollView, Styled, Switch} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import DispatchJobItem from './DispatchJobItem';

const Container = Styled.View`
  flex: 1;
`;

const Header = Styled.View`
  align-items: center;
  padding-top: ${(props) => (props.mobile ? 0 : 20)}px;
  padding-bottom: ${(props) => (props.mobile ? 10 : 20)}px;
  background-color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-vertical: 5px;
`;

const Title = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Summary = Styled.View`
  align-items: center;
`;

const Text = Styled.H7`
`;

const Jobs = Styled.View`
  padding-vertical: 10px;
`;

const Section = Styled.View`
  margin-bottom: 10px;
`;

const Label = Styled.H6`
  margin-vertical: 5px;
  ${fontWeight(500)}
  text-align: center;
`;

const Job = Styled.View`
  margin-bottom: ${(props) => (props.isLast ? 0 : 10)}px;
  margin-horizontal: 10px;
`;

const EmptyContainer = Styled.View`
  align-items: center;
`;

const EmptyMessage = Styled.H8`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const getUnscheduledJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return job.primaryStatus !== 'CANCELLED' && !job.isTest && !job.startTime1;
  });
};

const getMorningJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return (
      job.primaryStatus !== 'CANCELLED' &&
      !job.isTest &&
      Datetime.isBetweenTime(job.startTime1, '0000', '1200')
    );
  });
};

const getAfternoonJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return (
      job.primaryStatus !== 'CANCELLED' &&
      !job.isTest &&
      Datetime.isBetweenTime(job.startTime1, '1200', '1800')
    );
  });
};

const getEveningJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return (
      job.primaryStatus !== 'CANCELLED' &&
      !job.isTest &&
      Datetime.isBetweenTime(job.startTime1, '1800', '2400')
    );
  });
};

const getTrainingJobs = ({jobs}) => {
  return jobs.filter((job) => job.isTest);
};

const getMorningJobsCount = ({jobs}) => {
  return getMorningJobs({jobs}).length;
};

const getAfternoonJobsCount = ({jobs}) => {
  return getAfternoonJobs({jobs}).length;
};

const getEveningJobsCount = ({jobs}) => {
  return getEveningJobs({jobs}).length;
};

const getPrimaryJobsCountsText = ({jobs}) => {
  return `${getMorningJobsCount({jobs})} morning - ${getAfternoonJobsCount({
    jobs,
  })} afternoon - ${getEveningJobsCount({jobs})} evening`;
};

const filterJobs = ({isCompleteOnly, jobs}) => {
  return isCompleteOnly
    ? jobs.filter((job) => ['COMPLETE', 'FINAL', 'NOT_FINAL'].includes(job.primaryStatus))
    : jobs;
};

const JobsSection = ({hideIfEmpty, empty, label, jobs}) => {
  if (hideIfEmpty && jobs.length === 0) {
    return null;
  }

  return (
    <Section>
      <Label>{label}</Label>
      <FlatList
        data={jobs}
        keyExtractor={(job) => job.id}
        renderItem={({item: job, index}) => (
          <Job isLast={index === jobs.length - 1}>
            <DispatchJobItem job={job} />
          </Job>
        )}
        ListEmptyComponent={() => (
          <EmptyContainer>
            <EmptyMessage>{empty}</EmptyMessage>
          </EmptyContainer>
        )}
      />
    </Section>
  );
};

const JobsList = ({jobs}) => (
  <Jobs>
    <JobsSection
      hideIfEmpty
      empty={`No unscheduled jobs`}
      label={'Unscheduled Jobs'}
      jobs={getUnscheduledJobs({jobs})}
    />
    <JobsSection empty={`No morning jobs`} label={'Morning Jobs'} jobs={getMorningJobs({jobs})} />
    <JobsSection
      empty={`No afternoon jobs`}
      label={'Afternoon Jobs'}
      jobs={getAfternoonJobs({jobs})}
    />
    <JobsSection empty={`No evening jobs`} label={'Evening Jobs'} jobs={getEveningJobs({jobs})} />
    <JobsSection
      empty={`No training jobs`}
      label={'Training Jobs'}
      jobs={getTrainingJobs({jobs})}
    />
  </Jobs>
);

const DayJobsHeader = ({
  date,
  hasTitleDay,
  isCompleteOnly,
  jobs,
  responsive,
  setIsCompleteOnly,
}) => (
  <Header {...responsive}>
    {hasTitleDay && <Title>{Datetime.convertToDisplayDate(date)}</Title>}
    <Row>
      <Text style={{marginRight: 7}}>Only show complete jobs</Text>
      <Switch
        isOn={isCompleteOnly}
        onTintColor={colors.gray.primary}
        onChange={async () => {
          const completeOnly = await Storage.getItem('DAY_JOBS_COMPLETE_ONLY');
          const newCompleteOnly = completeOnly === 'true' ? 'false' : 'true';
          await Storage.setItem('DAY_JOBS_COMPLETE_ONLY', newCompleteOnly);
          setIsCompleteOnly(newCompleteOnly === 'true');
        }}
      />
    </Row>
    <Summary>
      <Text>{getPrimaryJobsCountsText({jobs})}</Text>
    </Summary>
  </Header>
);

const DispatchDayJobsList = ({date, calendarDay, hasTitleDay}) => {
  const responsive = useResponsive();
  const [isCompleteOnly, setIsCompleteOnly] = useState(false);

  return (
    <Lifecycle
      onMount={async () => {
        const completeOnly = await Storage.getItem('DAY_JOBS_COMPLETE_ONLY');
        setIsCompleteOnly(completeOnly === 'true');
      }}
    >
      <Container>
        {!responsive.mobile && (
          <DayJobsHeader
            date={date}
            hasTitleDay={hasTitleDay}
            isCompleteOnly={isCompleteOnly}
            jobs={calendarDay ? filterJobs({isCompleteOnly, jobs: calendarDay.jobs}) : []}
            responsive={responsive}
            setIsCompleteOnly={setIsCompleteOnly}
          />
        )}
        <ScrollView
          style={{
            flex: 1,
          }}
        >
          {responsive.mobile && (
            <DayJobsHeader
              date={date}
              isCompleteOnly={isCompleteOnly}
              jobs={calendarDay ? filterJobs({isCompleteOnly, jobs: calendarDay.jobs}) : []}
              responsive={responsive}
              setIsCompleteOnly={setIsCompleteOnly}
            />
          )}
          <JobsList
            isCompleteOnly={isCompleteOnly}
            jobs={calendarDay ? filterJobs({isCompleteOnly, jobs: calendarDay.jobs}) : []}
          />
        </ScrollView>
      </Container>
    </Lifecycle>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DispatchDayJobsList.propTypes = {
  date: PropTypes.string.isRequired,
  calendarDay: PropTypes.object,
  hasTitleDay: PropTypes.bool,
};

DispatchDayJobsList.defaultProps = {
  calendarDay: null,
  hasTitleDay: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DispatchDayJobsList.fragment = gql`
  ${DispatchJobItem.fragment}

  fragment DispatchDayJobsList on CalendarDay {
    jobs {
      id
      isTest
      primaryStatus: calendarPrimaryStatus
      ...DispatchJobItem
    }
  }
`;

export default DispatchDayJobsList;
