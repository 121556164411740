// Libararies
import React from 'react';

// Supermove
import {Styled, Link, Loading, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import TripTicketJobsList from 'modules/Dispatch/LongDistance/Trip/components/TripTicketJobsList';
import LongDistanceDispatchTripCard from 'modules/Dispatch/LongDistance/components/LongDistanceDispatchTripCard';
import EmployeePage from 'modules/Employee/components/EmployeePage';

const ContentContainer = Styled.View`
  flex: 1;
  border-right-width: ${({mobile}) => (mobile ? '0px' : '1px')};
  border-color: ${colors.gray.background};
  max-width: 500px;
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${({color}) => color};
  padding-vertical: 12px;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading5}
  color: ${colors.white};
`;

const PageHeader = () => {
  const {params} = useNavigationDOM();
  const responsive = useResponsive();
  return (
    <HeaderContainer color={responsive.mobile ? colors.black : colors.blue.interactive}>
      <Space width={16} />
      <Link to={`/1/dispatch/trips/${params.subTab}`}>
        <Icon source={Icon.ChevronLeft} color={colors.white} size={24} />
      </Link>
      <Space width={16} />
      <HeaderText>Back to Trips</HeaderText>
    </HeaderContainer>
  );
};

const TripSummary = ({trip}) => {
  return <LongDistanceDispatchTripCard trip={trip} />;
};

const LongDistanceDispatchViewTripPage = () => {
  const responsive = useResponsive();
  const {params} = useNavigationDOM();
  const {data, loading} = useQuery(LongDistanceDispatchViewTripPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      tripUuid: params.tripUuid,
    },
  });

  return (
    <EmployeePage selected={'dispatchtripview'} HeaderComponent={PageHeader}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <ContentContainer {...responsive}>
            {!responsive.mobile && <PageHeader />}
            <TripSummary trip={data.trip} />
            <Line />
            <TripTicketJobsList
              jobs={data.trip.packLoadDeliveryJobs}
              style={{backgroundColor: colors.gray.background}}
            />
          </ContentContainer>
        )}
      </Loading>
    </EmployeePage>
  );
};

LongDistanceDispatchViewTripPage.query = gql`
  ${LongDistanceDispatchTripCard.fragment}
  ${TripTicketJobsList.fragment}

  query LongDistanceDispatchViewTripPage(
    $tripUuid: String!,
  ) {
    ${gql.query}
    trip(tripUuid: $tripUuid) {
      id
      packLoadDeliveryJobs {
        id
        ...TripTicketJobsList
      }
      ...LongDistanceDispatchTripCard
    }
  }
`;

export default LongDistanceDispatchViewTripPage;
