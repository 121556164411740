// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CreateCrewWithSlotsAndTrucksForm from '@shared/modules/Dispatch/forms/CreateCrewWithSlotsAndTrucksForm';

const useCreateCrewWithSlotsAndTrucksMutation = ({
  createCrewWithSlotsAndTrucksForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      createCrewWithSlotsAndTrucksForm: CreateCrewWithSlotsAndTrucksForm.toForm(
        createCrewWithSlotsAndTrucksForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateCrewWithSlotsAndTrucksMutation.mutation,
    variables: {
      createCrewWithSlotsAndTrucksForm: CreateCrewWithSlotsAndTrucksForm.toMutation(
        form.values.createCrewWithSlotsAndTrucksForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateCrewWithSlotsAndTrucksMutation.mutation = gql`
  mutation useCreateCrewWithSlotsAndTrucksMutation($createCrewWithSlotsAndTrucksForm: CreateCrewWithSlotsAndTrucksForm!) {
    response: createCrewWithSlotsAndTrucks(createCrewWithSlotsAndTrucksForm: $createCrewWithSlotsAndTrucksForm) {
      ${gql.errors}
      crew {
        id
      }
    }
  }
`;

export default useCreateCrewWithSlotsAndTrucksMutation;
