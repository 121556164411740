// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteBillRuleTypeMutation = ({billRuleTypeId, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      billRuleTypeId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteBillRuleTypeMutation.mutation,
    variables: {
      billRuleTypeId: form.values.billRuleTypeId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useDeleteBillRuleTypeMutation.mutation = gql`
  mutation useDeleteBillRuleTypeMutation($billRuleTypeId: Int!) {
    response: deleteBillRuleType(billRuleTypeId: $billRuleTypeId) {
      ${gql.errors}
      billRuleType {
        id
      }
    }
  }
`;

export default useDeleteBillRuleTypeMutation;
