// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';

const handleSelectMoverPosition = ({form, field, handleSubmit, moverPosition}) => {
  const isChanged =
    _.toString(_.get(form.values, `${field}.primaryMoverPositionId`)) !==
    _.toString(moverPosition.id);
  if (isChanged) {
    const currentMoverPositionIds = _.get(form.values, `${field}.moverPositionIds`);
    form.setFieldValue(`${field}.primaryMoverPositionId`, moverPosition.id);
    form.setFieldValue(
      `${field}.moverPositionIds`,
      _.uniq([...currentMoverPositionIds, moverPosition.id]),
    );
    setTimeout(handleSubmit, 0);
  }
};

const getMoverPositionActions = ({moverPositions, handleAction}) => {
  return moverPositions
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .map((moverPosition) => ({
      text: moverPosition.name,
      onPress: () => handleAction({moverPosition}),
    }));
};

const JobUserMoverPositionPopover = ({popover, jobUser, form, field, handleSubmit, children}) => {
  const handleAction = ({moverPosition}) =>
    handleSelectMoverPosition({form, field, handleSubmit, moverPosition});

  const userMoverPositions = jobUser.user.moverPositions;
  const allMoverPositions = jobUser.user.organization.companySettings.moverPositions;

  return (
    <ActionMenuPopover
      popover={popover}
      width={400}
      popoverMaxHeight={400}
      actions={[
        {
          label: `${jobUser.user.fullName}'s Positions`,
          actions: getMoverPositionActions({
            moverPositions: userMoverPositions,
            handleAction,
          }),
        },
        {
          label: 'Other Positions',
          actions: getMoverPositionActions({
            moverPositions: allMoverPositions.filter(
              ({id}) => !_.find(userMoverPositions, ['id', id]),
            ),
            handleAction,
          }),
        },
      ]}
      placement={Popover.Positions.BottomStart}
    >
      {children}
    </ActionMenuPopover>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobUserMoverPositionPopover.fragment = gql`
  fragment JobUserMoverPositionPopover on JobUser {
    id
    user {
      id
      fullName
      organization {
        id
        companySettings {
          moverPositions {
            id
            name
          }
        }
      }
      moverPositions {
        id
        name
      }
    }
  }
`;

export default JobUserMoverPositionPopover;
