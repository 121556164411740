// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Modal, ScrollView, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import {Navigation} from 'modules/App/components';

import UpdateSupplyForm from './UpdateSupplyForm';

const Overlay = Styled.View`
  flex: 1;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props.mobile ? '100%' : '500px')};
  padding-horizontal: 20px;
`;

const Container = Styled.View`
  width: 100%;
  margin-vertical: 60px;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.blue.accent};
`;

const HeaderMainSection = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const HeaderBorderSection = Styled.View`
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.mobile ? 40 : 60)}px;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const UpdateSupplyModal = ({index, organizationSuppliesForm, refetch, trigger}) => {
  const responsive = useResponsive();

  return (
    <Modal onClose={refetch} trigger={trigger}>
      {({handleClose}) => (
        <Overlay pointerEvents={'box-none'}>
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Wrapper {...responsive}>
              <Container>
                <Header>
                  <HeaderBorderSection {...responsive}>
                    <Navigation.CloseButton responsive={responsive} onPress={handleClose} />
                  </HeaderBorderSection>
                  <HeaderMainSection>
                    <Title>Update Supply</Title>
                  </HeaderMainSection>
                  <HeaderBorderSection />
                </Header>
                <UpdateSupplyForm
                  index={index}
                  organizationSuppliesForm={organizationSuppliesForm}
                  onSuccess={handleClose}
                  isUpdate
                />
              </Container>
            </Wrapper>
          </ScrollView>
        </Overlay>
      )}
    </Modal>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateSupplyModal.propTypes = {
  organizationSuppliesForm: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
};

UpdateSupplyModal.defaultProps = {};

export default UpdateSupplyModal;
