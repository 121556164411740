// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const UnassignUserFromJobMutation = ({
  jobId,
  userId,
  refetchQueries,
  onSuccess,
  onError,
  children,
}) => {
  return (
    <Mutation
      variables={{
        jobId,
        userId,
      }}
      mutation={UnassignUserFromJobMutation.mutation}
      onCompleted={({response: {errors}}) => {
        if (!errors) {
          onSuccess();
        } else if (onError) {
          onError(errors);
        }
      }}
      refetchQueries={refetchQueries}
      awaitRefetchQueries
    >
      {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
    </Mutation>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UnassignUserFromJobMutation.propTypes = {
  refetchQueries: PropTypes.array,
};

UnassignUserFromJobMutation.defaultProps = {
  refetchQueries: [],
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UnassignUserFromJobMutation.mutation = gql`
  mutation UnassignUserFromJobMutation(
    $jobId: Int!,
    $userId: Int!,
  ) {
    response: unassignUserFromJob(
      jobId: $jobId,
      userId: $userId,
    ) {
      ${gql.errors}
    }
  }
`;

export default UnassignUserFromJobMutation;
