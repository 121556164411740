// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import BeginPaymentForm from '@shared/modules/Payment/forms/BeginPaymentForm';
import useBeginPaymentMutation from '@shared/modules/Payment/hooks/useBeginPaymentMutation';
import useFinalizePaymentMutation from '@shared/modules/Payment/hooks/useFinalizePaymentMutation';
import BillingProjectPaymentMethodsDropdown from 'modules/Project/Billing/components/BillingProjectPaymentMethodsDropdown';

const TitleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const InstructionText = Styled.H6`
  color: ${colors.black};
  ${fontWeight(500)}
`;

const FieldsContainer = Styled.View`
`;

const FieldContainer = Styled.View`
  z-index: ${({zIndex = 0}) => zIndex};
`;

const RemainingBalanceText = Styled.H8`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const TextInput = Styled.TextInput.H7`
`;

const AuthorizeDotNetButtonContainer = Styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-color: ${colors.blue.interactive};
  background-color: ${colors.Blue10};
  border-width: 1px;
  border-radius: 4px;
  padding-horizontal: 12px;
  padding-vertical: 8px;
`;

const AuthorizeDotNetButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive}
`;

const getPaymentAmount = (bill) => {
  const min = bill.project.activeJobsAggregateBill.minBalance;
  const max = bill.project.activeJobsAggregateBill.maxBalance;
  if (min > 0) {
    return min;
  }
  if (max > 0) {
    return max;
  }
  return 0;
};

const PaymentHeader = () => {
  return (
    <React.Fragment>
      <TitleText>Record Payment</TitleText>
      <Space height={16} />
      <InstructionText>
        Select a payment name and enter an amount. These payments should have happened already.
      </InstructionText>
    </React.Fragment>
  );
};

const AuthorizeDotNetLink = ({project, form}) => {
  const amount = Currency.convertToCents(_.get(form.values, 'beginPaymentForm.amount'));
  return (
    <a target={'_blank'} href={Project.getAuthorizeDotNetUrlWithAmount(project, {amount})}>
      <AuthorizeDotNetButtonContainer>
        <AuthorizeDotNetButtonText>Click to pay with authorize.net</AuthorizeDotNetButtonText>
        <Space width={8} />
        <Icon source={Icon.ArrowRight} size={12} color={colors.blue.interactive} />
      </AuthorizeDotNetButtonContainer>
    </a>
  );
};

const RemainingBalance = ({bill}) => {
  const min = bill.project.activeJobsAggregateBill.minBalance;
  const max = bill.project.activeJobsAggregateBill.maxBalance;
  const color = min > 0 || max > 0 ? colors.red.warning : colors.green.status;
  return (
    <RemainingBalanceText color={color}>
      {`Remaining Balance: ${Currency.formatRange({min, max})}`}
    </RemainingBalanceText>
  );
};

const PaymentFields = ({form, bill}) => {
  const isPaymentMethodAuthorizeDotNet =
    _.get(form.values, 'beginPaymentForm.method') === 'AUTHORIZE_DOT_NET';
  return (
    <FieldsContainer>
      <FieldContainer zIndex={4}>
        <FieldInput
          {...form}
          name={'beginPaymentForm.name'}
          label={'Payment Name'}
          isResponsive
          input={{
            placeholder: 'Enter a payment name',
            required: !form.values.beginPaymentForm.name,
          }}
        />
      </FieldContainer>
      <Space height={16} />
      <FieldContainer zIndex={3}>
        <FieldInput
          {...form}
          name={'beginPaymentForm.amount'}
          label={'Payment Amount'}
          component={CurrencyInput}
          isResponsive
          handleBlur={() => {
            const amount = _.get(form.values, 'beginPaymentForm.amount');
            const formattedAmount = Currency.display(Currency.convertToCents(amount), {
              shouldHideCentsIfZero: true,
            });
            form.setFieldValue('beginPaymentForm.amount', formattedAmount);
          }}
          input={{
            component: TextInput,
            placeholder: 'Enter a payment amount',
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
          }}
        />
      </FieldContainer>
      <Space height={4} />
      <RemainingBalance bill={bill} />
      <Space height={16} />
      <FieldContainer zIndex={2}>
        <BillingProjectPaymentMethodsDropdown form={form} bill={bill} />
      </FieldContainer>
      {!!bill.project.authorizeDotNetUrl && isPaymentMethodAuthorizeDotNet && (
        <React.Fragment>
          <Space height={16} />
          <AuthorizeDotNetLink project={bill.project} form={form} />
          <Space height={4} />
        </React.Fragment>
      )}
      <Space height={16} />
      <FieldContainer zIndex={1}>
        <FieldInput
          {...form}
          name={'beginPaymentForm.description'}
          label={'Payment Description'}
          isResponsive
          input={{
            placeholder: 'Add description',
            required:
              (form.values.beginPaymentForm.method === 'CHECK' ||
                form.values.beginPaymentForm.method === 'AUTHORIZE_DOT_NET') &&
              !form.values.beginPaymentForm.description,
            style: {height: 80, paddingTop: 10},
            multiline: true,
          }}
        />
      </FieldContainer>
    </FieldsContainer>
  );
};

const PaymentFooter = ({handleClose, handleSubmit, submitting, responsive}) => {
  return (
    <SmallModal.Footer isResponsive>
      {responsive.desktop && (
        <React.Fragment>
          <QuaternaryButton onPress={handleClose} disabled={submitting} text={'Cancel'} />
          <Space width={16} />
        </React.Fragment>
      )}
      <Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        onPress={handleSubmit}
        isSubmitting={submitting}
        isResponsive
        isWidthOfContainer={!responsive.desktop}
        text={'Confirm'}
      />
    </SmallModal.Footer>
  );
};

const BillingProjectRecordPaymentModalV2Content = ({handleClose, refetch, bill}) => {
  const {project} = bill;
  const responsive = useResponsive();
  const beginPaymentForm = BeginPaymentForm.new({
    billId: bill.id,
    customerId: bill.customerId,
    amount: getPaymentAmount(bill),
    name: `Payment for ${Project.getName(project)}`,
  });

  const beginPaymentMutation = useBeginPaymentMutation({
    beginPaymentForm,
    onSuccess: ({payment}) => {
      return payment;
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  const finalizePaymentMutation = useFinalizePaymentMutation({
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  const submitting = finalizePaymentMutation.submitting || beginPaymentMutation.submitting;
  const handleSubmit = async () => {
    const {data} = await beginPaymentMutation.handleSubmit();
    if (!data.response.payment) {
      return;
    }
    await finalizePaymentMutation.form.setFieldValue('paymentId', data.response.payment.id);
    finalizePaymentMutation.handleSubmit();
  };

  return (
    <React.Fragment>
      {responsive.desktop && (
        <React.Fragment>
          <PaymentHeader />
          <Space height={20} />
        </React.Fragment>
      )}
      <FieldContainer zIndex={1}>
        <PaymentFields form={beginPaymentMutation.form} bill={bill} />
      </FieldContainer>
      <Space height={20} />
      <PaymentFooter
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
        responsive={responsive}
      />
    </React.Fragment>
  );
};

const BillingProjectRecordPaymentModalV2 = ({handleClose, isOpen, refetch, bill}) => {
  const {loading, data} = useQuery(BillingProjectRecordPaymentModalV2.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      uuid: bill.uuid,
    },
  });

  return (
    <SmallModal
      handleClose={handleClose}
      isOpen={isOpen}
      isMobileSheet
      sheetLabel={'Record Payment'}
    >
      <Loading loading={loading}>
        {() => (
          <BillingProjectRecordPaymentModalV2Content
            handleClose={handleClose}
            refetch={refetch}
            bill={data.bill}
          />
        )}
      </Loading>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectRecordPaymentModalV2.query = gql`
  ${BillingProjectPaymentMethodsDropdown.fragment}
  ${Project.getAuthorizeDotNetUrlWithAmount.fragment}
  ${Project.getName.fragment}

  query BillingProjectRecordPaymentModalV2 ($uuid: String!) {
    ${gql.query}
    bill(uuid: $uuid) {
      id
      customerId
      project {
        id
        activeJobsAggregateBill {
          minBalance
          maxBalance
        }
        authorizeDotNetUrl
        ...Project_getAuthorizeDotNetUrlWithAmount
        ...Project_getName
      }
      ...BillingProjectPaymentMethodsDropdown
    }
  }
`;

BillingProjectRecordPaymentModalV2.fragment = gql`
  fragment BillingProjectRecordPaymentModalV2 on Bill {
    id
    uuid
  }
`;

export default BillingProjectRecordPaymentModalV2;
