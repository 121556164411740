/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useState} from '@supermove/hooks';

import {SSEContext, SSEContextValue} from '@shared/modules/SSE/context/SSEContext';
import {SSEEvent} from '@shared/modules/SSE/interfaces';

export const useSSE = (
  eventTypes: string[] = [],
): SSEContextValue & {latestEvent: SSEEvent | null} => {
  const [latestEvent, setLatestEvent] = useState<SSEEvent | null>(null);
  const sseContext = useContext(SSEContext);

  const {events, subscribe, unsubscribe, subscribedChannels, connected} = sseContext;
  useEffect(() => {
    const filteredEvents = eventTypes
      ? events.filter((event) => eventTypes.includes(event.eventType))
      : events;
    const latestEventChanged =
      filteredEvents[filteredEvents.length - 1]?.eventId !== latestEvent?.eventId;
    if (filteredEvents.length > 0 && latestEventChanged) {
      setLatestEvent(filteredEvents[filteredEvents.length - 1]);
    }
  }, [events]);

  const filteredEvents = eventTypes
    ? events.filter((event) => eventTypes.includes(event.eventType))
    : events;
  return {
    events: filteredEvents,
    subscribe,
    unsubscribe,
    latestEvent,
    subscribedChannels,
    connected,
    flushEvents: sseContext.flushEvents,
  };
};
