// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import SaveCollectionChangesModal from 'modules/Job/Inventory/components/SaveCollectionChangesModal';
import CreateRoomModal from 'modules/Room/components/CreateRoomModal';

const Sidebar = Styled.View`
  width: 180px;
`;

const TabButton = Styled.Touchable`
  flex-direction: row;
  align-items: center;
  width: 164px;
  height: 40px;
  padding-horizontal: 16px;
  background-color: ${(props) => (props.isSelected ? colors.blue.accent : colors.white)};
  border-radius: 8px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const TabText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const SurveyItemsTab = ({inventory, selectedRoom, handleSubmit, submitting, hasChanges}) => {
  const {navigator, params} = useNavigationDOM();
  const {takeCount} = inventory.collection;
  const saveCollectionChangesModal = useModal({
    name: 'Save Collection Changes Modal - Survey Items',
  });

  return (
    <React.Fragment>
      <TabButton
        isSelected={!selectedRoom}
        onPress={() => {
          if (hasChanges) {
            return saveCollectionChangesModal.handleOpen();
          }
          navigator.replace(`/jobs/${params.jobUuid}/inventories/${inventory.uuid}/summary`);
        }}
      >
        <Icon color={colors.gray.primary} size={10} source={Icon.Archive} />
        <Space width={8} />
        <TabText>{`Survey Items (${takeCount})`}</TabText>
      </TabButton>
      <SaveCollectionChangesModal
        isOpen={saveCollectionChangesModal.isOpen}
        handleClose={saveCollectionChangesModal.handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
        onSuccess={() =>
          navigator.replace(`/jobs/${params.jobUuid}/inventories/${inventory.uuid}/summary`)
        }
        roomName={selectedRoom ? selectedRoom.name : ''}
      />
    </React.Fragment>
  );
};

const AddNewRoomTab = ({inventory, selectedRoom, hasChanges, handleSubmit, submitting}) => {
  const {navigator, params} = useNavigationDOM();
  const addNewRoomModal = useModal({name: 'Add New Room Modal'});
  const saveCollectionChangesModal = useModal({name: 'Save Collection Changes Modal - Add Room'});

  return (
    <React.Fragment>
      <TabButton
        onPress={() => {
          if (hasChanges) {
            return saveCollectionChangesModal.handleOpen();
          }
          addNewRoomModal.handleOpen();
        }}
      >
        <TabText>+ Add New Room</TabText>
      </TabButton>
      <CreateRoomModal
        isOpen={addNewRoomModal.isOpen}
        inventory={inventory}
        handleClose={addNewRoomModal.handleClose}
        onSuccess={({room}) => {
          navigator.replace(
            `/jobs/${params.jobUuid}/inventories/${params.uuid}/rooms/${room.uuid}/edit`,
          );
        }}
      />
      <SaveCollectionChangesModal
        isOpen={saveCollectionChangesModal.isOpen}
        handleClose={saveCollectionChangesModal.handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
        onSuccess={() => {
          addNewRoomModal.handleOpen();
          saveCollectionChangesModal.handleClose();
        }}
        roomName={selectedRoom ? selectedRoom.name : ''}
      />
    </React.Fragment>
  );
};

const RoomTab = ({room, selectedRoom, hasChanges, handleSubmit, submitting}) => {
  const {navigator, params} = useNavigationDOM();
  const saveCollectionChangesModal = useModal({name: 'Save Collection Changes Modal - Room Tab'});
  const handleNavigation = () => {
    navigator.replace(`/jobs/${params.jobUuid}/inventories/${params.uuid}/rooms/${room.uuid}/edit`);
  };

  return (
    <React.Fragment>
      <TabButton
        isSelected={!!selectedRoom && selectedRoom.id === room.id}
        onPress={() => {
          if (hasChanges) {
            return saveCollectionChangesModal.handleOpen();
          }
          handleNavigation();
        }}
      >
        <TabText>{room.name}</TabText>
      </TabButton>
      <SaveCollectionChangesModal
        isOpen={saveCollectionChangesModal.isOpen}
        handleClose={saveCollectionChangesModal.handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
        onSuccess={handleNavigation}
        roomName={selectedRoom ? selectedRoom.name : ''}
      />
    </React.Fragment>
  );
};

const InventorySidebar = ({inventory, selectedRoom, hasChanges, handleSubmit, submitting}) => {
  return (
    <Sidebar>
      <ScrollView style={{flex: 1, paddingVertical: 20}}>
        <SurveyItemsTab
          inventory={inventory}
          selectedRoom={selectedRoom}
          hasChanges={hasChanges}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
        <Space height={8} />
        <AddNewRoomTab
          inventory={inventory}
          selectedRoom={selectedRoom}
          hasChanges={hasChanges}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
        <Space height={8} />
        {inventory.rooms.map((room) => (
          <React.Fragment key={room.id}>
            <RoomTab
              room={room}
              selectedRoom={selectedRoom}
              hasChanges={hasChanges}
              handleSubmit={handleSubmit}
              submitting={submitting}
            />
            <Space height={8} />
          </React.Fragment>
        ))}
      </ScrollView>
    </Sidebar>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventorySidebar.fragment = gql`
  ${CreateRoomModal.fragment}

  fragment InventorySidebar on Inventory {
    id
    collection {
      id
      takeCount
    }
    rooms {
      id
      uuid
      name
    }
    ...CreateRoomModal
  }
`;

export default InventorySidebar;
