// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useRef, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Float, pluralize} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import TextTooltip from '@shared/design/components/TextTooltip';
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import ItemTypeForm from '@shared/modules/Inventory/forms/ItemTypeForm';
import ItemTypesForm from '@shared/modules/Inventory/forms/ItemTypesForm';
import useUpsertItemTypesMutation from '@shared/modules/Inventory/hooks/useUpsertItemTypesMutation';
import InventoryLibraryBulkUpdateContents from 'modules/Organization/Settings/Inventory/components/InventoryLibraryBulkUpdateContents';
import getBulkActionCheckboxColumnDefinition from 'modules/Organization/Settings/Inventory/helpers/getBulkActionCheckboxColumnDefinition';

const CenteredCell = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Row = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label}
`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const IconContainer = Styled.View`
  width: 16px;
`;

const Touchable = Styled.ButtonV2`
  padding: 4px;
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const IsDerivedWeightButton = ({defaultDensityFactor, form, field, isDisabled}) => {
  const isDerivedWeight = _.get(form.values, `${field}.isDerivedWeight`);
  const volume = Float.toFloat(_.get(form.values, `${field}.volume`));

  return (
    <TextTooltip
      placement={'top'}
      style={{textAlign: 'center'}}
      text={isDerivedWeight ? 'Unlink from volume' : 'Link to volume'}
    >
      <Touchable
        disabled={isDisabled}
        onPress={() => {
          const newIsDerivedWeight = !isDerivedWeight;
          const newWeight = volume * defaultDensityFactor;
          form.setFieldValue(`${field}.isDerivedWeight`, newIsDerivedWeight);

          if (newIsDerivedWeight) {
            form.setFieldValue(`${field}.weight`, Float.toForm(newWeight));
          }
          form.setFieldValue(`${field}.isDirty`, true);
        }}
      >
        <Icon
          color={isDerivedWeight ? colors.red.warning : colors.blue.interactive}
          size={14}
          source={isDerivedWeight ? Icon.Unlink : Icon.Link}
        />
      </Touchable>
    </TextTooltip>
  );
};

const setFormIsDirty = ({form, field, rowIndex, isDirty = true}) => {
  form.setFieldValue(`${field}.${rowIndex}.isDirty`, isDirty);
};

const getColumnDefinitions = ({
  isEdit,
  inventoryLibrary,
  itemTypeKind,
  organization,
  form,
  field,
  scrollToBottom,
  bulkSelectedFormIndices,
  setBulkSelectedFormIndices,
}) => {
  return [
    getBulkActionCheckboxColumnDefinition({
      isEdit,
      form,
      field,
      bulkSelectedFormIndices,
      setBulkSelectedFormIndices,
    }),
    {
      flex: 4,
      headerContent: () => {
        return <TableHeaderText>{`${ItemTypeKind.getLabel(itemTypeKind)} Name`}</TableHeaderText>;
      },
      viewCellContent: ({item: itemType}) => {
        return <Text>{itemType.name}</Text>;
      },
      editCellContent: ({rowIndex}) => {
        return (
          <FieldInput.Memoized
            {...form}
            // since the field is memoized, we add a key to trigger an update on isDeleted
            key={_.get(form.values, `${field}.${rowIndex}.isDeleted`)}
            name={`${field}.${rowIndex}.name`}
            input={{
              autoFocus: rowIndex === _.size(_.get(form.values, field, [])) - 1,
              disabled: _.get(form.values, `${field}.${rowIndex}.isDeleted`),
              placeholder: `Enter ${ItemTypeKind.getLabel(itemTypeKind)} name`,
              setFieldValue: form.setFieldValue,
              style: {flex: 1},
            }}
            style={{flex: 1}}
            handleChange={() => setFormIsDirty({form, field, rowIndex})}
          />
        );
      },
      footerContent: () => {
        return (
          <TertiaryButton
            text={`Add ${ItemTypeKind.getLabel(itemTypeKind)}`}
            iconLeft={Icon.Plus}
            onPress={() => {
              const existingItemTypeForms = form.values.itemTypesForm.itemTypeForms;
              form.setFieldValue(field, [
                ...existingItemTypeForms,
                ItemTypeForm.new({
                  organizationId: organization.id,
                  inventoryLibraryId: inventoryLibrary.id,
                  kind: itemTypeKind,
                }),
              ]);
              setTimeout(() => scrollToBottom(), 0);
            }}
          />
        );
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <TableHeaderText>{`Volume (cu ft)`}</TableHeaderText>;
      },
      viewCellContent: ({item: itemType}) => {
        return <Text>{itemType.volume}</Text>;
      },
      editCellContent: ({rowIndex}) => {
        return (
          <FieldInput.Memoized
            {...form}
            // since the field is memoized, we add a key to trigger an update on isDeleted
            key={_.get(form.values, `${field}.${rowIndex}.isDeleted`)}
            name={`${field}.${rowIndex}.volume`}
            input={{
              disabled: _.get(form.values, `${field}.${rowIndex}.isDeleted`),
              placeholder: 'Enter volume',
              setFieldValue: form.setFieldValue,
              onChangeText: (text) => {
                const isDerivedWeight = _.get(form.values, `${field}.${rowIndex}.isDerivedWeight`);
                const newVolume = Float.toFloat(text);
                const newWeight = newVolume * organization.settings.defaultDensityFactor;

                // NOTE(mark): We don't set the volume to the converted float value because we want
                // to allow the user to enter in decimal places. '1.' would be converted to '1' and it
                // would be impossible to add '1.5'.
                form.setFieldValue(`${field}.${rowIndex}.volume`, text);

                if (isDerivedWeight) {
                  form.setFieldValue(`${field}.${rowIndex}.weight`, Float.toForm(newWeight));
                }

                setFormIsDirty({form, field, rowIndex});
              },
              style: {flex: 1},
            }}
            style={{flex: 1}}
          />
        );
      },
      footerContent: () => {},
    },
    {
      flex: 2,
      headerContent: () => {
        return <TableHeaderText>{`Weight (lbs)`}</TableHeaderText>;
      },
      viewCellContent: ({item: itemType}) => {
        return <Text>{itemType.weight}</Text>;
      },
      editCellContent: ({rowIndex}) => {
        const isDisabled =
          _.get(form.values, `${field}.${rowIndex}.isDerivedWeight`) ||
          _.get(form.values, `${field}.${rowIndex}.isDeleted`);
        return (
          <Row>
            <FieldInput.Memoized
              {...form}
              // since the field is memoized, we add a keys to trigger an update on isDisabled
              key={isDisabled}
              name={`${field}.${rowIndex}.weight`}
              input={{
                disabled: isDisabled,
                placeholder: 'Enter weight',
                style: {flex: 1},
              }}
              style={{
                flex: 1,
              }}
              handleChange={() => setFormIsDirty({form, field, rowIndex})}
            />
            <Space width={8} />
            <IsDerivedWeightButton
              isDisabled={_.get(form.values, `${field}.${rowIndex}.isDeleted`)}
              defaultDensityFactor={organization.settings.defaultDensityFactor}
              form={form}
              field={`${field}.${rowIndex}`}
            />
          </Row>
        );
      },
      footerContent: () => {},
    },
    {
      flex: 2,
      isHidden: !organization.features.isEnabledInventoryPriceField,
      headerContent: () => {
        return <TableHeaderText>{`Price ($)`}</TableHeaderText>;
      },
      viewCellContent: ({item: itemType}) => {
        return <Text>{Currency.display(itemType.price)}</Text>;
      },
      editCellContent: ({rowIndex}) => {
        return (
          <FieldInput.Memoized
            {...form}
            // since the field is memoized, we add a key to trigger an update on isDeleted
            key={_.get(form.values, `${field}.${rowIndex}.isDeleted`)}
            name={`${field}.${rowIndex}.price`}
            component={CurrencyInput}
            input={{
              disabled: _.get(form.values, `${field}.${rowIndex}.isDeleted`),
              component: TextInput,
              placeholder: 'Enter price',
              setFieldValue: form.setFieldValue,
              setFieldTouched: form.setFieldTouched,
              onChangeText: (value) => {
                setFormIsDirty({form, field, rowIndex});
              },
            }}
            style={{
              flex: 1,
            }}
          />
        );
      },
      footerContent: () => {},
    },
    {
      flex: 4,
      headerContent: () => {
        return (
          <Row>
            <TableHeaderText>Categories</TableHeaderText>
            <Space width={8} />
            <TextTooltip
              placement={'top'}
              style={{textAlign: 'center'}}
              text={`Hover to show full list of categories`}
            >
              <IconContainer>
                <Icon
                  color={colors.gray.tertiary}
                  size={Icon.Sizes.Medium}
                  source={Icon.InfoCircle}
                />
              </IconContainer>
            </TextTooltip>
          </Row>
        );
      },
      viewCellContent: ({item: itemType}) => {
        const categoryNames = _.join(
          _.map(
            itemType.itemTypeCategories,
            (itemTypeCategory) => itemTypeCategory.category.name,
          ).sort(),
          ', ',
        );
        return _.isEmpty(itemType.itemTypeCategories) ? (
          <Text>None</Text>
        ) : (
          <TextTooltip
            placement={'top'}
            style={{textAlign: 'center', maxWidth: '300px'}}
            text={categoryNames}
          >
            <Text>{pluralize('category', itemType.itemTypeCategories.length, true)}</Text>
          </TextTooltip>
        );
      },
      editCellContent: ({rowIndex}) => {
        return (
          <FieldInput.Memoized
            {...form}
            // since the field is memoized, we add a key to trigger an update on isDeleted
            key={_.get(form.values, `${field}.${rowIndex}.isDeleted`)}
            name={`${field}.${rowIndex}.categoryIds`}
            component={MultiDropdownCheckboxInput}
            input={{
              disabled: _.get(form.values, `${field}.${rowIndex}.isDeleted`),
              isPortaled: true,
              options: _.map(inventoryLibrary.categories, (category) => {
                return {
                  label: category.name,
                  value: category.id,
                };
              }),
              placeholder: 'Select categories',
              setFieldValue: form.setFieldValue,
              style: {width: '100%'},
              onChangeValue: () => setFormIsDirty({form, field, rowIndex}),
            }}
            style={{flex: 1}}
          />
        );
      },
      footerContent: () => {},
    },
    {
      flex: 1,
      headerContent: () => {},
      viewCellContent: () => {},
      editCellContent: ({rowIndex}) => {
        const isDeleted = _.get(form.values, `${field}.${rowIndex}.isDeleted`);
        return (
          <CenteredCell>
            <TertiaryButton
              text={isDeleted ? 'Restore' : 'Remove'}
              onPress={() => {
                form.setFieldValue(`${field}.${rowIndex}.isDeleted`, !isDeleted);
                setFormIsDirty({form, field, rowIndex});
              }}
              textColor={isDeleted ? colors.blue.interactive : colors.red.warning}
            />
          </CenteredCell>
        );
      },
      footerContent: () => {},
    },
  ];
};

const getViewColumnDefinitions = ({organization, itemTypeKind}) => {
  // these dependencies are not needed for the viewCellContent
  return _.map(
    getColumnDefinitions({
      isEdit: false,
      inventoryLibrary: null,
      itemTypeKind,
      organization,
      form: null,
      field: '',
      scrollToBottom: () => {},
      bulkSelectedFormIndices: [],
      setBulkSelectedFormIndices: () => {},
    }),
    (columnDefinition) => ({
      ...columnDefinition,
      cellContent: columnDefinition.viewCellContent,
    }),
  );
};

const getEditColumnDefinitions = ({
  inventoryLibrary,
  itemTypeKind,
  form,
  field,
  scrollToBottom,
  bulkSelectedFormIndices,
  setBulkSelectedFormIndices,
}) => {
  return _.map(
    getColumnDefinitions({
      isEdit: true,
      inventoryLibrary,
      itemTypeKind,
      organization: inventoryLibrary.organization,
      form,
      field,
      scrollToBottom,
      bulkSelectedFormIndices,
      setBulkSelectedFormIndices,
    }),
    (columnDefinition) => ({
      ...columnDefinition,
      cellContent: columnDefinition.editCellContent,
    }),
  );
};

const scrollToBottom = ({virtualizedListRef, itemCount}) => {
  virtualizedListRef.current.scrollToItem(itemCount);
};

const CartonAndItemsContent = ({
  inventoryLibrary,
  itemTypeKind,
  itemTypes,
  csvActions,
  title,
  emptyStateText,
  refetch,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [bulkSelectedFormIndices, setBulkSelectedFormIndices] = useState([]);
  const virtualizedListRef = useRef(null);

  // View
  const [searchTerm, setSearchTerm] = useState('');
  const viewColumnDefinitions = getViewColumnDefinitions({
    organization: inventoryLibrary.organization,
    itemTypeKind,
  });
  const filteredItemTypes = _.filter(itemTypes, (itemType) =>
    itemType.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Edit
  const itemTypesForm = ItemTypesForm.edit(itemTypes);
  const {form, handleSubmit, submitting} = useUpsertItemTypesMutation({
    itemTypesForm,
    onSuccess: () => {
      form.resetForm();
      refetch();
      setIsEdit(false);
    },
    onError: (errors) => console.log(errors),
  });
  const field = 'itemTypesForm.itemTypeForms';
  const editColumnDefinitions = getEditColumnDefinitions({
    inventoryLibrary,
    itemTypeKind,
    form,
    field,
    scrollToBottom: () =>
      scrollToBottom({virtualizedListRef, itemCount: _.get(form.values, field).length}),
    bulkSelectedFormIndices,
    setBulkSelectedFormIndices,
  });
  const tableWidth = '1156px';
  return (
    <InventoryLibraryBulkUpdateContents
      ViewComponent={InventoryLibraryBulkUpdateContents.ViewTable}
      viewComponentProps={{
        columnDefinitions: viewColumnDefinitions,
        tableItems: filteredItemTypes,
        emptyStateText,
        tableWidth,
      }}
      EditComponent={InventoryLibraryBulkUpdateContents.EditTable}
      editHeaderProps={{
        field,
        bulkSelectedFormIndices,
        setBulkSelectedFormIndices,
      }}
      editComponentProps={{
        form,
        field,
        itemKey: 'itemTypeId',
        columnDefinitions: editColumnDefinitions,
        tableWidth,
        virtualizedListRef,
      }}
      isEdit={isEdit}
      setIsEdit={(isEdit) => {
        setIsEdit(isEdit);
        setSearchTerm('');
      }}
      title={title}
      csvActions={csvActions}
      setSearchTerm={setSearchTerm}
      form={form}
      handleSubmit={handleSubmit}
      submitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CartonAndItemsContent.fragment = gql`
  fragment CartonAndItemsContent_InventoryLibrary on InventoryLibrary {
    id
    organization {
      id
      settings {
        id
        defaultDensityFactor
      }
      features {
        isEnabledInventoryPriceField: isEnabled(feature: "INVENTORY_PRICE_FIELD")
      }
    }
    categories {
      id
      name
    }
  }

  ${ItemTypesForm.edit.fragment}
  fragment CartonAndItemsContent_ItemType on ItemType {
    id
    name
    weight
    volume
    price
    isDerivedWeight
    itemTypeCategories {
      id
      category {
        id
        name
      }
    }
    ...ItemTypesForm_edit
  }
`;

export default CartonAndItemsContent;
