// Supermove
import {useReducer} from '@supermove/hooks';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import ContainerLocationKind from '@shared/modules/Storage/enums/ContainerLocationKind';

const SET_LOCATION_KIND = 'SET_LOCATION_KIND';
const SET_WAREHOUSE = 'SET_WAREHOUSE';
const SET_CONTAINER_TYPES = 'SET_CONTAINER_TYPES';
const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
const SET_CUSTOM_LOCATION_SEARCH_QUERY = 'SET_CUSTOM_LOCATION_SEARCH_QUERY';
const SET_CAPACITY_KINDS = 'SET_CAPACITY_KINDS';
const SET_WAREHOUSE_COORDINATE = 'SET_WAREHOUSE_COORDINATE';
const SET_PAGINATION = 'SET_PAGINATION';
const SET_SLUGS = 'SET_SLUGS';

const RESET_FILTERS = 'RESET_FILTERS';

const reducer = (state: any, action: any) => {
  // We reset to page 1 on any filter change
  const stateWithPaginationReset = {...state, pagination: PaginationBar.DEFAULT_PAGINATION};

  switch (action.type) {
    case SET_LOCATION_KIND:
      if (state.locationKind === action.payload) {
        return stateWithPaginationReset;
      }
      // If locationKind has changed, we need to reset the coordinates/custom location filters
      return {
        ...stateWithPaginationReset,
        warehouseCoordinateX: '',
        warehouseCoordinateY: '',
        warehouseCoordinateZ: '',
        customLocationSearchQuery: '',
        locationKind: action.payload,
      };
    case SET_WAREHOUSE:
      return {...stateWithPaginationReset, warehouseIds: action.payload ? [action.payload] : null};
    case SET_CONTAINER_TYPES:
      return {...stateWithPaginationReset, containerTypeIds: action.payload};
    case SET_SEARCH_QUERY:
      return {...stateWithPaginationReset, searchQuery: action.payload};
    case SET_CAPACITY_KINDS:
      return {...stateWithPaginationReset, capacityKinds: action.payload};
    case SET_CUSTOM_LOCATION_SEARCH_QUERY:
      return {...stateWithPaginationReset, customLocationSearchQuery: action.payload};
    case SET_WAREHOUSE_COORDINATE:
      switch (action.coordinateType) {
        case ContainerLocationKind.WAREHOUSE_COORDINATE_X:
          return {...stateWithPaginationReset, warehouseCoordinateX: action.payload};
        case ContainerLocationKind.WAREHOUSE_COORDINATE_Y:
          return {...stateWithPaginationReset, warehouseCoordinateY: action.payload};
        case ContainerLocationKind.WAREHOUSE_COORDINATE_Z:
          return {...stateWithPaginationReset, warehouseCoordinateZ: action.payload};
        default:
          return stateWithPaginationReset;
      }
    case SET_PAGINATION:
      return {
        ...stateWithPaginationReset,
        pagination: {
          page: action.payload,
          resultsPerPage: PaginationBar.DEFAULT_PAGINATION.resultsPerPage,
        },
      };
    case SET_SLUGS:
      return {
        ...stateWithPaginationReset,
        slugs: action.payload,
        warehouseIds: null,
      };
    case RESET_FILTERS:
      // NOTE(cooper): This only resets filters in the popover
      return {
        ...stateWithPaginationReset,
        containerTypeIds: null,
        warehouseCoordinateX: '',
        warehouseCoordinateY: '',
        warehouseCoordinateZ: '',
        customLocationSearchQuery: '',
      };
    default:
      return stateWithPaginationReset;
  }
};

const useFilteredContainersReducer = (initialFilters: any) => {
  const [filters, dispatch] = useReducer(reducer, initialFilters);

  return {
    filters,
    dispatch,
    actions: {
      SET_LOCATION_KIND,
      SET_WAREHOUSE,
      SET_CONTAINER_TYPES,
      SET_SEARCH_QUERY,
      SET_CUSTOM_LOCATION_SEARCH_QUERY,
      SET_CAPACITY_KINDS,
      SET_WAREHOUSE_COORDINATE,
      SET_SLUGS,
      SET_PAGINATION,
      RESET_FILTERS,
    },
  };
};

export default useFilteredContainersReducer;
