// Libraries
import React from 'react';

// App
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job, Project} from '@supermove/models';
import {fontWeight} from '@supermove/styles';

const Container = Styled.View`
  align-items: center;
  justify-content: center;
  width: 180px;
  padding-vertical: 2px;
  border-width: 2px;
  border-color: ${(props) => props.color};
  border-radius: 30px;
`;

const Section = Styled.View`
`;

const Primary = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const Secondary = Styled.H8`
  font-size: 11px;
  color: ${(props) => props.color};
`;

const getPrimaryStatusText = ({job}) => {
  const salesStatus = Project.getSalesStatus(job.project);
  const salesStatusText = Project.getSalesStatusText(job.project);
  const operationsStatusText = Job.getOperationsStatusText(job);

  if (job.isCancelled) {
    return 'Cancelled';
  }

  switch (salesStatus) {
    case 'LEAD':
    case 'HOLD':
      return salesStatusText;
    case 'BOOKED':
    default:
      return operationsStatusText;
  }
};

const JobStatusBadge = ({job, style}) => {
  const color = Job.getCalendarPrimaryStatusColor(job);

  return (
    <Container color={color} style={style}>
      <Section>
        <Primary color={color}>{getPrimaryStatusText({job})}</Primary>
      </Section>
      <Section>
        {!!Job.getCalendarSecondaryStatusText(job) && (
          <Secondary color={color}>{Job.getCalendarSecondaryStatusText(job)}</Secondary>
        )}
        {!!Job.getCalendarSecondaryStatusIcon(job) && (
          <Icon
            color={color}
            size={Icon.Sizes.Small}
            source={Job.getCalendarSecondaryStatusIcon(job)}
          />
        )}
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobStatusBadge.fragment = gql`
  ${Job.getCalendarPrimaryStatusColor.fragment}
  ${Job.getCalendarPrimaryStatusText.fragment}
  ${Job.getCalendarSecondaryStatusText.fragment}
  ${Job.getOperationsStatusText.fragment}
  ${Job.getCalendarSecondaryStatusIcon.fragment}
  ${Project.getSalesStatus.fragment}
  ${Project.getSalesStatusText.fragment}

  fragment JobStatusBadge on Job {
    id
    isCancelled
    project {
      id
      ...Project_getSalesStatus
      ...Project_getSalesStatusText
    }
    ...Job_getCalendarPrimaryStatusColor
    ...Job_getCalendarPrimaryStatusText
    ...Job_getCalendarSecondaryStatusIcon
    ...Job_getCalendarSecondaryStatusText
    ...Job_getOperationsStatusText
  }
`;

export default JobStatusBadge;
