const _new = ({id, booleanValue}: any) => ({
  id,
  booleanValue,
});

const toForm = ({id, booleanValue}: any) => ({
  id,
  booleanValue,
});

const toMutation = ({id, booleanValue}: any) => ({
  id,
  booleanValue: !booleanValue,
});

const ToggleBooleanPropertyForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ToggleBooleanPropertyForm;
