// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, ScrollView, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DocumentTemplateV2Form from '@shared/modules/Document/forms/DocumentTemplateV2Form';
import useDeleteDocumentTemplateV2Mutation from '@shared/modules/Document/hooks/useDeleteDocumentTemplateV2Mutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const PaddedLoadingIndicator = () => {
  return (
    <React.Fragment>
      <Space height={32} />
      <PageLoadingIndicator />
      <Space height={32} />
    </React.Fragment>
  );
};

const Text = Styled.Text`
  ${Typography.Body3}
  color: ${colors.blue.interactive};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const StyledScrollView = Styled(ScrollView)`
  max-height: 400px;
  padding: 8px;
  background-color: ${colors.gray.background}
  border-radius: 4px;
`;

const Button = Styled.ButtonV2`
`;

// getListItemText and getListItemUrl are functions that take in a listItem and return a string
const ListItems = ({listItems, getListItemText, getListItemUrl}) => {
  const {navigator} = useNavigationDOM();
  return (
    <StyledScrollView>
      {listItems.map((listItem) => (
        <Row key={listItem.id}>
          <Button onPress={() => navigator.pushNewTab(getListItemUrl(listItem))}>
            <Text>{getListItemText(listItem)}</Text>
          </Button>
        </Row>
      ))}
    </StyledScrollView>
  );
};

const DeleteDocumentTemplateModalContent = ({
  deleteDocumentTemplateModal,
  userId,
  documentTemplate,
  setIsModalOpen,
  refetch,
  handleClose,
}) => {
  const {navigator} = useNavigationDOM();
  const deleteDocumentTemplateToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Document template deleted',
  });
  const documentTemplateV2Form = DocumentTemplateV2Form.edit(documentTemplate, {userId});
  const {handleSubmit} = useDeleteDocumentTemplateV2Mutation({
    documentTemplateV2Form,
    onSuccess: () => {
      handleClose({deleteDocumentTemplateModal, setIsModalOpen});
      refetch();
      navigator.push(`/settings/documents/document-templates`);
      deleteDocumentTemplateToast.handleToast();
    },
    onError: (errors) => console.log({errors}),
  });
  const JOB_TYPE_DISPLAY_LIMIT = 5;
  return (
    <React.Fragment>
      <SmallModal.HeaderIcon
        source={Icon.Trash}
        color={colors.red.warning}
        label={`Delete "${documentTemplate.name}"?`}
        style={{alignItems: 'center'}}
      />
      <Space height={8} />
      {documentTemplate.jobTypesWithDocumentTemplateOnJobTypeStep.length === 0 ? (
        <SmallModal.Text>
          {'This document template is not currently used and is safe to delete.'}
        </SmallModal.Text>
      ) : (
        <React.Fragment>
          <SmallModal.Text>
            {'This template will be removed from the following job type(s):'}
          </SmallModal.Text>
          <Space height={8} />
          {documentTemplate.jobTypesWithDocumentTemplateOnJobTypeStep
            .slice(0, JOB_TYPE_DISPLAY_LIMIT)
            .map((jobType) => {
              return (
                <SmallModal.Text
                  key={jobType.id}
                >{`${jobType.projectType.name}: ${jobType.name}`}</SmallModal.Text>
              );
            })}
          {documentTemplate.jobTypesWithDocumentTemplateOnJobTypeStep.length >
            JOB_TYPE_DISPLAY_LIMIT && (
            <React.Fragment>
              <Space height={8} />
              <SmallModal.Text>{`And ${
                documentTemplate.jobTypesWithDocumentTemplateOnJobTypeStep.length -
                JOB_TYPE_DISPLAY_LIMIT
              } more job types...`}</SmallModal.Text>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <Space height={32} />
      <SmallModal.Footer>
        <SmallModal.Button
          onPress={() => handleClose({deleteDocumentTemplateModal, setIsModalOpen})}
        >
          Cancel
        </SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button color={colors.red.warning} onPress={handleSubmit}>
          Delete
        </SmallModal.Button>
      </SmallModal.Footer>
    </React.Fragment>
  );
};

const CannotDeleteDocumentTemplateContent = ({documentTemplate, handleClose}) => {
  return (
    <React.Fragment>
      <SmallModal.BoldText>Unable To Delete</SmallModal.BoldText>
      <Space height={8} />
      <SmallModal.Text>
        The document template is connected to quote and confirmation steps and cannot be deleted.
      </SmallModal.Text>
      <Space height={8} />
      <ListItems
        listItems={documentTemplate.moveConfirmationSteps}
        getListItemText={(confirmationStep) => confirmationStep.projectType.name}
        getListItemUrl={(confirmationStep) =>
          `/settings/projects/project-types/${confirmationStep.projectType.uuid}/quotes-and-confirmations`
        }
      />
      <SmallModal.Footer>
        <SmallModal.Button color={colors.blue.interactive} onPress={handleClose}>
          Confirm
        </SmallModal.Button>
      </SmallModal.Footer>
    </React.Fragment>
  );
};

const DeleteDocumentTemplateModal = ({
  documentTemplateUuid,
  deleteDocumentTemplateModal,
  userId,
  setIsModalOpen,
  refetch,
}) => {
  const {data, loading} = useQuery(DeleteDocumentTemplateModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: documentTemplateUuid,
    },
    skip: !deleteDocumentTemplateModal.isOpen,
  });
  const handleClose = ({deleteDocumentTemplateModal, setIsModalOpen}) => {
    deleteDocumentTemplateModal.handleClose();
    setIsModalOpen(false);
  };

  return (
    <SmallModal
      handlePressOutside={() => handleClose({deleteDocumentTemplateModal, setIsModalOpen})}
      isOpen={deleteDocumentTemplateModal.isOpen}
      style={{textAlign: 'center'}}
    >
      <Loading loading={loading} as={PaddedLoadingIndicator}>
        {() => {
          const hasConfirmationSteps = data.documentTemplate.moveConfirmationSteps.length > 0;
          if (hasConfirmationSteps) {
            return (
              <CannotDeleteDocumentTemplateContent
                documentTemplate={data.documentTemplate}
                handleClose={deleteDocumentTemplateModal.handleClose}
              />
            );
          }
          return (
            <DeleteDocumentTemplateModalContent
              documentTemplate={data.documentTemplate}
              deleteDocumentTemplateModal={deleteDocumentTemplateModal}
              userId={userId}
              setIsModalOpen={setIsModalOpen}
              refetch={refetch}
              handleClose={handleClose}
            />
          );
        }}
      </Loading>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteDocumentTemplateModal.query = gql`
  ${DocumentTemplateV2Form.edit.fragment}

  query DeleteDocumentTemplateModal($uuid: String!) {
    ${gql.query}
    documentTemplate(uuid: $uuid) {
      id
      name
      moveConfirmationSteps {
        id
        projectType {
          id
          uuid
          name
        }
      }
      jobTypesWithDocumentTemplateOnJobTypeStep {
        id
        name
        projectType {
          id
          name
        }
      }
      ...DocumentTemplateV2Form_edit 
    }
  }
`;

export default DeleteDocumentTemplateModal;
