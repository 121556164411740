// Libraries
import React from 'react';

// Supermove
import {Styled, Icon} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';
import {PayrollReport, CompensationReport} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';

const IconButton = Styled.ButtonV2`
  width: 16px;
  height: 16px;
  align-items: center;
`;

const CompensationReportActions = ({
  compensationReportActionsPopover,
  organizationSlug,
  compensationReport,
  handleExport,
}) => {
  const {navigator} = useNavigationDOM();

  const payrollRange = PayrollReport.getDateRange(compensationReport.payrollReport, {
    isShortened: true,
  });
  const actions = [
    {
      text: 'Print report',
      onPress: () =>
        navigator.pushNewTab(
          `/0/${organizationSlug}/compensation-reports/${compensationReport.uuid}/print`,
        ),
    },
    {
      text: 'Download CSV',
      onPress: () =>
        handleExport({
          filename: `${compensationReport.identifier}_${compensationReport.user.fullName}_${payrollRange}`,
          rows: CompensationReport.constructCSVRowsForCompensationReport(compensationReport),
        }),
    },
  ];
  return (
    <React.Fragment>
      <ActionMenuPopover
        popover={compensationReportActionsPopover}
        placement={ActionMenuPopover.Position.LeftStart}
        width={140}
        actions={actions}
      >
        <IconButton onPress={compensationReportActionsPopover.handleOpen}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </IconButton>
      </ActionMenuPopover>
    </React.Fragment>
  );
};

export default CompensationReportActions;
