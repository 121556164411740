const JOB = 'JOB';
const PROJECT = 'PROJECT';
const INVOICE = 'INVOICE';

const getCreatableKinds = () => {
  return {JOB, PROJECT};
};

const VariableKind = {
  JOB,
  PROJECT,
  INVOICE,

  VALUES: [PROJECT, JOB, INVOICE],

  getCreatableKinds,
};

export default VariableKind;
