// Supermove
import {Currency} from '@supermove/utils';

// App
import LineItem from './LineItem';
import Payment from './Payment';

const ReportBillInput = {
  toForm: ({
    billId,
    title,
    kind,
    salesTaxAmount,
    tipAmount,
    discount,
    subtotal,
    total,
    balance,
    billModifiers,
    lineItems,
    payments,
  }: any) => ({
    billId,
    title,
    kind,
    salesTaxAmount: salesTaxAmount ? Currency.toForm(salesTaxAmount) : '',
    tipAmount: tipAmount ? Currency.toForm(tipAmount) : '',
    discount,
    subtotal: Currency.toForm(subtotal),
    total: Currency.toForm(total),
    balance: Currency.toForm(balance),
    billModifiers,
    lineItems: lineItems.map((lineItem: any) => LineItem.toForm(lineItem)),
    payments: payments.map((payment: any) => Payment.toForm(payment)),
  }),

  /*
  Note that we do not send up discount, subtotal, total, or balance
  since these fields are derived by the other fields in the server.
  */
  toMutation: ({
    billId,
    title,
    kind,
    discountPercentage,
    salesTaxAmount,
    tipAmount,
    billModifiers,
    lineItems,
    payments,
  }: any) => ({
    billId,
    title,
    kind,
    discountPercentage,
    salesTaxAmount: Currency.toMutation(salesTaxAmount || 0),
    tipAmount: Currency.toMutation(tipAmount || 0),
    lineItemInputs: lineItems.map((lineItem: any) => LineItem.toMutation(lineItem)),
    paymentInputs: payments.map((payment: any) => Payment.toMutation(payment)),

    // This is here so that we can keep the existing BillModifiers that are on the ReportBill.
    billModifierForms: billModifiers.map((billModifier: any) => {
      return {
        kind: billModifier.kind,
        name: billModifier.name,
        description: billModifier.description,
        percentage: billModifier.percentage,
        amount: billModifier.amount,
      };
    }),
  }),
};

export default ReportBillInput;
