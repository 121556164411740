// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import UpdateTruckForm from 'modules/Organization/Settings/Dispatch/Trucks/components/UpdateTruckForm';

const UpdateTruckModal = ({truck, isOpen, onClose, refetch}) => {
  return (
    <SmallModal isOpen={isOpen}>
      <SmallModal.BoldText>{`Update Truck`}</SmallModal.BoldText>
      <Space height={16} />
      <SmallModal.Text>{`Update your truck details.`}</SmallModal.Text>
      <Space height={16} />
      <UpdateTruckForm handleClose={onClose} refetch={refetch} truck={truck} />
    </SmallModal>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
UpdateTruckModal.propTypes = {
  truck: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

UpdateTruckModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateTruckModal.fragment = gql`
  ${UpdateTruckForm.fragment}
  fragment UpdateTruckModal on Truck {
    id
    ...UpdateTruckForm
  }
`;

export default UpdateTruckModal;
