// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {User} from '@supermove/models';
import {colors} from '@supermove/styles';

// Components
import Avatar from './Avatar';

const Container = Styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid  ${(props) => props.color || colors.blue.interactive};
  border-radius: ${(props) => props.size / 2}px;
`;

const Text = Styled.H7`
  margin-left: 5px;
  color: ${(props) => props.color || colors.blue.interactive};
`;

const FullUserAvatar = ({color, size, user}) => (
  <Container color={color} size={size}>
    <Avatar color={color} size={size} text={User.getInitials(user)} />
    <Text color={color}>{user.firstName}</Text>
  </Container>
);

export default FullUserAvatar;
