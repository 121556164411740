// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import AssignUserToJobMutation from './AssignUserToJobMutation';

const AssignUserToJobButton = ({children, job, user, onSuccess}) => (
  <AssignUserToJobMutation job={job} user={user} onSuccess={onSuccess}>
    {({loading, handleSubmit, ...props}) => children({loading, handleSubmit, ...props})}
  </AssignUserToJobMutation>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
AssignUserToJobButton.propTypes = {
  job: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

AssignUserToJobButton.defaultProps = {};

export default AssignUserToJobButton;
