// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, usePropUpdatedAt, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import Button from '@shared/design/components/Button';
import Banner from 'modules/App/components/Banner';
import Page from 'modules/App/components/Page';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import JobNotFoundPage from 'modules/Job/components/JobNotFoundPage';
import JobPageSidebar from 'modules/Job/components/JobPageSidebar';

const Container = Styled.View`
  flex: 1;
`;

const ProjectPageV2Banner = ({job}) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  return job && !responsive.mobile ? (
    <Banner
      color={colors.blue.accentDark}
      text={'Experience the latest version of our project page!'}
      responsive={responsive}
    >
      <Space width={16} />
      <Button
        isSmall
        text={'Try the New Version'}
        onPress={() =>
          navigator.push(`/projects/${job.project.uuid}/view?section=Jobs&jobUuid=${job.uuid}`)
        }
      />
    </Banner>
  ) : null;
};

const JobPageContent = ({children, navigation, ...props}) => {
  const {isOpen, handleClose, handleOpen} = useModal();
  const {updatedAt: loadingUpdatedAt} = usePropUpdatedAt(props.loading);

  return (
    <JobPageSidebar
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      refetch={props.refetch}
    >
      <Container>
        <ProjectPageV2Banner job={props?.data?.job} />
        {navigation({handleOpenSidebar: handleOpen, ...props})}
        <Loading as={PageLoadingIndicator} loading={props.loading}>
          {() => (props.data.job ? children({loadingUpdatedAt, ...props}) : <JobNotFoundPage />)}
        </Loading>
      </Container>
    </JobPageSidebar>
  );
};

const JobPage = ({fetchPolicy, variables, query, navigation, children, pollInterval}) => {
  return (
    <Page fetchPolicy={fetchPolicy} query={query} variables={variables} pollInterval={pollInterval}>
      {(props) => <JobPageContent {...props} navigation={navigation} children={children} />}
    </Page>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobPage.propTypes = {};

JobPage.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobPage.fragment = gql`
  fragment JobPage on Job {
    id
    uuid
    project {
      id
      uuid
    }
  }
`;

export default JobPage;
