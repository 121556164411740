// Libararies
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import AttachmentPageContent from 'modules/Project/Attachments/components/AttachmentPageContent';

const ProjectAttachmentPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(ProjectAttachmentPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      attachmentUuid: params.attachmentUuid,
    },
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={''}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <SidebarPageV2 selected={''}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <AttachmentPageContent
              attachment={data.attachment}
              isEdit={params.status === 'edit'}
              refetch={refetch}
              projectUuid={params.projectUuid}
            />
          );
        }}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectAttachmentPage.query = gql`
  ${AttachmentPageContent.fragment}
  query ProjectAttachmentPage($attachmentUuid: String!) {
    attachment(uuid: $attachmentUuid) {
      id
      ...AttachmentPageContent
    }
  }
`;

export default ProjectAttachmentPage;
