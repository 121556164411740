// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectTypeDayForm from '@shared/modules/Dispatch/forms/ProjectTypeDayForm';

const useUpdateProjectTypeDayMutation = ({projectTypeDayForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      projectTypeDayForm: ProjectTypeDayForm.toForm(projectTypeDayForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectTypeDayMutation.mutation,
    variables: {
      projectTypeDayForm: ProjectTypeDayForm.toMutation(form.values.projectTypeDayForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateProjectTypeDayMutation.mutation = gql`
  mutation useProjectTypeDayMutation($projectTypeDayForm: ProjectTypeDayForm!) {
    response: updateProjectTypeDay(projectTypeDayForm: $projectTypeDayForm) {
      ${gql.errors}
      projectTypeDay {
        id
      }
    }
  }
`;

export default useUpdateProjectTypeDayMutation;
