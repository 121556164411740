// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import BillingDocumentProjectBills from '@shared/modules/Document/Billing/components/BillingDocumentProjectBills';
import BillingDocumentProjectPayments from '@shared/modules/Document/Billing/components/BillingDocumentProjectPayments';

const PlaceholderContainer = Styled.View`
  height: 180px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
`;

const PlaceholderText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const DocumentV2BillingInformationContent = ({
  project,
  isProposal,
  hasAllBills,
  hasBalance,
}: any) => {
  const {features} = project.organization;
  return (
    <React.Fragment>
      <BillingDocumentProjectBills
        isProposal={isProposal}
        hasAllBills={hasAllBills}
        hasQuantity={isProposal ? features.isEnabledConfirmationShowQuantity : true}
        hasTotal={isProposal ? features.isEnabledConfirmationShowTotal : true}
        hasGrandTotal={isProposal ? features.isEnabledConfirmationShowGrandTotal : true}
        project={project}
      />
      {(!isProposal || project.activeJobsAggregateBill.hasPayments) && (
        <React.Fragment>
          <Space height={10} />
          <BillingDocumentProjectPayments
            project={project}
            hasAllBills={hasAllBills}
            hasBalance={hasBalance}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const DocumentV2BillingInformationPreview = ({isProposal, hasAllBills, hasBalance}: any) => {
  return (
    <PlaceholderContainer>
      <PlaceholderText>Billing Information</PlaceholderText>
    </PlaceholderContainer>
  );
};

const DocumentV2BillingInformationQueried = ({
  project,
  isProposal,
  hasAllBills,
  hasBalance,
}: any) => {
  return (
    <DocumentV2BillingInformationContent
      project={project}
      isProposal={isProposal}
      hasAllBills={hasAllBills}
      hasBalance={hasBalance}
    />
  );
};

type OwnProjectDocumentV2BillingInformationProps = {
  isPreview?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'ProjectDocumentV2BillingInformationPro... Remove this comment to see the full error message
type ProjectDocumentV2BillingInformationProps = OwnProjectDocumentV2BillingInformationProps &
  typeof ProjectDocumentV2BillingInformation.defaultProps;

// @ts-expect-error TS(7022): 'ProjectDocumentV2BillingInformation' implicitly h... Remove this comment to see the full error message
const ProjectDocumentV2BillingInformation = ({
  isPreview,
  project,
  isProposal,
  hasAllBills,
  hasBalance,
}: ProjectDocumentV2BillingInformationProps) => {
  return (
    <React.Fragment>
      <Space height={5} />
      {isPreview ? (
        <DocumentV2BillingInformationPreview />
      ) : (
        <DocumentV2BillingInformationQueried
          project={project}
          isProposal={isProposal}
          hasAllBills={hasAllBills}
          hasBalance={hasBalance}
        />
      )}
    </React.Fragment>
  );
};

ProjectDocumentV2BillingInformation.defaultProps = {
  isPreview: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectDocumentV2BillingInformation.fragment = gql`
  ${BillingDocumentProjectBills.fragment}
  ${BillingDocumentProjectPayments.fragment}
  fragment ProjectDocumentV2BillingInformation on Project {
    id
    organization {
      id
      features {
        isEnabledConfirmationShowQuantity: isEnabled(feature: "CONFIRMATION_SHOW_QUANTITY")
        isEnabledConfirmationShowTotal: isEnabled(feature: "CONFIRMATION_SHOW_TOTAL")
        isEnabledConfirmationShowGrandTotal: isEnabled(feature: "CONFIRMATION_SHOW_GRAND_TOTAL")
      }
    }
    activeJobsAggregateBill {
      hasPayments
    }
    ...BillingDocumentProjectBills
    ...BillingDocumentProjectPayments
  }
`;

export default ProjectDocumentV2BillingInformation;
