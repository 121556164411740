import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import ThreadEmailV2 from 'modules/Communication/Email/components/ThreadEmailV2';

const Container = Styled.View`
  background-color: ${colors.white};
`;

const CustomerEmailThreadEmailsV2 = ({selectedThread, setSelectedThread}) => {
  return (
    <Container>
      {selectedThread.allEmails.map((email, index) => (
        <ThreadEmailV2 email={email} key={index} />
      ))}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerEmailThreadEmailsV2.fragment = gql`
  ${ThreadEmailV2.fragment}

  fragment CustomerEmailThreadEmailsV2 on Thread {
    id
    allEmails {
      id
      ...ThreadEmailV2
    }
  }
`;

export default CustomerEmailThreadEmailsV2;
