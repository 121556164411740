// Libraries
import React from 'react';

// Supermove
import {DateInput, Icon, Space, Styled} from '@supermove/components';
import {useMountEffect, useNavigationDOM, useRef} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const HeaderContainer = Styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CalendarDayText = Styled.Text`
  min-width: 200px;
  ${Typography.Label1}
  color: ${colors.gray.primary};
  text-align: center;
`;

const CalendarButtonsButton = Styled.ButtonV2`
  width: 36px;
  height: 36px;
  border: 1px;
  border-color: ${colors.gray.border}
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const DateButton = Styled.ButtonV2`
  width: 36px;
  height: 36px;
  border: 1px solid;
  border-color: ${colors.gray.border}
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

const CalendarDateButton = ({selectedDate}) => {
  const ref = useRef();
  const {params, navigator} = useNavigationDOM();
  const CustomDateButton = React.forwardRef(({onClick}, ref) => (
    <DateButton onPress={onClick}>
      <Icon color={colors.gray.secondary} size={Icon.Sizes.Medium} source={Icon.Calendar} />
    </DateButton>
  ));

  return (
    <DateInput
      customInput={<CustomDateButton ref={ref} />}
      setFieldValue={(name, date) => {
        const setDate = Datetime.toDate(Datetime.fromDate(date));
        return navigator.push(
          `/dispatch/long-distance/trips/${params.tripUuid}/day?date=${setDate}`,
        );
      }}
      offset={'-34px, 0px'}
      value={Datetime.fromDate(selectedDate)}
    />
  );
};

const TripDayViewDateNavigation = () => {
  const {params, navigator} = useNavigationDOM();
  const {tripUuid} = params;
  const selectedDate = params.date || Datetime.toMutationDate(Datetime.today);
  const selectedDateMoment = Datetime.fromDate(selectedDate);
  const displayDate = Datetime.convertToDisplayDate(selectedDate, Datetime.DISPLAY_FULL_DATE);

  useMountEffect(() => {
    if (!params.date) {
      navigator.replace(`/dispatch/long-distance/trips/${tripUuid}/day?date=${selectedDate}`);
    }
  });

  return (
    <HeaderContainer>
      <CalendarButtonsButton
        onPress={() => {
          const previousDay = Datetime.previousDay(selectedDateMoment);
          return navigator.push(
            `/dispatch/long-distance/trips/${tripUuid}/day?date=${previousDay}`,
          );
        }}
      >
        <Icon color={colors.gray.secondary} size={Icon.Sizes.Medium} source={Icon.ChevronLeft} />
      </CalendarButtonsButton>
      <Space width={12} />
      <CalendarDayText>{displayDate}</CalendarDayText>
      <Space width={12} />
      <CalendarButtonsButton
        onPress={() => {
          const nextDay = Datetime.nextDay(selectedDateMoment);
          return navigator.push(`/dispatch/long-distance/trips/${tripUuid}/day?date=${nextDay}`);
        }}
      >
        <Icon color={colors.gray.secondary} size={Icon.Sizes.Medium} source={Icon.ChevronRight} />
      </CalendarButtonsButton>
      <Space width={4} />
      <CalendarDateButton selectedDate={selectedDate} />
    </HeaderContainer>
  );
};

export default TripDayViewDateNavigation;
