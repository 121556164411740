// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {useModal, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import FieldInput from '@shared/design/components/Field/FieldInput';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteProjectTypeRoomTypeMutation from '@shared/modules/Inventory/hooks/useDeleteProjectTypeRoomTypeMutation';
import useUpsertProjectTypeRoomTypeMutation from '@shared/modules/Inventory/hooks/useUpsertProjectTypeRoomTypeMutation';

const ItemContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 12px 16px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  z-index: ${(props) => 100 - props.index};
  flex: 1;
`;

const SurveyMethodText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.black};
`;

const ActionButton = Styled.ButtonV2`
  min-width: 16px;
  align-items: center;
`;

const IconButton = Styled.ButtonV2`
`;

const Container = Styled.View`
  flex-direction: row;
  flex: 1;
  /* Must use padding here to get spacing between components in drag and drop list */
  /* Drag and drop does some weird stuff with the Space component so it does not show up properly */
  padding-vertical: 4px;
`;

const ActionContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const EditProjectTypeRoomTypeItem = ({
  projectTypeRoomTypeForm,
  index,
  refetch,
  handleCloseEdit,
}) => {
  const {form, handleSubmit, submitting} = useUpsertProjectTypeRoomTypeMutation({
    projectTypeRoomTypeForm,
    onSuccess: ({projectTypeRoomType}) => {
      refetch();
      handleCloseEdit({projectTypeRoomTypeId: _.get(projectTypeRoomType, 'id')});
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <Container>
      <ItemContainer index={index}>
        <FieldInput
          {...form}
          name={'projectTypeRoomTypeForm.name'}
          input={{
            autoFocus: true,
            placeholder: 'Enter room name',
            setFieldValue: form.setFieldValue,
            style: {
              width: '100%',
            },
          }}
          handleEnter={handleSubmit}
          style={{flex: 1}}
        />
        <Space width={24} />
        <ActionContainer>
          <IconButton onPress={handleSubmit} disabled={submitting}>
            <Icon source={Icon.Check} color={colors.blue.interactive} size={16} />
          </IconButton>
          <Space width={12} />
          <IconButton
            onPress={() =>
              handleCloseEdit({
                projectTypeRoomTypeId: _.get(projectTypeRoomTypeForm, 'projectTypeRoomTypeId'),
              })
            }
            disabled={submitting}
          >
            <Icon source={Icon.Times} color={colors.gray.secondary} size={16} />
          </IconButton>
          <Space width={3} />
        </ActionContainer>
      </ItemContainer>
    </Container>
  );
};

const EllipseButton = ({isDisabled, projectTypeRoomTypeForm, refetch}) => {
  const actionsPopover = usePopover();
  const deleteProjectTypeRoomTypeModal = useModal({name: 'Delete Project Type Room Type Modal'});
  const {handleSubmit, submitting} = useDeleteProjectTypeRoomTypeMutation({
    projectTypeRoomTypeForm,
    onSuccess: () => {
      refetch();
      deleteProjectTypeRoomTypeModal.handleClose();
    },
    onError: (errors) => console.log(errors),
  });
  return (
    <React.Fragment>
      <Popover.Content innerRef={actionsPopover.ref}>
        <ActionButton disabled={isDisabled} onPress={actionsPopover.handleToggle}>
          <Icon
            source={Icon.EllipsisV}
            color={isDisabled ? colors.gray.disabled : colors.gray.secondary}
            size={16}
          />
        </ActionButton>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={actionsPopover.isOpen}
        handleOpen={actionsPopover.handleOpen}
        handleClose={actionsPopover.handleClose}
        reference={actionsPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          handleClose={actionsPopover.handleClose}
          actions={[
            {
              text: 'Remove',
              onPress: deleteProjectTypeRoomTypeModal.handleOpen,
            },
          ]}
        />
      </Popover>
      <DeleteModal
        key={deleteProjectTypeRoomTypeModal.key}
        isOpen={deleteProjectTypeRoomTypeModal.isOpen}
        handleClose={deleteProjectTypeRoomTypeModal.handleClose}
        handleDelete={handleSubmit}
        isSubmitting={submitting}
        title={`Delete ${_.get(projectTypeRoomTypeForm, 'name', 'Room')}?`}
        subtitle={'Past surveys will not be affected.'}
      />
    </React.Fragment>
  );
};

const ProjectTypeRoomTypeItem = ({
  projectTypeRoomTypeForm,
  index,
  refetch,
  setIndexOfEdit,
  isEditingAnotherItem,
}) => {
  return (
    <Container>
      <ItemContainer index={index}>
        <SurveyMethodText>{projectTypeRoomTypeForm.name}</SurveyMethodText>
        <ActionContainer>
          <IconButton
            onPress={() => {
              setIndexOfEdit(index);
            }}
            disabled={isEditingAnotherItem}
            style={{paddingHorizontal: '8px'}}
          >
            <Icon
              source={Icon.Pen}
              color={isEditingAnotherItem ? colors.gray.disabled : colors.blue.interactive}
              size={16}
            />
          </IconButton>
          <EllipseButton
            projectTypeRoomTypeForm={projectTypeRoomTypeForm}
            refetch={refetch}
            isDisabled={isEditingAnotherItem}
          />
        </ActionContainer>
      </ItemContainer>
    </Container>
  );
};

ProjectTypeRoomTypeItem.Edit = EditProjectTypeRoomTypeItem;
export default ProjectTypeRoomTypeItem;
