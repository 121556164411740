import {ExecutionResult, MutationFunctionOptions} from 'react-apollo';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {MutationError, Form, MutationResponse} from '@supermove/hooks/src/forms/types';

// App
import PayengineCreditCardForm, {
  PayengineCreditCardFormInterface,
} from '@shared/modules/Payment/forms/PayengineCreditCardForm';

export type DeletePayengineCreditCardForm = {
  payengineCreditCardForm: PayengineCreditCardFormInterface;
};

export type DeletePayengineCreditCardResponse = {
  payengineCreditCard: {
    id: number;
  };
};
type GraphQLResponse = MutationResponse<DeletePayengineCreditCardResponse>;

type Args = {
  payengineCreditCardForm: PayengineCreditCardFormInterface;
  onSuccess: (response: DeletePayengineCreditCardResponse) => void;
  onError: (errors: MutationError[]) => void;
};

type Result = {
  form: Form<DeletePayengineCreditCardForm>;
  submitting: boolean | undefined;
  handleSubmit: (
    options?: MutationFunctionOptions<GraphQLResponse, DeletePayengineCreditCardForm>,
  ) => Promise<ExecutionResult<GraphQLResponse>>;
};

const useDeletePayengineCreditCardMutation = ({
  payengineCreditCardForm,
  onSuccess,
  onError,
}: Args): Result => {
  const form = useForm<DeletePayengineCreditCardForm>({
    initialValues: {
      payengineCreditCardForm,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeletePayengineCreditCardMutation.mutation,
    variables: {
      payengineCreditCardForm: PayengineCreditCardForm.toMutation(
        form.values.payengineCreditCardForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useDeletePayengineCreditCardMutation.mutation = gql`
  mutation useDeletePayengineCreditCardMutation(
    $payengineCreditCardForm: PayengineCreditCardForm!
  ) {
    response: deletePayengineCreditCard(
      payengineCreditCardForm: $payengineCreditCardForm,
    ) {
      ${gql.errors}
      payengineCreditCard {
        id
      }
    }
  }
`;

export default useDeletePayengineCreditCardMutation;
