// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useState, useEffect} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const CollapsibleContainer = Styled.View`
  border-color: ${colors.gray.border};
`;

const CollapsedRow = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 16px;
  padding-vertical: 8px;
`;

const CollapseBodyContainer = Styled.View`
  border-color: ${colors.gray.border};
  border-top-width: ${({isCollapsed}) => (isCollapsed ? '0px' : '1px')};
  z-index: -1;
`;

const SmallSectionHeader = Styled.Text`
  ${Typography.Label}
  color: ${({color}) => color};
`;

const CollapsibleSection = ({
  title,
  children,
  defaultCollapsed,
  style,
  handlePress,
  isCollapsedOverride,
  headerColor,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(
    isCollapsedOverride !== null ? isCollapsedOverride : defaultCollapsed,
  );
  useEffect(() => {
    setIsCollapsed(isCollapsedOverride !== null ? isCollapsedOverride : defaultCollapsed);
  }, [isCollapsedOverride, defaultCollapsed]);

  return (
    <CollapsibleContainer style={style}>
      <CollapsedRow
        onPress={() => {
          if (isCollapsedOverride === null) {
            setIsCollapsed(!isCollapsed);
          }
          handlePress(!isCollapsed);
        }}
      >
        <SmallSectionHeader color={headerColor}>{title}</SmallSectionHeader>
        <Space style={{flex: 1}} />
        <Icon
          source={isCollapsed ? Icon.ChevronRight : Icon.ChevronDown}
          color={headerColor || colors.black}
          size={12}
        />
      </CollapsedRow>
      <CollapseBodyContainer isCollapsed={isCollapsed}>
        {!isCollapsed && !_.isEmpty(children) && {...children}}
      </CollapseBodyContainer>
    </CollapsibleContainer>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
CollapsibleSection.propTypes = {
  title: PropTypes.string,
  defaultCollapsed: PropTypes.bool,
  style: PropTypes.object,
  handlePress: PropTypes.func,
  isCollapsedOverride: PropTypes.bool,
  headerColor: PropTypes.string,
};

CollapsibleSection.defaultProps = {
  title: '',
  defaultCollapsed: false,
  style: null,
  handlePress: () => {},
  isCollapsedOverride: null,
  headerColor: null,
};

export default CollapsibleSection;
