// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CapacityCalendarSlotDisplayKind from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSlotDisplayKind';

type SlotPriorityIndexType = number | null;
type SlotPositionType = string | null;

export interface CapacityCalendarSlotMetricFormType {
  metric: string | null;
  displayKind: string | null;
  slotPosition: SlotPositionType;
  slotPriorityIndex: SlotPriorityIndexType;
  isActive: boolean;
}

const getSlotPositionFromPriorityIndex = (
  slotPriorityIndex: SlotPriorityIndexType,
): SlotPositionType => {
  switch (slotPriorityIndex) {
    case 1:
      return 'TOP_LEFT';
    case 2:
      return 'BOTTOM_LEFT';
    default:
      return null;
  }
};

const _new = ({
  slotPriorityIndex,
}: {
  slotPriorityIndex: SlotPriorityIndexType;
}): CapacityCalendarSlotMetricFormType => {
  return {
    metric: null,
    displayKind: CapacityCalendarSlotDisplayKind.FRACTION,
    slotPosition: null,
    slotPriorityIndex,
    isActive: false,
  };
};

const edit = withFragment(
  (capacityCalendarSlotMetricForm: any): CapacityCalendarSlotMetricFormType => ({
    metric: capacityCalendarSlotMetricForm.metric,
    displayKind: capacityCalendarSlotMetricForm.displayKind,
    slotPosition: capacityCalendarSlotMetricForm.slotPosition,
    slotPriorityIndex: capacityCalendarSlotMetricForm.slotPriorityIndex,
    isActive: capacityCalendarSlotMetricForm.isActive,
  }),
  gql`
    fragment CapacityCalendarSlotMetricForm_edit on CapacityCalendarSlotMetric {
      id
      metric
      displayKind
      slotPosition
      slotPriorityIndex
      isActive
    }
  `,
);

const toForm = (capacityCalendarSlotMetric: any): CapacityCalendarSlotMetricFormType => {
  return {
    metric: capacityCalendarSlotMetric.metric,
    displayKind: capacityCalendarSlotMetric.displayKind,
    slotPosition: capacityCalendarSlotMetric.slotPosition,
    slotPriorityIndex: capacityCalendarSlotMetric.slotPriorityIndex,
    isActive: capacityCalendarSlotMetric.isActive,
  };
};

const toMutation = (capacityCalendarSlotMetricForm: CapacityCalendarSlotMetricFormType): any => {
  return {
    metric: capacityCalendarSlotMetricForm.metric,
    displayKind: capacityCalendarSlotMetricForm.displayKind,
    slotPosition: getSlotPositionFromPriorityIndex(
      capacityCalendarSlotMetricForm.slotPriorityIndex,
    ),
    slotPriorityIndex: capacityCalendarSlotMetricForm.slotPriorityIndex,
    isActive: capacityCalendarSlotMetricForm.isActive,
  };
};

const CapacityCalendarSlotMetricForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default CapacityCalendarSlotMetricForm;
