// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {useResponsive} from '@supermove/hooks';

// App
import TruckForm from '@shared/modules/Dispatch/forms/TruckForm';
import useCreateTruckMutation from '@shared/modules/Dispatch/hooks/useCreateTruckMutation';

import TruckFormFields from './TruckFormFields';

const CreateTruckForm = ({handleClose, refetch, viewer}) => {
  const responsive = useResponsive();
  const truckForm = TruckForm.new(viewer.viewingOrganization.id);
  const {form, submitting, handleSubmit} = useCreateTruckMutation({
    truckForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: () => {},
  });
  return (
    <TruckFormFields
      responsive={responsive}
      form={form}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    />
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
CreateTruckForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  viewer: PropTypes.object.isRequired,
};

CreateTruckForm.defaultProps = {};

export default CreateTruckForm;
