// Libraries
import React from 'react';

// App
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components
import {JobRequestItem} from 'modules/JobRequest/components';

const Container = Styled.View`
  margin-bottom: 120px;
`;

const JobRequest = Styled.View`
  margin-top: 10px;
`;

const EmptyContainer = Styled.View`
  align-items: center;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const getEmptyMessage = ({kind}) => {
  switch (kind) {
    case 'NEW':
      return 'There are no new move requests';
    case 'PENDING':
      return 'There are no pending move requests';
    case 'CANCELLED':
    default:
      return 'There are no deleted move requests';
  }
};

const JobRequestsListV2 = ({kind, jobRequests}) => (
  <Container>
    <FlatList
      data={jobRequests}
      keyExtractor={(jobRequest) => jobRequest.id}
      renderItem={({item: jobRequest, index}) => (
        <JobRequest>
          <JobRequestItem jobRequest={jobRequest} />
        </JobRequest>
      )}
      ListEmptyComponent={() => (
        <EmptyContainer>
          <EmptyMessage>{getEmptyMessage({kind})}</EmptyMessage>
        </EmptyContainer>
      )}
    />
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestsListV2.fragment = gql`
  ${JobRequestItem.fragment}

  fragment JobRequestsListV2 on JobRequest {
    id
    ...JobRequestItem
  }
`;

export default JobRequestsListV2;
