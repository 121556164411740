// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DequeueInvoiceForm from '@shared/modules/Integration/forms/DequeueInvoiceForm';

const useDequeueInvoiceMutation = ({dequeueInvoiceForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      dequeueInvoiceForm: DequeueInvoiceForm.toForm(dequeueInvoiceForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDequeueInvoiceMutation.mutation,
    variables: {
      dequeueInvoiceForm: DequeueInvoiceForm.toMutation(form.values.dequeueInvoiceForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDequeueInvoiceMutation.mutation = gql`
  mutation useDequeueInvoiceMutation($dequeueInvoiceForm: DequeueInvoiceForm!) {
    response: dequeueInvoice(dequeueInvoiceForm: $dequeueInvoiceForm) {
      ${gql.errors}
    }
  }
`;

export default useDequeueInvoiceMutation;
