// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const CalloutContainer = Styled.View`
  width: ${({width}: any) => (width ? `${width}px` : '100%')};
  background-color: ${({backgroundColor}: any) => backgroundColor};
  border: 1px solid ${({borderColor}: any) => borderColor};
  border-radius: 4px;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  flex-direction: row;
`;

const CalloutText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${({color}: any) => color};
`;

const CalloutLinkText = Styled.Text`
  ${Typography.Responsive.Link}
  color: ${({color}: any) => color};
  text-decoration-color: ${({color}: any) => color};
`;

const Button = Styled.ButtonV2`
`;

const CalloutLink = ({color, text, onPress}: any) => {
  const responsive = useResponsive();

  return (
    <Button onPress={onPress}>
      <CalloutLinkText responsive={responsive} color={color}>
        {text}
      </CalloutLinkText>
    </Button>
  );
};

const Callout = ({
  text,
  textColor,
  backgroundColor,
  borderColor,
  width,
  icon,
  iconColor,
  children,
}: any) => {
  const responsive = useResponsive();

  return (
    <CalloutContainer width={width} backgroundColor={backgroundColor} borderColor={borderColor}>
      <Icon
        source={icon}
        size={responsive.desktop ? 16 : 18}
        color={iconColor}
        style={{marginTop: 2}}
      />
      <Space width={8} />
      {text ? (
        <CalloutText responsive={responsive} color={textColor}>
          {text}
        </CalloutText>
      ) : (
        children
      )}
    </CalloutContainer>
  );
};

Callout.Text = CalloutText;
Callout.LinkText = CalloutLinkText;
Callout.Link = CalloutLink;

// --------------------------------------------------
// Props
// --------------------------------------------------
Callout.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  width: PropTypes.number,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
};

Callout.defaultProps = {
  text: '',
  textColor: colors.gray.primary,
  backgroundColor: colors.gray.border,
  borderColor: colors.gray.secondary,
  width: null,
  icon: Icon.InfoCircle,
  iconColor: colors.gray.secondary,
};

export default Callout;
