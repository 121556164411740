// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Form} from '@supermove/components';

// Components
import SupplyFormFields from './SupplyFormFields';
import UpdateSuppliesMutation from './UpdateSuppliesMutation';

const CreateSupplyForm = ({organizationSuppliesForm, onSuccess, onError}) => (
  <Form
    initialValues={{
      organizationSuppliesForm,
    }}
    onSubmit={(values) => {}}
  >
    {({submitForm, setMutationErrors, ...form}) => (
      <UpdateSuppliesMutation
        organizationSuppliesForm={form.values.organizationSuppliesForm}
        onSuccess={onSuccess}
        submitForm={submitForm}
        setMutationErrors={setMutationErrors}
      >
        {({loading, handleSubmit}) => {
          // The supply we create is always the last row (we create an empty row in index.js)
          const rowIndex = organizationSuppliesForm.suppliesForm.supplyForms.length - 1;
          return (
            <SupplyFormFields
              field={`organizationSuppliesForm.suppliesForm.supplyForms.${rowIndex}`}
              form={form}
              loading={loading}
              handleSubmit={handleSubmit}
            />
          );
        }}
      </UpdateSuppliesMutation>
    )}
  </Form>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
CreateSupplyForm.propTypes = {
  organizationSuppliesForm: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

CreateSupplyForm.defaultProps = {
  onError: () => {},
};

export default CreateSupplyForm;
