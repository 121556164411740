// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import AssignDriverAndTrucksToTripForm from '@shared/modules/Dispatch/forms/AssignDriverAndTrucksToTripForm';

const useAssignDriverAndTrucksToTripMutation = ({
  assignDriverAndTrucksToTripForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      assignDriverAndTrucksToTripForm: AssignDriverAndTrucksToTripForm.toForm(
        assignDriverAndTrucksToTripForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useAssignDriverAndTrucksToTripMutation.mutation,
    variables: {
      assignDriverAndTrucksToTripForm: AssignDriverAndTrucksToTripForm.toMutation(
        form.values.assignDriverAndTrucksToTripForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useAssignDriverAndTrucksToTripMutation.mutation = gql`
  mutation useAssignDriverAndTrucksToTripMutation($assignDriverAndTrucksToTripForm: AssignDriverAndTrucksToTripForm!) {
    response: assignDriverAndTrucksToTrip(assignDriverAndTrucksToTripForm: $assignDriverAndTrucksToTripForm) {
      ${gql.errors}
      trip {
        id
      }
    }
  }
`;

export default useAssignDriverAndTrucksToTripMutation;
