// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CreateCrewsForJobForm from '@shared/modules/Dispatch/forms/CreateCrewsForJobForm';
import CrewForm from '@shared/modules/Dispatch/forms/CrewForm';
import useCreateCrewsForJobMutation from '@shared/modules/Dispatch/hooks/useCreateCrewsForJobMutation';
import {Field} from 'modules/App/components';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import SystemMessage from 'modules/App/components/SystemMessage';

const Line = Styled.View`
  border-bottom-width: 1;
  border-color: ${colors.gray.border}
`;

const CrewItemContainer = Styled.View`
  background-color: ${(props) => (props.isHighlighted ? colors.hover : 'transparent')};
`;

const CrewItemHeader = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const CrewItemHeaderLeftTouchable = Styled.Touchable`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const CheckboxContainer = Styled.View`
  height: 14px;
  width: 14px;
  border-radius: 2px;
  border-width: 1px;
  border-color: ${(props) => (props.isChecked ? colors.blue.interactive : colors.gray.tertiary)};
  background-color: ${(props) => (props.isChecked ? colors.blue.interactive : 'transparent')};
  align-items: center;
  justify-content: center;
`;

const OrganizationName = Styled.H7`
  color: ${colors.black};
  ${fontWeight(500)}
  margin-top: 1px;
`;

const CrewItemHeaderRight = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const PrimaryBadgeContainer = Styled.View`
  background-color: ${colors.orange.status};
  padding-horizontal: 8px;
  padding-top: 3px;
  padding-bottom: 2px;
  border-radius: 20px;
  border-width: 1px;
  border-color: ${colors.orange.status};
`;

const PrimaryBadgeText = Styled.H8`
  color: ${colors.white};
  ${fontWeight(700)}
`;

const SecondaryBadgeContainer = Styled.Touchable`
  background-color: ${colors.gray.background};
  padding-horizontal: 8px;
  padding-top: 3px;
  padding-bottom: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  border-radius: 20px;
`;

const SecondaryBadgeText = Styled.H8`
  color: ${colors.gray.tertiary};
  ${fontWeight(700)}
`;

const FieldInputs = Styled.View`
  flex-direction: row;
  justify-content: center;
  margin-top: 8px;
`;

const getErrorText = ({hasTooManyTrucks, hasTooManyMovers}) => {
  if (hasTooManyTrucks && hasTooManyMovers) {
    return 'movers and trucks';
  }
  if (hasTooManyTrucks) {
    return 'trucks';
  }
  return 'movers';
};

const Badge = ({isPrimary, organization, form}) => {
  const setPrimaryCrew = ({organization, form}) => {
    const {crewForms} = form.values.createCrewsForJobForm;
    const updatedCrewForms = crewForms.map((crew) => {
      return {
        ...crew,
        isPrimary: String(crew.organizationId) === String(organization.id),
      };
    });
    form.setFieldValue(`createCrewsForJobForm.crewForms`, updatedCrewForms);
  };

  if (isPrimary) {
    return (
      <PrimaryBadgeContainer>
        <PrimaryBadgeText>Primary</PrimaryBadgeText>
      </PrimaryBadgeContainer>
    );
  }
  return (
    <SecondaryBadgeContainer onPress={() => setPrimaryCrew({organization, form})}>
      <SecondaryBadgeText>Primary</SecondaryBadgeText>
    </SecondaryBadgeContainer>
  );
};

const CreateCrewsPopoverContent = ({job, handleClose, refetch}) => {
  const createCrewsForJobForm = CreateCrewsForJobForm.edit(job);
  const {form, submitting, handleSubmit} = useCreateCrewsForJobMutation({
    createCrewsForJobForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => {
      console.log(errors);
    },
  });
  const sortedOrganizations = _.sortBy(job.company.organizations, ['name']);

  const field = 'createCrewsForJobForm.crewForms';
  const numberOfAssignedTrucks = _.sumBy(_.get(form.values, field), (crew) =>
    Number(crew.numberOfRequiredTrucks),
  );
  const numberOfAssignedMovers = _.sumBy(_.get(form.values, field), (crew) =>
    Number(crew.numberOfRequiredMovers),
  );
  const hasTooManyTrucks = numberOfAssignedTrucks > job.numberOfTrucks;
  const hasTooManyMovers = numberOfAssignedMovers > job.numberOfMovers;
  const hasError = hasTooManyTrucks || hasTooManyMovers;

  const responsive = useResponsive();

  return (
    <React.Fragment>
      <Line />
      <ScrollView
        style={{
          maxHeight: responsive.mobile ? null : 200,
          backgroundColor: colors.gray.background,
          flex: 1,
        }}
      >
        {sortedOrganizations.map((organization) => (
          <CrewRow
            key={organization.id}
            form={form}
            organization={organization}
            hasTooManyTrucks={hasTooManyTrucks}
            hasTooManyMovers={hasTooManyMovers}
          />
        ))}
      </ScrollView>
      {hasError ? (
        <React.Fragment>
          <Space height={8} />
          <SystemMessage>
            {`You've entered more ${getErrorText({
              hasTooManyTrucks,
              hasTooManyMovers,
            })} than required.`}
          </SystemMessage>
          <Space height={8} />
        </React.Fragment>
      ) : (
        <Space height={12} />
      )}
      <ResponsivePopover.Footer
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        handleSubmitText={'Assign'}
        disabled={submitting || hasError}
        loading={submitting}
      />
      <Space height={12} />
    </React.Fragment>
  );
};

const CrewRow = ({organization, form, hasTooManyTrucks, hasTooManyMovers}) => {
  const findCrewIndex = ({organization, form}) => {
    return _.findIndex(form.values.createCrewsForJobForm.crewForms, {
      organizationId: parseInt(organization.id, 10),
    });
  };

  const findCrew = ({organization, form}) => {
    return form.values.createCrewsForJobForm.crewForms[findCrewIndex({organization, form})];
  };

  const handleCheck = () => {
    const index = findCrewIndex({organization, form});

    if (index !== -1) {
      // If crewForm exists, remove it
      const {crewForms} = form.values.createCrewsForJobForm;
      const crewFormsRemoved = crewForms.slice(0, index).concat(crewForms.slice(index + 1));
      form.setFieldValue(`createCrewsForJobForm.crewForms`, crewFormsRemoved);

      const wasPrimary = crewForms[index].isPrimary;
      if (wasPrimary) {
        const firstNonPrimaryCrewIndex = _.findIndex(crewFormsRemoved, {isPrimary: false});
        if (firstNonPrimaryCrewIndex !== -1) {
          form.setFieldValue(
            `createCrewsForJobForm.crewForms.${firstNonPrimaryCrewIndex}.isPrimary`,
            true,
          );
        }
      }
    } else {
      // If crewForm does not exist, add it
      const {crewForms} = form.values.createCrewsForJobForm;

      // Set is primary = true, if there is not already a primary crew
      const isPrimary = !_.find(form.values.createCrewsForJobForm.crewForms, {isPrimary: true});
      const newCrewForm = CrewForm.toForm(
        CrewForm.new({
          jobId: form.values.createCrewsForJobForm.jobId,
          organizationId: parseInt(organization.id, 10),
          isPrimary,
          numberOfRequiredMovers: 0,
          numberOfRequiredTrucks: 0,
        }),
      );
      const crewFormsAdded = crewForms.concat(newCrewForm);
      form.setFieldValue(`createCrewsForJobForm.crewForms`, crewFormsAdded);
    }
  };
  const crewFormIndex = findCrewIndex({organization, form});
  const checked = crewFormIndex !== -1;
  const crew = findCrew({organization, form});
  const {ref, isHovered} = useHover();
  const responsive = useResponsive();
  const verticalPadding = responsive.mobile ? 12 : 8;
  return (
    <CrewItemContainer ref={ref} isHighlighted={checked || isHovered}>
      <Space height={verticalPadding} />
      <CrewItemHeader>
        <CrewItemHeaderLeftTouchable onPress={handleCheck}>
          <Space width={16} />
          <CheckboxContainer isChecked={checked}>
            {checked && <Icon source={Icon.Check} size={12} color={colors.white} />}
          </CheckboxContainer>
          <Space width={8} />
          <OrganizationName numberOfLines={1}>{organization.name}</OrganizationName>
          <Space width={4} />
        </CrewItemHeaderLeftTouchable>
        <CrewItemHeaderRight>
          {checked && <Badge isPrimary={crew.isPrimary} organization={organization} form={form} />}
          <Space width={16} />
        </CrewItemHeaderRight>
      </CrewItemHeader>
      {checked && (
        <FieldInputs>
          <Field
            {...form}
            name={`createCrewsForJobForm.crewForms.${crewFormIndex}.numberOfRequiredTrucks`}
            input={{
              placeholder: 'Enter trucks',
              keyboardType: 'phone-pad',
              style: {borderColor: hasTooManyTrucks ? colors.red.warning : colors.gray.tertiary},
            }}
            style={{width: 142, height: 32}}
          />
          <Space width={4} />
          <Field
            {...form}
            name={`createCrewsForJobForm.crewForms.${crewFormIndex}.numberOfRequiredMovers`}
            input={{
              placeholder: 'Enter movers',
              keyboardType: 'phone-pad',
              style: {borderColor: hasTooManyMovers ? colors.red.warning : colors.gray.tertiary},
            }}
            style={{width: 142, height: 32}}
          />
        </FieldInputs>
      )}
      <Space height={verticalPadding} />
    </CrewItemContainer>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
CreateCrewsPopoverContent.propTypes = {
  job: PropTypes.object.isRequired,
};

CreateCrewsPopoverContent.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCrewsPopoverContent.fragment = gql`
  ${CreateCrewsForJobForm.edit.fragment}
  fragment CreateCrewsPopoverContent on Job {
    id
    company {
      id
      organizations {
        id
        name
      }
    }
    ...CreateCrewsForJobForm_edit
  }
`;

export default CreateCrewsPopoverContent;
