// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {List} from '@supermove/utils';

// App
import TimesheetBlockModal from '@shared/modules/Timesheet/components/TimesheetBlockModal';
import DeleteTimesheetBlockForm from '@shared/modules/Timesheet/forms/DeleteTimesheetBlockForm';
import TimesheetBlockForm from '@shared/modules/Timesheet/forms/TimesheetBlockForm';
import UpdateTimesheetBillableEntryForm from '@shared/modules/Timesheet/forms/UpdateTimesheetBillableEntryForm';
import UpdateTimesheetBlockForm from '@shared/modules/Timesheet/forms/UpdateTimesheetBlockForm';
import useUpdateTimesheetBillableEntryMutation from '@shared/modules/Timesheet/hooks/useUpdateTimesheetBillableEntryMutation';

const handleNextTimesheetBlock = ({nextTimesheetBlock, form, field, timesheetBlockField}: any) => {
  const timesheetBlockForm = _.get(form.values, timesheetBlockField);
  const updatedRangeTo = timesheetBlockForm.rangeTo;
  const nextBlockForm = UpdateTimesheetBlockForm.toForm(
    UpdateTimesheetBlockForm.edit(nextTimesheetBlock, {isFixedRangeTo: true}),
  );
  const updatedBlockForms = [
    timesheetBlockForm,
    ...List.insertIf(updatedRangeTo, {
      ...nextBlockForm,
      rangeFrom: updatedRangeTo,
      rangeFromDate: TimesheetBlockForm.getMutationRangeToDate(timesheetBlockForm),
    }),
  ];
  form.setFieldValue(`${field}.timesheetBlocksUpdate`, updatedBlockForms);
};

const EditTimesheetBillableBlockModal = ({
  job,
  timesheetBillableEntry,
  timesheetBlock,
  nextTimesheetBlock,
  isOpen,
  handleClose,
  refetch,
  isFirstTimesheetBlock,
  isLastTimesheetBlock,
  onUpdate,
}: any) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const updateTimesheetBillableEntryForm = UpdateTimesheetBillableEntryForm.edit(
    timesheetBillableEntry,
    {
      timesheetBlocksUpdate: [timesheetBlock],
      isFixedRangeFrom: !isFirstTimesheetBlock,
      isFixedRangeTo: isFirstTimesheetBlock,
    },
  );

  const {form, handleSubmit, submitting} = useUpdateTimesheetBillableEntryMutation({
    updateTimesheetBillableEntryForm,
    onSuccess: () => {
      handleClose();
      refetch();
      onUpdate && onUpdate();
    },
    onError: (errors: any) => {
      setIsDeleting(false);
      console.log({errors});
    },
  });

  const field = 'updateTimesheetBillableEntryForm';
  const timesheetBlockField = `${field}.timesheetBlocksUpdate.0`;

  return (
    <TimesheetBlockModal
      job={job}
      form={form}
      field={field}
      timesheetBlockField={timesheetBlockField}
      handleSubmit={() => {
        if (nextTimesheetBlock) {
          handleNextTimesheetBlock({nextTimesheetBlock, form, field, timesheetBlockField});
        }
        setTimeout(handleSubmit, 0);
      }}
      isSubmitting={submitting}
      isDeleting={isDeleting}
      isOpen={isOpen}
      handleClose={handleClose}
      isEdit
      handleDelete={
        isFirstTimesheetBlock || isLastTimesheetBlock
          ? () => {
              setIsDeleting(true);

              form.setFieldValue(`${field}.timesheetBlocksUpdate`, []);
              form.setFieldValue(`${field}.timesheetBlocksDelete`, [
                DeleteTimesheetBlockForm.toForm(DeleteTimesheetBlockForm.edit(timesheetBlock)),
              ]);

              setImmediate(handleSubmit);
            }
          : null
      }
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditTimesheetBillableBlockModal.fragment = gql`
  ${UpdateTimesheetBillableEntryForm.edit.fragment}
  ${DeleteTimesheetBlockForm.edit.fragment}
  ${TimesheetBlockModal.fragment}

  fragment EditTimesheetBillableBlockModal on Job {
    id
    timesheetBillableEntries {
      id
      kind
      timesheetBlocks {
        id
        ...DeleteTimesheetBlockForm_edit
      }
      ...UpdateTimesheetBillableEntryForm_edit
    }
    ...TimesheetBlockModal
  }
`;

export default EditTimesheetBillableBlockModal;
