// Supermove
import {gql} from '@supermove/graphql';
import {Location} from '@supermove/models';
import {Datetime, Float, withFragment} from '@supermove/utils';

// App
import LocationKind from '@shared/modules/Location/enums/LocationKind';

const handleLocationInput = (locationForm: any, {location}: any) => {
  return {
    ...locationForm,
    address: location.address,
    city: location.city,
    state: location.state,
    zipCode: location.zipCode,
    country: location.country,
    latitude: location.latitude,
    longitude: location.longitude,
  };
};

const getDisplayCityState = (locationForm: any) => {
  return Location.getDisplayCityState({city: locationForm.city, state: locationForm.state});
};

const getDisplayAddressCityStateZip = (locationForm: any) => {
  return Location.getDisplayAddressCityStateZip(
    {
      address: locationForm.address,
      city: locationForm.city,
      state: locationForm.state,
      zipCode: locationForm.zipCode,
    },
    'Enter address',
  );
};

const getIsSameLocations = ({locationForm1, locationForm2}: any) => {
  return (
    locationForm1.address === locationForm2.address &&
    locationForm1.name === locationForm2.name &&
    locationForm1.notes === locationForm2.notes &&
    locationForm1.kind === locationForm2.kind &&
    locationForm1.unit === locationForm2.unit &&
    locationForm1.floor_number === locationForm2.floor_number &&
    locationForm1.stair_count === locationForm2.stair_count
  );
};

const _new = ({clientId, kind = LocationKind.PICK_UP}: any = {}) => ({
  locationId: null,
  kind,
  name: '',
  address: '',
  city: '',
  zipCode: '',
  country: '',
  state: '',
  latitude: '',
  longitude: '',
  unit: '',
  floorNumber: '',
  notes: '',
  hasElevator: false,
  hasLongWalk: false,
  stairCount: '',
  stairDescription: '',
  walkingDistance: '',
  elevatorReservationTime: '',
  buildingType: '',
  clientId,

  // Client-side only fields.
  isNameViewable: false,
  isExtraInfoVisible: false,

  // Private fields
  isLocationLoading: false,
  copyOfLocationId: null,
});

const copy = withFragment(
  (location: any) => ({
    locationId: location.id,
    kind: location.kind,
    name: location.name,
    address: location.address,
    city: location.city,
    zipCode: location.zipCode,
    state: location.state,
    country: location.country,
    latitude: location.latitude,
    longitude: location.longitude,
    unit: location.unit,
    floorNumber: location.floorNumber,
    notes: location.notes,
    hasElevator: location.hasElevator,
    hasLongWalk: location.hasLongWalk,
    stairCount: location.stairCount,
    stairDescription: location.stairDescription,
    walkingDistance: location.walkingDistance,
    elevatorReservationTime: location.elevatorReservationTime,
    buildingType: location.buildingType,
    clientId: location.clientId,

    // Private Fields
    isExtraInfoVisible:
      !!location.unit ||
      !!location.floorNumber ||
      !!location.stairDescription ||
      !!location.hasElevator ||
      !!location.hasLongWalk,
    isLocationLoading: false,
    copyOfLocationId: location.id,
  }),
  gql`
    fragment LocationForm_copy on Location {
      id
      kind
      name
      address
      city
      zipCode
      state
      country
      latitude
      longitude
      unit
      floorNumber
      notes
      hasElevator
      hasLongWalk
      stairCount
      stairDescription
      walkingDistance
      elevatorReservationTime
      buildingType
      clientId
    }
  `,
);

const _delete = ({locationId}: any) => ({
  ..._new,
  locationId,
});

// Whitelist the params that we submit as part of a form for LocationForms.
const toForm = ({
  locationId,
  kind,
  name,
  address,
  city,
  zipCode,
  state,
  country,
  latitude,
  longitude,
  unit,
  floorNumber,
  notes,
  hasElevator,
  hasLongWalk,
  stairCount,
  stairDescription,
  walkingDistance,
  elevatorReservationTime,
  buildingType,
  isExtraInfoVisible,
  clientId,
  copyOfLocationId,
}: any) => ({
  locationId,
  kind,
  name,
  address,
  state,
  country,
  city,
  zipCode,
  latitude,
  longitude,
  unit,
  floorNumber: Float.toString(floorNumber),
  notes,
  hasElevator,
  hasLongWalk,
  stairCount: Float.toString(stairCount),
  stairDescription,
  walkingDistance,
  elevatorReservationTime: Datetime.toFormTime(elevatorReservationTime),
  buildingType,
  clientId,

  // Client-side only fields.
  isNameViewable: !!name,
  isExtraInfoVisible,

  // Private fields
  isLocationLoading: false,
  copyOfLocationId,
});

const edit = withFragment(
  (location: any, {warehouseName}: any = {}) => ({
    locationId: location.id,
    kind: location.kind,
    name: warehouseName || location.name,
    address: location.address,
    city: location.city,
    state: location.state,
    country: location.country,
    zipCode: location.zipCode,
    latitude: location.latitude,
    longitude: location.longitude,
    unit: location.unit,
    floorNumber: location.floorNumber,
    notes: location.notes,
    hasElevator: location.hasElevator,
    hasLongWalk: location.hasLongWalk,
    stairCount: location.stairCount,
    stairDescription: location.stairDescription,
    walkingDistance: location.walkingDistance,
    elevatorReservationTime: location.elevatorReservationTime,
    buildingType: location.buildingType,
    clientId: location.clientId,

    // Private Fields
    isExtraInfoVisible:
      !!location.unit ||
      !!location.floorNumber ||
      !!location.stairDescription ||
      !!location.hasElevator ||
      !!location.hasLongWalk,
    isLocationLoading: false,
    copyOfLocationId: null,
  }),
  gql`
    fragment LocationForm_edit on Location {
      id
      kind
      name
      address
      city
      zipCode
      latitude
      longitude
      state
      country
      unit
      floorNumber
      notes
      hasElevator
      hasLongWalk
      stairCount
      stairDescription
      walkingDistance
      elevatorReservationTime
      buildingType
      clientId
    }
  `,
);

const toMutation = ({
  locationId,
  kind,
  name,
  address,
  city,
  zipCode,
  latitude,
  state,
  country,
  longitude,
  unit,
  floorNumber,
  notes,
  hasElevator,
  hasLongWalk,
  stairCount,
  stairDescription,
  walkingDistance,
  elevatorReservationTime,
  buildingType,
  clientId,
}: any) => ({
  locationId,
  kind,
  name,
  state,
  country,
  address,
  city,
  zipCode,
  latitude: Float.toFloat(latitude),
  longitude: Float.toFloat(longitude),
  unit,
  floorNumber: Float.toFloat(floorNumber),
  notes,
  hasElevator,
  hasLongWalk,
  stairCount: Float.toFloat(stairCount),
  stairDescription,
  walkingDistance,
  elevatorReservationTime: Datetime.toMutationTime(elevatorReservationTime),
  buildingType,
  clientId,
});

const LocationForm = {
  new: _new,
  edit,
  delete: _delete,
  copy,
  toForm,
  toMutation,

  getDisplayCityState,
  getDisplayAddressCityStateZip,
  getIsSameLocations,
  handleLocationInput,
};

export default LocationForm;
