// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Float, withFragment} from '@supermove/utils';

// App
import ExpirationTimeframeUnitKind from '@shared/modules/Proposal/enums/ExpirationTimeframeUnitKind';

const _new = ({organizationId, projectTypeId}: any) => ({
  confirmationStepsId: null,
  organizationId,
  projectTypeId,
  kind: '',
  stepsJson: [],
  emailTemplateId: null,
  isEnabledAutomaticExpiration: false,
  defaultExpirationTimeframeUnitKind: ExpirationTimeframeUnitKind.HOURS,
  defaultExpirationTimeframe: '',
  shouldAttachDocumentsInEmail: false,

  // private
  isEnabled: false,
});

const edit = withFragment(
  (confirmationSteps) => ({
    confirmationStepsId: (confirmationSteps as any).id,
    organizationId: (confirmationSteps as any).organizationId,
    projectTypeId: (confirmationSteps as any).projectTypeId,
    kind: (confirmationSteps as any).kind,
    stepsJson: (confirmationSteps as any).stepsJson,
    emailTemplateId: (confirmationSteps as any).emailTemplateId,
    isEnabledAutomaticExpiration: (confirmationSteps as any).isEnabledAutomaticExpiration,
    defaultExpirationTimeframeUnitKind:
      (confirmationSteps as any).defaultExpirationTimeframeUnitKind ||
      ExpirationTimeframeUnitKind.HOURS,
    defaultExpirationTimeframe: (confirmationSteps as any).defaultExpirationTimeframe,
    shouldAttachDocumentsInEmail: (confirmationSteps as any).shouldAttachDocumentsInEmail,
    // private
    isEnabled: (confirmationSteps as any).isEnabled,
  }),
  gql`
    fragment ConfirmationStepsForm_edit on ConfirmationSteps {
      id
      organizationId
      projectTypeId
      kind
      stepsJson
      emailTemplateId
      isEnabled
      isEnabledAutomaticExpiration
      defaultExpirationTimeframeUnitKind
      defaultExpirationTimeframe
      shouldAttachDocumentsInEmail
    }
  `,
);

const toForm = ({
  confirmationStepsId,
  organizationId,
  projectTypeId,
  kind,
  stepsJson,
  emailTemplateId,
  isEnabledAutomaticExpiration,
  defaultExpirationTimeframeUnitKind,
  defaultExpirationTimeframe,
  shouldAttachDocumentsInEmail,

  // private
  isEnabled,
}: any) => ({
  confirmationStepsId,
  organizationId,
  projectTypeId,
  kind,
  stepsJson,
  emailTemplateId: _.toString(emailTemplateId),
  isEnabledAutomaticExpiration,
  defaultExpirationTimeframeUnitKind,
  defaultExpirationTimeframe: Float.toForm(defaultExpirationTimeframe),
  shouldAttachDocumentsInEmail,

  // private
  isEnabled,
});

const toMutation = ({
  confirmationStepsId,
  organizationId,
  projectTypeId,
  kind,
  stepsJson,
  emailTemplateId,
  isEnabledAutomaticExpiration,
  defaultExpirationTimeframeUnitKind,
  defaultExpirationTimeframe,
  shouldAttachDocumentsInEmail,
}: any) => ({
  confirmationStepsId,
  organizationId,
  projectTypeId,
  kind,
  stepsJson,
  emailTemplateId: emailTemplateId ? _.toNumber(emailTemplateId) : null,
  isEnabledAutomaticExpiration,
  defaultExpirationTimeframeUnitKind,
  defaultExpirationTimeframe: Float.toMutation(defaultExpirationTimeframe),
  shouldAttachDocumentsInEmail,
});

const ConfirmationStepsForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ConfirmationStepsForm;
