// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal} from '@supermove/hooks';
import {BillItemType} from '@supermove/models';

// App
import IconButton from '@shared/design/components/IconButton';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteBillItemTypeMutation from '@shared/modules/Billing/hooks/useDeleteBillItemTypeMutation';
import EditBillItemTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/EditBillItemTypeDrawer';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const BillItemTypeActions = ({billItemType, refetch, userId}) => {
  const deleteBillItemTypeModal = useModal({name: 'Delete Bill Item Type Drawer'});
  const editBillItemTypeDrawer = useDrawer({name: 'Edit Bill Item Type Drawer'});
  const {handleSubmit, submitting} = useDeleteBillItemTypeMutation({
    billItemTypeId: billItemType.id,
    onSuccess: () => {
      deleteBillItemTypeModal.handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  const isSynced = BillItemType.getIsSynced(billItemType);
  return (
    <React.Fragment>
      <Row>
        <Space width={24} />
        <IconButton source={Icon.Pen} onPress={editBillItemTypeDrawer.handleOpen} />
        <Space width={8} />
        <IconButton
          source={Icon.Trash}
          onPress={deleteBillItemTypeModal.handleOpen}
          isDisabled={isSynced}
          isDestructive
        />
      </Row>
      <EditBillItemTypeDrawer
        key={editBillItemTypeDrawer.key}
        isOpen={editBillItemTypeDrawer.isOpen}
        handleClose={editBillItemTypeDrawer.handleClose}
        billItemType={billItemType}
        refetch={refetch}
        userId={userId}
      />
      <DeleteModal
        key={deleteBillItemTypeModal.key}
        isOpen={deleteBillItemTypeModal.isOpen}
        handleClose={deleteBillItemTypeModal.handleClose}
        handleDelete={handleSubmit}
        isSubmitting={submitting}
        title={`Delete '${billItemType.name}'?`}
        subtitle={
          "This will delete this bill item and remove it from all bill templates. You can't undo this action."
        }
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillItemTypeActions.fragment = gql`
  ${BillItemType.getIsSynced.fragment}
  ${EditBillItemTypeDrawer.fragment}

  fragment BillItemTypeActions on BillItemType {
    id
    ...BillItemType_getIsSynced
    ...EditBillItemTypeDrawer
  }
`;

export default BillItemTypeActions;
