// Note: This page now always redirects to the project level quote page. This is to redirect
// any job-level quote links customers may have.

// Libraries
import React from 'react';
import {Redirect} from 'react-router-dom';

// Components
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';

import {Page} from 'modules/App/components';

const QuoteJobCustomerPage = ({
  match: {
    params: {slug, uuid},
  },
}) => (
  <Page fetchPolicy={'network-only'} query={QuoteJobCustomerPage.query} variables={{uuid}}>
    {({loading, data}) => (
      <Loading loading={loading}>
        {() => {
          return <Redirect to={`/0/${slug}/projects/${data.job.project.uuid}/quote`} />;
        }}
      </Loading>
    )}
  </Page>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
QuoteJobCustomerPage.query = gql`
  query QuoteJobCustomerPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      uuid
      project {
        id
        uuid
      }
    }
  }
`;

export default QuoteJobCustomerPage;
