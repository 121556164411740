// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useState, useToast, useResponsive, ResponsiveType} from '@supermove/hooks';
import {Document, DocumentModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import Toast from '@shared/design/components/Toast';
import ShareDocumentForm from '@shared/modules/Document/forms/ShareDocumentForm';
import useShareDocumentMutation from '@shared/modules/Document/hooks/useShareDocumentMutation';

const ShareButton = Styled.ButtonV2`
`;

const LinkTextButton = Styled.ButtonV2<{responsive: ResponsiveType}>`
  width: 100%;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.tertiary};
  background-color: ${colors.gray.disabled};
  padding-horizontal: 12px;
  padding-vertical: ${({responsive}) => (responsive.desktop ? 12 : 16)}px;
  flex-direction: row;
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Body}
  flex: 1;
`;

const ShareDocumentLinkModal = ({
  document,
  isOpen,
  handleClose,
}: {
  document: DocumentModel;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const responsive = useResponsive();
  const shareLink = Document.getShareLink(document);
  const linkCopiedToast = useToast({
    ToastComponent: Toast,
    message: `Link copied to clipboard.`,
  });
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    linkCopiedToast.handleToast();
  };

  return (
    <ActionModal
      icon={Icon.Share}
      title={'Public link generated!'}
      message={
        'Public access to your document is now available. You can share this link with anyone.'
      }
      handlePrimaryAction={handleClose}
      primaryActionText={'Close'}
      isOpen={isOpen}
    >
      <Space height={16} />
      <LinkTextButton onPress={handleCopyLink} responsive={responsive}>
        <LinkText numberOfLines={1}>{shareLink}</LinkText>
        <Space width={4} />
        <Icon source={Icon.Copy} size={16} color={colors.blue.interactive} />
      </LinkTextButton>
    </ActionModal>
  );
};

const GenerateDocumentLinkModal = ({
  document,
  isOpen,
  handleClose,
  shareDocumentLinkModal,
}: {
  document: DocumentModel;
  isOpen: boolean;
  handleClose: () => void;
  shareDocumentLinkModal: ReturnType<typeof useModal>;
}) => {
  // We use isSubmitting here instead of the submitting value returned from the mutation hook
  // to smooth out the transition between the two modals. When the mutation completes there is
  // a moment where submitting is false but the but onSuccess callback has not yet been called.
  const [isSubmitting, setIsSubmitting] = useState(false);
  const shareDocumentForm = ShareDocumentForm.edit(document);
  const {handleSubmit} = useShareDocumentMutation({
    shareDocumentForm,
    onSuccess: () => {
      handleClose();
      shareDocumentLinkModal.handleOpen();
    },
    onError: (errors: unknown) => {
      console.log({errors});
      setIsSubmitting(false);
    },
  });

  return (
    <ActionModal
      icon={Icon.Lock}
      title={'Generate share link'}
      message={'Please click the Generate Link button to get a public share link.'}
      handleSecondaryAction={handleClose}
      secondaryActionText={'Close'}
      handlePrimaryAction={() => {
        setIsSubmitting(true);
        handleSubmit();
      }}
      primaryActionText={'Generate Link'}
      isOpen={isOpen}
      isSubmitting={isSubmitting}
    />
  );
};

const ShareDocumentButton = ({document}: {document: DocumentModel}) => {
  const generateDocumentLinkModal = useModal({
    name: 'Generate Document Link Modal',
    enableTracking: true,
  });
  const shareDocumentLinkModal = useModal({
    name: 'Share Document Link Modal',
    enableTracking: true,
  });

  return (
    <React.Fragment>
      <ShareButton
        onPress={
          document.isShared
            ? shareDocumentLinkModal.handleOpen
            : generateDocumentLinkModal.handleOpen
        }
      >
        <Icon source={Icon.Link} size={16} color={colors.blue.interactive} />
      </ShareButton>
      <GenerateDocumentLinkModal
        document={document}
        key={generateDocumentLinkModal.key}
        isOpen={generateDocumentLinkModal.isOpen}
        handleClose={generateDocumentLinkModal.handleClose}
        shareDocumentLinkModal={shareDocumentLinkModal}
      />
      <ShareDocumentLinkModal
        document={document}
        isOpen={shareDocumentLinkModal.isOpen}
        handleClose={shareDocumentLinkModal.handleClose}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShareDocumentButton.fragment = gql`
  ${ShareDocumentForm.edit.fragment}

  fragment ShareDocumentButton on Document {
    id
    isShared
    shareUuid
    ...ShareDocumentForm_edit
  }
`;

export default ShareDocumentButton;
