// Libraries
import React from 'react';

// Supermove
import {Styled, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';

// Components
import Callout from '@shared/design/components/Callout';
import AccountingPageHeader from 'modules/Accounting/components/AccountingPageHeader';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import {AccountingExportsContent} from 'modules/Organization/Settings/Company/OrganizationSettingsCompanyIntegrationsAccountingExportsPage';

const Container = Styled.View`
  flex: 1;
`;

const ListExportsAccountingPage = () => {
  const {loading, data} = useQuery(ListExportsAccountingPage.query, {
    options: {
      fetchPolicy: 'cache-and-network',
    },
  });
  const {navigator, params} = useNavigationDOM();

  if (loading) {
    return (
      <SidebarPageV2 selected={'accounting'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  const hasCodatIntegration = Boolean(data.viewer.viewingOrganization.activeCodatIntegration);

  return (
    <SidebarPageV2 selected={'accounting'}>
      <Container>
        <AccountingPageHeader organization={data.viewer.viewingOrganization} />
        <ScrollView style={{padding: '24px'}}>
          {hasCodatIntegration ? (
            <AccountingExportsContent navigator={navigator} params={params} />
          ) : (
            <Callout text={`Accounting integration is not set up for this organization.`} />
          )}
        </ScrollView>
      </Container>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListExportsAccountingPage.query = gql`
  ${AccountingPageHeader.fragment}
  query ListExportsAccountingPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        activeCodatIntegration {
          id
        }
        ...AccountingPageHeader
      }
    }
  }
`;

export default ListExportsAccountingPage;
