// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import LocationTypeForm from '@shared/modules/Organization/forms/LocationTypeForm';
import OrganizationLocationTypesForm from '@shared/modules/Organization/forms/OrganizationLocationTypesForm';

const useUpdateOrganizationLocationTypesMutation = ({
  organizationLocationTypesForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      organizationLocationTypesForm: OrganizationLocationTypesForm.toForm(
        organizationLocationTypesForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationLocationTypesMutation.mutation,
    variables: {
      organizationLocationTypesForm: OrganizationLocationTypesForm.toMutation(
        form.values.organizationLocationTypesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationLocationTypesMutation.mutation = gql`
  ${LocationTypeForm.edit.fragment}
  mutation useUpdateOrganizationLocationTypesMutation(
    $organizationLocationTypesForm: OrganizationLocationTypesForm!
  ) {
    response: updateOrganizationLocationTypes(
      organizationLocationTypesForm: $organizationLocationTypesForm
    ) {
      ${gql.errors}
      organization {
        id
        settings {
          id
          locationTypes {
            ...LocationTypeForm_edit
          }
        }
      }
    }
  }
`;

export default useUpdateOrganizationLocationTypesMutation;
