// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, ScrollView, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDebouncedCallback, useQuery, usePagination} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import EmptyState from '@shared/design/components/EmptyState';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import SearchBar from '@shared/design/components/SearchBar';
import Tabs from '@shared/design/components/Tabs';
import useFilteredItemsReducer from '@shared/modules/Inventory/hooks/useFilteredItemsReducer';
import ListInventoryItems from 'modules/Inventory/ListInventoryItems';

const InventoryItemsPanelWrapper = Styled.View`
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
  padding: 16px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  width: 100%;
`;

const Section = Styled.View`
  align-self: stretch;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const HeaderContainer = Styled.View`
`;

const LeftContainer = Styled.View`
  flex: 3;
  flex-direction: row;
  align-items: center;
`;

const RightContainer = Styled.View`
  flex: 2;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const FiltersContainers = Styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const TabsContainer = Styled.View`
`;

const LotColorIndicator = Styled.View`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: ${({color}) => color};
  border-width: 1px;
  border-color: ${({borderColor, color}) => borderColor || color};
`;

const TabHeader = ({activeTabKey, lots, onTabPress}) => {
  const tabs = lots.map(({lotNumber, color}) => {
    return {
      key: `${color}_${lotNumber}`,
      label: lotNumber,
      color,
      lotNumber,
    };
  });

  const activeTabIndex = _.findIndex(tabs, (tab) => tab.key === activeTabKey);

  return (
    <TabsContainer>
      <Tabs
        tabs={tabs}
        handlePressTab={onTabPress}
        activeTabIndex={activeTabIndex}
        TabComponent={({tab, handlePressTab, isActive}) => (
          <Tabs.Tab tab={tab} handlePressTab={handlePressTab} isActive={isActive} isSecondary>
            <LotColorIndicator
              color={tab.color}
              borderColor={
                _.toLower(tab.color) === _.toLower(colors.white) && colors.gray.secondary
              }
            />
            <Space width={8} />
            <Tabs.TabContent tab={tab} isActive={isActive} isSecondary />
          </Tabs.Tab>
        )}
      />
    </TabsContainer>
  );
};

const InventoryItemsPanelFilters = ({filters, actions, dispatch, inventory}) => {
  const handleChangeSearch = useDebouncedCallback(
    (input) => dispatch({type: actions.SET_SEARCH_QUERY, payload: input}),
    250,
    {leading: true},
  );

  return (
    <HeaderContainer>
      <FiltersContainers>
        <LeftContainer>
          <SearchBar
            onChangeText={handleChangeSearch}
            placeholder='Search by item number or name'
            style={{width: '348px'}}
            defaultValue={filters.searchQuery}
          />
          <Space width={12} />
          {/* TODO(cooper): Filters popover button goes here */}
        </LeftContainer>
        <RightContainer />
      </FiltersContainers>
      <Space height={16} />
      <TabHeader
        activeTabKey={`${filters.colors[0]}_${filters.lotNumbers[0]}`}
        lots={inventory.lotsByJobIds}
        onTabPress={(tab) =>
          dispatch({type: actions.SET_LOT, payload: {lotNumber: tab.lotNumber, color: tab.color}})
        }
      />
    </HeaderContainer>
  );
};

const InventoryItemsPanelContent = ({inventory, jobIds}) => {
  const {filters, dispatch, actions} = useFilteredItemsReducer({
    lotNumbers: [inventory.lotsByJobIds[0].lotNumber],
    colors: [inventory.lotsByJobIds[0].color],
    searchQuery: '',
    pagination: PaginationBar.DEFAULT_PAGINATION,
  });

  const {loading, data} = useQuery(InventoryItemsPanel.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inventoryUuid: inventory.uuid,
      jobIds,
      ...filters,
    },
  });

  const pagination = usePagination({
    currentPage: _.toNumber(filters.pagination.page),
    paginationMetadata: _.get(data, 'inventory.paginatedList.paginationMetadata'),
    onChangePage: (page) => dispatch({type: actions.SET_PAGINATION, payload: page}),
  });

  return (
    <Row>
      <Container>
        <Section sectionIndex={0}>
          <Content>
            <InventoryItemsPanelFilters
              filters={filters}
              actions={actions}
              dispatch={dispatch}
              inventory={inventory}
            />
          </Content>
        </Section>
        <ScrollView style={{flex: 1}}>
          <Content>
            <Section sectionIndex={1}>
              <ListInventoryItems
                items={data ? data.inventory.paginatedList.items : []}
                loading={loading}
              />
            </Section>
            <Space height={16} />
            <Section sectionIndex={2}>
              <PaginationBar pagination={pagination} />
            </Section>
          </Content>
        </ScrollView>
      </Container>
    </Row>
  );
};

const InventoryItemsPanel = ({inventory, jobIds}) => {
  if (!inventory.lotsByJobIds.length) {
    return (
      <InventoryItemsPanelWrapper>
        <EmptyState
          icon={Icon.BoxOpen}
          title={'No inventory items to display'}
          message={'Once inventory is captured, items will appear here.'}
          paddingVertical={24}
        />
      </InventoryItemsPanelWrapper>
    );
  }

  return (
    <InventoryItemsPanelWrapper>
      <InventoryItemsPanelContent inventory={inventory} jobIds={jobIds} />
    </InventoryItemsPanelWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryItemsPanel.query = gql`
  ${usePagination.fragment}
  ${ListInventoryItems.fragment}

  query InventoryItemsPanel(
    $inventoryUuid: String!,
    $pagination: PaginationInput!,
    $jobIds: [Int],
    $lotNumbers: [String],
    $colors: [String],
    $searchQuery: String,
  ) {
    ${gql.query}
    inventory(uuid: $inventoryUuid) {
      id
      paginatedList: filteredItemsPaginatedList(
        pagination: $pagination,
        jobIds: $jobIds,
        lotNumbers: $lotNumbers,
        colors: $colors,
        searchQuery: $searchQuery,
      ) {
        items: results {
          id
          ...ListInventoryItems
        }
        paginationMetadata {
          ...usePagination
        }
      }
    }
  }
`;

InventoryItemsPanel.fragment = gql`
  fragment InventoryItemsPanel on Inventory {
    id
    uuid
    lotsByJobIds(jobIds: $jobIds) {
      lotNumber
      color
    }
  }
`;

export default InventoryItemsPanel;
