// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {useState} from '@supermove/hooks';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import DocumentContentJson from '@shared/modules/Document/helpers/DocumentContentJson';
import {
  DocumentContentJsonWithSectionsType,
  SelectedDocumentItemType,
  SetSelectedDocumentItemType,
  SetDocumentContentJsonType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';

const handleMoveDocumentItem = ({
  selectedDocumentItem,
  setSelectedDocumentItem,
  setDraftDocumentContentJson,
  sectionUuid,
  index,
  columnIndex,
}: {
  selectedDocumentItem: SelectedDocumentItemType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  sectionUuid: string;
  index: number;
  columnIndex?: number;
}) => {
  setDraftDocumentContentJson((prevState) => {
    const fromDocumentItemsField = DocumentContentJson.getDocumentItemsField({
      sectionIndex: selectedDocumentItem.sectionIndex,
      columnIndex,
    });
    const fromDocumentItems = _.get(prevState, fromDocumentItemsField);

    if (!_.isNil(selectedDocumentItem.index)) {
      const isMovingSelectedItem = index === selectedDocumentItem.index;
      if (isMovingSelectedItem) {
        setSelectedDocumentItem({
          ...selectedDocumentItem,
          index: undefined,
          documentItem: undefined,
        });
      }
      const isShiftingDownSelectedItem = index < selectedDocumentItem.index;
      if (isShiftingDownSelectedItem) {
        setSelectedDocumentItem({...selectedDocumentItem, index: selectedDocumentItem.index - 1});
      }
    }

    const toSectionIndex = _.findIndex(prevState.documentItems, {uuid: sectionUuid});
    const toSectionDocumentItemsField = `documentItems.${toSectionIndex}.documentItems`;
    const toSectionDocumentItems = _.get(prevState, toSectionDocumentItemsField);
    const sectionHasColumns = toSectionDocumentItems[0]?.itemKind === DocumentItemKindV2.COLUMN;
    const toDocumentItemsField = `${toSectionDocumentItemsField}${sectionHasColumns ? `.0.documentItems` : ''}`;
    const toDocumentItems = _.get(prevState, toDocumentItemsField);

    const movingDocumentItem = fromDocumentItems.splice(index, 1)[0];

    _.set(prevState, fromDocumentItemsField, [...fromDocumentItems]);
    _.set(prevState, toDocumentItemsField, [...toDocumentItems, movingDocumentItem]);

    return {...prevState};
  });
};

const MoveDocumentItemModal = ({
  isOpen,
  handleClose,
  selectedDocumentItem,
  setSelectedDocumentItem,
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  documentItemName,
  index,
  columnIndex,
}: {
  isOpen: boolean;
  handleClose: () => void;
  selectedDocumentItem: SelectedDocumentItemType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  draftDocumentContentJson: DocumentContentJsonWithSectionsType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentItemName: string;
  index: number;
  columnIndex?: number;
}) => {
  const [sectionUuid, setSectionUuid] = useState('');
  const currentSectionUuid = _.get(
    draftDocumentContentJson,
    `documentItems.${selectedDocumentItem.sectionIndex}.uuid`,
  );

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Move Document Item'}
      description={`Select the section you would like to move ${documentItemName} to.`}
      handleAction={() => {
        handleMoveDocumentItem({
          selectedDocumentItem,
          setSelectedDocumentItem,
          setDraftDocumentContentJson,
          sectionUuid,
          index,
          columnIndex,
        });
        handleClose();
      }}
      handleActionText={'Save'}
      isDisabledPrimaryAction={!sectionUuid}
    >
      <FieldInput
        component={DropdownInput}
        value={sectionUuid}
        input={{
          placeholder: 'Select section',
          options: draftDocumentContentJson.documentItems.map((section) => {
            return {
              label: section.name,
              value: section.uuid,
              isDisabled: currentSectionUuid === section.uuid,
            };
          }),
          onChangeValue: (value: string) => setSectionUuid(value),
          isPortaled: true,
          autoFocus: true,
          style: {flex: 1},
        }}
        style={{flex: 1}}
      />
      <Space height={16} />
    </SmallModal>
  );
};

export default MoveDocumentItemModal;
