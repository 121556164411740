// Libraries
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import CapacityCalendarSettingHelpers from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSettingHelpers';
import CapacityCalendarSlotMetricKind from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSlotMetricKind';

// TODO(shopkins): Make this a more generic component in the design system
const Skeleton = Styled.View`
  height: ${({height}) => height};
  width: ${({width}) => width};
  background-color: ${colors.gray.border};
  border-radius: 5px;
`;

const MetricContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const SecondaryMetricRowContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CapacityNumeratorText = Styled.Text`
  ${Typography.Heading3}
  color: ${colors.gray.primary};
`;

const SecondaryNumeratorText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.primary};
`;

const CapacityDenominatorText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.tertiary};
`;

const SecondaryDenominatorText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.tertiary};
`;

const CapacityDescriptionText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.tertiary};
`;

const DayNotesText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.secondary};
`;

const TopLeftMetricContainer = Styled.View`
  position: absolute;
  top: 8;
  left: 12;
`;

const SecondaryMetricContainer = Styled.View`
  position: absolute;
  top: 30;
  right: 0;
  padding-right: 8px;
`;

const BottomMetricContainer = Styled.View`
  position: absolute;
  bottom: 8;
  left: 12;
  width: 90%;
`;

const MetricsContainer = Styled.View`
  flex: 1;
  height: 100%;
  background-color: ${({backgroundColor}) => backgroundColor};
`;
const Metric = ({
  capacityCalendarDay,
  additionalCapacityCalendarMetadata,
  slotMetric,
  shouldHideDayNotes,
  isSecondaryMetric,
}) => {
  // If there is no slot metric, then we don't render anything in that slot
  if (!slotMetric) {
    return null;
  }

  switch (slotMetric.metric) {
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE_BY_POSITION:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryTotalJobPositionCount
              : capacityCalendarDay.totalJobPositionCount
          }
          denominator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryAvailablePositionCount
              : capacityCalendarDay.availablePositionCount
          }
          subtitle={`${
            isSecondaryMetric
              ? additionalCapacityCalendarMetadata.secondaryPositionName
              : additionalCapacityCalendarMetadata.positionName
          }s committed`}
          isSecondaryMetric={isSecondaryMetric}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={capacityCalendarDay.totalTruckCount}
          denominator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryAvailablePositionCount
              : capacityCalendarDay.availablePositionCount
          }
          subtitle={`${
            isSecondaryMetric
              ? additionalCapacityCalendarMetadata.secondaryPositionName
              : additionalCapacityCalendarMetadata.positionName
          }s committed`}
          isSecondaryMetric={isSecondaryMetric}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.DAY_NOTES:
      if (!shouldHideDayNotes) {
        return <DayNotesMetric dayNotes={capacityCalendarDay.dayNotes} />;
      }
      return null;
    case CapacityCalendarSlotMetricKind.TOTAL_TRUCKS_AVAILABLE:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={capacityCalendarDay.totalTruckCount}
          denominator={capacityCalendarDay.availableTruckCount}
          subtitle={'Trucks committed'}
          isSecondaryMetric={isSecondaryMetric}
          icon={Icon.Truck}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={capacityCalendarDay.totalAllJobUserCount}
          denominator={capacityCalendarDay.availableAllMoverCount}
          subtitle={'Crew committed'}
          isSecondaryMetric={isSecondaryMetric}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_JOBS:
      return (
        <NumberWithSubtitle
          number={capacityCalendarDay.totalJobCount}
          subtitle={'Total jobs'}
          isSecondaryMetric={isSecondaryMetric}
          icon={Icon.BriefCaseBlank}
        />
      );
    default:
      return null;
  }
};

const NumeratorAndDenominatorWithSubtitle = ({
  numerator,
  denominator,
  subtitle,
  isSecondaryMetric,
  icon,
}) => {
  return !isSecondaryMetric ? (
    <React.Fragment>
      <MetricContainer>
        <CapacityNumeratorText>{numerator}</CapacityNumeratorText>
        <CapacityDenominatorText>/{denominator}</CapacityDenominatorText>
      </MetricContainer>
      <CapacityDescriptionText>{subtitle}</CapacityDescriptionText>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <TextTooltip style={{zIndex: 99999}} placement={'bottom'} text={subtitle}>
        <SecondaryMetricRowContainer>
          <SecondaryNumeratorText>{numerator}</SecondaryNumeratorText>
          <SecondaryDenominatorText>/{denominator}</SecondaryDenominatorText>
          <Space width={4} />
          <Icon source={icon} color={colors.black} size={12} />
        </SecondaryMetricRowContainer>
      </TextTooltip>
    </React.Fragment>
  );
};

const NumberWithSubtitle = ({number, subtitle, isSecondaryMetric, icon}) => {
  return !isSecondaryMetric ? (
    <React.Fragment>
      <MetricContainer>
        <CapacityNumeratorText>{number}</CapacityNumeratorText>
      </MetricContainer>
      <CapacityDescriptionText>{subtitle}</CapacityDescriptionText>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <TextTooltip style={{zIndex: 99999}} placement={'bottom'} text={subtitle}>
        <SecondaryMetricRowContainer>
          <SecondaryNumeratorText>{number}</SecondaryNumeratorText>
          <Space width={4} />
          <Icon source={icon} color={colors.gray.secondary} size={12} />
        </SecondaryMetricRowContainer>
      </TextTooltip>
    </React.Fragment>
  );
};

const DayNotesMetric = ({dayNotes}) => {
  return (
    <React.Fragment>
      <DayNotesText numberOfLines={1}>{dayNotes || ''}</DayNotesText>
    </React.Fragment>
  );
};

const TopLeftMetric = ({
  capacityCalendarDay,
  capacityCalendarSetting,
  additionalCapacityCalendarMetadata,
  slotMetric,
}) => {
  return (
    <TopLeftMetricContainer>
      <Metric
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendarSetting={capacityCalendarSetting}
        additionalCapacityCalendarMetadata={additionalCapacityCalendarMetadata}
        slotMetric={slotMetric}
      />
    </TopLeftMetricContainer>
  );
};

const SecondaryMetric = ({
  capacityCalendarDay,
  capacityCalendarSetting,
  additionalCapacityCalendarMetadata,
  slotMetric,
}) => {
  return (
    <SecondaryMetricContainer>
      <Metric
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendarSetting={capacityCalendarSetting}
        additionalCapacityCalendarMetadata={additionalCapacityCalendarMetadata}
        slotMetric={slotMetric}
        isSecondaryMetric
      />
    </SecondaryMetricContainer>
  );
};

const BottomMetric = ({
  capacityCalendarDay,
  capacityCalendarSetting,
  additionalCapacityCalendarMetadata,
  slotMetric,
  shouldHideDayNotes,
}) => {
  return (
    <BottomMetricContainer style={{bottom: 8}}>
      <Metric
        shouldHideDayNotes={shouldHideDayNotes}
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendarSetting={capacityCalendarSetting}
        additionalCapacityCalendarMetadata={additionalCapacityCalendarMetadata}
        slotMetric={slotMetric}
      />
    </BottomMetricContainer>
  );
};

const SkeletonLoader = () => {
  return (
    <React.Fragment>
      <TopLeftMetricContainer>
        <Skeleton height={'26px'} width={'50px'} />
        <Space height={6} />
        <Skeleton height={'15px'} width={'90px'} />
      </TopLeftMetricContainer>
      <BottomMetricContainer>
        <Skeleton height={'15px'} width={'100%'} />
      </BottomMetricContainer>
    </React.Fragment>
  );
};

const CapacityCalendarDaySlotMetrics = ({capacityCalendar, cellDate, shouldHideDayNotes}) => {
  const {capacityCalendarDays} = capacityCalendar;
  const capacityCalendarDay = capacityCalendarDays.find((day) => day.date === cellDate);
  const {capacityCalendarSetting} = capacityCalendar;
  const {additionalCapacityCalendarMetadata} = capacityCalendar;
  const {capacityCalendarSlotMetrics} = capacityCalendar;

  return (
    <MetricsContainer
      backgroundColor={
        CapacityCalendarSettingHelpers.getCapacityCalendarBackgroundColor({
          capacityCalendar,
          capacityCalendarDay,
        }).backgroundColor
      }
    >
      <TopLeftMetric
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendarSetting={capacityCalendarSetting}
        additionalCapacityCalendarMetadata={additionalCapacityCalendarMetadata}
        slotMetric={CapacityCalendarSettingHelpers.getMetricBySlotPriorityIndex({
          capacityCalendarSlotMetrics,
          slotPriorityIndex: 1,
        })}
      />
      <SecondaryMetric
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendarSetting={capacityCalendarSetting}
        additionalCapacityCalendarMetadata={additionalCapacityCalendarMetadata}
        slotMetric={CapacityCalendarSettingHelpers.getMetricBySlotPriorityIndex({
          capacityCalendarSlotMetrics,
          slotPriorityIndex: 2,
        })}
      />
      <BottomMetric
        shouldHideDayNotes={shouldHideDayNotes}
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendarSetting={capacityCalendarSetting}
        additionalCapacityCalendarMetadata={additionalCapacityCalendarMetadata}
        // Day notes are no longer part of additional slot metrics, we should always turn it on
        slotMetric={{
          metric: CapacityCalendarSlotMetricKind.DAY_NOTES,
        }}
      />
    </MetricsContainer>
  );
};

CapacityCalendarDaySlotMetrics.SkeletonLoader = SkeletonLoader;

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarDaySlotMetrics.fragment = gql`
  ${CapacityCalendarSettingHelpers.getCapacityCalendarBackgroundColor.fragment}
  ${CapacityCalendarSettingHelpers.getMetricBySlotPriorityIndex.fragment}

  fragment CapacityCalendarDaySlotMetrics on CapacityCalendar {
    capacityCalendarSlotMetrics {
      id
      metric
      slotPriorityIndex
    }
    capacityCalendarSetting {
      id
      crewForPositionCapacity
    }
    capacityCalendarDays {
      date
      totalJobPositionCount
      secondaryTotalJobPositionCount
      availablePositionCount
      secondaryAvailablePositionCount
      dayNotes
      totalTruckCount
      totalJobCount
      availableTruckCount
      totalAllJobUserCount
      availableAllMoverCount
    }
    additionalCapacityCalendarMetadata {
      positionName
      secondaryPositionName
    }
    ...CapacityCalendarSettingHelpers_getCapacityCalendarBackgroundColor
    ...CapacityCalendarSettingHelpers_getMetricBySlotPriorityIndex
  }
`;

export default CapacityCalendarDaySlotMetrics;
