// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useToast, useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import VariableKind from '@shared/modules/Billing/enums/VariableKind';
import TextMessageSystemKind from '@shared/modules/Sms/enums/TextMessageSystemKind';
import TextMessageTemplateCategory from '@shared/modules/Sms/enums/TextMessageTemplateCategory';
import TextMessageTemplateForm from '@shared/modules/Sms/forms/TextMessageTemplateForm';
import useUpdateTextMessageTemplateMutation from '@shared/modules/Sms/hooks/useUpdateTextMessageTemplateMutation';
import HardcodedSystemVariables from '@shared/modules/Variable/enums/HardcodedSystemVariables';
import SmsTemplatesField from 'modules/Organization/Settings/Communication/components/SmsTemplatesField';
import VariableList from 'modules/Variable/components/VariableList';

const Container = Styled.View`
  flex: 1;
`;

const PageContainer = Styled.View`
  width: 100%;
  background-color: ${colors.gray.background};
  flex-direction: row;
  height: 100%;
`;

const EditSmsTemplateHeaderContainer = Styled.View`
  padding: 24px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  height: 84px;
  width: 100%;
`;

const SmsTemplateFieldsContainer = Styled.View`
  width: 500px;
  padding: 24px;
`;

const SmsTemplateVariablesContainer = Styled.View`
  width: 40%;
  padding: 24px;
`;

const BackButton = Styled.ButtonV2`
`;

const HeaderText = Styled.Text`
  ${Typography.Heading2};
`;

const SubheadingText = Styled.Text`
  ${Typography.Subheading};
`;

const EditSmsTemplatePageHeader = ({
  navigator,
  handleSubmit,
  isSubmitting,
  form,
  textTemplateChangesFoundModal,
}) => {
  const isSystemTemplate =
    _.get(form.values, 'textMessageTemplateForm.systemTemplateKind') !==
    TextMessageSystemKind.CUSTOM;

  return (
    <EditSmsTemplateHeaderContainer>
      <BackButton
        onPress={() => {
          if (form.dirty) {
            textTemplateChangesFoundModal.handleOpen();
          } else {
            navigator.push(`/settings/communication/sms-templates`);
          }
        }}
      >
        <Icon source={Icon.ChevronLeft} color={colors.black} size={24} />
      </BackButton>
      <Space width={16} />
      <HeaderText>{`Edit ${isSystemTemplate ? 'System' : 'Custom'} SMS Template`}</HeaderText>
      <Space style={{flex: 1}} />
      <Button text={'Update'} isSubmitting={isSubmitting} width={80} onPress={handleSubmit} />
    </EditSmsTemplateHeaderContainer>
  );
};

const isCustomerCategory = ({form, field}) => {
  return _.get(form.values, `${field}.category`) === TextMessageTemplateCategory.CUSTOMER;
};

const isSystemTemplate = ({form}) => {
  return (
    _.get(form.values, 'textMessageTemplateForm.systemTemplateKind') !==
    TextMessageSystemKind.CUSTOM
  );
};

const getTextMessageTemplateVariableKindToExclude = ({form, textMessageTemplate}) => {
  if (!isSystemTemplate({form})) {
    return [VariableKind.INVOICE, VariableKind.JOB];
  }

  const textMessageTemplateVariableKinds =
    TextMessageSystemKind.ATTRIBUTES[textMessageTemplate.systemTemplateKind].variableKind;

  return VariableKind.VALUES.filter((variableKind) => {
    return !textMessageTemplateVariableKinds.includes(variableKind);
  });
};

const EditSmsTemplateContent = ({textMessageTemplate}) => {
  const {navigator} = useNavigationDOM();

  const updateSmsTemplateToast = useToast({
    ToastComponent: SuccessToast,
    message: `Sms template updated!`,
    isClosable: true,
  });

  const textMessageTemplateForm = TextMessageTemplateForm.edit({textMessageTemplate});
  const {form, handleSubmit} = useUpdateTextMessageTemplateMutation({
    textMessageTemplateForm,
    onSuccess: () => {
      updateSmsTemplateToast.handleToast();
      navigator.push(`/settings/communication/sms-templates`);
    },
    onError: (errors) => console.log({errors}),
  });

  const field = 'textMessageTemplateForm';

  const textTemplateChangesFoundModal = useModal({name: 'Text Template Changes Found Modal'});

  const variableKindToExclude = getTextMessageTemplateVariableKindToExclude({
    form,
    textMessageTemplate,
  });

  return (
    <Container>
      <EditSmsTemplatePageHeader
        form={form}
        textTemplateChangesFoundModal={textTemplateChangesFoundModal}
        handleSubmit={handleSubmit}
        navigator={navigator}
      />
      <PageContainer>
        <SmsTemplateFieldsContainer>
          <SubheadingText>Template Details</SubheadingText>
          <Space height={16} />
          <SmsTemplatesField
            form={form}
            field={field}
            organization={textMessageTemplate.organization}
          />
        </SmsTemplateFieldsContainer>
        {(isCustomerCategory({form, field}) || !_.isEmpty(variableKindToExclude)) && (
          <SmsTemplateVariablesContainer>
            <SubheadingText>Variables</SubheadingText>
            <Space height={24} />
            <VariableList
              organization={textMessageTemplate.organization}
              hardcodedSystemVariables={HardcodedSystemVariables.getProjectAndFilteredVariablesByKind(
                HardcodedSystemVariables.EMAIL_VARIABLES,
                [],
              )}
              variableKindsToExclude={variableKindToExclude}
              isCollapsible={false}
              containerStyle={{backgroundColor: colors.white}}
            />
          </SmsTemplateVariablesContainer>
        )}
      </PageContainer>
      <ActionModal
        title={'Exit this page?'}
        message={'Any unsaved changes will be deleted.'}
        icon={Icon.ExclamationTriangle}
        color={colors.red.warning}
        key={textTemplateChangesFoundModal.key}
        isOpen={textTemplateChangesFoundModal.isOpen}
        handlePrimaryAction={() => {
          navigator.push(`/settings/communication/sms-templates`);
        }}
        handleSecondaryAction={textTemplateChangesFoundModal.handleClose}
        primaryActionText={'Exit Page'}
        secondaryActionText={'Cancel'}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditSmsTemplateContent.fragment = gql`
  ${SmsTemplatesField.fragment}
  ${TextMessageTemplateForm.edit.fragment}
  ${VariableList.fragment}

  fragment EditSmsTemplateContent on TextMessageTemplate {
    id
    systemTemplateKind
    organization {
      id
      ...SmsTemplatesField
      ...VariableList
    }
    ...TextMessageTemplateForm_edit
  }
`;

export default EditSmsTemplateContent;
