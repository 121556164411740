// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CapacityCalendarSlotMetricForm, {
  CapacityCalendarSlotMetricFormType,
} from '@shared/modules/CapacityCalendarSetting/forms/CapacityCalendarSlotMetricForm';

export interface CapacityCalendarSettingFormType {
  capacityCalendarSettingId: number | null;
  organizationId: string | null;
  totalTruckCapacity: string | null;
  amTruckCapacity: string | null;
  pmTruckCapacity: string | null;
  totalCrewCapacity: string | null;
  amCrewCapacity: string | null;
  pmCrewCapacity: string | null;
  totalCrewHoursCapacity: string | null;
  crewForPositionCapacity: string | null;
  branchToShowIds: number[];
  moverPositionId: number | null;
  secondaryMoverPositionId: number | null;
  capacityCalendarSlotMetricForms: CapacityCalendarSlotMetricFormType[];
}

const edit = withFragment(
  (capacityCalendarSetting: any): CapacityCalendarSettingFormType => ({
    capacityCalendarSettingId: capacityCalendarSetting.id,
    organizationId: capacityCalendarSetting.organizationId,
    totalTruckCapacity: capacityCalendarSetting.totalTruckCapacity,
    amTruckCapacity: capacityCalendarSetting.amTruckCapacity,
    pmTruckCapacity: capacityCalendarSetting.pmTruckCapacity,
    totalCrewCapacity: capacityCalendarSetting.totalCrewCapacity,
    amCrewCapacity: capacityCalendarSetting.amCrewCapacity,
    pmCrewCapacity: capacityCalendarSetting.pmCrewCapacity,
    totalCrewHoursCapacity: capacityCalendarSetting.totalCrewHoursCapacity,
    branchToShowIds: capacityCalendarSetting.branchToShowIds,
    moverPositionId: capacityCalendarSetting.moverPositionId,
    secondaryMoverPositionId: capacityCalendarSetting.secondaryMoverPositionId,
    crewForPositionCapacity: capacityCalendarSetting.crewForPositionCapacity,
    capacityCalendarSlotMetricForms: capacityCalendarSetting.activeCapacityCalendarSlotMetrics.map(
      (capacityCalendarSlotMetric: any) => {
        return CapacityCalendarSlotMetricForm.edit(capacityCalendarSlotMetric);
      },
    ),
  }),
  gql`
    ${CapacityCalendarSlotMetricForm.edit.fragment}
    fragment CapacityCalendarSettingForm_edit on CapacityCalendarSetting {
      id
      organizationId
      totalTruckCapacity
      amTruckCapacity
      pmTruckCapacity
      totalCrewCapacity
      amCrewCapacity
      pmCrewCapacity
      totalCrewHoursCapacity
      crewForPositionCapacity
      branchToShowIds
      moverPositionId
      secondaryMoverPositionId
      activeCapacityCalendarSlotMetrics {
        id
        ...CapacityCalendarSlotMetricForm_edit
      }
    }
  `,
);

const toForm = (capacityCalendarSetting: any): CapacityCalendarSettingFormType => {
  return {
    capacityCalendarSettingId: capacityCalendarSetting.capacityCalendarSettingId,
    organizationId: capacityCalendarSetting.organizationId,
    totalTruckCapacity: capacityCalendarSetting.totalTruckCapacity,
    amTruckCapacity: capacityCalendarSetting.amTruckCapacity,
    pmTruckCapacity: capacityCalendarSetting.pmTruckCapacity,
    totalCrewCapacity: capacityCalendarSetting.totalCrewCapacity,
    amCrewCapacity: capacityCalendarSetting.amCrewCapacity,
    pmCrewCapacity: capacityCalendarSetting.pmCrewCapacity,
    totalCrewHoursCapacity: capacityCalendarSetting.totalCrewHoursCapacity,
    branchToShowIds: capacityCalendarSetting.branchToShowIds,
    moverPositionId: capacityCalendarSetting.moverPositionId,
    secondaryMoverPositionId: capacityCalendarSetting.secondaryMoverPositionId,
    crewForPositionCapacity: capacityCalendarSetting.crewForPositionCapacity,
    capacityCalendarSlotMetricForms: capacityCalendarSetting.capacityCalendarSlotMetricForms.map(
      (capacityCalendarSlotMetric: any) => {
        return CapacityCalendarSlotMetricForm.toForm(capacityCalendarSlotMetric);
      },
    ),
  };
};

const toMutation = (capacityCalendarSettingForm: CapacityCalendarSettingFormType): any => {
  return {
    capacityCalendarSettingId: capacityCalendarSettingForm.capacityCalendarSettingId,
    organizationId: capacityCalendarSettingForm.organizationId,
    totalTruckCapacity: _.parseInt(capacityCalendarSettingForm.totalTruckCapacity as string),
    amTruckCapacity: _.parseInt(capacityCalendarSettingForm.amTruckCapacity as string),
    pmTruckCapacity: _.parseInt(capacityCalendarSettingForm.pmTruckCapacity as string),
    totalCrewCapacity: _.parseInt(capacityCalendarSettingForm.totalCrewCapacity as string),
    amCrewCapacity: _.parseInt(capacityCalendarSettingForm.amCrewCapacity as string),
    pmCrewCapacity: _.parseInt(capacityCalendarSettingForm.pmCrewCapacity as string),
    totalCrewHoursCapacity: _.parseInt(
      capacityCalendarSettingForm.totalCrewHoursCapacity as string,
    ),
    branchToShowIds: capacityCalendarSettingForm.branchToShowIds,
    moverPositionId: capacityCalendarSettingForm.moverPositionId,
    secondaryMoverPositionId: capacityCalendarSettingForm.secondaryMoverPositionId,
    crewForPositionCapacity: _.parseInt(
      capacityCalendarSettingForm.crewForPositionCapacity as string,
    ),
    capacityCalendarSlotMetricForms:
      capacityCalendarSettingForm.capacityCalendarSlotMetricForms.map(
        (capacityCalendarSlotMetricForm: CapacityCalendarSlotMetricFormType) => {
          return CapacityCalendarSlotMetricForm.toMutation(capacityCalendarSlotMetricForm);
        },
      ),
  };
};

const CapacityCalendarSettingForm = {
  edit,
  toForm,
  toMutation,
};

export default CapacityCalendarSettingForm;
