// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CommercialMaterialForm from '@shared/modules/CommercialCatalog/forms/CommercialMaterialForm';

const useUpdateCommercialMaterialMutation = ({commercialMaterialForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      commercialMaterialForm: CommercialMaterialForm.toForm(commercialMaterialForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCommercialMaterialMutation.mutation,
    variables: {
      commercialMaterialForm: CommercialMaterialForm.toMutation(form.values.commercialMaterialForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCommercialMaterialMutation.mutation = gql`
  mutation useUpdateCommercialMaterialMutation($commercialMaterialForm: CommercialMaterialForm!) {
    response: updateCommercialMaterial(commercialMaterialForm: $commercialMaterialForm) {
      ${gql.errors}
      commercialMaterial {
        id
      }
    }
  }
`;

export default useUpdateCommercialMaterialMutation;
