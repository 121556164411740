// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import JobTypeForm from '@shared/modules/Job/forms/JobTypeForm';

const useUpdateJobTypeIndexMutation = ({jobTypeForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      jobTypeForm: JobTypeForm.toForm(jobTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateJobTypeIndexMutation.mutation,
    variables: {
      jobTypeForm: JobTypeForm.toMutation(form.values.jobTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateJobTypeIndexMutation.mutation = gql`
  mutation useUpdateJobTypeIndexMutation($jobTypeForm: JobTypeForm!) {
    response: updateJobTypeIndex(jobTypeForm: $jobTypeForm) {
      ${gql.errors}
      jobType {
        id
        index
      }
    }
  }
`;

export default useUpdateJobTypeIndexMutation;
