// Libraries
import _ from 'lodash';

// App
import {Currency} from '@supermove/utils';

import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';

const _new = ({
  confirmationStepsId,
  kind,
  name = '',
  value = '',
  valueTwo = '',
  showByDefault = true,
}: any) => ({
  confirmationStepsId,
  kind,
  value,
  valueTwo,
  name,
  showByDefault,
  actionText: 'Continue',
});

const edit = (confirmationStep: any) => ({
  confirmationStepsId: confirmationStep.confirmationStepsId,
  kind: confirmationStep.kind,
  value: confirmationStep.value,

  // Since value two comes from a nested bjson value in the database we don't transform it to camelcase
  valueTwo: confirmationStep.value_two,

  showByDefault: confirmationStep.show_by_default,
  name: confirmationStep.name,
  uuid: confirmationStep.uuid,
  actionText: confirmationStep.action_text,
});

const toForm = ({
  confirmationStepsId,
  kind,
  value,
  valueTwo,
  name,
  uuid,
  showByDefault,
  actionText,
}: any) => {
  const valueIsCurrency = _.includes(
    [
      ConfirmationStepKind.CONFIRMATION_STEP_MAKE_DEPOSIT,
      ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD,
    ],
    kind,
  );
  if (valueIsCurrency) {
    value = Currency.toForm(value);
  }

  return {
    confirmationStepsId,
    kind,
    value,
    valueTwo,
    name,
    uuid,
    showByDefault,
    actionText,
  };
};

const toMutation = ({
  confirmationStepsId,
  kind,
  value,
  valueTwo,
  name,
  uuid,
  showByDefault,
  actionText,
}: any) => {
  const valueIsCurrency = _.includes(
    [
      ConfirmationStepKind.CONFIRMATION_STEP_MAKE_DEPOSIT,
      ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD,
    ],
    kind,
  );
  if (valueIsCurrency) {
    value = Currency.toMutation(value);
  }
  return {
    confirmationStepsId,
    kind,
    value,
    valueTwo,
    name,
    uuid,
    showByDefault,
    actionText,
  };
};

const ConfirmationStepForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ConfirmationStepForm;
