// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TextMessageSystemKind from '@shared/modules/Sms/enums/TextMessageSystemKind';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SmsTemplatesTable from 'modules/Organization/Settings/Communication/components/SmsTemplatesTable';

const PageContainer = Styled.View`
  width: 100%;
  background-color: ${colors.white};
  flex-direction: row;
  height: 100%;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SmsTemplateContainer = Styled.View`
  flex: 2;
  border-right-width: 1px;
  border-color: ${colors.gray.border}
`;

const SmsTemplateContentContainer = Styled.View`
  flex: 1;
  padding: 24px;
`;

const SmsTemplateRenderContainer = Styled.View`
  flex: 1;
`;
const SubheadingText = Styled.Text`
  ${Typography.Subheading}
`;
const SmsTemplateContentHeaderContainer = Styled.View`
  padding-horizontal: 24px;
  padding-vertical: 12px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border}
`;

const CustomSmsTemplateContainer = Styled.View`
`;

const SystemSmsTemplateContainer = Styled.View`
`;

const TextMessageTemplatePreviewContainer = Styled.View`
  background-color: ${colors.gray.background};
  padding: 24px;
  min-height: 100vh;
`;

const BlankTextTemplatePreview = Styled.View`
  height: 100%;
  width: 100%;
  padding: 32px;
  align-items: center;
`;

const BlankTextTemplatePreviewText = Styled.Text`
  ${Typography.Heading2};
  color: ${colors.gray.tertiary};
  width: 400px;
  text-align: center;
`;

const SectionBody = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.primary};
  background-color: ${colors.white};
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border}
  padding: 24px;
  min-height: 300px;
`;

const SmsTemplateContentHeader = () => {
  const {navigator} = useNavigationDOM();

  return (
    <SmsTemplateContentHeaderContainer>
      <Row>
        <SubheadingText>SMS Templates</SubheadingText>
        <Space style={{flex: 1}} />
        <Button
          text={'Create Custom Template'}
          iconLeft={Icon.Plus}
          onPress={() => {
            navigator.push(`/settings/communication/sms-templates/create`);
          }}
        />
      </Row>
    </SmsTemplateContentHeaderContainer>
  );
};

const SmsTemplateRendererHeader = ({textMessageTemplate}) => {
  const {navigator} = useNavigationDOM();

  return (
    <SmsTemplateContentHeaderContainer>
      <Row>
        <SubheadingText numberOfLines={1}>{textMessageTemplate.name}</SubheadingText>
        <Space style={{flex: 1}} />
        <SecondaryButton
          text={'Edit Template'}
          iconLeft={Icon.Pen}
          onPress={() => {
            navigator.push(`/settings/communication/sms-templates/${textMessageTemplate.uuid}`);
          }}
        />
      </Row>
    </SmsTemplateContentHeaderContainer>
  );
};

const SmsTemplatesContent = ({
  setSelectedTextMessageTemplate,
  selectedTextMessageTemplate,
  textMessageTemplates,
  organization,
  loading,
  refetch,
}) => {
  const customTextMessageTemplates = _.filter(textMessageTemplates, (textMessageTemplate) => {
    return textMessageTemplate.systemTemplateKind === TextMessageSystemKind.CUSTOM;
  });

  const systemTextMessageTemplates = _.filter(textMessageTemplates, (textMessageTemplate) => {
    return textMessageTemplate.systemTemplateKind !== TextMessageSystemKind.CUSTOM;
  });
  return (
    <React.Fragment>
      <SmsTemplateContentHeader />
      <SmsTemplateContentContainer>
        <ScrollView style={{flex: 1}}>
          <CustomSmsTemplateContainer>
            <SubheadingText>Custom SMS Templates</SubheadingText>
            <Space height={16} />
            <SmsTemplatesTable
              setSelectedTextMessageTemplate={setSelectedTextMessageTemplate}
              selectedTextMessageTemplate={selectedTextMessageTemplate}
              textMessageTemplates={customTextMessageTemplates}
              organization={organization}
              loading={loading}
              refetch={refetch}
            />
          </CustomSmsTemplateContainer>
          <SystemSmsTemplateContainer>
            <SubheadingText>System SMS Templates</SubheadingText>
            <Space height={16} />
            <SmsTemplatesTable
              selectedTextMessageTemplate={selectedTextMessageTemplate}
              setSelectedTextMessageTemplate={setSelectedTextMessageTemplate}
              textMessageTemplates={systemTextMessageTemplates}
              organization={organization}
              loading={loading}
              refetch={refetch}
              isSystemTemplate
            />
          </SystemSmsTemplateContainer>
        </ScrollView>
      </SmsTemplateContentContainer>
    </React.Fragment>
  );
};

const SmsTemplatesRendererContent = ({textMessageTemplate}) => {
  return (
    <SmsTemplateRenderContainer>
      {_.isEmpty(textMessageTemplate) ? (
        <BlankTextTemplatePreview>
          <BlankTextTemplatePreviewText>
            {'Select a template to preview.'}
          </BlankTextTemplatePreviewText>
        </BlankTextTemplatePreview>
      ) : (
        <React.Fragment>
          <ScrollView style={{flex: 1}}>
            <SmsTemplateRendererHeader textMessageTemplate={textMessageTemplate} />
            <TextMessageTemplatePreviewContainer>
              <SectionBody>{textMessageTemplate.body}</SectionBody>
            </TextMessageTemplatePreviewContainer>
          </ScrollView>
        </React.Fragment>
      )}
    </SmsTemplateRenderContainer>
  );
};

const SmsTemplatesPage = () => {
  const {data, loading, refetch} = useQuery(SmsTemplatesPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  const [selectedTextMessageTemplate, setSelectedTextMessageTemplate] = useState({});

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <React.Fragment>
      <PageContainer>
        <SmsTemplateContainer>
          <SmsTemplatesContent
            selectedTextMessageTemplate={selectedTextMessageTemplate}
            setSelectedTextMessageTemplate={setSelectedTextMessageTemplate}
            textMessageTemplates={data.viewer.viewingOrganization.textMessageTemplates}
            organization={data.viewer.viewingOrganization}
            loading={loading}
            refetch={refetch}
          />
        </SmsTemplateContainer>
        <SmsTemplatesRendererContent textMessageTemplate={selectedTextMessageTemplate} />
      </PageContainer>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SmsTemplatesPage.query = gql`
  ${SmsTemplatesTable.fragment}

  query SmsTemplatesPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        textMessageTemplates {
          id
          ...SmsTemplatesTable
        }
      }
    }
  }
`;

export default SmsTemplatesPage;
