// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import TeamKind from '@shared/modules/TaskManagement/enums/TeamKind';
import TeamForm from '@shared/modules/TaskManagement/forms/TeamForm';
import useCreateTeamMutation from '@shared/modules/TaskManagement/hooks/useCreateTeamMutation';
import TeamDrawerFields from 'modules/Organization/Settings/Staff/components/TeamDrawerFields';

const CreateTeamDrawer = ({isOpen, handleClose, organization, isCrewTeam}) => {
  const teamForm = TeamForm.new({
    organizationId: organization.id,
    teamKind: isCrewTeam ? TeamKind.MOVER : TeamKind.OFFICE,
  });
  const {form, handleSubmit, submitting} = useCreateTeamMutation({
    teamForm,
    onSuccess: () => {
      successToast.handleToast();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: `${form.values.teamForm.name} created!`,
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Create Team'}
      primaryActionText={'Save'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      shouldCloseOnClickOutside={!form.dirty}
    >
      <TeamDrawerFields
        form={form}
        field={'teamForm'}
        organization={organization}
        isCrewTeam={isCrewTeam}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateTeamDrawer.fragment = gql`
  ${TeamDrawerFields.fragment}
  fragment CreateTeamDrawer on Organization {
    id
    ...TeamDrawerFields
  }
`;

export default CreateTeamDrawer;
