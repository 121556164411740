// Libraries
import React from 'react';

// Supermove
import {useEffect} from '@supermove/hooks';

// App
import {useSSE} from '@shared/modules/SSE/hooks/useSSE';
import GlobalNavigationSidebarItem from 'modules/App/Global/components/GlobalNavigationSidebarItem';
import useAppContext from 'modules/App/context/useAppContext';

interface SidebarItemWithSSERefetchContentProps {
  sseRefetchTrigger: string;
  count: number;
  name: string;
  selected: boolean;
  source: string;
  to: string;
  refetch: () => void;
  startPolling: (interval: number) => void;
  stopPolling: () => void;
  loading: boolean;
  handleClose: () => void;
}

export const SidebarItemWithSSERefetch = ({
  sseRefetchTrigger,
  count,
  name,
  selected,
  source,
  to,
  refetch,
  startPolling,
  stopPolling,
  loading,
  handleClose,
}: SidebarItemWithSSERefetchContentProps) => {
  const {latestEvent, connected, subscribedChannels} = useSSE([sseRefetchTrigger]);
  const {isGlobalNavSidebarExpanded} = useAppContext();
  useEffect(() => {
    if (latestEvent && !loading) {
      console.log('[SSE] updating sidebar data based on sse event');
      refetch();
    }
  }, [latestEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!connected) {
      console.log('[SSE]: starting polling');
      startPolling(1000 * 60);
    } else {
      console.log('[SSE]: stopping polling');
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [connected, startPolling, stopPolling]);

  return (
    <GlobalNavigationSidebarItem
      notificationCount={count}
      name={name}
      isSelected={selected}
      source={source}
      to={to}
      isExpanded={isGlobalNavSidebarExpanded}
      handleClose={handleClose}
      onPress={() => {}}
    />
  );
};
