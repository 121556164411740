// Libraries
import React from 'react';

// App
import {Emoji, Modal, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View<{width: number}>`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  font-weight: 500;
  text-align: center;
`;

const Subtitle = Styled.Text`
  ${Typography.Body3}
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  align-items: center;
  margin-vertical: 20px;
`;

const SubmitButton = Styled.Button`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 20px;
`;

const SubmitText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

interface StripeTerminalReaderConnectErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const StripeTerminalReaderConnectErrorModal = ({
  isOpen,
  onClose,
  children,
}: StripeTerminalReaderConnectErrorModalProps) => (
  <React.Fragment>
    {isOpen && (
      <Modal initialIsOpen trigger={() => null}>
        {({handleClose}: {handleClose: () => void}) => (
          <Container pointerEvents={'box-none'}>
            <Wrapper width={400}>
              <Circle>
                <Emoji component={EmojiText} name={'warning'} />
              </Circle>
              <Content>
                <Title>Could not connect to reader</Title>
                <Subtitle>
                  Please make sure the reader is on and nearby. If you continue to have trouble,
                  please contact us.
                </Subtitle>
                <Actions>
                  <SubmitButton
                    onPress={() => {
                      onClose();
                      handleClose();
                    }}
                  >
                    <SubmitText>Okay</SubmitText>
                  </SubmitButton>
                </Actions>
              </Content>
            </Wrapper>
          </Container>
        )}
      </Modal>
    )}
    {children}
  </React.Fragment>
);

export default StripeTerminalReaderConnectErrorModal;
