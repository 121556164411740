// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import Callout from '@shared/design/components/Callout';

interface ErrorCalloutProps {
  text?: string;
  width?: number | null;
  children?: React.ReactNode;
}

const ErrorCallout = ({text = '', width = null, children}: ErrorCalloutProps) => {
  return (
    // @ts-expect-error TS(2322): Type '{ children: ReactNode; text: string; width: ... Remove this comment to see the full error message
    <Callout
      text={text}
      width={width}
      textColor={colors.red.warning}
      backgroundColor={colors.red.accent}
      borderColor={colors.red.warning}
      icon={Icon.Ban}
      iconColor={colors.red.warning}
    >
      {children}
    </Callout>
  );
};

ErrorCallout.Text = Callout.Text;
ErrorCallout.LinkText = Callout.LinkText;
ErrorCallout.Link = Callout.Link;

// --------------------------------------------------
// Props
// --------------------------------------------------
ErrorCallout.propTypes = {
  text: PropTypes.string,
  width: PropTypes.number,
};

ErrorCallout.defaultProps = {
  text: '',
  width: null,
};

export default ErrorCallout;
