/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {DateInput} from '@supermove/components';

// App
import Field from 'modules/App/components/Field';

const EndDateFilter = ({form, index}) => {
  return (
    <Field
      {...form}
      index={index}
      component={DateInput}
      name={'reportViewFiltersForm.endDate'}
      label={'End Date'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        placeholder: 'End date',
        setFieldValue: form.setFieldValue,
        style: {
          width: 200,
        },
      }}
    />
  );
};

export default EndDateFilter;
