// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DeleteBatchForm from '@shared/modules/Integration/forms/DeleteBatchForm';

const useDeleteBatchMutation = ({
  onSuccess,
  onError,
  deleteBatchForm,
}: {
  onSuccess: () => void;
  onError: () => void;
  deleteBatchForm: any;
}) => {
  const form = useForm({
    initialValues: {
      deleteBatchForm: DeleteBatchForm.toForm(deleteBatchForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteBatchMutation.mutation,
    variables: {
      deleteBatchForm: DeleteBatchForm.toMutation(form.values.deleteBatchForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteBatchMutation.mutation = gql`
  mutation useDeleteBatchMutation($deleteBatchForm: DeleteBatchForm!) {
    response: deleteBatch(deleteBatchForm: $deleteBatchForm) {
      ${gql.errors}
    }
  }
`;

export default useDeleteBatchMutation;
