// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useQuery} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import CollapsibleContent from '@shared/design/components/CollapsibleContent';
import CreateProjectClaimDrawer from 'modules/Project/Claim/components/CreateProjectClaimDrawer';
import ProjectClaims from 'modules/Project/Claim/components/ProjectClaims';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading1}
`;

const BlockHeader = ({job, refetch}) => {
  const createClaimDrawer = useDrawer({name: 'Create Claim Drawer'});

  return (
    <React.Fragment>
      <Row>
        <HeaderText>Claims</HeaderText>
        <Space style={{flex: 1}} />
        <SecondaryButton
          iconLeft={Icon.Plus}
          text={'Create Claim'}
          isSmall
          onPress={createClaimDrawer.handleOpen}
        />
      </Row>
      <CreateProjectClaimDrawer
        key={createClaimDrawer.key}
        project={job.project}
        isOpen={createClaimDrawer.isOpen}
        handleClose={createClaimDrawer.handleClose}
        onSuccess={() => {
          createClaimDrawer.handleClose();
          refetch();
        }}
      />
    </React.Fragment>
  );
};

const JobProjectClaimsBlockContent = ({job}) => {
  const {data, loading, refetch} = useQuery(JobProjectClaimsBlock.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectUuid: job.project.uuid},
  });

  return (
    <Container>
      <BlockHeader job={job} refetch={refetch} />
      <Space height={24} />
      <CollapsibleContent title={'Claims'}>
        <Loading loading={loading}>
          {() => {
            const {project} = data;
            return <ProjectClaims project={project} refetch={refetch} />;
          }}
        </Loading>
      </CollapsibleContent>
    </Container>
  );
};

const JobProjectClaimsBlock = ({job}) => {
  // While attachments for claims can only be added from this block, they can be viewed
  // and deleted from the project attachments block. When an attachment for a claim gets
  // gets deleted, we refresh this content so each claim updates its attachments.
  return <JobProjectClaimsBlockContent key={job.project.claimAttachmentsHash} job={job} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectClaimsBlock.listener = gql`
  fragment JobProjectClaimsBlock_listener on Project {
    id
    claimAttachmentsHash
  }
`;

JobProjectClaimsBlock.fragment = gql`
  ${JobProjectClaimsBlock.listener}
  ${CreateProjectClaimDrawer.fragment}
  fragment JobProjectClaimsBlock on Job {
    id
    project {
      id
      uuid
      ...JobProjectClaimsBlock_listener
      ...CreateProjectClaimDrawer
    }
  }
`;

JobProjectClaimsBlock.query = gql`
  ${JobProjectClaimsBlock.listener}
  ${ProjectClaims.fragment}
  query JobProjectClaimsBlock($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      ...JobProjectClaimsBlock_listener
      ...ProjectClaims
    }
  }
`;

export default JobProjectClaimsBlock;
