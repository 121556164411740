// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Modal, ScrollView, Space, Styled, Tabs} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useTabs} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import UpdateProjectValuesForm from '@shared/modules/Billing/forms/UpdateProjectValuesForm';
import useUpdateProjectValuesMutation from '@shared/modules/Billing/hooks/useUpdateProjectValuesMutation';
import ValueFields from 'modules/Project/Billing/components/ValueFields';

const Touchable = Styled.Touchable`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SplitRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: ${(props) => (props.isMobile ? 'flex-start' : 'center')};
  justify-content: center;
  background-color: ${(props) => (props.isMobile ? colors.white : null)};
`;

const ModalContainer = Styled.View`
  width: 800px;
  background-color: ${colors.white};
  border-radius: ${(props) => (props.isMobile ? '0px' : '16px')};
  flex: 1;
`;

const HeaderContainer = Styled.View`
  padding-horizontal: 36px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading6}
`;

const TitleCaptionText = Styled.Text`
  ${Typography.Body2}
  color: ${colors.gray.tertiary};
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-left: 36px;
  padding-right: 24px;
  padding-vertical: 16px;
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const Button = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${(props) => props.color};
`;

const ActivityIndicator = Styled.Loading`
`;

const Header = ({handleClose}) => {
  return (
    <HeaderContainer>
      <SplitRow>
        <TitleText>Edit Billing Values</TitleText>
        <Touchable activeOpacity={0.8} onPress={handleClose}>
          <Icon source={Icon.Times} size={24} color={colors.gray.primary} />
        </Touchable>
      </SplitRow>
      <Space height={2} />
      <TitleCaptionText>
        Enter billing values below and select "Save & Preview" to see the updates.
      </TitleCaptionText>
    </HeaderContainer>
  );
};

const Footer = ({submitting, handleSubmit, handleClose}) => {
  return (
    <FooterContainer>
      <Row>
        <Button
          color={colors.gray.border}
          onPress={handleClose}
          activeOpacity={0.8}
          disabled={submitting}
        >
          <ButtonText color={colors.gray.tertiary}>Cancel</ButtonText>
        </Button>
        <Space width={16} />
        <Button
          color={submitting ? colors.gray.border : colors.blue.interactive}
          activeOpacity={0.8}
          disabled={submitting}
          onPress={handleSubmit}
        >
          {submitting ? (
            <ActivityIndicator color={colors.white} size={'small'} />
          ) : (
            <ButtonText color={colors.white}>Save</ButtonText>
          )}
        </Button>
      </Row>
    </FooterContainer>
  );
};

const SectionContainer = Styled.View`
`;

const Title = Styled.Text`
  ${Typography.Label1}
`;

const Section = ({title, children, style}) => {
  return (
    <SectionContainer style={style}>
      <Title>{title}</Title>
      <Space height={12} />
      {children}
    </SectionContainer>
  );
};

const ProjectValuesSection = ({form}) => {
  return (
    <Section title={'Project Information'} style={{paddingHorizontal: 36, zIndex: 100}}>
      <ValueFields field={`updateProjectValuesForm.valueForms`} form={form} />
    </Section>
  );
};

const TabsWrapper = Styled.View`
  padding-horizontal: 36px;
`;

const Tab = ({tab, isSelected, handlePress}) => {
  return (
    <Tabs.TabContainer
      onPress={handlePress}
      isSelected={isSelected}
      style={{paddingHorizontal: 12}}
    >
      <Tabs.TabText isSelected={isSelected}>{tab.label}</Tabs.TabText>
    </Tabs.TabContainer>
  );
};

const JobValuesPage = ({field, form}) => {
  return (
    <React.Fragment>
      <Section title={'Job Information'} style={{zIndex: 99}}>
        <ValueFields field={`${field}.valueForms`} form={form} />
      </Section>
    </React.Fragment>
  );
};

const ProjectJobValuesSection = ({form}) => {
  const {updateJobValuesForms} = form.values.updateProjectValuesForm;
  const tabs = useTabs({initialIndex: 0});
  const responsive = useResponsive();
  // Disable swipe on mobile so that horizontal scrolling scrolls the screen rather than the slide
  return (
    <TabsWrapper>
      <Tabs tabs={tabs} TabComponent={Tab} disabledSwipe={responsive.mobile}>
        {updateJobValuesForms.map((updateJobValuesForm, index) => (
          <Tabs.Slide key={index} tab={{label: updateJobValuesForm.name, value: index}}>
            <Space height={20} />
            <JobValuesPage
              field={`updateProjectValuesForm.updateJobValuesForms.${index}`}
              form={form}
            />
          </Tabs.Slide>
        ))}
      </Tabs>
    </TabsWrapper>
  );
};

const UpdateProjectValuesModalContent = ({project, handleClose, refetch}) => {
  const responsive = useResponsive();
  const updateProjectValuesForm = UpdateProjectValuesForm.edit(project);
  const {form, submitting, handleSubmit} = useUpdateProjectValuesMutation({
    updateProjectValuesForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  return (
    <ScreenContainer pointerEvents={'box-none'} isMobile={responsive.mobile}>
      <ScrollView
        horizontal
        style={{flex: 1, width: responsive.mobile ? '100%' : 800}}
        contentContainerStyle={{alignSelf: 'center', height: responsive.mobile ? '100%' : 648}}
      >
        <ModalContainer isMobile={responsive.mobile}>
          <Space height={32} />
          <Header handleClose={handleClose} />
          <ScrollView style={{flex: 1}}>
            <Space height={20} />
            <ProjectValuesSection form={form} />
            <Space height={24} />
            <ProjectJobValuesSection form={form} />
            <Space height={20} />
          </ScrollView>
          <Footer submitting={submitting} handleSubmit={handleSubmit} handleClose={handleClose} />
        </ModalContainer>
      </ScrollView>
    </ScreenContainer>
  );
};

const UpdateProjectValuesModal = ({isOpen, projectUuid, handleClose, refetch}) => {
  const {loading, data} = useQuery(UpdateProjectValuesModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      uuid: projectUuid,
    },
  });

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <Loading loading={loading}>
        {() =>
          data ? (
            <UpdateProjectValuesModalContent
              project={data.project}
              handleClose={handleClose}
              refetch={refetch}
            />
          ) : null
        }
      </Loading>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateProjectValuesModal.query = gql`
  ${UpdateProjectValuesForm.edit.fragment}

  query UpdateProjectValuesModal($uuid: String!) {
    project(uuid: $uuid) {
      id
      ...UpdateProjectValuesForm_edit
    }
  }
`;

export default UpdateProjectValuesModal;
