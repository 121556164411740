// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const FinalizeReportMoveMutation = ({reportMoveId, onSuccess, onError, children}) => (
  <Mutation
    variables={{
      reportMoveId,
    }}
    mutation={FinalizeReportMoveMutation.mutation}
    onCompleted={({response: {reportMove, errors}}) => {
      if (!errors) {
        onSuccess(reportMove);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
FinalizeReportMoveMutation.propTypes = {
  reportMoveId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

FinalizeReportMoveMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FinalizeReportMoveMutation.fragment = gql`
  fragment FinalizeReportMoveMutation on ReportMove {
    id
  }
`;

FinalizeReportMoveMutation.mutation = gql`
  mutation FinalizeReportMoveMutation(
    $reportMoveId: Int!,
  ) {
    response: finalizeReportMove(
      reportMoveId: $reportMoveId,
    ) {
      ${gql.errors}
      reportMove {
        id
        isFinal
        isFinalizable
        isSyncableToQuickbooks
        job {
          id
          calendarPrimaryStatus
        }
      }
    }
  }
`;

export default FinalizeReportMoveMutation;
