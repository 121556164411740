/**
 * Generic component that renders a PDF-able view of a document.
 */

/**
 * WARNING (warren)
 * This is a web component that renders HTML used to generate a PDF.
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import CustomerDocumentSignaturesList from './CustomerDocumentSignaturesList';
import DocumentHeaderOrganization from './DocumentHeaderOrganization';

const Container = Styled.View`
  width: 100%;
  background-color: ${colors.white};
`;

const DocumentPdfView = ({shouldPromptSignature, document}) => {
  return (
    <Container>
      <DocumentHeaderOrganization
        title={document.displayName}
        organization={document.job.project.organization}
      />
      <CustomerDocumentSignaturesList
        shouldPromptSignature={shouldPromptSignature}
        document={document}
      />
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DocumentPdfView.propTypes = {
  shouldPromptSignature: PropTypes.bool,
  document: PropTypes.object.isRequired,
};

DocumentPdfView.defaultProps = {
  shouldPromptSignature: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentPdfView.fragment = gql`
  ${DocumentHeaderOrganization.fragment}
  ${CustomerDocumentSignaturesList.fragment}

  fragment DocumentPdfView on Document {
    id
    displayName
    job {
      id
      project {
        id
        organization {
          id
          ...DocumentHeaderOrganization
        }
      }
    }
    ...CustomerDocumentSignaturesList
  }
`;

export default DocumentPdfView;
