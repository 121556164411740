// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import ClientCreditCardsRadioButtonList from 'modules/Client/components/ClientCreditCardsRadioButtonList';

const InvoiceCreditCardsRadioButtonList = ({
  invoice,
  handleSelectCard,
  selectedCardId,
  isDisabled,
  errorMessage,
}) => {
  const client = invoice.project.billingClient;
  const responsive = useResponsive();

  return (
    <ClientCreditCardsRadioButtonList
      client={client}
      handleSelectCard={handleSelectCard}
      selectedCardId={selectedCardId}
      isDisabled={isDisabled}
      errorMessage={errorMessage}
      responsive={responsive}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceCreditCardsRadioButtonList.fragment = gql`
  ${ClientCreditCardsRadioButtonList.fragment}

  fragment InvoiceCreditCardsRadioButtonList on Invoice {
    id
    project {
      id
      billingClient {
        id
        ...ClientCreditCardsRadioButtonList
      }
    }
  }
`;

export default InvoiceCreditCardsRadioButtonList;
