// Supermove
import {gql} from '@supermove/graphql';
import {Form, useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectTypeBillingSettingsForm, {
  ProjectTypeBillingSettingsFormType,
} from '@shared/modules/Project/forms/ProjectTypeBillingSettingsForm';

export type UpdateProjectTypeAccountingSettingsMutationFormType = Form<{
  projectTypeBillingSettingsForm: ProjectTypeBillingSettingsFormType;
}>;

const useUpdateProjectTypeAccountingSettingsMutation = ({
  projectTypeBillingSettingsForm,
  onSuccess,
  onError,
}: {
  projectTypeBillingSettingsForm: ProjectTypeBillingSettingsFormType;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}) => {
  const form = useForm<{projectTypeBillingSettingsForm: ProjectTypeBillingSettingsFormType}>({
    initialValues: {
      projectTypeBillingSettingsForm: ProjectTypeBillingSettingsForm.toForm(
        projectTypeBillingSettingsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectTypeAccountingSettingsMutation.mutation,
    variables: {
      projectTypeBillingSettingsForm: ProjectTypeBillingSettingsForm.toMutation(
        form.values.projectTypeBillingSettingsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateProjectTypeAccountingSettingsMutation.mutation = gql`
  mutation useUpdateProjectTypeAccountingSettingsMutation($projectTypeBillingSettingsForm: ProjectTypeBillingSettingsForm!){
    response: updateProjectTypeAccountingSettings(projectTypeBillingSettingsForm: $projectTypeBillingSettingsForm) {
      ${gql.errors}
      projectType{
        id
      }
    }
  }
`;

export default useUpdateProjectTypeAccountingSettingsMutation;
