// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Organization, Payment, PaymentMethod} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';

const getPaymentMethodOptions = (organization) => {
  if (organization.features.isEnabledCustomPaymentMethods) {
    return PaymentMethod.getOfficePaymentMethodOptions({organization});
  }
  const paymentMethods = Organization.getOfficePaymentMethods(organization);
  return Payment.getMethodDropdownOptions({methods: paymentMethods});
};

const BillingProjectPaymentMethodsDropdown = ({form, bill}) => {
  const options = getPaymentMethodOptions(bill.organization);

  return bill.organization.features.isEnabledCustomPaymentMethods ? (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={'beginPaymentForm.paymentMethodId'}
      label={'Payment Method'}
      isResponsive
      isRequired
      input={{
        options,
        placeholder: 'Choose payment method',
        setFieldValue: form.setFieldValue,
        onChangeValue: (value, option) => {
          form.setFieldValue('beginPaymentForm.method', option.kind);
          form.setFieldValue('beginPaymentForm.paymentMethodId', value);
        },
        style: {width: '100%'},
        isPortaled: true,
      }}
    />
  ) : (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={'beginPaymentForm.method'}
      label={'Payment Method'}
      isResponsive
      input={{
        options,
        required: !form.values.beginPaymentForm.method,
        placeholder: 'Choose payment method',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
        isPortaled: true,
      }}
    />
  );
};

BillingProjectPaymentMethodsDropdown.fragment = gql`
  ${Organization.getOfficePaymentMethods.fragment}
  ${PaymentMethod.getOfficePaymentMethodOptions.fragment}

  fragment BillingProjectPaymentMethodsDropdown on Bill {
    id
    organization {
      id
      features {
        isEnabledCustomPaymentMethods: isEnabled(feature: "CUSTOM_PAYMENT_METHODS")
      }
      ...Organization_getOfficePaymentMethods
      ...PaymentMethod_getOfficePaymentMethodOptions
    }
  }
`;

export default BillingProjectPaymentMethodsDropdown;
