import Expression, {
  GroupingExpression,
  IdentifierExpression,
  NumberLiteralExpression,
  SymbolExpression,
  CallExpression,
  StringLiteralExpression,
  OperatorExpression,
} from '@shared/ast/Expression';
import {Visitor} from '@shared/ast/Visitor';

import {FunctionDefinition, functions} from './library/functions';
import {
  prefixOperators,
  prefixOperatorImplementations,
  infixOperators,
  infixOperatorImplementations,
} from './library/operators';

interface FormulaEvaluatorPlugin {
  onVisit: (expression: Expression) => void;
  getResult: () => any;
}

interface FormulaEvaluatorConfig {
  customFunctionImplementations?: {[name: string]: FunctionDefinition};
  plugins?: FormulaEvaluatorPlugin[];
}

interface ValidIdentifierMap {
  [key: string]: boolean;
}

export class FormulaEvaluator extends Visitor {
  libraryFunctions: {[name: string]: FunctionDefinition};

  plugins: FormulaEvaluatorPlugin[];

  validIdentifiers: ValidIdentifierMap;

  constructor({customFunctionImplementations, plugins}: FormulaEvaluatorConfig = {}) {
    super();
    this.libraryFunctions = {...functions, ...(customFunctionImplementations || {})};
    this.plugins = plugins || [];
    this.validIdentifiers = this.makeValidIdentifiers();
  }

  makeValidIdentifiers = () => {
    const functionNames = Object.keys(this.libraryFunctions).reduce<{[key: string]: boolean}>(
      (acc: {[key: string]: boolean}, functionName: string) => {
        acc[functionName] = true;
        return acc;
      },
      {},
    );

    return {
      true: true,
      false: true,
      ...functionNames,
    };
  };

  evaluate = (expression: Expression): any => {
    return expression.accept(this);
  };

  handleVisit = (expression: Expression): void => {
    this.plugins.forEach((plugin) => {
      plugin.onVisit(expression);
    });
  };

  visitGrouping = (groupingExpression: GroupingExpression): any => {
    this.handleVisit(groupingExpression);
    return this.evaluate(groupingExpression.expression);
  };

  visitIdentifier = (identifierExpression: IdentifierExpression): any => {
    this.handleVisit(identifierExpression);
    if (!this.validIdentifiers[identifierExpression.value as string]) {
      throw new Error(`Unexpected identifier: "${identifierExpression.value}"`);
    }
    return identifierExpression.value;
  };

  visitNumberLiteral = (numberLiteralExpression: NumberLiteralExpression): any => {
    this.handleVisit(numberLiteralExpression);
    return numberLiteralExpression.value;
  };

  visitStringLiteral = (stringLiteralExpression: StringLiteralExpression): any => {
    this.handleVisit(stringLiteralExpression);
    return stringLiteralExpression.value;
  };

  visitSymbol = (symbolExpression: SymbolExpression): any => {
    this.handleVisit(symbolExpression);
    return symbolExpression.value;
  };

  visitCall = (callExpression: CallExpression): any => {
    this.handleVisit(callExpression);
    const libraryFunction = this.libraryFunctions[callExpression.name];
    if (!libraryFunction) {
      throw new TypeError(`${callExpression.name} is not a function.`);
    }
    libraryFunction.checkArity(callExpression.args);
    const resolvedArguments = callExpression.args.map((argument) => {
      return argument.accept(this);
    });
    libraryFunction.checkTypes(resolvedArguments);
    return libraryFunction.call(...resolvedArguments);
  };

  visitPrefixOperator = (operatorExpression: OperatorExpression): any => {
    this.handleVisit(operatorExpression);
    const libraryFunction = prefixOperatorImplementations[operatorExpression.operator.kind];
    libraryFunction.checkArity(operatorExpression.args);
    const argument = operatorExpression.args[0].accept(this);
    libraryFunction.checkTypes([argument]);
    return libraryFunction.call(argument);
  };

  visitInfixOperator = (operatorExpression: OperatorExpression): any => {
    this.handleVisit(operatorExpression);
    const libraryFunction = infixOperatorImplementations[operatorExpression.operator.kind];
    libraryFunction.checkArity(operatorExpression.args);
    const leftExpression = operatorExpression.args[0].accept(this);
    const rightExpression = operatorExpression.args[1].accept(this);
    libraryFunction.checkTypes([leftExpression, rightExpression]);
    return libraryFunction.call(leftExpression, rightExpression);
  };

  visitOperator = (operatorExpression: OperatorExpression): any => {
    this.handleVisit(operatorExpression);
    const operatorKind = operatorExpression.operator.kind;
    const argumentList = operatorExpression.args;
    // Dash is prefix if there is only one argument
    if (operatorKind === 'Dash') {
      return argumentList.length === 1
        ? this.visitPrefixOperator(operatorExpression)
        : this.visitInfixOperator(operatorExpression);
    }
    if (prefixOperators[operatorKind]) {
      return this.visitPrefixOperator(operatorExpression);
    }
    if (infixOperators[operatorKind]) {
      return this.visitInfixOperator(operatorExpression);
    }
  };

  visitExpression = (expression: Expression): any => {
    this.handleVisit(expression);
    return new Expression('');
  };
}
