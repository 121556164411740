// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {BillTypeModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export interface BillTypeFormType {
  billTypeId: string | undefined;
  billingLibraryId: string;
  organizationId: string | undefined;
  name: string;
  description: string;
  billItemTypeIds: string[];
  billRuleTypeIds: string[];
  isDeleted: boolean;
  createdById: string;
  updatedById: string;
}

const _new = ({
  billingLibraryId,
  organizationId,
  userId,
}: {
  billingLibraryId: string;
  organizationId: string | undefined;
  userId: string;
}): BillTypeFormType => ({
  billTypeId: undefined,
  billingLibraryId,
  organizationId,
  name: '',
  description: '',
  billItemTypeIds: [],
  billRuleTypeIds: [],
  isDeleted: false,
  createdById: userId,
  updatedById: userId,
});

const edit = withFragment(
  (billType: BillTypeModel, {userId}: {userId: string}): BillTypeFormType => ({
    billTypeId: billType.id,
    billingLibraryId: billType.billingLibrary.id,
    organizationId: billType.organizationId,
    name: billType.name,
    description: billType.description,
    billItemTypeIds: billType.billItemTypeIds,
    billRuleTypeIds: billType.billRuleTypeIds,
    isDeleted: billType.isDeleted,
    updatedById: userId,
    createdById: (billType as any).createdById,
  }),
  gql`
    fragment BillTypeForm_edit on BillType {
      id
      billingLibraryId
      organizationId
      name
      description
      billItemTypeIds
      billRuleTypeIds
      isDeleted
    }
  `,
);

export interface BillTypeFormToFormType {
  billTypeId: string | undefined;
  billingLibraryId: string;
  organizationId: string | undefined;
  name: string;
  description: string;
  billItemTypeIds: string[];
  billRuleTypeIds: string[];
  isDeleted: boolean;
  createdById: string;
  updatedById: string;
}

const toForm = ({
  billTypeId,
  billingLibraryId,
  organizationId,
  name,
  description,
  billItemTypeIds,
  billRuleTypeIds,
  isDeleted,
  createdById,
  updatedById,
}: BillTypeFormType): BillTypeFormToFormType => {
  return {
    billTypeId,
    billingLibraryId,
    organizationId,
    name,
    description,
    billItemTypeIds: billItemTypeIds.map((id: any) => _.toString(id)),
    billRuleTypeIds: billRuleTypeIds.map((id: any) => _.toString(id)),
    isDeleted,
    createdById,
    updatedById,
  };
};

const toMutation = ({
  billTypeId,
  billingLibraryId,
  organizationId,
  name,
  description,
  billItemTypeIds,
  billRuleTypeIds,
  isDeleted,
  createdById,
  updatedById,
}: BillTypeFormToFormType) => {
  return {
    billTypeId,
    billingLibraryId,
    organizationId,
    name,
    description,
    billItemTypeIds,
    billRuleTypeIds,
    isDeleted,
    createdById,
    updatedById,
  };
};

const BillTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default BillTypeForm;
