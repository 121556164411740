// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteSlotMutation = ({slotId, refetchQueries, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      slotId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteSlotMutation.mutation,
    variables: {
      slotId: form.values.slotId,
    },
    refetchQueries,
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useDeleteSlotMutation.mutation = gql`
  mutation useDeleteSlotMutation($slotId: Int!) {
    response: deleteSlot(slotId: $slotId) {
      ${gql.errors}
      slot {
        id
      }
    }
  }
`;

export default useDeleteSlotMutation;
