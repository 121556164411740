// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectTypeRoomTypeForm from '@shared/modules/Inventory/forms/ProjectTypeRoomTypeForm';

const useUpsertProjectTypeRoomTypeMutation = ({
  projectTypeRoomTypeForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      projectTypeRoomTypeForm: ProjectTypeRoomTypeForm.toForm(projectTypeRoomTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertProjectTypeRoomTypeMutation.mutation,
    variables: {
      projectTypeRoomTypeForm: ProjectTypeRoomTypeForm.toMutation(
        form.values.projectTypeRoomTypeForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpsertProjectTypeRoomTypeMutation.mutation = gql`
  mutation useUpsertProjectTypeRoomTypeMutation($projectTypeRoomTypeForm: ProjectTypeRoomTypeForm!) {
    response: upsertProjectTypeRoomType(projectTypeRoomTypeForm: $projectTypeRoomTypeForm) {
      ${gql.errors}
      projectTypeRoomType {
        id
      }
    }
  }
`;

export default useUpsertProjectTypeRoomTypeMutation;
