// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useState} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import UserRole from '@shared/modules/User/enums/UserRole';
import {PageLoadingIndicator} from 'modules/App/components';
import CompanyBranchesPanel from 'modules/Organization/Settings/Company/components/CompanyBranchesPanel';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';

const PageContentContainer = Styled.View`
  width: 100%;
  max-width: 800px;
`;

const Link = Styled.ButtonV2``;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const Header = () => {
  const {navigator} = useNavigationDOM();
  return (
    <React.Fragment>
      <Space height={32} />
      <CompanySettingsPage.TitleText>Branches</CompanySettingsPage.TitleText>
      <Space height={16} />
      <CompanySettingsPage.DescriptionText style={{maxWidth: '800px'}}>
        {`Manage the names for the main, corporate, franchise, and contractor branches. ` +
          `This information is internal and will only be shown in Supermove. `}
        <Link onPress={() => navigator.push('/settings/company/business-info')}>
          <LinkText>{`Customer-facing business information can be found here.`}</LinkText>
        </Link>
      </CompanySettingsPage.DescriptionText>
      <Space height={24} />
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyBranchesPage = () => {
  const [refetchKey, setRefetchKey] = useState(0);
  const {loading, data, refetch} = useQuery(OrganizationSettingsCompanyBranchesPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  const refetchWithKey = () => {
    setRefetchKey((key) => key + 1);
    refetch();
  };

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        const {viewingOrganization} = data.viewer;
        const hasEditPermissions = _.includes(UserRole.ADMIN_ROLES_PLUS_SUPER, data.viewer.role);
        return (
          <CompanySettingsPage HeaderComponent={Header}>
            <PageContentContainer key={refetchKey}>
              <CompanyBranchesPanel
                index={0}
                title={'Main Branch'}
                kind={OrganizationKind.MAIN}
                organization={viewingOrganization}
                refetch={refetchWithKey}
                hasEditPermissions={hasEditPermissions}
              />
              <CompanyBranchesPanel
                index={1}
                title={'Corporate Branches'}
                kind={OrganizationKind.BRANCH}
                organization={viewingOrganization}
                refetch={refetchWithKey}
                hasEditPermissions={hasEditPermissions}
              />
              <CompanyBranchesPanel
                index={2}
                title={'Franchise Branches'}
                kind={OrganizationKind.FRANCHISE}
                organization={viewingOrganization}
                refetch={refetchWithKey}
                hasEditPermissions={hasEditPermissions}
              />
              <CompanyBranchesPanel
                index={3}
                title={'Contractor Branches'}
                kind={OrganizationKind.CONTRACTOR}
                organization={viewingOrganization}
                refetch={refetchWithKey}
                hasEditPermissions={hasEditPermissions}
              />
              <Space height={100} />
            </PageContentContainer>
          </CompanySettingsPage>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyBranchesPage.query = gql`
  ${CompanyBranchesPanel.fragment}
  query OrganizationSettingsCompanyBranchesPage {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        ...CompanyBranchesPanel
      }
    }
  }
`;

export default OrganizationSettingsCompanyBranchesPage;
