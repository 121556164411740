const PUBLISHED = 'PUBLISHED' as const;
const DRAFT = 'DRAFT' as const;
const ARCHIVED = 'ARCHIVED' as const;

const LABELS = {
  [PUBLISHED]: 'Published',
  [DRAFT]: 'Draft',
  [ARCHIVED]: 'Archived',
} as const;

const DESCRIPTIONS = {
  [PUBLISHED]:
    'These reports are published and shown to customers in the Office App Reports Library.',
  [DRAFT]: 'These reports are drafts and not shown in the Office App Reports Library.',
  [ARCHIVED]:
    'These reports are archived and not shown in the Office App Reports Library. To edit a report, restore it first.',
} as const;

const DashboardStatus = {
  PUBLISHED,
  DRAFT,
  ARCHIVED,

  VALUES: [PUBLISHED, DRAFT, ARCHIVED],
  getLabel: (status: keyof typeof LABELS) => LABELS[status],
  getDescription: (status: keyof typeof DESCRIPTIONS) => DESCRIPTIONS[status],
};

export type DashboardStatusType = (typeof DashboardStatus.VALUES)[number];

export default DashboardStatus;
