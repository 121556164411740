/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import RoomForm from '@shared/modules/Inventory/forms/RoomForm';
import useCreateRoomMutation from '@shared/modules/Inventory/hooks/useCreateRoomMutation';
import Modal from 'components/Modal';
import Field from 'modules/App/components/Field';

const Container = Styled.View`
  padding-vertical: 20px;
`;

const Content = Styled.View`
  padding-horizontal: 20px;
`;

const Actions = Styled.View`
  align-items: stretch;
  padding-horizontal: 20px;
`;

const Button = Styled.LoadingButton`
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const CreateCustomRoomModal = ({isOpen, inventory, handleClose, onSuccess}) => {
  const responsive = useResponsive();
  const roomForm = RoomForm.new({inventoryId: inventory.id});
  const {form, submitting, handleSubmit} = useCreateRoomMutation({
    roomForm,
    onSuccess: ({room}) => {
      handleClose();
      onSuccess({room});
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      title={'Custom Room'}
      subtitle={'Edit room details.'}
      onClose={handleClose}
    >
      <Container>
        <Content>
          <Field
            {...form}
            name={'roomForm.name'}
            label={'Room Name'}
            input={{
              required: !form.values.roomForm.name,
              autoFocus: true,
              placeholder: 'Enter a custom room name',
              style: {
                width: responsive.mobile ? '100%' : 360,
              },
            }}
            style={{
              width: responsive.mobile ? '100%' : 360,
            }}
          />
          <Space height={20} />
          <Field
            {...form}
            name={'roomForm.floorNumber'}
            label={'Floor'}
            input={{
              placeholder: '#',
              style: {
                width: responsive.mobile ? '100%' : 360,
              },
            }}
            style={{
              width: responsive.mobile ? '100%' : 360,
            }}
          />
          <Space height={20} />
          <Field
            {...form}
            name={'roomForm.description'}
            label={'Additional Room Notes'}
            input={{
              placeholder: 'Enter additional notes',
              style: {
                width: responsive.mobile ? '100%' : 360,
              },
            }}
            style={{
              width: responsive.mobile ? '100%' : 360,
            }}
          />
        </Content>
        <Space height={20} />
        <Actions>
          <Button color={colors.blue.accentDark} loading={submitting} onPress={handleSubmit}>
            <Text color={colors.white}>Add</Text>
          </Button>
        </Actions>
      </Container>
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCustomRoomModal.fragment = gql`
  fragment CreateCustomRoomModal on Inventory {
    id
    uuid
  }
`;

export default CreateCustomRoomModal;
