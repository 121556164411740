// Libraries
import React from 'react';

// Supermove
import {Styled, Tabs} from '@supermove/components';
import {useEffect, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const BlockContainer = Styled.View`
  width: 100%;
  max-width: 492px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border}
  background-color: ${colors.white};
`;

const DynamicContainer = Styled.View`
  height: ${({height}) => `${height}px`};
`;

const SlideContainer = Styled.View`
  height: ${({height}) => `${height}px`};
`;

const SlideContentContainer = Styled.View`
  padding: 24px;
`;

const SlideTitle = Styled.Text`
  ${Typography.Label}
  `;

const SlideSubtitle = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.tertiary};
`;

const SlideLabel = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const SlideSubheading = Styled.Text`
  ${Typography.Subheading}
`;

const StampContainer = Styled.View`
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${colors.blue.accent};
`;

const Slide = ({index, tabs, slideHeight, setSlideHeight, loadingHeight, children}) => {
  const [height, setHeight] = useState(loadingHeight);

  useEffect(() => {
    if (index === tabs.selectedIndex && height && slideHeight !== height) {
      setSlideHeight(height);
    }
  }, [index, tabs, tabs.selectedIndex, height, slideHeight, setSlideHeight]);

  return (
    <Tabs.Slide key={index}>
      <DynamicContainer height={slideHeight}>
        <SlideContainer onLayout={({nativeEvent}) => setHeight(nativeEvent.layout.height)}>
          {children}
        </SlideContainer>
      </DynamicContainer>
    </Tabs.Slide>
  );
};

const WorkflowBuilderSlideSet = ({tabs, children, loadingHeight}) => {
  const [slideHeight, setSlideHeight] = useState();

  return (
    <BlockContainer>
      <Tabs.SlideViewer tabs={tabs} disabledSwipe>
        {React.Children.map(children, (child, index) => {
          return (
            <Slide
              index={index}
              tabs={tabs}
              slideHeight={slideHeight}
              setSlideHeight={setSlideHeight}
              loadingHeight={loadingHeight}
            >
              {child}
            </Slide>
          );
        })}
      </Tabs.SlideViewer>
    </BlockContainer>
  );
};

WorkflowBuilderSlideSet.Slide = SlideContentContainer;
WorkflowBuilderSlideSet.Title = SlideTitle;
WorkflowBuilderSlideSet.Subtitle = SlideSubtitle;
WorkflowBuilderSlideSet.Label = SlideLabel;
WorkflowBuilderSlideSet.Subheading = SlideSubheading;
WorkflowBuilderSlideSet.Stamp = StampContainer;

export default WorkflowBuilderSlideSet;
