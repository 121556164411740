// Libraries
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// App
import ProjectIsTestForm from '@shared/modules/Project/forms/ProjectIsTestForm';
import useSetProjectIsTestMutation from '@shared/modules/Project/hooks/useSetProjectIsTestMutation';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

const SetProjectIsTestModal = ({project, isOpen, handleClose}) => {
  const projectIsTestForm = ProjectIsTestForm.edit(project);
  const {submitting, handleSubmit} = useSetProjectIsTestMutation({
    projectIsTestForm,
    onSuccess: () => {
      handleClose();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Container pointerEvents={'box-none'}>
        <Wrapper width={350}>
          <Circle>
            <Emoji component={EmojiText} name={'warning'} />
          </Circle>
          <Content>
            <Title>{`Convert to ${project.isTest ? 'Real' : 'Training'}`}</Title>
            <Subtitle>
              {`Are you sure? This will convert the entire project and all jobs that ` +
                `are part of this project to ${project.isTest ? 'real' : 'training'}.`}
            </Subtitle>
            <Actions>
              <Button color={colors.gray.tertiary} onPress={handleClose}>
                <Text>Cancel</Text>
              </Button>
              <ButtonSpace />
              <Button loading={submitting} onPress={handleSubmit}>
                <Text>Confirm</Text>
              </Button>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SetProjectIsTestModal.propTypes = {};

SetProjectIsTestModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
SetProjectIsTestModal.fragment = gql`
  ${ProjectIsTestForm.edit.fragment}

  fragment SetProjectIsTestModal on Project {
    id
    ...ProjectIsTestForm_edit
  }
`;

SetProjectIsTestModal.mutation = gql`
  mutation SetProjectIsTestModal($projectId: Int!) {
    response: setProjectIsTest(projectId: $projectId) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default SetProjectIsTestModal;
