// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const OfficeHeader = Styled.View`
  flex-direction: row;
  background-color: ${colors.white};
  align-items: center;
  z-index: 100;
`;

// --------------------------------------------------
// OfficeHeaderBuilder.Content
// --------------------------------------------------
const OfficeHeaderContent = Styled.View`
  flex-direction: row;
  align-items: center;
`;

// --------------------------------------------------
// OfficeHeaderBuilder.Title
// --------------------------------------------------
const OfficeHeaderTitle = Styled.Text`
  ${Typography.Heading1};
`;

export const OfficeHeaderBuilder = {
  Content: OfficeHeaderContent,
  Title: OfficeHeaderTitle,
};

export default OfficeHeader;
