// Supermove
import {useContext} from '@supermove/hooks';
import {invariant} from '@supermove/utils';

// App
import {DayCalendarDispatchViewContext} from 'modules/Dispatch/Calendar/Day/context/DayCalendarDispatchViewContextProvider';

const useDayCalendarDispatchViewContext = () => {
  const context = useContext(DayCalendarDispatchViewContext);
  invariant(
    context,
    'useDayCalendarDispatchViewContext must be used within a DayCalendarDispatchViewContextProvider',
  );

  return context;
};

export default useDayCalendarDispatchViewContext;
