// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, DropdownInput, FileDragInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import FullWidthTabs from '@shared/design/components/Tabs/FullWidthTabs';
import DataTableKind from '@shared/modules/Billing/enums/DataTableKind';
import DataTableForm from '@shared/modules/Billing/forms/DataTableForm';
import useUploadDataTableMutation from '@shared/modules/Billing/hooks/useUploadDataTableMutation';
import ProjectTypeValuationCoverageForm from '@shared/modules/Project/forms/ProjectTypeValuationCoverageForm';
import useUpdateProjectTypeValuationCoverageMutation from '@shared/modules/Project/hooks/useUpdateProjectTypeValuationCoverageMutation';
import SystemMessage from 'modules/App/components/SystemMessage';

const ActivityIndicator = Styled.Loading`
`;

const FileDragInputContainer = Styled.View`
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-style: dotted;
  border-width: 2px;
  cursor: pointer;
  border-color: ${colors.gray.tertiary};
  background-color: ${({isHighlighted}) => (isHighlighted ? colors.gray.background : colors.white)};
`;

const FileDragInputText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
`;

const FileNameText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.blue.interactive};
`;

const CenteredContainer = Styled.View`
  align-items: center
`;

const SelectExistingValuationCoverageTable = ({
  refetch,
  handleClose,
  projectTypeId,
  valuationCoverageTables,
}) => {
  const projectTypeValuationCoverageForm = ProjectTypeValuationCoverageForm.edit({projectTypeId});
  const {form, handleSubmit, submitting} = useUpdateProjectTypeValuationCoverageMutation({
    projectTypeValuationCoverageForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });
  const field = 'projectTypeValuationCoverageForm';

  return (
    <React.Fragment>
      <Space height={20} />
      <FieldInput
        {...form}
        index={0}
        name={`${field}.valuationCoverageDataTableId`}
        label='Valuation Coverage Table'
        component={DropdownInput}
        input={{
          required: !_.get(form.values, `${field}.valuationCoverageDataTableId`),
          options: _.map(valuationCoverageTables, (table) => ({
            label: table.name,
            value: table.id,
          })),
          placeholder: 'Select an existing Valuation Coverage table',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
        }}
      />
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button style={{width: '50%'}} isDisabled={submitting} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          style={{width: '50%'}}
          isDisabled={submitting || !_.get(form.values, `${field}.valuationCoverageDataTableId`)}
          onPress={handleSubmit}
          color={submitting ? colors.gray.tertiary : colors.blue.interactive}
        >
          {submitting ? (
            <ActivityIndicator
              color={colors.white}
              style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}
            />
          ) : (
            'Save'
          )}
        </SmallModal.Button>
      </SmallModal.Footer>
    </React.Fragment>
  );
};

const UploadInput = ({form, field}) => {
  return (
    <FileDragInput
      onFilesChange={(files) => {
        form.setFieldValue(`${field}.data`, files[0]);
      }}
    >
      {({isDragActive}) => (
        <FileDragInputContainer isHighlighted={isDragActive}>
          {!_.get(form.values, `${field}.data`) ? (
            <FileDragInputText>
              {isDragActive ? 'Drop the file here' : 'Drag a file or click to upload'}
            </FileDragInputText>
          ) : (
            <FileNameText>{_.get(form.values, `${field}.data`).name}</FileNameText>
          )}
        </FileDragInputContainer>
      )}
    </FileDragInput>
  );
};

const UploadValuationCoverageTableFields = ({uploadDataTableMutation, dataTableFormField}) => {
  return (
    <React.Fragment>
      <FieldInput
        {...uploadDataTableMutation.form}
        index={0}
        label={'Table Name'}
        name={`${dataTableFormField}.name`}
        input={{
          required: !_.get(uploadDataTableMutation.form.values, `${dataTableFormField}.name`),
          placeholder: 'Enter a table name',
        }}
      />
      <Space height={8} />
      <FieldInput
        {...uploadDataTableMutation.form}
        index={2}
        name={`${dataTableFormField}.data`}
        component={UploadInput}
        input={{
          form: uploadDataTableMutation.form,
          field: dataTableFormField,
        }}
      />
      <Space height={8} />
      <SystemMessage.Container vars={{isWarning: true}} style={{margin: 0}}>
        <SystemMessage.Text vars={{isWarning: true}}>
          CSVs should have rows as follows: Category, Deductible, Minimum Amount, all other rows
          contain additional values
        </SystemMessage.Text>
      </SystemMessage.Container>
      <Space height={20} />
      <SmallModal.Button
        isDisabled={
          uploadDataTableMutation.submitting ||
          !_.get(uploadDataTableMutation.form.values, `${dataTableFormField}.data`)
        }
        onPress={uploadDataTableMutation.handleSubmit}
        color={uploadDataTableMutation.submitting ? colors.gray.tertiary : colors.blue.interactive}
      >
        {uploadDataTableMutation.submitting ? (
          <ActivityIndicator
            color={colors.white}
            style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}
          />
        ) : (
          'Upload'
        )}
      </SmallModal.Button>
    </React.Fragment>
  );
};

const UploadValuationCoverageTable = ({
  refetch,
  handleClose,
  projectTypeId,
  organizationId,
  userId,
}) => {
  const [isDataTableUploaded, setIsDataTableUploaded] = useState(false);

  const projectTypeValuationCoverageForm = ProjectTypeValuationCoverageForm.edit({projectTypeId});
  const updateProjectTypeValuationCoverageMutation = useUpdateProjectTypeValuationCoverageMutation({
    projectTypeValuationCoverageForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  const dataTableForm = DataTableForm.new({
    organizationId,
    userId,
    kind: DataTableKind.VALUATION_COVERAGE,
  });
  const uploadDataTableMutation = useUploadDataTableMutation({
    dataTableForm,
    onSuccess: ({dataTable}) => {
      updateProjectTypeValuationCoverageMutation.form.setFieldValue(
        'projectTypeValuationCoverageForm.valuationCoverageDataTableId',
        dataTable.id,
      );
      setIsDataTableUploaded(true);
    },
    onError: (errors) => console.log({errors}),
  });
  const dataTableFormField = 'dataTableForm';

  return (
    <React.Fragment>
      <Space height={20} />
      {isDataTableUploaded ? (
        <React.Fragment>
          <CenteredContainer>
            <SmallModal.HeaderText style={{textAlign: 'center'}}>
              Upload Successful
            </SmallModal.HeaderText>
            <Space height={12} />
            <SmallModal.Text style={{textAlign: 'center'}}>Save Valuation Coverage</SmallModal.Text>
          </CenteredContainer>
        </React.Fragment>
      ) : (
        <UploadValuationCoverageTableFields
          uploadDataTableMutation={uploadDataTableMutation}
          dataTableFormField={dataTableFormField}
        />
      )}
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button
          style={{width: '50%'}}
          isDisabled={
            uploadDataTableMutation.submitting ||
            updateProjectTypeValuationCoverageMutation.submitting
          }
          onPress={handleClose}
        >
          Cancel
        </SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          isDisabled={updateProjectTypeValuationCoverageMutation.submitting || !isDataTableUploaded}
          onPress={updateProjectTypeValuationCoverageMutation.handleSubmit}
          color={
            updateProjectTypeValuationCoverageMutation.submitting
              ? colors.gray.tertiary
              : colors.blue.interactive
          }
          style={{width: '50%'}}
        >
          {updateProjectTypeValuationCoverageMutation.submitting ? (
            <ActivityIndicator
              color={colors.white}
              style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}
            />
          ) : (
            'Save'
          )}
        </SmallModal.Button>
      </SmallModal.Footer>
    </React.Fragment>
  );
};

const UploadValuationCoverageTableModal = ({
  isOpen,
  handleClose,
  refetch,
  dataTables,
  projectTypeId,
  organizationId,
  userId,
  currentValuationCoverageDataTableId,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <SmallModal handlePressOutside={handleClose} isOpen={isOpen} style={{overflow: 'visible'}}>
      <FullWidthTabs
        tabs={[
          // activeTabIndex = 0
          {label: 'Select Existing'},
          // activeTabIndex = 1
          {label: 'Upload New'},
        ]}
        handlePressTab={({index}) => setActiveTabIndex(index)}
        activeTabIndex={activeTabIndex}
        style={{marginHorizontal: -24}}
      />
      {activeTabIndex === 0 && (
        <SelectExistingValuationCoverageTable
          refetch={refetch}
          handleClose={handleClose}
          projectTypeId={projectTypeId}
          valuationCoverageTables={dataTables.filter(
            (table) =>
              table.kind === DataTableKind.VALUATION_COVERAGE &&
              table.id !== currentValuationCoverageDataTableId,
          )}
        />
      )}
      {activeTabIndex === 1 && (
        <UploadValuationCoverageTable
          refetch={refetch}
          handleClose={handleClose}
          projectTypeId={projectTypeId}
          organizationId={organizationId}
          userId={userId}
        />
      )}
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UploadValuationCoverageTableModal.fragment = gql`
  fragment UploadValuationCoverageTableModal on DataTable {
    id
    name
    kind
  }
`;

export default UploadValuationCoverageTableModal;
