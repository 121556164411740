const ENUM = 'ENUM';
const STRING = 'STRING';
const NUMBER = 'NUMBER';
const DATE = 'DATE';
const BOOLEAN = 'BOOLEAN';

const STRING_TYPES = [STRING, DATE, ENUM];

const WorkflowModelDataTypes = {
  // Enum Options
  ENUM,
  STRING,
  NUMBER,
  DATE,
  BOOLEAN,

  STRING_TYPES,
};

export default WorkflowModelDataTypes;
