// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import AssignShipmentsToTripForm from '@shared/modules/Dispatch/forms/AssignShipmentsToTripForm';

const useAssignShipmentsToTripMutation = ({assignShipmentsToTripForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      assignShipmentsToTripForm: AssignShipmentsToTripForm.toForm(assignShipmentsToTripForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useAssignShipmentsToTripMutation.mutation,
    variables: {
      assignShipmentsToTripForm: AssignShipmentsToTripForm.toMutation(
        form.values.assignShipmentsToTripForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useAssignShipmentsToTripMutation.mutation = gql`
  mutation useAssignShipmentsToTripMutation($assignShipmentsToTripForm: AssignShipmentsToTripForm!) {
    response: assignShipmentsToTrip(assignShipmentsToTripForm: $assignShipmentsToTripForm) {
      ${gql.errors}
      trip {
        id
        shipments {
          id
        }
      }
    }
  }
`;

export default useAssignShipmentsToTripMutation;
