// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';

const getIsAssignedToProjectType = withFragment(
  (billingLibrary) => {
    return (billingLibrary as any).projectTypes.length > 0;
  },
  gql`
    fragment BillingLibrary_getIsAssignedToProjectType on BillingLibrary {
      id
      projectTypes {
        id
      }
    }
  `,
);

const getIsDefaultBillingLibrary = withFragment(
  (billingLibrary) => {
    return (
      String((billingLibrary as any).organization.defaultBillingLibraryId) ===
      String((billingLibrary as any).id)
    );
  },
  gql`
    fragment BillingLibrary_getIsDefaultBillingLibrary on BillingLibrary {
      id
      organization {
        id
        defaultBillingLibraryId
      }
    }
  `,
);

const getBillItemTypeDropdownOptions = withFragment(
  (billingLibrary) => {
    return _.sortBy((billingLibrary as any).billItemTypes, ['name']).map((billItemType) => ({
      value: _.toNumber(billItemType.id),
      label: billItemType.name,
      description: billItemType.description,
      secondaryLabel: BillItemTypeCategory.getDisplayCategory(billItemType.category),
    }));
  },
  gql`
    fragment BillingLibrary_getBillItemTypeDropdownOptions on BillingLibrary {
      id
      billItemTypes {
        id
        name
        description
        category
      }
    }
  `,
);

const BillingLibrary = {
  getIsAssignedToProjectType,
  getIsDefaultBillingLibrary,
  getBillItemTypeDropdownOptions,
};

export default BillingLibrary;
