// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DragAndDropList, Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDragAndDrop, useModal, usePopover, useResponsive, useToast} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import Panel from '@shared/design/components/Panel';
import Toast from '@shared/design/components/Toast';
import PaymentFeeKind from '@shared/modules/Organization/enums/PaymentFeeKind';
import OrganizationPaymentFeesForm from '@shared/modules/Organization/forms/OrganizationPaymentFeesForm';
import useUpdateOrganizationPaymentFeesMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationPaymentFeesMutation';
import PaymentFeeModal from 'modules/Organization/Settings/Company/components/PaymentFeeModal';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  flex-direction: row;
  flex: 1;
  /* Must use padding here to get spacing between components in drag and drop list */
  /* Drag and drop does some weird stuff with the Space component so it does not show up properly */
  padding-vertical: 4px;
`;

const ItemContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 8px;
  padding-vertical: 12px;
  padding-horizontal: 16px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  z-index: ${({index}) => 100 - index};
  flex: 1;
`;

const Label = Styled.Text`
  ${Typography.Responsive.Label}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const getPaymentFeeDescription = (paymentFeeForm) => {
  switch (paymentFeeForm.kind) {
    case PaymentFeeKind.RATE:
      return `${paymentFeeForm.amount}/hr`;
    case PaymentFeeKind.PERCENT:
      return paymentFeeForm.percentage;
    default:
      return '';
  }
};

const EllipseButton = ({form, field, handleSubmit, index, isViewingChildBranch}) => {
  const actionsPopover = usePopover();
  const deletePaymentMethodToast = useToast({
    ToastComponent: Toast,
    message: 'Payment fee deleted.',
  });
  return (
    <React.Fragment>
      <Popover.Content innerRef={actionsPopover.ref}>
        <TertiaryButton
          onPress={actionsPopover.handleToggle}
          style={{paddingHorizontal: 8}}
          isDisabled={isViewingChildBranch}
        >
          <Icon
            source={Icon.EllipsisV}
            color={isViewingChildBranch ? colors.gray.disabled : colors.gray.secondary}
            size={16}
          />
        </TertiaryButton>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={actionsPopover.isOpen}
        handleOpen={actionsPopover.handleOpen}
        handleClose={actionsPopover.handleClose}
        reference={actionsPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          handleClose={actionsPopover.handleClose}
          actions={[
            {
              text: 'Delete',
              onPress: () => {
                deletePaymentMethodToast.handleToast();
                const forms = _.cloneDeep(_.get(form.values, field));
                const updatedForms = List.remove(forms, index);
                form.setFieldValue(field, updatedForms);
                setTimeout(handleSubmit, 0);
              },
            },
          ]}
        />
      </Popover>
    </React.Fragment>
  );
};

const PaymentFeeItem = ({
  index,
  form,
  field,
  paymentFeeForm,
  organization,
  handleSubmit,
  isViewingChildBranch,
}) => {
  const responsive = useResponsive();
  const editPaymentFeeModal = useModal({name: 'Edit Payment Fee Modal'});
  const description = getPaymentFeeDescription(paymentFeeForm);
  return (
    <React.Fragment>
      <Container>
        <ItemContainer index={index}>
          <Column>
            <Label responsive={responsive}>{paymentFeeForm.name}</Label>
            {!!description && (
              <React.Fragment>
                <Space height={8} />
                <Description responsive={responsive}>{description}</Description>
              </React.Fragment>
            )}
          </Column>
          <Space style={{flex: 1, minWidth: 16}} />
          <Row>
            <TertiaryButton
              onPress={editPaymentFeeModal.handleOpen}
              isDisabled={isViewingChildBranch}
            >
              <Icon
                source={Icon.Pen}
                color={isViewingChildBranch ? colors.gray.disabled : colors.blue.interactive}
                size={16}
              />
            </TertiaryButton>
            <Space width={8} />
            <EllipseButton
              form={form}
              field={field}
              index={index}
              handleSubmit={handleSubmit}
              isViewingChildBranch={isViewingChildBranch}
            />
          </Row>
        </ItemContainer>
      </Container>
      {editPaymentFeeModal.isOpen && (
        <PaymentFeeModal.Edit
          key={editPaymentFeeModal.key}
          isOpen={editPaymentFeeModal.isOpen}
          handleClose={editPaymentFeeModal.handleClose}
          form={form}
          field={field}
          index={index}
          organization={organization}
        />
      )}
    </React.Fragment>
  );
};

const onReorder = ({form, field, fromIndex, toIndex, handleSubmit}) => {
  const forms = _.cloneDeep(_.get(form.values, field));
  const reorderedForms = List.move({
    list: forms,
    fromIndex,
    toIndex,
  });
  form.setFieldValue(field, reorderedForms);
  setTimeout(handleSubmit, 0);
};

const PaymentFeesPanel = ({index, organization, refetch, isViewingChildBranch}) => {
  const addPaymentFeeModal = useModal({name: 'Add Payment Fee Modal'});
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const organizationPaymentFeesForm = OrganizationPaymentFeesForm.edit(organization);
  const {form, handleSubmit} = useUpdateOrganizationPaymentFeesMutation({
    organizationPaymentFeesForm,
    onSuccess: () => {
      refetch();
      handleReorderEnd();
    },
    onError: (errors) => {
      console.log(errors);
      handleReorderEnd();
    },
  });

  const field = 'organizationPaymentFeesForm.paymentFeeForms';
  const paymentFeeForms = _.get(form.values, field);

  return (
    <Panel width={Panel.WIDTH.DEFAULT} index={index}>
      <Panel.Header>
        <Column>
          <Panel.HeaderText>Payment Fees</Panel.HeaderText>
          <Space height={8} />
          <Panel.Text style={{color: colors.gray.secondary}}>
            {`Payment fees are additional charges that are applied to bills based on the method of payment used. ` +
              `This will be shown, in this order, to the crew in the Crew App before the customer makes a payment.`}
          </Panel.Text>
        </Column>
      </Panel.Header>
      <Panel.Body>
        {isViewingChildBranch && (
          <React.Fragment>
            <Callout
              text={`Payment fees are set by the company. Please contact the admins at ${organization.company.primaryOrganization.name} to make changes.`}
            />
            <Space height={16} />
          </React.Fragment>
        )}
        {!_.isEmpty(paymentFeeForms) && (
          <React.Fragment>
            <DragAndDropList
              isDisabledWithVisibleIcons={isViewingChildBranch}
              isReordering={isReordering}
              onReorder={({fromIndex, toIndex}) => {
                handleReorderStart();
                onReorder({
                  form,
                  field,
                  fromIndex,
                  toIndex,
                  handleSubmit,
                });
              }}
            >
              {paymentFeeForms.map((paymentFeeForm, index) => {
                return (
                  <PaymentFeeItem
                    key={index}
                    index={index}
                    paymentFeeForm={paymentFeeForm}
                    form={form}
                    field={field}
                    organization={organization}
                    handleSubmit={handleSubmit}
                    isViewingChildBranch={isViewingChildBranch}
                  />
                );
              })}
            </DragAndDropList>
            <Space height={16} />
          </React.Fragment>
        )}
        <TertiaryButton
          text={'Add Payment Fee'}
          iconLeft={Icon.Plus}
          onPress={addPaymentFeeModal.handleOpen}
          isDisabled={isViewingChildBranch}
        />
        {addPaymentFeeModal.isOpen && (
          <PaymentFeeModal.Add
            key={addPaymentFeeModal.key}
            isOpen={addPaymentFeeModal.isOpen}
            handleClose={addPaymentFeeModal.handleClose}
            organization={organization}
            form={form}
            field={field}
          />
        )}
      </Panel.Body>
    </Panel>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PaymentFeesPanel.fragment = gql`
  ${PaymentFeeModal.Add.fragment}
  ${PaymentFeeModal.Edit.fragment}
  ${OrganizationPaymentFeesForm.edit.fragment}
  fragment PaymentFeesPanel on Organization {
    id
    company {
      id
      primaryOrganization {
        id
        name
      }
    }
    ...PaymentFeeModal_Add
    ...PaymentFeeModal_Edit
    ...OrganizationPaymentFeesForm_edit
  }
`;

export default PaymentFeesPanel;
