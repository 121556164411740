// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectInformationForm from '@shared/modules/Project/forms/ProjectInformationForm';

const useUpdateProjectInformationMutation = ({projectInformationForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      projectInformationForm: ProjectInformationForm.toForm(projectInformationForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectInformationMutation.mutation,
    variables: {
      projectInformationForm: ProjectInformationForm.toMutation(form.values.projectInformationForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateProjectInformationMutation.mutation = gql`
  ${ProjectInformationForm.edit.fragment}
  mutation useUpdateProjectInformationMutation($projectInformationForm: ProjectInformationForm!){
    response: updateProjectInformation(projectInformationForm: $projectInformationForm) {
      ${gql.errors}
      project {
        id
        ...ProjectInformationForm_edit
      }
    }
  }
`;

export default useUpdateProjectInformationMutation;
