// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';
import ProjectProposalBlockActions from 'modules/Project/V2/Show/Blocks/components/ProjectProposalBlockActions';
import ProjectProposalsTable from 'modules/Project/V2/Show/Blocks/components/ProjectProposalsTable';

const SkeletonComponent = () => {
  return (
    <ActionPanel
      BodyComponent={() => <SkeletonLoader height={150} isFullWidth />}
      ActionButtonComponent={() => (
        <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} width={160} />
      )}
      title={'Proposals'}
      style={{width: '100%'}}
    />
  );
};

const ProjectProposalsBlockContent = ({project, refetch, urlFilters}) => {
  return (
    <ActionPanel
      BodyComponent={ProjectProposalsTable}
      bodyComponentProps={{project, refetch, urlFilters}}
      ActionButtonComponent={ProjectProposalBlockActions}
      actionButtonComponentProps={{project, refetch, urlFilters}}
      title={'Proposals'}
      style={{width: '100%'}}
    />
  );
};

const ProjectProposalsBlockContentMobile = ({project, refetch, urlFilters}) => {
  return (
    <React.Fragment>
      <MobileProjectBlockHeader
        title={`Proposals`}
        ActionsComponent={ProjectProposalBlockActions}
        actionsComponentProps={{project, refetch, urlFilters}}
      />
      <ProjectProposalsTable.Mobile project={project} urlFilters={urlFilters} />
    </React.Fragment>
  );
};

const ProjectProposalsBlock = ({
  index,
  project,
  handleSetPositionY,
  layoutKey,
  urlFilters,
  projectBlockKind,
}) => {
  const responsive = useResponsive();

  return (
    <ProjectBlockWrapper
      index={index}
      query={ProjectProposalsBlock.query}
      queryVariables={{
        projectUuid: project.uuid,
        isDesktop: responsive.desktop,
      }}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data, refetch}) => {
        const {project} = data;
        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ProjectProposalsBlockContent
                project={project}
                refetch={refetch}
                urlFilters={urlFilters}
              />
            ) : (
              <ProjectProposalsBlockContentMobile
                project={project}
                refetch={refetch}
                urlFilters={urlFilters}
              />
            )}
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectProposalsBlock.fragment = gql`
  fragment ProjectProposalsBlock on Project {
    id
    uuid
  }
`;

ProjectProposalsBlock.query = gql`
  ${ProjectProposalBlockActions.fragment}
  ${ProjectProposalsTable.fragment}
  ${ProjectProposalsTable.Mobile.fragment}

  query ProjectProposalsBlock($projectUuid: String!, $isDesktop: Boolean!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      ...ProjectProposalBlockActions
      ...ProjectProposalsTable @include(if: $isDesktop)
      ...ProjectProposalsTable_Mobile @skip(if: $isDesktop)
    }
  }
`;

export default ProjectProposalsBlock;
