// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

export interface JobTypeStepDocumentTemplateFormType {
  jobTypeStepDocumentTemplateId?: string;
  jobTypeStepId: number;
  organizationId: number;
  documentTemplateId: number;
  readonlySectionUuids?: string[];
  index: number;
}

export type JobTypeStepDocumentTemplateNewFormType = Omit<
  JobTypeStepDocumentTemplateFormType,
  'jobTypeStepDocumentTemplateId' | 'documentTemplateId' | 'jobTypeStepId'
> & {
  documentTemplateId: string;
  jobTypeStepId: string;
};

export type JobTypeStepDocumentTemplateApiType = Omit<
  JobTypeStepDocumentTemplateFormType,
  'jobTypeStepDocumentTemplateId'
> & {
  id: string;
};

const sort = (a: JobTypeStepDocumentTemplateFormType, b: JobTypeStepDocumentTemplateFormType) => {
  return a.index - b.index;
};

const _new = ({
  jobTypeStepId,
  organizationId,
  documentTemplateId,
  readonlySectionUuids,
  index,
}: JobTypeStepDocumentTemplateNewFormType): JobTypeStepDocumentTemplateFormType => ({
  jobTypeStepId: parseInt(jobTypeStepId, 10),
  organizationId,
  documentTemplateId: parseInt(documentTemplateId, 10),
  readonlySectionUuids,
  index,
});

const edit = withFragment(
  ({
    id,
    jobTypeStepId,
    organizationId,
    documentTemplateId,
    readonlySectionUuids,
    index,
  }: JobTypeStepDocumentTemplateApiType): JobTypeStepDocumentTemplateFormType => ({
    jobTypeStepDocumentTemplateId: id,
    jobTypeStepId,
    organizationId,
    documentTemplateId,
    readonlySectionUuids,
    index,
  }),
  gql`
    fragment JobTypeStepDocumentTemplateForm on JobTypeStep {
      id
      jobTypeStepId
      organizationId
      updatedById
      createdById
      documentTemplateId
      readonlySectionUuids
      index
    }
  `,
);

const toForm = ({
  jobTypeStepDocumentTemplateId,
  jobTypeStepId,
  organizationId,
  documentTemplateId,
  readonlySectionUuids,
  index,
}: JobTypeStepDocumentTemplateFormType): JobTypeStepDocumentTemplateFormType => ({
  jobTypeStepDocumentTemplateId,
  jobTypeStepId,
  organizationId,
  documentTemplateId,
  readonlySectionUuids,
  index,
});

const toMutation = ({
  jobTypeStepDocumentTemplateId,
  jobTypeStepId,
  organizationId,
  documentTemplateId,
  readonlySectionUuids,
  index,
}: JobTypeStepDocumentTemplateFormType): JobTypeStepDocumentTemplateFormType => ({
  jobTypeStepDocumentTemplateId,
  jobTypeStepId,
  organizationId,
  documentTemplateId,
  readonlySectionUuids,
  index,
});

const JobTypeStepDocumentTemplateForm = {
  new: _new,
  edit,
  toForm,
  toMutation,

  // Helpers
  sort,
};

export default JobTypeStepDocumentTemplateForm;
