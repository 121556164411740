// Libraries
import React from 'react';

// Supermove
import {
  Drawer as BaseDrawer,
  Icon,
  IconSource,
  ScrollView,
  Space,
  Styled,
} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import {GetRefType} from '@shared/utilities/typescript';

const WIDTH = {
  DEFAULT: 400,
  MEDIUM: 620,
};

const Row = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
  width: ${({responsive, width}: any) => (!responsive.desktop ? '100%' : `${width}px`)};
`;

const BodyContainer = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View`
  background-color: ${colors.white};
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
`;

const HeaderTop = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PopoutPanelContainer = Styled.View`
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
  padding: 24px;
  background-color: ${colors.white};
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.primary};
`;

const DescriptionText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const View = Styled.View`
`;

const Divider = Styled.View`
  width: 1px;
  background-color: ${colors.gray.border};
`;

const MobilePopoutPanelContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const MobileHeaderRightContainer = Styled.View`
  position: absolute;
  right: 0;
`;

const PreventPropagationContainer = ({children, style}: {children: any; style: any}) => {
  return (
    <View
      onStartShouldSetResponder={(event: any) => true}
      onTouchEnd={(e: any) => {
        e.stopPropagation();
      }}
      style={style}
    >
      {children}
    </View>
  );
};

interface HeaderProps {
  headerText: string;
  description?: string | React.ReactNode;
  handleClose?: (() => void) | null;
  closeIcon?: IconSource;
  handleBack?: (() => void) | null;
  backIcon?: IconSource;
  isClosable?: boolean;
  isResponsive: boolean;
  children?: React.ReactNode;
}

const Header = ({
  headerText = '',
  description = '',
  handleBack,
  backIcon,
  handleClose,
  closeIcon = Icon.Xmark,
  isClosable = false,
  isResponsive = false,
  children,
}: HeaderProps) => {
  const responsive = useResponsive();

  if (isResponsive && !responsive.desktop) {
    return (
      <Drawer.HeaderMobile
        headerText={headerText}
        description={description}
        handleBack={handleBack}
        backIcon={backIcon}
        handleClose={handleClose}
        closeIcon={closeIcon}
        headerRight={children}
      />
    );
  }

  return (
    <HeaderContainer style={{padding: 24}}>
      <HeaderTop>
        <HeaderText responsive={responsive}>{headerText}</HeaderText>
        {children}
        {isClosable && (
          <React.Fragment>
            <Space width={16} />
            <TertiaryButton
              onPress={handleClose || (() => {})}
              style={{height: 24, width: 24}}
              isHitSlop
            >
              <Icon source={closeIcon} size={20} color={colors.gray.secondary} />
            </TertiaryButton>
          </React.Fragment>
        )}
      </HeaderTop>
      {!!description && (
        <React.Fragment>
          <Space height={8} />
          <DescriptionText>{description}</DescriptionText>
        </React.Fragment>
      )}
    </HeaderContainer>
  );
};

interface HeaderMobileButtonProps {
  icon: IconSource;
  onPress?: (() => void) | null;
}

const HEADER_MOBILE_BUTTON_WIDTH = 32;

const HeaderMobileButton = ({icon, onPress}: HeaderMobileButtonProps) => {
  if (!onPress) {
    return <Space width={HEADER_MOBILE_BUTTON_WIDTH} />;
  }

  return (
    <TertiaryButton
      onPress={onPress}
      style={{height: HEADER_MOBILE_BUTTON_WIDTH, width: HEADER_MOBILE_BUTTON_WIDTH}}
    >
      <Icon source={icon} size={20} color={colors.gray.secondary} />
    </TertiaryButton>
  );
};
interface HeaderMobileProps {
  headerText: string;
  description?: string | React.ReactNode;
  backIcon?: IconSource;
  closeIcon?: IconSource;
  handleBack?: (() => void) | null;
  handleClose?: (() => void) | null;
  headerRight?: React.ReactNode;
}

const HeaderMobile = ({
  headerText = '',
  description,
  backIcon = Icon.ArrowLeft,
  closeIcon = Icon.Xmark,
  handleBack,
  handleClose,
  headerRight,
}: HeaderMobileProps) => {
  const responsive = useResponsive();

  return (
    <HeaderContainer style={{paddingHorizontal: 16, paddingVertical: 12}}>
      <HeaderTop>
        <HeaderMobileButton icon={backIcon} onPress={handleBack} />
        <Space style={{flex: 1}} />
        <HeaderText responsive={responsive} style={{textAlign: 'center'}}>
          {headerText}
        </HeaderText>
        <Space style={{flex: 1}} />
        {!!headerRight && <MobileHeaderRightContainer>{headerRight}</MobileHeaderRightContainer>}
        <HeaderMobileButton icon={closeIcon} onPress={handleClose} />
      </HeaderTop>
      {!!description && (
        <React.Fragment>
          <Space height={8} />
          <DescriptionText style={{alignSelf: 'center', textAlign: 'center'}}>
            {description}
          </DescriptionText>
        </React.Fragment>
      )}
    </HeaderContainer>
  );
};

export interface DrawerPopoutPanelProps {
  title: string;
  description: string;
  handleClose: () => void;
  headerActionButtonComponent: React.ReactNode;
  closeIcon?: IconSource;
  isClosable?: boolean;
  children: React.ReactNode;
}

const PopoutPanel = ({
  title,
  description,
  handleClose,
  headerActionButtonComponent = null,
  closeIcon = Icon.ArrowRightToLine,
  isClosable,
  children,
}: DrawerPopoutPanelProps) => {
  const responsive = useResponsive();

  return (
    <PopoutPanelContainer>
      <Header
        headerText={title}
        description={description}
        handleBack={responsive.desktop ? null : handleClose}
        backIcon={Icon.AngleLeft}
        handleClose={responsive.desktop ? handleClose : null}
        closeIcon={closeIcon}
        isClosable={isClosable ?? responsive.desktop}
        isResponsive
      >
        {headerActionButtonComponent}
      </Header>
      {children}
    </PopoutPanelContainer>
  );
};

export interface DrawerBodyProps {
  children: React.ReactNode;
  bodyScrollStyle?: object;
  scrollViewRef?: GetRefType<typeof ScrollView>;
}

const Body = ({children, bodyScrollStyle = {}, scrollViewRef}: DrawerBodyProps) => {
  const responsive = useResponsive();

  return (
    <BodyContainer>
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={{
          padding: responsive.desktop ? 24 : 16,
          ...bodyScrollStyle,
        }}
      >
        {children}
      </ScrollView>
    </BodyContainer>
  );
};

interface FooterProps {
  primaryActionText: string;
  primaryActionIcon?: any;
  secondaryActionText?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  primaryAction: () => void;
  secondaryAction: () => void;
  isResponsive?: boolean;
}

const Footer = ({
  isDisabled = false,
  isSubmitting = false,
  primaryAction,
  secondaryAction,
  primaryActionIcon,
  primaryActionText = 'Submit',
  secondaryActionText = 'Cancel',
  isResponsive = true,
}: FooterProps) => {
  const responsive = useResponsive();

  if (isResponsive && !responsive.desktop) {
    return (
      <Drawer.FooterMobile
        isDisabled={isDisabled}
        isSubmitting={isSubmitting}
        primaryAction={primaryAction}
        primaryActionText={primaryActionText}
      />
    );
  }

  return (
    <FooterContainer>
      <Button
        textColor={colors.gray.secondary}
        onPress={secondaryAction}
        isDisabled={isSubmitting}
        text={secondaryActionText}
        style={{backgroundColor: colors.white, minWidth: 120}}
      />
      <Space width={16} />
      <Button
        onPress={primaryAction}
        isDisabled={isDisabled}
        isSubmitting={isSubmitting}
        iconLeft={primaryActionIcon || Icon.Check}
        text={primaryActionText}
        style={{minWidth: 120}}
      />
    </FooterContainer>
  );
};

interface FooterMobileProps {
  isDisabled?: boolean;
  isSubmitting?: boolean;
  primaryAction: () => void;
  primaryActionText: string;
}

const FooterMobile = ({
  isDisabled,
  isSubmitting,
  primaryAction,
  primaryActionText,
}: FooterMobileProps) => {
  return (
    <FooterContainer style={{padding: 16}}>
      <Button
        onPress={primaryAction}
        isDisabled={isDisabled}
        isSubmitting={isSubmitting}
        text={primaryActionText}
        iconLeft={Icon.Check}
        isWidthOfContainer
        isResponsive
        style={{flex: 1}}
      />
    </FooterContainer>
  );
};

export interface DrawerProps {
  isOpen: boolean;
  handleClose?: () => void;
  width?: number;
  shouldCloseOnClickOutside?: boolean;
  isLeft?: boolean;
  isAnimated?: boolean;
  isPopoutPanelOpen?: boolean;
  popoutPanelComponent?: React.ReactNode;
  children: React.ReactNode;
}

const Drawer = ({
  isOpen = false,
  handleClose = () => {},
  width,
  shouldCloseOnClickOutside = true,
  isLeft = false,
  isAnimated = true,
  isPopoutPanelOpen = false,
  popoutPanelComponent = null,
  children,
}: DrawerProps) => {
  const responsive = useResponsive();
  return (
    <BaseDrawer
      isOpen={isOpen}
      onClose={handleClose}
      shouldCloseOnClickOutside={shouldCloseOnClickOutside}
      position={isLeft ? BaseDrawer.POSITIONS.LEFT : BaseDrawer.POSITIONS.RIGHT}
      isAnimated={isAnimated}
    >
      <Row style={!responsive.desktop ? {width: '100%'} : null}>
        {responsive.desktop && isPopoutPanelOpen && (
          <React.Fragment>
            <Container responsive={responsive} width={width}>
              {popoutPanelComponent}
            </Container>
            <Divider />
          </React.Fragment>
        )}
        <Container
          responsive={responsive}
          width={width}
          style={{
            transitionProperty: 'width',
            transitionDuration: '0.3s',
            transitionTimingFunction: 'ease',
          }}
        >
          {children}
        </Container>
      </Row>
      {!responsive.desktop && !!popoutPanelComponent && (
        <BaseDrawer isOpen={isPopoutPanelOpen}>
          <MobilePopoutPanelContainer responsive={responsive} width={width}>
            {popoutPanelComponent}
          </MobilePopoutPanelContainer>
        </BaseDrawer>
      )}
    </BaseDrawer>
  );
};

Drawer.HeaderText = HeaderText;
Drawer.Text = Text;
Drawer.Header = Header;
Drawer.PopoutPanel = PopoutPanel;
Drawer.Body = Body;
Drawer.Footer = Footer;
Drawer.FooterContainer = FooterContainer;
Drawer.HeaderMobile = HeaderMobile;
Drawer.FooterMobile = FooterMobile;
Drawer.PreventPropagationContainer = PreventPropagationContainer;
Drawer.WIDTH = WIDTH;

export default Drawer;
