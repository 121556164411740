// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CommentForm from '@shared/modules/Comment/forms/CommentForm';

const useDeleteCommentMutation = ({commentForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      commentForm: CommentForm.toForm(commentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteCommentMutation.mutation,
    variables: {
      commentForm: CommentForm.toMutation(form.values.commentForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteCommentMutation.mutation = gql`
  mutation useDeleteCommentMutation($commentForm: CommentForm!) {
    response: deleteComment(commentForm: $commentForm) {
      ${gql.errors}
    }
  }
`;

export default useDeleteCommentMutation;
