// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';

// App
import ValueField from 'modules/Project/Billing/components/ValueField';

const Row = Styled.View`
  z-index: ${({index = 0}) => 100 - index};
  flex-direction: ${({mobile}) => (mobile ? 'column' : 'row')};
`;

const Container = Styled.View`
  z-index: ${({index = 0}) => 100 - index};
`;

const ValueItem = Styled.View`
  z-index: ${(props) => 100 - props.index};
  ${({mobile}) => (mobile ? '' : 'flex: 1')}
`;

const FieldSpace = () => {
  return <Space height={12} width={8} />;
};

const getVisibleValueFormIndexes = ({valueForms, startingValueFormIndex}) => {
  const visibleValueFormIndexes = _.filter(
    _.map(valueForms, (valueForm, index) =>
      valueForm.isVisibleForCreateProject ? index + startingValueFormIndex : null,
    ),
    (valueFormIndex) => !_.isNil(valueFormIndex),
  );
  return visibleValueFormIndexes;
};

const TwoColumnValueFields = ({
  outerIndex,
  form,
  valueFormsField,
  valueForms,
  startingValueFormIndex = 0,
}) => {
  const responsive = useResponsive();
  const visibleValueFormIndexes = getVisibleValueFormIndexes({valueForms, startingValueFormIndex});
  const valueFormIndexPairs = _.chunk(visibleValueFormIndexes, 2);
  return (
    <Container index={outerIndex}>
      {valueFormIndexPairs.map((indexPair, rowIndex) => {
        return (
          <React.Fragment key={`ROW_${rowIndex}`}>
            <Row index={rowIndex} {...responsive}>
              {indexPair.map((index, pairIndex) => {
                const isColumn2 = pairIndex === 1;
                const isRequired = _.get(
                  form.values,
                  `${valueFormsField}.${index}.isRequiredForCreateProject`,
                );
                return (
                  <React.Fragment key={`ITEM_${pairIndex}`}>
                    {isColumn2 && <FieldSpace />}
                    <ValueItem index={pairIndex} {...responsive}>
                      <ValueField
                        form={form}
                        field={`${valueFormsField}.${index}`}
                        required={isRequired}
                      />
                    </ValueItem>
                  </React.Fragment>
                );
              })}
            </Row>
            <FieldSpace />
          </React.Fragment>
        );
      })}
    </Container>
  );
};

export default TwoColumnValueFields;
