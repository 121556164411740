/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled, Tooltip} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import SyncAllCommercialCatalogBillsForm from '@shared/modules/CommercialCatalog/forms/SyncAllCommercialCatalogBillsForm';
import useSyncAllCommercialCatalogBillsMutation from '@shared/modules/CommercialCatalog/hooks/useSyncAllCommercialCatalogBillsMutation';
import ConfirmSyncCommercialCatalogBillModal from 'modules/Job/V2/Move/components/ConfirmSyncCommercialCatalogBillModal';

const Container = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  padding-vertical: 8px;
  padding-horizontal: 12px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  z-index: ${({index = 0}) => 100 - index};
`;

const BillStatus = Styled.H7`
  flex: 1;
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
`;

const SyncCommercialCatalogButton = Styled.Touchable`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-horizontal: 8px;
  padding-vertical: 4px;
  background-color: ${colors.gray.background};
  border-radius: 4px;
`;

const SyncCommercialCatalogText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Indicator = Styled.Loading`
`;

const QuestionIconCircle = Styled.View`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${colors.blue.accentDark};
  align-items: center;
  justify-content: center;
`;

const QuestionIcon = () => {
  return (
    <QuestionIconCircle>
      <Icon source={Icon.Question} color={colors.white} size={7} />
    </QuestionIconCircle>
  );
};

const TooltipText = Styled.H8`
  color: ${colors.white};
`;

const CommercialCatalogBillSection = ({index, commercialCatalog, refetch}) => {
  const responsive = useResponsive();
  const confirmSyncCommercialCatalogBillModal = useModal();
  const {hasSyncedCommercialCatalogBill, lastSyncedCommercialCatalogBillAt} =
    commercialCatalog.project;
  const syncAllCommercialCatalogBillsForm = SyncAllCommercialCatalogBillsForm.edit(
    commercialCatalog.project,
  );
  const {submitting, handleSubmit} = useSyncAllCommercialCatalogBillsMutation({
    syncAllCommercialCatalogBillsForm,
    onSuccess: () => refetch(),
    onError: () => console.log('SyncAllCommercialCatalogBillsMutation error'),
  });

  return (
    <Container {...responsive} index={index} style={{alignItems: 'center'}}>
      <BillStatus>
        {hasSyncedCommercialCatalogBill
          ? `Last synced equipment & materials bill on ` +
            `${Datetime.fromDatetime(lastSyncedCommercialCatalogBillAt).format(
              Datetime.DISPLAY_DATETIME,
            )}.`
          : `Create a bill based on the equipment & materials.`}
      </BillStatus>
      {hasSyncedCommercialCatalogBill ? (
        <Tooltip
          placement={'bottom'}
          overlay={() => <TooltipText>This will regenerate all items on the bill.</TooltipText>}
        >
          <SyncCommercialCatalogButton onPress={confirmSyncCommercialCatalogBillModal.handleOpen}>
            <SyncCommercialCatalogText>
              Regenerate Equipment & Materials Bill
            </SyncCommercialCatalogText>
            <Space width={4} />
            <QuestionIcon />
          </SyncCommercialCatalogButton>
        </Tooltip>
      ) : (
        <Tooltip
          placement={'bottom'}
          overlay={() => (
            <TooltipText>
              {`We'll automatically add a bill that includes:\n` +
                `1. Any Materials with a price\n` +
                `2. Any Equipments with a price`}
            </TooltipText>
          )}
        >
          <SyncCommercialCatalogButton disabled={submitting} onPress={handleSubmit}>
            {submitting ? (
              <Indicator size={'small'} color={colors.gray.secondary} />
            ) : (
              <React.Fragment>
                <SyncCommercialCatalogText>
                  Create Equipment & Materials Bill
                </SyncCommercialCatalogText>
                <Space width={4} />
                <QuestionIcon />
              </React.Fragment>
            )}
          </SyncCommercialCatalogButton>
        </Tooltip>
      )}
      <ConfirmSyncCommercialCatalogBillModal
        isOpen={confirmSyncCommercialCatalogBillModal.isOpen}
        onClose={confirmSyncCommercialCatalogBillModal.handleClose}
        commercialCatalog={commercialCatalog}
        refetch={refetch}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialCatalogBillSection.fragment = gql`
  ${SyncAllCommercialCatalogBillsForm.edit.fragment}
  ${ConfirmSyncCommercialCatalogBillModal.fragment}

  fragment CommercialCatalogBillSection on CommercialCatalog {
    id
    project {
      id
      lastSyncedCommercialCatalogBillAt
      hasSyncedCommercialCatalogBill
      ...SyncAllCommercialCatalogBillsForm_edit
    }
    ...ConfirmSyncCommercialCatalogBillModal
  }
`;

export default CommercialCatalogBillSection;
