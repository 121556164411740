// Libararies
import React from 'react';

// Supermove
import {Styled, Space, Icon, Tooltip} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import ExportSyncStatus from '@shared/modules/Billing/enums/ExportSyncStatus';

const View = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  display: flex;

`;

const Text = Styled.Text`
  ${Typography.Body2}
`;

const Column = Styled.View`
  flex-direction: column;
  display: flex;
`;

const TooltipText = Styled.Text`
${Typography.Body2}
color: ${colors.white};
`;

const getTooltipOverlay = (invoice) => {
  const linesMap = {
    invoice: [],
    payments: [],
    client: [],
  };
  const {latestClientExport} = invoice.project.billingClient || {};
  if (latestClientExport) {
    linesMap.client.push({
      text: `Client ${invoice.project.billingClient.name}:`,
      option: ExportSyncStatus.getStatusOption(latestClientExport.status),
    });
  }
  if (invoice.latestInvoiceExport) {
    linesMap.invoice.push({
      text: `Invoice ${invoice.identifier}:`,
      option: ExportSyncStatus.getStatusOption(invoice.latestInvoiceExport.status),
    });
  }
  for (const payment of invoice.payments) {
    if (payment.latestPaymentExport) {
      linesMap.payments.push({
        text: `Payment ${payment.name}:`,
        option: ExportSyncStatus.getStatusOption(payment.latestPaymentExport.status),
      });
    }
  }
  if (linesMap.invoice.length === 0) {
    linesMap.invoice.push({
      text: `Invoice ${invoice.identifier}:`,
      option: ExportSyncStatus.getStatusOption('UNSET'),
    });
  }
  if (linesMap.client.length === 0) {
    linesMap.client.push({
      text: `Client:`,
      option: ExportSyncStatus.getStatusOption('UNSET'),
    });
  }
  if (linesMap.payments.length === 0) {
    if (invoice.payments.length > 0) {
      linesMap.payments.push({
        text: `Payments:`,
        option: ExportSyncStatus.getStatusOption('UNSET'),
      });
    }
  }

  return (
    <Column>
      {Object.entries(linesMap).reduce((acc, [key, lines]) => {
        if (lines.length === 0) {
          return acc;
        }
        if (lines.length === 1) {
          const {option, text} = lines[0];
          const {icon, color} = option;
          const thisLine = (
            <Row>
              <TooltipText>{text}</TooltipText>
              <Space width={8} />
              {icon ? (
                <Icon source={icon} color={icon === 'clock' ? colors.white : color} size={'1x'} />
              ) : (
                <Icon source={Icon.TimesCircle} color={colors.red.warning} size={'1x'} />
              )}
            </Row>
          );
          return [...acc, thisLine];
        }

        return [
          ...acc,
          ...lines.map(({text, option: {icon, color}}) => (
            <Row key={text}>
              <TooltipText>{text}</TooltipText>
              <Space width={8} />
              {icon ? (
                <Icon source={icon} color={icon === 'clock' ? colors.white : color} size={'1x'} />
              ) : (
                <Icon source={Icon.TimesCircle} color={colors.red.warning} size={'1x'} />
              )}
            </Row>
          )),
        ];
      }, [])}
    </Column>
  );
};

const InvoiceExportStatusWithDate = ({invoice, showDate}) => {
  const invoiceExport = invoice.latestInvoiceExport;
  if (!invoiceExport) return null;
  const exportSyncStatusOption = ExportSyncStatus.getStatusOption(invoice.compositeStatus);
  const tooltipOverlay = getTooltipOverlay(invoice);
  return (
    <Row>
      <View>
        <Space height={2} />
        <Tooltip
          placement={'top'}
          overlay={tooltipOverlay}
          mouseEnterDelay={0.0}
          mouseLeaveDelay={0.0}
        >
          <Row>
            {exportSyncStatusOption.icon ? (
              <Icon
                color={exportSyncStatusOption.color}
                source={exportSyncStatusOption.icon}
                size={'1x'}
              />
            ) : null}
            <Space width={8} />
            {showDate && invoiceExport.lastSyncedAt && (
              <Text>
                {`${Datetime.convertToDisplayDate(
                  invoiceExport.lastSyncedAt,
                  Datetime.DISPLAY_SHORT_DATE,
                )}, ${Datetime.convertToDisplayDatetime(
                  invoiceExport.lastSyncedAt,
                  Datetime.DISPLAY_TIME,
                )}`}
              </Text>
            )}
          </Row>
        </Tooltip>
      </View>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceExportStatusWithDate.fragment = gql`
  fragment InvoiceExportStatusWithDate on Invoice {
    id
    compositeStatus
    identifier
    latestInvoiceExport {
      id
      status
      lastSyncedAt
    }
    project {
      id
      billingClient {
        id
        name
        latestClientExport {
          id
          status
          lastSyncedAt
        }
      }
    }
    payments {
      id
      name
      latestPaymentExport {
        id
        status
        lastSyncedAt
      }
    }
  }
`;

export default InvoiceExportStatusWithDate;
