// Move Details
const PROJECT_OVERVIEW = 'PROJECT_OVERVIEW' as const; // Currently only used on mobile
const CLIENTS = 'CLIENTS' as const;
const LOCATIONS = 'LOCATIONS' as const;
const INTERNAL = 'INTERNAL' as const;

// Sales
const SURVEY = 'SURVEY' as const;
const VALUATION_COVERAGE = 'VALUATION_COVERAGE' as const;
const PROPOSALS = 'PROPOSALS' as const;
const BILLING = 'BILLING' as const;

// Jobs
const JOBS = 'JOBS' as const;
const JOB_DETAILS = 'JOB_DETAILS' as const;
const STOPS = 'STOPS' as const;
const DISPATCH = 'DISPATCH' as const;
const EQUIPMENT_AND_MATERIALS = 'EQUIPMENT_AND_MATERIALS' as const;
const TIMESHEETS = 'TIMESHEETS' as const;
const JOB_INFORMATION = 'JOB_INFORMATION' as const;
const NOTES = 'NOTES' as const;
const JOB_TIMESHEET = 'JOB_TIMESHEET' as const;
const CREW_HOURS_AND_TIPS = 'CREW_HOURS_AND_TIPS' as const;
const CREW_HOURS = 'CREW_HOURS' as const;
const TIP_PAYOUTS = 'TIP_PAYOUTS' as const;

// Inventory
const INVENTORY_SUMMARY = 'INVENTORY_SUMMARY' as const;
const INVENTORY_ITEMS = 'INVENTORY_ITEMS' as const;

// Accounting
const ACCOUNTING_SUMMARY = 'ACCOUNTING_SUMMARY' as const;
const INVOICE = 'INVOICE' as const;
const COST_AND_COMPENSATION = 'COST_AND_COMPENSATION' as const;
const CLAIMS = 'CLAIMS' as const;

// Variables
const VARIABLES = 'VARIABLES' as const;

const Job = {
  JOB_DETAILS,
  JOB_INFORMATION,
  STOPS,
  DISPATCH,
  EQUIPMENT_AND_MATERIALS,
  TIMESHEETS,
  NOTES,
  JOB_TIMESHEET,
  CREW_HOURS_AND_TIPS,
  CREW_HOURS,
  TIP_PAYOUTS,
} as const;

const JobBlocks = [
  JOB_DETAILS,
  STOPS,
  DISPATCH,
  EQUIPMENT_AND_MATERIALS,
  TIMESHEETS,
  JOB_TIMESHEET,
  CREW_HOURS_AND_TIPS,
  CREW_HOURS,
  TIP_PAYOUTS,
] as const;

const EditJobBlocks = [JOB_INFORMATION, STOPS, DISPATCH, VARIABLES] as const;

const MobileEditJobBlocks = [JOB_INFORMATION, NOTES, STOPS, DISPATCH, VARIABLES] as const;

const KIND_TO_DISPLAY = {
  [CLIENTS]: 'Client & Bill To',
  [LOCATIONS]: 'Saved Locations',
  [INTERNAL]: 'Internal Information',
  [SURVEY]: 'Survey',
  [VALUATION_COVERAGE]: 'Valuation Coverage',
  [PROPOSALS]: 'Proposals',
  [BILLING]: 'Billing',
  [JOBS]: 'Jobs',
  [JOB_DETAILS]: 'Job Details',
  [JOB_INFORMATION]: 'Job Information',
  [NOTES]: 'Notes',
  [STOPS]: 'Stops',
  [DISPATCH]: 'Dispatch',
  [EQUIPMENT_AND_MATERIALS]: 'Equipment & Materials',
  [TIMESHEETS]: 'Timesheets',
  [JOB_TIMESHEET]: 'Job Timesheet',
  [CREW_HOURS_AND_TIPS]: 'Crew Hours & Tips',
  [CREW_HOURS]: 'Crew Hours',
  [TIP_PAYOUTS]: 'Tip Payouts',
  [INVENTORY_SUMMARY]: 'Inventory Summary',
  [INVENTORY_ITEMS]: 'Items',
  [ACCOUNTING_SUMMARY]: 'Summary',
  [INVOICE]: 'Invoice',
  [COST_AND_COMPENSATION]: 'Cost & Compensation',
  [CLAIMS]: 'Claims',
  [VARIABLES]: 'Variable Sections',
} as const;

export type ProjectBlockKindType = keyof typeof KIND_TO_DISPLAY;
const getDisplay = (projectBlockKind: ProjectBlockKindType) => {
  return KIND_TO_DISPLAY[projectBlockKind];
};

export default {
  PROJECT_OVERVIEW,
  JOBS,
  CLIENTS,
  LOCATIONS,
  SURVEY,
  VALUATION_COVERAGE,
  PROPOSALS,
  BILLING,
  INVENTORY_SUMMARY,
  INVENTORY_ITEMS,
  ACCOUNTING_SUMMARY,
  INVOICE,
  INTERNAL,
  COST_AND_COMPENSATION,
  CLAIMS,
  VARIABLES,

  Job,
  JobBlocks,
  EditJobBlocks,
  MobileEditJobBlocks,

  getDisplay,
};
