// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, DateInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Modal from '@shared/design/components/Modal';
import DateRangeInput from 'modules/Project/V2/components/DateRangeInput';

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  z-index: ${({index = 0}) => 100 - index};
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const FieldSpace = () => {
  return <Space height={12} width={8} />;
};

const ProjectNameToggle = ({form, field}) => {
  const isProjectNameVisible = _.get(form.values, `${field}.isProjectNameVisible`);
  return (
    <Row index={0} style={{alignItems: 'center'}}>
      <Icon
        source={isProjectNameVisible ? Icon.Trash : Icon.Plus}
        size={14}
        color={colors.blue.interactive}
      />
      <Space width={8} />
      <Button
        onPress={() => {
          form.setFieldValue(`${field}.isProjectNameVisible`, !isProjectNameVisible);
          form.setFieldValue(`${field}.name`, '');
        }}
      >
        <ButtonText>{isProjectNameVisible ? 'Remove project name' : 'Add project name'}</ButtonText>
      </Button>
    </Row>
  );
};

const HeaderRow = ({form, field}) => {
  const responsive = useResponsive();
  return (
    <Row index={0} {...responsive}>
      <Modal.BlockHeader>Storage Information</Modal.BlockHeader>
      {responsive.mobile ? <FieldSpace /> : <Space style={{flex: 1}} />}
      <ProjectNameToggle form={form} field={field} />
    </Row>
  );
};

const ProjectNameRow = ({form, field, index}) => {
  return (
    <Row index={index}>
      <FieldInput.Memoized
        {...form}
        name={`${field}.name`}
        label={'Storage Project Name'}
        input={{
          placeholder: 'Enter storage project name',
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const getDescriptionNameForOrganizationWarehouse = ({warehouses}) => {
  if (_.isEmpty(warehouses)) {
    return 'No warehouses';
  } else {
    return warehouses.map((warehouse) => warehouse.name).join(', ');
  }
};

const BranchRow = ({
  form,
  field,
  index,
  organization,
  setSelectedOrganization,
  isDisabled,
  isFixedBranch,
}) => {
  const responsive = useResponsive();
  const organizationOptions = _.orderBy(
    organization.company.organizations,
    ['isPrimary', 'name'],
    ['desc', 'asc'],
  ).map((organization) => {
    return {
      label: organization.name,
      value: _.toNumber(organization.id),
      isDisabled: _.isEmpty(organization.warehouses),
      description: getDescriptionNameForOrganizationWarehouse({
        warehouses: organization.warehouses,
      }),
      organization,
    };
  });
  return (
    <Row index={index} {...responsive}>
      <FieldInput
        {...form}
        label={'Branch'}
        component={DropdownInput}
        name={`${field}.assignedOrganizationId`}
        isRequired
        index={0}
        input={{
          options: organizationOptions,
          placeholder: `Select organization`,
          setFieldValue: form.setFieldValue,
          onChangeValue: (value, {organization}) => {
            setSelectedOrganization(organization);
            form.setFieldValue(`${field}.valueForms`, []);
            form.setFieldValue(`${field}.projectTypeId`, null);
          },
          isDisabled: isFixedBranch || isDisabled,
          style: {flex: 1},
          components: {
            Option: DropdownInput.OptionWithDescription,
          },
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const TypeRow = ({form, field, index, organization, setSelectedProjectTypeUuid}) => {
  const responsive = useResponsive();
  return (
    <Row index={index} {...responsive}>
      <FieldInput
        {...form}
        component={DropdownInput}
        name={`${field}.projectTypeId`}
        label={'Storage Project Type'}
        isRequired
        input={{
          options: _.isEmpty(organization)
            ? []
            : organization.storageProjectTypes.map((projectType) => ({
                label: projectType.name,
                value: Number(projectType.id),
                projectType,
              })),
          placeholder: 'Select storage project type',
          isSearchable: true,
          setFieldValue: form.setFieldValue,
          onChangeValue: (value, {projectType}) => {
            setSelectedProjectTypeUuid(projectType.uuid);
          },
          style: {flex: 1},
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const DateRow = ({form, field, index}) => {
  const responsive = useResponsive();
  return (
    <Row index={index} {...responsive}>
      <FieldInput.Memoized
        {...form}
        component={DateInput}
        name={`${field}.startDate`}
        label={'Date In'}
        isRequired
        input={{
          placeholder: 'Start storage on',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
        }}
        style={{flex: 1}}
      />
      <FieldSpace />
      <FieldInput.Memoized
        {...form}
        component={DateInput}
        name={`${field}.endDate`}
        label={'Date Out'}
        input={{
          placeholder: 'End storage on',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const DateRowV2 = ({form, field, index}) => {
  return (
    <DateRangeInput
      form={form}
      field={field}
      index={index}
      startDateField={`${field}.startDate`}
      endDateField={`${field}.endDate`}
      startDateProps={{label: 'Date In'}}
      startDateInputProps={{placeholder: 'Start storage on'}}
      endDateProps={{label: 'Date Out'}}
      endDateInputProps={{placeholder: 'End storage on'}}
    />
  );
};

const StorageProjectStorageFields = ({
  form,
  field,
  organization,
  isEdit,
  isFixedBranch,
  setSelectedProjectTypeUuid,
  hasContainersAssigned,
  isViewerOrgPrimaryMultibranch,
  selectedOrganization,
  setSelectedOrganization,
}) => {
  const isProjectNameVisible = _.get(form.values, `${field}.isProjectNameVisible`);

  const {
    isEnabledMultibranchStorage,
    isEnabledPastDateWarning,
    isEnabledStorageProjectAssignBranch,
  } = organization.features;

  return (
    <React.Fragment>
      <HeaderRow form={form} field={field} />
      {isProjectNameVisible && (
        <React.Fragment>
          <FieldSpace />
          <ProjectNameRow form={form} field={field} index={0} />
        </React.Fragment>
      )}
      {isEnabledMultibranchStorage && isViewerOrgPrimaryMultibranch && (
        <React.Fragment>
          <FieldSpace />
          <BranchRow
            isFixedBranch={isFixedBranch}
            form={form}
            field={field}
            index={1}
            organization={organization}
            setSelectedOrganization={setSelectedOrganization}
            isDisabled={isEdit && (hasContainersAssigned || !isEnabledStorageProjectAssignBranch)}
          />
        </React.Fragment>
      )}
      <FieldSpace />
      <TypeRow
        form={form}
        field={field}
        index={2}
        organization={selectedOrganization}
        setSelectedProjectTypeUuid={setSelectedProjectTypeUuid}
      />
      <FieldSpace />
      {isEnabledPastDateWarning ? (
        <DateRowV2 form={form} field={field} index={3} />
      ) : (
        <DateRow form={form} field={field} index={3} />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageProjectStorageFields.fragment = gql`
  fragment StorageProjectStorageFields on Organization {
    id
    isPrimaryMultibranch
    isPrimary
    isOwnerOfSettings
    storageProjectTypes: projectTypesForCategory(category: "STORAGE") {
      id
      name
      uuid
    }
    company {
      id
      primaryOrganization {
        id
      }
      organizations {
        id
        name
        isPrimary
        kind
        warehouses {
          id
          name
        }
        storageProjectTypes: projectTypesForCategory(category: "STORAGE") {
          id
          name
          uuid
        }
      }
    }
    features {
      isEnabledMultibranchStorage: isEnabled(feature: "MULTIBRANCH_STORAGE")
      isEnabledPastDateWarning: isEnabled(feature: "PAST_DATE_WARNING")
      isEnabledStorageProjectAssignBranch: isEnabled(feature: "STORAGE_PROJECT_ASSIGN_BRANCH")
    }
  }
`;

export default StorageProjectStorageFields;
