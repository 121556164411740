// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import Modal from '@shared/design/components/Modal';
import ProjectJobAdditionalInfoBlock from 'modules/Project/Update/components/ProjectJobAdditionalInfoBlock';

const FieldSpace = () => {
  return <Space height={12} width={8} />;
};

const ProjectJobAdditionalFields = ({form, field, organization}) => {
  return (
    <React.Fragment>
      <Modal.BlockHeader>Additional Information</Modal.BlockHeader>
      <FieldSpace />
      <ProjectJobAdditionalInfoBlock.Fields
        form={form}
        jobField={field}
        organization={organization}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobAdditionalFields.fragment = gql`
  ${ProjectJobAdditionalInfoBlock.fragment}

  fragment ProjectJobAdditionalFields on Organization {
    id
    ...ProjectJobAdditionalInfoBlock
  }
`;

export default ProjectJobAdditionalFields;
