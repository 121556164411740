// Libraries
import _ from 'lodash';
import {loadPayengine} from 'payengine';
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMountEffect, useNavigationDOM, useQuery, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Tabs from '@shared/design/components/Tabs';
import {PayEngineConfig} from '@shared/modules/Payment/components/PayEngineConfig';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';

const Row = Styled.View`
  flex-direction: row;
`;

const PageContainer = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View`
  padding-horizontal: 24px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading1}
`;

const DescriptionText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const getTabs = ({flags}) => [
  {
    label: 'Dashboard',
    url: '/payments/dashboard',
    type: 'dashboard',
    description:
      'View the total volume of credit card payments going ' +
      'through Supermove and the number of transactions ' +
      'collected.',
  },
  {
    label: 'Transactions',
    url: '/payments/transactions',
    type: 'transactions',
    description: 'View all payments on Supermove and issue refunds.',
  },
  {
    label: 'Batches',
    url: '/payments/batches',
    type: 'payouts',
    viewType: 'settlement',
    description:
      'View settled transactions processed by the payment ' +
      'processor. These transactions are ready to be ' +
      "deposited into your company's bank account.",
  },
  {
    label: 'Payouts',
    url: '/payments/payouts',
    type: 'payouts',
    viewType: 'funding',
    description: "View all payouts that have been deposited into your company's bank account.",
  },
  {
    label: 'Statements',
    url: '/payments/statements',
    type: 'statements',
    description: 'View statements of payments and refunds that have been made on Supermove.',
  },
  {
    label: 'Disputes',
    url: '/payments/disputes',
    type: 'disputes',
    description: 'View chargebacks and issues reported by customers to your payment processor.',
  },
];

const getTabIndex = ({navigator, flags}) => {
  return _.findIndex(getTabs({flags}), (tab) => navigator.location.pathname.includes(tab.url));
};

const getTab = ({navigator, flags}) => {
  return _.find(getTabs({flags}), (tab) => navigator.location.pathname.includes(tab.url));
};

const ContentHeader = ({tab}) => {
  return (
    <HeaderContainer>
      <TitleText>{tab.label}</TitleText>
      <Space height={12} />
      <DescriptionText>{tab.description}</DescriptionText>
    </HeaderContainer>
  );
};

const PaymentsTabContent = ({organization, flags}) => {
  const {navigator} = useNavigationDOM();
  const currentTab = getTab({navigator, flags});

  useMountEffect(() => {
    loadPayengine(PayEngineConfig.get(organization.mode));
  });

  // <pay-engine/> is a Web Component provided by PayEngine. See:
  //   https://docs.payengine.co/payengine-developer-docs/getting-started-1/5.-frontend-frameworks
  return (
    <ScrollView>
      <pay-engine
        type={currentTab.type}
        view-type={currentTab.viewType}
        merchant-id={organization.payengineMerchant.merchantId}
        hash={organization.payengineMerchant.hmac}
      />
    </ScrollView>
  );
};

const PaymentsPageContent = ({viewer}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const flags = viewer.viewingOrganization.features;
  const currentTabIndex = getTabIndex({navigator, flags});
  const currentTab = getTab({navigator, flags});

  return (
    <React.Fragment>
      <Row>
        <Tabs
          tabs={getTabs({flags})}
          handlePressTab={(tab) => navigator.push(tab.url)}
          activeTabIndex={currentTabIndex}
          style={{flex: 1}}
          scrollViewStyle={{paddingLeft: 24, paddingTop: responsive.mobile ? 12 : 0}}
        />
      </Row>
      <Line />
      <Space height={24} />
      <ContentHeader tab={currentTab} />
      <Space height={12} />
      <PaymentsTabContent organization={viewer.viewingOrganization} flags={flags} />
    </React.Fragment>
  );
};

const PaymentsPage = () => {
  const {data, loading} = useQuery(PaymentsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SidebarPageV2 selected={'payments'}>
      <PageContainer>
        <StandardOfficeHeader title={'Payments'} showLine={false} />
        <Loading loading={loading} as={PageLoadingIndicator}>
          {() => {
            return <PaymentsPageContent viewer={data.viewer} />;
          }}
        </Loading>
      </PageContainer>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PaymentsPage.query = gql`
  query PaymentsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        mode
        slug
        payengineMerchant {
          id
          merchantId
          hmac
        }
      }
    }
  }
`;

export default PaymentsPage;
