// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm, useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Float} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import ItemForm from '@shared/modules/Inventory/forms/ItemForm';
import Modal from 'components/Modal';
import Field from 'modules/App/components/Field';

const Container = Styled.View`
  padding: 20px;
`;

const CountRow = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
`;

const Name = Styled.H7`
  flex: 1;
  color: ${colors.gray.primary};
  text-transform: uppercase;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Actions = Styled.View`
  align-items: stretch;
`;

const Button = Styled.LoadingButton`
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const CounterTouchable = Styled.Touchable`
`;

const CounterButton = ({disabled, count, onPress, children}) => {
  return (
    <CounterTouchable disabled={disabled} onPress={onPress}>
      <Value style={{width: 40, height: 40}}>{children}</Value>
    </CounterTouchable>
  );
};

const Value = Styled.View`
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: ${(props) => props.color};
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const CounterTextInput = Styled.TextInput.H7`
`;

const CounterInput = ({name, count, itemForm, form}) => {
  const disabled = count === 0;
  const onDecrement = () => form.setFieldValue(name, Float.toForm(count - 1));
  const onIncrement = () => form.setFieldValue(name, Float.toForm(count + 1));

  return (
    <Row>
      <CounterButton disabled={disabled} onPress={onDecrement}>
        <Icon
          color={disabled ? colors.gray.tertiary : colors.gray.primary}
          size={16}
          source={Icon.Minus}
        />
      </CounterButton>
      <CounterTextInput
        keyboardType={'phone-pad'}
        value={_.get(form, `values.${name}`)}
        placeholder={'Count'}
        onChangeText={(value) => form.setFieldValue(name, value)}
        style={{
          width: 80,
          height: 40,
          borderRadius: 0,
          borderColor: colors.blue.accent,
          textAlign: 'center',
        }}
      />
      <CounterButton onPress={onIncrement}>
        <Icon color={colors.gray.primary} size={16} source={Icon.Plus} />
      </CounterButton>
    </Row>
  );
};

const Buttons = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Label = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const CounterNumberButton = ({disabled, count, onPress}) => {
  return (
    <CounterTouchable disabled={disabled} onPress={onPress}>
      <CounterValue
        color={disabled ? colors.gray.background : colors.white}
        value={count}
        width={60}
      />
    </CounterTouchable>
  );
};

const ValueText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const CounterValue = ({color, value, width}) => {
  return (
    <Value color={color} style={{width, height: 35}}>
      <ValueText>{value}</ValueText>
    </Value>
  );
};

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const Touchable = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const IsDerivedWeightButton = ({inventory, form}) => {
  const {isDerivedWeight} = form.values.itemForm;
  const densityFactor = Float.toFloat(form.values.itemForm.densityFactor);
  const volume = Float.toFloat(form.values.itemForm.volume);

  return (
    <Touchable
      color={isDerivedWeight ? colors.blue.interactive : colors.alpha(colors.red.warning, 0.1)}
      onPress={() => {
        const newIsDerivedWeight = !isDerivedWeight;
        const newDensityFactor = newIsDerivedWeight ? inventory.densityFactor : densityFactor;
        const newWeight = volume * newDensityFactor;
        form.setFieldValue('itemForm.isDerivedWeight', newIsDerivedWeight);

        if (newIsDerivedWeight) {
          form.setFieldValue('itemForm.densityFactor', Float.toForm(newDensityFactor));
          form.setFieldValue('itemForm.weight', Float.toForm(newWeight));
        }
      }}
    >
      <Icon
        color={isDerivedWeight ? colors.blue.accentDark : colors.red.warning}
        size={14}
        source={isDerivedWeight ? Icon.Link : Icon.Unlink}
      />
    </Touchable>
  );
};

const CreateItemModal = ({isOpen, name, itemForm, form: parentForm, inventory, handleClose}) => {
  const responsive = useResponsive();
  const form = useForm({
    initialValues: {
      itemForm: ItemForm.toForm(itemForm),
    },
    onSubmit: ({itemForm}) => {
      const {itemForms} = parentForm.values.collectionForm;

      // Add the new ItemForm to the collection and submit the parent form.
      parentForm.setFieldValue('collectionForm.itemForms', [...itemForms, itemForm]);
      parentForm.setFieldValue('collectionForm.hasChanges', true);
      handleClose();
    },
  });

  const {isDerivedWeight} = form.values.itemForm;
  const takeCount = Float.toFloat(form.values.itemForm.takeCount);
  const leaveCount = Float.toFloat(form.values.itemForm.leaveCount);
  const volume = Float.toFloat(form.values.itemForm.volume);
  const densityFactor = Float.toFloat(form.values.itemForm.densityFactor);
  const weight = Float.toFloat(form.values.itemForm.weight);
  const volumeIncrement = 5;
  const densityFactorIncrement = 0.25;

  return (
    <Modal isOpen={isOpen} title={name} subtitle={''} onClose={handleClose}>
      <Container>
        <FieldInput
          {...form}
          name={'itemForm.name'}
          label={'Item Name'}
          isRequired
          input={{
            autoFocus: true,
            placeholder: 'Enter a custom item name',
            style: {
              width: responsive.mobile ? '100%' : 360,
            },
          }}
          style={{
            width: responsive.mobile ? '100%' : 360,
          }}
        />
        <Space height={20} />
        <Row>
          <Label>CU FT</Label>
          <Buttons>
            <CounterNumberButton
              count={`-${volumeIncrement}`}
              onPress={() => {
                const newVolume = volume - volumeIncrement;
                const newWeight = newVolume * densityFactor;
                form.setFieldValue(`itemForm.volume`, newVolume);
                form.setFieldValue(`itemForm.weight`, newWeight);
              }}
            />
            <CounterValue color={colors.gray.background} value={volume} width={100} />
            <CounterNumberButton
              count={`+${volumeIncrement}`}
              onPress={() => {
                const newVolume = volume + volumeIncrement;
                const newWeight = newVolume * densityFactor;
                form.setFieldValue(`itemForm.volume`, newVolume);
                form.setFieldValue(`itemForm.weight`, newWeight);
              }}
            />
          </Buttons>
        </Row>
        <Space height={20} />
        <Row>
          <Label>LB / CU FT</Label>
          <Buttons>
            <IsDerivedWeightButton inventory={inventory} form={form} />
            <Space width={5} />
            <CounterNumberButton
              disabled={isDerivedWeight}
              count={`-${densityFactorIncrement}`}
              onPress={() => {
                const newDensityFactor = densityFactor - densityFactorIncrement;
                const newWeight = volume * newDensityFactor;
                form.setFieldValue(`itemForm.densityFactor`, newDensityFactor);
                form.setFieldValue(`itemForm.weight`, newWeight);
              }}
            />
            <CounterValue color={colors.gray.background} value={`${densityFactor}x`} width={100} />
            <CounterNumberButton
              disabled={isDerivedWeight}
              count={`+${densityFactorIncrement}`}
              onPress={() => {
                const newDensityFactor = densityFactor + densityFactorIncrement;
                const newWeight = volume * newDensityFactor;
                form.setFieldValue(`itemForm.densityFactor`, newDensityFactor);
                form.setFieldValue(`itemForm.weight`, newWeight);
              }}
            />
          </Buttons>
        </Row>
        <Space height={20} />
        <Row>
          <Label>LB</Label>
          <Buttons>
            <Space width={60} />
            <CounterValue
              color={colors.alpha(colors.gray.primary, 0.3)}
              value={weight}
              width={100}
            />
            <Space width={60} />
          </Buttons>
        </Row>
        {inventory.project.organization.features.isEnabledInventoryPriceField && (
          <React.Fragment>
            <Space height={20} />
            <Row>
              <Label>Price</Label>
              <Buttons>
                <Field
                  {...form}
                  name={'itemForm.price'}
                  label={''}
                  component={CurrencyInput}
                  input={{
                    component: TextInput,
                    placeholder: 'Enter the price',
                    setFieldValue: form.setFieldValue,
                    setFieldTouched: form.setFieldTouched,
                    style: {
                      width: 180,
                    },
                  }}
                />
              </Buttons>
            </Row>
          </React.Fragment>
        )}
        <Space height={20} />
        <Line />
        <Space height={10} />
        <CountRow>
          <Name>Going</Name>
          <CounterInput
            name={`itemForm.takeCount`}
            count={takeCount}
            itemForm={itemForm}
            form={form}
          />
        </CountRow>
        <CountRow>
          <Name>Not Going</Name>
          <CounterInput
            name={`itemForm.leaveCount`}
            count={leaveCount}
            itemForm={itemForm}
            form={form}
          />
        </CountRow>
        <Space height={20} />
        <Actions>
          <Button
            disabled={
              _.get(form.values, 'itemForm.name') === '' || (takeCount === 0 && leaveCount === 0)
            }
            color={colors.blue.accentDark}
            onPress={() => form.handleSubmit()}
          >
            <Text color={colors.white}>Add Custom Item</Text>
          </Button>
        </Actions>
      </Container>
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateItemModal.fragment = gql`
  fragment CreateItemModal on Inventory {
    id
    project {
      id
      organization {
        id
        features {
          isEnabledInventoryPriceField: isEnabled(feature: "INVENTORY_PRICE_FIELD")
        }
      }
    }
  }
`;

export default CreateItemModal;
