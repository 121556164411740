// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Float} from '@supermove/utils';

// App
import ItemForm from '@shared/modules/Inventory/forms/ItemForm';
import CreateItemModal from 'modules/Item/components/CreateItemModal';
import UpdateItemModal from 'modules/Item/components/UpdateItemModal';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  width: 215px;
  padding-horizontal: 10px;
  padding-vertical: 10px;
  border-radius: 8px;
`;

const Info = Styled.Touchable`
  flex: 1;
  justify-content: center;
`;

const Name = Styled.H8`
  height: 32px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Volume = Styled.H8`
  font-size: 8px;
  line-height: 12px;
  color: ${colors.gray.tertiary};
`;

const Counts = Styled.View`
  flex-direction: row;
`;

const CountContainer = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 3px;
  background-color: ${(props) => colors.alpha(props.color, 0.1)};
`;

const Count = Styled.H8`
  ${fontWeight(700)}
  color: ${({vars = {}}) => vars.color};
`;

const Counter = ({color, count, onIncrement}) => {
  return (
    <CountContainer color={color} onPress={() => onIncrement(count + 1)}>
      <Count vars={{color}}>{count}</Count>
    </CountContainer>
  );
};

const ItemFormItem = ({index, field, itemForm, form, inventory}) => {
  const updateItemModal = useModal({name: 'Update Item Modal'});
  const createItemModal = useModal({name: 'Create Item Modal'});

  // Used on the CreateItemModal.
  const newItemForm = ItemForm.new({
    collectionId: itemForm.collectionId,
    name: `${itemForm.name} custom`,
    takeCount: 1,
    leaveCount: 0,
    weight: ItemForm.getWeight(itemForm),
    volume: ItemForm.getVolume(itemForm),
    price: ItemForm.getPrice(itemForm),
    isDerivedWeight: itemForm.isDerivedWeight,
    densityFactor: ItemForm.getDensityFactor(itemForm),
  });

  return (
    <React.Fragment>
      <Container>
        <Info onPress={updateItemModal.handleOpen}>
          <Name numberOfLines={2}>{itemForm.name}</Name>
          <Space height={4} />
          <Volume>{`${itemForm.volume} CU FT`}</Volume>
        </Info>
        <Space width={5} />
        <Counts>
          <Counter
            color={colors.green.status}
            count={Float.toFloat(itemForm.takeCount)}
            onIncrement={(count) => {
              form.setFieldValue(`${field}.takeCount`, Float.toForm(count));
              form.setFieldValue('collectionForm.hasChanges', true);
            }}
          />
          <Space width={2} />
          <Counter
            color={colors.gray.secondary}
            count={Float.toFloat(itemForm.leaveCount)}
            onIncrement={(count) => {
              form.setFieldValue(`${field}.leaveCount`, Float.toForm(count));
              form.setFieldValue('collectionForm.hasChanges', true);
            }}
          />
        </Counts>
      </Container>
      <UpdateItemModal
        key={updateItemModal.key}
        canCreateItem
        isOpen={updateItemModal.isOpen}
        index={index}
        field={field}
        itemForm={itemForm}
        form={form}
        inventory={inventory}
        handleClose={updateItemModal.handleClose}
        handleOpenCreateItemModal={createItemModal.handleOpen}
      />
      <CreateItemModal
        key={createItemModal.key}
        isOpen={createItemModal.isOpen}
        name={itemForm.name}
        itemForm={newItemForm}
        form={form}
        inventory={inventory}
        handleClose={createItemModal.handleClose}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ItemFormItem.fragment = gql`
  ${UpdateItemModal.fragment}
  ${CreateItemModal.fragment}

  fragment ItemFormItem on Inventory {
    id
    ...UpdateItemModal
    ...CreateItemModal
  }
`;

export default ItemFormItem;
