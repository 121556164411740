// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {FlatList, Lifecycle, ScrollView, Space, Styled, Switch} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive, useState} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import CalendarDaySummaryModal from 'modules/App/Day/components/CalendarDaySummaryModal';
import CalendarProjectTypeDaysSection from 'modules/App/Day/components/CalendarProjectTypeDaysSection';
import DayJobItemV1 from 'modules/App/Day/components/DayJobItemV1';
import DayJobsStatusBanner from 'modules/App/Day/components/DayJobsStatusBanner';
import DayNotesSection from 'modules/App/Day/components/DayNotesSection';

const Container = Styled.View`
  flex: 1;
`;

const Header = Styled.View`
  padding-top: ${(props) => (props.mobile ? 0 : 10)}px;
  padding-bottom: ${(props) => (props.mobile ? 10 : 10)}px;
  background-color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-horizontal: 10px;
`;

const Title = Styled.H3`
  ${fontWeight(900)}
  color: ${colors.gray.primary};
  letter-spacing: -0.5;
`;

const Subtitle = Styled.H7`
  color: ${colors.gray.primary};
`;

const Text = Styled.H7`
`;

const Jobs = Styled.View`
`;

const Section = Styled.View`
  margin-bottom: 10px;
`;

const Label = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Job = Styled.View`
  margin-bottom: ${(props) => (props.isLast ? 0 : 10)}px;
  margin-horizontal: 10px;
`;

const EmptyContainer = Styled.View`
  flex: 1;
  align-items: center;
  padding-vertical: 20px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.gray.border};
`;

const EmptyMessage = Styled.H8`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const Actions = Styled.View`
`;

const Touchable = Styled.Touchable`
`;

const ViewSummaryLink = Styled.H6`
  color: ${colors.blue.interactive};
`;

const getUnscheduledJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return job.primaryStatus !== 'CANCELLED' && !job.isTest && !job.startTime1;
  });
};

const getMorningJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return (
      job.primaryStatus !== 'CANCELLED' &&
      !job.isTest &&
      Datetime.isBetweenTime(job.startTime1, '0000', '1200')
    );
  });
};

const getAfternoonJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return (
      job.primaryStatus !== 'CANCELLED' &&
      !job.isTest &&
      Datetime.isBetweenTime(job.startTime1, '1200', '1800')
    );
  });
};

const getEveningJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return (
      job.primaryStatus !== 'CANCELLED' &&
      !job.isTest &&
      Datetime.isBetweenTime(job.startTime1, '1800', '2400')
    );
  });
};

const getCancelledJobs = ({jobs}) => {
  return jobs.filter((job) => {
    return job.primaryStatus === 'CANCELLED' && !job.isTest;
  });
};

const getTrainingJobs = ({jobs}) => {
  return jobs.filter((job) => job.isTest);
};

const getMorningJobsCount = ({jobs}) => {
  return getMorningJobs({jobs}).length;
};

const getAfternoonJobsCount = ({jobs}) => {
  return getAfternoonJobs({jobs}).length;
};

const getEveningJobsCount = ({jobs}) => {
  return getEveningJobs({jobs}).length;
};

const getCancelledJobsCount = ({jobs}) => {
  return getCancelledJobs({jobs}).length;
};

const getTrainingJobsCount = ({jobs}) => {
  return getTrainingJobs({jobs}).length;
};

const getPrimaryJobsCountsText = ({jobs}) => {
  return (
    `${getMorningJobsCount({jobs})} morning - ` +
    `${getAfternoonJobsCount({jobs})} afternoon - ` +
    `${getEveningJobsCount({jobs})} evening`
  );
};

const filterJobs = ({isCompleteOnly, jobs}) => {
  if (isCompleteOnly) {
    return jobs.filter((job) => ['COMPLETE', 'FINAL', 'NOT_FINAL'].includes(job.primaryStatus));
  }
  return jobs;
};

const isBannerVisible = ({calendarDay, isCompleteOnly}) => {
  if (!calendarDay) {
    return false;
  }

  if (isCompleteOnly) {
    return false;
  }

  const cancelledCount = getCancelledJobsCount({jobs: calendarDay.jobs});
  const trainingCount = getTrainingJobsCount({jobs: calendarDay.jobs});
  return calendarDay.jobs.length - cancelledCount - trainingCount > 0;
};

const JobsSection = ({hideIfEmpty, empty, label, jobs}) => {
  if (hideIfEmpty && jobs.length === 0) {
    return null;
  }

  return (
    <Section>
      <Row>
        <Label>{`${label} (${jobs.length})`}</Label>
      </Row>
      <Space height={5} />
      <FlatList
        data={jobs}
        keyExtractor={(job) => job.id}
        renderItem={({item: job, index}) => (
          <Job isLast={index === jobs.length - 1}>
            <DayJobItemV1 job={job} />
          </Job>
        )}
        ListEmptyComponent={() => (
          <Row>
            <EmptyContainer>
              <EmptyMessage>{empty}</EmptyMessage>
            </EmptyContainer>
          </Row>
        )}
      />
    </Section>
  );
};

const JobsList = ({isCompleteOnly, jobs, refetch, isEnabledMovesCalendarJobCardV2}) => (
  <Jobs>
    <JobsSection
      hideIfEmpty
      empty={`No unscheduled jobs`}
      label={'Unscheduled Jobs'}
      jobs={getUnscheduledJobs({jobs})}
      refetch={refetch}
      isEnabledMovesCalendarJobCardV2={isEnabledMovesCalendarJobCardV2}
    />
    <Space height={20} />
    <JobsSection
      empty={`No morning jobs`}
      label={'Morning Jobs'}
      jobs={getMorningJobs({jobs})}
      refetch={refetch}
      isEnabledMovesCalendarJobCardV2={isEnabledMovesCalendarJobCardV2}
    />
    <Space height={20} />
    <JobsSection
      empty={`No afternoon jobs`}
      label={'Afternoon Jobs'}
      jobs={getAfternoonJobs({jobs})}
      refetch={refetch}
      isEnabledMovesCalendarJobCardV2={isEnabledMovesCalendarJobCardV2}
    />
    <Space height={20} />
    <JobsSection
      empty={`No evening jobs`}
      label={'Evening Jobs'}
      jobs={getEveningJobs({jobs})}
      refetch={refetch}
      isEnabledMovesCalendarJobCardV2={isEnabledMovesCalendarJobCardV2}
    />
    <Space height={20} />
    {!isCompleteOnly && (
      <React.Fragment>
        <JobsSection
          empty={'No cancelled jobs'}
          label={'Cancelled Jobs'}
          jobs={getCancelledJobs({jobs})}
          refetch={refetch}
          isEnabledMovesCalendarJobCardV2={isEnabledMovesCalendarJobCardV2}
        />
        <Space height={20} />
      </React.Fragment>
    )}
    <JobsSection
      empty={`No training jobs`}
      label={'Training Jobs'}
      jobs={getTrainingJobs({jobs})}
      refetch={refetch}
      isEnabledMovesCalendarJobCardV2={isEnabledMovesCalendarJobCardV2}
    />
    <Space height={20} />
  </Jobs>
);

const DayJobsHeader = ({
  date,
  hasTitleDay,
  isCompleteOnly,
  jobs,
  calendarDay,
  responsive,
  setIsCompleteOnly,
}) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const isEnabledCalendarDaySummary = _.get(
    calendarDay,
    'day.organization.features.isEnabledCalendarDaySummary',
    false,
  );

  return (
    <Header {...responsive}>
      {hasTitleDay && (
        <Row>
          <Title>{Datetime.convertToDisplayDate(date)}</Title>
        </Row>
      )}
      <Row>
        <Subtitle>{getPrimaryJobsCountsText({jobs})}</Subtitle>
      </Row>
      {isEnabledCalendarDaySummary && !!calendarDay && (
        <Row style={{marginTop: 5}}>
          <Actions>
            <Touchable onPress={handleOpen}>
              <ViewSummaryLink>View Summary</ViewSummaryLink>
            </Touchable>
          </Actions>
          <CalendarDaySummaryModal
            isOpen={isOpen}
            calendarDay={calendarDay}
            onClose={handleClose}
          />
        </Row>
      )}
      <Row style={{marginTop: 5}}>
        <Text style={{marginRight: 7}}>Only show complete jobs</Text>
        <Switch
          isOn={isCompleteOnly}
          onTintColor={colors.gray.primary}
          onChange={async () => {
            const completeOnly = await Storage.getItem('DAY_JOBS_COMPLETE_ONLY');
            const newCompleteOnly = completeOnly === 'true' ? 'false' : 'true';
            await Storage.setItem('DAY_JOBS_COMPLETE_ONLY', newCompleteOnly);
            setIsCompleteOnly(newCompleteOnly === 'true');
          }}
        />
      </Row>
      <Row>
        <CalendarProjectTypeDaysSection date={date} />
      </Row>
    </Header>
  );
};

const DayJobsListV1 = ({date, calendarDay, hasTitleDay, refetch}) => {
  const responsive = useResponsive();
  const [isCompleteOnly, setIsCompleteOnly] = useState(false);

  return (
    <Lifecycle
      onMount={async () => {
        const completeOnly = await Storage.getItem('DAY_JOBS_COMPLETE_ONLY');
        setIsCompleteOnly(completeOnly === 'true');
      }}
    >
      <Container>
        {!responsive.mobile && (
          <DayJobsHeader
            date={date}
            hasTitleDay={hasTitleDay}
            isCompleteOnly={isCompleteOnly}
            jobs={calendarDay ? filterJobs({isCompleteOnly, jobs: calendarDay.jobs}) : []}
            calendarDay={calendarDay}
            responsive={responsive}
            setIsCompleteOnly={setIsCompleteOnly}
          />
        )}
        <ScrollView
          style={{
            flex: 1,
          }}
        >
          {responsive.mobile && (
            <DayJobsHeader
              date={date}
              hasTitleDay={false}
              isCompleteOnly={isCompleteOnly}
              jobs={calendarDay ? filterJobs({isCompleteOnly, jobs: calendarDay.jobs}) : []}
              calendarDay={calendarDay}
              responsive={responsive}
              setIsCompleteOnly={setIsCompleteOnly}
            />
          )}
          {!!calendarDay && (
            <DayNotesSection day={calendarDay.day} style={{marginHorizontal: '10px'}} />
          )}
          {isBannerVisible({calendarDay, isCompleteOnly}) && (
            <DayJobsStatusBanner status={calendarDay.status} />
          )}
          <JobsList
            isCompleteOnly={isCompleteOnly}
            jobs={calendarDay ? filterJobs({isCompleteOnly, jobs: calendarDay.jobs}) : []}
            refetch={refetch}
            isEnabledMovesCalendarJobCardV2={_.get(
              calendarDay,
              'day.organization.features.isEnabledMovesCalendarJobCardV2',
              false,
            )}
          />
        </ScrollView>
      </Container>
    </Lifecycle>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DayJobsListV1.propTypes = {
  date: PropTypes.string.isRequired,
  hasTitleDay: PropTypes.bool,
};

DayJobsListV1.defaultProps = {
  hasTitleDay: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DayJobsListV1.fragment = gql`
  ${CalendarDaySummaryModal.fragment}
  ${DayJobItemV1.fragment}
  ${DayJobsStatusBanner.fragment}
  ${DayNotesSection.fragment}

  fragment DayJobsListV1 on CalendarDay {
    day {
      id
      organization {
        id
        features {
          isEnabledCalendarDaySummary: isEnabled(feature: "CALENDAR_DAY_SUMMARY")
        }
      }
      ...DayNotesSection
    }
    jobs {
      id
      isTest
      primaryStatus: calendarPrimaryStatus
      ...DayJobItemV1
    }
    ...CalendarDaySummaryModal
    ...DayJobsStatusBanner
  }
`;

export default DayJobsListV1;
