// Libraries

// Supermove
import {uuid} from '@supermove/utils';

export interface GetProjectDataActionFormType {
  workflowRunStepUuid: string;
}

const _new = (): GetProjectDataActionFormType => ({
  workflowRunStepUuid: uuid(),
});

const edit = (getProjectDataActionForm: any): GetProjectDataActionFormType => ({
  workflowRunStepUuid: getProjectDataActionForm.workflowRunStepUuid,
});

const toForm = (
  getProjectDataActionForm: GetProjectDataActionFormType,
): GetProjectDataActionFormType => ({
  workflowRunStepUuid: getProjectDataActionForm.workflowRunStepUuid,
});

const toMutation = (
  getProjectDataActionForm: GetProjectDataActionFormType,
): GetProjectDataActionFormType => ({
  workflowRunStepUuid: getProjectDataActionForm.workflowRunStepUuid,
});

const GetProjectDataActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default GetProjectDataActionForm;
