// Libraries
import React from 'react';

// Supermove
import {Emoji, Lifecycle, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// App
import JobProjectBillingBlock from 'modules/Job/V2/Move/components/JobProjectBillingBlock';
import ReportMoveJobInfo from 'modules/ReportMove/components/ReportMoveJobInfo';
import ReportMovePage from 'modules/ReportMove/components/ReportMovePage';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  align-items: center;
  width: 100%;
  padding-horizontal: 20px;
`;

const Content = Styled.View`
  width: ${(props) => (props.mobile ? '100%' : '800px')};
  padding-top: 60px;
  padding-bottom: 60px;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const Left = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
`;

const Subtitle = Styled.H6`
  margin-top: 10px;
  line-height: 24px;
`;

const Row = Styled.View`
  margin-top: 40px;
  z-index: ${({index = 0}) => 100 - index};
`;

const Line = Styled.View`
  margin-top: 40px;
  height: 10px;
  background-color: ${colors.gray.border};
`;

const Footer = Styled.View`
  align-self: center;
  width: ${(props) => (props.mobile ? '100%' : '800px')};
  padding-vertical: ${(props) => (props.mobile ? 10 : 15)};
  padding-horizontal: ${(props) => (props.mobile ? 10 : 0)}px;
  background-color: ${colors.white};
`;

const Button = Styled.LoadingButton`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const MiniRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const isCorrectPage = ({reportMove}) => {
  return !reportMove.isFinal;
};

const onMountOrUpdate = ({navigator, reportMove}) => {
  if (!isCorrectPage({reportMove})) {
    navigator.replace(`/move-reports/${reportMove.uuid}/show`);
  }
};

const Redirect = ({children, navigator, reportMove}) => (
  <Lifecycle
    onMount={() => onMountOrUpdate({navigator, reportMove})}
    onUpdate={() => onMountOrUpdate({navigator, reportMove})}
  >
    {isCorrectPage({reportMove}) ? children : null}
  </Lifecycle>
);

const EditBillsReportMovePage = ({
  match: {
    params: {uuid},
  },
}) => (
  <ReportMovePage
    variables={{uuid}}
    query={EditBillsReportMovePage.query}
    title={() => 'Move Report'}
    subtitle={() => 'Edit bills'}
  >
    {({responsive, navigator, data, refetch}) => (
      <Redirect navigator={navigator} reportMove={data.reportMove}>
        <Wrapper>
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Container>
              <Content {...responsive}>
                <ReportMoveJobInfo job={data.reportMove.job} />
                <Line />
                <Row index={0}>
                  <MiniRow>
                    <Left>
                      <Emoji component={EmojiText} name={'dollar'} />
                      <Title>Edit bills</Title>
                    </Left>
                  </MiniRow>
                  <Subtitle>
                    Please enter in the bills and payments associated with this report.
                  </Subtitle>
                </Row>
                <Row index={1}>
                  <JobProjectBillingBlock
                    job={data.reportMove.job}
                    refetch={refetch}
                    sectionIndex={0}
                  />
                </Row>
              </Content>
            </Container>
          </ScrollView>
          <Footer {...responsive}>
            <Button
              // This page is the 4th and last page in the report move flow.
              // By going back three pages, we simulate the user finishing the
              // report move flow and going back to the first page of the flow.
              // This then allows us to use navigator.goBack for the close button
              // on the entry page of the report move flow.
              onPress={() => navigator.go(-3)}
            >
              <Text>Save & Continue</Text>
            </Button>
          </Footer>
        </Wrapper>
      </Redirect>
    )}
  </ReportMovePage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillsReportMovePage.query = gql`
  ${JobProjectBillingBlock.fragment}
  ${ReportMoveJobInfo.fragment}
  ${ReportMovePage.fragment}

  query EditBillsReportMovePage($uuid: String!) {
    ${gql.query}
    reportMove(uuid: $uuid) {
      id
      uuid
      isFinal
      job {
        id
        ...ReportMoveJobInfo
        ...JobProjectBillingBlock
      }
      ...ReportMovePage
    }
  }
`;
// ...ReportMoveTimeRangesReference

export default EditBillsReportMovePage;
