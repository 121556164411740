// Libraries
import React from 'react';

// Supermove
import {Styled, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import ListClientsPageContent from 'modules/Client/components/ListClientsPageContent';

const Container = Styled.View`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const PageContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const ListClientsPage = () => {
  const {data, loading, refetch} = useQuery(ListClientsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={'customers'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <SidebarPageV2 selected={'customers'}>
      <PageContainer>
        <Container>
          <StandardOfficeHeader title={'Clients'} showLine={false} />
        </Container>
        <ScrollView
          horizontal
          contentContainerStyle={{
            flexGrow: 1,
          }}
        >
          <ListClientsPageContent
            organization={data.viewer.viewingOrganization}
            refetch={refetch}
          />
        </ScrollView>
      </PageContainer>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListClientsPage.query = gql`
  ${ListClientsPageContent.fragment}
  query ListClientsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...ListClientsPageContent
      }
    }
  }
`;

export default ListClientsPage;
