const _new = ({inventoryLibraryId}: any) => ({
  fileId: null,
  inventoryLibraryId,
});

const toForm = ({fileId, inventoryLibraryId}: any) => ({
  fileId,
  inventoryLibraryId,
});

const toMutation = ({fileId, inventoryLibraryId}: any) => ({
  fileId,
  inventoryLibraryId,
});

const ImportInventoryLibraryRoomTypesForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ImportInventoryLibraryRoomTypesForm;
