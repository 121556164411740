// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';

const CostChangesFoundModal = ({isOpen, handleClose, name, handleDecline, handleAccept}) => {
  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.BoldText>
        {`We found some changes made for ${name}. Would ` +
          `you like to save these changes before leaving?`}
      </SmallModal.BoldText>
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleDecline}>No</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button color={colors.blue.interactive} onPress={handleAccept}>
          Yes
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default CostChangesFoundModal;
