// Libraries
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToggle, useQuery} from '@supermove/hooks';

// App
import EditPanel from '@shared/design/components/Panel/EditPanel';
import OrganizationBusinessInfoAddressForm from '@shared/modules/Organization/forms/OrganizationBusinessInfoAddressForm';
import OrganizationBusinessInfoContactForm from '@shared/modules/Organization/forms/OrganizationBusinessInfoContactForm';
import OrganizationBusinessInfoHoursForm from '@shared/modules/Organization/forms/OrganizationBusinessInfoHoursForm';
import OrganizationBusinessInfoLicenseForm from '@shared/modules/Organization/forms/OrganizationBusinessInfoLicenseForm';
import useUpdateOrganizationBusinessInfoAddressMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationBusinessInfoAddressMutation';
import useUpdateOrganizationBusinessInfoContactMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationBusinessInfoContactMutation';
import useUpdateOrganizationBusinessInfoHoursMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationBusinessInfoHoursMutation';
import useUpdateOrganizationBusinessInfoLicenseMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationBusinessInfoLicenseMutation';
import BusinessAddressPanel from 'modules/Organization/Settings/Company/components/BusinessAddressPanel';
import BusinessHoursPanel from 'modules/Organization/Settings/Company/components/BusinessHoursPanel';
import BusinessLicensePanel from 'modules/Organization/Settings/Company/components/BusinessLicensePanel';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';
import ContactInformationPanel from 'modules/Organization/Settings/Company/components/ContactInformationPanel';

const ContactInformation = ({index, organization}) => {
  const editContactInformationToggle = useToggle();
  const organizationBusinessInfoContactForm =
    OrganizationBusinessInfoContactForm.edit(organization);
  const {form, handleSubmit, submitting} = useUpdateOrganizationBusinessInfoContactMutation({
    organizationBusinessInfoContactForm,
    onSuccess: () => {
      editContactInformationToggle.handleToggleOff();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      index={index}
      bodyComponentProps={{organization}}
      editBodyComponentProps={{form}}
      BodyComponent={ContactInformationPanel}
      EditBodyComponent={ContactInformationPanel.Edit}
      title={'Contact Information'}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editContactInformationToggle.isOn}
      handleEdit={editContactInformationToggle.handleToggleOn}
      handleClose={editContactInformationToggle.handleToggleOff}
    />
  );
};

const BusinessAddress = ({index, organization}) => {
  const editBusinessAddressToggle = useToggle();
  const organizationBusinessInfoAddressForm =
    OrganizationBusinessInfoAddressForm.edit(organization);
  const {form, handleSubmit, submitting} = useUpdateOrganizationBusinessInfoAddressMutation({
    organizationBusinessInfoAddressForm,
    onSuccess: () => {
      editBusinessAddressToggle.handleToggleOff();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      index={index}
      bodyComponentProps={{organization}}
      editBodyComponentProps={{form}}
      BodyComponent={BusinessAddressPanel}
      EditBodyComponent={BusinessAddressPanel.Edit}
      title={'Business Address'}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editBusinessAddressToggle.isOn}
      handleEdit={editBusinessAddressToggle.handleToggleOn}
      handleClose={editBusinessAddressToggle.handleToggleOff}
    />
  );
};

const BusinessHours = ({index, organization}) => {
  const editBusinessHoursToggle = useToggle();
  const organizationBusinessInfoHoursForm = OrganizationBusinessInfoHoursForm.edit(organization);
  const {form, handleSubmit, submitting} = useUpdateOrganizationBusinessInfoHoursMutation({
    organizationBusinessInfoHoursForm,
    onSuccess: () => {
      editBusinessHoursToggle.handleToggleOff();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      index={index}
      bodyComponentProps={{organization}}
      editBodyComponentProps={{form}}
      BodyComponent={BusinessHoursPanel}
      EditBodyComponent={BusinessHoursPanel.Edit}
      title={'Business Hours'}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editBusinessHoursToggle.isOn}
      handleEdit={editBusinessHoursToggle.handleToggleOn}
      handleClose={editBusinessHoursToggle.handleToggleOff}
    />
  );
};

const BusinessLicense = ({index, organization}) => {
  const editBusinessLicenseToggle = useToggle();
  const organizationBusinessInfoLicenseForm =
    OrganizationBusinessInfoLicenseForm.edit(organization);
  const {form, handleSubmit, submitting} = useUpdateOrganizationBusinessInfoLicenseMutation({
    organizationBusinessInfoLicenseForm,
    onSuccess: () => {
      editBusinessLicenseToggle.handleToggleOff();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      index={index}
      bodyComponentProps={{organization}}
      editBodyComponentProps={{form}}
      BodyComponent={BusinessLicensePanel}
      EditBodyComponent={BusinessLicensePanel.Edit}
      title={'Business License'}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editBusinessLicenseToggle.isOn}
      handleEdit={editBusinessLicenseToggle.handleToggleOn}
      handleClose={editBusinessLicenseToggle.handleToggleOff}
    />
  );
};

const CompanyBusinessInfoContent = ({organization}) => {
  return (
    <React.Fragment>
      <ContactInformation index={0} organization={organization} />
      <Space height={24} />
      <BusinessAddress index={1} organization={organization} />
      <Space height={24} />
      <BusinessHours index={2} organization={organization} />
      <Space height={24} />
      <BusinessLicense index={3} organization={organization} />
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyBusinessInfoPage = () => {
  const {loading, data} = useQuery(OrganizationSettingsCompanyBusinessInfoPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage
      title={'Business Info'}
      description={'Fill out all of your business information in the sections below.'}
    >
      <Loading loading={loading}>
        {() => <CompanyBusinessInfoContent organization={data.viewer.viewingOrganization} />}
      </Loading>
    </CompanySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyBusinessInfoPage.query = gql`
  ${OrganizationBusinessInfoAddressForm.edit.fragment}
  ${OrganizationBusinessInfoContactForm.edit.fragment}
  ${OrganizationBusinessInfoHoursForm.edit.fragment}
  ${OrganizationBusinessInfoLicenseForm.edit.fragment}
  query OrganizationSettingsCompanyBusinessInfoPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...OrganizationBusinessInfoAddressForm_edit
        ...OrganizationBusinessInfoContactForm_edit
        ...OrganizationBusinessInfoHoursForm_edit
        ...OrganizationBusinessInfoLicenseForm_edit
      }
    }
  }
`;

export default OrganizationSettingsCompanyBusinessInfoPage;
