// Libraries
import React from 'react';
// @ts-expect-error library is not typed
import {Elements, StripeProvider, injectStripe} from 'react-stripe-elements';

// Supermove
import {useMemo} from '@supermove/hooks';
import {Config} from '@supermove/sdk';

// Payments
import Stripe from '../../apis/Stripe';

import {StripeFormType} from './StripeFormTypes';

const Form = injectStripe(
  ({
    children,
    stripe: injectedStripe,
  }: {
    children: (props: {stripe: unknown}) => React.ReactNode;
    stripe: unknown;
  }) => {
    const stripe = useMemo(() => Stripe.wrap(injectedStripe), [injectedStripe]);
    return children({stripe});
  },
);

const StripeForm: StripeFormType = ({children, stripeAccountId}) => {
  return (
    <StripeProvider apiKey={Config.getStripePublishableKey()} stripeAccount={stripeAccountId}>
      <Elements>
        <Form>{children}</Form>
      </Elements>
    </StripeProvider>
  );
};

export default StripeForm;
