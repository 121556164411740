// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DocumentItemKindV2, {
  DocumentItemKindType,
} from '@shared/modules/Document/enums/DocumentItemKindV2';
import {DocumentTemplateCategoryType} from '@shared/modules/Document/enums/DocumentTemplateCategory';
import DocumentContentJson from '@shared/modules/Document/helpers/DocumentContentJson';
import {
  SelectedDocumentItemType,
  SetSelectedDocumentItemType,
  DocumentContentJsonType,
  SetDocumentContentJsonType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';

const handleAddDocumentItem = ({
  itemKind,
  organization,
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  selectedDocumentItem,
  setSelectedDocumentItem,
  columnIndex,
}: {
  itemKind: DocumentItemKindType;
  organization: OrganizationModel;
  draftDocumentContentJson: DocumentContentJsonType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  selectedDocumentItem: SelectedDocumentItemType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  columnIndex?: number;
}) => {
  const newDocumentItem = DocumentItemKindV2.getNewDocumentItem(itemKind, {
    isFullyEnabledTimesheetsV2: organization.isFullyEnabledTimesheetsV2,
  });
  const documentItemsField = DocumentContentJson.getDocumentItemsField({
    sectionIndex: selectedDocumentItem.sectionIndex,
    columnIndex,
  });
  const documentItems = _.get(draftDocumentContentJson, documentItemsField);
  const newDocumentItemIndex = documentItems.length;
  setSelectedDocumentItem({
    ...selectedDocumentItem,
    documentItem: newDocumentItem,
    index: newDocumentItemIndex,
    columnIndex,
  });
  setDraftDocumentContentJson((prevState) => {
    _.set(prevState, documentItemsField, [...documentItems, newDocumentItem]);
    return {...prevState};
  });
};

const getMenuActions = ({
  organization,
  documentTemplateCategory,
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  selectedDocumentItem,
  setSelectedDocumentItem,
  columnIndex,
}: {
  organization: OrganizationModel;
  documentTemplateCategory: DocumentTemplateCategoryType;
  draftDocumentContentJson: DocumentContentJsonType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  selectedDocumentItem: SelectedDocumentItemType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  columnIndex?: number;
}) => {
  const {isEnabledGenericSignatureDocumentItem} = organization.features;
  const {isEquipmentAndMaterialsEnabled} = organization.settings;
  const dropdownSections = DocumentItemKindV2.getDropdownSections({
    documentTemplateCategory,
    isEnabledGenericSignatureDocumentItem,
    isEquipmentAndMaterialsEnabled,
  });
  const isColumn = !_.isNil(columnIndex);

  return dropdownSections.map((section) => {
    return {
      label: section.name,
      actions: section.kinds.map((itemKind) => {
        const isDisabledForColumn = isColumn && DocumentItemKindV2.getIsDisabledForColumn(itemKind);
        return {
          text: DocumentItemKindV2.getItemDisplayName({
            itemKind,
            isFullyEnabledTimesheetsV2: organization.isFullyEnabledTimesheetsV2,
          }),
          isDisabled: isDisabledForColumn,
          tooltip: isDisabledForColumn ? 'This item cannot be added to a column.' : undefined,
          description: DocumentItemKindV2.getDescription(itemKind),
          onPress: () =>
            handleAddDocumentItem({
              itemKind,
              organization,
              draftDocumentContentJson,
              setDraftDocumentContentJson,
              selectedDocumentItem,
              setSelectedDocumentItem,
              columnIndex,
            }),
        };
      }),
    };
  });
};

const AddDocumentItemButton = ({
  organization,
  setDraftDocumentContentJson,
  draftDocumentContentJson,
  setSelectedDocumentItem,
  selectedDocumentItem,
  documentTemplateCategory,
  columnIndex,
}: {
  organization: OrganizationModel;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  draftDocumentContentJson: DocumentContentJsonType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  selectedDocumentItem: SelectedDocumentItemType;
  documentTemplateCategory: DocumentTemplateCategoryType;
  columnIndex?: number;
}) => {
  const addDocumentItemPopover = usePopover();
  return (
    <ActionMenuPopover
      popover={addDocumentItemPopover}
      width={400}
      popoverHeight={480}
      isSearchable
      actions={getMenuActions({
        organization,
        documentTemplateCategory,
        draftDocumentContentJson,
        setDraftDocumentContentJson,
        selectedDocumentItem,
        setSelectedDocumentItem,
        columnIndex,
      })}
    >
      <TertiaryButton
        text={'Add Item'}
        iconLeft={Icon.Plus}
        iconRight={Icon.ChevronDown}
        onPress={addDocumentItemPopover.handleToggle}
        style={{paddingVertical: 8}}
        isResponsive
      />
    </ActionMenuPopover>
  );
};

AddDocumentItemButton.fragment = gql`
  fragment AddDocumentItemButton on Organization {
    id
    isFullyEnabledTimesheetsV2
    features {
      isEnabledGenericSignatureDocumentItem: isEnabled(feature: "GENERIC_SIGNATURE_DOCUMENT_ITEM")
    }
    settings {
      id
      isEquipmentAndMaterialsEnabled
    }
  }
`;

export default AddDocumentItemButton;
