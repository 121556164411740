// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {OrganizationModel, PaymentMethod} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import PaymentMethodForm, {
  PaymentMethodFormType,
  PaymentMethodFormToFormType,
} from '@shared/modules/PaymentMethod/forms/PaymentMethodForm';

export interface UpdateOfficePaymentMethodsFormType {
  organizationId: string | undefined;
  paymentMethodForms: PaymentMethodFormType[];
}

interface UpdateOfficePaymentMethodsFormToFormType {
  organizationId: string | undefined;
  paymentMethodForms: PaymentMethodFormToFormType[];
}

const edit = withFragment(
  (organization: OrganizationModel): UpdateOfficePaymentMethodsFormType => {
    const paymentMethodForms = PaymentMethod.getSortedPaymentMethodsBySection(
      organization.paymentMethods.filter(
        (paymentMethod) =>
          !_.includes(PaymentMethodKind.getHardcodedCrewMethods(), paymentMethod.kind),
      ),
    ).flatMap(({label, paymentMethods}) =>
      paymentMethods.map((paymentMethod) =>
        PaymentMethodForm.edit(paymentMethod, {sectionLabel: label}),
      ),
    );
    return {
      organizationId: organization.id,
      paymentMethodForms,
    };
  },
  gql`
    ${PaymentMethod.getSortedPaymentMethodsBySection.fragment}
    ${PaymentMethodForm.edit.fragment}
    fragment UpdateOfficePaymentMethodsForm_edit on Organization {
      id
      paymentMethods {
        id
        ...PaymentMethodForm_edit
        ...PaymentMethod_getSortedPaymentMethodsBySection
      }
    }
  `,
);

const toForm = ({organizationId, paymentMethodForms}: UpdateOfficePaymentMethodsFormType) => {
  return {
    organizationId,
    paymentMethodForms: paymentMethodForms.map((paymentMethodForm: PaymentMethodFormType) =>
      PaymentMethodForm.toForm(paymentMethodForm),
    ),
  };
};

const toMutation = ({
  organizationId,
  paymentMethodForms,
}: UpdateOfficePaymentMethodsFormToFormType) => {
  return {
    organizationId,
    paymentMethodForms: paymentMethodForms.map((paymentMethodForm: PaymentMethodFormToFormType) =>
      PaymentMethodForm.toMutation(paymentMethodForm),
    ),
  };
};

const UpdateOfficePaymentMethodsForm = {
  edit,
  toForm,
  toMutation,
};

export default UpdateOfficePaymentMethodsForm;
