// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import ProjectClientContactName from 'modules/Project/V2/Show/components/ProjectClientContactName';
import ProjectClientEmail from 'modules/Project/V2/Show/components/ProjectClientEmail';
import ProjectClientPhoneNumber from 'modules/Project/V2/Show/components/ProjectClientPhoneNumber';

const Column = Styled.View`
`;

const ProjectHeaderClientInformation = ({project, urlFilters}) => {
  return (
    <Column>
      <ProjectClientContactName project={project} isMicro />
      <Space height={8} />
      <ProjectClientEmail project={project} isMicro />
      <Space height={8} />
      <ProjectClientPhoneNumber project={project} urlFilters={urlFilters} isMicro />
    </Column>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectHeaderClientInformation.fragment = gql`
  ${ProjectClientContactName.fragment}
  ${ProjectClientEmail.fragment}
  ${ProjectClientPhoneNumber.fragment}
  fragment ProjectHeaderClientInformation on Project {
    id
    ...ProjectClientContactName
    ...ProjectClientEmail
    ...ProjectClientPhoneNumber
  }
`;

export default ProjectHeaderClientInformation;
