// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

// App
import TimesheetBlockForm from '@shared/modules/Timesheet/forms/TimesheetBlockForm';

const edit = withFragment(
  (timesheetBlock, {isFixedRangeFrom, isFixedRangeTo} = {}) => ({
    timesheetBlockId: (timesheetBlock as any).id,
    kind: (timesheetBlock as any).kind,
    rangeFrom: (timesheetBlock as any).rangeFrom,
    rangeTo: (timesheetBlock as any).rangeTo,
    // Private
    isFixedRangeFrom,
    isFixedRangeTo,
    rangeFromDate: Datetime.convertToDate((timesheetBlock as any).rangeFrom),
    rangeToDate: Datetime.convertToDate((timesheetBlock as any).rangeTo),
  }),
  gql`
    fragment UpdateTimesheetBlockForm_edit on TimesheetBlock {
      id
      kind
      rangeFrom
      rangeTo
    }
  `,
);

const toForm = ({
  timesheetBlockId,
  kind,
  rangeFrom,
  rangeTo,
  isFixedRangeFrom,
  isFixedRangeTo,
  rangeFromDate,
  rangeToDate,
}: any) => ({
  timesheetBlockId,
  kind,
  rangeFrom: TimesheetBlockForm.getFormRangeTime({datetime: rangeFrom}),
  rangeTo: TimesheetBlockForm.getFormRangeTime({datetime: rangeTo}),

  // Private
  isFixedRangeFrom: !!isFixedRangeFrom,
  isFixedRangeTo: !!isFixedRangeTo,
  rangeFromDate,
  rangeToDate,
});

const toMutation = ({
  timesheetBlockId,
  kind,
  rangeFrom,
  rangeTo,

  // Private
  isFixedRangeTo,

  rangeFromDate,
  rangeToDate,
}: any) => {
  return {
    timesheetBlockId,
    kind,
    rangeFrom: TimesheetBlockForm.getMutationRangeFrom({date: rangeFromDate, rangeFrom}),
    rangeTo: TimesheetBlockForm.getMutationRangeTo({
      date: isFixedRangeTo ? rangeToDate : rangeFromDate,
      rangeFrom,
      rangeTo,
      isFixedRangeTo,
    }),
  };
};

const UpdateTimesheetBlockForm = {
  edit,
  toForm,
  toMutation,
};

export default UpdateTimesheetBlockForm;
