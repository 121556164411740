// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import TipOptionForm from '@shared/modules/Organization/forms/TipOptionForm';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    tipOptionForms: JSON.parse((organization as any).settings.tipOptions).map((tipOption: any) =>
      TipOptionForm.new(tipOption),
    ),
  }),
  gql`
    fragment OrganizationTipOptionsForm_edit on Organization {
      id
      settings {
        id
        tipOptions
      }
    }
  `,
);

const toForm = ({organizationId, tipOptionForms}: any) => ({
  organizationId,
  tipOptionForms: tipOptionForms.map((tipOptionForm: any) => TipOptionForm.toForm(tipOptionForm)),
});

const toMutation = ({organizationId, tipOptionForms}: any) => ({
  organizationId,
  tipOptionForms: tipOptionForms.map((tipOptionForm: any) =>
    TipOptionForm.toMutation(tipOptionForm),
  ),
});

const OrganizationTipOptionsForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationTipOptionsForm;
