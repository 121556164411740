// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import SurveyMethodKind from '@shared/modules/Inventory/enums/SurveyMethodKind';
import DefaultProjectTypeInventorySettingsForm from '@shared/modules/Inventory/forms/DefaultProjectTypeInventorySettingsForm';

const _new = ({organizationId}: any) => ({
  organizationId,
  surveyMethodId: null,
  name: '',
  // its only possible to create CUSTOM survey methods from the FE
  kind: SurveyMethodKind.CUSTOM,
  defaultProjectTypeInventorySettingsForms: [],
  newDefaultSurveyMethodId: null,
});

const edit = withFragment(
  (surveyMethod) => ({
    organizationId: (surveyMethod as any).organizationId,
    surveyMethodId: (surveyMethod as any).id,
    name: (surveyMethod as any).name,
    kind: (surveyMethod as any).kind,
    defaultProjectTypeInventorySettingsForms: (surveyMethod as any).projectTypes.map(
      (
        projectType: any, // new instead of edit since we want to force the user to pick new survey methods on delete
      ) =>
        DefaultProjectTypeInventorySettingsForm.new({
          projectTypeId: projectType.id,
          projectTypeName: projectType.name,
          projectTypeColor: projectType.color,
        }),
    ),
    newDefaultSurveyMethodId: null,
  }),
  gql`
    fragment SurveyMethodForm_edit on SurveyMethod {
      id
      organizationId
      name
      kind
      projectTypes {
        id
        name
        color
      }
    }
  `,
);

const toForm = ({
  surveyMethodId,
  organizationId,
  name,
  kind,
  defaultProjectTypeInventorySettingsForms,
  newDefaultSurveyMethodId,
}: any) => ({
  surveyMethodId,
  organizationId,
  name,
  kind,
  defaultProjectTypeInventorySettingsForms: defaultProjectTypeInventorySettingsForms.map(
    (defaultProjectTypeInventorySettingsForms: any) =>
      DefaultProjectTypeInventorySettingsForm.toForm(defaultProjectTypeInventorySettingsForms),
  ),
  newDefaultSurveyMethodId,
});

const toMutation = ({
  surveyMethodId,
  organizationId,
  name,
  kind,
  defaultProjectTypeInventorySettingsForms,
  newDefaultSurveyMethodId,
}: any) => ({
  surveyMethodId,
  organizationId,
  name,
  kind,
  defaultProjectTypeInventorySettingsForms: defaultProjectTypeInventorySettingsForms.map(
    (defaultProjectTypeInventorySettingsForms: any) =>
      DefaultProjectTypeInventorySettingsForm.toMutation(defaultProjectTypeInventorySettingsForms),
  ),
  newDefaultSurveyMethodId,
});

const SurveyMethodForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default SurveyMethodForm;
