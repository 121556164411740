// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

import ProjectDateKind from '@shared/modules/Project/enums/ProjectDateKind';
import ShipmentsTableDatePickerPopover from 'modules/Dispatch/Shipment/components/ShipmentsTableDatePickerPopover';

const Container = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const EmptyText = Styled.Text`
  ${Typography.Body};
  color: ${colors.gray.secondary}
`;

const ButtonTextContainer = Styled.View`
`;

const ButtonContainer = Styled.View`
  padding: 2px;
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid ${({showBorder}) => (showBorder ? `${colors.gray.border}` : 'white')}
`;

const ButtonScheduledDateText = Styled.Text`
  ${Typography.Body}
  color: ${({color}) => color};
`;

const ButtonDateRangeText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.secondary};
`;

const getDisplayValue = ({startDate, endDate}) => {
  if (startDate && endDate) {
    if (startDate === endDate) {
      return Datetime.convertToDisplayDate(startDate, Datetime.DISPLAY_SHORT_DATE);
    }

    const displayStartDate = Datetime.convertToDisplayDate(startDate, Datetime.DISPLAY_MONTH_DAY);
    const displayEndDate = Datetime.convertToDisplayDate(endDate, Datetime.DISPLAY_SHORT_DATE);
    return `${displayStartDate} - ${displayEndDate}`;
  } else if (startDate) {
    return Datetime.convertToDisplayDate(startDate, Datetime.DISPLAY_SHORT_DATE);
  }
  return '';
};

const ScheduledDateText = ({scheduledDate, isScheduledDateInsideDateRange}) => {
  if (!scheduledDate) {
    return (
      <ButtonScheduledDateText color={colors.gray.tertiary}>
        MM/DD/YY
        <Icon
          source={Icon.Calendar}
          color={colors.gray.tertiary}
          size={12}
          style={{marginLeft: 4}}
        />
      </ButtonScheduledDateText>
    );
  }

  const scheduledDisplayDate = Datetime.convertToDisplayDate(
    scheduledDate,
    Datetime.DISPLAY_SHORT_DATE,
  );

  if (!isScheduledDateInsideDateRange) {
    return (
      <ButtonScheduledDateText color={colors.gray.primary}>
        {scheduledDisplayDate}
        <Icon
          source={Icon.ExclamationTriangle}
          color={colors.orange.status}
          size={12}
          style={{marginLeft: 4}}
        />
      </ButtonScheduledDateText>
    );
  }

  return (
    <ButtonScheduledDateText color={colors.gray.primary}>
      {scheduledDisplayDate}
    </ButtonScheduledDateText>
  );
};

const ShipmentsTableDatePicker = ({project, projectDateKind, excludeDates}) => {
  const {ref, isHovered} = useHover();
  const dateRangePickerPopover = usePopover();
  const showBorder = isHovered;
  const jobUuid = project[ProjectDateKind.PROJECT_DATE_KIND_TO_JOB_UUID_MAP[projectDateKind]];
  const startDate = project[ProjectDateKind.PROJECT_DATE_KIND_TO_START_DATE_MAP[projectDateKind]];
  const endDate = project[ProjectDateKind.PROJECT_DATE_KIND_TO_END_DATE_MAP[projectDateKind]];
  const scheduledDate =
    project[ProjectDateKind.PROJECT_DATE_KIND_TO_SCHEDULED_DATE_MAP[projectDateKind]];

  // If there's no job linked for this date, then don't show the datepicker
  if (!jobUuid) {
    return <EmptyText>No Job</EmptyText>;
  }

  return (
    <Container index={0} ref={ref}>
      <Popover.Content innerRef={dateRangePickerPopover.ref}>
        <Button
          color={colors.white}
          onPress={dateRangePickerPopover.handleOpen}
          showBorder={showBorder}
        >
          <ButtonContainer>
            <ButtonTextContainer>
              <ScheduledDateText
                scheduledDate={scheduledDate}
                isScheduledDateInsideDateRange={Datetime.getIsDateInsideOfDateRange({
                  date: scheduledDate,
                  startDate,
                  endDate,
                })}
              />
              <ButtonDateRangeText color={colors.gray.secondary}>
                {getDisplayValue({startDate, endDate})}
              </ButtonDateRangeText>
            </ButtonTextContainer>
          </ButtonContainer>
        </Button>
        <ShipmentsTableDatePickerPopover
          popover={dateRangePickerPopover}
          project={project}
          projectDateKind={projectDateKind}
          excludeDates={excludeDates}
        />
      </Popover.Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShipmentsTableDatePicker.fragment = gql`
  ${ShipmentsTableDatePickerPopover.fragment}
  fragment ShipmentsTableDatePicker on Project {
    id
    packJobUuid
    packJobStartDate
    packJobEndDate
    packJobScheduledDate
    loadJobUuid
    loadJobStartDate
    loadJobEndDate
    loadJobScheduledDate
    deliveryJobUuid
    deliveryJobStartDate
    deliveryJobEndDate
    deliveryJobScheduledDate
    ...ShipmentsTableDatePickerPopover
  }
`;

export default ShipmentsTableDatePicker;
