// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectTypeBillingSettingsForm from '@shared/modules/Project/forms/ProjectTypeBillingSettingsForm';

const useUpdateProjectTypePaymentSettingsMutation = ({
  projectTypeBillingSettingsForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      projectTypeBillingSettingsForm: ProjectTypeBillingSettingsForm.toForm(
        projectTypeBillingSettingsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectTypePaymentSettingsMutation.mutation,
    variables: {
      projectTypeBillingSettingsForm: ProjectTypeBillingSettingsForm.toMutation(
        form.values.projectTypeBillingSettingsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateProjectTypePaymentSettingsMutation.mutation = gql`
  mutation useUpdateProjectTypePaymentSettingsMutation($projectTypeBillingSettingsForm: ProjectTypeBillingSettingsForm!){
    response: updateProjectTypePaymentSettings(projectTypeBillingSettingsForm: $projectTypeBillingSettingsForm) {
      ${gql.errors}
      projectType{
        id
      }
    }
  }
`;

export default useUpdateProjectTypePaymentSettingsMutation;
