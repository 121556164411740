// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {ClaimType} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';

const ClaimTypeDropdown = ({form, field, index, organization}) => {
  return (
    <FieldInput
      {...form}
      label={'Claim Type'}
      name={`${field}.claimTypeId`}
      component={DropdownInput}
      index={index}
      isRequired
      isResponsive
      input={{
        autoFocus: true,
        options: ClaimType.sortClaimTypes(organization.companySettings.claimTypes).map(
          (claimType) => ({
            value: claimType.id,
            label: claimType.name,
          }),
        ),
        placeholder: 'Select claim type',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClaimTypeDropdown.fragment = gql`
  ${ClaimType.sortClaimTypes.fragment}
  fragment ClaimTypeDropdown on Organization {
    id
    companySettings {
      claimTypes {
        id
        name
        ...ClaimType_sortClaimTypes
      }
    }
  }
`;

export default ClaimTypeDropdown;
