/* eslint-disable no-case-declarations */
// Libraries
import _ from 'lodash';

// Supermove
import {useReducer} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import ProjectStatus from '@shared/modules/Project/enums/ProjectStatus';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';

const SET_STATUS = 'SET_STATUS';
const SET_HAS_EMAIL_SEND_ERROR = 'SET_HAS_EMAIL_SEND_ERROR';
const SET_PROJECT_TYPE_CATEGORIES = 'SET_PROJECT_TYPE_CATEGORIES';
const SET_PROJECT_TYPES = 'SET_PROJECT_TYPES';
const SET_PROJECT_TAGS = 'SET_PROJECT_TAGS';
const SET_DATE_FROM = 'SET_DATE_FROM';
const SET_DATE_TO = 'SET_DATE_TO';
const SET_DUE_DATE_FROM = 'SET_DUE_DATE_FROM';
const SET_DUE_DATE_TO = 'SET_DUE_DATE_TO';
const SET_INVOICE_PAYMENTS = 'SET_INVOICE_PAYMENTS';
const SET_EXPORT_STATUSES = 'SET_EXPORT_STATUSES';
const SET_PROJECT_STATUSES = 'SET_PROJECT_STATUSES';
const SET_IS_TRAINING = 'SET_IS_TRAINING';
const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
const SET_PAGINATION = 'SET_PAGINATION';
const SET_SLUGS = 'SET_SLUGS';
const RESET_FILTERS = 'RESET_FILTERS';

const reducer = (state: any, action: any, visibleCalendarStatuses: any) => {
  // We reset to page 1 on any filter change
  const stateWithPaginationReset = {...state, pagination: PaginationBar.DEFAULT_PAGINATION};

  switch (action.type) {
    case SET_SLUGS:
      return {...stateWithPaginationReset, slugs: action.payload};
    case SET_STATUS:
      return {
        ...stateWithPaginationReset,
        statuses: [action.payload],
        hasEmailSendError: null,
      };
    case SET_HAS_EMAIL_SEND_ERROR:
      return {
        ...stateWithPaginationReset,
        statuses: InvoiceStatus.ALL_STATUSES,
        hasEmailSendError: action.payload,
      };
    case SET_PROJECT_TYPE_CATEGORIES:
      return {...stateWithPaginationReset, projectTypeCategories: action.payload};
    case SET_PROJECT_TYPES:
      return {...stateWithPaginationReset, projectTypeIds: action.payload};
    case SET_PROJECT_TAGS:
      return {...stateWithPaginationReset, projectTagIds: action.payload};
    case SET_DATE_FROM:
      return {...stateWithPaginationReset, dateFrom: handleUpdateDate(action.payload)};
    case SET_DATE_TO:
      return {...stateWithPaginationReset, dateTo: handleUpdateDate(action.payload)};
    case SET_DUE_DATE_FROM:
      return {...stateWithPaginationReset, dueDateFrom: handleUpdateDate(action.payload)};
    case SET_DUE_DATE_TO:
      return {...stateWithPaginationReset, dueDateTo: handleUpdateDate(action.payload)};
    case SET_INVOICE_PAYMENTS:
      return {...stateWithPaginationReset, invoicePayments: action.payload};
    case SET_EXPORT_STATUSES:
      return {...stateWithPaginationReset, exportStatuses: action.payload};
    case SET_PROJECT_STATUSES:
      return {...stateWithPaginationReset, projectStatuses: action.payload};
    case SET_IS_TRAINING:
      return {...stateWithPaginationReset, isTraining: action.payload};
    case SET_SEARCH_QUERY:
      return {...stateWithPaginationReset, searchQuery: action.payload};
    case SET_PAGINATION:
      return {
        ...stateWithPaginationReset,
        pagination: {
          page: action.payload,
          resultsPerPage: PaginationBar.DEFAULT_PAGINATION.resultsPerPage,
        },
      };
    case RESET_FILTERS:
      const initialFiltersForOrganization = getDefaultFiltersForOrganization(
        visibleCalendarStatuses,
        state.projectTypeCategories,
      );
      // NOTE(cooper): This only resets filters in the popover
      return {
        ...stateWithPaginationReset,
        projectTypeIds: null,
        projectTagIds: null,
        dateFrom: '',
        dateTo: '',
        dueDateFrom: '',
        dueDateTo: '',
        invoicePayments: null,
        exportStatuses: null,
        projectStatuses: initialFiltersForOrganization.projectStatuses,
        isTraining: initialFiltersForOrganization.isTraining,
      };
    default:
      return stateWithPaginationReset;
  }
};

const handleUpdateDate = (date: any) => {
  return date ? Datetime.convertToDate(date) : '';
};

export const getDefaultFiltersForOrganization = (
  visibleCalendarStatuses: any,
  projectTypeCategories: any,
) => {
  if (_.isEqual(projectTypeCategories, [ProjectTypeCategory.STORAGE])) {
    return {
      isTraining: null,
      projectStatuses: null,
    };
  }
  const moveProjectStatuses = [...visibleCalendarStatuses, ProjectStatus.COMPLETED];
  return {
    isTraining: false,
    projectStatuses: moveProjectStatuses,
  };
};

const useFilteredInvoicesReducer = (initialFilters: any, visibleCalendarStatuses: any) => {
  const initialFiltersForOrganization = getDefaultFiltersForOrganization(
    visibleCalendarStatuses,
    initialFilters.projectTypeCategories,
  );
  const [filters, dispatch] = useReducer(
    (state: any, action: any) => reducer(state, action, visibleCalendarStatuses),
    {
      ...initialFiltersForOrganization,
      ...initialFilters,
    },
  );

  return {
    filters,
    dispatch,
    actions: {
      SET_SLUGS,
      SET_STATUS,
      SET_HAS_EMAIL_SEND_ERROR,
      SET_PROJECT_TYPE_CATEGORIES,
      SET_PROJECT_TYPES,
      SET_PROJECT_TAGS,
      SET_DATE_FROM,
      SET_DATE_TO,
      SET_DUE_DATE_FROM,
      SET_DUE_DATE_TO,
      SET_INVOICE_PAYMENTS,
      SET_EXPORT_STATUSES,
      SET_PROJECT_STATUSES,
      SET_IS_TRAINING,
      SET_SEARCH_QUERY,
      SET_PAGINATION,
      RESET_FILTERS,
    },
  };
};

export default useFilteredInvoicesReducer;
