// Libraries
import React from 'react';

// Supermove
import {Space, Styled, Icon, ScrollView} from '@supermove/components';
import {ResponsiveType, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import IconButton from '@shared/design/components/IconButton';

const Container = Styled.View`
  flex: 1;
`;

const Header = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 16)}px;
  padding-vertical: 16px;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
  flex-direction: row;
  background-color: ${colors.white};
  align-items: center;
`;

const Body = Styled.View`
  width: 100%;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const BillTypePage = ({
  handleSubmit,
  handleClose,
  title,
  children,
}: {
  handleSubmit: () => void;
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();
  return (
    <Container>
      <Header responsive={responsive}>
        <IconButton source={Icon.Xmark} size={20} isSecondary onPress={handleClose} />
        <Space width={16} />
        <HeaderText responsive={responsive}>{title}</HeaderText>
        <Space flex={1} />
        <Button text={'Save'} iconLeft={Icon.Check} onPress={handleSubmit} />
      </Header>
      <ScrollView
        style={{flex: 1, backgroundColor: colors.gray.background}}
        contentContainerStyle={{
          alignItems: 'center',
          paddingHorizontal: responsive.desktop ? 24 : 0,
        }}
      >
        <Body style={{maxWidth: responsive.desktop ? 800 : 'none'}}>
          <Space height={24} />
          {children}
          <Space height={48} />
        </Body>
      </ScrollView>
    </Container>
  );
};

export default BillTypePage;
