// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import useCompleteCustomerTaskForm from 'modules/Customer/Job/Quote/logic/useCompleteCustomerTaskForm';
import InventoryDocument from 'modules/Customer/Project/Quote/components/InventoryDocument';
import DocumentHeaderOrganization from 'modules/Document/components/DocumentHeaderOrganization';
import PreviewDocument from 'modules/Document/components/PreviewDocument';

const Container = Styled.View`
  padding-bottom: 90px;
`;

const Section = Styled.View`
`;

const SectionSpace = Styled.View`
  margin-top: 30px;
`;

const Button = Styled.StatefulButton`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonContent = ({form}) => {
  if (form.loading) {
    return null;
  }
  return form.values.isFinished ? (
    <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.Check} />
  ) : (
    <Text>Continue</Text>
  );
};

const InventoryQuoteForm = ({confirmation, onError}) => {
  const {form, submitting, handleSubmit} = useCompleteCustomerTaskForm({
    customerTask: confirmation,
    stepKind: ConfirmationStepKind.QUOTE_STEP_ACCEPT_INVENTORY,
    onSuccess: () => form.setFieldValue('isFinished', true),
    onError: (errors) => console.log({errors}),
  });

  const {navigator} = useNavigationDOM();

  return (
    <Container>
      <Section>
        <PreviewDocument canFullscreen={false} title={'Itemized Inventory'} style={{marginTop: 10}}>
          <InventoryDocument project={confirmation.project} />
        </PreviewDocument>
      </Section>
      <SectionSpace />
      <Section>
        <Button
          loading={submitting}
          onPress={handleSubmit}
          onSuccess={() =>
            navigator.push(
              `/0/${confirmation.project.organization.slug}/projects/${confirmation.project.uuid}/quote`,
            )
          }
          success={form.values.isFinished}
        >
          <ButtonContent form={form} />
        </Button>
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryQuoteForm.fragment = gql`
  ${DocumentHeaderOrganization.fragment}
  ${InventoryDocument.fragment}

  fragment InventoryQuoteForm on Confirmation {
    id
    project {
      id
      uuid
      organization {
        id
        slug
      }
      ...InventoryDocument
    }
  }
`;

export default InventoryQuoteForm;
