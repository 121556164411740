// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

const SYNCED = 'SYNCED';
const FAILED = 'FAILED';
const LINKED = 'LINKED';
const PENDING = 'PENDING';
const UNSET = 'UNSET';
const PARTIAL = 'PARTIAL';

const PENDING_OPTION = {
  value: 'PENDING',
  label: 'Pending',
  color: colors.gray.primary,
  icon: Icon.Clock,
};

const SYNCED_OPTION = {
  value: 'SYNCED',
  label: 'Synced',
  color: colors.green.status,
  icon: Icon.CheckCircle,
};

const FAILED_OPTION = {
  value: 'FAILED',
  label: 'Failed',
  color: colors.red.warning,
  icon: Icon.TimesCircle,
};

const LINKED_OPTION = {
  value: 'LINKED',
  label: 'Linked',
  color: colors.green.status,
  icon: Icon.CheckCircle,
};

const UNSET_OPTION = {
  value: 'LINKED',
  label: 'Linked',
  color: null,
  icon: null,
};

const PARTIAL_OPTION = {
  value: 'PARTIAL',
  label: 'Partial',
  color: colors.yellow.status,
  icon: Icon.ExclamationTriangle,
};

const OPTIONS = [
  SYNCED_OPTION,
  FAILED_OPTION,
  LINKED_OPTION,
  PENDING_OPTION,
  UNSET_OPTION,
  PARTIAL_OPTION,
];

const STATUS_OPTION_MAP = {
  [SYNCED]: SYNCED_OPTION,
  [FAILED]: FAILED_OPTION,
  [LINKED]: LINKED_OPTION,
  [PARTIAL]: PARTIAL_OPTION,
  [PENDING]: PENDING_OPTION,
  [UNSET]: UNSET_OPTION,
};

const getStatusOption = (status: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return STATUS_OPTION_MAP[status];
};

const ExportSyncStatus = {
  OPTIONS,

  SYNCED,
  FAILED,
  LINKED,
  PENDING,
  UNSET,
  getStatusOption,
};

export default ExportSyncStatus;
