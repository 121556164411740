// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import useMarkTextMessagesReadMutation from '@shared/modules/Sms/hooks/useMarkTextMessagesReadMutation';
import StaffCommunicationDrawer from 'modules/Communication/components/StaffCommunicationDrawer';
import UpdateUserDrawer from 'modules/User/components/UpdateUserDrawer';

const NotificationContainer = Styled.View`
  position: relative;
`;

const Notification = Styled.View`
  position: absolute;
  right: -4px;
  top: -4px;
  height: 100%;
  width: 8px;
  height: 8px;
  border-radius: 16px;
  border-width: 6px;
  border-color: ${colors.red.warning};
`;

const StaffCommunicationButton = ({
  user,
  viewerRole,
  refetch,
  isDisabled,
  isEnabledOperationsAdmins,
  icon,
  iconSize,
  iconColor,
}) => {
  const staffCommunicationDrawer = useDrawer({name: 'Staff Communication Drawer'});
  const updateUserDrawer = useDrawer({name: 'Update User Drawer'});

  const {handleSubmit: handleSubmitMarkTextMessagesRead} = useMarkTextMessagesReadMutation({
    userId: user.id,
    onSuccess: () => {
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <React.Fragment>
      <NotificationContainer>
        <TertiaryButton
          iconLeft={icon || Icon.CommentDots}
          isDisabled={isDisabled}
          iconSize={iconSize || 22}
          textColor={iconColor}
          onPress={() => {
            handleSubmitMarkTextMessagesRead();
            staffCommunicationDrawer.handleOpen();
          }}
        />
        {user.hasUnreadText && <Notification />}
      </NotificationContainer>
      <StaffCommunicationDrawer
        isOpen={staffCommunicationDrawer.isOpen}
        handleClose={staffCommunicationDrawer.handleClose}
        user={user}
        handleMissingContactInfo={updateUserDrawer.handleOpen}
      />
      <UpdateUserDrawer
        key={updateUserDrawer.key}
        user={user}
        isOpen={updateUserDrawer.isOpen}
        handleClose={updateUserDrawer.handleClose}
        handleSuccess={() => {
          updateUserDrawer.handleClose();
          refetch();
        }}
        viewerRole={viewerRole}
        isEnabledOperationsAdmins={isEnabledOperationsAdmins}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StaffCommunicationButton.fragment = gql`
  ${StaffCommunicationDrawer.fragment}
  ${UpdateUserDrawer.fragment}

  fragment StaffCommunicationButton on User {
    id
    hasUnreadText
    ...StaffCommunicationDrawer
    ...UpdateUserDrawer
  }
`;

export default StaffCommunicationButton;
