// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SendTextMessageToTeamForm from '@shared/modules/Sms/forms/SendTextMessageToTeamForm';

const useSendTextMessageToTeamMutation = ({sendTextMessageToTeamForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      sendTextMessageToTeamForm: SendTextMessageToTeamForm.toForm(sendTextMessageToTeamForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSendTextMessageToTeamMutation.mutation,
    variables: {
      sendTextMessageToTeamForm: SendTextMessageToTeamForm.toMutation(
        form.values.sendTextMessageToTeamForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useSendTextMessageToTeamMutation.mutation = gql`
  mutation useSendTextMessageToTeamMutation($sendTextMessageToTeamForm: SendTextMessageToTeamForm!) {
    response: sendTextMessageToTeam(sendTextMessageToTeamForm: $sendTextMessageToTeamForm) {
      ${gql.errors}
    }
  }
`;

export default useSendTextMessageToTeamMutation;
