// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {Job, Location} from '@supermove/models';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {Distance, Location as LocationUtils} from '@supermove/utils';

// App
import ViewLocationButtons from '@shared/modules/Location/components/ViewLocationButtons';
import {FieldValue} from 'modules/App/components';

import JobMap from './JobMap';

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index}px;
`;

const Subsection = Styled.View`
`;

const SubsectionSpace = Styled.View`
  margin-top: 20px;
`;

const Dot = Styled.View`
  position: absolute;
  top: 6px;
  left: -5px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${colors.blue.accentDark};
`;

const Bar = Styled.View`
  position: absolute;
  top: 6px;
  left: 0px;
  width: 1px;
  height: 100%;
  background-color: ${colors.blue.accentDark};
`;

const TitleAndStatus = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = Styled.Text`
  ${Typography.Heading1}
`;

const Subtitle = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  margin-bottom: 5px;
`;

const Status = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
`;

const Text = Styled.H7`
  margin-left: 10px;
  color: ${colors.gray.secondary};
`;

const LocationTitle = Styled.H7`
  margin-bottom: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  z-index: ${({index = 0}) => 100 - index};
`;

const MapWrapper = Styled.View`
  align-self: stretch;
  height: 300px;
`;

const RowSpace = Styled.View`
  margin-top: 10px;
`;

const FieldSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 0)}px;
  margin-left: ${(props) => (props.mobile ? 0 : 10)}px;
`;

const NOT_APPLICABLE_STRING = 'n/a';

const MapButtons = ({location, map}) => {
  return (
    <ViewLocationButtons
      location={location}
      map={map}
      rowStyle={{alignItems: 'flex-start', paddingTop: 4}}
    />
  );
};

const LocationSection = ({isLast, index, count, location, responsive, map}) => (
  <Subsection
    index={index}
    style={
      isLast
        ? {
            paddingLeft: 25,
            marginLeft: 6,
          }
        : {
            paddingLeft: 25,
            marginLeft: 6,
          }
    }
  >
    <Dot />
    {!isLast && <Bar />}
    <LocationTitle>{Location.getDisplayName(location, {count})}</LocationTitle>
    <Row index={0} {...responsive} style={{alignItems: 'center'}}>
      <FieldValue
        newTab
        link={LocationUtils.createGoogleMapsUrl(location.address)}
        value={Location.getDisplayLocation(location)}
      />
      <Space width={8} height={8} />
      <MapButtons location={location} map={map} />
    </Row>
    <RowSpace {...responsive} />
    <Row index={1} {...responsive}>
      <FieldValue
        label={'Building Type'}
        style={{
          width: 120,
        }}
        empty={NOT_APPLICABLE_STRING}
        value={location.buildingType}
      />
      <FieldSpace {...responsive} />
      <FieldValue
        label={'Floor #'}
        style={{
          width: 120,
        }}
        empty={NOT_APPLICABLE_STRING}
        value={location.floorNumber}
      />
      <FieldSpace {...responsive} />
      {location.organization.features.isEnabledProjectPageV2 ? (
        <FieldValue
          label={'Flights of Stairs'}
          style={{
            width: 120,
          }}
          empty={NOT_APPLICABLE_STRING}
          value={location.stairCount}
        />
      ) : (
        <FieldValue
          label={'# of Stairs'}
          style={{
            width: 120,
          }}
          empty={NOT_APPLICABLE_STRING}
          value={location.stairDescription}
        />
      )}
      <FieldSpace {...responsive} />
      <FieldValue
        label={'Elevator?'}
        value={location.hasElevator ? 'Yes' : 'No'}
        style={{
          width: 120,
        }}
      />
      <FieldSpace {...responsive} />
      <FieldValue
        label={'Long Walk?'}
        value={location.hasLongWalk ? 'Yes' : 'No'}
        style={{
          width: 120,
        }}
      />
    </Row>
    <RowSpace {...responsive} />
    <Row index={2} {...responsive}>
      <FieldValue
        label={Job.FIELDS.LOCATION_NOTES}
        style={{
          width: responsive.mobile ? '100%' : 510,
        }}
        empty={'None'}
        value={location.notes}
      />
    </Row>
    {!isLast && <SubsectionSpace />}
  </Subsection>
);

const LocationStatus = ({totalDistance, locations}) => {
  return (
    <Status>
      <Text>
        {locations.length > 1
          ? `${locations.length} stops (${Distance.display(totalDistance)} total)`
          : `1 stop`}
      </Text>
    </Status>
  );
};

const WarehouseLocation = ({warehouseLocation, responsive, index, isLast, map}) => {
  return (
    <Subsection
      index={index}
      style={
        isLast
          ? {
              paddingLeft: 25,
              marginLeft: 6,
            }
          : {
              paddingLeft: 25,
              marginLeft: 6,
            }
      }
    >
      <LocationTitle>{'Warehouse Location'}</LocationTitle>
      <Row index={0} {...responsive} style={{alignItems: 'center'}}>
        <FieldValue
          newTab
          link={LocationUtils.createGoogleMapsUrl(warehouseLocation.address)}
          value={Location.getDisplayLocation(warehouseLocation)}
        />
        <Space width={8} height={8} />
        <MapButtons location={warehouseLocation} map={map} />
      </Row>
    </Subsection>
  );
};

const LocationsFieldValues = ({
  isCustomer,
  index,
  locationUpdateText,
  totalDistance,
  boundLocations,
  locations,
  renderMapAnnotations,
  warehouseLocation,
  showWarehouse,
  style,
}) => {
  const responsive = useResponsive();
  const [map, setMap] = useState(null);

  return (
    <Section index={index} style={style}>
      <TitleAndStatus>
        <Title>Location(s)</Title>
        <LocationStatus totalDistance={totalDistance} locations={locations} />
      </TitleAndStatus>
      {!!locationUpdateText && (
        <Subsection>
          <Subtitle>{locationUpdateText}</Subtitle>
        </Subsection>
      )}

      <Subsection index={1} {...responsive}>
        <MapWrapper>
          <JobMap
            isCustomer={isCustomer}
            renderMapAnnotations={renderMapAnnotations}
            locations={locations}
            boundLocations={boundLocations}
            warehouseLocation={warehouseLocation}
            onReady={setMap}
          />
        </MapWrapper>
      </Subsection>
      <SubsectionSpace />
      {showWarehouse && !_.isEmpty(warehouseLocation) && (
        <React.Fragment>
          <WarehouseLocation
            warehouseLocation={warehouseLocation}
            responsive={responsive}
            index={-1}
            isLast={false}
            map={map}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      {locations.map((location, index) => (
        <LocationSection
          key={index}
          isLast={index === locations.length - 1}
          count={locations.length}
          index={index}
          location={location}
          responsive={responsive}
          map={map}
        />
      ))}
    </Section>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
LocationsFieldValues.propTypes = {
  isCustomer: PropTypes.bool,
  totalDistance: PropTypes.number.isRequired,
  boundLocations: PropTypes.array,
  locations: PropTypes.array.isRequired,
  locationUpdateText: PropTypes.string,
  renderMapAnnotations: PropTypes.func,
};

LocationsFieldValues.defaultProps = {
  isCustomer: false,
  locationUpdateText: '',
  boundLocations: [],
  renderMapAnnotations: () => null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
LocationsFieldValues.fragment = gql`
  ${Location.getDisplayLocation.fragment}
  ${Location.getDisplayName.fragment}
  ${ViewLocationButtons.fragment}

  fragment LocationsFieldValues on Location {
    id
    name
    address
    latitude
    longitude
    floorNumber
    notes
    hasElevator
    hasLongWalk
    stairCount
    stairDescription
    walkingDistance
    elevatorReservationTime
    buildingType
    organization {
      id
      features {
        isEnabledProjectPageV2: isEnabled(feature: "PROJECT_PAGE_V2")
      }
    }
    ...Location_getDisplayLocation
    ...Location_getDisplayName
    ...ViewLocationButtons
  }
`;

export default LocationsFieldValues;
