// Libraries
import React from 'react';

import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';

// Components

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const SplitPage = ({split, children, style}) => {
  const {mobile} = useResponsive();

  return (
    <Container style={style}>
      {children}
      {!mobile && split}
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SplitPage.propTypes = {};

SplitPage.defaultProps = {};

export default SplitPage;
