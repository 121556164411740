// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Space, Styled} from '@supermove/components';
import {useResponsive, ResponsiveType} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import JobTipsForm, {JobTipsFormTypeForm} from '@shared/modules/Billing/forms/JobTipsForm';
import ProjectTipsForm, {UserWithTipType} from '@shared/modules/Billing/forms/ProjectTipsForm';
import {JobUserFormTypeForm} from '@shared/modules/Dispatch/forms/JobUserForm';

const Row = Styled.View`
  flex-direction: row;
`;

const JobUserNameColumn = Styled.View`
  flex: 1;
  flex-direction: column;
`;

const NameText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const AmountText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

interface ColumnComponentProps {
  children: React.ReactNode;
  responsive: ResponsiveType;
}

interface CellComponentProps {
  children: React.ReactNode;
  responsive: ResponsiveType;
}

const MobileJobHeader = ({
  mover,
  responsive,
}: {
  mover: UserWithTipType;
  responsive: ResponsiveType;
}) => {
  return (
    <React.Fragment>
      <JobUserNameColumn>
        <NameText responsive={responsive}>{mover.fullName}</NameText>
        <Space height={2} />
        <AmountText responsive={responsive}>{Currency.display(mover.tipAmount)}</AmountText>
      </JobUserNameColumn>
      <Space width={16} />
    </React.Fragment>
  );
};

const ProjectMoverTipInputs = ({
  form,
  field,
  jobTipsForm,
  jobTipsFormIndex,
  CellComponent,
  responsive,
}: {
  form: any;
  field: string;
  jobTipsForm: JobTipsFormTypeForm;
  jobTipsFormIndex: number;
  CellComponent: React.FC<CellComponentProps>;
  responsive: ResponsiveType;
}) => {
  const movers = _.get(form.values, `${field}.movers`);
  const inputStyle = responsive.desktop ? {flex: 1} : {width: 140};

  return (
    <React.Fragment>
      {movers.map((mover: UserWithTipType) => {
        const jobUserFormIndex = jobTipsForm.jobUserForms.findIndex(
          (jobUserForm: JobUserFormTypeForm) => _.toString(jobUserForm.userId) === mover.id,
        );
        const isMoverOnJob = jobUserFormIndex >= 0;

        return (
          <React.Fragment key={mover.id}>
            <CellComponent responsive={responsive}>
              <Row>
                {!responsive.desktop && <MobileJobHeader mover={mover} responsive={responsive} />}
                {!isMoverOnJob ? (
                  <FieldInput {...form} isResponsive style={inputStyle} input={{disabled: true}} />
                ) : (
                  <FieldInput.Memoized
                    {...form}
                    component={CurrencyInput}
                    name={`${field}.jobTipsForms.${jobTipsFormIndex}.jobUserForms.${jobUserFormIndex}.tipAmount`}
                    isResponsive
                    style={inputStyle}
                    input={{
                      component: FieldInput.TextInput,
                      placeholder: 'Enter amount',
                      setFieldValue: form.setFieldValue,
                      setFieldTouched: form.setFieldTouched,
                      onBlur: () => {
                        // As jobUser tips get updated, update the total amount of the billTip for the job
                        const updatedJobTipAmount = JobTipsForm.getJobUserTipTotal(jobTipsForm);
                        const updatedProjectTipAmount = ProjectTipsForm.getJobUserTipTotal(
                          _.get(form.values, field),
                        );
                        form.setFieldValue(
                          `${field}.jobTipsForms.${jobTipsFormIndex}.billTipForm.amount`,
                          Currency.toForm(updatedJobTipAmount),
                        );
                        form.setFieldValue(`${field}.totalTipAmount`, updatedProjectTipAmount);
                      },
                    }}
                  />
                )}
              </Row>
            </CellComponent>
            <Space height={16} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const EditTipPayoutsJobUserInputs = ({
  form,
  field,
  jobTipsForms,
  ColumnComponent,
  CellComponent,
  jobTipsFormIndexOffset,
}: {
  form: any;
  field: string;
  jobTipsForms: JobTipsFormTypeForm[];
  ColumnComponent: React.FC<ColumnComponentProps>;
  CellComponent: React.FC<CellComponentProps>;
  jobTipsFormIndexOffset: number;
}) => {
  const responsive = useResponsive();

  return (
    <Row>
      {jobTipsForms.map((jobTipsForm: JobTipsFormTypeForm, index: number) => {
        return (
          <React.Fragment key={jobTipsForm.jobId}>
            <ColumnComponent responsive={responsive}>
              <ProjectMoverTipInputs
                key={jobTipsForm.jobId}
                form={form}
                field={field}
                jobTipsForm={jobTipsForm}
                jobTipsFormIndex={jobTipsFormIndexOffset + index}
                CellComponent={CellComponent}
                responsive={responsive}
              />
            </ColumnComponent>
            <Space width={16} />
          </React.Fragment>
        );
      })}
    </Row>
  );
};

export default EditTipPayoutsJobUserInputs;
