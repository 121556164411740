// Supermove
import {useContext} from '@supermove/hooks';
import {invariant} from '@supermove/utils';

// App
import {BookingCalendarContext} from 'modules/Calendar/Booking/context/BookingCalendarContextProvider';

const useBookingCalendarContext = () => {
  const context = useContext(BookingCalendarContext);
  invariant(
    context,
    'useBookingCalendarContext must be used within a BookingCalendarContextProvider',
  );

  return context;
};

export default useBookingCalendarContext;
