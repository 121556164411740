// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import TagForm from '@shared/modules/Tag/forms/TagForm';
import useUpdateTagMutation from '@shared/modules/Tag/hooks/useUpdateTagMutation';
import TagDrawerFields from 'modules/Tag/components/TagDrawerFields';

const UpdateTagDrawer = ({tag, handleClose, isOpen, onSuccess}) => {
  const tagForm = TagForm.edit(tag);
  const {form, submitting, handleSubmit} = useUpdateTagMutation({
    tagForm,
    onSuccess: ({tag}) => {
      onSuccess(tag);
    },
    onError: (error) => console.log(error),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Edit Tag'}
      primaryActionText={'Save'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <TagDrawerFields form={form} isUpdate />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateTagDrawer.fragment = gql`
  ${TagForm.edit.fragment}
  fragment UpdateTagDrawer on Tag {
    id
    ...TagForm_edit
  }
`;

export default UpdateTagDrawer;
