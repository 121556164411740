// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useResponsive} from '@supermove/hooks';
import {Proposal} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import ConfirmationForm from '@shared/modules/Proposal/forms/ConfirmationForm';
import Line from 'components/Line';
import StatusBadge from 'modules/App/components/StatusBadge';
import ProjectProposalActions from 'modules/Project/V2/Show/Blocks/components/ProjectProposalActions';
import ProposalDetailsDrawer from 'modules/Project/V2/Show/Blocks/components/ProposalDetailsDrawer';
import ProposalProgress from 'modules/Project/V2/Show/Blocks/components/ProposalProgress';

const EMPTY_STATE_TEXT = 'No proposals';

const Row = Styled.View`
  flex-direction: row;
`;

const ProposalTypeText = Styled.Text`
  ${({responsive}) => (responsive.desktop ? Table.CellTextTypography : Typography.Mobile.Label)}
`;

const ProposalDateText = Styled.Text`
  ${({responsive}) => (responsive.desktop ? Table.CellTextTypography : Typography.Mobile.Micro)}
`;

const ProposalCardButton = Styled.ButtonV2`
`;

const MobileMicroText = Styled.Text`
  ${Typography.Mobile.Micro}
`;

const ProposalType = ({proposal, responsive}) => {
  return <ProposalTypeText responsive={responsive}>{_.capitalize(proposal.kind)}</ProposalTypeText>;
};

const ProposalStatus = ({proposal}) => {
  const {statusText, statusColor} = Proposal.getStatus(proposal);

  return (
    <StatusBadge
      label={statusText}
      color={statusColor}
      textColor={statusColor}
      size={StatusBadge.SIZE.SMALL}
    />
  );
};

const ProposalSentOn = ({proposal, responsive}) => {
  return (
    <ProposalDateText responsive={responsive}>
      {Datetime.convertToDisplayDatetime(proposal.createdAt)}
    </ProposalDateText>
  );
};

const ProposalCompletedOn = ({proposal, responsive}) => {
  return (
    <ProposalDateText responsive={responsive}>
      {proposal.completedAt ? Datetime.convertToDisplayDatetime(proposal.completedAt) : '-'}
    </ProposalDateText>
  );
};

const getColumnDefinitions = ({project, refetch}) => {
  return [
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText>Type</Table.HeaderText>;
      },
      cellContent: ({item: proposal, responsive}) => {
        return <ProposalType proposal={proposal} responsive={responsive} />;
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <Table.HeaderText>Status</Table.HeaderText>;
      },
      cellContent: ({item: proposal}) => {
        return <ProposalStatus proposal={proposal} />;
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <Table.HeaderText>Sent On</Table.HeaderText>;
      },
      cellContent: ({item: proposal, responsive}) => {
        return <ProposalSentOn proposal={proposal} responsive={responsive} />;
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <Table.HeaderText>Sent By</Table.HeaderText>;
      },
      cellContent: ({item: proposal}) => {
        return <Table.CellText>{proposal.createdBy?.fullName || '-'}</Table.CellText>;
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <Table.HeaderText>Progress</Table.HeaderText>;
      },
      cellContent: ({item: proposal}) => {
        return <ProposalProgress proposal={proposal} />;
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <Table.HeaderText>Completed On</Table.HeaderText>;
      },
      cellContent: ({item: proposal, responsive}) => {
        return <ProposalCompletedOn proposal={proposal} responsive={responsive} />;
      },
    },
    {
      width: 60,
      headerContent: () => {},
      cellContent: ({item: proposal}) => {
        return <ProjectProposalActions project={project} proposal={proposal} refetch={refetch} />;
      },
    },
  ];
};

const RowHookComponent = ({isOpen, handleClose, item, urlFilters}) => {
  return (
    <ProposalDetailsDrawer
      key={isOpen}
      isOpen={isOpen}
      handleClose={handleClose}
      confirmationId={item.id}
      urlFilters={urlFilters}
    />
  );
};

const getProposals = ({project}) => {
  return _.orderBy(
    _.filter(project.confirmations, (confirmation) => !confirmation.isConfirmedManually),
    'createdAt',
    'desc',
  );
};

const ProjectProposalsTable = ({project, refetch, urlFilters}) => {
  const columnDefinitions = getColumnDefinitions({project, refetch});

  return (
    <Table
      columnDefinitions={columnDefinitions}
      emptyStateText={EMPTY_STATE_TEXT}
      items={getProposals({project})}
      itemKey={'id'}
      isDense
      isClickable
      rowHook={{
        hook: useDrawer,
        hookArgument: {name: 'View Proposal Details Drawer'},
        Component: RowHookComponent,
        componentProps: {urlFilters},
      }}
    />
  );
};

const ProposalCard = ({proposal, urlFilters}) => {
  const responsive = useResponsive();
  const proposalDetailsDrawer = useDrawer({name: 'Proposal Details Drawer'});

  return (
    <React.Fragment>
      <ProposalCardButton onPress={proposalDetailsDrawer.handleOpen}>
        <Space height={8} />
        <Row style={{justifyContent: 'space-between'}}>
          <ProposalType proposal={proposal} responsive={responsive} />
          <Row style={{alignItems: 'center'}}>
            <ProposalStatus proposal={proposal} />
            <Space width={8} />
            <Icon source={Icon.AngleRight} color={colors.gray.secondary} size={14} />
          </Row>
        </Row>
        <Space height={8} />
        {!proposal.isActive ? (
          <ProposalCompletedOn proposal={proposal} responsive={responsive} />
        ) : (
          <ProposalSentOn proposal={proposal} responsive={responsive} />
        )}
        <Space height={8} />
      </ProposalCardButton>
      <ProposalDetailsDrawer
        key={proposalDetailsDrawer.key}
        isOpen={proposalDetailsDrawer.isOpen}
        handleClose={proposalDetailsDrawer.handleClose}
        confirmationId={proposal.id}
        urlFilters={urlFilters}
      />
    </React.Fragment>
  );
};

const ProjectProposalsCardList = ({project, urlFilters}) => {
  const proposals = getProposals({project});
  return (
    <React.Fragment>
      {proposals.length === 0 && <MobileMicroText>{EMPTY_STATE_TEXT}</MobileMicroText>}
      {proposals.map((proposal, index) => (
        <React.Fragment key={proposal.id}>
          {index !== 0 && <Line />}
          <ProposalCard proposal={proposal} urlFilters={urlFilters} />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

ProjectProposalsTable.Mobile = ProjectProposalsCardList;

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectProposalsTable.fragment = gql`
  ${ConfirmationForm.edit.fragment}
  ${Proposal.getStatus.fragment}
  ${ProposalProgress.fragment}
  ${ProjectProposalActions.projectFragment}
  ${ProjectProposalActions.confirmationFragment}

  fragment ProjectProposalsTable on Project {
    id
    confirmationUrl
    quoteUrl
    confirmations {
      id
      isActive
      isConfirmedManually
      kind
      createdAt
      completedAt
      createdBy {
        id
        fullName
      }
      ...ConfirmationForm_edit
      ...Proposal_getStatus
      ...ProposalProgress
      ...ProjectProposalActions_Confirmation
    }
    ...ProjectProposalActions_Project
  }
`;

ProjectProposalsTable.Mobile.fragment = gql`
  ${Proposal.getStatus.fragment}
  ${ProjectProposalActions.projectFragment}
  ${ProjectProposalActions.confirmationFragment}

  fragment ProjectProposalsTable_Mobile on Project {
    id
    confirmations {
      id
      isActive
      isConfirmedManually
      kind
      completedAt
      createdAt
      ...Proposal_getStatus
      ...ProjectProposalActions_Confirmation
    }
    ...ProjectProposalActions_Project
  }
`;

export default ProjectProposalsTable;
