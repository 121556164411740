// Supermove
import _ from 'lodash';

import {gql} from '@supermove/graphql';
import {titleize, withFragment} from '@supermove/utils';

// Apps
import DataTableVariableAccessKind from '@shared/modules/Billing/enums/DataTableVariableAccessKind';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import DataTableVariableForm from '@shared/modules/Billing/forms/DataTableVariableForm';

const getDisplayFormat = (variableForm: any) => {
  return VariableFormat.getDisplayName(variableForm.format);
};

const getDisplayKind = (variableForm: any) => {
  return titleize(variableForm.kind.replace(/_/g, ' '));
};

const getHasDataTable = (variableForm: any) => {
  return !!variableForm.dataTableVariableForm;
};

const getIsUsingTariff = (variableForm: any) => {
  return (
    _.get(variableForm, 'dataTableVariableForm.tableAccessKind') ===
    DataTableVariableAccessKind.WEIGHT_DISTANCE_TO_RATE
  );
};

const _new = ({organizationId, userId, category = null}: any) => {
  return {
    organizationId,
    identifier: '',
    name: '',
    description: '',
    kind: null,
    category,
    format: null,
    isDerived: false,
    createdById: userId,
    updatedById: userId,
    dataTableVariableForm: null,

    // Private
    isExistingVariable: false,
    isAddingNewDataTable: false,
  };
};

const edit = withFragment(
  (variable, {userId}) => ({
    organizationId: (variable as any).organizationId,
    identifier: (variable as any).identifier,
    name: (variable as any).name,
    description: (variable as any).description,
    kind: (variable as any).kind,
    category: (variable as any).category,
    format: (variable as any).format,
    isDerived: (variable as any).isDerived,
    createdById: (variable as any).createdById,
    updatedById: userId,
    dataTableVariableForm: (variable as any).dataTableVariable
      ? DataTableVariableForm.edit((variable as any).dataTableVariable)
      : null,
    // Private
    isExistingVariable: true,
    isAddingNewDataTable: false,
  }),
  gql`
    ${DataTableVariableForm.edit.fragment}
    fragment VariableForm_edit on Variable {
      id
      organizationId
      identifier
      name
      description
      kind
      category
      format
      isDerived
      updatedById
      createdById
      dataTableVariable {
        id
        ...DataTableVariableForm_edit
      }
    }
  `,
);

const toForm = ({
  organizationId,
  identifier,
  name,
  description,
  kind,
  category,
  format,
  isDerived,
  createdById,
  updatedById,
  dataTableVariableForm,
  isExistingVariable,
  isAddingNewDataTable,
}: any) => ({
  organizationId,
  identifier,
  name,
  description,
  kind,
  category,
  format,
  isDerived,
  createdById,
  updatedById,
  dataTableVariableForm: dataTableVariableForm
    ? DataTableVariableForm.toForm(dataTableVariableForm)
    : null,

  // Private
  isExistingVariable,
  isAddingNewDataTable,
});

const toMutation = ({
  organizationId,
  identifier,
  name,
  description,
  kind,
  category,
  format,
  isDerived,
  createdById,
  updatedById,
  dataTableVariableForm,
}: any) => ({
  organizationId,
  identifier,
  name,
  description,
  kind,
  category,
  format,
  isDerived,
  createdById,
  updatedById,
  dataTableVariableForm: dataTableVariableForm
    ? DataTableVariableForm.toMutation(dataTableVariableForm)
    : null,
});

const VariableForm = {
  toForm,
  toMutation,
  new: _new,
  edit,

  getDisplayFormat,
  getDisplayKind,
  getHasDataTable,
  getIsUsingTariff,
};

export default VariableForm;
