// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import UserRole from '@shared/modules/User/enums/UserRole';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import EmailReplyToPanel from 'modules/Organization/Settings/Communication/components/EmailReplyToPanel';
import EmailSystemNotificationsPanel from 'modules/Organization/Settings/Communication/components/EmailSystemNotificationsPanel';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const SectionTitle = Styled.Text`
  ${Typography.Heading1}
`;

const OrganizationSettingsCommunicationGeneralPage = () => {
  const {data, loading, refetch} = useQuery(OrganizationSettingsCommunicationGeneralPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SettingsPage.PageContent
      title={'General'}
      // TODO: Update description when call forwarding is implemented
      // "Configure system notifications, the reply-to email address, and call forwarding."
      description={'Configure system notifications and the reply-to email address.'}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          const hasEditPermissions = _.includes(UserRole.ADMIN_ROLES_PLUS_SUPER, data.viewer.role);
          return (
            <React.Fragment>
              <SectionTitle>Email</SectionTitle>
              <Space height={24} />
              <EmailSystemNotificationsPanel
                key={data.viewer.viewingOrganization.settings.notificationEmails.length}
                index={0}
                organization={data.viewer.viewingOrganization}
                refetch={refetch}
                hasEditPermissions={hasEditPermissions}
              />
              <Space height={24} />
              <EmailReplyToPanel
                key={data.viewer.viewingOrganization.settings.replyToKind}
                index={1}
                organization={data.viewer.viewingOrganization}
                refetch={refetch}
                hasEditPermissions={hasEditPermissions}
              />
            </React.Fragment>
          );
        }}
      </Loading>
    </SettingsPage.PageContent>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCommunicationGeneralPage.query = gql`
  ${EmailReplyToPanel.fragment}
  ${EmailSystemNotificationsPanel.fragment}
  query OrganizationSettingsCommunicationGeneralPage {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        settings {
          id
          notificationEmails
          replyToKind
        }
        ...EmailReplyToPanel
        ...EmailSystemNotificationsPanel
      }
    }
  }
`;

export default OrganizationSettingsCommunicationGeneralPage;
