// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getCategoryDisplayValue = withFragment(
  (jobTypeFeature) => {
    switch ((jobTypeFeature as any).category) {
      case 'CREW':
        return 'Crew App';
      case 'MANAGER':
        return 'Office App';
      case 'CUSTOMER':
        return 'Customer App';
      default:
        return '';
    }
  },
  gql`
    fragment JobTypeFeature_getCategoryDisplayValue on JobTypeFeature {
      id
      category
    }
  `,
);

const JobTypeFeature = {
  getCategoryDisplayValue,
};

export default JobTypeFeature;
