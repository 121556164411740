// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';

// App
import CapacityCalendar from 'modules/Calendar/Capacity/components/CapacityCalendar';
import CapacityCalendarDayView from 'modules/Calendar/Capacity/components/CapacityCalendarDayView';

const CapacityCalendarPage = ({organization}) => {
  const {params, navigator} = useNavigationDOM();
  const selectedTab = navigator.location.pathname.split('/').slice(-1)[0];

  return (
    <React.Fragment>
      {selectedTab === 'month' ? (
        <CapacityCalendar searchParams={params} organization={organization} />
      ) : selectedTab === 'day' ? (
        <CapacityCalendarDayView searchParams={params} organization={organization} />
      ) : null}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarPage.fragment = gql`
  ${CapacityCalendar.fragment}
  ${CapacityCalendarDayView.fragment}

  fragment CapacityCalendarPage on Organization {
    id
    ...CapacityCalendar
    ...CapacityCalendarDayView
  }
`;

export default CapacityCalendarPage;
