// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight} from '@supermove/styles';

import {ReportMoveTimeRangesChart, ReportMoveTimeRangesList} from 'modules/ReportMove/components';

const Container = Styled.View`
`;

const Row = Styled.View`
  z-index: ${({index = 0}) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: 20px;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
`;

const ReportMoveTimeRangesReference = ({reportMove}) => (
  <Container>
    <Row index={0}>
      <Title>Work Timesheet (for reference)</Title>
    </Row>
    <RowSpace />
    <Row index={1}>
      <ReportMoveTimeRangesChart timeRanges={reportMove.timeRanges} />
    </Row>
    <RowSpace />
    <Row index={2}>
      <ReportMoveTimeRangesList timeRanges={reportMove.timeRanges} />
    </Row>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMoveTimeRangesReference.fragment = gql`
  fragment ReportMoveTimeRangesReference on ReportMove {
    id
    timeRanges {
      name
      kind
      start
      end
    }
  }
`;

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ReportMoveTimeRangesReference.propTypes = {
  reportMove: PropTypes.object.isRequired,
};

ReportMoveTimeRangesReference.defaultProps = {};

export default ReportMoveTimeRangesReference;
