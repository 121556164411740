// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useModal, useResponsive} from '@supermove/hooks';
import {Inventory} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import InventoryForm from '@shared/modules/Inventory/forms/InventoryForm';
import InventorySurveyMethodForm from '@shared/modules/Inventory/forms/InventorySurveyMethodForm';
import useSyncInventoryBillMutation from '@shared/modules/Inventory/hooks/useSyncInventoryBillMutation';
import useUpdateInventorySurveyMethodMutation from '@shared/modules/Inventory/hooks/useUpdateInventorySurveyMethodMutation';
import ConfirmSyncInventoryBillModal from 'modules/Job/V2/Move/components/ConfirmSyncInventoryBillModal';
import UpdateInventoryKindVirtualWalkthroughModal from 'modules/Job/V2/Move/components/UpdateInventoryKindVirtualWalkthroughModal';
import AccessSurveyV1ActionsMenuButton from 'modules/Project/V2/Show/Blocks/components/AccessSurveyV1ActionsMenuButton';

const Container = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const Title = Styled.Text`
  ${Typography.Heading1}
`;

const Section = Styled.View`
  flex-direction: column;
  padding: 12px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  flex: 1;
`;

const InfoRow = Styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
  z-index: ${({index = 0}) => 100 - index};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const InfoBlockContainer = Styled.View`
  flex-direction: column;
  flex-basis: 168px;
`;

const InfoBlockTitle = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const InfoBlockValue = Styled.Text`
  ${Typography.Body}
`;

const SurveyNotesContainer = Styled.View`
  flex: 1;
`;

const InfoBlock = ({title, value}) => {
  return (
    <InfoBlockContainer>
      <InfoBlockTitle>{title}</InfoBlockTitle>
      <Space height={8} />
      <InfoBlockValue>{value}</InfoBlockValue>
      <Space height={16} />
    </InfoBlockContainer>
  );
};

const SurveyNotesBlock = ({title, value}) => {
  return (
    <SurveyNotesContainer>
      <InfoBlockTitle>{title}</InfoBlockTitle>
      <Space height={8} />
      <InfoBlockValue numberOfLines={1}>{value}</InfoBlockValue>
    </SurveyNotesContainer>
  );
};

const VirtualWalkthroughAction = ({inventory, refetch}) => {
  const {navigator} = useNavigationDOM();
  const virtualWalkthroughModal = useModal({name: 'Virtual Walkthrough Modal'});

  if (inventory.isVirtualWalkthroughStarted) {
    return (
      <Button
        onPress={() => navigator.push(`/inventories/${inventory.uuid}/edit`)}
        text={'View Virtual Walkthrough'}
      />
    );
  }

  return (
    <React.Fragment>
      <Button onPress={virtualWalkthroughModal.handleOpen} text={'Send Link to Customer'} />
      <UpdateInventoryKindVirtualWalkthroughModal
        isOpen={virtualWalkthroughModal.isOpen}
        inventoryUuid={inventory.uuid}
        handleClose={virtualWalkthroughModal.handleClose}
        onSuccess={() => refetch()}
      />
    </React.Fragment>
  );
};

const JobInventoryBlock = ({sectionIndex, job, refetch}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const confirmSyncInventoryBillModal = useModal({name: 'Confirm Sync Inventory Bill Modal'});
  const {inventory} = job.project;
  const inventorySurveyMethodForm = InventorySurveyMethodForm.edit(inventory);
  const {form, handleSubmit} = useUpdateInventorySurveyMethodMutation({
    inventorySurveyMethodForm,
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const inventoryForm = InventoryForm.edit(inventory);
  const {submitting, handleSubmit: syncInventoryBillHandleSubmit} = useSyncInventoryBillMutation({
    inventoryForm,
    onSuccess: () => {
      confirmSyncInventoryBillModal.handleClose();
      refetch();
    },
    onError: (error) => console.log(error),
  });

  const isMobile = responsive.mobile;
  const isVirtualWalkthrough = Inventory.getIsVirtualWalkthrough(inventory);

  return (
    <Container sectionIndex={sectionIndex}>
      <Header>
        <Title>Survey</Title>
      </Header>
      <Space height={24} />
      <Row
        index={0}
        style={isMobile ? {flexDirection: 'column', alignItems: 'flex-start'} : {flexWrap: 'wrap'}}
      >
        <FieldInput
          {...form}
          name={'inventorySurveyMethodForm.surveyMethodId'}
          index={0}
          component={DropdownInput}
          style={{width: isMobile ? '100%' : '250px'}}
          input={{
            placeholder: 'Select survey method',
            options: job.organization.surveyMethods.map((surveyMethod) => {
              return {
                value: surveyMethod.id,
                label: surveyMethod.name,
              };
            }),
            style: {flex: 1},
            setFieldValue: (field, value) => {
              form.setFieldValue(field, value);
              setTimeout(handleSubmit, 0);
            },
          }}
        />
        {isMobile ? <Space height={16} /> : <Space width={16} />}
        {isVirtualWalkthrough && (
          <React.Fragment>
            <VirtualWalkthroughAction inventory={inventory} refetch={refetch} />
            {isMobile ? <Space height={16} /> : <Space width={16} />}
          </React.Fragment>
        )}
        <Space style={{flex: 1}} />
        {!isVirtualWalkthrough && (
          <React.Fragment>
            <SecondaryButton
              text={'Survey Items'}
              iconLeft={Icon.ClipboardList}
              onPress={() => {
                navigator.push(`/projects/${job.project.uuid}/inventory/${inventory.uuid}`);
              }}
            />
            {isMobile ? <Space height={16} /> : <Space width={16} />}
          </React.Fragment>
        )}
        {inventory.hasSyncedBill ? (
          <SecondaryButton
            onPress={confirmSyncInventoryBillModal.handleOpen}
            text={'Regenerate Bill'}
            iconLeft={Icon.DollarSign}
          />
        ) : (
          <SecondaryButton
            onPress={syncInventoryBillHandleSubmit}
            isSubmitting={submitting}
            text={'Generate Bill'}
            iconLeft={Icon.DollarSign}
          />
        )}
        <Space width={8} />
        <AccessSurveyV1ActionsMenuButton jobUuid={job.uuid} inventoryUuid={inventory.uuid} />
        <ConfirmSyncInventoryBillModal
          isOpen={confirmSyncInventoryBillModal.isOpen}
          handleClose={confirmSyncInventoryBillModal.handleClose}
          handleSubmit={syncInventoryBillHandleSubmit}
          submitting={submitting}
        />
      </Row>
      <Space height={16} />
      <Row index={1}>
        <Section>
          <InfoRow>
            <InfoBlock
              title={'Est. Price'}
              value={Currency.display(inventory.collection.totalPrice)}
            />
            <InfoBlock
              title={'Est. Volume'}
              value={`${_.round(inventory.collection.totalVolume, 2)} cu ft`}
            />
            <InfoBlock
              title={'Est. Weight'}
              value={`${_.round(inventory.collection.totalWeight, 2)}  lbs`}
            />
            <InfoBlock
              title={'Rooms'}
              value={`${inventory.totalRooms} ${pluralize('room', inventory.totalRooms)}`}
            />
            <InfoBlock
              title={'Take Carton Items'}
              value={inventory.collection.totalTakeCartonItems}
            />
            <InfoBlock
              title={'Take Non-Carton Items'}
              value={inventory.collection.totalTakeNonCartonItems}
            />
          </InfoRow>
          <Row>
            <SurveyNotesBlock title={'Survey Notes'} value={inventory.notes} />
          </Row>
        </Section>
      </Row>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobInventoryBlock.fragment = gql`
  ${Inventory.getIsVirtualWalkthrough.fragment}
  ${InventorySurveyMethodForm.edit.fragment}
  ${InventoryForm.edit.fragment}

  fragment JobInventoryBlock on Job {
    id
    organization {
      id
      surveyMethods {
        id
        name
      }
    }
    project {
      id
      uuid
      inventory {
        id
        uuid
        notes
        totalRooms
        hasSyncedBill
        isVirtualWalkthroughStarted
        surveyMethod {
          id
          kind
        }
        collection {
          id
          totalPrice
          totalVolume
          totalWeight
          totalTakeCartonItems
          totalTakeNonCartonItems
        }
        ...Inventory_getIsVirtualWalkthrough
        ...InventorySurveyMethodForm_edit
        ...InventoryForm_edit
      }
    }
  }
`;

export default JobInventoryBlock;
