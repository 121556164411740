// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: (organization as any).id,
    pin: (organization as any).pin,
    // Private
    name: (organization as any).name,
    slug: (organization as any).slug,
  }),
  gql`
    fragment OrganizationTabletSettingsForm_edit on Organization {
      id
      name
      slug
      pin
    }
  `,
);

const toForm = ({organizationId, pin, name, slug}: any) => ({
  organizationId,
  pin,

  // Private
  name,
  slug,
});

const toMutation = ({organizationId, pin}: any) => ({
  organizationId,
  pin,
});

const OrganizationTabletSettingsForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationTabletSettingsForm;
