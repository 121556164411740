// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import JobProjectSection from 'modules/Project/components/JobProjectSection';

const JobProjectBlock = ({job}) => {
  return <JobProjectSection sectionIndex={0} job={job} />;
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobProjectBlock.propTypes = {
  job: PropTypes.object.isRequired,
};

JobProjectBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectBlock.fragment = gql`
  ${JobProjectSection.fragment}

  fragment JobProjectBlock on Job {
    id
    ...JobProjectSection
  }
`;

export default JobProjectBlock;
