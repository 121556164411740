// Supermove
import {Datetime} from '@supermove/utils';

const getFormRangeTime = ({datetime}: any) => {
  if (!datetime) {
    return '';
  }
  return Datetime.toDisplayTime(Datetime.fromDatetime(datetime, true), Datetime.FORM_TIME);
};

const getMutationRangeDatetime = ({date, time}: any) => {
  return Datetime.fromDateAndTime(date, Datetime.toMutationTime(time));
};

const getMutationRangeFrom = ({rangeFrom, date, rangeFromDate}: any) => {
  if (!rangeFrom) {
    return null;
  }
  return getMutationRangeDatetime({date: date || rangeFromDate, time: rangeFrom, rangeFromDate});
};

const getMutationRangeTo = ({rangeFrom, rangeTo, isFixedRangeTo, date, rangeFromDate}: any) => {
  if (!rangeTo) {
    return null;
  }

  const mutationRangeTo = getMutationRangeDatetime({date: date || rangeFromDate, time: rangeTo});

  // Increment by a day if the rangeTo is before the rangeFrom.
  if (!isFixedRangeTo) {
    const mutationRangeFrom = getMutationRangeFrom({date, rangeFrom, rangeFromDate});
    const isIntoNextDay = mutationRangeTo < mutationRangeFrom;
    if (isIntoNextDay) {
      return mutationRangeTo.add(1, 'day');
    }
  }

  return mutationRangeTo;
};

const getMutationRangeToDate = ({
  rangeFromDate,
  rangeToDate,
  rangeFrom,
  rangeTo,
  isFixedRangeTo,
}: any) => {
  if (isFixedRangeTo) {
    return rangeToDate;
  }

  if (!rangeTo) {
    return rangeFromDate;
  }

  const mutationRangeTo = getMutationRangeTo({
    date: rangeFromDate,
    rangeFrom,
    rangeTo,
  });

  return Datetime.toDate(mutationRangeTo);
};

const getIsValidTime = ({time}: any) => {
  return /^([01]?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/.test(time);
};

const getEndTimeIsNextDay = ({rangeFromDate, rangeFrom, rangeTo}: any) => {
  if (getIsValidTime({time: rangeFrom}) && getIsValidTime({time: rangeTo})) {
    const mutationRangeFrom = getMutationRangeFrom({date: rangeFromDate, rangeFrom});
    const mutationRangeTo = getMutationRangeDatetime({date: rangeFromDate, time: rangeTo});
    return mutationRangeFrom > mutationRangeTo;
  }
  return false;
};

const getMinutes = ({rangeFromDate, rangeFrom, rangeTo}: any) => {
  const datetimeFrom = getMutationRangeFrom({date: rangeFromDate, rangeFrom});
  const datetimeTo = getMutationRangeTo({date: rangeFromDate, rangeFrom, rangeTo});
  if (!datetimeFrom || !datetimeTo) {
    return 0;
  }
  return datetimeTo.diff(datetimeFrom, 'minutes');
};

const TimesheetBlockForm = {
  getFormRangeTime,
  getMutationRangeFrom,
  getMutationRangeTo,
  getMutationRangeDatetime,
  getMutationRangeToDate,
  getEndTimeIsNextDay,
  getMinutes,
  getIsValidTime,
};

export default TimesheetBlockForm;
