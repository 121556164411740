// Supermove
import {gql} from '@supermove/graphql';
import {SettingsModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export interface SettingsClaimAssignmentFormType {
  settingsId: string;
  mustLinkClaimToJob: boolean;
  autoAssignJobMoversToClaim: boolean;
}

const edit = withFragment(
  (settings: SettingsModel) => ({
    settingsId: settings.id,
    mustLinkClaimToJob: settings.mustLinkClaimToJob,
    autoAssignJobMoversToClaim: settings.autoAssignJobMoversToClaim,
  }),
  gql`
    fragment SettingsClaimAssignmentForm_edit on Settings {
      id
      mustLinkClaimToJob
      autoAssignJobMoversToClaim
    }
  `,
);

const toForm = ({
  settingsId,
  mustLinkClaimToJob,
  autoAssignJobMoversToClaim,
}: SettingsClaimAssignmentFormType) => ({
  settingsId,
  mustLinkClaimToJob,
  autoAssignJobMoversToClaim,
});

const toMutation = ({
  settingsId,
  mustLinkClaimToJob,
  autoAssignJobMoversToClaim,
}: SettingsClaimAssignmentFormType) => ({
  settingsId,
  mustLinkClaimToJob,
  autoAssignJobMoversToClaim,
});

const SettingsClaimAssignmentForm = {
  edit,
  toForm,
  toMutation,
};

export default SettingsClaimAssignmentForm;
