// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import TagAssignmentForm from '@shared/modules/Tag/forms/TagAssignmentForm';

const useDeleteTagAssignmentMutation = ({tagAssignmentForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      tagAssignmentForm: TagAssignmentForm.toForm(tagAssignmentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteTagAssignmentMutation.mutation,
    variables: {
      tagAssignmentForm: TagAssignmentForm.toMutation(form.values.tagAssignmentForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteTagAssignmentMutation.mutation = gql`
  mutation useDeleteTagAssignmentMutation($tagAssignmentForm: TagAssignmentForm!) {
    response: deleteTagAssignment(tagAssignmentForm: $tagAssignmentForm) {
      ${gql.errors}
      tagAssignment {
        id
      }
    }
  }
`;

export default useDeleteTagAssignmentMutation;
