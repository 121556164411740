// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled, SanitizedHTML} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMountEffect} from '@supermove/hooks';
import {EmailTemplate} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import EmailTemplateBodyKind from '@shared/modules/Email/enums/EmailTemplateBodyKind';
import EmailForm from '@shared/modules/Email/forms/EmailForm';
import EmailPreviewForm from '@shared/modules/Email/forms/EmailPreviewForm';
import useEmailPreviewMutation from '@shared/modules/Email/hooks/useEmailPreviewMutation';
import Field from 'modules/App/components/Field';
import EmailContentSection from 'modules/Email/components/EmailContentSection';
import EmailRecipientsSection from 'modules/Email/components/EmailRecipientsSection';

const Container = Styled.View`
`;

const Label = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const getEmailTemplates = ({form, field, organization, defaultEmailTemplateForm}) => {
  const defaultEmailTemplateFormKind = _.get(defaultEmailTemplateForm, 'emailForm.kind');
  const emailTemplates = _.sortBy(organization.sortedEmailTemplates, (emailTemplate) =>
    defaultEmailTemplateForm ? emailTemplate.kind !== defaultEmailTemplateFormKind : null,
  );

  // TODO(dan) Remove this conditional section once all email templates come from the backend
  const isDefaultEmailTemplateIncludedInEmailTemplates = !!_.find(
    emailTemplates,
    (emailTemplate) => emailTemplate.kind === defaultEmailTemplateFormKind,
  );
  if (defaultEmailTemplateForm && !isDefaultEmailTemplateIncludedInEmailTemplates) {
    const isCurrentEmailTemplateIdEmpty = _.isNil(_.get(form.values, `${field}.emailTemplateId`));
    if (isCurrentEmailTemplateIdEmpty) {
      // If there is no selected email template, we set it to the custom email template.
      form.setFieldValue(`${field}.emailTemplateId`, -1);
    }
    return [
      EmailTemplate.CUSTOM_EMAIL_TEMPLATE,
      {
        id: -1,
        name: EmailTemplate.getDisplayFromKind(defaultEmailTemplateFormKind),
        ...defaultEmailTemplateForm.emailForm,
      },
      ...emailTemplates,
    ];
  }

  return [EmailTemplate.CUSTOM_EMAIL_TEMPLATE, ...emailTemplates];
};

const getEmailTemplateOptions = ({emailTemplates}) => {
  const emailTemplateOptions = emailTemplates.map(({id, name}) => ({
    label: name,
    value: id,
  }));
  return emailTemplateOptions;
};

const submitEmailTemplateForPreview = ({
  emailTemplates,
  emailTemplateId,
  emailPreviewForm,
  handleSubmit,
  setIsGeneratingPreview,
}) => {
  const emailTemplate = emailTemplates.find(({id}) => id === emailTemplateId);
  emailPreviewForm.setFieldValue('emailPreviewForm.emailTemplate', emailTemplate);
  setIsGeneratingPreview && setIsGeneratingPreview(true);
  setTimeout(() => handleSubmit(), 0);
};

const EmailTemplateSection = ({
  form,
  field,
  emailTemplates,
  emailPreviewForm,
  handleEmailPreviewSubmit,
  isSubmitting,
  setIsGeneratingPreview,
}) => {
  const options = getEmailTemplateOptions({emailTemplates});

  return (
    <React.Fragment>
      <Label>Email Template</Label>
      <Space height={2} />
      <Section sectionIndex={1}>
        <Field
          {...form}
          component={DropdownInput}
          name={`${field}.emailTemplateId`}
          input={{
            options,
            placeholder: 'Select an email template',
            setFieldValue: (name, value) => {
              form.setFieldValue(`${field}.emailTemplateId`, value);
              submitEmailTemplateForPreview({
                emailTemplates,
                emailTemplateId: value,
                emailPreviewForm,
                handleSubmit: handleEmailPreviewSubmit,
                setIsGeneratingPreview,
              });
            },
            style: {
              width: '100%',
            },
            isLoading: isSubmitting,
          }}
          style={{
            width: '100%',
          }}
        />
      </Section>
      <Space height={12} />
    </React.Fragment>
  );
};

const ProjectEmailFieldsContent = ({
  form: parentForm,
  field,
  project,
  emailTemplates,
  viewerId,
  setIsGeneratingPreview,
}) => {
  const emailPreviewForm = EmailPreviewForm.new({projectId: project.id});
  const {form, handleSubmit, submitting} = useEmailPreviewMutation({
    emailPreviewForm,
    onSuccess: ({emailPreview}) => {
      const emailTemplate = _.get(form.values, 'emailPreviewForm.emailTemplate');
      const emailForm = EmailForm.toForm(
        EmailForm.newFromProjectWithEmailTemplate({
          viewerId,
          project,
          emailTemplate: {
            ...emailTemplate,
            subject: emailPreview.subject,
            body: emailPreview.body,
          },
          kind: emailTemplate.kind,
        }),
      );
      parentForm.setFieldValue(`${field}`, emailForm);
      setIsGeneratingPreview && setIsGeneratingPreview(false);
    },
    onError: (errors) => {
      console.log(errors);
    },
  });

  // on mount, we ensure the selected email template have been evaluated for preview
  useMountEffect(() => {
    const emailTemplateId = _.get(parentForm.values, `${field}.emailTemplateId`);
    submitEmailTemplateForPreview({
      emailTemplates,
      emailTemplateId,
      emailPreviewForm: form,
      handleSubmit,
      setIsGeneratingPreview,
    });
  });
  const bodyKind = _.get(form.values, 'emailPreviewForm.emailTemplate.bodyKind');

  return (
    <Container>
      <Section sectionIndex={1}>
        <EmailTemplateSection
          form={parentForm}
          field={field}
          emailTemplates={emailTemplates}
          emailPreviewForm={form}
          handleEmailPreviewSubmit={handleSubmit}
          isSubmitting={submitting}
          setIsGeneratingPreview={setIsGeneratingPreview}
        />
      </Section>
      <Section sectionIndex={2}>
        <EmailRecipientsSection form={parentForm} field={field} />
      </Section>
      <Section sectionIndex={3}>
        {bodyKind === EmailTemplateBodyKind.HTML ? (
          <SanitizedHTML rawHTML={_.get(parentForm.values, `${field}.body`)} />
        ) : (
          <EmailContentSection form={parentForm} field={field} />
        )}
      </Section>
    </Container>
  );
};

const ProjectEmailFields = ({
  field,
  form,
  viewerId,
  project,
  defaultEmailTemplateForm,
  setIsGeneratingPreview,
}) => {
  const {organization} = project;
  const emailTemplates = getEmailTemplates({
    form,
    field,
    organization,
    defaultEmailTemplateForm,
  });

  return (
    <ProjectEmailFieldsContent
      form={form}
      field={field}
      project={project}
      emailTemplates={emailTemplates}
      viewerId={viewerId}
      setIsGeneratingPreview={setIsGeneratingPreview}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectEmailFields.fragment = gql`
  ${EmailForm.newFromProjectWithEmailTemplate.fragment}

  fragment ProjectEmailFields on Project {
    id
    organization {
      id
      sortedEmailTemplates {
        id
        kind
        name
        ...EmailForm_newFromProjectWithEmailTemplate_EmailTemplate
      }
    }
    ...EmailForm_newFromProjectWithEmailTemplate_Project
  }
`;

export default ProjectEmailFields;
