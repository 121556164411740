import {Token} from './tokenizer';

export class Parser {
  tokens: Token[];

  currentTokenIndex: number;

  constructor(tokens: Token[]) {
    this.tokens = tokens;
    this.currentTokenIndex = 0;
  }

  match(...kinds: string[]) {
    for (const kind of kinds) {
      if (this.check(kind)) {
        this.advance();
        return true;
      }
    }
    return false;
  }

  check = (kind: string) => {
    if (this.isAtEnd()) return false;
    return this.peek().kind === kind;
  };

  isAtEnd = () => {
    return this.peek() === undefined;
  };

  peek = () => {
    return this.tokens[this.currentTokenIndex];
  };

  previous = () => {
    return this.tokens[this.currentTokenIndex - 1];
  };

  advance = () => {
    this.currentTokenIndex += 1;
    return this.previous();
  };

  consume = (kind: string, errorMessage: string) => {
    if (this.check(kind)) {
      return this.advance();
    }
    throw new Error(`${this.currentTokenIndex}: ${errorMessage}`);
  };
}
