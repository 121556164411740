// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (jobTypeFeature) => ({
    jobTypeFeatureId: (jobTypeFeature as any).id,
    jobTypeId: (jobTypeFeature as any).jobTypeId,
    organizationId: (jobTypeFeature as any).organizationId,
    updatedById: (jobTypeFeature as any).updatedById,
    createdById: (jobTypeFeature as any).createdById,
    kind: (jobTypeFeature as any).kind,
    isEnabled: (jobTypeFeature as any).isEnabled,
  }),
  gql`
    fragment JobTypeFeatureForm_edit on JobTypeFeature {
      id
      organizationId
      jobTypeId
      updatedById
      createdById
      kind
      isEnabled
    }
  `,
);

const toForm = ({
  jobTypeFeatureId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
}: any) => ({
  jobTypeFeatureId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
});

const toMutation = ({
  jobTypeFeatureId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
}: any) => ({
  jobTypeFeatureId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
});

const JobTypeFeatureForm = {
  edit,
  toForm,
  toMutation,
};

export default JobTypeFeatureForm;
