// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import OrganizationLogo from '@shared/modules/Organization/components/OrganizationLogo';

const Container = Styled.View`
  align-items: center;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const License = Styled.H8`
  text-align: center;
`;

const Name = Styled.H7`
  ${fontWeight(700)}
  text-align: center;
`;

const Text = Styled.H8`
`;

const DocumentHeaderOrganization = ({
  shouldShowBusinessName,
  shouldShowBusinessLicense,
  shouldShowBusinessAddressAndPhoneNumber,
  title,
  organization,
}) => {
  return (
    <Container>
      {!!title && <Title>{title}</Title>}
      <OrganizationLogo
        organization={organization}
        style={{
          width: 195,
          height: 80,
          marginBottom: '5px',
        }}
      />
      {shouldShowBusinessName && <Name>{organization.businessName}</Name>}
      {shouldShowBusinessLicense && <License>{organization.businessLicense}</License>}
      {shouldShowBusinessAddressAndPhoneNumber && (
        <React.Fragment>
          <Text>{organization.businessAddress}</Text>
          <Text>{Phone.display(organization.phoneNumber)}</Text>
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DocumentHeaderOrganization.propTypes = {
  shouldShowBusinessName: PropTypes.bool,
  shouldShowBusinessLicense: PropTypes.bool,
  shouldShowBusinessAddressAndPhoneNumber: PropTypes.bool,
  title: PropTypes.string,
  organization: PropTypes.object.isRequired,
};

DocumentHeaderOrganization.defaultProps = {
  shouldShowBusinessName: true,
  shouldShowBusinessLicense: true,
  shouldShowBusinessAddressAndPhoneNumber: true,
  title: '',
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentHeaderOrganization.fragment = gql`
  ${OrganizationLogo.fragment}
  fragment DocumentHeaderOrganization on Organization {
    id
    slug
    phoneNumber
    businessAddress
    businessLicense
    businessName
    ...OrganizationLogo
  }
`;

export default DocumentHeaderOrganization;
