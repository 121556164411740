// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import DeleteAttachmentForm from '@shared/modules/File/forms/DeleteAttachmentForm';

// App

const useDeleteAttachmentForm = ({deleteAttachmentForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      deleteAttachmentForm: DeleteAttachmentForm.toForm(deleteAttachmentForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteAttachmentForm.mutation,
    variables: {
      deleteAttachmentForm: DeleteAttachmentForm.toMutation(form.values.deleteAttachmentForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteAttachmentForm.mutation = gql`
  mutation useDeleteAttachmentForm($deleteAttachmentForm: DeleteAttachmentForm!) {
    response: deleteAttachment(deleteAttachmentForm: $deleteAttachmentForm) {
      ${gql.errors}
      attachmentId
    }
  }
`;

export default useDeleteAttachmentForm;
