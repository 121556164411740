// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

import CreateCrewWithSlotsAndTrucksForm from '@shared/modules/Dispatch/forms/CreateCrewWithSlotsAndTrucksForm';
import SetCrewSlotsToCrewForm from '@shared/modules/Dispatch/forms/SetCrewSlotsToCrewForm';
import useCreateCrewWithSlotsAndTrucksMutation from '@shared/modules/Dispatch/hooks/useCreateCrewWithSlotsAndTrucksMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import SystemMessage from 'modules/App/components/SystemMessage';
import TrucksAndSlotsList from 'modules/Dispatch/Crew/components/TrucksAndSlotsList';

const Container = Styled.View`
`;

const Line = Styled.View`
  border-bottom-width: 1;
  border-color: ${colors.gray.border};
`;

const CrewSectionContainer = Styled.View`
  max-height: ${(props) => (props.isMobile ? '35%' : '146px')};
  backgroundColor: ${colors.gray.background};
`;

const CrewTouchable = Styled.Touchable`
  padding-vertical: ${(props) => (props.responsive.mobile ? '12px' : '8px')};
  background-color: ${(props) => (props.isHighlighted ? colors.hover : colors.gray.background)};
  flex-direction: row;
  align-items: center;
`;

const RadioButtonOutline = Styled.View`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  border-width: 1px;
  border-color: ${colors.gray.tertiary}
  align-items: center;
  justify-content: center;
`;

const RadioButtonCenter = Styled.View`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: ${(props) => (props.isSelected ? colors.blue.interactive : 'transparent')};
`;

const RadioButtonText = Styled.Text`
  ${Typography.Body3}
`;

const HeaderSection = Styled.View`
  background-color: ${colors.white};
`;

const HeaderRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 12px;
`;

const Title = Styled.Text`
  ${Typography.Label2}
`;

const Subtitle = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.tertiary};
`;

const TrucksAndSlotsListContainer = Styled.View`
  background-color: ${colors.gray.background};
  flex: 1;
`;

const OptionMessageContainer = Styled.ButtonV2`
  background-color: ${colors.orange.accent};
  border-radius: 4px;
  margin-horizontal: 12px;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  flex-direction: row;
  align-items: center;
`;

const OptionMessage = Styled.Text`
  ${Typography.Body4}
  color: ${colors.orange.status};
`;

const Checkbox = Styled.View`;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  border-width: 1px;
  border-color: ${colors.orange.status};
  align-items: center;
  justify-content: center;
  background-color: ${({color}) => color};
`;

const NoContractorText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.tertiary};
`;

const handleToggleAssignTrucksToAllCrews = ({form}) => {
  const field = 'createCrewWithSlotsAndTrucksForm.setCrewSlotsToCrewForm.assignTrucksToAllCrews';
  const assignTrucksToAllCrews = _.get(form.values, field);
  form.setFieldValue(field, !assignTrucksToAllCrews);
};

const CreateCrewWithTrucksAndSlotsPopoverContent = ({
  job,
  handleClose,
  refetch,
  shouldRunUpsertCostsAndBillingEngineAsync,
}) => {
  const createCrewWithSlotsAndTrucksForm = CreateCrewWithSlotsAndTrucksForm.edit(job, {
    shouldRunUpsertCostsAndBillingEngineAsync,
  });
  const {form, submitting, handleSubmit} = useCreateCrewWithSlotsAndTrucksMutation({
    createCrewWithSlotsAndTrucksForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  const crewFormField = 'createCrewWithSlotsAndTrucksForm.crewForm';
  const crewSlotFormsField =
    'createCrewWithSlotsAndTrucksForm.setCrewSlotsToCrewForm.crewSlotForms';
  const hasTooManySelections = _.get(form.values, crewSlotFormsField).length > job.numberOfTrucks;

  return (
    <React.Fragment>
      <Line />
      <CrewSection job={job} form={form} />
      <Line />
      <AssignTrucksSection
        job={job}
        form={form}
        crewFormField={crewFormField}
        crewSlotFormsField={crewSlotFormsField}
        hasTooManySelections={hasTooManySelections}
      />
      <ResponsivePopover.Footer
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        handleSubmitText={'Assign'}
        disabled={submitting}
        loading={submitting}
      />
      <Space height={12} />
    </React.Fragment>
  );
};

const CrewSection = ({job, form}) => {
  const responsive = useResponsive();
  const sortedOrganizations = _.sortBy(job.company.organizations, ['name']);

  return (
    <CrewSectionContainer isMobile={responsive.mobile}>
      <ScrollView>
        {sortedOrganizations.map((organization, index) => (
          <React.Fragment key={organization.id}>
            {index > 0 && <Space height={2} />}
            <CrewItem organization={organization} form={form} />
          </React.Fragment>
        ))}
      </ScrollView>
    </CrewSectionContainer>
  );
};

const CrewItem = ({organization, form}) => {
  const {ref, isHovered} = useHover();
  const responsive = useResponsive();
  const isSelected =
    String(form.values.createCrewWithSlotsAndTrucksForm.crewForm.organizationId) ===
    organization.id;
  const handleOnSelect = () => {
    form.setFieldValue(`createCrewWithSlotsAndTrucksForm.crewForm.organizationId`, organization.id);
    form.setFieldValue(
      `createCrewWithSlotsAndTrucksForm.setCrewSlotsToCrewForm`,
      SetCrewSlotsToCrewForm.new(),
    );
  };

  return (
    <CrewTouchable
      ref={ref}
      isHighlighted={isSelected || isHovered}
      onPress={handleOnSelect}
      responsive={responsive}
    >
      <Space width={16} />
      <RadioButtonOutline>
        <RadioButtonCenter isSelected={isSelected} />
      </RadioButtonOutline>
      <Space width={8} />
      <RadioButtonText>{organization.name}</RadioButtonText>
    </CrewTouchable>
  );
};

const TrucksAndSlotsSection = ({job, crewSlotFormsField, form}) => {
  const {organizationId} = form.values.createCrewWithSlotsAndTrucksForm.crewForm;
  const responsive = useResponsive();

  return (
    <TrucksAndSlotsListContainer>
      <TrucksAndSlotsList
        organizationId={organizationId}
        job={job}
        field={crewSlotFormsField}
        form={form}
        responsive={responsive}
      />
    </TrucksAndSlotsListContainer>
  );
};

const AssignToAllJobsOption = ({form}) => {
  const {assignTrucksToAllCrews} =
    form.values.createCrewWithSlotsAndTrucksForm.setCrewSlotsToCrewForm;

  return (
    <OptionMessageContainer onPress={() => handleToggleAssignTrucksToAllCrews({form})}>
      <Checkbox color={assignTrucksToAllCrews ? colors.orange.status : colors.orange.accent}>
        <Icon source={Icon.Check} size={10} color={colors.orange.accent} />
      </Checkbox>
      <Space width={8} />
      <OptionMessage>Assign selected truck(s) to all jobs</OptionMessage>
    </OptionMessageContainer>
  );
};

const NoContractorMessage = () => {
  return (
    <Container style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <NoContractorText>Select a contractor to see trucks</NoContractorText>
    </Container>
  );
};

const TooManySelectionsWarning = () => {
  return (
    <React.Fragment>
      <Space height={8} />
      <SystemMessage isWarning>You've selected more trucks than required.</SystemMessage>
      <Space height={8} />
    </React.Fragment>
  );
};

const AssignTrucksSection = ({
  job,
  form,
  crewFormField,
  crewSlotFormsField,
  hasTooManySelections,
}) => {
  const numberOfSelectedTrucks = _.get(form.values, crewSlotFormsField).length;
  const hasContractor = !!_.get(form.values, `${crewFormField}.organizationId`);
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <HeaderSection>
        <Space height={10} />
        <HeaderRow>
          <Title>{`Assign Trucks ${numberOfSelectedTrucks}/${job.numberOfTrucks}`}</Title>
          <Subtitle>Optional</Subtitle>
        </HeaderRow>
        <Space height={10} />
      </HeaderSection>
      <Line />
      <Container style={responsive.mobile ? {flex: 1} : {height: 200}}>
        {hasContractor ? (
          <React.Fragment>
            <TrucksAndSlotsSection job={job} form={form} crewSlotFormsField={crewSlotFormsField} />
            <Space height={12} />
            <AssignToAllJobsOption form={form} />
            {hasTooManySelections ? <TooManySelectionsWarning /> : <Space height={12} />}
          </React.Fragment>
        ) : (
          <NoContractorMessage />
        )}
      </Container>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
CreateCrewWithTrucksAndSlotsPopoverContent.propTypes = {
  job: PropTypes.object.isRequired,
};

CreateCrewWithTrucksAndSlotsPopoverContent.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCrewWithTrucksAndSlotsPopoverContent.fragment = gql`
  ${TrucksAndSlotsList.fragment}
  ${CreateCrewWithSlotsAndTrucksForm.edit.fragment}

  fragment CreateCrewWithTrucksAndSlotsPopoverContent on Job {
    id
    numberOfTrucks
    company {
      id
      organizations {
        id
        name
      }
    }
    ...TrucksAndSlotsList
    ...CreateCrewWithSlotsAndTrucksForm_edit
  }
`;

export default CreateCrewWithTrucksAndSlotsPopoverContent;
