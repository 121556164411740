// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Percent, withFragment} from '@supermove/utils';

// App
import CostItemUnit from '@shared/modules/Billing/enums/CostItemUnit';

const _new = ({moverPositionId}: any) => ({
  costItemTypeRateId: null,
  moverPositionId,
  rate: '',

  // Private
  unit: null,
});

const edit = withFragment(
  (costItemTypeRate) => ({
    costItemTypeRateId: (costItemTypeRate as any).id,
    moverPositionId: (costItemTypeRate as any).moverPositionId,
    rate: (costItemTypeRate as any).rate,
    // Private
    unit: null,
  }),
  gql`
    fragment CostItemTypeRateForm_edit on CostItemTypeRate {
      id
      moverPositionId
      rate
    }
  `,
);

const toForm = ({
  costItemTypeRateId,
  moverPositionId,
  rate,

  // Private
  unit,
}: any) => {
  return {
    costItemTypeRateId,
    moverPositionId,
    rate: unit === CostItemUnit.PERCENT ? Percent.toForm(rate) : Currency.toForm(rate),
  };
};

const _toMutationRate = ({rate, unit}: any) => {
  if (rate) {
    return unit === CostItemUnit.PERCENT ? Percent.toMutation(rate) : Currency.toMutation(rate);
  } else {
    return 0;
  }
};

const toMutation = ({
  costItemTypeRateId,
  moverPositionId,
  rate,

  // Private
  unit,
}: any) => {
  return {
    costItemTypeRateId,
    moverPositionId,
    rate: _toMutationRate({rate, unit}),
  };
};

const CostItemTypeRateForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default CostItemTypeRateForm;
