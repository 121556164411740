// Libararies
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import TripStatusForm from '@shared/modules/Dispatch/forms/TripStatusForm';
import useUpdateTripStatusMutation from '@shared/modules/Dispatch/hooks/useUpdateTripStatusMutation';
import StatusDropdown from 'modules/Dispatch/LongDistance/components/StatusDropdown';

const EditTripStatusDropdown = ({trip, onSuccess, size}) => {
  const tripStatusForm = TripStatusForm.edit(trip);
  const {form, handleSubmit} = useUpdateTripStatusMutation({
    tripStatusForm,
    onSuccess: ({trip}) => {
      onSuccess(trip);
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <StatusDropdown
      options={TripStatus.OPTIONS}
      initialValue={form.values.tripStatusForm.status}
      onSelect={(option) => {
        form.setFieldValue('tripStatusForm.status', option.value);
        setTimeout(() => handleSubmit(), 0);
      }}
      size={size}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditTripStatusDropdown.fragment = gql`
  ${TripStatusForm.edit.fragment}

  fragment EditTripStatusDropdown on Trip {
    id
    ...TripStatusForm_edit
  }
`;

// --------------------------------------------------
// Props
// --------------------------------------------------
EditTripStatusDropdown.propTypes = {
  trip: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

EditTripStatusDropdown.defaultProps = {
  onSuccess: () => {},
};

export default EditTripStatusDropdown;
