// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Currency} from '@supermove/utils';

export interface JobUserFormType {
  jobUserId?: number;
  jobId: number;
  crewId: number;
  userId: number;
  position: string;
  branchCode: string;
  tipAmount: number;
  moverPositionIds: number[];
  primaryMoverPositionId: number | null;

  // Private
  fullName: string;
}

export interface JobUserFormTypeForm {
  jobUserId?: number;
  jobId: number;
  crewId: number;
  userId: number;
  position: string;
  branchCode: string;
  tipAmount: string;
  moverPositionIds: number[];
  primaryMoverPositionId: number | null;

  // Private
  fullName: string;
}

export interface JobUserFormTypeMutation {
  jobUserId?: number;
  jobId: number;
  crewId: number;
  userId: number;
  position: string;
  branchCode: string;
  tipAmount: number;
  moverPositionIds: number[];
  primaryMoverPositionId: number | null;
}

const edit = withFragment(
  (jobUser: any): JobUserFormType => ({
    jobUserId: jobUser.id,
    jobId: jobUser.jobId,
    crewId: jobUser.crewId,
    userId: jobUser.userId,
    position: jobUser.position,
    branchCode: jobUser.branchCode,
    tipAmount: jobUser.tipAmount,
    moverPositionIds: jobUser.jobUserMoverPositions.map(
      (jobUserMoverPosition: any) => jobUserMoverPosition.moverPositionId,
    ),
    primaryMoverPositionId: jobUser.jobUserMoverPositions.find(
      (jobUserMoverPosition: any) => jobUserMoverPosition.isPrimary,
    )?.moverPositionId,

    // Only for rendering.
    fullName: jobUser.user.fullName,
  }),
  gql`
    fragment JobUserForm_edit on JobUser {
      id
      jobId
      crewId
      userId
      position
      branchCode
      tipAmount
      user {
        id
        fullName
      }
      jobUserMoverPositions {
        id
        isPrimary
        moverPositionId
      }
    }
  `,
);

const _new = ({
  jobId,
  crewId,
  userId,
  fullName,
  position,
  branchCode,
  moverPositionIds,
  primaryMoverPositionId,
}: JobUserFormType) => ({
  jobUserId: null,
  jobId,
  crewId,
  userId,
  position,
  branchCode,
  moverPositionIds,
  primaryMoverPositionId,
  tipAmount: 0,

  // Private
  fullName,
});

const toForm = ({
  jobUserId,
  jobId,
  crewId,
  userId,
  fullName,
  position,
  branchCode,
  moverPositionIds,
  primaryMoverPositionId,
  tipAmount,
}: JobUserFormType): JobUserFormTypeForm => ({
  jobUserId,
  jobId,
  crewId,
  userId,
  position,
  branchCode,
  moverPositionIds,
  primaryMoverPositionId,
  tipAmount: Currency.toForm(tipAmount),

  // Private
  fullName,
});

const toMutation = ({
  jobUserId,
  jobId,
  crewId,
  userId,
  position,
  branchCode,
  moverPositionIds,
  primaryMoverPositionId,
  tipAmount,
}: JobUserFormTypeForm): JobUserFormTypeMutation => ({
  jobUserId,
  jobId,
  crewId,
  userId,
  position,
  branchCode,
  moverPositionIds,
  primaryMoverPositionId,
  tipAmount: Currency.toMutation(tipAmount),
});

const JobUserForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default JobUserForm;
