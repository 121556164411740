// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Icon, Space, PreventPropagation} from '@supermove/components';
import {useDebouncedCallback, useEffect, useHover, useResponsive} from '@supermove/hooks';
import {DocumentItemType, DocumentItemSectionType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import IconButton from '@shared/design/components/IconButton';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import {
  SelectedDocumentItemType,
  SetSelectedDocumentItemType,
  DocumentContentJsonType,
  SetDocumentContentJsonType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import DocumentTemplateDocumentItemActions from 'modules/Organization/Settings/Document/components/DocumentTemplateDocumentItemActions';

const DocumentItemCardButton = Styled.ButtonV2<{isSelected: boolean; isHovered?: boolean}>`
  flex: 1;
  flex-direction: row;
  align-items: center;
  border-width: 1px;
  border-radius: 6px;
  border-color: ${({isSelected, isHovered}) => (isSelected ? colors.blue.interactive : isHovered ? colors.blue.hover : colors.gray.border)};
  background-color: ${({isSelected}) => (isSelected ? colors.blue.accent : colors.white)};
  padding-horizontal: 16px;
  padding-vertical: 12px;
  min-height: 60px;
`;

const CardDetailsContainer = Styled.View`
  justify-content: center;
  flex: 1;
`;

const CardLabel = Styled.Text`
  ${Typography.Responsive.Label}
`;

const CardDescription = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const getSectionHasName = (
  documentItem: DocumentItemType,
): documentItem is DocumentItemSectionType => {
  return 'name' in documentItem;
};

const getIsSection = (documentItem: DocumentItemType): documentItem is DocumentItemSectionType => {
  return (
    'name' in documentItem &&
    'documentItems' in documentItem &&
    documentItem.itemKind === DocumentItemKindV2.SECTION
  );
};

const getDocumentItemDisplayName = ({
  documentItem,
  isFullyEnabledTimesheetsV2,
}: {
  documentItem: DocumentItemType;
  isFullyEnabledTimesheetsV2: boolean;
}) => {
  if (getSectionHasName(documentItem)) {
    return documentItem.name;
  }

  return DocumentItemKindV2.getItemDisplayName({
    itemKind: documentItem.itemKind,
    isFullyEnabledTimesheetsV2,
  });
};

const getDocumentItemDescription = (documentItem: DocumentItemType) => {
  const isSection = getIsSection(documentItem);
  if (isSection) {
    const hasColumns = documentItem.documentItems[0]?.itemKind === DocumentItemKindV2.COLUMN;
    if (hasColumns) {
      const columns = documentItem.documentItems;
      const totalDocumentItemsInAllColumns = _.sumBy(columns, (column) =>
        'documentItems' in column ? column.documentItems.length : 0,
      );
      return `2 columns, ${totalDocumentItemsInAllColumns} document items`;
    }

    const totalDocumentItemsInSection = documentItem.documentItems.length;
    return `1 column, ${totalDocumentItemsInSection} document items`;
  }
  // For plain text items, returns the text
  // For rich text items, returns the first line of text without html tags
  // Header and paragraph tags are always considered a full line
  if ('text' in documentItem) {
    return documentItem.text
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<\/?p[^>]*>/gi, '\n')
      .replace(/<\/?h[1-6][^>]*>/gi, '\n')
      .replace(/^\s*\n/gm, '')
      .replace(/<\/?[^>]+(>|$)/g, '')
      .split('\n')[0];
  }

  return '';
};

const DocumentItemCard = ({
  documentItem,
  draftDocumentContentJson,
  setDraftDocumentContentJson,
  setSelectedDocumentItem,
  selectedDocumentItem,
  index,
  columnIndex,
  hoveredSectionIndex,
  setHoveredSectionIndex,
  isFullyEnabledTimesheetsV2,
}: {
  documentItem: DocumentItemType;
  draftDocumentContentJson: DocumentContentJsonType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  selectedDocumentItem: SelectedDocumentItemType;
  index: number;
  columnIndex?: number;
  hoveredSectionIndex?: number;
  setHoveredSectionIndex?: (index?: number) => void;
  isFullyEnabledTimesheetsV2: boolean;
}) => {
  const responsive = useResponsive();
  const isSelected =
    selectedDocumentItem &&
    selectedDocumentItem.index === index &&
    selectedDocumentItem.columnIndex === columnIndex;
  const documentItemDescription = getDocumentItemDescription(documentItem);
  const isSection = getIsSection(documentItem);
  const documentItemName = getDocumentItemDisplayName({documentItem, isFullyEnabledTimesheetsV2});
  const {ref, isHovered} = useHover();

  const resetHoveredSectionIndex = useDebouncedCallback(() => {
    if (!isHovered && hoveredSectionIndex === index) {
      setHoveredSectionIndex?.(undefined);
    }
  }, 250);
  useEffect(() => {
    if (isSection && setHoveredSectionIndex) {
      if (isHovered && hoveredSectionIndex !== index) {
        setHoveredSectionIndex(index);
      } else if (!isHovered && hoveredSectionIndex === index) {
        resetHoveredSectionIndex();
      }
    }
  }, [isSection, isHovered, setHoveredSectionIndex, index]);

  return (
    <DocumentItemCardButton
      ref={isSection ? ref : undefined}
      isSelected={isSelected}
      isHovered={isHovered}
      onPress={() => {
        // Handle selecting a section
        if (isSection) {
          setHoveredSectionIndex?.(undefined);
          return setSelectedDocumentItem({...selectedDocumentItem, sectionIndex: index});
        }

        // Handle selecting or deselecting a document item
        setSelectedDocumentItem({
          ...selectedDocumentItem,
          documentItem: isSelected ? undefined : documentItem,
          index: isSelected ? undefined : index,
          columnIndex,
        });
      }}
    >
      <CardDetailsContainer>
        <CardLabel responsive={responsive}>{documentItemName}</CardLabel>
        {!!documentItemDescription && (
          <React.Fragment>
            <Space height={4} />
            <CardDescription numberOfLines={1} responsive={responsive}>
              {documentItemDescription}
            </CardDescription>
          </React.Fragment>
        )}
      </CardDetailsContainer>
      <Space width={8} />
      {isSection ? (
        <IconButton source={Icon.AngleRight} isSecondary isDisplayOnly />
      ) : (
        <PreventPropagation>
          <DocumentTemplateDocumentItemActions
            draftDocumentContentJson={draftDocumentContentJson}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
            setSelectedDocumentItem={setSelectedDocumentItem}
            selectedDocumentItem={selectedDocumentItem}
            documentItemName={documentItemName}
            index={index}
            columnIndex={columnIndex}
          />
        </PreventPropagation>
      )}
    </DocumentItemCardButton>
  );
};

export default DocumentItemCard;
