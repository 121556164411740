// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Drawer, Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import CompensationReportView from 'modules/Accounting/CompensationReport/components/CompensationReportView';

const Button = Styled.ButtonV2`
  padding: 6px;
`;

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.border};
  width: ${({responsive}) => (responsive.mobile ? '100%' : '635px')};
`;

const LoadingContainer = Styled.View`
  flex: 1;
  background-color: ${colors.white};
  justify-content: center;
`;

const LoadingIndicator = Styled.Loading`
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
  padding-horizontal: 16px;
`;

const HeaderSideSection = Styled.View`
  flex-direction: row;
  width: 30px;
`;

const HeaderText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.primary};
`;

const CompensationReportContainer = Styled.View`
  flex: 1;
`;

const Header = ({
  compensationReports,
  viewingCompensationReportIndex,
  setViewingCompensationReportIndex,
  handleClose,
}) => {
  const isFirstCompensationReport = viewingCompensationReportIndex === 0;
  const isLastCompensationReport =
    viewingCompensationReportIndex === compensationReports.length - 1;
  const currentCompensationReport = compensationReports[viewingCompensationReportIndex];
  const {fullName, position} = currentCompensationReport.user;

  return (
    <HeaderContainer>
      <HeaderSideSection>
        <Button
          onPress={() => setViewingCompensationReportIndex(viewingCompensationReportIndex - 1)}
          disabled={isFirstCompensationReport}
        >
          <Icon
            source={Icon.AngleLeft}
            size={16}
            color={isFirstCompensationReport ? colors.gray.tertiary : colors.blue.interactive}
          />
        </Button>
        <Space width={2} />
        <Button
          onPress={() => setViewingCompensationReportIndex(viewingCompensationReportIndex + 1)}
          disabled={isLastCompensationReport}
        >
          <Icon
            source={Icon.AngleRight}
            size={16}
            color={isLastCompensationReport ? colors.gray.tertiary : colors.blue.interactive}
          />
        </Button>
      </HeaderSideSection>
      <HeaderText>{`${fullName}${position ? `, ${position}` : ''}`}</HeaderText>
      <HeaderSideSection style={{justifyContent: 'flex-end'}}>
        <Button onPress={handleClose}>
          <Icon source={Icon.Times} size={16} color={colors.gray.primary} />
        </Button>
      </HeaderSideSection>
    </HeaderContainer>
  );
};

const CompensationReportLoading = () => {
  return (
    <LoadingContainer>
      <LoadingIndicator />
    </LoadingContainer>
  );
};

const CompensationReportsDrawerContent = ({
  compensationReports,
  viewingCompensationReportIndex,
  setViewingCompensationReportIndex,
  handleClose,
}) => {
  const responsive = useResponsive();
  const compensationReportUuid = _.get(
    compensationReports,
    `${viewingCompensationReportIndex}.uuid`,
  );
  const {loading, data} = useQuery(CompensationReportsDrawer.query, {
    fetchPolicy: 'network-only',
    variables: {compensationReportUuid},
  });

  return (
    <Container responsive={responsive}>
      <Header
        compensationReports={compensationReports}
        viewingCompensationReportIndex={viewingCompensationReportIndex}
        setViewingCompensationReportIndex={setViewingCompensationReportIndex}
        handleClose={handleClose}
      />
      <CompensationReportContainer>
        <ScrollView contentContainerStyle={{flex: 1, paddingHorizontal: 20}}>
          <Space height={16} />
          <Loading loading={loading} as={CompensationReportLoading}>
            {() => <CompensationReportView compensationReport={data.compensationReport} />}
          </Loading>
        </ScrollView>
      </CompensationReportContainer>
    </Container>
  );
};

const CompensationReportsDrawer = ({
  viewingCompensationReportIndex,
  setViewingCompensationReportIndex,
  compensationReports,
  isOpen,
  handleClose,
}) => {
  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      <CompensationReportsDrawerContent
        compensationReports={compensationReports}
        viewingCompensationReportIndex={viewingCompensationReportIndex}
        setViewingCompensationReportIndex={setViewingCompensationReportIndex}
        handleClose={handleClose}
      />
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompensationReportsDrawer.query = gql`
  ${CompensationReportView.fragment}
  query CompensationReport($compensationReportUuid: String!) {
    ${gql.query}
    compensationReport(compensationReportUuid: $compensationReportUuid) {
      id
      ...CompensationReportView
    }
  }
`;

CompensationReportsDrawer.fragment = gql`
  fragment CompensationReportsDrawer on CompensationReport {
    id
    uuid
    user {
      id
      fullName
      position
    }
  }
`;

export default CompensationReportsDrawer;
