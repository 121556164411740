// Libraries
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const MUTATION = gql`
  mutation SendJobDetailsToSpecificJobUsersMutation(
    $jobId: Int!,
    $jobUserIds: [Int!]!
    $creatorId: Int!,
  ) {
    response: sendJobDetailsToSpecificJobUsers(
      jobId: $jobId,
      jobUserIds: $jobUserIds
      creatorId: $creatorId,
    ) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

const useSendJobDetailsToSpecificJobUsersForm = ({
  jobId,
  jobUserIds,
  creatorId,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      jobId,
      jobUserIds,
      creatorId,
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: MUTATION,
    variables: {
      jobId: form.values.jobId,
      jobUserIds: form.values.jobUserIds,
      creatorId: form.values.creatorId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

export default useSendJobDetailsToSpecificJobUsersForm;
