/*
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import CrewUsersAndDriversSchedulesListItem from 'modules/Dispatch/Crew/components/CrewUsersAndDriversSchedulesListItem';

const ScheduleDayLabelsContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  background-color: ${colors.white};
  padding-top: 5px;
  padding-bottom: 4px;
`;

const ScheduleDay = Styled.H8`
  font-size: 11px;
  ${fontWeight(700)}
  color: ${(props) => props.color};
  width: 16px;
  text-align: center;
`;

const ScheduleDayLabels = () => {
  return (
    <ScheduleDayLabelsContainer>
      <ScheduleDay color={colors.gray.primary}>M</ScheduleDay>
      <ScheduleDay color={colors.gray.primary}>T</ScheduleDay>
      <ScheduleDay color={colors.gray.primary}>W</ScheduleDay>
      <ScheduleDay color={colors.gray.primary}>Th</ScheduleDay>
      <ScheduleDay color={colors.gray.primary}>F</ScheduleDay>
      <ScheduleDay color={colors.gray.primary}>S</ScheduleDay>
      <ScheduleDay color={colors.gray.primary}>Su</ScheduleDay>
      <Space width={20} />
    </ScheduleDayLabelsContainer>
  );
};

const CrewUsersAndDriversSchedulesList = ({
  crew,
  selectedUsers,
  userSchedules,
  handleAddUserId,
  handleRemoveUserId,
}) => {
  const selectedUserIds = selectedUsers.map((user) => String(user.id));
  return (
    <FlatList
      data={userSchedules}
      keyExtractor={(userSchedule) => String(userSchedule.user.id)}
      renderItem={({item: userSchedule}) => {
        return (
          <CrewUsersAndDriversSchedulesListItem
            crew={crew}
            userSchedule={userSchedule}
            isSelected={selectedUserIds.includes(userSchedule.user.id)}
            handleAddUser={() => handleAddUserId(userSchedule.user.id)}
            handleRemoveUser={() => handleRemoveUserId(userSchedule.user.id)}
          />
        );
      }}
      ListHeaderComponent={() => <ScheduleDayLabels />}
      stickyHeaderIndices={[0]}
      // Reducing windowSize makes selecting and unselecting much faster,
      // but when scrolling fast it will feel choppy
      windowSize={3}
      // The following three props should help optimize this flatlist, but
      // compared to windowSize the felt difference is very little
      maxToRenderPerBatch={7}
      initialNumberToRender={7}
      removeClippedSubviews
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewUsersAndDriversSchedulesList.fragment = gql`
  ${CrewUsersAndDriversSchedulesListItem.fragment}
  fragment CrewUsersAndDriversSchedulesList_Crew on Crew {
    id
    ...CrewUsersAndDriversSchedulesListItem_Crew
  }

  fragment CrewUsersAndDriversSchedulesList_EmployeeSchedule on EmployeeSchedule {
    user {
      id
    }
    ...CrewUsersAndDriversSchedulesListItem_EmployeeSchedule
  }
`;

export default CrewUsersAndDriversSchedulesList;
