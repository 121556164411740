// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import {gql} from '@supermove/graphql';

// App
import Pill from 'components/Pill';

const ProjectTypeDayEstimateItem = ({projectTypeDay, isMultiBranch}) => {
  return (
    <Pill
      color={projectTypeDay.projectType.color}
      count={projectTypeDay.estimatesCount}
      text={
        isMultiBranch
          ? `${projectTypeDay.projectType.name} (${projectTypeDay.projectType.organization.name})`
          : projectTypeDay.projectType.name
      }
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ProjectTypeDayEstimateItem.propTypes = {
  projectTypeDay: PropTypes.object.isRequired,
};

ProjectTypeDayEstimateItem.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeDayEstimateItem.fragment = gql`
  fragment ProjectTypeDayEstimateItem on ProjectTypeDay {
    id
    estimatesCount
    projectType {
      id
      color
      name
      organization {
        id
        name
      }
    }
  }
`;

export default ProjectTypeDayEstimateItem;
