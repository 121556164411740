// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import TripForm from '@shared/modules/Dispatch/forms/TripForm';

const useCreateTripMutation = ({tripForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      tripForm: TripForm.toForm(tripForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateTripMutation.mutation,
    variables: {
      tripForm: TripForm.toMutation(form.values.tripForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateTripMutation.mutation = gql`
  mutation useCreateTripMutation($tripForm: TripForm!) {
    response: createTrip(tripForm: $tripForm) {
      ${gql.errors}
      trip {
        id
        uuid
      }
    }
  }
`;

export default useCreateTripMutation;
