// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Percent} from '@supermove/utils';

export interface SalesTaxTemplateFormType {
  salesTaxTemplateId: string | null;
  rate: string;
  variableId: number | null;
  projectTypeId: string | null;
  settingsId: string | null;
}

export interface SalesTaxTemplateMutationType {
  salesTaxTemplateId: string | null;
  rate: string | null;
  variableId: string | null;
  projectTypeId: string | null;
  settingsId: string | null;
}

const _new = ({rate, variableId, projectTypeId, settingsId}: SalesTaxTemplateFormType) => ({
  salesTaxTemplateId: null,
  rate,
  variableId,
  projectTypeId,
  settingsId,
});

const edit = withFragment(
  (salesTaxTemplate: any, {projectTypeId = null} = {}) => ({
    salesTaxTemplateId: salesTaxTemplate.id,
    rate: salesTaxTemplate.rate,
    variableId: salesTaxTemplate.variableId,
    projectTypeId: projectTypeId || salesTaxTemplate.projectTypeId,
    settingsId: salesTaxTemplate.settingsId,
  }),
  gql`
    fragment SalesTaxTemplateForm_edit on SalesTaxTemplate {
      id
      rate
      variableId
      projectTypeId
      settingsId
    }
  `,
);

const toForm = ({
  salesTaxTemplateId,
  rate,
  variableId,
  projectTypeId,
  settingsId,
}: SalesTaxTemplateFormType) => ({
  salesTaxTemplateId,
  rate: Percent.toForm(rate),
  variableId: variableId ? _.toString(variableId) : null,
  projectTypeId: projectTypeId ? _.toString(projectTypeId) : null,
  settingsId: settingsId ? _.toString(settingsId) : null,
});

const toMutation = ({
  salesTaxTemplateId,
  rate,
  variableId,
  projectTypeId,
  settingsId,
}: SalesTaxTemplateMutationType) => ({
  salesTaxTemplateId,
  rate: rate ? Percent.toMutation(rate) : null,
  variableId,
  projectTypeId,
  settingsId,
});

const SalesTaxTemplateForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default SalesTaxTemplateForm;
