// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const SignConfirmationDocumentMutation = ({
  values,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}) => {
  return (
    <Mutation
      variables={{
        confirmationId: values.confirmationId,
        date: values.date,
        image: values.image,
      }}
      mutation={SignConfirmationDocumentMutation.mutation}
      onCompleted={({response: {confirmation, errors}}) => {
        submitForm();
        setMutationErrors(errors);

        if (!errors) {
          onSuccess(confirmation);
        } else if (onError) {
          onError(errors);
        }
      }}
    >
      {(handleSubmit, {loading, error}) =>
        children({
          loading,
          handleSubmit,
        })
      }
    </Mutation>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
SignConfirmationDocumentMutation.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  setMutationErrors: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

SignConfirmationDocumentMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SignConfirmationDocumentMutation.mutation = gql`
  mutation SignConfirmationDocumentMutation(
    $confirmationId: Int!,
    $date: String!,
    $image: String!,
  ) {
    response: signConfirmationDocument(
      confirmationId: $confirmationId,
      date: $date,
      image: $image,
    ) {
      ${gql.errors}
      confirmation {
        id
      }
    }
  }
`;

export default SignConfirmationDocumentMutation;
