// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import FileInputFieldV2 from '@shared/modules/File/components/FileInputFieldV2';
import SystemMessage from 'modules/App/components/SystemMessage';

const Row = Styled.View`
  flex-direction: row;
`;

const Requirement = ({children}) => {
  return (
    <Row>
      <SystemMessage.Text vars={{isWarning: true}}>•</SystemMessage.Text>
      <Space width={8} />
      <SystemMessage.Text vars={{isWarning: true}}>{children}</SystemMessage.Text>
    </Row>
  );
};

const Requirements = () => {
  return (
    <SystemMessage.Container vars={{isWarning: true}} style={{margin: 0}}>
      <Requirement>All rows and columns must have the same number of values.</Requirement>
      <Space height={4} />
      <Requirement>Existing categories of the same name will be replaced.</Requirement>
    </SystemMessage.Container>
  );
};

const ImportInventoryLibraryContentsModal = ({
  useUploadFileMutation,
  isSubmitting,
  handleSubmit,
  title,
  isOpen,
  handleClose,
  validationErrors,
}) => {
  return (
    <SmallModal isOpen={isOpen} style={{maxHeight: '722px'}}>
      <SmallModal.HeaderText>{title}</SmallModal.HeaderText>
      <Space height={16} />
      <Requirements />
      <Space height={12} />
      <FileInputFieldV2
        field={'uploadFileForm'}
        form={useUploadFileMutation.form}
        submitting={useUploadFileMutation.submitting}
        handleSubmit={useUploadFileMutation.handleSubmit}
        validationErrors={validationErrors}
        supportedFileTypes={['CSV']}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button
          onPress={handleClose}
          isDisabled={isSubmitting || useUploadFileMutation.submitting}
        >
          Cancel
        </SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          color={colors.blue.interactive}
          onPress={handleSubmit}
          isSubmitting={isSubmitting}
          isDisabled={useUploadFileMutation.submitting}
        >
          Upload
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default ImportInventoryLibraryContentsModal;
