// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ClaimStatusForm from '@shared/modules/Claim/forms/ClaimStatusForm';
import useUpdateClaimStatusMutation from '@shared/modules/Claim/hooks/useUpdateClaimStatusMutation';
import ClaimStatusFields from 'modules/Organization/Settings/Company/components/ClaimStatusFields';

const UpdateClaimStatusHeader = () => {
  return (
    <React.Fragment>
      <SmallModal.HeaderText>Edit Claim Status</SmallModal.HeaderText>
      <Space height={16} />
    </React.Fragment>
  );
};

const UpdateClaimStatusFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <SmallModal.Footer>
      <SmallModal.Button
        textColor={colors.gray.secondary}
        onPress={handleClose}
        isDisabled={submitting}
      >
        Cancel
      </SmallModal.Button>
      <SmallModal.Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        isSubmitting={submitting}
        onPress={handleSubmit}
        isDisabled={submitting}
      >
        <Icon source={Icon.Check} color={colors.white} size={12} />
        <Space width={4} />
        Save
      </SmallModal.Button>
    </SmallModal.Footer>
  );
};

const UpdateClaimStatusModal = ({claimStatus, refetch, isOpen, handleClose}) => {
  const claimStatusForm = ClaimStatusForm.edit(claimStatus);
  const {form, submitting, handleSubmit} = useUpdateClaimStatusMutation({
    claimStatusForm,
    onSuccess: ({claimStatus}) => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose} style={{overflow: 'visible'}}>
      <UpdateClaimStatusHeader />
      <Space height={4} />
      <ClaimStatusFields claimStatus={claimStatus} form={form} field={'claimStatusForm'} />
      <Space height={16} />
      <UpdateClaimStatusFooter
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateClaimStatusModal.fragment = gql`
  ${ClaimStatusFields.fragment}
  ${ClaimStatusForm.edit.fragment}
  fragment UpdateClaimStatusModal on ClaimStatus {
    id
    ...ClaimStatusFields
    ...ClaimStatusForm_edit
  }
`;

export default UpdateClaimStatusModal;
