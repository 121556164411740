// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Currency} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ErrorModal from '@shared/design/components/Modal/SmallModal/ErrorModal';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import EditClientModal from 'modules/Client/Show/components/EditClientModal';
import InvoiceFields from 'modules/Storage/components/InvoiceFields';
import InvoiceSettingsFields from 'modules/Storage/components/InvoiceSettingsFields';
import InvoiceSuccessModal from 'modules/Storage/components/InvoiceSuccessModal';
import QuitEditingModal from 'modules/Storage/components/QuitEditingModal';

const Container = Styled.View`
  flex: 1;
`;

const BackButton = Styled.ButtonV2`
`;

const Row = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const StorageInvoiceHeaderContainer = Styled.View`
  padding: 24px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  height: 84px;
  width: 100%;
`;

const StorageInvoiceFooterContainer = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  height: 80px;
  width: 100%;
`;

const StorageInvoiceLeftPanel = Styled.View`
  flex: 1;
  width: 70%;
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const StorageInvoiceRightPanel = Styled.View`
  width: 30%;
`;

const StorageInvoiceFieldContainer = Styled.View`
  padding: 24px;
  background-color: ${colors.gray.background};
`;

const StorageInvoicePaddingContainer = Styled.View`
  padding: 24px;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading1};
`;

const LoadingButton = Styled.Loading`
`;

const getGrandTotal = (form) => {
  const billTotalsPreview = _.get(form.values, 'invoiceForm.billTotalsPreview');
  return Currency.display(
    _.reduce(
      billTotalsPreview,
      (grandTotal, billTotal) => {
        grandTotal += billTotal.total;
        return grandTotal;
      },
      0,
    ),
  );
};

const resetBillFormIndex = (form) => {
  const billForms = _.get(form.values, 'invoiceForm.billForms');
  for (const [index] of billForms.entries()) {
    form.setFieldValue(`invoiceForm.billForms[${index}].index`, index);
  }
};

const StorageInvoiceHeader = ({
  navigator,
  params,
  project,
  form,
  handleSubmit,
  invoiceSuccessModal,
  isEdit,
  invoice,
  draftButtonSubmitting,
  setDraftButtonSubmitting,
  isSubmitting,
}) => {
  const isToday = Datetime.isToday(_.get(form.values, 'invoiceForm.date'));
  const missingEmailModal = useModal({name: 'Invoice Missing Email Modal', enableTracking: true});
  const editClientModal = useModal({name: 'Edit Client Modal', enableTracking: true});
  const quitEditingInvoiceModal = useModal({
    name: 'Quit Editing Invoice Modal',
    enableTracking: true,
  });
  const {billingClientForm, clientForm} = form.values.invoiceForm;
  const resetParentForm = (editedClientForm) => {
    const field = billingClientForm ? 'invoiceForm.billingClientForm' : 'invoiceForm.clientForm';
    // Currently used in creating/editing invoices to update customer information display
    form.setFieldValue(`${field}`, editedClientForm.values.clientForm);
    if (_.get(form, 'values.invoiceForm.isBillingClientSameAsProjectClient')) {
      // There is always a billing client form in this case
      // Edit so that both billing client and client info are updated on invoice forms
      form.setFieldValue('invoiceForm.clientForm', editedClientForm.values.clientForm);
    }
  };

  const showSaveAsDraftButton =
    !isEdit || invoice.status === InvoiceStatus.DRAFT || invoice.status === InvoiceStatus.SCHEDULED;

  return (
    <StorageInvoiceHeaderContainer>
      <BackButton onPress={() => quitEditingInvoiceModal.handleOpen()}>
        <Icon source={Icon.ChevronLeft} color={colors.black} size={24} />
      </BackButton>
      <Space width={16} />
      <HeaderText>
        {isEdit
          ? `Edit Invoice ${invoice.identifier}`
          : `New Invoice for ${_.capitalize(params.projectKind)} Project ${project.identifier}`}
      </HeaderText>
      <Space style={{flex: 1}} />
      {showSaveAsDraftButton && (
        <React.Fragment>
          <SecondaryButton
            text={'Save as Draft'}
            isSubmitting={isSubmitting && draftButtonSubmitting}
            width={160}
            onPress={() => {
              setDraftButtonSubmitting(true);
              resetBillFormIndex(form);
              form.setFieldValue(`invoiceForm.status`, InvoiceStatus.DRAFT);
              setTimeout(() => handleSubmit(), 0);
            }}
          />
          <Space width={12} />
        </React.Fragment>
      )}
      <Button
        text={`${isEdit ? 'Update' : 'Create'} & ${isToday ? 'Send' : 'Schedule'}`}
        isSubmitting={isSubmitting && !draftButtonSubmitting}
        width={160}
        onPress={() => {
          setDraftButtonSubmitting(false);
          resetBillFormIndex(form);
          form.setFieldValue(`invoiceForm.status`, InvoiceStatus.SCHEDULED);
          // Before handling submit, check clientForm and billingClientForm for email address
          if (
            // If isBillingClientSameAsProjectClient is True, we just bypass the billing client form check
            (!_.get(form, 'values.invoiceForm.isBillingClientSameAsProjectClient') &&
              billingClientForm &&
              !billingClientForm.primaryContactForm.email) ||
            (clientForm && !clientForm.primaryContactForm.email)
          ) {
            missingEmailModal.handleOpen();
          } else {
            setTimeout(() => handleSubmit(), 0);
          }
        }}
      />
      <ErrorModal
        title={'Missing email address'}
        subtitle={`Please add an email address to ${
          billingClientForm ? 'Bill To Information' : 'Customer Information'
        }.`}
        isOpen={missingEmailModal.isOpen}
        closeButtonText={'Edit Customer'}
        handleClose={() => {
          missingEmailModal.handleClose();
          // Allow user to update the client
          if (
            (billingClientForm && billingClientForm.clientUuid) ||
            (clientForm && !clientForm.primaryContactForm.email)
          ) {
            editClientModal.handleOpen();
          }
        }}
      />
      <EditClientModal
        key={editClientModal.key}
        isOpen={editClientModal.isOpen}
        handleClose={editClientModal.handleClose}
        clientUuid={
          billingClientForm && billingClientForm.clientUuid
            ? billingClientForm.clientUuid
            : clientForm.clientUuid
        }
        refetch={() => {}}
        resetParentForm={resetParentForm}
        emailAddressRequired
      />
      <QuitEditingModal
        navigator={navigator}
        params={params}
        handleClose={quitEditingInvoiceModal.handleClose}
        isOpen={quitEditingInvoiceModal.isOpen}
        projectUuid={params.projectUuid}
      />
      <InvoiceSuccessModal
        form={form}
        field={'invoiceForm'}
        isOpen={invoiceSuccessModal.isOpen}
        projectUuid={params.projectUuid}
        isEdit={isEdit}
      />
    </StorageInvoiceHeaderContainer>
  );
};

const StorageInvoiceFooter = ({form, grandTotalLoading}) => {
  return (
    <StorageInvoiceFooterContainer>
      <Row style={{justifyContent: 'flex-end'}}>
        <HeaderText>Grand Total: </HeaderText>
        {grandTotalLoading ? (
          <LoadingButton loading={grandTotalLoading} />
        ) : (
          <HeaderText>{getGrandTotal(form)}</HeaderText>
        )}
        <Space width={24} />
      </Row>
    </StorageInvoiceFooterContainer>
  );
};

const InvoicePageContent = ({
  form,
  field,
  project,
  viewer,
  handleSubmit,
  invoiceSuccessModal,
  isEdit,
  invoice,
  draftButtonSubmitting,
  setDraftButtonSubmitting,
  isSubmitting,
}) => {
  const {navigator, params} = useNavigationDOM();

  const [grandTotalLoading, setGrandTotalLoading] = useState(false);

  return (
    <Container>
      <StorageInvoiceHeader
        form={form}
        navigator={navigator}
        params={params}
        project={project}
        handleSubmit={handleSubmit}
        invoiceSuccessModal={invoiceSuccessModal}
        isEdit={isEdit}
        invoice={invoice}
        isSubmitting={isSubmitting}
        draftButtonSubmitting={draftButtonSubmitting}
        setDraftButtonSubmitting={setDraftButtonSubmitting}
      />
      <Row>
        <StorageInvoiceLeftPanel>
          <ScrollView style={{flexGrow: 1}}>
            <StorageInvoiceFieldContainer>
              <HeaderText>Invoice Details</HeaderText>
              <Space height={24} />
              <InvoiceFields
                form={form}
                field={field}
                project={project}
                viewer={viewer}
                setGrandTotalLoading={setGrandTotalLoading}
                grandTotalLoading={grandTotalLoading}
              />
            </StorageInvoiceFieldContainer>
          </ScrollView>
          <StorageInvoiceFooter form={form} grandTotalLoading={grandTotalLoading} />
        </StorageInvoiceLeftPanel>
        <StorageInvoiceRightPanel>
          <StorageInvoicePaddingContainer style={{flex: 1}}>
            <HeaderText>Invoice Settings</HeaderText>
            <Space height={24} />
            <InvoiceSettingsFields
              form={form}
              field={field}
              project={project}
              allowRecurring={params.projectKind === 'storage'}
            />
          </StorageInvoicePaddingContainer>
        </StorageInvoiceRightPanel>
      </Row>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoicePageContent.fragment = gql`
  ${InvoiceFields.fragment}
  ${InvoiceSettingsFields.fragment}

  fragment InvoicePageContent on Project {
    id
    identifier
    organization {
      id
    }
    ...InvoiceFields
    ...InvoiceSettingsFields
  }
`;

export default InvoicePageContent;
