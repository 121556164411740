// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';
import useDuplicateWorkflowMutation from '@shared/modules/Workflow/hooks/useDuplicateWorkflowMutation';

const DuplicateWorkflowModal = ({workflow, isOpen, handleClose, onSuccess}) => {
  const duplicateWorkflowToast = useToast({
    ToastComponent: SuccessToast,
    message: `"${workflow.name}" duplicated`,
  });

  const workflowForm = WorkflowForm.duplicate({workflowId: workflow.id});
  const {handleSubmit, submitting} = useDuplicateWorkflowMutation({
    workflowForm,
    onSuccess: () => {
      onSuccess && onSuccess();
      duplicateWorkflowToast.handleToast();
      handleClose();
    },
    onError: (errors) => {},
  });

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={`Duplicate ${workflow.name}?`}
      subtitle={'This workflow will be disabled by default.'}
      primaryActionText={'Confirm'}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      handleSecondaryAction={handleClose}
      handlePressOutside={handleClose}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DuplicateWorkflowModal.fragment = gql`
  fragment DuplicateWorkflowModal on Workflow {
    id
    name
  }
`;

export default DuplicateWorkflowModal;
