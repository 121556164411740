// Supermove
import {useForm} from '@supermove/hooks';

// App
import ReportViewFiltersForm from 'modules/Report/Metabase/forms/ReportViewFiltersForm';

const useReportViewFiltersForm = ({reportViewFiltersForm}) => {
  const form = useForm({
    initialValues: {
      reportViewFiltersForm: ReportViewFiltersForm.toForm(reportViewFiltersForm),
    },
  });

  return {
    form,
  };
};

export default useReportViewFiltersForm;
