// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive, useIdleTimer, useTrackEvent} from '@supermove/hooks';
import {Device, Environment} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';

// App
import Banner from './Banner';

const Container = Styled.View`
  z-index: 1000;
`;

const Touchable = Styled.Touchable`
  margin-left: 6px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
  text-decoration-line: underline;
`;

const UpdateAppBanner = () => {
  const responsive = useResponsive();
  const {trackEvent} = useTrackEvent({
    eventName: `Refreshed Update App Banner`,
    eventType: 'background',
    pageName: '',
  });
  useIdleTimer({
    onIdle: () => {
      if (!Environment.isLocal()) {
        trackEvent();
        Device.restartApp();
      }
    },
  });
  return (
    <Container data-no-print>
      <Banner
        color={colors.blue.interactive}
        text={'A new update for Supermove is available.'}
        responsive={responsive}
      >
        <Touchable onPress={() => Device.restartApp()}>
          <Text>Press to Reload</Text>
        </Touchable>
      </Banner>
    </Container>
  );
};

export default UpdateAppBanner;
