export interface RequestUploadFileFormTypeWrapper {
  requestUploadFileForm: RequestUploadFileFormType;
}

export interface RequestUploadFileFormType {
  filename: string | null;
  mimetype: string | null;
  organizationId: string;
  creatorId: string;
}

const _new = ({
  organizationId,
  creatorId,
}: Pick<RequestUploadFileFormType, 'organizationId' | 'creatorId'>) => ({
  filename: null,
  mimetype: null,
  organizationId,
  creatorId,
});

const toForm = ({filename, mimetype, organizationId, creatorId}: RequestUploadFileFormType) => ({
  filename,
  mimetype,
  organizationId,
  creatorId,
});

const toMutation = ({
  filename,
  mimetype,
  organizationId,
  creatorId,
}: RequestUploadFileFormType) => ({
  filename,
  mimetype,
  organizationId,
  creatorId,
});

const RequestUploadFileForm = {
  new: _new,
  toForm,
  toMutation,
};

export default RequestUploadFileForm;
