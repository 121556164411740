// Supermove

// Supermove
import {uuid} from '@supermove/utils';

export interface InvokeWebhookActionFormType {
  workflowRunStepUuid: string;
  method: string;
  url: string;
  body: string;
  headers: string;
}

const _new = (): InvokeWebhookActionFormType => ({
  workflowRunStepUuid: uuid(),
  method: 'POST',
  url: 'https://',
  body: '',
  headers: '{"Content-Type": "application/json"}',
});

const edit = (invokeWebhookActionForm: any): InvokeWebhookActionFormType => ({
  workflowRunStepUuid: invokeWebhookActionForm.workflowRunStepUuid,
  method: invokeWebhookActionForm.method,
  url: invokeWebhookActionForm.url,
  body: invokeWebhookActionForm.body,
  headers: invokeWebhookActionForm.headers,
});

const toForm = (
  InvokeWebhookActionForm: InvokeWebhookActionFormType,
): InvokeWebhookActionFormType => ({
  workflowRunStepUuid: InvokeWebhookActionForm.workflowRunStepUuid,
  method: InvokeWebhookActionForm.method,
  url: InvokeWebhookActionForm.url,
  body: InvokeWebhookActionForm.body,
  headers: InvokeWebhookActionForm.headers,
});

const toMutation = (
  InvokeWebhookActionForm: InvokeWebhookActionFormType,
): InvokeWebhookActionFormType => ({
  workflowRunStepUuid: InvokeWebhookActionForm.workflowRunStepUuid,
  method: InvokeWebhookActionForm.method,
  url: InvokeWebhookActionForm.url,
  body: InvokeWebhookActionForm.body,
  headers: InvokeWebhookActionForm.headers,
});

const InvokeWebhookActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default InvokeWebhookActionForm;
