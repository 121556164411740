// Supermove
import {Currency} from '@supermove/utils';

// App
import ValueForm from '@shared/modules/Billing/forms/ValueForm';

const editFromBillItemForm = (
  // @ts-expect-error TS(7006): Parameter 'billItemForm' implicitly has an 'any' t... Remove this comment to see the full error message
  billItemForm,
  {organizationId, valueForms}: any,
) => ({
  organizationId,
  nameFormulaId: billItemForm.nameFormulaId,
  amountFormulaId: billItemForm.amountFormulaId,
  minQuantityFormulaId: billItemForm.minQuantityFormulaId,
  maxQuantityFormulaId: billItemForm.maxQuantityFormulaId,
  moverPositionId: billItemForm.moverPositionId,
  amount: billItemForm.amount,
  maxQuantity: billItemForm.maxQuantity,
  minQuantity: billItemForm.minQuantity,
  name: billItemForm.name,
  valueForms,
});

const toMutation = ({
  organizationId,
  nameFormulaId,
  amountFormulaId,
  minQuantityFormulaId,
  maxQuantityFormulaId,
  moverPositionId,
  amount,
  maxQuantity,
  minQuantity,
  name,
  valueForms,
}: any) => ({
  organizationId,
  nameFormulaId,
  amountFormulaId,
  minQuantityFormulaId,
  maxQuantityFormulaId,
  moverPositionId,
  amount: Currency.toMutation(amount),
  maxQuantity: Number(maxQuantity),
  minQuantity: Number(minQuantity),
  name,
  valueForms: valueForms.map((valueForm: any) => ValueForm.toMutation(valueForm)),
});

const PreviewBillItemForm = {
  editFromBillItemForm,
  toMutation,
};

export default PreviewBillItemForm;
