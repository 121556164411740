// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {DocumentItemType} from '@supermove/models';

import {
  DocumentContentJsonType,
  SetDocumentContentJsonType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import BlueCheckboxField from 'modules/App/components/BlueCheckboxField';

const InputIsRequiredCheckBox = ({
  documentItem,
  documentItemField,
  setDraftDocumentContentJson,
}: {
  documentItem: DocumentItemType & {isRequired: boolean};
  documentItemField: string;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
}) => {
  return (
    <BlueCheckboxField
      values={{isRequired: !!documentItem.isRequired}}
      name={'isRequired'}
      label={'Required input(s)'}
      setFieldValue={(name: string, value: boolean) => {
        setDraftDocumentContentJson((prevState: DocumentContentJsonType) => {
          _.set(prevState, `${documentItemField}.isRequired`, value);
          return {...prevState};
        });
      }}
    />
  );
};

export default InputIsRequiredCheckBox;
