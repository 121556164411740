// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, usePagination, useNavigationDOM} from '@supermove/hooks';
import {URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import TripsTable from 'modules/Dispatch/LongDistance/Trip/components/TripsTable';
import CreateTripWithShipmentsModal from 'modules/Dispatch/LongDistance/components/CreateTripWithShipmentsModal';

const getStatuses = ({subTabValue}) => {
  if (subTabValue === TripStatus.ACTIVE.toLowerCase()) {
    return [TripStatus.PENDING, TripStatus.PLANNED, TripStatus.IN_TRANSIT];
  }
  return [subTabValue.toUpperCase()];
};

const PaginationFooter = ({paginationMetadata, params, navigator}) => {
  const pagination = usePagination({
    currentPage: _.toNumber(params.page),
    paginationMetadata,
    onChangePage: (page) => {
      const {subTab, ...filters} = params;
      const variables = {...filters, page};
      navigator.push(URL.getUrlFromVariables(`/dispatch/long-distance/trips/${subTab}`, variables));
    },
  });

  return <PaginationBar pagination={pagination} />;
};

const LongDistanceDispatchTripsTable = ({createTripWithShipmentsModal, refetchTabData}) => {
  const {navigator, params} = useNavigationDOM();
  const {
    data,
    loading,
    refetch: refetchTableData,
  } = useQuery(LongDistanceDispatchTripsTable.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      statuses: getStatuses({subTabValue: params.subTab}),
      pagination: {
        page: params.page,
        resultsPerPage: 50,
      },
      originCity: params.originCity,
      originState: params.originState,
      destinationCity: params.destinationCity,
      destinationState: params.destinationState,
      fromStartDate: params.fromStartDate,
      toStartDate: params.toStartDate,
      fromEndDate: params.fromEndDate,
      toEndDate: params.toEndDate,
      searchQuery: params.query,
    },
  });

  return (
    <React.Fragment>
      <Loading loading={!data} as={PageLoadingIndicator}>
        {() => (
          <ScrollView horizontal contentContainerStyle={{flex: 1}}>
            <TripsTable
              trips={data.paginatedList.trips}
              refetchTabData={refetchTabData}
              refetchTableData={refetchTableData}
              loading={loading}
            />
          </ScrollView>
        )}
      </Loading>
      <Space height={16} />
      {data && (
        <PaginationFooter
          paginationMetadata={data.paginatedList.paginationMetadata}
          params={params}
          navigator={navigator}
        />
      )}
      <Space height={32} />
      <CreateTripWithShipmentsModal
        isOpen={createTripWithShipmentsModal.isOpen}
        handleClose={createTripWithShipmentsModal.handleClose}
        onSuccess={() => {
          refetchTableData();
          refetchTabData();
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
LongDistanceDispatchTripsTable.query = gql`
  ${usePagination.fragment}
  ${TripsTable.fragment}

  query LongDistanceDispatchTripsTable(
    $statuses: [String]!
    $pagination: PaginationInput!
    $originCity: String
    $originState: String
    $destinationCity: String
    $destinationState: String
    $fromStartDate: String
    $toStartDate: String
    $fromEndDate: String
    $toEndDate: String
    $searchQuery: String
  ) {
    paginatedList: filteredTripsPaginatedList(
      statuses: $statuses
      pagination: $pagination
      startCity: $originCity
      startState: $originState
      endCity: $destinationCity
      endState: $destinationState
      fromStartDate: $fromStartDate
      toStartDate: $toStartDate
      fromEndDate: $fromEndDate
      toEndDate: $toEndDate
      searchQuery: $searchQuery
    ) {
      trips: results {
        id
        name
        ...TripsTable
      }
      paginationMetadata {
        ...usePagination
      }
    }
  }
`;

export default LongDistanceDispatchTripsTable;
