// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import {FieldValue} from 'modules/App/components';

const Container = Styled.View`
  z-index: 1;
`;

const Row = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = Styled.Text`
  ${Typography.Heading1}
`;

const Section = Styled.View`
  align-items: flex-start;
`;

const JobProjectSection = ({job, sectionIndex}) => {
  const responsive = useResponsive();

  return (
    <Container sectionIndex={sectionIndex}>
      <Section {...responsive}>
        <Title>Project Information</Title>
        <Space height={8} />
        <Row {...responsive}>
          <FieldValue
            label={'Project Description (shown to customer)'}
            value={job.project.description}
            empty={'None'}
            style={{flex: 1}}
          />
        </Row>
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobProjectSection.propTypes = {
  job: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
};

JobProjectSection.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectSection.fragment = gql`
  fragment JobProjectSection on Job {
    id
    project {
      id
      description
    }
  }
`;

export default JobProjectSection;
