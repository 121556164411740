// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery, useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import DeleteAttachmentModal from '@shared/modules/File/components/DeleteAttachmentModal';
import ProjectAttachmentUploaderDragInput from '@shared/modules/File/components/ProjectAttachmentUploaderDragInput';
import ProjectAttachmentUploaderTableCells from '@shared/modules/File/components/ProjectAttachmentUploaderTableCells';

const Label = Styled.Text`
  ${Typography.Label}
`;

const TableContainer = Styled.View`
  display: block;
  border: 1px solid ${colors.gray.border};
  border-radius: 4px;
  overflow: hidden;
`;

const Cell = Styled.View`
  align-items: ${(props) => ((props as any).isCentered ? 'center' : 'flex-start')};
  justify-content: center;
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const MobileUploadStatusContainer = Styled.View`
  width: 24px;
  height: 48px;
  justify-content: center;
`;

const Column = Styled.View`
  flex: 1;
`;

const getFileListColumns = ({
  form,
  field,
  viewer,
  project,
  handleRemoveAttachment,
  isViewMode,
  navigator,
  isEnabledIsVisibleToCrewColumn,
}: any) => [
  // Upload status
  {
    width: 24,
    isHidden: isViewMode,
    headerContent: () => {
      return null;
    },
    cellContent: ({item: attachmentForm, rowIndex}: any) => {
      return (
        <ProjectAttachmentUploaderTableCells.UploadStatus
          attachmentForm={attachmentForm}
          form={form}
          field={field}
          rowIndex={rowIndex}
          viewerId={viewer.id}
        />
      );
    },
  },
  // Filename
  {
    flex: 2,
    headerContent: () => {
      return <Label>File Name</Label>;
    },
    cellContent: ({item: attachmentForm, rowIndex}: any) => {
      return (
        <ProjectAttachmentUploaderTableCells.Filename
          isViewMode={isViewMode}
          projectUuid={project.uuid}
          attachmentForm={attachmentForm}
          form={form}
          field={field}
          rowIndex={rowIndex}
          navigator={navigator}
        />
      );
    },
  },
  // Notes
  {
    flex: 2,
    headerContent: () => {
      return <Label>Notes</Label>;
    },
    cellContent: ({item: attachmentForm, rowIndex}: any) => {
      return (
        <ProjectAttachmentUploaderTableCells.Notes
          isViewMode={isViewMode}
          attachmentForm={attachmentForm}
          form={form}
          field={field}
          rowIndex={rowIndex}
        />
      );
    },
  },
  // Visible To Crew
  {
    flex: 1,
    isHidden: !isEnabledIsVisibleToCrewColumn,
    headerContent: () => {
      return (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Cell isCentered>
          <Label>Visible To Crew</Label>
        </Cell>
      );
    },
    cellContent: ({item: attachmentForm, rowIndex}: any) => {
      return (
        <ProjectAttachmentUploaderTableCells.VisibleToCrew
          isViewMode={isViewMode}
          attachmentForm={attachmentForm}
          form={form}
          field={field}
          rowIndex={rowIndex}
        />
      );
    },
  },
  // Remove action
  {
    width: 60,
    isHidden: isViewMode,
    headerContent: () => {
      return null;
    },
    cellContent: ({item: attachmentForm, rowIndex}: any) => {
      return (
        <ProjectAttachmentUploaderTableCells.RemoveAttachment
          handleRemoveAttachment={handleRemoveAttachment}
          rowIndex={rowIndex}
          form={form}
          field={field}
        />
      );
    },
  },
];

const MobileTable = ({
  attachmentForms,
  form,
  field,
  viewer,
  project,
  navigator,
  handleRemoveAttachment,
  isEnabledIsVisibleToCrewColumn,
  attachmentRowStyle,
}: any) => {
  return attachmentForms.map((attachmentForm: any, rowIndex: any) => {
    return (
      <Row
        style={{
          borderBottomWidth: 1,
          borderBottomColor: colors.gray.border,
          paddingHorizontal: 16,
          paddingVertical: 12,
          ...attachmentRowStyle,
        }}
      >
        <MobileUploadStatusContainer>
          <ProjectAttachmentUploaderTableCells.UploadStatus
            attachmentForm={attachmentForm}
            form={form}
            field={field}
            rowIndex={rowIndex}
            viewerId={viewer.id}
          />
        </MobileUploadStatusContainer>
        <Column>
          <ProjectAttachmentUploaderTableCells.Filename
            projectUuid={project.uuid}
            attachmentForm={attachmentForm}
            form={form}
            field={field}
            rowIndex={rowIndex}
            navigator={navigator}
          />
          <Space height={16} />
          <ProjectAttachmentUploaderTableCells.Notes
            attachmentForm={attachmentForm}
            form={form}
            field={field}
            rowIndex={rowIndex}
          />
          <Space height={16} />
          <Row style={{justifyContent: 'space-between'}}>
            {isEnabledIsVisibleToCrewColumn && (
              <ProjectAttachmentUploaderTableCells.VisibleToCrew
                attachmentForm={attachmentForm}
                form={form}
                field={field}
                rowIndex={rowIndex}
                label={'Visible to Crew'}
              />
            )}
            <Column style={{alignItems: 'flex-end'}}>
              <ProjectAttachmentUploaderTableCells.RemoveAttachment
                handleRemoveAttachment={handleRemoveAttachment}
                rowIndex={rowIndex}
                form={form}
                field={field}
              />
            </Column>
          </Row>
        </Column>
      </Row>
    );
  });
};

const ProjectAttachmentUploaderContent = ({
  form,
  field,
  project,
  viewer,
  isViewMode,
  isEnabledIsVisibleToCrewColumn,
  isEnabledHardDeleteAttachments,
  dragInputContainerStyle,
  attachmentRowStyle,
}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const [attachmentIndex, setAttachmentIndex] = useState(0);
  const deleteAttachmentModal = useModal({name: 'Delete Attachment Modal', enableTracking: true});
  const attachmentForms = _.get(form.values, field);

  // NOTE(cooper): This does NOT delete the uploaded project attachment, only removes it from the current form
  const removeAttachmentForm = (removedAttachmentIndex: any) => {
    const newAttachmentForms = attachmentForms.filter(
      (_attachmentForm: any, index: any) => index !== removedAttachmentIndex,
    );
    form.setFieldValue(field, newAttachmentForms);
  };

  const handleRemoveAttachment = ({removedAttachmentIndex, hasUploadError}: any) => {
    setAttachmentIndex(removedAttachmentIndex);
    if (isEnabledHardDeleteAttachments && !hasUploadError) {
      deleteAttachmentModal.handleOpen();
    } else {
      removeAttachmentForm(removedAttachmentIndex);
    }
  };

  return (
    <React.Fragment>
      <ProjectAttachmentUploaderDragInput
        form={form}
        field={field}
        project={project}
        isViewMode={isViewMode}
        dragInputContainerStyle={dragInputContainerStyle}
      />
      {/* TODO(jholston): View Mode only displays name and notes which looks best in the table. Redo when designs exist. */}
      {!responsive.desktop && !isViewMode ? (
        <MobileTable
          attachmentForms={attachmentForms}
          form={form}
          field={field}
          viewer={viewer}
          project={project}
          navigator={navigator}
          handleRemoveAttachment={handleRemoveAttachment}
          isEnabledIsVisibleToCrewColumn={isEnabledIsVisibleToCrewColumn}
          attachmentRowStyle={attachmentRowStyle}
        />
      ) : (
        <TableContainer>
          <Table.FixedHeaderScroll
            columnDefinitions={getFileListColumns({
              form,
              field,
              viewer,
              project,
              handleRemoveAttachment,
              isViewMode,
              navigator,
              isEnabledIsVisibleToCrewColumn,
            })}
            items={attachmentForms}
            emptyStateText={'No attachments uploaded'}
            style={{maxHeight: 344}}
            containerStyle={{borderWidth: 0}}
            headerStyle={{
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderBottomWidth: 1,
              borderBottomColor: colors.gray.border,
            }}
            isDense
          />
        </TableContainer>
      )}
      <DeleteAttachmentModal
        attachmentId={_.get(form.values, `${field}.${attachmentIndex}.attachmentId`)}
        attachmentName={_.get(form.values, `${field}.${attachmentIndex}.filename`)}
        deleteAttachmentModal={deleteAttachmentModal}
        onDeleteSuccess={() => removeAttachmentForm(attachmentIndex)}
      />
    </React.Fragment>
  );
};

type OwnProjectAttachmentUploaderProps = {
  form: any;
  field: string;
  project: any;
  isViewMode?: boolean;
  isEnabledIsVisibleToCrewColumn?: boolean;
  isEnabledHardDeleteAttachments?: boolean;
  attachmentRowStyle?: any;
};

// @ts-expect-error TS(2456): Type alias 'ProjectAttachmentUploaderProps' circul... Remove this comment to see the full error message
type ProjectAttachmentUploaderProps = OwnProjectAttachmentUploaderProps &
  typeof ProjectAttachmentUploader.defaultProps;

// @ts-expect-error TS(7022): 'ProjectAttachmentUploader' implicitly has type 'a... Remove this comment to see the full error message
const ProjectAttachmentUploader = ({
  form,
  field,
  project,
  isViewMode,
  isEnabledIsVisibleToCrewColumn,
  isEnabledHardDeleteAttachments,
  dragInputContainerStyle,
  attachmentRowStyle,
}: ProjectAttachmentUploaderProps) => {
  const {data, loading} = useQuery(ProjectAttachmentUploader.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return null;
  }

  return (
    <ProjectAttachmentUploaderContent
      form={form}
      field={field}
      project={project}
      viewer={data.viewer}
      isViewMode={isViewMode}
      isEnabledIsVisibleToCrewColumn={isEnabledIsVisibleToCrewColumn}
      isEnabledHardDeleteAttachments={isEnabledHardDeleteAttachments}
      dragInputContainerStyle={dragInputContainerStyle}
      attachmentRowStyle={attachmentRowStyle}
    />
  );
};

ProjectAttachmentUploader.defaultProps = {
  isViewMode: false,
  isEnabledIsVisibleToCrewColumn: false,
  isEnabledHardDeleteAttachments: false,
  attachmentRowStyle: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectAttachmentUploader.query = gql`
  query ProjectAttachmentUploader {
    ${gql.query}
    viewer {
      id
    }
  }
`;

ProjectAttachmentUploader.fragment = gql`
  ${ProjectAttachmentUploaderDragInput.fragment}
  fragment ProjectAttachmentUploader on Project {
    id
    uuid
    ...ProjectAttachmentUploaderDragInput
  }
`;

export default ProjectAttachmentUploader;
