// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (projectTypeValuationCoverage) => ({
    projectTypeId: (projectTypeValuationCoverage as any).projectTypeId,
    valuationCoverageDataTableId: (projectTypeValuationCoverage as any)
      .valuationCoverageDataTableId,
  }),
  gql`
    fragment ProjectTypeValuationCoverageForm_edit on ProjectTypeValuationCoverage {
      valuationCoverageDataTableId
      projectTypeId
    }
  `,
);

const toForm = ({valuationCoverageDataTableId, projectTypeId}: any) => ({
  valuationCoverageDataTableId,
  projectTypeId,
});

const toMutation = ({valuationCoverageDataTableId, projectTypeId}: any) => ({
  valuationCoverageDataTableId,
  projectTypeId,
});

const ProjectTypeValuationCoverageForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectTypeValuationCoverageForm;
