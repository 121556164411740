// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CurrencyCode from '@shared/modules/Integration/enums/CurrencyCode';

const ALL_PROJECT_TYPES = 'ALL_PROJECT_TYPES';

export const CodatIntegrationKind = {
  CODAT: 'CODAT',
  RUTTER: 'RUTTER',
};

const _new = ({organizationId, kind}: any) => ({
  kind: kind || CodatIntegrationKind.CODAT,
  currencyCode: CurrencyCode.USD,
  codatIntegrationId: null,
  organizationId,
  defaultExternalInvoiceItemId: null,
  customExternalInvoiceItemId: null,
  shouldExportInvoiceSectionTitles: true,
  invoiceSectionExternalInvoiceItemId: null,
  tipExternalInvoiceItemId: null,
  salesTaxExternalInvoiceItemId: null,
  valuationCoverageExternalInvoiceItemId: null,
  paymentMethodFeeExternalInvoiceItemId: null,
  isAutomaticExportEnabled: false,
  batchKickoffHour: null,
  isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes: false,
  projectTypeIdsToEnqueueForExportUponInvoiceFinalized: [],
  isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes: false,
  projectTypeIdsToEnqueueForExportUponPaymentRecorded: [],
  projectTrackingCategorySourceVariableId: null,
  projectDepartmentSourceVariableId: null,
  status: null,

  // Private
  isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabled: false,
  isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabled: false,
  codatAccountReferenceJson: null,
  customSalesTaxRateVariableId: null,
  isEnabledCustomSalesTaxExports: false,

  // Rutter
  rutterPublicToken: null,
});

const edit = withFragment(
  (codatIntegration) => ({
    codatIntegrationId: (codatIntegration as any).id,
    organizationId: (codatIntegration as any).organizationId,
    defaultExternalInvoiceItemId: (codatIntegration as any).defaultExternalInvoiceItemId,
    customExternalInvoiceItemId: (codatIntegration as any).customExternalInvoiceItemId,
    shouldExportInvoiceSectionTitles: (codatIntegration as any).shouldExportInvoiceSectionTitles,
    invoiceSectionExternalInvoiceItemId: (codatIntegration as any)
      .invoiceSectionExternalInvoiceItemId,
    tipExternalInvoiceItemId: (codatIntegration as any).tipExternalInvoiceItemId,
    salesTaxExternalInvoiceItemId: (codatIntegration as any).salesTaxExternalInvoiceItemId,
    valuationCoverageExternalInvoiceItemId: (codatIntegration as any)
      .valuationCoverageExternalInvoiceItemId,
    paymentMethodFeeExternalInvoiceItemId: (codatIntegration as any)
      .paymentMethodFeeExternalInvoiceItemId,
    isAutomaticExportEnabled: (codatIntegration as any).isAutomaticExportEnabled,
    batchKickoffHour: (codatIntegration as any).batchKickoffHour,
    isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes: (codatIntegration as any)
      .isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes,
    projectTypeIdsToEnqueueForExportUponInvoiceFinalized: (codatIntegration as any)
      .projectTypeIdsToEnqueueForExportUponInvoiceFinalized,
    isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes: (codatIntegration as any)
      .isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes,
    projectTypeIdsToEnqueueForExportUponPaymentRecorded: (codatIntegration as any)
      .projectTypeIdsToEnqueueForExportUponPaymentRecorded,
    projectTrackingCategorySourceVariableId: (codatIntegration as any)
      .projectTrackingCategorySourceVariableId,
    projectDepartmentSourceVariableId: (codatIntegration as any).projectDepartmentSourceVariableId,
    kind: (codatIntegration as any).kind,
    currencyCode: (codatIntegration as any).currencyCode,
    // Private
    isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabled:
      (codatIntegration as any).isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes ||
      !!(codatIntegration as any).projectTypeIdsToEnqueueForExportUponInvoiceFinalized.length,
    isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabled:
      (codatIntegration as any).isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes ||
      !!(codatIntegration as any).projectTypeIdsToEnqueueForExportUponPaymentRecorded.length,
    codatAccountReferenceJson: (codatIntegration as any).codatAccountReferenceJson,
    isEnabledCustomSalesTaxExports: (codatIntegration as any).isEnabledCustomSalesTaxExports,
    customSalesTaxRateVariableId: (codatIntegration as any).customSalesTaxRateVariableId,
    rutterPublicToken: (codatIntegration as any).rutterPublicToken,
    status: (codatIntegration as any).status,
  }),
  gql`
    fragment CodatIntegrationForm_edit on CodatIntegration {
      id
      organizationId
      defaultExternalInvoiceItemId
      codatAccountReferenceJson
      customExternalInvoiceItemId
      shouldExportInvoiceSectionTitles
      invoiceSectionExternalInvoiceItemId
      tipExternalInvoiceItemId
      salesTaxExternalInvoiceItemId
      valuationCoverageExternalInvoiceItemId
      paymentMethodFeeExternalInvoiceItemId
      isAutomaticExportEnabled
      batchKickoffHour
      isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes
      projectTypeIdsToEnqueueForExportUponInvoiceFinalized
      isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes
      projectTypeIdsToEnqueueForExportUponPaymentRecorded
      projectTrackingCategorySourceVariableId
      projectDepartmentSourceVariableId
      customSalesTaxRateVariableId
      isEnabledCustomSalesTaxExports
      rutterPublicToken
      kind
      currencyCode
      status
    }
  `,
);

const toForm = ({
  codatIntegrationId,
  organizationId,
  defaultExternalInvoiceItemId,
  customExternalInvoiceItemId,
  shouldExportInvoiceSectionTitles,
  invoiceSectionExternalInvoiceItemId,
  tipExternalInvoiceItemId,
  salesTaxExternalInvoiceItemId,
  valuationCoverageExternalInvoiceItemId,
  paymentMethodFeeExternalInvoiceItemId,
  isAutomaticExportEnabled,
  batchKickoffHour,
  isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes,
  projectTypeIdsToEnqueueForExportUponInvoiceFinalized = [],
  isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes,
  projectTypeIdsToEnqueueForExportUponPaymentRecorded = [],
  projectTrackingCategorySourceVariableId,
  projectDepartmentSourceVariableId,

  // Private
  isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabled,
  isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabled,
  codatAccountReferenceJson,
  customSalesTaxRateVariableId,
  isEnabledCustomSalesTaxExports,

  // Rutter
  rutterPublicToken,

  kind,
  currencyCode,
  status,
}: any) => {
  return {
    codatIntegrationId,
    organizationId,
    defaultExternalInvoiceItemId: _.toString(defaultExternalInvoiceItemId),
    customExternalInvoiceItemId: _.toString(customExternalInvoiceItemId),
    shouldExportInvoiceSectionTitles,
    isAutomaticExportEnabled,
    invoiceSectionExternalInvoiceItemId: _.toString(invoiceSectionExternalInvoiceItemId),
    tipExternalInvoiceItemId: _.toString(tipExternalInvoiceItemId),
    salesTaxExternalInvoiceItemId: _.toString(salesTaxExternalInvoiceItemId),
    valuationCoverageExternalInvoiceItemId: _.toString(valuationCoverageExternalInvoiceItemId),
    paymentMethodFeeExternalInvoiceItemId: _.toString(paymentMethodFeeExternalInvoiceItemId),
    batchKickoffHour,
    isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes,
    projectTypeIdsToEnqueueForExportUponInvoiceFinalized:
      isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes
        ? [ALL_PROJECT_TYPES]
        : // @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
          projectTypeIdsToEnqueueForExportUponInvoiceFinalized.map((id) => _.toString(id)),
    isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes,
    projectTypeIdsToEnqueueForExportUponPaymentRecorded:
      isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes
        ? [ALL_PROJECT_TYPES]
        : // @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
          projectTypeIdsToEnqueueForExportUponPaymentRecorded.map((id) => _.toString(id)),
    projectTrackingCategorySourceVariableId: _.toString(projectTrackingCategorySourceVariableId),
    projectDepartmentSourceVariableId: _.toString(projectDepartmentSourceVariableId),

    // Private
    isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabled,
    isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabled,
    codatAccountReferenceJson,
    customSalesTaxRateVariableId,
    isEnabledCustomSalesTaxExports,
    // Rutter
    rutterPublicToken,
    kind,
    currencyCode,
    status,
  };
};

const toMutation = ({
  codatIntegrationId,
  organizationId,
  defaultExternalInvoiceItemId,
  customExternalInvoiceItemId,
  shouldExportInvoiceSectionTitles,
  invoiceSectionExternalInvoiceItemId,
  salesTaxExternalInvoiceItemId,
  valuationCoverageExternalInvoiceItemId,
  tipExternalInvoiceItemId,
  paymentMethodFeeExternalInvoiceItemId,
  isAutomaticExportEnabled,
  batchKickoffHour,
  isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes,
  projectTypeIdsToEnqueueForExportUponInvoiceFinalized,
  isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes,
  projectTypeIdsToEnqueueForExportUponPaymentRecorded,
  projectTrackingCategorySourceVariableId,
  projectDepartmentSourceVariableId,
  codatAccountReferenceJson,
  customSalesTaxRateVariableId,
  isEnabledCustomSalesTaxExports,
  rutterPublicToken,
  kind,
  currencyCode,
  status,
}: any) => {
  return {
    codatIntegrationId,
    organizationId,
    batchKickoffHour,
    isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes,
    projectTypeIdsToEnqueueForExportUponInvoiceFinalized:
      isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes
        ? []
        : projectTypeIdsToEnqueueForExportUponInvoiceFinalized.map((id: any) => _.toNumber(id)),
    isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes,
    projectTypeIdsToEnqueueForExportUponPaymentRecorded:
      isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes
        ? []
        : projectTypeIdsToEnqueueForExportUponPaymentRecorded.map((id: any) => _.toNumber(id)),
    defaultExternalInvoiceItemId: defaultExternalInvoiceItemId
      ? _.toNumber(defaultExternalInvoiceItemId)
      : null,
    customExternalInvoiceItemId: customExternalInvoiceItemId
      ? _.toNumber(customExternalInvoiceItemId)
      : null,
    shouldExportInvoiceSectionTitles,
    isAutomaticExportEnabled,
    invoiceSectionExternalInvoiceItemId: invoiceSectionExternalInvoiceItemId
      ? _.toNumber(invoiceSectionExternalInvoiceItemId)
      : null,
    tipExternalInvoiceItemId: tipExternalInvoiceItemId
      ? _.toNumber(tipExternalInvoiceItemId)
      : null,
    salesTaxExternalInvoiceItemId: salesTaxExternalInvoiceItemId
      ? _.toNumber(salesTaxExternalInvoiceItemId)
      : null,
    valuationCoverageExternalInvoiceItemId: valuationCoverageExternalInvoiceItemId
      ? _.toNumber(valuationCoverageExternalInvoiceItemId)
      : null,
    paymentMethodFeeExternalInvoiceItemId: paymentMethodFeeExternalInvoiceItemId
      ? _.toNumber(paymentMethodFeeExternalInvoiceItemId)
      : null,
    projectTrackingCategorySourceVariableId: projectTrackingCategorySourceVariableId
      ? _.toNumber(projectTrackingCategorySourceVariableId)
      : null,
    projectDepartmentSourceVariableId: projectDepartmentSourceVariableId
      ? _.toNumber(projectDepartmentSourceVariableId)
      : null,
    codatAccountReferenceJson,
    customSalesTaxRateVariableId,
    isEnabledCustomSalesTaxExports,
    // Rutter
    rutterPublicToken,
    kind,
    currencyCode,
    status,
  };
};

const CodatIntegrationForm = {
  new: _new,
  toForm,
  toMutation,
  edit,

  ALL_PROJECT_TYPES,
};

export default CodatIntegrationForm;
