const ACTIVE = 'ACTIVE';
const OVERFLOW = 'OVERFLOW';
const INACTIVE = 'INACTIVE';

const DISPLAY_VALUE_MAP = {
  [ACTIVE]: 'Active',
  [OVERFLOW]: 'Overflow',
  [INACTIVE]: 'Inactive',
};

const getDisplayValue = (status: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return DISPLAY_VALUE_MAP[status];
};

const TruckStatus = {
  ACTIVE,
  OVERFLOW,
  INACTIVE,

  getDisplayValue,
};

export default TruckStatus;
