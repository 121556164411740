// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import {ReportMoveMoveUsersList} from 'modules/ReportMove/components';

const Container = Styled.View`
  width: 100%;
`;

const JobReportMoveMoveUsers = ({moveUsers}) => (
  <Container>
    <ReportMoveMoveUsersList moveUsers={moveUsers} />
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobReportMoveMoveUsers.propTypes = {
  moveUsers: PropTypes.array.isRequired,
};

JobReportMoveMoveUsers.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobReportMoveMoveUsers.fragment = gql`
  ${ReportMoveMoveUsersList.fragment}

  fragment JobReportMoveMoveUsers on ReportMoveUser {
    ...ReportMoveMoveUsersList
  }
`;

export default JobReportMoveMoveUsers;
