// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useModal, useResponsive} from '@supermove/hooks';
import {BillingLibraryModel, BillTypeModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Table from '@shared/design/components/Table';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import useDeleteBillTypeMutation from '@shared/modules/Billing/hooks/useDeleteBillTypeMutation';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import BillingLibraryBillTypesPageContentV1 from 'modules/Organization/Settings/BillingLibraries/BillingLibraryBillTypesPageContentV1';
import BillingLibraryPageHeader from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageHeader';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const PageHeading = Styled.Text`
  ${Typography.Responsive.PageHeading}
;`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const getUsedOnText = (names: string[]) => {
  const DISPLAY_LIMIT = 3;

  // We do +1 here to ensure that we only show "and X more" when X is 2 or more
  if (names.length > DISPLAY_LIMIT + 1) {
    const namesToDisplay = _.take(names, DISPLAY_LIMIT).join(', ');
    const remainingCount = names.length - DISPLAY_LIMIT;
    return `${namesToDisplay} and ${remainingCount} more`;
  } else {
    return _.join(names, ', ');
  }
};

const DeleteBillTypeModal = ({
  billType,
  isOpen,
  handleClose,
  refetch,
}: {
  billType: BillTypeModel;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const {handleSubmit} = useDeleteBillTypeMutation({
    billTypeId: billType.id,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: unknown) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete '${billType.name}'?`}
      subtitle={
        "This will permanently remove this Bill Template from any linked Project Types, Job Types or Cost/Compensation Items. You can't undo this action."
      }
      handleClose={handleClose}
      handleDelete={handleSubmit}
    />
  );
};

const getColumnDefinitions = ({
  billingLibrary,
  navigator,
  refetch,
}: {
  billingLibrary: BillingLibraryModel;
  navigator: any;
  refetch: () => void;
}) => {
  return [
    {
      flex: 2,
      headerLabel: 'Template Name',
      cellText: (billType: BillTypeModel) => billType.name,
      numberOfLines: 3,
    },
    {
      flex: 3,
      headerLabel: 'Description',
      cellText: (billType: BillTypeModel) => billType.description,
      numberOfLines: 3,
    },
    {
      flex: 3,
      headerLabel: 'Used On Project Types',
      cellText: (billType: BillTypeModel) => {
        const usedOnProjectTypes = billType.projectTypes.map((projectType) => projectType.name);
        return getUsedOnText(usedOnProjectTypes);
      },
      numberOfLines: 3,
    },
    {
      flex: 3,
      headerLabel: 'Used On Job Types',
      cellText: (billType: BillTypeModel) => {
        const usedOnJobTypes = billType.jobTypes.map((jobType) => jobType.name);
        return getUsedOnText(usedOnJobTypes);
      },
      numberOfLines: 3,
    },
    {
      flex: 2,
      headerLabel: 'Last Updated',
      cellText: (billType: BillTypeModel) => Datetime.convertToDisplayDatetime(billType.updatedAt),
      secondary: {
        cellText: (billType: BillTypeModel) => {
          return billType.updatedBy ? billType.updatedBy.fullName : '';
        },
      },
    },
    {
      flex: 1,
      headerLabel: 'Actions',
      actions: (billType: BillTypeModel) => {
        return [
          {
            isDisabled: !!billType.identifier && !billType.createdById,
            text: 'Edit',
            desktopIcon: Icon.Pen,
            onPress: () => {
              navigator.push(
                `/settings/billing/billing-libraries/${billingLibrary.uuid}/bill-templates/${billType.uuid}`,
              );
            },
          },
          {
            isDisabled: !!billType.identifier && !billType.createdById,
            text: 'Remove',
            onPress: ({handleOpen}: {handleOpen?: () => void}) => {
              handleOpen && handleOpen();
            },
            actionHook: {
              hook: useModal,
              hookArgument: {name: 'Delete Bill Rule Type Modal'},
              renderComponent: ({
                isOpen,
                handleClose,
              }: {
                isOpen: boolean;
                handleClose: () => void;
              }) => {
                return (
                  <DeleteBillTypeModal
                    key={isOpen as unknown as React.Key}
                    isOpen={isOpen}
                    handleClose={handleClose}
                    billType={billType}
                    refetch={refetch}
                  />
                );
              },
            },
          },
        ];
      },
    },
  ];
};

const BillingLibraryBillTypesPageContent = ({
  billingLibrary,
  refetch,
}: {
  billingLibrary: BillingLibraryModel;
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const handleAddBillTemplate = () =>
    navigator.push(`/settings/billing/billing-libraries/${billingLibrary.uuid}/bill-templates/new`);
  return (
    <ScrollView
      style={{backgroundColor: colors.gray.background}}
      contentContainerStyle={{paddingHorizontal: responsive.desktop ? 24 : 12}}
    >
      <Container style={{maxWidth: 1400}}>
        <Space height={24} />
        <Row>
          <PageHeading responsive={responsive}>Bill Templates</PageHeading>
          <Space style={{flex: 1, minWidth: 16}} />
          <Button
            isResponsive
            iconLeft={Icon.Plus}
            text={'Add Bill Template'}
            onPress={handleAddBillTemplate}
          />
        </Row>
        <Space height={12} />
        <BodyText responsive={responsive}>
          {`Customize bill templates with predefined rules and items. These templates can be added to project or job types to auto-populate, or manually added when needed.`}
        </BodyText>
        <Space height={24} />
        <Table
          columnDefinitions={getColumnDefinitions({billingLibrary, navigator, refetch})}
          items={billingLibrary.billTypes}
          itemKey={'id'}
          emptyStateText={'No templates to display'}
          hasBorder
        />
        <Space height={36} />
      </Container>
    </ScrollView>
  );
};

const BillingLibraryBillTypesPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(BillingLibraryBillTypesPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      billingLibraryUuid: params.billingLibraryUuid,
    },
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <Container style={{flex: 1}}>
            <BillingLibraryPageHeader billingLibrary={data.billingLibrary} />
            {data.billingLibrary.organization.features.isEnabledConditionalBillItems ? (
              <BillingLibraryBillTypesPageContent
                billingLibrary={data.billingLibrary}
                refetch={refetch}
              />
            ) : (
              <BillingLibraryBillTypesPageContentV1
                billingLibrary={data.billingLibrary}
                refetch={refetch}
              />
            )}
          </Container>
        )}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryBillTypesPage.query = gql`
  ${BillingLibraryBillTypesPageContentV1.fragment}
  ${BillingLibraryPageHeader.fragment}

  query BillingLibraryBillTypesPage(
    $billingLibraryUuid: String!,
  ) {
    ${gql.query}
    billingLibrary(billingLibraryUuid: $billingLibraryUuid) {
      id
      name
      uuid
      billTypes {
        id
        name
        description
        uuid
        identifier
        updatedAt
        updatedBy {
          id
          fullName
        }
        createdById
        projectTypes {
          id
          name
        }
        jobTypes {
          id
          name
        }
      }
      organization {
        id
        features {
          isEnabledConditionalBillItems: isEnabled(feature: "CONDITIONAL_BILL_ITEMS")
        }
      }
      ...BillingLibraryBillTypesPageContentV1
      ...BillingLibraryPageHeader
    }
  }
`;

export default BillingLibraryBillTypesPage;
