// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import StorageProjectForm from '@shared/modules/Project/Storage/forms/StorageProjectForm';

const useCreateStorageProjectMutation = ({storageProjectForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      storageProjectForm: StorageProjectForm.toForm(storageProjectForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateStorageProjectMutation.mutation,
    variables: {
      storageProjectForm: StorageProjectForm.toMutation(form.values.storageProjectForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateStorageProjectMutation.mutation = gql`
  mutation useCreateStorageProjectMutation($storageProjectForm: StorageProjectForm!) {
    response: createStorageProject(storageProjectForm: $storageProjectForm) {
      ${gql.errors}
      project {
        id
        uuid
      }
    }
  }
`;

export default useCreateStorageProjectMutation;
