import _ from 'lodash';

import {Datetime, titleize} from '@supermove/utils';

const getDisplayFormat = (variableString: any) => {
  return titleize(variableString.replace(/_/g, ' '));
};

const getDisplayKind = (variableString: any) => {
  return titleize(variableString.replace(/_/g, ' '));
};

const getDisplayUpdatedAt = (variable: any) => {
  return Datetime.convertToDisplayDatetime(variable.updatedAt);
};

const getIdentifierText = (name: any) => {
  return _.snakeCase(name.replace('%', 'PERCENT').replace('#', 'NUMBER')).toUpperCase();
};

const getVariableDropdown = (enums: any) => {
  const dropdownOptions = [];
  for (const value in enums) {
    if (Object.prototype.hasOwnProperty.call(enums, value)) {
      dropdownOptions.push({
        label: _.startCase(_.toLower(value)),
        value,
      });
    }
  }
  return dropdownOptions;
};

const timesheetVariableIdentifiersToHide = [
  'JOB_TIMESHEET_BREAK_HOURS',
  'JOB_TIMESHEET_WORK_HOURS',
  'JOB_TIMESHEET_WAIT_HOURS',
  'JOB_TIMESHEET_TOTAL_HOURS',
  'JOB_TIMESHEET_DRIVE_HOURS',
];

const Variable = {
  getDisplayFormat,
  getDisplayUpdatedAt,
  getDisplayKind,
  getIdentifierText,
  getVariableDropdown,

  timesheetVariableIdentifiersToHide,
};

export default Variable;
