// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Datetime} from '@supermove/utils';

const edit = withFragment(
  (task) => ({
    taskId: (task as any).id,
    dueAtDate: (task as any).dueAt,
    dueAtTime: (task as any).dueAt ? Datetime.fromDatetimeToUtc((task as any).dueAt) : '23:59',
  }),
  gql`
    fragment TaskDueAtForm_edit on Task {
      id
      dueAt
    }
  `,
);

const toForm = ({taskId, dueAtDate, dueAtTime}: any) => ({
  taskId,
  dueAtDate: Datetime.toFormDate(dueAtDate),
  dueAtTime: Datetime.toFormTime(dueAtTime),
});

const toMutation = ({taskId, dueAtDate, dueAtTime}: any) => {
  return {
    taskId,
    dueAtDate: dueAtDate ? Datetime.toMutationDate(dueAtDate) : null,
    dueAtTime: Datetime.toMutationTime(dueAtTime),
  };
};

const TaskForm = {
  edit,
  toForm,
  toMutation,
};

export default TaskForm;
