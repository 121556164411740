import {ApolloError} from 'apollo-client';

export const transactionErrorMessage = (error: unknown): string => {
  let reason = 'UNKNOWN';
  if (error instanceof ApolloError) {
    for (const graphqlError of error.graphQLErrors || []) {
      const {responseMessage} = graphqlError.extensions?.gatewayException ?? {};
      if (responseMessage) {
        reason = responseMessage;
        break;
      }
    }
  }
  return `We are unable to process this credit card (reason: ${reason}).`;
};

export const transactionContactMessage = (contact: string): string => {
  return `Please use a different credit card or contact ${contact} to provide an alternative form of payment.`;
};
