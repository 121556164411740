// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import {UpdateUserDayByDateMutation} from 'modules/Employee/User/Schedule/components';

const Container = Styled.View`
  flex-direction: row;
`;

const JobCell = Styled.View`
  justify-content: flex-start;
  width: 110px;
  height: 75px;
  padding: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  background-color: ${(props) => props.color};
  opacity: ${(props) => props.opacity || 1.0};
  overflow: hidden;
`;

const Touchable = Styled.Touchable`
`;

const Job = Styled.View`
  width: 100%;
  padding-horizontal: 3px;
  padding-vertical: 2px;
  margin-bottom: 3px;
  background-color: ${colors.gray.primary};
  border-radius: 3px;
`;

const JobText = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.white};
`;

const IndicatorWrapper = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Indicator = Styled.Loading`
`;

const sortJobs = ({jobs}) => {
  return _.sortBy(jobs, ['startTime1', 'startTime2']);
};

const getColorByUserDay = ({userDay}) => {
  switch (userDay.scheduleStatus) {
    case 'AVAILABLE':
      return colors.green.status;
    case 'NOT_AVAILABLE':
      return colors.Pink600;
    default:
      return colors.gray.border;
  }
};

const getCellOpacity = ({date}) => {
  if (!date) {
    return 1.0;
  }
  return Datetime.isPast(date) ? 0.5 : 1.0;
};

const getToggledScheduleStatus = ({scheduleDay}) => {
  if (!scheduleDay.userDay) {
    return 'NOT_AVAILABLE';
  }
  switch (scheduleDay.userDay.scheduleStatus) {
    case 'AVAILABLE':
      return 'NOT_AVAILABLE';
    case 'NOT_AVAILABLE':
    default:
      return 'AVAILABLE';
  }
};

const ScheduleDayItem = ({scheduleDay, user}) => {
  const {navigator} = useNavigationDOM();
  return scheduleDay.userDay ? (
    <UpdateUserDayByDateMutation
      date={scheduleDay.date}
      scheduleStatus={getToggledScheduleStatus({scheduleDay})}
      userId={user.id}
    >
      {({loading, handleSubmit}) => (
        <Touchable onPress={handleSubmit}>
          <JobCell
            color={getColorByUserDay({userDay: scheduleDay.userDay})}
            opacity={getCellOpacity({date: scheduleDay.date})}
          >
            {loading ? (
              <IndicatorWrapper>
                <Indicator size={'small'} color={colors.gray.border} />
              </IndicatorWrapper>
            ) : (
              <React.Fragment>
                {sortJobs({jobs: scheduleDay.jobs}).map((job) => (
                  <Touchable key={`${job.id}`} onPress={() => navigator.push(`/jobs/${job.uuid}`)}>
                    <Job>
                      <JobText numberOfLines={1}>
                        {`#${job.number} - ${Datetime.convertToDisplayTime(job.startTime1)}`}
                      </JobText>
                    </Job>
                  </Touchable>
                ))}
              </React.Fragment>
            )}
          </JobCell>
        </Touchable>
      )}
    </UpdateUserDayByDateMutation>
  ) : (
    <UpdateUserDayByDateMutation
      date={scheduleDay.date}
      scheduleStatus={'AVAILABLE'}
      userId={user.id}
      refetchQueries={['EmployeeSchedules']}
    >
      {({loading, handleSubmit}) => (
        <Touchable onPress={handleSubmit}>
          <JobCell color={colors.gray.border} opacity={getCellOpacity({date: scheduleDay.date})}>
            {loading && (
              <IndicatorWrapper>
                <Indicator size={'small'} color={colors.gray.border} />
              </IndicatorWrapper>
            )}
          </JobCell>
        </Touchable>
      )}
    </UpdateUserDayByDateMutation>
  );
};

const CrewScheduleItem = ({scheduleDays, user}) => (
  <Container>
    {scheduleDays.map((scheduleDay, index) => (
      <ScheduleDayItem key={index} scheduleDay={scheduleDay} user={user} />
    ))}
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewScheduleItem.fragment = gql`
  fragment CrewScheduleItem on EmployeeScheduleDay {
    date
    day {
      id
      value
    }
    userDay {
      id
      userId
      dayId
      scheduleStatus
    }
    jobs {
      id
      uuid
      number
      startTime1
    }
  }
`;

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
CrewScheduleItem.propTypes = {};

CrewScheduleItem.defaultProps = {};

export default CrewScheduleItem;
