// Libraries

// Supermove
import {uuid} from '@supermove/utils';

export interface DelayWorkflowActionFormType {
  workflowRunStepUuid: string;
  delayUnitType: string | null;
  delayUnits: number | null;
}

const _new = (): DelayWorkflowActionFormType => ({
  workflowRunStepUuid: uuid(),
  delayUnitType: '',
  delayUnits: null,
});

const edit = (delayWorkflowActionForm: any): DelayWorkflowActionFormType => ({
  workflowRunStepUuid: delayWorkflowActionForm.workflowRunStepUuid,
  delayUnitType: delayWorkflowActionForm.delayUnitType,
  delayUnits: delayWorkflowActionForm.delayUnits,
});

const toForm = (
  DelayWorkflowActionForm: DelayWorkflowActionFormType,
): DelayWorkflowActionFormType => ({
  workflowRunStepUuid: DelayWorkflowActionForm.workflowRunStepUuid,
  delayUnitType: DelayWorkflowActionForm.delayUnitType,
  delayUnits: DelayWorkflowActionForm.delayUnits,
});

const toMutation = (
  DelayWorkflowActionForm: DelayWorkflowActionFormType,
): DelayWorkflowActionFormType => ({
  workflowRunStepUuid: DelayWorkflowActionForm.workflowRunStepUuid,
  delayUnitType: DelayWorkflowActionForm.delayUnitType,
  delayUnits: DelayWorkflowActionForm.delayUnits,
});

const DelayWorkflowActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default DelayWorkflowActionForm;
