// Libraries
import React from 'react';

// Supermove
import {Styled, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import JobValuationCoverageOptionsTable from '@shared/modules/Job/components/JobValuationCoverageOptionsTable';

const Wrapper = Styled.View`
  margin-bottom: 10px;
  z-index: ${(props) => 100 - (props as any).index};
`;

const Section = Styled.View`
`;

const ValuationDeclarationTableButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ValuationDeclarationTableButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const DocumentV2ValuationDeclarationTableContent = ({job, index, isCollapsible}: any) => {
  const [isShowingValuationCoverageTable, setIsShowingValuationCoverageTable] =
    useState(!isCollapsible);

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Wrapper index={index}>
      <Section>
        {isCollapsible && (
          <ValuationDeclarationTableButton
            onPress={() => setIsShowingValuationCoverageTable(!isShowingValuationCoverageTable)}
          >
            <ValuationDeclarationTableButtonText>{`${
              isShowingValuationCoverageTable ? 'Hide' : 'Show'
            } Rates For Valuation Coverage`}</ValuationDeclarationTableButtonText>
            <Space width={6} />
            <Icon
              color={colors.blue.interactive}
              size={18}
              source={isShowingValuationCoverageTable ? Icon.CaretUp : Icon.CaretDown}
            />
          </ValuationDeclarationTableButton>
        )}
        {isShowingValuationCoverageTable && (
          <React.Fragment>
            <Space height={8} />
            <JobValuationCoverageOptionsTable job={job} />
          </React.Fragment>
        )}
      </Section>
    </Wrapper>
  );
};

const DocumentV2ValuationDeclarationTablePreview = ({index}: any) => {
  return (
    <DocumentV2ValuationDeclarationTableContent job={MOCK_JOB_DATA} index={index} isCollapsible />
  );
};

const DocumentV2ValuationDeclarationTableQueried = ({index, job, isCollapsible}: any) => {
  return (
    <DocumentV2ValuationDeclarationTableContent
      job={job}
      index={index}
      isCollapsible={isCollapsible}
    />
  );
};

const DocumentV2ValuationDeclarationTable = ({index, isPreview, job, isCollapsible}: any) => {
  return (
    <React.Fragment>
      {isPreview ? (
        <DocumentV2ValuationDeclarationTablePreview index={index} />
      ) : (
        <DocumentV2ValuationDeclarationTableQueried
          index={index}
          job={job}
          isCollapsible={isCollapsible}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2ValuationDeclarationTable.fragment = gql`
  ${JobValuationCoverageOptionsTable.fragment}
  fragment DocumentV2ValuationDeclarationTable on Job {
    id
    ...JobValuationCoverageOptionsTable
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_JOB_DATA = {
  id: 1,
  jobType: {
    id: 1,
    valuationCoverageConfig: {
      valuationCoverageTable: [
        ['Valuation Options', 'Transportation Rates', 'Storage-In-Transit Rates'],
        ['Basic: $0.60 Per Pound Per Article', 'No additional charge', 'Not applicable'],
        ['Actual Cash Value', '$0.96 per $100', '$0.16 per $100'],
        ['Full Value Protection - No deductible', '$1.82 per $100', '$0.32 per $100'],
        ['Full Value Protection - $250 deductible', '$0.58 per $100', '$0.12 per $100'],
        ['Full Value Protection - $500 deductible', '$0.32 per $100', '$0.06 per $100'],
      ],
    },
  },
};

export default DocumentV2ValuationDeclarationTable;
