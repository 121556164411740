// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SurveyMethodForm from '@shared/modules/Inventory/forms/SurveyMethodForm';

const useDeleteSurveyMethodMutation = ({surveyMethodForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      surveyMethodForm: SurveyMethodForm.toForm(surveyMethodForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteSurveyMethodMutation.mutation,
    variables: {
      surveyMethodForm: SurveyMethodForm.toMutation(form.values.surveyMethodForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteSurveyMethodMutation.mutation = gql`
  mutation useDeleteSurveyMethodMutation($surveyMethodForm: SurveyMethodForm!) {
    response: deleteSurveyMethod(surveyMethodForm: $surveyMethodForm) {
      ${gql.errors}
      surveyMethod {
        id
      }
    }
  }
`;

export default useDeleteSurveyMethodMutation;
