// Libraries
import React from 'react';

// Supermove
import {Query, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import DayJobsListV1 from 'modules/App/Day/components/DayJobsListV1';

const Container = Styled.View`
  flex: 1;
  border-left-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Loading = Styled.View`
  padding-top: 40px;
`;

const Indicator = Styled.Loading`
`;

const CalendarDayDetailsV1 = ({date}) => (
  <Query
    fetchPolicy={'network-only'}
    variables={{
      date,
      kinds: ['MOVE'],
    }}
    query={CalendarDayDetailsV1.query}
  >
    {({loading, data, refetch}) => (
      <Container>
        {loading ? (
          <Loading>
            <Indicator size={'large'} color={colors.gray.secondary} />
          </Loading>
        ) : (
          <DayJobsListV1 date={date} calendarDay={data.calendarDay} refetch={refetch} />
        )}
      </Container>
    )}
  </Query>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CalendarDayDetailsV1.query = gql`
  ${DayJobsListV1.fragment}

  query CalendarDayDetailsV1($date: String!, $kinds: [String]!) {
    ${gql.query}
    calendarDay(date: $date, kinds: $kinds) {
      ...DayJobsListV1
    }
  }
`;

export default CalendarDayDetailsV1;
