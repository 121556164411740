// Libararies
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import TripForm from '@shared/modules/Dispatch/forms/TripForm';
import useUpdateTripMutation from '@shared/modules/Dispatch/hooks/useUpdateTripMutation';
import TripModalContent from 'modules/Dispatch/LongDistance/components/TripModalContent';

const EditTripModalContent = ({refetch, handleClose, trip}) => {
  const tripForm = TripForm.edit(trip);
  const field = 'tripForm';
  const {form, handleSubmit, submitting} = useUpdateTripMutation({
    tripForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.HeaderText>{'Edit Trip'}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body isScrollable>
        <TripModalContent form={form} field={field} organization={trip.organization} />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button onPress={handleClose} isDisabled={submitting}>
          {'Cancel'}
        </Modal.Button>
        <Space width={8} />
        <Modal.Button
          onPress={handleSubmit}
          isSubmitting={submitting}
          color={colors.blue.interactive}
        >
          {'Save'}
        </Modal.Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

const EditTripModal = ({isOpen, handleClose, trip, refetch}) => {
  return (
    <Modal isOpen={isOpen} width={Modal.WIDTH.LARGE} style={{flex: 1}}>
      <EditTripModalContent refetch={refetch} handleClose={handleClose} trip={trip} />
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditTripModal.fragment = gql`
  ${TripModalContent.fragment}
  ${TripForm.edit.fragment}

  fragment EditTripModal on Trip {
    id
    organization {
      id
      ...TripModalContent_Organization
    }
    ...TripForm_edit
  }
`;

export default EditTripModal;
