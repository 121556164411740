// Libraries
import _ from 'lodash';

// APIs
import _StripeTerminal from './StripeTerminal';
import {StripeTerminalType} from './StripeTerminalTypes';

const StripeTerminal = {
  // Key used for Storage where we save the last connected reader serial number.
  READER_KEY: 'stripeTerminalReaderSerialNumber',

  // Platform methods that call native modules.
  initialize: ({
    host,
    endpoint,
    stripeAccountId,
    onDisconnect,
  }: Parameters<StripeTerminalType['initialize']>['0']) => {
    return _StripeTerminal.initialize({host, endpoint, stripeAccountId, onDisconnect});
  },
  getConnectedReader: () => {
    return _StripeTerminal.getConnectedReader();
  },
  discoverReaders: ({
    deviceType,
    timeout,
    simulator,
  }: Parameters<StripeTerminalType['discoverReaders']>['0']) => {
    return _StripeTerminal.discoverReaders({deviceType, timeout, simulator});
  },
  connectReader: ({
    deviceType,
    timeout,
    simulator,
    serialNumber,
  }: Parameters<StripeTerminalType['connectReader']>['0']) => {
    return _StripeTerminal.connectReader({deviceType, timeout, simulator, serialNumber});
  },
  confirmPaymentIntent: ({
    clientSecret,
  }: Parameters<StripeTerminalType['confirmPaymentIntent']>['0']) => {
    return _StripeTerminal.confirmPaymentIntent({clientSecret});
  },

  // Constants
  getConstant: (name: string) => {
    const value = _.get(_StripeTerminal, name);
    if (!value) {
      throw new Error(`Unable to get StripeTerminal constant: ${name}`);
    }
    return value;
  },

  get DeviceTypes() {
    return {
      Chipper2X: StripeTerminal.getConstant('DeviceTypeChipper2X'),
    };
  },
  get DiscoveryMethods() {
    return {
      BluetoothScan: StripeTerminal.getConstant('DiscoveryMethodBluetoothScan'),
      BluetoothProximity: StripeTerminal.getConstant('DiscoveryMethodBluetoothProximity'),
    };
  },
  get PaymentIntentStatuses() {
    return {
      RequiresPaymentMethod: StripeTerminal.getConstant('PaymentIntentStatusRequiresPaymentMethod'),
      RequiresConfirmation: StripeTerminal.getConstant('PaymentIntentStatusRequiresConfirmation'),
      RequiresCapture: StripeTerminal.getConstant('PaymentIntentStatusRequiresCapture'),
      Canceled: StripeTerminal.getConstant('PaymentIntentStatusCanceled'),
      Succeeded: StripeTerminal.getConstant('PaymentIntentStatusSucceeded'),
    };
  },
  get ReaderEvents() {
    return {
      CardInserted: StripeTerminal.getConstant('ReaderEventCardInserted'),
      CardRemoved: StripeTerminal.getConstant('ReaderEventCardRemoved'),
    };
  },
  get PaymentStatuses() {
    return {
      NotReady: StripeTerminal.getConstant('PaymentStatusNotReady'),
      Ready: StripeTerminal.getConstant('PaymentStatusReady'),
      WaitingForInput: StripeTerminal.getConstant('PaymentStatusWaitingForInput'),
      Processing: StripeTerminal.getConstant('PaymentStatusProcessing'),
    };
  },
  get ConnectionStatuses() {
    return {
      NotConnected: StripeTerminal.getConstant('ConnectionStatusNotConnected'),
      Connected: StripeTerminal.getConstant('ConnectionStatusConnected'),
      Connecting: StripeTerminal.getConstant('ConnectionStatusConnecting'),
    };
  },
};

export default StripeTerminal;
