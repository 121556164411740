import {ExecutionResult} from 'react-apollo';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {MutationError, Form, MutationResponse} from '@supermove/hooks/src/forms/types';

type UpdateRecurringInvoiceResponseForm = {
  recurringInvoiceForm: {
    recurringInvoiceId: number;
    payengineCreditCardId?: number;
  };
};

type UpdateRecurringInvoiceResponse = {
  id: number;
  payengineCreditCardId?: number;
};

type Args = {
  recurringInvoiceId: number;
  onSuccess: (response: UpdateRecurringInvoiceResponse) => void;
  onError: (errors: MutationError[]) => void;
};

type Result = {
  form: Form<UpdateRecurringInvoiceResponseForm>;
  submitting: boolean | undefined;
  handleSubmit: () => Promise<ExecutionResult<MutationResponse<UpdateRecurringInvoiceResponse>>>;
};

const useUpdateRecurringInvoiceMutation = ({
  recurringInvoiceId,
  onSuccess,
  onError,
}: Args): Result => {
  const form = useForm<UpdateRecurringInvoiceResponseForm>({
    initialValues: {
      recurringInvoiceForm: {
        recurringInvoiceId,
      },
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateRecurringInvoiceMutation.mutation,
    variables: {
      recurringInvoiceForm: form.values.recurringInvoiceForm,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateRecurringInvoiceMutation.mutation = gql`
  mutation UpdateRecurringInvoiceMutation($recurringInvoiceForm: RecurringInvoiceForm!) {
    response: updateRecurringInvoice(recurringInvoiceForm: $recurringInvoiceForm) {
      ${gql.errors}
      recurringInvoice {
        id
        payengineCreditCardId
      }
    }
  }
`;

export default useUpdateRecurringInvoiceMutation;
