// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm, useModal, useState} from '@supermove/hooks';
import {BillItemType} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Currency, Percent, List} from '@supermove/utils';

// App
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import BillItemTypeForm from '@shared/modules/Billing/forms/BillItemTypeForm';
import BillTemplateEditBillItemTypeModal from 'modules/Organization/Settings/BillingLibraries/components/BillTemplateEditBillItemTypeModal';
import EditBillLineItemCells from 'modules/Project/Billing/components/EditBillLineItemCells';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const KindLabelContainer = Styled.View`
  padding-horizontal: 8px;
  padding-top: ${(props) => (props.index > 0 ? 7 : 6)}px;
`;

const DescriptionButton = Styled.ButtonV2`
  position: absolute;
  top: 7px;
  right: 8px;
  flex-direction: row;
  align-items: center;
`;

const swapBillItemTypes = (form, field, fromIndex, toIndex) => {
  const billItemTypeIds = _.get(form.values, field);
  const reorderedBillItemTypeIds = List.move({list: billItemTypeIds, fromIndex, toIndex});
  form.setFieldValue(field, reorderedBillItemTypeIds);
};

const removeBillItemType = (form, field, index) => {
  const billItemTypeIds = _.get(form.values, field);
  const updatedBillItemTypeIds = _.remove(billItemTypeIds, (form, i) => i !== index);
  form.setFieldValue(field, updatedBillItemTypeIds);
};

const MoveItemColumn = ({form, field, isFirstItem, index}) => {
  const billItemTypeIdsCount = _.get(form.values, field).length;
  const isLastItem = index === billItemTypeIdsCount - 1;
  const handleMoveItemUp = () => {
    swapBillItemTypes(form, field, index, index - 1);
  };
  const handleMoveItemDown = () => {
    swapBillItemTypes(form, field, index, index + 1);
  };

  return (
    <Container>
      {isFirstItem && <Space height={26} />}
      <EditBillLineItemCells.IconCell justify={'center'}>
        <EditBillLineItemCells.IconCircle
          activeOpacity={0.8}
          onPress={handleMoveItemUp}
          disabled={isFirstItem}
        >
          <Icon
            source={Icon.ArrowUp}
            size={12}
            color={isFirstItem ? colors.gray.border : colors.blue.interactive}
          />
        </EditBillLineItemCells.IconCircle>
        <Space height={2} />
        <EditBillLineItemCells.IconCircle
          activeOpacity={0.8}
          onPress={handleMoveItemDown}
          disabled={isLastItem}
        >
          <Icon
            source={Icon.ArrowDown}
            size={12}
            color={isLastItem ? colors.gray.border : colors.blue.interactive}
          />
        </EditBillLineItemCells.IconCircle>
      </EditBillLineItemCells.IconCell>
    </Container>
  );
};

const NameColumn = ({
  billItemTypeForm,
  index,
  isShowingDescription,
  isFirstItem,
  handleShowDescription,
  billItemType,
}) => {
  return (
    <Container>
      {isFirstItem && (
        <React.Fragment>
          <EditBillLineItemCells.CellText color={colors.gray.tertiary}>
            Kind/Item/Description
          </EditBillLineItemCells.CellText>
          <Space height={8} />
        </React.Fragment>
      )}
      <EditBillLineItemCells.LeftDataCell isLarge hasBorder isFirstItem={index === 0}>
        <KindLabelContainer index={index}>
          <EditBillLineItemCells.CellCaption>
            {BillItemTypeCategory.getDisplayCategory(billItemTypeForm.category, billItemTypeForm)}
          </EditBillLineItemCells.CellCaption>
        </KindLabelContainer>
        <Row>
          <Space width={8} />
          <EditBillLineItemCells.CellText>
            {BillItemType.getDisplayName(billItemType)}
          </EditBillLineItemCells.CellText>
        </Row>
        {!!billItemTypeForm.description && (
          <DescriptionButton activeOpacity={0.8} onPress={handleShowDescription}>
            <EditBillLineItemCells.CellButtonText>Description</EditBillLineItemCells.CellButtonText>
            <Space width={4} />
            <Icon
              source={isShowingDescription ? Icon.ChevronUp : Icon.ChevronDown}
              color={colors.blue.interactive}
              size={11}
            />
          </DescriptionButton>
        )}
      </EditBillLineItemCells.LeftDataCell>
    </Container>
  );
};

const DescriptionRow = ({billItemTypeForm, index}) => {
  return (
    <Row>
      <EditBillLineItemCells.IconCell />
      <Space width={2} />
      <EditBillLineItemCells.FullWidthCell>
        <KindLabelContainer index={index}>
          <EditBillLineItemCells.CellCaption>Description</EditBillLineItemCells.CellCaption>
        </KindLabelContainer>
        <Row>
          <Space width={8} />
          <EditBillLineItemCells.CellText color={colors.gray.secondary}>
            {billItemTypeForm.description}
          </EditBillLineItemCells.CellText>
        </Row>
      </EditBillLineItemCells.FullWidthCell>
      <Space width={2} />
      <EditBillLineItemCells.IconCell />
    </Row>
  );
};

const QuantityColumn = ({billItemTypeForm, isFirstItem, isEnabledTbdBillItems}) => {
  return (
    <Container>
      {isFirstItem && (
        <React.Fragment>
          <EditBillLineItemCells.CellText
            color={colors.gray.tertiary}
            style={{alignSelf: 'flex-end'}}
          >
            Quantity
          </EditBillLineItemCells.CellText>
          <Space height={8} />
        </React.Fragment>
      )}
      <EditBillLineItemCells.DataCell hasBorder isFirstItem={isFirstItem}>
        <EditBillLineItemCells.CellText vars={{hasPad: true}}>
          {BillItemForm.getEstimateQuantity(billItemTypeForm, {isEnabledTbdBillItems})}
        </EditBillLineItemCells.CellText>
      </EditBillLineItemCells.DataCell>
    </Container>
  );
};

const AmountPriceInput = ({billItemTypeForm, isFirstItem, isEnabledTbdBillItems}) => {
  return (
    <EditBillLineItemCells.RightDataCell hasBorder isFirstItem={isFirstItem}>
      <EditBillLineItemCells.CellText vars={{hasPad: true}}>
        {isEnabledTbdBillItems && _.isNil(billItemTypeForm.amount)
          ? 'TBD'
          : Currency.display(billItemTypeForm.amount)}
      </EditBillLineItemCells.CellText>
    </EditBillLineItemCells.RightDataCell>
  );
};

const PercentPriceInput = ({billItemTypeForm, isFirstItem, isEnabledTbdBillItems}) => {
  return (
    <EditBillLineItemCells.RightDataCell hasBorder isFirstItem={isFirstItem}>
      <EditBillLineItemCells.CellText vars={{hasPad: true}}>
        {isEnabledTbdBillItems && _.isNil(billItemTypeForm.percentage)
          ? 'TBD'
          : Percent.display(billItemTypeForm.percentage)}
      </EditBillLineItemCells.CellText>
    </EditBillLineItemCells.RightDataCell>
  );
};

const PriceColumn = ({billItemTypeForm, isFirstItem, isEnabledTbdBillItems}) => {
  return (
    <Container>
      {isFirstItem && (
        <React.Fragment>
          <EditBillLineItemCells.CellText
            color={colors.gray.tertiary}
            style={{alignSelf: 'flex-end'}}
          >
            Price
          </EditBillLineItemCells.CellText>
          <Space height={8} />
        </React.Fragment>
      )}
      {billItemTypeForm.kind === BillItemTypeKind.AMOUNT ? (
        <AmountPriceInput
          billItemTypeForm={billItemTypeForm}
          isFirstItem={isFirstItem}
          isEnabledTbdBillItems={isEnabledTbdBillItems}
        />
      ) : (
        <PercentPriceInput
          billItemTypeForm={billItemTypeForm}
          isFirstItem={isFirstItem}
          isEnabledTbdBillItems={isEnabledTbdBillItems}
        />
      )}
    </Container>
  );
};

const ActionsItemColumn = ({form, field, billItemTypeUseForm, isFirstItem, index}) => {
  const editBillItemItemTypeModal = useModal({name: 'Bill Template Edit Bill Item Type Modal'});
  return (
    <Container>
      {isFirstItem && <Space height={26} />}
      <EditBillLineItemCells.IconCell>
        <Space height={3} />
        <EditBillLineItemCells.IconCircle
          activeOpacity={0.8}
          color={colors.gray.tertiary}
          onPress={() => removeBillItemType(form, field, index)}
        >
          <Icon source={Icon.Trash} size={12} color={colors.gray.tertiary} />
        </EditBillLineItemCells.IconCircle>
        <Space height={2} />
        <EditBillLineItemCells.IconCircle
          activeOpacity={0.8}
          color={colors.gray.tertiary}
          onPress={editBillItemItemTypeModal.handleOpen}
        >
          <Icon source={Icon.Pen} size={12} color={colors.gray.tertiary} />
        </EditBillLineItemCells.IconCircle>
      </EditBillLineItemCells.IconCell>
      <BillTemplateEditBillItemTypeModal
        key={editBillItemItemTypeModal.key}
        form={billItemTypeUseForm}
        field={'billItemTypeForm'}
        isOpen={editBillItemItemTypeModal.isOpen}
        handleClose={editBillItemItemTypeModal.handleClose}
      />
    </Container>
  );
};

const BillingLibraryBillItemTypesRowV1 = ({
  form,
  field,
  billItemType,
  index,
  userId,
  isEnabledTbdBillItems,
}) => {
  // We create a new form for the billItemType and use it to display the billItemType
  // values. This is done so that the values can be updated when the user edits and
  // saves new values to a billItemType via the edit bill item type modal. Instead of
  // having to refetch the updated billItemType, we can dynamically update it through
  // this form.
  const billItemTypeFormEdit = BillItemTypeForm.edit(billItemType, {userId});
  const billItemTypeUseForm = useForm({
    initialValues: {billItemTypeForm: billItemTypeFormEdit},
  });
  const {billItemTypeForm} = billItemTypeUseForm.values;
  const [isShowingDescription, setIsShowingDescription] = useState(false);
  const isFirstItem = index === 0;
  return (
    <React.Fragment>
      <Row>
        <MoveItemColumn form={form} field={field} isFirstItem={isFirstItem} index={index} />
        <Space width={2} />
        <NameColumn
          billItemTypeForm={billItemTypeForm}
          index={index}
          handleShowDescription={() => {
            if (isShowingDescription) {
              setIsShowingDescription(false);
            } else {
              setIsShowingDescription(true);
            }
          }}
          isFirstItem={isFirstItem}
          isShowingDescription={isShowingDescription}
          billItemType={billItemType}
        />
        <QuantityColumn
          billItemTypeForm={billItemTypeForm}
          isFirstItem={isFirstItem}
          index={index}
          isEnabledTbdBillItems={isEnabledTbdBillItems}
        />
        <PriceColumn
          billItemTypeForm={billItemTypeForm}
          isFirstItem={isFirstItem}
          index={index}
          isEnabledTbdBillItems={isEnabledTbdBillItems}
        />
        <Space width={2} />
        <ActionsItemColumn
          form={form}
          field={field}
          billItemTypeUseForm={billItemTypeUseForm}
          isFirstItem={isFirstItem}
          index={index}
        />
      </Row>
      {isShowingDescription && <DescriptionRow billItemTypeForm={billItemTypeForm} index={index} />}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryBillItemTypesRowV1.fragment = gql`
  ${BillItemTypeForm.edit.fragment}
  ${BillItemType.getDisplayName.fragment}

  fragment BillingLibraryBillItemTypesRowV1 on BillItemType {
    id
    name
    ...BillItemType_getDisplayName
    ...BillItemTypeForm_edit
  }
`;

export default BillingLibraryBillItemTypesRowV1;
