const INDIVIDUAL_MOVER_ONLY = 'INDIVIDUAL_MOVER_ONLY';
const ALL_ORGANIZATION_JOBS = 'ALL_ORGANIZATION_JOBS';

const VISIBLE_JOB_OPTIONS = [INDIVIDUAL_MOVER_ONLY, ALL_ORGANIZATION_JOBS] as const;

export type CrewVisibleJobsForMoverType = (typeof VISIBLE_JOB_OPTIONS)[number];

const getLabel = (crewVisibleJobForMover: CrewVisibleJobsForMoverType) => {
  switch (crewVisibleJobForMover) {
    case INDIVIDUAL_MOVER_ONLY:
      return 'Assigned to individual mover only';
    case ALL_ORGANIZATION_JOBS:
      return 'Assigned to any mover';
    default:
      return crewVisibleJobForMover;
  }
};

const CrewVisibleJobsForMover = {
  INDIVIDUAL_MOVER_ONLY,
  ALL_ORGANIZATION_JOBS,

  getLabel,
};

export default CrewVisibleJobsForMover;
