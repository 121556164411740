// Libraries
import React from 'react';

// Supermove
import {Drawer, Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Line from 'modules/App/components/Line';
import NewTaskFields from 'modules/TaskManagement/Tasks/components/NewTaskFields';

const IconButton = Styled.ButtonV2`
  padding: 6px;
`;

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.white};
  width: ${({responsive}) => (responsive.mobile ? '100%' : '424px')};
`;

const LoadingContainer = Styled.View`
  flex: 1;
  justify-content: center;
`;

const LoadingIndicator = Styled.Loading`
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  height: 72px;
  align-items: center;
  padding-horizontal: 24px;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading2}
`;

const Header = ({handleClose}) => {
  return (
    <HeaderContainer>
      <HeaderText>New Task</HeaderText>
      <Space style={{flex: 1}} />
      <IconButton onPress={handleClose}>
        <Icon source={Icon.Times} size={18} color={colors.gray.primary} />
      </IconButton>
    </HeaderContainer>
  );
};

const LoadingComponent = () => {
  return (
    <LoadingContainer>
      <LoadingIndicator />
    </LoadingContainer>
  );
};

const NewTaskDrawer = ({isOpen, handleClose, projectUuid, refetch}) => {
  const responsive = useResponsive();
  const {data, loading} = useQuery(NewTaskDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectUuid: projectUuid || ''},
    skip: !isOpen,
  });
  const [hasChanges, setHasChanges] = useState(false);

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} shouldCloseOnClickOutside={!hasChanges}>
      <Container responsive={responsive}>
        <Header handleClose={handleClose} />
        <Line />
        <Loading loading={loading || !data} as={LoadingComponent}>
          {() => (
            <NewTaskFields
              organization={data.viewer.viewingOrganization}
              project={data.project}
              handleClose={handleClose}
              refetch={refetch}
              hasChanges={hasChanges}
              setHasChanges={setHasChanges}
              style={{flex: 1}}
            />
          )}
        </Loading>
      </Container>
    </Drawer>
  );
};

NewTaskDrawer.query = gql`
  ${NewTaskFields.fragment}
  query NewTaskDrawer($projectUuid: String!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...NewTaskFields_Organization
      }
    }
    project(uuid: $projectUuid) {
      id
      ...NewTaskFields_Project
    }
  }
`;

export default NewTaskDrawer;
