import {
  and,
  multiply,
  unequal,
  subtract,
  equal,
  greaterEq,
  greaterThan,
  lessEq,
  lessThan,
  or,
  add,
  divide,
  not,
  unaryMinus,
  FunctionDefinition,
} from './functions';

type OperatorMap = {[operatorKind: string]: boolean};

export const prefixOperators: OperatorMap = {
  Bang: true,
  Not: true,
};

export const infixOperators: OperatorMap = {
  BangEqual: true,
  DoubleEqual: true,
  GreaterThan: true,
  GreaterEqual: true,
  LessThan: true,
  LessEqual: true,
  Or: true,
  And: true,
  Plus: true,
  Slash: true,
  Asterisk: true,
};

export const infixOperatorImplementations: {[operatorKind: string]: FunctionDefinition} = {
  And: and,
  Asterisk: multiply,
  BangEqual: unequal,
  Dash: subtract,
  DoubleEqual: equal,
  GreaterEqual: greaterEq,
  GreaterThan: greaterThan,
  LessEqual: lessEq,
  LessThan: lessThan,
  Or: or,
  Plus: add,
  Slash: divide,
};

export const prefixOperatorImplementations: {[operatorKind: string]: FunctionDefinition} = {
  Bang: not,
  Not: not,
  Dash: unaryMinus,
};
