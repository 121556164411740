// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, useNavigationDOM, useQuery, usePagination} from '@supermove/hooks';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import InvoiceList from 'modules/Accounting/components/InvoiceList';
import InvoiceFilters from 'modules/Storage/components/InvoiceFilters';
import StorageProjectPageInformationBlockContent from 'modules/Storage/components/StorageProjectPageInformationBlockContent';

const ContentContainer = Styled.View`
  height: 100%;
`;

const InvoicesPanel = ({projectUuid, isJobPage}) => {
  const {params} = useNavigationDOM();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const {data, loading, refetch, error} = useQuery(InvoicesPanel.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: projectUuid || params.projectUuid,
      pagination: {
        page: currentPage,
        resultsPerPage: 5,
      },
      searchQuery,
    },
  });

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(data, 'project.paginatedList.paginationMetadata'),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });

  // New logic with viewing org requires data to be fetched before loading
  if (loading) {
    return null;
  }

  return (
    <ContentContainer>
      {!loading && (
        <InvoiceFilters
          setSearchQuery={(text) => {
            setSearchQuery(text);
            // Reset to the first page when filters change
            setCurrentPage(1);
          }}
          isJobPage={isJobPage}
          project={data.project}
          refetch={refetch}
        />
      )}
      <InvoiceList
        loading={loading}
        refetch={refetch}
        invoices={data ? data.project.paginatedList.invoices : []}
        hasError={!!error}
        viewingOrganization={data.viewer.viewingOrganization}
        hideSupplementalFields
      />
      <Space height={16} />
      <PaginationBar pagination={pagination} />
      <Space height={16} />
    </ContentContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoicesPanel.query = gql`
  ${usePagination.fragment}
  ${InvoiceList.fragment}
  ${InvoiceFilters.fragment}
  ${StorageProjectPageInformationBlockContent.listener}

  query InvoicesPanel(
    $projectUuid: String!
    $status: String
    $searchQuery: String
    $pagination: PaginationInput!
  ) {
    ${gql.query}
    viewer {
      id
      ...InvoiceList_Viewer
    }
    project(uuid: $projectUuid) {
      id
      paginatedList: filteredInvoicesPaginatedList(
        status: $status
        searchQuery: $searchQuery
        pagination: $pagination
      ) {
        invoices: results {
          id
          ...InvoiceList
        }
        paginationMetadata {
          ...usePagination
        }
      }
      ...InvoiceFilters
      ...StorageProjectPageInformationBlockContent_listener
    }
  }
`;

export default InvoicesPanel;
