// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ConfirmationStepForm from '@shared/modules/Proposal/forms/ConfirmationStepForm';

const useAddConfirmationStepMutation = ({confirmationStepForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      confirmationStepForm: ConfirmationStepForm.toForm(confirmationStepForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useAddConfirmationStepMutation.mutation,
    variables: {
      confirmationStepForm: ConfirmationStepForm.toMutation(form.values.confirmationStepForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useAddConfirmationStepMutation.mutation = gql`
  mutation useAddConfirmationStepMutation($confirmationStepForm: ConfirmationStepForm!) {
    response: addConfirmationStepToConfirmationSteps(confirmationStepForm: $confirmationStepForm) {
      ${gql.errors}
      confirmationSteps {
        id
      }
    }
  }
`;

export default useAddConfirmationStepMutation;
