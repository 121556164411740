// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, PercentInput, Icon, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import Field from 'modules/App/components/Field';
import Switch from 'modules/App/components/Switch';

const Row = Styled.View`
  flex-direction: row;
`;

const ActionsColumn = Styled.View`
`;

const IconButton = Styled.ButtonV2`
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border-width: 1px;
  border-color: ${({color}) => color};
  background-color: ${colors.white};
`;

const FieldsColumn = Styled.View`
  flex: 1;
`;

const SwitchContainer = Styled.View`
  height: 40px;
  width: 36px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray.border};
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const TextInput = Styled.TextInput.H6`
  border-color: ${colors.gray.border};
`;

const ActionButtons = ({isReordering, handleRemove, handleChangeOrder, index, isLastItem}) => {
  return (
    <ActionsColumn>
      {isReordering ? (
        <React.Fragment>
          <IconButton
            color={index === 0 ? colors.gray.border : colors.blue.interactive}
            onPress={() => handleChangeOrder(index - 1)}
            disabled={index === 0}
          >
            <Icon
              source={Icon.ArrowUp}
              color={index === 0 ? colors.gray.border : colors.blue.interactive}
              size={16}
            />
          </IconButton>
          <Space height={8} />
          <IconButton
            color={isLastItem ? colors.gray.border : colors.blue.interactive}
            onPress={() => handleChangeOrder(index + 1)}
            disabled={isLastItem}
          >
            <Icon
              source={Icon.ArrowDown}
              color={isLastItem ? colors.gray.border : colors.blue.interactive}
              size={16}
            />
          </IconButton>
        </React.Fragment>
      ) : (
        <IconButton color={colors.gray.border} onPress={handleRemove}>
          <Icon source={Icon.Trash} color={colors.gray.secondary} size={12} />
        </IconButton>
      )}
    </ActionsColumn>
  );
};

const BillItemPreSubtotalRow = ({
  billItemForm,
  form,
  index,
  handleRemove,
  handleChangeOrder,
  isReordering,
  isLastItem,
}) => {
  const field = `billForm.billItemFormsPreSubtotal.${index}`;
  const isTaxable = _.get(form.values, `${field}.isTaxable`);
  return (
    <React.Fragment>
      <Row>
        <Space width={20} />
        <ActionButtons
          isReordering={isReordering}
          handleRemove={handleRemove}
          handleChangeOrder={handleChangeOrder}
          index={index}
          isLastItem={isLastItem}
        />
        <Space width={4} />
        <FieldsColumn>
          <Field
            {...form}
            name={`${field}.name`}
            component={TextInput}
            input={{
              placeholder: 'Name',
              required: !_.get(billItemForm, 'name'),
              disabled: !!billItemForm.nameFormulaId,
            }}
          />
          <Space height={4} />
          <Row>
            <Field
              {...form}
              name={`${field}.minQuantity`}
              component={TextInput}
              input={{
                placeholder: 'Min',
                disabled: BillItemForm.getHasQuantityFormula(billItemForm),
                keyboardType: 'number-pad',
                style: {
                  width: 72,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
            />
            <Field
              {...form}
              name={`${field}.maxQuantity`}
              component={TextInput}
              input={{
                placeholder: 'Max',
                disabled: BillItemForm.getHasQuantityFormula(billItemForm),
                keyboardType: 'number-pad',
                style: {
                  width: 72,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                },
              }}
            />
            <Space width={4} />
            <FieldsColumn>
              <Field
                {...form}
                name={`${field}.amount`}
                component={CurrencyInput}
                input={{
                  component: TextInput,
                  placeholder: 'Price',
                  disabled: !!billItemForm.amountFormulaId,
                  setFieldValue: form.setFieldValue,
                  setFieldTouched: form.setFieldTouched,
                  keyboardType: 'default',
                  options: {
                    enableEmptyValues: true,
                  },
                }}
              />
            </FieldsColumn>
          </Row>
          <Space height={4} />
          <Row>
            <Checkbox
              isChecked={isTaxable}
              handleToggle={() => form.setFieldValue(`${field}.isTaxable`, !isTaxable)}
              label={'Taxable'}
            />
          </Row>
        </FieldsColumn>
        <Space width={12} />
      </Row>
    </React.Fragment>
  );
};

const BillItemPostSubtotalRow = ({
  billItemForm,
  form,
  index,
  handleRemove,
  handleChangeOrder,
  isReordering,
  isLastItem,
}) => {
  return (
    <Row>
      <Space width={20} />
      <ActionButtons
        isReordering={isReordering}
        handleRemove={handleRemove}
        handleChangeOrder={handleChangeOrder}
        index={index}
        isLastItem={isLastItem}
      />
      <Space width={4} />
      <FieldsColumn>
        <Field
          {...form}
          name={`billForm.billItemFormsPostSubtotal.${index}.name`}
          component={TextInput}
          input={{
            placeholder: 'Name',
            required: !_.get(billItemForm, 'name'),
            disabled: !!billItemForm.nameFormulaId,
          }}
        />
        <Space height={4} />
        <Row>
          <SwitchContainer>
            <Switch isOn color={colors.green.status} onChange={() => {}} />
          </SwitchContainer>
          <Space width={4} />
          <FieldsColumn>
            {BillItemForm.getIsAmount(billItemForm) && (
              <Field
                {...form}
                name={`billForm.billItemFormsPostSubtotal.${index}.amount`}
                component={CurrencyInput}
                input={{
                  component: TextInput,
                  placeholder: '$100.00',
                  disabled: !!billItemForm.amountFormulaId,
                  setFieldValue: form.setFieldValue,
                  setFieldTouched: form.setFieldTouched,
                  keyboardType: 'default',
                  required: !_.get(billItemForm, 'amount'),
                }}
              />
            )}
            {BillItemForm.getIsPercentage(billItemForm) && (
              <Field
                {...form}
                name={`billForm.billItemFormsPostSubtotal.${index}.percentage`}
                component={PercentInput}
                input={{
                  component: TextInput,
                  placeholder: '-50%',
                  disabled: !!billItemForm.amountFormulaId,
                  setFieldValue: form.setFieldValue,
                  setFieldTouched: form.setFieldTouched,
                  keyboardType: 'default',
                  required: !_.get(billItemForm, 'percentage'),
                }}
              />
            )}
          </FieldsColumn>
        </Row>
      </FieldsColumn>
      <Space width={12} />
    </Row>
  );
};

const EditBillItemsBillItem = ({
  billItemForm,
  form,
  index,
  handleRemoveBillItemForm,
  handleChangeOrder,
  isReordering,
  isLastItem,
}) => {
  const isBillItemPreSubtotalForm = billItemForm.billStage === BillStage.PRE_SUBTOTAL;
  return (
    <React.Fragment>
      {isBillItemPreSubtotalForm ? (
        <BillItemPreSubtotalRow
          billItemForm={billItemForm}
          form={form}
          index={index}
          handleRemove={handleRemoveBillItemForm}
          handleChangeOrder={handleChangeOrder}
          isReordering={isReordering}
          isLastItem={isLastItem}
        />
      ) : (
        <BillItemPostSubtotalRow
          billItemForm={billItemForm}
          form={form}
          index={index}
          handleRemove={handleRemoveBillItemForm}
          handleChangeOrder={handleChangeOrder}
          isReordering={isReordering}
          isLastItem={isLastItem}
        />
      )}
    </React.Fragment>
  );
};

export default EditBillItemsBillItem;
