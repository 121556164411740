// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Icon, PercentInput, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import PaymentFeeKind from '@shared/modules/Organization/enums/PaymentFeeKind';
import OrganizationPaymentFeesForm from '@shared/modules/Organization/forms/OrganizationPaymentFeesForm';
import useUpdateOrganizationPaymentFeesMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationPaymentFeesMutation';

const AmountField = ({kind, form, field}) => {
  switch (kind) {
    case PaymentFeeKind.PERCENT:
      return (
        <FieldInput
          {...form}
          name={`${field}.percentage`}
          label={'Percentage'}
          isRequired
          isResponsive
          component={PercentInput}
          input={{
            placeholder: 'Enter percentage',
            component: FieldInput.TextInput,
            setFieldTouched: form.setFieldTouched,
            setFieldValue: form.setFieldValue,
          }}
        />
      );
    case PaymentFeeKind.RATE:
      return (
        <FieldInput
          {...form}
          name={`${field}.amount`}
          label={'Amount Per Hour'}
          component={CurrencyInput}
          isRequired
          isResponsive
          input={{
            component: FieldInput.TextInput,
            placeholder: 'Enter amount per hour',
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
          }}
        />
      );
    default:
      return null;
  }
};

const PaymentFeeFields = ({form, field}) => {
  const kind = _.get(form.values, `${field}.kind`);
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        name={`${field}.name`}
        label={'Name'}
        isRequired
        isResponsive
        input={{
          placeholder: 'Enter name',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.kind`}
        label={'Fee Type'}
        component={DropdownInput}
        isRequired
        isResponsive
        input={{
          placeholder: 'Select a fee type',
          options: [
            {
              label: 'Percentage (%)',
              value: PaymentFeeKind.PERCENT,
            },
            {
              label: 'Amount Per Hour ($)',
              value: PaymentFeeKind.RATE,
            },
            {
              label: 'No Fee',
              value: PaymentFeeKind.NO_FEE,
            },
          ],
          setFieldValue: form.setFieldValue,
          isPortaled: true,
          style: {flex: 1},
        }}
        style={{flex: 1}}
      />
      <Space height={16} />
      {kind !== PaymentFeeKind.NO_FEE && <AmountField kind={kind} form={form} field={field} />}
    </React.Fragment>
  );
};

const PaymentFeeModal = ({
  title,
  isOpen,
  handleClose,
  organizationPaymentFeesForm,
  form: parentForm,
  field: parentField,
  index,
}) => {
  const {form, handleSubmit, submitting} = useUpdateOrganizationPaymentFeesMutation({
    organizationPaymentFeesForm,
    onSuccess: () => {
      parentForm.setFieldValue(parentField, _.get(form.values, parentField));
      handleClose();
    },
    onError: (errors) => {
      console.log(errors);
    },
  });
  const field = `${parentField}.${index}`;
  return (
    <SmallModal isOpen={isOpen}>
      <SmallModal.HeaderText>{title}</SmallModal.HeaderText>
      <Space height={16} />
      <PaymentFeeFields form={form} field={field} />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={submitting} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          isSubmitting={submitting}
          iconLeft={Icon.Check}
          color={colors.blue.interactive}
          onPress={handleSubmit}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

const EditPaymentFeeModal = ({isOpen, handleClose, form, field, index, organization}) => {
  const organizationPaymentFeesForm = OrganizationPaymentFeesForm.edit(organization);
  return (
    <PaymentFeeModal
      title={'Edit Payment Fee'}
      isOpen={isOpen}
      handleClose={handleClose}
      organizationPaymentFeesForm={organizationPaymentFeesForm}
      form={form}
      field={field}
      index={index}
    />
  );
};

const AddPaymentFeeModal = ({isOpen, handleClose, form, field, organization}) => {
  const organizationPaymentFeesForm =
    OrganizationPaymentFeesForm.editWithNewPaymentFee(organization);
  const paymentForms = _.get(form.values, field);
  const index = paymentForms.length;
  return (
    <PaymentFeeModal
      title={'Add Payment Fee'}
      isOpen={isOpen}
      handleClose={handleClose}
      organizationPaymentFeesForm={organizationPaymentFeesForm}
      form={form}
      field={field}
      index={index}
    />
  );
};

PaymentFeeModal.Edit = EditPaymentFeeModal;
PaymentFeeModal.Add = AddPaymentFeeModal;

// --------------------------------------------------
// Data
// --------------------------------------------------
PaymentFeeModal.Edit.fragment = gql`
  ${OrganizationPaymentFeesForm.edit.fragment}
  fragment PaymentFeeModal_Edit on Organization {
    id
    ...OrganizationPaymentFeesForm_edit
  }
`;

PaymentFeeModal.Add.fragment = gql`
  ${OrganizationPaymentFeesForm.editWithNewPaymentFee.fragment}
  fragment PaymentFeeModal_Add on Organization {
    id
    ...OrganizationPaymentFeesForm_editWithNewPaymentFee
  }
`;

export default PaymentFeeModal;
