// Libraries
import React from 'react';

// Supermove
import {Popover, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import OrganizationJobCardSample from 'modules/Dispatch/Calendar/Day/components/OrganizationJobCardSample';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const TitleText = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const DescriptionText = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
`;

const BookingStatuses = () => {
  return (
    <React.Fragment>
      <TitleText>Booking Status</TitleText>
      <Space height={8} />
      <DescriptionText>Here is an example of what a booked job looks like:</DescriptionText>
      <Space height={4} />
      <OrganizationJobCardSample />
      <Space height={8} />
      <DescriptionText>Here is an example of what a hold job looks like:</DescriptionText>
      <Space height={4} />
      <OrganizationJobCardSample isPending />
    </React.Fragment>
  );
};

const CalendarHelpPopover = ({popover}) => {
  return (
    <ResponsivePopover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.Container width={432}>
        <ContentContainer>
          <Space height={16} />
          <BookingStatuses />
          <Space height={16} />
        </ContentContainer>
      </ResponsivePopover.Container>
    </ResponsivePopover>
  );
};

export default CalendarHelpPopover;
